import React, { useEffect, useState } from 'react';
import { Spin, Alert } from 'antd';
import { Progress } from 'antd';
import { useLocation } from 'react-router';
import querString from 'query-string';

const UpdateProgressWindow = ({ test }) => {
	const location = useLocation();
	const id = querString.parse(location.search);
	const per = parseInt(id.id);


	return (
		<div>
			<Progress percent={per} />
		</div>
	);
};

export default UpdateProgressWindow;
