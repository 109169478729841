import queryString from "query-string";
import { getService, postService } from "../../../commonServices";
import { appBaseUrl } from "utils/config";

export class ReferenceOthersService {
  referenceOthersPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/referenceOthersPagination?${query}`;
    let data = getService(url);
    return data;
  }
  referenceOthersGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/referenceOthersGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  referenceOthersAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/referenceOthersAdd`;
    let data = postService(url, body);
    return data;
  }
  referenceOthersUpdate(body) {
    // console.log("apicalled")
    let url = `${appBaseUrl}/referenceManagement/referenceOthersUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  referenceOthersFavAddUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/referenceOthersFavoriteAddUpdate`;
    let data = postService(url, body);
    return data;
  }

  // for primary and secondary tag list in other reference
  referenceOthersPaginationWithRootLevel(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/referenceOthersPaginationWithRootLevel?${query}`;
    let data = getService(url);
    return data;
  }

  // ReferenceOthersCombineAdd

  referenceOthersCombineAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/referenceOthersCombineAdd`;
    let data = postService(url, body);
    return data;
  }
  // /api/app/referenceManagement/referenceOthersCombineUpdate/{referenceOthersId}
  referenceOthersCombineUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/referenceOthersCombineUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
}
