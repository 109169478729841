import { Skeleton } from 'antd';
const Rows = ({
  columns,
  rows,
  length,
  tableHeight,
  scrollTarget,
  totalLength,
  loading,
  activeKey,
}) => {
  return (
    <div
      className='global-rows-items'
      id={scrollTarget}
      style={{ height: tableHeight }}
    >
      <div className={`global-rows-flex`}>
        {rows &&
          rows.length > 0 &&
          rows?.map((row, index) => {
            return (
              <div
                className={`global-rows-item  w-${length} ${
                  row.id == activeKey && 'active'
                }`}
                key={index}
                data-id={row.id}
              >
                {columns?.map((column, i) => {
                  return (
                    <div
                      className={`global-rows-info-wrapper ${
                        column.className ? column.className : ''
                      } ${column.center && 'text-center'}`}
                      key={i}
                      data-id={row.id}
                    >
                      {column.render ? column.render(row) : ''}
                    </div>
                  );
                })}
              </div>
            );
          })}
        {loading && (
          <div className='loader-data-wrapper'>
            <Skeleton />
          </div>
        )}
        {rows && totalLength === rows.length && (
          <div className={`end-data-wrapper`}>
            <p>{rows.length > 0 ? 'End of Data' : 'No Data'}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Rows;
