import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import InterviewTemplateAddUpdateForm from "./InterviewTemplateAddUpdateForm";
import InterviewTemplateTable from "./InterviewTemplateTable";
import { questionTypeGet } from "services/redux/profile/profile.action";

const InterviewQuestion = () => {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState({
    drawer: false,
    modal: false,
    edit: false,
  });
  const [updateData, setUpdateData] = useState();

  useEffect(() => {
    dispatch(questionTypeGet());
  }, []);

  return (
    <>
      <div className="layout">
        <div className="organizer events">
          <div className="all-header-wrapper">
            <div className="meeting__flex">
              <h3 className="header__all">Interview Question</h3>
              <div className="organizerHoliday__middle--wrap">
                <Button
                  className="btn-primary"
                  onClick={() => {
                    setOpenForm({ drawer: true, modal: false });
                  }}
                >
                  <PlusOutlined /> New Question
                </Button>
              </div>
            </div>
          </div>
          <InterviewTemplateAddUpdateForm
            openForm={openForm}
            setOpenForm={setOpenForm}
            updateData={updateData}
            setUpdateData={setUpdateData}
          />
          <InterviewTemplateTable
            setOpenForm={setOpenForm}
            setUpdateData={setUpdateData}
          />
        </div>
      </div>
    </>
  );
};

export default InterviewQuestion;
