import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import moment from "moment";

import { actionForWeeklyAddUpdateReportGetDetails } from "services/redux/cead/action";
import questionCeadWeeklyReport from "utils/questionCeadWeeklyReport";

export function CEADWeeklyReportData({ id }) {
  const { tenderCeadWeeklyReportDetail, tenderCeadWeeklyReportDetailLoading } =
    useSelector((state) => state.ceadReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionForWeeklyAddUpdateReportGetDetails(id));
  }, [id]);

  const formatValue = (key, data) => {
    if (
      key === "reportingDate" ||
      key === "reportingToDate" ||
      key === "reportingFromDate" ||
      key === "submittedDate"
    ) {
      return moment(data[key]).format("YYYY-MM-DD");
    }
    return data[key];
  };

  const dataSource =
    tenderCeadWeeklyReportDetail &&
    Object.keys(tenderCeadWeeklyReportDetail)
      .filter(
        (key) =>
          key !== "isActive" &&
          key !== "tenderCEAD_Id" &&
          key !== "id" &&
          key !== "creatorUserDto" &&
          key !== "reportingFromDate" &&
          key !== "reportingToDate" &&
          key !== "applicationType" &&
          key !== "applicationTypeId" &&
          key !== "internalMessage"
      )
      .map((key, index) => ({
        key: index + 1,
        keyValue:
          key === "reportingDate"
            ? "Reporting Date"
            : key === "submittedDate"
            ? "Submitted Date"
            : questionCeadWeeklyReport(key),
        value: formatValue(key, tenderCeadWeeklyReportDetail),
      }));

  const columns = [
    {
      title: "S.N",
      dataIndex: "key",
      key: "key",
      width: "8%",
    },
    {
      title: "Title",
      dataIndex: "keyValue",
      key: "keyValue",
      width: "20%",
    },
    {
      title: "Remarks",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <>
      <div className="c-w-r" style={{ padding: "10px" }}>
        <Table
          dataSource={dataSource}
          columns={columns}
          loading={tenderCeadWeeklyReportDetailLoading}
          pagination={false}
          className="c-w-r-c-t"
        />
      </div>
    </>
  );
}
