import moment from "moment";

const getColumnsGuest = () => {
  return [
    {
      title: "S.N",
      dataIndex: "",
      render: (text, row, index) => index + 1,
    },
    {
      title: "Company Name",
      dataIndex: "sisterCompanyName",
      key: "sisterCompanyName",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Guests Applied",
      dataIndex: "applyNumberOfGuest",
      key: "applyNumberOfGuest",
    },
    {
      title: "Arrival Time",
      dataIndex: "arrivalDate",
      key: "arrivalDate",
      // render: (date) => moment(date).format("DD MMM YYYY"),
      render: (_, record) => {
        if (
          record?.applicationDate &&
          record.applicationDate !== "0001-01-01T00:00:00"
        ) {
          return moment(record.applicationDate).format("DD MMM YYYY");
        } else {
          return null;
        }
      },
    },
    {
      title: "Departure Time",
      dataIndex: "departureDate",
      key: "departureDate",
      render: (date) => moment(date).format("DD MMM YYYY"),
    },
    {
      title: "Status",
      key: "currentGuestEntertainmentStatus",
      dataIndex: "currentGuestEntertainmentStatus",
      render: (data) => <span className="application_status_name">{data}</span>,
    },
  ];
};

export default getColumnsGuest;
