import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  Input,
  Select,
  DatePicker,
  TimePicker,
  message,
  Layout,
  InputNumber,
  Modal,
  Checkbox,
  Tabs,
} from "antd";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import moment from "moment";
import { validateMessages } from "utils/misc";
import {
  userRegistrationAddUpdate,
  employeeInfoDelete,
  getOrganizationListing,
} from "services/redux/profile/profile.action";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import { getAllRoles } from "services/redux/adminSettings/roles/action";
import { getAllUsers } from "services/remanentCalls";
import { isEmpty } from "lodash";

const { Option } = Select;
const { confirm } = Modal;

const { Header, Footer } = Layout;

const labelStyle = {
  fontSize: "12px",
  fontWeight: "500",
};

const AddUserDrawer = ({
  onAddUserClose,
  drawerVisibility,
  isUpdateCredentials,
  form,
}) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { allRoleLists } = useSelector((state) => state.roleLists);
  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);
  const {
    userRegisteration,
    profileInfo,
    organizationListing,
    userRegisterationLoading,
  } = useSelector((state) => state.profile);

  const [user, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [leftCompany, setLeftCompany] = useState();
  const [isLocked, setIsLocked] = useState();
  const [sisterEmail, setSisterEmail] = useState([]);
  const [openOutBoxEmailField, setOpenOutBoxEmailField] = useState(false);

  function showConfirm() {
    confirm({
      title: "Do you Want to delete this User?",
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(
          employeeInfoDelete(profileInfo.userRegistrationAddUpdateDto.appUserId)
        );
      },

      onCancel() {},
    });
  }

  useEffect(() => {
    dispatch(getOrganizationListing());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(isUpdateCredentials)) {
      // {
      // console.log("isUpdateCredentials: ", isUpdateCredentials)
      // 	let employeeEmailDtos = {
      // 		emailAddress: '',
      // 	};

      // 	form.setFieldsValue({
      // 		employeeEmailDtos
      // 	})
      // 	return
      // };

      setSisterEmail(
        isUpdateCredentials.userRegistrationAddUpdateDto.employeeEmailDtos
      );
      form.setFieldsValue({
        ...isUpdateCredentials.userRegistrationAddUpdateDto,
        // added
        ...isUpdateCredentials?.employeeOfficialDetailUpdateDto,
        shiftTimeIn: moment(
          isUpdateCredentials.userRegistrationAddUpdateDto.shiftTimeIn
        ),
        shiftTimeOut: moment(
          isUpdateCredentials.userRegistrationAddUpdateDto.shiftTimeOut
        ),
      });
    } else {
      form.resetFields();
    }
  }, [isUpdateCredentials]);

  useEffect(() => {
    setOpenOutBoxEmailField(
      isUpdateCredentials?.userRegistrationAddUpdateDto?.isOutsideUser
    );
  }, [isUpdateCredentials]);

  useEffect(() => {
    dispatch(getAllRoles());
    // fetchAllRoles();
    if (isEmpty(sisterCompanyListing)) {
      dispatch(getSisterCompaniesListings());
    }
  }, []);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  useEffect(() => {
    if (userRegisteration) {
      onAddUserClose();
      form.resetFields();
    }
  }, [userRegisteration]);

  const onSubmit = async (type) => {
    try {
      let values = await form.validateFields();
      console.log(values, "values of form");
      const removeProp = "sisterCompanyDto";
      const { [removeProp]: remove, ...rest } = values;

      let sitserCompanyEmail = values.employeeEmailDtos
        ? [...values.employeeEmailDtos]
        : [
            ...(isUpdateCredentials?.userRegistrationAddUpdateDto
              ?.employeeEmailDtos || []),
          ];
      //   const data = sisterCompanyListing?.filter(
      //     (filtered) => filtered.id === values.employeeEmailDtos[0].sisterCompanyId
      //   );
      //   const result = array.filter((item) =>
      //     filterCategory.length ? filterCategory.includes(item.category) : item
      //   );

      if (type === "update") {
        let update = {
          ...rest,
          isLeftCompany: leftCompany,
          appUserId: isUpdateCredentials.userRegistrationAddUpdateDto.appUserId,
          isCurrent: true,
          employeeEmailDtos: sitserCompanyEmail?.map((obj) => ({
            emailAddress: obj.emailAddress,
            password: obj.password,
            sisterCompanyId: obj.sisterCompanyId,
            isSmtp: obj.isSmtp,
            isImap: obj.isImap,
            IsLateReasonNeeded: obj.isIsLateReasonNeeded,
            IsExcludedAttendanceView: obj.IsExcludedAttendanceView,
            isExitForm: obj.isExitForm,
            isLockedForCheckingInbox: isLocked,
            isDelete: obj.isDelete,
            id: obj.id ? obj.id : "",
            sisterCompanyDto: Object.assign(
              ...sisterCompanyListing?.filter((data) => {
                return data.id === obj.sisterCompanyId;
              })
            ),
          })),
        };
        dispatch(userRegistrationAddUpdate(update, leftCompany));
      } else {
        let add = {
          ...rest,
          isLeftCompany: leftCompany,
          employeeEmailDtos: values?.employeeEmailDtos?.map((obj) => ({
            sisterCompanyId: obj.sisterCompanyId,
            isSmtp: obj.isSmtp,
            isImap: obj.isImap,
            IsLateReasonNeeded: obj.IsLateReasonNeeded,
            IsExcludedAttendanceView: obj.IsExcludedAttendanceView,
            isLockedForCheckingInbox: isLocked,
            isDelete: obj.isDelete,
            emailAddress: obj.emailAddress,
            password: obj.password,
            id: "",
            sisterCompanyDto: Object.assign(
              {},
              ...sisterCompanyListing?.filter((data) => {
                if (data.id === obj.sisterCompanyId)
                  return {
                    ...data.id,
                  };
              })
            ),
          })),
        };

        console.log("add", add);
        dispatch(userRegistrationAddUpdate(add));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={550}
      className="drawer main-drawer-wrapper"
      onClose={onAddUserClose}
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">
          {`${!isEmpty(isUpdateCredentials) ? "Update" : "Create"} a user`}
        </h4>
      </Header>
      <div className="drawer__content">
        <div className="drawer__content--wrapper">
          <div className="contact__content__wrapper hr-module-wrapper">
            <Form
              className="add__user__form drawer-form-wrapper"
              layout="vertical"
              form={form}
              name="control-hooks"
              validateMessages={validateMessages}
            >
              <Tabs>
                <TabPane
                  className="basic-details-tab"
                  tab="Basic Details"
                  key="1"
                >
                  <div className="drawer-form-row">
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          className="form__group"
                          name="firstname"
                          label="First Name"
                          rules={[{ required: true }]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                        <Form.Item
                          className="form__group"
                          name="surname"
                          label="Last Name"
                          rules={[{ required: true }]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          className="form__group"
                          name="email"
                          label="Email Address"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ]}
                        >
                          {isUpdateCredentials?.userRegistrationAddUpdateDto
                            ?.email ? (
                            <Input readOnly />
                          ) : (
                            <Input />
                          )}
                        </Form.Item>
                        <Form.Item
                          className="form__group"
                          name="userName"
                          label="Username"
                          autoComplete="off"
                          rules={[{ required: true }]}
                        >
                          {isUpdateCredentials?.userRegistrationAddUpdateDto
                            ?.userName ? (
                            <Input autoComplete="off" readOnly />
                          ) : (
                            <Input autoComplete="off" />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                    {!isUpdateCredentials && (
                      <div className="drawer-form-group">
                        <div className="drawer-flex">
                          <Form.Item
                            className="form__group"
                            name="password"
                            label="Password"
                            autoComplete="new-password"
                            rules={[
                              {
                                required: true,
                                pattern:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()-_])[A-Za-z\d@$!%*?&#^()-_]{8,}$/,
                                message:
                                  "Minimum 8 characters, at least 1 uppercase letter, 1 number and 1 special character",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input.Password autoComplete="new-password" />
                          </Form.Item>
                          <Form.Item
                            className="form__group"
                            name="confirm"
                            label="Confirm Password"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    "The two passwords that you entered do not match!"
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password autoComplete="off" />
                          </Form.Item>
                        </div>
                      </div>
                    )}
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          className="form__group"
                          name="roleNames"
                          label="Roles"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            autoComplete="off"
                          >
                            {allRoleLists?.items?.map((role) => (
                              <Option
                                key={role?.id}
                                label={role?.name}
                                value={role.name}
                              >
                                <span>{role.name}</span>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="form__group"
                          name="phoneNumber"
                          label="Phone Number"
                          rules={[{ required: false }]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          className="form__group"
                          name="shiftTimeIn"
                          label="Shift Timein"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <TimePicker
                            use12Hours
                            format="h:mm A"
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Form.Item
                          className="form__group"
                          name="graceTimeIn"
                          type="number"
                          label="Grace Timein (minutes)"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            size="small"
                            min={1}
                            max={60}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          className="form__group"
                          name="shiftTimeOut"
                          label="Shift Timeout"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <TimePicker
                            use12Hours
                            format="h:mm a"
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Form.Item
                          className="form__group"
                          name="graceTimeOut"
                          label="Grace Timeout (minutes)"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber
                            size="small"
                            min={1}
                            max={60}
                            autoComplete="off"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          className="form__group"
                          name="userReportingManagerIds"
                          rules={[{ required: false }]}
                          label="Supervisior"
                        >
                          <Select
                            showSearch={true}
                            mode="multiple"
                            allowClear
                            filterOption={(input, option) =>
                              option.children[0] &&
                              option.children[0]
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {user.map((user) => (
                              <Option key={user.appUserId}>
                                {user.fullName} {`[${user.userName}]`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="form__group"
                          name="fingerprintId"
                          label="FingerPrintId"
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          className="form__group"
                          name="userReportingApprovalIds"
                          rules={[{ required: false }]}
                          label="Approval Supervisior"
                        >
                          <Select
                            showSearch={true}
                            mode="multiple"
                            allowClear
                            filterOption={(input, option) =>
                              option.children[0] &&
                              option.children[0]
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {user.map((user) => (
                              <Option key={user.appUserId}>
                                {user.fullName} {`[${user.userName}]`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          className="form__group"
                          name="fingerprintId"
                          label="FingerPrintId"
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          name="lockoutEnabled"
                          valuePropName="checked"
                        >
                          <Checkbox className="form__group">
                            <label>LockOut Enabled</label>
                          </Checkbox>
                        </Form.Item>

                        <Form.Item name="isLeftCompany" valuePropName="checked">
                          <Checkbox
                            checked={leftCompany}
                            onChange={(e) => {
                              setLeftCompany(e.target.checked);
                            }}
                            className="form__group"
                          >
                            <label>Left Company</label>
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          name="isLateReasonNeeded"
                          valuePropName="checked"
                        >
                          <Checkbox className="form__group">
                            <label>Late Reason Needed</label>
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name="isOutsideUser" valuePropName="checked">
                          <Checkbox
                            className="form__group"
                            onClick={() => {
                              setOpenOutBoxEmailField(!openOutBoxEmailField);
                            }}
                          >
                            <label>OutSide User</label>
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                    {!isEmpty(isUpdateCredentials) && (
                      <div className="drawer-form-group">
                        <div className="drawer-flex">
                          <Form.Item name="isExitForm" valuePropName="checked">
                            <Checkbox className="form__group">
                              <label>Clearance and Exit Interview</label>
                            </Checkbox>
                          </Form.Item>
                        </div>
                      </div>
                    )}
                    <Form.Item
                      name="isExcludedAttendanceView"
                      valuePropName="checked"
                    >
                      <Checkbox className="form__group">
                        <label>IsExcludedAttendanceView</label>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="isExcludedInternalMessage"
                      valuePropName="checked"
                    >
                      <Checkbox className="form__group">
                        <label>IsExcludedInternalMessage</label>
                      </Checkbox>
                    </Form.Item>
                    {!!openOutBoxEmailField && (
                      <Form.Item
                        className="form__group"
                        name="outsideEmailAddress"
                        label="Outside Email Address"
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    )}
                    <div className="drawer-form-group">
                      <Form.Item
                        className="form__group"
                        name="organizationLayerId"
                        rules={[{ required: true }]}
                        label="Organizational Listing"
                      >
                        <Select
                          showSearch={true}
                          allowClear
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {organizationListing?.map((organization) => (
                            <Option
                              value={organization.organizationLayerId}
                              key={organization.organizationLayerId}
                            >
                              {organization.organizationLayerName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="drawer-form-group">
                      <Form.Item
                        className="form__group"
                        label="Appointed Companies"
                        name="appoinmentCompanyId"
                      >
                        <Select
                          showSearch={true}
                          allowClear
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {sisterCompanyListing.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    {/* <div className="drawer-form-group">
                      <Form.Item
                        className="form__group"
                        name="userReportingManagerIds"
                        rules={[{ required: false }]}
                        label="Supervisior"
                      >
                        <Select
                          showSearch={true}
                          mode="multiple"
                          allowClear
                          filterOption={(input, option) =>
                            option.children[0] &&
                            option.children[0]
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {user.map((user) => (
                            <Option key={user.appUserId}>
                              {user.fullName} {`[${user.userName}]`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div> */}
                  </div>
                </TabPane>
                <TabPane
                  className="sister-company-tab"
                  key="2"
                  tab="Email Address"
                >
                  <div className="drawer-form-row">
                    <Form.List name={"employeeEmailDtos"}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, fieldIndex) => (
                            <React.Fragment key={field.key}>
                              <div className="drawer-group">
                                <div className="drawer-left">
                                  <div className="drawer-form-group">
                                    <Form.Item
                                      {...field}
                                      label="Select Sister Companys"
                                      className="form__group margin-select-placeholder"
                                      name={[field.name, "sisterCompanyId"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing Sister Company",
                                        },
                                      ]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "sisterCompanyId",
                                      ]}
                                      // rules={[{ required: true, message: 'Missing type' }]}
                                    >
                                      <Select placeholder="Select sister company">
                                        {sisterCompanyListing.map((item) => (
                                          <Option key={item.id} value={item.id}>
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                  <div className="drawer-form-group">
                                    <div className="drawer-flex">
                                      <Form.Item
                                        {...field}
                                        label="Email Address"
                                        className="form__group"
                                        name={[field.name, "emailAddress"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "emailAddressId",
                                        ]}
                                        rules={[
                                          {
                                            type: "email",
                                            message:
                                              "The input is not valid E-mail!",
                                          },
                                          {
                                            required: true,
                                            message:
                                              "Please input your E-mail!",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="Email Address"
                                          autoComplete="off"
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        {...field}
                                        label="Password"
                                        className="form__group"
                                        name={[field.name, "password"]}
                                        fieldKey={[field.fieldKey, "password"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing type",
                                          },
                                        ]}
                                      >
                                        <Input.Password
                                          placeholder="Password"
                                          autoComplete="off"
                                        />
                                      </Form.Item>
                                    </div>
                                  </div>
                                  <div
                                    className="drawer-form-group"
                                    style={{ marginTop: 10 }}
                                  >
                                    <div className="drawer-flex">
                                      <Form.Item
                                        {...field}
                                        className="form__group"
                                        name={[field.name, "isSmtp"]}
                                        fieldKey={[field.fieldKey, "isSmtp"]}
                                        initialValue={false}
                                        valuePropName="checked"
                                        rules={[
                                          {
                                            required: false,
                                          },
                                        ]}
                                      >
                                        <Checkbox className="form__group">
                                          <label>SMTP</label>
                                        </Checkbox>
                                      </Form.Item>
                                      <Form.Item
                                        {...field}
                                        className="form__group"
                                        name={[field.name, "isImap"]}
                                        fieldKey={[field.fieldKey, "isImap"]}
                                        initialValue={false}
                                        valuePropName="checked"
                                        rules={[
                                          {
                                            required: false,
                                          },
                                        ]}
                                      >
                                        <Checkbox className="form__group">
                                          <label>IMAP</label>
                                        </Checkbox>
                                      </Form.Item>
                                      {/* <Form.Item name="isLockedForCheckingInbox" valuePropName="checked">
                                          <Checkbox
                                            checked={isLocked}
                                            onChange={(e) => {
                                              setIsLocked(e.target.checked);
                                            }}
                                            className="form__group"
                                          >
                                            <label>IsLocked</label>
                                          </Checkbox>
                                        </Form.Item>   */}
                                      <Form.Item
                                        {...field}
                                        className="form__group"
                                        name={[field.name, "isDelete"]}
                                        fieldKey={[field.fieldKey, "isDelete"]}
                                        initialValue={false}
                                        valuePropName="checked"
                                        rules={[
                                          {
                                            required: false,
                                          },
                                        ]}
                                      ></Form.Item>
                                    </div>
                                  </div>
                                  <div
                                    className="drawer-form-group"
                                    style={{ marginTop: 10 }}
                                  >
                                    {fields.length === fieldIndex + 1 && (
                                      <Form.Item>
                                        <Button
                                          className="contact__buttons--userDrawer"
                                          onClick={() => add()}
                                        >
                                          <PlusCircleOutlined
                                            style={{ color: "green" }}
                                          />
                                          Add Email
                                        </Button>
                                      </Form.Item>
                                    )}
                                  </div>
                                </div>
                                <div className="drawer-right">
                                  <MinusCircleOutlined
                                    style={{ width: 45, marginTop: 3 }}
                                    className="contact__btn-danger"
                                    onClick={() => remove(field.name)}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                          {fields.length === 0 && (
                            <div className="drawer-form-group">
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  className="contact__buttons--userDrawer"
                                  onClick={() => add()}
                                  block
                                  icon={
                                    <PlusCircleOutlined
                                      style={{ color: "green" }}
                                    />
                                  }
                                >
                                  Add Email
                                </Button>
                              </Form.Item>
                            </div>
                          )}
                        </>
                      )}
                    </Form.List>
                  </div>
                </TabPane>
                <TabPane
                  className="extra-details-tab"
                  tab="Official Information"
                  key="3"
                >
                  <div className="drawer-form-row">
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          className="form__group"
                          // name="officialemail"
                          name="officialEmail"
                          label="Official Email"
                          rules={[{ required: true }]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="drawer-form-row">
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          label="Joining Date"
                          // name="hireDate"
                          className="form__group"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                          initialValue={moment()}
                        >
                          <DatePicker
                            defaultValue={moment()}
                            // disabledDate={(current) => {
                            //   return current && current < moment();
                            // }}
                            format="YYYY/MM/DD"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="drawer-form-row">
                    <div className="drawer-form-group">
                      <div className="drawer-flex">
                        <Form.Item
                          label="Termination Date"
                          // name="hireDate"
                          className="form__group"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                          initialValue={moment()}
                        >
                          <DatePicker
                            defaultValue={moment()}
                            // disabledDate={(current) => {
                            //   return current && current < moment();
                            // }}
                            format="YYYY/MM/DD"
                            autoComplete="off"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
              <div className="model-footer">
                <Button
                  className="ft-delete-btn"
                  onClick={() => {
                    showConfirm();
                  }}
                  danger
                >
                  {`${!isEmpty(isUpdateCredentials) ? "Delete" : ""}`}
                </Button>
                <Button onClick={onAddUserClose}>
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={() => {
                    onSubmit(isUpdateCredentials ? "update" : "add");
                  }}
                  type="primary"
                  loading={userRegisterationLoading}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AddUserDrawer;
