import  { FINGER_PRINT_TYPES } from '../../types';
import { init, success, finish, error } from 'services/common';
import { message } from 'antd';
import { FingerPrintService } from "./api"

const fingerPrintService = new FingerPrintService();

export const fingerPrintAdd = (body) => {
    return async(dispatch) => {
        dispatch(init(FINGER_PRINT_TYPES.CREATE_FINGER_PRINT));
        const resp = await fingerPrintService.addFingerPrint(body);
        dispatch(finish(FINGER_PRINT_TYPES.CREATE_FINGER_PRINT));
        if(resp.isSuccess) {
            dispatch(success(FINGER_PRINT_TYPES.CREATE_FINGER_PRINT, resp.data))
            message.success("Data pull successfully")
        } else if(!resp.isSuccess) {
            dispatch(error(resp.errorMessage))
        }
        
    }
} 