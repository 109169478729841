import { FINGER_PRINT_TYPES } from "services/types";

const INITIAL_STATE = {
    fingerPrintAddResponse: null,
    fingerPrintAddLoading: false,
}

export default function fingerPrintReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FINGER_PRINT_TYPES.CREATE_FINGER_PRINT_INIT: 
            return {
                ...state,
                fingerPrintAddResponse: null,
                fingerPrintAddLoading: true,
            }

        case FINGER_PRINT_TYPES.CREATE_FINGER_PRINT_SUCCESS:
            return {
                ...state,
                fingerPrintAddResponse: action.payload,
                fingerPrintAddLoading: false,
            }

        case FINGER_PRINT_TYPES.CREATE_FINGER_PRINT_FINISH:
            return {
                ...state,
                fingerPrintAddLoading: false,
            }

        default:
            return {
                ...state
            }
        
    }
}