import CEAD from "views/CEAD/tabs/contractStage";
import { CEAD_TYPES } from "../../types";

const INITIAL_STATE = {
  tenderCeadTransferLoading: false,
  tenderCeadList: [],
  tenderCeadListLoading: false,
  tenderCeadAccountViewList: [],
  tenderCeadAccountViewListLoading: "",
  tenderCeadPrincipleInvoiceDetailData: {},
  tenderCeadAddUpdateClosureDetails: {},
  tenderCeadWeeklyReportDetailsListing: [],
  tenderCeadWeeklyContractReportDetailsListing: [],
  tenderCeadAddUpdateClosureSuccess: false,
  ceadTenderSummaryResponse: [],
  ceadTenderSummaryLoading: false,
  ceadWeeklyReportMailResponse: [],
  ceadWeeklyReportMailLoading: false,
};

let updatedSingleTenderCeadData = {};

export default function ceadReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //CeadTenderListing Detail

    case CEAD_TYPES.GET_TENDER_CEAD_LIST_INIT:
      return {
        ...state,
        tenderCeadListLoading: true,
      };

    case CEAD_TYPES.GET_TENDER_CEAD_LIST_SUCCESS:
      return {
        ...state,
        tenderCeadList: action.payload,
        tenderCeadListLoading: false,
      };

    case CEAD_TYPES.GET_TENDER_CEAD_LIST_FINISH:
      return {
        ...state,
        tenderCeadListLoading: false,
      };

    //AccountViewList Detail

    case CEAD_TYPES.GET_TENDER_CEAD_ACCOUNT_VIEW_LIST_INIT:
      return {
        // ...state,
        tenderCeadAccountViewListLoading: true,
      };

    case CEAD_TYPES.GET_TENDER_CEAD_ACCOUNT_VIEW_LIST_SUCCESS:
      return {
        ...state,
        tenderCeadAccountViewList: action.payload,
        tenderCeadAccountViewListLoading: true,
      };

    case CEAD_TYPES.GET_TENDER_CEAD_ACCOUNT_VIEW_LIST_FINISH:
      return {
        ...state,
        tenderCeadAccountViewListLoading: false,
      };

    //singleCeadTender Detail

    case CEAD_TYPES.GET_SINGLE_TENDER_CEAD_LIST_INIT:
      return {
        ...state,
        singleTenderCeadLoading: true,
      };

    case CEAD_TYPES.GET_SINGLE_TENDER_CEAD_LIST_SUCCESS:
      return {
        ...state,
        singleTenderCeadData: action.payload,
        singleTenderCeadLoading: true,
      };

    case CEAD_TYPES.GET_SINGLE_TENDER_CEAD_LIST_FINISH:
      return {
        ...state,
        singleTenderCeadLoading: false,
      };

    //

    case CEAD_TYPES.ADD_UPDATE_TENDER_CEAD_INIT:
      return {
        ...state,
        // tenderCeadList: [],
        tenderCeadListLoading: true,
      };

    case CEAD_TYPES.ADD_UPDATE_TENDER_CEAD_SUCCESS:
      return {
        ...state,
        tenderCeadList: action.payload,
        tenderCeadListLoading: true,
      };

    case CEAD_TYPES.ADD_UPDATE_TENDER_CEAD_FINISH:
      return {
        ...state,
        tenderCeadListLoading: false,
      };

    //bid bond,peformance bond

    case CEAD_TYPES.ADD_UPDATE_BID_BOUND_INIT:
      return {
        ...state,
        tenderCeadBidBoundLoading: true,
      };

    case CEAD_TYPES.ADD_UPDATE_BID_BOUND_SUCCESS:
      let updateBondData = [];

      if (action.record === "bidType=0") {
        // updateBondData = [
        //   action.payload,
        //   ...state?.singleTenderCeadData?.biD_BOND_BidInformation,
        // ];
        updateBondData = action.payload;
        updatedSingleTenderCeadData = {
          ...state?.singleTenderCeadData,
          biD_BOND_BidInformation: updateBondData,
        };
      }

      if (action.record === "bidType=1") {
        // updateBondData = [
        //   action.payload,
        //   ...state?.singleTenderCeadData?.biD_BOND_BidInformation,
        // ];
        updateBondData = action.payload;
        updatedSingleTenderCeadData = {
          ...state?.singleTenderCeadData,
          performancE_BOND_BidInformation: updateBondData,
        };
      }

      if (action.record === "bidType=3") {
        updateBondData = [
          action.payload,
          ...state?.singleTenderCeadData?.seconD_LETTEROFCREDIT,
        ];
        updatedSingleTenderCeadData = {
          ...state?.singleTenderCeadData,
          seconD_LETTEROFCREDIT: updateBondData,
        };
      }

      if (action.record === "bidType=4") {
        updateBondData = [
          action.payload,
          ...state?.singleTenderCeadData?.second_PerformanceBond,
        ];
        updatedSingleTenderCeadData = {
          ...state?.singleTenderCeadData,
          second_PerformanceBond: updateBondData,
        };
      }

      if (action.record === "bidType=5") {
        updateBondData = [
          action.payload,
          ...state?.singleTenderCeadData?.second_PaymentGuarantee,
        ];
        updatedSingleTenderCeadData = {
          ...state?.singleTenderCeadData,
          second_PaymentGuarantee: updateBondData,
        };
      }
      return {
        ...state,
        singleTenderCeadData: updatedSingleTenderCeadData,
        tenderCeadBidBoundLoading: false,
      };

    case CEAD_TYPES.ADD_UPDATE_BID_BOUND_FINISH:
      return {
        ...state,
        tenderCeadBidBoundLoading: false,
      };

    //invoice details principle payment
    case CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST_INIT:
      return {
        ...state,
        tenderCeadPrincipleInvoiceDetailsLoading: true,
      };

    case CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        tenderCeadPrincipleInvoiceDetailData: action.payload,
        tenderCeadPrincipleInvoiceDetailsLoading: true,
      };

    case CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST_FINISH:
      return {
        ...state,
        tenderCeadPrincipleInvoiceDetailsLoading: false,
      };

    //cead compose email details
    case CEAD_TYPES.GET_CEAD_COMPOSE_EMAIL_DETAIL_INIT:
      return {
        ...state,
        tenderCeadComposeEmailDetailsLoading: true,
      };

    case CEAD_TYPES.GET_CEAD_COMPOSE_EMAIL_DETAIL_SUCCESS:
      return {
        ...state,
        tenderCeadComposeEmailDetailsData: action.payload,
        tenderCeadComposeEmailDetailsLoading: true,
      };

    case CEAD_TYPES.GET_CEAD_COMPOSE_EMAIL_DETAIL_FINISH:
      return {
        ...state,
        tenderCeadComposeEmailDetailsLoading: false,
      };

    //addupdate account specific financial information
    case CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION_INIT:
      return {
        ...state,
        tenderCeadAccSpecificDetailsLoading: true,
      };

    case CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION_SUCCESS:
      let updatedTenderCEADPaymentReceivedDtos = [];
      let updatedTenderPaymentReceivedPrincipalInformationDtos = [];
      let objReceiveDtos = {};
      // console.log("ADded", action, state?.singleTenderCeadData);
      if (action.payload.tenderPaymentReceivedInformationDtos) {
        objReceiveDtos = {
          ...action.payload,
          ...action.payload.tenderPaymentReceivedInformationDtos[0],
        };
        updatedTenderCEADPaymentReceivedDtos = [
          ...state.singleTenderCeadData.tenderCEADPaymentReceivedDtos,
          objReceiveDtos,
        ];
      }

      if (action.payload.tenderPaymentReceivedPrincipalInformationDtos) {
        objReceiveDtos = {
          ...action.payload,
          ...action.payload.tenderPaymentReceivedPrincipalInformationDtos[0],
        };
        updatedTenderPaymentReceivedPrincipalInformationDtos = [
          ...state.singleTenderCeadData.tenderCEADPaymentReceivedPrincipalDto,
          objReceiveDtos,
        ];
      }

      if (action.details === "CEADFinancialType=0") {
        updatedSingleTenderCeadData = {
          ...state?.singleTenderCeadData,
          foreign_ContractFinancialsInformationDto: action.payload,
        };
      } else if (action.payload.tenderPaymentReceivedInformationDtos) {
        updatedSingleTenderCeadData = {
          ...state?.singleTenderCeadData,
          tenderCEADPaymentReceivedDtos: updatedTenderCEADPaymentReceivedDtos,
        };
      } else if (action.payload.tenderPaymentReceivedPrincipalInformationDtos) {
        updatedSingleTenderCeadData = {
          ...state?.singleTenderCeadData,
          tenderCEADPaymentReceivedPrincipalDto:
            updatedTenderPaymentReceivedPrincipalInformationDtos,
        };
      } else {
        updatedSingleTenderCeadData = {
          ...state?.singleTenderCeadData,
          tenderCEADVariationDtos: [
            action.payload,
            ...state?.singleTenderCeadData?.tenderCEADVariationDtos,
          ],
        };
      }

      return {
        ...state,
        singleTenderCeadData: updatedSingleTenderCeadData,
        tenderCeadAccSpecificDetailsLoading: false,
      };

    case CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION_FINISH:
      return {
        ...state,
        tenderCeadAccSpecificDetailsLoading: false,
      };

    //add update funding information
    case CEAD_TYPES.ADD_UPDATE_FUNDING_INIT:
      return {
        ...state,
        tenderCeadFundingLoading: true,
      };

    case CEAD_TYPES.ADD_UPDATE_FUNDING_SUCCESS:
      updatedSingleTenderCeadData = {
        ...state?.singleTenderCeadData,
        tenderCEADFundingAgencys: action.payload,
      };

      return {
        ...state,
        singleTenderCeadData: updatedSingleTenderCeadData,
        tenderCeadFundingLoading: false,
      };

    case CEAD_TYPES.ADD_UPDATE_FUNDING_FINISH:
      return {
        ...state,
        tenderCeadFundingLoading: false,
      };

    //get payment funding information
    case CEAD_TYPES.GET_PAYMENT_RECEIVED_LOG_INIT:
      return {
        ...state,
        tenderCeadPaymentReceivedLogLoading: true,
      };

    case CEAD_TYPES.GET_PAYMENT_RECEIVED_LOG_SUCCESS:
      updatedSingleTenderCeadData = {
        ...state?.singleTenderCeadData,
        tenderCeadPaymentReceivedLogDetails: action.payload,
      };

      return {
        ...state,
        singleTenderCeadData: updatedSingleTenderCeadData,
        tenderCeadPaymentReceivedLogLoading: false,
      };

    case CEAD_TYPES.GET_PAYMENT_RECEIVED_LOG_FINISH:
      return {
        ...state,
        tenderCeadPaymentReceivedLogLoading: false,
      };

    //CONTRACT_ADD_UPDATE_CLOSURE
    case CEAD_TYPES.CONTRACT_ADD_UPDATE_CLOSURE_INIT:
      return {
        ...state,
        tenderCeadAddUpdateClosureLoading: true,
        tenderCeadAddUpdateClosureSuccess: false,
      };

    case CEAD_TYPES.CONTRACT_ADD_UPDATE_CLOSURE_SUCCESS:
      updatedSingleTenderCeadData = {
        ...state?.singleTenderCeadData,
        tenderCEADContractClosureDto: action.payload,
      };

      return {
        ...state,
        singleTenderCeadData: updatedSingleTenderCeadData,
        tenderCeadAddUpdateClosureLoading: false,
        tenderCeadAddUpdateClosureSuccess: true,
        tenderCeadAddUpdateClosureDetails: action.payload,
      };

    case CEAD_TYPES.CONTRACT_ADD_UPDATE_CLOSURE_FINISH:
      return {
        ...state,
        tenderCeadAddUpdateClosureLoading: false,
        tenderCeadAddUpdateClosureSuccess: false,
      };

    //ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER
    case CEAD_TYPES.ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER_INIT:
      return {
        ...state,
        tenderCeadTransferLoading: true,
        isTransferToCead: "",
      };

    case CEAD_TYPES.ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER_SUCCESS:
      return {
        ...state,
        singleTenderCeadData: action.payload,
        tenderCeadTransferLoading: false,
        isTransferToCead: "success",
      };

    case CEAD_TYPES.ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER_FINISH:
      return {
        ...state,
        tenderCeadTransferLoading: false,
        // isTransferToCead: "",
      };

    //UPDATE_QA
    case CEAD_TYPES.UPDATE_QA_INIT:
      return {
        ...state,
        QALoading: true,
      };

    case CEAD_TYPES.UPDATE_QA_SUCCESS:
      return {
        ...state,
        singleTenderCeadData: {
          ...state.singleTenderCeadData,
          tenderCEADQuestionAnswerDto: action.payload,
        },
        QALoading: false,
      };

    case CEAD_TYPES.UPDATE_QA_FINISH:
      return {
        ...state,
        QALoading: false,
      };

    //WEEKLY_REPORT_GET_DETAIL_LIST
    case CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_LIST_INIT:
      return {
        ...state,
        tenderCeadWeeklyReportDetailsListLoading: true,
      };

    case CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        tenderCeadWeeklyReportDetailsListing: action.payload.items,
        tenderCeadWeeklyReportDetailsListLoading: false,
      };

    case CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_LIST_FINISH:
      return {
        ...state,
        tenderCeadWeeklyReportDetailsListLoading: false,
      };

    //WEEKLY_REPORT_GET_DETAIL_SINGLE
    case CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_INIT:
      return {
        ...state,
        tenderCeadWeeklyReportDetailLoading: true,
      };

    case CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_SUCCESS:
      return {
        ...state,
        tenderCeadWeeklyReportDetail: action.payload,
        tenderCeadWeeklyReportDetailLoading: false,
      };

    case CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_FINISH:
      return {
        ...state,
        tenderCeadWeeklyReportDetailLoading: false,
      };

    //WEEKLY_REPORT_ADD_UPDATE
    case CEAD_TYPES.ADD_UPDATE_WEEKLY_REPORT_INIT:
      return {
        ...state,
        tenderCeadWeeklyReportAddUpdateLoading: true,
      };

    case CEAD_TYPES.ADD_UPDATE_WEEKLY_REPORT_SUCCESS:
      let updatedWklyRprtList = [];
      if (action.record === "Updated") {
        state.tenderCeadWeeklyReportDetailsListing?.map((x) => {
          if (x?.id == action.payload.id) {
            updatedWklyRprtList = [...updatedWklyRprtList, action.payload];
          } else {
            updatedWklyRprtList = [...updatedWklyRprtList, x];
          }
        });
      } else {
        updatedWklyRprtList = [
          action.payload,
          ...state.tenderCeadWeeklyReportDetailsListing,
        ];
      }

      return {
        ...state,
        tenderCeadWeeklyReportDetailsListing: updatedWklyRprtList,
        tenderCeadWeeklyReportAddUpdateLoading: false,
        tenderCeadWeeklyReportDetail: action.payload,
      };

    case CEAD_TYPES.ADD_UPDATE_WEEKLY_REPORT_FINISH:
      console.log("ErrorFinish", action);
      return {
        ...state,
        // tenderCeadWeeklyReportAddUpdateLoading: false,
        tenderCeadWeeklyReportAddUpdateSuccess: false,
      };

    //CONTRACT_WEEKLY_REPORT_ADD_UPDATE
    case CEAD_TYPES.ADD_UPDATE_WEEKLY_CONTRACT_REPORT_INIT:
      return {
        ...state,
        tenderCeadWeeklyContractReportAddUpdateLoading: true,
      };

    case CEAD_TYPES.ADD_UPDATE_WEEKLY_CONTRACT_REPORT_SUCCESS:
      console.log("action", action);
      let updatedWklyCntrcRprtList = [];
      if (action.record === "Updated") {
        state.tenderCeadWeeklyContractReportDetailsListing?.map((x) => {
          if (x?.id === action.payload.id) {
            updatedWklyCntrcRprtList = [
              ...updatedWklyCntrcRprtList,
              action.payload,
            ];
          } else {
            updatedWklyCntrcRprtList = [...updatedWklyCntrcRprtList, x];
          }
        });
      } else {
        updatedWklyCntrcRprtList = [
          action.payload,
          ...state.tenderCeadWeeklyContractReportDetailsListing,
        ];
      }

      return {
        ...state,
        tenderCeadWeeklyContractReportDetailsListing: updatedWklyCntrcRprtList,
        tenderCeadWeeklyContractReportAddUpdateLoading: false,
        tenderCeadContractWeeklyReportSingleDetail: action.payload,
        // tenderCeadWeeklyContractReportDetail: action.payload,
      };

    case CEAD_TYPES.ADD_UPDATE_WEEKLY_CONTRACT_REPORT_FINISH:
      return {
        ...state,
        tenderCeadWeeklyContractReportAddUpdateLoading: false,
      };

    //CONTRACT_WEEKLY_REPORT_GET_DETAIL_LISTING
    case CEAD_TYPES.WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST_INIT:
      return {
        ...state,
        tenderCeadWeeklyContractReportGetListingLoading: true,
      };

    case CEAD_TYPES.WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        tenderCeadWeeklyContractReportDetailsListing: action.payload.items,
        tenderCeadWeeklyContractReportGetListingLoading: false,
      };

    case CEAD_TYPES.WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST_FINISH:
      return {
        ...state,
        tenderCeadWeeklyContractReportGetListingLoading: false,
      };

    //CONTRACT_WEEKLY_REPORT_GET_DETAIL_SINGLE
    case CEAD_TYPES.CONTRACT_WEEKLY_REPORT_GET_DETAIL_INIT:
      return {
        ...state,
        tenderCeadContractWeeklyReportSingleDetailLoading: true,
      };

    case CEAD_TYPES.CONTRACT_WEEKLY_REPORT_GET_DETAIL_SUCCESS:
      return {
        ...state,
        tenderCeadContractWeeklyReportSingleDetail: action.payload,
        tenderCeadContractWeeklyReportSingleDetailLoading: false,
      };

    case CEAD_TYPES.CONTRACT_WEEKLY_REPORT_GET_DETAIL_FINISH:
      return {
        ...state,
        tenderCeadContractWeeklyReportSingleDetailLoading: false,
      };

    //invoice generation
    case CEAD_TYPES.INVOICE_GENERATED_INIT:
      return {
        ...state,
        invoiceLoading: true,
      };

    case CEAD_TYPES.INVOICE_GENERATED_SUCCESS:
      console.log("logs", action, state);
      // paymentReceivedPrincipalInformationId
      const updateData =
        state.singleTenderCeadData.tenderCEADPaymentReceivedPrincipalDto.map(
          (x) => {
            if (x.id === action.payload.paymentReceivedPrincipalInformationId) {
              return {
                ...x,
                isInvoiceGenerated: true,
                tenderPaymentReceivedPrincipalInformationDtos: [
                  {
                    ...x.tenderPaymentReceivedPrincipalInformationDtos[0],
                    uploadedFileDtos: action.payload.uploadedFileDtos,
                    isInvoiceGenerated: true,
                  },
                ],
              };
            } else {
              return x;
            }
          }
        );

      console.log("updateDAta", updateData);

      return {
        ...state,
        singleTenderCeadData: {
          ...state.singleTenderCeadData,
          tenderCEADPaymentReceivedPrincipalDto: updateData,
        },
        invoiceLoading: false,
      };

    case CEAD_TYPES.INVOICE_GENERATED_FINISH:
      return {
        ...state,
        invoiceLoading: false,
      };

    case CEAD_TYPES.GET_CEAD_TENDER_SUMMARY_INIT:
      return {
        ...state,
        ceadTenderSummaryResponse: [],
        ceadTenderSummaryLoading: true,
      };

    case CEAD_TYPES.GET_CEAD_TENDER_SUMMARY_SUCCESS:
      return {
        ...state,
        ceadTenderSummaryResponse: action.payload,
        ceadTenderSummaryLoading: false,
      };

    case CEAD_TYPES.GET_CEAD_TENDER_SUMMARY_FINISH:
      return {
        ...state,
        ceadTenderSummaryLoading: false,
      };

    // for cead weekly mail generated
    case CEAD_TYPES.CEAD_WEEKLY_REPORT_MAIL_INIT:
      return {
        ...state,
        ceadWeeklyReportMailResponse: [],
        ceadWeeklyReportMailLoading: true,
      };

    case CEAD_TYPES.CEAD_WEEKLY_REPORT_MAIL_SUCCESS:
      return {
        ...state,
        ceadWeeklyReportMailResponse: action.payload,
        ceadWeeklyReportMailLoading: false,
      };

    case CEAD_TYPES.CEAD_WEEKLY_REPORT_MAIL_FINISH:
      return {
        ...state,
        ceadWeeklyReportMailLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
