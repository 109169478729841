import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

// antd
import { Select, Popover, Switch, Pagination, Row, Col, Divider } from 'antd';
import { MoreOutlined, CheckOutlined } from '@ant-design/icons';

// constants
import { LogoImage } from 'utils/imageConstants';

const { Option } = Select;

const GoalsList = (props) => {
	const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);
	const { data, pageSize, goalEventsLoading, organizerEventLabelListing, currentPage, setCurrentPage } = props;

	const [selectedStatusId, setSelectedStatusId] = useState('');
	const [hideCompleted, setHideCompleted] = useState('');
	const [orderBy, setOrderBy] = useState('');
	const [sortType, setSortType] = useState('desc');
	const [viewList, setViewList] = useState([]);

	useEffect(() => {
		if (!isEmpty(data.items)) {
			let lists = [...popoverMenuVisible];
			let viewlists = [...viewList];
			data.items.map((item, i) => {
				lists[i] = false;
				viewlists[i] = 'More';
			});
			setPopoverMenuVisible(lists);
			setViewList(viewlists);
		}
	}, [data.items]);

	const handleVisibleChange = (index) => {
		let lists = [...popoverMenuVisible];
		lists[index] = !lists[index];
		setPopoverMenuVisible(lists);
	};

	const getActionContent = (item, index) => {
		return (
			<div className="guest-actions">
				<div
					className="guest-act"
					onClick={() => {
						props.editGoalList(item);
						handleVisibleChange(index);
					}}
				>
					Edit
				</div>
				<div className="guest-act">Delete</div>
			</div>
		);
	};

	const getFilterAction = () => {
		return (
			<div className="guest-actions filterTooltips">
				<a
					className="guest-act"
					onClick={() => {
						setOrderBy('');
						setCurrentPage(1);
						props.getData(pageSize, 0, selectedStatusId, hideCompleted, '', sortType);
					}}
				>
					{orderBy === '' && <CheckOutlined />} Created Date
				</a>
				<a
					className="guest-act"
					onClick={() => {
						setOrderBy('priority');
						setCurrentPage(1);
						props.getData(pageSize, 0, selectedStatusId, hideCompleted, 'priority', sortType);
					}}
				>
					{orderBy === 'priority' && <CheckOutlined />} Priority
				</a>
				<a
					className="guest-act"
					onClick={() => {
						setOrderBy('duedate');
						setCurrentPage(1);
						props.getData(pageSize, 0, selectedStatusId, hideCompleted, 'duedate', sortType);
					}}
				>
					{orderBy === 'duedate' && <CheckOutlined />} Due Date
				</a>
				<a
					className="guest-act"
					onClick={() => {
						setOrderBy('title');
						setCurrentPage(1);
						props.getData(pageSize, 0, selectedStatusId, hideCompleted, 'title', sortType);
					}}
				>
					{orderBy === 'title' && <CheckOutlined />} Goal Title
				</a>
				<Divider />
				<a
					className="guest-act"
					onClick={() => {
						setSortType('asc');
						setCurrentPage(1);
						props.getData(pageSize, 0, selectedStatusId, hideCompleted, orderBy, 'asc');
					}}
				>
					{sortType === 'asc' && <CheckOutlined />} Ascending
				</a>
				<a
					className="guest-act"
					onClick={() => {
						setSortType('desc');
						setCurrentPage(1);
						props.getData(pageSize, 0, selectedStatusId, hideCompleted, orderBy, 'desc');
					}}
				>
					{sortType === 'desc' && <CheckOutlined />} Descending
				</a>
			</div>
		);
	};

	const onChange = (checked) => {
		setHideCompleted(!checked);
		setCurrentPage(1);
		props.getData(pageSize, 0, selectedStatusId, !checked, orderBy, sortType);
	};

	const onChangePage = (page) => {
		setCurrentPage(page);
		props.getData(pageSize, (page - 1) * pageSize, selectedStatusId, hideCompleted, orderBy, sortType);
	};

	const getHalfDetails = (details) => {
		let halfDetails = details.split('').splice(0, 80).join('');
		return halfDetails;
	};

	// let detailsSummary =
	return (
		<div className="organizer__list">
			<h4 className="organizer__list--header">Goal List</h4>
			<Row gutter={[8, 8]}>
				<Col sm={12}>
					<Select
						placeholder="Select status"
						onChange={(value) => {
							setSelectedStatusId(value);
							setCurrentPage(1);
							props.getData(pageSize, 0, value, hideCompleted, orderBy, sortType);
						}}
					>
						<Option value={''}>All Status</Option>
						{!isEmpty(organizerEventLabelListing) &&
							organizerEventLabelListing.map((status, index) => {
								return (
									<Option value={status.id} key={index}>
										{status.name}
									</Option>
								);
							})}
					</Select>
				</Col>
				<Col sm={10} className="switch-div">
					<Switch onChange={onChange} /> Hide Completed
				</Col>
				<Col sm={2} className="m-auto">
					<Popover
						content={getFilterAction}
						title="Filter By"
						trigger="click"
						placement="bottomRight"
					>
						<MoreOutlined style={{ fontSize: 18 }} />
					</Popover>
				</Col>
				<div className="organizer__list--body body1">
					{!isEmpty(data.items) ? (
						data.items.map((item, index) => {
							const priorityColor = item.organizerEventPriorityDto?.colorHexValue;
							const lableColor = item.organizerEventLabelDto?.colorHexValue;
							return (
								<div className="gray__form" key={index}>
									<div className="organizer__card--status">
										<span className="badge" style={{ color: '#fff', backgroundColor: priorityColor, border: 0 }}>
											{item.organizerEventPriorityDto?.name}
										</span>
										<span className={`badge badge-outline--${item.isActive ? 'public' : 'inactive'}`}>{item.isActive ? 'Active' : 'Inactive'}</span>
										{item.isComplete && <span className="badge-outline--active badge">Completed</span>}
										<span className="private badge" style={{ color: '#fff', backgroundColor: lableColor, border: 0 }}>
											{item.organizerEventLabelDto?.name}
										</span>
									</div>
									<div className="organizer__card--body">
										<div className={`card-title ${item.isComplete && 'marksAsComplete'}`}>{item.title}</div>
										<div className="card-notes">
											{viewList[index] === 'More' ? (
												<>
													{getHalfDetails(item.details)}
													<span
														onClick={() => {
															let viewLists = [...viewList];
															viewLists[index] = 'Less';
															setViewList(viewLists);
														}}
													>
														{item.details.split('').length > 80 && `... View ${viewList[index]}`}
													</span>
												</>
											) : (
												<>
													{item.details}
													<span
														onClick={() => {
															let viewLists = [...viewList];
															viewLists[index] = 'More';
															setViewList(viewLists);
														}}
													>
														{' '}
														View {viewList[index]}
													</span>
												</>
											)}
										</div>
										<div className="card-date">{moment(item.dueDate).format('DD-MMM-YYYY')}</div>
										<Popover
											content={() => getActionContent(item, index)}
											title="Action"
											trigger="click"
											className="tooltips"
											visible={popoverMenuVisible[index]}
											onVisibleChange={() => handleVisibleChange(index)}
											placement="bottomRight"
										>
											<MoreOutlined style={{ fontSize: 18 }} />
										</Popover>
									</div>
								</div>
							);
						})
					) : goalEventsLoading ? (
						<div style={{ height: 50, width: 50 }}>
							<img src={LogoImage} height="100%" />
						</div>
					) : (
						'No data'
					)}
				</div>
				{!isEmpty(data.items) && <Pagination current={currentPage} total={data.totalCount} showSizeChanger={false} pageSize={pageSize} onChange={onChangePage} />}
			</Row>
		</div>
	);
};

export default GoalsList;
