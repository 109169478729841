import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Form, Input } from "antd";
// import { sourcePagination } from "services/redux/source/action";
const PrimaryReferenceAddUpdateTable = ({  }) => {
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
    },
    {
      title: "Source",
      dataIndex: "",
      key: "",
    },
  ];

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setdata] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;
 
  return (
    <>
      <Table
        className="organizer__tables"
        columns={columns}
        dataSource={sourceData}
        pagination={{
          total: totalCount,
          pageSize: pageSize,
        }}
      />
    </>
  );
};

export default PrimaryReferenceAddUpdateTable;
