import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// import AddUserDrawer from "./AddUserDrawer";
import ModalityAddUpdateTable from './ModalityTable';
import { employeeProfileDetail } from 'services/redux/profile/profile.action';
import ModalityAddUpdateDrawer from './ModalityDrawer';
import { ProjectService } from '../../../../services/redux/Reference/Project/api';

const Modality = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const onReset = () => {
		form.resetFields();
	};

	const [addUpdateModalityDrawerVisibility, setaddUpdateModalityDrawerVisibility] = useState(false);
	const [isUpdateModality, setIsUpdateModality] = useState(false);

	const showAddUpdateModalityDrawer = (e, record) => {
		setaddUpdateModalityDrawerVisibility(false);
		if (record) {
			setIsUpdateModality(record);
		} else {
			setIsUpdateModality(false);
		}
		setaddUpdateModalityDrawerVisibility(true);
	};

	const onAddUpdateModalityClose = () => {
		setIsUpdateModality('');
		setaddUpdateModalityDrawerVisibility(false);
	};

	return (
		<div className="layout">
			<div className="organizer events">
				<div className="all-header-wrapper">
					<div className="meeting__flex">
						<h3 className="header__all">Modality List</h3>
						<div className="organizerHoliday__middle--wrap">
								<Button
									className="btn-primary"
									onClick={() => {
										showAddUpdateModalityDrawer();
									}}
								>
									<PlusOutlined /> New Modality
								</Button>
						</div>
					</div>
				</div>

				<ModalityAddUpdateTable showAddUpdateModalityDrawer={showAddUpdateModalityDrawer} />
				<ModalityAddUpdateDrawer drawerVisibility={addUpdateModalityDrawerVisibility} onAddUpdateModalityClose={onAddUpdateModalityClose} isUpdateModality={isUpdateModality} />
			</div>
		</div>
	);
};

export default Modality;
