import moment from "moment";
import OnGoingData from "./onGoing";
import ArchieveData from "./archieve";
import SideHeader from "./header";

function SideBody({
  applicationAllDataListing,
  applicationAllDataLoading,
  setJobApplcationId,
  setOpenDrawerRecruitment,
  activeIndex,
  setActiveIndex,
  setSearchKeyword,
  searchKeyword,
}) {
  const today = moment();
  const onGoingApplicationData = applicationAllDataListing?.items
    ?.filter((x) => {
      const closingDate = moment(x?.closingDate);
      return closingDate >= today;
    })
    .sort((a, b) => moment(a.closingDate) - moment(b.closingDate));
  const archieveApplicationData = applicationAllDataListing?.items
    ?.filter((x) => {
      const closingDate = moment(x?.closingDate);
      return closingDate < today;
    })
    .sort((a, b) => moment(b.closingDate) - moment(a.closingDate));

  return (
    <>
      <SideHeader
        setOpenDrawerRecruitment={setOpenDrawerRecruitment}
        setSearchKeyword={setSearchKeyword}
      />
      <OnGoingData
        onGoingApplicationData={onGoingApplicationData}
        setJobApplcationId={setJobApplcationId}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        applicationAllDataLoading={applicationAllDataLoading}
        searchKeyword={searchKeyword}
      />

      <ArchieveData
        archieveApplicationData={archieveApplicationData}
        setJobApplcationId={setJobApplcationId}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        applicationAllDataLoading={applicationAllDataLoading}
      />
    </>
  );
}

export default SideBody;
