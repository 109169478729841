import { Button, Col, Row, Skeleton, message } from "antd";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import {
  attendancePagination,
  attendancePaginationUserLogin,
  getAllUsers,
  getMonthlyAttendance2,
} from "services/remanentCalls";
import { isEmpty, startCase, toLower } from "lodash-es";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { englishToNepaliDataConverter } from "services/redux/Home/action";
import { noticeBoardListing } from "services/redux/absent/absent.action";
import { getFinancialDashboardListing } from "services/redux/admin/FiscalYear/action";
import { getApplicationConfiguration } from "services/redux/common/applicationConfiguration/action";
import {
  emailPaginationCount,
  getErrorPagination,
} from "services/redux/email/mailMessageCenter/action";
import { todoShowZoom } from "services/redux/organizer/settings/action";
import { getTodoEventsPagination } from "services/redux/organizer/todoLists/action";
import { getProcurementCounter } from "services/redux/requistions/procurement/action";
import storage from "utils/storage";
import Layout from "./DashboardComponents/Layout";
import Attendance from "./DashboardComponents/MainDashboard/Attendance";
import Notification from "./DashboardComponents/MainDashboard/Notification";
import Other from "./DashboardComponents/MainDashboard/Other";
import Policies from "./DashboardComponents/MainDashboard/Policies";
import ViewBox from "./DashboardComponents/MainDashboard/ViewBox";
import ComplaintView from "./Modal/ComplainView";
import Tutorial from "./DashboardComponents/MainDashboard/Tutorial/index";
import NoticeBoardModal from "components/modals/dashboardModal/NoticeBoardModal";

const DraggableHome = forwardRef(({ navbarRef }, ref) => {
  const dispatch = useDispatch();
  const [attendancePaginationResponse, setAttendancePagination] = useState([]);
  const [absentPaginationResposne, setAbsentPaginationResponse] = useState([]);
  const [loader, setLoader] = useState(false);
  const [attendanceDetails, setAttendanceDetails] = useState();
  const [fingerDetails, setFingerDetails] = useState();
  const [isSoftware, setIsSoftware] = useState(false);
  const [
    attendancePaginationUserLoginDetail,
    setAttendancePaginationUserLoginDetail,
  ] = useState();
  const [meetingInvitees, setMeetingInvitees] = useState();
  const [attendenceTab, setAttendenceTab] = useState();
  const [filterSoftwareType, setFilterSoftwareType] = useState(false);
  const [filterFingerType, setFilterFingerType] = useState(false);
  const [shouldRenderRequisitionList, setShouldRenderRequisitionList] =
    useState(false);
  const [grantedPolices, setGrantedPolices] = useState({});
  const [toggleValue, setToggleValue] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [totalWorkingDays, setTotalWorkingDays] = useState(0);
  const [totalDays, setTotalDays] = useState(0);

  const [showBellNotificationLists, setShowBellNotificationLists] =
    useState(false);
  const [showAttendenceLists, setShowAttendenceLists] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showPoliciesPage, setShowPoliciesPage] = useState(false);
  const [noticeBoard, setNoticeBoard] = useState([]);
  const [showTutorial, setShowTutorial] = useState(false);

  const [showInformation, setShowInformation] = useState("");

  const [canExport, setCanexport] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [frommDate, setFrommDate] = useState();

  const [isTourOpen, setIsTourOpen] = useState(false);

  const [showLeaveDrawer, setShowLeaveDrawer] = useState(false);
  let localStorageId = storage.getItem("userId");

  const [userId, setUserId] = useState(localStorageId);
  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );
  const { procurementCounterResponse } = useSelector(
    (state) => state?.procurement
  );

  const { profileInfo } = useSelector((state) => state.profile);
  const { loginUserDto } = useSelector((state) => state.profile);
  const { allNoticeList } = useSelector((state) => state.absent);

  const { mailPaginationCount } = useSelector(
    (state) => state.mailMessageCenter
  );
  const { allBellNotifications, connection, bellCount } = useSelector(
    (state) => state.notificationsState
  );

  const { zoomData } = useSelector((state) => state.settingsData);
  const { nepaliDateResponse } = useSelector((state) => state.homeServices);

  const [newMonth, setNewMonth] = useState(
    nepaliDateResponse?.nepaliMonthInEnglish ?? 1
  );

  // const [newYear, setNewYear] = useState(
  //   nepaliDateResponse?.nepaliYearInEnglish ?? 2080
  // );
  const [newYear, setNewYear] = useState();

  const [employeeProfile, setEmployeeProfile] = useState("");
  const [userName, setUserName] = useState();

  const calendarRef = useRef();

  const fetchAttendacePagination = async (q) => {
    setLoader(true);
    const resp = await attendancePagination(q).catch((err) => {
      message.error("failure");
    });
    if (resp && resp.status === 200) {
      setLoader(false);
      setAttendancePagination(resp.data);
    }
  };

  const rolesToView = useMemo(
    () => [
      "admin",
      "LoanAccountant",
      "LoanApprover",
      "ManagerRepresentive",
      "ProcurementAccountant",
      "ProcurementApprover",
    ],
    []
  );

  const isAfter4PM = () => {
    const now = moment();
    const fourPM = moment().set("hour", 16).set("minute", 0).set("second", 0);
    return now.isAfter(fourPM);
  };

  const fetchAttendancePaginationByUserLogin = async (q) => {
    const response = await attendancePaginationUserLogin(q).catch((err) => {
      message.error("failure");
    });
    if (response && response.status === 200) {
      setAttendancePaginationUserLoginDetail(response.data);
    }
  };

  const fetchMonthlyAttendace = async (q) => {
    setLoader(true);
    let body = {
      ...q,
      IsFingerprint: isSoftware,
    };

    const resp = await getMonthlyAttendance2(body).catch((err) => {
      message.error("failure");
    });
    if (resp && resp.status === 200) {
      setLoader(false);
      setAbsentPaginationResponse(resp?.data?.attendanceUserList);
      setTotalWorkingDays(resp?.data?.totalWorkingDays);
      setTotalDays(
        resp?.data?.attendanceUserList?.[0]?.attendanceDetails?.length
      );
      setFrommDate(resp?.data?.fromDate);
    }
  };

  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {});

    if (resp && resp.status === 200) {
      let filterUser = resp.data?.filter((user) => {
        return user.appUserId !== localStorageId;
      });
      setMeetingInvitees(resp.data);
    }
  };

  useEffect(() => {
    setNewYear(nepaliDateResponse?.nepaliYearInEnglish ?? 2080);
  }, [nepaliDateResponse?.nepaliMonthInEnglish]);

  useEffect(() => {
    dispatch(getFinancialDashboardListing());
  }, []);

  useEffect(() => {
    dispatch(
      getTodoEventsPagination(
        `maxResultCount=100&skipCount=0&IsExcudeWatcher=true&IsToday=1&NormalOrPhoneOrVisit=0&NormalOrPhoneOrVisit=1&NormalOrPhoneOrVisit=2&isCompleted=0&SkipCount=0`
      )
    );

    dispatch(englishToNepaliDataConverter(moment().format("YYYY/MM/DD")));
    dispatch(getProcurementCounter());
    if (procurementCounterResponse?.leave?.total !== 0) {
    }
    dispatch(
      employeeProfileDetail(
        {
          AppUserId: localStorageId,
        },
        "loginUser"
      )
    );
  }, []);

  useEffect(() => {
    fetchAttendacePagination({ MaxResultCount: "400", SkipCount: "0" });
  }, []);

  useEffect(() => {
    if (!isEmpty(applicationConfiguration)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  const [calendarDate, setCalendarDate] = useState(moment());

  useEffect(() => {
    let appendUrl = {
      userId: userId,
      SearchDateFrom: moment(calendarDate)
        .startOf("month")
        .format("YYYY-MM-DD"),
      SearchDateTo: moment(calendarDate).endOf("month").format("YYYY-MM-DD"),
      // IsFingerprint: filterSoftwareType,
      IsFingerprint: filterFingerType,
    };
    if (appendUrl?.IsFingerprint === true) {
      // fetchAttendacePaginationByuser(appendUrl);
      fetchAttendancePaginationByUserLogin(appendUrl);
    } else {
      fetchAttendancePaginationByUserLogin(appendUrl);
      // fetchAttendacePaginationByuser(appendUrl);
    }
  }, [calendarDate, filterSoftwareType, filterFingerType, userId]);

  useEffect(() => {
    if (isMounted) {
      const query = {
        NepaliMonth: newMonth,
        NepaliYear: newYear,
      };
      fetchMonthlyAttendace(query);
    } else {
      setIsMounted(true);
    }
  }, [isSoftware]);

  useEffect(() => {
    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
  }, []);

  useEffect(() => {
    if (attendancePaginationUserLoginDetail) {
      //for fingerPrint
      let calendarDatas = attendancePaginationUserLoginDetail.map((event) => {
        let obj = {};
        var red = "#FF0000";
        var green = " #1cc0e8 ";

        if (event?.attendanceAbsentDetailsDto?.isHoliday === true) {
          return {
            ...obj,
            title: `Holiday \n
            ${
              event?.attendanceIn
                ? moment(event?.attendanceIn)?.format("hh:mm:ss a")
                : ""
            } 
            ${event?.attendanceIn ? "-" : ""}
            ${
              event?.attendanceIn
                ? moment(event?.attendanceOutAt)?.format("hh:mm:ss a")
                : ""
            } `.trim(),
            color: red,
            start: moment(event?.dateValue)?.format("YYYY-MM-DD"),
          };
        } else if (event?.attendanceAbsentDetailsDto?.isLeave === true) {
          return {
            ...obj,
            title: "Leave",
            color: green,
            start: moment(event?.dateValue)?.format("YYYY-MM-DD"),
          };
        } else if (event?.attendanceOutAt === null) {
          return {
            ...obj,
            title: moment(event?.attendanceIn)?.format("hh:mm:ss a"),
            start: moment(event.dateValue)?.format("YYYY-MM-DD"),
          };
        } else {
          return {
            ...obj,
            title:
              moment(event?.attendanceIn)?.format("hh:mm:ss a") +
              "-" +
              moment(event?.attendanceOutAt)?.format("hh:mm:ss a"),
            start: moment(event?.dateValue)?.format("YYYY-MM-DD"),
          };
        }
      });

      setAttendanceDetails(calendarDatas);
      setFingerDetails(calendarDatas);
    }
  }, [attendancePaginationUserLoginDetail]);

  useEffect(() => {
    if (attendancePaginationResponse?.items) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [attendancePaginationResponse]);

  useEffect(() => {
    const messageTypes = [
      "All",
      "From",
      "To",
      "Cc",
      "Bcc",
      "Pending",
      "Archived",
      "Trash",
      "Junk",
      "Specialflag1",
      "Draft",
      "Outbox",
    ];

    messageTypes.forEach((type) =>
      dispatch(emailPaginationCount({ messagecenterType: type }))
    );

    getErrorMessageData(10, 0);
  }, []);

  const getErrorMessageData = (MaxResultCount = 0, SkipCount = 0) => {
    let appendUrl = `skipCount=${SkipCount}&maxResultCount=${MaxResultCount}`;
    dispatch(getErrorPagination(appendUrl));
  };

  const nullChecker = (values, type) => {
    if (mailPaginationCount) {
      if (type === "all") {
        if (values !== null) {
          return values?.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return 0;
        }
      } else if (type === "unread") {
        if (values !== null) {
          return values?.unread
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return 0;
        }
      }
    } else {
      return <Skeleton.Avatar shape="circle" />;
    }
  };

  useEffect(() => {
    dispatch(getApplicationConfiguration());
  }, []);

  useEffect(() => {
    if (!isEmpty(loginUserDto)) {
      setUserName(
        startCase(
          toLower(loginUserDto?.employeePersonalDetailUpdateDto?.employeeName)
        )
      );
    }
  }, [employeeProfile]);

  useEffect(() => {
    if (
      !isEmpty(profileInfo) &&
      !isEmpty(profileInfo.employeePersonalDetailUpdateDto)
    ) {
      setEmployeeProfile(profileInfo.employeePersonalDetailUpdateDto);
      if (!isEmpty(procurementCounterResponse)) {
        const shouldRender =
          profileInfo?.userRegistrationAddUpdateDto?.roleNames.some((ai) =>
            rolesToView.includes(ai)
          ) ||
          procurementCounterResponse?.leave?.total +
            procurementCounterResponse?.loan?.total +
            procurementCounterResponse?.procurement?.total !==
            0;

        setShouldRenderRequisitionList(shouldRender);
      }
    }
  }, [
    rolesToView,
    procurementCounterResponse,
    profileInfo,
    procurementCounterResponse?.leave?.total,
    procurementCounterResponse?.loan?.total,
    procurementCounterResponse?.procurement?.total,
  ]);

  const readNotificationFunc = (notificationId) => {
    connection
      .invoke("MakeBellNotificationSeen", notificationId)
      .catch(function (err) {
        return console.error(err.toString());
      });
  };

  useEffect(() => {
    if (attendenceTab == 2) {
      let calendarApi = calendarRef.current;
    }
  }, [attendenceTab]);

  const calendarFilter = (value) => {
    setFilterSoftwareType(value);
    setFilterFingerType(value);
  };
  const calendarFilter2 = (value) => {
    setIsSoftware(value);
  };
  const onChangeCheckbox = (e) => {
    setShowDate(e.target.checked);
  };
  const onChangeSwitchButton = (checked) => {
    setToggleValue(!toggleValue);
    dispatch(todoShowZoom(!toggleValue));
  };
  const getNoticeBoard = () => {
    dispatch(noticeBoardListing());
  };
  useEffect(() => {
    getNoticeBoard();
  }, []);
  useEffect(() => {
    if (isEmpty(allNoticeList)) return;
    setNoticeBoard(allNoticeList);
  }, [allNoticeList]);

  const handleDateChange = (values) => {
    setNewYear(values);
  };

  const onFinish1 = async () => {
    var datequery = { SearchDateFrom: startDate };
    fetchAttendacePagination(datequery);
  };

  const onFinish = async () => {
    const query = {
      NepaliMonth: newMonth,
      NepaliYear: newYear,
    };
    fetchMonthlyAttendace(query);
  };

  const defaultDate = moment();

  return (
    <>
      <div className="dashboards dashing" ref={ref}>
        <Layout
          nepaliDateResponse={nepaliDateResponse}
          userName={userName}
          bellCount={bellCount}
          setShowInformation={setShowInformation}
          showBellNotificationLists={showBellNotificationLists}
          setShowBellNotificationLists={setShowBellNotificationLists}
          showAttendenceLists={showAttendenceLists}
          setShowAttendenceLists={setShowAttendenceLists}
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          setIsModalVisible={setIsModalVisible}
          showPoliciesPage={showPoliciesPage}
          setShowPoliciesPage={setShowPoliciesPage}
          setIsTourOpen={setIsTourOpen}
          showTutorial={showTutorial}
          setShowTutorial={setShowTutorial}
          isTourOpen={isTourOpen}
        />
        {noticeBoard?.totalCount === 0 ? (
          ""
        ) : (
          <>
            {!isEmpty(noticeBoard) && (
              <>
                <div
                  style={{
                    marginTop: "-10px",
                    backgroundColor: "white",
                    marginBottom: "14px",
                    borderRadius: "8px",
                  }}
                >
                  <div className="dashboard_notice_board_top">
                    <div
                      className="dashboard_notice_title"
                      style={{ width: "4%", marginLeft: "5px" }}
                    >
                      Notice|
                    </div>
                    <marquee className="marquee_tag_dashboard">
                      {noticeBoard?.items?.map((data, i) => (
                        <span className="title_dashboard_notice" key={data?.id}>
                          {data?.title}
                          <span className="description_dashboard_notice">{` : ${data?.description}`}</span>
                        </span>
                      ))}
                    </marquee>
                  </div>
                </div>
                {isAfter4PM() && <NoticeBoardModal noticeBoard={noticeBoard} />}
                {/* <NoticeBoardModal noticeBoard={noticeBoard} /> */}
              </>
            )}
          </>
        )}
        <div
          className={`dashboard__flip ${
            showBellNotificationLists ||
            showAttendenceLists ||
            showSettings ||
            isModalVisible ||
            showTutorial ||
            showPoliciesPage
              ? "addTransform"
              : ""
          }`}
        >
          <Row
            style={{ order: 1 }}
            gutter={[20, 0]}
            className={`dashboards__row dashboards__row--front ${
              showBellNotificationLists ||
              showAttendenceLists ||
              showSettings ||
              isModalVisible ||
              showTutorial ||
              showPoliciesPage
                ? "visibilityHidden"
                : ""
            }`}
          >
            <ViewBox
              meetingInvitees={meetingInvitees}
              nullChecker={nullChecker}
              mailPaginationCount={mailPaginationCount}
              shouldRenderRequisitionList={shouldRenderRequisitionList}
              profileInfo={profileInfo}
            />
          </Row>

          {showBellNotificationLists ||
          showAttendenceLists ||
          showSettings ||
          showPoliciesPage ||
          showTutorial ? (
            showInformation === "Notifications" ? (
              <Notification
                allBellNotifications={allBellNotifications}
                readNotificationFunc={readNotificationFunc}
              />
            ) : showInformation === "Attendence" ? (
              <>
                <Attendance
                  setAttendenceTab={setAttendenceTab}
                  onFinish1={onFinish1}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  defaultDate={defaultDate}
                  loader={loader}
                  setLoader={setLoader}
                  attendancePaginationResponse={attendancePaginationResponse}
                  grantedPolices={grantedPolices}
                  userId={userId}
                  setUserId={setUserId}
                  meetingInvitees={meetingInvitees}
                  filterSoftwareType={filterSoftwareType}
                  calendarFilter={calendarFilter}
                  calendarRef={calendarRef}
                  setCalendarDate={setCalendarDate}
                  filterFingerType={filterFingerType}
                  fingerDetails={fingerDetails}
                  attendanceDetails={attendanceDetails}
                  profileInfo={profileInfo}
                  showDate={showDate}
                  onChangeCheckbox={onChangeCheckbox}
                  onFinish={onFinish}
                  setCanexport={setCanexport}
                  canExport={canExport}
                  newYear={newYear}
                  newMonth={newMonth}
                  setNewMonth={setNewMonth}
                  isSoftware={isSoftware}
                  calendarFilter2={calendarFilter2}
                  nepaliDateResponse={nepaliDateResponse}
                  handleDateChange={handleDateChange}
                  totalWorkingDays={totalWorkingDays}
                  totalDays={totalDays}
                  frommDate={frommDate}
                  absentPaginationResposne={absentPaginationResposne}
                />
              </>
            ) : showInformation === "Policies" ? (
              <Policies />
            ) : showInformation === "Tutorial" ? (
              <Tutorial />
            ) : (
              <Other
                onChangeSwitchButton={onChangeSwitchButton}
                zoomData={zoomData}
              />
            )
          ) : (
            ""
          )}

          {isModalVisible ? (
            <Row
              gutter={[20, 0]}
              className="dashboards__row dashboards__row--back"
            >
              <Col lg={24} xs={24}>
                <div className="flexend">
                  <div
                    className="organizer ContactDetails__header"
                    style={{ border: "none", width: "12% !important" }}
                  >
                    <h3 className="header__all">Complaint </h3>
                  </div>

                  <div style={{ width: "calc(100% - 142px)" }}>Sort By</div>

                  <Button
                    className="calendar-create"
                    onClick={() => setShowLeaveDrawer(true)}
                  >
                    + Complain
                  </Button>
                </div>
                <ComplaintView />
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
});

export default DraggableHome;
