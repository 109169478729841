import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Radio,
  Space,
  Button,
  Affix,
  DatePicker,
} from "antd";
import moment from "moment";
import { getAllUsers } from "services/remanentCalls";
import {
  actionForCreateJobInterview,
  actionForUpdateJobInterview,
} from "services/redux/hrSystem/action";
import { isEmpty } from "lodash";
import ContactPersonPagination from "views/Expert/ContactCompanyPerson/ContactPersonPagination";

const JobInterviewForm = ({
  setOpenApplicantDrawer,
  selectedSingleApplicantDAta,
  setSelectedSingleApplicantData,
  formApplicantsBasic,
  formApplicantEdu,
  formApplicantJob,
  formApplicantReview,
  setActiveTabIndexofAppicant,
  jobFormData,
  setJobForm,
}) => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [checkboxValue, setCheckboxValue] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(null);
  const [interviewDate, setInterviewDate] = useState(null);
  const onChangeCheckbox = (e) => {
    setCheckboxValue(e.target.value);
  };

  const { Option } = Select;
  const [form] = Form.useForm();

  // FETCHING ALL USERS
  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    const formData = jobFormData?.data;
    if (formData && jobFormData.update) {
      const {
        applicableSkills,
        attitude,
        candidateAppearance,
        communication,
        confidence,
        education,
        emotionalIntelligence,
        enthusiasm_energy,
        flexibility,
        language,
        learning_Attitude,
        publicRelation,
        understanding,
        workExperience,
        rating,
        remarks,
        interviewerId,
        interviewerName,
        secondInterviewSchedule,
      } = formData;

      form.setFieldsValue({
        applicableSkills: applicableSkills || null,
        attitude: attitude || null,
        candidateAppearance: candidateAppearance || null,
        communication: communication || null,
        confidence: confidence || null,
        education: education || null,
        emotionalIntelligence: emotionalIntelligence || null,
        enthusiasm_energy: enthusiasm_energy || null,
        flexibility: flexibility || null,
        language: language || null,
        learning_Attitude: learning_Attitude || null,
        publicRelation: publicRelation || null,
        understanding: understanding || null,
        workExperience: workExperience || null,
        rating,
        interviewerName: interviewerName || null,
        remarks: remarks || null,
        username: interviewerName || null,
        userId: interviewerId || null,
        secondInterviewSchedule: secondInterviewSchedule || null,
      });

      setCheckboxValue(parseInt(rating));
      setInterviewDate(secondInterviewSchedule);
      // setInterviewDate(moment(secondInterviewSchedule, "YYYY-MM-DD"));
    } else {
      form.resetFields();
    }
  }, [jobFormData, form, selectedSingleApplicantDAta?.id]);

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  //   const onhandleChange = (e) => {
  //     setUser((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  //   };

  const onChangeInterviewDate = (date, dateString) => {
    setInterviewDate(dateString);
  };

  const onFinishJobInterview = async (values) => {
    let data = {
      // id: jobFormData?.data?.id,
      applicantId: selectedSingleApplicantDAta?.id || null,
      applicantName: selectedSingleApplicantDAta?.fullName || null,
      workExperience: values?.workExperience || null,
      enthusiasm_energy: values?.enthusiasm_energy || null,
      learning_Attitude: values?.learning_Attitude || null,
      publicRelation: values?.publicRelation || null,
      flexibility: values?.flexibility || null,
      emotionalIntelligence: values?.emotionalIntelligence || null,
      understanding: values?.understanding || null,
      education: values?.education || null,
      applicableSkills: values?.applicableSkills || null,
      candidateAppearance: values?.candidateAppearance || null,
      attitude: values?.attitude || null,
      confidence: values?.confidence || null,
      communication: values?.communication || null,
      language: values?.language || null,
      remarks: values?.remarks || null,
      interviewerId: values?.userId,
      // rating: values?.rating,
      rating: checkboxValue,
      secondInterviewSchedule: interviewDate,
      isActive: true,
    };
    if (jobFormData?.update === true) {
      let updateData = {
        ...data,
        id: jobFormData?.data?.id,
      };
      dispatch(actionForUpdateJobInterview(jobFormData?.data?.id, updateData));
      setJobForm({
        add: false,
        view: false,
        update: false,
        data: {},
      });
    } else {
      dispatch(actionForCreateJobInterview(data));
      setJobForm({
        add: false,
        view: false,
        update: false,
        data: {},
      });
    }
  };

  const onhandleDelete = (values) => {
    const updatedData = {
      ...jobFormData.data,
      isActive: false,
    };
    dispatch(actionForUpdateJobInterview(updatedData?.id, updatedData));
  };

  const jobForm = () => {
    return (
      <>
        <Form
          className="jobInterviewForm"
          name="dynamic_form_item"
          form={form}
          onFinish={onFinishJobInterview}
        >
          <h3
            style={{
              background: "#d8ddeb",
              fontWeight: 600,
              paddingLeft: "8px",
            }}
          >
            Area of Assement
          </h3>
          <Form.Item
            name="workExperience"
            label="Relevant Work Experience"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="enthusiasm_energy"
            label="Enthusisam / Energy"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="learning_Attitude"
            label="Learning Attitude"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="publicRelation"
            label="Public Relation"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="flexibility"
            label="Flexibility"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="emotionalIntelligence"
            label="Emotional Intelligence"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="understanding"
            label="IQ"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="education"
            label="Relevant Education"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="applicableSkills"
            label="Skills"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="candidateAppearance"
            label="Apperance"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="attitude"
            label="Attitude"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="confidence"
            label="Confidence"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="communication"
            label="communication"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="language"
            label="language"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
          <Form.Item
            name="remarks"
            label="Remarks"
            className="jobInteviewNum"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            //   onChange={onhandleChange}
            rules={[{ required: false, message: "Full name is required" }]}
          >
            <Input.TextArea rows={4} style={{ marginBottom: "16px" }} />
          </Form.Item>
          <h3
            style={{
              background: "#d8ddeb",
              fontWeight: 600,
              paddingLeft: "8px",
            }}
          >
            Interviewer Recommendation(Overall Observation)
          </h3>
          <Form.Item label="" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Radio.Group
              value={checkboxValue}
              onChange={onChangeCheckbox}
              style={{ marginBottom: "16px" }}
            >
              <Space direction="vertical">
                <Radio value={1}>Rejected</Radio>
                <Radio value={2}>Short Listed with Reservation</Radio>
                <Radio value={3}>Recommendable</Radio>
                <Radio value={4}>Highly Recommended</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <h3
            style={{
              background: "#d8ddeb",
              fontWeight: 600,
              paddingLeft: "8px",
            }}
          >
            Interviewed by
          </h3>
          <Form.Item
            name="userId"
            label="Username"
            className="jobInteviewNum"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            rules={[{ required: false, message: "Username is required" }]}
          >
            <Select
              showSearch
              placeholder="Select a user"
              optionFilterProp="children"
            >
              {users.map((user) => (
                <Option key={user.appUserId} value={user.appUserId}>
                  {`${user.fullName}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Interview Date"
            className="jobInterview_date"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <DatePicker
              value={interviewDate ? moment(interviewDate, "YYYY-MM-DD") : null}
              onChange={onChangeInterviewDate}
            ></DatePicker>
          </Form.Item>
          <Affix
            className="hrAffix"
            offsetBottom={0}
            style={{
              // background: "#ffffff",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <span></span>
            <Button type="primary" htmlType="submit">
              {jobFormData?.add ? "Save" : "Update"}
            </Button>
          </Affix>
        </Form>
      </>
    );
  };

  return <>{jobFormData?.add || jobFormData?.update ? jobForm() : null}</>;
};

export default JobInterviewForm;
