import { Card, Table } from "antd";
import React from "react";
import { PhoneOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector } from "react-redux";

const AgendaCard = ({ visitor, dataSource1 }) => {
  const { todoAgendasListingResponse, todoAgendasListingResponseLoading } =
    useSelector((state) => state.toDoEvents);
  const columns = [
    {
      title: "SN",
      dataIndex: "",
      key: "name",
      render: (row, record, index) => index + 1,
    },
    {
      title: "Agenda",
      dataIndex: "agenda",
      key: "agenda",
    },
    {
      title: "Discussion",
      dataIndex: "discussion",
      key: "discussion",
    },
    {
      title: "On",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (text, record) => (
        <div>{moment(record?.creationTime).format("YYYY-MM-DD")}</div>
      ),
    },
  ];
  return (
    <>
      <Card
        title={
          <>
            {visitor ? (
              <>
                <UserOutlined />
                Visit to Make
              </>
            ) : (
              <>
                <PhoneOutlined
                  style={{ transform: "scaleX(-1)", marginRight: "8px" }}
                />
                Calls to Make
              </>
            )}
          </>
        }
        style={{
          width: "95%",
          marginTop: "10px",
          marginBottom: "auto",
          gridAutoFlow: "row",
          gridRowGap: "10px",
        }}
      >
        <div className="drawer-form-group">
          <Table
            columns={columns}
            dataSource={todoAgendasListingResponse}
            pagination={false}
            loading={todoAgendasListingResponseLoading}
          />
        </div>
      </Card>
    </>
  );
};

export default AgendaCard;
