import React, { useEffect } from "react";
import { Table } from "antd";
import { LogoImage } from "utils/imageConstants";
import { EyeOutlined, PlayCircleTwoTone } from "@ant-design/icons";
import useRoles from "hooks/useRoles";

function TutorialTable({
  isSetEditData,
  isTutorialDrawerOpen,
  setIsTutorialDrawerOpen,
  setTutorial,
  data,
  loading,
  isModelOpen,
  setIsModelOpen,
  youtubeUrl,
  setYoutubeUrl,
  setModelTitle,
}) {
  //
  const { isLimitedRoleTutorial } = useRoles();
  //
  const paginationConfig = {
    pageSize: 20,
  };

  const columns = [
    {
      title: "S.N",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Play",
      key: "play",
      render: (record) => (
        <PlayCircleTwoTone
          onClick={() => {
            setYoutubeUrl(record?.youTubeLink);
            setIsModelOpen(!isModelOpen);
            setModelTitle(record?.title);
          }}
          style={{ fontSize: "18px" }}
        />
      ),
    },
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    { title: "Category", key: "category", dataIndex: "tutorialTypeName" },
    isLimitedRoleTutorial
      ? {
          title: "Action",
          render: (record) => (
            <>
              <EyeOutlined
                onClick={() => {
                  setIsTutorialDrawerOpen(!isTutorialDrawerOpen);
                  isSetEditData(record);
                  setTutorial({ type: "View", view: true });
                }}
              ></EyeOutlined>
            </>
          ),
        }
      : {},
  ];

  return (
    <>
      <Table
        className="next-organizer-table"
        columns={columns}
        dataSource={data}
        loading={{
          indicator: (
            <img
              src={LogoImage}
              height="auto"
              width="50px"
              alt="Tutorial Table Loader"
            />
          ),
          spinning: loading,
        }}
        pagination={paginationConfig}
      />
    </>
  );
}

export default TutorialTable;
