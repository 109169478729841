import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  InputNumber,
  DatePicker,
  Select,
  Collapse,
  Checkbox,
  Form,
  Layout,
  Skeleton,
  Upload,
  Popover,
  Switch,
  Pagination,
  Row,
  Col,
  message,
} from "antd";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  UploadOutlined,
  SettingOutlined,
  PaperClipOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  tenderBasicUpdate,
  tenderStageListing,
  tenderTypeListing,
  tenderBasicAdd,
} from "services/redux/Reference/Tender/action";
import { allCompanyListing } from "services/redux/Reference/action";
import { sectorListing, getAllUsers } from "services/remanentCalls";
import { allCountryListing } from "services/redux/contact/contact.action";
import { documentFilesAddUpdate } from "services/redux/Reference/Tender/action";
import { cqBaseUrl } from "utils/config";
import moment from "moment";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import { isEmpty, pickBy, groupBy, cloneDeep, add } from "lodash";
import {
  tenderParticipationUpdate,
  tenderParticipationGetDetails,
} from "services/redux/Reference/Tender/action";
import { tenderParticipationAdd } from "services/redux/Reference/Tender/action";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import { contactCompanyListing } from "../../../../../services/remanentCalls";
const { Option } = Select;

const AddUpdateParticipation = ({ getDetails, tenderTypeListingResponse }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const {
    tenderStageListingResponse,

    tenderBasicGetDetailsResponse,
    tenderBasicUpdateResponse,
    tenderBasicGetDetailsLoading,
    documentUploadResp,
    tenderDocumentGetDetailsResponse,
  } = useSelector((state) => state.tender);
  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);
  const { allCurrencyListingResponse } = useSelector(
    (state) => state.reference
  );
  const { allCountryListingResponse } = useSelector((state) => state.contact);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  //state Decalartation
  const [sectors, setSectors] = useState([]);
  const [user, setUsers] = useState([]);
  const [editForm, setEditForm] = useState(true);
  const [bidSecuirtyForm, setBidSecurityForm] = useState(false);
  const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);

  const [showDocumentPurchae, setDocumentPurchase] = useState([]);
  const [getDocPurchaseDetails, setDocPurchaseDetails] = useState();
  const [showMore, setShowMore] = useState([]);
  const [showLetterVisible, setShowLetterVisible] = useState([]);

  useEffect(() => {
    fetchAllSector();
    fetchAllUsers();
    if (isEmpty(sisterCompanyListing)) {
      dispatch(getSisterCompaniesListings());
    }
  }, []);

  // const onSelect = (e) => {
  // 	if (e.target.checked === true) {
  // 		setOpenForm(true);
  // 		form.setFieldsValue({
  // 			documentsPurchasedtos: [
  // 				{
  // 					purchaseDate: '',
  // 					companyName: '',
  // 					through: '',
  // 					claim: '',
  // 				},
  // 			],
  // 		});
  // 	} else {
  // 		setOpenForm(false);
  // 		form.setFieldsValue({
  // 			documentsPurchasedtos: [],
  // 		});
  // 	}
  // };

  useEffect(() => {
    if (!isEmpty(getDetails?.tenderParticipationDtos)) {
      let viewlists = [...showLetterVisible];
      getDetails.tenderParticipationDtos?.map((item, i) => {
        viewlists[i] = "More";
      });
      setShowLetterVisible(viewlists);
    }
  }, [getDetails?.tenderParticipationDtos]);

  const fetchAllSector = async () => {
    const resp = await sectorListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setSectors(resp.data);
    }
  };

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  useEffect(() => {
    if (!isEmpty(documentUploadResp)) {
      // setEditForm(true);
    }
  }, [documentUploadResp]);

  useEffect(() => {
    dispatch(tenderTypeListing());
    dispatch(tenderStageListing());
    dispatch(allCountryListing());
    dispatch(allCompanyListing());
  }, [dispatch]);

  const handleVisibleChange = (index) => {
    let lists = [...popoverMenuVisible];
    lists[index] = !lists[index];
    setPopoverMenuVisible(lists);
  };

  const getActionContent = (item, index) => {
    return (
      <div className='guest-actions'>
        <div
          className='guest-act'
          onClick={() => {
            handleVisibleChange(index);
            let lists = cloneDeep(showDocumentPurchae);
            // console.log(lists)
            lists[index] = "true";
            setDocumentPurchase(lists);
            setDocPurchaseDetails(item);
            setEditForm(false);
          }}>
          Edit
        </div>
        <div className='guest-act'>Delete</div>
      </div>
    );
  };

  const toogleshow = (index) => {
    let lists = cloneDeep(showMore);
    lists[index] = "true";
    setShowMore(lists);
  };

  const showBidSecurityForm = (value) => {
    if (value === "false") {
      setBidSecurityForm(false);
    } else {
      setBidSecurityForm(true);
    }
  };

  const onUpdateParticipation = (newAddDocValues) => {
    let newDoucPurchase = [
      {
        ...newAddDocValues,
        bidSecurity: newAddDocValues.bidSecurity === "Yes" ? true : false,
        tenderBasicId: getDocPurchaseDetails.tenderBasicId,
        id: getDocPurchaseDetails.id,
      },
    ];

    dispatch(
      tenderParticipationUpdate(
        newDoucPurchase,
        getDocPurchaseDetails.tenderBasicId
      )
    );
  };

  const onAddParticipation = (AddDocValues) => {
    // let newDoucmentPurhcase = newAddDocValues.tenderDocumentPurchaseAddUpdateDtos.map((obj) => ({
    // 	contactCompanyId: obj.contactCompanyId,
    // 	throughId: obj.throughCompanyId,
    // 	tenderBasicId: getDocPurchaseDetails.id,
    // 	claim: newAddDocValues.tenderDocumentPurchaseAddUpdateDtos.claim == 'true' ? 'true' : 'false',
    // 	purchaseDate: obj.purchaseDate,
    // }));
    let id = tenderBasicGetDetailsResponse?.id;
    let newDoucmentPurhcase =
      AddDocValues.tenderDocumentPurchaseAddUpdateDtos.map((obj) => ({
        ...obj,
        bidSecurity:
          AddDocValues.tenderDocumentPurchaseAddUpdateDtos.bidSecurity == "true"
            ? "true"
            : "false",
      }));
    dispatch(tenderParticipationUpdate(newDoucmentPurhcase, id));
  };

  return (
    <div>
      {tenderBasicGetDetailsResponse?.tenderParticipationDtos?.map(
        (data, index) => (
          <div>
            <>
              <div className='reference__view'>
                <div className='reference__header'>
                  <span className='reference__header--text'>
                    {data.companyName}:
                  </span>
                  <div className='reference__header--left'>
                    <span className='reference__header--rank'>
                      {" "}
                      {data.rank}
                    </span>
                    {/* <Popover
										content={() => getActionContent(data, index)}
										title="Action"
										trigger="click"
										className="tooltips"
										visible={popoverMenuVisible[index]}
										onVisibleChange={() => handleVisibleChange(index)}
										placement="bottomRight"
									>
										<MoreOutlined style={{ fontSize: 18 }} />
									</Popover> */}
                  </div>
                </div>
                <div className='reference__view--wrapper'>
                  <div className='reference__info'>
                    <div className='reference__label'>
                      <span className='referenceDetails--title '>Through:</span>
                    </div>
                    <div className='reference__labeldata'>
                      <span className='referenceDetails--subtitle '>
                        {data.throughCompanyName}
                      </span>
                    </div>
                  </div>
                  {/* {console.log(showLetterVisible[index])} */}

                  <div className='reference__info'>
                    <div className='reference__label'>
                      <span className='referenceDetails--title '>
                        Bid Price:
                      </span>
                    </div>
                    <div className='reference__labeldata'>
                      <span className='referenceDetails--subtitle '>
                        {data.bidPriceCurrencyName} {data.bidPrice}
                      </span>

                      {showLetterVisible[index] === "More" ? (
                        <span
                          className='reference__info--text'
                          onClick={() => {
                            toogleshow(index);
                            let viewLists = [...showLetterVisible];
                            viewLists[index] = "Less";
                            setShowLetterVisible(viewLists);
                          }}>
                          Show {showLetterVisible[index]}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {showLetterVisible[index] === "Less" ? (
                    <div>
                      <div className='reference__info'>
                        <div className='reference__label'>
                          <span className='referenceDetails--title '>
                            Bid Validity:
                          </span>
                        </div>
                        <div className='reference__labeldata'>
                          <span className='referenceDetails--subtitle '>
                            {moment(data.bidValidity).format("YYYY/MM/DD")}
                          </span>
                        </div>
                      </div>
                      <div className='reference__info'>
                        <div className='reference__label'>
                          <span className='referenceDetails--title '>
                            Rank:
                          </span>
                        </div>
                        <div className='reference__labeldata'>
                          <span className='referenceDetails--subtitle '>
                            {data.rank}
                          </span>
                        </div>
                      </div>
                      <div className='reference__info'>
                        <div className='reference__label'>
                          <span className='referenceDetails--title '>
                            Bid Security Amount:
                          </span>
                        </div>
                        <div className='reference__labeldata'>
                          <span className='referenceDetails--subtitle '>
                            {data.bidSecurityCurrencyName}{" "}
                            {data.bidSecurityValue}
                          </span>
                        </div>
                      </div>
                      <div className='reference__info'>
                        <div className='reference__label'>
                          <span className='referenceDetails--title '>
                            Type:
                          </span>
                        </div>
                        <div className='reference__labeldata'>
                          <span className='referenceDetails--subtitle '>
                            {data.tenderTypeName}
                          </span>
                        </div>
                      </div>
                      <div className='reference__info'>
                        <div className='reference__label'>
                          <span className='referenceDetails--title '>
                            Arranged By:
                          </span>
                        </div>
                        <div className='reference__labeldata'>
                          <span className='referenceDetails--subtitle '>
                            {data.arrangedBy}
                          </span>
                        </div>
                      </div>
                      <div className='reference__info'>
                        <div className='reference__label'>
                          <span className='referenceDetails--title '>
                            Issuing Bank:
                          </span>
                        </div>
                        <div className='reference__labeldata'>
                          <span className='referenceDetails--subtitle '>
                            {data.issuingBank}
                          </span>
                        </div>
                      </div>
                      <div className='reference__info'>
                        <div className='reference__label'>
                          <span className='referenceDetails--title '>
                            Reference No:
                          </span>
                        </div>
                        <div className='reference__labeldata'>
                          <span className='referenceDetails--subtitle '>
                            {data.referenceNo}
                          </span>
                        </div>
                      </div>
                      <div className='reference__info'>
                        <div className='reference__label'>
                          <span className='referenceDetails--title '>
                            BG Validity:
                          </span>
                        </div>
                        <div className='reference__labeldata'>
                          <span className='referenceDetails--subtitle '>
                            {moment(data.purchaseDate).format("YYYY/MM/DD")}
                          </span>

                          <span
                            onClick={() => {
                              let lists = cloneDeep(showMore);
                              let viewLists = [...showLetterVisible];
                              viewLists[index] = "More";
                              setShowLetterVisible(viewLists);
                              lists[index] = "false";
                              setShowMore(lists);
                            }}
                            className='reference__info--text'>
                            Show Less
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <Form
                layout='vertical'
                form={form}
                name='documentPurchase'
                onFinish={onUpdateParticipation}>
                {!isEmpty(showDocumentPurchae) &&
                showDocumentPurchae[index] === "true" ? (
                  <div className='reference__view'>
                    <div className='edittender__wrapper'>
                      <div className='editender__body'>
                        <div className='profiledetails__rowedit'>
                          <div className='profiledetails__rowedit--label'>
                            <span className='profile__bodyTitle'>Company</span>
                          </div>
                          <div className='profiledetails__rowedit--data'>
                            <span className='referenceDetails--subtitle '>
                              <Form.Item
                                name='companyId'
                                initialValue={data.companyId}
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}>
                                <Select
                                  autoFocus={true}
                                  size='small'
                                  showSearch={true}
                                  allowClear
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }>
                                  {allCountryListingResponse?.map((country) => (
                                    <Option value={country.id} key={country.id}>
                                      {country.companyName}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </span>
                          </div>
                        </div>

                        <div className='profiledetails__rowedit'>
                          <div className='profiledetails__rowedit--label'>
                            <span className='profile__bodyTitle'>Through</span>
                          </div>
                          <div className='profiledetails__rowedit--data'>
                            <span className='referenceDetails--subtitle '>
                              <Form.Item
                                name='throughCompanyId'
                                initialValue={data.throughCompanyId}
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}>
                                <Select size='small'>
                                  {sisterCompanyListing.map((item) => (
                                    <Option key={item.id} value={item.id}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </span>
                          </div>
                        </div>

                        <div className='profiledetails__rowedit'>
                          <div className='profiledetails__rowedit--label'>
                            <span className='profile__bodyTitle'>
                              Bid Price
                            </span>
                          </div>
                          <div
                            className='profiledetails__rowedit--data'
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <span
                              className='contactsDetails--subtitle bold'
                              style={{ width: "20%" }}>
                              <Form.Item
                                name='bidPriceCurrencyId'
                                initialValue={data.bidPriceCurrencyId}
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}>
                                <Select
                                  size='small'
                                  allowClear
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }>
                                  {allCurrencyListingResponse?.map(
                                    (currency) => (
                                      <Option
                                        key={currency.currencyId}
                                        value={currency.currencyId}>
                                        {currency.abbvr}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </span>
                            <span
                              className='contactsDetails--subtitle bold'
                              style={{ width: "78%" }}>
                              <Form.Item
                                name='bidPrice'
                                initialValue={data.bidPrice}
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}>
                                <Input size='small' />
                              </Form.Item>
                            </span>
                          </div>
                        </div>
                        <div className='profiledetails__rowedit'>
                          <div className='profiledetails__rowedit--label'>
                            <span className='profile__bodyTitle'>
                              Bid Validity
                            </span>
                          </div>
                          <div className='profiledetails__rowedit--data'>
                            <span className='referenceDetails--subtitle '>
                              <Form.Item
                                name='bidValidity'
                                initialValue={moment(data.bidValidity)}
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}>
                                <DatePicker
                                  size='small'
                                  disabledDate={(current) => {
                                    return current < moment();
                                  }}
                                  format='YYYY/MM/DD'
                                />
                              </Form.Item>
                            </span>
                          </div>
                        </div>
                        <div className='profiledetails__rowedit'>
                          <div className='profiledetails__rowedit--label'>
                            <span className='profile__bodyTitle'>Rank</span>
                          </div>
                          <div className='profiledetails__rowedit--data'>
                            <span className='referenceDetails--subtitle '>
                              <Form.Item
                                name='rank'
                                initialValue={data.rank}
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}>
                                <Input size='small' />
                              </Form.Item>
                            </span>
                          </div>
                        </div>
                        <div className='profiledetails__rowedit'>
                          <div className='profiledetails__rowedit--label'>
                            <span className='profile__bodyTitle'>
                              Bid Security Amount
                            </span>
                          </div>
                          <div className='profiledetails__rowedit--data'>
                            <span className='referenceDetails--subtitle '>
                              <Form.Item
                                name='bidSecurity'
                                initialValue={
                                  data.bidSecurity === true ? "YES" : "NO"
                                }
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}>
                                <Select
                                  size='small'
                                  onChange={showBidSecurityForm}>
                                  <Option value='true'>Yes</Option>
                                  <Option value='false'>No</Option>
                                </Select>
                              </Form.Item>
                            </span>
                          </div>
                        </div>

                        {data.bidSecurity == true || bidSecuirtyForm ? (
                          <>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>Type</span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    name='tenderTypeId'
                                    initialValue={data.tenderTypeId}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Select
                                      size='small'
                                      allowClear
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }>
                                      {tenderTypeListingResponse?.map(
                                        (type) => (
                                          <Option key={type.id} value={type.id}>
                                            {type.tenderTypeName}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Arranged By
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    name='arrangedBy'
                                    initialValue={data.arrangedBy}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Select
                                      autoFocus={true}
                                      size='small'
                                      showSearch={true}
                                      allowClear
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }>
                                      {allCountryListingResponse?.map(
                                        (country) => (
                                          <Option
                                            value={country.id}
                                            key={country.id}>
                                            {country.companyName}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Issuing Bank
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    name='issuingBank'
                                    initialValue={data.issuingBank}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Input size='small' />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Reference No
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    name='referenceNo'
                                    initialValue={data.referenceNo}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Input size='small' />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>

                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Bid Security Amount
                                </span>
                              </div>
                              <div
                                className='profiledetails__rowedit--data'
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <span
                                  className='contactsDetails--subtitle bold'
                                  style={{ width: "20%" }}>
                                  <Form.Item
                                    name='bidSecurityCurrencyId'
                                    initialValue={data.bidSecurityCurrencyId}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Select
                                      size='small'
                                      allowClear
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }>
                                      {allCurrencyListingResponse?.map(
                                        (currency) => (
                                          <Option
                                            key={currency.currencyId}
                                            value={currency.currencyId}>
                                            {currency.abbvr}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </span>
                                <span
                                  className='contactsDetails--subtitle bold'
                                  style={{ width: "78%" }}>
                                  <Form.Item
                                    name='bidSecurityValue'
                                    initialValue={data.bidSecurityValue}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Input size='small' />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Bid Security Validity Till
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    name='bidSecurityValidity'
                                    initialValue={moment(
                                      data.bidSecurityValidity
                                    )}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <DatePicker
                                      size='small'
                                      disabledDate={(current) => {
                                        return current < moment();
                                      }}
                                      format='YYYY/MM/DD'
                                    />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className='reference__head'>
                          <Button
                            type='link'
                            className='btn-cancel'
                            onClick={() => {
                              let lists = cloneDeep(showDocumentPurchae);
                              lists[index] = "false";
                              setDocumentPurchase(lists);
                            }}>
                            Cancel
                          </Button>
                          <Button
                            type='primary'
                            className='btn-update__updateref'
                            htmlType='submit'>
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Form>
            </>
          </div>
        )
      )}

      <Form
        form={form2}
        layout='vertical'
        name='addParticipation'
        onFinish={onAddParticipation}>
        <Form.List name='tenderDocumentPurchaseAddUpdateDtos'>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, fieldIndex) => (
                <React.Fragment key={field.key}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <div className='reference__view'>
                        <div className='edittender__wrapper'>
                          <div className='editender__body'>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Company
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='companyId'
                                    name={[field.name, "companyId"]}
                                    fieldKey={[field.fieldKey, "companyId"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Select
                                      autoFocus={true}
                                      size='small'
                                      showSearch={true}
                                      allowClear
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }>
                                      {allCountryListingResponse?.map(
                                        (country) => (
                                          <Option
                                            value={country.id}
                                            key={country.id}>
                                            {country.companyName}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </span>
                              </div>
                              <div style={{ width: 2 }}>
                                <MinusCircleOutlined
                                  style={{ width: 22, marginTop: 3 }}
                                  className='contact__btn-danger'
                                  onClick={() => remove(field.name)}
                                />
                              </div>
                            </div>

                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Through
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='throughCompanyId'
                                    name={[field.name, "throughCompanyId"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "throughCompanyId",
                                    ]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Select size='small'>
                                      {sisterCompanyListing.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </span>
                              </div>
                            </div>

                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Bid Price
                                </span>
                              </div>
                              <div
                                className='profiledetails__rowedit--data'
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <span
                                  className='contactsDetails--subtitle bold'
                                  style={{ width: "20%" }}>
                                  <Form.Item
                                    className='bidPriceCurrencyId'
                                    name={[field.name, "bidPriceCurrencyId"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "bidPriceCurrencyId",
                                    ]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Select
                                      size='small'
                                      allowClear
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }>
                                      {allCurrencyListingResponse?.map(
                                        (currency) => (
                                          <Option
                                            key={currency.currencyId}
                                            value={currency.currencyId}>
                                            {currency.abbvr}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </span>
                                <span
                                  className='contactsDetails--subtitle bold'
                                  style={{ width: "78%" }}>
                                  <Form.Item
                                    className='bidPrice'
                                    name={[field.name, "bidPrice"]}
                                    fieldKey={[field.fieldKey, "bidPrice"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Input size='small' />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Bid Validity
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='bidValidity'
                                    name={[field.name, "bidValidity"]}
                                    fieldKey={[field.fieldKey, "bidValidity"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <DatePicker
                                      size='small'
                                      disabledDate={(current) => {
                                        return current < moment();
                                      }}
                                      format='YYYY/MM/DD'
                                    />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>Rank</span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='rank'
                                    name={[field.name, "rank"]}
                                    fieldKey={[field.fieldKey, "rank"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Input size='small' />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Bid Security Amount
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='bidSecurity'
                                    name={[field.name, "bidSecurity"]}
                                    fieldKey={[field.fieldKey, "bidSecurity"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Select size='small'>
                                      <Option value='true'>Yes</Option>
                                      <Option value='false'>No</Option>
                                    </Select>
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>Type</span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='tenderTypeId'
                                    name={[field.name, "tenderTypeId"]}
                                    fieldKey={[field.fieldKey, "tenderTypeId"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Select
                                      size='small'
                                      allowClear
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }>
                                      {tenderTypeListingResponse?.map(
                                        (type) => (
                                          <Option key={type.id} value={type.id}>
                                            {type.tenderTypeName}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Arranged By
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='arrangedBy'
                                    name={[field.name, "arrangedBy"]}
                                    fieldKey={[field.fieldKey, "arrangedBy"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Input size='small' />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Issuing Bank
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='issuingBank'
                                    name={[field.name, "issuingBank"]}
                                    fieldKey={[field.fieldKey, "issuingBank"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Input size='small' />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Reference No
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='referenceNo'
                                    name={[field.name, "referenceNo"]}
                                    fieldKey={[field.fieldKey, "referenceNo"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Input size='small' />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>

                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Bid Security Amount
                                </span>
                              </div>
                              <div
                                className='profiledetails__rowedit--data'
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}>
                                <span
                                  className='contactsDetails--subtitle bold'
                                  style={{ width: "20%" }}>
                                  <Form.Item
                                    className='bidSecurityCurrencyId'
                                    name={[field.name, "bidSecurityCurrencyId"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "bidSecurityCurrencyId",
                                    ]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Select
                                      size='small'
                                      allowClear
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }>
                                      {allCurrencyListingResponse?.map(
                                        (currency) => (
                                          <Option
                                            key={currency.currencyId}
                                            value={currency.currencyId}>
                                            {currency.abbvr}
                                          </Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </span>
                                <span
                                  className='contactsDetails--subtitle bold'
                                  style={{ width: "78%" }}>
                                  <Form.Item
                                    className='bidSecurityValue'
                                    name={[field.name, "bidSecurityValue"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "bidSecurityValue",
                                    ]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <Input size='small' />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                            <div className='profiledetails__rowedit'>
                              <div className='profiledetails__rowedit--label'>
                                <span className='profile__bodyTitle'>
                                  Bid Security Validity Till
                                </span>
                              </div>
                              <div className='profiledetails__rowedit--data'>
                                <span className='referenceDetails--subtitle '>
                                  <Form.Item
                                    className='bidSecurityValidity'
                                    name={[field.name, "bidSecurityValidity"]}
                                    fieldKey={[
                                      field.fieldKey,
                                      "bidSecurityValidity",
                                    ]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}>
                                    <DatePicker
                                      size='small'
                                      disabledDate={(current) => {
                                        return current < moment();
                                      }}
                                      format='YYYY/MM/DD'
                                    />
                                  </Form.Item>
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div className="reference__head">
														<Button type="primary" className="btn-update__updateref" htmlType="submit">
															Save
														</Button>
													</div> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              ))}

              {/* <div className="reference__btns">
								<Form.Item>
									<Button type="dashed" className="reference__btns--add" onClick={() => add()} block icon={<PlusCircleOutlined />}>
										Add New
									</Button>
								</Form.Item>
							</div> */}
            </>

            //for button of Add Address
          )}
        </Form.List>
      </Form>
    </div>
  );
};

export default AddUpdateParticipation;
