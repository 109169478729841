import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

// antd
import { Select, Popover, Switch, Input, Pagination, Row, Col } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

// constants
import { LogoImage } from 'utils/imageConstants';

// redux
import { useDispatch } from 'react-redux';
import { meetingMarkAsComplete } from 'services/redux/organizer/meeting/action';

const { Search } = Input;
const { Option } = Select;

const MeetingLists = (props) => {
	const dispatch = useDispatch();

	const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);
	const { data, pageSize, meetingEventsLoading, currentPage, setCurrentPage, editMeetingList, openMeetingFormDrawer, setMeetingEventId, setCurrentMeetingEventAttendees, meetingEventId } = props;

	// const [hideCompleted, setHideCompleted] = useState('');
	const [orderBy, setOrderBy] = useState('');
	const [sortType, setSortType] = useState('desc');
	const [viewList, setViewList] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		if (!isEmpty(data.items)) {
			let lists = [...popoverMenuVisible];
			let viewlists = [...viewList];
			data.items.map((item, i) => {
				lists[i] = false;
				viewlists[i] = 'More';
			});
			setPopoverMenuVisible(lists);
			setViewList(viewlists);
		}
	}, [data.items]);

	const handleVisibleChange = (index) => {
		let lists = [...popoverMenuVisible];
		lists[index] = !lists[index];
		setPopoverMenuVisible(lists);
	};

	const getActionContent = (item, index) => {
		return (
			<div className="guest-actions">
				<div
					className="guest-act"
					onClick={() => {
						openMeetingFormDrawer();
						editMeetingList(item);
						handleVisibleChange(index);
					}}
				>
					Edit
				</div>
				{/* <div className="guest-act" onClick={() => {}}>Add agenda</div> */}
				<div className="guest-act">Delete</div>
			</div>
		);
	};

	// const onChange = (checked) => {
	//     setHideCompleted(!checked);
	//     setCurrentPage(1);
	//     props.getData(pageSize, 0, !checked, orderBy, sortType);
	// }

	const onChangePage = (page) => {
		setCurrentPage(page);
		props.getData(pageSize, (page - 1) * pageSize, orderBy, sortType, searchValue);
	};

	const onChangeSearch = (value) => {
		setSearchValue(value);
		props.getData(pageSize, 0, orderBy, sortType, value);
	};

	const getHalfDetails = (notes) => {
		let halfDetails = notes.split('').splice(0, 80).join('');
		return halfDetails;
	};

	// let notesSummary =
	return (
		<div className="organizer__list">
			<div className="title">
				<Search
					placeholder="Search By Meeting Title, Sister Company and Meeting Room"
					allowClear
					// enterButton="Search"
					size="large"
					onSearch={onChangeSearch}
				/>
				<Select
					placeholder="Select Order By"
					style={{ width: 150 }}
					onChange={(value) => {
						setOrderBy(value);
						setCurrentPage(1);
						props.getData(pageSize, 0, value, sortType, searchValue);
					}}
					value={orderBy}
				>
					<Option value={''}>Due Date</Option>
					<Option value="title">Meeting Title</Option>
					<Option value="sistercompany">Sister Company</Option>
					<Option value="startdate">Start Date</Option>
				</Select>

				<Select
					placeholder="Select SortType"
					style={{ width: 150 }}
					onChange={(value) => {
						setSortType(value);
						setCurrentPage(1);
						props.getData(pageSize, 0, orderBy, value, searchValue);
					}}
					value={sortType}
				>
					<Option value="asc">Ascending</Option>
					<Option value="desc">Descending</Option>
				</Select>
			</div>

			<div className="guest-list-body">
				{!isEmpty(data.items) ? (
					data.items.map((item, index) => {
						const eventStatusColor = item.meetingEventStatusDto?.colorHexValue || 'green';
						return (
							<div
								className={`gray__form show-cursor ${item.id === meetingEventId ? 'meetingEventActive' : ''}`}
								key={index}
								onClick={() => {
									setMeetingEventId(item.id);
									setCurrentMeetingEventAttendees(item.meetingEventCandidateDtos);
								}}
							>
								<div className="todo__card--status">
									<span className="badge" style={{ color: '#fff', backgroundColor: eventStatusColor, border: 0 }}>
										{item.meetingEventStatusDto?.name}
									</span>
									<span className={`badge badge-outline--${item.isActive ? 'public' : 'inactive'}`}>{item.isActive ? 'Active' : 'Inactive'}</span>
									{/* {item.isComplete && <span className="badge badge-outline--active">Completed</span>} */}
								</div>
								<div className="organizer__card--body">
									<div className={`card-title ${item.isComplete && 'marksAsComplete'}`}>{item.title}</div>
									{/* <div className="card-invitedBy">{item.}</div> */}
									{!isEmpty(item.meetingEventCandidateDtos) && (
										<div className="card-attendee">
											Attendees:{' '}
											{item.meetingEventCandidateDtos.map((candidate, i) => {
												return (
													<span key={i}>
														{candidate.candidateDto.fullName}
														{item.meetingEventCandidateDtos.length !== i + 1 && ','}
													</span>
												);
											})}
										</div>
									)}
									<div className="card-meeting-room">
										<span className="badge">{item.sisterCompanyDto.name}</span>
										<span className="badge">{item.meetingRoom}</span>
									</div>
									<div className="card-notes">
										{item.notes && viewList[index] === 'More' ? (
											<>
												{getHalfDetails(item.notes)}
												<span
													onClick={() => {
														let viewLists = [...viewList];
														viewLists[index] = 'Less';
														setViewList(viewLists);
													}}
												>
													{item.notes.split('').length > 80 && `... View ${viewList[index]}`}
												</span>
											</>
										) : (
											<>
												{item.notes}
												<span
													onClick={() => {
														let viewLists = [...viewList];
														viewLists[index] = 'More';
														setViewList(viewLists);
													}}
												>
													{' '}
													View {viewList[index]}
												</span>
											</>
										)}
									</div>
									<div className="card-date">
										{moment(item.startDate).format('DD-MMM-YYYY')} to {moment(item.dueDate).format('DD-MMM-YYYY')}
									</div>
									<div className="guest-right-menu">
										<div onClick={() => item.meetingEventStatusDto?.name !== 'Completed' && dispatch(meetingMarkAsComplete(item.id))} className="mark-it-off">
											{item.meetingEventStatusDto?.name === 'Completed' ? (
												<img src="./assets/images/icons/check.png" alt="complete" />
											) : (
												<img src="./assets/images/icons/incomplete.png" alt="incomplete" />
											)}
										</div>
										<Popover
											content={() => getActionContent(item, index)}
											title="Action"
											trigger="click"
											className="tooltips"
											visible={popoverMenuVisible[index]}
											onVisibleChange={() => handleVisibleChange(index)}
											placement="bottomRight"
										>
											<MoreOutlined style={{ fontSize: 18 }} />
										</Popover>
									</div>
								</div>
							</div>
						);
					})
				) : meetingEventsLoading ? (
					<div style={{ height: 50, width: 50 }}>
						<img src={LogoImage} height="100%" />
					</div>
				) : (
					'No data'
				)}
			</div>
			<Row>
				<Col span={24} style={{ marginTop: '20px' }}>
					{!isEmpty(data.items) && <Pagination current={currentPage} total={data.totalCount} showSizeChanger={false} pageSize={pageSize} onChange={onChangePage} />}
				</Col>
			</Row>
		</div>
	);
};

export default MeetingLists;
