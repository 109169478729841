import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Form, Input } from "antd";
import moment from "moment";
import { holidayEventsPagination } from "services/redux/holiday/action";

const HolidayEventAddUpdateTable = ({ showAddUpdateCountryDrawer }) => {
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Holiday Date",
      render: (record) => {
        return record.date ? moment(record.date).format("YYYY-MM-DD") : "----";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a
          className="action-btn action-btn-add"
          onClick={(e) => showAddUpdateCountryDrawer(e, record)}
        >
          Edit Holiday Event
        </a>
      ),
    },
  ];
  const pageSize = 10;
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [holidayData, setHolidayData] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const { holidayPaginationResponse } = useSelector((state) => state.holiday);
  useEffect(() => {
    getData();
  }, [searchKeyword]);
  useEffect(() => {
    if (!holidayPaginationResponse) return;
    setHolidayData(holidayPaginationResponse?.items);
    setTotalCount(holidayPaginationResponse.totalCount);
  }, [holidayPaginationResponse]);

  const getData = (MaxResultCount = pageSize, SkipCount = 0, Sorting) => {
    dispatch(
      holidayEventsPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
      })
    );
  };
  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;
    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };
  return (
    <>
      <Table
        dataSource={holidayData}
        className="organizer__tables"
        columns={columns}
        onChange={handleTableChange}
        rowKey="appUserId"
        pagination={{
          total: totalCount,
          pageSize: pageSize,
        }}
      />
    </>
  );
};

export default HolidayEventAddUpdateTable;
