import React, { useState, useEffect, useDis } from "react";
import {
  Button,
  Drawer,
  Layout,
  Input,
  Space,
  Form,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Select,
  message,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { AddUpdateGuestEntertainment } from "../AddUpdateGuestEntertainment";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { addProcurement } from "services/redux/requistions/procurement/action";
import { getEmployeeSisterCompanyListing } from "services/redux/common/employeeSisterCompany/action";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import ImageUploaderMultiple from "components/shared/FileUploaderApproval";
import { dynamicFileLabelTypeUploaderDetailDtos } from "services/remanentCalls";
const { Header } = Layout;

export const AddUpdateDrawerProcrument = ({
  drawerVisibility,
  onAddUpdateDrawerClose,
  isUpdateApplication,
  addProcurementResp,
  addProcurementRespLoading,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);

  const [uploaders, setUploaders] = useState();

  useEffect(() => {
    fetchDynamicFileLabelTypeUploaderDetailDtos();
  }, []);

  const fetchDynamicFileLabelTypeUploaderDetailDtos = async () => {
    const resp = await dynamicFileLabelTypeUploaderDetailDtos("Leave").catch(
      (err) => {
        message.error("Failure");
      }
    );
    if (resp && resp.status === 200) {
      let uploaders = resp.data.filter(
        (respData) => respData.code === "LEAVEATTACHMENT"
      );
      setUploaders(uploaders);
    }
  };

  const onFinish = (values) => {
    form.validateFields();
    let body = {
      ...values,
      procurementApplicationStatusId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    };
    dispatch(addProcurement(body));
  };
  useEffect(() => {
    dispatch(getSisterCompaniesListings());
  }, []);

  useEffect(() => {
    if (!isEmpty(addProcurementResp)) {
      form.resetFields();
      onAddUpdateDrawerClose();
    }
  }, [addProcurementResp]);

  return (
    <div>
      <Drawer
        width={472}
        maskClosable={false}
        onClose={onAddUpdateDrawerClose}
        visible={drawerVisibility}
        closable={false}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">Procrument</h4>
        </Header>
        <div className="drawer__content">
          <div className="drawer__content--wrapper">
            <div className="contact__content__wrapper">
              <Form
                name="dynamic_form_nest_item"
                className="drawer-form-wrapper"
                onFinish={onFinish}
                form={form}
              >
                <div className="drawer-form-row">
                  <div className="drawer-form-group">
                    <Form.Item
                      className="form__group"
                      label="On Account of"
                      name="sisterCompanyId"
                      required="true"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Select placeholder="Select Company" autoComplete="off">
                        {!isEmpty(sisterCompanyListing) &&
                          sisterCompanyListing.map((company, index) => {
                            return (
                              <Option value={company.id} key={index}>
                                {company.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="drawer-form-group">
                    <Form.Item
                      className="form__group"
                      label="Subject"
                      name="subject"
                      required="true"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                  </div>
                  <div className="drawer-form-group">
                    <Form.Item
                      className="form__group"
                      label="Description"
                      name="description"
                      required="true"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Input.TextArea autoComplete="off" />
                    </Form.Item>
                  </div>
                  <div className="drawer-form-group">
                    <Form.Item
                      className="form__group"
                      label="Amount"
                      name="tentativeAmount"
                    >
                      <Input autoComplete="off" type="number" min={0} />
                    </Form.Item>
                  </div>
                  <div className="drawer-form-group">
                    <Form.Item
                      className="form__group"
                      label="Vendor"
                      name="vendorName"
                    >
                      <Input.TextArea autoComplete="off" />
                    </Form.Item>
                  </div>
                  <div className="drawer-form-group upload_file_loan">
                    {uploaders?.map((u, i) => (
                      <Form.Item
                        className="form__group"
                        name={["uploadedFileDtos"]}
                      >
                        <ImageUploaderMultiple
                          name={u.name}
                          isMultipleFileAllowed={true}
                          id={u.id}
                          uploadedFileDtos={form.getFieldValue()}
                          formName="uploadedFileDtos"
                          form={form}
                          callback={(e) => {
                            let olduploadedFileDtos = form.getFieldValue()
                              .uploadedFileDtos
                              ? [...form.getFieldValue().uploadedFileDtos]
                              : [];

                            let uploadedFileDtos = [...olduploadedFileDtos];
                            uploadedFileDtos.push(e);
                            // uploadedFileDtos[i] = e;

                            form.setFieldsValue({
                              uploadedFileDtos,
                            });
                          }}
                        />
                      </Form.Item>
                    ))}
                  </div>
                  <div className="drawer-form-group">
                    <Form.Item
                      className="form__group"
                      label="Rate Reasonability"
                      name="rateReasonableComment"
                    >
                      <Input.TextArea autoComplete="off" />
                    </Form.Item>
                  </div>
                </div>
                <div className="model-footer">
                  <Button
                    onClick={() => {
                      onAddUpdateDrawerClose();
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={addProcurementRespLoading}
                  >
                    Send Request to Accounts
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
