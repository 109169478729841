import React from "react";
import { Drawer, Tabs, Collapse, Row, Col, Steps, Skeleton } from "antd";
import { isEmpty } from "lodash-es";
import moment from "moment";

const ReviewTemplateTablePresentation = ({
  openProfileDrawer,
  closeProfileDrawer,
  data,
}) => {
  // const filterUserForm = data?.employeeEvaluationParticipantDetailsDTOs.filter(
  //   (participant) => participant.participantType === 1
  // );

  if (!data || !data.employeeEvaluationParticipantDetailsDTOs) {
    // Data is not available or doesn't have the expected structure
    return <p></p>;
  }

  const filterUserForm = data.employeeEvaluationParticipantDetailsDTOs.filter(
    (participant) => participant.participantType === 1
  );

  const ProfileReviewPresentationComponent = () => {
    return (
      <>
        <Drawer
          title="Profile Review"
          placement="right"
          visible={openProfileDrawer}
          onClose={closeProfileDrawer}
          width={720}
          className="approval-form-wrapper"
        >
          <Steps
            current={
              data
                ? data?.employeeEvaluationStatus?.displayName === "Apply"
                  ? 1
                  : data?.employeeEvaluationStatus?.displayName ===
                    "SupervisorApproved"
                  ? 2
                  : 3
                : 1
            }
          >
            <Steps.Step title="Request" />
            <Steps.Step title="Reviewed" />
            <Steps.Step title="Complete" />
          </Steps>
          <Collapse accordion>
            <div className="mail-leave-details">
              <div className="leave-details">
                <Row gutter={20}>
                  <Col lg={12} className="leave-col">
                    <div className="leave-input">Name</div>
                    <div className="leave-input">Job Title</div>
                    <div className="leave-input">Department</div>
                    <div className="leave-input">Username</div>
                    <div className="leave-input">Date</div>
                    <div className="leave-input">Supervisor</div>
                    <div className="leave-input">Review Date (in days)</div>
                  </Col>
                  <Col lg={12} className="leave-col">
                    <div className="leave-input">{data?.name}</div>
                    <div className="leave-input">{data?.jobTitle}</div>
                    <div className="leave-input">{data?.department}</div>
                    <div className="leave-input">{data?.employeeInitials}</div>
                    <div className="leave-input">{moment(data?.date).format('YYYY-MM-DD')}</div>
                    <div className="leave-input">{data?.immidiateSuperior.join(', ')}</div>
                    <div className="leave-input">{data?.reviewPeriod}</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Collapse>
          <Tabs type="card" style={{ margin: "10px 10px" }}>
            {filterUserForm?.map((tab, tabIndex) => (
              <Tabs.TabPane
                tab={`${tab?.participantUser?.username} (supervisor)`}
                key={String(tabIndex + 1)}
              >
                <div className="mail-leave-details">
                  <div className="leave-details" style={{ margin: "0px 0px" }}>
                    <Row gutter={20}>
                      <Col lg={12} className="leave-col">
                        <div className="leave-title">Name</div>
                        {/* <div className="leave-input">Job Knowledge</div>
                        <div className="leave-input">Work Quality</div>
                        <div className="leave-input">Attendance</div>
                        <div className="leave-input">Initiative</div>
                        <div className="leave-input">Communication</div>
                        <div className="leave-input">Dependability</div> */}
                        <div className="leave-input">Job Understanding</div>
                        <div className="leave-input">Job Skills</div>
                        <div className="leave-input">Growth</div>
                        <div className="leave-input">Performance</div>
                        <div className="leave-input">Accountable</div>
                        <div className="leave-input">Commitment</div>
                        <div className="leave-input">Integrity</div>
                        <div className="leave-input">Collaboration</div>
                        <div className="leave-input">Attendance</div>
                        <div className="leave-input">Communication Skills</div>
                      </Col>
                      <Col lg={6} className="leave-col">
                        <div className="leave-title">Rating Number</div>
                        <div className="leave-input">
                          {tab?.jobKnowledge?.number}
                        </div>
                        <div className="leave-input">
                          {tab?.jobSkills?.number}
                        </div>
                        <div className="leave-input">
                          {tab?.growth?.number}
                        </div>
                        <div className="leave-input">
                          {tab?.performance?.number}
                        </div>
                        <div className="leave-input">
                          {tab?.accountable?.number}
                        </div>
                        <div className="leave-input">
                          {tab?.commitment?.number}
                        </div>
                        <div className="leave-input">
                          {tab?.integrity?.number}
                        </div>
                        <div className="leave-input">
                          {tab?.collaboration?.number}
                        </div>
                        <div className="leave-input">
                          {tab?.punctuality?.number}
                        </div>
                        <div className="leave-input">
                          {tab?.communication?.number}
                        </div>
                      </Col>
                      <Col lg={6} className="leave-col">
                        <div className="leave-title">Comments</div>
                        <div className="leave-input">
                          {tab?.jobKnowledge?.comments}
                        </div>
                        <div className="leave-input">
                          {tab?.jobSkills?.comments}
                        </div>
                        <div className="leave-input">
                          {tab?.growth?.comments}
                        </div>
                        <div className="leave-input">
                          {tab?.performance?.comments}
                        </div>
                        <div className="leave-input">
                          {tab?.accountable?.comments}
                        </div>
                        <div className="leave-input">
                          {tab?.commitment?.comments}
                        </div>
                        <div className="leave-input">
                          {tab?.integrity?.comments}
                        </div>
                        <div className="leave-input">
                          {tab?.collaboration?.comments}
                        </div>
                        <div className="leave-input">
                          {tab?.punctuality?.comments}
                        </div>
                        <div className="leave-input">
                          {tab?.communication?.comments}
                        </div>
                      </Col>
                      <Col lg={24} className="leave-col">
                        <div className="leave-title">Additional Comments</div>
                        <div className="leave-input">
                          {tab?.additionalComments}
                        </div>
                        <div className="leave-title">Goals</div>
                        <div className="leave-input">{tab?.newGoal}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Drawer>
      </>
    );
  };

  return data ? ProfileReviewPresentationComponent() : <Skeleton active />;
};

export default ReviewTemplateTablePresentation;
