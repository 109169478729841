import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { actionForAddUpdateCEADPaymentReceivedAddUpdateInformation } from "services/redux/cead/action";

const { Text, Title } = Typography;
const { Item, List } = Form;

export default function DemoForm({
  currencyOptions,
  formFeeReceived,
  singleTenderCeadData,
}) {
  const dispatch = useDispatch();

  const handleExRateChange = (index) => (e) => {
    const items =
      formFeeReceived?.getFieldsValue().tenderPaymentReceivedInformationDtos;

    const exchangeRate =
      e.target.value ||
      formFeeReceived?.getFieldsValue()?.tenderPaymentReceivedInformationDtos?.[
        index
      ]?.exchangeRate;
    items[index].equivalentNC = items[index].receivedAmount * exchangeRate;

    formFeeReceived.setFieldsValue({
      tenderPaymentReceivedInformationDtos: items,
    });
  };

  const onFinishFeeReceivedLogForm = (values) => {
    dispatch(
      actionForAddUpdateCEADPaymentReceivedAddUpdateInformation(
        values,
        singleTenderCeadData?.id
        // "CEADFinancialType=2"
      )
    );
  };

  return (
    <Card>
      <Form
        labelCol={{ span: 3 }}
        form={formFeeReceived}
        onFinish={onFinishFeeReceivedLogForm}
      >
        <Row>
          <Col span={24}>
            <Item label="Paid On" name="paymentRecivedDate">
              <DatePicker style={{ width: "250px", height: "30px" }} />
            </Item>
          </Col>

          <Col span={24}>
            <Item label="Of Invoice No." name="ofInvoiceNumber">
              {/* <DatePicker style={{ width: "250px", height: "30px" }} /> */}
              <Input style={{ width: "250px", height: "30px" }} />
            </Item>
          </Col>

          <List name="tenderPaymentReceivedInformationDtos">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={field.key} gutter={[10, 0]}>
                    <Col span={1}>{index + 1}</Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="Currency"
                        name={[field.name, "currencyId"]}
                        fieldKey={[field.fieldKey, "currencyId"]}
                        rules={[
                          {
                            required: true,
                            message: "Currency is required",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select
                          options={currencyOptions}
                          optionFilterProp="label"
                          placeholder="Select"
                          allowClear
                        />
                      </Item>
                    </Col>

                    <Col span={4}>
                      <Item
                        key={field.key}
                        label="Received Amt"
                        name={[field.name, "receivedAmount"]}
                        fieldKey={[field.fieldKey, "receivedAmount"]}
                        rules={[
                          {
                            required: true,
                            message: "Received Amt is required",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter ..." />
                      </Item>
                    </Col>

                    <Col span={3}>
                      <Item
                        key={field.key}
                        label="Ex. Rate"
                        name={[field.name, "exchangeRate"]}
                        fieldKey={[field.fieldKey, "exchangeRate"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Enter ..."
                          onChange={handleExRateChange(index)}
                        />
                      </Item>
                    </Col>

                    <Col span={3}>
                      <Item
                        key={field.key}
                        label="EQ NC"
                        name={[field.name, "equivalentNC"]}
                        fieldKey={[field.fieldKey, "equivalentNC"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter ..." />
                      </Item>
                    </Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        // label="Vat Amt"
                        label="Vat %"
                        name={[field.name, "vatPercentage"]}
                        fieldKey={[field.fieldKey, "vatPercentage"]}
                        rules={[
                          {
                            required: true,
                            message: "Vat % is required",
                            // message: "Vat Amt is required",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter ..." />
                      </Item>
                    </Col>

                    <Col span={3}>
                      <Item
                        key={field.key}
                        label="Bank Charges"
                        name={[field.name, "bankCharge"]}
                        fieldKey={[field.fieldKey, "bankCharge"]}
                        rules={[
                          {
                            required: true,
                            message: "Bank Charges is required",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter ..." />
                      </Item>
                    </Col>

                    <Col span={24}>
                      {index >= 0 && (
                        <Button
                          style={{ float: "right", marginTop: "-12px" }}
                          icon={<MinusCircleOutlined />}
                          onClick={() => remove(field.name)}
                        />
                      )}
                    </Col>
                  </Row>
                ))}
                <Button
                  style={{ marginBottom: "16px" }}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusCircleOutlined />}
                >
                  Add Variation
                </Button>
              </>
            )}
          </List>

          <Col span={24}>
            <Item label="Remarks" name="remarks">
              <Input.TextArea />
            </Item>
          </Col>
        </Row>

        <Button
          type="primary"
          htmlType="submit"
          style={{ float: "right", marginTop: "5px" }}
        >
          Save
        </Button>
      </Form>
    </Card>
  );
}
