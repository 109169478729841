import React from "react";

const pdfViewer = (url) => {
  fetch(url, {
    headers: {
      responseType: "blob",
    },
  })
    .then((res) => res.blob())
    .then((file) => {
      window.open(URL.createObjectURL(file));
    });
};

export default pdfViewer;
