import {
  EditOutlined,
  MailOutlined,
  GlobalOutlined,
  DeleteOutlined,
  PlusCircleTwoTone,
  EditTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Radio,
  Popconfirm,
  Spin,
  Skeleton,
  Checkbox,
  Tabs,
} from "antd";
import CommunicatonChannel from "components/communicationChannel";
import { debounce, isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState, useRef } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  ReferenceCompanyManualAdd,
  referencePartyAction,
  referencePartyListing,
  referencePartyRemarksHistoryAdd,
  referencePartyRemarksAddSolo,
  updateReferenceCompany,
  ReferenceRemarksCompanyAdd,
  actionForReferencePartyListingCircular,
  actionForReferenceCircularPlanningAdd,
  actionForReferenceCircularPlanningUpdate,
  actionForReferenceCompanyDelete,
  actionForGetAllCircularPlanningTemplate,
  actionForAddCompanyOrContactPersonEmailAddById,
  actionForCircularPlanningLog,
} from "services/redux/Reference/action";
import {
  getContactCompanyPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";
import {
  contactCompanyGetDetails,
  contactPersonGetDetails,
} from "services/redux/contact/companypeople/action";
import {
  actionForGetReferenceTemplateGetDetailsById,
  actionForAddUpdateReferenceTemplate,
  actionForPersistenTenderContent,
} from "services/redux/Reference/Tender/action";
import {
  actionForGetTenderCircularMail,
  persistSelectedTenderModule,
} from "services/redux/circularMail/action";
import { actionForPersistTenderMailData } from "./persistTender/action";
import { getCompanyorPersonEmailAddess } from "services/redux/contact/contact.action";
import { getCompanyPersonEmailAddress } from "services/redux/email/messageCenterType/action";
import AddCompany from "./AddCompany";
import useRoles from "hooks/useRoles";
import QuickAddCompany from "views/Contact/ContactCompanyPerson/AddQuickCompany";
import CircularMail from "components/internalMNew/CircularMail";
import QuickAddPerson from "components/modals/quickAddPerson";
import HTMLRendererComponent from "components/HTMLRenderer";
import CheckListContainer from "components/CheckList/CheckListContainer";
import CircularIndex from "components/Circular";
import CircularTemplate from "components/modals/circularTemplate/circularTemplate";
import IconButtonWithTooltip from "components/AddCompany/IconButtonWithTooltip";
import QuickAddCompanyEmailModal from "components/AddCompany/QuickAddCompanyEmailModal";
import LogList from "./LogList";
import LogScroll from "./Log";

const Companies = ({
  searchEmailTender,
  rowEventhandlers,
  companyId,
  emailReferenceId,
  getDetails,
  selectedTenderRowId,
  isCircular,
  showProjectTab,
  selectedBusinessTabPaneKey,
  selectedCompanyAttentionToIds,
}) => {
  const dispatch = useDispatch();

  const {
    referencePartyListingResponse,
    referencePartyListingLoading,
    updateCompanyReferenceResponse,
    updateCompanyReferenceLoading,
    referncePartyActionResponse,
    referncePartyActionLoading,
    referencePartyHistoryAddResponse,
    referencePartyHistoryAddLoading,
    referencePartyRemarksHistoryListingResponse,
    referencePartyRemarksHistoryListingLoading,
    referenceCompanyManualAddResponse,
    referenceCompanyManualAddLoading,
    referenceListingResponse,
    referenceListingLoading,
    referencePartyListingCircularResponse,
    referencePartyListingCircularLoading,
    allCircularPlanningTemplateResp,
    allCircularPlanningTemplateLoading,
    getCircularPlanningResp,
    getCircularPlanningLoading,
  } = useSelector((state) => state.reference);

  const {
    contactQuickAddEmailRespsonse,
    contactPersonGetDetailsResponse,
    contactCompanyGetDetailsResponse,
    allPersonListingResponse,
    contactPersonSearchListingResponse,
    contactCompanyPersonSearchPaginationLoading,
    contactQuickAddEmailLoading,
    contactCompanyPaginationResponse,
    contactCompanyPaginationLoading,
    contactPersonPaginationResponse,
    contactPersonPaginationLoading,
    quickContactCompanyAdd,
    quickContactCompanyLoader,
    quickPersonAddResponse,
  } = useSelector((state) => state.contactCompanyPerson);

  const {
    tenderCircularPlanningCheckListResponse,
    tenderCircularPlanningCheckListLoading,
    singleCircularReferenceTemplateResponse,
    singleCircularReferenceTemplateLoading,
  } = useSelector((state) => state.tender);

  const { contactCompanyPersonMailResponse, contactCompanyPersonMailLoading } =
    useSelector((state) => state.contact);

  const { instanceChatAddResp, instanceChatAddRespLoading } = useSelector(
    (state) => state.instanceChatCenter
  );

  const { isLimitedRoleAdmin, isTenderCompanyAdmin } = useRoles();

  const { TextArea } = Input;
  const { TabPane } = Tabs;
  const { Option } = Select;
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const history = useHistory();
  const linkRef = useRef(null);

  const [form] = Form.useForm();
  const [addCompanyModelForm] = Form.useForm();
  const [formRemark] = Form.useForm();
  const [formPriority] = Form.useForm();
  const [formQuickAddCompany] = Form.useForm();
  const [formQuickAddPerson] = Form.useForm();
  const [formCircularEdit] = Form.useForm();
  const pageSize = 110;
  const [list, setList] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [remarksHistory, setRemarksHistory] = useState([]);
  const [contactPerson, setContactPerson] = useState("");
  const [companyDetails, setCompanyDetails] = useState("");
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [SearchKeyword, setSearchKeyWord] = useState("");
  const [searchKeywordContactPerson, setSearchKeywordContactPerson] =
    useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddCompanyModelOpen, setIsAddCompanyModelOpen] = useState(false);
  const [modalRemark, setModalRemark] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [company, setCompany] = useState();
  const [addCompany, setAddCompany] = useState(false);
  const [valueRadioAddCompany, setValueRadioAddCompany] = useState("1");
  const [formCompanyId, setFormCompanyId] = useState("");
  const [value, setValue] = useState("");
  const [visibleQuickModal, setVisibleQuickModal] = useState(false);

  // added
  const [isCircularModalOpen, setIsCircularModalOpen] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isCircularAddEditModalOpen, setIsCircularAddEditModalOpen] =
    useState(false);
  const [circularAddEdit, setCircularAddEdit] = useState({
    add: false,
    edit: false,
    data: {},
  });
  const [templateData, setTemplateData] = useState([]);
  const [isPersonModalVisible, setIsPersonModalVisible] = useState(false);
  const [data, setData] = useState([]);
  //
  const [appType, setAppType] = useState("");
  const [activeKey, setActiveKey] = useState("1");

  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [selectedPartyName, setSelectedPartyName] = useState("");

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [checkListData, setCheckListData] = useState([]);

  const [circularContentData, setCircularContentData] = useState([]);
  const [circularContentDataId, setCircularContentDataId] = useState("");
  const [editorData, setEditorData] = useState("");
  const [lastModifiedLog, setLastModifiedLog] = useState("");
  const [modifier, setModifierName] = useState("");

  const [isAddNewPersonStatus, setisAddNewPersonStatus] = useState(false);

  const [isCircularEditVisible, setIsCircularEditVisible] = useState(false);
  // added
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterPriority, setFilterPriority] = useState("all");
  const [selectedCompanyIds, setSelectedCompanyIds] = useState({
    tenderId: "",
    companyIds: [],
  });
  const [isLoadingOpeningMail, setIsLoadingOpeningMail] = useState(false);
  // new add mail
  const [isModalNewMailOpen, setIsModalNewMailOpen] = useState(false);
  const [isLoadingNewMail, setIsLoadingNewMail] = useState(false);

  const handleCheckboxChange = (record) => {
    const isChecked = checkListData.includes(record.key);
    const updatedCheckedItems = isChecked
      ? checkListData.filter((item) => item !== record.key)
      : [...checkListData, record.key];

    setCheckListData(updatedCheckedItems);
  };

  const closeEditTemplateModal = () => {
    setModalVisible(false);
  };

  const handleOpenModal = (record) => {
    setSelectedItem(record);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setModalVisible(false);
    setCircularContentData(null);
    setCircularContentDataId(null);
    setEditorData("");
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
    let payload = {
      ReferenceId: getDetails?.referenceId || "",
    };
    // if (key === "4" && payload.ReferenceId) {
    //   dispatch(actionForCircularPlanningLog(payload));
    // }
  };

  const handleQuickAddCompanyModal = useCallback(() => {
    dispatch(
      getContactPersonPagination({
        CompanyId: selectedCompanyId || form.getFieldValue().CompanyId,
      })
    );
    formQuickAddCompany.resetFields();
    setVisibleQuickModal(false);
  }, [formQuickAddCompany]);

  function info(type) {
    setAppType(type);
    // setOpenLink(true);
    // setComposeIfReference(type);
  }

  // Circular Index
  // useEffect(() => {
  //   if (modalVisible === true && getDetails?.referenceId)
  //     dispatch(
  //       actionForGetReferenceTemplateGetDetailsById(getDetails?.referenceId)
  //     );
  // }, [modalVisible]);

  useEffect(() => {
    const { content, id, lastModifictaionTime, lastModifierName } =
      singleCircularReferenceTemplateResponse || {};

    if (id !== "00000000-0000-0000-0000-000000000000") {
      setCircularContentData(content);
      setCircularContentDataId(id);
      setEditorData(content);
      setLastModifiedLog(lastModifictaionTime);
      setModifierName(lastModifierName);
    } else {
      setCircularContentDataId(null);
      setCircularContentData(content);
      setEditorData(content);
      setLastModifiedLog(lastModifictaionTime);
      setModifierName(lastModifierName);
    }
  }, [singleCircularReferenceTemplateResponse]);

  useEffect(() => {
    if (!isEmpty(contactCompanyGetDetailsResponse)) {
      const CountryDetails =
        contactCompanyGetDetailsResponse?.contactCompanyGroupAddressDtos
          ? contactCompanyGetDetailsResponse?.contactCompanyGroupAddressDtos?.find(
              (x) => x.isPrimaryAddress === true
            )
          : null;

      formQuickAddPerson?.setFieldsValue({
        CompanyCountry: CountryDetails?.countryId,
        // CompanyPersonAdd: CountryDetails?.contactCompanyId,
      });
    }
  }, [contactCompanyGetDetailsResponse]);

  useEffect(() => {
    if (contactPersonPaginationResponse)
      setContactPerson(contactPersonPaginationResponse?.items);
  }, [contactPersonPaginationResponse]);

  useEffect(() => {
    if (contactCompanyGetDetailsResponse)
      setCompanyDetails(contactCompanyGetDetailsResponse);
  }, [contactCompanyGetDetailsResponse]);

  useEffect(() => {
    if (searchEmailTender) {
      if (isCircular) {
        dispatch(
          actionForReferencePartyListingCircular({
            referenceCode: searchEmailTender,
          })
        );
      } else {
        dispatch(referencePartyListing({ referenceCode: searchEmailTender }));
      }
    }
  }, [searchEmailTender, isCircular]);

  useEffect(() => {
    if (referencePartyListingResponse) {
      setList(referencePartyListingResponse);
    }
  }, [referencePartyListingResponse]);

  // added for cirular planning
  useEffect(() => {
    if (referencePartyListingCircularResponse) {
      setList(referencePartyListingCircularResponse);
    }
  }, [referencePartyListingCircularResponse]);

  useEffect(() => {
    if (!referencePartyHistoryAddLoading) {
      setModalRemark(false);
      formRemark.resetFields();
    }
  }, [referencePartyHistoryAddLoading]);

  useEffect(() => {
    if (!isEmpty(referenceCompanyManualAddResponse)) {
      setTimeout(() => {
        form.resetFields();
        setShowAddCompany(false);
      }, 1000);
    }
  }, [referenceCompanyManualAddResponse]);

  useEffect(() => {
    setActiveKey("1");
    setCompanyDetails();
    setContactPerson();
    setRemarksHistory();
  }, [selectedTenderRowId]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (isAddNewPersonStatus) {
  //         await dispatch(
  //           getContactPersonPagination({ CompanyId: selectedCompanyId })
  //         );
  //       }
  //     } catch (err) {
  //       console.error("Error fetching contact persons:", err);
  //     } finally {
  //       setisAddNewPersonStatus(false);
  //     }

  //     // SET THE DATA
  //     const newData = (contactPersonPaginationResponse?.items || []).map(
  //       (x) => ({
  //         label: x.personFullName,
  //         value: x.contactPersonId,
  //       })
  //     );
  //     setData(newData);
  //   };
  //   fetchData();
  // }, [contactPersonPaginationResponse, isAddNewPersonStatus]);
  useEffect(() => {
    if (contactPersonPaginationResponse) {
      const newData = (contactPersonPaginationResponse?.items || []).map(
        (x) => ({
          label: x.personFullName,
          value: x.contactPersonId,
        })
      );

      setData(newData);
    }
  }, [contactPersonPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(quickPersonAddResponse)) {
      const newPersonData = {
        label:
          quickPersonAddResponse.firstName + quickPersonAddResponse.lastName,
        value: quickPersonAddResponse.id,
      };

      // Checking if newPersonData already exists in data
      const isAlreadyAdded = data.some(
        (item) => item.value === newPersonData.value
      );

      if (!isAlreadyAdded) setData((prevData) => [...prevData, newPersonData]);
    }
  }, [quickPersonAddResponse]);

  useEffect(() => {
    if (referencePartyRemarksHistoryListingResponse) {
      setRemarksHistory(referencePartyRemarksHistoryListingResponse);
    }
  }, [referencePartyRemarksHistoryListingResponse]);

  useEffect(() => {
    if (isEmpty(SearchKeyword)) return;
    const timeoutId = setTimeout(() => getData(), 800);
    return () => clearTimeout(timeoutId);
  }, [SearchKeyword]);

  // getAllCirularPlanning
  useEffect(() => {
    if (allCircularPlanningTemplateResp) {
      setTemplateData(allCircularPlanningTemplateResp);
    }
  }, [allCircularPlanningTemplateResp]);

  // added bishow
  useEffect(() => {
    setRemarksHistory(null);
    setCompanyDetails(null);
    setContactPerson(null);
  }, [selectedBusinessTabPaneKey]);

  function actionChangeHandler(checked, actionType, rowId) {
    dispatch(
      referencePartyAction(
        rowId,
        {
          actionType: checked,
          actionOf: actionType,
        },
        searchEmailTender
      )
    );
  }

  function actionInterestedHandler(checked, actionOf, rowId) {
    dispatch(
      referencePartyAction(
        rowId,
        {
          actionType: checked,
          actionOf: actionOf,
        },
        searchEmailTender
      )
    );
  }

  const handleTenderMail = async () => {
    // console.log("getDetails: ",getDetails?.title)

    let data = {
      tos: referencePartyListingCircularResponse ?? "",
      refCode: getDetails?.referenceCode ?? "",
      subject: getDetails?.title ?? "",
    };
    // dispatch(actionForPersistTenderMailData(data));
    //
    setIsLoadingOpeningMail(true);
    try {
      let id = getDetails?.referenceId;
      await dispatch(
        getCompanyPersonEmailAddress(id, selectedCompanyIds?.companyIds)
      );

      // persist
      // await dispatch(actionForGetReferenceTemplateGetDetailsById(id));
      await dispatch(actionForPersistenTenderContent(id));

      // Wait for 5 seconds
      // setTimeout(() => {
      //   window.open("/#/email-compose?applicationType=tenderModule", "_blank");
      //   setIsLoadingOpeningMail(false);
      // }, 5000);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      window.open("/#/email-compose?applicationType=tenderModule", "_blank");
    } catch (err) {
      console.log(err);
      setIsLoadingOpeningMail(false);
    } finally {
      setIsLoadingOpeningMail(false);
    }
  };

  const handleUpdateCompany = () => {
    dispatch(updateReferenceCompany({ referenceCode: searchEmailTender }));
  };

  const getCicularTemplate = () => {
    try {
      dispatch(actionForGetAllCircularPlanningTemplate());
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const companyRemarks = (e) => {
    setRemarks(e.target.value);
  };

  const handleAddCompany = () => {
    setShowAddCompany(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    if (remarks) {
      dispatch(
        referencePartyRemarksHistoryAdd({
          contactCompanyId: companyId,
          remarks: remarks,
        })
      );

      setRemarks("");
    }
  };
  const handleNotOk = () => {
    setIsModalVisible(false);
    if (remarks) {
      dispatch(
        referencePartyListing({
          referenceCode: searchEmailTender,
        })
      );
      setRemarks("");
    }
  };

  const handleDeleteRecord = (values) => {
    if (values?.contactCompanyId) {
      dispatch(actionForReferenceCompanyDelete(values?.id));
    }
  };

  // Edit
  const handleCircularEdit = useCallback(
    async (record) => {
      if (!record) return;

      const attentionToArray = JSON.parse(record?.attentionTo || "[]");
      let mailAddressss = JSON.parse(record?.emailTo || "[]");

      setSelectedAttendees(attentionToArray);
      // setSelectedCompanyId(record?.contactCompanyId);
      formCircularEdit.setFieldsValue({
        CompanyName: record?.partyName || "",
        contactCompanyId: record?.contactCompanyId || "",
        attendees:
          (attentionToArray &&
            attentionToArray.map((item) => item.AttentionTo)) ||
          [],
        mailTo: mailAddressss || [],
        priority: record?.priority || "",
      });
      setIsCircularEditVisible(true);

      let selectedPersonIds =
        attentionToArray &&
        attentionToArray?.map((item) => ({
          contactPersonIds: item?.AttentionId,
        }));

      if (record?.contactCompanyId) {
        try {
          await Promise.all([
            dispatch(
              getContactPersonPagination({
                CompanyId: record.contactCompanyId,
              })
            ),
            dispatch(
              getCompanyorPersonEmailAddess(
                selectedPersonIds,
                record.contactCompanyId
              )
            ),
          ]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }

      switch (record?.modeofCommunication) {
        case "Email":
          setValueRadioAddCompany("1");
          break;
        case "Facebook":
          setValueRadioAddCompany("2");
          break;
        case "Whatsapp":
          setValueRadioAddCompany("3");
          break;
        case "Twitter":
          setValueRadioAddCompany("4");
          break;
        case "Other":
          setValueRadioAddCompany("5");
          break;
        default:
          setValueRadioAddCompany("1");
      }
    },
    [dispatch, formCircularEdit]
  );

  // Submit
  const onFinishCircularEdit = (values) => {
    let remarksData = {
      remarks:
        valueRadioAddCompany === "1"
          ? "Email"
          : valueRadioAddCompany === "2"
          ? "Facebook"
          : valueRadioAddCompany === "3"
          ? "Whatsapp"
          : valueRadioAddCompany === "4"
          ? "Twitter"
          : valueRadioAddCompany === "5"
          ? "Other"
          : "1",
    };

    const payload = {
      ModeofCommunication: remarksData?.remarks,
      attentionTo: JSON.stringify(selectedAttendees),
      emailTo: JSON.stringify(values?.mailTo),
      priority: values?.priority || "",
    };

    const url = {
      ReferenceId: emailReferenceId,
      CompanyId: formCircularEdit?.getFieldValue("contactCompanyId"),
    };

    try {
      dispatch(actionForReferenceCircularPlanningUpdate(payload, url));
    } catch (err) {
      console.log(err);
    } finally {
      setIsCircularEditVisible(false);
      formCircularEdit.resetFields();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddCompanyModal = () => {
    setIsAddCompanyModelOpen(false);
    setFormCompanyId(null);
    setSelectedCompanyId(null);

    // reset the Attention to selected:
    addCompanyModelForm.resetFields();
  };

  const handleQuickAddPersonModal = useCallback(() => {
    setIsPersonModalVisible(false);
    formQuickAddPerson.resetFields();
  }, [formQuickAddPerson]);

  const handleCircularModal = () => {
    setIsCircularModalOpen(false);
    // setHideInternalMessage(true);
  };

  const handleTemplateModal = () => {
    setIsTemplateModalOpen(false);
    setIsCircularAddEditModalOpen(false);
  };

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    // Sorting,
    // SortType,
    // SearchKeyword,
    ForPagination = false
  ) => {
    dispatch(
      getContactCompanyPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: SearchKeyword,
        },
        ForPagination
      )
    );
  };

  const cols = [
    {
      title: "S.NO",
      dataIndex: "",
      key: "",
      render: (text, row, index) => (
        <div style={{ paddingLeft: 30 }}>{index + 1}</div>
      ),
    },
    {
      title: "Person Name",
      key: "personFullName",
      render: (record) => {
        // return record.personFullName == " "
        //   ? "No Person"
        //   : record.personFullName;
        const isAttention =
          selectedCompanyAttentionToIds &&
          selectedCompanyAttentionToIds.includes(record?.contactPersonId);

        return (
          <span style={{ color: isAttention ? "red" : "inherit" }}>
            {record.personFullName || "No Person"}
          </span>
        );
      },
    },
    // {
    //   title: "Nick name",
    //   dataIndex: "personNickName",
    //   render: (record) => {
    //     return record ? record.personNickName : null;
    //   },
    // },
    // {
    //   title: "Designation",
    //   dataIndex: "designation",
    // },
    {
      title: "Contact",
      key: "contactNumber",
      render: (record) => {
        if (Array.isArray(record?.contactNumber)) {
          return (
            <div>
              {record?.contactNumber.length > 0
                ? record?.contactNumber.map((number, index) => (
                    <div key={index}>{number}</div>
                  ))
                : ""}
            </div>
          );
        } else if (typeof record?.contactNumber === "string") {
          return <div>{record?.contactNumner ?? ""}</div>;
        } else {
          return "";
        }
      },
    },
    {
      title: "Email",
      key: "emailAddress",
      render: (record) => {
        if (Array.isArray(record?.emailAddress)) {
          return (
            <div>
              {record?.emailAddress.length > 0
                ? record?.emailAddress.map((number, index) => (
                    <div key={index}>{number}</div>
                  ))
                : ""}
            </div>
          );
        } else if (typeof record?.emailAddress === "string") {
          return <div>{record?.emailAddress ?? ""}</div>;
        } else {
          return "";
        }
      },
    },
  ];

  const columns = [
    {
      title: "S.NO",
      dataIndex: "",
      width: 6,
      key: "",
      render: (text, row, index) => (
        <div style={{ paddingLeft: 5 }}>{index + 1}</div>
      ),
    },
    {
      title: "Party",
      dataIndex: "partyName",
      key: "partyName",
      width: "15%",
    },

    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: "10%",
    },
    {
      title: "Reply Status",
      // key: "callAction",
      key: "replyStatus",
      index: "replyStatus",
      render: (record) => {
        return (
          <Tooltip
            title={
              record?.lastActionDateDto?.lastReplyStatus && (
                <Moment format="YYYY-MM-DD">
                  {record?.lastActionDateDto?.lastReplyStatus}
                </Moment>
              )
            }
          >
            <Switch
              defaultChecked={record.replyStatus}
              size="small"
              onChange={(check) =>
                actionChangeHandler(check, "replyStatus", record.id)
              }
            />
          </Tooltip>
        );
      },
      width: "8%",
    },
    {
      title: "Interest Status",
      // dataIndex: "courier",
      key: "interestedAction",
      index: "interestedAction",
      render: (record) => {
        return (
          <Tooltip
            title={
              record?.lastActionDateDto?.lastInterestedAction && (
                <Moment format="YYYY-MM-DD">
                  {record?.lastActionDateDto?.lastInterestedAction}
                </Moment>
              )
            }
          >
            <Switch
              defaultChecked={record.interestedAction}
              size="small"
              onChange={(check) =>
                actionInterestedHandler(check, "interested", record.id)
              }
            />
          </Tooltip>
        );
      },
      width: "8%",
    },
    {
      title: "Tie Up Status",
      // dataIndex: "email",
      key: "emailAction",
      index: "emailAction",
      render: (record) => {
        return (
          <Tooltip
            title={
              record?.lastActionDateDto?.lastTiedStatus && (
                <Moment format="YYYY-MM-DD">
                  {record?.lastActionDateDto?.lastTiedStatus}
                </Moment>
              )
            }
          >
            <Switch
              defaultChecked={record.tiedStatus}
              size="small"
              onChange={(check) =>
                actionChangeHandler(check, "tiedStatus", record.id)
              }
            />
          </Tooltip>
        );
      },
      // width: "8%",
      width: 10,
    },
    // {
    //   title: "Courier Action",
    //   // dataIndex: "courier",
    //   key: "courierAction",

    //   render: (record) => {
    //     return (
    //       <Switch
    //         defaultChecked={record.courierAction}
    //         size="small"
    //         onChange={(check) =>
    //           actionChangeHandler(check, "courier", record.id)
    //         }
    //       />
    //     );
    //   },
    // },
    {
      title: "Priority",
      key: "priority",
      dataIndex: "priority",
      width: "4%",
    },
    {
      title: "Email Sent Date",
      key: "emailSentDate",
      width: "10%",
      render: (record) => {
        const { emailDetails } = record || {};
        const { emailSentDate, personName, emailAddress } = emailDetails || {};

        const sentDate =
          emailSentDate === "0001-01-01T00:00:00" || !emailSentDate
            ? "No Date"
            : moment(emailSentDate).format("YYYY-MM-DD");

        const tooltipTitle =
          personName || emailAddress
            ? `${personName || "Unknown Person"} - ${
                emailAddress || "Unknown Email"
              }`
            : "Details not available";

        return <Tooltip title={tooltipTitle}>{sentDate}</Tooltip>;
      },
    },
    {
      title: "Email Received Date",
      key: "emailReceivedDate",
      width: "10%",
      render: (record) => {
        const { emailDetails } = record || {};
        const { emailReceivedDate, replyByEmailAddress, replyByPersonName } =
          emailDetails || {};

        const receivedDate =
          emailReceivedDate === "0001-01-01T00:00:00" || !emailReceivedDate
            ? "No Date"
            : moment(emailReceivedDate).format("YYYY-MM-DD");

        const tooltipTitle =
          replyByEmailAddress || replyByPersonName
            ? `${replyByEmailAddress || "Unknown Email"} - ${
                replyByPersonName || "Unknown Person"
              }`
            : "Details not available";

        return <Tooltip title={tooltipTitle}>{receivedDate}</Tooltip>;
      },
    },
    {
      title: "Latest Remarks",
      key: "remarks",
      ellipsis: true,
      render: (record) => (
        <>
          <div className="latest-remark-wrapper">
            <span className="view-complain-remark">
              <Tooltip
                title={
                  record?.lastRemarksUpdateDate
                    ? moment(record?.lastRemarksUpdateDate).format(
                        "YYYY MMM DD"
                      )
                    : ""
                }
              >
                {referencePartyHistoryAddResponse?.contactCompanyId ===
                  record?.contactCompanyId &&
                getDetails?.id ==
                  referencePartyHistoryAddResponse?.tenderBasicId
                  ? referencePartyHistoryAddResponse?.remarks
                  : record.remarks}

                {/* {company?.contactCompanyId === record?.contactCompanyId &&
                getDetails?.id ==
                  referencePartyHistoryAddResponse?.tenderBasicId
                  ? referencePartyHistoryAddResponse?.remarks
                  : record.remarks} */}
              </Tooltip>
            </span>
          </div>
        </>
      ),
      width: "15%",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (record) => (
        <>
          <Space size="small">
            <PlusCircleTwoTone
              onClick={() => {
                setModalRemark(true);
                setCompany(record);
              }}
              style={{ marginRight: "8px" }}
            />

            <EditTwoTone
              onClick={() => {
                setModalEdit(true);
                setCompany(record);
                formPriority.setFieldsValue({
                  priority: record?.priority || "",
                });
              }}
            />

            {isTenderCompanyAdmin ? (
              <Popconfirm
                title="Are you sure you want to delete this item?"
                onConfirm={() => {
                  handleDeleteRecord(record);
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            ) : null}
          </Space>
        </>
      ),
      width: 10,
    },
  ];

  const col = [
    {
      title: "S.N.",
      dataIndex: "",
      key: "",
      render: (text, row, index) => (
        <div style={{ paddingLeft: 30 }}>{index + 1}</div>
      ),
    },
    {
      title: "Creator Name",
      dataIndex: "creatorName",
    },
    {
      title: "Date",
      dataIndex: "creationTime",
      key: "creationTime",

      render: (record) => (
        <>
          {/* <Moment format="YYYY/MM/DD">{record.creationTime}</Moment> */}
          <Moment format="YYYY/MM/DD HH:mm">{record}</Moment>
        </>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

  const testData = {
    emailDetails: {
      emailSentDate: "2024-08-29T12:10:32.2707626+05:45",
      emailReceivedDate: "0001-01-01T00:00:00",
      personName: "Bishow",
      emailAddress: "teestme81@gmail.com",
      replyByEmailAddress: null,
      replyByPersonName: null,
    },
  };

  const debounceDispatch = useCallback(
    debounce((selectedKeys) => {
      // console.log("dispatch", selectedKeys)
      let id = getDetails?.referenceId;
      if (id) dispatch(getCompanyPersonEmailAddress(id, selectedKeys));
    }, 2000),
    [dispatch]
  );

  const dataSourcePriority = isCircular
    ? referencePartyListingCircularResponse
    : referencePartyListingResponse;

  const handlePriorityChange = (value) => {
    setFilterPriority(value);
    // console.log("value: ", value);
    if (value === "all") {
      setSelectedRowKeys(dataSourcePriority.map((item) => item.key));
    } else {
      setSelectedRowKeys(
        dataSourcePriority
          .filter((item) => item.priority === value)
          .map((item) => item.key)
      );
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      let contactCompanyIds =
        selectedRows && selectedRows.map((row) => row.contactCompanyId);
      let id = getDetails?.referenceId;

      // UPDATED
      // let priorityOneOnly = selectedRows
      //   .filter((row) => row.priority === "1")
      //   ?.map((row) => row.id);
      // let contactCompanyIds =
      //   selectedRows &&
      //   selectedRows
      //     .filter((row) => row.priority === "1")
      //     .map((row) => row.contactCompanyId);
      // setSelectedRowKeys(priorityOneOnly);
      // END

      setSelectedRowKeys(selectedKeys);
      // debounceDispatch(contactCompanyIds);
      setSelectedCompanyIds({
        id: id,
        companyIds: contactCompanyIds,
      });
    },
    getCheckboxProps: (record) => {
      const isSelectAllChecked =
        selectedRowKeys.length ===
        referencePartyListingCircularResponse?.length;
      const priority = record.priority === "1";
      return {
        disabled: priority,
        // disabled:
        //   filterPriority !== "all" && record.priority !== filterPriority,
      };
    },
    type: "checkbox",
  };

  const testDataCircular = [
    {
      id: "068ae07f-5d1a-40e4-936e-8d91fbb8f1cb",
      contactCompanyId: "ffb446ab-7c15-4a7a-9dff-763558747128",
      partyName: "0a",
      country: "Angola",
      callAction: false,
      emailAction: false,
      courierAction: false,
      interestedAction: false,
      replyStatus: false,
      tiedStatus: true,
      lastActionDateDto: {
        lastCallAction: null,
        lastEmailAction: null,
        lastCourierAction: null,
        lastReplyStatus: null,
        lastTiedStatus: null,
        lastInterestedAction: null,
      },
      isActive: true,
      remarks: "csdcd",
      modeofCommunication: "Email",
      attentionto: null,
      creatorName: "Sajal Shrestha",
      attentionTo: "[]",
      attentionId: null,
      creatorid: "e77e1262-2c4f-a1a3-096a-3a07ba5af471",
      lastRemarksUpdateDate: "2024-10-28T15:53:21.9351507",
      isCircularPlanning: true,
      creationTime: "2024-09-05T10:40:34.4622717",
      emailDetails: {
        emailSentDate: "0001-01-01T00:00:00",
        emailReceivedDate: "0001-01-01T00:00:00",
        personName: null,
        emailAddress: null,
        replyByEmailAddress: null,
        replyByPersonName: null,
      },
      emailTo: null,
      priority: "1",
    },
    {
      id: "aedb334f-ccf4-4e02-a615-cb869c43c905",
      contactCompanyId: "05e9fe04-7317-48e6-ecb4-39fd501f6870",
      partyName: "20 Fiber j",
      country: "Albania",
      callAction: false,
      emailAction: false,
      courierAction: false,
      interestedAction: false,
      replyStatus: false,
      tiedStatus: false,
      lastActionDateDto: {
        lastCallAction: null,
        lastEmailAction: null,
        lastCourierAction: null,
        lastReplyStatus: null,
        lastTiedStatus: null,
        lastInterestedAction: null,
      },
      isActive: true,
      remarks: "",
      modeofCommunication: "Email",
      attentionto: null,
      creatorName: "Sajal Shrestha",
      attentionTo:
        '[{"AttentionTo":"Bishow Thapa","AttentionId":"c4206c37-a205-47d0-bab3-a553c3dcccbc"},{"AttentionTo":"Bijay IT Adhikari","AttentionId":"618f8381-86fc-47e5-94e3-927e808c3777"}]',
      attentionId: null,
      creatorid: "e77e1262-2c4f-a1a3-096a-3a07ba5af471",
      lastRemarksUpdateDate: null,
      isCircularPlanning: true,
      creationTime: "2024-09-05T10:40:34.4762305",
      emailDetails: {
        emailSentDate: "0001-01-01T00:00:00",
        emailReceivedDate: "0001-01-01T00:00:00",
        personName: null,
        emailAddress: null,
        replyByEmailAddress: null,
        replyByPersonName: null,
      },
      emailTo: '["shbdsh.9876@gmail.com","info@20fiber.com.cn"]',
      priority: "3",
    },
    {
      id: "6ef764e3-3dac-453a-a142-fc5d6c44941b",
      contactCompanyId: "edfdd8b7-7be1-c8b3-37ac-39fd501f685e",
      partyName: "21st Centry Unite Electric Co.,Ltd",
      country: "naya nepal",
      callAction: false,
      emailAction: false,
      courierAction: false,
      interestedAction: false,
      replyStatus: false,
      tiedStatus: false,
      lastActionDateDto: null,
      isActive: true,
      remarks: null,
      modeofCommunication: "Email",
      attentionto: null,
      creatorName: "Admin Role",
      attentionTo: "[]",
      attentionId: null,
      creatorid: "73fc9457-6b63-6a4a-64de-39f960722731",
      lastRemarksUpdateDate: "2024-10-23T09:20:04.9549024",
      isCircularPlanning: true,
      creationTime: "2024-10-23T09:20:04.3308428",
      emailDetails: {
        emailSentDate: "0001-01-01T00:00:00",
        emailReceivedDate: "0001-01-01T00:00:00",
        personName: null,
        emailAddress: null,
        replyByEmailAddress: null,
        replyByPersonName: null,
      },
      emailTo: null,
      priority: "2",
    },
  ];

  const testDataColumns = [
    {
      id: "39d0a39b-fa02-41c7-9cb3-45c72a7a1489",
      contactCompanyId: "e5cb1589-0044-ecfe-bfd4-39fd501f6852",
      partyName: "3iInfotech Pvt. ",
      country: "naya nepal",
      callAction: false,
      emailAction: false,
      courierAction: false,
      interestedAction: false,
      replyStatus: false,
      tiedStatus: false,
      lastActionDateDto: null,
      isActive: true,
      remarks: "sdfasfa",
      modeofCommunication: null,
      attentionto: null,
      creatorName: null,
      attentionTo: null,
      attentionId: null,
      creatorid: "00000000-0000-0000-0000-000000000000",
      lastRemarksUpdateDate: "2024-10-28T15:38:33.1369951",
      isCircularPlanning: false,
      creationTime: null,
      emailDetails: {
        emailSentDate: "2024-08-29T16:47:20.2432224+05:45",
        emailReceivedDate: "0001-01-01T00:00:00",
        personName: "bijay",
        emailAddress: "bijay.adhikari@info.com.np",
        replyByEmailAddress: null,
        replyByPersonName: null,
      },
      emailTo: null,
      priority: "2",
    },
    {
      id: "b8fc5fd2-ba08-4dd0-b17a-21078668aaf1",
      contactCompanyId: "7b265695-5836-a903-5e96-39fd501f6840",
      partyName: "3M Singapore Pte.Ltd. - SINGAPOREs",
      country: "Angola",
      callAction: false,
      emailAction: false,
      courierAction: false,
      interestedAction: false,
      replyStatus: false,
      tiedStatus: false,
      lastActionDateDto: null,
      isActive: true,
      remarks: "sfsad",
      modeofCommunication: null,
      attentionto: null,
      creatorName: null,
      attentionTo: null,
      attentionId: null,
      creatorid: "00000000-0000-0000-0000-000000000000",
      lastRemarksUpdateDate: "2024-10-28T16:35:06.9100374",
      isCircularPlanning: false,
      creationTime: null,
      emailDetails: {
        emailSentDate: "0001-01-01T00:00:00",
        emailReceivedDate: "0001-01-01T00:00:00",
        personName: null,
        emailAddress: null,
        replyByEmailAddress: null,
        replyByPersonName: null,
      },
      emailTo: null,
      priority: "1",
    },
  ];

  const columnCircular = [
    {
      title: "S.N",
      dataIndex: "",
      width: 15,
      key: "s.n",
      render: (text, row, index) => (
        <div style={{ paddingLeft: 5 }}>{index + 1}</div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            Party
          </span>
        </div>
      ),
      className: "dashboard_task-daily",
      dataIndex: "partyName",
      key: "partyName",
      width: 50,
      sorter: (a, b) => {
        const partyNameA = a?.partyName ?? "";
        const partyNameB = b?.partyName ?? "";
        return partyNameA.localeCompare(partyNameB);
      },
    },

    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            Country
          </span>
        </div>
      ),
      dataIndex: "country",
      key: "country",
      width: 50,
      className: "dashboard_task-daily",
      sorter: (a, b) => {
        const countryA = a?.country ?? "";
        const countryB = b?.country ?? "";
        return countryA.localeCompare(countryB);
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            Moc
          </span>
        </div>
      ),
      dataIndex: "modeofCommunication",
      key: "modeofCommunication",
      width: 50,
      className: "dashboard_task-daily",
      sorter: (a, b) => {
        const modeofCommunicationA = a?.modeofCommunication ?? "";
        const modeofCommunicationB = b?.modeofCommunication ?? "";
        return modeofCommunicationA.localeCompare(modeofCommunicationB);
      },
    },
    {
      title: "Priority",
      key: "priority",
      dataIndex: "priority",
      width: 40,
    },

    {
      title: "Attention to",
      key: "attentionTo",
      render: (record) => {
        try {
          const rawAttentionTo = record?.attentionTo;
          if (
            typeof rawAttentionTo === "string" &&
            rawAttentionTo.trim().startsWith("[") &&
            rawAttentionTo.trim().endsWith("]")
          ) {
            const attentionToArray = JSON.parse(rawAttentionTo);
            if (
              Array.isArray(attentionToArray) &&
              attentionToArray.length > 0
            ) {
              return attentionToArray.map((x) => x.AttentionTo).join(", ");
            }
          }
          return "";
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return "";
        }
      },
      width: 50,
    },
    {
      title: "Circular Planning",
      key: "isCircularPlanning",
      index: "isCircularPlanning",
      render: (record) => {
        return (
          <Tooltip
            title={
              record?.lastActionDateDto?.lastTiedStatus && (
                <Moment format="YYYY-MM-DD">
                  {record?.lastActionDateDto?.lastTiedStatus}
                </Moment>
              )
            }
          >
            <Switch
              // defaultChecked={
              //   record.isCircularPlanning && record.isCircularPlanning
              // }
              defaultChecked={record.tiedStatus && record.tiedStatus}
              size="small"
              onChange={(check) =>
                actionChangeHandler(check, "tiedStatus", record.id)
              }
            />
          </Tooltip>
        );
      },
      width: 30,
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            Created Date
          </span>
        </div>
      ),
      dataIndex: "creationTime",
      key: "creationTime",
      width: 30,
      render: (record) => <>{moment(record).format("YYYY-MM-DD")}</>,
      className: "dashboard_task-daily",
      sorter: (a, b) => {
        const creationTimeA = a?.creationTime ?? "";
        const creationTimeB = b?.creationTime ?? "";
        return moment(creationTimeA).diff(moment(creationTimeB));
      },
    },
    {
      title: "Email To",
      key: "emailTo",
      width: 30,
      render: (record) => {
        try {
          const rawEmailTo = record?.emailTo;

          if (
            typeof rawEmailTo === "string" &&
            rawEmailTo.trim().startsWith("[") &&
            rawEmailTo.trim().endsWith("]")
          ) {
            const emailToArray = JSON.parse(rawEmailTo);

            if (Array.isArray(emailToArray) && emailToArray.length > 0) {
              return emailToArray.join(", ");
            }
          }

          return "";
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return "";
        }
      },
    },
    {
      title: "Email Sent Date",
      key: "emailSentDate",
      width: 20,
      render: (record) => {
        const { emailDetails } = record || {};
        const { emailSentDate, personName, emailAddress } = emailDetails || {};

        const sentDate =
          emailSentDate === "0001-01-01T00:00:00" || !emailSentDate
            ? ""
            : moment(emailSentDate).format("YYYY-MM-DD");

        const tooltipTitle =
          personName || emailAddress
            ? `${personName || "Unknown Person"} - ${
                emailAddress || "Unknown Email"
              }`
            : "Details not available";

        return <Tooltip title={tooltipTitle}>{sentDate}</Tooltip>;
      },
    },
    // {
    //   title: "Email Received Date",
    //   key: "emailReceivedDate",
    //   width: 20,
    //   render: (record) => {
    //     const { emailDetails } = record || {};
    //     const { emailReceivedDate, replyByEmailAddress, replyByPersonName } = emailDetails || {};

    //     const receivedDate = (emailReceivedDate === "0001-01-01T00:00:00" || !emailReceivedDate)
    //       ? "No Date"
    //       : moment(emailReceivedDate).format('YYYY-MM-DD');

    //     const tooltipTitle = (replyByEmailAddress || replyByPersonName)
    //       ? `${replyByEmailAddress || 'Unknown Email'} - ${replyByPersonName || 'Unknown Person'}`
    //       : 'Details not available';

    //     return (
    //       <Tooltip title={tooltipTitle}>
    //         {receivedDate}
    //       </Tooltip>
    //     );
    //   }
    // },
    {
      title: "Latest Remarks",
      key: "remarks",
      ellipsis: true,
      render: (record) => (
        <>
          <div className="latest-remark-wrapper">
            <span className="view-complain-remark">
              <Tooltip
                title={
                  record
                    ? `${record?.creatorName ? record?.creatorName + " - " : ""}
                  ${
                    record?.lastRemarksUpdateDate
                      ? moment(record?.lastRemarksUpdateDate).format(
                          "YYYY/MM/DD HH:mm A"
                        )
                      : ""
                  }
                  `
                    : ""
                }
              >
                {referencePartyHistoryAddResponse?.contactCompanyId ===
                  record?.contactCompanyId &&
                getDetails?.id ==
                  referencePartyHistoryAddResponse?.tenderBasicId
                  ? referencePartyHistoryAddResponse?.remarks
                  : record.remarks}

                {/* {company?.contactCompanyId === record?.contactCompanyId &&
                getDetails?.id ==
                  referencePartyHistoryAddResponse?.tenderBasicId
                  ? referencePartyHistoryAddResponse?.remarks
                  : record.remarks} */}
              </Tooltip>
            </span>
          </div>
        </>
      ),
      width: 120,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      render: (record) => (
        <>
          <Space size="small">
            <PlusCircleTwoTone
              onClick={() => {
                setModalRemark(true);
                setCompany(record);
              }}
              style={{ marginRight: "8px" }}
            />
            <EditTwoTone
              style={{ marginRight: "8px" }}
              onClick={() => {
                setSelectedCompanyId(record?.contactCompanyId);
                setSelectedPartyName(record?.partyName);
                setSelectedCompanyName(record?.partyName);
                handleCircularEdit(record);
              }}
            />
            {isTenderCompanyAdmin ? (
              <Popconfirm
                title="Are you sure you want to delete this item?"
                onConfirm={() => handleDeleteRecord(record)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
            ) : null}
          </Space>
        </>
      ),
      width: 15,
    },
  ];

  const fetchCompanyEmailAddress = useCallback(
    (companyId) => {
      if (companyId !== null) {
        dispatch(getCompanyorPersonEmailAddess(null, companyId));
      }
    },
    [dispatch]
  );

  const onFinish = (values) => {
    setIsAddCompanyModelOpen(true);
    setFormCompanyId(values?.CompanyId);
    setSelectedCompanyId(values?.CompanyId);

    addCompanyModelForm.setFieldsValue({
      CompanyIdNew: selectedCompanyName,
    });

    fetchCompanyEmailAddress(values?.CompanyId);

    // RESET VALUE
    addCompanyModelForm.resetFields();
    setSelectedAttendees([]);

    dispatch(
      getContactPersonPagination({
        CompanyId: values?.CompanyId,
      })
    );
  };

  const onFinishAddCompany = (values) => {
    let remarksData = {
      remarks:
        valueRadioAddCompany === "1"
          ? "Email"
          : valueRadioAddCompany === "2"
          ? "Facebook"
          : valueRadioAddCompany === "3"
          ? "Whatsapp"
          : valueRadioAddCompany === "4"
          ? "Twitter"
          : valueRadioAddCompany === "5"
          ? "Other"
          : "1",
    };

    // for manual Company Add
    const api1 = {
      ReferenceId: emailReferenceId,
      CompanyId: selectedCompanyId,
      priority: values?.priority || "",
    };

    let payloadSource = showProjectTab ? "projectId" : "tenderBasicId";

    // for remarks
    const api2 = {
      [payloadSource]: getDetails.id,
      contactCompanyId: selectedCompanyId,
      remarks: values?.remarks,
      isDefaultOrCircular: isCircular ? 1 : 2,
    };

    // for tender
    const api3 = {
      ProjectId: getDetails.id,
      contactCompanyId: selectedCompanyId,
      remarks: values?.remarks,
      isDefaultOrCircular: isCircular ? 1 : 2,
    };

    const bodyPayload = {
      ModeofCommunication: remarksData?.remarks,
      attentionTo: JSON.stringify(selectedAttendees),
      emailTo: JSON.stringify(values?.emailTo),
      priority: values?.priority || "",
    };

    try {
      if (isCircular && showProjectTab) {
        // PROJECT AND CIRCULAR
        dispatch(
          actionForReferenceCircularPlanningAdd(
            "tender",
            bodyPayload,
            api1,
            api3
          )
        );
      } else if (!isCircular && showProjectTab) {
        dispatch(ReferenceRemarksCompanyAdd("project", api1, api2));
      } else if (isCircular) {
        // Circular Planning
        dispatch(
          actionForReferenceCircularPlanningAdd(null, bodyPayload, api1, api2)
        );
      } else {
        // Not Circular and Other
        dispatch(ReferenceRemarksCompanyAdd("tender", api1, api2));
      }
      // console.log("values: ", values);
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsAddCompanyModelOpen(false);
      addCompanyModelForm.resetFields();
    }
  };

  const debounceAttentiontoDispatch = useCallback(
    debounce((selectedPersonIds, selectedCompanyId) => {
      dispatch(
        getCompanyorPersonEmailAddess(selectedPersonIds, selectedCompanyId)
      );
    }, 300),
    [dispatch]
  );

  const handleSelectChange = (values, options) => {
    const selectedData = options
      .map((option) => ({
        AttentionTo: option.props.children || "Unknown",
        AttentionId: option.key || null,
      }))
      .filter((item) => item.AttentionId);

    setSelectedAttendees(selectedData);

    const selectedPersonIds =
      selectedData &&
      selectedData?.map((item) => ({
        contactPersonIds: item?.AttentionId,
      }));

    if (selectedPersonIds.length > 0 && selectedCompanyId) {
      debounceAttentiontoDispatch(selectedPersonIds, selectedCompanyId);
    }
  };

  const onChangeAddCompany = (value) => {
    setValueRadioAddCompany(value);
  };

  const setTaskClassName = (record) => {
    return record.contactCompanyId === companyId ? "clickRowStyl" : "";
  };

  const handleModalNewMailClose = () => {
    setIsModalNewMailOpen(false);
  };

  const handleAddEmailSubmit = async (newEmail) => {
    setIsLoadingNewMail(true);
    const body = {
      companyId: selectedCompanyId,
      email: newEmail,
    };

    try {
      if (body.companyId) {
        await dispatch(actionForAddCompanyOrContactPersonEmailAddById(body));
        handleModalNewMailClose();
        // console.log("recordxxx: selectedCompanyId ", selectedCompanyId);
        if (selectedCompanyId) {
          dispatch(getCompanyorPersonEmailAddess(null, selectedCompanyId));
        }
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setIsLoadingNewMail(false);
    }
  };

  // Template Edited Format
  const formattedDate = lastModifiedLog
    ? `${moment(lastModifiedLog).format(
        "MMMM Do YYYY, h:mm A"
      )},  by ${modifier}`
    : "Not Edited";

  // Tooltip
  const getDetailsSafe = getDetails || {};

  const isRequestSent = getDetailsSafe.isCircularPlanningApprovalRequestSent;
  const approvalStatus = getDetailsSafe.circularPlanningApprovedStatusDto || {};
  const sendForApprovalLog =
    getDetailsSafe.circularPlanningSendForApprovalLogDto || {};

  const approverName = approvalStatus.approverName || "Unknown";
  const approveDate = approvalStatus.approveDate
    ? moment(approvalStatus.approveDate).format("YYYY-MM-DD")
    : "N/A";
  const senderName = sendForApprovalLog.from?.participantName || "Unknown";
  const messageSentTime = sendForApprovalLog.messageSentTime
    ? moment(sendForApprovalLog.messageSentTime).format("YYYY-MM-DD HH:mm:ss")
    : "N/A";

  const toList = Array.isArray(sendForApprovalLog.to)
    ? sendForApprovalLog.to
        .map((item) => item.participantName || "Unnamed")
        .join(", ")
    : "No recipients";

  const tooltipMessage = !isRequestSent ? (
    "Not Sent"
  ) : approvalStatus.isApproved ? (
    `Approved by ${approverName} on ${approveDate}`
  ) : (
    <div>
      <p>Not yet approved</p>
      <p>Sent By: {senderName}</p>
      <p>To: {toList}</p>
      <p>Time: {messageSentTime}</p>
    </div>
  );

  // Button
  const isButtonDisabled =
    getDetails?.isCircularPlanningApprovalRequestSent !== false;

  const isCircularButtonDisabled = !getDetails?.isCircularApprovalStatus;
  const tooltipMessageCircularBtn = !getDetails?.isCircularApprovalStatus
    ? "Not Approved"
    : "Default";

  // Contact Details
  const contactDetails = () => {
    return (
      <>
        <div className="company_info">
          <div className="company_header" style={{ width: "100%" }}>
            <div
              className="referenceDetails__label"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <button className="referenceDetails--title header_print_btn">
                Contact Details
              </button>

              {companyDetails?.companyName && (
                <Button
                  style={{ height: "28px", lineHeight: "21px" }}
                  type="primary"
                  onClick={() => {
                    setAddCompany(true);
                  }}
                >
                  Update Contact
                </Button>
              )}
            </div>
          </div>

          {addCompany && (
            <AddCompany addCompany={addCompany} setAddCompany={setAddCompany} />
          )}

          {!isEmpty(companyDetails) && (
            <div
              className="referenceDetails__label person-info-wrap"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr ",
                width: "100%",
              }}
            >
              <div>
                <span className="referenceDetails--title header_print_btn">
                  {companyDetails?.companyName && (
                    <>
                      <MailOutlined
                        style={{ color: "grey", paddingRight: "5px" }}
                        onClick={showModal}
                      />
                      {companyDetails?.companyName}
                    </>
                  )}
                </span>
              </div>
              <br />
              <div>
                <span className="referenceDetails--title header_print_btn">
                  {companyDetails?.companyName && (
                    <>
                      <GlobalOutlined style={{ paddingRight: "5px" }} />
                      Website:
                    </>
                  )}
                  {companyDetails?.companyName &&
                    companyDetails?.contactCompanyGroupLabelDtos?.map((x) => {
                      if (
                        x.contactLabelGroupName == "Website" &&
                        x.contactLabelName == "Official"
                      ) {
                        return (
                          <>
                            <a
                              // href={`https://www.` + x.contactLabelDataValue}
                              href={x.contactLabelDataValue}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {x.contactLabelDataValue}
                            </a>
                          </>
                        );
                      }
                    })}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="person_info">
          <div>
            <Table
              columns={cols}
              dataSource={contactPerson}
              size="small"
              pagination={false}
              className="organizer__table company-details-table"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="wrapper_company">
      {getDetails?.referenceCode && (
        <div className="business-header" style={{ marginTop: "5px" }}>
          <div
            className="business-header-title"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="profiledetails__rowedit--data"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              title={getDetails?.title}
            >
              <span
                className="referenceDetails--subtitle"
                style={{ color: "blue" }}
              >
                {selectedTenderRowId} [{getDetails?.title}]
              </span>
            </div>
            {isCircular && (
              <div style={{ display: "flex" }}>
                <Select
                  defaultValue="all"
                  style={{ width: 120, marginRight: "8px" }}
                  onChange={handlePriorityChange}
                  disabled
                >
                  <Option value="all">All</Option>
                  <Option value="1">Priority 1</Option>
                  <Option value="2">Priority 2</Option>
                  <Option value="3">Priority 3</Option>
                </Select>
                {/* {getDetails?.isCircularApprovalStatus && ( */}
                <>
                  <Tooltip title={tooltipMessageCircularBtn}>
                    <Button
                      // type="primary"
                      type={isCircularButtonDisabled ? "dashed" : "primary"}
                      style={{ marginRight: "8px" }}
                      onClick={handleTenderMail}
                      loading={isLoadingOpeningMail}
                      disabled={isCircularButtonDisabled}
                    >
                      Circular
                      {/* <Link
                        to="/email-compose?applicationType=tenderModule"
                        target="_blank"
                        rel="opener"
                        style={{ color: "#6d6d6d" }}
                        className="compose_email_button"
                      >
                        Circular
                      </Link> */}
                    </Button>
                  </Tooltip>
                </>
                {/* )} */}

                <Button
                  type="primary"
                  onClick={() => {
                    getCicularTemplate();
                    setIsTemplateModalOpen(true);
                  }}
                  style={{ marginRight: "8px" }}
                >
                  Template
                </Button>

                <Tooltip title={tooltipMessage}>
                  <Button
                    type="primary"
                    onClick={() => setIsCircularModalOpen(true)}
                    disabled={isButtonDisabled}
                  >
                    Send for Approval
                  </Button>
                </Tooltip>
                {/* )} */}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="organizer__table company-table-wrapper">
        <Table
          className="company-table"
          // rowSelection={isCircular ? {
          //   type: 'checkbox',
          //   ...rowSelection,
          // } : null}
          rowKey="id"
          rowSelection={isCircular && rowSelection}
          columns={isCircular ? columnCircular : columns}
          dataSource={
            isCircular
              ? referencePartyListingCircularResponse
              : referencePartyListingResponse
          }
          // dataSource={isCircular ? testDataCircular : testDataColumns}
          loading={
            isCircular
              ? referencePartyListingCircularLoading
              : referencePartyListingLoading
          }
          scroll={true}
          pagination={false}
          onRow={rowEventhandlers}
          rowClassName={setTaskClassName}
        />
        <div className="company-add-update-action-wrapper">
          {showAddCompany ? (
            <Form layout="inline" form={form} onFinish={onFinish}>
              <Form.Item
                style={{ width: 300 }}
                name="CompanyId"
                rules={[
                  {
                    required: true,
                    message: "  ",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select company"
                  optionFilterProp="label"
                  allowClear={true}
                  autoComplete="off"
                  onSelect={(value, option) => {
                    setSelectedCompanyName(option?.label || "");
                  }}
                  loading={contactCompanyPaginationLoading}
                  onPopupScroll={(event) => {
                    try {
                      let maxScroll =
                        event.target.scrollHeight - event.target.clientHeight;

                      let currentScroll = event.target.scrollTop;
                      if (currentScroll === maxScroll) {
                        let MaxResultCount = pageSize;
                        let SkipCount = currentPage * pageSize;
                        if (
                          contactCompanyPaginationResponse?.totalCount >
                            SkipCount ||
                          !contactCompanyPaginationResponse
                        ) {
                          getData(
                            MaxResultCount,
                            SkipCount,
                            SearchKeyword,
                            true
                          );
                        }
                      }
                    } catch (e) {}
                  }}
                  onSearch={(value) => {
                    setSearchKeyWord(value);
                    setCurrentPage(1);
                    if (value) {
                      setValue(value);
                    } else {
                      setValue(value);
                    }
                  }}
                  notFoundContent={
                    !isEmpty(value) ? (
                      contactCompanyPaginationLoading ? (
                        <Spin />
                      ) : (
                        isEmpty(contactCompanyPaginationResponse?.items) &&
                        value?.length > 2 && (
                          <div>
                            {`Create "${value}"`}
                            <Button onClick={() => setVisibleQuickModal(true)}>
                              Add New Company
                            </Button>
                          </div>
                        )
                      )
                    ) : null
                  }
                >
                  {contactCompanyPaginationResponse?.items?.map((company) => (
                    <Option
                      value={company.contactCompanyId}
                      key={company.contactCompanyId}
                      label={company.contactCompanyName}
                    >
                      {company.contactCompanyName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  className="company-add-save-btn"
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  disabled={referenceCompanyManualAddLoading}
                  // onClick={() => {
                  //   setIsAddCompanyModelOpen(true);
                  // }}
                >
                  Add
                </Button>
              </Form.Item>
              <Form.Item className="task-form-item task-action-cross">
                <Button
                  type="dotted"
                  onClick={() => {
                    setShowAddCompany(!showAddCompany);
                    form.resetFields();
                  }}
                  bordered={false}
                  shape="round"
                  // size="small"
                >
                  {/* <CloseOutlined /> */}
                  Close
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Button
              onClick={() => {
                handleAddCompany();
              }}
              type="text"
              size="small"
            >
              <span style={{ fontSize: 14 }}> + Add Company</span>
            </Button>
            // ""
          )}
        </div>
      </div>

      <Modal
        // title="Template"
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <span style={{ marginRight: '10px' }}>Template</span> */}
            <Button
              type="primary"
              onClick={() => {
                setIsCircularAddEditModalOpen(true);
                setCircularAddEdit({ add: true, edit: false, data: {} });
              }}
            >
              Add Circular Template
            </Button>
          </div>
        }
        maskClosable={false}
        visible={isTemplateModalOpen}
        onCancel={handleTemplateModal}
        footer={null}
        width={900}
        // height="80%"
        style={{ height: "80vh" }}
        // bodyStyle={{ overflowY: 'auto', height: 'calc(100% - 55px)' }}
      >
        <CircularTemplate
          isTemplateModalOpen={isTemplateModalOpen}
          setIsTemplateModalOpen={setIsTemplateModalOpen}
          // isVisible={isTemplateModalOpen}

          applicationId={getDetails?.referenceId}
          refCode={getDetails?.referenceCode}
          templateData={templateData}
          // templateData={allCircularPlanningTemplateResp}
          setIsCircularAddEditModalOpen={setIsCircularAddEditModalOpen}
          isCircularAddEditModalOpen={isCircularAddEditModalOpen}
          circularAddEdit={circularAddEdit}
          setCircularAddEdit={setCircularAddEdit}
          emailReferenceId={emailReferenceId}
          showProjectTab={showProjectTab}
          getDetails={getDetails}
          isCircular={isCircular}
          setSelectedAttendees={setSelectedAttendees}
          selectedAttendees={selectedAttendees}
          data={data}
          setData={setData}
        />
      </Modal>

      {/* Internal Message Model for Approval */}
      <Modal
        title="Send Circular Planning"
        visible={isCircularModalOpen}
        onCancel={handleCircularModal}
        footer={null}
      >
        <CircularMail
          isVisible={isCircularModalOpen}
          setInternalVisible={setIsCircularModalOpen}
          origin={`Circular Planning of ${getDetails?.title}`}
          applicationId={getDetails?.referenceId}
          module="Circular_Planning"
          refCode={getDetails?.referenceCode}
        />
      </Modal>

      <Modal
        title="Add Company"
        className=""
        visible={isAddCompanyModelOpen}
        onCancel={handleAddCompanyModal}
        footer={null}
      >
        <Form
          {...formItemLayout}
          labelAlign="left"
          // initialValues={{ priority: "1" }}
          form={addCompanyModelForm}
          onFinish={onFinishAddCompany}
        >
          {isCircular && (
            <>
              <Form.Item label="Moc">
                <Select
                  value={valueRadioAddCompany}
                  onChange={onChangeAddCompany}
                  defaultValue="1"
                >
                  <Option value="1">Email</Option>
                  <Option value="2">Facebook</Option>
                  <Option value="3">Whatsapp</Option>
                  <Option value="4">Twitter</Option>
                  <Option value="5">Other</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Remaks" name="remarks">
                <Input.TextArea rows={4} placeholder="Remarks" />
              </Form.Item>
              <Form.Item label="Company Name">
                <Input disabled value={selectedCompanyName} />
                <IconButtonWithTooltip
                  tooltipText="Add Company Email"
                  onClick={() => setIsModalNewMailOpen(true)}
                >
                  +
                </IconButtonWithTooltip>
              </Form.Item>
              <Form.Item label="Attention to" name="attendees">
                <Select
                  showSearch
                  mode="multiple"
                  autoComplete="off"
                  optionFilterProp="label"
                  placeholder="Select Person"
                  allowClear
                  onSearch={(value) => {
                    setSearchKeywordContactPerson(value);
                  }}
                  onChange={handleSelectChange}
                  notFoundContent={
                    <>
                      {contactPersonPaginationLoading ? (
                        <Skeleton />
                      ) : (
                        <div>
                          Content Not Found. Do you want to Person?
                          <Button
                            type="primary"
                            onClick={() => {
                              setIsPersonModalVisible(true);
                              // Company Details
                              dispatch(
                                contactCompanyGetDetails(selectedCompanyId)
                              );

                              const namesArray =
                                searchKeywordContactPerson.split(" ");

                              // const firstName = namesArray[0];
                              // const secondName =
                              //   namesArray.slice(1).join(" ") || "";

                              formQuickAddPerson.setFieldsValue({
                                firstName: namesArray[0],
                                lastName: namesArray.slice(1).join(" ") || "",
                              });
                            }}
                          >
                            Yes
                          </Button>
                        </div>
                      )}
                    </>
                  }
                >
                  {data.map((option) => (
                    <Option
                      label={option.label}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          {!isCircular && (
            <>
              <Form.Item
                label="Remaks"
                name="remarks"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Remarks"
                  style={{ marginBottom: "8px" }}
                />
              </Form.Item>
            </>
          )}

          {isCircular && valueRadioAddCompany === "1" && (
            <Form.Item label="Email To" name="emailTo">
              <Select
                mode="multiple"
                placeholder="Select Contact Person"
                style={{ width: "100%" }}
                optionFilterProp="children"
              >
                {contactCompanyPersonMailResponse.map((contact) => (
                  <Option
                    key={`${contact.name}-${contact.email}`}
                    value={contact.email}
                  >
                    {contact.email} ({contact.name})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="Priority"
            name="priority"
            rules={[{ required: true, message: "Please select a priority!" }]}
          >
            <Select
              placeholder="Select your priority"
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
              ]}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="company-add-save-btn"
              type="primary"
              htmlType="submit"
              shape="round"
              // disabled={referenceCompanyManualAddLoading}
              // onClick={() => {
              //   setIsAddCompanyModelOpen(false);
              // }}
            >
              Submit
            </Button>

            <Button
              type="dotted"
              onClick={() => {
                setIsAddCompanyModelOpen(false);
              }}
              bordered={false}
              shape="round"
            >
              Close
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* const [isAddCompanyModelOpen, setIsAddCompanyModelOpen] = useState(false); */}

      {/* QUICK ADD PERSOM*/}
      <Modal
        title="Quick Add Person"
        visible={isPersonModalVisible}
        footer={null}
        onCancel={handleQuickAddPersonModal}
        style={{
          zIndex: 2000,
        }}
      >
        <QuickAddPerson
          form={formQuickAddPerson}
          previousForm={addCompanyModelForm}
          layout={formItemLayout}
          onCloseModal={handleQuickAddPersonModal}
          isAddNewPersonStatus={isAddNewPersonStatus}
          setisAddNewPersonStatus={setisAddNewPersonStatus}
          selectedCompanyName={selectedCompanyName}
          selectedCompanyId={selectedCompanyId}
        />
      </Modal>

      {/* QUICK ADD COMPANY */}
      <Modal
        title="Quick Add Company"
        visible={visibleQuickModal}
        footer={null}
        // onCancel={() => {
        //   formQuickAddCompany.setFieldsValue({
        //     scopeOfWork: [],
        //     companyName: "",
        //     countryId: "",
        //     // isActive: false,
        //     isFavorite: false,
        //     cityId: "",
        //   });
        //   setVisibleQuickModal(false);
        // }}
        onCancel={handleQuickAddCompanyModal}
        keyboard={false}
        maskClosable={false}
      >
        <QuickAddCompany
          form={formQuickAddCompany}
          mainForm={form}
          companySearch={value}
          quickContactCompanyAdd={quickContactCompanyAdd}
          quickContactCompanyLoader={quickContactCompanyLoader}
          close={handleQuickAddCompanyModal}
          setSelectedCompanyName={setSelectedCompanyName}
        />
      </Modal>

      <Modal
        title={`${companyDetails?.companyName} [${selectedTenderRowId}]`}
        className="company-remark-edit"
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1500}
        style={{ position: "absolute", top: 10, right: 0 }}
      >
        <>
          <CommunicatonChannel
            origin="tender"
            emailType="Business"
            emailDetailCompany={{
              companyId: companyId,
              referenceName: selectedTenderRowId,
            }}
          />
        </>
      </Modal>

      {/* Details */}
      <div className="company_details companies--bottom-wrapper">
        <div className="remarks_history">
          <div className="company_header">
            <div>
              <div className="referenceDetails__label">
                <button className="referenceDetails--title header_print_btn">
                  Remarks History
                </button>
              </div>
            </div>
          </div>
          <div>
            <Table
              columns={col}
              dataSource={remarksHistory}
              size="small"
              className="organizer__table company-bottom-table"
              pagination={false}
            />
          </div>
        </div>
        <div className="company_person">
          {!isCircular ? (
            contactDetails()
          ) : (
            <>
              <Tabs
                // defaultActiveKey="1"
                activeKey={activeKey}
                type="line"
                className="circularplanning__tabs--contact"
                onChange={handleTabChange}
              >
                <TabPane tab="Contact Details" key="1">
                  {contactDetails()}
                </TabPane>
                <TabPane tab="Tender Checklist" key="2">
                  <div className="person_info tender-checklist">
                    <div className="company_header">
                      <div
                        className="referenceDetails__label"
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <button className="referenceDetails--title header_print_btn">
                          Tender Checklist
                        </button>
                        <Button
                          style={{ height: "28px", lineHeight: "21px" }}
                          type="primary"
                          onClick={() => {
                            dispatch(
                              actionForGetReferenceTemplateGetDetailsById(
                                getDetails?.referenceId
                              )
                            );
                            setModalVisible(true);
                          }}
                        >
                          Circular Content
                        </Button>
                      </div>
                    </div>
                    <CheckListContainer referenceId={getDetails?.referenceId} />
                  </div>
                </TabPane>
                <TabPane tab="Log List" key="4">
                  {activeKey === "4" && (
                    <LogList
                      // loading={getCircularPlanningLoading}
                      // data={getCircularPlanningResp}
                      referenceId={getDetails?.referenceId}
                    />
                  )}
                </TabPane>
                {/* <TabPane tab="Top 5" key="5">
                  <LogScroll />
                </TabPane> */}
              </Tabs>
            </>
          )}
        </div>
      </div>

      <Modal
        // title={`Application Template  ${
        //   -selectedItem ? selectedItem.name : ""
        // }`}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span>Application {selectedItem ? selectedItem.name : ""}</span>
            <span style={{ marginRight: "22px", fontSize: "14px" }}>
              Last Edited: <span style={{ color: "red" }}>{formattedDate}</span>
            </span>
          </div>
        }
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <CircularIndex
          id={circularContentDataId}
          data={circularContentData}
          editorData={editorData}
          setEditorData={setEditorData}
          referenceId={getDetails?.referenceId}
          origin="tender"
          handleCloseEditModal={closeEditTemplateModal}
        />
        {/* <HTMLRendererComponent title="TenderApplication" /> */}
      </Modal>

      <Modal
        title="Add Remark"
        visible={modalRemark}
        onCancel={() => {
          setModalRemark(false);
        }}
        footer={null}
      >
        <Form
          form={formRemark}
          layout="vertical"
          onFinish={(values) => {
            let type = showProjectTab ? "project" : "tender";
            let tenderOrAny = showProjectTab ? "projectId" : "tenderBasicId";
            let apiData = {
              ...values,
              contactCompanyId: companyId,
              [tenderOrAny]: getDetails.id,
              isDefaultOrCircular: isCircular ? 1 : 2,
              // priority: values?.priority || "",
            };

            dispatch(referencePartyRemarksAddSolo(type, apiData));
            setModalRemark(false);
            formRemark.resetFields();
          }}
        >
          <Form.Item name="remarks" label="Remark">
            <Input.TextArea placeholder="Remarks" />
          </Form.Item>

          {/* <Form.Item label="Priority" name="priority">
            <Select
              placeholder="Select your priority"
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
              ]}
            />
          </Form.Item> */}

          {/* <Form.Item label="Moc">
            <Radio.Group
              onChange={onChangeAddCompany}
              value={valueRadioAddCompany}
              name="addupdateremarks"
            >
              <Space direction="vertical">
                {renderRadioWithRemarks(1, "Email")}
                {renderRadioWithRemarks(2, "Facebook")}
                {renderRadioWithRemarks(3, "Whatsapp")}
                {renderRadioWithRemarks(4, "Twitter")}
                {renderRadioWithRemarks(5, "Other")}
              </Space>
            </Radio.Group>
          </Form.Item> */}
          <Form.Item>
            <Button
              style={{
                marginTop: "12px ",
                float: "right",
              }}
              type="primary"
              htmlType="submit"
              loading={referencePartyHistoryAddLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit"
        visible={modalEdit}
        onCancel={() => {
          setModalEdit(false);
        }}
        footer={null}
      >
        <Form
          form={formPriority}
          layout="vertical"
          onFinish={(values) => {
            setModalEdit(false);
            // console.log("values: ", values);
            let type = showProjectTab ? "project" : "tender";
            let tenderOrAny = showProjectTab ? "projectId" : "tenderBasicId";
            let apiData = {
              ...values,
              CompanyId: companyId,
              // [tenderOrAny]: getDetails.id,
              ReferenceId: getDetails.id,
              isDefaultOrCircular: isCircular ? 1 : 2,
            };
            // console.log("getDetails: ", getDetails);
            // console.log("companyId: ", companyId);
            // dispatch(referencePartyRemarksAddSolo(type, apiData));
            dispatch(ReferenceCompanyManualAdd(apiData));
            formPriority.resetFields();
          }}
        >
          <Form.Item label="Priority" name="priority">
            <Select
              placeholder="Select your priority"
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Button
              style={{
                marginTop: "12px ",
                float: "right",
              }}
              type="primary"
              htmlType="submit"
              // loading={referencePartyHistoryAddLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/*CIRCULAR EDIT */}
      <Modal
        title="Edit Circular Planning"
        visible={isCircularEditVisible}
        onCancel={() => {
          setIsCircularEditVisible(false);
          setValueRadioAddCompany("1");
          formCircularEdit.resetFields();
        }}
        footer={null}
      >
        <Form
          {...formItemLayout}
          labelAlign="left"
          form={formCircularEdit}
          onFinish={onFinishCircularEdit}
        >
          <Form.Item label="Company Name">
            {/* {formCircularEdit?.getFieldValue("CompanyName")} */}
            {selectedPartyName}
            <IconButtonWithTooltip
              tooltipText="Add Company Email"
              onClick={() => setIsModalNewMailOpen(true)}
            >
              +
            </IconButtonWithTooltip>
          </Form.Item>

          <Form.Item label="Moc" wrapperCol={{ span: 18 }}>
            <Select
              value={valueRadioAddCompany}
              onChange={onChangeAddCompany}
              defaultValue="1"
            >
              <Option value="1">Email</Option>
              <Option value="2">Facebook</Option>
              <Option value="3">Whatsapp</Option>
              <Option value="4">Twitter</Option>
              <Option value="5">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Attention to"
            name="attendees"
            wrapperCol={{ span: 18 }}
          >
            <Select
              showSearch
              mode="multiple"
              autoComplete="off"
              optionFilterProp="label"
              placeholder="Select Person"
              allowClear
              onSearch={(value) => {
                setSearchKeywordContactPerson(value);
              }}
              onChange={handleSelectChange}
              notFoundContent={
                <>
                  <>
                    {contactPersonPaginationLoading ? (
                      <Skeleton />
                    ) : (
                      <div>
                        Content Not Found. Do you want to Person?
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsPersonModalVisible(true);
                            // Company Details
                            dispatch(
                              contactCompanyGetDetails(selectedCompanyId)
                            );

                            const namesArray =
                              searchKeywordContactPerson.split(" ");

                            formQuickAddPerson.setFieldsValue({
                              firstName: namesArray[0],
                              lastName: namesArray.slice(1).join(" ") || "",
                            });
                          }}
                        >
                          Yes
                        </Button>
                      </div>
                    )}
                  </>
                </>
              }
            >
              {data.map((option) => (
                <Option
                  label={option.label}
                  key={option.value}
                  value={option.label}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {valueRadioAddCompany === "1" && (
            <Form.Item label="Mail to" name="mailTo" wrapperCol={{ span: 18 }}>
              <Select
                mode="multiple"
                placeholder="Select Contact Person"
                style={{ width: "100%" }}
                optionFilterProp="children"
              >
                {contactCompanyPersonMailResponse.map((contact) => (
                  <Option
                    key={`${contact.name}-${contact.email}`}
                    value={contact.email}
                  >
                    {contact.email} ({contact.name})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label="Priority" name="priority">
            <Select
              placeholder="Select your priority"
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
              ]}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
            <Button
              style={{
                marginTop: "12px ",
                float: "right",
              }}
              type="primary"
              htmlType="submit"
              // loading={referencePartyHistoryAddLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <QuickAddCompanyEmailModal
        visible={isModalNewMailOpen}
        onClose={handleModalNewMailClose}
        onSubmit={handleAddEmailSubmit}
        loading={isLoadingNewMail}
      />
    </div>
  );
};

export default Companies;
