import { OFFICE_RESOURCE_TYPES } from "../../../types";

const INITIAL_STATE = {
  honorRollsPaginationLoading: false,
  honorRollsPaginationResponse: [],
  honorRollAddResponse: {},
  honorRollAddLoading: false,
};

export default function honorRollReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OFFICE_RESOURCE_TYPES.honorRollsPagination_INIT:
      return {
        ...state,
        honorRollsPaginationResponse: null,
        honorRollsPaginationLoading: true,
      };
    case OFFICE_RESOURCE_TYPES.honorRollsPagination_SUCCESS:
      return {
        ...state,
        honorRollsPaginationResponse: action.payload,
        honorRollsPaginationLoading: false,
      };
    case OFFICE_RESOURCE_TYPES.honorRollsPagination_FINISH:
      return {
        ...state,
        honorRollsPaginationResponse: null,
        honorRollsPaginationLoading: false,
      };

    case OFFICE_RESOURCE_TYPES.honorRollAdd_INIT:
      return {
        ...state,
        honorRollAddResponse: [],
        honorRollAddLoading: true,
      };
    case OFFICE_RESOURCE_TYPES.honorRollAdd_SUCCESS:
      return {
        ...state,
        honorRollAddResponse: action.payload,
        honorRollAddLoading: false,
      };
    case OFFICE_RESOURCE_TYPES.honorRollAdd_FINISH:
      return {
        ...state,
        honorRollAddResponse: [],
        honorRollAddLoading: false,
      };

    case OFFICE_RESOURCE_TYPES.honorRollUpdate_INIT:
      return {
        ...state,
        honorRollUpdateResponse: [],
        honorRollUpdateLoading: true,
      };
    case OFFICE_RESOURCE_TYPES.honorRollUpdate_SUCCESS:
      return {
        ...state,
        honorRollUpdateResponse: action.payload,
        honorRollUpdateLoading: false,
      };
    case OFFICE_RESOURCE_TYPES.honorRollUpdate_FINISH:
      return {
        ...state,
        honorRollUpdateResponse: [],
        honorRollUpdateLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
