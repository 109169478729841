import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService } from "../../../commonServices";

export class LoanApplicationService {
  getLoanApplicationPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/requisitionManagement/loanApplicationPagination?${query}`;
    let data = getService(url);
    return data;
  }

  addLoanApplication(body) {
    let url = `${appBaseUrl}/requisitionManagement/loanApplicationAdd`;
    let data = postService(url, body, "post");
    return data;
  }

  updateLoanApplication(body, id) {
    let url = `${appBaseUrl}/loanApplicationManagement/loanApplicationUpdate/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  getLoanApplicationDataGetDetails(id) {
    let url = `${appBaseUrl}/requisitionManagement/loanApplicationDetails/${id}`;
    let data = getService(url);
    return data;
  }
  updateLoanSupervisor(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/loanApplicationUpdateSupervisor/${id}`;
    let data = postService(url, body, "put");
    return data;
  }
  updateLoanAccountant(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/loanApplicationUpdateAccountant/${id}`;
    let data = postService(url, body, "put");
    return data;
  }
  updateLoanManagement(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/loanApplicationUpdateLoanApprover/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  // getSupervisorDetails() {
  // 	let url = `${appBaseUrl}/requisitionManagement/supervisorDetail`;
  // 	let data = getService(url);
  // 	return data;
  // }
}
