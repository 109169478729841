import React, { useState } from "react";
import { Modal, Button, Space, Popconfirm } from "antd";
import {
  EyeOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  DownloadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const AllFileViewer = (props) => {
  const {
    file,
    cqBaseUrl,
    token,
    height,
    width,
    type,
    isShowFileName,
    showDelete,
    onDeleteClick,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // const isPdf = file.fileName.toLowerCase().endsWith(".pdf");
  // const isImage = /\.(jpg|jpeg|png|gif)$/i.test(file.fileName);
  const isPdf = file?.displayFileName.toLowerCase().endsWith(".pdf");
  const isImage = /\.(jpg|jpeg|png|gif)$/i.test(file?.displayFileName);

  const handleOpenFile = async () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfLoaded(true);
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `${cqBaseUrl}/${file.viewFileURL}${token}`;
    link.target = "_blank";
    link.download = "downloaded.pdf";
    link.click();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {showDelete && (
          <Popconfirm
            onConfirm={onDeleteClick}
            title="Delete the file"
            description="Are you sure to delete this file?"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
          >
            <DeleteOutlined style={{ cursor: "pointer", marginRight: "8px" }} />
          </Popconfirm>
        )}

        <EyeOutlined
          style={{ cursor: "pointer", marginRight: "8px" }}
          onClick={handleOpenFile}
        />
        <DownloadOutlined onClick={handleDownload} />
        <div
          className="hr_fileName"
          style={{
            maxWidth: "140px",
            textOverflow: "ellipsis",
            color: "#616161",
            overflow: "hidden",
            marginLeft: "6px",
            fontSize: 12,
          }}
        >
          {/* {file?.displayFileName.slice(0, 8) + file?.displayFileName.slice(-4)} */}
          {isShowFileName === false
            ? null
            : file?.displayFileName.slice(0, 8) +
              file?.displayFileName.slice(-4)}
        </div>
      </div>

      <Modal
        visible={modalVisible}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Document Viewer</span>
            <span onClick={handleCloseModal}>
              <CloseOutlined />
            </span>
          </div>
        }
        footer={null}
        closable={false}
        onCancel={handleCloseModal}
        width={width ? width : 900}
      >
        {modalVisible && (
          <>
            {isPdf ? (
              <div
                style={{
                  height: height ? `${height}px` : "600px",
                  width: "100%",
                  overflowX: "scroll",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="test"
              >
                <Document
                  file={`${cqBaseUrl}/${file.viewFileURL}${token}`}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onClick={handleOpenFile}
                >
                  <Page key={`page_${currentPage}`} pageNumber={currentPage} />
                </Document>
              </div>
            ) : isImage ? (
              <img
                src={`${cqBaseUrl}/${file.viewFileURL}${token}`}
                alt="File"
                style={{
                  width: "100%",
                  height: height ? `${height}px` : "600px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <div>Unsupported file type. You can download the file:</div>
            )}
            <Space
              style={{
                width: "100%",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              {isPdf && (
                <div>
                  <Button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{ marginRight: "8px" }}
                  >
                    <LeftOutlined /> Previous Page
                  </Button>
                  <Button
                    onClick={handleNextPage}
                    disabled={currentPage === numPages}
                  >
                    Next Page <RightOutlined />
                  </Button>
                </div>
              )}

              <Button
                onClick={handleDownload}
                style={{ background: "#0096FF", color: "white" }}
              >
                <DownloadOutlined />
                Download File
              </Button>
            </Space>
          </>
        )}
      </Modal>
    </>
  );
};

export default AllFileViewer;
