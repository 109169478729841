import Tour from "reactour";
//For Home Tour Information Added
const HomeTour = (prop) => {
  return (
    <>
      <Tour
        onRequestClose={() => {
          prop.setIsTourOpen(false);
        }}
        // maskSpace={0}
        steps={[
          {
            selector: ".navbars__wrapper--avatar",
            content: "This is profile section",
          },
          {
            selector: ".icon1",
            content: "This is Dashboard section",
          },
          {
            selector: ".icon2",
            content: "This is mail section",
          },
          {
            selector: ".email_board",
            content: "This is email section",
            style: {
              padding: "20px", // or margin: '20px'
            },
          },
          {
            selector: ".tour2",
            content: "This is tender section",
            style: {
              padding: "10px",
            },
            maskSpace: 0,
          },
        ]}
        isOpen={prop.isTourOpen}
      />
    </>
  );
};

export default HomeTour;
