import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { cloneDeep, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contactUpdateSmall } from "services/redux/Reference/action";
import { contactCompanyGetDetails } from "services/redux/contact/companypeople/action";
import {
  cityListing,
  countryListing,
  scopeOfWorkListing,
  scopeofWorkAdd,
} from "services/remanentCalls";

const AddCompany = ({ addCompany, setAddCompany }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { contactCompanyGetDetailsResponse, contactCompanyGetDetailsLoading } =
    useSelector((state) => state.contactCompanyPerson);

  const { contactCompanyResponseLoading } = useSelector(
    (state) => state.reference
  );

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filterCitiesLists, setFilterCitiesLists] = useState([]);
  const [scopeSearch, setScopeSearch] = useState("");
  const [scopeTags, setScopeTags] = useState([]);
  const [compareTags, setCompareTags] = useState([]);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const fetchScopeofWorkListing = async () => {
    const resp = await scopeOfWorkListing({ searchText: scopeSearch }).catch(
      (err) => {
        message.error("failure");
      }
    );
    if (resp && resp.status === 200) {
      setScopeTags(resp.data);
    }
  };

  const fetchCities = async (CountryId) => {
    const resp = await cityListing({ CountryId }).catch((err) => {
      message.error("Failure");
    });

    const value = form.getFieldValue(`contactPersonGroupAddressDtos`);
    if (resp && resp.status === 200) {
      setCities(resp.data);
    }
  };

  const fetchCountryListing = async (body) => {
    const resp = await countryListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setCountries(resp.data);
    }
  };

  const addScopeofWork = async (value, option) => {
    if (isEmpty(option)) {
      const resp = await scopeofWorkAdd(value).catch((err) => {
        message.error("Failed to add this tag");
      });
    }
  };

  function info() {
    let data = scopeSearch.replace(/\s+/g, " ").trim();
    data = scopeSearch.split(" ");
    // let capitalizeData = data.map((element) => {
    //   return (
    //     element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
    //   );
    // });
    // let capitalizedCompareTags = compareTags.map((element) => {
    //   return (
    //     element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
    //   );
    // });
    data = data.filter((val) => !compareTags.includes(val));
    Modal.confirm({
      title: `Are you sure if you want to add new Scope of Work "${data}"`,
      okText: "Yes",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      cancelText: "No",
      onOk() {
        let val = data.map((list) => ({
          name: list,
        }));
        addScopeofWork(val, "");
        form.setFieldsValue({
          scopeOfWork: !isEmpty(form.getFieldsValue().scopeOfWork)
            ? [...form.getFieldsValue().scopeOfWork.concat(data)]
            : data,
        });
      },
      onCancel() {},
    });
  }

  const onChange = (value) => {
    const data = cities.filter((filtered) => filtered.countryId == value);
    let newFilterCities = cloneDeep(filterCitiesLists);
    newFilterCities = cloneDeep(data);
    setFilterCitiesLists(newFilterCities);
  };

  useEffect(() => {
    fetchCities();
    fetchCountryListing();
  }, []);

  useEffect(() => {
    if (scopeSearch) {
      fetchScopeofWorkListing();
    } else {
      setScopeTags([]);
    }
  }, [scopeSearch]);

  useEffect(() => {
    const selectedResponse =
      contactCompanyGetDetailsResponse?.contactCompanyGroupAddressDtos?.find(
        (x) =>
          x.isPrimaryAddress == true && x.contactLabelGroupName == "Address"
      );

    const filteredCities = cities.filter(
      (filtered) => filtered.countryId === selectedResponse?.countryId
    );
    setFilterCitiesLists(filteredCities);

    form.setFieldsValue({
      companyName: contactCompanyGetDetailsResponse.companyName,
      countryName: selectedResponse?.countryId || "",
      cityId: selectedResponse?.cityId || "",
      poBox: selectedResponse?.poBox || "",
      stateProvince: selectedResponse?.stateProvince || "",
      streetName: selectedResponse?.streetName || "",
      website:
        contactCompanyGetDetailsResponse.contactCompanyGroupLabelDtos?.find(
          (x) =>
            x.contactLabelGroupName === "Website" &&
            x.contactLabelName === "Official"
        )?.contactLabelDataValue || "",
      scopeOfWork:
        contactCompanyGetDetailsResponse?.scopeOfWork.split(",") || "",
    });
  }, [contactCompanyGetDetailsResponse, cities, form]);

  const submitCompany = (values) => {
    const selectedResponseAddress =
      contactCompanyGetDetailsResponse.contactCompanyGroupAddressDtos?.find(
        (x) =>
          x.isPrimaryAddress == true && x.contactLabelGroupName == "Address"
      );

    const newValues = {
      companyName: values.companyName,
      scopeOfWork: values.scopeOfWork && values.scopeOfWork.join(","),
      contactCompanyLightWebsiteupdate: {
        website: values.website,
        contactLabelDataId:
          contactCompanyGetDetailsResponse.contactCompanyGroupLabelDtos?.find(
            (x) =>
              x.contactLabelGroupName === "Website" &&
              x.contactLabelName === "Official"
          )?.contactLabelDataId,
      },
      contactCompanyLightAddressUpdateDto: {
        addressId: selectedResponseAddress?.addressId,
        contactLabelId: selectedResponseAddress?.contactLabelId,
        contactLabelGroupId: selectedResponseAddress?.contactLabelGroupId,
        contactCompanyId: contactCompanyGetDetailsResponse?.id,
        countryId: values.countryName,
        cityId: values.cityId,
        streetName: values.streetName,
      },
    };

    dispatch(
      contactUpdateSmall(contactCompanyGetDetailsResponse?.id, newValues)
    )
      .then(() => setAddCompany(false))
      .then(() => {
        dispatch(
          contactCompanyGetDetails(contactCompanyGetDetailsResponse?.id)
        );
      });
  };

  return (
    <Modal
      visible={addCompany}
      title="Update Company"
      footer={false}
      onCancel={() => {
        setAddCompany(false);
      }}
    >
      <Form {...layout} form={form} onFinish={submitCompany} labelAlign="left">
        <Form.Item
          name="companyName"
          label="Company Name"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="countryName"
          label="Country"
          rules={[{ required: true, message: "" }]}
        >
          <Select
            autoComplete="off"
            size="default"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch={true}
            onChange={(value) => onChange(value)}
            placeholder="Country"
          >
            {countries.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="cityId"
          label="City"
          rules={[{ required: true, message: "" }]}
        >
          <Select
            autoComplete="off"
            size="default"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch={true}
            placeholder="City"
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {filterCitiesLists?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="poBox" label="P.O.Box">
          <Input size="default" placeholder="Postal Code" autoComplete="off" />
        </Form.Item>

        <Form.Item name="stateProvince" label="State Province">
          <Input
            size="default"
            placeholder="State Province"
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item name="streetName" label="Street Name">
          <Input size="default" placeholder="Street Name" autoComplete="off" />
        </Form.Item>

        <Form.Item name="website" label="Website">
          <Input />
        </Form.Item>

        <Form.Item
          name="scopeOfWork"
          rules={[{ required: true }]}
          label="Scope"
        >
          <Select
            // bordered={false}
            autoComplete="off"
            placeholder="Scopes of Work"
            size="default"
            mode="multiple"
            onPopupScroll={(e) => {
              console.log("duplicated", e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                setScopeTags([]);
                setScopeSearch("");
                if (isEmpty(scopeTags) && !isEmpty(scopeSearch)) {
                  info();
                }
              }
            }}
            // onInputKeyDown={(e) => {
            //   if(e.key === "Enter"){
            //     setScopeTags([]);
            //     setScopeTags('')

            //   }

            // }}
            onSearch={(e) => {
              setScopeSearch(e);
            }}
            // onSelect={(value, option) => addScopeofWork(value, option)}
            // open={dropdownscope}
            listHeight={100}
            // // onInputKeyDown={onInputKeyDown}
            onClear={() => {
              setScopeSearch("");
            }}
          >
            {scopeTags?.map((tag) => (
              <Option value={tag.name} key={tag.id}>
                {tag.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row
          style={{
            justifyContent: "right",
            paddingTop: "10px",
            marginTop: "20px !important",
            gap: "10px",
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            autoComplete="off"
            loading={contactCompanyResponseLoading}
          >
            Submit
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddCompany;
