import { useState, useEffect, useCallback } from "react";
import {
  Input,
  Button,
  Select,
  Collapse,
  Checkbox,
  Form,
  // Row,
  // Col,
  message,
  Radio,
  // Popover,
  Modal,
  Spin,
  InputNumber,
  Skeleton,
  Card,
  Table,
  Typography,
} from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  StarFilled,
  PlusCircleOutlined,
  // MoreOutlined,
  PaperClipOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import QuickAddCompany from "views/Contact/ContactCompanyPerson/AddQuickCompany";
import {
  tenderBasicUpdate,
  tenderStageListing,
  tenderTypeListing,
  tenderBasicAdd,
  tenderFavAddUpdate,
  stageLevelAdd,
  getTenderBasicSearchPagination,
  // getDocumentPurchasePagintaion,
  tenderSubTypeListing,
} from "services/redux/Reference/Tender/action";
import { allCompanyListing } from "services/redux/Reference/action";
import DynamicImageUploader from "components/shared/DynamicImageUploader";
import { cqBaseUrl } from "utils/config";
import {
  sectorListing,
  getAllUsers,
  scopeOfWorkListing,
  scopeofWorkAdd,
  sectorsPagination,
} from "services/remanentCalls";
import { allCountryListing } from "services/redux/contact/contact.action";
import {
  getAllModality,
  getAllSectors,
  getAllStatus,
  getSubSectors,
} from "services/redux/officeresource/business/action";

// import { LogoImage } from "utils/imageConstants";
import { numberWithCommas, getDaysDifference } from "utils/commonFunc";
import storage from "utils/storage";
import queryString from "query-string";

import moment from "moment";
// import { RenderAwardStatus } from "./AwardStatus";
import axios from "axios";
import { appBaseUrl } from "utils/config";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import {
  isEmpty,
  // pickBy,
  groupBy,
  cloneDeep,
  // add,
  // values,
  // fromPairs,
  debounce,
  values,
} from "lodash";

import {
  tenderDocumentUpdate,
  // tenderDocumentGetDetails,
  tenderextensionGetBasicDetails,
  tenderGetBasicDetails,
} from "services/redux/Reference/Tender/action";
import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";

import AddUpdateParticipation from "../AddUpdateParticipation";

// import ImageUploaderMultiple from 'components/shared/ImageUploaderMultiple';

import {
  contactLabelGroupListing,
  dynamicFileLabelTypeUploaderDetailDtos,
} from "services/remanentCalls";

import Stages from "./Stages";
import RenderStageView from "./Stages/StagesView";
import TableDocs from "components/tables/business";
import { sameType } from "services/common";
import ProjectModal from "./ProjectModal";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
import pdfViewer from "components/Documents/ViewDocument";
import AllFileViewer from "components/Documents/allFileViewer";
import addCommaSeparator from "utils/inputCommaSeparator";
// import Participation from "./Participation";
const newWindow = "../../../assets/svg/newWindow.svg";

const { Option } = Select;
const { confirm } = Modal;

const Summary = ({
  isUpdate,
  setIsUpdate,
  setGetDetails,
  dynamicFileTypesState,
  dynamicTenderNoticeFileTypesState,
  dynamicTenderDocumentFileTypesState,
  dynamicROTOFileTypesState,
  dynamicExtensionFileTypesState,
  extensionData,
  setExtensionData,
  dynamicTenderDocumentileTypesState,
  getDetails,
  tenderBasicGetDetailsLoading,
  projectListing,
  selectedTenderRowId,
  setSelectedTenderRowId,
  setSearchKeyword,
  setShowChat,
  searchDataAtBusinessHeaderForm,
  tenderId,
}) => {
  const dispatch = useDispatch();
  const [adDocs, setAdDocs] = useState([]);

  const { Panel } = Collapse;
  const [showEditForm, setEditForm] = useState(false);
  const [addCompany] = Form.useForm();
  const [isFav, setIsFav] = useState(false);
  const [showExtensionDrawer, setExtensionDrawer] = useState(false);
  const [defaultUSD, setDefaultUSD] = useState();
  const [defaultNRP, setDefaultNRP] = useState();

  const [showofficalForm, setOfficialForm] = useState(false);
  const [scopeSearch, setScopeSearch] = useState("");
  const [test, setTest] = useState("update");

  const [groupedByDocuments, setGroupedByDocuments] = useState([]);
  const [showAddExtension, setShowAddExtension] = useState();
  const [groupedByDocumentstender, setGroupedByDocumentstender] = useState([]);
  const [responseNotes, setResponseNotes] = useState();
  const [showParticipation, setShowParticipation] = useState();
  const [editStages, setEditStages] = useState("");
  const [getStagesLevel, setGetStagesLevel] = useState();
  const [showSingleStageEdit, setShowSingleStageEdit] = useState(false);
  const [doubleStageEdit, setDoubleStageEdit] = useState(false);
  const [referencCode, setReferenceCode] = useState();
  const [showDoubleStages, setShowDoubleStage] = useState(false);
  const [typeListing, setTypeListing] = useState([]);
  const [subTypeListing, setSubTypeListing] = useState([]);
  const [searchTenderTitle, setSearchTenderTitle] = useState([]);
  const [value, setValue] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [companyTender, setCompanyTender] = useState("");
  const [contactCompanyList, setContactCompanyList] = useState([]);
  const [selectedFundAgency, setSelectedFundAgency] = useState("");
  const [selectedSearchedProjectTitle, setProjectTitleOnSearch] = useState("");

  let participantFrom = storage.getItem("userId");

  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState([]);
  const [selectedSectorsId, setSelectedSectorsId] = useState("");
  const [selectedSectorsItem, setSelectedSectorsItem] = useState([]);
  // const [showRenderDocumentPurchase, setshowRenderDocumentPurchase] = useState(true);
  // const [showRenderParticipation, setshowRenderParticipation] = useState(true);
  const [showDocumentPurchae, setDocumentPurchase] = useState([]);

  const [getDocPurchaseDetails, setDocPurchaseDetails] = useState();
  const [uploadersExceptProfile, setUploadersExceptProfile] = useState([]);
  const [isTruncated, setIsTruncated] = useState(false);
  const [checked, setChecked] = useState(false);
  const [advancedChecked, setAdvancedChecked] = useState(false);
  const [user, setUsers] = useState([]);
  const [showStages, setShowStages] = useState("singleStages");

  const [setForm, setOpenForm] = useState(false);
  const [setShowAddStage, showAddStage] = useState(false);
  const [scopeTags, setScopeTags] = useState([]);
  const [compareTags, setCompareTags] = useState([]);
  const [commaSeparatorNum, setCommaSeparatorNum] = useState("");
  const [documentOffFile, setDocumentOffFile] = useState([]);
  const [openingDate, setOpeningDate] = useState("");
  const [submissionDate, setSubmissionDate] = useState("");
  const [searchDataBusinessHeader, setSearchDataBusinessHeader] = useState();
  const [projectModal, setProjectModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCategoryItem, setSelectedCategoryItem] = useState([]);

  const { subSectorsListingLoading, subSectorsListingResponse } = useSelector(
    (state) => state.businessCenter
  );

  const { contactCompanyPaginationResponse, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);
  const { quickContactCompanyAdd, quickContactCompanyLoader } = useSelector(
    (state) => state.contactCompanyPerson
  );
  const { allCurrencyListingResponse } = useSelector(
    (state) => state.reference
  );
  const { token } = useSelector(
    //for accessing to the token form redux store
    (state) => state.auth
  );
  const { tenderBasicExtensionGetDetailsResponse } = useSelector(
    (state) => state.tender
  );

  const getCompanyPagination = (SkipCount = 0, ForPagination = false) => {
    dispatch(
      getContactCompanyPagination(
        {
          SkipCount,
          SearchKeyword: value,
          MaxResultCount: 50,
        },
        ForPagination
      )
    );
  };

  useEffect(() => {
    setAdDocs(getDetails?.tenderBasicAdvanceDocumentDtos);
  }, [getDetails?.tenderBasicAdvanceDocumentDtos]);

  useEffect(() => {
    fetchDynamicFileLabelTypeUploaderDetailDtos();
  }, []);

  // useEffect(() => {
  //   form.setFieldsValue({ fundingAgencyId: selectedFundAgency });
  // }, [selectedFundAgency]);

  // added
  const numberCommaSepartor = (event) => {
    let budget = form.getFieldValue("budget");

    const commaNum = budget.replace(/\,/g, "");
    if (isNaN(Number(commaNum))) {
      form.setFieldsValue({ budget: commaSeparatorNum });
      return;
    }

    let tmp = budget.replace(/,/g, "");
    let val = Number(tmp).toLocaleString();
    if (tmp == "") {
      form.setFieldsValue({ budget: "" });
    } else {
      form.setFieldsValue({ budget: val });
      setCommaSeparatorNum(val);
    }
  };

  const addScopeofWork = async (value, option) => {
    if (isEmpty(option)) {
      const resp = await scopeofWorkAdd(value).catch((err) => {
        message.error("Failed to add this tag");
      });
    }
  };

  const fetchScopeofWorkListing = async () => {
    const resp = await scopeOfWorkListing({ searchText: scopeSearch }).catch(
      (err) => {
        message.error("failure");
      }
    );
    if (resp && resp.status === 200) {
      setScopeTags(resp.data);
    }
  };

  const fetchDynamicFileLabelTypeUploaderDetailDtos = async () => {
    const resp = await dynamicFileLabelTypeUploaderDetailDtos("tender").catch(
      (err) => {
        message.error("Failure");
      }
    );
    if (resp && resp.status === 200) {
      let newUploadersExcept = resp.data.filter(
        (respData) => respData.name == "AdvanceDocument"
      );
      setUploadersExceptProfile(newUploadersExcept);
    }
  };

  const {
    tenderTypeListingResponse,
    documentUploadResp,
    tenderDocumentGetDetailsResponse,
    tenderBasicUpdateResponse,
    tenderBasicAddResponse,
    tenderBasicAddLoadingResponse,
    tenderBasicUpdateLoading,
    stageLevelAddResponse,
    stageLevelUpdate,
    tenderBasicUpdateStageResponse,
    stageDocumentResp,
    tenderSubTypeListingResponse,
    tenderSubTypeListingLoading,
  } = useSelector((state) => state.tender);

  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);

  const { allCountryListingResponse } = useSelector((state) => state.contact);

  const [form] = Form.useForm();
  const [singleStageForm] = Form.useForm();
  const [awardStatusForm] = Form.useForm();
  const [participationForm] = Form.useForm();
  const [documentPurchaseForm] = Form.useForm();

  const handleView = (src) => {
    setTimeout(() => {
      pdfViewer1(src);
    }, 1000);
  };

  function getSearchListTender(query) {
    axios
      .get(
        `${appBaseUrl}/referenceManagement/tenderBasicPagination?${queryString.stringify(
          query
        )}`
      )
      .then((resp) => {
        let data = resp.data?.items;
        if (data.length > 0) setSearchTenderTitle(data.slice(0, 5));
        else setSearchTenderTitle([]);
      })
      .catch((err) => {});
  }

  const handleTitleChange = (event) => {
    const allowOnlyOneSpace = (str) => {
      return str.endsWith(" ") ? str.trim() + " " : str.trim();
    };
    form.setFieldsValue({ title: allowOnlyOneSpace(event.target.value) });
    if (event.target.value.length >= 3) {
      let query = {
        MaxResultCount: 10,
        SkipCount: 0,
        SearchKeyword: event.target.value,
      };
      getSearchListTender(query);
    } else {
      setSearchTenderTitle([]);
    }
  };

  const handleSelectCategory = (values, option) => {
    setSelectedCategoryId(values);
  };

  const handleSectorsCategory = (values, option) => {
    setSelectedSectorsId(values);
  };

  const referenceHeader = () => (
    <div>
      {getDetails?.referenceCode ? (
        <div
          style={{
            display: "flex",
            // width: 21,
            height: 28,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            {
              <StarFilled
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch(
                    tenderFavAddUpdate({ tenderId: getDetails?.id }, getDetails)
                  );
                }}
                style={{
                  color: `${getDetails?.isFavourite ? "#FBA525" : "grey"}`,
                }}
              />
            }
          </div>

          <div
            className="profiledetails__rowedit--data"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            <span
              className="referenceDetails--subtitle"
              style={{
                color: "blue",
                whiteSpace: "nowrap",
                //   overflow: "hidden",
                //   textOverflow: "ellipsis",
                //   display: "-webkit-box",
                //   WebkitLineClamp: 1,
                //   WebkitBoxOrient: "vertical",
                //   whiteSpace: "nowrap",
                //   maxWidth: "100%",
              }}
              title={getDetails?.title}
            >
              {selectedTenderRowId} [{getDetails?.title}]
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  const changetest = (e) => {
    setShowParticipation(e);
  };

  const changeForthComing = (referenceCode) => {
    setReferenceCode(referencCode);
  };

  // subcategory
  const debouncedDispatch = useCallback(
    debounce((categoryId) => {
      dispatch(tenderSubTypeListing({ subTender: categoryId }));
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (!selectedCategoryId) return;

    debouncedDispatch(selectedCategoryId);
  }, [selectedCategoryId, debouncedDispatch]);
  // upto here

  // subsectors
  const debouncedDispatchSectors = useCallback(
    debounce((categoryId) => {
      dispatch(getSubSectors({ subSector: categoryId }));
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (!selectedSectorsId) return;

    debouncedDispatchSectors(selectedSectorsId);
  }, [selectedSectorsId, debouncedDispatchSectors]);
  // endhere

  useEffect(() => {
    if (!isEmpty(contactCompanyPaginationResponse?.items)) {
      // setContactCompanyList(contactCompanyPaginationResponse?.items);
      setContactCompanyList(
        contactCompanyPaginationResponse?.items.filter((data) => {
          return data.contactCompanyId !== getDetails?.clientId;
        })
      );
    } else {
    }
  }, [contactCompanyPaginationResponse]);

  useEffect(() => {
    if (isEmpty(value)) setContactCompanyList([]);
    if (isEmpty(value)) return;
    if (
      !isEmpty(contactCompanyList) &&
      isEmpty(contactCompanyPaginationResponse?.items)
    )
      return;

    const timeoutId = setTimeout(() => getCompanyPagination(), 500);
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    if (quickContactCompanyAdd) {
      // dispatch(allCountryListing());

      addCompany.setFieldsValue({
        scopeOfWork: [],
        companyName: "",
        countryId: "",
        isActive: false,
        isFavorite: false,
        cityId: "",
      });

      setVisibleModal(false);

      setTimeout(() => {
        let newClient;
        if (companyTender == "companyclient") {
          newClient = !isEmpty(
            form.getFieldValue("tenderBasicSecondaryInchargeDtos")
          )
            ? [
                ...form.getFieldValue("tenderBasicSecondaryInchargeDtos"),
                quickContactCompanyAdd.id,
              ]
            : [quickContactCompanyAdd.id];
        }

        companyTender == "companyclient" &&
          form.setFieldsValue({
            clientId: quickContactCompanyAdd.id,
            fundingAgencyId: quickContactCompanyAdd.id,
          });
        form.setFieldsValue({
          // fundingAgencyId:getDetails?.fundingAgencyId
        });
      }, 500);
    }
  }, [quickContactCompanyAdd]);

  useEffect(() => {
    if (!isEmpty(documentUploadResp)) {
      setEditForm(true);
    }
  }, [documentUploadResp]);

  useEffect(() => {
    setChecked(showofficalForm);
  }, [showofficalForm]);

  useEffect(() => {
    if (getDetails?.isFavourite) {
      setIsFav(true);
    } else if (isEmpty(getDetails)) {
      setIsFav(false);
    }
  }, [getDetails]);

  useEffect(() => {
    if (!isEmpty(allCurrencyListingResponse)) {
      let value = "";
      allCurrencyListingResponse?.map((currency) => {
        if (currency.abbvr == "USD") {
          value = currency.currencyId;
        }
      });
      setDefaultUSD(value);
    }
    if (!isEmpty(allCurrencyListingResponse)) {
      let nprValue = "";
      allCurrencyListingResponse?.map((currency) => {
        if (currency.abbvr == "NPR") {
          nprValue = currency.currencyId;
        }
      });
      setDefaultNRP(nprValue);
    }
  }, [allCurrencyListingResponse]);

  useEffect(() => {
    if (getDetails) {
      let documentsUploadedFiles = cloneDeep(
        getDetails?.tenderBasicAdvanceDocumentDtos
      );

      let groupByDocuments = groupBy(
        documentsUploadedFiles,
        "fileLableTypeName"
      );

      setGroupedByDocuments(groupByDocuments);
      setOfficialForm(getDetails?.officialInvitation ? true : false);
      setOpenForm(false);
      if (getDetails.tenderStageLevelDtos) {
        setShowParticipation(
          getDetails.tenderStageLevelDtos[0]?.submissionDate
        );
      }
      setShowStages(
        getDetails.tenderProcessType === 1 ? "singleStages" : "doubleStages"
      );
      setReferenceCode(getDetails.referenceCode);
      // setOfficialForm(getDetails.officialInvitation ? true : false);
      setChecked(getDetails.officialInvitation ? true : false);

      singleStageForm.resetFields();
    }
  }, [getDetails]);

  useEffect(() => {
    if (!isEmpty(getDetails)) {
      setResponseNotes(getDetails?.note);
    }
    if (!isEmpty(getDetails?.tenderDocumentPurchaseAddUpdateDtos)) {
      setOpenForm(true);
    }
  }, [getDetails]);

  useEffect(() => {
    if (!isEmpty(tenderTypeListingResponse)) {
      setTypeListing(tenderTypeListingResponse);
    }
  }, [tenderTypeListingResponse]);

  useEffect(() => {
    if (!isEmpty(tenderSubTypeListingResponse)) {
      setSubTypeListing(tenderSubTypeListingResponse);
    } else {
      setSubTypeListing([]);
    }
  }, [tenderSubTypeListingResponse]);

  useEffect(() => {
    if (!isEmpty(subSectorsListingResponse)) {
      setSubSectors(subSectorsListingResponse);
    } else {
      setSubSectors([]);
    }
  }, [subSectorsListingResponse]);

  useEffect(() => {
    dispatch(tenderTypeListing());
    dispatch(tenderStageListing());
    dispatch(allCountryListing());
    dispatch(allCompanyListing());
  }, []);

  useEffect(() => {
    if (scopeSearch) {
      fetchScopeofWorkListing();
    } else {
      setScopeTags([]);
    }
  }, [scopeSearch]);

  useEffect(() => {
    if (isUpdate === "add") {
      singleStageForm.resetFields();
      documentPurchaseForm.resetFields();
      awardStatusForm.resetFields();
      form.resetFields();
      setEditForm(true);
      setGetDetails("");
    } else if (isUpdate === "details") {
      form.resetFields();
    }
  }, [isUpdate]);

  useEffect(() => {
    if (!isEmpty(getDetails)) {
      setEditForm(false);
      setShowSingleStageEdit(false);
    } else if (isEmpty(getDetails)) {
      setEditForm(true);
      setOfficialForm(false);
      setChecked(false);
    }
  }, [getDetails]);

  useEffect(() => {
    if (tenderBasicAddResponse) {
      // setSearchKeyword(tenderBasicAddResponse.referenceCode);
      setSelectedTenderRowId(tenderBasicAddResponse.referenceCode);
      dispatch(tenderGetBasicDetails(tenderBasicAddResponse.id));
      setShowSingleStageEdit(true);
    }
  }, [tenderBasicAddResponse]);

  useEffect(() => {
    if (tenderBasicUpdateResponse) {
      setShowSingleStageEdit(true);
      setEditForm(false);

      dispatch(tenderGetBasicDetails(tenderBasicUpdateResponse.id));
    }
  }, [tenderBasicUpdateResponse]);

  useEffect(() => {
    if (stageLevelAddResponse) {
      singleStageForm.resetFields();
      setShowSingleStageEdit(false);
      dispatch(tenderGetBasicDetails(stageLevelAddResponse.tenderBasicId));
    }
  }, [stageLevelAddResponse]);

  useEffect(() => {
    if (stageLevelUpdate) {
      setShowSingleStageEdit(false);
      singleStageForm.resetFields();
      dispatch(tenderGetBasicDetails(stageLevelUpdate.tenderBasicId));
    }
  }, [stageLevelUpdate]);

  useEffect(() => {
    form.setFieldsValue({
      primaryInchargeId:
        form.getFieldValue()["primaryInchargeId"] || participantFrom,
    });
  });

  useEffect(() => {
    if (isEmpty(getDetails)) {
      form.resetFields();
      return;
    } else {
      setSelectedCategoryId(getDetails?.tenderTypeId);
      setSelectedCategoryItem(getDetails?.subTenderType);
      setSelectedSectorsId(getDetails?.sectorId);
      setSelectedSectorsItem(getDetails?.subSectorType);

      form.setFieldsValue({
        ...getDetails,
        ...getDetails.tenderAwardStatusDto,

        letterOfInternDate: moment(
          getDetails.tenderAwardStatusDto?.letterOfInternDate
        ),
        letterOfAwardDate: moment(
          getDetails.tenderAwardStatusDto?.letterOfAwardDate
        ),
        contactDate: moment(getDetails.tenderAwardStatusDto?.contactDate),
        subTenderTypeId: getDetails.subTenderType?.map((item) => item.id),
        subSectorId: getDetails.subSectorType?.map((item) => item.id),
        tenderBasicSecondaryInchargeDtos:
          getDetails.tenderBasicSecondaryInchargeDtos?.map(
            (secondary) => secondary.secondaryInchargeId
          ),
        scopeOfWork: getDetails?.scopeOfWork?.split(","),
        budget: Number(getDetails.budget)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        documentCost: Number(getDetails.documentCost)?.toFixed(2),
        tenderDocumentPurchaseAddUpdateDtos:
          getDetails.tenderDocumentPurchaseAddUpdateDtos?.map(
            (purchaseDtos) => ({
              ...purchaseDtos,
              purchaseDate: moment(purchaseDtos.purchaseDate),
            })
          ),
        scopeOfWork: getDetails?.scopeOfWork?.split(","),
        tenderParticipationAddUpdateDtos:
          getDetails.tenderParticipationDtos?.map((purchaseDtos) => ({
            ...purchaseDtos,
            bidValidity: moment(purchaseDtos.bidValidity),
            bidSecurityValidity: moment(purchaseDtos.bidSecurityValidity),
          })),
        bidValidity: moment(getDetails.bidValidity),
        submissionDate: moment(getDetails.submissionDate),
        bankGuranteeValidity: moment(getDetails.bankGuranteeValidity),
        publicationDate: moment(getDetails.publicationDate),
        bankGuranteeValue: Number(getDetails.bankGuranteeValue).toFixed(2),
      });
    }

    //Advnace Document
    let documentsUploadedFiles = [getDetails?.uploadedFileDtos];

    let groupByDocuments = groupBy(documentsUploadedFiles, "fileLableTypeName");

    dynamicFileTypesState.map((file) => {
      if (!isEmpty(getDetails?.tenderBasicAdvanceDocumentDtos)) {
        let fileName = file.name;
        fileName &&
          groupedByDocuments.hasOwnProperty(fileName) &&
          form.setFieldsValue({
            [`${file.name}FileDtos`]: groupedByDocuments[`${fileName}`],
          });
      } else {
        form.setFieldsValue({
          [`${file.name}FileDtos`]: [],
        });
      }
    });

    let tenderDocumentUploadedFiles = cloneDeep(
      getDetails?.tenderDocumentFileDtos
    );

    setGroupedByDocumentstender(tenderDocumentUploadedFiles);
    // dynamicTenderDocumentileTypesState.map((file) => {
    // 	let fileName = file.name;
    // 	fileName &&
    // 		groupedByDocumentstender.hasOwnProperty(fileName) &&
    // 		form.setFieldsValue({
    // 			[`${file.name}FileDtos`]: groupedByDocumentstender[`${fileName}`],
    // 		});
    // });

    //for getting tender Notice Document
    // let noticeUploadedFiles = [...getDetails?.tenderNoticeFileDtos];
    // let groupByDocumentsNotice = groupBy(noticeUploadedFiles, 'fileLableTypeName');
    // setGroupedByDocumentsNotice(groupByDocumentsNotice);
    // dynamicTenderNoticeFileTypesState.map((file) => {
    // 	let fileName = file.name;
    // 	fileName &&
    // 		groupedByDocumentsNotice.hasOwnProperty(fileName) &&
    // 		form.setFieldsValue({
    // 			[`${file.name}FileDtos`]: groupedByDocumentsNotice[`${fileName}`],
    // 		});
    // });
  }, [getDetails, showEditForm]);

  const popOver = (value, header) => {
    const textContent = (
      <div>
        <h3 className="popOver--title">Please Enter {header}</h3>
        <div className="popOver--content">
          <Input defaultValue={value} />
          <div className="popUpbuttons">
            <Button
              type="primary"
              className="popUpbuttons--btn-success"
              icon={<CheckOutlined style={{ fontSize: 12 }} />}
            />
            <Button
              type="danger"
              className="popUpbuttons--btn-danger"
              icon={<CloseOutlined style={{ fontSize: 12 }} />}
            />
          </div>
        </div>
      </div>
    );

    return <div>{value}</div>;
  };

  const getDayDiffSecurity = (startDate) => {
    var date1 = new Date(startDate);
    var date2 = new Date(startDate);
    var presentDate = new Date();

    if ((isUpdate = "details")) {
      // To calculate the time difference of two dates
      var Difference_In_Time = date1.getTime() - date2.getTime();

      // To calculate the no. of days between two dates
      var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    } else if (isUpdate == "add") {
      var Difference_In_Time = presentDate.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;
      setShowParticipation(diff);
    }

    return `${diff}`;
  };

  useEffect(() => {
    fetchAllSector();
    fetchAllUsers();
    if (isEmpty(sisterCompanyListing)) {
      dispatch(getSisterCompaniesListings());
    }
  }, []);

  const showExtensionDrawers = () => {
    setExtensionDrawer(true);
  };
  const addUpdateExtensions = (id) => {
    dispatch(tenderextensionGetBasicDetails(id));
  };

  const onClose = () => {
    setExtensionDrawer(false);
  };
  const fetchAllSector = async () => {
    const resp = await sectorsPagination().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setSectors(resp.data.items);
    }
  };

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  const officalForm = (e) => {
    let checked = e.target.checked;

    if (checked === true) {
      setOfficialForm(true);
      // let code = referencCode?.replace("F", "T");
      let code = referencCode?.replace("F", "");
      setReferenceCode(code);
      // setSearchKeyword(code);
      setChecked(true);
    } else {
      // let code = referencCode?.replace("T", "F");
      let code = referencCode?.replace("T", "FT");
      setReferenceCode(code);
      setOfficialForm(false);
      setChecked(false);
    }
  };

  const advancedInformationForm = (e) => {
    let checked = e.target.checked;
    if (checked === true) {
    }
  };

  const onSelect = (e) => {
    if (e.target.checked === true) {
      setOpenForm(true);
      form.setFieldsValue({
        documentsPurchasedtos: [
          {
            purchaseDate: "",
            companyName: "",
            through: "",
            claim: "",
          },
        ],
      });
    } else {
      setOpenForm(false);
      form.setFieldsValue({
        documentsPurchasedtos: [],
      });
    }
  };

  const onAddPurchaseDoc = (add, data) => {
    add();
    // setAddUpdateTenderDoc(data);
  };
  const openingDate1 = openingDate?.slice(0, 4);
  const openingDate2 = openingDate?.slice(5, 7);
  const openingDate3 = openingDate?.slice(8, 10);
  const openingTime1 =
    openingDate?.length == 20
      ? 0 + openingDate?.slice(13, 17)
      : openingDate?.slice(13, 18);
  const openingDateAndTime = `${openingDate1}-${openingDate2}-${openingDate3}T${openingTime1}:00.794Z`;

  const submissionDate1 = submissionDate?.slice(0, 4);
  const submissionDate2 = submissionDate?.slice(5, 7);
  const submissionDate3 = submissionDate?.slice(8, 10);
  const submissionTime1 =
    submissionDate?.length == 20
      ? 0 + submissionDate?.slice(13, 17)
      : submissionDate?.slice(13, 18);
  const submissionDateAndTime = `${submissionDate1}-${submissionDate2}-${submissionDate3}T${submissionTime1}:00.794Z`;

  const getCurrency = (value) => {};
  const [doubleStageForm] = Form.useForm();

  const addUpdateOfficialInviation = async () => {
    let singleStages = await singleStageForm.validateFields();
    let doubleStages = await doubleStageForm.validateFields();
    let awardStatusData = await awardStatusForm.validateFields();
    let documentPurchaseData = await documentPurchaseForm.validateFields();
    let singleStagesBody = {
      officialInvitation: true,
      tenderProcessType: showStages === "singleStages" ? 1 : 2,
      tenderBasicId: getDetails.id,
      ...singleStages,
      documentCostCurrencyId: singleStages.documentCostCurrencyId
        ? singleStages.documentCostCurrencyId
        : defaultNRP,
      bidSecurityValueCurrencyId: singleStages.bidSecurityValueCurrencyId
        ? singleStages.bidSecurityValueCurrencyId
        : defaultUSD,
      stageLevel:
        showStages === "singleStages"
          ? "stage1"
          : showStages === "doubleStages"
          ? `${
              getDetails.tenderStageLevelDtos?.length >= 1 ? "stage2" : "stage1"
            }`
          : "",

      tenderStageTenderNoticeFileList: singleStages.TenderNoticeFileDtos,
      tenderStageTenderOffDocumentFileList: documentOffFile,
    };

    let body = {
      ...singleStagesBody,
      submissionDate: moment(singleStages?.submissionDate?._d).format(
        "YYYY-MM-DD, hh:mm A"
      ),
      tenderBasicId: getDetails.id,
      isActive: true,
    };

    dispatch(stageLevelAdd(body));
  };

  function info() {
    let data = scopeSearch.replace(/\s+/g, " ").trim();
    data = scopeSearch.split(" ");
    // let capitalizeData = data.map((element) => {
    //   return (
    //     element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
    //   );
    // });
    // let capitalizedCompareTags = compareTags.map((element) => {
    //   return (
    //     element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
    //   );
    // });
    data = data.filter((val) => !compareTags.includes(val));
    Modal.confirm({
      title: `Are you sure if you want to add new Scope of Work "${data}"`,
      okText: "Yes",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      cancelText: "No",
      onOk() {
        let val = data.map((list) => ({
          name: list,
        }));
        addScopeofWork(val, "");
        form.setFieldsValue({
          scopeOfWork: !isEmpty(form.getFieldsValue().scopeOfWork)
            ? [...form.getFieldsValue().scopeOfWork.concat(data)]
            : data,
        });
      },
      onCancel() {},
    });
  }
  function showConfirm() {
    confirm({
      title: "Do you Want to delete this Company?",
      icon: <ExclamationCircleOutlined />,

      onOk() {
        setTest("delete");
      },
      onCancel() {},
    });
  }

  const handleOnBlur = (event) => {
    setSearchTenderTitle([]);
  };

  const onFinish = async () => {
    let newValues = await form.validateFields();
    let singleStages = await singleStageForm.validateFields();
    let doubleStages = await doubleStageForm.validateFields();
    let awardStatusData = await awardStatusForm.validateFields();
    let documentPurchaseData = await documentPurchaseForm.validateFields();

    let singleStagesBody = [
      {
        ...documentPurchaseData,
        ...singleStages,
        documentCostCurrencyId: singleStages.documentCostCurrencyId
          ? singleStages.documentCostCurrencyId
          : defaultNRP,
        bidSecurityValueCurrencyId: singleStages.bidSecurityValueCurrencyId
          ? singleStages.bidSecurityValueCurrencyId
          : defaultUSD,
        stageLevel:
          showStages === "singleStages"
            ? "stage1"
            : showStages === "doubleStages"
            ? "stage1"
            : "",
      },
    ];
    let doubleStagesBody = [
      {
        ...doubleStages,
        ...documentPurchaseData,
        documentCostCurrencyId: doubleStages.documentCostCurrencyId
          ? doubleStages.documentCostCurrencyId
          : defaultNRP,
        bidSecurityValueCurrencyId: doubleStages.bidSecurityValueCurrencyId
          ? doubleStages.bidSecurityValueCurrencyId
          : defaultUSD,
        stageLevel: showStages === "doubleStages" ? "stage2" : "",
      },
    ];

    let uploadedFileDtos = [];

    dynamicFileTypesState?.map((file) => {
      if (!isEmpty(newValues[`${file.name}FileDtos`])) {
        uploadedFileDtos = uploadedFileDtos.concat(
          newValues[`${file.name}FileDtos`]
        );
      }
    });

    // dynamicFileTypesState.map((file) => {
    // 	if (!isEmpty(newValues[`${file.name}FileDtos`])) {
    // 		uploadFiles = uploadFiles.concat(newValues[`${file.name}FileDtos`]);
    // 	}
    // });

    // dispatch(documentFilesAddUpdate(body));
    newValues?.AdvanceDocumentFileDtos?.map((x) => {
      if (x?.fileInformationId == null && x?.displayFileName) {
        adDocs?.push(x);
      }
    });

    if (getDetails?.id) {
      let uploadedFileDtos = [];
      dynamicFileTypesState?.map((file) => {
        if (!isEmpty(newValues[`${file.name}FileDtos`])) {
          uploadedFileDtos = uploadedFileDtos.concat(
            newValues[`${file.name}FileDtos`]
          );
        }
      });
      // dispatch(tenderDocumentAdd(uploadedFileDtos, getDetails.id));
      let fundingAgencies = form.getFieldValue("fundingAgencyId");

      if (!Array.isArray(fundingAgencies)) {
        fundingAgencies = [fundingAgencies];
      } else {
        fundingAgencies = fundingAgencies;
      }

      let val = {
        ...newValues,
        subTenderTypeId: selectedCategoryItem,
        subSectorId: selectedSectorsItem,
        tenderProcessType: showStages === "singleStages" ? 1 : 2,
        referenceCode: getDetails.referenceCode,
        id: getDetails.id,
        scopeOfWork: newValues.scopeOfWork.join(","),
        submissionDate: moment(newValues.submissionDate).format("YYYY/MM/DD"),

        tenderBasicSecondaryInchargeDtos:
          newValues.tenderBasicSecondaryInchargeDtos?.map((value) => {
            return {
              secondaryInchargeId: value,
            };
          }),
        // tenderBasicAdvancedDocumentDtos: newValues.AdvanceDocumentFileDtos,
        // tenderBasicAdvancedDocumentDtos: adDocs,
        isFavourite: getDetails.isFavourite,
        tenderBasicAdvancedDocumentDtos: adDocs
          ? adDocs
          : newValues.AdvanceDocumentFileDtos,
        budget: Number(newValues.budget.replace(/\,/g, "")).toFixed(2),
        bankGuranteeValue: Number(newValues.bankGuranteeValue),

        tenderBasicFundingAgencyDtos: fundingAgencies?.map((x) => {
          return {
            tenderBasicFundingAgencyId: x,
          };
        }),
      };

      delete val.fundingAgencyId;
      dispatch(tenderBasicUpdate(val));
    } else {
      let fundingAgencies = form.getFieldValue("fundingAgencyId");

      if (!Array.isArray(fundingAgencies)) {
        fundingAgencies = [fundingAgencies];
      } else {
        fundingAgencies = fundingAgencies;
      }
      let body = {
        ...newValues,
        tenderBasicAdvancedDocumentDtos: newValues.AdvanceDocumentFileDtos,
        // tenderBasicAdvancedDocumentDtos: adDocs,
        // tenderBasicAdvancedDocumentDtos: adDocs
        //   ? adDocs
        //   : newValues.AdvanceDocumentFileDtos,
        tenderBasicSecondaryInchargeDtos:
          newValues.tenderBasicSecondaryInchargeDtos?.map((value) => {
            return {
              secondaryInchargeId: value,
            };
          }),
        isFavourite: isFav,
        scopeOfWork: newValues.scopeOfWork.join(","),
        tenderProcessType: showofficalForm
          ? showStages === "singleStages"
            ? 1
            : 2
          : 0,
        budgetCurrencyId: newValues.budgetCurrencyId
          ? newValues.budgetCurrencyId
          : defaultUSD,
        budget: Number(newValues.budget.replace(/\,/g, "")).toFixed(2),
        bankGuranteeValueCurrencyId: newValues.bankGuranteeValueCurrencyId
          ? newValues.bankGuranteeValueCurrencyId
          : defaultUSD,
        documentCostCurrencyId: newValues.bankGuranteeValueCurrencyId
          ? newValues.documentCostCurrencyId
          : defaultNRP,
        officialInvitation: showofficalForm,
        tenderBasicFundingAgencyDtos: fundingAgencies?.map((x) => {
          return {
            tenderBasicFundingAgencyId: x,
          };
        }),
      };
      delete body.fundingAgencyId;
      dispatch(tenderBasicAdd(body));
    }
  };

  const onAddUpdateDocumentPurchase = (newAddDocValues) => {
    let newDoucPurchase = [
      {
        ...newAddDocValues,
        claim: newAddDocValues.claim === "Yes" ? true : false,
        tenderBasicId: getDocPurchaseDetails.tenderBasicId,
        id: getDocPurchaseDetails.id,
      },
    ];

    dispatch(
      tenderDocumentUpdate(newDoucPurchase, getDocPurchaseDetails.tenderBasicId)
    );
  };

  const onAddnewDocPurchase = (AddDocValues) => {
    // let newDoucmentPurhcase = newAddDocValues.tenderDocumentPurchaseAddUpdateDtos.map((obj) => ({
    // 	contactCompanyId: obj.contactCompanyId,
    // 	throughId: obj.throughCompanyId,
    // 	tenderBasicId: getDocPurchaseDetails.id,
    // 	claim: newAddDocValues.tenderDocumentPurchaseAddUpdateDtos.claim == 'true' ? 'true' : 'false',
    // 	purchaseDate: obj.purchaseDate,
    // }));
    let id = getDetails?.id;
    let newDoucmentPurhcase =
      AddDocValues.tenderDocumentPurchaseAddUpdateDtos?.map((obj) => ({
        contactCompanyId: obj.contactCompanyId,
        throughCompanyId: obj.throughCompanyId,
        tenderBasicId: id,
        claim:
          AddDocValues.tenderDocumentPurchaseAddUpdateDtos?.claim == "true"
            ? "true"
            : "false",
        purchaseDate: obj.purchaseDate,
      }));
    dispatch(tenderDocumentUpdate(newDoucmentPurhcase, id));
  };

  const renderTender = () => {
    let submissionDate = getDetails?.submissionDate;

    const getDayDiffSubmission = (startDate) => {
      var date1 = new Date(startDate);
      var date2 = new Date();
      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var submissionsDiff =
        Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;

      // let days = moment.duration(diff).days();
      return `${submissionsDiff} `;
    };
    const getDayDiffVadlitiy = (ValidityDate) => {
      var date1 = new Date(ValidityDate);
      var date2 = new Date(submissionDate);

      var Difference_In_Time = date1.getTime() - date2.getTime();

      var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;

      return `${diff} `;
    };

    return (
      <>
        <div className="business-information-wrapper" style={{ marginTop: 10 }}>
          {!isEmpty(getDetails) ? (
            <>
              {getDetails?.referenceCode && (
                // <div className="business-header">
                <div
                  style={{
                    background: "#d8ddeb",
                    padding: " 0px 7px",
                    height: "26px",
                  }}
                >
                  <div className="business-header-title">
                    {referenceHeader()}
                  </div>
                </div>
              )}
              <div className="business-tender-project-info-wrapper">
                {getDetails?.title && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Title</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span> {popOver(getDetails?.title, "Title")}</span>
                    </div>
                  </div>
                )}
                {getDetails?.budget ? (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Budget</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        {" "}
                        {getDetails?.budgetCurrencySymbol} &nbsp;
                        {numberWithCommas(getDetails?.budget)}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {getDetails?.projectTitle && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Project (if any)</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        {popOver(getDetails?.projectTitle, "Project")}
                      </span>
                    </div>
                  </div>
                )}
                {getDetails?.clientName && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Client</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>{`${getDetails?.clientName} ${
                        getDetails.clientAbbrevation
                          ? `[${getDetails.clientAbbrevation}]`
                          : ""
                      }`}</span>
                    </div>
                  </div>
                )}
                {getDetails?.department && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span> Department</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        {" "}
                        {popOver(getDetails?.department, "Department")}
                      </span>
                    </div>
                  </div>
                )}
                {getDetails?.tenderTypeName && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      {/* <span> Type</span> */}
                      <span>Category</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>{getDetails?.tenderTypeName}</span>
                    </div>
                  </div>
                )}
                {getDetails?.subTenderType && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      {/* <span> Type</span> */}
                      <span>Sub Category</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        {" "}
                        {getDetails?.subTenderType
                          .map((item) => item.tenderTypeName)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                )}
                {getDetails?.sectorName && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Sector</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>{getDetails?.sectorName}</span>
                    </div>
                  </div>
                )}
                {getDetails?.sectorName && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Sub Sector</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        {" "}
                        {getDetails?.subSectorType
                          .map((item) => item.sectorName)
                          .join(", ")}
                      </span>
                    </div>
                  </div>
                )}

                {getDetails?.primaryInchargeName && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Primary Incharge</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>{`${getDetails?.primaryInchargeName} ${
                        getDetails?.primaryInchargeUserName
                          ? `[${getDetails?.primaryInchargeUserName}]`
                          : ""
                      }`}</span>
                    </div>
                  </div>
                )}
                {!isEmpty(getDetails?.tenderBasicSecondaryInchargeDtos) && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Secondary Incharge</span>
                    </div>
                    <div className="business-tender-project-desc">
                      {getDetails?.tenderBasicSecondaryInchargeDtos.map(
                        (data, i) => {
                          return (
                            <span key={data.id}>
                              {`${data.secondaryInchargeName}${
                                data.secondaryInchargeUserName
                                  ? `[${data.secondaryInchargeUserName}]`
                                  : ""
                              }`}
                              {getDetails?.tenderBasicSecondaryInchargeDtos
                                .length >
                              i + 1
                                ? ", "
                                : ""}
                            </span>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
                {!isEmpty(getDetails?.tenderBasicAdvanceDocumentDtos) ? (
                  uploadersExceptProfile?.map((file) => {
                    // let fileName = file.name;
                    return (
                      <div
                        className="business-tender-project-item"
                        key={file.id}
                      >
                        <div className="business-tender-project-title">
                          <span>Advance Document</span>
                        </div>

                        <div className="business-tender-project-desc">
                          <span className="document-file-length-wrapper">
                            <PaperClipOutlined />
                            &nbsp;
                            {getDetails?.tenderBasicAdvanceDocumentDtos.length}
                          </span>
                          {getDetails?.tenderBasicAdvanceDocumentDtos.length >
                            0 && (
                            <>
                              <div className="office-attachment-dropdown">
                                {getDetails?.tenderBasicAdvanceDocumentDtos
                                  .length > 0 && (
                                  <>
                                    {getDetails?.tenderBasicAdvanceDocumentDtos.map(
                                      (doc, index) => {
                                        if (doc.isActive) {
                                          return (
                                            <>
                                              <AllFileViewer
                                                file={doc}
                                                cqBaseUrl={cqBaseUrl}
                                                token={token}
                                              />
                                            </>
                                          );
                                        } else {
                                          return null; // Skip rendering if doc.isActive is false
                                        }
                                      }
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : getDetails?.officialInvitation == false &&
                  isEmpty(getDetails?.tenderBasicAdvanceDocumentDtos) ? (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Advance Document</span>
                    </div>
                    <div className="bussiness-tender-project-desc">
                      {!isEmpty(groupedByDocuments) &&
                        uploadersExceptProfile.map((file) => {
                          let fileName = file.name;
                          return (
                            <div className="contactsDetails__info">
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title bold w-100">
                                  Document
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle bold">
                                  {fileName &&
                                    groupedByDocuments.hasOwnProperty(
                                      fileName
                                    ) &&
                                    groupedByDocuments[`${fileName}`].map(
                                      (doc) => {
                                        return (
                                          doc.isActive && (
                                            <div>
                                              <PaperClipOutlined />{" "}
                                              <a
                                                target="_blank"
                                                download
                                                href={
                                                  cqBaseUrl +
                                                  "/" +
                                                  doc.viewFileURL +
                                                  token
                                                }
                                                rel="noreferrer"
                                              >
                                                {doc.displayFileName}
                                              </a>
                                            </div>
                                          )
                                        );
                                      }
                                    )}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      <div className="missing-wrapper">
                        <span style={{ color: "red" }}>Missing</span>
                        <PlusCircleOutlined
                          style={{ color: "blue", marginLeft: 5 }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {getDetails?.fundingAgencyName && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Funding Agency</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>{getDetails?.fundingAgencyName}</span>
                    </div>
                  </div>
                )}
                {!getDetails?.officialInvitation && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span> Official Invitation</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        <Checkbox
                          onChange={officalForm}
                          checked={checked || showofficalForm}
                        />
                      </span>
                    </div>
                  </div>
                )}
                {getDetails?.scopeOfWork && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Scope of Work</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>{getDetails?.scopeOfWork}</span>
                    </div>
                  </div>
                )}
                {/* {
                    getDetails.officialInvitation && (
                      <div className="business-tender-project-item">
                        <div className="business-tender-project-title">
                          <span>Official Invitation</span>
                        </div>
                        <div className="referenceDetails__labeldata">
                          <span className="referenceDetails--subtitle">
                            {getDetails.tenderProcessType === 1
                              ? "Single Stage"
                              : getDetails.tenderProcessType === 2
                                ? "Double Stages"
                                : ""}
                          </span>
                        </div>
                      </div>
                    )
                  } */}
                {getDetails?.bidValidity !== null && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Bid Validity till</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        {moment(getDetails?.bidValidity).format("YYYY/MM/DD")} (
                        {getDaysDifference(getDetails?.bidValidity)} Days)
                      </span>
                    </div>
                  </div>
                )}
                {getDetails?.bankGuranteeValue ? (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Bid Seurity of</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        {getDetails?.bankGuranteeCurrencySymbol}.
                        {getDetails?.bankGuranteeValue}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {getDetails?.bankGuranteeValidity ? (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Bid Security Validity Till</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        {moment(getDetails?.bidValidity).format("YYYY/MM/DD")} (
                        {getDaysDifference(getDetails?.bankGuranteeValidity)}{" "}
                        Days)
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {getDetails?.officialInvitation && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Tender Process</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>
                        {" "}
                        {getDetails.tenderProcessType === 1
                          ? "Single Stage"
                          : getDetails.tenderProcessType === 2
                          ? "Double Stage"
                          : ""}
                      </span>
                    </div>
                  </div>
                )}

                {getDetails?.isAdvanceConversion && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Covertion FT to T: </span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span> {getDetails.isAdvanceConversion && "Yes"}</span>
                    </div>
                  </div>
                )}

                {responseNotes && (
                  <div className="business-tender-project-item">
                    <div className="business-tender-project-title">
                      <span>Note:</span>
                    </div>
                    <div className="business-tender-project-desc">
                      <span>{responseNotes}</span>
                    </div>
                  </div>
                )}

                <div className="business-tender-project-action">
                  <Button
                    type="link"
                    style={{}}
                    className="btn-edit bttn-edit"
                    onClick={() => {
                      setEditForm(true);
                      setIsUpdate("edit");
                    }}
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="more"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M456 231a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0z"></path>
                    </svg>
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div className="no-data-selected">No data Selected</div>
          )}
        </div>
        <div className="business-information-wrapper">
          {showofficalForm && (
            <>
              {showSingleStageEdit ? (
                <>
                  <Stages
                    showStages={showStages}
                    setShowSingleStageEdit={setShowSingleStageEdit}
                    setOfficialForm={setOfficialForm}
                    showParticipation={showParticipation}
                    changetest={changetest}
                    allCurrencyListingResponse={allCurrencyListingResponse}
                    allCountryListingResponse={allCountryListingResponse}
                    setEditForm={setEditForm}
                    tenderTypeListingResponse={tenderTypeListingResponse}
                    sisterCompanyListing={sisterCompanyListing}
                    getDetails={getDetails}
                    doubleStageForm={doubleStageForm}
                    singleStageForm={singleStageForm}
                    documentPurchaseForm={documentPurchaseForm}
                    editStages={editStages}
                    setEditStages={setEditStages}
                    setGetStagesLevel={setGetStagesLevel}
                    getStagesLevel={getStagesLevel}
                    addUpdateOfficialInviation={addUpdateOfficialInviation}
                    dynamicFileTypesState={dynamicFileTypesState}
                    setShowParticipation={setShowParticipation}
                    setShowDoubleStage={setShowDoubleStage}
                    showDoubleStages={showDoubleStages}
                    dynamicTenderNoticeFileTypesState={
                      dynamicTenderNoticeFileTypesState
                    }
                    dynamicTenderDocumentFileTypesState={
                      dynamicTenderDocumentFileTypesState
                    }
                    dynamicROTOFileTypesState={dynamicROTOFileTypesState}
                    setDocumentOffFile={setDocumentOffFile}
                    setSubmissionDate={setSubmissionDate}
                    setOpeningDate={setOpeningDate}
                  />
                  {/* {stageLevelAddResponse && (
                            <>
                              <div className="edittender">
                                <DocumentPurchase
                                  allCountryListingResponse={
                                    allCountryListingResponse
                                  }
                                  sisterCompanyListing={
                                    sisterCompanyListing
                                  }
                                  getDetails={getDetails}
                                  documentPurchaseForm={
                                    documentPurchaseForm
                                  }
                                />
                              </div>
                              <div className="edittender">
                                <ROTO />
                              </div>
                            </>
                          )} */}
                </>
              ) : (
                !showSingleStageEdit &&
                isEmpty(getDetails?.tenderStageLevelDtos) && (
                  <div className="business-header">
                    <div className="business-header-title">Stage1</div>
                    <Button
                      type="link"
                      style={{}}
                      className="btn-edit bttn-edit"
                      onClick={() => {
                        setShowSingleStageEdit(true);
                      }}
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="more"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M456 231a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0z"></path>
                      </svg>
                    </Button>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </>
    );
  };
  const AddupdateTender = () => {
    return (
      <div className="reference__right">
        <div className="business-form-wrapper">
          <div className="business-header">
            {isUpdate === "add" ? (
              ""
            ) : getDetails?.referenceCode ? (
              <div className="business-header-title">{referenceHeader()}</div>
            ) : (
              ""
            )}
          </div>
          <Form layout="vertical" form={form} name="control-hooks">
            <div className="global-form-flex">
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    Title<span className="red-star">*</span>
                  </label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      autoComplete="off"
                      onChange={handleTitleChange}
                      onClick={() => setSearchTenderTitle([])}
                      onBlur={handleOnBlur}
                    />
                  </Form.Item>
                  {searchTenderTitle && searchTenderTitle.length > 0 && (
                    <ul className="search-project-title">
                      {searchTenderTitle?.map((item, i) => {
                        return (
                          <li
                            key={i}
                          >{`${item?.referenceCode} ${item?.title}`}</li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    Budget<span className="red-star">*</span>
                  </label>
                </div>
                <div className="global-form-input flex">
                  <Form.Item
                    className="currency"
                    name="budgetCurrencyId"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    {!isEmpty(allCurrencyListingResponse) && (
                      <Select
                        size="small"
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                        defaultValue={() => {
                          let value = "";
                          allCurrencyListingResponse?.map((currency) => {
                            if (currency.abbvr == "USD") {
                              value = currency.currencyId;
                            }
                          });
                          return value;
                        }}
                        onChange={(value) => {
                          getCurrency(value);
                        }}
                      >
                        {allCurrencyListingResponse?.map((currency) => (
                          <Option
                            key={currency.currencyId}
                            value={currency.currencyId}
                          >
                            {currency.abbvr}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item
                    className="budget-number"
                    name="budget"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      autoComplete="off"
                      // onChange={(e) => addCommaSeparator(e, "budget", form)}
                      onChange={numberCommaSepartor}
                    />
                    {/* <InputNumber
                      size="small"
                      autoComplete="off"
                      onChange={(e) => addCommaSeparator(e, "budget", form)}
                    /> */}
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Project (if Any)</label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="projectTitle"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      size="small"
                      showSearch={true}
                      optionFilterProp="label"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(inputValue, option) => {
                        setProjectTitleOnSearch(inputValue);
                      }}
                      onChange={(value, option) => {
                        const [fundingAgency] = option.label;
                        setSelectedFundAgency(fundingAgency);
                      }}
                      notFoundContent={
                        <>
                          Add a project &nbsp;
                          <Button
                            type="primary"
                            onClick={() => {
                              setProjectModal(true);
                            }}
                          >
                            +Add
                          </Button>
                        </>
                      }
                    >
                      {projectListing?.map((project, id) => (
                        <Option
                          label={[project.description, project.fundingAgency]}
                          key={project.id}
                          value={project.title}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">
                              {project.referenceCode} {"" + ""}
                            </span>
                            [{project.title}]
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {projectModal && (
                    <ProjectModal
                      projectModal={projectModal}
                      setProjectModal={setProjectModal}
                      selectedSearchedProjectTitle={
                        selectedSearchedProjectTitle
                      }
                    />
                  )}
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    Client<span className="red-star">*</span>
                  </label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="clientId"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      allowClear
                      showSearch={true}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      // optionFilterProp="label"
                      filterOption={() => true}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                      onSearch={(value) => {
                        if (value) {
                          setValue(value);
                        } else {
                          setContactCompanyList([]);
                          setValue(value);
                        }
                      }}
                      notFoundContent={
                        !isEmpty(value) ? (
                          contactCompanyPaginationLoading ? (
                            <Spin />
                          ) : (
                            isEmpty(contactCompanyPaginationResponse?.items) &&
                            value?.length > 2 && (
                              <div>{`Create "${value}"`}</div>
                            )
                          )
                        ) : null
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (
                            isEmpty(contactCompanyPaginationResponse?.items) &&
                            !isEmpty(value)
                          ) {
                            // e.preventDefault();
                            setVisibleModal(true);
                            setCompanyTender("companyclient");
                          }
                        }
                      }}
                      onBlur={() => setContactCompanyList([])}
                    >
                      {!isEmpty(getDetails?.clientId) && (
                        <Option value={getDetails.clientId}>
                          {getDetails.clientName}
                        </Option>
                      )}
                      {contactCompanyList?.map((company) => (
                        <Option
                          value={company.contactCompanyId}
                          key={company.contactCompanyId}
                          label={company.contactCompanyName}
                        >
                          {company.contactCompanyName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Department</label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="department"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input size="small" autoComplete="off" />
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    {/* Type<span className="red-star">*</span> */}
                    Category<span className="red-star">*</span>
                  </label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="tenderTypeId"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      size="small"
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={handleSelectCategory}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {typeListing?.map((type) => (
                        <Option key={type.id} value={type.id}>
                          {type.tenderTypeName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    {/* Type<span className="red-star">*</span> */}
                    Sub Category
                  </label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="subTenderTypeId"
                    rules={[
                      {
                        required: false,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      size="small"
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(values, option) => {
                        const selectedData = option.map((option) => ({
                          id: option.value,
                          tenderTypeName: option.children,
                        }));
                        setSelectedCategoryItem(selectedData);
                      }}
                    >
                      {subTypeListing?.items?.map((type) => (
                        <Option key={type.id} value={type.id}>
                          {type.tenderTypeName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    Sector<span className="red-star">*</span>
                  </label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="sectorId"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      size="small"
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={handleSectorsCategory}
                    >
                      {sectors.map((data, i) => (
                        <Option key={i} value={data.id}>
                          {data.sectorName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Sub Sector</label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="subSectorId"
                    rules={[
                      {
                        required: false,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      size="small"
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value, option) => {
                        const selectedData = option.map((option) => ({
                          id: option.value,
                          sectorName: option.children,
                        }));
                        setSelectedSectorsItem(selectedData);
                      }}
                    >
                      {subSectors?.items?.map((data, i) => (
                        <Option key={i} value={data.id}>
                          {data.sectorName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    Scope of Work<span className="red-star">*</span>
                  </label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="scopeOfWork"
                    rules={[
                      {
                        required: test === "delete" ? false : true,
                        message: "Scope of Work is Required",
                      },
                    ]}
                  >
                    <Select
                      autoComplete="off"
                      placeholder="Scopes of Work"
                      size="default"
                      mode="multiple"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onPopupScroll={(e) => {}}
                      notFoundContent={
                        !isEmpty(scopeSearch)
                          ? isEmpty(scopeTags) && `Create ${scopeSearch}`
                          : null
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          setScopeTags([]);
                          setScopeSearch("");
                          if (isEmpty(scopeTags) && !isEmpty(scopeSearch)) {
                            info();
                          }
                        }
                      }}
                      // onInputKeyDown={(e) => {
                      //   if(e.key === "Enter"){
                      //     setScopeTags([]);
                      //     setScopeTags('')

                      //   }

                      // }}
                      onSearch={(e) => {
                        setScopeSearch(e);
                      }}
                      // onSelect={(value, option) => addScopeofWork(value, option)}
                      // open={dropdownscope}
                      listHeight={100}
                      // // onInputKeyDown={onInputKeyDown}
                      onClear={() => {
                        setScopeSearch("");
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      {scopeTags?.map((tag) => (
                        <Option value={tag.name} key={tag.id}>
                          {tag.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    Primary Incharge<span className="red-star">*</span>
                  </label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="primaryInchargeId"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      allowClear
                      optionFilterProp="label"
                      showSearch={true}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {user.map((user) => (
                        <Option
                          key={user.appUserId}
                          value={user.appUserId}
                          label={[user.fullName, user.userName]}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">
                              {user.fullName} {"" + ""}
                            </span>
                            [{user.userName}]
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    Secondary Incharge<span className="red-star">*</span>
                  </label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="tenderBasicSecondaryInchargeDtos"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      size="small"
                      mode="multiple"
                      showSearch={true}
                      optionFilterProp="label"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {user.map((user) => (
                        <Option
                          key={user.appUserId}
                          value={user.appUserId}
                          label={[user.fullName, user.userName]}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">
                              {user.fullName} {"" + ""}
                            </span>
                            [{user.userName}]
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              {dynamicFileTypesState?.map((u, i) => (
                <div className="global-form-group calc">
                  {/* <div className="global-form-label"> */}
                  <div className="">
                    <label>Advance Document</label>
                  </div>
                  <div className="global-form-input">
                    <Form.Item
                      name={[`${u.name}FileDtos`]}
                      className="form__group"
                      rules={[{ required: false }]}
                    >
                      <DynamicImageUploader
                        name={u.name}
                        isMultipleFileAllowed={u.isMultipleFileAllowed}
                        id={u.id}
                        allowedExtensions={u.allowedExtensions}
                        // fileList={fileList}
                        // setFileList={setFileList}
                        index={i}
                        form={form}
                        callback={(e) => {
                          let olduploadedFileDtos = form.getFieldValue()[
                            `${u.name}FileDtos`
                          ]
                            ? [...form.getFieldValue()[`${u.name}FileDtos`]]
                            : [];

                          let uploadedFileDtos = [...olduploadedFileDtos];
                          uploadedFileDtos.push(e);

                          form.setFieldsValue({
                            [`${u.name}FileDtos`]: uploadedFileDtos,
                          });
                        }}
                        setAdDocs={setAdDocs}
                        adDocs={adDocs}
                        category="adDocs"
                      />
                      {/* <Card style={{ marginTop: "10px", marginLeft: "10px" }}>
                        <TableDocs
                          category="advanceDocs"
                          dataSource={
                            getDetails?.tenderBasicAdvanceDocumentDtos
                          }
                          token={token}
                          cqBaseUrl={cqBaseUrl}
                        />
                      </Card> */}
                    </Form.Item>
                  </div>
                </div>
              ))}
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>
                    Funding Agency<span className="red-star">*</span>
                  </label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="fundingAgencyId"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      allowClear
                      mode="multiple"
                      showSearch={true}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      // optionFilterProp="label"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={(value) => {
                        if (value) {
                          setValue(value);
                        } else {
                          setContactCompanyList([]);
                          setValue(value);
                        }
                      }}
                      notFoundContent={
                        !isEmpty(value) ? (
                          contactCompanyPaginationLoading ? (
                            <Spin />
                          ) : (
                            isEmpty(contactCompanyPaginationResponse?.items) &&
                            value?.length > 2 && (
                              <div>{`Create "${value}"`}</div>
                            )
                          )
                        ) : null
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (
                            isEmpty(contactCompanyPaginationResponse?.items) &&
                            !isEmpty(value)
                          ) {
                            // e.preventDefault();
                            setVisibleModal(true);
                            setCompanyTender("companyclient");
                          }
                        }
                      }}
                      onBlur={() => setContactCompanyList([])}
                    >
                      {!isEmpty(getDetails?.fundingAgencyId) && (
                        <Option value={getDetails.fundingAgencyId}>
                          {getDetails.fundingAgencyName}
                        </Option>
                      )}
                      {contactCompanyList?.map((company) => (
                        <Option
                          value={company.contactCompanyId}
                          key={company.contactCompanyId}
                          label={company.contactCompanyName}
                        >
                          {company.contactCompanyName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Official Invitation</label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="officialInvitation"
                    valuePropName="checked"
                    rules={[{ required: false }]}
                  >
                    <Checkbox onChange={officalForm} checked={checked} />
                  </Form.Item>
                </div>
              </div>

              {showofficalForm && (
                <div className="global-form-group calc">
                  <div className="global-form-label">
                    <label> Tender Process</label>
                  </div>
                  <div className="global-form-input">
                    <Radio.Group
                      defaultValue={showStages}
                      onChange={(e) => {
                        setShowStages(e.target.value);
                      }}
                    >
                      <Radio value="singleStages">Single Stage</Radio>
                      <Radio value="doubleStages">Double Stage</Radio>
                    </Radio.Group>
                  </div>
                </div>
              )}

              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Advanced Information</label>
                </div>
                <div
                  className="global-form-input"
                  style={{ display: "flex", alignSelf: "center" }}
                >
                  <Form.Item
                    name="advancedInformation"
                    valuePropName="checked"
                    rules={[{ required: false }]}
                  >
                    <Checkbox
                      onChange={advancedInformationForm}
                      checked={advancedChecked}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="global-form-group full-block">
                <div className="note-header">
                  <label> Notes </label>
                </div>
                <div className="note-box-wrapper">
                  <Form.Item
                    className="form__group"
                    name="note"
                    rules={[{ required: false }]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="global-form-footer">
              <Button
                className="cancleAction"
                onClick={() => {
                  setEditForm(false);
                  setIsUpdate("details");
                }}
              >
                Cancel
              </Button>
              <Button
                className="saveAction"
                onClick={() => {
                  onFinish();
                  setEditForm(true);
                }}
                loading={
                  tenderBasicAddLoadingResponse || tenderBasicUpdateLoading
                }
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <div style={{ maxHeight: "94vh", overflow: "auto" }}>
      <div className="profiledetails">
        {!showEditForm ? (
          !tenderBasicGetDetailsLoading ? (
            renderTender()
          ) : (
            <div style={{ padddingLeft: 25 }}>
              <Skeleton paragraph={{ rows: 6 }} />
            </div>
          )
        ) : (
          (isUpdate === "add" || isUpdate === "edit") && AddupdateTender()
        )}

        {!isEmpty(getDetails) && !tenderBasicGetDetailsLoading && (
          <RenderStageView
            addUpdateOfficialInviation={addUpdateOfficialInviation}
            getDetails={getDetails}
            editStages={editStages}
            setEditStages={setEditStages}
            setGetStagesLevel={setGetStagesLevel}
            getStagesLevel={getStagesLevel}
            showStages={showStages}
            changetest={changetest}
            allCurrencyListingResponse={allCurrencyListingResponse}
            allCountryListingResponse={allCountryListingResponse}
            sisterCompanyListing={sisterCompanyListing}
            doubleStageForm={doubleStageForm}
            singleStageForm={singleStageForm}
            documentPurchaseForm={documentPurchaseForm}
            defaultNRP={defaultNRP}
            defaultUSD={defaultUSD}
            setShowStages={setShowStages}
            showParticipation={showParticipation}
            setShowParticipation={setShowParticipation}
            setShowSingleStageEdit={setShowSingleStageEdit}
            dynamicFileTypesState={dynamicFileTypesState}
            setOfficialForm={setOfficialForm}
            showDoubleStages={showDoubleStages}
            setShowDoubleStage={setShowDoubleStage}
            extensionData={extensionData}
            setExtensionData={setExtensionData}
            dynamicTenderNoticeFileTypesState={
              dynamicTenderNoticeFileTypesState
            }
            dynamicTenderDocumentFileTypesState={
              dynamicTenderDocumentFileTypesState
            }
            dynamicROTOFileTypesState={dynamicROTOFileTypesState}
            dynamicExtensionFileTypesState={dynamicExtensionFileTypesState}
            tenderId={tenderId}
          />
        )}
        <Modal
          visible={visibleModal}
          footer={null}
          onCancel={() => {
            addCompany.setFieldsValue({
              scopeOfWork: [],
              companyName: "",
              countryId: "",
              isActive: false,
              isFavorite: false,
              cityId: "",
            });
            setVisibleModal(false);
          }}
          keyboard={false}
          maskClosable={false}
        >
          <QuickAddCompany
            form={addCompany}
            companySearch={value}
            quickContactCompanyAdd={quickContactCompanyAdd}
            quickContactCompanyLoader={quickContactCompanyLoader}
            quickAddFromProject="project"
          />
        </Modal>
      </div>
    </div>
  );
};

export default Summary;
