import React,{useEffect,useState} from 'react'
import Columns from './columns'
import Rows from './rows'
const DivTable = (props) =>{
    const {columns,rows,className,id,height,scrollTarget,totalLength,loading,handleEvent,activeKey}=props
    const [tableHeight,setHeight]=useState(null)
    useEffect(()=>{
        setHeight(height)
    },[height])
    return(
        <div className={`global-table-wrapper ${className}`}>
            <div className="global-columns-wrapper">
                <Columns columns={columns} length={columns.length} />
            </div>
            <div className="global-rows-wrapper" onClick={handleEvent}>
                <Rows loading={loading} 
                    rows={rows} 
                    length={columns.length} 
                    columns={columns} 
                    tableHeight={tableHeight} 
                    scrollTarget={scrollTarget} 
                    totalLength={totalLength}  
                    activeKey={activeKey}
                />
            </div>
        </div>
    )
}


export default DivTable
