import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PrimaryReferenceAddUpdateTable from './PrimaryReferenceAddUpdateTable';
import { PrimaryReferenceAddUpdateDrawer } from './PrimaryReferenceDrawer';
const PrimaryReference = () => {
    const [refrencedrawer, setReferenceDrawer] = useState(false);
   const onCloseReferenceDrawer = () => {
    setReferenceDrawer(false);
   }
   
     
  return (
    <div className="layout">
    <div className="organizer events">
        <div className="all-header-wrapper">
            <div className="meeting__flex">
                <h3 className="header__all">Primary Reference</h3>
                <div className="organizerHoliday__middle--wrap">
                    <Button
                        className="btn-primary"
                        onClick={() => {
                            setReferenceDrawer(true);
                        }}
                    >
                        <PlusOutlined /> New Primary
                    </Button>
                </div>
            </div>
        </div>
        <PrimaryReferenceAddUpdateTable/>
        <PrimaryReferenceAddUpdateDrawer drawerVisibility={refrencedrawer} onCloseReferenceDrawer={onCloseReferenceDrawer}/>
    </div>
</div>
  );
};

export default PrimaryReference
