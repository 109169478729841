import queryString from "query-string";
import { getService, postService } from "../../commonServices";
import { appBaseUrl } from "utils/config";

export class HolidayService {
  holidayEventBulkAddUpdate(body) {
    let url = `${appBaseUrl}/organizerEvent/holidayEventBulkAddUpdate`;
    let data = postService(url, body);
    return data;
  }

  holidayEventsPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizerEvent/holidayEventsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  holidaySampleDataDownload() {
    let url = `${appBaseUrl}/adminModule/downloadSampleHolidayUploadExcel`;
    let data = getService(url);
    return data;
  }

  //sisco
  exportSiscoUserListToExcel() {
    let url = `${appBaseUrl}/employeeRequest/exportSiscoUserListToExcel`;
    let data = postService(url);
    return data;
  }

  exportCompanyUserListToExcel() {
    let url = `${appBaseUrl}/organizerEvent/exportSisterCompanyListToExcel`;
    let data = postService(url);
    return data;
  }
}
