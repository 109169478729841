import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Typography,
} from "antd";
import { actionForUpdateQuestionAnswer } from "services/redux/cead/action";
import { EditOutlined } from "@ant-design/icons";
import QADetails from "./qAdetails";

// const getBase64 = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });

export default function QAAccouontSpecific({ singleTenderCeadData, QAForm }) {
  const dispatch = useDispatch();

  const { QALoading } = useSelector((state) => state.ceadReducer);

  const [isPrincipalRegVat, setIsPrincipalRegVat] = useState(false);
  const [selectedQAShow, setQAShow] = useState(false);

  useEffect(() => {
    setIsPrincipalRegVat(
      singleTenderCeadData?.tenderCEADQuestionAnswerDto?.isPrincipalRegVat
    );
  }, [singleTenderCeadData]);

  useEffect(() => {
    if (!QALoading) {
      setQAShow(false);
    }
  }, [QALoading]);

  // const [fileList, setFileList] = useState([]);

  // const handleFileChange = ({ fileList: newFileList }) =>
  //   setFileList(newFileList);

  const onFinishQuestionAns = (values) => {
    dispatch(actionForUpdateQuestionAnswer(values, singleTenderCeadData?.id));
  };

  return (
    <>
      {/* Question Answers */}
      <Typography.Title level={5} style={{ background: "#DFDFDF" }}>
        QA
        {!selectedQAShow && (
          <span
            onClick={() => {
              setQAShow(!selectedQAShow);
            }}
            className="bid-pointer-cursor"
          >
            <EditOutlined />
          </span>
        )}
      </Typography.Title>

      {!selectedQAShow ? (
        <QADetails
          qaDetails={singleTenderCeadData?.tenderCEADQuestionAnswerDto}
        />
      ) : (
        <Form
          onFinish={onFinishQuestionAns}
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 18 }}
          style={{ marginTop: "50px" }}
          labelAlign="left"
          form={QAForm}
        >
          <Row gutter={[24, 8]}>
            <Col span={24}>
              <Form.Item
                label="Is the Principal Registered in VAT?"
                name="isPrincipalRegVat"
              >
                <Radio.Group
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  onChange={(e) => {
                    setIsPrincipalRegVat(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>

            {isPrincipalRegVat && (
              <Card style={{ width: 1370 }}>
                <Col span={24}>
                  <Form.Item label="Vat No." name="vatNo">
                    <Input placeholder="Enter vat no." />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="VAT Registration Date"
                    name="vatRegistrationDate"
                  >
                    {/* <Input placeholder="Enter VAT Registration Date" /> */}
                    <DatePicker />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label="Remarks" name="vatRemarks">
                    <Input placeholder="Enter Remarks" />
                  </Form.Item>
                </Col>
              </Card>
            )}

            <Col span={24}>
              <Form.Item
                label="Will Principal reimburse to you VAT applicable on Fee Remittance"
                name="isPrincipalVatFeeRemittance"
              >
                <Radio.Group
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Form.Item>
            </Col>

            {/* not needed now */}
            {/* <Col span={24}>
            <Form.Item
              label="Special Fee Invoice Format, if any?"
              name="isSpecialFeeInvFormat"
            >
              <Radio.Group
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                onChange={(e) => {
                  setFeeInvoice(e.target.value === 1 ? true : false);
                }}
              />
            </Form.Item>
          </Col>

          {IsFeeInvoice && (
            <Col
              span={24}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ color: "lightgray", paddingRight: "45px" }}>
                Note: Fee Format has to be in Microsoft Word Temaplate (.doc):
              </p>

              <Form.Item
                // label="Note: Fee Format has to be in Microsoft Word Temaplate (.doc)"
                name="feeInvoicefile"
              >
                <Upload
                  onChange={handleFileChange}
                  fileList={fileList}
                  listType="text"
                  className="upload-width"
                >
                  <Button icon={<UploadOutlined />}> Pick your file</Button>
                </Upload>
              </Form.Item>
            </Col>
          )} */}

            <Col span={24}>
              <Form.Item label="Remarks" name="invoiceRemarks">
                <Input placeholder="Enter Remarks" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Whether Bizpro need to approve invoice before sending?"
                name="isBizproApprove"
              >
                <Radio.Group
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Remarks" name="bizproRemarks">
                <Input placeholder="Enter Remarks" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Do we have to send the Fee-Invoice in Print format?"
                name="isFeeInvPrintFmt"
              >
                <Radio.Group
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Remarks" name="feeInvPrintFmtremarks">
                <Input placeholder="Enter Remarks" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Will Principal send TDS deducted payment"
                name="isPrincipalTDSDedPmt"
              >
                <Radio.Group
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Remarks" name="tdsRemarks">
                <Input placeholder="Enter Remarks" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Is Contract Agreement Copy Handed to Accounts"
                name="isCtrctAggCopyToAcc"
              >
                <Radio.Group
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Remarks" name="ctrctAggCopyToAccRemarks">
                <Input placeholder="Enter Remarks" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Do we need to Claim for Tender Docs Purchased"
                name="isClaimTndrDocPur"
              >
                <Radio.Group
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Remarks" name="claimTndrDocPurremarks">
                <Input placeholder="Enter Remarks" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ float: "right", marginTop: "20px" }} gutter={[16, 12]}>
            <Col>
              <Button
                htmlType="submit"
                onClick={() => {
                  setQAShow(false);
                }}
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
