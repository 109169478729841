import { Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getAllUsers } from "services/remanentCalls";
import { appBaseUrl } from "utils/config";

import "./tableCss.css";

export default function TableData({ autoForwardLoad, setAutoForwardLoad }) {
  const [tableData, setTableData] = useState();
  const [allUsersOptions, setAllUsersOptions] = useState([]);

  const fetchAllParticipants = async () => {
    const response = await getAllUsers();
    setAllUsersOptions(response?.data);
  };

  useEffect(() => {
    if (allUsersOptions?.length <= 0) {
      fetchAllParticipants();
    }
  }, []);

  async function getAutoForwardDetails() {
    const response = await axios.get(
      `${appBaseUrl}/emailMessage/internalMessageForwardingRulesPagination`
    );
    if (response?.status === 200) {
      setTableData(response?.data?.items);
      setAutoForwardLoad(false);
    }
  }

  useEffect(() => {
    getAutoForwardDetails();
  }, [autoForwardLoad]);

  const columns = [
    {
      title: "S.N",
      dataIndex: "sn",
      width: 40,
      align: "right",
    },
    {
      title: "Sender Address",
      dataIndex: "emailaddress",
      key: "hey1",
    },
    {
      title: "Forwarded Emails",
      dataIndex: "userIds",
      key: "userIds",
      width: 300,
      render: (text) => {
        return allUsersOptions?.map((x) => {
          return text?.map((y) => {
            return x?.appUserId == y ? x?.fullName + ", " : "";
          });
        });
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },

    {
      title: "Expiration Date",
      dataIndex: "expiryDateTime",
      key: "expiryDateTime",
    },
  ];

  return (
    <>
      <Table
        className="table-wrapper"
        columns={columns}
        dataSource={tableData?.map((x, index) => {
          return {
            ...x,
            sn: index + 1,
          };
        })}
        style={{
          overflow: "scroll",
          maxHeight: "250px",
          maxWidth: "800px",
        }}
        pagination={false}
        loading={autoForwardLoad}
      />
    </>
  );
}
