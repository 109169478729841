import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { CheckCircleTwoTone, WarningOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

const Alert = ({ alert }) => {
  const [error, setError] = useState("");
  const [test, setTest] = useState(true);

  useEffect(() => {
    if (alert) {
      setError(alert.errorMessage);
    }
  }, [alert.errorMessage]);
  useEffect(() => {
    if (alert.hasError) {
      if (error == "Request failed with status code 401") {
        setTest(false);
      } else if (error == "Request failed with status code 403") {
        setTest(false);
      } else if (
        error == "Cannot read properties of undefined (reading 'status')"
      ) {
        setTest(false);
      }
    } else {
      setTest(true);
    }
  }, [error]);

  const token = useSelector(({ auth }) => auth.token);
  const openNotification = (alert) => {
    test &&
      notification.open({
        message: alert.hasError ? "Try again..." : "Cool...",
        description: alert.errorMessage,
        icon: alert.hasError ? (
          <WarningOutlined style={{ color: "red" }} />
        ) : (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ),
        duration: 2,
        key: alert.errorMessage,
      });

    // electron.notificationApi.sendNotification(alert.errorMessage);
  };
  useEffect(() => {
    (alert.hasError || alert.hasSuccess) && openNotification(alert);
  }, [alert]);
  return <> </>;
};

export default connect((state) => state)(Alert);
