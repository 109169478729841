import React, { useState, useEffect } from "react";
import { Modal, List, Button } from "antd";
import { isEmpty } from "lodash";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const NoticeBoardModal = ({ noticeBoard }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItem, setVisibleItem] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // useEffect(() => {
  //   if (
  //     !isEmpty(noticeBoard.items) &&
  //     currentIndex < noticeBoard.items.length
  //   ) {
  //     setVisibleItem(noticeBoard.items[currentIndex]);
  //     setIsModalVisible(true);
  //   } else {
  //     setVisibleItem(null);
  //     setIsModalVisible(false);
  //   }
  // }, [noticeBoard, currentIndex]);
  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem("hasSeenNoticeBoardModal");

    if (
      !hasSeenModal &&
      !isEmpty(noticeBoard.items) &&
      currentIndex < noticeBoard.items.length
    ) {
      setVisibleItem(noticeBoard.items[currentIndex]);
      setIsModalVisible(true);
    }
  }, [noticeBoard, currentIndex]);

  const handleModalCancel = () => {
    sessionStorage.setItem("hasSeenNoticeBoardModal", "true");
    setIsModalVisible(false);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, noticeBoard.items.length - 1)
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <>
      {isModalVisible && visibleItem && (
        <Modal
          title={visibleItem.title}
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
        >
          <p>{visibleItem.description}</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              icon={<LeftOutlined />}
              onClick={handlePrev}
              disabled={currentIndex === 0}
            >
              Previous
            </Button>
            <Button
              icon={<RightOutlined />}
              onClick={handleNext}
              disabled={currentIndex === noticeBoard.items.length - 1}
            >
              Next
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default NoticeBoardModal;
