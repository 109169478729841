import { Table, Typography } from "antd";
import React, { useState } from "react";
import FeeChangeModal from "./FeeChangeModal";

const FeeRemainingTable = ({
  dashboardFinancialResponse,
  feeCategoryListLoading,
  showDrawer,
  setShowDrawer,
}) => {
  const [data, setData] = useState();

  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "accountHead",
      key: "accountHead",
    },
    {
      title: "Amount in NPR",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {record?.amount?.toLocaleString()}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Amount in USD",
      dataIndex: "amountTwo",
      key: "Two",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {record?.amountTwo?.toLocaleString()}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Fee Sub Category",
      render: (record) => (
        <button
          style={{
            background: "none",
            border: "none",
            color: "#67a1e4",
            cursor: "pointer",
            display: record?.accountHead === "Total" ? "none" : "inline",
          }}
          onClick={() => {
            setShowDrawer(true);
            setData(record);
          }}
        >
          Edit
        </button>
      ),
    },
  ];
  const filteredDataSource = dashboardFinancialResponse?.items;

  const amountNpr = filteredDataSource?.reduce(
    (acc, curr) => acc + (curr?.amount || 0),
    0
  );

  const amountUsd = filteredDataSource?.reduce(
    (acc, curr) => acc + (curr?.amountTwo || 0),
    0
  );

  const totalRow = {
    key: "Total",
    accountHead: "Total",
    amount: amountNpr,
    amountTwo: amountUsd,
  };

  const dataSource = [...(filteredDataSource || []), totalRow];

  return (
    <>
      <Table
        className='next-organizer-table'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={feeCategoryListLoading}
      />

      {showDrawer && (
        <FeeChangeModal
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          data={data}
        />
      )}
    </>
  );
};

export default FeeRemainingTable;
