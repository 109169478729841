import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Input, Form, Drawer, Button, Select, Checkbox } from "antd";
import {
  actionForAddTutorialCategory,
  actionForUpdateTutorialCategory,
} from "services/redux/admin/Tutorial/action";

function TutorialCategoryTemplate({
  category,
  openForm,
  setOpenForm,
  updateData,
  setUpdateData,
}) {
  const dispatch = useDispatch();
  const { Header } = Layout;
  const [form] = Form.useForm();
  const { Option } = Select;

  const [categoryTypeId, setCategoryTypeId] = useState();

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        id: updateData?.id,
        value: updateData?.id,
        label: updateData?.typeName,
      });

      setCategoryTypeId(updateData?.id);
    }
  }, [form, updateData]);

  const onFinish = (value) => {
    let data = { typeName: value?.name };
    try {
      if (updateData) {
        let updateItem = {
          id: categoryTypeId,
          typeName: value?.name,
          isActive: updateData?.isActive,
        };
        dispatch(actionForUpdateTutorialCategory(categoryTypeId, updateItem));
      } else {
        dispatch(actionForAddTutorialCategory(data));
      }
    } catch (err) {
      console.log("error: ", err);
    } finally {
      setOpenForm({ drawer: false, modal: false, edit: false });
    }
  };

  const handleDelete = () => {
    const value = {
      ...updateData,
      id: categoryTypeId,
      typName: updateData?.name,
      isActive: false,
    };
    dispatch(actionForUpdateTutorialCategory(categoryTypeId, value));
  };

  const handleCancel = () => {
    // setOpenForm(false);
    setOpenForm({ drawer: false, modal: false, edit: false });
    form.resetFields();
    setUpdateData(null);
  };

  const handleChange = (value) => {
    if (value) {
      setCategoryTypeId(value);
    }
  };

  return (
    <>
      <Drawer
        visible={openForm.drawer}
        width={551}
        className="drawer"
        closable={false}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">
            {`${updateData ? "Update " : "Add "} Tutorial Category`}
          </h4>
        </Header>
        <Form
          form={form}
          className="drawer-form-common-wrapper"
          onFinish={onFinish}
        >
          <div className="drawer-form-row">
            <div className="drawer-form-group">
              {openForm?.edit && (
                <Form.Item
                  className="form__group"
                  name="value"
                  label="Category"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select onChange={handleChange} disabled>
                    {category.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.typeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                className="form__group"
                name="name"
                label={openForm?.edit ? "Update Name" : "Name"}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div
            className="model-footer"
            style={{ justifyContent: "space-between", padding: "0 9px" }}
          >
            {openForm?.edit ? (
              <>
                <Form.Item>
                  <Button className="" onClick={handleDelete} danger>
                    Delete
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={handleCancel}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </>
            ) : (
              <>
                <span></span>
                <Form.Item>
                  <Button
                    onClick={handleCancel}
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    // loading={questionTypeAddLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </>
            )}
          </div>
        </Form>
      </Drawer>
    </>
  );
}

export default TutorialCategoryTemplate;
