import React from "react";
import { Card, Layout } from "antd";
import CalendarContent from "views/Oragnizer";

const { Content } = Layout;

const CalendarBox = () => {
  return (
    <div
      className={`dashboards__events dashboards__body`}
      style={{ height: "680px", overflow: "hidden" }}
    >
      <div className="dashboards__bodyWrap">
        <h4 className="dashboards__body--title">Calendar</h4>
      </div>

      {/* Calendar Content */}
      <Content>
        <CalendarContent origin="Dashboard" />
      </Content>
    </div>
  );
};

export default CalendarBox;
