import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Typography } from "antd";

// import { LogoImage } from "utils/imageConstants";
// import { isEmpty, values } from "lodash-es";
import {
  getParticipationPagination,
  tenderParticipationGetDetails,
} from "services/redux/Reference/Tender/action";
import NoStyleButton from "components/Button/NoStyleButton";

const ParticipationTable = ({ getDetails, setParticipationform }) => {
  const { participationPaginationResponse } = useSelector(
    (state) => state.tender
  );
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getParticipationPagination(getDetails.id));
  }, [getDetails]);

  useEffect(() => {
    if (participationPaginationResponse) {
      setData(participationPaginationResponse.items);
    }
  }, [participationPaginationResponse]);

  const columns = [
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
    },

    {
      title: " Agency",
      dataIndex: "localAgency",
      key: "localAgency",
    },

    {
      title: "Short Listed",
      dataIndex: "shortListed",
      key: "shortListed",
      render: (record) => (
        <Space size="middle">
          <div className="action-btn action-btn-add">
            {record ? "Yes" : "No"}
          </div>
        </Space>
      ),
    },

    {
      title: "Financial Score",
      dataIndex: "financialScore",
      key: "financialScore",
      align: "center",
    },

    {
      title: "Technical Score",
      dataIndex: "technicalScore",
      key: "technicalScore",
    },

    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
    },

    {
      title: "Bid Price",
      dataIndex: "bidPrice",
      key: "bidPrice",
    },

    {
      title: "JV Company",
      dataIndex: "jvPartnerCompanyDtos",
      key: "jvPartnerCompanyDtos",
      render: (record) => (
        <Space size="middle">
          <div className="action-btn action-btn-add">
            {record?.map((company, index) => (
              <div>
                {company.companyName}{" "}
                {company.companyName !== null && record.length > index + 1
                  ? ","
                  : ""}
              </div>
            ))}
          </div>
        </Space>
      ),
    },
    {
      title: "Sub Contract",
      dataIndex: "subContractCompanyDtos",
      key: "subContractCompanyDtos",
      render: (record) => (
        <Space size="middle">
          <div className="action-btn action-btn-add">
            {record?.map((company, index) => (
              <div>
                {company.companyName}{" "}
                {company.companyName !== null && record.length > index + 1
                  ? ","
                  : ""}
              </div>
            ))}
          </div>
        </Space>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <NoStyleButton
            text="Edit"
            OnClickButton={() => {
              dispatch(tenderParticipationGetDetails(record.id));
              setParticipationform(true);
            }}
          />
          {/* <a
            href="/#"
            className="action-btn action-btn-add"
            onClick={() => {
              dispatch(tenderParticipationGetDetails(record.id));
              setParticipationform(true);
            }}
          >
            Edit
          </a> */}
        </Space>
      ),
    },
  ];

  return (
    <Table
      className="organizer__table contact-table business-table"
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
};

export default ParticipationTable;
