import React, {useState, useEffect} from "react";
import {Row, Col, Table} from "antd";
import { LogoImage } from "utils/imageConstants";


const LeaveApprovalTable = () => {
    
  const columns = [
    {
      title: "S.No",
      dataIndex: "title",
      key: "title",
      sorter: true,
    },
    {
      title: "Applicant",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Who else got nootified",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Effective Form",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Active",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

    return (
      <Table
        className='contact-table'
        columns={columns}
        loading={{
          indicator: <img src={LogoImage} height='auto' width='50px' />,
         
        }}
      />
    );
   
   
}

export default LeaveApprovalTable;