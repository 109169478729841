import { EmailReferenceService } from "./api";
import { EMAIL_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
import queryString from "query-string";

const emailReferenceService = new EmailReferenceService();

export const mailReferenceAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_REFERENCE_TAGS_ADD));
    const response = await emailReferenceService.mailReferenceAdd(body);
    dispatch(finish(EMAIL_TYPES.MAIL_REFERENCE_TAGS_ADD));
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.MAIL_REFERENCE_TAGS_ADD, response.data));
      message.success("Tags Added");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const mailReferenceGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_REFERENCE_TAGS_DETAILS));
    const response = await emailReferenceService.mailReferenceGetDetails(id);
    dispatch(finish(EMAIL_TYPES.MAIL_REFERENCE_TAGS_DETAILS));
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.MAIL_REFERENCE_TAGS_DETAILS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
