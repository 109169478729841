import { init, success, finish, error } from "services/common";
import { MeetingDecisionService } from "./api";
import { MEETING_DECISION_TYPES } from "../../../../types";
import { meetingEventAgendaDecisionsList } from "../action";

const meetingDecisionService = new MeetingDecisionService();

export const addDecisionEventAgenda = (body, meetingEventId) => {
  return async (dispatch) => {
    dispatch(init(MEETING_DECISION_TYPES.ADD_DECISION_AGENDA));
    const response = await meetingDecisionService.addDecisionEventAgenda(body);
    dispatch(finish(MEETING_DECISION_TYPES.ADD_DECISION_AGENDA));
    if (response.isSuccess) {
      dispatch(success(MEETING_DECISION_TYPES.ADD_DECISION_AGENDA, response.data));
      dispatch(meetingEventAgendaDecisionsList(meetingEventId))
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const meetingEventDecisionUpdate = (body, id, meetingEventId) => {
  return async (dispatch) => {
    dispatch(init(MEETING_DECISION_TYPES.MEETING_DECISION_UPDATE));
    const response = await meetingDecisionService.meetingEventDecisionUpdate(body, id);
    dispatch(finish(MEETING_DECISION_TYPES.MEETING_DECISION_UPDATE));
    if (response.isSuccess) {
      dispatch(success(MEETING_DECISION_TYPES.MEETING_DECISION_UPDATE, response.data));
      dispatch(meetingEventAgendaDecisionsList(meetingEventId))
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};