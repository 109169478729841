import { Tooltip, Typography } from "antd";
import moment from "moment";
import React from "react";
import { showNumber } from "utils/showNumber";
import { handleSpecialDate } from "utils/tooltipSepecialDate";

const getODBalCol = ({ widthh, modalShow }) => {
    return [
        {
            title: (
                <Typography.Paragraph style={{ textAlign: "left", width: "100%" }}>
                    Bank
                </Typography.Paragraph>
            ),
            dataIndex: "bank",
            key: "bank",
            render: (_, record) => {
                const index =
                    record?.accountHead?.indexOf(":") > 0
                        ? record?.accountHead?.indexOf(":")
                        : record?.accountHead?.length;
                const result = record?.accountHead?.substring(0, index);
                return (
                    <Typography.Paragraph
                        style={{
                            textAlign: "left",
                            fontSize: widthh < 1550 ? 13 : 15,
                            fontWeight:
                                record?.companyName === "Total :" ? "bold" : "inherit",
                        }}
                    >
                        {result}
                        <Typography.Text style={{ color: "blue" }}>
                            {result == "Total" ? "" : <> [{record?.companyName}] </>}
                        </Typography.Text>
                    </Typography.Paragraph>
                );
            },
        },
        {
            title: (
                <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
                    Total limit
                </Typography.Paragraph>
            ),
            dataIndex: "totalLimit",
            key: "totalLimit",
            render: (_, record) => {
                return (
                    <Tooltip
                        title={
                            // record?.maxDate
                            //   ? moment(record?.maxDate).format("YYYY MMM DD")
                            //   : ""
                            handleSpecialDate(record?.maxDate)
                        }
                    >
                        <Typography.Paragraph
                            style={{
                                textAlign: "right",
                                fontSize: widthh < 1550 ? 13 : 15,
                                fontWeight:
                                    record?.companyName === "Total :" ? "bold" : "inherit",
                            }}
                            onDoubleClick={() => {
                                modalShow(record?.budgetQueryParam, "total", "Total limit");
                            }}
                        >
                            {showNumber(record?.totalLimit)}
                        </Typography.Paragraph>
                    </Tooltip>
                );
            },
        },
        {
            title: (
                <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
                    {" "}
                    Overdrawn
                </Typography.Paragraph>
            ),
            dataIndex: "overDrawn",
            key: "overDrawn",
            render: (_, record) => {
                return (
                    <Tooltip
                        title={
                            handleSpecialDate(record?.maxDate)
                        }
                    >
                        <Typography.Paragraph
                            style={{
                                textAlign: "right",
                                fontSize: widthh < 1550 ? 13 : 15,
                                fontWeight:
                                    record?.companyName === "Total :" ? "bold" : "inherit",
                            }}
                            onDoubleClick={() => {
                                modalShow(record?.accountBranchQuery, "overdrawn", "expenseQueryParam", record?.accountQuery);
                            }}
                        >
                            {showNumber(record?.overDrawn)}
                        </Typography.Paragraph>
                    </Tooltip>
                );
            },
        },
        {
            title: (
                <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
                    Limit Remaining
                </Typography.Paragraph>
            ),
            dataIndex: "limitRemaining",
            key: "limitRemaining",
            render: (_, record) => {
                return (
                    <Tooltip
                        title={
                            // record?.maxDate
                            //   ? moment(record?.maxDate).format("YYYY MMM DD")
                            //   : ""
                            handleSpecialDate(record?.maxDate)
                        }
                    >
                        <Typography.Paragraph
                            style={{
                                textAlign: "right",
                                fontSize: widthh < 1550 ? 13 : 15,
                                fontWeight:
                                    record?.companyName === "Total :" ? "bold" : "inherit",
                            }}
                            onDoubleClick={() => {
                                modalShow(record?.budgetQueryParam, "remaining", "Limit Remaining");
                            }}
                        >
                            {record?.financialInformationODBalance?.overDrawn >
                                0.75 * record?.financialInformationODBalance?.totalLimit ? (
                                <Typography.Text style={{ color: "red" }}>
                                    {Math.trunc(record?.limitRemaining)?.toLocaleString()}
                                </Typography.Text>
                            ) : (
                                <Typography.Text>
                                    {Math.trunc(record?.limitRemaining)?.toLocaleString()}
                                </Typography.Text>
                            )}
                        </Typography.Paragraph>
                    </Tooltip>
                );
            },
        },
    ]
};

export default getODBalCol;
