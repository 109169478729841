import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  Checkbox,
  Input,
  Select,
  Layout,
  message,
} from "antd";
import { validateMessages } from "utils/misc";

import { organizationTypeAdd } from "services/redux/organizer/typeListing/action";
import { isEmpty } from "lodash";

const { Header, Footer } = Layout;
const { Option } = Select;
export const OrganizationTypeAddUpdateDrawer = ({
  onCloseOrginaation,
  drawerVisibility,
  isUpdateSector,
  setShowOrganizationDrawer,
}) => {
  const { organizationTypeAddResponse } = useSelector(
    (state) => state.organizationType
  );

  const [form] = Form.useForm();
  const [complainDetail, setComplaintDetail] = useState();
  form.resetFields();
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationTypeAddResponse === "undefined") {
      onCloseOrginaation();
    }
  }, [organizationTypeAddResponse]);

  useEffect(() => {
    if (!isEmpty(organizationTypeAddResponse)) {
      setTimeout(() => {
        onCloseOrginaation();
        form.resetFields();
      }, 500);
      setComplaintDetail(organizationTypeAddResponse);
    }
  }, [organizationTypeAddResponse]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      dispatch(organizationTypeAdd(values));
      form.resetFields();
      onCloseOrginaation();
      setShowOrganizationDrawer(false);
    } catch (errorInfo) {
      message.error("failed", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onCloseOrginaation}
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">{`${
          isUpdateSector ? "Update" : "Create"
        } Organization Type`}</h4>
      </Header>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        validateMessages={validateMessages}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <Form.Item
            className="form__group"
            name="OrganizationLayerTypeName"
            label="Organization Type"
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </div>
        <div className="drawer-form-row">
          <Form.Item
            className="form__group"
            name="isActive"
            initialValue={false}
            valuePropName="checked"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <span style={{ width: "20%" }}>Is Active</span>
            <Checkbox className="form__group"></Checkbox>
          </Form.Item>
        </div>
        <div className="model-footer">
          <Button onClick={onCloseOrginaation}>Cancel</Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
