import { uniqBy } from "lodash";
import { ADMIN_TYPES } from "services/types";

const INITIAL_STATE = {
  emailShareListingResponse: [],
  emailShareListingResponseLoading: false,
};

export default function emailShareReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADMIN_TYPES.EMAIL_SHARE_LISTING_INIT:
      return {
        ...state,
        emailShareListingResponse: [],
        emailShareListingResponseLoading: true,
      };

    case ADMIN_TYPES.EMAIL_SHARE_LISTING_SUCCESS:
      return {
        ...state,
        // emailShareListingResponse: action.payload,
        emailShareListingResponseLoading: false,
        emailShareListingResponse: {
          items: action.loadMore
            ? uniqBy(
                state.emailShareListingResponse?.items.concat(
                  action.payload.items
                ),
                "id"
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
      };

    case ADMIN_TYPES.CORPORATE_EMAIL_ADD_INIT:
      return {
        ...state,
        corporateEmailAddResponse: [],
        corporateEmailAddResponseLoading: true,
      };

    case ADMIN_TYPES.CORPORATE_EMAIL_ADD_SUCCESS:
      return {
        ...state,
        corporateEmailAddResponse: action.payload,
        corporateEmailAddResponseLoading: false,
      };

    case ADMIN_TYPES.EMAIL_SHARE_GET_DETAILS_INIT:
      return {
        ...state,
        emailShareGetDetailsResponse: [],
        emailShareGetDetailsResponseLoading: true,
      };

    case ADMIN_TYPES.EMAIL_SHARE_GET_DETAILS_SUCCESS:
      return {
        ...state,
        emailShareGetDetailsResponse: action.payload,
        emailShareGetDetailsResponseLoading: false,
      };

    case ADMIN_TYPES.CORPORATE_EMAIL_SHARE_INIT:
      return {
        ...state,
        emailShareResponse: [],
        emailShareResponseLoading: true,
      };
    case ADMIN_TYPES.CORPORATE_EMAIL_SHARE_SUCCESS:
      return {
        ...state,
        emailShareResponse: action.payload,
        emailShareResponseLoading: false,
      };

    default:
      return { ...state };
  }
}
