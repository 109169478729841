import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Form, Input } from "antd";
import { sourcePagination } from "services/redux/source/action";

const SourceAddUpdateTable = ({ showAddUpdateSectorDrawer }) => {
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Source",
      dataIndex: "name",
      key: "name",
    },
  ];

  const dispatch = useDispatch();
  const { sourcePaginationResponse } = useSelector((state) => state.source);

  const [form] = Form.useForm();
  const [data, setdata] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!sourcePaginationResponse) return;
    setSourceData(sourcePaginationResponse.items);
    setTotalCount(sourcePaginationResponse.totalCount);
  }, [sourcePaginationResponse]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType
  ) => {
    dispatch(
      sourcePagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
      })
    );
  };

  return (
    <>
      <Table
        className="organizer__tables"
        columns={columns}
        dataSource={sourceData}
        pagination={{
          total: totalCount,
          pageSize: pageSize,
        }}
      />
    </>
  );
};

export default SourceAddUpdateTable;
