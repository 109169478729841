import React, { useCallback, useEffect, useState } from "react";
import { PlusOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { Empty, Tooltip, message, Menu, Dropdown, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  tenderDelete,
  getTenderBasicSearchPagination,
  tenderFavAddUpdate,
} from "services/redux/Reference/Tender/action";
import { actionForPersistTenderMailData } from "./Tabs/TenderTabs/companies/persistTender/action";
import { LogoImage } from "utils/imageConstants";
// const minimize = "assets/images/icons/minimize.png";
// const maximize = "assets/images/icons/maximize.png";

export const TenderPagination = ({
  rowEventhandlersTender,
  tenderData,
  setTenderTotalCount,
  setSearchEmailTender,
  setSelectedTenderRowId,
  selectedTenderRowId,
  setGetDetails,
  setBusinessTabPaneKey,
  selectedBusinessTabPaneKey,
  // setSearchKeyword,
  // searchDataAtBusinessHeaderForm,
  contextMenushow,
  setContextMenushow,
}) => {
  const dispatch = useDispatch();

  // console.log("tenderDataPagintaion", tenderData, selectedTenderRowId);
  // console.log("JSON: ", JSON.stringify(tenderData));

  // useEffect(() => {
  // 	getData();
  // }, [searchKeyword]);

  // const getData = (MaxResultCount = pageSize, SkipCount = 0, loadMore = false) => {
  // 	let appendUrl = `skipCount=${SkipCount}&maxResultCount=${MaxResultCount}`;

  // 	if (searchKeyword) {
  // 		appendUrl += `&SearchKeyword=${SearchKeyword}`;
  // 	}

  // 	setAppendUrlState(appendUrl);
  // 	dispatch(getTenderBasicSearchPagination(appendUrl, loadMore));
  // };

  // Added
  const [contextMenuHover, setContextMenuHover] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    id: "",
    referenceCode: "",
    body: {},
  });
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleDelete = (e) => {
    e.preventDefault();
    const { id, referenceCode, ...rest } = contextMenu;
    let payload = {
      id: id,
      referenceCode: referenceCode,
      isActive: false,
    };

    dispatch(tenderDelete(payload));

    let currentIndex = tenderData?.findIndex(
      (item) => item.referenceCode === selectedTenderRowId
    );

    let nextIndex =
      currentIndex + 1 < tenderData?.length
        ? currentIndex + 1
        : tenderData?.length - 1;
    const nextItem = tenderData[nextIndex];

    if (nextItem) {
      handleRowClick(tenderData[nextIndex]);
    }
  };

  const handleRowClick = (data) => {
    rowEventhandlersTender(data.id);
    setSelectedTenderRowId(data.referenceCode);
    setSearchEmailTender(data.referenceCode);

    let payload = {
      tos: "",
      refCode: [
        {
          id: data?.id,
          referenceCode: data?.referenceCode,
        },
      ],
      subject: data?.title ?? "",
    };
    dispatch(actionForPersistTenderMailData(payload));
  };

  const handleRightClickTender = useCallback((e, data) => {
    e.preventDefault();
    // handleRowClick(data);
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setContextMenushow(true);
    setContextMenu((prev) => ({
      ...prev,
      id: data?.id,
      referenceCode: data?.referenceCode,
      body: {
        ...data,
      },
    }));
  }, []);

  const closeContextMenu = () => {
    setContextMenushow(false);
    setContextMenu({ id: "", referenceCode: "" });
  };

  const menu = (
    <Menu style={{ width: 144, height: 76 }}>
      <Menu.Item key="delete">
        <Button type="primary" onClick={() => console.log("delete")}></Button>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleBodyClick = () => {
    setContextMenushow(false);
    setContextMenu({ id: "", referenceCode: "" });
  };

  useEffect(() => {
    let reference_item = document.querySelector("#tenderScroll");
    document.addEventListener("click", handleBodyClick);
    if (reference_item) {
      reference_item.addEventListener("contextmenu", handleRightClickTender);
    }

    return () => {
      document.removeEventListener("click", handleBodyClick);
      if (reference_item) {
        reference_item.removeEventListener(
          "contextmenu",
          handleRightClickTender
        );
      }
    };
  }, []);

  return (
    <>
      <div>
        {contextMenushow && (
          <ul
            className="email-list-menu-action-wrapper"
            style={{
              position: "absolute",
              top: contextMenuPosition.y - 50,
              left: contextMenuPosition.x + 1,
              backgroundColor: "white",
              border: "1px solid #ccc",
              listStyleType: "none",
              padding: "5px",
              zIndex: 1000,
            }}
            onMouseEnter={() => setContextMenuHover(true)}
            onMouseLeave={() => {
              setContextMenuHover(false);
              closeContextMenu();
            }}
          >
            {/* Permission */}
            <li
              className="right-click-menu-item"
              onClick={handleDelete}
              style={{ padding: "5px", cursor: "pointer" }}
            >
              Delete {contextMenu?.referenceCode}
            </li>
          </ul>
        )}

        {tenderData?.map((data, key) => {
          // console.log("tenderdata", data);
          const backGroundColor = key % 2 == 0 ? "" : "#f2f2f2";
          const getDayDiff = (startDate) => {
            // let diff = moment(endDate).diff(moment(startDate), 'days');
            // var endDatee = moment(endDate);
            // var startDatee = moment(startDate);
            // var days = endDatee.diff(startDatee, 'days');

            var date1 = new Date(startDate);
            var date2 = new Date();
            // To calculate the time difference of two dates
            var Difference_In_Time = date2.getTime() - date1.getTime();

            // To calculate the no. of days between two dates
            var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;

            // let days = moment.duration(diff).days();
            return (
              <div>
                <span>({diff})</span>
              </div>
            );
          };

          return (
            <>
              <div
                style={{ background: backGroundColor }}
                className={`reference ${
                  selectedTenderRowId === data.referenceCode && "active"
                }`}
                // onClick={() => {
                //   rowEventhandlersTender(data.id);
                //   setSelectedTenderRowId(data.referenceCode);
                //   setSearchEmailTender(data.referenceCode);
                //   // setSearchKeyword(data.referenceCode);
                //   // searchDataAtBusinessHeaderForm.setFieldsValue({
                //   //   searchDataAtBusinessHeader: data.referenceCode,
                //   // });

                //   let payload = {
                //     tos: "",
                //     refCode: [
                //       {
                //         id: data?.id,
                //         referenceCode: data?.referenceCode,
                //       },
                //     ],
                //     subject: data?.title ?? "",
                //   };
                //   dispatch(actionForPersistTenderMailData(payload));
                // }}
                key={key}
                onClick={() => handleRowClick(data)}
                onContextMenu={(e) => handleRightClickTender(e, data)}
              >
                <div className="reference__wrapper">
                  <div className="reference__wrapper--contents">
                    <div className="reference__body">
                      <div
                        className="reference__body--right "
                        onClick={() => {
                          dispatch(
                            tenderFavAddUpdate({ tenderId: data.id }, data)
                          );
                        }}
                      >
                        {data.isFavorite ? (
                          <StarFilled style={{ color: "#ffd036" }} />
                        ) : (
                          <StarOutlined style={{ color: "#ffd036" }} />
                        )}
                      </div>
                      <div className="reference__body--right">
                        <span className="reference__body--5right">
                          {data.referenceCode}
                        </span>
                      </div>
                    </div>
                    <div className="reference__main">
                      <span className=" reference__body--5main">
                        <Tooltip title={data.title} placement="topLeft">
                          {data.title}
                        </Tooltip>
                      </span>
                    </div>
                    {data.submissionDate !== "0001/01/01" &&
                    data.submissionDate ? (
                      <div className="reference__end">
                        <div className="reference__end--date ">
                          <span className="reference__body--5date">
                            {moment(data.submissionDate).format(
                              "YYYY/MM/DD"
                            ) === "Invalid date" || "0001/01/01"
                              ? ""
                              : moment(data.submissionDate).format(
                                  "YYYY/MM/DD"
                                )}
                          </span>
                        </div>
                        <div className="reference__end--days ">
                          <span className="reference__body--5date">
                            {getDayDiff(data.submissionDate) > 0
                              ? getDayDiff(data.submissionDate)
                              : ""}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="reference__end noDate">
                        <div className="reference__end--date ">
                          <span className="reference__body--5date">-</span>
                        </div>
                        <div className="reference__end--days ">
                          <span className="reference__body--5date"></span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export const TenderPaginationModal = ({
  setSelectedRows,
  selectedRows,
  setInputSearchValue,
  tenderData,
}) => {
  const dispatch = useDispatch();
  const [selectBackGroundColor, setselectBackGroundColor] = useState("#f2f2f2");

  const [checkedRow, setCheckedRow] = useState();
  const [checkedState, setCheckedState] = useState([]);

  const selectedMultipleRows = (item) => {
    setSelectedRows([...selectedRows, item.referenceCode]);
    if (selectedRows.includes(item.referenceCode)) {
      // className={`reference ${selectedTenderRowId === data.id && 'active'}`}
      message.warning(`Tender ${item.referenceCode} already selected`);
    } else {
      console.log("items not repeated");
    }
  };
  return (
    <>
      <>
        {isEmpty(tenderData) ? (
          <div className="nulldata">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        ) : !isEmpty(tenderData) ? (
          <div>
            {tenderData?.map((data, key) => {
              const backGroundColor = key % 2 == 0 ? "" : "#f2f2f2";
              const getDayDiff = (startDate) => {
                // let diff = moment(endDate).diff(moment(startDate), 'days');
                // var endDatee = moment(endDate);
                // var startDatee = moment(startDate);
                // var days = endDatee.diff(startDatee, 'days');

                var date1 = new Date(startDate);
                var date2 = new Date();
                // To calculate the time difference of two dates
                var Difference_In_Time = date2.getTime() - date1.getTime();

                // To calculate the no. of days between two dates
                var diff =
                  Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;

                // let days = moment.duration(diff).days();
                return (
                  <div>
                    <span>({diff})</span>
                  </div>
                );
              };

              return (
                <>
                  <div className="reference">
                    <div
                      onClick={(e) => {
                        selectedMultipleRows(data);
                        setCheckedRow(data.referenceCode);
                        setInputSearchValue("");
                      }}
                      // style={{ background: selectIndex == data.id && selectBackGroundColor }}
                      className="reference__wrapper"
                      style={{
                        background: checkedState[key] == true ? "#f2f2f2" : "",
                      }}
                    >
                      <div className="reference__wrapper--contents">
                        <div className="reference__body">
                          <div
                            className="reference__body--right "
                            onClick={() => {
                              dispatch(
                                tenderFavAddUpdate({ tenderId: data.id }, data)
                              );
                            }}
                          >
                            {data.isFavorite ? (
                              <StarFilled style={{ color: "#ffd036" }} />
                            ) : (
                              <StarOutlined style={{ color: "#ffd036" }} />
                            )}
                          </div>
                          <div className="reference__body--right">
                            <span className="reference__body--5right">
                              {data.referenceCode}
                            </span>
                          </div>
                        </div>
                        <div
                          className="reference__main"
                          style={{ width: "78% " }}
                        >
                          <div className="reference__main--middle ">
                            <span className=" reference__body--5main">
                              <Tooltip title={data.title} placement="topLeft">
                                {data.title}
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                        {/* <div className="reference__end">
												<div className="reference__end--date ">
													<span className="reference__body--5date">{moment(data.submissionDate).format('YYYY/MM/DD') === 'Invalid date' ? '' : moment(data.submissionDate).format('YYYY/MM/DD')}</span>
												</div>
												<div className="reference__end--days ">
													<span className="reference__body--5date">{getDayDiff(data.submissionDate)}</span>
												</div>
											</div> */}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </>
    </>
  );
};
