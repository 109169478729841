import queryString from "query-string";
import { getService, postService } from "../../commonServices";
import { appBaseUrl } from "utils/config";

export class SourceService {

    sourceAdd(body) {
        let url = `${appBaseUrl}/contactRequest/contactSourceAdd`;
        let data = postService(url, body);
        return data;
      }
      getSourcePagination() {
        let url =  `${appBaseUrl}/contactRequest/contactSourcesPagination`;
        let data = getService(url);
        return data;
      }
}