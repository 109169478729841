import { PERSIST_DATA } from "services/types";
import { persistSelectedTenderMail } from "services/common";

export const actionForPersistTenderMailData = (data) => {
  return async (dispatch, getState) => {
    //  // Retrieve the updated state from Redux after the dispatch
    dispatch(
      persistSelectedTenderMail(PERSIST_DATA.PERSIST_DATA_TENDER_SUCCESS, data)
    );
  };
};

export const actionForPersistTenderMailTos = (tos) => {
  return async (dispatch, getState) => {
    dispatch(
      persistSelectedTenderMail(PERSIST_DATA.PERSIST_DATA_TENDER_TOS, tos)
    );
  };
};

export const actionForPersistTenderMailContent = (content) => {
  // console.log("ACTION content: ",content)
  return async (dispatch, getState) => {
    dispatch(
      persistSelectedTenderMail(
        PERSIST_DATA.PERSIST_DATA_TENDER_CONTENT,
        content
      )
    );
  };
};
