import { OFFICE_RESOURCE_TYPES } from '../../../types';
import { HonorRollService } from './api';
import { init, success, finish, error } from 'services/common';

import { message } from 'antd';
const honorRollService = new HonorRollService();
export const honorRollsPagination = (body) => {
	return async (dispatch) => {
		dispatch(init(OFFICE_RESOURCE_TYPES.honorRollsPagination));
		const response = await honorRollService.honorRollsPagination(body);
		dispatch(finish(OFFICE_RESOURCE_TYPES.honorRollsPagination));
		if (response.isSuccess) {
			dispatch(success(OFFICE_RESOURCE_TYPES.honorRollsPagination, response.data));
		} else if (!response.isSuccess) {
			dispatch(error(response.errorMessage));
		}
	};
};

export const honorRollAdd = (query) => {
	return async (dispatch) => {
		dispatch(init(OFFICE_RESOURCE_TYPES.honorRollAdd));
		const response = await honorRollService.honorRollAdd(query);
		dispatch(finish(OFFICE_RESOURCE_TYPES.honorRollAdd));
		if (response.isSuccess) {
			message.success('Successfully added honor roll');
			dispatch(success(OFFICE_RESOURCE_TYPES.honorRollAdd, response.data));
			dispatch(honorRollsPagination());
		} else if (!response.isSuccess) {
			dispatch(error(response.errorMessage));
		}
	};
};

export const honorRollUpdate = (id, body) => {
	return async (dispatch) => {
		dispatch(init(OFFICE_RESOURCE_TYPES.honorRollUpdate));
		const response = await honorRollService.honorRollUpdate(id, body);
		dispatch(finish(OFFICE_RESOURCE_TYPES.honorRollUpdate));
		if (response.isSuccess) {
			message.success('Successfully updated honor roll');
			dispatch(success(OFFICE_RESOURCE_TYPES.honorRollUpdate, response.data));
			dispatch(honorRollsPagination());
		} else if (!response.isSuccess) {
			dispatch(error(response.errorMessage));
		}
	};
};
