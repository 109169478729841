import { EMAIL_TYPES, REFERENCE__TYPES, SOW_TYPES } from "../../../types";
import {
  init,
  success,
  finish,
  error,
  update,
  saveMailTypeAndMail,
  contactUpdated,
} from "services/common";
import { MailMessageCenterService } from "./api";
import { message, notification } from "antd";
import Storage from "utils/storage";
import axios from "axios";
import { ReferencesService } from "services/redux/Reference/api";
import { getAutoReferenceData } from "services/redux/Reference/action";
// import responsiveObserve from "antd/lib/_util/responsiveObserve";
const mailMessageCenterService = new MailMessageCenterService();
const referencesService = new ReferencesService();

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const getMailMessageCenterPagination = (q, loadMore, data) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION, data));
    const response =
      await mailMessageCenterService.getMailMessageCenterPagination(q, source);
    if (response?.cancelApi === false) return;
    if (response.isSuccess) {
      // console.log("getMailMessageCenterPagination ACTION CALLED")
      dispatch(
        success(
          EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION,
          response.data,
          loadMore,
          data
        )
      );
      dispatch(finish(EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
      message.error(response.errorMessage);
    }
  };
};

// Dashboard
export const getMailMessageCenterPaginationDashboard = (q, loadMore, data) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD, data));
    const response =
      await mailMessageCenterService.getMailMessageCenterPagination(q, source);
    if (response?.cancelApi === false) return;
    if (response.isSuccess) {
      dispatch(
        success(
          EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD,
          response.data,
          loadMore,
          data
        )
      );
      dispatch(finish(EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
      message.error(response.errorMessage);
    }
  };
};

export const updateMailMessgeCenterBody = (body) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT));
    const response =
      await mailMessageCenterService.mailMessageCenterHighlightBody(body);
    if (response.isSuccess) {
      dispatch(
        success(EMAIL_TYPES.MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const getErrorPagination = (q) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_ERROR_PAGINATION));
    const response = await mailMessageCenterService.errorMessage(q);
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.MAIL_ERROR_PAGINATION, response.data));
      dispatch(finish(EMAIL_TYPES.MAIL_ERROR_PAGINATION));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const emailGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_BODY_GET_DETAILS));
    const response = await mailMessageCenterService.mailBodyGetDetails(id);
    dispatch(finish(EMAIL_TYPES.MAIL_BODY_GET_DETAILS));
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.MAIL_BODY_GET_DETAILS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const emailPaginationCount = (q) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_PAGINATION_COUNT));
    const response = await mailMessageCenterService.countMessage(q);
    dispatch(finish(EMAIL_TYPES.MAIL_PAGINATION_COUNT));
    if (response.isSuccess) {
      dispatch(
        success(
          EMAIL_TYPES.MAIL_PAGINATION_COUNT,
          response.data,
          "",
          q.messagecenterType
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const mailReferenceTagUpdate = (body, currentSelectedMailMessage) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_REFERENCE_TAGS_UPDATE));
    const response = await mailMessageCenterService.mailReferenceUpdate(body);
    if (response.isSuccess) {
      dispatch(
        success(
          EMAIL_TYPES.MAIL_REFERENCE_TAGS_UPDATE,
          response.data,
          "",
          currentSelectedMailMessage
        )
      );
      notification.success({
        message: "Successfully updated Tags",
      });

      dispatch(mailReferenceListing());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(EMAIL_TYPES.MAIL_REFERENCE_TAGS_UPDATE));
  };
};

export const mailReferenceTagMultipleUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAI_REFERENCE_TAGS_MULTIPLE_UPDATE));
    const response = await mailMessageCenterService.mailReferenceMultipleUpdate(
      body
    );
    if (response.isSuccess) {
      dispatch(
        success(EMAIL_TYPES.MAI_REFERENCE_TAGS_MULTIPLE_UPDATE, response.data)
      );
      notification.success({
        message: "Successfully Updated Tags",
      });
      dispatch(mailReferenceListing());
      dispatch(getMailMessageCenterPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const mailReferenceListing = () => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MAIL_REFERENCE_TAGS_LISTING));
    const response = await mailMessageCenterService.mailReferenceListing();
    dispatch(finish(EMAIL_TYPES.MAIL_REFERENCE_TAGS_LISTING));
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.MAIL_REFERENCE_TAGS_LISTING, response.data));
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const composeEmail = (body, typeName, isClose) => {
  return async (dispatch) => {
    if (isClose) dispatch(init(EMAIL_TYPES.COMPOSE_EMAIL));
    if (!isClose) dispatch({ type: EMAIL_TYPES.COMPOSE_EMAIL_NOT_CLOSE_INIT });
    const response = await mailMessageCenterService.composeEmail(body);
    // dispatch(finish(EMAIL_TYPES.COMPOSE_EMAIL));
    if (response.isSuccess) {
      message.success("Email sent successfully");
      setTimeout(() => {
        dispatch(success(EMAIL_TYPES.COMPOSE_EMAIL, response.data));
      }, 500);
      setTimeout(() => {
        dispatch(finish(EMAIL_TYPES.COMPOSE_EMAIL));
      }, 600);
      dispatch(finish(EMAIL_TYPES.COMPOSE_EMAIL));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
      dispatch(finish(EMAIL_TYPES.COMPOSE_EMAIL));
    }
  };
};
export const circularComposeEmail = (body, typeName, isClose) => {
  return async (dispatch) => {
    if (isClose) dispatch(init(EMAIL_TYPES.COMPOSE_EMAIL));
    if (!isClose) dispatch({ type: EMAIL_TYPES.COMPOSE_EMAIL_NOT_CLOSE_INIT });
    const response = await mailMessageCenterService.circularComposeEmail(body);
    dispatch(finish(EMAIL_TYPES.COMPOSE_EMAIL));
    if (response.isSuccess) {
      notification.success({
        message: "Email sent successfully",
      });
      setTimeout(() => {
        dispatch(success(EMAIL_TYPES.COMPOSE_EMAIL_CIRCULAR, response.data));
      }, 500);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//for draft messages
export const composeDraftMessages = (body, typeName) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.DRAFT_MESSAGE));
    const response = await mailMessageCenterService.saveMessageAsDraft(body);
    dispatch(finish(EMAIL_TYPES.DRAFT_MESSAGE));
    if (response.isSuccess) {
      notification.success({
        message: "Email Saved as draft",
      });
      setTimeout(() => {
        dispatch(success(EMAIL_TYPES.DRAFT_MESSAGE, response.data));
      }, 500);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const changeEmailStatusType = (
  query,
  status,
  messageCenterTypeName,
  emailStatus,
  referenceValues
) => {
  // console.log("status", query);
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.PARTICIPANT_STATUSES_CHANGE));
    const response = await mailMessageCenterService.changeEmailStatusType(
      query
    );
    dispatch(finish(EMAIL_TYPES.PARTICIPANT_STATUSES_CHANGE));

    if (response.isSuccess) {
      dispatch(
        update(
          EMAIL_TYPES.MAIL_BODY_GET_DETAILS,
          query,
          emailStatus,
          messageCenterTypeName,
          referenceValues
        )
      );
      dispatch(success(EMAIL_TYPES.PARTICIPANT_STATUSES_CHANGE, response.data));
      dispatch(
        update(
          EMAIL_TYPES.SELECTED_MAIL_TYPE_AND_MAIL,
          query,
          emailStatus,
          messageCenterTypeName
        )
      );
      dispatch(
        update(
          EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION,
          query,
          emailStatus,
          messageCenterTypeName
        )
      );
      dispatch(finish(EMAIL_TYPES.PARTICIPANT_STATUSES_CHANGE));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const changeMultipleEmailStatusType = (
  body,
  type,
  emailType,
  setBulkStatusLoading
) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.PARTICIPANT_MULTIPEL_STATUSES_CHANGE));
    const response =
      await mailMessageCenterService.changeMultipleEmailStatusType(body);

    dispatch(finish(EMAIL_TYPES.PARTICIPANT_MULTIPEL_STATUSES_CHANGE));
    if (response.isSuccess) {
      setBulkStatusLoading(false);
      dispatch(
        success(EMAIL_TYPES.PARTICIPANT_MULTIPEL_STATUSES_CHANGE, response.data)
      );
      dispatch(
        getMailMessageCenterPagination(
          `skipCount=0&maxResultCount=10&IsRead_SearchKeyword=&IsInstanceMessage_SearchKeyword=&SortType=desc&IsFavourite_SearchKeyword=&messageCenterTypeName=${emailType}`
        )
      );
      {
        type === "Trash" &&
          message.success("Deleted selected emails successfullly");
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    // console.log(body,"body")
  };
};
// export const chageEmailReadStatus

export const refreshUserIncomingEmails = (typeName) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.REFRESH_INCOMING_MAIL));
    const response = await mailMessageCenterService.refreshUserIncomingEmails();
    dispatch(finish(EMAIL_TYPES.REFRESH_INCOMING_MAIL));
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.REFRESH_INCOMING_MAIL, response.data));
      Storage.setItem("timeStamp", Math.floor(Date.now() / 1000));
      const timeStamp = sessionStorage.getItem("timeStamp");
      if (timeStamp !== Math.floor(Date.now() / 1000)) {
        notification.success({
          message: "Refresh",
        });
      }

      setTimeout(() => {
        notification.destroy();
        dispatch(
          getMailMessageCenterPagination(
            `MaxResultCount=10&SkipCount=0${
              typeName ? `&messageCenterTypeName=${typeName}` : ""
            }`
          )
        );
      }, 1000);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const selectedMailTypeAndMail = (data) => {
  return async (dispatch) => {
    dispatch(
      saveMailTypeAndMail(EMAIL_TYPES.SELECTED_MAIL_TYPE_AND_MAIL, data)
    );
  };
};

// export const contactUpdate = (data) => {
//   console.log("status", data);
//   console.log("dispatchde from main");
//   return async (dispatch) => {
//     dispatch(contactUpdated
//       contactUpdated(
//         COMPANY_PEOPLE_TYPES.CONTACT_TYPE_UPDATE_CONTACTRESPONSE,
//         data
//       )
//     );
//   };
// };

//action for getting response from child window

export const getChildReferenceUpateResponse = (data) => {
  return async (dispatch) => {
    dispatch(contactUpdated(EMAIL_TYPES.MAIL_COMPOSE_RESPONSE_CHILD, data));
  };
};

// export const getChildReferenceUpateResponse = (data) => async (dispatch) => {
//   dispatch({
//     type: EMAIL_TYPES.MAIL_COMPOSE_RESPONSE_CHILD,
//     payload: data,
//   });
// };
export const quickEmailAddEmailSection = (body, setInternalVisible) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.QUICK_EMAIL_ADD_EMAIL));
    const response = await mailMessageCenterService.quickEmailAdd(body);
    if (response?.isSuccess) {
      dispatch(success(EMAIL_TYPES.QUICK_EMAIL_ADD_EMAIL, response?.data));
      message.success("successfully sent internal message");
      setInternalVisible && setInternalVisible(false);
    } else {
      dispatch(error(response?.errorMessage));
    }
    dispatch(finish(EMAIL_TYPES.QUICK_EMAIL_ADD_EMAIL));
  };
};
export const draftMailExistOrNot = (id) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.DRAFT_MAIL_EXIST_OR_NOT));
    const response = await mailMessageCenterService.draftMailExistOrNot(id);
    if (response?.isSuccess) {
      dispatch(success(EMAIL_TYPES.DRAFT_MAIL_EXIST_OR_NOT, response?.data));
      if (response?.data) {
        window.open(`/#/email-compose?applicationType=composeDraft`, "_blank");
      } else {
        window.alert("The email is not in the list");
      }
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getReplyDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.GET_REPLY_DETAILS));
    const response = await mailMessageCenterService.getReplyDetails(id);
    if (response?.isSuccess) {
      dispatch(success(EMAIL_TYPES.GET_REPLY_DETAILS, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getPredefinedMessageListing = () => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.GET_PREDEFINED_MESSAGE_LISTING));
    const response =
      await mailMessageCenterService.getPredefinedMessageListing();
    if (response?.isSuccess) {
      dispatch(
        success(EMAIL_TYPES.GET_PREDEFINED_MESSAGE_LISTING, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const preDefineMessageAdd = (body, form) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.PREDEFINED_MESSAGE_ADD));
    const response = await mailMessageCenterService.preDefineMessageAdd(body);
    if (response?.isSuccess) {
      dispatch(success(EMAIL_TYPES.PREDEFINED_MESSAGE_ADD, response?.data));
      message.success("Successfully added predefined message");
      dispatch(getPredefinedMessageListing());
      form.resetFields();
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const preDefineMessageUpdate = (id, body, form) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.PREDEFINED_MESSAGE_UPDATE));
    const response = await mailMessageCenterService.preDefineMessageUpdate(
      id,
      body
    );
    if (response?.isSuccess) {
      dispatch(success(EMAIL_TYPES.PREDEFINED_MESSAGE_UPDATE, response?.data));
      message.success("Successfully added predefined message");
      dispatch(getPredefinedMessageListing());
      form.resetFields();
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getSharedEmailLists = (id, body, form) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.GET_SHARED_EMAIL_LIST));
    const response = await mailMessageCenterService.getSharedEmailLists();
    if (response?.isSuccess) {
      dispatch(success(EMAIL_TYPES.GET_SHARED_EMAIL_LIST, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// referenceAuto
export const emailAutoReferenceAdd = (body, form) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD));
    const response = await referencesService.emailAutoReferenceAdd(body);

    dispatch(finish(REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD));
    if (response?.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD, response?.data)
      );
      message.success("Successfully added reference rules");
      dispatch(getAutoReferenceData());
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const emailSharedAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.SHARED_EMAIL_ADD));
    const response = await mailMessageCenterService.sharedEmailAdd(body);

    dispatch(finish(REFERENCE__TYPES.SHARED_EMAIL_ADD));
    if (response?.isSuccess) {
      dispatch(success(REFERENCE__TYPES.SHARED_EMAIL_ADD, response?.data));
      message.success("Successfully updated Email Shared Access");
      // dispatch(getAutoReferenceData());
      // dispatch(getAutoReferenceData());
      // form.resetFields();
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getAllSharedDetails = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_ALL_SHARED_EMAIL_LIST));
    const response = await mailMessageCenterService.getSharedListsAll();

    if (response?.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.GET_ALL_SHARED_EMAIL_LIST, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const resetOutboxMailFailureCount = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.OUTBOXMAIL_RESET_FAILURECOUNT));
    const response =
      await mailMessageCenterService.outboxResetEmailMessageOutboxFailureCount(
        id
      );
    dispatch(finish(REFERENCE__TYPES.OUTBOXMAIL_RESET_FAILURECOUNT));
    if (response?.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.OUTBOXMAIL_RESET_FAILURECOUNT, response?.data)
      );
      const successMessage = response.data?.message || "Resend Mail in Queue";
      message.success(successMessage);
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};
