import { COMPANY_PEOPLE_TYPES } from "../../../types";
import { ContactCompanyPeopleService } from "./api";
import { init, success, finish, error, contactUpdated } from "services/common";
import { message } from "antd";

const contactCompanyPeopleService = new ContactCompanyPeopleService();
export const allPersonListing = (query) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_LISTING));
    const response = await contactCompanyPeopleService.allPersonListing(query);
    dispatch(finish(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_LISTING, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//for getting response from another tabt to main tab
export const contactUpdate = (data) => {
  // console.log("status", data);
  // console.log("dispatchde from main");
  return async (dispatch) => {
    dispatch(
      contactUpdated(
        COMPANY_PEOPLE_TYPES.CONTACT_TYPE_UPDATE_CONTACTRESPONSE,
        data
      )
    );
  };
};

export const allSearchPersonListing = (query) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_SEARCH_LISTING));
    const response = await contactCompanyPeopleService.allSearchPersonListing(
      query
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_SEARCH_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(
          COMPANY_PEOPLE_TYPES.CONTACT_PERSON_SEARCH_LISTING,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactCompanyPersonSearchPagination = (body, forPagination) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.contactCompanyPersonSearch));
    const response =
      await contactCompanyPeopleService.contactCompanyPersonSearchPagination(
        body
      );
    dispatch(finish(COMPANY_PEOPLE_TYPES.contactCompanyPersonSearch));
    if (response.isSuccess) {
      if (forPagination) {
        dispatch(
          success(
            COMPANY_PEOPLE_TYPES.contactCompanyPersonSearchPaginationChange,
            response.data
          )
        );
      } else {
        dispatch(
          success(
            COMPANY_PEOPLE_TYPES.contactCompanyPersonSearch,
            response.data
          )
        );
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getContactCompanyPagination = (body, forPagination) => {
  // console.log("companyforpagination",forPagination)
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_PAGINATION));
    const response =
      await contactCompanyPeopleService.getContactCompanyPagination(body);
    dispatch(finish(COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_PAGINATION));
    if (response.isSuccess) {
      if (forPagination) {
        dispatch(
          success(
            COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_PAGINATION_CHANGE,
            response.data
          )
        );
      } else {
        dispatch(
          success(
            COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_PAGINATION,
            response.data
          )
        );
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getContactPersonPagination = (body, forPagination) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_PAGINATION));
    const response =
      await contactCompanyPeopleService.getContactPersonPagination(body);
    dispatch(finish(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_PAGINATION));
    if (response.isSuccess) {
      if (forPagination) {
        dispatch(
          success(
            COMPANY_PEOPLE_TYPES.CONTACT_PERSON_PAGINATION_CHANGE,
            response.data
          )
        );
      } else {
        dispatch(
          success(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_PAGINATION, response.data)
        );
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getExpertPagnination = (body, forPagination) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.EXPERT_PERSON_PAGINATION));
    const response = await contactCompanyPeopleService.getExpertPagination(
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.EXPERT_PERSON_PAGINATION));
    if (response.isSuccess) {
      if (forPagination) {
        dispatch(
          success(
            COMPANY_PEOPLE_TYPES.EXPERT_PERSON_PAGINATION_CHANGE,
            response.data
          )
        );
      } else {
        dispatch(
          success(COMPANY_PEOPLE_TYPES.EXPERT_PERSON_PAGINATION, response.data)
        );
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactCompanyDetailAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.contactCompanyDetailAdd));
    const response = await contactCompanyPeopleService.contactCompanyDetailAdd(
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.contactCompanyDetailAdd));
    if (response.isSuccess) {
      message.success("Successfully Added contact company");
      setTimeout(() => {
        dispatch(
          success(COMPANY_PEOPLE_TYPES.contactCompanyDetailAdd, response.data)
        );
      }, 500);
      dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactQuickEmailAdd = (body, type) => {
  // console.log("typee", type)
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.CONTACT_QUICK_EMAIL));
    const response = await contactCompanyPeopleService.contactQucikAddEmail(
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.CONTACT_QUICK_EMAIL));
    if (response.isSuccess) {
      dispatch(
        success(
          COMPANY_PEOPLE_TYPES.CONTACT_QUICK_EMAIL,
          response.data,
          "",
          type,
          body
        )
      );
      message.success("Email Created Successfully.");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactCompanyUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.contactCompanyUpdate));
    const response = await contactCompanyPeopleService.contactCompanyUpdate(
      id,
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.contactCompanyUpdate));
    if (response.isSuccess) {
      message.success("Successfully updated company");
      dispatch(
        success(COMPANY_PEOPLE_TYPES.contactCompanyUpdate, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactCompanyNoteUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.contactCompanyNoteUpdate));
    const response = await contactCompanyPeopleService.contactCompanyNotes(
      id,
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.contactCompanyNoteUpdate));
    if (response.isSuccess) {
      message.success("Successfully updated Note");
      dispatch(
        success(COMPANY_PEOPLE_TYPES.contactCompanyNoteUpdate, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactPersonUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.contactPersonUpdate));
    const response = await contactCompanyPeopleService.contactPersonUpdate(
      id,
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.contactPersonUpdate));
    if (response.isSuccess) {
      message.success("Successfully Updated contact Person");
      setTimeout(() => {
        dispatch(
          success(COMPANY_PEOPLE_TYPES.contactPersonUpdate, response.data)
        );
      }, 500);
      dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactPersonAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.contactPersonAdd));
    const response = await contactCompanyPeopleService.contactPersonAdd(body);
    dispatch(finish(COMPANY_PEOPLE_TYPES.contactPersonAdd));

    if (response.isSuccess) {
      message.success("Successfully added contact person");
      setTimeout(() => {
        dispatch(success(COMPANY_PEOPLE_TYPES.contactPersonAdd, response.data));
      }, 500);
      dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForQuickPersonAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.QUICK_PERSON_ADD));
    const response = await contactCompanyPeopleService.quickPersonAdd(body);
    dispatch(finish(COMPANY_PEOPLE_TYPES.QUICK_PERSON_ADD));

    if (response.isSuccess) {
      dispatch(success(COMPANY_PEOPLE_TYPES.QUICK_PERSON_ADD, response.data));
      message.success("Quick Person add successfully.");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const expertPersonAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.expertPersonAdd));
    const response = await contactCompanyPeopleService.expertPersonAdd(body);
    dispatch(finish(COMPANY_PEOPLE_TYPES.expertPersonAdd));

    if (response.isSuccess) {
      message.success("Successfully added contact person");
      setTimeout(() => {
        dispatch(success(COMPANY_PEOPLE_TYPES.expertPersonAdd, response.data));
      }, 500);
      dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const quickCompanyAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.quickCompanyAdd));
    const response = await contactCompanyPeopleService.quickContactCompanyAdd(
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.quickCompanyAdd));
    if (response.isSuccess) {
      message.success("Successfully added company");
      dispatch(success(COMPANY_PEOPLE_TYPES.quickCompanyAdd, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const contactPersonNoteUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.contactPersonNoteUpdate));
    const response = await contactCompanyPeopleService.contactPersonNotes(
      id,
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.contactPersonNoteUpdate));
    if (response.isSuccess) {
      message.success("Successfully updated  Person Note");
      dispatch(
        success(COMPANY_PEOPLE_TYPES.contactPersonNoteUpdate, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactCompanyGetDetails = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.contactCompanyGetDetails));
    const response = await contactCompanyPeopleService.contactCompanyGetDetails(
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.contactCompanyGetDetails));
    if (response.isSuccess) {
      dispatch(
        success(COMPANY_PEOPLE_TYPES.contactCompanyGetDetails, response.data)
      );
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactPersonGetDetails = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.contactPersonGetDetails));
    const response = await contactCompanyPeopleService.contactPersonGetDetails(
      body
    );
    dispatch(finish(COMPANY_PEOPLE_TYPES.contactPersonGetDetails));
    if (response.isSuccess) {
      dispatch(
        success(COMPANY_PEOPLE_TYPES.contactPersonGetDetails, response.data)
      );
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const expertPersonGetDetails = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.EXPERT_PERSON_GETDETAIL));
    const response = await contactCompanyPeopleService.expertPersonGetDetails(
      body
    );
    if (response.isSuccess) {
      dispatch(
        success(COMPANY_PEOPLE_TYPES.EXPERT_PERSON_GETDETAIL, response.data)
      );
      dispatch(finish(COMPANY_PEOPLE_TYPES.EXPERT_PERSON_GETDETAIL));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactCompanyFav = (body, record) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_IS_FAV));
    const response =
      await contactCompanyPeopleService.contactCompanyFavAddUpdate(body);
    dispatch(finish(COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_IS_FAV));
    if (response.isSuccess) {
      dispatch(
        success(
          COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_IS_FAV,
          response.data,
          "",
          record
        )
      );
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactPersonFav = (body, record) => {
  // console.log(record);
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_IS_FAV));
    const response =
      await contactCompanyPeopleService.contactPersonFavAddUpdate(body);
    dispatch(finish(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_IS_FAV));
    if (response.isSuccess) {
      dispatch(
        success(
          COMPANY_PEOPLE_TYPES.CONTACT_PERSON_IS_FAV,
          response.data,
          "",
          record
        )
      );
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const deleteCompany = (id) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.COMPANY_DELETE));
    const response = await contactCompanyPeopleService.contactCompanyDelete(id);
    dispatch(finish(COMPANY_PEOPLE_TYPES.COMPANY_DELETE));
    if (response.isSuccess) {
      dispatch(success(COMPANY_PEOPLE_TYPES.COMPANY_DELETE, response.data));
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const deletePerson = (id) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.PERSON_DELETE));
    const response = await contactCompanyPeopleService.personDelete(id);
    dispatch(finish(COMPANY_PEOPLE_TYPES.PERSON_DELETE));
    if (response.isSuccess) {
      // console.log("hey success", response.data);
      dispatch(success(COMPANY_PEOPLE_TYPES.PERSON_DELETE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const companyActiveStatus = (id, body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_ACTIVE_STATUS));
    const response = await contactCompanyPeopleService.companyActiveStatus(
      id,
      body
    );
    if (response.isSuccess) {
      message.success(`Status Updated - ${body?.contactActiveStatus}`);
      dispatch(
        success(
          COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_ACTIVE_STATUS,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_ACTIVE_STATUS));
  };
};

export const personActiveStatus = (id, body) => {
  return async (dispatch) => {
    dispatch(init(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_ACTIVE_STATUS));
    const response = await contactCompanyPeopleService.personActiveStatus(
      id,
      body
    );
    if (response.isSuccess) {
      message.success(`Status Updated - ${body?.contactActiveStatus}`);
      dispatch(
        success(
          COMPANY_PEOPLE_TYPES.CONTACT_PERSON_ACTIVE_STATUS,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(COMPANY_PEOPLE_TYPES.CONTACT_PERSON_ACTIVE_STATUS));
  };
};
