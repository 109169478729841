import { Col, Row } from "antd";
import moment from "moment";
import React from "react";

const CompliantDetails = ({ getDetails }) => {
  return (
    // <Col style={{ padding: "10px" }}> {3}{19}
    <div className="mail-leave-details" style={{ marginTop: "10px" }}>
      <div className="leave-details">
        <Row>
          <Col className="leave-col" span={12}>
            <div className="leave-input">Issued By:</div>
          </Col>
          <Col className="leave-col" span={12}>
            <div className="leave-input">{getDetails?.issueName}</div>
          </Col>
        </Row>
        <Row>
          <Col className="leave-col" span={12}>
            <div className="leave-input">Title:</div>
          </Col>
          <Col className="leave-col" span={12}>
            <div className="leave-input">{getDetails?.title}</div>
          </Col>
        </Row>
        <Row>
          <Col className="leave-col" span={12}>
            <div className="leave-input">Status:</div>
          </Col>
          <Col className="leave-col" span={12}>
            <div className="leave-input">{getDetails?.statusName}</div>
          </Col>
        </Row>
        <Row>
          <Col className="leave-col" span={12}>
            <div className="leave-input">Remarks:</div>
          </Col>
          <Col className="leave-col" span={12}>
            <div className="leave-input">{getDetails?.remarks}</div>
          </Col>
        </Row>
        <Row>
          <Col className="leave-col" span={12}>
            <div className="leave-input">Completion Time:</div>
          </Col>
          <Col className="leave-col" span={12}>
            <div className="leave-input">
              {getDetails?.completionTime != null ||
              getDetails?.completionTime !== undefined
                ? moment(getDetails?.completionTime).format("YYYY MM DD")
                : ""}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="leave-col" span={12}>
            <div className="leave-input">Estimation Date:</div>
          </Col>
          <Col className="leave-col" span={12}>
            <div className="leave-input">
              {getDetails?.estimatedDate != null ||
              getDetails?.estimatedDate !== undefined
                ? moment(getDetails?.estimatedDate).format("YYYY MM DD")
                : ""}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="leave-col" span={12}>
            <div className="leave-input">Compeletion Remarks:</div>
          </Col>
          <Col className="leave-col" span={12}>
            <div className="leave-input">{getDetails?.completionRemarks}</div>
          </Col>
        </Row>
        <Row></Row>
      </div>
    </div>
    // </Col>
  );
};

export default CompliantDetails;
