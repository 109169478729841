import queryString from "query-string";
import { getService, postService } from "../../../commonServices";
import { appBaseUrl } from "utils/config";

export class ProjectService {
  projectPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/projectsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  projectPaginationDashboard(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/projectsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  projectGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/projectGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  projectAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/projectAdd`;
    let data = postService(url, body);
    return data;
  }
  projectUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/projectUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  referencePagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/referencePagination?${query}`;
    let data = getService(url);
    return data;
  }

  referenceAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/referenceAdd`;
    let data = postService(url, body);
    return data;
  }
  referenceUpdate(id, body) {
    let url = `${appBaseUrl}/referenceManagement/referenceUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  projectFavAddUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/projectFavoriteAddUpdate`;
    let data = postService(url, body);
    return data;
  }
  noteProjectAdd(id, body) {
    let url = `${appBaseUrl}/referenceManagement/projectNoteUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
}
