import queryString from "query-string";
import { appBaseUrl } from "utils/config";
import { getService, postService, putService } from "../../commonServices";

export class ContactService {
  countryPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/countryPagination?${query}`;
    let data = getService(url);
    return data;
  }

  allCountryListing(q) {
    let url = `${appBaseUrl}/contactRequest/contactCompanyListing?`;
    let data = getService(url);
    return data;
  }

  countryAdd(body) {
    let url = `${appBaseUrl}/contactRequest/countryAdd`;
    let data = postService(url, body);
    return data;
  }

  countryUpdate(id, body) {
    let url = `${appBaseUrl}/contactRequest/countryUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  contactLabelsPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/contactLabelGroupsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  contactLabelGroupAdd(body) {
    let url = `${appBaseUrl}/contactRequest/contactLabelGroupAdd`;
    let data = postService(url, body);
    return data;
  }

  contactLabelGroupUpdate(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactLabelGroupUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  cityPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/cityPagination?${query}`;
    let data = getService(url);
    return data;
  }

  cityAdd(body) {
    let url = `${appBaseUrl}/contactRequest/cityAdd`;
    let data = postService(url, body);
    return data;
  }

  cityUpdate(id, body) {
    let url = `${appBaseUrl}/contactRequest/cityUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  contactLabelPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/contactLabelsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  contactLabelAdd(body) {
    let url = `${appBaseUrl}/contactRequest/contactLabelAdd`;
    let data = postService(url, body);
    return data;
  }

  contactLabelUpdate(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactLabelUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  //HR currency pagination
  getCurrencyPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/currencyPagination?${query}`;
    let data = getService(url);
    return data;
  }
  currencyAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/currencyAdd`;
    let data = postService(url, body);
    return data;
  }

  currencyUpdate(id, body) {
    let url = `${appBaseUrl}/referenceManagement/currencyUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  getModalityPagination() {
    let url = `${appBaseUrl}/referenceManagement/modalityPagination`;
    let data = getService(url);
    return data;
  }
  modalityAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/modalityAdd`;
    let data = postService(url, body);
    return data;
  }
  modalityUpdate(id, body) {
    let url = `${appBaseUrl}/referenceManagement/modalityUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  getSectorPagination() {
    let url = `${appBaseUrl}/referenceManagement/sectorsPagination`;
    let data = getService(url);
    return data;
  }
  sectorAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/sectorAdd`;
    let data = postService(url, body);
    return data;
  }
  sectorUpdate(id, body) {
    let url = `${appBaseUrl}/referenceManagement/sectorUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  getStatusPagination() {
    let url = `${appBaseUrl}/referenceManagement/projectStatusPagination`;
    let data = getService(url);
    return data;
  }
  statusAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/projectStatusAdd`;
    let data = postService(url, body);
    return data;
  }
  statusUpdate(id, body) {
    let url = `${appBaseUrl}/referenceManagement/projectStatusUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  contactExpertAdd(body) {
    let url = `${appBaseUrl}/contactRequest/expertAdd`;
    let data = postService(url, body);
    return data;
  }

  contactExpertUpdate(body) {
    let url = `${appBaseUrl}/contactRequest/expertUpdate/${body.id}`;
    let data = postService(url, body);
    return data;
  }

  contactExpertGetDetails(id) {
    let url = `${appBaseUrl}/contactRequest/expertGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  tagsListing() {
    let url = `${appBaseUrl}/contactRequest/searchTagListing`;
    let data = getService(url);
    return data;
  }

  getCompanyorPersonMailListing(q, id) {
    // let query = queryString.stringify(q);
    // console.log("API q: ", q);
    // let url = `${appBaseUrl}/referenceManagement/companyAndPersonEmailListingById/${id}?${query}`;

    const contactPersonIds = q && q?.map((person) => person.contactPersonIds);
    const query = queryString.stringify(
      { contactPersonIds },
      { arrayFormat: "repeat" }
    );
    let url = ``;
    if (id && q) {
      url += `${appBaseUrl}/referenceManagement/companyAndPersonEmailListingById/${id}?${query}`;
    } else {
      url += `${appBaseUrl}/referenceManagement/companyAndPersonEmailListingById/${id}`;
    }
    let data = getService(url);
    return data;
  }
}
