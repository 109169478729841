import { ADMIN_SETTINGS_TYPES } from "../../../../types";

const INITIAL_STATE = {
    organizationLayerTypeListing: [],
    organizationLayerTypeListingLoading: false,

    // addAppointmentEventResp: [],
    // addAppointmentEventRespLoading: false,

    // appointmentMarkItOffResp: [],
    // appointmentMarkItOffLoading: false,
};

export default function organizationLayerTypeReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Appointment events
        case ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_TYPE_LISTING_INIT:
            return {
                ...state,
                organizationLayerTypeListing: [],
                organizationLayerTypeListingLoading: true,
            };
        case ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_TYPE_LISTING_SUCCESS:
            return {
                ...state,
                organizationLayerTypeListing: action.payload,
                organizationLayerTypeListingLoading: false,
            };
        case ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_TYPE_LISTING_FINISH:
            return {
                ...state,
                organizationLayerTypeListingLoading: false,
            };

        // // Appointment events add
        // case ADMIN_SETTINGS_TYPES.ADD_APPOINTMENT_INIT:
        //     return {
        //         ...state,
        //         addAppointmentEventResp: [],
        //         addAppointmentEventRespLoading: true,
        //     };
        // case ADMIN_SETTINGS_TYPES.ADD_APPOINTMENT_SUCCESS:
        //     return {
        //         ...state,
        //         addAppointmentEventResp: action.payload,
        //         addAppointmentEventRespLoading: false,
        //     };
        // case ADMIN_SETTINGS_TYPES.ADD_APPOINTMENT_FINISH:
        //     return {
        //         ...state,
        //         addAppointmentEventRespLoading: false,
        //     };

        // // Appointment events mark it off
        // case ADMIN_SETTINGS_TYPES.APPOINTMENT_COMPLETE_INIT:
        //     return {
        //         ...state,
        //         appointmentMarkItOffResp: [],
        //         appointmentMarkItOffLoading: true,
        //     };
        // case ADMIN_SETTINGS_TYPES.APPOINTMENT_COMPLETE_SUCCESS:
        //     return {
        //         ...state,
        //         appointmentMarkItOffResp: action.payload,
        //         appointmentMarkItOffLoading: false,
        //     };
        // case ADMIN_SETTINGS_TYPES.APPOINTMENT_COMPLETE_FINISH:
        //     return {
        //         ...state,
        //         appointmentMarkItOffLoading: false,
        //     };
        default:
            return { ...state };
    }
}
