import React from "react";

const GuestEntertainmentDetailList = ({ list }) => {
  return (
    <>
      <span style={{ paddingLeft: 25 }}>Subject: {list.subject}</span>
      <br />
      <span style={{ paddingLeft: 25 }}>
        Guests Number: {list?.applyNumberOfGuest}
      </span>
      <br />
      <span style={{ paddingLeft: 25 }}>
        Company:
        {list?.sisterCompanyName}
      </span>
    </>
  );
};

export default GuestEntertainmentDetailList;
