import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Progress,
  Select,
  Space,
  Tooltip,
  Upload,
  message,
} from "antd";
import { Editor } from "@tinymce/tinymce-react";

import {
  LoadingOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import ImageUploaderMultiple from "components/shared/ImageUploaderMultiple";
import { appBaseUrl } from "utils/config";
import { getAllUsers, uploadFile } from "services/remanentCalls";
import { quickEmailAddEmailSection } from "services/redux/email/mailMessageCenter/action";
import { getReferenceListing } from "services/redux/Reference/action";
import {
  actionForCreateGroup,
  actionForGetListingGroup,
  actionForUpdateGroup,
} from "services/redux/createGroup/action";

const { Option } = Select;

const antIcon = (
  <LoadingOutlined style={{ fontSize: 14, color: "white" }} spin />
);

const sendIntM = "./assets/svg/send-white.svg";
const cancel = "./assets/svg/white-cross.svg";
const attachment = "assets/svg/attachment-white.svg";

const layoutFormGroup = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const { SubMenu } = Menu;

export default function InternalM(props) {
  const dispatch = useDispatch();

  const [form4] = Form.useForm();

  const { groupData, groupDataLoading, groupDataLoadingSuccess } = useSelector(
    (state) => state.createUpdateGroup
  );

  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );

  const { quickEmailAddresponseLoading } = useSelector(
    (state) => state.instanceMessageCenter
  );

  const { form, setInternalVisible, origin } = props;

  const [internalEditorData, setInternalEditorData] = useState("");

  const [createGroupModal, setCreateGroupModal] = useState(false);

  const [selectedGroupDataToBeUpdated, setGroupDataToBeUpdate] = useState();

  const [filterReference, setFilterReference] = useState([]);

  const [searchValueReference, setSearchValueReference] = useState("");

  const [filterParticipantType, setFilterParticipantType] = useState("");

  const [AltEnt, setAltEnt] = useState(false);

  const [userListing, setUserListing] = useState([]);

  const [progress, setProgress] = useState(0);

  const [selectTosInternal, setSelectTosInternal] = useState(false);

  const [selectTosNewCutGroup, setSelectTosNewCutGroup] = useState();

  const [selectCcsNewCutGroup, setSelectCcsNewCutGroup] = useState();

  const [isGroupChat, setIsGroupChat] = useState(false);

  const fetchUserListing = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      setUserListing(resp.data);
    }
  };

  useEffect(() => {
    if (isEmpty(userListing)) {
      fetchUserListing();
    }
  }, []);

  useEffect(() => {
    if (filterParticipantType === "REF") {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }

    form.setFieldsValue({
      messageText: origin,
      mailMessageReferenceDtos: ["Id-aa"],
    });

    if (selectTosInternal) {
      let allParticipantsId = userListing?.map(
        (participant) => participant?.appUserId
      );
      form.setFieldsValue({
        participantTos: allParticipantsId,
      });
    } else {
      form.setFieldsValue({
        participantTos: [],
      });
    }
  }, [searchValueReference, selectTosInternal, origin]);

  useEffect(() => {
    if (!isEmpty(selectTosNewCutGroup)) {
      form.setFieldsValue({
        participantTos: selectTosNewCutGroup,
        participantCCs: selectCcsNewCutGroup,
      });
    }
  }, [selectTosNewCutGroup]);

  useEffect(() => {
    if (!isEmpty(referenceListingResponse) && filterParticipantType === "REF") {
      setFilterReference(referenceListingResponse.items);
    }
  }, [filterParticipantType, referenceListingResponse]);

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgress,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
    });
    console.log("responsess", resp);
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      // setAttatchments(alteredResponse);
      let olduploadedFileDtos = form.getFieldValue().uploadedFileDtos
        ? [...form.getFieldValue().uploadedFileDtos]
        : [];

      let newUploadedFileDtos = [...olduploadedFileDtos];
      newUploadedFileDtos.push(alteredResponse);
      // uploadedFileDtos[i] = e;
      form.setFieldsValue({
        uploadedFileDtos: newUploadedFileDtos,
      });
      console.log("newUploadedFileDtos", newUploadedFileDtos);
      // onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  //alt&Enter
  useEffect(() => {
    if (AltEnt) {
      handleSaveContinue();
      setAltEnt(false);
    }
  }, [AltEnt]);

  const menuGroup = () => (
    <Menu style={{ maxHeight: "300px", overflowY: "auto" }}>
      {groupData &&
        groupData?.map((x) => {
          return (
            <Menu.Item
              key={x?.id}
              onClick={() => {
                let userIdsForTo = [];
                let userIdsForCc = [];
                x?.userList?.map((x) => {
                  if (x?.participantTypeDto?.id === 0) {
                    userIdsForTo.push(x?.userId);
                  } else {
                    userIdsForCc.push(x?.userId);
                  }
                });
                setSelectTosNewCutGroup(userIdsForTo);
                setSelectCcsNewCutGroup(userIdsForCc);
              }}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{x?.groupName}</div>

              <EditOutlined
                style={{ paddingLeft: 15 }}
                onClick={() => {
                  setGroupDataToBeUpdate(x);
                  setCreateGroupModal(true);
                }}
              />
            </Menu.Item>
          );
        })}

      <Menu.Item
        key="Create Group"
        onClick={() => {
          setCreateGroupModal(true);
          // setGroupDataToBeUpdate();
          form4.resetFields();
        }}
      >
        Create Group
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!isEmpty(selectedGroupDataToBeUpdated)) {
      let userIdsForTo = [];
      let userIdsForCc = [];
      selectedGroupDataToBeUpdated?.userList?.map((x) => {
        if (x?.participantTypeDto?.id === 0) {
          userIdsForTo.push(x?.userId);
        } else {
          userIdsForCc.push(x?.userId);
        }
      });
      form4.setFieldsValue({
        groupName: selectedGroupDataToBeUpdated?.groupName,
        userListTo: userIdsForTo,
        userListCc: userIdsForCc,
      });
    }

    if (groupDataLoadingSuccess) {
      setCreateGroupModal(false);
      setSelectTosNewCutGroup();
    }
  });

  const handleSaveContinue = (values, formType) => {
    try {
      form.validateFields(["participantTos", "participantCCs"]);
    } catch (error) {
      // Validation failed, display error message or handle it as needed
      message.error("To and Cc cannot be empty");
      return;
    }
    let participantTosValue = form.getFieldValue().participantTos;
    let participantCCsValue = form.getFieldValue().participantCCs;
    let uploadedFileDtos = form.getFieldValue().uploadedFileDtos;
    let tos = [];
    let ccs = [];
    participantTosValue &&
      participantTosValue?.map((item, i) => {
        tos = [...tos, { participantUserId: item }];
      });
    participantCCsValue &&
      participantCCsValue?.map((item, i) => {
        ccs = [...ccs, { participantUserId: item }];
      });
    let messageText = form.getFieldsValue().messageText;
    let remarks = form.getFieldsValue().remarks;
    let referenceFields = form.getFieldsValue().mailMessageReferenceDtos;
    if (isEmpty(participantTosValue)) {
      // message.error("Quick message To's cannot be empty");
      return;
    }
    if (isEmpty(messageText || remarks)) {
      message.error("Quick message subject or remarks cannot be empty");
      return;
    }
    if (isEmpty(referenceFields)) {
      message.error("Reference cannot be empty");
      return;
    }
    let body = {
      isQuickMessage: true,
      // instanceMessageAttachmentDtos: attachments,
      instanceMessageAttachmentDtos: uploadedFileDtos,
      instanceMessageStatusName: "Normal",
      isGroupChat: isGroupChat,
      mailMessageParticipantWithTypeDtos: {
        participantTos: tos,
        participantCCs: ccs,
      },
      messageText: messageText,
      // remarks: remarks,
      remarks: internalEditorData,
      // mailMessageReferenceDtos: [
      //   {
      //     referenceCode: referenceFields,
      //   },
      // ],
      mailMessageReferenceDtos: referenceFields?.map((x) => {
        return {
          referenceCode: x,
        };
      }),
    };
    if (formType !== "newCutting") {
      dispatch(quickEmailAddEmailSection(body, setInternalVisible));
    } else {
    }

    form.resetFields();
    // setPredefinedMessage();
    setInternalEditorData("");
  };

  let paramsForGroupListing = "SkipCount=0&MaxResultCount=100";
  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, []);

  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, [selectedGroupDataToBeUpdated, createGroupModal]);

  const onGroupCreateFinish = (values) => {
    const tos = values?.userListTo?.map((x) => {
      return {
        userId: x,
        participantTypeId: 0,
      };
    });
    const ccs = values?.userListCc?.map((x) => {
      return {
        userId: x,
        participantTypeId: 1,
      };
    });
    const to_cc = [...tos, ...ccs];

    let apiDataForGroupCreation = {
      groupName: values?.groupName,
      userList: to_cc,
      isActive: true,
    };

    if (isEmpty(selectedGroupDataToBeUpdated)) {
      dispatch(actionForCreateGroup(apiDataForGroupCreation));
    } else {
      apiDataForGroupCreation = {
        ...apiDataForGroupCreation,
        id: selectedGroupDataToBeUpdated?.id,
      };
      dispatch(
        actionForUpdateGroup(
          apiDataForGroupCreation,
          selectedGroupDataToBeUpdated?.id
        )
      ).then((res) => {
        setGroupDataToBeUpdate(
          values?.userList?.map((x) => {
            return {
              userId: x,
            };
          })
        );
      });
    }
  };

  return (
    <>
      <Form
        form={form}
        className="drawer-form-wrapper internal_message_section_email_section"
      >
        <div className="internal_message_popup_email_section">
          <div className="left-chat-action-btn" style={{ marginLeft: "14px" }}>
            <div className="chat-box-send-wrapper">
              {quickEmailAddresponseLoading ? (
                antIcon
              ) : (
                <Space
                  // <Dropdown.Button
                  // overlay={centerTypeMenu}
                  onClick={() => {
                    handleSaveContinue();
                  }}
                >
                  <img src={sendIntM} alt="" />

                  {/* </Dropdown.Button> */}
                </Space>
              )}
            </div>
          </div>
          <div className="chat-action-btn" style={{ display: "flex" }}>
            <div className="chat-box-send-wrapper">
              <Dropdown.Button
                overlay={menuGroup}
                icon={<UsergroupAddOutlined />}
              ></Dropdown.Button>
            </div>

            <div>
              <Checkbox
                onChange={(e) => {
                  setIsGroupChat(!isGroupChat);
                }}
              >
                GroupChat
              </Checkbox>
            </div>

            <div>
              <Checkbox
                onChange={(e) => {
                  setSelectTosInternal(!selectTosInternal);
                }}
              >
                To All
              </Checkbox>
            </div>

            <div>
              <Upload
                name="uploadedFiles"
                multiple={true}
                itemRender={(files) => {
                  <div style={{ display: "none" }}>{files}</div>;
                }}
                action={`${appBaseUrl}/file/uploadFile/`}
                customRequest={uploadPhotos}
              >
                <img src={attachment} alt="" />
              </Upload>
            </div>

            <div
              className="btn__chat--cancel"
              onClick={() => {
                setInternalVisible(false);
                form.setFieldsValue({
                  participantTos: [],
                  participantCCs: [],
                });
              }}
            >
              <Tooltip title="close">
                <img src={cancel} alt="" />
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          className="drawer-form-row mail-quick-message"
          style={{ padding: 0, overflow: "hidden" }}
        >
          <div className="email-text-input">
            <div className="email-text">To</div>
            <Form.Item
              className="form__group"
              name="participantTos"
              // label="To"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                autoComplete="off"
                mode="multiple"
                bordered={false}
                optionFilterProp="label"
              >
                {/* {!isEmpty(toUserListings) &&
                  toUserListings?.map((user, index) => { */}
                {!isEmpty(userListing) &&
                  userListing?.map((user, index) => {
                    return (
                      <Option
                        value={user?.appUserId}
                        key={index}
                        label={[user.fullName, user.userName]}
                      >
                        {user?.fullName} [{user?.userName.toUpperCase()}]
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="email-text-input">
            <div className="email-text">Cc</div>
            <Form.Item
              className="form__group"
              name="participantCCs"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              // label="Cc"
            >
              <Select
                autoComplete="off"
                mode="multiple"
                optionFilterProp="label"
                bordered={false}
              >
                {/* {!isEmpty(ccUserListings) &&
                  ccUserListings?.map((user, index) => { */}
                {!isEmpty(userListing) &&
                  userListing?.map((user, index) => {
                    return (
                      <Option
                        value={user?.appUserId}
                        key={index}
                        label={[user.fullName, user.userName]}
                      >
                        {user?.fullName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="email-text-input">
            <div className="email-text">Subject</div>
            <Form.Item
              className="form__group"
              name="messageText"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="email-text-input-internal text_input_reference">
            <div className="email-text">Reference</div>
            <Form.Item
              // label="Reference"
              name="mailMessageReferenceDtos"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Select
                mode="multiple"
                getPopupContainer={(trigger) => trigger.parentNode}
                showSearch
                placeholder="Select reference"
                allowClear={true}
                onSearch={(value) => {
                  setSearchValueReference(value);
                  setFilterParticipantType("REF");
                }}
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                autoComplete="off"
                bordered={false}
              >
                {filterReference.map((reference, index) => {
                  return (
                    <Option
                      value={reference.code}
                      key={index}
                      label={[reference.code, reference.title]}
                    >
                      {/* {reference.code} */}
                      <div className="demo-option-label-item">
                        <div>
                          {reference.code} {""}
                        </div>
                        <div>{reference.title}</div>
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          {progress > 0 ? <Progress percent={progress} /> : null}

          {!isEmpty(form.getFieldValue().uploadedFileDtos) && (
            <div className="email-text-input text_input_reference">
              <div className="email-text">Attachments</div>
              <Form.Item
                name="uploadedFileDtos"
                style={{ width: "calc(100% - 113px)" }}
              >
                <ImageUploaderMultiple
                  name="emailMessageAttachmentDtos"
                  isMultipleFileAllowed={true}
                  uploadedFileDtos={form.getFieldValue()}
                  formName="uploadedFileDtos"
                  form={form}
                />
              </Form.Item>
            </div>
          )}

          {/* <div
            className="email-text-input"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="email-text">
              Msg
              <Link
                // className="quick_message_predefined_button"
                to="#"
                className="btn btn-primary mb1 bg-blue"
                style={{ height: "20px", width: "30px", marginLeft: "8px" }}
                // style={{}}
                onClick={() => {
                  setOpenPrefinedModal(true);
                }}
              >
                Add
              </Link>
            </div>
           
            <Select
              bordered={false}
              onSelect={(e, data) => {
                setPredefinedMessage(data?.children);
                setInternalEditorData(data?.children);
              }}
              placeholder="Select Predefined Message"
            >
              {predefinedData?.map((data, index) => {
                return <Option key={index}>{data?.message}</Option>;
              })}
            </Select>
            <div></div>
          </div> */}
          <div
            // className="drawer-form-group textarea_quick_message"
            style={{ width: "100%" }}
          >
            <Editor
              value={internalEditorData}
              // toolbar_location="bottom"
              onKeyDown={(e) => {
                const { key, altKey } = e;
                if (altKey && key === "Enter") {
                  setAltEnt(true);
                }
              }}
              init={{
                plugins: "lists advlist",
                browser_spellcheck: true,
                menubar: "false",
                toolbar: "", //bold  italic underLine  | backcolor forecolor   |  bullist numlist

                content_style: "p{margin:0px}",
                forced_root_block_attrs: {
                  style: "font-family: Arial; font-size: 10pt",
                  id: "internal_replyBox",
                },
              }}
              onEditorChange={(e) => {
                setInternalEditorData(e);
              }}
              // id="replyChatBox"
            />
          </div>
        </div>
      </Form>

      <Modal
        visible={createGroupModal}
        title={`${
          isEmpty(selectedGroupDataToBeUpdated)
            ? "Create Your Group"
            : "Update Your Group"
        }`}
        onCancel={() => setCreateGroupModal(false)}
        footer={null}
        height={300}
      >
        <Form {...layoutFormGroup} onFinish={onGroupCreateFinish} form={form4}>
          <Form.Item
            name="groupName"
            label="Group Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Name of your group" />
          </Form.Item>

          <Form.Item
            name="userListTo"
            label="To Users"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Users"
              allowClear
              autoComplete="off"
              mode="multiple"
              optionFilterProp="label"
            >
              {!isEmpty(userListing) &&
                userListing?.map((user, index) => {
                  return (
                    <Option
                      value={user?.appUserId}
                      key={index}
                      label={[user.fullName, user.userName]}
                    >
                      {user?.fullName} [{user?.userName.toUpperCase()}]
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            name="userListCc"
            label="CC Users"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Users"
              allowClear
              autoComplete="off"
              mode="multiple"
              optionFilterProp="label"
            >
              {!isEmpty(userListing) &&
                userListing?.map((user, index) => {
                  return (
                    <Option
                      value={user?.appUserId}
                      key={index}
                      label={[user.fullName, user.userName]}
                    >
                      {user?.fullName} [{user?.userName.toUpperCase()}]
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={groupDataLoading}>
            {isEmpty(selectedGroupDataToBeUpdated) ? "Submit" : "Update"}
          </Button>
        </Form>
      </Modal>
    </>
  );
}
