import { error, finish, init, success, update } from "services/common";
import { EMPLOYMENT_REQUISITION_TYPES } from "../../types";
import { HrSystemServices } from "./api";
import { message } from "antd";

const hrSystemServices = new HrSystemServices();

export const actionForGetStageLevelListing = () => {
  return async (dispatch) => {
    dispatch(init(EMPLOYMENT_REQUISITION_TYPES.GET_ALL_STAGE_LEVEL_LISTING));
    const response = await hrSystemServices.apiForGetStageLevelListing();
    if (response?.isSuccess) {
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_ALL_STAGE_LEVEL_LISTING,
          response?.data
        )
      );
      // dispatch(
      //   finish(EMPLOYMENT_REQUISITION_TYPES.GET_ALL_STAGE_LEVEL_LISTING)
      // );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// CRUD Application

export const actionForCreateEmpRequisitionApplication = (body) => {
  return async (dispatch) => {
    dispatch(
      init(EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_REQUISITION_TYPES)
    );
    const response =
      await hrSystemServices.apiForCreateEmpRequisitionApplication(body);
    if (response?.isSuccess) {
      message.success("Job Application Created");
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_REQUISITION_TYPES,
          response?.data
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
    dispatch(
      finish(EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_REQUISITION_TYPES)
    );
  };
};

//All Application Details

// export const actionForGetEmpRequisitionAllApplicationDetail = () => {
//   return async (dispatch) => {
//     dispatch(
//       init(
//         EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES
//       )
//     );
//     const response =
//       await hrSystemServices.apiForGetEmpRequisitionApplicationListDetails();

//     if (response?.isSuccess) {
//       dispatch(
//         success(
//           EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES,
//           response?.data
//         )
//       );
//     } else {
//       dispatch(error(response?.errorMessage));
//     }
//     dispatch(
//       finish(
//         EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES
//       )
//     );
//   };
// };
// updated above code
export const actionForGetEmpRequisitionAllApplicationDetail = (
  query,
  loadMore
) => {
  return async (dispatch) => {
    dispatch(
      init(
        EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES
      )
    );
    const response =
      await hrSystemServices.apiForGetEmpRequisitionApplicationListDetails(
        query
      );
    if (response.isSuccess) {
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES,
          response.data,
          loadMore
        )
      );
      dispatch(
        finish(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//single Application Detail

//Get
export const actionForGetEmpRequisitionSingleApplicationDetail = (
  jobTitleId
) => {
  return async (dispatch) => {
    const response =
      await hrSystemServices.apiForGetEmpRequisitionSingleApplicationDetail(
        jobTitleId
      );

    if (response?.isSuccess) {
      dispatch(
        init(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES
        )
      );
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES,
          response?.data,
          "",
          "",
          "application"
        )
      );
      dispatch(
        finish(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

//sigle application status counter details

export const actionForGetEmpRequisitionSingleApplicationStatusCounterDetail = (
  jobTitleId
) => {
  return async (dispatch) => {
    dispatch(
      init(
        EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES
      )
    );
    const response =
      await hrSystemServices.apiForGetEmpRequisitionSingleApplicationStatusCounterDetail(
        jobTitleId
      );

    if (response?.isSuccess) {
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES,
          response?.data,
          "",
          "",
          "applicationStatusCounter"
        )
      );
      dispatch(
        finish(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

//update single application

export const actionForUpdateEmpRequisitionSingleApplicationDetail = (
  jobTitleId,
  body
) => {
  return async (dispatch) => {
    // dispatch(
    //   init(
    //     EMPLOYMENT_REQUISITION_TYPES.EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES
    //   )
    // );
    const response =
      await hrSystemServices.apiForUpdateEmpRequisitionSingleApplicationDetail(
        jobTitleId,
        body
      );

    if (response?.isSuccess) {
      message.success("Application Updated Successfully.");
      dispatch(
        update(
          EMPLOYMENT_REQUISITION_TYPES.EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES,
          response?.data
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

//end

//CRUD Applicants

//Basic Details

export const actionForCreateUpdateApplicants = (body, crudType) => {
  let msg =
    crudType === "update" || crudType === "updateBulk"
      ? "Applicant Updated"
      : "Applicant Added";
  return async (dispatch) => {
    dispatch(init(EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_TYPES));
    const response = await hrSystemServices.apiForCreateUpdateApplicants(body);
    if (response?.isSuccess) {
      message.success(msg);
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES,
          response?.data,
          "",
          "",
          {
            type: "applicants",
            crudType: crudType,
          }
        )
      );
      dispatch(
        success(EMPLOYMENT_REQUISITION_TYPES.APPLICANT_ADD, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
    dispatch(
      finish(
        EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES
      )
    );
  };
};

//Education Details
//Add Update education

export const actionForCreateUpdateApplicantsEducation = (
  body,
  applicantId,
  jobApplicationId,
  crudType
) => {
  let msg =
    crudType === "update"
      ? "Applicant Education Updatd"
      : "Applicant Education Added";
  return async (dispatch) => {
    const response =
      await hrSystemServices.apiForCreateUpdateApplicantsEducation(
        body,
        applicantId
      );
    if (response?.isSuccess) {
      message.success(msg);
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES,
          response?.data,
          "",
          "",
          {
            type: "applicantEducation",
            crudType: crudType,
            employeeRequisitionId: jobApplicationId,
            applicantId: applicantId,
          }
        )
      );
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES,
          response?.data,
          "",
          "",
          {
            type: "applicantEducation",
            crudType: crudType,
          }
        )
      );
      dispatch(
        finish(
          EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const actionForCreateUpdateApplicantsJobDetails = (
  body,
  applicantId,
  jobApplicationId,
  crudType
) => {
  let msg =
    crudType === "update"
      ? "Applicant Job Details Updatd"
      : "Applicant Job Details Added";
  return async (dispatch) => {
    const response =
      await hrSystemServices.apiForCreateUpdateApplicantsJobDetails(
        body,
        applicantId
      );
    if (response?.isSuccess) {
      message.success(msg);

      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES,
          response?.data,
          "",
          "",
          {
            type: "applicantJob",
            crudType: crudType,
            employeeRequisitionId: jobApplicationId,
            applicantId: applicantId,
          }
        )
      );

      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES,
          response?.data,
          "",
          "",
          {
            type: "applicantJob",
            crudType: crudType,
          }
        )
      );
      dispatch(
        finish(
          EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

//add update review
export const actionForCreateUpdateApplicantReviews = (
  body,
  applicantId,
  jobApplicationId,
  crudType
) => {
  let msg =
    crudType === "update"
      ? "Applicant Review Updatd"
      : "Applicant Review Added";
  return async (dispatch) => {
    const response = await hrSystemServices.apiForCreateUpdateApplicantReviews(
      body,
      applicantId
    );
    if (response?.isSuccess) {
      message.success(msg);
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES,
          response?.data,
          "",
          "",
          {
            type: "applicantReview",
            crudType: crudType,
            employeeRequisitionId: jobApplicationId,
            applicantId: applicantId,
          }
        )
      );
      dispatch(
        finish(
          EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// get corporate email listing
export const actionForCorporateEmailListing = (query) => {
  return async (dispatch) => {
    dispatch(init(EMPLOYMENT_REQUISITION_TYPES.GET_CORPORATE_MAIL_LISTING));
    const response = await hrSystemServices.apiForCorporateEmailListing(query);
    if (response.isSuccess) {
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_CORPORATE_MAIL_LISTING,
          response.data
        )
      );
      dispatch(finish(EMPLOYMENT_REQUISITION_TYPES.GET_CORPORATE_MAIL_LISTING));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// Final approve
export const actionForFinalApprove = (applicantId, data) => {
  return async (dispatch) => {
    dispatch(
      init(EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_FINAL_APPROVE)
    );
    const response = await hrSystemServices.apiForCreateFinalApprove(
      applicantId,
      data
    );
    if (response.isSuccess) {
      message.success("user migrate into employee");
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_FINAL_APPROVE,
          response.data
        )
      );
      dispatch(
        finish(EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_FINAL_APPROVE)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// Job Interview ADD
export const actionForCreateJobInterview = (data) => {
  return async (dispatch) => {
    dispatch(init(EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_INTERVIEW));
    const response = await hrSystemServices.apiForCreateJobInterview(data);
    if (response.isSuccess) {
      message.success("Added Job Interview");
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_INTERVIEW,
          response.data
        )
      );
      dispatch(
        finish(EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_INTERVIEW)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// UPDATE
export const actionForUpdateJobInterview = (applicantId, data) => {
  return async (dispatch) => {
    dispatch(init(EMPLOYMENT_REQUISITION_TYPES.UPDATE_APPLICANT_JOB_INTERVIEW));
    const response = await hrSystemServices.apiForUpdateJobInterview(
      applicantId,
      data
    );
    if (response.isSuccess) {
      message.success("updated");
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.UPDATE_APPLICANT_JOB_INTERVIEW,
          response.data
        )
      );
      dispatch(
        finish(EMPLOYMENT_REQUISITION_TYPES.UPDATE_APPLICANT_JOB_INTERVIEW)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForGetJobInterviewById = (id) => {
  return async (dispatch) => {
    dispatch(
      init(EMPLOYMENT_REQUISITION_TYPES.GET_APPLICANT_JOB_INTERVIEW_BY_ID)
    );
    const response = await hrSystemServices.apiForGetJobInterviewById(id);
    if (response.isSuccess) {
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_APPLICANT_JOB_INTERVIEW_BY_ID,
          response.data
        )
      );
      dispatch(
        finish(EMPLOYMENT_REQUISITION_TYPES.GET_APPLICANT_JOB_INTERVIEW_BY_ID)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// GET PAGINATION
export const actionForGetJobInterviewPag = () => {
  return async (dispatch) => {
    dispatch(init(EMPLOYMENT_REQUISITION_TYPES.GET_PAGINATION_JOB_INTERVIEW));
    const response = await hrSystemServices.apiForGetJobInterviewPagination();
    if (response.isSuccess) {
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.GET_PAGINATION_JOB_INTERVIEW,
          response.data
        )
      );
      dispatch(
        finish(EMPLOYMENT_REQUISITION_TYPES.GET_PAGINATION_JOB_INTERVIEW)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// Compose mail for job application
export const actionForJobApplicantComposeEmail = (data) => {
  return async (dispatch) => {
    dispatch(init(EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_MAIL));
    const response = await hrSystemServices.apiForJobApplicantComposeEmail(
      data
    );
    if (response.isSuccess) {
      message.success("job application mail sent");
      dispatch(
        success(
          EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_MAIL,
          response.data
        )
      );
      dispatch(finish(EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_MAIL));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// get hr search listing pagination
// export const apiForGetemployeeRequisitionGetPaginationListing = (
//   query,
//   loadMore
// ) => {
//   return async (dispatch) => {
//     dispatch(init(EMPLOYMENT_REQUISITION_TYPES.GET_HR_SYSTEM_SEARCH));
//     const response =
//       await hrSystemServices.apiForGetemployeeRequisitionGetPaginationListing(
//         query
//       );
//     if (response.isSuccess) {
//       dispatch(
//         success(
//           EMPLOYMENT_REQUISITION_TYPES.GET_HR_SYSTEM_SEARCH,
//           response.data,
//           loadMore
//         )
//       );
//       dispatch(finish(EMPLOYMENT_REQUISITION_TYPES.GET_HR_SYSTEM_SEARCH));
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };
