import React, { useEffect, useState } from "react";
import { Form, Modal, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import { WechatOutlined } from "@ant-design/icons";
import InternalMCompose from "components/internalMNew";
import { handleSpecialDate } from "utils/tooltipSepecialDate";
import getODBalCol from "./BudgetViewsCEMAT/ODBalanceCol";
import OneCommonModal from "../Modal/OneCommonModal";

const ODbalance = ({
  dashboardFinancialResponse,
  dashboardFinancialResponseloading,
}) => {
  const [form] = Form.useForm();

  const [widthh, setWidthh] = useState(window.innerWidth);

  const [internalVisible, setInternalVisible] = useState(false);

  const [modalVisible, setModalVisible] = useState({
    total: false,
    overdrawn: false,
    remaining: false,
    visible: false,
  });

  const [modalData, setModalData] = useState({
    dType: 0,
    query: "",
    colTitle: "",
    dExtaQuery: "",
  });

  const modalShow = (query, type, title, extraQuery) => {
    let dType = 0;
    let modalVisibleState = {
      total: false,
      overdrawn: false,
      remaining: false,
      visible: false,
    };

    if (type === "total") {
      modalVisibleState.total = true;
      modalVisibleState.visible = true;
      dType = 1;
    } else if (type === "overdrawn") {
      modalVisibleState.overdrawn = true;
      modalVisibleState.visible = true;
      dType = 2;
    } else if (type === "remaining") {
      modalVisibleState.remaining = true;
      modalVisibleState.visible = true;
      dType = 3;
    }

    setModalVisible(modalVisibleState);
    setModalData({
      query,
      dType: dType,
      colTitle: title,
      dExtaQuery: extraQuery,
    });
  };

  const columns = getODBalCol({
    widthh,
    modalShow,
  });

  useEffect(() => {
    const handleResize = () => setWidthh(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showNumber = (number) => {
    if (number === 0) {
      return `--`;
    } else if (number < 0) {
      return (
        <Typography.Text style={{ color: "red" }}>
          ({Math.abs(Math.trunc(number))?.toLocaleString()} )
        </Typography.Text>
      );
    } else {
      return (
        <Typography.Text>
          {Math.trunc(number)?.toLocaleString()}
        </Typography.Text>
      );
    }
  };

  // const columns = [
  //   {
  //     title: (
  //       <Typography.Paragraph style={{ textAlign: "left", width: "100%" }}>
  //         Bank
  //       </Typography.Paragraph>
  //     ),
  //     dataIndex: "bank",
  //     key: "bank",
  //     render: (_, record) => {
  //       const index =
  //         record?.accountHead?.indexOf(":") > 0
  //           ? record?.accountHead?.indexOf(":")
  //           : record?.accountHead?.length;
  //       const result = record?.accountHead?.substring(0, index);
  //       return (
  //         <Typography.Paragraph
  //           style={{
  //             textAlign: "left",
  //             fontSize: widthh < 1550 ? 13 : 15,
  //             fontWeight:
  //               record?.companyName === "Total :" ? "bold" : "inherit",
  //           }}
  //         >
  //           {result}
  //           <Typography.Text style={{ color: "blue" }}>
  //             {result == "Total" ? "" : <> [{record?.companyName}] </>}
  //           </Typography.Text>
  //         </Typography.Paragraph>
  //       );
  //     },
  //   },
  //   {
  //     title: (
  //       <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
  //         Total limit
  //       </Typography.Paragraph>
  //     ),
  //     dataIndex: "totalLimit",
  //     key: "totalLimit",
  //     render: (_, record) => {
  //       return (
  //         <Tooltip
  //           title={
  //             // record?.maxDate
  //             //   ? moment(record?.maxDate).format("YYYY MMM DD")
  //             //   : ""
  //             handleSpecialDate(record?.maxDate)
  //           }
  //         >
  //           <Typography.Paragraph
  //             style={{
  //               textAlign: "right",
  //               fontSize: widthh < 1550 ? 13 : 15,
  //               fontWeight:
  //                 record?.companyName === "Total :" ? "bold" : "inherit",
  //             }}
  //           >
  //             {showNumber(record?.totalLimit)}
  //           </Typography.Paragraph>
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     title: (
  //       <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
  //         {" "}
  //         Overdrawn
  //       </Typography.Paragraph>
  //     ),
  //     dataIndex: "overDrawn",
  //     key: "overDrawn",
  //     render: (_, record) => {
  //       return (
  //         <Tooltip
  //           title={
  //             // record?.maxDate
  //             //   ? moment(record?.maxDate).format("YYYY MMM DD")
  //             //   : ""
  //             handleSpecialDate(record?.maxDate)
  //           }
  //         >
  //           <Typography.Paragraph
  //             style={{
  //               textAlign: "right",
  //               fontSize: widthh < 1550 ? 13 : 15,
  //               fontWeight:
  //                 record?.companyName === "Total :" ? "bold" : "inherit",
  //             }}
  //           >
  //             {showNumber(record?.overDrawn)}
  //           </Typography.Paragraph>
  //         </Tooltip>
  //       );
  //     },
  //   },
  //   {
  //     title: (
  //       <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
  //         Limit Remaining
  //       </Typography.Paragraph>
  //     ),
  //     dataIndex: "limitRemaining",
  //     key: "limitRemaining",
  //     render: (_, record) => {
  //       return (
  //         <Tooltip
  //           title={
  //             // record?.maxDate
  //             //   ? moment(record?.maxDate).format("YYYY MMM DD")
  //             //   : ""
  //             handleSpecialDate(record?.maxDate)
  //           }
  //         >
  //           <Typography.Paragraph
  //             style={{
  //               textAlign: "right",
  //               fontSize: widthh < 1550 ? 13 : 15,
  //               fontWeight:
  //                 record?.companyName === "Total :" ? "bold" : "inherit",
  //             }}
  //           >
  //             {record?.financialInformationODBalance?.overDrawn >
  //             0.75 * record?.financialInformationODBalance?.totalLimit ? (
  //               <Typography.Text style={{ color: "red" }}>
  //                 {Math.trunc(record?.limitRemaining)?.toLocaleString()}
  //               </Typography.Text>
  //             ) : (
  //               <Typography.Text>
  //                 {Math.trunc(record?.limitRemaining)?.toLocaleString()}
  //               </Typography.Text>
  //             )}
  //           </Typography.Paragraph>
  //         </Tooltip>
  //       );
  //     },
  //   },
  // ];

  const filteredDataSource = dashboardFinancialResponse?.odBalance;

  const totalLimit = filteredDataSource?.reduce(
    (acc, curr) => acc + (curr?.totalLimit || 0),
    0
  );

  const overDrawn = filteredDataSource?.reduce(
    (acc, curr) => acc + (curr?.overDrawn || 0),
    0
  );

  const limitRemaining = filteredDataSource?.reduce(
    (acc, curr) => acc + (curr?.limitRemaining || 0),
    0
  );
  const totalRow = {
    key: "companyName",
    accountHead: "Total",
    // companyName: " ",
    totalLimit: totalLimit,
    overDrawn: overDrawn,
    limitRemaining: limitRemaining,
  };

  const dataSource = [...(filteredDataSource || []), totalRow];

  return (
    <div className=" honor-wrapper">
      <div
        className=" dashboards__events dashboards__body "
        style={{ height: 300 }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">OD Balance</h4>
          <WechatOutlined
            onClick={() => {
              setInternalVisible(true);
            }}
          />
        </div>

        <Table
          className="next-organizer-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          style={{ maxHeight: 270, overflow: "auto" }}
          loading={dashboardFinancialResponseloading}
        />
      </div>
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="OD Balance"
        />
      </Modal>

      {modalVisible?.total ||
      modalVisible?.overdrawn ||
      modalVisible?.remaining ? (
        <OneCommonModal
          modalVisible={modalVisible?.visible}
          setModalVisible={setModalVisible}
          modalData={modalData}
          type="odbalance"
        />
      ) : null}
    </div>
  );
};

export default ODbalance;
