import { ABSENT_TYPES } from "services/types";

const INITIAL_STATE = {
  allAbsentEvents: [],
  absentEventsLoading: false,

  noticeAddResponse: null,
  //New
  noticeBoardAddResponse: null,
  noticeboardListLoading: false,

  noticeAddResponseLoading: false,

  allNoticeList: [],
  //New Update Notice Board
  updateNoticeBoardResp: null,
  updateNoticeBoardLoading: false,

  noticeBoardLoading: false,
};

export default function absentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ABSENT_TYPES.ABSENT_LISTING_INIT:
      return {
        absentPaginationResponse: null,
        absentPaginationLoading: true,
      };
    case ABSENT_TYPES.ABSENT_LISTING_SUCCESS:
      return {
        ...state,
        absentPaginationResponse: action.payload,
        absentPaginationLoading: false,
      };
    case ABSENT_TYPES.ABSENT_LISTING_PAGINATION_FINISH:
      return {
        absentPaginationResponse: null,
        absentPaginationLoading: false,
      };
    case ABSENT_TYPES.TODAY_ABSENT_LIST_PAGINATION_INIT:
      return {
        todayAbsentListResp: null,
        todayAbsentListLoading: true,
      };
    case ABSENT_TYPES.TODAY_ABSENT_LIST_PAGINATION_SUCCESS:
      return {
        ...state,
        todayAbsentListResp: action.payload,
        todayAbsentListLoading: false,
      };
    case ABSENT_TYPES.TODAY_ABSENT_LIST_PAGINATION_FINISH:
      return {
        todayAbsentListResp: null,
        todayAbsentListLoading: false,
      };

    //Notice Board Pagination

    case ABSENT_TYPES.NOTICE_PAGINATION_INIT:
      return {
        noticePaginationResponse: null,
        noticePaginationLoading: true,
      };
    case ABSENT_TYPES.NOTICE_PAGINATION_SUCCESS:
      return {
        ...state,
        noticePaginationResponse: action.payload,
        noticePaginationLoading: false,
      };
    case ABSENT_TYPES.NOTICE_PAGINATION_FINISH:
      return {
        noticePaginationResponse: null,
        noticePaginationLoading: false,
      };

    case ABSENT_TYPES.NOTICE_BOARD_INIT:
      return {
        noticeBoardAddResponse: null,
        noticeboardListLoading: true,
      };
    case ABSENT_TYPES.NOTICE_BOARD_SUCCESS:
      return {
        ...state,
        noticeBoardAddResponse: action.payload,
        noticeboardListLoading: false,
      };
    case ABSENT_TYPES.NOTICE_BOARD_FINISH:
      return {
        noticeBoardAddResponse: null,
        noticeboardListLoading: true,
      };
    case ABSENT_TYPES.NOTICE_BOARD_PAGINATION_SUCCESS:
      return {
        ...state,
        allNoticeList: action.payload,
      };
    // Update Notoce Application
    case ABSENT_TYPES.NOTICE_BOARD_UPDATE_INIT:
      return {
        updateNoticeBoardResp: null,
        updateNoticeBoardLoading: true,
      };
    case ABSENT_TYPES.NOTICE_BOARD_UPDATE_SUCCESS:
      return {
        ...state,
        updateNoticeBoardResp: action.payload,
        updateNoticeBoardLoading: false,
      };

    case ABSENT_TYPES.NOTICE_BOARD_UPDATE_FINISH:
      return {
        updateNoticeBoardResp: null,
        updateNoticeBoardLoading: false,
      };
    case ABSENT_TYPES.ATTENDANCE_PAGINATION_INIT:
      return {
        ...state,
        allUsersAttendancePaginationResponse: [],
        allUsersAttendancePaginationLoading: true,
      };
    case ABSENT_TYPES.ATTENDANCE_PAGINATION_SUCCESS:
      return {
        ...state,
        allUsersAttendancePaginationResponse: action.payload,
        allUsersAttendancePaginationLoading: false,
      };
    case ABSENT_TYPES.ATTENDANCE_PAGINATION_FINISH:
      return {
        allUsersAttendancePaginationResponse: [],
        allUsersAttendancePaginationLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
