import { Table } from "antd";
import moment from "moment";

export default function PerformanceBONDDetails({ singleTenderCeadData }) {
  const columns = [
    {
      title: "Issuing Bank",
      dataIndex: "issueingBank",
    },
    {
      title: "Issuing Foreign Bank",
      dataIndex: "foreignBank",
    },
    {
      title: "L/C Description",
      dataIndex: "description",
    },
    {
      title: "Currency",
      dataIndex: "currencyId",
      width: 60,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: 60,
    },
    {
      title: "Validity",
      dataIndex: "validUpto",
      width: 80,
    },
    {
      title: "Last Shipment Date",
      dataIndex: "lastShipmentDate",
      width: 110,
    },
    // {
    //   title: "Renewal Record",
    //   dataIndex: "isRenewalRecord",
    //   width: 60,
    // },
    // {
    //   title: "Action",
    //   render: () => {
    //     return <EditOutlined />;
    //   },
    // },
  ];

  const dataSource = singleTenderCeadData?.second_PerformanceBond?.map((x) => {
    return {
      ...x,
      validUpto: moment(x?.validUpto).format("YYYY-MM-DD"),
      lastShipmentDate:
        x?.lastShipmentDate && moment(x?.lastShipmentDate).format("YYYY-MM-DD"),
      isRenewalRecord: x?.isRenewalRecord ? "True" : "False",
      currencyId: x?.currencyDto?.abbvr,
    };
  });
  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        style={{ maxHeight: 300, overflow: "scroll" }}
        showHeader
      />
    </>
  );
}
