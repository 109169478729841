const Columns = ({
    columns,
    length,
})=>{
    return(
        <div className="global-columns-items">
            <div className={`global-columns-flex w-${length}`} >
                {
                    columns &&  columns?.map((column,index)=>{
                        return(
                           <div className={`global-column-item ${column.center && 'text-center'}`} key={index}>
                                <h5>
                                  {typeof column.title =='function' ? column.title() : column.title}
                                </h5>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


export default Columns
