import useRoles from "hooks/useRoles";
import { upperCase } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionForAddUpdateFinancialInformation,
  getFinancialDashboardListing,
} from "services/redux/admin/FiscalYear/action";

const {
  Card,
  Form,
  Typography,
  Row,
  Col,
  Input,
  Button,
  InputNumber,
} = require("antd");

export default function USHPCFinancialInfoUpdate() {
  const [formICCLIncExpStatus] = Form.useForm();
  const [formICCLNCFCBankStatus] = Form.useForm();
  const dispatch = useDispatch();
  const { LimitedRoleDemo } = useRoles();

  const {
    updateFiscalYrResponseIncIcclLoading,
    updateFiscalYrResponseNCFCICCLLoading,
    dashboardFinancialResponse,
  } = useSelector((state) => state.fiscalYearState);

  //   useEffect(() => {
  //     let appendUrl = "";
  //     if (LimitedRoleDemo) {
  //       appendUrl +=
  //         "maxResultCount=100&Category=Income Expense status&AccountHead=USHEC";
  //     }

  //     dispatch(getFinancialDashboardListing(appendUrl));
  //   }, [LimitedRoleDemo]);

  //   console.log("dashboardFinancialResponse", dashboardFinancialResponse);
  let dataICCLInc = dashboardFinancialResponse?.items?.find((x) => {
    return (
      x?.accountCategory === "Income Expense status" &&
      x?.accountHead === "ICCL"
    );
  });

  let dataICCLNCFCBankStatus = dashboardFinancialResponse?.items?.find((x) => {
    return (
      x?.accountCategory === "NC/FC Bank Status" && x?.accountHead === "ICCL"
    );
  });

  useEffect(() => {
    formICCLIncExpStatus?.setFieldsValue({
      amount:
        dataICCLInc?.financialInformationIncomeExpenseStatus
          ?.incomeInFiscalYear,
      amountTwo:
        dataICCLInc?.financialInformationIncomeExpenseStatus
          ?.expenseInFiscalYear,
      amountThree: dataICCLInc?.financialInformationIncomeExpenseStatus?.budget,
    });

    formICCLNCFCBankStatus?.setFieldsValue({
      amount: dataICCLNCFCBankStatus?.financialInformationNcFcBankStatus?.nc,
      amountTwo: dataICCLNCFCBankStatus?.financialInformationNcFcBankStatus?.fc,
      amountThree:
        dataICCLNCFCBankStatus?.financialInformationNcFcBankStatus?.total,
    });
  }, [dashboardFinancialResponse]);

  const onFinishForm = (values, formName) => {
    // console.log("formName", formName);
    let apiData = {
      financialAccountingInfomationId:
        formName === "Income Expense status"
          ? dataICCLInc?.financialAccountingInformationId
          : dataICCLNCFCBankStatus?.financialAccountingInformationId,
      companyAbbrevation: "ICCL",
      accountHead: "ICCL",
      accountCategory: formName,
      ...values,
    };
    dispatch(actionForAddUpdateFinancialInformation(apiData));
  };

  return (
    <>
      {/* <div style={{ display: "flex" }}> */}
      <Card
        style={{ margin: 100 }}
        title={
          <Typography.Title style={{ textAlign: "center" }} level={5}>
            FINANCIAL INFORMATION (ICCL-INCOME, EXPENSE, BUDGET STATUS) (LIMITED
            ROLE)
          </Typography.Title>
        }
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={(values) => onFinishForm(values, "Income Expense status")}
          form={formICCLIncExpStatus}
        >
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <Form.Item label="Income" name="amount">
                <InputNumber
                  placeholder="Enter Income"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Expense" name="amountTwo">
                <InputNumber
                  placeholder="Enter Expense"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Budget" name="amountThree">
                <InputNumber
                  placeholder="Enter Budget"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            loading={updateFiscalYrResponseIncIcclLoading}
          >
            Submit
          </Button>
        </Form>
      </Card>

      <Card
        style={{ margin: 100 }}
        title={
          <Typography.Title style={{ textAlign: "center" }} level={5}>
            FINANCIAL INFORMATION (ICCL-NC/FC Bank Status) (LIMITED ROLE)
          </Typography.Title>
        }
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={(values) => onFinishForm(values, "NC/FC Bank Status")}
          form={formICCLNCFCBankStatus}
        >
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <Form.Item label="Nepali Currency" name="amount">
                <InputNumber
                  placeholder="Enter Income"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Foreign Currency" name="amountTwo">
                <InputNumber
                  placeholder="Enter Expense"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            loading={updateFiscalYrResponseNCFCICCLLoading}
          >
            Submit
          </Button>
        </Form>
      </Card>
      {/* </div> */}
    </>
  );
}
