import React, { useEffect, useRef, useState, useCallback } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { useDispatch, useSelector } from "react-redux";
import tippy from "tippy.js";
import {
  englishToNepaliDateRangeConverter,
  getCalenderListEvents,
  updateCalenderListEvent,
} from "../../../services/redux/calendar/action";
import { organizationTaskEventGetDetails } from "services/redux/organizer/goals/action";
import { debounce, stubFalse, throttle } from "lodash";
import moment from "moment";
import { isEmpty, cloneDeep } from "lodash";
import { englishToNepaliDataConverter } from "services/redux/Home/action";
// import { events } from "ckeditor4-react/dist/events";

const Calender = ({
  allCalendarEvents,
  handleChangeEvent,
  setDateClickDetails,
  origin,
}) => {
  const calendarRef = useRef();
  // const calendarEventState = useSelector((state) => state.calendarEvents);

  const dispatch = useDispatch();
  const [allEvents, setAllEvents] = useState();

  const [currentSelectedMonth, setCurrentSelectedMonth] = useState();
  // const [previousButtonClicked, setPreviousButtonClicked] = useState(false);

  const {
    englishToNepaliDateConversionResponse,
    englishToNepaliDateConversionResponseLoading,
    calendarEventsLoaded,
  } = useSelector((state) => state.calendarEvents);
  const { nepaliDateResponse } = useSelector((state) => state.homeServices);

  useEffect(() => {
    if (!isEmpty(allCalendarEvents)) {
      allCalendarEvents.forEach((event) => {
        if (event.allDay) {
          event.end = moment(event.end)
            .add("1", "d")
            .format("YYYY-MM-DD hh:mm:ss");
        }
      });
    }
    setAllEvents(allCalendarEvents);
  }, [allCalendarEvents]);

  const debounceEventUpdate = useCallback(
    debounce((eventData) => calenderUpdateEvent(eventData), 300),
    []
  );

  const handleEventChange = (e) => {
    const eventData = e.event.toPlainObject();
    // calenderUpdateEvent(eventData);
    debounceEventUpdate(eventData);
  };

  const calenderUpdateEvent = (changedEvent) => {
    let endDate = changedEvent.end;
    if (changedEvent.start.length <= 10) {
      endDate = moment(changedEvent.end)
        .subtract("1", "d")
        .format("YYYY-MM-DD hh:mm A");
    } else {
      endDate = changedEvent.end
        ? moment(changedEvent.end).format("YYYY-MM-DD hh:mm A")
        : moment(changedEvent.start)
            .add(30, "minutes")
            .format("YYYY-MM-DD hh:mm A");
    }
    let body = {
      id: changedEvent.id,
      start: moment(changedEvent.start).format("YYYY-MM-DD hh:mm A"),
      textColor: changedEvent.textColor,
      title: changedEvent.title,
      end: endDate,
      description: changedEvent.extendedProps.description,
      eventTypeSystemName: changedEvent.extendedProps.eventTypeSystemName,
      parentEventTypeSystemName:
        changedEvent.extendedProps.parentEventTypeSystemName,
      allDay: changedEvent?.start.length > 10 ? false : true,
    };
    dispatch(updateCalenderListEvent(body));
  };

  useEffect(() => {
    let calendarApi = calendarRef.current.getApi();
  }, []);

  // to enable double click function on calendar dateclick
  let FullCalendarActions = {
    currentTime: null,
    isDblClick: function () {
      let prevTime =
        typeof FullCalendarActions.currentTime === null
          ? new Date().getTime() - 1000000
          : FullCalendarActions.currentTime;
      FullCalendarActions.currentTime = new Date().getTime();
      return FullCalendarActions.currentTime - prevTime < 200;
    },
  };

  const dateClickHandler = (info) => {
    if (FullCalendarActions.isDblClick()) {
      handleChangeEvent("event");
      setDateClickDetails(info);
    }
  };

  const nepaliDateDisplayFunc = () => {
    englishToNepaliDateConversionResponse?.map((data, index) => {
      const nepaliDate = document.createElement("p");
      nepaliDate.className = "fc_daygrid_nepali_date";
      const date = document.createTextNode(data?.nepaliDayInNepali);
      nepaliDate?.appendChild(date);
      const dataIndex1 = document
        .getElementsByClassName("fc-daygrid-day-frame")
        [index]?.appendChild(nepaliDate);
    });
  };
  !isEmpty(englishToNepaliDateConversionResponse) && nepaliDateDisplayFunc();

  useEffect(() => {
    dispatch(englishToNepaliDataConverter(moment().format("YYYY/MM/DD")));
  }, []);

  useEffect(() => {
    const firstTableData = document.getElementsByClassName("fc-daygrid-day")[0];
    const lastTableData = document.getElementsByClassName("fc-daygrid-day")[41];
    const firstDateMonth = document.getElementsByClassName(
      "fc-daygrid-day-number"
    )[0]?.innerText;
    const lastDateMonth = document.getElementsByClassName(
      "fc-daygrid-day-number"
    )[41]?.innerText;
    const selectedMonthAndYear =
      document.getElementsByClassName("fc-toolbar-title")[0]?.innerText;
    const selectedYear = selectedMonthAndYear?.substr(-4);
    const selectedMonth = selectedMonthAndYear?.replace(selectedYear, "");

    let firstMonth;
    let lastMonth;
    let firstYear;
    let lastYear;

    let selectedMonthNumber;

    // eslint-disable-next-line default-case
    switch (selectedMonth?.trim()) {
      case "January":
        selectedMonthNumber = 1;
        break;
      case "February":
        selectedMonthNumber = 2;
        break;
      case "March":
        selectedMonthNumber = 3;
        break;
      case "April":
        selectedMonthNumber = 4;
        break;
      case "May":
        selectedMonthNumber = 5;
        break;
      case "June":
        selectedMonthNumber = 6;
        break;
      case "July":
        selectedMonthNumber = 7;
        break;
      case "August":
        selectedMonthNumber = 8;
        break;
      case "September":
        selectedMonthNumber = 9;
        break;
      case "October":
        selectedMonthNumber = 10;
        break;
      case "November":
        selectedMonthNumber = 11;
        break;
      case "December":
        selectedMonthNumber = 12;
        break;
    }

    if (firstTableData.classList.contains("fc-day-other")) {
      firstMonth = selectedMonthNumber === 1 ? 12 : selectedMonthNumber - 1;
    } else {
      firstMonth = selectedMonthNumber;
    }

    if (lastTableData.classList.contains("fc-day-other")) {
      lastMonth = selectedMonthNumber === 12 ? 1 : selectedMonthNumber + 1;
    } else {
      lastMonth = selectedMonthNumber;
    }
    firstYear =
      selectedMonthNumber === 1 ? Number(selectedYear) - 1 : selectedYear;
    lastYear =
      selectedMonthNumber === 12 ? Number(selectedYear) + 1 : selectedYear;

    const firstDate = `${firstYear}/${firstMonth}/${firstDateMonth}`;
    const lastDate = `${lastYear}/${lastMonth}/${lastDateMonth}`;
    dispatch(englishToNepaliDateRangeConverter(firstDate, lastDate));
  }, [currentSelectedMonth]);

  useEffect(() => {
    if (!isEmpty(englishToNepaliDateConversionResponse)) {
      const holidayDates = [6, 13, 20, 27, 34, 41];
      holidayDates?.map((dates) => {
        const holidayDate = document.getElementsByClassName(
          "fc-daygrid-day-frame"
        )[dates];
        // holidayDate.style.backgroundColor = "#d824249c";
        holidayDate.style.backgroundColor = "#FD5D5D";
      });
      const childElementNepaliMonHeader = document.querySelector(
        ".nepali_month_header"
      );
      let nepaliMonth = document.createElement("h2");
      nepaliMonth.className = "nepali_month_header";
      const data = document.createTextNode(
        `/  ${englishToNepaliDateConversionResponse?.[0]?.nepaliMonthNameInNepali}-${englishToNepaliDateConversionResponse?.[41]?.nepaliMonthNameInNepali},${englishToNepaliDateConversionResponse?.[0]?.nepaliYearInNepali}`
      );

      const englishDate =
        document.getElementsByClassName("fc-toolbar-chunk")[1];

      if (!childElementNepaliMonHeader) {
        nepaliMonth.appendChild(data);
        englishDate?.appendChild(nepaliMonth);
      } else if (childElementNepaliMonHeader) {
        nepaliMonth.appendChild(data);

        if (englishDate.childNodes[1] !== undefined) {
          englishDate.replaceChild(nepaliMonth, englishDate.childNodes[1]);
        }
      }
    }
  }, [englishToNepaliDateConversionResponse]);

  // var previousButton = document.getElementsByClassName("fc-prev-button")
  // for(var i=0 ; i<previousButton?.length ; i++){
  //   previousButton[0].addEventListener("click",function(){
  //     console.log("it is printed");
  //     setPreviousButtonClicked(true)
  //   })
  // }

  function eventContent(eventInfo) {
    return (
      <div>
        {origin !== "Dashboard"
          ? `${eventInfo.timeText} ${eventInfo.event.title}`
          : eventInfo.event.title}
      </div>
    );
  }

  async function getCalendarData(fetchInfo, successCallback) {
    try {
      if (fetchInfo) {
        setCurrentSelectedMonth(new Date(fetchInfo.end).getMonth());
      }

      return origin !== "Dashboard"
        ? allEvents
        : allEvents?.map((x) => {
            return {
              ...x,
              title: "...",
              description: `${moment(x?.start).format("hh:mm a")} ${x?.title}`,
              className:
                x?.parentEventTypeSystemName == "BUSINESS"
                  ? "event-business"
                  : "event-personal",
            };
          });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="full-calender-body">
      <FullCalendar
        height={origin !== "Dashboard" ? "800px" : "600px"}
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        headerToolbar={{
          // left: origin !== "Dashboard" ? "prev,next today" : "",
          left: origin !== "Dashboard" ? "prev,next today" : "prev,next",
          center: "title",
          right:
            origin !== "Dashboard"
              ? "dayGridMonth,timeGridWeek,timeGridDay,listMonth"
              : "",
        }}
        initialView="dayGridMonth"
        dayMaxEventRows={3}
        events={(fetchInfo, successCallback, failureCallback) =>
          getCalendarData(fetchInfo, successCallback, failureCallback)
        }
        editable={false}
        disableDragging={true}
        eventStartEditable={false}
        startEditable={false}
        // nextDayThreshold="00:00:00"
        //  collapseExtendedProps = {true}
        // displayEventEnd={true}
        // eventResize={(e) => console.log(e.event.toPlainObject())}
        // collapseExtendedProps={true}
        // collapseColor={true}
        // allDay = {true}
        // datesSet={(date) =>
        //   setDateRange({ start: date.startStr, end: date.endStr })
        // }
        // rerenderDelay={null}
        // nextDayThreshold = '10:00'
        // eventDurationEditable={false}
        // editable={true}
        // eventStartEditable={true}
        // eventResizableFromStart={true}

        // dayHeaderContent={(info) => console.log(info)}
        // calendar={(date) => console.log(date)}
        //  moreLinkClick=""
        // dayPopoverFormat='list-item'
        // eventDisplay = 'list-item'
        // dateClick={(arg) => console.log("argstr",arg.dateStr)}
        // eventDidMount={(ev) => console.log(ev)}
        // dayHeaderContent ={(arg) => console.log(arg)}
        // eventChange={(e) => {handleEventChange(e.event.toPlainObject())}}
        // dateClick = {(info) => {
        // }}
        eventContent={eventContent}
        eventMouseEnter={(arg) => {
          let title = arg.event?.toPlainObject()?.title;
          let description =
            arg.event?.toPlainObject()?.extendedProps?.description;
          let eventCreator =
            arg.event?.toPlainObject()?.extendedProps?.eventCreatorName;
          tippy(arg.el, {
            content:
              origin === "Dashboard"
                ? `${eventCreator} ${description}`
                : `Title:${title}`,
            allowHTML: true,
          });
        }}
        defaultAllDay={true}
        eventChange={handleEventChange}
        eventClick={(arg) => {
          if (
            arg.event?.toPlainObject()?.extendedProps.eventTypeSystemName ===
              "PERSONAL_GOAL" &&
            arg.event?.toPlainObject()?.extendedProps.editable !== false
          ) {
            let eventId = arg.event?.toPlainObject()?.id;
            dispatch(organizationTaskEventGetDetails(eventId));
            handleChangeEvent("event");
          }
        }}
        dateClick={dateClickHandler}
      />
    </div>
  );
};

export default Calender;
