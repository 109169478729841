// global
export const GLOBAL = {
  GLOBAL_ERROR: "GLOBAL_ERROR",
};

// app
export const APP_TYPES = {
  APP_IS_ONLINE: "APP_IS_ONLINE",
  APP_IS_OFFLINE: "APP_IS_OFFLINE",
};

// auth
export const AUTH_TYPES = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_INIT: "USER_LOGIN_INIT",
  USER_LOGIN_FINISH: "USER_LOGIN_FINISH",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_CHANGEPASSWORD: "USER_CHANGEPASSWORD",
  USER_CHANGEPASSWORD_INIT: "USER_CHANGEPASSWORD_INIT",
  USER_CHANGEPASSWORD_FINISH: "USER_CHANGEPASSWORD_FINISH",
  USER_CHANGEPASSWORD_SUCCESS: "USER_CHANGEPASSWORD_SUCCESS",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  LEFT_USERS_SUCCESS: "LEFT_USERS_SUCCESS",
  LEFT_USERS_INIT: "LEFT_USERS_INIT",
  LEFT_USERS_ERROR: "LEFT_USERS_ERROR",

  //userChanegPassword
  USER_RESET_CHANGEPASSWORD: "USER_RESET_CHANGEPASSWORD",
  USER_RESET_CHANGEPASSWORD_INIT: "USER_RESET_CHANGEPASSWORD_INIT",
  USER_RESET_CHANGEPASSWORD_FINISH: "USER_RESET_CHANGEPASSWORD_FINISH",
  USER_RESET_CHANGEPASSWORD_SUCCESS: "USER_RESET_CHANGEPASSWORD_SUCCESS",

  //check dailly attendence
  CHECK_DAILY_ATTENDENCE: "CHECK_DAILY_ATTENDENCE",
  CHECK_DAILY_ATTENDENCE_INIT: "CHECK_DAILY_ATTENDENCE_INIT",
  CHECK_DAILY_ATTENDENCE_SUCCESS: "CHECK_DAILY_ATTENDENCE_SUCCESS",
  CHECK_DAILY_ATTENDENCE_FINISH: "CHECK_DAILY_ATTENDENCE_FINISH",
};

//settings
export const SETTINGS_TYPES = {
  SETTINGS_UPDATE: "SETTINGS_UPDATE",
  SETTINGS_INITIAL_LOAD: "SETTINGS_INITIAL_LOAD",

  SETTINGS_EMAIL_ZOOM_SHOW: "SETTINGS_EMAIL_ZOOM_SHOW",
  SETTINGS_EMAIL_ZOOM_SHOW_SUCCESS: "SETTINGS_EMAIL_ZOOM_SHOW_SUCCESS",
};

// profile
export const PROFILE_TYPES = {
  GET_GENDER: "GET_GENDER",
  GET_GENDER_INIT: "GET_GENDER_INIT",
  GET_GENDER_FINISH: "GET_GENDER_FINISH",
  GET_GENDER_SUCCESS: "GET_GENDER_SUCCESS",

  //MARTIAL STATUS
  GET_MARITALSTATUS: "GET_MARITALSTATUS",
  GET_MARITALSTATUS_INIT: "GET_MARITALSTATUS_INIT",
  GET_MARITALSTATUS_FINISH: "GET_MARITALSTATUS_FINISH",
  GET_MARITALSTATUS_SUCCESS: "GET_MARITALSTATUS_SUCCESS",

  //GET Profile
  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_INIT: "GET_PROFILE_INIT",
  GET_PROFILE_FINISH: "GET_PROFILE_FINISH",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",

  // GET Employee Evaluation profile
  GET_EMPLOYEE_EVALUATION_PROFILE: "GET_EMPLOYEE_EVALUATION_PROFILE",
  GET_EMPLOYEE_EVALUATION_PROFILE_INIT: "GET_EMPLOYEE_EVALUATION_PROFILE_INIT",
  GET_EMPLOYEE_EVALUATION_PROFILE_FINISH:
    "GET_EMPLOYEE_EVALUATION_PROFILE_INIT",
  GET_EMPLOYEE_EVALUATION_PROFILE_SUCCESS:
    "GET_EMPLOYEE_EVALUATION_PROFILE_SUCCESS",

  //Update Profile
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_INIT: "UPDATE_PROFILE_INIT",
  UPDATE_PROFILE_FINISH: "UPDATE_PROFILE_FINISH",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",

  //Update Profile official
  UPDATE_PROFILE_OFFICIAL: "UPDATE_PROFILE_OFFICIAL",
  UPDATE_PROFILE_OFFICIAL_INIT: "UPDATE_PROFILE_OFFICIAL_INIT",
  UPDATE_PROFILE_OFFICIAL_FINISH: "UPDATE_PROFILE_OFFICIAL_FINISH",
  UPDATE_PROFILE_OFFICIAL_SUCCESS: "UPDATE_PROFILE_OFFICIAL_SUCCESS",

  //USER RESGISTRATION
  USER_REGISTERATION: "USER_REGISTERATION",
  USER_REGISTERATION_INIT: "USER_REGISTERATION_INIT",
  USER_REGISTERATION_FINISH: "USER_REGISTERATION_FINISH",
  USER_REGISTERATION_SUCCESS: "USER_REGISTERATION_SUCCESS",

  //USER Employee Pagination
  EMPLOYEES_PAGINATION: "EMPLOYEES_PAGINATION",
  EMPLOYEES_PAGINATION_INIT: "EMPLOYEES_PAGINATION_INIT",
  EMPLOYEES_PAGINATION_FINISH: "EMPLOYEES_PAGINATION_FINISH",
  EMPLOYEES_PAGINATION_SUCCESS: "EMPLOYEES_PAGINATION_SUCCESS",

  //organizational Listing
  GET_ORGANIZATIONLISTING: "GET_ORGANIZATIONLISTING",
  GET_ORGANIZATIONLISTING_INIT: "GET_ORGANIZATIONLISTING_INIT",
  GET_ORGANIZATIONLISTING_FINISH: "GET_ORGANIZATIONLISTING_FINISH",
  GET_ORGANIZATIONLISTING_SUCCESS: "GET_ORGANIZATIONLISTING_SUCCESS",

  // Documents upload
  DOCUMENTS_UPLOAD: "DOCUMENTS_UPLOAD",
  DOCUMENTS_UPLOAD_INIT: "DOCUMENTS_UPLOAD_INIT",
  DOCUMENTS_UPLOAD_FINISH: "DOCUMENTS_UPLOAD_FINISH",
  DOCUMENTS_UPLOAD_SUCCESS: "DOCUMENTS_UPLOAD_SUCCESS",

  //employeeEmailList
  EMPLOYEE_EMAIL_LIST: " EMPLOYEE_EMAIL_LIST",
  EMPLOYEE_EMAIL_LIST_INIT: " EMPLOYEE_EMAIL_LIST_INIT",
  EMPLOYEE_EMAIL_LIST_FINISH: " EMPLOYEE_EMAIL_LIST_FINISH",
  EMPLOYEE_EMAIL_LIST_SUCCESS: " EMPLOYEE_EMAIL_LIST_SUCCESS",

  // Email signature add
  EMAIL_SIGNATURE_ADD: "EMAIL_SIGNATURE_ADD",
  EMAIL_SIGNATURE_ADD_INIT: "EMAIL_SIGNATURE_ADD_INIT",
  EMAIL_SIGNATURE_ADD_FINISH: "EMAIL_SIGNATURE_ADD_FINISH",
  EMAIL_SIGNATURE_ADD_SUCCESS: "EMAIL_SIGNATURE_ADD_SUCCESS",

  //activeemail signature details
  ACTIVE_EMAIL_SIGNATURE_DETAILS: "ACTIVE_EMAIL_SIGNATURE_DETAILS",
  ACTIVE_EMAIL_SIGNATURE_DETAILS_INIT: "ACTIVE_EMAIL_SIGNATURE_DETAILS_INIT",
  ACTIVE_EMAIL_SIGNATURE_DETAILS_FINISH:
    "ACTIVE_EMAIL_SIGNATURE_DETAILS_FINISH",
  ACTIVE_EMAIL_SIGNATURE_DETAILS_SUCCESS:
    "ACTIVE_EMAIL_SIGNATURE_DETAILS_SUCCESS",

  // email signature update
  EMAIL_SIGNATURE_UPDATE: "EMAIL_SIGNATURE_UPDATE",
  EMAIL_SIGNATURE_UPDATE_INIT: "EMAIL_SIGNATURE_UPDATE_INIT",
  EMAIL_SIGNATURE_UPDATE_FINISH: "EMAIL_SIGNATURE_UPDATE_FINISH",
  EMAIL_SIGNATURE_UPDATE_SUCCESS: "EMAIL_SIGNATURE_UPDATE_SUCCESS",

  // email mark as default
  EMAIL_MARK_AS_DEFAULT: "EMAIL_MARK_AS_DEFAULT",
  EMAIL_MARK_AS_DEFAULT_INIT: "EMAIL_MARK_AS_DEFAULT_INIT",
  EMAIL_MARK_AS_DEFAULT_FINISH: "EMAIL_MARK_AS_DEFAULT_FINISH",
  EMAIL_MARK_AS_DEFAULT_SUCCESS: "EMAIL_MARK_AS_DEFAULT_SUCCESS",

  //IsCheckInbox Mark as Active and Inactive
  // ISCHECKINBOX_MARK_AS_DEFAULT: "ISCHECKINBOX_MARK_AS_DEFAULT",
  // ISCHECKINBOX_MARK_AS_DEFAULT_INIT:  "ISCHECKINBOX_MARK_AS_DEFAULT_INT",
  // ISCHECKINBOX_MARK_AS_DEFAULT_FINISH: "ISCHECKINBOX_MARK_AS_DEFAULT_FINISH",
  // ISCHECKINBOX_MARK_AS_DEFAULT_SUCCESS: "ISCHECKINBOX_MARK_AS_DEFAULT_SUCCESS",

  ISCHECKINBOX_MARK_AS_UPDATE: "ISCHECKINBOX_MARK_AS_UPDATE",
  ISCHECKINBOX_MARK_AS_UPDATE_INIT: "ISCHECKINBOX_MARK_AS_UPDATE_INT",
  ISCHECKINBOX_MARK_AS_UPDATE_FINISH: "ISCHECKINBOX_MARK_AS_UPDATE_FINISH",
  ISCHECKINBOX_MARK_AS_UPDATE_SUCCESS: "ISCHECKINBOX_MARK_AS_UPDATE_SUCCESS",

  //joining Message compose message
  COMPOSE_JOINING_MESSAGE: "COMPOSE_JOINING_MESSAGE",
  COMPOSE_JOINING_MESSAGE_INIT: "COMPOSE_JOINING_MESSAGE_INIT",
  COMPOSE_JOINING_MESSAGE_FINISH: "COMPOSE_JOINING_MESSAGE_FINISH",
  COMPOSE_JOINING_MESSAGE_SUCCESS: "COMPOSE_JOINING_MESSAGE_SUCCESS",

  //left company
  LEFT_COMPANY: "LEFT_COMPANY",
  LEFT_COMPANY_INIT: "LEFT_COMPANY_INIT",
  LEFT_COMPANY_FINISH: "LEFT_COMPANY_FINISH",
  LEFT_COMPANY_SUCCESS: "LEFT_COMPANY_SUCCESS",

  // clearance form
  CLEARANCE_FORM_ADD: "CLEARANCE_FORM_ADD",
  CLEARANCE_FORM_ADD_INIT: "CLEARANCE_FORM_ADD_INIT",
  CLEARANCE_FORM_ADD_SUCCESS: "CLEARANCE_FORM_ADD_SUCCESS",
  CLEARANCE_FORM_ADD_FINISH: "CLEARANCE_FORM_ADD_SUCCESS",

  CLEARANCE_FORM_GET: "CLEARANCE_FORM_GET",
  CLEARANCE_FORM_GET_INIT: "CLEARANCE_FORM_GET_INIT",
  CLEARANCE_FORM_GET_SUCCESS: "CLEARANCE_FORM_GET_SUCCESS",
  CLEARANCE_FORM_GET_FINISH: "CLEARANCE_FORM_GET_FINISH",

  // question type listing
  QUESTION_TYPE_GET: "QUESTION_TYPE_GET",
  QUESTION_TYPE_GET_INIT: "QUESTION_TYPE_GET_INIT",
  QUESTION_TYPE_GET_SUCCESS: "QUESTION_TYPE_GET_SUCCESS",
  QUESTION_TYPE_GET_FINISH: "QUESTION_TYPE_GET_FINISH",

  // question type add
  QUESTION_TYPE_ADD: "QUESTION_TYPE_ADD",
  QUESTION_TYPE_ADD_INIT: "QUESTION_TYPE_ADD_INIT",
  QUESTION_TYPE_ADD_SUCCESS: "QUESTION_TYPE_ADD_SUCCESS",
  QUESTION_TYPE_ADD_FINISH: "QUESTION_TYPE_ADD_FINISH",

  // question type listing
  QUESTION_TYPE_QUESTION_GET: "QUESTION_TYPE_QUESTION_GET",
  QUESTION_TYPE_QUESTION_GET_INIT: "QUESTION_TYPE_QUESTION_GET_INIT",
  QUESTION_TYPE_QUESTION_GET_SUCCESS: "QUESTION_TYPE_QUESTION_GET_SUCCESS",
  QUESTION_TYPE_QUESTION_GET_FINISH: "QUESTION_TYPE_QUESTION_GET_FINISH",

  // exit interview get
  EXIT_INTERVIEW_GET: "EXIT_INTERVIEW_GET",
  EXIT_INTERVIEW_GET_INIT: "EXIT_INTERVIEW_GET_INIT",
  EXIT_INTERVIEW_GET_SUCCESS: "EXIT_INTERVIEW_GET_SUCCESS",
  EXIT_INTERVIEW_GET_FINISH: "EXIT_INTERVIEW_GET_FINISH",

  // post
  EXIT_INTERVIEW_ADD: "EXIT_INTERVIEW_ADD",
  EXIT_INTERVIEW_ADD_INIT: "EXIT_INTERVIEW_ADD_INIT",
  EXIT_INTERVIEW_ADD_SUCCESS: "EXIT_INTERVIEW_ADD_SUCCESS",
  EXIT_INTERVIEW_ADD_FINISH: "EXIT_INTERVIEW_ADD_FINISH",

  // get
  EMPLOYEE_REVIEW_GET: "EMPLOYEE_REVIEW_GET",
  EMPLOYEE_REVIEW_GET_INIT: "EMPLOYEE_REVIEW_GET_INIT",
  EMPLOYEE_REVIEW_GET_SUCCESS: "EMPLOYEE_REVIEW_GET_SUCCESS",
  EMPLOYEE_REVIEW_GET_FINISH: "EMPLOYEE_REVIEW_GET_FINISH",

  // mail
  EMPLOYEE_SUPERVISOR_MAIL: "EMPLOYEE_SUPERVISOR_MAIL",
  EMPLOYEE_SUPERVISOR_MAIL_INIT: "EMPLOYEE_SUPERVISOR_MAIL_INIT",
  EMPLOYEE_SUPERVISOR_MAIL_SUCCESS: "EMPLOYEE_SUPERVISOR_MAIL_SUCCESS",
  EMPLOYEE_SUPERVISOR_MAIL_FINISH: "EMPLOYEE_SUPERVISOR_MAIL_FINISH",

  // employeeEvaluationSelfAction
  EMPLOYEE_EVALUATION_SELF_ACTION: "EMPLOYEE_EVALUATION_SELF_ACTION",
  EMPLOYEE_EVALUATION_SELF_ACTION_INIT: "EMPLOYEE_EVALUATION_SELF_ACTION_INIT",
  EMPLOYEE_EVALUATION_SELF_ACTION_SUCCESS:
    "EMPLOYEE_EVALUATION_SELF_ACTION_SUCCESS",
  EMPLOYEE_EVALUATION_SELF_ACTION_FINISH:
    "EMPLOYEE_EVALUATION_SELF_ACTION_FINISH",

  // SUPER API
  LISTING_ALL_USER_PROFILE: "LISTING_ALL_USER_PROFILE",
  LISTING_ALL_USER_PROFILE_INIT: "LISTING_ALL_USER_PROFILE_INIT",
  LISTING_ALL_USER_PROFILE_SUCCESS: "LISTING_ALL_USER_PROFILE_SUCCESS",
  LISTING_ALL_USER_PROFILE_FINISH: "LISTING_ALL_USER_PROFILE_FINISH",
};

// contact
export const CONTACT_TYPES = {
  COUNTRY_LISTING: "COUNTRY_LISTING",
  COUNTRY_LISTING_INIT: "COUNTRY_LISTING_INIT",
  COUNTRY_LISTING_FINISH: "COUNTRY_LISTING_FINISH",
  COUNTRY_LISTING_SUCCESS: "COUNTRY_LISTING_SUCCESS",

  COUNTRY_PAGINATION: "COUNTRY_PAGINATION",
  COUNTRY_PAGINATION_INIT: "COUNTRY_PAGINATION_INIT",
  COUNTRY_PAGINATION_FINISH: "COUNTRY_PAGINATION_FINISH",
  COUNTRY_PAGINATION_SUCCESS: "COUNTRY_PAGINATION_SUCCESS",

  ALL_COUNTRY_LISTING: "ALL_COUNTRY_LISTING",
  ALL_COUNTRY_LISTING_INIT: "ALL_COUNTRY_LISTING_INIT",
  ALL_COUNTRY_LISTING_FINISH: "ALL_COUNTRY_LISTING_FINISH",
  ALL_COUNTRY_LISTING_SUCCESS: "ALL_COUNTRY_LISTING_SUCCESS",

  COUNTRY_ADD: "COUNTRY_ADD",
  COUNTRY_ADD_INIT: "COUNTRY_ADD_INIT",
  COUNTRY_ADD_FINISH: "COUNTRY_ADD_FINISH",
  COUNTRY_ADD_SUCCESS: "COUNTRY_ADD_SUCCESS",
  COUNTRY_UPDATE: "COUNTRY_UPDATE",
  COUNTRY_UPDATE_INIT: "COUNTRY_UPDATE_INIT",
  COUNTRY_UPDATE_FINISH: "COUNTRY_UPDATE_FINISH",
  COUNTRY_UPDATE_SUCCESS: "COUNTRY_UPDATE_SUCCESS",

  CONTACT_LABELS_PAGINATION: "CONTACT_LABELS_PAGINATION",
  CONTACT_LABELS_PAGINATION_INIT: "CONTACT_LABELS_PAGINATION_INIT",
  CONTACT_LABELS_PAGINATION_FINISH: "CONTACT_LABELS_PAGINATION_FINISH",
  CONTACT_LABELS_PAGINATION_SUCCESS: "CONTACT_LABELS_PAGINATION_SUCCESS",
  CONTACT_LABELS_ADD: "CONTACT_LABELS_ADD",
  CONTACT_LABELS_ADD_INIT: "CONTACT_LABELS_ADD_INIT",
  CONTACT_LABELS_ADD_FINISH: "CONTACT_LABELS_ADD_FINISH",
  CONTACT_LABELS_ADD_SUCCESS: "CONTACT_LABELS_ADD_SUCCESS",
  CONTACT_LABELS_UPDATE: "CONTACT_LABELS_UPDATE",
  CONTACT_LABELS_UPDATE_INIT: "CONTACT_LABELS_UPDATE_INIT",
  CONTACT_LABELS_UPDATE_FINISH: "CONTACT_LABELS_UPDATE_FINISH",
  CONTACT_LABELS_UPDATE_SUCCESS: "CONTACT_LABELS_UPDATE_SUCCESS",

  CITY_PAGINATION: "CITY_PAGINATION",
  CITY_PAGINATION_INIT: "CITY_PAGINATION_INIT",
  CITY_PAGINATION_FINISH: "CITY_PAGINATION_FINISH",
  CITY_PAGINATION_SUCCESS: "CITY_PAGINATION_SUCCESS",

  CITY_ADD: "CITY_ADD",
  CITY_ADD_INIT: "CITY_ADD_INIT",
  CITY_ADD_FINISH: "CITY_ADD_FINISH",
  CITY_ADD_SUCCESS: "CITY_ADD_SUCCESS",
  CITY_UPDATE: "CITY_UPDATE",
  CITY_UPDATE_INIT: "CITY_UPDATE_INIT",
  CITY_UPDATE_FINISH: "CITY_UPDATE_FINISH",
  CITY_UPDATE_SUCCESS: "CITY_UPDATE_SUCCESS",

  // CurrencyAdd
  CURRENCY_ADD: "CURRENCY_ADD",
  CURRENCY_ADD_INIT: "CURRENCY_ADD_INIT",
  CURRENCY_ADD_FINISH: "CURRENCY_ADD_FINISH",
  CURRENCY_ADD_SUCCESS: "CURRENCY_ADD_SUCCESS",

  // CurrencyPagiination
  CURRENCY_PAGINATION: "CURRENCY_PAGINATION",
  CURRENCY_PAGINATION_INIT: "CURRENCY_PAGINATION_INIT",
  CURRENCY_PAGINATION_FINISH: "CURRENCY_PAGINATION_FINISH",
  CURRENCY_PAGINATION_SUCCESS: "CURRENCY_PAGINATION_SUCCESS",

  //CurrencyUpdate
  CURRENCY_UPDATE: "CURRENCY_UPDATE",
  CURRENCY_UPDATE_INIT: "CURRENCY_UPDATE_INIT",
  CURRENCY_UPDATE_FINISH: "CURRENCY_UPDATE_FINISH",
  CURRENCY_UPDATE_SUCCESS: "CURRENCY_UPDATE_SUCCESS",

  //Modality
  MODALITY_ADD: "MODALITY_ADD",
  MODALITY_ADD_INIT: "MODALITY_ADD_INIT",
  MODALITY_ADD_FINISH: "MODALITY_ADD_FINISH",
  MODALITY_ADD_SUCCESS: "MODALITY_ADD_SUCCESS",

  // ModalityPagination
  MODALITY_PAGINATION: "MODALITY_PAGINATION",
  MODALITY_PAGINATION_INIT: "MODALITY_PAGINATION_INIT",
  MODALITY_PAGINATION_FINISH: "MODLAITY_PAGINATION_FINISH",
  MODALITY_PAGINATION_SUCCESS: "MODALITY_PAGINATION_SUCCESS",

  //Modality Update
  MODALITY_UPDATE: "MODALITY_UPDATE",
  MODALITY_UPDATE_INIT: "MODALITY_UPDATE_INIT",
  MODALITY_UPDATE_FINISH: "MODALITY_UPDATE_FINISH",
  MODALITY_UPDATE_SUCCESS: "MODALITY_UPDATE_SUCCESS",

  //CurrencyUpdate
  CURRENCY_UPDATE: "CURRENCY_UPDATE",
  CURRENCY_UPDATE_INIT: "CURRENCY_UPDATE_INIT",
  CURRENCY_UPDATE_FINISH: "CURRENCY_UPDATE_FINISH",
  CURRENCY_UPDATE_SUCCESS: "CURRENCY_UPDATE_SUCCESS",

  CONTACT_LABEL_PAGINATION: "CONTACT_LABEL_PAGINATION",
  CONTACT_LABEL_PAGINATION_INIT: "CONTACT_LABEL_PAGINATION_INIT",
  CONTACT_LABEL_PAGINATION_FINISH: "CONTACT_LABEL_PAGINATION_FINISH",
  CONTACT_LABEL_PAGINATION_SUCCESS: "CONTACT_LABEL_PAGINATION_SUCCESS",
  CONTACT_LABEL_ADD: "CONTACT_LABEL_ADD",
  CONTACT_LABEL_ADD_INIT: "CONTACT_LABEL_ADD_INIT",
  CONTACT_LABEL_ADD_FINISH: "CONTACT_LABEL_ADD_FINISH",
  CONTACT_LABEL_ADD_SUCCESS: "CONTACT_LABEL_ADD_SUCCESS",
  CONTACT_LABEL_UPDATE: "CONTACT_LABEL_UPDATE",
  CONTACT_LABEL_UPDATE_INIT: "CONTACT_LABEL_UPDATE_INIT",
  CONTACT_LABEL_UPDATE_FINISH: "CONTACT_LABEL_UPDATE_FINISH",
  CONTACT_LABEL_UPDATE_SUCCESS: "CONTACT_LABEL_UPDATE_SUCCESS",

  // Expert Add
  CONTACT_EXPERT_ADD: "CONTACT_EXPERT_ADD",
  CONTACT_EXPERT_ADD_INIT: "CONTACT_EXPERT_ADD_INIT",
  CONTACT_EXPERT_ADD_FINISH: "CONTACT_EXPERT_ADD_FINISH",
  CONTACT_EXPERT_ADD_SUCCESS: "CONTACT_EXPERT_ADD_SUCCESS",

  // Expert Update
  CONTACT_EXPERT_UPDATE: "CONTACT_EXPERT_UPDATE",
  CONTACT_EXPERT_UPDATE_INIT: "CONTACT_EXPERT_UPDATE_INIT",
  CONTACT_EXPERT_UPDATE_FINISH: "CONTACT_EXPERT_UPDATE_FINISH",
  CONTACT_EXPERT_UPDATE_SUCCESS: "CONTACT_EXPERT_UPDATE_SUCCESS",

  // Expert Get
  CONTACT_EXPERT_GET: "CONTACT_EXPERT_GET",
  CONTACT_EXPERT_GET_INIT: "CONTACT_EXPERT_GET_INIT",
  CONTACT_EXPERT_GET_FINISH: "CONTACT_EXPERT_GET_FINISH",
  CONTACT_EXPERT_GET_SUCCESS: "CONTACT_EXPERT_GET_SUCCESS",

  //all tags
  ALL_TAGS_LIST: "ALL_TAGS_LIST",
  ALL_TAGS_LIST_INIT: "ALL_TAGS_LIST_INIT",
  ALL_TAGS_LIST_FINISH: "ALL_TAGS_LIST_FINISH",
  ALL_TAGS_LIST_SUCCESS: "ALL_TAGS_LIST_SUCCESS",

  // company and person mail address
  COMPANY_PERSON_MAIL_LISTING: "COMPANY_PERSON_MAIL_LISTING",
  COMPANY_PERSON_MAIL_LISTING_INIT: "COMPANY_PERSON_MAIL_LISTING_INIT",
  COMPANY_PERSON_MAIL_LISTING_FINISH: "COMPANY_PERSON_MAIL_LISTING_FINISH",
  COMPANY_PERSON_MAIL_LISTING_SUCCESS: "COMPANY_PERSON_MAIL_LISTING_SUCCESS",

  COMPANY_EMAIL_ADDRESS_LISTING: "COMPANY_EMAIL_ADDRESS_LISTING",
  COMPANY_EMAIL_ADDRESS_LISTING_INIT: "COMPANY_EMAIL_ADDRESS_LISTING_INIT",
  COMPANY_EMAIL_ADDRESS_LISTING_FINISH: "COMPANY_EMAIL_ADDRESS_LISTING_FINISH",
  COMPANY_EMAIL_ADDRESS_LISTING_SUCCESS:
    "COMPANY_EMAIL_ADDRESS_LISTING_SUCCESS",
};

export const COMPANY_PEOPLE_TYPES = {
  contactCompanyPersonSearch: "contactCompanyPersonSearch",
  contactCompanyPersonSearchPaginationChange:
    "contactCompanyPersonSearchPaginationChange",
  contactCompanyPersonSearch_INIT: "contactCompanyPersonSearch_INIT",
  contactCompanyPersonSearch_FINISH: "contactCompanyPersonSearch_FINISH",
  contactCompanyPersonSearch_SUCCESS: "contactCompanyPersonSearch_SUCCESS",
  contactCompanyPersonSearchPaginationChange_SUCCESS:
    "contactCompanyPersonSearchPaginationChange_SUCCESS",

  //for getting reponse from another tab while uploading

  CONTACT_TYPE_UPDATE_CONTACTRESPONSE: "CONTACT_TYPE_UPDATE_CONTACTRESPONSE",

  // contact company listing
  CONTACT_PERSON_LISTING: "CONTACT_PERSON_LISTING",
  CONTACT_PERSON_LISTING_INIT: "CONTACT_PERSON_LISTING_INIT",
  CONTACT_PERSON_LISTING_FINISH: "CONTACT_PERSON_LISTING_FINISH",
  CONTACT_PERSON_LISTING_SUCCESS: "CONTACT_PERSON_LISTING_SUCCESS",

  //DELETE COMPANY
  COMPANY_DELETE: "COMPANY_DELETE",
  COMPANY_DELETE_INIT: "COMPANY_DELETE_INIT",
  COMPANY_DELETE_FINISH: "COMPANY_DELETE_FINISH",
  COMPANY_DELETE_SUCCESS: "COMPANY_DELETE_SUCCESS",

  //Person delete
  PERSON_DELETE: "PERSON_DELETE",
  PERSON_DELETE_INIT: "PERSON_DELETE_INIT",
  PERSON_DELETE_FINISH: "PERSON_DELETE_FINISH",
  PERSON_DELETE_SUCCESS: "PERSON_DELETE_SUCCESS",

  //for Search Perons Listing
  CONTACT_PERSON_SEARCH_LISTING: "CONTACT_PERSON_SEARCH_LISTING",
  CONTACT_PERSON_SEARCH_LISTING_INIT: "CONTACT_PERSON_SEARCH_LISTING_INIT",
  CONTACT_PERSON_SEARCH_LISTING_FINISH: "CONTACT_PERSON_SEARCH_LISTING_FINISH",
  CONTACT_PERSON_SEARCH_LISTING_SUCCESS:
    "CONTACT_PERSON_SEARCH_LISTING_SUCCESS",

  //For Search Company Listing
  CONTACT_COMPANY_SEARCH_LISTING: "CONTACT_COMPANY_SEARCH_LISTING",
  CONTACT_COMPANY_SEARCH_LISTING_INIT: "CONTACT_COMPANY_SEARCH_LISTING_INIT",
  CONTACT_COMPANY_SEARCH_LISTING_FINISH:
    "CONTACT_COMPANY_SEARCH_LISTING_FINISH",
  CONTACT_COMPANY_SEARCH_LISTING_SUCCESS:
    "CONTACT_COMPANY_SEARCH_LISTING_SUCCESS",

  // contact company pagination
  CONTACT_COMPANY_PAGINATION: "CONTACT_COMPANY_PAGINATION",
  CONTACT_COMPANY_PAGINATION_INIT: "CONTACT_COMPANY_PAGINATION_INIT",
  CONTACT_COMPANY_PAGINATION_FINISH: "CONTACT_COMPANY_PAGINATION_FINISH",
  CONTACT_COMPANY_PAGINATION_SUCCESS: "CONTACT_COMPANY_PAGINATION_SUCCESS",

  //for Update of Pagination
  CONTACT_COMPANY_PAGINATION_UPDATE: "CONTACT_COMPANY_PAGINATION_UPDATE",

  CONTACT_COMPANY_PAGINATION_CHANGE: "CONTACT_COMPANY_PAGINATION_CHANGE",
  CONTACT_COMPANY_PAGINATION_CHANGE_SUCCESS:
    "CONTACT_COMPANY_PAGINATION_CHANGE_SUCCESS",

  // contact person pagination
  CONTACT_PERSON_PAGINATION: "CONTACT_PERSON_PAGINATION",
  CONTACT_PERSON_PAGINATION_INIT: "CONTACT_PERSON_PAGINATION_INIT",
  CONTACT_PERSON_PAGINATION_FINISH: "CONTACT_PERSON_PAGINATION_FINISH",
  CONTACT_PERSON_PAGINATION_SUCCESS: "CONTACT_PERSON_PAGINATION_SUCCESS",

  //contact expert pagination
  EXPERT_PERSON_PAGINATION: "EXPERT_PERSON_PAGINATION",
  EXPERT_PERSON_PAGINATION_INIT: "EXPERT_PERSON_PAGINATION_INIT",
  EXPERT_PERSON_PAGINATION_SUCCESS: "EXPERT_PERSON_PAGINATION_SUCCESS",
  EXPERT_PERSON_PAGINATION_FINISH: "EXPERT_PERSON_PAGINATION_FINISH",
  EXPERT_PERSON_PAGINATION_CHANGE: "EXPERT_PERSON_PAGINATION_CHANGE",
  EXPERT_PERSON_PAGINATION_CHANGE_SUCCESS:
    "EXPERT_PERSON_PAGINATION_CHANGE_SUCCESS",
  //isCompanyFav

  CONTACT_COMPANY_IS_FAV: "CONTACT_COMPANY_IS_FAV",
  CONTACT_COMPANY_IS_FAV_INIT: "CONTACT_COMPANY_IS_FAV_INIT",
  CONTACT_COMPANY_IS_FAV_FINISH: "CONTACT_COMPANY_IS_FAV_FINISH",
  CONTACT_COMPANY_IS_FAV_SUCCESS: "CONTACT_COMPANY_IS_FAV_SUCCESS",

  //isContactPersonFav

  CONTACT_PERSON_IS_FAV: "CONTACT_PERSON_IS_FAV",
  CONTACT_PERSON_IS_FAV_INIT: "CONTACT_PERSON_IS_FAV_INIT",
  CONTACT_PERSON_IS_FAV_FINISH: "CONTACT_PERSON_IS_FAV_FINISH",
  CONTACT_PERSON_IS_FAV_SUCCESS: "CONTACT_PERSON_IS_FAV_SUCCESS",

  CONTACT_PERSON_PAGINATION_CHANGE: "CONTACT_PERSON_PAGINATION_CHANGE",
  CONTACT_PERSON_PAGINATION_CHANGE_SUCCESS:
    "CONTACT_PERSON_PAGINATION_CHANGE_SUCCESS",

  contactCompanyDetailAdd: "contactCompanyDetailAdd",
  contactCompanyDetailAdd_INIT: "contactCompanyDetailAdd_INIT",
  contactCompanyDetailAdd_FINISH: "contactCompanyDetailAdd_FINISH",
  contactCompanyDetailAdd_SUCCESS: "contactCompanyDetailAdd_SUCCESS",

  contactCompanyUpdate: "contactCompanyUpdate",
  contactCompanyUpdate_INIT: "contactCompanyUpdate_INIT",
  contactCompanyUpdate_FINISH: "contactCompanyUpdate_FINISH",
  contactCompanyUpdate_SUCCESS: "contactCompanyUpdate_SUCCESS",

  quickCompanyAdd: "quickCompanyAdd",
  quickCompanyAdd_INIT: "quickCompanyAdd_INIT",
  quickCompanyAdd_FINISH: "quickCompanyAdd_FINISH",
  quickCompanyAdd_SUCCESS: "quickCompanyAdd_SUCCESS",

  contactPersonUpdate: "contactPersonUpdate",
  contactPersonUpdate_INIT: "contactPersonUpdate_INIT",
  contactPersonUpdate_FINISH: "contactPersonUpdate_FINISH",
  contactPersonUpdate_SUCCESS: "contactPersonUpdate_SUCCESS",

  contactPersonAdd: "contactPersonAdd",
  contactPersonAdd_INIT: "contactPersonAdd_INIT",
  contactPersonAdd_FINISH: "contactPersonAdd_FINISH",
  contactPersonAdd_SUCCESS: "contactPersonAdd_SUCCESS",

  // QUICK PERSON ADD
  QUICK_PERSON_ADD: "QUICK_PERSON_ADD",
  QUICK_PERSON_ADD_INIT: "QUICK_PERSON_ADD_INIT",
  QUICK_PERSON_ADD_FINISH: "QUICK_PERSON_ADD_FINISH",
  QUICK_PERSON_ADD_SUCCESS: "QUICK_PERSON_ADD_SUCCESS",

  expertPersonAdd: "expertPersonAdd",
  expertPersonAdd_INIT: "expertPersonAdd_INIT",
  expertPersonAdd_FINISH: "expertPersonAdd_FINISH",
  expertPersonAdd_SUCCESS: "expertPersonAdd_SUCCESS",

  contactCompanyGetDetails: "contactCompanyGetDetails",
  contactCompanyGetDetails_INIT: "contactCompanyGetDetails_INIT",
  contactCompanyGetDetails_FINISH: "contactCompanyGetDetails_FINISH",
  contactCompanyGetDetails_SUCCESS: "contactCompanyGetDetails_SUCCESS",
  contactPersonGetDetails: "contactPersonGetDetails",
  contactPersonGetDetails_INIT: "contactPersonGetDetails_INIT",
  contactPersonGetDetails_FINISH: "contactPersonGetDetails_FINISH",
  contactPersonGetDetails_SUCCESS: "contactPersonGetDetails_SUCCESS",

  EXPERT_PERSON_GETDETAIL: "EXPERT_PERSON_GETDETAIL",
  EXPERT_PERSON_GETDETAIL_INIT: "EXPERT_PERSON_GETDETAIL",
  EXPERT_PERSON_GETDETAIL_SUCCESS: "EXPERT_PERSON_GETDETAIL_SUCCESS",
  EXPERT_PERSON_GETDETAIL_FINISH: "EXPERT_PERSON_GETDETAIL_FINISH",

  SELECTED_CONTACT_TYPE: "SELECTED_CONTACT_TYPE",

  contactCompanyNoteUpdate: "contactCompanyNoteUpdate",
  contactCompanyNoteUpdate_INIT: "contactCompanyNoteUpdate_INIT",
  contactCompanyNoteUpdate_FINISH: "contactCompanyNoteUpdate_FINISH",
  contactCompanyNoteUpdate_SUCCESS: "contactCompanyNoteUpdate_SUCCESS",

  contactPersonNoteUpdate: "contactPersonNoteUpdate",
  contactPersonNoteUpdate_INIT: "contactPersonNoteUpdate_INIT",
  contactPersonNoteUpdate_FINISH: "contactPersonNoteUpdate_FINISH",
  contactPersonNoteUpdate_SUCCESS: "contactPersonNoteUpdate_SUCCESS",

  //Contact 	Quick Email Add
  CONTACT_QUICK_EMAIL: "CONTACT_QUICK_EMAIL",
  CONTACT_QUICK_EMAIL_INIT: "CONTACT_QUICK_EMAIL_INIT",
  CONTACT_QUICK_EMAIL_FINISH: "CONTACT_QUICK_EMAIL_FINISH",
  CONTACT_QUICK_EMAIL_SUCCESS: "CONTACT_QUICK_EMAIL_SUCCESS",

  CONTACT_UPDATE: "CONTACT_UPDATE",
  CONTACT_UPDATE_INIT: "CONTACT_UPDATE_INIT",
  CONTACT_UPDATE_SUCCESS: "CONTACT_UPDATE_SUCCESS",
  CONTACT_UPDATE_FINISH: "CONTACT_UPDATE_FINISH",

  // company Inactive Status
  CONTACT_COMPANY_ACTIVE_STATUS: "CONTACT_COMPANY_ACTIVE_STATUS",
  CONTACT_COMPANY_ACTIVE_STATUS_INIT: "CONTACT_COMPANY_ACTIVE_STATUS_INIT",
  CONTACT_COMPANY_ACTIVE_STATUS_FINISH: "CONTACT_COMPANY_ACTIVE_STATUS_FINISH",
  CONTACT_COMPANY_ACTIVE_STATUS_SUCCESS:
    "CONTACT_COMPANY_ACTIVE_STATUS_SUCCESS",

  // person Inactive Status
  CONTACT_PERSON_ACTIVE_STATUS: "CONTACT_PERSON_ACTIVE_STATUS",
  CONTACT_PERSON_ACTIVE_STATUS_INIT: "CONTACT_PERSON_ACTIVE_STATUS_INIT",
  CONTACT_PERSON_ACTIVE_STATUS_FINISH: "CONTACT_PERSON_ACTIVE_STATUS_FINISH",
  CONTACT_PERSON_ACTIVE_STATUS_SUCCESS: "CONTACT_PERSON_ACTIVE_STATUS_SUCCESS",
};

// organizer
export const ORGANIZER_TYPES = {
  // Fiscal Years
  FISCAL_YEARS: "FISCAL_YEARS",
  FISCAL_YEARS_INIT: "FISCAL_YEARS_INIT",
  FISCAL_YEARS_FINISH: "FISCAL_YEARS_FINISH",
  FISCAL_YEARS_SUCCESS: "FISCAL_YEARS_SUCCESS",

  // Holiday Events
  HOLIDAY_EVENTS: "HOLIDAY_EVENTS",
  HOLIDAY_EVENTS_INIT: "HOLIDAY_EVENTS_INIT",
  HOLIDAY_EVENTS_FINISH: "HOLIDAY_EVENTS_FINISH",
  HOLIDAY_EVENTS_SUCCESS: "HOLIDAY_EVENTS_SUCCESS",

  // Holiday Events
  BULK_HOLIDAY_EVENTS: "BULK_HOLIDAY_EVENTS",
  BULK_HOLIDAY_EVENTS_INIT: "BULK_HOLIDAY_EVENTS_INIT",
  BULK_HOLIDAY_EVENTS_FINISH: "BULK_HOLIDAY_EVENTS_FINISH",
  BULK_HOLIDAY_EVENTS_SUCCESS: "BULK_HOLIDAY_EVENTS_SUCCESS",

  // Guest Events
  GUEST_EVENTS: "GUEST_EVENTS",
  GUEST_EVENTS_INIT: "GUEST_EVENTS_INIT",
  GUEST_EVENTS_FINISH: "GUEST_EVENTS_FINISH",
  GUEST_EVENTS_SUCCESS: "GUEST_EVENTS_SUCCESS",

  //guests events all applied listing
  GUEST_EVENTS_LISTING: "GUEST_EVENTS_LISTING",
  GUEST_EVENTS_LISTING_INIT: "GUEST_EVENTS_LISTING_INIT",
  GUEST_EVENTS_LISTING_FINISH: "GUEST_EVENTS_LISTING_FINISH",
  GUEST_EVENTS_LISTING_SUCCESS: "GUEST_EVENTS_LISTING_SUCCESS",

  //guests events approval listing
  GUEST_EVENTS_APPROVAL_LISTING: "GUEST_EVENTS_APPROVAL_LISTING",
  GUEST_EVENTS_APPROVAL_LISTING_INIT: "GUEST_EVENTS_APPROVAL_LISTING_INIT",
  GUEST_EVENTS_APPROVAL_LISTING_FINISH: "GUEST_EVENTS_APPROVAL_LISTING_FINISH",
  GUEST_EVENTS_APPROVAL_LISTING_SUCCESS:
    "GUEST_EVENTS_APPROVAL_LISTING_SUCCESS",

  GUEST_EVENTS_STATUSES: "GUEST_EVENTS_STATUSES",
  GUEST_EVENTS_STATUSES_INIT: "GUEST_EVENTS_STATUSES_INIT",
  GUEST_EVENTS_STATUSES_FINISH: "GUEST_EVENTS_STATUSES_FINISH",
  GUEST_EVENTS_STATUSES_SUCCESS: "GUEST_EVENTS_STATUSES_SUCCESS",

  ADD_GUEST_EVENTS: "ADD_GUEST_EVENTS",
  ADD_GUEST_EVENTS_INIT: "ADD_GUEST_EVENTS_INIT",
  ADD_GUEST_EVENTS_FINISH: "ADD_GUEST_EVENTS_FINISH",
  ADD_GUEST_EVENTS_SUCCESS: "ADD_GUEST_EVENTS_SUCCESS",

  // Goal Events
  GOAL_EVENT_ADD: "GOAL_EVENT_ADD",
  GOAL_EVENT_ADD_INIT: "GOAL_EVENT_ADD_INIT",
  GOAL_EVENT_ADD_FINISH: "GOAL_EVENT_ADD_FINISH",
  GOAL_EVENT_ADD_SUCCESS: "GOAL_EVENT_ADD_SUCCESS",

  // GOAL EVENTS LISTING
  GOAL_EVENTS: "GOAL_EVENTS",
  GOAL_EVENTS_INIT: "GOAL_EVENTS_INIT",
  GOAL_EVENTS_FINISH: "GOAL_EVENTS_FINISH",
  GOAL_EVENTS_SUCCESS: "GOAL_EVENTS_SUCCESS",
  GOAL_EVENTS_PAGINATION: "GOAL_EVENTS_PAGINATION",
  GOAL_EVENTS_PAGINATION_SUCCESS: "GOAL_EVENTS_PAGINATION_SUCCESS",

  // organizer task event  add
  ORGANIZER_TASK_EVENT_ADD: "ORGANIZER_TASK_EVENT_ADD",
  ORGANIZER_TASK_EVENT_ADD_INIT: "ORGANIZER_TASK_EVENT_ADD_INIT",
  ORGANIZER_TASK_EVENT_ADD_FINISH: "ORGANIZER_TASK_EVENT_ADD_FINISH",
  ORGANIZER_TASK_EVENT_ADD_SUCCESS: "ORGANIZER_TASK_EVENT_ADD_SUCCESS",
  // organizer task event get details
  ORGANIZER_TASK_EVENT_GET_DETAILS: "ORGANIZER_TASK_EVENT_GET_DETAILS",
  ORGANIZER_TASK_EVENT_GET_DETAILS_INIT:
    "ORGANIZER_TASK_EVENT_GET_DETAILS_INIT",
  ORGANIZER_TASK_EVENT_GET_DETAILS_FINISH:
    "ORGANIZER_TASK_EVENT_GET_DETAILS_FINISH",
  ORGANIZER_TASK_EVENT_GET_DETAILS_SUCCESS:
    "ORGANIZER_TASK_EVENT_GET_DETAILS_SUCCESS",

  // organizer task event  update
  ORGANIZER_TASK_EVENT_UPDATE: "ORGANIZER_TASK_EVENT_UPDATE",
  ORGANIZER_TASK_EVENT_UPDATE_INIT: "ORGANIZER_TASK_EVENT_UPDATE_INIT",
  ORGANIZER_TASK_EVENT_UPDATE_FINISH: "ORGANIZER_TASK_EVENT_UPDATE_FINISH",
  ORGANIZER_TASK_EVENT_UPDATE_SUCCESS: "ORGANIZER_TASK_EVENT_UPDATE_SUCCESS",

  // event label
  LABELS_EVENTS: "LABELS_EVENTS",
  LABELS_EVENTS_INIT: "LABELS_EVENTS_INIT",
  LABELS_EVENTS_FINISH: "LABELS_EVENTS_FINISH",
  LABELS_EVENTS_SUCCESS: "LABELS_EVENTS_SUCCESS",

  LABELS_EVENTS_LISTING: "LABELS_EVENTS_LISTING",
  LABELS_EVENTS_LISTING_INIT: "LABELS_EVENTS_LISTING_INIT",
  LABELS_EVENTS_LISTING_FINISH: "LABELS_EVENTS_LISTING_FINISH",
  LABELS_EVENTS_LISTING_SUCCESS: "LABELS_EVENTS_LISTING_SUCCESS",

  PRIORITY_EVENTS_LISTING: "PRIORITY_EVENTS_LISTING",
  PRIORITY_EVENTS_LISTING_INIT: "PRIORITY_EVENTS_LISTING_INIT",
  PRIORITY_EVENTS_LISTING_FINISH: "PRIORITY_EVENTS_LISTING_FINISH",
  PRIORITY_EVENTS_LISTING_SUCCESS: "PRIORITY_EVENTS_LISTING_SUCCESS",

  // Appointments
  APPOINTMENTS: "APPOINTMENTS",
  APPOINTMENTS_INIT: "APPOINTMENTS_INIT",
  APPOINTMENTS_FINISH: "APPOINTMENTS_FINISH",
  APPOINTMENTS_SUCCESS: "APPOINTMENTS_SUCCESS",

  ADD_APPOINTMENT: "ADD_APPOINTMENT",
  ADD_APPOINTMENT_INIT: "ADD_APPOINTMENT_INIT",
  ADD_APPOINTMENT_FINISH: "ADD_APPOINTMENT_FINISH",
  ADD_APPOINTMENT_SUCCESS: "ADD_APPOINTMENT_SUCCESS",

  APPOINTMENT_COMPLETE: "APPOINTMENT_COMPLETE",
  APPOINTMENT_COMPLETE_INIT: "APPOINTMENT_COMPLETE_INIT",
  APPOINTMENT_COMPLETE_FINISH: "APPOINTMENT_COMPLETE_FINISH",
  APPOINTMENT_COMPLETE_SUCCESS: "APPOINTMENT_COMPLETE_SUCCESS",

  organizerEventLabelAdd: "organizerEventLabelAdd",
  organizerEventLabelAdd_INIT: "organizerEventLabelAdd_INIT",
  organizerEventLabelAdd_FINISH: "organizerEventLabelAdd_FINISH",
  organizerEventLabelAdd_SUCCESS: "organizerEventLabelAdd_SUCCESS",

  organizerEventLabelUpdate: "organizerEventLabelUpdate",
  organizerEventLabelUpdate_INIT: "organizerEventLabelUpdate_INIT",
  organizerEventLabelUpdate_FINISH: "organizerEventLabelUpdate_FINISH",
  organizerEventLabelUpdate_SUCCESS: "organizerEventLabelUpdate_SUCCESS",

  organizerEventPriorityPagination: "organizerEventPriorityPagination",
  organizerEventPriorityPagination_INIT:
    "organizerEventPriorityPagination_INIT",
  organizerEventPriorityPagination_FINISH:
    "organizerEventPriorityPagination_FINISH",
  organizerEventPriorityPagination_SUCCESS:
    "organizerEventPriorityPagination_SUCCESS",

  organizerEventPriorityAdd: "organizerEventPriorityAdd",
  organizerEventPriorityAdd_INIT: "organizerEventPriorityAdd_INIT",
  organizerEventPriorityAdd_FINISH: "organizerEventPriorityAdd_FINISH",
  organizerEventPriorityAdd_SUCCESS: "organizerEventPriorityAdd_SUCCESS",

  organizerEventPriorityUpdate: "organizerEventPriorityUpdate",
  organizerEventPriorityUpdate_INIT: "organizerEventPriorityUpdate_INIT",
  organizerEventPriorityUpdate_FINISH: "organizerEventPriorityUpdate_FINISH",
  organizerEventPriorityUpdate_SUCCESS: "organizerEventPriorityUpdate_SUCCESS",

  GET_TODAY_TASKS: "GET_TODAY_TASKS",
  GET_TODAY_TASKS_INIT: "GET_TODAY_TASKS_INIT",
  GET_TODAY_TASKS_FINISH: "GET_TODAY_TASKS_FINISH",
  GET_TODAY_TASKS_SUCCESS: "GET_TODAY_TASKS_SUCCESS",
};
export const APPLICATION_TEMPLATE = {
  //add application template
  ADD_APPLICATION_TEMPLATE: "ADD_APPLICATION_TEMPLATE",
  ADD_APPLICATION_TEMPLATE_INIT: "ADD_APPLICATION_TEMPLATE_INIT",
  ADD_APPLICATION_TEMPLATE_SUCCESS: "ADD_APPLICATION_TEMPLATE_SUCCESS",

  //get application template
  GET_APPLICATION_TEMPLATE: "GET_APPLICATION_TEMPLATE",
  GET_APPLICATION_TEMPLATE_INIT: "GET_APPLICATION_TEMPLATE_INIT",
  GET_APPLICATION_TEMPLATE_SUCCESS: "GET_APPLICATION_TEMPLATE_SUCCESS",

  //update application template
  UPDATE_APPLICATION_TEMPLATE: "UPDATE_APPLICATION_TEMPLATE",
  UPDATE_APPLICATION_TEMPLATE_INIT: "UPDATE_APPLICATION_TEMPLATE_INIT",
  UPDATE_APPLICATION_TEMPLATE_SUCCESS: "UPDATE_APPLICATION_TEMPLATE_SUCCESS",
};

export const TODO_TYPES = {
  // STATUS LISTING
  STATUS_LISTING: "STATUS_LISTING",
  STATUS_LISTING_INIT: "STATUS_LISTING_INIT",
  STATUS_LISTING_FINISH: "STATUS_LISTING_FINISH",
  STATUS_LISTING_SUCCESS: "STATUS_LISTING_SUCCESS",

  //STATUS Event LISTING UPDATE
  UPDATE_STATUS_LISTING: "ADD_APPOINTMENT",
  UPDATE_STATUS_LISTING_INIT: "ADD_APPOINTMENT_INIT",
  UPDATE_STATUS_LISTING_FINISH: "ADD_APPOINTMENT_FINISH",
  UPDATE_STATUS_LISTING_SUCCESS: "ADD_APPOINTMENT_SUCCESS",

  // ToDo Events
  TODO_LISTS: "TODO_LISTS",
  TODO_LISTS_INIT: "TODO_LISTS_INIT",
  TODO_LISTS_FINISH: "TODO_LISTS_FINISH",
  TODO_LISTS_SUCCESS: "TODO_LISTS_SUCCESS",
  //
  TODO_LISTS_UPDATE: "TODO_LISTS_UPDATE",
  TODO_LISTS_STATUS: "TODO_LISTS_STATUS",

  //Private Task Listing
  PRIVATE_LISTS: "PRIVATE_LISTS",
  PRIVATE_LISTS_INIT: "PRIVATE_LISTS_INIT",
  PRIVATE_LISTS_FINISH: "PRIVATE_LISTS_FINISH",
  PRIVATE_LISTS_SUCCESS: "PRIVATE_LISTS_SUCCESS",

  //ToDo Event Completed Date
  TODO_LISTS_TODAY: "TODO_LISTS_TODAY",
  TODO_LISTS_TODAY_INIT: "TODO_LISTS_TODAY_INIT",
  TODO_LISTS_TODAY_FINISH: "TODO_LISTS_TODAY_FINISH",
  TODO_LISTS_TODAY_SUCCESS: "TODO_LISTS_TODAY_SUCCESS",

  //todo events for kanban
  TODO_LISTS_KANBAN: "TODO_LISTS_KANBAN",
  TODO_LISTS_KANBAN_SUCCESS: "TODO_LISTS_KANBAN_SUCCESS",
  TODO_LISTS_KANBAN_INIT: "TODO_LISTS_KANBAN_INIT",
  TODO_LISTS_KANBAN_FINISH: "TODO_LISTS_KANBAN_FINISH",

  //todo kanban
  TODO_KANBAN: "TODO_KANBAN",
  TODO_KANBAN_INIT: "TODO_KANBAN_INIT",
  TODO_KANBAN_SUCCESS: "TODO_KANBAN_SUCCESS",

  //kanban drag
  TODO_KANBAN_DRAG: "TODO_KANBAN_DRAG",
  TODO_KANBAN_DRAG_INIT: "TODO_KANBAN_DRAG_INIT",
  TODO_KANBAN_DRAG_SUCCESS: "TODO_KANBAN_DRAG_SUCCESS",
  // for todolist pagination
  TOD0_LIST_CHANGE: "TODO_LIST_CHANGE",
  TODO_LIST_CHANGE_SUCCESS: "TODO_LIST_CHANGE_SUCCESS",

  //for doday complete pagination
  TODO_LIST_TODAY: "TODO_LIST_TODAY",
  TODO_LIST_TODAY_SUCCESS: "TODO_LIST_TODAY_SUCCESS",

  //For Task Counter
  TASK_COUNTER: "TASK_COUNTER",
  TASK_COUNTER_INIT: "TASK_COUNTER_INIT",
  TASK_COUNTER_FINISH: "TASK_COUNTER_FINISH",
  TASK_COUNTER_SUCCESS: "TASK_COUNTER_SUCCESS",

  // ToDo Events
  ADD_TODO_LISTS: "ADD_TODO_LISTS",
  ADD_TODO_LISTS_INIT: "ADD_TODO_LISTS_INIT",
  ADD_TODO_LISTS_FINISH: "ADD_TODO_LISTS_FINISH",
  ADD_TODO_LISTS_SUCCESS: "ADD_TODO_LISTS_SUCCESS",

  //Private Task
  ADD_PRIVATE_LISTS: "ADD_PRIVATE_LISTS",
  ADD_PRIVATE_LISTS_INIT: "ADD_PRIVATE_LISTS_INIT",
  ADD_PRIVATE_LISTS_FINISH: "ADD_PRIVATE_LISTS_FINISH",
  ADD_PRIVATE_LISTS_SUCCESS: "ADD_PRIVATE_LISTS_SUCCESS",

  TODO_COMPLETE: "TODO_COMPLETE",
  TODO_COMPLETE_INIT: "TODO_COMPLETE_INIT",
  TODO_COMPLETE_FINISH: "TODO_COMPLETE_FINISH",
  TODO_COMPLETE_SUCCESS: "TODO_COMPLETE_SUCCESS",

  TODO_ISFLAGGED: "TODO_ISFLAGGED",
  TODO_ISFLAGGED_INIT: "TODO_ISFLAGGED_INIT",
  TODO_ISFLAGGED_FINISH: "TODO_ISFLAGGED_FINISH",
  TODO_ISFLAGGED_SUCCESS: "TODO_ISFLAGGED_SUCCESS",

  TODO_GET_DETAILS: "TODO_GET_DETAILS",
  TODO_GET_DETAILS_INIT: "TODO_GET_DETAILS_INIT",
  TODO_GET_DETAILS_FINISH: "TODO_GET_DETAILS_FINISH",
  TODO_GET_DETAILS_SUCCESS: "TODO_GET_DETAILS_SUCCESS",

  UPDATE_TODO_EVENT: "UPDATE_TODO_EVENT",
  UPDATE_TODO_EVENT_INIT: "UPDATE_TODO_EVENT_INIT",
  UPDATE_TODO_EVENT_FINISH: "UPDATE_TODO_EVENT_FINISH",
  UPDATE_TODO_EVENT_SUCCESS: "UPDATE_TODO_EVENT_SUCCESS",

  ALL_TODAY_TASKS: "ALL_TODAY_TASKS",
  ALL_TODAY_TASKS_INIT: "ALL_TODAY_TASKS_INIT",
  ALL_TODAY_TASKS_FINISH: "ALL_TODAY_TASKS_FINISH",
  ALL_TODAY_TASKS_SUCCESS: "ALL_TODAY_TASKS_SUCCESS",

  //used References of task
  ALL_USED_REFERENCE: "ALL_USED_REFERENCE",
  ALL_USED_REFERENCE_INIT: "ALL_USED_REFERENCE_INIT",
  ALL_USED_REFERENCE_FINISH: "ALL_USED_REFERENCE_FINISH",
  ALL_USED_REFERENCE_SUCCESS: "ALL_USED_REFERENCE_SUCCESS",

  AGENDAS_LIST: "AGENDAS_LIST",
  AGENDAS_LIST_INIT: "AGENDAS_LIST_INIT",
  AGENDAS_LIST_SUCCESS: "AGENDAS_LIST_SUCCESS",
  AGENDAS_LIST_FINISH: "AGENDAS_LIST_FINISH",
};

export const REQUISITION_TYPES = {
  //Leave Application Pagination
  LEAVE_APPLICATION_PAGINATION: "LEAVE_APPLICATION_PAGINATION",
  LEAVE_APPLICATION_PAGINATION_INIT: "LEAVE_APPLICATION_PAGINATION_INIT",
  LEAVE_APPLICATION_PAGINATION_FINISH: "LEAVE_APPLICATION_PAGINATION_FINISH",
  LEAVE_APPLICATION_PAGINATION_SUCCESS: "LEAVE_APPLICATION_PAGINATION_SUCCESS",
  LEAVE_APPLICATION_PAGINATON_CHANGE: "LEAVE_APPLICATION_PAGINATON_CHANGE",

  //leave application approval
  LEAVE_APPLICATION_APPROVAL_PAGINATION:
    "LEAVE_APPLICATION_APPROVAL_PAGINATION",
  LEAVE_APPLICATION_APPROVAL_PAGINATION_INIT:
    "LEAVE_APPLICATION_APPROVAL_PAGINATION_INIT",
  LEAVE_APPLICATION_APPROVAL_PAGINATION_FINISH:
    "LEAVE_APPLICATION_APPROVAL_PAGINATION_FINISH",
  LEAVE_APPLICATION_APPROVAL_PAGINATION_SUCCESS:
    "LEAVE_APPLICATION_APPROVAL_PAGINATION_SUCCESS",

  LEAVE_APPLICATION_PAGINATON_CHANGE_SUCCESS:
    "LEAVE_APPLICATION_PAGINATON_CHANGE_SUCCESS",
  //Leave Application Listing
  LEAVE_APPLICATION_LISTING: "LEAVE_APPLICATION_LISTING",
  LEAVE_APPLICATION_LISTING_INT: "LEAVE_APPLICATION_LISTING_INT",
  LEAVE_APPLICATION_LISTING_FINISH: "LEAVE_APPLICATION_LISTING_FINISH",
  LEAVE_APPLICATION_LISTING_SUCCESS: "LEAVE_APPLICATION_LISTING_SUCCESS",

  //Leave Remaining User
  LEAVE_REMAINING_FOR_USER: "LEAVE_REMAINING_FOR_USER",
  LEAVE_REMAINING_FOR_USER_INT: "LEAVE_REMAINING_FOR_USER_INT",
  LEAVE_REMAINING_FOR_USER_FINISH: "LEAVE_REMAINING_FOR_USER_FINISH",
  LEAVE_REMAINING_FOR_USER_SUCCESS: "LEAVE_REMAINING_FOR_USER_SUCCESS",

  //Leave Type Listing
  LEAVE_TYPE_LISTING: "LEAVE_TYPE_LISTING",
  LEAVE_TYPE_LISTING_INT: "LEAVE_TYPE_LISTING_INT",
  LEAVE_TYPE_LISTING_FINISH: "LEAVE_TYPE_LISTING_FINISH",
  LEAVE_TYPE_LISTING_SUCCESS: "LEAVE_TYPE_LISTING_SUCCESS",

  REQUISITION_APP_STATUS_LISTING: "REQUISITION_APP_STATUS_LISTING",
  REQUISITION_APP_STATUS_LISTING_INT: "REQUISITION_APP_STATUS_LISTING_INT",
  REQUISITION_APP_STATUS_LISTING_FINISH:
    "REQUISITION_APP_STATUS_LISTING_FINISH",
  REQUISITION_APP_STATUS_LISTING_SUCCESS:
    "REQUISITION_APP_STATUS_LISTING_SUCCESS",

  LEAVE_APP_DETAILS_WITH_HISTORY: "LEAVE_APP_DETAILS_WITH_HISTORY",
  LEAVE_APP_DETAILS_WITH_HISTORY_INT: "LEAVE_APP_DETAILS_WITH_HISTORY_INT",
  LEAVE_APP_DETAILS_WITH_HISTORY_FINISH:
    "LEAVE_APP_DETAILS_WITH_HISTORY_FINISH",
  LEAVE_APP_DETAILS_WITH_HISTORY_SUCCESS:
    "LEAVE_APP_DETAILS_WITH_HISTORY_SUCCESS",

  LEAVE_APPLICATION_GET_DETAILS: "LEAVE_APPLICATION_GET_DETAILS",
  LEAVE_APPLICATION_GET_DETAILS_INT: "LEAVE_APPLICATION_GET_DETAILS_INT",
  LEAVE_APPLICATION_GET_DETAILS_FINISH: "LEAVE_APPLICATION_GET_DETAILS_FINISH",
  LEAVE_APPLICATION_GET_DETAILS_SUCCESS:
    "LEAVE_APPLICATION_GET_DETAILS_SUCCESS",

  LEAVE_APPLICATION_APPROVE_REJECT: "LEAVE_APPLICATION_APPROVE_REJECT",
  LEAVE_APPLICATION_APPROVE_REJECT_INT: "LEAVE_APPLICATION_APPROVE_REJECT_INT",
  LEAVE_APPLICATION_APPROVE_REJECT_FINISH:
    "LEAVE_APPLICATION_APPROVE_REJECT_FINISH",
  LEAVE_APPLICATION_APPROVE_REJECT_SUCCESS:
    "LEAVE_APPLICATION_APPROVE_REJECT_SUCCESS",
  //Supervisor details
  SUPERVISOR_DETAILS: "SUPERVISOR_DETAILS",
  SUPERVISOR_DETAILS_INT: "SUPERVISOR_DETAILS_INT",
  SUPERVISOR_DETAILS_FINISH: "SUPERVISOR_DETAILS_FINISH",
  SUPERVISOR_DETAILS_SUCCESS: "SUPERVISOR_DETAILS_SUCCESS",

  //Forward Leave Application
  ADD_LEAVE_APPLICATION: "ADD_LEAVE_APPLICATION",
  ADD_LEAVE_APPLICATION_INIT: "ADD_LEAVE_APPLICATION_INIT",
  ADD_LEAVE_APPLICATION_FINISH: "ADD_LEAVE_APPLICATION_FINISH",
  ADD_LEAVE_APPLICATION_SUCCESS: "ADD_LEAVE_APPLICATION_SUCCESS",

  REMAINING_LEAVE_APPLICATION_TYPES: "REMAINING_LEAVE_APPLICATION_TYPES",
  REMAINING_LEAVE_APPLICATION_TYPES_INT:
    "REMAINING_LEAVE_APPLICATION_TYPES_INT",
  REMAINING_LEAVE_APPLICATION_TYPES_FINISH:
    "REMAINING_LEAVE_APPLICATION_TYPES_FINISH",
  REMAINING_LEAVE_APPLICATION_TYPES_SUCCESS:
    "REMAINING_LEAVE_APPLICATION_TYPES_SUCCESS",

  LEAVE_DETAILS: "LEAVE_DETAILS",
  LEAVE_DETAILS_INIT: "LEAVE_DETAILS_INIT",
  LEAVE_DETAILS_FINISH: "LEAVE_DETAILS_FINISH",
  LEAVE_DETAILS_SUCCESS: "LEAVE_DETAILS_SUCCESS",

  LEAVE_APPLICATION_UPDATE_MANAGEMENT: "LEAVE_APPLICATION_UPDATE_MANAGEMENT",
  LEAVE_APPLICATION_UPDATE_MANAGEMENT_INIT:
    "LEAVE_APPLICATION_UPDATE_MANAGEMENT_INIT",
  LEAVE_APPLICATION_UPDATE_MANAGEMENT_FINISH:
    "LEAVE_APPLICATION_UPDATE_MANAGEMENT_FINISH",
  LEAVE_APPLICATION_UPDATE_MANAGEMENT_SUCCESS:
    "LEAVE_APPLICATION_UPDATE_MANAGEMENT_SUCCESS",

  //Guest Entertainment add
  GUEST_ENTERTAINMENT_APPLY: "GUEST_ENTERTAINMENT_APPLY",
  GUEST_ENTERTAINMENT_APPLY_INIT: "GUEST_ENTERTAINMENT_APPLY_INIT",
  GUEST_ENTERTAINMENT_APPLY_SUCCESS: "GUEST_ENTERTAINMENT_APPLY_SUCCESS",
  GUEST_ENTERTAINMENT_APPLY_FINISH: "GUEST_ENTERTAINMENT_APPLY_FINISH",

  //guest entertainment amount details
  AMOUNT_RESPONSE: "AMOUNT_RESPONSE",
  AMOUNT_RESPONSE_INIT: "AMOUNT_RESPONSE_INIT",
  AMOUNT_RESPONSE_SUCCESS: "AMOUNT_RESPONSE_SUCCESS",
  AMOUNT_RESPONSE_FINISH: "AMOUNT_RESPONSE_FINISH",

  //guestInfoDetails
  GUEST_ENTERTAIMENT_DETAIL: "GUEST_ENTERTAIMENT_DETAIL",
  GUEST_ENTERTAIMENT_DETAIL_INIT: "GUEST_ENTERTAIMENT_DETAIL_INIT",
  GUEST_ENTERTAIMENT_DETAIL_SUCCESS: "GUEST_ENTERTAIMENT_DETAIL_SUCCESS",
  GUEST_ENTERTAIMENT_DETAIL_FINISH: "GUEST_ENTERTAIMENT_DETAIL_FINISH",

  //guest Info supervisor action
  GUEST_ENTERTAIMENT_SUPERVISOR_ACTION: "GUEST_ENTERTAIMENT_SUPERVISOR_ACTION",
  GUEST_ENTERTAIMENT_SUPERVISOR_ACTION_INIT:
    "GUEST_ENTERTAIMENT_SUPERVISOR_ACTION_INIT",
  GUEST_ENTERTAIMENT_SUPERVISOR_ACTION_SUCCESS:
    "GUEST_ENTERTAIMENT_SUPERVISOR_ACTION_SUCCESS",
  GUEST_ENTERTAIMENT_SUPERVISOR_ACTION_FINISH:
    "GUEST_ENTERTAIMENT_SUPERVISOR_ACTION_FINISH",

  //guest entertainment managament action
  GUEST_ENTERTAIMENT_MANAGEMENT_ACTION: "GUEST_ENTERTAIMENT_MANAGEMENT_ACTION",
  GUEST_ENTERTAIMENT_MANAGEMENT_ACTION_INIT:
    "GUEST_ENTERTAIMENT_MANAGEMENT_ACTION_INIT",
  GUEST_ENTERTAIMENT_MANAGEMENT_ACTION_SUCCESS:
    "GUEST_ENTERTAIMENT_MANAGEMENT_ACTION_SUCCESS",
  GUEST_ENTERTAIMENT_MANAGEMENT_ACTION_FINISH:
    "GUEST_ENTERTAIMENT_MANAGEMENT_ACTION_FINISH",

  //guest entertainment accountant action
  GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION: "GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION",
  GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION_INIT:
    "GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION_INIT",
  GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION_SUCCESS:
    "GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION_SUCCESS",
  GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION_FINISH:
    "GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION_FINISH",

  LEAVE_APPLICATION_CANCEL: "LEAVE_APPLICATION_CANCEL",
  LEAVE_APPLICATION_CANCEL_INIT: "LEAVE_APPLICATION_CANCEL_INIT",
  LEAVE_APPLICATION_CANCEL_SUCCESS: "LEAVE_APPLICATION_CANCEL_SUCCESS",
  LEAVE_APPLICATION_CANCEL_FINISH: "LEAVE_APPLICATION_CANCEL_FINISH",
};
export const PROCUREMENT_TYPES = {
  //add procurement
  PROCUREMENT_ADD: "PROCUREMENT_ADD",
  PROCUREMENT_ADD_INIT: "PROCUREMENT_ADD_INIT",
  PROCUREMENT_ADD_FINISH: "PROCUREMENT_ADD_FINISH",
  PROCUREMENT_ADD_SUCCESS: "PROCUREMENT_ADD_SUCCESS",

  //get procurement
  PROCUREMENT_GET: "PROCUREMENT_GET",
  PROCUREMENT_GET_INIT: "PROCUREMENT_GET_INIT",
  PROCUREMENT_GET_SUCCESS: "PROCUREMENT_GET_SUCCESS",
  PROCUREMENT_GET_FINISH: "PROCUREMENT_GET_FINISH",

  //get approval procurement
  PROCUREMENT_APPROVAL_GET: "PROCUREMENT_APPROVAL_GET",
  PROCUREMENT_APPROVAL_GET_INIT: "PROCUREMENT_APPROVAL_GET_INIT",
  PROCUREMENT_APPROVAL_GET_SUCCESS: "PROCUREMENT_APPROVAL_GET_SUCCESS",
  PROCUREMENT_APPROVAL_GET_FINISH: "PROCUREMENT_APPROVAL_GET_FINISH",

  //individual procurement
  PROCUREMENT_GET_DETAILS: "PROCUREMENT_GET_DETAILS",
  PROCUREMENT_GET_DETAILS_INIT: "PROCUREMENT_GET_DETAILS_INIT",
  PROCUREMENT_GET_DETAILS_FINISH: "PROCUREMENT_GET_DETAILS_FINISH",
  PROCUREMENT_GET_DETAILS_SUCCESS: "PROCUREMENT_GET_DETAILS_SUCCESS",

  //supervisor procurement
  PROCUREMENT_UPDATE_SUPERVISOR: "PROCUREMENT_UPDATE_SUPERVISOR",
  PROCUREMENT_UPDATE_SUPERVISOR_INIT: "PROCUREMENT_UPDATE_SUPERVISOR_INIT",
  PROCUREMENT_UPDATE_SUPERVISOR_FINISH: "PROCUREMENT_UPDATE_SUPERVISOR_FINISH",
  PROCUREMENT_UPDATE_SUPERVISOR_SUCCESS:
    "PROCUREMENT_UPDATE_SUPERVISOR_SUCCESS",

  //Accountant procurement
  PROCUREMENT_UPDATE_ACCOUNTANT: "PROCUREMENT_UPDATE_ACCOUNTANT",
  PROCUREMENT_UPDATE_ACCOUNTANT_INIT: "PROCUREMENT_UPDATE_ACCOUNTANT_INIT",
  PROCUREMENT_UPDATE_ACCOUNTANT_FINISH: "PROCUREMENT_UPDATE_ACCOUNTANT_FINISH",
  PROCUREMENT_UPDATE_ACCOUNTANT_SUCCESS:
    "PROCUREMENT_UPDATE_ACCOUNTANT_SUCCESS",

  //Management procurement
  PROCUREMENT_UPDATE_MANAGEMENT: "PROCUREMENT_UPDATE_MANAGEMENT",
  PROCUREMENT_UPDATE_MANAGEMENT_INIT: "PROCUREMENT_UPDATE_MANAGEMENT_INIT",
  PROCUREMENT_UPDATE_MANAGEMENT_FINISH: "PROCUREMENT_UPDATE_MANAGEMENT_FINISH",
  PROCUREMENT_UPDATE_MANAGEMENT_SUCCESS:
    "PROCUREMENT_UPDATE_MANAGEMENT_SUCCESS",

  //Procurement Summary
  PROCUREMENT_SUMMARY_MANAGEMENT: "PROCUREMENT_SUMMARY_MANAGEMENT",
  PROCUREMENT_SUMMARY_MANAGEMENT_INIT: "PROCUREMENT_SUMMARY_MANAGEMENT_INIT",
  PROCUREMENT_SUMMARY_MANAGEMENT_FINISH:
    "PROCUREMENT_SUMMARY_MANAGEMENT_FINISH",
  PROCUREMENT_SUMMARY_MANAGEMENT_SUCCESS:
    "PROCUREMENT_SUMMARY_MANAGEMENT_SUCCESS",

  //Procurement Counter
  PROCUREMENT_COUNTER: "PROCUREMENT_COUNTER",
  PROCUREMENT_COUNTER_INIT: "PROCUREMENT_COUNTER_INIT",
  PROCUREMENT_COUNTER_FINISH: "PROCUREMENT_COUNTER_FINISH",
  PROCUREMENT_COUNTER_SUCCESS: "PROCUREMENT_COUNTER_SUCCESS",

  //Show similar procurement
  SHOW_SIMILAR_PROCUREMENT: "SHOW_SIMILAR_PROCUREMENT",
  SHOW_SIMILAR_PROCUREMENT_INIT: "SHOW_SIMILAR_PROCUREMENT_INIT",
  SHOW_SIMILAR_PROCUREMENT_FINISH: "SHOW_SIMILAR_PROCUREMENT_FINISH",
  SHOW_SIMILAR_PROCUREMENT_SUCCESS: "SHOW_SIMILAR_PROCUREMENT_SUCCESS",

  //show items
  GET_PROCUMENT_LATEST: "GET_PROCUMENT_LATEST",
  GET_PROCUMENT_LATEST_INIT: "GET_PROCUMENT_LATEST_INIT",
  GET_PROCUMENT_LATEST_FINISH: "GET_PROCUMENT_LATEST_FINISH",
  GET_PROCUMENT_LATEST_SUCCESS: "GET_PROCUMENT_LATEST_SUCCESS",

  //all similar procurements of same topic
  GET_ALL_SIMILAR_PROCUREMENT: "GET_ALL_SIMILAR_PROCUREMENT",
  GET_ALL_SIMILAR_PROCUREMENT_INIT: "GET_ALL_SIMILAR_PROCUREMENT_INIT",
  GET_ALL_SIMILAR_PROCUREMENT_SUCCESS: "GET_ALL_SIMILAR_PROCUREMENT_SUCCESS",
  GET_ALL_SIMILAR_PROCUREMENT_FINISH: "GET_ALL_SIMILAR_PROCUREMENT_FINISH",
};

export const CEADAPPROVAL_TYPES = {
  //individual tendercead
  CEADAPPROVAL_GET_DETAILS: "CEADAPPROVAL_GET_DETAILS",
  CEADAPPROVAL_GET_DETAILS_INIT: "CEADAPPROVAL_GET_DETAILS_INIT",
  CEADAPPROVAL_GET_DETAILS_FINISH: "CEADAPPROVAL_GET_DETAILS_FINISH",
  CEADAPPROVAL_GET_DETAILS_SUCCESS: "CEADAPPROVAL_GET_DETAILS_SUCCESS",
  //Management tendercead
  CEADAPPROVAL_UPDATE_MANAGEMENT: "CEADAPPROVAL_UPDATE_MANAGEMENT",
  CEADAPPROVAL_UPDATE_MANAGEMENT_INIT: "CEADAPPROVAL_UPDATE_MANAGEMENT_INIT",
  CEADAPPROVAL_UPDATE_MANAGEMENT_FINISH:
    "CEADAPPROVAL_UPDATE_MANAGEMENT_FINISH",
  CEADAPPROVAL_UPDATE_MANAGEMENT_SUCCESS:
    "CEADAPPROVAL_UPDATE_MANAGEMENT_SUCCESS",
};

export const HOME_TYPES = {
  ENGLISH_TO_NEPAL_CONVERTER: "ENGLISH_TO_NEPAL_CONVERTER",
  ENGLISH_TO_NEPAL_CONVERTER_INIT: "ENGLISH_TO_NEPAL_CONVERTER_INIT",
  ENGLISH_TO_NEPAL_CONVERTER_SUCCESS: "ENGLISH_TO_NEPAL_CONVERTER_SUCCESS",
  ENGLISH_TO_NEPAL_CONVERTER_FINISH: "ENGLISH_TO_NEPAL_CONVERTER_FINISH",

  //attendence reason
  ATTENDENCE_LATE_REASON_ADD: "ATTENDENCE_LATE_REASON_ADD",
  ATTENDENCE_LATE_REASON_ADD_INIT: "ATTENDENCE_LATE_REASON_ADD_INIT",
  ATTENDENCE_LATE_REASON_ADD_SUCCESS: "ATTENDENCE_LATE_REASON_ADD_SUCCESS",
  ATTENDENCE_LATE_REASON_ADD_FINISH: "ATTENDENCE_LATE_REASON_ADD_FINISH",
};

export const REFERENCE__TYPES = {
  //Project

  PROJECT_PAGINATION: "PROJECT_PAGINATION",
  PROJECT_PAGINATION_INIT: "PROJECT_PAGINATION_INIT",
  PROJECT_PAGINATION_FINISH: "PROJECT_PAGINATION_FINISH",
  PROJECT_PAGINATION_SUCCESS: "PROJECT_PAGINATION_SUCCESS",
  // PROJECT_PAGINATION_CHANGE: 'PROJECT_PAGINATION_CHANGE',
  // PROJECT_PAGINATION_CHANGE_SUCCESS: 'PROJECT_PAGINATION_CHANGE_SUCCESS',
  // PROJECT_PAGINATION_SUCCESS: 'CONTACT_COMPANY_PAGINATION_SUCCESS',

  //projects for dashboard
  PROJECT_PAGINATION_DASHBOARD: "PROJECT_PAGINATION_DASHBOARD",
  PROJECT_PAGINATION_DASHBOARD_INIT: "PROJECT_PAGINATION_DASHBOARD_INIT",
  PROJECT_PAGINATION_DASHBOARD_FINISH: "PROJECT_PAGINATION_DASHBOARD_FINISH",
  PROJECT_PAGINATION_DASHBOARD_SUCCESS: "PROJECT_PAGINATION_DASHBOARD_SUCCESS",
  //projectGetDetails
  projectGetDetails: "projectGetDetails",
  projectGetDetails_INIT: "projectGetDetails_INIT",
  projectGetDetails_FINISH: "projectGetDetails_FINISH",
  projectGetDetails_SUCCESS: "projectGetDetails_SUCCESS",

  PROJECT_ADD: "PROJECT_ADD",
  PROJECT_ADD_INIT: "PROJECT_ADD_INIT",
  PROJECT_ADD_FINISH: "PROJECT_ADD_FINISH",
  PROJECT_ADD_SUCCESS: "PROJECT_ADD_SUCCESS",

  //Project Update
  PROJECT_UPDATE: "PROJECT_UPDATE",
  PROJECT_UPDATE_INIT: "PROJECT_UPDATE_INIT",
  PROJECT_UPDATE_FINISH: "PROJECT_UPDATE_FINISH",
  PROJECT_UPDATE_SUCCESS: "PROJECT_UPDATE_SUCCESS",

  //add note project
  NOTE_PROJECT_ADD: "NOTE_PROJECT_ADD",
  NOTE_PROJECT_ADD_INIT: "NOTE_PROJECT_ADD_INIT",
  NOTE_PROJECT_ADD_SUCCESS: "NOTE_PROJECT_ADD_SUCCESS",
  NOTE_PROJECT_ADD_FINISH: "NOTE_PROJECT_ADD_FINISH",

  //Sector Add
  SECTOR_ADD: "SECTOR_ADD",
  SECTOR_ADD_INIT: "SECTOR_ADD_INIT",
  SECTOR_ADD_FINISH: "SECTOR_ADD_FINISH",
  SECTOR_ADD_SUCCESS: "SECTOR_ADD_SUCCESS",

  // SectorPagiination
  SECTOR_PAGINATION: "SECTOR_PAGINATION",
  SECTOR_PAGINATION_INIT: "SECTOR_PAGINATION_INIT",
  SECTOR_PAGINATION_FINISH: "SECTOR_PAGINATION_FINISH",
  SECTOR_PAGINATION_SUCCESS: "SECTOR_PAGINATION_SUCCESS",

  //SectorUpdate
  SECTOR_UPDATE: "SECTOR_UPDATE",
  SECTOR_UPDATE_INIT: "SECTOR_UPDATE_INIT",
  SECTOR_UPDATE_FINISH: "SECTOR_UPDATE_FINISH",
  SECTOR_UPDATE_SUCCESS: "SECTOR_UPDATE_SUCCESS",

  //Status Add
  STATUS_ADD: "STATUS_ADD",
  STATUS_ADD_INIT: "STATUS_ADD_INIT",
  STATUS_ADD_FINISH: "STATUS_ADD_FINISH",
  STATUS_ADD_SUCCESS: "STATUS_ADD_SUCCESS",

  // Status Pagiination
  STATUS_PAGINATION: "STATUS_PAGINATION",
  STATUS_PAGINATION_INIT: "STATUS_PAGINATION_INIT",
  STATUS_PAGINATION_FINISH: "STATUS_PAGINATION_FINISH",
  STATUS_PAGINATION_SUCCESS: "STATUS_PAGINATION_SUCCESS",

  //Status Update
  STATUS_UPDATE: "STATUS_UPDATE",
  STATUS_UPDATE_INIT: "STATUS_UPDATE_INIT",
  STATUS_UPDATE_FINISH: "STATUS_UPDATE_FINISH",
  STATUS_UPDATE_SUCCESS: "STATUS_UPDATE_SUCCESS",

  //Stage Add
  STAGE_ADD: "STAGE_ADD",
  STAGE_ADD_INIT: "STAGE_ADD_INIT",
  STAGE_ADD_FINISH: "STAGE_ADD_FINISH",
  STAGE_ADD_SUCCESS: "STAGE_ADD_SUCCESS",

  // Status Pagiination
  STAGE_PAGINATION: "STAGE_PAGINATION",
  STAGE_PAGINATION_INIT: "STAGE_PAGINATION_INIT",
  STAGE_PAGINATION_FINISH: "STAGE_PAGINATION_FINISH",
  STAGE_PAGINATION_SUCCESS: "STAGE_PAGINATION_SUCCESS",

  //stage Update
  STAGE_UPDATE: "STAGE_UPDATE",
  STAGE_UPDATE_INIT: "STAGE_UPDATE_INIT",
  STAGE_UPDATE_FINISH: "STAGE_UPDATE_FINISH",
  STAGE_UPDATE_SUCCESS: "STAGE_UPDATE_SUCCESS",

  //Reference Add
  REFERENCE_TYPE_ADD: "REFERENCE_TYPE_ADD",
  REFERENCE_TYPE_ADD_INIT: "REFERENCE_TYPE_ADD_INIT",
  REFERENCE_TYPE_ADD_FINISH: "REFERENCE_TYPE_ADD_FINISH",
  REFERENCE_TYPE_ADD_SUCCESS: "REFERENCE_TYPE_ADD_SUCCESS",

  //AddExtensions
  REFERENCE_TYPE_ADD_EXTENSION: "REFERENCE_TYPE_ADD_EXTENSION",
  REFERENCE_TYPE_ADD_EXTENSION_INIT: "REFERENCE_TYPE_ADD_EXTENSION_INIT",
  REFERENCE_TYPE_ADD_EXTENSION_FINISH: "REFERENCE_TYPE_ADD_EXTENSION_FINISH",
  REFERENCE_TYPE_ADD_EXTENSION_SUCCESS: "REFERENCE_TYPE_ADD_EXTENSION_SUCCESS",

  //StageExtension Add
  REFERENCE_TYPE_ADD_STAGE_EXTENSION: "REFERENCE_TYPE_ADD_STAGE_EXTENSION",
  REFERENCE_TYPE_ADD_STAGE_EXTENSION_INIT:
    "REFERENCE_TYPE_ADD_STAGE_EXTENSION_INIT",
  REFERENCE_TYPE_ADD_STAGE_EXTENSION_FINISH:
    "REFERENCE_TYPE_ADD_STAGE_EXTENSION_FINISH",
  REFERENCE_TYPE_ADD_STAGE_EXTENSION_SUCCESS:
    "REFERENCE_TYPE_ADD_STAGE_EXTENSION_SUCCESS",

  //UpdateStageExtension
  REFERENCE_TYPE_UPDATE_STAGE_EXTENSION:
    "REFERENCE_TYPE_UPDATE_STAGE_EXTENSION",
  REFERENCE_TYPE_UPDATE_STAGE_EXTENSION_INIT:
    "REFERENCE_TYPE_UPDATE_STAGE_EXTENSION_INIT",
  REFERENCE_TYPE_UPDATE_STAGE_EXTENSION_FINISH:
    "REFERENCE_TYPE_UPDATE_STAGE_EXTENSION_FINISH",
  REFERENCE_TYPE_UPDATE_STAGE_EXTENSION_SUCCESS:
    "REFERENCE_TYPE_UPDATE_STAGE_EXTENSION_SUCCESS",

  //GETDetailsExtensionsStage
  REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS:
    "EFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS",
  REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS_INIT:
    "REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS_INIT",
  REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS_FINISH:
    "REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS_FINISH",
  REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS_SUCCESS:
    "EFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS_SUCCESS",

  //UpdateExtensions
  REFERENCE_TYPE_UPDATE_EXTENSION: "REFERENCE_TYPE_UPDATE_EXTENSION",
  REFERENCE_TYPE_UPDATE_EXTENSION_INIT: "REFERENCE_TYPE_UPDATE_EXTENSION_INIT",
  REFERENCE_TYPE_UPDATE_EXTENSION_FINISH:
    "REFERENCE_TYPE_UPDATE_EXTENSION_FINISH",
  REFERENCE_TYPE_UPDATE_EXTENSION_SUCCESS:
    "REFERENCE_TYPE_UPDATE_EXTENSION_SUCCESS",

  //StageLevel Update docs
  REFERENCE_TYPE_UPDATE_STAGE: "REFERENCE_TYPE_UPDATE_STAGE",
  REFERENCE_TYPE_UPDATE_STAGE_INIT: "REFERENCE_TYPE_UPDATE_STAGE_INIT",
  REFERENCE_TYPE_UPDATE_STAGE_FINISH: "REFERENCE_TYPE_UPDATE_STAGE_FINISH",
  REFERENCE_TYPE_UPDATE_STAGE_SUCCESS: "REFERENCE_TYPE_UPDATE_STAGE_SUCCESS",

  //stageLevelUpdate
  REFERENCE_TYPE_UPDATE_STAGE_LEVEL: "REFERENCE_TYPE_UPDATE_STAGE_LEVEL",
  REFERENCE_TYPE_UPDATE_STAGE_LEVEL_INIT:
    "REFERENCE_TYPE_UPDATE_STAGE_LEVEL_INIT",
  REFERENCE_TYPE_UPDATE_STAGE_LEVEL_FINISH:
    "REFERENCE_TYPE_UPDATE_STAGE_LEVEL_FINISH",
  REFERENCE_TYPE_UPDATE_STAGE_LEVEL_SUCCESS:
    "REFERENCE_TYPE_UPDATE_STAGE_LEVEL_SUCCESS",

  //ADDdocument stage
  REFERENCE_TYPE_ADD_STAGE_DOC: "REFERENCE_TYPE_ADD_STAGE_DOC",
  REFERENCE_TYPE_ADD_STAGE_DOC_INIT: "REFERENCE_TYPE_ADD_STAGE_DOC_INIT",
  REFERENCE_TYPE_ADD_STAGE_DOC_FINISH: "REFERENCE_TYPE_ADD_STAGE_DOC_FINISH",
  REFERENCE_TYPE_ADD_STAGE_DOC_SUCCESS: "REFERENCE_TYPE_ADD_STAGE_DOC_SUCCESS",

  //ExtensionGetDetails
  TENDER_EXTENSION_GET_DETAILS: "TENDER_EXTENSION_GET_DETAILS",
  TENDER_EXTENSION_GET_DETAILS_INIT: "TENDER_EXTENSION_GET_DETAILS_INIT",
  TENDER_EXTENSION_GET_DETAILS_FINISH: "TENDER_EXTENSION_GET_DETAILS_FINISH",
  TENDER_EXTENSION_GEt_DETAILS_SUCCESS: "TENDER_EXTENSION_GET_DETAILS_SUCCESS",

  // Reference Pagiination
  REFERENCE_TYPE_PAGINATION: "REFERENCE_TYPE_PAGINATION",
  REFERENCE_TYPE_PAGINATION_INIT: "REFERENCE_TYPE_PAGINATION_INIT",
  REFERENCE_TYPE_PAGINATION_FINISH: "REFERENCE_TYPE_PAGINATION_FINISH",
  REFERENCE_TYPE_PAGINATION_SUCCESS: "REFERENCE_TYPE_PAGINATION_SUCCESS",

  REFERENCE_DUE_TENDER: "REFERENCE_DUE_TENDER",
  REFERENCE_DUE_TENDER_INIT: "REFERENCE_DUE_TENDER_INIT",
  REFERENCE_DUE_TENDER_FINISH: "REFERENCE_DUE_TENDER_FINISH",
  REFERENCE_DUE_TENDER_SUCCESS: "REFERENCE_DUE_TENDER_SUCCESS",

  //PARTICIPATION
  REFERENCE_TYPE_PARTICIPATION_PAGINATION:
    "REFERENCE_TYPE_PARTICIPATION_PAGINATION",
  REFERENCE_TYPE_PARTICIPATION_PAGINATION_INIT:
    "REFERENCE_TYPE_PARTICIPATION_PAGINATION_INIT",
  REFERENCE_TYPE_PARTICIPATION_PAGINATION_FINISH:
    "REFERENCE_TYPE_PARTICIPATION_PAGINATION_FINISH",
  REFERENCE_TYPE_PARTICIPATION_PAGINATION_SUCCESS:
    "REFERENCE_TYPE_PARTICIPATION_PAGINATION_SUCCESS",

  //Document Pagination
  REFERENCE_TYPE_DOCUMENT_PAGINATION: "REFERENCE_TYPE_DOCUMENT_PAGINATION",
  REFERENCE_TYPE_DOCUMENT_PAGINATION_INIT:
    "REFERENCE_TYPE_DOCUMENT_PAGINATION_INIT",
  REFERENCE_TYPE_DOCUMENT_PAGINATION_FINISH:
    "REFERENCE_TYPE_DOCUMENT_PAGINATION_FINISH",
  REFERENCE_TYPE_DOCUMENT_PAGINATION_SUCCESS:
    "REFERENCE_TYPE_DOCUMENT_PAGINATION_SUCCESS",

  //Reference Update
  REFERENCE_TYPE_UPDATE: "REFERENCE_TYPE_UPDATE",
  REFERENCE_TYPE_UPDATE_INIT: "REFERENCE_TYPE_UPDATE_INIT",
  REFERENCE_TYPE_UPDATE_FINISH: "REFERENCE_TYPE_UPDATE_FINISH",
  REFERENCE_TYPE_UPDATE_SUCCESS: "REFERENCE_TYPE_UPDATE_SUCCESS",

  //tenderFav
  TENDER_IS_FAV: "TENDER_IS_FAV",
  TENDER_IS_FAV_INIT: "TENDER_IS_FAV_INIT",
  TENDER_IS_FAV_FINISH: "TENDER_IS_FAV_FINISH",
  TENDER_IS_FAV_SUCCESS: "TENDER_IS_FAV_SUCCESS",

  //projectFav
  PROJECT_IS_FAV: "PROJECT_IS_FAV",
  PROJECT_IS_FAV_INIT: "PROJECT_IS_FAV_INIT",
  PROJECT_IS_FAV_FINISH: "PROJECT_IS_FAV_FINISH",
  PROJECT_IS_FAV_SUCCESS: "PROJECT_IS_FAV_SUCCESS",

  //Currency Listing
  CURRENCY_LISTING: "CURRENCY_LISTING",
  CURRENCY_LISTING_INIT: "CURRENCY_LISTING_INIT",
  CURRENCY_LISTING_FINISH: "CURRENCY_LISTING_FINISH",
  CURRENCY_LISTING_SUCCESS: "CURRENCY_LISTING_SUCCESS",

  //Tender Basic
  //Basic Add
  TENDER_STAGE_ADD: "TENDER_STAGE_ADD",
  TENDER_STAGE_ADD_INIT: "TENDER_STAGE_ADD_INIT",
  TENDER_STAGE_ADD_FINISH: "TENDER_STAGE_ADD_FINISH",
  TENDER_STAGE_ADD_SUCCESS: "TENDER_STAGE_ADD_SUCCESS",

  //StageLevel Add
  TENDER_BASIC_ADD: "TENDER_BASIC_ADD",
  TENDER_BASIC_ADD_INIT: "TENDER_BASIC_ADD_INIT",
  TENDER_BASIC_ADD_FINISH: "TENDER_BASIC_ADD_FINISH",
  TENDER_BASIC_ADD_SUCCESS: "TENDER_BASIC_ADD_SUCCESS",

  //tenderParticipationaDD
  TENDER_BASIC_ADD_PARTICIPATION: "TENDER_BASIC_ADD_PARTICIPATION",
  TENDER_BASIC_ADD_PARTICIPATION_INIT: "TENDER_BASIC_ADD_PARTICIPATION_INIT",
  TENDER_BASIC_ADD_PARTICIPATION_FINISH:
    "TENDER_BASIC_ADD_PARTICIPATION_FINISH",
  TENDER_BASIC_ADD_PARTICIPATION_SUCCESS:
    "TENDER_BASIC_ADD_PARTICIPATION_SUCCESS",

  //tenderParticipationUpdate
  TENDER_BASIC_UPDATE_PARTICIPATION: "TENDER_BASIC_UPDATE_PARTICIPATION",
  TENDER_BASIC_UPDATE_PARTICIPATION_INIT:
    "TENDER_BASIC_UPDATE_PARTICIPATION_INIT",
  TENDER_BASIC_UPDATE_PARTICIPATION_FINISH:
    "TENDER_BASIC_UPDATE_PARTICIPATION_FINISH",
  TENDER_BASIC_UPDATE_PARTICIPATION_SUCCESS:
    "TENDER_BASIC_UPDATE_PARTICIPATION_SUCCESS",

  // TENDER_BASIC_GETDETAILS_PARTICIPATION: "TENDER_BASIC_GETDETAILS_PARTICIPATION",
  // TENDER_BASIC_GETDETAILS_PARTICIPATION_INIT:
  //   "TENDER_BASIC_GETDETAILS_PARTICIPATION_INIT",
  // TENDER_BASIC_GETDEAILS_PARTICIPATION_FINISH:
  //   "TENDER_BASIC_GETAIK_PARTICIPATION_FINISH",
  // TENDER_BASIC_UPDATE_PARTICIPATION_SUCCESS:
  //   "TENDER_BASIC_UPDATE_PARTICIPATION_SUCCESS",

  // Basic Pagination
  TENDER_BASIC_PAGINATION: "TENDER_BASIC_PAGINATION",
  TENDER_BASIC_PAGINATION_INIT: "TENDER_BASIC_PAGINATION_INIT",
  TENDER_BASIC_PAGINATION_FINISH: "TENDER_BASIC_PAGINATION_FINISH",
  TENDER_BASIC_PAGINATION_SUCCESS: "TENDER_BASIC_PAGINATION_SUCCESS",

  // Basic Secondary Root Pagination
  TENDER_BASIC_PAGINATION_SECONDARY: "TENDER_BASIC_PAGINATION_SECONDARY",
  TENDER_BASIC_PAGINATION_SECONDARY_INIT:
    "TENDER_BASIC_PAGINATION_SECONDARY_INIT",
  TENDER_BASIC_PAGINATION_SECONDARY_FINISH:
    "TENDER_BASIC_PAGINATION_SECONDARY_FINISH",
  TENDER_BASIC_PAGINATION_SECONDARY_SUCCESS:
    "TENDER_BASIC_PAGINATION_SECONDARY_SUCCESS",

  //for inifinite Scroll Effect
  TENDER_BASIC_PAGINATION_CHANGE: "TENDER_BASIC_PAGINATION_CHANGE",
  TENDER_BASIC_PAGINATION_CHANGE_SUCCESS:
    "TENDER_BASIC_PAGINATION_CHANGE_SUCCESS",

  //history tender pagination
  TENDER_HISTORY_PAGINATION: "TENDER_HISTORY_PAGINATION",
  TENDER_HISTORY_PAGINATION_INIT: "TENDER_HISTORY_PAGINATION_INIT",
  TENDER_HISTORY_PAGINATION_FINISH: "TENDER_HISTORY_PAGINATION_FINISH",
  TENDER_HISTORY_PAGINATION_SUCCESS: "TENDER_HISTORY_PAGINATION_SUCCESS",

  //Tender Counter
  TENDER_COUNTER: "TENDER_COUNTER",
  TENDER_COUNTER_INIT: "TENDER_COUNTER_INIT",
  TENDER_COUNTER_FINSIH: "TENDER_COUNTER_FINSIH",
  TENDER_COUNTER_SUCCESS: "TENDER_COUNTER_SUCCESS",

  //upcoming tender pagination
  TENDER_UPCOMING_PAGINATION: "TENDER_UPCOMING_PAGINATION",
  TENDER_UPCOMING_PAGINATION_INIT: "TENDER_UPCOMING_PAGINATION_INIT",
  TENDER_UPCOMING_PAGINATION_FINISH: "TENDER_UPCOMING_PAGINATION_FINISH",
  TENDER_UPCOMING_PAGINATION_SUCCESS: "TENDER_UPCOMING_PAGINATION_SUCCESS",

  //Fourth Upcoming Tender Pagination
  TENDER_FOURTH_UPCOMING_PAGINATION: "TENDER_FOURTH_UPCOMING_PAGINATION",
  TENDER_FOURTH_PAGINATION_PAGINATION_INIT:
    "TENDER_FOURTH_PAGINATION_PAGINATION_INIT",
  TENDER_FOURTH_PAGINATION_PAGINATION_FINISH:
    "TENDER_FOURTH_PAGINATION_PAGINATION_FINISH",
  TENDER_FOURTH_UPCOMING_PAGINATION_SUCCESS:
    "TENDER_FOURTH_UPCOMING_PAGINATION_SUCCESS",
  //basic Update
  TENDER_BASIC_UPDATE: "TENDER_BASIC_UPDATE",
  TENDER_BASIC_UPDATE_INIT: "TENDER_BASIC_UPDATE_INIT",
  TENDER_BASIC_UPDATE_FINISH: "TENDER_BASIC_UPDATE_FINISH",
  TENDER_BASIC_UPDATE_SUCCESS: "TENDER_BASIC_UPDATE_SUCCESS",
  TENDER_BASIC_UPDATE_DELETE: "TENDER_BASIC_UPDATE_DELETE",

  // ROTO MODAL COMPONENT : GET
  TENDER_BASIC_ROTO_GetDetails: "TENDER_BASIC_ROTO_GetDetails",
  TENDER_BASIC_ROTO_GetDetails_INIT: "TENDER_BASIC_ROTO_GetDetails_INIT",
  TENDER_BASIC_ROTO_GetDetails_FINISH: "TENDER_BASIC_ROTO_GetDetails_FINISH",
  TENDER_BASIC_ROTO_GetDetails_SUCCESS: "TENDER_BASIC_ROTO_GetDetails_SUCCESS",

  //BASIC GET DETAILS
  TENDER_BASIC_GetDetails: "TENDER_BASIC_GetDetails",
  TENDER_BASIC_GetDetails_INIT: "TENDER_BASIC_GetDetails_INIT",
  TENDER_BASIC_GetDetails_FINISH: "TENDER_BASIC_GetDetails_FINISH",
  TENDER_BASIC_GetDetails_SUCCESS: "TENDER_BASIC_GetDetails_SUCCESS",
  TENDER_BASIC_GetDetails_UPDATE: "TENDER_BASIC_GetDetails_UPDATE",
  TENDER_BASIC_GetDetails_PARTIAL: "TENDER_BASIC_GetDetails_PARTIAL",

  //tenderStageListing
  GET_TENDERSTAGELISTING: "GET_TENDERSTAGELISTING",
  GET_TENDERSTAGELISTING_INIT: "GET_TENDERSTAGELISTING_INIT",
  GET_TENDERSTAGELISTING_FINISH: "GET_TENDERSTAGELISTING_FINISH",
  GET_TENDERSTAGELISTING_SUCCESS: "GET_TENDERSTAGELISTING_SUCCESS",

  //TENDERTYPELISTING
  GET_TENDERTYPELISTING: "GET_TENDERTYPELISTING",
  GET_TENDERTYPELISTING_INIT: "GET_TENDERTYPELISTING_INIT",
  GET_TENDERTYPELISTING_FINISH: "GET_TENDERTYPELISTING_FINISH",
  GET_TENDERTYPELISTING_SUCCESS: "GET_TENDERTYPELISTING_SUCCESS",

  // SUBTYPELISTING
  GET_TENDER_SUBTYPE_LISTING: "GET_TENDER_SUBTYPE_LISTING",
  GET_TENDER_SUBTYPE_LISTING_INIT: "GET_TENDER_SUBTYPE_LISTING_INIT",
  GET_TENDER_SUBTYPE_LISTING_FINISH: "GET_TENDER_SUBTYPE_LISTING_FINISH",
  GET_TENDER_SUBTYPE_LISTING_SUCCESS: "GET_TENDER_SUBTYPE_LISTING_SUCCESS",

  //TenderDocument

  //TenderDocumentAdd
  TENDER_DOCUMENT_ADD: "TENDER_DOCUMENT_ADD",
  TENDER_DOCUMENT_ADD_INIT: "TENDER_DOCUMENT_ADD_INIT",
  TENDER_DOCUMENT_ADD_FINISH: "TENDER_DOCUMENT_ADD_FINISH",
  TENDER_DOCUMENT_ADD_SUCCESS: "TENDER_DOCUMENT_ADD_SUCCESS",

  //TenderDoucmentGetDetails
  TENDER_DOCUMENT_GET_DETAILS: "TENDER_DOCUMENT_GET_DETAILS",
  TENDER_DOCUMENT_GET_DETAILS_INIT: "TENDER_DOCUMENT_GET_DETAILS_INIT",
  TENDER_DOCUMENT_GET_DETAILS_FINISH: "TENDER_DOCUMENT_GET_DETAILS_FINISH",
  TENDER_DOCUMENT_Get_DETAILS_SUCCESS: "TENDER_DOCUMENT_Get_DETAILS_SUCCESS",

  //TenderDocument Update
  TENDER_DOCUMENT_UPDATE: "TENDER_DOCUMENT_UPDATE",
  TENDER_DOCUMENT_UPDATE_INIT: "TENDER_DOCUMENT_UPDATE_INIT",
  TENDER_DOCUMENT_UPDATE_FINISH: "TENDER_DOCUMENT_UPDATE_FINISH",
  TENDER_DOCUMENT_UPDATE_SUCCESS: "TENDER_DOCUMENT_UPDATE_SUCCESS",

  //PARTICIPATION TENDER ADD/UPDATE/GETDETAILS

  TENDER_PARTICIPATION_ADD: "TENDER_PARTICIPATION_ADD",
  TENDER_PARTICIPATION_ADD_INIT: "TENDER_PARTICIPATION_ADD_INIT",
  TENDER_PARTICIPATION_ADD_FINISH: "TENDER_PARTICIPATION_ADD_FINISH",
  TENDER_PARTICIPATION_ADD_SUCCESS: "TENDER_PARTICIPATION_ADD_SUCCESS",

  //TenderDoucmentGetDetails
  TENDER_PARTICIPATION_GET_DETAILS: "TENDER_PARTICIPATION_GET_DETAILS",
  TENDER_PARTICIPATION_GET_DETAILS_INIT:
    "TENDER_PARTICIPATION_GET_DETAILS_INIT",
  TENDER_PARTICIPATION_GET_DETAILS_FINISH:
    "TENDER_PARTICIPATION_GET_DETAILS_FINISH",
  TENDER_PARTICIPATION_GET_DETAILS_SUCCESS:
    "TENDER_PARTICIPATION_GET_DETAILS_SUCCESS",

  //TenderDocument Update
  TENDER_PARTICIPATION_UPDATE: "TENDER_PARTICIPATION_UPDATE",
  TENDER_PARTICIPATION_UPDATE_INIT: "TENDER_PARTICIPATION_UPDATE_INIT",
  TENDER_PARTICIPATION_UPDATE_FINISH: "TENDER_PARTICIPATION_UPDATE_FINISH",
  TENDER_PARTICIPATION_UPDATE_SUCCESS: "TENDER_PARTICIPATION_UPDATE_SUCCESS",

  // tender award status

  //TenderDocumentAdd
  TENDER_AWARD_STATUS_ADD: "TENDER_AWARD_STATUS_ADD",
  TENDER_AWARD_STATUS_ADD_INIT: "TENDER_AWARD_STATUS_ADD_INIT",
  TENDER_AWARD_STATUS_ADD_FINISH: "TENDER_AWARD_STATUS_ADD_FINISH",
  TENDER_AWARD_STATUS_ADD_SUCCESS: "TENDER_AWARD_STATUS_ADD_SUCCESS",

  //TenderDoucmentGetDetails
  TENDER_AWARD_STATUS_DETAILS: " TENDER_AWARD_STATUS_DETAILS",
  TENDER_AWARD_STATUS_DETAILS_INIT: " TENDER_AWARD_STATUS_DETAILS_INIT",
  TENDER_AWARD_STATUS_DETAILS_FINISH: " TENDER_AWARD_STATUS_DETAILS_FINISH",
  TENDER_AWARD_STATUS_DETAILS_SUCCESS: " TENDER_AWARD_STATUS_DETAILS_SUCCESS",

  //TenderDocument Update
  TENDER_AWARD_STATUS_UPDATE: "TENDER_AWARD_STATUS_UPDATE",
  TENDER_AWARD_STATUS_UPDATE_INIT: "TENDER_AWARD_STATUS_UPDATE_INIT",
  TENDER_AWARD_STATUS_UPDATE_FINISH: "TENDER_AWARD_STATUS_UPDATE_FINISH",
  TENDER_AWARD_STATUS_UPDATE_SUCCESS: "TENDER_AWARD_STATUS_UPDATE_SUCCESS",

  //tenderdocument delete
  TENDER_STAGE_EXTENSION_DELETE: "TENDER_STAGE_EXTENSION_DELETE",
  TENDER_STAGE_EXTENSION_DELETE_INIT: "TENDER_STAGE_EXTENSION_DELETE_INIT",
  TENDER_STAGE_EXTENSION_DELETE_SUCCESS:
    "TENDER_STAGE_EXTENSION_DELETE_SUCCESS",
  TENDER_STAGE_EXTENSION_DELETE_FINISH: "TENDER_STAGE_EXTENSION_DELETE_FINISH",

  //Reference Others
  REFERENCE_OTHERS_PAGINATION: "REFERENCE_OTHERS_PAGINATION",
  REFERENCE_OTHERS_PAGINATION_INIT: "REFERENCE_OTHERS_PAGINATION_INIT",
  REFERENCE_OTHERS_PAGINATION_FINISH: "REFERENCE_OTHERS_PAGINATION_FINISH",
  REFERENCE_OTHERS_PAGINATION_SUCCESS: "REFERENCE_OTHERS_PAGINATION_SUCCESS",

  REFERENCE_OTHERS_ADD: "REFERENCE_OTHERS_ADD",
  REFERENCE_OTHERS_ADD_INIT: "REFERENCE_OTHERS_ADD_INIT",
  REFERENCE_OTHERS_ADD_FINISH: "REFERENCE_OTHERS_ADD_FINISH",
  REFERENCE_OTHERS_ADD_SUCCESS: "REFERENCE_OTHERS_ADD_SUCCESS",

  REFERENCE_OTHERS_UPDATE: "REFERENCE_OTHERS_UPDATE",
  REFERENCE_OTHERS_UPDATE_INIT: "REFERENCE_OTHERS_UPDATE_INIT",
  REFERENCE_OTHERS_UPDATE_FINISH: "REFERENCE_OTHERS_UPDATE_FINISH",
  REFERENCE_OTHERS_UPDATE_SUCCESS: "REFERENCE_OTHERS_UPDATE_SUCCESS",

  REFERENCE_OTHERS_GetDetails: "REFERENCE_OTHERS_GetDetails",
  REFERENCE_OTHERS_GetDetails_INIT: "REFERENCE_OTHERS_GetDetails_INIT",
  REFERENCE_OTHERS_GetDetails_FINISH: "REFERENCE_OTHERS_GetDetails_FINISH",
  REFERENCE_OTHERS_GetDetails_SUCCESS: "REFERENCE_OTHERS_GetDetails_SUCCESS",

  REFERENCE_OTHERS_IS_FAV: "REFERENCE_OTHERS_IS_FAV",
  REFERENCE_OTHERS_IS_FAV_INIT: "REFERENCE_OTHERS_IS_FAV_INIT",
  REFERENCE_OTHERS_IS_FAV_FINISH: "REFERENCE_OTHERS_IS_FAV_FINISH",
  REFERENCE_OTHERS_IS_FAV_SUCCESS: "REFERENCE_OTHERS_IS_FAV_SUCCESS",

  // primary and secondary tag listing

  REFERENCE_OTHERS_ROOTLEVEL: "REFERENCE_OTHERS_ROOTLEVEL",
  REFERENCE_OTHERS_ROOTLEVEL_INIT: "REFERENCE_OTHERS_ROOTLEVEL_INIT",
  REFERENCE_OTHERS_ROOTLEVEL_FINISH: "REFERENCE_OTHERS_ROOTLEVEL_FINISH",
  REFERENCE_OTHERS_ROOTLEVEL_SUCCESS: "REFERENCE_OTHERS_ROOTLEVEL_SUCCESS",

  // refernce secondary  add
  REFERENCE_SECONDARY_ADD: "REFERENCE_SECONDARY_ADD",
  REFERENCE_SECONDARY_ADD_INIT: "REFERENCE_SECONDARY_ADD_INIT",
  REFERENCE_SECONDARY_ADD_FINISH: "REFERENCE_SECONDARY_ADD_FINISH",
  REFERENCE_SECONDARY_ADD_SUCCESS: "REFERENCE_SECONDARY_ADD_SUCCESS",

  // refernce others combined add
  REFERENCE_OTHERS__COMBINED_ADD: "REFERENCE_OTHERS__COMBINED_ADD",
  REFERENCE_OTHERS__COMBINED_ADD_INIT: "REFERENCE_OTHERS__COMBINED_ADD_INIT",
  REFERENCE_OTHERS__COMBINED_ADD_FINISH:
    "REFERENCE_OTHERS__COMBINED_ADD_FINISH",
  REFERENCE_OTHERS__COMBINED_ADD_SUCCESS:
    "REFERENCE_OTHERS__COMBINED_ADD_SUCCESS",

  // reference others combine update

  REFERENCE_OTHERS_COMBINE_UPDATE: "REFERENCE_OTHERS_COMBINE_UPDATE",
  REFERENCE_OTHERS_COMBINE_UPDATE_INIT: "REFERENCE_OTHERS_COMBINE_UPDATE_INIT",
  REFERENCE_OTHERS_COMBINE_UPDATE_FINISH:
    "REFERENCE_OTHERS_COMBINE_UPDATE_FINISH",
  REFERENCE_OTHERS_COMBINE_UPDATE_SUCCESS:
    "REFERENCE_OTHERS_COMBINE_UPDATE_SUCCESS",

  //all reference Pagination
  REFERENCE_EMAIL_LISTING: "REFERENCE_EMAIL_LISTING",
  REFERENCE_EMAIL_LISTING_INIT: "REFERENCE_EMAIL_LISTING_INIT",
  REFERENCE_EMAIL_LISTING_FINISH: "REFERENCE_EMAIL_LISTING_FINISH",
  REFERENCE_EMAIL_LISTING_SUCCESS: "REFERENCE_EMAIL_LISTING_SUCCESS",

  // Reference Management / reference  Party listing
  REFERENCE_PARTY_lISTING: "REFERENCE_PARTY_lISTING",
  REFERENCE_PARTY_lISTING_INIT: "REFERENCE_PARTY_lISTING_INIT",
  REFERENCE_PARTY_lISTING_FINISH: "REFERENCE_PARTY_lISTING_FINISH",
  REFERENCE_PARTY_lISTING_SUCCESS: "REFERENCE_PARTY_lISTING_SUCCESS",

  // ADDED cirular planning
  REFERENCE_PARTY_LISTING_CIRCULAR: "REFERENCE_PARTY_LISTING_CIRCULAR",
  REFERENCE_PARTY_LISTING_CIRCULAR_INIT:
    "REFERENCE_PARTY_LISTING_CIRCULAR_INIT",
  REFERENCE_PARTY_LISTING_CIRCULAR_FINISH:
    "REFERENCE_PARTY_LISTING_CIRCULAR_FINISH",
  REFERENCE_PARTY_LISTING_CIRCULAR_SUCCESS:
    "REFERENCE_PARTY_LISTING_CIRCULAR_SUCCESS",
  REFERENCE_PARTY_LISTING_CIRCULAR_UPDATE:
    "REFERENCE_PARTY_LISTING_CIRCULAR_UPDATE",

  // POST CIRCULAR ADD
  REFERENCE_CIRCULAR_PLANNING_ADD: "REFERENCE_CIRCULAR_PLANNING_ADD",
  REFERENCE_CIRCULAR_PLANNING_ADD_INIT: "REFERENCE_CIRCULAR_PLANNING_ADD_INIT",
  REFERENCE_CIRCULAR_PLANNING_ADD_FINISH:
    "REFERENCE_CIRCULAR_PLANNING_ADD_FINISH",
  REFERENCE_CIRCULAR_PLANNING_ADD_SUCCESS:
    "REFERENCE_CIRCULAR_PLANNING_ADD_SUCCESS",

  // UPDATE CIRCULAR
  REFERENCE_CIRCULAR_PLANNING_UPDATE: "REFERENCE_CIRCULAR_PLANNING_UPDATE",
  REFERENCE_CIRCULAR_PLANNING_UPDATE_INIT:
    "REFERENCE_CIRCULAR_PLANNING_UPDATE_INIT",
  REFERENCE_CIRCULAR_PLANNING_UPDATE_FINISH:
    "REFERENCE_CIRCULAR_PLANNING_UPDATE_FINISH",
  REFERENCE_CIRCULAR_PLANNING_UPDATE_UPDATE:
    "REFERENCE_CIRCULAR_PLANNING_UPDATE_FINISH",
  REFERENCE_CIRCULAR_PLANNING_UPDATE_SUCCESS:
    "REFERENCE_CIRCULAR_PLANNING_UPDATE_SUCCESS",

  // DELETE COMPANY
  REFERENCE_COMPANY_DELETE: "REFERENCE_COMPANY_DELETE",
  REFERENCE_COMPANY_DELETE_INIT: "REFERENCE_COMPANY_DELETE_INIT",
  REFERENCE_COMPANY_DELETE_FINISH: "REFERENCE_COMPANY_DELETE_FINISH",
  REFERENCE_COMPANY_DELETE_SUCCESS: "REFERENCE_COMPANY_DELETE_SUCCESS",

  //  ReferencePartyRemarksHistoryAdd
  REFERENCE_PARTY_REMARKS_HISTORY_ADD: "REFERENCE_PARTY_REMARKS_HISTORY_ADD",
  REFERENCE_PARTY_REMARKS_HISTORY_ADD_INIT:
    "REFERENCE_PARTY_REMARKS_HISTORY_ADD_INIT",
  REFERENCE_PARTY_REMARKS_HISTORY_ADD_FINISH:
    "REFERENCE_PARTY_REMARKS_HISTORY_ADD_FINISH",
  REFERENCE_PARTY_REMARKS_HISTORY_ADD_SUCCESS:
    "REFERENCE_PARTY_REMARKS_HISTORY_ADD_SUCCESS",

  // solo REMARK TYPE
  REFERENCE_PARTY_REMARKS_HISTORY_SOLO: "REFERENCE_PARTY_REMARKS_HISTORY_SOLO",
  REFERENCE_PARTY_REMARKS_HISTORY_SOLO_INIT:
    "REFERENCE_PARTY_REMARKS_HISTORY_SOLO_INIT",
  REFERENCE_PARTY_REMARKS_HISTORY_SOLO_FINISH:
    "REFERENCE_PARTY_REMARKS_HISTORY_SOLO_FINISH",
  REFERENCE_PARTY_REMARKS_HISTORY_SOLO_SUCCESS:
    "REFERENCE_PARTY_REMARKS_HISTORY_SOLO_SUCCESS",

  // CIRCULAR PLANNING REMARKS
  REFERENCE_REMARKS_CIRCULAR_PLANNING: "REFERENCE_REMARKS_CIRCULAR_PLANNING",
  REFERENCE_REMARKS_CIRCULAR_PLANNING_INIT:
    "REFERENCE_REMARKS_CIRCULAR_PLANNING_INIT",
  REFERENCE_REMARKS_CIRCULAR_PLANNING_FINISH:
    "REFERENCE_REMARKS_CIRCULAR_PLANNING_FINISH",
  REFERENCE_REMARKS_CIRCULAR_PLANNING_SUCCESS:
    "REFERENCE_REMARKS_CIRCULAR_PLANNING_SUCCESS",

  // ReferenceParty RemarksHistoryListing
  REFERENCE_PARTY_REMARKS_HISTORY_LISTING:
    "REFERENCE_PARTY_REMARKS_HISTORY_LISTING",
  REFERENCE_PARTY_REMARKS_HISTORY_LISTING_INIT:
    "REFERENCE_PARTY_REMARKS_HISTORY_lISTING_INIT",
  REFERENCE_PARTY_REMARKS_HISTORY_LISTING_FINISH:
    "REFERENCE_PARTY_REMARKS_HISTORY_lISTING_FINISH",
  REFERENCE_PARTY_REMARKS_HISTORY_LISTING_SUCCESS:
    "REFERENCE_PARTY_REMARKS_HISTORY_LISTING_SUCCESS",

  // ReferenceCompanyManualAdd
  REFERENCE_COMPANY_MANUAL_ADD: "REFERENCE_COMPANY_MANUAL_ADD",
  REFERENCE_COMPANY_MANUAL_ADD_INIT: "REFERENCE_COMPANY_MANUAL_ADD_INIT",
  REFERENCE_COMPANY_MANUAL_ADD_FINISH: "REFERENCE_COMPANY_MANUAL_ADD_FINISH",
  REFERENCE_COMPANY_MANUAL_ADD_SUCCESS: "REFERENCE_COMPANY_MANUAL_ADD_SUCCESS",

  // UpdateReferenceCompanyOfMailMessageByReference
  UPDATE_REFERENCE_COMPANY: "UPDATE_REFERENCE_COMPANY",
  UPDATE_REFERENCE_COMPANY_INIT: "UPDATE_REFERENCE_COMPANY_INIT",
  UPDATE_REFERENCE_COMPANY_FINISH: "UPDATE_REFERENCE_COMPANY_FINISH",
  UPDATE_REFERENCE_COMPANY_SUCCESS: "UPDATE_REFERENCE_COMPANY_SUCCESS",

  // ReferencePartyActionWithActionType
  REFERENCE_PARTY_ACTION: "REFERENCE_PARTY_ACTION",
  REFERENCE_PARTY_ACTION_INIT: "REFERENCE_PARTY_ACTION_INIT",
  REFERENCE_PARTY_ACTION_FINISH: "REFERENCE_PARTY_ACTION_FINISH",
  REFERENCE_PARTY_ACTION_SUCCESS: "REFERENCE_PARTY_ACTION_SUCCESS",

  //auto reference listing
  AUTO_REFERENCE_LISTING: "AUTO_REFERENCE_LISTING",
  AUTO_REFERENCE_LISTING_INIT: "AUTO_REFERENCE_LISTING_INIT",
  AUTO_REFERENCE_LISTING_SUCCESS: "AUTO_REFERENCE_LISTING_SUCCESS",
  AUTO_REFERENCE_LISTING_FINISH: "AUTO_REFERENCE_LISTING_FINISH",

  //email auto reference add
  EMAIL_AUTO_REFERENCE_ADD: "EMAIL_AUTO_REFERENCE_ADD",
  EMAIL_AUTO_REFERENCE_ADD_INIT: "EMAIL_AUTO_REFERENCE_ADD_INIT",
  EMAIL_AUTO_REFERENCE_ADD_SUCCESS: "EMAIL_AUTO_REFERENCE_ADD_SUCCESS",

  //date fiultering search tender
  DATE_FILTERING_TENDER: "DATE_FILTERING_TENDER",
  DATE_FILTERING_TENDER_INIT: "DATE_FILTERING_TENDER_INIT",
  DATE_FILTERING_TENDER_SUCCESS: "DATE_FILTERING_TENDER_SUCCESS",

  //tender documents section
  ALL_TENDER_GET_DETAILS: "ALL_TENDER_GET_DETAILS",
  ALL_TENDER_GET_DETAILS_INIT: "ALL_TENDER_GET_DETAILS",
  ALL_TENDER_GET_DETAILS_SUCCESS: "ALL_TENDER_GET_DETAILS_SUCCESS",

  // CIRCULAR MAIL TENDER CHECKLIST
  GET_TENDER_CHECKLIST: "GET_TENDER_CHECKLIST",
  GET_TENDER_CHECKLIST_INIT: "GET_TENDER_CHECKLIST_INIT",
  GET_TENDER_CHECKLIST_SUCCESS: "GET_TENDER_CHECKLIST_SUCCESS",
  GET_TENDER_CHECKLIST_FINISH: "GET_TENDER_CHECKLIST_FINISH",

  //shared Email list
  SHARED_EMAIL_ADD: "SHARED_EMAIL_ADD",
  SHARED_EMAIL_ADD_INIT: "SHARED_EMAIL_ADD_INIT",
  SHARED_EMAIL_ADD_SUCCESS: "SHARED_EMAIL_ADD_SUCCESS",

  //all details of Shared mail
  GET_ALL_SHARED_EMAIL_LIST: "GET_ALL_SHARED_EMAIL_LIST",
  GET_ALL_SHARED_EMAIL_LIST_INIT: "GET_ALL_SHARED_EMAIL_LIST_INIT",
  GET_ALL_SHARED_EMAIL_LIST_SUCCESS: "GET_ALL_SHARED_EMAIL_LIST_SUCCESS",

  CONTACT_UPDATE: "CONTACT_UPDATE",
  CONTACT_UPDATE_INIT: "CONTACT_UPDATE_INIT",
  CONTACT_UPDATE_SUCCESS: "CONTACT_UPDATE_SUCCESS",
  CONTACT_UPDATE_FINISH: "CONTACT_UPDATE_FINISH",

  // outbox mailfailurecount reset
  OUTBOXMAIL_RESET_FAILURECOUNT: "OUTBOXMAIL_RESET_FAILURECOUNT",
  OUTBOXMAIL_RESET_FAILURECOUNT_INIT: "OUTBOXMAIL_RESET_FAILURECOUNT_INIT",
  OUTBOXMAIL_RESET_FAILURECOUNT_SUCCESS:
    "OUTBOXMAIL_RESET_FAILURECOUNT_SUCCESS",
  OUTBOXMAIL_RESET_FAILURECOUNT_FINISH: "OUTBOXMAIL_RESET_FAILURECOUNT_FINISH",

  // Checklist api circular api
  ADDUPDATE_CIRCULAR_CHEKLIST: "ADDUPDATE_CIRCULAR_CHEKLIST",
  ADDUPDATE_CIRCULAR_CHEKLIST_INIT: "ADDUPDATE_CIRCULAR_CHEKLIST_INIT",
  ADDUPDATE_CIRCULAR_CHEKLIST_FINISH: "ADDUPDATE_CIRCULAR_CHEKLIST_FINISH",
  ADDUPDATE_CIRCULAR_CHEKLIST_SUCCESS: "ADDUPDATE_CIRCULAR_CHEKLIST_SUCCESS",

  // GET referenceTemplateGetDetailsById
  GET_CIRCULAR_REFERENCE_TEMPLATE: "GET_CIRCULAR_REFERENCE_TEMPLATE",
  GET_CIRCULAR_REFERENCE_TEMPLATE_INIT: "GET_CIRCULAR_REFERENCE_TEMPLATE_INIT",
  GET_CIRCULAR_REFERENCE_TEMPLATE_FINISH:
    "GET_CIRCULAR_REFERENCE_TEMPLATE_FINISH",
  GET_CIRCULAR_REFERENCE_TEMPLATE_SUCCESS:
    "GET_CIRCULAR_REFERENCE_TEMPLATE_SUCCESS",
  GET_CIRCULAR_REFERENCE_TEMPLATE_UPDATE:
    "GET_CIRCULAR_REFERENCE_TEMPLATE_UPDATE",

  // ADD UPDATE
  ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE:
    "ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE",
  ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE_INIT:
    "ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE_INIT",
  ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE_FINISH:
    "ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE_FINISH",
  ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE_SUCCESS:
    "ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE_SUCCESS",

  // GET Approved Status Circular Planning
  GET_APPROVED_STATUS_CIRCULARPLANNING: "GET_APPROVED_STATUS_CIRCULARPLANNING",
  GET_APPROVED_STATUS_CIRCULARPLANNING_INIT:
    "GET_APPROVED_STATUS_CIRCULARPLANNING_INIT",
  GET_APPROVED_STATUS_CIRCULARPLANNING_FINISH:
    "GET_APPROVED_STATUS_CIRCULARPLANNING_FINISH",
  GET_APPROVED_STATUS_CIRCULARPLANNING_SUCCESS:
    "GET_APPROVED_STATUS_CIRCULARPLANNING_SUCCESS",

  // POST ISAPPROVED STATUS
  ADD_ISAPPROVED_STATUS: "ADD_ISAPPROVED_STATUS",
  ADD_ISAPPROVED_STATUS_INIT: "ADD_ISAPPROVED_STATUS_INIT",
  ADD_ISAPPROVED_STATUS_FINISH: "ADD_ISAPPROVED_STATUS_FINISH",
  ADD_ISAPPROVED_STATUS_SUCCESS: "ADD_ISAPPROVED_STATUS_SUCCESS",

  // CIRCULAR TEMPLATE MAIL
  GET_CIRCULARPLANNING_TEMPLATE: "GET_CIRCULAR_PLANNING_TEMPLATE",
  GET_CIRCULARPLANNING_TEMPLATE_INIT: "GET_CIRCULAR_PLANNING_TEMPLATE_INIT",
  GET_CIRCULARPLANNING_TEMPLATE_FINISH: "GET_CIRCULAR_PLANNING_TEMPLATE_FINISH",
  GET_CIRCULARPLANNING_TEMPLATE_SUCCESS:
    "GET_CIRCULAR_PLANNING_TEMPLATE_SUCCESS",

  // ALL
  GET_ALL_CIRCULARPLANNING_TEMPLATE: "GET_ALL_CIRCULARPLANNING_TEMPLATE",
  GET_ALL_CIRCULARPLANNING_TEMPLATE_INIT:
    "GET_ALL_CIRCULARPLANNING_TEMPLATE_INIT",
  GET_ALL_CIRCULARPLANNING_TEMPLATE_FINISH:
    "GET_ALL_CIRCULARPLANNING_TEMPLATE_FINISH",
  GET_ALL_CIRCULARPLANNING_TEMPLATE_SUCCESS:
    "GET_ALL_CIRCULARPLANNING_TEMPLATE_SUCCESS",

  // post
  ADD_CIRCULARPLANNING_TEMPLATE: "ADD_CIRCULARPLANNING_TEMPLATE",
  ADD_CIRCULARPLANNING_TEMPLATE_INIT: "ADD_CIRCULARPLANNING_TEMPLATE_INIT",
  ADD_CIRCULARPLANNING_TEMPLATE_FINISH: "ADD_CIRCULARPLANNING_TEMPLATE_FINISH",
  ADD_CIRCULARPLANNING_TEMPLATE_SUCCESS:
    "ADD_CIRCULARPLANNING_TEMPLATE_SUCCESS",

  // addd
  ADD_CIRCULARPLANNING_COMPANY: "ADD_CIRCULARPLANNING_COMPANY",
  ADD_CIRCULARPLANNING_COMPANY_INIT: "ADD_CIRCULARPLANNING_COMPANY_INIT",
  ADD_CIRCULARPLANNING_COMPANY_FINISH: "ADD_CIRCULARPLANNING_COMPANY_FINISH",
  ADD_CIRCULARPLANNING_COMPANY_SUCCESS: "ADD_CIRCULARPLANNING_COMPANY_SUCCESS",

  // updated company
  UPDATED_CIRCULARPLANNING_COMPANY: "UPDATED_CIRCULARPLANNING_COMPANY",
  UPDATED_CIRCULARPLANNING_COMPANY_INIT:
    "UPDATED_CIRCULARPLANNING_COMPANY_INIT",
  UPDATED_CIRCULARPLANNING_COMPANY_FINISH:
    "UPDATED_CIRCULARPLANNING_COMPANY_FINISH",
  UPDATED_CIRCULARPLANNING_COMPANY_SUCCESS:
    "UPDATED_CIRCULARPLANNING_COMPANY_SUCCESS",

  // company email add
  ADD_COMPANY_EMAIL: "ADD_COMPANY_EMAIL",
  ADD_COMPANY_EMAIL_INIT: "ADD_COMPANY_EMAIL_INIT",
  ADD_COMPANY_EMAIL_SUCCESS: "ADD_COMPANY_EMAIL_SUCCESS",
  ADD_COMPANY_EMAIL_FINISH: "ADD_COMPANY_EMAIL_FINISH",

  // import
  ADD_IMPORT_INTO_REFERENCE_COMPANY: "ADD_IMPORT_INTO_REFERENCE_COMPANY",
  ADD_IMPORT_INTO_REFERENCE_COMPANY_INIT:
    "ADD_IMPORT_INTO_REFERENCE_COMPANY_INIT",
  ADD_IMPORT_INTO_REFERENCE_COMPANY_FINISH:
    "ADD_IMPORT_INTO_REFERENCE_COMPANY_FINISH",
  ADD_IMPORT_INTO_REFERENCE_COMPANY_SUCCESS:
    "ADD_IMPORT_INTO_REFERENCE_COMPANY_SUCCESS",

  // circularlogs
  GET_CIRCULAR_PLANNING_LOG: "GET_CIRCULAR_PLANNING_LOG",
  GET_CIRCULAR_PLANNING_LOG_INIT: "GET_CIRCULAR_PLANNING_LOG_INIT",
  GET_CIRCULAR_PLANNING_LOG_SUCCESS: "GET_CIRCULAR_PLANNING_LOG_SUCCESS",
  GET_CIRCULAR_PLANNING_LOG_FINISH: "GET_CIRCULAR_PLANNING_LOG_FINISH",
  GET_CIRCULAR_PLANNING_LOG_MORE: "GET_CIRCULAR_PLANNING_LOG_MORE",
  GET_CIRCULAR_PLANNING_LOG_MORE_SUCCESS:
    "GET_CIRCULAR_PLANNING_LOG_MORE_SUCCESS",
};

export const MEETING_TYPES = {
  // Meeting Events
  MEETING_LISTS: "MEETING_LISTS",
  MEETING_LISTS_INIT: "MEETING_LISTS_INIT",
  MEETING_LISTS_FINISH: "MEETING_LISTS_FINISH",
  MEETING_LISTS_SUCCESS: "MEETING_LISTS_SUCCESS",

  // Meeting Events
  ADD_MEETING_EVENT: "ADD_MEETING_EVENT",
  ADD_MEETING_EVENT_INIT: "ADD_MEETING_EVENT_INIT",
  ADD_MEETING_EVENT_FINISH: "ADD_MEETING_EVENT_FINISH",
  ADD_MEETING_EVENT_SUCCESS: "ADD_MEETING_EVENT_SUCCESS",

  // Meeting Events
  MEETING_COMPLETE_EVENT: "MEETING_COMPLETE_EVENT",
  MEETING_COMPLETE_EVENT_INIT: "MEETING_COMPLETE_EVENT_INIT",
  MEETING_COMPLETE_EVENT_FINISH: "MEETING_COMPLETE_EVENT_FINISH",
  MEETING_COMPLETE_EVENT_SUCCESS: "MEETING_COMPLETE_EVENT_SUCCESS",

  MEETING_EVENT_AGENDA_DECISION_LIST: "MEETING_EVENT_AGENDA_DECISION_LIST",
  MEETING_EVENT_AGENDA_DECISION_LIST_INIT:
    "MEETING_EVENT_AGENDA_DECISION_LIST_INIT",
  MEETING_EVENT_AGENDA_DECISION_LIST_FINISH:
    "MEETING_EVENT_AGENDA_DECISION_LIST_FINISH",
  MEETING_EVENT_AGENDA_DECISION_LIST_SUCCESS:
    "MEETING_EVENT_AGENDA_DECISION_LIST_SUCCESS",

  // Meeting agenda
  ADD_MEETING_AGENDA: "ADD_MEETING_AGENDA",
  ADD_MEETING_AGENDA_INIT: "ADD_MEETING_AGENDA_INIT",
  ADD_MEETING_AGENDA_FINISH: "ADD_MEETING_AGENDA_FINISH",
  ADD_MEETING_AGENDA_SUCCESS: "ADD_MEETING_AGENDA_SUCCESS",

  // Remove Meeting agenda
  DELETE_MEETING_AGENDA: "DELETE_MEETING_AGENDA",
  DELETE_MEETING_AGENDA_INIT: "DELETE_MEETING_AGENDA_INIT",
  DELETE_MEETING_AGENDA_FINISH: "DELETE_MEETING_AGENDA_FINISH",
  DELETE_MEETING_AGENDA_SUCCESS: "DELETE_MEETING_AGENDA_SUCCESS",

  // Toggle Edit Meeting agenda
  TOGGLE_EDIT_MEETING_AGENDA: "TOGGLE_EDIT_MEETING_AGENDA",
  TOGGLE_EDIT_MEETING_AGENDA_INIT: "TOGGLE_EDIT_MEETING_AGENDA_INIT",
  TOGGLE_EDIT_MEETING_AGENDA_FINISH: "TOGGLE_EDIT_MEETING_AGENDA_FINISH",
  TOGGLE_EDIT_MEETING_AGENDA_SUCCESS: "TOGGLE_EDIT_MEETING_AGENDA_SUCCESS",
};

export const MEETING_AGENDA_TYPES = {
  // Meeting agenda add
  ADD_MEETING_AGENDA: "ADD_MEETING_AGENDA",
  ADD_MEETING_AGENDA_INIT: "ADD_MEETING_AGENDA_INIT",
  ADD_MEETING_AGENDA_FINISH: "ADD_MEETING_AGENDA_FINISH",
  ADD_MEETING_AGENDA_SUCCESS: "ADD_MEETING_AGENDA_SUCCESS",

  // Toggle Edit Meeting agenda
  MEETING_AGENDA_UPDATE: "MEETING_AGENDA_UPDATE",
  MEETING_AGENDA_UPDATE_INIT: "MEETING_AGENDA_UPDATE_INIT",
  MEETING_AGENDA_UPDATE_FINISH: "MEETING_AGENDA_UPDATE_FINISH",
  MEETING_AGENDA_UPDATE_SUCCESS: "MEETING_AGENDA_UPDATE_SUCCESS",
};

export const MEETING_DECISION_TYPES = {
  // Meeting decision
  ADD_DECISION_AGENDA: "ADD_DECISION_AGENDA",
  ADD_DECISION_AGENDA_INIT: "ADD_DECISION_AGENDA_INIT",
  ADD_DECISION_AGENDA_FINISH: "ADD_DECISION_AGENDA_FINISH",
  ADD_DECISION_AGENDA_SUCCESS: "ADD_DECISION_AGENDA_SUCCESS",

  // Edit Meeting agenda
  MEETING_DECISION_UPDATE: "MEETING_DECISION_UPDATE",
  MEETING_DECISION_UPDATE_INIT: "MEETING_DECISION_UPDATE_INIT",
  MEETING_DECISION_UPDATE_FINISH: "MEETING_DECISION_UPDATE_FINISH",
  MEETING_DECISION_UPDATE_SUCCESS: "MEETING_DECISION_UPDATE_SUCCESS",
};

export const ADMIN_TYPES = {
  // Sister Company
  SISTER_COMPANY_LISTS: "SISTER_COMPANY_LISTS",
  SISTER_COMPANY_LISTS_INIT: "SISTER_COMPANY_LISTS_INIT",
  SISTER_COMPANY_LISTS_FINISH: "SISTER_COMPANY_LISTS_FINISH",
  SISTER_COMPANY_LISTS_SUCCESS: "SISTER_COMPANY_LISTS_SUCCESS",

  SISTER_COMPANY_PAGINATION: "SISTER_COMPANY_PAGINATION",
  SISTER_COMPANY_PAGINATION_INIT: "SISTER_COMPANY_PAGINATION_INIT",
  SISTER_COMPANY_PAGINATION_FINISH: "SISTER_COMPANY_PAGINATION_FINISH",
  SISTER_COMPANY_PAGINATION_SUCCESS: "SISTER_COMPANY_PAGINATION_SUCCESS",

  SISTER_COMPANY_UPDATE: "SISTER_COMPANY_UPDATE",
  SISTER_COMPANY_UPDATE_INIT: "SISTER_COMPANY_UPDATE_INIT",
  SISTER_COMPANY_UPDATE_FINISH: "SISTER_COMPANY_UPDATE_FINISH",
  SISTER_COMPANY_UPDATE_SUCCESS: "SISTER_COMPANY_UPDATE_SUCCESS",

  // Meeting Status
  MEETING_STATUS_LISTING: "MEETING_STATUS_LISTING",
  MEETING_STATUS_LISTING_INIT: "MEETING_STATUS_LISTING_INIT",
  MEETING_STATUS_LISTING_FINISH: "MEETING_STATUS_LISTING_FINISH",
  MEETING_STATUS_LISTING_SUCCESS: "MEETING_STATUS_LISTING_SUCCESS",

  // Fiscal Year
  FISCAL_YEAR_PAGINATION: "FISCAL_YEAR_PAGINATION",
  FISCAL_YEAR_PAGINATION_INIT: "FISCAL_YEAR_PAGINATION_INIT",
  FISCAL_YEAR_PAGINATION_FINISH: "FISCAL_YEAR_PAGINATION_FINISH",
  FISCAL_YEAR_PAGINATION_SUCCESS: "FISCAL_YEAR_PAGINATION_SUCCESS",

  FISCAL_YEAR_ADD_UPDATE: "FISCAL_YEAR_ADD_UPDATE",
  FISCAL_YEAR_ADD_UPDATE_INIT: "FISCAL_YEAR_ADD_UPDATE_INIT",
  FISCAL_YEAR_ADD_UPDATE_FINISH: "FISCAL_YEAR_ADD_UPDATE_FINISH",
  FISCAL_YEAR_ADD_UPDATE_SUCCESS: "FISCAL_YEAR_ADD_UPDATE_SUCCESS",

  FISCAL_YEAR_LISTING_WITH_STATUS: "FISCAL_YEAR_LISTING_WITH_STATUS",
  FISCAL_YEAR_LISTING_WITH_STATUS_INIT: "FISCAL_YEAR_LISTING_WITH_STATUS_INIT",
  FISCAL_YEAR_LISTING_WITH_STATUS_FINISH:
    "FISCAL_YEAR_LISTING_WITH_STATUS_FINISH",
  FISCAL_YEAR_LISTING_WITH_STATUS_SUCCESS:
    "FISCAL_YEAR_LISTING_WITH_STATUS_SUCCESS",

  // Leave Application
  LEAVE_FISCAL_DAYS_PAGINATION: "LEAVE_FISCAL_DAYS_PAGINATION",
  LEAVE_FISCAL_DAYS_PAGINATION_INIT: "LEAVE_FISCAL_DAYS_PAGINATION_INIT",
  LEAVE_FISCAL_DAYS_PAGINATION_FINISH: "LEAVE_FISCAL_DAYS_PAGINATION_FINISH",
  LEAVE_FISCAL_DAYS_PAGINATION_SUCCESS: "LEAVE_FISCAL_DAYS_PAGINATION_SUCCESS",

  LEAVE_FISCAL_DAYS_ADD: "LEAVE_FISCAL_DAYS_ADD",
  LEAVE_FISCAL_DAYS_ADD_INIT: "LEAVE_FISCAL_DAYS_ADD_INIT",
  LEAVE_FISCAL_DAYS_ADD_FINISH: "LEAVE_FISCAL_DAYS_ADD_FINISH",
  LEAVE_FISCAL_DAYS_ADD_SUCCESS: "LEAVE_FISCAL_DAYS_ADD_SUCCESS",

  LEAVE_FISCAL_DAYS_UPDATE: "LEAVE_FISCAL_DAYS_UPDATE",
  LEAVE_FISCAL_DAYS_UPDATE_INIT: "LEAVE_FISCAL_DAYS_UPDATE_INIT",
  LEAVE_FISCAL_DAYS_UPDATE_FINISH: "LEAVE_FISCAL_DAYS_UPDATE_FINISH",
  LEAVE_FISCAL_DAYS_UPDATE_SUCCESS: "LEAVE_FISCAL_DAYS_UPDATE_SUCCESS",

  // Leave Application Type
  LEAVE_APPLICATION_TYPE: "LEAVE_APPLICATION_TYPE",
  LEAVE_APPLICATION_TYPE_INIT: "LEAVE_APPLICATION_TYPE_INIT",
  LEAVE_APPLICATION_TYPE_FINISH: "LEAVE_APPLICATION_TYPE_FINISH",
  LEAVE_APPLICATION_TYPE_SUCCESS: "LEAVE_APPLICATION_TYPE_SUCCESS",

  LEAVE_APPLICATION_TYPE_ADD: "LEAVE_APPLICATION_TYPE_ADD",
  LEAVE_APPLICATION_TYPE_ADD_INIT: "LEAVE_APPLICATION_TYPE_ADD_INIT",
  LEAVE_APPLICATION_TYPE_ADD_FINISH: "LEAVE_APPLICATION_TYPE_ADD_FINISH",
  LEAVE_APPLICATION_TYPE_ADD_SUCCESS: "LEAVE_APPLICATION_TYPE_ADD_SUCCESS",

  LEAVE_APPLICATION_TYPE_UPDATE: "LEAVE_APPLICATION_TYPE_UPDATE",
  LEAVE_APPLICATION_TYPE_UPDATE_INIT: "LEAVE_APPLICATION_TYPE_UPDATE_INIT",
  LEAVE_APPLICATION_TYPE_UPDATE_FINISH: "LEAVE_APPLICATION_TYPE_UPDATE_FINISH",
  LEAVE_APPLICATION_TYPE_UPDATE_SUCCESS:
    "LEAVE_APPLICATION_TYPE_UPDATE_SUCCESS",

  //email share listing
  EMAIL_SHARE_LISTING: "EMAIL_SHARE_LISTING",
  EMAIL_SHARE_LISTING_INIT: "EMAIL_SHARE_LISTING_INIT",
  EMAIL_SHARE_LISTING_SUCCESS: "EMAIL_SHARE_LISTING_SUCCESS",

  CORPORATE_EMAIL_ADD: "CORPORATE_EMAIL_ADD",
  CORPORATE_EMAIL_ADD_INIT: "CORPORATE_EMAIL_ADD_INIT",
  CORPORATE_EMAIL_ADD_SUCCESS: "CORPORATE_EMAIL_ADD_SUCCESS",

  EMAIL_SHARE_GET_DETAILS: "EMAIL_SHARE_GET_DETAILS",
  EMAIL_SHARE_GET_DETAILS_INIT: "EMAIL_SHARE_GET_DETAILS_INIT",
  EMAIL_SHARE_GET_DETAILS_SUCCESS: "EMAIL_SHARE_GET_DETAILS_SUCCESS",

  //email share permission
  CORPORATE_EMAIL_SHARE: "CORPORATE_EMAIL_SHARE",
  CORPORATE_EMAIL_SHARE_INIT: "CORPORATE_EMAIL_SHARE_INIT",
  CORPORATE_EMAIL_SHARE_SUCCESS: "CORPORATE_EMAIL_SHARE_SUCCESS",

  //dashboard financial Info
  DASHBOARD_FINANCIAL_INFO: "DASHBOARD_FINANCIAL_INFO",
  DASHBOARD_FINANCIAL_INFO_INIT: "DASHBOARD_FINANCIAL_INFO_INIT",
  DASHBOARD_FINANCIAL_INFO_SUCCESS: "DASHBOARD_FINANCIAL_INFO_SUCCESS",
  DASHBOARD_FINANCIAL_INFO_FINISH: "DASHBOARD_FINANCIAL_INFO_FINISH",

  //dashboard budget Income Expense
  BUDGET_INCOME_EXPENSE_INFO: "BUDGET_INCOME_EXPENSE_INFO",
  BUDGET_INCOME_EXPENSE_INFO_INIT: "BUDGET_INCOME_EXPENSE_INFO_INIT",
  BUDGET_INCOME_EXPENSE_INFO_SUCCESS: "BUDGET_INCOME_EXPENSE_INFO_SUCCESS",
  BUDGET_INCOME_EXPENSE_INFO_FINISH: "BUDGET_INCOME_EXPENSE_INFO_FINISH",

  //getAllUsersListing
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ALL_USERS_INIT: "GET_ALL_USERS_INIT",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FINISH: "GET_ALL_USERS_FINISH",

  // getAllRolesUserListing:
  GET_ALL_ROLES_USERS: "GET_ALL_ROLES_USERS",
  GET_ALL_ROLES_USERS_INIT: "GET_ALL_ROLES_USERS_INIT",
  GET_ALL_ROLES_USERS_SUCCESS: "GET_ALL_ROLES_USERS_SUCCESS",
  GET_ALL_ROLES_USERS_FINISH: "GET_ALL_ROLES_USERS_FINISH",

  // addupdate Roles in hr module
  UPDATE_ALL_ROLLS_USERS: "UPDATE_ALL_ROLLS_USERS",
  UPDATE_ALL_ROLLS_USERS_INIT: "UPDATE_ALL_ROLLS_USERS_INIT",
  UPDATE_ALL_ROLLS_USERS_SUCCESS: "UPDATE_ALL_ROLLS_USERS_SUCCESS",
  UPDATE_ALL_ROLLS_USERS_FINISH: "UPDATE_ALL_ROLLS_USERS_FINISH",

  //dashboard budget details
  ACCOUNT_BUDGET_EXPENSE: "ACCOUNT_BUDGET_EXPENSE",
  ACCOUNT_BUDGET_EXPENSE_INIT: "ACCOUNT_BUDGET_EXPENSE_INIT",
  ACCOUNT_BUDGET_EXPENSE_SUCCESS: "ACCOUNT_BUDGET_EXPENSE_SUCCESS",
  ACCOUNT_BUDGET_EXPENSE_FINISH: "ACCOUNT_BUDGET_EXPENSE_FINISH",

  //dashboard income details
  ACCOUNT_INCOME: "ACCOUNT_INCOME",
  ACCOUNT_INCOME_INIT: "ACCOUNT_INCOME_INIT",
  ACCOUNT_INCOME_SUCCESS: "ACCOUNT_INCOME_SUCCESS",
  ACCOUNT_INCOME_FINISH: "ACCOUNT_INCOME_FINISH",

  //dashboard income details
  ACCOUNT_LEDGER: "ACCOUNT_LEDGER",
  ACCOUNT_LEDGER_INIT: "ACCOUNT_LEDGER_INIT",
  ACCOUNT_LEDGER_SUCCESS: "ACCOUNT_LEDGER_SUCCESS",
  ACCOUNT_LEDGER_FINISH: "ACCOUNT_LEDGER_FINISH",

  //cashflow
  ACCOUNT_CASHFLOW: "ACCOUNT_CASHFLOW",
  ACCOUNT_CASHFLOW_INIT: "ACCOUNT_CASHFLOW_INIT",
  ACCOUNT_CASHFLOW_SUCCESS: "ACCOUNT_CASHFLOW_SUCCESS",
  ACCOUNT_CASHFLOW_FINISH: "ACCOUNT_CASHFLOW_FINISH",

  //dashboardFeeupdate
  DASHBOARD_FEE_UPDATE: "DASHBOARD_FEE_UPDATE",
  DASHBOARD_FEE_UPDATE_INIT: "DASHBOARD_FEE_UPDATE_INIT",
  DASHBOARD_FEE_UPDATE_SUCCESS: "DASHBOARD_FEE_UPDATE_SUCCESS",
  DASHBOARD_FEE_UPDATE_FINISH: "DASHBOARD_FEE_UPDATE_FINISH",

  //get fee category
  FEE_CATEGORY_LISTING: "FEE_CATEGORY_LISTING",
  FEE_CATEGORY_LISTING_INIT: "FEE_CATEGORY_LISTING_INIT",
  FEE_CATEGORY_LISTING_SUCCESS: "FEE_CATEGORY_LISTING_SUCCESS",
  FEE_CATEGORY_LISTING_FINISH: "FEE_CATEGORY_LISTING_FINISH",

  // Tutorial (Youtube)
  TUTORIAL_TYPE_LISTING: "TUTORIAL_TYPE_LISTING",
  TUTORIAL_TYPE_LISTING_INIT: "TUTORIAL_TYPE_LISTING_INIT",
  TUTORIAL_TYPE_LISTING_SUCCESS: "TUTORIAL_TYPE_LISTING_SUCCESS",
  TUTORIAL_TYPE_LISTING_FINISH: "TUTORIAL_TYPE_LISTING_FINISH",

  TUTORIAL_TYPE_ADD: "TUTORIAL_TYPE_ADD",
  TUTORIAL_TYPE_ADD_INIT: "TUTORIAL_TYPE_ADD_INIT",
  TUTORIAL_TYPE_ADD_SUCCESS: "TUTORIAL_TYPE_ADD_SUCCESS",
  TUTORIAL_TYPE_ADD_FINISH: "TUTORIAL_TYPE_ADD_FINISH",

  TUTORIAL_TYPE_UPDATE: "TUTORIAL_TYPE_UPDATE",
  TUTORIAL_TYPE_UPDATE_INIT: "TUTORIAL_TYPE_UPDATE_INIT",
  TUTORIAL_TYPE_UPDATE_SUCCESS: "TUTORIAL_TYPE_UPDATE_SUCCESS",
  TUTORIAL_TYPE_UPDATE_FINISH: "TUTORIAL_TYPE_UPDATE_FINISH",

  TUTORIAL_CATEGORY_LISTING: "TUTORIAL_CATEGORY_LISTING",
  TUTORIAL_CATEGORY_LISTING_INIT: "TUTORIAL_CATEGORY_LISTING_INIT",
  TUTORIAL_CATEGORY_LISTING_SUCCESS: "TUTORIAL_CATEGORY_LISTING_SUCCESS",
  TUTORIAL_CATEGORY_LISTING_FINISH: "TUTORIAL_CATEGORY_LISTING_FINISH",

  TUTORIAL_CATEGORY_ADD: "TUTORIAL_CATEGORY_ADD",
  TUTORIAL_CATEGORY_ADD_INIT: "TUTORIAL_CATEGORY_ADD_INIT",
  TUTORIAL_CATEGORY_ADD_SUCCESS: "TUTORIAL_CATEGORY_ADD_SUCCESS",
  TUTORIAL_CATEGORY_ADD_FINISH: "TUTORIAL_CATEGORY_ADD_FINISH",

  TUTORIAL_CATEGORY_UPDATE: "TUTORIAL_CATEGORY_UPDATE",
  TUTORIAL_CATEGORY_UPDATE_INIT: "TUTORIAL_CATEGORY_UPDATE_INIT",
  TUTORIAL_CATEGORY_UPDATE_SUCCESS: "TUTORIAL_CATEGORY_UPDATE_SUCCESS",
  TUTORIAL_CATEGORY_UPDATE_FINISH: "TUTORIAL_CATEGORY_UPDATE_FINISH",

  TUTORIAL_CATEGORY_DETAIL: "TUTORIAL_CATEGORY_DETAIL",
  TUTORIAL_CATEGORY_DETAIL_INIT: "TUTORIAL_CATEGORY_DETAIL_INIT",
  TUTORIAL_CATEGORY_DETAIL_SUCCESS: "TUTORIAL_CATEGORY_DETAIL_SUCCESS",
  TUTORIAL_CATEGORY_DETAIL_FINISH: "TUTORIAL_CATEGORY_DETAIL_FINISH",

  WHITELIST_IPADDRESS_LISTING: "WHITELIST_IPADDRESS_LISTING",
  WHITELIST_IPADDRESS_LISTING_INIT: "WHITELIST_IPADDRESS_LISTING_INIT",
  WHITELIST_IPADDRESS_LISTING_SUCCESS: "WHITELIST_IPADDRESS_LISTING_SUCCESS",
  WHITELIST_IPADDRESS_LISTING_FINISH: "WHITELIST_IPADDRESS_LISTING_FINISH",

  WHITELIST_IPADDRESS_UPDATE: "WHITELIST_IPADDRESS_UPDATE",
  WHITELIST_IPADDRESS_UPDATE_INIT: "WHITELIST_IPADDRESS_UPDATE_INIT",
  WHITELIST_IPADDRESS_UPDATE_SUCCESS: "WHITELIST_IPADDRESS_UPDATE_SUCCESS",
  WHITELIST_IPADDRESS_UPDATE_FINISH: "WHITELIST_IPADDRESS_UPDATE_FINISH",

  WHITELIST_IPADDRESS_ADD: "WHITELIST_IPADDRESS_ADD",
  WHITELIST_IPADDRESS_ADD_INIT: "WHITELIST_IPADDRESS_ADD_INIT",
  WHITELIST_IPADDRESS_ADD_SUCCESS: "WHITELIST_IPADDRESS_ADD_SUCCESS",
  WHITELIST_IPADDRESS_ADD_FINISH: "WHITELIST_IPADDRESS_ADD_FINISH",
};

export const CALENDAR_EVENTS_TYPES = {
  // Calendar Events
  CALENDAR_EVENTS_LISTS: "CALENDAR_EVENTS_LISTS",
  CALENDAR_EVENTS_LISTS_INIT: "CALENDAR_EVENTS_LISTS_INIT",
  CALENDAR_EVENTS_LISTS_FINISH: "CALENDAR_EVENTS_LISTS_FINISH",
  CALENDAR_EVENTS_LISTS_SUCCESS: "CALENDAR_EVENTS_LISTS_SUCCESS",

  // Calendar Filter Events
  CALENDAR_FILTERS: "CALENDAR_FILTERS",
  CALENDAR_FILTERS_INIT: "CALENDAR_FILTERS_INIT",
  CALENDAR_FILTERS_FINISH: "CALENDAR_FILTERS_FINISH",
  CALENDAR_FILTERS_SUCCESS: "CALENDAR_FILTERS_SUCCESS",

  // Update Calendar Filter Events
  UPDATE_CALENDAR_EVENT: "UPDATE_CALENDAR_EVENT",
  UPDATE_CALENDAR_EVENT_INIT: "UPDATE_CALENDAR_EVENT_INIT",
  UPDATE_CALENDAR_EVENT_FINISH: "UPDATE_CALENDAR_EVENT_FINISH",
  UPDATE_CALENDAR_EVENT_SUCCESS: "UPDATE_CALENDAR_EVENT_SUCCESS",

  // calendar event pagination

  CALENDAR_EVENT_PAGINATION: "CALENDAR_EVENT_PAGINATION",
  CALENDAR_EVENT_PAGINATION_INIT: "CALENDAR_EVENT_PAGINATION_INIT",
  CALENDAR_EVENT_PAGINATION_FINISH: "CALENDAR_EVENT_PAGINATION_FINISH",
  CALENDAR_EVENT_PAGINATION_SUCCESS: "CALENDAR_EVENT_PAGINATION_SUCCESS",

  //english to nepali date converter
  ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER:
    "ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER",
  ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER_INIT:
    "ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER_INIT",
  ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER_SUCCESS:
    "ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER_SUCCESS",
  ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER_FINISH:
    "ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER_FINISH",

  //Appointment
  CALENDAR_PRIVATE_EVENTS_LISTS: "CALENDAR_PRIVATE_EVENTS_LISTS",
  CALENDAR_PRIVATE_EVENTS_LISTS_INIT: "CALENDAR_PRIVATE_EVENTS_LISTS_INIT",
  CALENDAR_PRIVATE_EVENTS_LISTS_SUCCESS:
    "CALENDAR_PRIVATE_EVENTS_LISTS_SUCCESS",
  CALENDAR_PRIVATE_EVENTS_LISTS_FINISH: "CALENDAR_PRIVATE_EVENTS_LISTS_FINISH",

  //Public Events
  CALENDAR_PUBLIC_EVENTS_LISTS: "CALENDAR_PUBLIC_EVENTS_LISTS",
  CALENDAR_PUBLIC_EVENTS_LISTS_INIT: "CALENDAR_PUBLIC_EVENTS_LISTS_INIT",
  CALENDAR_PUBLIC_EVENTS_LISTS_SUCCESS: "CALENDAR_PUBLIC_EVENTS_LISTS_SUCCESS",
  CALENDAR_PUBLIC_EVENTS_LISTS_FINISH: "CALENDAR_PUBLIC_EVENTS_LISTS_FINISH",
};

//office resource type
export const OFFICE_RESOURCE_TYPES = {
  honorRollsPagination: "honorRollsPagination",
  honorRollsPagination_INIT: "honorRollsPagination_INIT",
  honorRollsPagination_FINISH: "honorRollsPagination_FINISH",
  honorRollsPagination_SUCCESS: "honorRollsPagination_SUCCESS",

  honorRollAdd: "honorRollAdd",
  honorRollAdd_INIT: "honorRollAdd_INIT",
  honorRollAdd_FINISH: "honorRollAdd_FINISH",
  honorRollAdd_SUCCESS: "honorRollAdd_SUCCESS",

  honorRollUpdate: "honorRollUpdate",
  honorRollUpdate_INIT: "honorRollUpdate_INIT",
  honorRollUpdate_FINISH: "honorRollUpdate_FINISH",
  honorRollUpdate_SUCCESS: "honorRollUpdate_SUCCESS",
};

// common
export const DYNAMIC_FILE_TYPES = {
  DYNAMIC_FILE_LABEL_TYPES: "DYNAMIC_FILE_LABEL_TYPES",
  DYNAMIC_FILE_LABEL_TYPES_ALL: "DYNAMIC_FILE_LABEL_TYPES_ALL",
  DYNAMIC_FILE_LABEL_TYPES_INIT: "DYNAMIC_FILE_LABEL_TYPES_INIT",
  DYNAMIC_FILE_LABEL_TYPES_FINISH: "DYNAMIC_FILE_LABEL_TYPES_FINISH",
  DYNAMIC_FILE_LABEL_TYPES_SUCCESS: "DYNAMIC_FILE_LABEL_TYPES_SUCCESS",
  DYNAMIC_FILE_LABEL_TYPES_ALL_SUCCESS: "DYNAMIC_FILE_LABEL_TYPES_ALL_SUCCESS",
};

export const APPLICATION_CONFIGURATION = {
  GET_APPLICATION_CONFIGURATION: "GET_APPLICATION_CONFIGURATION",
  GET_APPLICATION_CONFIGURATION_INIT: "GET_APPLICATION_CONFIGURATION_INIT",
  GET_APPLICATION_CONFIGURATION_FINISH: "GET_APPLICATION_CONFIGURATION_FINISH",
  GET_APPLICATION_CONFIGURATION_SUCCESS:
    "GET_APPLICATION_CONFIGURATION_SUCCESS",
};

// admin settings
export const ADMIN_SETTINGS_TYPES = {
  ADMIN_SETTINGS: "ADMIN_SETTINGS",
  ADMIN_SETTINGS_INIT: "ADMIN_SETTINGS_INIT",
  ADMIN_SETTINGS_FINISH: "ADMIN_SETTINGS_FINISH",
  ADMIN_SETTINGS_SUCCESS: "ADMIN_SETTINGS_SUCCESS",

  // add new role
  ADD_NEW_ROLE: "ADD_NEW_ROLE",
  ADD_NEW_ROLE_INIT: "ADD_NEW_ROLE_INIT",
  ADD_NEW_ROLE_FINISH: "ADD_NEW_ROLE_FINISH",
  ADD_NEW_ROLE_SUCCESS: "ADD_NEW_ROLE_SUCCESS",

  // update role
  UPDATE_ROLE: "UPDATE_ROLE",
  UPDATE_ROLE_INIT: "UPDATE_ROLE_INIT",
  UPDATE_ROLE_FINISH: "UPDATE_ROLE_FINISH",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",

  // update role (working)
  NEW_UPDATE_ROLE: "NEW_UPDATE_ROLE",
  NEW_UPDATE_ROLE_INIT: "NEW_UPDATE_ROLE_INIT",
  NEW_UPDATE_ROLE_FINISH: "NEW_UPDATE_ROLE_FINISH",
  NEW_UPDATE_ROLE_SUCCESS: "NEW_UPDATE_ROLE_SUCCESS",

  // permission lists for role
  PERMISSION_LISTS_FOR_ROLE: "PERMISSION_LISTS_FOR_ROLE",
  PERMISSION_LISTS_FOR_ROLE_INIT: "PERMISSION_LISTS_FOR_ROLE_INIT",
  PERMISSION_LISTS_FOR_ROLE_FINISH: "PERMISSION_LISTS_FOR_ROLE_FINISH",
  PERMISSION_LISTS_FOR_ROLE_SUCCESS: "PERMISSION_LISTS_FOR_ROLE_SUCCESS",

  // grant or prohibit permission lists for role
  GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE:
    "GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE",
  GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE_INIT:
    "GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE_INIT",
  GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE_FINISH:
    "GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE_FINISH",
  GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE_SUCCESS:
    "GRANT_OR_PROHIBIT_PERMISSION_LISTS_FOR_ROLE_SUCCESS",

  // organization layer
  ORGANIZATION_LAYER_LISTING: "ORGANIZATION_LAYER_LISTING",
  ORGANIZATION_LAYER_LISTING_INIT: "ORGANIZATION_LAYER_LISTING_INIT",
  ORGANIZATION_LAYER_LISTING_FINISH: "ORGANIZATION_LAYER_LISTING_FINISH",
  ORGANIZATION_LAYER_LISTING_SUCCESS: "ORGANIZATION_LAYER_LISTING_SUCCESS",

  // organization layer type
  ORGANIZATION_LAYER_TYPE_LISTING: "ORGANIZATION_LAYER_TYPE_LISTING",
  ORGANIZATION_LAYER_TYPE_LISTING_INIT: "ORGANIZATION_LAYER_TYPE_LISTING_INIT",
  ORGANIZATION_LAYER_TYPE_LISTING_FINISH:
    "ORGANIZATION_LAYER_TYPE_LISTING_FINISH",
  ORGANIZATION_LAYER_TYPE_LISTING_SUCCESS:
    "ORGANIZATION_LAYER_TYPE_LISTING_SUCCESS",
};

// mail message
export const EMAIL_TYPES = {
  MAIL_COMPOSE_RESPONSE_CHILD: "MAIL_COMPOSE_RESPONSE_CHILD",

  GET_SHARED_EMAIL_LIST: "GET_SHARED_EMAIL_LIST",
  GET_SHARED_EMAIL_LIST_INIT: "GET_SHARED_EMAIL_LIST_INIT",
  GET_SHARED_EMAIL_LIST_SUCCESS: "GET_SHARED_EMAIL_LIST_SUCCESS",

  //get all shared lists
  GET_ALL_SHARED_EMAIL_LIST: "GET_ALL_SHARED_EMAIL_LIST",
  GET_ALL_SHARED_EMAIL_LIST_INIT: "GET_ALL_SHARED_EMAIL_LIST_INIT",
  GET_ALL_SHARED_EMAIL_LIST_SUCCESS: "GET_ALL_SHARED_EMAIL_LIST_SUCCESS",

  PARTICIPANT_TYPE_LISTING: "PARTICIPANT_TYPE_LISTING",
  PARTICIPANT_TYPE_LISTING_INIT: "PARTICIPANT_TYPE_LISTING_INIT",
  PARTICIPANT_TYPE_LISTING_FINISH: "PARTICIPANT_TYPE_LISTING_FINISH",
  PARTICIPANT_TYPE_LISTING_SUCCESS: "PARTICIPANT_TYPE_LISTING_SUCCESS",

  // mail message center pagination
  MAIL_MESSAGE_CENTER_PAGINATION: "MAIL_MESSAGE_CENTER_PAGINATION",
  MAIL_MESSAGE_CENTER_PAGINATION_INIT: "MAIL_MESSAGE_CENTER_PAGINATION_INIT",
  MAIL_MESSAGE_CENTER_PAGINATION_FINISH:
    "MAIL_MESSAGE_CENTER_PAGINATION_FINISH",
  MAIL_MESSAGE_CENTER_PAGINATION_SUCCESS:
    "MAIL_MESSAGE_CENTER_PAGINATION_SUCCESS",
  MAIL_MESSAGE_CENTER_PAGINATION_UPDATE:
    "MAIL_MESSAGE_CENTER_PAGINATION_UPDATE",

  // dashboard mail message center pagination
  MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD:
    "MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD",
  MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_INIT:
    "MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_INIT",
  MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_FINISH:
    "MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_FINISH",
  MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_SUCCESS:
    "MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_SUCCESS",
  MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_UPDATE:
    "MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_UPDATE",

  //mail message center body highlight
  MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT: "MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT",
  MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT_INIT:
    "MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT_INIT",
  MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT_SUCCESS:
    "MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT_SUCCESS",

  //infinite Scroll loader in Pagination
  MAIL_MESSAGE_INFINITE_PAGINATION: "MAIL_MESSAGE_INFINITE_PAGINATION",
  MAIL_MESSAGE_INFINITE_PAGINATION_INIT:
    "MAIL_MESSAGE_INFINITE_PAGINATION_INIT",
  MAIL_MESSAGE_INFINITE_PAGINATION_FINISH:
    "MAIL_MESSAGE_INFINITE_PAGINATION_FINISH",
  MAIL_MESSAGE_INFINITE_PAGINATION_SUCCESS:
    "MAIL_MESSAGE_INFINITE_PAGINATION_SUCCESS",
  MAIL_MESSAGE_INFINITE_PAGINATION_UPDATE:
    "MAIL_MESSAGE_INFINITE_PAGINATION_UPDATE",

  //error message Pagination
  MAIL_ERROR_PAGINATION: "MAIL_ERROR_PAGINATION",
  MAIL_ERROR_PAGINATION_INIT: "MAIL_ERROR_PAGINATION_INIT",
  MAIL_ERROR_PAGINATION_FINISH: "MAIL_ERROR_PAGINATION_FINISH",
  MAIL_ERROR_PAGINATION_SUCCESS: "MAIL_ERROR_PAGINATION_SUCCESS",
  MAIL_ERROR_PAGINATION_UPDATE: "MAIL_ERROR_PAGINATION_UPDATE",

  //ACTIVE EMAIL FROM PERSIST
  EMAIL_TYPE_ACTIVE: "EMAIL_TYPE_ACTIVE",

  // message center type
  MESSAGE_PARTICIPANT_STATUS_LISTING: "MESSAGE_PARTICIPANT_STATUS_LISTING",
  MESSAGE_PARTICIPANT_STATUS_LISTING_INIT:
    "MESSAGE_PARTICIPANT_STATUS_LISTING_INIT",
  MESSAGE_PARTICIPANT_STATUS_LISTING_FINISH:
    "MESSAGE_PARTICIPANT_STATUS_LISTING_FINISH",
  MESSAGE_PARTICIPANT_STATUS_LISTING_SUCCESS:
    "MESSAGE_PARTICIPANT_STATUS_LISTING_SUCCESS",

  // LISTING
  COMPANY_PERSON_EMAIL_LISTING: "COMPANY_PERSON_EMAIL_LISTING",
  COMPANY_PERSON_EMAIL_LISTING_INIT: "COMPANY_PERSON_EMAIL_LISTING_INIT",
  COMPANY_PERSON_EMAIL_LISTING_FINISH: "COMPANY_PERSON_EMAIL_LISTING_FINISH",
  COMPANY_PERSON_EMAIL_LISTING_SUCCESS: "COMPANY_PERSON_EMAIL_LISTING_SUCCESS",

  // instance message center pagination
  INSTANCE_MESSAGE_CENTER_PAGINATION: "INSTANCE_MESSAGE_CENTER_PAGINATION",
  INSTANCE_MESSAGE_CENTER_PAGINATION_INIT:
    "INSTANCE_MESSAGE_CENTER_PAGINATION_INIT",
  INSTANCE_MESSAGE_CENTER_PAGINATION_FINISH:
    "INSTANCE_MESSAGE_CENTER_PAGINATION_FINISH",
  INSTANCE_MESSAGE_CENTER_PAGINATION_SUCCESS:
    "INSTANCE_MESSAGE_CENTER_PAGINATION_SUCCESS",

  // instance message center pagination
  REPLY_INSTANCE_MESSAGE: "REPLY_INSTANCE_MESSAGE",
  REPLY_INSTANCE_MESSAGE_INIT: "REPLY_INSTANCE_MESSAGE_INIT",
  REPLY_INSTANCE_MESSAGE_FINISH: "REPLY_INSTANCE_MESSAGE_FINISH",
  REPLY_INSTANCE_MESSAGE_SUCCESS: "REPLY_INSTANCE_MESSAGE_SUCCESS",

  // instance message center delete
  REPLY_INSTANCE_MESSAGE_RESP_DELETE: "REPLY_INSTANCE_MESSAGE_RESP_DELETE",
  REPLY_INSTANCE_MESSAGE__UPDATE_RESP_DELETE:
    "REPLY_INSTANCE_MESSAGE__UPDATE_RESP_DELETE",

  // Quick Message
  COMPOSE_QUICK_MESSAGE: "COMPOSE_QUICK_MESSAGE",
  COMPOSE_QUICK_MESSAGE_INIT: "COMPOSE_QUICK_MESSAGE_INIT",
  COMPOSE_QUICK_MESSAGE_FINISH: "COMPOSE_QUICK_MESSAGE_FINISH",
  COMPOSE_QUICK_MESSAGE_SUCCESS: "COMPOSE_QUICK_MESSAGE_SUCCESS",

  // Instance Message
  INSTANCE_MESSAGE_UPDATE: "INSTANCE_MESSAGE_UPDATE",
  INSTANCE_MESSAGE_UPDATE_INIT: "INSTANCE_MESSAGE_UPDATE_INIT",
  INSTANCE_MESSAGE_UPDATE_FINISH: "INSTANCE_MESSAGE_UPDATE_FINISH",
  INSTANCE_MESSAGE_UPDATE_SUCCESS: "INSTANCE_MESSAGE_UPDATE_SUCCESS",

  // Participant Email Addresses
  PARTICIPANT_EMAIL_ADDRESSES_LISTING: "PARTICIPANT_EMAIL_ADDRESSES_LISTING",
  PARTICIPANT_EMAIL_ADDRESSES_LISTING_INIT:
    "PARTICIPANT_EMAIL_ADDRESSES_LISTING_INIT",
  PARTICIPANT_EMAIL_ADDRESSES_LISTING_FINISH:
    "PARTICIPANT_EMAIL_ADDRESSES_LISTING_FINISH",
  PARTICIPANT_EMAIL_ADDRESSES_LISTING_SUCCESS:
    "PARTICIPANT_EMAIL_ADDRESSES_LISTING_SUCCESS",

  // Compose Email
  COMPOSE_EMAIL: "COMPOSE_EMAIL",
  COMPOSE_EMAIL_INIT: "COMPOSE_EMAIL_INIT",
  COMPOSE_EMAIL_NOT_CLOSE_INIT: "COMPOSE_EMAIL_NOT_CLOSE_INIT",
  COMPOSE_EMAIL_FINISH: "COMPOSE_EMAIL_FINISH",
  COMPOSE_EMAIL_SUCCESS: "COMPOSE_EMAIL_SUCCESS",

  //compose circular email
  COMPOSE_EMAIL_CIRCULAR: "COMPOSE_EMAIL_CIRCULAR",
  COMPOSE_EMAIL_CIRCULAR_SUCCESS: "COMPOSE_EMAIL_CIRCULAR_SUCCESS",
  //draft messages
  DRAFT_MESSAGE: "DRAFT_MESSAGE",
  DRAFT_MESSAGE_INIT: "DRAFT_MESSAGE_INIT",
  // DRAFT_MESSAGE_NOT_CLOSE_INIT: "DRAFT_MESSAGE_NOT_CLOSE_INIT",
  DRAFT_MESSAGE_FINISH: "DRAFT_MESSAGE_FINISH",
  DRAFT_MESSAGE_SUCCESS: "DRAFT_MESSAGE_SUCCESS",

  // Email address from with behalf listing
  EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING:
    "EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING",
  EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING_INIT:
    "EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING_INIT",
  EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING_FINISH:
    "EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING_FINISH",
  EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING_SUCCESS:
    "EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING_SUCCESS",

  PARTICIPANT_STATUSES_CHANGE: "PARTICIPANT_STATUSES_CHANGE",
  PARTICIPANT_STATUSES_CHANGE_INIT: "PARTICIPANT_STATUSES_CHANGE_INIT",
  PARTICIPANT_STATUSES_CHANGE_FINISH: "PARTICIPANT_STATUSES_CHANGE_FINISH",
  PARTICIPANT_STATUSES_CHANGE_SUCCESS: "PARTICIPANT_STATUSES_CHANGE_SUCCESS",

  PARTICIPANT_MULTIPEL_STATUSES_CHANGE: "PARTICIPANT_MULTIPEL_STATUSES_CHANGE",
  PARTICIPANT_MULTIPEL_STATUSES_CHANGE_INIT:
    "PARTICIPANT_MULTIPEL_STATUSES_CHANGE_INIT",
  PARTICIPANT_MULTIPEL_STATUSES_CHANGE_SUCCESS:
    "PARTICIPANT_MULTIPEL_STATUSES_CHANGE_SUCCESS",
  PARTICIPANT_MULTIPEL_STATUSES_CHANGE_FINISH:
    "PARTICIPANT_MULTIPEL_STATUSES_CHANGE_FINISH",

  SELECTED_MAIL_TYPE_AND_MAIL: "SELECTED_MAIL_TYPE_AND_MAIL",
  SELECTED_MAIL_TYPE_AND_MAIL_INIT: "SELECTED_MAIL_TYPE_AND_MAIL_INIT",
  SELECTED_MAIL_TYPE_AND_MAIL_FINISH: "SELECTED_MAIL_TYPE_AND_MAIL_FINISH",
  SELECTED_MAIL_TYPE_AND_MAIL_SUCCESS: "SELECTED_MAIL_TYPE_AND_MAIL_SUCCESS",
  SELECTED_MAIL_TYPE_AND_MAIL_UPDATE: "SELECTED_MAIL_TYPE_AND_MAIL_UPDATE",

  CONTACT_TYPE_AND_COMPANY: "CONTACT_TYPE_AND_COMPANY",
  CONTACT_TYPE_AND_COMPANY_INIT: "CONTACT_TYPE_AND_COMPANY_INIT",
  CONTACT_TYPE_AND_COMPANY_FINISH: "CONTACT_TYPE_AND_COMPANY_FINISH",
  CONTACT_TYPE_AND_COMPANY_SUCCESS: "CONTACT_TYPE_AND_COMPANY_SUCCESS",
  CONTACT_TYPE_AND_COMPANY_UPDATE: "CONTACT_TYPE_AND_COMPANY_UPDATE",

  CONTACT_TYPE_REMOVE: "CONTACT_TYPE_REMOVE",

  // Refresh Email
  REFRESH_INCOMING_MAIL: "REFRESH_INCOMING_MAIL",
  REFRESH_INCOMING_MAIL_INIT: "REFRESH_INCOMING_MAIL_INIT",
  REFRESH_INCOMING_MAIL_FINISH: "REFRESH_INCOMING_MAIL_FINISH",
  REFRESH_INCOMING_MAIL_SUCCESS: "REFRESH_INCOMING_MAIL_SUCCESS",

  //quick email add internal
  QUICK_EMAIL_ADD_EMAIL: "QUICK_EMAIL_ADD_EMAIL",
  QUICK_EMAIL_ADD_EMAIL_INIT: "QUICK_EMAIL_ADD_EMAIL_INIT",
  QUICK_EMAIL_ADD_EMAIL_SUCCESS: "QUICK_EMAIL_ADD_EMAIL_SUCCESS",
  QUICK_EMAIL_ADD_EMAIL_FINISH: "QUICK_EMAIL_ADD_EMAIL_FINISH",
  //Add Refrence Tags
  MAIL_REFERENCE_TAGS_ADD: "MAIL_REFERENCE_TAGS_ADD",
  MAIL_REFERENCE_TAGS_ADD_INIT: "MAIL_REFERENCE_TAGS_INIT_ADD",
  MAIL_REFERENCE_TAGS_ADD_FINISH: "MAIL_REFERENCE_TAGS_FINISH_ADD",
  MAIL_REFERENCE_TAGS_ADD_SUCCESS: "MAIL_REFERENCE_TAGS_SUCCESS_ADD",

  //Reference Tags Listing
  MAIL_REFERENCE_TAGS_LISTING: "MAIL_REFERENCE_TAGS_LISTING",
  MAIL_REFERENCE_TAGS_LISTING_INIT: "MAIL_REFERENCE_TAGS_LISTING_INIT",
  MAIL_REFERENCE_TAGS_LISTING_FINISH: "MAIL_REFERENCE_TAGS_LISTING_FINISH",
  MAIL_REFERENCE_TAGS_LISTING_SUCCESS: "MAIL_REFERENCE_TAGS_LISTING_SUCCESS",

  //Reference Tags Update
  MAIL_REFERENCE_TAGS_UPDATE: "MAIL_REFERENCE_TAGS_UPDATE",
  MAIL_REFERENCE_TAGS_UPDATE_INIT: "MAIL_REFERENCE_TAGS_UPDATE_INIT",
  MAIL_REFERENCE_TAGS_UPDATE_FINISH: "MAIL_REFERENCE_TAGS_UPDATE_FINISH",
  MAIL_REFERENCE_TAGS_UPDATE_SUCCESS: "MAIL_REFERENCE_TAGS_UPDATE_SUCCESS",

  //multiple rederence tags update
  MAI_REFERENCE_TAGS_MULTIPLE_UPDATE: "MAI_REFERENCE_TAGS_MULTIPLE_UPDATE",
  MAI_REFERENCE_TAGS_MULTIPLE_UPDATE_INIT:
    "MAI_REFERENCE_TAGS_MULTIPLE_UPDATE_INIT",
  MAI_REFERENCE_TAGS_MULTIPLE_UPDATE_SUCCESS:
    "MAI_REFERENCE_TAGS_MULTIPLE_UPDATE_SUCCESS",
  MAI_REFERENCE_TAGS_MULTIPLE_UPDATE_FINISH:
    "MAI_REFERENCE_TAGS_MULTIPLE_UPDATE_FINISH",
  //MailReferenceGetDetails

  MAIL_REFERENCE_TAGS_DETAILS: "MAIL_REFERENCE_TAGS_DETAILS",
  MAIL_REFERENCE_TAGS_DETAILS_INIT: "MAIL_REFERENCE_TAGS_DETAILS_INIT",
  MAIL_REFERENCE_TAGS_DETAILS_FINISH: "MAIL_REFERENCE_TAGS_DETAILS_FINISH",
  MAIL_REFERENCE_TAGS_DETAILS_SUCCESS: "MAIL_REFERENCE_TAGS_DETAILS_SUCCESS",

  //referenceTagsCorrection
  MAIL_REFERENCE_TAGS_CORRECTION_DETAILS_INIT:
    "MAIL_REFERENCE_TAGS_CORRECTION_DETAILS_INIT",
  MAIL_REFERENCE_TAGS_CORRECTION_DETAILS_SUCCESS:
    "MAIL_REFERENCE_TAGS_CORRECTION_DETAILS_SUCCESS",
  MAIL_REFERENCE_TAGS_CORRECTION_DETAILS_FINISH:
    "MAIL_REFERENCE_TAGS_CORRECTION_DETAILS_FINISH",

  //Get Email Body Details

  MAIL_BODY_GET_DETAILS: "MAIL_BODY_GET_DETAILS",
  MAIL_BODY_GET_DETAILS_INIT: "MAIL_BODY_GET_DETAILS_INIT",
  MAIL_BODY_GET_DETAILS_FINISH: "MAIL_BODY_GET_DETAILS_FINISH",
  MAIL_BODY_GET_DETAILS_SUCCESS: "MAIL_BODY_GET_DETAILS_SUCCESS",
  MAIL_BODY_GET_DETAILS_UPDATE: "MAIL_BODY_GET_DETAILS_UPDATE",

  //Pagination Count
  MAIL_PAGINATION_COUNT: "MAIL_PAGINATION_COUNT",
  MAIL_PAGINATION_COUNT_INIT: "MAIL_PAGINATION_COUNT_INIT",
  MAIL_PAGINATION_COUNT_FINISH: "MAIL_PAGINATION_COUNT_FINISH",
  MAIL_PAGINATION_COUNT_SUCCESS: "MAIL_PAGINATION_COUNT_SUCCESS",
  MAIL_PAGINATION_COUNT_UPDATE: "MAIL_PAGINATION_COUNT_UPDATE",

  //Get reference update response from child windows
  MAIL_REFERENCE_TAGS_UPDATE_RESPONSE: "MAIL_REFERENCE_TAGS_UPDATE_RESPONSE",

  //get unread read response from other child window

  DOCUMENT_INSTANT_MESSAGE: "DOCUMENT_INSTANT_MESSAGE",
  DOCUMENT_INSTANT_MESSAGE_INIT: "DOCUMENT_INSTANT_MESSAGE_INIT",
  DOCUMENT_INSTANT_MESSAGE_FINISH: "DOCUMENT_INSTANT_MESSAGE_FINISH",
  DOCUMENT_INSTANT_MESSAGE_SUCCESS: "DOCUMENT_INSTANT_MESSAGE_SUCCESS",

  DOCUMENT_INSTANT_MESSAGE_CONTINUE: "DOCUMENT_INSTANT_MESSAGE_CONTINUE",
  DOCUMENT_INSTANT_MESSAGE_CONTINUE_INIT:
    "DOCUMENT_INSTANT_MESSAGE_CONTINUE_INIT",
  DOCUMENT_INSTANT_MESSAGE_CONTINUE_FINISH:
    "DOCUMENT_INSTANT_MESSAGE_CONTINUE_FINISH",
  DOCUMENT_INSTANT_MESSAGE_CONTINUE_SUCCESS:
    "DOCUMENT_INSTANT_MESSAGE_CONTINUE_SUCCESS",

  //draft email exist or not
  DRAFT_MAIL_EXIST_OR_NOT: "DRAFT_MAIL_EXIST_OR_NOT",
  DRAFT_MAIL_EXIST_OR_NOT_INIT: "DRAFT_MAIL_EXIST_OR_NOT_INIT",
  DRAFT_MAIL_EXIST_OR_NOT_SUCCESS: "DRAFT_MAIL_EXIST_OR_NOT_SUCCESS",

  //getdwtails from reply
  GET_REPLY_DETAILS: "GET_REPLY_DETAILS",
  GET_REPLY_DETAILS_INIT: "GET_REPLY_DETAILS_INIT",
  GET_REPLY_DETAILS_SUCCESS: "GET_REPLY_DETAILS_SUCCESS",

  //email predefined message listing
  GET_PREDEFINED_MESSAGE_LISTING: "GET_PREDEFINED_MESSAGE_LISTING",
  GET_PREDEFINED_MESSAGE_LISTING_INIT: "GET_PREDEFINED_MESSAGE_LISTING_INIT",
  GET_PREDEFINED_MESSAGE_LISTING_SUCCESS:
    "GET_PREDEFINED_MESSAGE_LISTING_SUCCESS",

  //email add predefined message
  PREDEFINED_MESSAGE_ADD: "PREDEFINED_MESSAGE_ADD",
  PREDEFINED_MESSAGE_ADD_INIT: "PREDEFINED_MESSAGE_ADD_INIT",
  PREDEFINED_MESSAGE_ADD_SUCCESS: "PREDEFINED_MESSAGE_ADD_SUCCESS",

  //email predefine update
  PREDEFINED_MESSAGE_UPDATE: "PREDEFINED_MESSAGE_UPDATE",
  PREDEFINED_MESSAGE_UPDATE_INIT: "PREDEFINED_MESSAGE_UPDATE_INIT",
  PREDEFINED_MESSAGE_UPDATE_SUCCESS: "PREDEFINED_MESSAGE_UPDATE_SUCCESS",

  //download file

  //get all Message Type
  GET_ALL_MESSAGE_TYPE: "GET_ALL_MESSAGE_TYPE",
  GET_ALL_MESSAGE_TYPE_INIT: "GET_ALL_MESSAGE_TYPE_INIT",
  GET_ALL_MESSAGE_TYPE_SUCCESS: "GET_ALL_MESSAGE_TYPE_SUCCESS",

  GET_INSTANCE_MESSAGE_USER_SUGGESTION: "GET_INSTANCE_MESSAGE_USER_SUGGESTION",
  GET_INSTANCE_MESSAGE_USER_SUGGESTION_INIT:
    "GET_INSTANCE_MESSAGE_USER_SUGGESTION_INIT",
  GET_INSTANCE_MESSAGE_USER_SUGGESTION_SUCCESS:
    "GET_INSTANCE_MESSAGE_USER_SUGGESTION_SUCCESS",
  GET_INSTANCE_MESSAGE_USER_SUGGESTION_FINISH:
    "GET_INSTANCE_MESSAGE_USER_SUGGESTION_FINISH",

  //Birthday Aniversary Message
  BIRTHDAY_ANIVERSARY_MESSAGE: "BIRTHDAY_ANIVERSARY_MESSAGE",
  BIRTHDAY_ANIVERSARY_MESSAGE_INIT: "BIRTHDAY_ANIVERSARY_MESSAGE_INIT",
  BIRTHDAY_ANIVERSARY_MESSAGE_SUCCESS: "BIRTHDAY_ANIVERSARY_MESSAGE_SUCCESS",
  BIRTHDAY_ANIVERSARY_MESSAGE_FINISH: "BIRTHDAY_ANIVERSARY_MESSAGE_FINISH",

  //Late login sms
  LATE_LOGIN_SMS: "LATE_LOGIN_SMS",
  LATE_LOGIN_SMS_INIT: "LATE_LOGIN_SMS_INIT",
  LATE_LOGIN_SMS_SUCCESS: "LATE_LOGIN_SMS_SUCCESS",
  LATE_LOGIN_SMS_FINISH: "LATE_LOGIN_SMS_FINISH",
};

export const INSTANCE_CHAT_TYPES = {
  // chat add
  INSTANCE_MESSAGE_CHAT: "INSTANCE_MESSAGE_CHAT",
  INSTANCE_MESSAGE_CHAT_INIT: "INSTANCE_MESSAGE_CHAT_INIT",
  INSTANCE_MESSAGE_CHAT_FINISH: "INSTANCE_MESSAGE_CHAT_FINISH",
  INSTANCE_MESSAGE_CHAT_SUCCESS: "INSTANCE_MESSAGE_CHAT_SUCCESS",

  // instance chat center pagination
  INSTANCE_CHAT_CENTER_PAGINATION: "INSTANCE_CHAT_CENTER_PAGINATION",
  INSTANCE_CHAT_CENTER_PAGINATION_INIT: "INSTANCE_CHAT_CENTER_PAGINATION_INIT",
  INSTANCE_CHAT_CENTER_PAGINATION_FINISH:
    "INSTANCE_CHAT_CENTER_PAGINATION_FINISH",
  INSTANCE_CHAT_CENTER_PAGINATION_SUCCESS:
    "INSTANCE_CHAT_CENTER_PAGINATION_SUCCESS",
};

export const ORGANIZATION_STRUCTURE_TYPES = {
  // designation listing
  DESIGNATION_LISTING: "DESIGNATION_LISTING",
  DESIGNATION_LISTING_INIT: "DESIGNATION_LISTING_INIT",
  DESIGNATION_LISTING_FINISH: "DESIGNATION_LISTING_FINISH",
  DESIGNATION_LISTING_SUCCESS: "DESIGNATION_LISTING_SUCCESS",

  // organization layer pagination
  ORGANIZATION_LAYER_PAGINATION: "ORGANIZATION_LAYER_PAGINATION",
  ORGANIZATION_LAYER_PAGINATION_INIT: "ORGANIZATION_LAYER_PAGINATION_INIT",
  ORGANIZATION_LAYER_PAGINATION_FINISH: "ORGANIZATION_LAYER_PAGINATION_FINISH",
  ORGANIZATION_LAYER_PAGINATION_SUCCESS:
    "ORGANIZATION_LAYER_PAGINATION_SUCCESS",

  // organization layer add
  ORGANIZATION_LAYER_ADD: "ORGANIZATION_LAYER_ADD",
  ORGANIZATION_LAYER_ADD_INIT: "ORGANIZATION_LAYER_ADD_INIT",
  ORGANIZATION_LAYER_ADD_FINISH: "ORGANIZATION_LAYER_ADD_FINISH",
  ORGANIZATION_LAYER_ADD_SUCCESS: "ORGANIZATION_LAYER_ADD_SUCCESS",

  // organization layer update
  ORGANIZATION_LAYER_UPDATE: "ORGANIZATION_LAYER_UPDATE",
  ORGANIZATION_LAYER_UPDATE_INIT: "ORGANIZATION_LAYER_UPDATE_INIT",
  ORGANIZATION_LAYER_UPDATE_FINISH: "ORGANIZATION_LAYER_UPDATE_FINISH",
  ORGANIZATION_LAYER_UPDATE_SUCCESS: "ORGANIZATION_LAYER_UPDATE_SUCCESS",

  //get All departments
  ORGANIZATION_DEPARTMENT_PAGINATION: "ORGANIZATION_DEPARTMENT_PAGINATION",
  ORGANIZATION_DEPARTMENT_PAGINATION_INIT:
    "ORGANIZATION_DEPARTMENT_PAGINATION_INIT",
  ORGANIZATION_DEPARTMENT_PAGINATION_FINISH:
    "ORGANIZATION_DEPARTMENT_PAGINATION_FINISH",
  ORGANIZATION_DEPARTMENT_PAGINATION_SUCCESS:
    "ORGANIZATION_DEPARTMENT_PAGINATION_SUCCESS",

  //add upadte Department
  ADD_UPDATE_DEPARTMENT: "ADD_UPDATE_DEPARTMENT",
  ADD_UPDATE_DEPARTMENT_INIT: "ADD_UPDATE_DEPARTMENT_INIT",
  ADD_UPDATE_DEPARTMENT_SUCCESS: "ADD_UPDATE_DEPARTMENT_SUCCESS",
  ADD_UPDATE_DEPARTMENT_FINISH: "ADD_UPDATE_DEPARTMENT_FINISH",

  //get All remainder
  REMAINDER_LISTING: "REMAINDER_LISTING",
  REMAINDER_LISTING_INIT: "REMAINDER_LISTING_INIT",
  REMAINDER_LISTING_SUCCESS: "REMAINDER_LISTING_SUCCESS",
  REMAINDER_LISTING_FINISH: "REMAINDER_LISTING_FINISH",

  //add employee in a department
  ADD_EMPLOYEE_DEPARTMENT: "ADD_EMPLOYEE_DEPARTMENT",
  ADD_EMPLOYEE_DEPARTMENT_INIT: "ADD_EMPLOYEE_DEPARTMENT_INIT",
  ADD_EMPLOYEE_DEPARTMENT_SUCCESS: "ADD_EMPLOYEE_DEPARTMENT_SUCCESS",
  ADD_EMPLOYEE_DEPARTMENT_FINISH: "ADD_EMPLOYEE_DEPARTMENT_FINISH",

  //department details
  GET_DEPARTMENT_DETAILS: "GET_DEPARTMENT_DETAILS",
  GET_DEPARTMENT_DETAILS_INIT: "GET_DEPARTMENT_DETAILS_INIT",
  GET_DEPARTMENT_DETAILS_SUCCESS: "GET_DEPARTMENT_DETAILS_SUCCESS",
  GET_DEPARTMENT_DETAILS_FINISH: "GET_DEPARTMENT_DETAILS_FINISH",
};

// notification
export const NOTIFICATIONS_TYPES = {
  SET_BELL_NOTIFICATION_LISTS: "SET_BELL_NOTIFICATION_LISTS",
  SET_CONNECTION: "SET_CONNECTION",
  GET_BELL_NOTIFICATION_COUNT: "GET_BELL_NOTIFICATION_COUNT",
};

export const LOAN_APPLICATION_TYPES = {
  // loan application pagination
  LOAN_APPLICATION_PAGINATION: "LOAN_APPLICATION_PAGINATION",
  LOAN_APPLICATION_PAGINATION_INIT: "LOAN_APPLICATION_PAGINATION_INIT",
  LOAN_APPLICATION_PAGINATION_FINISH: "LOAN_APPLICATION_PAGINATION_FINISH",
  LOAN_APPLICATION_PAGINATION_SUCCESS: "LOAN_APPLICATION_PAGINATION_SUCCESS",

  //loan application pagination for approval
  LOAN_APPLICATION_APPROVAL_PAGINATION: "LOAN_APPLICATION_APPROVAL_PAGINATION",
  LOAN_APPLICATION_APPROVAL_PAGINATION_INIT:
    "LOAN_APPLICATION_APPROVAL_PAGINATION_INIT",
  LOAN_APPLICATION_APPROVAL_PAGINATION_FINISH:
    "LOAN_APPLICATION_APPROVAL_PAGINATION_FINISH",
  LOAN_APPLICATION_APPROVAL_PAGINATION_SUCCESS:
    "LOAN_APPLICATION_APPROVAL_PAGINATION_SUCCESS",

  // loan application add
  LOAN_APPLICATION_ADD: "LOAN_APPLICATION_ADD",
  LOAN_APPLICATION_ADD_INIT: "LOAN_APPLICATION_ADD_INIT",
  LOAN_APPLICATION_ADD_FINISH: "LOAN_APPLICATION_ADD_FINISH",
  LOAN_APPLICATION_ADD_SUCCESS: "LOAN_APPLICATION_ADD_SUCCESS",

  // loan application add
  LOAN_APPLICATION_UPDATE: "LOAN_APPLICATION_UPDATE",
  LOAN_APPLICATION_UPDATE_INIT: "LOAN_APPLICATION_UPDATE_INIT",
  LOAN_APPLICATION_UPDATE_FINISH: "LOAN_APPLICATION_UPDATE_FINISH",
  LOAN_APPLICATION_UPDATE_SUCCESS: "LOAN_APPLICATION_UPDATE_SUCCESS",

  // loan application add
  LOAN_APPLICATION_DATA_DETAILS: "LOAN_APPLICATION_DATA_DETAILS",
  LOAN_APPLICATION_DATA_DETAILS_INIT: "LOAN_APPLICATION_DATA_DETAILS_INIT",
  LOAN_APPLICATION_DATA_DETAILS_FINISH: "LOAN_APPLICATION_DATA_DETAILS_FINISH",
  LOAN_APPLICATION_DATA_DETAILS_SUCCESS:
    "LOAN_APPLICATION_DATA_DETAILS_SUCCESS",

  // change loan application status
  COMPLAINT_STATUS: "COMPLAINT_STATUS",
  COMPLAINT_STATUS_INIT: "COMPLAINT_STATUS_INIT",
  COMPLAINT_STATUS_FINISH: "COMPLAINT_STATUS_FINISH",
  COMPLAINT_STATUS_SUCCESS: "COMPLAINT_STATUS_SUCCESS",

  //loan application update supervisor
  LOAN_APPLICATION_UPDATE_SUPERVISOR: "LOAN_APPLICATION_UPDATE_SUPERVISOR",
  LOAN_APPLICATION_UPDATE_SUPERVISOR_INIT:
    "LOAN_APPLICATION_UPDATE_SUPERVISOR_INIT",
  LOAN_APPLICATION_UPDATE_SUPERVISOR_FINISH:
    "LOAN_APPLICATION_UPDATE_SUPERVISOR_FINISH",
  LOAN_APPLICATION_UPDATE_SUPERVISOR_SUCCESS:
    "LOAN_APPLICATION_UPDATE_SUPERVISOR_SUCCESS",

  //loan application update accountant
  LOAN_APPLICATION_UPDATE_ACCOUNTANT: "LOAN_APPLICATION_UPDATE_ACCOUNTANT",
  LOAN_APPLICATION_UPDATE_ACCOUNTANT_INIT:
    "LOAN_APPLICATION_UPDATE_ACCOUNTANT_INIT",
  LOAN_APPLICATION_UPDATE_ACCOUNTANT_FINISH:
    "LOAN_APPLICATION_UPDATE_ACCOUNTANT_FINISH",
  LOAN_APPLICATION_UPDATE_ACCOUNTANT_SUCCESS:
    "LOAN_APPLICATION_UPDATE_ACCOUNTANT_SUCCESS",

  //loan application update management
  LOAN_APPLICATION_UPDATE_MANAGEMENT: "LOAN_APPLICATION_UPDATE_MANAGEMENT",
  LOAN_APPLICATION_UPDATE_MANAGEMENT_INIT:
    "LOAN_APPLICATION_UPDATE_MANAGEMENT_INIT",
  LOAN_APPLICATION_UPDATE_MANAGEMENT_FINISH:
    "LOAN_APPLICATION_UPDATE_MANAGEMENT_FINISH",
  LOAN_APPLICATION_UPDATE_MANAGEMENT_SUCCESS:
    "LOAN_APPLICATION_UPDATE_MANAGEMENT_SUCCESS",
};

export const FOLDER_SERVICE_TYPES = {
  //parent folders
  FOLDER_LIST: "FOLDER_LIST",
  FOLDER_LIST_INIT: "FOLDER_LIST_INIT",
  FOLDER_LIST_SUCCESS: "FOLDER_LIST_SUCCESS",
  FOLDER_LIST_FINISH: "FOLDER_LIST_FINISH",

  //child folders
  CHILD_FOLDER_LIST: "CHILD_FOLDER_LIST",
  CHILD_FOLDER_LIST_INIT: "CHILD_FOLDER_LIST_INIT",
  CHILD_FOLDER_LIST_SUCCESS: "CHILD_FOLDER_LIST_SUCCESS",
  CHILD_FOLDER_LIST_FINISH: "CHILD_FOLDER_LIST_FINISH",

  //create folder
  CREATE_FOLDER: "CREATE_FOLDER",
  CREATE_FOLDER_INIT: "CREATE_FOLDER_INIT",
  CREATE_FOLDER_SUCCESS: "CREATE_FOLDER_SUCCESS",
  CREATE_FOLDER_FINISH: "CREATE_FOLDER_FINISH",

  //upload file
  UPLOAD_FILE: "UPLOAD_FILE",
  UPLOAD_FILE_INIT: "UPLOAD_FILE_INIT",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FINISH: "UPLOAD_FILE_FINISH",
};

export const EMPLOYEE_EVALUATION_TYPES = {
  // add
  EMPLOYEE_EVALUATION_ADD: "EMPLOYEE_EVALUATION_ADD",
  EMPLOYEE_EVALUATION_ADD_INIT: "EMPLOYEE_EVALUATION_ADD_INIT",
  EMPLOYEE_EVALUATION_ADD_SUCCESS: "EMPLOYEE_EVALUATION_ADD_SUCCESS",
  EMPLOYEE_EVALUATION_ADD_FINISH: "EMPLOYEE_EVALUATION_ADD_FINISH",

  // pagination
  EMPLOYEE_EVALUATION_GET: "EMPLOYEE_EVALUATION_GET",
  EMPLOYEE_EVALUATION_GET_INIT: "EMPLOYEE_EVALUATION_GET_INIT",
  EMPLOYEE_EVALUATION_GET_SUCCESS: "EMPLOYEE_EVALUATION_GET_SUCCESS",
  EMPLOYEE_EVALUATION_GET_FINISH: "EMPLOYEE_EVALUATION_GET_FINISH",

  // mail
  // EMPLOYEE_SUPERVISOR_MAIL: "EMPLOYEE_SUPERVISOR_MAIL",
  // EMPLOYEE_SUPERVISOR_MAIL_INIT: "EMPLOYEE_SUPERVISOR_MAIL_INIT",
  // EMPLOYEE_SUPERVISOR_MAIL_SUCCESS: "EMPLOYEE_SUPERVISOR_MAIL_SUCCESS",
  // EMPLOYEE_SUPERVISOR_MAIL_FINISH: "EMPLOYEE_SUPERVISOR_MAIL_FINISH",
};

export const ORGANIZATION_TYPES = {
  //ORGANIZATION TYPE ADD
  ORGANIZATION_ADD: "ORGANIZATION_ADD",
  ORGANIZATION_TYPE_ADD_INIT: "ORGANIZATION_TYPE_ADD_INIT",
  ORGANIZATION_TYPE_ADD_FINISH: "ORGANIZATION_TYPE_ADD_FINISH",
  ORGANIZATION_TYPE_ADD_SUCCESS: "ORGANIZATION_TYPE_ADD_SUCCESS",

  ORGANIZATION_PAGINATION: "ORGANIZATION_PAGINATION",
  ORGANIZATION_PAGINATION_INIT: "ORGANIZATION_PAGINATION_INIT",
  ORGANIZATION_PAGINATION_FINISH: "ORGANIZATION_PAGINATION_FINISH",
  ORGANIZATION_PAGINATION_SUCCESS: "ORGANIZATION_PAGINATION_SUCCESS",
};

export const SOURCE_TYPES = {
  //Source Add
  SOURCE_ADD: "SOURCE_ADD",
  SOURCE_ADD_INIT: "SOURCE_ADD_INIT",
  SOURCE_ADD_FINISH: "SOURCE_ADD_FINISH",
  SOURCE_ADD_SUCCESS: "SOURCE_ADD_SUCCESS",

  //Source Pagination
  SOURCE_PAGINATION: "SOURCE_PAGINATION",
  SOURCE_PAGINATION_INIT: "SOURCE_PAGINATION_INIT",
  SOURCE_PAGINATION_FINISH: "SOURCE_PAGINATION_FINISH",
  SOURCE_PAGINATION_SUCCESS: "SOURCE_PAGINATION_SUCCESS",
};

export const HOLIDAY_TYPES = {
  HOLIDAY_ADD: "HOLIDAY_ADD",
  HOLIDAY_ADD_INIT: "HOLIDAY_ADD_INIT",
  HOLIDAY_ADD_FINISH: "SOURCE_ADD_FINISH",
  HOLIDAY_ADD_SUCCESS: "HOLIDAY_ADD_SUCCESS",

  HOLIDAY_PAGINATION: "HOLIDAY_PAGINATION",
  HOLIDAY_PAGINATION_INTI: "HOLIDAY_PAGINATION_INTI",
  HOLIDAY_PAGINATION_FINISH: "HOLIDAY_PAGINATION_FINISH",
  HOLIDAY_PAGINATION_SUCCESS: "HOLIDAY_PAGINATION_SUCCESS",

  HOLIDAY_SAMPLE_DATA: "HOLIDAY_SAMPLE_DATA",
  HOLIDAY_SAMPLE_DATA_INIT: "HOLIDAY_SAMPLE_DATA_INIT",
  HOLIDAY_SAMPLE_DATA_FINISH: "HOLIDAY_SAMPLE_DATA_FINISH",
  HOLIDAY_SAMPLE_DATA_SUCCESS: "HOLIDAY_SAMPLE_DATA_SUCCESS",

  //
  EXPORT_SISCO_USER_LIST: "EXPORT_SISCO_USER_LIST",
  EXPORT_SISCO_USER_LIST_INIT: "EXPORT_SISCO_USER_LIST_INIT",
  EXPORT_SISCO_USER_LIST_FINISH: "EXPORT_SISCO_USER_LIST_FINISH",
  EXPORT_SISCO_USER_LIST_SUCCESS: "EXPORT_SISCO_USER_LIST_SUCCESS",

  EXPORT_COMPANY_USER_LIST: "EXPORT_COMPANY_USER_LIST",
  EXPORT_COMPANY_USER_LIST_INIT: "EXPORT_COMPANY_USER_LIST_INIT",
  EXPORT_COMPANY_USER_LIST_FINISH: "EXPORT_COMPANY_USER_LIST_FINISH",
  EXPORT_COMPANY_USER_LIST_SUCCESS: "EXPORT_COMPANY_USER_LIST_SUCCESS",
};

export const ABSENT_TYPES = {
  //Notice Board Add
  NOTICE_BOARD_ADD: "NOTICE_BOARD_ADD",
  NOTICE_BOARD_INIT: "NOTICE_BOARD_INIT",
  NOTICE_BOARD_FINISH: "NOTICE_BOARD_FINISH",
  NOTICE_BOARD_SUCCESS: "NOTICE_BOARD_SUCCESS",

  //Update
  NOTICE_BOARD_UPDATE: "NOTICE_BOARD_UPDATE",
  NOTICE_BOARD_UPDATE_INIT: "NOTICE_BOARD_UPDATE_INIT",
  NOTICE_BOARD_UPDATE_FINISH: "NOTICE_BOARD_UPDATE_FINISH",
  NOTICE_BOARD_UPDATE_SUCCESS: "NOTICE_BOARD_UPDATE_SUCCESS",

  //Notice Board Pagination or Listing Type
  NOTICE_BOARD: "NOTICE_BOARD",
  NOTICE_BOARD_INIT: "NOTICE_BOARD_INIT",
  NOTICE_BOARD_FINISH: "NOTICE_BOARD_FINISH",
  NOTICE_BOARD_SUCCESS: "NOTICE_BOARD_SUCCESS",
  NOTICE_BOARD_PAGINATION: "NOTICE_BOARD_PAGINATION",
  NOTICE_BOARD_PAGINATION_SUCCESS: "NOTICE_BOARD_PAGINATION_SUCCESS",

  //
  NOTICE_PAGINATION: "NOTICE_PAGINATION",
  NOTICE_PAGINATION_INIT: "NOTICE_PAGINATION_INIT",
  NOTICE_PAGINATION_FINISH: "NOTICE_PAGINATION_FINISH",
  NOTICE_PAGINATION_SUCCESS: "NOTICE_PAGINATION_SUCCESS",

  //Absent Listing
  TODAY_ABSENT_LIST_PAGINATION: "TODAY_ABSENT_LIST_PAGINATION",
  TODAY_ABSENT_LIST_PAGINATION_INIT: "TODAY_ABSENT_LIST_PAGINATION_INIT",
  TODAY_ABSENT_LIST_PAGINATION_FINISH: "TODAY_ABSENT_LIST_PAGINATION_FINISH",
  TODAY_ABSENT_LIST_PAGINATION_SUCCESS: "TODAY_ABSENT_LIST_PAGINATION_SUCCESS",

  ABSENT_LISTING: "ABSENT_LISTING",
  ABSENT_LISTING_INIT: "ABSENT_LISTING_INIT",
  ABSENT_LISTING_FINISH: "ABSENT_LISTING_FINSISH",
  ABSENT_LISTING_SUCCESS: "ABSENT_LISTING_SUCCESS",
  ABSENT_LISTING_PAGINATION_FINISH: "ABSENT_LISTING_PAGINATION_FINISH",
  ABSENT_LISTING_PAGINATION_SUCCESS: "ABSENT_LISTING_PAGINATION_SUCCESS",

  ATTENDANCE_PAGINATION: "ATTENDANCE_PAGINATION",
  ATTENDANCE_PAGINATION_INIT: "ATTENDANCE_PAGINATION_INIT",
  ATTENDANCE_PAGINATION_FINISH: "ATTENDANCE_PAGINATION_FINISH",
  ATTENDANCE_PAGINATION_SUCCESS: "ATTENDANCE_PAGINATION_SUCCESS",
};
export const PRIMARY_REFERENCE_TYPES = {
  PRIMARY_REFERENCE_ADD: "PRIMARY_REFERENCE_ADD",
  PRIMARY_REFERENCE_INIT: "PRIMARY_REFERENCE_INIT",
  PRIMARY_REFERENCE_FINISH: "PRIMARY_REFRENCE_FINISH",
  PRIMARY_REFERENCE_SUCCESS: "PRIMARY_REFERENCE_SUCCESS",
};
//complaint Module
export const COMPLAINT_TYPES = {
  //complaint Pagination
  COMPLAINT_PAGINATION: "COMPLAINT_PAGINATION",
  COMPLAINT_PAGINATION_INIT: "COMPLAINT_PAGINATION_INIT",
  COMPLAINT_PAGINATION_FINISH: "COMPLAINT_PAGINATION_FINISH",
  COMPLAINT_PAGINATION_SUCCESS: "COMPLAINT_PAGINATION_SUCCESS",

  COMPLAINT_PAGINATION_CHANGE: "COMPLAINT_PAGINATION_CHANGE",
  COMPLAINT_PAGINATION_CHANGE_SUCCESS: "COMPLAINT_PAGINATION_CHANGE_SUCCESS",

  // complaint add
  COMPLAINT_ADD: "COMPLAINT_ADD",
  COMPLAINT_ADD_INIT: "COMPLAINT_ADD_INIT",
  COMPLAINT_ADD_FINISH: "COMPLAINT_ADD_FINISH",
  COMPLAINT_ADD_SUCCESS: "COMPLAINT_ADD_SUCCESS",

  // complaint status update

  COMPLAINT_STATUS_UPDATE: "COMPLAINT_STATUS_UPDATE",
  COMPLAINT_STATUS_UPDATE_INIT: "COMPLAINT_STATUS_UPDATE_INIT",
  COMPLAINT_STATUS_UPDATE_FINISH: "COMPLAINT_STATUS_UPDATE_FINISH",
  COMPLAINT_STATUS_UPDATE_SUCCESS: "COMPLAINT_STATUS_UPDATE_SUCCESS",

  // complaint Update
  COMPLAINT_UPDATE: "COMPLAINT_UPDATE",
  COMPLAINT_UPDATE_INIT: "COMPLAINT_UPDATE_INIT",
  COMPLAINT_UPDATE_FINISH: "COMPLAINT_UPDATE_FINISH",
  COMPLAINT_UPDATE_SUCCESS: "COMPLAINT_UPDATE_SUCCESS",

  // complain counter
  COMPLAIN_COUNTER: "COMPLAIN_COUNTER",
  COMPLAIN_COUNTER_INIT: "COMPLAIN_COUNTER_INIT",
  COMPLAIN_COUNTER_FINISH: "COMPLAIN_COUNTER_FINISH",
  COMPLAIN_COUNTER_SUCCESS: "COMPLAIN_COUNTER_SUCCESS",
  COMPLAIN_COUNTER_UPDATE: "COMPLAIN_COUNTER_UPDATE",

  // complain getDetils
  COMPLAIN_GET_DETAILS: "COMPLAIN_GET_DETAILS",
  COMPLAIN_GET_DETAILS_INIT: "COMPLAIN_GET_DETAILS_INIT",
  COMPLAIN_GET_DETAILS_FINISH: "COMPLAIN_GET_DETAILS_FINISH",
  COMPLAIN_GET_DETAILS_SUCCESS: "COMPLAIN_GET_DETAILS_SUCCESS",

  //error log display complain box
  ERROR_LOG: "ERROR_LOG",
  ERROR_LOG_INIT: "ERROR_LOG_INIT",
  ERROR_LOG_SUCCESS: "ERROR_LOG_SUCCESS",

  // change loan application status
  // CHANGE_LOAN_APPLICATION_STATUS: 'CHANGE_LOAN_APPLICATION_STATUS',
  // CHANGE_LOAN_APPLICATION_STATUS_INIT: 'CHANGE_LOAN_APPLICATION_STATUS_INIT',
  // CHANGE_LOAN_APPLICATION_STATUS_FINISH: 'CHANGE_LOAN_APPLICATION_STATUS_FINISH',
  // CHANGE_LOAN_APPLICATION_STATUS_SUCCESS: 'CHANGE_LOAN_APPLICATION_STATUS_SUCCESS',
};

export const TADA_TYPES = {
  // tada add types
  TADA_DETAILS_ADD: "TADA_DETAILS_ADD",
  TADA_DETAILS_ADD_INIT: "TADA_DETAILS_ADD_INIT",
  TADA_DETAILS_ADD_FINISH: "TADA_DETAILS_ADD_FINISH",
  TADA_DETAILS_ADD_SUCCESS: "TADA_DETAILS_ADD_SUCCESS",

  // tada get types
  TADA_GET_DETAILS: "TADA_GET_DETAILS",
  TADA_GET_DETAILS_INIT: "TADA_GET_DETAILS_INIT",
  TADA_GET_DETAILS_FINISH: "TADA_GET_DETAILS_FINISH",
  TADA_GET_DETAILS_SUCCESS: "TADA_GET_DETAILS_SUCCESS",

  // tada single get types
  TADA_SINGLE_GET_DETAILS: "TADA_SINGLE_GET_DETAILS",
  TADA_SINGLE_GET_DETAILS_INIT: "TADA_SINGLE_GET_DETAILS_INIT",
  TADA_SINGLE_GET_DETAILS_FINISH: "TADA_SINGLE_GET_DETAILS_FINISH",
  TADA_SINGLE_GET_DETAILS_SUCCESS: "TADA_SINGLE_GET_DETAILS_SUCCESS",

  // tada update supervisor types
  TADA_DETAILS_UPDATE_SUPERVISOR: "TADA_DETAILS_UPDATE_SUPERVISOR",
  TADA_DETAILS_UPDATE_SUPERVISOR_INIT: "TADA_DETAILS_UPDATE_SUPERVISOR_INIT",
  TADA_DETAILS_UPDATE_SUPERVISOR_FINISH:
    "TADA_DETAILS_UPDATE_SUPERVISOR_FINISH",
  TADA_DETAILS_UPDATE_SUPERVISOR_SUCCESS:
    "TADA_DETAILS_UPDATE_SUPERVISOR_SUCCESS",

  // tada update management types
  TADA_DETAILS_UPDATE_MANAGEMENT: "TADA_DETAILS_UPDATE_MANAGEMENT",
  TADA_DETAILS_UPDATE_MANAGEMENT_INIT: "TADA_DETAILS_UPDATE_MANAGEMENT_INIT",
  TADA_DETAILS_UPDATE_MANAGEMENT_FINISH:
    "TADA_DETAILS_UPDATE_MANAGEMENT_FINISH",
  TADA_DETAILS_UPDATE_MANAGEMENT_SUCCESS:
    "TADA_DETAILS_UPDATE_MANAGEMENT_SUCCESS",

  // tada update accountant types
  TADA_DETAILS_UPDATE_ACCOUNTANT: "TADA_DETAILS_UPDATE_ACCOUNTANT",
  TADA_DETAILS_UPDATE_ACCOUNTANT_INIT: "TADA_DETAILS_UPDATE_ACCOUNTANT_INIT",
  TADA_DETAILS_UPDATE_ACCOUNTANT_FINISH:
    "TADA_DETAILS_UPDATE_ACCOUNTANT_FINISH",
  TADA_DETAILS_UPDATE_ACCOUNTANT_SUCCESS:
    "TADA_DETAILS_UPDATE_ACCOUNTANT_SUCCESS",

  // tada update settlement types
  TADA_DETAILS_UPDATE_SETTLEMENT: "TADA_DETAILS_UPDATE_SETTLEMENT",
  TADA_DETAILS_UPDATE_SETTLEMENT_INIT: "TADA_DETAILS_UPDATE_SETTLEMENT_INIT",
  TADA_DETAILS_UPDATE_SETTLEMENT_FINISH:
    "TADA_DETAILS_UPDATE_SETTLEMENT_FINISH",
  TADA_DETAILS_UPDATE_SETTLEMENT_SUCCESS:
    "TADA_DETAILS_UPDATE_SETTLEMENT_SUCCESS",

  //TADA approval details
  TADA_APPROVAL: "TADA_APPROVAL",
  TADA_APPROVAL_INIT: "TADA_APPROVAL_INIT",
  TADA_APPROVAL_SUCCESS: "TADA_APPROVAL_SUCCESS",
  TADA_APPROVAL_FINISH: "TADA_APPROVAL_FINISH",
};

export const VISIT_CATEGORY_TYPES = {
  VISIT_CATEGORY_GET_DETAILS: "VISIT_CATEGORY_GET_DETAILS",
  VISIT_CATEGORY_GET_DETAILS_INIT: "VISIT_CATEGORY_GET_DETAILS_INIT",
  VISIT_CATEGORY_GET_DETAILS_FINISH: "VISIT_CATEGORY_GET_DETAILS_FINISH",
  VISIT_CATEGORY_GET_DETAILS_SUCCESS: "VISIT_CATEGORY_GET_DETAILS_SUCCESS",
};

export const REQUEST_AMOUNT_TYPES = {
  REQUEST_AMOUNT_GET_DETAILS: "REQUEST_AMOUNT_GET_DETAILS",
  REQUEST_AMOUNT_GET_DETAILS_INIT: "REQUEST_AMOUNT_GET_DETAILS_INIT",
  REQUEST_AMOUNT_GET_DETAILS_FINISH: "REQUEST_AMOUNT_GET_DETAILS_FINISH",
  REQUEST_AMOUNT_GET_DETAILS_SUCCESS: "REQUEST_AMOUNT_GET_DETAILS_SUCCESS",
};

// common type
export const COMMON_TYPES = {
  EMPLOYEE_SISTER_COMPANY_LISTING: "EMPLOYEE_SISTER_COMPANY_LISTING",
  EMPLOYEE_SISTER_COMPANY_LISTING_INIT: "EMPLOYEE_SISTER_COMPANY_LISTING_INIT",
  EMPLOYEE_SISTER_COMPANY_LISTING_FINISH:
    "EMPLOYEE_SISTER_COMPANY_LISTING_FINISH",
  EMPLOYEE_SISTER_COMPANY_LISTING_SUCCESS:
    "EMPLOYEE_SISTER_COMPANY_LISTING_SUCCESS",

  ATTACHMENT_FILE_LISTING: "ATTACHMENT_FILE_LISTING",
  ATTACHMENT_FILE_LISTING_SUCCESS: "ATTACHMENT_FILE_LISTING_SUCCESS",
};

// document types

export const DOCUMENT_REQUEST_UPLOAD_TYPES = {
  DOCUMENT_REQUEST_DOCUMENT_UPLOAD: "DOCUMENT_REQUEST_DOCUMENT_UPLOAD",
  DOCUMENT_REQUEST_DOCUMENT_UPLOAD_INIT:
    "DOCUMENT_REQUEST_DOCUMENT_UPLOAD_INIT",
  DOCUMENT_REQUEST_DOCUMENT_UPLOAD_FINISH:
    "DOCUMENT_REQUEST_DOCUMENT_UPLOAD_FINISH",
  DOCUMENT_REQUEST_DOCUMENT_UPLOAD_SUCCESS:
    "DOCUMENT_REQUEST_DOCUMENT_UPLOAD_SUCCESS",
  DOCUMENT_REQUEST_DOCUMENT_UPLOAD_FAILED:
    "DOCUMENT_REQUEST_DOCUMENT_UPLOAD_FAILED",

  DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE:
    "DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE",
  DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE_INIT:
    "DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE_INIT",
  DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE_FINISH:
    "DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE_FINISH",
  DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE_SUCCESS:
    "DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE_SUCCESS",

  DOCUMENT_REQUEST_PAGINATION: "DOCUMENT_REQUEST_PAGINATION",
  DOCUMENT_REQUEST_PAGINATION_CHANGE: "DOCUMENT_REQUEST_PAGINATION_CHANGE",
  DOCUMENT_REQUEST_PAGINATION_INIT: "DOCUMENT_REQUEST_PAGINATION_INIT",
  DOCUMENT_REQUEST_PAGINATION_FINISH: "DOCUMENT_REQUEST_PAGINATION_FINISH",
  DOCUMENT_REQUEST_PAGINATION_SUCCESS: "DOCUMENT_REQUEST_PAGINATION_SUCCESS",
  DOCUMENT_REQUEST_PAGINATION_CHANGE_SUCCESS:
    "DOCUMENT_REQUEST_PAGINATION_CHANGE_SUCCESS",

  DOCUMENT_REQUEST_SHARE: "DOCUMENT_REQUEST_SHARE",
  DOCUMENT_REQUEST_SHARE_INIT: "DOCUMENT_REQUEST_SHARE_INIT",
  DOCUMENT_REQUEST_SHARE_SUCCESS: "DOCUMENT_REQUEST_SHARE_SUCCESS",
  DOCUMENT_REQUEST_PAGINATION: "DOCUMENT_REQUEST_PAGINATION",
  DOCUMENT_REQUEST_PAGINATION_CHANGE: "DOCUMENT_REQUEST_PAGINATION_CHANGE",
  DOCUMENT_REQUEST_PAGINATION_INIT: "DOCUMENT_REQUEST_PAGINATION_INIT",
  DOCUMENT_REQUEST_PAGINATION_FINISH: "DOCUMENT_REQUEST_PAGINATION_FINISH",
  DOCUMENT_REQUEST_PAGINATION_SUCCESS: "DOCUMENT_REQUEST_PAGINATION_SUCCESS",
  DOCUMENT_REQUEST_PAGINATION_CHANGE_SUCCESS:
    "DOCUMENT_REQUEST_PAGINATION_CHANGE_SUCCESS",
};

//Document Single Get Details (for Sector Purpose)
export const DOCUMENT_SINGLE_GET_TYPES = {
  DOCUMENT_SINGLE_GET: "DOCUMENT_SINGLE_GET",
  DOCUMENT_SINGLE_GET_INIT: "DOCUMENT_SINGLE_GET_INIT",
  DOCUMENT_SINGLE_GET_SUCCESS: "DOCUMENT_SINGLE_GET_SUCCESS",
  DOCUMENT_SINGLE_GET_FINISH: "DOCUMENT_SINGLE_GET_FINISH",
};

export const DASHBOARD_ABSENTEE_DATE = {
  DASHBOARD_ABSENTEE_LIST: "DASHBOARD_ABSENTEE_LIST",
  DASHBOARD_ABSENTEE_LIST_INIT: "DASHBOARD_ABSENTEE_LIST_INIT",
  DASHBOARD_ABSENTEE_LIST_SUCCESS: "DASHBOARD_ABSENTEE_LIST_SUCCESS",
  DASHBOARD_ABSENTEE_LIST_FINISH: "DASHBOARD_ABSENTEE_LIST_FINISH",
};

export const MULTIPLE_EMAIL_SELECTION = {
  MULTIPLE_EMAIL_SELECTION_LIST: "MULTIPLE_EMAIL_SELECTION_LIST",
  MULTIPLE_EMAIL_SELECTION_LIST_SUCCESS:
    "MULTIPLE_EMAIL_SELECTION_LIST_SUCCESS",
};

export const SOW_TYPES = {
  SOW_TYPE: "SOW_TYPE",
  SOW_TYPE_INIT: "SOW_TYPE_INIT",
  SOW_TYPE_SUCCESS: "SOW_TYPE_SUCCESS",
  SOW_TYPE_FINISH: "SOW_TYPE_FINISH",

  //get all sectors
  ALL_SECTORS_LISTING: "ALL_SECTORS_LISTING",
  ALL_SECTORS_LISTING_INIT: "ALL_SECTORS_LISTING_INIT",
  ALL_SECTORS_LISTING_SUCCESS: "ALL_SECTORS_LISTING_SUCCESS",
  ALL_SECTORS_LISTING_FINISH: "ALL_SECTORS_LISTING_FINISH",

  SUB_SECTORS_LISTING: "SUB_SECTORS_LISTING",
  SUB_SECTORS_LISTING_INIT: "SUB_SECTORS_LISTING_INIT",
  SUB_SECTORS_LISTING_SUCCESS: "SUB_SECTORS_LISTING_SUCCESS",
  SUB_SECTORS_LISTING_FINISH: "SUB_SECTORS_LISTING_FINISH",

  //get All status
  ALL_STATUS_LISTING: "ALL_STATUS_LISTING",
  ALL_STATUS_LISTING_INIT: "ALL_STATUS_LISTING_INIT",
  ALL_STATUS_LISTING_SUCCESS: "ALL_STATUS_LISTING_SUCCESS",
  ALL_STATUS_LISTING_FINISH: "ALL_STATUS_LISTING_FINISH",

  ALL_MODALITY_LISTING: "ALL_MODALITY_LISTING",
  ALL_MODALITY_LISTING_INIT: "ALL_MODALITY_LISTING_INIT",
  ALL_MODALITY_LISTING_SUCCESS: "ALL_MODALITY_LISTING_SUCCESS",
  ALL_MODALITY_LISTING_FINISH: "ALL_MODALITY_LISTING_FINISH",
};

export const CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES = {
  CREATE_GROUP_REQUEST: "CREATE_GROUP_REQUEST",
  CREATE_GROUP_REQUEST_INIT: "CREATE_GROUP_REQUEST_INIT",
  CREATE_GROUP_REQUEST_FINISH: "CREATE_GROUP_REQUEST_FINISH",
  CREATE_GROUP_REQUEST_SUCCESS: "CREATE_GROUP_REQUEST_SUCCESS",

  LIST_GROUP_REQUEST: "LIST_GROUP_REQUEST",
  LIST_GROUP_REQUEST_INIT: "LIST_GROUP_REQUEST_INIT",
  LIST_GROUP_REQUEST_SUCCESS: "LIST_GROUP_REQUEST_SUCCESS",
  LIST_GROUP_REQUEST_FINISH: "LIST_GROUP_REQUEST_FINISH",

  UPDATE_GROUP_REQUEST: "UPDATE_GROUP_REQUEST",
  UPDATE_GROUP_REQUEST_INIT: "UPDATE_GROUP_REQUEST_INIT",
  UPDATE_GROUP_REQUEST_SUCCESS: "UPDATE_GROUP_REQUEST_SUCCESS",
  UPDATE_GROUP_REQUEST_FINISH: "UPDATE_GROUP_REQUEST_FINISH",
};

export const EMPLOYMENT_REQUISITION_TYPES = {
  //Application Types
  CREATE_EMPLOYMENT_REQUISITION_TYPES: "CREATE_EMPLOYMENT_REQUISITION_TYPES",
  CREATE_EMPLOYMENT_REQUISITION_TYPES_INIT:
    "CREATE_EMPLOYMENT_REQUISITION_TYPES_INIT",
  CREATE_EMPLOYMENT_REQUISITION_TYPES_SUCCESS:
    "CREATE_EMPLOYMENT_REQUISITION_TYPES_SUCCESS",
  CREATE_EMPLOYMENT_REQUISITION_TYPES_FINISH:
    "CREATE_EMPLOYMENT_REQUISITION_TYPES_FINISH",

  GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES:
    "GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES",
  GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES_INIT:
    "GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES_INIT",
  GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES_SUCCESS:
    "GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES_SUCCESS",
  GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES_FINISH:
    "GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES_FINISH",

  //single application
  //get
  GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES:
    "GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES",
  GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_INIT:
    "GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_INIT",
  GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_SUCCESS:
    "GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_SUCCESS",
  GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_FINISH:
    "GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_FINISH",

  //update
  EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES:
    "EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES",
  EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_UPDATE:
    "EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_UPDATE",
  EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_UPDATE_SUCCESS:
    "EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_UPDATE_SUCCESS",
  EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_UPDATE_FINISH:
    "EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_UPDATE_FINISH",

  //Applicants Types

  //basic details

  CREATE_UPDATE_APPLICANTS_TYPES: "CREATE_UPDATE_APPLICANTS_TYPES",
  CREATE_UPDATE_APPLICANTS_TYPES_INIT: "CREATE_UPDATE_APPLICANTS_TYPES_INIT",
  CREATE_UPDATE_APPLICANTS_TYPES_SUCCESS:
    "CREATE_UPDATE_APPLICANTS_TYPES_SUCCESS",
  CREATE_UPDATE_APPLICANTS_TYPES_FINISH:
    "CREATE_UPDATE_APPLICANTS_TYPES_FINISH",

  //education details

  CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES:
    "CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES",
  CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES_INIT:
    "CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES_INIT",
  CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES_SUCCESS:
    "CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES_SUCCESS",
  CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES_FINISH:
    "CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES_FINISH",

  //job Details
  CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES:
    "CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES",
  CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES_INIT:
    "CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES_INIT",
  CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES_SUCCESS:
    "CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES_SUCCESS",
  CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES_FINISH:
    "CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES_FINISH",

  GET_ALL_STAGE_LEVEL_LISTING: "GET_ALL_STAGE_LEVEL_LISTING",
  GET_ALL_STAGE_LEVEL_LISTING_INIT: "GET_ALL_STAGE_LEVEL_LISTING_INIT",
  GET_ALL_STAGE_LEVEL_LISTING_SUCCESS: "GET_ALL_STAGE_LEVEL_LISTING_SUCCESS",
  GET_ALL_STAGE_LEVEL_LISTING_FINISH: "GET_ALL_STAGE_LEVEL_LISTING_FINISH",

  CATEGORY_DETAILS: "CATEGORY_DETAILS",
  CATEGORY_DETAILS_INIT: "CATEGORY_DETAILS_INIT",

  CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES:
    "CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES",
  CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES_INIT:
    "CREATE_UPDATE_APPLICANTS_CTAB_ATEGORY_DETAILS_TYPES_INIT",
  CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES_SUCCESS:
    "CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES_SUCCESS",
  CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES_FINISH:
    "CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES_FINISH",

  APPLICANT_ADD_SUCCESS: "APPLICANT_ADD_SUCCESS",
  APPLICANT_ADD: "APPLICANT_ADD",

  // corportate email listing
  GET_CORPORATE_MAIL_LISTING: "GET_CORPORATE_MAIL_LISTING",
  GET_CORPORATE_MAIL_LISTING_INIT: "GET_CORPORATE_MAIL_LISTING_INIT",
  GET_CORPORATE_MAIL_LISTING_SUCCESS: "GET_CORPORATE_MAIL_LISTING_SUCCESS",
  GET_CORPORATE_MAIL_LISTING_FINISH: "GET_CORPORATE_MAIL_LISTING_FINISH",

  // Add
  CREATE_EMPLOYMENT_FINAL_APPROVE: "CREATE_EMPLOYMENT_FINAL_APPROVE",
  CREATE_EMPLOYMENT_FINAL_APPROVE_INIT: "CREATE_EMPLOYMENT_FINAL_APPROVE_INIT",
  CREATE_EMPLOYMENT_FINAL_APPROVE_SUCCESS:
    "CREATE_EMPLOYMENT_FINAL_APPROVE_SUCCESS",
  CREATE_EMPLOYMENT_FINAL_APPROVE_FINISH:
    "CREATE_EMPLOYMENT_FINAL_APPROVE_FINISH",

  // job interview
  GET_PAGINATION_JOB_INTERVIEW: "GET_PAGINATION_JOB_INTERVIEW",
  GET_PAGINATION_JOB_INTERVIEW_INIT: "GET_PAGINATION_JOB_INTERVIEW_INIT",
  GET_PAGINATION_JOB_INTERVIEW_SUCCESS: "GET_PAGINATION_JOB_INTERVIEW_SUCCESS",
  GET_PAGINATION_JOB_INTERVIEW_FINISH: "GET_PAGINATION_JOB_INTERVIEW_FINISH",

  GET_APPLICANT_JOB_INTERVIEW_BY_ID: "GET_APPLICANT_JOB_INTERVIEW_BY_ID",
  GET_APPLICANT_JOB_INTERVIEW_BY_ID_INIT:
    "GET_APPLICANT_JOB_INTERVIEW_BY_ID_INIT",
  GET_APPLICANT_JOB_INTERVIEW_BY_ID_SUCCESS:
    "GET_APPLICANT_JOB_INTERVIEW_BY_ID_SUCCESS",
  GET_APPLICANT_JOB_INTERVIEW_BY_ID_FINISH:
    "GET_APPLICANT_JOB_INTERVIEW_BY_ID_FINISH",

  CREATE_APPLICANT_JOB_INTERVIEW: "CREATE_APPLICANT_JOB_INTERVIEW",
  CREATE_APPLICANT_JOB_INTERVIEW_INIT: "CREATE_APPLICANT_JOB_INTERVIEW_INIT",
  CREATE_APPLICANT_JOB_INTERVIEW_SUCCESS:
    "CREATE_APPLICANT_JOB_INTERVIEW_SUCCESS",
  CREATE_APPLICANT_JOB_INTERVIEW_FINISH:
    "CREATE_APPLICANT_JOB_INTERVIEW_FINISH",

  UPDATE_APPLICANT_JOB_INTERVIEW: "UPDATE_APPLICANT_JOB_INTERVIEW",
  UPDATE_APPLICANT_JOB_INTERVIEW_INIT: "UPDATE_APPLICANT_JOB_INTERVIEW_INIT",
  UPDATE_APPLICANT_JOB_INTERVIEW_SUCCESS:
    "UPDATE_APPLICANT_JOB_INTERVIEW_SUCCESS",
  UPDATE_APPLICANT_JOB_INTERVIEW_FINISH:
    "UPDATE_APPLICANT_JOB_INTERVIEW_FINISH",

  // compose email
  CREATE_APPLICANT_JOB_MAIL: "CREATE_APPLICANT_JOB_MAIL",
  CREATE_APPLICANT_JOB_MAIL_INIT: "CREATE_APPLICANT_JOB_MAIL_INIT",
  CREATE_APPLICANT_JOB_MAIL_SUCCESS: "CREATE_APPLICANT_JOB_MAIL_SUCCESS",
  CREATE_APPLICANT_JOB_MAIL_FINISH: "CREATE_APPLICANT_JOB_MAIL_FINISH",

  // hr system search
  GET_HR_SYSTEM_SEARCH: "GET_HR_SYSTEM_SEARCH",
  GET_HR_SYSTEM_SEARCH_INIT: "GET_HR_SYSTEM_SEARCH_INIT",
  GET_HR_SYSTEM_SEARCH_SUCCESS: "GET_HR_SYSTEM_SEARCH_SUCCESS",
  GET_HR_SYSTEM_SEARCH_FINISH: "GET_HR_SYSTEM_SEARCH_FINISH",
};

export const CEAD_TYPES = {
  ADD_UPDATE_TENDER_CEAD: "ADD_UPDATE_TENDER_CEAD",
  ADD_UPDATE_TENDER_CEAD_INIT: "ADD_UPDATE_TENDER_CEAD_INIT",
  ADD_UPDATE_TENDER_CEAD_SUCCESS: "ADD_UPDATE_TENDER_CEAD_SUCCESS",
  ADD_UPDATE_TENDER_CEAD_FINISH: "ADD_UPDATE_TENDER_CEAD_FINISH",

  GET_TENDER_CEAD_LIST: "GET_TENDER_CEAD_LIST",
  GET_TENDER_CEAD_LIST_INIT: "GET_TENDER_CEAD_LIST_INIT",
  GET_TENDER_CEAD_LIST_SUCCESS: "GET_TENDER_CEAD_LIST_SUCCESS",
  GET_TENDER_CEAD_LIST_FINISH: "GET_TENDER_CEAD_LIST_FINISH",

  GET_TENDER_CEAD_ACCOUNT_VIEW_LIST: "GET_TENDER_CEAD_ACCOUNT_VIEW_LIST",
  GET_TENDER_CEAD_ACCOUNT_VIEW_LIST_INIT:
    "GET_TENDER_CEAD_ACCOUNT_VIEW_LIST_INIT",
  GET_TENDER_CEAD_ACCOUNT_VIEW_LIST_SUCCESS:
    "GET_TENDER_CEAD_ACCOUNT_VIEW_LIST_SUCCESS",
  GET_TENDER_CEAD_ACCOUNT_VIEW_LIST_FINISH:
    "GET_TENDER_CEAD_ACCOUNT_VIEW_LIST_FINISH",

  GET_SINGLE_TENDER_CEAD_LIST: "GET_SINGLE_TENDER_CEAD_LIST",
  GET_SINGLE_TENDER_CEAD_LIST_INIT: "GET_SINGLE_TENDER_CEAD_LIST_INIT",
  GET_SINGLE_TENDER_CEAD_LIST_SUCCESS: "GET_SINGLE_TENDER_CEAD_LIST_SUCCESS",
  GET_SINGLE_TENDER_CEAD_LIST_FINISH: "GET_SINGLE_TENDER_CEAD_LIST_FINISH",

  ADD_UPDATE_CEAD_ACCOUNT_COMMISSION: "ADD_UPDATE_CEAD_ACCOUNT_COMMISSION",
  ADD_UPDATE_CEAD_ACCOUNT_COMMISSION_INIT:
    "ADD_UPDATE_CEAD_ACCOUNT_COMMISSION_INIT",
  ADD_UPDATE_CEAD_ACCOUNT_COMMISSION_FINISH:
    "ADD_UPDATE_CEAD_ACCOUNT_COMMISSION_FINISH",
  ADD_UPDATE_CEAD_ACCOUNT_COMMISSION_SUCCESS:
    "ADD_UPDATE_CEAD_ACCOUNT_COMMISSION_SUCCESS",

  ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER: "ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER",
  ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER_INIT:
    "ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER_INIT",
  ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER_FINISH:
    "ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER_FINISH",
  ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER_SUCCESS:
    "ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER_SUCCESS",

  ADD_UPDATE_FUNDING: "ADD_UPDATE_FUNDING",
  ADD_UPDATE_FUNDING_INIT: "ADD_UPDATE_FUNDING_INIT",
  ADD_UPDATE_FUNDING_FINISH: "ADD_UPDATE_FUNDING_FINISH",
  ADD_UPDATE_FUNDING_SUCCESS: "ADD_UPDATE_FUNDING_SUCCESS",

  ADD_UPDATE_BID_BOUND: "ADD_UPDATE_BID_BOUND",
  ADD_UPDATE_BID_BOUND_INIT: "ADD_UPDATE_BID_BOUND_INIT",
  ADD_UPDATE_BID_BOUND_FINISH: "ADD_UPDATE_BID_BOUND_FINISH",
  ADD_UPDATE_BID_BOUND_SUCCESS: "ADD_UPDATE_BID_BOUND_SUCCESS",

  ADD_UPDATE_PERFORMANCE_BOUND: "ADD_UPDATE_PERFORMANCE_BOUND",
  ADD_UPDATE_PERFORMANCE_BOUND_INIT: "ADD_UPDATE_PERFORMANCE_BOUND_INIT",
  ADD_UPDATE_PERFORMANCE_BOUND_FINISH: "ADD_UPDATE_PERFORMANCE_BOUND_FINISH",
  ADD_UPDATE_PERFORMANCE_BOUND_SUCCESS: "ADD_UPDATE_PERFORMANCE_BOUND_SUCCESS",

  ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE: "ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE",
  ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE_INIT:
    "ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE_INIT",
  ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE_FINISH:
    "ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE_FINISH",
  ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE_SUCCESS:
    "ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE_SUCCESS",

  ADD_UPDATE_FINANCIAL_INFORMATION: "ADD_UPDATE_FINANCIAL_INFORMATION",
  ADD_UPDATE_FINANCIAL_INFORMATION_INIT:
    "ADD_UPDATE_FINANCIAL_INFORMATION_INIT",
  ADD_UPDATE_FINANCIAL_INFORMATION_FINISH:
    "ADD_UPDATE_FINANCIAL_INFORMATION_FINISH",
  ADD_UPDATE_FINANCIAL_INFORMATION_SUCCESS:
    "ADD_UPDATE_FINANCIAL_INFORMATION_SUCCESS",

  GET_PRINCIPLE_INVOICE_LIST: "GET_PRINCIPLE_INVOICE_LIST",
  GET_PRINCIPLE_INVOICE_LIST_INIT: "GET_PRINCIPLE_INVOICE_LIST_INIT",
  GET_PRINCIPLE_INVOICE_LIST_FINISH: "GET_PRINCIPLE_INVOICE_LIST_FINISH",
  GET_PRINCIPLE_INVOICE_LIST_SUCCESS: "GET_PRINCIPLE_INVOICE_LIST_SUCCESS",

  GET_CEAD_COMPOSE_EMAIL_DETAIL: "GET_CEAD_COMPOSE_EMAIL_DETAIL",
  GET_CEAD_COMPOSE_EMAIL_DETAIL_INIT: "GET_CEAD_COMPOSE_EMAIL_DETAIL_INIT",
  GET_CEAD_COMPOSE_EMAIL_DETAIL_FINISH: "GET_CEAD_COMPOSE_EMAIL_DETAIL_FINISH",
  GET_CEAD_COMPOSE_EMAIL_DETAIL_SUCCESS:
    "GET_CEAD_COMPOSE_EMAIL_DETAIL_SUCCESS",

  GET_PAYMENT_RECEIVED_LOG: "GET_PAYMENT_RECEIVED_LOG",
  GET_PAYMENT_RECEIVED_LOG_INIT: "GET_PAYMENT_RECEIVED_LOG_INIT",
  GET_PAYMENT_RECEIVED_LOG_FINISH: "GET_PAYMENT_RECEIVED_LOG_FINISH",
  GET_PAYMENT_RECEIVED_LOG_SUCCESS: "GET_PAYMENT_RECEIVED_LOG_SUCCESS",

  CONTRACT_ADD_UPDATE_CLOSURE: "CONTRACT_ADD_UPDATE_CLOSURE",
  CONTRACT_ADD_UPDATE_CLOSURE_INIT: "CONTRACT_ADD_UPDATE_CLOSURE_INIT",
  CONTRACT_ADD_UPDATE_CLOSURE_FINISH: "CONTRACT_ADD_UPDATE_CLOSURE_FINISH",
  CONTRACT_ADD_UPDATE_CLOSURE_SUCCESS: "CONTRACT_ADD_UPDATE_CLOSURE_SUCCESS",

  UPDATE_QA: "UPDATE_QA",
  UPDATE_QA_INIT: "UPDATE_QA_INIT",
  UPDATE_QA_FINISH: "UPDATE_QA_FINISH",
  UPDATE_QA_SUCCESS: "UPDATE_QA_SUCCESS",

  ADD_UPDATE_WEEKLY_REPORT: "ADD_UPDATE_WEEKLY_REPORT",
  ADD_UPDATE_WEEKLY_REPORT_INIT: "ADD_UPDATE_WEEKLY_REPORT_INIT",
  ADD_UPDATE_WEEKLY_REPORT_FINISH: "ADD_UPDATE_WEEKLY_REPORT_FINISH",
  ADD_UPDATE_WEEKLY_REPORT_SUCCESS: "ADD_UPDATE_WEEKLY_REPORT_SUCCESS",

  WEEKLY_REPORT_GET_DETAIL_LIST: "WEEKLY_REPORT_GET_DETAIL_LIST",
  WEEKLY_REPORT_GET_DETAIL_LIST_INIT: "WEEKLY_REPORT_GET_DETAIL_LIST_INIT",
  WEEKLY_REPORT_GET_DETAIL_LIST_FINISH: "WEEKLY_REPORT_GET_DETAIL_LIST_FINISH",
  WEEKLY_REPORT_GET_DETAIL_LIST_SUCCESS:
    "WEEKLY_REPORT_GET_DETAIL_LIST_SUCCESS",

  WEEKLY_REPORT_GET_DETAIL: "WEEKLY_REPORT_GET_DETAIL",
  WEEKLY_REPORT_GET_DETAIL_INIT: "WEEKLY_REPORT_GET_DETAIL_INIT",
  WEEKLY_REPORT_GET_DETAIL_FINISH: "WEEKLY_REPORT_GET_DETAIL_FINISH",
  WEEKLY_REPORT_GET_DETAIL_SUCCESS: "WEEKLY_REPORT_GET_DETAIL_SUCCESS",

  // CONTRACT WEEKLY REPORT

  ADD_UPDATE_WEEKLY_CONTRACT_REPORT: "ADD_UPDATE_WEEKLY_CONTRACT_REPORT",
  ADD_UPDATE_WEEKLY_CONTRACT_REPORT_INIT:
    "ADD_UPDATE_WEEKLY_CONTRACT_REPORT_INIT",
  ADD_UPDATE_WEEKLY_CONTRACT_REPORT_FINISH:
    "ADD_UPDATE_WEEKLY_CONTRACT_REPORT_FINISH",
  ADD_UPDATE_WEEKLY_CONTRACT_REPORT_SUCCESS:
    "ADD_UPDATE_WEEKLY_CONTRACT_REPORT_SUCCESS",

  WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST:
    "WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST",
  WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST_INIT:
    "WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST_INIT",
  WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST_FINISH:
    "WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST_FINISH",
  WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST_SUCCESS:
    "WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST_SUCCESS",

  CONTRACT_WEEKLY_REPORT_GET_DETAIL: "CONTRACT_WEEKLY_REPORT_GET_DETAIL",
  CONTRACT_WEEKLY_REPORT_GET_DETAIL_INIT:
    "CONTRACT_WEEKLY_REPORT_GET_DETAIL_INIT",
  CONTRACT_WEEKLY_REPORT_GET_DETAIL_FINISH:
    "CONTRACT_WEEKLY_REPORT_GET_DETAIL_FINISH",
  CONTRACT_WEEKLY_REPORT_GET_DETAIL_SUCCESS:
    "CONTRACT_WEEKLY_REPORT_GET_DETAIL_SUCCESS",

  INVOICE_GENERATED: "INVOICE_GENERATED",
  INVOICE_GENERATED_INIT: "INVOICE_GENERATED_INIT",
  INVOICE_GENERATED_FINISH: "INVOICE_GENERATED_FINISH",
  INVOICE_GENERATED_SUCCESS: "INVOICE_GENERATED_SUCCESS",

  // For mail showing summary of tender cead
  GET_CEAD_TENDER_SUMMARY: "GET_CEAD_TENDER_SUMMARY",
  GET_CEAD_TENDER_SUMMARY_INIT: "GET_CEAD_TENDER_SUMMARY_INIT",
  GET_CEAD_TENDER_SUMMARY_FINISH: "GET_CEAD_TENDER_SUMMARY_FINISH",
  GET_CEAD_TENDER_SUMMARY_SUCCESS: "GET_CEAD_TENDER_SUMMARY_SUCCESS",

  // for cead weekly mail generated
  CEAD_WEEKLY_REPORT_MAIL: "CEAD_WEEKLY_REPORT_MAIL",
  CEAD_WEEKLY_REPORT_MAIL_INIT: "CEAD_WEEKLY_REPORT_MAIL_INIT",
  CEAD_WEEKLY_REPORT_MAIL_FINISH: "CEAD_WEEKLY_REPORT_MAIL_FINISH",
  CEAD_WEEKLY_REPORT_MAIL_SUCCESS: "CEAD_WEEKLY_REPORT_MAIL_SUCCESS",
};

// CIRCULAR MAIL TYPES
export const CIRCULAR_MAIL_TYPES = {
  GET_TENDER_CIRCULAR_MAIL: "GET_TENDER_CIRCULAR_MAIL",
  GET_TENDER_CIRCULAR_MAIL_INIT: "GET_TENDER_CIRCULAR_MAIL_INIT",
  GET_TENDER_CIRCULAR_MAIL_FINISH: "GET_TENDER_CIRCULAR_MAIL_FINISH",
  GET_TENDER_CIRCULAR_MAIL_SUCCESS: "GET_TENDER_CIRCULAR_MAIL_SUCCESS",
  GET_TENDER_CIRCULAR_MAIL_UPDATE: "GET_TENDER_CIRCULAR_MAIL_UPDATE",
  GET_TENDER_CIRCULAR_MAIL_PARTIAL: "GET_TENDER_CIRCULAR_MAIL_PARTIAL",

  PERSIST_TENDER_SELECTED_MAIL: "PERSIST_TENDER_SELECTED_MAIL",
  PERSIST_TENDER_SELECTED_MAIL_INIT: "PERSIST_TENDER_SELECTED_MAIL_INIT",
  PERSIST_TENDER_SELECTED_MAIL_FINISH: "PERSIST_TENDER_SELECTED_MAIL_FINISH",
  PERSIST_TENDER_SELECTED_MAIL_SUCCESS: "PERSIST_TENDER_SELECTED_MAIL_SUCCESS",
};

// Tender Persist Data For Mail
export const PERSIST_DATA = {
  PERSIST_DATA_TENDER: "PERSIST_DATA_TENDER",
  PERSIST_DATA_TENDER_INIT: "PERSIST_DATA_TENDER_INIT",
  PERSIST_DATA_TENDER_SUCCESS: "PERSIST_DATA_TENDER_SUCCESS",
  PERSIST_DATA_TENDER_FINISH: "PERSIST_DATA_TENDER_FINISH",

  PERSIST_DATA_TENDER_SUBJECT: "PERSIST_DATA_TENDER_SUBJECT",
  PERSIST_DATA_TENDER_REFCODE: "PERSIST_DATA_TENDER_REFCODE",
  PERSIST_DATA_TENDER_TOS: "PERSIST_DATA_TENDER_TOS",
  PERSIST_DATA_TENDER_CONTENT: "PERSIST_DATA_TENDER_CONTENT",
};

// blog
export const BLOG_TYPES = {
  CREATE_BLOG_MESSAGE: "CREATE_BLOG_MESSAGE",
  CREATE_BLOG_MESSAGE_INIT: "CREATE_BLOG_MESSAGE_INIT",
  CREATE_BLOG_MESSAGE_FINISH: "CREATE_BLOG_MESSAGE_FINISH",
  CREATE_BLOG_MESSAGE_SUCCESS: "CREATE_BLOG_MESSAGE_SUCCESS",

  UPDATE_BLOG_MESSAGE: "UPDATE_BLOG_MESSAGE",
  UPDATE_BLOG_MESSAGE_INIT: "UPDATE_BLOG_MESSAGE_INIT",
  UPDATE_BLOG_MESSAGE_FINISH: "UPDATE_BLOG_MESSAGE_FINISH",
  UPDATE_BLOG_MESSAGE_SUCCESS: "UPDATE_BLOG_MESSAGE_SUCCESS",

  GET_PAGINATION_BLOG_MESSAGE: "GET_PAGINATION_BLOG_MESSAGE",
  GET_PAGINATION_BLOG_MESSAGE_INIT: "GET_PAGINATION_BLOG_MESSAGE_INIT",
  GET_PAGINATION_BLOG_MESSAGE_FINISH: "GET_PAGINATION_BLOG_MESSAGE_FINISH",
  GET_PAGINATION_BLOG_MESSAGE_SUCCESS: "GET_PAGINATION_BLOG_MESSAGE_SUCCESS",

  GET_PAGINATION_BLOG_MESSAGE_CHANGE: "GET_PAGINATION_BLOG_MESSAGE_CHANGE",
  GET_PAGINATION_BLOG_MESSAGE_CHANGE_SUCCESS:
    "GET_PAGINATION_BLOG_MESSAGE_CHANGE_SUCCESS",
};

// FingerPrint Data Fetch
export const FINGER_PRINT_TYPES = {
  CREATE_FINGER_PRINT: "CREATE_FINGER_PRINT",
  CREATE_FINGER_PRINT_INIT: "CREATE_FINGER_PRINT_INIT",
  CREATE_FINGER_PRINT_FINISH: "CREATE_FINGER_PRINT_FINISH",
  CREATE_FINGER_PRINT_SUCCESS: "CREATE_FINGER_PRINT_SUCCESS",
};

// GROUP ChaT
export const GROUPCHAT_TYPES = {
  CREATE_GROUPCHAT_TITLE: "CREATE_GROUPCHAT_TITLE",
  CREATE_GROUPCHAT_TITLE_INIT: "CREATE_GROUPCHAT_TITLE_INIT",
  CREATE_GROUPCHAT_TITLE_FINISH: "CREATE_GROUPCHAT_TITLE_FINISH",
  CREATE_GROUPCHAT_TITLE_SUCCESS: "CREATE_GROUPCHAT_TITLE_SUCCESS",

  GET_GROUPCHAT_TITLE_PAGINATION: "GET_GROUPCHAT_TITLE_PAGINATION",
  GET_GROUPCHAT_TITLE_PAGINATION_INIT: "GET_GROUPCHAT_TITLE_PAGINATION_INIT",
  GET_GROUPCHAT_TITLE_PAGINATION_FINISH:
    "GET_GROUPCHAT_TITLE_PAGINATION_FINISH",
  GET_GROUPCHAT_TITLE_PAGINATION_SUCCESS:
    "GET_GROUPCHAT_TITLE_PAGINATION_SUCCESS",

  GET_GROUPCHAT_TITLE_PAGINATION_MORE: "GET_GROUPCHAT_TITLE_PAGINATION_MORE",
  GET_GROUPCHAT_TITLE_PAGINATION_MORE_SUCCESS:
    "GET_GROUPCHAT_TITLE_PAGINATION_MORE_SUCCESS",

  GET_GROUPCHAT_DETAIL: "GET_GROUPCHAT_DETAIL",
  GET_GROUPCHAT_DETAIL_INIT: "GET_GROUPCHAT_DETAIL_INIT",
  GET_GROUPCHAT_DETAIL_SUCCESS: "GET_GROUPCHAT_DETAIL_SUCCESS",
  GET_GROUPCHAT_DETAIL_FINISH: "GET_GROUPCHAT_DETAIL_FINISH",

  UPDATE_GROUPCHAT_TITLE: "UPDATE_GROUPCHAT_TITLE",
  UPDATE_GROUPCHAT_TITLE_INIT: "UPDATE_GROUPCHAT_TITLE_INIT",
  UPDATE_GROUPCHAT_TITLE_FINISH: "UPDATE_GROUPCHAT_TITLE_FINISH",
  UPDATE_GROUPCHAT_TITLE_SUCCESS: "UPDATE_GROUPCHAT_TITLE_SUCCESS",

  // message
  ADD_GROUPCHAT_MESSAGE: "ADD_GROUPCHAT_MESSAGE",
  ADD_GROUPCHAT_MESSAGE_INIT: "ADD_GROUPCHAT_MESSAGE_INIT",
  ADD_GROUPCHAT_MESSAGE_FINISH: "ADD_GROUPCHAT_MESSAGE_FINISH",
  ADD_GROUPCHAT_MESSAGE_SUCCESS: "ADD_GROUPCHAT_MESSAGE_SUCCESS",

  GET_GROUPCHAT_MESSAGE: "GET_GROUPCHAT_MESSAGE",
  GET_GROUPCHAT_MESSAGE_INIT: "GET_GROUPCHAT_MESSAGE_INIT",
  GET_GROUPCHAT_MESSAGE_FINISH: "GET_GROUPCHAT_MESSAGE_FINISH",
  GET_GROUPCHAT_MESSAGE_SUCCESS: "GET_GROUPCHAT_MESSAGE_SUCCESS",

  // mail read / unread
  UPDATE_GROUPCHAT_READ: "UPDATE_GROUPCHAT_READ",
  UPDATE_GROUPCHAT_READ_INIT: "UPDATE_GROUPCHAT_READ_INIT",
  UPDATE_GROUPCHAT_READ_FINISH: "UPDATE_GROUPCHAT_READ_FINISH",
  UPDATE_GROUPCHAT_READ_SUCCESS: "UPDATE_GROUPCHAT_READ_SUCCESS",

  // countmail
  GET_COUNT_GROUP_MAIL: "GET_COUNT_GROUP_MAIL",
  GET_COUNT_GROUP_MAIL_INIT: "GET_COUNT_GROUP_MAIL_INIT",
  GET_COUNT_GROUP_MAIL_FINISH: "GET_COUNT_GROUP_MAIL_FINISH",
  GET_COUNT_GROUP_MAIL_SUCCESS: "GET_COUNT_GROUP_MAIL_SUCCESS",
};

// Daily Dairy
export const DAILY_DAIRY = {
  TRANSFER_TO_DAILY_DIARY: "TRANSFER_TO_DAILY_DIARY",
  TRANSFER_TO_DAILY_DIARY_INIT: "TRANSFER_TO_DAILY_DIARY_INIT",
  TRANSFER_TO_DAILY_DIARY_SUCCESS: "TRANSFER_TO_DAILY_DIARY_SUCCESS",
  TRANSFER_TO_DAILY_DIARY_FINISH: "TRANSFER_TO_DAILY_DIARY_FINISH",

  UPDATE_DAILY_DIARY: "UPDATE_DAILY_DIARY",
  UPDATE_DAILY_DIARY_INIT: "UPDATE_DAILY_DIARY_INIT",
  UPDATE_DAILY_DIARY_SUCCESS: "UPDATE_DAILY_DIARY_SUCCESS",
  UPDATE_DAILY_DIARY_FINISH: "UPDATE_DAILY_DIARY_FINISH",

  GET_PAGINATION_DAILY_DIARY: "GET_PAGINATION_DAILY_DIARY",
  GET_PAGINATION_DAILY_DIARY_INIT: "GET_PAGINATION_DAILY_DIARY_INIT",
  GET_PAGINATION_DAILY_DIARY_SUCCESS: "GET_PAGINATION_DAILY_DIARY_SUCCESS",
  GET_PAGINATION_DAILY_DIARY_FINISH: "GET_PAGINATION_DAILY_DIARY_FINISH",
  //
  GET_PAGINATION_DAILY_DIARY_UPDATE: "GET_PAGINATION_DAILY_DIARY_UPDATE",
  GET_PAGIGATION_DAILY_DIARY_STATUS: "GET_PAGIGATION_DAILY_DIARY_STATUS",

  COMPLETE_DAILY_DIARY: "COMPLETE_DAILY_DIARY",
  COMPLETE_DAILY_DIARY_INIT: "COMPLETE_DAILY_DIARY_INIT",
  COMPLETE_DAILY_DIARY_SUCCESS: "COMPLETE_DAILY_DIARY_SUCCESS",
  COMPLETE_DAILY_DIARY_FINISH: "COMPLETE_DAILY_DIARY_FINISH",

  SAVE_DAILY_DAIRY: "SAVE_DAILY_DAIRY",
  SAVE_DAILY_DAIRY_INIT: "SAVE_DAILY_DAIRY_INIT",
  SAVE_DAILY_DAIRY_SUCCESS: "SAVE_DAILY_DAIRY_SUCCESS",
  SAVE_DAILY_DAIRY_FINISH: "SAVE_DAILY_DAIRY_FINISH",

  TRANSFER_TO_DAIRY_REMARKS: "TRANSFER_TO_DAIRY_REMARKS",
  TRANSFER_TO_DAIRY_REMARKS_INIT: "TRANSFER_TO_DAIRY_REMARKS_INIT",
  TRANSFER_TO_DAIRY_REMARKS_SUCCESS: "TRANSFER_TO_DAIRY_REMARKS_SUCCESS",
  TRANSFER_TO_DAIRY_REMARKS_FINISH: "TRANSFER_TO_DAIRY_REMARKS_FINISH",

  DELETE_DAILY_DIARY: "DELETE_DAILY_DIARY",
  DELETE_DAILY_DIARY_INIT: "DELETE_DAILY_DIARY_INIT",
  DELETE_DAILY_DIARY_SUCCESS: "DELETE_DAILY_DIARY_SUCCESS",
  DELETE_DAILY_DIARY_FINISH: "DELETE_DAILY_DIARY_FINISH",

  EXPORT_DAILY_DAIRY: "EXPORT_DAILY_DAIRY",
  EXPORT_DAILY_DAIRY_INIT: "EXPORT_DAILY_DAIRY_INIT",
  EXPORT_DAILY_DAIRY_SUCCESS: "EXPORT_DAILY_DAIRY_SUCCESS",
  EXPORT_DAILY_DAIRY_FINISH: "EXPORT_DAILY_DAIRY_FINISH",
};

export const DOWNLOAD_FILE = {
  SAMPLE_DAILY_DIARY: "SAMPLE_DAILY_DIARY",
  SAMPLE_DAILY_DIARY_INIT: "SAMPLE_DAILY_DIARY_INIT",
  SAMPLE_DAILY_DIARY_SUCCESS: "SAMPLE_DAILY_DIARY_SUCCESS",
  SAMPLE_DAILY_DIARY_FINISH: "SAMPLE_DAILY_DIARY_FINISH",
};
