import { AbsentService } from "./absent.api";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
import { ABSENT_TYPES } from "services/types";

const absentService = new AbsentService();

// export const absentPagination = (query, forPagination) =>{
//     return async(dispatch) => {
//         dispatch(init(ABSENT_TYPES.ABSENT_LISTING));
//         const response = await absentService.attendancePaginationByTypeByLeaveDetails(query);
//         dispatch(finish(ABSENT_TYPES.ABSENT_LISTING));
//         if(response.isSuccess) {
//             if(forPagination){
//                 dispatch(success(ABSENT_TYPES.ABSENT_LISTING_PAGINATION , response.data));
//             } else{
//                 dispatch(success(ABSENT_TYPES.ABSENT_LISTING, response.data));
//             }

//         } else if (!response.isSuccess) {
//             dispatch(error(response.errorMessage));
//         }
//     };
// };

export const todayGetAbsentUserList = () => {
  return async (dispatch) => {
    dispatch(init(ABSENT_TYPES.TODAY_ABSENT_LIST_PAGINATION));
    const response = await absentService.todayGetAbsentUserList();
    dispatch(finish(ABSENT_TYPES.TODAY_ABSENT_LIST_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(ABSENT_TYPES.TODAY_ABSENT_LIST_PAGINATION, response?.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const noticeBoardListing = (query) => {
  return async (dispatch) => {
    dispatch(init(ABSENT_TYPES.NOTICE_BOARD_PAGINATION));
    const response = await absentService.noticeBoardListing(query);
    dispatch(finish(ABSENT_TYPES.NOTICE_BOARD_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(ABSENT_TYPES.NOTICE_BOARD_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const noticeBoardAdd = (body, query) => {
  return async (dispatch) => {
    dispatch(init(ABSENT_TYPES.NOTICE_BOARD_ADD));
    const response = await absentService.noticeBoardAdd(body);
    dispatch(finish(ABSENT_TYPES.NOTICE_BOARD_ADD));
    if (response.isSuccess) {
      message.success("Notice Added");
      dispatch(noticePagination(query));
      dispatch(success(ABSENT_TYPES.NOTICE_BOARD_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateNoticeBoard = (id, body) => {
  return async (dispatch) => {
    dispatch(init(ABSENT_TYPES.NOTICE_BOARD_UPDATE));
    const response = await absentService.updateNoticeBoard(id, body);
    dispatch(finish(ABSENT_TYPES.NOTICE_BOARD_UPDATE));
    if (response.isSuccess) {
      dispatch(success(ABSENT_TYPES.NOTICE_BOARD_UPDATE, response.data));
      dispatch(noticePagination(`maxResultCount=10&skipCount=0`));
      message.success("Notice Board  updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const noticePagination = (query) => {
  return async (dispatch) => {
    dispatch(init(ABSENT_TYPES.NOTICE_PAGINATION));
    const response = await absentService.getNoticeBoardPagination(query);
    dispatch(finish(ABSENT_TYPES.NOTICE_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(ABSENT_TYPES.NOTICE_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getAttendancePagination = (query) => {
  return async (dispatch) => {
    dispatch(init(ABSENT_TYPES.ATTENDANCE_PAGINATION));
    const response = await absentService.getAllUsers(query);
    dispatch(finish(ABSENT_TYPES.ATTENDANCE_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(ABSENT_TYPES.ATTENDANCE_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
