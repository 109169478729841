import { Image } from "antd";
import React from "react";
import man from "../../assets/images/icons/man.png";

const NoContent = () => {
  return (
    <div className="dashboards__visitors--none">
      <Image className="data-no-img" src={man} />
      <span>There is no information.</span>
    </div>
  );
};

export default NoContent;
