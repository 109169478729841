import React, { useState, useEffect } from "react";
import { Button, Modal, Select, Pagination, Form, Table } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { complaintPagination } from "services/redux/complaint/action";
import { isEmpty } from "lodash";
import moment from "moment";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";
import { complaintStatusUpdate } from "services/redux/complaint/action";

const ComplaintView = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [complaintData, setComplaintData] = useState([]);
  const [updateStatusValue, setUpdateStatusValue] = useState();
  const [updatePaginationId, setUpdatePaginationId] = useState();
  const [updateComplain, setUpdateComplain] = useState("");

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Start Date",
      dataIndex: "creationTime",
      key: "creationTime",
    },
    {
      title:"Completion Date",
      dataIndex:"endDate",
      key:"endDate"
    }
  ];

  const { complaintPaginationResponse } = useSelector(
    (state) => state.complaint
  );
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isEmpty(allEventStatusListing)) {
      dispatch(getStatusEventListing());
    }
  }, []);


  const [form] = Form.useForm();
  const statusListingState = useSelector((state) => state.statusListing);
  const { allEventStatusListing } = statusListingState;
  const pageSize = 3;
  const getData = (MaxResultCount = pageSize, SkipCount = 0) => {
    let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;
    dispatch(complaintPagination(appendUrl));
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
    getData(pageSize, (page - 1) * pageSize);
  };
  useEffect(() => {
    if (!isEmpty(updatePaginationId) && !isEmpty(updateStatusValue)) {
      let values = {
        complainId: updatePaginationId,
        statusId: updateStatusValue,
      };
      const timeoutId = setTimeout(
        () => dispatch(complaintStatusUpdate(values)),
        1000
      );
      return () => clearTimeout(timeoutId);
    }
  }, [updateStatusValue]);

  useEffect(() => {
    if (!isEmpty(complaintPaginationResponse)) {
      setComplaintData(complaintPaginationResponse.items);
    }
  }, [complaintPaginationResponse]);

  return (
    <>
      <Table
        columns={columns}
        className={`organizer__table contact-table`}
        dataSource={complaintData}
        pagination={false}
      />
    </>
  );
};

export default ComplaintView;
