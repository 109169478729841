import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";

export class GuestEventsService {
  getAllGuestEvents(query) {
    let url = `${appBaseUrl}/organizerEvent/guestEventsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  getGuestEventsStatuses() {
    let url = `${appBaseUrl}/organizerEvent/guestEventStatusListing`;
    let data = getService(url);
    return data;
  }

  addGuestEvent(body) {
    let url = `${appBaseUrl}/organizerEvent/guestEventAdd`;
    let data = postService(url, body);
    return data;
  }

  updateGuestEvent(id, body) {
    let url = `${appBaseUrl}/organizerEvent/guestEventUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  guestEventListing(appendUrl) {
    let url = `${appBaseUrl}/requisitionManagement/guestEntertainmentGetPaginationListing?${appendUrl}`;
    let data = getService(url);
    return data;
  }
}
