import { PlusOutlined, EyeOutlined } from "@ant-design/icons";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import {
  Button,
  Checkbox,
  Dropdown,
  Image,
  Menu,
  Skeleton,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
const day = "assets/svg/internationalDay.svg";
const man = "assets/images/icons/man.png";

const PrivateEvents = ({
  calendarRemainingData,
  rotate,
  setRotate,
  setVisibleEventFormDrawer,
  setEventFormSelect,
  setNewTask,
  type,
  handleNewMessage,
  setNewId,
  setModalVisible,
  calendarEventsLoading,
  isWatcher,
  isAssignee,
  isSelf,
  setIsWatcher,
  setIsAssignee,
  setIsSelf,
}) => {
  // const [isWatcher, setIsWatcher] = useState(false);
  // const [isAssignee, setIsAssignee] = useState(false);
  // const [isSelf, setIsSelf] = useState(true);
  const [appointments, setAppointments] = useState();

  useEffect(() => {
    // const filteredData = calendarRemainingData?.filter((x) => {
    //   return JSON.stringify(x?.eventCreatorUserName) === sessionStorage?.name;
    // });

    // if (isWatcher) {
    //   setAppointments(calendarRemainingData);
    // } else {
    //   setAppointments(filteredData);
    // }

    // added
    const filteredData = calendarRemainingData.filter((x) => {
      const isCurrentUserCreator =
        JSON.stringify(x?.eventCreatorUserName) === sessionStorage?.name;
      const isNotWatcherOrAssignee =
        x?.isWatcher === false && x?.isAsignee === false;

      if (isWatcher && !isAssignee && !isSelf) {
        return x?.isWatcher === true;
      } else if (!isWatcher && isAssignee && !isSelf) {
        return x?.isAsignee === true;
      } else if (!isWatcher && !isAssignee && isSelf) {
        return isCurrentUserCreator && isNotWatcherOrAssignee;
      } else if (isWatcher && isAssignee && !isSelf) {
        return x?.isWatcher === true || x?.isAsignee === true;
      } else if (!isWatcher && isAssignee && isSelf) {
        return x?.isAsignee === true || isCurrentUserCreator;
      } else if (isWatcher && !isAssignee && isSelf) {
        return x?.isWatcher === true || isCurrentUserCreator;
      } else {
        return true;
      }
    });

    setAppointments(filteredData);
  }, [calendarRemainingData, isWatcher, isAssignee, isSelf]);

  // console.log("calendarRemainingData", calendarRemainingData);

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setIsWatcher(!isWatcher);
        }}
      >
        <Checkbox checked={isWatcher} /> Watcher
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsAssignee(!isAssignee);
        }}
      >
        <Checkbox checked={isAssignee} /> Assignee
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsSelf(!isSelf);
        }}
      >
        <Checkbox checked={isSelf} /> Self
      </Menu.Item>
    </Menu>
  );

  const tenderDays = (date) => {
    const momentDate = moment.utc(date, "YYYY-MM-DD");
    const momentNow = moment().startOf("day").utc(date, "YYYY-MM-DD");
    const diffDays = Math.abs(momentNow.diff(momentDate, "days"));
    if (diffDays === 0) return "Today";
    if (diffDays < 30) {
      return diffDays + "d";
    } else if (diffDays < 365) {
      return Math.trunc(diffDays / 30) + "m";
    } else {
      return Math.trunc(diffDays / 365) + "y";
    }
  };

  return (
    <>
      <div
        style={{
          backfaceVisibility: "hidden",
          backgroundColor: "white",
        }}
      >
        <div className="dashboards__bodyWrap" style={{ marginBottom: "5px" }}>
          <h4 className="dashboards__body--title">
            All Appointments ({calendarRemainingData?.length})
            <span
              onClick={() => {
                setRotate(rotate + 1);
              }}
              style={{
                marginLeft: "10px",
                height: "30px",
                marginBottom: "10px",
              }}
            >
              <img
                src="./assets/svg/toggle-arrow.svg"
                height={15}
                alt="complete"
              />
            </span>
          </h4>
          <div style={{ display: "flex", gap: "10px", marginLeft: "auto" }}>
            <Button className="contact__header__btn-primary">
              <PlusOutlined
                onClick={() => {
                  setVisibleEventFormDrawer(true);
                  setEventFormSelect("event");
                  setNewTask(true);
                }}
              />
            </Button>
          </div>
          <Dropdown.Button
            overlay={menu}
            className="new_menu"
            style={{
              marginBottom: "5px",
              width: "20px",
              border: "none",
              marginLeft: "2px",
            }}
          />
        </div>

        <div>
          <ul className="dashboards__todolists" style={{ height: "280px" }}>
            {appointments?.length > 0 ? (
              appointments?.map((list, i) => (
                <>
                  {list.event !== "day" ? (
                    <li
                      key={i}
                      className="dashboards__events--list"
                      style={{ padding: "0px" }}
                    >
                      <Tooltip
                        title={
                          (sessionStorage.userId !==
                          JSON.stringify(list.eventCreatorId)
                            ? "[" +
                              list.eventCreatorUserName?.toUpperCase() +
                              "]"
                            : "") +
                          " " +
                          list.title
                        }
                      >
                        <div
                          className={`dashboards__events--listTitle ${list?.event} `}
                          style={{ flexBasis: "40%" }}
                        >
                          {i + 1}.{" "}
                          <Typography.Text style={{ color: "gray" }}>
                            {" "}
                            {sessionStorage.userId !==
                            JSON.stringify(list.eventCreatorId)
                              ? "[" +
                                list.eventCreatorUserName?.toUpperCase() +
                                "]"
                              : ""}{" "}
                          </Typography.Text>
                          <Typography.Text style={{ color: list?.color }}>
                            {list.title}
                          </Typography.Text>
                        </div>
                      </Tooltip>

                      {type === "private" && (
                        <div className="dashboards__events--date">
                          {moment().format("DD-MMM-YYYY") ===
                          moment(list.start).format("DD-MMM-YYYY") ? (
                            <span style={{ flexShrink: 0 }}>
                              {moment(list.start).format("h:mm A")}-
                              {moment(list.end).format("h:mm A")}{" "}
                            </span>
                          ) : (
                            <>
                              <span style={{ flexShrink: 0 }}>
                                {moment(list.start).format("h:mm A")}{" "}
                                {moment(list.start).format("DD-MMM-YYYY")}
                              </span>
                              <span
                                className="remaining-wrapper"
                                style={{
                                  borderRadius: "34px",
                                  backgroundColor: "#e9e9e9",
                                  padding: "3px 8px",
                                  color: "#6d6d6d",
                                  marginLeft: "8px",
                                  flexShrink: 0,
                                }}
                              >
                                {tenderDays(list.start)}
                              </span>
                              <span style={{ width: "10px" }}></span>
                            </>
                          )}
                          <Link
                            onClick={() => {
                              handleNewMessage(list?.id);
                            }}
                          >
                            {/* <img
                                      src={internalMessageGreen}
                                      height="12px"
                                      alt="message "
                                      style={{ paddingLeft: "10px" }}
                                    /> */}
                          </Link>
                          <div
                            style={{
                              display: "inline",
                              marginLeft: "5px",
                            }}
                            onClick={() => {
                              setNewId(list?.id);
                              setModalVisible(true);
                            }}
                          >
                            {" "}
                            <EyeOutlined
                            // style={{ marginLeft: "5px" }}
                            />
                          </div>
                        </div>
                      )}
                      {type === "public" && (
                        <div className="dashboards__events--date">
                          <span style={{ flexShrink: 0 }}>
                            {" "}
                            {moment(list.start).format("DD-MMM-YYYY")}
                          </span>
                        </div>
                      )}
                    </li>
                  ) : (
                    <li key={i} className="dashboards__events--list specialDay">
                      <div className="dashboards__events--listTitle day">
                        <Image src={day} /> {list.eventTitle}
                      </div>
                      <div
                        className="dashboards__events--date"
                        style={{ width: "32%" }}
                      >
                        {list.date}
                      </div>
                    </li>
                  )}
                </>
              ))
            ) : !calendarEventsLoading ? (
              <div className="dashboards__visitors--none">
                <Image
                  className="data-no-img"
                  src={man}
                  height={50}
                  width={50}
                />
                <span>There is no information.</span>
              </div>
            ) : (
              <Skeleton />
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PrivateEvents;
