import React, { useEffect, useState } from "react";
import { TabPane } from "react-bootstrap";
import { Button, DatePicker, Form, Image, Table } from "antd";
import mailApp from "../../../../assets/images/icons/mail-inbox-app.png";
import moment from "moment";
import getColumnsDashboard from "../Tables/Columns";
import { LogoImage } from "utils/imageConstants";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import useRoles from "hooks/useRoles";
import { fingerPrintAdd } from "services/redux/fingerPrint/action";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

const TabOne = ({
  onFinish1,
  startDate,
  defaultDate,
  setStartDate,
  loader,
  setLoader,
  attendancePaginationResponse,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formTest] = Form.useForm();
  const columns = getColumnsDashboard();
  const { isRoleHr } = useRoles();
  const { RangePicker } = DatePicker;
  const [loadingApi, setLoadingApi] = useState(false);

  const {
    fingerPrintAddResponse,
    fingerPrintAddLoading,
  } = useSelector((state) => state.fingerPrintReducer);

  useEffect(() => {
    if (!isEmpty(fingerPrintAddResponse)) {
      setLoadingApi(false);
    }
    setLoadingApi(false);
  }, [fingerPrintAddResponse]);

  const disabledDate = (current) => {
    return current && current >= moment().startOf('day');
  };

  const handleFinish = (values) => {
    let fromDate = null;
    let toDate = null;

    if(values) {
      fromDate = moment(values?.date[0]).format("YYYY-MM-DD");
      toDate = moment(values?.date[1]).format("YYYY-MM-DD");

      // console.log("fromDate: ", fromDate)
      // console.log("toDate: ", toDate)

      let body = {
        dateFrom: fromDate,
        dateTo: toDate
      }
      // console.log("body: ", body)
      dispatch(fingerPrintAdd(body));
      setLoadingApi(true);
    }
  }

  return (
    <>
      <div className="calander_body">
        <div className="calander_body">
          <h5 className="dashboards__mailInfo--title">
            <Image src={mailApp} />
            Attendence
          </h5>

          {isRoleHr ? (
            <Form form={formTest} onFinish={handleFinish} style={{ padding: "10px 17px"}}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <div></div>
              <div
                className=""
                style={{
                  display: "flex",
                }}
              >
                <Form.Item name="date">
                  <RangePicker disabledDate={disabledDate} />
                </Form.Item>
                <Button
                  type="primary"
                  style={{
                    height: "100%",
                    // padding: "0 17px",
                    marginLeft: "10px",
                  }}
                  htmlType="submit"
                  loading={loadingApi}
                >
                  Fetch Data
                </Button>
              </div>
            </div>
          </Form>
          ) : null}

          

          <Form form1={form} onFinish={onFinish1}>
            <div
              style={{
                float: "left",
                marginLeft: 15,
                paddingTop: 15,
              }}
            >
              Date:
              {startDate === ""
                ? `Today  ${moment(new Date()).format("DD-MMM-YYYY")}`
                : startDate}
            </div>
            <div
              className="date_checker"
              style={{
                width: 235,
                display: "flex",
                marginLeft: "auto",
                marginRight: 70,
              }}
            >
              <Form.Item name="SearchToDate">
                <DatePicker
                  placeholder=""
                  defaultValue={defaultDate}
                  style={{ width: 178 }}
                  onChange={(value) => {
                    setStartDate(
                      value ? moment(value?._d).format("DD-MMM-YYYY") : ""
                    );
                  }}
                />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  // marginTop: "5px",
                  paddingLeft: 10,
                }}
              >
                <Button
                  style={{ height: 38, width: 100 }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => setLoader(true)}
                  loading={loader}
                >
                  Search
                </Button>
              </div>
            </div>
          </Form>

          <Table
            columns={columns}
            rowKey={(record) => record.appUserId}
            className={`organizer__tables contact-table attendance-table`}
            dataSource={attendancePaginationResponse?.items}
            pagination={false}
            loading={{
              indicator: (
                <img src={LogoImage} height="auto" width="50px" alt="logo" />
              ),
              spinning: loader,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TabOne;
