import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import TutorialCategoryTemplate from "./TutorialCategoryTemplate";
import TutorialCategoryTable from "./TutorialCategoryTable";
import {
  actionForGetTutorialListing,
  actionForUpdateTutorial,
  actionForGetTutorialCategoryListing,
} from "services/redux/admin/Tutorial/action";

function TutorialCategoryListing() {
  const {
    getTutorialListingResp,
    getTutorialListingLoading,
    getTutorialCategoryListingResp,
    getTutorialCategoryListingLoading,
  } = useSelector((state) => state.tutorialReducer);

  const dispatch = useDispatch();

  const [openForm, setOpenForm] = useState({
    drawer: false,
    modal: false,
    edit: false,
  });
  const [updateData, setUpdateData] = useState();
  const [tutorialCategoryListing, setTutorialCategoryListing] = useState([]);

  useEffect(() => {
    dispatch(actionForGetTutorialCategoryListing());
  }, []);

  useEffect(() => {
    if (!isEmpty(getTutorialCategoryListingResp)) {
      setTutorialCategoryListing(getTutorialCategoryListingResp);
    }
  }, [dispatch, getTutorialCategoryListingResp]);

  // console.log("tutorialCategoryListing: ", tutorialCategoryListing);

  return (
    <>
      <div className="layout">
        <div className="organizer events">
          <div className="all-header-wrapper">
            <div className="meeting__flex">
              <h3 className="header__all">Tutorial Category</h3>
              <div className="organizerHoliday__middle--wrap">
                <Button
                  className="btn-primary"
                  onClick={() => {
                    setOpenForm({ drawer: true, modal: false });
                  }}
                >
                  <PlusOutlined /> New Category
                </Button>
              </div>
            </div>
          </div>
          <TutorialCategoryTemplate
            category={tutorialCategoryListing}
            openForm={openForm}
            setOpenForm={setOpenForm}
            updateData={updateData}
            setUpdateData={setUpdateData}
          />
          <TutorialCategoryTable
            setOpenForm={setOpenForm}
            setUpdateData={setUpdateData}
          />
        </div>
      </div>
    </>
  );
}

export default TutorialCategoryListing;
