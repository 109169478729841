import { Table } from "antd";
import React, { useEffect } from "react";
import getColumns from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { getProcurement } from "services/redux/requistions/procurement/action";

const ApprovalTableProcurement = () => {
  const dispatch = useDispatch();
  const { procurementApprovalPagination, procurementApprovalRespLoading } =
    useSelector((state) => state.procurement);

  useEffect(() => {
    const body = {
      IsPendingRequisition: true,
    };
    dispatch(getProcurement(body, "approval"));
  }, []);

  const columns = getColumns().filter(
    (column) => column.key !== "procurementApplicationStatus"
  );

  return (
    <Table
      className="organizer__table contact-table procurement_table_approval"
      columns={columns}
      rowKey="appUserId"
      dataSource={procurementApprovalPagination?.procurementApplications}
      loading={procurementApprovalRespLoading}
    />
  );
};

export default ApprovalTableProcurement;
