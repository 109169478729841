import moment from "moment";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Layout,
  Row,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";

const { Header } = Layout;

const CustomRow = ({ label, value }) => {
  return (
    <Row>
      <Col span={8}>
        <Typography style={{ marginBottom: 4 }}>{label}</Typography>
      </Col>
      <Col span={16}>
        <Form.Item>
          <Input autoComplete="off" type="text" readOnly value={value} />
        </Form.Item>
      </Col>
    </Row>
  );
};

const CustomComment = ({ label, value }) => {
  return (
    <Layout.Content
      style={{
        backgroundColor: "#FDFDFD",
        marginTop: 5,
      }}
    >
      <Form.Item label={label}>
        <TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          autoComplete="off"
          value={value}
          readOnly
        />
      </Form.Item>
    </Layout.Content>
  );
};

const ProcurementDetails = ({
  procurementDetails,
  setHideProcrumentDrawer,
  drawerVisibility,
  setProcurementDetails,
}) => {
  const [form] = useForm();

  console.log("procurementDetails", procurementDetails);

  const supervisorName =
    procurementDetails[0]?.procurementApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarks != null &&
          x?.approveRejectRemarks !== undefined
      )
      ?.map((x) => x?.participantName);

  const supervisorDescription =
    procurementDetails[0]?.procurementApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarks !== null &&
          x?.approveRejectRemarks !== undefined
      )
      ?.map((x) => x?.approveRejectRemarks);

  const accountantName =
    procurementDetails[0]?.procurementApplicationParticipantStatuses?.accountantList

      ?.filter(
        (x) =>
          x?.approveRejectRemarks != null &&
          x?.approveRejectRemarks !== undefined
      )
      ?.map((x) => x?.participantName);

  const accountantDescription =
    procurementDetails[0]?.procurementApplicationParticipantStatuses?.accountantList

      ?.filter(
        (x) =>
          x?.approveRejectRemarks !== null &&
          x?.approveRejectRemarks !== undefined
      )
      ?.map((x) => x?.approveRejectRemarks);

  const managementName =
    procurementDetails[0]?.procurementApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarks != null &&
          x?.approveRejectRemarks !== undefined
      )
      ?.map((x) => x?.participantName);

  const managementDescription =
    procurementDetails[0]?.procurementApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarks !== null &&
          x?.approveRejectRemarks !== undefined
      )
      ?.map((x) => x?.approveRejectRemarks);

  return (
    <>
      <Drawer
        width={460}
        maskClosable={false}
        onClose={setHideProcrumentDrawer}
        visible={drawerVisibility}
        closable={false}
        footer={
          <Layout.Content
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => {
                setHideProcrumentDrawer(false);
              }}
            >
              Cancel
            </Button>
          </Layout.Content>
        }
      >
        <Header className="drawer__header">
          <Typography className="drawer__header--title">
            Procrument Details
          </Typography>
        </Header>
        <Form layout="vertical" form={form}>
          <>
            <Layout.Content
              style={{
                backgroundColor: "#FAFAFA",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
              }}
            >
              <CustomRow
                label="Company Name"
                value={procurementDetails[0]?.sisterCompanies?.name}
              />
              <CustomRow
                label="Vendor Name"
                value={procurementDetails[0]?.vendorName}
              />
              <CustomRow
                label="Subject"
                value={procurementDetails[0]?.subject}
              />
              <CustomRow
                label="Description"
                value={procurementDetails[0]?.description}
              />
              <CustomRow
                label="Amount"
                value={procurementDetails[0]?.tentativeAmount}
              />
              <CustomRow
                label="Rate Reasonability"
                value={procurementDetails[0]?.rateReasonableComment}
              />
              <CustomRow
                label="Application Status"
                value={
                  procurementDetails[0]?.procurementApplicationStatus
                    ?.displayName
                }
              />
              {procurementDetails[0]?.procurementApplicationStatus
                ?.displayName === "Accountant Approved" && (
                <CustomComment
                  label={`Accountant's Comment (${accountantName})`}
                  value={accountantDescription}
                />
              )}
              {procurementDetails[0]?.procurementApplicationStatus
                ?.displayName === "Supervisor Approved" && (
                <>
                  <CustomComment
                    label={`Accountant's Comment (${accountantName})`}
                    value={accountantDescription}
                  />
                  <CustomComment
                    label={`Supervisor's Comment (${supervisorName})`}
                    value={supervisorDescription}
                  />
                </>
              )}
              {procurementDetails[0]?.procurementApplicationStatus
                ?.displayName === "Final Approved" && (
                <>
                  <CustomComment
                    label={`Accountant's Comment (${accountantName})`}
                    value={accountantDescription}
                  />
                  <CustomComment
                    label={`Supervisor's Comment (${supervisorName})`}
                    value={supervisorDescription}
                  />
                  <CustomComment
                    label={`Management's Comment (${managementName})`}
                    value={managementDescription}
                  />
                </>
              )}
            </Layout.Content>
          </>
        </Form>
      </Drawer>
    </>
  );
};
export default ProcurementDetails;
