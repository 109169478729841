import { message } from "antd";
import { REQUISITION_TYPES } from "../../../types";
import { LeaveApplication } from "./api";
import { init, success, finish, error } from "services/common";
import { replyInstanceMessage } from "services/redux/email/instanceMessageCenter/action";
const leaveApplication = new LeaveApplication();
export const getLeaveApplicationPagination = (query, forPagination, type) => {
  if (type === "approval") {
    return async (dispatch) => {
      dispatch(init(REQUISITION_TYPES.LEAVE_APPLICATION_APPROVAL_PAGINATION));
      const response = await leaveApplication.getLeaveApplicationPagination(
        query
      );
      dispatch(finish(REQUISITION_TYPES.LEAVE_APPLICATION_APPROVAL_PAGINATION));
      if (response.isSuccess) {
        if (forPagination) {
          dispatch(
            success(
              REQUISITION_TYPES.LEAVE_APPLICATION_APPROVAL_PAGINATION,
              response.data
            )
          );
        } else {
          dispatch(
            success(
              REQUISITION_TYPES.LEAVE_APPLICATION_APPROVAL_PAGINATION,
              response.data
            )
          );
        }
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
      }
    };
  } else {
    return async (dispatch) => {
      dispatch(init(REQUISITION_TYPES.LEAVE_APPLICATION_PAGINATION));
      const response = await leaveApplication.getLeaveApplicationPagination(
        query
      );
      dispatch(finish(REQUISITION_TYPES.LEAVE_APPLICATION_PAGINATION));
      if (response.isSuccess) {
        if (forPagination) {
          dispatch(
            success(
              REQUISITION_TYPES.LEAVE_APPLICATION_PAGINATON_CHANGE,
              response.data
            )
          );
        } else {
          dispatch(
            success(
              REQUISITION_TYPES.LEAVE_APPLICATION_PAGINATION,
              response.data
            )
          );
        }
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
      }
    };
  }
};

export const leaveApplicationListing = () => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.LEAVE_APPLICATION_LISTING));
    const response = await leaveApplication.getLeaveApplicationListing();
    dispatch(finish(REQUISITION_TYPES.LEAVE_APPLICATION_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(REQUISITION_TYPES.LEAVE_APPLICATION_LISTING, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getSupervisorDetails = () => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.SUPERVISOR_DETAILS));
    const response = await leaveApplication.getSupervisorDetails();
    dispatch(finish(REQUISITION_TYPES.SUPERVISOR_DETAILS));
    if (response.isSuccess) {
      dispatch(success(REQUISITION_TYPES.SUPERVISOR_DETAILS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addLeaveApplication = (body) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.ADD_LEAVE_APPLICATION));
    const response = await leaveApplication.addLeaveApplication(body);
    dispatch(finish(REQUISITION_TYPES.ADD_LEAVE_APPLICATION));
    if (response.isSuccess) {
      dispatch(success(REQUISITION_TYPES.ADD_LEAVE_APPLICATION, response.data));
      dispatch(getLeaveApplicationPagination(`maxResultCount=10&skipCount=0`));
      dispatch(getRemainingLeaveApplicationTypesForEmployee());
      message.success("Leave Application forwaded successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getRemainingLeaveApplicationTypesForEmployee = (query) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.REMAINING_LEAVE_APPLICATION_TYPES));
    const response =
      await leaveApplication.getRemainingLeaveApplicationTypesForEmployee(
        query
      );
    dispatch(finish(REQUISITION_TYPES.REMAINING_LEAVE_APPLICATION_TYPES));
    if (response.isSuccess) {
      dispatch(
        success(
          REQUISITION_TYPES.REMAINING_LEAVE_APPLICATION_TYPES,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getLeaveApplicationGetDetailsWithHistoryAndRemaining = (id) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.LEAVE_APP_DETAILS_WITH_HISTORY));
    const response =
      await leaveApplication.getLeaveApplicationGetDetailsWithHistoryAndRemaining(
        id
      );
    dispatch(finish(REQUISITION_TYPES.LEAVE_APP_DETAILS_WITH_HISTORY));
    if (response.isSuccess) {
      dispatch(
        success(REQUISITION_TYPES.LEAVE_APP_DETAILS_WITH_HISTORY, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getLeaveApplicationDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.LEAVE_APPLICATION_GET_DETAILS));
    const response = await leaveApplication.getLeaveApplicationDetails(id);
    dispatch(finish(REQUISITION_TYPES.LEAVE_APPLICATION_GET_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(REQUISITION_TYPES.LEAVE_APPLICATION_GET_DETAILS, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const leaveApplicationApproveReject = (body, status) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.LEAVE_APPLICATION_APPROVE_REJECT));
    const response = await leaveApplication.leaveApplicationApproveReject(body);
    dispatch(finish(REQUISITION_TYPES.LEAVE_APPLICATION_APPROVE_REJECT));
    if (response.isSuccess) {
      dispatch(
        success(
          REQUISITION_TYPES.LEAVE_APPLICATION_APPROVE_REJECT,
          response.data
        )
      );
      dispatch(
        success(REQUISITION_TYPES.LEAVE_APP_DETAILS_WITH_HISTORY, response.data)
      );
      message.success(`Leave Application ${status} successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const cancelLeaveApplication = (id) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.LEAVE_APPLICATION_CANCEL));
    const response = await leaveApplication.leaveApplicationCancel(id);
    if (response.isSuccess) {
      dispatch(
        success(REQUISITION_TYPES.LEAVE_APPLICATION_CANCEL, response.data)
      );
      message.success("Leave is deleted sucessfully");
      dispatch(finish(REQUISITION_TYPES.LEAVE_APPLICATION_CANCEL));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
      message.error(response.errorMessage);
    }
  };
};
