import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from 'antd';
import { validateMessages } from 'utils/misc';
import { modalityAdd, modalityUpdate } from 'services/redux/contact/contact.action';

const { Header, Footer } = Layout;
const { Option } = Select;

const ModalityAddUpdateDrawer = ({ onAddUpdateModalityClose, drawerVisibility, isUpdateModality }) => {
	const { modalityAddResponse, modalityUpdateResponse } = useSelector((state) => state.contact);

	const [form] = Form.useForm();

	form.resetFields();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isUpdateModality) return;
		form.setFieldsValue({
			...isUpdateModality,
		});
	}, [isUpdateModality]);

	useEffect(() => {
		onAddUpdateModalityClose();
	}, [modalityAddResponse, modalityUpdateResponse]);

	const onSubmit = async () => {
		try {
			let values = await form.validateFields();
			if (isUpdateModality) {
				dispatch(modalityUpdate(isUpdateModality.id, values));
			} else {
				dispatch(modalityAdd(values));
			}
		} catch (errorInfo) {
			console.error('Failed:', errorInfo);
		}
	};

	return (
		<Drawer visible={drawerVisibility} width={472} maskClosable={false} className="drawer" onClose={onAddUpdateModalityClose} closable={false}>
			<Header className="drawer__header">
				<h4 className="drawer__header--title">{`${isUpdateModality ? 'Update' : 'Create'} Modality Name`}</h4>
			</Header>

			<Form layout="vertical" form={form} name="control-hooks" validateMessages={validateMessages} className="drawer-form-wrapper">
				<div className="drawer-form-row">
					<div className="drawer-form-group">
						<Form.Item className="form__group" name="title" label="Modality Name" rules={[{ required: true }]}>
							<Input autoComplete='off' />
						</Form.Item>
					</div>
				</div>
				<div className="model-footer">
					<Button onClick={onAddUpdateModalityClose}>Cancel</Button>
					<Button onClick={onSubmit} type="primary">
						Submit
					</Button>
				</div>
			</Form>

		</Drawer>
	);
};

export default ModalityAddUpdateDrawer;
