import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Input,
  Select,
  Layout,
} from "antd";
import { validateMessages } from "utils/misc";

import { organizationTypeAdd } from "services/redux/organizer/typeListing/action";
import { isEmpty } from "lodash";
import {
  noticeBoardAdd,
  updateNoticeBoard,
  noticeBoardListing,
} from "services/redux/absent/absent.action";
import moment from "moment";
const { Header, Footer } = Layout;

const { Option } = Select;
const NoticeListDrawer = ({
  drawerVisibility,
  isUpdateCountry,
  onAddUpdateCountryClose,
  onCloseNoticeBoard,
}) => {
  const [form] = Form.useForm();
  const [complainDetail, setComplaintDetail] = useState();
  const [startDatee, setStartDatee] = useState(null);

  form.resetFields();
  const dispatch = useDispatch();
  const {
    noticePaginationResponse,
    updateNoticeBoardResp,
    noticePaginationLoading,
  } = useSelector((state) => state.absent);
  // console.log(noticePaginationResponse,"testtesttsss")

  // useEffect(() => {
  //   if (!isUpdateCountry) return;
  //   form.setFieldsValue({
  //     ...isUpdateCountry,
  //   });
  // }, [isUpdateCountry]);
  useEffect(() => {
    if (!isUpdateCountry) {
      form.resetFields();
      return;
    } else {
      form.setFieldsValue({
        ...isUpdateCountry,
        startDate: moment(isUpdateCountry.startDate),
        endDate: moment(isUpdateCountry.endDate),
      });
    }
  }, [isUpdateCountry]);
  useEffect(() => {
    onAddUpdateCountryClose();
  }, [noticePaginationResponse, updateNoticeBoardResp]);

  // Handle start date selection

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (isUpdateCountry) {
        dispatch(updateNoticeBoard(isUpdateCountry.id, values));
      } else {
        dispatch(noticeBoardAdd(values));
      }
    } catch (errorInfo) {
      console.error("failed", errorInfo);
    }
  };
  // console.log(complainDetail,"complainDetail")
  // const onSubmit = async () => {
  //   try {

  //     // if (!isEmpty(isUpdateCountry)) {
  //     //   // body.id = isUpdateCountry.id;
  //     //   status = 'Updated'
  //     // }
  //     let values = await form.validateFields();
  //     if(!isEmpty(complainDetail)){
  //       let body = {
  //         ...values,
  //         startDate: moment(values.startDate).format('YYYY/MM/DD'),
  //         endDate: moment(values.endDate).format('YYYY/MM/DD'),
  //       }
  //       let status = 'Added';
  //       dispatch(updateNoticeBoard(isUpdateCountry.id,body));
  //     } else  if(isEmpty(complainDetail)){
  //       dispatch(noticeBoardAdd(values));
  //     }
  //   }
  //   catch (errorInfo) {
  //     console.error("Failed:", errorInfo);
  //   }
  // };
  useEffect(() => {
    if (!isEmpty(noticePaginationResponse)) {
      setTimeout(() => {
        onCloseNoticeBoard(true);
        form.resetFields();
      }, 500);
      setComplaintDetail(noticePaginationResponse);
    }
  }, [noticePaginationResponse]);

  // useEffect(() => {
  //   onCloseNoticeBoard(true);
  //   form.resetFields();
  // },[noticeBoardAdd]);

  return (
    <Drawer
      visible={drawerVisibility}
      onClose={onAddUpdateCountryClose}
      width={472}
      maskClosable={false}
      className="drawer"
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">Notice Board</h4>
      </Header>
      <Form
        form={form}
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        name="control-hooks"
        validateMessages={validateMessages}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <Form.Item
            className="form__group"
            label="Title"
            name="title"
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </div>
        <div className="drawer-form-row">
          <Form.Item
            className="form__group"
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </div>

        <div className="drawer-form-row">
          <Form.Item
            label="Start Date"
            name="startDate"
            className="form__group"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
            initialValue={moment()}
          >
            <DatePicker
              defaultValue={moment()}
              format="YYYY/MM/DD"
              autoComplete="off"
            />
          </Form.Item>
        </div>

        <div className="drawer-form-row">
          <Form.Item
            label="Due Date"
            name="endDate"
            className="form__group"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
            initialValue={moment()}
          >
            <DatePicker
              defaultValue={moment()}
              disabledDate={(current) => {
                return (
                  current &&
                  (form.getFieldValue("startDate")
                    ? current < form.getFieldValue("startDate")
                    : current < moment())
                );
              }}
              format="YYYY/MM/DD"
              autoComplete="off"
            />
          </Form.Item>
        </div>

        <div className="model-footer">
          <Button onClick={onAddUpdateCountryClose}>Cancel</Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default NoticeListDrawer;
