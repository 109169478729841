import { Card, Col, Row } from "antd";
import cross from "assets/images/cross.png";
import tick from "assets/images/tick.png";
import { isEmpty } from "lodash";
import { handleDateFormat } from "utils/dateFormat";
import { formatKey } from "utils/formatKey";

export default function ClosureDetails({ closureDetails }) {
  const keysToRemove = [
    "id",
    "isActive",
    "tenderCEAD_Id",
    "closedUserId",
    "closedUser" /* Add other keys to remove here */,
  ];
  keysToRemove.forEach((key) => {
    delete closureDetails?.[key];
  });

  return (
    <>
      <Card>
        {!isEmpty(closureDetails) ? (
          <Row>
            {Object.entries(closureDetails)?.map(([key, value]) => (
              <>
                <Col offset={0} span={6} className="cead-details-text">
                  {formatKey(key)}:
                </Col>
                <Col span={18} className="cead-details-text">
                  {" "}
                  {value === true ? (
                    <img src={tick} alt="" height={10} />
                  ) : value === false ? (
                    <img src={cross} alt="" height={10} />
                  ) : key === "closedDate" ? (
                    handleDateFormat(value, "DD-MMM-YYYY")
                  ) : (
                    value
                  )}
                </Col>
              </>
            ))}
          </Row>
        ) : (
          <div className="cead-details-text-no-data">No Available Data</div>
        )}
      </Card>
    </>
  );
}
