import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  Layout,
  Modal,
} from "antd";
import { isEmpty } from "lodash";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SafetyCertificateTwoTone,
  CarryOutTwoTone,
  CarryOutOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";
import {
  actionForDailyDairyComplete,
  actionForSaveDailyDairy,
  actionForDeleteDiary,
} from "services/redux/dailyDairy/action";
import { toDoMarkAsComplete } from "services/redux/organizer/todoLists/action";
import ChatDetails from "views/GlobalEmailChat";

function DailyDairy(props) {
  let {
    data,
    loading,
    setSelectedRecordData,
    setIsDailyDairyModalOpen,
    showResetButton,
    selectedAdvancedSearchDate,
    setSelectedAdvancedSearchDate,
  } = props;
  // console.log("data: ", data)

  const { allEventStatusListing, eventStatusListingLoading } = useSelector(
    (state) => state.statusListing
  );

  const dispatch = useDispatch();

  const [status, setStatus] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // added
  const [showModalForTaskDetail, setShowModalForTasKDetail] = useState(false);
  const [taskId, setTaskId] = useState();
  const [todoDetails, setTodoDetails] = useState([]);
  const [hideInternalMessage, setHideInternalMessage] = useState(false);
  const [chatHeight, setChatHeight] = useState(false);
  const [smallChatHeight, setSmallChatHeight] = useState(false);
  const [chatBox, setchatBox] = useState(true);
  const [showChat, setShowChat] = useState(false);

  const [form] = Form.useForm();
  const [formToday] = Form.useForm();
  const { Option } = Select;
  const { Content } = Layout;

  useEffect(() => {
    dispatch(getStatusEventListing());
  }, []);

  const handleTodaySaveDiary = () => {
    // console.log("data.items", data.items);

    const InomplteStatusItems =
      data?.items &&
      data.items.filter((item) => item.statusName !== "Completed");

    if (InomplteStatusItems.length > 0) {
      setIsModalOpen(true);

      //
      const initialFormValues = {};
      InomplteStatusItems.forEach((item) => {
        initialFormValues[`remarks_${item.id}`] = item.remarks || "";
      });
      formToday.setFieldsValue(initialFormValues);
    } else {
      console.log("All items are completed, no modal needed");
    }
  };

  function handleOk() {
    formToday.submit();
    setIsModalOpen(false);
  }

  function handleCancel() {
    formToday.resetFields();
    setIsModalOpen(false);
  }

  const handleOnFinishFormModal = async (values) => {
    let payload = {
      dailyDiaryRemarks: values,
    };
    console.log("Form Values Submitted: ", payload);
    dispatch(actionForSaveDailyDairy(payload));
  };

  const handleCompleteTask = (record) => {
    if (record?.id && record?.todoEventId) {
      // console.log("record: ", record)
      dispatch(actionForDailyDairyComplete(record?.id));
      // console.log("record?.todoEventId: ", record?.todoEventId);
      // dispatch(toDoMarkAsComplete(record?.todoEventId));
    }
  };

  const handleDeleteDiary = (record) => {
    // console.log("record: ", record);
    if (record?.id) {
      dispatch(actionForDeleteDiary(record?.id));
    }
  };

  const handleCancelChatModal = () => {
    setShowModalForTasKDetail(false);
  };

  const columns = [
    {
      title: "S.N",
      render: (text, row, index) => (
        <div style={{ paddingLeft: 5 }}>{index + 1}</div>
      ),
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, record) => {
        return (
          <span
            style={{
              textDecoration:
                record?.statusName === "Completed" ? "line-through" : "none",
              // color: record?.statusDto?.colorHexValue ? record?.statusDto?.colorHexValue: "none",
              color: "rgb(23, 132, 206)",
              fontWeight: record?.statusName === "Completed" ? 500 : 600,
              fontSize: "12px",
            }}
            className="dashboards__todolists--div  text-one-line"
            title={record?.title}
          >
            {record?.title}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Date",
      dataIndex: "dailyDairyDate",
      key: "dailyDairyDate",
      render: (text) => {
        const formatedDate = moment(text).format("YYYY-MM-DD");
        return <span>{formatedDate}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "12%",
      render: (_, record) => (
        <>
          <EyeOutlined
            onClick={() => {
              console.log("record: ", record);
              setShowModalForTasKDetail(true);
              setTaskId(record.id);
              setTodoDetails(record);
              setHideInternalMessage(true);
              setSmallChatHeight(false);
              setChatHeight(false);
              setchatBox(false);
            }}
          />
          <EditOutlined
            className="daily-diary-icon"
            onClick={() => {
              setSelectedRecordData(record);
              setIsDailyDairyModalOpen(true);
            }}
          />

          {record?.statusName && record?.statusName === "Completed" ? (
            <>
              <CarryOutTwoTone
                onClick={() => handleCompleteTask(record)}
                className="daily-diary-icon"
              />
            </>
          ) : (
            <>
              <CarryOutOutlined
                onClick={() => handleCompleteTask(record)}
                className="daily-diary-icon"
              />
            </>
          )}

          {record.statusDto?.name !== "Completed" && (
            <DeleteOutlined
              className="daily-diary-icon"
              onClick={() => handleDeleteDiary(record)}
            />
          )}
        </>
      ),
    },
  ];

  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");

  const isValidDate = (date) => {
    console.log("Date params: ", date);
    return date === today || date === yesterday;
  };
  const isDateValid =
    selectedAdvancedSearchDate === today ||
    selectedAdvancedSearchDate === yesterday;

  return (
    <>
      <div
        className="dashboards__todolists-diary"
        style={{
          paddingTop: "10px",
          height: "380px",
        }}
      >
        <Table
          loading={loading}
          columns={columns}
          dataSource={data?.items}
          pagination={false}
          scroll={{ x: "max-content", y: 520 }}
        />

        {isDateValid && (
          <Content
            style={{
              display: "flex",
              justifyContent: "end",
              paddingTop: "8px",
            }}
          >
            {/* {!loading && data != null && !showResetButton && !isEmpty(data.items) && ( */}
            {!loading && data != null && !isEmpty(data.items) && (
              <Button
                type="primary"
                onClick={handleTodaySaveDiary}
                style={{
                  padding: "0px 10px",
                }}
              >
                Save
              </Button>
            )}
          </Content>
        )}

        <Modal
          title="Incomplete Task"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          fotter={null}
          height={400}
        >
          <Form form={formToday} onFinish={handleOnFinishFormModal}>
            {data &&
              data.items
                .filter((item) => item.statusName !== "Completed")
                .map((item) => (
                  <div key={item.id}>
                    <h3>{item.title}</h3>
                    <Form.Item
                      // name={`remarks_${item.id}`}
                      name={item.id}
                      label="Remarks"
                      rules={[
                        { required: true, message: "Please enter remarks" },
                      ]}
                    >
                      <Input.TextArea rows={4} defaultValue={item.remarks} />
                    </Form.Item>
                  </div>
                ))}
          </Form>
        </Modal>

        {/* Internal Chat Message */}
        <Modal
          title="Daily Dairy Modal"
          visible={showModalForTaskDetail}
          onCancel={handleCancelChatModal}
          footer={null}
        >
          <ChatDetails
            // allToDoEvents={allToDoEvents}
            taskId={taskId}
            setShowChat={setShowChat}
            setSmallChatHeight={setSmallChatHeight}
            setChatHeight={setChatHeight}
            chatHeight={chatHeight}
            smallChatHeight={smallChatHeight}
            setchatBox={setchatBox}
            chatBox={chatBox}
            module="DailyTask"
            origin="Dashboard"
            setHideInternalMessage={setHideInternalMessage}
            hideInternalMessage={hideInternalMessage}
            todoDetails={todoDetails}
            isPrivate={false}
          />
        </Modal>
      </div>
    </>
  );
}

export default DailyDairy;
