import { Tooltip, Typography } from "antd";
import moment from "moment";
import React from "react";
import { showNumber } from "utils/showNumber";
import { handleSpecialDate } from "utils/tooltipSepecialDate";

const getDlColumnsCEMAT = ({ widthh, modalShow }) => {
    return [
        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "left",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    Company
                </Typography.Paragraph>
            ),
            key: "type",
            dataIndex: "type",
            render: (_, record) => {
                return (
                    <Typography.Paragraph
                        className="antd-paragraphg"
                        style={{
                            textAlign: "left",
                            fontSize: widthh < 1550 ? 13 : 15,
                            marginBottom: `0px !important`,
                        }}
                    >
                        {record?.companyName}
                    </Typography.Paragraph>
                );
            },
        },
        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "right",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    Core Biz Income(A)
                </Typography.Paragraph>
            ),
            key: "income",
            dataIndex: "income",
            render: (_, record) => (
                <Tooltip
                    // title={moment(record?.incomeUpdatedDate).format("YYYY MMM DD")}
                    title={handleSpecialDate(record?.incomeUpdatedDate)}
                >
                    <Typography.Paragraph
                        style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
                        onDoubleClick={() => {
                            modalShow(record?.budgetQueryParam, "fee income", "incomeQueryParam", record?.incomeQueryParam, "Core Biz Income(A)");
                        }}
                    >
                        {showNumber(record?.income)}
                    </Typography.Paragraph>
                </Tooltip>
            ),
        },
        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "right",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    Other Income (B)
                </Typography.Paragraph>
            ),
            key: "incomeOther",
            dataIndex: "incomeOther",
            render: (_, record) => (
                <Tooltip
                    // title={moment(record?.incomeUpdatedDate).format("YYYY MMM DD")}
                    title={handleSpecialDate(record?.incomeUpdatedDate)}
                >
                    <Typography.Paragraph
                        style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
                        onDoubleClick={() => {
                            modalShow(record?.budgetQueryParam, "other income", "incomeOtherQueryParam", record?.incomeOtherQueryParam, "Other Income (B)");
                        }}
                    >
                        {showNumber(record?.incomeOther)}
                    </Typography.Paragraph>
                </Tooltip>
            ),
        },
        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "right",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    Total Income{" "}
                </Typography.Paragraph>
            ),
            key: "incomeOther",
            dataIndex: "incomeOther",
            render: (_, record) => (
                <Tooltip
                    // title={moment(record?.incomeUpdatedDate).format("YYYY MMM DD")}
                    title={handleSpecialDate(record?.incomeUpdatedDate)}
                >
                    <Typography.Paragraph
                        style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
                        // onDoubleClick={() => {
                        //     modalShow(record?.budgetQueryParam, "total income", "budgetQueryParam", "", "Total Income");
                        // }}
                    >
                        {showNumber(record?.incomeOther + record?.income)}
                    </Typography.Paragraph>
                </Tooltip>
            ),
        },
        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "right",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    Expense
                </Typography.Paragraph>
            ),
            key: "expense",
            dataIndex: "expense",
            render: (_, record) => (
                <Tooltip
                    // title={moment(record?.expenseUpdatedDate).format("YYYY MMM DD")}
                    title={handleSpecialDate(record?.expenseUpdatedDate)}
                >
                    <Typography.Paragraph
                        style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
                        onDoubleClick={() => {
                            modalShow(record?.budgetQueryParam, "expense", "expenseQueryParam", record?.expenseQueryParam, "Expense");
                        }}
                    >
                        {showNumber(record?.expense)}
                    </Typography.Paragraph>
                </Tooltip>
            ),
        },
    ];
};

export default getDlColumnsCEMAT;
