import { Col, Row } from "antd";
import React from "react";

const TaDaBasicDetail = ({ getTadaSingleDetailsResponse }) => {
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={8}>Person:</Col>
        <Col span={16}>
          {
            getTadaSingleDetailsResponse?.tadaApplicationParticipantStatuses
              ?.fromList?.[0]?.participantName
          }
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Purpose of Visit:</Col>
        <Col span={16}>{getTadaSingleDetailsResponse?.purposeOfVisit}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Total Amount Requested:</Col>
        <Col span={16}>
          {getTadaSingleDetailsResponse?.totalAdvanceRequestedAmount}
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Visit days:</Col>
        <Col span={16}>{getTadaSingleDetailsResponse?.visitDays}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Place of Visit:</Col>
        <Col span={16}> {getTadaSingleDetailsResponse?.placeOfVisit}</Col>
      </Row>
    </>
  );
};

export default TaDaBasicDetail;
