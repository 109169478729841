import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService, putService } from "../../../commonServices";

export class LeaveApplication {
  getLeaveApplicationPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationPagination?${query}`;
    let data = getService(url);
    return data;
  }

  getLeaveApplicationListing() {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationListing`;
    let data = getService(url);
    return data;
  }

  getSupervisorDetails() {
    let url = `${appBaseUrl}/requisitionManagement/supervisorDetail`;
    let data = getService(url);
    return data;
  }

  addLeaveApplication(body) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationAdd`;
    let data = postService(url, body, "post");
    return data;
  }

  getRemainingLeaveApplicationTypesForEmployee(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/requisitionManagement/remainingLeaveApplicationTypesForEmployee?${query}`;
    let data = getService(url);
    return data;
  }

  getLeaveApplicationGetDetailsWithHistoryAndRemaining(id) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationGetDetailsWithHistoryAndRemaining/${id}`;
    let data = getService(url);
    return data;
  }

  getLeaveApplicationDetails(id) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  leaveApplicationApproveReject(body) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationApproveReject`;
    let data = postService(url, body, "post");
    return data;
  }

  leaveApplicationCancel(id) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationCancelRequest/${id}`;
    let data = putService(url);
    return data;
  }
}
