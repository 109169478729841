import { useEffect, useState } from "react";
import { Button, Skeleton, Table, Typography, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { EditOutlined, EyeTwoTone } from "@ant-design/icons";
import moment from "moment";
import { handleDateFormat } from "utils/dateFormat";
import { actionForAddCEADWeeklyReportMail } from "services/redux/cead/action";
import { CEADWeeklyReportData } from "views/CEAD/tabs/weeklyReport/report/details";
import ChatDetails from "views/GlobalEmailChat";
import questionCeadWeeklyReport from "utils/questionCeadWeeklyReport";
const internalMessageGreen = "assets/svg/internal-msg-12-green.svg";

const { Text } = Typography;

export default function WeeklyReportDetail(props) {
  const {
    tenderCeadWeeklyReportDetail,
    tenderCeadWeeklyReportDetailLoading,
    tenderCeadContractWeeklyReportSingleDetail,
    tenderCeadContractWeeklyReportSingleDetailLoading,
    ceadWeeklyReportMailLoading,
  } = useSelector((state) => state.ceadReducer);

  const {
    allToDoEvents,
    todoEventsLoading,
    addToDoEventResp,
    addToDoEventRespLoading,
  } = useSelector((state) => state.toDoEvents);

  const dispatch = useDispatch();

  const { setOpenDrawer, setShowWklyRptExtForm, stage, weeklyForm } = props;

  const [singleReportDetail, setWeeklySingleReportDetail] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const datas =
    stage === "execution"
      ? tenderCeadContractWeeklyReportSingleDetail
      : tenderCeadWeeklyReportDetail;
  const loader =
    tenderCeadWeeklyReportDetailLoading ||
    tenderCeadContractWeeklyReportSingleDetailLoading;

  const [createdBy, setCreatedBy] = useState("");

  // Chat
  const [taskId, setTaskId] = useState();
  const [showChat, setShowChat] = useState(false);
  const [chatHeight, setChatHeight] = useState(false);
  const [smallChatHeight, setSmallChatHeight] = useState(false);
  const [chatBox, setchatBox] = useState(true);
  const [hideInternalMessage, setHideInternalMessage] = useState(false);
  const [todoDetails, setTodoDetails] = useState([]);
  //

  useEffect(() => {
    if (!loader && !isEmpty(datas)) {
      const data = Object.keys(datas)
        ?.filter((y) => {
          if (y === "creatorUserDto") {
            setCreatedBy(datas[y]?.employeeName);
          }
          return (
            y !== "id" &&
            y !== "tenderCEAD_Id" &&
            y !== "isActive" &&
            y !== "notes" &&
            y !== "creatorUserDto" &&
            y !== "reportingFromDate" &&
            y !== "reportingToDate" &&
            y !== "applicationType" &&
            y !== "applicationTypeId" &&
            y !== "internalMessage"
          );
        })
        .map((x, index) => {
          return {
            sn: index + 1 + ".",
            title:
              x === "reportingDate"
                ? "Reporting Date"
                : x === "submittedDate"
                ? "Submitted Date"
                : questionCeadWeeklyReport(x),
            remarks:
              x === "reportingDate" || x === "submittedDate"
                ? handleDateFormat(datas?.[x], "DD-MMM-YYYY")
                : datas?.[x],
          };
        });
      setWeeklySingleReportDetail(data);
    }
  }, [loader]);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: "8%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

  return (
    <>
      <Button
        className="w-r-e-b"
        onClick={() => {
          if (stage === "execution") {
            setShowWklyRptExtForm({
              open: true,
              type: "form",
              wklyRptId: datas?.id,
            });
            weeklyForm.setFieldsValue({
              ...datas,
              // reportingFromDate: moment(datas?.reportingFromDate),
              reportingDate: moment(datas?.reportingDate),
            });
          } else {
            setOpenDrawer({
              open: true,
              type: "form",
              wklyRptId: datas?.id,
            });
            weeklyForm.setFieldsValue({
              ...datas,
              // reportingFromDate: moment(datas?.reportingFromDate),
              reportingDate: moment(datas?.reportingDate),
            });
          }
        }}
      >
        <div>
          <EditOutlined /> Edit
        </div>
      </Button>
      {loader ? (
        <Skeleton active />
      ) : (
        <>
          {tenderCeadWeeklyReportDetail &&
          tenderCeadWeeklyReportDetail?.submittedDate.includes("0001-01-01") ? (
            <Button
              type="primary"
              onClick={() =>
                dispatch(
                  actionForAddCEADWeeklyReportMail(props.id, { id: props.id })
                )
              }
              loading={ceadWeeklyReportMailLoading}
            >
              Submit Report
            </Button>
          ) : (
            <img
              onClick={() => {
                setIsModalOpen(true);
                setHideInternalMessage(true);
              }}
              src={internalMessageGreen}
              alt=""
              style={{ paddingLeft: "6px", cursor: "pointer" }}
            />
            // <EyeTwoTone
            //   onClick={() => {
            //     setIsModalOpen(true);
            //     setHideInternalMessage(true);
            //   }}
            // />
          )}
          <Table
            columns={columns}
            dataSource={singleReportDetail}
            pagination={false}
          />
          <Text style={{ fontWeight: "bold", margin: "5px 5px" }}>
            Created By : {createdBy}
          </Text>
        </>
      )}

      <Modal
        title="Weekly Report"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        {/* <CEADWeeklyReportData id={tenderCeadWeeklyReportDetail?.id} /> */}
        <ChatDetails
          allToDoEvents={allToDoEvents}
          // taskId={taskId}
          taskId={tenderCeadWeeklyReportDetail?.id}
          setShowChat={setShowChat}
          setSmallChatHeight={setSmallChatHeight}
          setChatHeight={setChatHeight}
          chatHeight={chatHeight}
          smallChatHeight={smallChatHeight}
          setchatBox={setchatBox}
          chatBox={chatBox}
          // module="Task"
          // origin="Dashboard"
          module="CEAD_WeeklyReport"
          origin="cead"
          setHideInternalMessage={setHideInternalMessage}
          hideInternalMessage={hideInternalMessage}
          todoDetails={todoDetails}
          isPrivate={false}
        />
      </Modal>
    </>
  );
}
