import { CALENDAR_EVENTS_TYPES, GLOBAL, REFERENCE__TYPES } from "../../types";
import { uniqBy, sortBy } from "lodash";

const INITIAL_STATE = {
  stageAddResponse: null,
  stageAddLoading: false,
  stagePaginationLoading: false,
  stagePaginationResponse: null,
  stageUpdateResponse: null,
  stageUpdateLoading: false,

  //currency
  allCurrencyListingResponse: [],
  allCurrencyListingLoading: false,

  //reference
  referencetTypeAddResponse: null,
  referencetTypeAddLoading: false,
  referenceTypePaginationLoading: false,
  referenceTypePaginationResponse: null,
  referenceTypeUpdateResponse: null,
  referenceTypeUpdateLoading: false,

  //ALL REFERENCE Listing
  referenceListingResponse: {
    items: [],
    totalCount: 1,
  },
  referenceListingLoading: false,

  // Reference party Listing
  referencePartyListingResponse: null,
  referencePartyListingLoading: false,

  // ADDED circular planning
  referencePartyListingCircularResponse: null,
  referencePartyListingCircularLoading: false,

  // Update
  referencePartyListingCircularUpdateResp: [],
  referencePartyListingCircularUpdateLoading: false,

  // post
  referenceCircularPlanningAddResponse: null,
  referenceCircularPlanningAddLoading: false,

  // DELETE COMPANY
  referenceCompanyDeleteResponse: null,
  referenceComapanyDeleteLoading: false,

  // Reference party remarks history add
  referencePartyHistoryAddResponse: null,
  referencePartyHistoryAddLoading: false,

  // solo Remarks Add
  soloRemarksAddResp: [],
  soloRemarksAddLoading: false,

  // CIRCULAR REMAKRS
  circularPlanningRemarksResp: null,
  circularPlanningRemarksLoading: false,

  // ReferenceParty Remarks HistoryListing
  referencePartyRemarksHistoryListingResponse: null,
  referencePartyRemarksHistoryListingLoading: false,

  // reference company manual add
  referenceCompanyManualAddResponse: null,
  referenceCompanyManualAddLoading: false,

  // update Company by Reference
  updateCompanyReferenceResponse: null,
  updateCompanyReferenceLoading: false,

  // reference party action
  referncePartyActionResponse: null,
  referncePartyActionLoading: true,

  //tender award status
  tenderAwardStatusAddResponse: null,
  tenderAwardStatusAddResponseLoading: false,

  tenderAwardStatusDetailsResponse: null,
  tenderAwardStatusDetailsResponseLoading: false,

  tenderAwardStatusUpdateResponse: null,
  tenderAwardStatusUpdateResponseLoading: false,

  // added
  allCircularPlanningTemplateResp: [],
  allCircularPlanningTemplateLoading: false,

  getCircularPlanningCompanyDtosResp: [],
  getCircularPlanningCompanyDtosLoading: false,

  addCircularPlanningResp: null,
  addCircularPlanningLoading: false,

  updateCircularPlanningCompanyResp: [],
  updateCircularPlanningCompanyLoading: false,

  addImportIntoReferenceCompaniesResp: null,
  addImportIntoReferenceCompaniesLoading: false,

  getCircularPlanningResp: [],
  getCircularPlanningLoading: false,
};

export default function referenceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REFERENCE__TYPES.STAGE_ADD_INIT:
      return {
        stageAddResponse: null,
        stageAddLoading: true,
      };
    case REFERENCE__TYPES.STAGE_ADD_SUCCESS:
      return {
        ...state,
        stageAddResponse: action.payload,
        stageAddLoading: false,
      };
    case REFERENCE__TYPES.STAGE_ADD_FINISH:
      return {
        stageAddResponse: null,
        stageAddLoading: false,
      };

    case REFERENCE__TYPES.STAGE_PAGINATION_INIT:
      return {
        stagePaginationResponse: null,
        stagePaginationLoading: true,
      };
    case REFERENCE__TYPES.STAGE_PAGINATION_SUCCESS:
      return {
        ...state,
        stagePaginationResponse: action.payload,
        stagePaginationLoading: false,
      };
    case REFERENCE__TYPES.STAGE_PAGINATION_FINISH:
      return {
        stagePaginationResponse: null,
        stagePaginationLoading: false,
      };

    case REFERENCE__TYPES.STAGE_UPDATE_INIT:
      return {
        stageUpdateResponse: null,
        stageUpdateLoading: true,
      };
    case REFERENCE__TYPES.STAGE_UPDATE_SUCCESS:
      return {
        ...state,
        stageUpdateResponse: action.payload,
        stageUpdateLoading: false,
      };
    case REFERENCE__TYPES.STAGE_UPDATE_FINISH:
      return {
        stageUpdateResponse: null,
        stageUpdateLoading: false,
      };

    //CURRENCY LISTING
    case REFERENCE__TYPES.CURRENCY_LISTING_INIT:
      return {
        ...state,
        allCurrencyListingResponse: [],
        allCurrencyListingLoading: true,
      };
    case REFERENCE__TYPES.CURRENCY_LISTING_SUCCESS:
      return {
        ...state,
        allCurrencyListingResponse: action.payload,
        allCurrencyListingLoading: false,
      };
    case REFERENCE__TYPES.CURRENCY_LISTING_FINISH:
      return {
        ...state,
        allCurrencyListingLoading: false,
      };

    //Reference Type
    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_INIT:
      return {
        referenceTypeAddResponse: null,
        referenceTypeAddLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_SUCCESS:
      return {
        ...state,
        referenceTypeAddResponse: action.payload,
        referenceTypeAddLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_FINISH:
      return {
        referenceTypeAddResponse: null,
        referenceTypeAddLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_PAGINATION_INIT:
      return {
        referenceTypePaginationResponse: null,
        referenceTypePaginationLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_TYPE_PAGINATION_SUCCESS:
      return {
        ...state,
        referenceTypePaginationResponse: action.payload,
        referenceTypePaginationLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_TYPE_PAGINATION_FINISH:
      return {
        referenceTypePaginationResponse: null,
        referenceTypePaginationLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_INIT:
      return {
        referenceTypeUpdateResponse: null,
        referenceTypeUpdateLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        referenceTypeUpdateResponse: action.payload,
        referenceTypeUpdateLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_FINISH:
      return {
        referenceTypeUpdateResponse: null,
        referenceTypeUpdateLoading: false,
      };

    //all referenceListing
    case REFERENCE__TYPES.REFERENCE_EMAIL_LISTING_INIT:
      return {
        ...state,
        referenceListingLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_EMAIL_LISTING_SUCCESS:
      return {
        ...state,
        referenceListingResponse: {
          items: action.loadMore
            ? uniqBy(
                state.referenceListingResponse.items.concat(
                  action.payload.items
                ),
                "id"
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
        referenceListingLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_EMAIL_LISTING_FINISH:
      return {
        ...state,
        referenceListingLoading: false,
      };

    // Reference PartyListing
    case REFERENCE__TYPES.REFERENCE_PARTY_lISTING_INIT:
      return {
        ...state,
        referencePartyListingResponse: null,
        referencePartyListingLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_PARTY_lISTING_SUCCESS:
      return {
        ...state,
        referencePartyListingResponse: action.payload,
        referencePartyListingLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_PARTY_lISTING_FINISH:
      return {
        ...state,
        referencePartyListingLoading: false,
      };

    // circular planning
    case REFERENCE__TYPES.REFERENCE_PARTY_LISTING_CIRCULAR_INIT:
      return {
        ...state,
        referencePartyListingCircularResponse: null,
        referencePartyListingCircularLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_PARTY_LISTING_CIRCULAR_SUCCESS:
      return {
        ...state,
        referencePartyListingCircularResponse: action.payload,
        referencePartyListingCircularLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_PARTY_LISTING_CIRCULAR_UPDATE:
      let updatedItems =
        state?.referencePartyListingCircularResponse &&
        state?.referencePartyListingCircularResponse?.map((item) => {
          if (action.payload.id === item?.id) {
            return {
              ...action.payload,
            };
          } else {
            return {
              ...item,
            };
          }
        });

      return {
        ...state,
        referencePartyListingCircularResponse: updatedItems,
        referencePartyListingCircularLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_PARTY_LISTING_CIRCULAR_FINISH:
      return {
        ...state,
        referencePartyListingCircularLoading: false,
      };

    // POST circular planning
    case REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_ADD_INIT:
      return {
        ...state,
        referenceCircularPlanningAddResponse: null,
        referenceCircularPlanningAddLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_ADD_SUCCESS:
      const updateItems = state?.referencePartyListingCircularResponse || [];
      return {
        ...state,
        referenceCircularPlanningAddLoading: false,
        referenceCircularPlanningAddResponse: action.payload,
        referencePartyListingCircularResponse: sortBy(
          [...updateItems, action.payload],
          "partyName"
        ),
      };

    case REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_ADD_FINISH:
      return {
        ...state,
        referenceCircularPlanningAddLoading: false,
      };

    // DELETE COMPANY
    case REFERENCE__TYPES.REFERENCE_COMPANY_DELETE_INIT:
      return {
        ...state,
        referenceCompanyDeleteResponse: null,
        referenceComapanyDeleteLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        referenceCompanyDeleteResponse: action.payload,
        referenceComapanyDeleteLoading: false,
        referencePartyListingResponse: state?.referencePartyListingResponse
          ?.map((item) => {
            if (item?.id === action.payload?.id) {
              return action.payload.isActive ? { ...action.payload } : null;
            } else {
              return {
                ...item,
              };
            }
          })
          .filter((item) => item !== null),
      };

    case REFERENCE__TYPES.REFERENCE_COMPANY_DELETE_FINISH:
      return {
        ...state,
        referenceComapanyDeleteLoading: false,
      };

    // reference party remarks history add
    case REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_ADD_INIT:
      return {
        ...state,
        referencePartyHistoryAddLoading: true,
        referencePartyHistoryAddResponse: [],
      };

    case REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_ADD_SUCCESS:
      const lastParty =
        state.referencePartyListingResponse[
          state.referencePartyListingResponse.length - 1
        ];

      const updatedLastParty = {
        ...lastParty,
        remarks: action.payload?.remarks,
      };

      const updatedPartyListingResponse = [
        ...state.referencePartyListingResponse.slice(0, -1),
        updatedLastParty,
      ];

      return {
        ...state,
        referencePartyHistoryAddResponse: action.payload,
        referencePartyHistoryAddLoading: false,
        referencePartyRemarksHistoryListingResponse: [
          // ...state.referencePartyRemarksHistoryListingResponse,
          ...state.referencePartyListingResponse,
          action.payload,
        ],
        referencePartyListingResponse: updatedPartyListingResponse,
      };

    case REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_ADD_FINISH:
      return {
        ...state,
        referencePartyHistoryAddLoading: false,
      };

    // solo REMARKS
    case REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_SOLO_INIT:
      return {
        ...state,
        soloRemarksAddResp: [],
        soloRemarksAddLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_SOLO_SUCCESS:
      const updatedDataSource =
        action.payload?.isDefaultOrCircular === 1
          ? "referencePartyListingCircularResponse"
          : "referencePartyListingResponse";
      return {
        ...state,
        soloRemarksAddResp: action.payload,
        soloRemarksAddLoading: false,
        [updatedDataSource]: state?.[updatedDataSource]?.map((item) => {
          if (item?.contactCompanyId === action.payload?.contactCompanyId) {
            return {
              ...item,
              remarks: action.payload.remarks,
            };
          } else {
            return {
              ...item,
            };
          }
        }),
        referencePartyRemarksHistoryListingResponse: [
          action.payload,
          ...state?.referencePartyRemarksHistoryListingResponse,
        ],
      };

    case REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_SOLO_FINISH:
      return {
        ...state,
        soloRemarksAddLoading: false,
      };

    // CIRCULAR PLANNIG REMARKS
    case REFERENCE__TYPES.REFERENCE_REMARKS_CIRCULAR_PLANNING_INIT:
      return {
        ...state,
        circularPlanningRemarksResp: null,
        circularPlanningRemarksLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_REMARKS_CIRCULAR_PLANNING_SUCCESS:
      const lastPartyCircular =
        state.referencePartyListingCircularResponse[
          state.referencePartyListingCircularResponse.length - 1
        ];

      const updatedLastPartyCircular = {
        ...lastPartyCircular,
        remarks: action.payload?.remarks,
        // modeofCommunication: action.payload?.modeofCommunication,
        // attentionTo: action.payload?.attentionTo,
      };

      const updatedPartyListingCircularResponse = [
        ...state.referencePartyListingCircularResponse.slice(0, -1),
        updatedLastPartyCircular,
      ];

      return {
        ...state,
        circularPlanningRemarksResp: action.payload,
        circularPlanningRemarksLoading: false,
        referencePartyListingCircularResponse:
          updatedPartyListingCircularResponse,
      };

    case REFERENCE__TYPES.REFERENCE_REMARKS_CIRCULAR_PLANNING_FINISH:
      return {
        ...state,
        circularPlanningRemarksLoading: false,
      };

    // ReferencePartyRemarksHisotryListing
    case REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_LISTING_INIT:
      return {
        ...state,
        referencePartyRemarksHistoryListingLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_LISTING_SUCCESS:
      return {
        ...state,
        referencePartyRemarksHistoryListingResponse: action.payload,
        referencePartyRemarksHistoryListingLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_LISTING_FINISH:
      return {
        ...state,
        referencePartyRemarksHistoryListingLoading: false,
      };

    // UPDATE CIRCULAR PLANNING
    case REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_UPDATE_INIT:
      return {
        ...state,
        referencePartyListingCircularUpdateResp: [],
        referencePartyListingCircularUpdateLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_UPDATE_SUCCESS:
      // console.log("REDUCER: action.payload - ", action?.payload);

      return {
        ...state,
        referencePartyListingCircularUpdateResp: action.payload,
        referencePartyListingCircularUpdateLoading: false,
        referencePartyListingCircularResponse:
          state?.referencePartyListingCircularResponse?.map((item) => {
            if (item?.contactCompanyId === action.payload?.contactCompanyId) {
              return {
                ...item,
                modeofCommunication: action.payload.modeofCommunication,
                attentionTo: action.payload.attentionTo,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
      };

    case REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_UPDATE_FINISH:
      return {
        ...state,
        referencePartyListingCircularUpdateLoading: false,
      };

    // ReferenceCompanyManualAdd
    case REFERENCE__TYPES.REFERENCE_COMPANY_MANUAL_ADD_INIT:
      return {
        ...state,
        referenceCompanyManualAddLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_COMPANY_MANUAL_ADD_SUCCESS:
      let updatedItemee = Array.isArray(state?.referencePartyListingResponse)
        ? state.referencePartyListingResponse.map((item) => {
            if (action?.payload?.id === item?.id) {
              return {
                ...item,
                ...action.payload,
              };
            }
            return item;
          })
        : [];

      const exists = updatedItemee.some(
        (item) => item.id === action.payload.id
      );

      if (!exists) {
        updatedItemee.push(action.payload);
      }

      return {
        ...state,
        referenceCompanyManualAddResponse: action.payload,
        referenceCompanyManualAddLoading: false,
        // referencePartyListingResponse: [
        //   ...state.referencePartyListingResponse,
        //   action.payload,
        // ],
        referencePartyListingResponse: updatedItemee,
      };
    case REFERENCE__TYPES.REFERENCE_COMPANY_MANUAL_ADD_FINISH:
      return {
        ...state,
        referenceCompanyManualAddLoading: false,
      };

    // update company by reference
    case REFERENCE__TYPES.UPDATE_REFERENCE_COMPANY_INIT:
      return {
        ...state,
        updateCompanyReferenceLoading: true,
      };
    case REFERENCE__TYPES.UPDATE_REFERENCE_COMPANY_SUCCESS:
      return {
        ...state,
        updateCompanyReferenceResponse: action.payload,
        updateCompanyReferenceLoading: false,
      };
    case REFERENCE__TYPES.UPDATE_REFERENCE_COMPANY_FINISH:
      return {
        ...state,
        updateCompanyReferenceLoading: false,
      };

    // reference party action
    case REFERENCE__TYPES.REFERENCE_PARTY_ACTION_INIT:
      return {
        ...state,
        referncePartyActionLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_PARTY_ACTION_SUCCESS:
      return {
        ...state,
        referncePartyActionResponse: action.payload,
        referncePartyActionLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_PARTY_ACTION_FINISH:
      return {
        ...state,
        referncePartyActionLoading: false,
      };

    //tender award status add
    case REFERENCE__TYPES.TENDER_AWARD_STATUS_ADD_INIT:
      return {
        ...state,
        tenderAwardStatusAddResponse: null,
        tenderAwardStatusAddResponseLoading: true,
      };
    case REFERENCE__TYPES.TENDER_AWARD_STATUS_ADD_SUCCESS:
      return {
        ...state,
        tenderAwardStatusAddResponse: action.payload,
        tenderAwardStatusAddResponseLoading: false,
      };
    case REFERENCE__TYPES.TENDER_AWARD_STATUS_ADD_FINISH:
      return {
        ...state,
        tenderAwardStatusAddResponse: null,
        tenderAwardStatusAddResponseLoading: false,
      };

    // award status update
    case REFERENCE__TYPES.TENDER_AWARD_STATUS_UPDATE_INIT:
      return {
        ...state,
        tenderAwardStatusAddResponse: null,
        tenderAwardStatusAddResponseLoading: true,
      };
    case REFERENCE__TYPES.TENDER_AWARD_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        tenderAwardStatusUpdateResponse: action.payload,
        tenderAwardStatusUpdateResponseLoading: false,
      };
    case REFERENCE__TYPES.TENDER_AWARD_STATUS_UPDATE_FINISH:
      return {
        ...state,
        tenderAwardStatusDetailsResponse: null,
        tenderAwardStatusDetailsResponseLoading: false,
      };

    // award status getdetails
    case REFERENCE__TYPES.TENDER_AWARD_STATUS_DETAILS_INIT:
      return {
        ...state,
        tenderAwardStatusAddResponse: null,
        tenderAwardStatusAddResponseLoading: true,
      };
    case REFERENCE__TYPES.TENDER_AWARD_STATUS_DETAILS_SUCCESS:
      return {
        ...state,
        tenderAwardStatusAddResponse: action.payload,
        tenderAwardStatusAddResponseLoading: false,
      };
    case REFERENCE__TYPES.TENDER_AWARD_STATUS_DETAILS_FINISH:
      return {
        ...state,
        tenderAwardStatusAddResponse: null,
        tenderAwardStatusAddResponseLoading: false,
      };
    case REFERENCE__TYPES.AUTO_REFERENCE_LISTING_INIT:
      return {
        ...state,
        autoReferenceListingResponse: [],
        autoReferenceListingResponseLoading: true,
      };
    case REFERENCE__TYPES.AUTO_REFERENCE_LISTING_SUCCESS:
      return {
        ...state,
        autoReferenceListingResponse: action.payload,
        autoReferenceListingResponseLoading: false,
      };
    case REFERENCE__TYPES.AUTO_REFERENCE_LISTING_FINISH:
      return {
        ...state,
        autoReferenceListingResponseLoading: false,
      };

    case REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD_INIT:
      return {
        ...state,
        emailAutoReferenceAddResponse: [],
        emailAutoReferenceAddResponseLoading: true,
      };

    case REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD_SUCCESS:
      return {
        ...state,
        emailAutoReferenceAddResponse: action.payload,
        emailAutoReferenceAddResponseLoading: false,
      };

    case REFERENCE__TYPES.CONTACT_UPDATE_INIT:
      return {
        ...state,
        contactCompanyResponseLoading: true,
      };
    case REFERENCE__TYPES.CONTACT_UPDATE_SUCCESS:
      const primaryAddress =
        action?.payload?.contactCompanyGroupAddressDtos?.find(
          (x) => x.isPrimaryAddress === true
        );

      const updatedContact = state.referencePartyListingResponse.map(
        (contact) => {
          if (contact.contactCompanyId === primaryAddress?.contactCompanyId) {
            return {
              ...primaryAddress,
            };
          }
          return contact;
        }
      );

      return {
        ...state,
        referencePartyListingResponse: updatedContact,
        contactCompanyResponseLoading: false,
      };

    case REFERENCE__TYPES.CONTACT_UPDATE_FINISH:
      return {
        ...state,
        contactCompanyResponseLoading: false,
      };

    // added
    case REFERENCE__TYPES.GET_ALL_CIRCULARPLANNING_TEMPLATE_INIT:
      return {
        ...state,
        allCircularPlanningTemplateLoading: true,
        allCircularPlanningTemplateResp: [],
      };

    case REFERENCE__TYPES.GET_ALL_CIRCULARPLANNING_TEMPLATE_SUCCESS:
      return {
        ...state,
        allCircularPlanningTemplateLoading: false,
        allCircularPlanningTemplateResp: action.payload,
      };

    case REFERENCE__TYPES.GET_ALL_CIRCULARPLANNING_TEMPLATE_FINISH:
      return {
        ...state,
        allCircularPlanningTemplateLoading: false,
      };

    // DTOS
    case REFERENCE__TYPES.GET_CIRCULARPLANNING_TEMPLATE_INIT:
      return {
        ...state,
        getCircularPlanningCompanyDtosResp: [],
        getCircularPlanningCompanyDtosLoading: true,
      };

    case REFERENCE__TYPES.GET_CIRCULARPLANNING_TEMPLATE_SUCCESS:
      return {
        ...state,
        getCircularPlanningCompanyDtosResp: action.payload,
        getCircularPlanningCompanyDtosLoading: false,
      };

    case REFERENCE__TYPES.GET_CIRCULARPLANNING_TEMPLATE_FINISH:
      return {
        ...state,
        getCircularPlanningCompanyDtosLoading: false,
      };

    // log
    case REFERENCE__TYPES.GET_CIRCULAR_PLANNING_LOG_INIT:
      return {
        ...state,
        // getCircularPlanningResp: [],
        getCircularPlanningLoading: true,
      };

    case REFERENCE__TYPES.GET_CIRCULAR_PLANNING_LOG_SUCCESS:
      return {
        ...state,
        getCircularPlanningResp: action.payload,
        getCircularPlanningLoading: false,
      };

    case REFERENCE__TYPES.GET_CIRCULAR_PLANNING_LOG_FINISH:
      return {
        ...state,
        getCircularPlanningLoading: false,
      };

    case REFERENCE__TYPES.GET_CIRCULAR_PLANNING_LOG_MORE_SUCCESS:
      return {
        ...state,
        getCircularPlanningResp: state.getCircularPlanningResp
          ? {
              items: uniqBy(
                state.getCircularPlanningResp?.items.concat(
                  action.payload.items
                ),
                "id"
              ),
              totalCount: action.payload.totalCount,
            }
          : action.payload,
        getCircularPlanningLoading: action.payload ? false : true,
      };

    // post
    case REFERENCE__TYPES.ADD_CIRCULARPLANNING_TEMPLATE_INIT:
      return {
        ...state,
        addCircularPlanningResp: [],
        addCircularPlanningLoading: true,
      };

    case REFERENCE__TYPES.ADD_CIRCULARPLANNING_TEMPLATE_SUCCESS:
      let updatedData = state.allCircularPlanningTemplateResp
        ? state.allCircularPlanningTemplateResp.reduce((accumulator, item) => {
            if (item.id === action.payload.id) {
              if (action.payload.isactive) {
                return [...accumulator, action.payload];
              } else {
                return accumulator;
              }
            } else {
              return [...accumulator, item];
            }
          }, [])
        : [];

      // if (
      //   !state.allCircularPlanningTemplateResp ||
      //   !state.allCircularPlanningTemplateResp.some(
      //     (item) => item.id === action.payload.id
      //   )
      // ) {
      //   updatedData.push(action.payload);
      // }

      if (
        !state.allCircularPlanningTemplateResp.some(
          (item) => item.id === action.payload.id
        ) &&
        action.payload.isactive
      ) {
        updatedData = [...updatedData, action.payload];
      }

      return {
        ...state,
        addCircularPlanningResp: action.payload,
        addCircularPlanningLoading: false,
        // allCircularPlanningTemplateResp: [...state.allCircularPlanningTemplateResp, action.payload]
        allCircularPlanningTemplateResp: sortBy(updatedData, "title"),
      };

    case REFERENCE__TYPES.ADD_CIRCULARPLANNING_TEMPLATE_FINISH:
      return {
        ...state,
        addCircularPlanningLoading: false,
      };

    // update circular Company
    case REFERENCE__TYPES.UPDATED_CIRCULARPLANNING_COMPANY_INIT:
      return {
        ...state,
        updateCircularPlanningCompanyResp: [],
        updateCircularPlanningCompanyLoading: true,
      };

    case REFERENCE__TYPES.UPDATED_CIRCULARPLANNING_COMPANY_SUCCESS:
      // let updatedData = state.getCircularPlanningCompanyDtosResp?.map((item, key) => {
      //   if(item.id === action.payload.id) {
      //     return { ...action.payload }
      //   } else {
      //     return {
      //       ...item
      //     }
      //   }
      // });

      const updatedItem = state.getCircularPlanningCompanyDtosResp
        ? state.getCircularPlanningCompanyDtosResp.reduce(
            (accumulator, item) => {
              if (item.id === action.payload.id) {
                if (action.payload.isactive) {
                  // console.log("COMBINE: ",[...accumulator, action.payload] )
                  return [...accumulator, action.payload];
                } else {
                  return accumulator;
                }
              } else {
                return [...accumulator, item];
              }
            },
            []
          )
        : [];

      if (
        !state.getCircularPlanningCompanyDtosResp ||
        !state.getCircularPlanningCompanyDtosResp.some(
          (item) => item.id === action.payload.id
        )
      ) {
        updatedItem.push(action.payload);
      }

      return {
        ...state,
        updateCircularPlanningCompanyResp: action.payload,
        updateCircularPlanningCompanyLoading: false,
        getCircularPlanningCompanyDtosResp: updatedItem,
      };

    case REFERENCE__TYPES.UPDATED_CIRCULARPLANNING_COMPANY_FINISH:
      return {
        ...state,
        updateCircularPlanningCompanyLoading: false,
      };

    // import
    case REFERENCE__TYPES.ADD_IMPORT_INTO_REFERENCE_COMPANY_INIT:
      return {
        ...state,
        addImportIntoReferenceCompaniesLoading: true,
      };

    case REFERENCE__TYPES.ADD_IMPORT_INTO_REFERENCE_COMPANY_SUCCESS:
      return {
        ...state,
        addImportIntoReferenceCompaniesResp: action.payload,
        addImportIntoReferenceCompaniesLoading: false,
        referencePartyListingCircularResponse: [
          ...state.referencePartyListingCircularResponse,
          ...action.payload,
        ],
      };

    case REFERENCE__TYPES.ADD_IMPORT_INTO_REFERENCE_COMPANY_FINISH:
      return {
        ...state,
        addImportIntoReferenceCompaniesLoading: false,
      };

    case GLOBAL.GLOBAL_ERROR:
      return {
        ...state,
        // emailAutoReferenceAddResponse : action.payload,
        emailAutoReferenceAddResponseLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
