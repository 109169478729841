import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { employeeProfileDetail } from "services/redux/profile/profile.action";
import { decodedToken } from "utils/misc";
import storage from "../utils/storage";

const useRoles = () => {
  const [role, setRole] = useState([]);
  const token = useSelector(({ auth }) => auth.token);
  // let localStorageId = storage.getItem("userId");
  // console.log("ROles", role);

  useEffect(() => {
    if (token) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const roleData = decodedToken(token);
      setRole(roleData?.role);
    }
  }, [token]);

  // Destructuring roles into boolean flags
  const LimitedRoleDemo = role?.includes("LimitedRoleDemo");
  const isLimitedRoleMailBox = role?.includes("Mailbox");
  const isLimitedRoleForICCL = role?.includes("LimitedRoleForICCL");
  const isLimitedRoleAdmin = role?.includes("admin");
  const isLimitedRoleTutorial = role?.includes("TutorialCreator");
  const isRoleHr = role?.includes("HR");
  const isTenderCompanyAdmin = role?.includes("ReferenceCompanyDelete");
  const isDashboardAttendanceViewer = role?.includes("DashboardAttendenceViewer");

  return {
    LimitedRoleDemo,
    isLimitedRoleMailBox,
    isLimitedRoleForICCL,
    isLimitedRoleAdmin,
    isLimitedRoleTutorial,
    isRoleHr,
    isTenderCompanyAdmin,
    isDashboardAttendanceViewer
  };
};

export default useRoles;
