import { MEETING_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { MeetingService } from "./api";
import { message } from "antd";

const meetingService = new MeetingService();

export const getMeetingEventsPagination = (query) => {
  return async (dispatch) => {
    dispatch(init(MEETING_TYPES.MEETING_LISTS));
    const response = await meetingService.getMeetingEventsPagination(query);
    dispatch(finish(MEETING_TYPES.MEETING_LISTS));
    if (response.isSuccess) {
      dispatch(success(MEETING_TYPES.MEETING_LISTS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addMeetingEvent = (body) => {
  return async (dispatch) => {
    dispatch(init(MEETING_TYPES.ADD_MEETING_EVENT));
    const response = await meetingService.addMeetingEvent(body);
    dispatch(finish(MEETING_TYPES.ADD_MEETING_EVENT));
    if (response.isSuccess) {
      dispatch(success(MEETING_TYPES.ADD_MEETING_EVENT, response.data));
      dispatch(getMeetingEventsPagination(`maxResultCount=10&skipCount=0`));
      message.success('Meeting Event added successfully');
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateMeetingEvent = (body, id) => {
  return async (dispatch) => {
    dispatch(init(MEETING_TYPES.ADD_MEETING_EVENT));
    const response = await meetingService.updateMeetingEvent(body, id);
    dispatch(finish(MEETING_TYPES.ADD_MEETING_EVENT));
    if (response.isSuccess) {
      dispatch(success(MEETING_TYPES.ADD_MEETING_EVENT, response.data));
      dispatch(getMeetingEventsPagination(`maxResultCount=10&skipCount=0`));
      message.success('Meeting Event updated successfully');
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const meetingMarkAsComplete = (id) => {
  return async (dispatch) => {
    dispatch(init(MEETING_TYPES.MEETING_COMPLETE_EVENT));
    const response = await meetingService.meetingMarkAsComplete(id);
    dispatch(finish(MEETING_TYPES.MEETING_COMPLETE_EVENT));
    if (response.isSuccess) {
      dispatch(success(MEETING_TYPES.MEETING_COMPLETE_EVENT, response.data));
      dispatch(getMeetingEventsPagination(`maxResultCount=10&skipCount=0`));
      message.success(`Meeting marked as complete successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const meetingEventAgendaDecisionsList = (id) => {
  return async (dispatch) => {
    dispatch(init(MEETING_TYPES.MEETING_EVENT_AGENDA_DECISION_LIST));
    const response = await meetingService.meetingEventAgendaDecisionsList(id);
    dispatch(finish(MEETING_TYPES.MEETING_EVENT_AGENDA_DECISION_LIST));
    if (response.isSuccess) {
      dispatch(success(MEETING_TYPES.MEETING_EVENT_AGENDA_DECISION_LIST, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addMeetingAgenda = (addAgenda) => {
  return {
    type: MEETING_TYPES.ADD_MEETING_AGENDA,
    addAgenda,
  };
};

export const removeMeetingEventAgenda = (index) => {
  return {
    type: MEETING_TYPES.DELETE_MEETING_AGENDA,
    index
  }
}

export const toggleEditMeetingEventAgenda = (index) => {
  return {
    type: MEETING_TYPES.TOGGLE_EDIT_MEETING_AGENDA,
    index
  }
}