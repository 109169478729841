import axios from "axios";
let cancelToken;
export const postService = async (url, body, method = "POST") => {
  try {
    const resp = await axios(url, {
      method,
      data: body,
    });

    if (resp.status >= 200 && resp.status < 300) {
      return {
        isSuccess: true,
        data: resp.data,
      };
    } else {
      return {
        isSuccess: false,
        errorMessage:
          resp?.data?.error?.message || resp?.data?.error_description,
      };
    }
  } catch (err) {
    return {
      isSuccess: false,
      errorMessage:
        err?.response?.data?.error?.message ||
        err?.response?.data?.error_description,
    };
  }
};

export const putService = async (url, body, method = "PUT") => {
  try {
    const resp = await axios(url, {
      method,
      data: body,
    });

    if (resp.status >= 200 && resp.status < 300) {
      return {
        isSuccess: true,
        data: resp.data,
      };
    } else {
      return {
        isSuccess: false,
        errorMessage:
          resp?.data?.error?.message || resp?.data?.error_description,
      };
    }
  } catch (err) {
    return {
      isSuccess: false,
      errorMessage:
        err?.response?.data?.error?.message ||
        err?.response?.data?.error_description,
    };
  }
};

export const patchService = async (url, body) => {
  try {
    const resp = await axios(url, {
      method: 'PATCH',
      data: body,
    });

    if (resp.status >= 200 && resp.status < 300) {
      return {
        isSuccess: true,
        data: resp.data,
      };
    } else {
      return {
        isSuccess: false,
        errorMessage:
          resp?.data?.error?.message || resp?.data?.error_description,
      };
    }
  } catch (err) {
    return {
      isSuccess: false,
      errorMessage:
        err?.response?.data?.error?.message ||
        err?.response?.data?.error_description ||
        'An unexpected error occurred',
    };
  }
};


export const getService = async (url) => {
  try {
    const resp = await axios(url, {
      method: "GET",
    });

    if (resp.status >= 200 && resp.status < 300) {
      return {
        isSuccess: true,
        data: resp.data,
      };
    } else {
      return {
        isSuccess: false,
        errorMessage: resp.data.error,
      };
    }
  } catch (err) {
    return {
      isSuccess: false,
      errorMessage: err.message,
    };
  }
};

export const getServiceMail = async (url) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel();
  }
  cancelToken = axios.CancelToken.source();

  try {
    const resp = await axios(url, {
      method: "GET",
      cancelToken: cancelToken.token,
    });

    if (resp.status >= 200 && resp.status < 300) {
      return {
        isSuccess: true,
        data: resp.data,
      };
    } else {
      return {
        isSuccess: false,
        errorMessage: resp.data.error,
      };
    }
  } catch (err) {
    // if (axios.isCancel(err)) {
    //   console.log("messageerr", err.message);
    // }
    // console.log("this block is run");
    return {
      isSuccess: false,
      errorMessage: err.message,
      cancelApi: axios.isCancel(),
    };
  }
};

export const getDetails = async (url) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel();
  }
  cancelToken = axios.CancelToken.source();

  try {
    const resp = await axios(url, {
      method: "GET",
      cancelToken: cancelToken.token,
    });

    if (resp.status >= 200 && resp.status < 300) {
      return {
        isSuccess: true,
        data: resp.data,
      };
    } else {
      return {
        isSuccess: false,
        errorMessage: resp.data.error,
      };
    }
  } catch (err) {
    // if (axios.isCancel(err)) {
    //   console.log("messageerr", err.message);
    // }
    return {
      isSuccess: false,
      errorMessage: err.message,
    };
  }
};

export const deletService = async (url, body) => {
  try {
    const resp = await axios(url, {
      method: "DELETE",
      data: body,
    });

    if (resp.status >= 200 && resp.status < 300) {
      return {
        isSuccess: true,
        data: resp.data,
      };
    } else {
      return {
        isSuccess: false,
        errorMessage: resp.data.error,
      };
    }
  } catch (err) {
    return {
      isSuccess: false,
      errorMessage: err.message,
    };
  }
};
