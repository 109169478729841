import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllPrivateEvents,
  getAllPublicEvents,
} from "services/redux/calendar/action";
import {
  organizationTaskEventGetDetails,
  organizerTaskEventAdd,
  organizerTaskEventUpdate,
} from "services/redux/organizer/goals/action";
import { getAllUsers } from "services/remanentCalls";

const GoalsForm = (props) => {
  const {
    organizerEventLabelListing,
    organizerEventPriorityListing,
    onCloseEventFormDrawer,
    dateClickDetails,
    eventTaskDetails,
    setEventTaskDetails,
    setModalVisible,
  } = props;

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;

  const [assignInvite, setAssignInvite] = useState(false);
  const [assignWatcher, setAssignWatcher] = useState(false);
  const [assignAllDay, setAssignAllDay] = useState(false);
  const [meetingInvitees, setMeetingInvitees] = useState();
  const [todoEventCandidateDtos, setTodoEventCandidateDtos] = useState([]);
  const [todoEventCandidateDtos1, setTodoEventCandidateDtos1] = useState([]);
  const [dueDateInitialValue, setDueDateInitialValue] = useState(
    moment().add(30, "minutes")
  );

  const {
    organizerTaskEventAddResp,
    organizerTaskEventAddRespLoading,
    organizerTaskEventUpdateResp,
    organizerTaskEventUpdateRespLoading,
    taskEventGetDetailsResp,
  } = useSelector((state) => state.goalEvents);

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  // let defaulPriority = organizerEventPriorityListing?.filter(
  //   (fav) => fav.name === "Normal"
  // );

  useEffect(() => {
    if (
      !isEmpty(organizerTaskEventAddResp) ||
      !isEmpty(organizerTaskEventUpdateResp)
    ) {
      setTimeout(() => {
        onCloseEventFormDrawer();
        form.resetFields();
        setAssignAllDay(false);
        setEventTaskDetails("");
      }, 500);
    }
  }, [organizerTaskEventAddResp, organizerTaskEventUpdateResp]);

  useEffect(() => {
    if (!isEmpty(dateClickDetails)) {
      form.setFieldsValue({
        startDate: moment(dateClickDetails.date),
        dueDate: moment(dateClickDetails.date),
        allDayOrNot: dateClickDetails.allDay,
      });
    }
  }, [dateClickDetails]);

  useEffect(() => {
    if (eventTaskDetails?.length !== 0) {
      if (!isEmpty(eventTaskDetails?.organiserTaskEventCandidateDtos)) {
        const hasWatcher =
          eventTaskDetails?.organiserTaskEventCandidateDtos?.some(
            (x) => x.isWatcher === true
          );
        setAssignWatcher(hasWatcher);

        const hasAssignee =
          eventTaskDetails?.organiserTaskEventCandidateDtos?.some(
            (x) => x.isPrimary === true
          );
        setAssignInvite(hasAssignee);
      }
      // setTodoEventCandidateDtos(
      //   eventTaskDetails.organiserTaskEventCandidateDtos
      //     .filter((assignee) => assignee.isPrimary)
      //     .map((assigneer) => assigneer)
      // );
      const uniqueEventCandidates =
        eventTaskDetails.organiserTaskEventCandidateDtos
          .filter((assigne) => assigne.isPrimary)
          .reduce((uniqueCandidates, watcher) => {
            if (
              !uniqueCandidates.some(
                (candidate) => candidate.candidateName === watcher.candidateName
              )
            ) {
              uniqueCandidates.push(watcher);
            }
            return uniqueCandidates;
          }, []);

      setTodoEventCandidateDtos(uniqueEventCandidates);

      // setTodoEventCandidateDtos1(
      //   eventTaskDetails.organiserTaskEventCandidateDtos
      //     .filter((watcher) => watcher.isWatcher)
      //     .map((watcher) => watcher)
      // );
      const uniqueEventCandidates1 =
        eventTaskDetails.organiserTaskEventCandidateDtos
          .filter((watcher) => watcher.isWatcher)
          .reduce((uniqueCandidates, watcher) => {
            if (
              !uniqueCandidates.some(
                (candidate) => candidate.candidateName === watcher.candidateName
              )
            ) {
              uniqueCandidates.push(watcher);
            }
            return uniqueCandidates;
          }, []);

      setTodoEventCandidateDtos1(uniqueEventCandidates1);

      form.setFieldsValue({
        startDate: moment(eventTaskDetails.startDate),
        dueDate: moment(eventTaskDetails.dueDate),
        title: eventTaskDetails.title,
        allDayOrNot: eventTaskDetails.allDayOrNot,
        organizerEventLabelId: eventTaskDetails.organizerEventLabelId,
        organizerEventPriorityId: eventTaskDetails.organizerEventPriorityId,
        details: eventTaskDetails.details,
        isComplete: eventTaskDetails.isComplete,
        isActive: eventTaskDetails.isActive,
        organiserTaskEventCandidateDtos:
          eventTaskDetails.organiserTaskEventCandidateDtos
            .filter((assignee) => assignee.isPrimary)
            .map((watcher) => watcher.candidateId),
        organiserTaskEventCandidateDtos1:
          eventTaskDetails.organiserTaskEventCandidateDtos
            .filter((watcher) => watcher.isWatcher)
            .map((watcher) => watcher.candidateId),
      });
    }
    return () => {
      form.resetFields();
    };
  }, [eventTaskDetails]);

  useEffect(() => {
    if (!isEmpty(eventTaskDetails)) {
      setAssignAllDay(eventTaskDetails.allDayOrNot);
    }
  }, [eventTaskDetails]);

  useEffect(() => {
    if (!isEmpty(dateClickDetails)) {
      setAssignAllDay(dateClickDetails.allDay);
    }
  }, [dateClickDetails]);

  const dispatch = useDispatch();
  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {
      // message.error('Failure');
    });

    if (resp && resp.status === 200) {
      setMeetingInvitees(resp.data);
    }
  };

  const changeEmployeeDto = (value, option) => {
    let newTodoEventCandidateDtos = option.map((candidate) => {
      return {
        candidateId: candidate.value,
        candidateName: candidate.title,
        isPrimary: true,
      };
    });
    setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  };
  const changeEmployeeDto1 = (value, option) => {
    let newTodoEventCandidateDtos = option.map((candidate) => {
      return {
        candidateId: candidate.value,
        candidateName: candidate.title,
        isWatcher: true,
      };
    });
    setTodoEventCandidateDtos1(newTodoEventCandidateDtos);
  };

  useEffect(() => {
    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
  }, []);

  const onFinish = (values) => {
    let newValues = {};
    assignAllDay
      ? (newValues = {
          ...values,
          startDate: moment(values.startDate)
            .startOf("day")
            .format("YYYY-MM-DD hh:mm A"),
          dueDate: moment(values.dueDate)
            .endOf("day")
            .format("YYYY-MM-DD hh:mm A"),
        })
      : (newValues = {
          ...values,
          startDate: moment(values.startDate).format("YYYY-MM-DD hh:mm A"),
          dueDate: moment(values.dueDate).format("YYYY-MM-DD hh:mm A"),
        });
    let assigee = assignInvite
      ? form.getFieldValue("organiserTaskEventCandidateDtos")?.map((x) => {
          return {
            candidateId: x,
            isPrimary: true,
          };
        })
      : [];
    let watcher = assignWatcher
      ? form.getFieldValue("organiserTaskEventCandidateDtos1")?.map((x) => {
          return {
            candidateId: x,
            isWatcher: true,
          };
        })
      : [];

    newValues.organiserTaskEventCandidateDtos = [].concat(
      assigee ?? [],
      watcher ?? []
    );
    newValues.setTodoEventCandidateDtos = values?.setTodoEventCandidateDtos;
    if (isEmpty(eventTaskDetails)) {
      dispatch(organizerTaskEventAdd(newValues))
        .then(() => {
          const body = {
            skipCount: 0,
            maxResultCount: 100,
            EventTypeSystemNames: "PERSONAL_APPOINTMENT",
          };
          dispatch(getAllPrivateEvents(body));
        })
        .then(() => {
          const body1 = {
            skipCount: 0,
            maxResultCount: 100,
            EventTypeSystemNames: ["PUBLIC_HOLIDAY", "BIRTHDAY_ANNIVERSARY"],
          };
          dispatch(getAllPublicEvents(body1));
        });
      setAssignInvite(false);
      setAssignWatcher(false);
    } else {
      // dispatch(organizerTaskEventUpdate(newValues, eventTaskDetails.id)).then(
      //   dispatch(organizationTaskEventGetDetails(eventTaskDetails.id))
      // )
      const updateAndFetchEvents = async (newValues, eventId) => {
        try {
          await dispatch(organizerTaskEventUpdate(newValues, eventId));
          await dispatch(organizationTaskEventGetDetails(eventId));
          const body = {
            skipCount: 0,
            maxResultCount: 100,
            EventTypeSystemNames: "PERSONAL_APPOINTMENT",
          };
          await dispatch(getAllPrivateEvents(body));
          const body1 = {
            skipCount: 0,
            maxResultCount: 100,
            EventTypeSystemNames: ["PUBLIC_HOLIDAY", "BIRTHDAY_ANNIVERSARY"],
          };
          await dispatch(getAllPublicEvents(body1));
        } catch (error) {
          console.log(error);
        }
      };
      updateAndFetchEvents(newValues, eventTaskDetails.id);
      setTodoEventCandidateDtos([]);
      setTodoEventCandidateDtos1([]);
      setAssignInvite(false);
      setAssignWatcher(false);
    }
  };

  return (
    <div className="gray__form gray-margin">
      <Form
        className="popup-form-wrapper drawer-form-wrapper"
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              label="Title"
              name="title"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              label="Priority"
              name="organizerEventPriorityId"
              initialValue={
                organizerEventPriorityListing?.find(
                  (priority) => priority.name === "Normal"
                )?.id
              }
              hidden={true}
            >
              <Select placeholder="Select Priority">
                {!isEmpty(organizerEventPriorityListing) &&
                  organizerEventPriorityListing.map((status, index) => {
                    return (
                      <Option value={status.id} key={index}>
                        {status.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              name="allDayOrNot"
              valuePropName="checked"
              className="form__group"
              // label="All Day"
              // wrapperCol={{ span: 12 }}
              // labelCol={{ span: 5 }}
            >
              <Checkbox
                checked={assignAllDay}
                onChange={() => setAssignAllDay(!assignAllDay)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                All Day
              </Checkbox>
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <div className="drawer-flex">
              {assignAllDay ? (
                <>
                  <Form.Item
                    label="Start Date"
                    name="startDate"
                    className="form__group"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      defaultValue={moment()}
                      disabledDate={(current) => {
                        return current && current < moment();
                      }}
                      format="YYYY/MM/DD"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Due Date"
                    name="dueDate"
                    className="form__group"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      defaultValue={moment()}
                      disabledDate={(current) => {
                        return (
                          current &&
                          (form.getFieldValue("startDate")
                            ? current < form.getFieldValue("startDate")
                            : current < moment())
                        );
                      }}
                      format="YYYY/MM/DD"
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    label="Start Date-Time"
                    name="startDate"
                    className="form__group"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      showTime
                      minuteStep={15}
                      defaultValue={moment()}
                      // disabledDate={(current) => {
                      //   return current && current < moment();
                      // }}
                      format="MMMM Do YYYY - h:mm A"
                      onChange={(date) => {
                        // setDueDateInitialValue(
                        //   moment(date)?.add(30, "minutes")
                        // );

                        // form.setFieldsValue(moment(date));
                        const newDueDate = moment(date).add(30, "minutes");
                        setDueDateInitialValue(newDueDate);
                        form.setFieldsValue({ dueDate: newDueDate });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Due Date-Time"
                    name="dueDate"
                    className="form__group"
                    rules={[{ required: true, message: "Required!" }]}
                    initialValue={dueDateInitialValue}
                  >
                    <DatePicker
                      showTime
                      minuteStep={15}
                      defaultValue={dueDateInitialValue}
                      disabledDate={(current) => {
                        return (
                          current &&
                          (form.getFieldValue("startDate")
                            ? current < form.getFieldValue("startDate")
                            : "")
                        );
                      }}
                      format="MMMM Do YYYY - h:mm A"
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </div>
          <div className="drawer-form-group">
            <div className="drawer-flex">
              <Form.Item
                name="eventStatus"
                className="form__group"
                initialValue="free"
              >
                <label style={{ marginRight: 8 }}>Show As:</label>
                <Select
                  defaultValue="free"
                  style={{ width: 120 }}
                  bordered={false}
                  autoComplete="off"
                >
                  <Option value="busy">
                    <label>Busy</label>
                  </Option>
                  <Option value="free">
                    <label>Free</label>
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="isPrivate"
                valuePropName="checked"
                initialValue={true}
                className="form__group"
                // label="Private"
              >
                <Checkbox
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Private
                </Checkbox>
              </Form.Item>
            </div>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              defaultValue="false"
              // name="addInvites"
              // label="Add Invites"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 12 }}
            >
              <Checkbox
                checked={assignInvite}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={() => setAssignInvite(!assignInvite)}
              >
                Add Invites
              </Checkbox>
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              name="organiserTaskEventCandidateDtos"
              className="form__group"
              // label="Person"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                optionFilterProp="label"
                disabled={!assignInvite}
                onChange={changeEmployeeDto}
                autoComplete="off"
              >
                {!isEmpty(meetingInvitees) &&
                  meetingInvitees.map((employee, index) => {
                    return (
                      <Option
                        value={employee.appUserId}
                        title={employee.fullName}
                        key={index}
                        label={[employee.fullName, employee.userName]}
                      >
                        {/* {employee.fullName} */}
                        <div className="demo-option-label-item">
                          <span role="img">{employee.fullName} </span>[
                          {employee?.userName?.toUpperCase()}]
                        </div>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              defaultValue="false"
              // name="addInvites"
              // label="Add Watcher"
              // labelCol={{ span: 5 }}
              // wrapperCol={{ span: 12 }}
            >
              <Checkbox
                checked={assignWatcher}
                onChange={() => setAssignWatcher(!assignWatcher)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* <div style={{ display: "inline" }}>Add Watcher</div> */}
                Add Watcher
              </Checkbox>
            </Form.Item>
          </div>

          <div className="drawer-form-group">
            <Form.Item
              name="organiserTaskEventCandidateDtos1"
              className="form__group"
              // label="Person"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                optionFilterProp="label"
                disabled={!assignWatcher}
                onChange={changeEmployeeDto1}
                autoComplete="off"
              >
                {!isEmpty(meetingInvitees) &&
                  meetingInvitees.map((employee, index) => {
                    return (
                      <Option
                        value={employee.appUserId}
                        title={employee.fullName}
                        key={index}
                        label={[employee.fullName, employee.userName]}
                      >
                        {/* {employee.fullName} */}
                        <div className="demo-option-label-item">
                          <span role="img">{employee.fullName} </span>[
                          {employee?.userName?.toUpperCase()}]
                        </div>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              label="Details"
              name="details"
              // rules={[
              //   {
              //     required: true,
              //     message: "Required!",
              //   },
              // ]}
            >
              <TextArea
                style={{ height: 160 }}
                placeholder="Notes"
                autoComplete="off"
              />
            </Form.Item>
          </div>
        </div>
        <div className="model-footer">
          {eventTaskDetails?.id && (
            <Button
              className="ant-btn ant-btn-dangerous ft-delete-btn"
              onClick={() => {
                dispatch(
                  organizerTaskEventUpdate(
                    { ...eventTaskDetails, isActive: false },
                    eventTaskDetails?.id
                  )
                );
              }}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={() => {
              onCloseEventFormDrawer();
              setAssignAllDay(false);
              form.resetFields();
              setAssignInvite(false);
              setTodoEventCandidateDtos([]);
              setTodoEventCandidateDtos1([]);
              setAssignInvite(false);
              setAssignWatcher(false);
            }}
          >
            Close
          </Button>
          <Button
            className="guest__entry__button"
            type="primary"
            htmlType="submit"
            loading={
              organizerTaskEventAddRespLoading ||
              organizerTaskEventUpdateRespLoading
            }
            disabled={
              organizerTaskEventAddRespLoading ||
              organizerTaskEventUpdateRespLoading
            }
          >
            {eventTaskDetails?.id ? "Update " : "Save"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default GoalsForm;
