import { TodosService } from "./todos.api";
export const TYPES = {
  TODOS_FETCH_SUCCESS: "TODOS_FETCH_SUCCESS",
  TODOS_FETCH_SUCCESS_OFFLINE: "TODOS_FETCH_SUCCESS_OFFLINE",
  TODOS_FETCH_ERROR: "TODOS_FETCH_ERROR",
  TODO_POST_ERROR: "TODO_POST_ERROR",
  TODO_POST_SUCCESS: "TODO_POST_SUCCESS",
  TODO_POST_SUCCESS_OFFLINE: "TODO_POST_SUCCESS_OFFLINE",
};

const todosService = new TodosService();

export const actions = {
  getTodos: (data) => {
    return (dispatch) => {
      todosService
        .getTodos(data)
        .then((response) => {
          dispatch({
            type: TYPES.TODOS_FETCH_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: TYPES.TODOS_FETCH_ERROR,
            payload: error,
          });
        });
    };
  },
  getTodosOffline: (data) => {
    return (dispatch) => {
      dispatch({
        type: TYPES.TODOS_FETCH_SUCCESS_OFFLINE,
      });
    };
  },

  postTodo: (data) => {
    return (dispatch) => {
      todosService
        .postTodo(data)
        .then((response) => {
          dispatch({
            type: TYPES.TODO_POST_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: TYPES.TODO_POST_ERROR,
            payload: error,
          });
        });
    };
  },
  postTodoOffline: (data) => {
    return (dispatch) => {
      dispatch({
        type: TYPES.TODO_POST_SUCCESS_OFFLINE,
        payload: data,
      });
    };
  },
};
