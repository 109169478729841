import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash-es";
import { Redirect, useHistory, useLocation } from "react-router-dom";

// antd
import { Menu, Skeleton } from "antd";

// includes
import { withBaseLayout } from "../../layouts/Base";
import RoleLists from "./Roles/RoleLists";
import OrganizationStructure from "./OrganizationStructure";
import AddUpdateCountry from "./AddUpdateCountry";
import ContactLabelGroup from "./ContactLabelGroup";
import ContactLabel from "./ContactLabel";
import OrganizerLabel from "./OrganizerLabel";
import OrganizerPriority from "./OrganizerPriority";
import CityAddUpdate from "./CityAddUpdate";
import FiscalYear from "./FiscalYear";
import StatusListingAddUpdate from "../AdminSettings/StatusEventListing";
import AddUpdateLeaveApproval from "../AdminSettings/AddUpdateLeaveApproval";
import AddUpdateLoanApproval from "./Authoiry/AddUpdateLoanApproval";
import AddUpdateGuestEntApproval from "./Authoiry/AddUpdateGuestApproval";
import AddUpdateTDDAApproval from "./Authoiry/AddUpdateTDDA";
import ProjectSettings from "./ProjectSetting/Currency";
import Modality from "./ProjectSetting/Modality";
import Sector from "./ProjectSetting/Sector";
import Source from "./SourceSetting/Source";
import PrimaryReference from "./PrimaryReference";
import OrganizationtType from "./OrganizationType";
import Status from "./ProjectSetting/Status";
import Stage from "../AdminSettings/TenderSettings/Stage";
import Type from "../AdminSettings/TenderSettings/Type";
import SisterCompanyListing from "./AddUpdateSisterCompany";
import LeaveFiscalDay from "./LeaveApplication/LeaveFiscalDayAddUpdate";
import LeaveApplicationType from "./LeaveApplication/LeaveApplicationType";
import HonorRollAddUpdate from "views/OfficeResource/HonorRoll";
import HolidayEventAddUpdate from "views/OfficeResource/HolidayEvent";
import NoticeBoardAddUpdate from "views/OfficeResource/NoticeBoard";
import GuestEntry from "views/Oragnizer/guestEntry";
// redux
import { useSelector } from "react-redux";
import NoticeBoard from "views/Home/NoticeBoard";
import EmailShareListing from "./AddUpdateEmailShare/EmailShareListing";
import FeeRemaining from "./FeeRemaining";
import FeeDue from "./FeeDue";
import WhiteIpAddress from "./WhiteIpAddress";

const minimize = "assets/images/icons/minimize.png";
const maximize = "assets/images/icons/maximize.png";
const defaultRoute = "/settings/roles";

const { SubMenu } = Menu;

function AdminSettings() {
  const history = useHistory();

  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );

  const [state, setState] = useState({
    current: null,
    collapsed: false,
  });

  const [grantedPolices, setGrantedPolices] = useState({});

  const handleClick = (e) => {
    history.push(e.key);
  };

  const toggleCollapse = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };

  useEffect(() => {
    if (!isEmpty(applicationConfiguration)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  useEffect(() => {
    if (history.location.pathname === "/settings") {
      history.push(defaultRoute);
    }

    setState({ ...state, current: history.location.pathname });
  }, [history.location.pathname]);

  const renderComponent = (pathname) => {
    switch (pathname) {
      case defaultRoute:
        return !isEmpty(grantedPolices) &&
          grantedPolices["AbpIdentity.Roles"] ? (
          <RoleLists grantedPolices={grantedPolices} />
        ) : (
          <Redirect to="/settings" />
        );
      // case '/settings/roles':
      // 	return !isEmpty(grantedPolices) && grantedPolices['AbpIdentity.Roles'] ? <RoleLists grantedPolices={grantedPolices} /> : <Redirect to="/settings" />;
      case "/settings/add-country":
        return <AddUpdateCountry />;
      case "/settings/add-city":
        return <CityAddUpdate />;
      case "/settings/contact-label-group":
        return <ContactLabelGroup />;
      case "/settings/contact-label":
        return <ContactLabel />;
      case "/settings/organizer-type":
        return <OrganizationtType />;
      case "/settings/primary":
        return <PrimaryReference />;
      case "/settings/organizer-label":
        return <OrganizerLabel />;
      case "/settings/organizer-priority":
        return <OrganizerPriority />;
      case "/settings/status-events-listing":
        return <StatusListingAddUpdate />;
      case "/settings/leave-approval":
        return <AddUpdateLeaveApproval />;
      case "/settings/td-da-approval":
        return <AddUpdateTDDAApproval />;
      case "/settings/loan-apporval":
        return <AddUpdateLoanApproval />;
      case "/settings/guest-exit-apporval":
        return <AddUpdateGuestEntApproval />;
      case "/settings/currency":
        return <ProjectSettings />;
      case "/settings/modality":
        return <Modality />;
      case "/settings/sector":
        return <Sector />;
      case "/settings/source":
        return <Source />;
      case "/settings/status":
        return <Status />;
      case "/settings/stage":
        return <Stage />;
      case "/settings/type":
        return <Type />;
      case "/settings/sister-company-listing":
        return <SisterCompanyListing />;
      case "/settings/fiscal-year":
        return <FiscalYear />;
      case "/settings/leave-fiscal-day":
        return <LeaveFiscalDay />;
      case "/settings/leave-application-type":
        return <LeaveApplicationType />;
      case "/settings/honorRoll":
        return <HonorRollAddUpdate />;
      case "/settings/holidayEvent":
        return <HolidayEventAddUpdate />;
      case "/settings/noticeBoard":
        return <NoticeBoardAddUpdate />;
      case "/settings/guestEntry":
        return <GuestEntry />;
      case "/settings/corporate":
        return <EmailShareListing type="corporate" />;
      case "/settings/email":
        return <EmailShareListing type="email" />;
      case "/settings/feeDue":
        return <FeeDue />;
      case "/settings/feeRemaining":
        return <FeeRemaining />;
      case "/settings/whiteIpAddress":
        return <WhiteIpAddress />;
      default:
        return <OrganizationStructure />;
    }
  };

  if (!state.current) {
    return <Skeleton />;
  }

  return (
    <div className="sidebars">
      <div
        className={`sidebars__menu ${state.collapsed && "text-align-right"}`}
      >
        <div className="toggle__arrow" onClick={toggleCollapse}>
          {state.collapsed ? <img src={maximize} /> : <img src={minimize} />}
        </div>
        <div className="sidebar-title">Admin</div>
        <Menu
          className="sidebars__menu--items"
          selectedKeys={[state.current]}
          mode="inline"
          inlineCollapsed={state.collapsed}
        >
          {/* <Menu.Item key={defaultRoute} onClick={handleClick} className="sidebars__menu--item">
						Organization Structure
					</Menu.Item> */}
          {!isEmpty(grantedPolices) && grantedPolices["AbpIdentity.Roles"] && (
            <Menu.Item
              key={"/settings/roles"}
              onClick={handleClick}
              className="sidebars__menu--item"
            >
              Role
            </Menu.Item>
          )}
          {/* <Menu.Item key="/settings/add-country" onClick={handleClick} className="sidebars__menu--item">
						Country
					</Menu.Item> */}
          {/* <Menu.Item key="/settings/add-city" onClick={handleClick} className="sidebars__menu--item">
						City
					</Menu.Item> */}
          {/* <Menu.Item key="/settings/contact-label-group" onClick={handleClick} className="sidebars__menu--item">
						CLG
					</Menu.Item> */}
          {/* <Menu.Item key="/settings/contact-label" onClick={handleClick} className="sidebars__menu--item">
						CL
					</Menu.Item> */}
          {/* <Menu.Item key="/settings/organizer-type" onClick={handleClick} className="sidebars__menu--item">
						Orgination Layer Type
					</Menu.Item>
					<Menu.Item key="/settings/organizer-label" onClick={handleClick} className="sidebars__menu--item">
						Organizer Label
					</Menu.Item> */}

          {/* <Menu.Item key="/settings/organizer-priority" onClick={handleClick} className="sidebars__menu--item">
						Organizer Priority
					</Menu.Item> */}
          {/* <Menu.Item key="/settings/status-events-listing" onClick={handleClick} className="sidebars__menu--item">
						Status Events Listing
					</Menu.Item> */}
          <Menu.Item
            key="/settings/sister-company-listing"
            onClick={handleClick}
            className="sidebars__menu--item"
          >
            Sister Company
          </Menu.Item>
          <Menu.Item
            key="/settings/fiscal-year"
            onClick={handleClick}
            className="sidebars__menu--item"
          >
            Fiscal Year
          </Menu.Item>
          <Menu.Item
            key="/settings/honorRoll"
            onClick={handleClick}
            className="sidebars__menu--item"
          >
            Honor Roll
          </Menu.Item>
          <Menu.Item
            key="/settings/noticeBoard"
            onClick={handleClick}
            className="sidebars__menu--item"
          >
            Notice Board
          </Menu.Item>
          <Menu.Item
            key="/settings/holidayEvent"
            onClick={handleClick}
            className="sidebars__menu--item"
          >
            Holiday Event
          </Menu.Item>
          <Menu.Item
            key="/settings/guestEntry"
            onClick={handleClick}
            className="sidebars__menu--item"
          >
            Guest Entry
          </Menu.Item>
          <SubMenu title="Email Share" className="userlist__navbar__items">
            <Menu.Item key="/settings/corporate" onClick={handleClick}>
              Corporate
            </Menu.Item>
            <Menu.Item key="/settings/email" onClick={handleClick}>
              Email{" "}
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            key="/settings/feeDue"
            onClick={handleClick}
            className="sidebars__menu--item"
          >
            Fee Due
          </Menu.Item>

          <Menu.Item
            key="/settings/feeRemaining"
            onClick={handleClick}
            className="sidebars__menu--item"
          >
            Fee Remaining
          </Menu.Item>

          <Menu.Item
            key="/settings/whiteIpAddress"
            onClick={handleClick}
            className="sidebars__menu--item"
          >
            WhiteList IP Address
          </Menu.Item>

          {/* <SubMenu style={{ background: 'transparent' }} className="userlist__navbar__items" key="sub1" title="Leave Application" onTitleClick={() => setState({ ...state, openKeys: ['sub1'] })}>
						<Menu.Item key="/settings/leave-fiscal-day" onClick={handleClick} className="sidebars__menu--item">
							Fiscal Day
						</Menu.Item>
						<Menu.Item key="/settings/leave-application-type" onClick={handleClick} className="sidebars__menu--item">
							Application Type
						</Menu.Item>
					</SubMenu> */}
          {/* <SubMenu style={{ background: 'transparent' }} className="userlist__navbar__items" key="sub2" title="Project Setting" onTitleClick={() => setState({ ...state, openKeys: ['sub2'] })}>
						<Menu.Item key="/settings/currency" onClick={handleClick} className="sidebars__menu--item">
							Currency
						</Menu.Item>
						<Menu.Item key="/settings/modality" onClick={handleClick} className="sidebars__menu--item">
							Modality
						</Menu.Item>
						<Menu.Item key="/settings/sector" onClick={handleClick} className="sidebars__menu--item">
							Sector
						</Menu.Item>
						<Menu.Item key="/settings/status" onClick={handleClick} className="sidebars__menu--item">
							Status
						</Menu.Item>
					</SubMenu> */}
          {/* <SubMenu style={{ background: 'transparent' }} className="userlist__navbar__items" key="sub3" title="Tender Setting" onTitleClick={() => setState({ ...state, openKeys: ['sub3'] })}>
						<Menu.Item key="/settings/stage" onClick={handleClick} className="sidebars__menu--item">
							Stage
						</Menu.Item>
						<Menu.Item key="/settings/type" onClick={handleClick} className="sidebars__menu--item">
							Type
						</Menu.Item>
						<Menu.Item key="/settings/primary" onClick={handleClick} className="sidebars__menu--item">
							Primary Reference
						</Menu.Item>
						
					</SubMenu> */}
          {/* <SubMenu style={{ background: 'transparent' }} className="userlist__navbar__items" key="sub4" title="Contact Setting" onTitleClick={() => setState({ ...state, openKeys: ['sub4'] })}>
						<Menu.Item key="/settings/source" onClick={handleClick} className="sidebars__menu--item">
							Source
						</Menu.Item>
						
					</SubMenu> */}
        </Menu>
      </div>
      <div className="main__body admin__body">
        {renderComponent(history.location.pathname)}
      </div>
    </div>
  );
}

// export default withBaseLayout(AdminSettings);
export default AdminSettings;
