import { Table, Form, Input, Select, Button, Row, Col, DatePicker } from "antd";
import { useEffect } from "react";
import { useSelector, useDispatch  } from "react-redux";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";
import { isEmpty } from "lodash";
import { actionForUpdateDailyDairy } from "services/redux/dailyDairy/action";

function AddUpdateDailyDairy(props) {

    let {
        selectedRecordData,
        handleAddUpdateDairy,
    } = props;

    const {
        updateDailyDiaryResp,
        updateDailyDiaryLoading,

    } = useSelector((state) => state.dailyDiaryReducer)

    const statusListingState = useSelector((state) => state.statusListing);
    const { allEventStatusListing } = statusListingState;

    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const { Option } = Select;
    const { TextArea } = Input;

    const handleOnFinish = async(values) => {
        let body = {
            statusId: values?.todoEventStatusId,
            remarks: values?.remarks
        }

        try {
            if(values?.todoEventStatusId) {
                await dispatch(actionForUpdateDailyDairy(selectedRecordData?.id, body))
            }
            
            handleAddUpdateDairy();
        } catch(err) {
            console.log("err: ", err)
        }
    }

    useEffect(()=> {
        if (isEmpty(allEventStatusListing)) {
            dispatch(getStatusEventListing());
        }
    }, [])
    

    useEffect(() => {
        // console.log("selectedRecordData: ", selectedRecordData);
        form.setFieldsValue({
            todoEventStatusId: selectedRecordData?.stutusId,
            remarks: selectedRecordData?.remarks
        })

    }, [selectedRecordData])


    return (
        <>
            <Form
                form={form}
                onFinish={handleOnFinish}
            >
                <Form.Item
                    label="Status"
                    name="todoEventStatusId"
                    className="form__group"
                >
                    <Select placeholder="Select Progress">
                    {!isEmpty(allEventStatusListing) &&
                        allEventStatusListing.map((status, index) => {
                        return (
                            <Option value={status.id} key={index}>
                            {status.name}
                            </Option>
                        );
                     })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Remarks"
                    name="remarks"
                    className="form__group"
                >
                    <TextArea rows={4} />
                </Form.Item>

                <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                        marginTop: "8px",
                    }}
                    loading={updateDailyDiaryLoading}
                >
                    Update
                </Button>
            </Form>
        </>
    )
}


export default AddUpdateDailyDairy;