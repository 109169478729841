import { ORGANIZER_TYPES } from "../../types";
import { init, success, finish, error } from "services/common";
import { OrganizerService } from "./organizer.api";
import { message } from "antd";

const organizerService = new OrganizerService();

export const getAllFiscalYears = () => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.FISCAL_YEARS));
    const response = await organizerService.getAllFiscalYears();
    dispatch(finish(ORGANIZER_TYPES.FISCAL_YEARS));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.FISCAL_YEARS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getHolidayEvents = (query) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.HOLIDAY_EVENTS));
    const response = await organizerService.getHolidayEvents(query);
    dispatch(finish(ORGANIZER_TYPES.HOLIDAY_EVENTS));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.HOLIDAY_EVENTS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addBulkHolidayEvents = (body, status) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.BULK_HOLIDAY_EVENTS));
    const response = await organizerService.addBulkHolidayEvents(body);
    dispatch(finish(ORGANIZER_TYPES.BULK_HOLIDAY_EVENTS));
    if (response.isSuccess) {
      let { fiscalYearId } = response.data[0];
      dispatch(success(ORGANIZER_TYPES.BULK_HOLIDAY_EVENTS, response.data));
      dispatch(getHolidayEvents({
        fiscalYearId,
        MaxResultCount: 5,
        SkipCount: 0,
        Sorting: '',
        SortType: ''
      }));
      message.success(`Holiday ${status} Successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const organizerEventLabelsPagination = (query) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.LABELS_EVENTS));
    const response = await organizerService.organizerEventLabelsPagination(query);
    dispatch(finish(ORGANIZER_TYPES.LABELS_EVENTS));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.LABELS_EVENTS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const organizerEventLabelAdd = (query) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.organizerEventLabelAdd));
    const response = await organizerService.organizerEventLabelAdd(query);
    dispatch(finish(ORGANIZER_TYPES.organizerEventLabelAdd));
    if (response.isSuccess) {
      message.success("Successfully added organizer event label")
      dispatch(success(ORGANIZER_TYPES.organizerEventLabelAdd, response.data));
      dispatch(organizerEventLabelsPagination())
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const organizerEventLabelUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.organizerEventLabelUpdate));
    const response = await organizerService.organizerEventLabelUpdate(id, body);
    dispatch(finish(ORGANIZER_TYPES.organizerEventLabelUpdate));
    if (response.isSuccess) {
      message.success("Successfully updated organizer event label")
      dispatch(success(ORGANIZER_TYPES.organizerEventLabelUpdate, response.data));
      dispatch(organizerEventLabelsPagination())
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const organizerEventPriorityPagination = (query) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.organizerEventPriorityPagination));
    const response = await organizerService.organizerEventPriorityPagination(query);
    dispatch(finish(ORGANIZER_TYPES.organizerEventPriorityPagination));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.organizerEventPriorityPagination, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const organizerEventPriorityAdd = (query) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.organizerEventPriorityAdd));
    const response = await organizerService.organizerEventPriorityAdd(query);
    dispatch(finish(ORGANIZER_TYPES.organizerEventPriorityAdd));
    if (response.isSuccess) {
      message.success("Successfully added organizer priority label")
      dispatch(success(ORGANIZER_TYPES.organizerEventPriorityAdd, response.data));
      dispatch(organizerEventPriorityPagination())
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const organizerEventPriorityUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.organizerEventPriorityUpdate));
    const response = await organizerService.organizerEventPriorityUpdate(id, body);
    dispatch(finish(ORGANIZER_TYPES.organizerEventPriorityUpdate));
    if (response.isSuccess) {
      message.success("Successfully updated organizer priority label")
      dispatch(success(ORGANIZER_TYPES.organizerEventPriorityUpdate, response.data));
      dispatch(organizerEventPriorityPagination())
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};