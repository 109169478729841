import { Button, Form, Input, Select, Spin, message, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { StarFilled } from "@ant-design/icons";
import { isEmpty, pickBy } from "lodash";
import axios from "axios";
import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import {
  projectAdd,
  projectUpdate,
} from "services/redux/Reference/Project/action";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllModality,
  getAllSectors,
  getAllStatus,
} from "services/redux/officeresource/business/action";
import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";
import { allCompanyListing } from "services/redux/Reference/action";
import { getAllUsers } from "services/remanentCalls";

const ProjectForm = ({
  isUpdate,
  isFav,
  setIsFav,
  searchTitleProject,
  setSearchTitleProject,
  value,
  setValue,
  contactCompanyList,
  setContactCompanyList,
  setVisibleModal,
  setClientAgencyType,
  getDetails,
  defaultCurrencyId,
  setEditForm,
  setIsUpdate,
  projectLoadingResponse,
  projectUpdateLoading,
  selectedSearchedProjectTitle,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [commaSeparatorNum, setCommaSeparatorNum] = useState("");
  const [user, setUsers] = useState([]);
  const {
    allSectorsListingResponse,
    allStatusListingResponse,
    allModalityListingResponse,
  } = useSelector((state) => state.businessCenter);

  const { contactCompanyPaginationResponse, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  const { allCurrencyListingResponse } = useSelector(
    (state) => state.reference
  );

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    if (getDetails) {
      form.setFieldsValue({
        title: getDetails.title,
        projectSectorDtos: getDetails?.projectSectorDtos?.map(
          (x) => x.sectorId
        ),
        clientId: getDetails?.clientId,
        modalityId: getDetails?.modalityId,
        description: getDetails?.description,
        projectCost: getDetails?.projectCost,
        currencyId: getDetails?.currencyDtos?.map((x) => x.currencyId),
      });
    }
  }, [form, getDetails]);

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  const getCompanyPagination = (
    MaxResultCount = 10,
    SkipCount = 0,
    // Sorting,
    // SortType,
    SearchKeyword,
    ForPagination = false
  ) => {
    dispatch(
      getContactCompanyPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: value,
        },
        ForPagination
      )
    );
  };

  useEffect(() => {
    if (isEmpty(value)) setContactCompanyList([]);
    if (isEmpty(value)) return;
    if (
      !isEmpty(contactCompanyList) &&
      isEmpty(contactCompanyPaginationResponse?.items)
    )
      return;
    const timeoutId = setTimeout(() => getCompanyPagination(), 500);
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    dispatch(getAllSectors());
    dispatch(getAllStatus());
    dispatch(getAllModality());
    dispatch(allCompanyListing());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(contactCompanyPaginationResponse?.items)) {
      setContactCompanyList(contactCompanyPaginationResponse?.items);
    } else {
      // setContactCompanyList([]);
    }
  }, [contactCompanyPaginationResponse]);

  const numberCommaSepartor = (event) => {
    let projectCost = form.getFieldValue("projectCost");

    const commaNum = projectCost.replace(/\,/g, "");
    if (isNaN(Number(commaNum))) {
      form.setFieldsValue({ projectCost: commaSeparatorNum });
      return;
    }

    let tmp = projectCost.replace(/,/g, "");
    let val = Number(tmp).toLocaleString();
    if (tmp == "") {
      form.setFieldsValue({ projectCost: "" });
    } else {
      form.setFieldsValue({ projectCost: val });
      setCommaSeparatorNum(val);
    }
  };

  const handleTitleChange = (event) => {
    // let errorWrapper = event.target.parentNode.querySelector(".error-wrapper");
    // if (errorWrapper) errorWrapper.remove();
    if (event.target.value.length >= 3) {
      let query = {
        MaxResultCount: 10,
        SkipCount: 0,
        SearchKeyword: event.target.value,
      };
      axios
        .get(
          `${appBaseUrl}/referenceManagement/projectsPagination?${queryString.stringify(
            query
          )}`
        )
        .then((resp) => {
          let data = resp.data?.items;
          if (data.length > 0) setSearchTitleProject(data.slice(0, 5));
          else setSearchTitleProject([]);
        })
        .catch((err) => {});
    } else {
      setSearchTitleProject([]);
    }
  };

  const handleOnBlur = (event) => {
    setSearchTitleProject([]);
  };

  const onAdvancedInfo = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onFinish = async () => {
    try {
      let newValues = await form.validateFields();
      if (newValues.projectStatusId === "OnProgress") {
        newValues.projectStatusId = allStatusListingResponse?.find((x) => {
          return x?.status === "OnProgress";
        }).id;
      }
      if (getDetails?.id) {
        let val = {
          ...newValues,
          isFavorite: isFav,
          // currencyId: newValues.currencyId,
          // projectCost: Number(newValues.projectCost.replace(/\,/g, "")).toFixed(
          //   2
          // ),
          currencyId: newValues.currencyId.toString(),
          // projectCost: newValues?.projectCost?.toFixed(2),
          projectCost: newValues?.projectCost + ".00",
          id: getDetails.id,
          projectSectorDtos: newValues.projectSectorDtos?.map((value) => {
            return {
              sectorId: value,
            };
          }),
          projectFundingAgencyDtos: newValues.projectFundingAgencyDtos?.map(
            (value) => {
              return {
                fundingAgencyId: value,
              };
            }
          ),
        };
        val = pickBy(val, (v) => !!v);

        dispatch(projectUpdate(val));
      } else {
        const decimal = ".00";
        let body = {
          title: newValues.title,
          isFavourite: isFav,
          projectStatusId: newValues.projectStatusId,
          description: newValues.description,
          clientId: newValues.clientId,
          modalityId: newValues.modalityId,
          // projectCost: newValues.projectCost.concat(decimal),
          projectCost: Number(newValues.projectCost.replace(/\,/g, "")).toFixed(
            2
          ),
          currencyId: newValues.currencyId,
          overview: newValues.overview,
          technicalFeatures: newValues.technicalFeatures,
          projectSectorDtos: newValues.projectSectorDtos?.map((value) => {
            return {
              sectorId: value,
            };
          }),
          primaryInchargeId: newValues.primaryInchargeId,
          tenderBasicSecondaryInchargeDtos:
            newValues.tenderBasicSecondaryInchargeDtos,
          projectFundingAgencyDtos: newValues.projectFundingAgencyDtos?.map(
            (value) => {
              return {
                projectFundingAgencyId: value,
              };
            }
          ),
          note: newValues.notes,
        };
        dispatch(projectAdd(body));
      }
    } catch (errorInfo) {
      console.error("Failedsssssssss:", errorInfo);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="control-hooks"
        className="project_form_add"
      >
        <div className="global-form-flex">
          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>
                Sector<span className="red-star">*</span>
              </label>
              {isUpdate === "add" ? (
                <div style={{ padding: 4, marginLeft: "auto" }}>
                  <StarFilled
                    style={{ color: `${isFav ? "#FBA525" : "grey"}` }}
                    onClick={() => {
                      setIsFav((prevState) => !prevState);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="global-form-input">
              <Form.Item
                name="projectSectorDtos"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  size="small"
                  mode="multiple"
                  allowClear
                  optionFilterProp="label"
                >
                  {allSectorsListingResponse?.map((data, i) => (
                    <Option key={i} value={data.id} label={[data.sectorName]}>
                      {data.sectorName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>
                Title<span className="red-star">*</span>
              </label>
            </div>
            <div className="global-form-input">
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  size="small"
                  onChange={handleTitleChange}
                  onBlur={handleOnBlur}
                  autoComplete="off"
                  onClick={() => {
                    setSearchTitleProject([]);
                  }}
                />
              </Form.Item>
              {searchTitleProject && searchTitleProject.length > 0 && (
                <ul
                  className="search-project-title"
                  onClick={() => {
                    setSearchTitleProject([]);
                  }}
                >
                  {searchTitleProject?.map((item, i) => {
                    return (
                      <li key={i}>{`${item?.referenceCode} ${item?.title}`}</li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>

          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>Status</label>
            </div>
            <div className="global-form-input">
              <Form.Item
                name="projectStatusId"
                rules={[{ required: true }]}
                initialValue={
                  !isEmpty(allStatusListingResponse) &&
                  allStatusListingResponse[2].status
                }
              >
                <Select
                  size="small"
                  showSearch={true}
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultvalue={
                    !isEmpty(allStatusListingResponse) &&
                    allStatusListingResponse[2].status
                  }
                >
                  {allStatusListingResponse?.map((data) => (
                    <Option key={data.id} value={data.id}>
                      {data.status}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>Client</label>
            </div>
            <div className="global-form-input">
              <Form.Item
                name="clientId"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Select
                  size="small"
                  showSearch={true}
                  allowClear
                  // dropdownClassName="select-zindex"
                  onSearch={(value) => {
                    if (value) {
                      setValue(value);
                    } else {
                      setContactCompanyList([]);
                      setValue(value);
                    }
                  }}
                  filterOption={(input, option) => {
                    console.log("Input:", input);
                    console.log("Option:", option);

                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  notFoundContent={
                    !isEmpty(value) ? (
                      contactCompanyPaginationLoading ? (
                        <Spin />
                      ) : (
                        isEmpty(contactCompanyPaginationResponse?.items) &&
                        value?.length > 2 && <div>{`Create "${value}"`}</div>
                      )
                    ) : (
                      console.log("No content found here")
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // setValue("");
                      // setContactCompanyList([]);

                      if (
                        isEmpty(contactCompanyPaginationResponse?.items) &&
                        !isEmpty(value)
                      ) {
                        // e.preventDefault();
                        setVisibleModal(true);
                        setClientAgencyType("client");
                      }
                    }
                  }}
                  optionFilterProp="label"
                >
                  {/* {!isEmpty(getDetails?.clientId) && (
                    <Option value={getDetails.clientId}>
                      {getDetails.clientName}
                    </Option>
                  )} */}

                  {contactCompanyPaginationResponse?.items?.map((company) => (
                    <Option
                      value={company.contactCompanyId}
                      key={company.contactCompanyId}
                      label={company.contactCompanyName}
                    >
                      {company.contactCompanyName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>Modality</label>
            </div>
            <div className="global-form-input">
              <Form.Item
                name="modalityId"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Select
                  size="small"
                  showSearch={true}
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {allModalityListingResponse?.map((modalities) => (
                    <Option key={modalities.id} value={modalities.id}>
                      {modalities.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>
                Primary Incharge<span className="red-star">*</span>
              </label>
            </div>
            <div className="global-form-input">
              <Form.Item
                name="primaryInchargeId"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  size="small"
                  allowClear
                  optionFilterProp="label"
                  showSearch={true}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {user.map((user) => (
                    <Option
                      key={user.appUserId}
                      value={user.appUserId}
                      label={[user.fullName, user.userName]}
                    >
                      <div className="demo-option-label-item">
                        <span role="img">
                          {user.fullName} {"" + ""}
                        </span>
                        [{user.userName}]
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>
                Secondary Incharge<span className="red-star">*</span>
              </label>
            </div>
            <div className="global-form-input">
              <Form.Item
                name="tenderBasicSecondaryInchargeDtos"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  allowClear
                  size="small"
                  mode="multiple"
                  showSearch={true}
                  optionFilterProp="label"
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {user.map((user) => (
                    <Option
                      key={user.appUserId}
                      value={user.appUserId}
                      label={[user.fullName, user.userName]}
                    >
                      <div className="demo-option-label-item">
                        <span role="img">
                          {user.fullName} {"" + ""}
                        </span>
                        [{user.userName}]
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>Funding Agency</label>
            </div>
            <div className="global-form-input">
              <Form.Item
                name="projectFundingAgencyDtos"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Select
                  size="small"
                  mode="multiple"
                  dropdownClassName="select-zindex"
                  showSearch={true}
                  allowClear
                  onSearch={(value) => setValue(value)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  notFoundContent={
                    !isEmpty(value) ? (
                      contactCompanyPaginationLoading ? (
                        <Spin />
                      ) : (
                        isEmpty(contactCompanyPaginationResponse?.items) &&
                        value?.length > 2 && <div>{`Create "${value}"`}</div>
                      )
                    ) : null
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (
                        isEmpty(contactCompanyPaginationResponse?.items) &&
                        !isEmpty(value)
                      ) {
                        setVisibleModal(true);
                        setClientAgencyType("agency");
                      }
                    }
                  }}
                  onBlur={() => setContactCompanyList([])}
                >
                  {!isEmpty(getDetails?.projectFundingAgencyDtos) &&
                    isEmpty(contactCompanyList) &&
                    getDetails?.projectFundingAgencyDtos?.map((company) => (
                      <Option
                        value={company.fundingAgencyId}
                        key={company.projectId}
                      >
                        {company.fundingAgencyName}
                      </Option>
                    ))}
                  {contactCompanyList?.map((company) => (
                    <Option
                      value={company.contactCompanyId}
                      key={company.contactCompanyId}
                      label={company.contactCompanyName}
                    >
                      {company.contactCompanyName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>
                Project Cost<span className="red-star">*</span>
              </label>
            </div>
            <div className="global-form-input flex">
              <Form.Item
                className="currency"
                name="currencyId"
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={defaultCurrencyId}
              >
                {!isEmpty(allCurrencyListingResponse) && (
                  <Select size="small" defaultValue={defaultCurrencyId}>
                    {allCurrencyListingResponse?.map((currency) => (
                      <Option
                        key={currency.currencyId}
                        value={currency.currencyId}
                      >
                        {currency.abbvr}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                className="budget-number"
                name="projectCost"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="small" onChange={numberCommaSepartor} />
              </Form.Item>
            </div>
          </div>

          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>
                Description<span className="red-star">*</span>
              </label>
            </div>
            <div className="global-form-input">
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea size="small" />
              </Form.Item>
            </div>
          </div>

          <div className="global-form-group calc" style={{ marginTop: 7 }}>
            <div className="global-form-label">
              <label> Technical Features</label>
            </div>
            <div className="global-form-input">
              <Form.Item
                name="technicalFeatures"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea size="small" />
              </Form.Item>
            </div>
          </div>

          <div className="global-form-group calc" style={{ marginTop: 7 }}>
            <div className="global-form-label">
              <label>Advance Information</label>
            </div>
            <div
              className="global-form-input"
              style={{ display: "flex", alignSelf: "center" }}
            >
              <Form.Item
                name="advancedInformation"
                valuePropName="checked"
                rules={[{ required: false }]}
              >
                <Checkbox onChange={onAdvancedInfo} checked={false} />
              </Form.Item>
            </div>
          </div>

          <div className="global-form-group full-block">
            <div className="note-header">
              <label> Notes </label>
            </div>
            <div className="note-box-wrapper">
              <Form.Item
                className="form__group"
                name="note"
                rules={[{ required: false }]}
              >
                <Input.TextArea />
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="global-form-footer">
          <Button
            className="cancleAction"
            onClick={() => {
              setEditForm?.(true);
              setIsUpdate("details");
            }}
          >
            Cancel
          </Button>
          <Button
            className="saveAction"
            onClick={() => {
              onFinish();
              setEditForm?.(true);
            }}
            loading={projectLoadingResponse || projectUpdateLoading}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ProjectForm;
