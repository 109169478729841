import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { isEmpty } from 'lodash';

import {
    actionForGroupChatTitlePagination,
    actionForGetChatTitleDetails,
} from 'services/redux/groupChat/action';

import GroupChatList from './ListView/GroupChatList';
import GroupChatDetail from './DetailView';
import GroupChatDrawer from './Drawer/GroupChatDrawer';

function GroupChatIndex() {
    const {
        groupChatTitlePaginationResp,
        groupChatTitlePaginationLoading,
        groupChatTitleDetailsResp,
        groupChatTitleDetailsRespLoading,
    } = useSelector((state) => state.groupChatReducer);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    // drawer
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isGroupTitleUpdating, setIsGroupTitleUpdating] = useState(false);

    // chatlist
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedGroupData, setSelectedGroupData] = useState([]);
    const [searchGroupTitle, setSearchGroupTitle] = useState('');
    const [isChatOpen, setIsChatOpen] = useState(false);

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 80;

    // search
    const [searchKeyword, setSearchKeyword] = useState();
    const [isAdvanceClear, setIsAdvanceClear] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    // console.log("searchKeyword: ",searchKeyword)


    // added search
    // const pageSize = 20;

    const getGroupChatSearchData = (
        MaxResultCount = pageSize,
        SkipCount = 0,
        Sorting,
        ForPagination = false
    ) => {
        // console.log("Search API CALLED")
        dispatch(
            actionForGroupChatTitlePagination(
                {
                    MaxResultCount,
                    SkipCount,
                    Sorting,
                    // SortType,
                    SearchKeyword: searchKeyword,
                },
                ForPagination
            )
        );
    };

    useEffect(() => {
        getGroupChatSearchData();
    }, [searchKeyword]);



    useEffect(() => {
        let body = {
            MaxResultCount: pageSize,
            SkipCount: 0,
        };
        dispatch(actionForGroupChatTitlePagination(body, true));
    }, [])


    // drawer
    const handleDrawerClose = () => {
        setIsDrawerVisible(false);
        setIsGroupTitleUpdating(false);
        form.resetFields();
    }

    //
    const getData = (MaxResultCount = pageSize, SkipCount, loadMore = true) => {
        dispatch(
            actionForGroupChatTitlePagination(
              {
                MaxResultCount,
                SkipCount,
              },
              loadMore
            )
        );
    }

    const getMoreData = () => {
        getData(pageSize, currentPage * pageSize, true);
    };


    // changeGroupChatStatus
    const handleGroupChatStatus = () => {

    }

    return (
        <>
            <div className="main__body">
                <div className="organizer email">
                    <div style={{ display: "flex", flexFlow: "row wrap", width: "100%" }}>
                        <div className="emailList">
                            <GroupChatList
                                loading={groupChatTitlePaginationLoading}
                                data={groupChatTitlePaginationResp}
                                selectedGroupId={selectedGroupId}
                                selectedGroupData={selectedGroupData}
                                setSelectedGroupId={setSelectedGroupId}
                                setSelectedGroupData={setSelectedGroupData}
                                setSearchGroupTitle={setSearchGroupTitle}
                                setIsDrawerVisible={setIsDrawerVisible}
                                searchKeyword={searchKeyword}
                                setSearchKeyword={setSearchKeyword}
                                isAdvanceClear={isAdvanceClear}
                                setIsAdvanceClear={setIsAdvanceClear}
                            />
                        </div>
                
                        <div
                            className="emailLeft"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {isEmpty(selectedGroupId) ? 
                            "No Data Selected" : 
                            <GroupChatDetail
                                selectedGroupId={selectedGroupId}
                                selectedGroupData={selectedGroupData}
                                setSelectedGroupData={setSelectedGroupData}
                                setIsDrawerVisible={setIsDrawerVisible}
                                setIsGroupTitleUpdating={setIsGroupTitleUpdating}
                                isChatOpen={isChatOpen}
                                setIsChatOpen={setIsChatOpen}
                            />}
                        </div>
                    </div>
                </div>
            </div>
                
            <GroupChatDrawer
                isDrawerVisible={isDrawerVisible}
                handleDrawerClose={handleDrawerClose}
                form={form}
                isGroupTitleUpdating={isGroupTitleUpdating}
                selectedGroupId={selectedGroupId}
            />
        </>
    )
}

export default GroupChatIndex;