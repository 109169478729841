import React, { useEffect } from "react";
import { isEmpty } from "lodash-es";
import moment from "moment";

// antd
import { Drawer, Form, Button, DatePicker, Input, Layout } from "antd";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fiscalYearAddUpdate } from "services/redux/admin/FiscalYear/action";

// utils
import { validateMessages } from "utils/misc";

const { Header, Footer } = Layout;

const FiscalYearAddUpdateDrawer = ({
  onAddUpdateFiscalYearClose,
  drawerVisibility,
  isUpdateFiscalYear,
}) => {
  const { addUpdateFiscalYearResponse } = useSelector(
    (state) => state.fiscalYearState
  );

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(isUpdateFiscalYear)) {
      form.resetFields();
      return;
    }

    form.setFieldsValue({
      ...isUpdateFiscalYear,
      fiscalStart: moment(isUpdateFiscalYear.fiscalStart),
      fiscalEnd: moment(isUpdateFiscalYear.fiscalEnd),
    });
  }, [isUpdateFiscalYear]);

  useEffect(() => {
    onAddUpdateFiscalYearClose();
  }, [addUpdateFiscalYearResponse]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      let body = {
        ...values,
        fiscalStart: moment(values.fiscalStart).format('YYYY/MM/DD'),
        fiscalEnd: moment(values.fiscalEnd).format('YYYY/MM/DD'),
      }

      let status = 'Added';

      if (!isEmpty(isUpdateFiscalYear)) {
        body.id = isUpdateFiscalYear.id;
        status = 'Updated'
      }

      dispatch(fiscalYearAddUpdate(body, status))
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onAddUpdateFiscalYearClose}
      closable={false}
    >
      <Header className='drawer__header'>
        <h4 className='drawer__header--title'>
          {`${isUpdateFiscalYear ? "Update" : "Create"} Fiscal Year`}
        </h4>
      </Header>
      <Form
        form={form}
        name='control-hooks'
        validateMessages={validateMessages}
        className='drawer-form-wrapper'
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name='fiscalStart'
              label='Fiscal Start'
              rules={[{ required: true }]}>
              <DatePicker placeholder="Fiscal Start" format="YYYY/MM/DD" autoComplete="off" />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              name='fiscalEnd'
              label='Fiscal End'
              className="form__group"
              rules={[{ required: true }]}>
              <DatePicker placeholder="Fiscal End" format="YYYY/MM/DD" autoComplete="off" />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              name='displayName'
              label='Display Name'
              className="form__group"
              rules={[{ required: true }]}>
              <Input placeholder="Display Name for fiscal year" autoComplete="off" />
            </Form.Item>
          </div>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateFiscalYearClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default FiscalYearAddUpdateDrawer;
