import { DAILY_DAIRY } from "services/types";

const INITIAL_STATE = {
  dailyDairyPaginationResp: null,
  dailyDairyPaginationLoading: false,

  transferToDailyDairyResp: null,
  transferToDailyDairyLoading: false,

  updateDailyDiaryResp: null,
  updateDailyDiaryLoading: false,

  dailyDiaryCompleteResp: null,
  dailyDairyCompleteLoading: false,

  saveDailyDairyResp: null,
  saveDailyDairyLoading: false,

  transferToDailyRemarksResp: [],
  transferToDailyRemarksLoading: false,

  deleteDailyDiaryResp: [],
  deleteDailyDiaryLoading: false,

  exportDailDiary: null,
  exportDailDiaryLoading: false,
};

export default function dailyDiaryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // pagination
    case DAILY_DAIRY.GET_PAGINATION_DAILY_DIARY_INIT:
      return {
        ...state,
        dailyDairyPaginationResp: null,
        dailyDairyPaginationLoading: true,
      };

    case DAILY_DAIRY.GET_PAGINATION_DAILY_DIARY_SUCCESS:
      return {
        ...state,
        dailyDairyPaginationResp: action.payload,
        dailyDairyPaginationLoading: false,
      };

    case DAILY_DAIRY.GET_PAGINATION_DAILY_DIARY_UPDATE:
      // console.log("REDUCER - action.payload: ", action.payload);
      // console.log("dailyDairyPaginationResp: ", state?.dailyDairyPaginationResp);
      // const pushData1 = [
      //     ...state?.dailyDairyPaginationResp?.items || [],
      //     action.payload?.dailyDiaryDto,
      // ];
      // const updatedItemsPagination = state?.dailyDairyPaginationResp?.items?.map((item) => {
      //     if(action.payload.dailyDiaryDto?.todoEventId === item?.todoEventId) {
      //         return {
      //             ...item,
      //             statusDto: action.payload.todoEventStatusDto,
      //             statusName: action.payload.todoEventStatusDto?.name,
      //             stutusId: action.payload.todoEventStatusDto?.id,
      //         }
      //     } else {
      //         // return item;
      //         return {
      //             ...item,
      //             ...action.payload.dailyDiaryDto
      //         };
      //     }
      // })

      // console.log("updatedItemsPagination: ",updatedItemsPagination)
      // return {
      //     ...state,
      //     dailyDairyPaginationResp: {
      //         items: updatedItemsPagination,
      //         totalCount: state?.dailyDairyPaginationResp?.totalCount,
      //     },
      //     dailyDairyPaginationLoading: false,
      // }

      // Combine existing items with the new item from the payload, ensuring no duplicates
      const newItem = action.payload?.dailyDiaryDto;
      const existingItems = state?.dailyDairyPaginationResp?.items || [];

      // Remove existing item with the same ID
      const filteredItems = existingItems.filter(
        (item) => item.todoEventId !== newItem?.todoEventId
      );

      // Create the updated items array
      const updatedItemsPagination = [...filteredItems, newItem].map((item) => {
        if (newItem?.todoEventId === item?.todoEventId) {
          return {
            ...item,
            statusDto: action.payload.todoEventStatusDto,
            statusName: action.payload.todoEventStatusDto?.name,
            statusId: action.payload.todoEventStatusDto?.id,
          };
        } else {
          return item;
        }
      });

      // console.log("updatedItemsPagination: ", updatedItemsPagination);

      return {
        ...state,
        dailyDairyPaginationResp: {
          items: updatedItemsPagination,
          totalCount: state?.dailyDairyPaginationResp?.totalCount,
        },
        dailyDairyPaginationLoading: false,
      };

    case DAILY_DAIRY.GET_PAGINATION_DAILY_DIARY_FINISH:
      return {
        ...state,
        dailyDairyPaginationLoading: false,
      };

    // delete
    case DAILY_DAIRY.DELETE_DAILY_DIARY_INIT:
      return {
        ...state,
        deleteDailyDiaryResp: [],
        deleteDailyDiaryLoading: true,
      };

    case DAILY_DAIRY.DELETE_DAILY_DIARY_SUCCESS:
      // let payload = {
      //     id: "1c04298e-9cf4-4053-a89d-cf9b8a275c03",
      //     todoEventId: "52050dcf-7910-4356-87c8-876a345003ba",
      //     dailyDiaryDate: "2024-08-22T00:00:00",
      //     stutusId: "4de2c2e9-aed1-4b28-bec0-6e52ab148f87",
      //     statusName: "Start",
      //     userId: "d838b480-8e6b-f3fc-5bf1-3a07ced19801",
      //     userName: "sp",
      //     title: "task 3",
      //     remarks: "feri start gareko",
      //     isActive: false,
      //     statusDto: {
      //       id: "4de2c2e9-aed1-4b28-bec0-6e52ab148f87",
      //       name: "Start",
      //       colorHexValue: "#6a71ad",
      //       colorFontHexValue: "#ffffff"
      //     },
      //     transferToDailyTask: {
      //       status: true
      //     }
      //   }

      let updatedDeleteItem = state?.dailyDairyPaginationResp?.items
        ?.map((item) => {
          if (action.payload.todoEventId === item.todoEventId) {
            return action.payload.isActive ? { ...action.payload } : null;
          } else {
            return {
              ...item,
            };
          }
        })
        .filter((item) => item !== null);

      // console.log("updated deleted: ", updatedDeleteItem)
      return {
        ...state,
        deleteDailyDiaryResp: action.payload,
        deleteDailyDiaryLoading: false,
        dailyDairyPaginationResp: {
          items: updatedDeleteItem,
          totalCount: state?.dailyDairyPaginationResp?.totalCount,
        },
      };

    case DAILY_DAIRY.DELETE_DAILY_DIARY_FINISH:
      return {
        ...state,
        deleteDailyDiaryLoading: false,
      };

    // updated
    case DAILY_DAIRY.UPDATE_DAILY_DIARY_INIT:
      return {
        ...state,
        updateDailyDiaryLoading: true,
        updateDailyDiaryResp: null,
      };

    case DAILY_DAIRY.UPDATE_DAILY_DIARY_SUCCESS:
      let updatedDailyItems = state.dailyDairyPaginationResp?.items?.map(
        (item) => {
          if (action.payload.id === item?.id) {
            return {
              ...action.payload,
            };
          } else {
            return {
              ...item,
            };
          }
        }
      );

      return {
        ...state,
        updateDailyDiaryLoading: false,
        updateDailyDiaryResp: action.payload,
        dailyDairyPaginationResp: {
          items: updatedDailyItems,
          totalCount: state.dailyDairyPaginationResp.totalCount,
        },
      };

    case DAILY_DAIRY.UPDATE_DAILY_DIARY_FINISH:
      return {
        ...state,
        updateDailyDiaryLoading: false,
      };

    // transfer
    case DAILY_DAIRY.TRANSFER_TO_DAILY_DIARY_INIT:
      return {
        ...state,
        transferToDailyDairyLoading: true,
        transferToDailyDairyResp: null,
      };

    case DAILY_DAIRY.TRANSFER_TO_DAILY_DIARY_SUCCESS:
      let updatedItems = [
        action.payload,
        ...state.dailyDairyPaginationResp?.items,
      ];
      // console.log("state?.dailyDairyPaginationResp: ", state?.dailyDairyPaginationResp);
      // console.log("action payload: ", action?.payload);
      // console.log("updatedItems: ",updatedItems)

      return {
        ...state,
        transferToDailyDairyLoading: false,
        transferToDailyDairyResp: action.payload,
        dailyDairyPaginationResp: {
          items: updatedItems,
          totalCount: state.dailyDairyPaginationResp.totalCount,
        },
      };

    case DAILY_DAIRY.TRANSFER_TO_DAILY_DIARY_FINISH:
      return {
        ...state,
        transferToDailyDairyLoading: false,
      };

    // Remarks
    case DAILY_DAIRY.TRANSFER_TO_DAIRY_REMARKS_INIT:
      return {
        ...state,
        transferToDailyRemarksResp: [],
        transferToDailyRemarksLoading: true,
      };

    case DAILY_DAIRY.TRANSFER_TO_DAIRY_REMARKS_SUCCESS:
      // console.log("REDUCER: action.payload: ", action.payload);
      // console.log("state.dailyDairyPaginationResp: ", state.dailyDairyPaginationResp);

      let updatedRemarks = state.dailyDairyPaginationResp?.items?.map(
        (item) => {
          if (action.payload.todoEventId === item.todoEventId) {
            return {
              ...action.payload,
            };
          } else {
            return item;
          }
        }
      );

      // console.log("XXX", updatedRemarks);

      return {
        ...state,
        transferToDailyRemarksResp: action.payload,
        transferToDailyRemarksLoading: false,
        dailyDairyPaginationResp: {
          items: updatedRemarks,
          totalCount: state.dailyDairyPaginationResp.totalCount,
        },
      };

    case DAILY_DAIRY.TRANSFER_TO_DAIRY_REMARKS_FINISH:
      return {
        ...state,
        transferToDailyRemarksLoading: false,
      };

    // case
    case DAILY_DAIRY.COMPLETE_DAILY_DIARY_INIT:
      return {
        ...state,
        dailyDiaryCompleteResp: null,
        dailyDairyCompleteLoading: true,
      };

    case DAILY_DAIRY.COMPLETE_DAILY_DIARY_SUCCESS:
      // console.log("REDUCER: action.payload: ", action.payload);
      // console.log("REDUCER: state.dailyDairyPaginationResp", state.dailyDairyPaginationResp);

      let updatedItem = state?.dailyDairyPaginationResp.items?.map((item) => {
        if (action.payload.id === item.id) {
          return {
            ...action.payload,
          };
        } else {
          return {
            ...item,
          };
        }
      });

      return {
        ...state,
        dailyDiaryCompleteResp: action.payload,
        dailyDairyCompleteLoading: false,
        dailyDairyPaginationResp: {
          totalCount: state?.dailyDairyPaginationResp,
          items: updatedItem,
        },
      };

    case DAILY_DAIRY.COMPLETE_DAILY_DIARY_FINISH:
      return {
        ...state,
        dailyDairyCompleteLoading: false,
      };

    // save
    case DAILY_DAIRY.SAVE_DAILY_DAIRY_INIT:
      return {
        ...state,
        saveDailyDairyResp: null,
        saveDailyDairyLoading: false,
      };

    case DAILY_DAIRY.SAVE_DAILY_DAIRY_SUCCESS:
      return {
        ...state,
        saveDailyDairyResp: action.payload,
        saveDailyDairyLoading: false,
      };

    case DAILY_DAIRY.SAVE_DAILY_DAIRY_FINISH:
      return {
        ...state,
        saveDailyDairyLoading: false,
      };

    // excel
    case DAILY_DAIRY.EXPORT_DAILY_DAIRY_INIT:
      return {
        ...state,
        exportDailDiaryLoading: true,
      };

    case DAILY_DAIRY.EXPORT_DAILY_DAIRY_SUCCESS:
      return {
        ...state,
        exportDailDiary: action.payload,
        exportDailDiaryLoading: false,
      };

    case DAILY_DAIRY.EXPORT_DAILY_DAIRY_FINISH:
      return {
        ...state,
        exportDailDiaryLoading: false,
      };

    // default
    default:
      return {
        ...state,
      };
  }
}
