import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, message, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import AddUserDrawer from "./AddUserDrawer";
import ContactLabelAddUpdateTable from "./ContactLabelAddUpdateTable";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import ContactLabelAddUpdateDrawer from "./ContactLabelAddUpdateDrawer";
import { honorRollGetDetails } from "../../../services/remanentCalls";
import moment from 'moment';

const { RangePicker } = DatePicker;

const HonorRollAddUpdate = () => {
  const dispatch = useDispatch();

  const [
    addUpdateCountryDrawerVisibility,
    setAddUpdateCountryDrawerVisibility,
  ] = useState(false);
  const [
    updateProfileDrawerVisibility,
    setUpdateProfileDrawerVisibility,
  ] = useState(false);
  const [isUpdateCountry, setIsUpdateCountry] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const showAddUpdateCountryDrawer = async (e, record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      const resp = await honorRollGetDetails(record.id).catch((err) =>
        message.error("Failure")
      );

      if (resp) {
        setIsUpdateCountry(resp.data);
      }
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };

  const onAddUpdateCountryClose = () => {
    setAddUpdateCountryDrawerVisibility(false);
  };

  return (
    <div className='layout'>
      <div className="organizer honorRoll">
      <div className="global-header-wrapper">
          <div className="header-info">
            <h3 className='header__all '>Honor Rolls</h3>
          </div>
          <div className="header-action-wrapper">
            <RangePicker
              onChange={(value) => {
                setStartDate(value ? moment(value[0]).format('YYYY-MM-DD') : '');
                setEndDate(value ? moment(value[1]).format('YYYY-MM-DD') : '')
              }}
           />
            <Button
              className='btn-primary'
              onClick={showAddUpdateCountryDrawer}
            >
              <PlusOutlined /> New Honor Roll
            </Button>
          </div>

        </div>
        {/* <div className="organizerHoliday__middle">
          <div className="organizerHoliday__middle--wrap">
            <Button
              className='btn-primary'
              onClick={showAddUpdateCountryDrawer}
            >
              <PlusOutlined /> New Honor Roll
            </Button>
            <div className="honor-roll-filter">
              <span>
                Date Range {' '}
                <RangePicker
                  onChange={(value) => {
                    setStartDate(value ? moment(value[0]).format('YYYY-MM-DD') : '');
                    setEndDate(value ? moment(value[1]).format('YYYY-MM-DD') : '')
                  }}
                />
              </span>
            </div>
          </div>
        </div> */}
        <ContactLabelAddUpdateTable
          showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
          startDate={startDate}
          endDate={endDate}
        />
        <ContactLabelAddUpdateDrawer
          drawerVisibility={addUpdateCountryDrawerVisibility}
          onAddUpdateCountryClose={onAddUpdateCountryClose}
          isUpdateCountry={isUpdateCountry}
        />
      </div>
    </div>
  );
};

export default HonorRollAddUpdate;
