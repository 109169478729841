import { contactLabelGroupAdd } from "services/redux/contact/contact.action";
import { REQUISITION_TYPES, EMAIL_TYPES } from "../../../types";
import { uniqBy } from "lodash";

const INTIAL_STATE = {
  leaveApplicationPagination: [],
  leaveApplicationPaginationLoading: false,

  allLeaveApplicationListing: [],
  allLeaveApplicationLoading: false,

  supervisorDetails: [],
  supervisorDetailsLoading: false,

  addLeaveApplicationResp: [],
  addLeaveApplicationRespLoading: false,

  remainingLeaveApplicationTypesListing: [],
  remainingLeaveApplicationTypesListingLoading: false,

  leaveApplicationGetDetailsWithHistory: {
    history: [],
    remaining: [],
  },
  leaveApplicationGetDetailsWithHistoryLoading: true,

  leaveApplicationGetDetails: {},
  leaveApplicationGetDetailsLoading: true,
  leaveApplicationApproveRejectResp: {},
};

export default function leaveApplicationReducer(state = INTIAL_STATE, action) {
  // console.log("actionss",action)
  switch (action.type) {
    // Leave Application Pagination

    case EMAIL_TYPES.MAIL_BODY_GET_DETAILS_SUCCESS:
      return {
        ...state,

        leaveApplicationGetDetailsWithHistory: {
          history: [],
          remaining: [],
        },
      };

    case REQUISITION_TYPES.LEAVE_APPLICATION_PAGINATION_INIT:
      return {
        ...state,
        leaveApplicationPaginationLoading: true,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_PAGINATION_SUCCESS:
      return {
        ...state,
        leaveApplicationPagination: action.payload,
        leaveApplicationPaginationLoading: false,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_PAGINATION_FINISH:
      return {
        ...state,
        leaveApplicationPaginationLoading: false,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_APPROVAL_PAGINATION_INIT:
      return {
        ...state,
        leaveApplicationApprovalPaginationLoading: true,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_APPROVAL_PAGINATION_SUCCESS:
      return {
        ...state,
        leaveApplicationApprovalPagination: action.payload,
        leaveApplicationApprovalPaginationLoading: false,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_APPROVAL_PAGINATION_FINISH:
      return {
        ...state,
        leaveApplicationApprovalPaginationLoading: false,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_PAGINATON_CHANGE_SUCCESS:
      return {
        ...state,
        leaveApplicationPagination: {
          items: uniqBy(
            state.leaveApplicationPagination?.items.concat(
              action.payload.items
            ),
            "id"
          ),
          totalCount: action.payload.totalCount,
        },
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_PAGINATION_FINISH:
      return {
        ...state,
        leaveApplicationPaginationLoading: false,
      };

    // LeaveApplicationListing
    case REQUISITION_TYPES.LEAVE_APPLICATION_LISTING_INT:
      return {
        ...state,
        allLeaveApplicationListing: [],
        allLeaveApplicationLoading: true,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_LISTING_SUCCESS:
      return {
        ...state,
        allLeaveApplicationListing: [action.payload],
        allLeaveApplicaitonLoading: false,
      };

    case REQUISITION_TYPES.LEAVE_APPLICATION_LISTING_FINISH:
      return {
        ...state,
        allLeaveApplicationLoading: false,
      };

    // Supervisor Details
    case REQUISITION_TYPES.SUPERVISOR_DETAILS_INT:
      return {
        ...state,
        supervisorDetails: [],
        supervisorDetailsLoading: true,
      };
    case REQUISITION_TYPES.SUPERVISOR_DETAILS_SUCCESS:
      return {
        ...state,
        supervisorDetails: action.payload,
        supervisorDetailsLoading: false,
      };

    case REQUISITION_TYPES.SUPERVISOR_DETAILS_FINISH:
      return {
        ...state,
        supervisorDetailsLoading: false,
      };

    // Add Leave Application
    case REQUISITION_TYPES.ADD_LEAVE_APPLICATION_INIT:
      return {
        ...state,
        addLeaveApplicationResp: [],
        addLeaveApplicationRespLoading: true,
      };
    case REQUISITION_TYPES.ADD_LEAVE_APPLICATION_SUCCESS:
      return {
        ...state,
        addLeaveApplicationResp: action.payload,
        addLeaveApplicationRespLoading: false,
      };

    case REQUISITION_TYPES.ADD_LEAVE_APPLICATION_FINISH:
      return {
        ...state,
        addLeaveApplicationRespLoading: false,
      };

    // Remaining leave application types
    case REQUISITION_TYPES.REMAINING_LEAVE_APPLICATION_TYPES_INT:
      return {
        ...state,
        remainingLeaveApplicationTypesListing: [],
        remainingLeaveApplicationTypesListingLoading: true,
      };
    case REQUISITION_TYPES.REMAINING_LEAVE_APPLICATION_TYPES_SUCCESS:
      return {
        ...state,
        remainingLeaveApplicationTypesListing: action.payload,
        remainingLeaveApplicationTypesListingLoading: false,
      };

    case REQUISITION_TYPES.REMAINING_LEAVE_APPLICATION_TYPES_FINISH:
      return {
        ...state,
        remainingLeaveApplicationTypesListingLoading: false,
      };

    // Remaining leave application with history
    case REQUISITION_TYPES.LEAVE_APP_DETAILS_WITH_HISTORY_INT:
      return {
        ...state,
        leaveApplicationGetDetailsWithHistory: {
          history: [],
          remaining: [],
        },
        leaveApplicationGetDetailsWithHistoryLoading: true,
      };
    case REQUISITION_TYPES.LEAVE_APP_DETAILS_WITH_HISTORY_SUCCESS:
      return {
        ...state,
        leaveApplicationGetDetailsWithHistory: action.payload,
        leaveApplicationGetDetailsWithHistoryLoading: false,
      };

    case REQUISITION_TYPES.LEAVE_APP_DETAILS_WITH_HISTORY_FINISH:
      return {
        ...state,
        leaveApplicationGetDetailsWithHistoryLoading: false,
      };

    // Leave application get details
    case REQUISITION_TYPES.LEAVE_APPLICATION_GET_DETAILS_INT:
      return {
        ...state,
        leaveApplicationGetDetails: {},
        leaveApplicationGetDetailsLoading: true,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_GET_DETAILS_SUCCESS:
      return {
        ...state,
        leaveApplicationGetDetails: action.payload,
        leaveApplicationGetDetailsLoading: false,
      };

    case REQUISITION_TYPES.LEAVE_APPLICATION_GET_DETAILS_FINISH:
      return {
        ...state,
        leaveApplicationGetDetailsLoading: false,
      };

    // Leave application Approve Reject
    case REQUISITION_TYPES.LEAVE_APPLICATION_APPROVE_REJECT_INT:
      return {
        ...state,
        leaveApplicationApproveRejectRespLoading: true,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_APPROVE_REJECT_SUCCESS:
      return {
        ...state,
        leaveApplicationApproveRejectResp: action.payload,
        leaveApplicationApproveRejectRespLoading: false,
      };

    case REQUISITION_TYPES.LEAVE_APPLICATION_APPROVE_REJECT_FINISH:
      return {
        ...state,
        leaveApplicationApproveRejectRespLoading: false,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_CANCEL_INIT:
      return {
        ...state,
        leaveApprovalDeleteResponse: [],
        leaveApprovalDeleteResponseLoading: true,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_CANCEL_SUCCESS:
      console.log("action.payload", state?.leaveApplicationPagination);
      return {
        ...state,
        leaveApprovalDeleteResponse: action.payload,
        leaveApprovalDeleteResponseLoading: false,
        leaveApplicationPagination: {
          items: state?.leaveApplicationPagination?.items?.filter(
            (x) => x.id !== action.payload.id
          ),
          totalCount: state?.leaveApplicationPagination?.items.totalCount - 1,
        },
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_CANCEL_FINISH:
      return {
        ...state,
        leaveApprovalDeleteResponseLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
