import { Col, Divider, Form, Row, Skeleton, Typography } from "antd";
import SideBody from "./sideBody";
import MainBody from "./mainBody";
import CreateUpdateJobDrawer from "./drawer/jobCreateUpdate";

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionForGetEmpRequisitionAllApplicationDetail,
  actionForGetEmpRequisitionSingleApplicationDetail,
  actionForGetEmpRequisitionSingleApplicationStatusCounterDetail,
  actionForGetStageLevelListing,
  actionForCorporateEmailListing,
  // apiForGetemployeeRequisitionGetPaginationListing,
} from "services/redux/hrSystem/action";
import { isEmpty } from "lodash";
import moment from "moment";

const wholeBody = {
  display: "flex",
  background: "white",
  height: "100%",
  overflow: "hidden",
};

const sideBody = {
  borderRight: "1px solid lightgray",
};

const noDataSelected = {
  textAlign: "center",
  marginTop: 105,
  color: "lightgray",
};

function HRSystem() {
  const {
    applicationAllDataListing,
    applicationAllDataLoading,
    applicationSingleData,
    applicationSingleDataLoading,
    applicationSingleDataSuccess,
    applicationDataSuccess,
    stageLevelListingData,
    applicantSingleBasicDetail,
    applicantSingleDetail,
    applicantTabSuccess,
    applicantId,
    applicationStatusCounter,
    corporateMailListing,
    hrSystemSearchResp,
  } = useSelector((state) => state.hrSystemServicesReducer);

  const dispatch = useDispatch();

  // console.log("applicantId", applicantId);

  const [formApplicantsBasic] = Form.useForm();
  const [formApplicantEdu] = Form.useForm();
  const [formApplicantJob] = Form.useForm();
  const [formApplicantReview] = Form.useForm();
  const [hrForm] = Form.useForm();
  const [selectedJobApplcationId, setJobApplcationId] = useState();
  const [openDrawerRecruitment, setOpenDrawerRecruitment] = useState({
    isOpen: false,
    isEditable: false,
  });
  const [openDrawerApplicant, setOpenApplicantDrawer] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTabIndexofApplicant, setActiveTabIndexofAppicant] =
    useState(null);
  const [selectedSalaryNegotiable, setSalaryNegotiable] = useState(false);
  const [selectedIsGraduated, setIsGraduated] = useState(false);
  const [selectedSingleApplicantDAta, setSelectedSingleApplicantData] =
    useState();
  const [hrCorporateMailLisiting, setHrCorporateMailLisiting] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState();

  // added search
  const pageSize = 20;

  const getHrSearchData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    ForPagination = false
  ) => {
    dispatch(
      actionForGetEmpRequisitionAllApplicationDetail(
        {
          MaxResultCount,
          SkipCount,
          Sorting,
          // SortType,
          SearchKeyword: searchKeyword,
        },
        ForPagination
      )
    );
  };

  useEffect(() => {
    // dispatch(actionForGetEmpRequisitionAllApplicationDetail());
    getHrSearchData();

    if (stageLevelListingData?.length <= 0) {
      dispatch(actionForGetStageLevelListing());
    }
  }, [stageLevelListingData, searchKeyword]);

  // useEffect(() => {
  //   dispatch(actionForGetEmpRequisitionAllApplicationDetail());
  // }, []);

  useEffect(() => {
    if (!isEmpty(selectedJobApplcationId)) {
      dispatch(
        actionForGetEmpRequisitionSingleApplicationDetail(
          selectedJobApplcationId
        )
      );

      dispatch(
        actionForGetEmpRequisitionSingleApplicationStatusCounterDetail(
          selectedJobApplcationId
        )
      );
    }
    if (applicationDataSuccess) {
      hrForm?.resetFields();
      setOpenDrawerRecruitment({
        isOpen: false,
        isEditable: false,
      });
    }
  }, [selectedJobApplcationId, applicationDataSuccess]);

  useEffect(() => {
    if (
      !isEmpty(applicationSingleData) &&
      openDrawerRecruitment.isEditable === true
    ) {
      setSalaryNegotiable(applicationSingleData?.isNegotiableSalary);
      hrForm.setFieldsValue({
        ...applicationSingleData,
        openingDate: moment(applicationSingleData?.openingDate),
        closingDate: moment(applicationSingleData?.closingDate),
        isNegotiableSalary: selectedSalaryNegotiable,
      });
    } else {
      hrForm?.resetFields();
      setSalaryNegotiable(false);
    }
  }, [applicationSingleData, openDrawerRecruitment]);

  useEffect(() => {
    if (!isEmpty(selectedSingleApplicantDAta)) {
      formApplicantsBasic.setFieldsValue({
        ...selectedSingleApplicantDAta,
      });

      formApplicantEdu.setFieldsValue({
        education: selectedSingleApplicantDAta?.eductionDetailsDtos?.map(
          (x, index) => {
            return {
              institution: x?.institution,
              areaOfStudy: x?.areaOfStudy,
              educationLevel: x?.educationLevel,
              joinedDate: moment(x?.joinedDate),
              isGraduated: x?.isGraduated,
            };
          }
        ),
      });

      formApplicantJob.setFieldsValue({
        previousJobDetails:
          selectedSingleApplicantDAta?.previousJobDetails?.map((x, index) => {
            return {
              companyName: x?.companyName,
              companyEmailAddress: x?.companyEmailAddress,
              companyPhoneNumber: x?.companyPhoneNumber,
              companyLocation: x?.companyLocation,
              jobPosition: x?.jobPosition,
              joingDate: moment(x?.joingDate),
              resignDate: moment(x?.resignDate),
            };
          }),
      });
    }
  }, [selectedSingleApplicantDAta]);

  //onSuccess of Adding Aplicants Basic Details

  useEffect(() => {
    if (applicationSingleDataSuccess) {
      // setOpenApplicantDrawer(false);
      if (activeTabIndexofApplicant === "4") {
        setSelectedSingleApplicantData(applicantSingleDetail);
      }
      if (activeTabIndexofApplicant === "1") {
        setActiveTabIndexofAppicant("2");
      } else if (activeTabIndexofApplicant === "2") {
        setActiveTabIndexofAppicant("3");
      } else {
        setActiveTabIndexofAppicant("4");
      }
    }
    if (!applicationAllDataLoading) {
      setOpenDrawerRecruitment({
        isOpen: false,
        isEditable: false,
      });
    }
  }, [applicationSingleDataSuccess, applicationAllDataLoading]);

  // corporate email address listing
  useEffect(() => {
    dispatch(
      actionForCorporateEmailListing({
        isExternal: true,
      })
    );
  }, []);
  useEffect(() => {
    if (!isEmpty(corporateMailListing)) {
      setHrCorporateMailLisiting(
        corporateMailListing.items.map((item) => item.emailAddress)
      );
    }
  }, [corporateMailListing]);
  //end

  return (
    <>
      <CreateUpdateJobDrawer
        openDrawerRecruitment={openDrawerRecruitment}
        setOpenDrawerRecruitment={setOpenDrawerRecruitment}
        hrForm={hrForm}
        setSalaryNegotiable={setSalaryNegotiable}
        selectedSalaryNegotiable={selectedSalaryNegotiable}
        selectedJobApplcationId={selectedJobApplcationId}
        applicationSingleData={applicationSingleData}
      />

      <Row style={wholeBody}>
        <Col span={4} style={sideBody}>
          <SideBody
            setJobApplcationId={setJobApplcationId}
            setOpenDrawerRecruitment={setOpenDrawerRecruitment}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            applicationAllDataListing={applicationAllDataListing}
            applicationAllDataLoading={applicationAllDataLoading}
            setSearchKeyword={setSearchKeyword}
            searchKeyword={searchKeyword}
          />
        </Col>

        <Col span={20}>
          {!isEmpty(selectedJobApplcationId) &&
          !applicationSingleDataLoading ? (
            <MainBody
              applicationSingleData={applicationSingleData}
              openDrawerApplicant={openDrawerApplicant}
              setOpenApplicantDrawer={setOpenApplicantDrawer}
              jobTitleId={selectedJobApplcationId}
              formApplicantsBasic={formApplicantsBasic}
              formApplicantEdu={formApplicantEdu}
              formApplicantJob={formApplicantJob}
              formApplicantReview={formApplicantReview}
              setOpenDrawerRecruitment={setOpenDrawerRecruitment}
              setSelectedSingleApplicantData={setSelectedSingleApplicantData}
              activeTabIndexofApplicant={activeTabIndexofApplicant}
              setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
              selectedSingleApplicantDAta={selectedSingleApplicantDAta}
              setIsGraduated={setIsGraduated}
              selectedIsGraduated={selectedIsGraduated}
              stageLevelListingData={stageLevelListingData}
              selectedTemporarilyApplicantId={applicantId}
              hrCorporateMailLisiting={hrCorporateMailLisiting}
            />
          ) : applicationSingleDataLoading ? (
            <Skeleton />
          ) : (
            <Typography.Title level={2} style={noDataSelected}>
              No Data Selected
            </Typography.Title>
          )}
        </Col>
      </Row>
    </>
  );
}

export default HRSystem;
