import React, { useEffect, useState } from "react";
import { Form, Modal, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import { WechatOutlined } from "@ant-design/icons";
import InternalMCompose from "components/internalMNew";
import { handleSpecialDate } from "utils/tooltipSepecialDate";

const IncomeExpense = ({
  dashboardFinancialResponse,
  dashboardFinancialResponseLoading,
}) => {
  const [widthh, setWidthh] = useState(window.innerWidth);
  const [form] = Form.useForm();

  const [internalVisible, setInternalVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidthh(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns = [
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "left", width: "100%" }}>
          {" "}
          Company
        </Typography.Paragraph>
      ),
      dataIndex: "company",
      key: "company",
      // width: 100,
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={{
              textAlign: "left",
              fontSize: widthh < 1550 ? 13 : 15,
              fontWeight: record?.company === "Total" ? "bold" : "inherit",
            }}
          >
            {record?.company}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right" }}>
          Inc in FY{" "}
        </Typography.Paragraph>
      ),
      dataIndex: "incomeInFiscalYear",
      key: "incomeInFiscalYear",
      align: "right",
      style: { textAlign: "right" },
      render: (_, record) => {
        return (
          <Tooltip
            title={
              // moment(record?.incomeUpdatedDate).format("YYYY MMM DD")
              // handleSpecialDate(record?.incomeUpdatedDate)
              handleSpecialDate(record?.lastUpdated)
            }
          >
            <Typography.Paragraph
              style={{
                textAlign: "right",
                fontSize: widthh < 1550 ? 13 : 15,
                fontWeight: record?.company === "Total" ? "bold" : "inherit",
              }}
            >
              {record?.incomeInFiscalYear === 0
                ? `--`
                : record?.incomeInFiscalYear?.toLocaleString()}
            </Typography.Paragraph>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
          Exp in FY
        </Typography.Paragraph>
      ),
      dataIndex: "expenseInFiscalYear",
      key: "expenseInFiscalYear",
      render: (_, record) => {
        return (
          <Tooltip
            title={
              // moment(record?.expenseUpdatedDate).format("YYYY MMM DD")
              // handleSpecialDate(record?.expenseUpdatedDate)
              handleSpecialDate(record?.lastUpdated)
            }
          >
            <Typography.Paragraph
              style={{
                textAlign: "right",
                fontSize: widthh < 1550 ? 13 : 15,
                fontWeight: record?.company === "Total" ? "bold" : "inherit",
              }}
            >
              {record?.expenseInFiscalYear === 0
                ? `--`
                : record?.expenseInFiscalYear?.toLocaleString()}
            </Typography.Paragraph>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
          Inc-EXP
        </Typography.Paragraph>
      ),
      dataIndex: "incomeExpenseDifference",
      key: "incomeExpenseDifference",
      align: "right",
      render: (_, record) => {
        const incomeExpenseDifference = record?.incomeExpenseDifference;
        const formattedIncomeExpenseDifference =
          incomeExpenseDifference !== undefined ? (
            Math.sign(incomeExpenseDifference) === -1 ? (
              <Typography.Text
                style={{
                  color: "red",
                  fontWeight: record?.company === "Total" ? "bold" : "inherit",
                }}
              >
                ({Math.abs(incomeExpenseDifference)?.toLocaleString()})
              </Typography.Text>
            ) : Math.sign(incomeExpenseDifference) === 0 ? (
              `--`
            ) : (
              incomeExpenseDifference.toLocaleString()
            )
          ) : (
            "--"
          );

        return (
          <Typography.Paragraph
            style={{
              textAlign: "right",
              fontSize: widthh < 1550 ? 13 : 15,
              fontWeight: record?.company === "Total" ? "bold" : "inherit",
            }}
          >
            {formattedIncomeExpenseDifference}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
          Budget
        </Typography.Paragraph>
      ),
      dataIndex: "budget",
      key: "budget",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={{
              textAlign: "right",
              fontSize: widthh < 1550 ? 13 : 15,
              fontWeight: record?.company === "Total" ? "bold" : "inherit",
            }}
          >
            {record?.budget === 0 ? `--` : record?.budget?.toLocaleString()}
          </Typography.Paragraph>
        );
      },
    },
  ];

  // const filteredDataSource = dashboardFinancialResponse?.items
  //   ?.filter((item) => item?.accountCategory === "Income Expense status")
  //   .sort((a, b) =>
  //     a?.financialInformationIncomeExpenseStatus?.company.localeCompare(
  //       b?.financialInformationIncomeExpenseStatus?.company
  //     )
  //   );

  const filteredDataSource = dashboardFinancialResponse?.sisco;

  const totalIncome = filteredDataSource?.reduce(
    (acc, curr) => acc + (curr?.incomeInFiscalYear || 0),
    0
  );
  const totalExpense = filteredDataSource?.reduce(
    (acc, curr) => acc + (curr?.expenseInFiscalYear || 0),
    0
  );
  const totalIncomeExpenseDifference = filteredDataSource?.reduce(
    (acc, curr) => acc + (curr?.incomeExpenseDifference || 0),
    0
  );
  const totalBudget = filteredDataSource?.reduce(
    (acc, curr) => acc + (curr?.budget || 0),
    0
  );

  // create new object for total row
  const totalRow = {
    key: "total",
    company: "Total",
    incomeInFiscalYear: totalIncome,
    expenseInFiscalYear: totalExpense,
    incomeExpenseDifference: totalIncomeExpenseDifference,
    budget: totalBudget,
  };

  // add total row to the dataSource array
  const dataSource = [...(filteredDataSource || []), totalRow];

  return (
    <div className=" honor-wrapper" style={{ paddingRight: 0 }}>
      <div
        className=" dashboards__events dashboards__body "
        style={{ height: 275 }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">
            Income vs Expense Status (Other than ICTC)
          </h4>
          <WechatOutlined
            onClick={() => {
              setInternalVisible(true);
            }}
          />
        </div>
        <Table
          className="next-organizer-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          // scroll={{ y: 400, x: 400 }}
          style={{ maxHeight: 200, overflow: "auto" }}
          rowKey="key"
          // sticky
        />
      </div>
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="Income vs Expense Status"
        />
      </Modal>
    </div>
  );
};

export default IncomeExpense;
