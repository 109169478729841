import moment from "moment";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { cancelLeaveApplication } from "services/redux/requistions/leaveApplication/action";
const LeaveDetail = ({ leaveDetails, setHideLeaveDrawer }) => {
  const dispatch = useDispatch();
  const { leaveApprovalDeleteResponseLoading } = useSelector(
    (state) => state.leaveApplication
  );
  return (
    <>
      <div className="single-leave-details-wrapper">
        <div className="single-leave-item-wrapper">
          <div className="single-leave-item-title">Type</div>
          <div className="single-leave-item-info">
            {leaveDetails[0]?.leaveApplicationTypeDto?.leaveTypeName}
          </div>
        </div>
        <div className="single-leave-item-wrapper">
          <div className="single-leave-item-title">Applied Date</div>
          <div className="single-leave-item-info">
            {moment(leaveDetails[0]?.creationTime).format("YYYY/MM/DD")}
          </div>
        </div>
        <div className="single-leave-item-wrapper">
          <div className="single-leave-item-title">Start</div>
          <div className="single-leave-item-info">
            {moment(leaveDetails[0]?.leaveDayFrom).format("YYYY/MM/DD")}
          </div>
        </div>
        <div className="single-leave-item-wrapper">
          <div className="single-leave-item-title">End</div>
          <div className="single-leave-item-info">
            {moment(leaveDetails[0]?.leaveDayTo).format("YYYY/MM/DD")}
          </div>
        </div>
        <div className="single-leave-item-wrapper">
          <div className="single-leave-item-title">Days</div>
          <div className="single-leave-item-info">
            {leaveDetails[0]?.leaveDays}
          </div>
        </div>
        <div className="single-leave-item-wrapper">
          <div className="single-leave-item-title">Status</div>
          <div className="single-leave-item-info">
            {
              leaveDetails[0]?.leaveApplicationCurrentStatusDto
                ?.statusDisplayName
            }{" "}
          </div>
        </div>
        <div className="single-leave-item-wrapper">
          <div className="single-leave-item-title">Supervisor</div>
          <div className="single-leave-item-info">
            <div className="single-supervison-wrapper">
              {leaveDetails[0]?.leaveApplicationSupervisorDtos.map(
                (supervisor) => {
                  return (
                    <>
                      <span className="supervisor-name">
                        {supervisor?.fullName}
                      </span>
                    </>
                  );
                }
              )}
            </div>
          </div>
        </div>
        <div className="single-leave-item-wrapper">
          <div className="single-leave-item-title">Reason</div>
          <div className="single-leave-item-info">
            {leaveDetails[0]?.reason}
          </div>
        </div>
      </div>
      <div
        className="model-footer"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          marginLeft: "auto",
        }}
      >
        <div>
          <Button
            style={{ float: "left", marginLeft: "5px", justifyItems: "left" }}
            onClick={() => {
              setHideLeaveDrawer(true);
            }}
          >
            <span>Cancel</span>
          </Button>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "5px",
          }}
        >
          {leaveDetails?.[0]?.leaveApplicationCurrentStatusDto
            ?.statusSystemName === "Apply" && (
            <Button
              type="danger"
              loading={leaveApprovalDeleteResponseLoading}
              onClick={() => {
                dispatch(cancelLeaveApplication(leaveDetails?.[0]?.id));
                // setHideLeaveDrawer(true);
              }}
            >
              <span>Delete</span>
            </Button>
          )}

          {leaveDetails?.[0]?.leaveApplicationCurrentStatusDto
            ?.statusSystemName === "Apply" && (
            <Button
              type="primary"
              onClick={() => {
                setHideLeaveDrawer(true);
              }}
            >
              <span>Edit</span>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default LeaveDetail;
