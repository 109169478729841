import { CONTACT_TYPES, REFERENCE__TYPES } from "../../types";
import { COMPANY_PEOPLE_TYPES } from "../../types";

const INITIAL_STATE = {
  countryPaginationLoading: false,
  countryPaginationResponse: null,
  allCountryListingLoading: false,
  allCountryListingResponse: [],
  countryAddLoading: false,
  countryAddResponse: null,
  countryUpdateLoading: false,
  countryUpdateResponse: null,
  contactLabelsPaginationLoading: false,
  contactLabelsPaginationResponse: null,
  contactLabelsAddLoading: false,
  contactLabelsAddResponse: null,
  contactLabelsUpdateLoading: false,
  contactLabelsUpdateResponse: null,
  cityPaginationLoading: false,
  cityPaginationResponse: null,
  cityAddResponse: [],
  cityAddLoading: false,
  cityUpdateLoading: false,
  cityUpdateResponse: null,
  contactLabelPaginationResponse: null,
  contactLabelPaginationLoading: false,
  contactLabelAddResponse: null,
  contactLabelAddLoading: false,
  contactLabelUpdateResponse: null,
  contactLabelUpdateLoading: false,

  //HR currency
  currencyAddResponse: null,
  currencyAddLoading: false,
  currencyPaginationLoading: false,
  currencyPaginationResponse: null,
  currencyUpdateResponse: null,
  currencyUpdateLoading: false,

  //modality Update
  modalityAddResponse: null,
  modalityAddLoading: false,
  modalityPaginationLoading: false,
  modalityPaginationResponse: null,
  modalityUpdateResponse: null,
  modalityUpdateLoading: false,

  //sector
  modalityAddResponse: null,
  modalityAddLoading: false,
  modalityPaginationLoading: false,
  modalityPaginationResponse: null,
  modalityUpdateResponse: null,
  modalityUpdateLoading: false,

  //STATUS
  statusAddResponse: null,
  statusAddLoading: false,
  statusPaginationLoading: false,
  statusPaginationResponse: null,
  statusUpdateResponse: null,
  statusUpdateLoading: false,

  // expert
  contactExpertAddResponse: null,
  contactExpertAddLoading: true,

  contactExpertGetResponse: null,
  contactExpertGetLoading: true,

  contactExpertUpdateResponse: null,
  contactExpertUpdateLoading: true,

  // company contact mail isting
  contactCompanyPersonMailResponse: [],
  contactCompanyPersonMailLoading: true,

  // 
  contactCompanyMailResonse: [],
  contactCompanyMailLoading: true,
};

export default function contactReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CONTACT_TYPES.COUNTRY_PAGINATION_INIT:
      return {
        countryPaginationResponse: null,
        countryPaginationLoading: true,
      };
    case CONTACT_TYPES.COUNTRY_PAGINATION_SUCCESS:
      return {
        ...state,
        countryPaginationResponse: action.payload,
        countryPaginationLoading: false,
      };
    case CONTACT_TYPES.COUNTRY_PAGINATION_FINISH:
      return {
        countryPaginationResponse: null,
        countryPaginationLoading: false,
      };

    // all country listing

    case CONTACT_TYPES.ALL_COUNTRY_LISTING_INIT:
      return {
        ...state,
        allCountryListingResponse: [],
        allCountryListingLoading: true,
      };
    case CONTACT_TYPES.ALL_COUNTRY_LISTING_SUCCESS:
      // console.log("actionresponse", action.payload);
      return {
        ...state,
        allCountryListingResponse: action.payload,
        allCountryListingLoading: false,
      };
    case CONTACT_TYPES.ALL_COUNTRY_LISTING_FINISH:
      return {
        ...state,
        allCountryListingLoading: false,
      };

    case COMPANY_PEOPLE_TYPES.quickCompanyAdd_SUCCESS:
      return {
        ...state,
        allCountryListingResponse: [
          ...state.allCountryListingResponse,
          action.payload,
        ],
      };

    case CONTACT_TYPES.COUNTRY_ADD_INIT:
      return {
        countryAddResponse: null,
        countryAddLoading: true,
      };
    case CONTACT_TYPES.COUNTRY_ADD_SUCCESS:
      return {
        ...state,
        countryAddResponse: action.payload,
        countryAddLoading: false,
      };
    case CONTACT_TYPES.COUNTRY_ADD_FINISH:
      return {
        countryAddResponse: null,
        countryAddLoading: false,
      };
    case CONTACT_TYPES.COUNTRY_UPDATE_INIT:
      return {
        countryUpdateResponse: null,
        countryUpdateLoading: true,
      };
    case CONTACT_TYPES.COUNTRY_UPDATE_SUCCESS:
      return {
        ...state,
        countryUpdateResponse: action.payload,
        countryUpdateLoading: false,
      };
    case CONTACT_TYPES.COUNTRY_UPDATE_FINISH:
      return {
        countryUpdateResponse: null,
        countryUpdateLoading: false,
      };

    case CONTACT_TYPES.CONTACT_LABELS_PAGINATION_INIT:
      return {
        contactLabelsPaginationResponse: null,
        contactLabelsPaginationLoading: true,
      };
    case CONTACT_TYPES.CONTACT_LABELS_PAGINATION_SUCCESS:
      return {
        ...state,
        contactLabelsPaginationResponse: action.payload,
        contactLabelsPaginationLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABELS_PAGINATION_FINISH:
      return {
        contactLabelsPaginationResponse: null,
        contactLabelsPaginationLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABELS_ADD_INIT:
      return {
        contactLabelsAddResponse: null,
        contactLabelsAddLoading: true,
      };
    case CONTACT_TYPES.CONTACT_LABELS_ADD_SUCCESS:
      return {
        ...state,
        contactLabelsAddResponse: action.payload,
        contactLabelsAddLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABELS_ADD_FINISH:
      return {
        contactLabelsAddResponse: null,
        contactLabelsAddLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABELS_UPDATE_INIT:
      return {
        contactLabelsUpdateResponse: null,
        contactLabelsUpdateLoading: true,
      };
    case CONTACT_TYPES.CONTACT_LABELS_UPDATE_SUCCESS:
      return {
        ...state,
        contactLabelsUpdateResponse: action.payload,
        contactLabelsUpdateLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABELS_UPDATE_FINISH:
      return {
        contactLabelsUpdateResponse: null,
        contactLabelsUpdateLoading: false,
      };
    case CONTACT_TYPES.CITY_PAGINATION_INIT:
      return {
        cityPaginationResponse: null,
        cityPaginationLoading: true,
      };
    case CONTACT_TYPES.CITY_PAGINATION_SUCCESS:
      return {
        ...state,
        cityPaginationResponse: action.payload,
        cityPaginationLoading: false,
      };
    case CONTACT_TYPES.CITY_PAGINATION_FINISH:
      return {
        cityPaginationResponse: null,
        cityPaginationLoading: false,
      };
    case CONTACT_TYPES.CITY_ADD_INIT:
      return {
        ...state,
        cityAddResponse: [],
        cityAddLoading: true,
      };
    case CONTACT_TYPES.CITY_ADD_SUCCESS:
      return {
        ...state,
        cityAddResponse: action.payload,
        cityAddLoading: false,
      };
    case CONTACT_TYPES.CITY_ADD_FINISH:
      return {
        cityAddLoading: false,
      };

    //currency HR
    case CONTACT_TYPES.CURRENCY_ADD_INIT:
      return {
        currencyAddResponse: null,
        currencyAddLoading: true,
      };
    case CONTACT_TYPES.CURRENCY_ADD_SUCCESS:
      return {
        ...state,
        currencyAddResponse: action.payload,
        currencyAddLoading: false,
      };
    case CONTACT_TYPES.CURRENCY_ADD_FINISH:
      return {
        currencyAddResponse: null,
        currencyAddLoading: false,
      };

    case CONTACT_TYPES.CURRENCY_PAGINATION_INIT:
      return {
        currencyPaginationResponse: null,
        currencyPaginationLoading: true,
      };
    case CONTACT_TYPES.CURRENCY_PAGINATION_SUCCESS:
      return {
        ...state,
        currencyPaginationResponse: action.payload,
        currencyPaginationLoading: false,
      };
    case CONTACT_TYPES.CURRENCY_PAGINATION_FINISH:
      return {
        currencyPaginationResponse: null,
        currencyPaginationLoading: false,
      };

    case CONTACT_TYPES.CURRENCY_UPDATE_INIT:
      return {
        currencyUpdateResponse: null,
        currencyUpdateLoading: true,
      };
    case CONTACT_TYPES.CURRENCY_UPDATE_SUCCESS:
      return {
        ...state,
        currencyUpdateResponse: action.payload,
        currencyUpdateLoading: false,
      };
    case CONTACT_TYPES.CURRENCY_UPDATE_FINISH:
      return {
        currencyUpdateResponse: null,
        currencyUpdateLoading: false,
      };

    //Sector

    case REFERENCE__TYPES.SECTOR_ADD_INIT:
      return {
        sectorAddResponse: null,
        sectorAddLoading: true,
      };
    case REFERENCE__TYPES.SECTOR_ADD_SUCCESS:
      return {
        ...state,
        sectorAddResponse: action.payload,
        sectorAddLoading: false,
      };
    case REFERENCE__TYPES.SECTOR_ADD_FINISH:
      return {
        sectorAddResponse: null,
        sectorAddLoading: false,
      };

    case REFERENCE__TYPES.SECTOR_PAGINATION_INIT:
      return {
        sectorPaginationResponse: null,
        sectorPaginationLoading: true,
      };
    case REFERENCE__TYPES.SECTOR_PAGINATION_SUCCESS:
      return {
        ...state,
        sectorPaginationResponse: action.payload,
        sectorPaginationLoading: false,
      };
    case REFERENCE__TYPES.SECTOR_PAGINATION_FINISH:
      return {
        sectorPaginationResponse: null,
        sectorPaginationLoading: false,
      };

    case REFERENCE__TYPES.SECTOR_UPDATE_INIT:
      return {
        sectorUpdateResponse: null,
        sectorUpdateLoading: true,
      };
    case REFERENCE__TYPES.SECTOR_UPDATE_SUCCESS:
      return {
        ...state,
        sectorUpdateResponse: action.payload,
        sectorUpdateLoading: false,
      };
    case REFERENCE__TYPES.SECTOR_UPDATE_FINISH:
      return {
        sectorUpdateResponse: null,
        sectorUpdateLoading: false,
      };

    //Status
    case REFERENCE__TYPES.STATUS_ADD_INIT:
      return {
        statusAddResponse: null,
        statusAddLoading: true,
      };
    case REFERENCE__TYPES.SECTOR_ADD_SUCCESS:
      return {
        ...state,
        statusAddResponse: action.payload,
        statusAddLoading: false,
      };
    case REFERENCE__TYPES.SECTOR_ADD_FINISH:
      return {
        statusAddResponse: null,
        statusAddLoading: false,
      };

    //STATUS

    case REFERENCE__TYPES.STATUS_PAGINATION_INIT:
      return {
        statusPaginationResponse: null,
        statusPaginationLoading: true,
      };
    case REFERENCE__TYPES.STATUS_PAGINATION_SUCCESS:
      return {
        ...state,
        statusPaginationResponse: action.payload,
        statusPaginationLoading: false,
      };
    case REFERENCE__TYPES.STATUS_PAGINATION_FINISH:
      return {
        statusPaginationResponse: null,
        statusPaginationLoading: false,
      };

    case REFERENCE__TYPES.STATUS_UPDATE_INIT:
      return {
        statusUpdateResponse: null,
        statusUpdateLoading: true,
      };
    case REFERENCE__TYPES.STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        statusUpdateResponse: action.payload,
        statusUpdateLoading: false,
      };
    case REFERENCE__TYPES.STATUS_UPDATE_FINISH:
      return {
        statusUpdateResponse: null,
        statusUpdateLoading: false,
      };
    //Modality
    case CONTACT_TYPES.MODALITY_ADD_INIT:
      return {
        modalityAddResponse: null,
        modalityAddLoading: true,
      };
    case CONTACT_TYPES.MODALITY_ADD_SUCCESS:
      return {
        ...state,
        modalityAddResponse: action.payload,
        modalityAddLoading: false,
      };
    case CONTACT_TYPES.MODALITY_ADD_FINISH:
      return {
        modalityAddResponse: null,
        modalityAddLoading: false,
      };

    case CONTACT_TYPES.MODALITY_PAGINATION_INIT:
      return {
        modalityPaginationResponse: null,
        modalityPaginationLoading: true,
      };
    case CONTACT_TYPES.MODALITY_PAGINATION_SUCCESS:
      return {
        ...state,
        modalityPaginationResponse: action.payload,
        modalityPaginationLoading: false,
      };
    case CONTACT_TYPES.MODALITY_PAGINATION_FINISH:
      return {
        modalityPaginationResponse: null,
        modalityPaginationLoading: false,
      };

    case CONTACT_TYPES.MODALITY_UPDATE_INIT:
      return {
        modalityUpdateResponse: null,
        modalityUpdateLoading: true,
      };
    case CONTACT_TYPES.MODALITY_UPDATE_SUCCESS:
      return {
        ...state,
        modalityUpdateResponse: action.payload,
        modalityUpdateLoading: false,
      };
    case CONTACT_TYPES.MODALITY_UPDATE_FINISH:
      return {
        modalityUpdateResponse: null,
        modalityUpdateLoading: false,
      };

    //cityUpdate
    case CONTACT_TYPES.CITY_UPDATE_INIT:
      return {
        cityUpdateResponse: null,
        cityUpdateLoading: true,
      };
    case CONTACT_TYPES.CITY_UPDATE_SUCCESS:
      return {
        ...state,
        cityUpdateResponse: action.payload,
        cityUpdateLoading: false,
      };
    case CONTACT_TYPES.CITY_UPDATE_FINISH:
      return {
        cityUpdateResponse: null,
        cityUpdateLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABEL_PAGINATION_INIT:
      return {
        contactLabelPaginationResponse: null,
        contactLabelPaginationLoading: true,
      };
    case CONTACT_TYPES.CONTACT_LABEL_PAGINATION_SUCCESS:
      return {
        ...state,
        contactLabelPaginationResponse: action.payload,
        contactLabelPaginationLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABEL_PAGINATION_FINISH:
      return {
        contactLabelPaginationResponse: null,
        contactLabelPaginationLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABEL_UPDATE_INIT:
      return {
        contactLabelAddResponse: null,
        contactLabelAddLoading: true,
      };
    case CONTACT_TYPES.CONTACT_LABEL_ADD_SUCCESS:
      return {
        ...state,
        contactLabelAddResponse: action.payload,
        contactLabelAddLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABEL_ADD_FINISH:
      return {
        contactLabelAddResponse: null,
        contactLabelAddLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABEL_UPDATE_INIT:
      return {
        contactLabelUpdateResponse: null,
        contactLabelUpdateLoading: true,
      };
    case CONTACT_TYPES.CONTACT_LABEL_UPDATE_SUCCESS:
      return {
        ...state,
        contactLabelUpdateResponse: action.payload,
        contactLabelUpdateLoading: false,
      };
    case CONTACT_TYPES.CONTACT_LABEL_UPDATE_FINISH:
      return {
        contactLabelUpdateResponse: null,
        contactLabelUpdateLoading: false,
      };
    // expert add
    case CONTACT_TYPES.CONTACT_EXPERT_ADD_INIT:
      return {
        ...state,
        contactExpertAddResponse: null,
        contactExpertAddLoading: true,
      };
    case CONTACT_TYPES.CONTACT_EXPERT_ADD_SUCCESS:
      return {
        ...state,
        contactExpertAddResponse: action.payload,
        contactExpertAddLoading: false,
      };
    case CONTACT_TYPES.CONTACT_EXPERT_ADD_FINISH:
      return {
        ...state,
        contactExpertAddResponse: null,
        contactExpertAddLoading: false,
      };
    // expert Get
    case CONTACT_TYPES.CONTACT_EXPERT_GET_INIT:
      return {
        ...state,
        contactExpertGetResponse: null,
        contactExpertGetLoading: true,
      };
    case CONTACT_TYPES.CONTACT_EXPERT_GET_SUCCESS:
      return {
        ...state,
        contactExpertGetResponse: action.payload,
        contactExpertGetLoading: false,
      };
    case CONTACT_TYPES.CONTACT_EXPERT_GET_FINISH:
      return {
        ...state,
        contactExpertGetResponse: null,
        contactExpertGetLoading: false,
      };
    // expert Update
    case CONTACT_TYPES.CONTACT_EXPERT_UPDATE_INIT:
      return {
        ...state,
        contactExpertUpdateResponse: null,
        contactExpertUpdateLoading: true,
      };
    case CONTACT_TYPES.CONTACT_EXPERT_UPDATE_SUCCESS:
      return {
        ...state,
        contactExpertUpdateResponse: action.payload,
        contactExpertUpdateLoading: false,
      };
    case CONTACT_TYPES.CONTACT_EXPERT_UPDATE_FINISH:
      return {
        ...state,
        contactExpertUpdateResponse: null,
        contactExpertUpdateLoading: false,
      };
    case CONTACT_TYPES.ALL_TAGS_LIST_INIT:
      return {
        ...state,
        tagsListResponse: [],
        tagsListResponseLoading: true,
      };
    case CONTACT_TYPES.ALL_TAGS_LIST_SUCCESS:
      return {
        ...state,
        tagsListResponse: action.payload,
        tagsListResponseLoading: false,
      };

    case CONTACT_TYPES.ALL_TAGS_LIST_FINISH:
      return {
        ...state,
        tagsListResponseLoading: false,
      };
    

    // added
    case CONTACT_TYPES.COMPANY_PERSON_MAIL_LISTING_INIT:
      return {
        ...state,
        contactCompanyPersonMailResponse: [],
        contactCompanyPersonMailLoading: true,
      }
    
    case CONTACT_TYPES.COMPANY_PERSON_MAIL_LISTING_SUCCESS:
      return {
        ...state,
        contactCompanyPersonMailResponse: action.payload,
        contactCompanyPersonMailLoading: false,
      }

    case CONTACT_TYPES.COMPANY_PERSON_MAIL_LISTING_FINISH:
      return {
        ...state,
        contactCompanyPersonMailLoading: false,
      }
    
    //
    case CONTACT_TYPES.COMPANY_EMAIL_ADDRESS_LISTING_INIT:
      return {
        ...state,
        contactCompanyMailResonse: [],
        contactCompanyMailLoading: true,
      }
    case CONTACT_TYPES.COMPANY_EMAIL_ADDRESS_LISTING_SUCCESS:
      return {
        ...state,
        contactCompanyMailResonse: action.payload,
        contactCompanyMailLoading: false,
      }

    case CONTACT_TYPES.COMPANY_EMAIL_ADDRESS_LISTING_FINISH:
      return {
        ...state,
        contactCompanyMailLoading: false,
      }


    default:
      return {
        ...state,
      };
  }
}
