import { Button, Col, Form, Input, InputNumber, Layout, Row } from "antd";
import React from "react";

const ManagementInputForm = ({
  commentName,
  totalName,
  loading,
  onClickApprove,
  onClickReject,
}) => {
  const validateNonNegativeNumber = (_, value) => {
    if (value && Number(value) < 0) {
      return Promise.reject(new Error("Value cannot be negative."));
    } else if (value && Number(value) > 10000000) {
      return Promise.reject(new Error("Value exceeds the limit 10000000."));
    }
    return Promise.resolve();
  };
  return (
    <Layout.Content
      style={{
        backgroundColor: "#FAFAFA",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        marginTop: 10,
      }}
    >
      <Form.Item label="Approver's Comment" name={commentName}>
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 6 }}
          autoComplete="off"
          placeholder="Place Your Comment Here..."
        />
      </Form.Item>
      <Form.Item>
        <Row>
          <Col span={5}>
            <Form.Item
              name={totalName}
              rules={[
                {
                  validator: validateNonNegativeNumber,
                },
              ]}
            >
              <InputNumber
                type="number"
                min={0}
                max={10000000}
                maxLength={10000000}
                style={{ width: "100%" }}
                placeholder="Place Amount For Approval"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Layout.Content
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <Button
          loading={loading}
          htmlType="submit"
          onClick={onClickApprove}
          style={{
            backgroundColor: "#3879C5",
            color: "white",
            borderRadius: 4,
            marginLeft: 5,
          }}
        >
          Approve
        </Button>
        <Button
          htmlType="submit"
          onClick={onClickReject}
          style={{
            backgroundColor: "#C53D38",
            color: "white",
            borderRadius: 4,
          }}
        >
          Reject
        </Button>
      </Layout.Content>
    </Layout.Content>
  );
};

export default ManagementInputForm;
