import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Collapse,
  Spin,
} from "antd";

import moment from "moment";
import { isEmpty } from "lodash";
import { CaretRightOutlined } from "@ant-design/icons";
import { getAllUsers } from "services/remanentCalls";
import storage from "../../../../utils/storage";
import { employeeEvaluationProfileDetail } from "../../../../services/redux/profile/profile.action";
import { addEmployeeEvaluationApplication } from "../../../../services/redux/requistions/EmployeeEvaluation/action";
import EmployeeEvaluationDetails from "../../../Email/Includes/EmployeeEvaluationDetails";

const EmployeeEvaluationFormTest = ({
  onCloseDrawer,
  selectedRecord,
  setSelectedRecord,
}) => {
  const dispatch = useDispatch();
  const { profileInfo, employeeProfileInfo } = useSelector(
    (state) => state.profile
  );
  let localStorageId = storage.getItem("userId");

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const currentDate = moment();
  const formattedDate = currentDate.format("YYYY-MM-DD");

  const [loading, setLoading] = useState(false);

  const [selectedNumber, setSelectedNumber] = useState("");
  const [activePanelKey, setActivePanelKey] = useState([]);

  const [supervisorList, setSupervisorList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [currentSteps, setCurrentSteps] = useState();
  const [supervisorValue, setSupervisorValue] = useState([]);

  const handleNumberChange = (value) => {
    setSelectedNumber(value);
    form.setFieldsValue(value);
  };

  // const supervisorValue =
  //   profileInfo?.userRegistrationAddUpdateDto?.userReportingManagerDto.map(
  //     (item) => item.username
  //   ) || [];

  // FETCHING ALL USERS
  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  const onChangeUser = (value) => {
    setSelectedUser(value);
    dispatch(
      employeeEvaluationProfileDetail({
        AppUserId: value,
      })
    );
  };

  useEffect(() => {
    if (employeeProfileInfo && selectedUser !== null) {
      form.setFieldsValue({
        employeeId:
          employeeProfileInfo?.userRegistrationAddUpdateDto?.employeeId,
        name: employeeProfileInfo?.employeePersonalDetailUpdateDto
          ?.employeeName,
        job: employeeProfileInfo?.employeeOfficialDetailUpdateDto
          ?.designationName,
        department:
          employeeProfileInfo?.employeeOfficialDetailUpdateDto?.departmentName,
        // supervisor: supervisorValue,
        supervisor:
          employeeProfileInfo?.userRegistrationAddUpdateDto?.userReportingManagerDto.map(
            (item) => item.username
          ) || [],
        username: employeeProfileInfo?.userRegistrationAddUpdateDto?.userName,
        date: moment(formattedDate, "YYYY-MM-DD"),
      });
      // added
      setSupervisorValue(
        profileInfo?.userRegistrationAddUpdateDto?.userReportingManagerDto.map(
          (item) => item.username
        ) || []
      );
    }
  }, [employeeProfileInfo, selectedUser]);

  useEffect(() => {
    if (selectedRecord) {
      form.setFieldsValue({
        id: selectedRecord?.id,
        employeeId: selectedRecord?.employeeId,
        name: selectedRecord?.name,
        job: selectedRecord?.jobTitle,
        department: selectedRecord?.department,
        supervisor: selectedRecord?.immidiateSuperior,
        username: selectedRecord?.employeeInitials,
        date: moment(selectedRecord?.date, "YYYY-MM-DD"),

        review: selectedRecord?.reviewPeriod || null,

        jobrating: parseInt(selectedRecord?.jobKnowledge?.number) || null,
        jobcomment: selectedRecord?.jobKnowledge?.comments || null,
        workrating: parseInt(selectedRecord?.workQuality?.number) || null,
        workcomment: selectedRecord?.workQuality?.comments || null,
        attendancerating: parseInt(selectedRecord?.punctuality?.number) || null,
        attendancecomment: selectedRecord?.punctuality?.comments || null,
        initiativerating: parseInt(selectedRecord?.initiative?.number),
        initiativecomment: selectedRecord?.initiative?.comments || null,
        communicationrating: parseInt(selectedRecord?.communication?.number),
        communicationcomment: selectedRecord?.communication?.comments || null,

        dependrating: parseInt(selectedRecord?.dependability?.number),
        dependcomment: selectedRecord?.dependability?.comments || null,

        additionalcomment: selectedRecord?.additionalComments || null,
        goalcomment: selectedRecord?.newGoal || null,
      });

      const reviewEdit = selectedRecord ? selectedRecord?.reviewPeriod : "";
      setSelectedNumber(reviewEdit);
    }
  }, [selectedRecord]);

  useEffect(() => {
    if (!isEmpty(selectedRecord?.employeeEvaluationParticipantDetailsDTOs)) {
      const filteredSuperVisor =
        selectedRecord?.employeeEvaluationParticipantDetailsDTOs?.filter(
          function (obj) {
            return obj.participantType === 1;
          }
        );
      setSupervisorList([...filteredSuperVisor]);
    }
  }, [selectedRecord?.employeeEvaluationParticipantDetailsDTOs]);

  useEffect(() => {
    if (!isEmpty(supervisorList)) {
      setCurrentSteps(supervisorList.every((item) => item.isResponed) ? 3 : 2);
    }
  }, [supervisorList]);

  const handleCancelEvaluation = () => {
    onCloseDrawer();
    form.resetFields();
    setSelectedRecord(null);
  };

  const handleDeleteEvaluation = () => {
    let value = {
      ...selectedRecord,
      isactive: false,
    };
    dispatch(addEmployeeEvaluationApplication(value, "delete"));
    onCloseDrawer();
    form.resetFields();
    // setSelectedRecord(null);
  };

  const onCollapseChange = (key) => {
    setActivePanelKey((prevKey) => (prevKey === key ? null : key));
  };

  const onSubmitEmployeeEvaluation = async (values) => {
    try {
      setLoading(true);

      let value = {
        employeeId: form.getFieldValue("employeeId") || null,
        name: form.getFieldValue("name") || null,
        jobTitle: form.getFieldValue("job") || null,
        department: form.getFieldValue("department") || null,
        employeeInitials: form.getFieldValue("username") || null,
        immidiateSuperior: form.getFieldValue("supervisor") || null,
        date: moment(form.getFieldValue("date")).format("YYYY-MM-DD") || null,
        reviewPeriod: form.getFieldValue("review") || null,
        isactive: true,
        approvedRejectRemarks: "This is remarks",
      };

      if (selectedRecord) {
        value.id = form.getFieldValue("id") || null;
      }

      await dispatch(addEmployeeEvaluationApplication(value, "add"));

      // Form submission successful, reset fields
      form.resetFields();
    } catch (error) {
      // console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
      onCloseDrawer();
    }
  };

  return (
    <>
      <Form
        className="drawer-form-wrapper"
        form={form}
        name="EmployeeEvaluationFormTest"
        onFinish={onSubmitEmployeeEvaluation}
      >
        {selectedRecord ? (
          <EmployeeEvaluationDetails
            id={selectedRecord?.id}
            showButton={false}
            currentStep={currentSteps}
          />
        ) : (
          <>
            <Form.Item
              className="form-required-false-common"
              label="Creater User"
              name="creater_user"
              rules={[
                {
                  required: false,
                  message: "please enter your name",
                },
              ]}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Select
                showSearch
                placeholder="Select a user"
                optionFilterProp="children"
                onChange={onChangeUser}
              >
                {users.map((user) => (
                  <Option key={user.appUserId} value={user.appUserId}>
                    {`${user.fullName}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="form-required-false-common"
              label="Name"
              name="name"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              rules={[
                { required: false, message: "Please input your username!" },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              className="form-required-true-common"
              label="Job title"
              name="job"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
              disabled
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              className="form-required-false-common"
              label="Department"
              name="department"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              rules={[
                { required: false, message: "Please input your username!" },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              className="form-required-false-common"
              label="Username"
              name="username"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              rules={[
                { required: false, message: "Please input your username!" },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              className="form-required-false-common"
              label="Date"
              name="date"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              rules={[
                { required: false, message: "Please input your username!" },
              ]}
            >
              <DatePicker
                defaultValue={moment(formattedDate, "YYYY-MM-DD")}
                disabled
              />
            </Form.Item>
            <Form.Item
              className="form-required-false-common"
              label="Supervisor"
              name="supervisor"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              rules={[
                { required: false, message: "Please input your username!" },
              ]}
            >
              <Select
                mode="multiple"
                disabled
                style={{
                  width: "100%",
                }}
                value={supervisorValue}
              >
                {supervisorValue.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="form-required-false-common"
              label="Review(in days)"
              name="review"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              rules={[
                { required: false, message: "Please input your username!" },
              ]}
            >
              <InputNumber min={1} max={100} onChange={handleNumberChange} />
            </Form.Item>

            <Collapse
              activeKey={activePanelKey}
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  rotate={isActive ? 90 : 1}
                  style={{ transition: "transform 0.3s" }}
                />
              )}
              // items={items}
              onChange={onCollapseChange}
            >
              <Collapse.Panel
                header="Employee Evaluation Rating"
                className="employeeEvaluationRatingForm"
                style={{ padding: "0" }}
                key="1"
              >
                <div className="mail-leave-details">
                  <div className="leave-details" style={{ margin: "0px 0px" }}>
                    <Row gutter={20}>
                      <Col lg={12} className="leave-col">
                        <div className="leave-title">Name</div>
                        <div className="leave-input">Job Understanding</div>
                        <div className="leave-input">Job Skills</div>
                        <div className="leave-input">Growth</div>
                        <div className="leave-input">Performance</div>
                        <div className="leave-input">Accountable</div>
                        <div className="leave-input">Commitment</div>
                        <div className="leave-input">Integrity</div>
                        <div className="leave-input">Collaboration</div>
                        <div className="leave-input">Attendance</div>
                        <div className="leave-input">Communication Skills</div>
                      </Col>
                      <Col lg={6} className="leave-col">
                        <div className="leave-title">Rating Number</div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                      </Col>
                      <Col lg={6} className="leave-col">
                        <div className="leave-title">Comments</div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                        <div className="leave-input"></div>
                      </Col>
                      <Col lg={24} className="leave-col">
                        <div className="leave-title">Major Strength</div>
                        <div className="leave-input"></div>
                        <div className="leave-title">Area of Improvement</div>
                        <div className="leave-input"></div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Collapse.Panel>
            </Collapse>
          </>
        )}

        <div
          className="model-footer"
          style={{ justifyContent: "space-between" }}
        >
          {selectedRecord ? (
            <>
              <span></span>
              <Form.Item>
                <Button
                  onClick={handleCancelEvaluation}
                  style={{ marginRight: "8px" }}
                >
                  <span>Cancel</span>
                </Button>
                <Button onClick={handleDeleteEvaluation} danger>
                  <span>Delete</span>
                </Button>
              </Form.Item>
            </>
          ) : (
            <>
              <span></span>
              <Form.Item>
                <Button
                  onClick={handleCancelEvaluation}
                  style={{ marginRight: "8px" }}
                >
                  <span>Cancel</span>
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Apply
                </Button>
              </Form.Item>
            </>
          )}
        </div>
      </Form>
    </>
  );
};

export default EmployeeEvaluationFormTest;
