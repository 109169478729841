const pdfViewerMail = async (url) => {
  try {
    const file = await fetch(url);
    const blob = await file.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.log(error);
  }
};

export default pdfViewerMail;
