import { Table } from "antd";
import React, { useEffect } from "react";
import getColumns from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { getLoanApplicationPagination } from "services/redux/requistions/loanApplication/action";

export const ApprovalTable = () => {
  const dispatch = useDispatch();

  const {
    loanApplicationApprovalPagination,
    loanApplicationApprovalPaginationLoading,
  } = useSelector((state) => state.loanApplication);

  useEffect(() => {
    const body = { IsPendingRequisition: true };
    dispatch(getLoanApplicationPagination(body, "Approval"));
  }, []);

  const columns = getColumns().filter(
    (column) => column.key !== "applicationStatus"
  );

  return (
    <Table
      className="organizer__table contact-table"
      columns={columns}
      rowKey="appUserId"
      dataSource={loanApplicationApprovalPagination?.items}
      loading={{
        spinning: loanApplicationApprovalPaginationLoading,
      }}
    />
  );
};
