import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import appReducer from "services/redux/app/app.reducer";
import settingsReducer from "services/redux/settings/settings.reducer";
import { reducer as todosReducer } from "services/redux/todos/todos.reducer";
import globalalertReducer from "services/redux/misc/globalalert.reducer.js";
import authReducer from "services/redux/auth/auth.reducer";
import profileReducer from "services/redux/profile/profile.reducer";
import contactReducer from "services/redux/contact/contact.reducer";
import contactCompanyPersonReducer from "services/redux/contact/companypeople/reducer.js";
import organizerReducer from "services/redux/organizer/organizer.reducer";
import guestEventsReducer from "services/redux/organizer/guestEvents/reducer";
import honorRollReducer from "services/redux/officeresource/honorroll/reducer";
import organizerEventsPriorityReducer from "services/redux/organizer/priorityLisiting/reducer";
import organizerEventsLabelReducer from "services/redux/organizer/labelListing/reducer";
import goalEventsReducer from "services/redux/organizer/goals/reducer";
import appointmentEventsReducer from "services/redux/organizer/appointments/reducer";
import statusEventListingReducer from "services/redux/todos/StatusListing/statusreducer";
import todoEventsReducer from "services/redux/organizer/todoLists/reducer";
import HomeServicesReducer from "services/redux/Home/reducer";
import meetingEventsReducer from "services/redux/organizer/meeting/reducer";
import sisterCompanyReducer from "services/redux/admin/sisterCompanyListing/reducer";
import meetingStatusReducer from "services/redux/admin/meetingStatusListing/reducer";
import leaveApplicationReducer from "services/redux/requistions/leaveApplication/reducer";
import meetingEventAgendaReducer from "services/redux/organizer/meeting/agenda/reducer";
import meetingEventDecisionReducer from "services/redux/organizer/meeting/decision/reducer";
import calendarEventsReducer from "services/redux/calendar/reducer";
import dynamicFileLabelTypeReducer from "services/redux/common/dynamicFileType/reducer";
import roleListsReducer from "services/redux/adminSettings/roles/reducer";
import mailMessageParticipantTypeListingReducer from "services/redux/email/participantType/reducer";
import quickMessageReducer from "services/redux/email/quickMessage/reducer";
import organizationStructureReducer from "services/redux/adminSettings/organizationStructure/reducer";
import organizationLayerTypeReducer from "services/redux/adminSettings/organizationStructure/oragnizationLayerType/reducer";
import designationReducer from "services/redux/adminSettings/organizationStructure/designation/reducer";
import mailMessageParticipationStatusListingReducer from "services/redux/email/messageCenterType/reducer";
import mailMessageCenterReducer from "services/redux/email/mailMessageCenter/reducer";
import businessCenterReducer from "services/redux/officeresource/business/reducer";
import instanceMessageReducer from "services/redux/email/instanceMessageCenter/reducer";
import participantTypeEmailAddressesListingReducer from "services/redux/email/participantEmailAdresses/reducer";
import projectReducer from "services/redux/Reference/Project/reducer";
import referenceReducer from "services/redux/Reference/reducer";
import tenderReducer from "services/redux/Reference/Tender/reducer";
import secondaryRoot2Reducer from "services/redux/Reference/Secondary/reducer";
import applicationConfigurationReducer from "services/redux/common/applicationConfiguration/reducer";
import remaininigLeaveForUserReducer from "services/redux/requistions/remaingLeaveForUser/reducer";
import leaveTypeReducer from "services/redux/requistions/leaveType/reducer";
import loanApplicationReducer from "services/redux/requistions/loanApplication/reducer";
import procurementReducer from "services/redux/requistions/procurement/reducer";
import notificationsReducer from "services/redux/notification/reducer";
import fiscalYearReducer from "services/redux/admin/FiscalYear/reducer";
import leaveFiscalDaysReducer from "services/redux/admin/LeaveApplication/LeaveFiscalDay/reducer";
import mailMessagePersistReducer from "services/redux/email/persistMail/reducer";
import persistTenderMailReducer from "views/OfficeResource/Reference/Tabs/TenderTabs/companies/persistTender/reducer";
import leaveApplicationTypeReducer from "services/redux/admin/LeaveApplication/LeaveApplicationType/reducer";
import employeeSisterCompanyReducer from "services/redux/common/employeeSisterCompany/reducer";
import contactSelectedReducer from "services/redux/contact/persistcontact/reducer";
import selectedFileNameReducer from "services/redux/common/persistFiles/reducer";
import referenceOthersReducer from "services/redux/Reference/Others/reducer";
import complaintReducer from "services/redux/complaint/redux";

import tadaDetailsReducer from "services/redux/requistions/tadaDetails/reducer";

import sourceReducer from "services/redux/source/redux";
import absentReducer from "services/redux/absent/absent.reducer";
import holidayReducer from "services/redux/holiday/reducer";

import organizationTypeReducer from "services/redux/organizer/typeListing/redux";
import documentRquestUploadReducer from "services/redux/document/reducer";
import instanceChatReducer from "services/redux/instanceChat/reducer";
import appMiddleware from "./middlewares/app";
import { persistStore, persistReducer } from "redux-persist";
//import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import storage from "redux-persist/lib/storage/session"; //Defauts to sessionStorage for web
import setAuthToken from "utils/setAuthToken";
import mailReferenceReducer from "services/redux/email/referenceTags/reducer";
import multipleSelectedEmailReducer from "services/redux/email/multipleEmailSelection/reducer";
// import { reference } from "@popperjs/core";
import settingsreducer from "services/redux/organizer/settings/reducer";
import applicationReducer from "services/redux/organizer/ApplicationTemplate/reducer";
import emailShareReducer from "services/redux/admin/EmailShare/reducer";
import createUpdateGroupReducer from "services/redux/createGroup/reducer";
import hrSystemServicesReducer from "services/redux/hrSystem/reducer";
import ceadReducer from "services/redux/cead/reducer";
import employeeEvaluationReducer from "services/redux/requistions/EmployeeEvaluation/reducer";
import folderReducer from "services/redux/Reference/Folder/reducer";
import blogReducer from "services/redux/officeresource/blog/reducer";
import documentSectorReducer from "services/redux/email/documentSector/reducer";
import ceadApprovalReducer from "services/redux/requistions/ceadApproval/reducer";
import tutorialReducer from "services/redux/admin/Tutorial/reducer";
import circularMailReducer from "services/redux/circularMail/reducer";
import fingerPrintReducer from "services/redux/fingerPrint/reducer";
import whitelistIpReducer from "services/redux/admin/WhitelistIPAddress/reducer";
import groupChatReducer from "services/redux/groupChat/reducer";
import dailyDiaryReducer from "services/redux/dailyDairy/reducer";
import downloadFileReducer from "services/redux/common/downloadFile/reducer";

export default function configureStore() {
  const middlewares = [thunkMiddleware, appMiddleware];

  const mainReducer = combineReducers({
    app: appReducer,
    alert: globalalertReducer,
    auth: authReducer,
    settings: settingsReducer,
    todos: todosReducer,
    profile: profileReducer,
    contact: contactReducer,
    contactCompanyPerson: contactCompanyPersonReducer,
    organizer: organizerReducer,
    application: applicationReducer,
    guestEvents: guestEventsReducer,
    emailShare: emailShareReducer,
    honorRoll: honorRollReducer,
    labelEvents: organizerEventsLabelReducer,
    priorityEvents: organizerEventsPriorityReducer,
    goalEvents: goalEventsReducer,
    appointmentEvents: appointmentEventsReducer,
    statusListing: statusEventListingReducer,
    toDoEvents: todoEventsReducer,
    homeServices: HomeServicesReducer,
    meetingEvents: meetingEventsReducer,
    sisterCompany: sisterCompanyReducer,
    meetingStatus: meetingStatusReducer,
    leaveApplication: leaveApplicationReducer,
    meetingEventAgenda: meetingEventAgendaReducer,
    meetingEventDecision: meetingEventDecisionReducer,
    calendarEvents: calendarEventsReducer,
    dynamicFileLabelTypes: dynamicFileLabelTypeReducer,
    roleLists: roleListsReducer,
    mailMessageParticipantType: mailMessageParticipantTypeListingReducer,
    quickMessage: quickMessageReducer,
    mailMessageParticipantStatus: mailMessageParticipationStatusListingReducer,
    organizationStructure: organizationStructureReducer,
    organizationLayerType: organizationLayerTypeReducer,
    designation: designationReducer,
    mailMessageCenter: mailMessageCenterReducer,
    businessCenter: businessCenterReducer,
    instanceMessageCenter: instanceMessageReducer,
    participantEmailAddresses: participantTypeEmailAddressesListingReducer,
    project: projectReducer,
    reference: referenceReducer,
    tender: tenderReducer,
    secondaryRoot2: secondaryRoot2Reducer,
    applicationConfig: applicationConfigurationReducer,
    remainingLeaverForUser: remaininigLeaveForUserReducer,
    leaveType: leaveTypeReducer,
    loanApplication: loanApplicationReducer,
    procurement: procurementReducer,
    approvalCEAD: ceadApprovalReducer,
    tadaDetails: tadaDetailsReducer,
    notificationsState: notificationsReducer,
    fiscalYearState: fiscalYearReducer,
    leaveFiscalDaysState: leaveFiscalDaysReducer,
    leaveApplicationTypeState: leaveApplicationTypeReducer,
    mailMessagePeristState: mailMessagePersistReducer,
    contactSelectedState: contactSelectedReducer,
    employeeSisterCompanyState: employeeSisterCompanyReducer,
    selectedFileNameState: selectedFileNameReducer,
    referenceOthers: referenceOthersReducer,
    folder: folderReducer,
    complaint: complaintReducer,
    source: sourceReducer,
    absent: absentReducer,
    holiday: holidayReducer,
    organizationType: organizationTypeReducer,
    mailReference: mailReferenceReducer,
    documentRequestUpload: documentRquestUploadReducer,
    instanceChatCenter: instanceChatReducer,
    multipleSelectedEmail: multipleSelectedEmailReducer,
    settingsData: settingsreducer,
    createUpdateGroup: createUpdateGroupReducer,
    hrSystemServicesReducer: hrSystemServicesReducer,
    ceadReducer: ceadReducer,
    employeeEvaluation: employeeEvaluationReducer,
    blogReducer: blogReducer,
    documentSector: documentSectorReducer,
    tutorialReducer: tutorialReducer,
    circularMailReducer: circularMailReducer,
    fingerPrintReducer: fingerPrintReducer,
    whitelistIpReducer: whitelistIpReducer,
    groupChatReducer: groupChatReducer,
    dailyDiaryReducer: dailyDiaryReducer,
    downloadFileReducer: downloadFileReducer,
    persistTenderMailState: persistTenderMailReducer,
  });

  const rootReducer = (state, action) => {
    if (action.type === "USER_LOGOUT_SUCCESS") {
      sessionStorage.removeItem("persist:root");
      state = undefined;
    }

    return mainReducer(state, action);
  };

  const persistConfig = {
    key: "root",
    storage,
    whitelist: [
      "todos",
      "auth",
      "applicationConfig",
      "mailMessagePeristState",
      "contactSelectedState",
      "selectedFileNameState",
      "profile",
      "persistTenderMailState",
    ],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares)
    // other store enhancers if any
  );

  let store = createStore(persistedReducer, enhancer);
  let currentState = store.getState();

  // console.log("currentState", currentState);

  store.subscribe(() => {
    // keep track of the previous and current state to compare changes
    let previousState = currentState;
    currentState = store.getState();
    // if the token changes set the value in localStorage and axios headers
    if (previousState.auth.token !== currentState.auth.token) {
      const token = currentState.auth.token;
      setAuthToken(token);
    }
    // console.log({currentState})
    // if(!currentState.auth.token){

    // }
  });
  let persistor = persistStore(store);

  return { store, persistor };
}
