import Funding from "./funding";
import BidBound from "./bidBound";
import PerformanceBound from "./performanceBound";
import { Divider } from "antd";
import styled from "styled-components";

const StyledDivider = styled(Divider)`
   {
    margin-top: 25px;
    border: 0.5px solid black;
  }
`;

export default function CeadSpecific({
  formFunding,
  formBidBound,
  formPerformanceBound,
  singleTenderCeadData,
  currencyOptions,
  companyOptions,
}) {
  return (
    <>
      <Funding
        singleTenderCeadData={singleTenderCeadData}
        formFunding={formFunding}
        currencyOptions={currencyOptions}
        companyOptions={companyOptions}
      />

      <StyledDivider />

      <BidBound
        singleTenderCeadData={singleTenderCeadData}
        formBidBound={formBidBound}
        currencyOptions={currencyOptions}
      />

      <StyledDivider />

      <PerformanceBound
        singleTenderCeadData={singleTenderCeadData}
        formPerformanceBound={formPerformanceBound}
        currencyOptions={currencyOptions}
      />
    </>
  );
}
