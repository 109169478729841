import React from "react";
import { Typography } from "antd";
import EmailListDetails from "views/Email/EmailListDetails";

const CommunicatonChannel = ({ origin, emailType, emailDetailCompany }) => {
  return (
    <>
      <EmailListDetails
        origin={origin}
        emailType={emailType}
        emailDetailCompany={emailDetailCompany}
      />
    </>
  );
};

export default CommunicatonChannel;
