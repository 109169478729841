import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";

export class LabelListingService {
  getOrganizerEventLabelListings() {
    let url = `${appBaseUrl}/organizerEvent/organizerEventLabelListing`;
    let data = getService(url);
    return data;
  }
}
