import React from "react";
import EmailListDetails from "./EmailListDetails";

const Todos = () => {
  return (
    <>
      <div className="sidebars email">
        <EmailListDetails
          hideMailListHeaderAndTabs={false}
          emailType="mailbox"
        />
      </div>
    </>
  );
};

export default Todos;
