import { saveAs } from 'file-saver';

export const saveBase64 = (props) => {
    let { base64Data, fileName, fileType } = props

    // Validate the presence of required props
    if (!base64Data || !fileName || !fileType) {
        console.error('Missing required properties: base64Data, fileName, or fileType');
        return;
    }

    // Convert base64 string to ArrayBuffer
    const binaryString = window.atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the ArrayBuffer
    // const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const blob = new Blob([bytes]);

    // Save the Blob as a file
    saveAs(blob, fileName + '.' + fileType, fileType);
};
