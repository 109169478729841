import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

const Policies = () => {
  return (
    <>
      <Row gutter={[20, 0]} className="dashboards__row dashboards__row--back">
        <Col lg={24} xs={24}>
          <div className="dashboards__body">
            <h5 className="dashboards__mailInfo--title">
              <ExclamationCircleOutlined
                style={{
                  width: 25,
                  height: 25,
                }}
              />
              {"  "}Company Policies
              <hr />
            </h5>
            <h5 className="setting_header">Policies</h5>
            <span>
              PLEASE READ THE FOLLOWING TERMS CAREFULLY BEFORE USING THIS SITE.
              The following terms governs the use of ‘This site’. By using our
              website you are indicating that you are fully accepting these
              terms. We recommend that you read these Terms prior to using our
              site and there after review any changes and you are responsible
              for doing so. Access to ICTC website We try to ensure that website
              availability is uninterrupted and that transmissions will be
              error-free. However, we cannot, guarantee that your access will
              not be suspended or restricted from time to time, including to
              allow for repairs, maintenance or the introduction of new
              facilities or services. We of course try to limit the frequency
              and duration of any suspension or restriction. ICTC copyright The
              materials in this site are subjected to copyright. The materials
              on or accessible via it belong to 'ICTC' or licensed to us by our
              registered users and other licensees. Any textual or graphic
              material you copy, print, or download is licensed by ICTC and/or
              its subsidiaries therein may not be distributed, published,
              licensed, used or reproduced in any way,in whole or in part in any
              form or any medium, or incorporated into any other work, without
              the express prior written permission of ICTC. Breaches of these
              terms of use Without prejudice to our other rights under these
              terms of use, if you breach these terms of use in any way, we may
              take such action as we deem appropriate to deal with the breach,
              including suspending your access to the website, prohibiting you
              from accessing the website, blocking computers using your IP
              address from accessing the website, contacting your internet
              service provider to request that they block your access to the
              website and/or bringing court proceedings against you. Changes to
              the Terms We may modify the Terms from time to time. When changes
              are made, we will notify you by making the revised version
              available on this webpage. You should revisit these Terms on a
              regular basis as revised versions will be binding on you. Any such
              modification will be effective upon our posting of new Terms. You
              understand and agree that your continued access to or use of the
              Site after any posted modification to the Terms indicates your
              acceptance of the modification.
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Policies;
