import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Image,
  Row,
  Col,
  DatePicker,
  message,
  Skeleton,
  Tooltip,
  Carousel,
  Modal,
  Form,
} from "antd";

import { WechatOutlined } from "@ant-design/icons";
import InternalMCompose from "components/internalMNew";

import { PlusOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { honorRollGetDetails } from "services/remanentCalls";
import { honorRollsPagination } from "services/redux/officeresource/honorroll/action";
import moment from "moment";
import { isEmpty } from "lodash";
import { cqBaseUrl } from "utils/config";

const internet = "assets/images/icons/internet.png";
const man = "assets/images/icons/man.png";

const HonorRollPagination = ({ heightt, shouldRenderRequisitionList }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { RangePicker } = DatePicker;
  const [data, setdata] = useState([]);
  const [cardData, setCardData] = useState({});
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [internalVisible, setInternalVisible] = useState(false);

  const { honorRollsPaginationResponse, honorRollsPaginationLoading } =
    useSelector((state) => state.honorRoll);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const myCurrentDate = new Date();
    const myFutureDate = new Date(myCurrentDate);
    const upcomingEvents = myFutureDate.setDate(myFutureDate.getDate() + 1000);

    const upcomingEventString = new Date(upcomingEvents);

    setStartDate(moment(myCurrentDate).format("YYYY-MM-DD"));
    setEndDate(moment(upcomingEventString).format("YYYY-MM-DD"));
    getData(
      10,
      0,
      moment(myCurrentDate).format("YYYY-MM-DD"),
      moment(upcomingEventString).format("YYYY-MM-DD")
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(honorRollsPaginationResponse?.items)) {
      setdata(honorRollsPaginationResponse.items);
      if (honorRollsPaginationResponse.items) {
        const fetchDetails = async () => {
          const resp = await honorRollGetDetails(
            honorRollsPaginationResponse.items[0].id
          ).catch((err) => message.error("Failure"));

          if (resp) {
            setCardData(resp.data);
          }
        };
        fetchDetails();
      }

      setTotalCount(honorRollsPaginationResponse.totalCount);
    } else {
      setdata([]);
    }
  }, [honorRollsPaginationResponse]);

  const getHonorEvents = () => {
    const myCurrentDate = new Date();
    const myFutureDate = new Date(myCurrentDate);
    const upcomingEvents = myFutureDate.setDate(myFutureDate.getDate() + 1000);

    const upcomingEventString = new Date(upcomingEvents);
    setStartDate(moment(myCurrentDate).format("YYYY-MM-DD"));
    setEndDate(moment(upcomingEventString).format("YYYY-MM-DD"));
  };

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,

    SearchDateFrom,
    SearchDateTo
  ) => {
    dispatch(
      honorRollsPagination({
        MaxResultCount,
        SkipCount,
        SearchDateFrom,
        SearchDateTo,
      })
    );
  };

  return (
    //   <RangePicker
    //     onChange={(value) => {
    //       setStartDate(value ? moment(value[0]).format("YYYY-MM-DD") : "");
    //       setEndDate(value ? moment(value[1]).format("YYYY-MM-DD") : "");
    //     }}
    //   />

    <div className="honor-wrapper" style={{ paddingRight: "10px" }}>
      <div
        className="dashboards__events dashboards__body"
        style={{ height: heightt[0] }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">Honor</h4>
          <WechatOutlined
            onClick={() => {
              setInternalVisible(true);
            }}
          />
        </div>

        <div
          className="dashboards__body--main"
          style={{ height: `${heightt[1]}%` }}
        >
          <ul className="dashboards__todolists list1">
            {honorRollsPaginationResponse?.items?.length > 0 ? (
              honorRollsPaginationResponse?.items?.map((list, i) => {
                return (
                  <li key={i}>
                    <Col span={24}>
                      <div className="honor__card">
                        <div className="honor__card--details">
                          <div className="honot-title-wrapper">
                            <h2> {list.title}</h2>
                            <p>{moment(list.endDate).format("YYYY/MM/DD")}</p>
                          </div>
                          <>
                            <div className="honor__card--image">
                              <div className="honor__card--wrap">
                                <Carousel autoplay={true} dots={false}>
                                  {list?.honorRollCandidateDtos?.map(
                                    (picture, i) => {
                                      let name = picture?.candidateName;
                                      let profilePicNameFL = name
                                        ?.match(/\b(\w)/g)
                                        .join("");

                                      return (
                                        <>
                                          <div
                                            className="honor__card--circle"
                                            key={i}
                                          >
                                            {!isEmpty(
                                              picture.candiateProfilePictureURL
                                            ) ? (
                                              <img
                                                src={
                                                  cqBaseUrl +
                                                  "/" +
                                                  picture.candiateProfilePictureURL +
                                                  token
                                                }
                                                alt="profile"
                                              />
                                            ) : (
                                              <div
                                                className="default-profile-name"
                                                style={{
                                                  width: "52px",
                                                  height: "52px",
                                                }}
                                                key={i}
                                              >
                                                {profilePicNameFL}
                                              </div>
                                            )}
                                          </div>
                                          <p className="author">
                                            {picture.candidateName}
                                          </p>
                                        </>
                                      );
                                    }
                                  )}
                                  {/* {cardData.honorRollCandidateDtos?.map((picture) => (
                  <>
                    <div className="honor__card--circle">
                      <img
                        src={
                          cqBaseUrl +
                          "/" +
                          picture.candiateProfilePictureURL +
                          token
                        }
                      />
                    </div>
                  </>
                ))} */}
                                </Carousel>
                              </div>
                            </div>
                          </>
                        </div>
                      </div>
                    </Col>
                  </li>
                );
              })
            ) : !honorRollsPaginationLoading ? (
              <div className="dashboards__todolists">
                <div className="dashboards__visitors--none">
                  <Image className="data-no-img" src={man} />
                  <span>There is no information.</span>
                </div>
              </div>
            ) : (
              <Skeleton />
            )}
          </ul>
        </div>
      </div>
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="Honor"
        />
      </Modal>
    </div>
  );
};

export default HonorRollPagination;
