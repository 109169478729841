import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { cloneDeep, indexOf, isEmpty, split } from "lodash";
import { Button, Dropdown, Image, Menu, Select, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { appBaseUrl } from "utils/config";

// utils
import storage from "../../utils/storage";
import {
  composeEmail,
  mailReferenceListing,
  emailPaginationCount,
  mailReferenceTagUpdate,
} from "services/redux/email/mailMessageCenter/action";
import { tenderGetBasicDetails } from "services/redux/Reference/Tender/action";
import { emailGetDetails } from "services/redux/email/mailMessageCenter/action";
import { selectedActiveEmail } from "services/redux/email/persistMail/action";
import { selectedMailTypeAndMail } from "services/redux/email/persistMail/action";
import { toDoGetDetails } from "services/redux/organizer/todoLists/action";

//antd
import { Tooltip } from "antd";
import { COMPANY_PEOPLE_TYPES } from "../../services/types";
import { setSelectedFileName } from "../../services/redux/common/persistFiles/action";
import { multipleEmailSelectionAction } from "../../services/redux/email/multipleEmailSelection/action";
import { changeEmailStatusType } from "services/redux/email/mailMessageCenter/action";
import {
  // PaperClipOutlined,
  PlusCircleOutlined,
  StarFilled,
  ExclamationCircleOutlined,
  FlagOutlined,
} from "@ant-design/icons";
import specialFlag0 from "../../assets/svg/flagSpecial0.svg";
import specialFlag1 from "../../assets/svg/flagSpecial1.svg";
import specialFlag2 from "../../assets/svg/flagSpecial2.svg";
import specialFlag3 from "../../assets/svg/flagSpecial3.svg";
import specialFlag4 from "../../assets/svg/flagSpecial4.svg";
import specialFlag5 from "../../assets/svg/flagSpecial5.svg";
import { projectGetDetails } from "services/redux/Reference/Project/action";
import { TagOutlined, TagsOutlined } from "@ant-design/icons";
import "./dropdown.css";
import axios from "axios";
import CommonModalForRecentUsedTags from "components/modals";
import { Link } from "react-router-dom";
import { getGuestEntertainmentDetails } from "services/redux/requistions/leaveType/action";
import { complainGetDetails } from "services/redux/complaint/action";

// icons
const chat = "assets/svg/chat-black.svg";
const unread = "assets/svg/unread.svg";
const star = "assets/svg/star.svg";
// const sun = "assets/svg/sun.svg";
// const read = "assets/svg/read.svg";
const send = "assets/svg/send-12.svg";
const archieve = "./assets/svg/archive-12.svg";
const junk = "./assets/svg/junk.svg";
const trash = "./assets/svg/delete-12.svg";
const pending = "./assets/svg/pending-12.svg";
const attachment = "assets/svg/attatchment.svg";
const isReadChat = "assets/svg/chat-blue.svg";
// const specialFlag = "assets/images/icons/specialFlag.png";
// const flag5 = "assets/images/icons/flag5.png";

let Maxcount = 3;

const EmailCard = ({
  // setNextMsg,
  Reference_SearchKeyword,
  mailMessageCenterPagination,
  mailMessageCenterPaginationLoading,
  setCurrentSelectedMailMessage,
  currentSelectedMailMessage,
  setchatBox,
  setShowSideMenuEmail,
  fetchMoreData,
  mailBodyGetDetailsLoading,
  setMultipleEmailSelection,
  multipelEmailSelection,
  messageCenterTypeName,
  allInputChecked,
  checkAll,
  openAutoReferencing,
  setOpenAutoReferencing,
  //amitadd
  referenceTagsFromAutoReference,
  setFilteredEmails,
  filteredEmails,
  currentTab,
  corporateSharedAccessAccountHolderUserId,
  setContextMenushow,
  contextMenushow,
  emailType,
}) => {
  //state
  const [paginationReferenceTags, setPaginationReferenceTags] = useState([]);
  const [selectedMail, setSelectedMail] = useState([]);
  const [emailChecked, setEmailChecked] = useState(false);
  // const [timeOut, setTimeoutD] = useState(3500);

  //redux store
  const { mailReferenceListingResponse, mailBodyGetDetailsResponse } =
    useSelector((state) => state.mailMessageCenter);

  const { activeEmail } = useSelector((state) => state.mailMessagePeristState);

  const { multipleSelectedEmail } = useSelector(
    (state) => state.multipleSelectedEmail
  );

  // const { autoReferenceListingResponse } = useSelector(
  //   (state) => state.reference
  // );

  // referenceTagsForEmailCard = referenceTagsForEmailCard?.map((x) => {
  //   return x?.emailReferenceIdReferenceCodeDtos;
  // });

  const [showEmailId, setShowEmailId] = useState("");
  const [recentlyUsedReferenceTags, setRecentlyUsedTags] = useState();
  const [openRecentReferenceModal, setOpenRecentlyAddTags] = useState(false);
  const {
    getResponseStatus,
    composeEmailRespLoading,
    changeEmailStatusTypeRespLoading,
    changeEmailStatusTypeResp,
  } = useSelector((state) => state.mailMessageCenter);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mailReferenceListing());
  }, [dispatch]);

  useEffect(() => {
    if (activeEmail) {
      setSelectedMail(activeEmail);
    }
  }, []);

  let spliceDatatest = "";
  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  useEffect(() => {
    if (isEmpty(mailReferenceListingResponse)) return;
    setPaginationReferenceTags(cloneDeep(mailReferenceListingResponse));
  }, [mailReferenceListingResponse]);

  useEffect(() => {
    if (!isEmpty(mailBodyGetDetailsResponse))
      setCurrentSelectedMailMessage(mailBodyGetDetailsResponse);
  }, [mailBodyGetDetailsResponse]);

  useEffect(() => {
    if (
      getResponseStatus?.participantStatusName === "Archived" ||
      getResponseStatus?.participantStatusName === "Trash" ||
      getResponseStatus?.participantStatusName === "doubleTrash" ||
      getResponseStatus?.participantStatusName === "Pending" ||
      getResponseStatus?.participantStatusName === "Read" ||
      getResponseStatus?.participantStatusName === "Junk" ||
      getResponseStatus?.participantStatusName === "Special1"
    ) {
      // setTimeoutD(0);
      const nextCurrentSelectMessage =
        mailMessageCenterPagination?.items[
          selectedMailTypeAndMailData.index == 0
            ? selectedMailTypeAndMailData.index + 1
            : selectedMailTypeAndMailData.index <
              mailMessageCenterPagination?.items.length - 1
            ? selectedMailTypeAndMailData.index + 1
            : selectedMailTypeAndMailData.index ==
                mailMessageCenterPagination?.items.length - 1 &&
              selectedMailTypeAndMailData.index - 1
        ];
      // setNextMsg(nextCurrentSelectMessage);
      let data = {
        scroll: "none",
        ...selectedMailTypeAndMailData,
        index:
          selectedMailTypeAndMailData.index == 0
            ? getResponseStatus?.participantStatusName === "Read"
              ? selectedMailTypeAndMailData.index + 1
              : selectedMailTypeAndMailData.index
            : selectedMailTypeAndMailData.index <
              mailMessageCenterPagination?.items.length - 1
            ? getResponseStatus?.participantStatusName === "Read"
              ? selectedMailTypeAndMailData.index + 1
              : selectedMailTypeAndMailData.index
            : selectedMailTypeAndMailData.index ==
                mailMessageCenterPagination?.items.length - 1 &&
              selectedMailTypeAndMailData.index - 1,
        selectedMail:
          mailMessageCenterPagination?.items[
            selectedMailTypeAndMailData.index == 0
              ? selectedMailTypeAndMailData.index + 1
              : selectedMailTypeAndMailData.index <
                mailMessageCenterPagination?.items.length - 1
              ? selectedMailTypeAndMailData.index + 1
              : selectedMailTypeAndMailData.index ==
                  mailMessageCenterPagination?.items.length - 1 &&
                selectedMailTypeAndMailData.index - 1
          ],
      };

      if (!changeEmailStatusTypeRespLoading && !mailBodyGetDetailsLoading) {
        dispatch(selectedMailTypeAndMail(data));
        if (!isEmpty(nextCurrentSelectMessage)) {
          // if (nextCurrentSelectMessage.applicationTypeSystemName === "Task") {
          //   dispatch(toDoGetDetails(nextCurrentSelectMessage?.applicationId));
          // }
          // if (
          //   nextCurrentSelectMessage.applicationTypeSystemName === "Business"
          // ) {
          //   dispatch(
          //     tenderGetBasicDetails(nextCurrentSelectMessage?.applicationId)
          //   );
          // }
          // if (
          //   nextCurrentSelectMessage.applicationTypeSystemName === "Project"
          // ) {
          //   dispatch(
          //     projectGetDetails(nextCurrentSelectMessage?.applicationId)
          //   );
          // }
          if (
            nextCurrentSelectMessage?.applicationTypeSystemName ===
            "InstanceMessage"
          ) {
            dispatch(
              emailGetDetails({
                mailMessageParticipantTableId:
                  nextCurrentSelectMessage?.mailMessageParticipantTableId,
                mailMessageCenterId:
                  nextCurrentSelectMessage.instanceMainMessageCenterId,
                latestInstanceMessageId:
                  nextCurrentSelectMessage.latestInstanceMessageId,
                applicationType:
                  nextCurrentSelectMessage.applicationTypeSystemName,
                filterTypes: emailType === "Business" ? "subordinates" : "",
              })
            );
          } else {
            dispatch(
              emailGetDetails({
                mailMessageParticipantTableId:
                  nextCurrentSelectMessage?.mailMessageParticipantTableId,
                // mailMessageCenterId: nextCurrentSelectMessage.id,
                mailMessageCenterId:
                  nextCurrentSelectMessage.instanceMainMessageCenterId,
                latestInstanceMessageId:
                  nextCurrentSelectMessage.latestInstanceMessageId,
                applicationType:
                  nextCurrentSelectMessage.applicationTypeSystemName,
                filterTypes: emailType === "Business" ? "subordinates" : "",
              })
            );
          }
        }
      }
      // getBodyDetails(getResponseStatus.currentSelectedMailMessage);
      if (!mailBodyGetDetailsLoading && !changeEmailStatusTypeRespLoading) {
        //
        fetchMoreData();
        // dispatch(emailPaginationCount({ messagecenterType: "All" }));
        // dispatch(emailPaginationCount({ messagecenterType: "From" }));
        // dispatch(emailPaginationCount({ messagecenterType: "To" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Cc" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Bcc" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Pending" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Archived" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Trash" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Junk" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Specialflag1" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Draft" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Outbox" }));
      }
    }
  }, [getResponseStatus, changeEmailStatusTypeRespLoading]);

  // useEffect(() => {
  //   if (getResponseStatus?.currentSelectedMailMessage) {
  //     getBodyDetails(getResponseStatus.currentSelectedMailMessage);
  //   }
  // }, [getResponseStatus?.currentSelectedMailMessage]);

  // useEffect(() => {
  // 	if (!isEmpty(mailBodyGetDetailsResponse)) {
  // 		if (mailBodyGetDetailsResponse?.applicationTypeSystemName === 'InstanceMessage') dispatch(emailGetDetails(mailBodyGetDetailsResponse?.instanceMainMessageCenterId));
  // 		else {
  // 			dispatch(emailGetDetails(mailBodyGetDetailsResponse?.id));
  // 		}
  // 	}
  // }, [mailBodyGetDetailsResponse]);

  // const spliceData = (tags, id) => {
  // 	let data = cloneDeep(tags);
  // 	const filter = data.filter((filterTags) => filterTags.messageCenterId === id);
  // 	let spliceDatatest = filter;
  // 	if (!isEmpty(paginationReferenceTags) && paginationReferenceTags.length > 4) {
  // 		spliceDatatest = filter.splice(4);
  // 	}
  // 	return (
  // 		<div className="referenceDetails__reference--revisions mailSection">
  // 			<Tooltip title={spliceDatatest.join(',')}>
  // 				<span className="referenceDetails--subtitle">+{spliceDatatest.length}</span>
  // 			</Tooltip>
  // 		</div>
  // 	);
  // };

  // const getDetails = (data) => {
  // 	let halfDetails = data.map((text, i) => {

  // 		return
  // 	})
  // 	return halfDetails;
  // };
  // useEffect(()=>{
  //   if(emailChecked){
  //     const checkBoxClass = document.getElementById("emailCardCheckBox")
  //     // checkBoxClass.style.display = "block";
  //     checkBoxClass.classList.add("checkboxClass")

  //   }else{
  //   }
  // },[emailChecked])

  const getRecentlyUsedreferenceData = async () => {
    try {
      const response = await axios.get(
        `${appBaseUrl}/mailMessageCenter/mailMessageReferenceListing?FilterType=recentlyused`
      );
      if (response?.status === 200) {
        const recentTags = response?.data?.map((x) => {
          return {
            label: x.referenceCode,
            value: x.referenceCode,
          };
        });
        setRecentlyUsedTags(recentTags);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getRecentlyUsedreferenceData();
  }, [currentSelectedMailMessage]);

  const getBodyDetails = (mail) => {
    if (mail) {
      if (mail.applicationTypeSystemName === "Task") {
        dispatch(toDoGetDetails(mail?.applicationId));
      }
      if (mail.applicationTypeSystemName === "Business") {
        dispatch(tenderGetBasicDetails(mail?.applicationId));
      }
      if (mail.applicationTypeSystemName === "Project") {
        dispatch(projectGetDetails(mail?.applicationId));
      }
      if (mail.applicationTypeSystemName === "GuestEntertainment") {
        dispatch(getGuestEntertainmentDetails(mail?.applicationId));
      }
      if (mail.applicationTypeSystemName === "Complaint") {
        dispatch(complainGetDetails(mail?.applicationId));
      }
      // if (showEmailId !== mail.id) {
      if (mail?.applicationTypeSystemName === "InstanceMessage") {
        dispatch(
          emailGetDetails({
            mailMessageParticipantTableId: mail?.mailMessageParticipantTableId,
            mailMessageCenterId: mail.instanceMainMessageCenterId,
            latestInstanceMessageId: mail.latestInstanceMessageId,
            applicationType: mail.applicationTypeSystemName,
            filterTypes: emailType === "Business" ? "subordinates" : "",
            // mailMessageCenterId: mail?.instanceMainMessageCenterId,
          })
        );
      } else {
        dispatch(
          emailGetDetails({
            mailMessageParticipantTableId: mail?.mailMessageParticipantTableId,
            //amit commented
            // mailMessageCenterId: mail.id,
            latestInstanceMessageId: mail.latestInstanceMessageId,
            applicationType: mail.applicationTypeSystemName,
            mailMessageCenterId: mail?.instanceMainMessageCenterId,
            //amit add
            CorporateSharedAccessAccountHolderUserId:
              corporateSharedAccessAccountHolderUserId,
            filterTypes: emailType === "Business" ? "subordinates" : "",
          })
        );
      }

      // }
    }
  };
  const getHalfDetails = (details) => {
    let values = [...details];

    let halfDetails = [];
    halfDetails = values.splice(3);
    let remainingSplicedTags = "";

    let getRemainingSplicedTags = halfDetails.map((data, i) => {
      remainingSplicedTags = data.referenceCode;
      return remainingSplicedTags;
    });

    return (
      <div className="flex-box" style={{ paddingBottom: 2 }}>
        {/* {values.map((data, key) => ( */}
        {values?.map((data, key) => (
          <>
            {data?.referenceCode.length > 9 ? (
              <div
                key={key}
                className="emailList__card--ref paginationTag"
                style={{
                  width: 50,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                <Tooltip title={data.referenceCode}>
                  {data?.referenceCode.substring(0, 12)}
                  {/* hello tags */}
                </Tooltip>
              </div>
            ) : (
              <div key={key} className="emailList__card--ref paginationTag">
                {data?.referenceCode}
                {/* hello tags */}
              </div>
            )}
          </>
        ))}
        {halfDetails.length > 0 && (
          <div className="referenceDetails__reference--revisions mailSection">
            <Tooltip title={getRemainingSplicedTags.join(",")}>
              <span className="referenceDetails--subtitle">
                +{getRemainingSplicedTags.length}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  // let dirname = electron.commonApi.getDirName();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  // const [contextMenushow, setContextMenushow] = useState(false); // hide menu
  const [rightClickIndex, setRightClickIndex] = useState(null);

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();

      let target;
      if (event.target.offsetParent.classList.contains("emailList__info")) {
        target = event.target.offsetParent;
      } else if (event.target.classList.contains("emailList__card")) {
        target = event.target.querySelector(".emailList__info");
      } else {
        target = event.target.offsetParent.offsetParent;
      }
      if (target) {
        let id = target.hasAttribute("data-id")
          ? target.getAttribute("data-id")
          : null;
        if (id) {
          setAnchorPoint({ x: event.pageX, y: event.pageY });
          setContextMenushow(true);
          let rightIndex = mailMessageCenterPagination?.items
            ?.map((item) => {
              return item.id;
            })
            .indexOf(id);
          setRightClickIndex(rightIndex);
        }
      }
    },
    [setAnchorPoint]
  );

  useEffect(() => {
    let emailList = document.querySelector(".emailList__lists");
    document.addEventListener("click", handleBodyClick);
    if (emailList) {
      emailList.addEventListener("contextmenu", handleContextMenu);
      return () => {
        emailList.removeEventListener("contextmenu", handleContextMenu);
      };
    }
    return () => {
      document.removeEventListener("click", handleBodyClick);
    };
  });

  useEffect(() => {
    setRightClickIndex(null);
  }, []);

  useEffect(() => {
    if (rightClickIndex >= 0) {
      let mail = mailMessageCenterPagination?.items[rightClickIndex];
      setchatBox(false);
      setShowEmailId(mail?.id);
      getBodyDetails(mail);
      setShowSideMenuEmail(false);
      let data = {
        ...selectedMailTypeAndMailData,
        selectedMail: mail,
        index: rightClickIndex,
      };
      if (mail?.id !== showEmailId) {
        setCurrentSelectedMailMessage("");
      }
      dispatch(
        selectedMailTypeAndMail(
          data
          // ...mail, /
          // index: index,
          // nextData: mailMessageCenterPagination?.items[index + 1],
        )
      );
    }
  }, [rightClickIndex]);

  const handleBodyClick = () => {
    setContextMenushow(false);
  };

  const changeEmailStatus = (emailStatus, status) => {
    let query = {
      messageCenterId: selectedMailTypeAndMailData?.selectedMail?.id,
      typeSystemName: messageCenterTypeName,
      mailMessageParticipantTableId:
        selectedMailTypeAndMailData?.selectedMail
          ?.mailMessageParticipantTableId,
      participantStatusName: emailStatus.isRead
        ? "Read"
        : emailStatus.junked
        ? emailStatus.junked
        : emailStatus.archived
        ? emailStatus.archived
        : emailStatus.trash
        ? `${
            messageCenterTypeName !== "Trash"
              ? "Trash"
              : messageCenterTypeName == "Trash" && emailStatus.move === "Inbox"
              ? "Trash"
              : "doubleTrash"
          }`
        : "Un-read",
      instanceMessageId:
        selectedMailTypeAndMailData?.selectedMail?.applicationTypeSystemName ===
        "InstanceMessage"
          ? selectedMailTypeAndMailData?.selectedMail?.latestInstanceMessageId
          : "",
      instanceMainMessageCenterId:
        selectedMailTypeAndMailData?.selectedMail?.instanceMainMessageCenterId,
      CorporateSharedAccessAccountHolderUserId:
        corporateSharedAccessAccountHolderUserId,
    };
    dispatch(
      changeEmailStatusType(query, status, messageCenterTypeName, emailStatus)
    );
  };

  const handleStatusClick = useCallback((e) => {
    //contextMenushow ? setContextMenushow(false) : null), [contextMenushow]
    let target = "";
    if (e.target.tagName === "P") {
      target = e.target.parentNode.parentNode;
    } else if (e.target.tagName === "IMG") {
      target = e.target.parentNode.parentNode.parentNode;
    } else {
      target = e.target;
    }
    let action = target.getAttribute("data-action");
    if (action === "Read") {
      changeEmailStatus(
        !selectedMailTypeAndMailData?.selectedMail?.isRead
          ? { isRead: true, isRead_InstanceMessage: true }
          : { isRead: false, isRead_InstanceMessage: false }
      );
    }
    if (action === "Archive") {
      changeEmailStatus(
        !selectedMailTypeAndMailData?.selectedMail?.isArchieve
          ? {
              isArchieve: true,
              isArchived_InstanceMessage: true,
              archived: "Archived",
            }
          : {
              isArchived_InstanceMessage: false,
              isArchieve: false,
              archived: "Archived",
            },
        selectedMailTypeAndMailData?.selectedMail?.isArchieve ? "Remove" : "Add"
      );
    }
    if (action === "Junk") {
      changeEmailStatus(
        !selectedMailTypeAndMailData?.selectedMail?.isJunk
          ? {
              isJunk: true,
              isJunked_InstanceMessage: true,
              junked: "Junk",
            }
          : {
              isJunked_InstanceMessage: false,
              isJunk: false,
              junked: "Junk",
            },
        selectedMailTypeAndMailData?.selectedMail?.isJunk ? "Remove" : "Add"
      );
    }
    if (action === "Delete") {
      changeEmailStatus(
        !selectedMailTypeAndMailData?.selectedMail?.isTrashed &&
          !selectedMailTypeAndMailData?.selectedMail?.isTrashed_InstanceMessage
          ? {
              isTrashed: true,
              isTrashed_InstanceMessage: true,
              trash: "Trash",
              move: "doubleTrash",
            }
          : {
              isTrashed_InstanceMessage: false,
              isTrashed: false,
              move: "doubleTrash",
            },
        selectedMailTypeAndMailData?.selectedMail?.isTrashed ||
          selectedMailTypeAndMailData?.selectedMail?.isTrashed_InstanceMessage
          ? "Remove"
          : "Add"
      );
    }
    if (action === "Recover") {
      changeEmailStatus(
        !selectedMailTypeAndMailData?.selectedMail?.isTrashed
          ? {
              isTrashed: true,
              isTrashed_InstanceMessage: true,
              trash: "Trash",
              move: "Inbox",
            }
          : {
              isTrashed_InstanceMessage: false,
              isTrashed: false,
              trash: "Trash",
              move: "Inbox",
            },
        selectedMailTypeAndMailData?.selectedMail?.isTrashed ||
          selectedMailTypeAndMailData?.selectedMail?.isTrashed_InstanceMessage
          ? "Move to Inbox"
          : "Undo"
      );
    }
    if (action === "AutoReference") {
      setOpenAutoReferencing(true);
    }

    // if (action === "Recent") {
    //   setOpenRecentlyAddTags(true);
    // }
  });

  useEffect(() => {
    let indexNewMsg = null;
    let newEmails = [];

    if (selectedMailTypeAndMailData?.typeDisplayName === "Special Flag 1") {
      newEmails = mailMessageCenterPagination?.items?.filter((x, idx) => {
        return x?.flagTypeId === 1;
      });
      setCurrentSelectedMailMessage([]);
    } else if (
      selectedMailTypeAndMailData?.typeDisplayName === "Special Flag 2"
    ) {
      newEmails = mailMessageCenterPagination?.items?.filter((x, idx) => {
        return x?.flagTypeId === 2;
      });
      setCurrentSelectedMailMessage([]);
    } else if (
      selectedMailTypeAndMailData?.typeDisplayName === "Special Flag 3"
    ) {
      newEmails = mailMessageCenterPagination?.items?.filter((x, idx) => {
        return x?.flagTypeId === 3;
      });
      setCurrentSelectedMailMessage([]);
    } else if (
      selectedMailTypeAndMailData?.typeDisplayName === "Special Flag 4"
    ) {
      newEmails = mailMessageCenterPagination?.items?.filter((x, idx) => {
        return x?.flagTypeId === 4;
      });
      setCurrentSelectedMailMessage([]);
    } else if (
      selectedMailTypeAndMailData?.typeDisplayName === "Special Flag 5"
    ) {
      newEmails = mailMessageCenterPagination?.items?.filter((x, idx) => {
        // indexNewMsg = idx;
        return x?.flagTypeId === 5;
      });
      setCurrentSelectedMailMessage([]);
    } else {
      return setFilteredEmails(mailMessageCenterPagination?.items);
      // return setTimeout(() => {
      //   setFilteredEmails(mailMessageCenterPagination?.items);
      // }, timeOut);
    }

    if (newEmails?.length === 0) {
      setCurrentSelectedMailMessage([]);
    }
    return setFilteredEmails(newEmails);
  }, [
    changeEmailStatusTypeRespLoading,
    selectedMailTypeAndMailData,
    mailMessageCenterPagination,
    changeEmailStatusTypeResp,
    setCurrentSelectedMailMessage,
  ]);

  const subMenuCompose = (
    <Menu style={{ minWidth: 144, minHeight: 176 }}>
      {recentlyUsedReferenceTags?.map((x) => {
        return (
          <Menu.Item
            className="sidebars__menu--item"
            onClick={() => {
              let reference =
                currentSelectedMailMessage &&
                currentSelectedMailMessage?.mailMessageReferenceDtos?.map(
                  (x) => {
                    return x?.referenceCode;
                  }
                );
              reference?.push(x?.value);
              reference = [...new Set(reference)];
              const lastApiData = reference?.map((x) => {
                return {
                  messageCenterId:
                    currentSelectedMailMessage?.instanceMainMessageCenterId,
                  referenceCode: x,
                };
              });
              dispatch(
                mailReferenceTagUpdate(lastApiData, currentSelectedMailMessage)
              );
              setRightClickIndex(null);
            }}
          >
            <div
              style={{
                display: "flex",
                width: 140,
                justifyContent: "space-around",
              }}
            >
              <div className="" style={{ width: "96%", marginLeft: "5px" }}>
                {x?.label}
              </div>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <>
      {contextMenushow && (
        <ul
          className="email-list-menu-action-wrapper"
          style={{ top: anchorPoint.y + 5, left: anchorPoint.x + 20 }}
          onClick={handleStatusClick}
        >
          {selectedMailTypeAndMailData?.typeSystemName !== "OutBox" && (
            <>
              {selectedMailTypeAndMailData?.typeDisplayName !==
                "Sent / Circular" && (
                <li data-action="Read">
                  <div className="right-click-menu-item">
                    <Image src={send} shape="square" preview={false} />
                    <p>
                      {!selectedMailTypeAndMailData?.selectedMail?.isRead
                        ? "Read"
                        : "Unread"}
                    </p>
                  </div>
                </li>
              )}

              <li data-action="Archive">
                <div className="right-click-menu-item">
                  <Image src={archieve} shape="square" preview={false} />
                  <p>
                    {!selectedMailTypeAndMailData?.selectedMail?.isArchieve
                      ? "Archive"
                      : "Unarchive"}
                  </p>
                </div>
              </li>
              {messageCenterTypeName === "Trash" && (
                <li data-action="Recover">
                  <div className="right-click-menu-item">
                    <Image src={pending} shape="square" preview={false} />
                    <p>Recover</p>
                  </div>
                </li>
              )}

              <li data-action="Junk">
                <div className="right-click-menu-item">
                  <Image src={junk} shape="square" preview={false} />
                  <p>Junk</p>
                </div>
              </li>
            </>
          )}

          <li data-action="Delete">
            <div className="right-click-menu-item">
              <Image src={trash} shape="square" preview={false} />
              <p>Delete</p>
            </div>
          </li>
          {selectedMailTypeAndMailData?.typeDisplayName === "Inbox" && (
            <>
              <li data-action="AutoReference">
                <div className="right-click-menu-item">
                  {/* <Image src={trash} shape="square" preview={false} /> */}
                  <TagOutlined />
                  <p>Auto Reference</p>
                </div>
              </li>

              <li data-action="Recent">
                <Dropdown
                  overlay={() => subMenuCompose}
                  arrow
                  placement="bottomRight"
                >
                  <div className="right-click-menu-item">
                    <TagsOutlined style={{ color: "lightgray" }} />
                    <p>Recently Used Reference</p>
                  </div>
                </Dropdown>
              </li>
            </>
          )}
        </ul>
      )}
      {/* {mailMessageCenterPagination?.items?.map((mail, index) => { */}
      {filteredEmails?.map((mail, index) => {
        const { mailMessageParticipantWithTypeDtos } =
          mail.applicationTypeDetails;
        let currentUserId = storage.getItem("userId");

        let allParticipantsLists =
          mailMessageParticipantWithTypeDtos?.tos.concat(
            mailMessageParticipantWithTypeDtos?.cCs,
            mailMessageParticipantWithTypeDtos?.bcCs
          );

        allParticipantsLists?.push(mailMessageParticipantWithTypeDtos?.from);

        let isParticipant = allParticipantsLists?.filter(
          (obj) => obj?.participantUserId === currentUserId
        );
        var x = document.getElementById(`email-list-${mail?.id}`);
        var checkedOrNot = x?.checked;
        return (
          <>
            <div
              key={index}
              className={`emailList__card ${
                selectedMailTypeAndMailData?.selectedMail?.id === mail?.id &&
                "active"
              }${" " + !!checkedOrNot && " checked"}`}
              onClick={(e) => {
                if (
                  e.target.tagName == "LABEL" ||
                  e.target.tagName == "INPUT"
                ) {
                  if (e.target.tagName === "INPUT") {
                    let input = e.target;
                    dispatch(
                      multipleEmailSelectionAction({
                        checkStatus: input.checked,
                        data: mail,
                      })
                    );
                  }
                } else {
                  setchatBox(false);
                  setShowEmailId(mail.id);
                  getBodyDetails(mail);
                  setShowSideMenuEmail(false);
                  let data = {
                    ...selectedMailTypeAndMailData,
                    selectedMail: mail,
                    index: index,
                  };
                  if (mail.id !== showEmailId) {
                    setCurrentSelectedMailMessage("");
                  }
                  dispatch(
                    selectedMailTypeAndMail(
                      data
                      // ...mail,
                      // index: index,
                      // nextData: mailMessageCenterPagination?.items[index + 1],
                    )
                  );
                }
              }}
            >
              <div
                className="emailList__info mailbox__info emailList-item"
                data-id={mail.id}
              >
                {/* <div className="emailList__icons">
								<div>
									{(mail.isSpecialFlagOne || mail.isSpecialFlagOne_InstanceMessage) && <StarFilled style={{ color: '#ffd036' }} />}
								</div>
								<div>
									{(mail.isSpecialFlagOne || mail.isSpecialFlagOne_InstanceMessage) && <StarFilled style={{ color: '#ffd036' }} />}
								</div>
								<div>
									{
										!isEmpty(mail.applicationTypeDetails?.mailMessageAttachmentDtos) &&
										<PaperClipOutlined />
									}
								</div>
								<div>
									{mail.isTheirInstanceMessage ? <MailOutlined /> : ''}
								</div>
							</div> */}
                <div className="emailList__right">
                  <div className="emailList__right--details">
                    <div className="emailList__card--smallTitle email-title-icon">
                      {/* <div className="text-left"> */}
                      <div>
                        {selectedMailTypeAndMailData?.typeSystemName !==
                          "From" &&
                          selectedMailTypeAndMailData?.typeSystemName !==
                            "Draft" &&
                          selectedMailTypeAndMailData?.typeSystemName !==
                            "OutBox" &&
                          (!mail.isRead ? (
                            <Image
                              src={unread}
                              style={{ width: 9 }}
                              preview={false}
                            />
                          ) : (
                            ""
                          ))}
                      </div>
                      {/* <div className="text-right PaginationEmail"> */}
                      <div
                        className="PaginationEmail"
                        style={{ marginLeft: mail.isRead ? "14px" : "15px" }}
                      >
                        {selectedMailTypeAndMailData?.typeSystemName !==
                          "From" &&
                        selectedMailTypeAndMailData?.typeSystemName !==
                          "Draft" &&
                        selectedMailTypeAndMailData?.typeSystemName !==
                          "OutBox" ? (
                          mail.applicationTypeDetails?.from[0]?.fullName?.trim() ? (
                            <>
                              {mail.applicationTypeDetails?.from[0]?.fullName}
                              <span
                                style={{
                                  color: "#007aff",
                                  fontWeight: "normal",
                                }}
                              >
                                {" "}
                                {mail.applicationTypeDetails?.from[0]
                                  ?.companyName ?? ""}
                              </span>
                            </>
                          ) : mail.applicationTypeDetails?.from[0]
                              ?.companyAbbrevation ? (
                            `${
                              mail.applicationTypeDetails?.from[0]
                                ?.companyAbbrevation
                            } ${
                              mail.applicationTypeDetails?.from[0]
                                ?.companyName ?? ""
                            }`
                          ) : mail.applicationTypeDetails?.from[0]
                              ?.companyName ? (
                            mail.applicationTypeDetails?.from[0]?.companyName
                          ) : (
                            mail.applicationTypeDetails?.from[0]?.emailAddress
                          )
                        ) : (
                          <>
                            {/* {mail.applicationTypeDetails?.tos
                                ?.map( (i, k) =>{
                                  return i.fullName?.trim()
                                    ? 
                                    `${i.fullName?.trim()}

                                      ${
                                        i?.companyAbbrevation
                                          ? i?.companyAbbrevation
                                          : i?.companyName ?? ""
                                      }`
                                    : i?.companyAbbrevation
                                    ? `${i?.companyAbbrevation} ${
                                        i?.companyName ?? ""
                                      }`
                                    : i?.companyName
                                    ? i?.companyName
                                    : i.emailAddress?.trim();
                                })
                                .join(",")} */}

                            {mail.applicationTypeDetails?.tos?.map((x) => (
                              <>
                                {x?.fullName
                                  ? x?.fullName?.trim()?.concat(",")
                                  : ""}{" "}
                                <span
                                  style={{
                                    color: "#007aff",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {x?.companyAbbrevation
                                    ? x?.companyAbbrevation
                                    : x?.companyName
                                    ? x?.companyName
                                    : x?.fullName
                                    ? ""
                                    : x?.emailAddress?.trim()}{" "}
                                </span>
                              </>
                            ))}
                          </>
                        )}
                      </div>

                      {/* <div className="text-right PaginationEmail">
                      {selectedMailTypeAndMailData?.typeSystemName !== "From" &&
                      selectedMailTypeAndMailData?.typeSystemName !== "Draft" &&
                      selectedMailTypeAndMailData?.typeSystemName !== "OutBox"
                        ? mail.applicationTypeDetails?.from[0]?.fullName?.trim()
                          ? mail.applicationTypeDetails?.from[0]?.fullName
                          : 
                          mail.applicationTypeDetails?.from[0]?.companyName
                          ? mail.applicationTypeDetails?.from[0]?.companyName
                          :
                          mail.applicationTypeDetails?.from[0]
                              ?.companyAbbrevation
                          ? mail.applicationTypeDetails?.from[0]
                              ?.companyAbbrevation
                          : mail.applicationTypeDetails?.from[0]?.emailAddress
                        : selectedMailTypeAndMailData?.typeDisplayName ===
                            "Sent" ||
                          selectedMailTypeAndMailData?.typeDisplayName ===
                            "Outbox" ||
                          selectedMailTypeAndMailData?.typeDisplayName ===
                            "Draft"
                        ? mail.applicationTypeDetails?.tos?.[0]?.fullName?.trim()
                          ? mail.applicationTypeDetails?.tos?.[0]?.fullName
                          : mail.applicationTypeDetails?.tos?.[0]?.companyName
                          ? mail.applicationTypeDetails?.tos?.[0]?.companyName
                          : 
                          mail.applicationTypeDetails?.tos[0]
                              ?.companyAbbrevation
                          ? mail.applicationTypeDetails?.tos[0]
                              ?.companyAbbrevation
                          : mail.applicationTypeDetails?.tos[0]?.emailAddress
                          ? mail.applicationTypeDetails?.tos[0]?.emailAddress
                          : "Anonymous"
                        : ""}
                    </div> */}

                      {/* <div className="emailList__compName">
                      {mail.applicationTypeDetails?.from[0]?.fullName?.trim()
                        ? mail.applicationTypeDetails?.from[0]
                            ?.companyAbbrevation
                          ? mail.applicationTypeDetails?.from[0]
                              ?.companyAbbrevation
                          : mail.applicationTypeDetails?.from[0]?.companyName
                          ? `${mail.applicationTypeDetails?.from[0]?.companyName?.slice(
                              0,
                              10
                            )}${
                              mail.applicationTypeDetails?.from[0]?.companyName
                                ?.length > 10
                                ? ".."
                                : ""
                            }`
                          : ""
                        : ""}
                    </div> */}
                    </div>
                    <div className="emailList__card--date">
                      {moment(mail.creationTime).format("YYYY") !==
                      moment(new Date()).format("YYYY")
                        ? moment(mail.creationTime).format("DD/MM/YYYY")
                        : moment(mail.creationTime).calendar({
                            sameDay: "h:mm a",
                            nextDay: "[Tomorrow]",
                            nextWeek: "DD MMM",
                            lastDay: "[Yesterday]",
                            lastWeek: "DD MMM",
                            sameElse: "DD MMM",
                          })}{" "}
                      {/* {isEmpty(isParticipant) && ` - R`} */}
                    </div>
                  </div>
                  <div
                    className="emailList__card--title email-title-icon"
                    title={mail.title}
                  >
                    <div className="text-left">
                      {mail.isInstanceMessage &&
                        (mail.isRead ? (
                          <Image src={chat} preview={false} />
                        ) : (
                          <Image src={isReadChat} preview={false} />
                        ))}
                    </div>
                    <div className="text-right text-title">
                      {mail?.applicationTypeSystemName === "Document"
                        ? isEmpty(mail?.mailMessageReferenceDtos)
                          ? "Shared Document"
                          : // : `Shared Document of ${currentSelectedMailMessage?.mailMessageReferenceDtos[0]?.referenceCode}`
                            mail?.subject
                        : mail.subject}
                    </div>
                  </div>
                  <div className="email-title-icon">
                    <div className="text-left">
                      {mail?.isAttachment && (
                        <Image src={attachment} preview={false} />
                      )}
                      {/* attachment */}
                    </div>
                    <div
                      style={{ height: 34 }}
                      className="emailList__card--desc text-right--description"
                      dangerouslySetInnerHTML={{
                        __html:
                          mail?.applicationTypeSystemName === "Document"
                            ? "Shared Document"
                            : mail?.bodyPlainText,
                      }}
                    ></div>
                  </div>

                  <div className="email-title-icon reference-Mail">
                    <div className="text-left">
                      {mail.flagTypeName === "FlagType1" ? (
                        <Image src={specialFlag1} />
                      ) : mail.flagTypeName === "FlagType2" ? (
                        <Image src={specialFlag2} />
                      ) : mail.flagTypeName === "FlagType3" ? (
                        <Image src={specialFlag3} />
                      ) : mail.flagTypeName === "FlagType4" ? (
                        <Image src={specialFlag4} />
                      ) : mail.flagTypeName === "FlagType5" ? (
                        <Image src={specialFlag5} />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="text-left" style={{ marginLeft: "8px" }}>
                      {mail.isSpecialFlagOne ? (
                        <Image src={star} className="star" preview={false} />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="emailList__card--refComp text-right"
                      style={{ paddingTop: 3, width: 300 }}
                    >
                      {!isEmpty(mail.mailMessageReferenceDtos) && (
                        <>{getHalfDetails(mail.mailMessageReferenceDtos)}</>
                        // {mail.mailMessageReferenceDtos.length > 3 && +0}
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    multipleSelectedEmail?.length > 0 || checkAll
                      ? " emailCardCheckBox"
                      : "email-list-checkbox-wrapper"
                  }
                  id="emailCardCheckBox"
                >
                  <input
                    type="checkbox"
                    className="email-list-checkboxes"
                    id={`email-list-${mail.id}`}
                    onChange={(e) => {
                      setEmailChecked(!emailChecked);
                    }}
                  />
                  {/* <label className="email-list-labeled" htmlFor={`email-list-${mail.id}`}></label> */}
                </div>
              </div>
            </div>
          </>
        );
      })}

      <CommonModalForRecentUsedTags
        title="Add Recent Reference Tags"
        visibility={openRecentReferenceModal}
        content="recentReferenceTags"
        setOpenRecentlyAddTags={setOpenRecentlyAddTags}
        recentlyUsedReferenceTags={recentlyUsedReferenceTags}
        currentSelectedMailMessage={currentSelectedMailMessage}
      />
      {/* <div className="emailList__card">
				<div className="emailList__info">
					<div className="emailList__card--smallTitle">
						<span className="isNotIcon"></span>DL
					</div>
					<div className="emailList__card--date">13 Nov</div>
				</div>
				<div className="emailList__card--title isSeen" title="Tally Inovice Due Amount">
					Tally Inovice Due Amount
					<Image src={chat} shape="square" />
					<Image src={star} shape="square" />
				</div>
				<div className="emailList__card--badge">
					<span className="badge badge-outline--lightred">ID-HR</span>
					<span className="badge badge-outline--normal">ICTC Private Limited</span>
					<span className="badge badge-gray">
						<Image src={link} shape="square" />
					</span>
				</div>
			</div>
			<div className="emailList__card">
				<div className="emailList__info">
					<div className="emailList__card--smallTitle">DL</div>
					<div className="emailList__card--date">13 Nov</div>
				</div>
				<div className="emailList__card--title" title="Tally Inovice Due Amount">
					Tally Inovice Due Amount
					<Image src={chat} shape="square" />
					<Image src={star} shape="square" />
				</div>
				<div className="emailList__card--badge">
					<span className="badge badge-outline--lightred">ID-HR</span>
					<span className="badge badge-outline--normal">ICTC Private Limited</span>
					<span className="badge badge-gray">
						<Image src={link} shape="square" />
					</span>
				</div>
			</div> */}
    </>
  );
};

export default EmailCard;
