import { appBaseUrl } from "utils/config";
import { getService } from "../../../commonServices";
import queryString from "query-string";

export class DocumentSectorServices {
  getSingleDocumentDetails(id, body) {
    let query = queryString.stringify(body);
    let url = `${appBaseUrl}/documentRequest/documentDetailsByFileIdGet/${id}?${query}`;
    let data = getService(url);
    return data;
  }
}
