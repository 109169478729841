import { Card, Col, Row } from "antd";

export default function OtherDetails({ otherDetails }) {
  const formatKey = (key) => {
    // Split the string by lowercase letters followed by uppercase letters
    const words = key.match(/[a-z]+|[A-Z][a-z]*/g);
    // Capitalize the first letter of each word
    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    // Join the words with a space
    return formattedWords ? formattedWords.join(" ").replace("Is", "") : key;
  };

  return (
    <>
      <Card>
        <Row className="cead-details-text">
          {Object.entries(otherDetails).map(([key, value]) => (
            <>
              {key === "clientId" ||
              key === "isActive" ||
              key === "tenderCEAD_Id" ||
              key === "principalId" ? (
                ""
              ) : (
                <>
                  <Col offset={0} span={5}>
                    {formatKey(key)}:
                  </Col>
                  <Col span={17}>
                    {key === "contractExecutionContactInformations" ||
                    key === "salesMarketingContactInformations"
                      ? value?.map((x, index) => {
                          let y = "";
                          y =
                            y +
                            x?.contactPersonName +
                            `${index + 1 === value?.length ? "" : ", "}`;

                          return y;
                        })
                      : value}
                  </Col>
                </>
              )}
            </>
          ))}
        </Row>
      </Card>
    </>
  );
}
