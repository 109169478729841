import queryString from "query-string";
import { getService, postService, putService } from "services/commonServices";
import { appBaseUrl } from "utils/config";

export class EmailShareService {
  emailShareListing(type, query) {
    let query1 = queryString.stringify(query);
    let plusquery;
    if (query1 === undefined) {
      plusquery = "";
    } else {
      plusquery = query1;
    }

    let url = (type = "corporate"
      ? `${appBaseUrl}/emailMessage/corporateEmailListing?EmailFilterType=${type}&${plusquery}`
      : `${appBaseUrl}/emailMessage/corporateEmailListing${plusquery}`);

    let data = getService(url);
    return data;
  }
  corporateEmailAdd(body) {
    let url = `${appBaseUrl}/emailMessage/corporateEmailAdd`;
    let data = postService(url, body);
    return data;
  }
  corporateEmailPermissionAdd(body, id, type) {
    let plus = type == "corporate" ? "?EmailFilterType=corporate" : "";
    let url = `${appBaseUrl}/emailMessage/corporateEmailUpdate/${id}${plus}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  emailShareGetDetails(id, type) {
    let plus = type === "corporate" ? "?EmailFilterType=corporate" : "";
    let url = `${appBaseUrl}/emailMessage/corporateEmailGetDetails/${id}${plus}`;
    let data = getService(url);
    return data;
  }
  corporateEmailShare(body, type, id) {
    const plus =
      type === "corporate" ? "?CorporateEmailId=" : "?EmployeeEmailId=";
    const url = `${appBaseUrl}/emailMessage/emailSharedAccessParticipaintAdd/${plus}${id}`;
    const data = putService(url, body);
    return data;
  }
}
