import { ADMIN_TYPES } from "../../../types";

const INITIAL_STATE = {
  fiscalYearPagination: {
    items: [],
    totalCount: 0,
  },
  fiscalYearPaginationLoading: false,

  addUpdateFiscalYearResponse: {},
  addUpdateFiscalYearResponseLoading: false,

  fiscalYearListingWithStatus: [],
  fiscalYearListingWithStatusLoading: false,

  dashboardFeeUpdateResponse: [],
  dashboardFeeUpdateLoading: false,

  feeCategoryListResponse: [],
  feeCategoryListLoading: false,

  dashboardBudgetIncomeExpenseResponse: [],
  dashboardBudgetIncomeExpenseResponseLoading: false,

  getAllUsersListResponse: [],
  getAllRolesUsersListResponse: [],
};

export default function fiscalYearReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Fiscal Year Pagination
    case ADMIN_TYPES.FISCAL_YEAR_PAGINATION_INIT:
      return {
        ...state,
        fiscalYearPagination: {
          items: [],
          totalCount: 0,
        },
        fiscalYearPaginationLoading: true,
      };
    case ADMIN_TYPES.FISCAL_YEAR_PAGINATION_SUCCESS:
      return {
        ...state,
        fiscalYearPagination: action.payload,
        fiscalYearPaginationLoading: false,
      };
    case ADMIN_TYPES.FISCAL_YEAR_PAGINATION_FINISH:
      return {
        ...state,
        fiscalYearPaginationLoading: false,
      };

    // Update Fiscal Year
    case ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE_INIT:
      console.log("actiondata", action);
      let accCat = action?.data?.accountCategory;
      let accHead = action?.data?.accountHead;
      return {
        ...state,
        addUpdateFiscalYearResponse: {},
        addUpdateFiscalYearResponseLoading:
          accCat === "Income Expense status" && accHead === "USHEC"
            ? true
            : false,
        addUpdateFiscalYearResponseNCFCBankStatusLoading:
          accCat === "NC/FC Bank Status" && accHead === "USHEC" ? true : false,
        updateFiscalYrResponseIncIcclLoading:
          accCat === "Income Expense status" && accHead === "ICCL"
            ? true
            : false,
        updateFiscalYrResponseNCFCICCLLoading:
          accCat === "NC/FC Bank Status" && accHead === "ICCL" ? true : false,
        updateFiscalYrResponseIncIsplLoading:
          accCat === "Income Expense status" && accHead === "ISPL"
            ? true
            : false,
        updateFiscalYrResponseNCFCISPLLoading:
          accCat === "NC/FC Bank Status" && accHead === "ISPL" ? true : false,
      };
    case ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE_SUCCESS:
      return {
        ...state,
        addUpdateFiscalYearResponse: action.payload,
        addUpdateFiscalYearResponseLoading: false,
        addUpdateFiscalYearResponseNCFCBankStatusLoading: false,
        updateFiscalYrResponseIncIcclLoading: false,
        updateFiscalYrResponseNCFCICCLLoading: false,
        updateFiscalYrResponseIncIsplLoading: false,
        updateFiscalYrResponseNCFCISPLLoading: false,
      };
    case ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE_FINISH:
      return {
        ...state,
        addUpdateFiscalYearResponseLoading: false,
        addUpdateFiscalYearResponseNCFCBankStatusLoading: false,
      };

    // Fiscal Year Listing With Status
    case ADMIN_TYPES.FISCAL_YEAR_LISTING_WITH_STATUS_INIT:
      return {
        ...state,
        fiscalYearListingWithStatus: [],
        fiscalYearListingWithStatusLoading: true,
      };
    case ADMIN_TYPES.FISCAL_YEAR_LISTING_WITH_STATUS_SUCCESS:
      return {
        ...state,
        fiscalYearListingWithStatus: action.payload,
        fiscalYearListingWithStatusLoading: false,
      };
    case ADMIN_TYPES.FISCAL_YEAR_LISTING_WITH_STATUS_FINISH:
      return {
        ...state,
        fiscalYearListingWithStatusLoading: false,
      };

    case ADMIN_TYPES.DASHBOARD_FINANCIAL_INFO_INIT:
      return {
        ...state,
        dashboardFinancialResponse: [],
        dashboardFinancialResponseLoading: true,
      };
    case ADMIN_TYPES.DASHBOARD_FINANCIAL_INFO_SUCCESS:
      return {
        ...state,
        dashboardFinancialResponse: action.payload,
        dashboardFinancialResponseLoading: false,
      };
    case ADMIN_TYPES.DASHBOARD_FINANCIAL_INFO_FINISH:
      return {
        ...state,
        dashboardFinancialResponseLoading: false,
      };
    case ADMIN_TYPES.BUDGET_INCOME_EXPENSE_INFO_INIT:
      return {
        ...state,
        dashboardBudgetIncomeExpenseResponse: [],
        dashboardBudgetIncomeExpenseResponseLoading: true,
      };
    case ADMIN_TYPES.BUDGET_INCOME_EXPENSE_INFO_SUCCESS:
      return {
        ...state,
        dashboardBudgetIncomeExpenseResponse: action.payload,
        dashboardBudgetIncomeExpenseResponseLoading: false,
      };
    case ADMIN_TYPES.BUDGET_INCOME_EXPENSE_INFO_FINISH:
      return {
        ...state,
        dashboardBudgetIncomeExpenseResponseLoading: false,
      };

    case ADMIN_TYPES.GET_ALL_USERS_INIT:
      return {
        ...state,
        getAllUsersListResponse: [],
        getAllUsersListResponseLoading: true,
      };
    case ADMIN_TYPES.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        getAllUsersListResponse: action.payload,
        getAllUsersListResponseLoading: false,
      };
    case ADMIN_TYPES.GET_ALL_USERS_FINISH:
      return {
        ...state,
        getAllUsersListResponseLoading: false,
      };

    // get all roles listing of users
    case ADMIN_TYPES.GET_ALL_ROLES_USERS_INIT:
      return {
        ...state,
        getAllRolesUsersListResponse: [],
        getAllRolesUsersListResponseLoading: true,
      };

    case ADMIN_TYPES.GET_ALL_ROLES_USERS_SUCCESS:
      return {
        ...state,
        getAllRolesUsersListResponse: action.payload,
        getAllRolesUsersListResponseLoading: false,
      };

    case ADMIN_TYPES.GET_ALL_ROLES_USERS_FINISH:
      return {
        ...state,
        getAllRolesUsersListResponseLoading: false,
      };

    // update all roles
    case ADMIN_TYPES.UPDATE_ALL_ROLLS_USERS_INIT:
      return {
        ...state,
        updateAllRolesUserListResponse: [],
        updateAllRolesUserListResponseLoading: true,
      };

    case ADMIN_TYPES.UPDATE_ALL_ROLLS_USERS_SUCCESS:
      const updatedRolesItem = action.payload;
      const updatedRolesItems = state.getAllRolesUsersListResponse.map(
        (item) => {
          if (item.appUserId === updatedRolesItem.appUserId) {
            return updatedRolesItem;
          } else {
            return item;
          }
        }
      );
      return {
        ...state,
        updateAllRolesUserListResponse: updatedRolesItem,
        updateAllRolesUserListResponseLoading: false,
        getAllRolesUsersListResponse: updatedRolesItems,
      };

    case ADMIN_TYPES.UPDATE_ALL_ROLLS_USERS_FINISH:
      return {
        ...state,
        updateAllRolesUserListResponseLoading: false,
      };

    case ADMIN_TYPES.ACCOUNT_BUDGET_EXPENSE_INTI:
      return {
        ...state,
        accountBudgetExpenseResponse: [],
        accountBudgetExpenseLoading: true,
      };
    case ADMIN_TYPES.ACCOUNT_BUDGET_EXPENSE_SUCCESS:
      return {
        ...state,
        accountBudgetExpenseResponse: action.payload,
        accountBudgetExpenseLoading: false,
      };
    case ADMIN_TYPES.ACCOUNT_BUDGET_EXPENSE_FINISH:
      return {
        ...state,
        accountBudgetExpenseLoading: false,
      };
    case ADMIN_TYPES.ACCOUNT_INCOME_INTI:
      return {
        ...state,
        accountIncomeResponse: [],
        accountIncomeLoading: true,
      };
    case ADMIN_TYPES.ACCOUNT_INCOME_SUCCESS:
      return {
        ...state,
        accountIncomeResponse: action.payload,
        accountIncomeLoading: false,
      };
    case ADMIN_TYPES.ACCOUNT_INCOME_FINISH:
      return {
        ...state,
        accountIncomeLoading: false,
      };

    case ADMIN_TYPES.ACCOUNT_LEDGER_INIT:
      return {
        ...state,
        accountLedgerResponse: [],
        accountLedgerLoading: true,
      }

    case ADMIN_TYPES.ACCOUNT_LEDGER_SUCCESS:
      return {
        ...state,
        accountLedgerResponse: action.payload,
        accountLedgerLoading: false,
      }

    case ADMIN_TYPES.ACCOUNT_LEDGER_FINISH:
      return {
        ...state,
        accountLedgerLoading: false,
      }

    case ADMIN_TYPES.ACCOUNT_CASHFLOW_INIT:
      return {
        ...state,
        accountCashFlowResponse: [],
        accountCashFlowLoading: true,
      }

    case ADMIN_TYPES.ACCOUNT_CASHFLOW_SUCCESS:
      return {
        ...state,
        accountCashFlowResponse: action.payload,
        accountCashFlowLoading: false,
      }

    case ADMIN_TYPES.ACCOUNT_CASHFLOW_FINISH:
      return {
        ...state,
        accountCashFlowLoading: false,
      }

    case ADMIN_TYPES.FEE_CATEGORY_LISTING_INIT:
      return {
        ...state,
        feeCategoryListResponse: [],
        feeCategoryListLoading: true,
      };
    case ADMIN_TYPES.FEE_CATEGORY_LISTING_SUCCESS:
      return {
        ...state,
        feeCategoryListResponse: action.payload,
        feeCategoryListLoading: false,
      };
    case ADMIN_TYPES.FEE_CATEGORY_LISTING_FINISH:
      return {
        ...state,
        feeCategoryListLoading: false,
      };

    case ADMIN_TYPES.DASHBOARD_FEE_UPDATE_INIT:
      return {
        ...state,
        dashboardFeeUpdateResponse: [],
        dashboardFeeUpdateLoading: true,
      };
    case ADMIN_TYPES.DASHBOARD_FEE_UPDATE_SUCCESS:
      const updatedItem = action.payload;
      const updatedItems = state.feeCategoryListResponse.items.map((item) => {
        if (
          item.financialAccountingInformationId ===
          updatedItem.financialAccountingInfomationId
        ) {
          return updatedItem;
        } else {
          return item;
        }
      });
      return {
        ...state,
        dashboardFeeUpdateResponse: updatedItem,
        updateTodoEventRespLoading: false,
        feeCategoryListResponse: {
          items: updatedItems,
          totalCount: state.feeCategoryListResponse.totalCount,
        },
      };

    case ADMIN_TYPES.DASHBOARD_FEE_UPDATE_FINISH:
      return {
        ...state,
        dashboardFeeUpdateLoading: false,
      };

    default:
      return { ...state };
  }
}
