import React from "react";
import { isEmpty } from "lodash";
import moment from "moment";

const LeaveDetails = ({ leaveDetails }) => {
  // console.log("leaveDetails", leaveDetails);
  return (
    !isEmpty(leaveDetails) && (
      <div className="task-view-wrapper">
        <div className="flex-row">
          {leaveDetails?.approveRejectRemarks ? (
            <div className="task-info-item">
              <strong>Remarks</strong>
              <p>{leaveDetails?.approveRejectRemarks}</p>
            </div>
          ) : (
            ""
          )}
          <div className="task-info-item">
            <strong>Reason</strong>
            <p>{leaveDetails?.reason}</p>
          </div>
          <div className="task-info-item">
            <strong>Progress</strong>
            <p>
              {" "}
              {
                leaveDetails?.leaveApplicationCurrentStatusDto
                  ?.statusDisplayName
              }
            </p>
          </div>
          <div className="task-info-item">
            <strong>Cretion Time</strong>
            <p>{moment(leaveDetails?.creationTime).format("YYYY-MM-DD")}</p>
          </div>
          <div className="task-info-item">
            <strong>Leave From</strong>
            <p>{moment(leaveDetails?.leaveDayFrom).format("YYYY-MM-DD")}</p>
          </div>
          <div className="task-info-item">
            <strong>Leave To</strong>
            <p>{moment(leaveDetails?.leaveDayTo).format("YYYY-MM-DD")}</p>
          </div>
          <div className="task-info-item">
            <strong>Leave Days</strong>
            <p>{leaveDetails?.leaveDays}</p>
          </div>
          <div className="task-info-item">
            <strong>Supervisior</strong>
            <p>
              {leaveDetails?.leaveApplicationSupervisorDtos?.map(
                (data, index) => {
                  return (
                    <span>
                      {`${data?.fullName}[${data?.userName}]`}
                      {leaveDetails?.leaveApplicationSupervisorDtos?.length >
                      index + 1
                        ? ","
                        : ""}
                      &nbsp;
                    </span>
                  );
                }
              )}
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export default LeaveDetails;
