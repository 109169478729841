import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Select,
  Skeleton,
  Tooltip,
  Typography,
  Table,
  message,
  Spin,
} from "antd";
import { useEffect, useState } from "react";

import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  PlusOutlined,
  PhoneOutlined,
  UserOutlined,
  CopyOutlined,
  BookOutlined,
  CloseCircleOutlined,
  FilterOutlined,
  InteractionOutlined,
  InteractionTwoTone,
  LoadingOutlined,
  MoreOutlined,
  DownloadOutlined,
  FireTwoTone,
  FileExcelTwoTone,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import { getOrganizerEventPriorityListings } from "services/redux/organizer/priorityLisiting/action";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";
import {
  getTodoEventsPagination,
  todayTasks,
  toDoMarkAsComplete,
  toDoMarkAsFlagged,
} from "../../services/redux/organizer/todoLists/action";

import _ from "lodash";
import { isEmpty } from "lodash-es";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  addToDoEvent,
  updateToDoEvent,
} from "services/redux/organizer/todoLists/action";
import { getReferenceListing } from "services/redux/Reference/action";
import { referenceSecondaryAdd } from "services/redux/Reference/Secondary/action";
import storage from "utils/storage";
import ChatDetails from "views/GlobalEmailChat";
import CreateEventDrawer from "views/Oragnizer/TaskEventDrawer/CreateEventDrawer";
import specialFlag0 from "../../assets/svg/flagSpecial0.svg";
import specialFlag1 from "../../assets/svg/flagSpecial3.svg";
import newLogIcon from "../../assets/svg/log-icon.svg";
import { getCalenderListEvents } from "services/redux/calendar/action";
import { Link } from "react-router-dom";
import DailyDairy from "./DashboardComponents/MainDashboard/DailyDairy";
import {
  actionForTransfertoDailyDairy,
  actionForGetPaginationDailyDairy,
  actionForTransfertoDailyDairyCombine,
} from "services/redux/dailyDairy/action";
import { actionForSampleDailyDairy } from "services/redux/common/downloadFile/action";
import { actionForExportDairyToExcel } from "services/redux/dailyDairy/action";
import AddUpdateDailyDairy from "./DashboardComponents/MainDashboard/DailyDairy/AddUpdate";
import { getAllUsers } from "services/remanentCalls";
import shouldDownloadExcel from "utils/shouldDownloadExcel";
import { saveBase64 } from "utils/saveBase64";
import { createProps } from "utils/conditionalProps";

const logIcon = "assets/svg/wic_332.svg";
const man = "assets/images/icons/man.png";

const TodoLists = ({ heightt }) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const [showAddTask, setShowAddTask] = useState(false);
  const [searchValueReference, setSearchValueReference] = useState("");
  const [referenceListing, setReferenceListing] = useState();
  const [taskTodayList, setTaskTodayList] = useState([]);
  const [otherTaskList, setOtherTaskList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [alltask, setAllTask] = useState(false);

  const [rotate, setRotate] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [chatHeight, setChatHeight] = useState(false);
  const [smallChatHeight, setSmallChatHeight] = useState(false);
  const [chatBox, setchatBox] = useState(true);
  const [hideInternalMessage, setHideInternalMessage] = useState(false);
  const [todoDetails, setTodoDetails] = useState([]);
  const [eventFormSelect, setEventFormSelect] = useState("");
  const [visibleEventFormDrawer, setVisibleEventFormDrawer] = useState(false);
  const [eventFilter, setEventFilter] = useState("");
  const [calls, setCalls] = useState(false);
  const [visit, setVisit] = useState(false);
  const [showTasks, setShowTasks] = useState(true);
  const [showPhones, setShowPhones] = useState(true);
  const [showVisits, setShowVisits] = useState(true);
  const [showOtherTasks, setShowOtherTasks] = useState(true);
  const [showOtherPhones, setShowOtherPhones] = useState(true);
  const [showOtherVisits, setShowOtherVisits] = useState(true);
  const [showTablularView, setShowTablularView] = useState(true);
  const [isRedirecting, setIsRedirecting] = useState(true);
  const [largeWidth, setLargeWidth] = useState(false);
  const [visitor, setVisitor] = useState(false);

  //
  const [showAdvanceSearchModal, setShowAdvanceSearchModal] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState();
  const [isSortingByTitle, setIsSortingByTitle] = useState(false);
  const [sortTypeValue, setSortTypeValue] = useState("");
  const [user, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
  //
  const [selectedAdvancedSearchDate, setSelectedAdvancedSearchDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [
    hasTodoDiaryBeenAdvancedSearched,
    setHasTodoDiaryBeenAdvancedSearched,
  ] = useState(false);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);
  const [isDownloadingSampleExcel, setIsDownloadingSampleExcel] =
    useState(false);
  const [isUserInitiatedSave, setIsUserInitiatedSave] = useState(false);
  const [isUserInitiatedSampleSave, setIsUserInitiatedSampleSave] =
    useState(false);

  const [loadingRecordId, setLoadingRecordId] = useState(null);
  const [grantedPolices, setGrantedPolices] = useState({});
  //date
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isFlippedBack, setIsFlippedBack] = useState(false);

  let participantFrom = storage.getItem("userId");
  const [form] = Form.useForm();
  const [formToDo] = Form.useForm();
  const [formDailyDiarySearch] = Form.useForm();
  const [formRemarks] = Form.useForm();
  const {
    allToDoEvents,
    todoEventsLoading,
    addToDoEventResp,
    addToDoEventRespLoading,
  } = useSelector((state) => state.toDoEvents);

  const {
    transferToDailyDairyResp,
    transferToDailyDairyLoading,
    dailyDairyPaginationResp,
    dailyDairyPaginationLoading,
    exportDailDiary,
    exportDailDiaryLoading,
  } = useSelector((state) => state.dailyDiaryReducer);

  const { exportSampleDailyDiary, exportSampleDailyDiaryLoading } = useSelector(
    (state) => state.downloadFileReducer
  );

  const { referenceSecondaryAddIsSuccess } = useSelector(
    (state) => state.secondaryRoot2
  );

  const priorityEventsState = useSelector((state) => state.priorityEvents);

  const { allEventStatusListing, eventStatusListingLoading } = useSelector(
    (state) => state.statusListing
  );
  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );

  const { todayTasksResponse, todayTasksResponseLoading } = useSelector(
    (state) => state.toDoEvents
  );

  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );
  useEffect(() => {
    if (!isEmpty(applicationConfiguration?.auth?.grantedPolicies)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
      // console.log("applicationConfiguration?.auth: ", applicationConfiguration?.auth);
      // console.log("applicationConfiguration?.auth?.grantedPolicies: ", applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  const pageSize = 10;
  const pageSizeDailyDiary = 50;
  const { organizerEventPriorityListing } = priorityEventsState;

  const getTaskList = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    forPagination = false,
    IsDashboardtask = false
  ) => {
    let appendUrl = `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}&IsDashboardtask=${IsDashboardtask}&IsToday=1&NormalOrPhoneOrVisit=0&NormalOrPhoneOrVisit=1&NormalOrPhoneOrVisit=2`;

    dispatch(getTodoEventsPagination(appendUrl, forPagination));
  };

  useEffect(() => {
    if (todoDetails?.normalOrPhoneOrVisit === 1) {
      setLargeWidth(true);
      setVisitor(false);
    } else if (todoDetails?.normalOrPhoneOrVisit === 2) {
      setLargeWidth(true);
      setVisitor(true);
    } else {
      setLargeWidth(false);
      setVisitor(false);
    }
  }, [todoDetails?.normalOrPhoneOrVisit]);

  useEffect(() => {
    if (!isEmpty(allToDoEvents)) {
      const filteredData = allToDoEvents?.items?.filter((x) => x?.isToday == 1);

      setTaskTodayList(filteredData);
      const filteredData1 = allToDoEvents?.items?.filter(
        (x) => x?.isToday === 1
      );
      setOtherTaskList(filteredData1);

      let newPage = filteredData.length / pageSize;
      setCurrentPage(newPage);
    }
  }, [allToDoEvents, todayTasksResponse?.totalCount, todayTasksResponse]);

  useEffect(() => {
    if (addToDoEventResp) {
      setShowAddTask(false);
      form.resetFields();
    }
  }, [form, addToDoEventResp]);

  useEffect(() => {
    if (!showTasks) {
      setOtherTaskList((prevList) => {
        const filteredList = prevList.filter(
          (task) => task.normalOrPhoneOrVisit !== 0
        );
        return filteredList;
      });
    }
    if (showTasks) {
      const itemsWithNormalOrPhoneOrVisit = (allToDoEvents?.items || [])
        .filter((item) => item?.normalOrPhoneOrVisit === 0)
        .filter((x) => x?.isToday === 1);
      setOtherTaskList((prevList) => [
        ...prevList,
        ...itemsWithNormalOrPhoneOrVisit,
      ]);
    }
  }, [showTasks]);

  useEffect(() => {
    if (!showPhones) {
      setOtherTaskList((prevList) => {
        const filteredList = prevList.filter(
          (task) => task.normalOrPhoneOrVisit !== 1
        );
        return filteredList;
      });
    }
    if (showPhones) {
      const itemsWithNormalOrPhoneOrVisit = (allToDoEvents?.items || [])
        .filter((item) => item?.normalOrPhoneOrVisit === 1)
        .filter((x) => x?.isToday === 1);
      setOtherTaskList((prevList) => [
        ...prevList,
        ...itemsWithNormalOrPhoneOrVisit,
      ]);
    }
  }, [showPhones]);

  useEffect(() => {
    if (!showVisits) {
      setOtherTaskList((prevList) => {
        const filteredList = prevList.filter(
          (task) => task.normalOrPhoneOrVisit !== 2
        );
        return filteredList;
      });
    }
    if (showVisits) {
      const itemsWithNormalOrPhoneOrVisit = (allToDoEvents?.items || [])
        .filter((item) => item?.normalOrPhoneOrVisit === 2)
        .filter((x) => x.isToday == 1);
      setOtherTaskList((prevList) => [
        ...prevList,
        ...itemsWithNormalOrPhoneOrVisit,
      ]);
    }
  }, [showVisits]);

  useEffect(() => {
    if (!showOtherTasks) {
      setTaskTodayList((prevList) => {
        const filteredList = prevList.filter(
          (task) => task.normalOrPhoneOrVisit !== 0
        );
        return filteredList;
      });
    }
    if (showOtherTasks) {
      const itemsWithNormalOrPhoneOrVisit = (allToDoEvents?.items || [])
        .filter((item) => item?.normalOrPhoneOrVisit === 0)
        .filter((x) => x.isToday == 1);

      setTaskTodayList((prevList) => [
        ...prevList,
        ...itemsWithNormalOrPhoneOrVisit,
      ]);
    }
  }, [showOtherTasks]);

  useEffect(() => {
    if (!showOtherPhones) {
      setTaskTodayList((prevList) => {
        const filteredList = prevList.filter(
          (task) => task.normalOrPhoneOrVisit !== 1
        );
        return filteredList;
      });
    }
    if (showOtherPhones) {
      const itemsWithNormalOrPhoneOrVisit = (allToDoEvents?.items || [])
        .filter((item) => item?.normalOrPhoneOrVisit === 1)
        .filter((x) => x.isToday === 1);

      setTaskTodayList((prevList) => [
        ...prevList,
        ...itemsWithNormalOrPhoneOrVisit,
      ]);
    }
  }, [showOtherPhones]);

  useEffect(() => {
    if (!showOtherVisits) {
      setTaskTodayList((prevList) => {
        const filteredList = prevList.filter(
          (task) => task.normalOrPhoneOrVisit !== 2
        );
        return filteredList;
      });
    }
    if (showOtherVisits) {
      const itemsWithNormalOrPhoneOrVisit = (allToDoEvents?.items || []).filter(
        (item) => item?.normalOrPhoneOrVisit === 2 && item?.isToday == 1
      );

      setTaskTodayList((prevList) => [
        ...prevList,
        ...itemsWithNormalOrPhoneOrVisit,
      ]);
    }
  }, [showOtherVisits]);

  useEffect(() => {
    if (!isEmpty(referenceListingResponse?.items)) {
      setReferenceListing(referenceListingResponse?.items);
    }
  }, [referenceListingResponse]);

  useEffect(() => {
    dispatch(getOrganizerEventPriorityListings());
    dispatch(getStatusEventListing());
  }, []);

  useEffect(() => {
    if (searchValueReference) {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ SearchKeyword: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  useEffect(() => {
    if (isUserInitiatedSave && !isEmpty(exportDailDiary)) {
      let { data, fileName, fileExtension } = exportDailDiary;
      saveBase64({
        base64Data: data,
        fileName,
        fileType: fileExtension,
      });

      setIsUserInitiatedSave(false);
    }
  }, [isUserInitiatedSave, exportDailDiary]);

  useEffect(() => {
    if (isUserInitiatedSampleSave && !isEmpty(exportSampleDailyDiary)) {
      let { data, fileName, fileExtension } = exportSampleDailyDiary;
      saveBase64({
        base64Data: data,
        fileName,
        fileType: fileExtension,
      });

      setIsUserInitiatedSampleSave(false);
    }
  }, [isUserInitiatedSampleSave, exportSampleDailyDiary]);

  const fetchMoreData = () => {
    getTaskList(pageSize, currentPage * pageSize, true);
  };

  const onFinish = async (values) => {
    let newValues = {
      startDate: moment(values.startDate),
      privateUserId: participantFrom,
      organizerEventLabelId: "5ed65d11-e8e4-4ca9-a35f-14dbaa6509a6",
      normalOrPhoneOrVisit: calls ? 1 : visit ? 2 : 0,
      isToday: 1,
      ...values,
    };
    await dispatch(addToDoEvent(newValues, undefined, "today"));
    // await fetchMoreData();
    await setCalls(false);
    await setVisit(false);
  };

  const [showModalForTaskDetail, setShowModalForTasKDetail] = useState(false);
  const [isDailyDairyModalOpen, setIsDailyDairyModalOpen] = useState(false);
  const [selectedRecordData, setSelectedRecordData] = useState([]);
  const [selectedTodayTaskRecord, setSelectedTodayTaskRecord] = useState(null);
  const [taskId, setTaskId] = useState();
  const handleCancel = () => {
    setShowModalForTasKDetail(false);
  };

  const handleAddUpdateDairy = () => {
    setIsDailyDairyModalOpen(false);
  };

  const handleRemarksOpen = () => {
    setIsRemarksModalOpen(false);
    formRemarks.resetFields();
  };

  // added getData here
  const getDailyDairyData = (
    MaxResultCount = pageSizeDailyDiary,
    SkipCount = 0,
    Sorting,
    ForPagination = false
    // UserId,
    // DailyDiaryDate,
  ) => {
    dispatch(
      actionForGetPaginationDailyDairy(
        {
          MaxResultCount,
          SkipCount,
          Sorting,
          SearchKeyword: inputSearchValue,
          // SortType: sortTypeValue,
          ...(sortTypeValue && { SortType: sortTypeValue }),
          ...(selectedUserId && { UserId: selectedUserId }),
          ...(selectedDate && { DailyDiaryDate: selectedDate }),
          ...(startDate && { DailyDiaryDateFrom: startDate }),
          ...(endDate && { DailyDiaryDateTo: endDate }),
        },
        ForPagination
      )
    );
  };

  useEffect(() => {
    getDailyDairyData();
  }, [inputSearchValue, showResetButton]);

  const handleSearchDailyDiary = (value) => {
    setInputSearchValue(value);
  };

  const handleCancelAdvancedSearch = () => {
    setShowAdvanceSearchModal(false);
  };

  const onAdvanSearchFinish = (values) => {
    let sorting = isSortingByTitle ? "title" : null;

    setHasTodoDiaryBeenAdvancedSearched(true);
    setShowAdvanceSearchModal(false);
    setShowResetButton(true);
    setSelectedAdvancedSearchDate(selectedDate);
    setSelectedDate(null);
    getDailyDairyData(pageSizeDailyDiary, 0, sorting, false);
  };

  const handleResetAdvancedSearch = () => {
    let todayDate = moment().format("YYYY-MM-DD");
    formDailyDiarySearch.resetFields(null);
    setShowResetButton(false);
    setSelectedUserId(null);
    setSelectedUserName(null);
    setSortTypeValue(null);
    setSelectedDate(moment().format("YYYY-MM-DD"));
    setStartDate(null);
    setEndDate(null);
    setSelectedAdvancedSearchDate(todayDate);
    setHasTodoDiaryBeenAdvancedSearched(false);
  };

  const handleSortingByTitle = (e) => {
    setIsSortingByTitle(e);
  };

  useEffect(() => {
    form.setFieldsValue({
      startDate: moment(),
      dueDate: alltask ? moment().add(1, "day") : moment(),
    });
  }, [showAddTask]);

  // useEffect(() => {
  //   dispatch(actionForGetPaginationDailyDairy({
  //     MaxResultCount: 50
  //   }));
  // }, [])

  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 18,
    },
  };

  const getData = () => {
    let appendUrl = `skipCount=0${eventFilter ? eventFilter : ""}`;
    dispatch(getCalenderListEvents(appendUrl, "Dashboard"));
  };

  const handleFinishFormRemarks = (values) => {
    // console.log("FORM REMARKS", values);
    let payloadRemarks = {
      todoEventId: selectedTodayTaskRecord.id,
      remarks: values.remarks,
    };

    try {
      if (selectedTodayTaskRecord?.id) {
        dispatch(
          actionForTransfertoDailyDairyCombine(
            selectedTodayTaskRecord.id,
            null,
            payloadRemarks
          )
        );
      }
      setIsRemarksModalOpen(false);
      formRemarks.resetFields();
      setSelectedTodayTaskRecord(null);
    } catch (err) {
      console.log("error: ", err);
    }
  };

  const handleMovetoDiary = (record) => {
    setLoadingRecordId(record.id);

    let stausNotCompleted = record?.todoEventStatusDto?.name !== "Completed";

    if (stausNotCompleted) {
      setIsRemarksModalOpen(true);
      setSelectedTodayTaskRecord(record);
    } else if (!stausNotCompleted) {
      console.log("DO not open modal");
      dispatch(actionForTransfertoDailyDairy(record.id));
    }

    // if (record?.id) {
    //   dispatch(actionForTransfertoDailyDairy(record.id));
    // }
  };

  const todayTaskListPagination = () => {
    return (
      <>
        {todoEventsLoading ? (
          // <Skeleton paragraph={{ rows: 10 }} />
          <></>
        ) : taskTodayList?.length > 0 ? (
          <ul className="dashboards__todolists">
            {taskTodayList?.map((todo, i) => (
              <li key={i} style={{ padding: "0px" }}>
                <div style={{ marginRight: "10px", width: "15px" }}>
                  {i + 1}
                </div>
                <Tooltip title="Have to finish Today">
                  <div
                    onClick={() => dispatch(toDoMarkAsFlagged(todo.id))}
                    className="mark-it-flagged"
                    style={{ marginRight: "7px" }}
                  >
                    {todo.isFlagged ? (
                      <Image src={specialFlag1} preview={false} />
                    ) : (
                      <Image src={specialFlag0} preview={false} />
                    )}
                  </div>
                </Tooltip>

                <div
                  onClick={() => dispatch(toDoMarkAsComplete(todo.id))}
                  className="mark-it-off"
                  style={{ marginRight: 6 }}
                >
                  {todo.todoEventStatusDto?.name === "Completed" ? (
                    <img src="./assets/images/icons/check.png" alt="complete" />
                  ) : (
                    <img
                      src="./assets/images/icons/incomplete.png"
                      alt="incomplete"
                    />
                  )}
                  {todo?.normalOrPhoneOrVisit === 1 ? (
                    <>
                      <PhoneOutlined
                        style={{ transform: "scaleX(-1)", marginLeft: "5px" }}
                      />
                    </>
                  ) : todo?.normalOrPhoneOrVisit === 2 ? (
                    <>
                      <UserOutlined style={{ marginLeft: "5px" }} />
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div style={{ flexBasis: "45%", marginRight: "auto" }}>
                  {!todoEventsLoading &&
                  todo.todoEventStatusDto?.name === "Completed" ? (
                    <span
                      style={{
                        textDecoration: "line-through",
                        color:
                          todo?.organizerEventPriorityDto?.colorHexValue ??
                          "white",
                      }}
                      className="dashboards__todolists--div"
                    >
                      {/* {console.log("xxxtodoxxx : ", todo)} */}
                      {todo.subject}
                    </span>
                  ) : (
                    <span
                      className="dashboards__todolists--div"
                      style={{
                        color:
                          todo?.organizerEventPriorityDto?.colorHexValue ??
                          "white",
                      }}
                      title={todo.subject}
                    >
                      {todo.subject}
                    </span>
                  )}
                </div>

                <span
                  className="badge"
                  style={{
                    color: "white",
                    backgroundColor: "grey",
                    border: 0,
                    textAlign: "center",
                    fontSize: "10px",
                    padding: "1px 5px 1px 5px",
                  }}
                >
                  {todo?.privateUser?.userName?.toUpperCase()}
                </span>

                {todo?.todoEventCandidateDtos?.map((assigneDetail, index) => {
                  if (assigneDetail?.isPrimary) {
                    return assigneDetail.profilePicture ? (
                      <Tooltip
                        title={todo?.todoEventCandidateDtos
                          ?.map((x) => {
                            return x?.isPrimary ? x?.candidateName : "";
                          })
                          ?.join(",")}
                        placement="topLeft"
                      >
                        <span
                          className="badge"
                          style={{
                            color: "white",
                            backgroundColor: index <= 2 ? "brown" : "",
                            border: 0,
                            textAlign: "center",
                            fontSize: "10px",
                            padding: "1px 5px 1px 5px",
                            display: index >= 3 ? "none" : "",
                          }}
                        >
                          {/* {assigneDetail?.userName?.toUpperCase()} */}
                          {index <= 1 && assigneDetail?.userName?.toUpperCase()}
                          {index === 2 &&
                            todo?.todoEventCandidateDtos?.length > 2 &&
                            "+"}
                        </span>
                      </Tooltip>
                    ) : // </div>
                    null;
                  }
                })}

                <div className="singel-test21-wrapper">
                  <Tooltip
                    title={todo?.referenceCode}
                    style={{ cursor: "pointer" }}
                  >
                    {todo?.referenceCode == null ? (
                      ""
                    ) : (
                      <div className="dashboards__todolists--reference">
                        <span>{todo?.referenceCode?.slice(0, 10)}</span>{" "}
                      </div>
                    )}
                  </Tooltip>
                </div>

                <div
                  className="mark-today"
                  onClick={async () => {
                    const date = moment().format("YYYY-MM-DD");
                    let newValues = {
                      dueDate: date,
                      startDate: moment(todo?.startDate).format("YYYY-MM-DD"),
                      notes: todo?.notes,
                      organizerEventLabelId: todo?.organizerEventLabelId,
                      organizerEventPriorityId: todo?.organizerEventPriorityId,
                      privateUserId: todo?.privateUserId,
                      referenceId: todo?.referenceId,
                      subject: todo?.subject,
                      todoEventCandidateDtos: todo?.todoEventCandidateDtos,
                      todoEventStatusId: todo?.todoEventStatusId,
                      normalOrPhoneOrVisit: todo?.normalOrPhoneOrVisit,
                      isFlagged: todo?.isFlagged,
                      isToday: todo?.isToday,
                    };
                    await dispatch(updateToDoEvent(newValues, todo?.id));
                    // await dispatch(getTodoEventsPagination());
                    // await fetchMoreData();
                    // const body = {
                    //   SearchDateFrom: date,
                    //   isCompleted: 0,
                    //   MaxResultCount: 50,
                    // };
                    // dispatch(todayTasks(body));
                  }}
                  style={{ marginRight: 6 }}
                >
                  <img
                    src="./assets/svg/toggle-arrow.svg"
                    height={15}
                    alt="complete"
                  />
                </div>
                <div
                  className="task-view-btn"
                  onClick={() => {
                    setShowModalForTasKDetail(true);
                    setTaskId(todo.id);
                    setTodoDetails(todo);
                    setHideInternalMessage(true);
                    setSmallChatHeight(false);
                    setChatHeight(false);
                    setchatBox(false);
                  }}
                >
                  {/* <Link
                    to={`/todoLists?task=${todo.id}`}
                    className="tender-view-btn"
                  > */}
                  <EyeOutlined />
                  {/* </Link> */}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="dashboards__todolists leave_absent_listss">
            <Image className="data-no-img" src={man} />
            <span>There are no Absent List.</span>
          </div>
        )}
      </>
    );
  };

  let searchKeywordFormat = searchValueReference?.split("-");
  let searchKeywordFormatData = "";

  if (searchKeywordFormat?.length === 2) {
    searchKeywordFormatData = `${_.startCase(
      searchKeywordFormat?.[0]
    )}-${_.startCase(searchKeywordFormat?.[1].trim())}`;
  }

  useEffect(() => {
    if (referenceSecondaryAddIsSuccess === true) {
      // const timeoutId = setTimeout(() => {
      // dispatch(getReferenceListing({ SearchKeyword: searchKeywordFormatData }));
      // }, 200);
      setTimeout(() => {
        dispatch(
          getReferenceListing({ SearchKeyword: searchKeywordFormatData })
        );
      }, 200);
    }
  }, [referenceSecondaryAddIsSuccess]);

  useEffect(() => {
    const idRef = referenceListing?.find((x) => {
      return x?.code == searchKeywordFormatData;
    });
    form.setFieldsValue({
      referenceId: idRef?.id,
    });
  }, [referenceListing]);

  const handleChangeEvent = (value) => {
    if (value) {
      setEventFormSelect(value);
      setShowModalForTasKDetail(false);
      setHideInternalMessage(false);
      setShowChat(false);
      setVisibleEventFormDrawer(true);
    }
  };

  const onCloseEventFormDrawer = () => {
    setVisibleEventFormDrawer(false);
  };

  const menudailytask = (record) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          // console.log("clicked", record);
          dispatch(
            actionForTransfertoDailyDairy(record?.id, {
              isYesterdayTransfer: true,
            })
          );
        }}
      >
        Yestarday Task
      </Menu.Item>
      {/* <Menu.Item key="2">
        <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
          2nd menu item
        </a>
      </Menu.Item> */}
    </Menu>
  );

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setShowTasks(!showTasks);
        }}
      >
        <Checkbox checked={showTasks} /> Task
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setShowPhones(!showPhones);
        }}
      >
        <Checkbox checked={showPhones} /> Phones
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setShowVisits(!showVisits);
        }}
      >
        <Checkbox checked={showVisits} /> Visits
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          onClick={() => {
            setShowTablularView(!showTablularView);
          }}
        >
          Old View
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  const menu1 = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setShowOtherTasks(!showOtherTasks);
        }}
      >
        <Checkbox checked={showOtherTasks} /> Task
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setShowOtherPhones(!showOtherPhones);
        }}
      >
        <Checkbox checked={showOtherPhones} /> Phones
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setShowOtherVisits(!showOtherVisits);
        }}
      >
        <Checkbox checked={showOtherVisits} /> Visits
      </Menu.Item>
    </Menu>
  );

  // added
  const columns = [
    {
      title: "S.N",
      key: "sn",
      width: "5%",
      fixed: "left",
      render: (text, row, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            Tasks
          </span>
        </div>
      ),
      className: "dashboard_task-daily",
      key: "tasks",
      width: "40%",
      fixed: "left",
      // sorter: true,
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (record) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="mark-today"
              onClick={async () => {
                const date = moment().format("YYYY-MM-DD");
                let newValues = {
                  dueDate: null,
                  startDate: moment(record?.startDate).format("YYYY-MM-DD"),
                  notes: record?.notes,
                  organizerEventLabelId: record?.organizerEventLabelId,
                  organizerEventPriorityId: record?.organizerEventPriorityId,
                  privateUserId: record?.privateUserId,
                  referenceId: record?.referenceId,
                  subject: record?.subject,
                  todoEventCandidateDtos: record?.todoEventCandidateDtos,
                  todoEventStatusId: record?.todoEventStatusId,
                  normalOrPhoneOrVisit: record?.normalOrPhoneOrVisit,
                  isFlagged: record?.isFlagged,
                  isToday: record?.isToday === 0 ? 1 : 0,
                };
                await dispatch(updateToDoEvent(newValues, record?.id));
              }}
              style={{ marginRight: 6 }}
            >
              <img
                src="./assets/svg/toggle-arrow.svg"
                height={15}
                style={{ marginBottom: 5 }}
                alt="complete"
              />
            </div>
            <Tooltip title="Have to finish Today">
              <div
                onClick={() => dispatch(toDoMarkAsFlagged(record.id))}
                className="mark-it-flagged"
                style={{ marginRight: "7px" }}
              >
                {record?.isFlagged ? (
                  <Image
                    src={specialFlag1}
                    preview={false}
                    style={{ height: "20px", width: "20px" }}
                  />
                ) : (
                  <Image
                    src={specialFlag0}
                    preview={false}
                    style={{ height: "20px", width: "20px" }}
                  />
                )}
              </div>
            </Tooltip>
            {/* <Tooltip title="Move to Daily Diary">
              <BookOutlined
                onClick={() => handleMovetoDiary(record)}
              />
            </Tooltip> */}
            <div
              onClick={() => {
                dispatch(toDoMarkAsComplete(record.id));
                // console.log("Xxx")
              }}
              className="mark-it-off"
              style={{ marginRight: 6, display: "flex", alignItems: "center" }}
            >
              {record?.todoEventStatusDto?.name === "Completed" ? (
                <img src="./assets/images/icons/check.png" alt="complete" />
              ) : (
                <img
                  src="./assets/images/icons/incomplete.png"
                  alt="incomplete"
                />
              )}
              {record?.normalOrPhoneOrVisit === 1 ? (
                <>
                  <PhoneOutlined
                    style={{
                      transform: "scaleX(-1)",
                      marginLeft: "5px",
                    }}
                  />
                </>
              ) : record?.normalOrPhoneOrVisit === 2 ? (
                <>
                  <UserOutlined style={{ marginLeft: "5px" }} />
                </>
              ) : (
                ""
              )}
            </div>
            <div style={{ flexBasis: "100%", marginRight: "auto" }}>
              {!todayTasksResponseLoading &&
              record?.todoEventStatusDto?.name === "Completed" ? (
                <span
                  style={{
                    textDecoration: "line-through",
                    color:
                      record?.organizerEventPriorityDto?.colorHexValue ??
                      "white",
                  }}
                  className="dashboards__todolists--div text-one-line"
                >
                  {/* {console.log("record: " , record)} */}
                  {record.subject}
                </span>
              ) : (
                <span
                  className="dashboards__todolists--div text-one-line"
                  title={record.subject}
                  style={{
                    color:
                      record?.organizerEventPriorityDto?.colorHexValue ??
                      "white",
                    fontWeight: 600,
                  }}
                >
                  {/* {console.log("record: " , record)} */}
                  {record.subject}
                </span>
              )}
            </div>
          </div>
        );
      },
    },
    {
      // title: "Ref",
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            Ref
          </span>
        </div>
      ),
      className: "dashboard_task-daily",
      key: "red",
      width: "10%",
      sorter: (a, b) => a.referenceCode.localeCompare(b.referenceCode),
      render: (record) => {
        return (
          <>
            <div
              // className="dashboards__todolists--reference"
              className="text-one-line"
              title={record.referenceCode}
              style={{
                // display: "inline-flex",
                maxWidth: "50px",
                color: "white",
                backgroundColor: "#708ff5",
                border: "0px",
                textAlign: "center",
                fontSize: "10px",
                padding: "1px 5px",
                borderRadius: "5px",
              }}
            >
              <span>{record.referenceCode}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Creator (From)",
      key: "creator",
      width: "10%",
      render: (record) => {
        return (
          <>
            <span
              className="badge text-one-line"
              title={record?.privateUser?.userName?.toUpperCase()}
              style={{
                maxWidth: "50px",
                color: "white",
                backgroundColor: "grey",
                border: 0,
                textAlign: "center",
                fontSize: "10px",
                padding: "1px 5px 1px 5px",
              }}
            >
              {record?.privateUser?.userName?.toUpperCase()}
            </span>
          </>
        );
      },
    },
    {
      title: "Assigned (To)",
      key: "assign",
      width: "10%",
      render: (record) => {
        return (
          <>
            {record?.todoEventCandidateDtos?.map((assigneDetail, index) => {
              if (assigneDetail?.isPrimary) {
                return assigneDetail.profilePicture ? (
                  <Tooltip
                    title={record?.todoEventCandidateDtos
                      ?.map((x) => {
                        return x?.isPrimary ? x?.candidateName : "";
                      })
                      ?.join(",")}
                    placement="topLeft"
                  >
                    <span
                      className="badge"
                      style={{
                        maxWidth: "50px",
                        color: "white",
                        backgroundColor: index <= 2 ? "brown" : "",
                        border: 0,
                        textAlign: "center",
                        fontSize: "10px",
                        padding: "1px 5px 1px 5px",
                        // display: index >= 3 ? "none" : "",
                        display: index >= 3 ? "none" : "inline-block",
                      }}
                    >
                      {/* {assigneDetail?.userName?.toUpperCase()} */}
                      {/* {index <= 1 && assigneDetail?.userName?.toUpperCase()}
                      {index === 2 &&
                        record?.todoEventCandidateDtos?.length > 2 &&
                        "+"} */}
                      {index <= 0 && assigneDetail?.userName?.toUpperCase()}
                      {index === 1 &&
                        record?.todoEventCandidateDtos?.length > 1 &&
                        "+"}
                    </span>
                  </Tooltip>
                ) : // </div>
                null;
              }
            })}
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            Created Date
          </span>
        </div>
      ),
      className: "dashboard_task-daily",
      key: "created",
      width: "15%",
      sorter: (a, b) => moment(a.creationTime).diff(moment(b.creationTime)),
      // width: 150,
      // width: "calc(10% - 150px)",
      render: (record) => {
        const recordStartDate = record?.startDate;
        if (recordStartDate) {
          const startDate = moment(recordStartDate);
          const today = moment();
          const formattedStartDate = startDate.format("YYYY-MM-DD");
          const daysDifference = today.diff(startDate, "days");
          const title = `${formattedStartDate} (${daysDifference}d)`;
          return (
            <div
              className="text-one-line"
              title={title}
              style={{
                // color: "rgb(23, 132, 206)",
                color:
                  record?.organizerEventPriorityDto?.colorHexValue ?? "white",
                fontWeight: "600",
              }}
            >
              {formattedStartDate}
              {` (${daysDifference}d)`}
            </div>
          );
        } else {
          return null;
        }
        // return (
        //   <>
        //     {record?.startDate
        //       ? moment(record?.startDate).format("YYYY-MM-DD")
        //       : null}
        //   </>
        // );
      },
    },
    {
      title: "Transfer Date",
      key: "primaryDailyDiaryTransferedDate",
      width: "2%",
      render: (record) => {
        const primaryDailyDiaryTransferedDate =
          record?.primaryDailyDiaryTransferedDate ?? "";
        const formattedDate =
          primaryDailyDiaryTransferedDate &&
          primaryDailyDiaryTransferedDate !== "0001-01-01T00:00:00"
            ? moment(primaryDailyDiaryTransferedDate).format("YYYY-MM-DD")
            : "";
        return (
          <div
            className="text-one-line"
            title={formattedDate}
            style={{
              display: "block",
            }}
            // style={{
            //   color:
            //     record?.organizerEventPriorityDto?.colorHexValue ?? "white",
            //   fontWeight: "600",
            // }}
          >
            {formattedDate}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "8%",
      fixed: "right",
      render: (record) => {
        return (
          <>
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                className="task-view-btn"
                onClick={() => {
                  setShowModalForTasKDetail(true);
                  setTaskId(record.id);
                  setTodoDetails(record);
                  setHideInternalMessage(true);
                  setSmallChatHeight(false);
                  setChatHeight(false);
                  setchatBox(false);
                }}
              >
                {/* <Link
                    to={`/todoLists?task=${task.id}`}
                    className="tender-view-btn"
                  > */}
                <EyeOutlined />
                {/* </Link> */}
              </div>

              <Tooltip
                title={
                  record?.todoTransferToDailyTask?.status
                    ? "Already moved"
                    : "Move to Daily Diary"
                }
              >
                {record?.todoTransferToDailyTask?.status ? (
                  <InteractionTwoTone
                    className="move-to-dailydairy"
                    // onClick={() => {
                    //   handleMovetoDiary(record);
                    //   console.log("record: ", record?.todoEventStatusDto?.name === "Completed");
                    // }}
                  />
                ) : (
                  <InteractionOutlined
                    className="move-to-dailydairy"
                    onClick={() => {
                      handleMovetoDiary(record);
                    }}
                  />
                )}
              </Tooltip>

              {/* <Dropdown
                overlay={menudailytask(record)}
              >
                <MoreOutlined
                  className="move-to-dailydairy"
                />
              </Dropdown> */}
            </div>
          </>
        );
      },
    },
  ];

  const DaysCounter = ({ date }) => {
    const startDate = moment(date);
    const today = moment();
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const daysDifference = today.diff(startDate, "days");
    const title = `${formattedStartDate} (${daysDifference}d)`;

    return (
      <>
        <span> ({daysDifference}d)</span>
      </>
    );
  };

  const handleDownloadTodoExcel = async () => {
    // console.log("Downloading ...");
    setIsDownloadingExcel(true);
    setIsUserInitiatedSave(true);
    try {
      let props = createProps({
        SortType: sortTypeValue,
        UserId: selectedUserId,
        DailyDiaryDate: selectedDate,
        DailyDiaryDateFrom: startDate,
        DailyDiaryDateTo: endDate,
      });
      await dispatch(actionForExportDairyToExcel(props));
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsDownloadingExcel(false);
    }
  };

  const handleDownloadSampleDairy = async () => {
    // console.log("Downloading...");
    setIsDownloadingSampleExcel(true);
    setIsUserInitiatedSampleSave(true);
    try {
      await dispatch(actionForSampleDailyDairy());
    } catch (err) {
      console.log("err: ", err);
    } finally {
      setIsDownloadingSampleExcel(false);
    }
  };

  const exxxcelItems = dailyDairyPaginationResp?.items || [];
  const canDownloadExcel = shouldDownloadExcel(
    hasTodoDiaryBeenAdvancedSearched,
    exxxcelItems
  );

  const buttonProps = {
    // type: "primary",
    disabled: !canDownloadExcel,
    icon: <DownloadOutlined />,
    loading: isDownloadingExcel,
    onClick: handleDownloadTodoExcel,
  };

  const sampleButtonProps = {
    icon: <FileExcelTwoTone />,
    loading: isDownloadingSampleExcel,
    onClick: handleDownloadSampleDairy,
  };

  return (
    <div className="flip-card" style={{ height: heightt[0] }}>
      <div
        className="flip-card-inner"
        style={{ transform: `rotateY(${rotate * 180}deg)` }}
      >
        <div
          className="flip-card-front"
          // style={{ position: "relative" }}
        >
          <div
            className="dashboards__todolist dashboards__body"
            style={{ height: heightt[0] }}
          >
            {/* <div className="dashboards__body--main" style={{ height: "80%" }}> */}
            <div
              className="dashboards__bodyWrap"
              style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
            >
              <h4
                className="dashboards__body--title"
                // onClick={() => {
                //   setRotate(rotate + 1);
                //   setAllTask(!alltask);
                // }}
              >
                {/* Today's Tasks({!todoEventsLoading ? otherTaskList?.length : ""}) */}
                Daily Diary({!todoEventsLoading ? otherTaskList?.length : ""})
                <span
                  onClick={() => {
                    // setRotate(rotate + 1);
                    // setAllTask(!alltask);
                  }}
                  style={{
                    marginLeft: "10px",
                    height: "30px",
                    marginBottom: "10px",
                  }}
                >
                  <Link to={`/todoLists?isRedirecting=${isRedirecting}`}>
                    <CopyOutlined />
                  </Link>
                </span>
                {/* ADDED */}
                <span
                  onClick={() => {
                    setRotate(rotate + 1);
                    setIsFlippedBack((prev) => !prev);
                  }}
                  style={{
                    marginLeft: "10px",
                    height: "30px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    className="dailydairy-281289"
                    // src="./assets/svg/toggle-arrow.svg"
                    src={newLogIcon}
                    // height={15}
                    height={18}
                    alt="complete"
                  />
                </span>
              </h4>

              <Input.Search
                className="form__group_email_search"
                style={{
                  margin: "0 auto",
                  width: "90%",
                  border: "1px solid gray",
                }}
                placeholder="Task Search"
                size="medium"
                allowClear
                bordered={true}
                onSearch={(e) => {
                  // setSearchKeyword(e);
                  //should search from here
                  const body = `&searchKeyword=${e}&isCompleted=0&MaxResultCount=50&IsToday=1`;
                  dispatch(getTodoEventsPagination(body));
                }}
                autoComplete="off"
              />

              <div
                className="goal-action-wrapper"
                style={{ marginLeft: "auto" }}
              >
                <button
                  onClick={() => {
                    setShowAddTask(true);
                    // setAllTask(false);
                  }}
                  style={{ cursor: "pointer", marginBottom: "4px" }}
                >
                  <PlusOutlined
                    style={{
                      fontSize: "10px",
                      color: "#000",
                      verticalAlign: "revert",
                    }}
                  />
                  Add Task
                </button>
                <Dropdown.Button
                  overlay={menu}
                  className="new_menu"
                  style={{
                    marginBottom: "5px",
                    width: "20px",
                    border: "none",
                    marginLeft: "2px",
                  }}
                />
              </div>
            </div>

            <div className="dashboards__body--main todolist-wrapper">
              <ul
                className="dashboards__todolists"
                // style={{ height: heightt[1] }}
                style={{
                  height: heightt[1],
                  position: !isFlippedBack ? "relative" : "static",
                  zIndex: !isFlippedBack ? 2 : "auto",
                }}
              >
                {/* showTablularView */}
                {todoEventsLoading ? (
                  <>
                    <Skeleton paragraph={{ rows: 10 }} />{" "}
                  </>
                ) : showTablularView ? (
                  <>
                    <Table
                      columns={columns}
                      dataSource={otherTaskList}
                      pagination={false}
                      scroll={{ x: "max-content", y: 520 }}
                    />
                  </>
                ) : otherTaskList?.length > 0 ? (
                  <>
                    {otherTaskList?.map((task, i) => (
                      <li key={i} style={{ padding: "0px" }}>
                        <div style={{ marginRight: "10px", width: "15px" }}>
                          {i + 1}
                        </div>

                        <Tooltip title="Have to finish Today">
                          <div
                            onClick={() => dispatch(toDoMarkAsFlagged(task.id))}
                            className="mark-it-flagged"
                            style={{ marginRight: "7px" }}
                          >
                            {task?.isFlagged ? (
                              <Image
                                src={specialFlag1}
                                preview={false}
                                style={{ height: "20px", width: "20px" }}
                              />
                            ) : (
                              <Image
                                src={specialFlag0}
                                preview={false}
                                style={{ height: "20px", width: "20px" }}
                              />
                            )}
                          </div>
                        </Tooltip>

                        <div
                          onClick={() => dispatch(toDoMarkAsComplete(task.id))}
                          className="mark-it-off"
                          style={{ marginRight: 6 }}
                        >
                          {task?.todoEventStatusDto?.name === "Completed" ? (
                            <img
                              src="./assets/images/icons/check.png"
                              alt="complete"
                            />
                          ) : (
                            <img
                              src="./assets/images/icons/incomplete.png"
                              alt="incomplete"
                            />
                          )}
                          {task?.normalOrPhoneOrVisit === 1 ? (
                            <>
                              <PhoneOutlined
                                style={{
                                  transform: "scaleX(-1)",
                                  marginLeft: "5px",
                                }}
                              />
                            </>
                          ) : task?.normalOrPhoneOrVisit === 2 ? (
                            <>
                              <UserOutlined style={{ marginLeft: "5px" }} />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div style={{ flexBasis: "45%", marginRight: "auto" }}>
                          {!todayTasksResponseLoading &&
                          task?.todoEventStatusDto?.name === "Completed" ? (
                            <span
                              style={{
                                textDecoration: "line-through",
                                color:
                                  task?.organizerEventPriorityDto
                                    ?.colorHexValue ?? "white",
                              }}
                              className="dashboards__todolists--div"
                            >
                              {task.subject}
                            </span>
                          ) : (
                            <span
                              className="dashboards__todolists--div"
                              title={task.subject}
                              style={{
                                color:
                                  task?.organizerEventPriorityDto
                                    ?.colorHexValue ?? "white",
                              }}
                            >
                              {task.subject}
                              <DaysCounter date={task?.startDate} />
                            </span>
                          )}
                        </div>
                        <span
                          className="badge"
                          style={{
                            color: "white",
                            backgroundColor: "grey",
                            border: 0,
                            textAlign: "center",
                            fontSize: "10px",
                            padding: "1px 5px 1px 5px",
                          }}
                        >
                          {task?.privateUser?.userName?.toUpperCase()}
                        </span>

                        {task?.todoEventCandidateDtos?.map(
                          (assigneDetail, index) => {
                            if (assigneDetail?.isPrimary) {
                              return assigneDetail.profilePicture ? (
                                <Tooltip
                                  title={task?.todoEventCandidateDtos
                                    ?.map((x) => {
                                      return x?.isPrimary
                                        ? x?.candidateName
                                        : "";
                                    })
                                    ?.join(",")}
                                  placement="topLeft"
                                >
                                  <span
                                    className="badge"
                                    style={{
                                      color: "white",
                                      backgroundColor:
                                        index <= 2 ? "brown" : "",
                                      border: 0,
                                      textAlign: "center",
                                      fontSize: "10px",
                                      padding: "1px 5px 1px 5px",
                                      display: index >= 3 ? "none" : "",
                                    }}
                                  >
                                    {/* {assigneDetail?.userName?.toUpperCase()} */}
                                    {index <= 1 &&
                                      assigneDetail?.userName?.toUpperCase()}
                                    {index === 2 &&
                                      task?.todoEventCandidateDtos?.length >
                                        2 &&
                                      "+"}
                                  </span>
                                </Tooltip>
                              ) : // </div>
                              null;
                            }
                          }
                        )}
                        <div className="dashboards__todolists--reference">
                          <span>{task.referenceCode}</span>
                        </div>

                        <div
                          className="mark-today"
                          onClick={async () => {
                            const date = moment().format("YYYY-MM-DD");
                            let newValues = {
                              dueDate: null,
                              startDate: moment(task?.startDate).format(
                                "YYYY-MM-DD"
                              ),
                              notes: task?.notes,
                              organizerEventLabelId:
                                task?.organizerEventLabelId,
                              organizerEventPriorityId:
                                task?.organizerEventPriorityId,
                              privateUserId: task?.privateUserId,
                              referenceId: task?.referenceId,
                              subject: task?.subject,
                              todoEventCandidateDtos:
                                task?.todoEventCandidateDtos,
                              todoEventStatusId: task?.todoEventStatusId,
                              normalOrPhoneOrVisit: task?.normalOrPhoneOrVisit,
                              isFlagged: task?.isFlagged,
                              isToday: task?.isToday === 0 ? 1 : 0,
                            };
                            await dispatch(
                              updateToDoEvent(newValues, task?.id)
                            );
                          }}
                          style={{ marginRight: 6 }}
                        >
                          <img
                            src="./assets/svg/toggle-arrow.svg"
                            height={15}
                            style={{ marginBottom: 5 }}
                            alt="complete"
                          />
                        </div>
                        <div
                          className="task-view-btn"
                          onClick={() => {
                            setShowModalForTasKDetail(true);
                            setTaskId(task.id);
                            setTodoDetails(task);
                            setHideInternalMessage(true);
                            setSmallChatHeight(false);
                            setChatHeight(false);
                            setchatBox(false);
                          }}
                        >
                          {/* <Link
                            to={`/todoLists?task=${task.id}`}
                            className="tender-view-btn"
                          > */}
                          <EyeOutlined />
                          {/* </Link> */}
                        </div>
                      </li>
                    ))}
                  </>
                ) : todayTasksResponse?.length === 0 ? (
                  <div className="dashboards__visitors--none">
                    <Image className="data-no-img" src={man} />
                    <span>There is no information.</span>
                  </div>
                ) : (
                  <div className="dashboards__visitors--none">
                    <Image className="data-no-img" src={man} />
                    <span>There is no information.</span>
                  </div>
                )}
              </ul>

              {showAddTask && (
                <Modal
                  title="Add Task"
                  visible={showAddTask}
                  footer={null}
                  onCancel={() => {
                    setShowAddTask(false);
                  }}
                >
                  <Form
                    name="basic"
                    labelAlign="left"
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    {...layout}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Form.Item
                        label="Task Name"
                        name="subject"
                        rules={[
                          {
                            required: true,
                            message: "  ",
                          },
                        ]}
                      >
                        <Input autoFocus={true} />
                      </Form.Item>

                      <Form.Item
                        name="referenceId"
                        label="Reference"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="label"
                          allowClear={true}
                          onSearch={(value) => {
                            setSearchValueReference(value);
                          }}
                          style={{ maxWidth: "290px" }}
                          notFoundContent={
                            <>
                              {searchKeywordFormat?.length === 2 && (
                                <>
                                  Content Not Found. Do you want to create{" "}
                                  <Typography.Text style={{ color: "blue" }}>
                                    {searchKeywordFormatData}
                                  </Typography.Text>{" "}
                                  ?
                                  <Button
                                    type="primary"
                                    onClick={() => {
                                      dispatch(
                                        referenceSecondaryAdd({
                                          title: searchKeywordFormatData,
                                          description: searchKeywordFormatData,
                                        })
                                      );
                                    }}
                                  >
                                    Yes
                                  </Button>
                                </>
                              )}
                            </>
                          }
                        >
                          {!isEmpty(referenceListing) &&
                            referenceListing.map((reference, index) => {
                              return (
                                <Option
                                  value={reference.id}
                                  key={index}
                                  label={[reference.code, reference.title]}
                                >
                                  <Tooltip title={reference.title}>
                                    <div className="demo-option-label-item">
                                      <div>{reference.code}</div>
                                      <div>{reference.title}</div>
                                    </div>
                                  </Tooltip>
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="organizerEventPriorityId"
                        className="task-form-item task-status"
                        label="Priority"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                        initialValue={
                          organizerEventPriorityListing?.find(
                            (priority) => priority.name === "Normal"
                          )?.id
                        }
                      >
                        <Select placeholder="Select Priority">
                          {!isEmpty(organizerEventPriorityListing) &&
                            organizerEventPriorityListing.map(
                              (status, index) => {
                                return (
                                  <Option value={status.id} key={index}>
                                    {status.name}
                                  </Option>
                                );
                              }
                            )}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="startDate"
                        label="Start Date"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                        // initialValue={
                        //   !alltask ? moment().add(1, "day") : moment()
                        // }
                        // initialValue={moment()}
                        hidden={alltask ? false : true}
                      >
                        <DatePicker format="YYYY/MM/DD " />
                      </Form.Item>

                      <Form.Item
                        name="dueDate"
                        label="Due Date"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                        // initialValue={defaultDate}
                        // initialValue={moment()}
                        hidden={alltask ? false : true}
                      >
                        <DatePicker
                          format="YYYY/MM/DD "
                          placeholder="select due date"
                        />
                      </Form.Item>

                      <Form.Item
                        name="todoEventStatusId"
                        initialValue={allEventStatusListing[1]?.id}
                        className="task-form-item task-progress"
                        hidden={true}
                      >
                        <Select
                          placeholder="Select Progress"
                          bordered={false}
                          className="task-form-item"
                        >
                          {!isEmpty(allEventStatusListing) &&
                            allEventStatusListing.map((status, index) => {
                              return (
                                <Option value={status.id} key={index}>
                                  {status.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>

                    <Form.Item label="Create to">
                      <Checkbox
                        checked={calls}
                        onChange={() => {
                          setCalls(!calls);
                          setVisit(false);
                        }}
                      >
                        Calls
                      </Checkbox>
                      <Checkbox
                        checked={visit}
                        onChange={() => {
                          setVisit(!visit);
                          setCalls(false);
                        }}
                      >
                        Visits
                      </Checkbox>
                    </Form.Item>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        gap: "10px",
                      }}
                    >
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          shape="round"
                          size="small"
                          loading={addToDoEventRespLoading}
                        >
                          <CheckOutlined />
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="dotted"
                          onClick={() => {
                            setShowAddTask(!showAddTask);
                          }}
                          bordered={false}
                          shape="round"
                          size="small"
                        >
                          <CloseOutlined />
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </Modal>
              )}
            </div>
          </div>
        </div>

        {/* <div className="flip-card-back">
          <div
            className="dashboards__todolist dashboards__body"
            style={{ height: heightt[0], backfaceVisibility: "hidden", }}
          >
            <div
              className="dashboards__bodyWrap"
              style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}
            >
              <h4
                className="dashboards__body--title"
                // onClick={() => {
                //   setRotate(rotate + 1);
                //   setAllTask(!alltask);
                // }}
              >
                Task List ({taskTodayList?.length})
                <span
                  onClick={() => {
                    setRotate(rotate + 1);
                    setAllTask(!alltask);
                  }}
                  style={{
                    marginLeft: "10px",
                    height: "30px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src="./assets/svg/toggle-arrow.svg"
                    height={15}
                    alt="complete"
                  />
                </span>
              </h4>
              <Input.Search
                className="form__group_email_search"
                style={{
                  margin: "0 auto",
                  width: "90%",
                  border: "1px solid gray",
                }}
                placeholder="Task Search"
                size="medium"
                allowClear
                bordered={true}
                onSearch={(e) => {
                  const body = `&searchKeyword=${e}&isCompleted=0&MaxResultCount=50&IsTodaytask=true`;
                  dispatch(getTodoEventsPagination(body));
                }}
                autoComplete="off"
              />

              <div
                className="goal-action-wrapper"
                style={{ marginLeft: "auto" }}
              >
                <button
                  onClick={() => {
                    setShowAddTask(true);
                    // setAllTask(false);
                  }}
                  style={{ cursor: "pointer", marginBottom: "4px" }}
                >
                  <PlusOutlined
                    style={{
                      fontSize: "10px",
                      color: "#000",
                      verticalAlign: "revert",
                    }}
                  />
                  Add Task
                </button>
                <Dropdown.Button
                  overlay={menu1}
                  className="new_menu"
                  style={{
                    marginBottom: "5px",
                    width: "20px",
                    border: "none",
                    marginLeft: "2px",
                  }}
                />
              </div>
            </div>
            <div
              className={`dashboards__body--main todolist-wrapper  ${
                todoEventsLoading ? "" : ""
              }`}
            >
              <div
                id="today-target"
                style={{
                  height: heightt[1],
                  overflow: "auto",
                  display: "block",
                }}
              >
                {!isEmpty(taskTodayList) ? (
                  <InfiniteScroll
                    dataLength={taskTodayList?.length}
                    next={fetchMoreData}
                    hasMore={
                      allToDoEvents?.totalCount > allToDoEvents?.items?.length
                    }
                    scrollableTarget="today-target"
                    // loader={<Skeleton paragraph={{ rows: 1 }} active />}
                  >
                    {todayTaskListPagination()}
                  </InfiniteScroll>
                ) : todoEventsLoading ? (
                  // <Skeleton paragraph={{ rows: 2 }} active />
                  <></>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div> */}

        <div className="flip-card-back">
          <div
            style={{
              backfaceVisibility: "hidden",
            }}
          >
            <div className={"dashboards__tenderlist dashboards__body"}>
              <div className="dashboards__bodyWrap">
                <h4 className="dashboards__body--title">
                  {/* {!showResetButton
                    ? `Diary Logs of Today`
                    : `Diary Logs of ${startDate} to ${endDate}${
                        selectedUserName ? ` [${selectedUserName}]` : ""
                      } ${
                        dailyDairyPaginationResp?.totalCount
                          ? `(${dailyDairyPaginationResp.totalCount})`
                          : ""
                      }`} */}
                  {!showResetButton
                    ? `Diary Logs of Today`
                    : `Searched Diary Logs${
                        startDate || endDate
                          ? ` of ${startDate || ""} to ${endDate || ""}`
                          : ""
                      }${selectedUserName ? ` [${selectedUserName}]` : ""} ${
                        dailyDairyPaginationResp?.totalCount
                          ? `(${dailyDairyPaginationResp.totalCount})`
                          : ""
                      }`}

                  {/* ADDED */}
                  <span
                    onClick={() => {
                      setRotate(rotate + 1);
                      setIsFlippedBack((prev) => !prev);
                    }}
                    style={{
                      marginLeft: "10px",
                      height: "30px",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      // src="./assets/svg/toggle-arrow.svg"
                      // height={15}
                      src={newLogIcon}
                      height={18}
                      alt="complete"
                    />
                  </span>
                </h4>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Tooltip title="Download Sample Diaily Dairy">
                    <Button {...sampleButtonProps} />
                  </Tooltip>
                  {hasTodoDiaryBeenAdvancedSearched && (
                    <Tooltip
                      title={
                        canDownloadExcel
                          ? "You can download Excel here"
                          : "Opps, nothing to download..."
                      }
                    >
                      <Button
                        {...buttonProps}
                        // onClick={handleDownloadTodoExcel}
                        // loading={isDownloadingExcel}
                      />
                    </Tooltip>
                  )}

                  {!showResetButton ? (
                    <>
                      <Input.Search
                        className="form__group_email_search"
                        bordered={false}
                        style={{
                          margin: "0 auto",
                          border: "1px solid gray",
                        }}
                        placeholder="Search"
                        allowClear
                        onSearch={handleSearchDailyDiary}
                        autoComplete="off"
                      />
                    </>
                  ) : (
                    <>
                      <Input.Search
                        className="form__group_email_search"
                        bordered={false}
                        style={{
                          margin: "0 auto",
                          // width: "40%",
                          border: "1px solid gray",
                        }}
                        // allowClear
                        // size="medium"
                        value="[Advance Search]"
                      />

                      <button
                        className="clear_icon_advance_search"
                        onClick={() => handleResetAdvancedSearch()}
                      >
                        <CloseCircleOutlined className="ant_close_icon" />
                      </button>
                    </>
                  )}

                  <div
                    onClick={() => {
                      setShowAdvanceSearchModal(true);
                    }}
                  >
                    <FilterOutlined className="hr_searchOutlined" />
                  </div>
                </div>

                {/* <Input.Search
                  className="form__group_email_search"
                  style={{
                    margin: "0 auto",
                    width: "40%",
                    border: "1px solid gray",
                  }}
                  placeholder="Daily Diary Search"
                  size="medium"
                  allowClear
                  bordered={true}
                  onSearch={(e) => {
                    let query = {
                      searchKeyword: e,
                      MaxResultCount: 50,
                    }
                    dispatch(actionForGetPaginationDailyDairy(query));
                  }}
                  autoComplete="off"
                /> */}
              </div>

              <div className="dashboards__body--main">
                <DailyDairy
                  data={dailyDairyPaginationResp}
                  loading={dailyDairyPaginationLoading}
                  // selectedRecordData={selectedRecordData}
                  setSelectedRecordData={setSelectedRecordData}
                  setIsDailyDairyModalOpen={setIsDailyDairyModalOpen}
                  showResetButton={showResetButton}
                  selectedAdvancedSearchDate={selectedAdvancedSearchDate}
                  setSelectedAdvancedSearchDate={setSelectedAdvancedSearchDate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Search Advanced Model*/}
      <Modal
        title="Daily Dairy Advanced Search"
        visible={showAdvanceSearchModal}
        onCancel={handleCancelAdvancedSearch}
        footer={null}
      >
        <Form
          form={formDailyDiarySearch}
          onFinish={onAdvanSearchFinish}
          labelAlign="left"
          colon={false}
          autoComplete="off"
        >
          <div className="row-wrapper">
            <div className="global-form-flex" style={{ flexFlow: "row wrap" }}>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Sorting by title</label>
                </div>
                <div
                  className="global-form-input"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                >
                  <Form.Item name="sortingByTitle" valuePropName="checked">
                    <Checkbox
                      checked={isSortingByTitle}
                      onChange={(e) => handleSortingByTitle(e.target.checked)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "6px",
                      }}
                    ></Checkbox>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Order</label>
                </div>
                <div className="global-form-input">
                  <Form.Item name="date">
                    <Select
                      placeholder="Select order"
                      onChange={(value) => setSortTypeValue(value)}
                    >
                      <Option value="asc">Ascending</Option>
                      <Option value="desc">Descending</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              {!isEmpty(grantedPolices) &&
                grantedPolices[
                  "CQNovalFramework.DashboardMenu.DailyDiary.UserListing"
                ] && (
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label>Users</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item className="form__group" name="UserId">
                        <Select
                          placeholder="Select a User"
                          showSearch={true}
                          // mode="multiple"
                          allowClear
                          filterOption={(input, option) =>
                            option.children[0] &&
                            option.children[0]
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(id, value) => {
                            setSelectedUserId(id);
                            if (id === null || id === undefined) {
                              // Reset the username when cleared
                              setSelectedUserName(null);
                            } else {
                              const selectedUser = user.find(
                                (u) => u.appUserId === id
                              );
                              if (selectedUser) {
                                setSelectedUserName(selectedUser.userName);
                              }
                            }
                          }}
                        >
                          {user.map((user) => (
                            <Option key={user.appUserId}>
                              {user.fullName} {`[${user.userName}]`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                )}

              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Date</label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="DailyDiaryDate"
                    // label="Date"
                    initialValue={moment()}
                    rules={[
                      { required: true, message: "Please select a date!" },
                    ]}
                  >
                    {/* <DatePicker
                      format="YYYY-MM-DD"
                      onChange={(date) =>
                        setSelectedDate(
                          date
                            ? date.format("YYYY-MM-DD")
                            : moment().format("YYYY-MM-DD")
                        )
                      }
                    /> */}
                    <RangePicker
                      onChange={(value) => {
                        setStartDate(
                          value ? moment(value[0]).format("YYYY-MM-DD") : ""
                        );
                        setEndDate(
                          value ? moment(value[1]).format("YYYY-MM-DD") : ""
                        );
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <Button
            type="ghost"
            shape="round"
            htmlType="submit"
            autoComplete="off"
          >
            Search
          </Button>
        </Form>
      </Modal>

      {/* Remarks Modal */}
      <Modal
        title="Remarks Modal"
        visible={isRemarksModalOpen}
        onCancel={handleRemarksOpen}
        footer={null}
      >
        <Form form={formRemarks} onFinish={handleFinishFormRemarks}>
          <Form.Item
            label="Remaks"
            name="remarks"
            rules={[
              {
                required: true,
                message: "Remarks field is required",
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder="Remarks" />
          </Form.Item>
          <Form.Item>
            <Button
              className="company-add-save-btn"
              type="primary"
              htmlType="submit"
              shape="round"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Edit Daily Dairy"
        visible={isDailyDairyModalOpen}
        onCancel={handleAddUpdateDairy}
        footer={null}
      >
        <AddUpdateDailyDairy
          selectedRecordData={selectedRecordData}
          handleAddUpdateDairy={handleAddUpdateDairy}
          // setSelectedRecordData={setSelectedRecordData}
        />
      </Modal>

      <Modal
        title={
          <Button
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleChangeEvent("task");
            }}
          >
            Edit Task
          </Button>
        }
        visible={showModalForTaskDetail}
        // onOk={handleOk}
        onCancel={handleCancel}
        // cancelButtonProps={{
        //   style: {
        //     display: "none",
        //   },
        // }}
        footer={null}
      >
        <ChatDetails
          allToDoEvents={allToDoEvents}
          taskId={taskId}
          setShowChat={setShowChat}
          setSmallChatHeight={setSmallChatHeight}
          setChatHeight={setChatHeight}
          chatHeight={chatHeight}
          smallChatHeight={smallChatHeight}
          setchatBox={setchatBox}
          chatBox={chatBox}
          module="Task"
          origin="Dashboard"
          setHideInternalMessage={setHideInternalMessage}
          hideInternalMessage={hideInternalMessage}
          todoDetails={todoDetails}
          isPrivate={false}
        />
      </Modal>

      <CreateEventDrawer
        isPrivate={false}
        visibleEventFormDrawer={visibleEventFormDrawer}
        eventFormSelect={eventFormSelect}
        onCloseEventFormDrawer={onCloseEventFormDrawer}
        getData={getData}
        todoDetails={todoDetails}
        form={formToDo}
        isDashboard={true}
        largeWidth={largeWidth}
        setLargeWidth={setLargeWidth}
        visitor={visitor}
        setVisitor={setVisitor}
      />
    </div>
  );
};

export default TodoLists;
