import React, {useState, useEffect} from "react";
import {
  Button,
  Drawer,
  Row,
  Col,
  Layout,
  Input,
  Space,
  Form,
  DatePicker,
  Checkbox,
} from "antd";

const { Header } = Layout;


export const AddUpdateTendeDrawer = ({
  drawerVisibility,
  onAddUpdateDrawerClose,
  isUpdateApplication,
}) => {
  return (
    <div>
      <Drawer
        width={472}
        onClose={onAddUpdateDrawerClose}
        visible={drawerVisibility}>
        <Header className='drawer__header'>
          <h4 className='drawer__header--title'>News Cutting</h4>
        </Header>
        <div className='drawer__content'>
          <div className='drawer__content--wrapper'>
            <div className='contact__content__wrapper'>
              <Form name='dynamic_form_nest_item'>
                <Form.Item className='form__group' label='To'>
                  <Input />
                </Form.Item>
                <Form.Item className='form__group' label='Subject'>
                  <Input />
                </Form.Item>
                <Form.Item
                  className='form__group'
                  label='Who else get notified'>
                  <Input />
                </Form.Item>
                <Form.Item className='form__group' label='Sector'>
                  <Input />
                </Form.Item>
                <Form.Item className='form__group' label='Newpaper'>
                  <Input />
                </Form.Item>
                <Form.Item className='form__group' label='Published Date'>
                  <DatePicker />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' block>
                    Send
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type='primary' block>
                    Send/Continue
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};