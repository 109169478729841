import moment from "moment";
import React from "react";
import { twoDecimalFormatter } from "utils/twoDecimalFormatter";

const CeadDetailsList = ({ list }) => {
  console.log("ceadlist", list);

  return (
    <>
      <span style={{ paddingLeft: 25 }}>Reference Code: {list?.contract}</span>
      <br />
      <span style={{ paddingLeft: 25 }}>
        Invoice Number: {list?.invoiceNumber}
      </span>
      <br />
      <span style={{ paddingLeft: 25 }}>
        Amount:
        {twoDecimalFormatter({
          number: list?.amount,
          isInteger: Number.isSafeInteger(list?.amount),
        })}
      </span>
      <br />
      <span style={{ paddingLeft: 25 }}>
        Issued:
        {moment(list?.creationTime).format("DD-MMM-YYYY")}
      </span>
    </>
  );
};

export default CeadDetailsList;
