import moment from "moment";

const getColumnsDashboard = () => {
  return [
    {
      title: "S NO",
      key: "sno",
      render: (text, object, index) => index + 1,
    },
    {
      title: "Name",
      render: (record) => {
        if (record?.attendanceLateReason) {
          return <span className="red-cross-section">{record.fullName}</span>;
        } else {
          return <span className="red-cross">{record.fullName}</span>;
        }
      },
    },

    {
      title: "Check In",
      render: (record) => {
        if (
          record?.attendanceIn === "0001-01-01T00:00:00" ||
          record?.attendanceIn === null
        ) {
          return <span className="red-cross">{"---"}</span>;
        } else {
          return (
            <span
              className={`${
                record?.attendanceLateReason ? "red-cross-section" : ""
              }`}
            >
              {moment(record?.attendanceIn).format("hh:mm:ss A")}
            </span>
          );
        }
      },
    },

    {
      title: "Check Out",
      render: (record) => {
        if (
          record?.attendanceOutAt === "0001-01-01T00:00:00" ||
          record?.attendanceOutAt === null
        ) {
          return <span className="red-cross">{"---"}</span>;
        } else {
          return (
            <span
              className={`${
                record?.attendanceLateReason ? "red-cross-section" : ""
              }`}
            >
              {moment(record?.attendanceOutAt).format("hh:mm:ss A")}
            </span>
          );
        }
      },
    },
    // Added
    {
      title: "Biometric In",
      render: (record) => {
        if (
          record?.biometricAttendanceIn === "0001-01-01T00:00:00" ||
          record?.biometricAttendanceIn === null
        ) {
          return <span className="red-cross">{"---"}</span>;
        } else {
          return (
            <span
              className={`${
                record?.attendanceLateReason ? "red-cross-section" : ""
              }`}
            >
              {record?.biometricAttendanceIn
                ? moment(record?.biometricAttendanceIn).format("hh:mm:ss A")
                : "---"}
            </span>
          );
        }
      },
    },
    {
      title: "Biometric Out",
      render: (record) => {
        if (
          record?.biometricAttendanceOut === "0001-01-01T00:00:00" ||
          record?.biometricAttendanceOut === null
        ) {
          return <span className="red-cross">{"---"}</span>;
        } else {
          return (
            <span
              className={`${
                record?.attendanceLateReason ? "red-cross-section" : ""
              }`}
            >
              {record?.biometricAttendanceOut
                ? moment(record?.biometricAttendanceOut).format("hh:mm:ss A")
                : "---"}
            </span>
          );
        }
      },
    },
    // Upto here
    {
      title: "Remarks",
      render: (record) => {
        return (
          <div>
            <span style={{ color: "#FF0000" }}>
              {record?.attendanceLateReason}
            </span>
            <span>
              {record?.leaveDateFrom
                ? `In Leave (${record?.leaveType}): ${moment(
                    record?.leaveDateFrom
                  ).format("DD MMM YYYY")}`
                : ""}{" "}
              {record?.leaveDateTo
                ? `- ${moment(record?.leaveDateTo).format("DD MMM YYYY")}(${
                    record?.leaveDays
                  } days)`
                : ""}
            </span>
          </div>
        );
      },
    },
  ];
};

export default getColumnsDashboard;
