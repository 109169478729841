import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from 'antd';
import { validateMessages } from 'utils/misc';
import { currencyAdd, currencyUpdate } from 'services/redux/contact/contact.action';
import { countryListing } from 'services/remanentCalls';

const { Option } = Select;
const { Header, Footer } = Layout;

const CurrencyAddUpdateDrawer = ({ onAddUpdateCurrencyClose, drawerVisibility, isUpdateCurrency }) => {
	const { currencyAddResponse, currencyUpdateResponse } = useSelector((state) => state.contact);

	const [form] = Form.useForm();

	form.resetFields();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isUpdateCurrency) return;
		form.setFieldsValue({
			...isUpdateCurrency,
		});
	}, [isUpdateCurrency]);

	useEffect(() => {
		onAddUpdateCurrencyClose();
	}, [currencyAddResponse, currencyUpdateResponse]);

	const onSubmit = async () => {
		try {
			let values = await form.validateFields();
			if (isUpdateCurrency) {
				dispatch(currencyUpdate(isUpdateCurrency.id, values));
			} else {
				dispatch(currencyAdd(values));
			}
		} catch (errorInfo) {
			console.error('Failed:', errorInfo);
		}
	};

	return (
		<Drawer visible={drawerVisibility} width={472} maskClosable={false} className="drawer" onClose={onAddUpdateCurrencyClose} closable={false}>
			<Header className="drawer__header">
				<h4 className="drawer__header--title">{`${isUpdateCurrency ? 'Update' : 'Create'} Currency Name`}</h4>
			</Header>

			<Form layout="vertical" form={form} name="control-hooks" validateMessages={validateMessages} className="drawer-form-wrapper">
				<div className="drawer-form-row">
					<div className="drawer-form-group">
						<Form.Item className="form__group" name="currencyName" label="Currency" rules={[{ required: true }]}>
							<Input autoComplete='off' />
						</Form.Item>
					</div>
					<div className="drawer-form-group">
						<Form.Item className="form__group" name="abbvr" label="Abbrevation" rules={[{ required: true }]}>
							<Input autoComplete='off' />
						</Form.Item>
					</div>
				</div>
				
				<div className="model-footer">
					<Button onClick={onAddUpdateCurrencyClose}>Cancel</Button>
					<Button onClick={onSubmit} type="primary">
						Submit
					</Button>
				</div>
			</Form>

			
		</Drawer>
	);
};

export default CurrencyAddUpdateDrawer;
