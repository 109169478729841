import { appBaseUrl } from "utils/config";
import {
  getService,
  postService,
  getServiceMail,
  putService,
} from "../../../commonServices";
import queryString from "query-string";

export class InstanceMessageCenterService {
  getInstanceMessageCenterPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/mailMessageCenter/messageCenterInstanceMessagePagination?${query}`;
    let data = getService(url);
    return data;
  }

  replyInstanceMessage(body) {
    let url = `${appBaseUrl}/mailMessageCenter/instanceMessageAdd`;
    let data = postService(url, body, "post");
    return data;
  }

  addDocumentMessage(body) {
    let url = `${appBaseUrl}/mailMessageCenter/mailMessageWithInstanceChatInsert`;
    let data = postService(url, body, "post");
    return data;
  }

  instanceMessageUpdate(body) {
    let url = `${appBaseUrl}/mailMessageCenter/instanceMessageUpdateAll`;
    let data = postService(url, body, "put");
    return data;
  }

  instanceMessageUserSuggestion(q) {
    // let query = queryString.stringify(q);
    let url = `${appBaseUrl}/mailMessageCenter/recentlyUsedMailMessageParticipaintFind?${q}`;
    let data = getService(url);
    return data;
  }

  instanceMessageDelete(id) {
    let url = `${appBaseUrl}/mailMessageCenter/instanceMessageDelete/${id}`;
    let data = postService(url, {}, "delete");
    return data;
  }

  sendCorrectionMessage(correctionBody) {
    let url = `${appBaseUrl}/mailMessageCenter/referenceCorrectionInstanceMessage`;
    let data = postService(url, correctionBody);
    return data;
  }
  getMessageApplicationTypeListing() {
    let url = `${appBaseUrl}/mailMessageCenter/mailMessageApplicationTypeListing`;
    let data = getService(url);
    return data;
  }
  sendAniversaryMessage(body) {
    let url = `${appBaseUrl}/mailMessageCenter/employeeCelebrationInstanceMessage`;
    let data = postService(url, body);
    return data;
  }
  sendLateLoginSms(body) {
    let url = `${appBaseUrl}/mailMessageCenter/composeBulkSMS`;
    let data = postService(url, body);
    return data;
  }
}
