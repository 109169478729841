import {
  Avatar,
  Col,
  Divider,
  Layout,
  List,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
// import { useState } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";

const { Content } = Layout;

const { Title } = Typography;

const titleHeight = {
  backgroundColor: "rgb(216, 221, 235)",
  height: "30px",
  padding: "3px 0px 0px 6px",
  margin: "0px",
};

export default function Archieve({
  setJobApplcationId,
  archieveApplicationData,
  activeIndex,
  setActiveIndex,
  applicationAllDataLoading,
}) {
  const jobTitleDate = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
    <>
      <Title level={5} style={titleHeight}>
        Archive
      </Title>

      {applicationAllDataLoading || applicationAllDataLoading === null ? (
        <Skeleton />
      ) : (
        <Content style={{ height: "280px", overflowY: "scroll" }}>
          {archieveApplicationData?.map((item, index) => {
            return (
              <div
                style={{
                  // background: activeIndex === item.id ? "#40a9ff" : "none",
                  background: activeIndex === item.id && "#67a1e4",
                }}
                className="hr_System_sideBody_row"
                key={item?.id}
              >
                <Tooltip title={item.jobTitle}>
                  <Row
                    className={`hrSystem_sidebody_list ${
                      activeIndex === item.id ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveIndex(item?.id);
                      setJobApplcationId(item?.id);
                    }}
                    style={{
                      marginLeft: 20,
                      fontWeight: activeIndex === item.id ? 750 : "normal",
                      fontSize: 12,
                      height: 28,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      offset={2}
                      xs={22}
                      md={22}
                      lg={12}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: activeIndex === item.id ? "white" : "black",
                      }}
                    >
                      {item.jobTitle}
                    </Col>
                    <Col
                      offset={2}
                      xs={24}
                      md={24}
                      lg={8}
                      style={{
                        ...jobTitleDate,
                        color: activeIndex === item.id ? "white" : "black",
                      }}
                    >
                      {/* {moment(item.closingDate).format("YYYY-MM-DD")} */}
                      {moment(item.closingDate).format("DD-MMM-YYYY")}
                    </Col>
                  </Row>
                </Tooltip>
                {/* <Divider style={{ margin: "-1px 0px 5px 0px" }} /> */}
              </div>
            );
          })}
        </Content>
      )}
    </>
  );
}
