import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useHistory, Link } from "react-router-dom";
import { isEmpty, upperCase } from "lodash-es";
import * as signalR from "@microsoft/signalr";
// import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

// antd
import { Badge, Layout, message } from "antd";
import {
  CheckCircleOutlined,
  CommentOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { appNotifyUrl } from "../utils/config";

// redux
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../services/redux/auth/auth.action";
import { employeeProfileDetail } from "../services/redux/profile/profile.action";
import { getTodoEventsPagination } from "services/redux/organizer/todoLists/action";
import { emailPaginationCount } from "services/redux/email/mailMessageCenter/action";
import { actionForCountGroupMail } from "services/redux/groupChat/action";
import { cqBaseUrl } from "../utils/config";
import storage from "../utils/storage";
import { setBellNotifications } from "../services/redux/notification/action";
import { getApplicationConfiguration } from "services/redux/common/applicationConfiguration/action";
import testIcon from "assets/svg/groupchat.svg"
//while response reload pagination for newEmail
// import { refreshUserIncomingEmails } from "services/redux/email/mailMessageCenter/action";

//remanent calls
// import { logOutAttendance } from "services/remanentCalls";

// context
import MyContext from "context/MyContext";

import useRoles from "../hooks/useRoles";
import expert from "../../src/assets/svg/expert.svg";
import cead from "../../src/assets/images/cooperation.png";

const Home = "./assets/svg/home.svg";

const business = "./assets/svg/business.svg";
const Calendar = "./assets/svg/organizer.svg";
const groupChatIcon = "./assets/svg/groupchat.svg";
const hr = "./assets/svg/HR.svg";
const privateTask = "./assets/svg/PrivateTask.svg";

const Mail = "./assets/svg/message.svg";
const admin = "./assets/svg/admin.svg";
const system = "./assets/svg/system.svg";
const Requistions = "./assets/svg/Approvals-32.svg";
const contactlist = "./assets/svg/contact-list.svg";
const Documents = "./assets/svg/Doc-12.svg";
const pageSize = 40;

const Navbar = forwardRef(({ canGoBack, view }, ref) => {
  const { mailOriginState, updateMailOriginState } = useContext(MyContext);

  const { LimitedRoleDemo, isLimitedRoleMailBox } = useRoles();
  const { mailPaginationCount } = useSelector(
    (state) => state.mailMessageCenter
  );
  const {
    getCountGroupMailResp
} = useSelector((state) => state.groupChatReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );
  const { profileInfo } = useSelector((state) => state.profile);

  const [connection, setConnection] = useState(null);

  const [state, setState] = useState({
    current: "/dashBoard",
    collapsed: true,
    basePath: "/dashBoard",
    profile: false,
  });

  const [grantedPolices, setGrantedPolices] = useState({});
  const [employeeProfilePicture, setEmployeeProfilePicture] = useState("");
  const [userId, setUserId] = useState();
  const [employeeProfilePictureName, setEmployeeProfilePictureName] =
    useState("");
  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );
  useEffect(() => {
    if (!isEmpty(applicationConfiguration)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  useEffect(() => {
    dispatch(emailPaginationCount({ messagecenterType: "All" }));
  }, []);

  useEffect(() => {
    dispatch(actionForCountGroupMail());
  }, [])

  useEffect(() => {
    dispatch(
      employeeProfileDetail(
        {
          AppUserId: localStorageId,
        },
        "loginUser"
      )
    );
  }, []);

  useEffect(() => {
    if (
      !isEmpty(profileInfo) &&
      !isEmpty(profileInfo.employeePersonalDetailUpdateDto)
    ) {
      // setEmployeeProfile(profileInfo.employeePersonalDetailUpdateDto)
      setUserId(profileInfo.userRegistrationAddUpdateDto.userName);
      if (
        profileInfo.userRegistrationAddUpdateDto.userName === localStorageName
      ) {
        setEmployeeProfilePicture(
          profileInfo.employeePersonalDetailUpdateDto.uploadedFileDtos
        );

        if (profileInfo.employeePersonalDetailUpdateDto.employeeName) {
          let name = profileInfo.employeePersonalDetailUpdateDto.employeeName;
          let profilePicNameFL = name.match(/\b(\w)/g);
          setEmployeeProfilePictureName(upperCase(profilePicNameFL.join("")));
        }
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    let basePath = history.location.pathname.split("/")[1];
    setState({ ...state, current: history.location.pathname, basePath });
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(getApplicationConfiguration());
  }, []);

  useEffect(() => {
    const connect = new signalR.HubConnectionBuilder()
      .withUrl(`${appNotifyUrl}`, {
        accessTokenFactory: () => {
          return sessionStorage.getItem("token") || "";
        },
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("ReceiveMessage", function (messagelog) {
            message.success(messagelog);
            //amit commented
            // dispatch(
            //   refreshUserIncomingEmails(
            //     selectedMailTypeAndMailData.typeSystemName
            //   )
            // );
          });
          connection.on("ReceiveUserEmail", function (messagelog) {
            message.success(messagelog);
            //amit commented
            // dispatch(
            //   refreshUserIncomingEmails(
            //     selectedMailTypeAndMailData.typeSystemName
            //   )
            // );
          });
          connection.on(
            "ReceiveBellNotification",
            function (jsonFormatNotificationData) {
              // alert('hey');
              dispatch(
                setBellNotifications(JSON.parse(jsonFormatNotificationData))
              );
              // Render_OL_List(list);
            }
          );
          connection.on(
            "ReceiveInstanceNotification",
            function (jsonFormatNotificationData) {
              var info = JSON.parse(jsonFormatNotificationData);
              message.success(info?.From + "=>" + info?.Message, 10);
            }
          );

          connection.on("LoadBellNotificationData", function (list) {
            dispatch(setBellNotifications(list));
          });
        })
        .catch((error) => console.log(error));
      connection.on("UserConnected", function (username) {
        let userId = storage.getItem("userId");
        // const myConnectionId = connectionId;
        // SendMessageFrom(username)
        LoadBellNotification(username);
      });
    }
  }, [connection]);

  // function SendMessageFrom(userName) {

  // 	connection
  // 		.invoke("SendMessage", userName, `Hey!! ${userName} here.`)
  // 		.catch(function (err) {
  // 			return console.error(err.toString());
  // 		});
  // }

  // useEffect(() => {
  // 	const connection = new signalR.HubConnectionBuilder()
  // 		.withUrl(
  // 			`https://cqnotify.info.com.np/signalr-hubs/CQNovalNotification`,
  // 			{
  // 				// transport: signalR.HttpTransportType.LongPolling,
  // 				accessTokenFactory: () => {
  // 					return localStorage.getItem("token") || "";
  // 				},
  // 			}
  // 		)
  // 		.configureLogging(signalR.LogLevel.Information)
  // 		.build();

  // 	async function start() {
  // 		try {
  // 			await connection.start();
  // 			console.log("SignalR Connected.");
  // 		} catch (err) {
  // 			setTimeout(start, 5000);
  // 		}
  // 	}

  // 	// // Start the connection.
  // 	start();
  // 	// connection.onclose(start);

  // 	connection.on("UserConnected", function (connectionId) {
  // 		let userId = storage.getItem('userId')

  // 		const myConnectionId = connectionId;

  // 		LoadBellNotification(myConnectionId, connection, userId);
  // 	});

  // 	connection.on("ReceiveBellNotification", function (jsonFormatNotificationData){
  // 		console.log('hey')
  // 		console.log(jsonFormatNotificationData)
  // 		// Render_OL_List(list);
  // 	});

  // 	connection.on("LoadBellNotificationData", function (connectionId, list) {

  // 		Render_OL_List(list);
  // 	});
  // }, []);

  function LoadBellNotification() {
    connection.invoke("Call_LoadBellNotificationData").catch(function (err) {
      return console.error(err.toString());
    });
  }
  let localStorageName = storage.getItem("name");
  let localStorageId = storage.getItem("userId");
  const handleLogout = () => {
    dispatch(logoutUser());
  };
  // console.log(
  //   "profileInfo.employeePersonalDetailUpdateDto.userName",
  //   profileInfo?.employeePersonalDetailUpdateDto?.userName,
  //   "name",
  //   localStorageName
  // );
  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    isComplete = 0,
    labelStatusId = "",
    CompleteStatus = "",
    orderBy = "",
    sortType = "",
    priorityStatusId = "",
    progressStatusId = "",
    assignedTo = "",
    referenceId = "",
    isPrivate = "true",
    forPagination = false
  ) => {
    let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}&isPrivate=${isPrivate}`;

    if (isComplete == 0 || isComplete == 1) {
      appendUrl += `&isCompleted=${isComplete}`;
    }

    if (labelStatusId) {
      appendUrl += `&labelStatuses=${labelStatusId}`;
    }
    if (!isEmpty(CompleteStatus)) {
      CompleteStatus.map((status) => {
        appendUrl += `&todoStatuses=${status.id}`;
      });
    }

    if (orderBy) {
      appendUrl += `&sorting=${orderBy}`;
    }
    if (sortType) {
      appendUrl += `&sortType=${sortType}`;
    }
    if (priorityStatusId) {
      appendUrl += `&priorityStatuses=${priorityStatusId}`;
    }
    if (progressStatusId) {
      appendUrl += `&TodoStatuses=${progressStatusId}`;
    }

    if (assignedTo) {
      appendUrl += `&AssignedStatus=${assignedTo}`;
    }
    if (referenceId) {
      appendUrl += `&ReferenceIds=${referenceId}`;
    }
    dispatch(getTodoEventsPagination(appendUrl, forPagination));
  };
  const getTaskData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    isComplete = 0,
    labelStatusId = "",
    CompleteStatus = "",
    orderBy = "",
    sortType = "",
    priorityStatusId = "",
    progressStatusId = "",
    assignedTo = "",
    referenceId = "",

    forPagination = false
  ) => {
    let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;

    if (isComplete == 0 || isComplete == 1) {
      appendUrl += `&isCompleted=${isComplete}`;
    }

    if (labelStatusId) {
      appendUrl += `&labelStatuses=${labelStatusId}`;
    }
    if (!isEmpty(CompleteStatus)) {
      CompleteStatus.map((status) => {
        appendUrl += `&todoStatuses=${status.id}`;
      });
    }

    if (orderBy) {
      appendUrl += `&sorting=${orderBy}`;
    }
    if (sortType) {
      appendUrl += `&sortType=${sortType}`;
    }
    if (priorityStatusId) {
      appendUrl += `&priorityStatuses=${priorityStatusId}`;
    }
    if (progressStatusId) {
      appendUrl += `&TodoStatuses=${progressStatusId}`;
    }

    if (assignedTo) {
      appendUrl += `&AssignedStatus=${assignedTo}`;
    }
    if (referenceId) {
      appendUrl += `&ReferenceIds=${referenceId}`;
    }
    dispatch(getTodoEventsPagination(appendUrl, forPagination));
  };

  return (
    // <Menu
    //   onClick={handleClick}
    //   style={{ width: 256 }}
    //   defaultSelectedKeys={[history.location.pathname]}
    //   defaultOpenKeys={["sub4"]}
    //   mode="inline"
    // >
    //   <Menu.Item key="/home">Home</Menu.Item>
    //   <Menu.Item key="/email">Email</Menu.Item>
    //   <Menu.Item key="3">Calender</Menu.Item>
    //   <Menu.Item key="4">Calls</Menu.Item>

    //   <SubMenu key="sub4" icon={<SettingOutlined />} title="Office Resource">
    //     <Menu.Item key="9">Attendance</Menu.Item>
    //     <Menu.Item key="10">Vehicle Request</Menu.Item>
    //     <Menu.Item key="11">Maintainence</Menu.Item>
    //     <Menu.Item key="12">Reports</Menu.Item>
    //   </SubMenu>
    //     <Menu.Item key="/profile">Profile</Menu.Item>
    //     {
    //       isAdmin() && <Menu.Item key="/admin">Admin</Menu.Item>
    //     }
    // </Menu>

    <div className="navbars">
      <div className="navbars__wrapper" ref={ref}>
        <Link
          to="/profile"
          className="navbars__wrapper--image"
          id="profile-link"
        >
          <div
            className={`navbars__wrapper--avatar ${
              state.basePath === "profile" ? "active" : ""
            }`}
          >
            {!isEmpty(employeeProfilePicture) ? (
              <img
                src={
                  cqBaseUrl +
                  "/" +
                  employeeProfilePicture[0]?.viewFileURL +
                  sessionStorage.getItem("token")
                }
                alt=""
              />
            ) : (
              <div className="default-profile-name">
                {employeeProfilePictureName}
              </div>
            )}
          </div>
        </Link>

        <div className="icons navbar-icons">
          {/* {!LimitedRoleDemo && ( */}
          {/* limited demo start */}
          <>
            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <div className="icon1">
                <Link
                  to="/dashBoard"
                  className={`icons__wrapper ${
                    state.basePath === "dashBoard" ? "active" : ""
                  }`}
                >
                  <img src={Home} alt="" />
                  <span className="icons__wrapper--name"> Dashboard </span>
                </Link>
              </div>
            )}

            {(isLimitedRoleMailBox || !LimitedRoleDemo) && (
              <div className="icon2">
                <Badge
                  count={mailPaginationCount?.allCount?.all?.unread}
                  size={20}
                >
                  {/* <Badge count="100" size={20}> */}
                  <Link
                    to="/home"
                    className={`icons__wrapper ${
                      state.basePath === "home" ? "active" : ""
                    }`}
                  >
                    <div
                      onClick={() => {
                        updateMailOriginState("mailbox");
                      }}
                    >
                      <img src={Mail} alt="" />
                      <span className="icons__wrapper--name">MailBox</span>
                    </div>
                  </Link>
                </Badge>
              </div>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <Link
                to="/organizer"
                className={`icons__wrapper ${
                  state.basePath === "organizer" ? "active" : ""
                }`}
              >
                <img src={Calendar} alt="" />
                <span className="icons__wrapper--name">Calendar</span>
              </Link>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <Link
                to="/contact"
                className={`icons__wrapper ${
                  state.basePath === "contact" ? "active" : ""
                }`}
              >
                <img src={contactlist} alt="" />
                <span className="icons__wrapper--name">Contact</span>
              </Link>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <Link
                to="/expert"
                className={`icons__wrapper ${
                  state.basePath === "expert" ? "active" : ""
                }`}
              >
                <img
                  src={expert}
                  alt=""
                  color="white"
                  className="expert"
                  style={{ fill: "white" }}
                />
                <span className="icons__wrapper--name">Expert role</span>
              </Link>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <Link
                to="/office-resource"
                className={`icons__wrapper ${
                  state.basePath === "office-resource" ? "active" : ""
                }`}
              >
                <img src={business} />
                {/* <span className="icons__wrapper--name">Business</span> */}
                {/* <span className="icons__wrapper--name">Tender</span> */}
                {/* <span className="icons__wrapper--name">Reference</span> */}
                <span className="icons__wrapper--name">
                  Tender/ Project/ Reference
                </span>
              </Link>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <Link
                to="/todoLists"
                className={`icons__wrapper ${
                  state.basePath === "todoLists" ? "active" : ""
                }`}
                onClick={() => {
                  getTaskData();
                }}
              >
                <CheckCircleOutlined className="taskIcon" />
                <span className="icons__wrapper--name">Task</span>
              </Link>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <Link
                to="/document"
                onClick={() => setState({ ...state, basepath: document })}
                className={`icons__wrapper ${
                  state.basePath === "document" ? "active" : ""
                }`}
              >
                <img src={Documents} alt="" />
                <span className="icons__wrapper--name">Document</span>
              </Link>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <Link
                to="/requisition"
                className={`icons__wrapper ${
                  state.basePath === "requisition" ? "active" : ""
                }`}
              >
                <img src={Requistions} alt="" />
                <span className="icons__wrapper--name">Approvals</span>
              </Link>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <>
                {!isEmpty(grantedPolices) &&
                  grantedPolices["AbpIdentity.Roles"] && (
                    <Link
                      to="/settings"
                      className={`icons__wrapper ${
                        state.basePath === "settings" ? "active" : ""
                      }`}
                    >
                      <img src={admin} alt="" />
                      <span className="icons__wrapper--name">Admin</span>
                    </Link>
                  )}
              </>
            )}
            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <>
                {!isEmpty(grantedPolices) &&
                  grantedPolices["AbpIdentity.Roles"] && (
                    <Link
                      to="/system"
                      className={`icons__wrapper ${
                        state.basePath === "system" ? "active" : ""
                      }`}
                    >
                      <img src={system} alt="" />
                      <span className="icons__wrapper--name">System</span>
                    </Link>
                  )}
              </>
            )}
            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <>
                {!isEmpty(grantedPolices) &&
                  grantedPolices["AbpIdentity.Roles"] && (
                    <Link
                      to="/admin"
                      className={`icons__wrapper ${
                        state.basePath === "admin" ? "active" : ""
                      }`}
                    >
                      <img src={hr} alt="" />
                      <span className="icons__wrapper--name"> HR </span>
                    </Link>
                  )}
              </>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <>
                {!isEmpty(grantedPolices) &&
                  grantedPolices["AbpIdentity.Roles"] && (
                    <Link
                      to="/hr-sys"
                      onClick={() =>
                        setState({ ...state, basepath: "/hr-sys" })
                      }
                      className={`icons__wrapper ${
                        state.basePath === "hr-sys" ? "active" : ""
                      }`}
                    >
                      <UsergroupAddOutlined className="taskIcon" />
                      <span className="icons__wrapper--name">HR Vacancies</span>
                    </Link>
                  )}
                {/* <Link
                  to="/hr-sys"
                  onClick={() => setState({ ...state, basepath: "/hr-sys" })}
                  className={`icons__wrapper ${
                    state.basePath === "hr-sys" ? "active" : ""
                  }`}
                >
                  <UsergroupAddOutlined className="taskIcon" />
                  <span className="icons__wrapper--name"> HR System </span>
                </Link> */}
              </>
            )}

            {!LimitedRoleDemo &&
              !isLimitedRoleMailBox &&
              profileInfo?.userRegistrationAddUpdateDto.roleNames.includes(
                "CEAD_View"
              ) && (
                <>
                  <Link
                    to="/cead"
                    onClick={() => setState({ ...state, basepath: "cead" })}
                    className={`icons__wrapper ${
                      state.basePath === "cead" ? "active" : ""
                    }`}
                  >
                    {/* <HomeOutlined className="taskIcon" /> */}
                    <img
                      src={cead}
                      alt="cead"
                      style={{
                        filter: state.basePath === "cead" ? "" : "invert(100%)",
                      }}
                    />
                    <span className="icons__wrapper--name"> CEAD </span>
                  </Link>
                </>
              )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <Link
                to="/complaint"
                onClick={() => setState({ ...state, basepath: "/complaint" })}
                className={`icons__wrapper ${
                  state.basePath === "complaint" ? "active" : ""
                }`}
              >
                <CommentOutlined className="taskIcon" />
                <span className="icons__wrapper--name"> Complain Box </span>
              </Link>
            )}

            {!LimitedRoleDemo && !isLimitedRoleMailBox && (
              <Badge
                count={getCountGroupMailResp ?? 1}
                size={20}
                style={{
                  lineHeight: "2.8",
                }}
              >
                <Link
                  to="/groupchat"
                  onClick={() => setState({ ...state, basepath: "/groupchat" })}
                  className={`icons__wrapper ${
                    state.basePath === "groupchat" ? "active" : ""
                  }`}
                >
                  <img src={testIcon} alt="" />
                  <span className="icons__wrapper--name">Group Chat</span>
                </Link>
              </Badge>
            )}

            {/* <div className="test">
            <Link
              to="/privateTask"
              className={`icons__wrapper ${
                state.basePath === "privateTask" ? "active" : ""
              }`}
              onClick={() => {
                getData();
              }}
            >
              <img src={privateTask} />
              <span className="icons__wrapper--name"> Private</span>
            </Link>
          </div> */}

            {/* <Link to="/requisition" className={`icons__wrapper ${state.basePath === 'requisition' ? 'active' : ''}`}>
						<img src={Requistions} />
						<span className="icons__wrapper--name">Approvals</span>
					</Link>

					
				
					{/* <Link to="">
              <div className="icondiv">
                <i className="icons">
                  <img src={Requistions} style={style} />
                </i>
                <span className="iconsname">HR</span>
              </div>
            </Link>
            <Link to="">
              <div className="icondiv">
                <i className="icons">
                  <img src={Meetings} style={style} />
                </i>
                <span className="iconsname">Admin Settings</span>
              </div>
            </Link>
            {isAdmin() && (
              <Link to="/admin">
                <div className="icondiv">
                  <i className="icons">
                    <img src={Documents} style={style} />
                  </i>
                  <span className="iconsname">Admin</span>
                </div>
              </Link>
            )} */}
          </>
          {/* )} */}
          {/* end of LimitedRoleDemo */}
        </div>
        <div
          style={{
            height: 80,
            position: "relative",
            background: "#06123E",
            left: 3,
            bottom: 0,
          }}
        >
          <div className="navbars__wrapper--logOut" onClick={handleLogout}>
            Log Out
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(Navbar);
