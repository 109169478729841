import React from "react";
import { Drawer } from "antd";
import EmployeeEvaluationFormTest from "./Includes/EmployeeEvaluationFormTest";

const LoanDrawer = ({
  drawerVisibility,
  onCloseDrawer,
  selectedRecord,
  setSelectedRecord,
}) => {
  return (
    <Drawer
      className="approval-form-wrapper"
      title={
        selectedRecord ? " Update Evaluation Form" : "Employee Evaluation Form"
      }
      visible={drawerVisibility}
      width={800}
      maskClosable={false}
      onClose={onCloseDrawer}
      closable={false}
      selectedRecord={selectedRecord}
      setSelectedRecord={setSelectedRecord}
    >
      <div className="drawer__content">
        <div className="drawer__content--wrapper">
          <div className="contact__content__wrapper">
            <EmployeeEvaluationFormTest
              onCloseDrawer={onCloseDrawer}
              selectedRecord={selectedRecord}
              setSelectedRecord={setSelectedRecord}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default LoanDrawer;
