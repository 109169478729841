import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "services/remanentCalls";
import {
  Checkbox,
  Table,
  message,
  Form,
  Select,
  Button,
  Radio,
  Layout,
  List,
  Input,
  Rate,
} from "antd";

import { exitInterviewAdd } from "services/redux/profile/profile.action";

const { Footer } = Layout;
const { TextArea } = Input;

const ExitInterviewSupervisorAddUpdateForm = ({
  employeeUserId,
  editMode,
  setEditMode,
  showSaveCancel,
  setShowSaveCancel,
  currentSupervisor,
  exitInterviewGetResponse,
}) => {
  const dispatch = useDispatch();

  const [supervisorQuestion, setSupervisorQuestion] = useState([]);
  const [questionAnswersResult, setQuestionAnswersResult] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [formFinishStatus, setFormFinishStatus] = useState(false);

  const [form] = Form.useForm();
  const { Option } = Select;

  const handleSaveClick = () => {
    if (formFinishStatus) {
      setEditMode(false);
      setShowSaveCancel(false);
    }
  };
  useEffect(() => {
    if (formFinishStatus) {
      handleSaveClick();
    }
  }, [formFinishStatus]);

  const handleCancelClick = () => {
    setEditMode(false);
    setShowSaveCancel(false);
  };

  useEffect(() => {
    const supervisorQuestions =
      exitInterviewGetResponse?.employeeReviewSupervisorPoints?.flatMap(
        (item) =>
          item?.questionAnswareDtos?.map((questionItem, index) => ({
            key: `question_${index}`,
            question: questionItem.question,
            id: questionItem.id,
            answered: questionItem.answered,
            supervisorId: item.reviewerId,
            supervisorName: item?.reviewerUserDto?.employeeName,
          }))
      ) || [];
    setSupervisorQuestion(supervisorQuestions || []);
  }, [exitInterviewGetResponse]);

  useEffect(() => {
    const initialValues = {};

    exitInterviewGetResponse.employeeReviewSupervisorPoints.forEach((item) => {
      item.questionAnswareDtos.forEach((nestedItem) => {
        const key = `${nestedItem.arrayIndex}_${nestedItem.id}`;
        initialValues[key] = nestedItem.answered || "";
      });
    });

    setSelectedValues(initialValues);
  }, [exitInterviewGetResponse]);

  const setFormValues = () => {
    const fieldValues = {};

    exitInterviewGetResponse?.primaryEmployeeQuestions.forEach((item) => {
      fieldValues[item.question] = item.answered || "";
    });

    exitInterviewGetResponse?.employeeReviewCompanyPoints.forEach((item) => {
      if (item.answered !== null) {
        const rating = item.answered.replace("YES", "");
        item.ratingNumber = parseInt(rating);
        fieldValues[item.question] = item.ratingNumber;
      } else {
        item.ratingNumber = null;
      }

      fieldValues["recommendEmployment"] =
        exitInterviewGetResponse?.wouldYouRecommendEmploymentToAnyoneHere;
    });

    form.setFieldsValue(fieldValues);
  };

  useEffect(() => {
    setFormValues();
  }, []);

  const handleAnswerChange = (question, answer) => {
    const updatedValues = {
      [question]: answer,
    };
    form.setFieldsValue(updatedValues);
  };

  const handleSupervisorCheckbox = (id, rowId, tableIndex, arrayIndex) => {
    const updatedData = [...questionAnswersResult];
    const existingIndex = updatedData.findIndex(
      (item) =>
        item.arrayIndex === arrayIndex &&
        item.id === rowId &&
        item.tableIndex === tableIndex
    );

    if (existingIndex !== -1) {
      updatedData[existingIndex].answered = id;
    } else {
      updatedData.push({ arrayIndex, id: rowId, answered: id, tableIndex });
    }

    setQuestionAnswersResult(updatedData);
  };

  const onFinish = (values) => {
    const questionAnswers =
      exitInterviewGetResponse?.primaryEmployeeQuestions.map((item) => ({
        id: item.id,
        answered: values[item.question],
      }));

    const supervisorAnswers =
      exitInterviewGetResponse?.employeeReviewSupervisorPoints?.map(
        (array, arrayIndex) => ({
          reviewerId: array.reviewerId,
          questionAnswareDtos: array.questionAnswareDtos.map((questionItem) => {
            const result = questionAnswersResult.find(
              (item) =>
                item.arrayIndex === arrayIndex && item.id === questionItem.id
            );
            // Set the default value here
            const defaultValue = questionItem.answered;
            const answered = result ? result.answered : defaultValue;
            return {
              id: questionItem.id,
              answered: answered !== null ? answered : defaultValue,
            };
          }),
        })
      );

    const ratings = exitInterviewGetResponse?.employeeReviewCompanyPoints.map(
      (item) => {
        const ratingValue = values[item.question];
        let ratingString = null;

        if (typeof ratingValue === "undefined") {
          ratingString = null;
        } else if (ratingValue === 0) {
          ratingString = null;
        } else if (ratingValue >= 1 && ratingValue <= 5) {
          ratingString = `YES${ratingValue}`;
        } else {
          ratingString = ratingValue.toString();
        }

        return {
          id: item.id,
          answered: ratingString,
        };
      }
    );

    let recommendEmploymentValue = values.recommendEmployment;
    if (recommendEmploymentValue === undefined) {
      recommendEmploymentValue = null;
    }

    const payload = {
      requestedEmployeeId: employeeUserId,
      employeeReviewCompanyPoints: ratings,
      primaryEmployeeQuestions: questionAnswers,
      employeeReviewSupervisorPoints: supervisorAnswers,
      wouldYouRecommendEmploymentToAnyoneHere: recommendEmploymentValue,
    };

    dispatch(exitInterviewAdd(payload));
    setFormFinishStatus(true);
  };

  const columns = [
    {
      title: "",
      dataIndex: "question",
      key: "question",
      render: (_, record, index) => {
        return <span>{record.question}</span>;
      },
    },
    {
      title: "",
      dataIndex: "supervisorName",
      key: "supervisorName",
      render: (_, record) => {
        if (record.index === 0) {
          return <span>{record.supervisorName}</span>;
        }
        return null;
      },
    },
    {
      title: "",
      dataIndex: "question",
      key: "always",
      render: (_, record, index) => {
        return (
          <Form.Item>
            <Radio.Group
              id={`${record.arrayIndex}_${record.id}`}
              defaultValue={record.answered}
              value={
                questionAnswersResult.find(
                  (item) =>
                    item.arrayIndex === record.arrayIndex &&
                    item.id === record.id &&
                    item.tableIndex === index
                )?.answered
              }
              onChange={(e) =>
                handleSupervisorCheckbox(
                  e.target.value,
                  record.id,
                  index,
                  record.arrayIndex
                )
              }
            >
              <Radio value="1">Always</Radio>
              <Radio value="2">Sometimes</Radio>
              <Radio value="3">Usually</Radio>
              <Radio value="4">Never</Radio>
            </Radio.Group>
          </Form.Item>
        );
      },
    },
  ];

  const dataSource = supervisorQuestion.map((item, index) => ({
    id: item.id,
    question: item.question,
    index: index,
    supervisorId: item?.employeeReviewSupervisorPoints?.reviewerId,
    supervisorName:
      item?.employeeReviewSupervisorPoints?.reviewerUserDto?.employeeName,
  }));

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        name="ExitInterViewForm"
        className="clearance__form"
      >
        {exitInterviewGetResponse?.primaryEmployeeQuestions.map((item) => (
          <Form.Item
            key={item.question}
            label={item.question}
            name={item.question}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Input.TextArea
              rows={4}
              style={{ resize: "none", marginBottom: "0.8em" }}
              onChange={(e) =>
                handleAnswerChange(item.question, e.target.value)
              }
            />
          </Form.Item>
        ))}

        <Form.Item
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          {exitInterviewGetResponse?.employeeReviewSupervisorPoints?.map(
            (array, arrayIndex) => (
              <div key={`table_${arrayIndex}`}>
                <h3 className="exit-interview__row">
                  Supervisor Points Table {arrayIndex + 1}
                </h3>
                <Table
                  dataSource={array.questionAnswareDtos.map(
                    (item, itemIndex) => ({
                      id: item.id,
                      question: item.question,
                      index: itemIndex,
                      answered: item.answered,
                      arrayIndex: arrayIndex,
                      supervisorId: array.reviewerId,
                      supervisorName: array.reviewerUserDto.employeeName,
                    })
                  )}
                  columns={columns}
                  pagination={false}
                />
              </div>
            )
          )}

          <h3 className="exit-interview__row">
            How would you rate the Company on the following points:
          </h3>
          {exitInterviewGetResponse?.employeeReviewCompanyPoints.map((item) => (
            <Form.Item
              key={item.question}
              label={item.question}
              name={item.question}
              labelCol={{
                span: 12,
              }}
              wrapperCol={{
                span: 12,
              }}
            >
              <Rate
                className="exit-interview__custom-rate"
                value={item.ratingNumber}
              />
            </Form.Item>
          ))}

          <h3 className="exit-interview__row">Recommendation</h3>
          <Form.Item
            label="Would you recommend employment to anyone here?"
            name="recommendEmployment"
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 12,
            }}
          >
            <Radio.Group
              value={
                exitInterviewGetResponse?.wouldYouRecommendEmploymentToAnyoneHere
              }
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          <Footer>
            <Button
              className="clearance__form--btn clearance__form--btn-cancel"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              className="clearance__form--btn clearance__form--btn-save "
              onClick={handleSaveClick}
              htmlType="submit"
            >
              Save
            </Button>
          </Footer>
        </Form.Item>
      </Form>
    </>
  );
};

export default ExitInterviewSupervisorAddUpdateForm;
