import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

function CheckListListing(props) {
  const { title } = props;
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  const [openForm, setOpenForm] = useState({
    drawer: false,
    modal: false,
  });

  return (
    <>
      <div className="layout">
        <div className="organizer events">
          <div className="all-header-wrapper">
            <div className="meeting__flex">
              <h3 className="header__all">Tender CheckList</h3>
              <div className="organizerHoliday__middle--wrap">
                <Button
                  className="btn-primary"
                  onClick={() => {
                    setOpenForm({ drawer: true, modal: false });
                  }}
                >
                  <PlusOutlined /> New CheckList
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckListListing;
