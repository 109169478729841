import { INSTANCE_CHAT_TYPES } from "services/types";

const INITIAL_STATE = {
  instanceChatCenterPagination: [],
  instanceChatCenterPaginationLoading: false,

  instanceChatAddResp: [],
  instanceChatAddRespLoading: false,
};

export default function instanceChatReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // instance message pagination
    case INSTANCE_CHAT_TYPES.INSTANCE_CHAT_CENTER_PAGINATION_INIT:
      return {
        ...state,
        instanceChatCenterPagination: [],
        instanceChatCenterPaginationLoading: true,
      };
    case INSTANCE_CHAT_TYPES.INSTANCE_CHAT_CENTER_PAGINATION_SUCCESS:
      return {
        instanceChatCenterPagination: action.payload,
        instanceChatCenterPaginationLoading: false,
      };
    case INSTANCE_CHAT_TYPES.INSTANCE_CHAT_CENTER_PAGINATION_FINISH:
      return {
        ...state,
        instanceChatCenterPagination: [],
        instanceChatCenterPaginationLoading: false,
      };

    // instance chat add
    case INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT_INIT:
      return {
        ...state,
        instanceChatAddResp: [],
        instanceChatAddRespLoading: true,
      };
    case INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT_SUCCESS:
      return {
        ...state,
        instanceChatAddResp: action.payload,
        instanceChatAddRespLoading: false,
        instanceChatCenterPagination: {
          items: [
            ...(state.instanceChatCenterPagination?.items || []),
            action.payload,
          ],
          totalCount: action.payload.totalCount,
        },
      };
    case INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT_FINISH:
      return {
        ...state,
        instanceChatAddResp: [],
        instanceChatAddRespLoading: false,
      };

    default:
      return { ...state };
  }
}
