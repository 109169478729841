import { jsonParser } from "utils/misc";
import { EMAIL_TYPES, AUTH_TYPES } from "../../../types";

const INITIAL_STATE = {
  selectedMailTypeAndMailData:
    jsonParser(
      jsonParser(sessionStorage.getItem("persist:root"))?.mailMessagePeristState
    )?.selectedMailTypeAndMailData || {},
  activeEmail:
    jsonParser(
      jsonParser(sessionStorage.getItem("persist:root"))?.mailMessagePeristState
    )?.activeEmail || {},
};

export default function mailMessagePersistReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case EMAIL_TYPES.SELECTED_MAIL_TYPE_AND_MAIL:
      return {
        ...state,
        selectedMailTypeAndMailData: action.payload,
      };
    case EMAIL_TYPES.SELECTED_MAIL_TYPE_AND_MAIL_UPDATE:
      let newData =
        state.selectedMailTypeAndMailData?.selectedMail?.id ===
        action.query.messageCenterId
          ? {
              ...state.selectedMailTypeAndMailData,
              selectedMail: {
                ...state.selectedMailTypeAndMailData?.selectedMail,
                ...action.emailStatus,
              },
            }
          : { ...state.selectedMailTypeAndMailData };
      return {
        ...state,
        selectedMailTypeAndMailData: newData,
      };
    case EMAIL_TYPES.EMAIL_TYPE_ACTIVE:
      return {
        ...state,
        activeEmail: action.payload,
      };
    case AUTH_TYPES.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        activeEmail: null,
        selectedMailTypeAndMailData: null,
      };
    default:
      return { ...state };
  }
}
