import { Button, Table, Layout } from "antd";
import { EditTwoTone, DeleteOutlined } from "@ant-design/icons";
import { update } from "lodash";
import { isDefined } from "react-pdf/dist/shared/utils";

const IpAddressTable = (props) => {

  const {
    tblData,
    addUpdateIp,
    setAddUpdateIp,
    isOpen,
    setIsOpenDrawer
  } = props;

  const columns = [
    {
      title: "S.N",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
      width: "30%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <>
            <Layout.Content
              style={{ display: "flex" }}
            >
              <EditTwoTone onClick={() => handleEdit(record)} />
            </Layout.Content>
          </>
        );
      },
    }
  ]

  const handleEdit = (record) => {
    setIsOpenDrawer(true);
    setAddUpdateIp({
      isAddIp: false,
      isUpdateIp: true,
      isDeleteIp: false,
      data: record
    });
  }

  return (
    <Table
      className="organizer__tables"
      columns={columns}
      dataSource={tblData?.items}
      rowKey="appUserId"
    />
  )
}

export default IpAddressTable;