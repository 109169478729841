import React from "react";
import {
  Button,
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  Radio,
  DatePicker,
  InputNumber,
  Collapse,
} from "antd";

const EmployeeEvaluationRating = ({
  jobUnderstanding,
  jobSkills,
  growth,
  performance,
  accountable,
  commitment,
  integrity,
  collaboration,
  attendance,
  communicationSkills,

  onChangeJobUnderstanding,
  onChangeJobSkills,
  onChangeGrowth,
  onChangePerformance,
  onChangeAccountable,
  onChangeCommitment,
  onChangeIntegrity,
  onChangeCollaboration,
  onChangeAttendance,
  onChangeCommunicationSkills,
}) => {
  return (
    <>
      <h5 className="employee-ev-rating ant-drawer-header" style={{}}>
        Ratings
      </h5>
      <Row className="employee-evaluation__rating">
        <Col span={8}></Col>
        {/* <Col span={3}>1 = Poor</Col>
        <Col span={3}>2 = Fair</Col>
        <Col span={3}>3 = Satisfactory</Col>
        <Col span={3}>4 = Good</Col>
        <Col span={3}>5 = Excellent</Col> */}
        <Col span={16}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "6px",
            }}
          >
            <p>1 = poor</p>
            <p>2 = Fair</p>
            <p>3 = Satisfactory</p>
            <p>4 = Good</p>
            <p>5 = Excellent</p>
          </div>
        </Col>
      </Row>
      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="jobUnderstandingRating"
          label="Job Understanding"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={jobUnderstanding}
            onChange={onChangeJobUnderstanding}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="jobUnderstandingComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="jobSkillsRating"
          label="Job Skills"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={jobSkills}
            onChange={onChangeJobSkills}
            style={{ width: "100%" }}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="jobSkillsComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="growthRating"
          label="Growth"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={growth}
            onChange={onChangeGrowth}
            style={{ width: "100%" }}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="growthComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>


      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="performanceRating"
          label="Performance"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={performance}
            onChange={onChangePerformance}
            style={{ width: "100%" }}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="performanceComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="accountableRating"
          label="Accountable"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={accountable}
            onChange={onChangeAccountable}
            style={{ width: "100%" }}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="accountableComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>


      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="commitmentRating"
          label="Commitment"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={commitment}
            onChange={onChangeCommitment}
            style={{ width: "100%" }}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="commitmentComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>


      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="integrityRating"
          label="integrity"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={integrity}
            onChange={onChangeIntegrity}
            style={{ width: "100%" }}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="integrityComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>


      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="collaborationRating"
          label="collaboration"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={collaboration}
            onChange={onChangeCollaboration}
            style={{ width: "100%" }}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="collaborationComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>


      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="attendanceRating"
          label="attendance"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={attendance}
            onChange={onChangeAttendance}
            style={{ width: "100%" }}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="attendanceComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Form.Item
          className="employee-ev-radioGroup-wrapper"
          name="communicationSkillsRating"
          label="communicationSkills"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Radio.Group
            value={communicationSkills}
            onChange={onChangeCommunicationSkills}
            style={{ width: "100%" }}
            className="employee-ev-radioGroup"
          >
            <Radio value={1} />
            <Radio value={2} />
            <Radio value={3} />
            <Radio value={4} />
            <Radio value={5} />
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="employe-ev-form"
          name="communicationSkillsComment"
          label="Comments"
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form.Item>


      <Form.Item
        className="employe-ev-form"
        name="majorStrengthComment"
        label="Major Strength"
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item
        className="employe-ev-form"
        name="areasOfImprovementComment"
        label="Area of Improvement"
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
      >
        <Input.TextArea rows={4} />
      </Form.Item>


    </>
  );
};

export default EmployeeEvaluationRating;
