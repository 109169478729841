import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
const getColumns = () => {
  return [
    {
      title: "Company Name",
      dataIndex: "sisterCompanies",
      key: "sisterCompanies",
      render: (record) => <span>{record?.name}</span>,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      render: (record) => <span>{record}</span>,
      width: "5%",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (record) => <span>{record}</span>,
      width: "25%",
    },
    {
      title: "Amount",
      dataIndex: "tentativeAmount",
      key: "tentativeAmount",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Rate Reasonability",
      dataIndex: "rateReasonableComment",
      key: "rateReasonableComment",
      render: (record) => <span>{record}</span>,
      width: "25%",
    },
    {
      title: "Application Status",
      dataIndex: "procurementApplicationStatus",
      key: "procurementApplicationStatus",
      render: (record) => (
        <span className="application_status_name">{record?.displayName}</span>
      ),
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Button
          htmlType="submit"
          style={{ border: 0, backgroundColor: "transparent" }}
          icon={<EyeOutlined />}
          onClick={() => {
            console.log("ID CONSOLE", record?.id);
            // setHideProcurementDrawer(true);
            // setShowProcurementDetails(true);
            // setProcurementDetailsId(record?.id);
          }}
        />
      ),
    },
  ];
};

export default getColumns;
