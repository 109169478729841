import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import _, { cloneDeep, filter, isEmpty, remove } from "lodash";
import queryString from "query-string";
import { appBaseUrl } from "utils/config";
import moment from "moment";
import { debounce } from "lodash";
import { Checkbox, Spin, Switch, Typography } from "antd";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { Redirect, useParams } from "react-router";
import { useHistory } from "react-router";
// antd
import {
  Button,
  Layout,
  Input,
  Space,
  Form,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
  Image,
  Modal,
  // Empty,
  Upload,
  message,
  Progress,
  // AutoComplete,
} from "antd";

import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

// ckeditor
import { Editor } from "@tinymce/tinymce-react";
import tinymce from "tinymce/tinymce";

// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  composeEmail,
  composeDraftMessages,
  circularComposeEmail,
} from "services/redux/email/mailMessageCenter/action";
import {
  getEmailAddressesFromWithBehalfOfListing,
  getParticipantTypeEmailAddressesListing,
} from "services/redux/email/participantEmailAdresses/action";
import { getOrganizerEventPriorityListings } from "services/redux/organizer/priorityLisiting/action";
import { getMailMessageParticipantTypeListing } from "services/redux/email/participantType/action";
// import { setSelectedFileName } from "services/redux/common/persistFiles/action";
import { getMailMessageParticipantStatusListing } from "services/redux/email/messageCenterType/action";
import { getAllUsers } from "services/remanentCalls";
import { uploadFile, uploadFileInternal } from "services/remanentCalls";
import { BusinessModal } from "./BusinessModal";
import { getReferenceListing } from "services/redux/Reference/action";
import { emailGetDetails } from "services/redux/email/mailMessageCenter/action";
// import Loader from "../../../components/Loader";
// import SavedComposeMessage from "../../../components/shared/CloseTabWindow";
import { BroadcastChannel } from "broadcast-channel";

// email signature details redux
import { activeEmailSignatureDetails } from "services/redux/profile/profile.action";

// inlcudes
import ImageUploaderMultiple from "components/shared/ImageUploaderMultiple";
import { getParticipantsInfo } from "../../../utils/commonFunc";

// utils
import storage from "../../../utils/storage";
import { useLocation } from "react-router";
import AddNewQuickEmail from "./AddNewQuickEmail";
import { referenceSecondaryAdd } from "services/redux/Reference/Secondary/action";
import {
  actionForCreateGroup,
  actionForGetListingGroup,
  actionForUpdateGroup,
} from "services/redux/createGroup/action";
import { actionForCeadEmailCompose } from "services/redux/cead/action";
// import { part } from "file-loader";
// import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
// import {
//   constrainPoint,
//   CustomContentRenderContext,
// } from "@fullcalendar/react";
// import { selectedActiveEmail } from "services/redux/email/persistMail/action";

const { Header, Footer } = Layout;
const { Option } = Select;
const antIcon = (
  <LoadingOutlined style={{ fontSize: 13, color: "black", width: 37 }} spin />
);

const composeInteral = "assets/svg/compose-internal.svg";
const chat = "assets/svg/chat-16.svg";
const hide = "assets/svg/hide-16.svg";
const send = "assets/svg/send.svg";
const attachment = "assets/svg/attatchment.svg";

const layoutFormGroup = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const ComposeEmailDrawer = (
  {
    // applicationTypeStatus,
    // setApplicationTypeStatus,
  }
) => {
  /* Prevent Application from Closing */
  const [sendMode, setSendMode] = useState(true);

  const history = useHistory();

  useEffect(() => {
    window.onbeforeunload = () =>
      sendMode ? "Close page without saving data?" : null;
  }, [sendMode]);
  /* Prevent Application from Closing*/
  const dispatch = useDispatch();
  const location = useLocation();
  const [emailid, setEmailid] = useState();
  const [loading, setLoading] = useState(false);
  const [AltEnt, setAltEnt] = useState({});
  const [mailRefDtos, setmailRefDto] = useState([]);

  const priorityEventsState = useSelector((state) => state.priorityEvents);
  const { token } = useSelector((state) => state.auth);
  const { documentShareData } = useSelector(
    (state) => state.documentRequestUpload
  );
  const mailMessageParticipantTypeState = useSelector(
    (state) => state.mailMessageParticipantType
  );
  const mailMessageParticipantStatusState = useSelector(
    (state) => state.mailMessageParticipantStatus
  );
  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );

  const { referenceSecondaryAddIsSuccess } = useSelector(
    (state) => state.secondaryRoot2
  );

  const { organizerEventPriorityListing } = priorityEventsState;
  const { mailMessageParticipantTypeListing } = mailMessageParticipantTypeState;
  const { mailMessageParticipationStatusListing } =
    mailMessageParticipantStatusState;
  const {
    composeEmailResp,
    draftMessagesResponse,
    draftMessageLoading,
    composeEmailRespLoading,
    composeEmailResNotCloseLoading,
    composeCircularEmailResp,
    composeEmailStatus,
  } = useSelector((state) => state.mailMessageCenter);

  const { emailAddressFromWithBehalfListing } = useSelector(
    (state) => state.participantEmailAddresses
  );

  const { tenderCeadComposeEmailDetailsData } = useSelector(
    (state) => state.ceadReducer
  );

  const { persistTenderMailDataResp } = useSelector(
    (state) => state.persistTenderMailState
  );

  const {
    participantTypeEmailAddressesListing,
    participantTypeEmailAddressesListingLoading,
  } = useSelector((state) => state.participantEmailAddresses);
  const defaultEmail =
    emailAddressFromWithBehalfListing?.requestEmployeeEmails?.filter(
      (obj) => obj.isDefault == true
    );
  const defaultEmailAddress = defaultEmail?.[0]?.emailAddress;

  const {
    // contactPersonAddResponse,
    // contactPersonUpdateResponse,
    // contactCompanyDetailAddResponse,
    // contactCompanyUpdateResponse,
    contactQuickAddEmailRespsonse,
  } = useSelector((state) => state.contactCompanyPerson);

  const { mailBodyGetDetailsResponse } = useSelector(
    (state) => state.mailMessageCenter
  );

  // const { activeEmail } = useSelector((state) => state.mailMessagePeristState);
  const { mailMessageCenterPagination } = useSelector(
    (state) => state.mailMessageCenter
  );
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [getparticipants, setGetParticipants] = useState([]);
  const applicationTypeStatus =
    queryString.parse(location.search)?.applicationType || "";
  const idComposeEmailCead = queryString.parse(
    location.search
  )?.idToComposeEmail;

  const [visibleInternalMessage, setVisibleInternalMessage] = useState(false);
  const [draftMessagePaginationResponse, setDraftMessagePaginationResponse] =
    useState();

  //ckeditor
  const [editorData, setEditorData] = useState("");
  // const [data, setData] = useState();

  const [editorReference, setEditorReference] = useState("");
  const [instanceEditorData, setInstanceEditorData] = useState("");
  const [toolBar, setToolBar] = useState(false);
  const [show, setShow] = useState(false);
  const [activeId, setActiveId] = useState([]);
  const [subject, setSubject] = useState("");
  const [draftAttatchments, setDraftMessages] = useState([]);

  const [participantToOpenDropdown, setParticiapantToOpenDropdown] =
    useState(false);
  const [participantCCOpenDropdown, setParticiapantCCOpenDropdown] =
    useState(false);
  const [participantBCCOpenDropdown, setParticiapantBCCOpenDropdown] =
    useState(false);
  const [referenceDropDown, setReferenceDropDown] = useState();

  const [selectedMessageParticipants, setSelectedMessageParticipants] =
    useState([]);
  const [selectedMessageParticipantsCC, setSelectedMessageParticipantsCC] =
    useState([]);
  const [selectedMessageParticipantsBCC, setSelectedMessageParticipantsBCC] =
    useState([]);

  // const [mouseMovement, setMouseMovement] = useState(false);
  const [getAutoDraftResponse, setAutoDraftResponse] = useState();
  const [draftPaginationResponse] = useState();
  const [autoDraftBody, setAutoDraftBody] = useState();
  const [progress, setProgress] = useState(0);
  const [progressInternal, setProgressInternal] = useState(0);
  const [infiniteScroll, setInfiniteScroll] = useState(false);

  //  For windowclose popup
  const [closeWindow, setCloseWindow] = useState("newEmail");

  const [newContactResponse, setNewContactResponse] = useState([]);

  const [
    selectedInstanceMessageParticipants,
    setSelectedInstanceMessageParticipants,
  ] = useState([]);
  const [
    selectedInstanceMessageParticipantsCC,
    setSelectedInstanceMessageParticipantsCC,
  ] = useState([]);
  const [
    selectedInstanceMessageParticipantsBCC,
    setSelectedInstanceMessageParticipantsBCC,
  ] = useState([]);

  const [internalFormDropdown, setInternalFormDropdown] = useState(false);
  const [visibleParticipantBCC, setVisibleParticipantBCC] = useState(false);
  const [visibleParticipantCC, setVisibleParticipantCC] = useState(false);
  //Check this comment
  //By kamal Comment
  //dropdown data
  const [messageParticipants, setMessageParticipants] = useState([]);
  const [messageParticipantsCC, setMessageParticipantsCC] = useState([]);
  const [messageParticipantsBCC, setMessageParticipantsBCC] = useState([]);
  const [messageParticipantsFrom, setMessageParticipantsFrom] = useState([]);
  const [currentSelectedMailMessage, setCurrentSelectedMailMessage] = useState(
    {}
  );
  const [referenceListing, setReferenceListing] = useState([]);
  const [filterMessageParticipants, setFilterMessageParticipants] = useState(
    []
  );
  const [filterMessagePartcipiantsCc, setFilterMessageParticipantsCc] =
    useState([]);
  const [filterMessageParticipantsBCc, setFilterMessageParticipantsBCc] =
    useState([]);
  const [filterReference, setFilterReference] = useState([]);

  //show or hide dropdown
  const [visibleInstanceParticipantBCC, setVisibleInstanceParticipantBCC] =
    useState(false);
  const [visibleInstanceParticipantCC, setVisibleInstanceParticipantCC] =
    useState(false);
  const [instanceMessageParticipants, setInstanceMessageParticipants] =
    useState([]);
  const [instanceMessageParticipantsCC, setInstanceMessageParticipantsCC] =
    useState([]);
  const [instanceMessageParticipantsBCC, setInstanceMessageParticipantsBCC] =
    useState([]);
  const [visiblePriority, setVisibilePriority] = useState(false);
  const [visibleInternalCkEditor, setVisibleInternalCkEditor] = useState(false);
  //declare type to,cc, reference
  const [mailMessageCenterType, setMailMessageCenterType] = useState([]);
  const [filterParticipantType, setFilterParticipantType] = useState("");
  const [allParticipants, setAllParticipants] = useState([]);
  const [allParticipantsEmail, setAllParticipantsEmail] = useState([]);

  //modal visibility
  const [addNewEmailModalVisible, setAddNewEmailModalVisible] = useState(false);
  const [confirmNewEmail, setConfirmNewEmail] = useState(false);
  //for checkmark on priority listing
  const [showcheckMark, setCheckMark] = useState(false);
  const [addNewEmailText, setAddNewEmailText] = useState("");

  //search cc, to, Bcc, reference
  const [value, setSearchKeyword] = useState();
  const [showBusinessModal, setBusinessModal] = useState(false);
  const [searchValueTo, setSearchValueTo] = useState("");
  const [searchValueCc, setSearchValueCc] = useState("");
  const [searchValueBCC, setSearchValueBCC] = useState("");
  const [searchValueReference, setSearchValueReference] = useState("");
  const [referenceValue, setReferencevalue] = useState("");
  const [selectedParticipantTos, setSelectedParticipantTos] = useState([]);
  const [hiddenOptions, setHiddenOptions] = useState([]);
  const [hiddenReferenceOptions, setHiddenReferenceOptions] = useState([]);
  // console.log(selectedParticipantTos, "selectedParticipantTos");
  //customize dropdown and scroll
  const [cursor, setCursor] = useState(0);
  const [attatchments, setAttatchments] = useState([]);
  const [internalAttatchments, setInternalAttatchemnts] = useState([]);

  const hideDropDownTo = () => setParticiapantToOpenDropdown(false);

  //for checking addnewquickmail from composerEmailDrawer
  const [messageFromCompose, setMessageFromCompose] = useState("ComposeEmail");

  //current page for infinite scroll
  const [currentPage, setCurrentPage] = useState(1);
  const [currentReferencePage, setCurrentReferencePage] = useState(1);

  //for quick mail
  const [notFoundCompany, setNotFoundCompany] = useState();
  const [companyCurrentPage, setCompanyCurrentPage] = useState(1);
  const [personId, setPersonId] = useState();
  const [companyId, setCompanyId] = useState();
  const [disableSavedButton, setDisableSaveButton] = useState(true);
  const [notFoundValue, setNotFoundValue] = useState("");
  const [allPersonListingState, setAllPersonListingState] = useState([]);
  const [allCompanyListingState, setAllCompanyListingState] = useState([]);
  const [newValuePersonCompany, setNewValuePersonCompany] = useState(false);
  const [newCompanyValue, setNewCompanyValue] = useState(false);
  const [attachmentValues, setAttachmentValues] = useState("compose");
  const [emailValue, setEmailValue] = useState("");

  //get div height of to and cc
  const [toHeight, setToHeight] = useState(38);
  const [ccHeight, setCCHeight] = useState(38);
  const bc = new BroadcastChannel("draftPagination");
  const [hideButton, setHideButton] = useState(false);
  const [draftDisabledButton, setDraftDisableButton] = useState(false);
  const [newQuickCompanyValue, setNewQuickCompanyValue] = useState("");
  // const [signature, setSignature] = useState("<p>Signature</p>");

  const gridIframe = useRef(null);

  function info() {
    Modal.confirm({
      title: `Are you sure if you want to add new Email "${addNewEmailText}"`,
      okText: "Yes",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      cancelText: "No",
      onOk() {
        setAddNewEmailModalVisible(true);
        setConfirmNewEmail(false);
      },
      onCancel() {},
    });
  }

  function bodyVal() {
    Modal.warning({
      title: `Please add description on Internal Message Section`,
      // cancelText:"ok",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      onOk() {
        // const editor = document.getElementById("internal_message_tinyMce")
        // tinymce.get('internal_message_tinyMce').focus()
        // tinymce.execCommand('mceFocus',true,'internal_message_tinyMce');
        // editor.focus()
        // addEventListener("click",function(){editor.focus()})
      },
    });
  }

  useEffect(() => {
    if (composeEmailStatus === "successful") {
      form.setFieldsValue({
        participantTos: [],
        uploadedFileDtos: [],
        instanceUploadedFileDtos: [],
        // instanceParticipantCCs: [],
        // instanceParticipantTos: [],

        // participantCCs: [],
        // participantBCCs: [],
        // mailMessageReferenceDtos: [],
        // subject: "",
      });
      // setInstanceEditorData("");
    }
  }, [composeEmailStatus]);

  const onClickTo = (participant) => {
    setAddNewEmailText("");
    setFilterMessageParticipants([]);
    let newParticipantArr = form.getFieldsValue().participantTos
      ? [...form.getFieldsValue().participantTos]
      : [];
    if (!newParticipantArr.includes(participant?.emailAddress)) {
      newParticipantArr.push(participant.emailAddress);
    }

    form.setFieldsValue({
      participantTos: newParticipantArr,
    });
    // // changeParticipantTable(participant, "To");
    // // changeParticipantsEmailDtos("To");

    // setSearchValueTo("");
    // setMessageParticipants([]);
    // setFilterMessageParticipants([]);
    // setFilterParticipantType("");
  };

  const searchResultRef = useRef(null);
  const searchResultRefCC = useRef(null);
  const searchResultReffBCC = useRef(null);
  const searchResultReference = useRef(null);

  // const scrollIntoView = (position, paritcipantType) => {
  //   console.log("position", position);
  //   if (paritcipantType === "To") {
  //     searchResultRef.current?.parentNode?.scrollTo({
  //       top: position,
  //       behavior: "smooth",
  //     });
  //     console.log("hey scroll View");
  //   } else if (paritcipantType === "CC") {
  //     searchResultRefCC.current?.parentNode?.scrollTo({
  //       top: position,
  //       behavior: "smooth",
  //     });
  //   } else if (paritcipantType === "BCC") {
  //     searchResultReffBCC.current?.parentNode?.scrollTo({
  //       top: position,
  //       behavior: "smooth",
  //     });
  //   } else if (paritcipantType === "REF") {
  //     searchResultReference.current?.parentNode?.scrollTo({
  //       top: position,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  //for email Attatchments
  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgress,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
    });
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      setAttatchments(alteredResponse);
      let olduploadedFileDtos = form.getFieldValue().uploadedFileDtos
        ? [...form.getFieldValue().uploadedFileDtos]
        : [];

      let newUploadedFileDtos = [...olduploadedFileDtos];
      newUploadedFileDtos.push(alteredResponse);
      // uploadedFileDtos[i] = e;

      form.setFieldsValue({
        uploadedFileDtos: newUploadedFileDtos,
      });
      // onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  // const alertUser = (event) => {
  //   event.preventDefault();
  //   event.returnValue = "";
  // };

  //for internal Attacthmemnts
  const uploadPhotosInternal = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFileInternal({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgressInternal,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
      // setFileList([]);
    });
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      setInternalAttatchemnts(alteredResponse);
      let olduploadedFileDtos = form.getFieldValue().instanceUploadedFileDtos
        ? [...form.getFieldValue().instanceUploadedFileDtos]
        : [];

      let newUploadedFileDtos = [...olduploadedFileDtos];
      newUploadedFileDtos.push(alteredResponse);
      // uploadedFileDtos[i] = e;

      form.setFieldsValue({
        instanceUploadedFileDtos: newUploadedFileDtos,
      });
      // onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };
  // console.log(currentSelectedMailMessage)
  const [checkAllInternalEmails, setCheckAllInternalEmails] = useState(false);

  const displayMessages = (status) => {
    let signatureBody = !isEmpty(signatureList) ? signatureList?.body : "";
    let participantTypeFrom =
      currentSelectedMailMessage?.applicationTypeDetails?.from[0];
    let participantTypeTos = !isEmpty(
      currentSelectedMailMessage?.applicationTypeDetails?.tos
    )
      ? currentSelectedMailMessage?.applicationTypeDetails?.tos.map(
          (typeTos) => {
            return { emailAddress: typeTos.emailAddress };
          }
        )
      : [];
    let participantTypeCCs = !isEmpty(
      currentSelectedMailMessage?.applicationTypeDetails?.cCs
    )
      ? currentSelectedMailMessage?.applicationTypeDetails?.cCs.map(
          (typeTos) => {
            return { emailAddress: typeTos.emailAddress };
          }
        )
      : [];

    const participantTos = getParticipantsInfo(participantTypeTos);
    const partipantsCCs = getParticipantsInfo(participantTypeCCs);
    if (status !== "reSend") {
      if (status === "composeDraft") {
        form.setFieldsValue({
          participantFrom: messageParticipantsFrom[0],
        });
        setEditorData(currentSelectedMailMessage.body);
      } else {
        let data = `From:${
          participantTypeFrom?.emailAddress
        }  <br> To:${participantTos} ${
          partipantsCCs && `CC:${partipantsCCs}`
        } <br>  Date:${
          currentSelectedMailMessage?.creationTime &&
          moment(currentSelectedMailMessage?.creationTime).format("MM/DD/YYYY")
        },${moment(currentSelectedMailMessage?.creationTime).format(
          "LT"
        )} <br> ${currentSelectedMailMessage.body} <br>`;
        setEditorData("<br/><br/><hr>" + data);
      }
      if (status === "emailForwardSend") {
        let data = `On: ${moment(
          currentSelectedMailMessage?.creationTime
        ).format("MM/DD/YYYY")},${moment(
          currentSelectedMailMessage?.creationTime,
          "HH:mm:ss"
        ).format("LT")} ${
          (currentSelectedMailMessage?.companyName
            ? currentSelectedMailMessage?.applicationTypeDetails.from[0]
                .companyName
            : "",
          currentSelectedMailMessage?.applicationTypeDetails.from[0]
            .emailAddress)
        }  wrote: <br> ${currentSelectedMailMessage?.body}`;
        setEditorData("<br/><br/><hr>" + data);
      }
    } else if (status === "reSend") {
      let data = `${currentSelectedMailMessage?.body}`;
      setEditorData("<br/><br/><hr>" + data);
    } else {
      let data = `Date:${
        currentSelectedMailMessage?.creationTime &&
        moment(currentSelectedMailMessage?.creationTime).format("LLLL")
      } <br> ${currentSelectedMailMessage?.body}`;
      setEditorData("<br/><br/><hr>" + data);
    }
  };
  // console.log(editorData)
  //actionForCeadEmailCompose

  useEffect(() => {
    if (!isEmpty(draftMessagesResponse)) {
      if (!isEmpty(mailMessageCenterPagination)) {
        setDraftMessagePaginationResponse(
          mailMessageCenterPagination?.items[0]
        );
      } else {
      }
    }
  }, [draftMessagesResponse, mailMessageCenterPagination]);

  useEffect(() => {
    if (isEmpty(draftMessagesResponse)) return;
    setAutoDraftResponse(draftMessagesResponse);
    // console.log("draftMessagesResponse", draftMessagesResponse);
  }, [draftMessagesResponse]);

  useEffect(() => {
    let toCompose = document.querySelector(".compose--to");
    const resize_ob = new ResizeObserver(function (entries) {
      let rect = entries[0].contentRect;
      let height = rect.height;
      setToHeight(height);
    });
    resize_ob.observe(toCompose);
  }, []);

  useEffect(() => {
    let toCompose = document.querySelector(".compose--cc");
    const resize_ob = new ResizeObserver(function (entries) {
      let rect = entries[0].contentRect;
      let height = rect.height;
      setCCHeight(height);
    });
    resize_ob.observe(toCompose);
  }, []);

  // useEffect(() => {
  //   if (isEmpty(getAutoDraftResponse) || isEmpty(composeEmailResp)) {
  //     window.onbeforeunload = (event) => {
  //       const e = event || window.event;
  //       e.preventDefault();
  //       if (e) {
  //         e.returnValue = "";
  //       }
  //       return "";
  //     };
  //   }
  // }, [getAutoDraftResponse, composeEmailResp]);

  const emailListingInfinteScroll = (event, type, addNewEmailText) => {
    setInfiniteScroll(true);
    if (isEmpty(addNewEmailText)) return;
    if (type !== "Ref") {
      // console.log("to", "Scroll to");
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = Math.round(event.target.scrollTop);
      // console.log("setMax scroll", currentScroll, maxScroll);
      if (currentScroll === maxScroll) {
        // console.log("you reached to bottom");
        let MaxResultCount = pageSize;
        let SkipCount = currentPage * pageSize + 10;
        // console.log(
        //   "sssssssss",
        //   SkipCount,
        //   participantTypeEmailAddressesListing?.totalCount
        // );

        if (participantTypeEmailAddressesListing?.totalCount > SkipCount) {
          // console.log("datascrolling down");
          getParticipantListingPagination(MaxResultCount, SkipCount, true);
        }
      }
    } else {
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = Math.round(event.target.scrollTop);
      if (currentScroll === maxScroll) {
        let MaxResultCount = pageSize;
        let SkipCount = currentReferencePage * pageSize + 10;
        if (
          referenceListingResponse?.totalCount > SkipCount ||
          !referenceListingLoading
        ) {
          getReferencePagination(MaxResultCount, SkipCount, true);
        }
      }
    }
  };

  // console.log("searchResultRef", searchResultRef);

  // console.log("setAddNewEmailText", addNewEmailText);
  // useEffect(() => {
  //   if (searchValueTo) {
  //     if (cursor < 0 || cursor > messageParticipants.length) {
  //       return () => {};
  //     }
  //     if (!isEmpty(searchResultRef) && filterMessageParticipants.length > 0) {
  //       let listItems = !isEmpty(
  //         Array?.from(searchResultRef?.current?.children)
  //       )
  //         ? Array?.from(searchResultRef?.current?.children)
  //         : "";
  //       !isEmpty(listItems) &&
  //         listItems[cursor] &&
  //         scrollIntoView(listItems[cursor].offsetTop, "To");
  //     }
  //   } else if (searchValueCc && !isEmpty(filterMessagePartcipiantsCc)) {
  //     if (cursor < 0 || !searchResultRefCC) {
  //       return () => {};
  //     }

  //     let listItems = !isEmpty(
  //       Array?.from(searchResultRefCC?.current?.children)
  //     )
  //       ? Array?.from(searchResultRefCC?.current?.children)
  //       : "";
  //     !isEmpty(listItems) &&
  //       listItems[cursor] &&
  //       scrollIntoView(listItems[cursor].offsetTop, "CC");
  //   } else if (searchValueBCC) {
  //     if (
  //       cursor < 0 ||
  //       cursor > messageParticipantsBCC.length ||
  //       !searchResultReffBCC
  //     ) {
  //       return () => {};
  //     }

  //     let listItems = !isEmpty(
  //       Array?.from(searchResultReffBCC?.current?.children)
  //     )
  //       ? Array?.from(searchResultReffBCC?.current?.children)
  //       : "";
  //     !isEmpty(listItems) &&
  //       listItems[cursor] &&
  //       scrollIntoView(listItems[cursor].offsetTop, "BCC");
  //   } else if (searchValueReference && !isEmpty(filterReference)) {
  //     if (
  //       cursor < 0 ||
  //       cursor > filterReference?.items?.length ||
  //       !searchResultReference
  //     ) {
  //       return () => {};
  //     }

  //     let listItems = Array?.from(searchResultReference?.current?.children);
  //     !isEmpty(listItems) &&
  //       listItems[cursor] &&
  //       scrollIntoView(listItems[cursor].offsetTop, "REF");
  //   }
  // }, [
  //   cursor,
  //   searchValueBCC,
  //   searchValueTo,
  //   searchValueCc,
  //   searchValueReference,
  // ]);

  // useEffect(() => {
  //   if (searchValueReference) {
  //     if (referenceListingResponse) {
  //       setReferenceListing(referenceListingResponse);
  //     }
  //   }
  // }, [referenceListingResponse, searchValueReference]);

  // const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const pageSize = 110;
  // console.log(addNewEmailText)
  const getParticipantListingPagination = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getParticipantTypeEmailAddressesListing(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword: addNewEmailText,
        },
        loadMore
      )
    );
  };

  const getReferencePagination = (
    MaxResultCount = 110,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getReferenceListing(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword: searchValueReference,
        },
        loadMore
      )
    );
  };

  const fetchMoreData = () => {
    // console.log("api needed");
    getParticipantListingPagination(pageSize, currentPage * pageSize, true);
  };

  useEffect(() => {
    // if (isEmpty(filterMessageParticipants) && filterParticipantType === "To") {
    if (filterParticipantType === "To") {
      if (addNewEmailText) {
        // console.log("first case is running")
        //here goes the code
        setInfiniteScroll(false);
        const timeoutId = setTimeout(
          () => getParticipantListingPagination(100, 0, false),
          150
        );
        return () => clearTimeout(timeoutId);
      }
    } else if (
      // isEmpty(filterMessagePartcipiantsCc) &&
      filterParticipantType === "CC"
    ) {
      // console.log("second case is running")
      setInfiniteScroll(false);
      const timeoutId = setTimeout(
        () => getParticipantListingPagination(100, 0, false),
        150
      );
      return () => clearTimeout(timeoutId);
    } else if (
      isEmpty(setFilterMessageParticipantsBCc) &&
      filterParticipantType === "BCC"
    ) {
      // console.log("third case is running")
      setInfiniteScroll(false);
      const timeoutId = setTimeout(
        () => getParticipantListingPagination(100, 0, false),
        150
      );
      return () => clearTimeout(timeoutId);
    } else if (filterParticipantType === "REF") {
      // console.log("forth case is running")
      setInfiniteScroll(false);
      const timeoutId = setTimeout(
        () => getReferencePagination(200, 0, false),
        150
      );
      return () => clearTimeout(timeoutId);

      // dispatch(getReferenceListing({searchkeyWord: searchValueReference}))
    }
    if (isEmpty(addNewEmailText)) {
      // setFilterMessageParticipants([]);
      // setFilterMessageParticipantsBCc([]);
      // setFilterMessageParticipantsCc([]);
      // setFilterReference([]);
    }
  }, [addNewEmailText, searchValueCc, searchValueBCC, searchValueReference]);
  // }, [addNewEmailText, searchValueCc, searchValueBCC, filterParticipantType]);

  // useEffect(() => {
  //   if (
  //     !isEmpty(searchValueTo) &&
  //     searchValueTo !== "Enter" &&
  //     searchValueTo !== "Tab"
  //   ) {
  //     setParticiapantToOpenDropdown(true);
  //   } else {
  //     setParticiapantToOpenDropdown(false);
  //   }
  // }, [searchValueTo]);

  useEffect(() => {
    if (!isEmpty(searchValueCc)) {
      setParticiapantCCOpenDropdown(true);
    } else {
      setParticiapantCCOpenDropdown(false);
    }
  }, [searchValueCc]);

  useEffect(() => {
    if (!isEmpty(searchValueBCC)) {
      setParticiapantBCCOpenDropdown(true);
    } else {
      setParticiapantBCCOpenDropdown(false);
    }
  }, [searchValueBCC]);

  useEffect(() => {
    document.title = "Compose Message";
    dispatch(getEmailAddressesFromWithBehalfOfListing());

    if (isEmpty(allParticipants)) {
      fetchAllParticipants();
    }

    if (isEmpty(organizerEventPriorityListing)) {
      dispatch(getOrganizerEventPriorityListings());
    }

    if (isEmpty(mailMessageParticipantTypeListing)) {
      dispatch(getMailMessageParticipantTypeListing());
    }

    if (isEmpty(mailMessageParticipationStatusListing)) {
      dispatch(getMailMessageParticipantStatusListing());
    }

    // if (isEmpty(participantTypeEmailAddressesListing)) {
    // 	dispatch(getParticipantTypeEmailAddressesListing());
    // }
  }, []);

  useEffect(() => {
    if (mailBodyGetDetailsResponse) {
      // let data = mailMessageCenterPagination.items.filter((item) => item.id === selectedMailTypeAndMailData.selectedMail.id);
      setLoading(false);
      setCurrentSelectedMailMessage(mailBodyGetDetailsResponse);
    } else {
      setLoading(true);
      setCurrentSelectedMailMessage({});
    }
  }, [mailBodyGetDetailsResponse]);

  // useEffect(() => {
  //   if (isEmpty(tenderBasicPaginationResponse?.items)) return;
  //   let newCurrentPage = tenderBasicPaginationResponse.items.length / pageSize;
  //   setCurrentPage(newCurrentPage);
  // }, [tenderBasicPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(participantTypeEmailAddressesListing.items)) {
      let participantFrom = storage.getItem("userId");
      // console.log(participantFrom)
      let newAllParticipants =
        participantTypeEmailAddressesListing?.items?.filter(
          (obj) => obj.employeeEmailAddressHolderId !== participantFrom
        );
      setAllParticipantsEmail(newAllParticipants);
    } else {
      setAllParticipantsEmail([]);
      setMessageParticipants([]);
      setMessageParticipantsBCC([]);
      setMessageParticipantsCC([]);
    }
  }, [
    participantTypeEmailAddressesListing,
    participantTypeEmailAddressesListingLoading,
  ]);

  useEffect(() => {
    if (!isEmpty(messageParticipants) && filterParticipantType == "To") {
      setFilterMessageParticipants(messageParticipants);
    } else if (
      !isEmpty(messageParticipantsCC) &&
      filterParticipantType == "CC"
    ) {
      setFilterMessageParticipantsCc(messageParticipantsCC);
    } else if (
      !isEmpty(messageParticipantsBCC) &&
      filterParticipantType == "BCC"
    ) {
      setFilterMessageParticipantsBCc(messageParticipantsBCC);
    } else if (
      !isEmpty(referenceListing.items) &&
      filterParticipantType == "REF"
    ) {
      setFilterReference(referenceListing.items);
    } else {
      // setFilterMessageParticipants([]);
      // setFilterReference([]);
      // setFilterMessageParticipantsBCc([]);
      // setFilterMessageParticipantsCc([])
    }
  }, [
    messageParticipants,
    messageParticipantsCC,
    messageParticipantsBCC,
    referenceListing,
  ]);

  useEffect(() => {
    if (!isEmpty(selectedMailTypeAndMailData.selectedMail)) {
      const selected = selectedMailTypeAndMailData.selectedMail;
      if (
        selected?.applicationTypeSystemName &&
        selected?.applicationTypeSystemName === "InstanceMessage"
      ) {
        dispatch(
          emailGetDetails({
            mailMessageParticipantTableId:
              selected?.mailMessageParticipantTableId,
            mailMessageCenterId: selected?.instanceMainMessageCenterId,
            latestInstanceMessageId: selected?.latestInstanceMessageId,
            applicationType: selected?.applicationTypeSystemName,
          })
        );
      } else {
        dispatch(
          emailGetDetails({
            mailMessageParticipantTableId:
              selected?.mailMessageParticipantTableId,
            mailMessageCenterId: selected?.id,
            latestInstanceMessageId: selected.latestInstanceMessageId,
            applicationType: selected?.applicationTypeSystemName,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(mailMessageParticipationStatusListing)) {
      let newMailMessageCenterType =
        mailMessageParticipationStatusListing.filter(
          (status) =>
            status.systemName === "Normal" ||
            status.systemName === "Pending" ||
            status.systemName === "Archived" ||
            status.systemName === "Timer"
        );

      setMailMessageCenterType(newMailMessageCenterType);
    }
  }, [mailMessageParticipationStatusListing]);

  // useEffect(() => {
  //   console.log("persistTenderMailDataResp: ", persistTenderMailDataResp)
  //   if(persistTenderMailDataResp) {
  //     form.setFieldsValue({
  //       subject: `${persistTenderMailDataResp?.subject}`
  //     })
  //   }
  // }, [persistTenderMailDataResp, form])

  useEffect(() => {
    if (!isEmpty(emailAddressFromWithBehalfListing)) {
      emailAddressFromWithBehalfListing.requestEmployeeEmails?.map(
        (data, index) => {
          setMessageParticipantsFrom((prevState) => [
            ...prevState,
            data.emailAddress,
          ]);
        }
      );
      // console.log(mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]?.emailAddress)
      // console.log(emailAddressFromWithBehalfListing?.requestEmployeeEmails)
      // let incomingEmail = emailAddressFromWithBehalfListing?.requestEmployeeEmails?.filter((email)=>email?.emailAddress === mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]?.emailAddress)
      // const emailIdIncoming = incomingEmail?.[0]
      // console.log(emailAddressFromWithBehalfListing?.requestEmployeeEmails[0]?.emailAddress,"inside useEffect")
      // console.log(mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]?.emailAddress)
      setEmailid(
        emailAddressFromWithBehalfListing?.requestEmployeeEmails?.[0]
          ?.emailAddress !==
          mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]
            ?.emailAddress && applicationTypeStatus !== "newEmail"
          ? emailAddressFromWithBehalfListing?.requestEmployeeEmails?.[1]
              ?.emailId
          : emailAddressFromWithBehalfListing?.requestEmployeeEmails?.[0]
              ?.emailId
      );
      if (applicationTypeStatus === "newEmail") {
        form.setFieldsValue({
          // participantFrom: [defaultEmailAddress || ""],
          participantFrom: [defaultEmailAddress ?? ""],
        });
      }
    }
  }, [emailAddressFromWithBehalfListing, applicationTypeStatus]);

  useEffect(() => {
    if (applicationTypeStatus === "documentShare") {
      form.setFieldsValue({
        participantFrom: messageParticipantsFrom[0],
      });
    }
  }, [applicationTypeStatus, messageParticipantsFrom]);

  useEffect(() => {
    // if (isEmpty(currentSelectedMailMessage?.applicationTypeDetails?.from))
    //   return;
    if (!isEmpty(currentSelectedMailMessage)) {
      let attactmentsOnAction =
        currentSelectedMailMessage?.mailMessageAttachmentDtos;
      if (
        !isEmpty(attactmentsOnAction) &&
        applicationTypeStatus !== "documentShare"
      ) {
        {
          params !== "?applicationType=newEmail" &&
            params !== "?applicationType=reply" && //add
            form.setFieldsValue({
              uploadedFileDtos: attactmentsOnAction,
            });
        }
      }
      let participantTypeTos =
        currentSelectedMailMessage?.applicationTypeDetails?.from &&
        currentSelectedMailMessage?.applicationTypeDetails?.from?.map(
          (typeTos) => {
            return typeTos.emailAddress;
          }
        );

      let participantTypeTosResend =
        currentSelectedMailMessage?.applicationTypeDetails?.tos &&
        currentSelectedMailMessage?.applicationTypeDetails?.tos?.map(
          (typeTos) => {
            return typeTos.emailAddress;
          }
        );

      let participantTypeCCs = !isEmpty(
        currentSelectedMailMessage?.applicationTypeDetails?.cCs
      )
        ? currentSelectedMailMessage?.applicationTypeDetails?.cCs?.map(
            (typeTos) => {
              return typeTos.emailAddress;
            }
          )
        : [];
      let participantTypeFrom =
        currentSelectedMailMessage?.applicationTypeDetails?.from[0] &&
        currentSelectedMailMessage?.applicationTypeDetails?.from[0]
          .emailAddress;
      let contactToCC =
        participantTypeTosResend &&
        participantTypeTosResend?.concat(participantTypeCCs);

      let showParticipantForm =
        contactToCC &&
        contactToCC?.filter((val) => messageParticipantsFrom?.includes(val));

      participantTypeCCs =
        participantTypeCCs &&
        participantTypeCCs?.filter(
          (val) => !messageParticipantsFrom?.includes(val)
        );

      let filterParticipantCcs =
        participantTypeCCs &&
        participantTypeCCs?.map(
          (filteredCcs) => {
            return filteredCcs;
          }
          // filteredCcs.emailAddress !==F
          //   messageParticipantsFrom[0]?.emailAddress || participantTypeFrom
        );

      let participantTypeTosResends =
        participantTypeTosResend &&
        participantTypeTosResend?.filter(
          (val) => !messageParticipantsFrom.includes(val)
        );

      let filterTos =
        participantTypeTosResends &&
        participantTypeTosResends?.map(
          (filterTos) => {
            return filterTos;
          }
          // filteredCcs.emailAddress !==F
          //   messageParticipantsFrom[0]?.emailAddress || participantTypeFrom
        );

      let newCCs =
        filterParticipantCcs && filterParticipantCcs.concat(filterTos);

      const tosLength =
        mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.length;
      // if (participantTypeFrom && applicationTypeStatus) {
      //   form.setFieldsValue({
      //     participantTos: participantTypeTosResend?.map((typeTos) => {
      //       return typeTos.emailAddress;
      //     }),
      //     participantCCs: participantTypeCCs.map((typeTos) => {
      //       return typeTos.emailAddress;
      //     }),
      //   });
      // }

      //check applicationstatus and display messages
      // console.log(participantTypeTos)
      if (applicationTypeStatus) {
        if (applicationTypeStatus === "reply") {
          displayMessages(applicationTypeStatus);
          form.setFieldsValue({
            participantCCs: [],
            participantTos: !isEmpty(participantTypeTos)
              ? participantTypeTos?.map((typeTos) => {
                  return typeTos;
                })
              : newContactResponse,
          });
        } else if (applicationTypeStatus === "replyall") {
          form.setFieldsValue({
            participantCCs: newCCs?.map((typeTos) => {
              return typeTos;
            }),
            participantTos: participantTypeTos?.map((typeTos) => {
              return typeTos;
            }),
            uploadedFileDtos: [],
          });

          displayMessages(applicationTypeStatus);
        } else if (applicationTypeStatus === "composeDraft") {
          displayMessages(applicationTypeStatus);
          form.setFieldsValue({
            participantCCs: filterParticipantCcs?.map((typeTos) => {
              return typeTos;
            }),
            participantTos: participantTypeTosResend?.map((typeTos) => {
              return typeTos;
            }),
          });
        } else if (applicationTypeStatus === "forward") {
          form.setFieldsValue({
            participantTos: [],
          });
          displayMessages(applicationTypeStatus);
        } else if (applicationTypeStatus === "emailForwardSend") {
          displayMessages(applicationTypeStatus);
          form.setFieldsValue({
            participantCCs: filterParticipantCcs?.map((typeTos) => {
              return typeTos;
            }),
            participantTos: participantTypeTosResend?.map((typeTos) => {
              return typeTos;
            }),
          });
        } else if (applicationTypeStatus === "reSend") {
          form.setFieldsValue({
            participantCCs: filterParticipantCcs.map((typeTos) => {
              return typeTos;
            }),
            participantTos: participantTypeTosResend?.map((typeTos) => {
              return typeTos;
            }),
            participantFrom: messageParticipantsFrom[0],
          });

          displayMessages(applicationTypeStatus);
        }
      } else {
        setEditorData("");
      }

      if (
        applicationTypeStatus === "emailForwardSend" ||
        applicationTypeStatus === "forward"
      ) {
        // console.log(messageParticipantsFrom)
        // const ownEmail = messageParticipantsFrom.includes(mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.map((emails)=>(emails?.emailAddress)) )
        // const ownEmail = mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.map((emails)=>{
        //   emails?.emailAddress?.filter((emails)=>emails === messageParticipantsFrom)
        // })
        // console.log(ownEmail)

        form.setFieldsValue({
          //   participantFrom: messageParticipantsFrom.includes(
          //     mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]
          //       ?.emailAddress
          //   )
          //     ? mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]
          //         ?.emailAddress
          //     : defaultEmailAddress,
          // });
          participantFrom:
            defaultEmailAddress ||
            mailBodyGetDetailsResponse?.applicationTypeDetails?.from?.[0]
              ?.emailAddress,
        });
      }

      if (
        applicationTypeStatus !== "newEmail" &&
        applicationTypeStatus !== "tenderModule"
      ) {
        form.setFieldsValue({
          subject: `${currentSelectedMailMessage.subject}`,
        });
      }

      if (
        applicationTypeStatus === "replyall" ||
        applicationTypeStatus === "reply"
      ) {
        // console.log(mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]?.emailAddress)
        form.setFieldsValue({
          participantFrom: messageParticipantsFrom.includes(
            mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]
              ?.emailAddress
          )
            ? mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]
                ?.emailAddress
            : defaultEmailAddress,
        });
      }
      // console.log(mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]?.emailAddress)
      if (applicationTypeStatus === "newEmail") {
        form.setFieldsValue({
          // participantFrom:  emailAddressFromWithBehalfListing?.requestEmployeeEmails[0]?.emailAddress,
        });
      }

      if (
        applicationTypeStatus !== "newEmail" &&
        applicationTypeStatus !== "documentShare" &&
        !isEmpty(currentSelectedMailMessage.mailMessageReferenceDtos)
      ) {
        form.setFieldsValue({
          mailMessageReferenceDtos:
            currentSelectedMailMessage.mailMessageReferenceDtos?.map(
              (reference) => {
                return reference.referenceCode;
              }
            ),
        });
        setEditorReference(
          currentSelectedMailMessage?.mailMessageReferenceDtos?.map(
            (data) => data?.referenceCode
          )
        );
      }

      if (!isEmpty(participantTypeTos)) setVisibleParticipantCC(true);
    }
  }, [
    applicationTypeStatus,
    currentSelectedMailMessage,
    messageParticipantsFrom,
  ]);

  useEffect(() => {
    if (!isEmpty(filterParticipantType)) {
      if (filterParticipantType === "To") {
        setMessageParticipants(allParticipantsEmail);
        setParticiapantCCOpenDropdown(false);
      } else if (filterParticipantType === "CC") {
        setMessageParticipantsCC(allParticipantsEmail);
        // setParticiapantToOpenDropdown(false);
      } else if (filterParticipantType === "BCC") {
        setMessageParticipantsBCC(allParticipantsEmail);
      }
    }
  }, [filterParticipantType]);

  useEffect(() => {
    if (!isEmpty(composeEmailResp)) {
      // setInstanceEditorData("");
      bc.postMessage(composeEmailResp);
    }
  }, [composeEmailResp]);

  useEffect(() => {
    if (!isEmpty(composeEmailResp) && closeWindow === "newEmail") {
      // setTimeout(() => {
      //   history.push("/home");
      //   window.location.reload();
      // }, 1000);

      setTimeout(() => {
        window.close();
        window.location.reload();
      }, 1000);
    }
  }, [composeEmailResp, closeWindow]);
  useEffect(() => {
    if (!isEmpty(composeCircularEmailResp)) {
      window.close();
    }
  }, [composeCircularEmailResp]);
  // console.log(contactCompanyDetailAddResponse)
  useEffect(() => {
    if (!isEmpty(contactQuickAddEmailRespsonse)) {
      let emailAddressResp = contactQuickAddEmailRespsonse?.email;
      setAddNewEmailModalVisible(false);
      if (filterParticipantType == "To") {
        form.setFieldsValue({
          participantTos: !isEmpty(form.getFieldsValue()?.participantTos)
            ? [...form.getFieldsValue()?.participantTos, emailAddressResp]
            : [emailAddressResp],
        });

        const uniqueMail = new Set([
          ...form.getFieldsValue()?.participantTos,
          emailAddressResp,
        ]);
        setNewContactResponse([...uniqueMail]);
      } else if (filterParticipantType == "CC") {
        form.setFieldsValue({
          participantCCs: !isEmpty(form.getFieldsValue()?.participantCCs)
            ? [...form.getFieldsValue()?.participantCCs, emailAddressResp]
            : [emailAddressResp],
        });
      }
    }
  }, [contactQuickAddEmailRespsonse]);

  // email signature details
  let participantFrom = storage.getItem("userId");
  const [signatureList, setSignatureList] = useState("");

  // console.log(emailid)
  const {
    activeEmailSignatureDetailsResponse,
    activeEmailSignatureDetailsLoading,
  } = useSelector((state) => state.profile);

  // console.log(emailAddressFromWithBehalfListing?.requestEmployeeEmails?.length)

  const selectedParticipantFrom = form.getFieldsValue()?.participantFrom;
  const selectedParticipantFromDetails =
    emailAddressFromWithBehalfListing?.requestEmployeeEmails?.filter(
      (emailid) => emailid?.emailAddress == selectedParticipantFrom
    );
  const selectedparticipantsFromId =
    selectedParticipantFromDetails?.[0]?.emailId;
  // console.log(selectedParticipantFrom,selectedparticipantsFromId)

  useEffect(() => {
    dispatch(
      activeEmailSignatureDetails({
        UserId: participantFrom,
        emailId: selectedparticipantsFromId,
      })
    );
  }, [selectedparticipantsFromId]);

  const ref = React.createRef();

  // console.log(applicationTypeStatus,"editordata")
  useEffect(() => {
    if (activeEmailSignatureDetailsResponse) {
      let signatureData = activeEmailSignatureDetailsResponse?.body
        ? `<div id="signatureData" ref={${ref}}><br/>${activeEmailSignatureDetailsResponse?.body}</div>`
        : "";
      const sign = localStorage.getItem("signatureData");
      // console.log("sign", sign);
      let participantTypeFrom =
        currentSelectedMailMessage?.applicationTypeDetails?.from[0];
      let participantTypeTos = !isEmpty(
        currentSelectedMailMessage?.applicationTypeDetails?.tos
      )
        ? currentSelectedMailMessage?.applicationTypeDetails?.tos.map(
            (typeTos) => {
              return { emailAddress: typeTos.emailAddress };
            }
          )
        : [];
      let participantTypeCCs = !isEmpty(
        currentSelectedMailMessage?.applicationTypeDetails?.cCs
      )
        ? currentSelectedMailMessage?.applicationTypeDetails?.cCs.map(
            (typeTos) => {
              return { emailAddress: typeTos.emailAddress };
            }
          )
        : [];

      const participantTos = getParticipantsInfo(participantTypeTos);
      const partipantsCCs = getParticipantsInfo(participantTypeCCs);
      let data = `From:${
        participantTypeFrom?.emailAddress
      }  <br> To:${participantTos} ${
        partipantsCCs && `CC:${partipantsCCs}`
      } <br>  Date:${
        currentSelectedMailMessage?.creationTime &&
        moment(currentSelectedMailMessage?.creationTime).format("MM/DD/YYYY")
      },${moment(currentSelectedMailMessage?.creationTime).format("LT")} <br> ${
        currentSelectedMailMessage.body
      } <br>`;
      if (
        applicationTypeStatus !== "newEmail" &&
        applicationTypeStatus !== "documentShare" &&
        activeEmailSignatureDetailsResponse?.body !== null
      ) {
        setEditorData("" + signatureData + "<br/><br/><hr>" + data);
        setSignatureList(activeEmailSignatureDetailsResponse);
      } else if (
        applicationTypeStatus === "newEmail" ||
        applicationTypeStatus === "documentShare"
      ) {
        setEditorData("" + signatureData);

        // setEditorData(editorData);
        // setSignature(signatureData);
        setSignatureList(activeEmailSignatureDetailsResponse);
      }
      // console.log(data)
    }
  }, [activeEmailSignatureDetailsResponse, applicationTypeStatus]);

  const keyboardNavigation = (e, participantType) => {
    if (!isEmpty(e)) {
      if (participantType == "To") {
        if (
          !isEmpty(filterMessageParticipants) &&
          !participantTypeEmailAddressesListingLoading
        ) {
          let participant = filterMessageParticipants[e];
          setAddNewEmailText("");
          onClickTo(participant);
        }
      } else if (participantType === "Cc" && e) {
        let participant = filterMessagePartcipiantsCc[e];
        setAddNewEmailText("");
        setFilterMessageParticipantsCc([]);
        let newParticipantArr = form.getFieldsValue().participantCCs
          ? [...form.getFieldsValue().participantCCs]
          : [];
        if (!newParticipantArr?.includes(participant?.emailAddress)) {
          newParticipantArr?.push(participant?.emailAddress);
        }
        const filteredParticipantArr = newParticipantArr.filter(
          (value) => value !== null && value !== undefined
        );
        form.setFieldsValue({
          // participantCCs: newParticipantArr,
          participantCCs: filteredParticipantArr,
        });
      } else if (participantType === "BCC" && e) {
        let participant = filterMessageParticipantsBCc[e];
        setAddNewEmailText("");
        setFilterMessageParticipantsBCc([]);
        let newParticipantArr = form.getFieldsValue().participantBCCs
          ? [...form.getFieldsValue().participantBCCs]
          : [];
        newParticipantArr.push(participant.emailAddress);

        form.setFieldsValue({
          participantBCCs: newParticipantArr,
        });
      } else if (participantType === "REF" && e) {
        let participant = filterReference[e];
        setAddNewEmailText("");
        setFilterReference([]);
        let newParticipantArr = form.getFieldsValue().mailMessageReferenceDtos
          ? [...form.getFieldsValue().mailMessageReferenceDtos]
          : [];
        // console.log(participant.code)
        if (!newParticipantArr.includes(participant?.code)) {
          newParticipantArr.push(participant?.code);
        }
        setEditorReference(newParticipantArr.join());
        const filteredParticipantArr = newParticipantArr.filter(
          (value) => value !== null && value !== undefined
        );
        form.setFieldsValue({
          // mailMessageReferenceDtos: newParticipantArr,
          mailMessageReferenceDtos: filteredParticipantArr,
        });
        if (isEmpty(form.getFieldsValue().subject)) {
          form.setFieldsValue({
            subject: participant?.code,
          });
        }
      }
    }
  };

  const debounced = useCallback(
    debounce((body) => {
      if (applicationTypeStatus === "newEmail") {
        if (isEmpty(getAutoDraftResponse)) {
          let draftbody = {
            id: !isEmpty(draftMessagesResponse)
              ? draftPaginationResponse?.id
              : "",
            ...body,

            emailMessageDraftParticipantWithEmaileDtos:
              body.emailMessageParticipantWithTypeDtos,
          };
          dispatch(
            composeDraftMessages(
              draftbody,
              selectedMailTypeAndMailData.typeSystemName
            )
          );
        } else if (!isEmpty(getAutoDraftResponse)) {
          let draftbody = {
            ...body,
            emailMessageDraftParticipantWithEmaileDtos:
              body.emailMessageParticipantWithTypeDtos,
            id: getAutoDraftResponse.id,
          };
          dispatch(
            composeDraftMessages(
              draftbody,
              selectedMailTypeAndMailData.typeSystemName
            )
          );
        }
      } else if (applicationTypeStatus === "composeDraft") {
        let draftbody = {
          ...body,
          emailMessageDraftParticipantWithEmaileDtos:
            body.emailMessageParticipantWithTypeDtos,
          id: draftPaginationResponse?.id,
        };
        dispatch(
          composeDraftMessages(
            draftbody,
            selectedMailTypeAndMailData.typeSystemName
          )
        );
      }
    }, 180000),
    [getAutoDraftResponse, applicationTypeStatus, draftPaginationResponse]
  );

  let defaulPriority = organizerEventPriorityListing?.filter(
    (fav) => fav.name === "Normal"
  );

  const autoDraft = (e, participant) => {
    let newParticipantArrTo = form.getFieldsValue().participantTos
      ? [...form.getFieldsValue().participantTos]
      : [];
    let newParticipantArrCC = form.getFieldsValue().participantCCs
      ? [...form.getFieldsValue().participantCCs]
      : [];
    let newParticipantArrBCC = form.getFieldsValue().participantBCCs
      ? [...form.getFieldsValue().participantBCCs]
      : [];
    let newParticipantRef = form.getFieldsValue().mailMessageReferenceDtos
      ? [...form.getFieldsValue().mailMessageReferenceDtos]
      : [];
    let newParticipantFrom = form.getFieldsValue().participantFrom
      ? [form.getFieldsValue().participantFrom]
      : [];

    let body = {
      mailMessageReferenceDtos: newParticipantRef?.map((code) => {
        return {
          referenceCode: code,
        };
      }),

      priorityId: defaulPriority[0]?.id,
      title: form.getFieldValue().subject,
      body: editorData,
      emailMessageParticipantWithTypeDtos: {
        participantFrom: {
          emailAddress: newParticipantFrom.toString(),
        },

        participantTos: newParticipantArrTo?.map((participants) => {
          return {
            emailAddress: participants,
          };
        }),
        participantCCs: newParticipantArrCC?.map((participants) => {
          return {
            emailAddress: participants,
          };
        }),
        participantBCCs: newParticipantArrBCC?.map((participants) => {
          return {
            emailAddress: participants,
          };
        }),
      },
    };

    setAutoDraftBody(body);

    debounced(body);
  };

  const handleFilter = (value, type) => {
    const searchWord = value;
    function escapeRegExp(string) {
      return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
    }
    if (type === "To") {
      setSearchValueTo(searchWord);
      const newFilter = messageParticipants.filter((value) => {
        if (searchWord === "") return true;
        const escaped = escapeRegExp(searchWord);
        const re = new RegExp(`.*${escaped}$`);
        return re.test(value.emailAddress);
      });

      setFilterMessageParticipants(newFilter);
    } else if (type === "Cc") {
      const newFilter = messageParticipantsCC.filter((value) => {
        if (searchWord === "") return true;
        const escaped = escapeRegExp(searchWord);
        const re = new RegExp(`.*${escaped}$`);
        return re.test(value.emailAddress);
      });
      setFilterMessageParticipantsCc(newFilter);
    } else if (type == "BCc") {
      const newFilter = messageParticipantsBCC.filter((value) => {
        if (searchWord === "") return true;
        const escaped = escapeRegExp(searchWord);
        const re = new RegExp(`.*${escaped}$`);
        return re.test(value.emailAddress);
      });

      setFilterMessageParticipantsBCc(newFilter);
    } else {
      const newFilter = referenceListing?.items?.filter((value) => {
        if (searchWord === "") return true;
        const escaped = escapeRegExp(searchWord);
        const re = new RegExp(`.*${escaped}$`);
        return re.test(value.code);
      });

      // setFilterReference(newFilter);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      participantTos: getparticipants.map(
        (data) => data.emailAddressSearchDto.personName
      ),
    });
  }, [getparticipants]);

  useEffect(() => {
    if (!isEmpty(allParticipantsEmail)) {
      changeParticipantsEmailDtos("To");
      changeParticipantsEmailDtos("CC");
      changeParticipantsEmailDtos("BCC");
    } else {
      setFilterMessageParticipants([]);
      setFilterMessageParticipantsBCc([]);
      setFilterMessageParticipantsCc([]);
      setFilterReference([]);
    }
  }, [allParticipantsEmail, applicationTypeStatus]);

  useEffect(() => {
    if (!isEmpty(allParticipants)) {
      setInstanceMessageParticipants(allParticipants);
      setInstanceMessageParticipantsCC(allParticipants);
      setInstanceMessageParticipantsBCC(allParticipants);
    }
  }, [allParticipants]);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      let participantFrom = storage.getItem("userId");
      let allUsers = resp.data.filter(
        (obj) => obj.appUserId !== participantFrom
      );
      setAllParticipants(allUsers);
    }
  };
  const changeParticipantsEmailDtos = (participantType) => {
    let participantsTo = form.getFieldsValue().participantTos
      ? form.getFieldsValue().participantTos
      : [];

    let participantsCC = form.getFieldsValue().participantCCs
      ? form.getFieldsValue().participantCCs
      : [];
    let participantsBCC = form.getFieldsValue().participantBCCs
      ? form.getFieldsValue().participantBCCs
      : [];

    let getReferenceFieldValue = form.getFieldsValue().mailMessageReferenceDtos
      ? form.getFieldsValue().mailMessageReferenceDtos
      : [];

    let newMessageParticipantDtos = [];
    if (participantType === "To") {
      participantsTo.map((email) => {
        messageParticipants.map((participant) => {
          if (email === participant.emailAddress) {
            newMessageParticipantDtos.push({
              participantUserId: participant.employeeEmailAddressHolderId,
              emailAddress: participant.emailAddress,
            });
          }
        });
      });
    } else if (participantType === "CC") {
      participantsCC.map((email) => {
        messageParticipantsCC.map((participant) => {
          if (email === participant.emailAddress) {
            newMessageParticipantDtos.push({
              participantUserId: participant.employeeEmailAddressHolderId,
              emailAddress: participant.emailAddress,
            });
          }
        });
      });
    } else if (participantType === "REF") {
    } else {
      participantsBCC.map((email) => {
        messageParticipantsBCC.map((participant) => {
          if (email === participant.emailAddress) {
            newMessageParticipantDtos.push({
              participantUserId: participant.employeeEmailAddressHolderId,
              emailAddress: participant.emailAddress,
            });
          }
        });
      });
    }

    let newMessagePariticipantsTo = [];
    let newMessagePariticipantsCC = [];
    let newMessagePariticipantsBCC = [];

    let allSelectedParticipantsBCC = [];
    let allSelectedParticipantsTo = [];
    let allSelectedParticipantsCC = [];
    if (participantType === "CC") {
      allSelectedParticipantsBCC = participantsTo.concat(participantsCC);
      allSelectedParticipantsTo = participantsBCC.concat(participantsCC);
    } else if (participantType === "BCC") {
      allSelectedParticipantsCC = participantsTo.concat(participantsBCC);
      allSelectedParticipantsTo = participantsCC.concat(participantsBCC);
    } else {
      allSelectedParticipantsBCC = participantsCC.concat(participantsTo);
      allSelectedParticipantsCC = participantsBCC.concat(participantsTo);
    }

    allParticipantsEmail.map((participant) => {
      let checkSelected = false;
      allSelectedParticipantsTo.map((selectedParticipant) => {
        if (selectedParticipant === participant.emailAddress) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipantsTo.push(participant);
      }
    });

    allParticipantsEmail.map((participant) => {
      let checkSelected = false;
      allSelectedParticipantsBCC.map((selectedParticipant) => {
        if (selectedParticipant === participant.emailAddress) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipantsBCC.push(participant);
      }
    });

    allParticipantsEmail.map((participant) => {
      let checkSelected = false;
      allSelectedParticipantsCC.map((selectedParticipant) => {
        if (selectedParticipant === participant.emailAddress) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipantsCC.push(participant);
      }
    });

    if (participantType === "CC") {
      setSelectedMessageParticipantsCC(newMessageParticipantDtos);
      setMessageParticipants(newMessagePariticipantsTo);
      setMessageParticipantsBCC(newMessagePariticipantsBCC);
    } else if (participantType === "BCC") {
      setSelectedMessageParticipantsBCC(newMessageParticipantDtos);
      setMessageParticipants(newMessagePariticipantsTo);
      setMessageParticipantsCC(newMessagePariticipantsCC);
    } else {
      setSelectedMessageParticipants(newMessageParticipantDtos);
      setMessageParticipantsCC(newMessagePariticipantsCC);
      setMessageParticipantsBCC(newMessagePariticipantsBCC);
    }
  };

  const changeParticipantTable = (participant, participantType) => {
    let newParticipantArr = [];
    if (participant) {
      if (participantType === "To") {
        // setActiveId((activeId) => [...activeId, participant?.id]);
        newParticipantArr = form.getFieldsValue().participantTos
          ? [...form.getFieldsValue().participantTos]
          : [];
      } else if (participantType === "CC") {
        setActiveId((activeId) => [...activeId, participant?.id]);
        newParticipantArr = form.getFieldsValue().participantCCs
          ? [...form.getFieldsValue().participantCCs]
          : [];
      } else {
        setActiveId((activeId) => [...activeId, participant?.id]);
        newParticipantArr = form.getFieldsValue().participantBCCs
          ? [...form.getFieldsValue().participantBCCs]
          : [];
      }

      if (newParticipantArr.includes(participant?.emailAddress)) {
        remove(newParticipantArr, (obj) => obj === participant?.emailAddress);
      } else {
        newParticipantArr.push(participant?.emailAddress);
      }
      if (participantType === "To") {
        form.setFieldsValue({
          participantTos: newParticipantArr,
        });
      } else if (participantType === "CC") {
        form.setFieldsValue({
          participantCCs: newParticipantArr,
        });
      } else {
        form.setFieldsValue({
          participantBCCs: newParticipantArr,
        });
      }
    }
  };

  const changeReferenceTable = (reference) => {
    let referenceTags = [];
    if (filterReference.length > 0) {
      referenceTags = form.getFieldsValue().mailMessageReferenceDtos
        ? [...form.getFieldsValue().mailMessageReferenceDtos]
        : [];
      referenceTags.push(reference.code);
      setEditorReference(referenceTags.join(", "));
      if (reference) {
        form.setFieldsValue({
          mailMessageReferenceDtos: referenceTags,
        });
        if (isEmpty(form.getFieldsValue().subject)) {
          form.setFieldsValue({
            subject: referenceTags,
          });
        }
      }
    }
  };

  useEffect(() => {
    // let iframeItem = gridIframe.current;
    // const pEditor = document.getElementsByClassName("tox-edit-area__iframe")
    // console.log(pEditor,"kkkkkkkkk")
    const testElements = document.getElementsByClassName(
      "tox-edit-area__iframe"
    );
    // const subElement = testElements.getElementById("editor-blocks")[0]
  }, []);

  const changeInstanceParticipantsDtos = (option, participantType) => {
    let newMessageParticipantDtos = option.map(
      (participant) => participant.value
    );
    // console.log(form.getFieldsValue().instanceParticipantTos)
    // console.log(instanceMessageParticipants)
    // console.log(newMessageParticipantDtos)
    let newMessagePariticipantsTo = [];
    let newMessagePariticipantsCC = [];
    let newMessagePariticipantsBCC = [];
    // console.log(form.getFieldsValue().instanceParticipantTos)
    let participantsTo = form.getFieldsValue().instanceParticipantTos;
    // ? form.getFieldsValue().instanceParticipantTos
    // : [];
    let participantsCC = form.getFieldsValue().instanceParticipantCCs;
    // ? form.getFieldsValue().instanceParticipantCCs
    // : [];
    let participantsBCC = form.getFieldsValue().instanceParticipantBCCs;
    // ? form.getFieldsValue().instanceParticipantBCCs
    // : [];

    let allSelectedParticipantsBCC = [];
    let allSelectedParticipantsTo = [];
    let allSelectedParticipantsCC = [];
    if (participantType === "CC") {
      allSelectedParticipantsBCC = participantsTo?.concat(participantsCC);
      allSelectedParticipantsTo = participantsBCC?.concat(participantsCC);
    } else if (participantType === "BCC") {
      allSelectedParticipantsCC = participantsTo?.concat(participantsBCC);
      allSelectedParticipantsTo = participantsCC?.concat(participantsBCC);
    } else {
      allSelectedParticipantsBCC = participantsCC?.concat(participantsTo);
      allSelectedParticipantsCC = participantsBCC?.concat(participantsTo);
    }

    allParticipants?.map((participant) => {
      let checkSelected = false;
      allSelectedParticipantsTo?.map((selectedParticipant) => {
        if (selectedParticipant === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipantsTo.push(participant);
      }
    });
    // console.log(allParticipants,participantType)
    allParticipants?.map((participant) => {
      let checkSelected = false;
      allSelectedParticipantsBCC?.map((selectedParticipant) => {
        if (selectedParticipant === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipantsBCC?.push(participant);
      }
    });

    allParticipants.map((participant) => {
      let checkSelected = false;
      allSelectedParticipantsCC?.map((selectedParticipant) => {
        if (selectedParticipant === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipantsCC?.push(participant);
      }
    });

    if (participantType === "CC") {
      setSelectedInstanceMessageParticipantsCC(newMessageParticipantDtos);
      setInstanceMessageParticipants(newMessagePariticipantsTo);
      setInstanceMessageParticipantsBCC(newMessagePariticipantsBCC);
    } else if (participantType === "BCC") {
      setSelectedInstanceMessageParticipantsBCC(newMessageParticipantDtos);
      setInstanceMessageParticipants(newMessagePariticipantsTo);
      setInstanceMessageParticipantsCC(newMessagePariticipantsCC);
    } else {
      setSelectedInstanceMessageParticipants(newMessageParticipantDtos);
      setInstanceMessageParticipantsCC(newMessagePariticipantsCC);
      setInstanceMessageParticipantsBCC(newMessagePariticipantsBCC);
    }
  };

  useEffect(() => {
    if (applicationTypeStatus === "documentShare" && documentShareData) {
      form.setFieldsValue({
        mailMessageReferenceDtos: documentShareData.referenceCode,
        subject: documentShareData.notes,
      });

      if (!isEmpty(documentShareData.uploadedFileDtos)) {
        form.setFieldsValue({
          uploadedFileDtos: documentShareData.uploadedFileDtos,
        });
      }
    }
  }, [
    applicationTypeStatus,
    currentSelectedMailMessage,
    messageParticipantsFrom,
  ]);

  useEffect(() => {
    if (applicationTypeStatus === "tenderModule") {
      // console.log("I'm CONTENT Module", persistTenderMailDataResp?.content?.content)

      form.setFieldsValue({
        // participantTos: ["test@gmail.com", "newmailsearch@gmail.com"],
        participantFrom: [defaultEmailAddress ?? ""],
        participantTos: persistTenderMailDataResp?.tos ?? [],
        subject: `${persistTenderMailDataResp?.subject}`,
        mailMessageReferenceDtos: persistTenderMailDataResp?.refCode?.map(
          (reference) => {
            return reference.referenceCode;
          }
        ),
      });

      setEditorData(persistTenderMailDataResp?.content?.content ?? "");
    }
  }, [applicationTypeStatus, persistTenderMailDataResp, defaultEmailAddress]);

  const toStringFunc = (args) => {
    return `${args}`;
  };
  const onChangeCompleted = () => {
    setHideButton(!hideButton);
    setDraftDisableButton(!draftDisabledButton);
  };

  const onFinish = async (centerTypeSystemName, isClose) => {
    if (
      (form.getFieldValue()["instanceParticipantTos"]?.length > 0 ||
        form.getFieldValue()["instanceParticipantCCs"]?.length > 0) &&
      instanceEditorData === ""
      //form.getFieldValue()["instanceEditorData"] === undefined
    ) {
      message.error("Internal message body cannot be empty");
    } else {
      try {
        let values = await form.validateFields();

        // console.log(
        //   "form.getFieldsValue().instanceParticipantTosValues",
        //   values
        // );

        // var codeBlock = `<div style=\"font-family: Helvetica; font-size: 10pt; \ font-style: italic;\color: #007afe;\ font-weight: 600;\">Ref: ${editorReference} </div><br>
        // <i style=\"color:red;\">{MailReferenceNumber}</i>`;
        var codeBlock = `<i style=\"\">{MailReferenceNumber}</i>`;
        let participant = Array.isArray(values.participantFrom)
          ? values.participantFrom
          : values.participantFrom.split();
        let defaulPriority = organizerEventPriorityListing?.filter(
          (fav) => fav.name === "Normal"
        );
        let bodyData =
          centerTypeSystemName !== "draft" &&
          applicationTypeStatus !== "newEmail"
            ? ` ${editorData} ${codeBlock}`
            : applicationTypeStatus === "newEmail"
            ? `${editorData} ${codeBlock}`
            : editorData;
        // let dom =new DOMParser().parseFromString(editorData, "text/html")
        // const wordsCount = dom.body.textContent
        // const signatureWordscount = activeEmailSignatureDetailsResponse?.body
        // const wordsCountLength = wordsCount.length
        // const removeP = signatureWordscount.replace(/<p>/gi, "");
        // const removePLength = toStringFunc(removeP).length - 3

        let body = {
          id:
            applicationTypeStatus === "composeDraft"
              ? selectedMailTypeAndMailData?.selectedMail?.id
              : "" || draftMessagesResponse
              ? draftMessagesResponse.id
              : "",

          mailMessageReferenceDtos: values.mailMessageReferenceDtos?.map(
            (code) => {
              return {
                referenceCode: code,
              };
            }
          ),
          mailMessageParticipantTableId:
            applicationTypeStatus !== "newEmail"
              ? selectedMailTypeAndMailData?.selectedMail
                  ?.mailMessageParticipantTableId
              : "",
          priorityId: !isEmpty(values.priorityId)
            ? values.priorityId
            : defaulPriority[0].id,
          title: Array.isArray(values.subject)
            ? values.subject.join()
            : values.subject,
          body: bodyData,
          ...(applicationTypeStatus === "tenderModule" && {
            IsCircularPlanning: true,
            CircularPlanningReferenceId:
              persistTenderMailDataResp?.refCode?.[0]?.id || null,
          }),
          emailMessageParticipantWithTypeDtos: {
            participantFrom: {
              emailAddress: participant[0],
            },

            participantTos: values.participantTos?.map((participants) => {
              return {
                emailAddress: participants?.trim(),
              };
            }),

            participantCCs: values.participantCCs?.map((participants) => {
              return {
                emailAddress: participants,
              };
            }),
            participantBCCs: values.participantBCCs?.map((participants) => {
              return {
                emailAddress: participants,
              };
            }),
          },
          emailMessageStatusName: centerTypeSystemName,
          emailMessageAttachmentDtos: values.uploadedFileDtos,
          emailInstanceMessageAddDto:
            !isEmpty(selectedInstanceMessageParticipants) || instanceEditorData
              ? {
                  description: instanceEditorData,
                  emailInstanceMessageStatusName: centerTypeSystemName,
                  emailInstanceMessageParticipantTypeDtos: {
                    // participantToIds: selectedInstanceMessageParticipants,
                    // participantCCIds: selectedInstanceMessageParticipantsCC,
                    participantBCCIds: selectedInstanceMessageParticipantsBCC,
                    participantToIds: values?.instanceParticipantTos,
                    participantCCIds: values?.instanceParticipantCCs,
                  },
                  emailInstanceMessageAttachmentDtos:
                    values.instanceUploadedFileDtos,
                }
              : null,
          // circularEmail:hideButton? true : false,
        };

        if (applicationTypeStatus === "ceadInvoice") {
          body = {
            ...body,
            applicationId: idComposeEmailCead,
            applicationType: "tenderceadinvoice",
          };
        }

        //circular email case
        if (hideButton === true) {
          if (!isEmpty(selectedInstanceMessageParticipants)) {
            if (instanceEditorData?.length < 1) {
              bodyVal();
            } else {
              if (window.confirm("Do you want to send circular mail ? ")) {
                dispatch(
                  circularComposeEmail(
                    body,
                    selectedMailTypeAndMailData.typeSystemName,
                    isClose
                  )
                );
              } else {
                // console.log("no button clicked");
              }
            }
          } else {
            if (window.confirm("Do you want to send circular mail ? ")) {
              dispatch(
                circularComposeEmail(
                  body,
                  selectedMailTypeAndMailData.typeSystemName,
                  isClose
                )
              );
            } else {
              // console.log("no button clicked");
            }
          }
          // dispatch(circularComposeEmail(body, selectedMailTypeAndMailData.typeSystemName,isClose))
        }
        //non circular email case
        else {
          if (!isEmpty(selectedInstanceMessageParticipants)) {
            if (instanceEditorData?.length < 1) {
              bodyVal();
            } else {
              if (centerTypeSystemName !== "draft") {
                // console.log("case 1",body)
                dispatch(
                  composeEmail(
                    body,
                    selectedMailTypeAndMailData.typeSystemName,
                    isClose
                  )
                );
              } else if (applicationTypeStatus !== "newEmail") {
                let draftbody = {
                  ...body,
                  emailMessageDraftParticipantWithEmaileDtos:
                    body.emailMessageParticipantWithTypeDtos,
                  id: selectedMailTypeAndMailData?.selectedMail?.id,
                };
                // console.log("case 2",draftbody)
                dispatch(
                  composeDraftMessages(
                    draftbody,
                    selectedMailTypeAndMailData.typeSystemName
                  )
                );
              } else {
                let draftbody = {
                  ...body,
                  emailMessageDraftParticipantWithEmaileDtos:
                    body.emailMessageParticipantWithTypeDtos,
                };
                // console.log("case 3", draftbody);
                dispatch(
                  composeDraftMessages(
                    draftbody,
                    selectedMailTypeAndMailData.typeSystemName
                  )
                );
              }
            }
          } else {
            if (
              participant[0] !==
                mailBodyGetDetailsResponse?.applicationTypeDetails?.tos?.[0]
                  ?.emailAddress &&
              applicationTypeStatus !== "newEmail" &&
              centerTypeSystemName != "draft"
            ) {
              // if(window.confirm("Are you sure you want to send from the next email?")){
              if (centerTypeSystemName !== "draft") {
                let newBody = {
                  ...body,
                  replyOfMessageCenterId:
                    selectedMailTypeAndMailData?.selectedMail
                      ?.instanceMainMessageCenterId,
                };
                // console.log("case 4",body,applicationTypeStatus)
                dispatch(
                  composeEmail(
                    //amitadd
                    // applicationTypeStatus === "reply" ? newBody : body,
                    applicationTypeStatus === "reply" ||
                      applicationTypeStatus === "replyall"
                      ? newBody
                      : body,
                    selectedMailTypeAndMailData.typeSystemName,
                    isClose
                  )
                );
              } else if (applicationTypeStatus !== "newEmail") {
                let draftbody = {
                  ...body,
                  emailMessageDraftParticipantWithEmaileDtos:
                    body.emailMessageParticipantWithTypeDtos,
                  id: selectedMailTypeAndMailData?.selectedMail?.id,
                };
                // console.log("case 5" , draftbody)
                dispatch(
                  composeDraftMessages(
                    draftbody,
                    selectedMailTypeAndMailData.typeSystemName
                  )
                );
              } else {
                let draftbody = {
                  ...body,
                  emailMessageDraftParticipantWithEmaileDtos:
                    body.emailMessageParticipantWithTypeDtos,
                };
                // console.log("case 6", draftbody);
                dispatch(
                  composeDraftMessages(
                    draftbody,
                    selectedMailTypeAndMailData.typeSystemName
                  )
                );
              }
              // }else {
              //   console.log("cancelled")
              // }
            } else {
              if (centerTypeSystemName !== "draft") {
                let newBody = {
                  ...body,
                  replyOfMessageCenterId:
                    selectedMailTypeAndMailData?.selectedMail
                      ?.instanceMainMessageCenterId,
                };
                // console.log("case 7",body,selectedMailTypeAndMailData.typeSystemName,isClose)
                dispatch(
                  composeEmail(
                    //amitadd
                    // applicationTypeStatus === "reply" ? newBody : body,
                    applicationTypeStatus === "reply" ||
                      applicationTypeStatus === "replyall"
                      ? newBody
                      : body,
                    selectedMailTypeAndMailData.typeSystemName,
                    isClose
                  )
                );
              } else if (applicationTypeStatus !== "newEmail") {
                let draftbody = {
                  ...body,
                  emailMessageDraftParticipantWithEmaileDtos:
                    body.emailMessageParticipantWithTypeDtos,
                  id: selectedMailTypeAndMailData?.selectedMail?.id,
                };
                // console.log("case 8",draftbody)
                dispatch(
                  composeDraftMessages(
                    draftbody,
                    selectedMailTypeAndMailData.typeSystemName
                  )
                );
              } else {
                let draftbody = {
                  ...body,
                  emailMessageDraftParticipantWithEmaileDtos:
                    body.emailMessageParticipantWithTypeDtos,
                };
                // console.log("case 9", draftbody,selectedMailTypeAndMailData.typeSystemName);
                dispatch(
                  composeDraftMessages(
                    draftbody,
                    selectedMailTypeAndMailData.typeSystemName
                  )
                );
              }
            }
          }
        }

        if (!isEmpty(selectedInstanceMessageParticipants)) {
          if (instanceEditorData?.length > 1) {
            {
              !isClose &&
                form.setFieldsValue({
                  // participantTos: [],
                  participantCCs: [],
                  participantBCCs: [],
                });
            }
          }
        } else if (centerTypeSystemName != "draft") {
          {
            !isClose &&
              form.setFieldsValue({
                // participantTos: [],
                participantCCs: [],
                participantBCCs: [],
              });
          }
        }
        setAltEnt();
      } catch (errorInfo) {}
    }
  };

  useEffect(() => {
    if (AltEnt?.alt && AltEnt?.enter) {
      let newMailMessageCenterType = mailMessageCenterType.filter(
        (type) => type.systemName === "Normal"
      );
      // console.log(newMailMessageCenterType[0].systemName)
      onFinish(newMailMessageCenterType[0].systemName, false);
    }
    if (AltEnt?.length !== 0) {
      setTimeout(() => {
        setAltEnt([]);
      }, 5000);
    }
  }, [AltEnt]);

  const checkMark = (data) => {
    if (data) {
      organizerEventPriorityListing.map((priority, i) => {
        if (priority.name === data) {
          setCheckMark(true);
        }
      });
    }
  };

  const menu = () => (
    <Menu>
      <Menu.Item
        key="2"
        onClick={() => {
          setVisibleParticipantBCC(!visibleParticipantBCC);
        }}
      >
        BCC
      </Menu.Item>
      {/* <Menu.Item
        key="3"
        onClick={() => {
          setVisibilePriority(!visiblePriority);
        }}
      >
        Priority
      </Menu.Item> */}
      <Menu.Item>
        <Button
          style={{
            border: "none",
            background: "transparent",
            boxShadow: "none",
          }}
          className="email-send-as save-continue"
          // overlay={menu}
          onClick={() => {
            let newMailMessageCenterType = mailMessageCenterType.filter(
              (type) => type.systemName === "Normal"
            );
            // console.log(newMailMessageCenterType[0].systemName)
            onFinish(newMailMessageCenterType[0].systemName, false);
          }}
          disabled={composeEmailResNotCloseLoading}
        >
          <div className="btn__chat">
            {composeEmailResNotCloseLoading ? antIcon : `Send & Continue`}
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );
  function destroyAll() {
    Modal.destroyAll();
  }

  const showConfirm = () => {
    setAddNewEmailText("");
    setSearchValueTo("");
    setFilterMessageParticipants([]);
    for (let i = 0; i < 1; i += 1) {
      Modal.error({
        content: "Invalid Email Address",
        onOk() {
          destroyAll();
          setConfirmNewEmail(false);
        },
      });
    }
  };

  const dropDown = (value, participants) => {
    if (value) {
      if ((participants = "TO")) {
        setParticiapantToOpenDropdown(true);
      }
    } else {
      setParticiapantToOpenDropdown(false);
    }
  };
  const formgetValue = form.getFieldValue();
  const params = useHistory().location.search;
  const attachmentPrevValue = () => {
    formgetValue.uploadedFileDtos = [];
  };

  function emailCheck() {
    Modal.confirm({
      title: "Are you sure you want to send email from the next email ?",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      onOk() {},
      onCancel() {},
    });
  }
  // const defaultOpenMail = applicationTypeStatus !==  "newEmail" ? true : false
  // applicationTypeStatus !==  "newEmail" &&
  // console.log(defaultOpenMail)
  const internalMailValues = instanceMessageParticipants?.map(
    (values) => values?.appUserId
  );
  // console.log(internalMailValues)
  useEffect(() => {
    if (visibleInternalMessage) {
      // console.log("checked all")
      if (!!checkAllInternalEmails) {
        form.setFieldsValue({
          instanceParticipantTos: internalMailValues,
        });
      } else {
        form.setFieldsValue({
          instanceParticipantTos: [],
          // instanceParticipantTos: internalMailValues,
        });
      }
      // setSelectedInstanceMessageParticipants(instanceMessageParticipants);
    }
  }, [checkAllInternalEmails]);

  // const handleUpdate = (value, editor) => {
  //   const length = editor?.getContent({ format: "text" }).length;
  //   console.log("length", length);
  // };

  let searchKeywordFormat = searchValueReference?.split("-");
  let searchKeywordFormatData = "";

  if (searchKeywordFormat?.length === 2) {
    searchKeywordFormatData = `${_.startCase(
      searchKeywordFormat?.[0]
    )}-${_.startCase(searchKeywordFormat?.[1].trim())}`;
  }

  useEffect(() => {
    if (referenceSecondaryAddIsSuccess) {
      // const timeoutId = setTimeout(() => {
      dispatch(getReferenceListing({ SearchKeyword: searchKeywordFormatData }));
      // }, 200);
    }
  }, [referenceSecondaryAddIsSuccess]);

  useEffect(() => {
    const idRef = referenceListingResponse?.items?.find((x) => {
      return x?.code == searchKeywordFormatData;
    });

    form.setFieldsValue({
      // mailMessageReferenceDtos: idRef?.code,
      subject:
        form.getFieldsValue()["subject"] === undefined
          ? searchKeywordFormatData
          : form.getFieldsValue()["subject"],
    });
  }, [referenceListingResponse?.items]);

  const handleDragStart = (event, index) => {
    console.log("target", index, event);

    event.dataTransfer.setData("index", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, target) => {
    // console.log("target", target, event);
    // const index = event.dataTransfer.getData('index');
    // const newData = [...sourceData];
    // const droppedItem = newData.splice(index, 1)[0];

    if (target === "source") {
      // setSourceData(newData);
    } else if (target === "target") {
      // setTargetData([...targetData, droppedItem]);
    }
  };
  const [form4] = Form.useForm();
  const { groupData, groupDataLoading, groupDataLoadingSuccess } = useSelector(
    (state) => state.createUpdateGroup
  );
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [selectedGroupDataToBeUpdated, setGroupDataToBeUpdate] = useState();
  const [selectTosNewCutGroup, setSelectTosNewCutGroup] = useState();
  const [selectCcsNewCutGroup, setSelectCcsNewCutGroup] = useState();

  let paramsForGroupListing = "SkipCount=0&MaxResultCount=100";
  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, []);

  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, [selectedGroupDataToBeUpdated, createGroupModal]);

  useEffect(() => {
    if (!isEmpty(selectTosNewCutGroup)) {
      form.setFieldsValue({
        instanceParticipantTos: selectTosNewCutGroup,
        instanceParticipantCCs: selectCcsNewCutGroup,
      });
    } else {
      form.setFieldsValue({
        instanceParticipantTos: [],
        instanceParticipantCCs: [],
      });
    }
  }, [selectTosNewCutGroup, selectCcsNewCutGroup]);

  useEffect(() => {
    if (!isEmpty(selectedGroupDataToBeUpdated)) {
      let userIdsForTo = [];
      let userIdsForCc = [];
      selectedGroupDataToBeUpdated?.userList?.map((x) => {
        if (x?.participantTypeDto?.id === 0) {
          userIdsForTo.push(x?.userId);
        } else {
          userIdsForCc.push(x?.userId);
        }
      });
      form4.setFieldsValue({
        groupName: selectedGroupDataToBeUpdated?.groupName,
        userListTo: userIdsForTo,
        userListCc: userIdsForCc,
      });
    }

    if (groupDataLoadingSuccess) {
      setCreateGroupModal(false);
      setSelectTosNewCutGroup();
    }
  });

  const menuGropCreate = () => (
    <Menu style={{ maxHeight: 250, overflow: "auto" }}>
      <Menu.Item
        key="Create Group"
        onClick={() => {
          setCreateGroupModal(true);
          setGroupDataToBeUpdate();
          form4.resetFields();
        }}
      >
        Create Group
      </Menu.Item>

      {groupData &&
        groupData?.map((x) => {
          return (
            <Menu.Item
              key={x?.id}
              onClick={() => {
                let userIdsForTo = [];
                let userIdsForCc = [];
                x?.userList?.map((x) => {
                  if (x?.participantTypeDto?.id === 0) {
                    userIdsForTo.push(x?.userId);
                  } else {
                    userIdsForCc.push(x?.userId);
                  }
                });
                setSelectTosNewCutGroup(userIdsForTo);
                setSelectCcsNewCutGroup(userIdsForCc);
              }}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{x?.groupName}</div>

              <EditOutlined
                style={{ paddingLeft: 15 }}
                onClick={() => {
                  setGroupDataToBeUpdate(x);
                  setCreateGroupModal(true);
                }}
              />
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const onGroupCreateFinish = (values) => {
    const tos = values?.userListTo?.map((x) => {
      return {
        userId: x,
        participantTypeId: 0,
      };
    });
    const ccs = values?.userListCc?.map((x) => {
      return {
        userId: x,
        participantTypeId: 1,
      };
    });
    const to_cc = [...tos, ...ccs];

    let apiDataForGroupCreation = {
      groupName: values?.groupName,
      userList: to_cc,
      isActive: true,
    };

    if (isEmpty(selectedGroupDataToBeUpdated)) {
      dispatch(actionForCreateGroup(apiDataForGroupCreation));
    } else {
      apiDataForGroupCreation = {
        ...apiDataForGroupCreation,
        id: selectedGroupDataToBeUpdated?.id,
      };
      dispatch(
        actionForUpdateGroup(
          apiDataForGroupCreation,
          selectedGroupDataToBeUpdated?.id
        )
      );
    }
  };

  useEffect(() => {
    if (
      !isEmpty(idComposeEmailCead) &&
      isEmpty(tenderCeadComposeEmailDetailsData)
    ) {
      dispatch(actionForCeadEmailCompose(idComposeEmailCead));
    }
    if (!isEmpty(tenderCeadComposeEmailDetailsData)) {
      form.setFieldsValue({
        participantTos:
          // tenderCeadComposeEmailDetailsData?.clientEmailAddressList,
          tenderCeadComposeEmailDetailsData?.ceaD_PRINCIPAL_EmailAddressList,
        participantFrom: defaultEmailAddress ? defaultEmailAddress : "",
        subject: tenderCeadComposeEmailDetailsData?.contract,
        mailMessageReferenceDtos: [
          tenderCeadComposeEmailDetailsData?.referenceDto?.referenceCode,
        ],
        // currentSelectedMailMessage.mailMessageReferenceDtos?.map(
        //   (reference) => {
        //     return reference.referenceCode;
        //   }
        // ),
      });
    }
  }, [
    idComposeEmailCead,
    tenderCeadComposeEmailDetailsData,
    applicationTypeStatus,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      mailMessageReferenceDtos: mailRefDtos,
    });
  }, [mailRefDtos]);

  const handleContextMenu = (event) => {
    event.preventDefault();

    if (event.target.outerText) {
      navigator.clipboard
        .writeText(event.target.outerText)
        .then(() => {
          message.success(`Copied "${event.target.outerText}" to clipboard`);
        })
        .catch((error) => {
          console.error("Copying failed:", error);
        });
    }
  };

  const handleChange = (selectedValues) => {
    console.log("selectedValues", selectedValues);

    setmailRefDto(selectedValues);
  };

  return (
    <>
      <Form
        name="dynamic_form_nest_item"
        className="composer-form"
        onFinish={onFinish}
        // onFinishFailed={(info) => console.log(info)}
        autoComplete="off"
        form={form}
        // onClick={() => {
        // 	setSearchKeyword('');
        // }}
      >
        <div
          className="mail-quick-message"
          onClick={(e) => {
            // setSearchValueCc('');
            setAddNewEmailText("");
            setFilterReference([]);
            setFilterMessageParticipantsBCc([]);
            setFilterMessageParticipants([]);
            setFilterMessageParticipantsCc([]);

            referenceDropDown && setReferenceDropDown(false);
            setCursor(0);
            // setFilterMessageParticipantsCc([]);
          }}
        >
          <div
            className="mail-message"
            style={{ width: `${visibleInternalMessage ? "65%" : "100%"}` }}
          >
            <Header className="email_compose__header">
              <Space>
                <div className="compose-action-wrapper">
                  <Button
                    style={{
                      border: "none",
                      background: "transparent",
                      boxShadow: "none",
                    }}
                    className="email-send-as send-close"
                    disabled={composeEmailRespLoading}
                    onClick={() => {
                      setSendMode(false);
                      setCloseWindow("newEmail");
                      let newMailMessageCenterType =
                        mailMessageCenterType.filter(
                          (type) => type.systemName === "Normal"
                        );
                      onFinish(newMailMessageCenterType[0].systemName, true);
                    }}
                  >
                    <div className="btn__chat">
                      {/* {composeEmailRespLoading ? ( */}
                      {composeEmailRespLoading ||
                      composeEmailResNotCloseLoading ? (
                        antIcon
                      ) : (
                        <Image src={send} preview={false} />
                      )}
                    </div>
                  </Button>
                  <Dropdown.Button
                    className="email-send-as"
                    overlay={menu}
                    onClick={() => {
                      let newMailMessageCenterType =
                        mailMessageCenterType.filter(
                          (type) => type.systemName === "Normal"
                        );
                      onFinish(newMailMessageCenterType[0].systemName, false);
                    }}
                  ></Dropdown.Button>
                  <Button
                    loading={draftMessageLoading}
                    className="save__icon bg-none"
                    onClick={() => {
                      onFinish("draft");
                      setCloseWindow("newDraft");
                    }}
                    style={{ boxShadow: "none", border: "none" }}
                    type="primary"
                    ghost
                    disabled={draftDisabledButton}
                  >
                    <Image
                      src="/assets/images/icons/save.svg"
                      className="save__icon"
                      preview={false}
                    />
                  </Button>
                  <Col className="switch-divv">
                    <Switch onChange={onChangeCompleted} checked={hideButton} />{" "}
                    Circular Email
                  </Col>
                </div>
              </Space>

              {/* <Button
							className="btn-collapse"
							type="link"
							onClick={() => {
								form.resetFields();
								setParticiapantToOpenDropdown(false);
								setParticiapantCCOpenDropdown(false);
								setParticiapantBCCOpenDropdown(false);
								// setApplicationTypeStatus('');
							}}
						>
							<span className="button__label">Collapse</span>
							<CaretRightOutlined />
						</Button> */}
              {/* <h4 className="email_compose__header--title">Compose Email</h4> */}

              <div
                className="flexend"
                style={{
                  width: 56,
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginRight: "5px",
                }}
              >
                <div
                  style={{
                    width: !visibleInternalMessage ? "30%" : "50%",
                    color: "black",
                  }}
                  className="hover aa__icon"
                  onClick={() => {
                    setToolBar(!toolBar);
                  }}
                >
                  <span>Aa</span>
                </div>
                <div
                  className="hover"
                  style={{ width: !visibleInternalMessage ? "30%" : "50%" }}
                >
                  <Upload
                    name="uploadedFiles"
                    multiple={true}
                    itemRender={(files) => {
                      <div style={{ display: "none" }}>{files}</div>;
                    }}
                    action={`${appBaseUrl}/file/uploadFile/`}
                    customRequest={uploadPhotos}
                  >
                    <img src={attachment} />
                  </Upload>
                </div>
                {!visibleInternalMessage && (
                  <div
                    className="remove-internal-msg hover"
                    style={{ width: "30%" }}
                    onClick={() =>
                      setVisibleInternalMessage(!visibleInternalMessage)
                    }
                  >
                    <Image src={composeInteral} preview={false} />
                  </div>
                )}
              </div>
            </Header>
            <div
              className="drawer__content overflow-hidden"
              style={{ padding: 0 }}
            >
              <div className="drawer__content--wrapper">
                <div
                  className="contact__content__wrapper"
                  style={{
                    width: `${visibleInternalMessage ? "65%" : "100%"}`,
                    position: "fixed",
                  }}
                >
                  <div className="email-text-input">
                    <div className="email-text">From</div>
                    <Form.Item
                      className="form__group"
                      name="participantFrom"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Select
                        bordered={false}
                        autoComplete="off"
                        onChange={(value, option) => {
                          setEmailid(option.emailid);
                        }}
                        // defaultOpen = {defaultOpenMail}
                        // defaultValue = "kamal.aryal@info.com.np"
                        // allowClear = {allowClearBol}
                      >
                        {emailAddressFromWithBehalfListing?.requestEmployeeEmails?.map(
                          (participant, j) => (
                            <Option
                              value={participant?.emailAddress}
                              key={j}
                              emailid={participant?.emailId}
                              // defaultValue = {participant?.emailAddress[1]}
                            >
                              {participant?.emailAddress}
                            </Option>
                          )
                        )}
                        {emailAddressFromWithBehalfListing?.employeeSharedAccessEmails?.map(
                          (participant, j) => (
                            <Option
                              value={participant?.emailAddress}
                              key={j}
                              emailid={participant?.emailId}
                              // defaultValue = {participant?.emailAddress[1]}
                            >
                              {participant?.emailAddress}
                              <span>
                                {" "}
                                <b>&nbsp;&nbsp;( Shared Corporate id)</b>
                              </span>
                            </Option>
                          )
                        )}
                        {emailAddressFromWithBehalfListing?.corporateSharedAccessEmails?.map(
                          (participant, j) => (
                            <Option
                              value={participant?.emailAddress}
                              key={j}
                              emailid={participant?.emailId}
                              // defaultValue = {participant?.emailAddress[1]}
                            >
                              {participant?.emailAddress}
                              <span>
                                {"    "}
                                <b>&nbsp;&nbsp;( Shared Personal id)</b>
                              </span>
                            </Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="email-text-input">
                    <div className="email-text">To</div>
                    <Form.Item
                      className="form__group compose--to"
                      name="participantTos"
                      rules={[
                        {
                          required: closeWindow === "newDraft" ? false : true,
                          message: "",
                        },
                      ]}
                    >
                      <Select
                        onContextMenu={handleContextMenu}
                        dropdownClassName="compose-reference-select-dropdown"
                        autoComplete="off"
                        onDeselect={(value) => {
                          if (isEmpty(emailValue)) {
                            console.log("if condition");
                          } else {
                            let newParticipantTos = form.getFieldsValue()
                              .participantTos
                              ? [...form.getFieldsValue().participantTos]
                              : [];
                            newParticipantTos.push(value);
                            form.setFieldsValue({
                              participantTos: newParticipantTos,
                            });
                            setFilterMessageParticipants([]);
                            setAddNewEmailText("");
                            setEmailValue("");
                          }
                        }}
                        mode="multiple"
                        notFoundContent={
                          !isEmpty(addNewEmailText) ? (
                            participantTypeEmailAddressesListingLoading ? (
                              <Spin size="small" />
                            ) : (
                              !participantTypeEmailAddressesListingLoading &&
                              isEmpty(filterMessageParticipants) &&
                              "No data"
                            )
                          ) : null
                        }
                        autoFocus={
                          applicationTypeStatus !== "reply" ||
                          applicationTypeStatus !== "replyall"
                            ? true
                            : false
                        }
                        // defaultActiveFirstOption
                        spellCheck="false"
                        bordered={false}
                        // open={participantToOpenDropdown}
                        // optionLabelProp="label"

                        onClick={() => {
                          // setAddNewEmailText("");
                          // setSearchValueTo("");
                        }}
                        onChange={(value) => {
                          autoDraft();
                          setSelectedParticipantTos(value);
                          // Note: The next line is important to properly update the form field value
                          setHiddenOptions(value); // Reset hidden options when selection changes
                          form.setFieldsValue({
                            participantTos: value,
                          });
                        }}
                        onKeyDown={(e) => {
                          setFilterParticipantType("To");
                          var tableContent = document.querySelector(
                            ".ant-select-item-option-active"
                          );

                          const fetchIndex =
                            tableContent?.getAttribute("title");
                          // console.log(fetchIndex,"jjjjj")
                          if (e.key === "Tab") {
                            // console.log(fetchIndex)
                            keyboardNavigation(fetchIndex, "To");
                          }
                          if (e.key === "Enter") {
                            // console.log(fetchIndex)
                            if (filterMessageParticipants.length > 0) {
                              setFilterMessageParticipants([]);
                              setAddNewEmailText("");
                            }
                          }
                          if (e.key === "Enter" || e.key === "Tab") {
                            if (
                              filterMessageParticipants.length === 0 &&
                              !participantTypeEmailAddressesListingLoading &&
                              !isEmpty(addNewEmailText)
                            ) {
                              if (reg.test(addNewEmailText)) {
                                info();
                                setConfirmNewEmail(true);
                              } else if (!reg.test(addNewEmailText)) {
                                showConfirm();
                              }
                            }
                          }
                        }}
                        optionLabelProp="label"
                        onSelect={(value, index) => {
                          // changeParticipantsEmailDtos("To");cl\\\

                          setMessageParticipants([]);
                          setAddNewEmailText("");

                          setFilterMessageParticipants([]);
                          setEmailValue("");
                        }}
                        onSearch={(value) => {
                          setParticiapantToOpenDropdown(true);
                          setAddNewEmailText(value);
                          setSearchValueTo(value);
                          setFilterParticipantType("To");
                          // handleFilter(value, "To");
                          setEmailValue(value);
                        }}
                        // filterOption={(input, option) => {
                        //   return option;

                        // }}
                        filterOption={(input, option) => {
                          // return option;
                          return option.label.includes(input);
                        }}
                        // onSelect={(value) => {
                        //   changeParticipantTable(value, "To");
                        // }}
                        onDragOver={(event) => handleDragOver(event)}
                        onDrop={(event) => handleDrop(event, "source")}
                      >
                        {/* {filterMessageParticipants.map((data, index) => ( */}
                        {/* {messageParticipants.map((data, index) => ( */}
                        {allParticipantsEmail.map((data, index) => {
                          const isHidden = hiddenOptions.includes(
                            data.emailAddress
                          );
                          const isSelected = selectedParticipantTos.includes(
                            data.emailAddress
                          );

                          if (isSelected) {
                            return null; // Skip rendering selected options
                          }
                          return (
                            <Option
                              value={data.emailAddress}
                              key={data.id}
                              className={index}
                              id={index}
                              title={index}
                              label={data.emailAddress}
                              draggable={true}
                              disabled={isHidden}
                              onDragStart={(event) =>
                                handleDragStart(event, index)
                              }
                            >
                              {/* <div className="mail-listing" key={index}>
                              <div className="mail-listing--address">
                                {data.emailAddress.toLowerCase()}
                              </div>
                              {data.emailAddressSearchDto?.personName && (
                                <div className="mail-listing--person">
                                  {data.emailAddressSearchDto?.personName}
                                </div>
                              )}
                              {data.emailAddressSearchDto?.companyName && (
                                <div className="mail-listing--company">
                                  [{data.emailAddressSearchDto?.companyName}]
                                </div>
                              )}
                            </div> */}
                              <div
                                className="compose-reference-wrapper"
                                key={index}
                              >
                                {!isEmpty(data?.emailAddress) && (
                                  <div
                                    className="compose-reference-title"
                                    title={data?.emailAddress}
                                  >
                                    <span> {data?.emailAddress}</span>
                                  </div>
                                )}
                                {!isEmpty(
                                  data?.emailAddressSearchDto?.personName
                                ) && (
                                  <div
                                    className="compose-reference-description"
                                    title={
                                      data?.emailAddressSearchDto?.personName
                                    }
                                  >
                                    <span>
                                      {data?.emailAddressSearchDto?.personName}
                                    </span>
                                  </div>
                                )}
                                {!isEmpty(
                                  data.emailAddressSearchDto?.companyName
                                ) && (
                                  <div
                                    className="compose-reference-code"
                                    title={
                                      data?.emailAddressSearchDto?.companyName
                                    }
                                  >
                                    <span>
                                      {data?.emailAddressSearchDto?.companyName}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="email-text-input">
                    <div className="email-text">Cc</div>
                    <Form.Item
                      className="form__group compose--cc"
                      name={`participantCCs`}
                      rules={[{ required: false, message: "" }]}
                    >
                      <Select
                        onContextMenu={handleContextMenu}
                        dropdownClassName="compose-reference-select-dropdown"
                        autoComplete="off"
                        bordered={false}
                        optionLabelProp="label"
                        notFoundContent={
                          !isEmpty(addNewEmailText) ? (
                            participantTypeEmailAddressesListingLoading ? (
                              <Spin size="small" />
                            ) : (
                              !participantTypeEmailAddressesListingLoading &&
                              isEmpty(filterMessagePartcipiantsCc) &&
                              "No data"
                            )
                          ) : null
                        }
                        spellCheck="false"
                        mode="multiple"
                        onKeyDown={(e) => {
                          var tableContent = document.querySelector(
                            ".ant-select-item-option-active"
                          );
                          const fetchIndex =
                            tableContent?.getAttribute("title");

                          if (e.key === "Tab") {
                            keyboardNavigation(fetchIndex, "Cc");
                          }
                          if (e.key === "Enter") {
                            // setAddNewEmailText("");
                            if (filterMessagePartcipiantsCc?.length > 0) {
                              setFilterMessageParticipantsCc([]);
                            }
                          }
                          if (e.key === "Enter" || e.key === "Tab") {
                            if (
                              filterMessagePartcipiantsCc.length === 0 &&
                              !participantTypeEmailAddressesListingLoading &&
                              !isEmpty(addNewEmailText)
                            ) {
                              if (reg.test(addNewEmailText)) {
                                info();
                                setConfirmNewEmail(true);
                              } else if (!reg.test(addNewEmailText)) {
                                showConfirm();
                              }
                            }
                          }

                          autoDraft();
                        }}
                        onChange={(e) => {
                          autoDraft();
                        }}
                        onSelect={() => {
                          setAddNewEmailText("");
                          setFilterMessageParticipantsCc([]);
                        }}
                        // onDeselect={(value) => {
                        //   setAddNewEmailText("");
                        //   setFilterMessageParticipantsCc([]);
                        //   let newParticipantArr = form.getFieldsValue()
                        //     .participantCCs
                        //     ? [...form.getFieldsValue().participantCCs]
                        //     : [];
                        //   newParticipantArr.push(value);
                        //   form.setFieldsValue({
                        //     participantCCs: newParticipantArr,
                        //   });
                        // }}
                        onSearch={(value) => {
                          setSearchValueCc(value);
                          setAddNewEmailText(value);
                          setFilterParticipantType("CC");
                          // handleFilter(value, "Cc");
                        }}
                        filterOption={(input, option) => {
                          // return option;
                          return option.label.includes(input);
                        }}
                      >
                        {allParticipantsEmail?.map((data, index) => (
                          // {filterMessagePartcipiantsCc?.map((data, index) => (
                          <Option
                            value={data.emailAddress}
                            key={data.id}
                            className={index}
                            id={index}
                            title={index}
                            label={data.emailAddress}
                          >
                            <div
                              className="compose-reference-wrapper"
                              key={index}
                            >
                              {!isEmpty(data?.emailAddress) && (
                                <div
                                  className="compose-reference-title"
                                  title={data?.emailAddress}
                                >
                                  <span> {data?.emailAddress}</span>
                                </div>
                              )}
                              {!isEmpty(
                                data?.emailAddressSearchDto?.personName
                              ) && (
                                <div
                                  className="compose-reference-description"
                                  title={
                                    data?.emailAddressSearchDto?.personName
                                  }
                                >
                                  <span>
                                    {data?.emailAddressSearchDto?.personName}
                                  </span>
                                </div>
                              )}
                              {!isEmpty(
                                data.emailAddressSearchDto?.companyName
                              ) && (
                                <div
                                  className="compose-reference-code"
                                  title={
                                    data?.emailAddressSearchDto?.companyName
                                  }
                                >
                                  <span>
                                    {data?.emailAddressSearchDto?.companyName}
                                  </span>
                                </div>
                              )}
                            </div>
                          </Option>
                        ))}{" "}
                      </Select>
                    </Form.Item>
                  </div>
                  {visibleParticipantBCC && (
                    <div
                      className={`email-text-input ${
                        visibleParticipantBCC && `active`
                      }`}
                    >
                      <div className="email-text">Bcc</div>
                      <Form.Item
                        className="form__group"
                        name={`participantBCCs`}
                        rules={[
                          { required: false, message: "Bcc is Required" },
                        ]}
                      >
                        <Select
                          dropdownClassName="compose-reference-select-dropdown"
                          autoComplete="off"
                          mode="multiple"
                          spellCheck="false"
                          optionLabelProp="label"
                          bordered={false}
                          notFoundContent={
                            !isEmpty(addNewEmailText) ? (
                              participantTypeEmailAddressesListingLoading ? (
                                <Spin size="small" />
                              ) : (
                                !participantTypeEmailAddressesListingLoading &&
                                "No data"
                              )
                            ) : null
                          }
                          onChange={() => {
                            autoDraft();
                          }}
                          onKeyDown={(e) => {
                            var tableContent = document.querySelector(
                              ".ant-select-item-option-active"
                            );
                            const fetchIndex =
                              tableContent?.getAttribute("title");
                            if (e.key === "Tab") {
                              // console.log(fetchIndex,"oooooooooooooo")
                              keyboardNavigation(fetchIndex, "BCc");
                            }
                            if (e.key === "Enter") {
                              setAddNewEmailText("");
                              if (filterMessageParticipantsBCc?.length > 0) {
                                setFilterMessageParticipantsBCc([]);
                              }
                            }
                            if (e.key === "Enter" || e.key === "Tab") {
                              if (
                                filterMessageParticipantsBCc.length === 0 &&
                                !participantTypeEmailAddressesListingLoading &&
                                !isEmpty(addNewEmailText)
                              ) {
                                if (reg.test(addNewEmailText)) {
                                  info();
                                  setConfirmNewEmail(true);
                                } else if (!reg.test(addNewEmailText)) {
                                  showConfirm();
                                }
                              }
                            }

                            setFilterParticipantType("BCC");
                            autoDraft();
                          }}
                          onSearch={(value) => {
                            setFilterParticipantType("BCC");
                            setSearchValueBCC(value);
                            setAddNewEmailText(value);
                            handleFilter(value, "BCc");
                          }}
                          filterOption={(input, option) => {
                            return option;
                          }}
                        >
                          {filterMessageParticipantsBCc?.map((data, index) => (
                            <Option
                              value={data.emailAddress}
                              className={index}
                              id={index}
                              title={index}
                              label={data.emailAddress}
                            >
                              <div
                                className="compose-reference-wrapper"
                                key={index}
                              >
                                {!isEmpty(data?.emailAddress) && (
                                  <div className="compose-reference-title">
                                    <span> {data?.emailAddress}</span>
                                  </div>
                                )}
                                {!isEmpty(
                                  data?.emailAddressSearchDto?.personName
                                ) && (
                                  <div className="compose-reference-description">
                                    <span>
                                      {data?.emailAddressSearchDto?.personName}
                                    </span>
                                  </div>
                                )}
                                {!isEmpty(
                                  data.emailAddressSearchDto?.companyName
                                ) && (
                                  <div className="compose-reference-code">
                                    <span>
                                      {data?.emailAddressSearchDto?.companyName}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </Option>
                          ))}{" "}
                        </Select>
                      </Form.Item>
                    </div>
                  )}
                  <div className="email-text-input compose-subject-wrapper">
                    <div className="email-text">Subject</div>
                    <Form.Item
                      spellCheck="false"
                      className="form__group"
                      name="subject"
                      rules={[
                        {
                          required: closeWindow === "newDraft" ? false : true,
                          message: "",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          setSubject(e.data);
                        }}
                      />
                    </Form.Item>
                    {visiblePriority && (
                      <div
                        className="priority-wrapper"
                        // style={{ border: "none", width: "10%" }}
                      >
                        <Form.Item
                          className="form__group"
                          name="priorityId"
                          style={{ marginBottom: 5 }}
                        >
                          <Select
                            autoComplete="off"
                            bordered={false}
                            defaultValue={() => {
                              let value = "";
                              organizerEventPriorityListing?.map((priority) => {
                                if (priority.name === "Normal") {
                                  value = priority.id;
                                }
                              });

                              return value;
                            }}
                          >
                            {organizerEventPriorityListing.map(
                              (priority, i) => (
                                <Option value={priority.id} key={i}>
                                  <div
                                    key={i}
                                    style={{
                                      color: `${
                                        priority.name === "High"
                                          ? "red"
                                          : priority.name === "Low"
                                          ? "grey"
                                          : "blue"
                                      }`,
                                    }}
                                    onClick={() => {
                                      checkMark(priority.name);
                                    }}
                                  >
                                    {/* <span>{showcheckMark ? <CheckOutlined /> : ''}</span> */}

                                    <span>
                                      {" "}
                                      {priority.name === "High"
                                        ? "!!"
                                        : priority.name === "Low"
                                        ? "-"
                                        : "!"}{" "}
                                      {priority.name}
                                    </span>
                                  </div>
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                  <div className="email-text-input-ref">
                    <div className="email-text">Reference</div>
                    <Form.Item
                      className="form__group compose-ref-select"
                      name="mailMessageReferenceDtos"
                      rules={[
                        {
                          required: closeWindow === "newDraft" ? false : true,
                          message: "",
                        },
                      ]}
                    >
                      <Select
                        dropdownClassName="compose-reference-select-dropdown"
                        autoComplete="off"
                        bordered={false}
                        mode="multiple"
                        spellCheck="false"
                        onDeselect={(value) => {
                          // if (isEmpty(referenceValue)) {
                          if (isEmpty(mailRefDtos)) {
                            console.log("if condition");
                          } else {
                            const filteredRef = mailRefDtos?.filter((x) => {
                              return x !== value;
                            });
                            // setAddNewEmailText("");
                            // setFilterReference([]);
                            // let newParticipantArr = form.getFieldsValue()
                            //   .mailMessageReferenceDtos
                            //   ? [
                            //       ...form.getFieldsValue()
                            //         .mailMessageReferenceDtos,
                            //     ]
                            //   : [];
                            // newParticipantArr.push(value);
                            setmailRefDto(filteredRef);
                            // form.setFieldsValue({
                            //   mailMessageReferenceDtos: newParticipantArr,
                            // });
                          }
                        }}
                        onSelect={(value) => {
                          // setFilterReference([]);
                          let newParticipantArr = form.getFieldsValue()
                            .mailMessageReferenceDtos
                            ? [
                                ...form.getFieldsValue()
                                  .mailMessageReferenceDtos,
                              ]
                            : [];
                          const uniqueArray = Array.from(
                            new Set([
                              ...mailRefDtos.map((dto) => dto.split("_")[0]),
                              value.split("_")[0],
                            ])
                          );
                          setmailRefDto(uniqueArray);
                          setHiddenReferenceOptions(uniqueArray);
                          // newParticipantArr.push(value);
                          setEditorReference(newParticipantArr.join());
                          setAddNewEmailText("");
                          setReferencevalue("");
                          if (isEmpty(form.getFieldsValue().subject)) {
                            form.setFieldsValue({
                              subject: value,
                            });
                          }
                        }}
                        // notFoundContent={
                        //   !isEmpty(referenceValue) ? (
                        //     referenceListingLoading ? (
                        //       <Spin size="small" />
                        //     ) : (
                        //       !referenceListingLoading &&
                        //       isEmpty(filterReference) &&
                        //       "No data"
                        //     )
                        //   ) : null
                        // }
                        notFoundContent={
                          <>
                            {searchKeywordFormat?.length === 2 && (
                              <>
                                Content Not Found. Do you want to create{" "}
                                <Typography.Text style={{ color: "blue" }}>
                                  {searchKeywordFormatData}
                                </Typography.Text>{" "}
                                ?
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    dispatch(
                                      referenceSecondaryAdd({
                                        title: searchKeywordFormatData,
                                        description: searchKeywordFormatData,
                                      })
                                    );
                                  }}
                                >
                                  Yes
                                </Button>
                              </>
                            )}
                          </>
                        }
                        onKeyDown={(e) => {
                          var tableContent = document.querySelector(
                            ".ant-select-item-option-active"
                          );
                          const fetchIndex =
                            tableContent?.getAttribute("title");
                          if (e.key === "Tab") {
                            keyboardNavigation(fetchIndex, "REF");
                          }
                          if (e.key === "Enter") {
                            if (filterReference?.length > 0) {
                              setFilterReference([]);
                              setAddNewEmailText("");
                            }
                          }
                          if (e.key === "Enter" || e.key === "Tab") {
                            if (
                              filterReference.length === 0 &&
                              !participantTypeEmailAddressesListingLoading &&
                              !isEmpty(addNewEmailText)
                            ) {
                              if (reg.test(addNewEmailText)) {
                                info();
                                setConfirmNewEmail(true);
                              } else if (!reg.test(addNewEmailText)) {
                                showConfirm();
                              }
                            }
                          }

                          setFilterParticipantType("REF");
                          autoDraft();
                        }}
                        onSearch={(value) => {
                          setSearchValueReference(value);
                          setReferencevalue(value);
                          setFilterParticipantType("REF");
                          handleFilter(value, "REF");
                        }}
                        showSearch
                        optionFilterProp="value"
                        filterOption={(input, option) => {
                          return (
                            option?.value
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }}
                      >
                        {/* {filterReference?.map((data, index) => ( */}
                        {referenceListingResponse?.items?.map((data, index) => {
                          const isHidden = hiddenReferenceOptions.includes(
                            data.code
                          );

                          const isSelected = mailRefDtos.includes(data?.code);

                          if (isSelected) {
                            return null; // Skip rendering selected options
                          }
                          return (
                            <Option
                              key={index}
                              value={`${data.code}_${data.title}`}
                              // className={index}
                              // id={index}
                              label={[data.code, data.title]}
                            >
                              <div
                                className="compose-reference-wrapper"
                                key={index}
                              >
                                <div
                                  className="compose-references-code"
                                  title={data?.code}
                                >
                                  <span>{data?.code}</span>
                                </div>
                                <div
                                  className="compose-references-title"
                                  title={data?.title}
                                >
                                  <span> {data?.title}</span>
                                </div>
                                <div
                                  className="compose-references-description"
                                  title={data?.description}
                                >
                                  <span>{data?.description}</span>
                                </div>
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  {progress > 0 ? <Progress percent={progress} /> : null}
                  {!isEmpty(form.getFieldValue().uploadedFileDtos) && (
                    <Col span={24}>
                      <div className="email-text-input">
                        {/* {console.log(params)} */}
                        <div className="email-text">Attachment</div>
                        <Form.Item
                          // name={["uploadedFileDtos"]}
                          name="uploadedFileDtos"
                          className="form__group"
                          // name={u.id}

                          // label={u.name}
                          rules={[{ required: false }]}
                        >
                          <ImageUploaderMultiple
                            name="emailMessageAttachmentDtos"
                            isMultipleFileAllowed={true}
                            id=""
                            uploadedFileDtos={form.getFieldValue()}
                            formName="uploadedFileDtos"
                            form={form}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  )}
                  <Row
                    className={`compose-email-row  compose-email-row-${toHeight}-${ccHeight}`}
                  >
                    <Col span={24}>
                      <input
                        id="my-file"
                        type="file"
                        name="my-file"
                        style={{ display: "none" }}
                        // onChange=""
                      />
                      <div
                        className={`test compose-editor-wrapper ${
                          toolBar && "active"
                        } ${
                          visibleInternalMessage && "internalmessage-active"
                        }`}
                      >
                        <Editor
                          className="compose-editor"
                          //composeEmailNewHere
                          value={editorData}
                          onKeyDown={(e) => {
                            let bbb = {};
                            if (e.key === "Alt") {
                              bbb = {
                                ...AltEnt,
                                alt: e.key,
                              };
                              setAltEnt(bbb);
                            }
                            if (e.key === "Enter") {
                              bbb = {
                                ...AltEnt,
                                enter: e.key,
                              };
                              setAltEnt(bbb);
                            }
                          }}
                          init={{
                            auto_focus:
                              applicationTypeStatus === "reply" ||
                              applicationTypeStatus === "replyall"
                                ? true
                                : false,
                            browser_spellcheck: true,
                            plugins: ["lists advlist", "image", " paste"],
                            menubar: "false",
                            file_browser_callback_types: "image",
                            file_picker_callback: function (
                              callback,
                              value,
                              meta
                            ) {
                              if (meta.filetype == "image") {
                                var input = document.getElementById("my-file");
                                input.click();
                                input.onchange = function () {
                                  var file = input.files[0];
                                  var reader = new FileReader();
                                  reader.onload = function (e) {
                                    // console.log("name", e.target);
                                    callback(e.target.result, {
                                      alt: file.name,
                                    });
                                  };
                                  reader.readAsDataURL(file);
                                };
                              }
                            },
                            paste_data_images: true,
                            // toolbar: "paste",
                            paste_as_text: false,
                            paste_merge_formats: false,
                            paste_tab_spaces: 12,
                            toolbar:
                              "fontselect fontsizeselect backcolor forecolor bold | italic | underLine lineheight |  strikethrough alignleft aligncenter alignright |  bullist numlist",

                            content_style: "p{margin:0px;padding:0px}",
                            forced_root_block: "div",
                            forced_root_block_attrs: {
                              style: "font-family: Arial; font-size: 10pt",
                              class: "editor-block-wrapper",
                              id: "editor-blocks",
                            },
                            font_formats:
                              "Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;Arial Narrow=arial narrow,avant garde;Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier;Cambria=cambria;Calibri=calibri;Calibri Body=calibri boby;Georgia=georgia,palatino;Times New Roman=times new roman,times;Helvetica=helvetica;Verdana=verdana,geneva;",
                          }}
                          onEditorChange={(e) => {
                            setEditorData(e);
                            // ComposeEmailNew(e, signature);
                            autoDraft();
                          }}
                          // onEditorChange={(value) => handleUpdate(value, "")}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* {console.log(editorData)} */}
              </div>
            </div>
          </div>
          {visibleInternalMessage && (
            <div className="quick-message-part">
              <Header className="email_compose__header">
                <Dropdown.Button
                  overlay={menuGropCreate}
                  icon={<UsergroupAddOutlined />}
                ></Dropdown.Button>
                <label className="send_to_all">To All</label>

                <div
                  className="hover"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "auto",
                    order: 2,
                    columnGap: 10,
                  }}
                >
                  {/* <input
											type="file"
											id="file-upload"
											onChange={(e) => {
												console.log('hey');
												uploadFiles(e);
											}}
											style={{ display: 'none' }}
										/>

										<label htmlFor="file-upload">{<Image src={attachment} preview={false} className="chat-img" />}</label> */}
                  {/* <div
                    style={{
                      width: !visibleInternalMessage ? "30%" : "50%",
                      color: "black",
                    }}
                    className="hover aa__icon"
                    onClick={() => {
                      setVisibleInternalCkEditor(!visibleInternalCkEditor);
                    }}
                  >
                    Aa
                  </div> */}
                  <div>
                    {/* <input type="checkbox" onchange={onChangeCheckbox} checked={checkAllInternalEmails}/> */}
                    <Form.Item
                      name="check_all_internal_emails"
                      valuePropName="checked"
                    >
                      <Checkbox
                        checked={checkAllInternalEmails}
                        onChange={(e) => {
                          setCheckAllInternalEmails(!checkAllInternalEmails);
                        }}
                        className="form__grouppp"
                      ></Checkbox>
                    </Form.Item>
                  </div>
                  <div
                    className="hover"
                    style={{ width: !visibleInternalMessage ? "30%" : "50%" }}
                  >
                    <Upload
                      name="uploadedFiles"
                      itemRender={(files) => {
                        <div style={{ display: "none" }}>{files}</div>;
                      }}
                      action={`${appBaseUrl}/file/uploadFile/`}
                      customRequest={uploadPhotosInternal}
                      multiple={true}
                    >
                      <img src={attachment} alt="igm" />
                    </Upload>
                  </div>
                  <Image
                    onClick={() => {
                      setVisibleInternalMessage(false);
                    }}
                    src={hide}
                    preview={false}
                    className="chat-img"
                  />
                </div>
              </Header>

              <div className="drawer__content--overflow-hidden">
                <div
                  className="drawer__content--wrapper"
                  style={{ paddingTop: 10 }}
                >
                  <div className="contact__content__wrapper">
                    <div className="email-text-input">
                      <div className="email-text">To</div>
                      <Form.Item
                        className="form__group"
                        name="instanceParticipantTos"
                        rules={[
                          {
                            required: instanceEditorData ? true : false,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          autoComplete="off"
                          bordered={false}
                          optionFilterProp="label"
                          mode="multiple"
                          placeholder="Internal To"
                          onChange={(value, option) =>
                            changeInstanceParticipantsDtos(option, "To")
                          }
                        >
                          {instanceMessageParticipants.map((participant, j) => (
                            <Option
                              value={participant.appUserId}
                              label={[
                                participant.fullName,
                                participant.userName,
                              ]}
                              key={j}
                            >
                              <div>
                                <div className="demo-option-label-item">
                                  <span role="img">
                                    {participant.fullName} {"" + ""}
                                  </span>
                                  [{participant.userName.toUpperCase()}]
                                </div>
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="email-text-input">
                      <div className="email-text">Cc</div>
                      <Form.Item
                        className="form__group"
                        name="instanceParticipantCCs"
                        rules={[
                          {
                            // required: instanceEditorData ? true : false,
                            required: false,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select
                          autoComplete="off"
                          bordered={false}
                          mode="multiple"
                          optionFilterProp="label"
                          placeholder="Internal CC"
                          onChange={(value, option) =>
                            changeInstanceParticipantsDtos(option, "CC")
                          }
                        >
                          {instanceMessageParticipantsCC.map(
                            (participant, j) => (
                              <Option
                                value={participant.appUserId}
                                label={[
                                  participant.fullName,
                                  participant.userName,
                                ]}
                                key={j}
                              >
                                <div className="demo-option-label-item">
                                  <span role="img">
                                    {participant.fullName} {"" + ""}
                                  </span>
                                  [{participant.userName.toUpperCase()}]
                                </div>
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </div>

                    {progressInternal > 0 ? (
                      <Progress percent={progressInternal} />
                    ) : null}
                    {!isEmpty(
                      form.getFieldValue().instanceUploadedFileDtos
                    ) && (
                      <div className="email-text-input internal-attachment-wrapper--info">
                        <div className="email-text attachemnt---text">
                          Attachments
                        </div>
                        <Form.Item
                          name="instanceUploadedFileDtos"
                          className="form__group"
                          // name={u.id}
                          // label={u.name}
                          rules={[{ required: false }]}
                        >
                          <ImageUploaderMultiple
                            name="emailInstanceMessageAttachmentDtos"
                            isMultipleFileAllowed={true}
                            id=""
                            uploadedFileDtos={form.getFieldValue()}
                            formName="instanceUploadedFileDtos"
                            form={form}
                            // callback={(e) => {
                            // 	let olduploadedFileDtos = form.getFieldValue().instanceUploadedFileDtos ? [...form.getFieldValue().instanceUploadedFileDtos] : [];

                            // 	let uploadedFileDtos = [...olduploadedFileDtos];
                            // 	uploadedFileDtos.push(e);
                            // 	// uploadedFileDtos[i] = e;

                            // 	form.setFieldsValue({
                            // 		instanceUploadedFileDtos: uploadedFileDtos,
                            // 	});
                            // }}
                          />
                        </Form.Item>
                      </div>
                    )}

                    <Row
                      className={`compose-email-row  compose-email-row-${toHeight}-${ccHeight}`}
                    >
                      <Col span={24}>
                        <div
                          className={`internal-ckEditor compose-internal-message-wrapper ${
                            visibleInternalCkEditor && "active"
                          }`}
                        >
                          <Form.Item
                          //  rules={[
                          //   {
                          //     required:
                          //       form.getFieldValue()["instanceParticipantTos"]
                          //         ?.length > 0 ||
                          //       form.getFieldValue()["instanceParticipantCCs"]
                          //         ?.length > 0
                          //         ? true
                          //         : false,
                          //     message: "",
                          //   },
                          // ]}
                          // name="instanceEditorData"
                          >
                            <Editor
                              value={instanceEditorData}
                              init={{
                                plugins: ["lists advlist", "image", " paste"],
                                menubar: "false",
                                browser_spellcheck: true,
                                file_browser_callback_types: "image",
                                file_picker_callback: function (
                                  callback,
                                  value,
                                  meta
                                ) {
                                  if (meta.filetype == "image") {
                                    var input =
                                      document.getElementById("my-file");
                                    input.click();
                                    input.onchange = function () {
                                      var file = input.files[0];
                                      var reader = new FileReader();
                                      reader.onload = function (e) {
                                        // console.log("name", e.target.result);
                                        callback(e.target.result, {
                                          alt: file.name,
                                        });
                                      };
                                      reader.readAsDataURL(file);
                                    };
                                  }
                                },
                                paste_data_images: true,
                                auto_focus: true,

                                toolbar:
                                  "fontselect fontsizeselect backcolor forecolor bold | italic | underLine |  strikethrough alignleft aligncenter alignright |  bullist numlist | paste image code",

                                content_style: "p{margin:0px}",
                              }}
                              onEditorChange={(e) => {
                                setInstanceEditorData(e);
                              }}
                              id="internal_message_tinyMce"
                            />
                          </Form.Item>

                          {/* {console.log(document.getElementById("internal_message_tinyMce"))} */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* {ModalMessage} */}
        <Modal
          visible={addNewEmailModalVisible}
          footer={null}
          onOk={() => {
            setNewQuickCompanyValue("");
            setAddNewEmailModalVisible(true);
          }}
          onCancel={() => {
            setNewQuickCompanyValue("");
            form1.setFieldsValue({
              companyPersonId: "",
              companyContactId: "",
              remarks: "",
              scopeOfWork: [],
            });
            setAddNewEmailModalVisible(false);
          }}
          keyboard={false}
          maskClosable={false}
          wrapClassName="quick_add_email_modal"
          afterClose={() => {
            setAllPersonListingState([]);
            setAllCompanyListingState([]);
            setNewCompanyValue([]);
            setNewValuePersonCompany([]);
            setNotFoundValue([]);
          }}
        >
          <AddNewQuickEmail
            message={messageFromCompose}
            addNewEmailModalVisible={addNewEmailModalVisible}
            setVisibleModal={setAddNewEmailModalVisible}
            setAddNewEmailText={setAddNewEmailText}
            emailText={addNewEmailText}
            form={form1}
            notFoundCompany={notFoundCompany}
            setNotFoundCompany={setNotFoundCompany}
            companyCurrentPage={companyCurrentPage}
            setCompanyCurrentPage={setCompanyCurrentPage}
            personId={personId}
            setPersonId={setPersonId}
            companyId={companyId}
            setCompanyId={setCompanyId}
            setDisableSaveButton={setDisableSaveButton}
            disableSavedButton={disableSavedButton}
            notFoundValue={notFoundValue}
            setNotFoundValue={setNotFoundValue}
            allPersonListingState={allPersonListingState}
            setAllPersonListingState={setAllPersonListingState}
            allCompanyListingState={allCompanyListingState}
            setAllCompanyListingState={setAllCompanyListingState}
            newValuePersonCompany={newValuePersonCompany}
            setNewValuePersonCompany={setNewValuePersonCompany}
            newCompanyValue={newCompanyValue}
            setNewCompanyValue={setNewCompanyValue}
            setNewQuickCompanyValue={setNewQuickCompanyValue}
            newQuickCompanyValue={newQuickCompanyValue}
          />
        </Modal>
      </Form>
      {/* ...GroupCreateUpdate */}
      <Modal
        visible={createGroupModal}
        title={`${
          isEmpty(selectedGroupDataToBeUpdated)
            ? "Create Your Group"
            : "Update Your Group"
        }`}
        onCancel={() => setCreateGroupModal(false)}
        footer={null}
        height={300}
      >
        <Form {...layoutFormGroup} onFinish={onGroupCreateFinish} form={form4}>
          <Form.Item
            name="groupName"
            label="Group Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Name of your group" />
          </Form.Item>

          <Form.Item
            name="userListTo"
            label="To Users"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Users"
              allowClear
              autoComplete="off"
              mode="multiple"
              optionFilterProp="label"
            >
              {!isEmpty(instanceMessageParticipants) &&
                instanceMessageParticipants?.map((user, index) => {
                  return (
                    <Option
                      value={user?.appUserId}
                      key={index}
                      label={[user.fullName, user.userName]}
                    >
                      {user?.fullName} [{user?.userName.toUpperCase()}]
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            name="userListCc"
            label="Cc Users"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Users"
              allowClear
              autoComplete="off"
              mode="multiple"
              optionFilterProp="label"
            >
              {!isEmpty(instanceMessageParticipants) &&
                instanceMessageParticipants?.map((user, index) => {
                  return (
                    <Option
                      value={user?.appUserId}
                      key={index}
                      label={[user.fullName, user.userName]}
                    >
                      {user?.fullName} [{user?.userName.toUpperCase()}]
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={groupDataLoading}>
            {isEmpty(selectedGroupDataToBeUpdated) ? "Submit" : "Update"}
          </Button>
        </Form>
      </Modal>

      <BusinessModal
        emailBusinessModal={showBusinessModal}
        setBusinessModal={setBusinessModal}
      />
    </>
  );
};

export default ComposeEmailDrawer;
