import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Upload, Modal, message, Button, Progress } from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import {
  uploadFile,
  uploadExcelAttendance,
} from "../../services/remanentCalls";
import { appBaseUrl, cqBaseUrl } from "../../utils/config";

import { isEmpty, cloneDeep } from "lodash";
// const fileLabelTypeId = "34ffc957-b591-4ca7-b2d1-8dbae3225769";
const AttendanceUpload = ({
  name,
  deleteImageById,
  isImageRemoved,
  isMultipleFileAllowed,
  id,
  callback,
  form,
  index,
  allowedExtensions,
  // fileList,
  // setFileList
}) => {
  const { token } = useSelector((state) => state.auth);
  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );

  // console.log(form.getFieldValue()[`${name}FileDtos`]  &&  form.getFieldValue()[`${name}FileDtos`][index])

  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });
  const [fileList, setFileList] = useState([
    // {
    //     uid: '-1',
    //     name: 'image.png',
    //     status: 'done',
    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ]);
  const [progress, setProgress] = useState(0);
  const [grantedPolices, setGrantedPolices] = useState({});
  const uploadedFileDtos = form.getFieldValue()[`${name}`];

  // console.log("heynice", name);
  useEffect(() => {
    if (uploadedFileDtos && uploadedFileDtos.length) {
      // if (isMultipleFileAllowed) {
      let fileLists = uploadedFileDtos
        .filter((list) => list.isActive)
        .map((list, index) => ({
          uid: index,
          name: list?.displayFileName,
          status: "done",
          url: `${cqBaseUrl}/${list?.viewFileURL}${token}`,
        }));
      setFileList(fileLists);
      // }
      // else if (uploadedFileDtos[0]?.displayFileName && uploadedFileDtos[0]?.isActive) {
      //   setFileList([
      //     {
      //       uid: 0,
      //       name: uploadedFileDtos[0]?.displayFileName,
      //       fileName: uploadedFileDtos[0]?.fileName,
      //       status: "done",
      //       url: `${cqBaseUrl}/${uploadedFileDtos[0]?.viewFileURL}${token}`
      //     }
      //   ])
      // }
      // else { }
    }
  }, [uploadedFileDtos]);

  useEffect(() => {
    if (!isEmpty(applicationConfiguration?.auth?.grantedPolicies)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleCancel = () => setPreview({ ...preview, previewVisible: false });
  const onChange = ({ file, fileList: newFileList, event }) => {
    if (file.status === "done") {
      // console.log({newFileList})
      // let newUrl = file?.response.data[0].viewFileURL
      // console.log({newUrl})
      // setFileList(newFileList);
      let updatedInfo = form.getFieldValue()[`${name}`];

      let newUpdatedInfo = updatedInfo.map((newFile, i) => {
        if (newFile.displayFileName === file.name) {
          return {
            ...newFile,
            isActive: true,
          };
        } else {
          return {
            ...newFile,
          };
        }
      });

      form.setFieldsValue({
        [`${name}`]: newUpdatedInfo,
      });
    }

    // setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    // const isPdf = file.type === "application/pdf";
    // if (!isJpgOrPng && !isPdf) {
    //   message.error("You can only upload JPG/PNG file!");
    //   return false;
    // }
    const isOverSize = file.size > 2480 * 3230;
    if (isOverSize) {
      message.error("Image must smaller than 2480 * 32308!");
      return false;
    }
    return true;
  };

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgress,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
      setFileList([]);
    });
    if (resp?.status === 200) {
      message.success(resp.data);
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      callback(alteredResponse);
      onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  return (
    <>
      {
        <Upload
          //  appBaseUrl + ``, body

          // action={`/product/images/${user?._id}`}
          accept={allowedExtensions}
          action={`${appBaseUrl}/attendance/importExcelAttendance`}
          customRequest={uploadPhotos}
          // listType={
          //   name !== "Profile" && name !== "ProfilePicture" ? "" : "picture-card"
          // }
          multiple={isMultipleFileAllowed}
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          beforeUpload={beforeUpload}
          // onRemove={async (file) => {
          // 	if (isMultipleFileAllowed) {
          // 		let updatedInfo = form.getFieldValue()[`${name}FileDtos`];
          // 		updatedInfo.splice(file.uid, 1);
          // 		let newUpdatedInfo = updatedInfo.map((newFile, i) => {
          // 			if (newFile.displayFileName === file.name) {
          // 				return {
          // 					...newFile,
          // 					isActive: false,
          // 				};
          // 			} else {
          // 				return {
          // 					...newFile,
          // 				};
          // 			}
          // 		});

          // 		let newfileLists = updatedInfo.map((list, index) => ({
          // 			uid: index,
          // 			name: list?.displayFileName,
          // 			status: 'done',
          // 			url: `${cqBaseUrl}/${list?.viewFileURL}${token}`,
          // 		}));
          // 		setFileList(newfileLists);

          // 		form.setFieldsValue({
          // 			[`${name}FileDtos`]: newUpdatedInfo,
          // 		});
          // 		return false;
          // 	} else {
          // 		let updatedInfo = cloneDeep(form.getFieldValue()[`${name}FileDtos`]);
          // 		// delete updatedInfo[index];
          // 		updatedInfo[file.uid].isActive = false;

          // 		setFileList([]);
          // 		form.setFieldsValue({
          // 			[`${name}FileDtos`]: updatedInfo,
          // 		});
          // 		return false;
          // 	}
          // }}
          onRemove={async (file) => {
            let updatedInfo = form.getFieldValue()[`${name}`];
            updatedInfo.splice(file.uid, 1);

            let newfileLists = updatedInfo.map((list, index) => ({
              uid: index,
              name: list?.displayFileName,
              status: "done",
              url: `${cqBaseUrl}/${list?.viewFileURL}${token}`,
            }));
            setFileList(newfileLists);
            form.setFieldsValue({
              [`${name}`]: updatedInfo,
            });
            return false;
            // return await deleteImageById(user?._id, file.response.data[0]._id)
            // return isImageRemoved
          }}
          progress={{
            strokeColor: {
              "0%": "#108ee9",
              "100%": "#87d068",
            },
            strokeWidth: 3,
            format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
          }}
          name="uploadedFiles"
        >
          {!isEmpty(grantedPolices) && grantedPolices["AbpIdentity.Roles"] && (
            <>
              {progress > 0 ? <Progress percent={progress} /> : null}
              {!isMultipleFileAllowed && fileList?.length === 0 && (
                <Button icon={<PlusOutlined />}> + add file</Button>
              )}
              {isMultipleFileAllowed && (
                <Button icon={<PlusOutlined />}>+add file</Button>
              )}
            </>
          )}
        </Upload>
      }
      <Modal
        visible={preview.previewVisible}
        title={preview.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {name === "Profile" || name === "ProfilePicture" ? (
          <img
            alt="alt"
            style={{ width: "100%" }}
            src={preview.previewImage}
            // onError={() => {
            //   let altmodalDiv = document.getElementById("altmodal");
            //   altmodalDiv.innerHTML = `
            //     <a href="${cqBaseUrl}/${fileList[0].response.viewFileURL}${token} download">Download</a>
            //     `;
            // }}
          />
        ) : (
          <>
            <a
              href={`${cqBaseUrl}/${fileList[0]?.viewFileURL}${token} download`}
            >
              Text
            </a>
          </>
          // <a href={`${cqBaseUrl}/${fileList[0].response.viewFileURL}${token} download`}>Download</a>
        )}
      </Modal>
    </>
  );
};

AttendanceUpload.propTypes = {
  user: PropTypes.object,
  // uploadFile:PropTypes.func,
  // isImageRemoved: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  // isImageRemoved: state.product.isImageRemoved
});

const mapDispatchToProps = {
  // uploadFile,
  // deleteImageById
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceUpload);
