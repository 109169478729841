import React, { useState } from "react";
import MyContext from "./MyContext";

const ContextProvider = ({ children }) => {
  const [mailOriginState, setMailOriginState] = useState("");

  const updateMailOriginState = (newValue) => {
    setMailOriginState(newValue);
  };

  return (
    <MyContext.Provider value={{ mailOriginState, updateMailOriginState }}>
      {children}
    </MyContext.Provider>
  );
};

export default ContextProvider;
