import { BLOG_TYPES } from "services/types";
import { uniqBy } from "lodash";

const INITIAL_STATE = {
  addBlogMessageLoading: false,
  addBlogMessageResp: [],

  getBlogMsgPaginationLoading: false,
  getBlogMsgPaginationResp: null,

  updateBlogMessageLoading: false,
  updateBlogMsgPaginationResp: null,
};

export default function blogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // create
    case BLOG_TYPES.CREATE_BLOG_MESSAGE_INIT:
      return {
        ...state,
        addBlogMessageLoading: true,
      };

    case BLOG_TYPES.CREATE_BLOG_MESSAGE_SUCCESS:
      // console.log("Reducer state", state.getBlogMsgPaginationResp);
      // console.log("Recducer:  payload", action.payload);
      return {
        ...state,
        addBlogMessageResp: action.payload,
        addBlogMessageLoading: false,
        getBlogMsgPaginationResp: {
          items: [action.payload, ...state.getBlogMsgPaginationResp?.items],
          totalCount: state.getBlogMsgPaginationResp.totalCount,
        },
      };

    case BLOG_TYPES.CREATE_BLOG_MESSAGE_FINISH:
      return {
        ...state,
        addBlogMessageLoading: false,
      };

    //update
    case BLOG_TYPES.UPDATE_BLOG_MESSAGE_INIT:
      return {
        ...state,
        updateBlogMessageLoading: true,
      };

    case BLOG_TYPES.UPDATE_BLOG_MESSAGE_SUCCESS:
      const updatedItem = state.getBlogMsgPaginationResp
        ? state.getBlogMsgPaginationResp.items.reduce((accumulator, item) => {
            if (item.id === action.payload.id) {
              if (action.payload.isActive) {
                // console.log("isActive true: ", [
                //   ...accumulator,
                //   action.payload,
                // ]);
                return [...accumulator, action.payload];
              } else {
                return accumulator;
              }
            } else {
              return [...accumulator, item];
            }
          }, [])
        : [];

      if (
        !state.getBlogMsgPaginationResp ||
        !state.getBlogMsgPaginationResp.items.some(
          (item) => item.id === action.payload.id
        )
      ) {
        updatedItem.push(action.payload);
      }

      return {
        ...state,
        updateBlogMsgPaginationResp: action.payload,
        updateBlogMessageLoading: false,
        getBlogMsgPaginationResp: {
          ...state.getBlogMsgPaginationResp,
          items: updatedItem,
        },
      };

    case BLOG_TYPES.UPDATE_BLOG_MESSAGE_FINISH:
      return {
        ...state,
        updateBlogMessageLoading: false,
      };

    // pagination
    case BLOG_TYPES.GET_PAGINATION_BLOG_MESSAGE_INIT:
      return {
        ...state,
        getBlogMsgPaginationLoading: true,
      };

    case BLOG_TYPES.GET_PAGINATION_BLOG_MESSAGE_SUCCESS:
      return {
        ...state,
        getBlogMsgPaginationResp: action.payload,
        getBlogMsgPaginationLoading: false,
      };

    case BLOG_TYPES.GET_PAGINATION_BLOG_MESSAGE_CHANGE_SUCCESS:
      return {
        ...state,
        getBlogMsgPaginationResp: state.getBlogMsgPaginationResp
          ? {
              items: uniqBy(
                state.getBlogMsgPaginationResp?.items.concat(
                  action.payload.items
                ),
                "id"
              ),
              totalCount: action.payload.totalCount,
            }
          : action.payload,
        getBlogMsgPaginationLoading: action.payload ? false : true,
      };

    case BLOG_TYPES.GET_PAGINATION_BLOG_MESSAGE_FINISH:
      return {
        ...state,
        getBlogMsgPaginationLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
