import { getService, postService } from "services/commonServices";
import { appBaseUrl } from "utils/config";
import queryString from "query-string";

export class Procurement {
  addProcurement(body) {
    let url = `${appBaseUrl}/requisitionManagement/procurementApplicationAdd`;
    let data = postService(url, body, "post");
    return data;
  }
  getProcurement(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/requisitionManagement/procumentApplicationPagination?${query}`;
    console.log("url here", url);
    let data = getService(url);
    return data;
  }
  getProcurementDetails(id) {
    let url = `${appBaseUrl}/requisitionManagement/procurementApplicationDetails/${id}`;
    let data = getService(url);
    return data;
  }
  procurementUpdateSupervisor(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/procurementApplicationUpdateSupervisor/${id}`;
    let data = postService(url, body, "put");
    return data;
  }
  procurementUpdateAccountant(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/procurementApplicationUpdateAccountant/${id}`;
    let data = postService(url, body, "put");
    return data;
  }
  procurementUpdateManagement(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/procurementApplicationUpdateManagement/${id}`;
    let data = postService(url, body, "put");
    return data;
  }
  procurementSummaryManagement(type) {
    let url = `${appBaseUrl}/requisitionManagement/pendingRequisitionList?requisitionType=${type}`;
    let data = getService(url);
    return data;
  }
  procurementCounter() {
    let url = `${appBaseUrl}/requisitionManagement/pendingRequisitionGetAllCounter`;
    let data = getService(url);
    return data;
  }
  showSimilarProcurement(id) {
    let url = `${appBaseUrl}/requisitionManagement/showSimilarProcurementApplication/${id}`;
    let data = getService(url);
    return data;
  }
  showProcurementTable(id) {
    let url = `${appBaseUrl}/requisitionManagement/historyProcurementApplicationForEmployee/${id}`;
    let data = getService(url);
    return data;
  }
  showAllSameSubjectProcurement(body) {
    const query = queryString.stringify(body);
    let url = `${appBaseUrl}/requisitionManagement/procumentApplicationPagination?${query}`;
    let data = getService(url);
    return data;
  }
}
