import React, { useEffect, useState } from "react";

// antd
import { Table, Tag, Button, Form } from "antd";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getSisterCompanyPagination } from "../../../services/redux/admin/sisterCompanyListing/action";

// utils
import { LogoImage } from "utils/imageConstants";

const AddUpdateSisterCompanyTable = ({ showAddUpdateSisterCompanyDrawer }) => {
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Domain Name",
      dataIndex: "domainName",
      key: "domainName",
    },
    {
      title: "SMTP Host",
      dataIndex: "smtphost",
      key: "smtphost",
    },
    {
      title: "Port",
      dataIndex: "port",
      key: "port",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (tag) => {
        let color = "";
        let status = "";
        if (tag) {
          color = "green";
          status = "Active";
        } else {
          color = "red";
          status = "InActive";
        }
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a
          className="action-btn action-btn-add"
          onClick={(e) => showAddUpdateSisterCompanyDrawer(e, record)}
        >
          Edit Sister Company
        </a>
      ),
    },
  ];

  const dispatch = useDispatch();
  const { sisterCompanyPagination, sisterCompanyPaginationLoading } =
    useSelector((state) => state.sisterCompany);

  const [data, setdata] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, [searchKeyword]);

  useEffect(() => {
    if (!sisterCompanyPagination) return;
    setdata(sisterCompanyPagination.items);
    setTotalCount(sisterCompanyPagination.totalCount);
  }, [sisterCompanyPagination]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType,
    SearchKeyword = searchKeyword
  ) => {
    dispatch(
      getSisterCompanyPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
        SearchKeyword,
      })
    );
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };

  return (
    <Table
      className="contact-table"
      columns={columns}
      rowKey="id"
      dataSource={data}
      onChange={handleTableChange}
      // loading={countryPaginationLoading}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" alt="loading" />
        ),
        spinning: sisterCompanyPaginationLoading,
      }}
      pagination={{
        total: totalCount,
        pageSize: pageSize,
      }}
    />
  );
};

export default AddUpdateSisterCompanyTable;
