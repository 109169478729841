import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, Input, Row, Steps, Table } from "antd";
import {
  actionForPaymentReceivedPrincipalInvoiceAccountApproved,
  actionForPaymentReceivedPrincipalInvoiceFinalAccountApproved,
  actionForPaymentReceivedPrincipalInvoiceGetDetail,
  actionForPaymentReceivedPrincipalInvoiceManagementApproved,
  // actionForCeadEmailCompose,
} from "services/redux/cead/action";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { cqBaseUrl } from "utils/config";
import AllFileViewer from "components/Documents/allFileViewer";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  actionForGetSingleCeadTenderDetail,
  actionForTenderCeadSummary,
} from "../../../../../../services/redux/cead/action";
import negativeNumColor from "utils/negativeNumColor";
const { Step } = Steps;

export function PrincpalPaymentData({ id }) {
  const dispatch = useDispatch();
  const userRole = sessionStorage.getItem("role");
  const { token } = useSelector((state) => state.auth);

  const [approvalRemarkForm] = useForm();

  const [isApproval, setIsApproval] = useState(false);

  const {
    tenderCeadPrincipleInvoiceDetailData,
    // tenderCeadPrincipleInvoiceDetailsLoading,
    singleTenderCeadData,
    singleTenderCeadLoading,
    ceadTenderSummaryResponse,
    ceadTenderSummaryLoading,
  } = useSelector((state) => state.ceadReducer);

  const isApprovedBy =
    tenderCeadPrincipleInvoiceDetailData?.invoiceStatusDto?.systemName;

  useEffect(() => {
    dispatch(actionForPaymentReceivedPrincipalInvoiceGetDetail(id));
  }, [id]);

  useEffect(() => {
    if (!isEmpty(tenderCeadPrincipleInvoiceDetailData?.tenderCeadId)) {
      dispatch(
        actionForGetSingleCeadTenderDetail(
          tenderCeadPrincipleInvoiceDetailData?.tenderCeadId
        )
      );
      dispatch(
        actionForTenderCeadSummary(
          tenderCeadPrincipleInvoiceDetailData?.tenderCeadId
        )
      );
    }
  }, [id, tenderCeadPrincipleInvoiceDetailData?.tenderCeadId]);

  const tblCol = [
    {
      key: "sn",
      title: "S.N",
      render: (_, text, index) => index + 1,
    },
    {
      key: "currency",
      title: "Currency",
      dataIndex: ["currencyDto", "abbvr"],
    },
    {
      key: "variationAmount",
      title: "Variation Amount",
      className: "acc-s-cell-int",
      // dataIndex: "amount",
      // render: (record) => record?.amount.toFixed(2),
      render: (record) => {
        return negativeNumColor(record?.amount);
      },
    },
    {
      key: "invoiceStatus",
      title: "Invoice Status",
      dataIndex: ["invoiceStatusDto", "displayName"],
    },
    {
      key: "emailStatus",
      title: "Email Status",
      render: (record) => <>{record?.mailSentDateTime ? "Send" : "Not Send"}</>,
    },
  ];

  let stepsStage = [
    {
      title: "Apply",
      key: "1",
    },
    {
      title: "Account",
      key: "2",
    },
    {
      title: "Managent",
      key: "3",
    },
    {
      title: "Email Sent",
      key: "4",
    },
  ];

  const ceadSummaryTbl = [
    {
      title: "S.N",
      render: (_, text, index) => index + 1,
    },
    // { title: "Name", dataIndex: "Name" },
    // { title: "Reference", dataIndex: "Reference" },
    {
      title: "Contract Amount",
      // dataIndex: "contractAmount",
      className: "acc-s-cell-int",
      render: (record) => {
        return negativeNumColor(record?.contractAmount);
      },
    },
    {
      title: "Principal Invoice",
      // dataIndex: "principalInvoice",
      // render: (record) => record?.principalInvoice.toFixed(2),
      className: "acc-s-cell-int",
      render: (record) => {
        return negativeNumColor(record?.principalInvoice);
      },
    },
    {
      title: "Received Amount",
      // dataIndex: "receivedAmount",
      className: "acc-s-cell-int",
      render: (record) => {
        return negativeNumColor(record?.receivedAmount);
      },
    },
    {
      title: "Currency",
      dataIndex: "currencyAbbvr",
    },
    {
      title: "Fee Value",
      // dataIndex: "feeValue",
      className: "acc-s-cell-int",
      render: (record) => {
        return negativeNumColor(record?.feeValue);
      },
    },
    {
      title: "Fee Remaining",
      // dataIndex: "feeRemaining",
      className: "acc-s-cell-int",
      render: (record) => {
        return negativeNumColor(record?.feeRemaining);
      },
    },
  ];

  const onFinish = (value) => {
    let apiData = {
      ...value,
      isApproved: isApproval,
    };

    if (isApprovedBy === "FS_AccountantApproved") {
      dispatch(
        actionForPaymentReceivedPrincipalInvoiceManagementApproved(apiData, id)
      );
    } else if (isApprovedBy === "ManagementApproved") {
      dispatch(
        actionForPaymentReceivedPrincipalInvoiceFinalAccountApproved(
          apiData,
          id
        )
      );
    } else {
      dispatch(
        actionForPaymentReceivedPrincipalInvoiceAccountApproved(apiData, id)
      );
    }
  };

  const data =
    tenderCeadPrincipleInvoiceDetailData?.tenderCEADPaymentReceivedPrincipalInvoiceParticipantDetailsDtos
      ?.slice()
      .sort((a, b) => a.participantTypeDto.id - b.participantTypeDto.id);

  return (
    <>
      <Card style={{ margin: "20px" }}>
        <Steps
          current={
            isApprovedBy === "SS_AccountantApproved" ||
            isApprovedBy === "ManagementApproved"
              ? 2
              : isApprovedBy === "FS_AccountantApproved"
              ? 1
              : 0
          }
        >
          {stepsStage.map((x) => {
            return <Step title={x.title} />;
          })}
        </Steps>

        <Card>
          <div className="mail_procurement_details">
            <div className="procurement_progress">
              <div className="procurement_details">
                <Row gutter={20}>
                  <Col lg={7} className="procurement_col">
                    <div className="procurement_title"> Ref:</div>
                    <div className="procurement_input">
                      {
                        tenderCeadPrincipleInvoiceDetailData?.referenceDto
                          ?.referenceCode
                      }
                    </div>
                  </Col>
                  <Col lg={17} className="procurement_col">
                    <div className="procurement_title"> Title:</div>
                    <div className="procurement_input">
                      {tenderCeadPrincipleInvoiceDetailData?.contract}
                    </div>
                  </Col>
                  <Col lg={24} className="procurement_col">
                    <div className="procurement_title ">Amount:</div>
                    <div className="procurement_input">
                      {tenderCeadPrincipleInvoiceDetailData?.currencyDto?.abbvr}
                      : {/* {tenderCeadPrincipleInvoiceDetailData?.amount} */}
                      {tenderCeadPrincipleInvoiceDetailData?.amount
                        ? negativeNumColor(
                            tenderCeadPrincipleInvoiceDetailData?.amount
                          )
                        : null}
                    </div>
                    <div className="procurement_input">
                      {tenderCeadPrincipleInvoiceDetailData?.amountNpr
                        ? "NPR: "
                        : ""}
                      {tenderCeadPrincipleInvoiceDetailData?.amountNpr
                        ? negativeNumColor(
                            tenderCeadPrincipleInvoiceDetailData?.amountNpr
                          )
                        : null}
                    </div>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col lg={24} className="procurement_col">
                    <div className="procurement_title ">Invoice:</div>
                    <div
                      className="procurement_input"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {tenderCeadPrincipleInvoiceDetailData?.uploadedFileDtos &&
                        tenderCeadPrincipleInvoiceDetailData?.uploadedFileDtos
                          .length > 0 && (
                          <>
                            {tenderCeadPrincipleInvoiceDetailData?.uploadedFileDtos.map(
                              (item, index) => (
                                <AllFileViewer
                                  file={item}
                                  cqBaseUrl={cqBaseUrl}
                                  token={token}
                                  height={600}
                                  width={900}
                                />
                              )
                            )}
                          </>
                        )}
                    </div>
                  </Col>
                </Row>

                <Row gutter={20}>
                  {data?.map((x) => {
                    return (
                      <>
                        {x?.isApproved && (
                          <>
                            <Col lg={24} className="procurement_col">
                              <div
                                className="procurement_title"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>
                                  Remarks ({x?.participantUserDto?.employeeName}
                                  ,{x?.participantTypeDto?.displayName ?? ""})
                                </span>

                                <span style={{ marginRight: "8px" }}>
                                  {moment(x?.approvedRejectDate).format(
                                    "YYYY-MM-DD"
                                  )}
                                </span>
                              </div>
                              <div className="procurement_input">
                                {x?.approveRejectRemarks}
                              </div>
                            </Col>
                          </>
                        )}
                      </>
                    );
                  })}
                </Row>
              </div>
            </div>

            <div className="leave-types" style={{ marginTop: "1.2em" }}>
              <h5
                style={{
                  fontWeight: "600",
                  fontSize: "1.1em",
                }}
              >
                List of last invoice
              </h5>
              <div className="">
                <Table
                  className="organizer__tables remove-counter"
                  columns={tblCol}
                  dataSource={
                    singleTenderCeadData?.tenderCEADPaymentReceivedPrincipalInvoiceLogDto
                  }
                  pagination={false}
                  loading={singleTenderCeadLoading}
                />
              </div>
            </div>

            <div className="leave-types" style={{ marginTop: "1.2em" }}>
              <h5
                style={{
                  fontWeight: "600",
                  fontSize: "1.1em",
                }}
              >
                List of Tender CEAD Summary
              </h5>
              <div className="">
                <Table
                  className="organizer__tables remove-counter"
                  scroll={{ x: 800 }}
                  columns={ceadSummaryTbl}
                  dataSource={ceadTenderSummaryResponse}
                  pagination={false}
                  loading={ceadTenderSummaryLoading}
                />
              </div>
            </div>
          </div>
        </Card>

        {/* onSlack */}
        {/* {userRole?.includes("TenderInvoiceManagementApproval") ||
        userRole?.includes("TenderInvoiceAccountApproval") ? (
          (isApprovedBy !== "SS_AccountantApproved" &&
            userRole?.includes("TenderInvoiceAccountApproval")) ||
          (isApprovedBy !== "ManagementApproved" &&
            isApprovedBy !== "SS_AccountantApproved" &&
            userRole?.includes("TenderInvoiceManagementApproval")) ||
          (isApprovedBy !== "FS_AccountantApproved" &&
            isApprovedBy !== "SS_AccountantApproved" &&
            userRole?.includes("TenderInvoiceAccountApproval")) ? (
            <Card>
              <Form
                layout="vertical"
                onFinish={onFinish}
                form={approvalRemarkForm}
              >
                <Form.Item
                  label="Remarks"
                  name="approveRejectRemarks"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>

                <div style={{ float: "right", marginTop: "10px" }}>
                  <Button
                    htmlType="submit"
                    onClick={() => {
                      setIsApproval(true);
                    }}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      height: 30,
                      marginRight: 5,
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    className="procurement_approved"
                    htmlType="submit"
                    onClick={() => {
                      setIsApproval(true);
                    }}
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      height: 30,
                    }}
                  >
                    Accept
                  </Button>
                </div>
              </Form>
            </Card>
          ) : userRole?.includes("TenderInvoiceManagementApproval") ? (
            ""
          ) : isApprovedBy === "SS_AccountantApproved" ? (
            <Button
              htmlType="submit"
              // onClick={() => {
              //   history.push({
              //     pathname: "/email-compose",
              //     state: {
              //       applicationType: "ceadInvoice",
              //       idToComposeEmailCead: id,
              //     },
              //   });
              // }}
              style={{
                backgroundColor: "#6d6d6d",
                color: "white",
                height: 30,
                float: "right",
              }}
              target="_blank"
              rel="opener"
            >
              <Link
                to={`/email-compose?applicationType=ceadInvoice&idToComposeEmail=${id}`}
                target="_blank"
                rel="opener"
              >
                Send Email
              </Link>
            </Button>
          ) : (
            ""
          )
        ) : (
          ""
        )} */}

        {userRole?.includes("TenderInvoiceManagementApproval") ||
        userRole?.includes("TenderInvoiceAccountApproval") ? (
          ((isApprovedBy === "FS_AccountantApproved" ||
            isApprovedBy === "SS_AccountantApproved") &&
            userRole?.includes("TenderInvoiceAccountApproval")) ||
          (isApprovedBy === "ManagementApproved" &&
            userRole?.includes("TenderInvoiceManagementApproval")) ? (
            ""
          ) : (
            <Card>
              <Form
                layout="vertical"
                onFinish={onFinish}
                form={approvalRemarkForm}
              >
                <Form.Item
                  label="Remarks"
                  name="approveRejectRemarks"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
                <div style={{ float: "right", marginTop: "10px" }}>
                  <Button
                    htmlType="submit"
                    onClick={() => {
                      setIsApproval(true);
                    }}
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      height: 30,
                      marginRight: 5,
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    className="procurement_approved"
                    htmlType="submit"
                    onClick={() => {
                      setIsApproval(true);
                    }}
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      height: 30,
                    }}
                  >
                    Accept
                  </Button>
                </div>
              </Form>
            </Card>
          )
        ) : (
          ""
        )}
        {isApprovedBy === "SS_AccountantApproved" &&
          userRole.includes("TenderInvoiceAccountApproval") && (
            <Button
              htmlType="submit"
              // onClick={() => {
              //   dispatch(actionForCeadEmailCompose(id));
              // }}
              style={{
                backgroundColor: "#6d6d6d",
                color: "white",
                height: 30,
                float: "right",
              }}
            >
              <Link
                to={`/email-compose?applicationType=ceadInvoice&idToComposeEmail=${id}`}
                target="_blank"
                rel="opener"
              >
                Send Email
              </Link>
            </Button>
          )}
      </Card>
    </>
  );
}
