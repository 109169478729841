import { DAILY_DAIRY, TODO_TYPES } from "services/types";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
import { DailyDairyService } from "./api";
import {
  getTodoEventsPagination,
  updateDailyTaskReducer,
  updateStatusMovetoDailyDiary,
} from "../organizer/todoLists/action";

const dailyDairyService = new DailyDairyService();

export const actionForGetPaginationDailyDairy = (query) => {
  return async (dispatch) => {
    dispatch(init(DAILY_DAIRY.GET_PAGINATION_DAILY_DIARY));

    try {
      const response =
        await dailyDairyService.apiForListingPaginationDailyDairy(query);

      if (response.isSuccess) {
        dispatch(
          success(DAILY_DAIRY.GET_PAGINATION_DAILY_DIARY, response.data)
        );
        return response.data;
      } else {
        dispatch(error(response.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch (err) {
      dispatch.error(err.message || "Unknown error occured");
      throw err;
    } finally {
      dispatch(finish(DAILY_DAIRY.GET_PAGINATION_DAILY_DIARY));
    }
  };
};

export const actionForTransfertoDailyDairy = (id, query) => {
  return async (dispatch) => {
    dispatch(init(DAILY_DAIRY.TRANSFER_TO_DAILY_DIARY));
    try {
      const response = await dailyDairyService.apiForTransferToDailyDairy(
        id,
        query
      );

      if (response.isSuccess) {
        dispatch(success(DAILY_DAIRY.TRANSFER_TO_DAILY_DIARY, response.data));
        // another action
        // let query = "MaxResultCount=100&SkipCount=0&IsDashboardtask=true&IsToday=1&NormalOrPhoneOrVisit=0&NormalOrPhoneOrVisit=1&NormalOrPhoneOrVisit=2"
        // await dispatch(getTodoEventsPagination(query));
        dispatch(
          updateStatusMovetoDailyDiary(
            TODO_TYPES.TODO_LISTS_STATUS,
            response.data
          )
        );

        if (query) {
          message.success("Transferred to Yesterday task");
        } else {
          message.success("Transferred to Daily Dairy");
        }

        return response.data;
      } else {
        // dispatch(error(response.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch (err) {
      dispatch(error(err.message || "Unknown error occured"));
      throw err;
    } finally {
      dispatch(finish(DAILY_DAIRY.TRANSFER_TO_DAILY_DIARY));
    }
  };
};

export const actionForTransfertoDailyDairyCombine = (id, query, remarks) => {
  return async (dispatch) => {
    dispatch(init(DAILY_DAIRY.TRANSFER_TO_DAILY_DIARY));
    try {
      // console.log("actionForTransfertoDailyDairyCombine CALLED");
      // console.log("id: ", id);
      // console.log("query: ", query);
      const response = await dailyDairyService.apiForTransferToDailyDairy(
        id,
        query
      );

      if (response.isSuccess) {
        dispatch(success(DAILY_DAIRY.TRANSFER_TO_DAILY_DIARY, response.data));
        // another action
        // let query = "MaxResultCount=100&SkipCount=0&IsDashboardtask=true&IsToday=1&NormalOrPhoneOrVisit=0&NormalOrPhoneOrVisit=1&NormalOrPhoneOrVisit=2"
        // await dispatch(getTodoEventsPagination(query));
        dispatch(
          updateStatusMovetoDailyDiary(
            TODO_TYPES.TODO_LISTS_STATUS,
            response.data
          )
        );

        if (query) {
          message.success("Transferred to Yesterday task");
        } else {
          message.success("Transferred to Daily Dairy");
        }

        // remarks
        await dispatch(actionForTransfertoDailyDiaryRemarks(remarks));

        return response.data;
      } else {
        // dispatch(error(response.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch (err) {
      dispatch(error(err.message || "Unknown error occured"));
      throw err;
    } finally {
      dispatch(finish(DAILY_DAIRY.TRANSFER_TO_DAILY_DIARY));
    }
  };
};

// remarks
export const actionForTransfertoDailyDiaryRemarks = (body) => {
  return async (dispatch) => {
    dispatch(init(DAILY_DAIRY.TRANSFER_TO_DAIRY_REMARKS));
    const response = await dailyDairyService.apiForMovetoDairyRemarks(body);
    // dispatch(finish(DAILY_DAIRY.TRANSFER_TO_DAIRY_REMARKS));
    try {
      if (response.isSuccess) {
        dispatch(success(DAILY_DAIRY.TRANSFER_TO_DAIRY_REMARKS, response.data));
        message.success("Added Remarks");
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
      }
    } catch (err) {
      throw new Error(response.errorMessage);
    } finally {
      dispatch(finish(DAILY_DAIRY.TRANSFER_TO_DAIRY_REMARKS));
    }
  };
};

export const actionForUpdateDailyDairy = (id, body) => {
  return async (dispatch) => {
    dispatch(init(DAILY_DAIRY.UPDATE_DAILY_DIARY));

    try {
      const response = await dailyDairyService.apiForDailyDiaryUpdate(id, body);

      if (response.isSuccess) {
        dispatch(success(DAILY_DAIRY.UPDATE_DAILY_DIARY, response.data));
        message.success("Updated");
        return response.data;
      } else {
        dispatch(error(response.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch (err) {
      // dispatch(error(err.message | 'Unknown error occured'));
      throw err;
    } finally {
      dispatch(finish(DAILY_DAIRY.UPDATE_DAILY_DIARY));
    }
  };
};

export const actionForDailyDairyComplete = (id) => {
  return async (dispatch) => {
    dispatch(init(DAILY_DAIRY.COMPLETE_DAILY_DIARY));

    try {
      const response = await dailyDairyService.apiForDailyDiaryComplete(id);

      if (response.isSuccess) {
        dispatch(success(DAILY_DAIRY.COMPLETE_DAILY_DIARY, response.data));

        dispatch(
          updateDailyTaskReducer(TODO_TYPES.TODO_LISTS_UPDATE, response.data)
        );
        // console.log("ACTION response.data: ", response.data?.statusDto);
        return response.data;
      } else {
        dispatch(error(response.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch (err) {
      dispatch(error(err.message | "Unknown error occured"));
      throw err;
    } finally {
      dispatch(finish(DAILY_DAIRY.COMPLETE_DAILY_DIARY));
    }
  };
};

export const actionForSaveDailyDairy = (body) => {
  return async (dispatch) => {
    dispatch(init(DAILY_DAIRY.SAVE_DAILY_DAIRY));

    const response = await dailyDairyService.apiForSaveDailyDairy(body);
    dispatch(finish(DAILY_DAIRY.SAVE_DAILY_DAIRY));
    if (response.isSuccess) {
      dispatch(success(DAILY_DAIRY.SAVE_DAILY_DAIRY, response.data));
      message.success("Successfully save the daily task");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForDeleteDiary = (id) => {
  return async (dispatch) => {
    dispatch(init(DAILY_DAIRY.DELETE_DAILY_DIARY));
    const response = await dailyDairyService.apiForDeleteDairy(id);
    dispatch(finish(DAILY_DAIRY.DELETE_DAILY_DIARY));

    // let response = {
    //     isSuccess: true,
    //     data: {
    //         id: "1c04298e-9cf4-4053-a89d-cf9b8a275c03",
    //         todoEventId: "49fa1f68-cf1a-4935-9128-3943e2d8335d",
    //         dailyDiaryDate: "2024-08-22T00:00:00",
    //         stutusId: "4de2c2e9-aed1-4b28-bec0-6e52ab148f87",
    //         statusName: "Start",
    //         userId: "d838b480-8e6b-f3fc-5bf1-3a07ced19801",
    //         userName: "sp",
    //         title: "task 3",
    //         remarks: "feri start gareko",
    //         isActive: false,
    //         statusDto: {
    //             id: "4de2c2e9-aed1-4b28-bec0-6e52ab148f87",
    //             name: "Start",
    //             colorHexValue: "#6a71ad",
    //             colorFontHexValue: "#ffffff"
    //         },
    //         transferToDailyTask: {
    //             status: true
    //         }
    //     }
    // }

    if (response.isSuccess) {
      dispatch(success(DAILY_DAIRY.DELETE_DAILY_DIARY, response.data));
      message.success("Deleted");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// EXCEL
export const actionForExportDairyToExcel = (q) => {
  return async (dispatch) => {
    dispatch(init(DAILY_DAIRY.EXPORT_DAILY_DAIRY));
    const response = await dailyDairyService.apiForExportDairyToExcel(q);
    if (response.isSuccess) {
      dispatch(success(DAILY_DAIRY.EXPORT_DAILY_DAIRY, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(DAILY_DAIRY.EXPORT_DAILY_DAIRY));
  };
};

// updated
export const updateDailyDiaryReducer = (
  GET_PAGINATION_DAILY_DIARY_UPDATE,
  data
) => {
  return {
    type: DAILY_DAIRY.GET_PAGINATION_DAILY_DIARY_UPDATE,
    payload: data,
  };
};

// status
export const changeDailyDiaryStatus = (data) => {
  return {
    type: DAILY_DAIRY.GET_PAGIGATION_DAILY_DIARY_STATUS,
    payload: data,
  };
};
