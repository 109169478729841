import moment from "moment";

export const handleSpecialDate = (date) => {
  if (!date) {
    return "No Date";
  }
  if (date === "0001-01-01T00:00:00") {
    return "No Date";
  }
  return moment(date).format("YYYY MMM DD");
};
