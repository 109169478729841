import { useState, useEffect } from "react";
import { Button } from "antd";
import LoanDrawer from "./LoanDrawer";
import LoanTable from "./Includes/LoanTable";
import { useDispatch, useSelector } from "react-redux";
import { getLoanApplicationPagination } from "services/redux/requistions/loanApplication/action";
import { ApprovalTable } from "./Includes/ApprovalTable";
import LoanDetails from "./Includes/LoanDetails";
import { isEmpty } from "lodash";

export const LoanApplication = () => {
  const dispatch = useDispatch();
  const { loanApplicationPagination, loanApplicationPaginationLoading } =
    useSelector((state) => state.loanApplication);
  console.log("loanApplicationPagination", loanApplicationPagination);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showLoanDrawer, setShowLoanDrawer] = useState(false);
  const [selectedLoanApp, setSelectedLoanApp] = useState({});
  const [loanDetailsId, setLoanDetailsId] = useState("");
  const [hideLoanDrawer, setHideLoanDrawer] = useState(false);
  const [showLoanDetails, setShowLoanDetails] = useState(false);
  const [loanDetails, setLoanDetails] = useState([]);
  const pageSize = 20;

  console.log("loanDetailsId", loanDetailsId, "loanDetails", loanDetails);

  useEffect(() => {
    getData();
  }, [searchKeyword]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    SearchKeyword = searchKeyword
  ) => {
    dispatch(
      getLoanApplicationPagination(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword,
        },
        "NoApproval"
      )
    );
  };

  useEffect(() => {
    const getLoanDetailsData = loanApplicationPagination.items?.filter(
      (item) => {
        if (item.id == loanDetailsId) return item;
      }
    );
    setLoanDetails(getLoanDetailsData);
  }, [loanDetailsId]);

  const onOpenLoanApplicationDrawer = () => setShowLoanDrawer(!showLoanDrawer);
  const onCloseLoanDrawer = () => setShowLoanDrawer(false);

  return (
    <div className="layout">
      <div className="organizer fullCalendar">
        <div className="calender-body">
          <h3 className="header__all">
            <span className="title">Loan Application</span>
            <Button
              className="calendar-create"
              onClick={() => {
                setShowLoanDrawer(true);
                setHideLoanDrawer(false);
                setLoanDetailsId("");
              }}
            >
              + Create
            </Button>
          </h3>

          <LoanTable
            loanApplicationPagination={loanApplicationPagination}
            setLoanDetailsId={setLoanDetailsId}
            setShowLoanDetails={setShowLoanDetails}
            setHideLoanDrawer={setHideLoanDrawer}
            loanDetailsId={loanDetailsId}
            loanApplicationPaginationLoading={loanApplicationPaginationLoading}
            onOpenLoanApplicationDrawer={onOpenLoanApplicationDrawer}
            setSelectedLoanApp={setSelectedLoanApp}
          />
          <h3 className="header__all">
            <span className="title">To Approve Loan Application</span>
          </h3>
          <ApprovalTable />
        </div>
      </div>
      {/* leave application form or leave details */}
      {showLoanDetails && !isEmpty(loanDetails) ? (
        // <div
        //   className={`approval-leave-right-wrapper ${
        //     hideLoanDrawer && "hide-approval-leave-right-wrapper"
        //   }`}
        // >
        //   <div className="leave-details-header-wrapper">
        //     <h2>Loan Details</h2>
        //   </div>
        //   <div className="leave-details-info-wrapper">
        //     <div className="leave-details-info-items">
        //       <div className="leave-details-info-item">
        //         <LoanDetails
        //           // loanDetails={loanDetails}
        //           setHideLoanDrawer={setHideLoanDrawer}
        //         />
        //       </div>
        //     </div>
        //   </div>
        //   {/* end of leave application form */}
        // </div>
        <LoanDetails
          loanDetails={loanDetails}
          drawerVisibility={hideLoanDrawer}
          setHideLoanDrawer={setHideLoanDrawer}
          onAddUpdateDrawerClose={setHideLoanDrawer}
          setLoanDetails={setLoanDetails}
        />
      ) : (
        <LoanDrawer
          drawerVisibility={showLoanDrawer}
          onCloseLoanDrawer={onCloseLoanDrawer}
          selectedLoanApp={selectedLoanApp}
        />
      )}
    </div>
  );
};
