import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Skeleton } from "antd";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import {
  contactPersonFav,
  getExpertPagnination,
} from "../../../services/redux/contact/companypeople/action";

const AddUpdateCompanyTable = ({
  showAddUpdatePersonDrawer,
  showUpdateProfileDrawer,
  contactCompanyId,
  rowEventhandlers,
  setRowPersonClassName,
  searchPersonCompany,
  contactPersonGetDetailsResponse,
  selectedMailTypeAndMailData,
  setOnClickPerson,
  onClickPerson,
}) => {
  const dispatch = useDispatch();
  const { expertPersonPaginationResponse, expertPersonPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 15;

  useEffect(() => {
    if (!expertPersonPaginationResponse) return;
    setdata(expertPersonPaginationResponse?.items);
    setTotalCount(expertPersonPaginationResponse?.totalCount);
    let newCurrentPage =
      expertPersonPaginationResponse?.items?.length / pageSize;
    setCurrentPage(newCurrentPage);
  }, [expertPersonPaginationResponse]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    SearchKeyword,
    ForPagination = false
  ) => {
    dispatch(
      getExpertPagnination(
        {
          MaxResultCount,
          SkipCount,
        },
        ForPagination
      )
    );
  };

  const fetchMoreData = () => {
    getData(pageSize, currentPage * pageSize, searchPersonCompany, true);
  };

  const contactPeronsPagination = () => {
    return (
      <>
        <div>
          {data?.map((person, key) => {
            const backGroundColor = key % 2 == 0 ? "" : "#f2f2f2";

            return (
              <>
                <div
                  style={{ background: backGroundColor, height: "30px" }}
                  className={`reference ${
                    setRowPersonClassName(person) && "active"
                  }`}
                  onClick={() => {
                    rowEventhandlers(person);
                    setOnClickPerson(!onClickPerson);
                  }}
                  key={key}
                >
                  <div className="reference__wrapper">
                    <div className="reference__wrapper--contents">
                      <div className="reference__body">
                        <div
                          className="reference__body--right "
                          onClick={() => {
                            dispatch(
                              contactPersonFav(
                                { contactPersonId: person.contactPersonId },
                                contactPersonGetDetailsResponse
                              )
                            );
                          }}
                        >
                          {person.isFavorite ? (
                            <StarFilled style={{ color: "#ffd036" }} />
                          ) : (
                            <StarOutlined style={{ color: "#ffd036" }} />
                          )}
                        </div>
                        <div className="reference__body--right">
                          <span className="reference__body--5right">
                            <Tooltip
                              title={`${person.expertFullName}  ${
                                person.expertNickName === null ||
                                !person.expertNickName
                                  ? ""
                                  : `[${person.expertNickName}],`
                              } ${person.designationName} `}
                              placement="bottomLeft"
                            >
                              {person.expertFullName}
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <p
        style={{
          background: "#d8ddeb",
          fontWeight: "bold",
          padding: "5px",
          marginBottom: "0px",
        }}
      >
        Expert
      </p>
      <div
        id="personScroll"
        style={{
          height: "50%",
          // overflowY: "auto",
        }}
      >
        {expertPersonPaginationLoading && (
          <Skeleton paragraph={{ rows: 10 }} active />
        )}
        {!isEmpty(data) ? (
          <InfiniteScroll
            style={{ height: "300px" }}
            dataLength={expertPersonPaginationResponse?.items.length}
            hasMore={
              expertPersonPaginationResponse?.totalCount >
              expertPersonPaginationResponse?.items.length
            }
            next={fetchMoreData}
            loader={<Skeleton paragraph={{ rows: 2 }} active />}
            scrollableTarget="personScroll"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>End of Expert</b>
              </p>
            }
          >
            {contactPeronsPagination()}
          </InfiniteScroll>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AddUpdateCompanyTable;
