import { CEADAPPROVAL_TYPES, GLOBAL } from "services/types";

const INITIAL_STATE = {
  ceadApprovalDetails: [],
  ceadApprovalDetailsLoading: false,
};
export default function ceadApprovalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CEADAPPROVAL_TYPES.CEADAPPROVAL_GET_DETAILS_INIT:
      return {
        ...state,
        ceadApprovalDetails: [],
        ceadApprovalDetailsLoading: true,
      };

    case CEADAPPROVAL_TYPES.CEADAPPROVAL_GET_DETAILS_SUCCESS:
      return {
        ...state,
        ceadApprovalDetails: action.payload,
        ceadApprovalDetailsLoading: false,
      };
    case CEADAPPROVAL_TYPES.CEADAPPROVAL_GET_DETAILS_FINISH:
      return {
        ...state,
        ceadApprovalDetailsLoading: false,
      };
    case GLOBAL.GLOBAL_ERROR:
      return {
        ...state,
        ceadApprovalDetailsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
