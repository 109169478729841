import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox, Modal, Typography } from "antd";
import LCDetailsForm from "./lcDetails/form";
import PerformanceBoundForm from "./performanceBound/form.js";
import LCDetails from "./lcDetails";
import PerformanceBoundDetails from "./performanceBound";
import AdvancedDetailsForm from "./advancedDetails/form";
import AdvancedPaymentDetails from "./advancedDetails";

const { Text, Title } = Typography;

export default function CeadView({
  anotherStageLCDetailForm,
  anotherStagePerformanceBoundForm,
  advancedDetailsForm,
  singleTenderCeadData,
  setIsRenewalRecord,
  isRenewalRecord,
  currencyOptions,
}) {
  const { tenderCeadBidBoundLoading } = useSelector(
    (state) => state.ceadReducer
  );

  const code = singleTenderCeadData?.referenceDto?.code;

  const [show, setShow] = useState({
    isKey: "",
    isVariation: false,
    isExpandAll: false,
  });
  const [showLCForm, setShowLCForm] = useState(false);

  const arrowRight = {
    border: "solid black",
    borderWidth: "0 2px 2px 0",
    display: "inline-block",
    padding: "3px",
    marginLeft: "5px",
    marginBottom: show ? "3px" : "",
    transform: show ? "rotate(45deg)" : "rotate(-45deg)",
    transition: "0.2s ease-in-out",
  };

  const handleForm = () => {
    setShowLCForm(true);
    anotherStageLCDetailForm?.resetFields();
    anotherStagePerformanceBoundForm?.resetFields();
    advancedDetailsForm?.resetFields();
  };

  const children = () => {
    return (
      <span style={{ float: "right", marginRight: "15px" }}>
        <Text onClick={handleForm} className="cead-stage-on-add">
          Add{" "}
        </Text>
      </span>
    );
  };

  const accViewListOptions = [
    {
      key: "1",
      label: "LC Details",
      children: children(),
      content: (
        <div style={{}}>
          <LCDetails singleTenderCeadData={singleTenderCeadData} />
        </div>
      ),
    },

    {
      key: "2",
      label: "Performance Bond",
      children: children(),
      content: (
        <div style={{}}>
          <PerformanceBoundDetails
            singleTenderCeadData={singleTenderCeadData}
          />
        </div>
      ),
    },

    // {
    //   key: "3",
    //   label: "Raised Fee Invoice Log",
    //   children: children(),
    //   content: "HEY RAISED FEE INVOICE LOG",
    // },

    {
      key: "4",
      label: "Advanced Payment Guarantee",
      children: children(),
      content: (
        <div style={{}}>
          <AdvancedPaymentDetails singleTenderCeadData={singleTenderCeadData} />
        </div>
      ),
    },

    // {
    //   key: "5",
    //   label: "Fee Inv Payment Status",
    //   children: children(),
    //   content: "HEY Inv Payment Status",
    // },
  ];

  useEffect(() => {
    if (!tenderCeadBidBoundLoading) {
      setShowLCForm(false);
    }
  }, [tenderCeadBidBoundLoading]);

  return (
    <>
      <div
        style={{
          margin: "10px 0px",
          background: "#DFF0FF",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {singleTenderCeadData?.contract}
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              setShow({ ...show, isExpandAll: true });
            } else {
              setShow({ ...show, isExpandAll: false });
            }
          }}
          checked={show.isExpandAll}
        >
          Expand All
        </Checkbox>
      </div>

      {accViewListOptions.map((x) => {
        return (
          <>
            <Title
              level={5}
              style={{ background: "#D8DDEB" }}
              onClick={() => {
                setShow({
                  isKey: x?.key,
                  isVariation:
                    x?.key === show?.isKey && show.isVariation === true
                      ? false
                      : x?.key === show?.isKey && show.isVariation === false
                      ? true
                      : true,
                });
              }}
              key={x.key}
            >
              <i
                style={{
                  ...arrowRight,
                  marginBottom:
                    (show.isKey === x?.key && show.isVariation) ||
                    show.isExpandAll
                      ? "3px"
                      : "",
                  transform:
                    (show.isKey === x?.key && show.isVariation) ||
                    show.isExpandAll
                      ? "rotate(45deg)"
                      : "rotate(-45deg)",
                }}
              ></i>{" "}
              {x?.label}
              {x?.children}
            </Title>
            {((x?.key === show.isKey && show.isVariation) ||
              show.isExpandAll) &&
              x.content}
          </>
        );
      })}

      <Modal
        title={
          show.isKey === "1"
            ? `L/C Details of ${code}`
            : show.isKey === "2"
            ? `Performance Bond Details ${code}`
            : `Advanced Payment Guarantee Details ${code}`
        }
        visible={showLCForm}
        footer={null}
        onCancel={() => {
          setShowLCForm(false);
        }}
      >
        {show.isKey === "1" && (
          <LCDetailsForm
            currencyOptions={currencyOptions}
            anotherStageLCDetailForm={anotherStageLCDetailForm}
            singleTenderCeadData={singleTenderCeadData}
            setIsRenewalRecord={setIsRenewalRecord}
            isRenewalRecord={isRenewalRecord}
          />
        )}

        {show.isKey === "2" && (
          <PerformanceBoundForm
            currencyOptions={currencyOptions}
            anotherStagePerformanceBoundForm={anotherStagePerformanceBoundForm}
            singleTenderCeadData={singleTenderCeadData}
            setIsRenewalRecord={setIsRenewalRecord}
            isRenewalRecord={isRenewalRecord}
          />
        )}

        {show.isKey === "4" && (
          <AdvancedDetailsForm
            currencyOptions={currencyOptions}
            advancedDetailsForm={advancedDetailsForm}
            singleTenderCeadData={singleTenderCeadData}
            setIsRenewalRecord={setIsRenewalRecord}
            isRenewalRecord={isRenewalRecord}
          />
        )}
      </Modal>
    </>
  );
}
