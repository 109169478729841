import { EMAIL_TYPES, GLOBAL, COMPANY_PEOPLE_TYPES } from "../../../types";

const INITIAL_STATE = {
  instanceMessageCenterPagination: [],
  instanceMessageCenterPaginationLoading: false,

  replyInstanceMessageResp: [],
  replyInstanceMessageRespLoading: false,

  instanceMessageUpdateResp: [],
  instanceMessageUpdateRespLoading: false,

  contactQuickAddEmailRespsonse: null,
  contactQuickAddEmailLoading: false,

  documentInstanceMessageResp: [],
  documentInstanceMessageLoading: false,
  documentInstanceMessageContinueLoading: false,
};

export default function instanceMessageReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // instance message pagination
    case EMAIL_TYPES.INSTANCE_MESSAGE_CENTER_PAGINATION_INIT:
      return {
        ...state,
        instanceMessageCenterPagination: [],
        instanceMessageCenterPaginationLoading: true,
      };
    case EMAIL_TYPES.INSTANCE_MESSAGE_CENTER_PAGINATION_SUCCESS:
      return {
        instanceMessageCenterPagination: action.payload,
        instanceMessageCenterPaginationLoading: false,
      };
    case EMAIL_TYPES.INSTANCE_MESSAGE_CENTER_PAGINATION_FINISH:
      return {
        ...state,
        instanceMessageCenterPagination: [],
        instanceMessageCenterPaginationLoading: false,
      };

    case COMPANY_PEOPLE_TYPES.CONTACT_QUICK_EMAIL_SUCCESS:
      // console.log("main component", action.payload);
      return {
        ...state,
      };

    // instance message reply
    case EMAIL_TYPES.REPLY_INSTANCE_MESSAGE_INIT:
      return {
        ...state,
        replyInstanceMessageResp: [],
        replyInstanceMessageRespLoading: true,
      };
    case EMAIL_TYPES.REPLY_INSTANCE_MESSAGE_SUCCESS:
      return {
        ...state,
        replyInstanceMessageResp: action.payload,
        replyInstanceMessageRespLoading: false,
        instanceMessageCenterPagination: {
          items: [
            action.payload,
            ...state?.instanceMessageCenterPagination?.items,
          ],
          totalCount: state.instanceMessageCenterPagination.totalCount + 1,
          changeIndex: true,
        },
      };
    case EMAIL_TYPES.REPLY_INSTANCE_MESSAGE_FINISH:
      // console.log("action triggerer");
      return {
        ...state,
        replyInstanceMessageResp: [],
        instanceMessageCenterPagination: {
          ...state.instanceMessageCenterPagination,
          changeIndex: false,
        },

        replyInstanceMessageRespLoading: false,
      };
    case EMAIL_TYPES.REPLY_INSTANCE_MESSAGE_RESP_DELETE:
      return {
        ...state,
        replyInstanceMessageResp: [],
        replyInstanceMessageRespLoading: false,
      };

    // instance message update
    case EMAIL_TYPES.INSTANCE_MESSAGE_UPDATE_INIT:
      return {
        ...state,
        instanceMessageUpdateResp: [],
        instanceMessageUpdateRespLoading: true,
      };
    case EMAIL_TYPES.INSTANCE_MESSAGE_UPDATE_SUCCESS:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        instanceMessageUpdateResp: action.payload,
        instanceMessageUpdateRespLoading: false,
        instanceMessageCenterPagination: {
          items: state.instanceMessageCenterPagination.items.map(
            (instanceMsg) => {
              if (instanceMsg.id === action.payload.id) {
                return action.payload;
              } else {
                return instanceMsg;
              }
            }
          ),
          totalCount: state.instanceMessageCenterPagination.totalCount,
        },
      };
    case EMAIL_TYPES.INSTANCE_MESSAGE_UPDATE_FINISH:
      return {
        ...state,
        instanceMessageUpdateRespLoading: false,
      };
    case EMAIL_TYPES.REPLY_INSTANCE_MESSAGE__UPDATE_RESP_DELETE:
      return {
        ...state,
        instanceMessageUpdateResp: [],
        instanceMessageUpdateRespLoading: false,
      };
    case EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE_INIT:
      return {
        ...state,
        documentInstanceMessageLoading: true,
      };
    case EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE_FINISH:
      return {
        ...state,
        documentInstanceMessageLoading: false,
      };
    case EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE_SUCCESS:
      return {
        ...state,
        documentInstanceMessageLoading: false,
      };
    case EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE_CONTINUE_INIT:
      return {
        ...state,
        documentInstanceMessageContinueLoading: true,
      };
    case EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE_CONTINUE_FINISH:
      return {
        ...state,
        documentInstanceMessageContinueLoading: false,
      };
    case EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE_CONTINU_SUCCESS:
      return {
        ...state,
        documentInstanceMessageContinueLoading: false,
      };

    case EMAIL_TYPES.GET_ALL_MESSAGE_TYPE_INIT:
      return {
        ...state,
        allMessageTypesResponse: [],
        allMessageTypeResponseLoading: true,
      };

    case EMAIL_TYPES.GET_ALL_MESSAGE_TYPE_SUCCESS:
      return {
        ...state,
        allMessageTypesResponse: action.payload,
        allMessageTypeResponseLoading: false,
      };

    case EMAIL_TYPES.GET_INSTANCE_MESSAGE_USER_SUGGESTION_INIT:
      return {
        ...state,
        allUserSuggestionResponse: [],
        allUserSuggestionResponseLoad: true,
      };

    case EMAIL_TYPES.GET_INSTANCE_MESSAGE_USER_SUGGESTION_SUCCESS:
      return {
        ...state,
        allUserSuggestionResponse: action.payload,
        allUserSuggestionResponseLoad: false,
      };

    case EMAIL_TYPES.BIRTHDAY_ANIVERSARY_MESSAGE_INIT:
      return {
        ...state,
        birthdayAniversaryMessageResponse: [],
        birthdayAniversaryMessageLoading: true,
      };
    case EMAIL_TYPES.BIRTHDAY_ANIVERSARY_MESSAGE_SUCCESS:
      return {
        ...state,
        birthdayAniversaryMessageResponse: action.payload,
        birthdayAniversaryMessageLoading: false,
      };
    case EMAIL_TYPES.BIRTHDAY_ANIVERSARY_MESSAGE_FINISH:
      return {
        ...state,
        birthdayAniversaryMessageLoading: false,
      };
    case EMAIL_TYPES.LATE_LOGIN_SMS_INIT:
      return {
        ...state,
        lateLoginSmsResponse: [],
        lateLoginSmsLoading: true,
      };
    case EMAIL_TYPES.LATE_LOGIN_SMS_SUCCESS:
      return {
        ...state,
        lateLoginSmsResponse: action.payload,
        lateLoginSmsLoading: false,
      };
    case EMAIL_TYPES.LATE_LOGIN_SMS_FINISH:
      return {
        ...state,
        lateLoginSmsLoading: false,
      };
    case GLOBAL.GLOBAL_ERROR:
      return {
        ...state,
        replyInstanceMessageRespLoading: false,
        birthdayAniversaryMessageLoading: false,
      };
    default:
      return { ...state };
  }
}
