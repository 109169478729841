import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { map } from "lodash";
import {
  getTodoEventsPagination,
  toDoGetDetails,
  todoEventGetAllCounter,
} from "services/redux/organizer/todoLists/action";
import { getAllUsers } from "services/remanentCalls";
import {
  addToDoEvent,
  updateToDoEvent,
} from "services/redux/organizer/todoLists/action";
import storage from "utils/storage";
import { mailReferenceListing } from "services/redux/email/mailMessageCenter/action";
import { getReferenceListing } from "services/redux/Reference/action";

import specialFlag0 from "../../../assets/svg/flagSpecial0.svg";
import specialFlag1 from "../../../assets/svg/flagSpecial3.svg";

import {
  Select,
  Switch,
  Row,
  Col,
  Table,
  Tooltip,
  Input,
  Image,
  Modal,
  Spin,
  Card,
  Dropdown,
  Menu,
} from "antd";

import { PhoneOutlined, UserOutlined } from "@ant-design/icons";

import { LogoImage } from "utils/imageConstants";
import {
  toDoMarkAsComplete,
  toDoMarkAsFlagged,
} from "../../../services/redux/organizer/todoLists/action";
import { useDispatch, useSelector } from "react-redux";
import ToDoForm from "./ToDoForm";
import ControlledBoard from "./UncontrolledBoard";
import "components/tables/tableCss.css";

const filter = "./assets/svg/filterstroke-16.svg";

const { Option } = Select;

const ToDoLists = (props) => {
  const dispatch = useDispatch();
  const toDoEventsState = useSelector((state) => state.toDoEvents);
  const { toDoGetDetailsResponse } = useSelector((state) => state.toDoEvents);
  const { addTodoEventsLoading, addTodoEventsKanban } = useSelector(
    (state) => state.toDoEvents
  );
  const { tasksUsedReference, tasksUsedReferenceLoading } = useSelector(
    (state) => state.toDoEvents
  );
  const [meetingInvitees, setMeetingInvitees] = useState();
  const [todoEventCandidateDtos, setTodoEventCandidateDtos] = useState([]);
  const [searchValueReference, setSearchValueReference] = useState("");
  const [filterParticipantType, setFilterParticipantType] = useState("");
  const [referenceListing, setReferenceListing] = useState([]);
  const [filterReference, setFilterReference] = useState([]);
  const { toDoMarkItOffResp } = toDoEventsState;
  const { token } = useSelector((state) => state.auth);

  const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [selectedPriorityStatusId, setSelectedPriorityStatusId] = useState("");
  const [selectedProgressStatusId, setSelectedProgressStatusId] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [sortType, setSortType] = useState("");
  const [viewList, setViewList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState();
  const [refkeyword, setRefkeyWord] = useState();
  const [hideCompleted, setHideCompleted] = useState([]);
  const [hideButton, setHideButton] = useState(false);
  const [fitlerReferenceId, setFilterReferenceId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [visibleEventFormDrawer, setVisibleEventFormDrawer] = useState(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [boardView, setBoardView] = useState(false);

  const { allToDoEvents, todoEventsLoading } = toDoEventsState;

  const {
    // data,
    pageSize,
    // currentPage,
    // setCurrentPage,
    organizerEventPriorityListing,
    allEventStatusListing,
    editToDoList,
    gettaskTodoList,
    // handleTableChange,
    taskAssignedTo,
    referenceId,
    searchEmailTender,
    todoRowEventHandler,
    statusTodo,
    // handleChangeEvent,
    showAddTask,
    setShowAddTask,
    showChat,
    setShowChat,
    setHideInternalMessage,
    setChatHeight,
    setSmallChatHeight,
    setchatBox,
    // hideInternalMessage,
    setTaskId,
    taskId,
    isPrivate,
    largeWidth,
    setLargeWidth,
    visitor,
    setVisitor,
    isComplete,
    setIsComplete,
    todoDetails,
    setTodoDetails,
    values,
    setValues,
    isAdvanceClear,
    setIsAdvanceClear,
    taskCat,
    departmentListingResponse,
    remainderListingResponse,
    fixedTaskCategory,
    url,
    setUrl,
    changeCategory,
    setChangeCategoryButton,
    privateTask,
    setSelectedTaskTos,
    setSelectedTaskCcs,
  } = props;

  useEffect(() => {
    if (url && hideButton) {
      const updatedUrl = url.replace("&isCompleted=0", "&isCompleted=1");
      dispatch(getTodoEventsPagination(updatedUrl));
    } else if (url) {
      const updatedUrl = url.replace("&isCompleted=1", "&isCompleted=0");
      dispatch(getTodoEventsPagination(updatedUrl));
    }
  }, [hideButton, url]);

  useEffect(() => {
    props.getData(
      pageSize,
      0,
      isComplete,
      selectedStatusId,
      hideCompleted,
      orderBy,
      sortType,
      selectedPriorityStatusId,
      selectedProgressStatusId,
      searchKeyword,
      taskAssignedTo,
      referenceId
    );
  }, []);

  const location = useLocation();
  const getTaskIdFromUrl = queryString.parse(location.search)?.task || "";

  const setTaskClassName = (record) => {
    return record.id === props.taskId ? "clickRowStyl" : "";
  };
  const onCloseEventFormDrawer = () => {
    setVisibleEventFormDrawer(false);
    // setTodoDetails("");
  };

  const getTaskList = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    IsFavourite_SearchKeyword = "false",
    loadMore = false
  ) => {
    let appendUrl = `&MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;
    if (largeWidth && visitor) {
      appendUrl += `&NormalOrPhoneOrVisit=2`;
    }
    if (largeWidth && !visitor) {
      appendUrl += `&NormalOrPhoneOrVisit=1`;
    }
    if (searchKeyword) {
      appendUrl += `&searchKeyword=${searchKeyword}`;
    }
    if (refkeyword) {
      appendUrl += `&ReferenceCodes=${refkeyword}`;
    }
    if (values.task !== "" || null) {
      appendUrl += `&searchKeyword=${values.task}`;
      setIsAdvanceClear(true);
    }
    if (values.refkeyword !== "" || null) {
      appendUrl += `&ReferenceIds=${values.refkeyword}`;
      if (isAdvancedSearch) {
        setIsAdvanceClear(false);
      } else {
        setIsAdvanceClear(true);
      }
    }
    if (values.watcher !== "" || null) {
      appendUrl += `&SearchByWatcher=${values.watcher}`;
      setIsAdvanceClear(true);
    }
    if (values.asignee !== "" || null) {
      appendUrl += `&SearchByAsignee=${values.asignee}`;
      setIsAdvanceClear(true);
    }
    if (values.startDate !== "" || null) {
      appendUrl += `&SearchDateFrom=${values.startDate}`;
      setIsAdvanceClear(true);
    }
    if (values.endDate !== "" || null) {
      appendUrl += `&SearchDateTo=${values.endDate}`;
      setIsAdvanceClear(true);
    }
    if (isComplete == 0 || isComplete == 1) {
      appendUrl += `&isCompleted=${isComplete}`;
    }

    // if (isFavorite == true) {
    //   appendUrl += `&IsFavourite_SearchKeyword=${IsFavourite_SearchKeyword}`;
    // }else if(isFavorite == false){
    //   appendUrl += undefined;
    // }

    dispatch(getTodoEventsPagination(appendUrl));
  };

  useEffect(() => {
    getTaskList();
  }, [refkeyword, values, isAdvanceClear, searchKeyword]);

  useEffect(() => {
    let appendUrl = `${url}&searchKeyword=${searchKeyword}`;
    dispatch(getTodoEventsPagination(appendUrl));
  }, [searchKeyword]);

  useEffect(() => {
    if (!isEmpty(getTaskIdFromUrl)) {
      dispatch(toDoGetDetails(getTaskIdFromUrl));
      setHideInternalMessage(true);
      setShowChat(true);
      setSmallChatHeight(false);
      setChatHeight(false);
      setchatBox(false);
    }
  }, []);

  useEffect(() => {
    isPrivate
      ? (window.location.hash = "/privateTask")
      : (window.location.hash = "/todoLists");
  }, [toDoGetDetailsResponse]);

  useEffect(() => {
    if (!isEmpty(toDoGetDetailsResponse)) {
      if (toDoGetDetailsResponse?.normalOrPhoneOrVisit == 1) {
        setLargeWidth(true);
        setVisitor(false);
      } else if (toDoGetDetailsResponse?.normalOrPhoneOrVisit == 2) {
        setLargeWidth(true);
        setVisitor(true);
      } else {
        setLargeWidth(false);
        setVisitor(false);
      }
    }
  }, [toDoGetDetailsResponse]);

  function compareSubjects(a, b) {
    if (a.subject < b.subject) {
      return -1;
    } else if (a.subject > b.subject) {
      return 1;
    } else {
      return 0;
    }
  }

  const subMenuCompose = (
    <Menu style={{ minWidth: 100, minHeight: 100 }}>
      {!isEmpty(organizerEventPriorityListing) &&
        organizerEventPriorityListing.map((status, index) => {
          return (
            <Menu.Item
              value={status.id}
              key={status.id}
              onClick={() => {
                let data = {};
                if (!isEmpty(todoDetails)) {
                  data = {
                    dueDate: moment(todoDetails?.dueDate),
                    isActive: todoDetails?.isActive,
                    notes: todoDetails?.notes,
                    organizerEventLabelId: todoDetails?.organizerEventLabelId,
                    organizerEventPriorityId: status?.id,
                    privateUserId: participantFrom,
                    referenceId: todoDetails?.referenceId,
                    startDate: moment(todoDetails?.startDate),
                    subject: todoDetails?.subject,
                    todoEventCandidateDtos: todoDetails?.todoEventCandidateDtos,
                    todoEventStatusId: todoDetails?.todoEventStatusId
                      ? todoDetails?.todoEventStatusId
                      : todoDetails?.todoEventStatusDto?.id,
                  };
                }
                dispatch(
                  updateToDoEvent(
                    data,
                    todoDetails?.id,
                    props?.taskAssignedTo,
                    todoDetails?.referenceId
                  )
                );
              }}
            >
              {status.name}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  function handleVisibleChanges(visible, taskId) {
    dispatch(toDoGetDetails(taskId));
  }

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },

    {
      title:
        largeWidth && visitor && changeCategory
          ? "Visit"
          : largeWidth && !visitor && changeCategory
          ? "Calls"
          : "Tasks",
      key: "subject",
      width: 200,
      ellipsis: true,
      render: (record) => {
        const priorityBackgroundColor =
          record?.organizerEventPriorityDto?.colorHexValue || "red";
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {taskCat !== "private" && !taskCat.includes("-") && (
              <div
                className="mark-it-off"
                style={{ marginRight: 6 }}
                onClick={() => {
                  let data = {};
                  if (!isEmpty(record)) {
                    data = {
                      dueDate: record?.dueDate,
                      isActive: record?.isActive,
                      notes: record?.notes,
                      organizerEventLabelId: record?.organizerEventLabelId,
                      organizerEventPriorityId:
                        record?.organizerEventPriorityDto?.id,
                      privateUserId: record?.privateUserId,
                      referenceId: record?.referenceId,
                      startDate: record?.startDate,
                      isFlagged: record.isFlagged,
                      subject: record?.subject,
                      todoEventCandidateDtos: record?.todoEventCandidateDtos,
                      todoEventStatusId: record?.todoEventStatusId
                        ? record?.todoEventStatusId
                        : record?.todoEventStatusDto?.id,
                      normalOrPhoneOrVisit: record?.normalOrPhoneOrVisit,
                      isToday: record?.isToday == 1 ? 0 : 1,
                    };
                  }
                  const hideState = true;
                  dispatch(
                    updateToDoEvent(
                      data,
                      record?.id,
                      props?.taskAssignedTo,
                      record?.referenceId,
                      hideState
                    )
                  ).then(dispatch(todoEventGetAllCounter()));
                }}
              >
                <img
                  src="./assets/svg/toggle-arrow.svg"
                  height={15}
                  alt="complete"
                />
              </div>
            )}

            <Tooltip title="Have to finish Today">
              <div
                onClick={() => dispatch(toDoMarkAsFlagged(record.id))}
                className="mark-it-flagged"
                style={{ marginRight: "7px" }}
              >
                {record.isFlagged ? (
                  <Image
                    src={specialFlag1}
                    preview={false}
                    style={{ height: "20px", width: "20px" }}
                  />
                ) : (
                  <Image
                    src={specialFlag0}
                    preview={false}
                    style={{ height: "20px", width: "20px" }}
                  />
                )}
              </div>
            </Tooltip>
            <div
              className="mark-it-off"
              style={{ marginRight: 6 }}
              onClick={() => dispatch(toDoMarkAsComplete(record.id))}
            >
              {record.todoEventStatusDto?.name === "Completed" ? (
                <img src="./assets/images/icons/check.png" alt="complete" />
              ) : (
                <img
                  src="./assets/images/icons/incomplete.png"
                  alt="incomplete"
                />
              )}
            </div>
            <div className="mark-it-off" style={{ marginRight: 6 }}>
              {record?.normalOrPhoneOrVisit === 1 ? (
                <PhoneOutlined style={{ transform: "scaleX(-1)" }} />
              ) : (
                <></>
              )}
              {record?.normalOrPhoneOrVisit === 2 ? <UserOutlined /> : <></>}
            </div>
            <div
              onClick={() => {
                dispatch(toDoGetDetails(record?.id));
                //show the drawer
                setHideInternalMessage(true);
                setSmallChatHeight(false);
                setChatHeight(false);
                setchatBox(false);

                const { todoEventCandidateDtos } = record || {};
                const getCandidateIds = (isWatcher) =>
                  todoEventCandidateDtos
                    ?.filter((item) => item?.isWatcher === isWatcher)
                    ?.map((item) => item?.candidateId) || [];

                setSelectedTaskTos(getCandidateIds(false));
                setSelectedTaskCcs(getCandidateIds(true));
              }}
            >
              <span className="merge--contact">
                {!hideButton &&
                !todoEventsLoading &&
                record.todoEventStatusDto?.name === "Completed" ? (
                  <span
                    // className="badge"
                    style={{
                      color: priorityBackgroundColor,
                      // backgroundColor: priorityBackgroundColor,
                      // border: 0,
                    }}
                  >
                    {record?.subject}
                    {/* {record?.subject.charAt(0)} */}
                  </span>
                ) : (
                  <Tooltip title={record.subject} placement="topLeft">
                    <span
                      className="merge--contact"
                      style={{
                        color: priorityBackgroundColor,
                        border: 0,
                      }}
                    >
                      {record?.subject}
                    </span>
                  </Tooltip>
                )}
              </span>
            </div>
          </div>
        );
      },
      sorter: (a, b) => compareSubjects(a, b),
    },

    !isPrivate && {
      title: referenceId ? "" : "Ref",
      //dataIndex: referenceId ? "" : "referenceCode",
      key: referenceId ? "" : "referenceCode",
      width: 100,
      render: (record) => {
        const priorityBackgroundColor =
          record?.organizerEventPriorityDto?.colorHexValue || "red";
        return (
          <div style={{ display: "block", width: "100%" }}>
            <span
              className="text-one-line merge--contacts--reference"
              style={{
                color: priorityBackgroundColor,
                border: 0,
              }}
            >
              {record?.referenceCode}
            </span>
          </div>
        );
      },
      sorter: (a, b) => {
        if (a.referenceCode && b.referenceCode) {
          return a.referenceCode.localeCompare(b.referenceCode);
        }
        if (a.referenceCode && !b.referenceCode) {
          return 1;
        }
        if (!a.referenceCode && b.referenceCode) {
          return -1;
        }
        return 0;
      },
    },
    {
      title: "Creator(From)",
      dataIndex: ["privateUser", "userName"],
      key: "privateUser",
      width: 100,
      render: (text, record) => {
        return (
          <span
            className="badge"
            style={{
              color: "white",
              backgroundColor: "grey",
              border: 0,
              textAlign: "center",
              fontSize: "10px",
              padding: "1px 5px 1px 5px",
            }}
          >
            <Tooltip title={record?.creatorName}>
              {record?.creatorName?.trim()?.split(" ")[0]?.toUpperCase()}
            </Tooltip>
          </span>
        );
      },
    },

    taskCat !== "private" && {
      title: "Assigned(To)",
      key: "todoEventCandidateDtos",
      ellipsis: true,
      width: 130,
      render: (record) => {
        return record?.todoEventCandidateDtos?.map((assigneDetail, index) => {
          if (assigneDetail?.isPrimary) {
            return assigneDetail.profilePicture ? (
              // <div
              // onClick={(e) => {
              //   dispatch(toDoGetDetails(record?.id));
              //   setHideInternalMessage(true);
              //   setShowChat(true);
              //   setSmallChatHeight(false);
              //   setChatHeight(false);
              //   setchatBox(false);
              // }}
              // >
              <Tooltip
                // title={assigneDetail.candidateName}
                title={record?.todoEventCandidateDtos
                  ?.map((x) => {
                    return x?.isPrimary ? x?.candidateName : "";
                  })
                  ?.join(",")}
                placement="topLeft"
              >
                {/* <Image
                    src={cqBaseUrl + "/" + assigneDetail.profilePicture + token}
                    style={{
                      height: 14,
                      width: 14,
                      borderRadius: "50%",
                      marginRight: 7,
                    }}
                    preview={false}
                  /> */}

                <span
                  className="badge"
                  style={{
                    color: "white",
                    backgroundColor: index <= 2 ? "brown" : "",
                    border: 0,
                    textAlign: "center",
                    fontSize: "10px",
                    padding: "1px 5px 1px 5px",
                  }}
                >
                  {/* {assigneDetail?.userName?.toUpperCase()} */}
                  {index <= 1 && assigneDetail?.userName?.toUpperCase()}
                  {index === 2 &&
                    record?.todoEventCandidateDtos?.length > 2 &&
                    "+"}
                </span>
              </Tooltip>
            ) : // </div>
            null;
          }
        });
      },
    },

    taskCat !== "private" && {
      title: "Watcher(Cc)",
      // dataIndex: "candidateNameWatcher",
      ellipsis: true,
      width: 130,
      render: (text, record) => {
        let watcher = [];
        record?.todoEventCandidateDtos?.map((x) => {
          if (x?.isWatcher) {
            watcher?.push(x);
          }
        });

        return watcher?.map((x, index) => {
          return (
            <>
              <Tooltip
                title={watcher
                  ?.map((x) => {
                    return x?.candidateName;
                  })
                  ?.join(",")}
              >
                {/* <Badge count={!x?.isReadMessage ? "R" : ""}> */}
                <span
                  className="badge"
                  style={{
                    color: "white",
                    backgroundColor: index <= 1 ? "green" : "",
                    border: 0,
                    textAlign: "center",
                    fontSize: "10px",
                    display: index >= 2 ? "none" : "",
                  }}
                >
                  {index === 0 && x?.userName.toUpperCase()}
                  {index === 1 && watcher?.length > 1 && "+"}
                </span>
                {/* </Badge> */}
              </Tooltip>
            </>
          );
        });
      },
    },

    {
      title: "Due Date",
      key: "dueDate",
      width: 130,
      render: (record) => {
        const date1 = moment(record?.dueDate);
        const date2 = moment(new Date().now);

        const differenceInDays = date2.diff(date1, "days");
        const priorityColor =
          record?.organizerEventPriorityDto?.colorFontHexValue || "white";
        const priorityBackgroundColor =
          record?.organizerEventPriorityDto?.colorHexValue || "red";
        return (
          <div
          // onClick={() => {
          //   dispatch(toDoGetDetails(record?.id));
          //   setHideInternalMessage(true);
          //   setShowChat(true);
          //   setSmallChatHeight(false);
          //   setChatHeight(false);
          //   setchatBox(false);
          // }}
          >
            <span
              className="merge--contact--duedate"
              style={{
                color: priorityBackgroundColor,
                border: 0,
              }}
            >
              {record?.dueDate == null
                ? "----"
                : moment(record?.dueDate).format("YYYY-MM-DD")}{" "}
              {isNaN(differenceInDays) || differenceInDays <= 0
                ? ""
                : `(${differenceInDays}d)`}
            </span>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <Select
            placeholder="Priority"
            bordered={false}
            onChange={(value, option) => {
              setSelectedPriorityStatusId(value);
              // setCurrentPage(1);
              props.getData(
                pageSize,
                0,
                isComplete,
                selectedStatusId,
                hideCompleted,
                orderBy,
                sortType,
                selectedPriorityStatusId,
                selectedProgressStatusId,
                searchKeyword,
                referenceId,
                taskAssignedTo,
                value
              );
            }}
            value={selectedPriorityStatusId}
          >
            <Option value={""}>Priority</Option>
            {!isEmpty(organizerEventPriorityListing) &&
              organizerEventPriorityListing.map((status, index) => {
                return (
                  <Option value={status.id} key={index}>
                    {status.name}
                  </Option>
                );
              })}
          </Select>
        );
      },
      //dataIndex: "organizerEventPriorityDto",
      key: "organizerEventPriorityDto",
      width: 60,
      render: (record) => {
        const priorityColor =
          record?.organizerEventPriorityDto?.colorFontHexValue || "white";
        const priorityBackgroundColor =
          record?.organizerEventPriorityDto?.colorHexValue || "green";
        return (
          <Dropdown
            overlay={() => subMenuCompose}
            arrow
            placement="bottomRight"
            trigger={["contextMenu"]}
            onVisibleChange={(visible) =>
              handleVisibleChanges(visible, record?.id)
            }
          >
            <Tooltip
              title={record?.organizerEventPriorityDto?.name}
              placement="topLeft"
            >
              <div
              // onClick={() => {
              //   dispatch(toDoGetDetails(record?.id));
              //   setHideInternalMessage(true);
              //   setShowChat(true);
              //   setSmallChatHeight(false);
              //   setChatHeight(false);
              //   setchatBox(false);
              // }}
              >
                <span
                  className="badge"
                  style={{
                    color: priorityColor,
                    backgroundColor: priorityBackgroundColor,
                    border: 0,
                    textAlign: "center",
                  }}
                >
                  {record?.organizerEventPriorityDto.name.charAt(0)}
                </span>
              </div>
            </Tooltip>
          </Dropdown>
        );
      },
    },
    // {
    //   title: () => {
    //     return (
    //       <Select
    //         placeholder="progress"
    //         bordered={false}
    //         onChange={(value) => {
    //           setSelectedProgressStatusId(value);
    //           // setCurrentPage(1);
    //           props.getData(
    //             pageSize,
    //             0,
    //             isComplete,
    //             selectedStatusId,
    //             hideCompleted,
    //             orderBy,
    //             sortType,
    //             selectedPriorityStatusId,
    //             value,
    //             taskAssignedTo,
    //             referenceId
    //           );
    //         }}
    //         value={selectedProgressStatusId}
    //       >
    //         <Option value={""}>Progress</Option>
    //         {!isEmpty(allEventStatusListing) &&
    //           allEventStatusListing.map((status, index) => {
    //             return (
    //               <Option value={status.id} key={index}>
    //                 {status.name}
    //               </Option>
    //             );
    //           })}
    //       </Select>
    //     );
    //   },

    //   //dataIndex: "todoEventStatusDto",
    //   key: "todoEventStatusDto",
    //   width: 70,
    //   render: (record) => {
    //     const progressColor = "white";

    //     const progressBackgroundColor =
    //       record?.todoEventStatusDto?.colorHexValue || "green";
    //     return (
    //       <Tooltip title={record?.todoEventStatusDto?.name} placement="topLeft">
    //         <div
    //           onClick={() => {
    //             // dispatch(toDoGetDetails(record?.id));
    //             // setHideInternalMessage(true);
    //             // setShowChat(true);
    //             // setSmallChatHeight(false);
    //             // setChatHeight(false);
    //             // setchatBox(false);
    //           }}
    //         >
    //           <span
    //             className="private badge"
    //             style={{
    //               color: progressColor,
    //               backgroundColor: progressBackgroundColor,
    //               border: 0,
    //             }}
    //           >
    //             {record?.todoEventStatusDto.name.charAt(0)}
    //           </span>
    //         </div>
    //       </Tooltip>
    //     );
    //   },
    // },
  ].filter(Boolean);

  const sortIcon = () => {
    return (
      <div>
        <img src={filter} />
        <span style={{ paddingLeft: 5 }}>Sort</span>
      </div>
    );
  };

  const [dataSource, setDataSource] = useState([]);

  const handleTableChange = (
    pagination,
    filters,
    sorter,
    extra,
    searchKeyWord
  ) => {
    let Sorting =
      sorter.order === "ascend"
        ? "subject"
        : sorter.order === "descend"
        ? "subject"
        : undefined;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    if (searchKeyWord) {
      searchKeyWord += `&searchKeyWord=${searchKeyWord}`;
    }

    props.getData(
      MaxResultCount,
      0,
      isComplete,
      selectedStatusId,
      hideCompleted,
      Sorting,
      SortType,
      selectedPriorityStatusId,
      selectedProgressStatusId,
      searchKeyword,
      taskAssignedTo,
      referenceId,
      values,
      isAdvanceClear
    );
  };

  //   const getPrivateTask = (
  //     MaxResultCount = pageSize,
  //     SkipCount = 0,
  //     IsFavourite_SearchKeyword = "false",
  //     loadMore = false
  //   ) => {
  //     let appendUrl =  `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;

  //     if(props.isPrivate){
  //       appendUrl+=`&isPrivate=${true}`;
  //     }
  // //     if (privateSearch) {
  // //       appendUrl += `&privateSearch=${privateSearch}`;
  // //  }
  //     // if (isFavorite == true) {
  //     //   appendUrl += `&IsFavourite_SearchKeyword=${IsFavourite_SearchKeyword}`;
  //     // }else if(isFavorite == false){
  //     //   appendUrl += undefined;
  //     // }
  //     dispatch(
  //       getTodoEventsPagination(appendUrl)
  //     );
  //   }

  useEffect(() => {
    if (!isEmpty(allToDoEvents?.items)) {
      setDataSource(allToDoEvents?.items);
    } else {
      setDataSource([]);
    }
  }, [allToDoEvents?.items]);

  useEffect(() => {
    if (!isEmpty(allToDoEvents?.items)) {
      if (allToDoEvents.totalCount !== allToDoEvents.items.length) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    }
  }, [allToDoEvents, todoEventsLoading]);

  // const dataSource = data.items;

  // useEffect(() => {
  //   if (!isEmpty(tenderReferenceCode)) {
  //     props.getData(
  //       pageSize,
  //       0,
  //       selectedStatusId,
  //       hideCompleted,
  //       orderBy,
  //       sortType,
  //       selectedPriorityStatusId,
  //       selectedProgressStatusId,
  //       assignedTo,
  //       tenderReferenceCode,
  //     );
  //   }
  // }, [tenderReferenceCode]);
  useEffect(() => {
    if (!isEmpty(allToDoEvents?.items)) {
      let lists = [...popoverMenuVisible];
      let viewlists = [...viewList];
      allToDoEvents?.items.map((item, i) => {
        lists[i] = false;
        viewlists[i] = "More";
      });
      setPopoverMenuVisible(lists);
      setViewList(viewlists);
    }
  }, [allToDoEvents?.items]);

  useEffect(() => {
    setSelectedStatusId("");
    setSelectedPriorityStatusId("");
    setSelectedProgressStatusId("");
    setHideCompleted([]);
    setOrderBy("");
    setSortType("");
  }, [toDoMarkItOffResp]);

  const handleVisibleChange = (index) => {
    let lists = [...popoverMenuVisible];
    lists[index] = !lists[index];
    setPopoverMenuVisible(lists);
  };

  const getActionContent = (item, index) => {
    return (
      <div className="guest-actions">
        <div
          className="guest-act"
          onClick={() => {
            editToDoList(item);
            handleVisibleChange(index);
          }}
        >
          Edit
        </div>
        <div className="guest-act">Delete</div>
      </div>
    );
  };

  const onChangeCompleted = (checked) => {
    setDataSource([]);
    // setCurrentPage(1);
    // let completedStatuses = [];
    // if (checked === false) {
    //   setHideCompleted(completedStatuses);
    // } else {
    //   if (allEventStatusListing) {
    //     allEventStatusListing.filter((status) => {
    //       if (status.name !== "Completed") {
    //         completedStatuses.push(status);
    //         setHideCompleted(completedStatuses);
    //       }
    //     });
    //   }
    // }
    let hideBtnValue;
    if (checked === true) {
      setIsComplete(1);
      setHideButton(true);
      hideBtnValue = 1;
    } else {
      setIsComplete(0);
      setHideButton(false);
      hideBtnValue = 0;
    }
    // props.getData(
    //   pageSize,
    //   0,
    //   hideBtnValue,
    //   selectedStatusId,
    //   hideCompleted,
    //   orderBy,
    //   sortType,
    //   searchKeyword,
    //   selectedPriorityStatusId,
    //   selectedProgressStatusId,
    //   taskAssignedTo,
    //   referenceId
    // );
  };

  // const onChangePage = (page) => {
  //   // setCurrentPage(page);
  //   props.getData(
  //     pageSize,
  //     (page - 1) * pageSize,
  //     isComplete,
  //     selectedStatusId,
  //     hideCompleted,
  //     orderBy,
  //     sortType,
  //     selectedPriorityStatusId,
  //     selectedProgressStatusId,
  //     taskAssignedTo,
  //     referenceId
  //   );
  // };

  useEffect(() => {
    if (!isEmpty(referenceId)) {
      props.getData(
        pageSize,
        0,
        isComplete,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        referenceId
      );
    }
  }, [referenceId]);

  useEffect(() => {
    if (taskAssignedTo)
      props.getData(
        pageSize,
        0,
        isComplete,
        selectedStatusId,
        hideCompleted,
        orderBy,
        sortType,
        selectedProgressStatusId,
        selectedPriorityStatusId,
        taskAssignedTo,
        referenceId
      );
  }, [taskAssignedTo]);

  useEffect(() => {
    if (fixedTaskCategory)
      props.getData(
        pageSize,
        0,
        isComplete,
        selectedStatusId,
        hideCompleted,
        orderBy,
        sortType,
        selectedProgressStatusId,
        selectedPriorityStatusId,
        taskAssignedTo,
        fixedTaskCategory,
        referenceId
      );
  }, [fixedTaskCategory]);

  const getHalfDetails = (details) => {
    let halfDetails = details ? details.split("").splice(0, 80).join("") : "";
    return halfDetails;
  };

  //   add task
  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {
      // message.error('Failure');
    });

    if (resp && resp.status === 200) {
      setMeetingInvitees(resp.data);
    }
  };

  const { mailReferenceListingResponse } = useSelector(
    (state) => state.mailMessageCenter
  );

  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );

  useEffect(() => {
    if (!isEmpty(searchEmailTender))
      dispatch(getReferenceListing({ searchKeyWord: searchEmailTender }));
  }, [searchEmailTender]);

  useEffect(() => {
    dispatch(mailReferenceListing());
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
  }, []);

  useEffect(() => {
    if (
      !isEmpty(searchEmailTender) &&
      !isEmpty(referenceListingResponse?.items)
    ) {
      // setFilterReferenceId(referenceListingResponse?.items[0]?.id);
      referenceListingResponse?.items.map((reference) =>
        setFilterReferenceId(reference.id)
      );
    }
  }, [referenceListingResponse, searchEmailTender]);

  useEffect(() => {
    if (!isEmpty(referenceListing) && filterParticipantType == "REF") {
      setFilterReference(referenceListing.items);
    }
  }, [referenceListing]);

  // // const organizerEventLabelID = organizerEventLabelListing[0]?.id

  // const changeEmployeeDto = (value, option) => {
  //   let newTodoEventCandidateDtos = option.map((candidate) => {
  //     if (todoEventCandidateDtos.length > 0) {
  //       let newCandidate = {};
  //       todoEventCandidateDtos.map((oldCandidate) => {
  //         if (oldCandidate.candidateId === candidate.value) {
  //           newCandidate = { ...oldCandidate };
  //         }
  //       });
  //       if (!isEmpty(newCandidate)) {
  //         return { ...newCandidate };
  //       } else {
  //         return {
  //           candidateId: candidate.value,
  //           candidateName: candidate.title,
  //           isPrimary: false,
  //           isWatcher: false,
  //         };
  //       }
  //     } else {
  //       return {
  //         candidateId: candidate.value,
  //         candidateName: candidate.title,
  //         isPrimary: true,
  //         isWatcher: false,
  //       };
  //     }
  //   });
  //   setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  // };

  const allEqual = (arr) => arr.every((val) => val === true);

  // const changeCandidatePrimary = (value, index) => {
  //   let newTodoEventCandidateDtos = cloneDeep(todoEventCandidateDtos);

  //   if (value === "supervisor") {
  //     newTodoEventCandidateDtos[index].isSupervisor = true;
  //     newTodoEventCandidateDtos[index].isPrimary = false;
  //   } else if (value === "primary") {
  //     newTodoEventCandidateDtos[index].isPrimary = true;
  //     newTodoEventCandidateDtos[index].isSupervisor = false;
  //   } else {
  //     newTodoEventCandidateDtos[index].isPrimary = false;
  //     newTodoEventCandidateDtos[index].isSupervisor = false;
  //   }
  //   let primaryValueArray = newTodoEventCandidateDtos.map(
  //     (candidate) => candidate.isPrimary
  //   );
  //   let supervisorValueArray = newTodoEventCandidateDtos.map(
  //     (candidate) => candidate.isSupervisor
  //   );

  //   if (primaryValueArray.includes(true) && !allEqual(supervisorValueArray)) {
  //     setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  //   }
  // };

  // const removeSelectedMember = (candidateId) => {
  //   let newTodoEventCandidateDtos = todoEventCandidateDtos.filter(
  //     (candidate) => candidate.candidateId !== candidateId
  //   );

  //   setTodoEventCandidateDtos(newTodoEventCandidateDtos);

  //   let newSelectTodoEventCandidateDtos = form
  //     .getFieldValue("todoEventCandidateDtos")
  //     .filter((obj) => obj !== candidateId);

  //   form.setFieldsValue({
  //     todoEventCandidateDtos: newSelectTodoEventCandidateDtos,
  //   });
  // };
  let participantFrom = storage.getItem("userId");
  const [showLoader, setShowLoader] = useState(true);
  const changeEmployeeDto = (value, option) => {
    let newTodoEventCandidateDtos = option.map((candidate) => {
      return {
        candidateId: candidate.value,
        candidateName: candidate.title,
        isPrimary: true,
        isWatcher: false,
      };
    });
    setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  };

  // const worker = {
  //   dueDate: moment(),
  //   startDate: moment(),
  // };

  // useEffect(() => {
  //   if (!isEmpty(tenderReferenceCode)) {
  //     form.setFieldsValue({
  //       referenceId: tenderReferenceCode,
  //     });
  //   }
  // }, [tenderReferenceCode]);

  const onFinish = (values) => {
    let newValues = {
      startDate: moment(values.startDate),
      privateUserId: participantFrom,
      organizerEventLabelId: "5ed65d11-e8e4-4ca9-a35f-14dbaa6509a6",
      ...values,
    };

    newValues.todoEventCandidateDtos = todoEventCandidateDtos;
    if (!isEmpty(fitlerReferenceId)) {
      newValues.referenceId = fitlerReferenceId;
    }
    dispatch(addToDoEvent(newValues));
    // gettaskTodoList();
    setShowAddTask(!showAddTask);
    setShowLoader(false);
  };

  // const [form] = useForm();
  const handleAddTask = () => {
    setTodoDetails([]);
    setShowAddTask(true);
  };

  useEffect(() => {
    if (filterParticipantType === "REF") {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ SearchKeyword: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  // useEffect(() => {
  //   var tableContent = document.querySelector(".toto-table .ant-table-body");
  //   tableContent.addEventListener("scroll", (event) => {
  //     let maxScroll = event.target.scrollHeight - event.target.clientHeight;
  //     let currentScroll = Math.round(event.target.scrollTop);
  //     if (currentScroll == maxScroll) {
  //       let MaxResultCount = pageSize;
  //       let SkipCount = currentPage * pageSize;
  //       if (allToDoEvents?.totalCount > SkipCount) {
  //         props.getData(
  //           MaxResultCount,
  //           SkipCount,
  //           isComplete,
  //           selectedStatusId,
  //           hideCompleted,
  //           orderBy,
  //           sortType,
  //           selectedPriorityStatusId,
  //           selectedProgressStatusId,
  //           taskAssignedTo,
  //           referenceId,
  //           true
  //         );
  //       }
  //     }
  //   });
  // }, [currentPage, allToDoEvents]);
  const onChangeView = () => {
    setBoardView(!boardView);
  };

  const kanbanData = addTodoEventsKanban?.items || {};
  const newKanbandata = Object.keys(kanbanData)?.map(function (nameIndex) {
    let getKanbanData = kanbanData[nameIndex];
    return getKanbanData;
  });
  const finalKanbanData = map(newKanbandata, (badge) => ({
    ...badge,
    badge: badge?.cards?.length?.toString(),
  }));

  const displayCard = (statusName, badge) => {
    return (
      <div className="kanban_header">
        <Card style={{ height: 60, width: 300 }}>
          <div className="header_top_maincard">
            <div className="innerdiv_header">
              <h6 className="text_div_header">
                {" "}
                {statusName}
                <small className={"badge_icon_header-" + statusName}>
                  {badge}
                </small>
              </h6>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div className="organizer__list todo__list">
        {/* <h4 className="organizer__list--header">To-Do List</h4> */}
        <Row gutter={[8, 8]}>
          <Col className="switch_view">
            {!props.isPrivate && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 0.2fr 1fr",
                }}
              >
                <Input.Search
                  className="form__group_email_search"
                  style={{ margin: 0 }}
                  placeholder="Task Search"
                  size="medium"
                  allowClear
                  onSearch={(e) => {
                    setSearchKeyword(e);
                  }}
                  autoComplete="off"
                />

                <Select
                  style={{ width: "200px", paddingLeft: "10px" }}
                  showSearch={false}
                  placeholder="Select reference"
                  filterOption={false}
                  optionFilterProp="label"
                  allowClear={true}
                  onSearch={(value) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      refkeyword: value,
                    }));
                  }}
                  onChange={(value, option) => {
                    if (value !== undefined) {
                      setValues((prevValues) => ({
                        ...prevValues,
                        refkeyword: value,
                      }));
                      setIsAdvancedSearch(true);
                    } else {
                      setValues((prevValues) => ({
                        ...prevValues,
                        refkeyword: "",
                      }));
                      setIsAdvancedSearch(false);
                    }
                  }}
                  autoComplete="off"
                  value={values.refkeyword}
                >
                  {!isEmpty(tasksUsedReference) &&
                    tasksUsedReference?.map((reference, index) => {
                      return (
                        <Option
                          value={reference.id}
                          key={index}
                          label={[reference.code, reference.title]}
                        >
                          <div className="demo-option-label-item">
                            <div>
                              {reference.code} {""}
                            </div>
                            <div>{reference.title}</div>
                          </div>
                        </Option>
                      );
                    })}
                </Select>
              </div>
            )}
            {props.isPrivate && "List View"}
          </Col>

          <Col className="switch-div">
            <Switch onChange={onChangeCompleted} checked={hideButton} />
            Show Completed
          </Col>
        </Row>
      </div>

      <div className="name here">
        {!boardView ? (
          <Card>
            <Table
              columns={columns}
              dataSource={dataSource?.map((x, index) => {
                return {
                  ...x,
                  sn: `${index + 1}.`,
                };
              })}
              // rowKey="appUserId"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setTaskId(record?.id);
                    // dispatch(toDoGetDetails(record?.id));
                  },
                };
              }}
              rowKey={(record) => record.id}
              rowClassName={setTaskClassName}
              // className={`organizer__table contact-table task-table ${
              //   showChat && "openChat"
              // } toto-table`}

              style={{
                maxHeight: window.innerHeight - 200,
                overflow: "scroll",
              }}
              size="middle"
              className="sticky-header"
              onChange={handleTableChange}
              loading={{
                indicator: <img src={LogoImage} height="auto" width="50px" />,
                spinning: todoEventsLoading,
              }}
              // pagination={{
              //   defaultPageSize: 50,
              //   showSizeChanger: false,
              //   pageSizeOptions: ["10", "20", "30"],
              // }}
              pagination={false}
              // scroll={{ y: 680 }}
              // onRow={todoRowEventHandler}
            />
          </Card>
        ) : addTodoEventsLoading ? (
          <Spin className="spin_loader" size="large" />
        ) : (
          <div>
            {/* <div className="kanban_task_header">
            {finalKanbanData?.map((data)=>(
              displayCard(data?.statusName , data?.badge)
            ))}
          </div>{console.log(finalKanbanData)} */}
            <ControlledBoard
              board={{ columns: finalKanbanData }}
              setHideInternalMessage={setHideInternalMessage}
            />
          </div>
        )}

        {showAddTask && (
          <Modal
            visible={showAddTask}
            title={
              visitor && largeWidth && changeCategory
                ? "Add Visit"
                : !visitor && largeWidth && changeCategory
                ? "Add Call"
                : "Add Task"
            }
            // title="Add Task"
            centered
            closable={false}
            footer={null}
            wrapClassName="todo-modal"
            width={largeWidth ? 1000 : 700}
            // width={700}
          >
            <ToDoForm
              type="private"
              organizerEventPriorityListing={organizerEventPriorityListing}
              meetingInvitees={meetingInvitees}
              allEventStatusListing={allEventStatusListing}
              todoEventDrawer={true}
              todoDetails={todoDetails}
              onCloseEventFormDrawer={onCloseEventFormDrawer}
              setShowAddTask={setShowAddTask}
              taskAssignedTo={taskAssignedTo}
              largeWidth={largeWidth}
              setLargeWidth={setLargeWidth}
              visitor={visitor}
              setVisitor={setVisitor}
              taskCat={taskCat}
              departmentListingResponse={departmentListingResponse}
              remainderListingResponse={remainderListingResponse}
              fixedTaskCategory={fixedTaskCategory}
            />
          </Modal>
        )}
        {showAddTask ? (
          ""
        ) : (
          <div className="add_task_button">
            {!isPrivate && (
              <button
                onClick={() => {
                  handleAddTask();
                  setHideInternalMessage(false);
                  setShowChat(false);
                }}
                className="button_add_task"
              >
                {largeWidth && visitor && changeCategory ? (
                  <>Add Visit</>
                ) : largeWidth && !visitor && changeCategory ? (
                  <>Add Calls</>
                ) : (
                  <>Add Task</>
                )}
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ToDoLists;
