import { Card, Drawer, Form, Table, Tabs, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import CeadView from "./ceadView";
import AccountView from "./accountView";
import moment from "moment";
import {
  actionForGetSingleCeadTenderDetail,
  actionForGetTenderCEADGetAccountViewPaginationListing,
} from "services/redux/cead/action";
import { useDispatch } from "react-redux";
import { showNumber } from "utils/showNumber";
import { twoDecimalFormatter } from "utils/twoDecimalFormatter";
import CeadSearch from "components/ceadSearch";
import ClosureView from "./closureView";
import { renderRightText } from "utils/rightAlignCellData";
import WeeklyReportExecutionStage from "./weeklyReport";
// import WeeklyReportExecutionStage from "./weeklyReport";

const { TabPane } = Tabs;

export default function AnotherStage({
  currencyOptions,
  singleTenderCeadData,
  tenderCeadAccountViewList,
  tenderCeadAccountViewListLoading,
  ...props
}) {
  const dispatch = useDispatch();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [anotherStageLCDetailForm] = Form.useForm();
  const [anotherStagePerformanceBoundForm] = Form.useForm();
  const [advancedDetailsForm] = Form.useForm();
  const [formVariation] = Form.useForm();
  const [paymentRecivedByPrincipalForm] = Form.useForm();
  const [formFeeReceived] = Form.useForm();
  const [formClosure] = Form.useForm();
  const [IsRenewalRecord, setIsRenewalRecord] = useState(false);

  const {
    checkedValues,
    setCheckValue,
    setSearchValue,
    searchValues,
    initialSearchValues,
    tenderCeadWeeklyContractReportDetailsListing,
    tenderCeadWeeklyContractReportGetListingLoading,
  } = props;

  useEffect(() => {
    anotherStageLCDetailForm.setFieldsValue(
      {
        issueingBank:
          singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.issueingBank,
        foreignBank:
          singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.foreignBank,
        currencyId:
          singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.currencyId,
        amount: singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.amount,
        validUpto: moment(
          singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.validUpto
        ),
        description:
          singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.description,
        lastShipmentDate: moment(
          singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.lastShipmentDate
        ),
        isRenewalRecord:
          singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.isRenewalRecord,
      }

      // {
      //   bidBond: singleTenderCeadData?.biD_BOUND_BidInformation?.map((x) => {
      //     return {
      //       issueingBank: x?.issueingBank,
      //       foreignBank: x?.foreignBank,
      //       currencyId: x?.currencyId,
      //       description: x?.description,
      //       amount: x?.amount,
      //       validUpto: moment(x?.validUpto),
      //       lastShipmentDate: moment(x?.lastShipmentDate),
      //       isRenewalRecord: x?.isRenewalRecord ? true : false,
      //     };
      //   }),
      // }
    );

    anotherStagePerformanceBoundForm.setFieldsValue({
      issueingBank:
        singleTenderCeadData?.performancE_BOUND_BidInformation?.[0]
          ?.issueingBank,
      foreignBank:
        singleTenderCeadData?.performancE_BOUND_BidInformation?.[0]
          ?.foreignBank,
      currencyId:
        singleTenderCeadData?.performancE_BOUND_BidInformation?.[0]?.currencyId,
      amount:
        singleTenderCeadData?.performancE_BOUND_BidInformation?.[0]?.amount,

      validUpto: moment(
        singleTenderCeadData?.performancE_BOUND_BidInformation?.[0]?.validUpto
      ),
      // releasedDate: moment(
      //   singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.releasedDate
      // ),
      description:
        singleTenderCeadData?.performancE_BOUND_BidInformation?.[0]
          ?.description,
      lastShipmentDate: moment(
        singleTenderCeadData?.performancE_BOUND_BidInformation?.[0]
          ?.lastShipmentDate
      ),
      isRenewalRecord:
        singleTenderCeadData?.performancE_BOUND_BidInformation?.[0]
          ?.isRenewalRecord,
    });

    formVariation.setFieldsValue({
      ...singleTenderCeadData?.tenderCEADVariationDto,
      variationDate: moment(
        singleTenderCeadData?.tenderCEADVariationDto?.variationDate
      ),
      variation:
        singleTenderCeadData?.tenderCEADVariationDto?.tenderCEADVariationInformationDtos?.map(
          (x) => {
            let variationAmt = x?.variationAmount;
            let feeableCv =
              x?.variationAmount - (x?.variationAmount * x?.exclusions) / 100;
            let exchangeRate = x?.exchangeRate;
            return {
              ...x,
              contractAmount: variationAmt,
              cvExclVat: variationAmt,
              feeableCv: feeableCv,
              feeAmt: (x?.feePercentage / 100) * feeableCv,
              equivalentNC: exchangeRate * feeableCv,
            };
          }
        ),
    });

    paymentRecivedByPrincipalForm.setFieldsValue({
      ...singleTenderCeadData?.tenderCEADPaymentReceivedPrincipalDto,
      paymentRecivedDate: moment(
        singleTenderCeadData?.tenderCEADPaymentReceivedPrincipalDto
          ?.paymentRecivedDate
      ),
      paymentReceivedByPrincipal:
        singleTenderCeadData?.tenderCEADPaymentReceivedPrincipalDto?.tenderPaymentReceivedPrincipalInformationDtos?.map(
          (x) => {
            let variationAmt = x?.variationAmount;
            let feeableCv = variationAmt - (variationAmt * x?.exclusions) / 100;
            let exchangeRate = x?.exchangeRate;
            return {
              ...x,
              contractAmount: variationAmt,
              cvExclVat: variationAmt,
              feeableCv: feeableCv,
              feeAmt: (x?.feePercentage / 100) * feeableCv,
              equivalentNC: exchangeRate * feeableCv,
            };
          }
        ),
    });

    formFeeReceived.setFieldsValue({
      ...singleTenderCeadData?.tenderCEADPaymentReceivedDto,
      paymentRecivedDate: moment(
        singleTenderCeadData?.tenderCEADPaymentReceivedDto?.paymentRecivedDate
      ),
      ofInvoiceNumber:
        singleTenderCeadData?.tenderCEADPaymentReceivedDto?.ofInvoiceNumber,
      tenderPaymentReceivedInformationDtos:
        singleTenderCeadData?.tenderCEADPaymentReceivedDto?.tenderPaymentReceivedInformationDtos?.map(
          (x) => {
            let variationAmt = x?.receivedAmount;
            let feeableCv = variationAmt - (variationAmt * x?.exclusions) / 100;
            let exchangeRate = x?.exchangeRate;
            return {
              ...x,
              equivalentNC: exchangeRate * variationAmt,
            };
          }
        ),
    });

    // formClosure.setFieldsValue({
    //   ...singleTenderCeadData?.tenderCEADContractClosureDto,
    //   closedDate: moment(
    //     singleTenderCeadData?.tenderCEADContractClosureDto?.closedDate
    //   ),
    // });
  }, [singleTenderCeadData]);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
    },
    {
      title: "Reference",
      dataIndex: "code",
    },
    {
      title: "Contract",
      dataIndex: "title",
    },
    {
      title: "Currency",
      dataIndex: "abbvr",
    },
    {
      title: renderRightText("Total Contract"),
      dataIndex: "totalContractValue",
      render: (text, record) => {
        return renderRightText(showNumber(text, record?.sn));
      },
    },
    {
      title: renderRightText("Fee'able CV"),
      dataIndex: "feeableContractValue",
      render: (text, record) => {
        return renderRightText(
          twoDecimalFormatter({
            number: text,
            isInteger: Number.isSafeInteger(text),
          })
        );
      },
    },
    {
      className: "text-align--right",
      title: renderRightText("(%) Fee"),
      dataIndex: "feePercentage",
    },
    {
      title: renderRightText("Total Receivable"),
      dataIndex: "totalReceivable",
      render: (text, record) => {
        return renderRightText(showNumber(text, record?.sn));
      },
    },
    {
      title: renderRightText("Fee Received"),
      dataIndex: "feeReceived",
      render: (text, record) => {
        return renderRightText(showNumber(text, record?.sn));
      },
    },
    {
      title: renderRightText("Fee Remaining"),
      dataIndex: "feeRemaining",
      render: (text, record) => {
        return renderRightText(showNumber(text, record?.sn));
      },
    },
    {
      title: renderRightText("Fee Due"),
      dataIndex: "feeDue",
      render: (text, record) => {
        return renderRightText(showNumber(text, record?.sn));
      },
    },
    {
      title: renderRightText("Fee Due Npr"),
      dataIndex: "feeDueNpr",
      render: (text, record) => {
        return renderRightText(showNumber(text, record?.sn));
      },
    },
    {
      title: renderRightText("Fee Remaining Npr"),
      dataIndex: "feeRemainingNpr",
      render: (text, record) => {
        return renderRightText(showNumber(text, record?.sn));
      },
    },
    {
      title: renderRightText("Last invoice raised on"),
      dataIndex: "lastInvoiceRaisedDate",
      render: (text, record) => {
        return renderRightText(text);
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => {
        return renderRightText(text);
      },
      // render: (record) => {
      //   return (
      //     <>
      //       <EyeOutlined
      //         onClick={() => {
      //           // setSelectedTender(record);
      //           setIsOpenDrawer(true);
      //           dispatch(actionForGetSingleCeadTenderDetail(record?.tenderId));
      //           // dispatch(
      //           //   actionForGetTenderCEADGetAccountViewPaginationListing(
      //           //     record?.tenderId
      //           //   )
      //           // );
      //         }}
      //       />
      //     </>
      //   );
      // },
    },
  ];

  const itemsTab = [
    {
      key: "1",
      label: "CEAD View",
      children: (
        <CeadView
          anotherStageLCDetailForm={anotherStageLCDetailForm}
          anotherStagePerformanceBoundForm={anotherStagePerformanceBoundForm}
          advancedDetailsForm={advancedDetailsForm}
          singleTenderCeadData={singleTenderCeadData}
          setIsRenewalRecord={setIsRenewalRecord}
          isRenewalRecord={IsRenewalRecord}
          currencyOptions={currencyOptions}
        />
      ),
    },
    {
      key: "2",
      label: "A/C View",
      children: (
        <AccountView
          currencyOptions={currencyOptions}
          formVariation={formVariation}
          formFeeReceived={formFeeReceived}
          paymentRecivedByPrincipalForm={paymentRecivedByPrincipalForm}
          setIsRenewalRecord={setIsRenewalRecord}
          singleTenderCeadData={singleTenderCeadData}
        />
      ),
    },
    {
      key: "3",
      label: "Closure",
      children: (
        <ClosureView
          singleTenderCeadData={singleTenderCeadData}
          formClosure={formClosure}
        />
      ),
    },
    {
      key: "4",
      label: "Weekly Report",
      children: (
        <WeeklyReportExecutionStage
          singleTenderCeadData={singleTenderCeadData}
          tenderCeadWeeklyContractReportDetailsListing={
            tenderCeadWeeklyContractReportDetailsListing
          }
          tenderCeadWeeklyContractReportGetListingLoading={
            tenderCeadWeeklyContractReportGetListingLoading
          }
        />
      ),
    },
  ];

  let xt = null;
  let dataSource = [];
  const dataEmpty = {
    sn: ".",
    code: "",
    title: "",
    abbvr: "",
    totalContractValue: "",
    feePercentage: "",
    feeValue: "",
    feeReceived: "",
    feeRemaining: "",
    feeDue: "",
    feeDueNpr: "",
    feeRemainingNpr: "",
    lastInvoiceRaisedDate: "",
    totalReceivable: "",
  };

  let serialNo = 1;
  const dataSources = tenderCeadAccountViewList?.map((x, index) => {
    if (x?.tenderCEAD_Id === xt) {
      xt = x?.tenderCEAD_Id;
      dataSource = [...dataSource, { ...x, sn: "", action: "" }];
    } else {
      if (index === 0) {
        dataSource = [
          ...dataSource,
          {
            ...x,
            sn: 1,
            action: (
              <EyeOutlined
                onClick={() => {
                  setIsOpenDrawer(true);
                  dispatch(
                    actionForGetSingleCeadTenderDetail(x?.tenderCEAD_Id)
                  );
                }}
              />
            ),
          },
        ];
        xt = x?.tenderCEAD_Id;
      } else {
        dataSource = [
          ...dataSource,
          dataEmpty,
          {
            ...x,
            sn: ++serialNo,
            action: (
              <EyeOutlined
                onClick={() => {
                  setIsOpenDrawer(true);
                  dispatch(
                    actionForGetSingleCeadTenderDetail(x?.tenderCEAD_Id)
                  );
                }}
              />
            ),
          },
        ];
        xt = x?.tenderCEAD_Id;
      }
    }
  });

  const totalRow = {
    ...dataEmpty,
    sn: "",
    feePercentage: 0,
    title: "Total",
    totalContractValue: tenderCeadAccountViewList?.reduce(
      (sum, item) => sum + item.totalContractValue,
      0
    ),

    feeValue: tenderCeadAccountViewList?.reduce(
      (sum, item) => sum + item.feeValue,
      0
    ),

    feeReceived: tenderCeadAccountViewList?.reduce(
      (sum, item) => sum + item.feeReceived,
      0
    ),

    feeRemaining: tenderCeadAccountViewList?.reduce(
      (sum, item) => sum + item.feeRemaining,
      0
    ),

    feeDueNpr: tenderCeadAccountViewList?.reduce(
      (sum, item) => sum + item.feeDueNpr,
      0
    ),

    feeRemainingNpr: tenderCeadAccountViewList?.reduce(
      (sum, item) => sum + item.feeRemainingNpr,
      0
    ),

    totalReceivable: tenderCeadAccountViewList?.reduce(
      (sum, item) => sum + item?.totalReceivable,
      0
    ),
  };

  dataSource = [...dataSource, totalRow];

  return (
    <>
      <Card
        style={{
          margin: 25,
        }}
      >
        {/* <CeadSearch
          checkedValues={checkedValues}
          setCheckValue={setCheckValue}
          setSearchValue={setSearchValue}
          searchValues={searchValues}
          initialSearchValues={initialSearchValues}
        /> */}

        <Table
          columns={columns}
          dataSource={dataSource?.map((x, index) => {
            // let feeValue =
            //   ((x?.totalContractValue - x?.exclusions) * x?.feePercentage) /
            //   100;
            // let feeRemainingNpr =
            //   x?.localCurrencyDto?.currencyValue * x?.feeRemaining;
            return {
              ...x,
              ...x.referenceDto,
              ...x.currencyDto,
              lastInvoiceRaisedDate:
                x.lastInvoiceRaisedDate &&
                moment(x.lastInvoiceRaisedDate).format("YYYY-MM-DD"),
              sn: x?.sn,
              tenderId: x?.tenderCEAD_Id,
              feeReceived: x?.feeReceived,
              // ...(x?.title !== "Total" && {
              //   feeRemainingNpr: feeRemainingNpr,
              //   feeValue: feeValue,
              // }),

              action: x?.action,
            };
          })}
          rowKey="referenceCode"
          className="custom-table"
          rowClassName={(record) => (record?.sn === "." ? "an-sn-hide" : "")}
          loading={tenderCeadAccountViewListLoading}
          pagination={false}
        />
      </Card>

      <Drawer
        title="Execution Stage"
        onClose={() => {
          setIsOpenDrawer(false);
        }}
        visible={isOpenDrawer}
        width={760}
        keyboard={false}
        maskClosable={false}
      >
        <Tabs
          defaultActiveKey="1"
          tabBarGutter={25}
          style={{ marginLeft: "10px" }}
        >
          {itemsTab?.map((x) => {
            return (
              <>
                <TabPane tab={x?.label} key={x?.key}>
                  {x?.children}
                </TabPane>
              </>
            );
          })}
        </Tabs>
      </Drawer>
    </>
  );
}
