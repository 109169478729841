import { Card } from "antd";
import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const RenderCardTitle = props => {
  // console.log(props,"props from header screen")
  return (
    <React.Fragment>
      <Card style={{height:60}}>
        <div className="header_top_maincard">
          <div className="innerdiv_header">
            <h6 className="text_div_header"> {props.name} 
              <small className={"badge_icon_header-" + props.name}>{props.badge}</small></h6>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default RenderCardTitle;
