import { ADMIN_SETTINGS_TYPES } from "../../../types";

const INITIAL_STATE = {
  allRoleLists: [],
  roleListsLoading: false,

  addNewRoleResp: [],
  addNewRoleRespLoading: false,

  updateRoleResp: [],
  updateRoleLoading: false,

  newUpdateRoleResp: [],
  newUpdateRoleLoading: false,

  permissionListsForRoleResp: [],
  permissionListsForRoleLoading: true,

  grantOrProhitbitPermissionForRoleResp: [],
  grantOrProhitbitPermissionForRoleLoading: false,
};

export default function roleListsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Appointment events
    case ADMIN_SETTINGS_TYPES.ADMIN_SETTINGS_INIT:
      return {
        ...state,
        allRoleLists: [],
        roleListsLoading: true,
      };
    case ADMIN_SETTINGS_TYPES.ADMIN_SETTINGS_SUCCESS:
      return {
        ...state,
        allRoleLists: action.payload,
        roleListsLoading: false,
      };
    case ADMIN_SETTINGS_TYPES.ADMIN_SETTINGS_FINISH:
      return {
        ...state,
        roleListsLoading: false,
      };

    // New role add
    case ADMIN_SETTINGS_TYPES.ADD_NEW_ROLE_INIT:
      return {
        ...state,
        addNewRoleResp: [],
        addNewRoleRespLoading: true,
      };
    case ADMIN_SETTINGS_TYPES.ADD_NEW_ROLE_SUCCESS:
      return {
        ...state,
        addNewRoleResp: action.payload,
        addNewRoleRespLoading: false,
      };
    case ADMIN_SETTINGS_TYPES.ADD_NEW_ROLE_FINISH:
      return {
        ...state,
        addNewRoleRespLoading: false,
      };

    // update role
    case ADMIN_SETTINGS_TYPES.UPDATE_ROLE_INIT:
      return {
        ...state,
        updateRoleResp: [],
        updateRoleLoading: true,
      };
    case ADMIN_SETTINGS_TYPES.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateRoleResp: action.payload,
        updateRoleLoading: false,
      };
    case ADMIN_SETTINGS_TYPES.UPDATE_ROLE_FINISH:
      return {
        ...state,
        updateRoleLoading: false,
      };

    // new update role
    case ADMIN_SETTINGS_TYPES.NEW_UPDATE_ROLE_INIT:
      return {
        ...state,
        newUpdateRoleLoading: true,
      };

    case ADMIN_SETTINGS_TYPES.NEW_UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        newUpdateRoleResp: action.payload,
        newUpdateRoleLoading: false,
      };

    case ADMIN_SETTINGS_TYPES.NEW_UPDATE_ROLE_FINISH:
      return {
        ...state,
        newUpdateRoleLoading: false,
      };

    // permission lists role
    case ADMIN_SETTINGS_TYPES.PERMISSION_LISTS_FOR_ROLE_INIT:
      return {
        ...state,
        permissionListsForRoleResp: [],
        permissionListsForRoleLoading: true,
      };
    case ADMIN_SETTINGS_TYPES.PERMISSION_LISTS_FOR_ROLE_SUCCESS:
      return {
        ...state,
        permissionListsForRoleResp: action.payload,
        permissionListsForRoleLoading: false,
      };
    case ADMIN_SETTINGS_TYPES.PERMISSION_LISTS_FOR_ROLE_FINISH:
      return {
        ...state,
        permissionListsForRoleLoading: false,
      };

    // grant or prohibit permission lists role
    case ADMIN_SETTINGS_TYPES.GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE_INIT:
      return {
        ...state,
        grantOrProhitbitPermissionForRoleResp: [],
        grantOrProhitbitPermissionForRoleLoading: true,
      };
    case ADMIN_SETTINGS_TYPES.GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE_SUCCESS:
      return {
        ...state,
        grantOrProhitbitPermissionForRoleResp: action.payload,
        grantOrProhitbitPermissionForRoleLoading: false,
      };
    case ADMIN_SETTINGS_TYPES.GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE_FINISH:
      return {
        ...state,
        grantOrProhitbitPermissionForRoleLoading: false,
      };
    default:
      return { ...state };
  }
}
