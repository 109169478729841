import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";

export class AppointmentsService {
  getAppointmentEventsPagination(query) {
    let url = `${appBaseUrl}/organizerEvent/appointmentEventsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  addAppointmentEvent(body) {
    let url = `${appBaseUrl}/organizerEvent/appointmentEventAdd`;
    let data = postService(url, body);
    return data;
  }

  updateAppointmentEvent(body, id) {
    let url = `${appBaseUrl}/organizerEvent/appointmentEventUpdate/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  appointmentMarkAsComplete(id) {
    let url = `${appBaseUrl}/organizerEvent/appointmentEventMarkItOff/${id}`;
    let data = postService(url, "", "patch");
    return data;
  }
}
