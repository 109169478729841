import React from "react";
import { Button, Alert, Typography } from "antd";
import { getFilenameFromStackTrace } from "utils/getFilenameFromStackTrace";

const { Title, Paragraph } = Typography;

export const handleErrorStack = (error, info) => {
  // logErrorToServer(error, info);
  // console.log("message: ", error.message);
  // console.log("name: ", error.name);
  console.log("stack: ", error.stack);
};

const FallbackUI = ({ error, resetErrorBoundary }) => {
  return (
    <div style={{ padding: "30px", textAlign: "center" }}>
      <Alert
        message="Something went wrong!"
        description={error.message}
        type="error"
        showIcon
        style={{ marginBottom: "20px" }}
      />
      <div>
        <Title level={3}>Oops! An unexpected error occurred.</Title>
        <Paragraph>
          We apologize for the inconvenience. Please try again by clicking the
          button below.
        </Paragraph>
        <Button
          type="primary"
          size="large"
          onClick={resetErrorBoundary}
          style={{ marginTop: "10px" }}
        >
          Try Again
        </Button>
      </div>
    </div>
  );
};

export default FallbackUI;
