import { Typography } from "antd";
import { useDispatch } from "react-redux";
import { getAllChildFolderList } from "services/redux/Reference/Folder/action";
import logo from "../../assets/svg/bi_slash.svg";

const FolderCreate = ({
  newFolderData,
  setShowAddUpdateFolderDrawer,
  setShowAddUpdateFileDrawer,
  setFolderRecord,
}) => {
  const dispatch = useDispatch();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "98%",
        paddingTop: "10px",
      }}
    >
      {newFolderData?.[0]?.level === 0 ? (
        <Typography.Paragraph style={{ paddingLeft: "10px" }}>
          URL:{" "}
          <b>
            {" "}
            <img
              src={logo}
              alt="logo"
              style={{
                height: "25px",
                marginRight: "-7px",
                marginLeft: "-7px",
              }}
            />
            {newFolderData?.[0]?.title}
          </b>
        </Typography.Paragraph>
      ) : (
        <Typography.Paragraph style={{ paddingLeft: "10px" }}>
          URL:{" "}
          <b>
            <img
              src={logo}
              alt="logo"
              style={{
                height: "25px",
                marginRight: "-7px",
                marginLeft: "-7px",
              }}
            />
            {newFolderData?.[0]?.parents?.map((x, u) => (
              <button
                key={x.id}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  dispatch(getAllChildFolderList(x?.id));
                }}
              >
                <Typography.Text style={{ color: "#1890ff" }}>
                  {x.name}
                </Typography.Text>

                <img
                  src={logo}
                  alt="logo"
                  style={{
                    height: "25px",
                    marginRight: "-7px",
                    marginLeft: "-7px",
                  }}
                />
              </button>
            ))}
            {newFolderData?.[0]?.title}
          </b>
        </Typography.Paragraph>
      )}
    </div>
  );
};

export default FolderCreate;
