import React, { useState, useEffect } from "react";
import { Collapse, Button, Checkbox, Upload, Tooltip, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  MoreOutlined,
  PlusCircleOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { cqBaseUrl } from "utils/config";
import { isEmpty, cloneDeep, values, groupBy } from "lodash";
import SingleStage from "./SingleStage";
import Stages from "./index";
import AddExtensions from "../../AddExtension";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import AddUpdateParticipation from "../../AddUpdateParticipation";
import AwardStatus, { RenderAwardStatus } from "../AwardStatus";
import BasicDetails from "./BasicDetails";
import DoubleStage from "./DoubleStage";
import DocumentPurchase from "../../DocumentPurchase";
import Participation from "../../Participation";

import {
  updateStageDocumentPurchase,
  addStageDocumentPurchase,
  updateStagelevel,
  stageLevelAdd,
  getDocumentPurchasePagintaion,
  tenderStageGetDetails,
} from "services/redux/Reference/Tender/action";
import ROTO from "../../Roto";
import { numberWithCommas, getDaysDifference } from "utils/commonFunc";
import { flexibleCompare } from "@fullcalendar/react";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
import pdfViewer from "components/Documents/ViewDocument";
import AllFileViewer from "components/Documents/allFileViewer";
import Blog from "components/Blog";

const newWindow = "../../../assets/svg/newWindow.svg";

const RenderStageView = ({
  setEditStages,
  setGetStagesLevel,
  showStages,
  allCurrencyListingResponse,
  allCountryListingResponse,
  sisterCompanyListing,
  getDetails,
  changetest,
  doubleStageForm,
  singleStageForm,
  documentPurchaseForm,
  showParticipation,
  editStages,
  defaultNRP,
  defaultUSD,
  addUpdateOfficialInviation,
  setShowStages,
  setEditForm,
  setShowParticipation,
  getStagesLevel,
  setShowSingleStageEdit,
  dynamicFileTypesState,
  setOfficialForm,
  showDoubleStages,
  setShowDoubleStage,
  dynamicTenderNoticeFileTypesState,
  dynamicTenderDocumentFileTypesState,
  dynamicROTOFileTypesState,
  dynamicExtensionFileTypesState,
  extensionData,
  setExtensionData,
  tenderId,
}) => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const CheckMark = "assets/svg/checkmark-24.svg";
  const cancel = "assets/svg/cross-24.svg";
  const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);
  const [fetchData, setFetchData] = useState();
  const [stageFetchData, setDoubleFetchData] = useState();
  const [editDocumentPurchase, setEditDocumentPurchase] = useState([]);
  const [stageIndex, setStageIndex] = useState([]);
  const [test, setTest] = useState();
  const [documentDetails, setDocumnentDetails] = useState();
  const [selectedStages, setSelectedStages] = useState("");
  const [index, setIndex] = useState();
  const [setForm, setOpenForm] = useState(false);
  const [showDocAddButton, setShowDocAddButton] = useState(true);
  const [docPurchasePagination, setDocPurchasePagination] = useState([]);
  const [showStageForm, setShowStageForm] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  //single stage docs
  const [singleTenderNoticeFiles, setSingleTenderNoticeFiles] = useState([]);
  const [singleofficialDoc, setSingleOfficalDoc] = useState([]);
  const [singleROTO, setSingleROTO] = useState([]);
  const [singleExtensionFile, setSingleExtensionFile] = useState([]);
  const [tenderDocumentFile, setTenderDocumentFile] = useState([]);

  //double stage docs
  const [doubleTenderNoticeFiles, setDoubleTenderNoticeFiles] = useState([]);
  const [doubleofficialDoc, setDoubleOfficalDoc] = useState([]);
  const [doubleROTO, setdoubleROTO] = useState([]);
  const [doubleExtensionFile, setDoubleExtensionFile] = useState([]);
  const [showExtensionDrawer, setExtensionDrawer] = useState(false);
  const [showAddExtension, setShowAddExtension] = useState();
  const [openingDate, setOpeningDate] = useState("");
  const [submissionDate, setSubmissionDate] = useState("");
  const [tenderStageROTOFileList, setTenderStageROTOFileList] = useState([]);

  const [officialDocument, setOfficialDocument] = useState([]);
  const [newForm, setNewForm] = useState(false);

  const [showEditViewDocumentForm, setShowEditViewDocumentForm] =
    useState(true);

  const [addNewDocumentPurchase, setAddNewDocumentPurchase] = useState([]);

  const {
    tenderTypeListingResponse,
    documentUploadResp,
    tenderDocumentGetDetailsResponse,
    tenderBasicUpdateResponse,
    tenderBasicAddResponse,
    tenderBasicAddLoadingResponse,
    tenderBasicUpdateLoading,
    stageLevelAddResponse,
    stageLevelUpdate,
    tenderBasicUpdateStageResponse,
    stageDocumentResp,
    documentPaginationResponse,
  } = useSelector((state) => state.tender);

  const { token } = useSelector(
    //for accessing to the token form redux store
    (state) => state.auth
  );

  const handleView = (src) => {
    setTimeout(() => {
      pdfViewer1(src);
    }, 1000);
  };

  useEffect(() => {
    if (getDetails) {
      getDetails?.tenderStageLevelDtos?.map((data, index) => {
        let tenderNotice;
        let OfficialDoc;
        let extensionDoc;
        let RotoDoc;
        let groupByDocuments;

        if (data.stageLevel === "stage1") {
          tenderNotice = cloneDeep(data.tenderStageTenderNoticeFileList);
          groupByDocuments = groupBy(tenderNotice, "fileLableTypeName");
          setSingleTenderNoticeFiles(groupByDocuments);
          OfficialDoc = cloneDeep(data?.tenderStageTenderOffDocumentFileList);
          groupByDocuments = groupBy(OfficialDoc, "fileLableTypeName");
          setSingleOfficalDoc(groupByDocuments);
        } else if (data.stageLevel === "stage2") {
          tenderNotice = cloneDeep(data.tenderStageTenderNoticeFileList);
          groupByDocuments = groupBy(tenderNotice, "fileLableTypeName");
          setDoubleTenderNoticeFiles(groupByDocuments);
          OfficialDoc = cloneDeep(data?.tenderStageTenderOffDocumentFileList);
          groupByDocuments = groupBy(OfficialDoc, "fileLableTypeName");
          setDoubleOfficalDoc(groupByDocuments);
        }

        // if (stageLevel == "stage1") {

        //   let singleStageNotice = data.tenderStageTenderNoticeFileList;
        //   let singleStageOfficialDoc =
        //     data.tenderStageTenderOffDocumentFileList;
        //   let singleStageROTO = data.tenderStageROTOFileList;
        //   let singleStageExtension = data.tenderStageExtensionList;
        // } else if (stageLevel === "stage2") {
        //   let doubleStageNotice = data.tenderStageTenderNoticeFileList;
        //   let doubleStageOfficialDoc =
        //     data.tenderStageTenderOffDocumentFileList;
        //   let doubleStageROTO = data.tenderStageROTOFileList;
        //   let doubleStageExtension = data.tenderStageExtensionList;
        // }
      });
    }
  }, [getDetails]);

  const onClose = () => {
    setExtensionDrawer(false);
  };

  const genExtra = (stages, index) => (
    <div style={{ width: 20 }}>
      <MoreOutlined
        style={{ transform: "rotate(90deg)" }}
        onClick={(event) => {
          setGetStagesLevel(stages.stageLevel);
          let lists = cloneDeep(popoverMenuVisible);
          lists.fill("false");
          lists[index] = "true";
          setPopoverMenuVisible(lists);
          setFetchData(stages);
          setShowDocAddButton(false);
        }}
      />
    </div>
  );

  const update = () => (
    <div
      className="business_action_button_wrapper"
      style={{ display: "flex", width: "100%" }}
    >
      <Button
        onClick={() => {
          setEditForm(false);

          // if (getDetails) {
          //   dispatch(tenderGetBasicDetails(getDetails.id));
          // }
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          addUpdateOfficialInviation();
        }}
        loading={stageLevelAddResponse || stageLevelUpdate}
      >
        Save
      </Button>
    </div>
  );

  const openingDate1 = openingDate?.slice(0, 4);
  const openingDate2 = openingDate?.slice(5, 7);
  const openingDate3 = openingDate?.slice(8, 10);
  const openingTime1 =
    openingDate?.length == 20
      ? 0 + openingDate?.slice(13, 17)
      : openingDate?.slice(13, 18);
  const openingDateAndTime = `${openingDate1}-${openingDate2}-${openingDate3}T${openingTime1}:00.000Z`;

  const submissionDate1 = submissionDate?.slice(0, 4);
  const submissionDate2 = submissionDate?.slice(5, 7);
  const submissionDate3 = submissionDate?.slice(8, 10);
  const submissionTime1 =
    submissionDate?.length == 20
      ? 0 + submissionDate?.slice(13, 17)
      : submissionDate?.slice(13, 18);
  const submissionDateAndTime = `${submissionDate1}-${submissionDate2}-${submissionDate3}T${submissionTime1}:00.000Z`;

  const updateStage = async () => {
    let singleStages = await singleStageForm.validateFields();
    let doubleStages = await doubleStageForm.validateFields();
    let singleStagesBody = {
      ...singleStages,
      tenderBasicId: getDetails.id,
      documentCostCurrencyId: singleStages.documentCostCurrencyId
        ? singleStages.documentCostCurrencyId
        : defaultNRP,
      bidSecurityValueCurrencyId: singleStages.bidSecurityValueCurrencyId
        ? singleStages.bidSecurityValueCurrencyId
        : defaultUSD,
      stageLevel: fetchData.stageLevel,
      submissiondate: moment(singleStages?.submissiondate?._d).format(
        "YYYY-MM-DD, hh:mm A"
      ),
      openingdate: moment(singleStages?.openingdate?._d).format(
        "YYYY-MM-DD, hh:mm A"
      ),
    };

    let body = {
      ...singleStagesBody,
      stageLevel: fetchData.stageLevel,
      tenderBasicId: getDetails.id,
      isActive: true,
      id: fetchData.id,
      officalInvitation: true,
      tenderProcessType: showStages === "singleStages" ? 1 : 2,
      tenderStageTenderNoticeFileList:
        singleStagesBody.tenderStageTenderNoticeFileList,
      tenderStageTenderOffDocumentFileList: tenderDocumentFile,
      tenderStageROTOFileList: singleStagesBody.tenderStageROTOFileList,
      // openingDate:
      //   openingDateAndTime?.length > 20
      //     ? openingDateAndTime
      //     : singleStages?.openingdate,

      // submissionDate:
      //   submissionDateAndTime?.length > 20
      //     ? submissionDateAndTime
      //     : singleStages?.submissiondate,
      // openingDate: openingDate,
      // submissionDate: submissionDate,
    };

    dispatch(updateStagelevel(body));
  };

  const addUpdate = (index) => (
    <div className="action-btn-wrapper" style={{ marginTop: 10 }}>
      <Button
        className="canclAction"
        onClick={() => {
          let lists = cloneDeep(popoverMenuVisible);
          lists[index] = "false";
          setPopoverMenuVisible(lists);
        }}
      >
        Cancel
      </Button>
      <Button
        htmlType="submit"
        className="saveAction"
        // onClick={() => {
        //   let lists = cloneDeep(popoverMenuVisible);
        //   lists[index] = "false";
        //   setPopoverMenuVisible(lists);
        //   updateStage();
        // }}
        loading={tenderBasicAddLoadingResponse || tenderBasicUpdateLoading}
      >
        Save
      </Button>
    </div>
  );

  return (
    <div className="business-information-wrapper">
      {!isEmpty(getDetails)
        ? !isEmpty(getDetails?.tenderStageLevelDtos) &&
          getDetails?.tenderStageLevelDtos?.map((data, i) => {
            return (
              <>
                <div className="business-header">
                  <div className="business-header-title">
                    {data.stageLevel === "stage1"
                      ? `${
                          getDetails.tenderStageLevelDtos?.length === 1
                            ? "Stage 1(RFP) "
                            : "Stage 1(EQ/PQ)"
                        }`
                      : "Stage 2(RFP)"}
                  </div>
                </div>

                {!isEmpty(popoverMenuVisible) &&
                popoverMenuVisible[i] === "true" &&
                data.stageLevel === getStagesLevel ? (
                  <>
                    <div className="reference-edit">
                      <BasicDetails
                        getDetails={getDetails}
                        showParticipation={showParticipation}
                        showStages={showStages}
                        changetest={changetest}
                        allCurrencyListingResponse={allCurrencyListingResponse}
                        allCountryListingResponse={allCountryListingResponse}
                        sisterCompanyListing={sisterCompanyListing}
                        doubleStageForm={doubleStageForm}
                        singleStageForm={singleStageForm}
                        documentPurchaseForm={documentPurchaseForm}
                        popoverMenuVisible={popoverMenuVisible}
                        index={i}
                        fetchData={fetchData}
                        dynamicTenderNoticeFileTypesState={
                          dynamicTenderNoticeFileTypesState
                        }
                        dynamicTenderDocumentFileTypesState={
                          dynamicTenderDocumentFileTypesState
                        }
                        dynamicROTOFileTypesState={dynamicROTOFileTypesState}
                        setOpeningDate={setOpeningDate}
                        setSubmissionDate={setSubmissionDate}
                        setTenderDocumentFile={setTenderDocumentFile}
                        setTenderStageROTOFileList={setTenderStageROTOFileList}
                        singleofficialDoc={singleofficialDoc}
                        doubleofficialDoc={doubleofficialDoc}
                        updateStage={updateStage}
                        addUpdate={addUpdate}
                        data={data}
                        genExtra={genExtra}
                      />
                      {/* {!isEmpty(popoverMenuVisible) &&
                      popoverMenuVisible[i] === "true"
                        ? addUpdate(i)
                        : genExtra(data, i)} */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="business-tender-project-info-wrapper">
                      <div className="business-tender-project-item">
                        <div className="business-tender-project-title">
                          <span>Invitation On</span>
                        </div>
                        <div className="business-tender-project-desc">
                          <span>
                            {data.inviatationDate !== null
                              ? moment(data?.inviatationDate).format(
                                  "YYYY/MM/DD"
                                )
                              : "_ _ _"}
                          </span>
                        </div>
                      </div>
                      {data.stageLevel === "stage1" ? (
                        <>
                          {!isEmpty(singleTenderNoticeFiles) &&
                            dynamicTenderNoticeFileTypesState.map((file) => {
                              let fileName = file.name;
                              return (
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Tender Notice</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    <span className="document-file-length-wrapper">
                                      <PaperClipOutlined />
                                      &nbsp;
                                      {
                                        singleTenderNoticeFiles[`${fileName}`]
                                          ?.length
                                      }
                                    </span>
                                    {singleTenderNoticeFiles[`${fileName}`]
                                      ?.length > 0 && (
                                      <div className="office-attachment-dropdown">
                                        {fileName &&
                                          singleTenderNoticeFiles.hasOwnProperty(
                                            fileName
                                          ) &&
                                          singleTenderNoticeFiles[
                                            `${fileName}`
                                          ].map((doc, index) => {
                                            return (
                                              doc.isActive && (
                                                <>
                                                  <AllFileViewer
                                                    file={doc}
                                                    cqBaseUrl={cqBaseUrl}
                                                    token={token}
                                                  />
                                                </>
                                              )
                                            );
                                          })}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ) : data.stageLevel === "stage2" ? (
                        <>
                          {!isEmpty(doubleTenderNoticeFiles) &&
                            dynamicTenderNoticeFileTypesState.map((file) => {
                              let fileName = file.name;
                              return (
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Tender Notice</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    <span className="document-file-length-wrapper">
                                      <PaperClipOutlined />
                                      &nbsp;
                                      {
                                        doubleTenderNoticeFiles[`${fileName}`]
                                          ?.length
                                      }
                                    </span>
                                    {doubleTenderNoticeFiles[`${fileName}`]
                                      .length > 0 && (
                                      <div className="office-attachment-dropdown">
                                        {fileName &&
                                          doubleTenderNoticeFiles.hasOwnProperty(
                                            fileName
                                          ) &&
                                          doubleTenderNoticeFiles[
                                            `${fileName}`
                                          ].map((doc, index) => {
                                            return (
                                              doc.isActive && (
                                                <>
                                                  <AllFileViewer
                                                    file={doc}
                                                    cqBaseUrl={cqBaseUrl}
                                                    token={token}
                                                  />
                                                </>
                                              )
                                            );
                                          })}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      ) : (
                        ""
                      )}
                      {data?.inviatationRef !== null ? (
                        <div className="business-tender-project-item">
                          <div className="business-tender-project-title">
                            <span>Invitation Ref</span>
                          </div>
                          <div className="business-tender-project-desc">
                            <span>{data?.inviatationRef}</span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {data.description != null && (
                        <div className="business-tender-project-item">
                          <div className="business-tender-project-title">
                            <span>Description</span>
                          </div>
                          <div className="business-tender-project-desc">
                            <span>{data.description}</span>
                          </div>
                        </div>
                      )}
                      {data.documentCost !== null && (
                        <div className="business-tender-project-item">
                          <div className="business-tender-project-title">
                            <span>Document Cost</span>
                          </div>
                          <div className="business-tender-project-desc">
                            <span>
                              {data.documentCostCurrencies} &nbsp;
                              {numberWithCommas(data.documentCost)}
                            </span>
                          </div>
                        </div>
                      )}
                      {data.submissionDate !== null && (
                        <div className="business-tender-project-item">
                          <div className="business-tender-project-title">
                            <span>Submission On</span>
                          </div>
                          <div className="business-tender-project-desc">
                            <span>
                              {moment(data?.submissionDate).format(
                                "YYYY-MM-DD , hh:mm A"
                              )}
                              <Button
                                style={{
                                  width: 22,
                                  height: 20,
                                  border: "none",
                                }}
                                icon={
                                  <PlusCircleOutlined
                                    style={{
                                      color: "blue",
                                      fontSize: 12,
                                    }}
                                    onClick={() => {
                                      setExtensionDrawer(true);
                                      setFetchData(data);
                                      setShowAddExtension(true);
                                      dispatch(tenderStageGetDetails(data.id));
                                    }}
                                  />
                                }
                              />
                              {getDaysDifference(data.submissionDate) >= 0 ? (
                                <span className="remaining-wrapper">
                                  ({getDaysDifference(data.submissionDate)}{" "}
                                  day(s) remaining)
                                </span>
                              ) : (
                                <></>
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {data.openingDate !== null && (
                        <div className="business-tender-project-item">
                          <div className="business-tender-project-title">
                            <span>Opening Date</span>
                          </div>
                          <div className="business-tender-project-desc">
                            <span>
                              {moment(data?.openingDate).format(
                                "YYYY-MM-DD , hh:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {data.bidValidityDate !== null && (
                        <div className="business-tender-project-item">
                          <div className="business-tender-project-title">
                            <span>Bid Validity Till</span>
                          </div>
                          <div className="business-tender-project-desc">
                            <span>
                              {/* {moment(data.bidValidityDate).format(
                                "YYYY-MM-DD, HH:mm:ss"
                              )} */}
                              {moment(data.bidValidityDate).format(
                                "YYYY-MM-DD , hh:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {data.bidSecurityValue !== null && (
                        <div className="business-tender-project-item">
                          <div className="business-tender-project-title">
                            <span>Bid Security Amount</span>
                          </div>
                          <div className="business-tender-project-desc">
                            <span>
                              {data.bidSecurityValueCurrencies}&nbsp;
                              {numberWithCommas(data.bidSecurityValue)}
                            </span>
                          </div>
                        </div>
                      )}
                      {data.bidSecurityValueDate !== null && (
                        <div className="business-tender-project-item">
                          <div className="business-tender-project-title">
                            <span>Bid Security Validity Till</span>
                          </div>
                          <div className="business-tender-project-desc">
                            <span>
                              {/* {moment(data.bidSecurityValueDate).format(
                                "YYYY/MM/DD"
                              )} */}
                              {moment(data.bidSecurityValueDate).format(
                                "YYYY-MM-DD , hh:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {!isEmpty(singleofficialDoc) &&
                      data.stageLevel === "stage1"
                        ? dynamicTenderDocumentFileTypesState.map((file) => {
                            let fileName = file.name;
                            return (
                              <div className="business-tender-project-item">
                                <div className="business-tender-project-title">
                                  <span>Offical Document</span>
                                </div>
                                <div className="business-tender-project-desc">
                                  <span className="document-file-length-wrapper">
                                    <PaperClipOutlined />
                                    &nbsp;
                                    {singleofficialDoc[`${fileName}`]?.length}
                                  </span>
                                  {singleofficialDoc[`${fileName}`]?.length >
                                    0 && (
                                    <div className="office-attachment-dropdown">
                                      {fileName &&
                                        singleofficialDoc.hasOwnProperty(
                                          fileName
                                        ) &&
                                        singleofficialDoc[`${fileName}`].map(
                                          (doc, index) => {
                                            return (
                                              doc.isActive && (
                                                <>
                                                  <AllFileViewer
                                                    file={doc}
                                                    cqBaseUrl={cqBaseUrl}
                                                    token={token}
                                                  />
                                                </>
                                              )
                                            );
                                          }
                                        )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })
                        : !isEmpty(doubleofficialDoc) &&
                          data.stageLevel === "stage2" &&
                          dynamicTenderDocumentFileTypesState.map((file) => {
                            let fileName = file.name;
                            return (
                              <div className="business-tender-project-item">
                                <div className="business-tender-project-title">
                                  <span>Offical Document</span>
                                </div>
                                <div className="business-tender-project-desc">
                                  <span className="document-file-length-wrapper">
                                    <PaperClipOutlined />
                                    &nbsp;
                                    {doubleofficialDoc[`${fileName}`]?.length}
                                  </span>
                                  {doubleofficialDoc[`${fileName}`]?.length >
                                    0 && (
                                    <div className="office-attachment-dropdown">
                                      {doubleofficialDoc[`${fileName}`].map(
                                        (doc, index) => {
                                          return (
                                            doc.isActive && (
                                              <>
                                                <AllFileViewer
                                                  file={doc}
                                                  cqBaseUrl={cqBaseUrl}
                                                  token={token}
                                                />
                                              </>
                                            )
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      {/* <div className="business-tender-project-item">
                        <div className="business-tender-project-title">
                          <span>Tender Document</span>
                        </div>
                        <div className="business-tender-project-desc">
                          <span style={{ color: "red" }}>Missing</span>
                          <Upload>
                            <Button
                              style={{
                                width: 22,
                                height: 20,
                                border: "none",
                              }}
                              icon={
                                <PlusCircleOutlined
                                  style={{ color: "blue", fontSize: 12 }}
                                />
                              }
                            />
                          </Upload>
                        </div>
                      </div> */}

                      {/* .....amit.... */}
                      {dynamicROTOFileTypesState.map((file) => {
                        let fileName = file.name;
                        return (
                          <div className="business-tender-project-item">
                            <div className="business-tender-project-title">
                              <span>ROTO</span>
                            </div>
                            <div className="business-tender-project-desc">
                              <span className="document-file-length-wrapper">
                                <PaperClipOutlined />
                                &nbsp;
                                {data?.tenderStageROTOFileList?.length}
                              </span>
                              {data?.tenderStageROTOFileList?.length > 0 && (
                                <div className="office-attachment-dropdown">
                                  {data?.tenderStageROTOFileList?.map(
                                    (doc, index) => {
                                      return (
                                        doc.isActive && (
                                          <>
                                            <AllFileViewer
                                              file={doc}
                                              cqBaseUrl={cqBaseUrl}
                                              token={token}
                                            />
                                          </>
                                        )
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}

                      {/* .....amit_end.... */}

                      <div className="business-tender-project-action">
                        {!isEmpty(popoverMenuVisible) &&
                        popoverMenuVisible[i] === "true"
                          ? addUpdate(i)
                          : genExtra(data, i)}
                      </div>
                    </div>
                  </>
                )}
                <div
                  className="business-tender-project-info-wrapper"
                  style={{ marginBottom: 10 }}
                >
                  {!isEmpty(data.tenderStageDocumentPurchaseAddUpdateDtos) && (
                    <div className="business-tender-project-document-purchase-header">
                      <div className="business-tender-project-document-purchase-title">
                        Document Purchase
                      </div>
                    </div>
                  )}
                  {!isEmpty(data?.tenderStageDocumentPurchaseAddUpdateDtos) &&
                    data?.tenderStageDocumentPurchaseAddUpdateDtos?.map(
                      (document, index) => {
                        const backGroundColor = index % 2 == 0 ? "#f2f2f2" : "";
                        return (
                          <>
                            {!isEmpty(editDocumentPurchase) &&
                            editDocumentPurchase[index] === "true" &&
                            document.tenderStageLevelId == test ? (
                              <DocumentPurchase
                                allCountryListingResponse={
                                  allCountryListingResponse
                                }
                                sisterCompanyListing={sisterCompanyListing}
                                getDetails={getDetails}
                                documentPurchaseForm={documentPurchaseForm}
                                documentDetails={documentDetails}
                                editDocumentPurchase={editDocumentPurchase}
                                setEditDocumentPurchase={
                                  setEditDocumentPurchase
                                }
                                i={index}
                                stageIndex={stageIndex}
                                setStageIndex={setStageIndex}
                                data={data}
                                setForm={setForm}
                                setOpenForm={setOpenForm}
                                setShowDocAddButton={setShowDocAddButton}
                                addNewDocumentPurchase={addNewDocumentPurchase}
                                setAddNewDocumentPurchase={
                                  setAddNewDocumentPurchase
                                }
                              />
                            ) : (
                              <div className="document-purchase-info-wrapper">
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Company</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    <span style={{ marginRight: "auto" }}>
                                      {document.contactCompanyName}
                                    </span>
                                    <MoreOutlined
                                      style={{ transform: "rotate(90deg)" }}
                                      onClick={() => {
                                        let lists =
                                          cloneDeep(editDocumentPurchase);
                                        lists[index] = "true";
                                        setIndex(index);
                                        setEditDocumentPurchase(lists);
                                        setDocumnentDetails(document);
                                        setShowDocAddButton(false);
                                        setOpenForm(true);
                                        setTest(document.tenderStageLevelId);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Purchase</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    <span>
                                      {moment(document.purchaseDate).format(
                                        "YYYY/MM/DD"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Through</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    {/* <span>{data?.through}</span> */}
                                    <span>{document?.through}</span>
                                  </div>
                                </div>
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Claim</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    <span>
                                      {" "}
                                      {document.claim ? "Yes" : "No"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      }
                    )}
                </div>

                {/* (isEmpty(data.tenderStageDocumentPurchaseAddUpdateDtos) &&
                    test === data.tenderStageLevelId) */}
                <>
                  {(isEmpty(data.tenderStageDocumentPurchaseAddUpdateDtos) ||
                    (!isEmpty(addNewDocumentPurchase) &&
                      addNewDocumentPurchase[i] === "true")) && (
                    <DocumentPurchase
                      allCountryListingResponse={allCountryListingResponse}
                      sisterCompanyListing={sisterCompanyListing}
                      getDetails={getDetails}
                      selectedStages={selectedStages}
                      showStages={showStages}
                      documentPurchaseForm={documentPurchaseForm}
                      data={data}
                      setForm={setForm}
                      setOpenForm={setOpenForm}
                      setShowDocAddButton={setShowDocAddButton}
                      setEditDocumentPurchase={setEditDocumentPurchase}
                      i={i}
                      editDocumentPurchase={editDocumentPurchase}
                      addNewDocumentPurchase={addNewDocumentPurchase}
                      setAddNewDocumentPurchase={setAddNewDocumentPurchase}
                    />
                  )}

                  {!isEmpty(data?.tenderStageDocumentPurchaseAddUpdateDtos) &&
                    showDocAddButton && (
                      <div>
                        <Button
                          type="dashed"
                          className="reference__btns--add"
                          onClick={() => {
                            setOpenForm(true);
                            setShowDocAddButton(false);
                            let lists = cloneDeep(addNewDocumentPurchase);
                            lists[i] = "true";
                            // setEditDocumentPurchase(lists);
                            setAddNewDocumentPurchase(lists);
                            setDocumnentDetails("");
                            // setShowDocAddButton(false);
                            setTest(data.id);
                          }}
                          style={{ width: "100%" }}
                          block
                          icon={<PlusCircleOutlined />}
                        >
                          Add New
                        </Button>
                      </div>
                    )}
                </>

                {getDetails.tenderStageLevelDtos.length === 1 && (
                  <div className="referenceDetails__info">
                    <div className="referenceDetails__label">
                      <span className="referenceDetails--title ">
                        Short Listing
                      </span>
                    </div>

                    <div
                      className="referenceDetails__labeldata"
                      style={{
                        display: "flex",
                        paddingRight: 10,
                      }}
                    >
                      <Checkbox
                        checked={showDoubleStages}
                        onChange={() => {
                          setShowDoubleStage(!showDoubleStages);
                          setShowStages("doubleStages");
                        }}
                      />
                    </div>
                  </div>
                )}

                {getDetails?.tenderStageLevelDtos.length == 1 &&
                  showDoubleStages && (
                    <Stages
                      showStages={showStages}
                      setOfficialForm={setOfficialForm}
                      showParticipation={showParticipation}
                      allCountryListingResponse={allCountryListingResponse}
                      setEditForm={setEditForm}
                      tenderTypeListingResponse={tenderTypeListingResponse}
                      sisterCompanyListing={sisterCompanyListing}
                      getDetails={getDetails}
                      doubleStageForm={doubleStageForm}
                      singleStageForm={singleStageForm}
                      documentPurchaseForm={documentPurchaseForm}
                      editStages={editStages}
                      setEditStages={setEditStages}
                      setGetStagesLevel={setGetStagesLevel}
                      getStagesLevel={getStagesLevel}
                      addUpdateOfficialInviation={addUpdateOfficialInviation}
                      setShowSingleStageEdit={setShowSingleStageEdit}
                      dynamicFileTypesState={dynamicFileTypesState}
                      setShowParticipation={setShowParticipation}
                      setShowDoubleStage={setShowDoubleStage}
                      dynamicTenderNoticeFileTypesState={
                        dynamicTenderNoticeFileTypesState
                      }
                      dynamicTenderDocumentFileTypesState={
                        dynamicTenderDocumentFileTypesState
                      }
                      dynamicROTOFileTypesState={dynamicROTOFileTypesState}
                    />
                  )}
              </>
            );
          })
        : ""}
      {!isEmpty(getDetails.tenderParticipationDtos) && (
        <div className="business-tender-project-document-purchase-header">
          <div className="business-tender-project-document-purchase-title">
            Participation
          </div>
        </div>
      )}
      {!isEmpty(getDetails?.tenderStageLevelDtos) &&
        getDaysDifference(showParticipation) <= 0 && (
          <div className="edittender">
            <Participation
              getDetails={getDetails}
              tenderTypeListingResponse={tenderTypeListingResponse}
              allCountryListingResponse={allCountryListingResponse}
              defaultUSD={defaultUSD}
            />
          </div>
        )}
      {/* tenderAwardStatusDto */}

      {!isEmpty(getDetails.tenderAwardStatusDto) && (
        <div className="business-tender-project-document-purchase-header">
          <div className="business-tender-project-document-purchase-title">
            Award Status
          </div>
        </div>
      )}
      {!isEmpty(getDetails?.tenderAwardStatusDto) && (
        <div className="edittender">
          <RenderAwardStatus getDetails={getDetails} />
        </div>
      )}

      {!isEmpty(getDetails) && isEmpty(getDetails?.tenderAwardStatusDto) && (
        <div className="edittender">
          <AwardStatus
            allCurrencyListingResponse={allCurrencyListingResponse}
            getDetails={getDetails}
          />
        </div>
      )}

      <AddExtensions
        extensionData={extensionData}
        showAddExtension={showAddExtension}
        getDetails={getDetails}
        fetchData={fetchData}
        setExtensionData={setExtensionData}
        showExtensionDrawer={showExtensionDrawer}
        onClose={onClose}
        dynamicExtensionFileTypesState={dynamicExtensionFileTypesState}
      />

      {/* Blog */}
      <div className="business-header">
        <div className="business-header-title" style={{ fontSize: "1.1em" }}>
          Blog
        </div>
      </div>
      <Blog tenderId={tenderId} />
    </div>
  );
};

export default RenderStageView;
