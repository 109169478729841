import {
  Row,
  Col,
  Divider,
  Button,
  Form,
  Skeleton,
  Input,
  InputNumber,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { pickBy } from "lodash";
import { renderNameOrNA } from "utils/misc";
import { employeeProfilePersonalUpdate } from "services/redux/profile/profile.action";

const _FirstPointContact = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [showInfo, setShowInfo] = useState(true);

  const { profileInfo, updateProfileLoading, updateProfile } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    if (updateProfile) {
      setShowInfo(true);
    }
  }, [updateProfile]);

  const validateMessages = {
    required: "${label} is required!",
    whitespace: "${label} can not be empty!",
    types: {
      email: "${label} is not valid!",
    },
  };

  // const showInitialInfo = (profileInfo) => {
  //   return {
  //     ...profileInfo,
  //   };
  // };

  useEffect(() => {
    form.resetFields();
    if (!profileInfo) return;
    form.setFieldsValue({
      ...profileInfo.employeePersonalDetailUpdateDto,
    });
  }, [profileInfo, showInfo]);

  const onFinish = (values) => {
    if (profileInfo?.employeePersonalDetailUpdateDto.appUserId) {
      let val = {
        ...profileInfo.employeePersonalDetailUpdateDto,
        ...values,
      };
      val = pickBy(val, (v) => !!v);

      dispatch(employeeProfilePersonalUpdate(val));
    }
  };

  const renderProfileInfo = () => {
    if (!profileInfo?.employeePersonalDetailUpdateDto) {
      return <Skeleton paragraph={{ rows: 4 }} />;
    }
    return (
      <>
        <div className="profiledetails">
          <div className="profiledetails__wrapper">
            <div className="profiledetails__wrapper--conatainer">
              <Row gutter={[8, 0]}>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Name</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeePersonalDetailUpdateDto
                            .relativeName
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Phone(Res)</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeePersonalDetailUpdateDto
                            .relativePhone
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Phone Mobile</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeePersonalDetailUpdateDto
                            .relativeMobilePhone
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Relation</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeePersonalDetailUpdateDto
                            .relativeRelation
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">
                        Contact Address
                      </span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeePersonalDetailUpdateDto
                            .relativeAddress
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Comment</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeePersonalDetailUpdateDto.comment
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="profile__details--footer"
                style={{ textAlign: "left" }}
              >
                <Button
                  type="link"
                  className="btn-update"
                  onClick={() => setShowInfo(false)}
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderProfileUpdate = () => {
    if (!profileInfo?.employeePersonalDetailUpdateDto) {
      return <Skeleton paragraph={{ rows: 10 }} />;
    }
    return (
      <div className="profiledetails">
        <div className="profiledetails__wrapper">
          <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            validateMessages={validateMessages}
            // initialValues={showInitialInfo(
            //   profileInfo?.employeePersonalDetailUpdateDto
            // )}
          >
            <div className="profiledetails__wrapper--conatainer">
              <Row gutter={[12, 12]}>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Name</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="relativeName">
                        <Input placeholder="" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={24} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Phone(Res)</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="relativePhone">
                        <InputNumber placeholder="" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>

                <Col lg={24} md={24} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Phone Mobile</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="relativeMobilePhone">
                        <InputNumber placeholder="" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Relation</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="relativeRelation">
                        <Input placeholder="" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">
                        Contact Address
                      </span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="relativeAddress">
                        <Input placeholder="" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Comment</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="comment">
                        <Input.TextArea placeholder="" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="profile__info--footer">
              <Button
                type="link"
                className="btn-cancel"
                onClick={() => setShowInfo(true)}
              >
                Cancel
              </Button>
              <Button
                type="link"
                className="btn-update"
                htmlType="submit"
                loading={updateProfileLoading}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  return <div>{showInfo ? renderProfileInfo() : renderProfileUpdate()}</div>;
};

export default _FirstPointContact;
