import { Button, Drawer, Form, Input } from "antd";
import Header from "components/header";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DashboardFeeUpdate } from "services/redux/admin/FiscalYear/action";

const FeeRemainingModal = ({ showDrawer, setShowDrawer, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = (values) => {
    const body = {
      financialAccountingInfomationId:
        data?.financialAccountingInformationId ?? "",
      isActive: true,
      companyAbbrevation: "ICTC",
      accountHead: values?.accountHead,
      accountCategory: "FeeDueSub",
      amount: +values?.amount,
      amountTwo: +values?.amountTwo,
      amountThree: 0,
    };
    console.log("body", body);
    dispatch(DashboardFeeUpdate(body, "FeeDueSub")).then(() =>
      setShowDrawer(false)
    );
  };

  useEffect(() => {
    if (data !== undefined) {
      form.setFieldsValue({
        accountHead: data?.accountHead,
        amount: data?.amount,
        amountTwo: data?.amountTwo,
      });
    }
  }, [data, form]);

  return (
    <Drawer
      title='Fee Due form'
      visible={showDrawer}
      onClose={() => setShowDrawer(false)}
      width={472}
      maskClosable={false}
      closable={false}
    >
      {/* <Header className="drawer__header">
        <h4 className="drawer__header--title">Fee Due Add</h4>
      </Header> */}

      <Form
        form={form}
        className='drawer-form-wrapper'
        {...layout}
        onFinish={onFinish}
      >
        <div className='drawer-form-row'>
          <div className='drawer-form-group'>
            <Form.Item name='accountHead' label='Account Head'>
              <Input />
            </Form.Item>
          </div>
          <div className='drawer-form-group'>
            <Form.Item name='amount' label='Amount in NPR'>
              <Input />
            </Form.Item>
          </div>

          <div className='drawer-form-group'>
            <Form.Item name='amountTwo' label='Amount in USD'>
              <Input />
            </Form.Item>
          </div>
        </div>

        <div className='model-footer'>
          <Button onClick={() => setShowDrawer(false)}>Cancel</Button>
          <Button
            className='guest__entry__button'
            type='primary'
            htmlType='submit'
          >
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default FeeRemainingModal;
