import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";

const InterviewTemplateTable = ({ setOpenForm, setUpdateData }) => {
  const dispatch = useDispatch();

  const { questionTypeGetResponse, questionTypeGetLoading } = useSelector(
    (state) => state.profile
  );

  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Question Type",
      dataIndex: "questionTypeName",
      key: "questionTypeName",
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Action",
      render: (record) => (
        <button
          style={{
            background: "none",
            border: "none",
            color: "#67a1e4",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenForm({
              modal: false,
              drawer: true,
              edit: true,
            });
            setUpdateData(record);
          }}
        >
          Edit
        </button>
      ),
      width: "7%",
    },
  ];

  return (
    <Table
      dataSource={questionTypeGetResponse?.items}
      columns={columns}
      className="next-organizer-table"
      style={{ fontSize: "15px" }}
      pagination={false}
      loading={{ spinning: questionTypeGetLoading }}
    />
  );
};

export default InterviewTemplateTable;
