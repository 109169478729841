export const validateReason = (minChars, minWords) => ({
    validator: (_, value) => {
      const words = value.trim().split(/\s+/);
      const characterCount = value.trim().length;
      const wordCount = words.length;
  
      if (characterCount < minChars || wordCount < minWords) {
        return Promise.reject(
          new Error(`Please justify the reason with at least ${minChars} characters and ${minWords} words`)
        );
      }
      return Promise.resolve();
    },
  });