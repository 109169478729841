import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allCompanyListing } from "services/redux/Reference/action";
import { actionForAddUpdateBidBounds } from "services/redux/cead/action";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { showNumber } from "utils/showNumber";

const { Title } = Typography;
const { Item, List } = Form;

export default function BidBound({
  formBidBound,
  singleTenderCeadData,
  currencyOptions,
}) {
  const dispatch = useDispatch();

  const { tenderCeadBidBoundLoading } = useSelector(
    (state) => state.ceadReducer
  );

  useEffect(() => {
    if (!tenderCeadBidBoundLoading) {
      setBidBondShow(false);
    }
  }, [tenderCeadBidBoundLoading]);

  const [showBidBond, setBidBondShow] = useState(false);

  const columns = [
    {
      title: "Issuing Bank",
      dataIndex: "issueingBank",
    },
    {
      title: "Foreign Bank",
      dataIndex: "foreignBank",
    },
    {
      title: "L/C Description",
      dataIndex: "description",
    },
    {
      title: "Currency",
      dataIndex: "abbvr",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => {
        return showNumber(text);
      },
    },
    {
      title: "Validity",
      dataIndex: "validUpto",
    },
    {
      title: "Last Shipment Date",
      dataIndex: "lastShipmentDate",
    },
    // {
    //   title: "Renewable Record",
    //   dataIndex: "isRenewalRecord",
    //   render: (text) => {
    //     return text ? "True" : "False";
    //   },
    // },
  ];

  const dataSource = singleTenderCeadData?.biD_BOND_BidInformation?.map((x) => {
    return {
      ...x,
      abbvr: x?.currencyDto?.abbvr,
      lastShipmentDate: moment(x?.lastShipmentDate).format("YYYY-MM-DD"),
      validUpto: moment(x?.validUpto).format("YYYY-MM-DD"),
    };
  });

  const onFinishBidBound = (values) => {
    let apiBody = values?.bidBond;
    dispatch(
      actionForAddUpdateBidBounds(
        apiBody,
        singleTenderCeadData?.id,
        "bidType=0"
      )
    );
  };

  return (
    <>
      <Title level={5} style={{ background: "#DFDFDF" }}>
        Bid Bond{" "}
        {!showBidBond && (
          <span
            onClick={() => {
              setBidBondShow(!showBidBond);
            }}
            className="bid-pointer-cursor"
          >
            <EditOutlined />
          </span>
        )}
      </Title>

      {!showBidBond ? (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          style={{ maxHeight: 550, overflow: "scroll" }}
        />
      ) : (
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          form={formBidBound}
          onFinish={onFinishBidBound}
        >
          <List name="bidBond">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={field.key} gutter={[10, 0]}>
                    <Col span={1}>{index + 1}</Col>
                    <Col span={4}>
                      <Item
                        key={field.key}
                        label="Issuing Bank"
                        name={[field.name, "issueingBank"]}
                        fieldKey={[field.fieldKey, "issueingBank"]}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Currency is required",
                        //   },
                        // ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Select Bank" />
                      </Item>
                    </Col>
                    <Col span={4}>
                      <Item
                        key={field.key}
                        label="Issuing Foreign Bank (if any)"
                        name={[field.name, "foreignBank"]}
                        fieldKey={[field.fieldKey, "foreignBank"]}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Vat % is required",
                        //   },
                        // ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Select Bank" />
                      </Item>
                    </Col>
                    <Col span={3}>
                      <Item
                        key={field.key}
                        label="L/C Description"
                        name={[field.name, "description"]}
                        fieldKey={[field.fieldKey, "description"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter description" />
                      </Item>
                    </Col>
                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="Currency"
                        name={[field.name, "currencyId"]}
                        fieldKey={[field.fieldKey, "currencyId"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select
                          options={currencyOptions}
                          // defaultValue={() => {
                          //   allCurrencyListingResponse?.map((currency) => {
                          //     if (currency.abbvr == "USD") {
                          //       return currency.currencyId;
                          //     }
                          //   });
                          // }}
                          placeholder="Select Currency"
                        />
                      </Item>
                    </Col>
                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="Amount"
                        name={[field.name, "amount"]}
                        fieldKey={[field.fieldKey, "amount"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter amount..." />
                      </Item>
                    </Col>
                    <Col span={3}>
                      <Item
                        key={field.key}
                        label="Validity"
                        name={[field.name, "validUpto"]}
                        fieldKey={[field.fieldKey, "validUpto"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <DatePicker style={{ height: 30 }} />
                      </Item>
                    </Col>
                    <Col span={3}>
                      <Item
                        key={field.key}
                        label="Last Shipment Date"
                        name={[field.name, "lastShipmentDate"]}
                        fieldKey={[field.fieldKey, "lastShipmentDate"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <DatePicker style={{ height: 30 }} />
                      </Item>
                    </Col>
                    {/* <Col span={2}>
                      <Item
                        key={field.key}
                        label="Renewal Record"
                        name={[field.name, "IsRenewalRecord"]}
                        fieldKey={[field.fieldKey, "IsRenewalRecord"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        valuePropName="checked"
                      >
                        <Checkbox
                     // onChange={(e) => {
                     //   setIsRenewalRecord(e.target.checked);
                     // }}
                     // value={isRenewalRecord}
                     // defaultChecked={
                     //   singleTenderCeadData?.biD_BOUND_BidInformation?.[0]
                     //     ?.isRenewalRecord
                     // }
                     />
                        <Checkbox />
                      </Item>
                    </Col> */}
                    <Col span={24}>
                      {index >= 0 && (
                        <Button
                          style={{ float: "right", marginTop: "-12px" }}
                          icon={<MinusCircleOutlined />}
                          onClick={() => remove(field.name)}
                        />
                      )}
                    </Col>
                  </Row>
                ))}
                <Button
                  style={{ marginBottom: "16px" }}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusCircleOutlined />}
                >
                  Add Bid Bond
                </Button>
              </>
            )}
          </List>

          <Row style={{ float: "right", paddingBottom: "20px" }}>
            <Col>
              <Button
                htmlType="submit"
                onClick={() => {
                  setBidBondShow(false);
                }}
              >
                Cancel
              </Button>
            </Col>

            <Col style={{ marginLeft: "10px" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
