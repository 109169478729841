import { uniqBy } from "lodash";
import { REFERENCE__TYPES } from "../../../types";

const INITIAL_STATE = {
  referenceOthersPaginationResponse: null,
  referenceOthersPaginationLoading: false,
  referenceOthersDetailsLoading: false,
  referenceOthersDetailsResponse: null,

  // add reference secondary
  referenceOthersAddResponse: [],
  referenceOthersAddLoading: false,

  //reference secondary Update
  referenceOthersUpdateResponse: [],
  referenceOthersLoading: false,

  referenceOthersisFavResponse: null,
  referenceOthersisFavResponseLoading: false,

  // primary and secondary tag listing
  referenceOthersPaginationWithRootLevelResponse: [],
  referenceOthersPaginationWithRootLevelLoading: false,

  // reference others combined add
  referenceOthersCombineAddResponse: [],
  referenceOthersCoombineAddLoading: false,

  // reference others combined update

  referenceOthersCombineUpdateResponse: [],
  referenceOthersCombineUpdateLoading: false,
};

export default function referenceOthersReducer(state = INITIAL_STATE, action) {
  //reference project pagination
  switch (action.type) {
    //PROJECT
    case REFERENCE__TYPES.REFERENCE_OTHERS_PAGINATION_INIT:
      return {
        ...state,
        referenceOthersPaginationLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_PAGINATION_SUCCESS:
      return {
        ...state,
        referenceOthersPaginationResponse: {
          items: action.loadMore
            ? uniqBy(
                state.referenceOthersPaginationResponse.items.concat(
                  action.payload.items
                ),
                "id"
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
        referenceOthersPaginationLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_PAGINATION_FINISH:
      return {
        ...state,
        referenceOthersPaginationLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_GetDetails_INIT:
      return {
        ...state,
        referenceOthersDetailsLoading: true,
        referenceOthersDetailsResponse: null,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_GetDetails_SUCCESS:
      return {
        ...state,
        referenceOthersDetailsResponse: action.payload,
        referenceOthersDetailsLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_GetDetails_FINISH:
      return {
        ...state,
        referenceOthersDetailsResponse: null,
        referenceOthersDetailsLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_ADD_INIT:
      return {
        referenceOthersAddLoading: true,
        referenceOthersAddResponse: null,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_ADD_SUCCESS:
      return {
        ...state,
        referenceOthersAddLoading: false,
        referenceOthersAddResponse: action.payload,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_ADD_FINISH:
      return {
        ...state,
        referenceOthersAddLoading: false,
        referenceOthersAddResponse: null,
      };

    //project Update
    case REFERENCE__TYPES.REFERENCE_OTHERS_UPDATE_INIT:
      return {
        ...state,
        referenceOthersUpdateResponse: null,
        referenceOthersLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_OTHERS_UPDATE_SUCCESS:
      return {
        referenceOthersUpdateResponse: action.payload,
        referenceOthersDetailsResponse: action.payload,
        referenceOthersLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_OTHERS_UPDATE_FINISH:
      return {
        ...state,
        referenceOthersLoading: false,
        referenceOthersUpdateResponse: null,
      };

    //REFERENCEOthers is Fav

    case REFERENCE__TYPES.REFERENCE_OTHERS_IS_FAV_INIT:
      return {
        ...state,
        referenceOthersisFavResponse: null,
        referenceOthersisFavResponseLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_OTHERS_IS_FAV_SUCCESS:
      return {
        ...state,
        referenceOthersDetailsResponse: {
          ...action.record,
          isFavorite: !action.record?.isFavorite,
        },
        referenceOthersPaginationResponse: {
          items: state.referenceOthersPaginationResponse.items.map((items) => {
            if (items.id === action.payload.referenceOthersId) {
              return {
                ...items,
                isFavorite: !items.isFavorite,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.referenceOthersPaginationResponse.totalCount,
        },
        referenceOthersisFavResponseLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_OTHERS_IS_FAV_FINISH:
      return {
        ...state,
        referenceOthersisFavResponseLoading: false,
      };

    //referenceOthersPaginationWithRootLevel for primary and secondary tag listing

    case REFERENCE__TYPES.REFERENCE_OTHERS_ROOTLEVEL_INIT:
      return {
        ...state,
        referenceOthersPaginationWithRootLevelLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_ROOTLEVEL_SUCCESS:
      return {
        ...state,
        referenceOthersPaginationWithRootLevelLoading: false,
        referenceOthersPaginationWithRootLevelResponse: action.payload,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS_ROOTLEVEL_SUCCESS:
      return {
        ...state,
        referenceOthersPaginationWithRootLevelLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS__COMBINED_ADD_INIT:
      return {
        ...state,
        referenceOthersCombineAddLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS__COMBINED_ADD_SUCCESS:
      return {
        ...state,
        referenceOthersCombineAddLoading: false,
        referenceOthersCombineAddResponse: action.payload,
      };

    case REFERENCE__TYPES.REFERENCE_OTHERS__COMBINED_ADD_FINISH:
      return {
        ...state,
        referenceOthersCombineAddLoading: false,
        referenceOthersCombineAddResponse: [],
      };

    // refernce combine update
    case REFERENCE__TYPES.REFERENCE_OTHERS_COMBINE_UPDATE_INIT:
      return {
        ...state,
        referenceOthersCombineUpdateLoading: true,
      };
    case REFERENCE__TYPES.REFERENCE_OTHERS_COMBINE_UPDATE_SUCCESS:
      return {
        ...state,
        referenceOthersCombineUpdateResponse: action.payload,
        referenceOthersDetailsResponse: action.payload,
        referenceOthersCombineUpdateLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_OTHERS_COMBINE_UPDATE_FINISH:
      return {
        ...state,
        referenceOthersCombineUpdateResponse: [],
        referenceOthersCombineUpdateLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
