import { appBaseUrl } from "utils/config";
import { getService, postService } from "services/commonServices";
import queryString from "query-string";

export class DownloadFileService {
  apiForSampleDailyDairy() {
    let url = `${appBaseUrl}/adminModule/downloadSampleDailyDiaryListExcel`;
    let data = getService(url);
    return data;
  }
}
