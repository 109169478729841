import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Steps, Row, Col, Form, Input, Select, Button, Tabs } from "antd";
import { isEmpty, values } from "lodash";
import moment from "moment";
import { supervisorEmployeeMail, actionForAddEmployeeEvaluationSelfAction } from "../../../services/redux/profile/profile.action";
import { getEmployeeReview } from "services/redux/profile/profile.action";
import storage from "../../../utils/storage";
import EmployeeEvaluationRating from "views/Requisition/AddUpdateEmployeeEvaluation/Includes/EmployeeEvaluationRating";

const EmployeeEvaluationDetails = ({
  id,
  setInstanceMessageCenterListing,
  showButton,
  currentStep,
}) => {
  const {
    employeeReviewResponse,
    employeeReviewLoading,
    profileInfo,
    employeeEvaluationSelfActionResp,
    employeeEvaluationSelfActionLoading,
  } =
    useSelector((state) => state.profile);

  const dispatch = useDispatch();
  let localStorageId = storage.getItem("userId");

  const [allEmployeeId, setAllEmployeeId] = useState([]);
  const [currentLoginUserId, setCurrentLoginUserId] = useState([]);
  const [currentUserName, setCurrentUserName] = useState();
  const [isEditing, setIsEditing] = useState(true);

  const [form] = Form.useForm();
  const [selfEmployeeForm] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;

  const [jobUnderstanding, setJobUnderstanding] = useState(false);
  const [jobSkills, setJobSkills] = useState(false);
  const [growth, setGrowth] = useState(false);
  const [performance, setPerformance] = useState(false);
  const [accountable, setAccountable] = useState(false);
  const [commitment, setCommitment] = useState(false);
  const [integrity, setIntegrity] = useState(false);
  const [collaboration, setCollaboration] = useState(false);
  const [attendance, setAttendance] = useState(false);
  const [communicationSkills, setCommunicationSkills] = useState(false);

  const [supervisorList, setSupervisorList] = useState([]);
  const [isCurrentUserSupervisor, setIsCurrentUserSupervisor] = useState();
  const [isCurrentUserHR, setIsCurrentUserHR] = useState();
  const [currentSupervisorForm, setCurrentSupervisorForm] = useState();
  const [currentEmployeeEvaluationForm, setCurrentEmployeeEvaluationForm] = useState();
  const [isCurrentSvFormSubmitted, setIsCurrentSvFormSubmitted] = useState();
  const [currentMailSteps, setCurrentMailSteps] = useState();
  const [isCurrentUserSelfEvaluation, setIsCurrentUserSelfEvaluation] = useState(false);
  const [isCurrentUserSelfEvaluationFormSubmitted, setIsCurrentUserSelfEvaluationFormSubmitted] = useState(false);
  const [participantEmployeeEvaluationForm, setParticipantEmployeeEvaluationForm] = useState();

  // added
  const onChangeJobUnderstanding = (e) => {
    setJobUnderstanding(e.target.value)
  }

  const onChangeJobSkills = (e) => {
    setJobSkills(e.target.value);
  }

  const onChangeGrowth = (e) => {
    setGrowth(e.target.value);
  }

  const onChangePerformance = (e) => {
    setPerformance(e.target.value);
  }

  const onChangeAccountable = (e) => {
    setAccountable(e.target.value);
  }

  const onChangeCommitment = (e) => {
    setCommitment(e.target.value);
  }

  const onChangeIntegrity = (e) => {
    setIntegrity(e.target.value);
  }

  const onChangeCollaboration = (e) => {
    setCollaboration(e.target.value);
  }

  const onChangeAttendance = (e) => {
    setAttendance(e.target.value);
  }

  const onChangeCommunicationSkills = (e) => {
    setCommunicationSkills(e.target.value);
  }


  // props
  let employeeFormProps = {
    jobUnderstanding,
    jobSkills,
    growth,
    performance,
    accountable,
    commitment,
    integrity,
    collaboration,
    attendance,
    communicationSkills,

    onChangeJobUnderstanding,
    onChangeJobSkills,
    onChangeGrowth,
    onChangePerformance,
    onChangeAccountable,
    onChangeCommitment,
    onChangeIntegrity,
    onChangeCollaboration,
    onChangeAttendance,
    onChangeCommunicationSkills,
  }


  useEffect(() => {
    if (
      !isEmpty(employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs)
    ) {
      const filteredSuperVisor =
        employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs?.filter(
          function (obj) {
            return obj.participantType === 1;
          }
        );
      setSupervisorList([...filteredSuperVisor]);

      var supervisorForm =
        employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs?.filter(
          function (obj) {
            return (
              obj.participantUserId === currentLoginUserId &&
              obj.participantType === 1
            );
          }
        );
      setCurrentSupervisorForm(supervisorForm);

      const isSvFormSubmitted =
        employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs?.some(
          (obj) =>
            obj.participantUserId === currentLoginUserId &&
            obj.participantType === 1 &&
            obj.isResponed === true
        );
      setIsCurrentSvFormSubmitted(isSvFormSubmitted);

      const currentUserIsSupervisor =
        employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs?.some(
          (obj) =>
            obj.participantUserId === currentLoginUserId &&
            obj.participantType === 1
        );
      setIsCurrentUserSupervisor(currentUserIsSupervisor);

      const employeeSelfEvaluationForm = employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs?.filter(
        function (obj) {
          return (
            obj.participantUserId === currentLoginUserId &&
            obj.participantType === 0
          );
        }
      );
      setCurrentEmployeeEvaluationForm(employeeSelfEvaluationForm);

      const currentUserSelfEvaluation = employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs?.some(
        (obj) =>
          obj.participantUserId === currentLoginUserId &&
          obj.participantType === 0
      );
      setIsCurrentUserSelfEvaluation(currentUserSelfEvaluation)

      const isSelfEmployeeEvaluationSubmitted = employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs?.some(
        (obj) =>
          obj.participantUserId === currentLoginUserId &&
          obj.participantType === 0 &&
          obj.isResponed === true
      );
      setIsCurrentUserSelfEvaluationFormSubmitted(isSelfEmployeeEvaluationSubmitted)

      const formParticipantForm = employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs?.filter(
        (obj) =>
          obj.participantType === 0
      );
      setParticipantEmployeeEvaluationForm(formParticipantForm)

      const currentUserIsHR =
        employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs?.some(
          (obj) =>
            obj.participantUserId === currentLoginUserId &&
            obj.participantType === 2
        );
      setIsCurrentUserHR(currentUserIsHR);
    }
  }, [employeeReviewResponse?.employeeEvaluationParticipantDetailsDTOs]);


  useEffect(() => {
    if (
      !isEmpty(employeeEvaluationSelfActionResp?.employeeEvaluationParticipantDetailsDTOs)
    ) {
      const employeeSelfEvaluationForm = employeeEvaluationSelfActionResp?.employeeEvaluationParticipantDetailsDTOs?.filter(
        function (obj) {
          return (
            obj.participantUserId === currentLoginUserId &&
            obj.participantType === 0
          );
        }
      );
      setCurrentEmployeeEvaluationForm(employeeSelfEvaluationForm);
    }
  }, [employeeEvaluationSelfActionResp])


  // added
  useEffect(() => {
    if (!isEmpty(supervisorList)) {
      setCurrentMailSteps(
        supervisorList.every((item) => item.isResponed) ? 3 : 2
      );
    }
  }, [supervisorList]);

  useEffect(() => {
    setCurrentLoginUserId(profileInfo?.userRegistrationAddUpdateDto?.appUserId);
    setCurrentUserName(profileInfo?.userRegistrationAddUpdateDto.userName.toUpperCase())
  }, [profileInfo?.userRegistrationAddUpdateDto?.appUserId]);

  useEffect(() => {
    dispatch(getEmployeeReview(id));
  }, [id]);

  useEffect(() => {
    setAllEmployeeId(employeeReviewResponse?.immidiateSuperiorId || null);
  }, [employeeReviewResponse?.immidiateSuperiorId]);

  const onSubmitEvaluation = (values, type) => {
    let value = {};
    const date =
      moment(employeeReviewResponse?.date).format("YYYY-MM-DD") || null;

    values = {
      participantUserId: currentLoginUserId,
      id: id,
      employeeId: id,
      name: employeeReviewResponse?.name || null,
      job: employeeReviewResponse?.jobTitle || null,
      department: employeeReviewResponse?.department || null,
      supervisor: employeeReviewResponse?.immidiateSuperior || null,
      username: employeeReviewResponse?.employeeInitials || null,
      date: date || null,
      review: employeeReviewResponse?.reviewPeriod || null,

      jobKnowledge: {
        number: values?.jobUnderstandingRating || null,
        comments: values?.jobUnderstandingComment || null,
      },
      jobSkills: {
        number: values?.jobSkillsRating || null,
        comments: values?.jobSkillsComment || null,
      },
      growth: {
        number: values?.growthRating || null,
        comments: values?.growthComment || null,
      },
      performance: {
        number: values?.performanceRating || null,
        comments: values?.performanceComment || null,
      },
      accountable: {
        number: values?.accountableRating || null,
        comments: values?.accountableComment || null,
      },
      commitment: {
        number: values?.commitmentRating || null,
        comments: values?.commitmentComment || null,
      },
      integrity: {
        number: values?.integrityRating || null,
        comments: values?.integrityComment || null,
      },
      collaboration: {
        number: values?.collaborationRating || null,
        comments: values?.collaborationComment || null,
      },
      punctuality: {
        number: values?.attendanceRating || null,
        comments: values?.attendanceComment || null,
      },
      communication: {
        number: values?.communicationSkillsRating || null,
        comments: values?.communicationSkillsComment || null,
      },

      // additionalComments: values?.additionalcomment || null,
      // newGoal: values?.goalcomment || null,
      majorStrength: values?.majorStrengthComment || null,
      areasOfImprovement: values?.areasOfImprovementComment || null,

      isApproved: true,
      isResponed: true,
      isactive: true,
      ApprovedRejectRemarks: `${currentUserName} Reviewed`,
    };

    try {
      if (type == 'selfEmployeeForm')
        dispatch(
          actionForAddEmployeeEvaluationSelfAction(id, values, setInstanceMessageCenterListing)
        )
      else {
        dispatch(
          supervisorEmployeeMail(id, values, setInstanceMessageCenterListing)
        );
      }
    } catch (err) {
      console.log("error", err)
    } finally {
      setIsCurrentUserSelfEvaluationFormSubmitted(true);
    }
  };

  const listingRating = () => {
    return (
      <>
        <div className="mail-leave-details">
          <div className="leave-details">
            <Row gutter={20}>
              <Col lg={12} className="leave-col">
                <div className="leave-title">Name</div>
                <div className="leave-input">Job Understanding</div>
                <div className="leave-input">Job Skills</div>
                <div className="leave-input">Growth</div>
                <div className="leave-input">Performance</div>
                <div className="leave-input">Accountable</div>
                <div className="leave-input">Commitment</div>
                <div className="leave-input">Integrity</div>
                <div className="leave-input">Collaboration</div>
                <div className="leave-input">Attendance</div>
                <div className="leave-input">Communication Skills</div>
              </Col>
              <Col lg={6} className="leave-col">
                <div className="leave-title">Rating Number</div>
                <div className="leave-input">
                  {/* {employeeReviewResponse?.jobKnowledge?.number} */}
                  {currentSupervisorForm?.map(
                    (item) => item?.jobKnowledge?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.jobSkills?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.growth?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.performance?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.accountable?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.commitment?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.integrity?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.collaboration?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.punctuality?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.communication?.number
                  )}
                </div>
              </Col>
              <Col lg={6} className="leave-col">
                <div className="leave-title">Comments</div>
                <div className="leave-input">
                  {/* {employeeReviewResponse?.jobKnowledge?.number} */}
                  {currentSupervisorForm?.map(
                    (item) => item?.jobKnowledge?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.jobSkills?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.growth?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.performance?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.accountable?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.commitment?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.integrity?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.collaboration?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.punctuality?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.communication?.comments
                  )}
                </div>
              </Col>
              <Col lg={24} className="leave-col">
                <div className="leave-title">Major Strength</div>
                <div className="leave-input">
                  {currentSupervisorForm?.map(
                    (item) => item?.majorStrength
                  )}
                </div>
                <div className="leave-title">Area of Improvement</div>
                <div className="leave-input">
                  {currentSupervisorForm?.map((item) => item?.areasOfImprovement)}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {participantEmployeeListing()}
      </>
    );
  };

  const participantEmployeeListing = () => {
    return (
      <>
        <h5 style={{
          margin: "0 10px",
          padding: "10px 0",
          fontSize: "14px"
        }}>EMPLOYEE: {participantEmployeeEvaluationForm?.map(
          (item) => item?.participantUser?.username
        )} Review</h5>
        <div className="mail-leave-details">
          <div className="leave-details">
            <Row gutter={20}>
              <Col lg={12} className="leave-col">
                <div className="leave-title">Name</div>
                <div className="leave-input">Job Understanding</div>
                <div className="leave-input">Job Skills</div>
                <div className="leave-input">Growth</div>
                <div className="leave-input">Performance</div>
                <div className="leave-input">Accountable</div>
                <div className="leave-input">Commitment</div>
                <div className="leave-input">Integrity</div>
                <div className="leave-input">Collaboration</div>
                <div className="leave-input">Attendance</div>
                <div className="leave-input">Communication Skills</div>
              </Col>
              <Col lg={6} className="leave-col">
                <div className="leave-title">Rating Number</div>
                <div className="leave-input">
                  {/* {employeeReviewResponse?.jobKnowledge?.number} */}
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.jobKnowledge?.number
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.jobSkills?.number
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.growth?.number
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.performance?.number
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.accountable?.number
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.commitment?.number
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.integrity?.number
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.collaboration?.number
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.punctuality?.number
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.communication?.number
                  )}
                </div>
              </Col>
              <Col lg={6} className="leave-col">
                <div className="leave-title">Comments</div>
                <div className="leave-input">
                  {/* {employeeReviewResponse?.jobKnowledge?.number} */}
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.jobKnowledge?.comments
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.jobSkills?.comments
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.growth?.comments
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.performance?.comments
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.accountable?.comments
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.commitment?.comments
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.integrity?.comments
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.collaboration?.comments
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.punctuality?.comments
                  )}
                </div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.communication?.comments
                  )}
                </div>
              </Col>
              <Col lg={24} className="leave-col">
                <div className="leave-title">Major Strength</div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map(
                    (item) => item?.majorStrength
                  )}
                </div>
                <div className="leave-title">Area of Improvement</div>
                <div className="leave-input">
                  {participantEmployeeEvaluationForm?.map((item) => item?.areasOfImprovement)}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    )
  }

  const listingSelfEvaluation = () => {
    return (
      <>
        <div className="mail-leave-details">
          <div className="leave-details">
            <Row gutter={20}>
              <Col lg={12} className="leave-col">
                <div className="leave-title">Name</div>
                <div className="leave-input">Job Understanding</div>
                <div className="leave-input">Job Skills</div>
                <div className="leave-input">Growth</div>
                <div className="leave-input">Performance</div>
                <div className="leave-input">Accountable</div>
                <div className="leave-input">Commitment</div>
                <div className="leave-input">Integrity</div>
                <div className="leave-input">Collaboration</div>
                <div className="leave-input">Attendance</div>
                <div className="leave-input">Communication Skills</div>
              </Col>
              <Col lg={6} className="leave-col">
                <div className="leave-title">Rating Number</div>
                <div className="leave-input">
                  {/* {employeeReviewResponse?.jobKnowledge?.number} */}
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.jobKnowledge?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.jobSkills?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.growth?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.performance?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.accountable?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.commitment?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.integrity?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.collaboration?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.punctuality?.number
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.communication?.number
                  )}
                </div>
              </Col>
              <Col lg={6} className="leave-col">
                <div className="leave-title">Comments</div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.jobKnowledge?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.jobSkills?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.growth?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.performance?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.accountable?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.commitment?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.integrity?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.collaboration?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.punctuality?.comments
                  )}
                </div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.communication?.comments
                  )}
                </div>
              </Col>
              <Col lg={24} className="leave-col">
                <div className="leave-title">Major Strength</div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map(
                    (item) => item?.majorStrength
                  )}
                </div>
                <div className="leave-title">Area of Improvement</div>
                <div className="leave-input">
                  {currentEmployeeEvaluationForm?.map((item) => item?.areasOfImprovement)}
                </div>
              </Col>
            </Row>
          </div>
        </div>

      </>
    )
  }

  const [activeTabKey, setActiveTabKey] = useState("1");
  const onChangeTab = (key) => {
    setActiveTabKey(key);
  };

  const mailSupervisorTabSteps = () => {
    return (
      <>
        <div className="mail-leave-details">
          <div className="leave-progerss">
            <Steps current={currentMailSteps}>
              <Steps.Step title="Request" />
              <Steps.Step title="Reviewed" />
              <Steps.Step title="Complete" />
            </Steps>
          </div>
        </div>
      </>
    );
  };

  const employeeEvaluationTabSteps = () => {
    return (
      <>
        <div className="mail-leave-details">
          <div className="leave-progerss">
            <Steps current={currentStep}>
              <Steps.Step title="Request" />
              <Steps.Step title="Reviewed" />
              <Steps.Step title="Complete" />
            </Steps>
          </div>
        </div>
      </>
    );
  };

  const hrTabs = () => {
    return (
      <>
        {participantEmployeeListing()}
        <Tabs
          onChange={onChangeTab}
          type="card"
          activeKey={activeTabKey}
          style={{ margin: "10px 10px" }}
        >
          {supervisorList.map((tab, index) => (
            <Tabs.TabPane
              // tab={`Supervisor ${index + 1}`}
              tab={tab?.participantUser?.username + ` (supervisor)`}
              key={String(index + 1)}
            >
              <div className="mail-leave-details">
                <div className="leave-details" style={{ margin: "0px 0px" }}>
                  <Row gutter={20}>
                    <Col lg={12} className="leave-col">
                      <div className="leave-title">Name</div>
                      <div className="leave-input">Job Understanding</div>
                      <div className="leave-input">Job Skills</div>
                      <div className="leave-input">Growth</div>
                      <div className="leave-input">Performance</div>
                      <div className="leave-input">Accountable</div>
                      <div className="leave-input">Commitment</div>
                      <div className="leave-input">Integrity</div>
                      <div className="leave-input">Collaboration</div>
                      <div className="leave-input">Attendance</div>
                      <div className="leave-input">Communication Skills</div>
                    </Col>
                    <Col lg={6} className="leave-col">
                      <div className="leave-title">Rating Number</div>
                      <div className="leave-input">
                        {tab?.jobKnowledge?.number}
                      </div>
                      <div className="leave-input">
                        {tab?.jobSkills?.number}
                      </div>
                      <div className="leave-input">
                        {tab?.growth?.number}
                      </div>
                      <div className="leave-input">
                        {tab?.performance?.number}
                      </div>
                      <div className="leave-input">
                        {tab?.accountable?.number}
                      </div>
                      <div className="leave-input">
                        {tab?.commitment?.number}
                      </div>
                      <div className="leave-input">
                        {tab?.integrity?.number}
                      </div>
                      <div className="leave-input">
                        {tab?.collaboration?.number}
                      </div>
                      <div className="leave-input">
                        {tab?.punctuality?.number}
                      </div>
                      <div className="leave-input">
                        {tab?.communication?.number}
                      </div>
                    </Col>
                    <Col lg={6} className="leave-col">
                      <div className="leave-title">Comments</div>
                      <div className="leave-input">
                        {tab?.jobKnowledge?.comments}
                      </div>
                      <div className="leave-input">
                        {tab?.jobSkills?.comments}
                      </div>
                      <div className="leave-input">
                        {tab?.growth?.comments}
                      </div>
                      <div className="leave-input">
                        {tab?.performance?.comments}
                      </div>
                      <div className="leave-input">
                        {tab?.accountable?.comments}
                      </div>
                      <div className="leave-input">
                        {tab?.commitment?.comments}
                      </div>
                      <div className="leave-input">
                        {tab?.integrity?.comments}
                      </div>
                      <div className="leave-input">
                        {tab?.collaboration?.comments}
                      </div>
                      <div className="leave-input">
                        {tab?.punctuality?.comments}
                      </div>
                      <div className="leave-input">
                        {tab?.communication?.comments}
                      </div>
                    </Col>
                    <Col lg={24} className="leave-col">
                      <div className="leave-title">Major Strength</div>
                      <div className="leave-input">
                        {tab?.majorStrength}
                      </div>
                      <div className="leave-title">Area of Improvement</div>
                      <div className="leave-input">{tab?.areasOfImprovement}</div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </>
    )
  }

  const selfEvaluationForm = () => {
    return (
      <>
        <Form
          className="drawer-form-wrapper"
          form={form}
          name="EmployeeEvaluationFormTest"
          onFinish={(values) => onSubmitEvaluation(values, 'selfEmployeeForm')}
        >
          <EmployeeEvaluationRating
            {...employeeFormProps}
          />
          <Form.Item className="employeeEvalutationBtn">
            <Button
              type="primary"
              htmlType="submit"
            // loading={employeeReviewLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    )
  }

  const selfSupervisorForm = () => {
    return (
      <>
        {participantEmployeeListing()}
        <Form
          className="drawer-form-wrapper"
          form={form}
          name="EmployeeEvaluationFormTest"
          onFinish={(values) => onSubmitEvaluation(values, 'supervisorForm')}
        >
          <EmployeeEvaluationRating
            // jobValue={jobValue}
            // onChangeJob={onChangeJob}
            // workValue={workValue}
            // onChangeWork={onChangeWork}
            // attendanceValue={attendanceValue}
            // onChangeAttendance={onChangeAttendance}
            // initiativeValue={initiativeValue}
            // onChangeInitiative={onChangeInitiative}
            // communicationValue={communicationValue}
            // onChangeCommunication={onChangeCommunication}
            // dependValue={dependValue}
            // onChangeDepend={onChangeDepend}
            employeeFormProps
          />
          <Form.Item className="employeeEvalutationBtn">
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              {/* <Button
              type=""
              onClick={() => setIsEditing(false)}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button> */}
              <Button
                type="primary"
                htmlType="submit"
                loading={employeeReviewLoading}
              >
                Review
              </Button>
            </span>
          </Form.Item>
        </Form>
      </>

    )
  }


  const mailSupervisorTab = () => {
    return (
      <>
        {
          isCurrentUserHR && (
            isCurrentUserSelfEvaluation && isCurrentUserSelfEvaluationFormSubmitted ||
            isCurrentUserSupervisor && isCurrentSvFormSubmitted ||
            !isCurrentUserSupervisor && !isCurrentUserSelfEvaluation
          ) ? (
            hrTabs()
          ) : !isCurrentUserHR && isCurrentSvFormSubmitted ? (
            listingRating()
          ) : isCurrentUserSelfEvaluation && !isCurrentUserSelfEvaluationFormSubmitted ? (
            selfEvaluationForm()
          ) : isCurrentUserSelfEvaluation && isCurrentUserSelfEvaluationFormSubmitted && !isCurrentUserHR ? (
            listingSelfEvaluation()
          ) : isCurrentUserSupervisor && !isCurrentSvFormSubmitted && (isEditing ? (
            selfSupervisorForm()
          ) : (
            <>
              {listingRating()}
              {
                showButton && (
                  <Button
                    onClick={() => setIsEditing(true)}
                    style={{
                      margin: "10px 10px",
                      color: "#FFFFFF",
                      background: "#0f6ad2",
                      borderRadius: "4px",
                    }}
                  >
                    Edit
                  </Button>
                )
              }
            </>
          )
          )
        }
      </>
    );
  };

  const employeeEvaluationTab = () => {
    return (
      <Tabs
        onChange={onChangeTab}
        type="card"
        activeKey={activeTabKey}
        style={{ margin: "10px 10px" }}
      >
        {supervisorList.map((tab, index) => (
          <Tabs.TabPane
            // tab={`Supervisor ${index + 1}`}
            tab={tab?.participantUser?.username + ` (supervisor)`}
            key={String(index + 1)}
          >
            <div className="mail-leave-details">
              <div className="leave-details" style={{ margin: "0px 0px" }}>
                <Row gutter={20}>
                  <Col lg={12} className="leave-col">
                    <div className="leave-title">Name</div>
                    <div className="leave-input">Job Understanding</div>
                    <div className="leave-input">Job Skills</div>
                    <div className="leave-input">Growth</div>
                    <div className="leave-input">Performance</div>
                    <div className="leave-input">Accountable</div>
                    <div className="leave-input">Commitment</div>
                    <div className="leave-input">Integrity</div>
                    <div className="leave-input">Collaboration</div>
                    <div className="leave-input">Attendance</div>
                    <div className="leave-input">Communication Skills</div>
                  </Col>
                  <Col lg={6} className="leave-col">
                    <div className="leave-title">Rating Number</div>
                    <div className="leave-input">
                      {tab?.jobKnowledge?.number}
                    </div>
                    <div className="leave-input">
                      {tab?.jobSkills?.number}
                    </div>
                    <div className="leave-input">
                      {tab?.growth?.number}
                    </div>
                    <div className="leave-input">
                      {tab?.performance?.number}
                    </div>
                    <div className="leave-input">
                      {tab?.accountable?.number}
                    </div>
                    <div className="leave-input">
                      {tab?.commitment?.number}
                    </div>
                    <div className="leave-input">
                      {tab?.integrity?.number}
                    </div>
                    <div className="leave-input">
                      {tab?.collaboration?.number}
                    </div>
                    <div className="leave-input">
                      {tab?.punctuality?.number}
                    </div>
                    <div className="leave-input">
                      {tab?.communication?.number}
                    </div>
                  </Col>
                  <Col lg={6} className="leave-col">
                    <div className="leave-title">Comments</div>
                    <div className="leave-input">
                      {tab?.jobKnowledge?.comments}
                    </div>
                    <div className="leave-input">
                      {tab?.jobSkills?.comments}
                    </div>
                    <div className="leave-input">
                      {tab?.growth?.comments}
                    </div>
                    <div className="leave-input">
                      {tab?.performance?.comments}
                    </div>
                    <div className="leave-input">
                      {tab?.accountable?.comments}
                    </div>
                    <div className="leave-input">
                      {tab?.commitment?.comments}
                    </div>
                    <div className="leave-input">
                      {tab?.integrity?.comments}
                    </div>
                    <div className="leave-input">
                      {tab?.collaboration?.comments}
                    </div>
                    <div className="leave-input">
                      {tab?.punctuality?.comments}
                    </div>
                    <div className="leave-input">
                      {tab?.communication?.comments}
                    </div>
                  </Col>
                  <Col lg={24} className="leave-col">
                    <div className="leave-title">Major Strength</div>
                    <div className="leave-input">
                      {tab?.majorStrength}
                    </div>
                    <div className="leave-title">Area of Improvement</div>
                    <div className="leave-input">{tab?.areasOfImprovement}</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    );
  };

  return (
    <>
      {showButton ? mailSupervisorTabSteps() : employeeEvaluationTabSteps()}

      <div className="mail-leave-details">
        <div className="leave-details">
          <Row gutter={20}>
            <Col lg={12} className="leave-col">
              <div className="leave-input">Name</div>
              <div className="leave-input">Job Title</div>
              <div className="leave-input">Department</div>
              <div className="leave-input">Username</div>
              <div className="leave-input">Date</div>
              <div className="leave-input">Supervisor</div>
              <div className="leave-input">Review Date (in days)</div>
            </Col>
            <Col lg={12} className="leave-col">
              <div className="leave-input">{employeeReviewResponse?.name}</div>
              <div className="leave-input">
                {employeeReviewResponse?.jobTitle}
              </div>
              <div className="leave-input">
                {employeeReviewResponse?.department}
              </div>
              <div className="leave-input">
                {employeeReviewResponse?.employeeInitials}
              </div>
              <div className="leave-input">
                {employeeReviewResponse?.date
                  ? moment(employeeReviewResponse?.date).format("YYYY-MM-DD")
                  : null}
              </div>
              <div className="leave-input">
                {employeeReviewResponse?.immidiateSuperior &&
                  employeeReviewResponse?.immidiateSuperior.length > 0
                  ? employeeReviewResponse?.immidiateSuperior.join(", ")
                  : null}
              </div>
              <div className="leave-input">
                {employeeReviewResponse?.reviewPeriod}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <section>
        {showButton ? mailSupervisorTab() : employeeEvaluationTab()}
      </section>
    </>
  );
};

export default EmployeeEvaluationDetails;
