import {
  Col,
  Button,
  Form,
  Input,
  Modal,
  Tooltip,
  Drawer,
  Layout,
  Row,
  Checkbox,
  Menu,
  Dropdown,
} from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cqBaseUrl } from "../../utils/config";
import { PaperClipOutlined, MinusCircleOutlined } from "@ant-design/icons";
import EmailCard from "views/Email/EmailCard";
import { useLocation } from "react-router-dom";
import storage from "utils/storage";
import {
  emailSignatureAdd,
  activeEmailSignatureDetails,
  getEmployeeEmailList,
  emailSignatureUpdate,
  emailMarkAsDefault,
  isCheckInboxValue,
} from "services/redux/profile/profile.action";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { isEmpty } from "lodash";

// ckeditor
import { Editor } from "@tinymce/tinymce-react";
import { CheckOutlined, EllipsisOutlined } from "@ant-design/icons";
import PermissionShareDrawer from "views/AdminSettings/AddUpdateEmailShare/PermissionShareDrawer";
import ProfileDrawer from "components/Drawer/ProfileDrawer";

const EmailChangePassWord = ({ employeeUserId, profileInfo }) => {
  const { TextArea } = Input;
  const [editorData, setEditorData] = useState("");
  const { Header, Footer } = Layout;

  const [emailList, setEmailList] = useState([]);
  const [emailSignature, setEmailSignature] = useState("");
  const [signatureList, setSignatureList] = useState("");
  const [updateSignatureList, setUpdateSignatureList] = useState(signatureList);
  const [emailId, setEmailId] = useState("");
  const [checked, setChecked] = useState(false);
  const [openDrawer, setOpenNewDrawer] = useState(false);
  const [userId, setuserId] = useState("");

  const dispatch = useDispatch();
  const text = <span>disable</span>;
  let participantFrom = storage.getItem("userId");
  const location = useLocation();

  const {
    activeEmailSignatureDetailsResponse,
    activeEmailSignatureDetailsLoading,
    emailSignatureAddResponse,
    emailSignatureAddLoading,
    emailSignatureUpdateResponse,
    emailSignatureUpdateLoading,
    employeeEmailListResponse,
    employeeEmailListLoading,
    markEmailAsDefaultLoading,
    markEmailAsDefaultResponse,
    markIsCheckInboxUpdateResponse,
    markIsCheckInboxUpdateLoading,
  } = useSelector((state) => state.profile);

  const createMenu = (email) => (
    <Menu>
      <Menu.Item key="share-access">
        <Button
          type="link"
          onClick={() => {
            setOpenNewDrawer(true);
            console.log("email ko id ya", email?.id);
            setuserId(email?.id);
          }}
        >
          Share Access
        </Button>
      </Menu.Item>
      <Menu.Item key="lock-toggle">
        <Button
          type="link"
          onClick={() =>
            handleIsCheckbox(email.id, !email.isLockedForCheckingInbox)
          }
          disabled={
            email.isLockedForCheckingInbox === false ||
            markIsCheckInboxUpdateLoading
          }
        >
          {email.isLockedForCheckingInbox === true ? "Unlock" : "Lock"}
        </Button>
      </Menu.Item>
      <Menu.Item key="set-as-default">
        <Button
          type="link"
          onClick={() => dispatch(emailMarkAsDefault(email.id))}
          disabled={email.isPrimary || markEmailAsDefaultLoading}
          loading={markEmailAsDefaultLoading}
        >
          {email.isPrimary === true ? "Disabled" : "Active"}
        </Button>
      </Menu.Item>
      <Menu.Item key="change-password" disabled>
        <Button type="link" disabled>
          Change Password
        </Button>
      </Menu.Item>
      <Menu.Item key="update-signature">
        <Button type="link" onClick={() => showModal(email.id)}>
          Update Signature
        </Button>
      </Menu.Item>
    </Menu>
  );

  //for update of mark as default
  useEffect(() => {
    if (
      employeeUserId &&
      location.pathname === "/admin/user-list" &&
      markEmailAsDefaultResponse === true
    ) {
      dispatch(getEmployeeEmailList({ appUserId: employeeUserId }));
    } else if (
      location.pathname !== "/admin/user-list" &&
      markEmailAsDefaultResponse === true
    ) {
      dispatch(getEmployeeEmailList({ appUserId: participantFrom }));
    }
  }, [employeeUserId, markEmailAsDefaultResponse]);

  // for update isCheckbox mark As default
  useEffect(() => {
    if (markIsCheckInboxUpdateResponse?.item2 == false) {
      dispatch(getEmployeeEmailList({ appUserId: employeeUserId }));
    } else if (markIsCheckInboxUpdateResponse?.item2 == true) {
      dispatch(getEmployeeEmailList({ appUserId: participantFrom }));
    }
  }, [markIsCheckInboxUpdateResponse]);
  //for HR section
  useEffect(() => {
    if (employeeUserId) {
      dispatch(getEmployeeEmailList({ appUserId: employeeUserId }));
    }
  }, [employeeUserId]);
  useEffect(() => {
    if (
      !isEmpty(markIsCheckInboxUpdateResponse) ||
      !isEmpty(markIsCheckInboxUpdateLoading)
    ) {
      setTimeout(() => {
        setChecked(false);
      }, 500);
    }
  }, [markIsCheckInboxUpdateResponse, markIsCheckInboxUpdateLoading]);

  //for reload
  useEffect(() => {
    if (location.pathname === "/profile")
      dispatch(getEmployeeEmailList({ appUserId: participantFrom }));
  }, []);

  useEffect(() => {
    if (employeeUserId && location.pathname === "/admin/user-list" && emailId) {
      dispatch(
        activeEmailSignatureDetails({
          UserId: employeeUserId,
          emailId: emailId,
        })
      );
    } else if (emailId) {
      dispatch(
        activeEmailSignatureDetails({
          UserId: participantFrom,
          emailId: emailId,
        })
      );
    }
  }, [employeeUserId, emailId]);

  useEffect(() => {
    if (employeeEmailListResponse) {
      setEmailList(employeeEmailListResponse);
    }
  }, [employeeEmailListResponse]);

  useEffect(() => {
    if (!isEmpty(activeEmailSignatureDetailsResponse)) {
      let data = `${activeEmailSignatureDetailsResponse?.body}`;
      setTimeout(() => {
        setEditorData(data);
      });
      setSignatureList(activeEmailSignatureDetailsResponse);
    }
  }, [activeEmailSignatureDetailsResponse]);

  useEffect(() => {
    if (emailSignatureUpdateResponse) {
      setUpdateSignatureList(emailSignatureUpdateResponse);
    }
  }, [emailSignatureUpdateResponse]);
  //console.log('test',emailSignatureUpdateResponse);
  const { id, body, name } = signatureList;
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(name);
  }, [signatureList]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = (id) => {
    setEmailId(id);
    setTimeout(() => {
      setIsModalVisible(true);
    }, 1200);
  };

  useEffect(() => {
    if (!isEmpty(signatureList)) {
      // setIsModalVisible(true);
    }
  }, [signatureList]);

  const handleIsCheckbox = (id, ischeckBox) => {
    dispatch(
      //isCheckInboxValue(id,isCheck)
      isCheckInboxValue(id, ischeckBox)
    );
  };
  const handleOk = () => {
    // console.log('activeEmailSignatureDetailsResponse',activeEmailSignatureDetailsResponse,editorData)
    activeEmailSignatureDetailsResponse?.body !== null
      ? dispatch(
          emailSignatureUpdate(id, {
            id: id,
            body: editorData,
            name: title,
          })
        )
      : dispatch(
          emailSignatureAdd({
            body: editorData,
            name: title,
            userId: participantFrom,
            emailId: emailId,
            email: emailList[0].emailAddress,
            isActive: true,
          })
        );
    setEmailId("");
  };
  // useEffect(())
  useEffect(() => {
    if (
      !isEmpty(emailSignatureUpdateResponse) ||
      !isEmpty(emailSignatureAddResponse)
    ) {
      setTimeout(() => {
        setIsModalVisible(false);
        setSignatureList("");
        setEditorData("");
      }, 1000);
    }
  }, [emailSignatureUpdateResponse, emailSignatureAddResponse]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditorData("");
    setSignatureList("");
    setEmailId("");
  };

  return (
    <div className="profiledetails">
      {/* {console.log(emailList,"tester")} */}
      {emailList.map((email) => {
        // console.log("list of email", email);
        return (
          <div className="profiledetails__wrapper">
            <Form className="hr-email-info-wrapper">
              <div
                className="profiledetails__wrapper--conatainer"
                style={{ height: 70, overflow: "unset" }}
              >
                <Row gutter={[12, 12]}>
                  <Col span={8}>
                    <div className="profiledetails__rowedit--label">
                      <span
                        className="profile__bodyTitle"
                        style={{ fontSize: 16, marginTop: 5 }}
                      >
                        {email.emailAddress}
                      </span>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div
                      className="profile__info--footer"
                      style={{ display: "flex", marginLeft: 100 }}
                    >
                      {/* <Button
                        className="btn-update"
                        style={{ width: 130 }}
                        // loading={markEmailAsDefaultLoading}
                        onClick={() => {
                          handleIsCheckbox(email.id);
                        }}
                        // onClick={() => {
                        //   dispatch(isCheckInboxValue(email.id,true));
                        // }}
                       
                      >
                         Active
                      </Button> */}
                      {/* <Form.Item
                        name="isCheckInbox"
                        valuePropName="checked"
                        className="form__group"
                        // checked={checked}
                        onClick={(e) => {
                          //console.log(e.target.checked)
                            handleIsCheckbox(email.id,e.target.checked);
                        }}
                        checked={checked} 
                        onChange={() => setChecked(!checked)}
                      >
                    <Checkbox> 
                      {email.isLockedForCheckingInbox === true ? "Locked" : "Inlocked"}
                    </Checkbox>
                    </Form.Item> */}

                      <Dropdown
                        overlay={createMenu(email)}
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <EllipsisOutlined className="profile-ellipsis-more" />
                      </Dropdown>

                      {/* <Button
                        className="btn-update"
                        style={{ marginRight: "10px", width: "150px" }}
                        onClick={() => {
                          setOpenNewDrawer(true);
                          console.log("email ko id ya", email?.id);
                          setuserId(email?.id);
                        }}
                      >
                        Share Access
                      </Button>
                      <div className="islocked-change-space">
                        <Button
                          className="btn-update"
                          style={{ width: 130 }}
                          disabled={
                            email.isLockedForCheckingInbox == false ||
                            markIsCheckInboxUpdateLoading
                          }
                          // loading={markEmailAsDefaultLoading}
                          onClick={(e) => {
                            handleIsCheckbox(
                              email.id,
                              !email.isLockedForCheckingInbox ? true : false
                            );
                          }}
                        >
                          {email.isLockedForCheckingInbox === true
                            ? "Unlocked"
                            : "Locked"}
                        </Button>
                      </div>
                      <Button
                        className="btn-update"
                        style={{ width: 130 }}
                        disabled={email.isPrimary || markEmailAsDefaultLoading}
                        loading={markEmailAsDefaultLoading}
                        onClick={() => {
                          dispatch(emailMarkAsDefault(email.id));
                        }}
                      >
                        {email.isPrimary === true ? "Disabled" : "Active"}
                      </Button>

                      <Button
                        className="btn-update"
                        style={{ width: 130, marginLeft: 10 }}
                        disabled
                      >
                        Change Password
                      </Button>

                      <Button
                        className="btn-update"
                        type="link"
                        //disabled={emailId}
                        // loading={activeEmailSignatureDetailsLoading}
                        style={{ width: 130, marginLeft: 10 }}
                        onClick={() => showModal(email.id)}
                      >
                        Update Signature
                      </Button> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        );
      })}
      <Drawer
        visible={isModalVisible}
        onClose={handleCancel}
        placement="right"
        width={500}
        maskClosable={false}
        className="drawer"
        closable={false}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">Email Signature</h4>
        </Header>
        <div className="drawer-form-wrapper">
          <div className="drawer-form-row">
            <div className="drawer-form-group">
              <Input
                placeholder="Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                autoComplete="off"
              />
              <input
                id="my-file"
                type="file"
                name="my-file"
                style={{ display: "none" }}
              />
            </div>
            <div className="drawer-form-group">
              <Editor
                // value={editorData ? editorData : ""}
                value={editorData != "null" ? editorData : ""}
                init={{
                  browser_spellcheck: true,
                  height: "762",
                  plugins: ["lists advlist", "image", "paste"],
                  menubar: "false",
                  file_browser_callback_types: "image",
                  file_picker_callback: function (callback, value, meta) {
                    if (meta.filetype == "image") {
                      var input = document.getElementById("my-file");
                      input.click();
                      input.onchange = function () {
                        var file = input.files[0];
                        var reader = new FileReader();
                        reader.onload = function (e) {
                          // console.log("name", e.target.result);
                          callback(e.target.result, {
                            alt: file.name,
                          });
                        };
                        reader.readAsDataURL(file);
                      };
                    }
                  },
                  paste_data_images: true,
                  forced_root_block: "div",
                  auto_focus: true,
                  toolbar:
                    "fontselect fontsizeselect backcolor forecolor bold | italic | underLine |  strikethrough alignleft aligncenter alignright |  bullist numlist | paste image code",
                  content_style: "p{margin:0px}",
                }}
                onEditorChange={(e) => setEditorData(e)}
              />
            </div>
          </div>
          <div className="model-footer">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                handleOk();
              }}
              disabled={emailSignatureUpdateLoading || emailSignatureAddLoading}
              loading={emailSignatureUpdateLoading || emailSignatureAddLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      </Drawer>

      <ProfileDrawer
        openDrawer={openDrawer}
        setOpenNewDrawer={setOpenNewDrawer}
        profileInfo={profileInfo}
        userId={userId}
      />
    </div>
  );
};

export default EmailChangePassWord;
