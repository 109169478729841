import { error, finish, init, success } from "services/common";
import { HOME_TYPES } from '../../types';
import { HomeServices } from "./api";

const homeServices = new HomeServices()

export const englishToNepaliDataConverter = (englishDate)=>{
    return async(dispatch) =>{
        dispatch(init(HOME_TYPES.ENGLISH_TO_NEPAL_CONVERTER));
        const response = await homeServices.englishToNepaliConverter(englishDate);
        if(response?.isSuccess){
            dispatch(success(HOME_TYPES.ENGLISH_TO_NEPAL_CONVERTER,response?.data))
        }else{
            dispatch(error(response?.errorMessage))
        }
        dispatch(finish(HOME_TYPES.ENGLISH_TO_NEPAL_CONVERTER))
    }
}
export const attendenceLateReasonAdd = (body)=>{
    return async(dispatch)=>{
        dispatch(init(HOME_TYPES.ATTENDENCE_LATE_REASON_ADD));
        const response = await homeServices.attendenceLateReasonAdd(body);
        if(response?.isSuccess){
            dispatch(success(HOME_TYPES.ATTENDENCE_LATE_REASON_ADD,response?.data))
        }
        else{
            dispatch(error(response?.errorMessage))
        }
        dispatch(finish(HOME_TYPES.ATTENDENCE_LATE_REASON_ADD))
    }
}