import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import WelcomeView from "./views/Welcome";
import HomeView from "./views/Home";
import Organizer from "./views/Oragnizer";
import Todo from "./views/Oragnizer/todoList";
import Email from "./views/Email";
import Profile from "./views/Profile";
import Admin from "./views/Admin";
import Test from "./views/Test";
import Contact from "./views/Contact";
import Document from "./views/Document";
import { AuthRoute } from "./App";
// import Meeting from "./views/Oragnizer/meeting/index";
import ChangePassword from "./views/ChangePassword";
import OfficeResource from "./views/OfficeResource";
import Requisition from "./views/Requisition";
import AdminSettings from "./views/AdminSettings";
import Navbar from "./components/Navbar";
import EmailDetails from "./views/Email/EmailPopups/EmailDetails";
import EmailForwardSend from "./views/Email/EmailPopups/EmailForwardSend";
import AddCompanyDrawer from "./views/Contact/ContactCompanyPerson/AddUpdateCompanyDrawer";
import ComposeEmailDrawer from "./views/Email/Includes/ComposeEmailDrawer";
import QuickMessageDrawer from "./views/Email/Includes/QuickMessageDrawer";
import AddPersonDrawer from "./views/Contact/ContactCompanyPerson/AddUpdatePersonDrawer";
import ToDoForm from "./views/Oragnizer/todoList/ToDoForm";
import AddExtensions from "./views/OfficeResource/Reference/Tabs/TenderTabs/AddExtension";
import ViewFiles from "./components/shared/ViewFiles";
import DownloadConfirmModal from "./views/DownloadConfirmModal";
import UpdateProgressWindow from "./views/DownloadProgressModal.";
import AddUserDrawer from "views/Admin/UserList/AddUserDrawer";
import ComplaintView from "views/Home/Modal/ComplainView";
import DraggableHome from "views/Home/DraggableHome";
import ComplainHomes from "views/Complain/Index";
import PrivateTask from "views/Oragnizer/privateTask";
import System from "views/AdminSettings/System";
import HRSystem from "views/HRSystem";
import NotFound from "components/notFound";

import LateAttendence from "views/Home/LateAttendence";

import USHPCFinancialInfoUpdate from "components/limitedRole/ushpc";
import ICCLFinancialInfoUpdate from "components/limitedRole/iccl";
import ISPLFinancialInfoUpdate from "components/limitedRole/ispl";
import useRoles from "hooks/useRoles";
import Expert from "views/Expert";
import CEAD from "views/CEAD";
import GroupChatIndex from "views/GroupChat/GroupChat";

const Routes = ({ token }) => {
  const location = useLocation();
  const { LimitedRoleDemo, isLimitedRoleMailBox, isLimitedRoleForICCL } =
    useRoles();

  const { profileInfo } = useSelector((state) => state.profile);

  const navbarRef = useRef(null);
  const [height, setHeight] = useState(true);
  const [test, setTest] = useState("");
  let body = document.querySelector("body");
  if (location.pathname === "/home" || location.pathname === "/email-compose") {
    body.style.position = "fixed";
    body.style.top = 0;
    body.style.left = 0;
    body.style.overflow = "hidden";
  } else {
    body.removeAttribute("style");
  }

  const renderSwitch = () => {
    return (
      <Switch>
        <Route path="/" exact>
          <WelcomeView />
        </Route>

        <AuthRoute path="/dashBoard/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <DraggableHome navbarRef={navbarRef} />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/demoRoute" exact>
          {LimitedRoleDemo || isLimitedRoleForICCL ? (
            <>
              {LimitedRoleDemo && (
                <>
                  <USHPCFinancialInfoUpdate />
                </>
              )}
              {isLimitedRoleForICCL && <ICCLFinancialInfoUpdate />}
              {/* {isLimitedRoleForICCL && <ISPLFinancialInfoUpdate />} */}
              {/* {LimitedRoleDemo && <USHPCFinancialInfoUpdate />}
              {isLimitedRoleForICCL && <ICCLFinancialInfoUpdate />} */}
            </>
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <Route path="/downloadProgressWindow" exact>
          <UpdateProgressWindow test={test} />
        </Route>

        <AuthRoute path="/admin/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? <Admin /> : <NotFound />}
        </AuthRoute>

        <AuthRoute path="/settings/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <AdminSettings />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/organizer/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <Organizer />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        {/* <AuthRoute path="/todoLists" exact>
          <Todo isPrivate={false} />
        </AuthRoute> */}

        <AuthRoute path="/todoLists/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <Todo isPrivate={false} />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute
          path="/office-resource/:slug?"
          onClick={() => {
            setHeight(false);
          }}
          exact
        >
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <OfficeResource height={height} setHeight={setHeight} />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/contact/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <Contact />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/expert/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <Expert />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        {/* <AuthRoute path="/email" exact>
					<Email />
				</AuthRoute> */}
        <AuthRoute path="/email-compose" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <ComposeEmailDrawer />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/email-quick-compose" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <QuickMessageDrawer />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/emailDetails" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <EmailDetails />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/emailForwardSend" exact>
          {/* <EmailForwardSend /> */}
        </AuthRoute>

        <AuthRoute path="/addUpdatecompanyDrawer" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <AddCompanyDrawer />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/addUpateEmployee" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <AddUserDrawer />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/addUpdatePersonDrawer" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <AddPersonDrawer />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/home" exact>
          {isLimitedRoleMailBox || !LimitedRoleDemo ? <Email /> : <NotFound />}
        </AuthRoute>

        <AuthRoute path="/profile" exact>
          {!LimitedRoleDemo ? (
            <Profile
              employeeId={profileInfo?.userRegistrationAddUpdateDto?.employeeId}
            />
          ) : (
            <USHPCFinancialInfoUpdate />
          )}
        </AuthRoute>

        <AuthRoute path="/profile/change-password" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <ChangePassword />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/requisition/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <Requisition />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/test" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? <Test /> : <NotFound />}
        </AuthRoute>

        <AuthRoute path="/todoForm" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <ToDoForm />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/addextensions" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <AddExtensions />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/viewFile" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <ViewFiles />
          ) : (
            <NotFound />
          )}
        </AuthRoute>
        {/* <AuthRoute path="/meeting" exact>
          <Meeting />
        </AuthRoute> */}
        <AuthRoute path="/document/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <Document />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        {/* <AuthRoute path="/dashBoard/:slug?" exact>
          <DraggableHome navbarRef={navbarRef} />
        </AuthRoute> */}

        <AuthRoute path="/complaint/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <ComplainHomes />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/privateTask/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <Todo isPrivate={true} />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/system/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <System />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/hr-sys/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <HRSystem />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/lateAttendence/:slug?" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? (
            <LateAttendence />
          ) : (
            <NotFound />
          )}
        </AuthRoute>

        <AuthRoute path="/cead" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? <CEAD /> : <NotFound />}
        </AuthRoute>

        <AuthRoute path="/groupchat" exact>
          {!LimitedRoleDemo && !isLimitedRoleMailBox ? 
            <GroupChatIndex /> : <NotFound />
          }
        </AuthRoute>

        <AuthRoute path="*" exact>
          <NotFound />
        </AuthRoute>
      </Switch>
    );
  };

  return (
    <>
      {token &&
      location.pathname !== "/emailDetails" &&
      location.pathname !== "/email-compose" &&
      location.pathname !== "/email-quick-compose" &&
      location.pathname !== "/addUpdatecompanyDrawer" &&
      location.pathname !== "/todoForm" &&
      location.pathname !== "/addextensions" &&
      location.pathname !== "/addUpdatePersonDrawer" &&
      location.pathname !== "/updateModal" &&
      location.pathname !== "/email-quick-compose" &&
      location.pathname !== "/emailForwardSend" &&
      location.pathname !== "/addUpateEmployee" &&
      location.pathname !== "/" &&
      location.pathname !== "/downloadProgressWindow" &&
      location.pathname !== "/viewFile" ? (
        <div className="dashboard">
          {/* <Modal visible={true}>
						<UpdateProgressWindow />
					</Modal>
					; */}
          <Navbar setHeight={setHeight} ref={navbarRef} />
          <div className="container">{renderSwitch()}</div>
        </div>
      ) : (
        renderSwitch()
      )}
    </>
  );
};

export default Routes;

// {!LimitedRoleDemo ? (
//   <AuthRoute path="/dashBoard/:slug?" exact>
//     <DraggableHome navbarRef={navbarRef} />
//   </AuthRoute>
// ) : (
//   <AuthRoute path="/demoRoute" exact>
//     <p style={{ color: "black" }}>HELLO LIMITED ROLE COMPONENT</p>
//   </AuthRoute>
// )}
