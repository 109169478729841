import { ORGANIZER_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { AppointmentsService } from "./api";
import { message } from "antd";

const appointmentsService = new AppointmentsService();

export const getAppointmentEventsPagination = (query) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.APPOINTMENTS));
    const response = await appointmentsService.getAppointmentEventsPagination(
      query
    );
    dispatch(finish(ORGANIZER_TYPES.APPOINTMENTS));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.APPOINTMENTS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addAppointmentEvent = (body) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.ADD_APPOINTMENT));
    const response = await appointmentsService.addAppointmentEvent(body);
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.ADD_APPOINTMENT, response.data));
      dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
      message.success("Appointment Event added successfully");
      dispatch(finish(ORGANIZER_TYPES.ADD_APPOINTMENT));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateAppointmentEvent = (body, id) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.ADD_APPOINTMENT));
    const response = await appointmentsService.updateAppointmentEvent(body, id);
    dispatch(finish(ORGANIZER_TYPES.ADD_APPOINTMENT));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.ADD_APPOINTMENT, response.data));
      dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
      message.success("Appointment Event updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const appointmentMarkAsComplete = (id) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.APPOINTMENT_COMPLETE));
    const response = await appointmentsService.appointmentMarkAsComplete(id);
    dispatch(finish(ORGANIZER_TYPES.APPOINTMENT_COMPLETE));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.APPOINTMENT_COMPLETE, response.data));
      dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
      let status = response.data.markItOff ? "complete" : "incomplete";
      message.success(`Appointment marked as ${status} successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
