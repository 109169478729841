export const twoDecimalFormatter = ({ number, isInteger }) => {
  if (typeof number === "number" && !isNaN(number)) {
    const formattedValue = number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return isInteger ? number.toLocaleString("en-US") : formattedValue;
  }

  // You can return a default value or an empty string if you prefer
  return null;
};

// export default twoDecimalFormatter;
