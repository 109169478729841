import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService } from "../../../../commonServices";

export class LeaveApplicationTypeService {

  getLeaveApplicationTypePagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationTypePagination?${query}`;
    let data = getService(url);
    return data;
  }

  leaveApplicationTypeAdd(body) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationTypeAdd`;
    let data = postService(url, body, 'post');
    return data;
  }

  leaveApplicationTypeUpdate(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationTypeUpdate/${id}`;
    let data = postService(url, body, 'put');
    return data;
  }
}
