import { appBaseUrl } from "utils/config";
import { getService, postService } from "services/commonServices";
import queryString from "query-string";

export class InstanceMessageChatServices {
  instanceChatAdd(body) {
    let url = `${appBaseUrl}/mailMessageCenter/instanceChatAdd`;
    let data = postService(url, body, "post");
    return data;
  }

  getInstanceChatCenterPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/mailMessageCenter/instanceChatPagination?${query}`;
    let data = getService(url);
    return data;
  }
}
