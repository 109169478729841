import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { Button, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { map } from "lodash";
import { Card, Dropdown,Button,message,Menu } from "antd";
import {ClockCircleOutlined,DashOutlined,EditOutlined,DeleteOutlined } from '@ant-design/icons';
import moment from "moment";
import { cqBaseUrl } from "utils/config";
import { useDispatch, useSelector } from "react-redux";
import { kanbanToDO } from "services/redux/organizer/todoLists/action";

const CardTaskBox = props => {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);

    const setHideInternalMessage = props?.setHideInternalMessage;
    const menu = (
        <Menu>
          <Menu.Item 
          onClick={()=>
            {
                dispatch(kanbanToDO(props )) ;
                setHideInternalMessage(true);
            }}><EditOutlined />Edit</Menu.Item>
          <Menu.Item><DeleteOutlined />Archieve</Menu.Item>
        </Menu>
      );
    const { data } = props;

    useEffect(()=>{
        dispatch(kanbanToDO(props))
    },[props?.dragging || !!props?.destinationData])
    return (
        <React.Fragment>
            <Card title={data?.subject}  style={{ width: 300 }} extra={<Dropdown overlay={menu} className="open_menu_card_title"><DashOutlined /></Dropdown>}>
                <div className="card-body">
                    <div className="card-text">
                        <p>{data?.notes}</p>
                    </div>
                    <div className="tag_and_assigner_display">
                        <div className="tag_display">
                            {<span className="badge badge-soft-primary me-1">{data.referenceCode}</span>}
                        </div>
                        <div className="assigner_display">
                            {map(data.todoEventCandidateDtos, (data, key) => (<Link to="#" className="avatar-group-item" key={key}>
                            <img src={cqBaseUrl + "/" + data?.profilePicture + token} alt="" className="rounded-circle avatar-xxs" />
                            </Link>))}
                        </div>
                    </div>
                </div>
                <div className="card_footer">
                    <div className="footer_combined">
                        <div className="footer_data_1">
                            <span className="text-muted"><ClockCircleOutlined /> {moment(data?.dueDate).format("YYYY-MM-DD")} </span>
                        </div>
                        <div className="footer_data_2">
                            Priority:
                            <span 
                                style={
                                        {
                                            background:data?.organizerEventPriorityDto?.colorHexValue,
                                            color:data?.organizerEventPriorityDto?.colorFontHexValue,
                                            borderRadius:"4px",
                                            padding:"1.2px 5px"
                                            }
                                        }>
                                    {data?.organizerEventPriorityDto?.name}
                            </span>
                        </div>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default CardTaskBox;
