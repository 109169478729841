import { ORGANIZER_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { GoalEventsService } from "./api";
import { message } from "antd";

const goalEventsService = new GoalEventsService();

export const getGoalEventsPagination = (query, forPagination) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.GOAL_EVENTS));
    const response = await goalEventsService.getGoalEventsPagination(query);
    dispatch(finish(ORGANIZER_TYPES.GOAL_EVENTS));
    if (response.isSuccess) {
      if (forPagination) {
        dispatch(
          success(ORGANIZER_TYPES.GOAL_EVENTS_PAGINATION, response.data)
        );
      } else {
        dispatch(success(ORGANIZER_TYPES.GOAL_EVENTS, response.data));
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addGoalEvent = (body) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.GOAL_EVENT_ADD));
    const response = await goalEventsService.addGoalEvent(body);
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.GOAL_EVENT_ADD, response.data));
      message.success(" Goal added successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(ORGANIZER_TYPES.GOAL_EVENT_ADD));
  };
};

// organizer task event
export const organizerTaskEventAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_ADD));
    const response = await goalEventsService.organizerTaskEventAdd(body);

    if (response.isSuccess) {
      dispatch(
        success(ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_ADD, response.data)
      );
      // dispatch(getGoalEventsPagination(`maxResultCount=10&skipCount=0`));
      message.success(" Event added successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_ADD));
  };
};
//  Organization task event getdetails
export const organizationTaskEventGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_GET_DETAILS));
    const response = await goalEventsService.organizerTaskEventGetDetails(id);
    dispatch(finish(ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_GET_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_GET_DETAILS, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// organizer task event update
export const organizerTaskEventUpdate = (body, id) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_UPDATE));
    const response = await goalEventsService.organizerTaskEventUpdate(body, id);
    if (response.isSuccess) {
      dispatch(
        success(ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_UPDATE, response.data)
      );
      // dispatch(getGoalEventsPagination(`maxResultCount=10&skipCount=0`));
      message.success("Event updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_UPDATE));
  };
};

// export const updateGoalEvent = (body, id) => {
//   return async (dispatch) => {
//     dispatch(init(ORGANIZER_TYPES.ADD_GOAL_EVENTS));
//     const response = await goalEventsService.updateGoalEvent(body, id);
//     dispatch(finish(ORGANIZER_TYPES.ADD_GOAL_EVENTS));
//     if (response.isSuccess) {
//       dispatch(success(ORGANIZER_TYPES.ADD_GOAL_EVENTS, response.data));
//       dispatch(getGoalEventsPagination(`maxResultCount=10&skipCount=0`));
//       message.success('Goal Event updated successfully');
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

export const getTasksDetails = (query) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.GET_TODAY_TASKS));
    const response = await goalEventsService.getTodayTasks(query);
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.GET_TODAY_TASKS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
