import { getService, postService } from "services/commonServices";
import { appBaseUrl } from "utils/config";

export class CircularMailService {
  getSingleTenderCircularMail(id, refCode) {
    let url = `${appBaseUrl}/referenceManagement/referencePartyListingCircularbyApplication/${id}?referenceCode=${refCode}`;
    let data = getService(url);
    return data;
  }
}
