import { Modal, Skeleton, Table, Typography } from "antd";
import React from "react";
import { renderRightText } from "utils/rightAlignCellData";
import { showNumber } from "utils/showNumber";

const FinanceReportModal = ({
  modalShow,
  setModalShow,
  feeCategoryListResponse,
  feeCategoryListLoading,
  typeFee,
}) => {
  // const columns = [
  //   {
  //     title: "SN",
  //     key: "sno",
  //     render: (text, object, index) => index + 1,
  //     width: "7%",
  //   },
  //   {
  //     title: "Title",
  //     dataIndex: "accountHead",
  //     key: "accountHead",
  //   },
  //   {
  //     title: (
  //       <Typography.Paragraph style={{ textAlign: "right" }}>
  //         Amount in NPR
  //       </Typography.Paragraph>
  //     ),
  //     dataIndex: "amount",
  //     key: "amount",
  //     render: (_, record) => {
  //       return (
  //         <Typography.Paragraph style={{ textAlign: "right" }}>
  //           {showNumber(record?.amount)}
  //         </Typography.Paragraph>
  //       );
  //     },
  //   },
  //   {
  //     title: (
  //       <Typography.Paragraph style={{ textAlign: "right" }}>
  //         Amount in USD
  //       </Typography.Paragraph>
  //     ),
  //     dataIndex: "amountTwo",
  //     key: "amountTwo",
  //     render: (_, record) => {
  //       return (
  //         <Typography.Paragraph style={{ textAlign: "right" }}>
  //           {showNumber(record?.amountTwo)}
  //         </Typography.Paragraph>
  //       );
  //     },
  //   },
  //   {
  //     title: (
  //       <Typography.Paragraph style={{ textAlign: "right" }}>
  //         Total Amount in NPR
  //       </Typography.Paragraph>
  //     ),
  //     dataIndex: "amountTwo",
  //     key: "amountTwo",
  //     render: (_, record) => {
  //       return (
  //         <Typography.Paragraph style={{ textAlign: "right" }}>
  //           {showNumber(
  //             record?.amount + record?.conversionRate * record?.amountTwo
  //           )}
  //         </Typography.Paragraph>
  //       );
  //     },
  //   },
  // ];

  const columns = [
    {
      title: "SN",
      key: "sno",
      render: (text, object, index) =>
        object.tenderName !== "Total" && index + 1,
      width: 2,
    },
    {
      title: "Ref.",
      dataIndex: "referenceCode",
      width: 100,
    },
    {
      title: "Tender Name",
      dataIndex: "tenderName",
    },

    typeFee !== "Fee Remaining"
      ? {
          title: renderRightText("Fee Due"),
          dataIndex: "feeDue",
          render: (text, record) => {
            // return renderRightText(text);
            return renderRightText(showNumber(text, record?.sn));
          },
        }
      : {
          title: renderRightText("Fee Remaining"),
          dataIndex: "feeRemaining",
          render: (text, record) => {
            // return renderRightText(text);
            return renderRightText(showNumber(text, record?.sn));
          },
        },
  ];
  const filteredDataSource = feeCategoryListResponse?.items;

  // const amountNpr = filteredDataSource?.reduce(
  //   (acc, curr) => acc + (curr?.amount || 0),
  //   0
  // );

  // const amountUsd = filteredDataSource?.reduce(
  //   (acc, curr) => acc + (curr?.amountTwo || 0),
  //   0
  // );

  // const conversionRate = filteredDataSource?.[0]?.conversionRate;

  const totalRow = {
    sno: "",
    tenderName: "Total",
    feeDue: filteredDataSource?.reduce((sum, item) => sum + item.feeDue, 0),
    feeRemaining: filteredDataSource?.reduce(
      (sum, item) => sum + item.feeRemaining,
      0
    ),
  };

  const dataSource = [...filteredDataSource, totalRow];

  return (
    <Modal
      title={`Summary of ${typeFee}`}
      width={600}
      visible={modalShow}
      onCancel={() => {
        setModalShow(false);
      }}
      footer={false}
    >
      {feeCategoryListLoading ? (
        <Skeleton active />
      ) : (
        <Table
          className="next-organizer-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          // loading={feeCategoryListLoading}
        />
      )}
    </Modal>
  );
};

export default FinanceReportModal;
