import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGuestEntertainment } from "services/redux/organizer/guestEvents/action";
import getColumnsGuest from "./Columns";

const ApprovalTable = () => {
  const dispatch = useDispatch();
  const {
    allGuestEventsApprovalListingResponse,
    allGuestEventsApprovalListingResponseLoading,
  } = useSelector((state) => state.guestEvents);

  useEffect(() => {
    const appendUrl = "&IsPendingRequisition=true";
    dispatch(getAllGuestEntertainment(appendUrl, "approval"));
  }, []);

  const columns = getColumnsGuest().filter(
    (column) => column.key !== "currentGuestEntertainmentStatus"
  );

  return (
    <>
      <Table
        className="next-organizer-table"
        columns={columns}
        dataSource={allGuestEventsApprovalListingResponse?.items}
      />
    </>
  );
};

export default ApprovalTable;
