import ActionButton from "antd/lib/modal/ActionButton";
import { uniqBy } from "lodash";
import { actions } from "react-table";
import { COMPANY_PEOPLE_TYPES } from "../../../types";

const INITIAL_STATE = {
  contactCompanyPersonSearchPaginationLoading: false,
  contactCompanyPersonSearchPaginationResponse: null,

  contactCompanyIsFavResponse: null,
  contactCompanyIsFavResponseLoading: false,

  contactPersonIsFavResponse: null,
  contactPersonIsFavResponseLoading: false,
  contactCompanyPaginationResponse: null,

  contactCompanyPaginationLoading: false,

  contactPersonPaginationresponse: null,
  contactPersonPaginatinationRonLoading: false,

  //for search Person Listting
  contactPersonSearchListingResponse: null,
  contactPersonSearchListingLoading: false,

  contactCompanyDetailAddLoading: false,
  contactCompanyDetailAddResponse: null,
  contactCompanyUpdateLoading: false,
  contactCompanyUpdateResponse: null,
  contactPersonUpdateLoading: false,
  contactPersonUpdateResponse: null,
  contactCompanyGetDetailsLoading: false,
  contactCompanyGetDetailsResponse: null,
  contactPersonGetDetailsLoading: false,
  contactPersonGetDetailsResponse: null,
  contactPersonAddLoading: false,
  contactPersonAddResponse: null,

  contactCompanyNoteRespone: null,
  contactCompanyNoteLoading: false,

  contactPersonNoteRespone: null,
  contactPersonNoteLoading: false,

  allPersonListingResponse: [],
  allPersonListingLoading: false,

  //For quick Email Add
  contactQuickAddEmailRespsonse: null,
  contactQuickAddEmailLoading: false,

  contactCompanyDelete: null,
  contactDeleteCompanyLoading: false,

  contactPersonDelete: null,
  contactPesonDeleteLoader: false,

  //response from another tab
  updatedContactResponse: null,

  quickContactCompanyAdd: null,
  quickContactCompanyLoader: false,

  // company Inactive statys
  companyActiveStatusLoading: false,
  companyActiveStatusResponse: [],

  // person
  personActiveStatusLoading: false,
  personActiveStatusResponse: [],

  // quickperson add
  quickPersonAddLoading: false,
  quickPersonAddResponse: [],
};

export default function contactReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // all person listing
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_LISTING_INIT:
      return {
        ...state,
        allPersonListingResponse: [],
        allPersonListingLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_LISTING_SUCCESS:
      return {
        ...state,
        allPersonListingResponse: action.payload,
        allPersonListingLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_LISTING_FINISH:
      return {
        ...state,
        allPersonListingLoading: false,
      };

    case COMPANY_PEOPLE_TYPES.contactCompanyPersonSearch_INIT:
      return {
        ...state,
        // contactCompanyPersonSearchPaginationResponse: null,
        contactCompanyPersonSearchPaginationLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyPersonSearch_SUCCESS:
      return {
        ...state,
        contactCompanyPersonSearchPaginationResponse: action.payload,
        contactCompanyPersonSearchPaginationLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyPersonSearchPaginationChange_SUCCESS:
      return {
        ...state,
        contactCompanyPersonSearchPaginationResponse:
          state.contactCompanyPersonSearchPaginationResponse
            ? {
                contactCompany: {
                  resultList: uniqBy(
                    state.contactCompanyPersonSearchPaginationResponse?.contactCompany?.resultList.concat(
                      action.payload.contactCompany.resultList
                    ),
                    "contactCompanyId"
                  ),
                  totalCount: action.payload.contactCompany.totalCount,
                },
                contactPerson: {
                  resultList: uniqBy(
                    state.contactCompanyPersonSearchPaginationResponse?.contactPerson?.resultList.concat(
                      action.payload.contactPerson.resultList
                    ),
                    "contactPersonId"
                  ),
                  totalCount: action.payload.contactPerson.totalCount,
                },
              }
            : action.payload,
        contactCompanyPersonSearchPaginationLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyPersonSearch_FINISH:
      return {
        ...state,
        // contactCompanyPersonSearchPaginationResponse: null,
        contactCompanyPersonSearchPaginationLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyPersonSearch_INIT:
      return {
        ...state,
        // contactCompanyPersonSearchPaginationResponse: null,
        contactCompanyPersonSearchPaginationLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyPersonSearch_SUCCESS:
      return {
        ...state,
        contactCompanyPersonSearchPaginationResponse: action.payload,
        contactCompanyPersonSearchPaginationLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyPersonSearchPaginationChange_SUCCESS:
      return {
        ...state,
        contactCompanyPersonSearchPaginationResponse:
          state.contactCompanyPersonSearchPaginationResponse
            ? {
                contactCompany: {
                  resultList: uniqBy(
                    state.contactCompanyPersonSearchPaginationResponse?.contactCompany?.resultList.concat(
                      action.payload.contactCompany.resultList
                    ),
                    "contactCompanyId"
                  ),
                  totalCount: action.payload.contactCompany.totalCount,
                },
                contactPerson: {
                  resultList: uniqBy(
                    state.contactCompanyPersonSearchPaginationResponse?.contactPerson?.resultList.concat(
                      action.payload.contactPerson.resultList
                    ),
                    "contactPersonId"
                  ),
                  totalCount: action.payload.contactPerson.totalCount,
                },
              }
            : action.payload,
        contactCompanyPersonSearchPaginationLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyPersonSearch_FINISH:
      return {
        ...state,
        // contactCompanyPersonSearchPaginationResponse: null,
        contactCompanyPersonSearchPaginationLoading: false,
      };

    //expert pagination
    case COMPANY_PEOPLE_TYPES.EXPERT_PERSON_PAGINATION_INIT:
      return {
        ...state,
        expertPersonPaginationLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.EXPERT_PERSON_PAGINATION_SUCCESS:
      return {
        ...state,
        expertPersonPaginationResponse: action.payload,
        expertPersonPaginationLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.EXPERT_PERSON_PAGINATION_CHANGE_SUCCESS:
      // console.log("companypagination matched")
      return {
        ...state,
        expertPersonPaginationResponse: state.expertPersonPaginationResponse
          ? {
              items: uniqBy(
                state.expertPersonPaginationResponse?.items.concat(
                  action.payload.items
                ),
                "contactPersonId"
              ),
              totalCount: action.payload.totalCount,
            }
          : action.payload,
        expertPersonPaginationLoading: false,
      };
    // contact company pagination
    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_PAGINATION_INIT:
      return {
        ...state,
        contactCompanyPaginationLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_PAGINATION_SUCCESS:
      return {
        ...state,
        contactCompanyPaginationResponse: action.payload,
        contactCompanyPaginationLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_PAGINATION_CHANGE_SUCCESS:
      return {
        ...state,
        contactCompanyPaginationResponse: state.contactCompanyPaginationResponse
          ? {
              items: uniqBy(
                state.contactCompanyPaginationResponse?.items.concat(
                  action.payload.items
                ),
                "contactCompanyId"
              ),
              totalCount: action.payload.totalCount,
            }
          : action.payload,
        contactCompanyPaginationLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_PAGINATION_FINISH:
      return {
        ...state,
        contactCompanyPaginationLoading: false,
      };

    // contact person pagination
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_PAGINATION_INIT:
      return {
        ...state,
        contactPersonPaginationLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_PAGINATION_SUCCESS:
      return {
        ...state,
        contactPersonPaginationResponse: action.payload,
        contactFavSelectReponse: state.contactPersonPaginationResponse?.items
          .filter((fav) => fav.isFavorite === true)
          .map((filteredfav) => {
            return filteredfav;
          }),
        contactPersonPaginationLoading: action.payload ? false : true,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_PAGINATION_CHANGE_SUCCESS:
      return {
        ...state,
        contactPersonPaginationResponse: state.contactPersonPaginationResponse
          ? {
              items: uniqBy(
                state.contactPersonPaginationResponse?.items.concat(
                  action.payload.items
                ),
                "contactPersonId"
              ),
              totalCount: action.payload.totalCount,
            }
          : action.payload,
        contactPersonPaginationLoading: action.payload ? false : true,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_PAGINATION_FINISH:
      return {
        ...state,
        contactPersonPaginationLoading: false,
      };

    case COMPANY_PEOPLE_TYPES.contactCompanyDetailAdd_INIT:
      return {
        ...state,
        contactCompanyDetailAddResponse: null,
        contactCompanyDetailAddLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyDetailAdd_SUCCESS:
      return {
        ...state,
        contactCompanyDetailAddResponse: action.payload,
        contactCompanyDetailAddLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyDetailAdd_FINISH:
      return {
        ...state,
        contactCompanyDetailAddResponse: null,
        contactCompanyDetailAddLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyUpdate_INIT:
      return {
        ...state,
        contactCompanyUpdateResponse: null,
        contactCompanyUpdateLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyUpdate_SUCCESS:
      return {
        ...state,
        contactCompanyUpdateResponse: action.payload,
        contactCompanyUpdateLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyUpdate_FINISH:
      return {
        ...state,
        contactCompanyUpdateResponse: null,
        contactCompanyUpdateLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactPersonUpdate_INIT:
      return {
        ...state,
        contactPersonUpdateResponse: null,
        contactPersonUpdateLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactPersonUpdate_SUCCESS:
      return {
        ...state,
        contactPersonUpdateResponse: action.payload,
        contactPersonUpdateLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactPersonUpdate_FINISH:
      return {
        ...state,
        contactPersonUpdateResponse: null,
        contactPersonUpdateLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactPersonAdd_INIT:
      return {
        ...state,
        contactPersonAddResponse: null,
        contactPersonAddLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactPersonAdd_SUCCESS:
      return {
        ...state,
        contactPersonAddResponse: action.payload,
        contactPersonAddLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactPersonAdd_FINISH:
      return {
        ...state,
        contactPersonAddResponse: null,
        contactPersonAddLoading: false,
      };

    case COMPANY_PEOPLE_TYPES.contactCompanyGetDetails_INIT:
      return {
        ...state,
        contactCompanyGetDetailsResponse: null,
        contactCompanyGetDetailsLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyGetDetails_SUCCESS:
      return {
        ...state,
        contactCompanyGetDetailsResponse: action.payload,
        contactCompanyGetDetailsLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyGetDetails_FINISH:
      return {
        ...state,
        contactCompanyGetDetailsLoading: false,
      };

    case COMPANY_PEOPLE_TYPES.contactPersonGetDetails_INIT:
      return {
        ...state,
        contactPersonGetDetailsResponse: null,
        contactPersonGetDetailsLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactPersonGetDetails_SUCCESS:
      return {
        ...state,
        contactPersonGetDetailsResponse: action.payload,
        contactPersonGetDetailsLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactPersonGetDetails_FINISH:
      return {
        ...state,
        contactPersonGetDetailsLoading: false,
      };

    case COMPANY_PEOPLE_TYPES.EXPERT_PERSON_GETDETAIL_INIT:
      return {
        ...state,
        expertPersonGetDetailsResponse: null,
        expertPersonGetDetailsLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.EXPERT_PERSON_GETDETAIL_SUCCESS:
      return {
        ...state,
        expertPersonGetDetailsResponse: action.payload,
        expertPersonGetDetailsLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.EXPERT_PERSON_GETDETAIL_FINISH:
      return {
        ...state,
        expertPersonGetDetailsLoading: false,
      };

    //isFavCompany
    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_IS_FAV_INIT:
      return {
        ...state,
        contactCompanyIsFavResponse: null,
        contactCompanyIsFavResponseLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_IS_FAV_SUCCESS:
      return {
        ...state,
        contactCompanyGetDetailsResponse: {
          ...action.record,
          isFavorite: !action.record?.isFavorite,
        },
        contactCompanyPaginationResponse: {
          items: state.contactCompanyPaginationResponse.items.map((items) => {
            if (items.contactCompanyId === action.payload.contactCompanyId) {
              return {
                ...items,
                isFavorite: !items.isFavorite,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.contactCompanyPaginationResponse.totalCount,
        },
        contactCompanyIsFavResponseLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_IS_FAV_FINISH:
      return {
        ...state,
        contactCompanyIsFavResponseLoading: false,
      };

    //isFavperson
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_IS_FAV_INIT:
      return {
        ...state,
        contactPersonIsFavResponse: null,
        contactPersonIsFavResponseLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_IS_FAV_SUCCESS:
      return {
        ...state,
        contactPersonGetDetailsResponse: {
          ...action.record,
          isFavorite: !action.record?.isFavorite,
        },
        contactPersonPaginationResponse: {
          items: state.contactPersonPaginationResponse?.items?.map((items) => {
            if (items.contactPersonId === action.payload.contactPersonId) {
              return {
                ...items,
                isFavorite: !items.isFavorite,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.contactPersonPaginationResponse.totalCount,
        },
        contactPersonIsFavResponseLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_IS_FAV_FINISH:
      return {
        ...state,
        contactPersonIsFavResponse: null,
        contactPersonIsFavResponseLoading: false,
      };

    case COMPANY_PEOPLE_TYPES.contactCompanyNoteUpdate_INIT:
      return {
        ...state,
        contactCompanyNoteRespone: null,
        contactCompanyNoteLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyNoteUpdate_SUCCESS:
      return {
        ...state,
        contactCompanyNoteRespone: action.payload.notes,
        contactCompanyGetDetailsResponse: {
          ...state.contactCompanyGetDetailsResponse,
          notes: action.payload.notes,
        },
        contactCompanyNoteLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.contactCompanyNoteUpdate_FINISH:
      return {
        ...state,
        contactCompanyNoteRespone: null,
        contactCompanyNoteLoading: false,
      };

    //PersonNotes
    case COMPANY_PEOPLE_TYPES.contactPersonNoteUpdate_INIT:
      return {
        ...state,
        contactPersonNoteRespone: null,
        contactPersonNoteLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.contactPersonNoteUpdate_SUCCESS:
      return {
        ...state,
        contactPersonNoteRespone: action.payload,
        contactPersonNoteLoading: false,
        contactPersonGetDetailsResponse: {
          ...state.contactPersonGetDetailsResponse,
          notes: action.payload.notes,
        },
      };
    case COMPANY_PEOPLE_TYPES.contactPersonNoteUpdate_FINISH:
      return {
        ...state,
        contactPersonNoteRespone: null,
        contactPersonNoteLoading: false,
      };

    //search person listing
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_SEARCH_LISTING_INIT:
      return {
        ...state,
        contactPersonSearchListingResponse: null,
        contactPersonSearchListingLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_SEARCH_LISTING_SUCCESS:
      return {
        ...state,
        contactPersonSearchListingResponse: action.payload,
        contactPersonSearchListingLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_SEARCH_LISTING_FINISH:
      return {
        ...state,
        contactPersonSearchListingResponse: null,
        contactPersonSearchListingLoading: false,
      };
    //for quick addEmail
    case COMPANY_PEOPLE_TYPES.CONTACT_QUICK_EMAIL_INIT:
      return {
        ...state,

        contactQuickAddEmailLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_QUICK_EMAIL_SUCCESS:
      return {
        ...state,
        contactQuickAddEmailRespsonse: action.payload,
        contactQuickAddEmailLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.CONTACT_QUICK_EMAIL_FINISH:
      return {
        ...state,

        contactQuickAddEmailLoading: false,
      };

    //gets respose from another tab
    case COMPANY_PEOPLE_TYPES.CONTACT_TYPE_UPDATE_CONTACTRESPONSE:
      // console.log("action redux", action);

      let filterActivePersonDeails = "";
      let filterActiveDetails = "";
      let newDataCompany = state.contactCompanyPaginationResponse?.items;
      let newDataPerson = state.contactPersonPaginationResponse?.items;
      if (action.payload.status === "company") {
        filterActiveDetails = action.payload.contactCompanyDelete
          ? ""
          : action.payload;
        if (
          action.payload.status === "company" &&
          action.payload.contactCompanyDelete
        ) {
          newDataCompany = state.contactCompanyPaginationResponse?.items.filter(
            (items) => items.contactCompanyId !== action.payload.id
          );
        } else if (action.payload.status === "company") {
          newDataCompany = state.contactCompanyPaginationResponse?.items;
        }
      } else if (action.payload.status === "person") {
        filterActivePersonDeails = action.payload.contactPersonDelete
          ? ""
          : action.payload;

        if (
          action.payload.status === "person" &&
          action.payload.contactPersonDelete
        ) {
          newDataPerson = state.contactPersonPaginationResponse?.items.filter(
            (items) => items.contactPersonId !== action.payload.id
          );
        } else if (action.payload.status === "person") {
          newDataPerson = state?.contactPersonPaginationResponse?.items;
        }
      }

      // console.log("filterActivePersonDeails", filterActivePersonDeails);

      return {
        ...state,
        updatedContactResponse: action.payload,
        contactCompanyGetDetailsResponse: {
          ...filterActiveDetails,
        },
        contactCompanyPaginationResponse: {
          items: newDataCompany?.map((items) => {
            if (items.contactCompanyId === action.payload.id) {
              return {
                ...items,
                isFavorite: action.payload.isFavorite,
                contactCompanyAbbrevation: action.payload.companyAbbrevation,
                contactCompanyCityName: "",
                contactCompanyCountryName: "",
                contactCompanyId: action.payload.id,
                contactCompanyName: action.payload.companyName,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.contactCompanyPaginationResponse?.totalCount,
          contactCompanyPaginationLoading: false,
        },
        contactPersonGetDetailsResponse: {
          ...filterActivePersonDeails,
        },
        contactPersonPaginationResponse: {
          items: newDataPerson?.map((items) => {
            if (items.contactPersonId === action.payload.id) {
              return {
                contactCompanyName: action.payload.companyName,
                isFavorite: action.payload.isFavorite,
                personFullName: action.payload.firstName.concat(
                  " ",
                  action.payload.middleName ? action.payload.middleName : "",
                  " ",
                  action.payload.lastName
                ),
                personNickName: action.payload.nickName,
                designationName: action.payload.designationName,
                contactPersonId: action.payload.id,
                contactCompanyAbbrevation: "",
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.contactCompanyPaginationResponse?.totalCount,
          contactPersonPaginatinationRonLoading: false,
        },
      };

    //delete company
    case COMPANY_PEOPLE_TYPES.COMPANY_DELETE_INIT:
      return {
        ...state,
        contactDeleteCompanyLoading: true,
      };
    case COMPANY_PEOPLE_TYPES.COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        contactCompanyDelete: action.payload,
        contactDeleteCompanyLoading: false,
      };
    case COMPANY_PEOPLE_TYPES.COMPANY_DELETE_FINISH:
      return {
        ...state,
        contactCompanyDelete: null,
        contactDeleteCompanyLoading: false,
      };

    //personDelete

    case COMPANY_PEOPLE_TYPES.PERSON_DELETE_INIT:
      // console.log("Redux person", action.payload);
      return {
        ...state,
        contactPesonDeleteLoader: true,
      };
    case COMPANY_PEOPLE_TYPES.PERSON_DELETE_SUCCESS:
      return {
        ...state,
        contactPersonDelete: action.payload,
        contactPesonDeleteLoader: false,
      };
    case COMPANY_PEOPLE_TYPES.PERSON_DELETE_FINISH:
      return {
        ...state,
        contactPersonDelete: null,
        contactPesonDeleteLoader: false,
      };

    //quick add company

    case COMPANY_PEOPLE_TYPES.quickCompanyAdd_INIT:
      return {
        ...state,
        quickContactCompanyLoader: true,
      };
    case COMPANY_PEOPLE_TYPES.quickCompanyAdd_SUCCESS:
      let data = {
        contactCompanyId: action.payload.id,
        contactCompanyName: action.payload.companyName,
      };
      return {
        ...state,

        quickContactCompanyAdd: action.payload,
        quickContactCompanyLoader: false,
        contactCompanyPaginationResponse: {
          items: [data],
          totalCount: state?.contactCompanyPaginationResponse?.totalCount,
          contactCompanyPaginationLoading: false,
        },
      };
    case COMPANY_PEOPLE_TYPES.quickCompanyAdd_FINISH:
      return {
        ...state,
        quickContactCompanyLoader: false,
      };

    // comapny active status
    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_ACTIVE_STATUS_INIT:
      return {
        ...state,
        companyActiveStatusLoading: true,
        companyActiveStatusResponse: [],
      };

    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_ACTIVE_STATUS_SUCCESS:
      return {
        ...state,
        contactCompanyGetDetailsResponse: {
          ...action.payload,
        },
        companyActiveStatusLoading: false,
        companyActiveStatusResponse: action.payload,
      };

    case COMPANY_PEOPLE_TYPES.CONTACT_COMPANY_ACTIVE_STATUS_FINISH:
      return {
        ...state,
        companyActiveStatusLoading: false,
      };

    // comapny active status
    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_ACTIVE_STATUS_INIT:
      return {
        ...state,
        personActiveStatusLoading: true,
        personActiveStatusResponse: [],
      };

    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_ACTIVE_STATUS_SUCCESS:
      return {
        ...state,
        contactPersonGetDetailsResponse: {
          ...action.payload,
        },
        personActiveStatusLoading: false,
        personActiveStatusResponse: action.payload,
      };

    case COMPANY_PEOPLE_TYPES.CONTACT_PERSON_ACTIVE_STATUS_FINISH:
      return {
        ...state,
        personActiveStatusLoading: false,
      };

    // QUICK PERSON ADD
    case COMPANY_PEOPLE_TYPES.QUICK_PERSON_ADD_INIT:
      return {
        ...state,
        quickPersonAddLoading: true,
        quickPersonAddResponse: [],
      };

    case COMPANY_PEOPLE_TYPES.QUICK_PERSON_ADD_SUCCESS:
      return {
        ...state,
        quickPersonAddLoading: false,
        quickPersonAddResponse: action.payload,
      };

    case COMPANY_PEOPLE_TYPES.QUICK_PERSON_ADD_FINISH:
      return {
        ...state,
        quickPersonAddLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
