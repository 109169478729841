import { isEmpty } from "lodash";
import { EMPLOYMENT_REQUISITION_TYPES } from "../../types";

const INITIAL_STATE = {
  applicationData: [],
  applicationDataLoading: false,
  applicationDataError: false,

  applicationAllDataListing: [],
  applicationAllDataLoading: false,
  applicationAllDataError: false,

  applicationSingleData: [],
  applicationSingleDataLoading: false,
  applicationSingleDataError: false,

  applicationSingleDataSuccess: false,

  stageLevelListingData: [],
  stageLevelListingDataLoading: false,

  applicantJobCrudSuccess: false,

  applicantTabSuccess: false,
  applicantId: "",

  corporateMailListing: [],
  corporateMailLoading: false,

  applicationFinalApproveSuccess: [],
  applicationFinalApproveLoading: false,

  applicationStatusCounter: {},

  applicationJobInteviewResp: [],
  applicationJobInterviewLoading: false,

  appplicationJobListingPag: [],
  appplicationJobListingPagLoading: false,

  applicationJobLisingByIdResp: [],
  applicationJobLisingByIdLoading: false,

  composeMailJobApplicationResp: [],
  composeMailJobApplicationLoading: false,

  hrSystemSearchResp: [],
  hrSystemSearchLoading: false,
};

export default function HrSystemServicesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EMPLOYMENT_REQUISITION_TYPES.GET_ALL_STAGE_LEVEL_LISTING_INIT:
      return {
        stageLevelListingData: state.stageLevelListingData,
        stageLevelListingDataLoading: true,
      };
    case EMPLOYMENT_REQUISITION_TYPES.GET_ALL_STAGE_LEVEL_LISTING_SUCCESS:
      return {
        stageLevelListingData: action.payload,
        stageLevelListingDataLoading: false,
      };
    case EMPLOYMENT_REQUISITION_TYPES.GET_ALL_STAGE_LEVEL_LISTING_FINISH:
      return {
        ...state.stageLevelListingData,
        stageLevelListingDataLoading: false,
      };

    //create Application Details
    case EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_REQUISITION_TYPES_INIT:
      return {
        ...state,
        applicationData: [],
        applicationDataLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_REQUISITION_TYPES_SUCCESS:
      let allApplicationData = [
        action.payload,
        ...state?.applicationAllDataListing?.items,
      ];

      return {
        ...state,
        applicationData: action.payload,
        applicationAllDataListing: {
          ...state.applicationAllDataListing,
          items: allApplicationData,
          totalCount: state.applicationAllDataListing?.totalCount + 1,
        },
        applicationDataLoading: false,
        applicationDataSuccess: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_REQUISITION_TYPES_FINISH:
      return {
        ...state,
        applicationDataLoading: false,
        applicationDataSuccess: false,
      };

    //Get All Applcation Details

    case EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES_INIT:
      return {
        ...state,
        applicationAllDataListing: [],
        applicationAllDataLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES_SUCCESS:
      return {
        ...state,
        applicationAllDataListing: action.payload,
        applicationAllDataLoading: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_All_APPLICATION_TYPES_FINISH:
      return {
        ...state,
        applicationAllDataLoading: false,
      };

    //Get Single Application Data
    case EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_INIT:
      return {
        ...state,
        applicationSingleDataLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_SUCCESS:
      let updateBasicDetails = [];

      let singlApplicant =
        state?.applicationSingleData?.applicantInformationDtos?.find(
          (x) => x?.id === action?.details?.applicantId
        );

      // let applicantDetailsCategory = {};
      let applicantDetailsCategory = singlApplicant;

      if (action.details.type === "applicants") {
        if (action.details.crudType === "update") {
          updateBasicDetails =
            state.applicationSingleData?.applicantInformationDtos?.map(
              (item) => {
                if (
                  item?.id === action.payload?.id &&
                  item?.employeeRequisitionId ===
                    action.payload?.employeeRequisitionId &&
                  item?.applicationStatusId ===
                    action.payload?.applicationStatusId
                ) {
                  return action.payload;
                }
                return item;
              }
            );
        } else if (action.details.crudType === "updateBulk") {
          const filteredArray =
            state.applicationSingleData?.applicantInformationDtos?.filter(
              (item) =>
                !action?.payload?.some(
                  (filterItem) => filterItem.id === item.id
                )
            );
          updateBasicDetails = [...action.payload, ...filteredArray];
        } else {
          updateBasicDetails = [
            action.payload,
            ...state.applicationSingleData?.applicantInformationDtos,
          ];
        }
      } else if (action.details.type === "applicantEducation") {
        if (action.details.crudType === "add") {
          applicantDetailsCategory = {
            ...singlApplicant,
            eductionDetailsDtos: action.payload,
          };

          updateBasicDetails =
            state.applicationSingleData?.applicantInformationDtos.map((x) => {
              if (x?.id === action.details.applicantId) {
                return applicantDetailsCategory;
              } else {
                return x;
              }
            });
        } else {
          updateBasicDetails = [
            action.payload,
            ...state?.applicationSingleData?.applicantInformationDtos
              ?.eductionDetailsDtos,
          ];
        }
      } else if (action.details.type === "applicantJob") {
        if (action.details.crudType === "add") {
          applicantDetailsCategory = {
            ...singlApplicant,
            previousJobDetails: action.payload,
          };

          updateBasicDetails =
            state.applicationSingleData?.applicantInformationDtos?.map((x) => {
              if (x?.id === action.details.applicantId) {
                return applicantDetailsCategory;
              } else {
                return x;
              }
            });
        }
      } else if (action.details.type === "applicantReview") {
        applicantDetailsCategory = {
          ...singlApplicant,
          applicantReviewDtos: [
            action.payload,
            ...singlApplicant?.applicantReviewDtos,
          ],
        };

        updateBasicDetails =
          state.applicationSingleData?.applicantInformationDtos.map((x) => {
            if (x?.id === action.details.applicantId) {
              return applicantDetailsCategory;
            } else {
              return x;
            }
          });
      }

      let appSingleData =
        action.details === "application"
          ? { ...state.applicationSingleData, ...action.payload }
          : action.details.type === "applicants"
          ? {
              ...state.applicationSingleData,
              applicantInformationDtos: updateBasicDetails,
            }
          : {
              ...state.applicationSingleData,
              applicantInformationDtos: updateBasicDetails,
            };

      const countMap = appSingleData?.applicantInformationDtos.reduce(
        (count, obj) => {
          const { currentApplicationStageLevel } = obj;
          if (currentApplicationStageLevel in count) {
            count[currentApplicationStageLevel] += 1;
          } else {
            count[currentApplicationStageLevel] = 1;
          }
          return count;
        },
        {}
      );

      let stageLevelCount = {
        apply: {
          total: countMap?.Apply ?? 0,
        },
        confirmApplicant: {
          total: countMap?.ConfirmApplicant ?? 0,
        },
        cvScreening: {
          total: countMap?.CvScreening ?? 0,
        },
        finalApproved: {
          total: countMap?.FinalApproved ?? 0,
        },
        finalRejected: {
          total: countMap?.FinalRejected ?? 0,
        },
        shortlisted: {
          total: countMap?.Shortlisted ?? 0,
        },
        telephoneInterview: {
          total: countMap?.TelephoneInterview ?? 0,
        },
        virtualInterview: {
          total: countMap?.VirtualInterview ?? 0,
        },
      };

      return {
        ...state,
        applicationSingleData: appSingleData,
        applicationSingleDataLoading: false,
        applicationSingleDataSuccess: true,
        applicantSingleDetail: applicantDetailsCategory,
        applicantSingleBasicDetail:
          action.details.type === "applicants" &&
          action.details.crudType === "add" &&
          action.payload,
        applicantTabSuccess: true,
        applicationStatusCounter: stageLevelCount,
        // action.details === "applicationStatusCounter"
        //   ? action.payload
        //   : state.applicationStatusCounter,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_FINISH:
      return {
        ...state,
        applicationSingleDataLoading: false,
        applicationSingleDataSuccess: false,
      };

    //update single application data

    case EMPLOYMENT_REQUISITION_TYPES.EMPLOYMENT_REQUISITION_SINGLE_APPLICATION_TYPES_UPDATE:
      let applicationSingleUpdatedData = {
        ...state.applicationSingleData,
        ...action.query,
        applicantInformationDtos:
          state.applicationSingleData.applicantInformationDtos,
      };

      const updatedItems = state?.applicationAllDataListing?.items?.map(
        (item) => {
          if (item?.id === applicationSingleUpdatedData?.id) {
            return applicationSingleUpdatedData;
          }
          return item;
        }
      );

      return {
        ...state,
        applicationSingleData: applicationSingleUpdatedData,
        applicationAllDataListing: {
          items: updatedItems,
          totalCount: state?.applicationAllDataListing?.totalCount,
        },
        applicationSingleDataLoading: false,
        applicationSingleDataSuccess: true,
      };

    //Given loader to all tab category form  so commentiong education and jobDetails

    //education details
    // case EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES_SUCCESS:
    //   return {
    //     ...state,
    //     applicantEducationSuccess: true,
    //   };

    // case EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_EDUCATION_TYPES_FINISH:
    //   return {
    //     ...state,
    //     applicantEducationSuccess: false,
    //   };

    // //job details
    // case EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES_SUCCESS:
    //   return {
    //     ...state,
    //     applicantJobCrudSuccess: true,
    //   };

    // case EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_JOB_DETAILS_TYPES_FINISH:
    //   return {
    //     ...state,
    //     applicantJobCrudSuccess: false,
    //   };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES_SUCCESS:
      return {
        ...state,
        applicantTabSuccess: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_UPDATE_APPLICANTS_TAB_CATEGORY_DETAILS_TYPES_FINISH:
      return {
        ...state,
        applicantTabSuccess: false,
        applicationSingleDataSuccess: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.APPLICANT_ADD_SUCCESS:
      return {
        ...state,
        applicantId: action.payload.id,
      };

    // corportate email listing
    case EMPLOYMENT_REQUISITION_TYPES.GET_CORPORATE_MAIL_LISTING_INIT:
      return {
        ...state,
        corporateMailListing: [],
        corporateMailLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_CORPORATE_MAIL_LISTING_SUCCESS:
      return {
        ...state,
        corporateMailListing: action.payload,
        corporateMailLoading: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_CORPORATE_MAIL_LISTING_FINISH:
      return {
        ...state,
        corporateMailLoading: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_FINAL_APPROVE_INIT:
      return {
        ...state,
        applicationFinalApproveSuccess: [],
        applicationFinalApproveLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_FINAL_APPROVE_SUCCESS:
      return {
        ...state,
        applicationFinalApproveSuccess: action.payload,
        applicationFinalApproveLoading: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_EMPLOYMENT_FINAL_APPROVE_FINISH:
      return {
        ...state,
        applicationFinalApproveLoading: true,
      };

    // job interview ADD
    case EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_INTERVIEW_INIT:
      return {
        ...state,
        applicationJobInterviewLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_INTERVIEW_SUCCESS:
      const updatedJob = action.payload;

      const updatedJobItem = [
        ...state.appplicationJobListingPag.items,
        updatedJob,
      ];

      const updatedAppplicationJobListingPag = {
        ...state.appplicationJobListingPag,
        items: updatedJobItem,
      };

      return {
        ...state,
        appplicationJobListingPag: updatedAppplicationJobListingPag,
        applicationJobInterviewLoading: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_INTERVIEW_FINISH:
      return {
        ...state,
        applicationJobInterviewLoading: false,
      };

    // job interview UPDATE
    case EMPLOYMENT_REQUISITION_TYPES.UPDATE_APPLICANT_JOB_INTERVIEW_INIT:
      return {
        ...state,
        applicationUpdateJobInterviewLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.UPDATE_APPLICANT_JOB_INTERVIEW_SUCCESS:
      // return {
      //   ...state,
      //   applicationUpdateJobInterviewLoading: false,
      // };
      let updatedJobItems = [];

      if (state.appplicationJobListingPag.items) {
        updatedJobItems = [...state.appplicationJobListingPag.items];
      }

      const existingItemIndex = updatedJobItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingItemIndex !== -1) {
        // If the item already exists, update it
        updatedJobItems[existingItemIndex] = action.payload;
      } else {
        // If the item doesn't exist, push it to the updatedJobItems array
        updatedJobItems.push(action.payload);
      }

      // Remove items with isActive: false
      updatedJobItems = updatedJobItems.filter(
        (item) => item.isActive !== false
      );

      return {
        ...state,
        // questionTypeAddResponse: action.payload,
        applicationJobInterviewLoading: false,
        appplicationJobListingPag: {
          ...state.appplicationJobListingPag,
          items: updatedJobItems,
        },
      };

    case EMPLOYMENT_REQUISITION_TYPES.UPDATE_APPLICANT_JOB_INTERVIEW_FINISH:
      return {
        ...state,
        applicationUpdateJobInterviewLoading: false,
      };

    // id
    case EMPLOYMENT_REQUISITION_TYPES.GET_APPLICANT_JOB_INTERVIEW_BY_ID_INIT:
      return {
        ...state,
        applicationJobLisingByIdLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_APPLICANT_JOB_INTERVIEW_BY_ID_SUCCESS:
      return {
        ...state,
        applicationJobLisingByIdResp: action.payload,
        applicationJobLisingByIdLoading: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_APPLICANT_JOB_INTERVIEW_BY_ID_FINISH:
      return {
        ...state,
        applicationJobLisingByIdLoading: false,
      };

    // get pagination
    case EMPLOYMENT_REQUISITION_TYPES.GET_PAGINATION_JOB_INTERVIEW_INIT:
      return {
        ...state,
        appplicationJobListingPagLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_PAGINATION_JOB_INTERVIEW_SUCCESS:
      return {
        ...state,
        appplicationJobListingPag: action.payload,
        appplicationJobListingPagLoading: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_PAGINATION_JOB_INTERVIEW_FINISH:
      return {
        ...state,
        appplicationJobListingPagLoading: false,
      };

    // compose mail job application
    case EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_MAIL_INIT:
      return {
        ...state,
        composeMailJobApplicationLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_MAIL_SUCCESS:
      return {
        ...state,
        composeMailJobApplicationResp: action.payload,
        composeMailJobApplicationLoading: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.CREATE_APPLICANT_JOB_MAIL_FINISH:
      return {
        ...state,
        composeMailJobApplicationLoading: false,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_HR_SYSTEM_SEARCH_INIT:
      return {
        ...state,
        hrSystemSearchLoading: true,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_HR_SYSTEM_SEARCH_SUCCESS:
      return {
        ...state,
        hrSystemSearchLoading: false,
        hrSystemSearchResp: action.payload,
      };

    case EMPLOYMENT_REQUISITION_TYPES.GET_HR_SYSTEM_SEARCH_FINISH:
      return {
        ...state,
        hrSystemSearchLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
