import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form, Button, Checkbox, Input, Select, Layout, DatePicker } from "antd";
import { validateMessages } from "utils/misc";
import {
  countryAdd,
  countryUpdate,
} from "services/redux/contact/contact.action";
import { isEmpty } from "lodash-es";
import { updateSisterCompany } from "../../../services/redux/admin/sisterCompanyListing/action";

const { Header, Footer } = Layout;

const AddUpdateSisterCompanyDrawer = ({
  onAddUpdateSisterCompanyClose,
  drawerVisibility,
  isUpdateSisterCompany,
  sisterCompanyListing,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const {
    addUpdatesisterCompanyResponse,
    addUpdatesisterCompanyResponseLoading,
  } = useSelector((state) => state.sisterCompany);

  useEffect(() => {
    if (!isUpdateSisterCompany) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        ...isUpdateSisterCompany,
      });
    }
  }, [isUpdateSisterCompany]);

  useEffect(() => {
    onAddUpdateSisterCompanyClose();
  }, [addUpdatesisterCompanyResponse]);

  const onChangeDatePicker = (date, dateString) => {
    console.log(date, dateString);
  };

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (isUpdateSisterCompany) {
        values.id = isUpdateSisterCompany.id;
        dispatch(updateSisterCompany(values));
      } else {
        dispatch(updateSisterCompany(values));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer main-drawer-wrapper"
      onClose={onAddUpdateSisterCompanyClose}
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">
          {`${isUpdateSisterCompany ? "Update" : "Create"} Sister Company`}
        </h4>
      </Header>
      <div className="drawer__content">
        <div className="drawer__content--wrapper">
          <div className="contact__content__wrapper">
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              validateMessages={validateMessages}
              className="drawer-form-wrapper"
            >
              <div className="drawer-form-row">
                <div className="drawer-form-group">
                  <Form.Item
                    name="name"
                    className="form__group"
                    label="Sister Company Name"
                    rules={[{ required: true }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="domainName"
                    className="form__group"
                    label="Domain Name"
                    rules={[{ required: true }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="smtpHost"
                    className="form__group"
                    label="SMTP Host"
                    rules={[{ required: true }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="imapHost"
                    className="form__group"
                    label="IMAP Host"
                    rules={[{ required: true }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                  {/* <Form.Item
                    name="isLocked"
                    className="form__group"
                    label="isLocked"
                    rules={[{ required: true }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item> */}
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="port"
                    className="form__group"
                    label="Port"
                    rules={[{ required: true }]}
                  >
                    <Input type="number" autoComplete="off" />
                  </Form.Item>
                </div>

                <div className="drawer-form-group">
                  <Form.Item
                    name="panNumber"
                    className="form__group"
                    label="Pan Number"
                    rules={[{ required: false }]}
                  >
                    <Input type="number" autoComplete="off" />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="address"
                    className="form__group"
                    label="Address"
                    rules={[{ required: false }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="website"
                    className="form__group"
                    label="Website"
                    rules={[{ required: false }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="linkedin"
                    className="form__group"
                    label="Linkedin"
                    rules={[{ required: false }]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="companyRegistrationNumber"
                    className="form__group"
                    label="Company Registration Number"
                    rules={[{ required: false }]}
                  >
                    <Input type="number" autoComplete="off" />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="companyRegistrationDate"
                    className="form__group"
                    label="Company Registration Date"
                    rules={[{ required: false }]}
                  >
                    <DatePicker format="YYYY-MM-DD" onChange={onChangeDatePicker} />
                  </Form.Item>
                </div>

                <div className="drawer-form-group">
                  <Form.Item
                    name="isActive"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Checkbox>Active</Checkbox>
                  </Form.Item>
                </div>
              </div>
              <div className="model-footer">
                <Button onClick={onAddUpdateSisterCompanyClose}>Cancel</Button>
                <Button
                  loading={addUpdatesisterCompanyResponseLoading}
                  onClick={onSubmit}
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AddUpdateSisterCompanyDrawer;
