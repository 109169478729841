import { GLOBAL } from "./types";

export const init = (type, data) => {
  return {
    type: `${type}_INIT`,
    data,
  };
};

export const finish = (type) => {
  return {
    type: `${type}_FINISH`,
  };
};

export const success = (type, payload, loadMore, record, details) => {
  return {
    type: `${type}_SUCCESS`,
    payload,
    loadMore,
    record,
    details,
  };
};

export const update = (
  type,
  query,
  emailStatus,
  messageCenterTypeName,
  values
) => {
  return {
    type: `${type}_UPDATE`,
    query,
    emailStatus,
    messageCenterTypeName,
    values,
  };
};

export const sameType = (type, payload) => {
  return {
    type: `${type}`,
    payload,
  };
};

export const error = (payload) => {
  return {
    type: GLOBAL.GLOBAL_ERROR,
    payload,
  };
};

export const saveMailTypeAndMail = (type, payload) => {
  return {
    type: `${type}`,
    payload,
  };
};

export const saveContactType = (type, payload) => {
  return {
    type: `${type}`,
    payload,
  };
};

export const saveActiveEmail = (type, payload) => {
  return {
    type: `${type}`,
    payload,
  };
};

export const contactUpdated = (type, payload) => {
  return {
    type: `${type}`,
    payload,
  };
};

// export const getReferenceResponseFromWindows = (type, payload) => {
// 	console.log("yyayyay", payload, type)
// 	return {
// 		type: `${type}`,
// 		payload,
// 	};
// };

// save Tender PersistMail
export const persistSelectedTenderMail = (type, payload) => {
  return {
    type: `${type}`,
    payload,
  };
};

export const test = (type, payload) => {
  // console.log(type, payload);
  return {
    type: `${type}`,
    payload,
  };
};

export const updateOnly = (type, payload) => {
  return {
    type: `${type}_UPDATE`,
    payload,
  };
};

export const partialUpdate = (type, payload) => {
  return {
    type: `${type}_PARTIAL`,
    payload,
  };
};

export const remove = (type, payload) => {
  return {
    type: `${type}_DELETE`,
    payload,
  };
};
