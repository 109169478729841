import { EMAIL_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { ParticipationTypeService } from "./api";
import { message } from "antd";

const participationTypeService = new ParticipationTypeService();

export const getParticipantTypeEmailAddressesListing = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.PARTICIPANT_EMAIL_ADDRESSES_LISTING));
    const response =
      await participationTypeService.getParticipantTypeEmailAddressesListing(
        query
      );
    dispatch(finish(EMAIL_TYPES.PARTICIPANT_EMAIL_ADDRESSES_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(
          EMAIL_TYPES.PARTICIPANT_EMAIL_ADDRESSES_LISTING,
          response.data,
          loadMore
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getEmailAddressesFromWithBehalfOfListing = () => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING));
    const response =
      await participationTypeService.getEmailAddressesFromWithBehalfOfListing();
    dispatch(finish(EMAIL_TYPES.EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING));
    if (response.isSuccess) {
      dispatch(
        success(
          EMAIL_TYPES.EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
