import { MULTIPLE_EMAIL_SELECTION } from "../../../types";
import { success } from "services/common";

export const multipleEmailSelectionAction = (data) => {
  return async (dispatch) => {
    dispatch(
      success(MULTIPLE_EMAIL_SELECTION.MULTIPLE_EMAIL_SELECTION_LIST, data)
    );
  };
};
