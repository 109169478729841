import { Card, Col, Row } from "antd";

export default function CeadDetails({ ceadDetails }) {
  return (
    <>
      <Card>
        <Row>
          {Object.entries(ceadDetails).map(([key, value]) => (
            <>
              <Col span={5} className="cead-details-text">
                {key}:
              </Col>
              <Col span={17} className="cead-details-text">
                {value}
              </Col>
            </>
          ))}
        </Row>
      </Card>
    </>
  );
}
