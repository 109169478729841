import React, { useEffect, useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationLayerListing } from 'services/redux/adminSettings/organizationStructure/action';
import { getOrganizationLayerTypeListing } from 'services/redux/adminSettings/organizationStructure/oragnizationLayerType/action';
import { getDesignationListing } from 'services/redux/adminSettings/organizationStructure/designation/action';

// includes
import OrganizationLayerFormDrawer from './OrganizationLayerFormDrawer';
import OrganizationLayerTable from './OrganizationLayerTable';

// antd
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const OrganizationStructure = () => {
  const dispatch = useDispatch();
  const {
    organizationLayerListing,
    organizationLayerListingLoading
  } = useSelector(state => state.organizationStructure);

  const {
    organizationLayerTypeListing
  } = useSelector(state => state.organizationLayerType);
  
  const {
    designationListing
  } = useSelector(state => state.designation);

  const [organizationLayerDrawerVisible, setOrganizationLayerDrawerVisible] = useState(false);
  const [organizationLayerRecord, setOrganizationLayerRecord] = useState({});

  useEffect(() => {
    dispatch(getOrganizationLayerListing());
    dispatch(getOrganizationLayerTypeListing());
    dispatch(getDesignationListing())
  }, []);

  const closeOrganizationLayerDrawer = () => {
    setOrganizationLayerDrawerVisible(false);
  }

  const addEditOrganizationLayer = (record) => {
    if (record) {
      setOrganizationLayerRecord(record);
    } else {
      setOrganizationLayerRecord({});
    }

    setOrganizationLayerDrawerVisible(true);
  }

  return (
    <div className="layout">
      <div className="organizer meetings">
        <div className="meetings__flex">
          <h3 className="header__all">Organization Structure</h3>
          <Button className="btn-primary" onClick={() => addEditOrganizationLayer()}>
            <PlusOutlined /> Create Organization Layer
          </Button>
        </div>
      </div>
      <OrganizationLayerTable
        addEditOrganizationLayer={addEditOrganizationLayer}
      />
    
      <OrganizationLayerFormDrawer
        organizationLayerDrawerVisible={organizationLayerDrawerVisible}
        closeOrganizationLayerDrawer={closeOrganizationLayerDrawer}
        organizationLayerTypeListing={organizationLayerTypeListing}
        organizationLayerRecord={organizationLayerRecord}
        organizationLayerListing={organizationLayerListing}
        designationListing={designationListing}
      />
    </div>
  )
}

export default OrganizationStructure;