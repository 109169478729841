import React from "react";
import { SectorAddUpdateDrawer } from "../../AdminSettings/ProjectSetting/Sector/SectorDrawer";

export const AddProjectSector = ({
  drawerVisibilty,
  onAddUpdateSectorClose,
}) => {
  //   useEffect(() => {
  //     onUpdateProfileClose();
  //   }, [userRegisteration]);

  return (
    <>
      <div>
        <SectorAddUpdateDrawer
          drawerVisibility={drawerVisibilty}
          onAddUpdateSectorClose={onAddUpdateSectorClose}
        />
      </div>
    </>
  );
};
