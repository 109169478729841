import { DOCUMENT_REQUEST_UPLOAD_TYPES } from "../../types";
import { documentUpload } from "./api";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
const documentRequestUpload = new documentUpload();
//document pagination
export const documentRequestPagination = (query, forPagination = false) => {
  return async (dispatch) => {
    dispatch(init(DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_PAGINATION));
    const response = await documentRequestUpload.documentRequestPagination(
      query
    );
    dispatch(finish(DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_PAGINATION));
    if (response.isSuccess) {
      if (forPagination) {
        dispatch(
          success(
            DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_PAGINATION_CHANGE,
            response.data
          )
        );
      } else {
        dispatch(
          success(
            DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_PAGINATION,
            response.data
          )
        );
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
//document upload according to label type
export const documentRequestDocumentUpload = (body) => {
  return async (dispatch) => {
    dispatch(
      init(DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD)
    );
    const response = await documentRequestUpload.documentRequestUpload(body);
    dispatch(
      finish(DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD)
    );
    if (response.isSuccess) {
      message.success("Issued document");
      dispatch(
        documentRequestPagination(
          {
            MaxResultCount: 20,
            skipCount: 0,
            FileLableTypeId: "",
            searchKeyword: "",
          },
          false
        )
      );
      dispatch(
        success(
          DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
      dispatch({
        type: DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_FAILED,
      });
    }
  };
};

//document upload in temp file
export const documentUploadToTempFile = (files, fileLabelTypeId) => {
  return async (dispatch) => {
    dispatch(
      init(
        DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE
      )
    );

    const response = await documentRequestUpload.documentRequestUploadTempFile(
      files,
      fileLabelTypeId
    );
    dispatch(
      finish(
        DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE
      )
    );
    if (response.isSuccess) {
      dispatch(
        success(
          DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//share document
export const documentShareToEmail = (data) => {
  return async (dispatch) => {
    dispatch(init(DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_SHARE));
    dispatch(
      success(DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_SHARE, data)
    );
  };
};
