import { ORGANIZER_TYPES } from "../../../types";

const INITIAL_STATE = {
  allAppointmentEvents: [],
  appointmentEventsLoading: false,

  addAppointmentEventResp: [],
  addAppointmentEventRespLoading: false,

  appointmentMarkItOffResp: [],
  appointmentMarkItOffLoading: false,
};

export default function appointmentEventsReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    // Appointment events
    case ORGANIZER_TYPES.APPOINTMENTS_INIT:
      return {
        ...state,
        allAppointmentEvents: [],
        appointmentEventsLoading: true,
      };
    case ORGANIZER_TYPES.APPOINTMENTS_SUCCESS:
      return {
        ...state,
        allAppointmentEvents: action.payload,
        appointmentEventsLoading: false,
      };
    case ORGANIZER_TYPES.APPOINTMENTS_FINISH:
      return {
        ...state,
        appointmentEventsLoading: false,
      };

    // Appointment events add
    case ORGANIZER_TYPES.ADD_APPOINTMENT_INIT:
      return {
        ...state,
        addAppointmentEventResp: [],
        addAppointmentEventRespLoading: true,
      };
    case ORGANIZER_TYPES.ADD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        addAppointmentEventResp: action.payload,
        addAppointmentEventRespLoading: false,
      };
    case ORGANIZER_TYPES.ADD_APPOINTMENT_FINISH:
      return {
        ...state,
        addAppointmentEventRespLoading: false,
      };

    // Appointment events mark it off
    case ORGANIZER_TYPES.APPOINTMENT_COMPLETE_INIT:
      return {
        ...state,
        appointmentMarkItOffResp: [],
        appointmentMarkItOffLoading: true,
      };
    case ORGANIZER_TYPES.APPOINTMENT_COMPLETE_SUCCESS:
      return {
        ...state,
        appointmentMarkItOffResp: action.payload,
        appointmentMarkItOffLoading: false,
      };
    case ORGANIZER_TYPES.APPOINTMENT_COMPLETE_FINISH:
      return {
        ...state,
        appointmentMarkItOffLoading: false,
      };
    default:
      return { ...state };
  }
}
