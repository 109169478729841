import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  Tabs,
  Menu,
  Table,
  Drawer,
  Modal,
  Form,
  Input,
  Button,
  Skeleton,
  Popconfirm,
  Select,
} from "antd";
import {
  actionForGetCircularPlanningCompanyDtos,
  actionForAddCircularPlanningTemplate,
  actionForUpdateCircularPlanningCompany,
  actionForGetAllCircularPlanningTemplate,
  actionForAddImportIntoReferenceCompanies,
} from "services/redux/Reference/action";
import { getCompanyorPersonEmailAddess } from "services/redux/contact/contact.action";
import {
  getContactCompanyPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";

import { isEmpty } from "lodash";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import AddCompanyComponent from "components/AddCompany/index";
import EditCompanyModel from "components/AddCompany/EditCompanyModal";

function CircularTemplate(props) {
  const {
    // isVisible,
    isTemplateModalOpen,
    setIsTemplateModalOpen,
    applicationId,
    refCode,
    templateData,
    setIsCircularAddEditModalOpen,
    isCircularAddEditModalOpen,
    circularAddEdit,
    setCircularAddEdit,
    emailReferenceId,
    showProjectTab,
    getDetails,
    isCircular,
    selectedAttendees,
    setSelectedAttendees,
    data,
    setData,
  } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formCircularEdit] = Form.useForm();

  const { Option } = Select;
  const { TabPane } = Tabs;
  const { SubMenu } = Menu;

  const {
    getCircularPlanningCompanyDtosResp,
    getCircularPlanningCompanyDtosLoading,
    allCircularPlanningTemplateLoading,
    addImportIntoReferenceCompaniesLoading,
  } = useSelector((state) => state.reference);

  const priorityEventsState = useSelector((state) => state.priorityEvents);
  const { organizerEventPriorityListing } = priorityEventsState;
  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );

  const [companyDtosData, setCompanyDtosData] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [selectedMenuItemTitle, setSelectedMenuItemTitle] = useState("");
  const [isCircularEditVisible, setIsCircularEditVisible] = useState(false);
  const [circularCompanyEdit, setCircularCompanyEdit] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [valueRadioAddCompany, setValueRadioAddCompany] = useState("1");
  const [grantedPolices, setGrantedPolices] = useState({});
  const [selectedCompanyName, setSelectedCompanyName] = useState("");

  useEffect(() => {
    if (!isEmpty(applicationConfiguration?.auth?.grantedPolicies)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  const handleMenuClick = (e) => {
    setSelectedMenuItem(e.key);
    dispatch(actionForGetCircularPlanningCompanyDtos(e?.key));
    setSelectedMenuItemTitle(e.item.props["value"]);
  };

  const handleCircularCompanyDelete = (e) => {
    let body = {};
    body = {
      id: selectedMenuItem,
      title: selectedMenuItemTitle,
      isactive: false,
      templatePriorityId: "3c1c3d00-df84-401b-aa96-7d730e96ea60",
    };
    // console.log("body: ", body)

    try {
      dispatch(actionForAddCircularPlanningTemplate(body));
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      // dispatch(actionForGetAllCircularPlanningTemplate())
      setSelectedMenuItem(null);
    }
  };

  // const handleCircularCompanyEdit = (record) => {
  //     if (!record) return;

  //     const attentionToArray = JSON.parse(record?.attentionTo || "[]");

  //     if (record?.companyId) {
  //         dispatch(
  //             getContactPersonPagination({
  //                 CompanyId: record?.companyId,
  //             })
  //         );
  //     }

  //     formCircularEdit.setFieldsValue({
  //         CompanyName: record?.companyName ?? '',
  //         contactCompanyId: record?.companyId ?? '',
  //         attendees: attentionToArray.map((item) => item.AttentionTo) ?? "[]",
  //         remarks: record?.remarks ?? '',
  //         ModeofCommunication: record?.modeofCommunication ?? '',
  //     });

  //     switch (record?.modeofCommunication) {
  //         case "Email":
  //             setValueRadioAddCompany("1");
  //             break;
  //         case "Facebook":
  //             setValueRadioAddCompany("2");
  //             break;
  //         case "Whatsapp":
  //             setValueRadioAddCompany("3");
  //             break;
  //         case "Twitter":
  //             setValueRadioAddCompany("4");
  //             break;
  //         case "Other":
  //             setValueRadioAddCompany("5");
  //             break;
  //         default:
  //             setValueRadioAddCompany("1");
  //     }
  // }
  const mapModeOfCommunicationToNumber = (mode) => {
    switch (mode) {
      case "Email":
        return 1;
      case "Facebook":
        return 2;
      case "Whatsapp":
        return 3;
      case "Twitter":
        return 4;
      case "Other":
        return 5;
      default:
        return 5;
    }
  };

  const mapNumberToModeOfCommunication = (number) => {
    switch (number) {
      case 1:
        return "Email";
      case 2:
        return "Facebook";
      case 3:
        return "Whatsapp";
      case 4:
        return "Twitter";
      case 5:
        return "Other";
      default:
        return "Other";
    }
  };

  const handleCircularCompanyEdit = (record) => {
    if (!record) return;

    // const {
    //   attentionTo = "[]",
    //   companyId,
    //   companyName = "",
    //   remarks = "",
    //   modeofCommunication = "",
    // } = record;
    const attentionToArray = JSON.parse(record?.attentionTo || "[]");
    let mailAddressss = JSON.parse(record?.emailTo || "[]");

    let selectedPersonIds =
      attentionToArray &&
      attentionToArray?.map((item) => ({
        contactPersonIds: item?.AttentionId,
      }));

    if (record.companyId) {
      dispatch(getContactPersonPagination({ CompanyId: record.companyId }));
      dispatch(
        getCompanyorPersonEmailAddess(selectedPersonIds, record.companyId)
      );
    }

    const modeOfCommunicationString = mapNumberToModeOfCommunication(
      record?.modeofCommunication
    );

    formCircularEdit.setFieldsValue({
      // CompanyName: companyName,
      // contactCompanyId: companyId || "",
      // attendees: attentionToArray.map((item) => item.AttentionTo) || [],
      // remarks: remarks ?? "",
      // ModeofCommunication: record?.modeofCommunication ?? "",
      // mailTo: mailAddressss || [],
      CompanyName: record?.partyName || "",
      contactCompanyId: record?.contactCompanyId || "",
      attendees:
        (attentionToArray &&
          attentionToArray.map((item) => item.AttentionTo)) ||
        [],
      mailTo: mailAddressss || [],
      remarks: record?.remarks ?? "",
      priority: record?.priority ?? "",
    });

    // const communicationMap = {
    //   Email: "1",
    //   Facebook: "2",
    //   Whatsapp: "3",
    //   Twitter: "4",
    //   Other: "5",
    // };

    // setValueRadioAddCompany(communicationMap[modeofCommunication] || "1");
    switch (record?.modeofCommunication) {
      case "Email":
        setValueRadioAddCompany("1");
        break;
      case "Facebook":
        setValueRadioAddCompany("2");
        break;
      case "Whatsapp":
        setValueRadioAddCompany("3");
        break;
      case "Twitter":
        setValueRadioAddCompany("4");
        break;
      case "Other":
        setValueRadioAddCompany("5");
        break;
      default:
        setValueRadioAddCompany("1");
    }
  };

  const handleImport = () => {
    let data = {
      templateId: selectedMenuItem,
      referenceId: getDetails?.referenceId,
    };

    // console.log(data);
    dispatch(actionForAddImportIntoReferenceCompanies(data));
  };

  useEffect(() => {
    if (getCircularPlanningCompanyDtosResp) {
      setCompanyDtosData(getCircularPlanningCompanyDtosResp);
    }
  }, [getCircularPlanningCompanyDtosResp]);

  useEffect(() => {
    form.setFieldsValue({
      remarks: circularAddEdit?.data?.remarks,
      title: circularAddEdit?.data?.title,
    });
  }, [circularAddEdit, form]);

  const handleDeleteCompany = (record) => {
    console.log("DELETE", record);
    let deletePayload = {
      ...record,
      isactive: false,
    };

    dispatch(actionForUpdateCircularPlanningCompany(deletePayload));
  };

  // let emailTo	= '["kamal6666@gmail.com","jamesbong@gmail.com"]';

  const columns = [
    {
      title: "S.N",
      dataIndex: "age",
      key: "age",
      width: "8%",
      render: (text, row, index) => <div>{index + 1}</div>,
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Moc",
      dataIndex: "modeofCommunication",
      key: "modeofCommunication",
    },
    {
      title: "Attention to",
      key: "attentionTo",
      render: (record) => {
        try {
          const rawAttentionTo = record?.attentionTo && record?.attentionTo;

          if (
            typeof rawAttentionTo === "string" &&
            rawAttentionTo.trim().startsWith("[") &&
            rawAttentionTo.trim().endsWith("]")
          ) {
            const attentionToArray = JSON.parse(rawAttentionTo);

            if (
              Array.isArray(attentionToArray) &&
              attentionToArray.length > 0
            ) {
              return attentionToArray.map((x) => x.AttentionTo).join(", ");
            }
          }

          return "";
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return "";
        }
      },
    },
    {
      title: "Priority",
      key: "priority",
      dataIndex: "priority",
    },
    {
      title: "Email To",
      key: "emailTo",
      render: (record) => {
        try {
          const rawEmailTo = record?.emailTo && record?.emailTo;

          if (
            typeof rawEmailTo === "string" &&
            rawEmailTo.trim().startsWith("[") &&
            rawEmailTo.trim().endsWith("]")
          ) {
            const emailToArray = JSON.parse(rawEmailTo);

            if (Array.isArray(emailToArray) && emailToArray.length > 0) {
              return emailToArray.join(", ");
            }
          }

          return "";
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return "";
        }
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Action",
      key: "action",
      width: "12%",
      render: (_, record) => (
        <>
          <EditOutlined
            onClick={() => {
              handleCircularCompanyEdit(record);
              setSelectedCompanyId(record?.companyId);
              setSelectedId(record?.id);
              setSelectedCompanyName(record?.companyName);
              setSelectedAttendees(JSON.parse(record?.attentionTo || "[]"));
              setIsCircularEditVisible(true);
            }}
          />
          <DeleteOutlined
            onClick={() => handleDeleteCompany(record)}
            style={{ marginLeft: "8px" }}
          />
        </>
      ),
    },
  ];

  const handleAddEditCancel = () => {
    setIsCircularAddEditModalOpen(false);
    form.resetFields();
  };

  const handleAddSubmit = async () => {
    let data = {};
    let values = await form.validateFields();
    data = {
      title: values?.title,
      isactive: true,
      templatePriorityId: values?.organizerEventPriorityId,
    };
    // console.log("data:" , data)
    try {
      dispatch(actionForAddCircularPlanningTemplate(data));
    } catch (err) {
      console.log("error: ", err);
    } finally {
      handleAddEditCancel();
    }
  };

  const handleEditSubmit = async () => {
    let update = {};
    let values = await form.validateFields();

    update = {
      id: selectedMenuItem,
      title: values?.title,
      isactive: true,
      templatePriorityId: values?.organizerEventPriorityId,
    };
    try {
      dispatch(actionForAddCircularPlanningTemplate(update));
      setSelectedMenuItemTitle(update?.title);
    } catch (err) {
      console.log("error: ", err);
    } finally {
      handleAddEditCancel();
    }
  };

  return (
    <>
      <div className="" style={{ display: "flex" }}>
        <div className="left-sidebar">
          <Menu
            onClick={handleMenuClick}
            style={{ width: 256 }}
            defaultSelectedKeys={["0"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
          >
            {/* {templateData.map((item) => (
                        <Menu.Item
                        key={item?.id}
                        className="sidebars__menu--item"
                        >
                        {item.title}
                        </Menu.Item>
                    ))} */}

            {allCircularPlanningTemplateLoading ? (
              <Skeleton active />
            ) : (
              templateData.map((item, index) => (
                <Menu.Item
                  key={item?.id}
                  value={item?.title}
                  // className="sidebars__menu--item"
                  className={`sidebars__menu--item ${
                    index % 2 === 0 ? "even-row" : "odd-row"
                  }`}
                >
                  <span>
                    {index + 1}. {item.title}
                  </span>
                </Menu.Item>
              ))
            )}
          </Menu>
        </div>
        <div
          className="main-layout"
          style={{ width: "calc(100% - 256px", padding: "0 .8em" }}
        >
          {selectedMenuItem !== null ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "6px",
                }}
              >
                <Button
                  type="button"
                  className="btn-primary"
                  onClick={handleImport}
                  loading={addImportIntoReferenceCompaniesLoading}
                >
                  Import
                </Button>
              </div>

              <div
                style={{
                  background: "#d8ddeb",
                  padding: " 0px 7px",
                  height: "26px",
                }}
              >
                <div
                  className="business-header-title"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Title - {selectedMenuItemTitle}</span>
                  <div className="">
                    <Button
                      type="text"
                      size="small"
                      onClick={() => {
                        setCircularAddEdit({
                          add: false,
                          edit: true,
                          data: {
                            title: selectedMenuItemTitle,
                          },
                        });
                        setIsCircularAddEditModalOpen(true);
                      }}
                    >
                      Edit
                    </Button>

                    {!isEmpty(grantedPolices) &&
                      grantedPolices[
                        "CQNovalFramework.Tender.CircularPlanning.Delete"
                      ] && (
                        <Popconfirm
                          title="Delete Circular Template"
                          description="Are you sure to delete this task?"
                          // onConfirm={confirm}
                          onConfirm={handleCircularCompanyDelete}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="text"
                            size="small"
                            // onClick={handleCircularCompanyDelete}
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                      )}
                  </div>
                </div>
              </div>
              <Table
                dataSource={companyDtosData}
                columns={columns}
                loading={getCircularPlanningCompanyDtosLoading}
              />
              <AddCompanyComponent
                selectedMenuItem={selectedMenuItem}
                applicationId={applicationId}
                isTemplateModalOpen={isTemplateModalOpen}
                setIsTemplateModalOpen={setIsTemplateModalOpen}
                emailReferenceId={emailReferenceId}
                showProjectTab={showProjectTab}
                getDetails={getDetails}
                isCircular={isCircular}
                selectedAttendees={selectedAttendees}
                setSelectedAttendees={setSelectedAttendees}
                data={data}
                setData={setData}
              />
            </>
          ) : (
            <div>No menu item selected.</div>
          )}
        </div>
      </div>

      <EditCompanyModel
        emailReferenceId={emailReferenceId}
        isCircularEditVisible={isCircularEditVisible}
        setIsCircularEditVisible={setIsCircularEditVisible}
        record={circularCompanyEdit}
        form={formCircularEdit}
        selectedId={selectedId}
        selectedCompanyId={selectedCompanyId}
        setSelectedCompanyId={setSelectedCompanyId}
        valueRadioAddCompany={valueRadioAddCompany}
        setValueRadioAddCompany={setValueRadioAddCompany}
        selectedAttendees={selectedAttendees}
        setSelectedAttendees={setSelectedAttendees}
        selectedMenuItem={selectedMenuItem}
        selectedCompanyName={selectedCompanyName}
      />

      {/* Modal */}
      <Modal
        title={circularAddEdit?.add ? "Add" : "Edit"}
        visible={isCircularAddEditModalOpen}
        onCancel={handleAddEditCancel}
        footer={null}
      >
        <Form name="basic" form={form}>
          <Form.Item
            label="Title"
            name="title"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Priority"
            name="organizerEventPriorityId"
            // className="form__group"
            initialValue={organizerEventPriorityListing[3]?.id}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Select Priority"
              autoComplete="off"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {!isEmpty(organizerEventPriorityListing) &&
                organizerEventPriorityListing.map((status, index) => {
                  return (
                    <Option value={status.id} key={index}>
                      {status.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          {circularAddEdit?.add ? (
            <Button onClick={handleAddSubmit} type="primary">
              Add
            </Button>
          ) : (
            <Button onClick={handleEditSubmit} type="primary">
              Update
            </Button>
          )}
        </Form>
      </Modal>
    </>
  );
}

export default CircularTemplate;
