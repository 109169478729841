import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Select, Card } from "antd";
import {
  contactPersonAdd,
  actionForQuickPersonAdd,
} from "services/redux/contact/companypeople/action";
import { useDispatch, useSelector } from "react-redux";
import { countryListing, cityListing } from "services/remanentCalls";
import { cloneDeep, groupBy, isEmpty } from "lodash";

function QuickAddPerson({
  form,
  previousForm,
  layout,
  onCloseModal,
  isAddNewPersonStatus,
  setisAddNewPersonStatus,
  selectedCompanyName,
  selectedCompanyId,
}) {
  // console.log("selectedCompanyName", selectedCompanyName);
  const { contactCompanyPaginationResponse, contactCompanyGetDetailsResponse } =
    useSelector((state) => state.contactCompanyPerson);

  const dispatch = useDispatch();

  const { Option } = Select;

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filterCitiesLists, setFilterCitiesLists] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredCity, setFilteredCity] = useState([]);
  const [primaryAddress, setPrimaryAddress] = useState({});

  const {
    contactPersonAddResponse,
    contactPersonAddLoading,
    contactPersonGetDetailsResponse,
  } = useSelector((state) => state.contactCompanyPerson);

  useEffect(() => {
    fetchCountryListing();
  }, []);

  useEffect(() => {
    fetchCities();
  }, []);

  // USEFFECT
  useEffect(() => {
    const citylisting = cities?.filter(
      (data) => data?.countryId === selectedCountry
    );
    setFilteredCity(citylisting);
  }, [selectedCountry, contactCompanyGetDetailsResponse]);

  useEffect(() => {
    if (!isEmpty(contactCompanyGetDetailsResponse)) {
      const scopeOfWorktrim =
        contactCompanyGetDetailsResponse?.scopeOfWork?.split(",");

      let filteredscopeOfWork = scopeOfWorktrim?.filter((data) => {
        return data;
      });

      let scopeOfWork = filteredscopeOfWork?.map((data) => {
        return data.replace(/\s+/g, "");
      });
      const primaryAddress =
        contactCompanyGetDetailsResponse?.contactCompanyGroupAddressDtos?.find(
          (obj) => obj.isPrimaryAddress
        );
      setPrimaryAddress(primaryAddress);
      form.setFieldsValue({
        designation: contactCompanyGetDetailsResponse?.designationName,
        contactAddressLabelId: primaryAddress?.contactLabelId,
        countryId: primaryAddress?.countryId || null,
        cityId: primaryAddress?.cityId || null,
        contactSourceId: contactCompanyGetDetailsResponse?.contactSourceId,
        // scopeOfWork: newQuickCompanyValue
        //   ? []
        //   : !isEmpty(scopeOfWork)
        //   ? scopeOfWork
        //   : [],
      });
      setSelectedCountry(primaryAddress?.countryId);
    }
  }, [contactCompanyGetDetailsResponse, form.getFieldValue().companyId]);

  // CONST
  const fetchCities = async (selectedCountry, key) => {
    const resp = await cityListing({ selectedCountry }).catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      setCities(resp.data);
    }
  };

  const fetchCountryListing = async (body) => {
    const resp = await countryListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setCountries(resp.data);
    }
  };

  const onChange = (value, index) => {
    const data = cities.filter((filtered) => filtered.countryId === value);

    let newFilterCities = cloneDeep(filterCitiesLists);

    newFilterCities[index] = cloneDeep(data);
    setFilterCitiesLists(newFilterCities);
    //
    setSelectedCountryId(value);
  };

  const validateEmail = (_, value) => {
    const email = form.getFieldValue("email");
    const mobile = form.getFieldValue("mobile");

    // Check if at least one of the fields is filled
    if (!email && !mobile) {
      return Promise.reject(
        "Please provide either an email address or a mobile number"
      );
    }

    // Basic email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Basic mobile validation
    const mobileRegex = /^\d{10}$/;

    // Apply corresponding validation based on the input
    if (
      (email && !emailRegex.test(email)) ||
      (mobile && !mobileRegex.test(mobile))
    ) {
      return Promise.reject("Invalid email address or mobile number");
    }
  };

  const handleQuickAddPerson = (values) => {
    let apiData = {
      // ...values,
      companyCountryId: values?.CompanyCountry,
      // companyId: values?.CompanyPersonAdd,
      companyId: selectedCompanyId,
      firstName: values?.firstName,
      lastName: values?.lastName,
      phoneNumber: values?.mobile,
      isFromCommunication: true,
      email: values?.email,
      companyCityId: values?.cityId,
    };

    try {
      // dispatch(contactPersonAdd(apiData));
      // NEW API
      dispatch(actionForQuickPersonAdd(apiData));
    } catch (err) {
      console.error("error: ", err);
    } finally {
      onCloseModal();
      setisAddNewPersonStatus(true);
    }
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        labelAlign="left"
        onFinish={handleQuickAddPerson}
      >
        <Form.Item name="firstName" label="First Name">
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name">
          <Input placeholder="Last Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          // rules={[
          //   {
          //     validator: validateEmail,
          //   },
          // ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="mobile"
          label="Mobile"
          // rules={[
          //   {
          //     validator: validateEmail,
          //   },
          // ]}
        >
          <Input placeholder="Mobile Number" />
        </Form.Item>
        <Card>
          <label>Company Name</label>
          <Form.Item>
            <Input disabled value={selectedCompanyName} />
          </Form.Item>
          <label>Company Country</label>
          <Form.Item name="CompanyCountry">
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch={true}
              size="default"
              placeholder="Country"
              autoComplete="off"
              onSelect={(value) => {
                setSelectedCountry(value);
              }}
              // getPopupContainer={(trigger) => trigger.parentNode}
            >
              {countries.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <label>Company City</label>
          <Form.Item name="cityId" rules={[{ required: false }]}>
            <Select
              autoComplete="off"
              placeholder="City"
              size="default"
              // onPopupScroll={(e) => {
              //   console.log("duplicated", e);
              // }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch={true}
              listHeight={200}
            >
              {filteredCity?.map((tag) => (
                <Option value={tag.id} key={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Card>

        <Form.Item>
          <Button
            className="company-add-save-btn"
            type="primary"
            htmlType="submit"
            shape="round"
            disabled={contactPersonAddLoading}
          >
            Add Person
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default QuickAddPerson;
