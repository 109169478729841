import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

// antd
import {
  Button,
  Radio,
  Col,
  Row,
  Input,
  Dropdown,
  Menu,
  Checkbox,
  Tabs,
  Collapse,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";

// includes
import Header from "components/header";
import { Event } from "./Organizer__components/discussion";
import CreateEventDrawer from "./TaskEventDrawer/CreateEventDrawer";
import GuestEntry from "./guestEntry";
import MainEvents from "./OragnizerEvent";
import Goals from "./goals";
import Appointments from "./appointment";
import ToDo from "./todoList";
import Meeting from "./meeting";
import FullCalender from "./FullCalender";

// reducer
import { useDispatch, useSelector } from "react-redux";
import { getCalenderListEvents } from "services/redux/calendar/action";
import { getCalenderFilters } from "services/redux/calendar/action";
// import {
//   getProjectSearchPagination,
//   projectGetDetails,
// } from "services/redux/Reference/Project/action";
import { calendarEventpagination } from "services/redux/calendar/action";

import { isEmpty } from "lodash-es";
//remanent

// import { LogoImage } from "utils/imageConstants";

// includes
// import { withBaseLayout } from "../../layouts/Base";
// import statusEventListingReducer from "services/redux/todos/StatusListing/statusreducer";
// import { flexibleCompare } from "@fullcalendar/react";

// icons
const minimize = "assets/images/icons/minimize.png";
const maximize = "assets/images/icons/maximize.png";
const defaultRoute = "/organizer/calendar";

function Organizer({ origin }) {
  const { TabPane } = Tabs;
  const { Panel } = Collapse;
  const { SubMenu } = Menu;
  const dispatch = useDispatch();
  const {
    calenderFilters,
    appointmentResponse,
    // calendarEventSearchPagination,
  } = useSelector((state) => state.calendarEvents);
  const calendarEventSearchPagination = useSelector(
    (state) => state.calendarEvents
  );
  const history = useHistory();
  // const [eventFilter, setEventFilter] = useState("");
  const [eventFilter, setEventFilter] = useState("");

  //For Drawer
  const [eventFormSelect, setEventFormSelect] = useState("");
  const [visibleEventFormDrawer, setVisibleEventFormDrawer] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [isUpdate, setIsUpdate] = useState("details");
  const [selectedTenderRowId, setSelectedTenderRowId] = useState();
  const [searchKeyword, setSearchKeyword] = useState();

  //for Function search function
  const [inputSearchValue, setInputSearchValue] = useState();
  // const [tenderReferenceCode, setTenderReferenceCode] = useState("");
  // const [taskType, setTaskType] = useState("all");
  const [allSearchEvents, setAllSearchEvents] = useState([]);
  const [dateClickDetails, setDateClickDetails] = useState();

  const { calendarEventPaginationResp, calendarEventPaginationLoading } =
    calendarEventSearchPagination;

  useEffect(() => {
    if (!isEmpty(calendarEventPaginationResp)) {
      setAllSearchEvents(calendarEventPaginationResp?.items);
    }
  }, [calendarEventPaginationResp]);

  useEffect(() => {
    dispatch(getCalenderFilters());
  }, []);

  useEffect(() => {
    if (!isEmpty(calenderFilters)) {
      let filteredCalWithOutTask = calenderFilters?.filter((x) => {
        return x?.systemName !== "TASK";
      });

      // setCheckedValues(calenderFilters);
      // onCheckFilter(calenderFilters);
      setCheckedValues(
        origin !== "Dashboard" ? calenderFilters : filteredCalWithOutTask
      );
      onCheckFilter(
        origin !== "Dashboard" ? calenderFilters : filteredCalWithOutTask
      );
    }
  }, [calenderFilters]);

  useEffect(() => {
    if (!isEmpty(eventFilter)) {
      // if (appointmentResponse?.length > 0) {
      getData();
      // }
    }
  }, [eventFilter, appointmentResponse]);

  const getData = () => {
    let appendUrl = `skipCount=0${eventFilter ? eventFilter : ""}`;
    dispatch(getCalenderListEvents(appendUrl, origin));
  };

  const handleChangeEvent = (value) => {
    if (value) {
      setEventFormSelect(value);
      setVisibleEventFormDrawer(true);
    }
  };

  const onCloseEventFormDrawer = () => {
    setVisibleEventFormDrawer(false);
  };
  const [state, setState] = useState({
    current: defaultRoute,
    collapsed: false,
  });

  useEffect(() => {
    if (!isEmpty(inputSearchValue)) {
      let appendUrl = `${
        eventFilter ? eventFilter : ""
      }&searchKeyword=${inputSearchValue}`;
      var timeoutId = setTimeout(() => {
        dispatch(calendarEventpagination(appendUrl));
      }, 100);
    }

    return () => {
      clearTimeout(timeoutId);
      setAllSearchEvents([]);
    };
  }, [inputSearchValue, eventFilter]);

  const pageSize = 30;
  const onCheckFilter = (info) => {
    // let newEventFilter = "";
    let newEventFilter = "&EventTypeSystemNames=BIRTHDAY_ANNIVERSARY";

    info.map((node) => {
      newEventFilter += "&EventTypeSystemNames=" + node.systemName;
      if (!isEmpty(node.children)) {
        node.children.map((nodeChild) => {
          newEventFilter += "&EventTypeSystemNames=" + nodeChild.systemName;
          if (!isEmpty(nodeChild.children)) {
            nodeChild.children.map((nodeChildChild) => {
              newEventFilter +=
                "&EventTypeSystemNames=" + nodeChildChild.systemName;
            });
          }
        });
      }
    });

    setEventFilter(newEventFilter);
    setCheckedValues(info);
  };

  const onCheckFilterToDo = (info) => {
    let newEventFilter = "";
    info.map((node) => {
      newEventFilter += "&EventTypeSystemNames=" + node.systemName;
      if (!isEmpty(node.children)) {
        node.children.map((nodeChild) => {
          if (nodeChild.systemName == "Personal_ToDo" || nodeChild.systemName)
            newEventFilter += "&EventTypeSystemNames=" + nodeChild.systemName;
          if (!isEmpty(nodeChild.children)) {
            nodeChild.children.map((nodeChildChild) => {
              newEventFilter +=
                "&EventTypeSystemNames=" + nodeChildChild.systemName;
            });
          }
        });
      }
    });
    setEventFilter(newEventFilter);
  };

  const toggleCollapse = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };

  const menu = (
    <Menu style={{ width: 144, height: "auto" }}>
      {/* <Menu.Item
				className="sidebars__menu--item"
				onClick={() => {
					handleChangeEvent('event');
				}}
			>
				<>
					<div>
						<div style={{ width: '18%' }}></div>
						<div style={{ width: '50%' }}> Event</div>
					</div>
				</>
			</Menu.Item> */}

      {/* <Menu.Item
				className="sidebars__menu--item"
				onClick={() => {
					handleChangeEvent('task');
				}}
			>
				<>
					<div>
						<div style={{ width: '18%' }}></div>
						<div style={{ width: '50%' }}> Tasks</div>
					</div>
				</>
			</Menu.Item> */}
    </Menu>
  );

  useEffect(() => {
    if (history.location.pathname === "/organizer") {
      history.push(defaultRoute);
    }
    setState({ ...state, current: history.location.pathname });
  }, [history.location.pathname]);

  // const renderComponent = (pathname) => {
  //   switch (pathname) {
  //     case "/organizer/calendar":
  //       return <FullCalender />;
  //     case "/organizer/appointments":
  //       return <Appointments />;
  //     case "/organizer/goals":
  //       return <Goals />;
  //     case "/organizer/guest-entry":
  //       return <GuestEntry />;
  //     case "/organizer/holiday":
  //       return <Event />;
  //     case "/organizer/event":
  //       return <MainEvents />;
  //     // case "/organizer/todoLists":
  //     //   return <ToDo />;
  //     case "/organizer/meeting":
  //       return <Meeting />;
  //     default:
  //       return <FullCalender />;
  //     // return <Event />;
  //   }
  // };
  const calendarEventState = useSelector((state) => state.calendarEvents);
  const { allCalendarEvents, load } = calendarEventState;

  return (
    <div className="sidebars">
      {origin !== "Dashboard" ? (
        <div
          className={`sidebars__menu organizer-sidebar ${
            state.collapsed && "text-align-right"
          }`}
        >
          <div className="toggle__arrow" onClick={toggleCollapse}>
            {state.collapsed ? <img src={maximize} /> : <img src={minimize} />}
          </div>
          {/* <div className="sidebar-title">Organizer</div> */}
          <div className="layout">
            <div className="organizer contacts">
              <Header name="Calendar" />
              <div className="search-wrapper border-b-1">
                <Input.Search
                  className="form__group"
                  style={{ margin: 0 }}
                  placeholder="Search"
                  size="medium"
                  allowClear
                  onSearch={(e) => {
                    setSearchKeyword(e);
                  }}
                  onChange={(e) => {
                    setInputSearchValue(e.target.value);
                    if (e.target.value === "") {
                      setAllSearchEvents([]);
                    }
                  }}
                  value={inputSearchValue}
                  autoComplete="off"
                  // onClick={() => {
                  // 	setshowAll(true);
                  // }}

                  // }}
                  // onClcik={(e) => setSearchKeyword(e.target.value)}
                  // value={SearchKeyword}
                />

                {/* <Dropdown
                overlay={menu}
                arrow
                trigger={["click"]}
                placement="bottomLeft"
                arrow
                placement="bottomRight"
              > */}
                {/* <div className="contactDetails__dropdown"> */}
                <Button className="contact__header__btn-primary">
                  <PlusOutlined
                    onClick={() => {
                      handleChangeEvent("event");
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>

          <Checkbox.Group
            className="check-box-wrapper"
            onChange={onCheckFilter}
            value={checkedValues}
          >
            <div style={{ paddingLeft: 7, marginTop: 15 }}>
              {calenderFilters.map((filter, i) => (
                <div key={i} style={{ height: 30 }}>
                  <Checkbox
                    className={filter.title.toLowerCase()}
                    value={filter}
                  >
                    {filter.title === "Business"
                      ? `${filter?.title}(All)`
                      : filter?.title}
                  </Checkbox>
                </div>
              ))}
            </div>
          </Checkbox.Group>
          <div className="search_events">
            {!isEmpty(allSearchEvents) ? <hr /> : ""}

            {allSearchEvents
              ? allSearchEvents?.map((searchedEvents, key) => {
                  // const backGroundColor = key % 2 == 0 ? "" : "#f2f2f2";
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "#f2f2f2",
                        margin: 5,
                      }}
                    >
                      <span>{searchedEvents.title}</span>
                      {/* <span>{searchedEvents.end}</span> */}
                      <span>
                        {moment(searchedEvents.end).format("YYYY-MM-DD")}
                      </span>
                    </div>
                  );
                })
              : " "}
          </div>

          {/* <Menu
          className="sidebars__menu--items"
          selectedKeys={[state.current]}
          mode="inline"
          inlineCollapsed={state.collapsed}
        >
          <Menu.Item
            className="sidebars__menu--item"
            key={defaultRoute}
            onClick={handleClick}
          >
            Calendar
          </Menu.Item>

          <Checkbox.Group onChange={onCheckFilter} value={checkedValues}>
            <div style={{ paddingLeft: 7, marginBottom: 15 }}>
              {calenderFilters.map((filter, i) => (
                <div key={i} style={{ height: 37 }}>
                  <Checkbox value={filter}>{filter.title}</Checkbox>
                </div>
              ))}
            </div>
          </Checkbox.Group>

          <Menu.Item className="sidebars__menu--item" key="/organizer/todoLists" onClick={handleClick}>
						Tasks
					</Menu.Item> */}
          {/* <Checkbox.Group onChange={onCheckFilterToDo}>
						<div style={{ paddingLeft: 15, marginBottom: 15 }}>
							{calenderFilters.map((filter, i) => {
								for (i; i <= 1; i++) {
									return (
										<div key={i}>
											<Checkbox value={filter}>{filter.title}</Checkbox>
										</div>
									);
								}
							})}
						</div>
					</Checkbox.Group> */}
          {/* <Menu.Item key="/organizer/holiday" onClick={handleClick} className="sidebars__menu--item">
						Holiday
					</Menu.Item> */}
          {/* <Menu.Item key="/organizer/guest-entry" className="sidebars__menu--item" onClick={handleClick}>
						Guest Entry
					</Menu.Item>
					<Menu.Item key="/organizer/event" onClick={handleClick} className="sidebars__menu--item">
						Event Type
					</Menu.Item> */}
          {/* <Menu.Item key="/organizer/goals" onClick={handleClick} className="sidebars__menu--item">
						Goals
					</Menu.Item> */}
          {/* <Menu.Item key="/organizer/appointments" onClick={handleClick} className="sidebars__menu--item">
						Appointments
					</Menu.Item> */}
          {/* <Menu.Item key="/organizer/todoLists" onClick={handleClick} className="sidebars__menu--item">
						To-Do Lists
					</Menu.Item> */}
          {/* <Menu.Item key="/organizer/meeting" onClick={handleClick} className="sidebars__menu--item">
						Meeting
					</Menu.Item> */}
          {/* </Menu> */}
        </div>
      ) : (
        <Checkbox.Group
          // className="check-box-wrapper"
          onChange={onCheckFilter}
          value={checkedValues}
        >
          {calenderFilters.map((filter, i) => (
            <div key={i} style={{ display: "inline" }}>
              {filter?.systemName !== "TASK" && (
                <Checkbox
                  className={filter?.title?.toLowerCase()}
                  value={filter}
                >
                  {filter.title === "Business"
                    ? // ? `${filter?.title}(All)`
                      "Tender(All)"
                    : filter?.title}
                </Checkbox>
              )}
            </div>
          ))}
        </Checkbox.Group>
      )}

      <div
        className="main__body"
        style={{
          width:
            origin !== "Dashboard" ? "calc(100% - 299px)" : "calc(100% - 0px)",
        }}
      >
        {/* {renderComponent(history.location.pathname)} */}
        {!load && (
          <FullCalender
            handleChangeEvent={handleChangeEvent}
            onCloseEventFormDrawer={onCloseEventFormDrawer}
            setDateClickDetails={setDateClickDetails}
            origin={origin}
          />
        )}
      </div>

      <CreateEventDrawer
        visibleEventFormDrawer={visibleEventFormDrawer}
        eventFormSelect={eventFormSelect}
        onCloseEventFormDrawer={onCloseEventFormDrawer}
        dateClickDetails={dateClickDetails}
        getData={getData}
      />
    </div>
  );
}

// export default withBaseLayout(Organizer)
export default Organizer;
