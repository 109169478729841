import { isEmpty } from "lodash";
import { approvalCeadApplicationUpdateManagement } from "services/redux/requistions/ceadApproval/action";
import {
  guestEntertainmentAccountantAction,
  guestEntertainmentManagementAction,
  guestEntertainmentSupervisorAction,
  leaveApplicationUpdateManagement,
} from "services/redux/requistions/leaveType/action";
import {
  loanApplicationUpdateAccountant,
  loanApplicationUpdateManagement,
  loanApplicationUpdateSupervisor,
} from "services/redux/requistions/loanApplication/action";
import {
  getProcurementCounter,
  getProcurementSummary,
  procurementApplicationUpdateAccountant,
  procurementApplicationUpdateManagement,
  procurementApplicationUpdateSupervisor,
} from "services/redux/requistions/procurement/action";
import {
  tadaDetailsUpdateAccountant,
  tadaDetailsUpdateManagement,
  tadaDetailsUpdateSupervisor,
} from "services/redux/requistions/tadaDetails/action";

export const handleSubmit = ({
  loan,
  procurement,
  leave,
  cead,
  tada,
  guestEntertainment,
  values,
  isApproval,
  supervisorStatus,
  accountantStatus,
  managementStatus,
  dispatch,
  loanApplicationId,
  form,
  setRequisitionModal,
  getProcurementAction,
  geStatus,
  guestEntertainmentDetailResponse,
}) => {
  if (loan) {
    let body = { ...values, isApproval: isApproval };
    if (supervisorStatus === "Pending") {
      dispatch(
        loanApplicationUpdateSupervisor(
          body,
          loanApplicationId,
          isApproval,
          undefined
        )
      );
    } else if (
      supervisorStatus === "Approved" &&
      accountantStatus === "Pending"
    ) {
      dispatch(
        loanApplicationUpdateAccountant(
          body,
          loanApplicationId,
          isApproval,
          undefined
        )
      );
    } else {
      dispatch(
        loanApplicationUpdateManagement(
          body,
          loanApplicationId,
          isApproval,
          undefined
        )
      );
    }
  } else if (procurement) {
    let body = { ...values, isApproval: isApproval };
    if (accountantStatus === "Pending" || isEmpty(accountantStatus)) {
      body = { ...body, accountHead: "" };
      dispatch(
        procurementApplicationUpdateAccountant(
          body,
          loanApplicationId,
          isApproval,
          setRequisitionModal,
          getProcurementAction,
          getProcurementCounter,
          undefined
        )
      );
    } else if (
      supervisorStatus === "Pending" &&
      accountantStatus === "Approved"
    ) {
      body = { ...body, accountHead: "" };
      dispatch(
        procurementApplicationUpdateSupervisor(
          body,
          loanApplicationId,
          isApproval,
          setRequisitionModal,
          getProcurementAction,
          getProcurementCounter,
          undefined
        )
      );
    } else {
      body = { ...body, accountHead: "" };
      dispatch(
        procurementApplicationUpdateManagement(
          body,
          loanApplicationId,
          isApproval,
          setRequisitionModal,
          getProcurementAction,
          getProcurementCounter,
          undefined
        )
      );
    }
  } else if (leave) {
    let body = { ...values, isApproved: isApproval };
    dispatch(
      leaveApplicationUpdateManagement(
        body,
        loanApplicationId,
        isApproval,
        setRequisitionModal,
        getProcurementAction,
        getProcurementCounter
      )
    );
    form.resetFields();
  } else if (cead) {
    let body = { ...values, isApproved: isApproval };
    dispatch(
      approvalCeadApplicationUpdateManagement(
        body,
        loanApplicationId,
        isApproval,
        setRequisitionModal,
        getProcurementAction,
        getProcurementCounter
      )
    );
    form.resetFields();
  } else if (tada) {
    const approveReject = isApproval === true ? 1 : 0;
    if (supervisorStatus === "Pending") {
      let body = {
        ...values,
        isApprovedOrRejected: isApproval === true ? 1 : 0,
        isSettlement: false,
      };
      dispatch(
        tadaDetailsUpdateSupervisor(
          approveReject,
          body,
          loanApplicationId,
          setRequisitionModal,
          undefined
        )
      );
      form.resetFields();
    } else if (supervisorStatus === "Supervisor Approved") {
      let body = {
        ...values,
        isApprovedOrRejected: isApproval === true ? 1 : 0,
        isSettlement: false,
      };
      dispatch(
        tadaDetailsUpdateManagement(
          approveReject,
          body,
          loanApplicationId,
          setRequisitionModal,
          undefined
        )
      );
    } else if (supervisorStatus === "Management Advance Approved") {
      let body = {
        ...values,
        isApprovedOrRejected: isApproval === true ? 1 : 0,
        isSettlement: false,
      };
      dispatch(
        tadaDetailsUpdateAccountant(
          approveReject,
          body,
          loanApplicationId,
          setRequisitionModal,
          undefined
        )
      );
    } else if (supervisorStatus === "Settlement Pending") {
      let body = {
        ...values,
        isApprovedOrRejected: isApproval === true ? 3 : 2,
        isSettlement: true,
      };
      const approvedOrNot = isApproval === true ? 3 : 2;
      dispatch(
        tadaDetailsUpdateSupervisor(
          approvedOrNot,
          body,
          loanApplicationId,
          setRequisitionModal
        )
      );
    } else if (supervisorStatus === "Supervisor Settlement Approved") {
      const approvedOrNot = isApproval === true ? 3 : 2;
      let body = {
        ...values,
        isApprovedOrRejected: isApproval === true ? 3 : 2,
        isSettlement: true,
      };
      dispatch(
        tadaDetailsUpdateManagement(
          approvedOrNot,
          body,
          loanApplicationId,
          setRequisitionModal
        )
      );
    } else if (supervisorStatus === "Management Settlement Approved") {
      let body = {
        ...values,
        isApprovedOrRejected: isApproval === true ? 3 : 2,
        isSettlement: true,
      };
      const approvedOrNot = isApproval === true ? 3 : 2;
      dispatch(
        tadaDetailsUpdateAccountant(
          approvedOrNot,
          body,
          loanApplicationId,
          setRequisitionModal
        )
      );
    }
  } else if (guestEntertainment) {
    if (supervisorStatus === "SupervisorApproved") {
      const body1 = {
        ...values,
        isApproved: geStatus === "Approve" ? true : false,
      };
      dispatch(
        guestEntertainmentManagementAction(
          guestEntertainmentDetailResponse?.id,
          body1,
          geStatus,
          undefined,
          setRequisitionModal
        )
      );
    } else if (managementStatus === "ManagementApproved") {
      const body2 = {
        ...values,
        isApproved: geStatus === "Approve" ? true : false,
      };

      dispatch(
        guestEntertainmentAccountantAction(
          guestEntertainmentDetailResponse?.id,
          body2,
          geStatus,
          undefined,
          setRequisitionModal
        )
      );
    } else {
      let body = {
        approvedRejectRemarks: values.approvedRejectRemarks,
        isApproved: geStatus === "Approve" ? true : false,
      };
      dispatch(
        guestEntertainmentSupervisorAction(
          guestEntertainmentDetailResponse?.id,
          body,
          geStatus,
          undefined,
          setRequisitionModal
        )
      );
    }
  }
};
