import queryString from "query-string";
import { appBaseUrl } from "utils/config";
import { getService, postService, putService } from "../../commonServices";

export class FingerPrintService {
    addFingerPrint(q) {
        let query = queryString.stringify(q);
        let url = `${appBaseUrl}/attendance/allFingerPrintDataByDateRangeAdd?${query}`;
        // local
        // let url = `http://localhost:7143/FingerPrintData/GetRevisedFingerPrintDataByDateRange?${query}`;
        let data = postService(url);
        return data;
    }
}