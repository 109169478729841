import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import {
  Button,
  Radio,
  Col,
  Row,
  Input,
  Menu,
  Collapse,
  Form,
  Modal,
  Select,
  DatePicker,
  Drawer,
} from "antd";
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";
import queryString from "query-string";
import CreateEventDrawer from "../TaskEventDrawer/CreateEventDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "services/remanentCalls";
import { getOrganizerEventPriorityListings } from "services/redux/organizer/priorityLisiting/action";
import {
  getTodoEventsKanban,
  getTodoEventsPagination,
  taskUsedReference,
  todoEventGetAllCounter,
} from "services/redux/organizer/todoLists/action";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";
import { getReferenceListing } from "services/redux/Reference/action";
import {
  getProjectSearchPagination,
  projectGetDetails,
} from "services/redux/Reference/Project/action";
import { getTenderBasicSearchPagination } from "services/redux/Reference/Tender/action";
import { getOthersSearchPagination } from "services/redux/Reference/Others/action";
import ToDoLists from "./TodoLists";
import Header from "components/header";
import ChatDetails from "views/GlobalEmailChat";
import { getAllUsersListing } from "services/redux/admin/FiscalYear/action";
import { referenceOthersGetDetails } from "services/redux/Reference/Others/action";
import { getDynamicFileLabelTypeUploaderDetail } from "services/redux/common/dynamicFileType/action";

import {
  getAllDepartments,
  getAllRemainder,
} from "services/redux/adminSettings/organizationStructure/action";

import { useLocation } from "react-router-dom";

const minimize = "assets/images/icons/minimize.png";
const maximize = "assets/images/icons/maximize.png";

const ToDo = (props) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const [formToDo] = Form.useForm();
  const [form2] = Form.useForm();
  const { allToDoEvents, todoEventsLoading } = useSelector(
    (state) => state.toDoEvents
  );
  const priorityEventsState = useSelector((state) => state.priorityEvents);
  const statusListingState = useSelector((state) => state.statusListing);

  const { organizerEventPriorityListing } = priorityEventsState;
  const { allEventStatusListing } = statusListingState;

  const [selectedToDoEvent, setSelectedToDoEvent] = useState({});
  const [meetingInvitees, setMeetingInvitees] = useState([]);
  const [tenderTotalCount, setTenderTotalCount] = useState();
  const [projectTotalCount, setprojectTotalCount] = useState();
  const [countTask, setCountTask] = useState([]);
  const [isAdvanceClear, setIsAdvanceClear] = useState(false);
  const [searchValueReference, setSearchValueReference] = useState(null);
  const [selectedReferenceType, setSelectedReferenceType] = useState(null);
  const [filterReference, setFilterReference] = useState([]);
  //   from organizer
  const [eventFormSelect, setEventFormSelect] = useState("");
  const [visibleEventFormDrawer, setVisibleEventFormDrawer] = useState(false);
  const { calenderFilters } = useSelector((state) => state.calendarEvents);

  const [showTabCategory, setTabCategory] = useState();
  const [showProjectTab, setProjectTab] = useState(false);
  const [tenderData, setTenderData] = useState([]);
  const [othersTotalCount, setOthersTotalCount] = useState();
  const [getDetails, setGetDetails] = useState({});
  const [searchEmailTender, setSearchEmailTender] = useState("");
  const [isUpdate, setIsUpdate] = useState("details");
  const [selectedTenderRowId, setSelectedTenderRowId] = useState();
  const [changeCategory, setChangeCategoryButton] = useState(false);
  const [referenceListing, setReferenceListing] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [privateSearch, setPrivateSearch] = useState();
  const [currentPageProject, setCurrentPageProject] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [selectedOtherRowId, setSelectedOtherRowId] = useState();
  const [currentPageOthers, setCurrentPageOthers] = useState(1);
  const [showContractTab, setContractTab] = useState(false);
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [selectedTaskTos, setSelectedTaskTos] = useState([]);
  const [selectedTaskCcs, setSelectedTaskCcs] = useState([]);

  const closeDrawer = () => {
    setHideInternalMessage(false);
  };

  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const [values, setValues] = useState({
    task: "",
    refkeyword: "",
    priority: "",
    watcher: "",
    asignee: "",
    startDate: "",
    endDate: "",
  });

  const [addProjectAddVisibilty, setAddProjectVisibility] = useState("hidden");
  const [projectData, setProjectData] = useState([]);
  const [OthersData, setOthersData] = useState([]);
  const [projectListing, setProjectListing] = useState([]);
  const [extensionData, setExtensionData] = useState([]);
  const [showTenderTab, setTenderTab] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const [showAdvanceSearchModal, setShowAdvanceSearchModal] = useState(false);
  //for Function search function
  const [inputSearchValue, setInputSearchValue] = useState();
  const [url, setUrl] = useState("");
  const [fixedTask, setFixedTask] = useState("");
  const [statusTodo, setStatusTodo] = useState([]);
  const [todoDetails, setTodoDetails] = useState([]);
  const [showAddTask, setShowAddTask] = useState(false);
  const [hideInternalMessage, setHideInternalMessage] = useState(false);
  const [taskId, setTaskId] = useState();
  const [largeWidth, setLargeWidth] = useState(false);
  const [visitor, setVisitor] = useState(false);

  const location = useLocation();
  const getReferenceCodeFromUrl =
    queryString.parse(location.search)?.isRedirecting || "";

  const getDataFromFunction = () => {
    if (getReferenceCodeFromUrl === "true") {
      return "todaysTask";
    } else {
      return "all";
    }
  };
  const [taskCat, setTaskCat] = useState(getDataFromFunction);
  const [taskAssignedTo, setTaskAssignedTo] = useState(getDataFromFunction);
  const [isCompleted, setIsComplete] = useState(0);
  const [privateTask, setPrivateTask] = useState([]);
  const [fixedTaskCategory, setFixedTaskCategory] = useState({
    departmentName: "",
    systemName: "",
  });

  const pageSize = 100;

  const {
    tenderBasicPaginationResponse,
    tenderBasicExtensionGetDetailsResponse,
    tenderBasicGetDetailsResponse,
  } = useSelector((state) => state.tender);

  const { projectPaginationResponse } = useSelector((state) => state.project);

  const { getAllUsersListResponse } = useSelector(
    (state) => state.fiscalYearState
  );

  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );

  const { tasksUsedReference, tasksUsedReferenceLoading } = useSelector(
    (state) => state.toDoEvents
  );

  const {
    departmentListingResponse,
    departmentListingResponseLoading,
    remainderListingResponse,
    remainderListingResponseLoading,
  } = useSelector((state) => state.organizationStructure);

  const { profileInfo } = useSelector((state) => state.profile);

  useEffect(() => {
    setFilterReference(referenceListingResponse?.items);
  }, [referenceListingResponse]);

  useEffect(() => {
    dispatch(getReferenceListing());
    dispatch(taskUsedReference());
    if (
      profileInfo?.userRegistrationAddUpdateDto?.roleNames?.includes("admin")
    ) {
      dispatch(getAllDepartments("admin"));
    } else {
      dispatch(getAllDepartments());
    }
    dispatch(getAllRemainder());
  }, []);

  useEffect(() => {
    if (!isEmpty(searchValueReference)) {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  useEffect(() => {
    if (!isEmpty(referenceListingResponse?.items)) {
      setReferenceListing(referenceListingResponse?.items);
    }
  }, [referenceListingResponse]);

  useEffect(() => {
    if (!isEmpty(selectedReferenceType)) {
      dispatch(getDynamicFileLabelTypeUploaderDetail(selectedReferenceType));
    }
  }, [selectedReferenceType]);

  const { kanbanData, taskCounterListResponse } = useSelector(
    (state) => state.toDoEvents
  );

  const toShowKanbanData = kanbanData?.data;

  const {
    referenceOthersPaginationResponse,
    referenceOthersPaginationLoading,
  } = useSelector((state) => state.referenceOthers);
  const todoEventDetails = useSelector((state) => state.toDoEvents);
  const { toDoGetDetailsResponse } = todoEventDetails;

  const getPrivateTask = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    IsFavourite_SearchKeyword = "false",
    loadMore = false
  ) => {
    let appendUrl = `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;

    if (props.isPrivate) {
      appendUrl += `&isPrivate=${true}`;
    }
    if (privateSearch) {
      appendUrl += `&privateSearch=${privateSearch}`;
    }
    if (props.isPrivate) {
      dispatch(getTodoEventsPagination(appendUrl));
    }
  };

  useEffect(() => {
    dispatch(getAllUsersListing());
  }, []);

  useEffect(() => {
    if (!isEmpty(tenderBasicExtensionGetDetailsResponse)) {
      setExtensionData(tenderBasicExtensionGetDetailsResponse);
    }
  }, [tenderBasicExtensionGetDetailsResponse]);

  useEffect(() => {
    if (!isEmpty(tenderBasicGetDetailsResponse)) {
      setGetDetails(tenderBasicGetDetailsResponse);
    }
  }, [tenderBasicGetDetailsResponse]);

  useEffect(() => {
    getTenderData();
    getDataProject();
    getDataOthers();
    getPrivateTask();
    // getPrivateTaskList();
    dispatch(todoEventGetAllCounter());
  }, [searchKeyword, privateSearch]);

  const { addTodoEventsKanban } = useSelector((state) => state.toDoEvents);

  useEffect(() => {
    if (!isEmpty(taskCounterListResponse)) {
      setCountTask(taskCounterListResponse);
      // setCountPrivate(taskCounterListResponse)
    }
  }, [taskCounterListResponse]);

  useEffect(() => {
    dispatch(getTodoEventsKanban());
  }, []);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    isComplete = 0,
    labelStatusId = "",
    CompleteStatus = "",
    orderBy = "",
    sortType = "",
    priorityStatusId = "",
    progressStatusId = "",
    searchKeyWord = "",
    assignedTo = "",
    referenceId = "",
    forPagination = false
  ) => {
    let appendUrl = `&maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;

    appendUrl += `&isCompleted=${isCompleted}`;
    if (labelStatusId) {
      appendUrl += `&labelStatuses=${labelStatusId}`;
    }

    if (!isEmpty(CompleteStatus)) {
      CompleteStatus.map((status) => {
        appendUrl += `&todoStatuses=${status.id}`;
      });
    }

    if (props.isPrivate) {
      appendUrl += `&isPrivate=${true}`;
    }

    // if (searchKeyWord) {
    //   appendUrl += `&searchKeyword=${searchKeyWord}`;
    // }

    if (orderBy) {
      appendUrl += `&sorting=${orderBy}`;
    }

    if (sortType) {
      appendUrl += `&sortType=${sortType}`;
    }

    if (priorityStatusId) {
      appendUrl += `&priorityStatuses=${priorityStatusId}`;
    }

    if (taskCat !== "private" && taskCat !== "all") {
      if (taskCat === "calls") {
        appendUrl += `&NormalOrPhoneOrVisit=1`;
      } else if (taskCat === "visitors") {
        appendUrl += `&NormalOrPhoneOrVisit=2`;
      } else if (taskCat === "todaysTask") {
        appendUrl += `&IsToday=1`;
      } else if (taskCat === "all") {
        appendUrl += `&IsToday=0`;
      } else {
        appendUrl += `&AssignedStatus=${taskCat}`;
      }
    } else if (taskCat === "private") {
      appendUrl += "&isPrivate=true";
    }

    if (taskCat.includes("-")) {
      appendUrl += `&DepartmentIds=${taskCat}`;
    }

    if (!!!referenceId && !!selectedTenderRowId) {
      appendUrl += `&ReferenceCodes=${selectedTenderRowId}`;
    }
    if (selectedProjectId) {
      appendUrl += `&ReferenceCodes=${selectedProjectId}`;
    }
    dispatch(getTodoEventsPagination(appendUrl, forPagination));
    setUrl(appendUrl);

    setSelectedTenderRowId("");
    setSelectedProjectId("");
  };

  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {});

    if (resp && resp.status === 200) {
      setMeetingInvitees(resp.data);
    }
  };

  useEffect(() => {
    if (allEventStatusListing) {
      const completedStatuses = allEventStatusListing.filter(
        (status) => status.name !== "Completed"
      );
      setStatusTodo(completedStatuses);
    }
  }, [allEventStatusListing]);

  useEffect(() => {
    // getData();

    if (isEmpty(organizerEventPriorityListing)) {
      dispatch(getOrganizerEventPriorityListings());
    }

    if (isEmpty(allEventStatusListing)) {
      dispatch(getStatusEventListing());
    }

    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
  }, [isCompleted, taskCat]);

  const editToDoList = (item) => {
    setSelectedToDoEvent(item);
  };

  const handleChangeEvent = (value) => {
    if (value) {
      setEventFormSelect(value);
      setVisibleEventFormDrawer(true);
    }
  };
  const onCloseEventFormDrawer = () => {
    setVisibleEventFormDrawer(false);
    setTodoDetails("");
  };
  const showTaskFormDrawer = () => {
    setVisibleEventFormDrawer(true);
    setEventFormSelect("task");
  };

  useEffect(() => {
    getTenderData();
    getDataProject();
    getDataOthers();
  }, [searchKeyword]);

  //for next pagination of Business Module
  useEffect(() => {
    if (isEmpty(tenderBasicPaginationResponse?.items)) return;
    let newCurrentPage = tenderBasicPaginationResponse.items.length / pageSize;
    setCurrentPage(newCurrentPage);
  }, [tenderBasicPaginationResponse]);

  useEffect(() => {
    if (isEmpty(projectPaginationResponse?.items)) return;
    let newCurrentPage = projectPaginationResponse.items.length / pageSize;
    setCurrentPageProject(newCurrentPage);
  }, [projectPaginationResponse]);

  useEffect(() => {
    if (isEmpty(referenceOthersPaginationResponse?.items)) return;
    let newCurrentPage =
      referenceOthersPaginationResponse.items.length / pageSize;
    setCurrentPageOthers(newCurrentPage);
  }, [referenceOthersPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(tenderBasicGetDetailsResponse)) {
      setGetDetails(tenderBasicGetDetailsResponse);
    }
  }, [tenderBasicGetDetailsResponse]);

  useEffect(() => {
    if (
      !tenderBasicPaginationResponse ||
      !projectPaginationResponse ||
      !referenceOthersPaginationResponse
    )
      return;
    setTenderData(tenderBasicPaginationResponse?.items);
    setTenderTotalCount(tenderBasicPaginationResponse?.totalCount);
    setProjectData(projectPaginationResponse?.items);
    setprojectTotalCount(projectPaginationResponse?.totalCount);
    setOthersData(referenceOthersPaginationResponse?.items);
    setOthersTotalCount(referenceOthersPaginationResponse?.totalCount);
  }, [
    tenderBasicPaginationResponse,
    projectPaginationResponse,
    referenceOthersPaginationResponse,
  ]);

  //for Tender Pagination
  const searchTender = tenderData
    .filter((fav) => fav.isFavorite === true)
    .map((filteredfav) => {
      return filteredfav;
    });
  const getTenderData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getTenderBasicSearchPagination(
        {
          MaxResultCount,
          SkipCount,

          SearchKeyword: searchKeyword,
        },
        loadMore
      )
    );
  };

  const getDataProject = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getProjectSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: searchKeyword,
        },
        loadMore
      )
    );
  };

  const getDataOthers = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getOthersSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: searchKeyword,
        },
        loadMore
      )
    );
  };

  const todoRowEventHandler = (record) => {
    return {
      onClick: (e) => {
        setTaskId(record?.id);
      },
    };
  };

  useEffect(() => {
    if (!isEmpty(toDoGetDetailsResponse)) {
      setTodoDetails(toDoGetDetailsResponse);
    }
  }, [toDoGetDetailsResponse]);
  const [state, setState] = useState({
    // current: defaultRoute,
    collapsed: false,
  });
  const toggleCollapse = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };
  const renderNameOrNA = (name) => name || "0";
  const fetchMoreDataProject = () => {
    getDataProject(pageSize, currentPageProject * pageSize, true);
  };
  const fetchMoreData = () => {
    getTenderData(pageSize, currentPage * pageSize, true);
  };
  const tenderHeader = () => {
    return <div> Tender [{renderNameOrNA(tenderTotalCount)}]</div>;
  };
  const projectHeader = () => {
    return <div> Project [{renderNameOrNA(projectTotalCount)}]</div>;
  };
  const othersHeader = () => {
    return <div> Others [{renderNameOrNA(othersTotalCount)}]</div>;
  };
  const fetchMoreDataOthers = () => {
    getDataProject(pageSize, currentPageOthers * pageSize, true);
  };

  const rowEventhandlersTender = (data) => {
    setReferenceId(data);
    setTenderTab(true);
    setTabCategory("tender");
    setIsUpdate("details");
    setProjectTab(false);
    setContractTab(false);
  };
  const rowEventhandlersProject = (data) => {
    setReferenceId(data);
    setAddProjectVisibility("hidden");
    setTabCategory("project");
    setIsUpdate("details");
    dispatch(projectGetDetails(data));
    setSelectedProjectId(data);
    setProjectTab(true);
    setTenderTab(false);
    setContractTab(false);
  };

  const rowEventhandlersOthers = (data) => {
    setTenderTab(false);
    setReferenceId(data);
    setTabCategory("others");
    setIsUpdate("details");
    dispatch(referenceOthersGetDetails(data));
    setProjectTab(false);
    setSelectedOtherRowId(data);
    setContractTab(true);
  };
  const [starColor] = useState("#c8c8c8");
  const [showChat, setShowChat] = useState(false);
  const [chatHeight, setChatHeight] = useState(false);
  const [smallChatHeight, setSmallChatHeight] = useState(false);
  const [chatBox, setchatBox] = useState(true);

  const handleOk = () => {
    setShowAdvanceSearchModal(false);
  };

  const handleCancel = () => {
    setShowAdvanceSearchModal(false);
  };

  const onFinishAdvanceSearch = (values) => {
    setValues({
      task: values.task ?? "",
      refkeyword: values.refkeyword ?? "",
      watcher: values?.watcher ?? "",
      asignee: values?.asignee ?? "",
      startDate: values?.dates?.[0]?.format("YYYY-MM-DD") ?? "",
      endDate: values?.dates?.[1]?.format("YYYY-MM-DD") ?? "",
    });
    setShowAdvanceSearchModal(false);
  };

  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  // useEffect(() => {
  //   if (getReferenceCodeFromUrl == "true") {
  //     setReferenceId("");
  //     setTaskAssignedTo("todaysTask");
  //     setLargeWidth(false);
  //     setVisitor(false);
  //     setTaskCat("todaysTask");
  //   }
  // }, [getReferenceCodeFromUrl]);

  return (
    <div className="sidebars">
      <div
        className={`sidebars__menu organizer-sidebar ${
          state.collapsed && "text-align-right"
        }`}
      >
        <div className="toggle__arrow" onClick={toggleCollapse}>
          {state.collapsed ? (
            <img src={maximize} alt="maximize" />
          ) : (
            <img src={minimize} alt="minimize" />
          )}
        </div>
        {/* <div className="sidebar-title">Organizer</div> */}
        <div className="layout">
          <div className="organizer contacts">
            {!props.isPrivate && <Header name="Task" />}

            {props.isPrivate && <Header name="Private Task" />}

            <div className="search-wrapper">
              {/* {!props.isPrivate&&(
                <StarFilled
                // onClick={(e) => {
                //   getData();
                //   getDataOthers();
                //   getDataProject();
                //   setSearchKeyword("");
                //   setInputSearchValue("");
                // }}
                style={{ color: starColor }}
                height="10"
                width="10"
                />
              )} */}

              {!props.isPrivate && isAdvanceClear === true ? (
                <>
                  <Input.Search
                    className="form__group_email_search"
                    style={{ margin: 0 }}
                    // allowClear
                    size="medium"
                    bordered={false}
                    value={"[Advance Search]"}
                  />
                  <button
                    onClick={() => {
                      setIsAdvanceClear(false);
                      setValues({
                        task: "",
                        refkeyword: "",
                        asignee: "",
                        watcher: "",
                        startDate: "",
                        endDate: "",
                      });
                      form2.resetFields();
                    }}
                    className="cross-section-advance-filter"
                  >
                    &#10006;
                  </button>{" "}
                </>
              ) : (
                <Input.Search
                  className="form__group_email_search"
                  style={{ margin: 0 }}
                  placeholder="Search"
                  size="medium"
                  allowClear
                  onSearch={(e) => {
                    setSearchKeyword(e);
                  }}
                  onChange={(e) => {
                    setInputSearchValue(e.target.value);
                  }}
                  value={inputSearchValue}
                  autoComplete="off"
                />
              )}

              {props.isPrivate && (
                <Input.Search
                  className="form__group_email_search"
                  style={{ margin: 0 }}
                  placeholder="Search"
                  size="medium"
                  allowClear
                  onSearch={(e) => {
                    setPrivateSearch(e);
                  }}
                  onChange={(e) => {
                    setInputSearchValue(e.target.value);
                  }}
                  value={inputSearchValue}
                  autoComplete="off"
                  // onClick={() => {
                  // 	setshowAll(true);
                  // }}

                  // }}
                  // onClcik={(e) => setSearchKeyword(e.target.value)}
                  // value={SearchKeyword}
                />
              )}

              {/* <Dropdown
                overlay={menu}
                arrow
                trigger={["click"]}
                placement="bottomLeft"
                arrow
                placement="bottomRight"
              > */}
              {/* <div className="contactDetails__dropdown"> */}
              <div
                className="side__search__wrapper side-contact-search-filter"
                style={{ cursor: "pointer" }}
                //  onClick={() => {
                //    setUnRead(!read);
                //    setSelectTopEmail(true);
                //    setCurrentSelectedMailMessage("");
                //  }}
                onClick={() => {
                  setShowAdvanceSearchModal(true);
                }}
              >
                <FilterOutlined />
              </div>
              <Button className="contact__header__btn-primary task-plus-btn">
                <PlusOutlined
                  onClick={() => {
                    handleChangeEvent("task");
                    setTodoDetails("");
                  }}
                />
              </Button>
              {/* </div> */}
              {/* </Dropdown> */}
            </div>
          </div>
        </div>

        <Row
          gutter={[8, 8]}
          style={{
            marginTop: "-12px",
            height: "36%",
          }}
        >
          <Col span={24}>
            <div className="profiledetails">
              <Collapse
                expandIconPosition="right"
                defaultActiveKey={["1"]}
                accordion={true}
              >
                <Panel className="task-panel" header="My Task" key="1">
                  <Radio.Group
                    className="panel-nav-wrapper task-side-panel"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflowY: "auto",
                    }}
                    value={taskAssignedTo}
                    onChange={(e) => {
                      setTaskAssignedTo(e.target.value);
                    }}
                  >
                    {!props.isPrivate && (
                      <Radio.Button
                        value="all"
                        onClick={() => {
                          setReferenceId("");
                          setLargeWidth(false);
                          setVisitor(false);
                          setTaskCat("all");
                        }}
                      >
                        All Task (
                        {countTask?.all?.total - countTask?.all?.complected})
                      </Radio.Button>
                    )}
                    {props.isPrivate && (
                      <Radio.Button
                        value={""}
                        onClick={() => {
                          setReferenceId("");
                          setLargeWidth(false);
                          setVisitor(false);
                          setTaskCat("");
                        }}
                      >
                        Private Task (
                        {countTask?.private?.total -
                          countTask?.private?.complected}
                        )
                      </Radio.Button>
                    )}

                    {!props.isPrivate && (
                      <>
                        <Radio.Button
                          value="personal"
                          onClick={() => {
                            setReferenceId("");
                            setLargeWidth(false);
                            setVisitor(false);
                            setTaskCat("personal");
                          }}
                        >
                          My Task (
                          {countTask?.personal?.total -
                            countTask?.personal?.complected}
                          )
                        </Radio.Button>

                        <Radio.Button
                          value="todaysTask"
                          onClick={() => {
                            setReferenceId("");
                            setLargeWidth(false);
                            setVisitor(false);
                            setTaskCat("todaysTask");
                          }}
                        >
                          {/* Today's Task (
                          {countTask?.toDay?.total -
                            countTask?.toDay?.complected}
                          ) */}
                          Daily Diary (
                          {countTask?.toDay?.total -
                            countTask?.toDay?.complected}
                          )
                        </Radio.Button>

                        <Radio.Button
                          value="primary"
                          onClick={() => {
                            // setValues({
                            //   ...values,
                            //   refkeyword: "",
                            // });
                            setReferenceId("");
                            setLargeWidth(false);
                            setVisitor(false);
                            setTaskCat("primary");
                          }}
                        >
                          Task Assigned To Me By Others (
                          {countTask?.assignedToMe?.total -
                            countTask?.assignedToMe?.complected}
                          )
                        </Radio.Button>
                        <Radio.Button
                          value="nonprimary"
                          onClick={() => {
                            setReferenceId("");
                            setLargeWidth(false);
                            setVisitor(false);
                            setTaskCat("nonprimary");
                          }}
                        >
                          Task Assinged To Others By Me (
                          {countTask?.assingedOthers?.total -
                            countTask?.assingedOthers?.complected}
                          )
                        </Radio.Button>
                        <Radio.Button
                          value="watcher"
                          onClick={() => {
                            setReferenceId("");
                            setLargeWidth(false);
                            setVisitor(false);
                            setTaskCat("watcher");
                          }}
                        >
                          Task Assigned To Me as Watcher (
                          {countTask?.assignedWatcher?.total -
                            countTask?.assignedWatcher?.complected}
                          )
                        </Radio.Button>

                        <Radio.Button
                          value="calls"
                          onClick={() => {
                            setReferenceId("");
                            setLargeWidth(true);
                            setVisitor(false);
                            setTaskCat("calls");
                            setChangeCategoryButton(true);
                          }}
                        >
                          Calls (
                          {countTask?.calls?.total -
                            countTask?.calls?.complected}
                          )
                        </Radio.Button>

                        <Radio.Button
                          value="visitors"
                          onClick={() => {
                            setReferenceId("");
                            setLargeWidth(true);
                            setVisitor(true);
                            setTaskCat("visitors");
                            setChangeCategoryButton(true);
                          }}
                        >
                          Visits(
                          {countTask?.visits?.total -
                            countTask?.visits?.complected}
                          )
                        </Radio.Button>
                      </>
                    )}
                  </Radio.Group>
                </Panel>

                <Panel className="task-panel" header="Fixed Task" key="2">
                  <Radio.Group
                    className="panel-nav-wrapper task-side-panel"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflowY: "auto",
                    }}
                    // value={fixedTaskCategory}
                    value={taskAssignedTo}
                    onChange={(e) => {
                      // setFixedTask(e.target.value);
                      setTaskAssignedTo(e.target.value);
                    }}
                  >
                    <Menu
                      mode="inline"
                      style={{ paddingLeft: "10px" }}
                      className="task_menu"
                    >
                      {departmentListingResponse?.map((x) => {
                        return (
                          <Menu.SubMenu
                            style={{
                              background: "transparent",
                            }}
                            className="menu__item"
                            title={x.departmentName}
                          >
                            {remainderListingResponse?.map((y) => {
                              return (
                                <Menu.Item
                                  style={{
                                    lineHeight: "20px",
                                    height: "20px",
                                  }}
                                  className="menu__item__new"
                                  onClick={() => {
                                    setReferenceId("");
                                    setLargeWidth(false);
                                    setVisitor(false);
                                    setFixedTaskCategory({
                                      departmentName: x?.departmentName,
                                      systemName: y?.displayName,
                                    });
                                    setFixedTask(x?.departmentName);
                                    setTaskCat(
                                      `${x.id}&TodoEventReminderTypes=${y.id}`
                                    );
                                  }}
                                >
                                  {y.displayName}
                                </Menu.Item>
                              );
                            })}
                          </Menu.SubMenu>
                        );
                      })}
                    </Menu>
                  </Radio.Group>
                </Panel>

                <Panel className="task-panel" header="Private Task" key="3">
                  <Radio.Group
                    className="panel-nav-wrapper task-side-panel"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflowY: "auto",
                    }}
                    value={taskAssignedTo}
                    onChange={(e) => {
                      setTaskAssignedTo(e.target.value);
                    }}
                  >
                    <Radio.Button
                      value="private"
                      onClick={() => {
                        setReferenceId("");
                        setLargeWidth(false);
                        setVisitor(false);
                        setTaskCat("private");
                      }}
                    >
                      Personal Task (Private) (
                      {countTask?.private?.total -
                        countTask?.private?.complected}
                      )
                    </Radio.Button>
                  </Radio.Group>
                </Panel>

                {!props.isPrivate && (
                  <>
                    {/* <Panel
                      header={tenderHeader()}
                      key="3"
                      onChange={() => {
                        setTenderTab(true);
                        setTabCategory("tender");
                        setProjectTab(false);
                      }}
                    >
                      <div
                        id="tenderScroll"
                        className="task-side-panel"
                        style={{
                          overflowY: "auto",
                        }}
                        onClick={() => {
                          setShowAddTask(false);
                        }}
                      >
                        {!isEmpty(tenderData) && (
                          <InfiniteScroll
                            dataLength={
                              tenderBasicPaginationResponse?.items.length
                            }
                            //This is important field to render the next data
                            hasMore={
                              tenderBasicPaginationResponse?.totalCount >
                              tenderBasicPaginationResponse?.items.length
                            }
                            next={fetchMoreData}
                            scrollableTarget="tenderScroll"
                            endMessage={
                              <p style={{ textAlign: "center" }}>
                                <b>End of Tender</b>
                              </p>
                            }
                          >
                            <TenderPagination
                              rowEventhandlersTender={rowEventhandlersTender}
                              setGetDetails={setGetDetails}
                              setSearchEmailTender={setSearchEmailTender}
                              pageSize={pageSize}
                              isUpdate={isUpdate}
                              setSelectedTenderRowId={setSelectedTenderRowId}
                              selectedTenderRowId={selectedTenderRowId}
                              tenderData={tenderData}
                            />
                          </InfiniteScroll>
                        )}
                        {tenderBasicPaginationLoading && (
                          <div style={{ textAlign: "center" }}>
                            <img src={LogoImage} height="auto" width="25px" />
                          </div>
                        )}
                      </div>
                    </Panel> */}

                    {/* <Panel
                      header={projectHeader()}
                      key="3"
                      expandIconPosition="right"
                      onClick={() => {}}
                    >
                      <div className="collapse__wrapper">
                        <div
                          id="scrollableProject"
                          className="task-side-panel"
                          style={{
                            overflowY: "auto",
                          }}
                          onClick={() => {
                            setShowAddTask(false);
                          }}
                        >
                          {!isEmpty(projectPaginationResponse?.items) && (
                            <InfiniteScroll
                              dataLength={
                                projectPaginationResponse?.items.length
                              }
                              //This is important field to render the next data
                              next={fetchMoreDataProject}
                              hasMore={
                                projectPaginationResponse.totalCount >
                                projectPaginationResponse.items.length
                              }
                              scrollableTarget="scrollableProject"
                              endMessage={
                                <p style={{ textAlign: "center" }}>
                                  <b>End of Project</b>
                                </p>
                              }
                            >
                              <ProjectPagination
                                rowEventhandlersProject={
                                  rowEventhandlersProject
                                }
                                projectPaginationResponse={
                                  projectPaginationResponse
                                }
                                setSearchEmailTender={setSearchEmailTender}
                                isUpdate={isUpdate}
                                selectedProjectId={selectedProjectId}
                                // setSelectedProjectRowId={setSelectedProjectRowId}
                                // selectedProjectRowId={selectedProjectRowId}
                                setSelectedProjectId={setSelectedProjectId}
                              />
                            </InfiniteScroll>
                          )}
                          {projectPaginationLoading && (
                            <div
                              style={{ textAlign: "center" }}
                              onClick={() => {
                                setShowAddTask(false);
                              }}
                            >
                              <img src={LogoImage} height="auto" width="25px" />
                            </div>
                          )}
                        </div>
                      </div>
                    </Panel> */}

                    {/* <Panel header="Contracts" key="3" expandIconPosition="right">
												  <ContractPagination rowEventhandlersContract={rowEventhandlersContract} />
											  </Panel> */}
                    {/* <Panel
                      header={othersHeader()}
                      key="4"
                      expandIconPosition="right"
                    >
                      <div className="collapse__wrapper">
                        <div
                          id="scrollableOthers others1"
                          style={{ height: "78vh", overflowY: "auto" }}
                          onClick={() => {
                            setShowAddTask(false);
                          }}
                        >
                          {!isEmpty(
                            referenceOthersPaginationResponse?.items
                          ) && (
                            <InfiniteScroll
                              dataLength={
                                referenceOthersPaginationResponse.items.length
                              }
                              //This is important field to render the next data
                              hasMore={true}
                              next={fetchMoreDataOthers}
                              scrollableTarget="scrollableOthers"
                              endMessage={
                                <p style={{ textAlign: "center" }}>
                                  <b>End of Others</b>
                                </p>
                              }
                            >
                              <ReferenceOthersPagination
                                rowEventhandlersOthers={rowEventhandlersOthers}
                                referenceOthersPaginationResponse={
                                  referenceOthersPaginationResponse
                                }
                                isUpdate={isUpdate}
                                selectedOtherRowId={selectedOtherRowId}
                                setSelectedOtherRowId={setSelectedOtherRowId}
                                setSearchEmailTender={setSearchEmailTender}
                                setSelectedProjectId={setSelectedProjectId}
                              />
                            </InfiniteScroll>
                          )}
                          {referenceOthersPaginationLoading && (
                            <div style={{ textAlign: "center" }}>
                              <img src={LogoImage} height="auto" width="25px" />
                            </div>
                          )}
                        </div>
                      </div>
                    </Panel> */}
                  </>
                )}
              </Collapse>
            </div>
          </Col>
        </Row>

        {/* <Menu
			className="sidebars__menu--items"
			selectedKeys={[state.current]}
			mode="inline"
			inlineCollapsed={state.collapsed}
		  >
			<Menu.Item
			  className="sidebars__menu--item"
			  key={defaultRoute}
			  onClick={handleClick}
			>
			  Calendar
			</Menu.Item>
  
			<Checkbox.Group onChange={onCheckFilter} value={checkedValues}>
			  <div style={{ paddingLeft: 7, marginBottom: 15 }}>
				{calenderFilters.map((filter, i) => (
				  <div key={i} style={{ height: 37 }}>
					<Checkbox value={filter}>{filter.title}</Checkbox>
				  </div>
				))}
			  </div>
			</Checkbox.Group>
  
			<Menu.Item className="sidebars__menu--item" key="/organizer/todoLists" onClick={handleClick}>
						  Tasks
					  </Menu.Item> */}
        {/* <Checkbox.Group onChange={onCheckFilterToDo}>
						  <div style={{ paddingLeft: 15, marginBottom: 15 }}>
							  {calenderFilters.map((filter, i) => {
								  for (i; i <= 1; i++) {
									  return (
										  <div key={i}>
											  <Checkbox value={filter}>{filter.title}</Checkbox>
										  </div>
									  );
								  }
							  })}
						  </div>
					  </Checkbox.Group> */}
        {/* <Menu.Item key="/organizer/holiday" onClick={handleClick} className="sidebars__menu--item">
						  Holiday
					  </Menu.Item> */}
        {/* <Menu.Item key="/organizer/guest-entry" className="sidebars__menu--item" onClick={handleClick}>
						  Guest Entry
					  </Menu.Item>
					  <Menu.Item key="/organizer/event" onClick={handleClick} className="sidebars__menu--item">
						  Event Type
					  </Menu.Item> */}
        {/* <Menu.Item key="/organizer/goals" onClick={handleClick} className="sidebars__menu--item">
						  Goals
					  </Menu.Item> */}
        {/* <Menu.Item key="/organizer/appointments" onClick={handleClick} className="sidebars__menu--item">
						  Appointments
					  </Menu.Item> */}
        {/* <Menu.Item key="/organizer/todoLists" onClick={handleClick} className="sidebars__menu--item">
						  To-Do Lists
					  </Menu.Item> */}
        {/* <Menu.Item key="/organizer/meeting" onClick={handleClick} className="sidebars__menu--item">
						  Meeting
					  </Menu.Item> */}
        {/* </Menu> */}
      </div>
      <div className="main__body task__body sidebar-width-calc">
        <ToDoLists
          searchKeyword={searchKeyword}
          isPrivate={props.isPrivate}
          data={allToDoEvents}
          editToDoList={editToDoList}
          getData={getData}
          pageSize={pageSize}
          todoEventsLoading={todoEventsLoading}
          organizerEventPriorityListing={organizerEventPriorityListing}
          allEventStatusListing={allEventStatusListing}
          referenceId={referenceId}
          taskAssignedTo={taskAssignedTo}
          showTaskFormDrawer={showTaskFormDrawer}
          todoRowEventHandler={todoRowEventHandler}
          statusTodo={statusTodo}
          // handleChangeEvent={handleChangeEvent}
          showAddTask={showAddTask}
          setShowAddTask={setShowAddTask}
          searchEmailTender={searchEmailTender}
          showChat={showChat}
          setShowChat={setShowChat}
          taskId={taskId}
          setHideInternalMessage={setHideInternalMessage}
          setTaskId={setTaskId}
          setSmallChatHeight={setSmallChatHeight}
          setChatHeight={setChatHeight}
          setchatBox={setchatBox}
          largeWidth={largeWidth}
          setLargeWidth={setLargeWidth}
          visitor={visitor}
          setVisitor={setVisitor}
          setIsComplete={setIsComplete}
          isComplete={isCompleted}
          todoDetails={todoDetails}
          setTodoDetails={setTodoDetails}
          values={values}
          setValues={setValues}
          isAdvanceClear={isAdvanceClear}
          setIsAdvanceClear={setIsAdvanceClear}
          taskCat={taskCat}
          departmentListingResponse={departmentListingResponse}
          remainderListingResponse={remainderListingResponse}
          fixedTaskCategory={fixedTaskCategory}
          url={url}
          setUrl={setUrl}
          changeCategory={changeCategory}
          setChangeCategoryButton={setChangeCategoryButton}
          setSelectedTaskTos={setSelectedTaskTos}
          setSelectedTaskCcs={setSelectedTaskCcs}
        />
        {/* <TaskPrivateList
         
         editToDoList={editToDoList}
         getData={getData}
         pageSize={pageSize}
         todoEventsLoading={todoEventsLoading}
         organizerEventPriorityListing={organizerEventPriorityListing}
         allEventStatusListing={allEventStatusListing}
         referenceId={referenceId}
         taskAssignedTo={taskAssignedTo}
         showTaskFormDrawer={showTaskFormDrawer}
         todoRowEventHandler={todoRowEventHandler}
         statusTodo={statusTodo}
         // handleChangeEvent={handleChangeEvent}
         showAddTask={showAddTask}
         setShowAddTask={setShowAddTask}
         searchEmailTender={searchEmailTender}
         showChat={showChat}
         setShowChat={setShowChat}
         taskId={taskId}
         setHideInternalMessage={setHideInternalMessage}
         setTaskId={setTaskId}
         setSmallChatHeight={setSmallChatHeight}
         setChatHeight={setChatHeight}
         setchatBox={setchatBox}
         /> */}

        {/* <TaskChat setShowChat={setShowChat} /> */}
        {hideInternalMessage ? (
          <Drawer
            title="Task Module"
            placement="right"
            onClose={closeDrawer}
            visible={hideInternalMessage}
            className="task-drawer"
            width={543}
          >
            <ChatDetails
              isPrivate={props.isPrivate}
              setShowChat={setShowChat}
              setHideInternalMessage={setHideInternalMessage}
              hideInternalMessage={hideInternalMessage}
              taskId={toShowKanbanData ? toShowKanbanData?.id : taskId}
              setTaskId={setTaskId}
              todoDetails={toShowKanbanData ? toShowKanbanData : todoDetails}
              handleChangeEvent={handleChangeEvent}
              setSmallChatHeight={setSmallChatHeight}
              setChatHeight={setChatHeight}
              chatHeight={chatHeight}
              smallChatHeight={smallChatHeight}
              setchatBox={setchatBox}
              chatBox={chatBox}
              module="Task"
              selectedTaskTos={selectedTaskTos}
              selectedTaskCcs={selectedTaskCcs}
            />
          </Drawer>
        ) : null}
      </div>
      <CreateEventDrawer
        isPrivate={props.isPrivate}
        visibleEventFormDrawer={visibleEventFormDrawer}
        eventFormSelect={eventFormSelect}
        onCloseEventFormDrawer={onCloseEventFormDrawer}
        getData={getData}
        todoDetails={toShowKanbanData ? toShowKanbanData : todoDetails}
        taskAssignedTo={taskAssignedTo}
        largeWidth={largeWidth}
        setLargeWidth={setLargeWidth}
        visitor={visitor}
        setVisitor={setVisitor}
        form={formToDo}
        changeCategory={changeCategory}
        setChangeCategoryButton={setChangeCategoryButton}
      />

      <Modal
        title="Advance Task Search"
        visible={showAdvanceSearchModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        wrapClassName="advance_search_email"
      >
        <Form
          form={form2}
          labelAlign="left"
          colon={false}
          onFinish={onFinishAdvanceSearch}
          autocomplete="off"
          {...layout}
        >
          <Form.Item name="task" label="Task Name">
            <Input />
          </Form.Item>

          <Form.Item name="refkeyword" label="Reference">
            <Select
              showSearch
              placeholder="Select reference"
              optionFilterProp="label"
              allowClear={true}
              onSearch={(value) => {
                setSearchValueReference(value);
              }}
              onChange={(value, option) =>
                setSelectedReferenceType(option?.label[2])
              }
              autoComplete="off"
            >
              {!isEmpty(tasksUsedReference) &&
                tasksUsedReference?.map((reference, index) => {
                  return (
                    <Option
                      value={reference.id}
                      key={index}
                      label={[reference.code, reference.title]}
                    >
                      <div className="demo-option-label-item">
                        <div>
                          {reference.code} {""}
                        </div>
                        <div>{reference.title}</div>
                      </div>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item name="watcher" label="Watcher">
            <Select
              mode="single"
              showSearch
              placeholder="Watcher"
              optionFilterProp="label"
              allowClear={true}
              autoComplete="off"
            >
              {!isEmpty(getAllUsersListResponse) &&
                getAllUsersListResponse.map((employee, index) => {
                  return (
                    <Option
                      value={employee.userName}
                      title={employee.fullName}
                      key={index}
                      label={[employee.fullName, employee.userName]}
                    >
                      {/* {employee.fullName} */}
                      <div className="demo-option-label-item">
                        <span role="img">{employee.fullName} </span>[
                        {employee.userName.toUpperCase()}]
                      </div>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item name="asignee" label="Asignee">
            <Select
              mode="single"
              showSearch
              placeholder="Asignee"
              optionFilterProp="label"
              allowClear={true}
              autoComplete="off"
            >
              {!isEmpty(getAllUsersListResponse) &&
                getAllUsersListResponse.map((employee, index) => {
                  return (
                    <Option
                      value={employee.userName}
                      title={employee.fullName}
                      key={index}
                      label={[employee.fullName, employee.userName]}
                    >
                      {/* {employee.fullName} */}
                      <div className="demo-option-label-item">
                        <span role="img">{employee.fullName} </span>[
                        {employee.userName.toUpperCase()}]
                      </div>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item name="dates" label="Enter Date">
            <RangePicker
            // onChange={(value) => {
            //   setStartDate(
            //     value ? moment(value[0]).format("YYYY-MM-DD") : ""
            //   );
            //   setEndDate(value ? moment(value[1]).format("YYYY-MM-DD") : "");
            // }}
            />
          </Form.Item>

          <Row
            style={{
              justifyContent: "right",
              paddingTop: "10px",
              marginTop: "20px !important",
              gap: "10px",
            }}
          >
            <Button
              type="ghost"
              shape="round"
              autoComplete="off"
              // onClick={clearAdvanceForm}
              onClick={() => {
                form2.resetFields();
              }}
              // disabled={advanceButtonDisabled}
            >
              Remove
            </Button>

            <Button
              type="ghost "
              shape="round"
              htmlType="submit"
              // loading={contactPersonPaginationLoading}
              autoComplete="off"
              // disabled={advanceButtonDisabled}
              //  onClick={() => {
              //   form.resetFields();
              // }}
            >
              Search
            </Button>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ToDo;
