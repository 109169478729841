import React, { useEffect } from "react";

import { HashRouter as Router, Route, Redirect } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";

import StoreProvider from "./services/store/StoreProvider";
import LoadingView from "./components/shared/LoadingView";
import { actions } from "services/redux/app/app.action";
import { loadInitialSettings } from "services/redux/settings/settings.action";

import Alert from "./components/shared/Alert";
import { decodedToken } from "./utils/misc";
import { reauthenticate } from "services/redux/auth/auth.action";
import setAuthToken from "./utils/setAuthToken";
import "rc-color-picker/assets/index.css";
import "tippy.js/dist/tippy.css";
import { logoutUser } from "./services/redux/auth/auth.action";
import ContextProvider from "context/ContextProvider";

import Routes from "./Routes";

if (sessionStorage.token) {
  setAuthToken(sessionStorage.token);
}

export const AuthRoute = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const onlyChild = React.Children.only(children);

  useEffect(() => {
    if (token) {
      const decodedTokenData = decodedToken(token);
      var current_time = new Date().getTime() / 1000;
      if (current_time > decodedTokenData.exp) {
        dispatch(reauthenticate(token));
      }
    }
  }, [sessionStorage.token]);

  const responseSuccessHandler = (response) => {
    return response;
  };

  const responseErrorHandler = (error) => {
    if (error?.response?.status === 401) {
      //|| error.response.status === 403
      dispatch(logoutUser());
    }

    return Promise.reject(error);
  };

  Axios.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          React.cloneElement(onlyChild, { ...rest, ...props })
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default function App() {
  const ContentWrapper = ({ children }) => {
    // const isDarkTheme = useSelector(({ settings }) => settings.isDarkTheme);
    return (
      // <div cl/assName={`content-wrapper ${isDarkTheme ? "dark" : "light"}`}>
      <>
        <Alert />
        {children}
      </>
      // </div>
    );
  };

  function MainApp() {
    const dispatch = useDispatch();
    const isOnline = useSelector(({ app }) => app.isOnline);
    const token = useSelector(({ auth }) => auth.token);

    useEffect(() => {
      dispatch(loadInitialSettings());
      const unsubFromConnection = dispatch(actions.listenToConnectionChanges());

      return () => {
        unsubFromConnection();
      };
    }, [dispatch]);

    // if (!isOnline) {
    //   return (
    //     <LoadingView message="Application has been disconnected from the internet. Please reconnect..." />
    //   );
    // }

    return (
      <Router>
        <ContentWrapper>
          <Routes token={token} />
        </ContentWrapper>
      </Router>
    );
  }

  return (
    <StoreProvider>
      <ContextProvider>
        <MainApp />
      </ContextProvider>
    </StoreProvider>
  );
}
