import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../commonServices";
import CalenderFilter from "../../../json/CalendarFilter.json";
import queryString from "query-string";
export class CalendarEventsService {
  getCalenderListEvents(query) {
    let url = `${appBaseUrl}/organizerEvent/calendarEventList?${query}`;
    let data = getService(url);
    return data;
  }

  getAllPrivateEvents(query) {
    const query1 = queryString.stringify(query);
    let url = `${appBaseUrl}/organizerEvent/calendarEventList?${query1}`;
    let data = getService(url);
    return data;
  }

  getAllPublicEvents(query) {
    const query1 = queryString.stringify(query, { arrayFormat: "repeat" });
    let url = `${appBaseUrl}/organizerEvent/calendarEventList?${query1}`;
    let data = getService(url);
    return data;
  }

  getCalenderFilters() {
    let url = CalenderFilter;
    return { isSuccess: true, data: url };
  }

  updateCalenderListEvent(body) {
    let url = `${appBaseUrl}/organizerEvent/calendarEventUpdateEvent`;
    let data = postService(url, body);
    return data;
  }
  // /api/app/organizerEvent/calendarEventListPagination

  calendarEventPagination(query) {
    let url = `${appBaseUrl}/organizerEvent/calendarEventListPagination?${query}`;
    let data = getService(url);
    return data;
  }
  englishToNepaliDateRangeConverter(firstDate, lastDate) {
    let url = `${appBaseUrl}/nepaliDateConveter/converteNepaliRangeDate?firstDate=${firstDate}&lastDate=${lastDate}`;
    let data = getService(url);
    return data;
  }
}
