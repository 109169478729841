import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "antd";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { tenderGetBasicROTODetails } from "services/redux/Reference/Tender/action";
import AllFileViewer from "components/Documents/allFileViewer";
import { cqBaseUrl } from "utils/config";

export default function RotoModal({ id }) {
  const [rotoId, setRotoId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const {
    tenderBasicGetDetailsRotoLoading,
    tenderBasicGetDetailsRotoResponse,
  } = useSelector((state) => state.tender);

  const handleOk = () => {
    setIsModalOpen(false);
    setRotoId("");
  };

  useEffect(() => {
    if (!isEmpty(rotoId)) {
      dispatch(tenderGetBasicROTODetails(rotoId));
    }
  }, [rotoId]);

  const col = [
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
    },
    {
      title: "Short Listed",
      dataIndex: "shortListed",
      key: "shortListed",
    },
    {
      title: "Financial Score",
      dataIndex: "financialScore",
      key: "financialScore",
    },
    {
      title: "Technical Score",
      dataIndex: "technicalScore",
      key: "technicalScore",
    },

    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Bid Price",
      dataIndex: "bidPrice",
      key: "bidPrice",
    },
    {
      title: "JV Company",
      dataIndex: "jvPartnerCompanyDtos",
      key: "jvPartnerCompanyDtos",
    },
    {
      title: "Sub Contract",
      dataIndex: "subContractCompanyDtos",
      key: "subContractCompanyDtos",
    },
  ];

  return (
    <div>
      <Button
        type="button"
        onClick={() => {
          setIsModalOpen(!isModalOpen);
          setRotoId(id);
        }}
      >
        Open Roto
      </Button>
      {isModalOpen ? (
        <Modal
          title="Roto Participation"
          visible={isModalOpen}
          width={800}
          onOk={handleOk}
          onCancel={handleOk}
          footer={null}
        >
          {tenderBasicGetDetailsRotoResponse?.tenderStageLevelDtos &&
            tenderBasicGetDetailsRotoResponse?.tenderStageLevelDtos.map(
              (outerItem, index) => (
                <div key={index}>
                  {outerItem?.stageLevel}
                  {outerItem?.tenderStageROTOFileList.map((file) => (
                    <>
                      <AllFileViewer
                        file={file}
                        cqBaseUrl={cqBaseUrl}
                        token={token}
                        height={600}
                        width={900}
                      />
                    </>
                  ))}
                </div>
              )
            )}

          <Table
            className=""
            loading={tenderBasicGetDetailsRotoLoading}
            columns={col}
            dataSource={
              tenderBasicGetDetailsRotoResponse?.tenderParticipationDtos
            }
          />
        </Modal>
      ) : null}
    </div>
  );
}
