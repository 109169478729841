import { rootBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService } from "../../../commonServices";

export class RoleService {
  getAllRoles() {
    // let url = `${rootBaseUrl}/identity/roles/all`;
    let url = `${rootBaseUrl}/app/employeeRequest/appRoleAll`;
    let data = getService(url);
    return data;
  }

  addNewRole(body) {
    let url = `${rootBaseUrl}/identity/roles`;
    let data = postService(url, body);
    return data;
  }

  updateRole(body, id) {
    let url = `${rootBaseUrl}/identity/roles/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  // new update api working
  newUpdateRole(body, id) {
    let url = `${rootBaseUrl}/app/employeeRequest/appRoleUpdate`;
    let data = postService(url, body, "put");
    return data;
  }

  getPermissionListsForRole(roleName) {
    let url = `${rootBaseUrl}/app/permissionManagement/permissionDetailsListForRole?roleName=${roleName}`;
    let data = getService(url);
    return data;
  }

  grantOrProhibitPermissionForRole(q) {
    let query = queryString.stringify(q);
    let url = `${rootBaseUrl}/app/permissionManagement/grantOrProhibitPermissionForRole?${query}`;
    let data = postService(url, {});
    return data;
  }

  // appointmentMarkAsComplete(id) {
  //   let url = `${rootBaseUrl}/organizerEvent/appointmentEventMarkItOff/${id}`;
  //   let data = postService(url, '', 'patch');
  //   return data;
  // }
}
