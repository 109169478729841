import { Col, Row } from "antd";
import React from "react";

const LoanBasicDetails = ({ loanApplicationDataGetDetails }) => {
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={8}>Company Name:</Col>
        <Col span={16}>
          {loanApplicationDataGetDetails?.sisterCompanyDto?.name}
        </Col>
        <Col span={8}>Person Name:</Col>
        <Col span={16}>
          {
            loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
              ?.fromList?.[0]?.participantName
          }
        </Col>
        <Col span={8}>Amount Requested:</Col>
        <Col span={16}>
          {loanApplicationDataGetDetails?.requestedAmount?.toLocaleString()}
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Loan Reason:</Col>
        <Col span={16}>{loanApplicationDataGetDetails?.reason}</Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={8}>Description:</Col>
        <Col span={16}>{loanApplicationDataGetDetails?.description}</Col>
      </Row>
    </>
  );
};

export default LoanBasicDetails;
