import { appBaseUrl } from "utils/config";
import { getService } from "../../../commonServices";

export class EmployeeSisterCompanyService {
  getEmployeeSisterCompanyListing() {
    let url = `${appBaseUrl}/organizerEvent/employeeSisterCompanyListing`;
    let data = getService(url);
    return data;
  }

  // addGoalEvent(body) {
  //   let url = `${appBaseUrl}/organizerEvent/goalEventAdd`;
  //   let data = postService(url, body);
  //   return data;
  // }

  // updateGoalEvent(body, id) {
  //   let url = `${appBaseUrl}/organizerEvent/goalEventUpdate/${id}`;
  //   let data = postService(url, body, 'put');
  //   return data;
  // }
}
