import { Button, Form, Input, Layout } from "antd";
import React from "react";

const SettlementSupervisorInputForm = ({
  commentName,
  loading,
  onClickRecommend,
  onClickReject,
}) => {
  return (
    <Layout.Content
      style={{
        backgroundColor: "#FAFAFA",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        marginTop: 10,
      }}
    >
      <Form.Item label="Supervisor's Comment" name={commentName}>
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 6 }}
          autoComplete="off"
          placeholder="Place Your Comment Here..."
        />
      </Form.Item>
      <Layout.Content
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <Button
          loading={loading}
          htmlType="submit"
          onClick={onClickRecommend}
          style={{
            backgroundColor: "#3879C5",
            color: "white",
            borderRadius: 4,
            marginLeft: 5,
          }}
        >
          Recommend
        </Button>
        <Button
          htmlType="submit"
          onClick={onClickReject}
          style={{
            backgroundColor: "#C53D38",
            color: "white",
            borderRadius: 4,
          }}
        >
          Reject
        </Button>
      </Layout.Content>
    </Layout.Content>
  );
};

export default SettlementSupervisorInputForm;
