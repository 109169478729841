import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Layout, message } from 'antd';
import { contactCompanyNoteUpdate } from 'services/redux/contact/companypeople/action';
import EditNotes from './EditNotes';
import { contactPersonNoteUpdate } from 'services/redux/contact/companypeople/action';

const { Header, Footer } = Layout;
const CheckMark = 'assets/svg/checkmark-24.svg';
const cancel = 'assets/svg/cross-24.svg';

const CompanyNoteDrawer = ({ onAddUpdateClose, personResponseNotes, responseNotes, drawerVisibility, windowWidth }) => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (responseNotes) {
			form.setFieldsValue({
				...responseNotes,
				notes: responseNotes.notes,
			});
		} else if (personResponseNotes) {
			form.setFieldsValue({
				...personResponseNotes,
				notes: personResponseNotes.notes,
			});
		}
	}, [responseNotes, personResponseNotes]);
	const [form] = Form.useForm();

	const onSubmit = async () => {
		try {
			let values = await form.validateFields();
			if (responseNotes) {
				dispatch(contactCompanyNoteUpdate(responseNotes.id, values));
			} else if (personResponseNotes) {
				dispatch(contactPersonNoteUpdate(personResponseNotes.id, values));
			}
		} catch (errorInfo) {
			console.error('Failed:', errorInfo);
		}
	};

	return (
		// <Drawer className="drawer" visible={drawerVisibility} width={300}>
		// 	<Header className="drawer__header">
		// 		<h4 className="drawer__header--title">Notes</h4>
		// 	</Header>
		// 	<EditNotes />
		// </Drawer>
		<Drawer visible={drawerVisibility} width={windowWidth < 780 ? 300 : 400} className="drawer" onClose={onAddUpdateClose} closable={false}>
			<div className="contactsDetails__infonotes" style={{ padding: '0 10px 0 10px' }}>
				<div className="contactDetails__label--infoNotes" style={{ display: 'flex', justifyContent: 'space-between', padding: '0px, 5px' }}>
					<span className="contactsDetails--title bold w-100 contactsDetails--title">Notes </span>
					<div className="contactDetails__button">
						<div>
							<img
								src={CheckMark}
								onClick={() => {
									onSubmit();
								}}
							/>
						</div>
						<div>
							<img
								src={cancel}
								onClick={() => {
									onAddUpdateClose();
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Form layout="vertical" form={form} name="control-hooks">
					<Form.Item name="notes" rules={[{ required: false }]}>
						<Input.TextArea bordered={false} />
					</Form.Item>
				</Form>
			</div>
		</Drawer>
	);
};

export default CompanyNoteDrawer;
