import { useState, useEffect } from "react";
import { Form, Button, Col, Row, Input, Select, message, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { validateMessages } from "utils/misc";
import { PlusOutlined } from "@ant-design/icons";
import { projectAdd } from "services/redux/Reference/Project/action";
import {
  sectorListing,
  statusListing,
  modalityListing,
} from "services/remanentCalls";
import { allCountryListing } from "services/redux/contact/contact.action";
import { allCompanyListing } from "services/redux/Reference/action";

const { Option } = Select;

export const AddProject = ({ onAddUpdateTenderClose, drawerVisibility }) => {
  // const [setForm, setOpenForm] = useState(false);
  // const [showForm, setForm] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [status, setStatus] = useState([]);
  const [modality, setModality] = useState([]);

  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchAllSector();
  }, []);
  useEffect(() => {
    dispatch(allCountryListing());
    dispatch(allCompanyListing());
  }, [dispatch]);

  useEffect(() => {
    fetchAllModality();
    fetchAllStatus();
  }, []);
  const { allCountryListingResponse } = useSelector((state) => state.contact);
  const { allCurrencyListingResponse } = useSelector(
    (state) => state.reference
  );

  const fetchAllSector = async () => {
    const resp = await sectorListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setSectors(resp.data);
    }
  };
  const fetchAllStatus = async () => {
    const resp = await statusListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setStatus(resp.data);
    }
  };
  const fetchAllModality = async () => {
    const resp = await modalityListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setModality(resp.data);
    }
  };

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      // setShowProject(true);
      const decimal = ".00";
      let body = {
        title: values.title,
        projectStatusId: values.projectStatusId,
        description: values.description,
        clientId: values.clientId,
        modalityId: values.modalityId,
        projectCost: values.projectCost.concat(decimal),
        currencyId: values.currencyId,
        overview: values.overview,
        technicalFeatures: values.technicalFeatures,
        projectSectorDtos: values.projectSectorDtos.map((value) => {
          return {
            sectorId: value,
          };
        }),
        projectFundingAgencyDtos: values.projectFundingAgencyDtos.map(
          (value) => {
            return {
              projectFundingAgencyId: value,
            };
          }
        ),
      };
      dispatch(projectAdd(body));
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <div>
      <div className="profiledetails">
        <div className="profiledetails__wrapper">
          <div className="reference__head">
            <Button
              type="link"
              className="btn-cancel__cancelref"
              onclick={() => {
                // setShowProject(true);
              }}
            >
              Cancel
            </Button>
            <Button className="btn-update__updateref" onClick={onSubmit}>
              Submit
            </Button>
          </div>
          <Form
            layout="vertical"
            form={form}
            name="control-hooks"
            validateMessages={validateMessages}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <div className="profiledetails__main">
                  <Collapse
                    accordion
                    expandIconPosition="right"
                    defaultActiveKey={["1"]}
                  >
                    <Panel header="Basic" key="1">
                      <div className="profiledetails__wrapper--reference">
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">Sector</span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <Form.Item
                              name="projectSectorDtos"
                              rules={[{ required: false }]}
                            >
                              <Select
                                showSearch={true}
                                mode="multiple"
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                notFoundContent={
                                  <div>
                                    <div>
                                      <Button
                                        className="btn-primary"
                                        // onClick={(e) =>
                                        // 	showAddUpdateSectorDrawer(e)
                                        // }
                                      >
                                        <PlusOutlined /> Add Sector
                                      </Button>
                                    </div>
                                  </div>
                                }
                              >
                                {sectors.map((data) => (
                                  <Option key={data.id} value={data.id}>
                                    {data.sectorName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">Title</span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <Form.Item
                              name="title"
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Input size="small" placeholder="Title" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">Status</span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <Form.Item
                              name="projectStatusId"
                              rules={[{ required: true }]}
                            >
                              <Select
                                showSearch={true}
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {status.map((data) => (
                                  <Option key={data.id} value={data.id}>
                                    {data.status}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">Client</span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <Form.Item
                              name="clientId"
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Select
                                showSearch={true}
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {allCountryListingResponse?.map((country) => (
                                  <Option value={country.id} key={country.id}>
                                    {country.companyName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">
                              Description
                            </span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <Form.Item
                              name="description"
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Input.TextArea
                                size="small"
                                placeholder="Description"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">Modality</span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <Form.Item
                              name="modalityId"
                              rules={[{ required: false }]}
                            >
                              <Select
                                showSearch={true}
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {modality.map((modalities) => (
                                  <Option
                                    key={modalities.id}
                                    value={modalities.id}
                                  >
                                    {modalities.title}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">
                              Funding Agency
                            </span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <Form.Item
                              name="projectFundingAgencyDtos"
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Select
                                showSearch={true}
                                mode="multiple"
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {allCountryListingResponse?.map((country) => (
                                  <Option value={country.id} key={country.id}>
                                    {country.companyName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">
                              Project Cost
                            </span>
                          </div>
                          <div
                            className="profiledetails__rowedit--data"
                            style={{ display: "flex" }}
                          >
                            <span
                              className="contactsDetails--subtitle bold"
                              style={{ width: "34%" }}
                            >
                              <Form.Item
                                name="currencyId"
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}
                              >
                                <Select
                                  allowClear
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {allCurrencyListingResponse?.map(
                                    (currency) => (
                                      <Option
                                        key={currency.currencyId}
                                        value={currency.currencyId}
                                      >
                                        {currency.abbvr}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                            </span>
                            <span
                              className="contactsDetails--subtitle bold"
                              style={{ width: "66%" }}
                            >
                              <Form.Item
                                name="projectCost"
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}
                              >
                                <Input size="small" />
                              </Form.Item>
                            </span>
                          </div>
                        </div>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">Overview</span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <Form.Item
                              name="overview"
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Input.TextArea
                                size="small"
                                placeholder="OverView"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">
                              Technical Features
                            </span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <Form.Item
                              name="technicalFeatures"
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Input.TextArea
                                size="small"
                                placeholder="Techncial Features"
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </Col>

              <Col span={24}>
                <Form.Item
                  className="form__group"
                  name="note"
                  label="Notes"
                  rules={[{ required: false }]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>

              {/* <Col span={8}>
									<Form.Item className="form__group" name="scopeOfWork" label="Scope of Work" rules={[{ required: false }]}>
										<Select mode="tags" onSelect={addScopeofWork} style={{ width: '100%' }}>
											{scopeTags.map((scopetag) => (
												<Option key={scopetag.name}>{scopetag.name}</Option>
											))}
										</Select>
									</Form.Item>
								</Col> */}
            </Row>

            <Row gutter={[8, 0]}></Row>
          </Form>
        </div>
      </div>
    </div>
  );
};
