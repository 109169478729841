import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Tag, message } from "antd";
import moment from "moment";
import { LogoImage } from "utils/imageConstants";
import { honorRollsPagination } from "services/redux/officeresource/honorroll/action";
import HonorCard from "./HonorCard";
import { honorRollGetDetails } from "../../../services/remanentCalls";
import { isEmpty } from "lodash";

const CityAddUpdateTable = ({
  showAddUpdateCountryDrawer,
  startDate,
  endDate,
}) => {
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      render: (text, row, index) => <div>{index + 1}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
    },
    {
      title: "Start Date",
      key: "startDate",
      render: (record) => moment(record.startDate).format("YYYY/MM/DD"),
    },
    {
      title: "End Date",
      key: "endDate",
      render: (record) => moment(record.endDate).format("YYYY/MM/DD"),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },

    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a
          className="action-btn action-btn-add"
          onClick={(e) => showAddUpdateCountryDrawer(e, record)}
        >
          Edit Honor Roll
        </a>
      ),
    },
  ];

  const rowEventhandler = (record) => {
    return {
      onClick: async () => {
        const resp = await honorRollGetDetails(record.id).catch((err) =>
          message.error("Failure")
        );

        if (resp) {
          setCardData(resp.data);
        }
      },
    };
  };

  const dispatch = useDispatch();
  const { honorRollsPaginationResponse, honorRollsPaginationLoading } =
    useSelector((state) => state.honorRoll);

  const [data, setdata] = useState([]);
  const [cardData, setCardData] = useState({});
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (!isEmpty(honorRollsPaginationResponse?.items)) {
      setdata(honorRollsPaginationResponse.items);
      if (honorRollsPaginationResponse.items) {
        const fetchDetails = async () => {
          const resp = await honorRollGetDetails(
            honorRollsPaginationResponse.items[0].id
          ).catch((err) => message.error("Failure"));

          if (resp) {
            setCardData(resp.data);
          }
        };
        fetchDetails();
      }

      setTotalCount(honorRollsPaginationResponse.totalCount);
    } else {
      setdata([]);
    }
  }, [honorRollsPaginationResponse]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType,
    SearchDateFrom = startDate,
    SearchDateTo = endDate
  ) => {
    dispatch(
      honorRollsPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
        SearchDateFrom,
        SearchDateTo,
      })
    );
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting =
      sorter.order === "ascend"
        ? "subject"
        : sorter.order === "descend"
        ? "subject"
        : undefined;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };

  return (
    <>
      <Table
        onRow={rowEventhandler}
        className="organizer__tables"
        columns={columns}
        rowKey="appUserId"
        dataSource={data}
        onChange={handleTableChange}
        // loading={honorRollsPaginationLoading}
        loading={{
          indicator: <img src={LogoImage} height="auto" width="50px" />,
          spinning: honorRollsPaginationLoading,
        }}
        pagination={{
          total: totalCount,
          pageSize: pageSize,
        }}
      />
      <HonorCard cardData={cardData} />
    </>
  );
};

export default CityAddUpdateTable;
