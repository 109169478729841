import React from "react";
const pdfViewer1 = (url, size) => {
  const pdfViewer = document.getElementById("pdfViewerFrame");

  fetch(url, {
    headers: {
      responseType: "blob",
    },
  })
    .then((res) => res.blob())
    .then((file) => {
      pdfViewer.src = URL.createObjectURL(file);
      pdfViewer.style.width = "100%";
      pdfViewer.style.height = size !== null || undefined ? `${size}` : "500";
      // pdfViewer.style.height =
      //   size !== null && size !== undefined ? `${size}` : "500px";
    });
};

export default pdfViewer1;
