import jwtDecode from "jwt-decode";
import Storage from "utils/storage";
import moment from "moment";

export const jsonParser = (content) => {
  try {
    return JSON.parse(content);
  } catch (ex) {
    return {};
  }
};

export const isTokenExpired = (token) => {
  if (typeof window == "undefined") {
    return false;
  }

  if (token) {
    const { exp } = jwtDecode(token);

    if (exp < (new Date().getTime() + 1) / 1000) {
      return true;
    }
    return false;
  } else {
    return true;
  }
};

export const decodedToken = (token) => {
  if (typeof window == "undefined") {
    return false;
  }

  if (token) {
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp > (new Date().getTime() + 1) / 1000) {
      return decodedToken;
    }
    return false;
  } else {
    return false;
  }
};

export const renderNameOrNA = (name) => name || "N/A";

export const isAdmin = () => {
  const role = Storage.getItem("role");
  if (!role) return;

  if (role === "admin") {
    return true;
  }

  return false;
};

export const validateMessages = {
  required: "${label} is required!",
  whitespace: "${label} can not be empty!",
  types: {
    email: "${label} is not valid!",
  },
};

export const requiredRule = [
  {
    required: true,
    whitespace: true,
  },
];

export function getDayDiff(endDate, startDate) {
  let parsedEndDate = moment(endDate).format("YYYY-MM-DDTHH:mm:00.000") + "Z";
  let parsedStartDate =
    moment(startDate).format("YYYY-MM-DDTHH:mm:00.000") + "Z";
  let diff = moment(parsedEndDate).diff(parsedStartDate);
  let days = moment.duration(diff).days();
  return days;
}