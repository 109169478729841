import { uniqBy } from "lodash-es";
import { SOW_TYPES } from "../../../types";

const INITIAL_STATE = {
  sowDetails: {
    items: [],
    totalCount: 0,
  },

  subSectorsListingResponse: [],
  subSectorsListingLoading: false,
};

export default function businessCenterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Get All SowDetails
    case SOW_TYPES.SOW_TYPE_INIT:
      return {
        ...state,
        sowDetailsLoading: true,
      };
    case SOW_TYPES.SOW_TYPE_SUCCESS:
      return {
        ...state,
        sowDetails: {
          items: action.loadMore
            ? uniqBy(state.sowDetails.items.concat(action.payload.item2), "id")
            : action.payload.item2,
          totalCount: action.payload.item1,
        },
        sowDetailsLoading: false,
      };
    case SOW_TYPES.SOW_TYPE_FINISH:
      return {
        ...state,
        sowDetailsLoading: false,
      };
    case SOW_TYPES.ALL_SECTORS_LISTING_INIT:
      return {
        ...state,
        allSectorsListingResponse: [],
        allSectorsListingLoading: false,
      };
    case SOW_TYPES.ALL_SECTORS_LISTING_SUCCESS:
      return {
        ...state,
        allSectorsListingResponse: action.payload,
        allSectorsListingLoading: false,
      };
    case SOW_TYPES.ALL_SECTORS_LISTING_FINISH:
      return {
        ...state,
        allSectorsListingLoading: false,
      };

    // SUB SECTORS
    case SOW_TYPES.SUB_SECTORS_LISTING_INIT:
      return {
        ...state,
        subSectorsListingLoading: true,
        subSectorsListingResponse: [],
      };

    case SOW_TYPES.SUB_SECTORS_LISTING_SUCCESS:
      return {
        ...state,
        subSectorsListingLoading: false,
        subSectorsListingResponse: action.payload,
      };

    case SOW_TYPES.SUB_SECTORS_LISTING_FINISH:
      return {
        ...state,
        subSectorsListingLoading: false,
      };

    case SOW_TYPES.ALL_STATUS_LISTING_INIT:
      return {
        ...state,
        allStatusListingResponse: [],
        allStatusListingLoading: false,
      };
    case SOW_TYPES.ALL_STATUS_LISTING_SUCCESS:
      return {
        ...state,
        allStatusListingResponse: action.payload,
        allStatusListingLoading: false,
      };
    case SOW_TYPES.ALL_STATUS_LISTING_FINISH:
      return {
        ...state,
        allStatusListingLoading: false,
      };
    case SOW_TYPES.ALL_MODALITY_LISTING_INIT:
      return {
        ...state,
        allModalityListingResponse: [],
        allModalityListingLoading: false,
      };
    case SOW_TYPES.ALL_MODALITY_LISTING_SUCCESS:
      return {
        ...state,
        allModalityListingResponse: action.payload,
        allModalityListingLoading: false,
      };
    case SOW_TYPES.ALL_MODALITY_LISTING_FINISH:
      return {
        ...state,
        allModalityListingLoading: false,
      };
    default:
      return { ...state };
  }
}
