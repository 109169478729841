import React, { useEffect, useState } from "react";
import { isEmpty, cloneDeep, upperCase } from "lodash";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { map } from "lodash";
import TinyColor2 from "tinycolor2";
import {
  getTodoEventsKanban,
  toDoGetDetails,
} from "services/redux/organizer/todoLists/action";
import { getAllUsers } from "services/remanentCalls";
import {
  SaveOutlined,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  addToDoEvent,
  updateToDoEvent,
} from "services/redux/organizer/todoLists/action";
import storage from "utils/storage";
import { mailReferenceListing } from "services/redux/email/mailMessageCenter/action";
import { getReferenceListing } from "services/redux/Reference/action";
// antd
import { cqBaseUrl } from "utils/config";
import {
  Select,
  Popover,
  Switch,
  Pagination,
  Row,
  Col,
  Table,
  Tooltip,
  Button,
  Form,
  Input,
  Radio,
  DatePicker,
  Space,
  Image,
  Modal,
  Spin,
  Card,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { MoreOutlined, FilterOutlined } from "@ant-design/icons";

// constants
import { LogoImage } from "utils/imageConstants";
import { toDoMarkAsComplete } from "../../../services/redux/organizer/todoLists/action";

// redux
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { current } from "@reduxjs/toolkit";

import { BlockMapBuilder } from "draft-js";

const edit = "./assets/svg/profile-edit.svg";
const filter = "./assets/svg/filterstroke-16.svg";
const internalMessageGreen = "assets/svg/internal-msg-12-green.svg";

const { Option } = Select;
const ToDoLists = (props) => {
  const dispatch = useDispatch();
  const toDoEventsState = useSelector((state) => state.toDoEvents);
  const [RecordRow, setRecord] = useState([]);
  const { toDoGetDetailsResponse } = useSelector((state) => state.toDoEvents);
  const { addTodoEventsLoading, addTodoEventsKanban } = useSelector(
    (state) => state.toDoEvents
  );
  const [meetingInvitees, setMeetingInvitees] = useState();
  const [todoEventCandidateDtos, setTodoEventCandidateDtos] = useState([]);
  const [searchValueReference, setSearchValueReference] = useState("");
  const [filterParticipantType, setFilterParticipantType] = useState("");
  const [referenceListing, setReferenceListing] = useState([]);
  const [filterReference, setFilterReference] = useState([]);
  const { toDoMarkItOffResp } = toDoEventsState;
  const { token } = useSelector((state) => state.auth);

  const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);

  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [selectedPriorityStatusId, setSelectedPriorityStatusId] = useState("");
  const [selectedProgressStatusId, setSelectedProgressStatusId] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [sortType, setSortType] = useState("");
  const [viewList, setViewList] = useState([]);

  const [hideCompleted, setHideCompleted] = useState([]);
  const [isComplete, setIsComplete] = useState(0);
  const [hideButton, setHideButton] = useState(false);
  const [fitlerReferenceId, setFilterReferenceId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [visibleEventFormDrawer, setVisibleEventFormDrawer] = useState(false);
  const [todoDetails, setTodoDetails] = useState([]);
  const [boardView, setBoardView] = useState(false);

  const { allToDoEvents, todoEventsLoading } = toDoEventsState;

  const {
    // data,
    pageSize,
    // currentPage,
    // setCurrentPage,
    organizerEventPriorityListing,
    allEventStatusListing,
    editToDoList,
    gettaskTodoList,
    // handleTableChange,
    taskAssignedTo,
    referenceId,
    searchEmailTender,
    todoRowEventHandler,
    statusTodo,
    // handleChangeEvent,
    showAddTask,
    setShowAddTask,
    showChat,
    setShowChat,
    setHideInternalMessage,
    setChatHeight,
    setSmallChatHeight,
    setchatBox,
    // hideInternalMessage,
    setTaskId,
    taskId,
  } = props;
  useEffect(() => {
    props.getData(
      pageSize,
      0,
      isComplete,
      selectedStatusId,
      hideCompleted,
      orderBy,
      sortType,
      selectedPriorityStatusId,
      selectedProgressStatusId,
      taskAssignedTo,
      referenceId
    );
  }, []);
  const location = useLocation();
  const getTaskIdFromUrl = queryString.parse(location.search)?.task || "";

  const setTaskClassName = (record) => {
    return record.id === props.taskId ? "clickRowStyl" : "";
  };
  const onCloseEventFormDrawer = () => {
    setVisibleEventFormDrawer(false);
    setTodoDetails("");
  };

  useEffect(() => {
    if (!isEmpty(getTaskIdFromUrl)) {
      dispatch(toDoGetDetails(getTaskIdFromUrl));
      setHideInternalMessage(true);
      setShowChat(true);
      setSmallChatHeight(false);
      setChatHeight(false);
      setchatBox(false);
    }
  }, []);

  useEffect(() => {
    window.location.hash = "/todoLists";
  }, [toDoGetDetailsResponse]);

  const columns = [
    {
      // title: () => {
      //   return (
      //     <>
      //       <span>Task</span>
      //       <Select
      //         bordered={false}
      //         placeholder={<FilterOutlined />}
      //         dropdownRender={() => (
      //           <div>
      //             <Select
      //               placeholder="Select SortType"
      //               onChange={(value) => {
      //                 setSortType(value);
      //                 setCurrentPage(1);
      //                 props.getData(
      //                   pageSize,
      //                   0,
      //                   selectedStatusId,
      //                   hideCompleted,
      //                   orderBy,
      //                   value,
      //                   selectedPriorityStatusId,
      //                   selectedProgressStatusId,
      //                   taskAssignedTo,
      //                   tenderReferenceCode
      //                 );
      //               }}
      //               value={sortType}
      //             >
      //               <Option value="asc">Ascending</Option>
      //               <Option value="desc">Descending</Option>
      //             </Select>
      //           </div>
      //         )}
      //       ></Select>
      //     </>
      //   );
      // },
      // title:"Task",
      // dataIndex:"subject",
      // key: "subject",
      // render: (record) => {
      //   return (
      //     <div
      //       style={{
      //         display: "flex",
      //         flexDirection: "row",
      //       }}
      //     >
      //       <div
      //         onClick={() =>
      //           record.todoEventStatusDto?.name !== "Completed" &&
      //           dispatch(toDoMarkAsComplete(record.id))
      //         }
      //         className="mark-it-off"
      //         style={{ marginRight: 6 }}
      //       >
      //         {record.todoEventStatusDto?.name === "Completed" ? (
      //           <img src="./assets/images/icons/check.png" alt="complete" />
      //         ) : (
      //           <img
      //             src="./assets/images/icons/incomplete.png"
      //             alt="incomplete"
      //           />
      //         )}
      //       </div>
      //       <span className="merge--contact">
      //         <Tooltip title={record.subject} placement="topLeft">
      //           {record.subject}
      //         </Tooltip>
      //       </span>
      //     </div>
      //   );
      // },
      // sorter:true

      title: "Tasks",
      key: "subject",
      render: (record) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-Between",
            }}
          >
            <div
              onClick={(e) => {
                setHideInternalMessage(false);
                setShowChat(false);

                dispatch(toDoMarkAsComplete(record.id));
              }}
              className="mark-it-off"
              style={{ marginRight: 6 }}
            >
              {record.todoEventStatusDto?.name === "Completed" ? (
                <img src="./assets/images/icons/check.png" alt="complete" />
              ) : (
                <img
                  src="./assets/images/icons/incomplete.png"
                  alt="incomplete"
                />
              )}
            </div>
            <div
              onClick={() => {
                dispatch(toDoGetDetails(record?.id));
                setHideInternalMessage(true);

                setSmallChatHeight(false);
                setChatHeight(false);
                setchatBox(false);
              }}
            >
              <span className="merge--contact">
                {!hideButton &&
                !todoEventsLoading &&
                record.todoEventStatusDto?.name === "Completed" ? (
                  <span style={{ textDecoration: "line-through" }}>
                    {record.subject}
                  </span>
                ) : (
                  <Tooltip title={record.subject} placement="topLeft">
                    {record.subject}
                  </Tooltip>
                )}
              </span>
            </div>
          </div>
        );
      },
      sorter: true,
    },

    {
      title: referenceId ? "" : "Ref",
      //dataIndex: referenceId ? "" : "referenceCode",
      key: referenceId ? "" : "referenceCode",

      render: (record) => {
        return (
          <div
            style={{ display: "block", width: "100%" }}
            onClick={(e) => {
              dispatch(toDoGetDetails(record?.id));
              setHideInternalMessage(true);
              setShowChat(true);
              setSmallChatHeight(false);
              setChatHeight(false);
              setchatBox(false);
            }}
          >
            <span>{record?.referenceCode}</span>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          // <Select
          //   placeholder="All Assigned"
          //   bordered={false}
          //   onChange={(value) => {
          //     setAssignedTo(value);
          //     setCurrentPage(1);
          //     props.getData(
          //       pageSize,
          //       0,
          //       selectedStatusId,
          //       hideCompleted,
          //       orderBy,
          //       sortType,
          //       selectedPriorityStatusId,
          //       selectedProgressStatusId,
          //       value,
          //       tenderReferenceCode
          //     );
          //   }}
          //   value={assignedTo}
          // >
          //   <Option value={""}>All Assigned</Option>
          //   <Option value="primary">Assigned to me</Option>
          //   <Option value="supervisor">Assigned to other</Option>
          //   <Option value="nonprimary">Assigned FYI</Option>
          // </Select>

          <span>Assigned To</span>
        );
      },
      // dataIndex: "todoEventCandidateDtos",
      key: "todoEventCandidateDtos",
      render: (record) => {
        return record?.todoEventCandidateDtos?.map((assigneDetail) => {
          if (assigneDetail?.isPrimary) {
            // let name = assigneDetail?.candidateName;
            // let profilePicNameFL = name.match(/\b(\w)/g);
            return assigneDetail.profilePicture ? (
              <div
                onClick={(e) => {
                  dispatch(toDoGetDetails(record?.id));
                  setHideInternalMessage(true);
                  setShowChat(true);
                  setSmallChatHeight(false);
                  setChatHeight(false);
                  setchatBox(false);
                }}
              >
                <Tooltip
                  title={assigneDetail.candidateName}
                  placement="topLeft"
                >
                  <Image
                    src={cqBaseUrl + "/" + assigneDetail.profilePicture + token}
                    style={{
                      height: 14,
                      width: 14,
                      borderRadius: "50%",
                      marginRight: 7,
                    }}
                    preview={false}
                  />
                </Tooltip>
              </div>
            ) : null;
          }
        });
        //   <span className="merge--contact">

        //     {record.subject}

        // </span>
      },

      // render: (record) => {
      //   let assignedName = record.map((assignName) => {
      //     return assignName?.candidateName;
      //   });
      //   let [candidate1, candidate2, ...remainingcandidate] = assignedName;

      //   return (
      //     <span>
      //       {candidate1} {candidate2}
      //     </span>
      //   );
      // },
    },

    {
      title: () => {
        return (
          <>
            <span>Due Date</span>
            {/* <Select
              bordered={false}
              placeholder={<FilterOutlined />}
              dropdownRender={() => (
                <div>
                  <Select
                    placeholder="Select Order By"
                    onChange={(value) => {
                      setOrderBy(value);
                      setCurrentPage(1);
                      props.getData(
                        pageSize,
                        0,
                        selectedStatusId,
                        hideCompleted,
                        value,
                        sortType,
                        selectedPriorityStatusId,
                        selectedProgressStatusId,
                        assignedTo
                      );
                    }}
                    value={orderBy}
                  >
                    <Option value={""}>Due Date</Option>
                    <Option value="priority">Priority</Option>
                    <Option value="startdate">Start Date</Option>
                    <Option value="todostatus">Progress</Option>
                    <Option value="subject">Subject</Option>
                  </Select>
                </div>
              )}
            ></Select> */}
          </>
        );
      },
      //dataIndex: "dueDate",
      key: "dueDate",
      render: (record) => {
        return (
          <div
            onClick={() => {
              dispatch(toDoGetDetails(record?.id));
              setHideInternalMessage(true);
              setShowChat(true);
              setSmallChatHeight(false);
              setChatHeight(false);
              setchatBox(false);
            }}
          >
            <span style={{ color: "#C4314B" }}>
              {moment(record?.dueDate).format("YYYY-MM-DD")}
            </span>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <Select
            placeholder="Priority"
            bordered={false}
            onChange={(value) => {
              setSelectedPriorityStatusId(value);
              // setCurrentPage(1);
              props.getData(
                pageSize,
                0,
                isComplete,
                selectedStatusId,
                hideCompleted,
                orderBy,
                sortType,
                value,
                selectedProgressStatusId,
                taskAssignedTo,
                referenceId
              );
            }}
            value={selectedPriorityStatusId}
          >
            <Option value={""}>Priority</Option>
            {!isEmpty(organizerEventPriorityListing) &&
              organizerEventPriorityListing.map((status, index) => {
                return (
                  <Option value={status.id} key={index}>
                    {status.name}
                  </Option>
                );
              })}
          </Select>
        );
      },
      //dataIndex: "organizerEventPriorityDto",
      key: "organizerEventPriorityDto",
      render: (record) => {
        // data.items.map((item, index) => {
        // 	const labelColor = TinyColor2(item.organizerEventLabelDto?.colorHexValue).isLight() ? 'black' : 'white';
        // 	const labelBackgroundColor = item.organizerEventLabelDto?.colorHexValue || 'green';
        // 	return (
        // 		<span className="private badge" style={{ color: labelColor, backgroundColor: labelBackgroundColor, border: 0 }}>
        // 			{item.organizerEventLabelDto?.name}
        // 		</span>
        //     )

        // const priorityColor = TinyColor2(record?.colorFontHexValue).isLight()
        //   ? "#FAFAFA"
        //   : "black";
        const priorityColor =
          record?.organizerEventPriorityDto?.colorFontHexValue || "white";
        const priorityBackgroundColor =
          record?.organizerEventPriorityDto?.colorHexValue || "green";
        return (
          <div
            onClick={() => {
              dispatch(toDoGetDetails(record?.id));
              setHideInternalMessage(true);
              setShowChat(true);
              setSmallChatHeight(false);
              setChatHeight(false);
              setchatBox(false);
            }}
          >
            <span
              className="badge"
              style={{
                color: priorityColor,
                backgroundColor: priorityBackgroundColor,
                border: 0,
              }}
            >
              {record?.organizerEventPriorityDto.name.charAt(0)}
            </span>
          </div>
        );
      },
    },
    {
      title: () => {
        return (
          <Select
            placeholder="progress"
            bordered={false}
            onChange={(value) => {
              setSelectedProgressStatusId(value);
              // setCurrentPage(1);
              props.getData(
                pageSize,
                0,
                isComplete,
                selectedStatusId,
                hideCompleted,
                orderBy,
                sortType,
                selectedPriorityStatusId,
                value,
                taskAssignedTo,
                referenceId
              );
            }}
            value={selectedProgressStatusId}
          >
            <Option value={""}>Progress</Option>
            {!isEmpty(allEventStatusListing) &&
              allEventStatusListing.map((status, index) => {
                return (
                  <Option value={status.id} key={index}>
                    {status.name}
                  </Option>
                );
              })}
          </Select>
        );
      },

      //dataIndex: "todoEventStatusDto",
      key: "todoEventStatusDto",
      render: (record) => {
        // const progressColor = TinyColor2(record?.colorFontHexValue).isLight()
        //   ? "#FAFAFA"
        //   : "black";

        const progressColor = "white";

        const progressBackgroundColor =
          record?.todoEventStatusDto?.colorHexValue || "green";
        return (
          <div
            onClick={() => {
              dispatch(toDoGetDetails(record?.id));
              setHideInternalMessage(true);
              setShowChat(true);
              setSmallChatHeight(false);
              setChatHeight(false);
              setchatBox(false);
            }}
          >
            <span
              className="private badge"
              style={{
                color: progressColor,
                backgroundColor: progressBackgroundColor,
                border: 0,
              }}
            >
              {record?.todoEventStatusDto.name.charAt(0)}
            </span>
          </div>
        );
      },
    },

    // {
    //   title: "Action",
    //   key: "action",
    //   render: (record) => (
    //     <Space size="middle">
    //       <a
    //         className="action-btn action-btn-add"
    //         onClick={() => {
    //           dispatch(toDoGetDetails(record?.id));
    //           handleChangeEvent("task");
    //         }}
    //       >
    //         <EditOutlined /> Edit
    //       </a>
    //       <div
    //         style={{ cursor: "pointer", width: "30px" }}
    //         onClick={() => {
    //           setHideInternalMessage(true);
    //           setTaskId(record?.id);
    //         }}
    //       >
    //         <Image src={internalMessageGreen} shape="square" preview={false} />
    //       </div>
    //     </Space>
    //   ),
    // },
  ];

  // const [assignedTo, setAssignedTo] = useState("");

  const sortIcon = () => {
    return (
      <div>
        <img src={filter} />
        <span style={{ paddingLeft: 5 }}>Sort</span>
      </div>
    );
  };

  const [dataSource, setDataSource] = useState([]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting =
      sorter.order === "ascend"
        ? "subject"
        : sorter.order === "descend"
        ? "subject"
        : undefined;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    // getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
    // let completed = [1, 2, 4, 5];
    props.getData(
      MaxResultCount,
      0,
      isComplete,
      selectedStatusId,
      hideCompleted,
      Sorting,
      SortType,
      selectedPriorityStatusId,
      selectedProgressStatusId,
      taskAssignedTo,
      referenceId
    );
  };

  useEffect(() => {
    if (!isEmpty(allToDoEvents?.items)) {
      setDataSource(allToDoEvents?.items);
    } else {
      setDataSource([]);
    }
  }, [allToDoEvents?.items]);

  useEffect(() => {
    if (!isEmpty(allToDoEvents?.items)) {
      if (allToDoEvents.totalCount !== allToDoEvents.items.length) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    }
  }, [allToDoEvents, todoEventsLoading]);

  // const dataSource = data.items;

  // useEffect(() => {
  //   if (!isEmpty(tenderReferenceCode)) {
  //     props.getData(
  //       pageSize,
  //       0,
  //       selectedStatusId,
  //       hideCompleted,
  //       orderBy,
  //       sortType,
  //       selectedPriorityStatusId,
  //       selectedProgressStatusId,
  //       assignedTo,
  //       tenderReferenceCode,
  //     );
  //   }
  // }, [tenderReferenceCode]);
  useEffect(() => {
    if (!isEmpty(allToDoEvents?.items)) {
      let lists = [...popoverMenuVisible];
      let viewlists = [...viewList];
      allToDoEvents?.items.map((item, i) => {
        lists[i] = false;
        viewlists[i] = "More";
      });
      setPopoverMenuVisible(lists);
      setViewList(viewlists);
    }
  }, [allToDoEvents?.items]);
  useEffect(() => {
    setSelectedStatusId("");
    setSelectedPriorityStatusId("");
    setSelectedProgressStatusId("");
    setHideCompleted([]);
    setOrderBy("");
    setSortType("");
  }, [toDoMarkItOffResp]);

  const handleVisibleChange = (index) => {
    let lists = [...popoverMenuVisible];
    lists[index] = !lists[index];
    setPopoverMenuVisible(lists);
  };

  const getActionContent = (item, index) => {
    return (
      <div className="guest-actions">
        <div
          className="guest-act"
          onClick={() => {
            editToDoList(item);
            handleVisibleChange(index);
          }}
        >
          Edit
        </div>
        <div className="guest-act">Delete</div>
      </div>
    );
  };

  const onChangeCompleted = (checked) => {
    setDataSource([]);
    // setCurrentPage(1);
    // let completedStatuses = [];
    // if (checked === false) {
    //   setHideCompleted(completedStatuses);
    // } else {
    //   if (allEventStatusListing) {
    //     allEventStatusListing.filter((status) => {
    //       if (status.name !== "Completed") {
    //         completedStatuses.push(status);
    //         setHideCompleted(completedStatuses);
    //       }
    //     });
    //   }
    // }
    let hideBtnValue;
    if (checked === true) {
      setIsComplete(1);
      setHideButton(true);
      hideBtnValue = 1;
    } else {
      setIsComplete(0);
      setHideButton(false);
      hideBtnValue = 0;
    }
    props.getData(
      pageSize,
      0,
      hideBtnValue,
      selectedStatusId,
      hideCompleted,
      orderBy,
      sortType,
      selectedPriorityStatusId,
      selectedProgressStatusId,
      taskAssignedTo,
      referenceId
    );
  };

  // const onChangePage = (page) => {
  //   // setCurrentPage(page);
  //   props.getData(
  //     pageSize,
  //     (page - 1) * pageSize,
  //     isComplete,
  //     selectedStatusId,
  //     hideCompleted,
  //     orderBy,
  //     sortType,
  //     selectedPriorityStatusId,
  //     selectedProgressStatusId,
  //     taskAssignedTo,
  //     referenceId
  //   );
  // };

  useEffect(() => {
    if (!isEmpty(referenceId)) {
      props.getData(
        pageSize,
        0,
        isComplete,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        referenceId
      );
    }
  }, [referenceId]);

  useEffect(() => {
    if (taskAssignedTo)
      props.getData(
        pageSize,
        0,
        isComplete,
        selectedStatusId,
        hideCompleted,
        orderBy,
        sortType,
        selectedProgressStatusId,
        selectedPriorityStatusId,
        taskAssignedTo,
        referenceId
      );
  }, [taskAssignedTo]);

  const getHalfDetails = (details) => {
    let halfDetails = details ? details.split("").splice(0, 80).join("") : "";
    return halfDetails;
  };

  //   add task
  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {
      // message.error('Failure');
    });

    if (resp && resp.status === 200) {
      setMeetingInvitees(resp.data);
    }
  };

  const { mailReferenceListingResponse } = useSelector(
    (state) => state.mailMessageCenter
  );

  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );

  useEffect(() => {
    if (!isEmpty(searchEmailTender))
      dispatch(getReferenceListing({ searchKeyWord: searchEmailTender }));
  }, [searchEmailTender]);

  useEffect(() => {
    dispatch(mailReferenceListing());
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
  }, []);

  useEffect(() => {
    if (searchValueReference) {
      if (referenceListingResponse) {
        setReferenceListing(referenceListingResponse);
      }
    }
  }, [referenceListingResponse, searchValueReference]);

  useEffect(() => {
    if (
      !isEmpty(searchEmailTender) &&
      !isEmpty(referenceListingResponse?.items)
    ) {
      // setFilterReferenceId(referenceListingResponse?.items[0]?.id);
      referenceListingResponse?.items.map((reference) =>
        setFilterReferenceId(reference.id)
      );
    }
  }, [referenceListingResponse, searchEmailTender]);

  useEffect(() => {
    if (!isEmpty(referenceListing) && filterParticipantType == "REF") {
      setFilterReference(referenceListing.items);
    }
  }, [referenceListing]);

  // // const organizerEventLabelID = organizerEventLabelListing[0]?.id

  // const changeEmployeeDto = (value, option) => {
  //   let newTodoEventCandidateDtos = option.map((candidate) => {
  //     if (todoEventCandidateDtos.length > 0) {
  //       let newCandidate = {};
  //       todoEventCandidateDtos.map((oldCandidate) => {
  //         if (oldCandidate.candidateId === candidate.value) {
  //           newCandidate = { ...oldCandidate };
  //         }
  //       });
  //       if (!isEmpty(newCandidate)) {
  //         return { ...newCandidate };
  //       } else {
  //         return {
  //           candidateId: candidate.value,
  //           candidateName: candidate.title,
  //           isPrimary: false,
  //           isWatcher: false,
  //         };
  //       }
  //     } else {
  //       return {
  //         candidateId: candidate.value,
  //         candidateName: candidate.title,
  //         isPrimary: true,
  //         isWatcher: false,
  //       };
  //     }
  //   });
  //   setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  // };

  const allEqual = (arr) => arr.every((val) => val === true);

  // const changeCandidatePrimary = (value, index) => {
  //   let newTodoEventCandidateDtos = cloneDeep(todoEventCandidateDtos);

  //   if (value === "supervisor") {
  //     newTodoEventCandidateDtos[index].isSupervisor = true;
  //     newTodoEventCandidateDtos[index].isPrimary = false;
  //   } else if (value === "primary") {
  //     newTodoEventCandidateDtos[index].isPrimary = true;
  //     newTodoEventCandidateDtos[index].isSupervisor = false;
  //   } else {
  //     newTodoEventCandidateDtos[index].isPrimary = false;
  //     newTodoEventCandidateDtos[index].isSupervisor = false;
  //   }
  //   let primaryValueArray = newTodoEventCandidateDtos.map(
  //     (candidate) => candidate.isPrimary
  //   );
  //   let supervisorValueArray = newTodoEventCandidateDtos.map(
  //     (candidate) => candidate.isSupervisor
  //   );

  //   if (primaryValueArray.includes(true) && !allEqual(supervisorValueArray)) {
  //     setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  //   }
  // };

  // const removeSelectedMember = (candidateId) => {
  //   let newTodoEventCandidateDtos = todoEventCandidateDtos.filter(
  //     (candidate) => candidate.candidateId !== candidateId
  //   );

  //   setTodoEventCandidateDtos(newTodoEventCandidateDtos);

  //   let newSelectTodoEventCandidateDtos = form
  //     .getFieldValue("todoEventCandidateDtos")
  //     .filter((obj) => obj !== candidateId);

  //   form.setFieldsValue({
  //     todoEventCandidateDtos: newSelectTodoEventCandidateDtos,
  //   });
  // };
  let participantFrom = storage.getItem("userId");
  const [showLoader, setShowLoader] = useState(true);
  const changeEmployeeDto = (value, option) => {
    let newTodoEventCandidateDtos = option.map((candidate) => {
      return {
        candidateId: candidate.value,
        candidateName: candidate.title,
        isPrimary: true,
        isWatcher: false,
      };
    });
    setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  };

  // const worker = {
  //   dueDate: moment(),
  //   startDate: moment(),
  // };

  // useEffect(() => {
  //   if (!isEmpty(tenderReferenceCode)) {
  //     form.setFieldsValue({
  //       referenceId: tenderReferenceCode,
  //     });
  //   }
  // }, [tenderReferenceCode]);

  const onFinish = (values) => {
    let newValues = {
      startDate: moment(values.startDate),
      privateUserId: participantFrom,
      organizerEventLabelId: "5ed65d11-e8e4-4ca9-a35f-14dbaa6509a6",
      ...values,
    };

    newValues.todoEventCandidateDtos = todoEventCandidateDtos;
    if (!isEmpty(fitlerReferenceId)) {
      newValues.referenceId = fitlerReferenceId;
    }
    dispatch(addToDoEvent(newValues));
    // gettaskTodoList();
    setShowAddTask(!showAddTask);
    setShowLoader(false);
  };

  const [form] = useForm();
  const handleAddTask = () => {
    setShowAddTask(true);
  };

  useEffect(() => {
    if (filterParticipantType === "REF") {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ SearchKeyword: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  // useEffect(() => {
  //   var tableContent = document.querySelector(".toto-table .ant-table-body");
  //   tableContent.addEventListener("scroll", (event) => {
  //     let maxScroll = event.target.scrollHeight - event.target.clientHeight;
  //     let currentScroll = Math.round(event.target.scrollTop);
  //     if (currentScroll == maxScroll) {
  //       let MaxResultCount = pageSize;
  //       let SkipCount = currentPage * pageSize;
  //       if (allToDoEvents?.totalCount > SkipCount) {
  //         props.getData(
  //           MaxResultCount,
  //           SkipCount,
  //           isComplete,
  //           selectedStatusId,
  //           hideCompleted,
  //           orderBy,
  //           sortType,
  //           selectedPriorityStatusId,
  //           selectedProgressStatusId,
  //           taskAssignedTo,
  //           referenceId,
  //           true
  //         );
  //       }
  //     }
  //   });
  // }, [currentPage, allToDoEvents]);
  const onChangeView = () => {
    setBoardView(!boardView);
  };

  const kanbanData = addTodoEventsKanban?.items || {};
  const newKanbandata = Object.keys(kanbanData)?.map(function (nameIndex) {
    let getKanbanData = kanbanData[nameIndex];
    return getKanbanData;
  });
  const finalKanbanData = map(newKanbandata, (badge) => ({
    ...badge,
    badge: badge?.cards?.length?.toString(),
  }));

  const displayCard = (statusName, badge) => {
    return (
      <div className="kanban_header">
        <Card style={{ height: 60, width: 300 }}>
          <div className="header_top_maincard">
            <div className="innerdiv_header">
              <h6 className="text_div_header">
                {" "}
                {statusName}
                <small className={"badge_icon_header-" + statusName}>
                  {badge}
                </small>
              </h6>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div className="organizer__list todo__list">
        <Row gutter={[8, 8]}>
          <Col className="switch_view">List View</Col>
          <Col className="switch-div">
            <Switch onChange={onChangeCompleted} checked={hideButton} />
            Show Completed
          </Col>
        </Row>
      </div>

      <div>
        <Table
          columns={columns}
          // rowKey="appUserId"
          rowKey={(record) => record.id}
          rowClassName={setTaskClassName}
          className={`organizer__table contact-table task-table ${
            showChat && "openChat"
          } toto-table`}
          dataSource={dataSource}
          onChange={handleTableChange}
          loading={{
            indicator: <img src={LogoImage} height="auto" width="50px" />,
            spinning: todoEventsLoading,
          }}
          pagination={false}
          scroll={{ y: 680 }}
          onRow={todoRowEventHandler}
        />
      </div>
    </>
  );
};

export default ToDoLists;
