import React, { useEffect, useState, useRef } from 'react';
import { isEmpty, orderBy } from 'lodash';

// antd
import {
  Button,
  Drawer,
  Layout,
  Input,
  Space,
  Form,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
  Upload,
} from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizerEventPriorityListings } from 'services/redux/organizer/priorityLisiting/action';
import { getMailMessageParticipantStatusListing } from 'services/redux/email/messageCenterType/action';

// inlcudes
import ImageUploaderMultiple from 'components/shared/ImageUploaderMultiple';
import Loader from '../../../components/Loader';
import { appBaseUrl, cqBaseUrl } from 'utils/config';
import { uploadFile } from 'services/remanentCalls';

// ckeditor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { composeQuickMessage } from 'services/redux/email/quickMessage/action';
import { getAllUsers } from 'services/remanentCalls';
import { getReferenceListing } from 'services/redux/Reference/action';

const { Header, Footer } = Layout;
const { Option } = Select;

const QuickMessageDrawer = (
  {
    // visible,
    // allParticipants,
    // organizerEventPriorityListing,
    // mailMessageParticipantTypeListing,
    // onCloseQuickMessageDrawer,
    // mailMessageParticipationStatusListing
  }
) => {
  const dispatch = useDispatch();
  const quickMessageState = useSelector((state) => state.quickMessage);
  const { organizerEventPriorityListing } = useSelector(
    (state) => state.priorityEvents
  );
  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );
  const { mailMessageParticipationStatusListing } = useSelector(
    (state) => state.mailMessageParticipantStatus
  );
  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );

  const { composeQuickMessageResp } = quickMessageState;

  const [form] = Form.useForm();
  const searchResultReference = useRef(null);

  const [editorData, setEditorData] = useState('');
  const [loading, setLoading] = useState('');
  const [allParticipants, setAllParticipants] = useState('');
  const [messageParticipants, setMessageParticipants] = useState([]);
  const [messageParticipantsCC, setMessageParticipantsCC] = useState([]);
  const [quickMessageParticipantDtosTo, setQuickMessageParticipantDtosTo] =
    useState([]);
  const [quickMessageParticipantDtosCC, setQuickMessageParticipantDtosCC] =
    useState([]);
  const [referenceListing, setReferenceListing] = useState([]);
  const [mailMessageCenterType, setMailMessageCenterType] = useState([]);
  const [attatchments, setAttatchments] = useState([]);
  const [referenceDropDown, setReferenceDropDown] = useState();
  const [searchValueReference, setSearchValueReference] = useState('');
  const [cursor, setCursor] = useState(-1);
  const [visiblePriority, setVisibilePriority] = useState(false);
  const [toolBar, setToolBar] = useState(false);

  const attachment = 'assets/svg/attatchment.svg';
  const send = 'assets/images/cqnovalsend.png';

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
    }).catch((err) => {
      // message.error('Error while uploading');
      onError(err);
    });
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      setAttatchments(alteredResponse);
      let olduploadedFileDtos = form.getFieldValue().uploadedFileDtos
        ? [...form.getFieldValue().uploadedFileDtos]
        : [];

      let newUploadedFileDtos = [...olduploadedFileDtos];
      newUploadedFileDtos.push(alteredResponse);
      // uploadedFileDtos[i] = e;

      form.setFieldsValue({
        uploadedFileDtos: newUploadedFileDtos,
      });
      // onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  useEffect(() => {
    if (!isEmpty(mailMessageParticipationStatusListing)) {
      let newMailMessageCenterType =
        mailMessageParticipationStatusListing.filter(
          (status) =>
            status.systemName === 'Normal' ||
            status.systemName === 'Pending' ||
            status.systemName === 'Archived'
        );

      setMailMessageCenterType(newMailMessageCenterType);
    }
  }, [mailMessageParticipationStatusListing]);

  useEffect(() => {
    if (referenceListingResponse) {
      setReferenceListing(referenceListingResponse);
    }
  }, [referenceListingResponse]);

  useEffect(() => {
    if (searchValueReference) {
      if (
        cursor < 0 ||
        cursor > referenceListing?.items?.length ||
        !searchResultReference
      ) {
        return () => {};
      }

      let listItems = Array.from(searchResultReference?.current?.children);

      listItems[cursor] && scrollIntoView(listItems[cursor].offsetTop, 'REF');
    }
  }, [cursor]);
  useEffect(() => {
    document.title = 'Internal Message';
  }, []);

  useEffect(() => {
    if (!isEmpty(searchValueReference)) {
      setReferenceDropDown(true);
    } else {
      setReferenceDropDown(false);
    }
  }, [searchValueReference]);

  const uploadFiles = (e) => {
    setAttatchments([...attatchments, e.target.files[0]]);
  };
  const scrollIntoView = (position, paritcipantType) => {
    if (paritcipantType === 'REF') {
      searchResultReference.current?.parentNode?.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    }
  };

  const keyboardNavigation = (e, participantType) => {
    if (participantType === 'REF') {
      if (e.key === 'ArrowDown') {
        setCursor((c) => (c < referenceListing?.items?.length - 1 ? c + 1 : c));
      }

      if (e.key === 'ArrowUp') {
        setCursor((c) => (c > 0 ? c - 1 : 0));
      }

      if (e.key === 'Enter' || e.key === 'Tab') {
        let participant =
          cursor > -1
            ? referenceListing?.items[cursor]
            : referenceListing?.items[0];
        changeReferenceTable(participant);
        setSearchValueReference('');
        setReferenceDropDown(false);
        setCursor(-1);
        setReferenceListing([]);
      }
      if (e.key === 'Escape' && cursor > -1) {
        setReferenceDropDown(false);
        setSearchValueReference('');
      }
    }
  };

  const onFinish = async (centerTypeSystemName) => {
    try {
      let values = await form.validateFields();
      let defaulPriority = organizerEventPriorityListing?.filter(
        (fav) => fav.name === 'Normal'
      );

      let body = {
        priorityId: !isEmpty(values.priorityId)
          ? values.priorityId
          : defaulPriority[0].id,

        MailMessageReferenceDtos: values.MailMessageReferenceDtos?.map(
          (code) => {
            return {
              referenceCode: code,
            };
          }
        ),
        subject: values.subject,
        description: editorData,
        mailMessageParticipantWithTypeDtos: {
          participantTos: values.participantTos.map((value) => {
            return {
              participantUserId: value,
            };
          }),
          participantCCs: values.participantCCs?.map((value) => {
            return {
              participantUserId: value,
            };
          }),
        },
        quickMessageStatusName: centerTypeSystemName,
        quickMessageAttachmentDtos: values.uploadedFileDtos || [],
      };

      dispatch(
        composeQuickMessage(body, selectedMailTypeAndMailData.typeSystemName)
      );
    } catch (errorInfo) {
      console.error('Failed:', errorInfo);
    }
  };

  useEffect(() => {
    if (isEmpty(allParticipants)) {
      fetchAllParticipants();
    }

    if (isEmpty(organizerEventPriorityListing)) {
      dispatch(getOrganizerEventPriorityListings());
    }

    if (isEmpty(mailMessageParticipationStatusListing)) {
      dispatch(getMailMessageParticipantStatusListing());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(composeQuickMessageResp)) {
      form.resetFields();
      setEditorData('');
      window.close();
    }
  }, [composeQuickMessageResp]);

  // useEffect(() => {
  //   if (!isEmpty(composeQuickMessageResp)) {
  //     onCloseQuickMessageDrawer();
  //   }
  // }, [composeQuickMessageResp])

  useEffect(() => {
    if (isEmpty(messageParticipants) && !isEmpty(allParticipants)) {
      setMessageParticipants(allParticipants);
      setMessageParticipantsCC(allParticipants);
    }
  }, [allParticipants]);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      // message.error('Failure');
    });

    if (resp && resp.status === 200) {
      setAllParticipants(resp.data);
      console.log('This should be here');
    }
  };

  const changeParticipantsDtosTo = (option, type) => {
    let newQuickMessageParticipantDtos = option.map((participant) => ({
      participantId: participant.value,
    }));
    setQuickMessageParticipantDtosTo(newQuickMessageParticipantDtos);

    let newMessagePariticipants = [];
    allParticipants.map((participant) => {
      let checkSelected = false;
      newQuickMessageParticipantDtos.map((selectedParticipant) => {
        if (selectedParticipant.participantId === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipants.push(participant);
      }
    });

    setMessageParticipantsCC(newMessagePariticipants);
  };
  const changeReferenceTable = (reference) => {
    let referenceTags = [];
    referenceTags = form.getFieldsValue().MailMessageReferenceDtos
      ? [...form.getFieldsValue().MailMessageReferenceDtos]
      : [];
    referenceTags.push(reference.code);
    if (reference) {
      form.setFieldsValue({
        MailMessageReferenceDtos: referenceTags,
      });
    }
  };

  const changeParticipantsDtosCC = (option, participantType) => {
    let newQuickMessageParticipantDtos = option.map((participant) => ({
      participantId: participant.value,
      participantTypeId: participantType.id,
    }));
    setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);

    let newMessagePariticipants = [];
    allParticipants.map((participant) => {
      let checkSelected = false;
      newQuickMessageParticipantDtos.map((selectedParticipant) => {
        if (selectedParticipant.participantId === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipants.push(participant);
      }
    });

    setMessageParticipants(newMessagePariticipants);
  };

  // function handleMenuClick(e) {
  //   console.log('click', e);
  // }

  const menu = () => (
    <Menu>
      {mailMessageCenterType.map(
        (centerType) =>
          centerType.systemName !== 'Normal' && (
            <Menu.Item
              onClick={(e) => onFinish(e.key)}
              key={centerType.systemName}
            >
              As {centerType.displayName}
            </Menu.Item>
          )
      )}
      <Menu.Item
        key='2'
        onClick={() => {
          setVisibilePriority(!visiblePriority);
        }}
      >
        Priority
      </Menu.Item>
    </Menu>
  );

  return (
    // <Drawer visible={visible} width={600} className="drawer email">
    <>
      <Form
        name='dynamic_form_nest_item'
        onFinish={onFinish}
        onFinishFailed={(info) => console.log(info)}
        autoComplete='off'
        form={form}
      >
        <div
          className='mail-quick-message'
          style={{ display: 'block' }}
          onClick={() => {
            referenceDropDown && setReferenceDropDown(false);
            setCursor(-1);
          }}
        >
          <div className='mail-message' style={{ width: '100%' }}>
            <Header className='email_compose__header'>
              {/* <Button className="btn-collapse" type="link">
          <span className="button__label" onClick={onCloseQuickMessageDrawer}>
            Collapse
						</span>
          <CaretRightOutlined />
        </Button> */}
              {/* <h4 className="email_compose__header--title">Quick Message</h4> */}
              {/* <Footer className="email__footer">
								{/* <Button onClick={onCloseQuickMessageDrawer}>Cancel</Button> */}
              <Space>
                <Dropdown.Button
                  ghost
                  className='email-send-as'
                  overlay={menu}
                  onClick={() => {
                    let newMailMessageCenterType = mailMessageCenterType.filter(
                      (type) => type.systemName === 'Normal'
                    );
                    onFinish(newMailMessageCenterType[0].systemName);
                  }}
                >
                  <div className='btn__chat'>
                    {<img className='rotate' src={send} />}
                  </div>
                </Dropdown.Button>
                <Button type='primary' ghost>
                  Save
                </Button>
              </Space>
              <div
                className='flexend'
                style={{
                  width: 41,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  className='hover'
                  onClick={() => {
                    setToolBar(!toolBar);
                  }}
                >
                  A
                </div>
                <div className='hover'>
                  <Upload
                    name='uploadedFiles'
                    itemRender={(files) => {
                      <div style={{ display: 'none' }}>{files}</div>;
                    }}
                    action={`${appBaseUrl}/file/uploadFile/`}
                    customRequest={uploadPhotos}
                  >
                    <img src={attachment} />
                  </Upload>
                </div>
              </div>
            </Header>
            <div className='drawer__content'>
              <div className='drawer__content--wrapper' style={{ padding: 10 }}>
                <div className='contact__content__wrapper'>
                  <div className='email-text-input'>
                    <div className='email-text'>To</div>
                    <Form.Item
                      className='form__group'
                      name={`participantTos`}
                      rules={[{ required: true, message: 'Required' }]}
                    >
                      <Select
                        mode='multiple'
                        bordered={false}
                        showSearch={true}
                        autoFocus={true}
                        allowClear
                        onChange={(value, option) =>
                          changeParticipantsDtosTo(option, 'To')
                        }
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {messageParticipants.map((participant, j) => (
                          <Option key={j} value={participant.appUserId}>
                            {participant.fullName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='email-text-input'>
                    <div className='email-text'>Cc</div>
                    <Form.Item
                      className='form__group'
                      name={`participantCCs`}
                      rules={[{ required: false, message: 'Required' }]}
                    >
                      <Select
                        mode='multiple'
                        bordered={false}
                        allowClear
                        onChange={(value, option) =>
                          changeParticipantsDtosCC(option, 'CC')
                        }
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {messageParticipantsCC.map((participant, j) => (
                          <Option value={participant.appUserId} key={j}>
                            {participant.fullName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='email-text-input'>
                    <div className='email-text' style={{ width: 113 }}>
                      Reference
                    </div>
                    <Form.Item
                      className='form__group'
                      name='MailMessageReferenceDtos'
                      rules={[{ required: true, message: 'Required' }]}
                    >
                      <Select
                        bordered={false}
                        mode='multiple'
                        allowClear
                        // open={participantCCOpenDropdown}
                        // onClick={() => setParticiapantCCOpenDropdown(!participantCCOpenDropdown)}
                        open={referenceDropDown}
                        onKeyDown={(e) => {
                          keyboardNavigation(e, 'REF');
                        }}
                        dropdownRender={() =>
                          !isEmpty(referenceListing?.items) ? (
                            // <table className="form__group--table">
                            // 	{/* <thead>
                            // 		<tr>
                            // 			<th>Code</th>
                            // 			<th>Title</th>
                            // 			<th>Descirption</th>
                            // 		</tr>
                            // 	</thead> */}
                            // 	<tbody>
                            // 		{referenceListingResponse?.items?.map((referenceId, j) => {
                            // 			let isSelected = form.getFieldValue().MailMessageReferenceDtos && form.getFieldValue().MailMessageReferenceDtos.includes(referenceId.code) ? true : false;
                            // 			return (
                            // 				<tr
                            // 					className={isSelected ? 'table-active' : ''}
                            // 					key={j}
                            // 					onClick={(value) => {
                            // 						changeReferenceTable(referenceId);
                            // 						setReferenceDropDown(false);
                            // 						// changeParticipantsEmailDtos('CC');
                            // 					}}
                            // 				>
                            // 					<td>{referenceId.code}</td>
                            // 					<td>{referenceId.title}</td>
                            // 					<td>{referenceId.description}</td>
                            // 				</tr>
                            // 			);
                            // 		})}
                            // 	</tbody>
                            // </table>
                            <div
                              style={{
                                width: '100%',
                                maxHeight: '350px',
                                overflowY: 'auto',
                              }}
                            >
                              <ul
                                className='list-group'
                                ref={searchResultReference}
                              >
                                {referenceListing?.items?.map(
                                  (referenceId, idx) => {
                                    let isSelected =
                                      form.getFieldValue()
                                        .MailMessageReferenceDtos &&
                                      form
                                        .getFieldValue()
                                        .MailMessageReferenceDtos.includes(
                                          referenceId.code
                                        )
                                        ? true
                                        : false || cursor === idx
                                        ? true
                                        : false;
                                    return (
                                      <li
                                        className={` mail-listing  ${
                                          isSelected ? 'table-active' : ''
                                        }`}
                                        key={idx}
                                        onClick={(value) => {
                                          changeReferenceTable(referenceId);
                                          setReferenceDropDown(false);
                                          setCursor(-1);
                                        }}
                                      >
                                        <div className='mail-listing--address'>
                                          {referenceId.code}
                                        </div>
                                        <div className='mail-listing--person'>
                                          {referenceId.title}
                                        </div>
                                        <div className='mail-listing--company'>
                                          [{referenceId.description}]
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          ) : (
                            <div>
                              {referenceListingLoading ? (
                                <span>loading...</span>
                              ) : (
                                <span>No data</span>
                              )}
                            </div>
                          )
                        }
                        onSearch={(value) => {
                          // setFilterParticipantType('CC');
                          setReferenceDropDown(true);
                          setSearchValueReference(value);
                          dispatch(
                            getReferenceListing({ SearchkeyWord: value })
                          );
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='email-text-input'>
                    <div className='email-text' style={{ width: 119 }}>
                      Subject
                    </div>
                    <Form.Item
                      className='form__group'
                      name='subject'
                      rules={[{ required: true, message: 'Required' }]}
                    >
                      <Input />
                    </Form.Item>
                    {visiblePriority && (
                      <div
                        className='email-text-input'
                        style={{ width: '11%', border: 'none' }}
                      >
                        {/* <div className="email-text">Priority</div> */}
                        <Form.Item className='form__group' name='priorityId'>
                          <Select
                            bordered={false}
                            defaultValue={() => {
                              let value = '';
                              organizerEventPriorityListing?.map((priority) => {
                                if (priority.name === 'Normal') {
                                  value = priority.id;
                                }
                              });

                              return value;
                            }}
                          >
                            {organizerEventPriorityListing.map(
                              (priority, i) => (
                                <Option value={priority.id} key={i}>
                                  <div
                                    key={i}
                                    style={{
                                      color: `${
                                        priority.name === 'High'
                                          ? 'red'
                                          : priority.name === 'Low'
                                          ? 'grey'
                                          : 'blue'
                                      }`,
                                    }}
                                  >
                                    {/* <span>{showcheckMark ? <CheckOutlined /> : ''}</span> */}

                                    <span>{priority.name}</span>
                                  </div>
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                  {!isEmpty(attatchments) && (
                    <Col span={24}>
                      <div className='email-text-input'>
                        <div className='email-text' style={{ width: 125 }}>
                          Attatchment
                        </div>
                        <Form.Item
                          name={['uploadedFileDtos']}
                          className='form__group'
                          // name={u.id}
                          // label={u.name}
                          rules={[{ required: false }]}
                        >
                          <ImageUploaderMultiple
                            name='quickMessageAttachmentDtos'
                            isMultipleFileAllowed={true}
                            id=''
                            formName='uploadedFileDtos'
                            attatchments={attatchments}
                            uploadedFileDtos={form.getFieldValue()}
                            form={form}
                            callback={(e) => {
                              let olduploadedFileDtos = form.getFieldValue()
                                .uploadedFileDtos
                                ? [...form.getFieldValue().uploadedFileDtos]
                                : [];

                              let uploadedFileDtos = [...olduploadedFileDtos];
                              uploadedFileDtos.push(e);
                              // uploadedFileDtos[i] = e;

                              form.setFieldsValue({
                                uploadedFileDtos: uploadedFileDtos,
                              });
                            }}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  )}

                  <Row gutter={[8, 8]}>
                    {/* {
                  orderBy(mailMessageParticipantTypeListing, ['displayName'], ['desc']).map((participantType, i) => (
                    (participantType.systemname !== 'Bcc' && participantType.systemname !== 'From') &&
                    (participantType.systemname === 'To' ? */}
                    <Col span={24}>
                      <div className={`test ${toolBar && 'active'}`}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={editorData}
                          config={{
                            toolbar: [
                              'heading',
                              '|',
                              'bold',
                              'italic',
                              'link',
                              'bulletedList',
                              'numberedList',
                              'blockQuote',
                              'insertTable',
                            ],
                            heading: {
                              options: [
                                {
                                  model: 'paragraph',
                                  title: 'Paragraph',
                                  class: 'ck-heading_paragraph',
                                },
                                {
                                  model: 'heading1',
                                  view: 'h1',
                                  title: 'Heading 1',
                                  class: 'ck-heading_heading1',
                                },
                                {
                                  model: 'heading2',
                                  view: 'h2',
                                  title: 'Heading 2',
                                  class: 'ck-heading_heading2',
                                },
                              ],
                            },
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorData(data);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
    // </Drawer>
  );
};

export default QuickMessageDrawer;
