import React, { useEffect, useState } from "react";

// antd
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  Modal,
  AutoComplete,
  Tooltip,
} from "antd";
import {
  ExclamationOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { allCountryListing } from "services/redux/contact/contact.action";
import {
  contactCompanyPersonSearchPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";
import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";

import {
  cityListing,
  contactSourceListing,
  countryListing,
  contactLabelGroupListing,
  contactLabelListing,
  scopeOfWorkListing,
  scopeofWorkAdd,
} from "services/remanentCalls";
import {
  contactCompanyDetailAdd,
  contactCompanyGetDetails,
  contactCompanyUpdate,
  contactPersonAdd,
  contactPersonGetDetails,
  contactQuickEmailAdd,
  contactPersonUpdate,
} from "../../../services/redux/contact/companypeople/action";
import { isEmpty } from "lodash-es";
import AddCompanyDrawer from "views/Contact/ContactCompanyPerson/AddUpdateCompanyDrawer";
import AddPersonDrawer from "views/Contact/ContactCompanyPerson/AddUpdatePersonDrawer";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { NavItem } from "react-bootstrap";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const tailLayout = {
  wrapperCol: { span: 16 },
};

const NEW_ITEM = "CREATE_NEW_ITEM";
const pageSize = 110;
{
  /* <img
                  src="./assets/images/icons/incomplete.png"
                  alt="incomplete"
                /> */
}

const AddNewQuickEmail = ({
  emailText,
  // message,
  type,
  setVisibleModal,
  currentSelectedMailMessage,
  addNewEmailModalVisible,
  form,
  notFoundCompany,
  setNotFoundCompany,
  companyCurrentPage,
  setCompanyCurrentPage,
  personId,
  setPersonId,
  companyId,
  setCompanyId,
  setDisableSaveButton,
  disableSavedButton,
  notFoundValue,
  setNotFoundValue,
  allPersonListingState,
  setAllPersonListingState,
  allCompanyListingState,
  setAllCompanyListingState,
  newValuePersonCompany,
  setNewValuePersonCompany,
  newCompanyValue,
  setNewCompanyValue,
  visibleModal,
  setNewQuickCompanyValue,
  newQuickCompanyValue,
}) => {
  const dispatch = useDispatch();
  const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const {
    contactQuickAddEmailRespsonse,
    contactPersonGetDetailsResponse,
    contactCompanyGetDetailsResponse,
    allPersonListingResponse,
    contactPersonSearchListingResponse,
    contactCompanyPersonSearchPaginationLoading,
    contactQuickAddEmailLoading,
    contactCompanyPaginationResponse,
    contactCompanyPaginationLoading,
    contactPersonPaginationResponse,
    contactPersonPaginationLoading,
  } = useSelector((state) => state.contactCompanyPerson);

  const { allCountryListingResponse } = useSelector((state) => state.contact);

  const complete = "assets/svg/complete.svg";

  // const [form] = Form.useForm();
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [emailAddress, setEmailAddress] = useState("");
  const [contactSources, setContactSources] = useState([]);
  const [scopeSearch, setScopeSearch] = useState("");
  const [compareTags, setCompareTags] = useState([]);
  const [disableContactCompany, setDisableContactCompany] = useState(true);
  const [disablePersonCompany, setDisablePersonCompany] = useState(false);
  // const [contactLabelGroupListing, setContactLabelGroupListing] = useState(false);
  const [emailLabelListing, setEmailLabelListing] = useState([]);
  const [addressLabelListing, setAddressLabelListing] = useState([]);
  const [primaryAddress, setPrimaryAddress] = useState({});

  const [showScopeOfWork, setShowScopeOfWork] = useState("");
  const [scopeTags, setScopeTags] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredCity, setFilteredCity] = useState([]);

  useEffect(() => {
    dispatch(allCountryListing());
    fetchCountryListing();
    fetchCities();
    fetchContactSourceListing();
    fetchContactLabelGroupListing();
    fetchScopeofWorkListing();
    document.getElementById("control-hooks_companyPersonId").focus();
  }, []);

  // useEffect(() => {
  //   if (personId) {
  //     setDisableSaveButton(false);
  //   }
  //   else if (notFoundValue ){
  //     setDisableSaveButton(false)
  //   }
  //   else if (!notFoundValue && newValuePersonCompany) {
  //     setDisableSaveButton(false);
  //   } else {

  //   }
  // }, [personId, notFoundValue ]);

  const personValue = form.getFieldsValue().companyPersonId;
  const companyValue = form.getFieldsValue().companyContactId;
  useEffect(() => {
    if (companyId) {
      setDisableSaveButton(false);
    } else if (notFoundCompany && isEmpty(companyId) && !isEmpty(personId)) {
      if (newCompanyValue) {
        setDisableSaveButton(false);
      } else if (!notFoundCompany && newCompanyValue) {
        setDisableSaveButton(false);
      }
    } else if (!!personValue || !!companyValue) {
      setDisableSaveButton(false);
    } else if (!personValue || !companyValue) {
      setDisableSaveButton(true);
    } else if (notFoundCompany) {
      setDisableSaveButton(false);
    } else if (!isEmpty(companyId) && !isEmpty(personId)) {
      setDisableSaveButton(true);
    }
  }, [companyId, notFoundCompany, personValue]);

  // useEffect(() => {
  //   if (!personId && !companyId) {
  //     setDisableSaveButton(true);
  //   } else if (personId && !companyId) {
  //     setDisableSaveButton(false);
  //   } else if (!personId && companyId) {
  //     setDisableSaveButton(false);
  //   }
  // }, [companyId, personId]);

  useEffect(() => {
    dispatch(getContactCompanyPagination());
  }, [dispatch]);

  // const fetchScopeofWorkListing = async () => {
  //   const resp = await scopeOfWorkListing().catch((err) => {
  //     message.error("failure");
  //   });
  //   if (resp && resp.status === 200) {
  //     setScopeTags(resp.data);
  //   }
  // };
  const fetchScopeofWorkListing = async () => {
    const resp = await scopeOfWorkListing({ searchText: scopeSearch }).catch(
      (err) => {
        message.error("failure");
      }
    );
    if (resp && resp.status === 200) {
      setScopeTags(resp.data);
    }
  };

  useEffect(() => {
    if (isEmpty(contactQuickAddEmailRespsonse)) return;
    form.resetFields();
    setVisibleModal(false);
  }, [contactQuickAddEmailRespsonse]);

  useEffect(() => {
    if (scopeSearch) {
      fetchScopeofWorkListing();
    } else {
      setScopeTags([]);
    }
  }, [scopeSearch]);

  useEffect(() => {
    if (!addNewEmailModalVisible) {
      form.resetFields();
    }
  }, [addNewEmailModalVisible]);

  useEffect(() => {
    if (!isEmpty(notFoundValue)) {
      const timeoutId = setTimeout(() => getDataPerson(), 800);
      return () => clearTimeout(timeoutId);
    }
  }, [notFoundValue]);

  useEffect(() => {
    if (isEmpty(notFoundCompany)) return;

    const timeoutId = setTimeout(() => getData(), 800);
    return () => clearTimeout(timeoutId);
  }, [notFoundCompany]);

  useEffect(() => {
    if (
      !isEmpty(contactPersonPaginationResponse?.items) &&
      !contactPersonPaginationLoading
    ) {
      if (
        contactPersonPaginationResponse?.totalCount >
        contactPersonPaginationResponse?.items?.length
      ) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    }
  }, [contactPersonPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(contactCompanyPaginationResponse?.items)) {
      form.setFieldsValue({
        companyContactId: notFoundCompany,
      });

      if (
        contactCompanyPaginationResponse?.totalCount >
        contactCompanyPaginationResponse?.items?.length
      ) {
        setCompanyCurrentPage((currentPage) => currentPage + 1);
      }
    } else {
      setCompanyId("");
    }
  }, [contactCompanyPaginationResponse]);

  useEffect(() => {
    const citylisting = cities?.filter(
      (data) => data?.countryId === selectedCountry
    );
    setFilteredCity(citylisting);
  }, [selectedCountry, contactCompanyGetDetailsResponse]);

  useEffect(() => {
    if (!isEmpty(contactPersonPaginationResponse?.items)) {
      // let personList = contactPersonPaginationResponse?.items?.map(
      //   (list, index) => ({
      //     ...list,
      //     contactCompanyAbbrevation: list.contactCompanyAbbrevation,
      //     contactCompanyName: list.contactCompanyName,
      //     value: list.contactPersonId,
      //     personFullName: list.personFullName,
      //     personNickName: list.personNickName,
      //   })
      // );
      setAllPersonListingState(contactPersonPaginationResponse?.items);
    } else {
      setPersonId();
    }
  }, [contactPersonPaginationResponse]);

  useEffect(() => {
    if (
      isEmpty(contactCompanyPaginationResponse?.items) &&
      !isEmpty(allCompanyListingState)
    ) {
      setAllCompanyListingState(allCompanyListingState);
    }
  }, [contactCompanyPaginationResponse]);

  useEffect(() => {
    if (emailText) {
      setEmailAddress(emailText);
      form.setFieldsValue({
        contactLabelDataValue: emailText,
      });
    }
  }, [emailText]);

  useEffect(() => {
    const contactLabelIdValue = form.getFieldsValue().contactLabelId;
    if (!!contactLabelIdValue) return;
    if (!isEmpty(emailLabelListing)) {
      let value = "";
      emailLabelListing?.map((label) => {
        if (label.name == "Official") {
          value = label.id;
        }
      });
      // console.log(value,"value")
      form.setFieldsValue({
        contactLabelId: value,
      });
    }
  });

  const addScopeofWork = async (value, option) => {
    if (isEmpty(option)) {
      const resp = await scopeofWorkAdd(value).catch((err) => {
        message.error("Failed to add this tag");
      });
    }
  };

  const getDataPerson = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    // Sorting,
    // SortType,
    SearchKeyword,
    ForPagination = false
  ) => {
    dispatch(
      getContactPersonPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: notFoundValue,
        },
        ForPagination
      )
    );
  };

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    // Sorting,
    // SortType,
    SearchKeyword,
    ForPagination = false
  ) => {
    dispatch(
      getContactCompanyPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: notFoundCompany,
        },
        ForPagination
      )
    );
  };

  useEffect(() => {
    if (!isEmpty(contactCompanyGetDetailsResponse)) {
      const scopeOfWorktrim =
        contactCompanyGetDetailsResponse?.scopeOfWork?.split(",");

      let filteredscopeOfWork = scopeOfWorktrim?.filter((data) => {
        return data;
      });

      let scopeOfWork = filteredscopeOfWork?.map((data) => {
        return data.replace(/\s+/g, "");
      });
      const primaryAddress =
        contactCompanyGetDetailsResponse?.contactCompanyGroupAddressDtos?.find(
          (obj) => obj.isPrimaryAddress
        );
      setPrimaryAddress(primaryAddress);
      form.setFieldsValue({
        designation: contactCompanyGetDetailsResponse?.designationName,
        contactAddressLabelId: primaryAddress?.contactLabelId,
        countryId: primaryAddress?.countryId || null,
        cityId: primaryAddress?.cityId || null,
        contactSourceId: contactCompanyGetDetailsResponse?.contactSourceId,
        scopeOfWork: newQuickCompanyValue
          ? []
          : !isEmpty(scopeOfWork)
          ? scopeOfWork
          : [],
      });
      setSelectedCountry(primaryAddress?.countryId);
    }
  }, [contactCompanyGetDetailsResponse, form.getFieldValue().companyId]);

  useEffect(() => {
    if (!isEmpty(contactPersonGetDetailsResponse)) {
      if (contactPersonGetDetailsResponse?.companyContactId) {
        setCompanyId(contactPersonGetDetailsResponse?.companyContactId);
        dispatch(
          contactCompanyGetDetails(
            contactPersonGetDetailsResponse?.companyContactId
          )
        );
      }
      const primaryAddress =
        contactPersonGetDetailsResponse?.contactPersonGroupAddressDtos?.find(
          (obj) => obj.isPrimaryAddress
        );

      if (contactPersonGetDetailsResponse?.companyName) {
        setNotFoundCompany(contactPersonGetDetailsResponse?.companyName);

        setNewCompanyValue(false);
        form.setFieldsValue({
          companyContactId: contactPersonGetDetailsResponse?.companyName,
        });
      }

      setPrimaryAddress(primaryAddress);
      form.setFieldsValue({
        designation: contactPersonGetDetailsResponse?.designationName,
        contactAddressLabelId: primaryAddress?.contactLabelId,
        countryId: primaryAddress?.countryId || null,
        cityId: primaryAddress?.cityId || null,
        // companyContactId: contactPersonGetDetailsResponse.companyContactId,
        contactSourceId: contactCompanyGetDetailsResponse?.contactSourceId,
      });
    }
  }, [contactPersonGetDetailsResponse]);

  const fetchCities = async (CountryId, key) => {
    const resp = await cityListing({ CountryId }).catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      setCities(resp.data);
    }
  };

  const fetchCountryListing = async () => {
    const resp = await countryListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setCountries(resp.data);
    }
  };

  const fetchContactLabelGroupListing = async (body) => {
    const resp = await contactLabelGroupListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      // setContactLabelGroupListing(resp.data);
      const emailGroup = resp.data.find((obj) => obj.name === "Email");
      const addressGroup = resp.data.find((obj) => obj.name === "Address");
      const allContactLabelListing = await fetchContactLabelListing();
      const newEmailLabelListing = allContactLabelListing.filter(
        (obj) => obj.contactLabelGroupId === emailGroup.id
      );
      const newAddressLabelListing = allContactLabelListing.filter(
        (obj) => obj.contactLabelGroupId === addressGroup.id
      );
      // console.log(newEmailLabelListing)
      setEmailLabelListing(newEmailLabelListing);
      setAddressLabelListing(newAddressLabelListing);
    }
  };

  const fetchContactLabelListing = async (body) => {
    const resp = await contactLabelListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      return resp.data;
      // setContactLabelListing(resp.data);
    }
  };

  const fetchContactSourceListing = async (body) => {
    const resp = await contactSourceListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setContactSources(resp.data);
    }
  };

  const changeParticipantTable = (participant) => {
    if (participant) {
      form.setFieldsValue({
        companyPersonId: participant,
      });
    }
  };
  const handleSubmit = async () => {
    try {
      let values = await form.validateFields();
      // if (isEmpty(values.companyPersonId)) {
      //   setNewValuePersonCompany(true);
      // } else if (isEmpty(values.companyContactId)) {
      //   setNewCompanyValue(true);

      // }
      const companyName = form.getFieldValue("companyContactId");

      const companyValue = form.getFieldsValue().companyContactId;
      const scopeOfWorkValue = form.getFieldsValue().scopeOfWork;
      const countryValue = form.getFieldsValue().countryId;
      const cityValue = form.getFieldsValue().cityId;
      if (companyValue) {
        if (!scopeOfWorkValue || isEmpty(scopeOfWorkValue)) {
          message.error("scope of work should be added");
          return;
        }
        if (!countryValue || isEmpty(countryValue)) {
          message.error("Country should be selected");
          return;
        }
        if (!cityValue || isEmpty(cityValue)) {
          message.error("City should be selected");
          return;
        }
      }

      if (values.companyPersonId && values.companyContactId) {
        let body = {
          ...values,
        };
        if (values.contactLabelId && values.contactLabelDataValue) {
          body = {
            ...body,
            contactPersonGroupLabelDtos: [
              {
                contactLabelId: values.contactLabelId,
                contactLabelDataValue: values.contactLabelDataValue,
              },
            ],
          };
        }
        if (
          values.contactAddressLabelId &&
          values.countryId &&
          values.countryId
        ) {
          body = {
            ...body,
            contactPersonGroupAddressDtos: [
              {
                contactLabelId: values.contactAddressLabelId,
                countryId: values.countryId,
                cityId: values.cityId,
                isPrimaryAddress: true,
              },
            ],
          };
        }
        if (newValuePersonCompany && newCompanyValue) {
          let personNameLength = values.companyPersonId?.split(" ").length;
          let personName = values.companyPersonId.split(" ");

          let scopeOfWork = values.scopeOfWork && values.scopeOfWork.join(",");
          body = {
            email: values.contactLabelDataValue,
            contactLabelId: emailLabelListing[1].id,
            scopeOfWork: scopeOfWork,
            companyName: companyName,
            contactSourceId: contactSources[0].id,
            firstName: personName ? personName?.shift() : "",
            lastName: personNameLength >= 2 ? personName?.pop() : "",
            middleName: personNameLength >= 2 ? personName?.join(" ") : "",
            MessageCenterId:
              message === "ComposeEmail"
                ? "00000000-0000-0000-0000-000000000000"
                : currentSelectedMailMessage?.instanceMainMessageCenterId,
            contactCompanyGroupAddressDto: {
              cityId: values?.cityId,
              countryId: values?.countryId,
            },
          };

          //  console.log("body1",body)
          dispatch(contactQuickEmailAdd(body, type));
        } else if (newValuePersonCompany) {
          let personNameLength = personId?.split(" ").length
            ? personId?.split(" ").length
            : values?.companyPersonId?.split(" ")?.length;
          let personName = values?.companyPersonId.split(" ");
          // console.log(personId, personName,personNameLength,"personName")
          body = {
            email: values.contactLabelDataValue,
            contactLabelId: emailLabelListing[1].id,
            companyId: companyId
              ? companyId
              : "00000000-0000-0000-0000-000000000000",
            scopeofWork: "",
            companyName: companyName,
            contactSourceId: contactSources[0].id,
            firstName: personName ? personName?.shift() : "",
            lastName: personNameLength >= 2 ? personName?.pop() : "",
            middleName: personNameLength >= 2 ? personName?.join(" ") : "",
            MessageCenterId:
              message === "ComposeEmail"
                ? "00000000-0000-0000-0000-000000000000"
                : currentSelectedMailMessage?.instanceMainMessageCenterId,
            contactCompanyGroupAddressDto: {
              cityId: values?.cityId,
              countryId: values?.countryId,
            },
          };
          //  console.log("body12",body)
          dispatch(contactQuickEmailAdd(body, type));
        } else if (newCompanyValue) {
          // let selectedPerson = contactPersonSearchListingResponse.find((obj) => obj.id === values.companyPersonId);
          let scopeOfWork = values.scopeOfWork && values.scopeOfWork.join(",");
          body = {
            email: values.contactLabelDataValue,
            contactLabelId: emailLabelListing[1].id,
            personId: personId,
            scopeofWork: scopeOfWork,
            companyName: companyName,
            contactSourceId: contactSources[0].id,
            firstName: "",
            middleName: "",
            lastName: "",
            MessageCenterId:
              message === "ComposeEmail"
                ? "00000000-0000-0000-0000-000000000000"
                : currentSelectedMailMessage?.instanceMainMessageCenterId,
            contactCompanyGroupAddressDto: {
              cityId: values?.cityId,
              countryId: values?.countryId,
            },
          };
          //  console.log("body2",body)
          dispatch(contactQuickEmailAdd(body, type));
        } else if (!newValuePersonCompany && !newCompanyValue) {
          let scopeOfWork = values.scopeOfWork && values.scopeOfWork.join(",");
          body = {
            email: values.contactLabelDataValue,
            contactLabelId: emailLabelListing[1].id,
            companyId: companyId
              ? companyId
              : "00000000-0000-0000-0000-000000000000",
            personId: personId,
            scopeofWork: scopeOfWork,
            companyName: companyName,
            contactSourceId: contactSources[0].id,
            firstName: "",
            middleName: "",
            lastName: "",
            MessageCenterId:
              message === "ComposeEmail"
                ? "00000000-0000-0000-0000-000000000000"
                : currentSelectedMailMessage?.instanceMainMessageCenterId,
            contactCompanyGroupAddressDto: {
              cityId: values?.cityId,
              countryId: values?.countryId,
            },
            // firstName: selectedPerson.fullName.split(' ')[0],
            // lastName: selectedPerson.fullName.split(' ').pop(),
          };

          console.log("body3", body);
          dispatch(contactQuickEmailAdd(body, type));
        }
      } else if (isEmpty(values.companyPersonId)) {
        let personNameLength = personId?.split(" ").length
          ? personId?.split(" ").length
          : values?.companyPersonId?.split(" ")?.length;
        let personName = values?.companyPersonId?.split(" ");
        let scopeOfWork = values.scopeOfWork && values.scopeOfWork.join(",");

        let body = {
          email: values.contactLabelDataValue,
          contactLabelId: emailLabelListing[1].id,
          scopeOfWork: scopeOfWork,
          companyId: companyId
            ? companyId
            : "00000000-0000-0000-0000-000000000000",
          companyName: companyName,
          // firstName: personId,
          // middleName: personId,
          // lastName: personId,
          personId: !newValuePersonCompany
            ? personId
            : "00000000-0000-0000-0000-000000000000",
          firstName: personName ? personName?.shift() : "",
          lastName: personNameLength >= 2 ? personName?.pop() : "",
          middleName: personNameLength >= 2 ? personName?.join(" ") : "",
          contactSourceId: contactSources[0].id,
          MessageCenterId:
            message === "ComposeEmail"
              ? "00000000-0000-0000-0000-000000000000"
              : currentSelectedMailMessage?.instanceMainMessageCenterId,
          contactCompanyGroupAddressDto: {
            cityId: values?.cityId,
            countryId: values?.countryId,
          },
        };
        // console.log("body4",body)
        dispatch(contactQuickEmailAdd(body, type));
      } else if (isEmpty(values.companyContactId)) {
        let personNameLength = personId?.split(" ").length
          ? personId?.split(" ").length
          : values?.companyPersonId?.split(" ")?.length;
        let personName = values?.companyPersonId.split(" ");
        let scopeOfWork = values.scopeOfWork && values.scopeOfWork.join(",");
        let body = {
          contactSourceId: contactSources[0].id,
          email: values.contactLabelDataValue,
          contactLabelId: emailLabelListing[1].id,
          scopeOfWork: scopeOfWork,
          contactCompanyId: companyId,

          companyId: companyId
            ? companyId
            : "00000000-0000-0000-0000-000000000000",
          // companyName: newCompanyValue
          //   ? companyId
          //   : "00000000-0000-0000-0000-000000000000",
          companyName: companyName,
          // firstName: personId,
          // middleName: personId,
          // lastName: personId,
          personId: !newValuePersonCompany
            ? personId
            : "00000000-0000-0000-0000-000000000000",
          firstName: personName ? personName?.shift() : "",
          lastName: personNameLength >= 2 ? personName?.pop() : "",
          middleName: personNameLength >= 2 ? personName?.join(" ") : "",
          MessageCenterId:
            message === "ComposeEmail"
              ? "00000000-0000-0000-0000-000000000000"
              : currentSelectedMailMessage?.instanceMainMessageCenterId,
          contactCompanyGroupAddressDto: {
            cityId: values?.cityId,
            countryId: values?.countryId,
          },
        };
        // console.log("body5",body,personName , values)
        dispatch(contactQuickEmailAdd(body, type));
      }
      // if (values.companyContactId) {
      // 	let body = {
      // 		...values,
      // 	};
      // 	if (values.contactLabelId && values.contactLabelDataValue) {
      // 		body = {
      // 			...body,
      // 			contactCompanyGroupLabelDtos: [
      // 				{
      // 					contactLabelId: values.contactLabelId,
      // 					contactLabelDataValue: values.contactLabelDataVbalue,
      // 				},
      // 			],
      // 		};
      // 	}
      // 	if (values.contactAddressLabelId && values.countryId && values.countryId) {
      // 		body = {
      // 			...body,
      // 			contactCompanyGroupAddressDtos: [
      // 				{
      // 					contactLabelId: values.contactAddressLabelId,
      // 					countryId: values.countryId,
      // 					cityId: values.countryId,
      // 					isPrimaryAddress: true,
      // 				},
      // 			],
      // 		};
      // 	}
      // 	if (newValuePersonCompany) {

      // 		body = {
      // 			...body,
      // 			companyContactId: '',
      // 			companyName: values.companyContactId,
      // 		};

      // 	} else {
      // 		let selectedCompany = allCountryListingResponse.find((obj) => obj.id === values.companyContactId);
      // 		body = {
      // 			...body,
      // 			companyName: selectedCompany.companyName,
      // 		};

      // 		dispatch(contactCompanyUpdate(body.companyContactId, body));
      // 	}
      // }
      // form.resetFields()
      // form.setFieldsValue({
      //   companyContactId: [],
      //   companyPersonId : []
      // });
      setPersonId("");
      setCompanyId("");
    } catch (errorInfo) {
      console.log("error", errorInfo);
    }
  };
  function info() {
    let data = scopeSearch.replace(/\s+/g, " ").trim();
    data = scopeSearch.split(" ");
    // let capitalizeData = data.map((element) => {
    //   return (
    //     element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
    //   );
    // });
    // let capitalizedCompareTags = compareTags.map((element) => {
    //   return (
    //     element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
    //   );
    // });
    data = data.filter((val) => !compareTags.includes(val));
    Modal.confirm({
      title: `Are you sure if you want to add new Scope of Work "${data}"`,
      okText: "Yes",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      cancelText: "No",
      onOk() {
        let val = data.map((list) => ({
          name: list,
        }));
        addScopeofWork(val, "");
        form.setFieldsValue({
          scopeOfWork: !isEmpty(form.getFieldsValue().scopeOfWork)
            ? [...form.getFieldsValue().scopeOfWork.concat(data)]
            : data,
        });
      },
      onCancel() {},
    });
  }

  return (
    <Form
      autoComplete="off"
      className="add__user__form"
      layout="vertical"
      form={form}
      name="control-hooks"
      {...layout}
    >
      <div className="quick_email_add_title">
        <h3>Quick Email Add</h3>
      </div>
      <Row style={{ marginBottom: 12 }} className="first_row_email_add">
        <Col span={6} className="inline-quick-email">
          <Form.Item
            name="contactLabelId"
            placeholder="Email Address"
            rules={[{ message: "Contact Label is required" }]}
            className="official_quick_add test"
          >
            <Select bordered={false} className="text-allign-center">
              {emailLabelListing?.map((email) => (
                <Option
                  value={email.id}
                  key={email.id}
                  className="text-akkal-middle"
                >
                  {email.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            name="contactLabelDataValue"
            rules={[
              {
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid Email Address",
              },
            ]}
            className="quick_email_add_top_email"
          >
            <Input
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              disabled={true}
              placeholder="Email Address"
              bordered={false}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Form.Item name="isCompanyOnly" valuePropName="checked" className="form__group" initialValue={false}>
				<Checkbox
					onChange={(e) => {
						if (e.target.checked) {
							setDisablePersonCompany(true);
							setDisableContactCompany(false);
							form.setFieldsValue({
								companyPersonId: null,
								countryId: null,
								cityId: null,
								designation: null,
								contactSourceId: null,
								contactAddressLabelId: null,
								scopeOfWork: null,
							});
						} else {
							setDisablePersonCompany(false);
							setDisableContactCompany(true);
							form.setFieldsValue({
								companyContactId: null,
								countryId: null,
								cityId: null,
								designation: null,
								contactSourceId: null,
								contactAddressLabelId: null,
								scopeOfWork: null,
							});
						}
					}}
				>
					<span style={{ marginLeft: 4 }}>Company Only</span>
				</Checkbox>
			</Form.Item> */}
      {/* name={` ${newValuePersonCompany ? '' : 'companyPersonId'}`} */}

      <div className="flex-box quick-addEmail test">
        <span style={{ width: "20%", color: "black" }}>Person:</span>
        <div className="input-box">
          <Form.Item
            spellcheck="false"
            name="companyPersonId"
            rules={[{ required: false }]}
          >
            <AutoComplete
              multiple
              bordered={false}
              defaultActiveFirstOption
              allowClear
              onClear={() => {
                setPersonId();
                setNewValuePersonCompany(false);
                setNotFoundValue();
              }}
              onChange={(e) => {
                if (!e) {
                  setPersonId();
                  setNewValuePersonCompany(false);
                  setNotFoundValue();
                }
              }}
              // notFoundContent={
              //   <>
              //     {!contactCompanyPersonSearchPaginationLoading
              //       ? isEmpty(contactPersonPaginationResponse?.items) && (
              //           <div
              //             onClick={() => {
              //               setNewValuePersonCompany(true);
              //               form.setFieldsValue({
              //                 companyPersonId: notFoundValue,
              //               });
              //             }}
              //           >
              //             {/* Create {"" + notFoundValue} */}
              //             Add New Person with a plus icon
              //           </div>
              //         )
              //       : contactCompanyPersonSearchPaginationLoading && "Loading"}
              //   </>
              // }
              loading={contactPersonPaginationLoading}
              onPopupScroll={(event) => {
                let maxScroll =
                  event.target.scrollHeight - event.target.clientHeight;
                let currentScroll = event.target.scrollTop;
                if (currentScroll === maxScroll) {
                  let MaxResultCount = pageSize;
                  let SkipCount = currentPage * pageSize + 10;
                  if (
                    contactPersonPaginationResponse?.totalCount > SkipCount ||
                    !contactPersonPaginationResponse
                  ) {
                    getDataPerson(
                      MaxResultCount,
                      SkipCount,
                      notFoundValue,
                      true
                    );
                  }
                }
              }}
              filterOption={(input, option) => {
                return (
                  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                  0
                );
              }}
              onSearch={(value) => {
                setNotFoundValue(value);
                setNewValuePersonCompany(true);
                setCurrentPage(1);
                setPersonId();
              }}
              onSelect={(option, value) => {
                setPersonId(option);
                form.setFieldsValue({
                  companyPersonId: value.label,
                });

                setNewValuePersonCompany(false);
                if (value) {
                  dispatch(contactPersonGetDetails(option));
                }
              }}
            >
              {allPersonListingState?.map((person) => (
                <Option
                  value={person.contactPersonId}
                  key={person.contactPersonId}
                  label={person.personFullName}
                >
                  <div style={{ display: "flex" }}>
                    <span> {person.personFullName}</span>
                    {person.contactCompanyName ? (
                      <span>({person.contactCompanyName})</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Option>
              ))}
              {contactPersonPaginationLoading && (
                <Option key="loading" label="loading">
                  ...loading
                </Option>
              )}
            </AutoComplete>

            {/* <Select
              bordered={false}
              autoFocus
              autoComplete="off"
              allowClear
              loading={contactPersonPaginationLoading}
              onPopupScroll={(event) => {
                let maxScroll =
                  event.target.scrollHeight - event.target.clientHeight;
                let currentScroll = event.target.scrollTop;
                if (currentScroll === maxScroll) {
                  let MaxResultCount = pageSize;
                  let SkipCount = currentPage * pageSize + 10;
                  if (
                    contactPersonPaginationResponse?.totalCount > SkipCount ||
                    !contactPersonPaginationResponse
                  ) {
                    getDataPerson(
                      MaxResultCount,
                      SkipCount,
                      notFoundValue,
                      true
                    );
                  }
                }
              }}
              onKeyDown={(e) => {
                if (
                  isEmpty(allPersonListingState) &&
                  (e.key === "Enter" || e.key === "Tab")
                ) {
                  setNewValuePersonCompany(true);
                  form.setFieldsValue({
                    companyPersonId: notFoundValue,
                  });
                  setNotFoundValue("");
                }
              }}
              optionFilterProp="children"
              showSearch
              defaultOpen={true}
              filterOption={(input, option) => {
                if (option?.key !== NEW_ITEM || option?.key !== "loading") {
                  return (
                    option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                    0
                  );
                }
              }}
              onChange={(value, option) => {
                if (option?.key === NEW_ITEM) {
                  let newAllPersonListingState = [
                    ...allPersonListingState,
                    { id: notFoundValue, fullName: notFoundValue },
                  ];
                  setAllPersonListingState(newAllPersonListingState);
                  setNewValuePersonCompany(true);
                  form.setFieldsValue({
                    companyPersonId: notFoundValue,
                  });
                  setNotFoundValue("");
                } else if (option?.key !== "loading") {
                  setNewValuePersonCompany(false);
                  if (value) {
                    dispatch(contactPersonGetDetails(value));
                  }
                }
              }}
              //   filterOption={(input, option) =>
              //     option?.children
              //       ?.toLowerCase()
              //       ?.indexOf(input?.toLowerCase()) >= 0
              //   }
              // disabled={disablePersonCompany}
              onSearch={(value) => {
                setNotFoundValue(value);
                setCurrentPage(1);
              }}
            >
              <Option
                key={NEW_ITEM}
                value={notFoundValue}
                label={notFoundValue}
              >
                <div style={{ display: "flex", flexDirection: "rowwrap" }}>
                  <div>{"create " + notFoundValue}</div>
                  <div style={{ width: "100%" }}>
                    {isEmpty(contactPersonPaginationResponse?.items) &&
                      contactPersonPaginationLoading && <Spin />}
                  </div>
                </div>
              </Option>

              {allPersonListingState?.map((person) => (
                <Option
                  value={person.contactPersonId}
                  key={person.contactPersonId}
                  label={person.personFullName}
                >
                  <div style={{ display: "flex" }}>
                    <span> {person.personFullName}</span>
                    {person.contactCompanyName ? (
                      <span>({person.contactCompanyName})</span>
                    ) : (
                      ""
                    )}
                  </div>
                </Option>
              ))}
              {contactPersonPaginationLoading && (
                <Option key="loading" label="loading">...loading</Option>
              )}
            </Select> */}
          </Form.Item>
        </div>
        {personId &&
        !contactPersonPaginationLoading &&
        form.getFieldValue().companyPersonId ? (
          <img src={complete} />
        ) : (
          <>
            {form.getFieldValue().companyPersonId &&
              !contactPersonPaginationLoading && (
                <Tooltip title="Person is not in the list">
                  <ExclamationOutlined />
                </Tooltip>
              )}
            {/* {!contactPersonPaginationLoading &&
              form.getFieldValue().companyPersonId && (
                <PlusOutlined
                  style={{ color: "red" }}
                  onClick={() => {
                    if (notFoundValue) {
                      let newAllPersonListingState = [
                        ...allPersonListingState,
                        { id: notFoundValue, fullName: notFoundValue },
                      ];
                      setAllPersonListingState(newAllPersonListingState);
                      setNewValuePersonCompany(true);
                      form.setFieldsValue({
                        companyPersonId: notFoundValue,
                      });

                      setPersonId(notFoundValue);
                      setNotFoundValue();
                      setDisableSaveButton(false);
                    }
                  }}
                />
              )} */}
          </>
        )}
      </div>

      <div className="flex-box quick-addEmail">
        <span style={{ width: "20%", color: "black" }}>Company:</span>
        <div className="input-box">
          <Form.Item
            spellcheck="false"
            name="companyContactId"
            rules={[{ required: false }]}
          >
            {/* <Select
              showSearch
              loading={contactCompanyPaginationLoading}
              autoComplete="off"
              bordered={false}
              onPopupScroll={(event) => {
                let maxScroll =
                  event.target.scrollHeight - event.target.clientHeight;
                let currentScroll = event.target.scrollTop;
                if (currentScroll === maxScroll) {
                  let MaxResultCount = pageSize;
                  let SkipCount = companyCurrentPage * pageSize;
                  if (
                    contactCompanyPaginationResponse?.totalCount > SkipCount ||
                    !contactCompanyPaginationResponse
                  ) {
                    getData(MaxResultCount, SkipCount, notFoundCompany, true);
                  }
                }
              }}
              allowClear
              notFoundContent={
                <>
                  {contactCompanyPaginationLoading
                    ? "Loading"
                    : isEmpty(contactCompanyPaginationResponse?.items) && (
                        <div
                          onClick={() => {
                            setNewCompanyValue(true);
                            form.setFieldsValue({
                              companyContactId: notFoundCompany,
                            });
                          }}
                        >
                          Create {"" + notFoundCompany}
                        </div>
                      )}
                </>
              }
              onKeyDown={(e, value, i) => {
                if (
                  notFoundCompany &&
                  isEmpty(contactCompanyPaginationResponse?.items) &&
                  (e.key === "Enter" || e.key === "Tab")
                ) {
                  setNewCompanyValue(true);
                  form.setFieldsValue({
                    companyContactId: notFoundCompany,
                  });
                  setNotFoundCompany();
                }
              }}
              onChange={(value, option) => {
                if (option?.key === NEW_ITEM) {
                  let newAllCompanyListingState = [
                    ...allCompanyListingState,
                    { id: NEW_ITEM, fullName: notFoundValue },
                  ];
                  form.setFieldsValue({
                    companyContactId: notFoundCompany,
                  });
                  setNotFoundCompany();
                  setAllCompanyListingState(newAllCompanyListingState);
                  setNewCompanyValue(true);
                  setNotFoundCompany("");
                } else if (option?.key === "loading") {
                  setNewCompanyValue(false);
                  if (value) {
                    dispatch(contactCompanyGetDetails(value));
                  }
                }
              }}
              optionFilterProp="children"
              filterOption={(input, option) => {
                if (option?.key !== NEW_ITEM || option?.key !== "loading") {
                  return (
                    option?.key?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                      0 ||
                    option?.label
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  );
                }
              }}
              // disabled={disableContactCompany}
              onSearch={(value) => {
                setNotFoundCompany(value);
                setCompanyCurrentPage(1);
              }}
            >
              <Option
                key={NEW_ITEM}
                value={notFoundCompany}
                label={notFoundCompany}
              >
                <div style={{ display: "flex", flexDirection: "rowwrap" }}>
                  <div> {"create " + notFoundCompany}</div>
                  <div style={{ width: "100%" }}>
                    {isEmpty(contactCompanyPaginationResponse.items) &&
                      contactCompanyPaginationLoading && <Spin />}
                  </div>
                </div>
              </Option>
              {contactCompanyPaginationResponse.items?.map((company) => (
                <Option
                  value={company.contactCompanyId}
                  key={company.contactCompanyId}
                  label={company.contactCompanyName}
                >
                  {company.contactCompanyName}
                </Option>
              ))}
              {contactCompanyPaginationLoading && (
                <Option key="loading">Loading...</Option>
              )}
            </Select> */}
            <AutoComplete
              showSearch
              listHeight={150}
              onChange={(e) => {
                if (!e) {
                  setCompanyId();
                }
                setNewQuickCompanyValue(e);
              }}
              onClear={() => {
                setCompanyId();
                setNewCompanyValue(false);
              }}
              defaultActiveFirstOption
              loading={contactCompanyPaginationLoading}
              bordered={false}
              autoComplete="off"
              onPopupScroll={(event) => {
                let maxScroll =
                  event.target.scrollHeight - event.target.clientHeight;
                let currentScroll = event.target.scrollTop;
                if (currentScroll === maxScroll) {
                  let MaxResultCount = pageSize;
                  let SkipCount = companyCurrentPage * pageSize;
                  if (
                    contactCompanyPaginationResponse?.totalCount > SkipCount ||
                    !contactCompanyPaginationResponse
                  ) {
                    getData(MaxResultCount, SkipCount, notFoundCompany, true);
                  }
                }
              }}
              allowClear
              filterOption={(input, option) => {
                return (
                  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                  0
                );
              }}
              onSearch={(value) => {
                setNotFoundCompany(value);
                setCompanyCurrentPage(1);
                setNewCompanyValue(true);
                setCompanyId();
              }}
              onSelect={(option, value) => {
                setCompanyId(option);
                form.setFieldsValue({
                  companyContactId: value.label,
                });
                setNewCompanyValue(false);
                if (value) {
                  dispatch(contactCompanyGetDetails(option));
                }
              }}
            >
              {contactCompanyPaginationResponse?.items?.map((company) => (
                <Option
                  value={company.contactCompanyId}
                  key={company.contactCompanyId}
                  label={company.contactCompanyName}
                >
                  {company.contactCompanyName}
                </Option>
              ))}
              {contactCompanyPaginationLoading && (
                <Option key="loading">Loading...</Option>
              )}
            </AutoComplete>
          </Form.Item>
        </div>
        {companyId &&
        !contactCompanyPaginationLoading &&
        form.getFieldValue().companyContactId ? (
          <img src={complete} />
        ) : (
          <>
            {form.getFieldValue().companyContactId &&
              !contactCompanyPaginationLoading && (
                <Tooltip title="Company is not in the list">
                  <ExclamationOutlined />
                </Tooltip>
              )}
            {/* {!contactCompanyPaginationLoading &&
              form.getFieldValue().companyContactId && (
                <PlusOutlined
                  style={{ color: "red" }}
                  onClick={() => {
                    if (notFoundCompany) {
                      let newAllCompanyListingState = [
                        ...allCompanyListingState,
                        { id: NEW_ITEM, fullName: notFoundCompany },
                      ];
                      form.setFieldsValue({
                        companyContactId: notFoundCompany,
                      });

                      setAllCompanyListingState(newAllCompanyListingState);
                      setNewCompanyValue(true);
                      setNotFoundCompany("");
                      setCompanyId(notFoundCompany);
                      setDisableSaveButton(false);
                    }
                  }}
                />
              )} */}
          </>
        )}
      </div>

      <div className="flex-box quick-addEmail">
        <span style={{ width: "20%", color: "black" }}>Remarks:</span>
        <div className="input-box">
          <Form.Item
            spellcheck="false"
            name="remarks"
            rules={[{ required: false }]}
          >
            <Input size="small" bordered={false} />
          </Form.Item>
        </div>
      </div>

      {!isEmpty(allCompanyListingState) &&
      allCompanyListingState.filter((o) => o.fullName === notFoundValue)
        .length === 0 ? (
        <div className="flex-box quick-addEmail">
          <span style={{ width: "20%" }}>Scope:</span>
          <div className="input-box">
            <Form.Item name="scopeOfWork" rules={[{ required: true }]}>
              <Select
                bordered={false}
                autoComplete="off"
                placeholder="Scopes of Work"
                size="default"
                mode="multiple"
                onPopupScroll={(e) => {
                  console.log("duplicated", e);
                }}
                notFoundContent={
                  !isEmpty(scopeSearch)
                    ? isEmpty(scopeTags) && `Create ${scopeSearch}`
                    : null
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    setScopeTags([]);
                    setScopeSearch("");
                    if (isEmpty(scopeTags) && !isEmpty(scopeSearch)) {
                      info();
                    }
                  }
                }}
                // onInputKeyDown={(e) => {
                //   if(e.key === "Enter"){
                //     setScopeTags([]);
                //     setScopeTags('')

                //   }

                // }}
                onSearch={(e) => {
                  setScopeSearch(e);
                }}
                // onSelect={(value, option) => addScopeofWork(value, option)}
                // open={dropdownscope}
                listHeight={100}
                // // onInputKeyDown={onInputKeyDown}
                onClear={() => {
                  setScopeSearch("");
                }}
              >
                {scopeTags?.map((tag) => (
                  <Option value={tag.name} key={tag.id}>
                    {tag.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      ) : (
        (newQuickCompanyValue || form.getFieldValue().companyContactId) && (
          <>
            <div className="flex-box quick-addEmail">
              <span style={{ width: "20%", color: "black" }}>Scope:</span>
              <div className="input-box">
                <Form.Item name="scopeOfWork" rules={[{ required: false }]}>
                  <Select
                    autoComplete="off"
                    placeholder="Scopes of Work"
                    size="default"
                    mode="multiple"
                    onPopupScroll={(e) => {
                      console.log("duplicated", e);
                    }}
                    notFoundContent={
                      !isEmpty(scopeSearch)
                        ? isEmpty(scopeTags) && `Create ${scopeSearch}`
                        : null
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Tab") {
                        setScopeTags([]);
                        setScopeSearch("");
                        if (isEmpty(scopeTags) && !isEmpty(scopeSearch)) {
                          info();
                        }
                      }
                    }}
                    // onInputKeyDown={(e) => {
                    //   if(e.key === "Enter"){
                    //     setScopeTags([]);
                    //     setScopeTags('')

                    //   }

                    // }}
                    onSearch={(e) => {
                      setScopeSearch(e);
                    }}
                    // onSelect={(value, option) => addScopeofWork(value, option)}
                    // open={dropdownscope}
                    listHeight={100}
                    // // onInputKeyDown={onInputKeyDown}
                    onClear={() => {
                      setScopeSearch("");
                    }}
                  >
                    {scopeTags?.map((tag) => (
                      <Option value={tag.name} key={tag.id}>
                        {tag.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="flex-box quick-addEmail">
              <span style={{ width: "20%", color: "black" }}>Country:</span>
              <div className="input-box">
                <Form.Item name="countryId" rules={[{ required: false }]}>
                  <Select
                    autoComplete="off"
                    placeholder="Country"
                    size="default"
                    // onPopupScroll={(e) => {
                    //   console.log("duplicated", e);
                    // }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch={true}
                    listHeight={200}
                    onSelect={(value) => {
                      setSelectedCountry(value);
                    }}
                  >
                    {countries?.map((tag) => (
                      <Option value={tag.id} key={tag.id}>
                        {tag.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="flex-box quick-addEmail">
              <span style={{ width: "20%", color: "black" }}>City:</span>
              <div className="input-box">
                <Form.Item name="cityId" rules={[{ required: false }]}>
                  <Select
                    autoComplete="off"
                    placeholder="City"
                    size="default"
                    // onPopupScroll={(e) => {
                    //   console.log("duplicated", e);
                    // }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch={true}
                    listHeight={200}
                  >
                    {filteredCity?.map((tag) => (
                      <Option value={tag.id} key={tag.id}>
                        {tag.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </>
        )
      )}
      <div className="quick_add_email ">
        {/* <button
          className={`button_add_task ${
            disableSavedButton && `disable_button`
          }`}
          style={{ marginTop: 12 }}
          loading={contactQuickAddEmailLoading}
          // type="primary"
          htmlType="submit"
          onClick={() => {
            handleSubmit();
            // form.resetFields()
          }}
          disabled={disableSavedButton}
        >
          Save
        </button> */}
        <Button
          className={`button_add_task ${
            disableSavedButton && `disable_button`
          }`}
          style={{ marginTop: 12 }}
          loading={contactQuickAddEmailLoading}
          // type="primary"
          htmlType="submit"
          onClick={() => {
            handleSubmit();
            // form.resetFields()
          }}
          disabled={disableSavedButton}
        >
          Save
        </Button>
        {/* {addMoreDetails && (
          <Button
            style={{ marginTop: 12 }}
            type="primary"
            htmlType="submit"
            onClick={() => {
              handleSubmit("update");
              setAddMoreDetails();
              form.resetFields();
            }}
          >
            {isEmpty(contactCompanyGetDetailsResponse) &&
            isEmpty(contactPersonGetDetailsResponse) ? (
              <Link
                to={`/addUpdatePersonDrawer?compose=${addMoreDetails}`}
                target="_blank"
              >
                Add More Details
              </Link>
            ) : (
              <Link
                to={`/addUpdatePersonDrawer?compose=${addMoreDetails}`}
                target="_blank"
              >
                Add More Details
              </Link>
            )}
          </Button>
        )} */}
      </div>
      {/* <div disable>{!personId ? "! To save the info please click the plus icon":""}</div> */}
    </Form>
  );
};

export default AddNewQuickEmail;
