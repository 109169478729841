import { init, success, finish, error } from "services/common";
import { CalendarEventsService } from "./api";
import { message } from "antd";
import { CALENDAR_EVENTS_TYPES } from "../../types";

const calendarEventsService = new CalendarEventsService();

export const getCalenderListEvents = (query) => {
  return async (dispatch) => {
    dispatch(init(CALENDAR_EVENTS_TYPES.CALENDAR_EVENTS_LISTS));
    const response = await calendarEventsService.getCalenderListEvents(query);
    if (response.isSuccess) {
      dispatch(
        success(CALENDAR_EVENTS_TYPES.CALENDAR_EVENTS_LISTS, response.data)
      );
      dispatch(finish(CALENDAR_EVENTS_TYPES.CALENDAR_EVENTS_LISTS));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
// export const getAllPrivateEvents = (query) => {
//   return async (dispatch) => {
//     dispatch(init(CALENDAR_EVENTS_TYPES.CALENDAR_PRIVATE_EVENTS_LISTS));
//     const response = await calendarEventsService.getAllPrivateEvents(query);
//     console.log("response here", response);
//     dispatch(finish(CALENDAR_EVENTS_TYPES.CALENDAR_PRIVATE_EVENTS_LISTS));
//     if (response?.isSuccess) {
//       dispatch(
//         success(
//           CALENDAR_EVENTS_TYPES.CALENDAR_PRIVATE_EVENTS_LISTS,
//           response.data
//         )
//       );
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//     return response.data; // optional: return the data
//   };
// };
export const getAllPrivateEvents = (query) => {
  return async (dispatch) => {
    dispatch(init(CALENDAR_EVENTS_TYPES.CALENDAR_PRIVATE_EVENTS_LISTS));
    const response = await calendarEventsService.getAllPrivateEvents(query);
    if (response.isSuccess) {
      dispatch(
        success(
          CALENDAR_EVENTS_TYPES.CALENDAR_PRIVATE_EVENTS_LISTS,
          response.data
        )
      );
      dispatch(finish(CALENDAR_EVENTS_TYPES.CALENDAR_PRIVATE_EVENTS_LISTS));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getAllPublicEvents = (query) => {
  return async (dispatch) => {
    dispatch(init(CALENDAR_EVENTS_TYPES.CALENDAR_PUBLIC_EVENTS_LISTS));
    const response = await calendarEventsService.getAllPublicEvents(query);
    if (response.isSuccess) {
      dispatch(
        success(
          CALENDAR_EVENTS_TYPES.CALENDAR_PUBLIC_EVENTS_LISTS,
          response.data
        )
      );
      dispatch(finish(CALENDAR_EVENTS_TYPES.CALENDAR_PUBLIC_EVENTS_LISTS));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const calendarEventpagination = (query) => {
  return async (dispatch) => {
    dispatch(init(CALENDAR_EVENTS_TYPES.CALENDAR_EVENT_PAGINATION));
    const response = await calendarEventsService.calendarEventPagination(query);
    dispatch(finish(CALENDAR_EVENTS_TYPES.CALENDAR_EVENT_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(CALENDAR_EVENTS_TYPES.CALENDAR_EVENT_PAGINATION, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getCalenderFilters = () => {
  return (dispatch) => {
    dispatch(init(CALENDAR_EVENTS_TYPES.CALENDAR_FILTERS));
    const response = calendarEventsService.getCalenderFilters();
    dispatch(finish(CALENDAR_EVENTS_TYPES.CALENDAR_FILTERS));
    if (response.isSuccess) {
      dispatch(success(CALENDAR_EVENTS_TYPES.CALENDAR_FILTERS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const updateCalenderListEvent = (body, eventFilter) => {
//   console.log("updatebody",body)
//   return async (dispatch) => {
//     dispatch(init(CALENDAR_EVENTS_TYPES.UPDATE_CALENDAR_EVENT));
//     const response = await calendarEventsService.updateCalenderListEvent(body);
//     dispatch(finish(CALENDAR_EVENTS_TYPES.UPDATE_CALENDAR_EVENT));
//     if (response.isSuccess) {
//       dispatch(success(CALENDAR_EVENTS_TYPES.UPDATE_CALENDAR_EVENT, response.data));
//       // let appendUrl = `skipCount=0${eventFilter ? eventFilter : ''}`;
//       // dispatch(getCalenderListEvents(appendUrl));
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

export const updateCalenderListEvent = (body, query) => {
  return async (dispatch) => {
    dispatch(init(CALENDAR_EVENTS_TYPES.UPDATE_CALENDAR_EVENT));
    const response = await calendarEventsService.updateCalenderListEvent(body);
    dispatch(finish(CALENDAR_EVENTS_TYPES.UPDATE_CALENDAR_EVENT));
    if (response.isSuccess) {
      dispatch(
        success(CALENDAR_EVENTS_TYPES.UPDATE_CALENDAR_EVENT, response.data)
      );
      // dispatch(getTodoEventsPagination(`maxResultCount=30&skipCount=0`));
      message.success("Event updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const englishToNepaliDateRangeConverter = (firstDate, lastDate) => {
  return async (dispatch) => {
    dispatch(
      init(CALENDAR_EVENTS_TYPES.ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER)
    );
    const response =
      await calendarEventsService.englishToNepaliDateRangeConverter(
        firstDate,
        lastDate
      );
    if (response?.isSuccess) {
      dispatch(
        success(
          CALENDAR_EVENTS_TYPES.ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER,
          response?.data
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};
