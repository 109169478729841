import { Button, Form, Input, Layout, Typography } from "antd";
import React from "react";

const SettlementAccountantInputForm = ({
  commentName,
  amountName,
  loading,
  onClick,
}) => {
  return (
    <Layout.Content>
      <Typography
        style={{
          paddingTop: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        Account Section
      </Typography>
      <Layout.Content
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 5,
          marginTop: 10,
        }}
      >
        <Form.Item label="Settlement Balance" name={amountName}>
          <Input autoComplete="off" readOnly style={{ marginBottom: 5 }} />
        </Form.Item>
        <Form.Item name={commentName}>
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 6 }}
            autoComplete="off"
            placeholder="Remarks"
          />
        </Form.Item>
        <Layout.Content
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: 5,
          }}
        >
          <Button
            loading={loading}
            htmlType="submit"
            onClick={onClick}
            style={{
              backgroundColor: "#3879C5",
              color: "white",
              borderRadius: 4,
            }}
          >
            Done
          </Button>
        </Layout.Content>
      </Layout.Content>
    </Layout.Content>
  );
};

export default SettlementAccountantInputForm;
