import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Input,
  Select,
  Layout,
} from "antd";
import { validateMessages } from "utils/misc";
import { useForm } from "antd/lib/form/Form";
import {
  addGuestEvent,
  updateGuestEvent,
} from "services/redux/organizer/guestEvents/action";
import { isEmpty } from "lodash";
import moment from "moment";
import { getAllUsers } from "services/remanentCalls";
const { Header, Footer } = Layout;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const { Option } = Select;
const GuestEntryForm = (props) => {
  const guestEventsState = useSelector((state) => state.guestEvents);
  const { addGuestEventResp } = guestEventsState;
  const { allGuestEvents, guestEventsLoading, guestEventStatuses } =
    guestEventsState;

  const [addWatcher, setAddWatcher] = useState(false);
  const [isPrivatee, setIsPrivatee] = useState(false);
  const [guestEventCandidateDtos, setguestEventCandidateDtos] = useState([]);
  const [filterMeetingInvitess, setFilterMeetingInvitess] = useState([]);
  const [assigneInvites, setAssigneInvites] = useState([]);
  const [meetingInvitees, setMeetingInvitees] = useState();

  const dispatch = useDispatch();

  const [form] = useForm();
  const {
    editGuestList,
    drawerVisibility,
    isUpdateCountry,
    onAddUpdateCountryClose,
    onCloseNoticeBoard,
  } = props;

  useEffect(() => {
    if (!isEmpty(assigneInvites)) {
      setFilterMeetingInvitess(assigneInvites);
    }
  }, [assigneInvites]);

  useEffect(() => {
    if (!isEmpty(meetingInvitees)) {
      setAssigneInvites(meetingInvitees);
    }
  }, [meetingInvitees]);

  useEffect(() => {
    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
    // dispatch(getTodoEventsKanban())
  }, []);

  useEffect(() => {
    if (!isEmpty(addGuestEventResp)) {
      form.resetFields();
      // editGuestList({});
    }
  }, [addGuestEventResp]);
  // useEffect(() => {
  // props.onCloseNoticeBoard();
  // },[addGuestEventResp])
  useEffect(() => {
    if (!isUpdateCountry) {
      form.resetFields();
      setIsPrivatee(false);
      setAddWatcher(false);
      return;
    } else {
      if (isUpdateCountry.isPrivate) {
        setIsPrivatee(true);
        setAddWatcher(false);
      } else if (isUpdateCountry.isWatcher == true) {
        setAddWatcher(true);
        setIsPrivatee(false);
      }
      let todoWatcherCandidate =
        isUpdateCountry?.guestEventCandidateDtos.filter(
          (candidate) => candidate.isWatcher === true
        );

      form.setFieldsValue({
        companyName: isUpdateCountry.companyName,
        guestEventStatusId: isUpdateCountry.guestEventStatusId,
        guestName: isUpdateCountry.guestName,
        notes: isUpdateCountry.notes,
        isPrivate: isUpdateCountry.isPrivate,
        isWatcher: isUpdateCountry.isWatcher,
        arrivalDate: moment(isUpdateCountry.arrivalDate),
        departureDate: moment(isUpdateCountry.departureDate),
        todoEventWatcherDtos: todoWatcherCandidate.map((x) => x.candidateId),
      });
    }
  }, [isUpdateCountry]);

  useEffect(() => {
    props.onAddUpdateCountryClose();
  }, [addGuestEventResp]);

  const changeEmployeeWatcherDtos = (value, option) => {
    let newTodoEventWatcherDtos = option.map((candidate) => {
      return {
        candidateId: candidate.value,
        candidateName: candidate.title,
        isPrimary: false,
        isWatcher: true,
      };
    });
    setguestEventCandidateDtos(newTodoEventWatcherDtos);
  };
  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {
      // message.error('Failure');
    });

    if (resp && resp.status === 200) {
      setFilterMeetingInvitess(resp.data);
    }
  };
  const onFinish = async () => {
    try {
      let values = await form.validateFields();

      delete values.todoEventWatcherDtos;
      values.guestEventCandidateDtos = guestEventCandidateDtos;
      if (addWatcher) {
        values.isPrivate = false;
      } else if (isPrivatee) {
        values.isPrivate = true;
        values.isWatcher = false;
        values.guestEventCandidateDtos = [];
      } else {
        values.isPrivate = false;
        values.isWatcher = false;
      }
      delete values.addWatcher;
      if (isUpdateCountry) {
        dispatch(updateGuestEvent(isUpdateCountry.id, values));
      } else {
        dispatch(addGuestEvent(values));
      }
    } catch (errorInfo) {
      console.error("failed", errorInfo);
    }
  };
  return (
    <Drawer
      visible={drawerVisibility}
      onClose={onAddUpdateCountryClose}
      width={472}
      maskClosable={false}
      className="drawer"
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">Guest Entry</h4>
      </Header>
      <Form
        className="drawer-form-wrapper"
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        form={form}
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              label="Guest Name"
              name="guestName"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input placeholder="Please enter guest name" autoComplete="off" />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              label="Company Namee"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input
                placeholder="Please enter company name"
                autoComplete="off"
              />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <div className="drawer-flex">
              <Form.Item
                label="Arrival Date"
                className="form__group"
                name="arrivalDate"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return current < moment();
                  }}
                  format="YYYY/MM/DD"
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                label="Departure Date"
                className="form__group"
                name="departureDate"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return current < moment();
                  }}
                  format="YYYY/MM/DD"
                  autoComplete="off"
                />
              </Form.Item>
            </div>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              label="Status"
              className="form__group"
              name="guestEventStatusId"
            >
              <Select placeholder="Select status" autoComplete="off">
                {!isEmpty(props.guestEventStatuses) &&
                  props.guestEventStatuses.map((status, index) => {
                    return (
                      <Option value={status.id} key={index}>
                        {status.displayName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              label="Purpose of Visit"
              name="notes"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input.TextArea
                className="guest__entry__textbox"
                autoComplete="off"
              />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <div className="drawer-flex"></div>
          </div>

          <div className="drawer-form-group">
            <div className="drawer-flex">
              <Form.Item
                name="isPrivate"
                className="form__group"
                valuePropName="notchecked"
                initialValue={false}
              >
                <Checkbox
                  defaultChecked={false}
                  checked={addWatcher === false && isPrivatee === true}
                  onChange={() => {
                    setAddWatcher(false);
                    setIsPrivatee(!isPrivatee);
                  }}
                >
                  <span className="guest__entry__label">Private</span>
                </Checkbox>
              </Form.Item>
            </div>
          </div>

          <div className="drawer-form-group">
            <Form.Item
              name="isWatcher"
              valuePropName="checked"
              className="form__group"
              initialValue={false}
            >
              <Checkbox
                defaultChecked={false}
                checked={addWatcher === true && isPrivatee === false}
                onChange={() => {
                  setAddWatcher(!addWatcher);
                  setIsPrivatee(false);
                }}
              >
                <span className="guest__entry__label"> Is Watcher</span>
              </Checkbox>
              {addWatcher && (
                <div className="drawer-form-group">
                  <Form.Item
                    label="Watcher"
                    className="form__group"
                    name="todoEventWatcherDtos"
                  >
                    <Select
                      mode="multiple"
                      showSearch
                      placeholder="Watchers"
                      optionFilterProp="label"
                      allowClear={true}
                      onChange={changeEmployeeWatcherDtos}
                      disabled={!addWatcher}
                      autoComplete="off"
                    >
                      {!isEmpty(filterMeetingInvitess) &&
                        filterMeetingInvitess.map((employee, index) => {
                          return (
                            <Option
                              value={employee.appUserId}
                              title={employee.fullName}
                              key={index}
                              label={[employee.fullName, employee.userName]}
                            >
                              <div className="demo-option-label-item">
                                <span role="img">{employee.fullName} </span>[
                                {employee.userName.toUpperCase()}]
                              </div>
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              )}
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <div className="drawer-flex">
              <Form.Item
                name="isActive"
                className="form__group"
                valuePropName="checked"
                initialValue={true}
              >
                <Checkbox>
                  <span className="guest__entry__label"> Active</span>
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateCountryClose}>Cancel</Button>
          <Button
            className="guest__entry__button"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default GuestEntryForm;
