import {
  Button,
  Col,
  // DatePicker,
  // Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import { isEmpty } from "lodash";
// import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getReferenceListing } from "services/redux/Reference/action";
import {
  // actionForAddUpdateCEADTender,
  actionForAddUpdateCeadFinancialInformation,
} from "services/redux/cead/action";
import {
  // getContactCompanyPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import QAAccountSpecific from "./qA";
import AccountSpecificDetails from "./details";
import commaSeparatorforList from "utils/commaSeparatorList";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function CreateCead({
  formAccountSpecific,
  QAForm,
  singleTenderCeadData,
  // companyOptions,
  currencyOptions,
}) {
  const dispatch = useDispatch();

  const { tenderCeadAccSpecificDetailsLoading } = useSelector(
    (state) => state.ceadReducer
  );

  // const [IsFeeInvoice, setFeeInvoice] = useState(false);

  // const [fileList, setFileList] = useState([]);

  const [selectedAccSpecificShow, setAccSpecificShow] = useState(false);

  const { contactPersonPaginationResponse } = useSelector(
    (state) => state.contactCompanyPerson
  );

  useEffect(() => {
    let apiBody = {
      MaxResultCount: 50,
      SkipCount: 0,
      searchKeyword: singleTenderCeadData?.principalName,
    };
    if (isEmpty(contactPersonPaginationResponse)) {
      dispatch(getContactPersonPagination(apiBody));
    }
  }, []);

  useEffect(() => {
    if (!tenderCeadAccSpecificDetailsLoading) {
      setAccSpecificShow(false);
    }
  }, [tenderCeadAccSpecificDetailsLoading]);

  let contactPersonOptions = contactPersonPaginationResponse?.items?.map(
    (y) => {
      return {
        label: y?.personFullName,
        value: y?.contactPersonId,
      };
    }
  );

  const onAccountSpecificFinish = (values) => {
    let contractFinancialRemarkDto = {
      contractFinancialRemarks: values.contractFinancialRemarks,
      paymentClaimRemarks: values.paymentClaimRemarks,
    };

    let tenderCEADContractFinancialsInformationDtos =
      values?.contractFinancial?.map((x) => {
        return {
          ...x,
          isActive: true,
        };
      });

    let apiData = {
      contractFinancialRemarkDto,
      tenderCEADContractFinancialsInformationDtos,
    };

    dispatch(
      actionForAddUpdateCeadFinancialInformation(
        apiData,
        singleTenderCeadData?.id,
        "CEADFinancialType=0"
      )
    );
  };

  const handleInputChange = (index) => (e) => {
    const items = formAccountSpecific?.getFieldsValue().contractFinancial;

    // const value = e.target.value;
    console.log(e.target.id?.split("_").includes("contractAmount"));
    const vatPercentage =
      formAccountSpecific?.getFieldsValue()?.contractFinancial?.[index]
        ?.vatPercentage ?? 0;
    const exclusions =
      formAccountSpecific?.getFieldsValue()?.contractFinancial?.[index]
        ?.exclusions ?? 0;
    const feePercentage =
      formAccountSpecific?.getFieldsValue()?.contractFinancial?.[index]
        ?.feePercentage ?? 0;
    const exchangeRate =
      formAccountSpecific?.getFieldsValue()?.contractFinancial?.[index]
        ?.exchangeRate ?? 1;
    const vatOnFeePercentage =
      formAccountSpecific?.getFieldsValue()?.contractFinancial?.[index]
        ?.vatOnFeePercentage ?? 0;

    let ctrctAmt = "";
    let _ctrctAmt = "";

    if (e.target.id?.split("_").includes("contractAmount")) {
      ctrctAmt = e.target.value?.replace(/,/g, "");
      _ctrctAmt = commaSeparatorforList(e);
    } else {
      _ctrctAmt =
        formAccountSpecific?.getFieldsValue()?.contractFinancial?.[index]
          ?.contractAmount;
      ctrctAmt = _ctrctAmt.replace(/,/g, "");
    }

    let cvExclVat = ctrctAmt - (vatPercentage / 100) * ctrctAmt;
    let feeableCV = cvExclVat - exclusions;
    let feeAmt = (feeableCV * feePercentage) / 100;
    let equivalentNCFree = exchangeRate * feeAmt;
    let feeAmtAfterVat = (vatOnFeePercentage / 100) * feeAmt + feeAmt;

    items[index].contractAmount = _ctrctAmt;
    items[index].cvExclVat = cvExclVat.toLocaleString();
    items[index].exclusions = exclusions;
    items[index].feeableCV = feeableCV.toLocaleString();
    items[index].feePercentage = feePercentage;
    items[index].feeAmt = feeAmt.toLocaleString();
    items[index].exchangeRate = exchangeRate;
    items[index].equivalentNCFree = equivalentNCFree.toLocaleString();
    items[index].vatOnFeePercentage = vatOnFeePercentage;
    items[index].feeAmtAfterVat = feeAmtAfterVat.toLocaleString();
    formAccountSpecific.setFieldsValue({
      contractFinancial: items,
    });
  };

  // const handleFileChange = ({ fileList: newFileList }) =>
  //   setFileList(newFileList);

  return (
    <>
      <Typography.Title level={5} style={{ background: "#DFDFDF" }}>
        Contract Financials
        {!selectedAccSpecificShow && (
          <span
            onClick={() => {
              setAccSpecificShow(!selectedAccSpecificShow);
            }}
            className="bid-pointer-cursor"
          >
            <EditOutlined />
          </span>
        )}
      </Typography.Title>
      {selectedAccSpecificShow ? (
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          form={formAccountSpecific}
          onFinish={onAccountSpecificFinish}
        >
          <Row gutter={[24, 0]}>
            <Col span={24} style={{ marginTop: "10px" }}>
              <Form.List name="contractFinancial">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row key={field.key} gutter={[10, 0]}>
                        <Col span={1}>{index + 1}</Col>

                        <Col span={3}>
                          <Form.Item
                            key={field.key}
                            label="Foreign Currency"
                            name={[field.name, "currencyId"]}
                            fieldKey={[field.fieldKey, "currencyId"]}
                            rules={[
                              {
                                required: true,
                                message: "Foreign Currency is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Select
                              options={currencyOptions}
                              optionFilterProp="label"
                              placeholder="Select Currency"
                              allowClear
                              // showSearch
                              // onSearch={(value) => {
                              //   let apiBody = {
                              //     MaxResultCount: 40,
                              //     SkipCount: 0,
                              //     searchKeyword: value,
                              //   };
                              //   dispatch(getContactCompanyPagination(apiBody));
                              // }}
                              // onChange={(value, label) => {
                              //   setSelectedClientCompany(label?.label);
                              //   setSelectedPrincipalCompany();
                              // }}
                              // onFocus={() => {
                              //   let apiBody = {
                              //     MaxResultCount: 40,
                              //     SkipCount: 0,
                              //     searchKeyword: singleTenderCeadData?.clientName,
                              //   };
                              //   dispatch(getContactCompanyPagination(apiBody));
                              // }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={3}>
                          <Form.Item
                            label="Contract Amount"
                            name={[field.name, `contractAmount`]}
                            fieldKey={[field.fieldKey, "contractAmount"]}
                            rules={[
                              {
                                required: true,
                                message: "Contract Amount is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input
                              placeholder="Enter Amount"
                              onChange={handleInputChange(index)}
                            />
                          </Form.Item>
                        </Col>

                        {/* <Col offset={1} span={11}> */}
                        <Col span={2}>
                          <Form.Item
                            label="VAT %"
                            name={[field.name, `vatPercentage`]}
                            fieldKey={[field.fieldKey, "vatPercentage"]}
                            rules={[
                              {
                                required: true,
                                message: "VAT % is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input
                              placeholder="Enter VAT %"
                              onChange={handleInputChange(index)}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={2}>
                          <Form.Item
                            label="CV EXCL. VAT"
                            name={[field.name, "cvExclVat"]}
                            fieldKey={[field.fieldKey, "cvExclVat"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "VAT % is required",
                            //   },
                            // ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input placeholder="Enter VAT %" readOnly />
                          </Form.Item>
                        </Col>

                        <Col span={2}>
                          <Form.Item
                            label="Exclusions"
                            name={[field.name, "exclusions"]}
                            fieldKey={[field.fieldKey, "exclusions"]}
                            rules={[
                              {
                                required: true,
                                message: "Exclusions is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input
                              placeholder="Enter Exclusions"
                              // onChange={handleExclusionChange(index)}
                              onChange={handleInputChange(index)}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={2}>
                          <Form.Item
                            label="Feeable CV"
                            name={[field.name, "feeableCV"]}
                            fieldKey={[field.fieldKey, "feeableCV"]}
                            rules={[
                              {
                                required: true,
                                message: "Feeable CV is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input placeholder="Enter Feeable CV" />
                          </Form.Item>
                        </Col>

                        <Col span={2}>
                          <Form.Item
                            label="Fee %"
                            name={[field.name, "feePercentage"]}
                            fieldKey={[field.fieldKey, "feePercentage"]}
                            rules={[
                              {
                                required: true,
                                message: "Fee % is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input
                              placeholder="Enter Fee %"
                              // onChange={handleFeePercentageChange(index)}
                              onChange={handleInputChange(index)}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={2}>
                          <Form.Item
                            label="Fee Amount"
                            name={[field.name, "feeAmt"]}
                            fieldKey={[field.fieldKey, "feeAmt"]}
                            rules={[
                              {
                                required: true,
                                message: "Fee Amount is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input placeholder="Enter Fee Amount" />
                          </Form.Item>
                        </Col>

                        <Col span={2}>
                          <Form.Item
                            label="Ex Rate"
                            name={[field.name, "exchangeRate"]}
                            fieldKey={[field.fieldKey, "exchangeRate"]}
                            rules={[
                              {
                                required: true,
                                message: "Ex Rate is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input
                              placeholder="Enter Ex Rate"
                              // onChange={handleExRateChange(index)}
                              onChange={handleInputChange(index)}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={3}>
                          <Form.Item
                            label="Equivalent NC Free"
                            name={[field.name, "equivalentNCFree"]}
                            fieldKey={[field.fieldKey, "equivalentNCFree"]}
                            rules={[
                              {
                                required: true,
                                message: "Equivalent NC Free is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input placeholder="Enter Equivalent NC Free" />
                          </Form.Item>
                        </Col>

                        <Col offset={1} span={3}>
                          <Form.Item
                            label="Vat % Fee Amount"
                            name={[field.name, "vatOnFeePercentage"]}
                            fieldKey={[field.fieldKey, "vatOnFeePercentage"]}
                            rules={[
                              {
                                required: true,
                                message: "Vat % Fee Amount is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input
                              placeholder="Enter Vat % Fee Amount"
                              // onChange={handleVatPercentFeeAmtChange(index)}
                              onChange={handleInputChange(index)}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={3}>
                          <Form.Item
                            label="Fee Amount After Vat"
                            name={[field.name, "feeAmtAfterVat"]}
                            fieldKey={[field.fieldKey, "feeAmtAfterVat"]}
                            rules={[
                              {
                                required: true,
                                message: "Fee Amount is required",
                              },
                            ]}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                          >
                            <Input placeholder="Enter Fee Amount" />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          {index >= 0 && (
                            <Button
                              style={{ float: "right", marginTop: "-12px" }}
                              icon={<MinusCircleOutlined />}
                              onClick={() => remove(field.name)}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}
                    <Button
                      style={{ marginBottom: "16px" }}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusCircleOutlined />}
                    >
                      Add
                    </Button>
                  </>
                )}
              </Form.List>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Contract Financial Remarks"
                name="contractFinancialRemarks"
              >
                <Input.TextArea placeholder="Enter remarks" />
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginTop: "10px" }}>
              <Form.Item
                label="Payment Claim Remarks"
                name="paymentClaimRemarks"
              >
                <Input.TextArea placeholder="Enter remarks" />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ float: "right" }}>
            <Col style={{ marginTop: "10px", marginRight: "10px" }}>
              <Button
                htmlType="submit"
                onClick={() => {
                  setAccSpecificShow(false);
                }}
              >
                Cancel
              </Button>
            </Col>

            <Col style={{ marginTop: "10px" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <AccountSpecificDetails
          singleTenderCeadData={singleTenderCeadData}
          setAccSpecificShow={setAccSpecificShow}
        />
      )}

      {/* Question Answers */}
      {/* <QAAccountSpecific
        singleTenderCeadData={singleTenderCeadData}
        QAForm={QAForm}
      /> */}
    </>
  );
}
