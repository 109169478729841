import { getService, postService } from "services/commonServices";
import { appBaseUrl } from "utils/config";

export class ApplicationService {
    addApplicationTemplate(body){
        let url = `${appBaseUrl}/adminModule/emailSystemTemplateAdd`;
        let data = postService(url,body);
        return data;
    }
    getApplicationTemplate(){
        let url = `${appBaseUrl}/adminModule/emailSystemTemplateListing`;
        let data = getService(url);
        return data;
    }
    updateApplicationTemplate(id,body){
        let url = `${appBaseUrl}/adminModule/emailSystemTemplateUpdate/${id}`;
        let data = postService(url , body , "PUT");
        return data;
    }
}