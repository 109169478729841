import { Col, Modal, Row, Table, Typography } from "antd";
import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getdashboardBudgetExpenseResponse, getdashboardLedgerResponse } from "services/redux/admin/FiscalYear/action";

const BudgetModal1 = ({ modalVisible, setModalVisible, modalData, type }) => {
  const dispatch = useDispatch();

  const { accountBudgetExpenseResponse, accountBudgetExpenseLoading, accountLedgerResponse, accountLedgerLoading } =
    useSelector((state) => state.fiscalYearState);

  useEffect(() => {
    const body = {
      theBranchis: modalData.query,
    };
    // console.log("type: ", type)
    if (type == 'dlreport') {
      let dlbody = {
        theBranchis: modalData.query,
        dTypeIncome: modalData.dTypeIncome,
        dExtaQuery: modalData?.queryTypeFormula,
        // [modalData.queryTypeParam]: modalData?.queryTypeFormula,
      }
      dispatch(getdashboardLedgerResponse(dlbody))
    }

    else if(type === 'bank' && modalData?.colTitle == 'B.Income') {
      let typeTwo = {
        theBranchis: modalData.query,
        dTypeIncome: 1,
      }
      dispatch(getdashboardBudgetExpenseResponse(typeTwo));
    }

    else {
      let bugetBody = {
        theBranchis: modalData.query,
        dTypeIncome: 2,
      }
      dispatch(getdashboardBudgetExpenseResponse(bugetBody));
    }
      
  }, []);

  const showNumber = (number) => {
    if (number === 0) {
      return `--`;
    } else if (number < 0) {
      return (
        <Typography.Text style={{ color: "red" }}>
          ({Math.abs(Math.trunc(number))?.toLocaleString()} )
        </Typography.Text>
      );
    } else {
      return (
        <Typography.Text>{Math.trunc(number).toLocaleString()}</Typography.Text>
      );
    }
  };

  const bankStatuscolumns = [
    {
      title: "S.N",
      key: "",
      dataIndex: "",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Account Type",
      dataIndex: "accountHead",
      key: "accountHead",
      render: (_, record) => {
        return (
          <Typography.Text
            style={
              record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
            }
          >
            {record?.accountHead}
          </Typography.Text>
        );
      },
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
            }
          >
            {showNumber(record?.budget)}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Expense",
      dataIndex: "expense",
      key: "expense",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
            }
          >
            {showNumber(record?.expense)}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
            }
          >
            {showNumber(record?.percentage)}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Variance",
      dataIndex: "variance",
      key: "variance",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{}}>
            {showNumber(record?.variance)}
          </Typography.Paragraph>
        );
      },
    },
  ];

  const commonColumns = [
    {
      title: "S.N",
      key: "sn",
      dataIndex: "sn",
      render: (_, record, index) => index + 1,
    },
    // {
    //   title: "Account Type",
    //   dataIndex: "accountHead",
    //   key: "accountHead",
    //   render: (_, record) => {
    //     return (
    //       <Typography.Text
    //         style={
    //           record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
    //         }
    //       >
    //         {record?.accountHead}
    //       </Typography.Text>
    //     );
    //   },
    // },
  ];

  const dlReportColumns = [
    {
      title: "Income Head",
      dataIndex: "accountHead",
      key: "accountHead",
      render: (_, record) => {
        return (
          <Typography.Text
            style={
              record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
            }
          >
            {record?.accountHead}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right" }}>
          cr Amount
        </Typography.Paragraph>
      ),
      key: "crAmount",
      align: "right",
      dataIndex: "crAmount",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.incomeHead === "Total"
                ? { fontWeight: "bold", textAlign: "right" }
                : { textAlign: "right" }
            }
          >
            {showNumber(record?.crAmount)}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right" }}>
          dr Amount
        </Typography.Paragraph>
      ),
      key: "drAmount",
      align: "right",
      dataIndex: "drAmount",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.incomeHead === "Total"
                ? { fontWeight: "bold", textAlign: "right" }
                : { textAlign: "right" }
            }
          >
            {showNumber(record?.drAmount)}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "center" }}>
          Description
        </Typography.Paragraph>
      ),
      key: "dateSn",
      dataIndex: "dateSn",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {record?.description}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "center" }}>
          Date
        </Typography.Paragraph>
      ),
      key: "dateSn",
      dataIndex: "dateSn",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {moment(record?.dateSn)?.format("YYYY MMM DD")}
          </Typography.Paragraph>
        );
      },
    },
  ]

  const cashflowColumns = [
    {
      title: "Opening Balance",
      dataIndex: "openingBal",
      key: "openingBal",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
            }
          >
            {showNumber(record?.expense)}
          </Typography.Paragraph>
        );
      },
    },
  ]

  const budget = accountBudgetExpenseResponse && accountBudgetExpenseResponse?.reduce(
    (acc, curr) => acc + (curr?.budget || 0),
    0
  );
  const expense = accountBudgetExpenseResponse && accountBudgetExpenseResponse?.reduce(
    (acc, curr) => acc + (curr?.expense || 0),
    0
  );
  const percentage = accountBudgetExpenseResponse && accountBudgetExpenseResponse?.reduce(
    (acc, curr) => acc + (curr?.percentage || 0),
    0
  );
  const variance = accountBudgetExpenseResponse && accountBudgetExpenseResponse?.reduce(
    (acc, curr) => acc + (curr?.variance || 0),
    0
  );

  const totalRow = {
    key: "total",
    accountHead: "Total",
    budget: budget,
    expense: expense,
    percentage: percentage,
    variance: variance,
  };

  const dataSourceBudget = [...(accountBudgetExpenseResponse || []), totalRow];

  const columns = type === "bank"
    ? [...bankStatuscolumns]
    : type === "dlreport"
      ? [...commonColumns, ...dlReportColumns]
      : type === "cashflow"
        ? [...commonColumns, ...cashflowColumns]
        : null;


  // DL Report
  const dlSerialNumber = accountLedgerResponse && accountLedgerResponse?.reduce(
    (acc, curr) => acc + (curr?.serialNumber || 0),
    0
  );

  const dlNprAmount = accountLedgerResponse && accountLedgerResponse?.reduce(
    (acc, curr) => acc + (curr?.drAmount || 0),
    0
  );

  const dlCrAmount = accountLedgerResponse && accountLedgerResponse?.reduce(
    (acc, curr) => acc + (curr?.crAmount || 0),
    0
  );

  const totalRowDl = {
    key: "Total",
    serialNumber: dlSerialNumber,
    accountHead: "Total",
    drAmount: dlNprAmount,
    crAmount: dlCrAmount,
  };

  const dataSourceDl = [...(accountLedgerResponse || []), totalRowDl]

  const dataSource = type === "dlreport" ? dataSourceDl : dataSourceBudget;


  return (
    <>
      <Modal
        visible={modalVisible}
        // title="Summary of Budget and Expense"
        title={`Summary of  ${modalData?.colTitle || 'Budget and Expense'}`}
        onCancel={() => setModalVisible({ budget: false })}
        footer={null}
        width={800}
      >
        <Col span={24}>
          <Table
            className="next-organizer-table"
            style={{ fontWeight: "12px !important", padding: "10px" }}
            columns={columns}
            dataSource={dataSource}
            loading={accountBudgetExpenseLoading}
            pagination={false}
          />
        </Col>
      </Modal>
    </>
  );
};

export default BudgetModal1;
