import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Drawer, Form, Skeleton, Table, Tabs } from "antd";

import { actionForGetSingleCeadTenderDetail } from "services/redux/cead/action";
import { EyeOutlined } from "@ant-design/icons";
import CreateCead from "../contractStage/createCead";
import CeadSpecific from "../contractStage/ceadSpecific";
import AccoutSpecific from "../contractStage/accountSpecific";
import Others from "../contractStage/others";

import moment from "moment";
import QAFormDetail from "./accountSpecific/qA";
import CeadSearch from "components/ceadSearch";

const { TabPane } = Tabs;

export default function CEAD({
  tenderCeadList,
  singleTenderCeadData,
  tenderCeadListLoading,
  throughCompOptions,
  inchargeUsersOptions,
  companyOptions,
  contactPersonOptions,
  currencyOptions,
  referenceListingResponse,
  referenceOptions,
  ...props
}) {
  const dispatch = useDispatch();
  const [formCreateCead] = Form.useForm();
  const [formFunding] = Form.useForm();
  const [formBidBound] = Form.useForm();
  const [formPerformanceBound] = Form.useForm();
  const [formAccountSpecific] = Form.useForm();
  const [formOtherInfo] = Form.useForm();
  const [QAForm] = Form.useForm();

  // const [activeIndex, setActiveIndex] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedTender, setSelectedTender] = useState();

  const {
    checkedValues,
    setCheckValue,
    setSearchValue,
    searchValues,
    initialSearchValues,
  } = props;

  useEffect(() => {
    //Bid Bond
    formBidBound.setFieldsValue({
      bidBond: singleTenderCeadData?.biD_BOND_BidInformation?.map(
        (x, index) => {
          return {
            issueingBank: x?.issueingBank,
            foreignBank: x?.foreignBank,
            currencyId: x?.currencyId,
            amount: x?.amount,

            validUpto: moment(x?.validUpto),
            releasedDate: moment(x?.releasedDate),
            description: x?.description,
            lastShipmentDate: moment(x?.lastShipmentDate),
            isRenewalRecord: x?.isRenewalRecord,
          };
        }
      ),
    });

    //performance Bound
    formPerformanceBound.setFieldsValue({
      performanceBond:
        singleTenderCeadData?.performancE_BOND_BidInformation?.map(
          (x, index) => {
            return {
              issueingBank: x?.issueingBank,
              foreignBank: x?.foreignBank,
              currencyId: x?.currencyId,
              amount: x?.amount,

              validUpto: moment(x?.validUpto),
              releasedDate: moment(x?.releasedDate),
              description: x?.description,
              lastShipmentDate: moment(x?.lastShipmentDate),
              isRenewalRecord: x?.isRenewalRecord,
            };
          }
        ),
    });

    formFunding.setFieldsValue({
      Funding: singleTenderCeadData?.tenderCEADFundingAgencys?.map((x) => {
        return {
          amount: x?.amount,
          loanEffectiveDate: moment(x?.loanEffectiveDate),
          loanEndDate: moment(x?.loanEndDate),
          currencyId: x?.currencyId,
          fundingAgencyId: x?.fundingAgencyName,
        };
      }),
    });

    formAccountSpecific.setFieldsValue({
      contractFinancial:
        singleTenderCeadData?.foreign_ContractFinancialsInformationDto?.tenderCEADContractFinancialsInformationDtos?.map(
          (x, index) => {
            let cvExclVat =
              x?.contractAmount - (x?.contractAmount * x?.vatPercentage) / 100;
            let feeableCV = cvExclVat - x?.exclusions;
            let feeAmt = feeableCV * (x?.feePercentage / 100);
            let equivalentNCFree = feeAmt * x?.exchangeRate;
            let feeAmtAfterVat =
              feeAmt + (x?.vatOnFeePercentage / 100) * feeAmt;
            return {
              ...x,
              contractAmount: x?.contractAmount.toLocaleString(),
              cvExclVat: cvExclVat?.toLocaleString(),
              feeableCV: feeableCV.toLocaleString(),
              feeAmt: feeAmt.toLocaleString(),
              equivalentNCFree: equivalentNCFree.toLocaleString(),
              feeAmtAfterVat: feeAmtAfterVat.toLocaleString(),
            };
          }
        ),
      ...singleTenderCeadData?.foreign_ContractFinancialsInformationDto
        ?.contractFinancialRemarkDto,
    });

    QAForm.setFieldsValue({
      ...singleTenderCeadData?.tenderCEADQuestionAnswerDto,
      vatRegistrationDate: moment(
        singleTenderCeadData?.tenderCEADQuestionAnswerDto?.vatRegistrationDate
      ),
    });

    formOtherInfo.setFieldsValue({
      principalId:
        // singleTenderCeadData?.tenderCEADOtherInformationDto?.principalId,
        singleTenderCeadData?.tenderCEADOtherInformationDto?.principalName,
      // clientId: singleTenderCeadData?.tenderCEADOtherInformationDto?.clientId,
      clientId: singleTenderCeadData?.tenderCEADOtherInformationDto?.clientName,
      reportingRequirementToPrinciple:
        singleTenderCeadData?.tenderCEADOtherInformationDto
          ?.reportingRequirementToPrinciple,
      majorServicesToPrincipleAgreement:
        singleTenderCeadData?.tenderCEADOtherInformationDto
          ?.majorServicesToPrincipleAgreement,
      isConstructionScheduleIncluded:
        singleTenderCeadData?.tenderCEADOtherInformationDto
          ?.isConstructionScheduleIncluded,
      isConstructionDocuments:
        singleTenderCeadData?.tenderCEADOtherInformationDto
          ?.isConstructionDocuments,
      additionalRemarks:
        singleTenderCeadData?.tenderCEADOtherInformationDto?.additionalRemarks,
      contractExecutionContactInformations:
        singleTenderCeadData?.tenderCEADOtherInformationDto?.contractExecutionContactInformations?.map(
          (x) => {
            return x?.contactPersonName;
            // return x?.contactId;
          }
        ),
      salesMarketingContactInformations:
        singleTenderCeadData?.tenderCEADOtherInformationDto?.salesMarketingContactInformations?.map(
          (x) => {
            return x?.contactPersonName;
            // return x?.contactId;
          }
        ),
    });
  }, [
    formAccountSpecific,
    formBidBound,
    formFunding,
    formOtherInfo,
    formPerformanceBound,
    singleTenderCeadData,
  ]);

  const itemsTab = [
    {
      key: "1",
      label: `Update CEAD`,
      children: (
        <CreateCead
          formCreateCead={formCreateCead}
          throughCompOptions={throughCompOptions}
          inchargeUsersOptions={inchargeUsersOptions}
          referenceListingResponse={referenceListingResponse}
          referenceOptions={referenceOptions}
          singleTenderCeadData={singleTenderCeadData}
          contactPersonOptions={contactPersonOptions}
          companyOptions={companyOptions}
        />
      ),
    },
    {
      key: "2",
      label: `CEAD Specific`,
      children: (
        <CeadSpecific
          formFunding={formFunding}
          formBidBound={formBidBound}
          formPerformanceBound={formPerformanceBound}
          singleTenderCeadData={singleTenderCeadData}
          currencyOptions={currencyOptions}
          companyOptions={companyOptions}
        />
      ),
    },
    {
      key: "3",
      label: `Account Specific`,
      children: (
        <AccoutSpecific
          formAccountSpecific={formAccountSpecific}
          singleTenderCeadData={singleTenderCeadData}
          currencyOptions={currencyOptions}
          QAForm={QAForm}
        />
      ),
    },
    {
      key: "4",
      label: "QA",
      children: (
        <QAFormDetail
          singleTenderCeadData={singleTenderCeadData}
          QAForm={QAForm}
        />
      ),
    },
    {
      key: "5",
      label: `Others`,
      children: (
        <Others
          companyOptions={companyOptions}
          contactPersonOptions={contactPersonOptions}
          singleTenderCeadData={singleTenderCeadData}
          formOtherInfo={formOtherInfo}
        />
      ),
    },
  ];

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
    },
    {
      title: "Reference",
      dataIndex: "code",
    },
    {
      title: "Subject",
      dataIndex: "title",
    },
    {
      title: "CEAD Transfer",
      dataIndex: "cead_transfer",
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <>
            <EyeOutlined
              onClick={() => {
                setSelectedTender(record);
                setIsOpenDrawer(true);
                dispatch(actionForGetSingleCeadTenderDetail(record?.tenderId));
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card
        style={{
          margin: 25,
        }}
      >
        <CeadSearch
          checkedValues={checkedValues}
          setCheckValue={setCheckValue}
          setSearchValue={setSearchValue}
          searchValues={searchValues}
          initialSearchValues={initialSearchValues}
        />
        {tenderCeadListLoading === undefined ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            dataSource={tenderCeadList?.items?.map((x, index) => {
              return {
                ...x.referenceDto,
                sn: index + 1,
                tenderId: x?.id,
              };
            })}
            rowKey="referenceCode"
          />
        )}
      </Card>
      <Drawer
        title="Contract Stage"
        onClose={() => {
          setIsOpenDrawer(false);
        }}
        visible={isOpenDrawer}
        width={1400}
        keyboard={false}
        maskClosable={false}
      >
        <Tabs
          defaultActiveKey="1"
          tabBarGutter={25}
          // activeKey={activeTabIndexofApplicant}
          // onChange={(key) => {
          //   setActiveTabIndexofAppicant(key);
          // }}
          style={{ marginLeft: "10px" }}
        >
          {itemsTab?.map((x) => {
            return (
              <>
                <TabPane tab={x?.label} key={x?.key}>
                  {x?.children}
                </TabPane>
              </>
            );
          })}
        </Tabs>
      </Drawer>
    </>
  );
}
