import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Split from "react-split";
import { isEmpty, slice } from "lodash";
import { Tag } from "antd";
import { cloneDeep } from "lodash";
import ReactToPrint from "react-to-print";
// antd
import { Image, Row, Col, Modal, Button, Tooltip, message } from "antd";
import {
  PaperClipOutlined,
  PlusCircleOutlined,
  StarFilled,
} from "@ant-design/icons";

// includes
import EmailChat from "../EmailChat";
import ReplyChatBox from "../Includes/ReplyChatBox";
import { BusinessModal } from "../Includes/BusinessModal";
import AddNewQuickEmail from "../Includes/AddNewQuickEmail";
import MailMessageCenterHeader from "../Includes/MailMessageCenterHeader";

// redux

import { useDispatch, useSelector } from "react-redux";
import { getInstanceMessageCenterPagination } from "services/redux/email/instanceMessageCenter/action";
import {
  mailReferenceTagUpdate,
  changeEmailStatusType,
  mailReferenceListing,
} from "services/redux/email/mailMessageCenter/action";
import { mailReferenceGetDetails } from "services/redux/email/referenceTags/action";
import { getMailMessageParticipantStatusListing } from "services/redux/email/messageCenterType/action";

// utils
import { getParticipantsEmailDetails } from "utils/commonFunc";
import { cqBaseUrl } from "utils/config";
import LeaveDetails from "../Includes/LeaveDetails";
import { getParticipantsEmailsExternal } from "utils/commonFunc";
import { setSelectedFileName } from "services/redux/common/persistFiles/action";

import { getParticipantTypeEmailAddressesListing } from "services/redux/email/participantEmailAdresses/action";
import { getAllUsers } from "services/remanentCalls";

const Trash = "assets/svg/email-trash.svg";
const attachment = "assets/svg/attatchment.svg";
const TrashRed = "assets/svg/trash-red.svg";
const Printer = "assets/svg/printer-outline.svg";
const Star = "assets/svg/star.svg";
const StarOutlined = "assets/svg/starred-12.svg";
const internalmessage = "assets/svg/internal-msg-12.svg";
const internalMessageGreen = "assets/svg/internal-msg-12-green.svg";
const windownew = "assets/svg/windownew-16.svg";
const hide = "assets/svg/hide-16.svg";
const show = "assets/svg/email-show16.svg";
const Extend = "assets/svg/windownew-16.svg";
const internalMsg = "assets/svg/internal-msg.svg";
const chatIcon = "assets/svg/chats.svg";

const EmailForwardSend = (
  {
    // toggleMail,
    // currentSelectedMailMessage,
    // mailMessageParticipantTypeListing,
    // mailMessageParticipationStatusListing,
    // allParticipants,
    // replyEmailCompose,
    // messageCenterTypeName,
    // setchatBox,
    // chatBox,
    // mailMessageCenterPaginationLoading,
  }
) => {
  const dispatch = useDispatch();

  //redux store
  const { instanceMessageCenterPagination } = useSelector(
    (state) => state.instanceMessageCenter
  );
  const priorityEventsState = useSelector((state) => state.priorityEvents);
  const mailMessageParticipantTypeState = useSelector(
    (state) => state.mailMessageParticipantType
  );
  const mailMessageParticipantStatusState = useSelector(
    (state) => state.mailMessageParticipantStatus
  );
  const { organizerEventPriorityListing } = priorityEventsState;
  const { mailMessageParticipantTypeListing } = mailMessageParticipantTypeState;
  const { mailMessageParticipationStatusListing } =
    mailMessageParticipantStatusState;
  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );
  const {
    participantTypeEmailAddressesListing,
    participantTypeEmailAddressesListingLoading,
  } = useSelector((state) => state.participantEmailAddresses);
  const { mailReferenceUpdateResponse } = useSelector(
    (state) => state.mailMessageCenter
  );
  const { mailReferenceGetDetailsResponse } = useSelector(
    (state) => state.mailReference
  );
  const { token } = useSelector((state) => state.auth);
  const printTableRef = useRef();

  //states
  const [hideInternalMessage, setHideInternalMessage] = useState(true);
  const [currentSelectedMailMessage, setCurrentSelectedMailMessage] = useState(
    {}
  );
  const [messageCenterId, setMessageCenterId] = useState("");
  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false);
  const [instanceMessageCenterListing, setInstanceMessageCenterListing] =
    useState([]);
  const [participantTypeFrom, setParticipantTypeFrom] = useState("");
  const [allParticipants, setAllParticipants] = useState([]);
  const [participantTypeToNames, setParticipantTypeToNames] = useState("");
  const [participantTypeCCNames, setParticipantTypeCCNames] = useState("");
  const [emailTypeToNames, setEmailTypeToNames] = useState("");
  const [initialMessage, setInitialMessage] = useState({});
  const [replyMsgDetails, setReplyMsgDetails] = useState({});
  const [replyStatus, setReplyStatus] = useState("");
  const [chatBox, setchatBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const [checkEmail, setCheckEmail] = useState("");
  const [replyOfChat, setReplyofChat] = useState("");
  const [referenceTags, setReferenceTags] = useState([]);
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const [showClosable, setShowClosable] = useState(-1);
  const [visibleModal, setVisibleModal] = useState(false);

  const [emailTypeCCNames, setEmailTypeCCNames] = useState("");
  const pageSize = 20;

  useEffect(() => {
    if (isEmpty(allParticipants)) {
      fetchAllParticipants();
    }

    if (isEmpty(mailMessageParticipationStatusListing)) {
      dispatch(getMailMessageParticipantStatusListing());
    }
  }, []);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setAllParticipants(resp.data);
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedMailTypeAndMailData)) {
      setCurrentSelectedMailMessage(selectedMailTypeAndMailData.selectedMail);
      setLoading(true);
    }
  }, [selectedMailTypeAndMailData]);

  // console.log(selectedMailTypeAndMailData, "id");

  useEffect(() => {
    if (isEmpty(currentSelectedMailMessage.mailMessageReferenceDtos)) {
      setReferenceTags([]);
    } else {
      const tenderDtos =
        currentSelectedMailMessage?.mailMessageReferenceDtos?.map((data) => {
          let val = "";

          val = data.referenceCode;

          return val;
        });

      setReferenceTags(tenderDtos);
    }
  }, [currentSelectedMailMessage]);

  // console.log(referenceTags, "referemce", currentSelectedMailMessage);

  // useEffect(() => {
  // 	window.electron.listenResponse(mailReferenceUpdateResponse);
  // 	if (mailReferenceUpdateResponse) {
  // 		setReferenceTags(mailReferenceUpdateResponse);
  // 	}
  // 	if (isEmpty(mailReferenceUpdateResponse)) {
  // 		setReferenceTags([]);
  // 	} else {
  // 		const tenderDtos = mailReferenceUpdateResponse?.map((data) => {
  // 			let val = '';

  // 			val = data.referenceCode;

  // 			return val;
  // 		});

  // 		setReferenceTags(tenderDtos);
  // 	}
  // }, [mailReferenceUpdateResponse]);

  useEffect(() => {
    if (
      !isEmpty(instanceMessageCenterPagination.items) &&
      !isEmpty(currentSelectedMailMessage)
    ) {
      setHideInternalMessage(true);
      setInstanceMessageCenterListing(instanceMessageCenterPagination);

      // setHideInternalMessage(true);
    } else {
      setInstanceMessageCenterListing([]);
      setHideInternalMessage(false);
      // setHideInternalMessage(false);
    }
  }, [instanceMessageCenterPagination, currentSelectedMailMessage]);

  const filterTags = (removedTag) => {
    let data = referenceTags.filter((tags) => tags !== removedTag);
    setReferenceTags(data);

    if (!isEmpty(data)) {
      let val = {
        tenderDtos: data.map((tenderCode) => ({
          messageCenterId:
            !isEmpty(currentSelectedMailMessage) &&
            currentSelectedMailMessage?.id,
          referenceCode: tenderCode,
        })),
      };
      dispatch(
        mailReferenceTagUpdate(val.tenderDtos, currentSelectedMailMessage)
      );
    } else if (isEmpty(data)) {
      let val = {
        tenderDtos: [
          {
            messageCenterId:
              !isEmpty(currentSelectedMailMessage) &&
              currentSelectedMailMessage?.id,
            referenceCode: null,
          },
        ],
      };
      dispatch(
        mailReferenceTagUpdate(val.tenderDtos, currentSelectedMailMessage)
      );
    }
  };

  // console.log("centerId", messageCenterId);

  useEffect(() => {
    if (!isEmpty(currentSelectedMailMessage)) {
      if (
        currentSelectedMailMessage.applicationTypeSystemName ===
        "InstanceMessage"
      ) {
        setMessageCenterId(
          currentSelectedMailMessage.instanceMainMessageCenterId
        );
        getData(20, 0, currentSelectedMailMessage.instanceMainMessageCenterId);
      } else {
        setMessageCenterId(currentSelectedMailMessage.id);
        getData(20, 0, currentSelectedMailMessage.id);
      }

      let emailMessageParticipantWithTypeDtos =
        currentSelectedMailMessage?.applicationTypeDetails;

      setInitialMessage(currentSelectedMailMessage.body);

      if (currentSelectedMailMessage?.applicationTypeSystemName === "EMail") {
        // let emailToNames = getParticipantsEmailsExternal(currentSelectedMailMessage?.applicationTypeDetails?.emailRecipients?.emailTOs);
        let emailToNames = getParticipantsEmailsExternal(
          currentSelectedMailMessage?.applicationTypeDetails?.tos
        );
        let emailCCNames = getParticipantsEmailsExternal(
          currentSelectedMailMessage?.applicationTypeDetails?.cCs
        );
        setEmailTypeCCNames(emailCCNames);
        setEmailTypeToNames(emailToNames);
      } else {
        if (!isEmpty(emailMessageParticipantWithTypeDtos)) {
          setParticipantTypeFrom(emailMessageParticipantWithTypeDtos?.from);

          let participantToNames = getParticipantsEmailDetails(
            emailMessageParticipantWithTypeDtos?.tos
          );
          // console.log(participantToNames);
          setParticipantTypeToNames(participantToNames);

          let participantCCNames = getParticipantsEmailDetails(
            emailMessageParticipantWithTypeDtos?.cCs
          );
          setParticipantTypeCCNames(participantCCNames);
        } else {
          setParticipantTypeFrom([]);
          setParticipantTypeToNames([]);
          setParticipantTypeCCNames([]);
        }
      }
    } else {
      setMessageCenterId("");
      setInitialMessage("");
      setParticipantTypeFrom([]);
      setParticipantTypeToNames([]);
      setParticipantTypeCCNames([]);
    }
  }, [currentSelectedMailMessage]);

  useEffect(() => {
    if (currentSelectedMailMessage.applicationTypeSystemName !== "EMail")
      setCheckEmail(participantUserFromDto?.from[0]?.emailAddress);
    else {
      setCheckEmail(
        currentSelectedMailMessage.applicationTypeDetails?.from[0]?.emailAddress
      );
    }
  }, [currentSelectedMailMessage]);

  useEffect(() => {
    if (checkEmail) {
      dispatch(
        getParticipantTypeEmailAddressesListing({ searchkeyWord: checkEmail })
      );
    }
  }, [checkEmail]);

  // useEffect(() => {
  // 	if (!isEmpty()) {
  // 		dispatch(getParticipantTypeEmailAddressesListing({ searchkeyWord: checkEmail }));
  // 	}
  // }, [participantTypeEmailAddressesListing]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    MessageCenterId = messageCenterId
    // SearchKeyword
  ) => {
    // dispatch(
    //   getInstanceMessageCenterPagination({
    //     MaxResultCount,
    //     SkipCount,
    //     MessageCenterId,
    //     // SearchKeyword
    //   })
    // );
  };
  const handleMouseEnter = (i) => {
    setShowClosable(i);
  };
  const handleMouseLeave = (i) => {
    setShowClosable(i);
  };
  const changeEmailStatus = (emailStatus, status) => {
    let query = {
      messageCenterId: currentSelectedMailMessage.id,
      participantStatusName: emailStatus.trash ? "Trash" : "Special-flag-1",
    };

    if (!isEmpty(instanceMessageCenterPagination.items)) {
      let instanceMessageId = instanceMessageCenterPagination.items[0].id;
      query.instanceMessageId = instanceMessageId;
    }

    dispatch(
      changeEmailStatusType(
        query,
        status,
        selectedMailTypeAndMailData?.typeDisplayName,
        emailStatus
      )
    );
  };

  const handleReply = (msg, status) => {
    setchatBox(true);
    setReplyofChat(msg.orderNumber);
    setReplyMsgDetails(msg);
    setReplyStatus(status);
  };

  const participantUserFromDto =
    currentSelectedMailMessage?.applicationTypeDetails;

  return (
    <div></div>
    // <div className="emailDetails">
    //   {loading && (
    //     <div>
    //       <div>
    //         <MailMessageCenterHeader
    //           currentSelectedMailMessage={currentSelectedMailMessage}
    //           instanceMessageCenterPagination={instanceMessageCenterListing}
    //           printTableRef={printTableRef}
    //         />
    //         <div className="emailDetails__body">
    //           <div className="emailChat__left">
    //             <div className="emailDetails__body--title">
    //               <div className="email-title-start">
    //                 <div
    //                   className="email-star"
    //                   onClick={() =>
    //                     changeEmailStatus(
    //                       !currentSelectedMailMessage.isSpecialFlagOne &&
    //                         !currentSelectedMailMessage.isSpecialFlagOne_InstanceMessage
    //                         ? {
    //                             isSpecialFlagOne: true,
    //                             isSpecialFlagOne_InstanceMessage: true,
    //                             starred: "Special-Flag-1",
    //                           }
    //                         : {
    //                             isSpecialFlagOne_InstanceMessage: false,
    //                             isSpecialFlagOne: false,
    //                             starred: "Special-Flag-1",
    //                           },
    //                       currentSelectedMailMessage.isSpecialFlagOne ||
    //                         currentSelectedMailMessage.isSpecialFlagOne_InstanceMessage
    //                         ? "Remove"
    //                         : "Add"
    //                     )
    //                   }
    //                 >
    //                   {/* {currentSelectedMailMessage.isSpecialFlagOne || currentSelectedMailMessage.isSpecialFlagOne_InstanceMessage ? (
    // 										<Image src={Star} shape="square" styke={{ width: 13 }} preview={false} />
    // 									) : (
    // 										<Image src={StarOutlined} shape="square" style={{ width: 13 }} preview={false} />
    // 									)} */}

    //                   <StarFilled
    //                     style={{
    //                       width: 10,
    //                       height: 10,
    //                       color:
    //                         currentSelectedMailMessage.isSpecialFlagOne ||
    //                         currentSelectedMailMessage.isSpecialFlagOne_InstanceMessage
    //                           ? "#ffd036"
    //                           : "#c8c8c8",
    //                     }}
    //                   />
    //                 </div>
    //                 <div className="emailReference__title">
    //                   <div className="email-title">
    //                     <div className="email-title--show">
    //                       <span>{currentSelectedMailMessage.subject}</span>
    //                     </div>
    //                     <div className="email-title--nowrap">
    //                       <Tooltip title={currentSelectedMailMessage.subject}>
    //                         <span className="hide">
    //                           {currentSelectedMailMessage.subject}
    //                         </span>
    //                       </Tooltip>
    //                     </div>
    //                     <div className="emailList__card--refComp text-right tags">
    //                       {!isEmpty(referenceTags) &&
    //                         referenceTags.map((tag, i) => {
    //                           return (
    //                             <>
    //                               {tag.length > 9 ? (
    //                                 <div
    //                                   className="emailList__card--ref"
    //                                   key={i}
    //                                   onMouseEnter={() => {
    //                                     handleMouseEnter(i);
    //                                   }}
    //                                   onMouseLeave={() => {
    //                                     handleMouseLeave(-1);
    //                                   }}
    //                                 >
    //                                   {
    //                                     <Tag
    //                                       closable={
    //                                         showClosable == i ? true : false
    //                                       }
    //                                       className="reference-tags"
    //                                       key={i}
    //                                       onClose={() => filterTags(tag)}
    //                                     >
    //                                       <Tooltip title={tag}>
    //                                         <div
    //                                           style={{
    //                                             width: 50,
    //                                             textOverflow: "ellipsis",
    //                                             overflow: "hidden",
    //                                           }}
    //                                         >
    //                                           {tag.substring(0, 12)}
    //                                         </div>
    //                                       </Tooltip>
    //                                     </Tag>
    //                                   }
    //                                 </div>
    //                               ) : (
    //                                 <div
    //                                   className="emailList__card--ref"
    //                                   key={i}
    //                                   onMouseEnter={() => {
    //                                     handleMouseEnter(i);
    //                                   }}
    //                                   onMouseLeave={() => {
    //                                     handleMouseLeave(-1);
    //                                   }}
    //                                 >
    //                                   {
    //                                     <Tag
    //                                       closable={
    //                                         showClosable == i ? true : false
    //                                       }
    //                                       className="reference-tags"
    //                                       key={i}
    //                                       onClose={() => filterTags(tag)}
    //                                     >
    //                                       <div>{tag}</div>
    //                                     </Tag>
    //                                   }
    //                                 </div>
    //                               )}
    //                             </>
    //                           );
    //                         })}

    //                       {/* {!isEmpty(referenceTags) && referenceTags.length > 24 && (
    // 												<div className="referenceDetails__reference--revisions mailSection">
    // 													<Tooltip title={splicedData.join(',')}>
    // 														<span className="referenceDetails--subtitle">+{splicedData.length}</span>
    // 													</Tooltip>
    // 												</div>
    // 											)} */}

    //                       <div
    //                         className="emailList__card--comp"
    //                         style={{ marginTop: -2 }}
    //                       >
    //                         <PlusCircleOutlined
    //                           onClick={() => {
    //                             setShowBusinessModal(true);
    //                             dispatch(
    //                               mailReferenceGetDetails(
    //                                 currentSelectedMailMessage?.id
    //                               )
    //                             );
    //                           }}
    //                         />
    //                       </div>
    //                     </div>
    //                     {/* <div className="emailDetails__body--titleIcon">
    // 										<a
    // 											onClick={() =>
    // 												changeEmailStatus(
    // 													!currentSelectedMailMessage.isSpecialFlagOne && !currentSelectedMailMessage.isSpecialFlagOne_InstanceMessage
    // 														? { isSpecialFlagOne: true, isSpecialFlagOne_InstanceMessage: true, starred: 'Special-Flag-1' }
    // 														: { isSpecialFlagOne_InstanceMessage: false, isSpecialFlagOne: false, starred: 'Special-Flag-1' },
    // 													currentSelectedMailMessage.isSpecialFlagOne || currentSelectedMailMessage.isSpecialFlagOne_InstanceMessage ? 'Remove' : 'Add'
    // 												)
    // 											}
    // 										>
    // 											{currentSelectedMailMessage.isSpecialFlagOne || currentSelectedMailMessage.isSpecialFlagOne_InstanceMessage ? (
    // 												<Image src={Star} shape="square" preview={false} />
    // 											) : (
    // 												<Image src={StarOutlined} shape="square" preview={false} />
    // 											)}
    // 										</a>
    // 										<a
    // 											onClick={() =>
    // 												changeEmailStatus(
    // 													!currentSelectedMailMessage.isTrashed && !currentSelectedMailMessage.isTrashed_InstanceMessage
    // 														? { isTrashed: true, isTrashed_InstanceMessage: true, trash: 'Trash' }
    // 														: { isTrashed_InstanceMessage: false, isTrashed: false, trash: 'Trash' },
    // 													currentSelectedMailMessage.isTrashed || currentSelectedMailMessage.isTrashed_InstanceMessage ? 'Remove' : 'Add'
    // 												)
    // 											}
    // 										>
    // 											{currentSelectedMailMessage.isTrashed || currentSelectedMailMessage.isTrashed_InstanceMessage ? (
    // 												<Image src={TrashRed} shape="square" preview={false} />
    // 											) : (
    // 												<Image src={Trash} shape="square" preview={false} />
    // 											)}
    // 										</a>
    // 										<a>
    // 											<ReactToPrint trigger={() => <Image src={Printer} shape="square" preview={false} />} content={() => printTableRef.current} />
    // 										</a>

    // 									</div> */}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div
    //               className={`emailDetails__body--infosMain ${
    //                 isEmpty(emailTypeCCNames || participantTypeCCNames) &&
    //                 "height"
    //               }`}
    //             >
    //               <div className="email-from-date">
    //                 <div className="infosMain">
    //                   <span className="title">From:</span>
    //                   <div style={{ display: "flex" }}>
    //                     <span>
    //                       <a
    //                         href="#"
    //                         className="active"
    //                         title={
    //                           participantUserFromDto?.from[0]?.emailAddress
    //                         }
    //                       >
    //                         {currentSelectedMailMessage.applicationTypeSystemName !==
    //                         "EMail" ? (
    //                           participantUserFromDto?.from[0]?.emailAddress ? (
    //                             `${participantUserFromDto?.from[0]?.fullName}`
    //                           ) : (
    //                             participantUserFromDto?.from[0]?.username ||
    //                             "<No Name>"
    //                           )
    //                         ) : participantUserFromDto?.from[0]?.username ? (
    //                           <Tooltip
    //                             title={
    //                               participantUserFromDto?.from[0]?.emailAddress
    //                             }
    //                             placement="topLeft"
    //                           >
    //                             <span className="contactsDetails--subtitle bold">
    //                               {participantUserFromDto?.from[0]?.fullName}
    //                             </span>
    //                           </Tooltip>
    //                         ) : (
    //                           currentSelectedMailMessage.applicationTypeDetails
    //                             ?.from[0]?.username
    //                         )}
    //                       </a>
    //                     </span>
    //                     {currentSelectedMailMessage.applicationTypeDetails
    //                       ?.from[0]?.companyName && (
    //                       <div>
    //                         <span
    //                           style={{ color: "#007AFF", fontWeight: "bold" }}
    //                         >
    //                           [
    //                           {
    //                             currentSelectedMailMessage
    //                               .applicationTypeDetails?.from[0]?.companyName
    //                           }
    //                           ]
    //                         </span>
    //                       </div>
    //                     )}

    //                     {participantTypeEmailAddressesListingLoading
    //                       ? ""
    //                       : isEmpty(participantTypeEmailAddressesListing) && (
    //                           <div className="plusIcon">
    //                             <PlusCircleOutlined
    //                               onClick={() => {
    //                                 setVisibleModal(true);
    //                               }}
    //                             />
    //                           </div>
    //                         )}
    //                   </div>
    //                 </div>
    //                 <div className="infosMain">
    //                   <span
    //                     className="details"
    //                     title={
    //                       currentSelectedMailMessage?.creationTime &&
    //                       moment(
    //                         currentSelectedMailMessage?.creationTime
    //                       ).format("LLLL")
    //                     }
    //                   >
    //                     {currentSelectedMailMessage?.creationTime &&
    //                       moment(
    //                         currentSelectedMailMessage?.creationTime
    //                       ).format("LLLL")}
    //                   </span>
    //                 </div>
    //               </div>
    //               <div className="email-to-cc">
    //                 <div className="infosMain">
    //                   <span className="title">To:</span>
    //                   <div>
    //                     <span>
    //                       <a
    //                         href="#"
    //                         className="active"
    //                         title={participantTypeToNames}
    //                       >
    //                         {currentSelectedMailMessage.applicationTypeSystemName !==
    //                         "EMail"
    //                           ? !isEmpty(participantTypeToNames)
    //                             ? participantTypeToNames
    //                             : "<No Name>"
    //                           : emailTypeToNames}
    //                       </a>
    //                     </span>
    //                   </div>
    //                 </div>
    //               </div>
    //               {!isEmpty(participantTypeCCNames) ||
    //                 (!isEmpty(emailTypeCCNames) && (
    //                   <>
    //                     <div
    //                       className={`infosMain email-to ${
    //                         isEmpty(emailTypeCCNames) ||
    //                         (isEmpty(participantTypeCCNames) && "display-none")
    //                       }`}
    //                     >
    //                       <span className="title">Cc:</span>
    //                       <div>
    //                         <a
    //                           href="#"
    //                           className="active"
    //                           title={participantTypeCCNames}
    //                         >
    //                           {currentSelectedMailMessage.applicationTypeSystemName !==
    //                           "EMail"
    //                             ? !isEmpty(participantTypeCCNames)
    //                               ? participantTypeCCNames
    //                               : "<No Name>"
    //                             : emailTypeCCNames}
    //                         </a>
    //                       </div>
    //                     </div>
    //                   </>
    //                 ))}
    //             </div>
    //           </div>
    //           <div className="emailDetails__body--infos" ref={printTableRef}>
    //             {hideInternalMessage ? (
    //               <Split
    //                 sizes={[65, 35]}
    //                 className="split__chat"
    //                 minSize={[50, 400]}
    //                 maxSize={300}
    //                 style={{ display: "flex", width: "100%" }}
    //                 expandToMin={false}
    //                 gutterSize={4}
    //                 gutterAlign="center"
    //                 snapOffset={30}
    //                 dragInterval={1}
    //                 cursor="col-resize"
    //               >
    //                 <div
    //                   className={`meetingInfos ${
    //                     isEmpty(emailTypeCCNames || participantTypeCCNames) &&
    //                     "height"
    //                   }`}
    //                 >
    //                   {currentSelectedMailMessage.applicationTypeSystemName !==
    //                     "Leave" &&
    //                     currentSelectedMailMessage.applicationTypeSystemName !==
    //                       "Loan" && (
    //                       <>
    //                         {/* {!isEmpty(
    //                           currentSelectedMailMessage?.mailMessageAttachmentDtos
    //                         ) && (
    //                           <div className="email-attachment">
    //                             <span
    //                               className="emailDetails__body--headerAttach"
    //                               onClick={() =>
    //                                 setAttachmentModalVisible(true)
    //                               }
    //                             >
    //                               <img src={attachment} />{" "}
    //                               {
    //                                 currentSelectedMailMessage?.mail
    //                                   ?.mailMessageAttachmentDtos
    //                               }{" "}
    //                               Attachments
    //                             </span>
    //                           </div>
    //                         )} */}
    //                       </>
    //                     )}
    //                   <div className="email-description">
    //                     {currentSelectedMailMessage.applicationTypeSystemName ===
    //                     "Leave" ? (
    //                       <LeaveDetails
    //                         leaveAppId={
    //                           currentSelectedMailMessage?.applicationTypeDetails
    //                             ?.id
    //                         }
    //                       />
    //                     ) : currentSelectedMailMessage.applicationTypeSystemName ===
    //                       "Loan" ? (
    //                       <LoanDetails
    //                         loanAppId={
    //                           currentSelectedMailMessage?.applicationTypeDetails
    //                             ?.id
    //                         }
    //                       />
    //                     ) : (
    //                       <p
    //                         className="main_content"
    //                         dangerouslySetInnerHTML={{
    //                           __html: initialMessage,
    //                         }}
    //                         style={{ paddingLeft: 10 }}
    //                       ></p>
    //                     )}
    //                   </div>
    //                 </div>

    //                 <div
    //                   className={`emailDetails__body--chat ${
    //                     hideInternalMessage ? "hide" : ""
    //                   }`}
    //                 >
    //                   <div className="chatmail">
    //                     <div className="chatmail__header--wrapper">
    //                       <div className="chatmail__header--leftcontent">
    //                         <div className="chatmail__image">
    //                           {isEmpty(
    //                             instanceMessageCenterPagination.items
    //                           ) ? (
    //                             <img src={internalmessage} />
    //                           ) : (
    //                             <img src={internalMessageGreen} />
    //                           )}
    //                         </div>
    //                         <div className="chatmail__text">Message</div>
    //                       </div>
    //                       <div className="chatmail__header--rightcontent">
    //                         <div className="chatmail__image">
    //                           {/* <Image src={Extend} shape="square" preview={false} /> */}
    //                         </div>

    //                         <div
    //                           className="chatmail__image"
    //                           onClick={() =>
    //                             setHideInternalMessage(!hideInternalMessage)
    //                           }
    //                         >
    //                           <img src={hide} />
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div
    //                     className={`emailDetails__body--chatwrap ${
    //                       chatBox ? "" : "showHeight"
    //                     }`}
    //                   >
    //                     <EmailChat
    //                       handleReply={handleReply}
    //                       instanceMessageCenterPagination={
    //                         instanceMessageCenterListing
    //                       }
    //                       mailMessageParticipantTypeListing={
    //                         mailMessageParticipantTypeListing
    //                       }
    //                       setReplyofChat={setReplyofChat}
    //                       setchatBox={setchatBox}
    //                       chatBox={chatBox}
    //                     />
    //                   </div>
    //                   {/* <div className={`emailDetails__body--chatwrap ${chatBox ? '' : 'showHeight'}`}>
    // 										<EmailChat
    // 											handleReply={handleReply}
    // 											instanceMessageCenterPagination={instanceMessageCenterListing}
    // 											mailMessageParticipantTypeListing={mailMessageParticipantTypeListing}
    // 											setReplyofChat={setReplyofChat}
    // 										/>
    // 									</div> */}

    //                   <ReplyChatBox
    //                     replyOfChat={replyOfChat}
    //                     mailMessageParticipantTypeListing={
    //                       mailMessageParticipantTypeListing
    //                     }
    //                     mailMessageParticipationStatusListing={
    //                       mailMessageParticipationStatusListing
    //                     }
    //                     allParticipants={allParticipants}
    //                     replyMsgDetails={replyMsgDetails}
    //                     currentSelectedMailMessage={currentSelectedMailMessage}
    //                     setchatBox={setchatBox}
    //                     replyStatus={replyStatus}
    //                     chatBox={chatBox}
    //                     setReplyofChat={setReplyofChat}
    //                     hideInternalMessage={hideInternalMessage}
    //                   />

    //                   {/* <div
    // 										className="chatmail__image"
    // 										style={{ position: 'absolute', bottom: 0, display: 'flex', justifyContent: 'flex-start', paddingLeft: '17px', alignItems: 'center', height: '23%' }}
    // 									>
    // 										{!chatBox ? (
    // 											<div>
    // 												<Button onClick={(() => setchatBox(true))} className="btn btn-internalChat">
    // 													+ New Message
    // 												</Button>
    // 											</div>
    // 										) : (
    // 											''
    // 										)}
    // 									</div> */}
    //                 </div>
    //               </Split>
    //             ) : (
    //               <div
    //                 className="email-internal-hide-show"
    //                 style={{
    //                   display: "flex",
    //                   paddingLeft: "10px",
    //                   justifyContent: "space-between",
    //                 }}
    //               >
    //                 <div className="meetingInfos">
    //                   {currentSelectedMailMessage.applicationTypeSystemName !==
    //                     "Leave" &&
    //                     currentSelectedMailMessage.applicationTypeSystemName !==
    //                       "Loan" && (
    //                       <>
    //                         {!isEmpty(
    //                           currentSelectedMailMessage?.mailMessageAttachmentDtos
    //                         ) && (
    //                           <div
    //                             className="email-attachment"
    //                             style={{ background: "transparent" }}
    //                           >
    //                             <span
    //                               className="emailDetails__body--headerAttach"
    //                               onClick={() =>
    //                                 setAttachmentModalVisible(true)
    //                               }
    //                             >
    //                               <img src={attachment} />{" "}
    //                               {
    //                                 currentSelectedMailMessage
    //                                   ?.mailMessageAttachmentDtos.length
    //                               }{" "}
    //                               Attachments
    //                             </span>
    //                           </div>
    //                         )}
    //                       </>
    //                     )}
    //                   <div className="email-description">
    //                     <p
    //                       className="main_content"
    //                       dangerouslySetInnerHTML={{
    //                         __html: initialMessage,
    //                       }}
    //                     ></p>
    //                   </div>
    //                 </div>
    //                 <div
    //                   className="chatmail__header--wrapper-hide chatmail__image"
    //                   style={{ width: "38px" }}
    //                 >
    //                   <div
    //                     style={{ cursor: "pointer", width: "30px" }}
    //                     onClick={() => setHideInternalMessage(true)}
    //                   >
    //                     {isEmpty(instanceMessageCenterPagination.items) ? (
    //                       <Image
    //                         src={internalmessage}
    //                         shape="square"
    //                         preview={false}
    //                       />
    //                     ) : (
    //                       <Image
    //                         src={internalMessageGreen}
    //                         shape="square"
    //                         preview={false}
    //                       />
    //                     )}
    //                   </div>

    //                   <div
    //                     style={{
    //                       cursor: "pointer",
    //                       marginTop: 5,
    //                       width: "30px",
    //                     }}
    //                     onClick={() => {
    //                       setHideInternalMessage(true);
    //                     }}
    //                   >
    //                     {/* {isEmpty(instanceMessageCenterPagination.items) ? (
    // 										<Image src={internalMsg} shape="square" preview={false} onClick={() => setchatBox(true)} />
    // 									) : (
    // 										<Image src={internalmessage} shape="square" preview={false} />
    // 									)} */}
    //                   </div>
    //                 </div>
    //                 {/* <div className="chatmail__image" onClick={() => setchatBox(true)}>
    // 							</div> */}
    //               </div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //       {/* <Modal visible={attachmentModalVisible} title={'Attachments'} footer={null} onCancel={() => setAttachmentModalVisible(false)}>
    // 			{!isEmpty(currentSelectedMailMessage?.mailMessageAttachmentDtos) &&
    // 				currentSelectedMailMessage?.mailMessageAttachmentDtos.map((fileList, i) => {
    // 					const fileType = fileList.displayFileName.split('.').pop();
    // 					return (
    // 						<div key={i}>
    // 							<img src={attachment} style={{ paddingRight: 8 }} />
    // 							{fileType === 'pdf' || fileType === 'jpg' || fileType === 'jpeg' ? (
    // 								<a
    // 									onClick={() => {
    // 										if (fileType === 'pdf') {
    // 											window.open(`${cqBaseUrl}/${fileList?.viewFileURL}${token}`, '_blank', 'top=50,left=200,frame=true');
    // 										} else {
    // 											dispatch(setSelectedFileName(`${cqBaseUrl}/${fileList?.viewFileURL}${token}`));
    // 											let dirname = electron.commonApi.getDirName();
    // 											window.open(`file://${dirname}/index.html#/viewFile`, '_blank', 'top=50,left=200,frame=true');
    // 										}
    // 									}}
    // 								>
    // 									{fileList.displayFileName}
    // 								</a>
    // 							) : (
    // 								<a href={`${cqBaseUrl}/${fileList?.viewFileURL}${token}`} download>
    // 									{fileList.displayFileName}
    // 								</a>
    // 							)}
    // 						</div>
    // 					);
    // 				})}
    // 		</Modal> */}
    //     </div>
    //   )}
    //   {/* <Modal visible={visibleModal} footer={null} onCancel={() => setVisibleModal(false)}>
    // 		<AddNewQuickEmail setVisibleModal={setVisibleModal} emailText={checkEmail} />
    // 	</Modal> */}

    //   <BusinessModal
    //     instanceMessageCenterPagination={currentSelectedMailMessage}
    //     showBusinessModal={showBusinessModal}
    //     setShowBusinessModal={setShowBusinessModal}
    //     referenceTags={mailReferenceGetDetailsResponse}
    //     currentSelectedMailMessage={currentSelectedMailMessage}
    //   />
    // </div>
  );
};

export default EmailForwardSend;
