import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// import AddUserDrawer from "./AddUserDrawer";
import SectorAddUpdateTable from './SectorTable';
import { employeeProfileDetail } from 'services/redux/profile/profile.action';
import { SectorAddUpdateDrawer } from './SectorDrawer';
import { ProjectService } from '../../../../services/redux/Reference/Project/api';

const Sector = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const onReset = () => {
		form.resetFields();
	};

	const [addUpdateSectorDrawerVisibility, setaddUpdateSectorDrawerVisibility] = useState(false);
	const [isUpdateSector, setIsUpdateSector] = useState(false);

	const showAddUpdateSectorDrawer = (e, record) => {
		setaddUpdateSectorDrawerVisibility(false);
		if (record) {
			setIsUpdateSector(record);
		} else {
			setIsUpdateSector(false);
		}
		setaddUpdateSectorDrawerVisibility(true);
	};

	const onAddUpdateSectorClose = () => {
		setIsUpdateSector('');
		setaddUpdateSectorDrawerVisibility(false);
	};

	return (
		<div className="layout">
			<div className="organizer events">
				<div className="all-header-wrapper">
					<div className="meeting__flex">
						<h3 className="header__all">Sector List</h3>
						<div className="organizerHoliday__middle--wrap">
							<Button
								className="btn-primary"
								onClick={() => {
									showAddUpdateSectorDrawer();
								}}
							>
								<PlusOutlined /> New Sector
							</Button>
						</div>
					</div>
				</div>
				<SectorAddUpdateTable showAddUpdateSectorDrawer={showAddUpdateSectorDrawer} />
				<SectorAddUpdateDrawer drawerVisibility={addUpdateSectorDrawerVisibility} onAddUpdateSectorClose={onAddUpdateSectorClose} isUpdateSector={isUpdateSector} />
			</div>
		</div>
	);
};

export default Sector;
