import {
  Button,
  Drawer,
  Form,
  Input,
  Layout,
  Upload,
  message,
  Progress,
  Col,
  Select,
  Checkbox,
  Row,
} from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import ImageUploaderMultiple from "components/shared/ImageUploaderMultiple";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postService } from "services/commonServices";
import { getAllUsers, uploadFile } from "services/remanentCalls";
import { appBaseUrl } from "utils/config";
import { uploadNewFile } from "services/redux/Reference/Folder/action";
import { getReferenceListing } from "services/redux/Reference/action";
import { getDynamicFileLabelTypeUploaderDetail } from "services/redux/common/dynamicFileType/action";

const { Header } = Layout;

const FileDrawer = ({
  showAddUpdateFileDrawer,
  setShowAddUpdateFileDrawer,
  folderRecord,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { folderListingLoading } = useSelector((state) => state.folder);
  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );
  const [searchValueReference, setSearchValueReference] = useState(null);
  const [filterReference, setFilterReference] = useState([]);
  const [selectedReferenceType, setSelectedReferenceType] = useState(null);
  const [progress, setProgress] = useState(0);
  const [permissionToAdd, setPermissionToAdd] = useState(true);
  const [messageParticipants, setMessageParticipants] = useState([]);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setMessageParticipants(resp.data);
    }
  };
  useEffect(() => {
    if (isEmpty(messageParticipants)) {
      fetchAllParticipants();
    }
  }, []);

  useEffect(() => {
    setFilterReference(referenceListingResponse?.items);
  }, [referenceListingResponse]);

  useEffect(() => {
    if (!isEmpty(searchValueReference)) {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  useEffect(() => {
    if (!isEmpty(selectedReferenceType)) {
      dispatch(getDynamicFileLabelTypeUploaderDetail(selectedReferenceType));
    }
  }, [selectedReferenceType]);

  const onSubmit = (values) => {
    values.dmsFolderStructureId = folderRecord.id;
    values.isPublic = permissionToAdd;
    console.log("values", values);
    dispatch(uploadNewFile(values));
    drawerClose();
  };

  const drawerClose = () => {
    setShowAddUpdateFileDrawer(false);
  };

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgress,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
    });
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };

      let olduploadedFileDtos = form.getFieldValue().uploadedFileDtos
        ? [...form.getFieldValue().uploadedFileDtos]
        : [];

      let newUploadedFileDtos = [...olduploadedFileDtos];
      newUploadedFileDtos.push(alteredResponse);

      form.setFieldsValue({
        uploadedFileDtos: newUploadedFileDtos,
      });
    }
  };

  return (
    <Drawer
      visible={showAddUpdateFileDrawer}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={drawerClose}
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">Upload a file</h4>
      </Header>

      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        // validateMessages={validateMessages}
        onFinish={onSubmit}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          Upload<span className="red-star">*</span>
          <Upload
            name="uploadedFiles"
            multiple={true}
            itemRender={(files) => {
              <div style={{ display: "none" }}>{files}</div>;
            }}
            action={`${appBaseUrl}/file/uploadFile/`}
            customRequest={uploadPhotos}
          >
            <FileAddOutlined style={{ padding: "0 10px 0 10px" }} />
          </Upload>
          {progress > 0 ? <Progress percent={progress} /> : null}
          {!isEmpty(form.getFieldValue().uploadedFileDtos) && (
            <Col span={24}>
              <div className="email-text-input">
                <div className="email-text">Attachment</div>
                <Form.Item
                  name="uploadedFileDtos"
                  className="form__group"
                  rules={[{ required: false }]}
                >
                  <ImageUploaderMultiple
                    name="emailMessageAttachmentDtos"
                    isMultipleFileAllowed={true}
                    id=""
                    uploadedFileDtos={form.getFieldValue()}
                    formName="uploadedFileDtos"
                    form={form}
                  />
                </Form.Item>
              </div>
            </Col>
          )}
          <Form.Item className="form__group" name="referenceId" label="Ref">
            <Select
              showSearch
              placeholder="Select reference"
              optionFilterProp="label"
              allowClear={true}
              onSearch={(value) => {
                setSearchValueReference(value);
              }}
              onChange={(value, option) =>
                setSelectedReferenceType(option?.label[2])
              }
              autoComplete="off"
            >
              {!isEmpty(filterReference) &&
                filterReference?.map((reference, index) => {
                  return (
                    <Option
                      value={reference.id}
                      key={index}
                      label={[
                        reference.code,
                        reference.title,
                        reference.referencType,
                      ]}
                    >
                      <div className="demo-option-label-item">
                        {}
                        <div>
                          {reference.code} {""}
                        </div>
                        <div>{reference.title}</div>
                      </div>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="notes" className="form__group" label="Remarks">
            <Input />
          </Form.Item>
        </div>
        <div style={{ paddingLeft: 10 }}>
          <div>Give Permission to</div>
          <div>
            <Checkbox
              onChange={() => {
                setPermissionToAdd(!permissionToAdd);
              }}
            >
              Private
            </Checkbox>
          </div>

          {!permissionToAdd && (
            <Row span={24}>
              <div>
                <Form.Item
                  className="form__group"
                  name={`userIds`}
                  rules={[{ required: true, message: "" }]}
                  // style={{ width: "100%" }}
                >
                  <Select
                    optionFilterProp="label"
                    // bordered={false}
                    style={{ width: "450px" }}
                    mode="multiple"
                    autoFocus={true}
                    placeholder={`Internal To`}
                    // onChange={(value, option) => changeParticipantsDtosTo(option)}
                  >
                    {messageParticipants?.map((participant, j) => (
                      <Option
                        key={j}
                        value={participant.appUserId}
                        label={[participant.fullName, participant.userName]}
                      >
                        <div className="demo-option-label-item">
                          <span role="img">
                            {participant.fullName} {"" + ""}
                          </span>
                          [{participant.userName.toUpperCase()}]
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Row>
          )}
        </div>
        <div className="model-footer">
          <Button onClick={drawerClose} loading={folderListingLoading}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default FileDrawer;
