import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Collapse,
  Form,
  DatePicker,
  TimePicker,
  Button,
  Layout,
  Drawer,
  Input,
  Popover,
  Checkbox,
  Select,
} from "antd";
import { forIn, isEmpty } from "lodash";
import moment from "moment";
import ROTO from "../../Roto";
import DocumentPurchase from "../../DocumentPurchase";
import { allCompanyListing } from "services/redux/Reference/action";
import DynamicImageUploader from "components/shared/DynamicImageUploader";
import { numberWithCommas, getDaysDifference } from "utils/commonFunc";

const SingleStage = ({
  allCountryListingResponse,
  sisterCompanyListing,
  getDetails,
  changetest,
  singleStageForm,
  stages,
  documentPurchaseForm,
  getDayDiffSecurity,
  setShowParticipation,
  dynamicTenderDocumentFileTypesState,
  dynamicTenderNoticeFileTypesState,
  dynamicROTOFileTypesState,
  showParticipation,
  setDocumentOffFile,
  setSubmissionDate,
  setOpeningDate,
}) => {
  const { Panel } = Collapse;
  const { Option } = Select;
  const [commaSeparatorNum, setCommaSeparatorNum] = useState("");
  const [invitationDate, setInvitationDate] = useState();

  const dispatch = useDispatch();
  const { allCurrencyListingResponse } = useSelector(
    (state) => state.reference
  );
  const { Header } = Layout;
  const [setForm, setOpenForm] = useState(true);
  useEffect(() => {
    if (stages === "doubleStages") {
      singleStageForm.resetFields();
    }
  }, [stages]);

  useEffect(() => {
    dispatch(allCompanyListing());
  }, [dispatch]);

  const onSelect = (e) => {
    if (e.target.checked === true) {
      setOpenForm(true);
    } else {
      setOpenForm(false);
    }
  };

  const numberCommaSepartor = (event) => {
    let projectCost = singleStageForm.getFieldValue("documentCost");

    const commaNum = projectCost.replace(/\,/g, "");
    if (isNaN(Number(commaNum))) {
      singleStageForm.setFieldsValue({ documentCost: commaSeparatorNum });
      return;
    }

    let tmp = projectCost.replace(/,/g, "");
    let val = Number(tmp).toLocaleString();
    if (tmp == "") {
      singleStageForm.setFieldsValue({ documentCost: "" });
    } else {
      singleStageForm.setFieldsValue({ documentCost: val });
      setCommaSeparatorNum(val);
    }
  };
  const bidSecuritySeperator = (event) => {
    let projectCost = singleStageForm.getFieldValue("bidSecurityValue");

    const commaNum = projectCost.replace(/\,/g, "");
    if (isNaN(Number(commaNum))) {
      singleStageForm.setFieldsValue({ bidSecurityValue: commaSeparatorNum });
      return;
    }

    let tmp = projectCost.replace(/,/g, "");
    let val = Number(tmp).toLocaleString();
    if (tmp == "") {
      singleStageForm.setFieldsValue({ bidSecurityValue: "" });
    } else {
      singleStageForm.setFieldsValue({ bidSecurityValue: val });
      setCommaSeparatorNum(val);
    }
  };
  return (
    <>
      {setForm ? (
        <Form
          autoComplete="off"
          layout="vertical"
          form={singleStageForm}
          name="control-hooks"
          style={{ padding: 7 }}
        >
          <div className="global-form-flex " style={{ flexFlow: "row wrap" }}>
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>
                  Inviatation Date<span className="red-star">*</span>
                </label>
              </div>
              <div className="global-form-input">
                <Form.Item
                  name="inviatationDate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    size="small"
                    format="YYYY/MM/DD"
                    onChange={(value, string) => {
                      console.log("string", string);
                      setInvitationDate(Date.parse(string));
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            {dynamicTenderNoticeFileTypesState.map((u, i) => (
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Invitation Notice</label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name={[`${u.name}FileDtos`]}
                    className="form__group"
                    rules={[{ required: false }]}
                  >
                    <DynamicImageUploader
                      name={u.name}
                      isMultipleFileAllowed={u.isMultipleFileAllowed}
                      id={u.id}
                      allowedExtensions={u.allowedExtensions}
                      // fileList={fileList}
                      // setFileList={setFileList}
                      index={i}
                      form={singleStageForm}
                      callback={(e) => {
                        let olduploadedFileDtos =
                          singleStageForm.getFieldValue()[`${u.name}FileDtos`]
                            ? [
                                ...singleStageForm.getFieldValue()[
                                  `${u.name}FileDtos`
                                ],
                              ]
                            : [];

                        let uploadedFileDtos = [...olduploadedFileDtos];
                        uploadedFileDtos.push(e);

                        singleStageForm.setFieldsValue({
                          [`${u.name}FileDtos`]: uploadedFileDtos,
                        });

                        // form.setFieldsValue({
                        //   uploadedFileDtos: [
                        //     ...olduploadedFileDtos,
                        //     e
                        //   ]
                        //     })
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            ))}
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Invitation Ref</label>
              </div>
              <div className="global-form-input">
                <Form.Item
                  name="inviatationRef"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </div>
            </div>
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Description</label>
              </div>
              <div className="global-form-input">
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input.TextArea size="small" />
                </Form.Item>
              </div>
            </div>
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Document Cost</label>
              </div>
              <div className="global-form-input flex">
                <Form.Item
                  className="currency"
                  name="documentCostCurrencyId"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  {!isEmpty(allCurrencyListingResponse) && (
                    <Select
                      showSearch
                      defaultValue={() => {
                        let value = "";
                        allCurrencyListingResponse?.map((currency) => {
                          if (currency.abbvr == "NPR") {
                            value = currency.currencyId;
                          }
                        });

                        return value;
                      }}
                      size="small"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {allCurrencyListingResponse?.map((currency) => (
                        <Option
                          key={currency.currencyId}
                          value={currency.currencyId}
                        >
                          {currency.abbvr}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  className="budget-number"
                  name="documentCost"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    size="small"
                    numberCommaSeparator
                    onChange={numberCommaSepartor}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Submission Date</label>
              </div>
              <div className="profiledetails__rowedit--data global-form-input">
                <Form.Item
                  name="submissionDate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  // initialValue={moment()}
                >
                  <DatePicker
                    // onChange={(e, dateString) => {
                    //   setShowParticipation(e._d);
                    //   // setSubmissionDate(dateString);
                    // }}
                    // size="small"
                    // // format="YYYY/MM/DD - h:mm A"
                    // disabledDate={(current) => {
                    //   if (!!invitationDate === true) {
                    //     // console.log("invitation date is running")
                    //     return current && current.valueOf() < invitationDate;
                    //   }
                    // }}
                    format="YYYY/MM/DD  hh:mm A"
                    minuteStep={15}
                    showTime
                    // defaultValue={moment()}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Opening Date</label>
              </div>
              <div className="global-form-input">
                <Form.Item
                  name="openingDate"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  {/* <DatePicker
                    showTime
                    // defaultValue={moment()}
                    // format="YYYY/MM/DD - h:mm A"
                    onChange={(e, dateString) => {
                      // console.log(value,dateString)
                      // setOpeningDate(dateString);
                    }}
                  /> */}
                  <DatePicker
                    // // defaultValue={moment()}
                    // // format="YYYY/MM/DD - h:mm A"
                    // onChange={(e, dateString) => {
                    //   // console.log(value,dateString)
                    //   // setOpeningDate(dateString);
                    // }}
                    size="small"
                    format="YYYY/MM/DD   hh:mm A"
                    showTime
                    minuteStep={15}
                    // onChange={(value, string) => {
                    //   setOpeningDate(Date.parse(string));
                    // }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Bid Validity Till</label>
              </div>
              <div className="global-form-input">
                <Form.Item
                  name="bidValidityDate"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <DatePicker size="small" format="YYYY/MM/DD" />
                </Form.Item>
              </div>
            </div>
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Bid Security Value</label>
              </div>
              <div className="global-form-input flex">
                <Form.Item
                  className="currency"
                  name="bidSecurityValueCurrencyId"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  {!isEmpty(allCurrencyListingResponse) && (
                    <Select
                      size="small"
                      defaultValue={() => {
                        let value = "";
                        allCurrencyListingResponse?.map((currency) => {
                          if (currency.abbvr == "USD") {
                            value = currency.currencyId;
                          }
                        });

                        return value;
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {allCurrencyListingResponse?.map((currency) => (
                        <Option
                          key={currency.currencyId}
                          value={currency.currencyId}
                        >
                          {currency.abbvr}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  className="budget-number"
                  name="bidSecurityValue"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    size="small"
                    numberCommaSeparator
                    onChange={bidSecuritySeperator}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label> Bid Security Validity Till</label>
              </div>
              <div className="global-form-input">
                <Form.Item
                  name="bidSecurityValueDate"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <DatePicker size="small" format="YYYY/MM/DD" />
                </Form.Item>
              </div>
            </div>
            {dynamicTenderDocumentFileTypesState.map((u, i) => (
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Official Document</label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name={[`${u.name}FileDtos`]}
                    className="form__group"
                    rules={[{ required: false }]}
                  >
                    <DynamicImageUploader
                      name={u.name}
                      isMultipleFileAllowed={u.isMultipleFileAllowed}
                      id={u.id}
                      allowedExtensions={u.allowedExtensions}
                      // fileList={fileList}
                      // setFileList={setFileList}
                      index={i}
                      form={singleStageForm}
                      callback={(e) => {
                        let olduploadedFileDtos =
                          singleStageForm.getFieldValue()[`${u.name}FileDtos`]
                            ? [
                                ...singleStageForm.getFieldValue()[
                                  `${u.name}FileDtos`
                                ],
                              ]
                            : [];

                        let uploadedFileDtos = [...olduploadedFileDtos];
                        uploadedFileDtos.push(e);

                        singleStageForm.setFieldsValue({
                          [`${u.name}FileDtos`]: uploadedFileDtos,
                        });
                        setDocumentOffFile(uploadedFileDtos);

                        // form.setFieldsValue({
                        //   uploadedFileDtos: [
                        //     ...olduploadedFileDtos,
                        //     e
                        //   ]
                        //     })
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            ))}
            <div className="roto-wrapper">
              {getDaysDifference(showParticipation) <= 0 && (
                <ROTO
                  dynamicROTOFileTypesState={dynamicROTOFileTypesState}
                  singleStageForm={singleStageForm}
                  showParticipation={showParticipation}
                />
              )}
            </div>
          </div>
        </Form>
      ) : (
        ""
      )}
    </>
  );
};

export default SingleStage;
