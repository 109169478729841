import queryString from "query-string";
import {
  getService,
  postService,
  deletService,
  putService,
} from "services/commonServices";
import { appBaseUrl } from "utils/config";

export class ContactCompanyPeopleService {
  allPersonListing(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/contactPersonListing?${query}`;
    let data = getService(url);
    return data;
  }

  allSearchPersonListing(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/PersonListing?${query}`;
    let data = getService(url);
    return data;
  }

  contactCompanyPersonSearchPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/contactCompanyPersonSearchPagination?${query}`;
    let data = getService(url);
    return data;
  }

  expertCompanyPersonSearchPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/expertCompanyPersonSearchPagination?${query}`;
    let data = getService(url);
    return data;
  }

  getContactCompanyPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/contactCompanyPagination?${query}`;
    let data = getService(url);
    return data;
  }

  getContactPersonPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/contactPersonPagination?${query}`;
    let data = getService(url);
    return data;
  }
  getExpertPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/contactRequest/expertsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  contactCompanyGetDetails(id) {
    let url = `${appBaseUrl}/contactRequest/contactCompanyGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  contactPersonGetDetails(id) {
    let url = `${appBaseUrl}/contactRequest/contactPersonGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  expertPersonGetDetails(id) {
    let url = `${appBaseUrl}/contactRequest/expertGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  contactCompanyDetailAdd(body) {
    let url = `${appBaseUrl}/contactRequest/contactCompanyDetailAdd`;
    let data = postService(url, body);
    return data;
  }

  contactCompanyUpdate(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactCompanyUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  contactPersonUpdate(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactPersonUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  contactPersonAdd(body) {
    let url = `${appBaseUrl}/contactRequest/contactPersonAdd`;
    let data = postService(url, body);
    return data;
  }

  // QUICK CONTACT PERSON ADD
  quickPersonAdd(body) {
    let url = `${appBaseUrl}/contactRequest/quickPersonAdd`;
    let data = postService(url, body);
    return data;
  }

  expertPersonAdd(body) {
    let url = `${appBaseUrl}/contactRequest/expertAdd`;
    let data = postService(url, body);
    return data;
  }

  quickContactCompanyAdd(body) {
    let url = `${appBaseUrl}/contactRequest/ContactCompanyQuickAdd`;
    let data = postService(url, body);
    return data;
  }

  contactCompanyFavAddUpdate(body) {
    let url = `${appBaseUrl}/contactRequest/contactFavoriteCompanyAddUpdate`;
    let data = postService(url, body);
    return data;
  }

  contactPersonFavAddUpdate(body) {
    let url = `${appBaseUrl}/contactRequest/contactFavoritePersonAddUpdate`;
    let data = postService(url, body);
    return data;
  }
  contactCompanyNotes(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactCompanyNoteUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  contactPersonNotes(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactPersonNoteUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  contactQucikAddEmail(body) {
    let url = `${appBaseUrl}/contactRequest/quickEmailAdd`;
    let data = postService(url, body);
    return data;
  }

  contactCompanyDelete(id) {
    let url = `${appBaseUrl}/contactRequest/contactCompanyDelete/${id}`;
    let data = deletService(url, id);
    return data;
  }

  personDelete(id) {
    let url = `${appBaseUrl}/contactRequest/contactPersonDelete/${id}`;
    let data = deletService(url, id);
    return data;
  }

  companyActiveStatus(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactCompanyActiveInactiveUpdate/${id}`;
    let data = putService(url, body);
    return data;
  }

  personActiveStatus(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactPersonActiveInActiveUpdate/${id}`;
    let data = putService(url, body);
    return data;
  }
}
