import React from "react";
import { Row, Col, Button, Affix } from "antd";
import { useDispatch } from "react-redux";
import { actionForUpdateJobInterview } from "services/redux/hrSystem/action";

function JobInterviewListDetails({ selectedData, setJobForm, jobFormData }) {
  const dispatch = useDispatch();

  const data = (values) => {
    setJobForm((prevJobForm) => ({
      ...prevJobForm,
      view: false,
      update: true,
      add: false,
      data: { ...selectedData },
    }));
  };

  const onhandleDelete = (values) => {
    const updatedData = {
      ...jobFormData.data,
      isActive: false,
    };
    dispatch(actionForUpdateJobInterview(updatedData?.id, updatedData));
    setJobForm((prevJobForm) => ({
      ...prevJobForm,
      view: false,
      update: false,
      add: false,
    }));
  };

  return (
    <>
      <Row className="hrSystem_job_interview-row">
        <Col span={12}>Relevant Work Experience</Col>
        <Col span={12}>{selectedData?.workExperience || null}</Col>
        <Col span={12}>Enthusisam / Energy</Col>
        <Col span={12}>{selectedData?.enthusiasm_energy || null}</Col>
        <Col span={12}>Learning Attitude</Col>
        <Col span={12}>{selectedData?.learning_Attitude || null}</Col>
        <Col span={12}>Public Relation</Col>
        <Col span={12}>{selectedData?.publicRelation || null}</Col>
        <Col span={12}>Flexibility</Col>
        <Col span={12}>{selectedData?.flexibility || null}</Col>
        <Col span={12}>Emotional Intelligence</Col>
        <Col span={12}>{selectedData?.emotionalIntelligence || null}</Col>
        <Col span={12}>IQ</Col>
        <Col span={12}>{selectedData?.understanding || null}</Col>
        <Col span={12}>Relevant Education</Col>
        <Col span={12}>{selectedData?.education || null}</Col>
        <Col span={12}>Skills</Col>
        <Col span={12}>{selectedData?.applicableSkills || null}</Col>
        <Col span={12}>Apperance</Col>
        <Col span={12}>{selectedData?.candidateAppearance || null}</Col>
        <Col span={12}>Attitude</Col>
        <Col span={12}>{selectedData?.attitude || null}</Col>
        <Col span={12}>Confidence</Col>
        <Col span={12}>{selectedData?.confidence || null}</Col>
        <Col span={12}>Communication</Col>
        <Col span={12}>{selectedData?.communication || null}</Col>
        <Col span={12}>Language</Col>
        <Col span={12}>{selectedData?.language || null}</Col>
        <Col span={12}>Remarks</Col>
        <Col span={12}>{selectedData?.remarks || null}</Col>
        <Col span={12}>Interviewer Recommentdation</Col>
        <Col span={12}>
          {selectedData?.rating === "1"
            ? "Rejected"
            : selectedData?.rating === "2"
            ? "Short Listed with Reservation"
            : selectedData?.rating === "3"
            ? "Recommendable"
            : selectedData?.rating === "4"
            ? "Highly Recommended"
            : null}
        </Col>
        <Col span={12}>Interview By</Col>
        <Col span={12}>{selectedData?.interviewerName || null}</Col>
        <Col span={12}>Date of Interview</Col>
        <Col span={12}>{selectedData?.secondInterviewSchedule || null}</Col>
      </Row>
      <Affix
        className="hrAffix"
        offsetBottom={0}
        style={{
          background: "#ffffff",
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <Button onClick={onhandleDelete} style={{ marginRight: "8px" }} danger>
          Delete
        </Button>
        <Button type="primary" onClick={data}>
          Edit
        </Button>
      </Affix>
    </>
  );
}

export default JobInterviewListDetails;
