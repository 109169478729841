import { Divider, Layout } from "antd";
import JobDetails from "./jobDetail";
import MainBodyHeader from "./header";
import ApplicantsFormDrawer from "../drawer/applicantsForm";

const { Content } = Layout;

const jobDetail = {
  margin: "0px 0px 0px 10px",
  height: "calc(100vh - 70px)",
  overflow: "auto",
};

function MainBody({
  applicationSingleData,
  openDrawerRecruitment,
  setOpenDrawerRecruitment,
  setOpenApplicantDrawer,
  openDrawerApplicant,
  jobTitleId,
  setSelectedSingleApplicantData,
  activeTabIndexofApplicant,
  setActiveTabIndexofAppicant,
  formApplicantsBasic,
  formApplicantEdu,
  formApplicantJob,
  formApplicantReview,
  setIsGraduated,
  selectedIsGraduated,
  selectedSingleApplicantDAta,
  applicantSingleDataID,
  stageLevelListingData,
  selectedTemporarilyApplicantId,
  hrCorporateMailLisiting,
}) {
  const statusUpdateButton = stageLevelListingData?.map((x) => {
    return {
      label: x.stageLevelName,
      value: x.id,
    };
  });
  return (
    <>
      <Content>
        <MainBodyHeader />
      </Content>
      <Divider style={{ marginTop: "7px" }} />

      <Content style={jobDetail}>
        <JobDetails
          applicationSingleData={applicationSingleData}
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          openDrawerRecruitment={openDrawerRecruitment}
          setOpenDrawerRecruitment={setOpenDrawerRecruitment}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
          statusUpdateButton={statusUpdateButton}
          jobTitleId={jobTitleId}
          hrCorporateMailLisiting={hrCorporateMailLisiting}
        />
      </Content>

      <Content>
        <ApplicantsFormDrawer
          openDrawerApplicant={openDrawerApplicant}
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          jobTitleId={jobTitleId}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          activeTabIndexofApplicant={activeTabIndexofApplicant}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
          formApplicantsBasic={formApplicantsBasic}
          formApplicantEdu={formApplicantEdu}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
          selectedSingleApplicantDAta={selectedSingleApplicantDAta}
          applicantSingleDataID={applicantSingleDataID}
          setIsGraduated={setIsGraduated}
          selectedIsGraduated={selectedIsGraduated}
          statusUpdateButton={statusUpdateButton}
          selectedTemporarilyApplicantId={selectedTemporarilyApplicantId}
        />
      </Content>
    </>
  );
}

export default MainBody;
