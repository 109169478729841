import { HOLIDAY_TYPES } from "services/types";

const INITIAL_STATE = {
  holidayAddResponse: null,
  holidayAddResponseLoading: false,

  holidaySampleResponse: null,
  holidaySampleLoading: false,

  //
  userSiscolListResponse: [],
  userSiscoListLoading: false,
};

export default function holidayReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HOLIDAY_TYPES.HOLIDAY_ADD_INIT:
      return {
        ...state,
        holidayAddResponse: null,
        holidayAddResponseLoading: true,
      };
    case HOLIDAY_TYPES.HOLIDAY_ADD_SUCCESS:
      return {
        ...state,
        holidayAddResponse: action.payload,
        holidayAddResponseLoading: false,
      };
    case HOLIDAY_TYPES.HOLIDAY_ADD_FINISH:
      return {
        holidayAddResponse: null,
        holidayAddResponseLoading: false,
      };
    case HOLIDAY_TYPES.HOLIDAY_PAGINATION_INTI:
      return {
        holidayPaginationResponse: null,
        holidayPaginaitonLoading: true,
      };
    case HOLIDAY_TYPES.HOLIDAY_PAGINATION_SUCCESS:
      return {
        ...state,
        holidayPaginationResponse: action.payload,
        holidayPaginaitonLoading: false,
      };
    case HOLIDAY_TYPES.HOLIDAY_PAGINATION_FINISH:
      return {
        holidayPaginationResponse: null,
        holidayPaginaitonLoading: false,
      };
    case HOLIDAY_TYPES.HOLIDAY_SAMPLE_DATA_INIT:
      return {
        ...state,
        holidaySampleResponse: null,
        holidaySampleLoading: true,
      };
    case HOLIDAY_TYPES.HOLIDAY_SAMPLE_DATA_SUCCESS:
      return {
        ...state,
        holidaySampleResponse: action.payload,
        holidaySampleLoading: false,
      };
    case HOLIDAY_TYPES.HOLIDAY_SAMPLE_DATA_FINISH:
      return {
        ...state,
        holidaySampleLoading: false,
      };

    case HOLIDAY_TYPES.EXPORT_COMPANY_USER_LIST_INIT:
      return {
        ...state,
        userSiscolListResponse: [],
        userSiscoListLoading: true,
      };

    case HOLIDAY_TYPES.EXPORT_COMPANY_USER_LIST_SUCCESS:
      return {
        ...state,
        userSiscolListResponse: action.payload,
        userSiscoListLoading: false,
      };

    case HOLIDAY_TYPES.EXPORT_COMPANY_USER_LIST_FINISH:
      return {
        ...state,
        userSiscoListLoading: false,
      };

    case HOLIDAY_TYPES.EXPORT_SISCO_USER_LIST_INIT:
      return {
        ...state,
        userSiscolListResponse: [],
        userSiscoListLoading: true,
      };

    case HOLIDAY_TYPES.EXPORT_SISCO_USER_LIST_SUCCESS:
      return {
        ...state,
        userSiscolListResponse: action.payload,
        userSiscoListLoading: false,
      };

    case HOLIDAY_TYPES.EXPORT_SISCO_USER_LIST_FINISH:
      return {
        userSiscoListLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
