import React from "react";
import { Modal, Button, Row, Col, Form, Input, Spin } from "antd";
import { useDispatch } from "react-redux";
import { getDashboardProjects } from "services/redux/Reference/Project/action";

const DashboardModal = ({
  visible,
  onCancel,
  onOk,
  onFinish,
  title,
  content,
  footer,
  form,
  width = 600,
  centered = true,
  isLoading,
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      footer={footer}
      width={width}
      centered={centered}
      destroyOnClose
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              label="Title"
              name="SearchKeyword"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              rules={[{ required: true, message: "Please input the title!" }]}
            >
              <Input placeholder="Enter title or Reference Code" />
            </Form.Item>

            {/* <Form.Item
              label="Reference Code"
              name="referenceCode"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              rules={[
                {
                  required: false,
                  message: "Please input the reference code!",
                },
              ]}
            >
              <Input placeholder="Enter reference code" />
            </Form.Item> */}

            <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
              {isLoading ? (
                <Spin />
              ) : (
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default DashboardModal;
