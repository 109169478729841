import React, { useEffect, useState } from "react";
import { filter, indexOf, isEmpty, wrap } from "lodash";
import Split from "react-split";

// includes
import QuickMessageDrawer from "./Includes/QuickMessageDrawer";
import ComposeEmailDrawer from "./Includes/ComposeEmailDrawer";
import EmailList from "./EmailList";
import EmailDetails from "./EmailDetails";
import { withBaseLayout } from "../../layouts/Base";

// antd
import {
  Menu,
  Button,
  Avatar,
  Image,
  Badge,
  Dropdown,
  message,
  Form,
} from "antd";
import { PlusOutlined, MenuFoldOutlined } from "@ant-design/icons";

// redux
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "services/remanentCalls";
import { getOrganizerEventPriorityListings } from "services/redux/organizer/priorityLisiting/action";
import { getMailMessageParticipantTypeListing } from "services/redux/email/participantType/action";
import { getMailMessageParticipantStatusListing } from "services/redux/email/messageCenterType/action";
import { getMailMessageCenterPagination } from "services/redux/email/mailMessageCenter/action";
import { getParticipantTypeEmailAddressesListing } from "services/redux/email/participantEmailAdresses/action";
import { emailGetDetails } from "services/redux/email/mailMessageCenter/action";
import { selectedActiveEmail } from "services/redux/email/persistMail/action";
import { getInstanceMessageCenterPagination } from "services/redux/email/instanceMessageCenter/action";

import { LogoImage } from "utils/imageConstants";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { emailAutoReferenceAdd } from "services/redux/Reference/action";
// import { getTenderBasicSearchPagination } from "services/redux/Reference/Tender/action";

const { SubMenu } = Menu;

const mailIcon = "assets/images/icons/mail.png";

const EmailListDetails = ({
  // mailMessageCenterPagination,
  // currentSelectedMailMessage,
  // currentTab,
  // setCurrentTab,
  // searchKeyword,
  // setSearchKeyword,
  // sortByEmail,
  // setSortByEmail,
  // mailMessageParticipantTypeListing,
  // mailMessageParticipationStatusListing,
  // // replyEmailCompose,
  // setCurrentSelectedMailMessage,
  // allParticipants,
  // messageCenterTypeName,
  hideMailListHeaderAndTabs,
  height,
  setChatHeight,
  chatHeight,
  searchEmailTender,
  emailType,
  Reference_SearchKeyword,
  origin,
  emailDetailCompany,
  tenderSelectedRowId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formSearch] = Form.useForm();

  const priorityEventsState = useSelector((state) => state.priorityEvents);
  const mailMessageParticipantTypeState = useSelector(
    (state) => state.mailMessageParticipantType
  );
  const mailMessageParticipantStatusState = useSelector(
    (state) => state.mailMessageParticipantStatus
  );

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  const { participantTypeEmailAddressesListing } = useSelector(
    (state) => state.participantEmailAddresses
  );
  const { activeEmail } = useSelector((state) => state.mailMessagePeristState);
  const [contextMenushow, setContextMenushow] = useState(false); // hide menu

  const {
    mailBodyGetDetailsResponse,
    mailMessageCenterPagination,
    mailMessagePaginationSuccess,
    mailMessageCenterPaginationLoading,
    mailBodyGetDetailsLoading,
    mailScrollLoader,
    changeInstanceMessageonStatusChange,
    infiniteScrollLoading,
  } = useSelector((state) => state.mailMessageCenter);

  // console.log(
  //   "mailMessagePaginationSuccess",
  //   mailMessagePaginationSuccess,
  //   mailMessageCenterPagination
  // );

  const { emailAutoReferenceAddResponse } = useSelector(
    (state) => state.reference
  );

  const [state, setState] = useState({
    collapsed: true,
  });

  const { organizerEventPriorityListing } = priorityEventsState;
  const { mailMessageParticipantTypeListing } = mailMessageParticipantTypeState;
  const { mailMessageParticipationStatusListing } =
    mailMessageParticipantStatusState;

  const [visibleQuickMessage, setVisibleQuickMessage] = useState(false);
  const [visibleComposeEmail, setVisibleComposeEmail] = useState(false);
  const [bulkStatusLoading, setBulkStatusLoading] = useState(false);
  const [currentSelectedMailMessage, setCurrentSelectedMailMessage] =
    useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [advancedFiltersData, setAdvancedFiltersData] = useState({});
  const [appendUrlState, setAppendUrlState] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortByEmail, setSortByEmail] = useState("desc");
  const [allParticipants, setAllParticipants] = useState([]);
  const [applicationTypeStatus, setApplicationTypeStatus] = useState("");
  const [chatBox, setchatBox] = useState(true);
  const [showSideMenuEmail, setShowSideMenuEmail] = useState(false);
  const [active, setActive] = useState("");
  const [test, setTest] = useState([]);
  const [preSelectEmailCard, setPreSelectEmailCard] = useState(true);
  const [messageCenterId, setMessageCenterId] = useState("");
  const [read, setUnRead] = useState(true);
  const [openAutoReferencing, setOpenAutoReferencing] = useState(false);
  const [predefinedData, setPredefinedData] = useState([]);
  const [referenceTagsFromAutoReference, setReferenceTagsFromAutoReference] =
    useState([]);

  // filter
  const [currentTab, setCurrentTab] = useState(
    selectedMailTypeAndMailData?.currentTab || "All"
  );

  const [messageCenterTypeName, setMessageCenterTypeName] = useState(
    selectedMailTypeAndMailData?.typeSystemName || ""
  );
  const [circularId, setCircularId] = useState();

  const [filteredEmails, setFilteredEmails] = useState([]);
  // console.log("filteredEmails", filteredEmails);

  const [
    corporateSharedAccessAccountHolderUserId,
    setCorporateSharedAccessAccountHolderUserId,
  ] = useState();

  useEffect(() => {
    if (
      emailAutoReferenceAddResponse?.currentMessageCenterReferenceDtos !== null
    ) {
      setReferenceTagsFromAutoReference(emailAutoReferenceAddResponse);
    } else {
      setReferenceTagsFromAutoReference(emailAutoReferenceAddResponse);
    }
  }, [emailAutoReferenceAddResponse]);

  const onCloseQuickMessageDrawer = () => setVisibleQuickMessage(false);
  const onCloseComposeEmail = () => setVisibleComposeEmail(false);

  const toggleCollapse = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };

  const toggleMailCollpaseTrue = () => {
    setState({
      ...state,
      toggleMail: true,
    });
  };

  const toggleMailCollpaseFalse = (key) => {
    setState({
      ...state,
      toggleMail: false,
    });
  };

  const pageSize = emailType === "Business" ? 10 : 10;

  useEffect(() => {
    if (activeEmail) {
      setActive(activeEmail);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [
    searchKeyword,
    sortByEmail,
    searchEmailTender,
    isFavorite,
    read,
    currentTab,
    advancedFiltersData,
    circularId,
    corporateSharedAccessAccountHolderUserId,
    emailDetailCompany?.companyId,
    emailDetailCompany?.referenceName,
    emailType,
    Reference_SearchKeyword,
    messageCenterTypeName,
  ]);

  useEffect(() => {
    setFilteredEmails([]);
  }, [emailType, Reference_SearchKeyword, messageCenterTypeName]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false,
    MessageCenterTypeName = emailType === "Business"
      ? "subordinates"
      : messageCenterTypeName,
    FlagTypeId = "",
    IsRead_SearchKeyword = read ? "" : false,
    ApplicationTypes = [],
    SearchKeyword = searchKeyword,
    SortByEmail = sortByEmail,
    IsFavourite_SearchKeyword = isFavorite ? isFavorite : "",
    IsInstanceMessage_SearchKeyword = currentTab !== "Internal" ? "" : true,
    circularIds
  ) => {
    let appendUrl = `skipCount=${SkipCount}&maxResultCount=${MaxResultCount}&IsRead_SearchKeyword=${IsRead_SearchKeyword}`;

    if (
      advancedFiltersData?.HasAttachment_SearchKeyword === true ||
      advancedFiltersData?.HasAttachment_SearchKeyword === false
    ) {
      appendUrl += `&HasAttachment_SearchKeyword=${advancedFiltersData?.HasAttachment_SearchKeyword}`;
    }

    if (FlagTypeId) {
      appendUrl += `&FlagTypeId=${FlagTypeId}`;
    }

    if (emailDetailCompany?.companyId) {
      appendUrl += `&CompanyId_SearchKeyword=${emailDetailCompany?.companyId}`;
    }

    if (advancedFiltersData?.Company_SearchKeyword) {
      appendUrl += `&Company_SearchKeyword=${advancedFiltersData?.Company_SearchKeyword}`;
    }

    if (advancedFiltersData?.DateRange_DateFrom) {
      appendUrl += `&DateRange_DateFrom=${advancedFiltersData?.DateRange_DateFrom}`;
    }

    if (advancedFiltersData?.DateRange_DateTo) {
      appendUrl += `&DateRange_DateTo=${advancedFiltersData?.DateRange_DateTo}`;
    }

    if (advancedFiltersData?.IsEmail_SearchKeyword) {
      appendUrl += `&IsEmail_SearchKeyword=${advancedFiltersData?.IsEmail_SearchKeyword}`;
    }

    if (advancedFiltersData?.Subject_SearchKeyword) {
      appendUrl += `&Subject_SearchKeyword=${advancedFiltersData?.Subject_SearchKeyword}`;
    }

    // if (advancedFiltersData?.referenceId) {
    //   appendUrl += `&DateRange_DateTo=${advancedFiltersData?.referenceId}`;
    // }

    if (SearchKeyword) {
      appendUrl += `&SearchKeyword=${SearchKeyword}`;
    }

    appendUrl += `&IsInstanceMessage_SearchKeyword=${IsInstanceMessage_SearchKeyword}`;

    if (sortByEmail) {
      appendUrl += `&SortType=${SortByEmail}`;
    }

    if (circularIds || circularId) {
      appendUrl += `&CircularTypeId=${circularIds || circularId}`;
    }

    if (isFavorite) {
      appendUrl += `&IsFavourite_SearchKeyword=${IsFavourite_SearchKeyword}`;
    } else {
      appendUrl += `&IsFavourite_SearchKeyword=${IsFavourite_SearchKeyword}`;
    }
    let paramRef =
      Reference_SearchKeyword ||
      emailDetailCompany?.referenceName ||
      advancedFiltersData?.referenceId;
    if (!isEmpty(paramRef)) {
      appendUrl += `&Reference_SearchKeyword=${paramRef}`;
    }

    if (!isEmpty(ApplicationTypes)) {
      ApplicationTypes.map((type) => {
        appendUrl += `&ApplicationTypes=${type}`;
      });
    }

    let msgCntrType = advancedFiltersData?.messageCenterTypeName
      ? advancedFiltersData?.messageCenterTypeName
      : MessageCenterTypeName;

    if (msgCntrType) {
      appendUrl += `&messageCenterTypeName=${msgCntrType}`;
    }

    if (corporateSharedAccessAccountHolderUserId) {
      appendUrl += `&CorporateSharedAccessAccountHolderUserId=${corporateSharedAccessAccountHolderUserId}`;
    }

    if (advancedFiltersData?.appTypeNames?.length > 0) {
      let __notIncluded = advancedFiltersData?.appTypeNames
        ?.map((x) => {
          return `&NotIncludeApplicationTypes=${x}`;
        })
        ?.join("");
      appendUrl += __notIncluded;
    }

    setAppendUrlState(appendUrl);
    dispatch(
      getMailMessageCenterPagination(appendUrl, loadMore, {
        emailType: emailType,
        reference_SearchKeyword: Reference_SearchKeyword,
      })
    );
  };

  // const { emailAutoReferenceAddResponseLoading } = useSelector(
  //   (state) => state.reference
  // );

  // useEffect(() => {
  //   if (emailAutoReferenceAddResponseLoading === false) {
  //     getData(pageSize, 0, false, messageCenterTypeName);
  //   }
  // }, [emailAutoReferenceAddResponseLoading]);

  useEffect(() => {
    setCurrentSelectedMailMessage("");
    if (
      !isEmpty(mailMessageCenterPagination.items) &&
      bulkStatusLoading === false
    ) {
      if (mailMessageCenterPagination) {
        setCurrentSelectedMailMessage(mailBodyGetDetailsResponse);
      }
    } else {
      setCurrentSelectedMailMessage("");
    }
  }, [
    mailMessageCenterPagination,
    mailBodyGetDetailsResponse,
    searchEmailTender,
    bulkStatusLoading,
  ]);

  useEffect(() => {
    let messageCenterId = "";
    if (changeInstanceMessageonStatusChange) {
      if (
        selectedMailTypeAndMailData?.selectedMail?.applicationTypeSystemName ===
        "InstanceMessage"
      ) {
        messageCenterId =
          selectedMailTypeAndMailData?.selectedMail
            ?.instanceMainMessageCenterId;
        setMessageCenterId(
          selectedMailTypeAndMailData?.selectedMail?.instanceMainMessageCenterId
        );
        instanceGetData(
          20,
          0,
          messageCenterId,
          selectedMailTypeAndMailData?.selectedMail?.applicationId
        );
      } else {
        messageCenterId = selectedMailTypeAndMailData?.selectedMail?.id;
        instanceGetData(
          20,
          0,
          messageCenterId,
          selectedMailTypeAndMailData?.selectedMail?.applicationId
        );
      }
    }
  }, [currentSelectedMailMessage]);

  const instancePageSize = 20;

  const instanceGetData = (
    MaxResultCount,
    SkipCount,
    MessageCenterId,
    applicationId
  ) => {
    dispatch(
      getInstanceMessageCenterPagination({
        MaxResultCount: MaxResultCount,
        SkipCount: SkipCount,
        MessageCenterId: MessageCenterId,
        applicationId: applicationId,
        applicationtypeId:
          selectedMailTypeAndMailData?.selectedMail?.applicationTypeId, // SearchKeyword
      })
    );
  };

  useEffect(() => {
    instanceGetData(
      20,
      0,
      selectedMailTypeAndMailData?.selectedMail?.instanceMainMessageCenterId,
      selectedMailTypeAndMailData?.selectedMail?.applicationId
    );
  }, [selectedMailTypeAndMailData]);

  useEffect(() => {
    if (isEmpty(allParticipants)) {
      fetchAllParticipants();
    }

    if (isEmpty(organizerEventPriorityListing)) {
      dispatch(getOrganizerEventPriorityListings());
    }

    if (isEmpty(mailMessageParticipantTypeListing)) {
      dispatch(getMailMessageParticipantTypeListing());
    }

    if (isEmpty(mailMessageParticipationStatusListing)) {
      dispatch(getMailMessageParticipantStatusListing());
    }

    if (isEmpty(participantTypeEmailAddressesListing)) {
      dispatch(getParticipantTypeEmailAddressesListing());
    }
  }, []);

  // const {
  //   getResponseStatus,
  //   composeEmailRespLoading,
  //   changeEmailStatusTypeRespLoading,
  //   changeEmailStatusTypeResp,
  // } = useSelector((state) => state.mailMessageCenter);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setAllParticipants(resp.data);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a
          onClick={() => {
            setVisibleComposeEmail(true);
          }}
        >
          Mail
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <a
          onClick={() => {
            setVisibleQuickMessage(true);
          }}
        >
          Quick Message
        </a>
      </Menu.Item>
    </Menu>
  );

  const replyEmailCompose = (type) => {
    setVisibleComposeEmail(true);
    setApplicationTypeStatus(type);
  };

  return (
    <div className="main__body">
      <div className="organizer email">
        <div style={{ display: "flex", flexFlow: "row wrap", width: "100%" }}>
          <div className="emailList">
            <EmailList
              Reference_SearchKeyword={Reference_SearchKeyword}
              preSelectEmailCard={preSelectEmailCard}
              setUnRead={setUnRead}
              isFavorite={isFavorite}
              read={read}
              setPreSelectEmailCard={setPreSelectEmailCard}
              toggleMailfalse={toggleMailCollpaseFalse}
              toggleMailTrue={toggleMailCollpaseTrue}
              mailMessageCenterPagination={mailMessageCenterPagination}
              setCurrentSelectedMailMessage={setCurrentSelectedMailMessage}
              currentSelectedMailMessage={currentSelectedMailMessage}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              sortByEmail={sortByEmail}
              setSortByEmail={setSortByEmail}
              setchatBox={setchatBox}
              hideMailListHeaderAndTabs={hideMailListHeaderAndTabs}
              setIsFavorite={setIsFavorite}
              mailMessageCenterPaginationLoading={
                mailMessageCenterPaginationLoading
              }
              mailScrollLoader={mailScrollLoader}
              infiniteScrollLoading={infiniteScrollLoading}
              getData={getData}
              setShowSideMenuEmail={setShowSideMenuEmail}
              showSideMenuEmail={showSideMenuEmail}
              pageSize={pageSize}
              setVisibleComposeEmail={setVisibleComposeEmail}
              setVisibleQuickMessage={setVisibleQuickMessage}
              setMessageCenterTypeName={setMessageCenterTypeName}
              messageCenterTypeName={messageCenterTypeName}
              appendUrlState={appendUrlState}
              emailType={emailType}
              allParticipants={allParticipants}
              openAutoReferencing={openAutoReferencing}
              setOpenAutoReferencing={setOpenAutoReferencing}
              predefinedData={predefinedData}
              setPredefinedData={setPredefinedData}
              setAdvancedFiltersData={setAdvancedFiltersData}
              setCircularId={setCircularId}
              referenceTagsFromAutoReference={referenceTagsFromAutoReference}
              setFilteredEmails={setFilteredEmails}
              filteredEmails={filteredEmails}
              formSearch={formSearch}
              setCorporateSharedAccessAccountHolderUserId={
                setCorporateSharedAccessAccountHolderUserId
              }
              corporateSharedAccessAccountHolderUserId={
                corporateSharedAccessAccountHolderUserId
              }
              setContextMenushow={setContextMenushow}
              contextMenushow={contextMenushow}
              height={height}
              origin={origin}
              tenderSelectedRowId={tenderSelectedRowId}
            />
          </div>
          <div
            className="emailLeft"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <EmailDetails
              setMessageCenterId={setMessageCenterId}
              messageCenterId={messageCenterId}
              mailBodyGetDetailsResponse={mailBodyGetDetailsResponse}
              setCurrentSelectedMailMessage={setCurrentSelectedMailMessage}
              mailBodyGetDetailsLoading={mailBodyGetDetailsLoading}
              LogoImage={LogoImage}
              height={height}
              setChatHeight={setChatHeight}
              chatHeight={chatHeight}
              setShowSideMenuEmail={setShowSideMenuEmail}
              toggleMail={state.toggleMail}
              currentSelectedMailMessage={currentSelectedMailMessage}
              mailMessageParticipantTypeListing={
                mailMessageParticipantTypeListing
              }
              mailMessageParticipationStatusListing={
                mailMessageParticipationStatusListing
              }
              mailMessageCenterPaginationLoading={
                mailMessageCenterPaginationLoading
              }
              allParticipants={allParticipants}
              replyEmailCompose={replyEmailCompose}
              messageCenterTypeName={messageCenterTypeName}
              setchatBox={setchatBox}
              chatBox={chatBox}
              mailMessageCenterPagination={mailMessageCenterPagination}
              setPreSelectEmailCard={setPreSelectEmailCard}
              openAutoReferencing={openAutoReferencing}
              setOpenAutoReferencing={setOpenAutoReferencing}
              predefinedData={predefinedData}
              setPredefinedData={setPredefinedData}
              // getData={getData}
              // pageSize={pageSize}
              referenceTagsFromAutoReference={referenceTagsFromAutoReference}
              filteredEmails={filteredEmails}
              corporateSharedAccessAccountHolderUserId={
                corporateSharedAccessAccountHolderUserId
              }
              setBulkStatusLoading={setBulkStatusLoading}
              bulkStatusLoading={bulkStatusLoading}
              setContextMenushow={setContextMenushow}
              contextMenushow={contextMenushow}
              origin={origin}
              emailType={emailType}
            />
          </div>
        </div>

        {!state.collapsed && !hideMailListHeaderAndTabs && (
          <div className="email__icon">
            <a href="#" className="email__icon--wrap">
              <Badge count={2}>
                <Avatar src={mailIcon} shape="square" />
              </Badge>
            </a>
            <a href="#" className="email__icon--wrap">
              <Badge count={1}>
                <Avatar src={mailIcon} shape="square" />
              </Badge>
            </a>
          </div>
        )}
      </div>
      {/* <QuickMessageDrawer
                visible={visibleQuickMessage}
                onCloseQuickMessageDrawer={onCloseQuickMessageDrawer}
                organizerEventPriorityListing={organizerEventPriorityListing}
                mailMessageParticipantTypeListing={mailMessageParticipantTypeListing}
                allParticipants={allParticipants}
                mailMessageParticipationStatusListing={mailMessageParticipationStatusListing}
            /> */}
      {/* <ComposeEmailDrawer
                visible={visibleComposeEmail}
                onCloseComposeEmail={onCloseComposeEmail}
                organizerEventPriorityListing={organizerEventPriorityListing}
                mailMessageParticipantTypeListing={mailMessageParticipantTypeListing}
                allParticipants={participantTypeEmailAddressesListing}
                currentSelectedMailMessage={currentSelectedMailMessage}
                mailMessageParticipationStatusListing={mailMessageParticipationStatusListing}
                applicationTypeStatus={applicationTypeStatus}
                setApplicationTypeStatus={setApplicationTypeStatus}
            /> */}
    </div>
  );
};

export default EmailListDetails;
