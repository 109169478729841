import { Col, Form, Input, Row } from "antd";
import React from "react";

const TaDaMoreDetail = ({ supervisorStatus }) => {
  return (
    <>
      {supervisorStatus === "Supervisor Approved" && (
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <div className="loan_title">Approve Amount: </div>
          </Col>
          <Col span={16}>
            <div className="loan_input">
              <Form.Item
                name="totalAdvanceApprovedAmount"
                className="form_group"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input type="number" style={{ width: 200 }} />
              </Form.Item>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default TaDaMoreDetail;
