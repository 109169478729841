// import { Item } from "react-bootstrap/lib/Breadcrumb";
import { CALENDAR_EVENTS_TYPES } from "../../types";
import { ORGANIZER_TYPES } from "../../types";

const INITIAL_STATE = {
  allCalendarEvents: [],
  calendarEventsLoading: false,

  calenderFilters: [],
  calendarFiltersLoading: false,

  calendarEventUpdateResp: [],
  calendarEventUpdateRespLoading: false,

  // guestEventStatuses: [],
  // guestEventStatusesLoading: false,
  addEventResponse: [],
  addEventResponseLoading: false,

  // calendar event pagination

  calendarEventPaginationResp: [],
  calendarEventPaginationLoading: false,

  englishToNepaliDateConversionResponse: [],
};

export default function calendarEventsReducer(state = INITIAL_STATE, action) {
  // console.log("actioncalendar", state);
  switch (action.type) {
    // Calendar events
    case CALENDAR_EVENTS_TYPES.CALENDAR_EVENTS_LISTS_INIT:
      return {
        ...state,
        allCalendarEvents: [],
        calendarEventsLoading: true,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_EVENTS_LISTS_SUCCESS:
      return {
        ...state,
        allCalendarEvents: action.payload,
        calendarEventsLoading: false,
        calendarEventsLoaded: true,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_EVENTS_LISTS_FINISH:
      return {
        ...state,
        calendarEventsLoading: false,
      };
    //Appointments list
    case CALENDAR_EVENTS_TYPES.CALENDAR_PRIVATE_EVENTS_LISTS_INIT:
      return {
        ...state,
        appointmentResponse: [],
        appointmentResponseLoading: true,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_PRIVATE_EVENTS_LISTS_SUCCESS:
      return {
        ...state,
        appointmentResponse: action.payload,
        appointmentResponseLoading: false,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_PRIVATE_EVENTS_LISTS_FINISH:
      return {
        ...state,
        appointmentResponseLoading: false,
      };
    //Public Tasks
    case CALENDAR_EVENTS_TYPES.CALENDAR_PUBLIC_EVENTS_LISTS_INIT:
      return {
        ...state,
        publicEventsResponse: [],
        publicEventsResponseLoading: true,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_PUBLIC_EVENTS_LISTS_SUCCESS:
      return {
        ...state,
        publicEventsResponse: action.payload,
        publicEventsResponseLoading: false,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_PUBLIC_EVENTS_LISTS_FINISH:
      return {
        ...state,
        publicEventsResponseLoading: false,
      };
    // Calendar event pagination
    case CALENDAR_EVENTS_TYPES.CALENDAR_EVENT_PAGINATION_INIT:
      return {
        ...state,
        calendarEventPaginationResp: [],
        calendarEventPaginationLoading: true,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_EVENT_PAGINATION_SUCCESS:
      return {
        ...state,
        calendarEventPaginationResp: action.payload,
        calendarEventPaginationLoading: false,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_EVENT_PAGINATION_FINISH:
      return {
        ...state,
        calendarEventPaginationLoading: false,
      };

    // Calendar filter events
    case CALENDAR_EVENTS_TYPES.CALENDAR_FILTERS_INIT:
      return {
        ...state,
        calenderFilters: [],
        calendarFiltersLoading: true,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_FILTERS_SUCCESS:
      return {
        ...state,
        calenderFilters: action.payload,
        calendarFiltersLoading: false,
      };
    case CALENDAR_EVENTS_TYPES.CALENDAR_FILTERS_FINISH:
      return {
        ...state,
        calendarEventsLoading: false,
      };

    // Calendar events update
    case CALENDAR_EVENTS_TYPES.UPDATE_CALENDAR_EVENT_INIT:
      return {
        ...state,
        calendarEventUpdateResp: [],
        calendarEventUpdateRespLoading: true,
      };
    case CALENDAR_EVENTS_TYPES.UPDATE_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        calendarEventUpdateResp: action.payload,
        calendarEventUpdateRespLoading: false,
      };
    case CALENDAR_EVENTS_TYPES.UPDATE_CALENDAR_EVENT_FINISH:
      return {
        ...state,
        calendarEventUpdateRespLoading: false,
      };
    case CALENDAR_EVENTS_TYPES.ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER_INIT:
      return {
        ...state,
        englishToNepaliDateConversionResponse: [],
        englishToNepaliDateConversionResponseLoading: true,
      };

    case CALENDAR_EVENTS_TYPES.ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER_SUCCESS:
      return {
        ...state,
        englishToNepaliDateConversionResponse: action.payload,
        englishToNepaliDateConversionResponseLoading: false,
      };

    case CALENDAR_EVENTS_TYPES.ENGLISH_TO_NEPALI_DATE_RANGE_CONVERTER_FINISH:
      return {
        ...state,
        englishToNepaliDateConversionResponse: [],
        englishToNepaliDateConversionResponseLoading: false,
      };
    // case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_ADD_SUCCESS:
    //     console.log("actionpayloads",action.payload)
    //     console.log("actionpayloads",state.allCalendarEvents)
    //   return {
    //     ...state,
    //     allCalendarEvents: [...state.allCalendarEvents, action.payload],
    //   };

    default:
      return { ...state };
  }
}
