import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { actionForHolidaySampleData } from "services/redux/holiday/action";
import { saveBase64 } from "utils/saveBase64";

const HolidayWithSampleButton = () => {
  const { holidaySampleResponse, holidaySampleLoading } = useSelector((state) => state.holiday);

  const dispatch = useDispatch();

  const [isDownloadStatus, setIsDownloadStatus] = useState(false);

  const handleDownload = () => {
    setIsDownloadStatus(true);
    dispatch(actionForHolidaySampleData());
  };

  useEffect(() => {
    if (holidaySampleResponse || !holidaySampleLoading) {
      try {
        if (isDownloadStatus) {
          let props = {
            base64Data: holidaySampleResponse?.data,
            fileName: holidaySampleResponse?.fileName,
            fileType: holidaySampleResponse?.fileExtension
          }
          saveBase64(props)
        }
      } catch (err) {
        console.log("error: ", err)
      } finally {
        setIsDownloadStatus(false);
      }
    }
  }, [holidaySampleResponse])


  return (
    <Button onClick={() => {
      handleDownload();
    }}>
      <DownloadOutlined /> Holiday Sample
    </Button>
  );
};

export default HolidayWithSampleButton;
