import React from "react";
import EmailListDetails from "../../../../../Email/EmailListDetails";
// import Email from "../../../../../../components/email";

const Communication = ({
  setChatHeight,
  chatHeight,
  height,
  searchEmailTender,
  getDetails,
  selectedTenderRowId,
  origin,
}) => {
  return (
    <div className="sidebars email business-emails">
      {getDetails?.referenceCode && (
        <div
          className="business-header"
          style={{ marginTop: "5px", width: "100%", zIndex: "1" }}
        >
          <div className="business-header-title">
            <div
              className="profiledetails__rowedit--data"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              title={getDetails?.title}
            >
              <span
                className="referenceDetails--subtitle"
                style={{ color: "blue" }}
              >
                {selectedTenderRowId} [{getDetails?.title}]
              </span>
            </div>
          </div>
        </div>
      )}

      {selectedTenderRowId && (
        <EmailListDetails
          // searchEmailTender={searchEmailTender}
          setChatHeight={setChatHeight}
          chatHeight={chatHeight}
          height={height}
          emailType="Business"
          Reference_SearchKeyword={selectedTenderRowId}
          origin={origin}
          tenderSelectedRowId={selectedTenderRowId}
        />
      )}
    </div>
  );
};

export default Communication;
