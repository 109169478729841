import React, { useState, } from "react";
import { Col, Row } from "antd";
import { StatusEventListing } from "./ListingAddUpdateTable";
import ListingAddUpdateDrawer from "./ListingAddUpdateDrawer";

const StatusListingAddUpdate = () => {

  const [onAddUpdateDrawerVisibility, setOnAddUpdateDrawerVisibility] = useState(false);
  const [isUpdateStatusListing, setIsUpdateStatusListing] = useState(false);

  const showAddUpdateListingDrawer = (e, record) => {

    setOnAddUpdateDrawerVisibility(false);
    if (record) {

      setIsUpdateStatusListing(record);
    }
    else {

      setIsUpdateStatusListing(false);
    }
    setOnAddUpdateDrawerVisibility(true);
  };

  const onAddUpdatedrawerClose = () => {
    setIsUpdateStatusListing('');
    setOnAddUpdateDrawerVisibility(false);
  }
  return (
    <div className='layout'>
      <div className="organizer events">
         <div className="all-header-wrapper">
              <div className="meeting__flex">
                    <h3 className="header__all">Status Event Listing</h3>
                    <div className="organizerHoliday__middle--wrap">
                      {/* <Button
                        className='btn-primary'
                        onClick={() => {onReset();}}
                        onClick={showAddUpdateCountryDrawer}
                        >
                        <PlusOutlined /> New Status Event
                      </Button> */}
                    </div>
              </div>
          </div>
        <StatusEventListing
          showAddUpdateListingDrawer={showAddUpdateListingDrawer}
        />
        <ListingAddUpdateDrawer
          onAddUpdatedrawerClose={onAddUpdatedrawerClose}
          drawerVisibility={onAddUpdateDrawerVisibility}
          isUpdateStatusListing={isUpdateStatusListing}
        />
      </div>
    </div>
  );
};

export default StatusListingAddUpdate;;
