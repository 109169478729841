import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";

export class DynamicFileLabelTypeService {
  getDynamicFileLabelTypeUploaderDetail(type) {
    let url = `${appBaseUrl}/documentRequest/dynamicFolderLabelTypeUploaderDtos?fileLabelTypeGroupName=${type.type}&reference=${type.referenceCode}`;
    let data = getService(url);
    return data;
  }

  // addGoalEvent(body) {
  //   let url = `${appBaseUrl}/organizerEvent/goalEventAdd`;
  //   let data = postService(url, body);
  //   return data;
  // }

  // updateGoalEvent(body, id) {
  //   let url = `${appBaseUrl}/organizerEvent/goalEventUpdate/${id}`;
  //   let data = postService(url, body, 'put');
  //   return data;
  // }
}
