import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Upload, Modal, message } from "antd";
import ImgCrop from "antd-img-crop";
import { uploadFileWithNoProgress } from "../../services/remanentCalls";
import { appBaseUrl, cqBaseUrl } from "../../utils/config";
// const fileLabelTypeId = "34ffc957-b591-4ca7-b2d1-8dbae3225769";
const ContactImageUploader = ({
  name,
  deleteImageById,
  isImageRemoved,
  isMultipleFileAllowed,
  id,
  uploadedFileDtos,
  callback,
  form,
  index,
  // fileList,
  // setFileList
}) => {
  const { token } = useSelector((state) => state.auth);
  // console.log(form.getFieldValue().uploadedFileDtos  &&  form.getFieldValue().uploadedFileDtos[index])

  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });
  const [fileList, setFileList] = useState([
    // {
    //     uid: '-1',
    //     name: 'image.png',
    //     status: 'done',
    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ]);

  useEffect(() => {
    if (
      form.getFieldValue().uploadedFileDtos &&
      form.getFieldValue().uploadedFileDtos.length &&
      form.getFieldValue().uploadedFileDtos[index]?.displayFileName
    ) {
      //  form.getFieldValue().uploadedFileDtos.map((field, i) => {
      //   if(field.isActive){
      // console.log({field})
      setFileList([
        // ...fileList,
        {
          uid: index,
          name: form.getFieldValue().uploadedFileDtos[index]?.displayFileName,
          status: "done",
          url: `${cqBaseUrl}/${
            form.getFieldValue().uploadedFileDtos[index]?.viewFileURL
          }${token}`,
        },
      ]);
      // }
      //  })
      // console.log(`${cqBaseUrl}/${viewFileURL}${token}`)
    }
  }, [form.getFieldValue().uploadedFileDtos]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleCancel = () => setPreview({ ...preview, previewVisible: false });
  const onChange = ({ file, fileList: newFileList, event }) => {
    // let newUrl

    if (file.status === "done") {
      // console.log({newFileList})
      // let newUrl = file?.response.data[0].viewFileURL
      // console.log({newUrl})
      // setFileList(newFileList);
    }

    // setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isPdf = file.type === "application/pdf";
    if (!isJpgOrPng && !isPdf) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isOverSize = file.size > 2480 * 3230;
    if (isOverSize) {
      message.error("Image must smaller than 2480 * 32308!");
      return false;
    }
    return true;
  };

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFileWithNoProgress({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
      setFileList([]);
    });
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      callback(alteredResponse);
      onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  return (
    <>
      <Upload
        //  appBaseUrl + ``, body

        // action={`/product/images/${user?._id}`}
        action={`${appBaseUrl}/file/uploadFile/${id}`}
        customRequest={uploadPhotos}
        listType={
          name !== "Profile" && name !== "ProfilePicture" ? "" : "picture-card"
        }
        multiple={false}
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        onRemove={async (file) => {
          let updatedInfo = form.getFieldValue().uploadedFileDtos;
          delete updatedInfo[index];
          // updatedInfo[index].isActive = false;

          setFileList([]);
          return false;
          // return await deleteImageById(user?._id, file.response.data[0]._id)
          // return isImageRemoved
        }}
        progress={{
          strokeColor: {
            "0%": "#108ee9",
            "100%": "#87d068",
          },
          strokeWidth: 3,
          format: (percent) => {
            return `${percent}%`;
          },
        }}
        name="uploadedFiles"
      >
        {!isMultipleFileAllowed ? fileList?.length < 1 && "Upload" : "+Upload"}
      </Upload>
      <Modal
        visible={preview.previewVisible}
        title={preview.previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {name === "Profile" || name === "ProfilePicture" ? (
          <img
            alt="alt"
            style={{ width: "100%" }}
            src={preview.previewImage}
            // onError={() => {
            //   let altmodalDiv = document.getElementById("altmodal");
            //   altmodalDiv.innerHTML = `
            //     <a href="${cqBaseUrl}/${fileList[0].response.viewFileURL}${token} download">Download</a>
            //     `;
            // }}
          />
        ) : (
          <>
            <a
              href={`${cqBaseUrl}/${fileList[0]?.viewFileURL}${token} download`}
            >
              Text
            </a>
          </>
          // <a href={`${cqBaseUrl}/${fileList[0].response.viewFileURL}${token} download`}>Download</a>
        )}
      </Modal>
    </>
  );
};

ContactImageUploader.propTypes = {
  user: PropTypes.object,
  // uploadFile:PropTypes.func,
  // isImageRemoved: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  // isImageRemoved: state.product.isImageRemoved
});

const mapDispatchToProps = {
  // uploadFile,
  // deleteImageById
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactImageUploader);
