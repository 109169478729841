import {
  Form,
  Row,
  Col,
  Input,
  Button,
  InputNumber,
  DatePicker,
  Checkbox,
} from "antd";
import { useDispatch } from "react-redux";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { actionForCreateUpdateApplicantsJobDetails } from "services/redux/hrSystem/action";
import ButtonForForm from "./buttons";

export default function JobDetails({
  setOpenApplicantDrawer,
  setSelectedSingleApplicantData,
  formApplicantsBasic,
  formApplicantEdu,
  formApplicantJob,
  formApplicantReview,
  selectedSingleApplicantDAta,
  setActiveTabIndexofAppicant,
  jobTitleId,
  selectedTemporarilyApplicantId,
}) {
  const dispatch = useDispatch();

  const onFinishJobDetails = (values) => {
    let applicantId = selectedSingleApplicantDAta?.id
      ? selectedSingleApplicantDAta?.id
      : selectedTemporarilyApplicantId;
    let apiData = values?.previousJobDetails?.map((x) => {
      return {
        ...x,
        applicantId: applicantId,
      };
    });
    dispatch(
      actionForCreateUpdateApplicantsJobDetails(
        apiData,
        applicantId,
        jobTitleId,
        "add"
      )
    );
  };

  return (
    <>
      <Form
        form={formApplicantJob}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinishJobDetails}
      >
        <Form.List name="previousJobDetails">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} gutter={[10, 0]}>
                  <Col span={24}>
                    {index >= 0 && (
                      <Button
                        style={{
                          float: "right",
                          // marginTop: "-12px",
                        }}
                        icon={<MinusCircleOutlined style={{ color: "red" }} />}
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </Col>

                  <Col span={1}>{index + 1}</Col>

                  <Col span={11}>
                    <Form.Item
                      key={field.key}
                      label="Company Name"
                      name={[field.name, "companyName"]}
                      fieldKey={[field.fieldKey, "companyName"]}
                      rules={[
                        { required: true, message: "Company Name is required" },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <Input placeholder="Enter Company Name" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Company Email Address"
                      name={[field.name, `companyEmailAddress`]}
                      fieldKey={[field.fieldKey, "companyEmailAddress"]}
                      rules={[
                        {
                          required: true,
                          message: "companyEmailAddress  is required",
                        },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <Input placeholder="Enter Company Email Address" />
                    </Form.Item>
                  </Col>

                  <Col offset={1} span={11}>
                    <Form.Item
                      label="Company Phone Number"
                      name={[field.name, `companyPhoneNumber`]}
                      fieldKey={[field.fieldKey, "companyPhoneNumber"]}
                      rules={[
                        {
                          required: true,
                          message: "Company PhoneNumber is required",
                        },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <Input placeholder="Enter Company PhoneNumber" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      // key={field.key}
                      label="Company Location"
                      name={[field.name, "companyLocation"]}
                      fieldKey={[field.fieldKey, "companyLocation"]}
                      rules={[
                        {
                          required: true,
                          message: "Company Location is required",
                        },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <Input placeholder="Enter company location" />
                    </Form.Item>
                  </Col>

                  <Col offset={1} span={11}>
                    <Form.Item
                      // key={field.key}
                      label="Job Position"
                      name={[field.name, "jobPosition"]}
                      fieldKey={[field.fieldKey, "jobPosition"]}
                      rules={[
                        {
                          required: true,
                          message: "Job Position is required",
                        },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <Input placeholder="Enter Job Postion" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Joined Date"
                      name={[field.name, `joingDate`]}
                      fieldKey={[field.fieldKey, "joingDate"]}
                      rules={[
                        { required: true, message: "Joined date is required" },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <DatePicker placeholder="Select date" />
                    </Form.Item>
                  </Col>

                  <Col offset={1} span={11}>
                    <Form.Item
                      label="Resignition Date"
                      name={[field.name, `resignDate`]}
                      fieldKey={[field.fieldKey, "resignDate"]}
                      rules={[
                        {
                          required: false,
                          message: "Resignition Date is required",
                        },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <DatePicker placeholder="Select date" />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Button
                style={{ marginBottom: "16px" }}
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusCircleOutlined />}
              >
                Add Job
              </Button>
            </>
          )}
        </Form.List>

        <ButtonForForm
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          formApplicantsBasic={formApplicantsBasic}
          formApplicantEdu={formApplicantEdu}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
        />
      </Form>
    </>
  );
}
