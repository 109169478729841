import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { cqBaseUrl } from "utils/config";
import {
  EyeOutlined,
  DownloadOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { textCenterDot, fileDownload } from "utils/commonFunc";
import DivTable from "components/table";
import { documentRequestPagination } from "services/redux/document/action";
import useInfiniteScroll from "utils/useInfinityScroll";
import { isEmpty } from "lodash";
import pdfViewer from "components/Documents/ViewDocument";
import { Tooltip } from "antd";

const DocumentListing = ({
  setDocDetail,
  docDetailId,
  referenceId,
  referenceType,
  token,
  showModal,
  handleActiveTable,
  setHideDrawer,
}) => {
  const dispatch = useDispatch();
  const {
    documentRequestListPaginationLoading,
    documentRequestListPagination,
  } = useSelector((state) => state.documentRequestUpload);
  console.log("documentRequestListPagination", documentRequestListPagination);
  const {
    data,
    totalData,
    skipCount,
    setSkipCount,
    setPaginationSize,
    setResponseData,
  } = useInfiniteScroll("document-table-scroll");

  const pageSize = 20;
  //pagination action
  const fetchData = (
    MaxResultCount = pageSize,
    skipCount = 0,
    referenceId = "",
    referenceType = "",
    searchKeyword,
    forPagination = false
  ) => {
    dispatch(
      documentRequestPagination(
        {
          MaxResultCount: MaxResultCount,
          skipCount: skipCount,
          id: referenceId,
          type: referenceType ?? "O",
          searchKeyword: searchKeyword,
        },
        forPagination
      )
    );
  };

  useEffect(() => {
    setPaginationSize(pageSize);
  }, []);

  useEffect(() => {
    if (data.length != totalData && skipCount > 0 && data.length <= totalData) {
      fetchData(pageSize, skipCount, referenceId, referenceType, null, true);
    }
  }, [skipCount, referenceId]);

  //filter with type
  useEffect(() => {
    setSkipCount(0);
    fetchData(pageSize, 0, referenceId, referenceType, null, false);
  }, [referenceId]);

  //set response data
  useEffect(() => {
    setResponseData(documentRequestListPagination);
  }, [documentRequestListPagination, setResponseData]);

  useEffect(() => {
    const getDocDetail = documentRequestListPagination.items?.filter((item) => {
      if (item.id === docDetailId) return item;
    });
    setDocDetail(getDocDetail);
  }, [docDetailId]);

  const columns = [
    {
      title: "#Ref",
      key: "referenceCode",
      render: (row) => {
        return <>{row.referenceCode}</>;
      },
    },
    {
      key: "Type",
      title: "Type",
      render: (row) => {
        return <>{row.fileLabelType}</>;
      },
    },
    {
      title: "Uploaded Date",
      key: "creationTime",
      render: (row) => {
        return <>{moment(row?.creationTime).format("YYYY/MM/DD")}</>;
      },
    },
    {
      title: "Sector",
      key: "sectorNames",
      render: (row) => {
        return <>{row?.sectorNames?.join(", ")}</>;
      },
    },
    {
      key: "",
      title: "File Name",
      render: (row) => {
        return (
          <Tooltip title={row?.fileDisplayName}>
            <div
              style={{
                maxWidth: "150px", // Adjust the maximum width as needed
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {row?.fileDisplayName}
            </div>
          </Tooltip>
        );
      },
    },
    {
      key: "File",
      title: "Action",
      render: (row) => {
        return (
          <div className="file-action-btn-wrapper">
            {!isEmpty(row?.viewFileURL) && (
              <>
                <div className="action-btn-item">
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      fileDownload(
                        cqBaseUrl + "/" + row?.viewFileURL + token,
                        row?.fileDisplayName
                      );
                    }}
                  >
                    <DownloadOutlined />
                  </button>
                </div>
                <div className="action-btn-item">
                  <ShareAltOutlined onClick={showModal} />
                </div>

                {row?.fileDisplayName.slice(-3) === "pdf" ||
                row?.fileDisplayName.slice(-3) === "jpg" ||
                row?.fileDisplayName.slice(-3) === "JPG" ? (
                  <div className="action-btn-item">
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setHideDrawer(true);
                        pdfViewer(`${cqBaseUrl}/${row?.viewFileURL}${token}`);
                      }}
                    >
                      <EyeOutlined />
                    </button>
                  </div>
                ) : null}
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DivTable
        columns={columns}
        rows={data}
        height={600}
        scrollTarget="document-table-scroll"
        totalLength={totalData}
        loading={documentRequestListPaginationLoading}
        handleEvent={handleActiveTable}
        activeKey={docDetailId}
      />
    </>
  );
};

export default DocumentListing;
