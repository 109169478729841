import React, { useEffect, useState } from "react";
import { TenderPaginationModal } from "../../OfficeResource/Reference/TenderPagination";
import { ProjectPaginationModal } from "../../OfficeResource/Reference/ProjectPagination";
import { ReferenceOthersPaginationModal } from "../../OfficeResource/Reference/OthersPagination";
import { SecondaryRoot2PaginationModal } from "../../OfficeResource/Reference/SecondaryRoot2Pagination";
import { MailReferenceSearchPagination } from "./MailReference";
import {
  Collapse,
  Form,
  Modal,
  Input,
  Tag,
  Tooltip,
  Skeleton,
  Button,
  Checkbox,
} from "antd";
import { Link, Router } from "react-router-dom";
import {
  PlusOutlined,
  StarOutlined,
  StarFilled,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { getTenderBasicSearchPagination } from "services/redux/Reference/Tender/action";
import {
  getSecondaryRootBasicSearchPagination,
  referenceSecondaryAdd,
} from "services/redux/Reference/Secondary/action";
import { getOthersSearchPagination } from "services/redux/Reference/Others/action";
import { getProjectSearchPagination } from "services/redux/Reference/Project/action";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  mailReferenceTagUpdate,
  mailReferenceTagMultipleUpdate,
} from "services/redux/email/mailMessageCenter/action";
import { getReferenceListing } from "services/redux/Reference/action";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { multipleEmailSelectionAction } from "services/redux/email/multipleEmailSelection/action";

// const minimize = "assets/images/icons/minimize.png";
// const maximize = "assets/images/icons/maximize.png";

export const BusinessModal = ({
  emailBusinessModal,
  showBusinessModal,
  setShowBusinessModal,
  instanceMessageCenterPagination,
  mailReferenceListingResponse,
  currentSelectedMailMessage,
  referenceTags,
  setBusinessModal,
  setMailReferenceTags,
  composeIfReferenceValue,
  multipleSelectedEmail,
  mailMessageCenterPagination,
  setComposeIfReference,
}) => {
  const [searchKeyword, setSearchKeyword] = useState();

  const [inputSearchValue, setInputSearchValue] = useState();

  //rootlevel2Search
  let searchKeywordForRootlevel2Format = inputSearchValue?.split("-");
  let searchKeywordForRootlevel2FormatData = "";

  if (searchKeywordForRootlevel2Format?.length === 2) {
    searchKeywordForRootlevel2FormatData = `${
      searchKeywordForRootlevel2Format?.[0]
    }-${searchKeywordForRootlevel2Format?.[1].trim()}`;
  }

  // ...............................................................

  const [searchKeywordRootLevel2, setSearchKeywordForRootLevel2] = useState();

  const [inputKeywordRootLevel2, setInputSearchValueForRootLevel2] = useState();
  const [starColor, setStarColor] = useState("#c8c8c8");
  const [tenderData, setTenderData] = useState([]);
  const [secondaryRootLevel2Data, setSecondaryRootLevel2Data] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [OthersData, setOthersData] = useState([]);
  const [changeIcon, setChangeIcon] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  // console.log("selectedRows", selectedRows);
  const [showClosable, setShowClosable] = useState(-1);
  const [splicedData, setSplicedData] = useState([]);
  const [filterSplice, setFilderSplice] = useState([]);
  const [mailSearchReference, setMailSearchReference] = useState([]);
  const [test, setTest1] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageProject, setCurrentPageProject] = useState(1);
  const [currentPageOthers, setCurrentPageOthers] = useState(1);
  //
  const [loading, setLoading] = useState(false);

  const { tenderBasicPaginationResponse, tenderBasicPaginationLoading } =
    useSelector((state) => state.tender);

  const {
    secondaryBasicPaginationResponse,
    secondaryBasicPaginationLoading,
    referenceSecondaryAddIsSuccess,
  } = useSelector((state) => state.secondaryRoot2);

  // console.log("secondaryRootLevel2Data", secondaryRootLevel2Data);

  const { projectPaginationResponse, projectPaginationLoading } = useSelector(
    (state) => state.project
  );
  const { mailReferenceUpdateRespoonseLoading } = useSelector(
    (state) => state.mailMessageCenter
  );
  const { multipleReferenceUpdateLoading } = useSelector(
    (state) => state.multipleSelectedEmail
  );

  const {
    referenceOthersPaginationResponse,
    referenceOthersPaginationLoading,
  } = useSelector((state) => state.referenceOthers);
  const { referenceListingResponse } = useSelector((state) => state.reference);
  const dispatch = useDispatch();
  const pageSize = 10;
  const history = useHistory();
  const searchTender = tenderData
    .filter((fav) => fav.isFavorite === true)
    .map((filteredfav) => {
      return filteredfav;
    });

  // const [tenderCount , setTenderCount] = useState([0])
  // useEffect(()=>{
  //   console.log(tenderBasicPaginationResponse?.totalCount,"search is pressed")
  //   // setTenderCount(tenderBasicPaginationResponse?.totalCount)
  // },[searchKeyword,BusinessModal])

  useEffect(() => {
    if (!isEmpty(tenderBasicPaginationResponse)) {
      setTenderData(tenderBasicPaginationResponse?.items);
    }
  }, [tenderBasicPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(secondaryBasicPaginationResponse)) {
      setSecondaryRootLevel2Data(secondaryBasicPaginationResponse?.items);
    }
  }, [secondaryBasicPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(projectPaginationResponse)) {
      setProjectData(projectPaginationResponse?.items);
    }
  }, [projectPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(referenceOthersPaginationResponse)) {
      setOthersData(referenceOthersPaginationResponse?.items);
    }
  }, [referenceOthersPaginationResponse]);

  useEffect(() => {
    if (isEmpty(tenderBasicPaginationResponse?.items)) return;
    let newCurrentPage = tenderBasicPaginationResponse.items.length / pageSize;
    setCurrentPage(newCurrentPage);
  }, [tenderBasicPaginationResponse]);

  useEffect(() => {
    if (isEmpty(projectPaginationResponse?.items)) return;
    let newCurrentPage = projectPaginationResponse.items.length / pageSize;
    setCurrentPageProject(newCurrentPage);
  }, [projectPaginationResponse]);

  useEffect(() => {
    if (isEmpty(referenceOthersPaginationResponse?.items)) return;
    let newCurrentPage =
      referenceOthersPaginationResponse.items.length / pageSize;
    setCurrentPageOthers(newCurrentPage);
  }, [referenceOthersPaginationResponse]);

  // console.log(
  //   "tender",
  //   tenderData,
  //   "project",
  //   projectData,
  //   "other",
  //   referenceOthersPaginationResponse
  // );

  useEffect(() => {
    if (!referenceListingResponse) return;
    setMailSearchReference(referenceListingResponse?.items);
  }, [referenceListingResponse]);

  useEffect(() => {
    if (
      (searchTender.length > 0 &&
        tenderData.length > 0 &&
        searchProject.length > 0 &&
        projectData.length > 0) ||
      (searchOthers.length > 0 && OthersData.length > 0)
    ) {
      if (
        searchTender.length === tenderData.length &&
        searchProject.length === projectData.length &&
        searchOthers.length === OthersData.length
      ) {
        setStarColor("#ffd036");
      } else {
        setStarColor("#c8c8c8");
      }
    } else {
      setStarColor("#c8c8c8");
    }
  }, [searchTender, tenderData]);

  useEffect(() => {
    if (isEmpty(referenceTags)) {
      setSelectedRows([]);
    } else {
      const tenderDtos = referenceTags?.map((data) => {
        let val = "";

        val = data.referenceCode;

        return val;
      });

      setSelectedRows(tenderDtos);
    }
  }, [referenceTags]);
  useEffect(() => {
    setSelectedRows([]);
  }, [mailReferenceListingResponse]);

  useEffect(() => {
    //this caused to reload the when swicthing the tab to communication
    getData();

    // if (referenceSecondaryAddIsSuccess) {
    //   getData();
    // }

    getDataProject();
    getDataOthers();
  }, [searchKeyword, referenceSecondaryAddIsSuccess]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getTenderBasicSearchPagination(
        {
          MaxResultCount,
          SkipCount,

          SearchKeyword: searchKeyword,
        },
        loadMore
      )
    );
  };

  //for Secondary Root Pagination
  const getSecondaryData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getSecondaryRootBasicSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          RootLevel: 2,
          SearchKeyword: searchKeywordRootLevel2 ? searchKeywordRootLevel2 : "",
        },
        loadMore
      )
    );
  };

  //For ProjectPagination
  const getDataProject = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getProjectSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: searchKeyword,
        },
        loadMore
      )
    );
  };

  const getDataOthers = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getOthersSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: searchKeyword,
          ...(uniqueNames?.length > 0 && {
            RootLevel: 0,
          }),
        },
        loadMore
      )
    );
  };

  const fetchMoreData = () => {
    getData(pageSize, currentPage * pageSize, true);
    // console.log("hey this is ok");
  };

  const fetchMoreSecondaryRootLevel2Data = () => {
    getSecondaryData(pageSize, currentPageProject * pageSize, true);
  };

  const fetchMoreDataProject = () => {
    getDataProject(pageSize, currentPageProject * pageSize, true);
  };

  const fetchMoreDataOthers = () => {
    getDataProject(pageSize, currentPageOthers * pageSize, true);
  };

  const handleMouseEnter = (i) => {
    setShowClosable(i);
  };
  const handleMouseLeave = (i) => {
    setShowClosable(i);
  };
  const deselectCheckboxFunction = () => {
    mailMessageCenterPagination?.items?.map((mail, id) => {
      var x = document?.getElementById(`email-list-${mail?.id}`);
      x.checked = false;
      dispatch(
        multipleEmailSelectionAction({ checkStatus: false, data: mail })
      );
    });
  };

  // const Icon = () => {
  //   return (
  //     <>
  //       {changeIcon ? (
  //         <div
  //           onClick={() => {
  //             setChangeIcon(false);
  //           }}
  //         >
  //           <PlusOutlined />
  //         </div>
  //       ) : (
  //         <div
  //           onClick={() => {
  //             setChangeIcon(true);
  //           }}
  //         >
  //           <MinusCircleOutlined />
  //         </div>
  //       )}
  //     </>
  //   );
  // };

  const [form] = Form.useForm();

  const searchProject = projectData
    .filter((fav) => fav.isFavorite === true)
    .map((filteredfav) => {
      return filteredfav;
    });

  const filterTags = (removedTag) => {
    if (!isEmpty(splicedData)) {
      let spliceDatatest = splicedData.splice(-1);
      selectedRows.push(spliceDatatest);
    }

    let data = selectedRows?.filter((tags) => tags !== removedTag);
    setSelectedRows(data);
  };

  const searchOthers = OthersData?.filter((fav) => fav.isFavorite === true).map(
    (filteredfav) => {
      return filteredfav;
    }
  );

  const uniqueNames = selectedRows?.filter((val, id, array) => {
    return array.indexOf(val) == id;
  });

  const handleOk = async () => {
    // console.log("uniqueNames", uniqueNames);
    // setComposeIfReference(null);
    setLoading(true);
    try {
      if (!isEmpty(uniqueNames)) {
        // console.log("uniqueNames: ", uniqueNames);
        if (multipleSelectedEmail && multipleSelectedEmail.length > 0) {
          let val = [];
          let body = {
            messageCenterIds: multipleSelectedEmail?.map((x) => {
              // return x?.id;
              return x?.instanceMainMessageCenterId;
            }),
            referenceCodes: uniqueNames,
          };
          // multipleSelectedEmail?.map((item) => {
          //   let currentSelectedReference = item?.mailMessageReferenceDtos;
          //   uniqueNames.map((tenderCode) => {
          //     let findRemoveIndex = currentSelectedReference
          //       ?.map((currentCode) => currentCode.referenceCode)
          //       .indexOf(tenderCode);
          //     findRemoveIndex >= 0 &&
          //       currentSelectedReference.splice(findRemoveIndex, 1);
          //     val = [
          //       ...val,
          //       { messageCenterId: item.id, referenceCode: tenderCode },
          //     ];
          //   });
          //   currentSelectedReference &&
          //     currentSelectedReference?.map((code) => {
          //       val = [
          //         ...val,
          //         { messageCenterId: item.id, referenceCode: code.referenceCode },
          //       ];
          //     });
          // });
          document.querySelectorAll(".email-list-checkbox").forEach((item) => {
            if (item.checked) {
              return (item.checked = false);
            }
          });
          // dispatch(mailReferenceTagMultipleUpdate(val));
          await dispatch(mailReferenceTagMultipleUpdate(body));
          deselectCheckboxFunction();
        } else {
          let val = {
            tenderDtos: uniqueNames.map((tenderCode) => ({
              messageCenterId:
                !isEmpty(instanceMessageCenterPagination) &&
                instanceMessageCenterPagination?.instanceMainMessageCenterId,
              referenceCode: tenderCode,
            })),
          };
          await dispatch(
            mailReferenceTagUpdate(val.tenderDtos, currentSelectedMailMessage)
          );
          // history.push("/email-compose?applicationType=forward")
          if (composeIfReferenceValue === "replyAll") {
            window.open(`/#/email-compose?applicationType=replyall`, "_blank");
          } else if (
            !!composeIfReferenceValue &&
            composeIfReferenceValue !== "replyAll"
          ) {
            window.open(
              `/#/email-compose?applicationType=${composeIfReferenceValue}`,
              "_blank"
            );
          }
        }
      } else if (isEmpty(uniqueNames)) {
        let val = {
          tenderDtos: [
            {
              messageCenterId:
                !isEmpty(instanceMessageCenterPagination) &&
                instanceMessageCenterPagination?.id,
              referenceCode: null,
            },
          ],
        };
        dispatch(
          mailReferenceTagUpdate(val.tenderDtos, currentSelectedMailMessage)
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setShowBusinessModal(false);
      setLoading(false);
    }

    // setTenderData([]);
    // setProjectData([]);
    // setOthersData([]);
  };

  const { Panel } = Collapse;

  const handleCancel = () => {
    setShowBusinessModal(false);
    setLoading(false);
    // setInputSearchValue("")
    // setTenderData([]);
    // setProjectData([]);
    // setOthersData([]);
  };

  const onKeyDown = (e) => {
    if (!inputSearchValue) {
      if (e.code == "Backspace") {
        const tagsCopy = [...selectedRows];
        tagsCopy.pop();

        setSelectedRows(tagsCopy);
      }
    }
  };

  const renderNameOrNA = (name) => name || "0";
  // console.log(tenderBasicPaginationResponse)
  // console.log(tenderCount,"ss")
  const tenderHeader = () => {
    return (
      <div>
        {" "}
        Tender [{renderNameOrNA(tenderBasicPaginationResponse?.totalCount)}]
      </div>
    );
  };

  const projectHeader = () => {
    return (
      <div>
        {" "}
        Project [{renderNameOrNA(projectPaginationResponse?.totalCount)}]
      </div>
    );
  };

  const othersHeader = () => {
    return (
      <div>
        {" "}
        Others [{renderNameOrNA(referenceOthersPaginationResponse?.totalCount)}]
      </div>
    );
  };

  const secondaryHeader = () => {
    return (
      <div>
        {" "}
        Secondary [
        {renderNameOrNA(secondaryBasicPaginationResponse?.totalCount)}]
      </div>
    );
  };

  const inputTitle = () => {
    return (
      <div className="organizer contacts">
        <div className="contactDetails__header">
          <div className="contact__Detailsfav">
            <StarFilled
              onClick={(e) => {
                dispatch(getTenderBasicSearchPagination());
                dispatch(getProjectSearchPagination());
                dispatch(getOthersSearchPagination());
                setSearchKeyword("");
                setInputSearchValue("");
                setMailSearchReference([]);
              }}
              style={{ color: starColor }}
            />
          </div>
          <div
            className="tender-ref-header-wrapper"
            style={{
              overflowX: "scroll",
              width: "100%",
              maxWidth: "100%",
              overflowY: "hidden",
            }}
          >
            <Input.Search
              className="form__mail"
              placeholder="Search"
              size="medium"
              allowClear
              autoFocus={true}
              onKeyUp={(e) => {
                onKeyDown(e);
              }}
              value={inputSearchValue}
              onSearch={(e) => {
                setSearchKeyword(e);
              }}
              onChange={(e) => {
                setInputSearchValue(e.target.value);
              }}

              // }}
              // onClcik={(e) => setSearchKeyword(e.target.value)}
              // value={SearchKeyword}
            />
          </div>
        </div>
        {uniqueNames && uniqueNames.length > 0 && (
          <div className="email-title">
            <div className="emailList__card--refComp text-right tags">
              {uniqueNames.map((tag, i) => {
                return (
                  <div
                    className="emailList__card--ref"
                    key={i}
                    onMouseEnter={() => {
                      handleMouseEnter(i);
                    }}
                    onMouseLeave={() => {
                      handleMouseLeave(-1);
                    }}
                  >
                    <Tag
                      closable={showClosable == i ? true : false}
                      className="reference-tags"
                      key={tag}
                      onClose={() => filterTags(tag)}
                    >
                      <div>{tag}</div>
                    </Tag>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const inputRootLevel2Search = () => {
    return (
      <div className="organizer contacts">
        <div className="contactDetails__header">
          <div className="contact__Detailsfav">
            <StarFilled
              // onClick={(e) => {
              //   dispatch(getTenderBasicSearchPagination());
              //   dispatch(getProjectSearchPagination());
              //   dispatch(getOthersSearchPagination());
              //   setSearchKeyword("");
              //   setInputSearchValue("");
              //   setMailSearchReference([]);
              // }}
              style={{ color: starColor }}
            />
          </div>
          <div
            className="tender-ref-header-wrapper"
            style={{
              overflowX: "scroll",
              width: "100%",
              maxWidth: "100%",
              overflowY: "hidden",
            }}
          >
            <Input.Search
              className="form__mail"
              placeholder="Search"
              size="medium"
              allowClear
              autoFocus={true}
              onKeyUp={(e) => {
                onKeyDown(e);
              }}
              value={inputKeywordRootLevel2}
              onSearch={(e) => {
                setSearchKeywordForRootLevel2(e);
              }}
              onChange={(e) => {
                // console.log("inputSearchVAlue", inputKeywordRootLevel2);
                setInputSearchValueForRootLevel2(e.target.value);
              }}

              // }}
              // onClcik={(e) => setSearchKeyword(e.target.value)}
              // value={SearchKeyword}
            />
          </div>
        </div>
        {uniqueNames && uniqueNames.length > 0 && (
          <div className="email-title">
            <div className="emailList__card--refComp text-right tags">
              {uniqueNames.map((tag, i) => {
                return (
                  <div
                    className="emailList__card--ref"
                    key={i}
                    onMouseEnter={() => {
                      handleMouseEnter(i);
                    }}
                    onMouseLeave={() => {
                      handleMouseLeave(-1);
                    }}
                  >
                    {
                      <Tag
                        closable={showClosable == i ? true : false}
                        className="reference-tags"
                        key={tag}
                        onClose={() => filterTags(tag)}
                      >
                        <div>{tag}</div>
                      </Tag>
                    }
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };
  // console.log(OthersData,referenceOthersPaginationResponse,"data")

  useEffect(() => {
    if (referenceSecondaryAddIsSuccess === true) {
      selectedRows?.push(searchKeywordForRootlevel2FormatData);
      setSelectedRows(selectedRows);
      setSearchKeyword("");
      // setInputSearchValue("");
    }
  }, [referenceSecondaryAddIsSuccess]);

  return (
    <Modal
      className="modal popup-wrapper tender-ref-model"
      title={inputTitle()}
      visible={showBusinessModal || emailBusinessModal}
      closable={false}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={handleOk} loading={loading}>
          OK
        </Button>,
      ]}
    >
      <div className="businessmodal">
        <Collapse expandIconPosition="left" defaultActiveKey={["1", "2", "3"]}>
          <Panel header={tenderHeader()} key="1">
            {/* <Panel header={""} key="1"> */}
            {!isEmpty(tenderData) && (
              <div
                id="collapse__wrapper"
                style={{
                  height: 150,
                  overflowY: "auto",
                }}
              >
                {!isEmpty(tenderData) && (
                  <InfiniteScroll
                    dataLength={tenderBasicPaginationResponse?.items?.length}
                    //This is important field to render the next data
                    hasMore={
                      tenderBasicPaginationResponse?.totalCount >
                      tenderBasicPaginationResponse?.items.length
                    }
                    next={fetchMoreData}
                    scrollableTarget="collapse__wrapper"
                  >
                    <TenderPaginationModal
                      setInputSearchValue={setInputSearchValue}
                      tenderData={tenderData}
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                    />
                    {tenderBasicPaginationLoading && (
                      <Skeleton paragraph={{ rows: 2 }} active />
                    )}
                  </InfiniteScroll>
                )}
              </div>
            )}
          </Panel>

          <Panel header={projectHeader()} key="2">
            {!isEmpty(projectData) && (
              <div
                id="collapse__project"
                style={{
                  height: 150,
                  overflowY: "auto",
                }}
              >
                {!isEmpty(projectData) && (
                  <InfiniteScroll
                    dataLength={projectPaginationResponse?.items?.length}
                    //This is important field to render the next data
                    next={fetchMoreDataProject}
                    hasMore={
                      projectPaginationResponse?.totalCount >
                      projectPaginationResponse?.items?.length
                    }
                    scrollableTarget="collapse__project"
                  >
                    <ProjectPaginationModal
                      setInputSearchValue={setInputSearchValue}
                      projectData={projectData}
                      setSelectedRows={setSelectedRows}
                      selectedRows={selectedRows}
                    />
                    {projectPaginationLoading && (
                      <Skeleton paragraph={{ rows: 2 }} active />
                    )}
                  </InfiniteScroll>
                )}
              </div>
            )}
          </Panel>

          <Panel header={othersHeader()} key="3">
            <div
              id="collapse__other"
              style={{
                height: 150,
                overflowY: "auto",
              }}
            >
              {!isEmpty(OthersData) && (
                <InfiniteScroll
                  dataLength={referenceOthersPaginationResponse?.items?.length}
                  //This is important field to render the next data
                  hasMore={
                    referenceOthersPaginationResponse?.totalCount >
                    referenceOthersPaginationResponse?.items?.length
                  }
                  next={fetchMoreDataOthers}
                  scrollableTarget="collapse__other"
                >
                  <ReferenceOthersPaginationModal
                    setInputSearchValue={setInputSearchValue}
                    OthersData={OthersData}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                  />
                  {referenceOthersPaginationLoading && (
                    <Skeleton paragraph={{ rows: 2 }} active />
                  )}
                </InfiniteScroll>
              )}
            </div>
          </Panel>
        </Collapse>
      </div>

      {
        // tenderBasicPaginationResponse?.totalCount === 0 &&
        // projectPaginationResponse?.totalCount === 0 &&
        referenceOthersPaginationResponse?.totalCount === 0 &&
        searchKeywordForRootlevel2Format?.length === 2 &&
        !referenceSecondaryAddIsSuccess ? (
          <div style={{ marginLeft: "14px" }}>
            Do you want to create
            {
              <span style={{ color: "blue" }}>
                {" "}
                "{searchKeywordForRootlevel2FormatData}"
              </span>
            }
            ?
            <Button
              style={{
                marginLeft: "10px",
                marginBottom: "5px",
                backgroundColor: "#0096FF",
                color: "white",
              }}
              // onChange={(e) => {
              //   if (e.target.checked) {
              //     // setShowBusinessModal(false);
              //     dispatch(
              //       referenceSecondaryAdd({
              //         title: searchKeywordForRootlevel2FormatData,
              //         description: searchKeywordForRootlevel2FormatData,
              //       })
              //     );
              //   }
              // }}
              // checked={false}
              onClick={() => {
                dispatch(
                  referenceSecondaryAdd({
                    title: searchKeywordForRootlevel2FormatData,
                    description: searchKeywordForRootlevel2FormatData,
                  })
                );
              }}
            >
              Yes
            </Button>
          </div>
        ) : (
          ""
          // <>
          //   {inputRootLevel2Search()}
          //   <div className="businessmodal">
          //     <Collapse expandIconPosition="left" defaultActiveKey={["4"]}>
          //       <Panel header={secondaryHeader()} key="4">
          //         <div
          //           id="collapse__other"
          //           style={{
          //             height: 150,
          //             overflowY: "auto",
          //           }}
          //         >
          //           {!isEmpty(secondaryRootLevel2Data) && (
          //             <InfiniteScroll
          //               dataLength={
          //                 secondaryBasicPaginationResponse?.items?.length
          //               }
          //               //This is important field to render the next data
          //               next={fetchMoreSecondaryRootLevel2Data}
          //               hasMore={
          //                 secondaryBasicPaginationResponse?.totalCount >
          //                 secondaryBasicPaginationResponse?.items?.length
          //               }
          //               scrollableTarget="collapse__other"
          //             >
          //               <SecondaryRoot2PaginationModal
          //                 setInputSearchValue={setInputSearchValueForRootLevel2}
          //                 secondaryRootLevel2Data={secondaryRootLevel2Data}
          //                 setSelectedRows={setSelectedRows}
          //                 selectedRows={selectedRows}
          //               />
          //               {secondaryBasicPaginationLoading && (
          //                 <Skeleton paragraph={{ rows: 2 }} active />
          //               )}
          //             </InfiniteScroll>
          //           )}
          //         </div>
          //       </Panel>
          //     </Collapse>
          //   </div>
          // </>
        )
      }
    </Modal>
  );
};
