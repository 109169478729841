class Storage {
  setItem(key, value) {
    if (!value) return null;
    const sValue = JSON.stringify(value);
    sessionStorage.setItem(key, sValue);
    return this;
  }

  getItem(key) {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : {};
  }
}

export default new Storage();
