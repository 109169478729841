import { PRIMARY_REFERENCE_TYPES } from "services/types";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
import { PrimaryReferenceOther } from "./api";

const primaryRefrenceOther = new PrimaryReferenceOther();

export const primaryRefrenceAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(PRIMARY_REFERENCE_TYPES.PRIMARY_REFERENCE_ADD));
    const response = await primaryRefrenceOther.primaryRefrenceAdd(body);
    // console.log("responseListData",response)
    dispatch(finish(PRIMARY_REFERENCE_TYPES.PRIMARY_REFERENCE_ADD));
    if (response.isSuccess) {
      dispatch(
        success(PRIMARY_REFERENCE_TYPES.PRIMARY_REFERENCE_ADD, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
