import { GROUPCHAT_TYPES } from "services/types";

const INITIAL_STATE = {
    groupChatTitleAddResponse: null,
    groupChatTitleAddLoading: false,

    groupChatTitlePaginationResp: null,
    groupChatTitlePaginationLoading: false,

    groupChatTitleDetailsResp: null,
    groupChatTitleDetailsRespLoading: false,

    updateGroupTitleResp: null,
    updateGroupTitleLoading: false,

    paginationGroupMessageResp: null,
    paginationGroupMessageLoading: false,

    addGroupMessageResp: null,
    addGroupMessageLoading: false,

    updateGroupMailReadResp: null,
    updateGroupMailReadLoading: false,

    getCountGroupMailResp: null,
    getCountGroupMailLoading: false,
}

export default function groupChatReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GROUPCHAT_TYPES.CREATE_GROUPCHAT_TITLE_INIT:
            return {
                ...state,
                groupChatTitleAddResponse: null,
                groupChatTitleAddLoading: true,
            }
        case GROUPCHAT_TYPES.CREATE_GROUPCHAT_TITLE_SUCCESS:
            return {
                ...state,
                groupChatTitleAddResponse: action.payload,
                groupChatTitleAddLoading: false,
                groupChatTitlePaginationResp: {
                    items: [
                        action.payload,
                        ...state.groupChatTitlePaginationResp?.items,
                    ],
                    totalCount: state.groupChatTitlePaginationResp?.totalCount
                }
            }

        case GROUPCHAT_TYPES.CREATE_GROUPCHAT_TITLE_FINISH:
            return {
                ...state,
                groupChatTitleAddLoading: false,
            }
        
        case GROUPCHAT_TYPES.GET_GROUPCHAT_TITLE_PAGINATION_INIT:
            return {
                ...state,
                groupChatTitlePaginationResp: null,
                groupChatTitlePaginationLoading: true,
            }

        case GROUPCHAT_TYPES.GET_GROUPCHAT_TITLE_PAGINATION_SUCCESS:
            return {
                ...state,
                groupChatTitlePaginationResp: action.payload,
                groupChatTitlePaginationLoading: false,
            }
        
        case GROUPCHAT_TYPES.GET_GROUPCHAT_TITLE_PAGINATION_MORE:
            return {
                ...state,
            }
        
        case GROUPCHAT_TYPES.GET_GROUPCHAT_TITLE_PAGINATION_MORE_SUCCESS:
            // console.log("REDUCER: state.getBlogMsgPaginationResp: ", state?.getBlogMsgPaginationResp);
            // console.log("REDUCER: action.payload: ", action.payload);
            return {
                ...state,
            }
            
        
        case GROUPCHAT_TYPES.GET_GROUPCHAT_TITLE_PAGINATION_FINISH:
            return {
                ...state,
                groupChatTitlePaginationLoading: false,
            }

        // Details
        case GROUPCHAT_TYPES.GET_GROUPCHAT_DETAIL_INIT:
            return {
                ...state,
                groupChatTitleDetailsResp: null,
                groupChatTitleDetailsRespLoading: true,
            }

        case GROUPCHAT_TYPES.GET_GROUPCHAT_DETAIL_SUCCESS:
            return {
                ...state,
                groupChatTitleDetailsResp: action.payload,
                groupChatTitleDetailsRespLoading: false,
            }

        case GROUPCHAT_TYPES.GET_GROUPCHAT_DETAIL_FINISH:
            return {
                ...state,
                groupChatTitleDetailsRespLoading: false,
            }
        
        // update
        case GROUPCHAT_TYPES.UPDATE_GROUPCHAT_TITLE_INIT:
            return {
                ...state,
                updateGroupTitleResp: null,
                updateGroupTitleLoading: true,
            }
        
        case GROUPCHAT_TYPES.UPDATE_GROUPCHAT_TITLE_SUCCESS:
            let groupChatTitlePaginationRespUpdated = state?.groupChatTitlePaginationResp?.items?.map((item, key) =>
                {
                    if(item?.id === action.payload.id) {
                        return action.payload.isActive ? { ...action.payload }  : null
                    } else {
                        return {
                            ...item
                        }
                    }
                }
            ).filter(item => item !== null);

            return {
                ...state,
                updateGroupTitleResp: action.payload,
                updateGroupTitleLoading: false,
                groupChatTitlePaginationResp: {
                    items: groupChatTitlePaginationRespUpdated,
                    totalCount: state.groupChatTitlePaginationResp?.totalCount,

                }
            }
    
        case GROUPCHAT_TYPES.UPDATE_GROUPCHAT_TITLE_FINISH:
            return {
                ...state,
                updateGroupTitleLoading: false,
            }


        // pagination
        case GROUPCHAT_TYPES.GET_GROUPCHAT_MESSAGE_INIT:
            return {
                ...state,
                paginationGroupMessageResp: null,
                paginationGroupMessageLoading: true,
            }
        case GROUPCHAT_TYPES.GET_GROUPCHAT_MESSAGE_SUCCESS:
            // console.log("REDUCER: action.payload: ",action.payload)
            // console.log("REDUCER: action.payload: ",action.payload?.items)
            const sortedItems = action.payload?.items?.slice().sort((a, b) => new Date(b.creationTime) - new Date(a.creationTime));
            return {
                ...state,
                // paginationGroupMessageResp: action.payload,
                paginationGroupMessageResp: {
                    ...action.payload,
                    items: sortedItems
                },
                paginationGroupMessageLoading: false,
            }
        case GROUPCHAT_TYPES.GET_GROUPCHAT_MESSAGE_FINISH:
            return {
                ...state,
                paginationGroupMessageLoading: false,
            }
        
        // add
        case GROUPCHAT_TYPES.ADD_GROUPCHAT_MESSAGE_INIT:
            return {
                ...state,
                addGroupMessageResp: null,
                addGroupMessageLoading: true,
            }
        case GROUPCHAT_TYPES.ADD_GROUPCHAT_MESSAGE_SUCCESS:
            const updatedItems = [
                action.payload,
                ...state.paginationGroupMessageResp?.items,
            ];
        
            const sortedUpdatedItems = updatedItems.sort((a, b) => new Date(b.creationTime) - new Date(a.creationTime));

            return {
                ...state,
                addGroupMessageResp: action.payload,
                // paginationGroupMessageResp: {
                //     items: [
                //         ...state.paginationGroupMessageResp?.items,
                //         action.payload
                //     ],
                //     totalCount: state.paginationGroupMessageResp?.totalCount
                // },
                paginationGroupMessageResp: {
                    items: sortedUpdatedItems,
                    totalCount: state.paginationGroupMessageResp?.totalCount
                },
                addGroupMessageLoading: false,
            }
        case GROUPCHAT_TYPES.ADD_GROUPCHAT_MESSAGE_FINISH:
            return {
                ...state,
                addGroupMessageLoading: false,
            }

        

        // MAIL READ
        case GROUPCHAT_TYPES.UPDATE_GROUPCHAT_READ_INIT:
            return {
                ...state,
                updateGroupMailReadResp: null,
                updateGroupMailReadLoading: true,
            }
        
        case GROUPCHAT_TYPES.UPDATE_GROUPCHAT_READ_SUCCESS:
            // console.log("groupChatTitlePaginationResp: ", state.groupChatTitlePaginationResp);
            // console.log("action.payload: ", action.payload);

            let test = 
            {
                ...state.groupChatTitlePaginationResp,
                items: state.groupChatTitlePaginationResp.items.map(item =>
                    item.id === action.payload.mailId
                        ? { ...item, isGroupChatRead: true }
                        : item
                ),
            }

            return {
                ...state,
                updateGroupMailReadResp: action.paylod?.data,
                updateGroupMailReadLoading: false,
                // groupChatTitlePaginationResp: {
                //     items: [
                //         action.payload,
                //         ...state.groupChatTitlePaginationResp?.items,
                //     ],
                //     totalCount: state.groupChatTitlePaginationResp?.totalCount
                // }

                groupChatTitlePaginationResp: {
                    ...state.groupChatTitlePaginationResp,
                    items: state.groupChatTitlePaginationResp.items.map(item =>
                        item.id === action.payload.mailId
                            ? { ...item, isGroupChatRead: action.payload.read }
                            : item
                    ),
                }
            }
        
        case GROUPCHAT_TYPES.UPDATE_GROUPCHAT_READ_FINISH:
            return {
                ...state,
                updateGroupMailReadLoading: false,
            }


        case GROUPCHAT_TYPES.GET_COUNT_GROUP_MAIL_INIT:
            return {
                ...state,
                getCountGroupMailResp: null,
                getCountGroupMailLoading: true,
            }

        case GROUPCHAT_TYPES.GET_COUNT_GROUP_MAIL_SUCCESS:
            return {
                ...state,
                getCountGroupMailResp: action.payload?.unreadCount,
                getCountGroupMailLoading: false,
            }

        case GROUPCHAT_TYPES.GET_COUNT_GROUP_MAIL_FINISH:
            return {
                ...state,
                getCountGroupMailLoading: false,
            }

        default:
            return {
                ...state
            }
        
    }
}