import queryString from "query-string";
import { getService, postService } from "../../../commonServices";
import { appBaseUrl } from "utils/config";

export class SecondaryService {
  //tenderBasic
  referenceSecondaryAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/referenceOthersRootThreeAdd`;
    let data = postService(url, body);
    return data;
  }
  secondaryRootBasicPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/referenceOthersPagination?${query}`;
    let data = getService(url);
    return data;
  }
}
