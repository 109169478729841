import { Form, Layout } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

const SettlementSupervisorForm = ({ label, comment }) => {
  return (
    <Layout.Content
      style={{
        backgroundColor: "#FAFAFA",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        marginTop: 10,
      }}
    >
      <Form.Item label={label} name={comment}>
        <TextArea
          autoSize={{ minRows: 1, maxRows: 6 }}
          autoComplete="off"
          readOnly
        />
      </Form.Item>
    </Layout.Content>
  );
};

export default SettlementSupervisorForm;
