import { EMAIL_TYPES } from "../../../types";

const INITIAL_STATE = {
  mailReferenceAddResponse: null,
  mailReferenceAddRespoonseLoading: false,

  mailReferenceListingResponse: null,
  mailReferenceListingResponseLoading: false,

  mailReferenceGetDetailsResponse: null,
  mailReferenceGetDetailsResponseLoadinG: false,

  correctionResponseData: null,
  correctionResponseLoad: false,
};

export default function mailReferenceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Appointment events
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_ADD_INIT:
      return {
        ...state,

        mailReferenceAddRespoonseLoading: true,
      };
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_ADD_SUCCESS:
      return {
        ...state,
        mailReferenceAddResponse: action.payload,
        mailReferenceAddRespoonseLoading: false,
      };
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_ADD_FINISH:
      return {
        ...state,
        mailReferenceAddRespoonseLoading: false,
      };

    //mailReferenceGetDetails
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_DETAILS_INIT:
      return {
        ...state,
        mailReferenceGetDetailsResponseLoadinG: true,
      };
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_DETAILS_SUCCESS:
      return {
        ...state,
        mailReferenceGetDetailsResponse: action.payload,
        mailReferenceGetDetailsResponseLoadinG: false,
      };
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_DETAILS_FINISH:
      return {
        ...state,
        mailReferenceGetDetailsResponseLoadinG: false,
      };

    //mailReferenceCorrectionDetails
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_CORRECTION_DETAILS_INIT:
      return {
        ...state,
        correctionResponseLoad: true,
      };
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_CORRECTION_DETAILS_SUCCESS:
      return {
        ...state,
        correctionResponseData: action.payload,
        correctionResponseLoad: false,
      };
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_CORRECTION_DETAILS_FINISH:
      return {
        ...state,
        correctionResponseLoad: false,
      };

    default:
      return { ...state };
  }
}
