import { message } from "antd";
import { REQUISITION_TYPES } from "../../../types";
import { RequisitionService } from "./api";
import { init, success, finish, error } from "services/common";
import { getProcurementCounter } from "../procurement/action";
import { getInstanceMessageCenterPagination } from "services/redux/email/instanceMessageCenter/action";
import { isEmpty } from "lodash";
import { getAllGuestEntertainment } from "services/redux/organizer/guestEvents/action";

const requisitionService = new RequisitionService();
export const getLeaveTypeListing = () => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.LEAVE_TYPE_LISTING));
    const response = await requisitionService.getLeaveTypeListing();
    dispatch(finish(REQUISITION_TYPES.LEAVE_TYPE_LISTING));
    if (response.isSuccess) {
      dispatch(success(REQUISITION_TYPES.LEAVE_TYPE_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getRequisitionApplicationStatusListing = () => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.REQUISITION_APP_STATUS_LISTING));
    const response =
      await requisitionService.getRequisitionApplicationStatusListing();
    dispatch(finish(REQUISITION_TYPES.REQUISITION_APP_STATUS_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(REQUISITION_TYPES.REQUISITION_APP_STATUS_LISTING, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const getLeaveDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.LEAVE_DETAILS));
    const response = await requisitionService.getLeaveDetails(id);
    dispatch(finish(REQUISITION_TYPES.LEAVE_DETAILS));
    if (response.isSuccess) {
      dispatch(success(REQUISITION_TYPES.LEAVE_DETAILS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const leaveApplicationUpdateManagement = (
  body,
  id,
  isApproval,
  setRequisitionModal,
  getProcurementAction,
  getProcurementCounter
) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.LEAVE_APPLICATION_UPDATE_MANAGEMENT));
    const response = await requisitionService.updateLeaveManagement(body, id);

    dispatch(finish(REQUISITION_TYPES.LEAVE_APPLICATION_UPDATE_MANAGEMENT));
    if (response?.isSuccess) {
      dispatch(
        success(
          REQUISITION_TYPES.LEAVE_APPLICATION_UPDATE_MANAGEMENT,
          response?.data
        )
      );
      if (isApproval) {
        message.success("The Leave is accepted");
      } else {
        message.success("The Leave is rejected");
      }
      setRequisitionModal?.(false);
      getProcurementAction?.("leave");
      dispatch(getProcurementCounter());
    } else {
      dispatch(error(response?.errorMessage));
      message.error("This leave has error");
      setRequisitionModal?.(false);
    }
  };
};

export const guestEntertainmentApply = (
  body,
  setAddUpdateLeaveDrawerVisibility
) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.GUEST_ENTERTAINMENT_APPLY));
    const response = await requisitionService.guestEntertainmentApply(body);
    if (response.isSuccess) {
      dispatch(
        success(REQUISITION_TYPES.GUEST_ENTERTAINMENT_APPLY, response.data)
      );
      message.success(`Guest Entertainment Applied sucessfully`);
      setAddUpdateLeaveDrawerVisibility(false);
      dispatch(getAllGuestEntertainment());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getAmmountListing = () => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.AMOUNT_RESPONSE));
    const response = await requisitionService.getGuestEntertainmentAmountList();
    dispatch(finish(REQUISITION_TYPES.AMOUNT_RESPONSE));
    if (response.isSuccess) {
      dispatch(success(REQUISITION_TYPES.AMOUNT_RESPONSE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getGuestEntertainmentDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.GUEST_ENTERTAIMENT_DETAIL));
    const response = await requisitionService.guestInformation(id);
    dispatch(finish(REQUISITION_TYPES.GUEST_ENTERTAIMENT_DETAIL));
    if (response.isSuccess) {
      dispatch(
        success(REQUISITION_TYPES.GUEST_ENTERTAIMENT_DETAIL, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const guestEntertainmentSupervisorAction = (
  id,
  body,
  geStatus,
  setInstanceMessageCenterListing,
  setRequisitionModal
) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.GUEST_ENTERTAIMENT_SUPERVISOR_ACTION));
    const response = await requisitionService.guestEntertainmentSupervisor(
      id,
      body
    );
    if (response.isSuccess) {
      dispatch(
        success(
          REQUISITION_TYPES.GUEST_ENTERTAIMENT_SUPERVISOR_ACTION,
          response.data
        )
      );
      if (geStatus === "Approve") {
        message
          .success(`Guest Entertainment Approved sucessfully`)
          .then(dispatch(getGuestEntertainmentDetails(id)))
          .then(
            setInstanceMessageCenterListing?.((prevData) => {
              if (!isEmpty(prevData.items)) {
                return {
                  items: [
                    response?.data?.instanceMessageDto,
                    ...prevData.items,
                  ],
                  totalCount: prevData.totalCount + 1,
                };
              } else {
                return {
                  items: [response?.data?.instanceMessageDto],
                  totalCount: 1,
                };
              }
            })
          );
      } else {
        message
          .success(`Guest Entertainment Rejected sucessfully`)
          .then(dispatch(getGuestEntertainmentDetails(id)))
          .then(
            setInstanceMessageCenterListing((prevData) => {
              if (!isEmpty(prevData.items)) {
                return {
                  items: [
                    response?.data?.instanceMessageDto,
                    ...prevData.items,
                  ],
                  totalCount: prevData.totalCount + 1,
                };
              } else {
                return {
                  items: [response?.data?.instanceMessageDto],
                  totalCount: 1,
                };
              }
            })
          );
      }
      setRequisitionModal?.(false);
      dispatch(getProcurementCounter());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const guestEntertainmentManagementAction = (
  id,
  body,
  geStatus,
  setInstanceMessageCenterListing,
  setRequisitionModal
) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.GUEST_ENTERTAIMENT_MANAGEMENT_ACTION));
    const response =
      await requisitionService.guestEntertainmentManagementAction(id, body);
    if (response.isSuccess) {
      dispatch(
        success(
          REQUISITION_TYPES.GUEST_ENTERTAIMENT_MANAGEMENT_ACTION,
          response.data
        )
      );
      if (geStatus === "Approve") {
        message
          .success(`Guest Entertainment Approved sucessfully`)
          .then(dispatch(getGuestEntertainmentDetails(id)))
          .then(
            setInstanceMessageCenterListing((prevData) => {
              if (!isEmpty(prevData.items)) {
                return {
                  items: [
                    response?.data?.instanceMessageDto,
                    ...prevData.items,
                  ],
                  totalCount: prevData.totalCount + 1,
                };
              } else {
                return {
                  items: [response?.data?.instanceMessageDto],
                  totalCount: 1,
                };
              }
            })
          );
      } else {
        message
          .success(`Guest Entertainment Rejected sucessfully`)
          .then(dispatch(getGuestEntertainmentDetails(id)))
          .then(
            setInstanceMessageCenterListing((prevData) => {
              if (!isEmpty(prevData.items)) {
                return {
                  items: [
                    response?.data?.instanceMessageDto,
                    ...prevData.items,
                  ],
                  totalCount: prevData.totalCount + 1,
                };
              } else {
                return {
                  items: [response?.data?.instanceMessageDto],
                  totalCount: 1,
                };
              }
            })
          );
      }
      setRequisitionModal?.(false);
      dispatch(getProcurementCounter());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const guestEntertainmentAccountantAction = (
  id,
  body,
  geStatus,
  setInstanceMessageCenterListing,
  setRequisitionModal
) => {
  return async (dispatch) => {
    dispatch(init(REQUISITION_TYPES.GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION));
    const response =
      await requisitionService.guestEntertainmentAccountantAction(
        id,
        body,
        setInstanceMessageCenterListing
      );
    if (response.isSuccess) {
      dispatch(
        success(
          REQUISITION_TYPES.GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION,
          response.data
        )
      );
      if (geStatus === "Approve") {
        message
          .success(`Guest Entertainment Approved sucessfully`)
          .then(dispatch(getGuestEntertainmentDetails(id)))
          .then(
            setInstanceMessageCenterListing((prevData) => {
              if (!isEmpty(prevData?.items)) {
                return {
                  items: [
                    response?.data?.instanceMessageDto,
                    ...prevData.items,
                  ],
                  totalCount: prevData.totalCount + 1,
                };
              } else {
                return {
                  items: [response?.data?.instanceMessageDto],
                  totalCount: 1,
                };
              }
            })
          );
        // .then(
        //   dispatch(
        //     getInstanceMessageCenterPagination({
        //       MaxResultCount: 100,
        //       SkipCount: 0,
        //       MessageCenterId: MessageCenterId,
        //       applicationId:
        //     })
        //   )
        // );
      } else {
        message
          .success(`Guest Entertainment Rejected sucessfully`)
          .then(dispatch(getGuestEntertainmentDetails(id)))
          .then(
            setInstanceMessageCenterListing((prevData) => {
              if (!isEmpty(prevData.items)) {
                return {
                  items: [
                    response?.data?.instanceMessageDto,
                    ...prevData.items,
                  ],
                  totalCount: prevData.totalCount + 1,
                };
              } else {
                return {
                  items: [response?.data?.instanceMessageDto],
                  totalCount: 1,
                };
              }
            })
          );
      }
      setRequisitionModal?.(false);
      dispatch(getProcurementCounter());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
