import { Checkbox, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
// import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import VariationForm from "./contractVariation/form";
import PaymentReceivedForm from "./paymentReceived/form";
import FeeReceivedForm from "./feeReceived/form";
import ContractVariationDetails from "./contractVariation";
import PaymentReceivedByPrincipalDetails from "./paymentReceived";
import FeeReceivedDetails from "./feeReceived";
import PrincipleReceivedLogDetails from "./paymentReceivedLog";
import { useSelector } from "react-redux";

const { Text, Title } = Typography;

const arrowRight = {
  border: "solid black",
  borderWidth: "0 2px 2px 0",
  display: "inline-block",
  padding: "3px",
  marginLeft: "5px",
  transition: "0.2s ease-in-out",
};

export default function AccountView({
  currencyOptions,
  formVariation,
  paymentRecivedByPrincipalForm,
  formFeeReceived,
  singleTenderCeadData,
  ...props
}) {
  const { tenderCeadAccSpecificDetailsLoading } = useSelector(
    (state) => state.ceadReducer
  );

  const { code } = singleTenderCeadData?.referenceDto;

  const [show, setShow] = useState({
    isKey: "",
    isVariation: false,
    isExpandAll: false,
  });

  const [variationForm, setShowVariationForm] = useState(false);

  const handleAddForm = () => {
    setShowVariationForm(true);
    formVariation.resetFields();
    paymentRecivedByPrincipalForm.resetFields();
    formFeeReceived.resetFields();
  };

  useEffect(() => {
    if (!tenderCeadAccSpecificDetailsLoading) {
      setShowVariationForm(false);
    }
  }, [tenderCeadAccSpecificDetailsLoading]);

  const children = () => {
    return (
      <span style={{ float: "right", marginRight: "15px" }}>
        <Text onClick={handleAddForm} className="cead-stage-on-add">
          Add
        </Text>
      </span>
    );
  };

  const accViewListOptions = [
    {
      key: "1",
      label: "Contract Value/Variations (Principal-Client)",
      children: children(),
      content: (
        <ContractVariationDetails singleTenderCeadData={singleTenderCeadData} />
      ),
    },

    {
      key: "2",
      label: "Pmt. Received by Principal",
      children: children(),
      content: (
        <PaymentReceivedByPrincipalDetails
          singleTenderCeadData={singleTenderCeadData}
        />
      ),
    },

    {
      key: "3",
      label: "Raised Fee Invoice Log",
      // children: children(),
      content: (
        <PrincipleReceivedLogDetails
          singleTenderCeadData={singleTenderCeadData}
        />
      ),
    },

    {
      key: "4",
      label: "Fee Received Log",
      children: children(),
      content: (
        <FeeReceivedDetails singleTenderCeadData={singleTenderCeadData} />
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          margin: "10px 0px",
          background: "#DFF0FF",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {singleTenderCeadData?.contract}
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              setShow({ ...show, isExpandAll: true });
            } else {
              setShow({ ...show, isExpandAll: false });
            }
          }}
          checked={show.isExpandAll}
        >
          Expand All
        </Checkbox>
      </div>

      {accViewListOptions.map((x) => {
        return (
          <>
            <Title
              level={5}
              style={{ background: "#D8DDEB" }}
              onClick={() => {
                setShow({
                  isKey: x?.key,
                  isVariation:
                    x?.key === show?.isKey && show.isVariation === true
                      ? false
                      : x?.key === show?.isKey && show.isVariation === false
                      ? true
                      : true,
                });
              }}
              key={x.key}
            >
              <i
                style={{
                  ...arrowRight,
                  marginBottom:
                    (show.isKey === x?.key && show.isVariation) ||
                    show.isExpandAll
                      ? "3px"
                      : "",
                  transform:
                    (show.isKey === x?.key && show.isVariation) ||
                    show.isExpandAll
                      ? "rotate(45deg)"
                      : "rotate(-45deg)",
                }}
              ></i>{" "}
              {x?.label}
              {x?.children}
            </Title>
            {/* {((x?.key == show.isKey && show.isVariation) || show.isExpandAll) &&
              x.content} */}
            {((x?.key == show.isKey && show.isVariation) || show.isExpandAll) &&
              x.content}
          </>
        );
      })}

      <Modal
        title={
          show.isKey === "1"
            ? `Variation Details of ${code}`
            : show.isKey === "2"
            ? `Payment Received by Principle Details of ${code}`
            : `Fee Received Log of ${code}`
        }
        visible={variationForm}
        footer={null}
        onCancel={() => {
          setShowVariationForm(false);
        }}
        width={1200}
      >
        {show.isKey === "1" && (
          <VariationForm
            currencyOptions={currencyOptions}
            formVariation={formVariation}
            singleTenderCeadData={singleTenderCeadData}
          />
        )}

        {show.isKey === "2" && (
          <PaymentReceivedForm
            currencyOptions={currencyOptions}
            paymentRecivedByPrincipalForm={paymentRecivedByPrincipalForm}
            singleTenderCeadData={singleTenderCeadData}
          />
        )}

        {show.isKey === "4" && (
          ///feeReceivedForm
          <FeeReceivedForm
            currencyOptions={currencyOptions}
            singleTenderCeadData={singleTenderCeadData}
            formFeeReceived={formFeeReceived}
          />
        )}
      </Modal>
    </>
  );
}
