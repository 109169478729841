import { Tooltip, Typography } from "antd";
import moment from "moment";
import React from "react";
import { showNumber } from "utils/showNumber";
import { handleSpecialDate } from "utils/tooltipSepecialDate";

const getCashColumnsCEMAT = ({ widthh, modalShow }) => {
    return [
        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "left",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    Company
                </Typography.Paragraph>
            ),
            key: "type",
            dataIndex: "type",
            render: (_, record) => {
                return (
                    <Typography.Paragraph
                        className="antd-paragraphg"
                        style={{
                            textAlign: "left",
                            fontSize: widthh < 1550 ? 13 : 15,
                            marginBottom: `0px !important`,
                        }}
                    >
                        {record?.companyName}
                    </Typography.Paragraph>
                );
            },
        },

        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "right",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    Opening Balance
                </Typography.Paragraph>
            ),
            key: "openingBal",
            dataIndex: "openingBal",
            render: (_, record) => (
                <Tooltip
                    // title={moment(record?.incomeUpdatedDate).format("YYYY MMM DD")}
                    title={handleSpecialDate(record?.incomeUpdatedDate)}
                >
                    <Typography.Paragraph
                        style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
                        onDoubleClick={() => {
                            modalShow(record?.branchQueryParam, "fee income", "", "Opening Balance");
                        }}
                    >
                        {showNumber(record?.openingBal)}
                    </Typography.Paragraph>
                </Tooltip>
            ),
        },
        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "right",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    All Balance (Debt)
                </Typography.Paragraph>
            ),
            key: "debit",
            dataIndex: "debit",
            render: (_, record) => (
                <Tooltip
                    // title={moment(record?.incomeUpdatedDate).format("YYYY MMM DD")}
                    title={handleSpecialDate(record?.debitUpdatedDate)}
                >
                    <Typography.Paragraph
                        style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
                        onDoubleClick={() => {
                            modalShow(record?.branchQueryParam, "other income", record?.debitQueryParam, "All Balance (Debt)");
                        }}
                    >
                        {showNumber(record?.debit)}
                    </Typography.Paragraph>
                </Tooltip>
            ),
        },
        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "right",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    All Balance (Credit)
                </Typography.Paragraph>
            ),
            key: "credit",
            dataIndex: "credit",
            render: (_, record) => (
                <Tooltip
                    // title={moment(record?.incomeUpdatedDate).format("YYYY MMM DD")}
                    title={handleSpecialDate(record?.creditUpdatedDate)}
                >
                    <Typography.Paragraph
                        style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
                        onDoubleClick={() => {
                            modalShow(record?.branchQueryParam, "total income", record?.creditQueryParam, "All Balance (Credit)");
                        }}
                    >
                        {showNumber(record?.credit)}
                    </Typography.Paragraph>
                </Tooltip>
            ),
        },
        {
            title: (
                <Typography.Paragraph
                    style={{
                        textAlign: "right",
                        width: "100%",
                        verticalAlign: "baseline",
                    }}
                >
                    Closing Balance
                </Typography.Paragraph>
            ),
            key: "closing",
            dataIndex: "closing",
            render: (_, record) => (
                <Tooltip
                    // title={moment(record?.expenseUpdatedDate).format("YYYY MMM DD")}
                    title={handleSpecialDate(record?.expenseUpdatedDate)}
                >
                    <Typography.Paragraph
                        style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
                    // onDoubleClick={() => {
                    //     modalShow(record?.branchQueryParam, "expense", "", "Closing Balance");
                    // }}
                    >
                        {showNumber(record?.closingBal)}
                    </Typography.Paragraph>
                </Tooltip>
            ),
        },
    ];
};

export default getCashColumnsCEMAT;
