import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Radio,
  Popconfirm,
  Spin,
  Skeleton,
  Checkbox,
} from "antd";

import { isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {
  contactCompanyGetDetails,
  contactPersonGetDetails,
} from "services/redux/contact/companypeople/action";

import {
  actionForReferenceCircularPlanningAdd,
  ReferenceRemarksCompanyAdd,
  actionForUpdateCircularPlanningCompany,
  actionForAddCompanyOrContactPersonEmailAddById,
} from "services/redux/Reference/action";
import { getCompanyorPersonEmailAddess } from "services/redux/contact/contact.action";
import QuickAddPerson from "components/modals/quickAddPerson";
import { debounce } from "lodash";

function AddCompanyModal(props) {
  const {
    selectedMenuItem,
    applicationId,
    // isCircularTemplateModal,
    isTemplateModalOpen,
    setIsTemplateModalOpen,

    formCompanyId,
    setFormCompanyId,
    selectedCompanyId,
    setSelectedCompanyId,
    addCompanyModelForm,
    emailReferenceId,
    showProjectTab,
    getDetails,
    isCircular,
    selectedAttendees,
    setSelectedAttendees,
    selectedCompanyName,
    setSelectedCompanyName,
    data,
    setData,
    isAddCompanyModelOpen,
    setIsAddCompanyModelOpen,
  } = props;

  const {
    contactPersonPaginationResponse,
    contactPersonPaginationLoading,
    contactCompanyMailResonse,
    contactCompanyMailLoading,
  } = useSelector((state) => state.contactCompanyPerson);

  const { contactCompanyPersonMailResponse, contactCompanyPersonMailLoading } =
    useSelector((state) => state.contact);

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const dispatch = useDispatch();
  const [formQuickAddPerson] = Form.useForm();
  const [formAddCompanyEmail] = Form.useForm();
  const { Option } = Select;

  const [valueRadioAddCompany, setValueRadioAddCompany] = useState("1");
  const [searchKeywordContactPerson, setSearchKeywordContactPerson] =
    useState("");
  const [isPersonModalVisible, setIsPersonModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [hasCompanyEmailAddress, setHasCompanyEmailAddress] = useState(false);
  const [hasSelectedAttentionTos, setHasSelectedAttentionTos] = useState(false);

  //
  const [isAddNewPersonStatus, setisAddNewPersonStatus] = useState(false);
  const [isAddCompanyEmailModalOpen, setIsAddCompanyEmailModalOpen] =
    useState(false);
  const [isAddCompanyEmailModalLoading, setIsAddCompanyEmailModalLoading] =
    useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleAddCompanyEmailModalClose = () => {
    formAddCompanyEmail.resetFields();
    setIsAddCompanyEmailModalOpen(false);
  };

  const debouncedDispatch = useCallback(
    debounce((selectedPersonIds, selectedCompanyId) => {
      dispatch(
        getCompanyorPersonEmailAddess(selectedPersonIds, selectedCompanyId)
      );
    }, 300),
    [dispatch]
  );

  const handleAddCompanyModal = () => {
    addCompanyModelForm.resetFields();
    setIsAddCompanyModelOpen(false);
    setFormCompanyId(null);
    setSelectedCompanyId(null);
    setHasSelectedAttentionTos(false);
  };

  const onChangeAddCompany = (value) => {
    setValueRadioAddCompany(value);
    setIsTemplateModalOpen(true);
  };

  const handleSelectChange = (values, options) => {
    const selectedData = options.map((option) => ({
      AttentionTo: option.props.children,
      AttentionId: option.key,
    }));

    let selectedPersonIds =
      selectedData &&
      selectedData?.map((item) => ({
        contactPersonIds: item?.AttentionId,
      }));

    setSelectedAttendees(selectedData);
    setHasSelectedAttentionTos(!isEmpty(values));

    // dispatch(getCompanyorPersonEmailAddess(selectedPersonIds, selectedCompanyId));
    debouncedDispatch(selectedPersonIds, selectedCompanyId);
  };

  const handleQuickAddPersonModal = useCallback(() => {
    setIsPersonModalVisible(false);
    formQuickAddPerson.resetFields();
  }, [formQuickAddPerson]);

  // useEffect(() => {
  //     const hasEmail = contactCompanyMailResonse?.some(item => item.email) ?? false;
  //     setHasCompanyEmailAddress(hasEmail);
  // }, [contactCompanyMailResonse])

  const onFinishAddCompany = (values) => {
    let remarksData = {
      remarks:
        valueRadioAddCompany === "1"
          ? "Email"
          : valueRadioAddCompany === "2"
          ? "Facebook"
          : valueRadioAddCompany === "3"
          ? "Whatsapp"
          : valueRadioAddCompany === "4"
          ? "Twitter"
          : valueRadioAddCompany === "5"
          ? "Other"
          : null,
    };

    // for manual Company Add
    const api1 = {
      ReferenceId: emailReferenceId,
      CompanyId: selectedCompanyId,
    };

    let payloadSource = showProjectTab ? "projectId" : "tenderBasicId";

    const bodyPayload = {
      companyId: selectedCompanyId,
      circularPlanningTemplateId: selectedMenuItem,
      remarks: values?.remarks,
      ModeofCommunication: remarksData?.remarks,
      attentionTo: JSON.stringify(selectedAttendees),
      isactive: true,
      emailTo: JSON.stringify(values?.emailTo),
      Priority: values?.priority || "",
    };

    try {
      dispatch(actionForUpdateCircularPlanningCompany(bodyPayload));
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsAddCompanyModelOpen(false);
      addCompanyModelForm.resetFields();
    }
  };

  const onFinishAddCompanyEmail = async (values) => {
    let body = {
      companyId: selectedCompanyId,
      email: values?.newEmail ?? "",
    };

    setIsAddCompanyEmailModalLoading(true);

    try {
      if (body?.companyId) {
        await dispatch(actionForAddCompanyOrContactPersonEmailAddById(body));
        handleAddCompanyEmailModalClose();
        if (selectedCompanyId) {
          dispatch(getCompanyorPersonEmailAddess(null, selectedCompanyId));
        }
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setIsAddCompanyEmailModalLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="Add Company Template"
        className=""
        visible={isAddCompanyModelOpen}
        onCancel={handleAddCompanyModal}
        footer={null}
      >
        <Form
          {...formItemLayout}
          labelAlign="left"
          form={addCompanyModelForm}
          onFinish={onFinishAddCompany}
        >
          <Form.Item label="Moc">
            <Select
              value={valueRadioAddCompany}
              onChange={onChangeAddCompany}
              defaultValue="1"
            >
              <Option value="1">Email</Option>
              <Option value="2">Facebook</Option>
              <Option value="3">Whatsapp</Option>
              <Option value="4">Twitter</Option>
              <Option value="5">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Remaks" name="remarks">
            <Input.TextArea rows={4} placeholder="Remarks" />
          </Form.Item>
          <Form.Item label="Company Name">
            <div
              style={{
                display: "flex",
              }}
            >
              <Input disabled value={selectedCompanyName} />
              <Button
                // type="primary"
                type="dashed"
                shape="circle"
                onClick={() => setIsAddCompanyEmailModalOpen(true)}
              >
                +
              </Button>
            </div>
          </Form.Item>

          <Form.Item
            label="Priority"
            name="priority"
            rules={[{ required: true, message: "Please select a priority!" }]}
          >
            <Select
              placeholder="Select your priority"
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
              ]}
            />
          </Form.Item>

          {/* {!hasCompanyEmailAddress && (
                        <Form.Item
                            label="Company Email"
                            name="email"
                            rules={[
                            { required: true, message: 'Please enter the company email!' },
                            {
                                pattern: emailRegex,
                                message: 'Please enter a valid email address!',
                            },
                            ]}
                        >
                            <Input
                                placeholder="Enter company email"
                            />
                        </Form.Item>
                    )} */}

          <Form.Item label="Attention to" name="attendees">
            <Select
              showSearch
              mode="multiple"
              autoComplete="off"
              optionFilterProp="label"
              placeholder="Select Person"
              allowClear
              onSearch={(value) => {
                setSearchKeywordContactPerson(value);
              }}
              onChange={handleSelectChange}
              notFoundContent={
                <>
                  {contactPersonPaginationLoading ? (
                    <Skeleton />
                  ) : (
                    <div>
                      Content Not Found. Do you want to Person?
                      <Button
                        type="primary"
                        onClick={() => {
                          setIsPersonModalVisible(true);

                          dispatch(contactCompanyGetDetails(selectedCompanyId));
                          const namesArray =
                            searchKeywordContactPerson.split(" ");
                          formQuickAddPerson.setFieldsValue({
                            firstName: namesArray[0],
                            lastName: namesArray.slice(1).join(" ") || "",
                          });
                        }}
                      >
                        Yes
                      </Button>
                    </div>
                  )}
                </>
              }
            >
              {data.map((option) => (
                <Option
                  label={option.label}
                  key={option.value}
                  value={option.label}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {valueRadioAddCompany === "1" && (
            <Form.Item label="Email To" name="emailTo">
              <Select
                mode="multiple"
                placeholder="Select Contact Person"
                style={{ width: "100%" }}
                optionFilterProp="children"
              >
                {contactCompanyPersonMailResponse.map((contact) => (
                  <Option
                    key={`${contact.name}-${contact.email}`}
                    value={contact.email}
                  >
                    {contact.email} ({contact.name})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item>
            <Button
              className="company-add-save-btn"
              type="primary"
              htmlType="submit"
              shape="round"
            >
              Submit
            </Button>
            <Button
              type="dotted"
              onClick={() => {
                setIsAddCompanyModelOpen(false);
              }}
              bordered={false}
              shape="round"
            >
              Close
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* QUICK ADD PERSOM*/}
      <Modal
        title="Quick Add Person"
        visible={isPersonModalVisible}
        footer={null}
        onCancel={handleQuickAddPersonModal}
        style={{
          zIndex: 2000,
        }}
      >
        <QuickAddPerson
          form={formQuickAddPerson}
          previousForm={addCompanyModelForm}
          layout={formItemLayout}
          onCloseModal={handleQuickAddPersonModal}
          isAddNewPersonStatus={isAddNewPersonStatus}
          setisAddNewPersonStatus={setisAddNewPersonStatus}
          selectedCompanyName={selectedCompanyName}
          selectedCompanyId={selectedCompanyId}
        />
      </Modal>

      <Modal
        title="Add Compay Email"
        visible={isAddCompanyEmailModalOpen}
        footer={null}
        onCancel={handleAddCompanyEmailModalClose}
      >
        {/* ADD EMAIL HERE */}
        <Form form={formAddCompanyEmail} onFinish={onFinishAddCompanyEmail}>
          <Form.Item
            label="Email Addresss"
            name="newEmail"
            rules={[
              { required: true, message: "Please enter the company email!" },
              {
                pattern: emailRegex,
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input placeholder="Enter company email" />
          </Form.Item>
          <Form.Item>
            <Button
              className="company-add-save-btn"
              type="primary"
              htmlType="submit"
              shape="round"
              loading={isAddCompanyEmailModalLoading}
            >
              Add Email
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddCompanyModal;
