import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Col, message, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "services/remanentCalls";
import { getOrganizerEventLabelListings } from "services/redux/organizer/labelListing/action";
import { getOrganizerEventPriorityListings } from "services/redux/organizer/priorityLisiting/action";
import { getTodoEventsPagination } from "services/redux/organizer/todoLists/action";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";

import ToDoLists from "./TodoList";
import ToDoForm from "./TodoForm";

const ToDo = (props) => {
  const dispatch = useDispatch();
  const toDoEventsState = useSelector((state) => state.toDoEvents);
  const labelEventsState = useSelector((state) => state.labelEvents);
  const priorityEventsState = useSelector((state) => state.priorityEvents);
  const statusListingState = useSelector((state) => state.statusListing);

  const { allToDoEvents, todoEventsLoading } = toDoEventsState;
  const { organizerEventLabelListing } = labelEventsState;
  const { organizerEventPriorityListing } = priorityEventsState;
  const { allEventStatusListing } = statusListingState;

  const [selectedToDoEvent, setSelectedToDoEvent] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [meetingInvitees, setMeetingInvitees] = useState([]);
  const [showCompleted, setShowCompleted] = useState(false);

  const pageSize = 10;

  const [
    addUpdateCountryDrawerVisibility,
    setAddUpdateCountryDrawerVisibility,
  ] = useState(false);
  const [updateProfileDrawerVisibility, setUpdateProfileDrawerVisibility] =
    useState(false);
  const [isUpdateCountry, setIsUpdateCountry] = useState(false);

  const showAddUpdateCountryDrawer = (e, record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };

  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry("");
    setAddUpdateCountryDrawerVisibility(false);
  };

  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setMeetingInvitees(resp.data);
    }
  };
  const onChangeCompleted = () => {
    setShowCompleted(!showCompleted);
    if (!showCompleted) {
      dispatch(
        getTodoEventsPagination(
          `maxResultCount=100&skipCount=0&ReferenceIds=${props?.emailReferenceId}&isCompleted=1`
        )
      );
    } else {
      dispatch(
        getTodoEventsPagination(
          `maxResultCount=100&skipCount=0&ReferenceIds=${props?.emailReferenceId}`
        )
      );
    }
  };

  useEffect(() => {
    if (isEmpty(organizerEventLabelListing)) {
      dispatch(getOrganizerEventLabelListings());
    }

    if (isEmpty(organizerEventPriorityListing)) {
      dispatch(getOrganizerEventPriorityListings());
    }

    if (isEmpty(allEventStatusListing)) {
      dispatch(getStatusEventListing());
    }

    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
  }, []);

  const editToDoList = (item) => {
    setSelectedToDoEvent(item);
  };

  return (
    <div className="layout">
      {props?.getDetails?.referenceCode && (
        <div className="business-header" style={{ marginTop: "5px" }}>
          <div className="business-header-title">
            <div
              className="profiledetails__rowedit--data"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              title={props?.getDetails?.title}
            >
              <span
                className="referenceDetails--subtitle"
                style={{ color: "blue" }}
              >
                {props?.getDetails?.referenceCode} [{props?.getDetails?.title}]
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="organizer appointments">
        <Col className="switch_div_show_completed_tender_task">
          <Switch onChange={onChangeCompleted} checked={showCompleted} />
          Show Completed
        </Col>

        <ToDoLists
          data={allToDoEvents}
          editToDoList={editToDoList}
          //   getData={getData}
          pageSize={pageSize}
          todoEventsLoading={todoEventsLoading}
          organizerEventLabelListing={organizerEventLabelListing}
          organizerEventPriorityListing={organizerEventPriorityListing}
          allEventStatusListing={allEventStatusListing}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
          emailReferenceId={props.emailReferenceId}
          referenceCode={props?.getDetails?.referenceCode}
        />

        <ToDoForm
          selectedToDoEvent={selectedToDoEvent}
          editToDoList={editToDoList}
          organizerEventLabelListing={organizerEventLabelListing}
          organizerEventPriorityListing={organizerEventPriorityListing}
          meetingInvitees={meetingInvitees}
          allEventStatusListing={allEventStatusListing}
          todoEventDrawer={false}
          drawerVisibility={addUpdateCountryDrawerVisibility}
          onAddUpdateCountryClose={onAddUpdateCountryClose}
          isUpdateCountry={isUpdateCountry}
        />
      </div>
    </div>
  );
};

export default ToDo;
