import { jsonParser } from "utils/misc";
import { APPLICATION_CONFIGURATION } from "../../../types";

const INITIAL_STATE = {
    applicationConfiguration: jsonParser(jsonParser(sessionStorage.getItem("persist:root"))?.applicationConfig)
    ?.applicationConfiguration || [],
    applicationConfigurationLoading: false,
};

export default function applicationConfigurationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case APPLICATION_CONFIGURATION.GET_APPLICATION_CONFIGURATION_INIT:
            return {
                ...state,
                applicationConfiguration: [],
                applicationConfigurationLoading: true,
            };
        case APPLICATION_CONFIGURATION.GET_APPLICATION_CONFIGURATION_SUCCESS:
            return {
                ...state,
                applicationConfiguration: action.payload,
                applicationConfigurationLoading: false,
            };
        case APPLICATION_CONFIGURATION.GET_APPLICATION_CONFIGURATION_FINISH:
            return {
                ...state,
                applicationConfigurationLoading: false,
            };
        default:
            return { ...state };
    }
}
