import { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas, getDaysDifference } from "utils/commonFunc";

import {
  Input,
  Button,
  Select,
  Collapse,
  Checkbox,
  Form,
  Row,
  Col,
  message,
  Radio,
  Popover,
  Spin,
  InputNumber,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  StarFilled,
  PlusCircleOutlined,
  MoreOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { cqBaseUrl } from "utils/config";

const ProjectDetails = ({ getDetails }) => {
  const [uploadersExceptProfile, setUploadersExceptProfile] = useState([]);
  const [groupedByDocuments, setGroupedByDocuments] = useState([]);

  const { token } = useSelector(
    //for accessing to the token form redux store
    (state) => state.auth
  );

  const getDayDiffVadlitiy = (ValidityDate) => {
    // var date1 = new Date(ValidityDate);
    // var date2 = new Date(submissionDate);
    // var Difference_In_Time = date1.getTime() - date2.getTime();
    // var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    // return `${diff} `;
  };

  const getDayDiffSecurity = (startDate) => {
    // var date1 = new Date(startDate);
    // var date2 = new Date(startDate);
    // var presentDate = new Date();
    // if ((isUpdate = "details")) {
    //   // To calculate the time difference of two dates
    //   var Difference_In_Time = date1.getTime() - date2.getTime();
    //   // To calculate the no. of days between two dates
    //   var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    // } else if (isUpdate == "add") {
    //   var Difference_In_Time = presentDate.getTime() - date1.getTime();
    //   // To calculate the no. of days between two dates
    //   var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    //   setShowParticipation(diff);
    // }
    // return `${diff}`;
  };

  const popOver = (value, header) => {
    const textContent = (
      <div>
        <h3 className="popOver--title">Please Enter {header}</h3>
        <div className="popOver--content">
          <Input defaultValue={value} />
          <div className="popUpbuttons">
            <Button
              type="primary"
              className="popUpbuttons--btn-success"
              icon={<CheckOutlined style={{ fontSize: 12 }} />}
            />
            <Button
              type="danger"
              className="popUpbuttons--btn-danger"
              icon={<CloseOutlined style={{ fontSize: 12 }} />}
            />
          </div>
        </div>
      </div>
    );

    return <div>{value}</div>;
  };
  const screenSize = window.innerHeight;

  return (
    <>
      {!isEmpty(getDetails) ? (
        <div
        //   className="Project_Details"
        //   style={{ padding: 10, width: 535, paddingLeft: 20 }}
        >
          <div className="profiledetails__header" style={{ marginTop: 5 }}>
            <div className="profiledetails__header--text">
              {getDetails.referenceCode}
            </div>
          </div>

          <div className="profiledetails__content--divider">
            {getDetails?.projectStatus ? (
              <div className="referenceDetails__info">
                <div className="referenceDetails__label">
                  <span className="referenceDetails--title ">Status </span>
                </div>
                <div className="referenceDetails__labeldata">
                  <span className="referenceDetails--subtitle noWrap-content">
                    {popOver(getDetails?.projectStatus, "Title")}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="referenceDetails__info">
              <div className="referenceDetails__label">
                <span className="referenceDetails--title ">Title </span>
              </div>
              <div className="referenceDetails__labeldata">
                <span className="referenceDetails--subtitle noWrap-content">
                  {popOver(getDetails?.title)}
                </span>
              </div>
            </div>
            <div className="referenceDetails__info">
              <div className="referenceDetails__label">
                <span className="referenceDetails--title ">Sector</span>
              </div>
              <div className="referenceDetails__labeldata">
                <span className="referenceDetails--subtitle noWrap-content">
                  {popOver(
                    // getDetails?.projectSectorDtos?.map(
                    //   (x) => `${x?.sectorName},`
                    // ),
                    getDetails?.projectSectorDtos?.map(
                      (x, index) =>
                        `${index === 0 ? x?.sectorName : ", " + x?.sectorName}`
                    ),
                    "Title"
                  )}
                </span>
              </div>
            </div>

            <div className="referenceDetails__info">
              <div className="referenceDetails__label">
                <span className="referenceDetails--title ">Client </span>
              </div>
              <div className="referenceDetails__labeldata">
                <span className="referenceDetails--subtitle noWrap-content">
                  {popOver(getDetails?.clientName, "Title")}
                </span>
              </div>
            </div>

            <div className="referenceDetails__info">
              <div className="referenceDetails__label">
                <span className="referenceDetails--title ">Description </span>
              </div>
              <div className="referenceDetails__labeldata">
                <span className="referenceDetails--subtitle noWrap-content">
                  {popOver(getDetails?.description, "Title")}
                </span>
              </div>
            </div>

            <div className="referenceDetails__info">
              <div className="referenceDetails__label">
                <span className="referenceDetails--title ">Modality </span>
              </div>
              <div className="referenceDetails__labeldata">
                <span className="referenceDetails--subtitle noWrap-content">
                  {popOver(getDetails?.modalityName, "Title")}
                </span>
              </div>
            </div>
            <div className="referenceDetails__info">
              <div className="referenceDetails__label">
                <span className="referenceDetails--title ">
                  Funding Agency{" "}
                </span>
              </div>
              <div className="referenceDetails__labeldata">
                <span className="referenceDetails--subtitle noWrap-content">
                  {popOver(
                    getDetails?.projectFundingAgencyDtos?.map(
                      (x, index) =>
                        `${
                          index === 0
                            ? x?.fundingAgencyName
                            : ", " + x?.fundingAgencyName
                        }`
                    ),
                    "Title"
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>No Data Selected</div>
      )}
    </>
  );
};

export default ProjectDetails;
