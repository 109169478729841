import { DYNAMIC_FILE_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { DynamicFileLabelTypeService } from "./api";

const dynamicFileLabelTypeService = new DynamicFileLabelTypeService();

//document lable type
export const getDynamicFileLabelTypeUploaderDetail = (type, isAll = false) => {
  // console.log("typsssssssssse", type);
  return async (dispatch) => {
    dispatch(init(DYNAMIC_FILE_TYPES.DYNAMIC_FILE_LABEL_TYPES));
    const response =
      await dynamicFileLabelTypeService.getDynamicFileLabelTypeUploaderDetail(
        type
      );
    dispatch(finish(DYNAMIC_FILE_TYPES.DYNAMIC_FILE_LABEL_TYPES));
    if (response.isSuccess) {
      if (isAll) {
        dispatch(
          success(
            DYNAMIC_FILE_TYPES.DYNAMIC_FILE_LABEL_TYPES_ALL,
            response.data
          )
        );
      } else {
        dispatch(
          success(DYNAMIC_FILE_TYPES.DYNAMIC_FILE_LABEL_TYPES, response.data)
        );
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
