import { Table } from "antd";
import { showNumber } from "utils/showNumber";

export default function ContractVariationDetails({ singleTenderCeadData }) {
  const columns = [
    {
      title: "Group Index/Type",
      dataIndex: "groupIndex",
    },

    {
      title: "Currency",
      dataIndex: "abbvr",
    },
    {
      title: "Contract Amt",
      className: "text-align--right",
      dataIndex: "contractAmount",
      render: (text) => {
        // return text ? text : "--";
        return text ? showNumber(text) : "--";
      },
    },

    {
      title: "Variation Amount",
      className: "text-align--right",
      dataIndex: "variationAmount",
      render: (text) => {
        return text ? showNumber(text) : "--";
      },
    },

    {
      title: "Fee Percentage",
      className: "text-align--right",
      dataIndex: "feePercentage",
    },
    {
      title: "Fee Amount",
      className: "text-align--right",
      dataIndex: "feeAmount",
      render: (text, record) => {
        let amt = record?.variationAmount
          ? record?.variationAmount
          : record?.contractAmount;
        let feeAmt = (amt * record?.feePercentage) / 100;
        return isNaN(feeAmt) ? "" : showNumber(feeAmt);
      },
    },
    // {
    //   title: "Exclusions",
    //   dataIndex: "exclusions",
    // },
    // {
    //   title: "Vat Percentage",
    //   dataIndex: "vatPercentage",
    // },
    // {
    //   title: "Vat On Fee Percentage",
    //   dataIndex: "vatOnFeePercentage",
    // },
    // {
    //   title: "Ex. Rate",
    //   dataIndex: "exchangeRate",
    // },
  ];

  const dataSources = singleTenderCeadData?.tenderCEADVariationDtos;

  const originalData =
    singleTenderCeadData?.foreign_ContractFinancialsInformationDto
      ?.tenderCEADContractFinancialsInformationDtos;

  const transformedDataSource = dataSources?.flatMap(
    ({ groupIndex, remarks }, index) =>
      singleTenderCeadData?.tenderCEADVariationDtos?.[
        index
      ]?.tenderCEADVariationInformationDtos?.map((x) => ({
        groupIndex,
        ...x,
        remarks,
      }))
  );

  let dataSource = transformedDataSource?.map((x) => {
    return {
      ...x,
      ...x?.currencyDto,
      groupIndex: `Variation ${x?.groupIndex}`,
    };
  });

  let destructureOriginalData = originalData?.map((x) => {
    return {
      ...x,
      ...x?.currencyDto,
    };
  });

  dataSource =
    dataSource?.length > 0
      ? [
          ...dataSource,
          {
            groupIndex: "Org. Cont",
          },
          ...destructureOriginalData,
        ]
      : [
          {
            groupIndex: "Org. Cont",
          },
          ...destructureOriginalData,
        ];

  return (
    <>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
    </>
  );
}
