import useRoles from "hooks/useRoles";
import { upperCase } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionForAddUpdateFinancialInformation,
  getFinancialDashboardListing,
} from "services/redux/admin/FiscalYear/action";

const {
  Card,
  Form,
  Typography,
  Row,
  Col,
  Input,
  Button,
  InputNumber,
} = require("antd");

export default function USHPCFinancialInfoUpdate() {
  const [formUSHECIncExpStatus] = Form.useForm();
  const [formUSHECNCFCBankStatus] = Form.useForm();
  const dispatch = useDispatch();
  const { LimitedRoleDemo } = useRoles();

  const {
    addUpdateFiscalYearResponseLoading,
    addUpdateFiscalYearResponseNCFCBankStatusLoading,
    dashboardFinancialResponse,
  } = useSelector((state) => state.fiscalYearState);

  useEffect(() => {
    let appendUrl = "";
    if (LimitedRoleDemo) {
      appendUrl +=
        "maxResultCount=100&Category=Income Expense status&AccountHead=USHEC";
    }

    dispatch(getFinancialDashboardListing(appendUrl));
  }, [LimitedRoleDemo]);

  // console.log("dashboardFinancialResponse", dashboardFinancialResponse);
  let dataUSHECInc = dashboardFinancialResponse?.items?.find((x) => {
    return (
      x?.accountCategory === "Income Expense status" &&
      x?.accountHead === "USHEC"
    );
  });

  let dataUSHECNCFCBankStatus = dashboardFinancialResponse?.items?.find((x) => {
    return (
      x?.accountCategory === "NC/FC Bank Status" && x?.accountHead === "USHEC"
    );
  });

  useEffect(() => {
    formUSHECIncExpStatus?.setFieldsValue({
      amount:
        dataUSHECInc?.financialInformationIncomeExpenseStatus
          ?.incomeInFiscalYear,
      amountTwo:
        dataUSHECInc?.financialInformationIncomeExpenseStatus
          ?.expenseInFiscalYear,
      amountThree:
        dataUSHECInc?.financialInformationIncomeExpenseStatus?.budget,
    });

    formUSHECNCFCBankStatus?.setFieldsValue({
      amount: dataUSHECNCFCBankStatus?.financialInformationNcFcBankStatus?.nc,
      amountTwo:
        dataUSHECNCFCBankStatus?.financialInformationNcFcBankStatus?.fc,
      amountThree:
        dataUSHECNCFCBankStatus?.financialInformationNcFcBankStatus?.total,
    });
  }, [dashboardFinancialResponse]);

  const onFinishForm = (values, formName) => {
    console.log("formName", formName);
    let apiData = {
      financialAccountingInfomationId:
        formName === "Income Expense status"
          ? dataUSHECInc?.financialAccountingInformationId
          : dataUSHECNCFCBankStatus?.financialAccountingInformationId,
      companyAbbrevation: "USHEC",
      accountHead: "USHEC",
      accountCategory: formName,
      ...values,
    };
    dispatch(actionForAddUpdateFinancialInformation(apiData));
  };

  return (
    <>
      <div
        style={{
          //   display: "flex",
          //  justifyContent: "space-evenly",
          margin: 100,
        }}
      >
        <Card
          title={
            <Typography.Title style={{ textAlign: "center" }} level={5}>
              FINANCIAL INFORMATION (USHEC-INCOME, EXPENSE, BUDGET STATUS)
              (LIMITED ROLE)
            </Typography.Title>
          }
        >
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={(values) => onFinishForm(values, "Income Expense status")}
            form={formUSHECIncExpStatus}
          >
            <Row gutter={[24, 0]}>
              <Col span={12}>
                <Form.Item label="Income" name="amount">
                  <InputNumber
                    placeholder="Enter Income"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Expense" name="amountTwo">
                  <InputNumber
                    placeholder="Enter Expense"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Budget" name="amountThree">
                  <InputNumber
                    placeholder="Enter Budget"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "right" }}
              loading={addUpdateFiscalYearResponseLoading}
            >
              Submit
            </Button>
          </Form>
        </Card>

        <Card
          title={
            <Typography.Title style={{ textAlign: "center" }} level={5}>
              FINANCIAL INFORMATION (USHEC-NC/FC Bank Status) (LIMITED ROLE)
            </Typography.Title>
          }
        >
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFinish={(values) => onFinishForm(values, "NC/FC Bank Status")}
            form={formUSHECNCFCBankStatus}
          >
            <Row gutter={[24, 0]}>
              <Col span={12}>
                <Form.Item label="Nepali Currency" name="amount">
                  <InputNumber
                    placeholder="Enter Income"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Foreign Currency (in Rs.)" name="amountTwo">
                  <InputNumber
                    placeholder="Enter Expense"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "right" }}
              loading={addUpdateFiscalYearResponseNCFCBankStatusLoading}
            >
              Submit
            </Button>
          </Form>
        </Card>
      </div>

      {/* </div> */}
    </>
  );
}
