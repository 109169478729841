import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import IpAddressTable from "./IPAddressTable";
import AddUpdateIpDrawer from "./AddUpdateIpDrawer";
import { actionForGetWhiteAccessGetListingIpAddress } from "services/redux/admin/WhitelistIPAddress/action";
import { isEmpty } from "lodash";

const WhiteIpAddress = () => {
    const {
        getIpAddressResp,
        getIpAddressLoading
    } = useSelector((state) => state.whitelistIpReducer)

    const dispatch = useDispatch();


    const [tblData, setTblData] = useState([]);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [addUpdateIp, setAddUpdateIp] = useState({
        isAddIp: false,
        isUpdateIp: false,
        isDeleteIp: false,
        data: {}
    })

    useEffect(() => {
        dispatch(actionForGetWhiteAccessGetListingIpAddress());
    }, []);

    useEffect(() => {
        if (!isEmpty(getIpAddressResp)) {
            setTblData(getIpAddressResp)
        }
    }, [getIpAddressResp])

    const handleOpenDrawer = () => {
        setIsOpenDrawer(true);
        setAddUpdateIp({
            isAddIp: true,
            isUpdateIp: false,
            isDeleteIp: false,
            data: {}
        })
    }

    const handleCloseDrawer = () => {
        setIsOpenDrawer(false);
    };

    return (
        <div>
            <div className="all-header-wrapper organizer meetings">
                <div className="meeting__flex">
                    <h3 className="header__all">Whitelist IP Address</h3>
                    <Button className="ant-btn btn-primary"
                        onClick={handleOpenDrawer}
                    >
                        Add Ip Address
                    </Button>
                </div>
            </div>

            <IpAddressTable
                tblData={tblData}
                addUpdateIp={addUpdateIp}
                setAddUpdateIp={setAddUpdateIp}
                isOpen={isOpenDrawer}
                setIsOpenDrawer={setIsOpenDrawer}
            />

            <AddUpdateIpDrawer
                isOpen={isOpenDrawer}
                onClose={handleCloseDrawer}
                addUpdateIp={addUpdateIp}
                setAddUpdateIp={setAddUpdateIp}
            />

        </div>
    )
}

export default WhiteIpAddress;