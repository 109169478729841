import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
  AutoComplete,
  Tooltip,
  Modal,
} from "antd";
//loadas
import { cloneDeep, isEmpty } from "lodash";
//common api
import {
  countryListing,
  cityListing,
  scopeOfWorkListing,
  scopeofWorkAdd,
} from "services/remanentCalls";
import {
  CheckOutlined,
  CloseOutlined,
  StarFilled,
  PlusCircleOutlined,
  MoreOutlined,
  PaperClipOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { quickCompanyAdd } from "services/redux/contact/companypeople/action";
const QuickAddCompany = ({
  form,
  companySearch,
  quickContactCompanyAdd,
  quickContactCompanyLoader,
  quickAddFromProject,
}) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [scopeTags, setScopeTags] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [filterCitiesLists, setFilterCitiesLists] = useState([]);
  const [scopeSearch, setScopeSearch] = useState("");
  const [compareTags, setCompareTags] = useState([]);

  useEffect(() => {
    fetchCountryListing();
    // getScopeListing();
    fetchCities();
  }, []);

  useEffect(() => {
    if (scopeSearch) {
      fetchScopeofWorkListing();
    } else {
      setScopeTags([]);
    }
  }, [scopeSearch]);

  useEffect(() => {
    if (companySearch) {
      form.setFieldsValue({
        companyName: companySearch,
      });
    }
  }, [companySearch]);

  const onChange = (value, index) => {
    const data = cities.filter((filtered) => filtered.countryId === value);

    setFilterCitiesLists(data);
  };

  const fetchCities = async (body) => {
    const resp = await cityListing().catch((err) => {
      message.error("Failure");
    });

    const value = form.getFieldValue(`contactCompanyGroupAddressDtos`);
    if (resp && resp.status === 200) {
      setCities(resp.data);
    }
  };
  const fetchCountryListing = async (body) => {
    const resp = await countryListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setCountries(resp.data);
    }
  };

  const fetchScopeofWorkListing = async () => {
    const resp = await scopeOfWorkListing({ searchText: scopeSearch }).catch(
      (err) => {
        message.error("failure");
      }
    );
    if (resp && resp.status === 200) {
      setScopeTags(resp.data);
    }
  };

  const handleSubmit = async () => {
    try {
      let values = await form.validateFields();
      // console.log("values", values);
      let scopeOfWork = values.scopeOfWork && values.scopeOfWork.join(",");
      let body = {
        ...values,
        scopeOfWork: scopeOfWork,
        contactSourceId: "8f9224f5-8fb0-4366-9305-0a92effe3101",
      };
      dispatch(quickCompanyAdd(body));

      // console.log("values", values);
    } catch {
      // console.log("error");
    }
  };
  const addScopeofWork = async (value, option) => {
    if (isEmpty(option)) {
      const resp = await scopeofWorkAdd(value).catch((err) => {
        message.error("Failed to add this tag");
      });
    }
  };
  function info() {
    let data = scopeSearch.replace(/\s+/g, " ").trim();
    data = scopeSearch.split(" ");

    data = data.filter((val) => !compareTags.includes(val));
    Modal.confirm({
      title: `Are you sure if you want to add new Scope of Work "${data}"`,
      okText: "Yes",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      cancelText: "No",
      onOk() {
        let val = data.map((list) => ({
          name: list,
        }));

        addScopeofWork(val, "");
        form.setFieldsValue({
          scopeOfWork: !isEmpty(form.getFieldsValue().scopeOfWork)
            ? [...form.getFieldsValue().scopeOfWork.concat(data)]
            : data,
        });
      },
      onCancel() {},
    });
  }

  return (
    <Form
      autoComplete="off"
      className="add__user__form"
      layout="vertical"
      form={form}
      name="control-hooks"
    >
      <div className="flex-box quick-addEmail">
        <span style={{ width: "20%" }}>Company:</span>
        <div className="input-box">
          <Form.Item
            name="companyName"
            rules={[
              {
                required: true,
                message: "Company Name is Required",
              },
            ]}
          >
            <Input size="small" bordered={false} />
          </Form.Item>
        </div>
      </div>

      <div className="flex-box quick-addEmail">
        <span style={{ width: "20%" }}>Scope of Work:</span>
        <div className="input-box">
          <Form.Item
            name="scopeOfWork"
            rules={[
              {
                required: true,
                message: "Scope of Work is Required",
              },
            ]}
          >
            <Select
              autoComplete="off"
              placeholder="Scopes of Work"
              size="default"
              mode="multiple"
              bordered={false}
              onPopupScroll={(e) => {
                console.log("duplicated", e);
              }}
              notFoundContent={
                !isEmpty(scopeSearch)
                  ? isEmpty(scopeTags) && `Create ${scopeSearch}`
                  : null
              }
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Tab") {
                  setScopeTags([]);
                  setScopeSearch("");
                  if (isEmpty(scopeTags) && !isEmpty(scopeSearch)) {
                    info();
                  }
                }
              }}
              onSearch={(e) => {
                setScopeSearch(e);
              }}
              listHeight={100}
              onClear={() => {
                setScopeSearch("");
              }}
            >
              {scopeTags?.map((tag) => (
                <Option value={tag.name} key={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      {quickAddFromProject !== "project" && (
        <>
          <div className="flex-box quick-addEmail">
            <span style={{ width: "20%" }}>Country</span>
            <div className="input-box">
              <Form.Item name="countryId" rules={[{ required: false }]}>
                <Select
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch={true}
                  size="default"
                  bordered={false}
                  onChange={(value) => onChange(value)}
                  placeholder="Country"
                >
                  {countries.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="flex-box quick-addEmail">
            <span style={{ width: "20%" }}>City:</span>
            <div className="input-box">
              <Form.Item name="cityId" rules={[{ required: false }]}>
                <Select
                  size="default"
                  bordered={false}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch={true}
                  placeholder="City"
                >
                  {filterCitiesLists &&
                    filterCitiesLists?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="flex-box quick-addEmail">
            <span style={{ width: "20%" }}>Is Active</span>
            <div className="input-box">
              <Form.Item
                className="form__group"
                name="isActive"
                initialValue={false}
                valuePropName="checked"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Checkbox className="form__group"></Checkbox>
              </Form.Item>
            </div>
          </div>

          <div className="flex-box quick-addEmail">
            <span style={{ width: "20%" }}>Is Favorite:</span>
            <div className="input-box">
              <Form.Item
                className="form__group"
                name="isFavorite"
                initialValue={false}
                valuePropName="checked"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </div>
          </div>
        </>
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          className="add-new-email-form-btn"
          type="primary"
          htmlType="submit"
          loading={quickContactCompanyLoader}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default QuickAddCompany;
