import { rootBaseUrl } from "utils/config";
import { getService } from "../../../commonServices";

export class ApplicationConfigurationService {
  getApplicationConfiguration() {
    let url = `${rootBaseUrl}/abp/application-configuration`;
    let data = getService(url);
    return data;
  }
}
