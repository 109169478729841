import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, DatePicker, Input, Button, Select, message } from "antd";
import moment from "moment";
import { getAllUsers } from "services/remanentCalls";
import { getAllDepartments } from "services/redux/adminSettings/organizationStructure/action";
import { clearanceFormAdd } from "services/redux/profile/profile.action";

const ClearanceTemplateAddUpdateForm = ({
  employeeUserId,
  formState,
  setFormState,
  updateData,
  setUpdateData,
}) => {
  const dispatch = useDispatch();
  const { departmentListingResponse } = useSelector(
    (state) => state.organizationStructure
  );

  const [form] = Form.useForm();
  const { Option } = Select;

  const [users, setUsers] = useState([]);
  const [showAllDepartment, setShowAllDepartment] = useState([]);
  const [issueDate, setIssueDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);

  const onChange = (value) => {};
  const onSearch = (value) => {};

  const handleDateChange = (date, dateString) => {
    form.setFieldsValue({ date: dateString });
  };

  // DatePicker Validation
  const validateReturnDate = (_, value) => {
    if (!value || !issueDate) {
      return Promise.resolve();
    }

    const momentValue = moment(value);
    const momentIssueDate = moment(issueDate);

    if (momentValue.isSameOrAfter(momentIssueDate, "day")) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        new Error("Return Date should be later than or equal to Issue Date")
      );
    }
  };

  const handleIssueDateChange = (date, dateString) => {
    setIssueDate(date);
    if (!date) {
      form.setFieldsValue({ return_date: null });
    }
  };
  const handleReturnDateChange = (date, dateString) => {
    setReturnDate(date);
  };

  const onFinish = (values) => {
    let value = {};
    const createrUser = values.creater_user;
    const approvalUser = values.approval_user;
    if (updateData) {
      value = {
        id: updateData?.id,
        requestedEmployeeId: employeeUserId,
        equipmentName: values.name,
        organizationDepartmentId: values.department,
        description: values.description,
        issuedDate: moment(values.issue_date).toISOString(),
        returnDate: moment(values.return_date).toISOString(),
        clearanceIssueUserId: createrUser,
        clearanceApprovalUserId: approvalUser,
        approvalDate: moment(values.approval_date).toISOString(),
        isActive: true,
      };
    } else {
      value = {
        requestedEmployeeId: employeeUserId,
        equipmentName: values.name,
        organizationDepartmentId: values.department,
        description: values.description,
        issuedDate: moment(values.issue_date).toISOString(),
        returnDate: moment(values.return_date).toISOString(),
        clearanceIssueUserId: createrUser,
        clearanceApprovalUserId: approvalUser,
        approvalDate: moment(values.approval_date).toISOString(),
        isActive: true,
      };
    }

    if (values) {
      dispatch(clearanceFormAdd(value));
      form.resetFields();
    }
  };

  const handleDelete = () => {
    if (updateData) {
      const updateValue = {
        ...updateData,
        isActive: false,
      };
      dispatch(clearanceFormAdd(updateValue));
      form.resetFields();
      // setShowForm(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    dispatch(getAllDepartments());
  }, [dispatch]);

  useEffect(() => {
    setShowAllDepartment(departmentListingResponse);
  }, [departmentListingResponse]);

  useEffect(() => {
    if (updateData) {
      const issueDate = moment(updateData?.issuedDate);
      const returnDate = moment(updateData?.returnDate);
      const approvalDate = moment(updateData?.approvalDate);

      form.setFieldsValue({
        name: updateData?.equipmentName,
        department: updateData?.organizationDepartmentId,
        description: updateData?.description,
        issue_date: issueDate.isValid() ? moment(issueDate) : null,
        return_date: returnDate.isValid() ? moment(returnDate) : null,
        approval_date: approvalDate.isValid() ? moment(approvalDate) : null,
        creater_user: updateData?.clearanceIssueUserId,
        approval_user: updateData?.clearanceApprovalUserId,
      });
    } else {
      form.resetFields(); // Clear form fields if updateData is null or undefined
    }
  }, [updateData, form]);

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        name="ClearanceForm"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 10,
        }}
        className="clearance__form"
      >
        {formState?.addForm ? (
          <>
            <Form.Item
              label="EquipmentName"
              name="name"
              rules={[
                {
                  required: true,
                  message: "please enter your name",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Department"
              name="department"
              rules={[
                {
                  required: true,
                  message: "please enter your department",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a department"
                optionFilterProp="children"
                onChange={onChange}
              >
                {showAllDepartment.map((department) => (
                  <Option key={department.id} value={department.id}>
                    {department.departmentName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "please enter your description",
                },
              ]}
            >
              <Input.TextArea style={{ resize: "none" }} />
            </Form.Item>
            <Form.Item
              label="Issued Date"
              name="issue_date"
              className="demo"
              rules={[
                {
                  required: true,
                  message: "please enter your date",
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                onChange={handleIssueDateChange}
              />
            </Form.Item>
            <Form.Item
              className="form-required-false"
              label="Return Date"
              name="return_date"
              rules={[
                {
                  // required: false,
                  // message: "please enter your date",
                  validator: validateReturnDate,
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                onChange={handleReturnDateChange}
                disabled={!issueDate}
                disabledDate={(current) =>
                  issueDate &&
                  current &&
                  current < moment(issueDate).startOf("day")
                }
              />
            </Form.Item>
            <Form.Item
              label="Issued By User"
              name="creater_user"
              rules={[
                {
                  required: true,
                  message: "please enter your name",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a user"
                optionFilterProp="children"
                onChange={onChange}
              >
                {users.map((user) => (
                  <Option key={user.appUserId} value={user.appUserId}>
                    {`${user.fullName}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="form-required-false"
              label="Approval User"
              name="approval_user"
              rules={[
                {
                  required: false,
                  message: "please enter your name",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a user"
                optionFilterProp="children"
                onChange={onChange}
              >
                {users.map((user) => (
                  <Option key={user.appUserId} value={user.appUserId}>
                    {`${user.fullName}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="form-required-false"
              label="Approval Date"
              name="approval_date"
              rules={[
                {
                  required: false,
                  message: "please enter your date",
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                onChange={(date) => handleDateChange(date)}
              />
            </Form.Item>
            <Form.Item
              className="clearance_footer"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
            >
              {formState?.editForm ? (
                <div
                  className=""
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Item>
                    <Button
                      className="clearance__form--btn clearance__form--btn-cancel ant-btn-dangerous"
                      type=""
                      onClick={() => {
                        setUpdateData(null);
                        setFormState({
                          editForm: false,
                          showForm: false,
                        });
                        handleDelete();
                      }}
                    >
                      Delete
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="clearance__form--btn clearance__form--btn-cancel"
                      type=""
                      onClick={() => {
                        // form.resetFields();
                        setUpdateData(null);
                        setFormState({
                          editForm: false,
                          showForm: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="clearance__form--btn clearance__form--btn-save"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Form.Item>
                    <Button
                      className="clearance__form--btn clearance__form--btn-cancel"
                      type=""
                      onClick={() => {
                        // form.resetFields();
                        setUpdateData(null);
                        setFormState({
                          editForm: false,
                          showForm: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="clearance__form--btn clearance__form--btn-save"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item>
              <Button
                onClick={() => {
                  setFormState({ addForm: true, editForm: false });
                  form.resetFields();
                  setUpdateData(null);
                }}
                className="clearance_add-btn"
              >
                + Add Clearance Equiment
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
};

export default ClearanceTemplateAddUpdateForm;
