import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  DatePicker,
  Select,
  Layout,
  message,
} from "antd";
import { validateMessages } from "utils/misc";
import {
  honorRollAdd,
  honorRollUpdate,
} from "services/redux/officeresource/honorroll/action";
import { getAllUsers } from "services/remanentCalls";
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Header, Footer } = Layout;

const CityAddUpdateDrawer = ({
  onAddUpdateCountryClose,
  drawerVisibility,
  isUpdateCountry,
}) => {
  const { honorRollAddResponse, honorRollUpdateResponse } = useSelector(
    (state) => state.honorRoll
  );

  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdateCountry) {
      form.resetFields();
      return;
    }

    let range = [];

    range[0] = moment(isUpdateCountry.startDate);
    range[1] = moment(isUpdateCountry.endDate);

    let honorRollCandidateDtos = isUpdateCountry.honorRollCandidateDtos?.map(
      (dto) => {
        return dto.candidateId;
      }
    );

    form.setFieldsValue({
      ...isUpdateCountry,
      honorRollCandidateDtos,
      range,
    });
  }, [isUpdateCountry]);

  useEffect(() => {
    onAddUpdateCountryClose();
    form.resetFields();
  }, [honorRollAddResponse, honorRollUpdateResponse]);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      let honorRollCandidateDtos = [];
      values.honorRollCandidateDtos?.forEach((dto) => {
        honorRollCandidateDtos.push({
          candidateId: dto,
        });
      });

      let body = {
        ...values,
        honorRollCandidateDtos,
        startDate: moment(values.range[0]).format("YYYY-MM-DD"),
        endDate: moment(values.range[1]).format("YYYY-MM-DD"),
      };

      if (isUpdateCountry) {
        // let newdto = [];

        // isUpdateCountry.honorRollCandidateDtos?.forEach(data => {
        //   if(values.honorRollCandidateDtos.includes(data.candidateId)){
        //     newdto.push(data)
        //   }else{
        //     newdto.push({...data, isActive: false})
        //   }
        // })
        dispatch(honorRollUpdate(isUpdateCountry.id, body));
      } else {
        dispatch(honorRollAdd(body));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <>
      <Drawer
        visible={drawerVisibility}
        width={472}
        maskClosable={false}
        className="drawer"
        onClose={onAddUpdateCountryClose}
        closable={false}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">
            {`${isUpdateCountry ? "Update" : "Create"} Honor Roll`}
          </h4>
        </Header>
        <div className="drawer__content">
          <div className="drawer__content--wrapper">
            <div className="contact__content__wrapper">
              <Form
                className="drawer-form-wrapper"
                layout="vertical"
                form={form}
                name="control-hooks"
                validateMessages={validateMessages}
              >
                <div className="drawer-form-row">
                  <div className="drawer-form-group">
                    <Form.Item
                      name="title"
                      className="form__group"
                      label="Honor Roll Title"
                      rules={[{ required: true }]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                  </div>
                  <div className="drawer-form-group">
                    <Form.Item
                      name="range"
                      label="Date range"
                      className="form__group"
                      rules={[{ required: true }]}
                    >
                      <RangePicker
                        autoComplete="off"
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </div>
                  <div className="drawer-form-group">
                    <Form.Item
                      name="note"
                      className="form__group"
                      label="Remarks"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea autoComplete="off" />
                    </Form.Item>
                  </div>
                  <div className="drawer-form-group">
                    <Form.Item
                      name={["honorRollCandidateDtos"]}
                      label="Users"
                      className="form__group"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        autoComplete="off"
                        mode="multiple"
                        onSelect={(e) => {
                          if (isUpdateCountry) {
                            isUpdateCountry.honorRollCandidateDtos.push({
                              candidateId: e,
                            });
                          }
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        // allowClear
                        // onDeselect={e => {
                        //   // let honorRollCandidateDtos = [];
                        //   let honorRollCandidateDtos = form.getFieldValue().honorRollCandidateDtos.map(data => {
                        //     if(data.id === e){
                        //       data.isActive = false;
                        //     }
                        //     return data;
                        //   })
                        //   form.setFieldsValue({...form.getFieldValue(), honorRollCandidateDtos})
                        // }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {users.map((user) => (
                          <Option key={user.appUserId}>{user.fullName}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateCountryClose}>Cancel</Button>
          <Button onClick={onSubmit} type="primary">
            Save Changes
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default CityAddUpdateDrawer;
