import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button , Row, Col} from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import AddUserDrawer from "./AddUserDrawer";
import ContactLabelAddUpdateTable from "./ContactLabelAddUpdateTable";
import ContactLabelAddUpdateDrawer from "./ContactLabelAddUpdateDrawer";

const ContactLabelAddUpdate = () => {

  const [addUpdateCountryDrawerVisibility, setAddUpdateCountryDrawerVisibility] = useState(false);
  const [
    updateProfileDrawerVisibility,
    setUpdateProfileDrawerVisibility,
  ] = useState(false);
  const [isUpdateCountry, setIsUpdateCountry] = useState(false);

  const showAddUpdateCountryDrawer = (e, record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };

  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry('');
    setAddUpdateCountryDrawerVisibility(false);
  };

  return (
    <div className='layout'>
      <div className="organizer events">
        <div className="all-header-wrapper">
            <div className="meeting__flex">
              <h3 className="header__all">Organizer Priority List</h3>
                <div className="organizerHoliday__middle--wrap">
                  <Button
                    className='btn-primary'
                    onClick={showAddUpdateCountryDrawer}>
                    <PlusOutlined /> New Organizer Priority Label
                  </Button>
                </div>
            </div>
        </div>
        <ContactLabelAddUpdateTable
          showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
        />
        <ContactLabelAddUpdateDrawer
          drawerVisibility={addUpdateCountryDrawerVisibility}
          onAddUpdateCountryClose={onAddUpdateCountryClose}
          isUpdateCountry={isUpdateCountry}
        />
      </div>
    </div>
  );
};

export default ContactLabelAddUpdate;
