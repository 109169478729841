import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Space, Button } from 'antd';
import { LogoImage } from 'utils/imageConstants';
import { statusPagination } from 'services/redux/contact/contact.action';


const StatusAddUpdateTable = ({ showAddUpdateStatusDrawer }) => {
	
	const dispatch = useDispatch();
	const columns = [
		{
			title: 'S No',
			key: "sno",
        	render: (text, object, index) => index + 1,
        	width:"5%",
		},
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Action',
			key: 'action',
			render: (record) => (
				<a className="action-btn action-btn-add" onClick={(e) => showAddUpdateStatusDrawer(e, record)}>
					Edit Status
				</a>
			),
		},
	];

	useEffect(() => {
		dispatch(statusPagination());
	
	}, [dispatch]);

	const { statusPaginationResponse, statusPaginationLoading } = useSelector((state) => state.contact);

	const [data, setdata] = useState([]);
	const [totalCount, setTotalCount] = useState(10);
	const pageSize = 10;

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (!statusPaginationResponse) return;
		setdata(statusPaginationResponse.items);
		setTotalCount(statusPaginationResponse.totalCount);
	}, [statusPaginationResponse]);

	const getData = (MaxResultCount = pageSize, SkipCount = 0, Sorting, SortType) => {
		dispatch(
			statusPagination({
				MaxResultCount,
				SkipCount,
				Sorting,
				SortType,
			})
		);
	};

	const handleTableChange = (pagination, filters, sorter, extra) => {
		let Sorting = sorter.columnKey;
		let SortType = sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : undefined;
		let MaxResultCount = pageSize;
		let SkipCount = (pagination.current - 1) * pagination.pageSize;

		getData(MaxResultCount, SkipCount, Sorting, SortType);
		// getData(pagination.current, pagination.pageSize);
	};

	return (
		<Table
			className="organizer__tables"
			columns={columns}
			rowKey="appUserId"
			dataSource={data}
			onChange={handleTableChange}
			loading={statusPaginationLoading}
			loading={{
				indicator: <img src={LogoImage} height="auto" width="50px" />,
				spinning: statusPaginationLoading,
			}}
			pagination={{
				total: totalCount,
				pageSize: pageSize,
			}}
		/>
	);
};

export default StatusAddUpdateTable;
