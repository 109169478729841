import React, { useEffect } from "react";
import { Input, Select, Button } from "antd";

function TutorialSearch({
  handleCategory,
  searchText,
  setSearchText,
  onSearch,
  listing,
}) {
  const { Search } = Input;
  const { Option } = Select;

  const options = [
    ...(listing || []).map((item) => ({
      value: item.typeName.toLowerCase(),
      label: item.typeName,
    })),
  ];

  return (
    <div className="tutorial" style={{ marginBottom: "1em", display: "flex" }}>
      <Select
        defaultValue="All"
        style={{ flexBasis: "200px" }}
        onChange={handleCategory}
        // options={type}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      <Search
        placeholder="Search your tutorial videos"
        allowClear
        // enterButton="Search Tutorial"
        enterButton={<Button type="primary">Search tutorial</Button>}
        size="middle"
        onSearch={onSearch}
      />
    </div>
  );
}

export default TutorialSearch;
