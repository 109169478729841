
import React, { useEffect, useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import DivTable from "components/table";

const LeaveTemplateDetailTable = ({
    data,
    totalData,
    leaveApplicationPaginationLoading,
}) => {

    const columns = [
        {
          key: "",
          title: "#REFF",
          render: (row) => {
            return <>{moment(row?.creationTime).format("YYYY/MM/DD")}</>;
          },
        },
        {
          key: "leaveTypeName",
          title: "Type",
          center: true,
          render: (row) => {
            return <>{row?.leaveApplicationTypeDto?.leaveTypeName}</>;
          },
        },
        {
          key: "",
          title: "Start-End",
          center: true,
          render: (row) => {
            return (
              <>
                {moment(row?.leaveDayFrom).format("YYYY/MM/DD")} -{" "}
                {moment(row?.leaveDayTo).format("YYYY/MM/DD")}
              </>
            );
          },
        },
        {
          key: "",
          title: "Days",
          center: true,
          render: (row) => {
            return <>{row?.leaveDays}</>;
          },
        },
        {
          key: "",
          title: "Status",
          center: true,
          render: (row) => {
            return <>{row.leaveApplicationCurrentStatusDto?.statusDisplayName}</>;
          },
        },
      ];


    return (
        <>
          <DivTable
            columns={columns}
            rows={data}
            height={300}
            scrollTarget="leave-table-scroll"
            totalLength={totalData}
            loading={leaveApplicationPaginationLoading}
            // handleEvent={handleActiveTable}
            // activeKey={leaveDetailsId}
          />
        </>
      );
}

export default LeaveTemplateDetailTable