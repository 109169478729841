import { Button, Col, Drawer, Modal, Row, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CreateEventDrawer from "views/Oragnizer/TaskEventDrawer/CreateEventDrawer";
import GlobalChat from "views/GlobalEmailChat/GlobalChat";
import ReplyChatBoxGlobal from "views/GlobalEmailChat/ReplyChatBoxGlobal";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersListing } from "services/redux/admin/FiscalYear/action";
import { getMailMessageParticipantStatusListing } from "services/redux/email/messageCenterType/action";

const AppointmentDetailModal = ({
  modalVisible,
  setModalVisible,
  details,
  applicationType,
}) => {
  const { getAllUsersListResponse } = useSelector(
    (state) => state.fiscalYearState
  );

  const mailMessageParticipantStatusState = useSelector(
    (state) => state.mailMessageParticipantStatus
  );
  const { mailMessageParticipationStatusListing } =
    mailMessageParticipantStatusState;

  const { instanceChatCenterPagination, instanceChatCenterPaginationLoading } =
    useSelector((state) => state.instanceChatCenter);

  const dispatch = useDispatch();
  const [visibleEventFormDrawer, setVisibleEventFormDrawer] = useState(false);
  //   const [displayState, setDisplayState] = useState("modal");
  const [chatHeight, setChatHeight] = useState(false);
  const [smallChatHeight, setSmallChatHeight] = useState(false);
  const [replyMsgDetails, setReplyMsgDetails] = useState({});
  const [chatbox, setchatBox] = useState(false);
  const [replyOfChat, setReplyofChat] = useState("");
  const [replyStatus, setReplyStatus] = useState("");

  const onCloseEventFormDrawer = () => {
    setVisibleEventFormDrawer(false);
  };

  useEffect(() => {
    dispatch(getAllUsersListing());
    dispatch(getMailMessageParticipantStatusListing());
  }, []);

  const handleReply = (msg, status) => {
    setchatBox(true);
    setReplyofChat(msg.orderNumber);
    setReplyMsgDetails(msg);
    setReplyStatus(status);
    console.log("reply message part", msg, status);
  };

  return (
    <>
      <Modal
        title={
          JSON.stringify(details.eventCreatorUserName) ===
          sessionStorage?.name ? (
            <Button
              type="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // setModalVisible(false);
                setVisibleEventFormDrawer(true);
                //   setDisplayState("drawer");
              }}
            >
              Edit Appointment
            </Button>
          ) : (
            <>Appointment Details</>
          )
        }
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Typography.Text style={{ fontWeight: "bold" }}>
              Title
            </Typography.Text>
            <Typography.Paragraph> {details?.title}</Typography.Paragraph>
          </Col>
          <Col span={12}>
            <Typography.Text style={{ fontWeight: "bold" }}>
              Details
            </Typography.Text>
            <Typography.Paragraph>{details?.details}</Typography.Paragraph>
          </Col>

          <Col span={12}>
            <Typography.Text style={{ fontWeight: "bold" }}>
              Start Date
            </Typography.Text>
            <Typography.Paragraph>
              {" "}
              {moment(details?.startDate).format("DD MMM YYYY h:mm:ss a")}
            </Typography.Paragraph>
          </Col>

          <Col span={12}>
            <Typography.Text style={{ fontWeight: "bold" }}>
              Due Date
            </Typography.Text>
            <Typography.Paragraph>
              {moment(details?.dueDate).format("DD MMM YYYY h:mm:ss a")}
            </Typography.Paragraph>
          </Col>
          <Col span={12}>
            <Typography.Text style={{ fontWeight: "bold" }}>
              Asignees
            </Typography.Text>
            <Typography.Paragraph>
              {" "}
              {details?.organiserTaskEventCandidateDtos
                ?.filter((watcher) => watcher.isPrimary)
                ?.map((x) => `${x.candidateName},`)}
            </Typography.Paragraph>
          </Col>
          <Col span={12}>
            <Typography.Text style={{ fontWeight: "bold" }}>
              Watcher
            </Typography.Text>
            <Typography.Paragraph>
              {" "}
              {details?.organiserTaskEventCandidateDtos
                ?.filter((watcher) => watcher.isWatcher)
                ?.map((x) => `${x.candidateName},`)}
            </Typography.Paragraph>
          </Col>
        </Row>

        {/* <ChatDetails1
          chatHeight={chatHeight}
          setChatHeight={setChatHeight}
          smallChatHeight={smallChatHeight}
          setSmallChatHeight={setSmallChatHeight}
          chatBox={chatbox}
          setchatBox={setchatBox}
        /> */}

        <GlobalChat
          isPrivate={false}
          setchatBox={setchatBox}
          module="Appointment"
          handleReply={handleReply}
          setReplyStatus={setReplyStatus}
          chatBox={chatbox}
          todoDetails={details}
          setChatHeight={setChatHeight}
          chatHeight={chatHeight}
          setSmallChatHeight={setSmallChatHeight}
          smallChatHeight={smallChatHeight}
          taskId={details?.id}
          mailMessageParticipationStatusListing={
            mailMessageParticipationStatusListing
          }
          instanceChatCenterPagination={instanceChatCenterPagination}
          origin="Dashboard"
        />

        <ReplyChatBoxGlobal
          setchatBox={setchatBox}
          replyMsgDetails={replyMsgDetails}
          // replyOfChat={replyOfChat}
          replyStatus={replyStatus}
          chatBox={chatbox}
          allParticipants={getAllUsersListResponse}
          chatHeight={chatHeight}
          setChatHeight={setChatHeight}
          setSmallChatHeight={setSmallChatHeight}
          mailMessageParticipationStatusListing={
            mailMessageParticipationStatusListing
          }
          taskId={details?.id}
          todoDetails={details}
          setReplyStatus={setReplyStatus}
          handleReply={handleReply}
          chatbox={chatbox}
          applicationType={applicationType}
          origin="Dashboard"
        />
      </Modal>
      <CreateEventDrawer
        isPrivate="false"
        visibleEventFormDrawer={visibleEventFormDrawer}
        eventFormSelect="event"
        //   visibleEventFormDrawer={displayState === "drawer"}
        onCloseEventFormDrawer={onCloseEventFormDrawer}
        todoDetails={details}
        setModalVisible={setModalVisible}
      />
    </>
  );
};

export default AppointmentDetailModal;
