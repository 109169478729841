// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Drawer, Form, Button, Col, Row, Input, Select, Layout, message } from 'antd';

// const { Header, Footer } = Layout;
// const internalmessage = 'assets/svg/message-internal.svg';
// const hide = 'assets/svg/hide-16.svg';
// const show = 'assets/svg/email-show16.svg';
// const Extend = 'assets/svg/extend.svg';

// const TenderEmailChat = ({ drawerVisibility }) => {
// 	return (
// 		<Drawer className="drawer" visible={drawerVisibility} width={550}>
// 			<Header className="drawer__header">
// 				<h4 className="drawer__header--title">Show</h4>
// 			</Header>
// 		</Drawer>
// 	);
// };

// export default TenderEmailChat;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Layout,
  message,
} from "antd";

const { Header, Footer } = Layout;
const internalmessage = "assets/svg/message-internal.svg";
const hide = "assets/svg/hide-16.svg";
const show = "assets/svg/email-show16.svg";
const Extend = "assets/svg/extend.svg";

export const TenderEmailChat = ({
  onAddUpdateCountryClose,
  drawerVisibility,
}) => {
  return (
    // <Drawer className="drawer" visible={drawerVisibility} width={300}>
    // 	<Header className="drawer__header">
    // 		<h4 className="drawer__header--title">Notes</h4>
    // 	</Header>
    // 	<EditNotes />
    // </Drawer>
    <Drawer
      visible={drawerVisibility}
      width={200}
      className="drawer"
      onClose={onAddUpdateCountryClose}
      closable={false}
    >
      <div className="emailDetails__body--chat">
        <div className="chatmail">
          <div className="chatmail__header--wrapper">
            <div className="chatmail__header--leftcontent">
              <div className="chatmail__image">
                <img src={internalmessage} />
              </div>
              <div className="chatmail__text">Internal Chat</div>
            </div>
            <div className="chatmail__header--rightcontent">
              <div className="chatmail__image">
                <img src={Extend} />
              </div>
              <div className="chatmail__image">
                <img src={hide} onClick={() => onAddUpdateCountryClose()} />
              </div>
            </div>
          </div>
        </div>

        {/* <div className={`emailDetails__body--chatwrap ${chatBox ? '' : 'showHeight'}`}>
						<EmailChat handleReply={handleReply} instanceMessageCenterPagination={instanceMessageCenterListing} mailMessageParticipantTypeListing={mailMessageParticipantTypeListing} />
					</div>
					{chatBox && (
						<ReplyChatBox
							mailMessageParticipantTypeListing={mailMessageParticipantTypeListing}
							mailMessageParticipationStatusListing={mailMessageParticipationStatusListing}
							allParticipants={allParticipants}
							replyMsgDetails={replyMsgDetails}
							currentSelectedMailMessage={currentSelectedMailMessage}
							setchatBox={setchatBox}
							replyStatus={replyStatus}
						/>
					)} */}
      </div>
    </Drawer>
  );
};
