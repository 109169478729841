// import React, { useState } from "react";
import {
  Divider,
  Dropdown,
  Input,
  Layout,
  Menu,
  Typography,
  Button,
} from "antd";
import { StarFilled, PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Content } = Layout;

const starFilled = {
  display: "flex",
  alignItems: "center",
  borderBottom: "1px solid lightgray",
  borderTop: "1px solid lightgray",
  padding: "0 7px",
};

const divider = { background: "#c8c8c8", height: "15px" };

export default function SideHeader({
  setOpenDrawerRecruitment,
  setSearchKeyword,
}) {
  const hrSubMenuCompose = (
    // <Menu style={{ width: 160, height: 105 }}>
    <Menu style={{ width: 160 }}>
      <Menu.Item
        className="hr_sidebars"
        onClick={() => {
          setOpenDrawerRecruitment({
            isOpen: true,
            isEditable: false,
          });
        }}
      >
        Create Job Application
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Title
        level={4}
        style={{
          padding: "10px 0 0 10px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
        strong
      >
        Human Resource System
      </Title>

      <Content style={starFilled}>
        {/* <StarFilled  style={{ color: isFavorite ? "#ffd036" : "#c8c8c8" }}/> */}
        {/* <StarFilled style={{ color: "#c8c8c8" }} /> */}

        {/* <Divider type="vertical" style={divider} /> */}

        <Input.Search
          style={{ margin: 0 }}
          placeholder="Search"
          size="medium"
          allowClear
          autoComplete="off"
          bordered={false}
          className="form__group_email_search hrSystem_search"
          onSearch={(e) => {
            setSearchKeyword(e);
          }}
        />
        <Dropdown
          overlay={() => hrSubMenuCompose}
          arrow
          placement="bottomRight"
        >
          {/* <PlusOutlined style={{ background: "#B04759", marginRight: "3px" }} /> */}
          <div className="contactDetails__dropdown">
            <Button className="contact__header__btn-primary hrSystem_search_btn">
              <PlusOutlined />
            </Button>
          </div>
        </Dropdown>
      </Content>
    </>
  );
}
