import { Dropdown, Form, Menu } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getProcurementCounter,
  getProcurementSummary,
} from "services/redux/requistions/procurement/action";

import storage from "utils/storage";

import { getAllApprovalDetails } from "services/redux/requistions/tadaDetails/action";
import RequisitionModal from "./Modal/Requisition";
import { LoanLeaveProcurementTable } from "./Requisition/LoanLeaveProcurementTable";
import TADATable from "./Requisition/TADATable";
import Permission from "components/Requisition/Permission";

const RequisitionList = () => {
  const dispatch = useDispatch();
  const [leave, setLeave] = useState(true);
  const [loan, setLoan] = useState(false);
  const [procurement, setProcurement] = useState(false);
  const [tada, setTada] = useState(false);
  const [cead, setCead] = useState(false);
  const [guestEntertainment, setGuestEntertainment] = useState(false);
  const [requisitionModal, setRequisitionModal] = useState(false);
  const [loanApplicationId, setLoanApplicationId] = useState();
  const [activateLoan, setActivateLoan] = useState(false);
  const [activateLeave, setActivateLeave] = useState(true);
  const [activateProcurement, setActivateProcurement] = useState(false);
  const [activateTada, setActivateTada] = useState(false);
  const [activateGuest, setActivateGuest] = useState(false);
  const [activateCead, setActivateCead] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showMore, setShowMore] = useState(false);

  const {
    procurementDetails,
    procurementDetailsLoading,
    procurementSummaryManagementResponse,
    procurementSummaryManagementLoading,
    procurementCounterResponse,
    procurementUpdateAccountantLoading,
    procurementCounterLoading,
    procurementUpdateSupervisorLoading,
    procurementUpdateManagementLoading,
  } = useSelector((state) => state?.procurement);

  const { ceadApprovalDetails, ceadApprovalDetailsLoading } = useSelector(
    (state) => state?.approvalCEAD
  );

  const {
    loanApplicationDataGetDetails,
    loanApplicationDataGetDetailsLoading,
    loanUpdateSupervisorLoading,
    loanUpdateAccountantLoading,
    loanUpdateManagementLoading,
  } = useSelector((state) => state.loanApplication);

  const { guestEntertainmentDetailResponse, guestEntertainmentDetailLoading } =
    useSelector((state) => state.leaveType);

  const { leaveDetails, leaveDetailsLoading, leaveUpdateManagementLoading } =
    useSelector((state) => state.leaveType);

  const { tadaApprovalResponse, tadaApprovalLoading } = useSelector(
    (state) => state.tadaDetails
  );
  const { getTadaSingleDetailsResponse, getTadaSingleDetailsLoading } =
    useSelector((state) => state?.tadaDetails);

  //check status
  const {
    fromUser,
    accountantUser,
    managementUser,
    supervisorStatus,
    supervisorUsers,
    isSupervisor,
    accountantStatus,
    managementUsers,
    isManagement,
    managementStatus,
    accountantUsers,
    isAccountant,
    accountantName,
    accountantDescription,
    supervisorUser,
    supervisorName,
    supervisorDescription,
    managementName,
    managementDescription,
  } = Permission({
    loan,
    procurement,
    tada,
    guestEntertainment,
    storage,
    loanApplicationDataGetDetails,
    procurementDetails,
    getTadaSingleDetailsResponse,
    guestEntertainmentDetailResponse,
  });

  const getProcurementAction = (type) => {
    dispatch(getProcurementSummary(type));
  };

  const getAllTadaDetails = () => {
    dispatch(getAllApprovalDetails());
  };

  useEffect(() => {
    if (loan) {
      dispatch(getProcurementSummary("loan"));
    } else if (leave) {
      dispatch(getProcurementSummary("leave"));
    } else if (cead) {
      dispatch(getProcurementSummary("TenderCEAD"));
    } else {
      dispatch(getProcurementSummary("procurement"));
    }
    dispatch(getProcurementCounter());
    setLeave(true);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //

  useEffect(() => {
    if (width >= 1000) {
      setActivateLeave(true);
      setActivateLoan(true);
      setActivateProcurement(true);
      setActivateTada(true);
      setActivateGuest(true);
      setActivateCead(true);
    } else {
      setActivateLeave(true);
      setActivateLoan(false);
      setActivateProcurement(false);
      setActivateTada(false);
      setActivateCead(false);
    }
  }, [width]);

  const menu = (
    <Menu>
      <Menu.Item
        disabled={activateLeave}
        onClick={() => {
          setActivateLeave(true);
          setLeave(true);
          setActivateLoan(false);
          setLoan(false);
          setCead(false);
          setActivateProcurement(false);
          setActivateTada(false);
          setProcurement(false);
          setTada(false);
          getProcurementAction("leave");
        }}
      >
        Leave ({procurementCounterResponse?.leave?.total})
      </Menu.Item>

      <Menu.Item
        disabled={activateProcurement}
        onClick={() => {
          setActivateLoan(false);
          setLoan(false);
          setCead(false);
          setActivateLeave(false);
          setLeave(false);
          setActivateProcurement(true);
          setActivateTada(false);
          setProcurement(true);
          setTada(false);
          getProcurementAction("procurement");
        }}
      >
        Procurement ({procurementCounterResponse?.procurement?.total})
      </Menu.Item>

      <Menu.Item
        disabled={activateLoan}
        onClick={() => {
          setActivateLoan(true);
          setLoan(true);
          setCead(false);
          setActivateLeave(false);
          setLeave(false);
          setActivateProcurement(false);
          setProcurement(false);
          setTada(false);
          setActivateTada(false);
          getProcurementAction("loan");
        }}
      >
        Loan ({procurementCounterResponse?.loan?.total})
      </Menu.Item>

      <Menu.Item
        disabled={activateTada}
        onClick={() => {
          setActivateLoan(false);
          setLoan(false);
          setCead(false);
          setActivateLeave(false);
          setLeave(false);
          setActivateProcurement(false);
          setProcurement(false);
          setTada(true);
          setActivateTada(true);
          getAllTadaDetails();
        }}
      >
        TA/DA ({procurementCounterResponse?.tada?.total})
      </Menu.Item>

      <Menu.Item
        disabled={activateGuest}
        onClick={() => {
          setActivateLoan(false);
          setLoan(false);
          setCead(false);
          setActivateLeave(false);
          setLeave(false);
          setActivateProcurement(false);
          setProcurement(false);
          setTada(false);
          setActivateTada(false);
          setActivateGuest(true);
          setGuestEntertainment(true);
          getAllTadaDetails();
        }}
      >
        GE ({procurementCounterResponse?.tada?.total})
      </Menu.Item>

      <Menu.Item
        disabled={activateCead}
        onClick={() => {
          setActivateLoan(true);
          setLoan(false);
          setCead(true);
          setActivateLeave(false);
          setLeave(false);
          setActivateProcurement(false);
          setProcurement(false);
          setTada(false);
          setActivateTada(false);
          getProcurementAction("TenderCEAD");
        }}
      >
        CEAD ({procurementCounterResponse?.tenderCead?.total})
      </Menu.Item>
    </Menu>
  );

  const style = width < 1510 ? { paddingLeft: 25 } : {};

  return (
    <div
      className="dashboards__tenderlist dashboards__body requisition_body"
      style={{ height: "95%" }}
    >
      <div className="dashboards__bodyWrap dashboards__bodyWrap1">
        <h4 className="dashboards__body--title title_req">Pending Approvals</h4>

        <h4
          className={`dashboards__body--title`}
          style={{ display: "flex", flexDirection: "row", gap: "10px" }}
        >
          {activateGuest && (
            <button
              className={`upcoming-btn btn1 ${guestEntertainment && "active"}`}
              onClick={() => {
                setGuestEntertainment(true);
                setTada(false);
                setLeave(false);
                setLoan(false);
                setCead(false);
                setProcurement(false);
                getProcurementAction("guestentertainment");
                if (width <= 1000) {
                  setActivateProcurement(false);
                  setActivateLoan(false);
                  setActivateLeave(false);
                  setActivateTada(false);
                }
              }}
            >
              GE ({procurementCounterResponse?.guestEntertainment?.total})
            </button>
          )}

          {activateTada && (
            <button
              className={`upcoming-btn btn1 ${tada && "active"}`}
              onClick={() => {
                setTada(true);
                setLeave(false);
                setLoan(false);
                setCead(false);
                setProcurement(false);
                setGuestEntertainment(false);
                getAllTadaDetails();

                if (width <= 1000) {
                  setActivateProcurement(false);
                  setActivateLoan(false);
                  setActivateLeave(false);
                }
              }}
            >
              TA/DA ({procurementCounterResponse?.tada?.total})
            </button>
          )}

          {activateLeave && (
            <button
              className={`upcoming-btn btn1 ${leave && "active"}`}
              onClick={() => {
                setLoan(false);
                setCead(false);
                setLeave(true);
                setProcurement(false);
                setTada(false);
                getProcurementAction("leave");
                setGuestEntertainment(false);
                if (width <= 1000) {
                  setActivateProcurement(false);
                  setActivateLoan(false);
                  setActivateLeave(false);
                }
              }}
            >
              Leave({procurementCounterResponse?.leave?.total})
            </button>
          )}

          {activateProcurement && (
            <button
              className={`history-btn btn1 ${procurement && "active"}`}
              onClick={() => {
                setLoan(false);
                setCead(false);
                setLeave(false);
                setProcurement(true);
                setTada(false);
                getProcurementAction("procurement");
                setGuestEntertainment(false);
                if (width <= 1000) {
                  setActivateLeave(false);
                  setActivateLoan(false);
                  setActivateTada(false);
                }
              }}
            >
              Procurement({procurementCounterResponse?.procurement?.total})
            </button>
          )}

          {activateLoan && (
            <button
              className={`forthcoming-btn btn1 ${loan && "active"}`}
              onClick={() => {
                setLoan(true);
                setCead(false);
                setLeave(false);
                setProcurement(false);
                setTada(false);
                setGuestEntertainment(false);
                getProcurementAction("loan");
                if (width <= 1000) {
                  setActivateProcurement(false);
                  setActivateLeave(false);
                  setActivateTada(false);
                }
              }}
            >
              Loan({procurementCounterResponse?.loan?.total})
            </button>
          )}
          {activateCead && (
            <button
              className={`upcoming-btn btn1 ${cead && "active"}`}
              onClick={() => {
                setTada(false);
                setLeave(false);
                setLoan(false);
                setCead(true);
                setProcurement(false);
                setGuestEntertainment(false);
                getProcurementAction("TenderCEAD");

                if (width <= 1000) {
                  setActivateProcurement(false);
                  setActivateLoan(false);
                  setActivateLeave(false);
                }
              }}
            >
              CEAD ({procurementCounterResponse?.tenderCead?.total})
            </button>
          )}

          {width < 1000 && (
            <Dropdown.Button
              overlay={menu}
              className="open_menu_card_title_dashboard"
            ></Dropdown.Button>
          )}
        </h4>
      </div>

      <div className="dashboards__body--main">
        <div className="dashboard_title_header_tender">
          <div className="dashboard_title_main">
            <h5 style={{ paddingLeft: 5 }}>SN</h5>
            <h5 style={{ paddingLeft: 10 }}>Title</h5>
          </div>
          <div className="dashboard_title_main due_date_dashboard"></div>
        </div>
        <ul
          className="dashboards__todolists requisition1"
          style={{ height: 210, paddingTop: 10 }}
        >
          {loan || leave || procurement || guestEntertainment || cead ? (
            <LoanLeaveProcurementTable
              procurementSummaryManagementResponse={
                procurementSummaryManagementResponse
              }
              procurementSummaryManagementLoading={
                procurementSummaryManagementLoading
              }
              width={width}
              style={style}
              loan={loan}
              leave={leave}
              cead={cead}
              procurement={procurement}
              guestEntertainment={guestEntertainment}
              setLoanApplicationId={setLoanApplicationId}
              setRequisitionModal={setRequisitionModal}
              setShowMore={setShowMore}
            />
          ) : tada ? (
            <TADATable
              tadaApprovalResponse={tadaApprovalResponse}
              tadaApprovalLoading={tadaApprovalLoading}
              tada={tada}
              setLoanApplicationId={setLoanApplicationId}
              setRequisitionModal={setRequisitionModal}
              setShowMore={setShowMore}
            />
          ) : (
            ""
          )}
        </ul>
      </div>

      {requisitionModal && (
        <RequisitionModal
          leaveDetails={leaveDetails}
          leaveDetailsLoading={leaveDetailsLoading}
          procurementDetails={procurementDetails}
          procurementDetailsLoading={procurementDetailsLoading}
          ceadApprovalDetails={ceadApprovalDetails}
          ceadApprovalDetailsLoading={ceadApprovalDetailsLoading}
          loanApplicationDataGetDetails={loanApplicationDataGetDetails}
          loanApplicationDataGetDetailsLoading={
            loanApplicationDataGetDetailsLoading
          }
          requisitionModal={requisitionModal}
          setRequisitionModal={setRequisitionModal}
          loan={loan}
          cead={cead}
          leave={leave}
          procurement={procurement}
          tada={tada}
          accountantUser={accountantUser}
          accountantStatus={accountantStatus}
          managementUser={managementUser}
          managementStatus={managementStatus}
          supervisorStatus={supervisorStatus}
          loanApplicationId={loanApplicationId}
          fromUser={fromUser}
          showMore={showMore}
          setShowMore={setShowMore}
          loanUpdateSupervisorLoading={loanUpdateSupervisorLoading}
          loanUpdateAccountantLoading={loanUpdateAccountantLoading}
          loanUpdateManagementLoading={loanUpdateManagementLoading}
          procurementUpdateAccountantLoading={
            procurementUpdateAccountantLoading
          }
          procurementUpdateSupervisorLoading={
            procurementUpdateSupervisorLoading
          }
          procurementUpdateManagementLoading={
            procurementUpdateManagementLoading
          }
          leaveUpdateManagementLoading={leaveUpdateManagementLoading}
          getProcurementAction={getProcurementAction}
          getTadaSingleDetailsResponse={getTadaSingleDetailsResponse}
          getTadaSingleDetailsLoading={getTadaSingleDetailsLoading}
          guestEntertainment={guestEntertainment}
          guestEntertainmentDetailResponse={guestEntertainmentDetailResponse}
          guestEntertainmentDetailLoading={guestEntertainmentDetailLoading}
          supervisorUsers={supervisorUsers}
          isSupervisor={isSupervisor}
          managementUsers={managementUsers}
          isManagement={isManagement}
          accountantUsers={accountantUsers}
          isAccountant={isAccountant}
          accountantName={accountantName}
          accountantDescription={accountantDescription}
          supervisorUser={supervisorUser}
          supervisorName={supervisorName}
          supervisorDescription={supervisorDescription}
          managementName={managementName}
          managementDescription={managementDescription}
        />
      )}
    </div>
  );
};

export default RequisitionList;
