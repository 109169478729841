import { Typography } from "antd";
import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Typography.Title>404 - Page Not Found</Typography.Title>
      <Typography.Text>The requested page could not be found.</Typography.Text>
    </div>
  );
};

export default NotFound;
