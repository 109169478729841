import React from "react";
import { Link } from "react-router-dom";

const MailInfo = ({ nullChecker, mailPaginationCount }) => {
  return (
    <>
      <div className="dashboards__mailInfo dashboards__body email_board">
        <h4 className="dashboards__body--title email_notify_dashboard">
          Emails
        </h4>
        <div className="dashboards__wraps">
          <div className="dashboard-email-section">
            <h6 className="dashboards__mailInfo--Count red">
              {nullChecker(mailPaginationCount?.allCount?.all, "unread")}
            </h6>
            <span className="dashboards__mailInfo--CountTitle">
              <Link to={`/home?unread=unread`} style={{ color: "#6d6d6d" }}>
                unread emails
              </Link>
            </span>
          </div>

          <div className="dashboard-email-section">
            <h6 className="dashboards__mailInfo--Count blue">
              {nullChecker(mailPaginationCount?.toCount?.all, "all")}
            </h6>
            <span className="dashboards__mailInfo--CountTitle">
              Total incoming emails
            </span>
          </div>
          <div className="dashboard-email-section">
            <h6 className="dashboards__mailInfo--Count green">
              {nullChecker(mailPaginationCount?.pendingCount?.all, "all")}
            </h6>
            <span className="dashboards__mailInfo--CountTitle">
              action pending emails
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailInfo;
