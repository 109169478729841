import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import { LogoImage } from "utils/imageConstants";
import { Skeleton } from "antd";

function YoutubePlayer({ url }) {
  const [loading, setLoading] = useState(true);
  const [errorInfo, setErrorInfo] = useState("");
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    const getVideoIdFromUrl = (url) => {
      if (!url) return null;

      try {
        const urlParams = new URLSearchParams(new URL(url).search);
        return urlParams.get("v");
      } catch (error) {
        console.error("Invalid URL:", error);
        setErrorInfo(`${error?.name}: Invalid URL`);
        return null;
      }
    };

    const id = getVideoIdFromUrl(url);
    setVideoId(id);

    return () => {};
  }, [url]);

  const onReady = (event) => {
    console.log("Player is ready", event);
    setLoading(false);
  };

  const onError = (event) => {
    console.log("eror", event);
    setLoading(false);
  };

  const opts = {
    height: "400",
    width: "720",
    playerVars: {
      autoplay: 1,
    },
  };

  if (loading) {
    <Skeleton active />;
  }

  return (
    <>
      {videoId ? (
        <YouTube
          videoId={videoId}
          opts={opts}
          onReady={onReady}
          onError={onError}
        />
      ) : (
        <>{errorInfo}</>
      )}
    </>
  );
}

export default YoutubePlayer;
