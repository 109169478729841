import {
  Button,
  Drawer,
  Form,
  Input,
  Layout,
  Checkbox,
  Select,
  message,
  Row,
} from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewFolder } from "services/redux/Reference/Folder/action";
import { getAllUsers } from "services/remanentCalls";
import { validateMessages } from "utils/misc";

const { Header, Footer } = Layout;

const FolderDrawer = ({
  showAddUpdateFolderDrawer,
  setShowAddUpdateFolderDrawer,
  folderRecord,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { folderListingLoading } = useSelector((state) => state.folder);
  const [permissionToAdd, setPermissionToAdd] = useState(false);
  const [messageParticipants, setMessageParticipants] = useState([]);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setMessageParticipants(resp.data);
    }
  };
  useEffect(() => {
    if (isEmpty(messageParticipants)) {
      fetchAllParticipants();
    }
  }, []);

  const drawerClose = () => {
    setShowAddUpdateFolderDrawer(false);
  };

  const onSubmit = (values) => {
    if (folderRecord) {
      values.parentFolderId = folderRecord.id;
    }
    values.isPrivate = permissionToAdd;
    dispatch(createNewFolder(values));
    drawerClose();
  };

  return (
    <Drawer
      visible={showAddUpdateFolderDrawer}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={drawerClose}
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">
          {folderRecord.title ? (
            <>Create Folder-{folderRecord.title}</>
          ) : (
            <>Create root folder</>
          )}
        </h4>
      </Header>

      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        // validateMessages={validateMessages}
        onFinish={onSubmit}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <Form.Item
            name="name"
            className="form__group"
            label="Folder Name"
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="remarks"
            className="form__group"
            label="Remarks"
            // rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>

          <br />
        </div>
        <div style={{ paddingLeft: 10 }}>
          <div>Give Permission to</div>
          <div>
            <Checkbox
              onChange={() => {
                setPermissionToAdd(!permissionToAdd);
              }}
            >
              Private
            </Checkbox>
          </div>

          {permissionToAdd && (
            <Row span={24}>
              <div>
                <Form.Item
                  className="form__group"
                  name={`userIds`}
                  rules={[{ required: true, message: "" }]}
                  // style={{ width: "100%" }}
                >
                  <Select
                    optionFilterProp="label"
                    // bordered={false}
                    style={{ width: "450px" }}
                    mode="multiple"
                    autoFocus={true}
                    placeholder={`Internal To`}
                    // onChange={(value, option) => changeParticipantsDtosTo(option)}
                  >
                    {messageParticipants?.map((participant, j) => (
                      <Option
                        key={j}
                        value={participant.appUserId}
                        label={[participant.fullName, participant.userName]}
                      >
                        <div className="demo-option-label-item">
                          <span role="img">
                            {participant.fullName} {"" + ""}
                          </span>
                          [{participant.userName.toUpperCase()}]
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Row>
          )}
        </div>

        <div className="model-footer">
          <Button onClick={drawerClose} loading={folderListingLoading}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default FolderDrawer;
