import React, { useEffect, useState } from "react";
import { PlusOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Tooltip, message } from "antd";
import {
  getOthersSearchPagination,
  referenceOthersFavAddUpdate,
} from "services/redux/Reference/Others/action";

import { LogoImage } from "utils/imageConstants";
// const minimize = "assets/images/icons/minimize.png";
// const maximize = "assets/images/icons/maximize.png";

export const ReferenceOthersPagination = ({
  referenceOthersPaginationResponse,
  rowEventhandlersOthers,
  selectedOtherRowId,
  setSelectedOtherRowId,
  setSearchEmailTender,
  setSelectedProjectId,
}) => {
  //redux store
  const [projectData, setProjectData] = useState([]);

  const dispatch = useDispatch();
  // console.log("shadess", referenceOthersPaginationResponse);
  // const { referenceOthersPaginationResponse, referenceOthersPaginationLoading } = useSelector((state) => state.referenceOthers);

  // const getData = (MaxResultCount = pageSize, SkipCount = 0, Sorting, SortType, searchKeyword = searchKeyword) => {
  // 	let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;
  // 	if (Sorting) {
  // 		appendUrl += `&sorting=${Sorting}`;
  // 	}
  // 	if (SortType) {
  // 		appendUrl += `&sortType=${SortType}`;
  // 	}
  // 	if (searchKeyword) {
  // 		appendUrl += `&searchKeyword=${searchKeyword}`;
  // 	}
  // 	dispatch(getProjectSearchPagination(appendUrl));
  // };

  useEffect(() => {
    if (!referenceOthersPaginationResponse) return;
    setProjectData(referenceOthersPaginationResponse.items);
  }, [referenceOthersPaginationResponse]);

  return (
    <>
      {isEmpty(projectData) ? (
        <div className="nulldata">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        projectData.map((data, key) => {
          const backGroundColor = key % 2 == 0 ? "" : "#f2f2f2";

          return (
            <div
              style={{ background: backGroundColor }}
              className={`project ${
                selectedOtherRowId === data.id && "active"
              }`}
              onClick={() => {
                setSelectedProjectId(data?.referenceCode);
                rowEventhandlersOthers(data.id);
                setSelectedOtherRowId(data.id);
                setSearchEmailTender(data.referenceCode);
              }}
              key={key}
            >
              <div className="project__wrapper wrapper1">
                <div className="project__wrapper--contents">
                  <div
                    className="project__body--right"
                    style={{ color: "red" }}
                  >
                    {data.isFavorite ? (
                      <div
                        onClick={() => {
                          dispatch(
                            referenceOthersFavAddUpdate(
                              { referenceOthersId: data.id },
                              data
                            )
                          );
                        }}
                      >
                        <StarFilled style={{ color: "#ffd036" }} />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          dispatch(
                            referenceOthersFavAddUpdate(
                              { referenceOthersId: data.id },
                              data
                            )
                          );
                        }}
                      >
                        <StarOutlined style={{ color: "#ffd036" }} />
                      </div>
                    )}
                  </div>

                  <div className="project__main">
                    <div className="project__main--middle">
                      <span className="project__body--title">
                        <Tooltip title={data.title} placement="topLeft">
                          {data.title}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  {/* <div className="project__end">
										<div className="project__end--date">
											<span className="prooject__body--date">{data.description}</span>
										</div>
									</div> */}
                </div>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};
export const ReferenceOthersPaginationModal = ({
  searchKeyword,
  OthersData,
  setSelectedRows,
  selectedRows,
  setInputSearchValue,
}) => {
  //redux store
  const [projectData, setProjectData] = useState([]);
  const [totalCount, setTotalCount] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const {
    referenceOthersPaginationResponse,
    referenceOthersPaginationLoading,
  } = useSelector((state) => state.referenceOthers);

  useEffect(() => {
    dispatch(getOthersSearchPagination);
  }, [dispatch]);

  // useEffect(() => {
  //   getData();
  // }, [searchKeyword]);

  const pageSize = 100;
  const selectedMultipleRows = (item) => {
    setSelectedRows([...selectedRows, item.referenceCode]);
    if (selectedRows.includes(item.referenceCode)) {
      // className={`reference ${selectedTenderRowId === data.id && 'active'}`}
      message.warning(`Other ${item.referenceCode} already selected`);
    } else {
      console.log("items not repeated");
    }
  };

  // const getData = (
  //   MaxResultCount = pageSize,
  //   SkipCount = 0,
  //   ForPagination = false
  // ) => {
  //   dispatch(
  //     getOthersSearchPagination(
  //       {
  //         MaxResultCount,
  //         SkipCount,
  //         // Sorting,
  //         // SortType,
  //         SearchKeyword: searchKeyword,
  //       },
  //       ForPagination
  //     )
  //   );
  // };

  useEffect(() => {
    if (!referenceOthersPaginationResponse) return;
    setProjectData(referenceOthersPaginationResponse.items);
  }, [referenceOthersPaginationResponse]);

  return (
    <>
      {isEmpty(OthersData) ? (
        <div className="nulldata">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        OthersData.map((data, key) => {
          const backGroundColor = key % 2 == 0 ? "" : "#f2f2f2";

          return (
            <div
              onClick={() => {
                selectedMultipleRows(data);
                setInputSearchValue("");
              }}
              style={{ background: backGroundColor }}
              className="project"
              key={key}
            >
              <div className="project__wrapper">
                <div className="project__wrapper--contents">
                  <div
                    className="project__body--right"
                    style={{ color: "red" }}
                  >
                    {data.isFavorite ? (
                      <div
                        onClick={() => {
                          dispatch(
                            referenceOthersFavAddUpdate(
                              { referenceOthersId: data.id },
                              data
                            )
                          );
                        }}
                      >
                        <StarFilled style={{ color: "#ffd036" }} />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          dispatch(
                            referenceOthersFavAddUpdate(
                              { referenceOthersId: data.id },
                              data
                            )
                          );
                        }}
                      >
                        <StarOutlined style={{ color: "#ffd036" }} />
                      </div>
                    )}
                  </div>

                  <div className="project__main reference_others_listing">
                    <div className="project__main--middle ">
                      <span className="project__body--title">
                        {data.referenceCode}
                      </span>
                    </div>
                    <div className="project__main--middle reference_listing_description">
                      <span className="project__body--title">
                        <Tooltip title={data?.description}>
                          {data.description}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  {/* <div className="project__end">
										<div className="project__end--date">
											<span className="prooject__body--date">{data.description}</span>
										</div>
									</div> */}
                </div>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};
