import { DOCUMENT_REQUEST_UPLOAD_TYPES } from "../../types";
import { uniqBy } from "lodash";
import { jsonParser } from 'utils/misc';
const INITIAL_STATE = {
  // document request upload
  documentRequestListPagination : [],
  documentRequestListPaginationLoading:false,
  documentRequestdocumentUploadResponse: [],
  documentRequestdocumentUploadLoading: false,
  documentRequestdocumentUploadSuccess:false,
  documentRequestdocumentUploadTempFileResponse :[],
  documentRequestdocumentUploadTempFileLoading :false,
  documentShareData:jsonParser(localStorage.getItem('shareDocumentData')) || [],
};
// complaintReducer

export default function documentRquestUploadReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_PAGINATION_INIT:
      return{
        ...state,
        documentRequestListPaginationLoading:true
      }
    case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_PAGINATION_SUCCESS:
      return{
        ...state,
        documentRequestListPaginationLoading:false,
        documentRequestListPagination:action.payload,

      }
      case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_PAGINATION_CHANGE_SUCCESS:
        return{
          ...state,
          documentRequestListPaginationLoading:false,
          documentRequestListPagination:{
            items:uniqBy(
              state.documentRequestListPagination?.items.concat(action.payload.items),
              "id"
            ),
            totalCount:action.payload.totalCount
          },
  
        }
    case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_PAGINATION_FINISH:
        return{
          ...state,
          documentRequestListPaginationLoading:false
        }
    
    case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_INIT:
      return {
        ...state,
        documentRequestdocumentUploadLoading: true,
        documentRequestdocumentUploadResponse: null,
        documentRequestdocumentUploadSuccess:false
      };

    case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        documentRequestdocumentUploadResponse: action.payload,
        documentRequestdocumentUploadLoading: false,
        documentRequestdocumentUploadSuccess:true
      };
    case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_FAILED:
        return {
          ...state,
          documentRequestdocumentUploadSuccess:false
        };
  
    case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_FINISH:
      return {
        ...state,
        documentRequestdocumentUploadResponse: null,
        documentRequestdocumentUploadLoading: false,
      };
    
    case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE_INIT:
      return{
        ...state,
        documentRequestdocumentUploadTempFileLoading:false
      }
    case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE_SUCCESS:
      return {
        ...state,
        documentRequestdocumentUploadTempFileResponse: action.payload,
        documentRequestdocumentUploadTempFileLoading: false,
      };
      case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_DOCUMENT_UPLOAD_TEMP_FILE_FINISH:
        return {
          ...state,
          documentRequestdocumentUploadTempFileLoading: false,
        };
      case DOCUMENT_REQUEST_UPLOAD_TYPES.DOCUMENT_REQUEST_SHARE_SUCCESS:
        return{
          ...state,
          documentShareData:localStorage.setItem('shareDocumentData',JSON.stringify(action.payload))
        };
    default:
      return {
        ...state,
      };
  }
}
