import { Button, Drawer, Form, Input } from "antd";
import Header from "components/header";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DashboardFeeUpdate } from "services/redux/admin/FiscalYear/action";

const FeeRemainingModal = ({ showDrawer, setShowDrawer, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  console.log("data", data);

  const onFinish = (values) => {
    if (data !== undefined) {
      const body = {
        financialAccountingInfomationId: data?.financialAccountingInformationId,
        isActive: true,
        companyAbbrevation: "ICTC",
        accountHead: values?.accountHead,
        accountCategory: "FeeRemainingSub",
        amount: +values?.amount,
        amountTwo: +values?.amountTwo,
        amountThree: 0,
      };
      dispatch(DashboardFeeUpdate(body, "FeeRemainingSub")).then(() =>
        setShowDrawer(false)
      );
    } else {
      const body = {
        financialAccountingInfomationId: "",
        isActive: true,
        companyAbbrevation: "ICTC",
        accountHead: values?.accountHead,
        accountCategory: "FeeRemainingSub",
        amount: +values?.amount,
        amountTwo: +values?.amountTwo,
        amountThree: 0,
      };
      const financialAccountingInfomationId =
        body.financialAccountingInfomationId;
      delete body.financialAccountingInfomationId; // Remove the empty id from the body
      dispatch(
        DashboardFeeUpdate(
          body,
          "FeeRemainingSub",
          financialAccountingInfomationId
        )
      ).then(() => setShowDrawer(false));
    }
  };

  useEffect(() => {
    if (data !== undefined) {
      form.setFieldsValue({
        accountHead: data?.accountHead,
        amount: data?.amount,
        amountTwo: data?.amountTwo,
      });
    }
  }, [data, form]);

  return (
    <Drawer
      title='Fee Remaining form'
      visible={showDrawer}
      onClose={() => setShowDrawer(false)}
      width={472}
      maskClosable={false}
      closable={false}
    >
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        className='drawer-form-wrapper'
      >
        <div className='drawer-form-row'>
          <div className='drawer-form-group'>
            <Form.Item name='accountHead' label='Account Head'>
              <Input />
            </Form.Item>
          </div>

          <div className='drawer-form-group'>
            <Form.Item name='amount' label='Amount in NPR'>
              <Input />
            </Form.Item>
          </div>

          <div className='drawer-form-group'>
            <Form.Item name='amountTwo' label='Amount in USD'>
              <Input />
            </Form.Item>
          </div>

          <div className='model-footer'>
            <Button onClick={() => setShowDrawer(false)}>Cancel</Button>
            <Button
              className='guest__entry__button'
              type='primary'
              htmlType='submit'
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default FeeRemainingModal;
