import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from ".";
import { handleErrorStack } from ".";

const FallBackWrap = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={FallbackUI} onError={handleErrorStack}>
      {children}
    </ErrorBoundary>
  );
};

export default FallBackWrap;
