import { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";
import { useDispatch, useSelector } from "react-redux";
import { actionForAddUpdateFunding } from "services/redux/cead/action";
import moment from "moment";
import addCommaSeparator from "utils/inputCommaSeparator";
import { showNumber } from "utils/showNumber";

const { Title } = Typography;

export default function Funding({
  singleTenderCeadData,
  formFunding,
  currencyOptions,
  companyOptions,
}) {
  const dispatch = useDispatch();

  const { tenderCeadFundingLoading } = useSelector(
    (state) => state.ceadReducer
  );

  const [showFunding, setFundingShow] = useState(false);

  useEffect(() => {
    if (!tenderCeadFundingLoading) {
      setFundingShow(false);
    }
  }, [tenderCeadFundingLoading]);

  const columns = [
    {
      title: "Financier",
      dataIndex: "fundingAgencyName",
    },
    {
      title: "Currency",
      dataIndex: "abbvr",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => {
        return showNumber(text);
      },
    },
    {
      title: "Loan Effective Date",
      dataIndex: "loanEffectiveDate",
    },
    {
      title: "Loan Closing Date",
      dataIndex: "loanEndDate",
    },
  ];

  const dataSource = singleTenderCeadData?.tenderCEADFundingAgencys?.map(
    (x) => {
      return {
        ...x,
        abbvr: x?.currencyDto?.abbvr,
        loanEffectiveDate: moment(x?.loanEffectiveDate).format("YYYY-MM-DD"),
        loanEndDate: moment(x?.loanEndDate).format("YYYY-MM-DD"),
      };
    }
  );

  const onFinishFormFunding = (values) => {
    //no need this
    // let fundingApiData = [];

    // const filteredArray =
    //   singleTenderCeadData?.tenderCEADFundingAgencys?.filter((item1) =>
    //     values?.Funding?.some(
    //       (item2) => item1.fundingAgencyName == item2.fundingAgencyId
    //     )
    //   );
    // const filteredArray2 = values?.Funding?.filter(
    //   (item1) =>
    //     !singleTenderCeadData?.tenderCEADFundingAgencys?.some(
    //       (item2) => item1.fundingAgencyId == item2.fundingAgencyName
    //     )
    // );

    // fundingApiData = [...filteredArray, ...filteredArray2];

    // console.log("fundingApiDatas", fffff);
    // const apiData = fundingApiData?.map((x) => {
    //   return {
    //     isActive: true,
    //     ...x,
    //     remarks: "Remarks",
    //   };
    // });
    //no need this

    const apiData = values?.Funding?.map((x, index) => {
      if (
        singleTenderCeadData?.tenderCEADFundingAgencys?.[index]
          ?.fundingAgencyName === x?.fundingAgencyId
      ) {
        return {
          ...x,
          fundingAgencyId:
            singleTenderCeadData?.tenderCEADFundingAgencys?.[index]
              ?.fundingAgencyId,
          isActive: true,
        };
      } else {
        return {
          ...x,
          isActive: true,
        };
      }
    });

    dispatch(actionForAddUpdateFunding(apiData, singleTenderCeadData?.id));
    // dispatch(actionForAddUpdateFunding(fffff, singleTenderCeadData?.id));
  };
  return (
    <>
      <Title level={5} style={{ background: "#DFDFDF" }}>
        Funding
        {!showFunding && (
          <span
            onClick={() => {
              setFundingShow(!showFunding);
            }}
            className="bid-pointer-cursor"
          >
            <EditOutlined />
          </span>
        )}
      </Title>

      {!showFunding ? (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          style={{ maxHeight: 550, overflow: "scroll" }}
        />
      ) : (
        <Form
          form={formFunding}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinishFormFunding}
          style={{ marginTop: "10px" }}
        >
          <Form.List name="Funding">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={field.key} gutter={[10, 0]}>
                    <Col span={1}>{index + 1}</Col>

                    <Col span={3}>
                      <Form.Item
                        key={field.key}
                        label="Financier"
                        name={[field.name, "fundingAgencyId"]}
                        fieldKey={[field.fieldKey, "fundingAgencyId"]}
                        rules={[
                          {
                            required: true,
                            message: "Financier is required",
                          },
                        ]}
                        labelAlign="left"
                        align="middle"
                        vertical="top"
                      >
                        <Select
                          options={companyOptions}
                          optionFilterProp="label"
                          placeholder="Select Financier"
                          allowClear
                          showSearch
                          onSearch={(value) => {
                            let apiBody = {
                              MaxResultCount: 40,
                              SkipCount: 0,
                              searchKeyword: value,
                            };
                            dispatch(getContactCompanyPagination(apiBody));
                          }}
                          // onChange={(value, label) => {
                          //   setSelectedClientCompany(label?.label);
                          //   setSelectedPrincipalCompany();
                          // }}
                          // onFocus={() => {
                          //   let apiBody = {
                          //     MaxResultCount: 40,
                          //     SkipCount: 0,
                          //     searchKeyword: singleTenderCeadData?.clientName,
                          //   };
                          //   dispatch(getContactCompanyPagination(apiBody));
                          // }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={3}>
                      <Form.Item
                        label="Currency"
                        name={[field.name, `currencyId`]}
                        fieldKey={[field.fieldKey, "currencyId"]}
                        rules={[
                          {
                            required: true,
                            message: "Currency is required",
                          },
                        ]}
                        labelAlign="left"
                        align="middle"
                        vertical="top"
                      >
                        <Select
                          placeholder="Enter Currency Level"
                          options={currencyOptions}
                          // defaultValue={() => {
                          //   allCurrencyListingResponse?.map((currency) => {
                          //     if (currency.abbvr == "USD") {
                          //       return currency.currencyId;
                          //     }
                          //   });
                          // }}
                        />
                      </Form.Item>
                    </Col>

                    {/* <Col offset={1} span={11}> */}
                    <Col span={3}>
                      <Form.Item
                        label="Amount"
                        name={[field.name, `amount`]}
                        fieldKey={[field.fieldKey, "amount"]}
                        rules={[
                          {
                            required: true,
                            message: "Amount is required",
                          },
                        ]}
                        labelAlign="left"
                        align="middle"
                        vertical="top"
                      >
                        <Input placeholder="Enter Amount" />
                      </Form.Item>
                    </Col>

                    <Col span={7} className="cead-date-picker">
                      {/* <Col offset={1} span={8}> */}
                      <Form.Item
                        label="Loan Effective Date"
                        name={[field.name, "loanEffectiveDate"]}
                        fieldKey={[field.fieldKey, "loanEffectiveDate"]}
                        rules={[
                          {
                            required: true,
                            message: "Loan Effective Date is required",
                          },
                        ]}
                        labelAlign="left"
                        align="middle"
                        vertical="top"
                      >
                        <DatePicker placeholder="Select date" />
                      </Form.Item>
                    </Col>

                    {/* <Col offset={1} span={11}> */}
                    <Col span={7} className="cead-date-picker">
                      <Form.Item
                        label="Loan Closing Date"
                        name={[field.name, `loanEndDate`]}
                        fieldKey={[field.fieldKey, "loanEndDate"]}
                        rules={[
                          {
                            required: true,
                            message: "Loan Closing Date is required",
                          },
                        ]}
                        labelAlign="left"
                        align="middle"
                        vertical="top"
                      >
                        <DatePicker placeholder="Select date" />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      {index >= 0 && (
                        <Button
                          style={{ float: "right", marginTop: "-12px" }}
                          icon={<MinusCircleOutlined />}
                          onClick={() => remove(field.name)}
                        />
                      )}
                    </Col>
                  </Row>
                ))}
                <Button
                  style={{ marginBottom: "16px" }}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusCircleOutlined />}
                >
                  Add Fund
                </Button>
              </>
            )}
          </Form.List>
          <Row style={{ float: "right" }}>
            <Col>
              <Button
                htmlType="submit"
                onClick={() => {
                  setFundingShow(false);
                }}
              >
                Cancel
              </Button>
            </Col>

            <Col
              style={{
                marginLeft: "10px",
                marginBottom: "15px",
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
