import { EMAIL_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { ParticipationTypeService } from "./api";
import { message } from "antd";

const participationTypeService = new ParticipationTypeService();

export const getMailMessageParticipantTypeListing = () => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.PARTICIPANT_TYPE_LISTING));
    const response = await participationTypeService.getMailMessageParticipantTypeListing();
    dispatch(finish(EMAIL_TYPES.PARTICIPANT_TYPE_LISTING));
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.PARTICIPANT_TYPE_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};