import { message } from "antd";
import { BlogService } from "./api";
import { init, success, finish, error } from "services/common";
import { isEmpty } from "lodash";
import { BLOG_TYPES } from "services/types";

const blogService = new BlogService();

export const blogAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(BLOG_TYPES.CREATE_BLOG_MESSAGE));
    const response = await blogService.createBlogMessage(body);
    dispatch(finish(BLOG_TYPES.CREATE_BLOG_MESSAGE));
    if (response.isSuccess) {
      message.success("post message");
      dispatch(success(BLOG_TYPES.CREATE_BLOG_MESSAGE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateBlogMessage = (id, body) => {
  return async (dispatch) => {
    dispatch(init(BLOG_TYPES.UPDATE_BLOG_MESSAGE));
    const response = await blogService.updateBlogMessage(id, body);
    dispatch(finish(BLOG_TYPES.UPDATE_BLOG_MESSAGE));
    if (response.isSuccess) {
      message.success("update message");
      dispatch(success(BLOG_TYPES.UPDATE_BLOG_MESSAGE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getBlogPagination = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(BLOG_TYPES.GET_PAGINATION_BLOG_MESSAGE));
    const response = await blogService.getBlogMessagePagination(query);
    try {
      if (response.isSuccess) {
        if (loadMore) {
          dispatch(
            success(
              BLOG_TYPES.GET_PAGINATION_BLOG_MESSAGE_CHANGE,
              response.data
            )
          );
        } else {
          dispatch(
            success(BLOG_TYPES.GET_PAGINATION_BLOG_MESSAGE, response.data)
          );
        }
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(finish(BLOG_TYPES.GET_PAGINATION_BLOG_MESSAGE));
    }
  };
};
