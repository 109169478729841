import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  DatePicker,
  Select,
  Checkbox,
  Form,
  Row,
  Col,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  tenderBasicUpdate,
  tenderStageListing,
  tenderTypeListing,
  tenderBasicAdd,
} from "services/redux/Reference/Tender/action";
import { allCompanyListing } from "services/redux/Reference/action";
import { allCountryListing } from "services/redux/contact/contact.action";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
// import ParticipationSecurityForm from "./ParticipationBidSecurity";
// import LocalForm from "./Local";
import ParticipationForm from "./Participation";
// import moment from "moment";
import ParticipationTable from "./ParticipationTable";
import {
  // getParticipationPagination,
  tenderGetBasicDetails,
} from "services/redux/Reference/Tender/action";

const Participation = ({
  getDetails,
  // tenderTypeListingResponse,
  participationForm,
  data,
  defaultUSD,
  allCountryListingResponse,
}) => {
  const {
    tenderParticipationGetDetailsResponse,
    // tenderParticipationUpdateResponse,
    participationAddResponse,
    participationUpdateResponse,
  } = useSelector((state) => state.tender);
  const dispatch = useDispatch();
  const [particiaptionform, setParticipationform] = useState(false);
  const [getParticipationDetails, setGetParticipantDetails] = useState();

  const onSelectParticipant = (event) => {
    if (event.target.checked === true) {
      setParticipationform(true);
    } else {
      setParticipationform(false);
    }
  };

  useEffect(() => {
    if (tenderParticipationGetDetailsResponse) {
      setGetParticipantDetails(tenderParticipationGetDetailsResponse);
      // setParticipationform(false);
    }
  }, [tenderParticipationGetDetailsResponse]);

  useEffect(() => {
    if (!isEmpty(participationAddResponse)) {
      console.log("thisistheresp7");

      dispatch(tenderGetBasicDetails(participationAddResponse?.tenderBasicId));
      setParticipationform(false);
    }
  }, [participationAddResponse]);

  useEffect(() => {
    if (!isEmpty(participationUpdateResponse)) {
      console.log("thisistheresp8");

      // dispatch(tenderGetBasicDetails(participationUpdateResponse?.tenderBasicId));
      setParticipationform(false);
    }
  }, [participationUpdateResponse]);

  return (
    <>
      <div
        style={{
          width: "100",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {isEmpty(getDetails?.tenderParticipationDtos) && (
          <div className="participation-wrapper" style={{ marginTop: 10 }}>
            <div className="participation-title">
              <span>Participation</span>
            </div>
            <div className="checkbox-wrapper">
              <Checkbox
                onChange={onSelectParticipant}
                // defaultChecked={!isEmpty(getDetails?.tenderParticipationDtos) ? '' : false}
                // disabled={!isEmpty(getDetails?.tenderParticipationDtos) ? true : false}
              />
            </div>
          </div>
        )}
      </div>
      {!isEmpty(getDetails?.tenderParticipationDtos) ? (
        <ParticipationTable
          getDetails={getDetails}
          setParticipationform={setParticipationform}
        />
      ) : (
        ""
      )}
      {particiaptionform && (
        <>
          <ParticipationForm
            particiaptionform={particiaptionform}
            participationForm={participationForm}
            setParticipationform={setParticipationform}
            data={data}
            getDetails={getDetails}
            defaultUSD={defaultUSD}
            getParticipationDetails={getParticipationDetails}
            setGetParticipantDetails={setGetParticipantDetails}
            allCountryListingResponse={allCountryListingResponse}
          />
        </>
      )}
      {!isEmpty(getDetails?.tenderParticipationDtos) && !particiaptionform && (
        <div>
          <Button
            type="dashed"
            className="reference__btns--add"
            onClick={() => {
              setParticipationform(true);
              setGetParticipantDetails("");
            }}
            style={{ width: "100%" }}
            block
            icon={<PlusCircleOutlined />}
          >
            Add New
          </Button>
        </div>
      )}
    </>
  );
};

export default Participation;
