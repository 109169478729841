import { Table } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveApplicationGetDetailsWithHistoryAndRemaining } from "services/redux/requistions/leaveApplication/action";

const LeaveTable = ({ loanApplicationId }) => {
  const dispatch = useDispatch();
  const LogoImage = "assets/images/loader.gif";

  useEffect(() => {
    dispatch(
      getLeaveApplicationGetDetailsWithHistoryAndRemaining(loanApplicationId)
    );
  }, []);

  const {
    leaveApplicationGetDetailsWithHistory: { history, remaining, details },
    leaveApplicationGetDetailsWithHistoryLoading,
  } = useSelector((state) => state.leaveApplication);

  const historyColumns = [
    {
      title: "Leave Type",
      key: "leaveTypeName",
      render: (record) =>
        record?.leaveApplicationDto?.leaveApplicationTypeDto?.leaveTypeName,
      width: 100,
    },

    {
      title: "Leave Start",
      key: "leaveDayFrom",
      render: (record) => (
        <span>
          {moment(record?.leaveApplicationDto?.leaveDayFrom).format(
            "D MMM, YYYY"
          )}
        </span>
      ),
    },
    {
      title: "Leave End",
      key: "leaveDayTo",
      render: (record) => (
        <span>
          {moment(record?.leaveApplicationDto?.leaveDayTo).format(
            "D MMM, YYYY"
          )}
        </span>
      ),
    },
    {
      title: "Leave Days",
      key: "leaveDays",
      render: (record) => record?.leaveApplicationDto?.leaveDays,
    },
    {
      title: "Status",
      key: "status",
      render: (record) => record?.requisitionApplicationStatusDto?.displayName,
    },
  ];

  return (
    <>
      <Table
        className="organizer__tables leave__table"
        columns={historyColumns}
        rowKey="id"
        dataSource={history}
        pagination={false}
        loading={{
          indicator: (
            <img src={LogoImage} height="auto" width="50px" alt="logo" />
          ),
          spinning: leaveApplicationGetDetailsWithHistoryLoading,
        }}
      />
    </>
  );
};

export default LeaveTable;
