import React, { useEffect, useState, useRef } from "react";
// antd
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
  Space,
  Spin,
  message,
  Tooltip,
  Upload,
  Progress,
  Layout,
} from "antd";
import {
  LoadingOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { indexOf, isEmpty, wrap } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, uploadFile } from "services/remanentCalls";
import { instanceChatAdd } from "services/redux/instanceChat/action";
import ImageUploaderMultiple from "components/shared/ImageUploaderMultiple";
import ModalForGroupCreateUpdate from "components/modals/modalGroupCreateUpdate";
import { replyInstanceMessage } from "services/redux/email/instanceMessageCenter/action";

import storage from "utils/storage";

// ckdeditor
import { Editor } from "@tinymce/tinymce-react";
import { appBaseUrl, cqBaseUrl } from "utils/config";
import {
  actionForCreateGroup,
  actionForGetListingGroup,
  actionForUpdateGroup,
} from "services/redux/createGroup/action";

const ReplyChatBoxGlobal = ({
  setchatBox,
  chatBox,
  allParticipants,
  setChatHeight,
  setSmallChatHeight,
  smallChatHeight,
  chatHeight,
  mailMessageParticipationStatusListing,
  taskId,
  todoDetails,
  replyMsgDetails,
  replyOfChat,
  replyStatus,
  setReplyStatus,
  applicationType,
  module,
  origin,
  selectedTaskTos,
  selectedTaskCcs,
}) => {
  const { Option } = Select;
  const CheckMark = "assets/svg/checkmark-24.svg";
  const cancel = "assets/svg/white-cross.svg";
  const attachment = "assets/svg/attachment-white.svg";
  const threeDot = "assets/svg/3dot-white.svg";
  const upChervon = "assets/svg/white-up-chervon.svg";
  const send = "assets/svg/send-white.svg";
  const aa = "assets/svg/Aa-white.svg";
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 14, color: "white" }} spin />
  );

  // console.log("module",module)
  const [form] = Form.useForm();
  const [form4] = Form.useForm();

  const dispatch = useDispatch();
  const { groupData, groupDataLoading, groupDataLoadingSuccess } = useSelector(
    (state) => state.createUpdateGroup
  );
  const [editorData, setEditorData] = useState("");
  const [smsText, setSMSText] = useState("");
  const [messageParticipants, setMessageParticipants] = useState([]);
  const [mailMessageCenterType, setMailMessageCenterType] = useState([]);
  const [messageParticipantsCC, setMessageParticipantsCC] = useState([]);
  const [quickMessageParticipantDtosTo, setQuickMessageParticipantDtosTo] =
    useState([]);
  const [quickMessageParticipantDtosCC, setQuickMessageParticipantDtosCC] =
    useState([]);
  const [progress, setProgress] = useState(0);
  const [attatchments, setAttatchments] = useState([]);
  const [filterParticipantForm, setFilterParticipantForm] = useState();
  const [users, setUsers] = useState([]);
  // const [AltEnt, setAltEnt] = useState({});
  const [AltEnt, setAltEnt] = useState(false);
  const editorRef = useRef(null);
  const [par, setPar] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );
  const instanceChatCenter = useSelector((state) => state.instanceChatCenter);

  const {
    instanceChatCenterPagination,
    instanceChatCenterPaginationLoading,
    replyInstanceMessageResp,
    replyInstanceMessageRespLoading,
  } = useSelector((state) => state.instanceMessageCenter);

  const { instanceChatAddResp, instanceChatAddRespLoading } = useSelector(
    (state) => state.instanceChatCenter
  );

  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [selectedGroupDataToBeUpdated, setGroupDataToBeUpdate] = useState();
  const [selectTosNewCutGroup, setSelectTosNewCutGroup] = useState();
  const [selectCcsNewCutGroup, setSelectCcsNewCutGroup] = useState();

  useEffect(() => {
    // if (AltEnt?.alt && AltEnt?.enter) {
    //   let newMailMessageCenterType = mailMessageCenterType.filter(
    //     (type) => type.systemName === "Normal"
    //   );
    //   onFinish(newMailMessageCenterType[0].systemName, false);
    // }
    // if (AltEnt?.length !== 0) {
    //   setTimeout(() => {
    //     setAltEnt([]);
    //   }, 5000);
    // }
    if (AltEnt) {
      const newMailMessageCenterTypo = mailMessageCenterType.filter(
        (type) => type.systemName === "Normal"
      );
      onFinish(newMailMessageCenterTypo?.[0].systemName);
      setAltEnt(false);
    }
  }, [AltEnt]);

  useEffect(() => {
    if (!isEmpty(users) && !isEmpty(replyMsgDetails)) {
      let fromEmailAddresss = users[0]?.appUserId; //.employeeEmailDtos[0]
      let replyFrom =
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
          .participantUserDto.appUserId;

      if (fromEmailAddresss === replyFrom) {
        setFilterParticipantForm(true);
      }
    }
  }, [users, replyMsgDetails]);

  useEffect(() => {
    if (isEmpty(messageParticipants)) {
      setMessageParticipants(allParticipants);
      setMessageParticipantsCC(allParticipants);
    }
  }, [allParticipants]);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      let participantFrom = storage.getItem("userId");
      let allUsers = resp.data.filter(
        (obj) => obj.appUserId == participantFrom
      );

      setUsers(allUsers);
    }
  };

  useEffect(() => {
    fetchAllParticipants();
  }, []);

  useEffect(() => {
    if (chatBox === true) {
      form.setFieldsValue({
        participantTos: selectedTaskTos,
        participantCCs: selectedTaskCcs,
      });
    }
  }, [chatBox]);

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgress,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
    });
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      setAttatchments(alteredResponse);
      let olduploadedFileDtos = form.getFieldValue().uploadedFileDtos
        ? [...form.getFieldValue().uploadedFileDtos]
        : [];

      let newUploadedFileDtos = [...olduploadedFileDtos];
      newUploadedFileDtos.push(alteredResponse);
      // uploadedFileDtos[i] = e;

      form.setFieldsValue({
        uploadedFileDtos: newUploadedFileDtos,
      });
      // onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  const changeParticipantsDtosTo = (option) => {
    let newQuickMessageParticipantDtos = option.map((participant) => ({
      participantUserId: participant.value,
    }));
    setQuickMessageParticipantDtosTo(newQuickMessageParticipantDtos);

    let newMessagePariticipants = [];
    allParticipants.map((participant) => {
      let checkSelected = false;
      newQuickMessageParticipantDtos.map((selectedParticipant) => {
        if (selectedParticipant.participantUserId === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipants.push(participant);
      }
    });

    setMessageParticipantsCC(newMessagePariticipants);
  };

  const changeParticipantsDtosCC = (option) => {
    let newQuickMessageParticipantDtos = option.map((participant) => ({
      participantUserId: participant.value,
    }));
    setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);

    let newMessagePariticipants = [];
    allParticipants.map((participant) => {
      let checkSelected = false;
      newQuickMessageParticipantDtos.map((selectedParticipant) => {
        if (selectedParticipant.participantUserId === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipants.push(participant);
      }
    });

    setMessageParticipants(newMessagePariticipants);
  };

  useEffect(() => {
    if (!isEmpty(mailMessageParticipationStatusListing)) {
      let newMailMessageCenterType =
        mailMessageParticipationStatusListing?.filter(
          (status) =>
            status.systemName === "Normal" ||
            status.systemName === "Pending" ||
            status.systemName === "Archived"
        );

      setMailMessageCenterType(newMailMessageCenterType);
    }
  }, [mailMessageParticipationStatusListing]);

  useEffect(() => {
    setchatBox(false);
  }, []);

  useEffect(() => {
    if (!isEmpty(instanceChatAddResp)) {
      setchatBox(false);
      setChatHeight(false);

      setTimeout(() => {
        form.resetFields();
        setEditorData(" ");
      }, 2000);
    }
  }, [instanceChatAddResp]);

  useEffect(() => {
    if (!isEmpty(replyInstanceMessageResp)) {
      setchatBox(false);
      setChatHeight(false);

      setTimeout(() => {
        form.resetFields();
        setEditorData(" ");
      }, 2000);
    }
  }, [replyInstanceMessageResp]);

  const onFinish = async (centerTypeStatus, msgCat) => {
    let values = await form.validateFields();
    let body = {
      ...(msgCat == "sms" && {
        messagePlainText: smsText,
        isComposeSMS: true,
      }),
      messageText: editorData,
      mailMessageParticipantWithTypeDtos: {
        participantTos: !isEmpty(form.getFieldValue().participantTos)
          ? quickMessageParticipantDtosTo
          : [],
        participantCCs: !isEmpty(form.getFieldValue().participantCCs)
          ? quickMessageParticipantDtosCC
          : [],
      },
      // applicationId: todoDetails?.id,
      applicationId: todoDetails?.id ? todoDetails?.id : taskId,
      applicationTypeId: applicationType,
      instanceMessageStatusName: centerTypeStatus,
      instanceMessageActionName: replyStatus
        ? replyStatus === "replyall"
          ? "reply"
          : replyStatus
        : "reply",
      // mailMessageReferenceDtos: [todoDetails?.referenceCode],
      mailMessageReferenceDtos: [{ referenceCode: todoDetails?.referenceCode }],
      replyOfMessageCenterId: replyMsgDetails ? replyMsgDetails.id : null,
      instanceMessageAttachmentDtos: values.uploadedFileDtos || [],
    };

    if (!isEmpty(instanceChatCenterPagination)) {
      body.mailMessageCenterId =
        instanceChatCenterPagination?.items[0]?.mailMessageCenterId;
    }

    let instanceMessageBody = {
      ...(msgCat == "sms" && {
        messagePlainText: smsText,
        isComposeSMS: true,
      }),
      messageText: editorData,
      mailMessageParticipantWithTypeDtos: {
        participantTos: !isEmpty(form.getFieldValue().participantTos)
          ? quickMessageParticipantDtosTo
          : [],
        participantCCs: !isEmpty(form.getFieldValue().participantCCs)
          ? quickMessageParticipantDtosCC
          : [],
      },
      // applicationId: todoDetails?.id,
      // internalChatId: todoDetails?.id ? todoDetails?.id : taskId,
      internalChatId: replyMsgDetails ? replyMsgDetails.id : null,
      applicationTypeId: applicationType,
      instanceMessageStatusName: centerTypeStatus,
      instanceMessageActionName: replyStatus
        ? replyStatus === "replyall"
          ? "reply"
          : replyStatus
        : "reply",
      // mailMessageReferenceDtos: [todoDetails?.referenceCode],
      mailMessageReferenceDtos: [{ referenceCode: todoDetails?.referenceCode }],
      replyOfInstanceMessageId: replyMsgDetails
        ? replyMsgDetails.replyOfInstanceMessageId
        : null,
      // replyOfInstanceMessageId: replyMsgDetails ? replyMsgDetails.id : null,
      mailMessageCenterId: replyMsgDetails
        ? replyMsgDetails?.mailMessageCenterId
        : null,
      instanceMessageAttachmentDtos: values.uploadedFileDtos || [],
      mailMessageParticipantTableId: replyMsgDetails
        ? replyMsgDetails?.mailMessageParticipantTableId
        : null,
    };

    let groupChatPayload = {
      ...instanceMessageBody,
      mailMessageCenterId: taskId,
    };

    if (module === "GroupChat") {
      // console.log("GroupChat MODULE", groupChatPayload)
      // dispatch(replyInstanceMessage(instanceMessageBody));
      dispatch(replyInstanceMessage(groupChatPayload));
    } else if (module === "DailyTask") {
      let dailyPayload = {
        ...body,
        applicationId: todoDetails?.todoEventId,
      };
      // console.log("dailyPayload: ", dailyPayload);
      dispatch(instanceChatAdd(body));
    } else {
      // console.log("GroupChat xxxxx");
      dispatch(instanceChatAdd(body));
    }
    // dispatch(instanceChatAdd(body));
  };

  useEffect(() => {
    if (replyStatus === "") {
      form.setFieldsValue({ participantTos: [] });
    }
  }, [replyStatus]);

  useEffect(() => {
    if (replyStatus) {
      form.resetFields();
      setchatBox(true);
      let userId = storage.getItem("userId");
      let participantTypeTos =
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantTos.map(
          (typeTos) => {
            return { participantUserId: typeTos.participantUserId };
          }
        );
      let participantTypeCCs = !isEmpty(
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantCCs
      )
        ? replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantCCs.map(
            (typeTos) => {
              return { participantUserId: typeTos.participantUserId };
            }
          )
        : [];

      let participantTypeFrom = [
        {
          participantUserId:
            replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
              .participantUserId,
        },
      ];

      let filterParticipantTos = participantTypeFrom;
      let filterParticipantCcs = participantTypeCCs.filter(
        (filteredCcs) => filteredCcs.participantUserId !== userId
      );
      let newQuickMessageParticipantDtos = participantTypeTos.filter(
        (filterTos) => filterTos.participantUserId !== userId
      );
      newQuickMessageParticipantDtos =
        newQuickMessageParticipantDtos.concat(filterParticipantCcs);

      if (
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
          .participantUserId == userId
      ) {
        //All the To and CC Remains the same
        filterParticipantTos = participantTypeTos.filter(
          (filterTos) => filterTos.participantUserId !== userId
        );
        let filterParticipantCcs = participantTypeCCs.filter(
          (filteredCcs) => filteredCcs.participantUserId !== userId
        );
        newQuickMessageParticipantDtos = filterParticipantCcs;
      }

      if (replyStatus !== "edit" && replyStatus !== "forward") {
        form.setFieldsValue({
          participantTos: filterParticipantTos.map((typeTos) => {
            return typeTos.participantUserId;
          }),

          // participantCCs: newQuickMessageParticipantDtos.map((typeTos) => {
          //   return typeTos.participantUserId;
          // }),
        });

        setQuickMessageParticipantDtosTo(filterParticipantTos);
        // setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);
      }
      if (replyStatus === "replyall") {
        form.setFieldsValue({
          participantCCs: newQuickMessageParticipantDtos.map((typeTos) => {
            return typeTos.participantUserId;
          }),
        });

        setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);
      } else if (replyStatus === "forward") {
        form.setFieldsValue({
          participantTos: [],
          participantCCs: [],
        });
      } else if (replyStatus === "edit") {
        form.setFieldsValue({
          participantTos: participantTypeTos.map((typeTos) => {
            return typeTos.participantUserId;
          }),
          participantCCs: !isEmpty(participantTypeCCs)
            ? participantTypeCCs.map((typeTos) => {
                return typeTos.participantUserId;
              })
            : [],
        });

        setQuickMessageParticipantDtosTo(participantTypeTos);
        setQuickMessageParticipantDtosCC(participantTypeCCs);
      }
      if (replyStatus === "forward" || replyStatus === "edit") {
        let data =
          replyStatus === "forward"
            ? `This is forwaded message of ${participantTypeFrom?.participantUserDto?.userName} \n ------- ${replyMsgDetails.messageText}`
            : replyMsgDetails.messageText;
        setEditorData(data);
      } else {
        setEditorData("");
      }
    } else if (!isEmpty(todoDetails)) {
      setEditorData("");
      // let todoAssignCandidate = todoDetails?.todoEventCandidateDtos?.filter(
      //   (candidate) => candidate.isPrimary === true
      // );
      // let todoWatcherCandidate = todoDetails?.todoEventCandidateDtos?.filter(
      //   (candidate) => candidate.isWatcher === true
      // );
      // let newQuickMessageParticipantDtos = todoAssignCandidate?.map(
      //   (participant) => ({
      //     participantUserId: participant.candidateId,
      //   })
      // );
      // setQuickMessageParticipantDtosTo(newQuickMessageParticipantDtos);
      // let newQuickMessageParticipantCcDtos = todoWatcherCandidate?.map(
      //   (participant) => ({
      //     participantUserId: participant.candidateId,
      //   })
      // );
      // setQuickMessageParticipantDtosCC(newQuickMessageParticipantCcDtos);

      // form.setFieldsValue({
      //   participantTos: todoAssignCandidate?.map(
      //     (assigne) => assigne.candidateId
      //   ),
      //   participantCCs: todoWatcherCandidate?.map(
      //     (watcher) => watcher.candidateId
      //   ),
      // });
    }
  }, [replyMsgDetails, replyStatus, filterParticipantForm, todoDetails]);

  const centerTypeMenu = () => (
    <Menu>
      <Menu.Item>AsPending</Menu.Item>
      <Menu.Item>AsArchieved</Menu.Item>
    </Menu>
  );

  const menu = () => (
    <Menu>
      <Menu.Item
        key="Send as SMS"
        onClick={() => {
          let newMailMessageCenterType = mailMessageCenterType.filter(
            (type) => type.systemName === "Normal"
          );
          onFinish(newMailMessageCenterType[0].systemName, "sms");
        }}
      >
        Send as SMS
      </Menu.Item>
    </Menu>
  );

  //Predefined User Data

  const menuGroupCreate = () => (
    <Menu style={{ maxHeight: 250, overflow: "auto" }}>
      <Menu.Item
        key="Create Group"
        onClick={() => {
          setCreateGroupModal(true);
          setGroupDataToBeUpdate();
          form4.resetFields();
        }}
      >
        Create Group
      </Menu.Item>

      {groupData &&
        groupData?.map((x) => {
          return (
            <Menu.Item
              key={x?.id}
              onClick={() => {
                let userIdsForTo = [];
                let userIdsForCc = [];
                x?.userList?.map((x) => {
                  if (x?.participantTypeDto?.id === 0) {
                    userIdsForTo.push(x?.userId);
                  } else {
                    userIdsForCc.push(x?.userId);
                  }
                });
                setSelectTosNewCutGroup(userIdsForTo);
                setSelectCcsNewCutGroup(userIdsForCc);
              }}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{x?.groupName}</div>

              <EditOutlined
                style={{ paddingLeft: 15 }}
                onClick={() => {
                  setGroupDataToBeUpdate(x);
                  setCreateGroupModal(true);
                }}
              />
            </Menu.Item>
          );
        })}
    </Menu>
  );
  let paramsForGroupListing = "SkipCount=0&MaxResultCount=100";
  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, []);

  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, [selectedGroupDataToBeUpdated, createGroupModal]);

  useEffect(() => {
    if (!isEmpty(selectTosNewCutGroup)) {
      let participantTos = selectTosNewCutGroup?.map((x) => {
        return {
          participantUserId: x,
        };
      });
      let participantCcs = selectCcsNewCutGroup?.map((x) => {
        return {
          participantUserId: x,
        };
      });
      setQuickMessageParticipantDtosTo(participantTos);
      setQuickMessageParticipantDtosCC(participantCcs);
      form.setFieldsValue({
        participantTos: selectTosNewCutGroup,
        participantCCs: selectCcsNewCutGroup,
      });
    } else {
      form.setFieldsValue({
        participantTos: [],
        participantCCs: [],
      });
    }
  }, [selectTosNewCutGroup]);

  useEffect(() => {
    if (!isEmpty(selectedGroupDataToBeUpdated)) {
      let userIdsForTo = [];
      let userIdsForCc = [];
      selectedGroupDataToBeUpdated?.userList?.map((x) => {
        if (x?.participantTypeDto?.id === 0) {
          userIdsForTo.push(x?.userId);
        } else {
          userIdsForCc.push(x?.userId);
        }
      });
      form4.setFieldsValue({
        groupName: selectedGroupDataToBeUpdated?.groupName,
        userListTo: userIdsForTo,
        userListCc: userIdsForCc,
      });
    }

    if (groupDataLoadingSuccess) {
      setCreateGroupModal(false);
      setSelectTosNewCutGroup();
    }
  });

  const onGroupCreateFinish = (values) => {
    const tos = values?.userListTo?.map((x) => {
      return {
        userId: x,
        participantTypeId: 0,
      };
    });
    const ccs = values?.userListCc?.map((x) => {
      return {
        userId: x,
        participantTypeId: 1,
      };
    });
    const to_cc = [...tos, ...ccs];

    let apiDataForGroupCreation = {
      groupName: values?.groupName,
      userList: to_cc,
      isActive: true,
    };

    if (isEmpty(selectedGroupDataToBeUpdated)) {
      dispatch(actionForCreateGroup(apiDataForGroupCreation));
    } else {
      apiDataForGroupCreation = {
        ...apiDataForGroupCreation,
        id: selectedGroupDataToBeUpdated?.id,
      };
      dispatch(
        actionForUpdateGroup(
          apiDataForGroupCreation,
          selectedGroupDataToBeUpdated?.id
        )
      );
    }
  };

  const handleEditorFocus = () => {
    let data = editorData?.replace(par, "");
    if (replyStatus !== "reply" && replyStatus !== "replyall") {
      let participantsName = "";
      messageParticipants
        .filter((obj) =>
          form.getFieldsValue().participantTos?.includes(obj.appUserId)
        )
        .map((x, index) => {
          participantsName =
            participantsName +
            x?.userName
              ?.toUpperCase()
              .concat(
                index + 1 === form.getFieldsValue().participantTos?.length
                  ? ":"
                  : "/"
              );
        });
      setPar(participantsName);
      editorRef.current.insertContent(participantsName);
      setEditorData(participantsName + data);
    }
    editorRef.current.selection.setCursorLocation(
      editorRef.current.getBody(),
      1
    );
  };

  return (
    <>
      {chatBox ? (
        <div
          className={`chat--messageBox email_internal_chatbox ${
            smallChatHeight && "smallChatHeight"
          }`}
        >
          <div className="reply-Of chat-box-head-wrapper">
            <div className="left-chat-action-btn xxxx">
              <div className="chat-box-send-wrapper">
                {instanceChatAddRespLoading ||
                replyInstanceMessageRespLoading ? (
                  antIcon
                ) : (
                  <Space
                    // <Dropdown.Button
                    overlay={centerTypeMenu}
                    //Is causing message to be delivered when any message is clicked--Aakash
                    // onClick={() => {
                    //   let newMailMessageCenterType =
                    //     mailMessageCenterType.filter(
                    //       (type) => type.systemName === "Normal"
                    //     );
                    //   onFinish(newMailMessageCenterType[0].systemName);
                    // }}
                  >
                    <Layout.Content
                      overlay={centerTypeMenu}
                      onClick={() => {
                        let newMailMessageCenterType =
                          mailMessageCenterType.filter(
                            (type) => type.systemName === "Normal"
                          );
                        onFinish(newMailMessageCenterType[0].systemName);
                      }}
                    >
                      <img src={send} alt="" />
                    </Layout.Content>

                    <Dropdown.Button
                      overlay={menu}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    ></Dropdown.Button>
                    <Dropdown.Button
                      overlay={menuGroupCreate}
                      icon={<UsergroupAddOutlined />}
                    ></Dropdown.Button>
                  </Space>
                )}
              </div>
            </div>
            <div className="chat-action-btn">
              {/* <div className="chat-box-editor-header-btn-wrapper">
            <div
              className="btn__chat-editor-header"
              onClick={() => {
                setEditorHeader(!showEditorHeader);
              }}
            >
              <img src={aa} />
            </div>
          </div> */}
              <div className="chat-box-attachment-bth-wrapper">
                <div className="attachment-img-wrapper">
                  <Upload
                    name="uploadedFiles"
                    itemRender={(files) => {
                      <div style={{ display: "none" }}>{files}</div>;
                    }}
                    action={`${appBaseUrl}/file/uploadFile/`}
                    customRequest={uploadPhotos}
                  >
                    <img src={attachment} />
                  </Upload>
                </div>
              </div>
              <div
                className="btn__chat--cancel"
                onClick={() => {
                  setchatBox(false);
                  setChatHeight(false);
                  setReplyStatus("");
                  //   setReplyofChat("");
                  //   setReplyStatus("");
                  //   setReplyChatHeight(false);
                  //   setActiveChat();
                  //   setSmallChatHeight(false);
                }}
              >
                <Tooltip title="close">
                  <img src={cancel} />
                </Tooltip>
              </div>
              {origin !== "Dashboard" && (
                <div
                  className={`btn__chat--upChervon`}
                  // onClick={() => {
                  //   setReplyChatHeight(!replyChatheight);
                  //   setActiveChat("");
                  //   setSmallChatHeight(replyChatheight ? true : false);
                  // }}
                  onClick={() => {
                    // chatHeight ? setChatHeight(false) : setChatHeight(true);
                    // console.log("Hey");
                    setChatHeight(!chatHeight);
                    setSmallChatHeight(!smallChatHeight);
                  }}
                >
                  <img src={upChervon} />
                </div>
              )}
            </div>
          </div>
          <Form
            name="replyChatBox"
            onFinish={onFinish}
            onFinishFailed={(info) => console.log(info)}
            autoComplete="off"
            form={form}
          >
            <div
            //   className={`replyChatHeight ${replyChatheight ? "active" : ""} `}
            >
              <Col span={24}>
                <div className="instant-msg-box">
                  <div className="box-type">To</div>
                  <Form.Item
                    className="form__group"
                    name={`participantTos`}
                    rules={[{ required: true, message: "" }]}
                    style={{ width: "100%" }}
                  >
                    <Select
                      optionFilterProp="label"
                      bordered={false}
                      mode="multiple"
                      autoFocus={true}
                      placeholder={`Internal To`}
                      onChange={(value, option) =>
                        changeParticipantsDtosTo(option)
                      }
                    >
                      {messageParticipants?.map((participant, j) => (
                        <Option
                          key={j}
                          value={participant.appUserId}
                          label={[participant.fullName, participant.userName]}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">
                              {participant.fullName} {"" + ""}
                            </span>
                            [{participant.userName.toUpperCase()}]
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className="instant-msg-box">
                  <div className="box-type">Cc</div>
                  <Form.Item
                    className="form__group"
                    name={`participantCCs`}
                    rules={[{ required: false }]}
                    style={{ width: "100%" }}
                  >
                    <Select
                      bordered={false}
                      mode="multiple"
                      placeholder={`Internal CC`}
                      onChange={(value, option) =>
                        changeParticipantsDtosCC(option)
                      }
                      optionFilterProp="label"
                    >
                      {messageParticipantsCC?.map((participant, j) => (
                        <Option
                          key={j}
                          value={participant.appUserId}
                          label={[participant.fullName, participant.userName]}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">{participant.fullName}</span>[
                            {participant.userName}]
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col>
                {!isEmpty(form.getFieldValue().uploadedFileDtos) ? (
                  <div className="instant-msg-box">
                    {/* <div className="box-type">
                      <img src={attachment} />
                    </div> */}
                    {progress > 0 ? <Progress percent={progress} /> : null}
                    <div className="uploadImg" style={{ width: "87%" }}>
                      <Form.Item
                        name={["uploadedFileDtos"]}
                        className="form__group"
                        // name={u.id}
                        // label={u.name}
                        rules={[{ required: false }]}
                      >
                        <ImageUploaderMultiple
                          name="emailMessageAttachmentDtos"
                          isMultipleFileAllowed={true}
                          id=""
                          uploadedFileDtos={form.getFieldValue()}
                          formName="uploadedFileDtos"
                          form={form}
                        />
                      </Form.Item>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col span={24}>
                {/* <Form.Item name="description" className="form__group" label="Message"> */}
                {/* <Editor
                initialValue="<p>This is the initial content of the editor</p>"
                apiKey="zlyurpa97iti4svx0l8rc4nwgnqpm0s9459csq8ic2bxuo8f"
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help'
                }}
                onEditorChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorData(data);
                }}
              /> */}

                <div
                  className={`replyChatBox`}
                  style={{ position: "relative", width: "100%" }}
                >
                  <Editor
                    value={editorData}
                    // toolbar_location="bottom"
                    onMouseEnter={() => setIsFocused(false)}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    init={{
                      plugins: "lists advlist",
                      browser_spellcheck: true,
                      menubar: "false",
                      toolbar: "", //bold  italic underLine  | backcolor forecolor   |  bullist numlist

                      content_style: "p{margin:0px}",
                    }}
                    onKeyDown={(e) => {
                      const { key, altKey } = e;
                      if (altKey && key === "Enter") {
                        setAltEnt(true);
                      }
                      // let bbb = {};
                      // if (e.key === "Alt") {
                      //   bbb = {
                      //     ...AltEnt,
                      //     alt: e.key,
                      //   };
                      //   setAltEnt(bbb);
                      // }
                      // if (e.key === "Enter") {
                      //   bbb = {
                      //     ...AltEnt,
                      //     enter: e.key,
                      //   };
                      //   setAltEnt(bbb);
                      // }
                    }}
                    onFocus={handleEditorFocus}
                    onEditorChange={(e, editor) => {
                      setEditorData(e);
                      const plainText = editor.getContent({ format: "text" });
                      setSMSText(plainText);
                    }}
                  />
                </div>
              </Col>
            </div>
          </Form>
        </div>
      ) : (
        <div
          className="chatmail__imag chat-button-wrapper"
          style={{
            position: "absolute",
            bottom: 0,
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            height: "15%",
            background: "#fff",
            width: "100%",
            display: "none",
          }}
        ></div>
      )}
      <ModalForGroupCreateUpdate
        createGroupModal={createGroupModal}
        setCreateGroupModal={setCreateGroupModal}
        selectedGroupDataToBeUpdated={selectedGroupDataToBeUpdated}
        onGroupCreateFinish={onGroupCreateFinish}
        form4={form4}
        messageParticipants={messageParticipants}
        groupDataLoading={groupDataLoading}
      />
    </>
  );
};

export default ReplyChatBoxGlobal;
