import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService, putService } from "services/commonServices";
import { isEmpty } from "lodash";

export class WhitelistIpService {
    getWhiteAccessGetListingIpAddress() {
        let url = `${appBaseUrl}/attendance/whiteAccessGetListingIpAddress`;
        let data = getService(url);
        return data;
    }

    addWhiteAccessCreateIpAddress(body) {
        let url = `${appBaseUrl}/attendance/whiteAccessCreateIpAddress`;
        let data = postService(url, body);
        return data;
    }

    updateWhiteAccessUpdateIpAddress(id, body) {
        let url = `${appBaseUrl}/attendance/whiteAccessUpdateIpAddress/${id}`;
        let data = putService(url, body);
        return data;
    }
}