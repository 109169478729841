import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { LogoImage } from "utils/imageConstants";
import { isEmpty } from "lodash-es";
import getColumns from "./Columns";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";

const LoanTable = ({
  loanApplicationPagination,
  loanApplicationPaginationLoading,
  onOpenLoanApplicationDrawer,
  setSelectedLoanApp,
  setLoanDetailsId,
  setShowLoanDetails,
  setHideLoanDrawer,
  loanDetailsId,
}) => {
  const columns = [
    {
      title: "S.NO",
      dataIndex: "",
      key: "",
      render: (text, row, index) => <div>{index + 1}</div>,
      width: "5%",
    },
    {
      title: "Loan Application Date",
      key: "loanApplicationDate",
      render: (record) => (
        <span>{moment(record.creationTime).format("YYYY/MM/DD")}</span>
      ),
    },
    {
      title: "Company Name",
      key: "companyName",
      render: (record) => <span>{record?.sisterCompanyDto?.name}</span>,
    },
    {
      title: "Amount Requested",
      dataIndex: "requestedAmount",
      key: "requestedAmount",
    },
    {
      title: "Application Status",
      // dataIndex: 'loanApplicationStatusDtos',
      key: "applicationStatus",
      render: (record) => (
        <span className="application_status_name">
          {record?.loanApplicationStatusDtos?.displayName}
        </span>
      ),
    },
    {
      title: "Approved Amount",
      dataIndex: "approvedAmount",
      key: "approvedAmount",
      render: (record) => <span>{record}</span>,
      width: "10%",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: "25%",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Button
          htmlType="submit"
          style={{ border: 0, backgroundColor: "transparent" }}
          icon={<EyeOutlined />}
          onClick={() => {
            console.log("ID CONSOLE", record?.id);
            setHideLoanDrawer(true);
            setShowLoanDetails(true);
            setLoanDetailsId(record?.id);
          }}
        />
      ),
    },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!isEmpty(loanApplicationPagination?.items)) {
      setData(loanApplicationPagination?.items);
    }
  }, [loanApplicationPagination]);
  //   const [totalCount, setTotalCount] = useState(10);
  //   const pageSize = 10;

  //   useEffect(() => {
  //     setData(allRoleLists.items)
  //   }, [allRoleLists]);

  return (
    <Table
      className="organizer__table contact-table"
      columns={columns}
      rowKey="appUserId"
      dataSource={data}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" alt="loader" />
        ),
        spinning: loanApplicationPaginationLoading,
      }}
    />
  );
};

export default LoanTable;
