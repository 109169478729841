import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Input, Form, Drawer, Button, Select, Checkbox } from "antd";
import { fromPairs, isEmpty, values } from "lodash";
import {
  questionTypeAdd,
  questionTypeList,
} from "services/redux/profile/profile.action";

const InterviewTemplateAddUpdateForm = ({
  openForm,
  setOpenForm,
  updateData,
  setUpdateData,
}) => {
  const dispatch = useDispatch();
  const { Header } = Layout;
  const [form] = Form.useForm();
  const { Option } = Select;

  const {
    questionTypeResponse,
    questionTypeAddResponse,
    questionTypeAddLoading,
  } = useSelector((state) => state.profile);

  const [questionTypeData, setQuestionTypeData] = useState([]);
  const [questionTypeId, setQuestionTypeId] = useState();

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        question: updateData?.question,
        isActive: updateData?.isActive,
        selectQuestion: updateData?.questionTypeId,
        questionTypeId: updateData?.questionTypeId,
      });
    }
  }, [updateData]);

  useEffect(() => {
    if (questionTypeAddResponse) {
      setOpenForm({ drawer: false });
      form.resetFields();
      setUpdateData(null);
    }
  }, [questionTypeAddResponse]);

  useEffect(() => {
    dispatch(questionTypeList());
  }, []);

  useEffect(() => {
    setQuestionTypeData(questionTypeResponse);
  }, [dispatch, questionTypeResponse]);

  const handleChange = (value) => {
    if (value) {
      setQuestionTypeId(value);
    }
  };

  const onFinish = (values) => {
    let value = {};
    if (updateData) {
      value = {
        id: updateData.id,
        question: values.question,
        // questionTypeId: updateData?.questionTypeId,
        questionTypeId: questionTypeId,
        isActive: updateData?.isActive,
      };
      dispatch(questionTypeAdd(value, "update"));
      form.resetFields();
      setUpdateData(null);
    } else {
      value = {
        question: values.question,
        questionTypeId: questionTypeId,
        isActive: true,
      };
      dispatch(questionTypeAdd(value, "add"));
    }
    form.resetFields();
    setUpdateData(null);
  };

  const handleDelete = () => {
    const value = {
      id: updateData.id,
      question: updateData.question,
      questionTypeId: updateData.questionTypeId,
      isActive: false,
    };
    dispatch(questionTypeAdd(value, "delete"));
  };

  const handleCancel = () => {
    setOpenForm(false);
    form.resetFields();
    setUpdateData(null);
  };

  return (
    <>
      <Drawer
        visible={openForm.drawer}
        width={551}
        maskClosable={false}
        className="drawer"
        closable={false}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">
            {`${updateData ? "Update " : "Add "}Interview Question`}
          </h4>
        </Header>
        <Form
          form={form}
          className="drawer-form-common-wrapper"
          onFinish={onFinish}
        >
          <div className="drawer-form-row">
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="question"
                label="Interview Question"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                className="form__group"
                name="selectQuestion"
                label="Question Type"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select onChange={handleChange}>
                  {questionTypeData
                    ? questionTypeData.map((data) => (
                        <Option key={data.id} value={data.id}>
                          {data.displayName}
                        </Option>
                      ))
                    : ""}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div
            className="model-footer"
            style={{ justifyContent: "space-between", padding: "0 9px" }}
          >
            {openForm?.edit ? (
              <>
                <Form.Item>
                  <Button className="" onClick={handleDelete} danger>
                    Delete
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button onClick={handleCancel} style={{ marginRight: "4px" }}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </>
            ) : (
              <>
                <span></span>
                <Form.Item>
                  <Button onClick={handleCancel} style={{ marginRight: "4px" }}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={questionTypeAddLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </>
            )}
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default InterviewTemplateAddUpdateForm;
