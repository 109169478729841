import { CIRCULAR_MAIL_TYPES } from "services/types";
import { jsonParser } from "utils/misc";

const INITIAL_STATE = {
  circularMailLoading: false,
  circularMailResp: [],
  tenderSelectedForMailData:
    jsonParser(
      jsonParser(sessionStorage.getItem("persist:root"))?.mailMessagePeristState
    )?.tenderSelectedForMailData || {},
  tenderSelectedForMailDataLoading: false,
};

export default function circularMailReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // CIRCULAR MAIL
    case CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL_INIT:
      return {
        ...state,
        circularMailLoading: true,
      };

    case CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL_SUCCESS:
      return {
        ...state,
        circularMailResp: action.payload,
        circularMailLoading: false,
      };

    case CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL_UPDATE:
      let updatedItems =
        state?.circularMailResp &&
        state?.circularMailResp?.map((item) => {
          if (action?.payload?.id === item?.id) {
            return {
              ...action.payload,
            };
          } else {
            return {
              ...item,
            };
          }
        });

      return {
        ...state,
        circularMailLoading: false,
        circularMailResp: updatedItems,
      };

    case CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL_PARTIAL:
      let updatedTiedStatus =
        state?.circularMailResp &&
        state?.circularMailResp?.map((item) => {
          if (action.payload.id === item.id) {
            return {
              ...item,
              tiedStatus: action?.payload?.tiedStatus,
            };
          } else {
            return {
              ...item,
            };
          }
        });

      return {
        ...state,
        circularMailLoading: false,
        circularMailResp: updatedTiedStatus,
      };

    case CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL_FINISH:
      return {
        ...state,
        circularMailLoading: false,
      };

    case CIRCULAR_MAIL_TYPES.PERSIST_TENDER_SELECTED_MAIL_INIT:
      return {
        ...state,
        tenderSelectedForMailData: [],
        tenderSelectedForMailDataLoading: true,
      };

    case CIRCULAR_MAIL_TYPES.PERSIST_TENDER_SELECTED_MAIL_SUCCESS:
      return {
        ...state,
        tenderSelectedForMailData: action?.payload,
        tenderSelectedForMailDataLoading: false,
      };

    case CIRCULAR_MAIL_TYPES.PERSIST_TENDER_SELECTED_MAIL_FINISH:
      return {
        ...state,
        tenderSelectedForMailDataLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
