import { ORGANIZATION_TYPES } from "../../../types";

const INITIAL_STATE = {
    organizationTypeAddResponse: null,
    organizationTypeAddResponseLoading: false,
};

export default function organizationTypeReducer(state = INITIAL_STATE, action) {
    switch (action.type){
        case ORGANIZATION_TYPES.ORGANIZATION_TYPE_ADD_INIT:
            return {
                ...state,
                organizationTypeAddResponse: null,
                organizationTypeAddResponseLoading: true,
            };
        case ORGANIZATION_TYPES.ORGANIZATION_TYPE_ADD_SUCCESS:
            return {
                ...state,
                organizationTypeAddResponse: action.payload,
                organizationTypeAddResponseLoading: false,
            }
        case ORGANIZATION_TYPES.ORGANIZATION_TYPE_ADD_FINISH:
            return {
                organizationTypeAddResponse: null,
                organizationTypeAddResponseLoading: false,
            }
        case ORGANIZATION_TYPES.ORGANIZATION_PAGINATION_INIT:
                return {
                    orginizationLayerResponse: null,
                    orginizationLayerLoading: true,
                };
        case ORGANIZATION_TYPES.ORGANIZATION_PAGINATION_SUCCESS:
                return {
                    ...state,
                    orginizationLayerResponse: action.payload,
                    orginizationLayerLoading: false,
                };
        case ORGANIZATION_TYPES.ORGANIZATION_PAGINATION_FINISH:
                return {
                    orginizationLayerResponse: null,
                    orginizationLayerLoading:false,
                };
        default: 
        return {
            ...state,
        };
    };
};