import { Sider, Row, Button, Menu } from "antd";
import React from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";

export const ChildNav = ({ children, ...props }) => {
  const history = useHistory();
  return (
    <>
      {/* <Sider trigger={null} collapsible collapsed={false}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="1">nav 1</Menu.Item>
          <Menu.Item key="2">nav 2</Menu.Item>
          <Menu.Item key="3">nav 3</Menu.Item>
        </Menu>
      </Sider> */}
      {children}
    </>
  );
};

function getDisplayName(Component) {
  return Component.displayName || Component.name || "Component";
}

export const withBaseLayout = (Component, config) => (props) => {
  const history = useHistory();
  const viewName = getDisplayName(Component);
  return (
    <div className="dashboard">
      <Navbar {...config} view={viewName} />

      <div className="container">
        {config?.canGoBack && (
          <Button onClick={() => history.goBack()}>Go Back</Button>
        )}
        <Component {...props} />
      </div>
    </div>
  );
};
