import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// antd

import { Button, Row, Col, message, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import HolidayEventAddUpdateTable from "./HolidayEventTable";
import HolidayEventAddUpdateDrawer from "./HolidayEventDrawer";
import HolidayWithSampleButton from "./HolidayWithSampleButton";
import moment from 'moment';
const { RangePicker } = DatePicker;
const HolidayEventAddUpdate = () => {
    const dispatch = useDispatch();
    const [addUpdateCountryDrawerVisibility, setAddUpdateCountryDrawerVisibility] = useState(false);
    const [
      updateProfileDrawerVisibility,
      setUpdateProfileDrawerVisibility,
    ] = useState(false);
    const [showNoticeBoard, setShowNoticeBoard] = useState(false);
    const [startDate,setStartDate] = useState('');
    const [isUpdateCountry, setIsUpdateCountry] = useState(false);
    const [endDate,setEndDate] = useState('');
    const onCloseNoticeBoard = () =>{
        setShowNoticeBoard(false);
      }
  const showAddUpdateCountryDrawer = (e, record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      // console.log(record,"testRecord")
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };
  // console.log(isUpdateCountry,"showAddUpdateCountryDrawer");
  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry('');
    setAddUpdateCountryDrawerVisibility(false);
  };
  return (
    <div className='layout'>
    <div className="organizer honorRoll">
    <div className="global-header-wrapper">
        <div className="header-info">
          <h3 className='header__all '>Holiday Event</h3>
        </div>
        <div className="header-action-wrapper">
          {/* <RangePicker
            onChange={(value) => {
              setStartDate(value ? moment(value[0]).format('YYYY-MM-DD') : '');
              setEndDate(value ? moment(value[1]).format('YYYY-MM-DD') : '')
            }}
         /> */}
         <HolidayWithSampleButton/>
          <Button
            className='btn-primary'
          onClick={showAddUpdateCountryDrawer}
          >
            <PlusOutlined /> New Holiday Event
          </Button>
        </div>
      </div>
    <HolidayEventAddUpdateDrawer
    drawerVisibility={addUpdateCountryDrawerVisibility}
    isUpdateCountry={isUpdateCountry}
    onAddUpdateCountryClose={onAddUpdateCountryClose}
    onCloseNoticeBoard={onCloseNoticeBoard}
    />
    <HolidayEventAddUpdateTable
    showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
    // isUpdateCountry={isUpdateCountry}
    startDate={startDate}
    endDate={endDate}
    />
    </div>
  </div>
  )
}

export default HolidayEventAddUpdate
