import React, { useEffect, useRef, useState } from "react";
import {
  // Image,
  // Row,
  // Col,
  // Modal,
  // Button,
  // Tooltip,
  // Menu,
  Dropdown,
  // message,
  Typography,
  // Divider,
} from "antd";

import { ArrowDownOutlined, BellOutlined } from "@ant-design/icons";
import moment from "moment";
import { getParticipantsEmails } from "utils/commonFunc";
import { cqBaseUrl } from "utils/config";
import storage from "utils/storage";
// import { MessageApplicationTypeListing } from "services/remanentCalls";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { changeEmailStatusType } from "services/redux/email/mailMessageCenter/action";
// import { changeEmailStatusTypeInstanceMessage } from "services/redux/email/instanceMessageCenter/action";
import {
  changeEmailStatusTypeInstanceMessageBusiness,
  changeEmailStatusTypeInstanceMessageBusinessTask,
} from "services/redux/instanceChat/action";
// const internalMessageGreen = "assets/svg/internal-msg-12-green.svg";
const Reply = "assets/svg/reply.svg";
const ReplyAll = "assets/svg/replyAll.svg";
const Forward = "assets/svg/next.svg";
const attachment = "assets/svg/attatchment.svg";
const archieve = "./assets/svg/archive-12.svg";

const BusinessChat = (props) => {
  const dispatch = useDispatch();

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  const [messageCenterTypeName, setMessageCenterTypeName] = useState(
    selectedMailTypeAndMailData?.typeSystemName || ""
  );

  const {
    handleReply,
    // handleChangeEvent,
    // setHideInternalMessage,
    // hideInternalMessage,
    // setShowChat,
    // setSmallChatHeight,
    setChatHeight,
    chatHeight,
    smallChatHeight,
    // setchatBox,
    // chatBox,
    // mailMessageParticipationStatusListing,
  } = props;
  const [instanceChatList, setInsatanceChatList] = useState();
  const instanceChatCenter = useSelector((state) => state.instanceChatCenter);
  const currentUserId = storage.getItem("userId");
  const { token } = useSelector((state) => state.auth);

  const [showAllAttachments, setShowAllAttachments] = useState(false);
  const { instanceChatCenterPagination, instanceChatCenterPaginationLoading } =
    instanceChatCenter;

  useEffect(() => {
    if (!isEmpty(instanceChatCenterPagination?.items)) {
      // setInsatanceChatList(instanceChatCenterPagination?.items);
      setInsatanceChatList(
        instanceChatCenterPagination?.items
          .map((x) => {
            return {
              ...x,
              creationTime: new Date(x?.creationTime),
            };
          })
          .sort((a, b) => b.creationTime - a.creationTime)
      );
    } else {
      setInsatanceChatList([]);
    }
  }, [instanceChatCenterPagination]);

  const showAttatchments = (msg, instant) => {
    const menu = (
      <div className={`dropdown-attatchmemnt here ${instant}`}>
        {!isEmpty(msg?.instanceMessageAttachmentDtos) &&
          msg?.instanceMessageAttachmentDtos.map((fileList, i) => {
            const fileType = fileList.displayFileName.split(".").pop();
            return (
              <div key={i} className="attachmentItem">
                <img
                  src={attachment}
                  style={{ height: "auto", width: "auto", paddingRight: 8 }}
                  alt=""
                />
                {fileType === "pdf" ? (
                  <a
                    onClick={() => {
                      if (fileType === "pdf") {
                        window.open(
                          `${cqBaseUrl}/${fileList?.viewFileURL}${token}`,
                          "_blank",
                          "top=50,left=200,frame=true"
                        );
                      } else {
                        // dispatch(
                        //   setSelectedFileName(
                        //     `${cqBaseUrl}/${fileList?.viewFileURL}${token}`
                        //   )
                        // );
                        // let dirname = electron.commonApi.getDirName();
                        // window.open(`file://${dirname}/index.html#/viewFile`, '_blank', 'top=50,left=200,frame=true');
                      }
                    }}
                  >
                    {fileList.displayFileName}
                  </a>
                ) : (
                  <a
                    target="_blank"
                    rel="opener noreferrer"
                    href={`${cqBaseUrl}/${fileList?.viewFileURL}${token}`}
                    download
                  >
                    {fileList.displayFileName}
                  </a>
                )}
                <ArrowDownOutlined className="attach-download-icon" />
              </div>
            );
          })}
      </div>
    );

    return (
      <div>
        <Dropdown overlay={menu}>
          <a onClick={(e) => e.preventDefault()}>
            <span>
              <img
                src="/assets/svg/chervon_down.svg"
                style={{ height: "auto", width: "auto" }}
              />
            </span>
          </a>
        </Dropdown>
      </div>
    );
  };

  const getAckUnAckLabel = (msg) => {
    let toAck = msg?.mailMessageParticipantWithTypeDtos?.participantTos?.find(
      (x) => {
        return (
          x?.participantUserId == currentUserId && x?.isAcknowledged === true
        );
      }
    );
    let ccAck = msg?.mailMessageParticipantWithTypeDtos?.participantCCs?.find(
      (x) => {
        return (
          x?.participantUserId == currentUserId && x?.isAcknowledged === true
        );
      }
    );

    return !isEmpty(toAck) === true
      ? "UnAck Msg"
      : !isEmpty(ccAck) === true
      ? "UnAck Msg"
      : "Ack Msg";
  };

  const changeEmailStatus = async (msg, emailCat) => {
    let toAck = msg?.mailMessageParticipantWithTypeDtos?.participantTos?.find(
      (x) => {
        return (
          x?.participantUserId == currentUserId && x?.isAcknowledged === true
        );
      }
    );
    let ccAck = msg?.mailMessageParticipantWithTypeDtos?.participantCCs?.find(
      (x) => {
        return (
          x?.participantUserId == currentUserId && x?.isAcknowledged === true
        );
      }
    );

    let emailStatus = {
      isAcknowledged: toAck === true || ccAck === true ? false : true,
    };
    let query = {
      // messageCenterId: selectedMailTypeAndMailData?.selectedMail?.id,
      // messageCenterId:
      //   emailCat === "ack" ? msg?.id : emailCat === "reminder" ? msg?.id : "",
      messageCenterId: msg?.id,
      typeSystemName: selectedMailTypeAndMailData?.typeDisplayName,
      mailMessageParticipantTableId:
        selectedMailTypeAndMailData?.selectedMail
          ?.mailMessageParticipantTableId,
      participantStatusName:
        emailCat === "reminder"
          ? "RemainderCount"
          : emailCat === "ack" &&
            (!isEmpty(toAck) === true || !isEmpty(ccAck) === true)
          ? "UnAcknowledged"
          : "Acknowledged",
      instanceMessageId:
        selectedMailTypeAndMailData?.selectedMail?.applicationTypeSystemName ===
        "InstanceMessage"
          ? selectedMailTypeAndMailData?.selectedMail?.latestInstanceMessageId
          : "",
      instanceMainMessageCenterId:
        selectedMailTypeAndMailData?.selectedMail?.instanceMainMessageCenterId,

      // CorporateSharedAccessAccountHolderUserId:
      //   corporateSharedAccessAccountHolderUserId,
    };

    // dispatch(
    //   // changeEmailStatusType(query, status, messageCenterTypeName, emailStatus)
    //   changeEmailStatusType(query, "", messageCenterTypeName, emailStatus)
    // );

    if (emailCat !== "reminder") {
      dispatch(
        // changeEmailStatusType(query, status, messageCenterTypeName, emailStatus)
        changeEmailStatusType(query, "", messageCenterTypeName, emailStatus)
      );
    } else {
      dispatch(
        changeEmailStatusTypeInstanceMessageBusinessTask(
          query,
          "",
          messageCenterTypeName,
          emailStatus
        )
      );
    }
  };

  return (
    <div>
      {/* <div className="global-chat-header">
        <div className="global-chat-row">
          <div className="global-message-icon">
            <img
              onClick={() => {
                setChatHeight(false);
                setSmallChatHeight(false);
              }}
              src={internalMessageGreen}
            />
            <span>Message</span>
          </div>
          {!chatBox ? (
            <div className="global-chat-plus-icon">
              <Button
                onClick={() => {
                  setchatBox(true);
                  setChatHeight(true);
                  setSmallChatHeight(false);
                  // setReplyofChat("");
                  // setReplyStatus("");
                  // setReplyChatHeight(true);
                  // setActiveChat("");
                  // setSmallChatHeight(false);
                }}
                className="btn btn-internalChat"
              >
                +
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div> */}

      <div
        className={`global-chat-info-wrapper business-chat-wrapper ${
          chatHeight && "showChatHeight"
        } ${smallChatHeight && "smallChatHeight"} `}
      >
        <div className="global-chat-items">
          {instanceChatList &&
            instanceChatList?.map((chat, key) => {
              let participantToNames = getParticipantsEmails(
                chat.mailMessageParticipantWithTypeDtos.participantTos
              );

              let participantCCNames = getParticipantsEmails(
                chat.mailMessageParticipantWithTypeDtos.participantCCs
              );
              return (
                <div
                  key={key}
                  className={`global-chat-item ${
                    currentUserId ==
                    chat.mailMessageParticipantWithTypeDtos?.participantFrom
                      ?.participantUserId
                      ? "right"
                      : "left"
                  }`}
                >
                  <div className="global-chat-item-wrapper">
                    <div className="global-chat-item-info-header">
                      <div className="global-chat-left">
                        <div
                          className="global-chat-name-wrapper"
                          style={{ width: "100%" }}
                        >
                          {/* <span className="global-chat-owner">
                            {chat.mailMessageParticipantWithTypeDtos?.participantFrom?.participantUserDto.userName.toUpperCase()}
                          </span>
                          {participantToNames && (
                            <span className="global-chat-to">
                              <ArrowRightOutlined />
                              {participantToNames.toUpperCase()}
                            </span>
                          )}

                          {participantCCNames && (
                            <span className="global-chat-cc">
                              ,{participantCCNames.toUpperCase()}
                            </span>
                          )} */}

                          <div style={{ display: "flex", width: "90%" }}>
                            <div
                            // className="left_one"
                            // style={{
                            //   width: "30%",
                            // }}
                            >
                              <div
                                style={{
                                  fontSize: "small",
                                }}
                              >
                                From:
                              </div>
                              <div
                                style={{
                                  marginTop: "-5px",
                                  fontSize: "small",
                                }}
                              >
                                TO:
                              </div>

                              {!isEmpty(participantCCNames) && (
                                <div
                                  style={{
                                    marginTop: "-5px",
                                    fontSize: "small",
                                  }}
                                >
                                  CC
                                </div>
                              )}
                            </div>
                            <div className="right_one">
                              <Typography.Paragraph
                                style={{
                                  color: "black",
                                  paddingBottom: "2px",
                                  marginBottom: "-8px",
                                  fontSize: "small",
                                }}
                              >
                                {chat.mailMessageParticipantWithTypeDtos?.participantFrom?.participantUserDto.userName.toUpperCase()}
                              </Typography.Paragraph>

                              {chat?.mailMessageParticipantWithTypeDtos?.participantTos?.map(
                                (x, index) => {
                                  if (index >= 3) {
                                    return null;
                                  }
                                  return (
                                    <Typography.Text
                                      style={{
                                        color:
                                          x?.isReadMessage === true
                                            ? "green"
                                            : "black",
                                        paddingBottom: "2px",
                                        marginBottom: "-8px",
                                        fontSize: "small",
                                      }}
                                    >
                                      {/* <span className="chat-to-info"> */}
                                      {x?.participantUserDto?.userName?.toUpperCase()}
                                      {index === 2 && " ..."},{/* </span> */}
                                    </Typography.Text>
                                  );
                                }
                              )}
                              <br />
                              <div style={{ marginTop: "-8px" }}>
                                {!isEmpty(participantCCNames) &&
                                  chat?.mailMessageParticipantWithTypeDtos?.participantCCs?.map(
                                    (x, index) => {
                                      if (index >= 3) {
                                        return null; // Skip rendering the remaining items
                                      }
                                      return (
                                        //
                                        <Typography.Text
                                          style={{
                                            color:
                                              x?.isReadMessage === true
                                                ? "green"
                                                : "black",
                                            fontSize: "small",
                                            marginTop: "-8px",
                                          }}
                                        >
                                          {/* <span className="chat-to-info"> */}
                                          {x?.participantUserDto?.userName?.toUpperCase()}
                                          {index === 2 && " ..."},
                                          {/* </span> */}
                                        </Typography.Text>
                                        // </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {participantToNames || participantCCNames ? (
                          <div className="onHover__cc__to_details details1">
                            <span className="chatItem__mailTo">
                              From:{" "}
                              {chat.mailMessageParticipantWithTypeDtos?.participantFrom?.participantUserDto.userName.toUpperCase()}
                            </span>
                            <span className="chatItem__mailTo">
                              To: {participantToNames.toUpperCase()}
                            </span>
                            {participantCCNames && (
                              <div className="chatItem__mailTo">
                                Cc: {participantCCNames.toUpperCase()}
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="global-chat-right">
                        <span className="global-chat-time">
                          {moment(chat.creationTime).format("YYYY") !==
                          moment(new Date()).format("YYYY")
                            ? moment(chat.creationTime).format("DD/MM/YYYY")
                            : moment(chat.creationTime).calendar({
                                sameDay: "h:mm a",
                                nextDay: "[Tomorrow]",
                                nextWeek: "DD MMM",
                                lastDay: "[Yesterday]",
                                lastWeek: "DD MMM",
                                sameElse: "DD MMM",
                              })}
                        </span>
                        <span className="orderNumber-wrapper">
                          [
                          <span className="orderNumber">
                            {chat?.orderNumber}
                          </span>
                          {chat.replyOfInstanceMessageOrderNumber && (
                            <div className="global-reply-of">
                              , Reply of{" "}
                              {chat.replyOfInstanceMessageOrderNumber}
                            </div>
                          )}
                          ]
                        </span>
                      </div>
                    </div>

                    <div className="global-chat-details">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: chat?.messageText,
                        }}
                      ></p>
                    </div>
                    <div className="global-reply-reply-all-btn-wrapper">
                      <div className="global-chat-action-wrapper">
                        {currentUserId !=
                          chat.mailMessageParticipantWithTypeDtos
                            ?.participantFrom?.participantUserId && (
                          <>
                            <a
                              // onClick={() => changeEmailStatus(chat, "ack")}
                              className="global-chat-icon none-icon"
                            >
                              <span className="chatReply-desc">
                                {getAckUnAckLabel(chat)}
                              </span>

                              <img src={archieve} alt="" />
                            </a>
                          </>
                        )}
                        {currentUserId ===
                          chat.mailMessageParticipantWithTypeDtos
                            ?.participantFrom?.participantUserId && (
                          <>
                            <a
                              onClick={() =>
                                changeEmailStatus(chat, "reminder")
                              }
                              className="global-chat-icon none-icon"
                            >
                              <span className="chatReply-desc">Reminder</span>
                              <BellOutlined style={{ color: "grey" }} />
                            </a>
                          </>
                        )}
                        <a
                          onClick={() => {
                            handleReply(chat, "forward");
                            setChatHeight(true);
                          }}
                          className="global-chat-icon none-icon"
                        >
                          <span className="chatReply-desc">Forward</span>
                          <img src={Forward} />
                        </a>
                        <a
                          className="global-chat-icon none-icon"
                          onClick={() => {
                            handleReply(chat, "reply");
                            setChatHeight(true);
                          }}
                        >
                          <span className="chatReply-desc">Reply</span>
                          <img src={Reply} />
                        </a>
                        <a
                          className="global-chat-icon"
                          onClick={() => {
                            handleReply(chat, "replyall");
                            setChatHeight(true);
                          }}
                        >
                          <span className="chatReply-desc">Reply All</span>
                          <img src={ReplyAll} />
                        </a>
                      </div>
                    </div>

                    {!isEmpty(chat?.instanceMessageAttachmentDtos) && (
                      <div className="global-attachment-wrapper">
                        <div
                          className="email-attachments quick-msg-attach chat-attachment-item"
                          style={{
                            height: showAllAttachments ? "100%" : 40,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                          }}
                        >
                          <img
                            className="attachmentImage-chat"
                            style={{
                              height: 17,
                              paddingTop: 5,
                              width: "auto",
                            }}
                            src={attachment}
                          />{" "}
                          <span className="attachment_lenght_count">
                            {chat?.instanceMessageAttachmentDtos.length}{" "}
                          </span>
                          {showAttatchments(chat, "instantHover")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default BusinessChat;
