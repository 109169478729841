import { Form, Select, AutoComplete, Switch } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import AttendanceUpload from "components/shared/AttendaceFileUpload";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

const TabTwo = ({
  grantedPolices,
  userId,
  setUserId,
  meetingInvitees,
  filterSoftwareType,
  filterFingerType,
  calendarFilter,
  calendarRef,
  setCalendarDate,
  fingerDetails,
  attendanceDetails,
}) => {
  const [form] = Form.useForm();
  const { Option } = AutoComplete;
  return (
    <>
      <div className='calander_body'>
        <div className='calender-wise'>
          {!isEmpty(grantedPolices) &&
            grantedPolices["CQNovalFramework.ContactPerson.Edit"] && (
              <Form form={form}>
                <Form.Item name={["attendanceDtos"]}>
                  <AttendanceUpload
                    name='attendanceDtos'
                    form={form}
                    isMultipleFileAllowed={false}
                    id='1'
                    callback={(e) => {
                      let olduploadedFileDtos = form.getFieldValue()[
                        "attendanceDtos"
                      ]
                        ? [...form.getFieldValue()["attendanceDtos"]]
                        : [];
                      let uploadedFileDtos = [...olduploadedFileDtos];
                      uploadedFileDtos.push(e);

                      form.setFieldsValue({
                        attendanceDtos: uploadedFileDtos,
                      });

                      // form.setFieldsValue({
                      //   uploadedFileDtos: [
                      //     ...olduploadedFileDtos,
                      //     e
                      //   ]
                      //     })
                    }}
                  />
                </Form.Item>
              </Form>
            )}
          <div
            className='attendence-filter'
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {!isEmpty(grantedPolices) &&
              grantedPolices["AbpIdentity.Roles"] && (
                <div
                  className='name-attendence__filter'
                  style={{
                    width: "300px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: "10px" }}>Name</span>
                  <Select
                    className='select_users'
                    // placeholder="Select Users"
                    defaultValue={userId}
                    allowClear
                    optionFilterProp='label'
                    showSearch={true}
                    onChange={(value) => setUserId(value)}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {!isEmpty(meetingInvitees) &&
                      meetingInvitees.map((employee, index) => {
                        return (
                          <Option
                            value={employee.appUserId}
                            // title={employee.fullName}
                            key={index}
                            label={[employee.fullName, employee.userName]}
                          >
                            <span role='img'>{employee.fullName}</span>[
                            {employee.userName.toUpperCase()}]
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              )}
            {/* {!isEmpty(grantedPolices) &&
  grantedPolices[
    "AbpIdentity.Roles"
  ] && ( */}
            <div className='software-attendence__filter'>
              <span>Software</span>
              <Switch
                checked={filterSoftwareType}
                onChange={calendarFilter}
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              <span>Finger Print</span>
            </div>
            {/* )} */}
          </div>
        </div>

        <div className='attendence-calendar'>
          <FullCalendar
            ref={calendarRef}
            customButtons={{
              customprev: {
                text: "<",
                click: function () {
                  calendarRef.current.getApi().prev();

                  setCalendarDate((prev) => moment(prev).subtract(1, "months"));
                },
              },
              customnext: {
                text: ">",
                click: function () {
                  calendarRef.current.getApi().next();

                  setCalendarDate((prev) => moment(prev).add(1, "months"));
                },
              },

              customtoday: {
                text: "today",
                click: function () {
                  calendarRef.current.getApi().today();

                  setCalendarDate(moment());
                },
              },
            }}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            headerToolbar={{
              left: "customprev customtoday customnext",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView='dayGridMonth'
            // dayMaxEventRows={3}
            events={filterFingerType ? fingerDetails : attendanceDetails}
            editable={false}
            disableDragging={true}
            eventStartEditable={false}
            startEditable={false}
            defaultAllDay={true}
            // height="800px"
          />
        </div>
      </div>
    </>
  );
};

export default TabTwo;
