import { Image } from "antd";
import { cqBaseUrl } from "utils/config";
import { useSelector } from "react-redux";

export default function MatchingImageDisplay({ listingAllProfile, item }) {
  const { token } = useSelector((state) => state.auth);

  const matchingObject = listingAllProfile.find(
    (obj) => obj.userId.toLowerCase() === item.userId.toLowerCase()
  );

  return (
    <div>
      {matchingObject && (
        <Image
          className="blog-img-avatar"
          key={matchingObject?.userId}
          src={cqBaseUrl + "/" + matchingObject?.imageUrl + token}
        />
      )}
    </div>
  );
}
