import queryString from "query-string";
import { appBaseUrl, baseUrl, cqBaseUrl } from "utils/config";
import { getService, postService, putService } from "../../commonServices";

export class ReferencesService {
  getStagePagination() {
    let url = `${appBaseUrl}/referenceManagement/tenderStagePagination`;
    let data = getService(url);
    return data;
  }
  stageAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderStageAdd`;
    let data = postService(url, body);
    return data;
  }
  stageUpdate(id, body) {
    let url = `${appBaseUrl}/referenceManagement/tenderStageUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  allCurrencyListing(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/currencyListing${query}`;
    let data = getService(url);
    return data;
  }
  referenceTypePagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/referenceTypePagination?${query}`;
    let data = getService(url);
    return data;
  }
  referenceTypeAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/referenceTypeAdd`;
    let data = postService(url, body);
    return data;
  }
  referenceTypeUpdate(id, body) {
    let url = `${appBaseUrl}/referenceManagement/referenceTypeUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  referenceListing(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/ReferencePagination?${query}`;
    let data = getService(url);
    return data;
  }
  //  referencePartyListing
  referencePartyListing(id) {
    let query = queryString.stringify(id);
    let url = `${appBaseUrl}/referenceManagement/referencePartyListing?${query}`;
    let data = getService(url);
    return data;
  }

  // ADDED for circular planning
  referencePartyListingCircular(id) {
    let query = queryString.stringify(id);
    let url = `${appBaseUrl}/referenceManagement/referencePartyListingCircular?${query}`;
    let data = getService(url);
    return data;
  }

  // for ADD & UPDATE
  referenceCompanyCircularPlanningAdd(bodyPayload, apiData) {
    let query = queryString.stringify(apiData);
    let url = `${appBaseUrl}/referenceManagement/referenceCompanyCircularPlanningAdd?${query}`;
    let data = postService(url, bodyPayload);
    return data;
  }

  // delete company
  referenceCompanyDelete(id) {
    let url = `${appBaseUrl}/referenceManagement/referenceCompanyDelete/${id}`;
    let data = putService(url);
    return data;
  }

  // ReferencePartyRemarksHistoryAdd
  referencePartyRemarksHistoryAdd(apiData) {
    let url = `${appBaseUrl}/referenceManagement/referencePartyRemarksHistoryAdd`;
    let data = postService(url, apiData);
    return data;
  }

  // solo Remarks
  referencePartyRemarksHistoryAddSolo(apiData) {
    let url = `${appBaseUrl}/referenceManagement/referencePartyRemarksHistoryAdd`;
    let data = postService(url, apiData);
    return data;
  }

  // PROJECT REMARKS ADD
  projectPartyRemarksHistoryAdd(apiData) {
    let url = `${appBaseUrl}/referenceManagement/projectPartyRemarksHistoryAdd`;
    let data = postService(url, apiData);
    return data;
  }

  // Reference remarks history listing
  referencePartyRemarksHistoryListing(body) {
    let query = queryString.stringify(body);
    let url = `${appBaseUrl}/referenceManagement/referencePartyRemarksHistoryListing?${query}`;
    let data = getService(url);
    return data;
  }

  // PROJECT Remarks Listing
  apiForProjectRemarksHistoryListing(body) {
    let query = queryString.stringify(body);
    let url = `${appBaseUrl}/referenceManagement/projectPartyRemarksHistoryListing?${query}`;
    let data = getService(url);
    return data;
  }

  // reference company manual add
  ReferenceCompanyManualAdd(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/referenceCompanyManualAdd?${query}`;
    let data = getService(url);
    return data;
  }
  // reference auto update company
  UpdateReferenceCompanyOfMailMessageByReference(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/updateReferenceCompanyOfMailMessageByReference?${query}`;
    let data = getService(url);
    return data;
  }

  // ReferencePartyAction WithActionType
  referencePartyAction(id, q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/ReferencePartyActionWithActionType/${id}?${query}`;
    let data = postService(url, q);
    return data;
  }
  getAutoReferenceData() {
    let url = `${appBaseUrl}/emailMessage/emailAutoReferenceListing`;
    let data = getService(url);
    return data;
  }
  emailAutoReferenceAdd(body) {
    let url = `${appBaseUrl}/emailMessage/emailAutoReferenceAdd`;
    let data = postService(url, body);
    return data;
  }
  emailAutoReferenceUpdate(body, id) {
    let url = `${appBaseUrl}/emailMessage/emailAutoReferenceUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  updateContactData(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactCompanyLightUpdate/${id}`;
    let data = putService(url, body);
    return data;
  }

  // added
  getCircularPlanningTemplate(id) {
    let url = `${appBaseUrl}/referenceManagement/allCircularPlanningCompanyDto?CircularPlanningTemplateId=${id}`;
    let data = getService(url);
    return data;
  }

  getAllCircularPlanningTemplate() {
    let url = `${appBaseUrl}/referenceManagement/allCircularPlanningTemplate`;
    let data = getService(url);
    return data;
  }

  // post
  addCircularPlanningTemplate(body) {
    let url = `${appBaseUrl}/referenceManagement/circularPlanningTemplate`;
    let data = postService(url, body);
    return data;
  }

  // add/updated company
  updateCircularPlanningCompany(body) {
    let url = `${appBaseUrl}/referenceManagement/circularPlanningCompany`;
    let data = postService(url, body);
    return data;
  }

  // add company email address
  addCompanyOrContactPersonEmailAddById(body) {
    let url = `${appBaseUrl}/referenceManagement/companyOrContactPersonEmailAddById`;
    let data = postService(url, body);
    return data;
  }

  // import
  addImportIntoReferenceCompanies(body) {
    let url = `${cqBaseUrl}/ImportIntoReferenceCompanies`;
    let data = postService(url, body);
    return data;
  }

  // loglist
  getCircularPlanningLog(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/circularplanninglogsGetAllData?${query}`;
    let data = getService(url, query);
    return data;
  }
}
