import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";

export class MeetingStatusService {
  getMeetingStatusListings() {
    let url = `${appBaseUrl}/organizerEvent/meetingEventStatusListing`;
    let data = getService(url);
    return data;
  }
}
