import { Col, Form, Input, Layout, Row } from "antd";
import React from "react";

const SettlementAccountForm = ({ label, comment, amount }) => {
  return (
    <Layout.Content
      style={{
        backgroundColor: "#FAFAFA",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        marginTop: 10,
      }}
    >
      <Form.Item label={label} name={comment}>
        <Input.TextArea
          readOnly
          autoSize={{ minRows: 1, maxRows: 6 }}
          autoComplete="off"
          placeholder="Remarks"
        />
      </Form.Item>
      <Row>
        <Col span={5}>
          <Form.Item name={amount}>
            <Input autoComplete="off" style={{ marginBottom: 5 }} readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default SettlementAccountForm;
