import { uniqBy } from "lodash-es";
import {
  EMAIL_TYPES,
  COMPANY_PEOPLE_TYPES,
  REFERENCE__TYPES,
  SOW_TYPES,
} from "../../../types";

import { isEmpty } from "lodash";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import StatusAddUpdateDrawer from "views/AdminSettings/ProjectSetting/Status/StatusDrawer";
import { InputGroup } from "react-bootstrap";

const INITIAL_STATE = {
  composeEmailStatus: "",
  emailType: "",
  referenceCode: "",

  mailMessageCenterPagination: {
    items: [],
    totalCount: 1,
  },
  mailMessageCenterPaginationLoading: false,

  mailMessageCenterPaginationDasboard: {
    items: [],
    totalCount: 1,
  },
  mailMessageCenterPaginationDasboardLoading: false,

  composeEmailResp: [],
  composeEmailRespLoading: false,
  composeEmailResNotCloseLoading: false,

  changeEmailStatusTypeResp: [],
  changeEmailStatusTypeRespLoading: false,

  getResponseStatus: null,
  getResponseLoader: false,

  selectedMailTypeAndMailData: {},

  refreshIncomingMailResp: [],
  refreshIncomingMailRespLoading: false,

  mailReferenceUpdateResponse: null,
  mailReferenceUpdateRespoonseLoading: false,

  mailReferenceListingResponse: null,
  mailReferenceListingResponseLoading: false,

  mailBodyGetDetailsResponse: null,
  mailBodyGetDetailsLoading: false,

  allCount: null,
  toCount: null,
  fromCount: null,
  ccCount: null,
  bccCount: null,
  pendingCount: null,
  archievedCount: null,
  trashCount: null,
  junkCount: null,
  flagCount: null,
  drafCount: null,
  outBoxCount: null,
  approvalCount: null,
  loanCount: null,
  leaveCount: null,
  procurementCount: null,
  tenderBusinessCount: null,
  circularInboxCount: null,
  circularSentCount: null,
  groupChatCount: null,
  groupChatArchiveCount: null,

  mailPaginationCount: null,
  mailPaginationCountLoading: false,

  infiniteScrollLoading: false,

  childReferenceUpdateResponse: null,

  draftMessagesResponse: [],
  draftMessageLoading: false,

  onUpdateStatusChangePagination: true,
  changeInstanceMessageonStatusChange: false,
  mailScrollLoader: false,

  errorMessage: null,
  errorMessageLoading: false,
  mailMessageCenterBodyHighlightLoading: false,
  mailMessageCenterBodyHighlightResp: null,

  outBoxResetFailCountResponse: [],
  outBoxResetFailCountLoading: false,
};

let approvalCount = "";
let allCount = "";
let toCount = "";
let fromCount = "";
let ccCount = "";
let bccCount = "";
let pendingCount = "";
let archievedCount = "";
let trashCount = "";
let junkCount = "";
let flagCount = "";
let drafCount = "";
let outBoxCount = "";
let loanCount = "";
let leaveCount = "";
let procurementCount = "";
let tenderBusinessCount = "";
let circularInboxCount = "";
let circularSentCount = "";
let groupChatCount = "";
let groupChatArchiveCount = "";

export default function mailMessageCenterReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    // Get All Email
    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_INIT:
      return {
        ...state,
        emailType: action?.data?.emailType,
        referenceCode: action?.data?.reference_SearchKeyword,
        // ...(state?.emailType !== action.data.emailType && {
        //   mailMessageCenterPagination: {
        //     items: [],
        //     totalCount: 0,
        //   },
        // }),

        ...(state?.referenceCode !== action?.data?.reference_SearchKeyword && {
          mailMessageCenterPagination: {
            items: [],
            totalCount: 0,
          },
        }),

        // mailMessageCenterPagination: [],
        // getResponseStatus: null,
        // mailBodyGetDetailsResponse: null,
        // mailMessageCenterPaginationLoading: true,
        mailMessageCenterPaginationLoading: false,
        infiniteScrollLoading: true,
        mailMessagePaginationSuccess: false,
      };
    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_SUCCESS:
      return {
        ...state,

        onUpdateStatusChangePagination: true,

        infiniteScrollLoading:
          action.record === "hey"
            ? true
            : action.record === "st"
            ? false
            : false,
        mailMessageCenterPagination: {
          items: action.loadMore
            ? uniqBy(
                state.mailMessageCenterPagination.items.concat(
                  action.payload.items
                ),
                "id"
                // "id"
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
        mailMessageCenterPaginationLoading: false,
        mailScrollLoader: true,
        mailMessagePaginationSuccess: true,
      };
    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_FINISH:
      return {
        ...state,
        mailMessageCenterPaginationLoading: false,
        infiniteScrollLoading: false,
      };
    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_UPDATE:
      let newData = state.mailMessageCenterPagination.items.map((email) => {
        if (
          email.applicationTypeSystemName === "EMail" ||
          email.applicationTypeSystemName === "QuickMessage" ||
          email.applicationTypeSystemName === "Leave" ||
          email.applicationTypeSystemName == "Loan" ||
          email.applicationTypeSystemName === "Business" ||
          email.applicationTypeSystemName === "Document" ||
          email.applicationTypeSystemName === "TODO" ||
          email.applicationTypeSystemName === "Todo" ||
          email.applicationTypeSystemName === "Procurement" ||
          "Loan"
        ) {
          if (action.query.messageCenterId === email.id) {
            return {
              changeEmailStatusTypeResp: true,
              ...email,
              ...action.emailStatus,
            };
          } else {
            return {
              ...email,
            };
          }
        } else if (email.applicationTypeSystemName === "InstanceMessage") {
          if (
            action.query.messageCenterId === email.instanceMainMessageCenterId
          ) {
            return {
              changeEmailStatusTypeResp: true,
              ...email,
              ...action.emailStatus,
            };
          } else {
            return {
              ...email,
            };
          }
        }
      });

      let filterNewData = newData.filter((data) => data);
      let filterArchieve = filterNewData.filter((data) =>
        action.emailStatus.isArchieve ? !data.isArchieve : data.isArchieve
      );
      let filterJunk = filterNewData.filter((data) =>
        action.emailStatus.isJunk ? !data.isJunk : data.isJunk
      );
      let filterChatArchive = filterNewData.filter((data) =>
        action.emailStatus.isGroupChatArchieve
          ? !data.isGroupChatArchieve
          : data.isGroupChatArchieve
      );
      let filterGroupChatSwitch = filterNewData.filter((data) =>
        action.emailStatus.isGroupChatSwitch
          ? !data.isGroupChatSwitch
          : data.isGroupChatSwitch
      );
      let filterPending = filterNewData.filter((data) =>
        action.emailStatus.isPending ? !data.isPending : data.isPending
      );
      // console.log("action: ", action);
      // console.log("filterPending", filterJunk);
      let doubleTrash = filterNewData.filter(
        (data) => data?.move !== "doubleTrash"
      );
      let filterTrashData = filterNewData.filter(
        (data) => data && !data.isTrashed
      );

      let filterLowPriorityMail = filterNewData.filter((data) =>
        action.emailStatus.isUnSolicited
          ? !data.isUnSolicited
          : data.isUnSolicited
      );
      // console.log(
      //   "action.query.participantStatusName",
      //   action.query.participantStatusName
      // );

      return {
        ...state,
        getResponseStatus: action.query,
        mailMessageCenterPagination: {
          items:
            action.query.participantStatusName === "Trash"
              ? filterTrashData
              : action.query.participantStatusName === "doubleTrash"
              ? doubleTrash
              : action.query.participantStatusName === "Archived"
              ? filterArchieve
              : action.query.participantStatusName === "Pending"
              ? filterPending
              : action.query.participantStatusName === "Junk"
              ? filterJunk
              : action.query.participantStatusName === "GroupChatArchive"
              ? filterChatArchive
              : action.query.participantStatusName === "GroupChatSwitch"
              ? filterGroupChatSwitch
              : action.query.participantStatusName === "Special1"
              ? filterLowPriorityMail
              : filterNewData,
          totalCount: state.mailMessageCenterPagination.totalCount,
        },
        mailMessageCenterPaginationLoading: false,
      };

    // Dashboard xxxx
    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_INIT:
      return {
        ...state,
        emailType: action?.data?.emailType,
        referenceCode: action?.data?.reference_SearchKeyword,
        // ...(state?.emailType !== action.data.emailType && {
        //   mailMessageCenterPagination: {
        //     items: [],
        //     totalCount: 0,
        //   },
        // }),

        ...(state?.referenceCode !== action?.data?.reference_SearchKeyword && {
          mailMessageCenterPaginationDasboard: {
            items: [],
            totalCount: 0,
          },
        }),

        // mailMessageCenterPagination: [],
        // getResponseStatus: null,
        // mailBodyGetDetailsResponse: null,
        // mailMessageCenterPaginationLoading: true,
        mailMessageCenterPaginationDasboardLoading: false,
        infiniteScrollLoading: true,
        mailMessagePaginationSuccess: false,
      };
    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_SUCCESS:
      return {
        ...state,

        onUpdateStatusChangePagination: true,

        infiniteScrollLoading:
          action.record === "hey"
            ? true
            : action.record === "st"
            ? false
            : false,
        mailMessageCenterPaginationDasboard: {
          items: action.loadMore
            ? uniqBy(
                state.mailMessageCenterPaginationDasboard.items.concat(
                  action.payload.items
                ),
                "id"
                // "id"
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
        mailMessageCenterPaginationLoading: false,
        mailScrollLoader: true,
        mailMessagePaginationSuccess: true,
      };
    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_FINISH:
      return {
        ...state,
        mailMessageCenterPaginationDasboardLoading: false,
        infiniteScrollLoading: false,
      };
    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_PAGINATION_DASHBOARD_UPDATE:
      let newDataDb = state.mailMessageCenterPaginationDasboard.items.map(
        (email) => {
          if (
            email.applicationTypeSystemName === "EMail" ||
            email.applicationTypeSystemName === "QuickMessage" ||
            email.applicationTypeSystemName === "Leave" ||
            email.applicationTypeSystemName == "Loan" ||
            email.applicationTypeSystemName === "Business" ||
            email.applicationTypeSystemName === "Document" ||
            email.applicationTypeSystemName === "TODO" ||
            email.applicationTypeSystemName === "Todo" ||
            email.applicationTypeSystemName === "Procurement" ||
            "Loan"
          ) {
            if (action.query.messageCenterId === email.id) {
              return {
                changeEmailStatusTypeResp: true,
                ...email,
                ...action.emailStatus,
              };
            } else {
              return {
                ...email,
              };
            }
          } else if (email.applicationTypeSystemName === "InstanceMessage") {
            if (
              action.query.messageCenterId === email.instanceMainMessageCenterId
            ) {
              return {
                changeEmailStatusTypeResp: true,
                ...email,
                ...action.emailStatus,
              };
            } else {
              return {
                ...email,
              };
            }
          }
        }
      );

      let filterNewDataDb = newData.filter((data) => data);
      let filterArchieveDb = filterNewData.filter((data) =>
        action.emailStatus.isArchieve ? !data.isArchieve : data.isArchieve
      );
      let filterJunkDb = filterNewData.filter((data) =>
        action.emailStatus.isJunk ? !data.isJunk : data.isJunk
      );
      let filterChatArchiveDb = filterNewData.filter((data) =>
        action.emailStatus.isGroupChatArchieve
          ? !data.isGroupChatArchieve
          : data.isGroupChatArchieve
      );
      let filterGroupChatSwitchDb = filterNewData.filter((data) =>
        action.emailStatus.isGroupChatSwitch
          ? !data.isGroupChatSwitch
          : data.isGroupChatSwitch
      );
      let filterPendingDb = filterNewData.filter((data) =>
        action.emailStatus.isPending ? !data.isPending : data.isPending
      );
      // console.log("action: ", action);
      // console.log("filterPending", filterJunk);
      let doubleTrashDb = filterNewData.filter(
        (data) => data?.move !== "doubleTrash"
      );
      let filterTrashDataDb = filterNewData.filter(
        (data) => data && !data.isTrashed
      );

      let filterLowPriorityMailDb = filterNewData.filter((data) =>
        action.emailStatus.isUnSolicited
          ? !data.isUnSolicited
          : data.isUnSolicited
      );
      // console.log(
      //   "action.query.participantStatusName",
      //   action.query.participantStatusName
      // );

      return {
        ...state,
        getResponseStatus: action.query,
        mailMessageCenterPaginationDasboard: {
          items:
            action.query.participantStatusName === "Trash"
              ? filterTrashDataDb
              : action.query.participantStatusName === "doubleTrash"
              ? doubleTrashDb
              : action.query.participantStatusName === "Archived"
              ? filterArchieveDb
              : action.query.participantStatusName === "Pending"
              ? filterPendingDb
              : action.query.participantStatusName === "Junk"
              ? filterJunkDb
              : action.query.participantStatusName === "GroupChatArchive"
              ? filterChatArchiveDb
              : action.query.participantStatusName === "GroupChatSwitch"
              ? filterGroupChatSwitchDb
              : action.query.participantStatusName === "Special1"
              ? filterLowPriorityMailDb
              : filterNewDataDb,
          totalCount: state.mailMessageCenterPaginationDasboard.totalCount,
        },
        mailMessageCenterPaginationDasboardLoading: false,
      };

    //ERROR PAGINATION
    case EMAIL_TYPES.MAIL_ERROR_PAGINATION_INIT:
      return {
        ...state,
        errorMessage: null,
        errorMessageLoading: true,
      };
    case EMAIL_TYPES.MAIL_ERROR_PAGINATION_SUCCESS:
      return {
        ...state,
        errorMessage: action.payload,
        errorMessageLoading: false,
      };
    case EMAIL_TYPES.MAIL_ERROR_PAGINATION_FINISH:
      return {
        ...state,
        errorMessageLoading: true,
      };

    // Compose Email
    case EMAIL_TYPES.COMPOSE_EMAIL_INIT:
      return {
        ...state,
        composeEmailResp: [],
        composeEmailRespLoading: true,
        composeEmailStatus: "",
      };
    case EMAIL_TYPES.COMPOSE_EMAIL_NOT_CLOSE_INIT:
      return {
        ...state,
        composeEmailResp: [],
        composeEmailResNotCloseLoading: true,
      };
    case EMAIL_TYPES.COMPOSE_EMAIL_SUCCESS:
      return {
        ...state,
        composeEmailResp: action.payload,
        composeEmailRespLoading: false,
        composeEmailResNotCloseLoading: false,
        composeEmailStatus: "successful",
      };
    case EMAIL_TYPES.COMPOSE_EMAIL_FINISH:
      return {
        ...state,
        composeEmailRespLoading: false,
        composeEmailResNotCloseLoading: false,
        composeEmailStatus: "",
      };

    //circular email compose
    case EMAIL_TYPES.COMPOSE_EMAIL_CIRCULAR_SUCCESS:
      return {
        ...state,
        composeCircularEmailResp: action.payload,
        composeCircularEmailRespLoading: false,
        composeCircularEmailResNotCloseLoading: false,
        composeEmailStatus: "successful",
      };

    //draft Messages

    case EMAIL_TYPES.DRAFT_MESSAGE_INIT:
      return {
        ...state,
        draftMessagesResponse: [],
        draftMessageLoading: true,
      };
    case EMAIL_TYPES.DRAFT_MESSAGE_SUCCESS:
      return {
        ...state,
        draftMessagesResponse: action.payload,
        draftMessageLoading: false,
      };
    case EMAIL_TYPES.DRAFT_MESSAGE_FINISH:
      return {
        ...state,
        draftMessageLoading: false,
        draftMessagesNotLoadingResponse: false,
      };

    // Change Email Statuses
    case EMAIL_TYPES.PARTICIPANT_STATUSES_CHANGE_INIT:
      return {
        ...state,
        changeEmailStatusTypeResp: [],
        getResponseStatus: null,
        changeEmailStatusTypeRespLoading: true,
      };
    case EMAIL_TYPES.PARTICIPANT_STATUSES_CHANGE_SUCCESS:
      return {
        ...state,
        changeEmailStatusTypeResp: action.payload,
        changeEmailStatusTypeRespLoading: false,
        changeInstanceMessageonStatusChange: false,
        onUpdateStatusChangePagination: false,
      };
    case EMAIL_TYPES.PARTICIPANT_STATUSES_CHANGE_FINISH:
      return {
        ...state,
        changeEmailStatusTypeRespLoading: false,
        getResponseStatus: null,
      };

    case EMAIL_TYPES.PARTICIPANT_MULTIPEL_STATUSES_CHANGE_INIT:
      return {
        ...state,
        changeEmailStatusTypeResp: [],
        getResponseStatus: null,
        changeEmailStatusTypeRespLoading: true,
      };
    case EMAIL_TYPES.PARTICIPANT_MULTIPEL_STATUSES_CHANGE_SUCCESS: {
      return {
        ...state,
        changeEmailStatusTypeResp: action.payload,
        changeEmailStatusTypeRespLoading: false,
        changeInstanceMessageonStatusChange: false,
        onUpdateStatusChangePagination: false,
      };
    }

    case EMAIL_TYPES.PARTICIPANT_MULTIPEL_STATUSES_CHANGE_FINISH:
      return {
        ...state,
        changeEmailStatusTypeRespLoading: false,
        getResponseStatus: null,
      };
    // Refresh Incoming Email
    case EMAIL_TYPES.REFRESH_INCOMING_MAIL_INIT:
      return {
        ...state,
        refreshIncomingMailResp: [],
        refreshIncomingMailRespLoading: true,
      };
    case EMAIL_TYPES.REFRESH_INCOMING_MAIL_SUCCESS:
      return {
        ...state,
        refreshIncomingMailResp: action.payload,
        refreshIncomingMailRespLoading: false,
      };
    case EMAIL_TYPES.REFRESH_INCOMING_MAIL_FINISH:
      return {
        ...state,
        refreshIncomingMailRespLoading: false,
      };
    case EMAIL_TYPES.SELECTED_MAIL_TYPE_AND_MAIL:
      return {
        ...state,
        selectedMailTypeAndMailData: action.payload,
      };
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_UPDATE_INIT:
      return {
        ...state,

        mailReferenceUpdateRespoonseLoading: true,
      };
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_UPDATE_SUCCESS:
      return {
        ...state,
        mailReferenceUpdateResponse: action.payload,
        onUpdateStatusChangePagination: false,
        // mailReferenceListingResponse: action.payload,

        mailMessageCenterPagination: {
          items: state.mailMessageCenterPagination.items.map((items) => {
            if (
              items.instanceMainMessageCenterId ===
              action.record.instanceMainMessageCenterId
            ) {
              return {
                ...items,
                mailMessageReferenceDtos: action.payload,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.mailMessageCenterPagination.totalCount,
        },
        mailReferenceUpdateRespoonseLoading: false,
      };

    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_UPDATE_FINISH:
      console.log("FINISH state", state);
      return {
        ...state,
        // mailReferenceUpdateResponse: null,
        mailReferenceUpdateRespoonseLoading: false,
      };

    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_LISTING_INIT:
      return {
        ...state,

        mailReferenceListingResponseLoading: true,
      };

    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_LISTING_SUCCESS:
      return {
        ...state,
        mailReferenceListingResponse: action.payload,
        mailReferenceListingResponseLoading: false,
      };

    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_LISTING_FINISH:
      return {
        ...state,

        mailReferenceListingResponseLoading: false,
      };

    //get Details of Email Body
    case EMAIL_TYPES.MAIL_BODY_GET_DETAILS_INIT:
      return {
        ...state,
        mailBodyGetDetailsLoading: true,
      };
    case EMAIL_TYPES.MAIL_BODY_GET_DETAILS_SUCCESS:
      return {
        ...state,
        mailBodyGetDetailsResponse: action.payload,
        mailBodyGetDetailsLoading: false,
        changeInstanceMessageonStatusChange: true,
      };
    case EMAIL_TYPES.MAIL_BODY_GET_DETAILS_FINISH:
      return {
        ...state,
        mailBodyGetDetailsLoading: true,
      };

    case EMAIL_TYPES.MAIL_BODY_GET_DETAILS_UPDATE:
      return {
        ...state,
        mailBodyGetDetailsResponse: {
          ...state.mailBodyGetDetailsResponse,
          ...action.emailStatus,
          ...state?.mailReferenceUpdateResponse,
          mailMessageReferenceDtos: state?.mailReferenceUpdateResponse
            ? state?.mailReferenceUpdateResponse
            : action.values,
        },
      };

    //pagination count
    case EMAIL_TYPES.MAIL_PAGINATION_COUNT_INIT:
      return {
        ...state,
        mailPaginationCountLoading: true,
      };
    case EMAIL_TYPES.MAIL_PAGINATION_COUNT_SUCCESS:
      switch (action.record) {
        case "All":
          allCount = action.payload;
          break;
        case "From":
          fromCount = action.payload;
          break;
        case "To":
          toCount = action.payload;
          break;
        case "Cc":
          ccCount = action.payload;
          break;
        case "Bcc":
          bccCount = action.payload;
          break;
        case "Pending":
          pendingCount = action.payload;
          break;
        case "Archived":
          archievedCount = action.payload;
          break;
        case "Trash":
          trashCount = action.payload;
          break;
        case "Outbox":
          outBoxCount = action.payload;
          break;
        case "Junk":
          junkCount = action.payload;
          break;

        case "Specialflag1":
          flagCount = action.payload;
          break;
        case "Draft":
          drafCount = action.payload;
          break;
        case "Loan,Leave,Procurement":
          approvalCount = action.payload;
          break;
        case "Loan":
          loanCount = action.payload;
          break;
        case "Leave":
          leaveCount = action.payload;
          break;
        case "Procurement":
          procurementCount = action.payload;
          break;
        case "Business":
          tenderBusinessCount = action.payload;
          break;
        case "CircularInbox":
          circularInboxCount = action.payload;
          break;
        case "CircularSent":
          circularSentCount = action.payload;
          break;
        case "GroupChat":
          groupChatCount = action.payload;
          break;
        case "GroupChatArchive":
          groupChatArchiveCount = action.payload;
          break;
        default:
      }

      return {
        ...state,
        mailPaginationCount: {
          allCount: allCount,
          toCount: toCount,
          fromCount: fromCount,
          ccCount: ccCount,
          bccCount: bccCount,
          pendingCount: pendingCount,
          archievedCount: archievedCount,
          trashCount: trashCount,
          junkCount: junkCount,
          flagCount: flagCount,
          drafCount: drafCount,
          outBoxCount: outBoxCount,
          approvalCount: approvalCount,
          loanCount: loanCount,
          leaveCount: leaveCount,
          procurementCount: procurementCount,
          tenderBusinessCount: tenderBusinessCount,
          circularInboxCount: circularInboxCount,
          circularSentCount: circularSentCount,
          groupChatCount: groupChatCount,
          groupChatArchiveCount: groupChatArchiveCount,
        },
        allCount: allCount,
        toCount: toCount,
        fromCount: fromCount,
        ccCount: ccCount,
        bccCount: bccCount,
        pendingCount: pendingCount,
        archievedCount: archievedCount,
        trashCount: trashCount,
        junkCount: junkCount,
        flagCount: flagCount,
        drafCount: drafCount,
        outBoxCount: outBoxCount,
        approvalCount: approvalCount,
        loanCount: loanCount,
        leaveCount: leaveCount,
        procurementCount: procurementCount,
        tenderBusinessCount: tenderBusinessCount,
        circularInboxCount: circularInboxCount,
        circularSentCount: circularSentCount,
        groupChatCount: groupChatCount,
        groupChatArchiveCount: groupChatArchiveCount,
        mailPaginationCountLoading: false,
      };

    case EMAIL_TYPES.MAIL_PAGINATION_COUNT_FINISH:
      return {
        ...state,
        mailPaginationCountLoading: true,
      };

    case EMAIL_TYPES.MAIL_COMPOSE_RESPONSE_CHILD:
      // console.log("heyyyasdggfsadf", action.payload);
      return {
        mailBodyGetDetailsResponse: {
          ...state.mailBodyGetDetailsResponse,
          isRead: true,
        },
        mailMessageCenterPagination: {
          items: state.mailMessageCenterPagination.items.map((items) => {
            if (
              items?.mailMessageParticipantTableId ===
                action?.payload?.mailMessageParticipantTableId ||
              items?.id === action.payload?.internalChatId
            ) {
              return {
                ...items,
                isRead: true,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.mailMessageCenterPagination.totalCount,
        },
        mailReferenceUpdateRespoonseLoading: false,
      };

    //quickCompany Add Response

    case COMPANY_PEOPLE_TYPES.CONTACT_QUICK_EMAIL_SUCCESS:
      // console.log("heyaction", action);
      let applicationDetails =
        state?.mailBodyGetDetailsResponse?.applicationTypeDetails;
      let participantFrom = "";
      let participantTos = "";
      let participantCcs = "";
      let participantBCcs = "";
      if (action.record === "From") {
        participantTos = applicationDetails.tos;
        participantCcs = applicationDetails.cCs;
        participantBCcs = applicationDetails.bcCs;
        participantFrom = applicationDetails.from.map((items, index) => {
          if (action.payload.email === items.emailAddress) {
            return {
              ...items,
              emailAddress: action.payload.email,
              companyName: action.payload.companyName,
              fullName:
                action.payload.firstName +
                " " +
                action.payload.middleName +
                " " +
                action.payload.lastName,
            };
          } else {
            return {
              ...items,
            };
          }
        });
      } else if (action.record === "To") {
        participantCcs = applicationDetails.cCs;
        participantBCcs = applicationDetails.bcCs;
        participantFrom = applicationDetails.from;
        participantTos = applicationDetails.tos.map((items, index) => {
          if (action.payload.email === items.emailAddress) {
            return {
              ...items,
              emailAddress: action.payload.email,
              companyName: action.payload.companyName,
              fullName:
                action.payload.firstName +
                " " +
                action.payload.middleName +
                " " +
                action.payload.lastName,
            };
          } else {
            return {
              ...items,
            };
          }
        });
      } else if (action.record === "CC") {
        participantBCcs = applicationDetails.bcCs;
        participantFrom = applicationDetails.from;
        participantTos = applicationDetails.tos;
        participantCcs = applicationDetails.cCs.map((items, index) => {
          if (action.payload.email === items.emailAddress) {
            return {
              ...items,
              emailAddress: action.payload.email,
              companyName: action.payload.companyName,
              fullName:
                action.payload.firstName +
                " " +
                action.payload.middleName +
                " " +
                action.payload.lastName,
            };
          } else {
            return {
              ...items,
            };
          }
        });
      } else if (action.record === "BCC") {
        participantTos = applicationDetails.tos;
        participantFrom = applicationDetails.from;
        participantCcs = applicationDetails.bcCs.map((items, index) => {
          if (action.payload.email === items.emailAddress) {
            return {
              ...items,
              emailAddress: action.payload.email,
              companyName: action.payload.companyName,
              fullName:
                action.payload.firstName +
                " " +
                action.payload.middleName +
                " " +
                action.payload.lastName,
            };
          } else {
            return {
              ...items,
            };
          }
        });
      }
      return {
        ...state,
        mailBodyGetDetailsResponse: {
          ...state.mailBodyGetDetailsResponse,
          applicationTypeDetails: {
            from: participantFrom,
            tos: participantTos,
            cCs: participantCcs,
            bcCs: participantBCcs,
          },
        },
        mailMessageCenterPagination: {
          items: state.mailMessageCenterPagination.items.map((items) => {
            if (
              items.instanceMainMessageCenterId ===
              action.details?.MessageCenterId
            ) {
              return {
                ...items,
                applicationTypeDetails: {
                  from: participantFrom,
                  tos: participantTos,
                  cCs: participantCcs,
                  bcCs: participantBCcs,
                },
              };
            } else {
              return {
                ...items,
              };
            }
          }),
        },
      };

    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT_INIT: {
      return {
        ...state,
        mailMessageCenterBodyHighlightLoading: true,
      };
    }
    case EMAIL_TYPES.MAIL_MESSAGE_CENTER_BODY_HIGHLIGHT_SUCCESS: {
      return {
        ...state,
        mailMessageCenterBodyHighlightLoading: false,
      };
    }

    case EMAIL_TYPES.QUICK_EMAIL_ADD_EMAIL_INIT: {
      return {
        ...state,
        quickEmailAddresponse: [],
        quickEmailAddresponseLoading: false,
      };
    }
    case EMAIL_TYPES.QUICK_EMAIL_ADD_EMAIL_SUCCESS: {
      return {
        ...state,
        quickEmailAddresponse: action.payload,
        quickEmailAddresponseLoading: true,
      };
    }
    case EMAIL_TYPES.QUICK_EMAIL_ADD_EMAIL_FINISH: {
      return {
        ...state,
        quickEmailAddresponse: [],
        quickEmailAddresponseLoading: true,
      };
    }

    case EMAIL_TYPES.DRAFT_MAIL_EXIST_OR_NOT_INIT: {
      return {
        ...state,
        emailExistOrNot: [],
      };
    }
    case EMAIL_TYPES.DRAFT_MAIL_EXIST_OR_NOT_SUCCESS: {
      return {
        ...state,
        emailExistOrNot: action.payload,
      };
    }

    case EMAIL_TYPES.GET_REPLY_DETAILS_INIT: {
      return {
        ...state,
        getDetailsReplyResponse: [],
        getDetailsReplyResponseLoading: true,
      };
    }

    case EMAIL_TYPES.GET_REPLY_DETAILS_SUCCESS: {
      return {
        ...state,
        getDetailsReplyResponse: action.payload,
        getDetailsReplyResponseLoading: false,
      };
    }

    case EMAIL_TYPES.GET_PREDEFINED_MESSAGE_LISTING_INIT:
      return {
        ...state,
        getPredefinedMessageResponse: [],
        getPredefinedMessageResponseLoading: true,
      };

    case EMAIL_TYPES.GET_PREDEFINED_MESSAGE_LISTING_SUCCESS:
      return {
        ...state,
        getPredefinedMessageResponse: action.payload,
        getPredefinedMessageResponseLoading: false,
      };

    case EMAIL_TYPES.PREDEFINED_MESSAGE_ADD_INIT:
      return {
        ...state,
        predefinedMessageAddResponse: [],
        predefinedMessageAddResponseLoading: true,
      };

    case EMAIL_TYPES.PREDEFINED_MESSAGE_ADD_SUCCESS:
      return {
        ...state,
        predefinedMessageAddResponse: action.payload,
        predefinedMessageAddResponseLoading: false,
      };

    case EMAIL_TYPES.PREDEFINED_MESSAGE_UPDATE_INIT:
      return {
        ...state,
        predefinedMessageUpdateResponse: [],
        predefinedMessageUpdateResponseLoading: true,
      };

    case EMAIL_TYPES.PREDEFINED_MESSAGE_UPDATE_SUCCESS:
      return {
        ...state,
        predefinedMessageUpdateResponse: action.payload,
        predefinedMessageUpdateResponseLoading: false,
      };

    case EMAIL_TYPES.GET_SHARED_EMAIL_LIST_INIT:
      return {
        ...state,
        getSharedEmailListsResponse: [],
        getSharedEmailListsResponseLoading: true,
        mailMessageCenterPaginationLoading: true,
      };

    case EMAIL_TYPES.GET_SHARED_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        getSharedEmailListsResponse: action.payload,
        getSharedEmailListsResponseLoading: false,
        mailMessageCenterPaginationLoading: false,
      };

    case REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD_INIT:
      return {
        ...state,
        emailAutoReferenceAddResponse: [],
        emailAutoReferenceAddResponseLoading: true,
        mailReferenceUpdateRespoonseLoading: true,
      };

    case REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD_SUCCESS:
      console.log(
        "autoADdreereference",
        action,
        state.mailMessageCenterPagination.items
      );
      return {
        ...state,
        mailReferenceUpdateResponse:
          action.payload?.currentMessageCenterReferenceDtos,
        onUpdateStatusChangePagination: false,
        mailReferenceListingResponse:
          action.payload?.currentMessageCenterReferenceDtos,

        mailMessageCenterPagination: {
          items: state.mailMessageCenterPagination.items.map((items) => {
            // if (items.id === action.record.id) {
            console.log(
              "ids",
              items.instanceMainMessageCenterId,
              action.payload.messageCenterId
            );
            if (
              items.instanceMainMessageCenterId ===
              action.payload.messageCenterId
            ) {
              return {
                ...items,
                // mailMessageReferenceDtos: action.payload,
                mailMessageReferenceDtos:
                  action.payload?.currentMessageCenterReferenceDtos,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.mailMessageCenterPagination.totalCount,
        },
        mailReferenceUpdateRespoonseLoading: false,
        emailAutoReferenceAddResponseLoading: false,
      };

    case REFERENCE__TYPES.SHARED_EMAIL_ADD_INIT:
      return {
        ...state,
        sharedMailAddResponse: [],
        sharedMailAddResponseoading: true,
      };

    case REFERENCE__TYPES.SHARED_EMAIL_ADD_SUCCESS:
      return {
        ...state,
        sharedMailAddResponse: action.payload,
        sharedMailAddResponseoading: false,
      };

    case REFERENCE__TYPES.GET_ALL_SHARED_EMAIL_LIST_INIT:
      return {
        ...state,
        getAllSharedListResponse: [],
        getAllSharedListResponseLoading: true,
      };

    case REFERENCE__TYPES.GET_ALL_SHARED_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        getAllSharedListResponse: action.payload,
        getAllSharedListResponseLoading: false,
      };

    case REFERENCE__TYPES.OUTBOXMAIL_RESET_FAILURECOUNT_INIT:
      return {
        ...state,
        outBoxResetFailCountResponse: [],
        outBoxResetFailCountLoading: true,
      };

    case REFERENCE__TYPES.OUTBOXMAIL_RESET_FAILURECOUNT_SUCCESS:
      return {
        ...state,
        outBoxResetFailCountResponse: action.payload,
        outBoxResetFailCountLoading: false,
      };
    case REFERENCE__TYPES.OUTBOXMAIL_RESET_FAILURECOUNT_FINISH:
      return {
        ...state,
        outBoxResetFailCountLoading: false,
      };

    default:
      return { ...state };
  }
}
