import { useState, useEffect } from "react";
import { Button, Form, message, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import QuickAddCompany from "views/Contact/ContactCompanyPerson/AddQuickCompany";
import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";

import { isEmpty } from "lodash";
import { projectGetDetails } from "services/redux/Reference/Project/action";
import {
  sectorListing,
  statusListing,
  modalityListing,
} from "services/remanentCalls";
import { allCountryListing } from "services/redux/contact/contact.action";
import { allCompanyListing } from "services/redux/Reference/action";
import { numberWithCommas } from "utils/commonFunc";

import renderProject from "./ProjectDetails";
import ProjectForm from "./ProjectForm";
import { getAllSectors } from "services/redux/officeresource/business/action";
const edit = "./assets/svg/profile-edit.svg";

const Summary = ({
  showAddUpdateSectorDrawer,
  isUpdate,
  setIsUpdate,
  setShowChat,
  showChat,
  projectListing,
  setSearchKeyword,
  setSelectedProjectId,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [getDetails, setGetDetails] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [searchTitleProject, setSearchTitleProject] = useState([]);
  const [isFav, setIsFav] = useState(false);
  const [showEditForm, setEditForm] = useState(true);
  const [status, setStatus] = useState([]);
  const [modality, setModality] = useState([]);
  const [defaultCurrencyId, setDefaultCurrencyID] = useState();
  const [projectStatusId, setProjectStatusId] = useState();
  const [titlteText, setTitleText] = useState("");
  const [titleExist, setTitleExist] = useState(false);
  const [clientAgencyType, setClientAgencyType] = useState("");
  const [contactCompanyList, setContactCompanyList] = useState([]);
  const [value, setValue] = useState("");
  const [addCompany] = Form.useForm();
  const pageSize = 100;

  const {
    projectGetDetailsResponse,
    projectUpdateResponse,
    projectUpdateLoading,
    projectAddResponse,
    projectLoadingResponse,
  } = useSelector((state) => state.project);

  const { allCurrencyListingResponse } = useSelector(
    (state) => state.reference
  );

  const { contactCompanyPaginationResponse, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  useEffect(() => {
    if (!isEmpty(projectAddResponse)) {
      setSearchKeyword(projectAddResponse.referenceCode);
      setSelectedProjectId(projectAddResponse.id);
      dispatch(projectGetDetails(projectAddResponse.id));
      setEditForm(false);
      setIsUpdate("details");
    }
  }, [projectAddResponse]);

  const { quickContactCompanyAdd, quickContactCompanyLoader } = useSelector(
    (state) => state.contactCompanyPerson
  );

  const { allSectorsListingResponse, allSectorsListingLoading } = useSelector(
    (state) => state.businessCenter
  );

  useEffect(() => {
    if (quickContactCompanyAdd) {
      addCompany.setFieldsValue({
        scopeOfWork: [],
        companyName: "",
        countryId: "",
        isActive: false,
        isFavorite: false,
        cityId: "",
      });
      setVisibleModal(false);
      setTimeout(() => {
        let newAgency;
        if (clientAgencyType == "agency") {
          newAgency = !isEmpty(form.getFieldValue("projectFundingAgencyDtos"))
            ? [
                ...form.getFieldValue("projectFundingAgencyDtos"),
                quickContactCompanyAdd.id,
              ]
            : [quickContactCompanyAdd.id];
        }

        clientAgencyType == "agency"
          ? form.setFieldsValue({
              projectFundingAgencyDtos: newAgency,
            })
          : clientAgencyType == "client" &&
            form.setFieldsValue({
              clientId: quickContactCompanyAdd.id,
            });
      }, 500);
    }
  }, [quickContactCompanyAdd]);

  useEffect(() => {
    if (isUpdate === "details") {
      setEditForm(false);
    } else {
      setEditForm(true);
      if (isUpdate === "add") {
        setGetDetails("");
      } else {
        setGetDetails(projectGetDetailsResponse);
      }
    }
  }, [isUpdate]);

  useEffect(() => {
    if (getDetails?.isFavorite) {
      setIsFav(true);
    } else if (isEmpty(getDetails)) {
      setIsFav(false);
    }
  }, [getDetails]);

  useEffect(() => {
    fetchAllStatus();
    fetchAllModality();
  }, []);

  useEffect(() => {
    dispatch(allCountryListing());
  }, []);

  useEffect(() => {
    dispatch(getAllSectors());
  }, []);

  const fetchAllStatus = async () => {
    const resp = await statusListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setStatus(resp.data);
    }
  };
  const fetchAllModality = async () => {
    const resp = await modalityListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setModality(resp.data);
    }
  };

  useEffect(() => {
    if (!isEmpty(projectUpdateResponse) && isUpdate !== "add") {
      setEditForm(false);
      setSearchKeyword(getDetails?.referenceCode);
      setSelectedProjectId(getDetails?.id);
      // dispatch(projectGetDetails(getDetails.id));
      setIsUpdate("details");
    }
  }, [projectUpdateResponse]);

  useEffect(() => {
    if (!isEmpty(allCurrencyListingResponse)) {
      allCurrencyListingResponse?.map((currency) => {
        if (currency.abbvr == "USD") {
          setDefaultCurrencyID(currency.currencyId);
          form.setFieldsValue({ currencyId: currency.currencyId });
        }
      });
    }
  }, [allCurrencyListingResponse]);

  // useEffect(() => {
  //   if (!isEmpty(status)) {
  //     status?.map((sorting) => {
  //       if (sorting.status == "OnProgress") {
  //         setStatus(sorting.id);
  //         form.setFieldsValue({ id: sorting.id });
  //       }
  //     });
  //   }
  // }, [status]);

  useEffect(() => {
    if (!isEmpty(status)) {
      status?.map((data) => {
        if (data.status == "OnProgress") {
          setProjectStatusId(data.id);
          form.setFieldsValue({ projectStatusId: data.id });
        }
      });
    }
  }, [status]);

  useEffect(() => {
    if (isEmpty(getDetails)) {
      form.resetFields();
      return;
    }
    setTimeout(() => {
      form.setFieldsValue({
        ...getDetails,
        projectSectorDtos: getDetails.projectSectorDtos?.map(
          (sector) => sector.sectorId
        ),
        projectCost: Number(getDetails.projectCost)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        projectFundingAgencyDtos: getDetails.projectFundingAgencyDtos.map(
          (funding) => funding.fundingAgencyId
        ),
        currencyId: defaultCurrencyId,
        clientId: getDetails.clientId,
      });
    });
  }, [getDetails, showEditForm]);

  const genExtra = () => (
    <Button
      type="link"
      style={{ paddingTop: 1 }}
      className="btn-edit"
      onClick={() => {
        setEditForm(false);
        setIsUpdate("edit");
      }}
    >
      <img src={edit} alt="edit" />
    </Button>
  );

  const height = window.innerHeight;

  const updateProject = () => {
    return (
      <div className="reference__right">
        <div
          className={`business-form-wrapper ${
            height < 600 ? "project_add_form" : ""
          }`}
        >
          <ProjectForm
            isUpdate={isUpdate}
            isFav={isFav}
            setIsFav={setIsFav}
            searchTitleProject={searchTitleProject}
            setSearchTitleProject={setSearchTitleProject}
            // status={status}
            value={value}
            setValue={setValue}
            contactCompanyList={contactCompanyList}
            setContactCompanyList={setContactCompanyList}
            setVisibleModal={setVisibleModal}
            setClientAgencyType={setClientAgencyType}
            getDetails={getDetails}
            modality={modality}
            defaultCurrencyId={defaultCurrencyId}
            setEditForm={setEditForm}
            setIsUpdate={setIsUpdate}
            projectLoadingResponse={projectLoadingResponse}
            projectUpdateLoading={projectUpdateLoading}
          />
        </div>
      </div>
    );
  };
  // useEffect(()=>{
  //   document.body.addEventListener("click", function (evt) {
  //     setSearchTitleProject([])
  // });
  // },[searchTitleProject])

  return (
    <div className="reference__right">
      {!showEditForm && isUpdate == "details" ? (
        <div style={{ width: "100%" }}>
          {renderProject({
            projectGetDetailsResponse,
            genExtra,
            numberWithCommas,
            dispatch,
          })}
        </div>
      ) : (
        <div style={{ width: "100%" }}> {updateProject()}</div>
      )}

      <Modal
        visible={visibleModal}
        footer={null}
        onCancel={() => {
          addCompany.setFieldsValue({
            scopeOfWork: [],
            companyName: "",
            countryId: "",
            isActive: false,
            isFavorite: false,
            cityId: "",
          });
          setVisibleModal(false);
        }}
        keyboard={false}
        maskClosable={false}
      >
        <QuickAddCompany
          form={addCompany}
          companySearch={value}
          quickContactCompanyAdd={quickContactCompanyAdd}
          quickContactCompanyLoader={quickContactCompanyLoader}
          quickAddFromProject="project"
        />
      </Modal>
    </div>
  );
};

export default Summary;
