import { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Row,
  Col,
  Input,
  Button,
  InputNumber,
  DatePicker,
  Checkbox,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";

import { isEmpty } from "lodash";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import {
  actionForCreateEmpRequisitionApplication,
  actionForUpdateEmpRequisitionSingleApplicationDetail,
} from "services/redux/hrSystem/action";

const { Option } = Select;

const inputFieldWidht = {
  width: "260px",
};

export default function CreateUpdateJob({
  openDrawerRecruitment,
  setOpenDrawerRecruitment,
  hrForm,
  setSalaryNegotiable,
  selectedSalaryNegotiable,
  selectedJobApplcationId,
  applicationSingleData,
}) {
  const dispatch = useDispatch();
  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);

  useEffect(() => {
    if (isEmpty(sisterCompanyListing)) {
      dispatch(getSisterCompaniesListings());
    }
  }, []);

  const onClose = () => {
    setOpenDrawerRecruitment({
      isOpen: false,
      isEditable: false,
    });
  };

  const onCreateUpdateJob = (values) => {
    let apiData = {
      ...values,
      isNegotiableSalary: selectedSalaryNegotiable,
    };

    if (!openDrawerRecruitment.isEditable) {
      dispatch(actionForCreateEmpRequisitionApplication(apiData));
    } else {
      // apiData = {
      //   ...apiData,
      //   ...applicationSingleData,
      // };
      dispatch(
        actionForUpdateEmpRequisitionSingleApplicationDetail(
          selectedJobApplcationId,
          apiData
        )
      );
    }
  };

  return (
    <>
      <Drawer
        title={
          openDrawerRecruitment.isEditable
            ? "Update Application"
            : "Create Application"
        }
        width={630}
        onClose={onClose}
        visible={openDrawerRecruitment.isOpen}
        keyboard={false}
        maskClosable={false}
      >
        <Form form={hrForm} layout="vertical" onFinish={onCreateUpdateJob}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="jobTitle"
                label="Job Title"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <Input placeholder="Job Title" style={inputFieldWidht} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="categoryName"
                label="Category"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <Input placeholder="Job Category" style={inputFieldWidht} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="jobLevel"
                label="Job Level"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <Input placeholder="Job Level" style={inputFieldWidht} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="minimumExperience"
                label="Minimum Experience"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <Input
                  placeholder="Minimum Experience"
                  style={inputFieldWidht}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="minimumEduction"
                label="Minimum Education"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <Input
                  placeholder="Minimum Education"
                  style={inputFieldWidht}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="jobLocation"
                label="Job Location"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <Input placeholder="Job Location" style={inputFieldWidht} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="sisterCompanyId"
                label="Company"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <Select
                  placeholder="Select sister company"
                  style={inputFieldWidht}
                >
                  {sisterCompanyListing.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="numberOfOpenings"
                label="No. of Openings"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <InputNumber
                  placeholder="No. of Openings"
                  style={inputFieldWidht}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="openingDate"
                label="Opening Date"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <DatePicker placeholder="Select date" style={inputFieldWidht} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="closingDate"
                label="Closing Date"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <DatePicker placeholder="Select date" style={inputFieldWidht} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="salary"
                label="Salary"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <InputNumber placeholder="Salary" style={inputFieldWidht} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="isNegotiableSalary"
                label="Salary Negotiable"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 13 }}
              >
                <Checkbox
                  onChange={(e) => {
                    setSalaryNegotiable(e.target.checked);
                  }}
                  checked={selectedSalaryNegotiable}
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ marginLeft: "8px" }}>
            <Form.Item name="applicationDescription" label="Description">
              <Input.TextArea placeholder="Description" />
            </Form.Item>
          </div>

          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right", margin: "5px 15px 0px 0px" }}
          >
            {openDrawerRecruitment.isEditable ? "Update" : "Submit"}
          </Button>
        </Form>
      </Drawer>
    </>
  );
}
