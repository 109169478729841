import React, { useEffect, useState } from "react";
import { PlusOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Tooltip, message } from "antd";
import {
  getProjectSearchPagination,
  projectFavAddUpdate,
} from "services/redux/Reference/Project/action";

import { LogoImage } from "utils/imageConstants";
// const minimize = "assets/images/icons/minimize.png";
// const maximize = "assets/images/icons/maximize.png";

export const ProjectPagination = ({
  rowEventhandlersProject,
  setSearchEmailTender,
  selectedProjectId,
  setInputSearchValue,
  setSelectedProjectId,
  projectPaginationResponse,
}) => {
  //redux store
  const [projectData, setProjectData] = useState([]);
  const dispatch = useDispatch();

  // const getData = (MaxResultCount = pageSize, SkipCount = 0, Sorting, SortType, searchKeyword = searchKeyword) => {
  // 	let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;
  // 	if (Sorting) {
  // 		appendUrl += `&sorting=${Sorting}`;
  // 	}
  // 	if (SortType) {
  // 		appendUrl += `&sortType=${SortType}`;
  // 	}
  // 	if (searchKeyword) {
  // 		appendUrl += `&searchKeyword=${searchKeyword}`;
  // 	}
  // 	dispatch(getProjectSearchPagination(appendUrl));
  // };

  useEffect(() => {
    if (!projectPaginationResponse) return;
    setProjectData(projectPaginationResponse.items);
  }, [projectPaginationResponse]);

  return (
    <>
      {isEmpty(projectData) ? (
        <div className="nulldata">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        projectData.map((data, key) => {
          const backGroundColor = key % 2 == 0 ? "" : "#f2f2f2";
          return (
            <div
              style={{ background: backGroundColor }}
              className={`project ${
                selectedProjectId === data.referenceCode && "active"
              }`}
              onClick={() => {
                rowEventhandlersProject(data.id);
                setSelectedProjectId(data.referenceCode);
                setSearchEmailTender(data.referenceCode);
              }}
              key={key}
            >
              <div className="project__wrapper">
                <div className="project__wrapper--contents">
                  <div className="project__body">
                    <div
                      className="project__body--right"
                      style={{ color: "red" }}
                    >
                      {data.isFavorite ? (
                        <div
                          onClick={() => {
                            dispatch(
                              projectFavAddUpdate({ projectId: data.id }, data)
                            );
                          }}
                        >
                          <StarFilled style={{ color: "#ffd036" }} />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            dispatch(
                              projectFavAddUpdate({ projectId: data.id }, data)
                            );
                          }}
                        >
                          <StarOutlined style={{ color: "#ffd036" }} />
                        </div>
                      )}
                    </div>
                    <div className="project__body--right">
                      <span className="project__body--code">
                        {data.referenceCode}
                      </span>
                    </div>
                  </div>
                  <div className="project__main">
                    <div className="project__main--middle">
                      <span className="project__body--title">
                        <Tooltip title={data.title} placement="topLeft">
                          {data.title}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  <div className="project__end">
                    {/* <div className="project__end--date">
											<span className="prooject__body--date">{moment(data.creationTime).format('YYYY/MM/DD')}</span>
										</div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export const ProjectPaginationModal = ({
  searchKeyword,
  projectData,
  setSelectedRows,
  selectedRows,
}) => {
  //redux store

  const [totalCount, setTotalCount] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { projectPaginationResponse, projectPaginationLoading } = useSelector(
    (state) => state.project
  );

  const pageSize = 100;

  const selectedMultipleRows = (item) => {
    setSelectedRows([...selectedRows, item.referenceCode]);
    if (selectedRows.includes(item.referenceCode)) {
      // className={`reference ${selectedTenderRowId === data.id && 'active'}`}
      message.warning(`Project ${item.referenceCode} already selected`);
    } else {
      console.log("items not repeated");
    }
  };

  // const getData = (MaxResultCount = pageSize, SkipCount = 0, Sorting, SortType, searchKeyword = searchKeyword) => {
  // 	let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;
  // 	if (Sorting) {
  // 		appendUrl += `&sorting=${Sorting}`;
  // 	}
  // 	if (SortType) {
  // 		appendUrl += `&sortType=${SortType}`;
  // 	}
  // 	if (searchKeyword) {
  // 		appendUrl += `&searchKeyword=${searchKeyword}`;
  // 	}
  // 	dispatch(getProjectSearchPagination(appendUrl));
  // };

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    ForPagination = false
  ) => {
    dispatch(
      getProjectSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: searchKeyword,
        },
        ForPagination
      )
    );
  };

  //   useEffect(() => {
  //     if (!projectPaginationResponse) return;
  //     setProjectData(projectPaginationResponse.items);
  //   }, [projectPaginationResponse]);

  return (
    <>
      {isEmpty(projectData) ? (
        <div className="nulldata">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        projectData.map((data, key) => {
          const backGroundColor = key % 2 == 0 ? "" : "#f2f2f2";
          return (
            <div
              onClick={(e) => {
                selectedMultipleRows(data);
              }}
              style={{ background: backGroundColor }}
              className="project"
              key={key}
            >
              <div className="project__wrapper">
                <div className="project__wrapper--contents">
                  <div className="project__body">
                    <div
                      className="project__body--right"
                      style={{ color: "red" }}
                    >
                      {data.isFavorite ? (
                        <div
                          onClick={() => {
                            dispatch(
                              projectFavAddUpdate({ projectId: data.id }, data)
                            );
                          }}
                        >
                          <StarFilled style={{ color: "#ffd036" }} />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            dispatch(
                              projectFavAddUpdate({ projectId: data.id }, data)
                            );
                          }}
                        >
                          <StarOutlined style={{ color: "#ffd036" }} />
                        </div>
                      )}
                    </div>
                    <div className="project__body--right">
                      <span className="project__body--code">
                        {data.referenceCode}
                      </span>
                    </div>
                  </div>
                  <div className="project__main">
                    <div className="project__main--middle">
                      <span className="project__body--title">
                        <Tooltip title={data.title} placement="topLeft">
                          {data.title}
                        </Tooltip>
                        {data.title}
                      </span>
                    </div>
                  </div>
                  <div className="project__end">
                    {/* <div className="project__end--date">
											<span className="prooject__body--date">{moment(data.creationTime).format('YYYY/MM/DD')}</span>
										</div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </>
  );
};
