import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Layout, Empty, Spin } from "antd";
import { getPermissionListsForRole } from "services/redux/adminSettings/roles/action";
import { isEmpty } from "lodash";

const { Header } = Layout;

function ViewPermissionDrawer({
  drawerVisibility,
  onViewPermissionClose,
  permissionDetails,
  permissionListsForRoleLoading,
  selectedPermissionList,
}) {
  // const dispatch = useDispatch();

  // const { permissionListsForRoleResp, permissionListsForRoleLoading } =
  //   useSelector((state) => state.roleLists);

  // const [selectedPermissionList, setSelectedPermissionList] = useState([]);

  // useEffect(() => {
  //   if (!isEmpty(permissionDetails)) {
  //     console.log("permissionDetails: ", permissionDetails);
  //     dispatch(getPermissionListsForRole(permissionDetails));
  //   }
  // }, [permissionDetails, dispatch]);

  // useEffect(() => {
  //   if (!isEmpty(permissionListsForRoleResp)) {
  //     const newSelectedPermissionLists = permissionListsForRoleResp.filter(
  //       (list) => list.isGranted
  //     );
  //     setSelectedPermissionList(newSelectedPermissionLists);
  //   }
  // }, [permissionListsForRoleResp]);

  return (
    <Drawer
      visible={drawerVisibility}
      width={550}
      className="drawer main-drawer-wrapper"
      onClose={onViewPermissionClose}
      closable={true}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">
          {permissionDetails} Permissions
        </h4>
      </Header>
      <div className="drawer__content">
        <div className="drawer__content--wrapper">
          <div className="contact__content__wrapper hr-module-wrapper">
            {permissionListsForRoleLoading ? (
              <Spin
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ) : (
              <div
                className="contact__content__wrapper hr-module-wrapper"
                style={{ padding: "0px 9px" }}
              >
                {selectedPermissionList.length === 0 ? (
                  <Empty description="No Permissions" />
                ) : (
                  selectedPermissionList.map((list, index) => (
                    <div key={index}>{list?.name}</div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default ViewPermissionDrawer;
