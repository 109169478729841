import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row ,Form} from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import AddUserDrawer from "./AddUserDrawer";
import CityAddUpdateTable from "./CityAddUpdateTable";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import CityAddUpdateDrawer from "./CityAddUpdateDrawer";

const CityAddUpdate = () => {
  const dispatch = useDispatch();
   const [form] = Form.useForm();
   const onReset = () => {
     form.resetFields();
   };


  const [addUpdateCountryDrawerVisibility, setAddUpdateCountryDrawerVisibility] = useState(false);
  const [
    updateProfileDrawerVisibility,
    setUpdateProfileDrawerVisibility,
  ] = useState(false);
  const [isUpdateCountry, setIsUpdateCountry] = useState(false);

  const showAddUpdateCountryDrawer = (e, record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };

  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry('');
    setAddUpdateCountryDrawerVisibility(false);
  };

  return (
    <div className='layout'>
      <div className="organizer events">
          <div className="all-header-wrapper">
              <div className="meeting__flex">
                  <h3 className="header__all">City List</h3>
                  <div className="organizerHoliday__middle--wrap">
                    <Button
                      className='btn-primary'
                      onClick={() => {
                        showAddUpdateCountryDrawer();
                      }}
                      >
                      <PlusOutlined /> New City
                    </Button>
                  </div>
              </div>
          </div>
        <CityAddUpdateTable
          showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
        />
        <CityAddUpdateDrawer
          drawerVisibility={addUpdateCountryDrawerVisibility}
          onAddUpdateCountryClose={onAddUpdateCountryClose}
          isUpdateCountry={isUpdateCountry}
        />
      </div>
    </div>
  );
};

export default CityAddUpdate;
