import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from 'antd';
import { isEmpty, set } from "lodash";
import { getLeaveApplicationPagination } from "services/redux/requistions/leaveApplication/action";
import { getRemainingLeaveApplicationTypesForEmployee } from "services/redux/requistions/leaveApplication/action";
import LeaveTemplateDetailTable from "./LeaveTemplateDetailTable";
import useInfiniteScroll from "utils/useInfinityScroll";

const LeaveTemplateTable = ({ employeeUserId, appUserId }) => {
    const dispatch = useDispatch();

    const { data, totalData, skipCount, setPaginationSize, setResponseData } = useInfiniteScroll("leave-table-scroll");

    const {
        leaveApplicationPagination,
        leaveApplicationPaginationLoading,
        remainingLeaveApplicationTypesListing,
        remainingLeaveApplicationTypesListingLoading,
        addLeaveApplicationResp,
    } = useSelector((state) => state.leaveApplication);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [hideLeaveDrawer, setHideLeaveDrawer] = useState(true);
    const [leaveDetailsId, setLeaveDetailsId] = useState("");
    const [showLeaveDetails, setShowLeaveDetails] = useState(false);
    const [leaveDetails, setLeaveDetails] = useState([]);

    useEffect(() => {
        if (!isEmpty(addLeaveApplicationResp))
          dispatch(getRemainingLeaveApplicationTypesForEmployee());
      }, [addLeaveApplicationResp]);

    // useEffect(() => {
    // const getLeaveDetailsData = leaveApplicationPagination.items?.filter(
    //     (item) => {
    //     if (item.id == leaveDetailsId) return item;
    //     }
    // );
    // setLeaveDetails(getLeaveDetailsData);
    // }, [leaveDetailsId]);


  //pagination action
  const fetchData = (
    MaxResultCount = 10,
    skipCount = 0,
    searchKeyword = "",
    forPagination = false
  ) => {
    dispatch(
      getLeaveApplicationPagination(
        {
          MaxResultCount: MaxResultCount,
          skipCount: skipCount,
          searchKeyword,
          EmployeeId: employeeUserId
        },
        forPagination
      )
    );
  };
  useEffect(() => {
    if (data.length != totalData && skipCount > 0 && data.length <= totalData) {
      fetchData(10, skipCount, searchKeyword, true);
    }
  }, [searchKeyword, skipCount]);
  useEffect(() => {
    setPaginationSize(10);
    dispatch(getRemainingLeaveApplicationTypesForEmployee({ EmployeeId: employeeUserId}));
    fetchData();
  }, [employeeUserId]);
  useEffect(() => {
    setResponseData(leaveApplicationPagination);
  }, [leaveApplicationPagination]);
  //end of pagination


    return (
        <>
            <div className="global-header-wrapper">
              <h3 className="header__all">
                <span className="title">Summary</span>
              </h3>
            </div>
            <div className="leave-summary-wrapper w-75">
                <div className="leave-summary-items">
                    <div className="leave-summary-header-items-wrapper">
                    <div className="leave-summary-header-items col-7 text-center border-right">
                        <div className="leave-summary-header">
                        <h2>Provisioned</h2>
                        </div>
                        <div className="leave-summary-header">
                        <h2>Allowed</h2>
                        </div>
                        <div className="leave-summary-header">
                        <h2>Applied</h2>
                        </div>
                        <div className="leave-summary-header">
                        <h2>Approved</h2>
                        </div>
                        <div className="leave-summary-header">
                        <h2>Rejected</h2>
                        </div>
                        <div className="leave-summary-header">
                        <h2>Pending</h2>
                        </div>
                        <div className="leave-summary-header">
                        <h2>Remaining</h2>
                        </div>
                    </div>
                    </div>
                    <div className="leave-summary-details-items-wrapper">
                    {remainingLeaveApplicationTypesListing?.map((item) => {
                        return (
                        <div
                            className="leave-summary-details-items col-6 text-center border-right"
                            key={item.id}
                        >
                            <div className="leave-summary-details">
                            <p>{item?.leaveApplicationTypeDto?.leaveTypeName}</p>
                            </div>
                            <div className="leave-summary-details">
                            <p>{item?.allowedleave ?? 0}</p>
                            </div>
                            <div className="leave-summary-details">
                            <p>{item?.appliedLeave ?? 0}</p>
                            </div>
                            <div className="leave-summary-details">
                            <p>{item?.approvedLeave ?? 0}</p>
                            </div>
                            <div className="leave-summary-details">
                            <p>{item?.unApprovedLeave ?? 0}</p>
                            </div>
                            <div className="leave-summary-details">
                            <p className="text-red">{item?.pendingLeave ?? 0}</p>
                            </div>
                            <div className="leave-summary-details">
                            <p
                                className={
                                item?.remainingLeave > 0
                                    ? "text-green"
                                    : "text-red"
                                }
                            >
                                {item?.remainingLeave > 0
                                ? item?.remainingLeave
                                : 0}
                            </p>
                            </div>
                        </div>
                        );
                    })}
                    </div>
                </div>
            </div>
            <div className="global-header-wrapper">
              <h3 className="header__all">
                <span className="title">Details</span>
              </h3>
            </div>
            <div className="leave-summary-wrapper">
              <LeaveTemplateDetailTable
                data={data}
                totalData={totalData}
                leaveApplicationPaginationLoading={
                  leaveApplicationPaginationLoading
                }
                
              />
            </div>
        </>
    )
}

export default LeaveTemplateTable;