import React, { useEffect, useState } from "react";

// antd
import { Table, Tag, Button, Form, Row, Col } from "antd";

// redux
import { useDispatch, useSelector } from "react-redux";

// utils
import { LogoImage } from "utils/imageConstants";
import moment from "moment";
const editIcon = "./assets/svg/profile-edit.svg";
const SiscoView = ({
  isUpdateSiscoCredentials,
  showAddUpdateSisterCompanyDrawer,
}) => {
  return (
    <div className="profile__info profile__wrap--sister">
      <div className="profile__info--wrapper">
        <div className="profile__info--body">
          <div className="contactsDetails__info mb-3 sisco-header-wapper">
            <span className="contactsDetails--title bold w-100">Basic</span>
            <div className="sisco-action-button">
              <div
                onClick={() => {
                  showAddUpdateSisterCompanyDrawer(isUpdateSiscoCredentials);
                }}
              >
                <img src={editIcon} />
              </div>
            </div>
          </div>
          {!isUpdateSiscoCredentials?.creationTime ? (
            ""
          ) : (
            <div className="profile__row sister">
              <div className="profile__row--label sister--label">
                <span className="profile__bodyTitle">Creation Time</span>
              </div>
              <div className="profile__row--data sister--data">
                <span className="profile__bodyInfo">
                  {moment(isUpdateSiscoCredentials?.creationTime).format(
                    "YYYY/MM/DD"
                  )}
                </span>
              </div>
            </div>
          )}
          {!isUpdateSiscoCredentials?.domainName ? (
            ""
          ) : (
            <div className="profile__row sister">
              <div className="profile__row sister--label">
                <span className="profile__bodyTitle">Domain Name</span>
              </div>
              <div className="profile__row--data sister-data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.domainName}
                </span>
              </div>
            </div>
          )}
          {!isUpdateSiscoCredentials?.smtpHost ? (
            ""
          ) : (
            <div className="profile__row sister">
              <div className="profile__row--label sister--label">
                <span className="profile__bodyTitle">SMTP Host</span>
              </div>
              <div className="profile__row--data sister--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.smtpHost}
                </span>
              </div>
            </div>
          )}
          {!isUpdateSiscoCredentials?.imapHost ? (
            ""
          ) : (
            <div className="profile__row sister">
              <div className="profile__row--label sister--label">
                <span className="profile__bodyTitle">IMAP Host</span>
              </div>
              <div className="profile__row--data sister--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.imapHost}
                </span>
              </div>
            </div>
          )}

          {!isUpdateSiscoCredentials?.isActive ? (
            ""
          ) : (
            <div className="profile__row">
              <div className="profile__row--label">
                <span className="profile__bodyTitle">Active </span>
              </div>
              <div className="profile__row--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.isActive ? "True" : "False"}
                </span>
              </div>
            </div>
          )}

          {!isUpdateSiscoCredentials?.name ? (
            ""
          ) : (
            <div className="profile__row">
              <div className="profile__row--label">
                <span className="profile__bodyTitle">Name </span>
              </div>
              <div className="profile__row--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.name}
                </span>
              </div>
            </div>
          )}

          {!isUpdateSiscoCredentials?.port ? (
            ""
          ) : (
            <div className="profile__row">
              <div className="profile__row--label">
                <span className="profile__bodyTitle">Port </span>
              </div>
              <div className="profile__row--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.port}
                </span>
              </div>
            </div>
          )}

          {/* Added */}
          {/* {!isUpdateSiscoCredentials?.address ? (
            ""
          ) : (
            <div className="profile__row">
              <div className="profile__row--label">
                <span className="profile__bodyTitle">Address </span>
              </div>
              <div className="profile__row--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.address}
                </span>
              </div>
            </div>
          )}
          {!isUpdateSiscoCredentials?.website ? (
            ""
          ) : (
            <div className="profile__row">
              <div className="profile__row--label">
                <span className="profile__bodyTitle">Website </span>
              </div>
              <div className="profile__row--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.website}
                </span>
              </div>
            </div>
          )}
          {!isUpdateSiscoCredentials?.linkedin ? (
            ""
          ) : (
            <div className="profile__row">
              <div className="profile__row--label">
                <span className="profile__bodyTitle">Linkedin </span>
              </div>
              <div className="profile__row--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.linkedin}
                </span>
              </div>
            </div>
          )}
          {!isUpdateSiscoCredentials?.companyRegistrationNumber ? (
            ""
          ) : (
            <div className="profile__row">
              <div className="profile__row--label">
                <span className="profile__bodyTitle">Company Registration Number </span>
              </div>
              <div className="profile__row--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.companyRegistrationNumber}
                </span>
              </div>
            </div>
          )}
          {!isUpdateSiscoCredentials?.companyRegistrationDate ? (
            ""
          ) : (
            <div className="profile__row">
              <div className="profile__row--label">
                <span className="profile__bodyTitle">Company Registration Date </span>
              </div>
              <div className="profile__row--data">
                <span className="profile__bodyInfo">
                  {isUpdateSiscoCredentials?.companyRegistrationDate}
                </span>
              </div>
            </div>
          )} */}


          <div className="profile__row">
            <div className="profile__row--label">
              <span className="profile__bodyTitle">Address </span>
            </div>
            <div className="profile__row--data">
              <span className="profile__bodyInfo">
                {isUpdateSiscoCredentials?.address ? isUpdateSiscoCredentials?.address : ''}
              </span>
            </div>
          </div>

          <div className="profile__row">
            <div className="profile__row--label">
              <span className="profile__bodyTitle">Website </span>
            </div>
            <div className="profile__row--data">
              <span className="profile__bodyInfo">
                {isUpdateSiscoCredentials?.website ? isUpdateSiscoCredentials?.website : ''}
              </span>
            </div>
          </div>

          <div className="profile__row">
            <div className="profile__row--label">
              <span className="profile__bodyTitle">Linkedin </span>
            </div>
            <div className="profile__row--data">
              <span className="profile__bodyInfo">
                {isUpdateSiscoCredentials?.linkedin ? isUpdateSiscoCredentials?.linkedin : ''}
              </span>
            </div>
          </div>
          

          <div className="profile__row">
            <div className="profile__row--label">
              <span className="profile__bodyTitle">Pan Number </span>
            </div>
            <div className="profile__row--data">
              <span className="profile__bodyInfo">
                {isUpdateSiscoCredentials?.panNumber ? isUpdateSiscoCredentials?.panNumber : ''}
              </span>
            </div>
          </div>

          <div className="profile__row">
            <div className="profile__row--label">
              <span className="profile__bodyTitle">Registration Number </span>
            </div>
            <div className="profile__row--data">
              <span className="profile__bodyInfo">
                {isUpdateSiscoCredentials?.companyRegistrationNumber ? isUpdateSiscoCredentials?.companyRegistrationNumber : ''}
              </span>
            </div>
          </div>

          <div className="profile__row">
            <div className="profile__row--label">
              <span className="profile__bodyTitle">Registration Date </span>
            </div>
            <div className="profile__row--data">
              <span className="profile__bodyInfo">
                {isUpdateSiscoCredentials?.companyRegistrationDate ? moment(isUpdateSiscoCredentials?.companyRegistrationDate).format('YYYY-MM-DD') : ''}
              </span>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default SiscoView;
