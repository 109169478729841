import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService } from "services/commonServices";

export class FolderService {
  getDocumentListing() {
    let url = `${appBaseUrl}/documentRequest/dMSParentFolderStructureList`;
    let data = getService(url);
    return data;
  }
  getAllChildFolders(id, sorter) {
    if (sorter) {
      let url = `${appBaseUrl}/documentRequest/dMSImmediateChildFolderList/${id}?sorting=creationtime`;
      let data = getService(url);
      return data;
    } else {
      let url = `${appBaseUrl}/documentRequest/dMSImmediateChildFolderList/${id}`;
      let data = getService(url);
      return data;
    }
  }
  addFolder(body) {
    let url = `${appBaseUrl}/documentRequest/dMSFolderAddUpdate`;
    let data = postService(url, body);
    return data;
  }
  uploadFiles(body) {
    let url = `${appBaseUrl}/documentRequest/dMSDocumentUpload`;
    let data = postService(url, body);
    return data;
  }
}
