import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  DatePicker,
  Button,
  Col,
  Row,
  Input,
  Select,
  Layout,
  Checkbox,
} from "antd";
import { validateMessages } from "utils/misc";
import { holidayEventBulkAddUpdate } from "services/redux/holiday/action";
import moment from "moment";
import { isEmpty } from "lodash";

const { Header, Footer } = Layout;
const { Option } = Select;

const HolidayEventAddUpdateDrawer = ({
  drawerVisibility,
  isUpdateCountry,
  onAddUpdateCountryClose,
  onCloseNoticeBoard,
}) => {
  const { holidayAddResponse } = useSelector((state) => state.holiday);

  const [form] = Form.useForm();
  const [complainDetail, setComplaintDetail] = useState();
  form.resetFields();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdateCountry) {
      form.resetFields();
      return;
    } else {
      form.setFieldsValue({
        ...isUpdateCountry,
        date: moment(isUpdateCountry.date),
      });
    }
  }, [isUpdateCountry]);
  useEffect(() => {
    onAddUpdateCountryClose();
  }, [holidayAddResponse]);

  useEffect(() => {
    if (!isEmpty(holidayAddResponse)) {
      setTimeout(() => {
        onCloseNoticeBoard();
        form.resetFields();
      }, 500);
      setComplaintDetail(holidayAddResponse);
    }
  }, [holidayAddResponse]);

  // const onSubmit = async () => {
  //     try{
  //         let values = await form.validateFields();
  //         if (isUpdateCountry) {
  //           dispatch(holidayEventBulkAddUpdate(isUpdateCountry.id,isUpdateCountry.date, values));
  //         } else {
  //           dispatch(holidayEventBulkAddUpdate(values));
  //         }
  //     }catch(errorInfo) {
  //         console.error("failed", errorInfo);
  //     }
  // };
  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      let body = {
        ...values,
        date: moment(values.date).format("YYYY/MM/DD"),
      };
      // let status = 'Added';
      if (!isEmpty(isUpdateCountry)) {
        body.id = isUpdateCountry.id;
        // status = 'Updated'
      }
      dispatch(holidayEventBulkAddUpdate([body]));
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };
  return (
    <Drawer
      visible={drawerVisibility}
      onClose={onAddUpdateCountryClose}
      width={472}
      maskClosable={false}
      className="drawer"
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">Holiday Event</h4>
      </Header>
      <Form
        form={form}
        layout="vertical"
        name="control-hooks"
        validateMessages={validateMessages}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <Form.Item
            className="form__group"
            name="title"
            label="Title"
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </div>
        <div className="drawer-form-row">
          <Form.Item
            className="form__group"
            name="remarks"
            label="Remarks"
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </div>
        <div className="drawer-form-row">
          <Form.Item
            label="Date"
            name="date"
            className="form__group"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
            initialValue={moment()}
          >
            <DatePicker
              defaultValue={moment()}
              // disabledDate={(current) => {
              //   return (
              //     current &&
              //     (form.getFieldValue("startDate")
              //       ? current < form.getFieldValue("startDate")
              //       : current < moment())
              //   );
              // }}
              format="YYYY/MM/DD"
              autoComplete="off"
            />
          </Form.Item>
        </div>
        <div className="drawer-form-row">
          {/* <div className="drawer-flex"> */}
          <Form.Item
            name="isActive"
            valuePropName="checked"
            initialValue={false}
            className="form__group"
          >
            <Checkbox>Is Active</Checkbox>
          </Form.Item>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateCountryClose}>Cancel</Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default HolidayEventAddUpdateDrawer;
