import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Image,
  Skeleton,
  message,
  Tooltip,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { PlusOutlined, StarFilled, PaperClipOutlined } from "@ant-design/icons";
import { cqBaseUrl } from "utils/config";
import {
  contactLabelGroupListing,
  dynamicFileLabelTypeUploaderDetailDtos,
} from "services/remanentCalls";
import { Link } from "react-router-dom";
import { clone, cloneDeep, groupBy, isEmpty, set } from "lodash";
import {
  contactCompanyGetDetails,
  contactPersonGetDetails,
  contactCompanyFav,
} from "../../../services/redux/contact/companypeople/action";
export const Showdataform = ({
  showAddUpdateCountryDrawer,
  grantedPolices,
  data,
  setEditCompany,
}) => {
  const [contactLabelGroup, setContactLabelGroup] = useState([]);
  const [favData, setFavData] = useState();
  // const[filterfav,setfa]
  const [uploadersExceptProfile, setUploadersExceptProfile] = useState([]);
  const [groupedByDocuments, setGroupedByDocuments] = useState([]);
  const {
    contactCompanyDetailAddResponse,
    contactCompanyUpdateResponse,
    contactCompanyGetDetailsResponse,
  } = useSelector((state) => state.contactCompanyPerson);
  const dispatch = useDispatch();
  const [test, setTest] = useState();

  useEffect(() => {
    fetchContactLabelGroupListing();
    fetchDynamicFileLabelTypeUploaderDetailDtos();
  }, []);

  useEffect(() => {
    fetchContactLabelGroupListing();
    fetchDynamicFileLabelTypeUploaderDetailDtos();
  }, []);

  useEffect(() => {
    if (contactCompanyGetDetailsResponse?.uploadedFileDtos) {
      let documentsUploadedFiles = cloneDeep(
        contactCompanyGetDetailsResponse?.uploadedFileDtos
      );

      let groupByDocuments = groupBy(
        documentsUploadedFiles,
        "fileLableTypeName"
      );
      setTest(contactCompanyGetDetailsResponse);
      setGroupedByDocuments(groupByDocuments);
    } else {
      setGroupedByDocuments([]);
    }
  }, [contactCompanyGetDetailsResponse]);

  // console.log(groupedByDocuments);

  const fetchDynamicFileLabelTypeUploaderDetailDtos = async () => {
    const resp = await dynamicFileLabelTypeUploaderDetailDtos("contact").catch(
      (err) => {
        message.error("Failure");
      }
    );

    if (resp && resp.status === 200) {
      let newUploadersExcept = resp.data.filter(
        (respData) => respData.name !== "Profile"
      );
      setUploadersExceptProfile(newUploadersExcept);
    }
  };

  const companyAvatar = "assets/svg/company-avatar1.svg";

  const { token } = useSelector((state) => state.auth);

  const fetchContactLabelGroupListing = async (body) => {
    const resp = await contactLabelGroupListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setContactLabelGroup(resp.data);
    }
  };

  const renderdataorNA = (data) => data || "na";
  if (!contactCompanyGetDetailsResponse) {
    return (
      <Skeleton
        avatar={{ size: 96, shape: "square" }}
        paragraph={{ rows: 10 }}
      />
    );
  }
  // console.log("groupBy", groupedByDocuments);

  return (
    <div className="contactsDetails">
      {/* { SearchKeyword === contactCompanyGetDetailsResponse?.companyName ? "data" : "no"} */}
      {/* {!ContactCompanyId  ? "Hi there there is no data" : "Oops no data at all"} */}

      <div className="contactsDetails__main">
        <div className="contactDetail header">
          <div className="contact__details__image">
            {isEmpty(contactCompanyGetDetailsResponse?.uploadedFileDtos) ? (
              <span>
                <Image src={companyAvatar} />
              </span>
            ) : (
              contactCompanyGetDetailsResponse?.uploadedFileDtos.map(
                //For accessing image
                (imagefile) => (
                  <>
                    {imagefile.fileLableTypeName === "Profile" && (
                      <Image
                        src={cqBaseUrl + "/" + imagefile.viewFileURL + token}
                      />
                    )}
                  </>
                )
              )
            )}

            {/* {contactCompanyGetDetailsResponse?.uploadedFileDtos?.map(
								//For accessing image
								(imagefile) => (
									<div>{renderdataorNA(<Image src={cqBaseUrl + '/' + imagefile.viewFileURL + token} />)}</div>
								)
							)} */}
          </div>

          <div className="right-header">
            <div style={{ display: "flex" }}>
              <span
                onClick={() => {
                  dispatch(
                    contactCompanyFav(
                      {
                        contactCompanyId: contactCompanyGetDetailsResponse?.id,
                      },
                      contactCompanyGetDetailsResponse
                    )
                  );
                }}
                style={{ marginLeft: -20, width: 20 }}
              >
                {
                  <StarFilled
                    style={{
                      color: `${
                        contactCompanyGetDetailsResponse?.isFavorite
                          ? "#FBA525"
                          : "grey"
                      }`,
                    }}
                  />
                }
              </span>
              {!contactCompanyGetDetailsResponse?.companyName ? (
                " "
              ) : (
                <>
                  <div className="contactsDetails__infoheader noToolTip">
                    {/* <span className="contactsDetails--title bold w-100">Name :</span> */}
                    <span className="contactsDetails--subtitle bold">
                      {renderdataorNA(
                        contactCompanyGetDetailsResponse?.companyName
                      )}
                    </span>
                  </div>

                  <div className="contactsDetails__infoheader tooltip">
                    {/* <span className="contactsDetails--title bold w-100">Name :</span> */}
                    <Tooltip
                      title={contactCompanyGetDetailsResponse?.companyName}
                      placement="topLeft"
                    >
                      <span className="contactsDetails--subtitle bold">
                        {renderdataorNA(
                          contactCompanyGetDetailsResponse?.companyName
                        )}
                      </span>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
            {!contactCompanyGetDetailsResponse?.companyAbbrevation ? (
              ""
            ) : (
              <div className="contactsDetails__infoheader">
                {/* <span className="contactsDetails--title bold w-100">Abbrevation :</span> */}
                <span className="contactsDetails--subtitle bold">
                  "
                  {renderdataorNA(
                    contactCompanyGetDetailsResponse?.companyAbbrevation
                  )}
                  "
                </span>
              </div>
            )}

            {!contactCompanyGetDetailsResponse?.mailAddress ? (
              ""
            ) : (
              <div className="contactsDetails__infoheader">
                {/* <span className="contactsDetails--title bold w-100">Mail Address :</span> */}
                <span className="contactsDetails--headersubtitle bold">
                  {renderdataorNA(
                    contactCompanyGetDetailsResponse?.mailAddress
                  )}
                </span>
              </div>
            )}

            {!contactCompanyGetDetailsResponse?.scopeOfWork ? (
              " "
            ) : (
              <div className="contactsDetails__infoheader">
                {/* <span className="contactsDetails--title bold w-100">Tag Names :</span> */}
                <span className="contactsDetails--headersubtitle bold">
                  {renderdataorNA(
                    contactCompanyGetDetailsResponse?.scopeOfWork
                  )}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="contactDetails" style={{ height: "68vh" }}>
          <div>
            {contactLabelGroup.map((label, index) => {
              let dataPresent = false;
              contactCompanyGetDetailsResponse?.contactCompanyGroupLabelDtos?.map(
                (name) => {
                  if (
                    name.contactLabelGroupName === label.name &&
                    name.contactLabelDataValue
                  ) {
                    dataPresent = true;
                  }
                }
              );

              return (
                label.name !== "Address" && (
                  <div className="contactsDetails__infoMain" key={index}>
                    {dataPresent && (
                      <div className="contactsDetails__info mb-3">
                        <span className="contactsDetails--title bold w-100">
                          {label.name}
                        </span>
                      </div>
                    )}

                    {contactCompanyGetDetailsResponse?.contactCompanyGroupLabelDtos?.map(
                      (name) =>
                        name.contactLabelDataValue &&
                        name.contactLabelGroupName === label.name && (
                          <div className="contactsDetails__info">
                            <div className="contactDetails__label">
                              <span className="contactsDetails--title bold w-100">
                                {name.contactLabelName}
                              </span>
                            </div>

                            <div className="contactDetails__labeldata  noToolTip">
                              <span className="contactsDetails--subtitle bold">
                                {name.contactLabelDataValue}
                              </span>
                            </div>
                            <div className="contactDetails__labeldata  tooltip">
                              <Tooltip title={name.contactLabelDataValue}>
                                <span className="contactsDetails--title bold w-100">
                                  {name.contactLabelDataValue}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                )
              );
            })}
          </div>

          <div>
            {contactLabelGroup.map((label, index) => {
              let dataPresent = false;
              contactCompanyGetDetailsResponse?.contactCompanyGroupAddressDtos?.map(
                (name) => {
                  if (
                    name.contactLabelGroupName === label.name &&
                    (name.streetName ||
                      name.streetName ||
                      name.cityName ||
                      name.postalCodeZip ||
                      name.province ||
                      name.countryName)
                  ) {
                    dataPresent = true;
                  }
                }
              );

              return (
                label.name == "Address" && (
                  <div className="contactsDetails__infoMain" key={index}>
                    {dataPresent && (
                      <div className="contactsDetails__info mb-3">
                        <span className="contactsDetails--title bold w-100">
                          {label.name}
                        </span>
                      </div>
                    )}

                    {contactCompanyGetDetailsResponse?.contactCompanyGroupAddressDtos?.map(
                      (name) =>
                        (name.streetName ||
                          name.streetName ||
                          name.cityName ||
                          name.postalCodeZip ||
                          name.province ||
                          name.countryName) &&
                        name.contactLabelGroupName === label.name && (
                          <div
                            className="contactsDetails__subinfo"
                            style={{ height: "auto" }}
                          >
                            <div style={{ width: "100%", display: "flex" }}>
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title sub w-100">
                                  {name.contactLabelName}{" "}
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle">
                                  <div>
                                    {!name.streetName ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.streetName
                                          .charAt(0)
                                          .toUpperCase() +
                                          name.streetName.slice(1)}
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    {!name.cityName ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.cityName.charAt(0).toUpperCase() +
                                          name.cityName.slice(1)}
                                        ,
                                      </span>
                                    )}
                                    {!name.postalCodeZip ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.postalCodeZip},
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    {!name.stateProvince ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.stateProvince},
                                      </span>
                                    )}
                                    {!name.countryName ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.countryName
                                          .charAt(0)
                                          .toUpperCase() +
                                          name.countryName.slice(1)}
                                      </span>
                                    )}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                )
              );
            })}
          </div>

          {/* // )} */}

          {/* <div className="contactsDetails__infoMain">
						<div className="contactsDetails__info mb-3">
							<span className="contactsDetails--title bold w-100">Address </span>
						</div>
						{contactCompanyGetDetailsResponse?.contactCompanyGroupAddressDtos.map((name) => (
							<>
								{!name.contactLabelName ? (
									''
								) : (
									<div>
										<div className="contactsDetails__subinfo">
											<div className="contactDetails__label">
												<span className="contactsDetails--title sub w-100">{name.contactLabelName} </span>
											</div>
											<div className="contactDetails__labeldata">
												<span className="contactsDetails--subtitle">
													{name.streetName} ,{name.cityName} {name.postalCodeZip} , {name.province} , {name.countryName}
												</span>
											</div>
										</div>
									</div>
								)}
							</>
						))}
					</div> */}

          {contactCompanyGetDetailsResponse?.scopeOfWork ||
          contactCompanyGetDetailsResponse?.tagNames ? (
            <div>
              <div className="contactsDetails__info mb-3">
                <span className="contactsDetails--title bold w-100">
                  Background
                </span>
              </div>

              {!contactCompanyGetDetailsResponse?.tagNames ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Tag
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {renderdataorNA(
                        contactCompanyGetDetailsResponse?.tagNames
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!contactCompanyGetDetailsResponse?.scopeOfWork ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Source
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {renderdataorNA(
                        contactCompanyGetDetailsResponse?.contactSourceName
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <span></span>
          )}

          {/* {contactLabelGroup.map(
							(label, index) =>
								label.name !== 'Address' && (
									<div className="contactsDetails__infoMain" key={index}>
										<div className="contactsDetails__info mb-3">
											<span className="contactsDetails--title bold w-100">{label.name}:</span>
										</div>

										{contactCompanyGetDetailsResponse?.contactCompanyGroupLabelDtos.map(
											(name) =>
												name.contactLabelDataValue &&
												name.contactLabelGroupName === label.name && (
													<div div className="contactsDetails__subinfo">
														<span className="contactsDetails--title sub w-100">{name.contactLabelName}:</span>
														<span className="contactsDetails--subtitle">{name.contactLabelDataValue}</span>
													</div>
												)
										)}
									</div>
								)
						)} */}

          {!isEmpty(groupedByDocuments) &&
            !isEmpty(contactCompanyDetailAddResponse?.uploadedFileDtos) &&
            uploadersExceptProfile.map((file) => {
              let fileName = file.name;

              return (
                <>
                  <div className="contactsDetails__info">
                    <div className="contactDetails__label">
                      <span className="contactsDetails--title bold w-100">
                        Document
                      </span>
                    </div>
                    <div className="contactDetails__labeldata">
                      <span className="contactsDetails--subtitle bold">
                        {fileName &&
                          groupedByDocuments.hasOwnProperty(fileName) &&
                          groupedByDocuments[`${fileName}`].map((doc) => {
                            return (
                              doc.isActive && (
                                <div className="contact-document-wrapper">
                                  <PaperClipOutlined />{" "}
                                  <a
                                    target="_blank"
                                    rel="opener"
                                    download
                                    href={
                                      cqBaseUrl + "/" + doc.viewFileURL + token
                                    }
                                  >
                                    {doc.displayFileName}
                                  </a>
                                </div>
                              )
                            );
                          })}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
      {!contactCompanyGetDetailsResponse?.id ? (
        <Skeleton />
      ) : (
        <Row gutter={[8, 0]} style={{ position: "absolute", bottom: 15 }}>
          <Col lg={8}>
            <div
              className="company-person-edit-btn"
              onClick={() => {
                setEditCompany("edit");
              }}
            >
              Edit
              {/* <Link
            to={`/addUpdatecompanyDrawer?id=${contactCompanyGetDetailsResponse?.id}`}
            target="_blank"
            rel="opener"
            className="company-person-edit-btn"
          >
            Edit
          </Link> */}
              {/* <Button type="primary">Edit</Button> */}
            </div>
            {/* </Link> */}
          </Col>
        </Row>
      )}
    </div>
  );
};
