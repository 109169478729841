import { CONTACT_TYPES, REFERENCE__TYPES } from "../../types";
import { ContactService } from "./contact.api";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
const contactService = new ContactService();

export const countryPagination = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.COUNTRY_PAGINATION));
    const response = await contactService.countryPagination(body);
    dispatch(finish(CONTACT_TYPES.COUNTRY_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(CONTACT_TYPES.COUNTRY_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const allCountryListing = (query) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.ALL_COUNTRY_LISTING));
    const response = await contactService.allCountryListing(query);
    dispatch(finish(CONTACT_TYPES.ALL_COUNTRY_LISTING));
    if (response.isSuccess) {
      dispatch(success(CONTACT_TYPES.ALL_COUNTRY_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const countryAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.COUNTRY_ADD));
    const response = await contactService.countryAdd(body);
    dispatch(finish(CONTACT_TYPES.COUNTRY_ADD));
    if (response.isSuccess) {
      message.success("Successfully added country");
      dispatch(countryPagination());
      dispatch(success(CONTACT_TYPES.COUNTRY_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const countryUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.COUNTRY_UPDATE));
    const response = await contactService.countryUpdate(id, body);
    dispatch(finish(CONTACT_TYPES.COUNTRY_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated country");
      dispatch(countryPagination());
      dispatch(success(CONTACT_TYPES.COUNTRY_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactLabelsPagination = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CONTACT_LABELS_PAGINATION));
    const response = await contactService.contactLabelsPagination(body);
    dispatch(finish(CONTACT_TYPES.CONTACT_LABELS_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(CONTACT_TYPES.CONTACT_LABELS_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactLabelGroupAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CONTACT_LABELS_ADD));
    const response = await contactService.contactLabelGroupAdd(body);
    dispatch(finish(CONTACT_TYPES.CONTACT_LABELS_ADD));
    if (response.isSuccess) {
      message.success("Successfully added contact label group");
      dispatch(contactLabelsPagination());
      dispatch(success(CONTACT_TYPES.CONTACT_LABELS_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactLabelGroupUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CONTACT_LABELS_UPDATE));
    const response = await contactService.contactLabelGroupUpdate(id, body);
    dispatch(finish(CONTACT_TYPES.CONTACT_LABELS_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated contact label group");
      dispatch(contactLabelsPagination());
      dispatch(success(CONTACT_TYPES.CONTACT_LABELS_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const cityPagination = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CITY_PAGINATION));
    const response = await contactService.cityPagination(body);
    dispatch(finish(CONTACT_TYPES.CITY_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(CONTACT_TYPES.CITY_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const currencyPagination = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CURRENCY_PAGINATION));
    const response = await contactService.getCurrencyPagination(query);
    dispatch(finish(CONTACT_TYPES.CURRENCY_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(CONTACT_TYPES.CURRENCY_PAGINATION, response.data, loadMore)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const cityAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CITY_ADD));
    const response = await contactService.cityAdd(body);
    // dispatch(finish(CONTACT_TYPES.CITY_ADD));
    // if (response.isSuccess) {
    //   message.success("Successfully added the city");
    //   dispatch(cityPagination());
    //   dispatch(success(CONTACT_TYPES.CITY_ADD, response.data));
    // } else if (!response.isSuccess) {
    //   dispatch(error(response.errorMessage));
    // }
    try {
      if (response.isSuccess) {
        message.success("Successfully added the city");
        // dispatch(cityPagination());
        dispatch(success(CONTACT_TYPES.CITY_ADD, response.data));
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(finish(CONTACT_TYPES.CITY_ADD));
    }
  };
};

export const currencyAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CURRENCY_ADD));
    const response = await contactService.currencyAdd(body);
    dispatch(finish(CONTACT_TYPES.CURRENCY_ADD));
    if (response.isSuccess) {
      message.success("Successfully added the currency");
      dispatch(currencyPagination());
      dispatch(success(CONTACT_TYPES.CURRENCY_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const currencyUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CURRENCY_UPDATE));
    const response = await contactService.currencyUpdate(id, body);
    dispatch(finish(CONTACT_TYPES.CURRENCY_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated currency");
      dispatch(currencyPagination());
      dispatch(success(CONTACT_TYPES.CURRENCY_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const modalityPagination = () => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.MODALITY_PAGINATION));
    const response = await contactService.getModalityPagination();
    dispatch(finish(CONTACT_TYPES.MODALITY_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(CONTACT_TYPES.MODALITY_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const modalityAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.MODALITY_ADD));
    const response = await contactService.modalityAdd(body);
    dispatch(finish(CONTACT_TYPES.MODALITY_ADD));
    if (response.isSuccess) {
      message.success("Successfully added Modality");
      dispatch(modalityPagination());
      dispatch(success(CONTACT_TYPES.MODALITY_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const modalityUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.MODALITY_UPDATE));
    const response = await contactService.modalityUpdate(id, body);
    dispatch(finish(CONTACT_TYPES.MODALITY_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated modality");
      dispatch(modalityPagination());
      dispatch(success(CONTACT_TYPES.MODALITY_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const sectorPagination = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.SECTOR_PAGINATION));
    const response = await contactService.getSectorPagination();
    dispatch(finish(REFERENCE__TYPES.SECTOR_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.SECTOR_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const sectorAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.SECTOR_ADD));
    const response = await contactService.sectorAdd(body);
    dispatch(finish(REFERENCE__TYPES.SECTOR_ADD));
    if (response.isSuccess) {
      message.success("Successfully added Sector");
      dispatch(sectorPagination());
      dispatch(success(REFERENCE__TYPES.SECTOR_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const sectorUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.SECTOR_UPDATE));
    const response = await contactService.sectorUpdate(id, body);
    dispatch(finish(REFERENCE__TYPES.SECTOR_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated sector");
      dispatch(sectorPagination());
      dispatch(success(REFERENCE__TYPES.SECTOR_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const statusPagination = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.STATUS_PAGINATION));
    const response = await contactService.getStatusPagination();
    dispatch(finish(REFERENCE__TYPES.STATUS_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.STATUS_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const statusAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.STATUS_ADD));
    const response = await contactService.statusAdd(body);
    dispatch(finish(REFERENCE__TYPES.STATUS_ADD));
    if (response.isSuccess) {
      message.success("Successfully added Sector");
      dispatch(statusPagination());
      dispatch(success(REFERENCE__TYPES.SECTOR_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const statusUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.STATUS_UPDATE));
    const response = await contactService.statusUpdate(id, body);
    dispatch(finish(REFERENCE__TYPES.STATUS_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated modality");
      dispatch(statusPagination());
      dispatch(success(REFERENCE__TYPES.STATUS_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const cityUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CITY_UPDATE));
    const response = await contactService.cityUpdate(id, body);
    dispatch(finish(CONTACT_TYPES.CITY_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated the city");
      dispatch(cityPagination());
      dispatch(success(CONTACT_TYPES.CITY_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactLabelPagination = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CONTACT_LABEL_PAGINATION));
    const response = await contactService.contactLabelPagination(body);
    dispatch(finish(CONTACT_TYPES.CONTACT_LABEL_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(CONTACT_TYPES.CONTACT_LABEL_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactLabelAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CONTACT_LABEL_ADD));
    const response = await contactService.contactLabelAdd(body);
    dispatch(finish(CONTACT_TYPES.CONTACT_LABEL_ADD));
    if (response.isSuccess) {
      message.success("Successfully added the contact label");
      dispatch(contactLabelPagination());
      dispatch(success(CONTACT_TYPES.CONTACT_LABEL_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const contactLabelUpdate = (id, body, MaxResultCount, skipCount) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.CONTACT_LABEL_UPDATE));
    const response = await contactService.contactLabelUpdate(id, body);
    dispatch(finish(CONTACT_TYPES.CONTACT_LABEL_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated the contact label");
      let query = {
        MaxResultCount: MaxResultCount,
        skipCount: skipCount,
      };
      dispatch(contactLabelPagination(query));
      dispatch(success(CONTACT_TYPES.CONTACT_LABEL_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const contactExpertAdd = (body) => {
// 	return async (dispatch) => {
// 		dispatch(init(CONTACT_TYPES.CONTACT_EXPERT_ADD));
// 		const response = await contactCompanyPeopleService.contactExpertAdd(body);
// 		dispatch(finish(CONTACT_TYPES.CONTACT_EXPERT_ADD));
// 		if (response.isSuccess) {
// 			dispatch(success(CONTACT_TYPES.CONTACT_EXPERT_ADD, response.data));
// 		} else if (!response.isSuccess) {
// 			dispatch(error(response.errorMessage));
// 		}
// 	};
// };

// export const contactExpertUpdate = (id, body) => {
// 	return async (dispatch) => {
// 		dispatch(init(CONTACT_TYPES.CONTACT_EXPERT_UPDATE));
// 		const response = await contactCompanyPeopleService.contactExpertUpdate(id, body);
// 		dispatch(finish(COMPANY_PEOPLE_TYPES.contactExpertUpdate));
// 		if (response.isSuccess) {
// 			message.success('Successfully updated  Person Note');
// 			dispatch(success(CONTACT_TYPES.CONTACT_EXPERT_UPDATE, response.data));
// 		} else if (!response.isSuccess) {
// 			dispatch(error(response.errorMessge));
// 		}
// 	};
// };

// export const contactExpertGetDetails = (body) => {
// 	return async (dispatch) => {
// 		dispatch(init(CONTACT_TYPES.CONTACT_EXPERT_GET));
// 		const response = await contactCompanyPeopleService.contactExpertGetDetails(body);
// 		dispatch(finish(CONTACT_TYPES.CONTACT_EXPERT_GET));
// 		if (response.isSuccess) {
// 			_;
// 			dispatch(success(CONTACT_TYPES.CONTACT_EXPERT_GET, response.data));
// 		} else if (!response.isSuccess) {
// 			dispatch(error(response.errorMessage));
// 		}
// 	};
// };

export const getAllTagsList = () => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.ALL_TAGS_LIST));
    const response = await contactService.tagsListing();
    dispatch(finish(CONTACT_TYPES.ALL_TAGS_LIST));
    if (response.isSuccess) {
      dispatch(success(CONTACT_TYPES.ALL_TAGS_LIST, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};


//
export const getCompanyorPersonEmailAddess = (q, id) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.COMPANY_PERSON_MAIL_LISTING));
    const response = await contactService.getCompanyorPersonMailListing(q, id);
    dispatch(finish(CONTACT_TYPES.COMPANY_PERSON_MAIL_LISTING));
    if (response.isSuccess) {
      dispatch(success(CONTACT_TYPES.COMPANY_PERSON_MAIL_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
}

export const getCompanyEmailAddress = (q, id) => {
  return async (dispatch) => {
    dispatch(init(CONTACT_TYPES.COMPANY_EMAIL_ADDRESS_LISTING));
    const response = await contactService.getCompanyorPersonMailListing(q, id);
    dispatch(finish(CONTACT_TYPES.COMPANY_EMAIL_ADDRESS_LISTING));
    if (response.isSuccess) {
      dispatch(success(CONTACT_TYPES.COMPANY_EMAIL_ADDRESS_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  }
}