import { jsonParser } from "utils/misc";
import { actions, TYPES } from "./todos.action";

const initialState = {
  todosResponse:
    jsonParser(jsonParser(sessionStorage.getItem("persist:root"))?.todos)
      ?.todosResponse || [],
  todosDraft:
    jsonParser(jsonParser(sessionStorage.getItem("persist:root"))?.todos)
      .todosDraft || [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.TODOS_FETCH_SUCCESS:
      return {
        ...state,
        todosResponse: action.payload.todos,
        todosError: null,
      };
    case TYPES.TODOS_FETCH_SUCCESS_OFFLINE:
      return {
        ...state,
        todosError: null,
      };
    case TYPES.TODOS_FETCH_ERROR:
      return {
        ...state,
        todosResponse: null,
        todosError: action.payload,
      };
    case TYPES.TODO_POST_SUCCESS:
      return {
        ...state,
        todosResponse: [action.payload, ...state.todosResponse],
        todosDraft: [...state.todosDraft].filter(
          (item) => item.item === action.payload
        ),
      };
    case TYPES.TODO_POST_SUCCESS_OFFLINE:
      return {
        ...state,
        todosDraft: [{ item: action.payload }, ...state.todosDraft],
      };
    case TYPES.TODO_POST_ERROR:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
}
