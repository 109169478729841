import { CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES } from "../../types";

const INITIAL_STATE = {
  groupData: [],
  groupDataLoading: false,
};

export default function createUpdateGroupReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.CREATE_GROUP_REQUEST_INIT:
      return {
        ...state,
        groupDataLoading: true,
      };

    case CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.CREATE_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        groupData: action?.payload?.items,
        groupDataLoading: false,
        groupDataLoadingSuccess: true,
      };

    case CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.CREATE_GROUP_REQUEST_FINISH:
      return {
        ...state,
        groupDataLoading: false,
        groupDataLoadingSuccess: false,
      };

    case CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.LIST_GROUP_REQUEST_INIT:
      return {
        ...state,
        groupDataLoading: true,
      };

    case CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.LIST_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        groupData: action?.payload?.items,
        groupDataLoading: false,
      };

    case CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.LIST_GROUP_REQUEST_FINISH:
      return {
        ...state,
        groupDataLoading: false,
      };

    case CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.UPDATE_GROUP_REQUEST_INIT:
      return {
        ...state,
        groupDataLoading: true,
      };

    case CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.UPDATE_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        groupData: action?.payload?.items,
        groupDataLoading: false,
        groupDataLoadingSuccess: true,
      };

    case CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.UPDATE_GROUP_REQUEST_FINISH:
      return {
        ...state,
        groupDataLoading: false,
        groupDataLoadingSuccess: false,
      };

    default:
      return {
        ...state,
      };
  }
}
