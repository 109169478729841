import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Table,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { getReferenceListing } from "services/redux/Reference/action";
import { mailReferenceListing } from "services/redux/email/mailMessageCenter/action";
import {
  addToDoEvent,
  getTodoEventsPagination,
  updateToDoEvent,
} from "services/redux/organizer/todoLists/action";
import { getAllUsers } from "services/remanentCalls";
import { LogoImage } from "utils/imageConstants";
import storage from "utils/storage";

import { useDispatch, useSelector } from "react-redux";
import getTodoColumns from "./TodoColumns";

const { Option } = Select;

const ToDoLists = (props) => {
  const [form] = useForm();
  const [showAddTask, setShowAddTask] = useState(false);
  const [showUpdateTask, setShowUpdateTask] = useState(false);
  const [updateTaskRecord, setUpdateTaskRecord] = useState();
  const handleAddTask = () => {
    setShowAddTask(true);
  };
  const dispatch = useDispatch();
  const toDoEventsState = useSelector((state) => state.toDoEvents);
  const { token } = useSelector((state) => state.auth);
  const [meetingInvitees, setMeetingInvitees] = useState();
  const [todoEventCandidateDtos, setTodoEventCandidateDtos] = useState([]);
  const [searchValueReference, setSearchValueReference] = useState("");
  const [filterParticipantType, setFilterParticipantType] = useState("");
  const [referenceListing, setReferenceListing] = useState([]);
  const [filterReference, setFilterReference] = useState([]);
  const [hideButton, setHideButton] = useState(false);

  const {
    data,
    todoEventsLoading,
    organizerEventPriorityListing,
    allEventStatusListing,
    editToDoList,
  } = props;

  const columns = getTodoColumns({ hideButton, todoEventsLoading, token, setShowAddTask, setShowUpdateTask, setUpdateTaskRecord });
  const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setDataSource(data.items);
    }
  }, [data.items]);

  const [viewList, setViewList] = useState([]);

  useEffect(() => {
    const query = `maxResultCount=100&skipCount=0&ReferenceIds=${props?.emailReferenceId}`;
    dispatch(getTodoEventsPagination(query));
  }, []);

  useEffect(() => {
    if (!isEmpty(data.items)) {
      let lists = [...popoverMenuVisible];
      let viewlists = [...viewList];
      data.items.map((item, i) => {
        lists[i] = false;
        viewlists[i] = "More";
      });
      setPopoverMenuVisible(lists);
      setViewList(viewlists);
    }
  }, [data.items]);

  const handleVisibleChange = (index) => {
    let lists = [...popoverMenuVisible];
    lists[index] = !lists[index];
    setPopoverMenuVisible(lists);
  };

  const getActionContent = (item, index) => {
    return (
      <div className="guest-actions">
        <div
          className="guest-act"
          onClick={() => {
            editToDoList(item);
            handleVisibleChange(index);
          }}
        >
          Edit
        </div>
        <div className="guest-act">Delete</div>
      </div>
    );
  };

  const getHalfDetails = (details) => {
    let halfDetails = details ? details.split("").splice(0, 80).join("") : "";
    return halfDetails;
  };

  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setMeetingInvitees(resp.data);
    }
  };

  const { referenceListingResponse } = useSelector((state) => state.reference);

  useEffect(() => {
    dispatch(mailReferenceListing());
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
  }, []);

  useEffect(() => {
    if (searchValueReference) {
      if (referenceListingResponse) {
        setReferenceListing(referenceListingResponse);
      }
    }
  }, [referenceListingResponse, searchValueReference]);

  useEffect(() => {
    if (!isEmpty(referenceListing) && filterParticipantType == "REF") {
      setFilterReference(referenceListing.items);
    }
  }, [referenceListing]);

  const changeEmployeeDto = (value, option) => {
    let newTodoEventCandidateDtos = option.map((candidate) => {
      if (todoEventCandidateDtos.length > 0) {
        let newCandidate = {};
        todoEventCandidateDtos.map((oldCandidate) => {
          if (oldCandidate.candidateId === candidate.value) {
            newCandidate = { ...oldCandidate };
          }
        });
        if (!isEmpty(newCandidate)) {
          return { ...newCandidate };
        } else {
          return {
            candidateId: candidate.value,
            candidateName: candidate.title,
            isPrimary: false,
            isSupervisor: false,
          };
        }
      } else {
        return {
          candidateId: candidate.value,
          candidateName: candidate.title,
          isPrimary: true,
          isSupervisor: false,
        };
      }
    });
    setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  };

  const allEqual = (arr) => arr.every((val) => val === true);

  const changeCandidatePrimary = (value, index) => {
    let newTodoEventCandidateDtos = cloneDeep(todoEventCandidateDtos);

    if (value === "supervisor") {
      newTodoEventCandidateDtos[index].isSupervisor = true;
      newTodoEventCandidateDtos[index].isPrimary = false;
    } else if (value === "primary") {
      newTodoEventCandidateDtos[index].isPrimary = true;
      newTodoEventCandidateDtos[index].isSupervisor = false;
    } else {
      newTodoEventCandidateDtos[index].isPrimary = false;
      newTodoEventCandidateDtos[index].isSupervisor = false;
    }
    let primaryValueArray = newTodoEventCandidateDtos.map(
      (candidate) => candidate.isPrimary
    );
    let supervisorValueArray = newTodoEventCandidateDtos.map(
      (candidate) => candidate.isSupervisor
    );

    if (primaryValueArray.includes(true) && !allEqual(supervisorValueArray)) {
      setTodoEventCandidateDtos(newTodoEventCandidateDtos);
    }
  };

  const removeSelectedMember = (candidateId) => {
    let newTodoEventCandidateDtos = todoEventCandidateDtos.filter(
      (candidate) => candidate.candidateId !== candidateId
    );

    setTodoEventCandidateDtos(newTodoEventCandidateDtos);

    let newSelectTodoEventCandidateDtos = form
      .getFieldValue("todoEventCandidateDtos")
      .filter((obj) => obj !== candidateId);

    form.setFieldsValue({
      todoEventCandidateDtos: newSelectTodoEventCandidateDtos,
    });
  };
  let participantFrom = storage.getItem("userId");

  const onFinish = (values) => {
    // e.preventDefault();
    // console.log("VALUE xxx: ", values)
    // console.log("START OF: ", moment(values.startDate).startOf("date"))
    let newValues = {
      ...values,
      startDate: moment(values.startDate).startOf("date"),
      dueDate: moment(values.dueDate).endOf("date"),
      privateUserId: participantFrom,
      organizerEventLabelId: "5ed65d11-e8e4-4ca9-a35f-14dbaa6509a6",
      referenceId: props?.emailReferenceId,
    };
    // console.log("new values: ", newValues)

    const fieldValues = form.getFieldValue("todoEventCandidateDtos");
    if (fieldValues) {
      newValues.todoEventCandidateDtos = fieldValues.map((x) => {
        return { candidateId: x, isPrimary: true, isWatcher: false };
      });
    } else {
      newValues.todoEventCandidateDtos = [];
    }
    // dispatch(addToDoEvent(newValues, undefined, "all"));
    try {
      if(showAddTask) {
        dispatch(addToDoEvent(newValues, undefined, "all"));
      } else {
        let updatePayload = {
          ...newValues,
          id: updateTaskRecord?.id,
          startDate: newValues?.startDate.format("YYYY-MM-DDTHH:mm:ss.SSSS"),
          dueDate: newValues?.dueDate.format("YYYY-MM-DDTHH:mm:ss.SSSS"),
        }

        const idToSend = updatePayload.id;

        delete updatePayload?.id
        dispatch(updateToDoEvent(updatePayload, idToSend));
      }

      setShowAddTask(false);
      setShowUpdateTask(false);
      form.resetFields();
    } catch(err) {
      console.log("error: ", err);
    }
    // setShowAddTask(!showAddTask);
    // setShowAddTask(false);
    // setShowUpdateTask(false);
    // form.resetFields();
  };

  useEffect(() => {
    if (filterParticipantType === "REF") {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  useEffect(() => {
    if(showUpdateTask) {
      // console.log("updateTaskRecord: ", updateTaskRecord)

      let canditateId = updateTaskRecord && updateTaskRecord?.todoEventCandidateDtos?.map((id) => id.candidateId);
      let processDate = moment(updateTaskRecord?.dueDate)

      form.setFieldsValue({
        subject: updateTaskRecord?.subject,
        // referenceId: updateTaskRecord,
        dueDate: processDate,
        organizerEventPriorityId: updateTaskRecord?.organizerEventPriorityId,
        todoEventStatusId: updateTaskRecord?.todoEventStatusId,
        todoEventCandidateDtos: canditateId,
      })
    } else {
      form.resetFields()
    }
  }, [updateTaskRecord?.id, showUpdateTask, showAddTask])


  return (
    <>
      <div>
        <Table
          columns={columns}
          // rowKey="appUserId"
          rowKey={(record) => record.id}
          // rowClassName={setTaskClassName}
          className={`organizer__table contact-table task-table toto-table`}
          dataSource={dataSource}
          loading={{
            indicator: (
              <img src={LogoImage} height="auto" width="50px" alt="logo" />
            ),
            spinning: todoEventsLoading,
          }}
          pagination={false}
        />

        {(showAddTask || showUpdateTask) && (
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            layout="inline"
            onFinish={onFinish}
            autoComplete="off"
            // initialValues={worker}
            className="task__form "
          >
            <div className="add-task-form-item">
              <Form.Item
                className="task-form-item"
                name="subject"
                rules={[
                  {
                    required: true,
                    message: "  ",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter subject"
                  bordered={false}
                  autoFocus={true}
                />
              </Form.Item>
            </div>
            <div className="add-task-form-item">
              <Form.Item
                name="referenceId"
                className="task-D-item"
                // hidden={!isEmpty(emailReferenceId)}
                // rules={[
                //   {
                //     required: true,
                //     message: "  ",
                //   },
                // ]}
              >
                <Select
                  showSearch
                  placeholder="Select reference"
                  bordered={false}
                  optionFilterProp="label"
                  allowClear={true}
                  onSearch={(value) => {
                    // setReferenceDropDown(true);
                    // setSearchValueReference(value);
                    // dispatch(
                    //   getReferenceListing({ SearchkeyWord: searchValueReference })
                    // );
                    setSearchValueReference(value);
                    setFilterParticipantType("REF");
                  }}
                  disabled={true}
                  defaultValue={props?.referenceCode}
                >
                  {filterReference.map((reference, index) => {
                    return (
                      <Option
                        value={reference.id}
                        key={index}
                        label={[reference.code, reference.title]}
                      >
                        <div className="demo-option-label-item">
                          <div>
                            {reference.code} {""}
                          </div>
                          <div>{reference.title}</div>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="add-task-form-item">
              <Form.Item
                name="todoEventCandidateDtos"
                className="task-form-item"
              >
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="assign event candidate"
                  bordered={false}
                  optionFilterProp="label"
                  allowClear={true}
                  onChange={changeEmployeeDto}
                >
                  {!isEmpty(meetingInvitees) &&
                    meetingInvitees.map((employee, index) => {
                      return (
                        <Option
                          value={employee.appUserId}
                          title={employee.fullName}
                          key={index}
                          label={[employee.fullName, employee.userName]}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">{employee.fullName} </span>[
                            {employee.userName.toUpperCase()}]
                          </div>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div className="add-task-form-item">
              <Form.Item hidden={true}>
                <div className="appointment-select-form form__group">
                  {todoEventCandidateDtos?.map((candidate, index) => {
                    return (
                      <div className="appointment-candidate" key={index}>
                        <span>{candidate.candidateName}</span>
                        <span>
                          <Radio.Group
                            // onChange={(e) =>
                            //   changeCandidatePrimary(e.target.value, index)
                            // }
                            value={
                              candidate.isPrimary
                                ? "primary"
                                : candidate.isSupervisor
                                ? "supervisor"
                                : ""
                            }
                          >
                            <Radio value={"supervisor"}>Supervisor</Radio>
                            <Radio value={"primary"}>Primary</Radio>
                          </Radio.Group>
                        </span>
                        <span
                        // onClick={() => changeCandidatePrimary("reset", index)}
                        >
                          reset
                        </span>
                        <span
                        // onClick={() =>
                        //   removeSelectedMember(candidate.candidateId)
                        // }
                        >
                          <CloseOutlined />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </Form.Item>
            </div>
            <div className="add-task-form-item">
              <Form.Item name="startDate" hidden={true} initialValue={moment()}>
                <DatePicker
                  disabledDate={(current) => {
                    return current && current < moment();
                  }}
                  format="YYYY/MM/DD"
                />
              </Form.Item>
            </div>
            <div className="add-task-form-item">
              <Form.Item
                name="dueDate"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
                className="task-form-item ant-date-picker"
                initialValue={moment()}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return (
                      current &&
                      (form.getFieldValue("startDate")
                        ? current < form.getFieldValue("startDate")
                        : current < moment())
                    );
                  }}
                  format="YYYY/MM/DD "
                  placeholder="select due date"
                  bordered={false}
                />
              </Form.Item>
            </div>
            <div className="add-task-form-item">
              <Form.Item
                name="organizerEventPriorityId"
                className="task-form-item task-status"
                initialValue={organizerEventPriorityListing[0]?.id}
              >
                <Select placeholder="Select Priority" bordered={false}>
                  {!isEmpty(organizerEventPriorityListing) &&
                    organizerEventPriorityListing.map((status, index) => {
                      return (
                        <Option value={status.id} key={index}>
                          {status.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div className="add-task-form-item">
              <Form.Item
                name="todoEventStatusId"
                initialValue={allEventStatusListing[1]?.id}
                className="task-form-item task-progress"
              >
                <Select
                  placeholder="Select Progress"
                  bordered={false}
                  className="task-form-item"
                >
                  {!isEmpty(allEventStatusListing) &&
                    allEventStatusListing.map((status, index) => {
                      return (
                        <Option value={status.id} key={index}>
                          {status.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div className="add-task-form-item add-update-task-wrapper">
              <Form.Item className="task-form-item task-action-wrapper task-action-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size="small"
                >
                  <CheckOutlined />
                </Button>
              </Form.Item>
              <Form.Item className="task-form-item task-action-cross">
                <Button
                  type="dotted"
                  onClick={() => {
                    // setShowAddTask(!showAddTask);
                    setShowAddTask(false);
                    setShowUpdateTask(false);
                  }}
                  bordered={false}
                  shape="round"
                  size="small"
                >
                  <CloseOutlined />
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}
        {showAddTask ? (
          ""
        ) : (
          <Button
            onClick={() => {
              handleAddTask();
              setShowUpdateTask(false);
              form.resetFields();
            }}
            type="text"
            size="small"
          >
            <span style={{ fontSize: 14 }}> + Add Task</span>
          </Button>
        )}
      </div>
    </>
  );
};

export default ToDoLists;
