import React, { useEffect, useState } from "react";
import { Card, Col, Empty, List, Row, Skeleton, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import "./style.css";
import moment from "moment";
import { getSOWDetails } from "services/redux/officeresource/business/action";
import { FilterOutlined } from "@ant-design/icons";
import SowFilter from "./SowFilter";
import RotoModal from "components/modals/rotoModal";
const pageSize = 10;

export default function SOWTable({ sows, details_with_sow }) {
  const { sowDetailsLoading, sowDetails } = useSelector(
    (state) => state.businessCenter
  );

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [sowDetailsLoad, setSowDetailsLoad] = useState(false);
  const [filterModal, setFilterModal] = useState();
  useEffect(() => {
    if (!isEmpty(sowDetails?.items)) {
      let newPage = sowDetails?.items?.length / pageSize;
      setCurrentPage(newPage);
    }
  }, [sowDetails]);

  useEffect(() => {
    setSowDetailsLoad(true);
    loadMoreData();
  }, [sows]);

  const loadMoreData = async (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    let querySows =
      sows
        ?.split(",")
        .map((x) => {
          return `&ScopeOfWorks=${x}`;
        })
        .toString()
        .replace(",", "") ?? "&ScopeOfWorks=";

    let appendUrl = `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}${querySows}`;
    // dispatch(getSOWDetails(appendUrl, loadMore, setSowDetailsLoad));

    // added by bishow
    const params = {
      MaxResultCount,
      SkipCount,
      scopeOfWorks: sows,
    };
    dispatch(getSOWDetails(params, loadMore, setSowDetailsLoad));
  };

  const fetchMoreData = () => {
    loadMoreData(pageSize, currentPage * pageSize, true);
  };

  return (
    <>
      <Card
        title={
          <div style={{ display: "flex" }}>
            <Typography.Title level={4}>
              <Typography.Text strong style={{ textAlign: "left" }}>
                Similar Tenders (SOW - {`${sows ?? ""}`})
              </Typography.Text>
            </Typography.Title>

            <Typography.Text>
              <span
                className="side__search__wrapper side-contact-search-filter"
                style={{
                  cursor: "pointer",
                  height: "25px",
                  marginTop: "5 px",
                  marginLeft: "40px",
                }}
                onClick={() => {
                  setFilterModal(true);
                }}
              >
                <FilterOutlined />
              </span>
            </Typography.Text>
          </div>
        }
      >
        {sowDetailsLoad ? (
          <Skeleton />
        ) : !isEmpty(sowDetails?.items) ? (
          <>
            <div
              id="scrollableDivSow"
              style={{
                maxHeight: 500,
                overflow: "auto",
                padding: "0 16px",
                border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
            >
              <InfiniteScroll
                dataLength={sowDetails?.items?.length}
                next={fetchMoreData}
                hasMore={sowDetails?.totalCount > sowDetails?.items?.length}
                scrollableTarget="scrollableDivSow"
                loader={<Skeleton paragraph={{ rows: 1 }} active />}
                endMessage={
                  <Typography.Title
                    level={4}
                    strong
                    style={{ textAlign: "center" }}
                  >
                    End
                  </Typography.Title>
                }
              >
                <List
                  dataSource={sowDetails?.items}
                  renderItem={(item, index) => (
                    <List.Item key={item?.id} className="listSow">
                      <List.Item.Meta
                        title={
                          <Typography.Title level={5} strong>
                            {index + 1}.{_.startCase(item?.title)}
                          </Typography.Title>
                        }
                        description={
                          <>
                            <Row>
                              <Col span={4}>Budget:</Col>
                              <Col span={20}>{item.budget}</Col>
                              <Col span={4}>Year:</Col>
                              <Col span={20}>{item.year}</Col>
                              <Col span={4}>Reference Code:</Col>
                              <Col span={20}>{item.referenceCode}</Col>
                              <Col span={4}>SOW:</Col>
                              <Col span={20}>
                                {/* {sows} */}
                                {item?.scopeOfWorks}
                              </Col>
                              <Col span={24}>
                                <RotoModal id={item.id} />
                              </Col>
                            </Row>
                          </>
                        }
                      />
                      <div>
                        <Typography.Text>
                          {item.submissionDate
                            ? moment(item.submissionDate).format(
                                "YYYY MMM DD - HH:MM A"
                              )
                            : "--"}
                        </Typography.Text>
                      </div>
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            </div>
          </>
        ) : (
          <Empty />
        )}
      </Card>
      <SowFilter filterModal={filterModal} setFilterModal={setFilterModal} />
    </>
  );
}
