import { cqBaseUrl } from "utils/config";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EditTwoTone } from '@ant-design/icons';
const { Tooltip, Image } = require("antd");
const {
  toDoMarkAsComplete,
} = require("services/redux/organizer/todoLists/action");

const getTodoColumns = ({ hideButton, todoEventsLoading, token, setShowAddTask, setShowUpdateTask, setUpdateTaskRecord }) => {
  const dispatch = useDispatch;
  return [
    {
      title: "Tasks",
      key: "subject",
      dataIndex: "subject",
      render: (text, record) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              onClick={(e) => {
                dispatch(toDoMarkAsComplete(record.id));
              }}
              className="mark-it-off"
              style={{ marginRight: 6 }}
            >
              {record.todoEventStatusDto?.name === "Completed" ? (
                <img src="./assets/images/icons/check.png" alt="complete" />
              ) : (
                <img
                  src="./assets/images/icons/incomplete.png"
                  alt="incomplete"
                />
              )}
            </div>
            <div
              onClick={() => {
                // dispatch(toDoGetDetails(record?.id));
              }}
            >
              <span className="merge--contact">
                {!hideButton &&
                !todoEventsLoading &&
                record.todoEventStatusDto?.name === "Completed" ? (
                  <span style={{ textDecoration: "line-through" }}>
                    {record.subject}
                  </span>
                ) : (
                  <Tooltip title={record.subject} placement="topLeft">
                    {record.subject}
                  </Tooltip>
                )}
              </span>
            </div>
          </div>
        );
      },
      sorter: (a, b) => a.subject.localeCompare(b.subject),
    },

    // {
    //   title: " Ref",
    //   dataIndex: "referenceCode",
    //   key: "referenceCode",

    // render: (record) => {
    //   // let refcode = referenceListing?.items?.map((reference) => {
    //   //   if (reference.id === record) {
    //   //     return reference.code;
    //   //   }
    //   // });
    //   return <span>{refcode}</span>;
    // },
    // },
    {
      title: () => {
        return <span>Assigned To</span>;
      },

      key: "todoEventCandidateDtos",
      render: (record) => {
        return record?.todoEventCandidateDtos?.map((assigneDetail, index) => {
          if (assigneDetail?.isPrimary) {
            const initials = assigneDetail.candidateName
              .split(" ")
              .map((name) => name.charAt(0).toUpperCase())
              .join("");
            return assigneDetail.profilePicture ? (
              // <div
              //   onClick={(e) => {
              //     // dispatch(toDoGetDetails(record?.id));
              //   }}
              //   style={{display:'flex',gl}}
              // >
              <Tooltip title={assigneDetail.candidateName} placement="topLeft">
                <span
                  className="badge"
                  style={{
                    color: "white",
                    backgroundColor: index <= 2 ? "brown" : "",
                    border: 0,
                    textAlign: "center",
                    fontSize: "10px",
                    padding: "1px 5px 1px 5px",
                  }}
                >
                  {initials}
                </span>
              </Tooltip>
            ) : // </div>
            null;
          }
        });
      },
    },

    {
      title: () => {
        return (
          <>
            <span>Due Date</span>
          </>
        );
      },
      //dataIndex: "dueDate",
      key: "dueDate",
      render: (record) => {
        return (
          <div
            onClick={() => {
              // dispatch(toDoGetDetails(record?.id));
            }}
          >
            <span style={{ color: "#C4314B" }}>
              {moment(record?.dueDate).format("YYYY-MM-DD")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Priority",
      // dataIndex: "organizerEventPriorityDto",
      key: "organizerEventPriorityDto",

      render: (record) => {
        const priorityColor =
          record?.organizerEventPriorityDto?.colorFontHexValue || "white";
        const priorityBackgroundColor =
          record?.organizerEventPriorityDto?.colorHexValue || "green";
        return (
          <div>
            <span
              className="badge"
              style={{
                color: priorityColor,
                backgroundColor: priorityBackgroundColor,
                border: 0,
              }}
            >
              {record?.organizerEventPriorityDto?.name.charAt(0)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Progress",
      // dataIndex: "todoEventStatusDto",
      key: "todoEventStatusDto",
      render: (record) => {
        // const progressColor = TinyColor2(record?.colorFontHexValue).isLight()
        //   ? "#FAFAFA"
        //   : "black";

        const progressColor = "white";
        const progressBackgroundColor =
          record?.todoEventStatusDto?.colorHexValue || "green";
        return (
          <div
            onClick={() => {
              // dispatch(toDoGetDetails(record?.id));
            }}
          >
            <span
              className="private badge"
              style={{
                color: progressColor,
                backgroundColor: progressBackgroundColor,
                border: 0,
              }}
            >
              {record?.todoEventStatusDto?.name.charAt(0)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        return (<>
            <EditTwoTone 
              onClick={() => {
                // console.log("selected record: ", record)
                setShowUpdateTask(true);
                setShowAddTask(false);
                setUpdateTaskRecord(record);
              }}
            />
        </>)
      }
    }
  ];
};

export default getTodoColumns;
