import { useEffect, useState } from "react";
import moment from "moment";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  Layout,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  Tag,
  Popover,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import {
  MailOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { actionForCreateUpdateApplicants } from "services/redux/hrSystem/action";
import { isEmpty } from "lodash";
import { cqBaseUrl } from "utils/config";
import pdfViewer from "../../../../components/Documents/ViewDocument";
import pdfViewer1 from "../../../../components/Documents/pdfViewerIfrane";
import FileViewer from "./FileViewer";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import HrHiringMail from "./hrMail/index";
import AllFileViewer from "../../../../components/Documents/allFileViewer";

const newWindow = "../../../../assets/svg/newWindow.svg";

const { Content } = Layout;
const { Title, Text } = Typography;

const titleTable = {
  textAlign: "center",
  background: "#40a9ff",
  fontWeight: "bold",
};

export default function JobDetails({
  applicationSingleData,
  setOpenApplicantDrawer,
  openDrawerRecruitment,
  setOpenDrawerRecruitment,
  setSelectedSingleApplicantData,
  setActiveTabIndexofAppicant,
  statusUpdateButton,
  jobTitleId,
  hrCorporateMailLisiting,
}) {
  const dispatch = useDispatch();

  const { applicationStatusCounter } = useSelector(
    (state) => state.hrSystemServicesReducer
  );

  const { token } = useSelector((state) => state.auth);

  const [filteredStageLevel, setFilteredStageLevel] = useState();

  const [activeStatusBtnKey, setActiveStatusBtnKey] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [showViewer, setShowViewer] = useState(false);
  // added
  const [isHrMailModalOpen, setIsHrMailModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleSearchInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filterResults = filteredStageLevel?.filter((x) =>
      x?.emailAddress.toLowerCase().includes(query)
    );

    setFilteredData(filterResults);
  };

  const showHrMailModal = () => {
    setIsHrMailModalOpen(true);
  };

  const closeHrMailModal = () => {
    setIsHrMailModalOpen(false);
  };

  // console.log("hrCorporateMailLisiting JOB DETAILS", hrCorporateMailLisiting);

  // let checkedData = [];

  const columns = [
    // {
    //   title: (
    //     <Title style={titleTable} level={4}>
    //       Applicants List
    //     </Title>
    //   ),

    //   children: [
    // {
    //   title: "Select",
    //   key: "5",
    //   width: 70,
    //   align: "center",
    //   // render: (record) => (
    //   //   <Checkbox
    //   //     onChange={(e) => {
    //   //       if (e.target.checked) {
    //   //         checkedData = [...checkedData, record?.id];
    //   //       } else {
    //   //         checkedData = checkedData?.filter((x) => x !== record?.id);
    //   //       }
    //   //     }}
    //   //   />
    //   // ),
    // },
    {
      title: "S.N.",
      key: "sn",
      dataIndex: "sn",
      width: 50,
    },
    {
      title: "Name",
      key: "1",
      dataIndex: "emailAddress",
      ellipsis: "true",
      render: (text) => (
        <>
          <Tooltip title={text}>{text}</Tooltip>
        </>
      ),
    },
    {
      title: "Highest Education",
      key: "2",
      dataIndex: "highestLevelEducation",
    },
    {
      title: "Applied Date",
      key: "3",
      dataIndex: "applicationDate",
      render: (record) => {
        return moment(record?.applicationDate).format("YYYY-MM-DD");
      },
    },
    {
      title: "Avg. Rating",
      key: "4",
      render: (record) => {
        return record ? record?.averageRating : null;
      },
    },
    {
      title: "Action",
      key: "5",
      render: (text, record) => (
        <>
          <EditOutlined
            onClick={() => {
              setOpenApplicantDrawer(true);
              setSelectedSingleApplicantData(record);
              setActiveTabIndexofAppicant("1");
            }}
          />

          {record?.uploadedFileDtos && record?.uploadedFileDtos.length > 0 && (
            <Dropdown
              overlay={
                <Menu>
                  {record?.uploadedFileDtos.map((file, index) => (
                    <Menu.Item key={index}>
                      <AllFileViewer
                        file={file}
                        cqBaseUrl={cqBaseUrl}
                        token={token}
                        height={600}
                        width={900}
                      />
                    </Menu.Item>
                  ))}
                </Menu>
              }
              title="File Name"
            >
              <EyeOutlined style={{ cursor: "pointer", marginLeft: "8px" }} />
            </Dropdown>
          )}
        </>
      ),
    },
    //   ],
    // },
  ];

  if (activeStatusBtnKey === "81dec8c9-6328-4d44-b27c-23597f722ab3") {
    columns.push({
      title: "Migrate Into Employee",
      key: "5",
      dataIndex: "isApplicantMigrateIntoEmployee",
      render: (_, record) => {
        return record && record?.isApplicantMigrateIntoEmployee ? "Yes" : "No";
      },
    });
  }

  useEffect(() => {
    let filteredByStageLevel = !isEmpty(activeStatusBtnKey)
      ? applicationSingleData?.applicantInformationDtos?.filter((x) => {
          return x?.currentApplicationStageLevelId === activeStatusBtnKey;
        })
      : applicationSingleData?.applicantInformationDtos;
    setFilteredStageLevel(filteredByStageLevel);
  }, [activeStatusBtnKey, applicationSingleData?.applicantInformationDtos]);

  // const filteredByStatus =
  //   applicationSingleData?.applicantInformationDtos?.filter((x, index) => {
  //     if (activeStatusBtnKey) {
  //       return x?.currentApplicationStageLevelId === activeStatusBtnKey;
  //     } else {
  //       return x?.currentApplicationStageLevelId !== activeStatusBtnKey;
  //     }
  //   });

  const paginationConfig = {
    pageSize: 20,
    total: filteredStageLevel?.length,
    showSizeChanger: false,
    showQuickJumper: false,
  };

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleView = (src) => {
    setShowViewer(true);
    setTimeout(() => {
      pdfViewer1(src);
    }, 1000);
  };

  return (
    <>
      <Content
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "10px",
        }}
      >
        <span>
          Job: {applicationSingleData?.jobTitle}{" "}
          <EditOutlined
            onClick={() =>
              setOpenDrawerRecruitment({
                isOpen: true,
                isEditable: true,
              })
            }
          />
        </span>

        <Button
          style={{
            color: "rgb(56, 121, 197)",
            border: "1px solid rgb(56, 121, 197)",
          }}
          borderColor="rgb(56, 121, 197)"
          onClick={() => {
            setOpenApplicantDrawer(true);
            setActiveTabIndexofAppicant("1");
          }}
        >
          {" "}
          + Add as applicants
        </Button>
      </Content>

      <Content>
        Coupon Code: <Tag color="#f50">{applicationSingleData?.couponCode}</Tag>
      </Content>
      <Content>
        Email:{" "}
        {hrCorporateMailLisiting
          ? hrCorporateMailLisiting.join(", ")
          : "No Email Address"}
      </Content>

      <Content>
        <Text style={{ color: "crimson" }}>
          No questions were added to this job.
        </Text>

        <Row gutter={[0, 8]}>
          <Col span={14}>
            <Button style={{ color: "#008000" }} onClick={showHrMailModal}>
              <MailOutlined />
              Send Email
            </Button>
            <Modal
              title="Template Mail"
              width="70%"
              visible={isHrMailModalOpen}
              onCancel={closeHrMailModal}
              footer={null}
            >
              <HrHiringMail selectedRowKeys={selectedRowKeys} />
            </Modal>

            {/* <Button style={{ marginLeft: "5px" }}>
              <Avatar
                icon={<CheckOutlined />}
                // style={{ backgroundColor: "#87d068" }}
                size="small"
                style={{ marginRight: "5px" }}
              />
              Select All
            </Button>

            <Button style={{ marginLeft: "5px" }}>
              <Avatar
                icon={<CloseOutlined />}
                size="small"
                style={{
                  marginRight: "5px",
                }}
              />
              Unselect All
            </Button> */}
          </Col>

          <Col span={10} style={{ display: "flex" }}>
            Go to Status:
            <Select
              options={statusUpdateButton}
              style={{ width: "69%" }}
              value={selectedStatus}
              onChange={(value) => {
                setSelectedStatus(value);
              }}
            />
            <Button
              // type="primary"
              style={{
                marginLeft: "5px",
                background: "rgb(103, 161, 228)",
                color: "#ffffff",
                border: "0px",
              }}
              onClick={() => {
                let apiBulkData = {
                  applicantInformationDto: {
                    applicantIds: selectedRowKeys,
                    requisitionApplicationId: jobTitleId,
                    currentApplicationStageLevelId: selectedStatus,
                  },
                };

                dispatch(
                  actionForCreateUpdateApplicants(apiBulkData, "updateBulk")
                );
              }}
            >
              Go
            </Button>
          </Col>
        </Row>
      </Content>

      <Content>
        <Text>
          Press the button to change the Status.
          {activeStatusBtnKey && (
            <>
              (Current Status:{" "}
              <span style={{ fontWeight: "bold" }}>
                {
                  statusUpdateButton?.find(
                    (x) => x?.value === activeStatusBtnKey
                  )?.label
                }
              </span>
              )
            </>
          )}
        </Text>
        <br />
        {statusUpdateButton?.map((x) => {
          // return (
          //   <Button
          //     style={{
          //       margin: "5px 5px 0 0",
          //       background:
          //         activeStatusBtnKey === x.value ? "white" : "#007AFF",
          //       color: activeStatusBtnKey === x.value ? "#007AFF" : "white",
          //     }}
          //     onClick={() => setActiveStatusBtnKey(x.value)}
          //   >
          //     {x.label}{" "}
          //     <span
          //       style={{
          //         background:
          //           activeStatusBtnKey === x.value ? "#007AFF" : "white",
          //         width: "17px",
          //         color: activeStatusBtnKey === x.value ? "white" : "#007AFF",
          //         height: "17px",
          //         fontSize: "12px",
          //         marginLeft: "10px",
          //       }}
          //     >
          //       {x?.label === "Apply"
          //         ? applicationStatusCounter?.apply?.total
          //         : x?.label === "ConfirmApplicant"
          //         ? applicationStatusCounter?.confirmApplicant?.total
          //         : x?.label === "CvScreening"
          //         ? applicationStatusCounter?.cvScreening?.total
          //         : x?.label === "TelephoneInterview"
          //         ? applicationStatusCounter?.telephoneInterview?.total
          //         : x?.label === "VirtualInterview"
          //         ? applicationStatusCounter?.virtualInterview?.total
          //         : x?.label === "Shortlisted"
          //         ? applicationStatusCounter?.shortlisted?.total
          //         : x?.label === "FinalApproved"
          //         ? applicationStatusCounter?.finalApproved?.total
          //         : applicationStatusCounter?.finalRejected?.total}
          //     </span>
          //   </Button>
          // );
          const buttonElement = (
            <Button
              key={x.value}
              style={{
                margin: "5px 5px 0 0",
                background:
                  activeStatusBtnKey === x.value ? "white" : "#3879c5",
                color: activeStatusBtnKey === x.value ? "#3879c5" : "white",
                border: "1px solid #3879c5",
              }}
              onClick={() => setActiveStatusBtnKey(x.value)}
            >
              {x.label}{" "}
              <span
                style={{
                  background:
                    activeStatusBtnKey === x.value ? "#3879c5" : "white",
                  width: "17px",
                  color: activeStatusBtnKey === x.value ? "white" : "#3879c5",
                  height: "17px",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                {x?.label === "Apply"
                  ? applicationStatusCounter?.apply?.total
                  : x?.label === "ConfirmApplicant"
                  ? applicationStatusCounter?.confirmApplicant?.total
                  : x?.label === "CvScreening"
                  ? applicationStatusCounter?.cvScreening?.total
                  : x?.label === "TelephoneInterview"
                  ? applicationStatusCounter?.telephoneInterview?.total
                  : x?.label === "VirtualInterview"
                  ? applicationStatusCounter?.virtualInterview?.total
                  : x?.label === "Shortlisted"
                  ? applicationStatusCounter?.shortlisted?.total
                  : x?.label === "FinalApproved"
                  ? applicationStatusCounter?.finalApproved?.total
                  : applicationStatusCounter?.finalRejected?.total}
              </span>
            </Button>
          );

          return buttonElement;
        })}

        {/* <Button style={{ marginLeft: "5px" }}>CV Screening</Button> */}

        <Divider />
        <Text style={{ color: "crimson" }}>
          Note: if the Job-seeker-name is displayed in Red, indicated that
          he/she has applied to multiple jobs posted by this Company.
        </Text>
      </Content>
      <Divider />

      <Content
        style={{
          display: "flex",
          justifyContent: "end",
          margin: "10px 0",
        }}
      >
        <Text>Search:</Text>
        <Input
          style={{
            width: "250px",
            height: "30px",
          }}
          placeholder="Search"
          onChange={handleSearchInputChange}
        />
      </Content>

      <Card>
        {selectedRowKeys.length > 0
          ? `Selected ${selectedRowKeys.length} item/s`
          : ""}
        <Table
          columns={columns}
          dataSource={
            searchQuery
              ? filteredData?.map((x, index) => ({
                  ...x,
                  sn: index + 1 + ".",
                  key: x?.id,
                }))
              : filteredStageLevel?.map((x, index) => {
                  return {
                    ...x,
                    sn: index + 1 + ".",
                    key: x?.id,
                  };
                })
          }
          pagination={paginationConfig}
          scroll={{ y: 400 }}
          rowSelection={rowSelection}
        />
      </Card>
    </>
  );
}
