import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// import AddUserDrawer from "./AddUserDrawer";
import TypeAddUpdateTable from './TypeTable';
import { employeeProfileDetail } from 'services/redux/profile/profile.action';
import TypeAddUpdateDrawer from './TypeDrawer';
import { ProjectService } from '../../../../services/redux/Reference/Project/api';

const Type = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const onReset = () => {
		form.resetFields();
	};

	const [addUpdateTypeDrawerVisibility, setaddUpdateTypeDrawerVisibility] = useState(false);
	const [isUpdateType, setIsUpdateType] = useState(false);

	const showAddUpdateTypeDrawer = (e, record) => {
		setaddUpdateTypeDrawerVisibility(false);
		if (record) {
			setIsUpdateType(record);
		} else {
			setIsUpdateType(false);
		}
		setaddUpdateTypeDrawerVisibility(true);
	};

	const onAddUpdateTypeClose = () => {
		setIsUpdateType('');
		setaddUpdateTypeDrawerVisibility(false);
	};

	return (
		<div className="layout">
			<div className="organizer events">
					<div className="all-header-wrapper">
						<div className="meeting__flex">
							<h3 className="header__all">Type List</h3>
							<div className="organizerHoliday__middle--wrap">
								<Button
									className="btn-primary"
									onClick={() => {
										showAddUpdateTypeDrawer();
									}}
								>
									<PlusOutlined /> New Type
								</Button>
							</div>
						</div>
					</div>
				<TypeAddUpdateTable showAddUpdateTypeDrawer={showAddUpdateTypeDrawer} />
				<TypeAddUpdateDrawer drawerVisibility={addUpdateTypeDrawerVisibility} onAddUpdateTypeClose={onAddUpdateTypeClose} isUpdateType={isUpdateType} />
			</div>
		</div>
	);
};

export default Type;
