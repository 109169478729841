import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button } from "antd";
import { LogoImage } from "utils/imageConstants";
import { cityPagination } from "services/redux/contact/contact.action";

const CityAddUpdateTable = ({ showAddUpdateCountryDrawer, showUpdateProfileDrawer }) => {
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width:"5%",
    },
    {
      title: "City",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Country Name",
      dataIndex: "countryName",
      key: "countryName",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a className='action-btn action-btn-add' onClick={(e) => showAddUpdateCountryDrawer(e, record)}>
          Edit City
        </a>
      ),
    },
  ];

  const dispatch = useDispatch();
  const {
    cityPaginationResponse,
    cityPaginationLoading,
  } = useSelector((state) => state.contact);

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!cityPaginationResponse) return;
    setdata(cityPaginationResponse.items);
    setTotalCount(cityPaginationResponse.totalCount);
  }, [cityPaginationResponse]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType
  ) => {
    dispatch(
      cityPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType
      })
    );
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey
    let SortType = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };

  return (
    <Table className="contact-table"
      columns={columns}
      rowKey="appUserId"
      dataSource={data}
      onChange={handleTableChange}
      // loading={cityPaginationLoading}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" />
        ),
        spinning: cityPaginationLoading,
      }}
      pagination={{
        total: totalCount,
        pageSize: pageSize,
      }}
    />
  );
};

export default CityAddUpdateTable;
