import queryString from "query-string"
import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../commonServices";
export class OrganizerService {
  getAllFiscalYears() {
    let url = `${appBaseUrl}/organizationStructure/fiscalYearListingWithStatus`;
    let data = getService(url);
    return data;
  }

  getHolidayEvents({fiscalYearId, MaxResultCount, SkipCount, Sorting, SortType}) {
    let appendUrl = `?FiscalYearIds=${fiscalYearId}&skipCount=${SkipCount}&maxResultCount=${MaxResultCount}`;
    if(Sorting){
      appendUrl += `&sorting=${Sorting}`;
    }

    if(SortType){
      appendUrl += `&sortType=${SortType}`;
    }
    let url = `${appBaseUrl}/organizerEvent/holidayEventsPagination${appendUrl}`;
    let data = getService(url);
    return data;
  }

  addBulkHolidayEvents(body) {
    let url = `${appBaseUrl}/organizerEvent/holidayEventBulkAddUpdate`;
    let data = postService(url, body);
    return data;
  }

  organizerEventLabelsPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizerEvent/organizerEventLabelsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  organizerEventLabelAdd(body) {
    let url = `${appBaseUrl}/organizerEvent/organizerEventLabelAdd`;
    let data = postService(url, body);
    return data;
  }

  organizerEventLabelUpdate(id, body) {
    let url = `${appBaseUrl}/organizerEvent/organizerEventLabelUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  organizerEventPriorityPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizerEvent/organizerEventPriorityPagination?${query}`;
    let data = getService(url);
    return data;
  }

  organizerEventPriorityAdd(body) {
    let url = `${appBaseUrl}/organizerEvent/organizerEventPriorityAdd`;
    let data = postService(url, body);
    return data;
  }

  organizerEventPriorityUpdate(id, body) {
    let url = `${appBaseUrl}/organizerEvent/organizerEventPriorityUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
}
