import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";

export class ParticipationStatusService {
  getMailMessageParticipantStatusListing() {
    let url = `${appBaseUrl}/mailMessageCenter/mailMessageParticipantStatusListing`;
    let data = getService(url);
    return data;
  }

  getCompanyPersonEmailAddress(id, body) {
    let url = `${appBaseUrl}/referenceManagement/emailAddressListingById/${id}`;
    let data = postService(url, body);
    return data;
  }
}