import { ADMIN_TYPES } from "../../../../types";

const INITIAL_STATE = {
    leaveApplicationTypePagination: {
        items: [],
        totalCount: 0
    },
    leaveApplicationTypePaginationLoading: false,

    addLeaveApplicationTypeResponse: {},
    addLeaveApplicationTypeResponseLoading: false,

    updateLeaveApplicationTypeResponse: {},
    updateLeaveApplicationTypeResponseLoading: false,
};

export default function leaveApplicationTypeReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Fiscal Year Pagination
        case ADMIN_TYPES.LEAVE_APPLICATION_TYPE_INIT:
            return {
                ...state,
                leaveApplicationTypePagination: {
                    items: [],
                    totalCount: 0
                },
                leaveApplicationTypePaginationLoading: true,
            };
        case ADMIN_TYPES.LEAVE_APPLICATION_TYPE_SUCCESS:
            return {
                ...state,
                leaveApplicationTypePagination: action.payload,
                leaveApplicationTypePaginationLoading: false,
            };
        case ADMIN_TYPES.LEAVE_APPLICATION_TYPE_FINISH:
            return {
                ...state,
                leaveApplicationTypePaginationLoading: false,
            };

        // Add Leave Fiscal Day
        case ADMIN_TYPES.LEAVE_APPLICATION_TYPE_ADD_INIT:
            return {
                ...state,
                addLeaveApplicationTypeResponse: {},
                addLeaveApplicationTypeResponseLoading: true,
            };
        case ADMIN_TYPES.LEAVE_APPLICATION_TYPE_ADD_SUCCESS:
            return {
                ...state,
                addLeaveApplicationTypeResponse: action.payload,
                addLeaveApplicationTypeResponseLoading: false,
            };
        case ADMIN_TYPES.LEAVE_APPLICATION_TYPE_ADD_FINISH:
            return {
                ...state,
                addLeaveApplicationTypeResponseLoading: false,
            };

        // Update Leave Fiscal Day
        case ADMIN_TYPES.LEAVE_APPLICATION_TYPE_UPDATE_INIT:
            return {
                ...state,
                updateLeaveApplicationTypeResponse: {},
                updateLeaveApplicationTypeResponseLoading: true,
            };
        case ADMIN_TYPES.LEAVE_APPLICATION_TYPE_UPDATE_SUCCESS:
            return {
                ...state,
                updateLeaveApplicationTypeResponse: action.payload,
                updateLeaveApplicationTypeResponseLoading: false,
            };
        case ADMIN_TYPES.LEAVE_APPLICATION_TYPE_UPDATE_FINISH:
            return {
                ...state,
                updateLeaveApplicationTypeResponseLoading: false,
            };
        default:
            return { ...state };
    }
}
