import { useEffect, useState } from "react";
import { GuestEntertaintmentForm } from "./GuestEntertainmentForm";
import { Row, Col, Button } from "antd";
import GuestEntertainmentTable from "./GuestEntertainmentTable";
import ApprovalTable from "./ApprovalTable";
import { isEmpty } from "lodash";
import GuestEntertainmentDetails from "./GuestEntertainmentDetails";

export const AddUpdateGuestEntertainment = () => {
  const [addUpdateLeaveDrawerVisibility, setAddUpdateLeaveDrawerVisibility] =
    useState(false);
  const [isUpdateApplication, setisUpdateApplication] = useState(false);
  const [guestEntertainmentId, setGuestEntertainmentId] = useState("");
  const [hideguestEntertainmentDrawer, setHideGuestEntertainmentDrawer] =
    useState(false);
  const [showGuestEntertainmentDetails, setShowGuestEntertainmentDetails] =
    useState(false);
  const [guestEntertainmentDetails, setGuestEntertainmentDetails] = useState(
    []
  );
  console.log("guestEntertainmentDetails", guestEntertainmentDetails);

  const showAddUpdateDrawer = (e, record) => {
    setAddUpdateLeaveDrawerVisibility(false);
    if (record) {
      setisUpdateApplication(record);
    } else {
      setisUpdateApplication(false);
    }

    setAddUpdateLeaveDrawerVisibility(true);
  };
  const onAddUpdateDrawerClose = () => {
    setisUpdateApplication("");
    setAddUpdateLeaveDrawerVisibility(false);
  };

  return (
    <>
      <div className="global-header-wrapper">
        <div className="header-info">
          <h3 className="header__all ">Guest Entertainment</h3>
        </div>
        <div className="header-action-wrapper">
          <Button
            className="global-create-btn"
            onClick={() => {
              showAddUpdateDrawer(true);
              setHideGuestEntertainmentDrawer(false);
              setGuestEntertainmentId("");
            }}
          >
            Add Guest Entertainment
          </Button>
        </div>
      </div>
      <Row>
        <Col span={24}>
          <GuestEntertainmentTable
            guestEntertainmentId={guestEntertainmentId}
            setGuestEntertainmentId={setGuestEntertainmentId}
            setShowGuestEntertainmentDetails={setShowGuestEntertainmentDetails}
            setHideGuestEntertainmentDrawer={setHideGuestEntertainmentDrawer}
            setGuestEntertainmentDetails={setGuestEntertainmentDetails}
          />
        </Col>
        <div className="header-info">
          <h3 className="header__all ">To Approve Guest Entertainment</h3>
        </div>
        <Col span={24}>
          <ApprovalTable />
        </Col>
      </Row>
      {showGuestEntertainmentDetails && !isEmpty(guestEntertainmentDetails) ? (
        <Row>
          <Col span={24}>
            <GuestEntertainmentDetails
              guestEntertainmentDetails={guestEntertainmentDetails}
              drawerVisibility={hideguestEntertainmentDrawer}
              setHideGuestEntertainmentDrawer={setHideGuestEntertainmentDrawer}
              onAddUpdateDrawerClose={setHideGuestEntertainmentDrawer}
              setGuestEntertainmentDetails={setGuestEntertainmentDetails}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <GuestEntertaintmentForm
              drawerVisibility={addUpdateLeaveDrawerVisibility}
              setAddUpdateLeaveDrawerVisibility={
                setAddUpdateLeaveDrawerVisibility
              }
              onAddUpdateDrawerClose={onAddUpdateDrawerClose}
              isUpdateApplication={isUpdateApplication}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
