import React, { useEffect, useState } from "react";
import ReviewTemplateTable from "./ReviewTemplateTable";

const ReviewTemplate = ({ employeeUserId }) => {
  return (
    <>
      <ReviewTemplateTable employeeUserId={employeeUserId} />
    </>
  );
};

export default ReviewTemplate;
