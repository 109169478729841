import { Col, Row } from "antd";
import AbsentList from "views/Home/AbsentList";
import CalendarBox from "views/Home/Calendar";
import HonorRollPagination from "views/Home/HonorRollPagiantion";
import MailInfo from "views/Home/MailInfo";
import RequisitionList from "views/Home/Requisition";
import EventLists from "views/Home/events";
import TenderLists from "views/Home/tenders";
import TodoLists from "views/Home/todolist";
import FinanceMainBox from "./FinanceBox";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import GroupChat from "./GroupChat";

const ViewBox = ({
  meetingInvitees,
  nullChecker,
  mailPaginationCount,
  shouldRenderRequisitionList,
  // profileInfo,
}) => {
  const { profileInfo } = useSelector((state) => state.profile);

  return (
    <>
      <Col lg={9} xs={24}>
        <CalendarBox />
        <EventLists type="private" meetingInvitees={meetingInvitees} />
        <MailInfo
          nullChecker={nullChecker}
          mailPaginationCount={mailPaginationCount}
        />
      </Col>

      <Col lg={15} xs={24}>
        <Col lg={24} xs={24} style={{ paddingRight: "0px !important" }}>
          <TodoLists heightt={[680, 580]} />
        </Col>

        <Row gutter={[20, 0]}>
          <Col lg={24} xs={24}>
            <TenderLists classNames="tour2" />
          </Col>
        </Row>
      </Col>
      <Row gutter={[20, 0]} lg={24} xs={24} style={{ width: "100%" }}>
        {shouldRenderRequisitionList ? (
          <>
            <Col lg={9} xs={24}>
              <AbsentList />
            </Col>
            <Col lg={15} xs={24}>
              <RequisitionList />
            </Col>
            {/* <Col lg={24} xs={24}>
              <GroupChat />
            </Col> */}
            <Col lg={9} xs={24}>
              <EventLists type="public" meetingInvitees={meetingInvitees} />
            </Col>
            <Col lg={15} xs={24}>
              <HonorRollPagination
                heightt={[340, 90]}
                shouldRenderRequisitionList={shouldRenderRequisitionList}
              />
            </Col>
          </>
        ) : (
          <>
            <Col lg={9} xs={24}>
              <AbsentList />
            </Col>
            <Col lg={15} xs={24}>
              <EventLists type="public" meetingInvitees={meetingInvitees} />
            </Col>
            <Col lg={9} xs={24}>
              <HonorRollPagination
                heightt={[340, 90]}
                shouldRenderRequisitionList={shouldRenderRequisitionList}
              />
            </Col>
          </>
        )}
      </Row>
      <Row  gutter={[20, 0]} lg={24} xs={24} style={{ width: "100%" }}>
        <Col lg={24} xs={24}>
          <GroupChat />
        </Col>
      </Row>

      {!isEmpty(profileInfo) &&
        (profileInfo?.userRegistrationAddUpdateDto?.roleNames.includes(
          "Dashboard Account Viewer"
        ) ||
          profileInfo?.userRegistrationAddUpdateDto?.roleNames.includes(
            "CEMAT viewer"
          )) && <FinanceMainBox profileInfo={profileInfo} />}
    </>
  );
};

export default ViewBox;
