import { Button, Card, Drawer, Form, Skeleton, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { EyeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ReportForm from "./report/reportingForm";
import WeeklyReportDetail from "./report/reportingDetail";
import { handleDateFormat } from "utils/dateFormat";
import { actionForWeeklyAddUpdateReportGetDetails } from "services/redux/cead/action";
import { isEmpty } from "lodash";

const initialDrawerState = {
  open: false,
  type: "",
  wklyRptId: "",
};

export default function WeeklyTableReport({ props }) {
  const {
    tenderCeadWeeklyReportDetailsListLoading,
    tenderCeadWeeklyReportDetailsListing,
  } = props;

  const { tenderCeadWeeklyReportAddUpdateLoading } = useSelector(
    (state) => state.ceadReducer
  );

  const [weeklyForm] = Form.useForm();

  const dispatch = useDispatch();

  const [isSetOpenDrawer, setOpenDrawer] = useState(initialDrawerState);
  const [weeklyId, setWeeklyId] = useState("");

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
    },
    {
      title: "Date Range",
      dataIndex: "dateRange",
      key: "dateRange",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Reporting Date",
      dataIndex: "reportingDate",
      key: "reportingDate",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const dataSource = tenderCeadWeeklyReportDetailsListing?.map((x, index) => {
    return {
      sn: index + 1 + ".",
      dateRange: `${
        handleDateFormat(x?.reportingFromDate, "DD-MMM-YYYY") +
        "/" +
        handleDateFormat(x?.reportingToDate, "DD-MMM-YYYY")
      }`,
      createdBy: x?.creatorUserDto?.employeeName,
      reportingDate: handleDateFormat(x?.reportingToDate, "DD-MMM-YYYY"),
      action: (
        <EyeOutlined
          onClick={() => handleDrawerWeeklyReport("detail", x?.id)}
        />
      ),
    };
  });

  const handleDrawerWeeklyReport = (type, id) => {
    setOpenDrawer({
      open: type === "detail" ? true : !isSetOpenDrawer.open,
      type: type,
      wklyRptId: "",
    });
    if (!isEmpty(id))
      dispatch(actionForWeeklyAddUpdateReportGetDetails(id), setWeeklyId(id));
  };

  useEffect(() => {
    if (!tenderCeadWeeklyReportAddUpdateLoading) {
      setOpenDrawer({
        ...isSetOpenDrawer,
        open: isSetOpenDrawer.wklyRptId ? true : false,
        type: isSetOpenDrawer.wklyRptId ? "detail" : "",
      });
    }
  }, [tenderCeadWeeklyReportAddUpdateLoading]);

  return (
    <>
      <Card className="c-w-r">
        <Button
          className="c-add-w-r"
          onClick={() => {
            handleDrawerWeeklyReport("form");
            weeklyForm.resetFields();
          }}
        >
          + Add Weekly Reporting
        </Button>
        {tenderCeadWeeklyReportDetailsListLoading ? (
          <Skeleton active />
        ) : (
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            className="c-w-r-c-t"
          />
        )}
      </Card>

      <Drawer
        title={
          isSetOpenDrawer.type === "form"
            ? "Weekly Report Form"
            : "Weekly Report Detail"
        }
        visible={isSetOpenDrawer.open}
        onClose={handleDrawerWeeklyReport}
        maskClosable={false}
        mask={false}
        width={600}
      >
        {isSetOpenDrawer.type === "form" ? (
          <ReportForm
            handleDrawerWeeklyReport={handleDrawerWeeklyReport}
            weeklyForm={weeklyForm}
            setOpenDrawer={setOpenDrawer}
            isSetOpenDrawer={isSetOpenDrawer}
          />
        ) : (
          <WeeklyReportDetail
            setOpenDrawer={setOpenDrawer}
            weeklyForm={weeklyForm}
            id={weeklyId}
          />
        )}
      </Drawer>
    </>
  );
}
