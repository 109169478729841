import React from "react";

// antd
import { Drawer } from "antd";

// includes
import LoanForm from "./Includes/LoanForm";

const LoanDrawer = ({
  drawerVisibility,
  onCloseLoanDrawer,
  selectedLoanApp,
}) => {
  return (
    <Drawer
      className="approval-form-wrapper"
      title="Loan Form"
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      onClose={onCloseLoanDrawer}
      closable={false}
    >
      {/* <Header className='drawer__header'>
				<h4 className='drawer__header--title'>Application</h4>
			</Header> */}
      <div className="drawer__content">
        <div className="drawer__content--wrapper">
          <div className="contact__content__wrapper">
            <LoanForm
              onCloseLoanDrawer={onCloseLoanDrawer}
              selectedLoanApp={selectedLoanApp}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default LoanDrawer;
