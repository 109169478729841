import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";

export class MeetingService {
  getMeetingEventsPagination(query) {
    let url = `${appBaseUrl}/organizerEvent/meetingEventsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  addMeetingEvent(body) {
    let url = `${appBaseUrl}/organizerEvent/meetingEventAdd`;
    let data = postService(url, body);
    return data;
  }

  updateMeetingEvent(body, id) {
    let url = `${appBaseUrl}/organizerEvent/meetingEventUpdate/${id}`;
    let data = postService(url, body, 'put');
    return data;
  }

  meetingMarkAsComplete(id) {
    let url = `${appBaseUrl}/organizerEvent/meetingEventMakeComplete/${id}`;
    let data = postService(url, '', 'patch');
    return data;
  }

  meetingEventAgendaDecisionsList(id) {
    let url = `${appBaseUrl}/organizerEvent/meetingEventAgendaDecisionsList/${id}`;
    let data = getService(url);
    return data;
  }
}
