import { EMAIL_TYPES } from "../../../types";
import { saveContactType } from "services/common";

export const selectedMailTypeAndMail = (data) => {
  // console.log("persist data", data);
  return async (dispatch) => {
    dispatch(saveContactType(EMAIL_TYPES.CONTACT_TYPE_AND_COMPANY, data));
  };
};

export const removeContactPersist = () => {
  return async (dispatch) => {
    dispatch(saveContactType(EMAIL_TYPES.CONTACT_TYPE_REMOVE));
  };
};
