import { Col,Row , Button} from "antd";
import React, {useState, useEffect} from "react";
import LeaveApprovalFormDrawer from "./LeaveApprovalForm.Drawer";
import LeaveApprovalTable from "./LeaveApprovalTable";




 const AddUpdateLeaveApproval  = () => {
const [addUpdateLeaveDrawerVisibility, setAddUpdateLeaveDrawerVisibility] = useState(false);
const [isUpdateApplication, setisUpdateApplication] = useState(false);





const showAddUpdateDrawer = (e, record) => {
    setAddUpdateLeaveDrawerVisibility(false);
    if(record){
        setisUpdateApplication(record);
    }
    else{
        setisUpdateApplication(false);

       
    }
    
    setAddUpdateLeaveDrawerVisibility(true);
};
const onAddUpdateDrawerClose = () => {
  setisUpdateApplication('');
  setAddUpdateLeaveDrawerVisibility(false);
};


    return (
      <>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <h3 className='header__all '>Leave Approval</h3>
          </Col>
          <Col span={12}>
            <div className='organizerHoliday__middle--wrap'>
              <Button onClick={showAddUpdateDrawer}>Leave Approval</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
                <LeaveApprovalTable/>
            <LeaveApprovalFormDrawer
              drawerVisibility={addUpdateLeaveDrawerVisibility}
              onAddUpdateDrawerClose={onAddUpdateDrawerClose}
              isUpdateApplication={isUpdateApplication}
            />
          </Col>
        </Row>
      </>
    );
}

export default AddUpdateLeaveApproval;