import { NOTIFICATIONS_TYPES } from "../../types";
import { sameType } from "services/common";
import { isEmpty } from "lodash-es";

export const setBellNotifications = (data) => {
  return async (dispatch) => {
    if (!isEmpty(data)) {
      dispatch(sameType(NOTIFICATIONS_TYPES.SET_BELL_NOTIFICATION_LISTS, data));
    } 
  };
};

export const setNotificationConnection = (data) => {
  return async (dispatch) => {
    if (!isEmpty(data)) {
      dispatch(sameType(NOTIFICATIONS_TYPES.SET_CONNECTION, data));
    } 
  };
};

export const setBellNotificationCount = (count) => {
  return async (dispatch) => {
    if (count) {
      dispatch(sameType(NOTIFICATIONS_TYPES.GET_BELL_NOTIFICATION_COUNT, count));
    } 
  };
};