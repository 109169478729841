import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash-es";

// includes
import EditRoleDrawer from "./EditRoleDrawer";
import RoleListsTable from "./RoleListsTable";
import EditPermissionsDrawer from "./EditPermissionsDrawer";

// antd
import { Button, Row, Col } from "antd";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRoles,
  getPermissionListsForRole,
} from "services/redux/adminSettings/roles/action";

const RoleLists = ({ grantedPolices }) => {
  const dispatch = useDispatch();
  const roleListsState = useSelector((state) => state.roleLists);

  const { allRoleLists, roleListsLoading, permissionListsForRoleResp } =
    roleListsState;

  const [roleDetails, setRoleDetails] = useState({});
  const [permissionDetails, setPermissionDetails] = useState({});
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [editPermissionDrawerVisibility, setEditPermissionDrawerVisibility] =
    useState(false);

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const onAddUpdateRoleClose = () => {
    setRoleDetails("");
    setDrawerVisibility(false);
  };

  const editRoleDetails = (data) => {
    setRoleDetails(data);
    setDrawerVisibility(true);
  };

  const showRoleDrawer = () => {
    setDrawerVisibility(true);
    setRoleDetails("");
  };

  const editPermissions = (data) => {
    setPermissionDetails(data);
    setEditPermissionDrawerVisibility(true);
    dispatch(getPermissionListsForRole(data.name));
  };

  const onEditPermissionClose = () => {
    setPermissionDetails("");
    setEditPermissionDrawerVisibility(false);
  };

  return (
    <div>
      <div className="all-header-wrapper organizer meetings">
        <div className="meeting__flex">
          <h3 className="header__all">Role</h3>
          {!isEmpty(grantedPolices) &&
            grantedPolices["AbpIdentity.Roles.Create"] && (
              <Button className="ant-btn btn-primary" onClick={showRoleDrawer}>
                Add Role
              </Button>
            )}
        </div>
      </div>

      <Row>
        <Col lg={24}>
          <RoleListsTable
            allRoleLists={allRoleLists}
            roleListsLoading={roleListsLoading}
            editRoleDetails={editRoleDetails}
            editPermissions={editPermissions}
            grantedPolices={grantedPolices}
          />
        </Col>
      </Row>
      <EditRoleDrawer
        onAddUpdateRoleClose={onAddUpdateRoleClose}
        roleDetails={roleDetails}
        drawerVisibility={drawerVisibility}
      />
      <EditPermissionsDrawer
        onEditPermissionClose={onEditPermissionClose}
        permissionDetails={permissionDetails}
        drawerVisibility={editPermissionDrawerVisibility}
        permissionListsForRoleResp={permissionListsForRoleResp}
      />
    </div>
  );
};

export default RoleLists;
