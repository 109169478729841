import React, { useMemo } from "react";
import { Table, Empty } from "antd";
import { EyeOutlined } from "@ant-design/icons";

function JobInterviewTable({ setJobForm, tblData, isLoading }) {
  const updateDataInOtherComponent = (values) => {
    setJobForm((prevJobForm) => ({
      ...prevJobForm,
      data: values,
      view: true,
      update: false,
      add: false,
    }));
  };

  const columns = [
    {
      title: "S.N",
      dataIndex: "sn",
      key: "sn",
      width: "5%",
      render: (text, object, index) => index + 1,
    },
    // {
    //   title: "applicantName",
    //   dataIndex: "applicantName",
    //   key: "applicantName",
    // },
    {
      title: "Interview by",
      dataIndex: "interviewerName",
      key: "interviewerName",
    },
    {
      title: "Date of Interview",
      dataIndex: "dateOfInterview",
      key: "date",
      render: (_, record) => {
        return <>{record?.secondInterviewSchedule}</>;
      },
    },
    {
      title: "Total Marks (140)",
      dataIndex: "id",
      key: "totalMarks",
      render: (id) => {
        const propertiesToSum = [
          "applicableSkills",
          "attitude",
          "candidateAppearance",
          "communication",
          "confidence",
          "education",
          "emotionalIntelligence",
          "enthusiasm_energy",
          "flexibility",
          "language",
          "learning_Attitude",
          "publicRelation",
          "understanding",
          "workExperience",
        ];

        const record = tblData.find((item) => item.id === id);
        const total = propertiesToSum.reduce((acc, property) => {
          return acc + (parseInt(record[property]) || 0);
        }, 0);

        return <span>{total}</span>;
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
            <EyeOutlined onClick={() => updateDataInOtherComponent(record)} />
          </>
        );
      },
    },
  ];

  return (
    <>
      {tblData?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={tblData}
          loading={isLoading}
          pagination={false}
        />
      ) : (
        <Empty />
      )}
    </>
  );
}

export default JobInterviewTable;
