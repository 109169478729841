import { jsonParser } from "utils/misc";
import { EMAIL_TYPES, AUTH_TYPES } from "../../../types";

const INITIAL_STATE = {
  selectedMailTypeAndMailData:
    jsonParser(
      jsonParser(sessionStorage.getItem("persist:root"))?.contactSelectedState
    )?.selectedMailTypeAndMailData || {},
};

export default function contactSelectedReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EMAIL_TYPES.CONTACT_TYPE_AND_COMPANY:
      return {
        ...state,
        selectedMailTypeAndMailData: action.payload,
      };
    case EMAIL_TYPES.CONTACT_TYPE_REMOVE:
      return {
        ...state,
        selectedMailTypeAndMailData: "",
      };
    case AUTH_TYPES.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        selectedMailTypeAndMailData: "",
      };
    default:
      return { ...state };
  }
}
