import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space } from "antd";
import moment from "moment";

import NoStyleButton from "components/Button/NoStyleButton";
import LoanDrawer from "../../AddUpdateEmployeeEvaluation/LoanDrawer";
import { EyeOutlined } from "@ant-design/icons";
import { getEmployeeEvaluationApplication } from "../../../../services/redux/requistions/EmployeeEvaluation/action";

const EmployeeEvaluationTable = ({
  drawerVisibility,
  onCloseDrawer,
  showDrawer,
  setShowDrawer,
  selectedRecord,
  setSelectedRecord,
}) => {
  const dispatch = useDispatch();

  const { getEmployeeEvaluationLoading, getEmployeeEvaluationListingResp } =
    useSelector((state) => state.employeeEvaluation);

  const dataCol = [
    {
      title: "S.N",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Job",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Username",
      dataIndex: "employeeInitials",
      key: "employeeInitials",
    },
    {
      title: "Supervisor",
      dataIndex: "immidiateSuperior",
      key: "immidiateSuperior",
      render: (record) => {
        return (
          <>
            {record.map((item, index) => (
              <span
                key={index}
                style={{
                  background: "#d6d6d6",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  marginRight: "5px",
                }}
              >
                {item}
              </span>
            ))}
          </>
        );
      },
    },
    {
      title: "Review (in days)",
      dataIndex: "reviewPeriod",
      key: "reviewPeriod",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => {
        return <>{moment(record?.date).format("YYYY-MM-DD")}</>;
      },
    },
    {
      title: "Action",
      render: (record) => (
        // <Space size="middle">
        //   <NoStyleButton
        //     text="View"
        //     OnClickButton={() => {
        //       setSelectedRecord(record);
        //       setShowDrawer(!showDrawer);
        //     }}
        //   />
        // </Space>
        <EyeOutlined
          onClick={() => {
            setSelectedRecord(record);
            setShowDrawer(!showDrawer);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(getEmployeeEvaluationApplication());
  }, [dispatch]);

  return (
    <>
      <Table
        // className="employee-evaluation-tbl"
        className="next-organizer-table"
        dataSource={getEmployeeEvaluationListingResp?.items}
        columns={dataCol}
        loading={getEmployeeEvaluationLoading}
        pagination={false}
      />

      <LoanDrawer
        drawerVisibility={showDrawer}
        onCloseDrawer={onCloseDrawer}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
      />
    </>
  );
};

export default EmployeeEvaluationTable;
