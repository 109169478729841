import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";
import queryString from "query-string";
export class ToDoListService {
  getTodoEventsPagination(query) {
    let url = `${appBaseUrl}/organizerEvent/todoEventsPagination?${query}`;
    let data = getService(url);
    return data;
  }
  // privatePaginationData() {
  // 	let url = `${appBaseUrl}/organizerEvent/todoEventPrivateListing`;
  // 	let data = getService(url);
  // 	return data;
  // }

  getTodoEventCompletedList(query) {
    let url = `${appBaseUrl}/organizerEvent/todoEventsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  getAllTodayTasks(query) {
    const query1 = queryString.stringify(query);
    let url = `${appBaseUrl}/organizerEvent/todoEventsPagination?${query1}`;
    let data = getService(url);
    return data;
  }

  getTodoEventsKanban() {
    let url = `${appBaseUrl}/organizerEvent/todoEventsKabanPagination?SkipCount=0&MaxResultCount=50`;
    let data = getService(url);
    return data;
  }

  addToDoEvent(body) {
    // console.log("API body: ", body)
    let url = `${appBaseUrl}/organizerEvent/todoEventAdd`;
    let data = postService(url, body);
    return data;
  }

  eventGetDetails(id) {
    let url = `${appBaseUrl}/organizerEvent/todoEventGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  updateToDoEvent(body, id) {
    let url = `${appBaseUrl}/organizerEvent/todoEventUpdate/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  toDoMarkAsComplete(id) {
    let url = `${appBaseUrl}/organizerEvent/todoEventMakeComplete/${id}`;
    let data = postService(url, "", "patch");
    return data;
  }

  toDoMarkAsFlagged(id) {
    let url = `${appBaseUrl}/organizerEvent/todoEventMakeAsFlagged/${id}`;
    let data = postService(url, "", "patch");
    return data;
  }
  kanbanDragData(taskId, statusId) {
    let url = `${appBaseUrl}/organizerEvent/kabanTaskStatus?TaskId=${taskId}&StatusId=${statusId}`;
    let data = postService(url, "", "put");
    return data;
  }
  todoEventGetAllCounter() {
    let url = `${appBaseUrl}/organizerEvent/todoEventGetAllCounter`;
    let data = getService(url);
    return data;
  }

  getTasksReference() {
    let url = `${appBaseUrl}/organizerEvent/todoEventGetParticipantAttachReferenceListing`;
    let data = getService(url);
    return data;
  }
  getAgendaListing(body) {
    const query = queryString.stringify(body);
    let url = `${appBaseUrl}/organizerEvent/todoAgendaGetListing?${query}`;
    let data = getService(url);
    return data;
  }
}
