import { error, finish, init, success } from "services/common";
import { DocumentSectorServices } from "./api";
import { DOCUMENT_SINGLE_GET_TYPES } from "services/types";
const documentSectorServices = new DocumentSectorServices();

export const getSingleDocumentDetails = (id, body) => {
  return async (dispatch) => {
    dispatch(init(DOCUMENT_SINGLE_GET_TYPES.DOCUMENT_SINGLE_GET));
    const response = await documentSectorServices.getSingleDocumentDetails(
      id,
      body
    );
    dispatch(finish(DOCUMENT_SINGLE_GET_TYPES.DOCUMENT_SINGLE_GET));
    if (response?.isSuccess) {
      dispatch(
        success(DOCUMENT_SINGLE_GET_TYPES.DOCUMENT_SINGLE_GET, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};
