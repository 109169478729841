import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService } from "../../../../commonServices";

export class LeaveFiscalDayService {

  leaveApplicationFiscalDaysPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationFiscalDaysPagination?${query}`;
    let data = getService(url);
    return data;
  }

  leaveApplicationFiscalDayAdd(body) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationFiscalDayAdd`;
    let data = postService(url, body, 'post');
    return data;
  }

  leaveApplicationFiscalDayUpdate(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationFiscalDayUpdate/${id}`;
    let data = postService(url, body, 'put');
    return data;
  }
}
