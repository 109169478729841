import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Space, Button } from 'antd';
import { LogoImage } from 'utils/imageConstants';
import { stagePagination } from 'services/redux/Reference/action';

const StageAddUpdateTable = ({ showAddUpdateStageDrawer }) => {
	const columns = [
		{
			title: 'S No',
			key: "sno",
			render: (text, object, index) => index + 1,
			width:"5%",
		},
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Stage Name',
			dataIndex: 'stageName',
			key: 'stageName',
		},
		{
			title: 'Action',
			key: 'action',
			render: (record) => (
				<a className="action-btn action-btn-add" onClick={(e) => showAddUpdateStageDrawer(e, record)}>
					Edit Stage
				</a>
			),
		},
	];

	const dispatch = useDispatch();
	const { stagePaginationResponse, stagePaginationLoading } = useSelector((state) => state.reference);

	const [data, setdata] = useState([]);
	const [totalCount, setTotalCount] = useState(10);
	const pageSize = 10;

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (!stagePaginationResponse) return;
		setdata(stagePaginationResponse.items);
		setTotalCount(stagePaginationResponse.totalCount);
	}, [stagePaginationResponse]);

	const getData = (MaxResultCount = pageSize, SkipCount = 0, Sorting, SortType) => {
		dispatch(
			stagePagination({
				MaxResultCount,
				SkipCount,
				Sorting,
				SortType,
			})
		);
	};

	const handleTableChange = (pagination, filters, sorter, extra) => {
		let Sorting = sorter.columnKey;
		let SortType = sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : undefined;
		let MaxResultCount = pageSize;
		let SkipCount = (pagination.current - 1) * pagination.pageSize;

		getData(MaxResultCount, SkipCount, Sorting, SortType);
		// getData(pagination.current, pagination.pageSize);
	};

	return (
		<Table
			className="organizer__tables"
			columns={columns}
			rowKey="appUserId"
			dataSource={data}
			onChange={handleTableChange}
			loading={stagePaginationLoading}
			loading={{
				indicator: <img src={LogoImage} height="auto" width="50px" />,
				spinning: stagePaginationLoading,
			}}
			pagination={{
				total: totalCount,
				pageSize: pageSize,
			}}
		/>
	);
};

export default StageAddUpdateTable;
