import Axios from "axios"
const baseUrl = "http://localhost:3000/todo";

export class TodosService {
  async getTodos() {
    let apiEndPoint = `${baseUrl}`;
    return await Axios.get(apiEndPoint);
  }
  async postTodo(body) {
    let apiEndPoint = `${baseUrl}`;
    return await Axios.post(apiEndPoint, {item: body});
  }
}
