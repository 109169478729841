import { Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";

const LoanMoreDetails = ({
  accountantUser,
  fromUser,
  supervisorStatus,
  accountantStatus,
  managementUser,
  managementStatus,
}) => {
  return (
    <>
      {" "}
      {accountantUser &&
        !fromUser &&
        supervisorStatus === "Approved" &&
        accountantStatus === "Pending" && (
          <div className="loan_accountant_management_form">
            <Row gutter={[10, 10]}>
              <Col span={8}>Provident Fund Deposited till date:</Col>
              <Col span={16}>
                <Form.Item
                  name="providentFundDepositTillDate"
                  className="form_group"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <DatePicker autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col span={8}>Rate Reasonability:</Col>
              <Col span={16}>
                <Form.Item
                  name="rateReasonableComment"
                  className="form_group"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      {managementUser &&
        accountantStatus === "Approved" &&
        managementStatus === "Pending" && (
          <div className="loan_accountant_management_form">
            <Row gutter={[10, 10]}>
              <Col span={8}>Approved Amount:</Col>
              <Col span={14}>
                <Form.Item
                  name="approvedAmount"
                  className="form_group"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col span={8}>Loan Repayment Per Month:</Col>
              <Col span={14}>
                <Form.Item
                  name="loanRepaymentAmountPerMonth"
                  className="form_group"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
    </>
  );
};

export default LoanMoreDetails;
