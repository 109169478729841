import React from "react";
import { Drawer, Button, Layout, Row, Col } from "antd";
import TutorialForm from "./TutorialForm";

function TutorialDrawer({
  form,
  categoryType,
  dispatch,
  tutorial,
  handleClose,
  handleDelete,
  isTutorialDrawerOpen,
  isSetEditData,
  isFormLoading,
  setIsFormLoading,
  setEditData,
  setTutorial,
  setIsTutorialDrawerOpen,
}) {
  const { Header } = Layout;
  const formProps = {
    form,
    categoryType,
    dispatch,
    tutorial,
    isSetEditData,
    isFormLoading,
    setIsFormLoading,
    setIsTutorialDrawerOpen,
    handleClose,
  };

  return (
    <>
      <Drawer
        visible={isTutorialDrawerOpen}
        onClose={handleClose}
        closable={false}
        key="right"
        width={380}
        placement="right"
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">{tutorial?.type}</h4>
        </Header>

        {tutorial?.add && (
          <TutorialForm {...formProps} type="add" record={null} />
        )}

        {tutorial?.edit && (
          <TutorialForm {...formProps} type="edit" record={setEditData} />
        )}

        {tutorial?.view && (
          <div className="mail-leave-details">
            <div className="leave-details" style={{ margin: "0px 0px" }}>
              <Row gutter={20}>
                <Col lg={12} className="leave-col">
                  <div className="leave-input">Title</div>
                  <div className="leave-input">Description</div>
                  <div className="leave-input">Category</div>
                  <div className="leave-input">Youtube Url</div>
                </Col>
                <Col lg={12} className="leave-col">
                  <div className="leave-input">{setEditData?.title}</div>
                  <div className="leave-input">{setEditData?.description}</div>
                  <div className="leave-input">
                    {setEditData?.tutorialTypeName}
                  </div>
                  <div className="leave-input">{setEditData?.youTubeLink}</div>
                </Col>
              </Row>
            </div>
            <div
              className="model-footer"
              style={{ justifyContent: "space-between" }}
            >
              <div>
                <Button danger onClick={() => handleDelete(setEditData)}>
                  Delete
                </Button>
              </div>
              <div>
                <Button
                  type="secondary"
                  onClick={handleClose}
                  style={{ marginRight: "8px" }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setTutorial({ type: "Edit", edit: true });
                    //   setEditData(setEditData);
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
}

export default TutorialDrawer;
