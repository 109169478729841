import { Button, Col, Dropdown, Menu, Row, Table, Typography } from "antd";
import moment from "moment";
import AllFileViewer from "components/Documents/allFileViewer";
import { useDispatch, useSelector } from "react-redux";
import { actionForPaymentReceivedPrincipalInvoiceGenerate } from "services/redux/cead/action";
import { cqBaseUrl } from "utils/config";
import { FileOutlined, EditOutlined } from "@ant-design/icons";
import { showNumber } from "utils/showNumber";

const { Text, Title } = Typography;

export default function PaymentReceivedByPrincipalDetails({
  singleTenderCeadData,
}) {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.auth);
  const { invoiceLoading } = useSelector((state) => state.ceadReducer);

  const columns = [
    {
      title: "Group Index",
      dataIndex: "groupIndex",
    },
    {
      title: "Currency",
      dataIndex: "abbvr",
    },
    {
      title: "Amount",
      className: "text-align--right",
      // dataIndex: "variationAmount",
      render: (record) => {
        // const variationAmnt = record?.variationAmount;
        // if (Number.isInteger(variationAmnt)) {
        //   return variationAmnt.toString();
        // } else {
        //   return variationAmnt.toFixed(2);
        // }
        return record?.variationAmount
          ? showNumber(record?.variationAmount)
          : "--";
      },
    },
    {
      title: "L/C Description",
      dataIndex: "remarks",
    },

    // {
    //   title: "Variation Amount",
    //   dataIndex: "variationAmount",
    // },

    {
      title: "Fee Percentage",
      className: "text-align--right",
      dataIndex: "feePercentage",
    },
    {
      title: "Exclusions",
      className: "text-align--right",
      dataIndex: "exclusions",
    },
    {
      title: "Vat Percentage",
      className: "text-align--right",
      dataIndex: "vatPercentage",
    },
    {
      title: "Vat On Fee Percentage",
      className: "text-align--right",
      dataIndex: "vatOnFeePercentage",
    },
    {
      title: "Ex. Rate",
      className: "text-align--right",
      dataIndex: "exchangeRate",
    },
    {
      title: "Created Date",
      dataIndex: "creationtime",
      render: (record) => {
        return record?.creationtime
          ? moment(record?.creationtime).format("YYYY-MM-DD")
          : null;
      },
    },
    {
      title: "Action",
      render: (text, record) => {
        // console.log("record", record);
        return (
          <div style={{ display: "flex", placeItems: "center" }}>
            {!record.isInvoiceGenerated ? (
              <>
                <EditOutlined
                  onClick={() => console.log("edit record", record)}
                />
                <Button
                  onClick={() => {
                    dispatch(
                      actionForPaymentReceivedPrincipalInvoiceGenerate(
                        record.id
                      )
                    );
                  }}
                  style={{
                    fontSize: "small",
                    width: 55,
                    height: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  loading={invoiceLoading}
                >
                  Invoice
                </Button>
              </>
            ) : (
              <>
                {record?.uploadedFileDtos &&
                  record?.uploadedFileDtos.length > 0 && (
                    <Dropdown
                      overlay={
                        <Menu>
                          {record?.uploadedFileDtos.map((file, index) => (
                            <Menu.Item key={index}>
                              <AllFileViewer
                                file={file}
                                cqBaseUrl={cqBaseUrl}
                                token={token}
                                height={600}
                                width={900}
                              />
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                      title="File Name"
                    >
                      <FileOutlined
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Dropdown>
                  )}
              </>
            )}
          </div>
        );
      },
    },
  ];

  const dataSources =
    singleTenderCeadData?.tenderCEADPaymentReceivedPrincipalDto;
  const transformedDataSource = dataSources?.flatMap(
    ({ groupIndex, remarks }, index) =>
      singleTenderCeadData?.tenderCEADPaymentReceivedPrincipalDto?.[
        index
      ]?.tenderPaymentReceivedPrincipalInformationDtos?.map((x) => ({
        groupIndex,
        ...x,
        remarks,
      }))
  );

  const dataSource = transformedDataSource
    ?.sort((a, b) => {
      return a.groupIndex - b.groupIndex;
    })
    .map((x) => {
      return {
        ...x,
        ...x?.currencyDto,
      };
    });

  return (
    <>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
    </>
  );
}
