import { Button, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllApprovalDetails,
  getSingleTadaDetails,
} from "services/redux/requistions/tadaDetails/action";
import { EditOutlined } from "@ant-design/icons";

import Permission from "components/Requisition/Permission";
import storage from "utils/storage";
import RequisitionModal1 from "views/Home/Modal/Requisition";

const TadaApproverTable = () => {
  const dispatch = useDispatch();

  const { tadaApprovalResponse, tadaApprovalLoading } = useSelector(
    (state) => state.tadaDetails
  );

  const { getTadaSingleDetailsResponse, getTadaSingleDetailsLoading } =
    useSelector((state) => state?.tadaDetails);

  const [requisitionModal, setRequisitionModal] = useState(false);

  useEffect(() => {
    dispatch(getAllApprovalDetails());
  }, []);

  const columns = [
    {
      title: "Company Name",
      key: "sisterCompanyName",
      dataIndex: "sisterCompanyName",
    },
    {
      title: "Requested User",
      key: "requestedUserName",
      dataIndex: "requestedUserName",
    },
    {
      title: "Subject",
      key: "subject",
      dataIndex: "subject",
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (_, record) => {
        return <>{moment(record?.creationTime).format("YYYY MMM DD")}</>;
      },
    },
    {
      title: "Status",
      key: "applicationStatusName",
      dataIndex: "applicationStatusName",
    },
    {
      title: "Action",
      key: "",
      dataIndex: "",
      render: (_, record) => {
        return (
          <>
            <Button
              style={{ height: "28px" }}
              onClick={() => {
                console.log("Clicked here", record);
                setRequisitionModal(true);
                dispatch(getSingleTadaDetails(record?.id));
              }}
            >
              <EditOutlined
                style={{
                  fontSize: "16px",
                  color: "#08c",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                }}
              />
            </Button>
          </>
        );
      },
    },
  ];

  const {
    fromUser,
    accountantUser,
    managementUser,
    supervisorStatus,
    accountantStatus,
    managementStatus,
  } = Permission({
    // loan,
    // procurement,
    tada: true,
    storage,
    // loanApplicationDataGetDetails,
    // procurementDetails,
    getTadaSingleDetailsResponse,
  });

  return (
    <>
      <h2>To Approve TA/DA details</h2>
      <Table
        className="next-organizer-table"
        dataSource={tadaApprovalResponse?.items}
        loading={tadaApprovalLoading}
        pagination={true}
        columns={columns}
      />

      {requisitionModal && (
        <RequisitionModal1
          // leaveDetails={leaveDetails}
          // leaveDetailsLoading={leaveDetailsLoading}
          // procurementDetails={procurementDetails}
          // procurementDetailsLoading={procurementDetailsLoading}
          // loanApplicationDataGetDetails={loanApplicationDataGetDetails}
          // loanApplicationDataGetDetailsLoading={
          //   loanApplicationDataGetDetailsLoading
          // }
          requisitionModal={requisitionModal}
          setRequisitionModal={setRequisitionModal}
          // loan={loan}
          // leave={leave}
          // procurement={procurement}
          tada={true}
          accountantUser={accountantUser}
          accountantStatus={accountantStatus}
          managementUser={managementUser}
          managementStatus={managementStatus}
          supervisorStatus={supervisorStatus}
          loanApplicationId={getTadaSingleDetailsResponse?.id}
          fromUser={fromUser}
          // showMore={showMore}
          // setShowMore={setShowMore}
          // loanUpdateSupervisorLoading={loanUpdateSupervisorLoading}
          // loanUpdateAccountantLoading={loanUpdateAccountantLoading}
          // loanUpdateManagementLoading={loanUpdateManagementLoading}
          // procurementUpdateAccountantLoading={
          //   procurementUpdateAccountantLoading
          // }
          // procurementUpdateSupervisorLoading={
          //   procurementUpdateSupervisorLoading
          // }
          // procurementUpdateManagementLoading={
          //   procurementUpdateManagementLoading
          // }
          // leaveUpdateManagementLoading={leaveUpdateManagementLoading}

          // getProcurementAction={getProcurementAction}
          getTadaSingleDetailsResponse={getTadaSingleDetailsResponse}
          getTadaSingleDetailsLoading={getTadaSingleDetailsLoading}
        />
      )}
    </>
  );
};

export default TadaApproverTable;
