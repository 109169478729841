import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Form,
  Row,
  Col,
  Input,
  Layout,
  Select,
  DatePicker,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";
import { isEmpty, startCase } from "lodash";
import {
  complaintAdd,
  complaintStatusUpdate,
  complaintUpdate,
} from "services/redux/complaint/action";
import storage from "utils/storage";

const ComplainBox = (props) => {
  useEffect(() => {
    if (isEmpty(allEventStatusListing)) {
      dispatch(getStatusEventListing());
    }
  }, []);
  const {
    drawerVisibility,
    onCloseLeaveDrawer,
    selectedModule,
    employeeProfile,
    userName,
    complainDetail,
    viewRemarks,
    setViewRemarks,
    grantedPolices,
  } = props;
  //  console.log(complainDetail,"complainDetail")
  const { Header, Footer } = Layout;
  const { TextArea } = Input;
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectDropdown, setSelectDroprdown] = useState(false);
  const [test, setTest] = useState();

  const statusListingState = useSelector((state) => state.statusListing);
  const { allEventStatusListing } = statusListingState;
  const {
    complaintPaginationResponse,
    complaintAddResponse,
    complaintAddResponseLoading,
    complaintUpdateResponse,
    complaintUpdateResponseLoading,
  } = useSelector((state) => state.complaint);
  // console.log(complaintAddResponseLoading,"complaintAddResponseLoading");

  useEffect(() => {
    if (!isEmpty(complaintAddResponse) || !isEmpty(complaintUpdateResponse)) {
      setTimeout(() => {
        onCloseLeaveDrawer();
        form.resetFields();
      }, 500);
    }
  }, [complaintAddResponse, complaintUpdateResponse]);

  // useEffect(() => {
  //   if(complainDetail?.statusId == "6a4a2e83-c05a-4134-999b-fd211a546e86")
  //   {
  //     setTest();
  //   }
  // },[])

  let localStorageName = storage.getItem("name");
  let userRole = storage.getItem("role");
  const handleChange = (event) => {
    // console.log(event)
    setSelectDroprdown(event);
  };
  const onFinish = (values) => {
    let addValue = {
      ...values,
      issueName: userName,
    };
    if (!isEmpty(complainDetail)) {
      dispatch(
        complaintUpdate(values, complainDetail.id, props?.selectedModule)
      );
    } else {
      dispatch(complaintAdd(addValue));
    }
  };

  useEffect(() => {
    if (!isEmpty(complainDetail)) {
      form.setFieldsValue({
        issueName: complainDetail.issueName,
        remarks: complainDetail.remarks,
        completionRemarks: complainDetail.completionRemarks,
        title: complainDetail.title,
        statusId: complainDetail.statusId,
      });
    } else {
      form.resetFields();
    }
  }, [complainDetail]);

  return (
    <Drawer
      visible={drawerVisibility}
      maskClosable={false}
      onClose={onCloseLeaveDrawer}
      className="drawer complain-drawer main-drawer-wrapper"
      width={472}
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">Complain Box</h4>
      </Header>
      <div className="drawer__content">
        <div className="drawer__content--wrapper">
          <div className="contact__content__wrapper">
            <Form
              form={form}
              className="add__user__form drawer-form-wrapper"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="drawer-form-row">
                <div className="drawer-form-group">
                  <Form.Item
                    className="form__group"
                    name="issueName"
                    label="Feedback By"
                    rules={[{ required: false }]}
                    initialValue={userName}
                  >
                    <Input
                      defaultValue={userName}
                      disabled={true}
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    label="Module"
                    name="title"
                    className="form__group"
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                    autoComplete="off"
                  >
                    <Select
                      placeholder="Select Module"
                      disabled={
                        !isEmpty(viewRemarks) ||
                        (!isEmpty(grantedPolices) &&
                          grantedPolices["AbpIdentity.Roles"] &&
                          !isEmpty(complainDetail) &&
                          userName !== complainDetail?.issueName)
                      }
                      className="margin-select-placeholder"
                    >
                      <Option value="MailBox">MailBox</Option>
                      <Option value="Calendar">Calendar</Option>
                      <Option value="Contact">Contact</Option>
                      <Option value="Business">Business</Option>
                      <Option value="Task">Task</Option>
                      <Option value="Document">Document</Option>
                      <Option value="Approvals">Approvals</Option>
                      <Option value="HR">HR</Option>
                    </Select>
                  </Form.Item>
                </div>
                {!isEmpty(grantedPolices) && (
                  <div className="drawer-form-group">
                    <Form.Item
                      className="form__group"
                      name="estimatedDate"
                      label="Estimated Date"
                      rules={[{ required: false }]}
                      // initialValue={userName}
                    >
                      <DatePicker
                        placeholder="Select Estimated Date"
                        size="small"
                        format="YYYY/MM/DD"
                      />
                    </Form.Item>
                  </div>
                )}
                <div className="drawer-form-group">
                  <Form.Item
                    label="Progress"
                    name="statusId"
                    className="form__group"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Required!",
                    //   },
                    // ]}
                    initialValue={allEventStatusListing[1]?.id}
                    hidden={isEmpty(complainDetail)}
                  >
                    <Select
                      placeholder="Select Progress"
                      onChange={handleChange}
                      disabled={
                        !isEmpty(viewRemarks) || isEmpty(grantedPolices)
                      }
                      autoComplete="off"
                    >
                      {!isEmpty(allEventStatusListing) &&
                        allEventStatusListing.map((status, index) => {
                          return (
                            <Option value={status.id} key={index}>
                              <p> {status.name}</p>
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    className="form__group drawer-complain-remarks "
                    name="remarks"
                    label="Remarks"
                    rules={[{ required: true }]}
                  >
                    <TextArea
                      className="drawer-complain-remarks"
                      style={{ height: viewRemarks ? 500 : 100 }}
                      disabled={
                        !isEmpty(viewRemarks) ||
                        (!isEmpty(grantedPolices) &&
                          grantedPolices["AbpIdentity.Roles"] &&
                          !isEmpty(complainDetail) &&
                          userName !== complainDetail?.issueName)
                      }
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
              </div>
              {selectDropdown == "6a4a2e83-c05a-4134-999b-fd211a546e86" ||
              complainDetail?.statusId ==
                "6a4a2e83-c05a-4134-999b-fd211a546e86" ? (
                <div className="completion-remarks">
                  <div className="drawer-form-group">
                    <Form.Item
                      className="form__group"
                      name="completionRemarks"
                      label="Solved Description"
                      rules={[{ required: true }]}
                    >
                      <TextArea
                        className="drawer-complain-remarks"
                        style={{ height: viewRemarks ? 500 : 90 }}
                        autoComplete="off"
                      />
                    </Form.Item>
                  </div>
                </div>
              ) : complainDetail?.statusId ==
                "6a4a2e83-c05a-4134-999b-fd211a546e86" ? (
                <div className="completion-remarks">
                  <div className="drawer-form-group">
                    <Form.Item
                      className="form__group"
                      name="completionRemarks"
                      label="Solved Description"
                      rules={[{ required: true }]}
                    >
                      <TextArea
                        className="drawer-complain-remarks"
                        style={{ height: complainDetail ? 150 : 100 }}
                        autoComplete="off"
                      />
                    </Form.Item>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="model-footer">
                <Button
                  onClick={() => {
                    onCloseLeaveDrawer();
                    form.resetFields();
                    setSelectDroprdown(false);
                  }}
                >
                  <span>Cancel</span>
                </Button>
                {viewRemarks ? (
                  ""
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={
                      complaintAddResponseLoading ||
                      complaintUpdateResponseLoading
                    }
                    disabled={
                      complaintAddResponseLoading ||
                      complaintUpdateResponseLoading
                    }
                  >
                    {complainDetail?.id ? "Update" : "Submit"}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ComplainBox;
