import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// import AddUserDrawer from "./AddUserDrawer";
import { SourceAddUpdateDrawer } from './SourceDrawer';
import SourceAddUpdateTable from './SourceTable';

const Source = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const onReset = () => {
		form.resetFields();
	};
const [showSourceDrawer,setShowSourceDrawer] = useState(false);
const onCloseSourceDrawer = () =>{
    setShowSourceDrawer(false);
}
	return (
		<div className="layout">
			<div className="organizer events">
				<div className="all-header-wrapper">
					<div className="meeting__flex">
						<h3 className="header__all">Source List</h3>
						<div className="organizerHoliday__middle--wrap">
							<Button
								className="btn-primary"
                                onClick={() => {
                                    setShowSourceDrawer(true);
                                }}	
							>
								<PlusOutlined /> New Source
							</Button>
						</div>
					</div>
				</div>
                <SourceAddUpdateTable/>
                <SourceAddUpdateDrawer drawerVisibility={showSourceDrawer} onCloseSourceDrawer={onCloseSourceDrawer}  />
				
			</div>
		</div>
	);
};

export default Source;
