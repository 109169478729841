import { PaperClipOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Steps, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Permission from "components/Requisition/Permission";
import { isEmpty } from "lodash";
import {
  getAllSameTitleProcurement,
  getProcurementDetailsIndividual,
  getSimilarProcurement,
  procurementApplicationUpdateAccountant,
  procurementApplicationUpdateManagement,
  procurementApplicationUpdateSupervisor,
} from "services/redux/requistions/procurement/action";
import { LogoImage } from "utils/imageConstants";
import storage from "utils/storage";
import { cqBaseUrl } from "utils/config";
import { fileDownload } from "utils/commonFunc";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
import pdfViewer from "components/Documents/ViewDocument";
const attachmentView = "assets/svg/attachment-view.svg";
const newWindow = "assets/svg/newWindow.svg";
const attachmentDownload = "assets/svg/attachment-download.svg";

const ProcurementDetails = ({
  procurementId,
  setInstanceMessageCenterListing,
}) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const { Step } = Steps;

  const {
    procurementDetails,
    procurementDetailsLoading,
    procurementUpdateSupervisor,
    procurementSimilarResponse,
    procurementSimilarResponseLoading,
    // procurementUpdateSupervisorLoading,
    procurementUpdateAccountant,
    // procurementUpdateAccountantLoading,
    procurementUpdateManagement,
    // procurementUpdateManagementLoading,
  } = useSelector((state) => state.procurement);

  const {
    allSimilarProcurementResponse,
    allSimilarProcurementLoading,
    // procurementDashResponseLoading,
  } = useSelector((state) => state.procurement);

  // const { selectedMailTypeAndMailData } = useSelector(
  //   (state) => state.mailMessagePeristState
  // );
  const { token } = useSelector((state) => state.auth);

  const [details, setDetails] = useState({});
  const [isApproval, setIsApproval] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleView = (src) => {
    setTimeout(() => {
      // console.log("pdfViewer", pdfViewer);
      pdfViewer1(src);
    }, 1000);
  };

  const RemarksForm = (supervisor) => {
    return (
      <Form name="basic" form={form} onFinish={onFinish}>
        {supervisor && (
          <div className="procurement_accountant_management_form">
            <Form.Item
              label="Vendor Name"
              name="vendorName"
              className="form_group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Tentative Amount"
              name="tentativeAmount"
              className="form_group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input type="number" min={0} />
            </Form.Item>
            <Form.Item
              label="Rate Reasonability"
              name="rateReasonableComment"
              className="form_group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </div>
        )}

        <Form.Item
          label="Remarks"
          name="comment"
          className="form__group"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <div className="procurement_details_footer" style={{ marginTop: 15 }}>
          <Button
            className="procurement_reject"
            htmlType="submit"
            onClick={() => {
              setIsApproval(false);
            }}
          >
            Reject
          </Button>
          <Button
            className="procurement_approved"
            htmlType="submit"
            onClick={() => {
              setIsApproval(true);
            }}
          >
            Accept
          </Button>
        </div>
      </Form>
    );
  };
  const remarksDetails = (name, details, levelName) => {
    return (
      <Row gutter={20}>
        <Col lg={24} className="procurement_col">
          <div className="procurement_title">
            Remarks({name} , {levelName})
          </div>
          <div className="procurement_input">{details}</div>
        </Col>
      </Row>
    );
  };

  const onFinish = (values) => {
    let body = { ...values, isApproval: isApproval };
    // if (supervisorStatus === "Pending" || isEmpty(supervisorStatus)) {
    //   dispatch(procurementApplicationUpdateSupervisor(body, procurementId));
    // } else if (
    //   supervisorStatus === "Approved" &&
    //   accountantStatus === "Pending"
    // ) {
    //   body = { ...body, accountHead: "Kamal Aryal" };
    //   dispatch(procurementApplicationUpdateAccountant(body, procurementId));
    // } else {
    //   body = { ...body, accountHead: "" };
    //   dispatch(procurementApplicationUpdateManagement(body, procurementId));
    // }

    if (managementStatus == "Pending" && supervisorStatus == "Approved") {
      body = { ...body, accountHead: "" };
      dispatch(
        procurementApplicationUpdateManagement(
          body,
          procurementId,
          isApproval,
          undefined,
          undefined,
          undefined,
          setInstanceMessageCenterListing
        )
      );
    } else if (
      supervisorStatus === "Pending" &&
      accountantStatus === "Approved"
    ) {
      body = { ...body, accountHead: "" };
      dispatch(
        procurementApplicationUpdateSupervisor(
          body,
          procurementId,
          isApproval,
          undefined,
          undefined,
          undefined,
          setInstanceMessageCenterListing
        )
      );
    } else if (accountantStatus === "Pending" || isEmpty(accountantStatus)) {
      body = { ...body, accountHead: "" };
      dispatch(
        procurementApplicationUpdateAccountant(
          body,
          procurementId,
          isApproval,
          undefined,
          undefined,
          undefined,
          setInstanceMessageCenterListing
        )
      );
    }
  };
  useEffect(() => {
    if (!isEmpty(procurementDetails)) {
      dispatch(getSimilarProcurement(procurementDetails?.requestedUserId));
    }
  }, [procurementDetails]);

  useEffect(() => {
    dispatch(getProcurementDetailsIndividual(procurementId));
    const body = {
      IsShowOnlyApproved: true,
      IsShowAllUserProcurements: true,
    };
    dispatch(getAllSameTitleProcurement(body));
  }, [procurementId]);

  const leaveColumns = [
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Tentaive Amount",
      key: "tentativeAmount",
      dataIndex: "tentativeAmount",
      render: (_, record) => {
        return <>{record?.tentativeAmount?.toLocaleString()}</>;
      },
    },
  ];
  const {
    supervisorStatus,
    supervisorName,
    accountantStatus,
    accountantName,
    managementStatus,
    managementName,
    supervisorDescription,
    accountantDescription,
    managementDescription,
    fromUser,
    supervisorUser,
    managementUser,
    accountantUser,
  } = Permission({
    procurement: true,
    storage,
    procurementDetails: details,
  });

  useEffect(() => {
    setDetails(procurementDetails);
  }, [procurementDetails]);

  useEffect(() => {
    if (!isEmpty(procurementUpdateSupervisor)) {
      setDetails(procurementUpdateSupervisor?.requisitionResult);
    }
  }, [procurementUpdateSupervisor]);
  useEffect(() => {
    if (!isEmpty(procurementUpdateAccountant)) {
      setDetails(procurementUpdateAccountant?.requisitionResult);
    }
  }, [procurementUpdateAccountant]);
  useEffect(() => {
    if (!isEmpty(procurementUpdateManagement)) {
      setDetails(procurementUpdateManagement?.requisitionResult);
    }
  }, [procurementUpdateManagement]);

  return (
    <div className="mail_procurement_details">
      <div className="procurement_progress">
        <Steps
          current={
            // supervisorStatus === "Approved"
            //   ? accountantStatus === "Approved"
            //     ? managementStatus === "Approved"
            //       ? 5
            //       : 3
            //     : 2
            //   : 1

            managementStatus === "Approved"
              ? 5
              : supervisorStatus == "Approved"
              ? 3
              : accountantStatus === "Approved"
              ? 2
              : 1
          }
        >
          <Step title="Apply" />
          <Step
            title={
              accountantStatus === "Approved"
                ? "Approved"
                : accountantStatus === "Reject"
                ? "Rejected"
                : "Pending"
            }
            description="(Accountant)"
          />
          <Step
            title={
              supervisorStatus === "Approved"
                ? "Approved"
                : supervisorStatus === "Reject"
                ? "Rejected"
                : "Pending"
            }
            description="(Supervisor)"
          />

          <Step
            title={
              managementStatus === "Approved"
                ? "Approved"
                : managementStatus === "Reject"
                ? "Rejected"
                : "Pending"
            }
            status={managementStatus === "Reject" ? "error" : ""}
            description="(Management)"
          />
          <Step title="Approved" />
        </Steps>
      </div>
      {!procurementDetailsLoading && (
        <div className="procurement_details">
          <Row gutter={20}>
            <Col lg={7} className="procurement_col">
              <div className="procurement_title">Company Name</div>
              <div className="procurement_input">
                {details?.sisterCompanies?.name}
              </div>
            </Col>
            <Col lg={7} className="procurement_col">
              <div className="procurement_title">Person Name</div>
              <div className="procurement_input">
                {
                  details?.procurementApplicationParticipantStatuses
                    ?.fromList?.[0]?.participantName
                }
              </div>
            </Col>
            <Col lg={10} className="procurement_col">
              <div className="procurement_title ">Subject</div>
              <div className="procurement_input">{details?.subject}</div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col lg={12} className="procurement_col">
              <div className="procurement_title ">Rate Reasonability</div>
              <div className="procurement_input">
                {details?.rateReasonableComment}
              </div>
            </Col>
            <Col lg={12} className="procurement_col">
              <div className="procurement_title">Amount</div>
              <div className="procurement_input">
                {details?.tentativeAmount?.toLocaleString()}
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col lg={24} className="procurement_col">
              <div className="procurement_title ">Attachment</div>
              <div className="procurement_input">
                <div className="office-attachment-wrapper">
                  <span className="office-attachment-length">
                    <PaperClipOutlined />
                    &nbsp;
                    {details?.procurementApplicationAttachmentDtos?.length}
                  </span>
                  {details?.procurementApplicationAttachmentDtos?.length >
                    0 && (
                    <div className="office-attachment-dropdown">
                      {details?.procurementApplicationAttachmentDtos?.map(
                        (doc, index) => {
                          return (
                            doc.isActive && (
                              <div className="office-attachment-item">
                                <PaperClipOutlined />{" "}
                                <a
                                  target="_blank"
                                  download
                                  href={
                                    cqBaseUrl + "/" + doc.viewFileURL + token
                                  }
                                >
                                  <span className="office-attachment-overflow">
                                    {doc.displayFileName.slice(
                                      0,
                                      doc.displayFileName.length - 5
                                    )}
                                  </span>
                                  {doc.displayFileName.slice(
                                    doc.displayFileName.length - 5,
                                    doc.displayFileName.length
                                  )}
                                </a>
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "none",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setModalVisible(true);
                                    handleView(
                                      `${cqBaseUrl}/${doc?.viewFileURL}${token}`
                                    );
                                  }}
                                >
                                  <img src={attachmentView} alt="view" />
                                </button>
                                {modalVisible && (
                                  <Modal
                                    visible={modalVisible}
                                    title={
                                      <div
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns:
                                            "20fr 0.5fr 0.5fr ",
                                        }}
                                      >
                                        <span>Document Viewer</span>
                                        <span
                                          onClick={() => {
                                            pdfViewer(
                                              `${cqBaseUrl}/${doc?.viewFileURL}${token}`
                                            );
                                          }}
                                        >
                                          <img
                                            src={newWindow}
                                            height={15}
                                            style={{ marginBottom: "5px" }}
                                            alt="new"
                                          />
                                        </span>
                                        <span
                                          onClick={() => {
                                            setModalVisible(false);
                                          }}
                                        >
                                          <CloseOutlined />
                                        </span>
                                      </div>
                                    }
                                    footer={null}
                                    closable={false}
                                    onCancel={() => {
                                      setModalVisible(false);
                                    }}
                                    width="70%"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                      }}
                                    ></div>

                                    {modalVisible && (
                                      <iframe
                                        id="pdfViewerFrame"
                                        width="100%"
                                        height="500px"
                                      />
                                    )}
                                  </Modal>
                                )}
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "none",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    // fileDownload(
                                    //   `${details?.leaveAttachmentDocumentDtos
                                    //     .viewFileURL}`,
                                    //   fileList?.displayFileName
                                    // );
                                    fileDownload(
                                      `${cqBaseUrl}/${doc?.viewFileURL}${token}`,
                                      doc.displayFileName
                                    );
                                  }}
                                >
                                  <img
                                    src={attachmentDownload}
                                    alt="download "
                                  />
                                </button>
                              </div>
                            )
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col lg={24} className="procurement_col">
              <div className="procurement_title">Vendor</div>
              <div className="procurement_input">{details?.vendorName}</div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col lg={24} className="procurement_col">
              <div className="procurement_title">Description</div>
              <div className="procurement_input">{details?.description}</div>
            </Col>
          </Row>

          {/* {supervisorUser && supervisorStatus === "Pending" && RemarksForm()}
          {supervisorStatus === "Approved" &&
            remarksDetails(supervisorName, supervisorDescription, "Supervisor")}
          {accountantUser &&
            !fromUser &&
            supervisorStatus === "Approved" &&
            accountantStatus === "Pending" &&
            RemarksForm("Supervisor")}
          {accountantStatus === "Approved" &&
            remarksDetails(accountantName, accountantDescription, "Accountant")}
          {managementUser &&
            accountantStatus === "Approved" &&
            managementStatus === "Pending" &&
            RemarksForm()}
          {managementStatus === "Approved" &&
            remarksDetails(managementName, managementDescription, "Management")} */}

          {accountantUser &&
            !fromUser &&
            accountantStatus === "Pending" &&
            RemarksForm("Accountant")}

          {accountantStatus === "Approved" &&
            remarksDetails(accountantName, accountantDescription, "Accountant")}

          {supervisorUser &&
            !fromUser &&
            supervisorStatus === "Pending" &&
            accountantStatus === "Approved" &&
            RemarksForm()}

          {supervisorStatus === "Approved" &&
            remarksDetails(supervisorName, supervisorDescription, "Supervisor")}

          {managementUser &&
            !fromUser &&
            supervisorStatus === "Approved" &&
            managementStatus === "Pending" &&
            RemarksForm()}

          {managementStatus === "Approved" &&
            remarksDetails(managementName, managementDescription, "Management")}
        </div>
      )}
      {!isEmpty(procurementSimilarResponse) && (
        <div
          className="leave-types"
          style={{ paddingTop: 25, paddingLeft: 10, paddingBottom: 45 }}
        >
          <b>Summary of Previous Procuuremnt</b>
          <Table
            className="organizer__tables remove-counter"
            columns={leaveColumns}
            rowKey="id"
            dataSource={procurementSimilarResponse}
            pagination={false}
            loading={{
              indicator: (
                <img src={LogoImage} height="auto" width="50px" alt="loading" />
              ),
              spinning: procurementSimilarResponseLoading,
            }}
          />

          <b>Similar Procurement</b>
          <Table
            className="organizer__tables remove-counter"
            columns={leaveColumns}
            rowKey="id"
            dataSource={allSimilarProcurementResponse?.procurementApplications}
            pagination={false}
            loading={allSimilarProcurementLoading}
          />
        </div>
      )}
    </div>
  );
};
export default ProcurementDetails;
