import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BroadcastChannel } from "broadcast-channel";
import { Showdataform } from "./Showdataformcompany";
import { Showdataformperson } from "./Showdataformperson";
import AddCompanyDrawer from "./AddUpdateCompanyDrawer";
import AddPersonDrawer from "./AddUpdatePersonDrawer";

import {
  Button,
  Row,
  Col,
  Input,
  Select,
  Menu,
  Dropdown,
  Skeleton,
  Form,
  Modal,
  message,
  Slider,
  Space,
  Radio,
  Tabs,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";

import {
  sectorListing,
  qualificationListing,
  scopeOfWorkListing,
  scopeofWorkAdd,
} from "services/remanentCalls";
import {
  PlusOutlined,
  StarFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
// import AddUserDrawer from "./AddUserDrawer";
import AddUpdateCompanyTable from "./AddUpdateCompanyTable";
import EditNotes from "./EditNotes";
import PersonEditNotes from "./PersonEditNotes";
import AddUpdatePersonTable from "./AddUpdatePersonTable";
import {
  contactCompanyGetDetails,
  contactPersonGetDetails,
} from "services/redux/contact/companypeople/action";

import {
  getContactPersonPagination,
  getContactCompanyPagination,
  contactUpdate,
} from "services/redux/contact/companypeople/action";
import { selectedMailTypeAndMail } from "services/redux/contact/persistcontact/action";
import { actionForExportSiscoUserListToExcel, actionForExportCompanyUserListToExcel } from "services/redux/holiday/action";
import { saveBase64 } from "utils/saveBase64";
import UniversalFileUpload from "components/fileUpload";

const { Option } = Select;

const addperson = "assets/svg/addperson.svg";
const addcompany = "assets/svg/addcompany.svg";

const ContactCompanyPerson = () => {
  // const formRef = useRef(null);
  const [qualificationData, setQualificationData] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [form] = Form.useForm();
  const [fileForm] = Form.useForm();
  const [companyForm] = Form.useForm();
  const [companyNotesForm] = Form.useForm();
  const [selectedExportValue, setSelectedExportValue] = useState('company');

  const [personForm] = Form.useForm();
  const [expertForm] = Form.useForm();
  const [personNotesForm] = Form.useForm();

  const { Option } = Select;
  const { Search } = Input;
  const dispatch = useDispatch();
  const [favList, setFavList] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFavorite, setIsFavorite] = useState(true);
  const [test, setTest] = useState();
  const [fomValueReset, setFormValueReset] = useState();
  const [advanceButtonDisabled, setAdvanceButtonDisabled] = useState(true);
  const [notes, setNotes] = useState(false);
  const [onClickCompany, setOnClickCompany] = useState(false);
  const [onClickPerson, setOnClickPerson] = useState(false);

  const [scopeSearch, setScopeSearch] = useState("");
  const [scopeTags, setScopeTags] = useState([]);
  const [compareTags, setCompareTags] = useState([]);
  const { TabPane } = Tabs;

  const [searchStatusvalue, setSearchStatusValue] = useState(-1);
  const onContactActiveStatusChange = (e) => {
    // console.log("radio checked", e.target.value);
    setSearchStatusValue(e.target.value);
  };

  const [inputValue, setInputValue] = useState("");
  const [hiddenButton, setHiddenButton] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const bc = new BroadcastChannel("test_channel");
  const bcPerson = new BroadcastChannel("testperson");
  const switchIcon = "assets/svg/toogle-icon.svg";

  const {
    contactCompanyUpdateResponse,
    contactCompanyDetailAddResponse,
    contactPersonAddResponse,
    contactCompanyDelete,
    contactPersonUpdateResponse,
    contactCompanyPaginationResponse,
    contactCompanyGetDetailsResponse,
    contactPersonGetDetailsResponse,
    contactFavSelectReponse,
    contactPersonPaginationResponse,
    contactPersonNoteRespone,
    contactCompanyNoteRespone,
    contactPersonDelete,
    contactCompanyGetDetailsLoading,
    contactPersonGetDetailsLoading,
    contactPersonPaginationLoading,
  } = useSelector((state) => state.contactCompanyPerson);

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.contactSelectedState
  );

  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );

  const {
    holidaySampleResponse,
    holidaySampleLoading,
    userSiscolListResponse,
    userSiscoListLoading
  } = useSelector((state) => state.holiday);

  // const [showNotes, setShowNotes] = useState('');
  const [data, setdata] = useState([]);

  const [isUpdateCountry, setIsUpdateCountry] = useState(false);
  const [isUpdatePerson, setIsUpdatePerson] = useState(false);
  const [id, setId] = useState();
  const [contactCompanyId, setContactCompanyId] = useState("");
  const [contactPersonId, setContactPersonId] = useState("");
  const [showAll, setshowAll] = useState(false);
  const [show, sethidden] = useState(false);

  const [
    addUpdateCountryDrawerVisibility,
    setAddUpdateCountryDrawerVisibility,
  ] = useState(false);
  const [addUpdatePersonDrawerVisibility, setAddUpdatePersonDrawerVisibility] =
    useState(false);
  //  const [eventFormSelect, setEventFormSelect] = useState('');
  const [SearchKeyword, setSearchKeyword] = useState();
  const [searchPersonCompany, setSearchPersonCompany] = useState("");
  const [grantedPolices, setGrantedPolices] = useState({});
  // const [hideInternalMessage, setHideInternalMessage] = useState(true);
  const [starColor, setStarColor] = useState("#c8c8c8");
  const [inputSearchValue, setInputSearcHValue] = useState();
  const [inputSearchList, setInputSearchList] = useState();
  const [responseNotes, setResponseNotes] = useState();
  const [personResponseNotes, setPersonResponseNotes] = useState();
  const [companyData, setCompanyData] = useState([]);
  const [persistData, setPersistData] = useState();
  const [addUpdateDrawerVisibility, setAddUpdateDrawerVisibility] =
    useState(false);
  const [showArrow, setShowArrow] = useState(true);
  const [subjectValue, setSubjectValue] = useState("");
  const [qulification, setQulification] = useState("");
  //form
  const [editCompany, setEditCompany] = useState("view");
  const [editPerson, setEditPerson] = useState("view");
  const [isAdvanceClear, setIsAdvanceClear] = useState(false);
  const pageSize = 100;

  const [isDownloadStatus, setIsDownloadStatus] = useState(false);


  useEffect(() => {
    if (!isEmpty(userSiscolListResponse) || !userSiscoListLoading) {
      try {
        if (isDownloadStatus) {
          let props = {
            base64Data: userSiscolListResponse?.data,
            fileName: userSiscolListResponse?.fileName,
            fileType: userSiscolListResponse?.fileExtension
          }
          saveBase64(props);
        }
      } catch (err) {
        console.log("error: ", err);
      } finally {
        setIsDownloadStatus(false);
      }
    }
  }, [userSiscolListResponse])
  //for getting data when clicked on table row

  // console.log("personresponse", editPerson, editCompany);

  useEffect(() => {
    if (scopeSearch) {
      fetchScopeofWorkListing();
    } else {
      setScopeTags([]);
    }
  }, [scopeSearch]);

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  });

  const fetchScopeofWorkListing = async () => {
    const resp = await scopeOfWorkListing({ searchText: scopeSearch }).catch(
      (err) => {
        message.error("failure");
      }
    );
    if (resp && resp.status === 200) {
      setScopeTags(resp.data);
    }
  };

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  const onChangeStarFilled = (e) => {
    if (e.key == "Enter") {
      setIsFavorite(false);
    }
    // console.log(e,"target")
  };

  useEffect(() => {
    fetchAllSector();
    getQualificationListing();
  }, []);
  useEffect(() => {
    setFormValueReset();
  }, []);

  // useEffect(()=>{
  //   if(qulification || subjectValue){
  //     setAdvanceButtonDisabled(false)
  //   }else{
  //     setAdvanceButtonDisabled(true)
  //   }
  // },[qulification,subjectValue])

  const fetchAllSector = async () => {
    const resp = await sectorListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setSectors(resp.data);
    }
  };

  const getQualificationListing = async () => {
    const resp = await qualificationListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setQualificationData(resp.data);
    }
  };

  // console.log("sectors", sectors);

  // useEffect(() => {
  //   bc.onmessage = (ev) => {
  //     setTest(ev);

  //     dispatch(contactUpdate(ev));
  //   };

  //   bcPerson.onmessage = (ev) => {
  //     console.log(ev);
  //     setTest(ev);
  //     dispatch(contactUpdate(ev));
  //   };

  //   return () => {
  //     bc.close();
  //     // bcPerson.close();
  //   };
  // }, [bc, bcPerson]);

  // function onChange(value) {
  //   console.log("onChange: ", value);
  // }

  // function onAfterChange(value) {
  //   console.log("onAfterChange: ", value);
  // }
  useEffect(() => {
    if (
      !isEmpty(contactCompanyDetailAddResponse) ||
      !isEmpty(contactCompanyUpdateResponse)
    ) {
      let data = {
        ...contactCompanyUpdateResponse,
        status: "company",
      };
      dispatch(contactUpdate(data));
      setEditCompany("view");
    }
  }, [contactCompanyDetailAddResponse, contactCompanyUpdateResponse]);

  useEffect(() => {
    if (contactCompanyDelete) {
      let data = {
        contactCompanyDelete: contactCompanyDelete,
        id: contactCompanyGetDetailsResponse?.id,
        status: "company",
      };
      setEditCompany("view");
      dispatch(contactUpdate(data));
      dispatch(selectedMailTypeAndMail(""));
    }
  }, [contactCompanyDelete]);

  useEffect(() => {
    if (
      !isEmpty(contactPersonAddResponse) ||
      !isEmpty(contactPersonUpdateResponse)
    ) {
      setEditPerson("view");
      let data = {
        ...contactPersonUpdateResponse,
        status: "person",
      };
      dispatch(contactUpdate(data));
    }
  }, [contactPersonAddResponse, contactPersonUpdateResponse]);

  useEffect(() => {
    if (contactPersonDelete) {
      let data = {
        contactPersonDelete: contactPersonDelete,
        id: contactPersonGetDetailsResponse?.id,
        status: "person",
      };
      setEditPerson("view");
      dispatch(contactUpdate(data));
      dispatch(selectedMailTypeAndMail(""));
    }
  }, [contactPersonDelete]);

  const showAddUpdateDrawer = (e) => {
    setAddUpdateDrawerVisibility(true);
    setShowArrow(false);
  };

  const onAddUpdateClose = () => {
    setAddUpdateDrawerVisibility(false);
    setShowArrow(true);
  };

  useEffect(() => {
    if (!isEmpty(applicationConfiguration)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  useEffect(() => {
    if (isEmpty(contactCompanyPaginationResponse?.items)) {
      setContactCompanyId();
      setId();
    }
  }, [contactCompanyPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(selectedMailTypeAndMailData)) {
      // setPersistData(selectedMailTypeAndMailData);

      if (selectedMailTypeAndMailData?.id) {
        sethidden(false);
        if (selectedMailTypeAndMailData?.table === "company") {
          setSearchPersonCompany("");
          setContactPersonId("");
          setContactCompanyId(
            selectedMailTypeAndMailData?.id
              ? selectedMailTypeAndMailData?.id
              : ""
          );
          dispatch(contactCompanyGetDetails(selectedMailTypeAndMailData.id));
        } else if (selectedMailTypeAndMailData?.table === "person") {
          sethidden(true);
          setContactCompanyId("");
          setContactPersonId(
            selectedMailTypeAndMailData?.id
              ? selectedMailTypeAndMailData?.id
              : ""
          );
          dispatch(contactPersonGetDetails(selectedMailTypeAndMailData.id));
        }
      }
      setId(
        selectedMailTypeAndMailData?.id ? selectedMailTypeAndMailData?.id : ""
      );
      setInputSearcHValue(
        selectedMailTypeAndMailData.search
          ? selectedMailTypeAndMailData.search
          : ""
      );
      setSearchKeyword(
        selectedMailTypeAndMailData.search
          ? selectedMailTypeAndMailData.search
          : ""
      );
      setSearchPersonCompany(
        selectedMailTypeAndMailData.search
          ? selectedMailTypeAndMailData.search
          : ""
      );
    }
  }, []);

  useEffect(() => {
    if (!contactPersonPaginationResponse) return;
    setdata(contactPersonPaginationResponse?.items);
    setIsModalVisible(false);
  }, [contactPersonPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(contactCompanyPaginationResponse?.items)) return;
    setCompanyData(contactCompanyPaginationResponse?.items);
  }, [contactCompanyPaginationResponse]);

  const getContactDetails = (
    // MaxResultCount = 50,
    MaxResultCount = pageSize,
    SkipCount = 0,
    IsFavourite_SearchKeyword = isFavorite ? isFavorite : "false",
    loadMore = false
  ) => {
    let appendUrl = `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;
    // console.log(isFavorite,"isFavorite")
    if (isFavorite == true) {
      appendUrl += `&IsFavourite_SearchKeyword=${IsFavourite_SearchKeyword}`;
    } else if (isFavorite == false) {
      appendUrl += undefined;
    }
    dispatch(
      getContactPersonPagination(
        {
          MaxResultCount,
          SkipCount,
          IsFavourite_SearchKeyword,
          // searchKeyword: SearchKeyword,
          searchKeyword: typeof SearchKeyword === "object" ? "" : SearchKeyword,
        },
        loadMore
      )
    );
  };
  // const getNullContactDetail = (
  //   MaxResultCount = 60,
  //   SkipCount = 0,
  //   IsFavourite_SearchKeyword = isFavorite ? isFavorite : "false",
  //   loadMore = false
  // ) => {
  //   let appendUrl =  `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;
  //   // console.log(isFavorite,"isFavorite")

  //   dispatch(
  //     getContactPersonPagination({
  //       MaxResultCount,
  //       SkipCount,
  //       IsFavourite_SearchKeyword,
  //     },
  //     loadMore
  //     )
  //   );
  // }

  // const getNullCompanyDetail = (
  //   MaxResultCount = 10,
  //   SkipCount = 0,
  //   IsFavourite_SearchKeyword = isFavorite ? isFavorite : "false",
  //   loadMore = false
  // ) => {
  //   let appendUrl =  `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;
  //   // console.log(isFavorite,"isFavorite")

  //   dispatch(
  //     getContactCompanyPagination({
  //       MaxResultCount,
  //       SkipCount,
  //       IsFavourite_SearchKeyword,

  //     },
  //     loadMore
  //     )
  //   );
  // }

  const getPersonDetails = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    IsFavourite_SearchKeyword = isFavorite ? isFavorite : "false",
    loadMore = false
  ) => {
    let appendUrl = `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;
    // console.log(isFavorite,"isFavorite")
    if (isFavorite == true) {
      appendUrl += `&IsFavourite_SearchKeyword=${IsFavourite_SearchKeyword}`;
    } else {
    }
    dispatch(
      getContactCompanyPagination(
        {
          MaxResultCount,
          SkipCount,
          IsFavourite_SearchKeyword,
          // searchKeyword: SearchKeyword,
          searchKeyword: typeof SearchKeyword === "object" ? "" : SearchKeyword,
        },
        loadMore
      )
    );
  };
  const search = data
    ?.filter((fav) => fav.isFavorite === true)
    .map((filteredfav) => {
      return filteredfav;
    });

  const searchCompany =
    !isEmpty(contactCompanyPaginationResponse) &&
    contactCompanyPaginationResponse?.items
      ?.filter((fav) => fav.isFavorite === true)
      .map((filteredfav) => {
        return filteredfav;
      });

  useEffect(() => {
    if (contactCompanyGetDetailsResponse) {
      setResponseNotes(contactCompanyGetDetailsResponse);
    }
  }, [contactCompanyGetDetailsResponse]);

  useEffect(() => {
    setNotes(true);
  }, [onClickCompany, onClickPerson]);

  useEffect(() => {
    // getContactDetails();
    // getPersonDetails();
    if (!isAdvanceClear) {
      getContactDetails();
      getPersonDetails();
    }
  }, [SearchKeyword, isFavorite, isAdvanceClear]);

  useEffect(() => {
    // getNullContactDetail();
    // getNullCompanyDetail();
  }, [SearchKeyword, isFavorite]);

  useEffect(() => {
    if (contactPersonGetDetailsResponse) {
      setPersonResponseNotes(contactPersonGetDetailsResponse);
    }
    // if (contactPersonNoteRespone) {
    //   setPersonResponseNotes(contactPersonNoteRespone);
    // }
  }, [contactPersonGetDetailsResponse]);

  const rowEventhandlerscompany = (record) => {
    sethidden(false);
    setEditCompany("view");
    companyForm.resetFields();
    dispatch(contactCompanyGetDetails(record.contactCompanyId));
    setContactCompanyId(record.contactCompanyId);
    setSearchPersonCompany(record.contactCompanyName);
    setId(record.contactCompanyId);
    dispatch(
      selectedMailTypeAndMail({
        id: record.contactCompanyId,
        search: SearchKeyword,
        table: "company",
      })
    );
  };

  const setRowClassName = (record) => {
    return record.contactCompanyId === id ? "clickRowStyl" : "";
  };

  const rowEventhandlersperson = (record) => {
    setEditPerson("view");
    // setSearchPersonCompany("");
    personForm.resetFields();
    sethidden(true);
    dispatch(contactPersonGetDetails(record.contactPersonId));
    setContactPersonId(record.contactPersonId);
    // setSearchPersonCompany(record.contactCompanyName);
    setId(record.contactPersonId);
    dispatch(
      selectedMailTypeAndMail({
        id: record.contactPersonId,
        search: SearchKeyword,
        table: "person",
      })
    );
  };

  const setRowPersonClassName = (record) => {
    return record.contactPersonId === id;
  };

  const showAddUpdateCountryDrawer = (e, record) => {
    setAddUpdatePersonDrawerVisibility(false);
    if (record) {
      dispatch(contactCompanyGetDetails(record.contactCompanyId));
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };

  const showAddUpdatePersonDrawer = (e, record) => {
    setAddUpdateCountryDrawerVisibility(false);
    if (record) {
      dispatch(contactPersonGetDetails(record.contactPersonId));
      setIsUpdatePerson(record);
    } else {
      setIsUpdatePerson(false);
    }
    setAddUpdatePersonDrawerVisibility(true);
  };

  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry("");
    setSearchKeyword("");
    setAddUpdateCountryDrawerVisibility(false);
    form.resetFields();
  };

  const onAddUpdatePersonClose = () => {
    setIsUpdatePerson("");
    setSearchKeyword("");
    setAddUpdatePersonDrawerVisibility(false);
  };

  useEffect(() => {
    if (contactPersonId) {
      dispatch(contactPersonGetDetails(contactPersonId));
    }
    if (contactCompanyId) {
      dispatch(contactCompanyGetDetails(contactCompanyId));
    }
  }, [contactCompanyUpdateResponse, contactPersonUpdateResponse]);

  useEffect(() => {
    if (
      search?.length > 0 &&
      data?.length > 0 &&
      searchCompany?.length > 0 &&
      contactCompanyPaginationResponse?.items?.length > 0
    ) {
      if (
        search.length === data.length &&
        searchCompany.length === contactCompanyPaginationResponse?.items?.length
      ) {
        setStarColor("#ffd036");
      } else {
        setStarColor("#c8c8c8");
      }
    } else {
      setStarColor("#c8c8c8");
    }
  }, [search, data]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    const filterValue = {
      ...values,
      ContactActiveStatus: searchStatusvalue,
      IsAdvancedFilter: true,
      ExperienceFrom: values?.yearsOfExperience?.[0],
      ExperienceTo: values?.yearsOfExperience?.[1],
    };
    // delete filterValue.yearsOfExperience;
    if (
      values?.Email === undefined &&
      values?.Phone === undefined &&
      values?.Qualification === undefined &&
      values?.Website === undefined &&
      values?.majorSubject === undefined &&
      values?.sector === undefined &&
      values?.yearsOfExperience === undefined &&
      values?.CompanyContactName === undefined &&
      values?.scopeOfWork === undefined
    ) {
      message.error("Please Fill the Form");
    } else {
      const personValue = { ...filterValue };
      delete personValue.scopeOfWork;
      dispatch(
        getContactPersonPagination(
          {
            // MaxResultCount: 35,
            MaxResultCount: pageSize,
            SkipCount: 0,
            // ...filterValue,
            ...personValue,
          },
          false
        )
      );
      setIsAdvanceClear(true);
      dispatch(
        getContactCompanyPagination(
          {
            // MaxResultCount: 35,
            MaxResultCount: pageSize,
            SkipCount: 0,
            ...filterValue,
          },
          false
        )
      );
      setSearchKeyword(filterValue);
    }
    // console.log("valuessssss", filterValue);
  };

  const handleExport = () => {
    if (selectedExportValue === 'company') {
      setIsDownloadStatus(true);
      dispatch(actionForExportCompanyUserListToExcel())
    }
    else {
      setIsDownloadStatus(true);
      dispatch(actionForExportSiscoUserListToExcel())
    }
  }


  // const clearAdvanceForm = () => {
  //     formRef.current?.resetFields();
  //   };
  // const clearAdvanceForm = (e) => {
  //   e.preventDefault();
  //   // Do something with the form data here, such as sending it to an API
  //   // Then clear the input fields:
  //   if(formRef.current){
  //     formRef.current.reset();
  //   } else {
  //     console.log("ref is null, check the ref is passed correctly")
  //   }
  // };
  // console.log(search);
  // console.log(' filtered person' + '' + search?.length);
  // console.log(data);
  // console.log('person  response' + '' + data?.length);
  // console.log(searchCompany);
  // console.log('filtered company' + ' ' + searchCompany?.length);

  // console.log('fcompany' + '' + companyData?.length);

  // added for scope of works
  const addScopeofWork = async (value, option) => {
    if (isEmpty(option)) {
      const resp = await scopeofWorkAdd(value).catch((err) => {
        message.error("Failed to add this tag");
      });
    }
  };
  function info() {
    let data = scopeSearch.replace(/\s+/g, " ").trim();
    data = scopeSearch.split(" ");

    data = data.filter((val) => !compareTags.includes(val));
    Modal.confirm({
      title: `Are you sure if you want to add new Scope of Work "${data}"`,
      okText: "Yes",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      cancelText: "No",
      onOk() {
        let val = data.map((list) => ({
          name: list,
        }));

        addScopeofWork(val, "");
        form.setFieldsValue({
          scopeOfWork: !isEmpty(form.getFieldsValue().scopeOfWork)
            ? [...form.getFieldsValue().scopeOfWork.concat(data)]
            : data,
        });
      },
      onCancel() { },
    });
  }

  useEffect(() => {
    if (
      !isEmpty(contactCompanyPaginationResponse?.items) &&
      isEmpty(contactCompanyId) &&
      isEmpty(contactPersonId)
    ) {
      let id = contactCompanyPaginationResponse.items[0].contactCompanyId;
      dispatch(contactCompanyGetDetails(id));
      setContactCompanyId(id);
      setId(id);
    } else {
      setContactCompanyId("");
      setId("");
    }
  }, [contactCompanyPaginationResponse]);

  const resetInputField = () => {
    setSearchKeyword("");
  };
  const menu = (
    <Menu style={{ width: 144, height: 76 }}>
      {/* {!isEmpty(grantedPolices) && grantedPolices['CQNovalFramework.ContactCompany.Create'] && ( */}
      <Menu.Item
        className="sidebars__menu--item"
        onClick={() => {
          setEditCompany("add");
          companyForm.resetFields();
          companyNotesForm.resetFields();
          sethidden(false);
        }}
      >
        <div
          style={{
            display: "flex",
            width: 114,
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "18%" }}>
            <img src={addcompany} />
          </div>
          <div style={{ width: "50%" }}> Company</div>
        </div>
      </Menu.Item>
      {/* )} */}
      {/* {!isEmpty(grantedPolices) && grantedPolices['CQNovalFramework.ContactPerson.Create'] && ( */}
      <Menu.Item className="sidebars__menu--item">
        <>
          <div
            style={{
              display: "flex",
              width: 114,
              justifyContent: "space-around",
            }}
            onClick={() => {
              setEditPerson("add");
              sethidden(true);
              personForm.resetFields();
              expertForm.resetFields();
              personNotesForm.resetFields();
            }}
          >
            <div style={{ width: "18%" }}>
              <img src={addperson} />
            </div>
            <div style={{ width: "50%" }}> Person</div>
          </div>
        </>
      </Menu.Item>
      {/* )} */}
    </Menu>
  );

  return (
    <div className="layout">
      <div className="organizer contacts">
        <div className="contactDetails__components">
          <div
            className="contactDetails__split"
            style={{ display: "flex", width: "100%" }}
          >
            <div className="contactDetails__split--pagination">
              <Row className="events__buttons">
                <Col lg={24}>
                  <div className="ContactDetails__header">
                    <h3 className="header__all">Contact </h3>
                  </div>
                </Col>

                {/* 
							<Col lg={6}>
								<Button className="btn-primary" onClick={showAddUpdateCountryDrawer}>
									<PlusOutlined />
									<span className="button__label">New Company</span>
								</Button>
							</Col>
							<Col lg={6}>
								<Button className="btn-primary" onClick={showAddUpdatePersonDrawer}>
									<PlusOutlined />
									<span className="button__label">New Person</span>
								</Button>
							</Col> */}
              </Row>
              <Modal
                title="Advance Contact Filter"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
                wrapClassName="advance_search_email"
              >
                <Form
                  form={form}
                  labelAlign="left"
                  colon={false}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="row-wrapper">
                    <div
                      className="global-form-flex"
                      style={{ flexFlow: "row wrap" }}
                    >
                      {/* <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Full Name</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item
                            name="fullname"
                          >
                            <Input placeholder="Fullname" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Company Name</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item
                            name="CompanyName"
                          >
                            <Input placeholder="Company" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Phone</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item
                            name="phone"
                          >
                            <Input placeholder="Phone" type="number" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Email</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item
                            name="email"
                          >
                            <Input placeholder="Email" type="email" />
                          </Form.Item>
                        </div>
                      </div> */}
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Person/Company Name</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="CompanyContactName">
                            <Input placeholder="Name" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Scope of Work</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="scopeOfWork">
                            <Select
                              autoComplete="off"
                              placeholder="Scopes of Work"
                              size="default"
                              mode="multiple"
                              onPopupScroll={(e) => {
                                // console.log("duplicated", e);
                              }}
                              notFoundContent={
                                !isEmpty(scopeSearch)
                                  ? isEmpty(scopeTags) &&
                                  `Create ${scopeSearch}`
                                  : null
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === "Tab") {
                                  setScopeTags([]);
                                  setScopeSearch("");
                                  if (
                                    isEmpty(scopeTags) &&
                                    !isEmpty(scopeSearch)
                                  ) {
                                    info();
                                  }
                                }
                              }}
                              onSearch={(e) => {
                                setScopeSearch(e);
                              }}
                              listHeight={100}
                              onClear={() => {
                                setScopeSearch("");
                              }}
                            >
                              {scopeTags?.map((tag) => (
                                <Option value={tag.name} key={tag.id}>
                                  {tag.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Sector</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="sector">
                            <Select
                              showSearch={true}
                              allowClear
                              filterOption={(input, option) =>
                                option
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {sectors.map((data) => (
                                <Option key={data.id} value={data.sectorName}>
                                  {data.sectorName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Qualification</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="Qualification">
                            <Input placeholder="Qualification" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Major Subject</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="majorSubject">
                            <Input placeholder="Major Subject" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Phone Number</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="Phone">
                            <Input placeholder="Phone Number" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Email</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="Email">
                            <Input placeholder="Email" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Website</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="Website">
                            <Input placeholder="Website" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Years of Experience</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="yearsOfExperience">
                            <Slider
                              range
                              step={1}
                              defaultValue={[0, 0]}
                              // onChange={onChange}
                              // onAfterChange={onAfterChange}
                              max="25"
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Status</label>
                        </div>
                        <div className="global-form-input">
                          {/* <Radio.Group onChange={onChange} value={1}> */}
                          <Radio.Group
                            name="ContactActiveStatus"
                            onChange={onContactActiveStatusChange}
                            value={searchStatusvalue}
                          >
                            <Space direction="vertical">
                              <Radio value={-1}>All</Radio>
                              <Radio value={1}>Active</Radio>
                              <Radio value={2}>Inactive</Radio>
                              <Radio value={0}>Unchecked</Radio>
                            </Space>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className="global-form-group calc">
                        <div style={{ color: "#fa541c", paddingTop: ".8em" }}>
                          <label style={{ display: "block" }}>Notes:</label>
                          <span>
                            if you search a*, only letter containing a will be
                            search
                          </span>
                        </div>
                        {/* <div className="global-form-label"></div>
                    <div className="global-form-input"></div> */}
                      </div>

                      <div className="global-form-group calc advance-btn-search-wrapper">
                        <Form.Item>
                          <Button
                            type="ghost"
                            shape="round"
                            autoComplete="off"
                            // onClick={clearAdvanceForm}
                            onClick={() => {
                              form.resetFields();
                              setSearchStatusValue(-1);
                            }}
                          // disabled={advanceButtonDisabled}
                          >
                            Remove
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="ghost "
                            shape="round"
                            htmlType="submit"
                            loading={contactPersonPaginationLoading}
                            autoComplete="off"
                          // disabled={advanceButtonDisabled}
                          //  onClick={() => {
                          //   form.resetFields();
                          // }}
                          >
                            Search
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>


                {/* <Tabs defaultActiveKey="1" onChange={key => console.log(key)} className="custom-tab-model">
                  <TabPane tab="Search" key="1">
                  </TabPane>
                  <TabPane tab="Export" key="2">
                    <div className="global-form-group calc" style={{ marginTop: ".8em" }}>
                      <div className="global-form-label">
                        <Button type="dashed" onClick={handleExport}>Export Template</Button>
                      </div>
                      <div className="global-form-input">
                        <Select
                          autoComplete="off"
                          placeholder="Scopes of Work"
                          size="default"
                          value={selectedExportValue}
                          onChange={(value) => setSelectedExportValue(value)}
                          options={[
                            {
                              value: 'company',
                              label: 'Company',
                            },
                            {
                              value: 'person',
                              label: 'Person',
                            }]}
                        ></Select>
                      </div>
                    </div>

                    <UniversalFileUpload
                      name='File'
                      form={fileForm}
                      isMultipleFileAllowed={false}
                      id='1'
                      callback={(e) => {
                        let olduploadedFileDtos = fileForm.getFieldValue()[
                          "attendanceDtos"
                        ]
                          ? [...fileForm.getFieldValue()["attendanceDtos"]]
                          : [];
                        let uploadedFileDtos = [...olduploadedFileDtos];
                        uploadedFileDtos.push(e);

                        form.setFieldsValue({
                          attendanceDtos: uploadedFileDtos,
                        });

                      }}
                    />
                  </TabPane>
                </Tabs> */}
              </Modal>
              <div className="search-wrapper">
                <StarFilled
                  style={{ color: isFavorite ? "#ffd036" : "#c8c8c8" }}
                  onClick={() => {
                    setIsFavorite(!isFavorite);
                  }}
                />
                {/* <Input
                  className="form__groups"
                  placeholder="Search"
                  size="medium"
                  type="text"
                  allowClear
                  onPressEnter={(e) => {
                    setEditCompany("");
                    setEditPerson("");
                    setSearchKeyword(e);
                    setSearchPersonCompany(e);
                    setContactCompanyId("");
                    setId("");
                    dispatch(
                      selectedMailTypeAndMail({
                        id: selectedMailTypeAndMailData?.id
                          ? selectedMailTypeAndMailData?.id
                          : "",
                        search: e.target.value,
                        table: "",
                      })
                    );
                  }}
                  onChange={(e) => {
                    setInputSearcHValue(e.target.value);
                    if (!e.target.value) {
                      dispatch(
                        selectedMailTypeAndMail({
                          id: selectedMailTypeAndMailData?.id
                            ? selectedMailTypeAndMailData?.id
                            : "",
                          search: e.target.value,
                          table: "",
                        })
                      );
                    }
                  }}
                  onClick={() => {
                    setshowAll(true);
                  }}
                  defaultValue={inputSearchValue}
                  value={inputSearchValue}
                  autoComplete="off"
                  // }}
                  // onClcik={(e) => setSearchKeyword(e.target.value)}
                  // value={SearchKeyword}
                />  */}
                {/* <svg xmlns="" width="24" 
                height="24" viewBox="0 0 24 24"
                onClick={showModal}>
                  <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z">
                    </path>
                    </svg> */}
                {isAdvanceClear === true ? (
                  <>
                    <Input.Search
                      className="form__group_email_search"
                      style={{ margin: 0 }}
                      // allowClear
                      size="medium"
                      bordered={false}
                      value={"[Advance Search]"}
                    />
                    <button
                      onClick={() => {
                        resetInputField();
                        setIsAdvanceClear(false);
                        setIsFavorite(true);
                        //  getNullContactDetail();
                        //  getNullCompanyDetail();
                        form.resetFields();
                      }}
                      className="cross-section-advance-filter"
                    >
                      &#10006;
                    </button>{" "}
                  </>
                ) : (
                  <Input.Search
                    className="form__group"
                    style={{ margin: 0 }}
                    placeholder="Search"
                    size="medium"
                    allowClear
                    onSearch={(e) => {
                      setSearchKeyword(e);
                      setIsFavorite(!isFavorite);
                    }}
                    onKeyDown={onChangeStarFilled}
                    onChange={(e) => {
                      setInputSearcHValue(e.target.value);
                      // if (!e.target.value) {
                      //   dispatch(
                      //     selectedMailTypeAndMail({
                      //       id: selectedMailTypeAndMailData?.id
                      //         ? selectedMailTypeAndMailData?.id
                      //         : "",
                      //       search: e.target.value,
                      //       table: "",
                      //     })
                      //   );
                      // }
                    }}
                    autoComplete="off"
                  />
                )}

                <div
                  className="side__search__wrapper side-contact-search-filter"
                  style={{ cursor: "pointer" }}
                  //  onClick={() => {
                  //    setUnRead(!read);
                  //    setSelectTopEmail(true);
                  //    setCurrentSelectedMailMessage("");
                  //  }}
                  onClick={showModal}
                >
                  {/* { <Tooltip placement="topLeft" title="contact filter"> } */}
                  <FilterOutlined />
                  {/* </Tooltip> */}
                </div>
                {/* <button
                  onClick={() => {
                    resetInputField();
                    setIsAdvanceClear(false);
                  }}
                ></button> */}
                {/* <Col span={4} style={{:"none"}}>
										<div className="contactdetails__showall" >
											<span>Show All</span>
										</div>
									</Col> */}
                {/* {!isEmpty(grantedPolices) && (grantedPolices['CQNovalFramework.ContactCompany.Create'] || grantedPolices['CQNovalFramework.ContactPerson.Create']) && ( */}
                <Dropdown
                  overlay={menu}
                  arrow
                  trigger={["click"]}
                  // arrow
                  placement="bottomRight"
                >
                  <div className="contactDetails__dropdown">
                    <Button className="contact__header__btn-primary">
                      <PlusOutlined />
                    </Button>
                  </div>
                </Dropdown>
                {/* )} */}
              </div>
              <>
                <div style={{ height: "43%" }}>
                  <AddUpdateCompanyTable
                    searchKeyword={SearchKeyword}
                    showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
                    showAddUpdatePersonDrawer={showAddUpdatePersonDrawer}
                    rowEventhandlers={rowEventhandlerscompany}
                    setRowClassName={setRowClassName}
                    contactCompanyGetDetailsResponse={
                      contactCompanyGetDetailsResponse
                    }
                    contactCompanyGetDetailsLoading={
                      contactCompanyGetDetailsLoading
                    }
                    selectedMailTypeAndMailData={selectedMailTypeAndMailData}
                    setOnClickCompany={setOnClickCompany}
                    onClickCompany={onClickCompany}
                    pageSize={pageSize}
                  />
                </div>
              </>
              <>
                {/* <div style={{ height: "100%" }}> */}
                <div style={{ height: "57%" }}>
                  <AddUpdatePersonTable
                    searchKeyword={SearchKeyword}
                    rowEventhandlers={rowEventhandlersperson}
                    showAddUpdatePersonDrawer={showAddUpdatePersonDrawer}
                    setRowPersonClassName={setRowPersonClassName}
                    searchPersonCompany={searchPersonCompany}
                    contactPersonGetDetailsResponse={
                      contactPersonGetDetailsResponse
                    }
                    contactPersonGetDetailsLoading={
                      contactPersonGetDetailsLoading
                    }
                    contactCompanyId={contactCompanyId}
                    selectedMailTypeAndMailData={selectedMailTypeAndMailData}
                    setOnClickPerson={setOnClickPerson}
                    onClickPerson={onClickPerson}
                    isFavorite={isFavorite}
                    pageSize={pageSize}
                  />
                </div>
                {/* : <Skeleton /> */}
              </>
            </div>
            <div
              className="contactDetails__split--right conatct-right-side"
              style={{ display: "flex" }}
            >
              <div
                className="contact__details__render compant-person-render"
                style={{ padddingLeft: 25 }}
              >
                {show ? (
                  !contactPersonGetDetailsLoading &&
                    !isEmpty(contactPersonGetDetailsResponse && editPerson) ? (
                    editPerson === "view" ? (
                      <Showdataformperson
                        setEditPerson={setEditPerson}
                        showAddUpdatePersonDrawer={showAddUpdatePersonDrawer}
                        SearchKeyword={SearchKeyword}
                        grantedPolices={grantedPolices}
                        data={data}
                      />
                    ) : (
                      <AddPersonDrawer
                        setEditPerson={setEditPerson}
                        editPerson={editPerson}
                        form={personForm}
                        form1={expertForm}
                      />
                    )
                  ) : editPerson === "add" ? (
                    <AddPersonDrawer
                      setEditPerson={setEditPerson}
                      editPerson={editPerson}
                      form={personForm}
                      form1={expertForm}
                    />
                  ) : (
                    <div style={{ padddingLeft: 25 }}>
                      <Skeleton
                        avatar={{ size: 96, shape: "square" }}
                        paragraph={{ rows: 10 }}
                      />
                    </div>
                  )
                ) : !contactCompanyGetDetailsLoading &&
                  !isEmpty(contactCompanyGetDetailsResponse && editCompany) ? (
                  editCompany === "edit" || editCompany === "add" ? (
                    <AddCompanyDrawer
                      setEditCompany={setEditCompany}
                      editCompany={editCompany}
                      form={companyForm}
                    />
                  ) : (
                    <div style={{ padddingLeft: 25 }}>
                      <Showdataform
                        setEditCompany={setEditCompany}
                        showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
                        SearchKeyword={SearchKeyword}
                        grantedPolices={grantedPolices}
                        data={data}
                        companyId={contactCompanyGetDetailsResponse?.id}
                      />
                    </div>
                  )
                ) : editCompany === "add" ? (
                  <AddCompanyDrawer
                    setEditCompany={setEditCompany}
                    editCompany={editCompany}
                    form={companyForm}
                  />
                ) : (
                  <div style={{ padddingLeft: 25 }}>
                    <Skeleton
                      avatar={{ size: 96, shape: "square" }}
                      paragraph={{ rows: 10 }}
                    />
                  </div>
                )}
              </div>

              {show ? (
                contactPersonId ? (
                  editPerson === "view" && (
                    <div className="contact__editnotes">
                      <PersonEditNotes
                        personResponseNotes={personResponseNotes}
                        showArrow={showArrow}
                        drawerVisibility={addUpdateDrawerVisibility}
                        showAddUpdateDrawer={showAddUpdateDrawer}
                        onAddUpdateClose={onAddUpdateClose}
                        notes={notes}
                        setNotes={setNotes}
                      />
                    </div>
                  )
                ) : (
                  <div style={{ padddingLeft: 25 }}>
                    <Skeleton paragraph={{ rows: 1 }} />
                  </div>
                )
              ) : contactCompanyId ? (
                editCompany === "view" && (
                  <div className="contact__editnotes">
                    <div className="editnotes-wrapper">
                      <EditNotes
                        drawerVisibility={addUpdateDrawerVisibility}
                        showArrow={showArrow}
                        showAddUpdateDrawer={showAddUpdateDrawer}
                        responseNotes={responseNotes}
                        onAddUpdateClose={onAddUpdateClose}
                        windowWidth={windowWidth}
                        editCompany={editCompany}
                        form={companyNotesForm}
                        notes={notes}
                        setNotes={setNotes}
                      />
                    </div>
                  </div>
                )
              ) : (
                <div style={{ padddingLeft: 25 }}>
                  <Skeleton paragraph={{ rows: 1 }} />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <Row gutter={32} style={{ height: '98vh', overflow: 'hidden' }}>
					<Col lg={12} className="organizer__divider">
					</Col>

					<Col lg={12}>
						
					</Col>
				</Row> */}
      </div>
    </div>
  );
};

export default ContactCompanyPerson;
