import Axios from 'axios';
import { accessTokenKey } from './config';


const setAuthToken = (token) => {
	
	if (token) {
		Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
		sessionStorage.setItem(accessTokenKey, token);
	} else {
		delete Axios.defaults.headers.common['Authorization'];
		sessionStorage.removeItem(accessTokenKey);
	}
}

export default setAuthToken;
