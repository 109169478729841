import React, { useEffect } from "react";
import moment from 'moment';
import { isEmpty } from "lodash";

// antd
import {
  Button,
  Drawer,
  Layout,
  Input,
  Space,
  Form,
  Select,
} from "antd";

// redux
import { useDispatch } from "react-redux";
import { addBulkHolidayEvents } from "../../../services/redux/organizer/organizer.action";

const { Header } = Layout;
const { Option } = Select;

export const BulkFormDrawer = ({ name,visible, onClose, selectedEditHolidayEvent, eventFormType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = values => {
    dispatch(addBulkHolidayEvents([...values.holidayEvents]));
  };

  useEffect(() => {
    if (!isEmpty(selectedEditHolidayEvent)) {
      let holidayEvents = selectedEditHolidayEvent.map((holiday) => (
        {
          id: holiday.id || holiday.key,
          title: holiday.title,
          date: moment(holiday.date),
          remarks: holiday.remarks,
          isActive: holiday.isActive
        }
      ));

      form.setFieldsValue({
        holidayEvents
      })
    } else {
      form.setFieldsValue({
        holidayEvents: [{
          title: '',
          date: '',
          remarks: '',
          isActive: true
        }]
      })
    }
  }, [selectedEditHolidayEvent])

  return (
    <Drawer
      visible={visible}
      width={472}
      className="drawer"
      onClose={onClose}>
        
      <Header className='drawer__header'>
        <h4 className='drawer__header--title'>
          {name}
          </h4>
      </Header>

      <div className='drawer__content'>
        <div className='drawer__content--wrapper'>
          <div className='contact__content__wrapper'>
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Form.List name="holidayEvents">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(field => {
                      return (
                        <Space key={field.key} style={{ display: 'block', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...field}
                            label="Event Title"
                            className="form__group"
                            name={[field.name, 'title']}
                            fieldKey={[field.fieldKey, 'title']}
                            rules={[{ required: true, message: 'Missing title' }]}
                          >
                            <Input placeholder="Enter Title" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label="Hexcode"
                            className="form__group"
                            name={[field.name, 'hexcode']}
                            fieldKey={[field.fieldKey, 'hexcode']}
                            rules={[{ required: true, message: 'Missing HexCode' }]}
                          >
                            <Input placeholder="Enter Hexcode" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label="Parent Event Type"
                            className="form__group"
                            name={[field.name, 'ParentEventType']}
                            fieldKey={[field.fieldKey, 'ParentEventType']}
                            rules={[{ required: true, message: 'Missing Parent Event Type' }]}
                            >
                            <Select placeholder='Parent Event Type'>
                                <Option key={1}>Parent Event Type</Option>
                            </Select>
                        </Form.Item>
                        </Space>
                      )
                    })}
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      {/* <Row gutter={[16, 16]} className='contact__bottom__items'>
        <Col span={12}>
          <div className='contact__bottom__buttons' style={{ float: "left" }}>
            <Button>Cancel</Button>
          </div>
        </Col>

        <Col span={12}>
          <div className='contact__bottom__buttons'>
            <Button className='contact__bottom__buttons__style'>
              Save Changes
            </Button>
          </div>
        </Col>
      </Row> */}
    </Drawer>
  );
};

