import { GROUPCHAT_TYPES } from '../../types';
import { init, success, finish, error } from 'services/common';
import { message } from 'antd';
import { GroupChatService } from "./api"

const groupChatService = new GroupChatService();

export const actionForGroupChatTitleAdd = (body) => {
    return async(dispatch) => {
        dispatch(init(GROUPCHAT_TYPES.CREATE_GROUPCHAT_TITLE));
        // const resp = await groupChatService.apiForGroupChatTitleAdd(body);
        // if(resp.isSuccess) {
        //     dispatch(success(GROUPCHAT_TYPES.CREATE_GROUPCHAT_TITLE, resp.data))
        //     message.success("Newly added Group Chat")
        // } else if(!resp.isSuccess) {
        //     dispatch(error(resp.errorMessage))
        // }
        // dispatch(finish(GROUPCHAT_TYPES.CREATE_GROUPCHAT_TITLE));
        try {
            const resp = await groupChatService.apiForGroupChatTitleAdd(body);
            if(resp.isSuccess) {
                dispatch(success(GROUPCHAT_TYPES.CREATE_GROUPCHAT_TITLE, resp.data));
                message.success("Newly added Group Chat");
                return resp.data;
            } else {
                dispatch(error(resp.errorMessage));
                throw new Error(resp.errorMessage);
            }
        } catch (err) {
            dispatch(error(err.message || 'Unknown error occurred'));
            throw err;
        } finally {
            dispatch(finish(GROUPCHAT_TYPES.CREATE_GROUPCHAT_TITLE));
        }
    }
}

export const actionForGroupChatTitleUpdate = (id, body) => {
    return async(dispatch) => {
        dispatch(init(GROUPCHAT_TYPES.UPDATE_GROUPCHAT_TITLE));
        const resp = await groupChatService.apiForGroupChatTitleUpdate(id, body);
        if(resp.isSuccess) {
            dispatch(success(GROUPCHAT_TYPES.UPDATE_GROUPCHAT_TITLE, resp.data));
            // console.log("ACTION: resp.data ", resp.data)
            message.success("Updated");
            return resp.data;
        } else if(!resp.isSuccess) {
            dispatch(error(resp.errorMessage))
        }
        dispatch(finish(GROUPCHAT_TYPES.UPDATE_GROUPCHAT_TITLE));
    }
}

export const actionForGroupChatTitlePagination = (query, loadMore) => {
    return async(dispatch) => {
        dispatch(init(GROUPCHAT_TYPES.GET_GROUPCHAT_TITLE_PAGINATION));
        const resp = await groupChatService.apiForGroupChatTitlePagination(query);
        dispatch(finish(GROUPCHAT_TYPES.GET_GROUPCHAT_TITLE_PAGINATION));
        if(resp.isSuccess) {
            dispatch(success(GROUPCHAT_TYPES.GET_GROUPCHAT_TITLE_PAGINATION, resp.data));
            if (loadMore) {
                // console.log("LOAD More Called");
                dispatch(
                    success(GROUPCHAT_TYPES.GET_GROUPCHAT_TITLE_PAGINATION_MORE, resp.data)
                );
            };
        } else if(!resp.isSuccess) {
            dispatch(error(resp.errorMessage))
        }
        
    }
} 

export const actionForGetChatTitleDetails = (q) => {
    return async(dispatch) => {
        dispatch(init(GROUPCHAT_TYPES.GET_GROUPCHAT_DETAIL));
        const resp = await groupChatService.apiForGroupChatTitleDetails(q);
        if(resp.isSuccess) {
            dispatch(success(GROUPCHAT_TYPES.GET_GROUPCHAT_DETAIL, resp.data));
        } else if(!resp.isSuccess) {
            dispatch(error(resp.errorMessage));
        }
    }
}

// message

export const actionForGetMessagePagination = (q) => {
    return async(dispatch) => {
        dispatch(init(GROUPCHAT_TYPES.GET_GROUPCHAT_MESSAGE));
        const resp = await groupChatService.apiForGroupMessagePagination(q);
        if(resp.isSuccess) {
            dispatch(success(GROUPCHAT_TYPES.GET_GROUPCHAT_MESSAGE, resp.data));
        } else if(!resp.isSuccess) {
            dispatch(error(resp.errorMessage));
        }
    }
}


export const actionForGroupMessageAdd = (body) => {
    return async(dispatch) => {
        dispatch(init(GROUPCHAT_TYPES.ADD_GROUPCHAT_MESSAGE));
        const resp = await groupChatService.apiForGroupMessageAdd(body);
        if(resp.isSuccess) {
            dispatch(success(GROUPCHAT_TYPES.ADD_GROUPCHAT_MESSAGE, resp.data));
            message.success("Message Added");
            return resp.data;
        } else if(!resp.isSuccess) {
            dispatch(error(resp.errorMessage))
        }
        dispatch(finish(GROUPCHAT_TYPES.ADD_GROUPCHAT_MESSAGE));
    }
}



export const actionForGroupChatMailRead = (id, q, appUserId, mailId) => {
    return async(dispatch) => {
        dispatch(init(GROUPCHAT_TYPES.UPDATE_GROUPCHAT_READ));
        const resp = await groupChatService.apiForGroupChatMailRead(id, q);
        if(resp.isSuccess) {
            dispatch(
                // success(
                //     GROUPCHAT_TYPES.UPDATE_GROUPCHAT_READ,
                //     resp.data,
                // )
                success(
                    GROUPCHAT_TYPES.UPDATE_GROUPCHAT_READ,
                    {
                        data: resp.data,
                        appUserId: appUserId,
                        mailId: mailId,
                        read: q.isGroupChatRead,
                    }
                )
            );
            message.success("Updated");
            return resp.data;
        } else if(!resp.isSuccess) {
            dispatch(error(resp.errorMessage))
        }
        dispatch(finish(GROUPCHAT_TYPES.UPDATE_GROUPCHAT_READ));
    }
}


// count mail

export const actionForCountGroupMail = (q) => {
    return async(dispatch) => {
        dispatch(init(GROUPCHAT_TYPES.GET_COUNT_GROUP_MAIL));
        const response = await groupChatService.apiForCountGroupMail(q);
        if(response.isSuccess) {
            dispatch(success( GROUPCHAT_TYPES.GET_COUNT_GROUP_MAIL, response.data));
            return response.data;
        } else if(!response.isSuccess) {
            dispatch(error(response.errorMessage))
        }
        dispatch(finish(GROUPCHAT_TYPES.GET_COUNT_GROUP_MAIL));
    }
}