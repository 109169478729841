import { message } from "antd";
import { LoanApplicationService } from "./api";
import { init, success, finish, error } from "services/common";
import { LOAN_APPLICATION_TYPES, PROCUREMENT_TYPES } from "../../../types";
import {
  getProcurementCounter,
  getProcurementSummary,
} from "../procurement/action";
import { isEmpty } from "lodash";

const loanApplicationService = new LoanApplicationService();

export const getLoanApplicationPagination = (query, type) => {
  if (type === "Approval") {
    return async (dispatch) => {
      dispatch(
        init(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_APPROVAL_PAGINATION)
      );
      const response =
        await loanApplicationService.getLoanApplicationPagination(query);
      dispatch(
        finish(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_APPROVAL_PAGINATION)
      );
      if (response.isSuccess) {
        dispatch(
          success(
            LOAN_APPLICATION_TYPES.LOAN_APPLICATION_APPROVAL_PAGINATION,
            response.data
          )
        );
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
      }
    };
  } else if (type === "NoApproval") {
    return async (dispatch) => {
      dispatch(init(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_PAGINATION));
      const response =
        await loanApplicationService.getLoanApplicationPagination(query);
      dispatch(finish(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_PAGINATION));
      if (response.isSuccess) {
        dispatch(
          success(
            LOAN_APPLICATION_TYPES.LOAN_APPLICATION_PAGINATION,
            response.data
          )
        );
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
      }
    };
  }
};

export const addLoanApplication = (body) => {
  return async (dispatch) => {
    dispatch(init(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_ADD));
    const response = await loanApplicationService.addLoanApplication(body);
    if (response.isSuccess) {
      dispatch(
        success(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_ADD, response.data)
      );
      message.success("Loan Application applied successfully");
      dispatch(getLoanApplicationPagination(`maxResultCount=10&skipCount=0`));
      dispatch(finish(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_ADD));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateLoanApplication = (body, id) => {
  return async (dispatch) => {
    dispatch(init(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE));
    const response = await loanApplicationService.updateLoanApplication(
      body,
      id
    );
    dispatch(finish(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE));
    if (response.isSuccess) {
      dispatch(
        success(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE, response.data)
      );
      dispatch(getLoanApplicationPagination(`maxResultCount=10&skipCount=0`));
      message.success("Loan Application updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getLoanApplicationDataGetDetails = (id, isApproval) => {
  return async (dispatch) => {
    dispatch(init(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_DATA_DETAILS));
    const response =
      await loanApplicationService.getLoanApplicationDataGetDetails(id);
    dispatch(finish(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_DATA_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(
          LOAN_APPLICATION_TYPES.LOAN_APPLICATION_DATA_DETAILS,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const loanApplicationUpdateSupervisor = (
  body,
  id,
  isApproval,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_SUPERVISOR));
    const response = await loanApplicationService.updateLoanSupervisor(
      body,
      id
    );
    dispatch(finish(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_SUPERVISOR));
    if (response?.isSuccess) {
      dispatch(
        success(
          LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_SUPERVISOR,
          response?.data
        )
      );
      if (isApproval) {
        message.success("Loan approved from Supervisor ");
      } else {
        message.success("Rejected from Supervisor ");
      }
      setInstanceMessageCenterListing?.((prevData) => {
        if (!isEmpty(prevData.items)) {
          return {
            items: [response?.data?.internalMessage, ...prevData.items],
            totalCount: prevData.totalCount + 1,
          };
        } else {
          return {
            items: [response?.internalMessage],
            totalCount: 1,
          };
        }
      });
      dispatch(getProcurementSummary("loan"));
      dispatch(getProcurementCounter());
    } else {
      dispatch(error(response?.errorMessage));
      message.error("There is error here");
    }
  };
};

export const loanApplicationUpdateAccountant = (
  body,
  id,
  isApproval,
  // requisitionModal,
  // setRequisitionModal,
  // getProcurementAction,
  // getProcurementCounter,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    // console.log("requisitionModal", requisitionModal);
    dispatch(init(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_ACCOUNTANT));
    const response = await loanApplicationService.updateLoanAccountant(
      body,
      id
    );
    dispatch(finish(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_ACCOUNTANT));
    if (response?.isSuccess) {
      dispatch(
        success(
          LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_ACCOUNTANT,
          response?.data
        )
      );
      if (isApproval) {
        message.success("Loan approved from accountant");
      } else {
        message.success("Loan rejected from Accountant");
      }
      setInstanceMessageCenterListing((prevData) => {
        if (!isEmpty(prevData.items)) {
          return {
            items: [response?.data?.internalMessage, ...prevData.items],
            totalCount: prevData.totalCount + 1,
          };
        } else {
          return {
            items: [response?.data?.internalMessage],
            totalCount: 1,
          };
        }
      });
      dispatch(getProcurementSummary("loan"));
      dispatch(getProcurementCounter());
    } else {
      dispatch(error(response?.errorMessage));
      message.error("There is error here");
    }
  };
};

export const loanApplicationUpdateManagement = (
  body,
  id,
  isApproval,
  // requisitionModal,
  // setRequisitionModal,
  // getProcurementAction,
  // getProcurementCounter,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_MANAGEMENT));
    const response = await loanApplicationService.updateLoanManagement(
      body,
      id
    );
    dispatch(finish(LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_MANAGEMENT));
    if (response?.isSuccess) {
      dispatch(
        success(
          LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_MANAGEMENT,
          response?.data
        )
      );
      if (isApproval) {
        message.success("Loan is approved ");
      } else {
        message.success("Loan is rejected ");
      }
      setInstanceMessageCenterListing?.((prevData) => {
        if (!isEmpty(prevData.items)) {
          return {
            items: [response?.data?.internalMessage, ...prevData.items],
            totalCount: prevData.totalCount + 1,
          };
        } else {
          return {
            items: [response?.data?.internalMessage],
            totalCount: 1,
          };
        }
      });
      dispatch(getProcurementSummary("loan"));
      dispatch(getProcurementCounter());
    } else {
      dispatch(error(response?.errorMessage));
      message.error("This loan has error");
    }
  };
};
