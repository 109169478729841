import { Button, Layout, Space, Table } from "antd";
import { Input } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailShareListing } from "services/redux/admin/EmailShare/action";
import EmailShareDrawer from "./EmailShareDrawer";
import PermissionShareDrawer from "./PermissionShareDrawer";

export default function EmailShareListing({ type }) {
  const { Header, Footer } = Layout;
  const { Search } = Input;
  const dispatch = useDispatch();
  const { emailShareListingResponse } = useSelector(
    (state) => state.emailShare
  );

  const [emailShareData, setEmailShareData] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openNewDrawer, setOpenNewDrawer] = useState(false);
  const [permissionData, setPermissionData] = useState("");
  const [newPermissionData, setNewPermissionData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const columns = [
    {
      title: "S.NO",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Email Address",
      key: "emailAddress",
      dataIndex: "emailAddress",
      width: "40%",
    },
    {
      title: "Action1 ",
      render: (record) => (
        <Space size="middle">
          <div className="line-spacing-role">
            <a
              role="button"
              onClick={() => {
                setPermissionData(record);
              }}
            >
              Edit Details{" "}
            </a>
          </div>
          <a
            className="action-btn action-btn-add"
            onClick={() => {
              setNewPermissionData(record);
            }}
          >
            Edit Permission{" "}
          </a>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    setSearchKeyword([]);
  }, []);

  useEffect(() => {
    if (type === "email") {
      dispatch(emailShareListing());
      setSearchKeyword([]);
      setSearchValue();
    } else {
      dispatch(emailShareListing("corporate"));
      setSearchKeyword([]);
      setSearchValue();
    }
  }, [type, dispatch]);

  useEffect(() => {
    if (!isEmpty(emailShareListingResponse)) {
      setEmailShareData(emailShareListingResponse?.items);
    }
  }, [emailShareListingResponse]);

  useEffect(() => {
    if (!isEmpty(permissionData)) {
      setOpenDrawer(true);
    }
  }, [permissionData]);

  useEffect(() => {
    if (!isEmpty(newPermissionData)) {
      setOpenNewDrawer(true);
    }
  }, [newPermissionData]);

  const [data, setdata] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, [searchKeyword]);

  useEffect(() => {
    if (!emailShareListingResponse) return;
    setdata(emailShareListingResponse.items);
    setTotalCount(emailShareListingResponse.totalCount);
  }, [emailShareListingResponse]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType,
    SearchKeyword = searchKeyword
  ) => {
    dispatch(
      emailShareListing(type, {
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
        SearchKeyword,
      })
    );
    // setSearchKeyword();
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };
  const onSearch = (value) => {
    setSearchKeyword(value);
  };
  return (
    <div>
      <Header
        className="drawer__header"
        style={{ display: "grid", gridTemplateColumns: "9fr 2fr 1.5fr" }}
      >
        <h4 className="drawer__header--title">
          {type === "corporate" ? "Corporate Email" : "Email List"}
        </h4>

        <Search
          placeholder="Search by mail"
          className="custom-search"
          onSearch={onSearch}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          // inputStyle={{ fontSize: "26px" }}
          style={{
            width: 200,
            height: 32,
            lineHeight: 32,
            fontSize: 20,
          }}
          buttonText="Custom"
          buttonProps={{ style: { backgroundColor: "red", color: "white" } }}
          enterButton
        />
        <Button
          onClick={() => {
            setOpenDrawer(true);
          }}
        >
          Add Email
        </Button>
      </Header>

      <EmailShareDrawer
        type={type}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        openNewDrawer={openNewDrawer}
        setOpenNewDrawer={setOpenNewDrawer}
        permissionData={permissionData}
        setPermissionData={setPermissionData}
        newPermissionData={newPermissionData}
        setNewPermissionData={setNewPermissionData}
      />

      <PermissionShareDrawer
        type={type}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        openNewDrawer={openNewDrawer}
        setOpenNewDrawer={setOpenNewDrawer}
        permissionData={permissionData}
        setPermissionData={setPermissionData}
        newPermissionData={newPermissionData}
        setNewPermissionData={setNewPermissionData}
      />

      <Table
        className="contact-table"
        columns={columns}
        onChange={handleTableChange}
        dataSource={emailShareData}
        pagination={{ total: totalCount, pageSize: pageSize }}
      />
    </div>
  );
}
