import { Modal, Table, Typography, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getdashboardIncomeResponse,
    getdashboardLedgerResponse,
    getdashboardCashFlowResponse,
    getdashboardBudgetExpenseResponse
} from "services/redux/admin/FiscalYear/action";

const OneCommonModal = ({ modalVisible, setModalVisible, modalData, type }) => {
    const dispatch = useDispatch();

    const {
        accountIncomeResponse,
        accountIncomeLoading,
        accountLedgerResponse,
        accountLedgerLoading,
        accountCashFlowResponse,
        accountCashFlowLoading,
        accountBudgetExpenseResponse,
        accountBudgetExpenseLoading
    } = useSelector(
        (state) => state.fiscalYearState
    );

    useEffect(() => {
        const body = {
            theBranchis: modalData.query,
            dTypeIncome: modalData.dType,
        };

        if (type == 'odbalance') {
            let dlbody = {
                theBranchis: modalData.query,
                dTypeIncome: modalData.dType,
                dExtaQuery: modalData?.dExtaQuery,
            }
            dispatch(getdashboardLedgerResponse(dlbody));
            // console.log("dlbody: ", dlbody, modalData)
        }

        else
            dispatch(getdashboardIncomeResponse(body));
    }, []);

    const showNumber = (number) => {
        if (number === 0) {
            return `--`;
        } else if (number < 0) {
            return (
                <Typography.Text style={{ color: "red" }}>
                    ({Math.abs(Math.trunc(number))?.toLocaleString()} )
                </Typography.Text>
            );
        } else {
            return (
                <Typography.Text>
                    {Math.trunc(number)?.toLocaleString()}
                </Typography.Text>
            );
        }
    };

    const commonColumns = [
        {
            title: "S.N",
            key: "sn",
            dataIndex: "sn",
            render: (_, record, index) => index + 1,
        },
    ];

    const dlReportColumns = [
        {
            title: "Income Head",
            dataIndex: "accountHead",
            key: "accountHead",
            render: (_, record) => {
                return (
                    <Typography.Text
                        style={
                            record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
                        }
                    >
                        {record?.accountHead}
                    </Typography.Text>
                );
            },
        },
        {
            title: (
                <Typography.Paragraph style={{ textAlign: "right" }}>
                    cr Amount
                </Typography.Paragraph>
            ),
            key: "crAmount",
            align: "right",
            dataIndex: "crAmount",
            render: (_, record) => {
                return (
                    <Typography.Paragraph
                        style={
                            record?.incomeHead === "Total"
                                ? { fontWeight: "bold", textAlign: "right" }
                                : { textAlign: "right" }
                        }
                    >
                        {showNumber(record?.crAmount)}
                    </Typography.Paragraph>
                );
            },
        },
        {
            title: (
                <Typography.Paragraph style={{ textAlign: "right" }}>
                    dr Amount
                </Typography.Paragraph>
            ),
            key: "drAmount",
            align: "right",
            dataIndex: "drAmount",
            render: (_, record) => {
                return (
                    <Typography.Paragraph
                        style={
                            record?.incomeHead === "Total"
                                ? { fontWeight: "bold", textAlign: "right" }
                                : { textAlign: "right" }
                        }
                    >
                        {showNumber(record?.drAmount)}
                    </Typography.Paragraph>
                );
            },
        },
        {
            title: (
                <Typography.Paragraph style={{ textAlign: "right" }}>
                    Description
                </Typography.Paragraph>
            ),
            key: "description",
            dataIndex: "description",
            render: (_, record) => {
                return (
                    <Typography.Paragraph style={{ textAlign: "center" }}>
                        {record?.description}
                    </Typography.Paragraph>
                );
            },
        },
        {
            title: (
                <Typography.Paragraph style={{ textAlign: "center" }}>
                    Date
                </Typography.Paragraph>
            ),
            key: "date",
            dataIndex: "date",
            render: (_, record) => {
                return (
                    <Typography.Paragraph style={{ textAlign: "center" }}>
                        {record?.date ? moment(record?.date)?.format("YYYY MMM DD") : null}
                    </Typography.Paragraph>
                );
            },
        },
    ]

    // DL Report
    const dlSerialNumber = accountLedgerResponse && accountLedgerResponse?.reduce(
        (acc, curr) => acc + (curr?.serialNumber || 0),
        0
    );

    const dlNprAmount = accountLedgerResponse && accountLedgerResponse?.reduce(
        (acc, curr) => acc + (curr?.drAmount || 0),
        0
    );

    const dlCrAmount = accountLedgerResponse && accountLedgerResponse?.reduce(
        (acc, curr) => acc + (curr?.crAmount || 0),
        0
    );

    const totalRowDl = {
        key: "Total",
        serialNumber: dlSerialNumber,
        accountHead: "Total",
        drAmount: dlNprAmount,
        crAmount: dlCrAmount,
    };

    const dataSourceDl = [...(accountLedgerResponse || []), totalRowDl]


    const columns = [...commonColumns, ...dlReportColumns]
    const dataSource = dataSourceDl;


    return (
        <>
            <Modal
                visible={modalVisible}
                // title="Summary of Income ${modalData?.colTitle ? modalData?.colTitle} : null}`"
                title={`Summary of  ${modalData?.colTitle || 'Income'}`}
                onCancel={() => setModalVisible({ visible: false })}
                footer={null}
                width={1000}
            >
                <Table
                    className="next-organizer-table"
                    dataSource={dataSource}
                    columns={columns}
                    loading={accountIncomeLoading}
                    pagination={false}
                />
            </Modal>
        </>
    );
};

export default OneCommonModal;
