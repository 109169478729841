import { ORGANIZER_TYPES } from "../../../types";

const INITIAL_STATE = {
    organizerEventLabelListing: [],
    organizerEventLabelListingLoading: false,
};

export default function organizerEventsLabelReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Labels Lisiting
        case ORGANIZER_TYPES.LABELS_EVENTS_LISTING_INIT:
            return {
                ...state,
                organizerEventLabelListing: [],
                organizerEventLabelListingLoading: true,
            };
        case ORGANIZER_TYPES.LABELS_EVENTS_LISTING_SUCCESS:
            return {
                ...state,
                organizerEventLabelListing: action.payload,
                organizerEventLabelListingLoading: false,
            };
        case ORGANIZER_TYPES.LABELS_EVENTS_LISTING_FINISH:
            return {
                ...state,
                organizerEventLabelListingLoading: false,
            };
        default:
            return { ...state };
    }
}
