import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { cqBaseUrl } from "utils/config";
import { textCenterDot } from "utils/commonFunc";
import Header from "components/header";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  PlusOutlined,
  StarFilled,
  StarOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Form,
  Input,
  Select,
  message,
  Button,
  Collapse,
  Dropdown,
  Menu,
  Layout,
  Tree,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import DocumentListing from "./includes/docListing";
import CreateEditDocument from "./includes/createEditDocDrawer";
import DocDetail from "./includes/docDetail";

import {
  documentInstantMessage,
  documentInstantMessageContinue,
} from "services/redux/email/instanceMessageCenter/action";
import {
  documentRequestPagination,
  documentShareToEmail,
} from "services/redux/document/action";
import { getTenderBasicSearchPagination } from "services/redux/Reference/Tender/action";
import { getProjectSearchPagination } from "services/redux/Reference/Project/action";
import { getOthersSearchPagination } from "services/redux/Reference/Others/action";
import { getAllUsers } from "services/remanentCalls";
import {
  actionForCreateGroup,
  actionForGetListingGroup,
  actionForUpdateGroup,
} from "services/redux/createGroup/action";
import {
  getAllChildFolderList,
  getFolderList,
} from "services/redux/Reference/Folder/action";
import TableNew from "./DocumentNew/TableNew";
import FolderDrawer from "./FolderDrawer";
import FolderCreate from "./FolderCreate";
import FileDrawer from "./FileDrawer";

const { Panel } = Collapse;
const { DirectoryTree } = Tree;

const layoutFormGroup = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
function Document() {
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [formCreateUpdateGroup] = Form.useForm();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const {
    documentInstanceMessageLoading,
    documentInstanceMessageContinueLoading,
  } = useSelector((state) => state.instanceMessageCenter);

  const { profileInfo } = useSelector((state) => state.profile);

  const { tenderBasicPaginationResponse, tenderBasicPaginationLoading } =
    useSelector((state) => state.tender);

  const { projectPaginationResponse, projectPaginationLoading } = useSelector(
    (state) => state.project
  );

  const {
    folderListingLoading,
    folderListingResponse,
    childFolderListingResponse,
    childFolderListingLoading,
  } = useSelector((state) => state.folder);

  useEffect(() => {
    setNewFolderData(childFolderListingResponse);
  }, [childFolderListingResponse]);

  const {
    referenceOthersPaginationResponse,
    referenceOthersPaginationLoading,
  } = useSelector((state) => state.referenceOthers);

  const { groupData, groupDataLoading, groupDataLoadingSuccess } = useSelector(
    (state) => state.createUpdateGroup
  );

  const [hideDrawer, setHideDrawer] = useState(true);
  const [showDocDetails, setShowDocDetails] = useState(false);
  const [docDetailId, setDocDetailId] = useState("");
  const [docDetail, setDocDetail] = useState([]);
  const [fileTypeId, setFileTypeId] = useState(null);
  const [referenceId, setReferenceId] = useState(null);
  const [referenceType, setReferenceType] = useState(null);
  const [userListing, setUserListing] = useState([]);
  const [toUserListings, setToUserListings] = useState([]);
  const [ccUserListings, setCCUserListings] = useState([]);
  const [visible, setVisible] = useState(false);
  const [internalVisible, setInternalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageProject, setCurrentPageProject] = useState(1);
  const [currentPageOther, setCurrentPageOther] = useState(1);
  const [tenderData, setTenderData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [othersData, setOthersData] = useState([]);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [selectedGroupDataToBeUpdated, setGroupDataToBeUpdate] = useState();
  const [selectedTosSharedGroupMembers, setSelectedTosSharedGroupMembers] =
    useState();
  const [folderShow, setFolderShow] = useState(false);
  const [newFolderData, setNewFolderData] = useState([]);
  const [showAddUpdateFolderDrawer, setShowAddUpdateFolderDrawer] =
    useState(false);
  const [showAddUpdateFileDrawer, setShowAddUpdateFileDrawer] = useState(false);
  const [folderRecord, setFolderRecord] = useState([]);
  const pageSize = 30;

  //setting value for internal subject and remarks
  form.setFieldsValue({
    applicationSubject: docDetail[0]?.fileDisplayName.replace(".pdf", ""),
    remarks: docDetail[0]?.fileDisplayName.replace(".pdf", ""),
  });
  useEffect(() => {
    if (!isEmpty(folderListingResponse)) {
      dispatch(getAllChildFolderList(folderListingResponse?.[0]?.id));
    }
  }, [dispatch, folderListingResponse]);

  useEffect(() => {
    dispatch(
      documentRequestPagination(
        {
          MaxResultCount: 20,
          skipCount: 0,
          FileLableTypeId: "",
          searchKeyword: "",
        },
        false
      )
    );
  }, []);

  // #region tender project other basic pagination
  useEffect(() => {
    if (isEmpty(tenderBasicPaginationResponse?.items)) return;
    let newCurrentPage = tenderBasicPaginationResponse.items.length / pageSize;
    setCurrentPage(newCurrentPage);
  }, [tenderBasicPaginationResponse]);

  useEffect(() => {
    if (isEmpty(projectPaginationResponse?.items)) return;
    let newCurrentPage = projectPaginationResponse.items.length / pageSize;
    setCurrentPageProject(newCurrentPage);
  }, [projectPaginationResponse]);

  useEffect(() => {
    if (isEmpty(referenceOthersPaginationResponse?.items)) return;
    let newCurrentPage =
      referenceOthersPaginationResponse.items.length / pageSize;
    setCurrentPageOther(newCurrentPage);
  }, [referenceOthersPaginationResponse]);
  // region fetch data from the api

  const getTenderData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getTenderBasicSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword: searchKeyword,
        },
        loadMore
      )
    );
  };

  const getProjectData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getProjectSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword: searchKeyword,
        },
        loadMore
      )
    );
  };

  const getOthersData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getOthersSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword: searchKeyword,
          RootLevel: 0,
        },
        loadMore
      )
    );
  };

  const getFoldersData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getOthersSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword: searchKeyword,
        },
        loadMore
      )
    );
  };

  const fetchMoreTender = () => {
    getTenderData(pageSize, currentPage * pageSize, true);
  };

  const fetchMoreProject = () => {
    getProjectData(pageSize, currentPageProject * pageSize, true);
  };

  const fetchMoreOthers = () => {
    getOthersData(pageSize, currentPageOther * pageSize, true);
  };
  // #endregion fetch data from api

  useEffect(() => {
    getTenderData();
    getProjectData();
    getOthersData();
    dispatch(getFolderList());
    //get all the list of folders
  }, [searchKeyword]);

  useEffect(() => {
    if (
      !tenderBasicPaginationResponse ||
      !projectPaginationResponse ||
      !referenceOthersPaginationResponse
    )
      return;
    setTenderData(tenderBasicPaginationResponse?.items);
    setProjectData(projectPaginationResponse?.items);
    setOthersData(referenceOthersPaginationResponse?.items);
  }, [
    tenderBasicPaginationResponse,
    projectPaginationResponse,
    referenceOthersPaginationResponse,
  ]);
  // #endregion tender basic pagination

  // # region fetch the userlisting form api
  const fetchUserListing = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      setUserListing(resp.data);
    }
  };
  // # endregion fetch the userlisting form api
  useEffect(() => {
    if (isEmpty(userListing)) {
      fetchUserListing();
    }
  }, []);

  // #region set in user listing in state
  useEffect(() => {
    setToUserListings(userListing);
    setCCUserListings(userListing);
  }, [userListing]);
  // # endregion set in uselisting in state
  const showModal = () => {
    setVisible(true);
  };
  // #region handle active table according to id
  const handleActiveTable = (e) => {
    let target = e.target;
    let id = target.parentNode.getAttribute("data-id");

    let parent = target.parentNode.parentNode.parentNode.parentNode;
    if (!isEmpty(id)) {
      setDocDetailId(id);
      setShowDocDetails(true);
      setHideDrawer(false);
    }
    if (target.tagName == "svg" || target.tagName == "path") {
      if (target.tagName == "path") {
        parent = parent.parentNode;
      }
      setDocDetailId(parent.getAttribute("data-id"));
      setShowDocDetails(true);
      // if (viewDrawerClose) {
      //   setHideDrawer(true);
      // }
    }
  };
  //endregion handle active table according to id
  // #region handle cancel  for document share model
  const handleCancel = () => {
    setVisible(false);
  };
  // #endregion handle cancel for document share model
  // #region filter by  reference code
  const filterByReference = (e) => {
    setFolderShow(true);
    if (e.target.tagName !== "svg" && e.target.tagName !== "path") {
      let target = e.target;
      if (e.target.tagName === "span") {
        target = target.parentNode.parentNode.parentNode;
      }
      let id = target.getAttribute("data-id");
      let type = target.getAttribute("data-type");
      setReferenceId(id);
      setReferenceType(type);
    }
  };

  // #endregion filter by reference code
  // #region document share status
  const handleShareStatus = (e) => {
    let shareType =
      e.target.textContent || e.target.querySelector("span").textContent;
    let data = {};
    if (shareType === "Email") {
      data = {
        notes: docDetail[0]?.notes,
        referenceCode: docDetail?.map((data) => data?.referenceCode),
        uploadedFileDtos: [
          {
            viewFileURL: docDetail[0]?.viewFileURL,
            displayFileName: docDetail[0]?.fileDisplayName,
            fileInformationId: docDetail[0]?.fileInformationId,
            fileLableTypeId: docDetail[0]?.fileLabelTypeId,
            fileLableTypeName: docDetail[0]?.fileLabelTypeId,
            isActive: true,
            notes: docDetail[0]?.notes,
            uploadedFileDomainMappingDto: {
              domainId: docDetail[0]?.uploadedFileDomainMappingDto?.domainId,
              isPublic: true,
            },
          },
        ],
      };
      dispatch(documentShareToEmail(data));
    }
    if (shareType === "Internal") {
      setInternalVisible(true);
    }
    setVisible(false);
  };

  // #endregion document share status
  // #region submit document share as internal
  useEffect(() => {
    if (!documentInstanceMessageLoading) {
      setInternalVisible(false);
    }
  }, [documentInstanceMessageLoading]);

  useEffect(() => {
    if (documentInstanceMessageContinueLoading) {
      setInternalVisible(true);
    }
  }, [documentInstanceMessageContinueLoading]);

  const shareDocumentData = (values) => {
    let tos = [];
    let ccs = [];
    values.participantTos &&
      values.participantTos?.map((item, i) => {
        tos = [...tos, { participantUserId: item }];
      });
    values.participantCCs &&
      values.participantCCs?.map((item, i) => {
        ccs = [...ccs, { participantUserId: item }];
      });
    let iframe = `${cqBaseUrl}/${docDetail[0]?.viewFileURL}`;
    let instanceBodyMessage = {
      applicationTypeId: "0D51BD87-55F6-43EA-B285-7C834F30BA98",
      applicationId: docDetail[0]?.id,
      messageText: `${iframe}`,
      remarks: values.remarks,
      applicationSubject: values.applicationSubject,
      instanceMessageStatusName: "normal",
      mailMessageParticipantWithTypeDtos: {
        participantTos: tos,
        participantCCs: ccs,
      },
      instanceMessageAttachmentDtos: [
        {
          viewFileURL: docDetail[0]?.viewFileURL,
          displayFileName: docDetail[0]?.fileDisplayName,
          fileInformationId: docDetail[0]?.fileInformationId,
          fileLableTypeId: docDetail[0]?.fileLabelTypeId,
          fileLableTypeName: docDetail[0]?.fileLabelTypeId,
          isActive: true,
          notes: docDetail[0]?.notes,
          uploadedFileDomainMappingDto: {
            domainId: docDetail[0]?.uploadedFileDomainMappingDto?.domainId,
            isPublic: true,
          },
        },
      ],
      mailMessageReferenceDtos: [
        {
          referenceCode: docDetail[0]?.referenceCode,
        },
      ],
    };
    return instanceBodyMessage;
  };

  const handleSaveContinue = (e) => {
    const participantTosValue = form.getFieldValue().participantTos;
    const participantCCsValue = form.getFieldValue().participantCCs;
    const subject = form.getFieldValue().applicationSubject;
    if (!!!participantTosValue) {
      return message.error("To or cc is empty");
    }
    let data = {
      participantTos: participantTosValue,
      participantCCs: participantCCsValue,
      remarks: form.getFieldValue("remarks"),
      applicationSubject: subject,
    };
    dispatch(documentInstantMessageContinue(shareDocumentData(data)));
    form.resetFields();
  };

  const shareDocumentForm = (values) => {
    dispatch(documentInstantMessage(shareDocumentData(values)));
  };
  // #endregion submit document share as internal

  const menuGroup = () => (
    <Menu>
      {groupData &&
        groupData?.map((x) => {
          return (
            <Menu.Item
              key={x?.id}
              onClick={() => {
                let userIds = x?.userList?.map((x) => {
                  return x?.userId;
                });
                setSelectedTosSharedGroupMembers(userIds);
              }}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{x?.groupName}</div>

              <EditOutlined
                style={{ paddingLeft: 15 }}
                onClick={() => {
                  setGroupDataToBeUpdate(x);
                  setCreateGroupModal(true);
                }}
              />
            </Menu.Item>
          );
        })}

      <Menu.Item
        key="Create Group"
        onClick={() => {
          setCreateGroupModal(true);
          setGroupDataToBeUpdate();
          formCreateUpdateGroup.resetFields();
        }}
      >
        Create Group
      </Menu.Item>
    </Menu>
  );

  let paramsForGroupListing = "SkipCount=0&MaxResultCount=100";

  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, []);

  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, [selectedGroupDataToBeUpdated, createGroupModal]);

  useEffect(() => {
    if (!isEmpty(selectedGroupDataToBeUpdated)) {
      formCreateUpdateGroup.setFieldsValue({
        groupName: selectedGroupDataToBeUpdated?.groupName,
        userList: selectedGroupDataToBeUpdated?.userList?.map((x) => {
          return x?.userId;
        }),
      });
    }

    if (groupDataLoadingSuccess) {
      setCreateGroupModal(false);
      setSelectedTosSharedGroupMembers();
      form.resetFields();
    } else if (!isEmpty(selectedTosSharedGroupMembers)) {
      form.setFieldsValue({
        participantTos: selectedTosSharedGroupMembers,
      });
    }
  });

  const onGroupCreateFinish = (values) => {
    let apiDataForGroupCreation = {
      ...values,
      userList: values?.userList?.map((x) => {
        return {
          userId: x,
        };
      }),
      isActive: true,
    };

    if (isEmpty(selectedGroupDataToBeUpdated)) {
      dispatch(actionForCreateGroup(apiDataForGroupCreation));
    } else {
      apiDataForGroupCreation = {
        ...apiDataForGroupCreation,
        id: selectedGroupDataToBeUpdated?.id,
      };
      dispatch(
        actionForUpdateGroup(
          apiDataForGroupCreation,
          selectedGroupDataToBeUpdated?.id
        )
      );
    }
  };

  const onSelect = (keys, info) => {
    dispatch(getAllChildFolderList(info?.node?.id));
    setFolderShow(false);
  };

  const onExpand = (keys, info) => {};

  return (
    <>
      <div className="sidebars">
        <div className="sidebars__menu">
          <div className="layout">
            <div className="organizer contacts">
              <Header name="Documents" />
              <div className="search-wrapper">
                <Input.Search
                  className="form__group"
                  style={{ margin: 0 }}
                  placeholder="Search"
                  size="medium"
                  allowClear
                  autoComplete="off"
                  onSearch={(e) => {
                    setSearchKeyword(e);
                  }}
                />

                <Button
                  className="contact__header__btn-primary task-plus-btn"
                  onClick={() => {
                    setHideDrawer(false);
                    setShowDocDetails(false);
                    setDocDetailId(null);
                  }}
                >
                  <PlusOutlined />
                </Button>
                {/* </Dropdown> */}
              </div>
            </div>
            <div className="document-panel-wrapper">
              <Collapse
                className="document-panel-collaspe"
                expandIconPosition="right"
                accordion={true}
                defaultActiveKey={["1"]}
              >
                <Panel key="1" header="Folder">
                  <div
                    style={{
                      height: "calc(100vh - 203px)",
                      overflowY: "auto",
                    }}
                  >
                    <div id="othersScroll">
                      {!isEmpty(profileInfo) &&
                        profileInfo?.userRegistrationAddUpdateDto?.roleNames.includes(
                          "DMSFolderCreator"
                        ) && (
                          <button
                            style={{
                              border: 0,
                              background: "none",
                              color: "black",
                              fontFamily: "inherit",
                              paddingLeft: "30px",
                              fontWeight: "bold",
                            }}
                            onClick={() => {
                              setShowAddUpdateFolderDrawer(true);
                              setFolderRecord(false);
                            }}
                          >
                            <PlusOutlined style={{ color: "black" }} />
                            New Root Folder
                          </button>
                        )}

                      <DirectoryTree
                        multiple
                        defaultExpandAll
                        onSelect={onSelect}
                        onExpand={onExpand}
                        treeData={folderListingResponse}
                      />
                    </div>
                  </div>
                </Panel>
                <Panel key="2" header="Tender">
                  <div
                    id="documentTenderScroll"
                    style={{ height: "calc(100vh - 203px)", overflowY: "auto" }}
                  >
                    <InfiniteScroll
                      dataLength={tenderBasicPaginationResponse?.items.length}
                      //This is important field to render the next data
                      next={fetchMoreTender}
                      hasMore={
                        tenderBasicPaginationResponse?.totalCount >
                        tenderBasicPaginationResponse?.items.length
                      }
                      scrollableTarget="documentTenderScroll"
                      endMessage={
                        <p className="endData" style={{ textAlign: "center" }}>
                          <b>End of Tender</b>
                        </p>
                      }
                    >
                      {tenderData &&
                        tenderData?.map((item, i) => {
                          return (
                            <div
                              className={`document-panel-item ${
                                referenceId === item.id && "active"
                              }`}
                              key={i}
                              data-id={item?.id}
                              data-type={item?.type}
                              onClick={filterByReference}
                            >
                              <div className="document-type-name">
                                <div className="document-code-info-wrapper">
                                  {item.isFavorite ? (
                                    <StarFilled
                                      style={{ color: "#ffd036" }}
                                      className="star-document"
                                    />
                                  ) : (
                                    <StarOutlined
                                      style={{ color: "#ffd036" }}
                                      className="star-document"
                                    />
                                  )}
                                  <span className="ref-name">
                                    {item.referenceCode}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </InfiniteScroll>
                  </div>
                </Panel>
                <Panel key="3" header="Project">
                  <div
                    id="documentProjectScroll"
                    style={{ height: "calc(100vh - 203px)", overflowY: "auto" }}
                  >
                    <InfiniteScroll
                      dataLength={projectPaginationResponse?.items.length}
                      //This is important field to render the next data
                      next={fetchMoreProject}
                      hasMore={
                        projectPaginationResponse?.totalCount >
                        projectPaginationResponse?.items.length
                      }
                      scrollableTarget="documentProjectScroll"
                      endMessage={
                        <p className="endData" style={{ textAlign: "center" }}>
                          <b>End of Project</b>
                        </p>
                      }
                    >
                      {projectData &&
                        projectData?.map((item, i) => {
                          return (
                            <div
                              className={`document-panel-item ${
                                referenceId === item.id && "active"
                              }`}
                              key={i}
                              data-id={item?.id}
                              data-type={item?.type}
                              onClick={filterByReference}
                            >
                              <div className="document-type-name">
                                <div className="document-code-info-wrapper">
                                  {item.isFavorite ? (
                                    <StarFilled
                                      style={{ color: "#ffd036" }}
                                      className="star-document"
                                    />
                                  ) : (
                                    <StarOutlined
                                      style={{ color: "#ffd036" }}
                                      className="star-document"
                                    />
                                  )}
                                  <span className="ref-name">
                                    {item.referenceCode}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </InfiniteScroll>
                  </div>
                </Panel>
                <Panel key="4" header="Others">
                  <div
                    style={{
                      height: "calc(100vh - 203px)",
                      overflowY: "auto",
                    }}
                  >
                    <div id="othersScroll">
                      <InfiniteScroll
                        dataLength={
                          referenceOthersPaginationResponse?.items.length
                        }
                        //This is important field to render the next data
                        next={fetchMoreOthers}
                        hasMore={
                          referenceOthersPaginationResponse?.totalCount >
                          referenceOthersPaginationResponse?.items.length
                        }
                        scrollableTarget="othersScroll"
                        endMessage={
                          <p
                            className="endData"
                            style={{ textAlign: "center" }}
                          >
                            <b>End of Others</b>
                          </p>
                        }
                      >
                        {othersData &&
                          othersData?.map((item, i) => {
                            return (
                              <div
                                className={`document-panel-item ${
                                  referenceId === item.id && "active"
                                }`}
                                key={i}
                                data-id={item?.id}
                                data-type={item?.type}
                                onClick={filterByReference}
                              >
                                <div className="document-type-name">
                                  <div className="document-code-info-wrapper">
                                    {item.isFavorite ? (
                                      <StarFilled
                                        style={{ color: "#ffd036" }}
                                        className="star-document"
                                      />
                                    ) : (
                                      <StarOutlined
                                        style={{ color: "#ffd036" }}
                                        className="star-document"
                                      />
                                    )}
                                    <span className="ref-name">
                                      {item.referenceCode}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </InfiniteScroll>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
        <div className="main__body sidebar-width-calc">
          <div className="global-body-with-drawer-wrapper">
            <div className="global-body-flex">
              <div className={`global-body ${hideDrawer && "hideDrawer"}`}>
                <div className="global-body-header">
                  <h3 className="header__all">
                    <span className="title">Summary</span>
                  </h3>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {!folderShow && (
                      <Button
                        className="btn-primary"
                        onClick={() => {
                          setShowAddUpdateFileDrawer(true);
                          setFolderRecord(newFolderData?.[0]);
                        }}
                      >
                        + Upload a file
                      </Button>
                    )}

                    {!isEmpty(profileInfo) &&
                      profileInfo?.userRegistrationAddUpdateDto?.roleNames.includes(
                        // "Dashboard Account Viewer"
                        "DMSFolderCreator"
                      ) &&
                      !folderShow && (
                        <div>
                          <Button
                            className="btn-primary"
                            onClick={() => {
                              setShowAddUpdateFolderDrawer(true);
                              setFolderRecord(newFolderData?.[0]);
                            }}
                          >
                            + Create Folder
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
                {showAddUpdateFolderDrawer && (
                  <FolderDrawer
                    showAddUpdateFolderDrawer={showAddUpdateFolderDrawer}
                    setShowAddUpdateFolderDrawer={setShowAddUpdateFolderDrawer}
                    folderRecord={folderRecord}
                  />
                )}

                {showAddUpdateFileDrawer && (
                  <FileDrawer
                    showAddUpdateFileDrawer={showAddUpdateFileDrawer}
                    setShowAddUpdateFileDrawer={setShowAddUpdateFileDrawer}
                    folderRecord={folderRecord}
                  />
                )}

                {folderShow ? (
                  <>
                    <div className="global-body-info-wrapper">
                      <DocumentListing
                        docDetailId={docDetailId}
                        setDocDetail={setDocDetail}
                        referenceId={referenceId}
                        referenceType={referenceType}
                        token={token}
                        showModal={showModal}
                        handleActiveTable={handleActiveTable}
                        setHideDrawer={setHideDrawer}
                      />
                    </div>{" "}
                  </>
                ) : (
                  <>
                    <FolderCreate
                      newFolderData={newFolderData}
                      setShowAddUpdateFolderDrawer={
                        setShowAddUpdateFolderDrawer
                      }
                      setShowAddUpdateFileDrawer={setShowAddUpdateFileDrawer}
                      setFolderRecord={setFolderRecord}
                    />
                    <TableNew
                      newFolderData={newFolderData}
                      setShowAddUpdateFolderDrawer={
                        setShowAddUpdateFolderDrawer
                      }
                      setFolderRecord={setFolderRecord}
                    />
                  </>
                )}
              </div>
              <div
                className={`global-custom-drawer-wrapper ${
                  hideDrawer && "hideDrawer"
                }`}
              >
                <div className="global-custom-drawer-header">
                  <h3>{showDocDetails ? "Doc Detail" : "Create Doc"}</h3>
                </div>
                <div className="global-custom-box-wrapper">
                  {showDocDetails && !isEmpty(docDetail) ? (
                    <DocDetail
                      setHideDrawer={setHideDrawer}
                      docDetail={docDetail}
                      token={token}
                      showModal={showModal}
                      setDocDetailId={setDocDetailId}
                    />
                  ) : (
                    <CreateEditDocument
                      hideDrawer={hideDrawer}
                      setHideDrawer={setHideDrawer}
                      setFileTypeId={setFileTypeId}
                      token={token}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal visible={visible} onCancel={handleCancel} footer="">
        <div className=" document-share-wrapper">
          <p>Do you want to share document as</p>
          <div className="share-status-wrapper">
            <Link
              to="/email-compose?applicationType=documentShare"
              target="_blank"
              rel="opener"
              className="share-as-email"
              type="primary"
              onClick={handleShareStatus}
            >
              Email
            </Link>
            <Button type="default" onClick={handleShareStatus}>
              Internal
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <Layout.Content
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            Share Document
            <Dropdown.Button
              overlay={menuGroup}
              icon={<UsergroupAddOutlined />}
            ></Dropdown.Button>
          </Layout.Content>
        }
        closable={false}
        visible={internalVisible}
        footer={null}
      >
        <Form
          form={form}
          className="drawer-form-wrapper share-document-form"
          onFinish={shareDocumentForm}
        >
          <div
            className="drawer-form-row"
            style={{ padding: 0, overflow: "hidden" }}
          >
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="participantTos"
                label="To"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select"
                  autoComplete="off"
                  mode="multiple"
                  optionFilterProp="label"
                >
                  {!isEmpty(toUserListings) &&
                    toUserListings?.map((user, index) => {
                      return (
                        <Option
                          value={user?.appUserId}
                          key={index}
                          label={[user.fullName, user.userName]}
                        >
                          {user?.fullName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="participantCCs"
                label="Cc"
              >
                <Select
                  placeholder="Select"
                  autoComplete="off"
                  mode="multiple"
                  optionFilterProp="label"
                >
                  {!isEmpty(ccUserListings) &&
                    ccUserListings?.map((user, index) => {
                      return (
                        <Option
                          value={user?.appUserId}
                          key={index}
                          label={[user.fullName, user.userName]}
                        >
                          {user?.fullName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>

            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="applicationSubject"
                label="Subject"
              >
                <Input placeholder="Enter Subject" />
              </Form.Item>
            </div>

            <div className="drawer-form-group" style={{ width: "100%" }}>
              <Form.Item className="form__group" label="File" name="body">
                <div className="file-info-wrapper">
                  {!isEmpty(docDetail) && (
                    <span>
                      <a
                        style={{ display: "inline-block" }}
                        onClick={() => {
                          window.open(
                            `${cqBaseUrl}/${docDetail[0]?.viewFileURL}${token}`,
                            "_blank",
                            "top=50,left=200,frame=true"
                          );
                        }}
                      >
                        {textCenterDot(docDetail[0]?.fileDisplayName)}
                      </a>
                    </span>
                  )}
                </div>
              </Form.Item>
            </div>
            <div className="drawer-form-group" style={{ width: "100%" }}>
              <Form.Item className="form__group" label="Remarks" name="remarks">
                <TextArea autoComplete="off" rows={10}></TextArea>
              </Form.Item>
            </div>
            <div
              className="drawer-form-group"
              style={{ display: "flex", columnGap: 7, marginTop: 15 }}
            >
              <Button
                type="default"
                onClick={() => {
                  setInternalVisible(false);
                  form.resetFields();
                }}
                style={{ marginLeft: "auto" }}
                htmlType="button"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={documentInstanceMessageLoading}
              >
                Submit
              </Button>
              <Button
                type="primary"
                htmlType="button"
                loading={documentInstanceMessageContinueLoading}
                onClick={handleSaveContinue}
              >
                Save & Continue
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      {/* Creating Group To Send to particular Group of Users */}

      <Modal
        visible={createGroupModal}
        title={`${
          isEmpty(selectedGroupDataToBeUpdated)
            ? "Create Your Group"
            : "Update Your Group"
        }`}
        onCancel={() => setCreateGroupModal(false)}
        footer={null}
        height={300}
      >
        <Form
          {...layoutFormGroup}
          onFinish={onGroupCreateFinish}
          form={formCreateUpdateGroup}
        >
          <Form.Item
            name="groupName"
            label="Group Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Name of your group" />
          </Form.Item>
          <Form.Item name="userList" label="Users" rules={[{ required: true }]}>
            <Select
              placeholder="Select Users"
              allowClear
              autoComplete="off"
              mode="multiple"
              optionFilterProp="label"
            >
              {!isEmpty(toUserListings) &&
                toUserListings?.map((user, index) => {
                  return (
                    <Option
                      value={user?.appUserId}
                      key={index}
                      label={[user.fullName, user.userName]}
                    >
                      {user?.fullName} [{user?.userName.toUpperCase()}]
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Layout.Content style={{ display: "flex", justifyContent: "end" }}>
            <Button type="primary" htmlType="submit" loading={groupDataLoading}>
              {isEmpty(selectedGroupDataToBeUpdated) ? "Submit" : "Update"}
            </Button>
          </Layout.Content>
        </Form>
      </Modal>
    </>
  );
}

// export default withBaseLayout(Contact);
export default Document;
