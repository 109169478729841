import { EMAIL_TYPES } from "../../../types";

const INITIAL_STATE = {
    mailMessageParticipantTypeListing: [],
    mailMessageParticipantTypeListingLoading: false,
};

export default function mailMessageParticipantTypeListingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Appointment events
        case EMAIL_TYPES.PARTICIPANT_TYPE_LISTING_INIT:
            return {
                ...state,
                mailMessageParticipantTypeListing: [],
                mailMessageParticipantTypeListingLoading: true,
            };
        case EMAIL_TYPES.PARTICIPANT_TYPE_LISTING_SUCCESS:
            return {
                ...state,
                mailMessageParticipantTypeListing: action.payload,
                mailMessageParticipantTypeListingLoading: false,
            };
        case EMAIL_TYPES.PARTICIPANT_TYPE_LISTING_FINISH:
            return {
                ...state,
                mailMessageParticipantTypeListingLoading: false,
            };
        default:
            return { ...state };
    }
}
