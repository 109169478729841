import { appBaseUrl } from "utils/config";
import { getService } from "../../../commonServices";
import queryString from "query-string";
// import axios from "axios";
// import mailMessageCenterReducer from "./reducer";

export class BusinessService {
  getSOWDetails(body, source) {
    const query = queryString.stringify(body);
    let url = `${appBaseUrl}/referenceManagement/tenderBasicPaginationList_New?${query}`;
    let data = getService(url);
    return data;
  }
  getAllSectors() {
    let url = `${appBaseUrl}/referenceManagement/sectorListing`;
    let data = getService(url);
    return data;
  }

  getSubSectors(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/sectorsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  getAllStatus() {
    let url = `${appBaseUrl}/referenceManagement/projectStatusListing`;
    let data = getService(url);
    return data;
  }
  getAllModality() {
    let url = `${appBaseUrl}/referenceManagement/modalityListing`;
    let data = getService(url);
    return data;
  }
}
