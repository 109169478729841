import React, { useState, useEffect } from "react";
import {
  Collapse,
  Form,
  DatePicker,
  TimePicker,
  Button,
  Row,
  Col,
  Checkbox,
  Select,
  Spin,
  Modal,
  Input,
} from "antd";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";
import QuickAddCompany from "views/Contact/ContactCompanyPerson/AddQuickCompany";
import {
  updateStageDocumentPurchase,
  addStageDocumentPurchase,
} from "services/redux/Reference/Tender/action";
import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";

const DocumentPurchase = ({
  
  sisterCompanyListing,
  getDetails,
  documentPurchaseForm,
  documentDetails,
  editDocumentPurchase,
  setEditDocumentPurchase,
  i,
  showStages,
  data,
  setOpenForm,
  setForm,
  setShowDocAddButton,
  stageIndex,
  setStageIndex,
  addNewDocumentPurchase,
  setAddNewDocumentPurchase,
}) => {
  const tooltip = "./assets/svg/rototool-24.svg";
  const CheckMark = "assets/svg/checkmark-24.svg";
  const cancel = "assets/svg/cross-24.svg";
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (!isEmpty(getDetails?.tenderDocumentPurchaseAddUpdateDtos)) {
  //     setOpenForm(true);
  //   }
  // }, [getDetails]);


  useEffect(() => {
    if (isEmpty(editDocumentPurchase)) {
      // setOpenForm(false);
    }
  }, [editDocumentPurchase]);

  useEffect(() => {
    if (getDetails) {
      // setOpenForm(false);
      // setEditDocumentPurchase([]);
      singleForm.resetFields();
    }
  }, [getDetails]);

  // console.log("setFormerror", setForm);

  const addNewForm = (add) => {
    add();
    setFocus(true);
  };
  const [singleForm] = Form.useForm();
  const { Option } = Select;

  const [focus, setFocus] = useState(false);

  const [value, setValue] = useState("");
  const [addCompany] = Form.useForm();
  const pageSize = 100;
  const [contactCompanyList, setContactCompanyList] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [companyPurchase, setCompanyPurchase] = useState("");
  const { contactCompanyPaginationResponse, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);
    const { allCountryListingResponse } = useSelector((state) => state.contact);
  const { quickContactCompanyAdd, quickContactCompanyLoader } = useSelector(
    (state) => state.contactCompanyPerson
  );
  const getCompanyPagination = (
    SkipCount = 0,
    // Sorting,
    // SortType,
    SearchKeyword,
    ForPagination = false
  ) => {
    dispatch(
      getContactCompanyPagination(
        {
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: value,
        },
        ForPagination
      )
    );
  };
  const onSelect = (e) => {
    if (e.target.checked === true) {
      setOpenForm(true);
      // let lists = cloneDeep(editDocumentPurchase);
      let lists = cloneDeep(addNewDocumentPurchase);
      lists[i] = "true";
      // setEditDocumentPurchase(lists);
      setAddNewDocumentPurchase(lists);
      documentPurchaseForm.setFieldsValue({
        documentsPurchasedtos: [
          {
            purchaseDate: "",
            companyName: "",
            through: "",
            claim: "",
          },
        ],
      });
    } else {
      setOpenForm(false);
      // let lists = cloneDeep(editDocumentPurchase);
      // lists[i] = "false";
      let lists = cloneDeep(addNewDocumentPurchase);
      lists[i] = "false";
      setAddNewDocumentPurchase(lists);
      // setEditDocumentPurchase(lists);
      documentPurchaseForm.setFieldsValue({
        documentsPurchasedtos: [],
      });
    }
  };
  useEffect(() => {
    if (!isEmpty(contactCompanyPaginationResponse?.items)) {
      setContactCompanyList(contactCompanyPaginationResponse?.items);
    } else {
    }
  }, [contactCompanyPaginationResponse]);

  useEffect(() => {
    if (isEmpty(value)) setContactCompanyList([]);
    if (isEmpty(value)) return;
    if (
      !isEmpty(contactCompanyList) &&
      isEmpty(contactCompanyPaginationResponse?.items)
    )
      return;

    const timeoutId = setTimeout(() => getCompanyPagination(), 500);
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    if (quickContactCompanyAdd) {
      // dispatch(allCountryListing());

      addCompany.setFieldsValue({
        scopeOfWork: [],
        companyName: "",
        countryId: "",
        isActive: false,
        isFavorite: false,
        cityId: "",
      });

      setVisibleModal(false);

      setTimeout(() => {
        let newCompany;
        if (companyPurchase == "purchase") {
          newCompany = !isEmpty(
            singleForm.getFieldValue("tenderStageDocumentPurchaseAddUpdateDtos")
          )
            ? [
                ...singleForm.getFieldValue(
                  "tenderStageDocumentPurchaseAddUpdateDtos"
                ),
                quickContactCompanyAdd.id,
              ]
            : [quickContactCompanyAdd.id];
        }

        companyPurchase == "purchase" &&
          singleForm.setFieldsValue({
            contactCompanyId: quickContactCompanyAdd.id,
          });
      }, 500);
    }
  }, [quickContactCompanyAdd]);


  useEffect(() => {
    if (!isEmpty(documentDetails)) {
      setOpenForm(true);
      // console.log("hey you are in");
      singleForm.setFieldsValue({
        ...documentDetails,
        purchaseDate: moment(documentDetails.purchaseDate),
        claim: documentDetails.claim.toString(),
      });
    }
  }, [documentDetails]);


  const onSubmitUpdate = async (action) => {
    // console.log("action", action);
    try {
      let values = await singleForm.validateFields();

      if (action === "update") {
        // console.log("hey this is awa");
        let update = {
          ...values,
          id: documentDetails?.id,
          tenderStageLevelId: documentDetails?.id,
          isActive: true,
          docPurchaseId: documentDetails.id,
          tenderBasicId: getDetails.id,
        };

        dispatch(updateStageDocumentPurchase(update));
      } else {
        let add = {
          ...values,
          tenderStageLevelId: data?.id,
          tenderBasicId: getDetails.id,
        };
        // console.log("you are here add");
        dispatch(addStageDocumentPurchase(add));
      }
      setEditDocumentPurchase([]);
      setAddNewDocumentPurchase({});
      setShowDocAddButton(true);
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };



  return (
    <>
    
      <div>
        {isEmpty(data?.tenderStageDocumentPurchaseAddUpdateDtos) && (
          <div className="participation-wrapper">
            <div className="participation-title">
              <span>Document Purchase</span>
              <span
                className="edittender__secondaryText"
                style={{ marginLeft: 20, color: "#777", fontWeight: 400 }}
              >
                (Would you like to add documents)
              </span>
            </div>
            <div className="checkbox-wrapper">
              <Checkbox
                onChange={onSelect}
                checked={
                  !isEmpty(addNewDocumentPurchase) &&
                  addNewDocumentPurchase[i] === "true"
                    ? true
                    : false
                }
              />
            </div>
          </div>
        )}
        
        {(!isEmpty(editDocumentPurchase) &&
          editDocumentPurchase[i] === "true") ||
        (!isEmpty(addNewDocumentPurchase) &&
          addNewDocumentPurchase[i] === "true")
          ? setForm && (
              <div className="business-form-wrapper" style={{ marginTop: 10 }}>
                <Form layout="vertical" form={singleForm} name="control-hooks">
                  <div className="global-form-flex">
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Company</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          name="contactCompanyId"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            size="small"
                            showSearch={true}
                            allowClear
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={trigger => trigger.parentNode}
                            onSearch={(value) => {
                              if (value) {
                                setValue(value);
                              } else {
                                setContactCompanyList([]);
                                setValue(value);
                              }
                            }}
                            notFoundContent={
                              !isEmpty(value) ? (
                                contactCompanyPaginationLoading ? (
                                  <Spin />
                                ) : (
                                  isEmpty(
                                    contactCompanyPaginationResponse?.items
                                  ) &&
                                  value?.length > 2 && (
                                    <div>{`Create "${value}"`}</div>
                                  )
                                )
                              ) : null
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                if (
                                  isEmpty(
                                    contactCompanyPaginationResponse?.items
                                  ) &&
                                  !isEmpty(value)
                                ) {
                                  // e.preventDefault();
                                  setVisibleModal(true);
                                  setCompanyPurchase("purchase");
                                }
                              }
                            }}
                            onBlur={() => setContactCompanyList([])}
                          >
                            {/* {allCountryListingResponse?.map((country) => (
                              <Option value={country.id} key={country.id}>
                                {country.companyName}
                              </Option>
                            ))} */}
                                {!isEmpty(documentDetails?.contactCompanyId) && (
                        <Option value={documentDetails.contactCompanyId}>
                          {documentDetails.contactCompanyName}
                       
                        </Option>
                      )}
                   
                        {/* {!isEmpty(contactCompanyList) && allCountryListingResponse?.map((country) => (
                              <Option value={country.id} key={country.id}>
                                {country.companyName}
                              </Option>
                            ))} */}
                               {contactCompanyList?.map((company) => (
                        <Option
                          value={company.contactCompanyId}
                          key={company.contactCompanyId}
                          label={company.contactCompanyName}
                        >
                          {company.contactCompanyName}
                        </Option>
                      ))}
                      {/* {contactCompanyList?.map((company) => (
                        <Option
                          value={company.contactCompanyId}
                          key={company.contactCompanyId}
                          label={company.contactCompanyName}
                        >
                          {company.contactCompanyName}
                        </Option>
                      ))} */}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Through</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          name="throughCompanyId"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select size="small" getPopupContainer={trigger => trigger.parentNode}>
                            {sisterCompanyListing?.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label> Date of Purchase</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          name="purchaseDate"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                          initialValue={moment()}
                        >
                          <DatePicker
                            size="small"
                            format="YYYY/MM/DD"
                            defaultValue={moment()}
                            getPopupContainer={trigger => trigger.parentNode}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Claim</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          name="claim"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                          initialValue="true"
                        >
                          <Select size="small" defaultValue={true} getPopupContainer={trigger => trigger.parentNode}>
                            <Option value="true">Yes</Option>
                            <Option value="false">No</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-footer">
                      <Button
                        className="cancleAction"
                        onClick={() => {
                          let lists = cloneDeep(editDocumentPurchase);
                          lists[i] = "false";
                          setEditDocumentPurchase(lists);
                          singleForm.resetFields();
                          setShowDocAddButton(true);
                          let addNew = cloneDeep(addNewDocumentPurchase);
                          addNew[i] = "false";
                          setAddNewDocumentPurchase(addNew);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="saveAction"
                        onClick={() => {
                          onSubmitUpdate(
                            !isEmpty(documentDetails) ? "update" : "add"
                          );
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            )
          : ""}
        <Modal
          visible={visibleModal}
          footer={null}
          onCancel={() => {
            addCompany.setFieldsValue({
              scopeOfWork: [],
              companyName: "",
              countryId: "",
              isActive: false,
              isFavorite: false,
              cityId: "",
            });
            setVisibleModal(false);
          }}
          keyboard={false}
          maskClosable={false}
        >
          <QuickAddCompany
            form={addCompany}
            companySearch={value}
            quickContactCompanyAdd={quickContactCompanyAdd}
            quickContactCompanyLoader={quickContactCompanyLoader}
            quickAddFromProject="project"
          />
        </Modal>
      </div>
    </>
  );
};

export default DocumentPurchase;
