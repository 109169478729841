import { PERSIST_DATA } from "services/types";
import { jsonParser } from "utils/misc";

const INITIAL_STATE = {
  persistTenderMailDataResp: {
    tos:
      jsonParser(
        jsonParser(sessionStorage.getItem("persist:root"))
          ?.persistTenderMailState
      )?.persistTenderMailDataResp?.tos || {},

    refCode:
      jsonParser(
        jsonParser(sessionStorage.getItem("persist:root"))
          ?.persistTenderMailState
      )?.persistTenderMailDataResp?.refCode || {},

    subject:
      jsonParser(
        jsonParser(sessionStorage.getItem("persist:root"))
          ?.persistTenderMailState
      )?.persistTenderMailDataResp?.subject || {},

    content:
      jsonParser(
        jsonParser(sessionStorage.getItem("persist:root"))
          ?.persistTenderMailState
      )?.persistTenderMailDataResp?.content || {},
  },
  loading: false,
};

export default function persistTenderMailReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case PERSIST_DATA.PERSIST_DATA_TENDER_INIT:
      return {
        ...state,
        loading: true,
      };

    case PERSIST_DATA.PERSIST_DATA_TENDER_SUCCESS:
      // console.log("REDUCER: action", action)
      return {
        ...state,
        persistTenderMailDataResp: {
          tos: action.payload.tos,
          refCode: action.payload.refCode,
          subject: action?.payload.subject,
          content: action?.payload.content,
        },
        loading: false,
      };

    case PERSIST_DATA.PERSIST_DATA_TENDER_TOS:
      // console.log("REDUCER action.payload: ", action)
      return {
        ...state,
        persistTenderMailDataResp: {
          ...state.persistTenderMailDataResp,
          // tos: action.payload.tos || state.persistTenderMailDataResp.tos,
          tos: action.payload,
        },
        loading: false,
      };

    case PERSIST_DATA.PERSIST_DATA_TENDER_CONTENT:
      // console.log("REDUCER action.payload: ", action)
      return {
        ...state,
        persistTenderMailDataResp: {
          ...state.persistTenderMailDataResp,
          content: action.payload,
        },
        loading: false,
      };

    case PERSIST_DATA.PERSIST_DATA_TENDER_FINISH:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
