import moment from "moment";
import { Typography } from "antd";

const getColumnsDashboard2 = ({
  totalWorkingDays,
  newMonth,
  getYear,
  totalDays,
  frommDate,
  showDate,
}) => {
  const fromDate = new Date(frommDate).getDay();

  const monthOptions = [
    { value: 1, label: "Baisakh" },
    { value: 2, label: "Jestha" },
    { value: 3, label: "Ashar" },
    { value: 4, label: "Shrawan" },
    { value: 5, label: "Bhadra" },
    { value: 6, label: "Ashwin" },
    { value: 7, label: "Kartik" },
    { value: 8, label: "Mangsir" },
    { value: 9, label: "Poush" },
    { value: 10, label: "Magh" },
    { value: 11, label: "Falgun" },
    { value: 12, label: "Chaitra" },
  ];

  const getMonths = () => {
    return monthOptions.find((option) => option.value === newMonth);
  };

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return [
    {
      title: "SN",
      dataIndex: "s.no",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: 40,
      fixed: "left",
    },
    {
      title: "Full Name",
      width: 200,
      dataIndex: "fullName",
      fixed: "left",
    },
    {
      title: "Total Working days",
      width: 150,
      dataIndex: "totalWorkingDays",
      render: () => {
        return totalWorkingDays;
      },
    },
    {
      title: "Total Present Days",
      width: 150,
      dataIndex: "totalAbsentDays",
      render: (data, row) => {
        return row?.totalPresentDays;
      },
    },
    {
      title: "Total Unauthorized Leave",
      width: 150,
      dataIndex: "totalAbsentDays",
    },
    {
      title: "Total Leave Days",
      width: 150,
      render: (data, row) => {
        return row?.totalLeaveDays;
      },
    },
    {
      title: `${getMonths().label} ${getYear().label}`,
      align: "center",
      children: new Array(totalDays).fill(0).map((_, i) => ({
        title: `${i + 1}/${dayNames[(fromDate + i) % 7]}`,
        key: i,
        render: (_, row) => (
          <Typography.Text
            type={
              row?.attendanceDetails?.[i]?.attendanceStatus ===
              "UnAuthorizedLeave"
                ? "danger"
                : row?.attendanceDetails?.[i]?.attendanceStatus === "Present"
                ? "success"
                : row?.attendanceDetails?.[i]?.attendanceStatus === "Holiday"
                ? "disabled"
                : row?.attendanceDetails?.[i]?.attendanceStatus === "Leave"
                ? "warning"
                : "secondary"
            }
            style={{ whiteSpace: "pre-line" }}
          >
            {showDate
              ? row?.attendanceDetails?.[i]?.attendanceStatus === "Leave"
                ? "L"
                : row?.attendanceDetails?.[i]?.attendanceStatus ===
                  "UnAuthorizedLeave"
                ? "UL"
                : row?.attendanceDetails?.[i]?.attendanceStatus === "Holiday"
                ? "H"
                : row?.attendanceDetails?.[i]?.attendanceStatus === "Present"
                ? `In: ${moment(row?.attendanceDetails?.[i].inDateTime).format(
                    "hh:mm:ss a"
                  )}
              Out: ${moment(row?.attendanceDetails?.[i].outDateTime).format(
                "hh:mm:ss a"
              )}`
                : row?.attendanceDetails?.[i]?.attendanceStatus
              : row?.attendanceDetails?.[i]?.attendanceStatus}
          </Typography.Text>
        ),
        width: 150,
      })),
    },
  ];
};

export default getColumnsDashboard2;
