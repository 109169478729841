import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, message, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import AddUserDrawer from "./AddUserDrawer";
import NoticeAddUpdateDrawer from "./NoticeAddUpdateDrawer";
import NoticeAddUpdateTable from "./NoticeAddUpdateTable";
import { honorRollGetDetails } from "../../../services/remanentCalls";
import moment from "moment";
const { RangePicker } = DatePicker;
const NoticeBoardAddUpdate = () => {
  const dispatch = useDispatch();
  const [
    addUpdateCountryDrawerVisibility,
    setAddUpdateCountryDrawerVisibility,
  ] = useState(false);
  const [updateProfileDrawerVisibility, setUpdateProfileDrawerVisibility] =
    useState(false);
  const [showNoticeBoard, setShowNoticeBoard] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [isUpdateCountry, setIsUpdateCountry] = useState(false);
  const [endDate, setEndDate] = useState("");
  const onCloseNoticeBoard = () => {
    setShowNoticeBoard(false);
  };

  const showAddUpdateCountryDrawer = (e, record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      // console.log(record,"testRecord")
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };
  // console.log(isUpdateCountry,"showAddUpdateCountryDrawer");
  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry("");
    setAddUpdateCountryDrawerVisibility(false);
  };
  return (
    <div className="layout">
      <div className="organizer honorRoll">
        <div className="global-header-wrapper">
          <div className="header-info">
            <h3 className="header__all ">Notice Board</h3>
          </div>
          <div className="header-action-wrapper">
            <RangePicker
              onChange={(value) => {
                setStartDate(
                  value ? moment(value[0]).format("YYYY-MM-DD") : ""
                );
                setEndDate(value ? moment(value[1]).format("YYYY-MM-DD") : "");
              }}
            />
            <Button
              className="btn-primary"
              onClick={showAddUpdateCountryDrawer}
            >
              <PlusOutlined /> New Notice Board
            </Button>
          </div>
        </div>
        <NoticeAddUpdateDrawer
          drawerVisibility={addUpdateCountryDrawerVisibility}
          isUpdateCountry={isUpdateCountry}
          onAddUpdateCountryClose={onAddUpdateCountryClose}
          onCloseNoticeBoard={onCloseNoticeBoard}
        />
        <NoticeAddUpdateTable
          showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
          // isUpdateCountry={isUpdateCountry}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  );
};

export default NoticeBoardAddUpdate;
