import React, { useEffect, useState } from "react";
import moment from 'moment';
import { isEmpty } from "lodash-es";

// antd
import { Table, Tag } from "antd";

// utils
import { LogoImage } from "utils/imageConstants";

// redux
import { useDispatch, useSelector } from "react-redux";
import { leaveApplicationFiscalDaysPagination } from "services/redux/admin/LeaveApplication/LeaveFiscalDay/action";

const LeaveFiscalDayTable = ({ showAddUpdateLeaveFiscalDay }) => {
  const columns = [
    {
      title: "Enqueue Number",
      dataIndex: "enqueueNumber",
      key: "enqueueNumber",
    },
    {
      title: "Total Allowed Days",
      dataIndex: "totalAllowedDays",
      key: "totalAllowedDays"
    },
    {
      title: "Fiscal Year",
      render: (info) => {
				return <span>{info.fiscalYearDto?.displayName || ''}</span>;
			}
    },
		{
			title: 'Current',
      dataIndex: "isCurrent",
      key: "isCurrent",
			render: (tag) => {
				let color = '';
				let status = '';
				if (tag) {
					color = 'green';
					status = 'Active';
				} else {
					color = 'red';
					status = 'InActive';
				}
				return <Tag color={color}>{status.toUpperCase()}</Tag>;
			},
		},
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a className='action-btn action-btn-add' onClick={() => showAddUpdateLeaveFiscalDay(record)}>
          Edit Fiscal Days
        </a>
      ),
    },
  ];

  const dispatch = useDispatch();
  const {
    leaveFiscalDayPagination,
    leaveFiscalDayPaginationLoading,
  } = useSelector((state) => state.leaveFiscalDaysState);

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isEmpty(leaveFiscalDayPagination?.items)) return;
    setdata(leaveFiscalDayPagination.items);
    setTotalCount(leaveFiscalDayPagination.totalCount);
  }, [leaveFiscalDayPagination]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType
  ) => {
    dispatch(
      leaveApplicationFiscalDaysPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType
      })
    );
  };

  return (
    <Table className="contact-table"
      columns={columns}
      dataSource={data}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" />
        ),
        spinning: leaveFiscalDayPaginationLoading,
      }}
      pagination={{
        total: totalCount,
        pageSize: pageSize,
      }}
    />
  );
};

export default LeaveFiscalDayTable;