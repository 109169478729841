import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "antd";

import Companies from "./companies/Companies";

import Summary from "./information/Summary";
import Communication from "./communication/Communication";
// import Docs from "./Docs";
import { isEmpty, values } from "lodash";
// services
import { useDispatch, useSelector } from "react-redux";
// import { AddTender } from "./AddSummary";
import ToDo from "./Todo/index";
import { getDynamicFileLabelTypeUploaderDetail } from "services/redux/common/dynamicFileType/action";

import {
  getContactPersonPagination,
  contactPersonFav,
  contactCompanyGetDetails,
} from "services/redux/contact/companypeople/action";
import { referencePartyRemarksHistoryListing } from "services/redux/Reference/action";
import TenderDocuments from "./docs/TenderDocuments";
import { selectedMailTypeAndMail } from "services/redux/email/mailMessageCenter/action";
import { actionForGetTenderCheckList, actionForGetReferenceTemplateGetDetailsById } from "services/redux/Reference/Tender/action";
import SOWTable from "./Sow";
import Blog from "./blog/index";
import storage from "../../../../../utils/storage";
// import Blog from "components/Blog/index";
import MyContext from "context/MyContext";

const Tender = ({
  addTenderDrawerVisibility,
  isUpdate,
  searchEmailTender,
  tenderBasicGetDetailsLoading,
  selectedTenderRowId,
  setIsUpdate,
  extensionData,
  showChat,
  setShowChat,
  getDetails,
  setGetDetails,
  projectListing,
  height,
  setSelectedTenderRowId,
  setSearchKeyword,
  emailReferenceId,
  setExtensionData,
  searchDataAtBusinessHeaderForm,
  setBusinessTabPaneKey,
  selectedBusinessTabPaneKey,
  tenderId,
  showTenderTab,
}) => {
  const { TabPane } = Tabs;

  const [companyId, setCompanyId] = useState();

  const dispatch = useDispatch();
  const dynamicFileTypesState = useSelector(
    (state) => state.dynamicFileLabelTypes
  );
  const { dynamicFileLabelTypes } = dynamicFileTypesState;
  const [chatHeight, setChatHeight] = useState(false);
  // business chat
  const [hideInternalMessage, setHideInternalMessage] = useState(false);
  const [grantedPolices, setGrantedPolices] = useState({});

  // added
  const { mailOriginState, updateMailOriginState } = useContext(MyContext);

  const [isCircularTabStatus, setIsCircularTabStatus] = useState(false);
  const [selectedCompanyAttentionToIds, setSelectedCompanyAttentionToIds] = useState([]);

  // useEffect(() => {
  // 	dispatch(getDynamicFileLabelTypeUploaderDetail('Project'));
  // }, []);

  const [contactCompanyId, setContactCompanyId] = useState("");
  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );

  let userId = storage.getItem("userId");

  const selectedType = {
    type: "tender",
    referenceCode: tenderId,
  };


  useEffect(() => {
    if (selectedTenderRowId) {
      dispatch(getDynamicFileLabelTypeUploaderDetail(selectedType));
    }
  }, [tenderId]);

  useEffect(() => {
    if (!isEmpty(applicationConfiguration)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  const rowEventhandlerscompany = (record) => {
    return {
      onClick: () => {
        setContactCompanyId(record.contactCompanyId);
        dispatch(
          getContactPersonPagination({
            CompanyId: record.contactCompanyId,
            SearchKeyword: record.partyName,
          })
        );
        dispatch(contactCompanyGetDetails(record.contactCompanyId));
        const body = {
          contactCompanyId: record.contactCompanyId,
          tenderBasicId: getDetails.id,
          isCircular: record?.isCircularPlanning ? true : false,
        };
        dispatch(referencePartyRemarksHistoryListing(body));
        
        let attentionTo = JSON.parse(record?.attentionTo);
        if(!isEmpty(attentionTo)) {
          let id = attentionTo?.map((item) => item?.AttentionId);
          setSelectedCompanyAttentionToIds(id)
        }
      },
      // onDoubleClick: () => {
      //   dispatch(
      //     getContactPersonPagination({
      //       CompanyId: record.contactCompanyId,
      //       SearchKeyword: record.partyName,
      //     })
      //   );
      //   dispatch(contactCompanyGetDetails(record.contactCompanyId));
      //   const body = {
      //     contactCompanyId: record.contactCompanyId,
      //     tenderBasicId: getDetails.id,
      //     isCircular: record?.isCircularPlanning ? true : false,
      //   };
      //   dispatch(referencePartyRemarksHistoryListing(body));
      // },
    };
  };

  const tenderCommunication = () => {
    let saveData = {
      typeSystemName: "Inbox",
      typeDisplayName: "Inbox",
      currentTab: "All",
      index: 0,
    };
    dispatch(selectedMailTypeAndMail(saveData));
  };

  const handleTabChange = (key) => {
    setBusinessTabPaneKey(key);
    
    if (key === "2") {
      tenderCommunication();
      updateMailOriginState("communication");
      setIsCircularTabStatus(false);
    } else if (key === "3") {
      tenderCommunication();
      updateMailOriginState("companies");
    } else if(key === "7") {
      setIsCircularTabStatus(true);
    }
    else {
      // console.log("I'm other tab");
      setIsCircularTabStatus(false);
    }
  };

  return (
    <div>
      <Tabs
        className="global-tabs-wrapper tender-tabs-wrapper"
        defaultActiveKey="1"
        size="large"
        activeKey={selectedBusinessTabPaneKey}
        // onChange={(key) => {
        //   setBusinessTabPaneKey(key);
        //   if (key === "2" || key === "3") {
        //     let saveData = {
        //       typeSystemName: "Inbox",
        //       typeDisplayName: "Inbox",
        //       // count: mailMessageCenterPagination.totalCount,
        //       // icon: mailInbox,
        //       // selectedMail: mailMessageCenterPagination.items[0],
        //       // totalLength: currentSelectedMailIndex + 1,
        //       currentTab: "All",
        //       index: 0,
        //     };
        //     dispatch(selectedMailTypeAndMail(saveData));
        //   }
        // }}
        onChange={handleTabChange}
      >
        <TabPane
          className={`summary-wrapper ${showChat && "chatOpen"}`}
          tab="Information"
          key="1"
        >
          <Summary
            getDetails={getDetails}
            projectListing={projectListing}
            setGetDetails={setGetDetails}
            tenderBasicGetDetailsLoading={tenderBasicGetDetailsLoading}
            extensionData={extensionData}
            isUpdate={isUpdate}
            showChat={showChat}
            setExtensionData={setExtensionData}
            setShowChat={setShowChat}
            setIsUpdate={setIsUpdate}
            setSearchKeyword={setSearchKeyword}
            selectedTenderRowId={selectedTenderRowId}
            setSelectedTenderRowId={setSelectedTenderRowId}
            // searchDataAtBusinessHeaderForm={searchDataAtBusinessHeaderForm}
            dynamicFileTypesState={dynamicFileLabelTypes?.filter(
              (obj) => obj.code === "ADVDOC"
            )}
            dynamicTenderNoticeFileTypesState={dynamicFileLabelTypes?.filter(
              (obj) => obj.code === "TENNOTICE"
            )}
            dynamicTenderDocumentFileTypesState={dynamicFileLabelTypes?.filter(
              (obj) => obj.code === "OFFDOC"
            )}
            dynamicROTOFileTypesState={dynamicFileLabelTypes?.filter(
              (obj) => obj.code === "ROTO"
            )}
            dynamicExtensionFileTypesState={dynamicFileLabelTypes?.filter(
              (obj) => obj.code === "EXTNOTICE"
            )}
            // added
            tenderId={tenderId}
          />
        </TabPane>
        

        <TabPane
          tab="Companies"
          // key="3"
          key="2"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        >
          <Companies
            getDetails={getDetails}
            searchEmailTender={searchEmailTender}
            rowEventhandlers={rowEventhandlerscompany}
            companyId={contactCompanyId}
            emailReferenceId={emailReferenceId}
            selectedTenderRowId={selectedTenderRowId}
            isCircular={isCircularTabStatus}
            selectedBusinessTabPaneKey={selectedBusinessTabPaneKey}
          />
        </TabPane>

        <TabPane
          tab="Communication"
          // key="2"
          key="3"
        >
          <Communication
            setChatHeight={setChatHeight}
            searchEmailTender={searchEmailTender}
            chatHeight={chatHeight}
            height={height}
            getDetails={getDetails}
            selectedTenderRowId={selectedTenderRowId}
            // origin={mailOrigin}
            origin={mailOriginState}
          />
        </TabPane>

        <TabPane
          tab="Tasks"
          key="4"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        >
          <ToDo
            emailReferenceId={getDetails?.referenceId}
            getDetails={getDetails}
          />
        </TabPane>

        <TabPane
          tab="Document"
          key="5"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        >
          <TenderDocuments getDetails={getDetails} />
        </TabPane>

        <TabPane
          tab="Similar Tenders"
          key="6"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        >
          <SOWTable
            sows={getDetails?.scopeOfWork}
            details_with_sow={getDetails}
          />
        </TabPane>
        {/* <TabPane
          tab="Blog"
          key="7"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        >
          <Blog tenderId={tenderId} />
        </TabPane> */}
        <TabPane
          tab="Circular Planning"
          key="7"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        >
          <Companies
            getDetails={getDetails}
            searchEmailTender={searchEmailTender}
            rowEventhandlers={rowEventhandlerscompany}
            companyId={contactCompanyId}
            emailReferenceId={emailReferenceId}
            selectedTenderRowId={selectedTenderRowId}
            isCircular={isCircularTabStatus}
            selectedBusinessTabPaneKey={selectedBusinessTabPaneKey}
            selectedCompanyAttentionToIds={selectedCompanyAttentionToIds}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Tender;
