import { Tooltip, Typography } from "antd";
import moment from "moment";
import React from "react";
import { showNumber } from "utils/showNumber";
import { handleSpecialDate } from "utils/tooltipSepecialDate";

const getColumns = ({ widthh, modalShow }) => {
  return [
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "left",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Company
        </Typography.Paragraph>
      ),
      key: "type",
      dataIndex: "type",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            className="antd-paragraphg"
            style={{
              textAlign: "left",
              fontSize: widthh < 1550 ? 13 : 15,
              marginBottom: `0px !important`,
            }}
          >
            {record?.companyName}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          {/* B.Income */}
          Budget <br/> Income
        </Typography.Paragraph>
      ),
      key: "incomeOther",
      dataIndex: "incomeOther",
      render: (_, record) => (
        <Tooltip
          title={
            // moment(record?.incomeUpdatedDate).format("YYYY MMM DD")
            handleSpecialDate(record?.incomeUpdatedDate)
          }
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
            onDoubleClick={() => {
              modalShow(record?.budgetQueryParam, "budget", "expense", "B.Income");
            }}
          >
            {showNumber(record?.incomeBudget)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          {/* A.Income */}
          Actual <br/> Income
        </Typography.Paragraph>
      ),
      key: "income",
      dataIndex: "income",
      render: (_, record) => (
        <Tooltip
          title={
            // moment(record?.incomeUpdatedDate).format("YYYY MMM DD")
            handleSpecialDate(record?.incomeUpdatedDate)
          }
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
            onDoubleClick={() => {
              modalShow(record?.budgetQueryParam, "fee income", "income", "A.Income");
            }}
          >
            {showNumber(record?.income)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Variance{" "}
        </Typography.Paragraph>
      ),
      key: "incomeOther",
      dataIndex: "incomeOther",
      render: (_, record) => (
        <Tooltip
          title={
            // moment(record?.incomeUpdatedDate).format("YYYY MMM DD")
            handleSpecialDate(record?.incomeUpdatedDate)
          }
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
          // onDoubleClick={() => {
          //   modalShow(record?.budgetQueryParam, "total income");
          // }}
          >
            {showNumber(record?.incomeBudget - record?.income)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Budget <br/> Expense
        </Typography.Paragraph>
      ),
      key: "expense",
      dataIndex: "expense",
      render: (_, record) => (
        <Tooltip
          title={
            // moment(record?.expenseUpdatedDate).format("YYYY MMM DD")
            handleSpecialDate(record?.expenseUpdatedDate)
          }
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
            onDoubleClick={() => {
              modalShow(record?.budgetQueryParam, "expense", "expense", "B.Expense");
            }}
          >
            {showNumber(record?.budget)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Actual <br/> Expense
        </Typography.Paragraph>
      ),
      key: "budget",
      dataIndex: "budget",
      render: (_, record) => (
        <Tooltip
          title={
            // moment(record?.expenseUpdatedDate).format("YYYY MMM DD")
            handleSpecialDate(record?.expenseUpdatedDate)
          }
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
            onDoubleClick={() => {
              modalShow(record?.budgetQueryParam, "budget", "expense", "A.Expense");
            }}
          >
            {showNumber(record?.expense)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Variance{" "}
        </Typography.Paragraph>
      ),
      key: "budgetOther",
      dataIndex: "budgetOther",
      render: (_, record) => (
        <Tooltip
          title={
            // moment(record?.incomeUpdatedDate).format("YYYY MMM DD")
            handleSpecialDate(record?.incomeUpdatedDate)
          }
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
          // onDoubleClick={() => {
          //   modalShow(record?.budgetQueryParam, "total income");
          // }}
          >
            {showNumber(record?.budget - record?.expense)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{ fontWeight: "bold", textAlign: "right" }}
        >
          Inc-Exp
        </Typography.Paragraph>
      ),
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            fontSize: widthh < 1550 ? 13 : 15,
            fontWeight: "bold",
          }}
        >
          {showNumber(record?.income + record?.incomeOther - record?.expense)}
        </Typography.Paragraph>
      ),
    },
    // {
    //   title: (
    //     <Typography.Paragraph
    //       style={{ fontWeight: "bold", textAlign: "right" }}
    //     >
    //       Bud-Exp
    //     </Typography.Paragraph>
    //   ),
    //   dataIndex: "",
    //   key: "",
    //   render: (_, record) => (
    //     <Typography.Paragraph
    //       style={{
    //         textAlign: "right",
    //         fontSize: widthh < 1550 ? 13 : 15,
    //         fontWeight: "bold",
    //       }}
    //     >
    //       {showNumber(record?.budget - record?.expense)}
    //     </Typography.Paragraph>
    //   ),
    // },
  ];
};

export default getColumns;
