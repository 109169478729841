import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form, Button, Checkbox, Input, Layout, Select } from "antd";
import { validateMessages } from "utils/misc";
import { getAllRoles } from "services/redux/adminSettings/roles/action";
import { actionForuserRoleAddUpdate } from "services/redux/admin/FiscalYear/action";

function UpdateRole({ onAddUpdateRoleClose, roleDetails, drawerVisibility }) {
  const [form] = Form.useForm();
  const { Header, Footer } = Layout;
  const { Option } = Select;

  const dispatch = useDispatch();

  const { allRoleLists } = useSelector((state) => state.roleLists);
  const { updateAllRolesUserListResponseLoading } = useSelector(
    (state) => state.fiscalYearState
  );

  useEffect(() => {
    if (!roleDetails) {
      form.resetFields();
      return;
    }
    form.setFieldsValue({
      ...roleDetails,
    });
  }, [roleDetails]);

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (roleDetails) {
        dispatch(actionForuserRoleAddUpdate(values, roleDetails?.appUserId));
      } else {
        // dispatch(addNewRole(values));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    } finally {
      onAddUpdateRoleClose();
    }
  };

  return (
    <>
      <Drawer
        visible={drawerVisibility}
        width={472}
        maskClosable={false}
        className="drawer"
        onClose={onAddUpdateRoleClose}
        closable={false}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">
            {`${roleDetails ? "Update" : "Create"} Role`}
          </h4>
        </Header>
        <Form
          form={form}
          name="control-hooks"
          className="drawer-form-wrapper"
          validateMessages={validateMessages}
        >
          <div className="drawer-form-row">
            <Form.Item
              className="form__group"
              name="roleNames"
              label="Roles"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                autoComplete="off"
              >
                {allRoleLists?.items?.map((role) => (
                  <Option key={role?.id} label={role?.name} value={role.name}>
                    <span>{role.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="model-footer">
            <Button onClick={onAddUpdateRoleClose}>Cancel</Button>
            <Button
              onClick={onSubmit}
              type="primary"
              loading={updateAllRolesUserListResponseLoading}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateRole;
