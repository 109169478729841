import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  getContactCompanyPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { actionForAddUpdateCEADOtherInformation } from "services/redux/cead/action";
import OtherDetails from "./details";
import { EditOutlined } from "@ant-design/icons";

const { Title } = Typography;

export default function Others({
  companyOptions,
  contactPersonOptions,
  singleTenderCeadData,
  formOtherInfo,
}) {
  const dispatch = useDispatch();
  const [selectedPrincipalCompany, setSelectedPrincipalCompany] = useState();
  const [selectedPrincipalCompanyid, setSelectedPrincipalCompanyid] =
    useState();

  // useEffect(() => {
  //   if (!isEmpty(selectedPrincipalCompanyid)) {
  //     // let apiBody = {
  //     //   MaxResultCount: 50,
  //     //   SkipCount: 0,
  //     //   searchKeyword: selectedPrincipalCompany,
  //     // };
  //     let apiBody = {
  //       companyId: selectedPrincipalCompanyid,
  //     };
  //     dispatch(getContactPersonPagination(apiBody));
  //   }
  // }, [selectedPrincipalCompany]);
  useEffect(() => {
    if (
      !isEmpty(singleTenderCeadData?.principalId) ||
      !isEmpty(selectedPrincipalCompanyid)
    ) {
      let apiBody = {
        companyId:
          // singleTenderCeadData?.principalId || selectedPrincipalCompanyid,
          selectedPrincipalCompanyid
            ? selectedPrincipalCompanyid
            : singleTenderCeadData?.principalId,
      };
      dispatch(getContactPersonPagination(apiBody));
    }
  }, [singleTenderCeadData, selectedPrincipalCompanyid]);

  const onOtherInfoFinish = (values) => {
    let clientId_data =
      values?.clientId === singleTenderCeadData?.clientName
        ? singleTenderCeadData?.clientId
        : values?.clientId;

    let principalId_data =
      values?.principalId === singleTenderCeadData?.principalName
        ? singleTenderCeadData?.principalId
        : values?.principalId;

    let ceaD_CLIENT_ContactInformations_data = [];

    let ceaD_PRINCIPAL_ContactInformations_data = [];

    if (
      !isEmpty(
        singleTenderCeadData?.tenderCEADOtherInformationDto
          ?.contractExecutionContactInformations
      )
    ) {
      singleTenderCeadData?.tenderCEADOtherInformationDto?.contractExecutionContactInformations?.forEach(
        (element) => {
          values?.contractExecutionContactInformations?.map((y) => {
            if (element?.contactPersonName == y) {
              ceaD_CLIENT_ContactInformations_data.push(element?.contactId);
            } else {
              ceaD_CLIENT_ContactInformations_data.push(y);
            }
          });
        }
      );
    } else {
      values?.contractExecutionContactInformations?.map((y) => {
        ceaD_CLIENT_ContactInformations_data.push(y);
      });
    }

    if (
      !isEmpty(
        singleTenderCeadData?.tenderCEADOtherInformationDto
          ?.salesMarketingContactInformations
      )
    ) {
      singleTenderCeadData?.tenderCEADOtherInformationDto?.salesMarketingContactInformations?.forEach(
        (element) => {
          values?.salesMarketingContactInformations?.map((y) => {
            if (element?.contactPersonName === y) {
              ceaD_PRINCIPAL_ContactInformations_data.push(element?.contactId);
            } else {
              ceaD_PRINCIPAL_ContactInformations_data.push(y);
            }
          });
        }
      );
    } else {
      values?.salesMarketingContactInformations?.map((y) => {
        ceaD_PRINCIPAL_ContactInformations_data.push(y);
      });
    }

    let contractExecutionContactInformations =
      ceaD_CLIENT_ContactInformations_data?.map((x) => {
        return {
          contactId: x,
          isActive: true,
        };
      });

    let salesMarketingContactInformations =
      ceaD_PRINCIPAL_ContactInformations_data?.map((x) => {
        return {
          contactId: x,
          isActive: true,
        };
      });

    let apiData = {
      ...values,
      clientId: clientId_data,
      principalId: principalId_data,
      contractExecutionContactInformations,
      salesMarketingContactInformations,
    };

    dispatch(
      actionForAddUpdateCEADOtherInformation(apiData, singleTenderCeadData?.id)
    );
  };

  const [showOtherDetails, setShowOtherDetails] = useState(false);

  return (
    <>
      <Title level={5} style={{ background: "#DFDFDF" }}>
        Other Details{" "}
        {!showOtherDetails && (
          <span
            onClick={() => {
              setShowOtherDetails(!showOtherDetails);
            }}
            className="bid-pointer-cursor"
          >
            <EditOutlined />
          </span>
        )}
      </Title>

      {showOtherDetails ? (
        <Form
          onFinish={onOtherInfoFinish}
          labelCol={{ span: 7 }}
          form={formOtherInfo}
        >
          <Form.Item label="Principal" name="principalId">
            <Select
              options={companyOptions}
              optionFilterProp="label"
              placeholder="Select Incharge"
              allowClear
              showSearch
              onSearch={(value) => {
                let apiBody = {
                  MaxResultCount: 40,
                  SkipCount: 0,
                  searchKeyword: value,
                };
                // let apiBody = {
                //   companyId: selectedPrincipalCompanyid,
                // };
                dispatch(getContactCompanyPagination(apiBody));
              }}
              onChange={(value, label) => {
                setSelectedPrincipalCompany(label?.label);
                setSelectedPrincipalCompanyid(value);
              }}
            />
          </Form.Item>

          <Form.Item label="Client" name="clientId">
            <Select
              options={companyOptions}
              optionFilterProp="label"
              placeholder="Select Incharge"
              allowClear
              showSearch
              onSearch={(value) => {
                let apiBody = {
                  MaxResultCount: 40,
                  SkipCount: 0,
                  searchKeyword: value,
                };
                dispatch(getContactCompanyPagination(apiBody));
              }}
              // onChange={(value, label) => {
              //   setSelectedPrincipalCompany(label?.label);
              // }}
            />
          </Form.Item>

          <Form.Item
            label="Contract Execution Contact Person"
            name="contractExecutionContactInformations"
          >
            <Select
              mode="multiple"
              options={contactPersonOptions}
              placeholder="Select Incharge"
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Sales Marketing Contact Person"
            name="salesMarketingContactInformations"
          >
            <Select
              mode="multiple"
              options={contactPersonOptions}
              placeholder="Select Incharge"
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Construction Schedule Included"
            name="isConstructionScheduleIncluded"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Reporting Requirement to Principal"
            name="reportingRequirementToPrinciple"
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Major Services/Obligation to Principal as per Agreement"
            name="majorServicesToPrincipleAgreement"
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Construction Documents"
            name="isConstructionDocuments"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item label="Additional Remarks" name="additionalRemarks">
            <Input.TextArea />
          </Form.Item>

          {/* <Form.List name="contractExecutionContactInformations">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} gutter={[10, 0]}>
                  <Col span={1}>{index + 1}</Col>

                  <Col span={6}>
                    <Form.Item
                      key={field.key}
                      label="Contract Execution Contact Person"
                      name={[field.name, "contactId"]}
                      fieldKey={[field.fieldKey, "contactId"]}
                      rules={[
                        {
                          required: true,
                          message: "contactPerson is required",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Select
                        options={contactPersonOptions}
                        optionFilterProp="label"
                        placeholder="Select"
                        allowClear
                        // showSearch
                        // onSearch={(value) => {
                        //   let apiBody = {
                        //     MaxResultCount: 40,
                        //     SkipCount: 0,
                        //     searchKeyword: value,
                        //   };
                        //   dispatch(getContactCompanyPagination(apiBody));
                        // }}
                        // onChange={(value, label) => {
                        //   setSelectedClientCompany(label?.label);
                        //   setSelectedPrincipalCompany();
                        // }}
                        // onFocus={() => {
                        //   let apiBody = {
                        //     MaxResultCount: 40,
                        //     SkipCount: 0,
                        //     searchKeyword: singleTenderCeadData?.clientName,
                        //   };
                        //   dispatch(getContactCompanyPagination(apiBody));
                        // }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item
                      label="Email"
                      name={[field.name, `email`]}
                      fieldKey={[field.fieldKey, "email"]}
                      rules={[
                        {
                          required: true,
                          message: "Contract Amount is required",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input placeholder="Phone" />
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item
                      label="Phone"
                      name={[field.name, `phone`]}
                      fieldKey={[field.fieldKey, "phone"]}
                      rules={[
                        {
                          required: true,
                          message: "Contract Amount is required",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input placeholder="Phone" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    {index >= 0 && (
                      <Button
                        style={{ float: "right", marginTop: "-12px" }}
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </Col>
                </Row>
              ))}
              <Button
                style={{ marginBottom: "16px" }}
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusCircleOutlined />}
              >
                Add Contract Execution Contact Person
              </Button>
            </>
          )}
        </Form.List> */}

          {/* <Form.List name="salesMarketingContactInformations">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} gutter={[10, 0]}>
                  <Col span={1}>{index + 1}</Col>

                  <Col span={6}>
                    <Form.Item
                      key={field.key}
                      label="Sales/Marketing Contact Person"
                      name={[field.name, "contactId"]}
                      fieldKey={[field.fieldKey, "contactId"]}
                      rules={[
                        {
                          required: true,
                          message: "contactPerson is required",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Select
                        //   options={currencyOptions}
                        optionFilterProp="label"
                        placeholder="Select"
                        allowClear
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item
                      label="Email"
                      name={[field.name, `email`]}
                      fieldKey={[field.fieldKey, "email"]}
                      rules={[
                        {
                          required: true,
                          message: "Contract Amount is required",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input placeholder="Phone" />
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item
                      label="Phone"
                      name={[field.name, `phone`]}
                      fieldKey={[field.fieldKey, "phone"]}
                      rules={[
                        {
                          required: true,
                          message: "Contract Amount is required",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input placeholder="Phone" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    {index >= 0 && (
                      <Button
                        style={{ float: "right", marginTop: "-12px" }}
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </Col>
                </Row>
              ))}
              <Button
                style={{ marginBottom: "16px" }}
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusCircleOutlined />}
              >
                Add Sales/Marketing Contact Person
              </Button>
            </>
          )}
        </Form.List>

        <Form.Item label="Construction Schedule included">
          <Checkbox />
        </Form.Item>

        <Form.Item
          label="Reporting Requirement To Principal"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.TextArea placeholder="Comment your reports" />
        </Form.Item>

        <Form.Item
          label="Major Services/Obligation To Principal as per Agreement"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.TextArea placeholder="Comment your reports" />
        </Form.Item>

        <Form.Item label="Construction Documents">
          <Checkbox />
        </Form.Item>

        <Form.Item
          label="Additional Remarks"
          placeholder="Comment your reports"
        >
          <Input.TextArea placeholder="Comment your reports" />
        </Form.Item> */}
          <Row style={{ float: "right" }}>
            <Col style={{ marginTop: "10px", marginRight: "10px" }}>
              <Button
                onClick={() => {
                  setShowOtherDetails(false);
                }}
              >
                Cancel
              </Button>
            </Col>

            <Col style={{ marginTop: "10px", marginRight: "10px" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <OtherDetails
          otherDetails={singleTenderCeadData?.tenderCEADOtherInformationDto}
        />
      )}
    </>
  );
}
