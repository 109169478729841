import { Button, Col, Form, Input, Modal, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendLateLoginMessage } from "services/redux/email/instanceMessageCenter/action";

const AbsentModal = ({ modalShow, setModalShow, record }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { lateLoginSmsLoading } = useSelector(
    (state) => state.instanceMessageCenter
  );

  const onFinish = (values) => {
    const body = {
      smsBody: values.smsBody,
      participantUserIds: [record?.userId],
    };
    dispatch(sendLateLoginMessage(body)).then(() => {
      setModalShow(false);
    });
  };

  return (
    <Modal
      title={`Send SMS to ${record?.fullName} for No login`}
      visible={modalShow}
      footer={false}
      closable={false}
    >
      <Form form={form} onFinish={onFinish}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Row style={{ paddingBottom: "10px" }}>SMS body</Row>
            <Form.Item name='smsBody' style={{ width: "100%" }}>
              <Input.TextArea autoSize={{ minRows: 4 }} />
            </Form.Item>
          </Col>

          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
          >
            <Button
              style={{ marginRight: "10px" }}
              onClick={() => {
                setModalShow(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type='primary '
              htmlType='submit'
              style={{ paddingTop: "5px" }}
              loading={lateLoginSmsLoading}
            >
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AbsentModal;
