import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Input, Col, Row, Tooltip, message } from "antd";
import { LogoImage } from "utils/imageConstants";
import { isEmpty, set } from "lodash";
import { contactFavoritePersonAddUpdate } from "services/remanentCalls";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import moment from "moment";
import { complaintAdd } from "services/redux/complaint/action";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";

const ErrorMessagePagination = ({ errorMessage }) => {
  const { profileInfo } = useSelector((state) => state.profile);
  const statusListingState = useSelector((state) => state.statusListing);
  const { allEventStatusListing } = statusListingState;

  const [reportButton, setReportButton] = useState("Report");
  const statusId = allEventStatusListing?.[1]?.id;
  const employeeName =
    profileInfo?.employeePersonalDetailUpdateDto?.employeeName;
  const columns = [
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: "18%",
    },

    {
      title: "Date",
      key: "lastModificationTime",
      dataIndex: "lastModificationTime",
      render: (errorMessage) => (
        <div>
          <span className="merge--contact">
            {moment(errorMessage).format("DD/MM/YYYY ,h:mm:ss a")}
          </span>
        </div>
      ),
      width: "18%",
    },
    {
      title: "Message",
      key: "message",
      dataIndex: "message",
    },
    {
      title: "Report",
      key: "report",
      render: (errorMessage) => (
        <div className="report_button">
          <button
            className="button_report"
            onClick={() => {
              let messageValue = errorMessage;
              let submitValue = {
                issueName: employeeName,
                remarks: messageValue?.message,
                statusId: statusId,
                title: "MailBox",
              };
              dispatch(complaintAdd(submitValue));
              // setReportButton("Reported")
            }}
          >
            {reportButton}
          </button>
        </div>
      ),
      width: "7%",
    },
  ];
  const dispatch = useDispatch();

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const pageSize = 12;
  useEffect(() => {
    if (isEmpty(allEventStatusListing)) {
      dispatch(getStatusEventListing());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(errorMessage)) {
      setdata(errorMessage?.emailErrorLogListDtos);
      setTotalCount(errorMessage?.totalCount);

      // let newCurrentPage =
      // errorMessage.items.length / pageSize;
      // setCurrentPage(newCurrentPage);
    }
  }, [errorMessage]);

  // console.log("heyPagination", errorMessage);

  //   const getData = (
  //     MaxResultCount = pageSize,
  //     SkipCount = 0,
  //     // Sorting,
  //     // SortType,
  //     SearchKeyword,
  //     ForPagination = false
  //   ) => {
  //     {
  //       dispatch(
  //         getContactPersonPagination(
  //           {
  //             MaxResultCount,
  //             SkipCount,
  //             // Sorting,
  //             // SortType,
  //             SearchKeyword: searchPersonCompany,
  //             CompanyId: contactCompanyId
  //               ? contactCompanyId
  //               : "00000000-0000-0000-0000-000000000000",
  //           },
  //           ForPagination
  //         )
  //       );
  //     }
  //   };

  //   const handleTableChange = (pagination, filters, sorter, extra) => {
  //   	let Sorting = sorter.columnKey;
  //   	let SortType = sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : undefined;
  //   	let MaxResultCount = pageSize;
  //   	let SkipCount = (pagination.current - 1) * pagination.pageSize;

  //   	getData(MaxResultCount, SkipCount, Sorting, SortType);
  //   	// getData(pagination.current, pagination.pageSize);
  //   };

  //   useEffect(() => {
  //     var tableContent = document.querySelector(".person-table .ant-table-body");
  //     tableContent.addEventListener("scroll", (event) => {
  //       let maxScroll = event.target.scrollHeight - event.target.clientHeight;
  //       let currentScroll = event.target.scrollTop;
  //       if (currentScroll === maxScroll) {
  //         // load more data
  //         let MaxResultCount = pageSize;
  //         let SkipCount = currentPage * pageSize;

  //         if (
  //           contactPersonPaginationResponse?.totalCount > SkipCount ||
  //           !contactPersonPaginationResponse
  //         ) {
  //           getData(MaxResultCount, SkipCount, searchPersonCompany, true);
  //         }
  //       }
  //     });
  //   }, [currentPage, contactPersonPaginationResponse]);
  return (
    <>
      {console.log("data here", data)}
      <Table
        className="organizer__table person-table"
        columns={columns}
        sticky={true}
        rowKey="appUserId"
        dataSource={data}
        scroll={{ y: 1300 }}
        // onChange={handleTableChange}
        // loading={contactPersonPaginationLoading}

        pagination={false}
      />
    </>
  );
};

export default ErrorMessagePagination;
