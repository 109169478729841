import React from "react";

const LoanDetailsList = ({ list }) => {
  return (
    <>
      <span style={{ paddingLeft: 25 }}>
        Status: {list.applicationStatusName}
      </span>
      <br />
      <span style={{ paddingLeft: 25 }}>
        Amount: {list?.loanRequestedAmount?.toLocaleString()}
      </span>
      <br />
      <span style={{ paddingLeft: 25 }}>
        Reason:
        {list?.loanReason}
      </span>
    </>
  );
};

export default LoanDetailsList;
