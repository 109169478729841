import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Tag, Tooltip } from "antd";
import { isEmpty } from "lodash-es";
import { EditOutlined } from "@ant-design/icons";
import {
  getAllUsersListing,
  getUserReportingManagerUserListing,
} from "services/redux/admin/FiscalYear/action";

function UpdateRoleView({
  type,
  grantedPolices,
  editRoleDetails,
  viewPermissionDetails,
}) {
  const dispatch = useDispatch();
  const {
    getAllUsersListResponse,
    getAllUsersListResponseLoading,
    getAllRolesUsersListResponse,
    getAllRolesUsersListResponseLoading,
  } = useSelector((state) => state.fiscalYearState);

  const paginationConfig = {
    pageSize: 20,
  };

  useEffect(() => {
    let value = {
      department: type,
    };
    if (!isEmpty(type)) {
      dispatch(getUserReportingManagerUserListing(value));
    }
  }, [type, dispatch]);

  const columns = [
    {
      title: "S.N",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Role",
      // dataIndex: "roleNames",
      key: "roleNames",
      render: (record) => (
        <>
          {/* {record.map((tag) => (
            <Tag
              color="volcano"
              key={tag}
              onClick={() => viewPermissionDetails(tag)}
            >
              {tag}
            </Tag>
          ))} */}
          {record?.appRoleDtos
            ? record.appRoleDtos
                .filter((item) => item?.name)
                .map((item) => (
                  <Tag
                    color="volcano"
                    key={item.name}
                    onClick={() => viewPermissionDetails(item.name)}
                  >
                    <Tooltip title={item?.description}>{item.code}</Tooltip>
                  </Tag>
                ))
            : []}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        // <Space size="middle">
        <>
          {!isEmpty(grantedPolices) &&
            grantedPolices["AbpIdentity.Roles.Update"] && (
              <div
                className="line-spacing-role"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <a
                  className="action-btn action-btn-add"
                  onClick={() => editRoleDetails(record)}
                >
                  <EditOutlined />
                </a>
              </div>
            )}
          {/* </Space> */}
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="all-header-wrapper organizer meetings">
        <div className="meeting__flex">
          <h3 className="header__all">{type} Role</h3>
        </div>
      </div>
      <Table
        className="organizer__tables"
        columns={columns}
        dataSource={getAllRolesUsersListResponse}
        loading={getAllRolesUsersListResponseLoading}
        pagination={paginationConfig}
      />
    </div>
  );
}

export default UpdateRoleView;
