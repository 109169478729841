import { HOLIDAY_TYPES } from "services/types";

import { HolidayService } from "./api";
import { init, success, finish, error } from "services/common";
import { message } from "antd";

const holidayEvent = new HolidayService();

export const holidayEventBulkAddUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(HOLIDAY_TYPES.HOLIDAY_ADD));
    const response = await holidayEvent.holidayEventBulkAddUpdate(body);
    dispatch(finish(HOLIDAY_TYPES.HOLIDAY_ADD));
    if (response.isSuccess) {
      message.success("Holiday Added");
      dispatch(holidayEventsPagination());
      dispatch(success(HOLIDAY_TYPES.HOLIDAY_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const holidayEventsPagination = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(HOLIDAY_TYPES.HOLIDAY_PAGINATION));
    const response = await holidayEvent.holidayEventsPagination(query);
    dispatch(finish(HOLIDAY_TYPES.HOLIDAY_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(HOLIDAY_TYPES.HOLIDAY_PAGINATION, response.data, loadMore)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForHolidaySampleData = () => {
  return async (dispatch) => {
    dispatch(init(HOLIDAY_TYPES.HOLIDAY_SAMPLE_DATA));
    const response = await holidayEvent.holidaySampleDataDownload();
    if (response.isSuccess) {
      dispatch(success(HOLIDAY_TYPES.HOLIDAY_SAMPLE_DATA, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(HOLIDAY_TYPES.HOLIDAY_SAMPLE_DATA));
  };
};

export const actionForExportSiscoUserListToExcel = () => {
  return async (dispatch) => {
    dispatch(init(HOLIDAY_TYPES.EXPORT_SISCO_USER_LIST));
    const response = await holidayEvent.exportSiscoUserListToExcel();
    if (response.isSuccess) {
      dispatch(success(HOLIDAY_TYPES.EXPORT_SISCO_USER_LIST, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(HOLIDAY_TYPES.EXPORT_SISCO_USER_LIST));
  };
};

export const actionForExportCompanyUserListToExcel = () => {
  return async (dispatch) => {
    dispatch(init(HOLIDAY_TYPES.EXPORT_COMPANY_USER_LIST));
    const response = await holidayEvent.exportCompanyUserListToExcel();
    if (response.isSuccess) {
      dispatch(success(HOLIDAY_TYPES.EXPORT_COMPANY_USER_LIST, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(HOLIDAY_TYPES.EXPORT_COMPANY_USER_LIST));
  };
};
