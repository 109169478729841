import {
  ADMIN_SETTINGS_TYPES,
  ORGANIZATION_STRUCTURE_TYPES,
} from "../../../types";
import { init, success, finish, error } from "services/common";
import { OrganizationLayerService } from "./api";
import { message } from "antd";

const organizationLayerService = new OrganizationLayerService();

export const getOrganizationLayerListing = () => {
  return async (dispatch) => {
    dispatch(init(ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_LISTING));
    const response =
      await organizationLayerService.getOrganizationLayerListing();
    dispatch(finish(ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_LISTING, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getOrganizationLayerPagination = (q) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_PAGINATION));
    const response =
      await organizationLayerService.getOrganizationLayerPagination(q);
    dispatch(
      finish(ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_PAGINATION)
    );
    if (response.isSuccess) {
      dispatch(
        success(
          ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_PAGINATION,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addOrganizationLayer = (body) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_ADD));
    const response = await organizationLayerService.addOrganizationLayer(body);
    dispatch(finish(ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_ADD));
    if (response.isSuccess) {
      dispatch(
        success(
          ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_ADD,
          response.data
        )
      );
      dispatch(getOrganizationLayerPagination(`maxResultCount=10&skipCount=0`));
      message.success("Organization Layer added successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateOrganizationLayer = (body, id) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_UPDATE));
    const response = await organizationLayerService.updateOrganizationLayer(
      body,
      id
    );
    dispatch(finish(ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_UPDATE));
    if (response.isSuccess) {
      dispatch(
        success(
          ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_UPDATE,
          response.data
        )
      );
      dispatch(getOrganizationLayerPagination(`maxResultCount=10&skipCount=0`));
      message.success("Organization Layer updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const appointmentMarkAsComplete = (id) => {
//   return async (dispatch) => {
//     dispatch(init(ORGANIZER_TYPES.APPOINTMENT_COMPLETE));
//     const response = await roleService.appointmentMarkAsComplete(id);
//     dispatch(finish(ORGANIZER_TYPES.APPOINTMENT_COMPLETE));
//     if (response.isSuccess) {
//       dispatch(success(ORGANIZER_TYPES.APPOINTMENT_COMPLETE, response.data));
//       dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
//       let status = response.data.markItOff ? 'complete' : 'incomplete';
//       message.success(`Appointment marked as ${status} successfully`);
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

export const getAllDepartments = (type) => {
  return async (dispatch) => {
    dispatch(
      init(ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_DEPARTMENT_PAGINATION)
    );
    const response = await organizationLayerService.departmentListing(type);
    dispatch(
      finish(ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_DEPARTMENT_PAGINATION)
    );
    if (response.isSuccess) {
      dispatch(
        success(
          ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_DEPARTMENT_PAGINATION,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addUpdateDepartment = (body, updateData, deleteItem) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZATION_STRUCTURE_TYPES.ADD_UPDATE_DEPARTMENT));
    const response = await organizationLayerService.departmentAddUpdate(body);
    if (response.isSuccess) {
      if (updateData) {
        message.success("Data is updated sucessfully");
        dispatch(
          success(ORGANIZATION_STRUCTURE_TYPES.ADD_UPDATE_DEPARTMENT, {
            ...response.data,
            operation: "update",
          })
        );
      } else if (deleteItem) {
        message.success("Data is deleted sucessfully");
        dispatch(
          success(ORGANIZATION_STRUCTURE_TYPES.ADD_UPDATE_DEPARTMENT, {
            ...response.data,
            operation: "delete",
          })
        );
      } else {
        message.success("Department added successfully");
        dispatch(
          success(ORGANIZATION_STRUCTURE_TYPES.ADD_UPDATE_DEPARTMENT, {
            ...response.data,
            operation: "add",
          })
        );
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getAllRemainder = () => {
  return async (dispatch) => {
    dispatch(init(ORGANIZATION_STRUCTURE_TYPES.REMAINDER_LISTING));
    const response = await organizationLayerService.getRemainderListing();
    dispatch(finish(ORGANIZATION_STRUCTURE_TYPES.REMAINDER_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(ORGANIZATION_STRUCTURE_TYPES.REMAINDER_LISTING, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
