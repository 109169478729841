import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  Layout,
  message,
  Select,
} from 'antd';
import { getSisterCompaniesListings } from 'services/redux/admin/sisterCompanyListing/action';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  corporateEmailAdd,
  corporateEmailPermissionAdd,
  emailShareGetDetails,
} from 'services/redux/admin/EmailShare/action';
import { getAllUsers } from 'services/remanentCalls';

export default function EmailShareDrawer({
  type,
  setOpenDrawer,
  openDrawer,
  openNewDrawer,
  setOpenNewDrawer,
  permissionData,
  setPermissionData,
  newPermissionData,
  setNewPermissionData,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;

  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);

  const {
    corporateEmailAddResponse,
    corporateEmailAddResponseLoading,
    emailShareGetDetailsResponse,
  } = useSelector((state) => state.emailShare);

  const { Header, Footer } = Layout;
  const [messageParticipants, setMessageParticipants] = useState([]);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error('Failure');
    });

    if (resp && resp.status === 200) {
      setMessageParticipants(resp.data);
    }
  };

  const onSubmit = (values) => {
    let body = { ...values, isActive: true };
    // console.log('Second here', body);
    if (!isEmpty(permissionData)) {
      let body = {
        ...values,
        employeeEmailShareAccessDtos: [
          {
            id: permissionData?.id,
            employeeId: values?.participantTos,
            isActive: true,
          },
        ],
      };
      let id = permissionData?.id;
      dispatch(corporateEmailPermissionAdd(body, id, type));
    } else {
      dispatch(corporateEmailAdd(body, form));
    }
    setOpenDrawer(false);
    setPermissionData('');
  };

  useEffect(() => {
    if (isEmpty(sisterCompanyListing)) {
      dispatch(getSisterCompaniesListings());
    }
    fetchAllParticipants();
  }, []);

  useEffect(() => {
    if (isEmpty(corporateEmailAddResponse)) {
      setOpenDrawer(false);
    }
  }, [corporateEmailAddResponse]);

  useEffect(() => {
    if (isEmpty(corporateEmailAddResponse)) {
      setOpenNewDrawer(false);
    }
  }, [corporateEmailAddResponse]);

  useEffect(() => {
    if (!isEmpty(permissionData)) {
      dispatch(emailShareGetDetails(permissionData?.id, type));
    }
  }, [permissionData]);

  useEffect(() => {
    if (!isEmpty(permissionData)) {
      form.setFieldsValue({
        sisterCompanyId: emailShareGetDetailsResponse?.sisterCompanyId,
        emailAddress: emailShareGetDetailsResponse?.emailAddress,
        password: emailShareGetDetailsResponse?.password,
        isSmtp: emailShareGetDetailsResponse?.isSmtp,
        isImap: emailShareGetDetailsResponse?.isImap,
        participantTos:
          emailShareGetDetailsResponse?.employeeEmailShareAccessDtos?.map(
            (data) => data?.employeeId
          ),
      });
    }
  }, [permissionData, emailShareGetDetailsResponse]);

  useEffect(() => {
    if (!isEmpty(newPermissionData)) {
      form.setFieldsValue({
        participantTos:
          emailShareGetDetailsResponse?.employeeEmailShareAccessDtos?.map(
            (data) => data?.employeeId
          ),
      });
    }
  }, [newPermissionData, emailShareGetDetailsResponse]);

  return (
    <div>
      <Drawer
        visible={openDrawer}
        width={472}
        maskClosable={false}
        closable={false}
        className='drawer main-drawer-wrapper form_email_share'
      >
        <Form
          onFinish={onSubmit}
          form={form}
          name='control-hooks'
          className='add__user__form'
        >
          <div className='drawer-form-row'>
            <div className='drawer-left'>
              <div className='drawer-form-group'>
                <Form.Item
                  label='Select Sister Company'
                  className='form__group margin-select-placeholder'
                  name={'sisterCompanyId'}
                  rules={[
                    {
                      required: true,
                      message: 'Missing Sister Company',
                    },
                  ]}
                >
                  <Select placeholder='Select sister company'>
                    {sisterCompanyListing.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className='drawer-form-group'>
                <div className='drawer-flex'>
                  <Form.Item
                    label='Email Address'
                    className='form__group'
                    name={'emailAddress'}
                    rules={[
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ]}
                  >
                    <Input placeholder='Email Address' autoComplete='off' />
                  </Form.Item>
                  <Form.Item
                    label='Password'
                    className='form__group'
                    name={'password'}
                    rules={[
                      {
                        required: true,
                        message: 'Missing type',
                      },
                    ]}
                  >
                    <Input.Password placeholder='Password' autoComplete='off' />
                  </Form.Item>
                </div>
              </div>
              <div
                className='drawer-form-group'
                style={{ marginTop: 10, display: 'flex' }}
              >
                <Form.Item
                  className='form__group'
                  name={'isSmtp'}
                  initialValue={false}
                  valuePropName='checked'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Checkbox className='form__group'>
                    <label>SMTP</label>
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  className='form__group'
                  name={'isImap'}
                  initialValue={false}
                  valuePropName='checked'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Checkbox className='form__group'>
                    <label>IMAP</label>
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  className='form__group'
                  name={'isActive'}
                  initialValue={true}
                  valuePropName='checked'
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Checkbox className='form__group'>
                    <label>Is Active</label>
                  </Checkbox>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className='model-footer'>
            <Button
              onClick={() => {
                setOpenDrawer(false);
                setPermissionData('');
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            {isEmpty(permissionData) ? (
              <Button
                type='primary'
                htmlType='submit'
                loading={corporateEmailAddResponseLoading}
              >
                Submit
              </Button>
            ) : (
              <Button type='primary' htmlType='submit'>
                Update
              </Button>
            )}
          </div>
        </Form>
      </Drawer>
    </div>
  );
}
