import React, { useState, useEffect } from "react";
import {
  Collapse,
  Form,
  DatePicker,
  TimePicker,
  Button,
  Layout,
  Drawer,
  Input,
  Popover,
} from "antd";
import DoubleStage from "./DoubleStage";
import SingleStage from "./SingleStage";
import RenderStageView from "./StagesView";
import { isEmpty } from "lodash";
import { update } from "lodash";
import Participation from "../../Participation";

const Stages = ({
  showStages,
  allCurrencyListingResponse,
  allCountryListingResponse,
  sisterCompanyListing,
  getDetails,
  changetest,
  doubleStageForm,
  singleStageForm,
  documentPurchaseForm,
  showParticipation,
  setShowParticipation,
  editStages,
  addUpdateOfficialInviation,
  getDayDiffSecurity,
  tenderTypeListingResponse,
  setEditForm,
  setShowStages,
  setShowSingleStageEdit,
  setOfficialForm,
  participationForm,
  setShowDoubleStage,
  showDoubleStages,
  dynamicTenderNoticeFileTypesState,
  dynamicTenderDocumentFileTypesState,
  dynamicROTOFileTypesState,
  setDocumentOffFile,
  setSubmissionDate,
  setOpeningDate,
}) => {
  const { Panel } = Collapse;
  const CheckMark = "assets/svg/checkmark-24.svg";
  const cancel = "assets/svg/cross-24.svg";
  //   const dispatch = useDispatch();

  // console.log("editStages", showStages);
  const update = () => (
    <div
      className="business_action_button_wrapper"
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <Button
        className="cancleAction"
        onClick={() => {
          setShowSingleStageEdit(false);
          setShowDoubleStage(false);
        }}
      >
        Cancel
      </Button>
      <Button
        className="saveAction"
        // loading={participationAddLoading || participationUpdateLoading}
        onClick={() => {
          addUpdateOfficialInviation();
        }}
      >
        Save
      </Button>
    </div>
  );

  return (
    <>
      {showStages === "singleStages" || showStages === "doubleStages" ? (
        <>
          <div className="business-header">
            <div className="business-header-title">
              {`${
                showStages === "doubleStages"
                  ? `${
                      getDetails.tenderProcessType === 0 &&
                      getDetails?.tenderStageLevelDtos
                        ? "Stage 1"
                        : getDetails.tenderProcessType === 2 &&
                          isEmpty(getDetails?.tenderStageLevelDtos)
                        ? "Stage 1"
                        : "Stage 2"
                    }
                    `
                  : ""
              }`}
            </div>
          </div>
          <SingleStage
            allCurrencyListingResponse={allCurrencyListingResponse}
            allCountryListingResponse={allCountryListingResponse}
            sisterCompanyListing={sisterCompanyListing}
            getDetails={getDetails}
            changetest={changetest}
            singleStageForm={singleStageForm}
            documentPurchaseForm={documentPurchaseForm}
            getDayDiffSecurity={getDayDiffSecurity}
            setEditForm={setEditForm}
            setShowParticipation={setShowParticipation}
            dynamicTenderNoticeFileTypesState={
              dynamicTenderNoticeFileTypesState
            }
            dynamicTenderDocumentFileTypesState={
              dynamicTenderDocumentFileTypesState
            }
            showParticipation={showParticipation}
            dynamicROTOFileTypesState={dynamicROTOFileTypesState}
            setDocumentOffFile={setDocumentOffFile}
            setSubmissionDate={setSubmissionDate}
            setOpeningDate={setOpeningDate}
          />
          <div>{!isEmpty(getDetails) && update()}</div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Stages;
