import React, { useEffect } from 'react';
import { Drawer, Button, Form, Input, Layout } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import {
    actionForAddWhiteAccessCreateIpAddress,
    actionForUpdateWhiteAccessUpdateIpAddress
} from "services/redux/admin/WhitelistIPAddress/action";



const AddUpdateIpDrawer = (props) => {
    const {
        isOpen,
        onClose,
        addUpdateIp,
        setAddUpdateIp
    } = props

    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const { Header, Footer } = Layout;

    useEffect(() => {
        if (addUpdateIp?.isUpdateIp) {
            form.setFieldsValue({
                id: addUpdateIp.data?.id,
                ipAddress: addUpdateIp.data?.ipAddress,
                description: addUpdateIp.data?.description,
                isActive: addUpdateIp.data?.isActive,
            });
        } else {
            form.resetFields();
        }
    }, [addUpdateIp, form]);


    const onFinish = async (values) => {
        let payload;

        try {
            if (addUpdateIp?.isAddIp == true) {
                payload = {
                    ipAddress: values?.ipAddress,
                    description: values?.description,
                    isActive: true,
                }
                dispatch(actionForAddWhiteAccessCreateIpAddress(payload));
            }
            else if (addUpdateIp?.isUpdateIp == true) {
                payload = {
                    id: addUpdateIp.data?.id,
                    ipAddress: values?.ipAddress,
                    description: values?.description,
                    isActive: addUpdateIp.data?.isActive,
                }
                dispatch(actionForUpdateWhiteAccessUpdateIpAddress(addUpdateIp.data?.id, payload));
            }
            else {
                return ''
            }
        } catch (err) {
            console.log("error: ", err)
        } finally {
            onClose();
        }
    }

    const handleDeleteIp = () => {
        let payload = {
            ...addUpdateIp?.data,
            isActive: false,
        }

        try {
            if (addUpdateIp.data)
                dispatch(actionForUpdateWhiteAccessUpdateIpAddress(addUpdateIp.data?.id, payload));
        }
        catch (err) {
            console.log("error: ", err)
        } finally {
            onClose();
        }
    }


    return (
        <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={isOpen}
            width={400}
        >
            <Header className="drawer__header">
                <h4 className="drawer__header--title">
                    {addUpdateIp?.isUpdateIp ? "Update" : "Add"} IP Address
                </h4>
            </Header>
            <Form
                form={form}
                onFinish={onFinish}
                name="control-hooks"
                className="drawer-form-wrapper"
            >
                <div className="drawer-form-row">
                    <div className="drawer-form-group">
                        <Form.Item
                            className="form__group"
                            name="ipAddress"
                            label="Ip Address"
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </div>
                <div className="drawer-form-row">
                    <div className="drawer-form-group">
                        <Form.Item
                            className="form__group"
                            name="description"
                            label="Description"
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </div>
                <div className="model-footer">
                    <Button onClick={handleDeleteIp}>Delete</Button>
                    <Button onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </Drawer>
    )
}
export default AddUpdateIpDrawer;