import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  message,
  Space,
  DatePicker,
  Layout,
  Image,
  InputNumber,
  Modal,
  Skeleton,
  Checkbox,
  AutoComplete,
  Spin,
} from "antd";
import { validateMessages } from "utils/misc";
import moment from "moment";
import {
  PlusOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  StarFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  getMaritalStatusList,
  getGenders,
} from "services/redux/profile/profile.action";
import {
  contactPersonAdd,
  contactPersonUpdate,
  deletePerson,
} from "services/redux/contact/companypeople/action";
import { allCountryListing } from "services/redux/contact/contact.action";
import { AddCompanyDrawer } from "./AddUpdateCompanyDrawer";
import Loader from "../../../components/Loader";
import Expert from "./Expertise";
import { BroadcastChannel } from "broadcast-channel";

import {
  searchTagListing,
  searchTagAdd,
  contactLabelGroupListing,
  contactLabelListing,
  countryListing,
  cityListing,
  dynamicFileLabelTypeUploaderDetailDtos,
  contactSourceListing,
  qualificationListing,
} from "services/remanentCalls";

import ContactImageUploader from "../../../components/shared/ContactImageUploader";
import { contactPersonGetDetails } from "services/redux/contact/companypeople/action";
import { cloneDeep, groupBy, isEmpty } from "lodash";
import DynamicImageUploader from "components/shared/DynamicImageUploader";
import { useLocation } from "react-router";
import querString from "query-string";

//components
import QuickAddCompany from "./AddQuickCompany";

import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";
import { Editor } from "draft-js";
import { ApplicationConfigurationService } from "services/redux/common/applicationConfiguration/api";

const { Option } = Select;
const { Header, Footer } = Layout;
const { confirm } = Modal;

const AddPersonDrawer = ({ setEditPerson, editPerson, form, form1 }) => {
  const [filtercities, setFilteredCities] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [grantedPolices, setGrantedPolices] = useState({});

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [value, setValue] = useState("");
  const [tagLists, setTagLists] = useState([]);
  const [contactLabelGroup, setContactLabelGroup] = useState([]);
  const [contactLabel, setContactLabel] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [uploaders, setUploaders] = useState([]);
  const [uploadersExceptProfile, setUploadersExceptProfile] = useState([]);
  const [filterCitiesLists, setFilterCitiesLists] = useState([]);
  const [dropdownscope, setdropdownscope] = useState(false);
  const [dropdowntag, setdropdowntag] = useState(false);
  const [contactSources, setContactSources] = useState([]);
  const [groupedByDocuments, setGroupedByDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [qualificationData, setQualificationData] = useState([]);
  const [showExpertModule, setShowExpertModule] = useState(false);
  const [companyCurrentPage, setCompanyCurrentPage] = useState(1);
  const [adDocs, setAdDocs] = useState([]);
  //visible modal
  const [visibleModal, setVisibleModal] = useState(false);

  const {
    contactPersonAddResponse,
    contactPersonUpdateResponse,
    contactPersonGetDetailsResponse,
    contactCompanyDetailAddResponse,
    contactPersonAddLoading,
    contactPersonUpdateLoading,
    quickContactCompanyAdd,
    quickContactCompanyLoader,
    contactCompanyPaginationResponse,
    contactCompanyPaginationLoading,
    contactPesonDeleteLoader,
  } = useSelector((state) => state.contactCompanyPerson);

  const { allCountryListingResponse } = useSelector((state) => state.contact);

  const { genders, maritalStatusList } = useSelector((state) => state.profile);
  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );
  const [addCompany] = Form.useForm();
  const pageSize = 110;

  const [
    addUpdateCountryDrawerVisibility,
    setAddUpdateCountryDrawerVisibility,
  ] = useState(false);
  const location = useLocation();
  const id = querString.parse(location.search);
  const bc = new BroadcastChannel("testperson");

  useEffect(() => {
    if (quickContactCompanyAdd) {
      dispatch(allCountryListing());

      addCompany.setFieldsValue({
        scopeOfWork: [],
        companyName: "",
        countryId: "",
        isActive: false,
        isFavorite: false,
        cityId: "",
      });

      setVisibleModal(false);

      if (editPerson == "view") {
        form.setFieldsValue({
          companyContactId: quickContactCompanyAdd.id,
        });
      }
    }
  }, [quickContactCompanyAdd]);

  useEffect(() => {
    if (!isEmpty(applicationConfiguration?.auth?.grantedPolicies)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  // console.log(applicationConfiguration,"applicationConfiguration");

  useEffect(() => {
    if (!isEmpty(contactCompanyPaginationResponse?.items)) {
      if (
        contactCompanyPaginationResponse?.totalCount >
        contactCompanyPaginationResponse?.items?.length
      ) {
        setCompanyCurrentPage((currentPage) => currentPage + 1);
      }
    }
  }, [contactCompanyPaginationResponse]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (isEmpty(value)) return;
    const timeoutId = setTimeout(() => getCompanyPagination(), 800);
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    if (!isEmpty(contactCompanyDetailAddResponse && editPerson === "edit")) {
      form.setFieldsValue({
        // companyContactId: contactCompanyDetailAddResponse.id,
      });
    }
  }, [contactCompanyDetailAddResponse, editPerson, form]);

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  });

  //for add company drawer
  function showConfirm() {
    confirm({
      title: "Do you Want to delete this Person?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deletePerson(contactPersonGetDetailsResponse?.id));
      },
      onCancel() {},
    });
  }
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  const showAddUpdateCountryDrawer = (e, record) => {
    setAddUpdateCountryDrawerVisibility("false");
    if (record) {
      setIsUpdate(record);
    } else {
      setIsUpdate(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };
  const onAddUpdateCountryClose = () => {
    setIsUpdate("");
    setAddUpdateCountryDrawerVisibility(false);
  };
  const fetchContactSourceListing = async (body) => {
    const resp = await contactSourceListing().catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      setContactSources(resp.data);
    }
  };
  const dispatch = useDispatch();
  const filteredOptions = (groupId) => {
    if (!groupId) return contactLabel;
    return contactLabel.filter(
      (label) => label.contactLabelGroupId === groupId
    );
  };
  const filteredOptionscities = (groupId) => {
    if (!groupId) return filtercities;
    return filtercities.filter((label) => label.countryId === groupId);
  };
  const filteredOptionsCities = (fieldKey, array) => {
    if (!form.getFieldValue().contactPersonGroupAddressDtos[fieldKey]) {
      return array;
    } else {
      const id =
        form.getFieldValue().contactPersonGroupAddressDtos[fieldKey].country;
      return array.filter((label) => label.countryId === id);
    }
  };
  useEffect(() => {
    if (!isEmpty(contactPersonGetDetailsResponse) && editPerson === "edit") {
      if (contactPersonGetDetailsResponse?.isFavorite) {
        setIsFav(true);
      }
      let tagNames = [];
      if (!isEmpty(contactPersonGetDetailsResponse?.tagNames)) {
        const tagNamestrim =
          contactPersonGetDetailsResponse?.tagNames?.split(",");
        let filteredTagNames =
          !isEmpty(tagNamestrim) &&
          tagNamestrim?.filter((data) => {
            return data;
          });

        tagNames = filteredTagNames?.map((data) => {
          return data.replace(/\s+/g, "");
        });
      }
      const contactPersonGroupAddressDtos = {
        [contactPersonGetDetailsResponse?.contactPersonGroupAddressDtos?.[0]
          ?.contactLabelGroupId]: [],
      };
      const contactPersonGroupLabelDtos = {};
      let newFilterCities = [];
      contactPersonGetDetailsResponse?.contactPersonGroupAddressDtos?.forEach(
        (resp, i) => {
          if (resp.countryId) {
            contactPersonGroupAddressDtos[
              Object.keys(contactPersonGroupAddressDtos)[0]
            ].push(resp);
            const data = cities.filter(
              (filtered) => filtered.countryId === resp.countryId
            );
            newFilterCities[i] = cloneDeep(data);
          }
        }
      );

      setFilterCitiesLists(newFilterCities);

      let documentsUploadedFiles = cloneDeep(
        contactPersonGetDetailsResponse?.uploadedFileDtos
      );
      // console.log("allCountryListingResponse", contactPersonGetDetailsResponse);

      let groupByDocuments = groupBy(
        documentsUploadedFiles,
        "fileLableTypeName"
      );

      setGroupedByDocuments(groupByDocuments);

      contactPersonGetDetailsResponse.contactPersonGroupLabelDtos.forEach(
        (resp, i) => {
          if (!contactPersonGroupLabelDtos[resp.contactLabelGroupId]) {
            contactPersonGroupLabelDtos[resp.contactLabelGroupId] = [];
          }

          if (resp.contactLabelDataValue) {
            contactPersonGroupLabelDtos[resp.contactLabelGroupId].push(resp);
          }
        }
      );

      let expert = contactPersonGetDetailsResponse?.expertDtos;

      if (editPerson === "edit") {
        uploadersExceptProfile.map((file) => {
          let fileName = file.name;
          fileName &&
            groupByDocuments.hasOwnProperty(fileName) &&
            form.setFieldsValue({
              [`${file.name}FileDtos`]: groupByDocuments[`${fileName}`],
            });
        });

        form.setFieldsValue({
          ...contactPersonGetDetailsResponse,
          // companyContactId: !isEmpty(allCountryListingResponse)
          //   ? contactPersonGetDetailsResponse?.companyContactId
          //   : "",
          anniversaryDate: !contactPersonGetDetailsResponse?.anniversaryDate
            ? ""
            : moment(contactPersonGetDetailsResponse?.anniversaryDate),
          dateOfBirth: !contactPersonGetDetailsResponse?.anniversaryDate
            ? ""
            : moment(contactPersonGetDetailsResponse?.dateOfBirth),
          contactPersonGroupAddressDtos,
          contactPersonGroupLabelDtos,
          tagNames,
          uploadedFileDtos:
            contactPersonGetDetailsResponse?.uploadedFileDtos.filter(
              (files) => files.fileLableTypeName === "Profile"
            ),
        });

        if (!isEmpty(expert)) {
          setShowExpertModule(true);
          form1.setFieldsValue({
            sectorId: expert.sectorId,
            majorSubject: expert.majorSubject,
            yearOfExperience: expert.yearOfExperience,
            expertEducationDtos: expert?.expertEducationDtos?.map((data) => ({
              ...data,
              qualificationId: data.qualificationId,
              majorSubject: data.majorSubject,
            })),
            expertSectorDtos: expert?.expertSectorDtos?.map((data) => ({
              ...data,
              position: data.sectorId,
              expertProfession: data.expertProfession,
            })),

            contactWorkExperienceDtos: expert?.contactWorkExperienceDtos?.map(
              (data) => ({
                ...data,
                sectorId: data.sectorId,
                startingDate: moment(data.startingDate),
                endingDate: moment(data.endingDate),
                responsibility: data.responsibility,
                isWorking: data.isWorking,
              })
            ),
          });
        }
      }
    }
  }, [
    contactPersonGetDetailsResponse,
    uploadersExceptProfile,
    editPerson,
    cities,
    form,
    form1,
  ]);

  // console.log("testss", contactPersonGetDetailsResponse);

  useEffect(() => {
    dispatch(allCountryListing());
  }, [dispatch]);

  // useEffect(() => {
  // 	if (contactPersonAddResponse) {
  // 		message.success('SuccessFully Added Person');
  // 		window.close();
  // 	} else if (contactPersonUpdateResponse) {
  // 		message.success('SuccessFully Updated Person');
  // 			window.close();
  // 	}
  // }, [contactPersonAddResponse, contactPersonUpdateResponse]);

  useEffect(() => {
    dispatch(getMaritalStatusList());
    dispatch(getGenders());
    fetchSearchTagListing();
    fetchContactLabelGroupListing();
    fetchContactLabelListing();
    fetchCountryListing();
    fetchContactSourceListing();
    fetchCities();
    fetchDynamicFileLabelTypeUploaderDetailDtos();
    getQualificationListing();
  }, []);

  const closeWindowOnResponse = () => {
    window.close();
  };
  const fetchSearchTagListing = async () => {
    const resp = await searchTagListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setTagLists(resp.data);
    }
  };

  const addSearchTag = async (body) => {
    const resp = await searchTagAdd({ name: body }).catch((err) => {
      message.error("Failed to add this tag");
    });

    // if (resp && resp.status === 200) {
    //   message.success("Success");
    // }
  };

  const fetchContactLabelGroupListing = async (body) => {
    const resp = await contactLabelGroupListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setContactLabelGroup(resp.data);
    }
  };

  const getQualificationListing = async (body) => {
    const resp = await qualificationListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setQualificationData(resp.data);
    }
  };

  const fetchContactLabelListing = async (body) => {
    const resp = await contactLabelListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setContactLabel(resp.data);
    }
  };

  const fetchCountryListing = async (body) => {
    const resp = await countryListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setCountries(resp.data);
    }
  };

  const fetchDynamicFileLabelTypeUploaderDetailDtos = async () => {
    const resp = await dynamicFileLabelTypeUploaderDetailDtos("contact").catch(
      (err) => {
        message.error("Failure");
      }
    );

    if (resp && resp.status === 200) {
      let uploaders = resp.data.filter(
        (respData) => respData.name === "Profile"
      );
      setUploaders(uploaders);
      let newUploadersExcept = resp.data.filter(
        (respData) => respData.name !== "Profile"
      );
      setUploadersExceptProfile(newUploadersExcept);
    }
  };

  const fetchCities = async (CountryId, key) => {
    const resp = await cityListing({ CountryId }).catch((err) => {
      message.error("Failure");
    });

    // console.log(`contactPersonGroupAddressDtos[${key}].city`)

    // contactPersonGroupAddressDtos: [
    //   {},
    //   {}
    // ]
    const value = form.getFieldValue(`contactPersonGroupAddressDtos`);

    if (resp && resp.status === 200) {
      //   const fields = form.getFieldsValue()
      // const { contactPersonGroupAddressDtos } = fields
      // Object.assign(contactPersonGroupAddressDtos[key], { city: '' })
      // form.setFieldsValue({ contactPersonGroupAddressDtos })
      // form.setFieldsValue({
      //   ['contactPersonGroupAddressDtos']: [...value, value[key].city: ''],
      //   'companyCEO': 'efg'
      // })
      setCities(resp.data);
    }
  };
  const onChange = (value, index) => {
    const data = cities.filter((filtered) => filtered.countryId === value);
    let newFilterCities = cloneDeep(filterCitiesLists);
    newFilterCities[index] = cloneDeep(data);
    setFilterCitiesLists(newFilterCities);
  };

  const onSubmit = async (action) => {
    try {
      let values = await form.validateFields();
      let expertValues = await form1.validateFields();

      let expert = {};
      if (expertValues.yearOfExperience !== undefined) {
        expert.expertDtos = {
          contactPersonId: contactPersonGetDetailsResponse?.id,
          id: contactPersonGetDetailsResponse?.expertDtos?.id,
          majorSubject: expertValues.majorSubject,
          yearOfExperience: expertValues.yearOfExperience,
          sectorId: expertValues.sectorId,
          expertEducationDtos: expertValues.expertEducationDtos,
          expertSectorDtos: expertValues.expertSectorDtos,
          contactWorkExperienceDtos: expertValues.contactWorkExperienceDtos,
        };
      } else {
        expert.expertDtos = null;
      }

      let tagNames = values.tagNames && values.tagNames.join(", ");
      let contactPersonGroupLabelDtos = [];
      let contactPersonGroupAddressDtos = [];
      let uploadedFileDtos = [];

      for (let val in values.contactPersonGroupLabelDtos) {
        if (values?.contactPersonGroupLabelDtos[val]) {
          values?.contactPersonGroupLabelDtos[val]?.forEach((lab) => {
            let ele = {};
            if (lab.contactLabelDataId) {
              let changedContactLabelId = true;
              contactPersonGetDetailsResponse?.contactPersonGroupLabelDtos?.forEach(
                (resp, i) => {
                  if (resp.contactLabelDataId) {
                    if (lab.contactLabelId === resp.contactLabelId) {
                      changedContactLabelId = false;
                    }
                    // contactPersonGroupLabelDtos[resp.contactLabelGroupId].push(resp);
                  }
                }
              );
              if (!changedContactLabelId) {
                ele = {
                  ...lab,
                  contactLabelDataValue: lab.contactLabelDataValue,
                  contactLabelGroupId: val,
                };
              } else {
                ele = {
                  contactLabelId: lab.contactLabelId,
                  contactLabelDataValue: lab.contactLabelDataValue,
                  contactLabelGroupId: val,
                };
              }
            } else {
              ele = {
                contactLabelId: lab.contactLabelId,
                contactLabelDataValue: lab.contactLabelDataValue,
                contactLabelGroupId: val,
              };
            }
            contactPersonGroupLabelDtos.push(ele);
            // lab.contactLabelGroupId = val;
          });
          // contactPersonGroupLabelDtos.push(...values.contactPersonGroupLabelDtos[val]);
        }
      }

      for (let val in values.contactPersonGroupAddressDtos) {
        if (values.contactPersonGroupAddressDtos[val]) {
          values.contactPersonGroupAddressDtos[val].forEach((lab) => {
            lab.contactLabelGroupId = val;
          });
          contactPersonGroupAddressDtos.push(
            ...values.contactPersonGroupAddressDtos[val]
          );
        }
      }

      values.uploadedFileDtos?.forEach((file) => {
        file && uploadedFileDtos.push(file);
      });

      uploadersExceptProfile.map((file) => {
        if (!isEmpty(values[`${file.name}FileDtos`])) {
          uploadedFileDtos = uploadedFileDtos.concat(
            values[`${file.name}FileDtos`]
          );
        }
      });

      if (action === "edit") {
        dispatch(
          contactPersonUpdate(contactPersonGetDetailsResponse?.id, {
            ...values,
            ...expert,
            contactPersonGroupLabelDtos,
            contactPersonGroupAddressDtos,
            tagNames,
            isFavorite: isFav,
            uploadedFileDtos,
          })
        );
      } else if (action === "add") {
        dispatch(
          contactPersonAdd({
            ...values,
            isFavorite: isFav,
            ...expert,
            contactPersonGroupLabelDtos,
            contactPersonGroupAddressDtos,
            tagNames,
            uploadedFileDtos,
          })
        );
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  const onAddAddress = (add, fieldIndex, labelGroup) => {
    add();
    let values = form.getFieldsValue();
    for (let val in values.contactPersonGroupAddressDtos) {
      if (values.contactPersonGroupAddressDtos[val]) {
        values.contactPersonGroupAddressDtos[val].forEach((lab, i) => {
          if (lab === undefined) {
            // const data = cities.filter((filtered) => filtered.countryId === countries[0].id);
            // let newFilterCities = cloneDeep(filterCitiesLists);
            // newFilterCities[fieldIndex] = cloneDeep(data);
            // setFilterCitiesLists(newFilterCities);

            values.contactPersonGroupAddressDtos[val][i] = {
              contactLabelId: filteredOptions(labelGroup.id)[0].id,
              // countryId: countries[0].id,
              // cityId: newFilterCities[fieldIndex] ? newFilterCities[fieldIndex][0]?.id : '',
            };

            form.setFieldsValue({ ...values });
          }
        });
      }
    }
  };

  const onAddContactGroupLabels = (add, labelGroup) => {
    add();
    let values = form.getFieldsValue();
    for (let val in values.contactPersonGroupLabelDtos) {
      if (values.contactPersonGroupLabelDtos[val]) {
        values.contactPersonGroupLabelDtos[val].forEach((lab, i) => {
          if (lab === undefined) {
            values.contactPersonGroupLabelDtos[val][i] = {
              contactLabelId: filteredOptions(labelGroup.id)[0].id,
            };
            form.setFieldsValue({ ...values });
          }
        });
      }
    }
  };

  const onInputKeyDown = (event) => {
    if (event.key === "Enter") {
      setdropdownscope(false);
    }
  };
  const onkeyDownTagsName = (event) => {
    if (event.key === "Enter") {
      setdropdowntag(false);
    }
  };
  const getCompanyPagination = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    // Sorting,
    // SortType,
    SearchKeyword,
    ForPagination = false
  ) => {
    dispatch(
      getContactCompanyPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: value,
        },
        ForPagination
      )
    );
  };
  return (
    <>
      <div
        // visible={drawerVisibility}
        // width={568}
        // mask={false}
        className="contact-person-company-wrapper"
        // onClose={onAddUpdatePersonClose}
        onClick={() => {
          setdropdownscope(false);
          setdropdowntag(false);
        }}
      >
        <Form
          className="add__user__form"
          layout="vertical"
          form={form}
          name="control-hooks"
          validateMessages={validateMessages}
        >
          <div className="row-wrapper">
            <div className="contact-person-company-form-wrapper">
              <div className="global-form-flex">
                <div className="global-form-group profile-img-wrapper">
                  {uploaders.map((u, i) => (
                    <Form.Item
                      name={[`uploadedFileDtos`]}
                      className="form__group"
                      // name={u.id}
                      // label={u.name}
                      rules={[{ required: false }]}
                    >
                      <ContactImageUploader
                        name={u.name}
                        isMultipleFileAllowed={u.isMultipleFileAllowed}
                        id={u.id}
                        allowedExtensions={u.allowedExtensions}
                        // fileList={fileList}
                        // setFileList={setFileList}
                        index={i}
                        form={form}
                        callback={(e) => {
                          let olduploadedFileDtos = form.getFieldValue()[
                            `uploadedFileDtos`
                          ]
                            ? [...form.getFieldValue()[`uploadedFileDtos`]]
                            : [];

                          let uploadedFileDtos = cloneDeep(olduploadedFileDtos);
                          uploadedFileDtos.unshift(e);
                          form.setFieldsValue({
                            uploadedFileDtos,
                          });

                          // form.setFieldsValue({
                          //   uploadedFileDtos: [
                          //     ...olduploadedFileDtos,
                          //     e
                          //   ]
                          //     })
                        }}
                      />
                    </Form.Item>
                  ))}
                </div>
                <div className="global-form-group star">
                  <StarFilled
                    style={{
                      color: `${isFav ? "#FBA525" : "grey"}`,
                    }}
                    onClick={() => {
                      setIsFav((prevState) => !prevState);
                    }}
                  />
                </div>
                <div className="personal-info-form-wrapper">
                  <div className="global-group w-32">
                    <div className="global-form-group">
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "First Name is Required",
                          },
                        ]}
                      >
                        <Input
                          size="default"
                          placeholder="First Name"
                          autoFocus={true}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </div>
                    <div className="global-form-group">
                      <Form.Item
                        name="middleName"
                        rules={[{ required: false }]}
                      >
                        <Input size="default" placeholder="Middle Name" />
                      </Form.Item>
                    </div>
                    <div className="global-form-group">
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Last Name is Required",
                          },
                        ]}
                      >
                        <Input
                          size="default"
                          placeholder="Last Name"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-group w-49">
                    <div className="global-form-group">
                      <Form.Item name="nickName" rules={[{ required: false }]}>
                        <Input
                          size="default"
                          placeholder="NickName"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </div>
                    <div className="global-form-group">
                      <Form.Item
                        // className="form__group"
                        name="genderId"
                        rules={[
                          {
                            required: false,
                            message: "Gender is required",
                          },
                        ]}
                      >
                        <Select
                          size="default"
                          placeholder="Gender"
                          // loading={!genders?.length}
                          // disabled={!genders?.length}
                          autoComplete="off"
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {genders?.map((gender, i) => (
                            <Option value={gender.id} key={i}>
                              {gender.displayName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-group w-49">
                    <div className="global-form-group">
                      <Form.Item
                        name="designationName"
                        rules={[{ required: false }]}
                      >
                        <Input
                          size="default"
                          placeholder="Designation"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </div>
                    <div className="global-form-group">
                      <Form.Item
                        name="departmentName"
                        rules={[{ required: false }]}
                      >
                        <Input
                          size="default"
                          placeholder="Department"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group">
                    <Form.Item
                      name="companyContactId"
                      rules={[{ required: false }]}
                    >
                      <Select
                        // onFocus={() => setdropdownscope(false)}
                        // onSearch={(e) => setdropdownscope(true)}
                        // onInputKeyDown={onInputKeyDown}
                        // onMouseEnter={() => setdropdownscope(false)}
                        // open={dropdownscope}
                        autoComplete="off"
                        placeholder="Select Company"
                        size="default"
                        onSearch={(value) => setValue(value)}
                        filterOption={(input, option) => {
                          return (
                            option?.label
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          );
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        notFoundContent={
                          !isEmpty(value) ? (
                            contactCompanyPaginationLoading ? (
                              <Spin />
                            ) : (
                              isEmpty(
                                contactCompanyPaginationResponse?.items
                              ) && (
                                <Button
                                  className="btn-primary"
                                  onClick={() => {
                                    setVisibleModal(true);
                                  }}
                                >
                                  <PlusOutlined /> Add Company
                                </Button>
                              )
                            )
                          ) : null
                        }
                        showSearch={true}
                        onPopupScroll={(event) => {
                          let maxScroll =
                            event.target.scrollHeight -
                            event.target.clientHeight;
                          let currentScroll = Math.floor(
                            event.target.scrollTop
                          );
                          // console.log(currentScroll, maxScroll);
                          if (currentScroll === maxScroll) {
                            // console.log("matched");
                            let MaxResultCount = pageSize;
                            let SkipCount = companyCurrentPage * pageSize;
                            // console.log(
                            //   "SkipCount",
                            //   SkipCount,
                            //   contactCompanyPaginationResponse?.totalCount
                            // );
                            if (
                              contactCompanyPaginationResponse?.totalCount >
                              SkipCount
                            ) {
                              getCompanyPagination(
                                MaxResultCount,
                                SkipCount,
                                value,
                                true
                              );
                            }
                          }
                        }}
                      >
                        {!isEmpty(
                          contactPersonGetDetailsResponse?.companyContactId
                        ) && (
                          <Option
                            value={
                              contactPersonGetDetailsResponse.companyContactId
                            }
                          >
                            {contactPersonGetDetailsResponse.companyName}
                          </Option>
                        )}
                        {contactCompanyPaginationResponse?.items?.map(
                          (company) => (
                            <Option
                              value={company.contactCompanyId}
                              key={company.contactCompanyId}
                              label={company.contactCompanyName}
                            >
                              {company.contactCompanyName}
                            </Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="company-label-wrapper">
                <div className="company-person-label-items">
                  {contactLabelGroup.map((labelGroup, i) => (
                    <div className="company-person-label-item" key={i}>
                      {!labelGroup.isMultipleValuedAllowed ? (
                        <div className="company-person-label-item-wrapper">
                          <div className="company-person-label-header-wrapper">
                            <span className="company-person-label-header">
                              {labelGroup.name}
                            </span>
                          </div>
                          <div className="company-person-label-field-wrapper">
                            <Form.List
                              name={[
                                "contactPersonGroupLabelDtos",
                                labelGroup.id,
                              ]}
                            >
                              {(fields, { add, remove }) => (
                                <div className="global-form-flex">
                                  {fields.map((field, fieldIndex) => (
                                    <React.Fragment key={field.key}>
                                      <div className="global-form-group calc mt">
                                        <div className="global-form-label">
                                          <Form.Item
                                            noStyle
                                            shouldUpdate={(
                                              prevValues,
                                              curValues
                                            ) =>
                                              prevValues.labelGroup !==
                                              curValues.labelGroup
                                            }
                                          >
                                            {() => (
                                              <Form.Item
                                                {...field}
                                                // label="Type"
                                                // className="form__group"
                                                name={[
                                                  field.name,
                                                  "contactLabelId",
                                                ]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "contactLabelId",
                                                ]}
                                              >
                                                <Select
                                                  size="default"
                                                  bordered={false}
                                                  autoComplete="off"
                                                  getPopupContainer={(
                                                    trigger
                                                  ) => trigger.parentNode}
                                                >
                                                  {filteredOptions(
                                                    labelGroup.id
                                                  ).map((item) => (
                                                    <Option
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.name}
                                                    </Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            )}
                                          </Form.Item>
                                        </div>
                                        <div className="global-form-input">
                                          <Form.Item
                                            {...field}
                                            // label="contactLabelDataValue"
                                            name={[
                                              field.name,
                                              "contactLabelDataValue",
                                            ]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "contactLabelDataValue",
                                            ]}
                                            rules={[
                                              {
                                                required: true,
                                                message: ` ${labelGroup.name} is Required`,
                                              },
                                            ]}
                                          >
                                            <Input
                                              size="default"
                                              type={
                                                labelGroup?.name == "Phone"
                                                  ? "number"
                                                  : ""
                                              }
                                            />
                                          </Form.Item>
                                        </div>
                                        <div className="remove-field-wrapper">
                                          <MinusCircleOutlined
                                            style={{
                                              width: 45,
                                              marginTop: 3,
                                            }}
                                            className="contact__btn-danger"
                                            onClick={() => remove(field.name)}
                                          />
                                        </div>
                                      </div>
                                      <div className="global-form-group calc">
                                        {fields.length === fieldIndex + 1 && (
                                          <Form.Item>
                                            <Button
                                              className="contact__buttons"
                                              onClick={() =>
                                                onAddContactGroupLabels(
                                                  add,
                                                  labelGroup
                                                )
                                              }
                                            >
                                              <PlusCircleOutlined
                                                style={{
                                                  color: "green",
                                                }}
                                              />
                                              Add {labelGroup.name}
                                            </Button>
                                          </Form.Item>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}
                                  {fields.length === 0 && (
                                    <div className="global-form-group calc">
                                      <Form.Item>
                                        <Button
                                          type="dashed"
                                          className="contact__buttons"
                                          onClick={() =>
                                            onAddContactGroupLabels(
                                              add,
                                              labelGroup
                                            )
                                          }
                                          block
                                          icon={
                                            <PlusCircleOutlined
                                              style={{ color: "green" }}
                                            />
                                          }
                                        >
                                          Add {labelGroup.name}
                                        </Button>
                                      </Form.Item>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Form.List>
                          </div>
                        </div>
                      ) : (
                        <div className="company-person-label-item-wrapper">
                          <div className="company-person-label-header-wrapper">
                            <span className="company-person-label-header">
                              {labelGroup.name}
                            </span>
                          </div>
                          <div className="company-person-label-field-wrapper">
                            <Form.List
                              name={[
                                "contactPersonGroupAddressDtos",
                                labelGroup.id,
                              ]}
                            >
                              {(fields, { add, remove }) => (
                                <div className="global-form-flex">
                                  {fields.map((field, fieldIndex) => (
                                    <React.Fragment key={field.key}>
                                      <div className="global-form-group calc mt">
                                        <div className="global-form-label">
                                          <Form.Item noStyle>
                                            <Form.Item
                                              {...field}
                                              // label="Type"
                                              // className="form__group"
                                              name={[
                                                field.name,
                                                "contactLabelId",
                                              ]}
                                              fieldKey={[
                                                field.fieldKey,
                                                "contactLabelId",
                                              ]}
                                            >
                                              <Select
                                                size="default"
                                                bordered={false}
                                                autoComplete="off"
                                                getPopupContainer={(trigger) =>
                                                  trigger.parentNode
                                                }
                                              >
                                                {filteredOptions(
                                                  labelGroup.id
                                                ).map((item) => (
                                                  <Option
                                                    key={item.id}
                                                    value={item.id}
                                                  >
                                                    {item.name}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </Form.Item>
                                        </div>
                                        <div className="global-form-input">
                                          <div className="global-form-group calc">
                                            <Form.Item
                                              {...field}
                                              // label="streetName"
                                              // className="form__group"
                                              name={[field.name, "streetName"]}
                                              fieldKey={[
                                                field.fieldKey,
                                                "streetName",
                                              ]}
                                            >
                                              <Input
                                                size="default"
                                                placeholder="Street Name"
                                                autoComplete="off"
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className="global-group w-49">
                                            <div className="global-form-group">
                                              <Form.Item
                                                className="mar-select-input"
                                                {...field}
                                                // label="Country"
                                                name={[field.name, "countryId"]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "countryId",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Country is Required",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  autoComplete="off"
                                                  size="default"
                                                  filterOption={(
                                                    input,
                                                    option
                                                  ) =>
                                                    option.children
                                                      .toLowerCase()
                                                      .indexOf(
                                                        input.toLowerCase()
                                                      ) >= 0
                                                  }
                                                  getPopupContainer={(
                                                    trigger
                                                  ) => trigger.parentNode}
                                                  showSearch={true}
                                                  onChange={(value) =>
                                                    onChange(value, fieldIndex)
                                                  }
                                                  placeholder="Country"
                                                >
                                                  {countries.map((item) => (
                                                    <Option
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.name
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        item.name.slice(1)}
                                                    </Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            </div>
                                            <div className="global-form-group">
                                              <Form.Item
                                                {...field}
                                                // label="Zip/Postal"
                                                // className="form__group"
                                                name={[
                                                  field.name,
                                                  "postalCodeZip",
                                                ]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "postalCodeZip",
                                                ]}
                                              >
                                                <Input
                                                  size="default"
                                                  placeholder="Postal Code"
                                                  autoComplete="off"
                                                />
                                              </Form.Item>
                                            </div>
                                          </div>
                                          <div className="global-group w-49">
                                            <div className="global-form-group">
                                              <Form.Item
                                                className="mar-select-input"
                                                {...field}
                                                // label="City"
                                                // className="form__group"
                                                name={[field.name, "cityId"]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "cityId",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "City is Required",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  autoComplete="off"
                                                  size="default"
                                                  filterOption={(
                                                    input,
                                                    option
                                                  ) =>
                                                    option.children
                                                      .toLowerCase()
                                                      .indexOf(
                                                        input.toLowerCase()
                                                      ) >= 0
                                                  }
                                                  showSearch={true}
                                                  placeholder="City"
                                                  getPopupContainer={(
                                                    trigger
                                                  ) => trigger.parentNode}
                                                >
                                                  {filterCitiesLists[
                                                    fieldIndex
                                                  ]?.map((item) => (
                                                    <Option
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.name
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        item.name.slice(1)}
                                                    </Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            </div>
                                            <div className="global-form-group">
                                              <Form.Item
                                                {...field}
                                                // label="stateProvince"
                                                // className="form__group"
                                                name={[
                                                  field.name,
                                                  "stateProvince",
                                                ]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "stateProvince",
                                                ]}
                                              >
                                                <Input
                                                  size="default"
                                                  placeholder="State/Province"
                                                  autoComplete="off"
                                                />
                                              </Form.Item>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="remove-field-wrapper">
                                          <MinusCircleOutlined
                                            style={{
                                              width: 45,
                                              marginTop: 3,
                                            }}
                                            onClick={() => remove(field.name)}
                                            className="contact__btn-danger"
                                          />
                                        </div>
                                      </div>
                                      <div className="global-form-group calc">
                                        {fields.length === fieldIndex + 1 && (
                                          <Button
                                            type="dashed"
                                            style={{
                                              width: 45,
                                              marginRight: 5,
                                            }}
                                            className="contact__buttons"
                                            onClick={() =>
                                              onAddAddress(
                                                add,
                                                fieldIndex + 1,
                                                labelGroup
                                              )
                                            }
                                          >
                                            <PlusCircleOutlined
                                              style={{ color: "green" }}
                                            />
                                            Add {labelGroup.name}
                                          </Button>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}
                                  {fields.length === 0 && (
                                    <div className="global-form-group calc">
                                      <Form.Item>
                                        <Button
                                          type="dashed"
                                          className="contact__buttons"
                                          onClick={() =>
                                            onAddAddress(add, 0, labelGroup)
                                          }
                                          block
                                          icon={
                                            <PlusCircleOutlined
                                              style={{ color: "green" }}
                                            />
                                          }
                                        >
                                          Add {labelGroup.name}
                                        </Button>
                                      </Form.Item>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Form.List>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="extra-form-wrapper">
                <div className="company-person-label-header-wrapper">
                  <span className="company-person-label-header">Basic</span>
                </div>
                <div className="background-form-wrapper">
                  <div className="global-form-flex">
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label> Date of Birth</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          // className="form__group"
                          name="dateOfBirth"
                          rules={[
                            {
                              required: false,
                              message: "Marital Status is required",
                            },
                          ]}
                        >
                          <DatePicker format="YYYY/MM/DD" autoComplete="off" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Marital Status</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          // className="form__group"
                          name="maritalStatusId"
                          rules={[
                            {
                              required: false,
                              message: "Marital Status is required",
                            },
                          ]}
                        >
                          <Select
                            autoComplete="off"
                            size="default"
                            loading={!maritalStatusList?.length}
                            disabled={!maritalStatusList?.length}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {maritalStatusList?.map((status, i) => (
                              <Option value={status.id} key={i}>
                                {status.displayName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Anniversary</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          // className="form__group"
                          name="anniversaryDate"
                          rules={[
                            {
                              required: false,
                              message: "Marital Status is required",
                            },
                          ]}
                        >
                          <DatePicker format="YYYY/MM/DD" autoComplete="off" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Hobbies</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item name="hobbies" rules={[{ required: false }]}>
                          <Input size="default" autoComplete="off" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <lable>Tags</lable>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          name="tagNames"
                          rules={[{ required: false }]}
                        >
                          <Select
                            size="default"
                            mode="tags"
                            onClick={() => setdropdowntag(false)}
                            onSearch={(e) => setdropdowntag(true)}
                            onInputKeyDown={onkeyDownTagsName}
                            open={dropdowntag}
                            autoComplete="off"
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {tagLists.map((tag) => (
                              <Option key={tag.name}>{tag.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Source</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item name="conatactSourceId">
                          <Select
                            autoComplete="off"
                            size="default"
                            allowClear
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {contactSources.map((source) => (
                              <Option key={source.id}>{source.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Document</label>
                      </div>
                      <div
                        className="global-form-input"
                        style={{ marginTop: 7 }}
                      >
                        {uploadersExceptProfile.map((u, i) => (
                          <>
                            {/* <div className="profiledetails__rowedit--label"><span className="profile__bodyTitle">{u.name}</span></div> */}
                            <div
                              className="profiledetails__rowedit--data"
                              style={{ marginTop: "-9px", width: "100%" }}
                            >
                              <Form.Item
                                name={[`${u.name}FileDtos`]}
                                className="form__group"
                                rules={[{ required: false }]}
                              >
                                <DynamicImageUploader
                                  name={u.name}
                                  isMultipleFileAllowed={
                                    u.isMultipleFileAllowed
                                  }
                                  id={u.id}
                                  allowedExtensions={u.allowedExtensions}
                                  // fileList={fileList}
                                  // setFileList={setFileList}
                                  index={i}
                                  form={form}
                                  callback={(e) => {
                                    let olduploadedFileDtos =
                                      form.getFieldValue()[`${u.name}FileDtos`]
                                        ? [
                                            ...form.getFieldValue()[
                                              `${u.name}FileDtos`
                                            ],
                                          ]
                                        : [];

                                    let uploadedFileDtos = [
                                      ...olduploadedFileDtos,
                                    ];
                                    uploadedFileDtos.push(e);

                                    form.setFieldsValue({
                                      [`${u.name}FileDtos`]: uploadedFileDtos,
                                    });

                                    // form.setFieldsValue({
                                    //   uploadedFileDtos: [
                                    //     ...olduploadedFileDtos,
                                    //     e
                                    //   ]
                                    //     })
                                  }}
                                  setAdDocs={setAdDocs}
                                  adDocs={adDocs}
                                />
                              </Form.Item>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="expert-form-wrapper">
                <Form
                  className="add__user__form person_user_form"
                  layout="vertical"
                  form={form1}
                  name="control-hooks"
                  validateMessages={validateMessages}
                >
                  <Expert
                    setShowExpertModule={setShowExpertModule}
                    showExpertModule={showExpertModule}
                  />
                </Form>
              </div>
            </div>
            <div className="contact-person-company-editor-wrapper">
              <div className="editor-header-wrapper">
                <h3>Notes</h3>
              </div>
              <div className="global-form-group">
                <Form.Item
                  className="form__group"
                  name="notes"
                  rules={[{ required: false }]}
                >
                  <Input.TextArea
                    showCount
                    //autoSize={{ minRows: 28, maxRows: 30 }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="global-form-footer">
              {!isEmpty(grantedPolices) &&
                grantedPolices["AbpIdentity.Roles"] && (
                  <div className="delete-button-permission">
                    {editPerson === "edit" ? (
                      <Button
                        onClick={() => {
                          showConfirm();
                        }}
                        danger
                        loading={
                          contactPersonUpdateLoading || contactPesonDeleteLoader
                        }
                      >
                        Delete
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              <Button
                className="cancel-btn"
                onClick={() => setEditPerson("view")}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onSubmit(editPerson === "add" ? "add" : "edit");
                }}
                loading={
                  contactPersonAddLoading ||
                  contactPersonUpdateLoading ||
                  contactPesonDeleteLoader
                }
              >
                {/* {isUpdatePerson ? <span>Save</span> : <span>Submit</span>} */}
                Done
              </Button>
            </div>
          </div>
        </Form>

        <Modal
          visible={visibleModal}
          footer={null}
          onCancel={() => {
            addCompany.setFieldsValue({
              scopeOfWork: [],
              companyName: "",
              countryId: "",
              isActive: false,
              isFavorite: false,
              cityId: "",
            });
            setVisibleModal(false);
          }}
          keyboard={false}
          maskClosable={false}
        >
          <QuickAddCompany
            form={addCompany}
            companySearch={value}
            quickContactCompanyAdd={quickContactCompanyAdd}
            quickContactCompanyLoader={quickContactCompanyLoader}
          />
        </Modal>
      </div>
    </>
  );
};

export default AddPersonDrawer;
