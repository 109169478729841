import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Form, Input } from "antd";
import { LogoImage } from "utils/imageConstants";
import { sectorPagination } from "services/redux/contact/contact.action";
import { isEmpty } from "lodash";

const SectorAddUpdateTable = ({ showAddUpdateSectorDrawer }) => {
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width:"5%",
    },
    {
      title: "Sector",
      dataIndex: "sectorName",
      key: "sectorName",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        return (
          <>
            {!isEmpty(record) ? (
              <a
                className="action-btn action-btn-add"
                onClick={(e) => showAddUpdateSectorDrawer(e, record)}
              >
                Edit Sector
              </a>
            ) : (
              <a className="action-btn action-btn-add">Delete</a>
            )}
          </>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const { sectorPaginationResponse, sectorPaginationLoading } = useSelector(
    (state) => state.contact
  );
  const [form] = Form.useForm();
  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;
  const EditableRow = ({ index, ...props }) => {
    // console.log("props", props);
    return (
      <Form form={form} component={false}>
        <tr {...props} />
      </Form>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!sectorPaginationResponse) return;
    setdata(sectorPaginationResponse.items);
    setTotalCount(sectorPaginationResponse.totalCount);
  }, [sectorPaginationResponse]);

  const components = {
    body: {
      row: EditableRow,
      //   cell: EditableCell,
    },
  };
  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType
  ) => {
    dispatch(
      sectorPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
      })
    );
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };
  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      //   form.setFieldsValue({
      //     [dataIndex]: record[dataIndex],
      //   });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        // console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const handleAdd = () => {
    const newData = {};
    setdata((pre) => {
      return [...pre, newData];
    });
  };

  return (
    <>
      <Button
        onClick={() => {
          handleAdd(data);
        }}
        type="primary add-row-btn"
        style={{
          marginBottom: 16,
        }}
      >
        Add a row
      </Button>
      <Table
        className="organizer__tables"
        columns={columns}
        components={components}
        rowKey="appUserId"
        dataSource={data}
        onChange={handleTableChange}
        loading={sectorPaginationLoading}
        loading={{
          indicator: <img src={LogoImage} height="auto" width="50px" />,
          spinning: sectorPaginationLoading,
        }}
        pagination={{
          total: totalCount,
          pageSize: pageSize,
        }}
      />
    </>
  );
};

export default SectorAddUpdateTable;
