import { error, finish, init, success } from "services/common";
import { CircularMailService } from "./api";
import { message } from "antd";
import { CIRCULAR_MAIL_TYPES } from "services/types";
import { persistSelectedTenderMail } from "services/common";

const circularMailService = new CircularMailService();

export const actionForGetTenderCircularMail = (id, refCode) => {
  return async (dispatch) => {
    dispatch(init(CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL));
    const response = await circularMailService.getSingleTenderCircularMail(
      id,
      refCode
    );
    if (response?.isSuccess) {
      dispatch(
        success(CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL, response?.data)
      );
      dispatch(finish(CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};


// export const persistSelectedTenderModule = (data) => {
//   return async (dispatch) => {
//     dispatch(persistSelectedTenderMail(CIRCULAR_MAIL_TYPES.PERSIST_TENDER_SELECTED_MAIL, data))
//   }
// }

export const actionForUpdateTenderCircularMail = (data) => {
  return {
    type: CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL_UPDATE,
    payload: data,
  }
}