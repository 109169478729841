import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Select,
  Form,
  DatePicker,
  Input,
  Modal,
  Button,
  Row,
  Col,
  Typography,
  Divider,
  Skeleton,
  Card,
} from "antd";
// import {
//   PlusCircleOutlined,
//   MinusCircleOutlined,
//   UploadOutlined,
// } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import {
  getContactCompanyPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";
import QuickAddCompany from "views/Contact/ContactCompanyPerson/AddQuickCompany";
import { tenderAwardStatusAdd } from "services/redux/Reference/Tender/action";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import { getAllUsersListing } from "services/redux/admin/FiscalYear/action";
import {
  actionForAddUpdateCEADTender,
  actionForGetTenderCEADGetPaginationListing,
} from "services/redux/cead/action";
// import { getReferenceListing } from "services/redux/Reference/action";
// import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import tick from "assets/images/tick.png";
import cross from "assets/images/cross.png";
import CreateEmployee from "views/CEAD/tabs/contractStage/createCead/createEmployee";

const AwardStatus = ({
  allCurrencyListingResponse,
  getDetails,
  awardStatusForm,
}) => {
  const [addCompany] = Form.useForm();
  useEffect(() => {
    if (isEmpty(getDetails?.tenderAwardStatusDto)) {
      return;
    }

    if (!isEmpty(getDetails?.tenderAwardStatusDto)) {
      setParticipationform(true);
    }

    if (getDetails?.tenderAwardStatusDto.awardStatus == "Awarded-to-us") {
      setAwardedToUs(true);
      setAwardedToOther(true);
      setAwardCancelled(false);
    } else if (
      getDetails?.tenderAwardStatusDto.awardStatus == "Awarded-to-other"
    ) {
      setAwardedToOther(false);
    } else if (getDetails?.tenderAwardStatusDto.awardStatus === "Cancelled") {
      setAwardedToUs(false);
      setAwardedToOther(false);
      setAwardCancelled(true);
    }
  }, [getDetails]);

  const tooltip = "./assets/svg/rototool-24.svg";
  const dispatch = useDispatch();
  const [particiaptionform, setParticipationform] = useState(false);
  const [awardedToUs, setAwardedToUs] = useState(false);
  // const [showAward, setShowAward] =useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [contactCompanyList, setContactCompanyList] = useState("");
  const [awardedToOther, setAwardedToOther] = useState(false);
  const [awardCancelled, setAwardCancelled] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [companyTender, setCompanyTender] = useState("");
  const [currencyValue, setCurrencyValue] = useState();

  const { Option } = Select;
  const { contactCompanyPaginationResponse, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  const { allCountryListingResponse } = useSelector((state) => state.contact);

  const { quickContactCompanyAdd, quickContactCompanyLoader } = useSelector(
    (state) => state.contactCompanyPerson
  );
  const { participationPaginationResponse } = useSelector(
    (state) => state.tender
  );

  useEffect(() => {
    if (!isEmpty(contactCompanyPaginationResponse?.items)) {
      setContactCompanyList(contactCompanyPaginationResponse?.items);
    } else {
    }
  }, [contactCompanyPaginationResponse]);

  useEffect(() => {
    if (isEmpty(searchValue)) setContactCompanyList([]);
    if (isEmpty(searchValue)) return;
    if (
      !isEmpty(contactCompanyList) &&
      isEmpty(contactCompanyPaginationResponse?.items)
    )
      return;

    const timeoutId = setTimeout(() => getCompanyPagination(), 500);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);
  useEffect(() => {
    if (quickContactCompanyAdd) {
      addCompany.setFieldsValue({
        scopeOfWork: [],
        companyName: "",
        countryId: "",
        isActive: false,
        isFavorite: false,
        cityId: "",
      });

      setVisibleModal(false);

      setTimeout(() => {
        companyTender == "companyclient" &&
          awardStatusForm?.setFieldsValue({
            companyId: quickContactCompanyAdd.id,
          });
      }, 500);
    }
  }, [quickContactCompanyAdd]);

  const onSelectParticipant = (event) => {
    if (event.target.checked === true) {
      setParticipationform(true);
      // form.setFieldsValue({
      // 	particiaption: [
      // 		{
      // 			company: '',
      // 			stage: '',
      // 			through: '',
      // 			bidPrice: '',
      // 			validity: '',
      // 			rank: '',
      // 			bidSecurity: '',
      // 			type: '',
      // 			arrangedBy: '',
      // 			issuingBank: '',
      // 			refNo: '',
      // 			value: '',
      // 		},
      // 	],
      // });
    } else {
      setParticipationform(false);
      // form.setFieldsValue({
      // 	particiaption: [],
      // });
    }
  };
  const getCompanyPagination = (SkipCount = 0, ForPagination = false) => {
    dispatch(
      getContactCompanyPagination(
        {
          SkipCount,
          SearchKeyword: searchValue,
        },
        ForPagination
      )
    );
  };
  const showBidSecurityForm = (value) => {
    if (value == "Awarded-to-us") {
      setAwardedToUs(true);
      setAwardedToOther(true);
      setAwardCancelled(false);
      // form.setFieldsValue({
      // 	tenderParticipationAddUpdateDtos: [
      // 		{
      // 			tenderTypeId: '',
      // 			arrangedBy: '',
      // 			issuingBank: '',
      // 			referenceNo: '',
      // 			bidSecurityValue: '',
      // 			bidSecurityValidity: '',
      // 			bidSecurityCurrencyId: '',
      // 		},
      // 	],
      // });
    } else if (value == "Awarded-to-other") {
      setAwardedToOther(false);
      setAwardedToUs(true);
    } else if (value == "Cancelled") {
      setAwardedToUs(false);
      setAwardedToOther(false);
      setAwardCancelled(true);
    }
  };

  const onFinish = (values) => {
    const id = getDetails?.id;
    let body = {
      ...values,
      isActive: true,
      tenderBasicId: id,
      contactValueCurrencyId: currencyValue
        ? currencyValue
        : "83d6df22-1416-4c1c-ab37-4bcf5ceaf3cc",
    };
    console.log(body);
    dispatch(tenderAwardStatusAdd(body, getDetails?.id));
  };

  return (
    <>
      <div className="edittender__header">
        <div className="edittender__header--text">
          <span className="edittender__primaryText">Award Status</span>
        </div>

        <div className="edittender__header--checkbox">
          <Checkbox
            onChange={onSelectParticipant}
            defaultChecked={
              !isEmpty(getDetails?.tenderAwardStatusDto) ? true : false
            }
          />
        </div>
      </div>
      {particiaptionform && (
        <div className="edittender__wrapper">
          <Form
            layout="vertical"
            form={awardStatusForm}
            name="documentPurchase"
            onFinish={onFinish}
          >
            <div className="edittender__body">
              <div className="profiledetails__rowedit">
                <div className="profiledetails__rowedit--label">
                  <span className="profile__bodyTitle">Award Status</span>
                </div>
                <div className="profiledetails__rowedit--data">
                  <span className="referenceDetails--subtitle ">
                    <Form.Item
                      name="awardStatus"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select size="small" onChange={showBidSecurityForm}>
                        <Option value="Awarded-to-us">Awarded To Us</Option>
                        <Option value="Awarded-to-other">
                          Awared To Other
                        </Option>
                        <Option value="Cancelled">Cancelled</Option>
                      </Select>
                    </Form.Item>
                  </span>
                </div>
              </div>
            </div>
            {awardedToUs && (
              <div
                className="profiledetails__content--divider"
                style={{
                  borderBottom: "1px solid #E2ECF8",
                  paddingBottom: 8,
                  marginBottom: 8,
                }}
              >
                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">Company Name</span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <span className="referenceDetails--subtitle ">
                      <Form.Item
                        name="companyId"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Select size="small">
                          {participationPaginationResponse?.items?.map(
                            (company) => (
                              <Option
                                value={company.companyId}
                                key={company.companyId}
                                label={company.companyName}
                              >
                                {company.companyName}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </span>
                  </div>
                </div>

                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">Local Partner</span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <span className="referenceDetails--subtitle ">
                      <Form.Item
                        name="localPatner"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input size="small" />
                      </Form.Item>
                    </span>
                  </div>
                </div>

                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">Letter of Intent</span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <span className="referenceDetails--subtitle ">
                      <Form.Item
                        name="letterOfInternDate"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <DatePicker size="small" format="YYYY/MM/DD" />
                      </Form.Item>
                    </span>
                  </div>
                </div>

                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">Letter of Award</span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <span className="referenceDetails--subtitle ">
                      <Form.Item
                        name="letterOfAwardDate"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <DatePicker size="small" format="YYYY/MM/DD" />
                      </Form.Item>
                    </span>
                  </div>
                </div>

                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">Contract</span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <span className="referenceDetails--subtitle ">
                      <Form.Item
                        name="contactDate"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <DatePicker size="small" format="YYYY/MM/DD" />
                      </Form.Item>
                    </span>
                  </div>
                </div>

                {awardedToOther && (
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Contract Value</span>
                    </div>
                    <div
                      className="profiledetails__rowedit--data"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        className="contactsDetails--subtitle bold"
                        style={{ width: "20%" }}
                      >
                        <Form.Item
                          name="contactValueCurrencyId"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          {!isEmpty(allCurrencyListingResponse) && (
                            <Select
                              defaultValue={() => {
                                let value = "";
                                allCurrencyListingResponse?.map((currency) => {
                                  if (currency.abbvr == "USD") {
                                    value = currency.currencyId;
                                  }
                                });

                                return value;
                              }}
                              size="small"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onSelect={(value) => {
                                setCurrencyValue(value);
                              }}
                            >
                              {allCurrencyListingResponse?.map((currency) => (
                                <Option
                                  key={currency.currencyId}
                                  value={currency.currencyId}
                                >
                                  {currency.abbvr}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </span>
                      <span
                        className="contactsDetails--subtitle bold"
                        style={{ width: "78%" }}
                      >
                        <Form.Item
                          name="contactValue"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input size="small" />
                        </Form.Item>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {awardedToOther && (
              <div className="profiledetails__rowedit">
                <div className="profiledetails__rowedit--label">
                  <span className="profile__bodyTitle">
                    Performance Security
                  </span>
                </div>
                <div className="profiledetails__rowedit--data">
                  <span className="referenceDetails--subtitle ">
                    <Form.Item
                      name="performanceSecurity"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select size="small">
                        <Option value="Awarded-to-us">Counter Guranteed</Option>
                        <Option value="Awarded-to-other">Foreign Bank</Option>
                        <Option value="Cancelled">Local</Option>
                        <Option value="Cancelled">Cash</Option>
                      </Select>
                    </Form.Item>
                  </span>
                </div>
              </div>
            )}

            {awardCancelled && (
              <>
                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">
                      Cancellation date
                    </span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <span className="referenceDetails--subtitle ">
                      <Form.Item
                        name="cancellationDate"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <DatePicker size="small" format="YYYY/MM/DD" />
                      </Form.Item>
                    </span>
                  </div>
                </div>
                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">Cancellation doc</span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <span className="referenceDetails--subtitle ">
                      Document Upload
                    </span>
                  </div>
                </div>
                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">Reason</span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <span className="referenceDetails--subtitle ">
                      <Form.Item
                        name="reason"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <TextArea />
                      </Form.Item>
                    </span>
                  </div>
                </div>
              </>
            )}
            <div className="award-form-footer">
              <Button
                className="cancleAction"
                onClick={() => {
                  setParticipationform(false);
                }}
              >
                Close
              </Button>
              <Button className="saveAction" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
          <Modal
            visible={visibleModal}
            footer={null}
            onCancel={() => {
              addCompany.setFieldsValue({
                scopeOfWork: [],
                companyName: "",
                countryId: "",
                isActive: false,
                isFavorite: false,
                cityId: "",
              });
              setVisibleModal(false);
            }}
            keyboard={false}
            maskClosable={false}
          >
            <QuickAddCompany
              form={addCompany}
              companySearch={searchValue}
              quickContactCompanyAdd={quickContactCompanyAdd}
              quickContactCompanyLoader={quickContactCompanyLoader}
              quickAddFromProject="project"
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default AwardStatus;

export const RenderAwardStatus = ({ getDetails }) => {
  const [formCreateCead] = Form.useForm();
  const dispatch = useDispatch();

  const { contactPersonPaginationLoading, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);
  const { getAllUsersListResponse } = useSelector(
    (state) => state.fiscalYearState
  );

  const { contactCompanyPaginationResponse, contactPersonPaginationResponse } =
    useSelector((state) => state.contactCompanyPerson);

  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);

  const { tenderCeadListLoading, tenderCeadTransferLoading, isTransferToCead } =
    useSelector((state) => state.ceadReducer);

  const { isTransferredToCEAD } = getDetails;

  const { awardStatus } = getDetails?.tenderAwardStatusDto;

  // const { referenceListingResponse } = useSelector((state) => state.reference);

  const [showCeadTranferForm, setShowCeadTransferForm] = useState(false);
  const [selectedClientCompany, setSelectedClientCompany] = useState();
  const [selectedClientCompanyid, setSelectedClientCompanyId] = useState();
  const [selectedPrincipalCompany, setSelectedPrincipalCompany] = useState();
  const [selectedPrincipleCompanyid, setSelectedPrincipleCompanyId] =
    useState();
  const [openCreateEmployee, setOpenCreateEmployee] = useState({
    type: "",
    open: false,
  });

  useEffect(() => {
    if (isEmpty(sisterCompanyListing)) {
      dispatch(getSisterCompaniesListings());
    }

    if (isEmpty(getAllUsersListResponse)) {
      dispatch(getAllUsersListing());
    }

    // dispatch(actionForGetTenderCEADGetPaginationListing());
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedClientCompany || selectedPrincipalCompany)) {
      let apiBody = {
        MaxResultCount: 50,
        SkipCount: 0,
        searchKeyword: selectedClientCompany || selectedPrincipalCompany,
      };
      dispatch(getContactPersonPagination(apiBody));
    }
  }, [selectedClientCompany, selectedPrincipalCompany]);

  useEffect(() => {
    if (!tenderCeadTransferLoading) {
      setShowCeadTransferForm(false);
    }
  }, [tenderCeadTransferLoading]);

  // let throughCompOptions = sisterCompanyListing?.map((x) => {
  //   return {
  //     label: x?.name,
  //     value: x?.id,
  //   };
  // });

  let inchargeUsersOptions = getAllUsersListResponse?.map((x) => {
    return {
      label: x?.userName,
      value: x?.appUserId,
    };
  });

  let companyOptions = contactCompanyPaginationResponse?.items?.map((x) => {
    return {
      label: x?.contactCompanyName,
      value: x?.contactCompanyId,
    };
  });

  let contactPersonOptions = contactPersonPaginationResponse?.items?.map(
    (y) => {
      return {
        label: y?.personFullName,
        value: y?.contactPersonId,
      };
    }
  );

  // console.log("getDetails", getDetails);

  const onCeadCreateFinish = (values) => {
    let apiBody = {
      ...values,
      contract: getDetails?.title,
      referenceId: getDetails?.referenceId,
      throughCompanyId: getDetails?.tenderAwardStatusDto?.companyId,
      ceaD_Inchars: values?.ceaD_Inchars?.map((x) => {
        return {
          isActive: true,
          participantId: x,
        };
      }),
      tendeR_BASIC_Inchars: values?.tendeR_BASIC_Inchars?.map((x) => {
        return {
          isActive: true,
          participantId: x,
        };
      }),
      ceaD_CLIENT_ContactInformations: values?.contactId?.map((x) => {
        return {
          isActive: true,
          contactId: x,
        };
      }),
      ceaD_PRINCIPAL_ContactInformations: values?.principleContactId?.map(
        (x) => {
          return {
            isActive: true,
            principleContactId: x,
          };
        }
      ),
      handedOverToId: JSON.parse(sessionStorage.getItem("userId")),
    };

    dispatch(actionForAddUpdateCEADTender(apiBody));
  };

  useEffect(() => {
    if (!tenderCeadListLoading) {
      setShowCeadTransferForm(false);
    }
  }, [tenderCeadListLoading]);

  // const notFoundContentAdd = (companyType) => {
  //   // console.log("companyType", companyType);
  //   // return companyType;
  //   return (
  //     <>
  //       {contactCompanyPaginationLoading ? (
  //         <Skeleton />
  //       ) : (
  //         <div>
  //           Search for Company. Not Found! Create One?
  //           <span
  //             // onClick={() => {
  //             //   setOpenCreateEmployee({
  //             //     type: "client",
  //             //     open: true,
  //             //   });
  //             // }}
  //             style={{ width: 15 }}
  //           >
  //             Yes
  //           </span>
  //         </div>
  //       )}
  //     </>
  //   );
  // };

  return (
    <div>
      <Modal
        title="Transfer to CEAD"
        visible={showCeadTranferForm}
        onCancel={() => setShowCeadTransferForm(false)}
        footer={null}
        // closable={false}
        width={750}
      >
        <Card>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={formCreateCead}
            onFinish={onCeadCreateFinish}
          >
            <Row gutter={[24, 0]}>
              <Col span={8}>
                <Form.Item
                  label="Biz-Pro-In-Charge"
                  name="tendeR_BASIC_Inchars"
                >
                  <Select
                    mode="multiple"
                    options={inchargeUsersOptions}
                    placeholder="Select Incharge"
                    allowClear
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="CEAD-In-Charge" name="ceaD_Inchars">
                  <Select
                    mode="multiple"
                    options={inchargeUsersOptions}
                    placeholder="Select Incharge"
                    allowClear
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={8} className="cead-date-picker">
                <Form.Item
                  label="Contract Signed Date"
                  name="contractSignedDate"
                >
                  <DatePicker placeholder="Select date" />
                </Form.Item>
              </Col>

              <Col span={8} className="cead-date-picker">
                <Form.Item
                  label="Contract Effective Date"
                  name="contractEffectiveDate"
                >
                  <DatePicker placeholder="Select date" />
                </Form.Item>
              </Col>

              <Col span={8} className="cead-date-picker">
                <Form.Item label="Contract End Date" name="contractEndDate">
                  <DatePicker placeholder="Select date" />
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            {/* clientInfoForm */}
            <Typography.Title level={5}>Client Info</Typography.Title>

            <Row gutter={[24, 0]}>
              <Col span={8}>
                <Form.Item label="Client Name" name="clientId">
                  <Select
                    options={companyOptions}
                    optionFilterProp="label"
                    placeholder="Select Incharge"
                    allowClear
                    showSearch
                    onSearch={(value) => {
                      // console.log("values", value);
                      let apiBody = {
                        MaxResultCount: 40,
                        SkipCount: 0,
                        searchKeyword: value,
                      };
                      dispatch(getContactCompanyPagination(apiBody));
                    }}
                    onChange={(value, label) => {
                      setSelectedClientCompany(label?.label);
                      setSelectedClientCompanyId(label?.value);
                      // setSelectedPrincipalCompany();
                    }}
                    // notFoundContent={notFoundContentAdd("clientCompany")}
                    onFocus={() => {
                      let apiBody = {
                        MaxResultCount: 50,
                        SkipCount: 0,
                        searchKeyword: selectedClientCompany,
                      };
                      dispatch(getContactCompanyPagination(apiBody));
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Contact Person" name="contactId">
                  <Select
                    mode="multiple"
                    options={contactPersonOptions}
                    placeholder="Select Incharge"
                    allowClear
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      <>
                        <>
                          {contactPersonPaginationLoading ? (
                            <Skeleton />
                          ) : (
                            <div>
                              Content Not Found. Do you want to create?
                              <Button
                                type="primary"
                                onClick={() => {
                                  setOpenCreateEmployee({
                                    type: "client",
                                    open: true,
                                  });
                                }}
                              >
                                Yes
                              </Button>
                            </div>
                          )}
                        </>
                      </>
                    }
                    onFocus={() => {
                      // let apiBody = {
                      //   MaxResultCount: 50,
                      //   SkipCount: 0,
                      //   searchKeyword: selectedClientCompany,
                      // };
                      let apiBody = {
                        companyId: selectedClientCompanyid,
                      };
                      dispatch(getContactPersonPagination(apiBody));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Typography.Title level={5}>Principal Info</Typography.Title>

            <Row gutter={[24, 0]}>
              <Col span={8}>
                <Form.Item label="Principal Name" name="principalId">
                  <Select
                    options={companyOptions}
                    placeholder="Select Incharge"
                    allowClear
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSearch={(value) => {
                      let apiBody = {
                        MaxResultCount: 40,
                        SkipCount: 0,
                        searchKeyword: value,
                      };
                      dispatch(getContactCompanyPagination(apiBody));
                    }}
                    onChange={(value, label) => {
                      // setSelectedClientCompany();
                      setSelectedPrincipalCompany(label?.label);
                      setSelectedPrincipleCompanyId(label?.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Contact Person" name="principleContactId">
                  <Select
                    mode="multiple"
                    options={contactPersonOptions}
                    placeholder="Select Incharge"
                    allowClear
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      <>
                        <>
                          {contactPersonPaginationLoading ? (
                            <Skeleton />
                          ) : (
                            <div>
                              Content Not Found. Do you want to create?
                              <Button
                                type="primary"
                                onClick={() => {
                                  setOpenCreateEmployee({
                                    type: "principal",
                                    open: true,
                                  });
                                }}
                              >
                                Yes
                              </Button>
                            </div>
                          )}
                        </>
                      </>
                    }
                    onFocus={() => {
                      // let apiBody = {
                      //   MaxResultCount: 50,
                      //   SkipCount: 0,
                      //   searchKeyword: selectedPrincipalCompany,
                      // };
                      let apiBody = {
                        companyId: selectedPrincipleCompanyid,
                      };
                      dispatch(getContactPersonPagination(apiBody));
                    }}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={8}>
              <Form.Item label="Client:Email" name="client_email">
                <Input placeholder="Enter ..." />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Client:Phone" name="client_phone">
                <Input placeholder="Enter ..." />
              </Form.Item>
            </Col> */}
            </Row>

            <Row style={{ float: "right" }}>
              <Col>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>

      <div className="reference__view">
        <div>
          {!isTransferredToCEAD && (
            <div className="reference__header">
              <span className="reference__header--text">{awardStatus}</span>
              {(awardStatus === "Awarded-to-us" ||
                isTransferToCead !== "success") && (
                <span style={{ fontWeight: "bold" }}>
                  Transfer to CEAD{" "}
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setShowCeadTransferForm(true);
                        formCreateCead.resetFields();
                      }
                    }}
                    checked={showCeadTranferForm}
                  />
                </span>
              )}
            </div>
          )}

          <div className="reference__view--wrapper">
            <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title ">Company</span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle ">
                  {getDetails?.tenderAwardStatusDto?.companyName}
                </span>
              </div>
            </div>
            <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title ">Contact Date</span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle ">
                  {getDetails?.tenderAwardStatusDto?.contactDate
                    ? moment(
                        getDetails?.tenderAwardStatusDto?.contactDate
                      ).format("YYYY / MM / DD")
                    : "---"}
                </span>
              </div>
            </div>
            <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title ">
                  Letter of Award Date
                </span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle ">
                  {getDetails?.tenderAwardStatusDto?.letterOfAwardDate
                    ? moment(
                        getDetails?.tenderAwardStatusDto?.letterOfAwardDate
                      ).format("YYYY / MM / DD")
                    : "---"}
                </span>
              </div>
            </div>
            <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title ">
                  Letter of Intent Date
                </span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle ">
                  {getDetails?.tenderAwardStatusDto?.letterOfInternDate
                    ? moment(
                        getDetails?.tenderAwardStatusDto?.letterOfInternDate
                      ).format("YYYY / MM / DD")
                    : "---"}
                </span>
              </div>
            </div>
            <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title ">Local Partner</span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle ">
                  {getDetails?.tenderAwardStatusDto?.localPatner}
                </span>
              </div>
            </div>
            {/* <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title ">
                  Perform Security
                </span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle ">
                  {getDetails?.tenderAwardStatusDto?.performanceSecurity}
                </span>
              </div>
            </div> */}
            {awardStatus === "Awarded-to-us" && (
              <div className="reference__info">
                <div className="reference__label">
                  <span className="referenceDetails--title ">
                    Transfer to Cead
                  </span>
                </div>
                <div className="reference__labeldata">
                  <span className="referenceDetails--subtitle ">
                    {isTransferredToCEAD || isTransferToCead === "success" ? (
                      <img src={tick} alt="" height={10} />
                    ) : (
                      <img src={cross} alt="" height={10} />
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal visible={openCreateEmployee.open} closable={false} footer={null}>
        <CreateEmployee
          setOpenCreateEmployee={setOpenCreateEmployee}
          selectedClientCompanyid={selectedClientCompanyid}
          selectedPrincipleCompanyid={selectedPrincipleCompanyid}
          type={openCreateEmployee.type}
        />
      </Modal>
    </div>
  );
};
