import React, { useEffect, useState } from "react";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";

// antd
import {
  Form,
  Input,
  Button,
  Checkbox,
  DatePicker,
  Select,
  TimePicker,
  Radio,
  Row,
  Col,
  Drawer,
  Layout,
} from "antd";

import { useForm } from "antd/lib/form/Form";
import { CloseOutlined } from "@ant-design/icons";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  addToDoEvent,
  updateToDoEvent,
} from "services/redux/organizer/todoLists/action";
import { FormContext } from "antd/lib/form/context";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const { Option } = Select;
const { Header, Footer } = Layout;

const ToDoForm = (props) => {
  const dispatch = useDispatch();
  const toDoEventsState = useSelector((state) => state.toDoEvents);

  const [form] = useForm();

  const { addToDoEventResp } = toDoEventsState;

  const [todoEventCandidateDtos, setTodoEventCandidateDtos] = useState([]);
  const [assignMembers, setAssignMembers] = useState(false);
  const [currentToDoEventMembers, setCurrentToDoEventMembers] = useState(false);

  const {
    selectedToDoEvent,
    organizerEventLabelListing,
    organizerEventPriorityListing,
    allEventStatusListing,
    editToDoList,
    meetingInvitees,
    todoEventDrawer,
    onAddUpdateCountryClose,
    drawerVisibility,
    isUpdateCountry,
  } = props;
  // form.resetFields();

  if (!isUpdateCountry) {
    form.resetFields();
    // setAssignMembers(false);
  }
  useEffect(() => {
    if (isEmpty(isUpdateCountry)) {
      form.resetFields();
      setAssignMembers(false);
    }
  }, [isUpdateCountry]);

  useEffect(() => {
    if (!isEmpty(selectedToDoEvent)) {
      form.setFieldsValue({
        ...selectedToDoEvent,
        startDate: moment(selectedToDoEvent.startDate),
        dueDate: moment(selectedToDoEvent.dueDate),
        organizerEventLabelId: selectedToDoEvent.organizerEventLabelDto.id,
        organizerEventPriorityId:
          selectedToDoEvent.organizerEventPriorityDto.id,
        todoEventStatusId: selectedToDoEvent.todoEventStatusDto.id,
        todoEventCandidateDtos: selectedToDoEvent.todoEventCandidateDtos.map(
          (candidate) => candidate.candidateId
        ),
        reminderDate: moment(selectedToDoEvent.reminderDate),
        reminderTime: moment(selectedToDoEvent.reminderDateTime),
      });
      setAssignMembers(
        !isEmpty(selectedToDoEvent.todoEventCandidateDtos) ? true : false
      );
      setTodoEventCandidateDtos(selectedToDoEvent.todoEventCandidateDtos);

      let oldCurrentToDoEventMembers = cloneDeep(
        selectedToDoEvent.todoEventCandidateDtos
      );
      let newCurrentToDoEventMembers = [];

      if (selectedToDoEvent.privateUserId) {
        let currentUserDetails = [];
        meetingInvitees.map((candidate) => {
          if (candidate.appUserId === selectedToDoEvent.privateUserId) {
            let ele = {
              candidateId: candidate.appUserId,
              candidateName: candidate.fullName,
            };
            currentUserDetails.push(ele);
          }
        });
        newCurrentToDoEventMembers =
          oldCurrentToDoEventMembers.concat(currentUserDetails);
      }

      setCurrentToDoEventMembers(newCurrentToDoEventMembers);
    }
  }, [selectedToDoEvent]);

  useEffect(() => {
    if (!isEmpty(addToDoEventResp)) {
      form.resetFields();
      if (!todoEventDrawer) {
        editToDoList({});
        setTodoEventCandidateDtos([]);
      }
    }
  }, [addToDoEventResp]);

  const onFinish = (values) => {
    let newValues = {
      ...values,
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      dueDate: moment(values.dueDate).format("YYYY-MM-DD"),
      reminderDate: moment(values.reminderDate).format("YYYY-MM-DD"),
      reminderTime: moment(values.reminderTime).format("h:mm:ss a"),
    };

    newValues.todoEventCandidateDtos = todoEventCandidateDtos;

    if (isEmpty(selectedToDoEvent)) {
      dispatch(addToDoEvent(newValues));
    } else {
      dispatch(updateToDoEvent(newValues, selectedToDoEvent.id));
    }
  };

  const changeEmployeeDto = (value, option) => {
    let newTodoEventCandidateDtos = option.map((candidate) => {
      if (todoEventCandidateDtos.length > 0) {
        let newCandidate = {};
        todoEventCandidateDtos.map((oldCandidate) => {
          if (oldCandidate.candidateId === candidate.value) {
            newCandidate = { ...oldCandidate };
          }
        });
        if (!isEmpty(newCandidate)) {
          return { ...newCandidate };
        } else {
          return {
            candidateId: candidate.value,
            candidateName: candidate.title,
            isPrimary: false,
            isSupervisor: false,
          };
        }
      } else {
        return {
          candidateId: candidate.value,
          candidateName: candidate.title,
          isPrimary: true,
          isSupervisor: false,
        };
      }
    });
    setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  };

  const allEqual = (arr) => arr.every((val) => val === true);

  const changeCandidatePrimary = (value, index) => {
    let newTodoEventCandidateDtos = cloneDeep(todoEventCandidateDtos);

    if (value === "supervisor") {
      newTodoEventCandidateDtos[index].isSupervisor = true;
      newTodoEventCandidateDtos[index].isPrimary = false;
    } else if (value === "primary") {
      newTodoEventCandidateDtos[index].isPrimary = true;
      newTodoEventCandidateDtos[index].isSupervisor = false;
    } else {
      newTodoEventCandidateDtos[index].isPrimary = false;
      newTodoEventCandidateDtos[index].isSupervisor = false;
    }
    let primaryValueArray = newTodoEventCandidateDtos.map(
      (candidate) => candidate.isPrimary
    );
    let supervisorValueArray = newTodoEventCandidateDtos.map(
      (candidate) => candidate.isSupervisor
    );

    if (primaryValueArray.includes(true) && !allEqual(supervisorValueArray)) {
      setTodoEventCandidateDtos(newTodoEventCandidateDtos);
    }
  };

  const removeSelectedMember = (candidateId) => {
    let newTodoEventCandidateDtos = todoEventCandidateDtos.filter(
      (candidate) => candidate.candidateId !== candidateId
    );

    setTodoEventCandidateDtos(newTodoEventCandidateDtos);

    let newSelectTodoEventCandidateDtos = form
      .getFieldValue("todoEventCandidateDtos")
      .filter((obj) => obj !== candidateId);

    form.setFieldsValue({
      todoEventCandidateDtos: newSelectTodoEventCandidateDtos,
    });
  };

  const disableSubmit =
    todoEventCandidateDtos && todoEventCandidateDtos.length === 1
      ? true
      : false;

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      onClose={onAddUpdateCountryClose}
      className="drawer"
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">{`${
          isUpdateCountry ? "Update" : "Create"
        } To Do `}</h4>
      </Header>
      <div className="gray__form gray-margin margin1">
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          form={form}
        >
          <Row gutter={[8, 0]}>
            <Col lg={24}>
              <Form.Item
                label="Subject"
                name="subject"
                className="form__group"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input placeholder="Please enter subject" />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Progress"
                name="todoEventStatusId"
                className="form__group"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Select placeholder="Select Progress">
                  {!isEmpty(allEventStatusListing) &&
                    allEventStatusListing.map((status, index) => {
                      return (
                        <Option value={status.id} key={index}>
                          {status.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Priority"
                name="organizerEventPriorityId"
                className="form__group"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Select placeholder="Select Priority">
                  {!isEmpty(organizerEventPriorityListing) &&
                    organizerEventPriorityListing.map((status, index) => {
                      return (
                        <Option value={status.id} key={index}>
                          {status.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Start Date"
                name="startDate"
                className="form__group"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return current && current < moment();
                  }}
                  format="YYYY/MM/DD"
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Due Datee"
                name="dueDate"
                className="form__group"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    return (
                      current &&
                      (form.getFieldValue("startDate")
                        ? current < form.getFieldValue("startDate")
                        : current < moment())
                    );
                  }}
                  format="YYYY/MM/DD"
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Todo Label"
                name="organizerEventLabelId"
                className="form__group"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Select placeholder="Select Goal">
                  {!isEmpty(organizerEventLabelListing) &&
                    organizerEventLabelListing.map((status, index) => {
                      return (
                        <Option value={status.id} key={index}>
                          {status.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                label="Remarks"
                name="notes"
                className="form__group"
                rules={[
                  {
                    required: false,
                    message: "Required!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Reminder Date"
                name="reminderDate"
                className="form__group"
              >
                <DatePicker
                  disabledDate={(current) => {
                    return (
                      current < moment()
                      // form.getFieldValue('endDate') ? current > form.getFieldValue('endDate') : current < moment()
                    );
                  }}
                  format="YYYY/MM/DD"
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="Reminder Time"
                className="form__group"
                name="reminderTime"
              >
                <TimePicker format="HH:mm" />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Checkbox
                checked={assignMembers}
                valuePropName="checked"
                onChange={() => setAssignMembers(!assignMembers)}
              >
                Assign
              </Checkbox>
            </Col>
            <Col lg={24}>
              <Form.Item name="todoEventCandidateDtos" className="form__group">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Please select"
                  onChange={changeEmployeeDto}
                  disabled={!assignMembers}
                >
                  {!isEmpty(meetingInvitees) &&
                    meetingInvitees.map((employee, index) => {
                      return (
                        <Option
                          value={employee.appUserId}
                          title={employee.fullName}
                          key={index}
                        >
                          {employee.fullName}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {!isEmpty(selectedToDoEvent) ? (
              <Col lg={24}>
                <div className="appointment-select-form form__group">
                  {todoEventCandidateDtos?.map((candidate, index) => {
                    return (
                      <div className="appointment-candidate" key={index}>
                        <span>{candidate.candidateName}</span>
                        <span>
                          <Radio.Group
                            onChange={(e) =>
                              changeCandidatePrimary(e.target.value, index)
                            }
                            value={
                              candidate.isPrimary
                                ? "primary"
                                : candidate.isSupervisor
                                ? "supervisor"
                                : ""
                            }
                          >
                            <Radio value={"supervisor"}>Supervisor</Radio>
                            <Radio value={"primary"}>Primary</Radio>
                          </Radio.Group>
                        </span>
                        <span
                          onClick={() => changeCandidatePrimary("reset", index)}
                        >
                          reset
                        </span>
                        <span
                          onClick={() =>
                            removeSelectedMember(candidate.candidateId)
                          }
                        >
                          <CloseOutlined />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </Col>
            ) : (
              ""
            )}
            {!isEmpty(selectedToDoEvent) && (
              <Col lg={24}>
                <Form.Item
                  label="Change Private User"
                  name="privateUserId"
                  className="form__group"
                >
                  <Select
                    allowClear
                    placeholder="Please select user"
                    disabled={todoEventCandidateDtos.length > 0}
                  >
                    {!isEmpty(currentToDoEventMembers) &&
                      currentToDoEventMembers.map((employee, index) => {
                        return (
                          <Option
                            value={employee.candidateId}
                            title={employee.candidateName}
                            key={index}
                          >
                            {employee.candidateName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <div className="note">
                  *Please empty assigned members to change private user
                </div>
              </Col>
            )}
            <Col lg={24}>
              <Form.Item
                name="isActive"
                valuePropName="checked"
                initialValue={true}
              >
                <Checkbox>Active</Checkbox>
              </Form.Item>
            </Col>
            <Button type="primary" htmlType="submit" disabled={disableSubmit}>
              Submit
            </Button>
          </Row>
        </Form>
      </div>
    </Drawer>
  );
};

export default ToDoForm;
