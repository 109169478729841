import { Card } from "antd";
import WeeklyTableReport from "./tableReport";
import CeadSearch from "components/ceadSearch";

export default function WeeklyReport(props) {
  const {
    checkedValues,
    setCheckValue,
    setSearchValue,
    searchValues,
    initialSearchValues,
  } = props;
  return (
    <>
      <Card
        style={{
          margin: 25,
        }}
      >
        <CeadSearch
          checkedValues={checkedValues}
          setCheckValue={setCheckValue}
          setSearchValue={setSearchValue}
          searchValues={searchValues}
          initialSearchValues={initialSearchValues}
          tab="weeklyReport"
        />
        <WeeklyTableReport props={props} />
      </Card>
    </>
  );
}
