import { appBaseUrl } from "utils/config";
import { getService, postService, putService } from "../../commonServices";
import queryString from "query-string";
export class documentUpload {
  documentRequestUpload(body) {
    let url = `${appBaseUrl}/documentRequest/documentUpload`;
    let data = postService(url, body);
    return data;
  }
  documentRequestPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/documentRequest/documentUploadPaginationByReference?${query}`;
    let data = getService(url);
    return data;
  }

  documentRequestUploadTempFile(files, fileLabelTypeId) {
    // console.log('tempFile',files,fileLabelTypeId)
    let url = `${appBaseUrl}/file/uploadFile/${fileLabelTypeId}`;
    let data = postService(url, files);
    return data;
  }

  updateContactData(id, body) {
    let url = `${appBaseUrl}/contactRequest/contactCompanyLightUpdate/${id}`;
    let data = putService(url, body);
    return data;
  }
}
