import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./index.scss";
import FallBackWrap from "./components/Fallback/FallBackWrapper";

ReactDOM.render(
  <FallBackWrap>
    <App />
  </FallBackWrap>,
  document.getElementById("uzz")
);
