import { Button, Col, Form, Input, Row, Select, Steps } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { isEmpty, set } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAmmountListing,
  getGuestEntertainmentDetails,
  guestEntertainmentAccountantAction,
  guestEntertainmentManagementAction,
  guestEntertainmentSupervisorAction,
} from "services/redux/requistions/leaveType/action";
import storage from "../../utils/storage";

const { Step } = Steps;
const GuestEntertaimentDetails = ({
  guestInfoId,
  setInstanceMessageCenterListing,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;

  const { amountResponse, amountResponseLoading } = useSelector(
    (state) => state.leaveType
  );
  const { guestEntertainmentDetailResponse, guestEntertainmentDetailLoading } =
    useSelector((state) => state.leaveType);

  const [geStatus, setGeStatus] = useState(false);
  const [position, setPosition] = useState();

  useEffect(() => {
    dispatch(getGuestEntertainmentDetails(guestInfoId));
  }, [guestInfoId]);

  // console.log("guestInfoDetails", guestInfoId);

  useEffect(() => {
    dispatch(getAmmountListing());
  }, []);

  let localStorageId = storage.getItem("userId");

  useEffect(() => {
    const status =
      guestEntertainmentDetailResponse?.currentGuestEntertainmentStatus;
    setPosition(status);
  }, [guestEntertainmentDetailResponse]);

  const supervisorStatus =
    guestEntertainmentDetailResponse?.currentGuestEntertainmentStatus;

  const supervisorUsers =
    guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.filter(
      (user) =>
        user?.participantDetails?.participantId === localStorageId &&
        user?.participantTypeName === "Supervisor"
    );

  const isSupervisor = supervisorUsers?.some(
    (user) => user?.participantDetails?.participantId === localStorageId
  );

  const managementUsers =
    guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.filter(
      (user) =>
        user?.participantDetails?.participantId === localStorageId &&
        user?.participantTypeName === "Management"
    );
  const isManagement = managementUsers?.some(
    (user) => user?.participantDetails?.participantId === localStorageId
  );

  const managementStatus =
    guestEntertainmentDetailResponse?.currentGuestEntertainmentStatus;

  const accountantUsers =
    guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.filter(
      (user) =>
        user?.participantDetails?.participantId === localStorageId &&
        user?.participantTypeName === "Accountant"
    );
  const isAccountant = accountantUsers?.some(
    (user) => user?.participantDetails?.participantId === localStorageId
  );

  const accountantStatus =
    guestEntertainmentDetailResponse?.currentGuestEntertainmentStatus;

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  const {
    supervisorActionResponse,
    supervisorActionResponseLoading,
    managementActionResponse,
    managementActionResponseLoading,
    accountantActionResponse,
    accountantActionResponseLoading,
  } = useSelector((state) => state.leaveType);

  const onFinish = (values) => {
    if (supervisorStatus === "SupervisorApproved") {
      const body1 = {
        ...values,
        isApproved: geStatus == "Approve" ? true : false,
      };
      dispatch(
        guestEntertainmentManagementAction(
          guestEntertainmentDetailResponse?.id,
          body1,
          geStatus,
          setInstanceMessageCenterListing
        )
      );
    } else if (managementStatus === "ManagementApproved") {
      const body2 = {
        ...values,
        isApproved: geStatus == "Approve" ? true : false,
      };

      dispatch(
        guestEntertainmentAccountantAction(
          guestEntertainmentDetailResponse?.id,
          body2,
          geStatus,
          setInstanceMessageCenterListing
        )
      );
    } else {
      let body = {
        approvedRejectRemarks: values.approvedRejectRemarks,
        isApproved: geStatus === "Approve" ? true : false,
      };
      console.log("body", body);
      dispatch(
        guestEntertainmentSupervisorAction(
          guestEntertainmentDetailResponse?.id,
          body,
          geStatus,
          setInstanceMessageCenterListing
        )
      );
    }
  };

  return (
    <>
      <div className="mail-leave-details">
        <div
          className="loan-progerss"
          style={{
            backgroundColor: "#eef2f8",
            padding: "10px",
            margin: "10px",
          }}
        >
          {
            <Steps
              current={
                accountantStatus === "AccountantApproved"
                  ? 5
                  : managementStatus === "ManagementApproved"
                  ? 3
                  : supervisorStatus === "SupervisorApproved"
                  ? 2
                  : 1
              }
            >
              <Step title="Apply" />
              <Step
                title={
                  supervisorStatus === "SupervisorApproved" ||
                  supervisorStatus === "ManagementApproved" ||
                  supervisorStatus === "AccountantApproved"
                    ? "Approved"
                    : supervisorStatus === "SupervisorRejected"
                    ? "Rejected"
                    : "Pending"
                }
                status={
                  supervisorStatus === "SupervisorRejected" ? "error" : ""
                }
                description="(Supervisor)"
              />
              <Step
                title={
                  accountantStatus === "ManagementApproved" ||
                  supervisorStatus === "AccountantApproved"
                    ? "Approved"
                    : accountantStatus === "ManagementRejected"
                    ? "Rejected"
                    : "Pending"
                }
                status={
                  accountantStatus === "ManagementRejected" ? "error" : ""
                }
                description="(Management)"
              />
              <Step
                title={
                  managementStatus === "AccountantApproved"
                    ? "Approved"
                    : managementStatus === "AccountantRejected"
                    ? "Rejected"
                    : "Pending"
                }
                status={
                  managementStatus === "AccountantRejected" ? "error" : ""
                }
                description="(Accountant)"
              />
              <Step title="Approved" />
            </Steps>
          }
        </div>
        <div className="leave-details">
          <Row gutter={20}>
            <Col lg={16} className="leave-col">
              <div className="leave-title">Guest Names</div>
              <div className="leave-input">
                {guestEntertainmentDetailResponse?.guestNames}
              </div>
            </Col>
            <Col lg={8} className="leave-col">
              <div className="leave-title">Applied Number of guests</div>
              <div className="leave-input">
                {guestEntertainmentDetailResponse?.applyNumberOfGuest}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8} className="leave-col">
              <div className="leave-title">Applied Food Amount</div>
              <div className="leave-input">
                {
                  guestEntertainmentDetailResponse?.applyFoodAmountDetails
                    ?.ratingAmount
                }
                (
                {
                  guestEntertainmentDetailResponse?.applyFoodAmountDetails
                    ?.displayName
                }
                )
              </div>
            </Col>

            <Col lg={8} className="leave-col">
              <div className="leave-title">Applied Drink Amount</div>
              <div className="leave-input">
                {
                  guestEntertainmentDetailResponse?.applyDrinkAmountDetails
                    ?.ratingAmount
                }
                (
                {
                  guestEntertainmentDetailResponse?.applyDrinkAmountDetails
                    ?.displayName
                }
                )
              </div>
            </Col>
            <Col lg={8} className="leave-col">
              <div className="leave-title">Arrival Date</div>
              <div className="leave-input">
                {
                  guestEntertainmentDetailResponse?.applyDrinkAmountDetails
                    ?.ratingAmount
                }
                (
                {
                  guestEntertainmentDetailResponse?.applyDrinkAmountDetails
                    ?.displayName
                }
                )
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col lg={12} className="leave-col">
              <div className="leave-title">Sister Company Name</div>
              <div className="leave-input">
                {guestEntertainmentDetailResponse?.sisterCompanyName}
              </div>
            </Col>
            <Col lg={6} className="leave-col">
              <div className="leave-title">To be Entertained at </div>
              <div className="leave-input">
                {guestEntertainmentDetailResponse?.isHomeRestaurant === true ? (
                  <>Home</>
                ) : (
                  <>Resturant</>
                )}
              </div>
            </Col>
            <Col lg={6} className="leave-col">
              <div className="leave-title">Pool</div>
              <div className="leave-input">
                {guestEntertainmentDetailResponse?.isPoolCookRequired ? (
                  <>True</>
                ) : (
                  <>False</>
                )}
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col lg={12} className="leave-col">
              <div className="leave-title">Guest Company Name</div>
              <div className="leave-input">
                {guestEntertainmentDetailResponse?.guestCompanyName}
              </div>
            </Col>
          </Row>
          {position === "SupervisorApproved" && (
            <Row gutter={20}>
              <Col lg={24} className="leave-col">
                <div className="leave-title">Supervisor comment</div>
                <div className="leave-input">
                  {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                    (x) => {
                      if (x.participantTypeName === "Supervisor")
                        return x.approveRejectRemarks;
                    }
                  )}
                </div>
              </Col>
            </Row>
          )}
          {position === "ManagementApproved" && (
            <>
              <Row gutter={20}>
                <Col lg={24} className="leave-col">
                  <div className="leave-title">Supervisor comment</div>
                  <div className="leave-input">
                    {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                      (x) => {
                        if (x.participantTypeName === "Supervisor")
                          return x.approveRejectRemarks;
                      }
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col lg={8} className="leave-col">
                  <div className="leave-title">Approved Food Amount</div>
                  <div className="leave-input">
                    {
                      guestEntertainmentDetailResponse
                        ?.managementFoodAmountApprovedDetails?.ratingAmount
                    }
                    (
                    {
                      guestEntertainmentDetailResponse
                        ?.managementFoodAmountApprovedDetails?.displayName
                    }
                    )
                  </div>
                </Col>

                <Col lg={8} className="leave-col">
                  <div className="leave-title">Approved Drink Amount</div>
                  <div className="leave-input">
                    {
                      guestEntertainmentDetailResponse
                        ?.managementFoodAmountApprovedDetails?.ratingAmount
                    }
                    (
                    {
                      guestEntertainmentDetailResponse
                        ?.managementDrinkAmountApprovedDetails?.displayName
                    }
                    )
                  </div>
                </Col>
                <Col lg={8} className="leave-col">
                  <div className="leave-title">Approved guests</div>
                  <div className="leave-input">
                    {guestEntertainmentDetailResponse?.approvedNumberOfGuest}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={20} className="leave-col">
                  <div className="leave-title">Management comment</div>
                  <div className="leave-input">
                    {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                      (x) => {
                        if (x.participantTypeName === "Management")
                          return x.approveRejectRemarks;
                      }
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}

          {position === "AccountantApproved" && (
            <>
              <Row gutter={20}>
                <Col lg={24} className="leave-col">
                  <div className="leave-title">Supervisor comment</div>
                  <div className="leave-input">
                    {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                      (x) => {
                        if (x.participantTypeName === "Supervisor")
                          return x.approveRejectRemarks;
                      }
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col lg={8} className="leave-col">
                  <div className="leave-title">Approved Food Amount</div>
                  <div className="leave-input">
                    {
                      guestEntertainmentDetailResponse
                        ?.managementFoodAmountApprovedDetails?.displayName
                    }
                  </div>
                </Col>
                <Col lg={8} className="leave-col">
                  <div className="leave-title">Approved guests</div>
                  <div className="leave-input">
                    {guestEntertainmentDetailResponse?.approvedNumberOfGuest}
                  </div>
                </Col>
                <Col lg={8} className="leave-col">
                  <div className="leave-title">Approved Drink Amount</div>
                  <div className="leave-input">
                    {
                      guestEntertainmentDetailResponse
                        ?.managementDrinkAmountApprovedDetails?.displayName
                    }
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={24} className="leave-col">
                  <div className="leave-title">Management comment</div>
                  <div className="leave-input">
                    {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                      (x) => {
                        if (x.participantTypeName === "Management")
                          return x.approveRejectRemarks;
                      }
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col lg={24} className="leave-col">
                  <div className="leave-title">Accountant comment</div>
                  <div className="leave-input">
                    {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                      (x) => {
                        if (x.participantTypeName === "Accountant")
                          return x.approveRejectRemarks;
                      }
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Form form={form} onFinish={onFinish}>
            {position === "SupervisorApproved" && isManagement && (
              <>
                <Row gutter={20}>
                  <Col lg={8} className="leave-col">
                    <div className="leave-title">Approved Number of Guests</div>
                    <div className="leave-input">
                      <Form.Item
                        required="true"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                        name="approvedNumberOfGuest"
                        style={{ width: "100%" }}
                      >
                        <Input type="number"></Input>
                      </Form.Item>
                    </div>
                  </Col>

                  <Col lg={8} className="leave-col">
                    <div className="leave-title">Food Rating Amount</div>
                    <div className="leave-input">
                      <Form.Item
                        required="true"
                        name="foodRatingAmountId"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                        style={{ width: "95%" }}
                      >
                        <Select placeholder="Select Company" autoComplete="off">
                          {!isEmpty(amountResponse) &&
                            amountResponse.map((amount, index) => {
                              return (
                                <Option value={amount.id} key={index}>
                                  {amount.displayName}({amount.ratingAmount})
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col lg={8} className="leave-col">
                    <div className="leave-title">Drinking Rating Amount</div>
                    <div className="leave-input">
                      <Form.Item
                        required="true"
                        name="drinkRatingAmountId"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                        style={{ width: "95%" }}
                      >
                        <Select placeholder="Select Company" autoComplete="off">
                          {!isEmpty(amountResponse) &&
                            amountResponse.map((amount, index) => {
                              return (
                                <Option value={amount.id} key={index}>
                                  {amount.displayName}({amount.ratingAmount})
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row></Row>
              </>
            )}

            {position === "Apply" && isSupervisor && (
              <>
                <Form.Item
                  name="approvedRejectRemarks"
                  required="true"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                  style={{ width: "100%" }}
                  label="Remarks"
                  labelAlign="left"
                >
                  <Col span={24}>
                    <TextArea />
                  </Col>
                </Form.Item>
                <Row span={24} style={{ paddingTop: "20px" }}>
                  <Button
                    type="primary"
                    style={{ marginRight: "15px" }}
                    htmlType="submit"
                    loading={supervisorActionResponseLoading}
                    onClick={() => {
                      setGeStatus("Approve");
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    type="danger"
                    htmlType="submit"
                    onClick={() => {
                      setGeStatus("Reject");
                    }}
                  >
                    Reject
                  </Button>
                </Row>
              </>
            )}

            {position === "SupervisorApproved" && isManagement && (
              <>
                <Form.Item
                  name="approvedRejectRemarks"
                  style={{ width: "100%" }}
                  label="Remarks"
                  required="true"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                  labelAlign="left"
                >
                  <Col span={24}>
                    <TextArea />
                  </Col>
                </Form.Item>
                <Row span={24} style={{ paddingTop: "20px" }}>
                  <Button
                    type="primary"
                    style={{ marginRight: "15px" }}
                    htmlType="submit"
                    loading={managementActionResponseLoading}
                    onClick={() => {
                      setGeStatus("Approve");
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    type="danger"
                    htmlType="submit"
                    onClick={() => {
                      setGeStatus("Reject");
                    }}
                  >
                    Reject
                  </Button>
                </Row>
              </>
            )}

            {position === "ManagementApproved" && isAccountant && (
              <>
                <Form.Item
                  name="approvedRejectRemarks"
                  style={{ width: "100%" }}
                  label="Remarks"
                  labelAlign="left"
                  required="true"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Col span={24}>
                    <TextArea />
                  </Col>
                </Form.Item>
                <Row span={24} style={{ paddingTop: "20px" }}>
                  <Button
                    type="primary"
                    style={{ marginRight: "15px" }}
                    htmlType="submit"
                    loading={managementActionResponseLoading}
                    onClick={() => setGeStatus("Approve")}
                  >
                    Approve
                  </Button>
                  <Button
                    type="danger"
                    htmlType="submit"
                    onClick={() => setGeStatus("Reject")}
                  >
                    Reject
                  </Button>
                </Row>
              </>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

export default GuestEntertaimentDetails;
