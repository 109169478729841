import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import aniversary from "../../../assets/svg/wedding.svg";
import birthday from "../../../assets/svg/birthday.svg";
import holiday from "../../../assets/svg/vacation.svg";
import { Form, Image, Modal, Skeleton, Tooltip, Select } from "antd";
import moment from "moment";
import BirthdayModal from "../Modal/BirthdayModal";
import { WechatOutlined } from "@ant-design/icons";
import InternalMCompose from "components/internalMNew";

import { holidayEventsPagination } from "services/redux/holiday/action";

const internalMessageGreen = "assets/svg/internal-msg-12-green.svg";
const day = "assets/svg/internationalDay.svg";
const man = "assets/images/icons/man.png";

const PublicEvents = ({
  allCalenderData,
  textRef,
  type,
  setBirthdayModal,
  setPerson,
  calendarEventsLoading,
  birthdayModal,
  person,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const pageSize = 100;

  const [internalVisible, setInternalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [showHoliday, setShowHoliday] = useState(false);
  const [holidayData, setHolidayData] = useState([]);

  const { holidayPaginationResponse } = useSelector((state) => state.holiday);

  const tenderDays = (date) => {
    const momentDate = moment.utc(date, "YYYY-MM-DD");
    const momentNow = moment().startOf("day").utc(date, "YYYY-MM-DD");
    const diffDays = Math.abs(momentNow.diff(momentDate, "days"));
    if (diffDays === 0) return "Today";
    if (diffDays < 30) {
      return diffDays + "d";
    } else if (diffDays < 365) {
      return Math.trunc(diffDays / 30) + "m";
    } else {
      return Math.trunc(diffDays / 365) + "y";
    }
  };

  const birthdayDate = (date) => {
    const momentDate = moment.utc(date, "YYYY-MM-DD").year(2000);
    const momentNow = moment()
      .startOf("day")
      .utc(date, "YYYY-MM-DD")
      .year(2000);
    const diffDays = Math.abs(momentNow.diff(momentDate, "days"));
    if (diffDays === 0) return "Today";
    if (diffDays < 30) {
      return diffDays + "d";
    } else if (diffDays < 365) {
      return Math.trunc(diffDays / 30) + "m";
    } else {
      return Math.trunc(diffDays / 365) + "y";
    }
  };

  useEffect(() => {
    const sortedData = [...allCalenderData];
    sortedData.sort((a, b) => moment(a.start).diff(b.start));
    // setData(sortedData);

    // filtering out events that have ended
    const currentDate = moment();
    const filteredData = sortedData.filter((event) =>
      // moment(event.end).isAfter(currentDate) ||
      moment(event.start).isSameOrAfter(currentDate, "day")
    );
    setData(filteredData);
  }, [allCalenderData]);

  useEffect(() => {
    if (!holidayPaginationResponse) return;
    setHolidayData(holidayPaginationResponse?.items);
    // setTotalCount(holidayPaginationResponse.totalCount);
  }, [holidayPaginationResponse]);

  // holiday event
  const date = [
    {
      value: "2080",
      label: "2080",
    },
    {
      value: "2081",
      label: "2081",
    },
  ];

  const getData = (MaxResultCount, SearchByNepaliYear, SkipCount) => {
    dispatch(
      holidayEventsPagination({
        MaxResultCount,
        SearchByNepaliYear,
        SkipCount,
      })
    );
  };

  const handleChange = (value) => {
    if (value) {
      setShowHoliday(true);
      getData(pageSize, value, 0);
    }
  };

  const handleClear = () => {
    setShowHoliday(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          height: "100%",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">
            {" "}
            {showHoliday ? "Holiday" : "Events"}
          </h4>
          <div className="" style={{ display: "flex", alignItems: "center" }}>
            <Select
              allowClear
              placeholder="Holiday"
              options={date}
              style={{ width: "120px", marginRight: "8px" }}
              onChange={handleChange}
              onClear={handleClear}
            />
            <WechatOutlined
              onClick={() => {
                setInternalVisible(true);
              }}
            />
          </div>
        </div>

        <div className="dashboards__body--main" style={{ height: "90%" }}>
          <ul className="dashboards__todolists" style={{ height: "100%" }}>
            {showHoliday ? (
              holidayData.length > 0 ? (
                holidayData.map((holiday, i) => (
                  <li key={i} className="dashboards__events--list">
                    <div>
                      <span
                        style={{
                          width: "20px",
                          display: "inline-block",
                          marginRight: "6px",
                        }}
                      >
                        {i + 1}.
                      </span>
                      <span>{holiday.title}</span>
                    </div>
                    <div>{moment(holiday.date).format("DD-MMM-YYYY")}</div>
                  </li>
                ))
              ) : (
                <div className="dashboards__visitors--none">
                  <Image className="data-no-img" src={man} />
                  <span>No holiday information available.</span>
                </div>
              )
            ) : (
              <>
                {data?.length > 0 ? (
                  data?.map((list, i) => (
                    <>
                      {list.event !== "day" ? (
                        <li
                          key={i}
                          className="dashboards__events--list"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className={`dashboards__events--listTitle ${list?.event} `}
                            style={{ flex: 6 }}
                          >
                            <span
                              style={{ width: "20px", display: "inline-block" }}
                            >
                              {i + 1}.
                            </span>

                            {list?.type === "Birthday" ? (
                              <img
                                src={birthday}
                                height={15}
                                alt="b"
                                style={{ paddingRight: "10px" }}
                              />
                            ) : list?.type === "Anniversary" ? (
                              <img
                                src={aniversary}
                                height={15}
                                alt="b"
                                style={{ paddingRight: "10px" }}
                              />
                            ) : (
                              <img
                                src={holiday}
                                height={15}
                                alt="b"
                                style={{ paddingRight: "5px" }}
                              />
                            )}
                            <Tooltip
                              title={
                                list?.type === "Holiday"
                                  ? list.title
                                  : `${list?.type} of ${list.name}`
                              }
                            >
                              <span ref={textRef}>
                                {list?.type === "Holiday"
                                  ? list.title
                                  : `${list?.type} of ${list.name}`}
                              </span>
                            </Tooltip>
                          </div>

                          {type === "private" && (
                            <div
                              className="dashboards__events--date"
                              style={{
                                flex: 5,
                                justifyContent: "right",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {moment().format("DD-MMM-YYYY") ===
                              moment(list.start).format("DD-MMM-YYYY") ? (
                                <span style={{ flexShrink: 0 }}>
                                  {moment(list.start).format("h:mm A")}-
                                  {moment(list.end).format("h:mm A")}{" "}
                                </span>
                              ) : (
                                <>
                                  <span style={{ flexShrink: 0 }}>
                                    {moment(list.start).format("h:mm A")}{" "}
                                    {moment(list.start).format("DD MMM YYYY")}
                                  </span>
                                </>
                              )}
                            </div>
                          )}

                          {type === "public" && (
                            <div
                              className="dashboards__events--date"
                              style={{
                                flex: 5,
                                justifyContent: "right",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {list.type !== "Holiday" ? (
                                <>
                                  <span style={{ flexShrink: 0 }}>
                                    {/* {list?.monthDay} */}
                                    {list
                                      ? moment(list?.start).format(
                                          "DD-MMM-YYYY"
                                        )
                                      : null}
                                  </span>
                                  <span
                                    className="remaining-wrapper"
                                    style={{
                                      borderRadius: "34px",
                                      backgroundColor: "#e9e9e9",
                                      padding: "3px 8px",
                                      color: "#6d6d6d",
                                      marginLeft: "8px",
                                      marginRight: "10px",
                                      flexShrink: 0,
                                    }}
                                  >
                                    {birthdayDate(list.date)}
                                  </span>

                                  <img
                                    src={internalMessageGreen}
                                    onClick={() => {
                                      setBirthdayModal(true);
                                      setPerson(list);
                                    }}
                                    height="12px"
                                    alt="message "
                                  />
                                </>
                              ) : (
                                <>
                                  <span style={{ flexShrink: 0 }}>
                                    {moment(list.start).format("DD-MMM-YYYY")}
                                  </span>
                                  <span
                                    className="remaining-wrapper"
                                    style={{
                                      borderRadius: "34px",
                                      backgroundColor: "#e9e9e9",
                                      padding: "3px 8px",
                                      color: "#6d6d6d",
                                      marginLeft: "8px",
                                      flexShrink: 0,
                                    }}
                                  >
                                    {tenderDays(list.start)}
                                  </span>
                                  <span style={{ width: "20px" }}></span>
                                </>
                              )}
                            </div>
                          )}
                        </li>
                      ) : (
                        <li
                          key={i}
                          className="dashboards__events--list specialDay"
                        >
                          <div className="dashboards__events--listTitle day">
                            <Image src={day} /> {list.eventTitle}
                          </div>
                          <div
                            className="dashboards__events--date"
                            style={{ width: "32%" }}
                          >
                            {list.date}
                          </div>
                        </li>
                      )}
                    </>
                  ))
                ) : !calendarEventsLoading ? (
                  <div className="dashboards__visitors--none">
                    <Image className="data-no-img" src={man} />
                    <span>There is no information.</span>
                  </div>
                ) : (
                  <Skeleton />
                )}
              </>
            )}
          </ul>
        </div>
        <BirthdayModal
          birthdayModal={birthdayModal}
          setBirthdayModal={setBirthdayModal}
          person={person}
        />
      </div>
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="Events"
        />
      </Modal>
    </>
  );
};

export default PublicEvents;
