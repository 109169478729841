import { message } from "antd";
import { error, finish, init, success } from "services/common";
// import { PROCUREMENT_TYPES } from "../../../types";
import { PROCUREMENT_TYPES } from "services/types";
import { Procurement } from "./api";
import { isEmpty } from "lodash";

const procurement = new Procurement();

export const getProcurement = (body, type) => {
  if (type === "approval") {
    return async (dispatch) => {
      dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_APPROVAL_GET));
      const response = await procurement.getProcurement(body);
      dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_APPROVAL_GET));
      if (response?.isSuccess) {
        dispatch(
          success(PROCUREMENT_TYPES.PROCUREMENT_APPROVAL_GET, response?.data)
        );
      } else {
        dispatch(error(response?.errorMessage));
      }
    };
  } else if (type === "No Approval") {
    return async (dispatch) => {
      dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_GET));
      const response = await procurement.getProcurement(body);
      dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_GET));
      if (response?.isSuccess) {
        dispatch(success(PROCUREMENT_TYPES.PROCUREMENT_GET, response?.data));
      } else {
        dispatch(error(response?.errorMessage));
      }
    };
  }
};
export const addProcurement = (body) => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_ADD));
    const response = await procurement.addProcurement(body);
    dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_ADD));
    if (response?.isSuccess) {
      dispatch(success(PROCUREMENT_TYPES.PROCUREMENT_ADD, response?.data));
      message.success("successfully added procurement");
      dispatch(getProcurement());
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};
// export const getProcurementDetailsIndividual = (id) => {
//   return async (dispatch) => {
//     dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_GET_DETAILS));
//     const response = await procurement.getProcurementDetails(id);

//     dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_GET_DETAILS));
//     if (response?.isSuccess) {
//       dispatch(
//         success(PROCUREMENT_TYPES.PROCUREMENT_GET_DETAILS, response?.data)
//       );
//     } else {
//       console.log("response response?.errorMessage", response?.errorMessage);
//       dispatch(error(response?.errorMessage));
//     }
//   };
// };

export const getProcurementDetailsIndividual = (id) => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_GET_DETAILS));
    const response = await procurement.getProcurementDetails(id);

    dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_GET_DETAILS));
    if (response?.isSuccess) {
      dispatch(
        success(PROCUREMENT_TYPES.PROCUREMENT_GET_DETAILS, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const procurementApplicationUpdateSupervisor = (
  body,
  id,
  isApproval,
  setRequisitionModal,
  getProcurementAction,
  getProcurementCounter,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_UPDATE_SUPERVISOR));
    const response = await procurement.procurementUpdateSupervisor(body, id);
    dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_UPDATE_SUPERVISOR));
    setRequisitionModal?.(false);
    getProcurementAction?.("procurement");
    if (response?.isSuccess) {
      dispatch(
        success(PROCUREMENT_TYPES.PROCUREMENT_UPDATE_SUPERVISOR, response?.data)
      );

      if (isApproval) {
        message.success("Procument approved from supervisor").then(
          setInstanceMessageCenterListing?.((prevData) => {
            if (!isEmpty(prevData.items)) {
              return {
                items: [response?.data?.internalMessage, ...prevData.items],
                totalCount: prevData.totalCount + 1,
              };
            } else {
              return {
                items: [response?.data?.internalMessage],
                totalCount: 1,
              };
            }
          })
        );
      } else {
        message.success(" Procurement rejected from supervisor ").then(
          setInstanceMessageCenterListing?.((prevData) => {
            if (!isEmpty(prevData.items)) {
              return {
                items: [response?.data?.internalMessage, ...prevData.items],
                totalCount: prevData.totalCount + 1,
              };
            } else {
              return {
                items: [response?.data?.internalMessage],
                totalCount: 1,
              };
            }
          })
        );
      }
      getProcurementCounter && dispatch(getProcurementCounter());
      dispatch(getProcurementSummary("guestentertainment"));
    } else {
      dispatch(error(response?.errorMessage));
      message.error("The procurement has error");
      setRequisitionModal?.(false);
    }
  };
};

export const procurementApplicationUpdateAccountant = (
  body,
  id,
  isApproval,
  setRequisitionModal,
  getProcurementAction,
  getProcurementCounter,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_UPDATE_ACCOUNTANT));
    const response = await procurement.procurementUpdateAccountant(body, id);
    dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_UPDATE_ACCOUNTANT));
    if (response?.isSuccess) {
      dispatch(
        success(PROCUREMENT_TYPES.PROCUREMENT_UPDATE_ACCOUNTANT, response?.data)
      );
      if (isApproval) {
        message.success("Procurement approved from accountant").then(
          setInstanceMessageCenterListing?.((prevData) => {
            if (!isEmpty(prevData.items)) {
              return {
                items: [response?.data?.internalMessage, ...prevData.items],
                totalCount: prevData.totalCount + 1,
              };
            } else {
              return {
                items: [response?.data?.internalMessage],
                totalCount: 1,
              };
            }
          })
        );
      } else {
        message.success("Procurement rejected from accountant").then(
          setInstanceMessageCenterListing?.((prevData) => {
            if (!isEmpty(prevData.items)) {
              return {
                items: [response?.data?.internalMessage, ...prevData.items],
                totalCount: prevData.totalCount + 1,
              };
            } else {
              return {
                items: [response?.data?.internalMessage],
                totalCount: 1,
              };
            }
          })
        );
      }
      setRequisitionModal?.(false);
      getProcurementAction?.("procurement");
      getProcurementCounter && dispatch(getProcurementCounter());
      dispatch(getProcurementSummary("guestentertainment"));
    } else {
      dispatch(error(response?.errorMessage));
      setRequisitionModal?.(false);
    }
  };
};

export const procurementApplicationUpdateManagement = (
  body,
  id,
  isApproval,
  setRequisitionModal,
  getProcurementAction,
  getProcurementCounter,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_UPDATE_MANAGEMENT));
    const response = await procurement.procurementUpdateManagement(body, id);
    dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_UPDATE_MANAGEMENT));
    if (response?.isSuccess) {
      dispatch(
        success(PROCUREMENT_TYPES.PROCUREMENT_UPDATE_MANAGEMENT, response?.data)
      );

      if (isApproval) {
        message.success("The procurement is approved");
        setInstanceMessageCenterListing?.((prevData) => {
          if (!isEmpty(prevData.items)) {
            return {
              items: [response?.data?.internalMessage, ...prevData.items],
              totalCount: prevData.totalCount + 1,
            };
          } else {
            return {
              items: [response?.data?.internalMessage],
              totalCount: 1,
            };
          }
        });
      } else {
        message.success("The procurement is rejected").then(
          setInstanceMessageCenterListing?.((prevData) => {
            if (!isEmpty(prevData.items)) {
              return {
                items: [response?.data?.internalMessage, ...prevData.items],
                totalCount: prevData.totalCount + 1,
              };
            } else {
              return {
                items: [response?.data?.internalMessage],
                totalCount: 1,
              };
            }
          })
        );
      }
      setRequisitionModal?.(false);
      getProcurementAction?.("procurement");
      getProcurementCounter && dispatch(getProcurementCounter());
      dispatch(getProcurementSummary("guestentertainment"));
    } else {
      dispatch(error(response?.errorMessage));
      message.error("The procurement has error");
    }
  };
};
export const getProcurementSummary = (type) => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_SUMMARY_MANAGEMENT));
    const response = await procurement.procurementSummaryManagement(type);
    dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_SUMMARY_MANAGEMENT));
    if (response?.isSuccess) {
      dispatch(
        success(
          PROCUREMENT_TYPES.PROCUREMENT_SUMMARY_MANAGEMENT,
          response?.data
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};
export const getProcurementCounter = () => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.PROCUREMENT_COUNTER));
    let response = await procurement.procurementCounter();
    dispatch(finish(PROCUREMENT_TYPES.PROCUREMENT_COUNTER));
    if (response?.isSuccess) {
      dispatch(success(PROCUREMENT_TYPES.PROCUREMENT_COUNTER, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getSimilarProcurement = (id) => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.SHOW_SIMILAR_PROCUREMENT));
    let response = await procurement.showSimilarProcurement(id);
    dispatch(finish(PROCUREMENT_TYPES.SHOW_SIMILAR_PROCUREMENT));
    if (response?.isSuccess) {
      dispatch(
        success(PROCUREMENT_TYPES.SHOW_SIMILAR_PROCUREMENT, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getProcurementTable = (id) => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.GET_PROCUMENT_LATEST));
    const response = await procurement.showProcurementTable(id);
    if (response?.isSuccess) {
      dispatch(success(PROCUREMENT_TYPES.GET_PROCUMENT_LATEST, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getAllSameTitleProcurement = (body) => {
  return async (dispatch) => {
    dispatch(init(PROCUREMENT_TYPES.GET_ALL_SIMILAR_PROCUREMENT));
    const response = await procurement.showAllSameSubjectProcurement(body);
    dispatch(finish(PROCUREMENT_TYPES.GET_ALL_SIMILAR_PROCUREMENT));
    if (response?.isSuccess) {
      dispatch(
        success(PROCUREMENT_TYPES.GET_ALL_SIMILAR_PROCUREMENT, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};
