import React from "react";
import { Table } from "antd";

function CheckListPresentation(props) {
  const { columns, data, loading } = props;

  return (
    <>
      <Table
        className="organizer__table company-details-table"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
      ></Table>
    </>
  );
}

export default CheckListPresentation;
