import { Form, Modal, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { showNumber } from "utils/showNumber";
import InternalMCompose from "components/internalMNew";
import { WechatOutlined } from "@ant-design/icons";
import { handleSpecialDate } from "utils/tooltipSepecialDate";

const DailyReport = ({
  dashboardFinancialResponse,
  dashboardFinancialResponseloading,
  type,
}) => {
  const [form] = Form.useForm();

  const [widthh, setWidthh] = useState(window.innerWidth);

  const [internalVisible, setInternalVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidthh(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const tooltipTitle = (date) => {
    return date !== null ? moment(date).format("YYYY MMM DD") : "";
  };

  const columns = [
    {
      title: "Particular",
      key: "particular",
      dataIndex: "particular",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={{
              textAlign: "left",
              fontSize: widthh < 1550 ? 13 : 15,
              fontWeight:
                record?.particular === "Difference(BF +  Income-outflow)"
                  ? "bold"
                  : "inherit",
            }}
          >
            {record?.particular}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Month",
      key: "forMonth",
      dataIndex: "forMonth",
      render: (_, record) => {
        return (
          <Tooltip
            title={
              // tooltipTitle(record?.lastUpdatedDate)
              handleSpecialDate(record?.lastUpdatedDate)
            }
          >
            <Typography.Paragraph
              style={{
                textAlign: "right",
                fontSize: widthh < 1550 ? 13 : 15,
                fontWeight:
                  record?.particular === "Difference(BF +  Income-outflow)"
                    ? "bold"
                    : "inherit",
              }}
            >
              {showNumber(record?.forMonth)}
            </Typography.Paragraph>
          </Tooltip>
        );
      },
    },
    {
      title: "FY",
      key: "forFY",
      dataIndex: "forFY ",
      render: (_, record) => {
        return (
          <Tooltip
            title={
              // tooltipTitle(record?.lastUpdatedDate)
              handleSpecialDate(record?.lastUpdatedDate)
            }
          >
            <Typography.Paragraph
              style={{
                textAlign: "right",
                fontSize: widthh < 1550 ? 13 : 15,
                fontWeight:
                  record?.particular === "Difference(BF +  Income-outflow)"
                    ? "bold"
                    : "inherit",
              }}
            >
              {showNumber(record?.forFY)}
            </Typography.Paragraph>
          </Tooltip>
        );
      },
    },
  ];
  const dataSource =
    type === "Cemat"
      ? dashboardFinancialResponse?.dailyReportMonthCemat
      : dashboardFinancialResponse?.dailyReportMonth;

  return (
    <div
      className=" honor-wrapper"
      // style={type === "Cemat" ? { paddingRight: "10px" } : {}}
    >
      <div
        className=" dashboards__events dashboards__body "
        style={type === "Cemat" ? { height: 320 } : { height: 300 }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">
            {type !== "Cemat" ? (
              <>Income Vs Outflow (Excluding CEMAT)</>
            ) : (
              <>Income Vs Outflow of CEMAT</>
            )}
          </h4>
          <WechatOutlined
            onClick={() => {
              setInternalVisible(true);
            }}
          />
        </div>

        <Table
          className="next-organizer-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={dashboardFinancialResponseloading}
          style={{ maxHeight: 220, overflow: "auto" }}
        />
      </div>
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin={
            type !== "Cemat"
              ? "Income Vs Outflow (Excluding CEMAT)"
              : "Income Vs Outflow of CEMAT"
          }
        />
      </Modal>
    </div>
  );
};

export default DailyReport;
