import React, { useState, useEffect } from "react";
import TinyColor2 from "tinycolor2";
import { isEmpty } from "lodash";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";

// antd
import { Button, Table, Tag, Space } from "antd";

// utils
import { LogoImage } from "utils/imageConstants";

export const StatusEventListing = ({ showAddUpdateListingDrawer }) => {
  const dispatch = useDispatch();

  const { allEventStatusListing, eventStatusListingLoading } = useSelector(
    (state) => state.statusListing
  );
  const [data, setdata] = useState([]);

  useEffect(() => {
    if (isEmpty(allEventStatusListing)) return;
    setdata(allEventStatusListing);
  }, [allEventStatusListing]);

  useEffect(() => {
    dispatch(getStatusEventListing());
  }, []);


  const Columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      render:(text,object,index) => index + 1 
     
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // sorter: true,
    },

    {
      title: " Color Hex Value",
      key: "colorHexValue",
      render: (record) => (
        <Tag color={record.colorHexValue}>
          <span
            style={{
              color: TinyColor2(record.colorHexValue).isLight()
                ? "black"
                : "white",
            }}>
            {record.colorHexValue}
          </span>
        </Tag>
      ),
    },
    {
      title: " Color Font Hex Value",
      key: "colorFontHexValue",
      render: (record) => (
        <Tag color={record.colorFontHexValue}>
          <span
            style={{
              color: TinyColor2(record.colorFontHexValue).isLight()
                ? "black"
                : "white",
            }}>
            {record.colorFontHexValue}
          </span>
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a
          className='action-btn action-btn-add'
          onClick={(e) => showAddUpdateListingDrawer(e, record)}>
          Edit Status Events
        </a>
      ),
    },
  ];
  return (
    <Table
      className='organizer__tables'
      columns={Columns}
      dataSource={data}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" />
        ),
        spinning: eventStatusListingLoading,
      }}></Table>
  );
};
