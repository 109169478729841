import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Skeleton, Button } from "antd";
import { getApplicationTemplate } from "services/redux/organizer/ApplicationTemplate/action";
import { actionForJobApplicantComposeEmail } from "services/redux/hrSystem/action";

function HrHiringMail({ selectedRowKeys }) {
  const dispatch = useDispatch();

  const {
    getApplicationTemplateResponse,
    getApplicationTemplateResponseLoading,
  } = useSelector((state) => state.application);

  const { composeMailJobApplicationLoading } = useSelector(
    (state) => state.hrSystemServicesReducer
  );

  const { TabPane } = Tabs;

  const [hrMailData, setHrMailData] = useState({
    templateId: "",
    applicantList: [],
  });

  console.log("hrMailData", hrMailData);

  useEffect(() => {
    dispatch(getApplicationTemplate());
  }, []);

  const TemplateEmailTabs = () => {
    return (
      <>
        <Tabs defaultActiveKey="1" tabPosition="left">
          {getApplicationTemplateResponse?.items &&
            getApplicationTemplateResponse?.items.map((item) => (
              <TabPane tab={item.title} key={item.id}>
                {/* <div>{item.content}</div> */}
                {console.log("item", item)}
                <div dangerouslySetInnerHTML={{ __html: item?.body }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    padding: "0 5em",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      //   setHrMailData({
                      //     templateId: item?.id,
                      //     applicantList: selectedRowKeys,
                      //   });
                      //   const data = {
                      //     templateId: item?.id,
                      //     applicantList: selectedRowKeys,
                      //   };
                      //   actionForJobApplicantComposeEmail(data);
                      dispatch(
                        actionForJobApplicantComposeEmail({
                          templateId: item?.id,
                          applicantList: selectedRowKeys,
                        })
                      );
                    }}
                    loading={composeMailJobApplicationLoading}
                  >
                    Submit
                  </Button>
                </div>
              </TabPane>
            ))}
        </Tabs>
      </>
    );
  };

  return (
    <div>
      {/* <Tabs
        defaultActiveKey="1"
        items={getApplicationTemplateResponse?.items}
      /> */}
      {!getApplicationTemplateResponseLoading ? (
        TemplateEmailTabs()
      ) : (
        <Skeleton />
      )}
    </div>
  );
}

export default HrHiringMail;
