import { Modal, Table } from "antd";
import { MailOutlined, FilePdfOutlined } from "@ant-design/icons";
// import { Link } from "react-router-dom";
import { PrincpalPaymentData } from "../paymentReceived/details";
import { useState } from "react";
// import AllFileViewer from "components/Documents/allFileViewer";
// import { cqBaseUrl } from "utils/config";
import { showNumber } from "utils/showNumber";

export default function PrincipleReceivedLogDetails({ singleTenderCeadData }) {
  //   const dispatch = useDispatch();
  const { id, tenderCEADPaymentReceivedPrincipalInvoiceLogDto } =
    singleTenderCeadData;

  const [showPrincipalEmailStatus, setShowPrincipalEmailStatus] = useState({
    show: false,
    id: "",
  });

  //   useEffect(() => {
  //     dispatch(actionForPaymentReceivedPrincipalInvoiceLogGetListing(id));
  //   }, [id]);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
    },
    {
      title: "Currency",
      dataIndex: "abbvr",
    },
    {
      title: "Invoice Amt",
      dataIndex: "amount",
      className: "text-align--right",
      render: (text, record) => {
        // if (text !== undefined) {
        //   return Number.isSafeInteger(text) ? text.toString() : text.toFixed(2);
        // }
        return record?.amount ? showNumber(record?.amount) : "--";
      },
    },
    {
      title: "Invoice Status",
      dataIndex: "displayName",
    },
    {
      title: "Email Status",
      dataIndex: "mailMessageCenterId",
      render: (text) => {
        return text ? "Sent" : "Not Send";
      },
    },

    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            <MailOutlined
              onClick={() => {
                setShowPrincipalEmailStatus({
                  show: true,
                  id: record?.id,
                });
              }}
            />{" "}
            {/* <AllFileViewer
              file={{ displayFileName: "file" }}
              cqBaseUrl={cqBaseUrl}
              token={"token"}
              height={600}
              width={900}
              type="invoice"
            /> */}
          </>
        );
      },
    },
  ];

  const dataSource = tenderCEADPaymentReceivedPrincipalInvoiceLogDto
    ?.sort((a, b) => {
      return a.groupIndex - b.groupIndex;
    })
    .map((x, index) => {
      return {
        sn: index + 1 + ".",
        ...x,
        ...x?.currencyDto,
        ...x?.invoiceStatusDto,
        id: x?.id,
      };
    });

  return (
    <>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
      <Modal
        visible={showPrincipalEmailStatus.show}
        onCancel={() => {
          setShowPrincipalEmailStatus(false);
        }}
        width={800}
        footer={null}
      >
        <PrincpalPaymentData id={showPrincipalEmailStatus.id} />
      </Modal>
    </>
  );
}
