import { Button, DatePicker, Form, Input, Typography } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionForWeeklyAddUpdateReport } from "services/redux/cead/action";

const { TextArea } = Input;

export default function ReportForm(props) {
  const {
    handleDrawerWeeklyReport,
    handleFormWklyRptExtStage,
    weeklyForm,
    isSetOpenDrawer,
    showWklyRptExecForm,
    setShowWklyRptExtForm,
    setOpenDrawer,
    stage,
    onFinishWeeklyAddUpdatReportExecution,
  } = props;

  const {
    tenderCeadWeeklyReportAddUpdateLoading,
    tenderCeadWeeklyContractReportAddUpdateLoading,
    tenderCeadWeeklyReportAddUpdateSuccess,
  } = useSelector((state) => state.ceadReducer);

  console.log(
    "tenderCeadWeeklyReportAddUpdateSuccess",
    tenderCeadWeeklyReportAddUpdateSuccess,
    tenderCeadWeeklyReportAddUpdateLoading,
    tenderCeadWeeklyContractReportAddUpdateLoading
  );

  const dispatch = useDispatch();
  const labels = [
    {
      label: (
        <Typography.Text className="c-w-r-f-label">
          <p>AA. Main/Major Activity of the week</p>
          <span>(Do not Repeat here info. filled in BB. to LL. Section)</span>
        </Typography.Text>
      ),
      name: "aa",
      inputType: (
        // <Input placeholder="Enter" />
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "BB. Clients Visited/Contacted this Week",
      name: "bb",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "CC. Principals met/Contacted this Week",
      name: "cc",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "DD. Principals released to Principals this Week",
      name: "dd",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "EE. Fees Claimed this Week",
      name: "ee",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "FF. Fee Received this Week",
      name: "ff",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "GG. Visitors arrived this Week/will Arrive next Week",
      name: "gg",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "HH. Site Visits made this Week",
      name: "hh",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label:
        "II. New Contracts signed this Week/Contracts transferred from Bizpro",
      name: "ii",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "JJ. File Completion Report made this Week",
      name: "jj",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "KK. Reports, if any.to the Principals this week",
      name: "kk",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
    {
      label: "LL. Miscellaneous",
      name: "ll",
      inputType: (
        <TextArea className="wr--txtarea" rows={2} placeholder="Enter here" />
      ),
    },
  ];

  const onFinishWeeklyAddUpdatReport = (values) => {
    const apiData = {
      ...values,
      id: isSetOpenDrawer?.wklyRptId,
      isActive: true,
      // reportingToDate: moment(values.reportingFromDate).clone().add(1, "days"),
    };
    dispatch(actionForWeeklyAddUpdateReport(apiData));
  };

  return (
    <>
      <Form
        labelCol={{ offset: 1, span: 23 }}
        wrapperCol={{ offset: 1, span: 23 }}
        labelAlign="left"
        onFinish={
          stage === "execution"
            ? onFinishWeeklyAddUpdatReportExecution
            : onFinishWeeklyAddUpdatReport
        }
        form={weeklyForm}
      >
        <div className="cead-date-picker">
          <Form.Item
            label="Reporting Date"
            labelAlign="left"
            labelCol={{ offset: 1, span: 5 }}
            wrapperCol={{ span: 18 }}
            // name="reportingFromDate"
            name="reportingDate"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker placeholder="Select Date" />
          </Form.Item>
        </div>

        {labels.map((x) => {
          return (
            <Form.Item label={x.label} name={x.name}>
              {x.inputType}
            </Form.Item>
          );
        })}

        <Form.Item className="btn-w-r">
          <Button
            className="btn-w-r-c"
            onClick={() => {
              if (stage === "execution") {
                return handleFormWklyRptExtStage(
                  showWklyRptExecForm.wklyRptId ? "detail" : ""
                );
              } else {
                return handleDrawerWeeklyReport(
                  isSetOpenDrawer?.wklyRptId ? "detail" : ""
                );
              }
            }}
          >
            Cancel
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            loading={
              tenderCeadWeeklyReportAddUpdateSuccess === false
                ? tenderCeadWeeklyReportAddUpdateSuccess
                : tenderCeadWeeklyReportAddUpdateLoading ||
                  tenderCeadWeeklyContractReportAddUpdateLoading
            }
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
