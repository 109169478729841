import { EMAIL_TYPES } from "../../../types";
import { saveMailTypeAndMail, saveActiveEmail } from "services/common";

export const selectedMailTypeAndMail = (data) => {
  return async (dispatch) => {
    dispatch(
      saveMailTypeAndMail(EMAIL_TYPES.SELECTED_MAIL_TYPE_AND_MAIL, data)
    );
  };
};

export const selectedActiveEmail = (data) => {
  return async (dispatch) => {
    dispatch(saveActiveEmail(EMAIL_TYPES.EMAIL_TYPE_ACTIVE, data));
  };
};
