import { appBaseUrl } from "utils/config";
import {
  getService,
  postService,
  putService,
} from "../../../../commonServices";

export class DesignationService {
  getDesignationListing() {
    let url = `${appBaseUrl}/organizationStructure/designationListing`;
    let data = getService(url);
    return data;
  }

  // addAppointmentEvent(body) {
  //   let url = `${rootBaseUrl}/organizerEvent/appointmentEventAdd`;
  //   let data = postService(url, body);
  //   return data;
  // }

  // updateAppointmentEvent(body, id) {
  //   let url = `${rootBaseUrl}/organizerEvent/appointmentEventUpdate/${id}`;
  //   let data = postService(url, body, 'put');
  //   return data;
  // }

  // appointmentMarkAsComplete(id) {
  //   let url = `${rootBaseUrl}/organizerEvent/appointmentEventMarkItOff/${id}`;
  //   let data = postService(url, '', 'patch');
  //   return data;
  // }
  addEmployeeInDepartment(body, id) {
    let url = `${appBaseUrl}/organizationStructure/organizationDepartmentAddUpdateEmployee/${id}`;
    let data = putService(url, body);
    return data;
  }
  getDepartmentDetails(id) {
    let url = `${appBaseUrl}/organizationStructure/organizationDepartmentGetDetails/${id}`;
    let data = postService(url);
    return data;
  }
}
