import { MEETING_DECISION_TYPES } from "../../../../types";

const INITIAL_STATE = {
    addMeetingEventDecisionResp: [],
    addMeetingEventDecisionRespLoading: false,

    meetingEventDecisionUpdateResp: [],
    meetingEventDecisionUpdateRespLoading: false,
};

export default function meetingEventDecisionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Labels Lisiting
        case MEETING_DECISION_TYPES.ADD_DECISION_AGENDA_INIT:
            return {
                ...state,
                addMeetingEventDecisionResp: [],
                addMeetingEventDecisionRespLoading: true,
            };
        case MEETING_DECISION_TYPES.ADD_DECISION_AGENDA_SUCCESS:
            return {
                ...state,
                addMeetingEventDecisionResp: action.payload,
                addMeetingEventDecisionRespLoading: false,
            };
        case MEETING_DECISION_TYPES.ADD_DECISION_AGENDA_FINISH:
            return {
                ...state,
                addMeetingEventDecisionRespLoading: false,
            };

        // Decision Update
        case MEETING_DECISION_TYPES.MEETING_DECISION_UPDATE_INIT:
            return {
                ...state,
                meetingEventDecisionUpdateResp: [],
                meetingEventDecisionUpdateRespLoading: true,
            };
        case MEETING_DECISION_TYPES.MEETING_DECISION_UPDATE_SUCCESS:
            return {
                ...state,
                meetingEventDecisionUpdateResp: action.payload,
                meetingEventDecisionUpdateRespLoading: false,
            };
        case MEETING_DECISION_TYPES.MEETING_DECISION_UPDATE_FINISH:
            return {
                ...state,
                meetingEventDecisionUpdateRespLoading: false,
            };
        default:
            return { ...state };
    }
}
