import { getService, postService } from "services/commonServices";
import { appBaseUrl } from "utils/config";

export class ApprovaCEAD {
  getApprovalCeadDetails(id) {
    let url = `${appBaseUrl}/requisitionManagement/tenderCeadGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  apiForPaymentReceivedPrincipalInvoiceAccountApproved(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADPaymentReceivedPrincipalInvoiceAccountApproved/${id}`;
    let data = postService(url, body);
    return data;
  }
}
