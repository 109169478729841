import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Button,
  Skeleton,
  Row,
  Col,
  Affix,
  Dropdown,
  Popover,
} from "antd";
import { ExclamationOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { isEmpty } from "lodash";

import { actionForGetAllUsersPP } from "services/redux/profile/profile.action";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import MatchingImageDisplay from "components/shared/MatchingImageDisplay";

function ChatPaginationListing(props) {
  let { isChatOpen, data, loading } = props;

  const {
    addBlogMessageLoading,
    addBlogMessageResp,
    getBlogMsgPaginationResp,
    getBlogMsgPaginationLoading,
  } = useSelector((state) => state.blogReducer);

  const { listingAllUsersProfileResp, listingAllUsersProfileLoading } =
    useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { profileInfo } = useSelector((state) => state.profile);

  const scrollableRef = useRef();

  const [blogMsg, setBlogMsg] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [editMode, setEditMode] = useState(false);
  const [editedBlog, setEditedBlog] = useState({});
  const [listingAllProfile, setListingAllProfile] = useState([]);

  const appUserIdUC = sessionStorage?.userId.toUpperCase();

  useEffect(() => {
    dispatch(actionForGetAllUsersPP());
  }, []);

  useEffect(() => {
    if (!isEmpty(listingAllUsersProfileResp)) {
      setListingAllProfile(listingAllUsersProfileResp);
    }
  }, [listingAllUsersProfileResp]);
  // end SUPERAPI **************************************************************

  useEffect(() => {
    dispatch(
      employeeProfileDetail({
        AppUserId: sessionStorage?.userId.replaceAll('"', ""),
      })
    );
  }, []);

  const blogItemStyle = {
    display: "inline-block",
    background: "#FFFFFF",
    marginBottom: "1em",
    padding: "0.4em 1em",
  };

  const blogItemNameStyle = {
    color: "rgb(85, 85, 85)",
    marginBottom: "0em",
  };

  const blogItemBodyStyle = {
    fontSize: "1.1em",
    marginBottom: "0em",
  };

  const handleEditClick = (item) => {
    setEditMode(true);
    setEditedBlog({
      id: item.id,
      name: item.name,
      body: item.body,
    });
  };

  const getMoreData = () => {
    console.log("calling");
    // getData(pageSize, currentPage * pageSize, true);
  };

  const handleEditSubmit = (values) => {
    const updatedBlog = {
      id: editedBlog.id,
      userId: sessionStorage?.userId,
      subject: "string",
      name: editedBlog.name,
      body: values.editedMessage,
      isActive: true,
      // referenceCode: tenderId,
      lastmodified: moment().format(),
    };

    // dispatch(updateBlogMessage(editedBlog.id, updatedBlog));

    setEditMode(false);
    setEditedBlog({});
  };

  const blogPagination = () => {
    return (
      <>
        {/* {console.log("data: ",data)} */}
        {data?.items.map((item, index) => (
          <div
            className="blogWrapper"
            key={index}
            style={{
              display: "flex",
              justifyContent:
                JSON.stringify(item?.userId)?.toUpperCase() === appUserIdUC
                  ? "flex-end"
                  : "flex-start",
            }}
          >
            {/* {console.log("FIRST item: ", item)} */}
            {JSON.stringify(item?.userId)?.toUpperCase() !== appUserIdUC ? (
              <span
                style={{
                  height: "40px",
                  width: "40px",
                  background: "#67a1e4",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  color: "#FFF",
                  marginRight: "12px",
                }}
              >
                {/* {item ? item?.name[0].toUpperCase() : null} */}
                <MatchingImageDisplay
                  listingAllProfile={listingAllProfile}
                  item={item}
                />
              </span>
            ) : null}

            <div
              style={{
                display: "inline-block",
                maxWidth: "80%",
                background:
                  JSON.stringify(item?.userId)?.toUpperCase() === appUserIdUC
                    ? "#E8EBFA"
                    : "#FFFFFF",
                marginBottom: "1em",
                padding: "0.4em 1em",
              }}
            >
              {/* <div>
                  {editMode && editedBlog.id === item.id ? (
                    <Form
                      onFinish={handleEditSubmit}
                      initialValues={{ editedMessage: editedBlog.body }}
                    >
                      <Form.Item
                        name="editedMessage"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          style={{
                            height: "42px",
                            borderBottom: "4px solid #8e92d1",
                          }}
                        />
                      </Form.Item>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                      <Button onClick={() => setEditMode(false)}>Cancel</Button>
                    </Form>
                  ) : (
                    <>
                      <div
                        className=""
                        style={{
                          display: "flex",
                        }}
                      >
                        <span style={{ marginRight: "2em" }}>{item?.name}</span>
                        <span style={{ color: "#807e7e" }}>
                          {item?.lastmodified
                            ? "Modified " + moment(item?.lastmodified).calendar()
                            : moment(item?.creationTime).startOf("min").fromNow()}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={blogItemBodyStyle} dangerouslySetInnerHTML={{ __html: item?.messageBody }}></p>
                        <span
                          className={
                            JSON.stringify(item?.userId) ===
                            sessionStorage?.userId.toUpperCase()
                              ? "blog__content"
                              : ""
                          }
                        >
                          {JSON.stringify(item?.userId)?.toUpperCase() ===
                          appUserIdUC ? (
                            <EditOutlined onClick={() => handleEditClick(item)} />
                          ) : null}
                        </span>
                      </div>
                    </>
                  )}
                </div> */}
              <div
                className=""
                style={{
                  display: "flex",
                }}
              >
                <span style={{ marginRight: "2em" }}>{item?.userName}</span>
                <span style={{ color: "#807e7e" }}>
                  <Popover
                    // content={moment(item?.creationTime).format("YYYY-MM-DD")}
                    content={moment(item?.creationTime).format(
                      "DD-MMM-YYYY hh:mm A"
                    )}
                  >
                    {moment(item?.creationTime).startOf("min").fromNow()}
                  </Popover>

                  {/* {item?.lastmodified
                          ? "Modified " + moment(item?.lastmodified).calendar()
                          : moment(item?.creationTime).startOf("min").fromNow()} */}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={blogItemBodyStyle}
                  dangerouslySetInnerHTML={{ __html: item?.messageBody }}
                ></p>
                {/* <span
                      className={
                          JSON.stringify(item?.userId) ===
                          sessionStorage?.userId.toUpperCase()
                          ? "blog__content"
                          : ""
                      }
                      >
                      {JSON.stringify(item?.userId)?.toUpperCase() ===
                      appUserIdUC ? (
                          <EditOutlined onClick={() => handleEditClick(item)} />
                      ) : null}
                      </span> */}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  if (loading) return <Skeleton />;

  return (
    <>
      <div className="blog-content">
        <div
          id="groupChatDiv"
          ref={scrollableRef}
          className={`groupchat-message-body ${
            isChatOpen ? "chat-visible-height" : "chat-not-visible-height"
          }`}
        >
          {loading && <Skeleton paragraph={{ rows: 40 }} active />}

          {!isEmpty(data) ? (
            <InfiniteScroll
              //To put endMessage and loader to the top.
              style={{ display: "flex", flexDirection: "column-reverse" }}
              dataLength={data.items.length}
              inverse={true}
              // hasMore={
              //     data.totalCount >
              //     data.items.length
              // }
              // next={getMoreData}
              loader={<Skeleton paragraph={{ rows: 4 }} active />}
              scrollableTarget="groupChatDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  {data.items.length > 2 ? (
                    <span>Start of Group Chat</span>
                  ) : null}
                </p>
              }
            >
              {blogPagination()}
            </InfiniteScroll>
          ) : (
            <p style={{ textAlign: "center" }}>
              <b>No Data</b>
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default ChatPaginationListing;
