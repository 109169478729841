import { useEffect, useState } from "react";

import { Card, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import { handleDateFormat } from "utils/dateFormat";

export default function WeeklyContractReportDetailsList(props) {
  const {
    tenderCeadWeeklyContractReportDetailsListing,
    tenderCeadWeeklyContractReportGetListingLoading,
    handleFormWklyRptExtStage,
  } = props;

  const dataSource = tenderCeadWeeklyContractReportDetailsListing?.map(
    (x, index) => {
      return {
        sn: index + 1 + ".",
        dateRange: `${
          handleDateFormat(x?.reportingFromDate, "YYYY-MM-DD") +
          "/" +
          handleDateFormat(x?.reportingToDate, "YYYY-MM-DD")
        }`,
        createdBy: x?.creatorUserDto?.employeeName,
        reportingDate: handleDateFormat(x?.reportingToDate, "YYYY-MM-DD"),
        action: (
          <EyeOutlined
            onClick={() => {
              handleFormWklyRptExtStage("detail", x?.id);
            }}
          />
        ),
      };
    }
  );

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
    },
    {
      title: "Date Range",
      dataIndex: "dateRange",
      key: "dateRange",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Reporting Date",
      dataIndex: "reportingDate",
      key: "reportingDate",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={tenderCeadWeeklyContractReportGetListingLoading}
      />
    </>
  );
}
