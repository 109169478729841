import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { OrganizationTypeAddUpdateDrawer } from "./OrganizationTypeDrawer";
import OrganizationTypeAddUpdateTable from "./OrganizationTypeAddUpdateTable";
const OrganizationtType = () => {
  const dispatch = useDispatch();

  const [showOrganizationDrawer, setShowOrganizationDrawer] = useState(false);
  const onCloseOrginaation = () => {
    setShowOrganizationDrawer(false);
  };
  return (
    <div>
      <div className="layout">
        <div className="organizer events">
          <div className="all-header-wrapper">
            <div className="meeting__flex">
              <h3 className="header__all">OrganizationType List</h3>
              <div className="organizerHoliday__middle--wrap">
                <Button
                  className="btn-primary"
                  onClick={() => {
                    setShowOrganizationDrawer(true);
                  }}
                >
                  <PlusOutlined /> New OrginationType Add
                </Button>
              </div>
            </div>
          </div>
          <OrganizationTypeAddUpdateTable />
          <OrganizationTypeAddUpdateDrawer
            drawerVisibility={showOrganizationDrawer}
            onCloseOrginaation={onCloseOrginaation}
            setShowOrganizationDrawer={setShowOrganizationDrawer}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationtType;
