import { ORGANIZER_TYPES } from "../../../types";

const INITIAL_STATE = {
    organizerEventPriorityListing: [],
    organizerEventPriorityListingLoading: false,
};

export default function organizerEventsPriorityReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Labels Lisiting
        case ORGANIZER_TYPES.PRIORITY_EVENTS_LISTING_INIT:
            return {
                ...state,
                organizerEventPriorityListing: [],
                organizerEventPriorityListingLoading: true,
            };
        case ORGANIZER_TYPES.PRIORITY_EVENTS_LISTING_SUCCESS:
            return {
                ...state,
                organizerEventPriorityListing: action.payload,
                organizerEventPriorityListingLoading: false,
            };
        case ORGANIZER_TYPES.PRIORITY_EVENTS_LISTING_FINISH:
            return {
                ...state,
                organizerEventPriorityListingLoading: false,
            };
        default:
            return { ...state };
    }
}
