import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersListing } from "services/redux/admin/FiscalYear/action";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import { allCountryListing } from "services/redux/contact/contact.action";
import { guestEntertainmentApply } from "services/redux/requistions/leaveType/action";
import storage from "../../../../utils/storage";

const ApplicantFormGE = ({ setAddUpdateLeaveDrawerVisibility }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);
  const { allCountryListingResponse } = useSelector((state) => state.contact);
  const { getAllUsersListResponse } = useSelector(
    (state) => state.fiscalYearState
  );

  const { amountResponse, amountResponseLoading } = useSelector(
    (state) => state.leaveType
  );
  const { guestInfoAddUpdateLoading } = useSelector((state) => state.leaveType);
  const [isHomeRestaurant, setIsHomeResturant] = useState(false);
  const [ratingAmount, setRatingAmount] = useState({ food: 0, drinking: 0 });
  const [expense, setExpense] = useState(0);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  useEffect(() => {
    dispatch(getSisterCompaniesListings());
    dispatch(getAllUsersListing());
    dispatch(allCountryListing());
  }, []);

  useEffect(() => {
    if (guestInfoAddUpdateLoading) {
      form.resetFields();
    }
  }, [form, guestInfoAddUpdateLoading]);

  const onFinish = (values) => {
    values.isHomeRestaurant = isHomeRestaurant;
    if (values.isPoolCookRequired === undefined) {
      values.isPoolCookRequired = false;
    }
    values.applicantId = storage.getItem("userId");
    values.applyNumberOfGuest = JSON.parse(values.applyNumberOfGuest);
    values.isActive = true;
    const newValues = values.guestNames;
    values.guestNames = newValues;

    dispatch(
      guestEntertainmentApply(values, setAddUpdateLeaveDrawerVisibility)
    );
  };

  const handleSelectChange = (value, option) => {
    setRatingAmount((prevExpense) => ({
      ...prevExpense,
      food: option?.children?.[2],
    }));
  };

  const handleSelectChange1 = (value, option) => {
    setRatingAmount((prevExpense) => ({
      ...prevExpense,
      drinking: option?.children?.[2],
    }));
  };

  const validateFoodTimes = (_, value) => {
    if (value && value < 0) {
      return Promise.reject("Food times must be greater than or equal to 0.");
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form
        {...layout}
        name="dynamic_form_nest_item"
        form={form}
        onFinish={onFinish}
        labelAlign="left"
        style={{
          marginLeft: "auto",
          width: "98%",
          padding: "10px",
          paddingBottom: "0px",
        }}
      >
        <Form.Item
          label="On Account of"
          name="sisterCompanyId"
          className="form__group"
          required="true"
          style={{ width: "95%", marginRight: "auto", paddingBottom: "0px" }}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Select placeholder="Select Company" autoComplete="off">
            {!isEmpty(sisterCompanyListing) &&
              sisterCompanyListing.map((company, index) => {
                return (
                  <Option value={company.id} key={index}>
                    {company.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          className="form__group"
          name="arrivalDate"
          label="Date"
          required="true"
          style={{ width: "95%" }}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <DatePicker format="YYYY/MM/DD" />
        </Form.Item>

        <Form.Item
          className="form__group"
          label="Guest Name"
          name="guestNames"
          required="true"
          style={{ width: "95%" }}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          {/* <Select
            optionFilterProp="label"
            bordered={true}
            autoFocus={true}
            placeholder={`Guests Name`}
            mode="multiple"
          >
            {getAllUsersListResponse?.map((participant, j) => (
              <Option
                key={j}
                value={participant?.userName}
                label={participant?.fullName}
              >
                <div className="demo-option-label-item">
                  <span role="img">{participant?.fullName}</span>
                </div>
              </Option>
            ))}
          </Select> */}
          <Input />
        </Form.Item>

        <Form.Item
          label="Number of guest including applicant"
          name="applyNumberOfGuest"
          className="form__group"
          required="true"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
          style={{ width: "95%" }}
        >
          <Input
            autoComplete="off"
            type="number"
            min={0}
            onChange={(e) => {
              setExpense(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Guest Company"
          name="guestCompanyId"
          className="form__group"
          style={{ width: "95%" }}
          required="true"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Select
            autoFocus={true}
            size="small"
            showSearch={true}
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {allCountryListingResponse?.map((country) => (
              <Option value={country.id} key={country.id}>
                {country.companyName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <Form.Item
            label="Food Expense"
            name="foodRatingAmountId"
            required="true"
            className="form__group"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
            style={{ width: "95%" }}
          >
            <Select
              placeholder="Select Company"
              autoComplete="off"
              onChange={handleSelectChange}
            >
              {!isEmpty(amountResponse) &&
                amountResponse.map((amount, index) => {
                  return (
                    <Option value={amount.id} key={index}>
                      {amount.displayName}({amount.ratingAmount})
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Drink Expense"
            name="drinkRatingAmountId"
            required="true"
            className="form__group"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
            style={{ width: "95%" }}
          >
            <Select
              placeholder="Select Company"
              autoComplete="off"
              onChange={handleSelectChange1}
            >
              {!isEmpty(amountResponse) &&
                amountResponse.map((amount, index) => {
                  return (
                    <Option value={amount.id} key={index}>
                      {amount.displayName}({amount.ratingAmount})
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <Form.Item
            label="Food times"
            name="foodtimes"
            required="true"
            className="form__group"
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: validateFoodTimes,
              },
            ]}
            style={{ width: "95%" }}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Drink times"
            name="drinktimes"
            required="true"
            className="form__group"
            rules={[
              {
                required: true,
                message: "",
              },
              {
                validator: validateFoodTimes,
              },
            ]}
            style={{ width: "95%" }}
          >
            <Input type="number" />
          </Form.Item>
        </div>
        <Form.Item
          name="isHomeRestaurant"
          label="To be entertained at"
          className="form__group"
          style={{ width: "95%" }}
        >
          <Checkbox
            checked={isHomeRestaurant}
            onChange={() => setIsHomeResturant(!isHomeRestaurant)}
          >
            Home
          </Checkbox>
          <Checkbox
            checked={!isHomeRestaurant}
            onChange={() => setIsHomeResturant(!isHomeRestaurant)}
          >
            Outside
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="isPoolCookRequired"
          label="Pool Cook Required"
          className="form__group"
          style={{ width: "95%" }}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="subject"
          label="Remarks"
          style={{ width: "95%" }}
          required="true"
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Card style={{ margin: "10px 0px  46px 0px" }}>
          <Col span={24}>
            <Row span={24}>
              <Col span={6}>Category</Col>
              <Col span={6}>Rate</Col>
              <Col span={6}>Person</Col>
              <Col span={6}>Total</Col>
            </Row>

            <hr style={{ border: "0.5px solid lightgray" }} />
            <Row span={24}>
              <Col span={6}>Fooding</Col>
              <Col span={6}>{ratingAmount.food}</Col>
              <Col span={6}>{expense}</Col>
              <Col span={6}>{ratingAmount.food * expense}</Col>
            </Row>
            <Row span={24}>
              <Col span={6}>Drinking</Col>
              <Col span={6}>{ratingAmount.drinking}</Col>
              <Col span={6}>{expense}</Col>
              <Col span={6}>{ratingAmount.drinking * expense}</Col>
            </Row>
          </Col>
        </Card>

        <div className="model-footer" style={{ paddingRight: "40px" }}>
          <Button
            type="danger"
            onClick={() => setAddUpdateLeaveDrawerVisibility(false)}
          >
            Close
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={guestInfoAddUpdateLoading}
          >
            Apply
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ApplicantFormGE;
