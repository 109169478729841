import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash-es";
import { Redirect, useHistory, useLocation } from "react-router-dom";

// antd
import { Menu, Skeleton } from "antd";

// includes
import { withBaseLayout } from "../../layouts/Base";
import RoleLists from "./Roles/RoleLists";
import OrganizationStructure from "./OrganizationStructure";
import AddUpdateCountry from "./AddUpdateCountry";
import ContactLabelGroup from "./ContactLabelGroup";
import ContactLabel from "./ContactLabel";
import OrganizerLabel from "./OrganizerLabel";
import OrganizerPriority from "./OrganizerPriority";
import CityAddUpdate from "./CityAddUpdate";
import FiscalYear from "./FiscalYear";
import StatusListingAddUpdate from "../AdminSettings/StatusEventListing";
import AddUpdateLeaveApproval from "../AdminSettings/AddUpdateLeaveApproval";
import AddUpdateLoanApproval from "./Authoiry/AddUpdateLoanApproval";
import AddUpdateGuestEntApproval from "./Authoiry/AddUpdateGuestApproval";
import AddUpdateTDDAApproval from "./Authoiry/AddUpdateTDDA";
import ProjectSettings from "./ProjectSetting/Currency";
import Modality from "./ProjectSetting/Modality";
import Sector from "./ProjectSetting/Sector";
import Source from "./SourceSetting/Source";
import PrimaryReference from "./PrimaryReference";
import OrganizationtType from "./OrganizationType";
import Status from "./ProjectSetting/Status";
import Stage from "../AdminSettings/TenderSettings/Stage";
import Type from "../AdminSettings/TenderSettings/Type";
import SisterCompanyListing from "./AddUpdateSisterCompany";
import LeaveFiscalDay from "./LeaveApplication/LeaveFiscalDayAddUpdate";
import LeaveApplicationType from "./LeaveApplication/LeaveApplicationType";
import HonorRollAddUpdate from "../OfficeResource/HonorRoll";
import NoticeBoardAddUpdate from "views/OfficeResource/NoticeBoard";
import GuestEntry from "views/Oragnizer/guestEntry";
// redux
import { useSelector } from "react-redux";
import NoticeBoard from "views/Home/NoticeBoard";
import ApplicationTemplate from "./ApplicationTemplate";
import Department from "./Department";
import InterviewQuestion from "./InterviewQuestion";
import TutorialCategoryListing from "./TurorialCategoryListing";
import CheckListListing from "components/CheckList/System/CheckListListing";

const minimize = "assets/images/icons/minimize.png";
const maximize = "assets/images/icons/maximize.png";

const defaultRoute = "/system/organization-structure";

const { SubMenu } = Menu;

function System() {
  const history = useHistory();

  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );

  const [state, setState] = useState({
    current: null,
    collapsed: false,
  });

  const [grantedPolices, setGrantedPolices] = useState({});
  const handleCheck = (e) => {
    history.push(e.key);
  };

  const toggleCollapse = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };

  useEffect(() => {
    if (!isEmpty(applicationConfiguration)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  useEffect(() => {
    if (history.location.pathname === "/system") {
      history.push(defaultRoute);
    }

    setState({ ...state, current: history.location.pathname });
  }, [history.location.pathname]);

  const renderComponent = (pathname) => {
    switch (pathname) {
      case defaultRoute:
        return <OrganizationStructure />;
      case "/system/roles":
        return !isEmpty(grantedPolices) &&
          grantedPolices["AbpIdentity.Roles"] ? (
          <RoleLists grantedPolices={grantedPolices} />
        ) : (
          <Redirect to="/system" />
        );
      case "/system/add-country":
        return <AddUpdateCountry />;
      case "/system/add-city":
        return <CityAddUpdate />;
      case "/system/contact-label-group":
        return <ContactLabelGroup />;
      case "/system/contact-label":
        return <ContactLabel />;
      case "/system/organizer-type":
        return <OrganizationtType />;
      case "/system/primary":
        return <PrimaryReference />;
      case "/system/organizer-label":
        return <OrganizerLabel />;
      case "/system/organizer-priority":
        return <OrganizerPriority />;
      case "/system/status-events-listing":
        return <StatusListingAddUpdate />;
      case "/system/leave-approval":
        return <AddUpdateLeaveApproval />;
      case "/system/td-da-approval":
        return <AddUpdateTDDAApproval />;
      case "/system/loan-apporval":
        return <AddUpdateLoanApproval />;
      case "/system/guest-exit-apporval":
        return <AddUpdateGuestEntApproval />;
      case "/system/currency":
        return <ProjectSettings />;
      case "/system/modality":
        return <Modality />;
      case "/system/sector":
        return <Sector />;
      case "/system/source":
        return <Source />;
      case "/system/status":
        return <Status />;
      case "/system/stage":
        return <Stage />;
      case "/system/type":
        return <Type />;
      case "/system/sister-company-listing":
        return <SisterCompanyListing />;
      case "/system/fiscal-year":
        return <FiscalYear />;
      case "/system/leave-fiscal-day":
        return <LeaveFiscalDay />;
      case "/system/leave-application-type":
        return <LeaveApplicationType />;
      case "/system/honorRoll":
        return <HonorRollAddUpdate />;
      case "/system/noticeBoard":
        return <NoticeBoardAddUpdate />;
      case "/system/guestEntry":
        return <GuestEntry />;
      case "/system/application-template":
        return <ApplicationTemplate />;
      case "/system/department":
        return <Department />;
      case "/system/interview-question":
        return <InterviewQuestion />;
      case "/system/tutorial-category":
        return <TutorialCategoryListing />;
      case "/system/application-template-type":
        return <CheckListListing />;
      default:
        return <OrganizationStructure />;
    }
  };

  if (!state.current) {
    return <Skeleton />;
  }

  return (
    <div className="sidebars">
      <div
        className={`sidebars__menu ${state.collapsed && "text-align-right"}`}
      >
        <div className="toggle__arrow" onClick={toggleCollapse}>
          {state.collapsed ? <img src={maximize} /> : <img src={minimize} />}
        </div>
        <div className="sidebar-title">System</div>
        <Menu
          className="sidebars__menu--items"
          selectedKeys={[state.current]}
          mode="inline"
          inlineCollapsed={state.collapsed}
        >
          <Menu.Item
            key={defaultRoute}
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Organization Structure
          </Menu.Item>
          <Menu.Item
            key="/system/add-country"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Country
          </Menu.Item>
          <Menu.Item
            key="/system/add-city"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            City
          </Menu.Item>
          <Menu.Item
            key="/system/contact-label-group"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Contact Label Group
          </Menu.Item>
          <Menu.Item
            key="/system/contact-label"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Contact Label
          </Menu.Item>
          <Menu.Item
            key="/system/organizer-type"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Organization Layer Type
          </Menu.Item>
          <Menu.Item
            key="/system/organizer-label"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Organizer Label
          </Menu.Item>

          <Menu.Item
            key="/system/organizer-priority"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Organizer Priority
          </Menu.Item>
          <Menu.Item
            key="/system/status-events-listing"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Status Events Listing
          </Menu.Item>
          <SubMenu
            style={{ background: "transparent" }}
            className="userlist__navbar__items"
            key="sub2"
            title="Project Setting"
            onTitleClick={() => setState({ ...state, openKeys: ["sub2"] })}
          >
            <Menu.Item
              key="/system/currency"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Currency
            </Menu.Item>
            <Menu.Item
              key="/system/modality"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Modality
            </Menu.Item>
            <Menu.Item
              key="/system/sector"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Sector
            </Menu.Item>
            <Menu.Item
              key="/system/status"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Status
            </Menu.Item>
          </SubMenu>
          <SubMenu
            style={{ background: "transparent" }}
            className="userlist__navbar__items"
            key="sub3"
            title="Tender Setting"
            onTitleClick={() => setState({ ...state, openKeys: ["sub3"] })}
          >
            <Menu.Item
              key="/system/stage"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Stage
            </Menu.Item>
            <Menu.Item
              key="/system/type"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Type
            </Menu.Item>
            <Menu.Item
              key="/system/primary"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Primary Reference
            </Menu.Item>
          </SubMenu>
          <SubMenu
            style={{ background: "transparent" }}
            className="userlist__navbar__items"
            key="sub4"
            title="Contact Setting"
            onTitleClick={() => setState({ ...state, openKeys: ["sub4"] })}
          >
            <Menu.Item
              key="/system/source"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Source
            </Menu.Item>
          </SubMenu>
          <SubMenu
            style={{ background: "transparent" }}
            className="userlist__navbar__items"
            key="sub1"
            title="Leave Application"
            onTitleClick={() => setState({ ...state, openKeys: ["sub1"] })}
          >
            <Menu.Item
              key="/system/leave-fiscal-day"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Fiscal Day
            </Menu.Item>
            <Menu.Item
              key="/system/leave-application-type"
              onClick={handleCheck}
              className="sidebars__menu--item"
            >
              Application Type
            </Menu.Item>
          </SubMenu>
          <Menu.Item
            key="/system/application-template"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Application Template
          </Menu.Item>
          <Menu.Item
            key="/system/department"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Department
          </Menu.Item>
          <Menu.Item
            key="/system/interview-question"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Interview Question
          </Menu.Item>
          <Menu.Item
            key="/system/tutorial-category"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Tutorial Category
          </Menu.Item>
          <Menu.Item
            key="/system/application-template-type"
            onClick={handleCheck}
            className="sidebars__menu--item"
          >
            Application Template Type
          </Menu.Item>
        </Menu>
      </div>
      <div className="main__body admin__body">
        {renderComponent(history.location.pathname)}
      </div>
    </div>
  );
}

// export default withBaseLayout(AdminSettings);
export default System;
