import { Form, Input, Button, Row, Col } from "antd";
import { SendOutlined } from "@ant-design/icons";
const { TextArea } = Input;

const BlogForm = ({ form, onFinish, loading }) => {
  return (
    <Form
      name="blog"
      form={form}
      onFinish={onFinish}
      wrapperCol={{ span: 24 }}
      style={{ background: "#f9f9f9", padding: "2em 4em" }}
    >
      <Row gutter={8}>
        <Col span={22}>
          <Form.Item
            name="message"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea
              rows={2}
              style={{
                // height: "42px",
                borderBottom: "4px solid #8e92d1",
                resize: "none",
                fontSize: "1.1em",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              // type="primary"
              htmlType="submit"
              style={{
                height: "100%",
                width: "100%",
                // added
                background: "none",
                border: "none",
                color: "#67a1e4",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              loading={loading}
            >
              {/* Submit */}
              <SendOutlined style={{ fontSize: "28px" }} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BlogForm;
