import { getService, postService } from "services/commonServices";
import { appBaseUrl } from "utils/config";
import queryString from "query-string";

export class VisitCategory {
  getVisitCategory(radioValue) {
    let url = `${appBaseUrl}/requisitionManagement/tadaDestinationTypeListing?IsDomesticOrInternational=${radioValue}`;
    let data = getService(url);
    return data;
  }
}

export class RequestAmount {
  getRequestAmount(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/requisitionManagement/tadaDestinationRateListing?${query}`;
    let data = getService(url);
    return data;
  }
}

export class TadaDetails {
  addTadaDetails(body) {
    let url = `${appBaseUrl}/requisitionManagement/tadaApplicationAdd`;
    let data = postService(url, body, "post");
    return data;
  }

  getTadaDetailsPagination() {
    let url = `${appBaseUrl}/requisitionManagement/tadaApplicationPagination`;
    let data = getService(url);
    return data;
  }

  getTadaSingleDetails(id) {
    let url = `${appBaseUrl}/requisitionManagement/tadaApplicationDetails/${id}`;
    let data = getService(url);
    return data;
  }

  tadaDetailsUpdateSupervisor(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/tadaApplicationUpdateSupervisor/${id}`;
    let data = postService(url, body, "put");
    return data;
  }
  tadaDetailsUpdateManagement(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/tadaApplicationUpdateManagement/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  tadaDetailsUpdateAccountant(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/tadaApplicationUpdateAccountant/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  tadaDetailsUpdateSettlement(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/tadaApplicationSettlementUpdate/${id}`;
    let data = postService(url, body, "put");
    return data;
  }
  tadaApprovalData() {
    let url = `${appBaseUrl}/requisitionManagement/pendingTadaApplicationList`;
    let data = getService(url);
    return data;
  }
}
