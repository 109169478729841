import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// import AddUserDrawer from "./AddUserDrawer";
import AddUpdateSisterCompanyTable from './AddUpdateSisterCompanyTable';
import { employeeProfileDetail } from 'services/redux/profile/profile.action';
import AddUpdateSisterCompanyDrawer from './AddUpdateSisterCompanyDrawer';
import { getSisterCompaniesListings } from '../../../services/redux/admin/sisterCompanyListing/action';

const AddUpdateSisterCompany = () => {
  const dispatch = useDispatch();

  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);

  const [
    addUpdateSisterCompanyDrawerVisibility,
    setAddUpdateSisterCompanyDrawerVisibility,
  ] = useState(false);

  const [isUpdateSisterCompany, setIsUpdateSisterCompany] = useState(false);

  useEffect(() => {
    dispatch(getSisterCompaniesListings());
  }, []);

  const showAddUpdateSisterCompanyDrawer = (e, record) => {
    if (record) {
      setIsUpdateSisterCompany(record);
    } else {
      setIsUpdateSisterCompany(false);
    }
    setAddUpdateSisterCompanyDrawerVisibility(true);
  };

  const onAddUpdateSisterCompanyClose = () => {
    setIsUpdateSisterCompany('');
    setAddUpdateSisterCompanyDrawerVisibility(false);
  };

  return (
    <div className='layout'>
      <div className='organizer events'>
        <div className='all-header-wrapper'>
          <div className='meeting__flex'>
            <h3 className='header__all'>Sister Company Lists</h3>
            <div className='organizerHoliday__middle--wrap'>
              <Button
                className='btn-primary'
                onClick={showAddUpdateSisterCompanyDrawer}
              >
                <PlusOutlined /> New Sister Company
              </Button>
            </div>
          </div>
        </div>

        <AddUpdateSisterCompanyTable
          showAddUpdateSisterCompanyDrawer={showAddUpdateSisterCompanyDrawer}
        />
        <AddUpdateSisterCompanyDrawer
          drawerVisibility={addUpdateSisterCompanyDrawerVisibility}
          onAddUpdateSisterCompanyClose={onAddUpdateSisterCompanyClose}
          isUpdateSisterCompany={isUpdateSisterCompany}
          sisterCompanyListing={sisterCompanyListing}
        />
      </div>
    </div>
  );
};

export default AddUpdateSisterCompany;
