import { jsonParser } from "utils/misc";
import { COMMON_TYPES, EMAIL_TYPES } from "../../../types";

const INITIAL_STATE = {
    selectedFileName: jsonParser(jsonParser(sessionStorage.getItem("persist:root"))?.selectedFileNameState)?.selectedFileName ||
        {}
};

export default function selectedFileNameReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case COMMON_TYPES.ATTACHMENT_FILE_LISTING_SUCCESS:
            return {
                ...state,
                selectedFileName: action.payload
            }
        default:
            return { ...state };
    }
}
