import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import {
  addTadaDetails,
  getRequestAmount,
  getVisitCategory,
} from "services/redux/requistions/tadaDetails/action";
import storage from "utils/storage";

const ApplicantForm = ({ current, setCurrent, onAddDrawerClose }) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  let localStorageId = storage.getItem("userId");

  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);
  const { profileInfo } = useSelector((state) => state.profile);

  const {
    visitCategoryListingResponse,
    requestAmountListingResponse,
    addTadaDetailsResponse,
    addTadaDetailsLoading,
    isAddSuccess,
  } = useSelector((state) => state.tadaDetails);

  const [numOfDays, setNumOfDays] = useState(null);
  const [radioValue, setRadioValue] = useState();
  const [miscExpenses, setMiscExpenses] = useState(0);
  const [designationTypeValue, setDesignationTypeValue] = useState(null);
  const [totalPrice, setTotalPrice] = useState();

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const onDesignationTypeChange = (value) => {
    setDesignationTypeValue(value);
  };

  const handleMiscExpenses = (e) => {
    setMiscExpenses(Number(e.target.value));
  };

  const handleRangePickerChange = (dates) => {
    if (dates && dates.length === 2) {
      const diffInDays = moment(dates[1]).diff(moment(dates[0]), "days") + 1; // +1 to include both start and end date
      setNumOfDays(diffInDays);
    } else {
      setNumOfDays(null);
    }
  };

  const validateNonNegativeNumber = (_, value) => {
    if (value && Number(value) < 0) {
      return Promise.reject(new Error("Value cannot be negative."));
    } else if (value && Number(value) > 10000000) {
      return Promise.reject(new Error("Value exceeds the limit 10000000."));
    }
    return Promise.resolve();
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  useEffect(() => {
    dispatch(
      employeeProfileDetail(
        {
          AppUserId: localStorageId,
        },
        "loginUser"
      )
    );
  }, []);

  useEffect(() => {
    dispatch(getSisterCompaniesListings());
  }, []);

  useEffect(() => {
    if (radioValue !== undefined) {
      dispatch(getVisitCategory(radioValue));
    }
  }, [radioValue]);

  useEffect(() => {
    let lPrice =
      designationTypeValue &&
      requestAmountListingResponse?.map(
        (price) => price?.lodgingRate * (numOfDays - 1)
      );

    let fPrice =
      designationTypeValue &&
      requestAmountListingResponse?.map(
        (price) => price?.foodingRate * numOfDays
      );
    form.setFieldsValue({
      lodgingRate: lPrice,
      foodingRate: fPrice,
    });
    setTotalPrice(Number(lPrice) + Number(fPrice));
  }, [requestAmountListingResponse]);

  useEffect(() => {
    const body = {
      TadaDestinationTypeId: designationTypeValue,
      IsDomesticOrInternational: radioValue,
      DesignationId:
        profileInfo?.employeeOfficialDetailUpdateDto?.designationId,
    };
    dispatch(getRequestAmount(body));
  }, [designationTypeValue, radioValue]);

  useEffect(() => {
    if (addTadaDetailsResponse === true) {
      form.resetFields();
    }
  }, [addTadaDetailsResponse]);

  useEffect(() => {
    if (!isEmpty(addTadaDetailsResponse)) {
      setTimeout(() => {
        form.resetFields();
      }, 500);
    }
  }, [addTadaDetailsResponse]);

  useEffect(() => {
    if (isAddSuccess === true) {
      onAddDrawerClose(false);
      form.resetFields();
    }
  }, [isAddSuccess]);

  const next = () => {
    setCurrent(current + 1);
  };

  const rangeConfig = {
    rules: [{ type: "array", required: true, message: "Please select time!" }],
  };

  const onFinish = (values) => {
    // Should format date value before submit.
    const removeProp = "tadaDaysRange";
    const removefoodingRate = "foodingRate";
    const removelodgingRate = "lodgingRate";
    const {
      [removeProp]: remove,
      [removefoodingRate]: remove1,
      [removelodgingRate]: remove2,
      ...rest
    } = values;
    const body = {
      ...rest,
      tadaStatus: true,
      visitDays: numOfDays,
      miscellaneousAdvanceTotal: miscExpenses,
      visitStartDateEn: moment(values.tadaDaysRange[0]).format("YYYY-MM-DD"),
      visitEndDateEn: moment(values.tadaDaysRange[1]).format("YYYY-MM-DD"),
    };
    dispatch(addTadaDetails(body));
    setDesignationTypeValue(null);
  };

  return (
    <React.Fragment>
      <Form
        form={form}
        name="tadaDetails"
        layout="vertical"
        onFinish={onFinish}
      >
        <Layout.Content style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Form.Item
            className="form__group"
            label="On Account of"
            name="sisterCompanyId"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select placeholder="Select Company" autoComplete="off">
              {!isEmpty(sisterCompanyListing) &&
                sisterCompanyListing.map((company, index) => {
                  return (
                    <Option value={company.id} key={index}>
                      {company.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Row>
            <Col span={19}>
              <Form.Item
                label="Visit Date"
                name="tadaDaysRange"
                requiredMark="optional"
                {...rangeConfig}
                style={{ display: "block" }}
              >
                <RangePicker
                  onChange={handleRangePickerChange}
                  size="small"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={4}>
              <Form.Item label="Days" style={{ display: "block" }}>
                <InputNumber value={numOfDays} readOnly />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="isDomesticOrInternational" label="Type of visit">
            <Radio.Group onChange={onRadioChange} value={radioValue}>
              <Radio value={0}>Domestic</Radio>
              <Radio value={1}>International</Radio>
            </Radio.Group>
          </Form.Item>

          <Row style={{ justifyContent: "space-between", paddingTop: 5 }}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="tadaDestinationTypeId"
                rules={[
                  {
                    required: true,
                    message: "Please select your visit category!",
                  },
                ]}
              >
                <Select
                  size="small"
                  placeholder="Visit Category"
                  value={designationTypeValue}
                  onChange={onDesignationTypeChange}
                >
                  {!isEmpty(visitCategoryListingResponse) &&
                    visitCategoryListingResponse.map(
                      (visitCategoryValue, index) => {
                        return (
                          <Option value={visitCategoryValue?.id} key={index}>
                            {visitCategoryValue?.typeName}
                          </Option>
                        );
                      }
                    )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item name="placeOfVisit">
                <Input autoComplete="off" placeholder="City/Country Name" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Purpose of visit" name="purposeOfVisit">
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 6 }}
              autoComplete="off"
              placeholder="Mention purpose of visit here..."
            />
          </Form.Item>

          <Form.Item label="Transport Means" name="transportationMeans">
            <Input
              autoComplete="off"
              placeholder="for e.g Bus, Air, Train and so on."
            />
          </Form.Item>

          <Typography style={{ marginBottom: 4 }}>Request Amount</Typography>
        </Layout.Content>
        <Layout.Content
          style={{
            backgroundColor: "#FAFAFA",
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
          }}
        >
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>Category</Typography>
            </Col>
            <Col span={8}>
              <Typography style={{ marginBottom: 4, textAlign: "end" }}>
                Per Day
              </Typography>
            </Col>
            <Col span={8}>
              <Typography style={{ marginBottom: 4, textAlign: "end" }}>
                Total
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>Lodging</Typography>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  autoComplete="off"
                  placeholder="0"
                  readonly
                  value={
                    designationTypeValue &&
                    requestAmountListingResponse?.map(
                      (price) => price?.lodgingRate
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="lodgingRate">
                <Input
                  autoComplete="off"
                  placeholder="0"
                  disabled
                  readOnly
                  // value={
                  //   designationTypeValue &&
                  //   requestAmountListingResponse?.map(
                  //     (price) => price?.lodgingRate * numOfDays
                  //   )
                  // }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>Fooding</Typography>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  autoComplete="off"
                  placeholder="0"
                  readonly
                  value={
                    designationTypeValue &&
                    requestAmountListingResponse?.map(
                      (price) => price?.foodingRate
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="foodingRate">
                <Input
                  autoComplete="off"
                  placeholder="0"
                  readonly
                  disabled
                  // value={
                  //   designationTypeValue &&
                  //   requestAmountListingResponse?.map(
                  //     (price) => price?.foodingRate * numOfDays
                  //   )
                  // }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>
                Misc. Expenses
              </Typography>
            </Col>
            <Col span={16}>
              <Form.Item
                name="miscellaneousAdvanceTotal"
                rules={[
                  {
                    validator: validateNonNegativeNumber,
                  },
                ]}
              >
                <Input
                  type="number"
                  min={0}
                  max={10000000}
                  maxLength={10000000}
                  autoComplete="off"
                  placeholder="0"
                  value={miscExpenses}
                  onChange={handleMiscExpenses}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>Total Advance</Typography>
            </Col>
            <Col span={16}>
              <Form.Item>
                <Input
                  autoComplete="off"
                  placeholder="0"
                  readonly
                  value={totalPrice + miscExpenses}
                />
              </Form.Item>
            </Col>
          </Row>
        </Layout.Content>
        <Layout.Content
          style={{ display: "flex", flexDirection: "row-reverse", padding: 10 }}
        >
          <Button
            loading={addTadaDetailsLoading}
            htmlType="submit"
            style={{
              backgroundColor: "#3879C5",
              color: "white",
              borderRadius: 4,
            }}
          >
            Apply
          </Button>
        </Layout.Content>
      </Form>
    </React.Fragment>
  );
};

export default ApplicantForm;
