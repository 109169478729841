import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProcurementTable } from "services/redux/requistions/procurement/action";
import { LogoImage } from "utils/imageConstants";

const ProcurementTable = ({ loanApplicationId }) => {
  const dispatch = useDispatch();
  const leaveColumns = [
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      render: (_, record) => {
        return <>{record?.procurementApplicationDto?.vendorName}</>;
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (_, record) => {
        return <>{record?.procurementApplicationDto?.subject}</>;
      },
    },
    {
      title: "Amount",
      key: "tentativeAmount",
      dataIndex: "tentativeAmount",
      render: (_, record) => {
        return <>{record?.procurementApplicationDto?.tentativeAmount}</>;
      },
    },
  ];

  const { procurementDashResponse, procurementDashResponseLoading } =
    useSelector((state) => state.procurement);

  useEffect(() => {
    dispatch(getProcurementTable(loanApplicationId));
  }, [dispatch, loanApplicationId]);

  return (
    <>
      <Table
        className="organizer__tables remove-counter"
        columns={leaveColumns}
        rowKey="id"
        dataSource={procurementDashResponse}
        pagination={false}
        loading={{
          indicator: (
            <img src={LogoImage} height="auto" width="50px" alt="loading" />
          ),
          spinning: procurementDashResponseLoading,
        }}
      />
    </>
  );
};

export default ProcurementTable;
