import { ADMIN_TYPES } from "../../../../types";
import { init, success, finish, error } from "services/common";
import { LeaveFiscalDayService } from "./api";
import { message } from "antd";

const leaveFiscalDayService = new LeaveFiscalDayService();

export const leaveApplicationFiscalDaysPagination = (query) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.LEAVE_FISCAL_DAYS_PAGINATION));
    const response = await leaveFiscalDayService.leaveApplicationFiscalDaysPagination(query);
    dispatch(finish(ADMIN_TYPES.LEAVE_FISCAL_DAYS_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.LEAVE_FISCAL_DAYS_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const leaveApplicationFiscalDayAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.LEAVE_FISCAL_DAYS_ADD));
    const response = await leaveFiscalDayService.leaveApplicationFiscalDayAdd(body);
    dispatch(finish(ADMIN_TYPES.LEAVE_FISCAL_DAYS_ADD));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.LEAVE_FISCAL_DAYS_ADD, response.data));
      dispatch(leaveApplicationFiscalDaysPagination(`maxResultCount=10&skipCount=0`));
      message.success(`Fiscal Year Added Successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const leaveApplicationFiscalDayUpdate = (body, id) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.LEAVE_FISCAL_DAYS_UPDATE));
    const response = await leaveFiscalDayService.leaveApplicationFiscalDayUpdate(body, id);
    dispatch(finish(ADMIN_TYPES.LEAVE_FISCAL_DAYS_UPDATE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.LEAVE_FISCAL_DAYS_UPDATE, response.data));
      dispatch(leaveApplicationFiscalDaysPagination(`maxResultCount=10&skipCount=0`));
      message.success(`Fiscal Year Updated Successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};