import { Button, Card, Checkbox, DatePicker, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { actionForAddUpdateBidBound } from "services/redux/cead/action";
const { Item } = Form;

export default function PerformanceBoundForm({
  // setIsRenewalRecord,
  // isRenewalRecord,
  singleTenderCeadData,
  anotherStagePerformanceBoundForm,
  currencyOptions,
}) {
  const { tenderCeadBidBoundLoading } = useSelector(
    (state) => state.ceadReducer
  );
  const dispatch = useDispatch();

  const OnAnotherStagePerformanceBoundFormFinish = (values) => {
    let apiBody = values;

    dispatch(
      actionForAddUpdateBidBound(apiBody, singleTenderCeadData?.id, "bidType=4")
    );
  };
  return (
    <Card>
      <Form
        labelCol={{ span: 10 }}
        form={anotherStagePerformanceBoundForm}
        onFinish={OnAnotherStagePerformanceBoundFormFinish}
      >
        <Item label="Issuing Bank" name="issueingBank">
          <Input placeholder="Select Bank" />
        </Item>

        <Item label="Issuing Foreign Bank (if any)" name="foreignBank">
          <Input placeholder="Select Bank" />
        </Item>

        <Item label="L/C Description" name="description">
          <Input.TextArea placeholder="Enter Description" />
        </Item>

        <Item label="Currency" name="currencyId">
          <Select
            options={currencyOptions}
            // defaultValue={() => {
            //   allCurrencyListingResponse?.map((currency) => {
            //     if (currency.abbvr == "USD") {
            //       return currency.currencyId;
            //     }
            //   });
            // }}
          />
        </Item>

        <Item label="Amount" name="amount">
          <Input placeholder="Enter Amount" />
        </Item>

        <Item label="Validity" name="validUpto">
          <DatePicker />
        </Item>

        <Item
          label="Last Shipment Date"
          style={{ marginTop: "15px" }}
          name="lastShipmentDate"
        >
          <DatePicker />
        </Item>

        {/* <Item
          label="Renewal Record"
          name="IsRenewalRecord"
          valuePropName="checked"
        >
          <Checkbox
          // onChange={(e) => {
          //   setIsRenewalRecord(e.target.checked);
          // }}
          // //   value={isRenewalRecord}
          // defaultChecked={
          //   singleTenderCeadData?.biD_BOUND_BidInformation?.[0]?.isRenewalRecord
          // }
          />
        </Item> */}

        <Button
          type="primary"
          htmlType="submit"
          style={{ float: "right" }}
          loading={tenderCeadBidBoundLoading}
        >
          Save
        </Button>
      </Form>
    </Card>
  );
}
