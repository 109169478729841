import { Form, Input, Layout } from "antd";
import React from "react";

const SettlementApproverForm = ({ label, comment }) => {
  return (
    <Layout.Content
      style={{
        backgroundColor: "#FAFAFA",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        marginTop: 10,
      }}
    >
      <Form.Item label={label} name={comment}>
        <Input.TextArea
          readOnly
          autoSize={{ minRows: 1, maxRows: 6 }}
          autoComplete="off"
        />
      </Form.Item>
    </Layout.Content>
  );
};

export default SettlementApproverForm;
