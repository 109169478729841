import { HOME_TYPES } from '../../types';

const INITIAL_STATE = {
    nepaliDateResponse:[],
    attendenceAddNewResponse:[],
    attendenceAddNewResponseLoading : false
}

export default function HomeServicesReducer (state = INITIAL_STATE , action){
    switch(action.type){
        case HOME_TYPES.ENGLISH_TO_NEPAL_CONVERTER_INIT : 
            return {
                ...state,
                nepaliDateResponse : []
            }

        case HOME_TYPES.ENGLISH_TO_NEPAL_CONVERTER_SUCCESS : 
            return  {
                ...state,
                nepaliDateResponse : action.payload
            }

        case HOME_TYPES.ATTENDENCE_LATE_REASON_ADD_INIT:
            return {
                ...state,
                attendenceAddNewResponse : [],
                attendenceAddNewResponseLoading :true
            }

        case HOME_TYPES.ATTENDENCE_LATE_REASON_ADD_SUCCESS:
            return {
                ...state,
                attendenceAddNewResponse : action.payload,
                attendenceAddNewResponseLoading :false
            }

        case HOME_TYPES.ATTENDENCE_LATE_REASON_ADD_FINISH:
            return {
                ...state,
                attendenceAddNewResponseLoading :false
            }

        default :
        return {
            ...state,
        }
    }
}