import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Layout,
  Input,
  Space,
  Form,
  DatePicker,
  Checkbox,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
const { Header } = Layout;

const LoanApprovalFormDrawer = ({
  drawerVisibility,
  onAddUpdateDrawerClose,
  isUpdateApplication,
}) => {
  return (
    <div>
      <Drawer
        width={472}
        maskClosable={false}
        onClose={onAddUpdateDrawerClose}
        visible={drawerVisibility}>
        <Header className='drawer__header'>
          <h4 className='drawer__header--title'>Loan Approval</h4>
        </Header>
        <div className='drawer__content'>
          <div className='drawer__content--wrapper'>
            <div className='contact__content__wrapper'>
              <Form name='dynamic_form_nest_item'>
                <Form.Item className='form__group' label='Account Approver'>
                  <Input />
                </Form.Item>
                <Form.Item className='form__group' label='Management Approver'>
                  <Input />
                </Form.Item>
                <Form.Item
                  className='form__group'
                  label='Who else get notified after applied'>
                  <Input />
                </Form.Item>
                <Form.Item
                  className='form__group'
                  label='Who else get notified after applied after Management Action'>
                  <Input />
                </Form.Item>
                <Form.Item className='form__group' label='Effective From'>
                  <DatePicker />
                </Form.Item>
                <Form.Item className='form__group'>
                  <Checkbox>Active</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type='primary' >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default LoanApprovalFormDrawer;
