import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Layout,
  message,
} from "antd";
import { validateMessages } from "utils/misc";
import { cityAdd, cityUpdate } from "services/redux/contact/contact.action";
import { countryListing } from "services/remanentCalls";

const { Header, Footer } = Layout;
const { Option } = Select;

const CityAddUpdateDrawer = ({
  onAddUpdateCountryClose,
  drawerVisibility,
  isUpdateCountry,
}) => {
  const { cityAddResponse, cityUpdateResponse } = useSelector(
    (state) => state.contact
  );

  const [countries, setCountries] = useState([]);

  const [form] = Form.useForm();

  form.resetFields();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdateCountry) return;
    form.setFieldsValue({
      ...isUpdateCountry,
    });
  }, [isUpdateCountry]);

  useEffect(() => {
    onAddUpdateCountryClose();
  }, [cityAddResponse, cityUpdateResponse]);

  useEffect(() => {
    fetchCountryListing();
  }, []);

  const fetchCountryListing = async () => {
    const resp = await countryListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setCountries(resp.data);
    }
  };

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (isUpdateCountry) {
        dispatch(cityUpdate(isUpdateCountry.id, values));
      } else {
        dispatch(cityAdd(values));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onAddUpdateCountryClose}
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">
          {`${isUpdateCountry ? "Update" : "Create"} City Name`}
        </h4>
      </Header>
      <Form
        form={form}
        name="control-hooks"
        validateMessages={validateMessages}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name="countryId"
              label="Country"
              rules={[{ required: true }]}
            >
              <Select
                showSearch={true}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {countries.map((country) => (
                  <Option key={country.id}>{country.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              name="name"
              label="City Name"
              className="form__group"
              rules={[{ required: true }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </div>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateCountryClose}>Cancel</Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default CityAddUpdateDrawer;
