import { appBaseUrl } from "utils/config";
import { getService, postService } from "services/commonServices";

export class OrganizationType {
    
    organizationTypeAdd(body) {
        let url =  `${appBaseUrl}/organizationStructure/organizationLayerTypeCreate`;
        let data = postService(url, body);
        return data;
    }

    getOrganizationTypepagination(query) {
        let url =  `${appBaseUrl}/organizationStructure/organizationLayerTypePagination?${query}`;
        let data = getService(url);
        return data;
    }

}