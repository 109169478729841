import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, message, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import AddUserDrawer from "./AddUserDrawer";
import { getGuestEventsStatuses } from "services/redux/organizer/guestEvents/action";

import { honorRollGetDetails } from "../../../services/remanentCalls";
import moment from "moment";
import GuestEntryForm from "./GuestEntryForm";
import GuestIndex from "./GuestIndex";
import GuestList from "./GuestList";
const { RangePicker } = DatePicker;

const GuestEntry = () => {
  const dispatch = useDispatch();
  const guestEventsState = useSelector((state) => state.guestEvents);
  const location = useLocation();
  const {
    allGuestEvents,
    guestEventsLoading,
    addGuestEventResp,
    guestEventStatuses,
  } = guestEventsState;

  const [
    addUpdateCountryDrawerVisibility,
    setAddUpdateCountryDrawerVisibility,
  ] = useState(false);
  const [updateProfileDrawerVisibility, setUpdateProfileDrawerVisibility] =
    useState(false);
  const [showNoticeBoard, setShowNoticeBoard] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [isUpdateCountry, setIsUpdateCountry] = useState(false);
  const [endDate, setEndDate] = useState("");
  const onCloseNoticeBoard = () => {
    setShowNoticeBoard(false);
  };

  const showAddUpdateCountryDrawer = (e, record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };
  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry("");
    setAddUpdateCountryDrawerVisibility(false);
  };
  useEffect(() => {
    dispatch(getGuestEventsStatuses());
  }, []);

  return (
    <div className="layout">
      <div className="organizer honorRoll">
        <div className="global-header-wrapper">
          <div className="header-info">
            <h3 className="header__all ">Guest Entry</h3>
          </div>
          <div className="header-action-wrapper">
            <RangePicker
              onChange={(value) => {
                setStartDate(
                  value ? moment(value[0]).format("YYYY-MM-DD") : ""
                );
                setEndDate(value ? moment(value[1]).format("YYYY-MM-DD") : "");
              }}
            />
            <Button
              className="btn-primary"
              onClick={showAddUpdateCountryDrawer}
            >
              <PlusOutlined /> New Guest Entry
            </Button>
          </div>
        </div>
        <GuestEntryForm
          drawerVisibility={addUpdateCountryDrawerVisibility}
          isUpdateCountry={isUpdateCountry}
          onAddUpdateCountryClose={onAddUpdateCountryClose}
          onCloseNoticeBoard={onCloseNoticeBoard}
          guestEventStatuses={guestEventStatuses}
        />
        <GuestIndex
          data={allGuestEvents}
          showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
          guestEventsLoading={guestEventsLoading}
          guestEventStatuses={guestEventStatuses}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  );
};

export default GuestEntry;
