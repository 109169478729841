import { ADMIN_TYPES } from '../../../types';
import { uniqBy } from 'lodash';
import { FiscalYearService } from '../FiscalYear/api';

const INITIAL_STATE = {
  sisterCompanyListing: [],
  sisterCompanyListingLoading: false,

  sisterCompanyPagination: {
    items: [],
    totalCount: 1,
  },
  sisterCompanyPaginationLoading: false,

  sisterGetDetails: null,

  addUpdatesisterCompanyResponse: [],
  addUpdatesisterCompanyResponseLoading: false,
};

export default function sisterCompanyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Sister Company Lisiting
    case ADMIN_TYPES.SISTER_COMPANY_LISTS_INIT:
      return {
        ...state,
        sisterCompanyListing: [],
        sisterCompanyListingLoading: true,
      };
    case ADMIN_TYPES.SISTER_COMPANY_LISTS_SUCCESS:
      return {
        ...state,
        sisterCompanyListing: action.payload,
        sisterCompanyListingLoading: false,
      };
    case ADMIN_TYPES.SISTER_COMPANY_LISTS_FINISH:
      return {
        ...state,
        sisterCompanyListingLoading: false,
      };

    // Sister Company Pagination
    case ADMIN_TYPES.SISTER_COMPANY_PAGINATION_INIT:
      return {
        ...state,
        sisterCompanyPagination: [],
        sisterCompanyPaginationLoading: true,
      };
    case ADMIN_TYPES.SISTER_COMPANY_PAGINATION_SUCCESS:
      return {
        ...state,

        sisterCompanyPaginationLoading: false,
        sisterCompanyPagination: {
          items: action.loadMore
            ? uniqBy(
                state.sisterCompanyPagination?.items.concat(
                  action.payload.items
                ),
                'id'
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
      };
    case ADMIN_TYPES.SISTER_COMPANY_PAGINATION_FINISH:
      return {
        ...state,
        sisterCompanyPaginationLoading: false,
      };

    // Update Sister Company Pagination
    case ADMIN_TYPES.SISTER_COMPANY_UPDATE_INIT:
      return {
        ...state,
        addUpdatesisterCompanyResponse: [],
        addUpdatesisterCompanyResponseLoading: true,
      };
    case ADMIN_TYPES.SISTER_COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        addUpdatesisterCompanyResponse: action.payload,
        sisterCompanyPagination: {
          items: state.sisterCompanyPagination.items.map((items) => {
            if (items.id === action.payload.id) {
              return {
                ...action.payload,
              };
            } else {
              // console.log("same");
              return {
                ...items,
              };
            }
          }),
          totalCount: state.sisterCompanyPagination.totalCount,
        },
        sisterCompanyPaginationLoading: false,
        addUpdatesisterCompanyResponseLoading: false,
      };
    case ADMIN_TYPES.SISTER_COMPANY_UPDATE_FINISH:
      return {
        ...state,
        addUpdatesisterCompanyResponseLoading: false,
      };
    default:
      return { ...state };
  }
}
