import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Space, Table, Button } from "antd";

import NoStyleButton from "components/Button/NoStyleButton";
import moment from "moment";

import ClearanceTemplateAddUpdateForm from "./ClearanceTemplateAddUpdateForm";

const ClearanceTemplateTable = ({
  employeeUserId,
  clearanceFormListingResponse,
  formState,
  setFormState,
  updateData,
  setUpdateData,
}) => {
  const columns = [
    {
      title: "S.N",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: 30,
    },
    {
      title: "Equipment Name",
      dataIndex: "equipmentName",
      key: "equipmentName",
      width: 100,
    },
    {
      title: "Department",
      dataIndex: "departmentName",
      key: "departmentName",
      width: 100,
      render: (_, record) => {
        return <>{record?.organizationDepartmentDto?.departmentName}</>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 100,
    },
    {
      title: "Issued Date",
      dataIndex: "issueDate",
      key: "issueDate",
      width: 100,
      render: (_, record) => {
        return <>{moment(record?.issuedDate).format("YYYY MMM DD")}</>;
      },
    },
    {
      title: "Return Date",
      dataIndex: "returnDate",
      key: "returnDate",
      width: 100,
      render: (_, record) => {
        return <>{moment(record?.returnDate).format("YYYY MMM DD")}</>;
      },
    },
    {
      title: "Issuer",
      dataIndex: "creatorInformation",
      key: "creatorInformation",
      width: 100,
      render: (_, record) => {
        return <>{record?.clearanceIssueUserDto?.employeeName}</>;
      },
    },
    {
      title: "Approval",
      dataIndex: "approverInformation",
      key: "approverInformation",
      width: 100,
      render: (_, record) => {
        return <>{record?.clearanceApprovalUserDto?.employeeName}</>;
      },
    },
    {
      title: "Approval Date",
      dataIndex: "approverDate",
      key: "approverDate",
      width: 100,
      render: (_, record) => {
        return <>{moment(record?.approvalDate).format("YYYY MMM DD")}</>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <NoStyleButton
            text="Edit"
            OnClickButton={() => {
              setFormState({ addForm: true, editForm: true });
              setUpdateData(record);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={clearanceFormListingResponse}
        columns={columns}
        pagination={false}
        scroll={{
          x: 1200,
          y: 400,
        }}
        className="clearance__form-tbl"
      />

      <ClearanceTemplateAddUpdateForm
        employeeUserId={employeeUserId}
        formState={formState}
        setFormState={setFormState}
        updateData={updateData}
        setUpdateData={setUpdateData}
      />
    </>
  );
};

export default ClearanceTemplateTable;
