import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button } from "antd";
import { LogoImage } from "utils/imageConstants";

const CityAddUpdateTable = ({ showAddUpdateCountryDrawer, getData, setSkipCount, pageSize }) => {
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width:"5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Contact Label Group Name",
      dataIndex: "contactLabelGroupName",
      key: "contactLabelGroupName",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a className='action-btn action-btn-add' onClick={(e) => showAddUpdateCountryDrawer(e, record)}>
          Edit Contact Label
        </a>
      ),
    },
  ];

  const dispatch = useDispatch();
  const {
    contactLabelPaginationResponse,
    contactLabelPaginationLoading,
  } = useSelector((state) => state.contact);

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);

  useEffect(() => {
    if (!contactLabelPaginationResponse) return;
    setdata(contactLabelPaginationResponse.items);
    setTotalCount(contactLabelPaginationResponse.totalCount);
  }, [contactLabelPaginationResponse]);


  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey
    let SortType = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;
    setSkipCount(SkipCount);
    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };

  return (
    <Table className="contact-table"
      columns={columns}
      rowKey="appUserId"
      dataSource={data}
      onChange={handleTableChange}
      // loading={contactLabelPaginationLoading}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" />
        ),
        spinning: contactLabelPaginationLoading,
      }}
      pagination={{
        total: totalCount,
        pageSize: pageSize,
      }}
    />
  );
};

export default CityAddUpdateTable;
