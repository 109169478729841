import { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  actionForContractAddUpdateClosure,
  //   actionForGetSingleCeadTenderDetail,
} from "services/redux/cead/action";
import ClosureDetails from "./details";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { isEmpty } from "lodash";

const { Title } = Typography;

export default function ClosureView({ formClosure, singleTenderCeadData }) {
  const userId = JSON.parse(sessionStorage.getItem("userId"));

  const {
    tenderCeadAddUpdateClosureLoading,
    tenderCeadAddUpdateClosureSuccess,
  } = useSelector((state) => state.ceadReducer);

  const dispatch = useDispatch();

  const [showFunding, setFundingShow] = useState(false);
  console.log(
    "tenderCeadAddUpdateClosureSuccess",
    tenderCeadAddUpdateClosureSuccess
  );

  useEffect(() => {
    if (tenderCeadAddUpdateClosureSuccess) {
      setFundingShow(false);
    }
  }, [tenderCeadAddUpdateClosureLoading]);

  const onFinish = (values) => {
    const apiData = {
      ...values,
      tenderCEAD_Id: singleTenderCeadData?.id,
      isActive: true,
      closedUserId: userId,
    };
    dispatch(actionForContractAddUpdateClosure(apiData));
  };

  return (
    <>
      <Title level={5} style={{ background: "#DFDFDF" }}>
        Closure Details
        {!showFunding && (
          <span
            onClick={() => {
              setFundingShow(!showFunding);
              if (isEmpty(singleTenderCeadData?.tenderCEADContractClosureDto)) {
                formClosure.resetFields();
              }
              formClosure.setFieldsValue({
                ...singleTenderCeadData?.tenderCEADContractClosureDto,
                closedDate: moment(
                  singleTenderCeadData?.tenderCEADContractClosureDto?.closedDate
                ),
              });
            }}
            className="bid-pointer-cursor"
          >
            <EditOutlined />
          </span>
        )}
      </Title>

      {!showFunding ? (
        <ClosureDetails
          closureDetails={singleTenderCeadData?.tenderCEADContractClosureDto}
        />
      ) : (
        <div style={{ marginTop: "10px" }}>
          <Form
            layout="horizontal"
            labelCol={{ span: 12 }}
            onFinish={onFinish}
            form={formClosure}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Letter of Credit Status"
                  name="letterOfCreditStatus"
                >
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Performance Bond" name="performanceBond">
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Terms Against Dispatch"
                  name="termsAgainstDispatch"
                >
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Terms Amount" name="termsAmount">
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Terms Delay Days" name="termsDelayDays">
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Shipment" name="shipment">
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Commission Rate" name="commissionRate">
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Commission Amount" name="commissionAmount">
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Commission Mode" name="commissionMode">
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Commission Remarks" name="commissionRemarks">
                  <Input placeholder="Enter..." />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Closed Date" name="closedDate">
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ float: "right" }}>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setFundingShow(false);
                }}
              >
                Cancel
              </Button>

              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      )}
    </>
  );
}
