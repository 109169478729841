import {
  Button,
  Checkbox,
  Form,
  Image,
  Row,
  Select,
  Switch,
  Table,
  Tag,
} from "antd";
import React from "react";
import mailApp from "../../../../assets/images/icons/mail-inbox-app.png";
import { DownloadOutlined } from "@ant-design/icons";
import getColumnsDashboard2 from "../Tables/Columns2";
import { Excel } from "antd-table-saveas-excel";
import { LogoImage } from "utils/imageConstants";

const TabThree = ({
  showDate,
  onChangeCheckbox,
  onFinish,
  canExport,
  setCanexport,
  isSoftware,
  calendarFilter2,
  nepaliDateResponse,
  handleDateChange,
  dateOptions,
  loader,
  setLoader,
  handleMonthChange,
  monthOptions,
  totalWorkingDays,
  newMonth,
  getYear,
  totalDays,
  frommDate,
  absentPaginationResposne,
}) => {
  const [form] = Form.useForm();
  const columns1 = getColumnsDashboard2({
    totalWorkingDays,
    newMonth,
    getYear,
    totalDays,
    frommDate,
    showDate,
  });
  return (
    <>
      <div
        className="calander_body"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="date_title_first" style={{ display: "flex" }}>
          <h5 className="dashboards__mailInfo--title">
            <Image src={mailApp} />
            Attendence
          </h5>
          <div
            className="date_left"
            style={{
              marginLeft: "auto",
              marginRight: 20,
            }}
          >
            <Checkbox checked={showDate} onChange={onChangeCheckbox}>
              Show Time
            </Checkbox>
          </div>
        </div>

        <Form form={form} onFinish={onFinish}>
          <Row>
            {canExport && (
              <div
                className="fc-toolbar-title"
                style={{ paddingLeft: 15, marginTop: 4 }}
              >
                Date:
                {/* {`${newYear} ${getMonths().label}`} */}
              </div>
            )}

            <div
              className="date_form"
              style={{
                display: "flex",
                width: 726,
                marginLeft: "auto",
                marginRight: 10,
              }}
            >
              <div
                className="software-attendence__filter"
                style={{ marginRight: 20, marginTop: 4 }}
              >
                <span>Software</span>
                <Switch
                  disabled={!canExport}
                  checked={isSoftware}
                  onChange={calendarFilter2}
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
                <span>Finger Print</span>
              </div>

              <div
                className="dateForm"
                style={{
                  width: "100px",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Form.Item name="SearchToDate1">
                  <Select
                    defaultValue={nepaliDateResponse?.nepaliYearInEnglish}
                    onChange={handleDateChange}
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                    options={dateOptions}
                  ></Select>
                </Form.Item>
              </div>
              <div
                className="dateForm"
                style={{
                  width: "200px",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Form.Item name="month">
                  <Select
                    disabled={loader}
                    defaultValue={1}
                    onChange={handleMonthChange}
                    options={monthOptions}
                    rules={[
                      {
                        required: true,
                        message: "Required!",
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  style={{ width: 103 }}
                  loading={loader}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    setCanexport(true);
                    setLoader(false);
                  }}
                >
                  Search
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  disabled={!canExport}
                  // disabled
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    const excel = new Excel();

                    excel
                      .addSheet("test")
                      .addColumns(columns1)
                      .addDataSource(absentPaginationResposne, {
                        str2Percent: true,
                      })
                      .saveAs("test.xlsx");
                  }}
                >
                  Export
                </Button>
              </div>
            </div>
          </Row>
        </Form>

        <Table
          scroll={{ x: true }}
          columns={columns1}
          rowKey={(record) => record.appUserId}
          className={`organizer__tables contact-table attendance-table table_not_scroll_x`}
          dataSource={absentPaginationResposne}
          pagination={false}
          loading={{
            indicator: (
              <img src={LogoImage} height="auto" width="50px" alt="logo" />
            ),
            spinning: loader,
          }}
        ></Table>
        {showDate && canExport && (
          <div
            className="AttendanceInfo"
            style={{
              float: "right",
              width: "100%",
              paddingLeft: 15,
              paddingTop: 15,
            }}
          >
            <Tag color="#f50" style={{ height: "15px" }}></Tag>
            <span>L: Leave</span>
            <br />
            <Tag color="#cd201f" style={{ height: "15px" }}></Tag>
            <span>UL:UnAuthorized Leave</span>
            <br />
            <Tag color="#73d13d" style={{ height: "15px" }}></Tag>
            <span>P:Present</span>
            <br />
            <Tag color="#d9d9d9" style={{ height: "15px" }}></Tag>
            <span>H:Holiday</span>
            <br />
            <br />
          </div>
        )}
      </div>
    </>
  );
};

export default TabThree;
