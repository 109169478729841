import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import _, { uniqBy } from "lodash";
import { actions } from "react-table";
import { FiscalYearService } from "services/redux/admin/FiscalYear/api";
import { REFERENCE__TYPES } from "../../../types";

const INITIAL_STATE = {
  tenderBasicPaginationResponse: {
    items: [],
    totalCount: 1,
  },
  tenderCounterListResponse: {
    items: [],
  },
  tenderHistoryPaginationResponse: {
    items: [],
    totalCount: 1,
  },
  tenderFourthPaginationResponse: [],
  tenderBasicPaginationLoading: false,
  tenderBasicGetDetailsLoading: false,
  tenderBasicGetDetailsResponse: null,
  tenderBasicAddResponse: null,
  tenderBasicAddLoadingResponse: false,

  tenderBasicGetDetailsRotoLoading: false,
  tenderBasicGetDetailsRotoResponse: [],

  tenderHistoryUpcomingPaginationLoading: false,

  dueDateTenderResponse: null,
  dueDateTenderResponseLoading: false,

  //addExtension
  tenderBasicAddExtensionResponse: null,
  tenderBasicAddExtensionsLoadingResponse: false,

  //UpdateExtension
  tenderBasicUpdateExtensionResponse: null,
  tenderBasicUpdateExtensionsLoadingResponse: false,

  //GetDetailsExtensions
  tenderBasicExtensionGetDetailsLoading: false,
  tenderBasicExtensionGetDetailsResponse: [],

  //PROJECT Update
  tenderBasicUpdateResponse: null,
  tenderBasicUpdateLoading: false,

  //TenderFav
  tenderIsFavResponse: null,
  tenderIsFavResponseLoading: false,

  //tender stage and type listing
  tenderStageListingLoading: false,
  tenderStageListingResponse: [],
  tenderTypeListingLoading: false,
  tenderTypeListingResponse: [],

  tenderSubTypeListingLoading: false,
  tenderSubTypeListingResponse: [],

  //tenderDocument
  tenderDocumentGetDetailsLoading: false,
  tenderDocumentGetDetailsResponse: null,
  tenderDocumentAddResponse: null,
  tenderDocumentAddLoadingResponse: false,
  tenderDocumentUpdateResponse: null,
  tenderDocumentUpdateLoading: false,

  //tenderParticipation
  tenderParticipationGetDetailsLoading: false,
  tenderParticipationGetDetailsResponse: null,
  tenderParticipationAddResponse: null,
  tenderParticipationAddLoadingResponse: false,
  tenderParticipationUpdateResponse: null,
  tenderParticipationUpdateLoading: false,

  //tenderBasicDocument
  documentUploadResp: [],
  documentUploadLoading: false,

  //updateStageLevel
  tenderBasicUpdateStageResponse: null,
  tenderBasicUpdateStageLoadingResponse: false,

  //addStageDocumnet
  stageDocumentResp: null,
  stageDocumentLoading: false,

  //STAGEADD
  stageLevelAddResponse: null,
  stageLevelAddLoading: false,

  //updateStage
  stageLevelUpdate: null,
  stageLevelUpdateLoading: false,

  participationAddResponse: null,
  participationAddLoading: false,

  participationUpdateResponse: null,
  participationUpdateLoading: false,

  //tenderParticipationPagination
  participationPaginationLoading: false,
  participationPaginationResponse: { items: [], totalCount: 1 },

  //documentPurchasePaginationResponse
  documentPaginationLoading: false,
  documentPaginationResponse: { items: [], totalCount: 1 },

  //stageAddExtension Response

  tenderStageAddExtensionResponse: null,
  tenderStageAddExtensionsLoadingResponse: false,

  //updateExtension Response
  tenderStageUpdateExtensionResponse: null,
  tenderStageUpdateExtensionsLoadingResponse: false,

  //getDetailsExtension Response

  tenderStageGetDetailsExtensionResponse: null,
  tenderStageGetDetailsExtensionsLoadingResponse: false,

  // CIRCULAR PLANNING TENDER CHECKLIST
  tenderCircularPlanningCheckListResponse: [],
  tenderCircularPlanningCheckListLoading: false,

  // checklist api
  addUpdateCircularCheckListResponse: [],
  addUpdateCircularCheckListLoading: false,

  // circular reference template
  singleCircularReferenceTemplateResponse: [],
  singleCircularReferenceTemplateLoading: false,

  // addupate
  addUpdateCircularReferenceTemplateResponse: [],
  addUpdateCircularReferenceTemplateLoading: false,

  // isApproveStatus
  isApproveTenderMailResp: [],
  isApproveTenderMailLoading: false,

  // getApprovedStatus Circular Planning
  getApprovedStatusCircularPlanningResp: [],
  getApprovedStatusCircularPlanningLoading: false,
};

export default function tenderReducer(state = INITIAL_STATE, action) {
  //reference project pagination
  switch (action.type) {
    //Tender Basic
    case REFERENCE__TYPES.TENDER_BASIC_PAGINATION_INIT:
      return {
        ...state,
        tenderBasicPaginationLoading: true,
      };

    case REFERENCE__TYPES.TENDER_BASIC_PAGINATION_SUCCESS:
      return {
        ...state,
        // tenderBasicPaginationResponse: {
        //   items: action.loadMore
        //     ? uniqBy(
        //         state.tenderBasicPaginationResponse.items.concat(
        //           action.payload.items
        //         ),
        //         "id"
        //       )
        //     : action.payload.items,
        //   totalCount: action.payload.totalCount,
        // },
        tenderBasicPaginationResponse: {
          items: action.loadMore
            ? uniqBy(
                state.tenderBasicPaginationResponse.items
                  .concat(action.payload.items)
                  .filter((item) => item.isActive),
                "id"
              )
            : uniqBy(
                action.payload.items.filter((item) => item.isActive),
                "id"
              ),
          totalCount: action.payload.totalCount,
        },

        tenderBasicPaginationLoading: false,
      };
    case REFERENCE__TYPES.TENDER_BASIC_PAGINATION_FINISH:
      return {
        ...state,
        tenderBasicPaginationLoading: false,
      };
    //history tender
    case REFERENCE__TYPES.TENDER_HISTORY_PAGINATION_INIT:
      return {
        ...state,
        tenderHistoryUpcomingPaginationLoading: true,
      };
    case REFERENCE__TYPES.TENDER_HISTORY_PAGINATION_SUCCESS:
      return {
        ...state,
        tenderHistoryPaginationResponse: {
          items: action.payload.items,
          totalCount: action.payload.totalCount,
        },
        tenderHistoryPaginationLoading: false,
      };
    case REFERENCE__TYPES.TENDER_HISTORY_PAGINATION_FINISH:
      return {
        ...state,
        tenderHistoryUpcomingPaginationLoading: false,
      };
    //upcoming tender
    case REFERENCE__TYPES.TENDER_UPCOMING_PAGINATION_INIT:
      return {
        ...state,
        tenderHistoryUpcomingPaginationLoading: true,
      };
    case REFERENCE__TYPES.TENDER_UPCOMING_PAGINATION_SUCCESS:
      return {
        ...state,
        tenderUpcomingPaginationResponse: {
          items: action.payload.items,
          totalCount: action.payload.totalCount,
        },
      };
    case REFERENCE__TYPES.TENDER_UPCOMING_PAGINATION_FINISH:
      return {
        ...state,
        tenderHistoryUpcomingPaginationLoading: false,
      };
    //Fourth Coming  Tender
    case REFERENCE__TYPES.TENDER_FOURTH_PAGINATION_PAGINATION_INIT:
      return {
        ...state,
        tenderHistoryUpcomingPaginationLoading: true,
      };
    case REFERENCE__TYPES.TENDER_FOURTH_UPCOMING_PAGINATION_SUCCESS:
      return {
        ...state,
        tenderFourthPaginationResponse: {
          items: action.payload.items,
          totalCount: action.payload.totalCount,
        },
      };
    case REFERENCE__TYPES.TENDER_FOURTH_UPCOMING_PAGINATION_FINISH:
      return {
        ...state,
        tenderHistoryUpcomingPaginationLoading: false,
      };
    //     REFERENCE_DUE_TENDER: "REFERENCE_DUE_TENDER",
    // REFERENCE_DUE_TENDER_INIT: "REFERENCE_DUE_TENDER_INIT",
    // REFERENCE_DUE_TENDER_FINISH: "REFERENCE_DUE_TENDER_FINISH",
    // REFERENCE_DUE_TENDER_SUCCESS: "REFERENCE_DUE_TENDER_SUCCESS",
    case REFERENCE__TYPES.REFERENCE_DUE_TENDER_INIT:
      return {
        ...state,
        dueDateTenderResponseLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_DUE_TENDER_SUCCESS:
      return {
        ...state,
        dueDateTenderResponse: action.payload,

        dueDateTenderResponseLoading: false,
      };
    case REFERENCE__TYPES.REFERENCE_DUE_TENDER_FINISH:
      return {
        ...state,
        dueDateTenderResponseLoading: false,
      };

    // ROTO MODAL GET
    // TENDER_BASIC_ROTO_GetDetails: "TENDER_BASIC_ROTO_GetDetails",
    // TENDER_BASIC_ROTO_GetDetails_INIT: "TENDER_BASIC_ROTO_GetDetails_INIT",
    // TENDER_BASIC_ROTO_GetDetails_FINISH: "TENDER_BASIC_ROTO_GetDetails_FINISH",
    // TENDER_BASIC_ROTO_GetDetails_SUCCESS: "TENDER_BASIC_ROTO_GetDetails_SUCCESS",

    case REFERENCE__TYPES.TENDER_BASIC_ROTO_GetDetails_INIT:
      return {
        ...state,
        tenderBasicGetDetailsRotoLoading: true,
        tenderBasicGetDetailsRotoResponse: [],
      };
    case REFERENCE__TYPES.TENDER_BASIC_ROTO_GetDetails_SUCCESS:
      return {
        ...state,
        tenderBasicGetDetailsRotoResponse: action.payload,
        tenderBasicGetDetailsRotoLoading: false,
      };
    case REFERENCE__TYPES.TENDER_BASIC_ROTO_GetDetails_FINISH:
      return {
        ...state,
        tenderBasicGetDetailsRotoLoading: false,
      };

    case REFERENCE__TYPES.TENDER_BASIC_GetDetails_INIT:
      return {
        ...state,
        tenderBasicAddResponse: null,
        tenderBasicUpdateResponse: null,
        stageLevelAddResponse: null,
        stageLevelUpdate: null,
        tenderBasicGetDetailsLoading: true,
        tenderBasicGetDetailsResponse: null,
      };

    case REFERENCE__TYPES.TENDER_BASIC_GetDetails_SUCCESS:
      return {
        ...state,
        tenderBasicGetDetailsResponse: action.payload,
        tenderBasicGetDetailsLoading: false,
      };

    // case UPDATE
    case REFERENCE__TYPES.TENDER_BASIC_GetDetails_UPDATE:
      let id = "c2ff5cf8-c16b-4c19-ad48-f14405fc3ea2";
      // let { participantFrom, participantTos, ...rest } =
      //   action.payload.mailMessageParticipantWithTypeDtos;
      // let requirenF = {
      //   ...rest,
      //   from: participantFrom && {
      //     participantUserId: participantFrom?.participantUserId,
      //     participantName: participantFrom?.participantUserDto?.fullName,
      //   },
      //   to:
      //     participantTos &&
      //     participantTos?.map((item) => ({
      //       participantUserId: item?.participantUserId,
      //       participantName: item?.participantUserDto?.fullName,
      //     })),
      // };

      let updatedObject;
      if (
        state?.tenderBasicGetDetailsResponse?.id ===
        action.payload.tenderBasicId
      ) {
        updatedObject = {
          ...state.tenderBasicGetDetailsResponse,
          isCircularPlanningApprovalRequestSent: true,
          mailMessageParticipantWithTypeDtos:
            action.payload.mailMessageParticipantWithTypeDtos ?? {},
          // mailMessageParticipantWithTypeDtos: requirenF ?? {},
          circularPlanningSendForApprovalLogDto:
            action.payload.circularPlanningSendForApprovalLogDto,
        };
      } else {
        updatedObject = state.tenderBasicGetDetailsResponse;
      }

      return {
        ...state,
        tenderBasicGetDetailsResponse: updatedObject,
        tenderBasicGetDetailsLoading: false,
      };

    // case PARTIAL
    case REFERENCE__TYPES.TENDER_BASIC_GetDetails_PARTIAL:
      return {
        ...state,
        tenderBasicGetDetailsResponse: {
          ...state?.tenderBasicGetDetailsResponse,
          isCircularPlanningApprovalRequestSent: false,
          isCircularApprovalStatus: false,
        },
        tenderBasicGetDetailsLoading: false,
      };

    case REFERENCE__TYPES.TENDER_BASIC_GetDetails_FINISH:
      return {
        ...state,
        tenderBasicGetDetailsResponse: null,
        tenderBasicGetDetailsLoading: false,
      };

    case REFERENCE__TYPES.TENDER_BASIC_ADD_INIT:
      return {
        ...state,
        tenderBasicAddLoadingResponse: true,
        tenderBasicAddResponse: null,
      };

    case REFERENCE__TYPES.TENDER_BASIC_ADD_SUCCESS:
      return {
        ...state,
        tenderBasicAddLoadingResponse: false,
        tenderBasicAddResponse: action.payload,
        tenderBasicGetDetailsResponse: action.payload,
        tenderBasicPaginationResponse: state.tenderBasicPaginationResponse,
      };

    case REFERENCE__TYPES.TENDER_BASIC_ADD_FINISH:
      return {
        ...state,
        tenderBasicAddLoadingResponse: false,
        tenderBasicAddResponse: null,
      };

    case REFERENCE__TYPES.TENDER_BASIC_UPDATE_INIT:
      return {
        ...state,
        tenderBasicUpdateResponse: null,
        tenderBasicUpdateLoading: true,
      };
    case REFERENCE__TYPES.TENDER_BASIC_UPDATE_SUCCESS:
      return {
        ...state,
        tenderBasicUpdateResponse: action.payload,
        // tenderBasicGetDetailsResponse: action.payload,
        tenderBasicUpdateLoading: false,
      };

    case REFERENCE__TYPES.TENDER_BASIC_UPDATE_FINISH:
      return {
        ...state,
        tenderBasicUpdateResponse: null,
        tenderBasicUpdateLoading: false,
      };

    case REFERENCE__TYPES.TENDER_BASIC_UPDATE_DELETE:
      let items = state?.tenderBasicPaginationResponse?.items || [];

      let activeItems = items
        ?.map((item) => {
          if (action.payload.id === item.id) {
            return action.payload?.isActive
              ? { ...item, isActive: action.payload.isActive }
              : null;
          } else {
            return {
              ...item,
            };
          }
        })
        .filter((item) => item !== null);

      let totalCountUpdate = activeItems?.length;

      return {
        ...state,
        tenderBasicPaginationResponse: {
          items: activeItems,
          totalCount: totalCountUpdate,
        },
        tenderBasicPaginationLoading: false,
      };

    //stage listing
    case REFERENCE__TYPES.GET_TENDERSTAGELISTING_INIT:
      return {
        ...state,
        tenderStageListingResponse: [],
        tenderStageListingLoading: true,
      };
    case REFERENCE__TYPES.GET_TENDERSTAGELISTING_SUCCESS:
      return {
        ...state,
        tenderStageListingResponse: action.payload,
        tenderStageListingLoading: false,
      };
    case REFERENCE__TYPES.GET_TENDERSTAGELISTING_FINISH:
      return {
        ...state,
        tenderStageListingLoading: false,
      };
    //type Listing
    case REFERENCE__TYPES.GET_TENDERTYPELISTING_INIT:
      return {
        ...state,
        tenderTypeListingResponse: [],
        tenderTypeListingLoading: true,
      };
    case REFERENCE__TYPES.GET_TENDERTYPELISTING_SUCCESS:
      return {
        ...state,
        tenderTypeListingResponse: action.payload,
        tenderTypeListingLoading: false,
      };
    case REFERENCE__TYPES.GET_TENDERTYPELISTING_FINISH:
      return {
        ...state,
        tenderTypeListingLoading: false,
      };

    // subtype
    case REFERENCE__TYPES.GET_TENDER_SUBTYPE_LISTING_INIT:
      return {
        ...state,
        tenderSubTypeListingResponse: [],
        tenderSubTypeListingLoading: true,
      };

    case REFERENCE__TYPES.GET_TENDER_SUBTYPE_LISTING_SUCCESS:
      return {
        ...state,
        tenderSubTypeListingResponse: action.payload,
        tenderSubTypeListingLoading: false,
      };

    case REFERENCE__TYPES.GET_TENDER_SUBTYPE_LISTING_FINISH:
      return {
        ...state,
        tenderSubTypeListingLoading: false,
      };

    //tenderDocument

    case REFERENCE__TYPES.TENDER_DOCUMENT_GET_DETAILS_INIT:
      return {
        ...state,
        tenderDocumentGetDetailsLoading: true,
        tenderDocumentGetDetailsResponse: null,
      };

    case REFERENCE__TYPES.TENDER_DOCUMENT_Get_DETAILS_SUCCESS:
      return {
        ...state,
        tenderDocumentGetDetailsLoading: action.payload,
        tenderDocumentGetDetailsResponse: false,
      };

    case REFERENCE__TYPES.TENDER_DOCUMENT_GET_DETAILS_FINISH:
      return {
        ...state,
        tenderDocumentGetDetailsLoading: null,
        tenderDocumentGetDetailsResponse: false,
      };

    case REFERENCE__TYPES.TENDER_DOCUMENT_ADD:
      return {
        tenderDocumentAddLoadingResponse: true,
        tenderDocuemntAddResponse: null,
      };

    case REFERENCE__TYPES.TENDER_DOCUMENT_ADD_SUCCESS:
      return {
        ...state,
        tenderDocumentAddLoadingResponse: false,
        tenderDocumentAddResponse: action.payload,
      };

    case REFERENCE__TYPES.TENDER_DOCUMENT_ADD_FINISH:
      return {
        tenderDocumentAddLoadingResponse: false,
        tenderDocumentAddResponse: null,
      };

    case REFERENCE__TYPES.TENDER_DOCUMENT_UPDATE_INIT:
      return {
        ...state,
        tenderDocumentUpdateResponse: null,
        tenderDocumentUpdateLoading: true,
      };
    case REFERENCE__TYPES.TENDER_DOCUMENT_UPDATE_SUCCESS:
      // console.log("hey doc added", action.payload);
      return {
        tenderDocumentUpdateResponse: action.payload,

        tenderDocumentUpdateLoading: false,
      };
    case REFERENCE__TYPES.TENDER_DOCUMENT_UPDATE_FINISH:
      return {
        ...state,
        tenderDocumentUpdateLoading: false,
      };

    //tenderParticipation
    case REFERENCE__TYPES.TENDER_PARTICIPATION_GET_DETAILS_INIT:
      return {
        ...state,
        tenderParticipationGetDetailsLoading: true,
        tenderParticipationGetDetailsResponse: null,
      };

    case REFERENCE__TYPES.TENDER_PARTICIPATION_GET_DETAILS_SUCCESS:
      // console.log("heyy", action.payload);
      return {
        ...state,
        tenderParticipationGetDetailsResponse: action.payload,
        tenderParticipationGetDetailsLoading: false,
      };

    case REFERENCE__TYPES.TENDER_PARTICIPATION_GET_DETAILS_FINISH:
      return {
        ...state,
        tenderParticipationGetDetailsResponse: null,
        tenderParticipationGetDetailsLoading: false,
      };

    // case REFERENCE__TYPES.TENDER_PARTICIPATION_ADD:
    //   return {
    //     tenderParticipationAddResponse: true,
    //     tenderParticipationAddLoadingResponse: null,
    //   };

    // case REFERENCE__TYPES.TENDER_PARTICIPATION_ADD_SUCCESS:
    //   return {
    //     ...state,
    //     tenderParticipationAddResponse: action.payload,
    //     tenderParticipationAddLoadingResponse: false,
    //   };

    // case REFERENCE__TYPES.TENDER_PARTICIPATION_ADD_FINISH:
    //   return {
    //     tenderParticipationAddResponse: null,
    //     tenderParticipationAddLoadingResponse: false,
    //   };

    case REFERENCE__TYPES.TENDER_PARTICIPATION_UPDATE_INIT:
      return {
        ...state,
        tenderParticipationUpdateResponse: null,
        tenderParticipationUpdateLoading: true,
      };
    case REFERENCE__TYPES.TENDER_PARTICIPATION_UPDATE_SUCCESS:
      return {
        tenderParticipationUpdateResponse: action.payload,
        tenderParticipationUpdateLoading: false,
      };
    case REFERENCE__TYPES.TENDER_PARTICIPATION_UPDATE_FINISH:
      return {
        ...state,
        tenderParticipationUpdateLoading: false,
      };
    //tenderDocument Add
    case REFERENCE__TYPES.DOCUMENTS_UPLOAD_INIT:
      return {
        ...state,
        documentUploadResp: [],
        documentUploadLoading: true,
      };
    case REFERENCE__TYPES.DOCUMENTS_UPLOAD_SUCCESS:
      return {
        ...state,
        documentUploadResp: action.payload,
        documentUploadLoading: false,
      };
    case REFERENCE__TYPES.DOCUMENTS_UPLOAD_FINISH:
      return {
        ...state,
        documentUploadLoading: false,
      };

    case REFERENCE__TYPES.TENDER_IS_FAV_INIT:
      return {
        ...state,
        tenderIsFavResponse: null,
        tenderIsFavResponseLoading: true,
      };
    case REFERENCE__TYPES.TENDER_IS_FAV_SUCCESS:
      // console.log("action", action);
      return {
        ...state,
        tenderBasicGetDetailsResponse: {
          ...action.record,
          isFavourite: !action.record?.isFavourite,
        },
        tenderBasicPaginationResponse: {
          items: state.tenderBasicPaginationResponse?.items.map((items) => {
            if (items.id === action.payload.tenderId) {
              return {
                ...items,
                isFavorite: !items.isFavorite,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.tenderBasicPaginationResponse?.totalCount,
        },
        tenderIsFavResponseLoading: false,
      };
    case REFERENCE__TYPES.TENDER_IS_FAV_FINISH:
      return {
        ...state,
        tenderIsFavResponseLoading: false,
      };

    //Add Extensions

    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_EXTENSION_INIT:
      return {
        ...state,
        tenderBasicAddExtensionsLoadingResponse: true,
        tenderBasicAddExtensionResponse: null,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_EXTENSION_SUCCESS:
      return {
        ...state,
        tenderBasicAddExtensionsLoadingResponse: false,
        tenderBasicAddExtensionResponse: action.payload,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_EXTENSION_FINISH:
      return {
        ...state,
        tenderBasicAddExtensionsLoadingResponse: false,
        tenderBasicAddExtensionResponse: null,
      };

    //Getdetails Extension
    case REFERENCE__TYPES.TENDER_EXTENSION_GET_DETAILS_INIT:
      return {
        ...state,
        tenderBasicExtensionGetDetailsLoading: true,
        tenderBasicExtensionGetDetailsResponse: null,
      };

    case REFERENCE__TYPES.TENDER_EXTENSION_GEt_DETAILS_SUCCESS:
      return {
        ...state,
        tenderBasicExtensionGetDetailsLoading: false,
        tenderBasicExtensionGetDetailsResponse: action.payload,
      };

    case REFERENCE__TYPES.TENDER_EXTENSION_GET_DETAILS_FINISH:
      return {
        ...state,
        tenderBasicExtensionGetDetailsLoading: false,
        tenderBasicExtensionGetDetailsResponse: null,
      };

    //Update EXTENSION LIST
    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_EXTENSION:
      return {
        ...state,
        tenderBasicUpdateExtensionResponse: null,
        tenderBasicUpdateExtensionsLoadingResponse: true,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_EXTENSION_SUCCESS:
      // console.log("actionpayload", action.payload);
      return {
        ...state,
        tenderBasicUpdateExtensionsLoadingResponse: false,
        tenderBasicUpdateExtensionResponse: action.payload,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_EXTENSION_FINISH:
      return {
        ...state,
        tenderBasicUpdateExtensionsLoadingResponse: false,
        tenderBasicUpdateExtensionResponse: null,
      };

    //Update StageLevel docs
    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE:
      return {
        ...state,
        tenderBasicUpdateStageResponse: null,
        tenderBasicUpdateStageLoadingResponse: true,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_SUCCESS:
      // console.log("whatsuppp", action);
      return {
        ...state,
        tenderBasicUpdateStageLoadingResponse: false,
        tenderBasicUpdateStageResponse: action.payload,
        // tenderBasicGetDetailsResponse: {
        //   ...state.tenderBasicExtensionGetDetailsResponse,
        //   tenderStageLevelDtos:
        // },
        // tenderBasicGetDetailsResponse:
        //   state.tenderBasicGetDetailsResponse?.tenderStageLevelDtos?.map(
        //     (data, i) => {
        //       if (action.payload.tenderStageLevelId === data.id) {
        //         console.log("you are here1");
        //         data.tenderStageDocumentPurchaseAddUpdateDtos.map(
        //           (docDat, i) => {
        //             if (docDat.id === action.record.docPurchaseId) {
        //               console.log("you are here");
        //               return {
        //                 // ...state.tenderBasicGetDetailsResponse,
        //                 ...action.payload,
        //               };
        //             }
        //           }
        //         );
        //       } else {
        //         return{

        //         }
        //         ...data
        //       }
        //     }
        //   ),
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_FINISH:
      return {
        ...state,
        tenderBasicUpdateStageLoadingResponse: false,
        tenderBasicUpdateStageResponse: null,
      };

    //Update stageLevel
    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_LEVEL_INIT:
      return {
        ...state,
        stageLevelUpdate: null,
        stageLevelUpdateLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_LEVEL_SUCCESS:
      // console.log("uodated", action);
      return {
        ...state,
        stageLevelUpdateLoading: false,
        stageLevelUpdate: action.payload,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_LEVEL_FINISH:
      return {
        ...state,
        stageLevelUpdateLoading: false,
        stageLevelUpdate: null,
      };

    //stage add
    case REFERENCE__TYPES.TENDER_STAGE_ADD:
      return {
        ...state,
        stageLevelAddResponse: null,
        stageLevelAddLoading: true,
      };

    case REFERENCE__TYPES.TENDER_STAGE_ADD_SUCCESS:
      return {
        ...state,
        stageLevelAddLoading: false,
        stageLevelAddResponse: action.payload,
      };

    case REFERENCE__TYPES.TENDER_STAGE_ADD_FINISH:
      return {
        ...state,
        stageLevelAddLoading: false,
        stageLevelAddResponse: null,
      };

    //add doc
    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_DOC:
      return {
        ...state,
        stageDocumentResp: null,
        stageDocumentLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_DOC_SUCCESS:
      return {
        ...state,
        stageDocumentLoading: false,
        stageDocumentResp: action.payload,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_DOC_FINISH:
      return {
        ...state,
        stageDocumentLoading: false,
        stageDocumentResp: null,
      };

    case REFERENCE__TYPES.TENDER_PARTICIPATION_ADD_INIT:
      return {
        ...state,
        participationAddResponse: null,
        participationAddLoading: true,
      };

    case REFERENCE__TYPES.TENDER_PARTICIPATION_ADD_SUCCESS:
      return {
        ...state,
        participationAddResponse: action.payload,
        participationAddLoading: false,
        participationPaginationResponse: state?.participationPaginationResponse
          ?.items
          ? {
              items: [
                action.payload,
                ...state.participationPaginationResponse.items,
              ],
              totalCount: state.participationPaginationResponse?.totalCount + 1,
            }
          : {
              items: [action.payload],
              totalCount: 1,
            },
      };

    case REFERENCE__TYPES.TENDER_PARTICIPATION_ADD_FINISH:
      return {
        ...state,
        participationAddResponse: [],
        participationAddLoading: false,
      };

    //update participation
    case REFERENCE__TYPES.TENDER_BASIC_UPDATE_PARTICIPATION_INIT:
      return {
        ...state,
        participationUpdateResponse: null,
        participationUpdateLoading: true,
      };

    case REFERENCE__TYPES.TENDER_BASIC_UPDATE_PARTICIPATION_SUCCESS:
      return {
        ...state,
        participationUpdateResponse: action.payload,
        participationUpdateLoading: false,
        participationPaginationResponse: {
          items: state.participationPaginationResponse.items.map(
            (pagination) => {
              if (pagination.id === action.payload.id) {
                return { ...pagination, ...action.payload };
              } else {
                return { ...pagination };
              }
            }
          ),
          totalCount: state.participationPaginationResponse.totalCount,
        },
      };

    case REFERENCE__TYPES.TENDER_BASIC_UPDATE_PARTICIPATION_FINISH:
      return {
        ...state,
        participationUpdateResponse: null,
        participationUpdateLoading: false,
      };

    //ParticipationpagintionResponse

    case REFERENCE__TYPES.REFERENCE_TYPE_PARTICIPATION_PAGINATION_INIT:
      return {
        ...state,

        participationPaginationLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_PARTICIPATION_PAGINATION_SUCCESS:
      return {
        ...state,
        participationPaginationResponse: action.payload,
        participationPaginationLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_PARTICIPATION_PAGINATION_FINISH:
      return {
        ...state,
        participationPaginationLoading: true,
      };

    //documentPaginatonRespons

    case REFERENCE__TYPES.REFERENCE_TYPE_DOCUMENT_PAGINATION_INIT:
      return {
        ...state,
        documentPaginationLoading: true,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_DOCUMENT_PAGINATION_SUCCESS:
      return {
        ...state,
        documentPaginationResponse: action.payload,
        documentPaginationLoading: false,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_DOCUMENT_PAGINATION_FINISH:
      return {
        ...state,
        documentPaginationLoading: true,
      };

    //stageExtensionAdd

    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_EXTENSION_INIT:
      return {
        ...state,
        tenderStageAddExtensionsLoadingResponse: true,
        tenderStageAddExtensionResponse: null,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_EXTENSION_SUCCESS:
      let data = [action.payload];

      console.log("new data added", data);
      let payload = state?.tenderStageGetDetailsExtensionResponse
        ? state.tenderStageGetDetailsExtensionResponse.concat(data)
        : data;
      const newStageData =
        state?.tenderBasicGetDetailsResponse?.tenderStageLevelDtos?.map(
          (x) => ({
            ...x,
            ...action.payload,
          })
        );
      // const newStageData = {
      //   ...state?.tenderBasicGetDetailsResponse?.tenderStageLevelDtos?.map(
      //     (x) => ({
      //       ...x,
      //       ...action.payload,
      //     })
      //   ),
      // };
      const newtenderBasicGetDetailsResponse = {
        ...state?.tenderBasicGetDetailsResponse,
        tenderStageLevelDtos: newStageData,
      };

      return {
        ...state,
        tenderStageAddExtensionsLoadingResponse: false,
        tenderStageAddExtensionResponse: action.payload,

        tenderStageGetDetailsExtensionResponse: payload,
        tenderBasicGetDetailsResponse: newtenderBasicGetDetailsResponse,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_EXTENSION_FINISH:
      return {
        ...state,
        tenderStageAddExtensionsLoadingResponse: false,
        tenderStageAddExtensionResponse: null,
      };

    //Getdetails stage Extension
    case REFERENCE__TYPES.REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS_INIT:
      return {
        ...state,
        tenderStageGetDetailsExtensionsLoadingResponse: true,
        tenderStageGetDetailsExtensionResponse: null,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS_SUCCESS:
      return {
        ...state,
        tenderStageGetDetailsExtensionsLoadingResponse: false,
        tenderStageGetDetailsExtensionResponse: action.payload,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS_FINISH:
      return {
        ...state,
        tenderStageGetDetailsExtensionsLoadingResponse: false,
        tenderStageGetDetailsExtensionResponse: null,
      };
    //Tender Counter
    case REFERENCE__TYPES.TENDER_COUNTER_INIT:
      return {
        ...state,
        tenderCounterListLoading: true,
      };
    case REFERENCE__TYPES.TENDER_COUNTER_SUCCESS:
      return {
        ...state,
        tenderCounterListResponse: action.payload,
        tenderCounterListLoading: false,
      };
    case REFERENCE__TYPES.TENDER_COUNTER_FINSIH:
      return {
        ...state,
        tenderCounterListResponse: null,
        tenderCounterListLoading: false,
      };

    //Update stage EXTENSION LIST
    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_EXTENSION_INIT:
      return {
        ...state,
        tenderStageUpdateExtensionResponse: null,
        tenderStageUpdateExtensionsLoadingResponse: true,
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_EXTENSION_SUCCESS:
      return {
        ...state,
        tenderStageUpdateExtensionsLoadingResponse: false,
        tenderStageUpdateExtensionResponse: action.payload,
        tenderStageGetDetailsExtensionResponse:
          state.tenderStageGetDetailsExtensionResponse?.map((items) => {
            if (items.id === action.payload.id) {
              return {
                ...action.payload,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
      };

    case REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_EXTENSION_FINISH:
      return {
        ...state,
        tenderStageUpdateExtensionsLoadingResponse: false,
        tenderStageUpdateExtensionResponse: null,
      };

    case REFERENCE__TYPES.DATE_FILTERING_TENDER_INIT:
      return {
        ...state,
        dateFilteringResponse: [],
        dateFilteringResponseLoading: true,
      };

    case REFERENCE__TYPES.DATE_FILTERING_TENDER_SUCCESS:
      return {
        ...state,
        dateFilteringResponse: action.payload,
        dateFilteringResponseLoading: false,
      };
    case REFERENCE__TYPES.ALL_TENDER_GET_DETAILS_INIT:
      return {
        ...state,
        allTenderGetDetailsResponse: [],
        allTenderGetDetailsLoading: true,
      };
    case REFERENCE__TYPES.ALL_TENDER_GET_DETAILS_SUCCESS:
      return {
        ...state,
        allTenderGetDetailsResponse: action.payload,
        allTenderGetDetailsLoading: false,
      };

    // CIRCULAR PLANNING TENDER CHECKLIST
    case REFERENCE__TYPES.GET_TENDER_CHECKLIST_INIT:
      return {
        ...state,
        tenderCircularPlanningCheckListResponse: [],
        tenderCircularPlanningCheckListLoading: true,
      };

    case REFERENCE__TYPES.GET_TENDER_CHECKLIST_SUCCESS:
      return {
        ...state,
        tenderCircularPlanningCheckListLoading: false,
        tenderCircularPlanningCheckListResponse: action.payload,
      };

    case REFERENCE__TYPES.GET_TENDER_CHECKLIST_FINISH:
      return {
        ...state,
        tenderCircularPlanningCheckListLoading: false,
      };

    case REFERENCE__TYPES.ADDUPDATE_CIRCULAR_CHEKLIST_INIT:
      return {
        ...state,
        addUpdateCircularCheckListResponse: [],
        addUpdateCircularCheckListLoading: true,
      };

    case REFERENCE__TYPES.ADDUPDATE_CIRCULAR_CHEKLIST_SUCCESS:
      return {
        ...state,
        addUpdateCircularCheckListResponse: action.payload,
        addUpdateCircularCheckListLoading: false,
        tenderCircularPlanningCheckListResponse:
          state?.tenderCircularPlanningCheckListResponse?.map((item) => {
            if (item.id === action.payload.checklistid) {
              return {
                ...item,
                isactive: action.payload?.isactive,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
      };

    case REFERENCE__TYPES.ADDUPDATE_CIRCULAR_CHEKLIST_FINISH:
      return {
        ...state,
        addUpdateCircularCheckListLoading: false,
      };

    // Circular Content Template
    case REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE_INIT:
      return {
        ...state,
        singleCircularReferenceTemplateResponse: [],
        singleCircularReferenceTemplateLoading: true,
      };

    case REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE_SUCCESS:
      return {
        ...state,
        singleCircularReferenceTemplateResponse: action.payload,
        singleCircularReferenceTemplateLoading: false,
      };

    // update in mail
    case REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE_UPDATE:
      let updatedTemplateMail =
        state?.singleCircularReferenceTemplateResponse &&
        (state.singleCircularReferenceTemplateResponse.id === action.payload.id
          ? { ...action.payload }
          : state.singleCircularReferenceTemplateResponse);

      return {
        ...state,
        singleCircularReferenceTemplateLoading: false,
        singleCircularReferenceTemplateResponse: updatedTemplateMail,
      };

    case REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE_FINISH:
      return {
        ...state,
        singleCircularReferenceTemplateLoading: false,
      };

    // add update
    case REFERENCE__TYPES.ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE_INIT:
      return {
        ...state,
        addUpdateCircularReferenceTemplateResponse: [],
        addUpdateCircularReferenceTemplateLoading: true,
      };

    case REFERENCE__TYPES.ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE_SUCCESS:
      return {
        ...state,
        addUpdateCircularReferenceTemplateResponse: action.payload,
        addUpdateCircularReferenceTemplateLoading: false,
      };

    case REFERENCE__TYPES.ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE_FINISH:
      return {
        ...state,
        addUpdateCircularReferenceTemplateLoading: false,
      };

    // POST ISAPPROVED
    case REFERENCE__TYPES.ADD_ISAPPROVED_STATUS_INIT:
      return {
        ...state,
        isApproveTenderMailResp: [],
        isApproveTenderMailLoading: true,
      };

    case REFERENCE__TYPES.ADD_ISAPPROVED_STATUS_SUCCESS:
      return {
        ...state,
        isApproveTenderMailResp: action.payload,
        isApproveTenderMailLoading: false,
      };

    case REFERENCE__TYPES.ADD_ISAPPROVED_STATUS_FINISH:
      return {
        ...state,
        isApproveTenderMailLoading: false,
      };

    // Get Approved Status of Circular Planning
    case REFERENCE__TYPES.GET_APPROVED_STATUS_CIRCULARPLANNING_INIT:
      return {
        ...state,
        getApprovedStatusCircularPlanningResp: [],
        getApprovedStatusCircularPlanningLoading: true,
      };

    case REFERENCE__TYPES.GET_APPROVED_STATUS_CIRCULARPLANNING_SUCCESS:
      return {
        ...state,
        getApprovedStatusCircularPlanningResp: action.payload,
        getApprovedStatusCircularPlanningLoading: false,
      };

    case REFERENCE__TYPES.GET_APPROVED_STATUS_CIRCULARPLANNING_FINISH:
      return {
        ...state,
        getApprovedStatusCircularPlanningLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
