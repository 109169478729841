import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from 'antd';
import { validateMessages } from 'utils/misc';

import { sourceAdd, sourceUpdate } from 'services/redux/source/action';
import { isEmpty } from 'lodash';

const { Header, Footer } = Layout;
const { Option } = Select;

export const SourceAddUpdateDrawer = ({ onCloseSourceDrawer, drawerVisibility, isUpdateSector }) => {
const {  
    sourceAddResponse,
    sourceAddResponseLoading,
    sourceUpdateResponse,
    sourceUpdateResponseLoading
} = useSelector((state) => state.source);
const [form] = Form.useForm();
form.resetFields();
const dispatch = useDispatch();

useEffect(() => {
    if(!isEmpty(sourceAddResponse)) {
        setTimeout(() => {
            onCloseSourceDrawer();
            form.resetFields();
        }, 500);
    }
},[sourceAddResponse]);

const onSubmit = async () => {
    try{
        let values = await form.validateFields();
        dispatch(sourceAdd(values));
    }catch(errorInfo) {
        console.error("failed", errorInfo); 
    }
};


	return (
        <Drawer visible={drawerVisibility} width={472} maskClosable={false} className="drawer" onClose={onCloseSourceDrawer} closable={false}>
        <Header className="drawer__header">
            <h4 className="drawer__header--title">{`${isUpdateSector ? 'Update' : 'Create'} Source Name`}</h4>
        </Header>

        <Form
        form={form} 
        layout="vertical"  
        name="control-hooks"
        validateMessages={validateMessages}
        className="drawer-form-wrapper">
            <div className="drawer-form-row">
                <Form.Item className="form__group" name="name" label="Source Name" rules={[{ required: true }]}>
                    <Input autoComplete='off' />
                </Form.Item>
            </div>
            <div className="model-footer">
                <Button onClick={onCloseSourceDrawer}>Cancel</Button>
                <Button onClick={onSubmit} type="primary">
                    Submit
                </Button>
            </div>
        </Form>
    </Drawer>
	);
};

