import { SETTINGS_TYPES, TODO_TYPES } from "../../../types";

const INITIAL_STATE ={
    zoomData: false,

}

export default function settingsreducer(state = INITIAL_STATE , action){
    switch(action.type){
        case SETTINGS_TYPES.SETTINGS_EMAIL_ZOOM_SHOW_SUCCESS:
            return {
              ...state,
              zoomData:action.payload,
            }
        default:
            return {...state};
    }
}