import React, { useEffect, useState } from "react";

// antd
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getFiscalYearListingWithStatus } from "services/redux/admin/FiscalYear/action";

// includes
import LeaveFiscalDayTable from "./LeaveFiscalDayTable";
import LeaveFiscalDayAddUpdateDrawer from "./LeaveFiscalDayAddUpdateDrawer";

const LeaveFiscalDay = () => {
  const dispatch = useDispatch();
  const { fiscalYearListingWithStatus } = useSelector((state) => state.fiscalYearState);

  const [addUpdateLeaveFiscalDayVisibility, setAddUpdateLeaveFiscalDayVisibility] = useState(false);
  const [isUpdateLeaveFiscalDay, setIsUpdateLeaveFiscalDay] = useState(false);

  useEffect(() => {
    dispatch(getFiscalYearListingWithStatus());
  }, []);

  const showAddUpdateLeaveFiscalDay = (record) => {
    if (record) {
      setIsUpdateLeaveFiscalDay(record);
    } else {
      setIsUpdateLeaveFiscalDay(false);
    }
    setAddUpdateLeaveFiscalDayVisibility(true);
  };

  const onAddUpdateLeaveFiscalDayClose = () => {
    setIsUpdateLeaveFiscalDay('');
    setAddUpdateLeaveFiscalDayVisibility(false);
  }; 

  return (
    <div className='layout'>
      <div className="organizer events">
         <div className="all-header-wrapper">
              <div className="meeting__flex">
                  <h3 className="header__all">Leave Fiscal Days</h3>
                  <div className="organizerHoliday__middle--wrap">
                    <Button
                      className='btn-primary'
                      onClick={() => {
                        showAddUpdateLeaveFiscalDay();
                      }}
                      >
                      <PlusOutlined /> New Leave Fiscal Day
                    </Button>
                  </div>
              </div>
          </div>
        <LeaveFiscalDayTable
          showAddUpdateLeaveFiscalDay={showAddUpdateLeaveFiscalDay}
        />
        <LeaveFiscalDayAddUpdateDrawer
          drawerVisibility={addUpdateLeaveFiscalDayVisibility}
          onAddUpdateLeaveFiscalDayClose={onAddUpdateLeaveFiscalDayClose}
          isUpdateLeaveFiscalDay={isUpdateLeaveFiscalDay}
          fiscalYearListingWithStatus={fiscalYearListingWithStatus}
        />
      </div>
    </div>
  );
};

export default LeaveFiscalDay;
