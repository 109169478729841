import { appBaseUrl } from "utils/config";
import {
  getService,
  putService,
  postService,
  patchService,
} from "services/commonServices";
import queryString from "query-string";

export class DailyDairyService {
  // pagination
  apiForListingPaginationDailyDairy(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizerEvent/dailyDiaryPagination?${query}`;
    let data = getService(url);
    return data;
  }

  // transfer
  apiForTransferToDailyDairy(id, query) {
    let queryStringified = queryString.stringify(query);
    let url = `${appBaseUrl}/organizerEvent/dailyDiaryAdd/${id}?${queryStringified}`;
    let data = postService(url);
    return data;
    // const queryStringified = queryString.stringify(query);
    // const url = queryStringified
    //     ? `${appBaseUrl}/organizerEvent/dailyDiaryAdd/${id}?${queryStringified}`
    //     : `${appBaseUrl}/organizerEvent/dailyDiaryAdd/${id}`;
    // return postService(url);
  }

  // updated daily dairy status
  apiForDailyDiaryUpdate(id, body) {
    let url = `${appBaseUrl}/organizerEvent/dailyDiaryUpdate/${id}`;
    let data = putService(url, body);
    return data;
  }

  //
  apiForAddDailyDairy(body) {
    let url = `${appBaseUrl}/add`;
    let data = postService(url, data);
    return data;
  }

  // complete daily dairy
  apiForDailyDiaryComplete(id) {
    let url = `${appBaseUrl}/organizerEvent/dailyDairyMakeComplete/${id}`;
    let data = patchService(url);
    return data;
  }

  // saveall
  apiForSaveDailyDairy(body) {
    let url = `${appBaseUrl}/organizerEvent/dailyDiarySave`;
    let data = patchService(url, body);
    return data;
  }

  // movetodairyremakrs
  apiForMovetoDairyRemarks(body) {
    let url = `${appBaseUrl}/organizerEvent/dailyDiaryTransfer`;
    let data = postService(url, body);
    return data;
  }

  // deleteapi
  apiForDeleteDairy(id) {
    let url = `${appBaseUrl}/organizerEvent/dailyDiaryDelete/${id}`;
    let data = putService(url);
    return data;
  }

  // excel
  apiForExportDairyToExcel(q) {
    let query = queryString.stringify(q);
    // let url = `${appBaseUrl}/organizerEvent/exportSisterCompanyListToExcel?${query}`;
    // let data = postService(url);
    let url = `${appBaseUrl}/organizerEvent/exportDailyDiaryListToExcel?${query}`;
    let data = getService(url);
    return data;
  }
}
