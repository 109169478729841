import {
  EditOutlined,
  MailOutlined,
  GlobalOutlined,
  DeleteOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Radio,
  Popconfirm,
  Spin,
  Skeleton,
  Checkbox,
} from "antd";
import CommunicatonChannel from "components/communicationChannel";
import { debounce, isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {
  actionForReferencePartyListingCircular,
  actionForReferenceCircularPlanningAdd,
  actionForReferenceCircularPlanningUpdate,
  actionForUpdateCircularPlanningCompany,
  actionForAddCompanyOrContactPersonEmailAddById,
} from "services/redux/Reference/action";
import { getCompanyorPersonEmailAddess } from "services/redux/contact/contact.action";
import {
  contactCompanyGetDetails,
  contactPersonGetDetails,
  getContactCompanyPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";

import IconButtonWithTooltip from "./IconButtonWithTooltip";

function EditCompanyModel(props) {
  const {
    emailReferenceId,
    isCircularEditVisible,
    setIsCircularEditVisible,
    record,
    form,
    selectedId,
    selectedCompanyId,
    setSelectedCompanyId,
    valueRadioAddCompany,
    setValueRadioAddCompany,
    selectedAttendees,
    setSelectedAttendees,
    selectedMenuItem,
    selectedCompanyName,
  } = props;

  const dispatch = useDispatch();
  const [formQuickAddPerson] = Form.useForm();
  const [formAddCompanyEmail] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const { Option } = Select;

  const {
    contactQuickAddEmailRespsonse,
    contactPersonGetDetailsResponse,
    contactCompanyGetDetailsResponse,
    allPersonListingResponse,
    contactPersonSearchListingResponse,
    contactCompanyPersonSearchPaginationLoading,
    contactQuickAddEmailLoading,
    contactCompanyPaginationResponse,
    contactCompanyPaginationLoading,
    contactPersonPaginationResponse,
    contactPersonPaginationLoading,
    quickContactCompanyAdd,
    quickContactCompanyLoader,
    quickPersonAddResponse,
  } = useSelector((state) => state.contactCompanyPerson);
  const { contactCompanyPersonMailResponse, contactCompanyPersonMailLoading } =
    useSelector((state) => state.contact);

  const [searchKeywordContactPerson, setSearchKeywordContactPerson] =
    useState("");
  const [isPersonModalVisible, setIsPersonModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isAddCompanyEmailModalOpen, setIsAddCompanyEmailModalOpen] =
    useState(false);
  const [isAddCompanyEmailModalLoading, setIsAddCompanyEmailModalLoading] =
    useState(false);

  const handleAddCompanyEmailModalClose = () => {
    formAddCompanyEmail.resetFields();
    setIsAddCompanyEmailModalOpen(false);
  };

  useEffect(() => {
    if (contactPersonPaginationResponse) {
      const newData = (contactPersonPaginationResponse?.items || []).map(
        (x) => ({
          label: x.personFullName,
          value: x.contactPersonId,
        })
      );

      setData(newData);
    }
  }, [contactPersonPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(quickPersonAddResponse)) {
      const newPersonData = {
        label:
          quickPersonAddResponse.firstName + quickPersonAddResponse.lastName,
        value: quickPersonAddResponse.id,
      };

      // Checking if newPersonData already exists in data
      const isAlreadyAdded = data.some(
        (item) => item.value === newPersonData.value
      );

      if (!isAlreadyAdded) setData((prevData) => [...prevData, newPersonData]);
    }
  }, [quickPersonAddResponse]);

  const onChangeAddCompany = (value) => {
    setValueRadioAddCompany(value);
  };

  const debounceAttentiontoDispatch = useCallback(
    debounce((selectedPersonIds, selectedCompanyId) => {
      dispatch(
        getCompanyorPersonEmailAddess(selectedPersonIds, selectedCompanyId)
      );
    }, 300),
    [dispatch]
  );

  const handleSelectChange = (values, options) => {
    const selectedData = options.map((option) => ({
      AttentionTo: option.props.children,
      AttentionId: option.key,
    }));

    setSelectedAttendees(selectedData);

    const selectedPersonIds =
      selectedData &&
      selectedData?.map((item) => ({
        contactPersonIds: item?.AttentionId,
      }));

    if (selectedPersonIds.length > 0 && selectedCompanyId) {
      debounceAttentiontoDispatch(selectedPersonIds, selectedCompanyId);
    }
  };

  // helper functions
  const renderRadioWithRemarks = (value, label) => (
    <Radio key={value} value={value}>
      {label}
    </Radio>
  );

  const mapNumberToModeOfCommunication = (number) => {
    const num = Number(number);
    switch (num) {
      case 1:
        return "Email";
      case 2:
        return "Facebook";
      case 3:
        return "Whatsapp";
      case 4:
        return "Twitter";
      case 5:
        return "Other";
      default:
        return "Other";
    }
  };

  const onFinishCircularEdit = (values) => {
    let mocMaptoNum = mapNumberToModeOfCommunication(valueRadioAddCompany);

    let bodyPayload = {
      id: selectedId,
      companyId: selectedCompanyId,
      circularPlanningTemplateId: selectedMenuItem,
      remarks: form.getFieldValue().remarks,
      ModeofCommunication: mocMaptoNum,
      attentionTo: JSON.stringify(selectedAttendees),
      isactive: true,
      emailTo: JSON.stringify(values?.mailTo),
      Priority: values?.priority || "",
    };

    try {
      dispatch(actionForUpdateCircularPlanningCompany(bodyPayload));
    } catch (err) {
      console.log(err);
    } finally {
      setIsCircularEditVisible(false);
      form.resetFields();
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const onFinishAddCompanyEmail = async (values) => {
    let body = {
      companyId: selectedCompanyId,
      email: values?.newEmail ?? "",
    };

    setIsAddCompanyEmailModalLoading(true);

    try {
      if (body?.companyId) {
        await dispatch(actionForAddCompanyOrContactPersonEmailAddById(body));
        handleAddCompanyEmailModalClose();
        if (selectedCompanyId) {
          dispatch(getCompanyorPersonEmailAddess(null, selectedCompanyId));
        }
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setIsAddCompanyEmailModalLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="Edit Circular Planning Template"
        visible={isCircularEditVisible}
        onCancel={() => {
          setIsCircularEditVisible(false);
          setValueRadioAddCompany(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          {...formItemLayout}
          labelAlign="left"
          form={form}
          onFinish={onFinishCircularEdit}
        >
          <Form.Item label="Company Name">
            {/* {form?.getFieldValue("CompanyName")} */}
            {selectedCompanyName}
            <IconButtonWithTooltip
              tooltipText="Add Company Email"
              onClick={() => setIsAddCompanyEmailModalOpen(true)}
            >
              +
            </IconButtonWithTooltip>
          </Form.Item>
          <Form.Item label="Moc">
            <Select
              value={valueRadioAddCompany}
              onChange={onChangeAddCompany}
              defaultValue="1"
            >
              <Option value="1">Email</Option>
              <Option value="2">Facebook</Option>
              <Option value="3">Whatsapp</Option>
              <Option value="4">Twitter</Option>
              <Option value="5">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Priority" name="priority">
            <Select
              placeholder="Select your priority"
              options={[
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "2",
                },
                {
                  value: "3",
                  label: "3",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="Attention to" name="attendees">
            <Select
              showSearch
              mode="multiple"
              autoComplete="off"
              optionFilterProp="label"
              placeholder="Select Person"
              allowClear
              onSearch={(value) => {
                setSearchKeywordContactPerson(value);
              }}
              onChange={handleSelectChange}
              notFoundContent={
                <>
                  {contactPersonPaginationLoading ? (
                    <Skeleton />
                  ) : (
                    <div>
                      Content Not Found. Do you want to Person?
                      <Button
                        type="primary"
                        onClick={() => {
                          setIsPersonModalVisible(true);
                          // Company Details
                          // dispatch(
                          //   contactCompanyGetDetails(selectedCompanyId)
                          // );

                          const namesArray =
                            searchKeywordContactPerson.split(" ");

                          formQuickAddPerson.setFieldsValue({
                            firstName: namesArray[0],
                            lastName: namesArray.slice(1).join(" ") || "",
                          });
                        }}
                      >
                        Yes
                      </Button>
                    </div>
                  )}
                </>
              }
            >
              {data.map((option) => (
                <Option
                  label={option.label}
                  key={option.value}
                  value={option.label}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {valueRadioAddCompany === "1" && (
            <Form.Item label="Mail to" name="mailTo" wrapperCol={{ span: 18 }}>
              <Select
                mode="multiple"
                placeholder="Select Contact Person"
                style={{ width: "100%" }}
                optionFilterProp="children"
              >
                {contactCompanyPersonMailResponse.map((contact) => (
                  <Option
                    key={`${contact.name}-${contact.email}`}
                    value={contact.email}
                  >
                    {contact.email} ({contact.name})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="Remarks" name="remarks">
            <Input.TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
            <Button
              style={{
                marginTop: "12px ",
                float: "right",
              }}
              type="primary"
              htmlType="submit"
              // loading={referencePartyHistoryAddLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Add Compay Email"
        visible={isAddCompanyEmailModalOpen}
        footer={null}
        onCancel={handleAddCompanyEmailModalClose}
      >
        <Form form={formAddCompanyEmail} onFinish={onFinishAddCompanyEmail}>
          <Form.Item
            label="Email Addresss"
            name="newEmail"
            rules={[
              { required: true, message: "Please enter the company email!" },
              {
                pattern: emailRegex,
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input placeholder="Enter company email" />
          </Form.Item>
          <Form.Item>
            <Button
              className="company-add-save-btn"
              type="primary"
              htmlType="submit"
              shape="round"
              loading={isAddCompanyEmailModalLoading}
            >
              Add Email
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default EditCompanyModel;
