import { actions } from "react-table";
import { APPLICATION_TEMPLATE } from "services/types";

const INITIAL_STATE = {
    addApplicationTemplateResponse : [],
    addApplicationTemplateResponseLoading:false,
}

export default function applicationReducer (state = INITIAL_STATE, action){
    switch(action.type){
        case APPLICATION_TEMPLATE.ADD_APPLICATION_TEMPLATE_INIT:
            return {
                ...state,
                addApplicationTemplateResponse : [],
                addApplicationTemplateResponseLoading : true,
            }

        case APPLICATION_TEMPLATE.ADD_APPLICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                addApplicationTemplateResponse : action.payload,
                addApplicationTemplateResponseLoading : false,
            }

        case APPLICATION_TEMPLATE.GET_APPLICATION_TEMPLATE_INIT:
            return {
                ...state,
                getApplicationTemplateResponse :[],
                getApplicationTemplateResponseLoading : true,
            }
        
        case APPLICATION_TEMPLATE.GET_APPLICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                getApplicationTemplateResponse :action.payload,
                getApplicationTemplateResponseLoading : false,
            }

        case APPLICATION_TEMPLATE.UPDATE_APPLICATION_TEMPLATE_INIT:
            return{
                ...state,
                updateApplicationTemplateResponse: [],
                updateApplicationTemplateResponseLoading : true,
            }

        case APPLICATION_TEMPLATE.UPDATE_APPLICATION_TEMPLATE_SUCCESS:
            return{
                ...state,
                updateApplicationTemplateResponse: action.payload,
                updateApplicationTemplateResponseLoading : false,
            }

        default:
        return { ...state };
    }
}