import { ORGANIZER_TYPES } from "../../types";

const INITIAL_STATE = {
  allFiscalYears: [],
  fiscalYearsLoading: false,

  holidayEvents: [],
  holidayEventsLoading: false,
  addHolidayEventsLoading: false,
  addUpdateBulkHolidayResp: false,
  organizerEventLabelsPaginationResp: [],
  organizerEventPriorityPaginationResp: [],
};

export default function organizerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // fiscal years
    case ORGANIZER_TYPES.FISCAL_YEARS_INIT:
      return {
        ...state,
        allFiscalYears: [],
        fiscalYearsLoading: true,
      };
    case ORGANIZER_TYPES.FISCAL_YEARS_SUCCESS:
      return {
        ...state,
        allFiscalYears: action.payload,
        fiscalYearsLoading: false,
      };
    case ORGANIZER_TYPES.FISCAL_YEARS_FINISH:
      return {
        ...state,
        fiscalYearsLoading: false,
      };

    // holiday events
    case ORGANIZER_TYPES.HOLIDAY_EVENTS_INIT:
      return {
        ...state,
        holidayEvents: [],
        holidayEventsLoading: true,
      };
    case ORGANIZER_TYPES.HOLIDAY_EVENTS_SUCCESS:
      return {
        ...state,
        holidayEvents: action.payload,
        holidayEventsLoading: false,
      };
    case ORGANIZER_TYPES.HOLIDAY_EVENTS_FINISH:
      return {
        ...state,
        holidayEventsLoading: false,
      };

    // holiday events
    case ORGANIZER_TYPES.BULK_HOLIDAY_EVENTS_INIT:
      return {
        ...state,
        addHolidayEventsLoading: true,
      };
    case ORGANIZER_TYPES.BULK_HOLIDAY_EVENTS_SUCCESS:
      return {
        ...state,
        addUpdateBulkHolidayResp: action.payload,
        addHolidayEventsLoading: false,
      };
    case ORGANIZER_TYPES.BULK_HOLIDAY_EVENTS_FINISH:
      return {
        ...state,
        addUpdateBulkHolidayResp: [],
        addHolidayEventsLoading: false,
      };

    // label pagination events
    case ORGANIZER_TYPES.LABELS_EVENTS_INIT:
      return {
        ...state,
        organizerEventLabelsPaginationResp: [],
        organizerEventLabelsPaginationLoading: true,
      };
    case ORGANIZER_TYPES.LABELS_EVENTS_SUCCESS:
      return {
        ...state,
        organizerEventLabelsPaginationResp: action.payload,
        organizerEventLabelsPaginationLoading: false,
      };
    case ORGANIZER_TYPES.LABELS_EVENTS_FINISH:
      return {
        ...state,
        organizerEventLabelsPaginationResp: [],
        organizerEventLabelsPaginationLoading: false,
      };

    case ORGANIZER_TYPES.organizerEventLabelAdd_INIT:
      return {
        ...state,
        organizerEventLabelAddResp: [],
        organizerEventLabelAddLoading: true,
      };
    case ORGANIZER_TYPES.organizerEventLabelAdd_SUCCESS:
      return {
        ...state,
        organizerEventLabelAddResp: action.payload,
        organizerEventLabelAddLoading: false,
      };
    case ORGANIZER_TYPES.organizerEventLabelAdd_FINISH:
      return {
        ...state,
        organizerEventLabelAddResp: [],
        organizerEventLabelAddLoading: false,
      };

    case ORGANIZER_TYPES.organizerEventLabelUpdate_INIT:
      return {
        ...state,
        organizerEventLabelUpdateResp: [],
        organizerEventLabelUpdateLoading: true,
      };
    case ORGANIZER_TYPES.organizerEventLabelUpdate_SUCCESS:
      return {
        ...state,
        organizerEventLabelUpdateResp: action.payload,
        organizerEventLabelUpdateLoading: false,
      };
    case ORGANIZER_TYPES.organizerEventLabelUpdate_FINISH:
      return {
        ...state,
        organizerEventLabelUpdateResp: [],
        organizerEventLabelUpdateLoading: false,
      };

    case ORGANIZER_TYPES.organizerEventPriorityPagination_INIT:
      return {
        ...state,
        organizerEventPriorityPaginationResp: [],
        organizerEventPriorityPaginationLoading: true,
      };
    case ORGANIZER_TYPES.organizerEventPriorityPagination_SUCCESS:
      return {
        ...state,
        organizerEventPriorityPaginationResp: action.payload,
        organizerEventPriorityPaginationLoading: false,
      };
    case ORGANIZER_TYPES.organizerEventPriorityPagination_FINISH:
      return {
        ...state,
        organizerEventPriorityPaginationResp: [],
        organizerEventPriorityPaginationLoading: false,
      };

    case ORGANIZER_TYPES.organizerEventPriorityAdd_INIT:
      return {
        ...state,
        organizerEventPriorityAddResp: [],
        organizerEventPriorityAddLoading: true,
      };
    case ORGANIZER_TYPES.organizerEventPriorityAdd_SUCCESS:
      return {
        ...state,
        organizerEventPriorityAddResp: action.payload,
        organizerEventPriorityAddLoading: false,
      };
    case ORGANIZER_TYPES.organizerEventPriorityAdd_FINISH:
      return {
        ...state,
        organizerEventPriorityAddResp: [],
        organizerEventPriorityAddLoading: false,
      };

    case ORGANIZER_TYPES.organizerEventPriorityUpdate_INIT:
      return {
        ...state,
        organizerEventPriorityUpdateResp: [],
        organizerEventPriorityUpdateLoading: true,
      };
    case ORGANIZER_TYPES.organizerEventPriorityUpdate_SUCCESS:
      return {
        ...state,
        organizerEventPriorityUpdateResp: action.payload,
        organizerEventPriorityUpdateLoading: false,
      };
    case ORGANIZER_TYPES.organizerEventPriorityUpdate_FINISH:
      return {
        ...state,
        organizerEventPriorityUpdateResp: [],
        organizerEventPriorityUpdateLoading: false,
      };

    default:
      return { ...state };
  }
}
