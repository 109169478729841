import { DOCUMENT_SINGLE_GET_TYPES, GLOBAL } from "services/types";

const INITIAL_STATE = {
  getDocumentSingleDetailsResponse: [],
  getDocumentSingleDetailsLoading: false,
};

export default function documentSectorReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DOCUMENT_SINGLE_GET_TYPES.DOCUMENT_SINGLE_GET_INIT:
      return {
        ...state,
        getDocumentSingleDetailsResponse: null,
        getDocumentSingleDetailsLoading: true,
      };

    case DOCUMENT_SINGLE_GET_TYPES.DOCUMENT_SINGLE_GET_SUCCESS:
      return {
        ...state,
        getDocumentSingleDetailsResponse: action.payload,
        getDocumentSingleDetailsLoading: false,
      };
    case DOCUMENT_SINGLE_GET_TYPES.DOCUMENT_SINGLE_GET_FINISH:
      return {
        ...state,
        getDocumentSingleDetailsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
