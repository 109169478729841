import { Form, Modal, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { showNumber } from "utils/showNumber";
import InternalMCompose from "components/internalMNew";
import { WechatOutlined } from "@ant-design/icons";
import { handleSpecialDate } from "utils/tooltipSepecialDate";

const FeeRemaining = ({
  dashboardFinancialResponse,
  dashboardFinancialResponseLoading,
}) => {
  const [form] = Form.useForm();

  const [widthh, setWidthh] = useState(window.innerWidth);

  const [internalVisible, setInternalVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidthh(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: (
          <Typography.Paragraph style={{ textAlign: "left", width: "100%" }}>
            NC/FC Bal
          </Typography.Paragraph>
        ),
        dataIndex: "accountCategory",
        key: "accountCategory",
        width: 10,
        ellipsis: false,
        //  width: widthh < 1550 ? 80 : 150,
        render: (_, record) => {
          return (
            <Typography.Paragraph
              style={{
                textAlign: "left",
                fontSize: widthh < 1550 ? 13 : 15,
                width: "100%",
                fontWeight:
                  record?.companyName === "Total" ? "bold" : "inherit",
              }}
            >
              {record?.companyName}
            </Typography.Paragraph>
          );
        },
      },
      {
        title: (
          <Typography.Paragraph
            style={{
              textAlign: "right",
              width: "100%",
            }}
          >
            {" "}
            NC{" "}
          </Typography.Paragraph>
        ),
        dataIndex: "nc",
        key: "nc",
        render: (_, record) => {
          return (
            <Tooltip
              // title={moment(record?.lastUpdatedDate).format("YYYY MMM DD")}
              title={handleSpecialDate(record?.lastUpdatedDate)}
            >
              <Typography.Paragraph
                style={{
                  textAlign: "right",
                  width: "100%",
                  fontSize: widthh < 1550 ? 13 : 15,
                  fontWeight:
                    record?.companyName === "Total" ? "bold" : "inherit",
                }}
              >
                {showNumber(record?.ncValue)}
              </Typography.Paragraph>
            </Tooltip>
          );
        },
      },
      {
        title: (
          <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
            FC
          </Typography.Paragraph>
        ),
        dataIndex: "fc",
        key: "fc",
        render: (_, record) => {
          return (
            <Tooltip
              // title={moment(record?.lastUpdatedDate).format("YYYY MMM DD")}
              title={handleSpecialDate(record?.lastUpdatedDate)}
            >
              <Typography.Paragraph
                style={{
                  textAlign: "right",
                  width: "100%",
                  fontSize: widthh < 1550 ? 13 : 15,
                  fontWeight:
                    record?.companyName === "Total" ? "bold" : "inherit",
                }}
              >
                {showNumber(record?.fcValue)}
              </Typography.Paragraph>
            </Tooltip>
          );
        },
      },
      {
        title: (
          <Typography.Paragraph style={{ textAlign: "right", width: "100%" }}>
            Total
          </Typography.Paragraph>
        ),
        dataIndex: "total",
        key: "total",
        render: (_, record) => {
          return (
            <Typography.Paragraph
              style={{
                textAlign: "right",
                width: "100%",
                fontSize: widthh < 1550 ? 13 : 15,
                fontWeight:
                  record?.companyName === "Total" ? "bold" : "inherit",
              }}
            >
              {showNumber(record?.total)}
            </Typography.Paragraph>
          );
        },
      },
    ],
    []
  );

  const filteredDataSource = dashboardFinancialResponse?.ncfcInformation;

  const dataSource = filteredDataSource;
  return (
    <div className=" honor-wrapper" style={{ paddingRight: 0 }}>
      <div
        className=" dashboards__events dashboards__body "
        style={{ height: 275 }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">NC/FC Bank Status</h4>
          <WechatOutlined
            onClick={() => {
              setInternalVisible(true);
            }}
          />
        </div>

        <Table
          className="next-organizer-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          ellipsis={{ rows: 2, expandable: true }}
          style={{ maxHeight: 180, overflow: "auto" }}
        />
      </div>
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="NC/FC Bank Status"
        />
      </Modal>
    </div>
  );
};

export default FeeRemaining;
