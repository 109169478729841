import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  message,
  Select,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { getAllUsers } from "services/remanentCalls";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  emailSharedAdd,
  getAllSharedDetails,
} from "services/redux/email/mailMessageCenter/action";

import { isEmpty } from "lodash";

const EmailShare = () => {
  const [form1] = Form.useForm();

  const { Option } = Select;
  const dispatch = useDispatch();
  const [messageParticipants, setMessageParticipants] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [widthh, setWidthh] = useState(window.innerWidth);
  const [removeItem, setRemoveItem] = useState([]);
  const [newFilteredData, setNewFilteredData] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState([]);
  const [dates, setDates] = useState([]);

  const { getAllSharedListResponse, getAllSharedListResponseLoading } =
    useSelector((state) => state.mailMessageCenter);

  const { sharedMailAddResponseoading } = useSelector(
    (state) => state.mailMessageCenter
  );

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setMessageParticipants(resp.data);
    }
  };

  useEffect(() => {
    const handleResize = () => setWidthh(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    fetchAllParticipants();
    dispatch(getAllSharedDetails());
  }, []);

  useEffect(() => {
    if (!isEmpty(getAllSharedListResponse)) {
      const intialSelectedOptions = getAllSharedListResponse?.items?.map(
        (x) => {
          return x.employeeId;
        }
      );
      setSelectedOptions(intialSelectedOptions);
      setSelectedIndex(intialSelectedOptions);

      form1.setFieldsValue({
        items1: getAllSharedListResponse?.items?.map((item, index) => {
          return {
            employeeId: item.employeeId,
            expiryDateTime: item.expiryDateTime
              ? moment(item.expiryDateTime)
              : moment(new Date(), "YYYY-MM-DD"),
            isInboxAccess: item.isInboxAccess,
            isSentAccess: item.isSentAccess,
          };
        }),
      });
    }
  }, [getAllSharedListResponse, form1]);

  const handleDateChange = (index, date) => {
    const newDates = [...dates];
    newDates[index] = date;
    form1.setFieldsValue({
      [`items1[${index}].expiryDateTime`]: moment(date),
    });

    setDates(newDates);
  };

  const handleSelectChange = (field, value) => {
    setSelectedOptions((prevState) => {
      const newSelectedOptions = [...prevState];
      newSelectedOptions[field.key] = value;
      return newSelectedOptions;
    });
  };

  const onFinish = (values) => {
    let newData = values?.items1?.map((x, index) => ({
      ...x,
      expiryDateTime: dates[index]?._d,
      isActive: true,
    }));
    let removedData = removeItem?.map((x, index) => {
      return {
        ...x,
        expiryDateTime: dates[index]?._d,
        isActive: false,
      };
    });
    let lastData = newData.concat(removedData);
    dispatch(emailSharedAdd(lastData));
  };

  useEffect(() => {
    const filteredArray = selectedIndex.filter(
      (value) => !selectedOptions.includes(value)
    );
    setNewFilteredData(filteredArray);
    let deletedData = getAllSharedListResponse?.items?.filter((x) => {
      return filteredArray.includes(x.employeeId);
    });

    setRemoveItem(deletedData);
  }, [selectedOptions]);

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };
  return (
    <>
      <Card bordered={false} style={{ height: 461, overflow: "auto" }}>
        <Form onFinish={onFinish} form={form1}>
          <Form.List className="test" name="items1">
            {(fields, { add, remove }) => ( 
              <>
                {fields.map((field, index) => {
                  const disabledOptions = messageParticipants.filter(
                    (participant) =>
                      selectedOptions.includes(participant?.employeeId)
                  );

                  return (
                    <div
                      className="profile-emailshare"
                      style={{ width: "100%", padding: "10px" }}
                    >
                      <Form.Item
                        key={`${field.key}`}
                        style={{ marginBottom: 0 }}
                      >
                        <Form.Item
                          key={`${field.key}-id`}
                          {...field}
                          name={[field.name, "employeeId"]}
                          fieldKey={[field.fieldKey, "employeeId"]}
                          rules={[
                            { required: true, message: "Name is required" },
                          ]}
                          style={{
                            display: "inline-block",
                            width: "50%",
                          }}
                        >
                          <Select
                            style={{ fontSize: "14px" }}
                            optionFilterProp="label"
                            bordered={true}
                            autoFocus={true}
                            onChange={(value) =>
                              handleSelectChange(field, value)
                            }
                            placeholder={`Internal To`}
                          >
                            {messageParticipants.map((participant, j) => {
                              const isDisabled = disabledOptions.some(
                                (option) =>
                                  option.employeeId === participant?.employeeId
                              );

                              return (
                                <Option
                                  key={j}
                                  value={participant?.employeeId}
                                  label={[
                                    participant?.fullName,
                                    participant?.userName,
                                  ]}
                                  disabled={isDisabled}
                                >
                                  <div className="demo-option-label-item">
                                    <span role="img">
                                      {participant?.fullName} {"" + ""}
                                    </span>
                                    [{participant?.userName.toUpperCase()}]
                                  </div>
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <br />

                        <div style={{ paddingTop: "0px" }}>
                          <div
                            style={{
                              width: widthh < 1700 ? "80%" : "43%",
                            }}
                          >
                            <Form.Item
                              key={field.key}
                              {...field}
                              name={[field.name, "isSentAccess"]}
                              fieldKey={[field.fieldKey, "isSentAccess"]}
                              valuePropName="checked"
                              style={{
                                display: "inline-block",
                                width: "30%",
                              }}
                            >
                              <Checkbox>Send Access</Checkbox>
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, "isInboxAccess"]}
                              fieldKey={[field.fieldKey, "isInboxAccess"]}
                              valuePropName="checked"
                              style={{
                                display: "inline-block",
                                width: "30%",
                              }}
                            >
                              <Checkbox>Inbox Access</Checkbox>
                            </Form.Item>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              float: "left",
                              width: widthh < 1700 ? "47%" : "28%",
                              justifyContent: "left",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                width: "50%",
                                marginTop: "10px",
                              }}
                            >
                              Expiry Date{" "}
                            </span>
                            <Form.Item
                              {...field}
                              name={[field.name, "expiryDateTime"]}
                              fieldKey={[field.fieldKey, "expiryDateTime"]}
                              key={field.key}
                              style={{
                                display: "inline-block",
                                float: "left",
                                width: "50%",
                              }}
                            >
                              <DatePicker
                                format="YYYY-MM-DD "
                                disabledDate={disabledDate}
                                placeholder=""
                                style={{
                                  width: "100%",
                                  float: "right",
                                  marginTop: "10px",
                                }}
                                vealue={
                                  form1.getFieldValue([
                                    "items1",
                                    field.name,
                                    "expiryDateTime",
                                  ]) || undefined
                                }
                                onChange={(date) =>
                                  handleDateChange(index, date)
                                }
                              />
                            </Form.Item>
                            <MinusCircleOutlined
                              style={{
                                display: "inline-block",
                                paddingLeft: 20,
                                paddingTop: 10,
                              }}
                              className="contact__btn-danger"
                              onClick={() => {
                                const indexesToRemove = [field.name];

                                const newSelectedOptions =
                                  selectedOptions.filter(
                                    (_, index) =>
                                      !indexesToRemove.includes(index)
                                  );

                                setSelectedOptions((prevState) => {
                                  return newSelectedOptions;
                                });

                                indexesToRemove.forEach((index) =>
                                  remove(index)
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  );
                })}
                <Form.Item>
                  <Button
                    style={{
                      width: 45,
                      marginRight: 5,
                    }}
                    type="dashed"
                    className="contact__buttons"
                    onClick={() => {
                      add();
                    }}
                  >
                    <PlusCircleOutlined
                      style={{
                        color: "green",
                      }}
                    />
                    Add User
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button
              type="link"
              loading={sharedMailAddResponseoading}
              className="btn-cancel"
              style={{
                width: "120px",
                backgroundColor: "#3879c5",
                color: "white",
                marginLeft: "8px",
              }}
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default EmailShare;
