import {
  MinusCircleOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Table,
  Typography,
} from "antd";
import _, { isEmpty, startCase } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReferenceListing } from "services/redux/Reference/action";
import { getAllUsers } from "services/remanentCalls";

import { referenceSecondaryAdd } from "services/redux/Reference/Secondary/action";
import {
  getAllDepartments,
  getAllRemainder,
} from "services/redux/adminSettings/organizationStructure/action";
import {
  addToDoEvent,
  getTodoEventsPagination,
  toDoGetAgendas,
  todoEventGetAllCounter,
  updateToDoEvent,
} from "services/redux/organizer/todoLists/action";

import storage from "utils/storage";
import AgendaCard from "./AgendaCard";

const options = [
  { label: "Normal", value: "normal" },
  { label: "Call", value: "call" },
  { label: "Visit", value: "visit" },
  { label: "Fixed", value: "fixed" },
  { label: "Private", value: "private" },
];

const ToDoForm = (props) => {
  const {
    todoDetails,
    onCloseEventFormDrawer,
    setShowAddTask,
    largeWidth,
    setLargeWidth,
    visitor,
    setVisitor,
    taskCat,
    isDashboard,
    // departmentListingResponse,
    // remainderListingResponse,
    fixedTaskCategory,
  } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { TextArea } = Input;

  const [addAssign, setAddAssign] = useState(false);
  const [addWatcher, setAddWatcher] = useState(false);
  const [removedData, setRemovedData] = useState([]);
  const [searchValueReference, setSearchValueReference] = useState("");
  const [filterParticipantType, setFilterParticipantType] = useState("");
  const [referenceListing, setReferenceListing] = useState([]);
  const [filterReference, setFilterReference] = useState([]);
  const [meetingInvitees, setMeetingInvitees] = useState();
  const [todoEventCandidateDtos, setTodoEventCandidateDtos] = useState([]);
  const [todoEventWatcherDtos, setTodoEventWatcherDtos] = useState([]);
  const [filterMeetingInvitess, setFilterMeetingInvitess] = useState([]);
  const [assigneInvites, setAssigneInvites] = useState([]);
  const [reference, setReference] = useState([]);
  const [phone, setPhone] = useState(false);
  const [visit, setVisit] = useState(false);
  const [normal, setNormal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedValue, setSelectedRadioValue] = useState("normal");

  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );

  const { referenceSecondaryAddIsSuccess } = useSelector(
    (state) => state.secondaryRoot2
  );

  const labelEventsState = useSelector((state) => state.labelEvents);
  const statusListingState = useSelector((state) => state.statusListing);
  const priorityEventsState = useSelector((state) => state.priorityEvents);
  const { organizerEventPriorityListing } = priorityEventsState;
  const { allEventStatusListing } = statusListingState;
  const todoEventDetails = useSelector((state) => state.toDoEvents);
  const { addTodoEventsKanban } = useSelector((state) => state.toDoEvents);
  const {
    departmentListingResponse,
    departmentListingResponseLoading,
    remainderListingResponse,
    remainderListingResponseLoading,
  } = useSelector((state) => state.organizationStructure);

  const { profileInfo } = useSelector((state) => state.profile);

  useEffect(() => {
    if (
      profileInfo?.userRegistrationAddUpdateDto?.roleNames?.includes("admin")
    ) {
      dispatch(getAllDepartments("admin"));
    } else {
      dispatch(getAllDepartments());
    }
    dispatch(getAllRemainder());
  }, []);

  let searchKeywordFormat = searchValueReference?.split("-");
  let searchKeywordFormatData = "";

  if (searchKeywordFormat?.length === 2) {
    searchKeywordFormatData = `${_.startCase(
      searchKeywordFormat?.[0]
    )}-${_.startCase(searchKeywordFormat?.[1].trim())}`;
  }

  useEffect(() => {
    if (referenceSecondaryAddIsSuccess) {
      setSearchValueReference("");
      setReference("");
      form.setFieldsValue({
        referenceId:
          form.getFieldsValue()["referenceId"] === undefined
            ? searchKeywordFormatData
            : form.getFieldsValue()["referenceId"],
      });
    }
  }, [referenceSecondaryAddIsSuccess]);

  const { updateTodoEventRespLoading, addToDoEventRespLoading } =
    todoEventDetails;

  let participantFrom = storage.getItem("userId");

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {
      // message.error('Failure');
    });

    if (resp && resp.status === 200) {
      setMeetingInvitees(resp.data);
    }
  };

  useEffect(() => {
    if (largeWidth && visitor) {
      setSelectedRadioValue("visit");
      form.setFieldsValue({ taskType: "visit" });
    } else if (largeWidth && !visitor) {
      setSelectedRadioValue("call");
      form.setFieldsValue({ taskType: "call" });
    }
  }, [largeWidth, visitor]);

  useEffect(() => {
    if (!isEmpty(meetingInvitees)) {
      // let allInvitess = meetingInvitees.filter((user) => {
      //   return user.appUserId !== participantFrom;
      // });
      setAssigneInvites(meetingInvitees);
    }
  }, [meetingInvitees]);

  useEffect(() => {
    if (!isEmpty(todoDetails)) {
      let primaryCandidate = todoDetails.todoEventCandidateDtos.some(
        (candidate) => {
          return candidate.isPrimary === true;
        }
      );
      setAddAssign(primaryCandidate);

      let watcherCandidate = todoDetails.todoEventCandidateDtos.some(
        (candidate) => {
          return candidate.isWatcher === true;
        }
      );
      setAddWatcher(watcherCandidate);

      let todoAssignCandidate = todoDetails?.todoEventCandidateDtos.filter(
        (candidate) => candidate.isPrimary === true
      );

      let todoWatcherCandidate = todoDetails?.todoEventCandidateDtos.filter(
        (candidate) => candidate.isWatcher === true
      );
      setReference(todoDetails?.referenceId);

      let type = todoDetails?.organizationDepartmentDto?.id
        ? "fixed"
        : todoDetails?.isPrivate
        ? "private"
        : todoDetails?.normalOrPhoneOrVisit === 1
        ? "call"
        : todoDetails?.normalOrPhoneOrVisit === 2
        ? "visit"
        : "normal";

      setSelectedRadioValue(type);

      form.setFieldsValue({
        startDate: moment(todoDetails?.startDate),
        dueDate: moment(todoDetails?.dueDate),
        subject: todoDetails?.subject,
        organizerEventLabelId: todoDetails?.organizerEventLabelId,
        organizerEventPriorityId: todoDetails?.organizerEventPriorityId
          ? todoDetails?.organizerEventPriorityId
          : todoDetails?.organizerEventPriorityDto?.id,
        referenceId: todoDetails?.referenceCode,
        todoEventStatusId: todoDetails?.todoEventStatusId
          ? todoDetails?.todoEventStatusId
          : todoDetails?.todoEventStatusDto?.id,
        notes: todoDetails?.notes,
        isActive: todoDetails?.isActive,
        todoEventCandidateDtos: todoAssignCandidate.map(
          (assigne) => assigne.candidateId
        ),
        todoEventWatcherDtos: todoWatcherCandidate.map(
          (watcher) => watcher.candidateId
        ),
        todoEventAgendas: todoDetails?.todoEventAgendas,
        taskType: type,
        todoEventReminderType:
          type === "fixed" ? todoDetails?.todoEventRemainderTypeDto?.id : "",
        departmentId:
          type === "fixed" ? todoDetails?.organizationDepartmentDto?.id : "",
      });
    } else if (isEmpty(todoDetails)) {
      setReference(null);
      form.resetFields();
    }
    // }, [form, todoDetails]);
  }, [form, todoDetails]);

  const changeEmployeeDto = (value, option) => {
    let newTodoEventCandidateDtos = option.map((candidate) => {
      return {
        candidateId: candidate.value,
        candidateName: candidate.title,
        isPrimary: true,
        isWatcher: false,
      };
    });
    setTodoEventCandidateDtos(newTodoEventCandidateDtos);
  };
  useEffect(() => {
    if (!isEmpty(assigneInvites)) {
      setFilterMeetingInvitess(assigneInvites);
    }
  }, [assigneInvites]);

  const changeEmployeeWatcherDtos = (value, option) => {
    let newTodoEventWatcherDtos = option.map((candidate) => {
      return {
        candidateId: candidate.value,
        candidateName: candidate.title,
        isPrimary: false,
        isWatcher: true,
      };
    });
    setTodoEventWatcherDtos(newTodoEventWatcherDtos);
  };

  const onFinish = (values) => {
    delete values.referenceId;
    let candidates = form.getFieldValue("todoEventCandidateDtos")?.map((x) => {
      return {
        candidateId: x,
        isPrimary: true,
        isActive: true,
      };
    });
    let candidates1 = form.getFieldValue("todoEventWatcherDtos")?.map((x) => {
      return {
        candidateId: x,
        isWatcher: true,
        isActive: true,
      };
    });
    let newTodoCandidate = [].concat(candidates ?? [], candidates1 ?? []);

    delete values.todoEventWatcherDtos;
    let newValues = {
      privateUserId: todoDetails?.privateUserId ?? participantFrom,
      organizerEventLabelId: "5ed65d11-e8e4-4ca9-a35f-14dbaa6509a6",
      referenceId: reference?.length !== 0 ? reference : null,
      // normalOrPhoneOrVisit:
      //   largeWidth && visitor ? 2 : largeWidth && !visitor ? 1 : 0,
      normalOrPhoneOrVisit:
        selectedValue === "call" ? 1 : selectedValue === "visit" ? 2 : 0,
      isPrivate: selectedValue === "private" ? true : false,
      changeValue:
        selectedValue == "private" && todoDetails?.isPrivate !== true,
      ...values,
    };

    newValues.todoEventCandidateDtos = newTodoCandidate;
    newValues.isToday = todoDetails?.isToday ? todoDetails?.isToday : 0;
    newValues.todoEventAgendas = todoDetails?.todoEventAgendas?.map((curr) => {
      const newVal = values?.todoEventAgendas?.find(
        (val) => val.id === curr.id
      );
      if (newVal) {
        return newVal;
      }
      return {
        ...curr,
        isActive: false,
      };
    });

    newValues.todoEventAgendas = [
      ...(newValues?.todoEventAgendas || []),
      ...(values?.todoEventAgendas?.filter((x) => !x.id) || []),
    ];

    let type = "calls";
    if (isEmpty(todoDetails)) {
      // form.resetFields();
      setAddAssign(false);
      setAddWatcher(false);
      onCloseEventFormDrawer();

      dispatch(addToDoEvent(newValues, type, taskCat));

      setRemovedData([]);
    } else {
      newValues.referenceId = reference;
      if (phone) {
        newValues.normalOrPhoneOrVisit = 1;
      } else if (visit) {
        newValues.normalOrPhoneOrVisit = 2;
      } else if (normal) {
        newValues.normalOrPhoneOrVisit = 0;
      }
      if (isDelete) {
        newValues.isActive = false;
      } else {
        newValues.isActive = true;
      }

      dispatch(
        updateToDoEvent(
          newValues,
          todoDetails?.id,
          props?.taskAssignedTo,
          todoDetails?.referenceId
        )
      );
      // console.log("I'm clicked", todoDetails?.id, newValues)
      // .then(() => {
      //   if (isDashboard) {
      //     dispatch(
      //       getTodoEventsPagination(
      //         "maxResultCount=30&skipCount=0&normalOrPhoneOrVisit=0&&normalOrPhoneOrVisit=1&normalOrPhoneOrVisit=2"
      //       )
      //     );
      //   } else {
      //     if (largeWidth && visit) {
      //       dispatch(
      //         getTodoEventsPagination(
      //           "maxResultCount=30&skipCount=0&normalOrPhoneOrVisit=2"
      //         )
      //       );
      //     } else if (largeWidth && !visit) {
      //       dispatch(
      //         getTodoEventsPagination(
      //           "maxResultCount=30&skipCount=0&normalOrPhoneOrVisit=1"
      //         )
      //       );
      //     } else {
      //       dispatch(
      //         getTodoEventsPagination(
      //           "maxResultCount=30&skipCount=0&normalOrPhoneOrVisit=0"
      //         )
      //       );
      //     }
      //   }
      // })

      setRemovedData([]);
      setLargeWidth(!!largeWidth);
      setVisit(false);
    }
    setShowAddTask && setShowAddTask(false);
    form.resetFields();
    onCloseEventFormDrawer();
  };

  useEffect(() => {
    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
  }, []);

  useEffect(() => {
    if (searchValueReference) {
      if (referenceListingResponse) {
        setReferenceListing(referenceListingResponse);
      }
    }
  }, [referenceListingResponse, searchValueReference]);

  useEffect(() => {
    const selectedDepartment = departmentListingResponse?.find(
      (x) => x?.departmentName === fixedTaskCategory?.departmentName
    );
    const selectedRemainder = remainderListingResponse?.find(
      (x) => x?.systemName === fixedTaskCategory?.systemName
    );
    form.setFieldsValue({
      departmentId: selectedDepartment?.id,
      todoEventReminderType: selectedRemainder?.id,
    });
  }, [
    departmentListingResponse,
    fixedTaskCategory?.departmentName,
    form,
    fixedTaskCategory?.systemName,
    remainderListingResponse,
  ]);

  useEffect(() => {
    if (!isEmpty(referenceListing) && filterParticipantType == "REF") {
      setFilterReference(referenceListing.items);
    }
  }, [referenceListing]);

  useEffect(() => {
    if (filterParticipantType === "REF") {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  const handleInputChange = (event) => {
    const body = {
      AgendaFilterType: "ShowLastCallAgenda",
      SearchKeyboard: event.target.value,
      MaxCount: 20,
    };
    setTimeout(() => {
      dispatch(toDoGetAgendas(body));
    }, 1000);
  };

  const dataSource1 = todoDetails?.todoEventAgendas;

  return (
    <Form
      // style={{ marginLeft: "auto", width: "40%" }}
      className="popup-form-wrapper drawer-form-wrapper"
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
        phone: false,
        visit: false,
        normal: false,
      }}
      onFinish={onFinish}
      form={form}
      autoComplete="off"
    >
      <div className="gray__form gray-margin">
        <div
          className="grid-column"
          style={
            largeWidth
              ? { display: "grid", gridTemplateColumns: "5fr 7fr" }
              : null
          }
        >
          <div
            className="drawer-form-row"
            style={{ marginLeft: "auto", width: "100%", height: "fit-content" }}
          >
            <div className="drawer-form-group">
              <Form.Item
                label="Title"
                name="subject"
                className="form__group"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  placeholder="Title"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </div>

            {largeWidth && (
              <div className="drawer-form-group">
                <Form.List
                  name="todoEventAgendas"
                  initialValue={todoDetails?.todoEventAgendas}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <>
                          {index === 0 && (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: "10px",
                              }}
                            >
                              {/* <div style={{ width: "5px" }}>sn</div> */}
                              <div style={{ width: "43%", marginLeft: "15px" }}>
                                Enter the Agenda
                              </div>
                              <div style={{ width: "50%" }}>
                                Enter the Discussion
                              </div>
                              <div style={{ display: "none" }}>sn</div>
                            </div>
                          )}
                          <div
                            style={{
                              display: "flex",
                              marginBottom: "10px",
                              // gap: "5px",
                            }}
                            key={field.fieldKey}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "5px",
                              }}
                            >
                              <div>{index + 1}</div>
                            </div>

                            <Form.Item
                              // label={index === 0 ? "Agenda" : null}
                              {...field}
                              name={[field.name, "agenda"]}
                              fieldKey={[field.fieldKey, "agenda"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Input field is required",
                                },
                              ]}
                            >
                              <Input.TextArea autoSize={{ minRows: 2 }} />
                            </Form.Item>
                            <Form.Item
                              // label={index === 0 ? " Discussion" : null}
                              {...field}
                              name={[field.name, "discussion"]}
                              fieldKey={[field.fieldKey, "discussion"]}
                            >
                              <Input.TextArea autoSize={{ minRows: 2 }} />
                            </Form.Item>

                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="remove-field-wrapper">
                                <MinusCircleOutlined
                                  style={{
                                    width: 45,
                                    marginTop: 3,
                                  }}
                                  className="contact__btn-danger"
                                  onClick={() => remove(field.name)}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      <Form.Item>
                        <Button
                          style={{
                            width: 45,
                            marginRight: 5,
                          }}
                          type="dashed"
                          className="contact__buttons"
                          onClick={() => add()}
                        >
                          <PlusCircleOutlined
                            style={{
                              color: "green",
                            }}
                          />
                          Add Agenda
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            )}

            {!props.isPrivate && (
              <div className="drawer-form-group">
                <Form.Item
                  label="Reference"
                  name="referenceId"
                  className="addTask__form--item"
                  rules={[
                    {
                      required: largeWidth ? false : true,
                      message: "",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select reference"
                    bordered={false}
                    optionFilterProp="label"
                    allowClear={true}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value) => {
                      setReference(value);
                    }}
                    onSearch={(value) => {
                      setSearchValueReference(value);
                      setFilterParticipantType("REF");
                    }}
                    autoComplete="off"
                    notFoundContent={
                      <>
                        {searchKeywordFormat?.length === 2 && (
                          <>
                            Content Not Found. Do you want to create{" "}
                            <Typography.Text style={{ color: "blue" }}>
                              {searchKeywordFormatData}
                            </Typography.Text>{" "}
                            ?
                            <Button
                              type="primary"
                              onClick={() => {
                                dispatch(
                                  referenceSecondaryAdd({
                                    title: searchKeywordFormatData,
                                    description: searchKeywordFormatData,
                                  })
                                );
                                form.setFieldsValue({
                                  referenceId: searchKeywordFormatData,
                                });
                              }}
                            >
                              Yes
                            </Button>
                          </>
                        )}
                      </>
                    }
                  >
                    {filterReference.map((reference, index) => {
                      return (
                        <Option
                          value={reference.id}
                          key={index}
                          label={[reference.code, reference.title]}
                        >
                          <div className="demo-option-label-item">
                            <div>
                              {reference.code} {""}
                            </div>
                            <div>{reference.title}</div>
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            )}

            {props.isPrivate && (
              <div className="drawer-form-group">
                <Form.Item
                  className="form__group"
                  name="isPrivate"
                  initialValue="true"
                  hidden={true}
                >
                  <input
                    type="hidden"
                    name="isPrivate"
                    value="true"
                    hide
                  ></input>
                </Form.Item>
              </div>
            )}

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr ",
                gap: "10px",
                width: "100%",
              }}
            >
              <div className="drawer-form-group" style={{ width: "100%" }}>
                <Form.Item
                  label="Progress"
                  name="todoEventStatusId"
                  className="form__group"
                  initialValue={allEventStatusListing[1]?.id}
                >
                  <Select
                    placeholder="Select Progress"
                    autoComplete="off"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {!isEmpty(allEventStatusListing) &&
                      allEventStatusListing.map((status, index) => {
                        return (
                          <Option value={status.id} key={index}>
                            {status.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>

              <div className="drawer-form-group" style={{ width: "100%" }}>
                <Form.Item
                  label="Priority"
                  name="organizerEventPriorityId"
                  className="form__group"
                  initialValue={organizerEventPriorityListing[3]?.id}
                >
                  <Select
                    placeholder="Select Priority"
                    autoComplete="off"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {!isEmpty(organizerEventPriorityListing) &&
                      organizerEventPriorityListing.map((status, index) => {
                        return (
                          <Option value={status.id} key={index}>
                            {status.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr ",
                gap: "10px",
                width: "100%",
              }}
            >
              <div className="drawer-form-group">
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  className="form__group"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    defaultValue={moment()}
                    disabledDate={(current) => {
                      return current && current < moment();
                    }}
                    format="YYYY/MM/DD"
                    autoComplete="off"
                  />
                </Form.Item>
              </div>

              <div className="drawer-form-group">
                <Form.Item
                  label="Due Date"
                  name="dueDate"
                  className="form__group"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                  initialValue={moment()}
                >
                  <DatePicker
                    defaultValue={moment()}
                    disabledDate={(current) => {
                      return (
                        current &&
                        (form.getFieldValue("startDate")
                          ? current < form.getFieldValue("startDate")
                          : current < moment())
                      );
                    }}
                    format="YYYY/MM/DD"
                    autoComplete="off"
                  />
                </Form.Item>
              </div>
            </div>
            {taskCat !== "private" && (
              <>
                <div className="drawer-form-group">
                  <Form.Item
                    className="form__group"
                    valuePropName="checked"
                    // name="addAssignee"
                    initialValue={false}
                  >
                    {!props.isPrivate && (
                      <Checkbox
                        checked={addAssign}
                        onChange={() => setAddAssign(!addAssign)}
                      >
                        Add Assignees
                      </Checkbox>
                    )}
                  </Form.Item>
                  {/* </div> */}
                </div>

                {addAssign && (
                  <div className="drawer-form-group">
                    <Form.Item
                      label="Assignees"
                      name="todoEventCandidateDtos"
                      className="form__group"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        placeholder="Assignees"
                        optionFilterProp="label"
                        allowClear={true}
                        disabled={!addAssign}
                        onChange={changeEmployeeDto}
                        autoComplete="off"
                      >
                        {!isEmpty(assigneInvites) &&
                          assigneInvites.map((employee, index) => {
                            return (
                              <Option
                                value={employee.appUserId}
                                title={employee.fullName}
                                key={index}
                                label={[employee.fullName, employee.userName]}
                              >
                                {/* {employee.fullName} */}
                                <div className="demo-option-label-item">
                                  <span role="img">{employee.fullName} </span>[
                                  {employee.userName.toUpperCase()}]
                                </div>
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                )}
                <div className="drawer-form-group">
                  <Form.Item
                    // className="form__group"
                    // valuePropName="checked"
                    name="watchers"
                    initialValue={false}
                  >
                    {!props.isPrivate && (
                      <Checkbox
                        checked={addWatcher}
                        onChange={() => setAddWatcher(!addWatcher)}
                      >
                        Add Watcher
                      </Checkbox>
                    )}
                  </Form.Item>
                </div>

                {addWatcher && (
                  <div className="drawer-form-group">
                    <Form.Item
                      label="Watcher"
                      className="form__group"
                      name="todoEventWatcherDtos"
                    >
                      <Select
                        mode="multiple"
                        showSearch
                        placeholder="Watchers"
                        optionFilterProp="label"
                        allowClear={true}
                        onChange={changeEmployeeWatcherDtos}
                        disabled={!addWatcher}
                        autoComplete="off"
                      >
                        {!isEmpty(filterMeetingInvitess) &&
                          filterMeetingInvitess.map((employee, index) => {
                            return (
                              <Option
                                value={employee.appUserId}
                                title={employee.fullName}
                                key={index}
                                label={[employee.fullName, employee.userName]}
                              >
                                {/* {employee.fullName} */}
                                <div className="demo-option-label-item">
                                  <span role="img">{employee.fullName} </span>[
                                  {employee.userName.toUpperCase()}]
                                </div>
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                )}
              </>
            )}
            <Form.Item name="taskType">
              <Radio.Group
                onChange={(e) => {
                  setSelectedRadioValue(e.target.value);
                  if (e.target.value === "call") {
                    setLargeWidth(true);
                    setVisitor(false);
                  } else if (e.target.value === "visit") {
                    setLargeWidth(true);
                    setVisitor(true);
                  } else {
                    setLargeWidth(false);
                    setVisit(false);
                  }
                }}
                defaultValue={selectedValue}
              >
                {options.map((option) => (
                  <Radio key={option.value} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {/* {!isEmpty(todoDetails) && (
              <div className="drawer-form-group">
                <strong>Transfer to </strong>
                <div style={{ display: "flex" }}>
                  {!(largeWidth && !visitor) && (
                    <Form.Item name="phone" className="form__group">
                      <Checkbox
                        checked={phone}
                        onChange={(e) => {
                          setPhone(!phone);
                          setVisit(false);
                          setNormal(false);
                        }}
                      >
                        Calls
                      </Checkbox>
                    </Form.Item>
                  )}

                  {!visitor && (
                    <Form.Item name="visit" className="form__group">
                      <Checkbox
                        checked={visit}
                        onChange={(event) => {
                          // dispatch(updateToDoEvent(singleTaskDetail));
                          setVisit(!visit);
                          setPhone(false);
                          setNormal(false);
                        }}
                      >
                        Visit
                      </Checkbox>
                    </Form.Item>
                  )}

                  {largeWidth && (
                    <Form.Item name="visit" className="form__group">
                      <Checkbox
                        checked={normal}
                        onChange={(event) => {
                          // dispatch(updateToDoEvent(singleTaskDetail));
                          setNormal(!normal);
                          setPhone(false);
                          setVisit(false);
                        }}
                      >
                        Task
                      </Checkbox>
                    </Form.Item>
                  )}
                </div>
              </div>
            )} */}

            {/* {isEmpty(todoDetails) && (
              <div className="drawer-form-group">
                <Checkbox
                  checked={department}
                  onChange={() => {
                    setDepartment(!department);
                  }}
                >
                  Fixed Task
                </Checkbox>
              </div>
            )} */}

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr ",
                width: "100%",
                gap: "20px",
              }}
            >
              {selectedValue === "fixed" && (
                <Form.Item name="departmentId">
                  <Select placeholder="Department Type" autoComplete="off">
                    {!isEmpty(departmentListingResponse) &&
                      departmentListingResponse?.map((x) => {
                        return (
                          <Option
                            value={x?.id}
                            title={x?.departmentName}
                            key={x?.id}
                          >
                            {x?.departmentName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}
              {selectedValue === "fixed" && (
                <Form.Item name="todoEventReminderType">
                  <Select placeholder="Remainder Type" autoComplete="off">
                    {!isEmpty(remainderListingResponse) &&
                      remainderListingResponse?.map((x) => {
                        return (
                          <Option
                            value={x?.id}
                            title={x?.displayName}
                            key={x?.id}
                          >
                            {x?.displayName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )}
            </div>

            <div className="drawer-form-group">
              <Form.Item
                label="Notes"
                className="form__group"
                name="notes"
                initialValue=""
              >
                <TextArea
                  style={{ height: 100 }}
                  placeholder="Notes"
                  autoComplete="off"
                />
              </Form.Item>
            </div>
          </div>

          {largeWidth && (
            <AgendaCard visitor={visitor} dataSource1={dataSource1} />
          )}
        </div>

        <div className={setShowAddTask ? "modal-footer-popup" : "model-footer"}>
          {todoDetails?.id ? (
            <Button
              className="ant-btn ant-btn-dangerous ft-delete-btn"
              htmlType="submit"
              onClick={() => {
                setIsDelete(true);
              }}
            >
              Delete
            </Button>
          ) : (
            ""
          )}
          <Button
            onClick={() => {
              onCloseEventFormDrawer();
              // form.resetFields();
              // setAddAssign(false);
              // setAddWatcher(false);
              // setPhone(false);
              // setVisit(false);
              // setNormal(false);
              // setLargeWidth(false);
              setShowAddTask && setShowAddTask(false);
            }}
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="guest__entry__button"
            type="primary"
            htmlType="submit"
            loading={addToDoEventRespLoading || updateTodoEventRespLoading}
            disabled={addToDoEventRespLoading || updateTodoEventRespLoading}
          >
            {todoDetails?.id ? "Update" : "Submit"}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ToDoForm;
