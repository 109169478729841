import { Tooltip } from "antd";

export const getHalfDetailsTag = (details) => {
  let values = [...details];

  let halfDetails = [];
  halfDetails = values.splice(3);
  let remainingSplicedTags = "";

  let getRemainingSplicedTags = halfDetails.map((data, i) => {
    remainingSplicedTags = data.referenceCode;
    return remainingSplicedTags;
  });

  return (
    <div className="flex-box">
      {values?.map((data, key) => (
        <>
          {data?.referenceCode.length > 9 ? (
            <div
              key={key}
              className="emailList__card--ref paginationTag"
              style={{
                width: 50,
                textOverflow: "ellipsis",
                overflow: "hidden",
                alignItems: "center",
              }}
            >
              <Tooltip title={data.referenceCode}>
                {data?.referenceCode.substring(0, 12)}
              </Tooltip>
            </div>
          ) : (
            <div
              key={key}
              className="emailList__card--ref paginationTag"
              style={{ alignItems: "center" }}
            >
              {data?.referenceCode}
            </div>
          )}
        </>
      ))}
      {halfDetails.length > 0 && (
        <div className="referenceDetails__reference--revisions mailSection">
          <Tooltip title={getRemainingSplicedTags.join(",")}>
            <span className="referenceDetails--subtitle">
              +{getRemainingSplicedTags.length}
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
