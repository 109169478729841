import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Form, DatePicker, Checkbox, message, Select, Collapse } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import ImageUploaderMultiple from "components/shared/FileUploaderApproval";
//add leave application
import { addLeaveApplication } from "../../../../services/redux/requistions/leaveApplication/action";
//axios call
import { dynamicFileLabelTypeUploaderDetailDtos } from "services/remanentCalls";
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const LeaveForm = ({
    remainingLeaveApplicationTypesListing,
    setHideLeaveDrawer,
}) => {
    const dispatch = useDispatch();
    const [uploaders, setUploaders] = useState();
    useEffect(() => {
        fetchDynamicFileLabelTypeUploaderDetailDtos();
    }, []);

    const fetchDynamicFileLabelTypeUploaderDetailDtos = async () => {
        const resp = await dynamicFileLabelTypeUploaderDetailDtos("Leave").catch(
            (err) => {
                message.error("Failure");
            }
        );

        if (resp && resp.status === 200) {
            let uploaders = resp.data.filter(
                (respData) => respData.code === "LEAVEATTACHMENT"
            );
            setUploaders(uploaders);
        }
    };
    const [form] = Form.useForm();
    const { applicationConfiguration } = useSelector(
        (state) => state.applicationConfig
    );

    const userDetails = useSelector(
        (state) => state.profile?.profileInfo?.userRegistrationAddUpdateDto
    );


    const { addLeaveApplicationResp, addLeaveApplicationRespLoading } =
        useSelector((state) => state.leaveApplication);
    const { } = useSelector((state) => state.leaveApplication);

    const [currentUser, setCurrentUser] = useState({});

    useEffect(() => {
        if (!isEmpty(applicationConfiguration?.currentUser)) {
            setCurrentUser(applicationConfiguration?.currentUser);
        }
    }, []);
    useEffect(() => {
        if (addLeaveApplicationResp === true) {
            form.resetFields();
        }
    }, [addLeaveApplicationResp]);

    useEffect(() => {
        if(!isEmpty(addLeaveApplicationResp)) {
            setTimeout(() => {
                setHideLeaveDrawer(true);
                form.resetFields();
            },500);
        }
    },[addLeaveApplicationResp]);

    //on submit leave application
    const onSubmitApplicationLeave = (values) => {
        const removeProp = "leaveDaysRange";
        const { [removeProp]: remove, ...rest } = values;
        // removed leaveDaysrange from values as it is not required in payload
        let body = {
            ...rest,
            leaveApplicationTypeId: values?.leaveApplicationTypeId
                ? values?.leaveApplicationTypeId
                : remainingLeaveApplicationTypesListing[0]?.leaveApplicationTypeId,
            leaveDayFrom: moment(values.leaveDaysRange[0]).format("YYYY-MM-DD"),
            leaveDayTo: moment(values.leaveDaysRange[1]).format("YYYY-MM-DD"),
        };
        dispatch(addLeaveApplication(body));

    };
  
    //end of add leave
    return (
        <div className="leave-details-info-form">
            <Form
                className="drawer-form-wrapper"
                form={form}
                name="applicantLeave"
                onFinish={onSubmitApplicationLeave}
            >
                <div className="drawer-form-row">
                    <div className="drawer-form-group">
                        <Form.Item 
                        className="form__group" 
                        label="Leave By"
                        rules={[
                            {
                              required: true,
                              message: "  ",
                            },
                          ]}>
                            <Input
                                autoComplete="off"
                                value={`${currentUser?.userName
                                    }`}
                                className="disabled"
                                disabled
                            />
                        </Form.Item>
                    </div>
                    <div className="drawer-form-group">
                        <Form.Item
                            className="form__group"
                            label="Choose Leave Type"
                            name="leaveApplicationTypeId"
                            rules={[
                                {
                                  required: true,
                                 
                                },
                              ]}
                        >
                            <Select
                                placeholder="Please select"
                                autoComplete="off"
                            >
                                {
                                    !isEmpty(remainingLeaveApplicationTypesListing) &&
                                    remainingLeaveApplicationTypesListing.map((leaveType, i) => (
                                        <Option
                                            value={leaveType.leaveApplicationTypeId}
                                            title={leaveType?.leaveApplicationTypeDto?.leaveTypeName}
                                            key={i}>
                                            {leaveType?.leaveApplicationTypeDto?.leaveTypeName} {`(${leaveType?.remainingLeave > 0 ? leaveType?.remainingLeave : 0} remaining out of ${leaveType?.allowedleave} days)`}
                                        </Option>
                                    ))}

                            </Select>
                        </Form.Item>
                    </div>
                    <div className="drawer-form-group">
                        <Form.Item
                            className="form__group"
                            label="Select Date"
                            name="leaveDaysRange"
                            rules={[
                                {
                                  required: true,
                                 
                                },
                              ]}
                        >
                            <RangePicker format="YYYY-MM-DD" />
                        </Form.Item>
                    </div>
                    <div className="drawer-form-group">
                        <Form.Item
                            className="form__group"
                            label="Supervisor"
                        >
                            <div className="supervisor-list-wrapper">
                                {
                                    userDetails?.userReportingManagerDto?.map((supervisor, i) => {
                                        return (
                                            <>
                                                <span key={i}>{supervisor.fullName}</span>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </Form.Item>
                    </div>
                    <div className="drawer-form-group">
                        <Form.Item
                            name="isHalfDay"
                            valuePropName="checked"
                            className="form__group"
                            initialValue={false}
                        >
                            <Checkbox>Half Day</Checkbox>
                        </Form.Item>
                    </div>
                    <div className="drawer-form-group upload_leaveApplication">
                        {uploaders?.map((u, i) => (
                            <Form.Item className="form__group" name={["uploadedFileDtos"]} >
                                <ImageUploaderMultiple
                                    name={u.name}
                                    isMultipleFileAllowed={true}
                                    id={u.id}
                                    allowedExtensions={u.allowedExtensions}
                                    index={i}
                                    uploadedFileDtos={form.getFieldValue()}
                                    formName="uploadedFileDtos"
                                    form={form}
                                    callback={(e) => {
                                        let olduploadedFileDtos = form.getFieldValue()
                                            .uploadedFileDtos
                                            ? [...form.getFieldValue().uploadedFileDtos]
                                            : [];

                                        let uploadedFileDtos = [...olduploadedFileDtos];
                                        uploadedFileDtos.push(e);

                                        form.setFieldsValue({
                                            uploadedFileDtos,
                                        });
                                    }}
                                />
                            </Form.Item>
                        ))}
                    </div>
                    <div className="drawer-form-group">
                        <Form.Item
                            className="form__group"
                            label="Leave Reason"
                            name="reason"
                            rules={[
                                {
                                  required: true,
                                 
                                },
                              ]}
                        >
                            <TextArea autoComplete="off" rows={25} />
                        </Form.Item>
                    </div>
                </div>
                <div className="model-footer">
                    <Button
                        onClick={() => {
                            setHideLeaveDrawer(true);
                            form.resetFields();
                        }}
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button
                        loading={addLeaveApplicationRespLoading}
                        type="primary"
                        htmlType="submit"
                    >
                        Apply
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default LeaveForm