import React, { useState, useEffect } from "react";

// antd
import { Drawer, Form, Button, Checkbox, Input, Layout, Select } from "antd";

// utils
import { validateMessages } from "utils/misc";

// redux
import { useDispatch, useSelector } from "react-redux";
import { addNewRole, updateRole } from "services/redux/adminSettings/roles/action";
import { isEmpty, mapValues } from "lodash-es";
import { grantOrProhibitPermissionForRole } from "../../../services/redux/adminSettings/roles/action";
import { contactLabelGroupListing } from "../../../services/remanentCalls";

const { Header, Footer } = Layout;
const { Option } = Select;

const EditPermissionsDrawer = ({
  onEditPermissionClose,
  drawerVisibility,
  permissionDetails,
  permissionListsForRoleResp
}) => {
  const dispatch = useDispatch();
  const { grantOrProhitbitPermissionForRoleResp } = useSelector(
    (state) => state.roleLists
  );
  const [form] = Form.useForm();

  const [permissionLists, setPermissionLists] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (!isEmpty(permissionListsForRoleResp)) {
      form.resetFields();
      let newSelectedPermissionLists = permissionListsForRoleResp.filter(list => list.isGranted);
      setPermissionLists(permissionListsForRoleResp)
      setSelectedPermissions(newSelectedPermissionLists)
    }
  }, [permissionListsForRoleResp])

  useEffect(() => {
    if(grantOrProhitbitPermissionForRoleResp){
      onEditPermissionClose();
      form.resetFields();
    }
  }, [grantOrProhitbitPermissionForRoleResp]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      values.roleName = permissionDetails.name
      dispatch(grantOrProhibitPermissionForRole(values));
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <>
      <Drawer
        visible={drawerVisibility}
        width={500}
        maskClosable={false}
        className="drawer"
        onClose={onEditPermissionClose}
      >
        <Header className='drawer__header'>
          <h4 className='drawer__header--title'>
            {`Manage Permissions`}
          </h4>
        </Header>
        <div className='drawer__content'>
          <div className='drawer__content--wrapper'>
            <div className='contact__content__wrapper'>
              <div className="permissions_lists">
                <div className="title">Current Permissions</div>
                {
                  !isEmpty(selectedPermissions) ?
                    <ul className="current-permissions">
                      {
                        selectedPermissions.map((list, i) => (
                          <li key={i}>- {list.name}</li>
                        ))
                      }
                    </ul>
                    : 'No Permissions allowed'
                }
              </div>
              <Form
                layout='vertical'
                form={form}
                name='control-hooks'
                validateMessages={validateMessages}
              >
                <Form.Item
                  className="form__group"
                  name="permissionName"
                  label="Permissions"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Select permission"
                    onChange={(value) => {
                      permissionLists.map(list => {
                        if (list.name === value) {
                          form.setFieldsValue({
                            isGranted: list.isGranted
                          })
                        }
                      })
                    }}
                  >
                    {
                      permissionLists.map((lists, i) => {
                        return <Option value={lists.name} key={i} >{lists.name}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  name="isGranted"
                  valuePropName="checked"
                  className='form__group'
                  initialValue={false}
                >
                  <Checkbox>Granted</Checkbox>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <Footer className='drawer__footer'>
          <Button onClick={() => {
            form.resetFields();
            onEditPermissionClose();
          }}>
            Cancel
        </Button>
          <Button onClick={onSubmit} type="primary">
            Submit
        </Button>
        </Footer>
      </Drawer>
    </>
  );
};

export default EditPermissionsDrawer;
