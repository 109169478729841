import { Form } from "antd";
import DynamicImageUploader from "components/shared/DynamicImageUploader";
import React, { useState } from "react";

const InvitationNotice = ({ u, i, singleStageForm }) => {
  const [adDocs, setAdDocs] = useState();
  return (
    <>
      <div className="profiledetails__rowedit--data stage_form_to_Straight">
        <Form.Item
          // name={[`${u.name}FileDtos`]}
          name={[`${u.name}FileDtos`]}
          className="form__group"
          rules={[{ required: false }]}
        >
          <DynamicImageUploader
            name={u.name}
            isMultipleFileAllowed={u.isMultipleFileAllowed}
            id={u.id}
            allowedExtensions={u.allowedExtensions}
            // fileList={fileList}
            // setFileList={setFileList}
            index={i}
            form={singleStageForm}
            callback={(e) => {
              let olduploadedFileDtos = singleStageForm.getFieldValue()[
                `${u.name}FileDtos`
              ]
                ? [...singleStageForm.getFieldValue()[`${u.name}FileDtos`]]
                : [];

              let uploadedFileDtos = [...olduploadedFileDtos];
              uploadedFileDtos.push(e);

              singleStageForm.setFieldsValue({
                [`${u.name}FileDtos`]: uploadedFileDtos,
              });

              // form.setFieldsValue({
              //   uploadedFileDtos: [
              //     ...olduploadedFileDtos,
              //     e
              //   ]
              //     })
              setAdDocs = { setAdDocs };
              adDocs = { adDocs };
            }}
          />
        </Form.Item>
      </div>
    </>
  );
};

export default InvitationNotice;
