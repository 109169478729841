import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Collapse,
  Form,
  DatePicker,
  TimePicker,
  Button,
  Layout,
  Drawer,
  Input,
  Popover,
  Checkbox,
  Select,
  Card,
  List,
  Typography,
  Table,
} from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { forIn, isEmpty, cloneDeep, groupBy } from "lodash";
import ROTO from "../../Roto";
// import DocumentPurchase from "../DocumentPurchase";
import DynamicImageUploader from "components/shared/DynamicImageUploader";

import { getDaysDifference } from "utils/commonFunc";

import moment from "moment";
import { Link } from "react-router-dom";
import { cqBaseUrl } from "utils/config";
import InvitationNotice from "./InvitationNotice";
import AllFileViewer from "components/Documents/allFileViewer";

const BasicDetails = ({
  allCurrencyListingResponse,
  allCountryListingResponse,
  sisterCompanyListing,
  getDetails,
  changetest,
  singleStageForm,
  stages,
  dynamicTenderNoticeFileTypesState,
  dynamicTenderDocumentFileTypesState,
  dynamicROTOFileTypesState,
  fetchData,
  documentPurchaseForm,
  showParticipation,
  popoverMenuVisible,
  index,
  setOpeningDate,
  setSubmissionDate,
  setTenderDocumentFile,
  setTenderStageROTOFileList,
  singleofficialDoc,
  updateStage,
  addUpdate,
  data,
  genExtra,
  doubleofficialDoc,
}) => {
  const { token } = useSelector((state) => state.auth);

  const { Panel } = Collapse;
  const { Option } = Select;

  const { Header } = Layout;
  const [setForm, setOpenForm] = useState(true);
  const [commaSeparatorNum, setCommaSeparatorNum] = useState("");
  const [invitationDate, setInvitationDate] = useState();
  const [bidChecked, setBidChecked] = useState(false);
  const [adDocs, setAdDocs] = useState([]);
  const [offDocs, setOffDocs] = useState([]);

  useEffect(() => {
    if (stages === "doubleStages") {
      singleStageForm.resetFields();
    }
  }, [stages]);

  const onSelect = (e) => {
    if (e.target.checked === true) {
      setOpenForm(true);
    } else {
      setOpenForm(false);
    }
  };
  const numberCommaSepartor = (event) => {
    let documentCost = singleStageForm.getFieldValue("documentCost");

    const commaNum = documentCost.replace(/\,/g, "");
    if (isNaN(Number(commaNum))) {
      singleStageForm.setFieldsValue({ documentCost: commaSeparatorNum });
      return;
    }

    let tmp = documentCost.replace(/,/g, "");
    let val = Number(tmp).toLocaleString();
    if (tmp == "") {
      singleStageForm.setFieldsValue({ documentCost: "" });
    } else {
      singleStageForm.setFieldsValue({ documentCost: val });
      setCommaSeparatorNum(val);
    }
  };
  const numberCommaReplace = (event) => {
    let bidSecurityValue = singleStageForm.getFieldValue("bidSecurityValue");

    const commaNum = bidSecurityValue.replace(/\,/g, "");
    if (isNaN(Number(commaNum))) {
      singleStageForm.setFieldsValue({ bidSecurityValue: commaSeparatorNum });
      return;
    }

    let tmp = bidSecurityValue.replace(/,/g, "");
    let val = Number(tmp).toLocaleString();
    if (tmp == "") {
      singleStageForm.setFieldsValue({ bidSecurityValue: "" });
    } else {
      singleStageForm.setFieldsValue({ bidSecurityValue: val });
      setCommaSeparatorNum(val);
    }
  };

  useEffect(() => {
    if (fetchData.stageLevel === "stage1") {
      setBidChecked(
        fetchData.bidValidityDate !== null ||
          fetchData.bidSecurityValue !== null
      );
      singleStageForm.setFieldsValue({
        ...fetchData,
        tenderstagenoticeFileDtos: fetchData.tenderStageTenderNoticeFileList,
        tenderstageofficialdocumentFileDtos:
          fetchData.tenderStageTenderOffDocumentFileList,
        bidSecurityValueDate:
          fetchData.bidSecurityValueDate !== null
            ? moment(fetchData.bidSecurityValueDate)
            : "",
        bidValidityDate:
          fetchData.bidValidityDate !== null
            ? // ? moment(fetchData.bidSecurityValueDate)
              // : "",
              moment(fetchData.bidValidityDate)
            : "",
        inviatationDate:
          fetchData.inviatationDate !== null
            ? moment(fetchData.inviatationDate)
            : "",
        submissiondate:
          fetchData.submissionDate !== null
            ? moment(fetchData.submissionDate)
            : "",
        // submissionDate:
        //   fetchData.submissionDate !== null
        //     ? moment(fetchData.submissionDate)
        //     : "",
        openingdate:
          fetchData.openingDate !== null ? moment(fetchData.openingDate) : "",
        bidSecurityValue:
          fetchData.bidSecurityValue !== null
            ? fetchData.bidSecurityValue
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "",

        documentCost:
          fetchData.documentCost !== null
            ? fetchData.documentCost
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "",
      });
    } else if (fetchData.stageLevel === "stage2") {
      setBidChecked(
        fetchData.bidValidityDate !== null ||
          fetchData.bidSecurityValue !== null
      );
      let data = cloneDeep(fetchData);
      singleStageForm.setFieldsValue({
        ...data,
        tenderstagenoticeFileDtos: data.tenderStageTenderNoticeFileList,
        bidSecurityValueDate:
          data.bidSecurityValueDate !== null
            ? moment(fetchData.bidSecurityValueDate)
            : "",
        bidValidityDate:
          data.bidValidityDate !== null
            ? // ? moment(fetchData.bidSecurityValueDate)
              // : "",
              moment(fetchData.bidValidityDate)
            : "",
        inviatationDate:
          data.inviatationDate !== null
            ? moment(fetchData.inviatationDate)
            : "",
        submissiondate:
          data.submissionDate !== null ? moment(fetchData.submissionDate) : "",
        openingdate:
          data.openingDate !== null ? moment(fetchData.openingDate) : "",
        documentCost:
          fetchData.documentCost !== null
            ? fetchData.documentCost
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "",
        bidSecurityValue:
          fetchData.bidSecurityValue !== null
            ? fetchData.bidSecurityValue
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "",
      });
    }
  }, [fetchData]);

  const docColumns = [
    {
      title: "S.N.",
      dataIndex: "sn",
    },
    {
      title: "Filename",
      dataIndex: "displayFileName",
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            <Layout.Content
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              {/* <a
                target="_blank"
                // download
                href={cqBaseUrl + "/" + record?.viewFileURL + token}
                rel="noreferrer"
              >
                <EyeOutlined />
              </a> */}
              <AllFileViewer
                isShowFileName={false}
                file={record}
                cqBaseUrl={cqBaseUrl}
                token={token}
                height={600}
                width={900}
                showDelete={true}
                onDeleteClick={() => onDeleteClick(record)}
              />

              {/* <a
                target="_blank"
                download
                href={cqBaseUrl + "/" + record?.viewFileURL + token}
                rel="noreferrer"
              >
               <DeleteOutlined />
              </a> */}
              {/* <Link>
                <DeleteOutlined />
              </Link> */}
            </Layout.Content>
          </>
        );
      },
    },
  ];

  const onChange = (e) => {
    setBidChecked(!bidChecked);
  };

  let officialDocData;
  switch (fetchData?.stageLevel) {
    case "stage1":
      officialDocData = singleofficialDoc?.["Tender Official Document"];
      break;
    case "stage2":
      officialDocData = doubleofficialDoc?.["Tender Official Document"];
      break;
    default:
      officialDocData = null;
  }

  useEffect(() => {
    setOffDocs(officialDocData);
  }, []);

  const onDeleteClick = (record) => {
    // Filter the array
    let filterData = offDocs.filter(
      (item) => item.fileInformationId !== record.fileInformationId
    );
    setOffDocs(filterData);
  };

  return (
    <div>
      {/* <div
        className='edittender__header'
        style={{ backgroundColor: "#9BC7F9" }}>
        <div className='edittender__header--text'>
          <span className='edittender__primaryText'>Stage 1</span>
        </div>

        <div className='edittender__header--checkbox'>
          <Checkbox onChange={onSelect} defaultChecked={setForm} />
        </div>
      </div> */}
      {setForm ? (
        <Form
          layout="vertical"
          form={singleStageForm}
          // name="control-hooks"
          onFinish={updateStage}
        >
          <div className="outline-grayborders">
            <div className="profiledetails__rowedit">
              <div className="profiledetails__rowedit--label">
                <span className="profile__bodyTitle">Invitation Date</span>
              </div>
              <div className="profiledetails__rowedit--data">
                <Form.Item
                  name="inviatationDate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker
                    size="small"
                    format="YYYY/MM/DD"
                    onChange={(value, string) => {
                      setInvitationDate(Date.parse(string));
                    }}
                  />
                </Form.Item>
              </div>
            </div>

            {dynamicTenderNoticeFileTypesState.map((u, i) => (
              <div className="profiledetails__rowedit" key={u.id}>
                <div className="profiledetails__rowedit--label">
                  <span className="profile__bodyTitle">Invitation Notice</span>
                </div>

                <InvitationNotice
                  u={u}
                  i={i}
                  singleStageForm={singleStageForm}
                />
              </div>
            ))}
            <div className="profiledetails__rowedit">
              <div className="profiledetails__rowedit--label">
                <span className="profile__bodyTitle">Invitation Ref</span>
              </div>
              <div className="profiledetails__rowedit--data">
                <Form.Item
                  name="inviatationRef"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </div>
            </div>
            <div
              className="profiledetails__rowedit"
              style={{ margin: "6px 0px 6px 0px" }}
            >
              <div className="profiledetails__rowedit--label">
                <span className="profile__bodyTitle">Description</span>
              </div>
              <div className="profiledetails__rowedit--data">
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input.TextArea size="small" />
                </Form.Item>
              </div>
            </div>
            <div className="profiledetails__rowedit">
              <div className="profiledetails__rowedit--label">
                <span className="profile__bodyTitle"> Document Cost </span>
              </div>

              <div
                className="profiledetails__rowedit--data"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span
                  className="contactsDetails--subtitle bold"
                  style={{ width: 80 }}
                >
                  <Form.Item
                    name="documentCostCurrencyId"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    {!isEmpty(allCurrencyListingResponse) && (
                      <Select
                        defaultValue={() => {
                          let value = "";
                          allCurrencyListingResponse?.map((currency) => {
                            if (currency.abbvr == "NPR") {
                              value = currency.currencyId;
                            }
                          });

                          return value;
                        }}
                        size="small"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allCurrencyListingResponse?.map((currency) => (
                          <Option
                            key={currency.currencyId}
                            value={currency.currencyId}
                          >
                            {currency.abbvr}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </span>
                <span
                  className="contactsDetails--subtitle bold"
                  style={{ width: "calc(100% - 80px)" }}
                >
                  <Form.Item
                    name="documentCost"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      autoComplete="off"
                      onChange={numberCommaSepartor}
                    />
                  </Form.Item>
                </span>
              </div>
            </div>
            <div
              className="profiledetails__rowedit"
              style={{ margin: "6px 0px 6px 0px" }}
            >
              <div className="profiledetails__rowedit--label">
                <span className="profile__bodyTitle">Submission Date</span>
              </div>
              <div
                className="profiledetails__rowedit--data"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  name="submissiondate"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(value, dateString) => {
                      changetest(value?._d);
                      setSubmissionDate(dateString);
                    }}
                    // format="YYYY/MM/DD - h:mm A"
                    minuteStep={15}
                    format="YYYY/MM/DD hh:mm A"
                    // disabledDate={(current) => {
                    //   if (!!invitationDate === true) {
                    //     return current && current.valueOf() < invitationDate;
                    //   } else {
                    //     return (
                    //       current &&
                    //       current.valueOf() <
                    //         Date.parse(fetchData?.inviatationDate?.slice(0, 10))
                    //     );
                    //   }
                    // }}
                    disabled={!!singleStageForm.getFieldValue("submissiondate")}
                    showTime
                  />
                </Form.Item>
              </div>
            </div>
            <div
              className="profiledetails__rowedit"
              style={{ margin: "6px 0px 6px 0px" }}
            >
              <div className="profiledetails__rowedit--label">
                <span className="profile__bodyTitle">Opening Date</span>
              </div>
              <div
                className="profiledetails__rowedit--data"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  name="openingdate"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    defaultValue={moment()}
                    // format="YYYY/MM/DD - h:mm A"
                    minuteStep={15}
                    format="YYYY/MM/DD hh:mm A"
                    onChange={(value, dateString) => {
                      setOpeningDate(dateString);
                    }}
                  />
                </Form.Item>
              </div>
            </div>

            {/* bidValidity shows if checkbox true */}
            <Checkbox onChange={onChange} checked={bidChecked}>
              Bid Validity
            </Checkbox>
            {bidChecked && (
              <>
                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">
                      Bid Validity Till
                    </span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <Form.Item
                      name="bidValidityDate"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <DatePicker size="small" format="YYYY/MM/DD" />
                    </Form.Item>
                  </div>
                </div>
                <div
                  className="profiledetails__rowedit "
                  style={{ margin: "5px 0px 5px 0px" }}
                >
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">
                      Bid Security Value
                    </span>
                  </div>
                  <div
                    className="profiledetails__rowedit--data"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      className="contactsDetails--subtitle bold"
                      style={{ width: "80px" }}
                    >
                      <Form.Item
                        name="bidSecurityValueCurrencyId"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        {!isEmpty(allCurrencyListingResponse) && (
                          <Select
                            size="small"
                            defaultValue={() => {
                              let value = "";
                              allCurrencyListingResponse?.map((currency) => {
                                if (currency.abbvr == "USD") {
                                  value = currency.currencyId;
                                }
                              });

                              return value;
                            }}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {allCurrencyListingResponse?.map((currency) => (
                              <Option
                                key={currency.currencyId}
                                value={currency.currencyId}
                              >
                                {currency.abbvr}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </span>
                    <span
                      className="contactsDetails--subtitle bold"
                      style={{ width: "calc(100% - 80px)" }}
                    >
                      <Form.Item
                        name="bidSecurityValue"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input
                          size="small"
                          autoComplete="off"
                          onChange={numberCommaReplace}
                        />
                      </Form.Item>
                    </span>
                  </div>
                </div>

                <div className="profiledetails__rowedit">
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">
                      Bid Security Validity Till
                    </span>
                  </div>
                  <div className="profiledetails__rowedit--data">
                    <Form.Item
                      name="bidSecurityValueDate"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <DatePicker size="small" format="YYYY/MM/DD" />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}

            {dynamicTenderDocumentFileTypesState.map((u, i) => (
              <div
                key={u.id}
                className="profiledetails__rowedit"
                style={{ margin: "3px 0px 0px 0px" }}
              >
                <div className="profiledetails__rowedit--label">
                  <span className="profile__bodyTitle">Official Documents</span>
                </div>
                <div className="profiledetails__rowedit--data stage_form_to_Straight">
                  <Form.Item
                    name={[`${u.name}FileDtos`]}
                    className="form__group"
                    rules={[{ required: false }]}
                  >
                    <DynamicImageUploader
                      name={u.name}
                      isMultipleFileAllowed={u.isMultipleFileAllowed}
                      id={u.id}
                      allowedExtensions={u.allowedExtensions}
                      // fileList={fileList}
                      // setFileList={setFileList}
                      index={i}
                      form={singleStageForm}
                      callback={(e) => {
                        let olduploadedFileDtos =
                          singleStageForm.getFieldValue()[`${u.name}FileDtos`]
                            ? [
                                ...singleStageForm.getFieldValue()[
                                  `${u.name}FileDtos`
                                ],
                              ]
                            : [];

                        let uploadedFileDtos = [...olduploadedFileDtos];
                        uploadedFileDtos.push(e);

                        singleStageForm.setFieldsValue({
                          [`${u.name}FileDtos`]: uploadedFileDtos,
                        });

                        // form.setFieldsValue({
                        //   uploadedFileDtos: [
                        //     ...olduploadedFileDtos,setAdDocs
                        //     e
                        //   ]
                        //     })
                        setTenderDocumentFile(uploadedFileDtos);
                        setAdDocs = { setAdDocs };
                        adDocs = { adDocs };
                      }}
                    />

                    {/* {singleofficialDoc?.["Tender Official Document"]?.length >
                      0 && (
                      <Card style={{ marginTop: "10px", marginLeft: "10px" }}>
                        <Table
                          columns={docColumns}
                          dataSource={singleofficialDoc?.[
                            "Tender Official Document"
                          ]?.map((x, idx) => {
                            return {
                              ...x,
                              sn: idx + 1,
                            };
                          })}
                          pagination={false}
                          height={50}
                        />
                      </Card>
                    )} */}

                    {offDocs?.length > 0 && (
                      <Card style={{ marginTop: "10px", marginLeft: "10px" }}>
                        <Table
                          columns={docColumns}
                          dataSource={offDocs?.map((x, idx) => {
                            return {
                              ...x,
                              sn: idx + 1,
                            };
                          })}
                          pagination={false}
                          height={50}
                        />
                      </Card>
                    )}
                  </Form.Item>
                </div>
              </div>
            ))}
          </div>
          {getDaysDifference(showParticipation) <= 0 && (
            <ROTO
              dynamicROTOFileTypesState={dynamicROTOFileTypesState}
              singleStageForm={singleStageForm}
              showParticipation={showParticipation}
              setTenderStageROTOFileList={setTenderStageROTOFileList}
              rotoFiles={
                // getDetails?.tenderStageLevelDtos?.[0]?.tenderStageROTOFileList
                fetchData.tenderStageROTOFileList
              }
              token={token}
            />
          )}

          {!isEmpty(popoverMenuVisible) && popoverMenuVisible[index] === "true"
            ? addUpdate(index)
            : genExtra(data, index)}
        </Form>
      ) : (
        ""
      )}
    </div>
  );
};

export default BasicDetails;
