import { ADMIN_TYPES } from "../../../types";

const INITIAL_STATE = {
    meetingStatusListing: [],
    meetingStatusListingLoading: false,
};

export default function meetingStatusReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Meeting Status Lisiting
        case ADMIN_TYPES.MEETING_STATUS_LISTING_INIT:
            return {
                ...state,
                meetingStatusListing: [],
                meetingStatusListingLoading: true,
            };
        case ADMIN_TYPES.MEETING_STATUS_LISTING_SUCCESS:
            return {
                ...state,
                meetingStatusListing: action.payload,
                meetingStatusListingLoading: false,
            };
        case ADMIN_TYPES.MEETING_STATUS_LISTING_FINISH:
            return {
                ...state,
                meetingStatusListingLoading: false,
            };
        default:
            return { ...state };
    }
}
