import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Tag } from "antd";
import { LogoImage } from "utils/imageConstants";
import { organizerEventPriorityPagination } from "services/redux/organizer/organizer.action";
import TinyColor2 from "tinycolor2";

const CityAddUpdateTable = ({
  showAddUpdateCountryDrawer,
  showUpdateProfileDrawer,
}) => {
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width:"5%",
    
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Color Hex Value",
      key: "colorHexValue",
      render: (record) => (
        <Tag color={record.colorHexValue}>
          <span
            style={{
              color: TinyColor2(record.colorHexValue).isLight()
                ? "black"
                : "white",
            }}>
            {record.colorHexValue}
          </span>
        </Tag>
      ),
    },
    {
      title: "Color Font Hex Value",
      key: "colorFontHexValue",
      render: (record) => (
        <Tag color={record.colorFontHexValue}>
          <span
            style={{
              color: TinyColor2(record.colorFontHexValue).isLight()
                ? "black"
                : "white",
            }}>
            {record.colorFontHexValue}
          </span>
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a
          className='action-btn action-btn-add'
          onClick={(e) => showAddUpdateCountryDrawer(e, record)}>
          Edit Organizer Priority Label
        </a>
      ),
    },
  ];
  
  const dispatch = useDispatch();
  const {
    organizerEventPriorityPaginationResp,
    organizerEventPriorityPaginationLoading,
  } = useSelector((state) => state.organizer);

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!organizerEventPriorityPaginationResp) return;
    setdata(organizerEventPriorityPaginationResp.items);
    setTotalCount(organizerEventPriorityPaginationResp.totalCount);
  }, [organizerEventPriorityPaginationResp]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType
  ) => {
    dispatch(
      organizerEventPriorityPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
      })
    );
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };

  return (
    <Table
      className="contact-table"
      columns={columns}
      rowKey="appUserId"
      dataSource={data}
      onChange={handleTableChange}
      // loading={organizerEventPriorityPaginationLoading}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" />
        ),
        spinning: organizerEventPriorityPaginationLoading,
      }}
      pagination={{
        total: totalCount,
        pageSize: pageSize,
      }}
    />
  );
};

export default CityAddUpdateTable;
