import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import BankStatusCEMAT from "views/Home/BankStatusCEMAT";
import DailyReport from "views/Home/Financial/DailyReport";
import FinanceInfoHeader from "views/Home/Financial/FinanceInfo";

const CematBox = () => {
  const {
    dashboardBudgetIncomeExpenseResponse,
    dashboardBudgetIncomeExpenseResponseLoading,
  } = useSelector((state) => state.fiscalYearState);

  return (
    <>
      <Col span={24}>
        <FinanceInfoHeader title="CEMAT information" />
      </Col>

      <Col lg={9} xs={24}>
        <DailyReport
          dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
          dashboardBudgetIncomeExpenseResponseLoading={
            dashboardBudgetIncomeExpenseResponseLoading
          }
          type="Cemat"
        />
      </Col>
      <Col lg={15} xs={24}>
        <Row gutter={[20, 0]}>
          <Col lg={24} xs={24}>
            <BankStatusCEMAT
              dashboardBudgetIncomeExpenseResponse={
                dashboardBudgetIncomeExpenseResponse
              }
              type="CEMAT"
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default CematBox;
