import React, { useState, useEffect } from "react";
import {
  Collapse,
  Form,
  DatePicker,
  TimePicker,
  Button,
  Row,
  Col,
  Checkbox,
  Select,
  Input,
  message,
  InputNumber,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { sectorListing, qualificationListing } from "services/remanentCalls";
const { Panel } = Collapse;

const Expert = ({ showExpertModule, setShowExpertModule }) => {
  const [qualificationData, setQualificationData] = useState([]);

  const [sectors, setSectors] = useState([]);
  const { Option } = Select;
  const { TextArea } = Input;
  const addNewForm = (add) => {
    add();
  };

  const addNewSector = (add) => {
    add();
  };

  useEffect(() => {
    fetchAllSector();
    getQualificationListing();
  }, []);

  const fetchAllSector = async () => {
    const resp = await sectorListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setSectors(resp.data);
    }
  };

  const getQualificationListing = async () => {
    const resp = await qualificationListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setQualificationData(resp.data);
    }
  };

  const expertModule = (e) => {
    if (e.target.checked === true) {
      setShowExpertModule(true);
    } else {
      setShowExpertModule(false);
    }
  };

  return (
    <>
      <div className="expert-header-wrapper">
        <div className="company-person-label-header-wrapper">
          <span className="company-person-label-header">
            Expert
          </span>
          <div className="expert-checkbox-wrapper">
            <Checkbox checked={showExpertModule} onChange={expertModule} style={{ marginLeft: 9 }} />
          </div>
        </div>
      </div>
      {showExpertModule && (
        <div className="expert-panel-wrapper mt-10">
          <Collapse
            expandIconPosition="right"
            defaultActiveKey={["1"]}
            accordion={false}
          >
            <Panel key="1" header="Expert Details">
              <div className="global-form-flex">
                <div className="global-form-group calc">
                  <div className="global-form-label">
                    <label>Sector</label>
                  </div>
                  <div className="global-form-input">
                    <Form.Item
                      name="sectorId"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={trigger => trigger.parentNode}
                      >
                        {sectors.map((data) => (
                          <Option key={data.id} value={data.id}>
                            {data.sectorName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="global-form-group calc">
                  <div className="global-form-label">
                    <label>Major Subject</label>
                  </div>
                  <div className="global-form-input">
                    <Form.Item
                      name="majorSubject"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input size="small" />
                    </Form.Item>
                  </div>
                </div>
                <div className="global-form-group calc">
                  <div className="global-form-label">
                    <label>Year of Experience</label>
                  </div>
                  <div className="global-form-input">
                    <Form.Item
                      name="yearOfExperience"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <InputNumber size="small" autoComplete="off" />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Panel>
            <Panel key="2" header="Education">
              <div className="company-person-label-field-wrapper">
                <Form.List name="expertEducationDtos">
                  {(fields, { add, remove }) => (
                    <div className="global-form-flex" style={{ padding: 0 }}>
                      {fields.map((field, fieldIndex) => (
                        <React.Fragment key={field.key}>
                          <div className="global-form-group expert-group calc">
                            <div className="global-form-group calc">
                              <div className="global-form-label">
                                <label>
                                  Qualification
                                </label>
                              </div>
                              <div className="global-form-input">
                                <Form.Item
                                  name={[field.name, "qualificationId"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "qualificationId",
                                  ]}
                                  rules={[
                                    {
                                      required: false,
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch={true}
                                    allowClear
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >=
                                      0
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                  >
                                    {qualificationData.map((data) => (
                                      <Option
                                        key={data.id}
                                        value={data.id}
                                      >
                                        {data.qualificationName}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>

                            </div>
                            <div className="global-form-group calc">
                              <div className="global-form-label">
                                <label>Major Subject</label>
                              </div>
                              <div className="global-form-input">
                                <Form.Item
                                  name={[field.name, "majorSubject"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "majorSubject",
                                  ]}
                                  rules={[
                                    {
                                      required: false,
                                    },
                                  ]}
                                >
                                  <Input size="small" autoComplete="off" />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="remove-field-wrapper">
                            <MinusCircleOutlined
                              style={{ width: 22, marginTop: 3 }}
                              className="contact__btn-danger"
                              onClick={() => remove(field.name)}
                            />
                          </div>

                        </React.Fragment>
                      ))}
                      <div className="global-form-group calc expert-plus-wrapper">
                        <Form.Item>
                          <Button
                            type="dashed"
                            className="reference__btns--add"
                            onClick={() => addNewForm(add)}
                            block
                            icon={<PlusCircleOutlined />}
                          >
                            Add New
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  )}
                </Form.List>
              </div>
            </Panel>
            <Panel key="3" header="Sector" expandIconPosition="right">
              <div className="company-person-label-field-wrapper">
                <Form.List name="expertSectorDtos">
                  {(fields, { add, remove }) => (
                    <div className="global-form-flex" style={{ padding: 0 }}>
                      {fields.map((field, fieldIndex) => (
                        <React.Fragment key={field.key}>
                          <div className="global-form-group expert-group calc">
                            <div className="global-form-group calc">
                              <div className="global-form-label">
                                <label>Sector</label>
                              </div>
                              <div className="global-form-input">
                                <Form.Item
                                  name={[field.name, "sectorId"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "sectorId",
                                  ]}
                                  rules={[
                                    {
                                      required: false,
                                    },
                                  ]}
                                >
                                  <Select
                                    autoComplete="off"
                                    showSearch={true}
                                    allowClear
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >=
                                      0
                                    }
                                    getPopupContainer={trigger => trigger.parentNode}
                                  >
                                    {sectors.map((data) => (
                                      <Option
                                        key={data.id}
                                        value={data.id}
                                      >
                                        {data.sectorName}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="global-form-group calc">
                              <div className="global-form-label">
                                <label> Profession</label>
                              </div>
                              <div className="global-form-input">
                                <Form.Item
                                  name={[
                                    field.name,
                                    "expertProfession",
                                  ]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "expertProfession",
                                  ]}
                                  rules={[
                                    {
                                      required: false,
                                    },
                                  ]}
                                >
                                  <Input size="small" autoComplete="off" />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="remove-field-wrapper">
                            <MinusCircleOutlined
                              style={{ width: 22, marginTop: 3 }}
                              className="contact__btn-danger"
                              onClick={() => remove(field.name)}
                            />
                          </div>
                        </React.Fragment>
                      ))}
                      <div className="global-form-group calc expert-plus-wrapper">
                        <Form.Item>
                          <Button
                            type="dashed"
                            className="reference__btns--add"
                            onClick={() => addNewSector(add)}
                            block
                            icon={<PlusCircleOutlined />}
                          >
                            Add New
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  )}
                </Form.List>
              </div>
            </Panel>
            <Panel
              key="4"
              header="Work Experience"
              expandIconPosition="right"
              style={{ paddingBottom: 10 }}
            >
              <div className="company-person-label-field-wrapper">
                <Form.List name="contactWorkExperienceDtos">
                  {(fields, { add, remove }) => (
                    <div className="global-form-flex" style={{ padding: 0 }}>
                      {fields.map((field, fieldIndex) => (
                        <React.Fragment key={field.key}>
                          <div className="global-form-group expert-group calc">
                            <div className="global-form-group calc">
                              <div className="global-form-label">
                                <label>Position</label>
                              </div>
                              <div className="global-form-input">
                                <Form.Item
                                  name={[field.name, "position"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "position",
                                  ]}
                                  rules={[
                                    {
                                      required: false,
                                    },
                                  ]}
                                >
                                  <Input
                                    size="small"
                                    placeholder="e.g Ceo"
                                    autoComplete="off"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="global-form-group calc">
                              <div className="global-form-label">
                                <label>Starting Date</label>
                              </div>
                              <div className="global-form-input">
                                <Form.Item
                                  name={[field.name, "startingDate"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "startingDate",
                                  ]}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    getPopupContainer={trigger => trigger.parentNode}
                                    size="small"
                                    format="YYYY/MM/DD"
                                    placeholder="DD/MM/YYYY"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="global-form-group calc">
                              <div className="global-form-label"></div>
                              <div className="global-form-input">
                                <Form.Item
                                  valuePropName="checked"
                                  name={[field.name, "isWorking"]}
                                  fieldKey={[field.fieldKey, "isWorking"]}
                                >
                                  <span className="profile__bodyTitle">
                                    <Checkbox style={{ padding: 2 }} />
                                    Currently i am working in this role
                                  </span>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="global-form-group calc">
                              <div className="global-form-label">
                                <label>Ending Date</label>
                              </div>
                              <div className="global-form-input">
                                <Form.Item
                                  name={[field.name, "endingDate"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "endingDate",
                                  ]}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    size="small"
                                    format="YYYY/MM/DD"
                                    placeholder="DD/MM/YYYY"
                                    autoComplete="off"
                                    getPopupContainer={trigger => trigger.parentNode}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="global-form-group calc">
                              <div className="global-form-label">
                                <label>Responsibilities</label>
                              </div>
                              <div className="global-form-input" style={{marginTop:5}}>
                                <Form.Item
                                  name={[field.name, "responsibility"]}
                                  fieldKey={[
                                    field.fieldKey,
                                    "responsibility",
                                  ]}
                                  rules={[
                                    {
                                      required: false,
                                    },
                                  ]}
                                >
                                  <TextArea
                                    autoComplete="off"
                                    placeholder="Responsibilities"
                                    style={{ height: "80px" }}
                                    
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="remove-field-wrapper">
                            <MinusCircleOutlined
                              style={{ width: 22, marginTop: 3 }}
                              className="contact__btn-danger"
                              onClick={() => remove(field.name)}
                            />
                          </div>
                        </React.Fragment>
                      ))}
                      <div className="global-form-group calc expert-plus-wrapper">
                        <Form.Item>
                          <Button
                            type="dashed"
                            className="reference__btns--add"
                            onClick={() => addNewSector(add)}
                            block
                            icon={<PlusCircleOutlined />}
                          >
                            Add experience
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  )}
                </Form.List>
              </div>
            </Panel>
          </Collapse>
        </div>
      )}
    </>

  );
};
export default Expert;
