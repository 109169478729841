import { APPLICATION_CONFIGURATION } from "../../../types";
import { init, success, finish, error } from "services/common";
import { ApplicationConfigurationService } from "./api";

const applicationConfigurationService = new ApplicationConfigurationService();

export const getApplicationConfiguration = () => {
  return async (dispatch) => {
    dispatch(init(APPLICATION_CONFIGURATION.GET_APPLICATION_CONFIGURATION));
    const response = await applicationConfigurationService.getApplicationConfiguration();
    dispatch(finish(APPLICATION_CONFIGURATION.GET_APPLICATION_CONFIGURATION));
    if (response.isSuccess) {
      dispatch(success(APPLICATION_CONFIGURATION.GET_APPLICATION_CONFIGURATION, { auth: response?.data?.auth || {}, currentUser: response?.data?.currentUser || {} }));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};