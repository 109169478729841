import { COMPLAINT_TYPES } from "../../types";
import { uniqBy } from "lodash";

const INITIAL_STATE = {
  complaintPaginationResponse: {
    items:[]
  },
  complaintPaginationResponseLoading: false,


  complainCounterListResponse: {
    items:[]
  },
  // complainCounterListResponseLoading:false,

  //addComplaint
  complaintAddResponse: null,
  complaintAddResponseLoading: false,

  //UpdateExtension
  complaintUpdateResponse: null,
  complaintUpdateResponseLoading: false,

  // complain getDetails
  complainGetDetailsResponse: [],
  complainGetDetailsLoading: false,

  //complain status update
  complainStatusUpdateResponse: [],
  complainStatusUpdateRespLoading: false,

};

export default function complaintReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    //Tender Basic
    case COMPLAINT_TYPES.COMPLAINT_PAGINATION_INIT:
      return {
        ...state,
        complaintPaginationResponseLoading: true,
      };

    case COMPLAINT_TYPES.COMPLAINT_PAGINATION_SUCCESS:
      return {
        ...state,
        complaintPaginationResponse: action.payload,
        complaintPaginationResponseLoading: false,
      };

    case COMPLAINT_TYPES.COMPLAINT_PAGINATION_CHANGE_SUCCESS:
      return {
        ...state,
        complaintPaginationResponse: state.complaintPaginationResponse
          ? {
              items: uniqBy(
                state.complaintPaginationResponse?.items.concat(
                  action.payload.items
                ),
                "id"
              ),
              totalCount: action.payload.totalCount,
            }
          : action.payload,
        complaintPaginationResponseLoading: false,
      };

    case COMPLAINT_TYPES.COMPLAINT_PAGINATION_FINISH:
      return {
        ...state,
        complaintPaginationResponseLoading: false,
      };

    case COMPLAINT_TYPES.COMPLAINT_ADD_INIT:
      return {
        ...state,
        complaintAddResponseLoading: true,
        complaintAddResponse: null,
      };

    case COMPLAINT_TYPES.COMPLAINT_ADD_SUCCESS:
      return {
        ...state,
        complaintAddResponse: action.payload,
        complaintAddResponseLoading: false,
        complaintPaginationResponse: {
          items: [action.payload, ...state.complaintPaginationResponse?.items],
          totalCount: state.complaintPaginationResponse.totalCount,
        },
      };

    case COMPLAINT_TYPES.COMPLAINT_ADD_FINISH:
      return {
        ...state,
        complaintAddResponse: null,
        complaintAddResponseLoading: false,
      };

    // Complain Counter 
    case COMPLAINT_TYPES.COMPLAIN_COUNTER_INIT:
      return {
      ...state,
        complainCounterListLoading: true,
      };
    
    case COMPLAINT_TYPES.COMPLAIN_COUNTER_SUCCESS:
        return {
          ...state,
          complainCounterListResponse: action.payload,
          complainCounterListLoading: false,
        };

    case COMPLAINT_TYPES.COMPLAIN_COUNTER_FINISH:
      return {
        ...state,
        complainCounterListResponse: null,
        complainCounterListLoading: false,
      } 


    // complaint update
    case COMPLAINT_TYPES.COMPLAINT_UPDATE_INIT:
      return {
        ...state,
        complaintUpdateResponseLoading: true,
        complaintUpdateResponse: null,
      };

    case COMPLAINT_TYPES.COMPLAINT_UPDATE_SUCCESS:
      // console.log("actionpayload", action.payload);
      return {
        ...state,
        complaintUpdateResponse: action.payload,
        complaintUpdateResponseLoading: false,
        complaintPaginationResponse: {
          items: state.complaintPaginationResponse?.items?.map((item) => {
            let complaintId = action.payload?.id;
            if (item.id === complaintId) {
              return {
                ...item,
                ...action.payload,
              };
            } else {
              return { ...item };
            }
          }),
          totalCount: state.complaintPaginationResponse?.totalCount,
        },
      };

    case COMPLAINT_TYPES.COMPLAINT_UPDATE_FINISH:
      return {
        ...state,
        complaintUpdateResponse: null,
        complaintUpdateResponseLoading: false,
      };

    // complaint status upadate
    case COMPLAINT_TYPES.COMPLAINT_STATUS_UPDATE_INIT:
      return {
        ...state,

        complainStatusUpdateRespLoading: true,
        complainStatusUpdateResponse: null,
      };

    case COMPLAINT_TYPES.COMPLAINT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        complainStatusUpdateResponse: action.payload,
        complainStatusUpdateRespLoading: false,
      };

    case COMPLAINT_TYPES.COMPLAINT_STATUS_UPDATE_FINISH:
      return {
        ...state,
        complainStatusUpdateResponse: null,
        complainStatusUpdateRespLoading: false,
      };

    // complain getdetails
    case COMPLAINT_TYPES.COMPLAIN_GET_DETAILS_INIT:
      return {
        ...state,
        complainGetDetailsLoading: true,
        complainGetDetailsResponse: null,
      };

    case COMPLAINT_TYPES.COMPLAIN_GET_DETAILS_SUCCESS:
      return {
        ...state,
        complainGetDetailsResponse: action.payload,
        complainGetDetailsLoading: false,
      };
    
    case COMPLAINT_TYPES.ERROR_LOG_INIT:
      return{
        ...state,
        errorLogsDetailsLoading:true,
        errorLogsDetailsResponse:null,
      }
    case COMPLAINT_TYPES.ERROR_LOG_SUCCESS:
      return{
        ...state,
        errorLogsDetailsResponse:action.payload,
        errorLogsDetailsLoading:false,
        
      }

    case COMPLAINT_TYPES.COMPLAIN_GET_DETAILS_FINISH:
      return {
        ...state,
        complainGetDetailsResponse: null,
        complainGetDetailsLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
