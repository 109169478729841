import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { actionForGetTutorialCategoryListing } from "services/redux/admin/Tutorial/action";

function TutorialCategoryTable({ setOpenForm, setUpdateData }) {
  const dispatch = useDispatch();
  const [tutorialCategoryListing, setTutorialCategoryListing] = useState();
  const { getTutorialCategoryListingResp, getTutorialCategoryListingLoading } =
    useSelector((state) => state.tutorialReducer);

  useEffect(() => {
    dispatch(actionForGetTutorialCategoryListing());
  }, []);

  useEffect(() => {
    if (!isEmpty(getTutorialCategoryListingResp)) {
      setTutorialCategoryListing(getTutorialCategoryListingResp);
    }
  }, [getTutorialCategoryListingResp]);

  const col = [
    {
      title: "S.N",
      render: (_, text, index) => index + 1,
      width: "5%",
    },
    {
      title: "Category",
      dataIndex: "typeName",
      width: "80%",
    },
    {
      title: "Action",
      render: (_, record) => (
        <>
          <EditOutlined
            onClick={() => {
              setOpenForm({
                modal: false,
                drawer: true,
                edit: true,
              });
              setUpdateData(record);
            }}
          />
        </>
      ),
    },
  ];
  return (
    <>
      <Table
        className="next-organizer-table"
        style={{ fontSize: "15px" }}
        columns={col}
        dataSource={tutorialCategoryListing}
        loading={getTutorialCategoryListingLoading}
      />
    </>
  );
}

export default TutorialCategoryTable;
