import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService, putService } from "services/commonServices";
import { isEmpty } from "lodash";

export class TutorialService {
  getTutorialListing(q) {
    let url = `${appBaseUrl}/adminModule/tutorialListing`;
    switch (true) {
      case !isEmpty(q?.search) && !isEmpty(q?.type):
        url += `?title=${encodeURIComponent(
          q.search
        )}&category=${encodeURIComponent(q.type)}`;
        break;

      case !isEmpty(q?.search):
        url += `?title=${encodeURIComponent(q.search)}`;
        break;

      case !isEmpty(q?.type):
        url += `?category=${encodeURIComponent(q.type)}`;
        break;

      default:
        break;
    }

    try {
      const data = getService(url);
      return data;
    } catch (error) {
      console.error("Error fetching tutorial listing:", error);
      throw error;
    }
  }
  // getTutorialListing(q) {
  //   let url = `${appBaseUrl}/adminModule/tutorialListing`;
  //   if (q) {
  //     url += `?title=${q}`;
  //   }
  //   let data = getService(url);
  //   return data;
  // }

  createTutorial(body) {
    let url = `${appBaseUrl}/adminModule/tutorialAdd`;
    let data = postService(url, body);
    return data;
  }

  updateTutorial(tutorialId, body) {
    let url = `${appBaseUrl}/adminModule/tutorialUpdate/${tutorialId}`;
    let data = putService(url, body);
    return data;
  }

  // category
  getTutorialCategoryListing() {
    let url = `${appBaseUrl}/adminModule/tutorialTypeListing`;
    let data = getService(url);
    return data;
  }

  createTutorialCategory(body) {
    let url = `${appBaseUrl}/adminModule/tutorialTypesAdd`;
    let data = postService(url, body);
    return data;
  }

  updateTutorialCategory(id, body) {
    let url = `${appBaseUrl}/adminModule/tutorialTypesUpdate/${id}`;
    let data = putService(url, body);
    return data;
  }

  getTutorialCategoryDetails(id) {
    let url = `${appBaseUrl}/adminModule/tutorialTypeGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
}
