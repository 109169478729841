import { ADMIN_TYPES } from "../../../../types";
import { init, success, finish, error } from "services/common";
import { LeaveApplicationTypeService } from "./api";
import { message } from "antd";

const leaveApplicationTypeService = new LeaveApplicationTypeService();

export const getLeaveApplicationTypePagination = (query) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.LEAVE_APPLICATION_TYPE));
    const response = await leaveApplicationTypeService.getLeaveApplicationTypePagination(query);
    dispatch(finish(ADMIN_TYPES.LEAVE_APPLICATION_TYPE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.LEAVE_APPLICATION_TYPE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const leaveApplicationTypeAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.LEAVE_APPLICATION_TYPE_ADD));
    const response = await leaveApplicationTypeService.leaveApplicationTypeAdd(body);
    dispatch(finish(ADMIN_TYPES.LEAVE_APPLICATION_TYPE_ADD));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.LEAVE_APPLICATION_TYPE_ADD, response.data));
      dispatch(getLeaveApplicationTypePagination(`maxResultCount=10&skipCount=0`));
      message.success(`Leave Application Type Added Successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const leaveApplicationTypeUpdate = (body, id) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.LEAVE_APPLICATION_TYPE_UPDATE));
    const response = await leaveApplicationTypeService.leaveApplicationTypeUpdate(body, id);
    dispatch(finish(ADMIN_TYPES.LEAVE_APPLICATION_TYPE_UPDATE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.LEAVE_APPLICATION_TYPE_UPDATE, response.data));
      dispatch(getLeaveApplicationTypePagination(`maxResultCount=10&skipCount=0`));
      message.success(`Leave Application Type Updated Successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};