import React, { useState, useEffect } from "react";

// antd
import { Drawer, Form, Button, Checkbox, Input, Layout } from "antd";

// utils
import { validateMessages } from "utils/misc";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  addNewRole,
  updateRole,
  actionForNewUpdateRole,
} from "services/redux/adminSettings/roles/action";

const { Header, Footer } = Layout;
const { TextArea } = Input;

const EditRoleDrawer = ({
  onAddUpdateRoleClose,
  drawerVisibility,
  roleDetails,
}) => {
  const { addNewRoleResp, updateRoleResp } = useSelector(
    (state) => state.roleLists
  );

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!roleDetails) {
      form.resetFields();
      return;
    }
    form.setFieldsValue({
      ...roleDetails,
    });
  }, [roleDetails]);

  useEffect(() => {
    onAddUpdateRoleClose();
  }, [addNewRoleResp, updateRoleResp]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (roleDetails) {
        delete values?.isDefault;
        delete values?.isPublic;

        let data = {
          roleId: roleDetails?.id,
          ...values,
        };
        // dispatch(updateRole(values, roleDetails.id));
        dispatch(actionForNewUpdateRole(data, roleDetails.id));
      } else {
        dispatch(addNewRole(values));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <>
      <Drawer
        visible={drawerVisibility}
        width={472}
        maskClosable={false}
        className="drawer"
        onClose={onAddUpdateRoleClose}
        closable={false}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">
            {`${roleDetails ? "Update" : "Create"} Role`}
          </h4>
        </Header>
        <Form
          form={form}
          name="control-hooks"
          className="drawer-form-wrapper"
          validateMessages={validateMessages}
        >
          <div className="drawer-form-row">
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="name"
                label="Code"
                rules={[{ required: true }]}
              >
                {roleDetails ? (
                  <Input autoComplete="off" disabled />
                ) : (
                  <Input autoComplete="off" />
                )}
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="code"
                label="Name"
                rules={[{ required: true }]}
              >
                <Input autoComplete="off" />
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="description"
                label="Description"
              // rules={[{ required: true }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                name="isDefault"
                valuePropName="checked"
                className="form__group"
                initialValue={false}
              >
                <Checkbox>Default</Checkbox>
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                name="isPublic"
                valuePropName="checked"
                className="form__group"
                initialValue={true}
              >
                <Checkbox>Public</Checkbox>
              </Form.Item>
            </div>
          </div>
          <div className="model-footer">
            <Button onClick={onAddUpdateRoleClose}>Cancel</Button>
            <Button onClick={onSubmit} type="primary">
              Submit
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default EditRoleDrawer;
