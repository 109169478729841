import { ProjectService } from "./api";
import { REFERENCE__TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
import queryString from "query-string";

const projectService = new ProjectService();

export const getProjectSearchPagination = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.PROJECT_PAGINATION));
    const response = await projectService.projectPagination(query);
    dispatch(finish(REFERENCE__TYPES.PROJECT_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.PROJECT_PAGINATION, response.data, loadMore)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getDashboardProjects = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.PROJECT_PAGINATION_DASHBOARD));
    const response = await projectService.projectPaginationDashboard(query);
    dispatch(finish(REFERENCE__TYPES.PROJECT_PAGINATION_DASHBOARD));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.PROJECT_PAGINATION_DASHBOARD,
          response.data,
          loadMore
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const projectGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.projectGetDetails));
    const response = await projectService.projectGetDetails(id);
    dispatch(finish(REFERENCE__TYPES.projectGetDetails));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.projectGetDetails, response.data));
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const projectAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.PROJECT_ADD));
    const response = await projectService.projectAdd(body);
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.PROJECT_ADD, response.data));
      message.success("Successfully added Project");
      dispatch(getProjectSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.PROJECT_ADD));
  };
};

export const projectUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.PROJECT_UPDATE));
    const response = await projectService.projectUpdate(body);
    dispatch(finish(REFERENCE__TYPES.PROJECT_UPDATE));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.PROJECT_UPDATE, response.data));
      message.success(`Successfully updated Project`);
      dispatch(getProjectSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const projectFavAddUpdate = (body, record) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.PROJECT_IS_FAV));
    const response = await projectService.projectFavAddUpdate(body);
    dispatch(finish(REFERENCE__TYPES.PROJECT_IS_FAV));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.PROJECT_IS_FAV, response.data, "", record)
      );
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const noteProjectAdd = (id, body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.NOTE_PROJECT_ADD));
    const response = await projectService.noteProjectAdd(id, body);
    if (response?.isSuccess) {
      dispatch(success(REFERENCE__TYPES.NOTE_PROJECT_ADD, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.NOTE_PROJECT_ADD));
  };
};
