import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import AddUserDrawer from "./AddUserDrawer";
import ContactLabelGroupTable from "./ContactLabelGroupTable";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import AddUpdateCountryDrawer from "./AddUpdateCountryDrawer";

const AddUpdateCountry = () => {
  const dispatch = useDispatch();

  const [addUpdateCountryDrawerVisibility, setAddUpdateCountryDrawerVisibility] = useState(false);
  const [
    updateProfileDrawerVisibility,
    setUpdateProfileDrawerVisibility,
  ] = useState(false);
  const [isUpdateCountry, setIsUpdateCountry] = useState(false);

  const showAddUpdateCountryDrawer = (e, record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };

  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry('');
    setAddUpdateCountryDrawerVisibility(false);
  };

  return (
    <div className='layout'>
      <div className="organizer events">
            <div className="all-header-wrapper">
              <div className="meeting__flex">
                  <h3 className="header__all">Contact Label Group List</h3>
                  <div className="organizerHoliday__middle--wrap">
                    <Button
                      className='btn-primary'
                      onClick={showAddUpdateCountryDrawer}>
                      <PlusOutlined /> New Label Group
                    </Button>
                  </div>                
              </div>
           </div>
          
        <ContactLabelGroupTable
          showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
        />
        <AddUpdateCountryDrawer
          drawerVisibility={addUpdateCountryDrawerVisibility}
          onAddUpdateCountryClose={onAddUpdateCountryClose}
          isUpdateCountry={isUpdateCountry}
        />
      </div>
    </div>
  );
};

export default AddUpdateCountry;
