import { COMMON_TYPES } from "../../../types";

const INITIAL_STATE = {
    employeeSisterCompanyListing: [],
    employeeSisterCompanyListingLoading: false,

    // addGoalEventResp: [],
    // addGoalEventRespLoading: false,

    // guestEventStatuses: [],
    // guestEventStatusesLoading: false,
};

export default function employeeSisterCompanyReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Goal events
        case COMMON_TYPES.EMPLOYEE_SISTER_COMPANY_LISTING_INIT:
            return {
                ...state,
                employeeSisterCompanyListing: [],
                employeeSisterCompanyListingLoading: true,
            };
        case COMMON_TYPES.EMPLOYEE_SISTER_COMPANY_LISTING_SUCCESS:
            return {
                ...state,
                employeeSisterCompanyListing: action.payload,
                employeeSisterCompanyListingLoading: false,
            };
        case COMMON_TYPES.EMPLOYEE_SISTER_COMPANY_LISTING_FINISH:
            return {
                ...state,
                employeeSisterCompanyListingLoading: false,
            };

        // Goal events add
        // case COMMON_TYPES.ADD_GOAL_EVENTS_INIT:
        //     return {
        //         ...state,
        //         addGoalEventResp: [],
        //         addGoalEventRespLoading: true,
        //     };
        // case COMMON_TYPES.ADD_GOAL_EVENTS_SUCCESS:
        //     return {
        //         ...state,
        //         addGoalEventResp: action.payload,
        //         addGoalEventRespLoading: false,
        //     };
        // case COMMON_TYPES.ADD_GOAL_EVENTS_FINISH:
        //     return {
        //         ...state,
        //         addGoalEventRespLoading: false,
        //     };
        default:
            return { ...state };
    }
}
