import moment from "moment";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Layout,
  Row,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";

const { Header } = Layout;

const CustomRow = ({ label, value }) => {
  return (
    <Row>
      <Col span={8}>
        <Typography style={{ marginBottom: 4 }}>{label}</Typography>
      </Col>
      <Col span={16}>
        <Form.Item>
          <Input autoComplete="off" type="text" readOnly value={value} />
        </Form.Item>
      </Col>
    </Row>
  );
};

const CustomComment = ({ label, value }) => {
  return (
    <Layout.Content
      style={{
        backgroundColor: "#FDFDFD",
        marginTop: 5,
      }}
    >
      <Form.Item label={label}>
        <TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          autoComplete="off"
          value={value}
          readOnly
        />
      </Form.Item>
    </Layout.Content>
  );
};

const GuestEntertainmentDetails = ({
  guestEntertainmentDetails,
  setHideGuestEntertainmentDrawer,
  drawerVisibility,
  setGuestEntertainmentDetails,
}) => {
  const [form] = useForm();

  console.log("guestEntertainmentDetails", guestEntertainmentDetails);

  const supervisorName =
    guestEntertainmentDetails[0]?.guestEntertainmentParticipantDetails
      ?.filter(
        (x) =>
          x?.approveRejectRemarks != null &&
          x?.approveRejectRemarks !== undefined &&
          x?.participantTypeName === "Supervisor"
      )
      ?.map((x) => x?.participantDetails?.fullName);

  const supervisorDescription =
    guestEntertainmentDetails[0]?.guestEntertainmentParticipantDetails
      ?.filter(
        (x) =>
          x?.approveRejectRemarks !== null &&
          x?.approveRejectRemarks !== undefined &&
          x?.participantTypeName === "Supervisor"
      )
      ?.map((x) => x?.approveRejectRemarks);

  const accountantName =
    guestEntertainmentDetails[0]?.guestEntertainmentParticipantDetails

      ?.filter(
        (x) =>
          x?.approveRejectRemarks != null &&
          x?.approveRejectRemarks !== undefined &&
          x?.participantTypeName === "Accountant"
      )
      ?.map((x) => x?.participantDetails?.fullName);

  const accountantDescription =
    guestEntertainmentDetails[0]?.guestEntertainmentParticipantDetails

      ?.filter(
        (x) =>
          x?.approveRejectRemarks !== null &&
          x?.approveRejectRemarks !== undefined &&
          x?.participantTypeName === "Accountant"
      )
      ?.map((x) => x?.approveRejectRemarks);

  const managementName =
    guestEntertainmentDetails[0]?.guestEntertainmentParticipantDetails
      ?.filter(
        (x) =>
          x?.approveRejectRemarks != null &&
          x?.approveRejectRemarks !== undefined &&
          x?.participantTypeName === "Management"
      )
      ?.map((x) => x?.participantDetails?.fullName);

  const managementDescription =
    guestEntertainmentDetails[0]?.guestEntertainmentParticipantDetails
      ?.filter(
        (x) =>
          x?.approveRejectRemarks !== null &&
          x?.approveRejectRemarks !== undefined &&
          x?.participantTypeName === "Management"
      )
      ?.map((x) => x?.approveRejectRemarks);

  const totalTimesFoodAmount =
    guestEntertainmentDetails[0]?.approvedFoodtimes *
    guestEntertainmentDetails[0]?.managementFoodAmountApprovedDetails
      ?.ratingAmount;

  const totalTimesDrinkAmount =
    guestEntertainmentDetails[0]?.approvedDrinktimes *
    guestEntertainmentDetails[0]?.managementDrinkAmountApprovedDetails
      ?.ratingAmount;

  return (
    <>
      <Drawer
        width={460}
        maskClosable={false}
        onClose={setHideGuestEntertainmentDrawer}
        visible={drawerVisibility}
        closable={false}
        footer={
          <Layout.Content
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => {
                setHideGuestEntertainmentDrawer(false);
              }}
            >
              Cancel
            </Button>
          </Layout.Content>
        }
      >
        <Header className="drawer__header">
          <Typography className="drawer__header--title">
            Guest Entertainment Details
          </Typography>
        </Header>
        <Form layout="vertical" form={form}>
          <>
            <Layout.Content
              style={{
                backgroundColor: "#FAFAFA",
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
              }}
            >
              <CustomRow
                label="Company Name"
                value={guestEntertainmentDetails[0]?.sisterCompanyName}
              />
              <CustomRow
                label="Arrival Time"
                value={moment(
                  guestEntertainmentDetails[0]?.applicationDate
                ).format("YYYY/MM/DD")}
              />
              <CustomRow
                label="Departure Time"
                value={moment(
                  guestEntertainmentDetails[0]?.applicationDate
                ).format("YYYY/MM/DD")}
              />
              <CustomRow
                label="Subject"
                value={guestEntertainmentDetails[0]?.subject}
              />
              <CustomRow
                label="Guests Name"
                value={guestEntertainmentDetails[0]?.guestNames}
              />
              <CustomRow
                label="Application Status"
                value={
                  guestEntertainmentDetails[0]?.currentGuestEntertainmentStatus
                }
              />
              <Layout.Content>
                <Row>
                  <Col span={6}>
                    <Typography style={{ marginBottom: 4 }}>
                      Category
                    </Typography>
                  </Col>
                  <Col span={6}>
                    <Typography style={{ marginBottom: 4, textAlign: "end" }}>
                      Requested
                    </Typography>
                  </Col>
                  <Col span={6}>
                    <Typography style={{ marginBottom: 4, textAlign: "end" }}>
                      Approved
                    </Typography>
                  </Col>
                  <Col span={6}>
                    <Typography style={{ marginBottom: 4, textAlign: "end" }}>
                      Total Amount
                    </Typography>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Typography style={{ marginBottom: 4 }}>
                      No. of Guests
                    </Typography>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        autoComplete="off"
                        placeholder="0"
                        readOnly
                        value={guestEntertainmentDetails[0]?.applyNumberOfGuest}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        autoComplete="off"
                        placeholder="0"
                        readOnly
                        value={
                          guestEntertainmentDetails[0]?.approvedNumberOfGuest
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Typography style={{ marginBottom: 4 }}>
                      Food Times
                    </Typography>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        autoComplete="off"
                        placeholder="0"
                        readOnly
                        value={guestEntertainmentDetails[0]?.applyFoodtimes}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        autoComplete="off"
                        placeholder="0"
                        readOnly
                        value={guestEntertainmentDetails[0]?.approvedFoodtimes}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        autoComplete="off"
                        placeholder="0"
                        readOnly
                        value={totalTimesFoodAmount}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Typography style={{ marginBottom: 4 }}>
                      Drink Times
                    </Typography>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        autoComplete="off"
                        placeholder="0"
                        readOnly
                        value={guestEntertainmentDetails[0]?.applyDrinktimes}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        autoComplete="off"
                        placeholder="0"
                        readOnly
                        value={guestEntertainmentDetails[0]?.approvedDrinktimes}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        autoComplete="off"
                        placeholder="0"
                        readOnly
                        value={totalTimesDrinkAmount}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <Typography style={{ marginBottom: 4 }}>
                      Overall Total
                    </Typography>
                  </Col>
                  <Col span={18}>
                    <Form.Item>
                      <Input
                        autoComplete="off"
                        placeholder="0"
                        readOnly
                        value={totalTimesFoodAmount + totalTimesDrinkAmount}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Layout.Content>
              {guestEntertainmentDetails[0]?.currentGuestEntertainmentStatus ===
                "SupervisorApproved" && (
                <CustomComment
                  label={`Supervisor's Comment (${supervisorName})`}
                  value={supervisorDescription}
                />
              )}
              {guestEntertainmentDetails[0]?.currentGuestEntertainmentStatus ===
                "ManagementApproved" && (
                <>
                  <CustomComment
                    label={`Supervisor's Comment (${supervisorName})`}
                    value={supervisorDescription}
                  />
                  <CustomComment
                    label={`Management's Comment (${managementName})`}
                    value={managementDescription}
                  />
                </>
              )}
              {guestEntertainmentDetails[0]?.currentGuestEntertainmentStatus ===
                "AccountantApproved" && (
                <>
                  <CustomComment
                    label={`Supervisor's Comment (${supervisorName})`}
                    value={supervisorDescription}
                  />
                  <CustomComment
                    label={`Management's Comment (${managementName})`}
                    value={managementDescription}
                  />
                  <CustomComment
                    label={`Accountant's Comment (${accountantName})`}
                    value={accountantDescription}
                  />
                </>
              )}
            </Layout.Content>
          </>
        </Form>
      </Drawer>
    </>
  );
};
export default GuestEntertainmentDetails;
