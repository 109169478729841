import { uniqBy } from "lodash";
import { ORGANIZER_TYPES } from "../../../types";

const INITIAL_STATE = {
  allGoalEvents: [],
  allgoalEventsLoading: false,

  guestEventStatuses: [],
  guestEventStatusesLoading: false,

  // organizer task event add
  organizerTaskEventAddResp: [],
  organizerTaskEventAddRespLoading: false,

  // Goal add
  organizerGoalAddResp: [],
  organizerGoalAddRespLoading: false,

  // organizer task event getdetails
  taskEventGetDetailsResp: [],
  taskEventGetDetailsRespLoading: false,

  // organizer task event update
  organizerTaskEventUpdateResp: [],
  organizerTaskEventUpdateRespLoading: false,
};

export default function goalEventsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Goal events
    case ORGANIZER_TYPES.GOAL_EVENTS_INIT:
      return {
        ...state,
        allgoalEventsLoading: true,
      };
    case ORGANIZER_TYPES.GOAL_EVENTS_SUCCESS:
      return {
        ...state,
        allGoalEvents: action.payload,
        allgoalEventsLoading: false,
      };
    case ORGANIZER_TYPES.GOAL_EVENTS_FINISH:
      return {
        ...state,
        allgoalEventsLoading: false,
      };

    case ORGANIZER_TYPES.GOAL_EVENTS_PAGINATION_SUCCESS:
      return {
        ...state,
        allGoalEvents: state.allGoalEvents
          ? {
              items: [...state.allGoalEvents.items, ...action.payload.items],

              totolCount: action.payload.totolCount,
            }
          : action.payload,
      };

    // Goal add
    case ORGANIZER_TYPES.GOAL_EVENT_ADD_INIT:
      return {
        ...state,
        organizerGoalAddRespLoading: true,
      };
    case ORGANIZER_TYPES.GOAL_EVENT_ADD_SUCCESS:
      return {
        ...state,
        organizerGoalAddResp: action.payload,
        allGoalEvents: {
          items: [...state.allGoalEvents.items, action.payload],
          totolCount: state.allGoalEvents.totolCount + 1,
        },
        organizerGoalAddRespLoading: false,
      };
    case ORGANIZER_TYPES.GOAL_EVENT_ADD_FINISH:
      return {
        ...state,
        organizerGoalAddResp: [],
        organizerGoalAddRespLoading: false,
      };

    // organzier task events add
    case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_ADD_INIT:
      return {
        ...state,
        organizerTaskEventAddResp: [],
        organizerTaskEventAddRespLoading: true,
      };
    case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_ADD_SUCCESS:
      return {
        ...state,
        organizerTaskEventAddResp: action.payload,
        organizerTaskEventAddRespLoading: false,
      };
    case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_ADD_FINISH:
      return {
        ...state,
        organizerTaskEventAddResp: [],

        organizerTaskEventAddRespLoading: false,
      };

    // organizer task event getDetails
    case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_GET_DETAILS_INIT:
      return {
        ...state,
        taskEventGetDetailsResp: [],
        taskEventGetDetailsRespLoading: true,
      };
    case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_GET_DETAILS_SUCCESS:
      return {
        ...state,
        taskEventGetDetailsResp: action.payload,
        taskEventGetDetailsRespLoading: false,
      };
    case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_GET_DETAILS_FINISH:
      return {
        ...state,
        taskEventGetDetailsRespLoading: false,
      };

    // organizer task event update
    case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_UPDATE_INIT:
      return {
        ...state,
        organizerTaskEventUpdateResp: [],
        organizerTaskEventUpdateRespLoading: true,
      };
    case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_UPDATE_SUCCESS:
      return {
        ...state,
        organizerTaskEventUpdateResp: action.payload,
        allGoalEvents: {
          items: state.allGoalEvents?.items?.map((item) => {
            let goaliId = action.payload.id;
            if (item.id === goaliId) {
              return {
                ...item,
                ...action.payload,
              };
            } else {
              return { ...item };
            }
          }),
          totalCount: state?.allGoalEvents?.totalCount,
        },
        organizerTaskEventUpdateRespLoading: false,
      };
    case ORGANIZER_TYPES.ORGANIZER_TASK_EVENT_UPDATE_FINISH:
      return {
        ...state,
        organizerTaskEventUpdateResp: [],
        organizerTaskEventUpdateRespLoading: false,
      };

    case ORGANIZER_TYPES.GET_TODAY_TASKS_INIT:
      return {
        ...state,
        allTodayTaskResponses: [],
        allTodayTaskResponsesLoading: true,
      };

    case ORGANIZER_TYPES.GET_TODAY_TASKS_SUCCESS:
      return {
        ...state,
        allTodayTaskResponses: action.payload,
        allTodayTaskResponsesLoading: false,
      };

    default:
      return { ...state };
  }
}
