import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form, Button, Col, Row, Input, Select, Layout, message } from "antd";
import { validateMessages } from "utils/misc";
import { contactLabelAdd, contactLabelUpdate } from "services/redux/contact/contact.action";
import { contactLabelGroupListing } from "services/remanentCalls";

const { Option } = Select;
const { Header, Footer } = Layout;

const CityAddUpdateDrawer = ({
  onAddUpdateCountryClose,
  drawerVisibility,
  isUpdateCountry,
  pageSize,
  sortType,
  sorting,
  skipCount
}) => {
  const { contactLabelAddResponse, contactLabelUpdateResponse } = useSelector(
    (state) => state.contact
  );

  const [labelGroups, setLabelGroups] = useState([]);

  const [form] = Form.useForm();
  form.resetFields();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdateCountry) return;
    form.setFieldsValue({
      ...isUpdateCountry,
    });
  }, [isUpdateCountry]);

  useEffect(() => {
    onAddUpdateCountryClose();
  }, [contactLabelAddResponse, contactLabelUpdateResponse]);

  useEffect(() => {
    fetchcontactLabelGroupListing();
  }, []);

  const fetchcontactLabelGroupListing = async () => {
    const resp = await contactLabelGroupListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setLabelGroups(resp.data);
    }
  };

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (isUpdateCountry) {
        dispatch(contactLabelUpdate(isUpdateCountry.id, values, pageSize, skipCount))
      } else {
        dispatch(contactLabelAdd(values));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onAddUpdateCountryClose}
      closable={false}
    >
      <Header className='drawer__header'>
        <h4 className='drawer__header--title'>
          {`${isUpdateCountry ? "Update" : "Create"} City Name`}
        </h4>
      </Header>
      <Form
        layout='vertical'
        form={form}
        name='control-hooks'
        validateMessages={validateMessages}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              name='contactLabelGroupId'
              label='Label Group Name'
              className="form__group"
              rules={[{ required: true }]}>
              <Select
                // mode='multiple'
                showSearch={true}
                allowClear
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }>
                {labelGroups.map((labelgroup) => (
                  <Option key={labelgroup.id}>{labelgroup.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name='name'
              label='Label Name'
              rules={[{ required: true }]}>
              <Input autoComplete="off" />
            </Form.Item>
          </div>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateCountryClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default CityAddUpdateDrawer;
