import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";

// antd
import { Select, Popover, DatePicker, Pagination, Row, Col, Form } from "antd";
import { MoreOutlined } from "@ant-design/icons";

// constants
import { LogoImage } from "utils/imageConstants";
import InfiniteScroll from "react-infinite-scroll-component";

const { Option } = Select;

const { RangePicker } = DatePicker;

const GuestList = (props) => {
  const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);
  const {
    data,
    pageSize,
    guestEventsLoading,
    guestEventStatuses,
    currentPage,
    setCurrentPage,
  } = props;

  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [viewList, setViewList] = useState([]);

  const location = useLocation();

  useEffect(() => {
    if (!isEmpty(data?.items)) {
      let viewlists = [...viewList];
      data?.items.map((item, i) => {
        let lists = [...popoverMenuVisible];
        lists[i] = false;
        viewlists[i] = "More";
        setPopoverMenuVisible(lists);
        setViewList(viewlists);
      });
    }
  }, [data?.items]);

  const handleVisibleChange = (index) => {
    let lists = [...popoverMenuVisible];
    lists[index] = !lists[index];
    setPopoverMenuVisible(lists);
  };

  const getHalfDetails = (details) => {
    let halfDetails = details.split("").splice(0, 80).join("");
    return halfDetails;
  };

  const getActionContent = (item, index) => {
    return (
      <div className="guest-actions">
        <div
          className="guest-act"
          onClick={() => {
            props.editGuestList(item);
            handleVisibleChange(index);
          }}
        >
          Edit
        </div>
        <div className="guest-act">Delete</div>
      </div>
    );
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
    props.getData(
      pageSize,
      (page - 1) * pageSize,
      "",
      "",
      arrivalDate,
      departureDate,
      selectedStatusId
    );
  };

  return (
    <div className="organizer__list">
      {location.pathname === "/requisition/guestEntry" && (
        <>
          <h4 className="organizer__list--header">Guest List</h4>
          <div className="drawer-form-wrapper">
            <div className="drawer-form-row">
              <div className="drawer-form-group">
                <Select
                  autoComplete="off"
                  placeholder="Select status"
                  onChange={(value) => {
                    setSelectedStatusId(value);
                    setCurrentPage(1);
                    props.getData(
                      pageSize,
                      0,
                      "",
                      "",
                      arrivalDate,
                      departureDate,
                      value
                    );
                  }}
                >
                  <Option value={""}>All Status</Option>
                  {!isEmpty(guestEventStatuses) &&
                    guestEventStatuses.map((status, index) => {
                      return (
                        <Option value={status.id} key={index}>
                          {status.displayName}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div className="drawer-form-group">
                <RangePicker
                  autoComplete="off"
                  onChange={(value) => {
                    value !== null
                      ? props.getData(
                          pageSize,
                          0,
                          "",
                          "",
                          value[0],
                          value[1],
                          selectedStatusId
                        )
                      : props.getData(
                          pageSize,
                          0,
                          "",
                          "",
                          "",
                          "",
                          selectedStatusId
                        );
                    setArrivalDate(value[0]);
                    setCurrentPage(1);
                    setDepartureDate(value[1]);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="organizer__list--body body1">
        {!isEmpty(data?.items) ? (
          data?.items.map((item, index) => {
            return (
              <div className="gray__form" key={index}>
                {location.pathname === "/requisition/guestEntry" && (
                  <div className="guest__card--status">
                    <span className="badge badge-outline--normal">
                      {item.guestEventStatusDisplayName}
                    </span>
                    <span
                      className={`badge badge-outline--${
                        item.isActive ? "active" : "inactive"
                      }`}
                    >
                      {item.isActive ? "Active" : "Inactive"}
                    </span>
                    <span
                      className={`badge badge-outline--${
                        item.isPrivate ? "private" : "public"
                      }`}
                    >
                      {item.isPrivate ? "Private" : "Public"}
                    </span>
                  </div>
                )}
                <div className="organizer__card--body">
                  <div className="card-title">
                    {item.guestName} |{" "}
                    <span
                      style={{
                        color: "#007AFF",
                        fontWeight: "bold",
                      }}
                    >
                      {item.companyName}
                    </span>
                  </div>
                  <div className="card-notes">
                    {viewList[index] === "More" ? (
                      <>
                        {getHalfDetails(item.notes)}
                        <span
                          onClick={() => {
                            let viewLists = [...viewList];
                            viewLists[index] = "Less";
                            setViewList(viewLists);
                          }}
                        >
                          {item.notes.split("").length > 80 &&
                            `... View ${viewList[index]}`}
                        </span>
                      </>
                    ) : (
                      <>
                        {item.notes}
                        <span
                          onClick={() => {
                            let viewLists = [...viewList];
                            viewLists[index] = "More";
                            setViewList(viewLists);
                          }}
                        >
                          {" "}
                          View {viewList[index]}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="card-date">
                    {moment(item.arrivalDate).format("DD-MMM-YYYY")} to{" "}
                    {item.departureDate &&
                      moment(item.departureDate).format("DD-MMM-YYYY")}
                  </div>
                  {location.pathname === "/requisition/guestEntry" && (
                    <Popover
                      content={() => getActionContent(item, index)}
                      title="Action"
                      trigger="click"
                      className="tooltips"
                      visible={popoverMenuVisible[index]}
                      onVisibleChange={() => handleVisibleChange(index)}
                      placement="bottomRight"
                    >
                      <MoreOutlined style={{ fontSize: 18 }} />
                    </Popover>
                  )}
                </div>
              </div>
            );
          })
        ) : guestEventsLoading ? (
          <div style={{ height: 50, width: 50 }}>
            <img src={LogoImage} height="100%" />
          </div>
        ) : (
          "No data"
        )}
      </div>
      {/* {!isEmpty(data.items) && (
            <Pagination
              current={currentPage}
              total={data.totalCount}
              showSizeChanger={false}
              pageSize={pageSize}
              onChange={onChangePage}
            />
          )} */}
    </div>
  );
};

export default GuestList;
