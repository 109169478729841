import useRoles from "hooks/useRoles";
import { upperCase } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionForAddUpdateFinancialInformation,
  //   getFinancialDashboardListing,
} from "services/redux/admin/FiscalYear/action";

const {
  Card,
  Form,
  Typography,
  Row,
  Col,
  Input,
  Button,
  InputNumber,
} = require("antd");

export default function USHPCFinancialInfoUpdate() {
  const [formICCLIncExpStatus] = Form.useForm();
  const [formICCLNCFCBankStatus] = Form.useForm();
  const dispatch = useDispatch();
  //   const { LimitedRoleDemo } = useRoles();

  const {
    updateFiscalYrResponseIncIsplLoading,
    updateFiscalYrResponseNCFCISPLLoading,
    dashboardFinancialResponse,
  } = useSelector((state) => state.fiscalYearState);

  let dataISPLInc = dashboardFinancialResponse?.items?.find((x) => {
    return (
      x?.accountCategory === "Income Expense status" &&
      x?.accountHead === "ISPL"
    );
  });

  let dataISPLNCFCBankStatus = dashboardFinancialResponse?.items?.find((x) => {
    return (
      x?.accountCategory === "NC/FC Bank Status" && x?.accountHead === "ISPL"
    );
  });
  console.log("dataISPLNCFCBankStatus", dataISPLNCFCBankStatus);

  useEffect(() => {
    formICCLIncExpStatus?.setFieldsValue({
      amount:
        dataISPLInc?.financialInformationIncomeExpenseStatus
          ?.incomeInFiscalYear,
      amountTwo:
        dataISPLInc?.financialInformationIncomeExpenseStatus
          ?.expenseInFiscalYear,
      amountThree: dataISPLInc?.financialInformationIncomeExpenseStatus?.budget,
    });

    formICCLNCFCBankStatus?.setFieldsValue({
      amount: dataISPLNCFCBankStatus?.financialInformationNcFcBankStatus?.nc,
      amountTwo: dataISPLNCFCBankStatus?.financialInformationNcFcBankStatus?.fc,
      amountThree:
        dataISPLNCFCBankStatus?.financialInformationNcFcBankStatus?.total,
    });
  }, [dashboardFinancialResponse]);

  const onFinishForm = (values, formName) => {
    // console.log("formName", formName);
    let apiData = {
      financialAccountingInfomationId:
        formName === "Income Expense status"
          ? dataISPLInc?.financialAccountingInformationId
          : dataISPLNCFCBankStatus?.financialAccountingInformationId,
      companyAbbrevation: "ISPL",
      accountHead: "ISPL",
      accountCategory: formName,
      ...values,
    };
    dispatch(actionForAddUpdateFinancialInformation(apiData));
  };

  return (
    <>
      {/* <div style={{ display: "flex" }}> */}
      <Card
        style={{ margin: 100 }}
        title={
          <Typography.Title style={{ textAlign: "center" }} level={5}>
            FINANCIAL INFORMATION (ISPL-INCOME, EXPENSE, BUDGET STATUS) (LIMITED
            ROLE)
          </Typography.Title>
        }
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={(values) => onFinishForm(values, "Income Expense status")}
          form={formICCLIncExpStatus}
        >
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <Form.Item label="Income" name="amount">
                <InputNumber
                  placeholder="Enter Income"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Expense" name="amountTwo">
                <InputNumber
                  placeholder="Enter Expense"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Budget" name="amountThree">
                <InputNumber
                  placeholder="Enter Budget"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            loading={updateFiscalYrResponseIncIsplLoading}
          >
            Submit
          </Button>
        </Form>
      </Card>

      <Card
        style={{ margin: 100 }}
        title={
          <Typography.Title style={{ textAlign: "center" }} level={5}>
            FINANCIAL INFORMATION (ISPL-NC/FC Bank Status) (LIMITED ROLE)
          </Typography.Title>
        }
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={(values) => onFinishForm(values, "NC/FC Bank Status")}
          form={formICCLNCFCBankStatus}
        >
          <Row gutter={[24, 0]}>
            <Col span={12}>
              <Form.Item label="Nepali Currency" name="amount">
                <InputNumber
                  placeholder="Enter Income"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Foreign Currency" name="amountTwo">
                <InputNumber
                  placeholder="Enter Expense"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right" }}
            loading={updateFiscalYrResponseNCFCISPLLoading}
          >
            Submit
          </Button>
        </Form>
      </Card>
      {/* </div> */}
    </>
  );
}
