import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService, putService } from "../../../commonServices";

export class FiscalYearService {
  getFiscalYearPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizationStructure/fiscalYearPagination?${query}`;
    let data = getService(url);
    return data;
  }

  fiscalYearAddUpdate(body) {
    let url = `${appBaseUrl}/organizationStructure/fiscalYearAddUpdate`;
    let data = postService(url, body, "post");
    return data;
  }

  getFiscalYearListingWithStatus() {
    let url = `${appBaseUrl}/organizationStructure/fiscalYearListingWithStatus`;
    let data = getService(url);
    return data;
  }

  getFinancialInfo() {
    let url = `${appBaseUrl}/organizationStructure/finanacialAccountingInformationPagination?MaxResultCount=100`;
    let data = getService(url);
    return data;
  }
  getFinancialInfoBugetIncomeExpenseDashBoard() {
    let url = `${appBaseUrl}/organizationStructure/financialAccountingInformationBudgetIncomeExpense`;
    let data = getService(url);
    return data;
  }

  getBudgetDataDashBoard() {
    let url = `${appBaseUrl}/organizationStructure/syncAccountData?accessToken=8A80B51E-168E-7B42-8A05-7D2C1B5551D9`;
    let data = postService(url);
    return data;
  }
  getAllUsersList() {
    const url = `${appBaseUrl}/organizationStructure/userReportingManagerUserListing`;
    let data = getService(url);
    return data;
  }

  // hr module
  getUserReportingManagerUserList(value) {
    // const url = `${appBaseUrl}/organizationStructure/userReportingManagerUserRoleListing?departmentName=${value?.depatment}`;
    // let data = getService(url);
    // return data;

    try {
      // const baseUrl = `${appBaseUrl}/organizationStructure/userReportingManagerUserRoleListing`;
      const baseUrl = `${appBaseUrl}/employeeRequest/userAppRoleListing`;
      let url = baseUrl;

      if (value?.department !== "All") {
        url += `?departmentName=${value?.department}`;
      }

      return getService(url)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          throw error;
        });
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  // for updating roles in hr module
  apiForuserRoleAddUpdate(body, id) {
    let url = `${appBaseUrl}/employeeRequest/userRoleAddUpdate/${id}`;
    let data = putService(url, body);
    return data;
  }

  getBudgetData(body) {
    const query = queryString.stringify(body);
    let url = `${appBaseUrl}/organizationStructure/accountBudgetExpense?${query}&accessToken=8A80B51E-168E-7B42-8A05-7D2C1B5551D9`;
    let data = postService(url);
    return data;
  }
  getIncomeData(body) {
    const query = queryString.stringify(body);
    let url = `${appBaseUrl}/organizationStructure/accountIncomeDetails?${query}&accessToken=8A80B51E-168E-7B42-8A05-7D2C1B5551D9`;
    let data = postService(url);
    return data;
  }
  getLedgerData(body) {
    const query = queryString.stringify(body);
    let url = `${appBaseUrl}/organizationStructure/accountLedgerDetails?${query}&accessToken=8A80B51E-168E-7B42-8A05-7D2C1B5551D9`;
    let data = postService(url);
    return data;
  }
  getCashFlowData(body) {
    const query = queryString.stringify(body);
    let url = `${appBaseUrl}/organizationStructure/accountCashFlowDetails?${query}&accessToken=8A80B51E-168E-7B42-8A05-7D2C1B5551D9`;
    let data = postService(url);
    return data;
  }

  updateDashboardFee(body) {
    let url = `${appBaseUrl}/organizationStructure/financialAccountingInformationAddUpdate`;
    let data = postService(url, body);
    return data;
  }

  apiForAddUpdateFinancialInformation(body, id) {
    // let url = `${appBaseUrl}/referenceManagement/tenderCEADContractFinancialsAddUpdateInformation/${id}`;
    let url = `${appBaseUrl}/organizationStructure/financialAccountingInformationAddUpdate`;
    let data = postService(url, body);
    return data;
  }

  getFeeSubcategorySub(category) {
    // let url = `${appBaseUrl}/organizationStructure/feeDueFeeRemainingPagination?Category=${category}`;
    let url = `${appBaseUrl}/organizationStructure/feeDueFeeRemainingCeadPagination?Category=${category}`;
    let data = getService(url);
    return data;
  }
}
