import { Button } from "antd";
import React, { useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import ApplicationTemplateViewTable from "./ApplicationTemplateViewTable";
import ApplicationTemplateAddUpdateForm from "./ApplicationTemplateAddUpdateForm";

export default function ApplicationTemplate() {
  const [openForm, setOpenForm] = useState(false);
  const [updateData, setUpdateData] = useState("");

  return (
    <div className="layout">
      <div className="organizer events">
        <div className="all-header-wrapper">
          <div className="meeting__flex">
            <h3 className="header__all">Application Template</h3>
            <div className="organizerHoliday__middle--wrap">
              <Button
                className="btn-primary"
                onClick={() => {
                  setOpenForm(true);
                }}
              >
                <PlusOutlined /> New Application Template
              </Button>
            </div>
          </div>
        </div>
        <ApplicationTemplateViewTable
          setOpenForm={setOpenForm}
          setUpdateData={setUpdateData}
        />
        <ApplicationTemplateAddUpdateForm
          formVisibility={openForm}
          setOpenForm={setOpenForm}
          updateData={updateData}
          setUpdateData={setUpdateData}
        />
      </div>
    </div>
  );
}
