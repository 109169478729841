import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, Form, Button } from "antd";
import { noteProjectAdd } from "services/redux/Reference/Project/action";
import { isEmpty } from "lodash";

const addperson = "assets/svg/Addperson.svg";
const addcompany = "assets/svg/addcompany.svg";
const CheckMark = "assets/svg/checkmark-24.svg";
const cancel = "assets/svg/cross-24.svg";

const EditNotes = ({ projectGetDetailsResponse }) => {
  const { addProjectNoteResponse } = useSelector((state) => state.project);
  const [notes, setNotes] = useState(true);
  const [noteDetails, setNoteDetails] = useState("");

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const edit = "./assets/svg/profile-edit.svg";

  const onFinishNote = (values) => {
    dispatch(noteProjectAdd(projectGetDetailsResponse?.id, values));
    form.resetFields();
  };
  useEffect(() => {
    if (!isEmpty(addProjectNoteResponse)) {
      setNotes(true);
    }
  }, [addProjectNoteResponse]);
  useEffect(() => {
    setNoteDetails(projectGetDetailsResponse?.note);
  });
  useEffect(() => {
    form.setFieldsValue({
      note: projectGetDetailsResponse?.note,
    });
  }, [notes]);
  // console.log(notes,"notes")
  return (
    <>
      <div>
        <div>
          {notes ? (
            <>
              <div className="contactsDetails__infonotes">
                <div className="contactDetails__label--infoNotes">
                  <span className="contactsDetails--title bold w-100">
                    Notes{" "}
                  </span>
                  <div>
                    <img
                      src={edit}
                      onClick={() => {
                        setNotes(false);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="contactDetails__labeldata">
                <span className="contactsDetails--subtitle bold">
                  {projectGetDetailsResponse?.note
                    ? projectGetDetailsResponse?.note
                    : "No Notes"}
                </span>
              </div>
            </>
          ) : (
            <div>
              <div className="contactsDetails__infonotes">
                <div className="contactDetails__label--infoNotes">
                  <span className="contactsDetails--title bold w-100">
                    Notes{" "}
                  </span>
                  <div className="contactDetails__button note_add_button">
                    <div>
                      <img
                        src={cancel}
                        onClick={() => {
                          setNotes(true);
                        }}
                      />
                    </div>
                    {/* <div>
											<img
												src={cancel}
												onClick={() => {
													setNotes(true);
												}}
											/>
										</div> */}
                  </div>
                </div>
              </div>
              <div>
                <Form form={form} onFinish={onFinishNote}>
                  <Form.Item
                    className="form__group"
                    name="note"
                    rules={[{ required: false }]}
                    form={form}
                  >
                    <Input.TextArea placeholder="Notes" showCount />
                  </Form.Item>
                  <Button
                    className="save_button_notes"
                    style={{ marginTop: "5px" }}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default EditNotes;
