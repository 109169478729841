import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import InfiniteScroll from "react-infinite-scroll-component";

// utils
import { LogoImage } from "utils/imageConstants";

// antd
import {
  Menu,
  Input,
  Avatar,
  Tabs,
  Select,
  Dropdown,
  Button,
  Image,
  Modal,
  Switch,
  Skeleton,
  Form,
  message,
  Upload,
  Progress,
  Spin,
  RangePicker,
  DatePicker,
  Tooltip,
  Space,
  Table,
  Popover,
  Typography,
  notification,
  Layout,
} from "antd";

import {
  StarFilled,
  DownOutlined,
  PlusOutlined,
  ExclamationOutlined,
  MailOutlined,
  WechatOutlined,
  CopyOutlined,
  FilterOutlined,
  CloseCircleOutlined,
  PlusSquareOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import { Checkbox, Divider } from "antd";

import { BroadcastChannel } from "broadcast-channel";

// redux
import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { selectedMailTypeAndMail } from "services/redux/email/persistMail/action";

import {
  refreshUserIncomingEmails,
  getErrorPagination,
  getChildReferenceUpateResponse,
  getMailMessageCenterPagination,
  quickEmailAddEmailSection,
  getPredefinedMessageListing,
  preDefineMessageAdd,
  preDefineMessageUpdate,
  getSharedEmailLists,
} from "services/redux/email/mailMessageCenter/action";
import { selectedActiveEmail } from "services/redux/email/persistMail/action";
import {
  emailGetDetails,
  emailPaginationCount,
} from "services/redux/email/mailMessageCenter/action";
import { toDoGetDetails } from "services/redux/organizer/todoLists/action";
import {
  // getTenderBasicSearchPagination,
  tenderGetBasicDetails,
} from "services/redux/Reference/Tender/action";

// includes
import EmailCard from "./EmailCard";
import ErrorMessagePagination from "./ErrorMessageTable";
// import { current } from "@reduxjs/toolkit";
// import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
// import { objectsEqual } from "utils/commonFunc";
import { multipleEmailSelectionAction } from "services/redux/email/multipleEmailSelection/action";
import {
  getAllUsers,
  messageApplicationTypeListing,
  uploadFile,
  // uploadFileInternal,
  // uploadFileWithNoProgress,
} from "services/remanentCalls";
// import { cqBaseUrl } from "utils/config";
import TextArea from "antd/lib/input/TextArea";
// import { appBaseUrl } from "utils/config";
import { getReferenceListing } from "services/redux/Reference/action";
import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";
import moment from "moment";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";
// import axios from "axios";
// import { getSharedEmailLists } from "services/redux/email/mailMessageCenter/api";
import { MailMessageCenterService } from "services/redux/email/mailMessageCenter/api";
import * as CONSTANT from "components/constants";
import { projectGetDetails } from "services/redux/Reference/Project/action";
import { Editor } from "@tinymce/tinymce-react";
import { EditorState } from "draft-js";
import {
  documentInstantMessage,
  getAllMessageApplicationType,
} from "services/redux/email/instanceMessageCenter/action";
import {
  actionForCreateGroup,
  actionForGetListingGroup,
  actionForUpdateGroup,
} from "services/redux/createGroup/action";
import ImageUploaderMultiple from "components/shared/ImageUploaderMultiple";
import { appBaseUrl } from "utils/config";
import InternalMCompose from "components/internalMNew";
import MyContext from "context/MyContext";

const mailMessageCenterService = new MailMessageCenterService();

const { TabPane } = Tabs;
const { SubMenu } = Menu;
const { Option } = Select;

// const email = "./assets/svg/email.svg";
// const emailAdd = "./assets/svg/email-add.svg";
// const filter = "./assets/svg/filterstroke-16.svg";
// const upChervon = "assets/svg/white-up-chervon.svg";
// const addperson = "./assets/svg/addperson.svg";
// const addcompany = "./assets/svg/addcompany.svg";
// const attachment = "./assets/svg/attatchment.svg";
const refresh = "./assets/svg/refresh.svg";
const mailInbox = "./assets/svg/mailinbox-12.svg";
const trash = "./assets/svg/delete-12.svg";
const mailOutbox = "./assets/svg/mail-outbox16.svg";
const pending = "./assets/svg/pending-12.svg";
const draft = "./assets/svg/draft-12.svg";
const starred = "./assets/svg/starred-12.svg";
const label = "./assets/svg/label-12.svg";
const switchIcon = "assets/svg/toogle-icon.svg";
const switchBlue = "assets/svg/toogle-icon-blue.svg";

const cancel = "./assets/svg/white-cross.svg";
const sendIntM = "./assets/svg/send-white.svg";

const layoutFormGroup = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const EmailList = ({
  Reference_SearchKeyword,
  toggleMail,
  currentTab,
  mailMessageCenterPagination,
  mailMessagePaginationSuccess,
  currentSelectedMailMessage,
  setCurrentSelectedMailMessage,
  searchKeyword,
  setSearchKeyword,
  setCurrentTab,
  setchatBox,
  hideMailListHeaderAndTabs,
  mailMessageCenterPaginationLoading,
  mailScrollLoader,
  getData,
  pageSize,
  setMessageCenterTypeName,
  setCircularId,
  showSideMenuEmail,
  setShowSideMenuEmail,
  messageCenterTypeName,
  preSelectEmailCard,
  setPreSelectEmailCard,
  infiniteScrollLoading,
  setIsFavorite,
  isFavorite,
  read,
  setUnRead,
  emailType,
  allParticipants,
  openAutoReferencing,
  setOpenAutoReferencing,
  predefinedData,
  setPredefinedData,
  setAdvancedFiltersData,
  advancedFiltersData,
  referenceTagsFromAutoReference,
  setFilteredEmails,
  filteredEmails,
  formSearch,
  setCorporateSharedAccessAccountHolderUserId,
  corporateSharedAccessAccountHolderUserId,
  setContextMenushow,
  contextMenushow,
  origin,
  tenderSelectedRowId,
}) => {
  const { mailOriginState, updateMailOriginState } = useContext(MyContext);

  const dispatch = useDispatch();
  const { groupData, groupDataLoading, groupDataLoadingSuccess } = useSelector(
    (state) => state.createUpdateGroup
  );
  const [form, form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const { RangePicker } = DatePicker;
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 14, color: "white" }} spin />
  );

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );
  const { getSharedEmailListsResponse } = useSelector(
    (state) => state.mailMessageCenter
  );

  const { allMessageTypesResponse, documentInstanceMessageLoading } =
    useSelector((state) => state.instanceMessageCenter);

  const [appAppTypes, setAppTypes] = useState();

  useEffect(() => {
    if (
      allMessageTypesResponse?.length < 0 ||
      allMessageTypesResponse === undefined
    ) {
      dispatch(getAllMessageApplicationType());
    } else if (allMessageTypesResponse?.length > 0) {
      let __apptypse = allMessageTypesResponse?.map((x) => {
        return {
          label: x?.displayName,
          // value: x?.id,
          value: x?.displayName,
        };
      });
      setAppTypes(__apptypse);
    }
  }, [allMessageTypesResponse]);

  const {
    // refreshIncomingMailRespLoading,
    // refreshIncomingMailResp,
    mailBodyGetDetailsLoading,
    mailBodyGetDetailsResponse,
    // allCount,
    // toCount,
    // fromCount,
    // ccCount,
    // bccCount,
    // pendingCount,
    // archievedCount,
    // trashCount,
    // flagCount,
    // drafCount,
    // outBoxCount,
    errorMessage,
    getResponseStatus,
    changeEmailStatusTypeRespLoading,
    mailPaginationCount,
    mailReferenceUpdateResponse,
    // composeEmailResp,
    getPredefinedMessageResponse,
    getPredefinedMessageResponseLoading,
    // mailReferenceListingResponse
  } = useSelector((state) => state.mailMessageCenter);

  const {
    replyInstanceMessageResp,
    instanceMessageUpdateResp,
    // replyInstanceMessageRespLoading,
    // quickEmailAddresponse,
    quickEmailAddresponseLoading,
  } = useSelector((state) => state.instanceMessageCenter);

  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );
  const { token } = useSelector((state) => state.auth);
  const { contactCompanyPaginationResponse, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  const [currentPage, setCurrentPage] = useState(1);
  const [menu, setMenu] = useState("");
  const [selectedEmailType, setSelectedEmailType] = useState({});
  const [rotate, setRotate] = useState(false);
  const [coutUnRead, setCountUnRead] = useState();
  const [showInboxSubMenu, setShowInboxSubMeu] = useState(false);
  const [showFlagSubMenu, setShowFlagSubMeu] = useState(false);
  const [selectedFirstIndex, setSelectFirstIndex] = useState([]);
  const [showErrorModal, setErrorModal] = useState(false);
  const [messagePagination, setMessagePagination] = useState([]);
  const [selectTopMail, setSelectTopEmail] = useState(false);
  const [infiniteScroll, setInfiniteScroll] = useState(false);
  const [childTabMessage, setChildTabMessage] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [reloader, setReloader] = useState(false);
  const bc = new BroadcastChannel("draftPagination");

  const [internalVisible, setInternalVisible] = useState(false);
  const [newCuttingVisible, setNewCuttinghVisible] = useState(false);
  const [userListing, setUserListing] = useState([]);
  const [toUserListings, setToUserListings] = useState([]);
  const [ccUserListings, setCCUserListings] = useState([]);
  const [searchValueReference, setSearchValueReference] = useState("");
  const [filterParticipantType, setFilterParticipantType] = useState("");
  const [filterReference, setFilterReference] = useState([]);
  const [referenceListing, setReferenceListing] = useState([]);
  const [showAdvanceSearchModal, setShowAdvanceSearchModal] = useState(false);
  const [searchAttachment, setSearchAttachment] = useState(true);
  const [editorData, setEditorData] = useState("");
  const [internalEditorData, setInternalEditorData] = useState("");
  const [applicationType, setApplicationType] = useState();
  // checkbox
  const plainOptions = [mailMessageCenterPagination?.items];
  const defaultCheckedList = [];
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [progress, setProgress] = useState(0);

  const [value, setValue] = useState("");
  const [contactCompanyList, setContactCompanyList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectTosInternal, setSelectTosInternal] = useState(false);
  const [selectTosNewCut, setSelectTosNewCut] = useState(false);
  const [selectTosNewCutGroup, setSelectTosNewCutGroup] = useState();
  const [selectCcsNewCutGroup, setSelectCcsNewCutGroup] = useState();
  const [selectedGroupDataToBeUpdated, setGroupDataToBeUpdate] = useState();

  // added by bishow
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  const [openAttachmentAdvanceSearch, setOpenAttachmentAdvanceSearch] =
    useState(false);
  const [showApprovalsInboxMenu, setShowApprovalsInboxMenu] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [subjectValue, setSubjectValue] = useState("");
  const [advanceButtonDisabled, setAdvanceButtonDisabled] = useState(true);
  const [referenceSearch, setReferenceSearch] = useState("");
  const [inputValue, setInputValue] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [openPredefinedModal, setOpenPrefinedModal] = useState(false);
  // const [predefinedData , setPredefinedData] = useState([]);
  const [predefinedEditData, setPredefinedEditData] = useState("");
  const [predefinedMessage, setPredefinedMessage] = useState("");

  // const [AltEnt, setAltEnt] = useState({});
  const [AltEnt, setAltEnt] = useState(false);
  const [createGroupModal, setCreateGroupModal] = useState(false);

  //advancedSearch
  const [advancedSearchData, setAdvancedSearchData] = useState({});
  const callMouseFunction = (e) => {
    setIsShown(true);
  };
  const onCheckAllChange = (e) => {
    // setCheckedList(e.target.checked ? plainOptions : []);
    if (e.target.checked) {
      mailMessageCenterPagination?.items?.map((mail, id) => {
        var x = document.getElementById(`email-list-${mail?.id}`);
        x.checked = true;
        const selectedEmail = multipleSelectedEmail?.map((id) => id?.id);
        if (!selectedEmail?.includes(mail?.id)) {
          dispatch(
            multipleEmailSelectionAction({
              checkStatus: e.target.checked,
              data: mail,
            })
          );
        }
      });
    } else {
      mailMessageCenterPagination?.items?.map((mail, id) => {
        var x = document?.getElementById(`email-list-${mail?.id}`);
        x.checked = false;
        dispatch(
          multipleEmailSelectionAction({
            checkStatus: e.target.checked,
            data: mail,
          })
        );
      });
    }
    // setIndeterminate(false);

    setCheckAll(e.target.checked);
  };

  const { multipleSelectedEmail } = useSelector(
    (state) => state.multipleSelectedEmail
  );

  const location = useLocation();
  const getEmailStatusFromUrl =
    queryString.parse(location.search)?.unread || "";

  // added by bishow
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNoDataMessage(true);
    }, 30000);
    // wait for 30 seconds to arrive data, while waiting display the skeleton

    return () => {
      clearTimeout(timer);
      setShowNoDataMessage(false);
    };
  }, [origin, tenderSelectedRowId]);

  useEffect(() => {
    if (!isEmpty(getEmailStatusFromUrl)) {
      setUnRead(false);
      setSelectTopEmail(true);
      setCurrentSelectedMailMessage("");
      window.location.hash = "/home";
    }
  }, [getEmailStatusFromUrl]);

  useEffect(() => {
    // setSelectTopEmail(true);
    setInfiniteScroll(false);
  }, [read, searchKeyword, isFavorite]);

  useEffect(() => {
    setMessagePagination([]);
    setCurrentSelectedMailMessage("");
    setPreSelectEmailCard(true);
    setSelectTopEmail(true);
    setInfiniteScroll(false);
  }, [
    currentTab,
    Reference_SearchKeyword,
    setCurrentSelectedMailMessage,
    setPreSelectEmailCard,
  ]);

  useEffect(
    () => {
      if (emailType !== "Business") {
        dispatch(emailPaginationCount({ messagecenterType: CONSTANT.ALL }));
        dispatch(emailPaginationCount({ messagecenterType: "CircularInbox" }));
        dispatch(emailPaginationCount({ messagecenterType: "CircularSent" }));
        dispatch(emailPaginationCount({ messagecenterType: "Outbox" }));
        dispatch(emailPaginationCount({ messagecenterType: "From" }));
        dispatch(emailPaginationCount({ messagecenterType: "To" }));
        dispatch(emailPaginationCount({ messagecenterType: "Cc" }));
        dispatch(emailPaginationCount({ messagecenterType: "Business" }));
        dispatch(emailPaginationCount({ messagecenterType: "Bcc" }));
        dispatch(emailPaginationCount({ messagecenterType: "Pending" }));
        dispatch(emailPaginationCount({ messagecenterType: "Archived" }));
        dispatch(emailPaginationCount({ messagecenterType: "Trash" }));
        dispatch(emailPaginationCount({ messagecenterType: CONSTANT.JUNK }));
        dispatch(emailPaginationCount({ messagecenterType: "Specialflag1" }));
        dispatch(emailPaginationCount({ messagecenterType: "Draft" }));
        dispatch(emailPaginationCount({ messagecenterType: "Outbox" }));
        dispatch(emailPaginationCount({ messagecenterType: "GroupChat" }));
        dispatch(emailPaginationCount({ messagecenterType: "GroupChatArchive" }));
        dispatch(
          emailPaginationCount({
            messagecenterType: "Loan",
          })
        );
        dispatch(
          emailPaginationCount({
            messagecenterType: "Leave",
          })
        );
        dispatch(
          emailPaginationCount({
            messagecenterType: "Procurement",
          })
        );
        dispatch(
          emailPaginationCount({
            messagecenterType: "Loan,Leave,Procurement",
          })
        );
        getErrorMessageData(10, 0);

        if (selectedMailTypeAndMailData?.typeSystemName === "Draft") {
          dispatch(
            getMailMessageCenterPagination(
              "skipCount=0&maxResultCount=10&IsRead_SearchKeyword=&IsInstanceMessage_SearchKeyword=&SortType=desc&IsFavourite_SearchKeyword=&messageCenterTypeName=Draft"
            )
          );
        }
      }
    },
    [
      // replyInstanceMessageResp,
      // changeEmailStatusTypeRespLoading,
      // selectedMailTypeAndMailData,
    ]
  );

  useEffect(() => {
    if (emailType !== "Business") {
      if (selectedMailTypeAndMailData?.typeSystemName === "inbox") {
        dispatch(emailPaginationCount({ messagecenterType: "CircularInbox" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "From") {
        dispatch(emailPaginationCount({ messagecenterType: "CircularSent" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "OutBox") {
        dispatch(emailPaginationCount({ messagecenterType: "Outbox" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "From") {
        dispatch(emailPaginationCount({ messagecenterType: "From" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Inbox") {
        dispatch(emailPaginationCount({ messagecenterType: "All" }));
        dispatch(emailPaginationCount({ messagecenterType: "Junk" }));
        dispatch(emailPaginationCount({ messagecenterType: "To" }));
        dispatch(emailPaginationCount({ messagecenterType: "Cc" }));
        // dispatch(emailPaginationCount({ messagecenterType: "Favourite" }));
        dispatch(emailPaginationCount({ messagecenterType: "Bcc" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Archived") {
        dispatch(emailPaginationCount({ messagecenterType: "Archived" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Pending") {
        dispatch(emailPaginationCount({ messagecenterType: "Pending" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Trash") {
        dispatch(emailPaginationCount({ messagecenterType: "Trash" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Specialflag1") {
        dispatch(emailPaginationCount({ messagecenterType: "Specialflag1" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Draft") {
        dispatch(emailPaginationCount({ messagecenterType: "Draft" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Loan'") {
        dispatch(emailPaginationCount({ messagecenterType: "Loan'" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Leave") {
        dispatch(emailPaginationCount({ messagecenterType: "Leave" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Procurement") {
        dispatch(emailPaginationCount({ messagecenterType: "Procurement" }));
      }
      if (selectedMailTypeAndMailData?.typeSystemName === "Business") {
        dispatch(emailPaginationCount({ messagecenterType: "Business" }));
      }

      if (selectedMailTypeAndMailData?.typeSystemName === "Approval") {
        dispatch(
          emailPaginationCount({
            messagecenterType: "Loan,Leave,Procurement",
          })
        );
      }
    }
  }, [changeEmailStatusTypeRespLoading]);

  //commented by Amit

  // useEffect(() => {
  //   if (!isEmpty(selectedMailTypeAndMailData)) {
  //     // setCurrentSelectedMailMessage(selectedMailTypeAndMailData.selectedMail);
  //     let nextCurrentSelectMessage = selectedMailTypeAndMailData?.selectedMail;
  //     // setCurrentTab(selectedMailTypeAndMailData?.currentTab);
  //     if (
  //       nextCurrentSelectMessage?.applicationTypeSystemName ===
  //       "InstanceMessage"
  //     ) {
  //       dispatch(
  //         emailGetDetails({
  //           mailMessageParticipantTableId:
  //             nextCurrentSelectMessage?.mailMessageParticipantTableId,
  //           mailMessageCenterId:
  //             nextCurrentSelectMessage?.instanceMainMessageCenterId,
  //           latestInstanceMessageId:
  //             nextCurrentSelectMessage?.latestInstanceMessageId,
  //           applicationType:
  //             selectedMailTypeAndMailData?.selectedMail
  //               ?.applicationTypeSystemName,
  //           filterTypes: emailType === "Business" ? "subordinates" : "",
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         emailGetDetails({
  //           mailMessageParticipantTableId:
  //             nextCurrentSelectMessage?.mailMessageParticipantTableId,
  //           mailMessageCenterId: nextCurrentSelectMessage?.id,
  //           latestInstanceMessageId:
  //             nextCurrentSelectMessage?.latestInstanceMessageId,
  //           applicationType:
  //             selectedMailTypeAndMailData?.selectedMail
  //               ?.applicationTypeSystemName,
  //           filterTypes: emailType === "Business" ? "subordinates" : "",
  //         })
  //       );
  //     }
  //   }
  // }, [selectedMailTypeAndMailData]);

  useEffect(() => {
    // if (!!mailReferenceUpdateResponse || !isEmpty(replyInstanceMessageResp))
    //   return;
    if (!isEmpty(replyInstanceMessageResp)) return;
    // if(reloader) return;
    // if(!mailBodyGetDetailsLoading) return ;
    // if(instanceMessageUpdateResp) return null;
    if (!isEmpty(selectedEmailType) && !infiniteScroll) {
      let saveData = {
        ...selectedEmailType,
        selectedMail: !isEmpty(messagePagination?.items)
          ? messagePagination.items[0]
          : "",
        index: 0,
        currentTab: currentTab,
      };

      dispatch(selectedMailTypeAndMail(saveData));
    } else if (
      isEmpty(selectedEmailType) &&
      !isEmpty(selectedMailTypeAndMailData)
    ) {
      setSelectedEmailType({
        typeDisplayName: selectedMailTypeAndMailData.typeDisplayName,
        typeSystemName: selectedMailTypeAndMailData.typeSystemName,
        count: selectedMailTypeAndMailData.count,
        icon: selectedMailTypeAndMailData.icon,
        subMenu: selectedMailTypeAndMailData.subMenu,
        totalLength: selectedMailTypeAndMailData.totalLength,
        currentTab: selectedMailTypeAndMailData.currentTab,
      });
    }
  }, [selectedEmailType]);

  useEffect(() => {
    setUnRead(true);
  }, [selectedEmailType, reloader]);

  useEffect(() => {
    if (!getSharedEmailListsResponse) {
      dispatch(getSharedEmailLists());
    }
  }, [getSharedEmailListsResponse]);

  useEffect(() => {
    if (!!mailReferenceUpdateResponse || !isEmpty(replyInstanceMessageResp))
      return;
    // if(reloader) return;
    if (!mailBodyGetDetailsLoading) return;
    // if(instanceMessageUpdateResp) return null;
    if (!isEmpty(selectedEmailType) && !infiniteScroll) {
      let saveData = {
        ...selectedEmailType,
        selectedMail: !isEmpty(messagePagination?.items)
          ? messagePagination.items[0]
          : "",
        index: 0,
        currentTab: currentTab,
      };

      dispatch(selectedMailTypeAndMail(saveData));
    } else if (
      isEmpty(selectedEmailType) &&
      !isEmpty(selectedMailTypeAndMailData)
    ) {
      setSelectedEmailType({
        typeDisplayName: selectedMailTypeAndMailData.typeDisplayName,
        typeSystemName: selectedMailTypeAndMailData.typeSystemName,
        count: selectedMailTypeAndMailData.count,
        icon: selectedMailTypeAndMailData.icon,
        subMenu: selectedMailTypeAndMailData.subMenu,
        totalLength: selectedMailTypeAndMailData.totalLength,
        currentTab: selectedMailTypeAndMailData.currentTab,
      });
    }
  }, [messagePagination]);

  // useEffect(()=>{
  //   if (!isEmpty(selectedEmailType) && !infiniteScroll) {
  //     let saveData = {
  //       ...selectedEmailType,
  //       selectedMail: !isEmpty(messagePagination?.items)
  //         ? messagePagination.items[0]
  //         : "",
  //       index: 0,
  //       currentTab: currentTab,
  //     };

  //     dispatch(selectedMailTypeAndMail(saveData));
  //   }
  // },[showSideMenuEmail]);

  <Avatar src={selectedMailTypeAndMailData?.icon} size={20} />;

  useEffect(() => {
    if (
      getResponseStatus ||
      mailReferenceUpdateResponse ||
      childTabMessage ||
      !isEmpty(replyInstanceMessageResp)
    )
      return;
    let currentSelectedMailIndex = 0;
    mailMessageCenterPagination.items.map((item, i) => {
      if (item.id === currentSelectedMailMessage?.id) {
        currentSelectedMailIndex = i;
      }
    });

    if (
      isEmpty(selectedMailTypeAndMailData) &&
      !isEmpty(mailMessageCenterPagination.items)
    ) {
      setCurrentSelectedMailMessage("");
      // setCurrentTab("All")
      const nextCurrentSelectMessage = mailMessageCenterPagination.items[0];
      let saveData = {
        typeSystemName: "Inbox",
        typeDisplayName: "Inbox",
        count: mailMessageCenterPagination.totalCount,
        icon: mailInbox,
        selectedMail: mailMessageCenterPagination.items[0],
        totalLength: currentSelectedMailIndex + 1,
        currentTab: "All",
        index: 0,
      };
      setSelectedEmailType(saveData);
      dispatch(selectedMailTypeAndMail(saveData));
      if (!isEmpty(nextCurrentSelectMessage)) {
        if (nextCurrentSelectMessage.applicationTypeSystemName === "Task") {
          dispatch(toDoGetDetails(nextCurrentSelectMessage?.applicationId));
        }

        if (nextCurrentSelectMessage.applicationTypeSystemName === "Business") {
          dispatch(
            tenderGetBasicDetails(nextCurrentSelectMessage?.applicationId)
          );
        }
        if (nextCurrentSelectMessage.applicationTypeSystemName === "Project") {
          dispatch(projectGetDetails(nextCurrentSelectMessage?.applicationId));
        }
        if (
          nextCurrentSelectMessage?.applicationTypeSystemName ===
          "InstanceMessage"
        ) {
          dispatch(
            emailGetDetails({
              mailMessageParticipantTableId:
                nextCurrentSelectMessage?.mailMessageParticipantTableId,
              mailMessageCenterId:
                nextCurrentSelectMessage.instanceMainMessageCenterId,
              latestInstanceMessageId:
                nextCurrentSelectMessage.latestInstanceMessageId,
              applicationType:
                nextCurrentSelectMessage?.applicationTypeSystemName,
              filterTypes: emailType === "Business" ? "subordinates" : "",
            })
          );
        } else {
          dispatch(
            emailGetDetails({
              mailMessageParticipantTableId:
                nextCurrentSelectMessage?.mailMessageParticipantTableId,
              mailMessageCenterId:
                nextCurrentSelectMessage.instanceMainMessageCenterId,
              latestInstanceMessageId:
                nextCurrentSelectMessage.latestInstanceMessageId,
              applicationType:
                nextCurrentSelectMessage?.applicationTypeSystemName,
              filterTypes: emailType === "Business" ? "subordinates" : "",
            })
          );
        }
      }
    }
  }, [mailMessageCenterPagination]);

  useEffect(() => {
    if (
      getResponseStatus ||
      mailReferenceUpdateResponse ||
      childTabMessage ||
      replyInstanceMessageResp ||
      instanceMessageUpdateResp
    )
      return;
    let currentSelectedMailIndex = 0;

    mailMessageCenterPagination.items.map((item, i) => {
      if (item.id === currentSelectedMailMessage?.id) {
        currentSelectedMailIndex = i;
      }
    });

    if (
      !isEmpty(selectedMailTypeAndMailData) &&
      !isEmpty(mailMessageCenterPagination.items) &&
      !infiniteScroll &&
      selectTopMail
    ) {
      const nextCurrentSelectMessage =
        mailMessageCenterPagination?.items[currentSelectedMailIndex];

      if (nextCurrentSelectMessage?.applicationTypeSystemName === "Task") {
        dispatch(toDoGetDetails(nextCurrentSelectMessage?.applicationId));
      }
      if (nextCurrentSelectMessage?.applicationTypeSystemName === "Business") {
        dispatch(
          tenderGetBasicDetails(nextCurrentSelectMessage?.applicationId)
        );
      }
      if (nextCurrentSelectMessage?.applicationTypeSystemName === "Project") {
        dispatch(projectGetDetails(nextCurrentSelectMessage?.applicationId));
      }
      {
        if (
          nextCurrentSelectMessage?.applicationTypeSystemName ===
          "InstanceMessage"
        ) {
          dispatch(
            emailGetDetails({
              mailMessageParticipantTableId:
                nextCurrentSelectMessage?.mailMessageParticipantTableId,
              mailMessageCenterId:
                nextCurrentSelectMessage.instanceMainMessageCenterId,
              latestInstanceMessageId:
                nextCurrentSelectMessage.latestInstanceMessageId,
              applicationType:
                nextCurrentSelectMessage?.applicationTypeSystemName,
              filterTypes: emailType === "Business" ? "subordinates" : "",
            })
          );
        } else {
          dispatch(
            emailGetDetails({
              mailMessageParticipantTableId:
                nextCurrentSelectMessage?.mailMessageParticipantTableId,
              mailMessageCenterId:
                nextCurrentSelectMessage.instanceMainMessageCenterId,
              latestInstanceMessageId:
                nextCurrentSelectMessage.latestInstanceMessageId,
              applicationType:
                nextCurrentSelectMessage?.applicationTypeSystemName,
              filterTypes: emailType === "Business" ? "subordinates" : "",
            })
          );
        }
      }
    }
    // },[]);
  }, [mailMessageCenterPagination]);

  const [allInputChecked, setAllInputChecked] = useState(false);

  // else if (
  //   !isEmpty(selectedMailTypeAndMailData) &&
  //   // !isEmpty(mailMessageCenterPagination.items) &&
  //   !infiniteScroll &&
  //   selectTopMail
  // ) {
  //   const nextCurrentSelectMessage = mailMessageCenterPagination?.items[0];

  //   {
  //     if (
  //       nextCurrentSelectMessage?.applicationTypeSystemName ===
  //       "InstanceMessage"
  //     ) {
  //       dispatch(
  //         emailGetDetails({
  //           mailMessageParticipantTableId:
  //             nextCurrentSelectMessage?.mailMessageParticipantTableId,
  //           mailMessageCenterId:
  //             nextCurrentSelectMessage.instanceMainMessageCenterId,
  //           latestInstanceMessageId:
  //             nextCurrentSelectMessage.latestInstanceMessageId,
  //           applicationType:
  //             nextCurrentSelectMessage.applicationTypeSystemName,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         emailGetDetails({
  //           mailMessageParticipantTableId:
  //             nextCurrentSelectMessage?.mailMessageParticipantTableId,
  //           mailMessageCenterId: nextCurrentSelectMessage.id,
  //           latestInstanceMessageId:
  //             nextCurrentSelectMessage.latestInstanceMessageId,
  //           applicationType:
  //             nextCurrentSelectMessage.applicationTypeSystemName,
  //         })
  //       );
  //     }
  //   }
  // }

  // useEffect(() => {
  //   let currentSelectedMailIndex = 0;
  //   mailMessageCenterPagination.items.map((item, i) => {
  //     if (item.id === currentSelectedMailMessage.id) {
  //       currentSelectedMailIndex = i;
  //     }
  //   });
  //   if (onUpdateStatusChangePagination) {
  //     if (
  //       !isEmpty(selectedMailTypeAndMailData) &&
  //       !isEmpty(mailMessageCenterPagination.items)
  //     ) {
  //       const nextCurrentSelectMessage = mailMessageCenterPagination.items[0];
  //       let saveData = {
  //         ...selectedEmailType,
  //         ...selectedMailTypeAndMailData,
  //         typeDisplayName: "All",
  //         typeSystemName: "All",
  //         icon: mailInbox,
  //         selectedMail: mailMessageCenterPagination.items[0],
  //         totalLength: currentSelectedMailIndex + 1,
  //         currentTab: currentTab,
  //         index: 0,
  //       };

  //       dispatch(selectedMailTypeAndMail(saveData));
  //       if (!isEmpty(nextCurrentSelectMessage)) {
  //         if (
  //           nextCurrentSelectMessage?.applicationTypeSystemName ===
  //           "InstanceMessage"
  //         ) {
  //           dispatch(
  //             emailGetDetails({
  //               mailMessageCenterId:
  //                 nextCurrentSelectMessage.instanceMainMessageCenterId,
  //               latestInstanceMessageId:
  //                 nextCurrentSelectMessage.latestInstanceMessageId,
  //               applicationType:
  //                 nextCurrentSelectMessage.applicationTypeSystemName,
  //             })
  //           );
  //         } else {
  //           dispatch(
  //             emailGetDetails({
  //               mailMessageCenterId: nextCurrentSelectMessage.id,
  //               latestInstanceMessageId:
  //                 nextCurrentSelectMessage.latestInstanceMessageId,
  //               applicationType:
  //                 nextCurrentSelectMessage.applicationTypeSystemName,
  //             })
  //           );
  //         }
  //       }
  //     } else if (
  //       isEmpty(
  //         selectedMailTypeAndMailData &&
  //           isEmpty(mailMessageCenterPagination.items)
  //       )
  //     ) {
  //       let saveData = {
  //         icon: mailInbox,
  //         selectedMail: mailMessageCenterPagination.items[0],
  //         totalLength: currentSelectedMailIndex + 1,
  //         currentTab: currentTab,
  //         index: 0,
  //       };

  //       dispatch(selectedMailTypeAndMail(saveData));
  //     } else if (
  //       !isEmpty(
  //         selectedMailTypeAndMailData &&
  //           isEmpty(mailMessageCenterPagination.items)
  //       )
  //     ) {
  //       let saveData = {
  //         ...selectedEmailType,
  //         icon: mailInbox,
  //         selectedMail: mailMessageCenterPagination.items[0],
  //         totalLength: currentSelectedMailIndex + 1,
  //         currentTab: currentTab,
  //         index: 0,
  //       };

  //       dispatch(selectedMailTypeAndMail(saveData));
  //     }
  //   }
  // }, [mailMessageCenterPagination]);

  useEffect(() => {
    setMessagePagination(mailMessageCenterPagination);
    if (!isEmpty(mailMessageCenterPagination.items)) {
      const filterUnRead = mailMessageCenterPagination.items?.filter(
        (data) => !data.isRead
      );
      setCountUnRead(filterUnRead.length);
    } else if (
      !mailMessageCenterPaginationLoading &&
      isEmpty(mailMessageCenterPagination.items)
    ) {
      // setCurrentSelectedMailMessage("");
    }
  }, [mailMessageCenterPagination]);

  // useEffect(() => {
  //   if (
  //     !isEmpty(selectedEmailType) &&
  //     !isEmpty(mailMessageCenterPagination.items)
  //   ) {
  //     const changePagination = mailMessageCenterPagination.items[0];
  //     let saveData = {
  //       ...selectedMailTypeAndMailData,
  //       ...selectedEmailType,
  //       index: 0,
  //       selectedMail: changePagination,
  //     };
  //     setSelectedEmailType(saveData);
  //     dispatch(selectedMailTypeAndMail(saveData));

  //     if (!isEmpty(changePagination)) {
  //       if (changePagination?.applicationTypeSystemName === "InstanceMessage") {
  //         dispatch(
  //           emailGetDetails({
  //             mailMessageCenterId: changePagination.instanceMainMessageCenterId,
  //             latestInstanceMessageId: changePagination.latestInstanceMessageId,
  //             applicationType: changePagination.applicationTypeSystemName,
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           emailGetDetails({
  //             mailMessageCenterId: changePagination.id,
  //             latestInstanceMessageId: changePagination.latestInstanceMessageId,
  //             applicationType: changePagination.applicationTypeSystemName,
  //           })
  //         );
  //       }
  //     }
  //   }
  // }, [currentTab, mailMessageCenterPagination, selectedEmailType]);

  const getErrorMessageData = (MaxResultCount = 0, SkipCount = 0) => {
    let appendUrl = `skipCount=${SkipCount}&maxResultCount=${MaxResultCount}`;

    dispatch(getErrorPagination(appendUrl));
  };

  useEffect(() => {
    bc.onmessage = (ev) => {
      if (isEmpty(ev)) return;
      else {
        setChildTabMessage(ev);
        dispatch(getChildReferenceUpateResponse(ev));
      }
    };

    return () => {
      bc.close();
    };
  }, [bc]);

  useEffect(() => {
    if (isEmpty(mailMessageCenterPagination?.items)) return;
    let newCurrentPage = mailMessageCenterPagination.items.length / pageSize;
    setCurrentPage(newCurrentPage);
  }, [mailMessageCenterPagination]);

  useEffect(() => {
    if (
      selectedMailTypeAndMailData?.totalLength ===
      mailMessageCenterPagination.totalCount
    ) {
      let objDiv1 = document.getElementById("scrollableDiv");
      let objDiv2 = document.getElementById("scrollableDiv2");
      if (objDiv1) {
        objDiv1.scrollTop = objDiv1
          ? objDiv1.scrollHeight - objDiv1.clientHeight
          : 1;
      } else if (objDiv2) {
        if (currentTab === "Internal") {
          objDiv2.scrollTop = objDiv2
            ? objDiv2.scrollHeight - objDiv2.clientHeight
            : 1;
        }
      }
    }
  }, [selectedMailTypeAndMailData]);

  useEffect(() => {
    if (searchValueReference) {
      if (referenceListingResponse) {
        setReferenceListing(referenceListingResponse);
      }
    }
  }, [referenceListingResponse, searchValueReference]);

  useEffect(() => {
    if (!isEmpty(referenceListing) && filterParticipantType == "REF") {
      setFilterReference(referenceListing.items);
    }
  }, [referenceListing]);

  useEffect(() => {
    if (filterParticipantType === "REF") {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  useEffect(() => {
    // if(internalVisible){
    dispatch(getPredefinedMessageListing());
    // }
  }, []);

  const animate = () => {
    // Button begins to shake
    setRotate(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setRotate(false), 1000);
  };

  const onFinishPredefineMessage = () => {
    const messagePredefined = form.getFieldsValue().remarksPredefined;
    if (messagePredefined?.length < 2 || !messagePredefined) {
      message.error("Message must be entered");
      return;
    }
    let body = { message: messagePredefined, isActive: true };
    if (predefinedEditData) {
      dispatch(preDefineMessageUpdate(predefinedEditData?.id, body, form));
    } else {
      dispatch(preDefineMessageAdd(body, form));
    }
    setPredefinedEditData("");
  };

  const columnsPredefinedModal = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "titmessagele",
      width: "60%",
    },
    {
      title: "Action",
      render: (record) => (
        <a
          onClick={() => {
            setPredefinedEditData(record);
          }}
        >
          Edit
        </a>
      ),
    },
  ];

  const filterMenu = (
    <Menu>
      <Menu.Item>All</Menu.Item>
      <Menu.Item>UnRead</Menu.Item>
    </Menu>
  );

  const fetchMoreData = () => {
    getData(pageSize, currentPage * pageSize, true);
    setInfiniteScroll(true);
    setSelectTopEmail(false);
  };

  const onFinishAdvanceSearch = (values) => {
    setAdvancedSearchData({});
    setAdvancedFiltersData({});

    const body = {
      ...values,
      DateRange_DateFrom: startDate,
      DateRange_DateTo: endDate,
      HasAttachment_SearchKeyword: !openAttachmentAdvanceSearch
        ? ""
        : searchAttachment
        ? false
        : true,
      // maxResultCount: 2000,
      messageCenterTypeName: "advanceSearch",
    };

    setAdvancedSearchData(body);
    setAdvancedFiltersData(body);
    // getAdvanceFilterData(body);
    setShowAdvanceSearchModal(false);
    setInputValue(!inputValue);
    setShowResetButton(true);
  };

  const handleOk = () => {
    setShowAdvanceSearchModal(false);
    // form.resetFields();
  };
  const handleCancel = () => {
    setShowAdvanceSearchModal(false);
    setNewCuttinghVisible(false);
    form.resetFields();
  };
  const nullChecker = (values, type) => {
    if (type === "all") {
      if (values !== null) {
        return values?.total;
      } else {
        return 0;
      }
    } else if (type === "unread") {
      if (values !== null) {
        return values?.unread;
      } else {
        return 0;
      }
    }
  };

  const applicationTypeListing = async () => {
    const resp = await messageApplicationTypeListing().catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      resp?.data?.map((type) => {
        if (type.displayName === "News Cutting") {
          setApplicationType(type);
        }
      });
    }
  };

  const subMenuCompose = (
    <Menu style={{ width: 160, height: 105 }}>
      <Menu.Item className="sidebars__menu--item" onClick={() => {}}>
        <Link
          to="/email-compose?applicationType=newEmail"
          target="_blank"
          rel="opener"
          style={{ color: "#6d6d6d" }}
          className="compose_email_button"
        >
          <div
            style={{
              display: "flex",
              width: 140,
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "18%", marginLeft: "0px" }}>
              <MailOutlined />
            </div>

            <div className="" style={{ width: "96%", marginLeft: "5px" }}>
              {/* <Button className="contact__header__btn-primary"> */}
              Email
              {/* </Button> */}
            </div>
            {/* <div style={{ width: "96%" }}>Compose Email</div> */}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item className="sidebars__menu--item">
        <>
          <div
            style={{
              display: "flex",
              width: 140,
              justifyContent: "space-around",
            }}
            onClick={() => {
              setInternalVisible(true);
              form.resetFields();
              setInternalEditorData("");
              setPredefinedMessage("");
            }}
          >
            <div style={{ width: "18%", marginLeft: "0px" }}>
              <WechatOutlined />
            </div>
            <div style={{ width: "95%", marginLeft: "5px" }}>InternalM</div>
          </div>
        </>
      </Menu.Item>

      <Menu.Item className="sidebars__menu--item">
        <>
          <div
            style={{
              display: "flex",
              width: 140,
              justifyContent: "space-around",
            }}
            onClick={() => {
              setNewCuttinghVisible(true);
              applicationTypeListing();
            }}
          >
            <div style={{ width: "18%", marginLeft: "0px" }}>
              <CopyOutlined />
            </div>
            <div style={{ width: "95%", marginLeft: "5px" }}>News Cuttings</div>
          </div>
        </>
      </Menu.Item>
    </Menu>
  );

  const fetchUserListing = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      setUserListing(resp.data);
    }
  };

  useEffect(() => {
    if (!isEmpty(predefinedMessage)) {
      form.setFieldsValue({
        remarks: predefinedMessage,
      });
    }
  }, [predefinedMessage]);

  useEffect(() => {
    setToUserListings(userListing);
    setCCUserListings(userListing);
  }, [userListing]);

  useEffect(() => {
    if (isEmpty(userListing)) {
      fetchUserListing();
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      remarksPredefined: predefinedEditData?.message,
    });
  }, [predefinedEditData]);

  const shareDocumentForm = (values) => {
    values = form.validateFields();
    console.log("valuesZ", values);
    let tos = [];
    let ccs = [];
    values?.participantTos &&
      values.participantTos?.map((item, i) => {
        tos = [...tos, { participantUserId: item }];
      });
    values.participantCCs &&
      values.participantCCs?.map((item, i) => {
        ccs = [...ccs, { participantUserId: item }];
      });
    let body = {
      isQuickMessage: true,
      instanceMessageAttachmentDtos: attachments,
      instanceMessageStatusName: "Normal",
      mailMessageParticipantWithTypeDtos: {
        participantTos: tos,
        participantCCs: ccs,
      },
      messageText: values?.messageText,
      // remarks: values?.remarks,
      remarks: internalEditorData,
      // mailMessageReferenceDtos: [
      //   { referenceCode: values?.mailMessageReferenceDtos },
      // ],
      mailMessageReferenceDtos: values?.mailMessageReferenceDtos?.map((x) => {
        return { referenceCode: x };
      }),
    };

    dispatch(quickEmailAddEmailSection(body, setInternalVisible));

    form.resetFields();
  };

  const getCompanyPagination = (SkipCount = 0, ForPagination = false) => {
    dispatch(
      getContactCompanyPagination(
        {
          SkipCount,
          SearchKeyword: value,
        },
        ForPagination
      )
    );
  };

  const handleSaveContinue = (values, formType) => {
    let participantTosValue = form.getFieldValue().participantTos;
    let participantCCsValue = form.getFieldValue().participantCCs;
    let uploadedFileDtos = form.getFieldValue().uploadedFileDtos;
    let tos = [];
    let ccs = [];
    participantTosValue &&
      participantTosValue?.map((item, i) => {
        tos = [...tos, { participantUserId: item }];
      });
    participantCCsValue &&
      participantCCsValue?.map((item, i) => {
        ccs = [...ccs, { participantUserId: item }];
      });
    let messageText = form.getFieldsValue().messageText;
    let remarks = form.getFieldsValue().remarks;
    let referenceFields = form.getFieldsValue().mailMessageReferenceDtos;
    if (isEmpty(participantTosValue)) {
      // message.error("Quick message To's cannot be empty");
      return;
    }
    if (isEmpty(messageText || remarks)) {
      message.error("Quick message subject or remarks cannot be empty");
      return;
    }
    if (isEmpty(referenceFields)) {
      message.error("Reference cannot be empty");
      return;
    }
    let body = {
      isQuickMessage: true,
      // instanceMessageAttachmentDtos: attachments,
      instanceMessageAttachmentDtos: uploadedFileDtos,
      instanceMessageStatusName: "Normal",
      mailMessageParticipantWithTypeDtos: {
        participantTos: tos,
        participantCCs: ccs,
      },
      messageText: messageText,
      // remarks: remarks,
      remarks: internalEditorData,
      // mailMessageReferenceDtos: [
      //   {
      //     referenceCode: referenceFields,
      //   },
      // ],
      mailMessageReferenceDtos: referenceFields?.map((x) => {
        return {
          referenceCode: x,
        };
      }),
    };
    if (formType !== "newCutting") {
      dispatch(quickEmailAddEmailSection(body, setInternalVisible));
    } else {
    }

    form.resetFields();
    setPredefinedMessage();
    setInternalEditorData("");
  };

  useEffect(() => {
    if (
      multipleSelectedEmail?.length > 0 &&
      multipleSelectedEmail?.length >=
        mailMessageCenterPagination?.items?.length
    ) {
      setIndeterminate(false);
      setCheckAll(true);
    } else if (
      multipleSelectedEmail?.length > 0 &&
      multipleSelectedEmail?.length !==
        mailMessageCenterPagination?.items?.length
    ) {
      setIndeterminate(true);
    } else if (multipleSelectedEmail?.length < 1) {
      setIndeterminate(false);
      setCheckAll(false);
    }
  }, [multipleSelectedEmail?.length]);

  useEffect(() => {
    mailMessageCenterPagination?.items?.map((mail, id) => {
      dispatch(
        multipleEmailSelectionAction({ checkStatus: false, data: mail })
      );
    });
    setCheckAll(false);
  }, [messageCenterTypeName, currentTab]);

  useEffect(() => {
    if (isEmpty(value)) setContactCompanyList([]);
    if (isEmpty(value)) return;
    if (
      !isEmpty(contactCompanyList) &&
      isEmpty(contactCompanyPaginationResponse?.items)
    )
      return;

    const timeoutId = setTimeout(() => getCompanyPagination(), 500);
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    if (!isEmpty(contactCompanyPaginationResponse?.items)) {
      setContactCompanyList(contactCompanyPaginationResponse?.items);
    } else {
    }
  }, [contactCompanyPaginationResponse]);

  useEffect(() => {
    if (selectTosNewCut === true) {
      let allParticipantsId = allParticipants?.map(
        (participant) => participant?.appUserId
      );

      form3.setFieldsValue({
        participantTos: allParticipantsId,
      });
      setSelectTosNewCutGroup();
    } else if (!isEmpty(selectTosNewCutGroup)) {
      form3.setFieldsValue({
        participantTos: selectTosNewCutGroup,
        participantCCs: selectCcsNewCutGroup,
      });
      form.setFieldsValue({
        participantTos: selectTosNewCutGroup,
        participantCCs: selectCcsNewCutGroup,
      });
    } else {
      form3.setFieldsValue({
        participantTos: [],
        participantCCs: [],
      });
      form.setFieldsValue({
        participantTos: [],
        participantCCs: [],
      });
    }
  }, [allParticipants, form3, selectTosNewCut, selectTosNewCutGroup]);

  useEffect(() => {
    if (!isEmpty(selectedGroupDataToBeUpdated)) {
      let userIdsForTo = [];
      let userIdsForCc = [];
      selectedGroupDataToBeUpdated?.userList?.map((x) => {
        if (x?.participantTypeDto?.id === 0) {
          userIdsForTo.push(x?.userId);
        } else {
          userIdsForCc.push(x?.userId);
        }
      });
      form4.setFieldsValue({
        groupName: selectedGroupDataToBeUpdated?.groupName,
        userListTo: userIdsForTo,
        userListCc: userIdsForCc,
      });
    }

    if (groupDataLoadingSuccess) {
      setCreateGroupModal(false);
      setSelectTosNewCutGroup();
    }
  });

  useEffect(() => {
    if (
      emailValue ||
      subjectValue ||
      searchValueReference ||
      value ||
      openAttachmentAdvanceSearch ||
      startDate
    ) {
      setAdvanceButtonDisabled(false);
    } else {
      setAdvanceButtonDisabled(true);
    }
  }, [
    emailValue,
    subjectValue,
    searchValueReference,
    value,
    openAttachmentAdvanceSearch,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (!isEmpty(getPredefinedMessageResponse)) {
      setPredefinedData(getPredefinedMessageResponse?.items);
    }
  }, [getPredefinedMessageResponse]);

  //alt&Enter
  useEffect(() => {
    if (AltEnt) {
      if (!isEmpty(editorData)) {
        newCuttingFormFinish(form3.getFieldsValue());
      } else {
        handleSaveContinue();
      }

      setAltEnt(false);
    }
  }, [AltEnt]);

  const handleEmailSharedDetails = (
    accountHolderAppUserId,
    msgCenterTypeName,
    emailType
  ) => {
    if (msgCenterTypeName === CONSTANT.TO) {
      dispatch(selectedActiveEmail("changePagination"));
    }

    setCorporateSharedAccessAccountHolderUserId(accountHolderAppUserId);
    setSearchKeyword("");
    setAdvancedSearchData({});
    setAdvancedFiltersData({});
    setShowResetButton(false);
    setShowSideMenuEmail(false);
    if (
      emailType === CONSTANT.CIRCULAR &&
      (msgCenterTypeName === CONSTANT.SENT_CIRCULAR_FROM ||
        msgCenterTypeName === CONSTANT.INBOX_LOWERCASE)
    ) {
      getData(
        pageSize,
        0,
        false,
        msgCenterTypeName,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        1
      );
    } else {
      getData(
        pageSize,
        0,
        false,
        emailType === "flag_type"
          ? emailType
          : emailType === "Business"
          ? "subordinates"
          : msgCenterTypeName,
        emailType === "flag_type"
          ? 1
          : msgCenterTypeName === CONSTANT.CEAD
          ? 0
          : 5
      );
    }

    setSelectTopEmail(true);
    setMessagePagination([]);
    setPreSelectEmailCard(true);
    setCurrentSelectedMailMessage("");
    setInfiniteScroll(false);
    setCircularId(emailType === CONSTANT.CIRCULAR ? 1 : "");
    setMessageCenterTypeName(msgCenterTypeName);
    setSelectedEmailType({
      typeDisplayName:
        msgCenterTypeName === "all"
          ? CONSTANT.SHAREDMAIL_INBOX
          : msgCenterTypeName === CONSTANT.ALL
          ? CONSTANT.INBOX
          : msgCenterTypeName === CONSTANT.TENDERFAVOURITE
          ? CONSTANT.TENDERFAVOURITEINBOX
          : msgCenterTypeName === CONSTANT.TO
          ? CONSTANT.INBOX_TO
          : msgCenterTypeName === CONSTANT.CC
          ? CONSTANT.INBOX_CC
          : msgCenterTypeName === CONSTANT.BCC
          ? CONSTANT.INBOX_BCC
          : msgCenterTypeName === CONSTANT.JUNK
          ? CONSTANT.INBOX_JUNK
          : msgCenterTypeName === CONSTANT.OUTBOX
          ? CONSTANT.OUTBOX
          : msgCenterTypeName === CONSTANT.FROM &&
            emailType !== CONSTANT.CIRCULAR &&
            emailType !== "sharedMailSent"
          ? CONSTANT.SENT
          : msgCenterTypeName === CONSTANT.INBOX_LOWERCASE &&
            emailType === CONSTANT.CIRCULAR
          ? CONSTANT.INBOX_CIRCULAR_DISPLAY
          : msgCenterTypeName === CONSTANT.SENT_CIRCULAR_FROM &&
            emailType === CONSTANT.CIRCULAR
          ? CONSTANT.SENT_CIRCULAR_FROM_DISPLAY
          : msgCenterTypeName === CONSTANT.PENDING
          ? CONSTANT.PENDING
          : msgCenterTypeName === CONSTANT.ARCHIEVED
          ? CONSTANT.ARCHIEVED
          : msgCenterTypeName === CONSTANT.STARRED_FLAG_NAME
          ? CONSTANT.STARRED_DISPLAY_NAME
          : msgCenterTypeName === CONSTANT.DRAFT
          ? CONSTANT.DRAFT
          : msgCenterTypeName === "From" && emailType === "sharedMailSent"
          ? CONSTANT.SHAREDMAIL_SENT
          : msgCenterTypeName === CONSTANT.TRASH
          ? CONSTANT.TRASH
          : msgCenterTypeName === "Application"
          ? CONSTANT.APPROVAL
          : msgCenterTypeName === CONSTANT.PROCUREMENT
          ? CONSTANT.PROCUREMENT_APPROVAL
          : msgCenterTypeName === CONSTANT.LOAN
          ? CONSTANT.LOAN_APPROVAL
          : msgCenterTypeName === CONSTANT.LEAVE
          ? CONSTANT.LEAVE_APPROVAL
          : msgCenterTypeName === CONSTANT.BUSINESS
          ? CONSTANT.TENDER
          : msgCenterTypeName === CONSTANT.CORPORATE
          ? CONSTANT.CORPORATE
          : msgCenterTypeName === CONSTANT.GROUP_CHAT
          ? CONSTANT.GROUP_CHAT
          : msgCenterTypeName === CONSTANT.GROUP_CHAT_ARCHIEVE
          ? CONSTANT.GROUP_CHAT_ARCHIEVE
          : msgCenterTypeName === "flagtype1"
          ? "Special Flag 1"
          : msgCenterTypeName === "flagtype2"
          ? "Special Flag 2"
          : msgCenterTypeName === "flagtype3"
          ? "Special Flag 3"
          : msgCenterTypeName === "flagtype4"
          ? "Special Flag 4"
          : msgCenterTypeName === "flagtype5"
          ? "Special Flag 5"
          : msgCenterTypeName === CONSTANT.UNSOLICITED
          ? "Un Solicited"
          : msgCenterTypeName === CONSTANT.CEAD
          ? "CEAD"
          : messageCenterTypeName,
      typeSystemName:
        msgCenterTypeName === "all"
          ? CONSTANT.ALL
          : msgCenterTypeName === CONSTANT.ALL
          ? CONSTANT.INBOX
          : msgCenterTypeName === CONSTANT.TENDERFAVOURITE
          ? CONSTANT.FAVOURITE
          : msgCenterTypeName === CONSTANT.TO
          ? CONSTANT.TO
          : msgCenterTypeName === CONSTANT.CC
          ? CONSTANT.CC
          : msgCenterTypeName === CONSTANT.BCC
          ? CONSTANT.BCC
          : msgCenterTypeName === CONSTANT.JUNK
          ? CONSTANT.JUNK
          : msgCenterTypeName === CONSTANT.OUTBOX
          ? CONSTANT.OUTBOX
          : msgCenterTypeName === CONSTANT.FROM &&
            emailType !== CONSTANT.CIRCULAR &&
            emailType !== "sharedMailSent"
          ? CONSTANT.FROM
          : msgCenterTypeName === CONSTANT.INBOX_LOWERCASE &&
            emailType === CONSTANT.CIRCULAR
          ? CONSTANT.INBOX_LOWERCASE
          : msgCenterTypeName === CONSTANT.SENT_CIRCULAR_FROM &&
            emailType === CONSTANT.CIRCULAR
          ? CONSTANT.SENT_CIRCULAR_FROM
          : msgCenterTypeName === CONSTANT.PENDING
          ? CONSTANT.PENDING
          : msgCenterTypeName === CONSTANT.ARCHIEVED
          ? CONSTANT.ARCHIEVED
          : msgCenterTypeName === CONSTANT.STARRED_FLAG_NAME
          ? CONSTANT.STARRED_FLAG_NAME
          : msgCenterTypeName === CONSTANT.DRAFT
          ? CONSTANT.DRAFT
          : msgCenterTypeName === CONSTANT.FROM &&
            emailType === "sharedMailSent"
          ? "From"
          : msgCenterTypeName === CONSTANT.TRASH
          ? CONSTANT.TRASH
          : msgCenterTypeName === "Application"
          ? CONSTANT.APPROVAL
          : msgCenterTypeName === CONSTANT.PROCUREMENT
          ? CONSTANT.PROCUREMENT
          : msgCenterTypeName === CONSTANT.LOAN
          ? CONSTANT.LOAN
          : msgCenterTypeName === CONSTANT.LEAVE
          ? CONSTANT.LEAVE
          : msgCenterTypeName === CONSTANT.BUSINESS
          ? CONSTANT.BUSINESS
          : msgCenterTypeName === CONSTANT.CORPORATE
          ? CONSTANT.CORPORATE
          : msgCenterTypeName === CONSTANT.GROUP_CHAT
          ? CONSTANT.GROUP_CHAT
          : msgCenterTypeName === CONSTANT.GROUP_CHAT_ARCHIEVE
          ? CONSTANT.GROUP_CHAT_ARCHIEVE
          : msgCenterTypeName === "flagtype1"
          ? "flagtype1"
          : msgCenterTypeName === "flagtype2"
          ? "flagtype2"
          : msgCenterTypeName === "flagtype3"
          ? "flagtype3"
          : msgCenterTypeName === "flagtype4"
          ? "flagtype4"
          : msgCenterTypeName === "flagtype5"
          ? "flagtype5"
          : msgCenterTypeName === CONSTANT.UNSOLICITED
          ? CONSTANT.UNSOLICITED
          : msgCenterTypeName === CONSTANT.CEAD
          ? "CEAD"
          : messageCenterTypeName,
      count: mailMessageCenterPagination.totalCount,
      icon:
        msgCenterTypeName === CONSTANT.OUTBOX
          ? mailOutbox
          : msgCenterTypeName === CONSTANT.PENDING ||
            msgCenterTypeName === CONSTANT.ARCHIEVED
          ? pending
          : msgCenterTypeName === CONSTANT.STARRED_FLAG_NAME
          ? starred
          : msgCenterTypeName === CONSTANT.DRAFT
          ? draft
          : msgCenterTypeName === CONSTANT.TRASH
          ? trash
          : mailInbox,
      totalLength: 10,
      currentTab: currentTab,
      // subMenu: accountHolderAppUserId,
    });
    setCurrentSelectedMailMessage("");
    formSearch.resetFields();
  };

  const newCuttingFormFinish = (values) => {
    const newReference = { referenceCode: values?.mailMessageReferenceDtos };

    form3.resetFields();
    setEditorData("");
    setSelectTosNewCut(false);

    const body = {
      ...values,
      isQuickMessage: true,
      // mailMessageParticipantWithTypeDtos:{participantTos:values?.participantTos?.map((x)=participantUserId:x),participantCCs:values.participantCCs},
      mailMessageParticipantWithTypeDtos: {
        participantTos: values?.participantTos?.map((x) => ({
          participantUserId: x,
        })),
        participantCCs: values?.participantCCs?.map((x) => ({
          participantUserId: x,
        })),
      },
      mailMessageReferenceDtos: [newReference],
      instanceMessageStatusName: "Normal",
      remarks: editorData,
      applicationTypeId: applicationType?.id,
    };

    dispatch(documentInstantMessage(body)).then(setNewCuttinghVisible(false));
  };

  const menuGroup = () => (
    <Menu>
      {groupData &&
        groupData?.map((x) => {
          return (
            <Menu.Item
              key={x?.id}
              onClick={() => {
                let userIdsForTo = [];
                let userIdsForCc = [];
                x?.userList?.map((x) => {
                  if (x?.participantTypeDto?.id === 0) {
                    userIdsForTo.push(x?.userId);
                  } else {
                    userIdsForCc.push(x?.userId);
                  }
                });
                setSelectTosNewCutGroup(userIdsForTo);
                setSelectCcsNewCutGroup(userIdsForCc);
              }}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{x?.groupName}</div>

              <EditOutlined
                style={{ paddingLeft: 15 }}
                onClick={() => {
                  setGroupDataToBeUpdate(x);
                  setCreateGroupModal(true);
                }}
              />
            </Menu.Item>
          );
        })}

      <Menu.Item
        key="Create Group"
        onClick={() => {
          setCreateGroupModal(true);
          setGroupDataToBeUpdate();
          form4.resetFields();
        }}
      >
        Create Group
      </Menu.Item>
    </Menu>
  );

  let paramsForGroupListing = "SkipCount=0&MaxResultCount=100";
  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, []);

  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, [selectedGroupDataToBeUpdated, createGroupModal]);

  const onGroupCreateFinish = (values) => {
    const tos = values?.userListTo?.map((x) => {
      return {
        userId: x,
        participantTypeId: 0,
      };
    });
    const ccs = values?.userListCc?.map((x) => {
      return {
        userId: x,
        participantTypeId: 1,
      };
    });
    const to_cc = [...tos, ...ccs];

    let apiDataForGroupCreation = {
      groupName: values?.groupName,
      userList: to_cc,
      isActive: true,
    };

    if (isEmpty(selectedGroupDataToBeUpdated)) {
      dispatch(actionForCreateGroup(apiDataForGroupCreation));
    } else {
      apiDataForGroupCreation = {
        ...apiDataForGroupCreation,
        id: selectedGroupDataToBeUpdated?.id,
      };
      dispatch(
        actionForUpdateGroup(
          apiDataForGroupCreation,
          selectedGroupDataToBeUpdated?.id
        )
      ).then((res) => {
        setGroupDataToBeUpdate(
          values?.userList?.map((x) => {
            return {
              userId: x,
            };
          })
        );
      });
    }
  };

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgress,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
    });
    console.log("responsess", resp);
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      // setAttatchments(alteredResponse);
      let olduploadedFileDtos = form.getFieldValue().uploadedFileDtos
        ? [...form.getFieldValue().uploadedFileDtos]
        : [];

      let newUploadedFileDtos = [...olduploadedFileDtos];
      newUploadedFileDtos.push(alteredResponse);
      // uploadedFileDtos[i] = e;
      form.setFieldsValue({
        uploadedFileDtos: newUploadedFileDtos,
      });
      console.log("newUploadedFileDtos", newUploadedFileDtos);
      // onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  return (
    <div
      className={`emailInfo ${toggleMail ? "" : "full-row"}`}
      onMouseLeave={() => setIsShown(false)}
    >
      <div
        className="emailInfo__form top-header-email"
        onMouseLeave={() => setIsShown(false)}
      >
        {showSideMenuEmail ||
          (isShown && (
            <div className={`sidebars__menu--email`}>
              <div
                className="sidemenu-scroll"
                style={{ height: "450px", overflow: "scroll" }}
              >
                <div className="side-padding">
                  <Menu
                    defaultSelectedKeys={[selectedMailTypeAndMailData?.subMenu]}
                    mode="inline"
                    expandIcon={(e) => {
                      return (
                        <div style={{ position: "absolute", right: 10 }}>
                          <DownOutlined
                            style={{ fontSize: 9 }}
                            onClick={() => {
                              if (e.index == 0) {
                                setShowInboxSubMeu(true);
                                setShowApprovalsInboxMenu(false);
                                setShowFlagSubMeu(false);
                              } else if (e.index == 8) {
                                setShowApprovalsInboxMenu(true);
                                setShowInboxSubMeu(false);
                                setShowFlagSubMeu(false);
                              } else if (e.index == 11) {
                                setShowFlagSubMeu(true);
                                setShowInboxSubMeu(false);
                                setShowApprovalsInboxMenu(false);
                              }
                            }}
                          />
                        </div>
                      );
                    }}
                    className="sidebars__menu--items sidebars__menu--body"
                  >
                    <SubMenu
                      className={`sub-menu-wrapper ${
                        selectedEmailType.typeDisplayName === CONSTANT.INBOX
                          ? "active"
                          : ""
                      }`}
                      style={{ paddingLeft: 0 }}
                      skype
                      key="sub1"
                      icon={
                        <div>
                          <Avatar
                            className="inbox--icon"
                            onClick={() => {
                              setShowInboxSubMeu(false);
                            }}
                            src={mailInbox}
                            shape="square"
                          />
                        </div>
                      }
                      title={
                        <div
                          key="sub10"
                          className={`sidebars__menu--item all-menu-list inbox  ${
                            selectedEmailType.typeDisplayName === CONSTANT.INBOX
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            handleEmailSharedDetails("", CONSTANT.ALL);
                          }}
                        >
                          <>
                            Inbox
                            <div className="badge badge--notificationSmall">
                              {selectedMailTypeAndMailData?.typeSystemName ? (
                                <div
                                  className="inbox-counter-wrapper"
                                  style={{ display: "flex" }}
                                >
                                  <div>(</div>
                                  <div>
                                    {Number(
                                      nullChecker(
                                        mailPaginationCount?.allCount?.all,
                                        "unread"
                                      )
                                    ).toLocaleString()}
                                    &nbsp;/&nbsp;
                                  </div>
                                  <div>
                                    {Number(
                                      nullChecker(
                                        mailPaginationCount?.allCount?.all,
                                        "all"
                                      )
                                    ).toLocaleString()}
                                  </div>
                                  <div>)</div>
                                </div>
                              ) : (
                                "---"
                              )}
                            </div>
                          </>
                        </div>
                      }
                    >
                      {showInboxSubMenu && (
                        <>
                          <Menu.Item
                            key="sub14"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === "Favourite"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails(
                                "",
                                CONSTANT.TENDERFAVOURITE
                              );
                            }}
                          >
                            <>
                              Favorite
                              <div className="badge badge--notificationSmall">
                                {/* {mailPaginationCount?.junkCount?.all?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.junkCount?.all,
                                      "all"
                                    )
                                  ).toLocaleString()} */}
                                -
                              </div>
                            </>
                          </Menu.Item>

                          <Menu.Item
                            key="sub11"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === CONSTANT.TO
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails("", CONSTANT.TO);
                            }}
                          >
                            <>
                              To
                              <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.toCount?.all?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.toCount?.all,
                                      "all"
                                    )
                                  )?.toLocaleString()}
                              </div>
                            </>
                          </Menu.Item>
                          <Menu.Item
                            key="sub12"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === CONSTANT.CC
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails("", CONSTANT.CC);
                            }}
                          >
                            <>
                              Cc
                              <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.ccCount?.all?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.ccCount?.all,
                                      "all"
                                    )
                                  ).toLocaleString()}
                              </div>
                            </>
                          </Menu.Item>
                          <Menu.Item
                            key="sub13"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === CONSTANT.BCC
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails("", CONSTANT.BCC);
                            }}
                          >
                            <>
                              Bcc
                              <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.bccCount?.all?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.bccCount?.all,
                                      "all"
                                    )
                                  ).toLocaleString()}
                              </div>
                            </>
                          </Menu.Item>

                          <Menu.Item
                            key="sub15"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === CONSTANT.JUNK
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails("", CONSTANT.JUNK);
                            }}
                          >
                            <>
                              Junk
                              <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.junkCount?.all?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.junkCount?.all,
                                      "all"
                                    )
                                  ).toLocaleString()}
                              </div>
                            </>
                          </Menu.Item>

                          <Menu.Item
                            key="sub16"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName ===
                              CONSTANT.INBOX_LOWERCASE
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails(
                                "",
                                CONSTANT.INBOX_LOWERCASE,
                                CONSTANT.CIRCULAR
                              );
                            }}
                          >
                            <>
                              Circular
                              <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.circularInboxCount?.all
                                  ?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.circularInboxCount
                                        ?.all,
                                      "all"
                                    )
                                  ).toLocaleString()}
                              </div>
                            </>
                          </Menu.Item>

                          <Menu.Item
                            key="subInbox17"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName ===
                              CONSTANT.UNSOLICITED
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails(
                                "",
                                CONSTANT.UNSOLICITED
                              );
                            }}
                          >
                            <>
                              Un-Solicited Mails
                              {/* <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.circularInboxCount?.all
                                  ?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.circularInboxCount
                                        ?.all,
                                      "all"
                                    )
                                  ).toLocaleString()}
                              </div> */}
                            </>
                          </Menu.Item>
                        </>
                      )}
                    </SubMenu>

                    {/* <div className="sidebars__menu--body"> */}
                    <Menu.Item
                      key="1"
                      icon={<Avatar src={mailOutbox} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType?.typeSystemName === CONSTANT.OUTBOX
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails("", CONSTANT.OUTBOX);
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title">Outbox</span>
                        <span className="mail-child-counter">
                          {mailPaginationCount?.outBoxCount?.all?.unread +
                            "/" +
                            Number(
                              nullChecker(
                                mailPaginationCount?.outBoxCount?.all,
                                "all"
                              )
                            ).toLocaleString()}
                        </span>
                      </div>
                    </Menu.Item>

                    {/* Sents */}
                    <SubMenu
                      className={`sub-menu-wrapper ${
                        selectedEmailType.typeSystemName === CONSTANT.FROM
                          ? "active"
                          : ""
                      }`}
                      style={{ paddingLeft: 0 }}
                      skype
                      key="subSent1"
                      icon={
                        <div>
                          <Avatar
                            className="inbox--icon"
                            onClick={() => {
                              setShowInboxSubMeu(false);
                            }}
                            src={mailInbox}
                            shape="square"
                          />
                        </div>
                      }
                      title={
                        <div
                          key="subSent10"
                          className={`sidebars__menu--item all-menu-list inbox  ${
                            selectedEmailType.typeSystemName === CONSTANT.FROM
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            handleEmailSharedDetails("", CONSTANT.FROM);
                          }}
                        >
                          <>
                            Sent
                            <div className="badge badge--notificationSmall">
                              {selectedMailTypeAndMailData?.typeSystemName ? (
                                <div
                                  className="inbox-counter-wrapper"
                                  style={{ display: "flex" }}
                                >
                                  <div>(</div>

                                  <div>
                                    {Number(
                                      nullChecker(
                                        mailPaginationCount?.fromCount?.all,
                                        "unread"
                                      )
                                    ).toLocaleString()}
                                    &nbsp;/&nbsp;
                                  </div>
                                  <div>
                                    {Number(
                                      nullChecker(
                                        mailPaginationCount?.fromCount?.all,
                                        "all"
                                      )
                                    ).toLocaleString()}
                                  </div>
                                  <div>)</div>
                                </div>
                              ) : (
                                "---"
                              )}
                            </div>
                          </>
                        </div>
                      }
                    >
                      <Menu.Item
                        key="subSent16"
                        className={`sidebars__menu--item side-drop-item ${
                          selectedEmailType.typeSystemName ===
                          CONSTANT.SENT_CIRCULAR_FROM
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          handleEmailSharedDetails(
                            "",
                            CONSTANT.SENT_CIRCULAR_FROM,
                            CONSTANT.CIRCULAR
                          );
                        }}
                      >
                        <>
                          Circular
                          <div className="badge badge--notificationSmall">
                            {mailPaginationCount?.junkCount?.all?.unread +
                              "/" +
                              Number(
                                nullChecker(
                                  mailPaginationCount?.circularSentCount?.all,
                                  "all"
                                )
                              ).toLocaleString()}
                            {/* -- */}
                          </div>
                        </>
                      </Menu.Item>
                    </SubMenu>

                    <Menu.Item
                      key="3"
                      icon={<Avatar src={pending} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType.typeDisplayName === CONSTANT.PENDING
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails("", CONSTANT.PENDING);
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title">Pending</span>
                        <span className="mail-child-counter">
                          {mailPaginationCount?.pendingCount?.all?.unread +
                            "/" +
                            Number(
                              nullChecker(
                                mailPaginationCount?.pendingCount?.all,
                                "all"
                              )
                            ).toLocaleString()}
                        </span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      key="4"
                      icon={<Avatar src={pending} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType.typeDisplayName === "Archived"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails("", CONSTANT.ARCHIEVED);
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title">Archived</span>
                        <span className="mail-child-counter">
                          {mailPaginationCount?.archievedCount?.all?.unread +
                            "/" +
                            Number(
                              nullChecker(
                                mailPaginationCount?.archievedCount?.all,
                                "all"
                              )
                            )?.toLocaleString()}
                        </span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      key="5"
                      icon={<Avatar src={starred} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType.typeSystemName ===
                        CONSTANT.STARRED_FLAG_NAME
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails(
                          "",
                          CONSTANT.STARRED_FLAG_NAME
                        );
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title">Starred</span>
                        <span className="mail-child-counter">
                          {mailPaginationCount?.flagCount?.all?.unread +
                            "/" +
                            Number(
                              nullChecker(
                                mailPaginationCount?.flagCount?.all,
                                "all"
                              )
                            ).toLocaleString()}
                        </span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      key="6"
                      icon={<Avatar src={draft} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType.typeSystemName === CONSTANT.DRAFT
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails("", CONSTANT.DRAFT);
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title"> Drafts</span>
                        <span className="mail-child-counter">
                          {mailPaginationCount?.drafCount?.all?.unread +
                            "/" +
                            Number(
                              nullChecker(
                                mailPaginationCount?.drafCount?.all,
                                "all"
                              )
                            ).toLocaleString()}
                        </span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      key="7"
                      icon={<Avatar src={trash} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType.typeSystemName === CONSTANT.TRASH
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails("", CONSTANT.TRASH);
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title"> Trash</span>
                        <span className="mail-child-counter">
                          {" "}
                          {mailPaginationCount?.trashCount?.all?.unread +
                            "/" +
                            Number(
                              nullChecker(
                                mailPaginationCount?.trashCount?.all,
                                "all"
                              )
                            )?.toLocaleString()}
                        </span>
                      </div>
                    </Menu.Item>
                    <SubMenu
                      className={`sub-menu-wrapper ${
                        selectedEmailType.typeDisplayName === CONSTANT.APPROVAL
                          ? "active"
                          : ""
                      }`}
                      style={{ paddingLeft: 0 }}
                      skype
                      key="subApproval"
                      icon={
                        <div>
                          <Avatar
                            className="inbox--icon"
                            onClick={() => {
                              setShowInboxSubMeu(false);
                            }}
                            src={mailInbox}
                            shape="square"
                          />
                        </div>
                      }
                      title={
                        <div
                          key="sub20"
                          className={`sidebars__menu--item all-menu-list inbox  ${
                            selectedEmailType.typeDisplayName ===
                            CONSTANT.APPROVAL
                              ? "active"
                              : ""
                          }`}
                          onCLick={() => {
                            handleEmailSharedDetails("", "Application");
                          }}
                        >
                          <>
                            Approval
                            <div className="badge badge--notificationSmall">
                              {selectedMailTypeAndMailData?.typeSystemName ? (
                                <div
                                  className="inbox-counter-wrapper"
                                  style={{ display: "flex" }}
                                >
                                  <div>(</div>
                                  <div>
                                    {Number(
                                      nullChecker(
                                        mailPaginationCount?.approvalCount?.all,
                                        "unread"
                                      )
                                    ).toLocaleString()}
                                    /{/* -- &nbsp;/&nbsp; */}
                                  </div>
                                  <div>
                                    {Number(
                                      nullChecker(
                                        mailPaginationCount?.approvalCount?.all,
                                        "all"
                                      )
                                    ).toLocaleString()}
                                    {/* -- */}
                                  </div>
                                  <div>)</div>
                                </div>
                              ) : (
                                "---"
                              )}
                            </div>
                          </>
                        </div>
                      }
                    >
                      {showApprovalsInboxMenu && (
                        <>
                          <Menu.Item
                            key="sub21"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName ===
                              CONSTANT.PROCUREMENT
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails(
                                "",
                                CONSTANT.PROCUREMENT
                              );
                            }}
                          >
                            <>
                              Procurement
                              <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.procurementCount?.all
                                  ?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.procurementCount
                                        ?.all,
                                      "all"
                                    )
                                  )?.toLocaleString()}
                              </div>
                            </>
                          </Menu.Item>
                          <Menu.Item
                            key="sub22"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === CONSTANT.LOAN
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails("", CONSTANT.LOAN);
                            }}
                          >
                            <>
                              Loan
                              <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.loanCount?.all?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.loanCount?.all,
                                      "all"
                                    )
                                  ).toLocaleString()}
                              </div>
                            </>
                          </Menu.Item>

                          <Menu.Item
                            key="sub23"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName ===
                              CONSTANT.LEAVE
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails("", CONSTANT.LEAVE);
                            }}
                          >
                            <>
                              Leave
                              <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.leaveCount?.all?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.leaveCount?.all,
                                      "all"
                                    )
                                  ).toLocaleString()}
                                {/* -- */}
                              </div>
                            </>
                          </Menu.Item>

                          <Menu.Item
                            key="sub245"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === CONSTANT.CEAD
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails("", CONSTANT.CEAD);
                            }}
                          >
                            <>CEAD</>
                          </Menu.Item>
                        </>
                      )}
                    </SubMenu>
                    <Menu.Item
                      key="9"
                      icon={<Avatar src={mailInbox} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType.typeSystemName === CONSTANT.BUSINESS
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails("", CONSTANT.BUSINESS);
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title"> Tender</span>
                        <span className="mail-child-counter">
                          {mailPaginationCount?.tenderBusinessCount?.all
                            ?.unread +
                            "/" +
                            Number(
                              nullChecker(
                                mailPaginationCount?.tenderBusinessCount?.all,
                                "all"
                              )
                            )?.toLocaleString()}
                        </span>
                      </div>
                    </Menu.Item>

                    {/* <Menu.Item
                      key="10"
                      icon={<Avatar src={mailInbox} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType.typeSystemName === CONSTANT.CORPORATE
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails("", CONSTANT.CORPORATE);
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title"> Corporate</span>
                        <span className="mail-child-counter">
                          {" "}
                          {mailPaginationCount?.trashCount?.all?.unread + "/" + Number(
                            nullChecker(
                              mailPaginationCount?.trashCount?.all,
                              "all"
                            )
                          )?.toLocaleString()}
                          --
                        </span>
                      </div>
                    </Menu.Item> */}

                    <SubMenu
                      className={`sub-menu-wrapper ${
                        // selectedEmailType.typeDisplayName === "Special Flag"
                        selectedEmailType?.typeSystemName === "flagtype1"
                          ? "active"
                          : ""
                      }`}
                      style={{ paddingLeft: 0 }}
                      skype
                      key="subSpecialFlag"
                      icon={
                        <div>
                          <Avatar
                            className="inbox--icon"
                            onClick={() => {
                              setShowFlagSubMeu(true);
                            }}
                            src={mailInbox}
                            shape="square"
                          />
                        </div>
                      }
                      title={
                        <div
                          key="subSpecialFlag10"
                          className={`sidebars__menu--item all-menu-list inbox  ${
                            selectedEmailType?.typeSystemName === "flagtype1" ||
                            selectedEmailType?.typeSystemName === "flagtype2" ||
                            selectedEmailType?.typeSystemName === "flagtype3" ||
                            selectedEmailType?.typeSystemName === "flagtype4" ||
                            selectedEmailType?.typeSystemName === "flagtype5"
                              ? "active"
                              : ""
                          }`}
                        >
                          <>
                            Special Flag
                            <div className="badge badge--notificationSmall"></div>
                          </>
                        </div>
                      }
                    >
                      {showFlagSubMenu && (
                        <>
                          <Menu.Item
                            key="subFlag1"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === "flagtype1"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails(
                                "",
                                "flagtype1",
                                "flag_type"
                              );
                            }}
                          >
                            <>
                              Flag Type 1
                              {/* <div className="badge badge--notificationSmall">
                                {mailPaginationCount?.toCount?.all?.unread +
                                  "/" +
                                  Number(
                                    nullChecker(
                                      mailPaginationCount?.toCount?.all,
                                      "all"
                                    )
                                  )?.toLocaleString()}
                              </div> */}
                            </>
                          </Menu.Item>
                          <Menu.Item
                            key="subFlag2"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === "flagtype2"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails(
                                "",
                                "flagtype2",
                                "flag_type"
                              );
                            }}
                          >
                            <>Flag Type 2</>
                          </Menu.Item>
                          <Menu.Item
                            key="subFlag3"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === "flagtype3"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails(
                                "",
                                "flagtype3",
                                "flag_type"
                              );
                            }}
                          >
                            <>Flag Type 3</>
                          </Menu.Item>
                          <Menu.Item
                            key="subFlag4"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === "flagtype4"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails(
                                "",
                                "flagtype4",
                                "flag_type"
                              );
                            }}
                          >
                            <>Flag Type 4</>
                          </Menu.Item>
                          <Menu.Item
                            key="subFlag5"
                            className={`sidebars__menu--item side-drop-item ${
                              selectedEmailType.typeSystemName === "flagtype5"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              handleEmailSharedDetails(
                                "",
                                "flagtype5",
                                "flag_type"
                              );
                            }}
                          >
                            <>Flag Type 5</>
                          </Menu.Item>
                        </>
                      )}
                    </SubMenu>

                    <SubMenu
                      className={`sub-menu-wrapper ${
                        selectedEmailType.typeDisplayName === "sharedMail"
                          ? "active"
                          : ""
                      }`}
                      style={{ paddingLeft: 0 }}
                      skype
                      key="sharedMail"
                      icon={
                        <div>
                          <Avatar
                            className="inbox--icon"
                            // onClick={() => {
                            //   showFlagSubMenu(false);
                            // }}
                            src={mailInbox}
                            shape="square"
                          />
                        </div>
                      }
                      title={
                        <div
                          key="sharedMail"
                          className={`sidebars__menu--item all-menu-list inbox  ${
                            selectedEmailType.typeSystemName === "sharedMail"
                              ? "active"
                              : ""
                          }`}
                        >
                          <>
                            Shared Mail
                            <div className="badge badge--notificationSmall"></div>
                          </>
                        </div>
                      }
                    >
                      <>
                        <SubMenu
                          title={
                            <div
                              key="sharedMail"
                              className={`sidebars__menu--item all-menu-list inbox  ${
                                selectedEmailType.typeSystemName ===
                                "sharedMailInbox"
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <>
                                Inbox
                                <div className="badge badge--notificationSmall"></div>
                              </>
                            </div>
                          }
                        >
                          {getSharedEmailListsResponse?.map((x) => {
                            if (x?.isInboxAccess) {
                              return (
                                <Menu.Item
                                  key={x?.emailAddress}
                                  className={`sidebars__menu--item side-drop-item
                                    ${
                                      selectedEmailType.typeSystemName ===
                                      "sharedMailInbox"
                                        ? "active"
                                        : ""
                                    }
                                    `}
                                  onClick={() =>
                                    handleEmailSharedDetails(
                                      x?.accountHolderAppUserId,
                                      "all"
                                    )
                                  }
                                >
                                  <>{x?.emailAddress}</>
                                </Menu.Item>
                              );
                            }
                          })}
                        </SubMenu>

                        <SubMenu
                          title={
                            <div
                              key="sharedMailSent"
                              className={`sidebars__menu--item all-menu-list inbox  ${
                                selectedEmailType.typeDisplayName ===
                                CONSTANT.SHAREDMAIL_SENT
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <>
                                Sent
                                <div className="badge badge--notificationSmall"></div>
                              </>
                            </div>
                          }
                        >
                          {getSharedEmailListsResponse?.map((x) => {
                            if (x?.isSentAccess) {
                              return (
                                <Menu.Item
                                  key={x?.emailAddress}
                                  className={`sidebars__menu--item side-drop-item
                                    ${
                                      selectedEmailType.typeDisplayName ===
                                      CONSTANT.SHAREDMAIL_SENT
                                        ? "active"
                                        : ""
                                    }
                                    `}
                                  onClick={() =>
                                    handleEmailSharedDetails(
                                      x?.accountHolderAppUserId,
                                      CONSTANT.FROM,
                                      CONSTANT.EMAIL_TYPE_SHAREDMAIL_SENT
                                    )
                                  }
                                >
                                  <>{x?.emailAddress}</>
                                </Menu.Item>
                              );
                            }
                          })}
                        </SubMenu>
                      </>
                    </SubMenu>

                    <Menu.Item
                      key="GroupChat4"
                      icon={<Avatar src={pending} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType.typeDisplayName === CONSTANT.GROUP_CHAT
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails("", CONSTANT.GROUP_CHAT);
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title">Group Chat</span>
                        <span className="mail-child-counter">
                          {mailPaginationCount?.groupChatCount?.all?.unread +
                            "/" +
                            Number(
                              nullChecker(
                                mailPaginationCount?.groupChatCount?.all,
                                "all"
                              )
                            )?.toLocaleString()}
                        </span>
                      </div>
                    </Menu.Item>

                    <Menu.Item
                      key="GroupChat5"
                      icon={<Avatar src={pending} shape="square" />}
                      className={`sidebars__body--item ${
                        selectedEmailType.typeDisplayName === CONSTANT.GROUP_CHAT_ARCHIEVE
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        handleEmailSharedDetails("", CONSTANT.GROUP_CHAT_ARCHIEVE);
                      }}
                    >
                      <div className="mailbox-child-title-wrapper">
                        <span className="mail-child-title">Chat Archived</span>
                        <span className="mail-child-counter">
                          {mailPaginationCount?.groupChatArchiveCount?.all?.unread +
                            "/" +
                            Number(
                              nullChecker(
                                mailPaginationCount?.groupChatArchiveCount?.all,
                                "all"
                              )
                            )?.toLocaleString()}
                        </span>
                      </div>
                    </Menu.Item>

                    <Menu.Divider style={{ marginTop: 15 }} />
                  </Menu>

                  <Menu mode="inline other__option_menu">
                    <SubMenu
                      key="sub2"
                      icon={<Avatar src={mailInbox} shape="square" />}
                      title="Requisition"
                    >
                      <Menu.Item
                        key="sub21"
                        icon={
                          <Image
                            src={label}
                            shape="square"
                            className="ant-avatar-square"
                          />
                        }
                        className="sidebars__menu--item side-drop-item"
                      >
                        Entertainment
                      </Menu.Item>
                      <Menu.Item
                        key="sub22"
                        icon={
                          <Image
                            src={label}
                            shape="square"
                            className="ant-avatar-square"
                          />
                        }
                        className="sidebars__menu--item side-drop-item"
                        onClick={() => {
                          setSearchKeyword("");
                          setShowSideMenuEmail(false);
                          setCurrentSelectedMailMessage("");
                          getData(pageSize, 0, false, "", ["Leave"]);
                          setSelectTopEmail(true);
                          setInfiniteScroll(false);
                          setMessageCenterTypeName("");
                          setSelectedEmailType({
                            typeDisplayName: "Leave",
                            typeSystemName: "Leave",
                            count: mailMessageCenterPagination.items.length,
                            icon: label,
                            totalLength: 10,
                            currentTab: currentTab,
                          });
                        }}
                      >
                        Leave
                      </Menu.Item>
                      <Menu.Item
                        key="sub23"
                        icon={
                          <Image
                            src={label}
                            shape="square"
                            className="ant-avatar-square"
                          />
                        }
                        className="sidebars__menu--item side-drop-item"
                      >
                        Loan
                      </Menu.Item>
                      <Menu.Item
                        key="sub24"
                        icon={
                          <Image
                            src={label}
                            shape="square"
                            className="ant-avatar-square"
                          />
                        }
                        className="sidebars__menu--item side-drop-item"
                      >
                        Procrument
                      </Menu.Item>
                    </SubMenu>

                    {/* <div className="sidebards__menu--body">
										<SubMenu key="sub3" icon={<Avatar src={mailIcon} shape="square" />} title="Requisitions">
											<Menu.Item key="sub31" icon={<Image src={arrowRight} shape="square" className="ant-avatar-square" />} className="sidebars__menu--bodyitem">
												Guest Entertainment App
											</Menu.Item>
											<Menu.Item key="sub32" icon={<Image src={arrowRight} shape="square" className="ant-avatar-square" />} className="sidebars__menu--bodyitem">
												Leave Application
											</Menu.Item>
											<Menu.Item key="sub33" icon={<Image src={arrowRight} shape="square" className="ant-avatar-square" />} className="sidebars__menu--bodyitem">
												Loan Application
											</Menu.Item>
											<Menu.Item key="sub34" icon={<Image src={arrowRight} shape="square" className="ant-avatar-square" />} className="sidebars__menu--bodyitem">
												<>
													Porucrement Request <div className="badge badge--notificationSmall">-</div>
												</>
											</Menu.Item>
										</SubMenu>
									</div> */}
                    <SubMenu
                      key="sub3"
                      icon={<Avatar src={mailInbox} shape="square" />}
                      title="Business"
                    >
                      <Menu.Item
                        key="sub21"
                        icon={
                          <Image
                            src={label}
                            shape="square"
                            className="ant-avatar-square"
                          />
                        }
                        className="sidebars__menu--item side-drop-item"
                      >
                        Tender Cuttings
                      </Menu.Item>
                      <Menu.Item
                        key="sub22"
                        icon={
                          <Image
                            src={label}
                            shape="square"
                            className="ant-avatar-square"
                          />
                        }
                        className="sidebars__menu--item side-drop-item"
                      >
                        News Cuttings
                      </Menu.Item>
                      <Menu.Item
                        key="sub23"
                        icon={
                          <Image
                            src={label}
                            shape="square"
                            className="ant-avatar-square"
                          />
                        }
                        className="sidebars__menu--item side-drop-item"
                      >
                        Fax
                      </Menu.Item>
                      <Menu.Item
                        key="sub24"
                        icon={
                          <Image
                            src={label}
                            shape="square"
                            className="ant-avatar-square"
                          />
                        }
                        className="sidebars__menu--item side-drop-item"
                      >
                        Letter
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sub4"
                      icon={<Avatar src={mailInbox} shape="square" />}
                      title="Others"
                    >
                      <Menu.Item
                        key="6"
                        icon={<Avatar src={label} shape="square" />}
                        className="sidebars__menu--item side-drop-item"
                      >
                        Contracts
                      </Menu.Item>
                      <Menu.Item
                        key="7"
                        icon={<Avatar src={label} shape="square" />}
                        className="sidebars__menu--item side-drop-item"
                      >
                        Archieved Mails
                      </Menu.Item>
                      <Menu.Item
                        key="8"
                        icon={<Avatar src={label} shape="square" />}
                        className="sidebars__menu--item side-drop-item"
                      >
                        Incomplete Contact List
                      </Menu.Item>
                      <Menu.Item
                        key="9"
                        icon={<Avatar src={label} shape="square" />}
                        className="sidebars__menu--item side-drop-item"
                      >
                        Unsolicit
                      </Menu.Item>
                      <Menu.Item
                        key="10"
                        icon={<Avatar src={label} shape="square" />}
                        className="sidebars__menu--item side-drop-item"
                      >
                        Fee Related
                      </Menu.Item>
                      <Menu.Item
                        key="11"
                        icon={<Avatar src={label} shape="square" />}
                        className="sidebars__menu--item side-drop-item"
                      >
                        Special Priority Related
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                </div>

                {/* <div className="sidebars__menuref">
								<div className="sidebars__menuref--wrap">
									<h4 className="sidebars__menuref--title">Favorite Reference</h4>
									<ul className="sidebars__menuref--items">
										<li className="sidebars__menuref--item">
											<a href="#">AFL-ICCON</a>
										</li>
										<li className="sidebars__menuref--item">
											<a href="#">AMPERS</a>
										</li>
										<li className="sidebars__menuref--item">
											<a href="#">CQ</a>
										</li>
										<li className="sidebars__menuref--item">
											<a href="#">ID-IT</a>
										</li>
										<li className="sidebars__menuref--item">
											<a href="#">Scan Document</a>
										</li>
									</ul>
								</div>
							</div> */}
              </div>
            </div>
          ))}

        {!hideMailListHeaderAndTabs ? (
          <div className="mail-main-header">
            <div className="main-title">MailBox</div>
            {(selectedMailTypeAndMailData?.typeSystemName === "Inbox" ||
              selectedMailTypeAndMailData?.typeSystemName === "OutBox") && (
              <>
                {mailPaginationCount?.outBoxCount?.all?.total > 0 && (
                  <div
                    className="system-error-wrapper"
                    style={{ cursor: "pointer" }}
                  >
                    {emailType !== "Business" && (
                      <ExclamationOutlined
                        onClick={() => {
                          setErrorModal(true);
                        }}
                      />
                    )}
                  </div>
                )}
                <div style={{ cursor: "pointer" }}>
                  <Image
                    src={refresh}
                    className={`${rotate ? "rotate" : ""}`}
                    onClick={() => {
                      animate();
                      if (emailType !== "Business") {
                        dispatch(
                          getMailMessageCenterPagination(
                            `skipCount=0&maxResultCount=10&IsRead_SearchKeyword=&IsInstanceMessage_SearchKeyword=&SortType=desc&IsFavourite_SearchKeyword=&messageCenterTypeName=${selectedMailTypeAndMailData?.typeSystemName}`
                          )
                        );
                        dispatch(
                          refreshUserIncomingEmails(
                            selectedMailTypeAndMailData?.typeSystemName
                          )
                        );
                        setReloader(!reloader);
                      } else {
                        getData();
                      }
                    }}
                    preview={false}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          "Email"
        )}
      </div>

      {origin !== "tender" && (
        <div className="mail-third-menu" onMouseLeave={() => setIsShown(false)}>
          <div
            className="search-wrapper p-0"
            onMouseLeave={() => setIsShown(false)}
          >
            <StarFilled
              style={{ color: isFavorite ? "#ffd036" : "#c8c8c8" }}
              onClick={() => {
                setIsFavorite(!isFavorite);
                setSelectTopEmail(true);
                setCurrentSelectedMailMessage("");
              }}
            />
            {!showResetButton ? (
              <Form form={formSearch}>
                <Form.Item name="search">
                  <Input.Search
                    className="form__group_email_search"
                    style={{ marginTop: 3 }}
                    allowClear
                    size="medium"
                    bordered={false}
                    placeholder="Search"
                    onSearch={(e) => {
                      setSearchKeyword(e);
                      setSelectTopEmail(true);
                      setCurrentSelectedMailMessage("");
                    }}
                    autoComplete="off"
                    // defaultValue={subjectValue ? subjectValue : ""}
                  />
                </Form.Item>
              </Form>
            ) : (
              <>
                <Input.Search
                  className="form__group_email_search"
                  style={{ margin: 0 }}
                  // allowClear
                  size="medium"
                  bordered={false}
                  value="[Advance Search]"
                />
                <button
                  className="clear_icon_advance_search"
                  onClick={() => {
                    setAdvancedSearchData({});
                    setAdvancedFiltersData({});
                    // getData();
                    // getData(pageSize, 0, false, "All");
                    // dispatch(getMailMessageCenterPagination(appendUrl, loadMore));
                    setShowResetButton(false);
                  }}
                >
                  <CloseCircleOutlined className="ant_close_icon" />
                </button>
              </>
            )}

            {emailType !== "Business" && (
              <>
                <div
                  className="side__search__wrapper"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setUnRead(!read);
                    setSelectTopEmail(true);
                    setCurrentSelectedMailMessage("");
                  }}
                >
                  {read ? <img src={switchIcon} /> : <img src={switchBlue} />}
                </div>
                <div
                  className="side__search__wrapper side-contact-search-filter"
                  style={{ cursor: "pointer" }}
                  //  onClick={() => {
                  //    setUnRead(!read);
                  //    setSelectTopEmail(true);
                  //    setCurrentSelectedMailMessage("");
                  //  }}
                  onClick={() => {
                    setShowAdvanceSearchModal(true);
                  }}
                >
                  <FilterOutlined />
                </div>
                {/* <Switch
               size="small"
               onChange={(e) => {
                 setMessagePagination([]);
                 onChange(e);
               }}
             /> */}

                {/* <Link
             to="/email-compose?applicationType=newEmail"
             target="_blank"
             rel="opener"
           >
             <div className="contactDetails__dropdown">
               <Button className="contact__header__btn-primary">
                 <PlusOutlined />
               </Button>
             </div>
           </Link> */}
                <Dropdown
                  overlay={() => subMenuCompose}
                  arrow
                  // trigger={["click"]}
                  // arrow
                  placement="bottomRight"
                >
                  <div className="contactDetails__dropdown">
                    <Button className="contact__header__btn-primary">
                      <PlusOutlined />
                    </Button>
                  </div>
                </Dropdown>
              </>
            )}
          </div>
        </div>
      )}

      <div className="mail-second-menu">
        <div className="selection-mail-type">
          <div
            className="inbox-btn-wrapper"
            onMouseEnter={emailType == "Business" ? "" : callMouseFunction}
            // onClick={() => setShowSideMenuEmail(!showSideMenuEmail)}
          >
            <div className="selected-left">
              <Avatar
                src={
                  !isEmpty(selectedEmailType)
                    ? selectedEmailType.icon
                    : selectedMailTypeAndMailData?.icon
                    ? selectedMailTypeAndMailData?.icon
                    : mailInbox
                }
                size={20}
              />
              <div
                style={{
                  paddingLeft: 10,
                  color: "#2563AA",
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                {selectedEmailType?.typeDisplayName
                  ? selectedEmailType?.typeDisplayName
                  : selectedMailTypeAndMailData?.typeDisplayName
                  ? selectedMailTypeAndMailData?.typeDisplayName
                  : "Inbox"}
              </div>
            </div>
            {emailType !== "Business" && (
              <div className="mail-side-menu">
                <DownOutlined style={{ fontSize: 12 }} />
              </div>
            )}
          </div>
          {/* Objects.nonNull(foo) && foo.something */}
          <div
            className="mail-counter"
            style={{ fontSize: 12, color: "#67A1E4" }}
          >
            {emailType === "Business"
              ? mailMessageCenterPagination?.totalCount
              : selectedMailTypeAndMailData?.typeSystemName === "Inbox"
              ? Number(
                  nullChecker(mailPaginationCount?.allCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "OutBox"
              ? Number(
                  nullChecker(mailPaginationCount?.outBoxCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "From"
              ? Number(
                  nullChecker(mailPaginationCount?.fromCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "inbox"
              ? Number(
                  nullChecker(
                    mailPaginationCount?.circularInboxCount?.all,
                    "all"
                  )
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Pending"
              ? Number(
                  nullChecker(mailPaginationCount?.pendingCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Archived"
              ? Number(
                  nullChecker(mailPaginationCount?.archievedCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "specialflag1"
              ? Number(
                  nullChecker(mailPaginationCount?.flagCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Draft"
              ? Number(
                  nullChecker(mailPaginationCount?.drafCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Trash"
              ? Number(
                  nullChecker(mailPaginationCount?.trashCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "To"
              ? Number(
                  nullChecker(mailPaginationCount?.toCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Cc"
              ? Number(
                  nullChecker(mailPaginationCount?.ccCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Bcc"
              ? Number(
                  nullChecker(mailPaginationCount?.bccCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Junk"
              ? Number(
                  nullChecker(mailPaginationCount?.junkCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Business"
              ? Number(
                  nullChecker(
                    mailPaginationCount?.tenderBusinessCount?.all,
                    "all"
                  )
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Approval"
              ? Number(
                  nullChecker(mailPaginationCount?.approvalCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Loan"
              ? Number(
                  nullChecker(mailPaginationCount?.loanCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Leave"
              ? Number(
                  nullChecker(mailPaginationCount?.leaveCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "Procurement"
              ? Number(
                  nullChecker(mailPaginationCount?.procurementCount?.all, "all")
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "inbox"
              ? Number(
                  nullChecker(
                    mailPaginationCount?.circularInboxCount?.all,
                    "all"
                  )
                ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "groupchat"
              ? Number(
                  nullChecker(
                  mailPaginationCount?.groupChatCount?.all,
                  "all"
                  )
              ).toLocaleString()
              : selectedMailTypeAndMailData?.typeSystemName === "GroupChatArchive"
              ? Number(
                  nullChecker(
                  mailPaginationCount?.groupChatArchiveCount?.all,
                  "all"
                  )
              ).toLocaleString()
              : ""}
          </div>
        </div>

        {/* <div
          className="show-email-menu-icon"
          onClick={() => setShowSideMenuEmail(!showSideMenuEmail)}
        >
           <Image src={email} preview={false} />
          <span>MailBox</span>
          <DownOutlined style={{ fontSize: 12 }} />
        </div> */}
      </div>
      {/* <div className="selectAll_emailList_section" > */}
      {/* <div className="selectAll_emailList_section">
        <input type="checkbox" onChange ={(e)=>{
          let checkSelector =document.querySelectorAll('.email-list-check')
          checkSelector.forEach(item=>{
            if(e.target.checked === true)
              item.setAttribute('checked',true)
            else  
              item.removeAttribute('checked')
          })
        }}></input>
      </div> */}
      {emailType !== "Business" && (
        <div className="selectAll_emailList_section">
          <Checkbox
            indeterminate={indeterminate}
            className="size-checkbox-anteds"
            onChange={onCheckAllChange}
            checked={checkAll}
          ></Checkbox>
        </div>
      )}

      {!hideMailListHeaderAndTabs ? (
        <Tabs
          activeKey={currentTab}
          onChange={(key) => {
            setCurrentTab(key);
            // setSelectedEmailType({
            //   typeDisplayName: "Inbox",
            //   typeSystemName: "Inbox",
            //   count: mailMessageCenterPagination.totalCount,
            //   icon: mailInbox,
            //   subMenu: "sub10",
            //   totalLength: 10,
            // });
          }}
        >
          <TabPane
            tab={
              <p style={{ height: emailType === "Business" ? "" : "2px" }}>
                All{" "}
                {!isEmpty(mailPaginationCount) && emailType !== "Business"
                  ? `(${
                      selectedMailTypeAndMailData?.typeSystemName === "Inbox"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.allCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "OutBox"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.outBoxCount?.all,
                              "all"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "inbox"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.circularInboxCount?.all,
                              "all"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "From"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.fromCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Pending"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.pendingCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Archived"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.archievedCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "specialflag1"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.flagCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Draft"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.drafCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Trash"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.trashCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "To"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.toCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "Cc"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.ccCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "Bcc"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.bccCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "Junk"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.junkCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Approval"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.approvalCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "Loan"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.loanCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Leave"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.leaveCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Procurement"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.procurementCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Business"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.tenderBusinessCount?.all,
                              "unread"
                            )
                          ).toLocaleString()
                        : ""
                    })`
                  : ""}
              </p>
            }
            key="All"
            className="emailList"
          >
            <>
              <div
                onClick={() => {
                  setShowSideMenuEmail(false);
                  setPreSelectEmailCard(true);
                }}
                className={`emailList__lists ${
                  mailMessageCenterPaginationLoading ? "" : ""
                }`}
                id="scrollableDiv"
              >
                {mailMessageCenterPaginationLoading ? (
                  <span>
                    <div style={{ textAlign: "center" }}>
                      <Skeleton paragraph={{ rows: 10 }} active />
                    </div>
                  </span>
                ) : !isEmpty(messagePagination?.items) ? (
                  <InfiniteScroll
                    dataLength={messagePagination.items.length}
                    next={fetchMoreData}
                    hasMore={
                      messagePagination.totalCount >
                      messagePagination.items.length
                    }
                    scrollableTarget="scrollableDiv"
                    loader={<Skeleton paragraph={{ rows: 2 }} active />}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>No More Emails</b>
                      </p>
                    }
                    height={
                      emailType !== "Business" ? "" : window.innerHeight - 250
                    }
                  >
                    <EmailCard
                      Reference_SearchKeyword={Reference_SearchKeyword}
                      mailMessageCenterPagination={messagePagination}
                      mailMessageCenterPaginationLoading={
                        mailMessageCenterPaginationLoading
                      }
                      currentSelectedMailMessage={currentSelectedMailMessage}
                      setCurrentSelectedMailMessage={
                        setCurrentSelectedMailMessage
                      }
                      setchatBox={setchatBox}
                      fetchMoreData={fetchMoreData}
                      setShowSideMenuEmail={setShowSideMenuEmail}
                      mailBodyGetDetailsLoading={mailBodyGetDetailsLoading}
                      messageCenterTypeName={messageCenterTypeName}
                      allInputChecked={allInputChecked}
                      plainOptions={plainOptions}
                      checkAll={checkAll}
                      openAutoReferencing={openAutoReferencing}
                      setOpenAutoReferencing={setOpenAutoReferencing}
                      advancedFiltersData={advancedFiltersData}
                      //amitadded
                      setFilteredEmails={setFilteredEmails}
                      filteredEmails={filteredEmails}
                      referenceTagsFromAutoReference={
                        referenceTagsFromAutoReference
                      }
                      corporateSharedAccessAccountHolderUserId={
                        corporateSharedAccessAccountHolderUserId
                      }
                      setContextMenushow={setContextMenushow}
                      contextMenushow={contextMenushow}
                      emailType={emailType}
                    />
                  </InfiniteScroll>
                ) : (
                  <div
                    onClick={() => {
                      setShowSideMenuEmail(false);
                    }}
                  >
                    {showNoDataMessage ? (
                      <div className="no-data-wrapper">
                        {mailMessageCenterPagination?.items?.length === 0 && (
                          <span>No data</span>
                        )}
                      </div>
                    ) : (
                      <>
                        {/* {emailType === "Business" ||
                        selectedMailTypeAndMailData?.typeSystemName ===
                          "Inbox" ? (
                          <Skeleton paragraph={{ rows: 10 }} active />
                        ) : null} */}
                        {emailType === "Business" ||
                        (selectedMailTypeAndMailData?.typeSystemName ===
                          "Inbox" &&
                          mailMessageCenterPagination?.items?.length === 0) ? (
                          <Skeleton paragraph={{ rows: 10 }} active />
                        ) : (
                          <Skeleton paragraph={{ rows: 10 }} active />
                        )}
                      </>
                    )}

                    {/* {mailMessageCenterPaginationLoading ? (
                      <span>
                        <div style={{ textAlign: "center" }}>
                          <Skeleton paragraph={{ rows: 10 }} active />
                        </div>
                      </span>
                    ) : (
                      <div className="no-data-wrapper">
                        <span>No data</span>
                      </div>
                    )} */}
                  </div>
                )}
              </div>
            </>
          </TabPane>
          <TabPane
            tab={
              <p style={{ height: emailType === "Business" ? "" : "2px" }}>
                Internal
                {!isEmpty(mailPaginationCount) && emailType !== "Business"
                  ? `(${
                      selectedMailTypeAndMailData?.typeSystemName === "Inbox"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.allCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "OutBox"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.outBoxCount?.internal,
                              "all"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "From"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.fromCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Pending"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.pendingCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Archived"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.archievedCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "specialflag1"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.flagCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Draft"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.drafCount?.internal,
                              "all"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Trash"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.trashCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "To"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.toCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "Cc"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.ccCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "Bcc"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.bccCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "Junk"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.junkCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Approval"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.approvalCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName === "Loan"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.loanCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Leave"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.leaveCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Procurement"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.procurementCount?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : selectedMailTypeAndMailData?.typeSystemName ===
                          "Business"
                        ? Number(
                            nullChecker(
                              mailPaginationCount?.tenderBusinessCount
                                ?.internal,
                              "unread"
                            )
                          ).toLocaleString()
                        : ""
                    })`
                  : ""}
              </p>
            }
            key="Internal"
            onChange={(key) => {
              setCurrentTab(key);
            }}
          >
            <div
              // className="emailList__lists"
              id="scrollableDiv2"
              className={`emailList__lists ${
                mailMessageCenterPaginationLoading ? "" : ""
              }`}
            >
              {!isEmpty(messagePagination.items) ? (
                <InfiniteScroll
                  dataLength={messagePagination.items.length}
                  //This is important field to render the next data
                  next={fetchMoreData}
                  hasMore={
                    !mailMessageCenterPaginationLoading &&
                    messagePagination.totalCount >
                      messagePagination.items.length
                  }
                  loader={<Skeleton paragraph={{ rows: 2 }} active />}
                  scrollableTarget="scrollableDiv2"
                  height={
                    emailType !== "Business" ? "" : window.innerHeight - 250
                  }
                >
                  {mailMessageCenterPaginationLoading ? (
                    <Skeleton paragraph={{ rows: 2 }} active />
                  ) : (
                    <EmailCard
                      // Reference_SearchKeyword={Reference_SearchKeyword}
                      // mailMessageCenterPagination={messagePagination}
                      // mailMessageCenterPaginationLoading={
                      //   mailMessageCenterPaginationLoading
                      // }
                      // currentSelectedMailMessage={currentSelectedMailMessage}
                      // setCurrentSelectedMailMessage={
                      //   setCurrentSelectedMailMessage
                      // }
                      // setchatBox={setchatBox}
                      // setShowSideMenuEmail={setShowSideMenuEmail}
                      // fetchMoreData={fetchMoreData}
                      // mailBodyGetDetailsLoading={mailBodyGetDetailsLoading}
                      // setFilteredEmails={setFilteredEmails}
                      // filteredEmails={filteredEmails}
                      // currentTab={currentTab}
                      // corporateSharedAccessAccountHolderUserId={
                      //   corporateSharedAccessAccountHolderUserId
                      // }
                      // setContextMenushow={setContextMenushow}
                      // contextMenushow={contextMenushow}
                      // emailType={emailType}

                      // setNextMsg={setNextMsg}

                      Reference_SearchKeyword={Reference_SearchKeyword}
                      mailMessageCenterPagination={messagePagination}
                      mailMessageCenterPaginationLoading={
                        mailMessageCenterPaginationLoading
                      }
                      currentSelectedMailMessage={currentSelectedMailMessage}
                      setCurrentSelectedMailMessage={
                        setCurrentSelectedMailMessage
                      }
                      setchatBox={setchatBox}
                      fetchMoreData={fetchMoreData}
                      setShowSideMenuEmail={setShowSideMenuEmail}
                      mailBodyGetDetailsLoading={mailBodyGetDetailsLoading}
                      messageCenterTypeName={messageCenterTypeName}
                      allInputChecked={allInputChecked}
                      plainOptions={plainOptions}
                      checkAll={checkAll}
                      openAutoReferencing={openAutoReferencing}
                      setOpenAutoReferencing={setOpenAutoReferencing}
                      advancedFiltersData={advancedFiltersData}
                      //amitadded
                      setFilteredEmails={setFilteredEmails}
                      filteredEmails={filteredEmails}
                      referenceTagsFromAutoReference={
                        referenceTagsFromAutoReference
                      }
                      corporateSharedAccessAccountHolderUserId={
                        corporateSharedAccessAccountHolderUserId
                      }
                      setContextMenushow={setContextMenushow}
                      contextMenushow={contextMenushow}
                      emailType={emailType}
                    />
                  )}
                </InfiniteScroll>
              ) : (
                <div
                  onClick={() => {
                    setShowSideMenuEmail(false);
                  }}
                >
                  {emailType === "Business" ? (
                    <div className="no-data-wrapper">
                      <span>No data</span>
                    </div>
                  ) : (
                    <Skeleton paragraph={{ rows: 10 }} active />
                  )}
                  {/* {mailMessageCenterPaginationLoading ? (
                    <span>
                      <Skeleton paragraph={{ rows: 10 }} active />
                    </span>
                  ) : (
                    <div className="no-data-wrapper">
                      <span>No data</span>
                    </div>
                  )} */}
                </div>
              )}
            </div>
          </TabPane>
        </Tabs>
      ) : (
        <div className="emailList__lists">
          {mailMessageCenterPaginationLoading ? (
            <div style={{ height: 50, width: 50 }}>
              <img src={LogoImage} height="100%" alt="imageAlt" />
            </div>
          ) : !isEmpty(mailMessageCenterPagination.items) ? (
            <EmailCard
              Reference_SearchKeyword={Reference_SearchKeyword}
              mailMessageCenterPagination={mailMessageCenterPagination}
              mailMessageCenterPaginationLoading={
                mailMessageCenterPaginationLoading
              }
              currentSelectedMailMessage={currentSelectedMailMessage}
              setCurrentSelectedMailMessage={setCurrentSelectedMailMessage}
              setchatBox={setchatBox}
              setShowSideMenuEmail={setShowSideMenuEmail}
              setFilteredEmails={setFilteredEmails}
              corporateSharedAccessAccountHolderUserId={
                corporateSharedAccessAccountHolderUserId
              }
              setContextMenushow={setContextMenushow}
              contextMenushow={contextMenushow}
              emailType={emailType}
              // setNextMsg={setNextMsg}
            />
          ) : (
            "No data Selected"
          )}
        </div>
      )}
      <Modal
        title="Email Logs"
        visible={showErrorModal}
        footer={[]}
        onCancel={() => {
          setErrorModal(false);
        }}
        width="1200px"
        wrapClassName="email_log_model"
      >
        <div>
          <ErrorMessagePagination errorMessage={errorMessage} />
        </div>
      </Modal>

      <Modal
        // title="Internal Message"
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose form={form} setInternalVisible={setInternalVisible} />
      </Modal>

      {openPredefinedModal && (
        <Modal
          visible={openPredefinedModal}
          footer={false}
          title={"Predefined Messages"}
          onCancel={() => {
            setOpenPrefinedModal(false);
          }}
          width={700}
          wrapClassName="predefine_modal_popup"
        >
          <div>
            <div>
              <Table
                className="organizer__table contact-table predefined_message_table"
                columns={columnsPredefinedModal}
                scroll={true}
                rowKey="appUserId"
                dataSource={predefinedData}
                sticky={true}
                loading={{
                  // indicator: <img src={LogoImage} height="auto" width="50px" />,
                  spinning: getPredefinedMessageResponseLoading,
                }}
                pagination={false}
              />
            </div>
            {/* <Form form={form}> */}
            <Form>
              <div
                className="drawer-form-row modal_predefined_function"
                style={{ padding: 0, overflow: "hidden" }}
              >
                <div className="drawer-form-group" style={{ width: "100%" }}>
                  <Form.Item
                    className="form__group"
                    label="Body"
                    name="remarksPredefined"
                  >
                    <TextArea autoComplete="off" rows={10}></TextArea>
                  </Form.Item>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    onClick={() => {
                      onFinishPredefineMessage();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      )}
      <Modal
        title="Advance Email Search"
        visible={showAdvanceSearchModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        wrapClassName="advance_search_email"
      >
        <Form
          form={form2}
          labelAlign="left"
          colon={false}
          onFinish={onFinishAdvanceSearch}
          autoComplete="off"
        >
          <div className="row-wrapper">
            <div className="global-form-flex" style={{ flexFlow: "row wrap" }}>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>E-mail</label>
                </div>
                <div className="global-form-input">
                  <Form.Item name="IsEmail_SearchKeyword">
                    <Input
                      onChange={(e) => {
                        setEmailValue(e.target.value);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Subject</label>
                </div>
                <div className="global-form-input">
                  <Form.Item name="Subject_SearchKeyword">
                    <Input
                      onChange={(e) => {
                        setSubjectValue(e.target.value);
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Reference</label>
                </div>
                <div className="global-form-input">
                  <Form.Item name="referenceId" className="addTask__form--item">
                    <Select
                      showSearch
                      placeholder="Select reference"
                      optionFilterProp="label"
                      allowClear={true}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onSearch={(value) => {
                        setSearchValueReference(value);
                        setFilterParticipantType("REF");
                      }}
                      autoComplete="off"
                      onSelect={(value) => {
                        setReferenceSearch(value);
                      }}
                    >
                      {filterReference.map((reference, index) => {
                        return (
                          <Option
                            value={reference.code}
                            key={index}
                            label={[reference.code, reference.title]}
                          >
                            <div className="demo-option-label-item">
                              <div>
                                {reference.code} {""}
                              </div>
                              <div>{reference.title}</div>
                            </div>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Party</label>
                </div>
                <div className="global-form-input">
                  <Form.Item name="Company_SearchKeyword">
                    <Select
                      size="small"
                      allowClear
                      placeholder="Select Company"
                      showSearch={true}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      // optionFilterProp="label"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={(value) => {
                        if (value) {
                          setValue(value);
                        } else {
                          setContactCompanyList([]);
                          setValue(value);
                        }
                      }}
                      notFoundContent={
                        !isEmpty(value) ? (
                          contactCompanyPaginationLoading ? (
                            <Spin />
                          ) : (
                            isEmpty(contactCompanyPaginationResponse?.items) &&
                            value?.length > 2 && (
                              <div>{`Create "${value}"`}</div>
                            )
                          )
                        ) : null
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (
                            isEmpty(contactCompanyPaginationResponse?.items) &&
                            !isEmpty(value)
                          ) {
                          }
                        }
                      }}
                      onBlur={() => setContactCompanyList([])}
                    >
                      {contactCompanyList?.map((company) => (
                        <Option
                          value={company.contactCompanyName}
                          key={company.contactCompanyId}
                          label={company.contactCompanyName}
                        >
                          {company.contactCompanyName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc attachment_check_advance_search_email">
                <div className="global-form-labels">
                  <label>Search Attachment</label>
                </div>
                <div className="global-form-input">
                  <Checkbox
                    onChange={() => {
                      setOpenAttachmentAdvanceSearch(
                        !openAttachmentAdvanceSearch
                      );
                    }}
                  />
                </div>
              </div>

              {openAttachmentAdvanceSearch && (
                <div className="global-form-group calc attachment_check_advance_search_email">
                  <div className="global-form-label">
                    <label>With Attachment</label>
                  </div>
                  <div className="global-form-input">
                    <Form.Item>
                      <Switch
                        onChange={() => setSearchAttachment(!searchAttachment)}
                      />
                    </Form.Item>
                  </div>
                </div>
              )}

              <div className="global-form-group calc attachment_check_advance_search_email">
                <div className="global-form-label">
                  <label>Exclude</label>
                </div>
                <div className="global-form-input">
                  <Form.Item name="appTypeNames">
                    <Select mode="multiple" options={appAppTypes} allowClear />
                  </Form.Item>
                </div>
              </div>

              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Date Range</label>
                </div>
                <div className="global-form-input">
                  <Form.Item>
                    <RangePicker
                      onChange={(value) => {
                        setStartDate(
                          value ? moment(value[0]).format("YYYY-MM-DD") : ""
                        );
                        setEndDate(
                          value ? moment(value[1]).format("YYYY-MM-DD") : ""
                        );
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="global-form-group calc advance-btn-search-wrapper">
                <Form.Item>
                  <Button
                    type="ghost"
                    shape="round"
                    autoComplete="off"
                    onClick={() => {
                      form.resetFields();
                    }}
                    disabled={advanceButtonDisabled}
                    style={{
                      marginRight: "12px",
                    }}
                  >
                    Remove
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="ghost"
                    shape="round"
                    htmlType="submit"
                    // loading={contactPersonPaginationLoading}
                    autoComplete="off"
                    // disabled={advanceButtonDisabled}
                  >
                    Search
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Modal>

      {/* NewCutting Form */}

      <Modal
        visible={newCuttingVisible}
        footer={null}
        closable={false}
        width={800}
      >
        <Form
          form={form3}
          className="drawer-form-wrapper internal_message_section_email_section"
          onFinish={newCuttingFormFinish}
        >
          <div className="internal_message_popup_email_section">
            <div className="left-chat-action-btn" style={{ marginTop: "-5px" }}>
              <div className="chat-box-send-wrapper">
                {documentInstanceMessageLoading ? (
                  antIcon
                ) : (
                  <Button
                    htmlType="submit"
                    style={{
                      background: "#67a1e4",
                      border: "none",
                      height: "5px",
                    }}
                  >
                    <img src={sendIntM} alt="send" />
                  </Button>
                )}
              </div>
            </div>
            <div className="chat-action-btn" style={{ display: "flex" }}>
              <div className="chat-box-send-wrapper">
                <Dropdown.Button
                  overlay={menuGroup}
                  icon={<UsergroupAddOutlined />}
                ></Dropdown.Button>
              </div>

              <div>
                <Checkbox
                  onChange={(e) => {
                    setSelectTosNewCut(!selectTosNewCut);
                  }}
                  checked={selectTosNewCut}
                >
                  To All
                </Checkbox>
              </div>

              <div
                className="btn__chat--cancel"
                onClick={() => {
                  setNewCuttinghVisible(false);
                  setSelectTosNewCut(false);
                }}
              >
                <Tooltip title="close">
                  <img src={cancel} alt="cancel" />
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            className="drawer-form-row mail-quick-message"
            style={{ padding: 0, overflow: "hidden" }}
          >
            <div className="email-text-input">
              <div className="email-text">To</div>
              <Form.Item
                className="form__group"
                name="participantTos"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  autoComplete="off"
                  mode="multiple"
                  bordered={false}
                  optionFilterProp="label"
                >
                  {!isEmpty(toUserListings) &&
                    toUserListings?.map((user, index) => {
                      return (
                        <Option
                          value={user?.appUserId}
                          key={index}
                          label={[user.fullName, user.userName]}
                        >
                          {user?.fullName} [{user?.userName.toUpperCase()}]
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div className="email-text-input">
              <div className="email-text">Cc</div>
              <Form.Item
                className="form__group"
                name="participantCCs"
                // label="Cc"
              >
                <Select
                  autoComplete="off"
                  mode="multiple"
                  optionFilterProp="label"
                  bordered={false}
                >
                  {!isEmpty(ccUserListings) &&
                    ccUserListings?.map((user, index) => {
                      return (
                        <Option
                          value={user?.appUserId}
                          key={index}
                          label={[user.fullName, user.userName]}
                        >
                          {user?.fullName}[{user?.userName.toUpperCase()}]
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div className="email-text-input">
              <div className="email-text">Subject</div>
              <Form.Item
                className="form__group"
                name="messageText"
                // rules={[
                //   {
                //     required: true,
                //     message: "",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="email-text-input text_input_reference">
              <div className="email-text">Reference</div>
              <Form.Item
                // label="Reference"
                name="mailMessageReferenceDtos"
                className="form__group"
                // rules={[
                //   {
                //     required: true,
                //     message: "",
                //   },
                // ]}
              >
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  showSearch
                  placeholder="Select reference"
                  // bordered={false}
                  optionFilterProp="label"
                  allowClear={true}
                  onSearch={(value) => {
                    setSearchValueReference(value);
                    setFilterParticipantType("REF");
                  }}
                  autoComplete="off"
                  bordered={false}
                >
                  {filterReference.map((reference, index) => {
                    return (
                      <Option
                        value={reference.code}
                        key={index}
                        label={[reference.code, reference.title]}
                      >
                        {/* {reference.code} */}
                        <div className="demo-option-label-item">
                          <div>
                            {reference.code} {""}
                          </div>
                          <div>{reference.title}</div>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>

            <div>
              <div
              // className=""
              >
                <Editor
                  className="compose-editor"
                  value={editorData}
                  onKeyDown={(e) => {
                    // let bbb = {};
                    // if (e.key === "Alt") {
                    //   bbb = {
                    //     ...AltEnt,
                    //     alt: e.key,
                    //   };
                    //   setAltEnt(bbb);
                    // }
                    // if (e.key === "Enter") {
                    //   bbb = {
                    //     ...AltEnt,
                    //     enter: e.key,
                    //   };
                    //   setAltEnt(bbb);
                    // }
                    const { key, altKey } = e;
                    if (altKey && key === "Enter") {
                      setAltEnt(true);
                    }
                  }}
                  init={{
                    // auto_focus:
                    //   applicationTypeStatus === "reply" ||
                    //   applicationTypeStatus === "replyall"
                    //     ? true
                    //     : false,
                    height: 800,
                    width: 800,
                    auto_focus: true,
                    browser_spellcheck: true,
                    plugins: ["lists advlist", "image", " paste"],
                    menubar: "false",
                    file_browser_callback_types: "image",
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype == "image") {
                        var input = document.getElementById("my-file");
                        input.click();
                        input.onchange = function () {
                          var file = input.files[0];
                          var reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },
                    paste_data_images: true,
                    // toolbar: "paste",
                    paste_as_text: false,
                    paste_merge_formats: false,
                    paste_tab_spaces: 12,
                    toolbar:
                      "fontselect fontsizeselect backcolor forecolor bold | italic | underLine lineheight |  strikethrough alignleft aligncenter alignright |  bullist numlist",

                    content_style: "p{margin:0px;padding:0px}",
                    forced_root_block: "div",
                    forced_root_block_attrs: {
                      style: "font-family: Arial; font-size: 10pt",
                      class: "editor-block-wrapper",
                      id: "editor-blocks",
                    },
                    font_formats:
                      "Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;Arial Narrow=arial narrow,avant garde;Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier;Cambria=cambria;Calibri=calibri;Calibri Body=calibri boby;Georgia=georgia,palatino;Times New Roman=times new roman,times;Helvetica=helvetica;Verdana=verdana,geneva;",
                  }}
                  onEditorChange={(e) => {
                    setEditorData(e);
                  }}
                />
              </div>
            </div>
          </div>
        </Form>
      </Modal>

      {/* Creating Group To Send to particular Group of Users */}

      <Modal
        visible={createGroupModal}
        title={`${
          isEmpty(selectedGroupDataToBeUpdated)
            ? "Create Your Group"
            : "Update Your Group"
        }`}
        onCancel={() => setCreateGroupModal(false)}
        footer={null}
        height={300}
      >
        <Form {...layoutFormGroup} onFinish={onGroupCreateFinish} form={form4}>
          <Form.Item
            name="groupName"
            label="Group Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Name of your group" />
          </Form.Item>

          <Form.Item
            name="userListTo"
            label="To Users"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Users"
              allowClear
              autoComplete="off"
              mode="multiple"
              optionFilterProp="label"
            >
              {!isEmpty(toUserListings) &&
                toUserListings?.map((user, index) => {
                  return (
                    <Option
                      value={user?.appUserId}
                      key={index}
                      label={[user.fullName, user.userName]}
                    >
                      {user?.fullName} [{user?.userName.toUpperCase()}]
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            name="userListCc"
            label="CC Users"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Users"
              allowClear
              autoComplete="off"
              mode="multiple"
              optionFilterProp="label"
            >
              {!isEmpty(toUserListings) &&
                toUserListings?.map((user, index) => {
                  return (
                    <Option
                      value={user?.appUserId}
                      key={index}
                      label={[user.fullName, user.userName]}
                    >
                      {user?.fullName} [{user?.userName.toUpperCase()}]
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={groupDataLoading}>
            {isEmpty(selectedGroupDataToBeUpdated) ? "Submit" : "Update"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default EmailList;
