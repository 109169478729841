import { EMAIL_TYPES, PERSIST_DATA } from "../../../types";
import { init, success, finish, error } from "services/common";
import { actionForPersistTenderMailTos } from "views/OfficeResource/Reference/Tabs/TenderTabs/companies/persistTender/action";
import { ParticipationStatusService } from "./api";
import { message } from "antd";

const participationStatusService = new ParticipationStatusService();

export const getMailMessageParticipantStatusListing = () => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.MESSAGE_PARTICIPANT_STATUS_LISTING));
    const response =
      await participationStatusService.getMailMessageParticipantStatusListing();
    dispatch(finish(EMAIL_TYPES.MESSAGE_PARTICIPANT_STATUS_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(EMAIL_TYPES.MESSAGE_PARTICIPANT_STATUS_LISTING, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};


export const getCompanyPersonEmailAddress = (id, body) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.COMPANY_PERSON_EMAIL_LISTING));
    const response =
      await participationStatusService.getCompanyPersonEmailAddress(id, body);
    dispatch(finish(EMAIL_TYPES.COMPANY_PERSON_EMAIL_LISTING));

    try {
      if (response.isSuccess) {
        dispatch(
          success(EMAIL_TYPES.COMPANY_PERSON_EMAIL_LISTING, response.data)
        );
  
        dispatch(actionForPersistTenderMailTos(response.data))
        
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch(err) {
      throw err;
    }
  };
}

