import { Table } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect,useState } from 'react'
import { getOrganizationTypepagination } from 'services/redux/organizer/typeListing/action';

const OrganizationTypeAddUpdateTable = () => {
    const columns = [
        {
            title: "S No",
            key: "sno",
            render: (text, object, index) => index + 1,
            width:"5%",
        },
        {
            title:  "Organization Type",
            dataIndex: "organizationLayerTypeName",
            key: "organizationLayerTypeName",
        },
        // {
        //     title: "organizationLayerTypeName",
        //     render: (record) => {
        //         console.log("recordTest",record?.isActive)
        //      if(record == true){
        //         return <span className="true-isactive">True</span>
        //      }else{
        //         return <span className="false-isActive">False </span>
        //      }
        //     }
        //   },
    ];
    const dispatch = useDispatch();
    const {orginizationLayerResponse} = useSelector((state) => state.organizationType);
    const [organizeData, setOrganizeData] = useState([]);
    const [totalCount, setTotalCount] = useState(10);
    const pageSize = 10;
    useEffect(() => {
        getData();
      },[])

      useEffect(() => {
        if(!orginizationLayerResponse)
        return ;
        setOrganizeData(orginizationLayerResponse?.items);
        setTotalCount(orginizationLayerResponse?.totalCount);
      },[orginizationLayerResponse])
 
    const getData = (
        MaxResultCount = pageSize,
        SkipCount = 0,
        Sorting,
        SortType
      ) => {
        let appendUrl = `skipCount=${SkipCount}`;
        dispatch(getOrganizationTypepagination(appendUrl));
        // dispatch(
        //     getOrganizationTypepagination({
        //     MaxResultCount,
        //     SkipCount,
        //     Sorting,
        //     SortType,
        //   })
        // );
      };
      const handleTableChange = (pagination, filters, sorter, extra) => {
        let Sorting =
        sorter.order === "ascend"
          ? "subject"
          : sorter.order === "descend"
          ? "subject"
          : undefined;
        let SortType =
          sorter.order === "ascend"
            ? "asc"
            : sorter.order === "descend"
            ? "desc"
            : undefined;
        let MaxResultCount = pageSize;
        let SkipCount = (pagination.current - 1) * pagination.pageSize;
    
        getData(MaxResultCount, SkipCount, Sorting, SortType);
        // getData(pagination.current, pagination.pageSize);
      };
      
  return (
   <>
   <Table
   className='organizer__tables'
   columns={columns}
   onChange={handleTableChange}
   dataSource={organizeData}
   pagination={{
    total: totalCount,
    pageSize: pageSize,
  }}
   />

   
   </>
  )
}

export default OrganizationTypeAddUpdateTable
