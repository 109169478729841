import { EMAIL_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { InstanceMessageCenterService } from "./api";
// import { getChildReferenceUpateResponse } from "../mailMessageCenter/action";
import { message } from "antd";
import { MailMessageCenterService } from "../mailMessageCenter/api";

const instanceMessageCenterService = new InstanceMessageCenterService();

const mailMessageCenterService = new MailMessageCenterService();

export const getInstanceMessageCenterPagination = (query) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.INSTANCE_MESSAGE_CENTER_PAGINATION));
    const response =
      await instanceMessageCenterService.getInstanceMessageCenterPagination(
        query
      );
    dispatch(finish(EMAIL_TYPES.INSTANCE_MESSAGE_CENTER_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(EMAIL_TYPES.INSTANCE_MESSAGE_CENTER_PAGINATION, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const replyInstanceMessage = (body) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE));
    const response = await instanceMessageCenterService.replyInstanceMessage(
      body
    );
    if (response.isSuccess) {
      message.success("Instant Message");
      // dispatch(getChildReferenceUpateResponse(body));
      dispatch(success(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE, response.data));
      dispatch(finish(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const deleteReplyMsgResponse = () => {
  return async (dispatch) => {
    dispatch({ type: EMAIL_TYPES.REPLY_INSTANCE_MESSAGE_RESP_DELETE });
  };
};

export const deleteReplyMsgUpdateResponse = () => {
  return async (dispatch) => {
    dispatch({ type: EMAIL_TYPES.REPLY_INSTANCE_MESSAGE__UPDATE_RESP_DELETE });
  };
};

export const instanceMessageUpdate = (body, mailMessageCenterId) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.INSTANCE_MESSAGE_UPDATE));
    const response = await instanceMessageCenterService.instanceMessageUpdate(
      body
    );
    dispatch(finish(EMAIL_TYPES.INSTANCE_MESSAGE_UPDATE));
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.INSTANCE_MESSAGE_UPDATE, response.data));
      message.success("Message edited successfully");
      // dispatch(getInstanceMessageCenterPagination({
      //   MaxResultCount: 20,
      //   SkipCount: 0,
      //   MessageCenterId: mailMessageCenterId
      // }));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const instanceMessageDelete = (id, mailMessageCenterId) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.INSTANCE_MESSAGE_UPDATE));
    const response = await instanceMessageCenterService.instanceMessageDelete(
      id
    );
    dispatch(finish(EMAIL_TYPES.INSTANCE_MESSAGE_UPDATE));
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.INSTANCE_MESSAGE_UPDATE, response.data));
      message.success("Message deleted successfully");
      dispatch(
        getInstanceMessageCenterPagination({
          MaxResultCount: 20,
          SkipCount: 0,
          MessageCenterId: mailMessageCenterId,
        })
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const documentInstantMessage = (body) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE));
    const response = await instanceMessageCenterService.addDocumentMessage(
      body
    );
    dispatch(finish(EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE));
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE, response.data));
      message.success("Document shared successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const documentInstantMessageContinue = (body) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE_CONTINUE));
    const response = await instanceMessageCenterService.addDocumentMessage(
      body
    );
    dispatch(finish(EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE_CONTINUE));
    if (response.isSuccess) {
      dispatch(
        success(EMAIL_TYPES.DOCUMENT_INSTANT_MESSAGE_CONTINUE, response.data)
      );
      message.success("Document shared successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const sendCorrectionMessage = (
  correctionBody,
  setShowModalForCorretionTag
) => {
  return async (dispatch) => {
    let successMessage = "Correction Message Sent";
    dispatch(init(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE));
    const response = await instanceMessageCenterService.sendCorrectionMessage(
      correctionBody
    );
    if (response.isSuccess) {
      message.success(successMessage);
      dispatch(success(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE, response.data));
      // dispatch(getInstanceMessageCenterPagination());
      setShowModalForCorretionTag({
        visibility: false,
        tagName: "",
      });
      dispatch(finish(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getAllMessageApplicationType = () => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.GET_ALL_MESSAGE_TYPE));
    const response =
      await instanceMessageCenterService.getMessageApplicationTypeListing();
    if (response.isSuccess) {
      dispatch(success(EMAIL_TYPES.GET_ALL_MESSAGE_TYPE, response.data));
      dispatch(finish(EMAIL_TYPES.GET_ALL_MESSAGE_TYPE));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const instanceMessageUserSuggestion = (query) => {
  // console.log("querySugest", query);
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.GET_INSTANCE_MESSAGE_USER_SUGGESTION));
    const response =
      await instanceMessageCenterService.instanceMessageUserSuggestion(query);
    if (response.isSuccess) {
      dispatch(
        success(EMAIL_TYPES.GET_INSTANCE_MESSAGE_USER_SUGGESTION, response.data)
      );
      dispatch(finish(EMAIL_TYPES.GET_INSTANCE_MESSAGE_USER_SUGGESTION));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const replyInstanceMessage = (body) => {
//   return async (dispatch) => {
//     dispatch(init(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE));
//     const response = await instanceMessageCenterService.replyInstanceMessage(
//       body
//     );
//     if (response.isSuccess) {
//       message.success("Instant Message");
//       // dispatch(getChildReferenceUpateResponse(body));
//       dispatch(success(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE, response.data));
//       dispatch(finish(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE));
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

//instanceMessageChangeStatus
export const changeEmailStatusTypeInstanceMessage = (
  query,
  status,
  messageCenterTypeName,
  emailStatus,
  referenceValues
) => {
  // console.log("status", query);
  return async (dispatch) => {
    // dispatch(init(EMAIL_TYPES.PARTICIPANT_STATUSES_CHANGE));
    dispatch(init(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE));

    const response = await mailMessageCenterService.changeEmailStatusType(
      query
    );
    // dispatch(finish(EMAIL_TYPES.PARTICIPANT_STATUSES_CHANGE));
    if (response.isSuccess) {
      message.success("Instant Message");
      dispatch(success(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE, response.data));
      dispatch(finish(EMAIL_TYPES.REPLY_INSTANCE_MESSAGE));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const sendBirthdayMessage = (body) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.BIRTHDAY_ANIVERSARY_MESSAGE));
    const response = await instanceMessageCenterService.sendAniversaryMessage(
      body
    );
    if (response.isSuccess) {
      message.success("Wish Message sent");
      dispatch(success(EMAIL_TYPES.BIRTHDAY_ANIVERSARY_MESSAGE, response.data));
      dispatch(finish(EMAIL_TYPES.BIRTHDAY_ANIVERSARY_MESSAGE));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const sendLateLoginMessage = (body) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.LATE_LOGIN_SMS));
    const response = await instanceMessageCenterService.sendLateLoginSms(body);
    if (response.isSuccess) {
      message.success("No login SMS sent");
      dispatch(success(EMAIL_TYPES.LATE_LOGIN_SMS, response.data));
      dispatch(finish(EMAIL_TYPES.LATE_LOGIN_SMS));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
