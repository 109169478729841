import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Divider,
  Button,
  Form,
  Skeleton,
  Input,
  DatePicker,
  Checkbox,
  Select,
  message,
  Collapse,
} from "antd";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { pickBy } from "lodash";
import {
  employeeProfileDetail,
  employeeProfileOfficalUpdate,
  joiningMessage,
} from "services/redux/profile/profile.action";
// import  { getDesignationListing } from "services/redux/adminSetting/organizationStructure/designation/designation.action"
import { getDesignationListing } from "services/redux/adminSettings/organizationStructure/designation/action";
import { isAdmin } from "utils/misc";
import { getAllUsers } from "services/remanentCalls";
import { isEmpty } from "lodash-es";
import { ApplicationConfigurationService } from "services/redux/common/applicationConfiguration/api";

const { Option } = Select;
const { RangePicker } = DatePicker;
const renderNameOrNA = (data) => data || "...";
const requiredRule = [
  {
    required: true,
    whitespace: true,
  },
];

const _OfficialInformation = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [showInfo, setShowInfo] = useState(true);
  const [isOnContract, setIsOnContract] = useState(false);
  const [grantedPolices, setGrantedPolices] = useState({});
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState();
  const [showButton, setShowButton] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { designationListing, designationListingLoading } = useSelector(
    (state) => state.designation
  );

  const { profileInfo, updateProfileOfficialLoading, updateProfileOfficial } =
    useSelector((state) => state.profile);

  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );
  // console.log("designationListing",designationListing)
  useEffect(() => {
    if (!isEmpty(applicationConfiguration?.auth?.grantedPolicies)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  useEffect(() => {
    dispatch(getDesignationListing());
  }, []);

  useEffect(() => {
    if (updateProfileOfficial) {
      setShowInfo(true);
    }
  }, [updateProfileOfficial]);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  const validateMessages = {
    required: "${label} is required!",
    whitespace: "${label} can not be empty!",
    types: {
      email: "${label} is not valid!",
    },
  };

  // const showInitialInfo = (profileInfo) => {
  //   return {
  //     ...profileInfo,
  //     hireDate: moment(profileInfo.hireDate),
  //   };
  // };

  useEffect(() => {
    form.resetFields();
    if (!profileInfo) return;
    form.setFieldsValue({
      ...profileInfo.employeeOfficialDetailUpdateDto,
      ...profileInfo.userRegistrationAddUpdateDto,

      hireDate: profileInfo.employeeOfficialDetailUpdateDto.hireDate
        ? moment(profileInfo.employeeOfficialDetailUpdateDto.hireDate)
        : "",
      designationId: profileInfo.employeeOfficialDetailUpdateDto.designationId,
    });
    setUserId(profileInfo?.employeeOfficialDetailUpdateDto?.appUserId);
    setShowButton(
      !profileInfo?.employeeOfficialDetailUpdateDto?.isSentWelcomeMessage
    );
  }, [profileInfo, showInfo]);

  const sendMessage = (id) => {
    dispatch(joiningMessage(id));
  };

  const onFinish = (values) => {
    console.log("values", values);
    if (profileInfo?.employeeOfficialDetailUpdateDto.appUserId) {
      let val = {
        ...values,
        appUserId: profileInfo.employeeOfficialDetailUpdateDto.appUserId,
        userEmployementInformationDto: {
          designationName: values.designationName,
        },
        DateRange_DateFrom: startDate,
        DateRange_DateTo: endDate,
      };
      val = pickBy(val, (v) => !!v);
      dispatch(employeeProfileOfficalUpdate(val));
      // console.log("val: ", val)
    }
  };

  const renderProfileInfo = () => {
    if (!profileInfo?.employeeOfficialDetailUpdateDto) {
      return <Skeleton paragraph={{ rows: 4 }} />;
    }
    return (
      <>
        <div className="profiledetails">
          <div className="profiledetails__wrapper">
            <div className="profiledetails__wrapper--conatainer">
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Hire Date</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          moment(
                            profileInfo?.employeeOfficialDetailUpdateDto
                              ?.hireDate
                          ).format("YYYY/MM/DD")
                        ) === "Invalid date"
                          ? "...."
                          : renderNameOrNA(
                              moment(
                                profileInfo?.employeeOfficialDetailUpdateDto
                                  ?.hireDate
                              ).format("YYYY/MM/DD")
                            )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Designation</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.designationName
                        }
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Department</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.departmentName
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">
                        Vehicle Motorcycle No.
                      </span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.vehicleNumber
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">
                        Contract Period
                      </span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.contractPeriod
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                {/* <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Contact</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto?.contact
                        )}
                      </span>
                    </div>
                  </div>
                </Col> */}
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Initials of CQ</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.cqInitial
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                {/* <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Email Address</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.emailAddress
                        )}
                      </span>
                    </div>
                  </div>
                </Col> */}
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Interviewed By</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.interviewedBy
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">
                        Internal Phone Code
                      </span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.internalPhoneCode
                        )}
                      </span>
                    </div>
                  </div>
                </Col>

                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">
                        Till Date Experience (Yrs)
                      </span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.tillDateExperience
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Official Mail</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.officialEmail
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">
                        Last Educational Qualification
                      </span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.lastEducation
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Last Training</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.lastTraining
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">
                        Training Certification
                      </span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.trainingCertification
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">SSF Number</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.ssfNumber
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">PAN Number</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.panNumber
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                {/* <Col span={24}>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <span className="profile__bodyTitle">Designation</span>
                    </div>
                    <div className="profiledetails__row--data">
                      <span className="profile__bodyInfo">
                        {renderNameOrNA(
                          profileInfo?.employeeOfficialDetailUpdateDto
                            ?.desginationName
                        )}
                      </span>
                    </div>
                  </div>
                </Col> */}

                {!isEmpty(grantedPolices) &&
                  grantedPolices["CQNovalFramework.DashboardMenu.Update"] && (
                    <div className="profile__details--footer">
                      <Button
                        type="link"
                        className="btn-update"
                        onClick={() => setShowInfo(false)}
                      >
                        Edit
                      </Button>
                    </div>
                  )}

                <div className="profile__details--footer">
                  {!profileInfo?.employeeOfficialDetailUpdateDto
                    ?.isSentWelcomeMessage && (
                    <Button
                      type="link"
                      className="btn-update update1"
                      style={{ marginLeft: 20 }}
                      onClick={() => sendMessage(userId)}
                    >
                      Joining Message
                    </Button>
                  )}
                </div>
              </Row>
            </div>
          </div>
        </div>
        <div className="profile__details--footer">
          {/* <Button
            type="link"
            className="btn-update"
            onClick={() => setShowInfo(false)}
          >
            Edit
          </Button> */}
        </div>
      </>
    );
  };

  const renderProfileUpdate = () => {
    if (!profileInfo?.employeeOfficialDetailUpdateDto) {
      return <Skeleton paragraph={{ rows: 10 }} />;
    }
    return (
      <div className="profiledetails">
        <div className="profiledetails__wrapper">
          <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            validateMessages={validateMessages}
            // initialValues={showInitialInfo(
            //   profileInfo?.employeeOfficialDetailUpdateDto
            // )}
          >
            <div className="profiledetails__wrapper--conatainer">
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Hire Date</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="hireDate">
                        <DatePicker format="YYYY/MM/DD" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Designation</span>
                    </div>
                    <div className="designationList-part">
                      <Form.Item
                        name="designationId"
                        rules={[
                          {
                            required: true,
                            message: "Required!",
                          },
                        ]}
                      >
                        <Select
                          className="designationList"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Please select"
                          autoComplete="off"
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {!isEmpty(designationListing) &&
                            designationListing.map((designation, index) => {
                              return (
                                <Option
                                  value={designation.designationId}
                                  title={designation.designationName}
                                  key={index}
                                >
                                  {designation.designationName}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Department</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="departmentName">
                        <Input placeholder="Department" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">
                        Vehicle/ Motorcycle No.
                      </span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="vehicleNumber">
                        <Input placeholder="Vehicle Motorcycle No." />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">
                        Contract Period
                      </span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          checked={isOnContract}
                          style={{ marginRight: 6 }}
                          onChange={() =>
                            setIsOnContract((prevState) => !prevState)
                          }
                        ></Checkbox>
                        <Form.Item name="contractPeriod">
                          <Input
                            placeholder="Contract Period"
                            disabled={!isOnContract}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Mobile Number</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <span className="profile__bodyInfo">
                        <Form.Item name="mobileNumber">
                          <Input placeholder="Mobile Number" />
                        </Form.Item>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">SSF Number</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <span className="profile__bodyInfo">
                        <Form.Item name="ssfNumber">
                          <Input placeholder="SSF Number" />
                        </Form.Item>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">PAN Number</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <span className="profile__bodyInfo">
                        <Form.Item name="panNumber">
                          <Input placeholder="PAN Number" />
                        </Form.Item>
                      </span>
                    </div>
                  </div>
                </Col>
                {/* <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit--label">
                    <span className="profile__bodyTitle">Contract Date :</span>
                  </div>
                </Col>
                <Form.List
                  name="items1"
                  rules={[
                    // {
                    //   validator: async (_, names) => {
                    //     if (!names || names.length < 1) {
                    //       return Promise.reject(
                    //         new Error("At least 1 passengers")
                    //       );
                    //     }
                    //   },
                    // },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Col lg={24} md={12} xs={24}>
                          <Form.Item required={false} key={field.key}>
                            <div
                              className="profiledetails__rowedit"
                              style={{
                                alignItems: "center",
                              }}
                            >
                              <div
                                className="profile__bodyTitle"
                                style={{
                                  marginRight: "5px",
                                }}
                              >
                                {index + 1}.
                              </div>
                              <div
                                style={{
                                  marginRight: "5px",
                                }}
                                className="profiledetails__rowedit--label"
                              >
                                <span className="profile__bodyTitle">
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "contractType"]}
                                    fieldKey={[field.fieldKey, "contractType"]}
                                  >
                                    <Select>
                                      <Select.Option value="permanent">
                                        Permanent
                                      </Select.Option>
                                      <Select.Option value="probation">
                                        Probation
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                </span>
                              </div>
                              <div
                                style={{
                                  marginRight: "5px",
                                }}
                                className="profiledetails__rowedit--data"
                              >
                                <span className="profile__bodyInfo">
                                  <Form.Item
                                    {...field}
                                    name={[field.name, "ContractDate"]}
                                    fieldKey={[field.fieldKey, "ContractDate"]}
                                  >
                                    <RangePicker
                                      format="YYYY-MM-DD"
                                      onChange={(value) => {
                                        // setStartDate(
                                        //   value
                                        //     ? moment(value[0]).format(
                                        //         "YYYY-MM-DD"
                                        //       )
                                        //     : ""
                                        // );
                                        // setEndDate(
                                        //   value
                                        //     ? moment(value[1]).format(
                                        //         "YYYY-MM-DD"
                                        //       )
                                        //     : ""
                                        // );
                                      }}
                                    />
                                  </Form.Item>
                                </span>
                              </div>
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </div>
                          </Form.Item>
                        </Col>
                      ))}
                      <Col lg={24} md={12} xs={24}>
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{
                              width: "60%",
                            }}
                            icon={<PlusOutlined />}
                          >
                            Add Contract
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Form.List> */}
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Contract Date</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <span className="profile__bodyInfo">
                        <Form.Item name="ContractDate">
                          <RangePicker
                            format="YYYY-MM-DD"
                            onChange={(value) => {
                              setStartDate(
                                value
                                  ? moment(value[0]).format("YYYY-MM-DD")
                                  : ""
                              );
                              setEndDate(
                                value
                                  ? moment(value[1]).format("YYYY-MM-DD")
                                  : ""
                              );
                            }}
                          />
                        </Form.Item>
                      </span>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Initials of CQ</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="cqInitial">
                        <Input placeholder="Initials of CQ" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                {/* <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Email Address</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <span className="profile__bodyInfo">---</span>
                    </div>
                  </div>
                </Col> */}
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Interviewed By</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="interviewedBy">
                        <Input placeholder="Interviewed By" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">
                        Internal Phone Code
                      </span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="internalPhoneCode">
                        <Input placeholder="Internal Phone Code" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">
                        Till Date Experience (Yrs)
                      </span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="tillDateExperience">
                        <Input placeholder="Till Date Experience  (Yrs)" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Official Mail</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="officialEmail">
                        <Input placeholder="Official Mail" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">
                        Last Educational Qualification
                      </span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="lastEducation">
                        <Input placeholder="Last Educational Qualification" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">Last Training</span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="lastTraining">
                        <Input placeholder="Last Training" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col lg={24} md={12} xs={24}>
                  <div className="profiledetails__rowedit">
                    <div className="profiledetails__rowedit--label">
                      <span className="profile__bodyTitle">
                        Training Certification
                      </span>
                    </div>
                    <div className="profiledetails__rowedit--data">
                      <Form.Item name="trainingCertification">
                        <Input placeholder="Training Certification" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>

                {/* <Col lg={12} md={24} xs={24}>
							<span className="profile__bodyTitle">Supervisior</span>
							<Form.Item name="userReportingManagerIds" rules={[{ required: false }]}>
								<Select mode="multiple" allowClear filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
									{users.map((user) => (
										<Option  key={user.fullName} value={user.fullName}>
											{user.fullName}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col> */}
              </Row>
            </div>

            <div className="profile__info--footer">
              <Button
                type="link"
                className="btn-cancel"
                onClick={() => setShowInfo(true)}
              >
                Cancel
              </Button>
              <Button
                type="link"
                className="btn-update"
                htmlType="submit"
                loading={updateProfileOfficialLoading}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  return <div>{showInfo ? renderProfileInfo() : renderProfileUpdate()}</div>;
};

export default _OfficialInformation;
