import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Form, Space, Layout, Button, Spin } from "antd";
import { SaveTwoTone } from "@ant-design/icons";

import {
  actionForGetReferenceTemplateGetDetailsById,
  actionForAddUpdateReferenceTemplate,
  actionForMailUpdateReferenceTemplateById,
  actionForUpdateTemplate,
} from "services/redux/Reference/Tender/action";
import { useDispatch, useSelector } from "react-redux";
import {
  ACTION_CONTENT_TEMPLATE_MAIL,
  ACTION_CONTENT_TEMPLATE_TENDER,
} from "components/constants/mail";

function CircularIndex(props) {
  const {
    id,
    data,
    referenceId,
    editorData,
    setEditorData,
    origin,
    handleCloseEditModal,
  } = props;

  const { addUpdateCircularReferenceTemplateLoading } = useSelector(
    (state) => state.tender
  );

  const dispatch = useDispatch();

  const [formCircularEditor] = Form.useForm();
  const [isEditCircularTemplateLoading, setIsEditCircularTemplateLoading] =
    useState(false);
  const [AltEnt, setAltEnt] = useState(false);

  const { Header, Footer } = Layout;

  const onCircularContentFinish = async () => {
    let apiData = {
      referenceId,
      content: editorData,
    };

    let updateApiData = {
      referenceId,
      content: editorData,
      referenceTemplateId: id,
    };

    try {
      if (id) {
        await dispatch(
          actionForAddUpdateReferenceTemplate("mail", updateApiData)
        );
        if (origin === "mail") {
          let apiPayload = {
            ...updateApiData,
            id: updateApiData?.referenceTemplateId,
          };

          delete apiData?.referenceId;
          delete apiData?.referenceTemplateId;
          // await dispatch(actionForUpdateTemplate(apiPayload))
          setIsEditCircularTemplateLoading(false);
          handleCloseEditModal();
        } else {
          await dispatch(
            actionForGetReferenceTemplateGetDetailsById(referenceId)
          );
          handleCloseEditModal();
        }
      } else {
        await dispatch(actionForAddUpdateReferenceTemplate("mail", apiData));
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setIsEditCircularTemplateLoading(false);
      setEditorData("");
      handleCloseEditModal();
    }
  };

  return (
    <>
      <Form form={formCircularEditor} onFinish={onCircularContentFinish}>
        <div className="mail-quick-message">
          <div className="mail-message" style={{ width: "100%" }}>
            <Header className="email_compose__header">
              <Space>
                <div
                  className="compose-action-wrapper"
                  style={{ color: "#000000" }}
                >
                  <Button
                    style={{
                      border: "none",
                      background: "transparent",
                      boxShadow: "none",
                      outline: "none",
                    }}
                    htmlType="submit"
                    // type="primary"
                    // loading={emailShareResponseLoading}
                  >
                    {/* SEND */}
                    {/* {addUpdateCircularReferenceTemplateLoading ? ( */}
                    {isEditCircularTemplateLoading ? (
                      <Spin />
                    ) : (
                      <SaveTwoTone
                        onClick={() => setIsEditCircularTemplateLoading(true)}
                        style={{
                          fontSize: "20px",
                        }}
                      />
                    )}
                  </Button>
                </div>
              </Space>
            </Header>
          </div>
        </div>
        <Editor
          className="compose-editor"
          value={editorData}
          onKeyDown={(e) => {
            const { key, altKey } = e;
            if (altKey && key === "Enter") {
              setAltEnt(true);
            }
          }}
          init={{
            // auto_focus:
            //   applicationTypeStatus === "reply" ||
            //   applicationTypeStatus === "replyall"
            //     ? true
            //     : false,
            height: 800,
            // width: 800,
            width: "100%",
            auto_focus: true,
            browser_spellcheck: true,
            plugins: ["lists advlist", "image", " paste"],
            menubar: "false",
            file_browser_callback_types: "image",
            file_picker_callback: function (callback, value, meta) {
              if (meta.filetype == "image") {
                var input = document.getElementById("my-file");
                input.click();
                input.onchange = function () {
                  var file = input.files[0];
                  var reader = new FileReader();
                  reader.onload = function (e) {
                    callback(e.target.result, {
                      alt: file.name,
                    });
                  };
                  reader.readAsDataURL(file);
                };
              }
            },
            paste_data_images: true,
            // toolbar: "paste",
            paste_as_text: false,
            paste_merge_formats: false,
            paste_tab_spaces: 12,
            toolbar:
              "fontselect fontsizeselect backcolor forecolor bold | italic | underLine lineheight |  strikethrough alignleft aligncenter alignright |  bullist numlist",

            content_style: "p{margin:0px;padding:0px}",
            forced_root_block: "div",
            forced_root_block_attrs: {
              style: "font-family: Arial; font-size: 10pt",
              class: "editor-block-wrapper",
              id: "editor-blocks",
            },
            font_formats:
              "Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;Arial Narrow=arial narrow,avant garde;Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier;Cambria=cambria;Calibri=calibri;Calibri Body=calibri boby;Georgia=georgia,palatino;Times New Roman=times new roman,times;Helvetica=helvetica;Verdana=verdana,geneva;",
          }}
          onEditorChange={(e) => {
            setEditorData(e);
          }}
        />
      </Form>
    </>
  );
}

export default CircularIndex;
