import { Drawer, Form, Row, Col, Input, Button, Tabs } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import BasicDetails from "./basicDetails";
import EducationDetails from "./educationDetails";
import JobDetails from "./jobDetails";
import ReviewDetails from "./reviews";
import ApproveDetails from "./approveDetails";
import JobInterview from "./jobInterview";

const { TabPane } = Tabs;

const inputFieldWidht = {
  width: "260px",
};

export default function ApplicantsFormDrawer({
  openDrawerApplicant,
  setOpenApplicantDrawer,
  jobTitleId,
  formApplicantsBasic,
  formApplicantEdu,
  setSelectedSingleApplicantData,
  activeTabIndexofApplicant,
  setActiveTabIndexofAppicant,
  selectedSingleApplicantDAta,
  applicantSingleDataID,
  setIsGraduated,
  selectedIsGraduated,
  formApplicantJob,
  formApplicantReview,
  statusUpdateButton,
  selectedTemporarilyApplicantId,
}) {
  const onClose = () => {
    formApplicantJob.resetFields();
    formApplicantsBasic.resetFields();
    formApplicantEdu.resetFields();
    formApplicantReview.resetFields();
    setOpenApplicantDrawer(false);
    setSelectedSingleApplicantData();
  };

  // const [selectedActiveKey, setSelectedActiveKey] = useState();
  const headerItems = [
    {
      name: "Applicant",
      key: "1",
      content: (
        <BasicDetails
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          jobTitleId={jobTitleId}
          formApplicantsBasic={formApplicantsBasic}
          formApplicantEdu={formApplicantEdu}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          selectedSingleApplicantDAta={selectedSingleApplicantDAta}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
        />
      ),
    },
    {
      name: "Education Details",
      key: "2",
      content: (
        <EducationDetails
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          jobTitleId={jobTitleId}
          formApplicantsBasic={formApplicantsBasic}
          formApplicantEdu={formApplicantEdu}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          selectedSingleApplicantDAta={selectedSingleApplicantDAta}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
          applicantSingleDataID={applicantSingleDataID}
          setIsGraduated={setIsGraduated}
          selectedIsGraduated={selectedIsGraduated}
          selectedTemporarilyApplicantId={selectedTemporarilyApplicantId}
        />
      ),
    },
    {
      name: "Job Details",
      key: "3",
      content: (
        <JobDetails
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          jobTitleId={jobTitleId}
          formApplicantsBasic={formApplicantsBasic}
          formApplicantEdu={formApplicantEdu}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          selectedSingleApplicantDAta={selectedSingleApplicantDAta}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
          selectedTemporarilyApplicantId={selectedTemporarilyApplicantId}
        />
      ),
    },
    {
      name: "Reviews",
      key: "4",
      content: (
        <ReviewDetails
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          jobTitleId={jobTitleId}
          formApplicantsBasic={formApplicantsBasic}
          formApplicantEdu={formApplicantEdu}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          selectedSingleApplicantDAta={selectedSingleApplicantDAta}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
          statusUpdateButton={statusUpdateButton}
          selectedTemporarilyApplicantId={selectedTemporarilyApplicantId}
        />
      ),
    },
    {
      name: "Job Interview",
      key: "5",
      content: (
        <JobInterview
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          jobTitleId={jobTitleId}
          formApplicantsBasic={formApplicantsBasic}
          formApplicantEdu={formApplicantEdu}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          selectedSingleApplicantDAta={selectedSingleApplicantDAta}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
          statusUpdateButton={statusUpdateButton}
          selectedTemporarilyApplicantId={selectedTemporarilyApplicantId}
        />
      ),
    },
  ];

  if (
    selectedSingleApplicantDAta?.currentApplicationStageLevel ===
    "FinalApproved"
  ) {
    headerItems.push({
      name: "Convert",
      // name: "Approve",
      key: "6",
      content: (
        <ApproveDetails
          jobTitleId={jobTitleId}
          selectedSingleApplicantDAta={selectedSingleApplicantDAta}
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          formApplicantEdu={formApplicantEdu}
          formApplicantsBasic={formApplicantsBasic}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
        />
      ),
    });
  }

  return (
    <>
      <Drawer
        title="Applicant Details"
        width={630}
        onClose={onClose}
        closable={false}
        visible={openDrawerApplicant}
        keyboard={false}
        maskClosable={false}
      >
        <span
          onClick={onClose}
          style={{
            position: "absolute",
            right: "16px",
            top: "16px",
            cursor: "pointer",
          }}
        >
          <CloseOutlined />
        </span>
        <Tabs
          defaultActiveKey="1"
          tabBarGutter={25}
          activeKey={activeTabIndexofApplicant}
          onChange={(key) => {
            setActiveTabIndexofAppicant(key);
          }}
          style={{ marginLeft: "10px" }}
        >
          {headerItems.map((x) => {
            return (
              <>
                <TabPane tab={x.name} key={x.key} className="testing">
                  <div style={{ marginTop: "8px" }}>{x.content}</div>
                </TabPane>
              </>
            );
          })}
        </Tabs>
      </Drawer>
    </>
  );
}
