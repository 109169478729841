import React, { useState, useEffect } from "react";
import {
  Input,
  Col,
  Row,
  Button,
  InputNumber,
  DatePicker,
  Select,
  Collapse,
  Form,
  Skeleton,
  message,
} from "antd";
import { PlusOutlined, StarFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { referenceOthersFavAddUpdate } from "services/redux/Reference/Others/action";

import { isEmpty, pickBy } from "lodash";
import ContactEmail from "../TenderTabs/Includes/EmailChat.";
const { Panel } = Collapse;
const edit = "./assets/svg/profile-edit.svg";

const { Option } = Select;

const RenderData = ({ getDetails, setEditForm, setIsUpdate }) => {
  const dispatch = useDispatch();
  const genExtra = () => (
    <Button
      type="link"
      style={{ paddingTop: 1 }}
      className="btn-edit"
      onClick={() => {
        // setEditForm(false);
        setIsUpdate("edit");
      }}
    >
      <img src={edit} />
    </Button>
  );
  const {
    referenceOthersDetailsResponse,
    referenceOthersUpdateResponse,
    referenceOthersLoading,
  } = useSelector((state) => state.referenceOthers);

  return (
    <>
      <div className="profiledetails" style={{ margin: 0, width: "100%" }}>
        <div className="profiledetails__wrapper">
          <div className="profiledetails__collapse">
            <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
              <Panel header="Basic" key="1" extra={genExtra()}>
                {referenceOthersDetailsResponse?.title ? (
                  <div className="referenceDetails__info">
                    <div className="referenceDetails__label">
                      <span className="referenceDetails--title bold ">
                        {" "}
                        Title
                      </span>
                    </div>
                    <div style={{ width: 20, marginLeft: -20 }}>
                      <StarFilled
                        onClick={() => {
                          dispatch(
                            referenceOthersFavAddUpdate(
                              {
                                referenceOthersId:
                                  referenceOthersDetailsResponse?.id,
                              },
                              referenceOthersDetailsResponse
                            )
                          );
                        }}
                        style={{
                          color: `${
                            referenceOthersDetailsResponse?.isFavorite
                              ? "#FBA525"
                              : "grey"
                          }`,
                        }}
                      />
                    </div>
                    <div className="referenceDetails__labeldata">
                      <span
                        className="referenceDetails--subtitle bold"
                        style={{ color: "blue" }}
                      >
                        {referenceOthersDetailsResponse?.referenceCode}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {referenceOthersDetailsResponse?.description ? (
                  <div className="referenceDetails__info">
                    <div className="referenceDetails__label">
                      <span className="referenceDetails--title bold ">
                        {" "}
                        Description
                      </span>
                    </div>

                    <div className="referenceDetails__labeldata">
                      <span className="referenceDetails--subtitle bold">
                        {referenceOthersDetailsResponse?.description}
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderData;
