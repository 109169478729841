import { ADMIN_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { MeetingStatusService } from "./api";

const meetingStatusService = new MeetingStatusService();

export const getMeetingStatusListings = () => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.MEETING_STATUS_LISTING));
    const response = await meetingStatusService.getMeetingStatusListings();
    dispatch(finish(ADMIN_TYPES.MEETING_STATUS_LISTING));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.MEETING_STATUS_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};