import { ADMIN_TYPES } from '../../../types';
import { init, success, finish, error } from 'services/common';
import { SisterCompanyService } from './api';
import { message } from 'antd';

const sisterCompanyService = new SisterCompanyService();

export const getSisterCompaniesListings = () => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.SISTER_COMPANY_LISTS));
    const response = await sisterCompanyService.getSisterCompaniesListings();
    dispatch(finish(ADMIN_TYPES.SISTER_COMPANY_LISTS));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.SISTER_COMPANY_LISTS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getSisterCompanyPagination = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.SISTER_COMPANY_PAGINATION));
    const response = await sisterCompanyService.getSisterCompanyPagination(
      query
    );
    dispatch(finish(ADMIN_TYPES.SISTER_COMPANY_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(ADMIN_TYPES.SISTER_COMPANY_PAGINATION, response.data, loadMore)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateSisterCompany = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.SISTER_COMPANY_UPDATE));
    const response = await sisterCompanyService.updateSisterCompany(body);
    dispatch(finish(ADMIN_TYPES.SISTER_COMPANY_UPDATE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.SISTER_COMPANY_UPDATE, response.data));
      dispatch(getSisterCompanyPagination(`maxResultCount=10&skipCount=0`));
      message.success('Sister company updated successfully');
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
