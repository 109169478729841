import { appBaseUrl } from "utils/config";
import { postService } from "../../../../commonServices";

export class MeetingDecisionService {
  addDecisionEventAgenda(body) {
    let url = `${appBaseUrl}/organizerEvent/meetingEventDecisionTodoAdd`;
    let data = postService(url, body, 'post');
    return data;
  }
  meetingEventDecisionUpdate(body, id) {
    let url = `${appBaseUrl}/organizerEvent/meetingEventDecisionTodoUpdate/${id}`;
    let data = postService(url, body, 'put');
    return data;
  }
}