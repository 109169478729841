const addCommaSeparator = (event, name1, form) => {
  const { value } = event.target;

  const commaNum = value.replace(/\,/g, "");
  if (isNaN(Number(commaNum))) {
    return value;
  }

  const tmp = value.replace(/,/g, "");
  const formattedValue = Number(tmp).toLocaleString();
  // return formattedValue;
  form.setFieldsValue({ [name1]: formattedValue });
};

export default addCommaSeparator;
