import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import moment from "moment";

// antd
import { Row, Col, Button, Layout, Tag, Table, Select } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";

// includes
import { BulkFormDrawer } from "./BulkFormDrawer";

// constants
import { LogoImage } from "utils/imageConstants";

// redux
import {
  getAllFiscalYears,
  getHolidayEvents,
} from "services/redux/organizer/organizer.action";
import { useDispatch, useSelector } from "react-redux";
import { usePreviousQuery } from "utils/commonFunc";

const { Option } = Select;

export const Event = () => {
  const dispatch = useDispatch();
  const organizerState = useSelector((state) => state.organizer);

  const {
    allFiscalYears,
    fiscalYearsLoading,
    holidayEvents,
    holidayEventsLoading,
    addUpdateBulkHolidayResp,
    addHolidayEventsLoading,
  } = organizerState;

  const { Content } = Layout;

  const [selectName, setName] = useState("");
  const [selectedFiscalYear, setSelectedFiscalYear] = useState("");
  const [visibleBulkForm, setVisibleBulkForm] = useState(false);
  const [eventFormType, setEventFormType] = useState("single");
  const [selectedEditHolidayEvent, setSelectedEditHolidayEvent] = useState([]);
  const pageSize = 10;

  useEffect(() => {
    dispatch(getAllFiscalYears());
  }, []);

  let prevAllFiscalYear = usePreviousQuery(allFiscalYears);

  useEffect(() => {
    if (allFiscalYears !== prevAllFiscalYear && !isEmpty(allFiscalYears)) {
      let currentFiscalYear = allFiscalYears.filter((year) => year.isCurrent);
      setSelectedFiscalYear(currentFiscalYear[0].fiscalYearId);
      getData(pageSize, 0, "", "", currentFiscalYear[0].fiscalYearId);
    }
  }, [allFiscalYears]);

  useEffect(() => {
    if (!isEmpty(addUpdateBulkHolidayResp)) {
      setVisibleBulkForm(false);
    }
  }, [addUpdateBulkHolidayResp]);

  const onSelect = () => {
    onOpenCloseBulkForm();
  };

  const onChangeFiscalYear = (value) => {
    setSelectedFiscalYear(value);
    getData(pageSize, 0, "", "", value);
  };

  const onOpenCloseBulkForm = () => setVisibleBulkForm(!visibleBulkForm);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType,
    fiscalYearId = selectedFiscalYear
  ) => {
    dispatch(
      getHolidayEvents({
        fiscalYearId,
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
      })
    );
  };

  const selectTitle = (name) => {
    setName(name);
  };

  const HolidayClickedButton = ({ name }) => {
    return (
      <Button
        className="btn-primary"
        onClick={() => {
          selectTitle(name);
          onSelect();
          setSelectedEditHolidayEvent([]);
          setEventFormType("single");
        }}
      >
        <PlusOutlined />
        <span className="button__label">Create Holiday Entry</span>
      </Button>
    );
  };

  const Bulk__ClickedButton = ({ name }) => {
    return (
      <Button
        className="btn-primary"
        onClick={() => {
          selectTitle(name);
          onSelect();
          setSelectedEditHolidayEvent([]);
          setEventFormType("bulk");
        }}
      >
        <PlusOutlined />
        <span className="button__label">Create Bulk Entry</span>
      </Button>
    );
  };

  const EditBulkClickedButton = ({ name }) => {
    return (
      <Button
        className="btn-primary"
        onClick={() => {
          selectTitle(name);
          onSelect();
          setSelectedEditHolidayEvent(holidayEvents.items);
          setEventFormType("bulk");
        }}
        disabled={isEmpty(holidayEvents.items)}
      >
        <EditOutlined />
        <span className="button__label">Edit Bulk Entry</span>
      </Button>
    );
  };

  const Columns = [
    {
      title: "S NO",
      align: "center",

      // sorter: true,
    },
    {
      title: "Title",
      align: "center",
      dataIndex: "title",
      key: "title",
      sorter: true,
    },
    {
      title: "Date",
      align: "center",
      dataIndex: "date",
      key: "date",
      sorter: true,
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "isActive",
      key: "isActive",
      render: (tag) => {
        let color = "";
        let status = "";
        if (tag) {
          color = "green";
          status = "Active";
        } else {
          color = "red";
          status = "InActive";
        }
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Remarks",
      align: "center",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Action",
      align: "center",
      key: "action",
      render: (text) => (
        <>
          <a
            className="action-btn action-btn-add"
            onClick={() => {
              setSelectedEditHolidayEvent([text]);
              setEventFormType("single");
              onOpenCloseBulkForm();
            }}
          >
            <EditOutlined /> Edit
          </a>
        </>
      ),
    },
  ];

  const data = !isEmpty(holidayEvents.items)
    ? holidayEvents.items.map((holiday) => {
        return {
          key: holiday.id,
          title: holiday.title,
          date: moment(holiday.date).format("YYYY/MM/DD"),
          remarks: holiday.remarks,
          isActive: holiday.isActive,
        };
      })
    : [];

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
  };

  return (
    <Layout className="organizerHoliday layout">
      <Content>
        <div className="event_content__wrapper">
          <div className="organizerHoliday__top">
            <h3 className="header__all">Holiday</h3>
            <div className="contract__row__wrapper">
              <span className="contact__discussion__label">Fiscal Year</span>
              <div className="contact__discussion__input">
                {
                  <Select
                    // mode="multiple"
                    // allowClear
                    placeholder="Select Fiscal Year"
                    style={{ width: 150 }}
                    onChange={onChangeFiscalYear}
                    value={selectedFiscalYear}
                  >
                    {allFiscalYears.map((year, index) => {
                      return (
                        <Option value={year.fiscalYearId} key={index}>
                          {year.displayName}
                        </Option>
                      );
                    })}
                  </Select>
                }
              </div>
            </div>
          </div>
          <div className="organizerHoliday__middle">
            <div className="organizerHoliday__middle--wrap">
              <HolidayClickedButton name="Create Holiday" />
            </div>
            <div className="organizerHoliday__middle--wrap">
              <Bulk__ClickedButton name="Create Bulk Entry" />
            </div>
            <div className="organizerHoliday__middle--wrap">
              <EditBulkClickedButton name="Edit Bulk Entry" />
            </div>
          </div>
          <Table
            className="organizer__tables"
            dataSource={data}
            columns={Columns}
            onChange={handleTableChange}
            pagination={{
              total: holidayEvents.totalCount,
              pageSize: pageSize,
            }}
            loading={{
              indicator: <img src={LogoImage} height="auto" width="50px" />,
              spinning:
                holidayEventsLoading ||
                addHolidayEventsLoading ||
                fiscalYearsLoading,
            }}
          />
        </div>
        <BulkFormDrawer
          name={selectName}
          visible={visibleBulkForm}
          onClose={onOpenCloseBulkForm}
          selectedEditHolidayEvent={selectedEditHolidayEvent}
          eventFormType={eventFormType}
        />
      </Content>
    </Layout>
  );
};
