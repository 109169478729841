import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import {
  Form,
  Input,
  Layout,
  Button,
  Checkbox,
  DatePicker,
  Select,
  Row,
  Col,
  Divider,
  Drawer,
  Radio,
  Steps,
  message,
  InputNumber,
  Typography,
  Upload,
} from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";
import { appBaseUrl } from "../../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { tadaDetailsUpdateSettlement } from "services/redux/requistions/tadaDetails/action";
import { isEmpty } from "lodash";

const SettlementApplicantForm = ({ current, setCurrent, details }) => {
  console.log("Settlement Data", details);
  const dispatch = useDispatch();
  const [form] = useForm();
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const {
    tadaDetailsUpdateSettlementResponse,
    tadaDetailsUpdateSettlementLoading,
  } = useSelector((state) => state.tadaDetails);

  const [numOfDays, setNumOfDays] = useState(details?.visitDays);
  const [fileList, setFileList] = useState([]);
  const [totalPrice, setTotalPrice] = useState();
  const [miscActualExpenses, setMiscActualExpenses] = useState(0);

  console.log("numOfDays", numOfDays);

  const handleMiscExpenses = (e) => {
    setMiscActualExpenses(Number(e.target.value));
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const rangeConfig = {
    rules: [{ type: "array", required: true, message: "Please select time!" }],
  };

  const handleChange = ({ file, fileList }) => {
    // Update the file list
    setFileList(fileList);

    // Check for errors in file upload
    if (file.status === "error") {
      message.error(`File "${file.name}" upload failed.`);
    }

    // Check for completed file uploads
    if (file.status === "done") {
      message.success(`File "${file.name}" uploaded successfully.`);
    }
  };

  const handleBeforeUpload = (file) => {
    // Validate file type, size, etc. before uploading
    console.log("File before upload:", file);
    return true; // Return true to allow upload, or false to prevent upload
  };

  const handleRemove = (file) => {
    // Remove file from the file list
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const validateNonNegativeNumber = (_, value) => {
    if (value && Number(value) < 0) {
      return Promise.reject(new Error("Value cannot be negative."));
    } else if (value && Number(value) > 10000000) {
      return Promise.reject(new Error("Value exceeds the limit 10000000."));
    }
    return Promise.resolve();
  };

  const props = {
    // action: { appBaseUrl } / file / uploadFile / { id }, // Specify the API endpoint for uploading the file
    accept: ".pdf,.doc,.docx", // Specify the accepted file types
    beforeUpload: handleBeforeUpload, // Callback function to handle file validation before upload
    onChange: handleChange, // Callback function to handle file upload status changes
    onRemove: handleRemove, // Callback function to handle file removal
    fileList: fileList, // Pass the file list to be displayed
  };

  const handleRangePickerChange = (dates) => {
    if (dates && dates.length === 2) {
      const diffInDays = moment(dates[1]).diff(moment(dates[0]), "days") + 1; // +1 to include both start and end date
      setNumOfDays(diffInDays);
    } else {
      setNumOfDays(details?.visitDays);
    }
  };

  useEffect(() => {
    let lPrice = details?.lodgingRate * (numOfDays - 1);

    let fPrice = details?.foodingRate * numOfDays;

    form.setFieldsValue({
      lodgingRate: lPrice,
      foodingRate: fPrice,
    });

    setTotalPrice(Number(lPrice) + Number(fPrice));
    if (details) {
      const startDate = moment(details.visitStartDateEn, "YYYY-MM-DD");
      const endDate = moment(details.visitEndDateEn, "YYYY-MM-DD");

      form.setFieldsValue({
        actualDateRange: [startDate, endDate],
      });
    }
  }, [details, form, numOfDays]);

  useEffect(() => {
    if (tadaDetailsUpdateSettlementResponse === true) {
      form.resetFields();
    }
  }, [tadaDetailsUpdateSettlementResponse]);

  useEffect(() => {
    if (!isEmpty(tadaDetailsUpdateSettlementResponse)) {
      setTimeout(() => {
        form.resetFields();
      }, 500);
    }
  }, [tadaDetailsUpdateSettlementResponse]);

  const onFinish = (values) => {
    const removeProp = "actualDateRange";
    const { [removeProp]: remove, ...rest } = values;
    const body = {
      ...rest,
      actualVisitDays: numOfDays,
      actualStartDateEn: moment(values.actualDateRange[0]).format("YYYY-MM-DD"),
      actualEndDateEn: moment(values.actualDateRange[1]).format("YYYY-MM-DD"),
      miscellaneousActualTotal: miscActualExpenses,
    };
    dispatch(tadaDetailsUpdateSettlement(body, details?.id));
    form.resetFields();
    console.log("values here", values, body);
  };

  console.log(form.getFieldValue("actualDateRange"));

  return (
    <React.Fragment>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Layout.Content style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Row>
            <Col span={19}>
              <Form.Item
                label="Visit Date (Actual)"
                {...rangeConfig}
                name="actualDateRange"
                style={{ display: "block" }}
              >
                <RangePicker onChange={handleRangePickerChange} size="small" />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={4}>
              <Form.Item label="Days" style={{ display: "block" }}>
                <InputNumber value={numOfDays} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="radio-group" label="Type of visit">
            <Radio.Group value={details?.isDomesticOrInternational}>
              {details?.isDomesticOrInternational === 0 ? (
                <>
                  <Radio checked>Domestic</Radio>
                </>
              ) : (
                <>
                  <Radio checked>International</Radio>
                </>
              )}
            </Radio.Group>
          </Form.Item>

          <Row style={{ justifyContent: "space-between", paddingTop: 5 }}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please select your visit category!",
                  },
                ]}
              >
                <Select
                  size="small"
                  placeholder="Visit Category"
                  disabled
                  value={details?.tadaDestinationTypeDto?.typeName}
                />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item>
                <Input
                  autoComplete="off"
                  placeholder="City/Country Name"
                  readOnly
                  value={details?.placeOfVisit}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Purpose of visit">
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 6 }}
              autoComplete="off"
              readOnly
              value={details?.purposeOfVisit}
              placeholder="Mention purpose of visit here..."
            />
          </Form.Item>

          <Form.Item label="Transport Means">
            <Input
              autoComplete="off"
              readOnly
              placeholder="for e.g Bus, Air, Train and so on."
              value={details?.transportationMeans}
            />
          </Form.Item>

          <Typography style={{ marginBottom: 4 }}>Actual Amount</Typography>
        </Layout.Content>
        <Layout.Content
          style={{
            backgroundColor: "#FAFAFA",
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
          }}
        >
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>Category</Typography>
            </Col>
            <Col span={8}>
              <Typography style={{ marginBottom: 4, textAlign: "end" }}>
                Per Day
              </Typography>
            </Col>
            <Col span={8}>
              <Typography style={{ marginBottom: 4, textAlign: "end" }}>
                Total
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>Lodging</Typography>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  autoComplete="off"
                  placeholder="0"
                  readOnly
                  value={details?.lodgingRate}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="lodgingRate">
                <Input autoComplete="off" placeholder="0" readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>Fooding</Typography>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  autoComplete="off"
                  placeholder="0"
                  readOnly
                  value={details?.foodingRate}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="foodingRate">
                <Input autoComplete="off" placeholder="0" readOnly />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>
                Misc. Expenses
              </Typography>
            </Col>
            <Col span={16}>
              <Form.Item>
                <Input
                  readOnly
                  value={details?.miscellaneousAdvanceTotal}
                  autoComplete="off"
                  placeholder="0"
                />
              </Form.Item>
            </Col>
          </Row> */}
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>
                Total Actual Amount
              </Typography>
            </Col>
            <Col span={16}>
              <Form.Item>
                <Input
                  autoComplete="off"
                  placeholder="0"
                  readOnly
                  value={totalPrice}
                />
              </Form.Item>
            </Col>
          </Row>
        </Layout.Content>
        <Layout.Content
          style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}
        >
          <Typography style={{ marginBottom: 4 }}>Expense Summary</Typography>
        </Layout.Content>
        <Layout.Content
          style={{
            backgroundColor: "#FAFAFA",
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
          }}
        >
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>
                Actual Expense
              </Typography>
            </Col>
            <Col span={16}>
              <Form.Item>
                <Input autoComplete="off" placeholder="0" value={totalPrice} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>
                Miscellaneous Expense
              </Typography>
            </Col>
            <Col span={16}>
              <Form.Item
                name="miscellaneousActualTotal"
                rules={[
                  {
                    validator: validateNonNegativeNumber,
                  },
                ]}
              >
                <Input
                  type="number"
                  min={0}
                  max={10000000}
                  maxLength={10000000}
                  autoComplete="off"
                  placeholder="0"
                  value={miscActualExpenses}
                  onChange={handleMiscExpenses}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>Advance Taken</Typography>
            </Col>
            <Col span={16}>
              <Form.Item>
                <Input
                  autoComplete="off"
                  placeholder="0"
                  readOnly
                  value={details?.totalAdvanceApprovedAmount}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Typography style={{ marginBottom: 4 }}>Balance</Typography>
            </Col>
            <Col span={16}>
              <Form.Item>
                <Input
                  autoComplete="off"
                  placeholder="0"
                  value={
                    totalPrice +
                    miscActualExpenses -
                    details?.totalAdvanceApprovedAmount
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Layout.Content>
        {/* <Layout.Content
          style={{
            backgroundColor: "#FAFAFA",
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            marginTop: 10,
          }}
        >
          <Form.Item label="Upload Attachment">
            <Upload {...props} action={`${appBaseUrl}/file/uploadFile`}>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
         
          <Form.Item label="Remarks">
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 2 }}
              autoComplete="off"
              placeholder="Remarks if any."
            />
          </Form.Item>
        </Layout.Content> */}
        {/* <Typography style={{ marginBottom: 4 }}>Upload Attachment</Typography> */}
        {/* <Row>
            <Col span={24}>
              <Form.Item label="Upload Attachment">
                <Upload {...props} action={`${appBaseUrl}/file/uploadFile`}>
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row> */}
        <Layout.Content
          style={{ display: "flex", flexDirection: "row-reverse", padding: 10 }}
        >
          <Button
            htmlType="submit"
            loading={tadaDetailsUpdateSettlementLoading}
            style={{
              backgroundColor: "#3879C5",
              color: "white",
              borderRadius: 4,
            }}
          >
            Submit
          </Button>
        </Layout.Content>
      </Form>
    </React.Fragment>
  );
};

export default SettlementApplicantForm;
