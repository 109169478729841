import { Row, Col, Carousel } from "antd";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { cqBaseUrl } from "utils/config";

function HonorCard({ cardData }) {
  const { token } = useSelector((state) => state.auth);
  return (
    <Row className="honor-row">
      <Col lg={24}>
        <div className="honor__card">
          <div className="honor__card--details">
            <h2>{cardData?.title}</h2>
            <p>{cardData?.note}</p>
            <p className="author">
              {cardData.honorRollCandidateDtos?.map((picture, i) => {
                return (
                  <span>
                    {picture.candidateName}
                    {cardData?.honorRollCandidateDtos?.length > i + 1
                      ? ","
                      : ""}{" "}
                    &nbsp;
                  </span>

                  // picture.candidateName {instanceMessage.length > i + 1 ? "," : ""}
                );
              })}
            </p>
          </div>
          <div className="honor__card--image">
            <div className="honor__card--wrap">
              {/* <Carousel autoplay dots={false}>
                {[1, 2, 3].map((data) => (
                  <div className="honor__card--circle">
                    <img src={`https://picsum.photos/id/${data}/150/150`} />
                  </div>
                ))}
              </Carousel> */}
              <Carousel autoplay={true} dots={false}>
                {cardData.honorRollCandidateDtos?.map((picture) => {
                  let name = picture?.candidateName;
                  let profilePicNameFL = name?.match(/\b(\w)/g).join("");

                  return (
                    <>
                      <div className="honor__card--circle">
                        {!isEmpty(picture.candiateProfilePictureURL) ? (
                          <img
                            src={
                              cqBaseUrl +
                              "/" +
                              picture.candiateProfilePictureURL +
                              token
                            }
                          />
                        ) : (
                          <div
                            className="default-profile-name"
                            style={{ width: "100%", height: "100%" }}
                          >
                            {profilePicNameFL}
                          </div>
                        )}
                      </div>
                      <p className="author">{picture.candidateName}</p>
                    </>
                  );
                })}
                {/* {cardData.honorRollCandidateDtos?.map((picture) => (
                  <>
                    <div className="honor__card--circle">
                      <img
                        src={
                          cqBaseUrl +
                          "/" +
                          picture.candiateProfilePictureURL +
                          token
                        }
                      />
                    </div>
                  </>
                ))} */}
              </Carousel>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default HonorCard;
