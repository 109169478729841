import { PaperClipOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Steps,
  Table,
  Select
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { isEmpty } from "lodash-es";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { replyInstanceMessage } from "services/redux/email/instanceMessageCenter/action";
import { getLeaveApplicationGetDetailsWithHistoryAndRemaining } from "services/redux/requistions/leaveApplication/action";
import { fileDownload } from "utils/commonFunc";
import { cqBaseUrl } from "utils/config";
import { leaveApplicationApproveReject } from "../../../services/redux/requistions/leaveApplication/action";
import { getRequisitionApplicationStatusListing } from "../../../services/redux/requistions/leaveType/action";
import { getRemainingLeaveApplicationTypesForEmployee } from "services/redux/requistions/leaveApplication/action";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
import pdfViewer from "components/Documents/ViewDocument";
const { Step } = Steps;
const attachmentView = "assets/svg/attachment-view.svg";
const attachmentDownload = "assets/svg/attachment-download.svg";
const LogoImage = "assets/images/loader.gif";
const newWindow = "assets/svg/newWindow.svg";

const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const columns = [
  {
    title: "Leave Type",
    key: "leaveTypeName",
    render: (record) => record?.leaveApplicationTypeDto?.leaveTypeName,
  },
  {
    title: "Allowed Leaves",
    dataIndex: "allowedleave",
    key: "allowedleave",
  },
  {
    title: "Leaves Taken",
    dataIndex: "takenLeave",
    key: "takenLeave",
  },
  {
    title: "Leaves Remaining",
    dataIndex: "remainingLeave",
    key: "remainingLeave",
  },
];

const historyColumns = [
  {
    title: "Leave Type",
    key: "leaveTypeName",
    render: (record) =>
      record?.leaveApplicationDto?.leaveApplicationTypeDto?.leaveTypeName,
  },
  {
    title: "Leave Start",
    key: "leaveDayFrom",
    render: (record) => (
      <span>
        {moment(record?.leaveApplicationDto?.leaveDayFrom).format(
          "D MMM, YYYY"
        )}
      </span>
    ),
  },
  {
    title: "Leave End",
    key: "leaveDayTo",
    render: (record) => (
      <span>
        {moment(record?.leaveApplicationDto?.leaveDayTo).format("D MMM, YYYY")}
      </span>
    ),
  },
  {
    title: "Leave Days",
    key: "leaveDays",
    render: (record) => record?.leaveApplicationDto?.leaveDays,
  },
  {
    title: "Status",
    key: "status",
    render: (record) => record?.requisitionApplicationStatusDto?.displayName,
  },
];

const LeaveDetails = ({ leaveAppId }) => {
  const { token } = useSelector(
    //for accessing to the token form redux store
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const {
    leaveApplicationGetDetailsWithHistory: { history, remaining, details },
    leaveApplicationGetDetailsWithHistoryLoading,
  } = useSelector((state) => state.leaveApplication);

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  const { requisitionAppStatusListing } = useSelector(
    (state) => state.leaveType
  );

  const {
    remainingLeaveApplicationTypesListing,
    remainingLeaveApplicationTypesListingLoading,
    addLeaveApplicationResp,
} = useSelector((state) => state.leaveApplication);


  const [form] = useForm();
  const [statusListing, setStatusListing] = useState([]);
  const [leaveStatusName, setLeaveStatusName] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    dispatch(getRemainingLeaveApplicationTypesForEmployee());
  }, []);

  useEffect(() => {
      if(!isEmpty(details)) {
        form.setFieldsValue({
            leaveApplicationTypeId: details?.leaveApplicationTypeId,
        })
        }
  }, [details])


  useEffect(() => {
    dispatch(getLeaveApplicationGetDetailsWithHistoryAndRemaining(leaveAppId));
  }, [leaveAppId]);

  useEffect(() => {
    dispatch(getRequisitionApplicationStatusListing());
  }, []);

  useEffect(() => {
    if (!isEmpty(requisitionAppStatusListing)) {
      let newStatusListing = requisitionAppStatusListing.filter(
        (obj) => obj.systemName !== "Apply"
      );
      setStatusListing(newStatusListing);
    }
  }, [requisitionAppStatusListing]);

  const onFinish = (values) => {
    const status = statusListing.filter(
      (obj) => obj.systemName === leaveStatusName
    );

    let body = {
      ...values,
      leaveApplicationId: details.id,
      approveRejectStatusId: status[0].id,
    };
    let instanceBodyMessage = {
      internalChatId: selectedMailTypeAndMailData?.selectedMail?.id,
      mailMessageParticipantTableId: null,
      messageText: values.approveRejectRemarks,
      applicationId: selectedMailTypeAndMailData?.selectedMail?.applicationId,
      mailMessageParticipantWithTypeDtos: {
        participantTos: [
          { participantUserId: details.requestedUserDto.appUserId },
        ],
        participantCCs: [],
      },

      mailMessageCenterId: selectedMailTypeAndMailData?.selectedMail?.id,
      applicationTypeId:
        selectedMailTypeAndMailData?.selectedMail?.applicationType === "EMail"
          ? "4f24a8cc-d015-4b63-acfd-a6ca4dd6c5b3"
          : selectedMailTypeAndMailData?.selectedMail?.applicationTypeId,
      instanceMessageStatusName: "Normal",
      instanceMessageActionName: "reply",

      replyOfInstanceMessageId: null,
    };

    dispatch(leaveApplicationApproveReject(body, status.displayName));
    dispatch(replyInstanceMessage(instanceBodyMessage));
  };

  const leaveStatus =
    details && details?.leaveApplicationCurrentStatusDto?.statusSystemName;
  const handleView = (src) => {
    setTimeout(() => {
      // console.log("pdfViewer", pdfViewer);
      pdfViewer1(src);
    }, 1000);
  };

  return !isEmpty(details) ? (
    <div className="mail-leave-details">
      <div className="leave-progerss">
        {
          <Steps current={leaveStatus === "Apply" ? 1 : 3}>
            <Step title="Apply" />
            <Step title="Pending" />
            <Step
              title={leaveStatus === "Rejected" ? "Rejected" : "Approved"}
              status={leaveStatus === "Rejected" ? "error" : ""}
            />
          </Steps>
        }
      </div>
      <div className="leave-details">
        <Row gutter={20}>
          <Col lg={12} className="leave-col">
            <div className="leave-title">Leave Dates</div>
            <div className="leave-input">
              {moment(details?.leaveDayFrom).format("DD-MMM-YYYY")} {"->"}{" "}
              {moment(details?.leaveDayTo).format("DD-MMM-YYYY")}
            </div>
          </Col>
          <Col lg={6} className="leave-col">
            <div className="leave-title">Total Days</div>
            <div className="leave-input">{details.leaveDays}</div>
          </Col>
          <Col lg={6} className="leave-col">
            <div className="leave-title">Leave Type</div>
            <div className="leave-input">
              {details?.leaveApplicationTypeDto?.leaveTypeName}
            </div>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col lg={24} className="leave-col">
            <div className="leave-title">Reason</div>
            <div className="leave-input">{details?.reason}</div>
          </Col>

          <Col lg={24} className="leave-col">
            <div className="leave-title">Attachment</div>
            <div className="leave-input">
              <div className="office-attachment-wrapper">
                <span className="office-attachment-length">
                  <PaperClipOutlined />
                  &nbsp;{details?.leaveAttachmentDocumentDtos.length}
                </span>
                {details?.leaveAttachmentDocumentDtos?.length > 0 && (
                  <div className="office-attachment-dropdown">
                    {details?.leaveAttachmentDocumentDtos?.map((doc, index) => {
                      return (
                        doc.isActive && (
                          <div className="office-attachment-item">
                            <PaperClipOutlined />{" "}
                            <a
                              target="_blank"
                              download
                              href={cqBaseUrl + "/" + doc.viewFileURL + token}
                            >
                              <span className="office-attachment-overflow">
                                {doc.displayFileName.slice(
                                  0,
                                  doc.displayFileName.length - 5
                                )}
                              </span>
                              {doc.displayFileName.slice(
                                doc.displayFileName.length - 5,
                                doc.displayFileName.length
                              )}
                            </a>
                            <button
                              style={{
                                backgroundColor: "transparent",
                                color: "none",
                                border: "none",
                              }}
                              onClick={() => {
                                setModalVisible(true);
                                handleView(
                                  `${cqBaseUrl}/${doc?.viewFileURL}${token}`
                                );
                              }}
                            >
                              <img src={attachmentView} alt="view" />
                            </button>
                            {modalVisible && (
                              <Modal
                                visible={modalVisible}
                                title={
                                  <div
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "20fr 0.5fr 0.5fr ",
                                    }}
                                  >
                                    <span>Document Viewer</span>
                                    <span
                                      onClick={() => {
                                        pdfViewer(
                                          `${cqBaseUrl}/${doc?.viewFileURL}${token}`
                                        );
                                      }}
                                    >
                                      <img
                                        src={newWindow}
                                        height={15}
                                        style={{ marginBottom: "5px" }}
                                        alt="new"
                                      />
                                    </span>
                                    <span
                                      onClick={() => {
                                        setModalVisible(false);
                                      }}
                                    >
                                      <CloseOutlined />
                                    </span>
                                  </div>
                                }
                                footer={null}
                                closable={false}
                                onCancel={() => {
                                  setModalVisible(false);
                                }}
                                width="70%"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    width: "100%",
                                  }}
                                ></div>

                                {modalVisible && (
                                  <iframe
                                    id="pdfViewerFrame"
                                    width="100%"
                                    height="500px"
                                  />
                                )}
                              </Modal>
                            )}
                            <button
                              style={{
                                backgroundColor: "transparent",
                                color: "none",
                                border: "none",
                              }}
                              onClick={() => {
                                // fileDownload(
                                //   `${details?.leaveAttachmentDocumentDtos
                                //     .viewFileURL}`,
                                //   fileList?.displayFileName
                                // );
                                fileDownload(
                                  `${cqBaseUrl}/${doc?.viewFileURL}${token}`,
                                  doc.displayFileName
                                );
                              }}
                            >
                              <img src={attachmentDownload} alt="download " />
                            </button>
                          </div>
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {details.isSupervisor && leaveStatus === "Apply" ? (
          <Form name="basic" onFinish={onFinish} form={form} {...layout}>
            <Form.Item
              label="Remarks"
              name="approveRejectRemarks"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
                className="form__group"
                label="Choose Leave Type"
                name="leaveApplicationTypeId"
                // rules={[
                //     {
                //     required: true,
                //     },
                // ]}
                style={{
                    paddingTop: "10px"
                }}
            >
                <Select
                    placeholder="Please select"
                    autoComplete="off"
                >
                    {
                    !isEmpty(remainingLeaveApplicationTypesListing) &&
                    remainingLeaveApplicationTypesListing.map((leaveType, i) => (
                        <Option
                        value={leaveType.leaveApplicationTypeId}
                        title={leaveType?.leaveApplicationTypeDto?.leaveTypeName}
                        key={i}>
                        {leaveType?.leaveApplicationTypeDto?.leaveTypeName}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <div className="leave-details-footer" style={{ marginTop: 15 }}>
              <Button
                className="leave-reject"
                htmlType="submit"
                onClick={() => {
                  setLeaveStatusName("Rejected");
                }}
              >
                Reject
              </Button>
              <Button
                className="leave-accept"
                htmlType="submit"
                onClick={() => {
                  setLeaveStatusName("Approved");
                }}
              >
                Accept
              </Button>
            </div>
          </Form>
        ) : (
          <Col lg={24} className="leave-col">
            <div className="leave-title">Remarks({details?.remarksBy})</div>
            <div className="leave-input">{details?.approveRejectRemarks}</div>
          </Col>
        )}
      </div>
      {!isEmpty(history) && (
        <div className="leave-types">
          <b>Summary</b>
          <Table
            className="organizer__tables remove-counter"
            columns={historyColumns}
            rowKey="id"
            dataSource={history}
            pagination={false}
            loading={{
              indicator: <img src={LogoImage} height="auto" width="50px" />,
              spinning: leaveApplicationGetDetailsWithHistoryLoading,
            }}
          />
        </div>
      )}
      <div className="leave-types">
        <div>
          <b>Details</b>
        </div>
        <Table
          className="organizer__tables remove-counter"
          columns={columns}
          rowKey="id"
          dataSource={remaining}
          pagination={false}
          loading={{
            indicator: <img src={LogoImage} height="auto" width="50px" />,
            spinning: leaveApplicationGetDetailsWithHistoryLoading,
          }}
        />
      </div>
    </div>
  ) : (
    <Skeleton active />
  );
};

export default LeaveDetails;
