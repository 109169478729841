import { Button, DatePicker, Drawer, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersListing } from "services/redux/admin/FiscalYear/action";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { isEmpty } from "lodash";
import {
  emailShareGetDetails,
  sharedEmailParticipation,
} from "services/redux/admin/EmailShare/action";

const ProfileDrawer = ({
  openDrawer,
  setOpenNewDrawer,
  profileInfo,
  userId,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;

  const [startDate, setStartDate] = useState("");

  const { getAllUsersListResponse } = useSelector(
    (state) => state.fiscalYearState
  );

  const {
    emailShareGetDetailsResponse,
    emailShareResponse,
    emailShareResponseLoading,
  } = useSelector((state) => state.emailShare);

  useEffect(() => {
    if (!isEmpty(userId)) {
      dispatch(emailShareGetDetails(userId, ""));
    }
  }, [profileInfo, userId]);

  useEffect(() => {
    if (!isEmpty(emailShareGetDetailsResponse)) {
      console.log("emailShareGetDetailsResponse", emailShareGetDetailsResponse);

      form.setFieldsValue({
        myList: emailShareGetDetailsResponse?.employeeEmailShareAccessDtos?.map(
          (item, index) => {
            return {
              participantTos: item?.employeeId,
              maxLimitCount: item?.maxLimitCount,
              expiryDateTime: moment(item?.expiryDateTime),
            };
          }
        ),
      });
    }
  }, [emailShareGetDetailsResponse]);

  useEffect(() => {
    dispatch(getAllUsersListing());
  }, []);

  const onSubmit = (values) => {
    if (!isEmpty(profileInfo)) {
      let list1 = values?.myList?.map((data) => {
        return {
          employeeId: data?.participantTos,
          isActive: true,
          maxLimitCount: data?.maxLimitCount,
          expiryDateTime: data?.expiryDateTime?._d,
        };
      });
      console.log("list1 here", list1);
      dispatch(sharedEmailParticipation(list1, "", userId));
    }
  };
  return (
    <div>
      <Drawer
        visible={openDrawer}
        width={472}
        maskClosable={false}
        closable={false}
        className="drawer main-drawer-wrapper form_email_share"
      >
        <Form
          onFinish={onSubmit}
          form={form}
          name="control-hooks"
          className="add__user__form "
        >
          {!isEmpty(profileInfo) && (
            <div>
              <div>Give Permission Tos</div>

              <Form.List name="myList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <>
                        <Form.Item
                          className="form__group"
                          // name="participantTos"
                          name={[field.name, "participantTos"]}
                          fieldKey={[field.fieldKey, "participantTos"]}
                          rules={[{ required: true, message: "" }]}
                          style={{ width: "100%" }}
                        >
                          <Select
                            optionFilterProp="label"
                            bordered={true}
                            autoFocus={true}
                            placeholder={`Internal To`}
                          >
                            {getAllUsersListResponse?.map((participant, j) => (
                              <Option
                                key={j}
                                value={participant?.employeeId}
                                label={[
                                  participant?.fullName,
                                  participant?.userName,
                                ]}
                              >
                                <div className="demo-option-label-item">
                                  <span role="img">
                                    {participant?.fullName} {"" + ""}
                                  </span>
                                  [{participant?.userName.toUpperCase()}]
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            label={`Enter limit count:`}
                            {...field}
                            name={[field.name, "maxLimitCount"]}
                            fieldKey={[field.fieldKey, "maxLimitCount"]}
                            rules={[
                              {
                                required: true,
                                message: "Input field is required",
                              },
                            ]}
                          >
                            <Input type="number" min={0} />
                          </Form.Item>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            label={`Enter expiry limit:`}
                            {...field}
                            name={[field.name, "expiryDateTime"]}
                            fieldKey={[field.fieldKey, "expiryDateTime"]}
                            rules={[
                              {
                                required: true,
                                message: "Input field is required",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder=""
                              disabledDate={(current) => {
                                return (
                                  current &&
                                  current <
                                    moment().add(1, "days").startOf("day")
                                );
                              }}
                              style={{ width: 178 }}
                              onChange={(value) => {
                                setStartDate(
                                  value
                                    ? moment(value?._d).format("DD-MMM-YYYY")
                                    : ""
                                );
                              }}
                            />
                          </Form.Item>
                          <div className="remove-field-wrapper">
                            <MinusCircleOutlined
                              style={{
                                width: 45,
                                marginTop: 3,
                              }}
                              className="contact__btn-danger"
                              onClick={() => remove(field.name)}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                    <Form.Item>
                      <Button
                        style={{
                          width: 45,
                          marginRight: 5,
                        }}
                        type="dashed"
                        className="contact__buttons"
                        onClick={() => add()}
                      >
                        <PlusCircleOutlined
                          style={{
                            color: "green",
                          }}
                        />
                        Add User
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          )}

          <div className="model-footer">
            <Button
              onClick={() => {
                setOpenNewDrawer(false);
                // setNewPermissionData("");
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            {isEmpty(profileInfo) ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={emailShareResponseLoading}
              >
                Submit
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                loading={emailShareResponseLoading}
              >
                Update
              </Button>
            )}
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default ProfileDrawer;
