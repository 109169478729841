import { REFERENCE__TYPES } from "../../../types";
import { SecondaryService } from "./api";
import { init, success, finish, error } from "services/common";
import { message, notification } from "antd";

//referenceOthersRoot2paginationSecondary

const secondaryRootService = new SecondaryService();

export const getSecondaryRootBasicSearchPagination = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_BASIC_PAGINATION_SECONDARY));
    const response = await secondaryRootService.secondaryRootBasicPagination(
      query
    );
    dispatch(finish(REFERENCE__TYPES.TENDER_BASIC_PAGINATION_SECONDARY));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.TENDER_BASIC_PAGINATION_SECONDARY,
          response.data,
          loadMore
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const referenceSecondaryAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_SECONDARY_ADD));
    const response = await secondaryRootService.referenceSecondaryAdd(body);
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_SECONDARY_ADD, response.data)
      );
      notification.success({ message: "Successfully Added Referenced" });
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
      //   notification.error({ message: "Something went Wrong" });
    }
    dispatch(finish(REFERENCE__TYPES.REFERENCE_SECONDARY_ADD));
  };
};
