import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Input,
  Button,
  Image,
  Skeleton,
  message,
  Tooltip,
  Modal,
  Space,
} from "antd";
import {
  PlusOutlined,
  PaperClipOutlined,
  StarFilled,
  CloseOutlined,
  FullscreenOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { cqBaseUrl } from "utils/config";
import {
  contactLabelGroupListing,
  dynamicFileLabelTypeUploaderDetailDtos,
} from "services/remanentCalls";
import { isEmpty, clone, cloneDeep, groupBy } from "lodash";
import moment from "moment";
import { contactPersonFav } from "../../../services/redux/contact/companypeople/action";
import { Link } from "react-router-dom";
import pdfViewer from "components/Documents/ViewDocument";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
const newWindow = "../../../assets/svg/newWindow.svg";

export const Showdataformperson = ({
  showAddUpdatePersonDrawer,
  grantedPolices,
  setEditPerson,
}) => {
  const { contactPersonGetDetailsResponse } = useSelector(
    (state) => state.contactCompanyPerson
  );
  const [contactLabelGroup, setContactLabelGroup] = useState([]);
  const [uploadersExceptProfile, setUploadersExceptProfile] = useState([]);
  const [groupedByDocuments, setGroupedByDocuments] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [showViewer, setShowViewer] = useState(false);

  const { token } = useSelector(
    //for accessing to the token form redux store
    (state) => state.auth
  );
  const personAvatar = "assets/svg/person-avatar.svg";
  const dispatch = useDispatch();

  useEffect(() => {
    fetchContactLabelGroupListing();
    fetchDynamicFileLabelTypeUploaderDetailDtos();
  }, []);
  useEffect(() => {
    if (!isEmpty(contactPersonGetDetailsResponse)) {
      let documentsUploadedFiles = cloneDeep(
        contactPersonGetDetailsResponse?.uploadedFileDtos
      );

      let groupByDocuments = groupBy(
        documentsUploadedFiles,
        "fileLableTypeName"
      );

      setGroupedByDocuments(groupByDocuments);
    }
  }, [contactPersonGetDetailsResponse]);

  // console.log("persongetDetails", contactPersonGetDetailsResponse);

  const fetchDynamicFileLabelTypeUploaderDetailDtos = async () => {
    const resp = await dynamicFileLabelTypeUploaderDetailDtos("contact").catch(
      (err) => {
        message.error("Failure");
      }
    );

    if (resp && resp.status === 200) {
      let newUploadersExcept = resp.data.filter(
        (respData) => respData.name !== "Profile"
      );
      setUploadersExceptProfile(newUploadersExcept);
    }
  };

  const fetchContactLabelGroupListing = async (body) => {
    const resp = await contactLabelGroupListing().catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      setContactLabelGroup(resp.data);
    }
  };

  const handleView = (src) => {
    setShowViewer(true);
    setTimeout(() => {
      // console.log("pdfViewer", pdfViewer);
      pdfViewer1(src);
    }, 1000);
  };

  const renderdataorNA = (data) => data || ""; // a function displaying data if there is data, if not display NA
  if (!contactPersonGetDetailsResponse) {
    return (
      <Skeleton
        avatar={{ size: 96, shape: "circle" }}
        paragraph={{ rows: 10 }}
      />
    );
  }
  // console.log(contactPersonGetDetailsResponse);

  return (
    <div className="contactsDetails">
      <div className="contactsDetails__main">
        <div className="contactDetail header">
          <div className="person__image">
            {isEmpty(contactPersonGetDetailsResponse?.uploadedFileDtos) ? (
              <span>
                {" "}
                <Image src={personAvatar} />
              </span>
            ) : (
              contactPersonGetDetailsResponse?.uploadedFileDtos.map(
                //For accessing image
                (imagefile) => (
                  <>
                    {imagefile.fileLableTypeName === "Profile" && (
                      <Image
                        src={cqBaseUrl + "/" + imagefile.viewFileURL + token}
                      />
                    )}
                  </>
                )
              )
            )}
            {/* {contactPersonGetDetailsResponse?.uploadedFileDtos.map(
							//For accessing image
							(imagefile) => (
								<Image src={cqBaseUrl + '/' + imagefile.viewFileURL + token} />
							)
						)} */}
          </div>

          <div className="right-header">
            <div style={{ display: "flex" }}>
              <span
                style={{ marginLeft: -20, width: 20 }}
                onClick={() => {
                  dispatch(
                    contactPersonFav(
                      { contactPersonId: contactPersonGetDetailsResponse?.id },
                      contactPersonGetDetailsResponse
                    )
                  );
                }}
              >
                <StarFilled
                  style={{
                    color: `${
                      contactPersonGetDetailsResponse?.isFavorite
                        ? "#FBA525"
                        : "grey"
                    }`,
                  }}
                />
              </span>
              {!contactPersonGetDetailsResponse?.firstName ? (
                ""
              ) : (
                <>
                  <div className="contactsDetails__infoheader noToolTip">
                    {/* <span className="contactsDetails--title bold w-100">Full Name :</span> */}
                    <span className="contactsDetails--subtitle bold ">
                      {renderdataorNA(
                        contactPersonGetDetailsResponse?.firstName
                      )}{" "}
                      {renderdataorNA(
                        contactPersonGetDetailsResponse?.middleName
                      )}{" "}
                      {renderdataorNA(
                        contactPersonGetDetailsResponse?.lastName
                      )}
                    </span>
                  </div>
                  <div className="contactsDetails__infoheader tooltip">
                    <Tooltip
                      title={
                        contactPersonGetDetailsResponse?.firstName +
                        "" +
                        contactPersonGetDetailsResponse?.middleName +
                        "" +
                        contactPersonGetDetailsResponse?.lastName
                      }
                    >
                      <span className="contactsDetails--subtitle bold">
                        {renderdataorNA(
                          contactPersonGetDetailsResponse?.firstName
                        )}{" "}
                        {renderdataorNA(
                          contactPersonGetDetailsResponse?.middleName
                        )}{" "}
                        {renderdataorNA(
                          contactPersonGetDetailsResponse?.lastName
                        )}
                      </span>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>

            {!contactPersonGetDetailsResponse?.nickName ? (
              " "
            ) : (
              <div className="contactsDetails__infoheader">
                {/* <span className="contactsDetails--title bold w-100">Full Name :</span> */}
                <span className="contactsDetails--subtitle bold">
                  "{contactPersonGetDetailsResponse?.nickName}"
                </span>
              </div>
            )}
            {!contactPersonGetDetailsResponse?.designationName ? (
              ""
            ) : (
              <div className="contactsDetails__infoheader">
                {/* <span className="contactsDetails--title bold w-100"></span> */}
                <span className="contactsDetails--headersubtitle bold">
                  {renderdataorNA(
                    contactPersonGetDetailsResponse?.designationName
                  )}
                </span>
              </div>
            )}
            {!contactPersonGetDetailsResponse?.departmentName ? (
              ""
            ) : (
              <div className="contactsDetails__infoheader">
                {/* <span className="contactsDetails--title bold w-100"></span> */}
                <span className="contactsDetails--headersubtitle bold">
                  {renderdataorNA(
                    contactPersonGetDetailsResponse?.departmentName
                  )}
                </span>
              </div>
            )}
            {!contactPersonGetDetailsResponse?.companyName ? (
              ""
            ) : (
              <div className="contactsDetails__infoheader">
                {/* <span className="contactsDetails--title bold w-100"></span> */}
                <span className="contactsDetails--headersubtitle bold">
                  {renderdataorNA(contactPersonGetDetailsResponse?.companyName)}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="contactDetails" style={{ height: "68vh" }}>
          {contactLabelGroup.map((label, index) => {
            let dataPresent = false;
            contactPersonGetDetailsResponse?.contactPersonGroupLabelDtos?.map(
              (name) => {
                if (
                  name.contactLabelGroupName === label.name &&
                  name.contactLabelDataValue
                ) {
                  dataPresent = true;
                }
              }
            );
            return (
              label.name !== "Address" && (
                <div className="contactsDetails__infoMain" key={index}>
                  {dataPresent && (
                    <div className="contactsDetails__info mb-3">
                      <span className="contactsDetails--title bold w-100">
                        {label.name}
                      </span>
                    </div>
                  )}

                  {contactPersonGetDetailsResponse?.contactPersonGroupLabelDtos?.map(
                    (name) =>
                      name.contactLabelDataValue &&
                      name.contactLabelGroupName === label.name && (
                        <div className="contactsDetails__info">
                          <div className="contactDetails__label">
                            <span className="contactsDetails--title bold w-100">
                              {name.contactLabelName}
                            </span>
                          </div>

                          <div className="contactDetails__labeldata  noToolTip">
                            <span className="contactsDetails--subtitle bold">
                              {name.contactLabelDataValue}
                            </span>
                          </div>
                          <div className="contactDetails__labeldata  tooltip">
                            <Tooltip title={name.contactLabelDataValue}>
                              <span className="contactsDetails--title bold w-100">
                                {name.contactLabelDataValue}
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )
            );
          })}

          <div>
            {contactLabelGroup.map((label, index) => {
              let dataPresent = false;
              contactPersonGetDetailsResponse?.contactPersonGroupAddressDtos?.map(
                (name) => {
                  if (
                    name.contactLabelGroupName === label.name &&
                    (name.streetName ||
                      name.streetName ||
                      name.cityName ||
                      name.postalCodeZip ||
                      name.province ||
                      name.countryName)
                  ) {
                    dataPresent = true;
                  }
                }
              );

              return (
                label.name == "Address" && (
                  <div className="contactsDetails__infoMain" key={index}>
                    {dataPresent && (
                      <div className="contactsDetails__info mb-3">
                        <span className="contactsDetails--title bold w-100">
                          {label.name}
                        </span>
                      </div>
                    )}

                    {contactPersonGetDetailsResponse?.contactPersonGroupAddressDtos?.map(
                      (name) =>
                        (name.streetName ||
                          name.streetName ||
                          name.cityName ||
                          name.postalCodeZip ||
                          name.province ||
                          name.countryName) &&
                        name.contactLabelGroupName === label.name && (
                          <div
                            className="contactsDetails__subinfo"
                            style={{ height: "auto" }}
                          >
                            <div style={{ width: "100%", display: "flex" }}>
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title sub w-100">
                                  {name.contactLabelName}{" "}
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle">
                                  <div>
                                    {!name.streetName ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.streetName}
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    {!name.cityName ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.cityName.charAt(0).toUpperCase() +
                                          name.cityName.slice(1)}
                                        ,
                                      </span>
                                    )}
                                    {!name.postalCodeZip ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.postalCodeZip},
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    {!name.stateProvince ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.stateProvince},
                                      </span>
                                    )}
                                    {!name.countryName ? (
                                      ""
                                    ) : (
                                      <span style={{ paddingLeft: 2 }}>
                                        {name.countryName
                                          .charAt(0)
                                          .toUpperCase() +
                                          name.countryName.slice(1)}
                                      </span>
                                    )}
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                )
              );
            })}
          </div>

          {/* <div className="contactsDetails__infoMain">
					<div className="contactsDetails__info mb-3">
						<span className="contactsDetails--title bold w-100">Address :</span>
					</div>
					{contactPersonGetDetailsResponse?.contactPersonGroupAddressDtos.map((name) => (
						<>
							{!name.countryName ? (
								''
							) : (
								<div className="contactsDetails__subinfo">
									<div className="contactDetails__label">
										<span className="contactsDetails--title sub w-100">{name.contactLabelName} </span>
									</div>
									<div className="contactDetails__labeldata">
										<span className="contactsDetails--subtitle">
											{name.streetName} , {name.cityName} {name.postalCodeZip}, {name.stateProvince}, {name.country}
										</span>
									</div>
								</div>
							)}
						</>
					))}
				</div> */}
          {contactPersonGetDetailsResponse?.maritalStatusName ||
          contactPersonGetDetailsResponse?.genderName ||
          contactPersonGetDetailsResponse?.hobbies ||
          contactPersonGetDetailsResponse?.tagNames ||
          contactPersonGetDetailsResponse?.anniversaryDate ||
          contactPersonGetDetailsResponse?.contactSourceName ||
          contactPersonGetDetailsResponse?.dateOfBirth ? (
            <div>
              <div className="contactsDetails__info mb-3">
                <span className="contactsDetails--title bold w-100">Basic</span>
              </div>
              {!contactPersonGetDetailsResponse?.dateOfBirth ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Date of Birth{" "}
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {moment(
                        contactPersonGetDetailsResponse?.dateOfBirth
                      ).format("YYYY/MM/DD")}
                    </span>
                  </div>
                </div>
              )}
              {!contactPersonGetDetailsResponse?.maritalStatusName ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Marital Status{" "}
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {renderdataorNA(
                        contactPersonGetDetailsResponse?.maritalStatusName
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!contactPersonGetDetailsResponse?.anniversaryDate ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Anniversary Date{" "}
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {moment(
                        contactPersonGetDetailsResponse?.anniversaryDate
                      ).format("YYYY/MM/DD")}
                    </span>
                  </div>
                </div>
              )}

              {!contactPersonGetDetailsResponse?.genderName ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Gender{" "}
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {renderdataorNA(
                        contactPersonGetDetailsResponse?.genderName
                      )}
                    </span>
                  </div>
                </div>
              )}

              {!contactPersonGetDetailsResponse?.hobbies ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Hobbies{" "}
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {renderdataorNA(contactPersonGetDetailsResponse?.hobbies)}
                    </span>
                  </div>
                </div>
              )}

              {!contactPersonGetDetailsResponse?.tagNames ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Tags{" "}
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {renderdataorNA(
                        contactPersonGetDetailsResponse?.tagNames
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!contactPersonGetDetailsResponse?.contactSourceName ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Contact Source{" "}
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {renderdataorNA(
                        contactPersonGetDetailsResponse?.contactSourceName
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          {contactPersonGetDetailsResponse?.expertDtos ? (
            <div>
              <div className="contactsDetails__info mb-3">
                <span className="contactsDetails--title bold w-100">
                  Expert Details
                </span>
              </div>
              {!contactPersonGetDetailsResponse?.expertDtos?.majorSubject ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Major Subject
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {
                        contactPersonGetDetailsResponse?.expertDtos
                          ?.majorSubject
                      }
                    </span>
                  </div>
                </div>
              )}
              {!contactPersonGetDetailsResponse?.expertDtos?.majorSubject ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Years of Experience
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {
                        contactPersonGetDetailsResponse?.expertDtos
                          ?.yearOfExperience
                      }
                    </span>
                  </div>
                </div>
              )}
              {!contactPersonGetDetailsResponse?.expertDtos?.sectorId ? (
                ""
              ) : (
                <div className="contactsDetails__info">
                  <div className="contactDetails__label">
                    <span className="contactsDetails--title bold w-100">
                      Sector
                    </span>
                  </div>
                  <div className="contactDetails__labeldata">
                    <span className="contactsDetails--subtitle bold">
                      {contactPersonGetDetailsResponse?.expertDtos?.sectorName}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          {!isEmpty(
            contactPersonGetDetailsResponse?.expertDtos?.expertEducationDtos
          ) && (
            <div>
              <div className="contactsDetails__info mb-3">
                <span className="contactsDetails--title bold w-100">
                  Expert Education
                </span>
              </div>
              {contactPersonGetDetailsResponse?.expertDtos?.expertEducationDtos?.map(
                (data, index) => {
                  return (
                    <>
                      {data?.subject ? (
                        <div className="contactsDetails__info">
                          <div className="contactDetails__label">
                            <span className="contactsDetails--title bold w-100">
                              Major Subject
                            </span>
                          </div>
                          <div className="contactDetails__labeldata">
                            <span className="contactsDetails--subtitle bold">
                              {data?.majorSubject}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {data.qualificationId ? (
                        <div className="contactsDetails__info">
                          <div className="contactDetails__label">
                            <span className="contactsDetails--title bold w-100">
                              Qualification
                            </span>
                          </div>
                          <div className="contactDetails__labeldata">
                            <span className="contactsDetails--subtitle bold">
                              {data?.qualificationName}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                }
              )}
              {!isEmpty(
                contactPersonGetDetailsResponse?.expertDtos
                  ?.contactWorkExperienceDtos
              ) && (
                <div>
                  <div className="contactsDetails__info mb-3">
                    <span className="contactsDetails--title bold w-100">
                      Expert Work Experience
                    </span>
                  </div>
                  {contactPersonGetDetailsResponse?.expertDtos?.contactWorkExperienceDtos?.map(
                    (data, index) => {
                      return (
                        <>
                          {data?.position ? (
                            <div className="contactsDetails__info">
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title bold w-100">
                                  Position
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle bold">
                                  {data?.position}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {data.isWorking ? (
                            <div className="contactsDetails__info">
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title bold w-100">
                                  Currently Working
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle bold">
                                  {data?.isWorking ? "Yes" : "No"}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {data?.startingDate ? (
                            <div className="contactsDetails__info">
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title bold w-100">
                                  Starting Date
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle bold">
                                  {moment(data?.startingDate).format(
                                    "YYYY/MM/DD"
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {data?.endingDate ? (
                            <div className="contactsDetails__info">
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title bold w-100">
                                  Ending Date
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle bold">
                                  {moment(data?.endingDate).format(
                                    "YYYY/MM/DD"
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    }
                  )}
                </div>
              )}
              {!isEmpty(
                contactPersonGetDetailsResponse?.expertDtos?.expertSectorDtos
              ) && (
                <div>
                  <div className="contactsDetails__info mb-3">
                    <span className="contactsDetails--title bold w-100">
                      Expert Sector
                    </span>
                  </div>
                  {contactPersonGetDetailsResponse?.expertDtos?.expertSectorDtos?.map(
                    (data, index) => {
                      return (
                        <>
                          {!isEmpty(data?.expertProfession) ? (
                            <div className="contactsDetails__info">
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title bold w-100">
                                  Expert Position
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle bold">
                                  {data?.expertProfession}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {data?.sectorId ? (
                            <div className="contactsDetails__info">
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title bold w-100">
                                  Sector
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle bold">
                                  {data?.sectorName}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {data?.endingDate ? (
                            <div className="contactsDetails__info">
                              <div className="contactDetails__label">
                                <span className="contactsDetails--title bold w-100">
                                  Ending Date
                                </span>
                              </div>
                              <div className="contactDetails__labeldata">
                                <span className="contactsDetails--subtitle bold">
                                  {moment(data?.endingDate).format(
                                    "YYYY/MM/DD"
                                  )}
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          )}

          {!isEmpty(groupedByDocuments) &&
            uploadersExceptProfile.map((file) => {
              let fileName = file.name;

              return (
                <>
                  <div className="contactsDetails__info">
                    <div className="contactDetails__label">
                      <span className="contactsDetails--title bold w-100">
                        Document
                      </span>
                    </div>
                    <div className="contactDetails__labeldata">
                      <span className="contactsDetails--subtitle bold">
                        {fileName &&
                          groupedByDocuments.hasOwnProperty(fileName) &&
                          groupedByDocuments[`${fileName}`].map((doc) => {
                            return (
                              doc.isActive && (
                                <div style={{ display: "flex" }}>
                                  <button
                                    style={{
                                      background: "none",
                                      border: "none",
                                      color: "#67a1e4",
                                      cursor: "pointer",
                                    }}
                                    // download
                                    // href={
                                    //   cqBaseUrl + "/" + doc.viewFileURL + token
                                    // }
                                    onClick={() => {
                                      setModalVisible(true);
                                      handleView(
                                        `${cqBaseUrl}/${doc.viewFileURL}${token}`
                                      );
                                      // pdfViewer(
                                      //   `${cqBaseUrl}/${doc.viewFileURL}${token}`
                                      // );
                                    }}
                                  >
                                    {doc.displayFileName}
                                  </button>
                                  {modalVisible && (
                                    <Modal
                                      visible={modalVisible}
                                      title={
                                        <div
                                          style={{
                                            display: "grid",
                                            gridTemplateColumns:
                                              "20fr 0.5fr 0.5fr ",
                                          }}
                                        >
                                          <span>Document Viewer</span>
                                          <span
                                            onClick={() => {
                                              pdfViewer(
                                                `${cqBaseUrl}/${doc.viewFileURL}${token}`
                                              );
                                            }}
                                          >
                                            <img
                                              src={newWindow}
                                              height={15}
                                              style={{ marginBottom: "5px" }}
                                              alt="new"
                                            />
                                          </span>
                                          <span
                                            onClick={() => {
                                              setModalVisible(false);
                                            }}
                                          >
                                            <CloseOutlined />
                                          </span>
                                        </div>
                                      }
                                      footer={null}
                                      closable={false}
                                      onCancel={() => {
                                        setModalVisible(false);
                                        setShowViewer(false);
                                      }}
                                      width="70%"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                          width: "100%",
                                        }}
                                      ></div>

                                      {modalVisible && (
                                        <iframe
                                          id="pdfViewerFrame"
                                          width="100%"
                                          height="500px"
                                        />
                                      )}
                                    </Modal>
                                  )}
                                  <PaperClipOutlined />{" "}
                                </div>
                              )
                            );
                          })}
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
        </div>

        {/* {!contactPersonGetDetailsResponse?.notes ? (
					<div className="contactsDetails__infonotes">
						<div className="contactDetails__label">
							<span className="contactsDetails--title bold w-100">Notes</span>
						</div>
						<div className="contactDetails__labeldata">
							<span className="contactsDetails--subtitle bold"> No Notes......</span>
						</div>
					</div>
				) : (
					<div className="contactsDetails__infonotes">
						<div className="contactDetails__label">
							<span className="contactsDetails--title bold w-100">Notes </span>
						</div>
						<div className="contactDetails__labeldata">
							<span className="contactsDetails--subtitle bold" style={{ paddingLeft: 11 }}>
								{renderdataorNA(contactPersonGetDetailsResponse?.notes)}
							</span>
						</div>
					</div>
				)} */}
      </div>
      <Row gutter={[8, 0]} style={{ position: "absolute", bottom: 15 }}>
        <Col lg={8}>
          {/* <Link to={`/addUpdatePersonDrawer?id=${contactPersonGetDetailsResponse?.id}`} target="_blank"> */}
          <div>
            <div
              className="company-person-edit-btn"
              onClick={() => {
                setEditPerson("edit");
              }}
              // to={`/addUpdatePersonDrawer?id=${contactPersonGetDetailsResponse?.id}`}
              // target="_blank"
              // rel="opener"
            >
              Edit
            </div>
            {/* <Button
								type="primary"
								
							>
								
								Edit
							</Button> */}
          </div>

          {/* </Link> */}
        </Col>
      </Row>
    </div>
  );
};
