import { GLOBAL, PROCUREMENT_TYPES } from "services/types";

const INITIAL_STATE = {
  procurementPagination: [],
  procurementRespLoading: false,
  addProcurementResp: [],
  addProcurementRespLoading: false,
  procurementDetails: [],
  procurementDetailsLoading: false,
  procurementUpdateSupervisor: [],
  procurementUpdateSupervisorLoading: false,
};
export default function procurementReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROCUREMENT_TYPES.PROCUREMENT_GET_INIT:
      return {
        ...state,
        procurementPagination: [],
        procurementRespLoading: true,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_GET_SUCCESS:
      return {
        ...state,
        procurementPagination: action.payload,
        procurementRespLoading: false,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_GET_FINISH:
      return {
        ...state,
        procurementRespLoading: false,
      };
    case PROCUREMENT_TYPES.PROCUREMENT_APPROVAL_GET_INIT:
      return {
        ...state,
        procurementApprovalPagination: [],
        procurementApprovalRespLoading: true,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_APPROVAL_GET_SUCCESS:
      return {
        ...state,
        procurementApprovalPagination: action.payload,
        procurementApprovalRespLoading: false,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_APPROVAL_GET_FINISH:
      return {
        ...state,
        procurementApprovalRespLoading: false,
      };
    case PROCUREMENT_TYPES.PROCUREMENT_ADD_INIT:
      return {
        ...state,
        addProcurementResp: [],
        addProcurementRespLoading: true,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_ADD_SUCCESS:
      return {
        ...state,
        addProcurementResp: action.payload,
        addProcurementRespLoading: false,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_GET_DETAILS_INIT:
      return {
        ...state,
        procurementDetails: [],
        procurementDetailsLoading: true,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_GET_DETAILS_SUCCESS:
      return {
        ...state,
        procurementDetails: action.payload,
        procurementDetailsLoading: false,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_UPDATE_SUPERVISOR_INIT:
      return {
        ...state,
        procurementUpdateSupervisor: [],
        procurementUpdateSupervisorLoading: true,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_UPDATE_SUPERVISOR_SUCCESS:
      return {
        ...state,
        procurementUpdateSupervisor: action.payload,
        procurementUpdateSupervisorLoading: false,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_UPDATE_ACCOUNTANT_INIT:
      return {
        ...state,
        procurementUpdateAccountant: [],
        procurementUpdateAccountantLoading: true,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_UPDATE_ACCOUNTANT_SUCCESS:
      return {
        ...state,
        procurementUpdateAccountant: action.payload,
        procurementUpdateAccountantLoading: false,
      };
    case PROCUREMENT_TYPES.PROCUREMENT_UPDATE_MANAGEMENT_INIT:
      return {
        ...state,
        procurementUpdateManagement: [],
        procurementUpdateManagementLoading: true,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_UPDATE_MANAGEMENT_SUCCESS:
      return {
        ...state,
        procurementUpdateManagement: action.payload,
        procurementUpdateManagementLoading: false,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_SUMMARY_MANAGEMENT_INIT:
      return {
        ...state,
        procurementSummaryManagementResponse: [],
        procurementSummaryManagementLoading: true,
      };

    case PROCUREMENT_TYPES.PROCUREMENT_SUMMARY_MANAGEMENT_SUCCESS:
      return {
        ...state,
        procurementSummaryManagementResponse: action.payload,
        procurementSummaryManagementLoading: false,
      };
    case PROCUREMENT_TYPES.PROCUREMENT_SUMMARY_MANAGEMENT_FINISH:
      return {
        ...state,
        procurementSummaryManagementLoading: false,
      };
    case PROCUREMENT_TYPES.PROCUREMENT_COUNTER_INIT:
      return {
        ...state,
        procurementCounterResponse: [],
        procurementCounterLoading: true,
      };
    case PROCUREMENT_TYPES.PROCUREMENT_COUNTER_SUCCESS:
      return {
        ...state,
        procurementCounterResponse: action.payload,
        procurementCounterLoading: false,
      };
    case PROCUREMENT_TYPES.SHOW_SIMILAR_PROCUREMENT_INIT:
      return {
        ...state,
        procurementSimilarResponse: [],
        procurementSimilarResponseLoading: true,
      };
    case PROCUREMENT_TYPES.SHOW_SIMILAR_PROCUREMENT_SUCCESS:
      return {
        ...state,
        procurementSimilarResponse: action.payload,
        procurementSimilarResponseLoading: false,
      };
    case PROCUREMENT_TYPES.GET_PROCUMENT_LATEST_INIT:
      return {
        ...state,
        procurementDashResponse: [],
        procurementDashResponseLoading: true,
      };
    case PROCUREMENT_TYPES.GET_PROCUMENT_LATEST_SUCCESS:
      return {
        ...state,
        procurementDashResponse: action.payload,
        procurementDashResponseLoading: false,
      };

    case PROCUREMENT_TYPES.GET_ALL_SIMILAR_PROCUREMENT_INIT:
      return {
        ...state,
        allSimilarProcurementResponse: [],
        allSimilarProcurementLoading: true,
      };
    case PROCUREMENT_TYPES.GET_ALL_SIMILAR_PROCUREMENT_SUCCESS:
      return {
        ...state,
        allSimilarProcurementResponse: action.payload,
        allSimilarProcurementLoading: false,
      };
    case PROCUREMENT_TYPES.GET_ALL_SIMILAR_PROCUREMENT_FINISH:
      return {
        ...state,
        allSimilarProcurementLoading: false,
      };
    case GLOBAL.GLOBAL_ERROR:
      return {
        ...state,
        procurementUpdateAccountantLoading: false,
        procurementUpdateSupervisorLoading: false,
        procurementUpdateManagementLoading: false,
        addProcurementRespLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
