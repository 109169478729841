import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from "antd";
import { validateMessages } from "utils/misc";
import {
  countryAdd,
  countryUpdate,
} from "services/redux/contact/contact.action";

const { Header, Footer } = Layout;

const AddUserDrawer = ({
  onAddUpdateCountryClose,
  drawerVisibility,
  isUpdateCountry,
}) => {
  const { countryAddResponse, countryUpdateResponse } = useSelector((state) => state.contact);

  const [form] = Form.useForm();

  form.resetFields();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdateCountry) return;
    form.setFieldsValue({
      ...isUpdateCountry,
    });
  }, [isUpdateCountry]);

  useEffect(() => {
    onAddUpdateCountryClose();
  }, [countryAddResponse, countryUpdateResponse]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (isUpdateCountry) {
        dispatch(countryUpdate(isUpdateCountry.id, values));
      } else {
        dispatch(countryAdd(values));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onAddUpdateCountryClose}
      closable={false}
    >
      <Header className='drawer__header'>
        <h4 className='drawer__header--title'>
          {`${isUpdateCountry ? "Update" : "Create"} Country Name`}
        </h4>
      </Header>
      <Form
        form={form}
        name='control-hooks'
        validateMessages={validateMessages}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              name='name'
              className="form__group"
              label='Country Name'
              rules={[{ required: true }]}>
              <Input autoComplete="off" />
            </Form.Item>
          </div>

        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateCountryClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default AddUserDrawer;
