import React, { useState, useEffect } from "react";
import moment from "moment";
import { Layout, Button, Drawer, Steps, Typography, Collapse } from "antd";

import ApplicantForm from "./ApplicationPhase/ApplicantForm";

const { Header } = Layout;

export const AddDrawer = ({ addDrawerVisibility, onAddDrawerClose }) => {
  return (
    <Drawer
      width={600}
      maskClosable={false}
      onClose={onAddDrawerClose}
      visible={addDrawerVisibility}
      closable={false}
      footer={
        <Layout.Content
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onAddDrawerClose}>Cancel</Button>
        </Layout.Content>
      }
    >
      <Header className="drawer__header">
        <Typography className="drawer__header--title">TA/DA Details</Typography>
      </Header>
      <Layout.Content style={{ padding: 10 }}>
        <ApplicantForm onAddDrawerClose={onAddDrawerClose} />
      </Layout.Content>
    </Drawer>
  );
};
