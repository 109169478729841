import { appBaseUrl } from "utils/config";
import { getService, postService, putService } from "../../commonServices";
import queryString from "query-string";

export class CreateUpdateGroup {
  apiForCreateGroup(body) {
    let url = `${appBaseUrl}/organizerEvent/preDefineUserListAdd`;
    let data = postService(url, body);
    return data;
  }

  apiForGroupListing(q) {
    let query = q;
    let url = `${appBaseUrl}/organizerEvent/PreDefineUserGroupPagination?${query}`;
    let data = getService(url, q);
    return data;
  }

  apiForUpdateGroup(query, groupId) {
    let url = `${appBaseUrl}/organizerEvent/PreDefineUserListUpdate/${groupId}`;
    let data = putService(url, query);
    return data;
  }
}
