import { produce } from "immer";
import { DOWNLOAD_FILE } from "services/types";

const initialState = {
  exportSampleDailyDiary: null,
  exportSampleDailyDiaryLoading: false,
};

const downloadFileReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case DOWNLOAD_FILE.SAMPLE_DAILY_DIARY_INIT:
        draft.exportSampleDailyDiaryLoading = true;
        break;

      case DOWNLOAD_FILE.SAMPLE_DAILY_DIARY_SUCCESS:
        draft.exportSampleDailyDiary = action.payload;
        draft.exportSampleDailyDiaryLoading = false;
        break;

      case DOWNLOAD_FILE.SAMPLE_DAILY_DIARY_FINISH:
        draft.exportSampleDailyDiaryLoading = false;
        break;

      default:
        return state;
    }
  });
};

export default downloadFileReducer;
