import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash-es";

// antd
import { Table, Space } from "antd";

// utils
import { LogoImage } from "utils/imageConstants";

const RoleListsTable = ({
  allRoleLists,
  roleListsLoading,
  editRoleDetails,
  editPermissions,
  grantedPolices,
}) => {
  const columns = [
    {
      title: "S.NO",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Code",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Name",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Default",
      key: "isDefault",
      render: (text) => <span>{text ? "true" : "false"}</span>,
    },
    {
      title: "Public",
      key: "isPublic",
      render: (text) => <span>{text ? "true" : "false"}</span>,
    },
    {
      title: "Static",
      key: "isStatic",
      render: (text) => <span>{text ? "true" : "false"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          {!isEmpty(grantedPolices) &&
            grantedPolices["AbpIdentity.Roles.Update"] && (
              <div className="line-spacing-role">
                <a
                  className="action-btn action-btn-add"
                  onClick={() => editRoleDetails(record)}
                >
                  Edit Role
                </a>
              </div>
            )}
          {!isEmpty(grantedPolices) &&
            grantedPolices["AbpIdentity.Roles.ManagePermissions"] && (
              <a
                className="action-btn action-btn-add"
                onClick={(e) => editPermissions(record)}
              >
                Edit Permissions
              </a>
            )}
        </Space>
      ),
    },
  ];

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    // setData(allRoleLists.items);
    if (!isEmpty(allRoleLists)) {
      setData(allRoleLists.items);
    }
  }, [allRoleLists]);

  // useEffect(() => {
  //   if (!employeesPaginationResponse) return;
  //   setdata(employeesPaginationResponse.items);
  //   setTotalCount(employeesPaginationResponse.totalCount);
  // }, [employeesPaginationResponse]);

  // const handleTableChange = (pagination, filters, sorter, extra) => {
  //   let Sorting = sorter.columnKey
  //   let SortType = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : undefined;
  //   let MaxResultCount = pageSize;
  //   let SkipCount = (pagination.current - 1) * pagination.pageSize;

  //   getData(MaxResultCount, SkipCount, Sorting, SortType);
  //   // getData(pagination.current, pagination.pageSize);
  // };

  return (
    <Table
      className="organizer__tables"
      columns={columns}
      rowKey="appUserId"
      dataSource={data}
      // onChange={handleTableChange}
      loading={{
        indicator: <img src={LogoImage} height="auto" width="50px" />,
        spinning: roleListsLoading,
      }}
      // pagination={{
      //   total: totalCount,
      //   pageSize: pageSize,
      // }}
    />
  );
};

export default RoleListsTable;
