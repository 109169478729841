import { Drawer, Layout, message } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import AppointmentForm from "../appointment/AppointmentForm";
import GoalsForm from "../goals/GoalsForm";
import ToDoForm from "../todoList/ToDoForm";

import { getOrganizerEventLabelListings } from "services/redux/organizer/labelListing/action";
import { getOrganizerEventPriorityListings } from "services/redux/organizer/priorityLisiting/action";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";
import { getAllUsers } from "services/remanentCalls";

const CreateEventDrawer = ({
  visibleEventFormDrawer,
  eventFormSelect,
  onCloseEventFormDrawer,
  todoDetails,
  dateClickDetails,
  getData,
  taskAssignedTo,
  isPrivate,
  largeWidth,
  setLargeWidth,
  visitor,
  setVisitor,
  form,
  newTask,
  setModalVisible,
  isDashboard,
}) => {
  const dispatch = useDispatch();
  const [eventTaskDetails, setEventTaskDetails] = useState([]);
  const labelEventsState = useSelector((state) => state.labelEvents);
  const priorityEventsState = useSelector((state) => state.priorityEvents);
  const statusListingState = useSelector((state) => state.statusListing);

  const {
    organizerTaskEventAddResp,
    organizerTaskEventAddRespLoading,
    organizerTaskEventUpdateResp,
    organizerTaskEventUpdateRespLoading,
    taskEventGetDetailsResp,
  } = useSelector((state) => state.goalEvents);

  useEffect(() => {
    if (!isEmpty(taskEventGetDetailsResp) && !newTask) {
      setEventTaskDetails(taskEventGetDetailsResp);
    } else if (newTask) {
      setEventTaskDetails([]);
    }
  }, [taskEventGetDetailsResp]);

  // useEffect(() => {
  //   if (
  //     !isEmpty(organizerTaskEventAddResp) ||
  //     !isEmpty(organizerTaskEventUpdateResp)
  //   ) {
  //     getData && getData();
  //   }
  // }, [organizerTaskEventAddResp, organizerTaskEventUpdateResp]);

  const [meetingInvitees, setMeetingInvitees] = useState([]);

  const { organizerEventLabelListing } = labelEventsState;
  const { organizerEventPriorityListing } = priorityEventsState;
  const { allEventStatusListing } = statusListingState;
  const { Header } = Layout;

  const fetchAllInvitees = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setMeetingInvitees(resp.data);
    }
  };
  const closeDrawer = () => {
    onCloseEventFormDrawer();
    setEventTaskDetails("");
  };

  useEffect(() => {
    if (isEmpty(organizerEventLabelListing)) {
      dispatch(getOrganizerEventLabelListings());
    }

    if (isEmpty(organizerEventPriorityListing)) {
      dispatch(getOrganizerEventPriorityListings());
    }

    if (isEmpty(allEventStatusListing)) {
      dispatch(getStatusEventListing());
    }

    if (isEmpty(meetingInvitees)) {
      fetchAllInvitees();
    }
  }, []);

  return (
    <Drawer
      placement="right"
      width={
        eventFormSelect === "task" && !isPrivate && largeWidth ? 1000 : 500
      }
      mask={true}
      maskClosable={false}
      keyboard={false}
      onClose={onCloseEventFormDrawer}
      visible={visibleEventFormDrawer}
      className="drawer right-side-popup"
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">
          {eventFormSelect.charAt(0).toUpperCase() + eventFormSelect.slice(1)}
        </h4>
      </Header>

      {eventFormSelect === "event" ? (
        <GoalsForm
          organizerEventLabelListing={organizerEventLabelListing}
          organizerEventPriorityListing={organizerEventPriorityListing}
          editGoalList={(e) => {}}
          onCloseEventFormDrawer={closeDrawer}
          dateClickDetails={dateClickDetails}
          eventTaskDetails={eventTaskDetails}
          setEventTaskDetails={setEventTaskDetails}
          setModalVisible={setModalVisible}
        />
      ) : eventFormSelect === "task" ? (
        <ToDoForm
          isPrivate={isPrivate}
          organizerEventLabelListing={organizerEventLabelListing}
          organizerEventPriorityListing={organizerEventPriorityListing}
          meetingInvitees={meetingInvitees}
          allEventStatusListing={allEventStatusListing}
          todoEventDrawer={true}
          todoDetails={todoDetails}
          onCloseEventFormDrawer={onCloseEventFormDrawer}
          taskAssignedTo={taskAssignedTo}
          largeWidth={largeWidth}
          setLargeWidth={setLargeWidth}
          visitor={visitor}
          setVisitor={setVisitor}
          form={form}
          isDashboard={isDashboard}
        />
      ) : (
        <AppointmentForm
          organizerEventLabelListing={organizerEventLabelListing}
          organizerEventPriorityListing={organizerEventPriorityListing}
          meetingInvitees={meetingInvitees}
          appointmentDrawer={true}
        />
      )}
    </Drawer>
  );
};

export default CreateEventDrawer;
