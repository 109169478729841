import { useEffect, useState } from "react";
import BudgetModal1 from "./Modal/BudgetModal";
import IncomeModal from "./Modal/IncomeModal";
import BudgetFrontCEMAT from "./Financial/BudgetViewsCEMAT/BudgetFront";
import getColumnsCEMAT from "./Financial/BudgetViewsCEMAT/Column";

const BankStatusCEMAT = ({ dashboardBudgetIncomeExpenseResponse }) => {
  const [widthh, setWidthh] = useState(window.innerWidth);
  const [rotate, setRotate] = useState(0);
  const [modalVisible, setModalVisible] = useState({
    income: false,
    expense: false,
    budget: false,
  });

  const [modalData, setModalData] = useState({
    dTypeIncome: 0,
    query: "",
  });

  useEffect(() => {
    const handleResize = () => setWidthh(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const modalShow = (query, type) => {
    let dType = 0;
    let modalVisibleState = {
      income: false,
      expense: false,
      budget: false,
    };

    if (
      type === "fee income" ||
      type === "other income" ||
      type === "total income"
    ) {
      modalVisibleState.income = true;
      if (type === "fee income") {
        dType = 1;
      } else if (type === "other income") {
        dType = 2;
      }
    } else if (type === "expense") {
      modalVisibleState.expense = true;
    } else if (type === "budget") {
      modalVisibleState.budget = true;
    }

    setModalVisible(modalVisibleState);
    setModalData({ query, dTypeIncome: dType });
  };

  const columns = getColumnsCEMAT({
    widthh,
    modalShow,
  });

  const totalIncome =
    dashboardBudgetIncomeExpenseResponse?.cematIncomeExpenseIncluded?.reduce(
      (total, item) => total + item.income,
      0
    );

  const totalIncomeOther =
    dashboardBudgetIncomeExpenseResponse?.cematIncomeExpenseIncluded?.reduce(
      (total, item) => total + item.incomeOther,
      0
    );
  const totalExpense =
    dashboardBudgetIncomeExpenseResponse?.cematIncomeExpenseIncluded?.reduce(
      (total, item) => total + item.expense,
      0
    );
  const totalBudget =
    dashboardBudgetIncomeExpenseResponse?.cematIncomeExpenseIncluded?.reduce(
      (total, item) => total + item.expense,
      0
    );

  const totalRow = {
    key: "total",
    companyName: "Total",
    income: totalIncome,
    incomeOther: totalIncomeOther,
    expense: totalExpense,
    budget: totalBudget,
  };

  const dataSource = [
    ...(dashboardBudgetIncomeExpenseResponse?.cematIncomeExpenseIncluded || []),
    totalRow,
  ];

  return (
    <div className="flip-card">
      <div
        className="flip-card-inner"
        style={{ transform: `rotateY(${rotate * 180}deg)` }}
      >
        <BudgetFrontCEMAT
          rotate={rotate}
          setRotate={setRotate}
          columns={columns}
          dataSource={dataSource}
        />
      </div>

      {(modalVisible.budget || modalVisible.expense) && (
        <BudgetModal1
          modalVisible={modalVisible.budget || modalVisible.expense}
          setModalVisible={setModalVisible}
          modalData={modalData}
          type="cemat"
        />
      )}

      {modalVisible?.income && (
        <IncomeModal
          modalVisible={modalVisible.income}
          setModalVisible={setModalVisible}
          modalData={modalData}
          type="cemat"
        />
      )}
    </div>
  );
};

export default BankStatusCEMAT;
