import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Table, Spin, Skeleton, message } from "antd";

const InfiniteScrollTable = ({
  fetchData,
  hasMore,
  columns,
  data,
  loading = false,
}) => {
  return (
    <div
      id="scrollable-table"
      style={{
        height: 200,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <InfiniteScroll
        dataLength={data?.length}
        next={fetchData}
        // next={() => {
        //   console.log("Scroll triggered");
        //   fetchData();
        // }}
        hasMore={hasMore}
        loader={<Skeleton active={4} />}
        // endMessage={<p style={{ textAlign: "center" }}>No more data</p>}
        // endMessage={<p style={{ textAlign: "center" }}></p>}
        scrollThreshold={0.6}
        scrollableTarget="scrollable-table"
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
          rowKey="id"
          // scroll={{ y: 200 }}
        />
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollTable;
