import { uniqBy } from "lodash";
import { REFERENCE__TYPES } from "../../../types";

const INITIAL_STATE = {
  projectPaginationResponse: null,
  projectPaginationLoading: false,
  projectDetailsLoading: false,
  projectGetDetailsResponse: null,
  projectAddResponse: null,
  projectLoadingResponse: false,

  //PROJECT Update
  projectUpdateResponse: null,
  projectUpdateLoading: false,

  projectIsFavResponse: null,
  projectIsFavResponseLoading: false,
};

export default function projectReducer(state = INITIAL_STATE, action) {
  //reference project pagination
  switch (action.type) {
    //PROJECT
    case REFERENCE__TYPES.PROJECT_PAGINATION_INIT:
      return {
        ...state,
        projectPaginationLoading: true,
      };

    case REFERENCE__TYPES.PROJECT_PAGINATION_SUCCESS:
      return {
        ...state,
        projectPaginationResponse: {
          items: action.loadMore
            ? uniqBy(
                state.projectPaginationResponse.items.concat(
                  action.payload.items
                ),
                "id"
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
        projectPaginationLoading: false,
      };

    case REFERENCE__TYPES.PROJECT_PAGINATION_FINISH:
      return {
        ...state,
        projectPaginationLoading: false,
      };

    case REFERENCE__TYPES.projectGetDetails_INIT:
      return {
        ...state,
        projectDetailsLoading: true,
        projectGetDetailsResponse: null,
      };

    case REFERENCE__TYPES.projectGetDetails_SUCCESS:
      return {
        ...state,
        projectGetDetailsResponse: action.payload,
        projectDetailsLoading: false,
      };

    case REFERENCE__TYPES.projectGetDetails_FINISH:
      return {
        ...state,
        projectGetDetailsResponse: null,
        projectDetailsLoading: false,
      };

    case REFERENCE__TYPES.PROJECT_ADD_INIT:
      return {
        ...state,
        projectLoadingResponse: true,
        projectAddResponse: null,
      };

    case REFERENCE__TYPES.PROJECT_ADD_SUCCESS:
      return {
        ...state,
        projectLoadingResponse: false,
        projectAddResponse: action.payload,
      };

    case REFERENCE__TYPES.PROJECT_ADD_FINISH:
      return {
        ...state,
        projectLoadingResponse: false,
        projectAddResponse: null,
      };

    //project Update
    case REFERENCE__TYPES.PROJECT_UPDATE_INIT:
      return {
        ...state,
        projectUpdateResponse: null,
        projectUpdateLoading: true,
      };
    case REFERENCE__TYPES.PROJECT_UPDATE_SUCCESS:
      return {
        ...state,
        projectUpdateResponse: action.payload,
        projectUpdateLoading: false,
      };
    case REFERENCE__TYPES.PROJECT_UPDATE_FINISH:
      return {
        ...state,
        projectUpdateLoading: false,
      };

    //REFERENCE
    case REFERENCE__TYPES.STATUS_PAGINATION_INIT:
      return {
        statusPaginationResponse: null,
        statusPaginationLoading: true,
      };
    case REFERENCE__TYPES.STATUS_PAGINATION_SUCCESS:
      return {
        ...state,
        statusPaginationResponse: action.payload,
        statusPaginationLoading: false,
      };
    case REFERENCE__TYPES.STATUS_PAGINATION_FINISH:
      return {
        statusPaginationResponse: null,
        statusPaginationLoading: false,
      };

    case REFERENCE__TYPES.STATUS_UPDATE_INIT:
      return {
        statusUpdateResponse: null,
        statusUpdateLoading: true,
      };
    case REFERENCE__TYPES.STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        statusUpdateResponse: action.payload,
        statusUpdateLoading: false,
      };
    case REFERENCE__TYPES.STATUS_UPDATE_FINISH:
      return {
        statusUpdateResponse: null,
        statusUpdateLoading: false,
      };

    case REFERENCE__TYPES.PROJECT_IS_FAV_INIT:
      return {
        ...state,
        projectIsFavResponse: null,
        projectIsFavResponseLoading: true,
      };
    case REFERENCE__TYPES.PROJECT_IS_FAV_SUCCESS:
      return {
        ...state,
        projectGetDetailsResponse: {
          ...action.record,
          isFavorite: !action.record?.isFavorite,
        },
        projectPaginationResponse: {
          items: state.projectPaginationResponse.items.map((items) => {
            if (items.id === action.payload.projectId) {
              return {
                ...items,
                isFavorite: !items.isFavorite,
              };
            } else {
              return {
                ...items,
              };
            }
          }),
          totalCount: state.projectPaginationResponse.totalCount,
        },
        projectIsFavResponseLoading: false,
      };
    case REFERENCE__TYPES.PROJECT_IS_FAV_FINISH:
      return {
        ...state,
        projectIsFavResponseLoading: false,
      };

    case REFERENCE__TYPES.NOTE_PROJECT_ADD_INIT:
      return {
        ...state,
        addProjectNoteResponse: [],
        addProjectNoteResponseLoading: true,
      };

    case REFERENCE__TYPES.NOTE_PROJECT_ADD_SUCCESS:
      return {
        ...state,
        addProjectNoteResponse: action.payload,
        projectGetDetailsResponse: action.payload,
        addProjectNoteResponseLoading: false,
      };

    case REFERENCE__TYPES.NOTE_PROJECT_ADD_FINISH:
      return {
        ...state,
        addProjectNoteResponseLoading: true,
      };
    //Reference
    case REFERENCE__TYPES.PROJECT_PAGINATION_DASHBOARD_INIT:
      return {
        ...state,
        dashboardProjectPaginationLoading: true,
      };

    case REFERENCE__TYPES.PROJECT_PAGINATION_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardProjectPaginationResponse: {
          items: action.loadMore
            ? uniqBy(
                state.projectPaginationResponse.items.concat(
                  action.payload.items
                ),
                "id"
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
        dashboardProjectPaginationLoading: false,
      };

    case REFERENCE__TYPES.PROJECT_PAGINATION_DASHBOARD_FINISH:
      return {
        ...state,
        dashboardProjectPaginationLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
