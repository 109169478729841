import { SOURCE_TYPES } from "services/types";
import { SourceService } from "./api";
import { init, success, finish, error } from "services/common";
import { message } from "antd";

const sourceService = new SourceService();

export const sourceAdd = (body) => {
    return async (dispatch) => {
      dispatch(init(SOURCE_TYPES.SOURCE_ADD));
      const response = await sourceService.sourceAdd(body);
      dispatch(finish(SOURCE_TYPES.SOURCE_ADD));
      if(response.isSuccess) {
        message.success("Source Added");
        dispatch(sourcePagination());
        dispatch(success(SOURCE_TYPES.SOURCE_ADD, response.data));
      }else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
      }
    };
  };

export const sourcePagination = () =>{
  return async (dispatch) => {
    dispatch(init(SOURCE_TYPES.SOURCE_PAGINATION));
    const response = await sourceService.getSourcePagination();
    dispatch(finish(SOURCE_TYPES.SOURCE_PAGINATION));
    if(response.isSuccess) {
      dispatch(success(SOURCE_TYPES.SOURCE_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

  