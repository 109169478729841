import React, { useState } from "react";

// antd
import { Button,DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// includes
import FiscalYearAddUpdateDrawer from "./FiscalYearAddUpdateDrawer";
import FiscalYearTable from "./FiscalYearTable";
import moment from 'moment';
const { RangePicker } = DatePicker;

const FiscalYear = () => {

  const [addUpdateFiscalYearVisibility, setAddUpdateFiscalYearVisibility] = useState(false);
  const [isUpdateFiscalYear, setIsUpdateFiscalYear] = useState(false);
  const [fiscalStart, setFiscalStart] = useState('');
  const [fiscalEnd, setFiscalEnd] = useState('');
  const showAddUpdateFiscalYear = (record) => {
    if (record) {
      setIsUpdateFiscalYear(record);
    } else {
      setIsUpdateFiscalYear(false);
    }
    setAddUpdateFiscalYearVisibility(true);
  };

  const onAddUpdateFiscalYearClose = () => {
    setIsUpdateFiscalYear('');
    setAddUpdateFiscalYearVisibility(false);
  };

  return (
<div className='layout'>
      <div className="organizer honorRoll">
      <div className="global-header-wrapper">
          <div className="header-info">
            <h3 className='header__all '>Fiscal Year</h3>
          </div>
          <div className="header-action-wrapper">
                {/* <RangePicker
                  onChange={(value) => {
                    setFiscalStart(value ? moment(value[0]).format('YYYY-MM-DD') : '');
                    setFiscalEnd(value ? moment(value[1]).format('YYYY-MM-DD') : '')
                  }}
                /> */}
                  <Button
                    className='btn-primary'
                    onClick={() => {
                      showAddUpdateFiscalYear();
                    }}
                    >
                    <PlusOutlined /> New Fiscal Year
                  </Button>
                </div>
              </div>
          
        <FiscalYearTable
          showAddUpdateFiscalYear={showAddUpdateFiscalYear}
          fiscalStart={fiscalStart}
          fiscalEnd={fiscalEnd}
          
        />
        <FiscalYearAddUpdateDrawer
          drawerVisibility={addUpdateFiscalYearVisibility}
          onAddUpdateFiscalYearClose={onAddUpdateFiscalYearClose}
          isUpdateFiscalYear={isUpdateFiscalYear}
        />
      
      </div>
    </div>
  );
};

export default FiscalYear;
