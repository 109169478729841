import React from "react";

const FinanceInfoHeader = ({ title }) => {
  return (
    <div className=" honor-wrapper" style={{ paddingRight: 0 }}>
      <div className="dashboards__body">
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">{title}</h4>
        </div>
      </div>
    </div>
  );
};

export default FinanceInfoHeader;
