import { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { Modal, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import AddNewQuickEmail from "../views/Email/Includes/AddNewQuickEmail";
import { Collapse } from "react-bootstrap";
import { appBaseUrl } from "./config";
import { getService } from "services/commonServices";
let instanceMessageLength, moreInstanceMessage;
export function usePreviousQuery(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function getParticipantsEmails(instanceMessage) {
  let participantNames = "";
  instanceMessage?.map((detail, i) => {
    participantNames = `${participantNames} ${
      detail?.participantUserDto?.userName || ""
    } ${instanceMessage?.length > i + 1 ? "," : ""}`;
  });
  return participantNames;
}

export function getParticipantsEmailDetails(instanceMessage) {
  instanceMessage.map((detail, i) => {
    return (
      <div className="test21">
        {detail.userName ? (
          <Tooltip title={detail.emailAddress}>{detail.username}</Tooltip>
        ) : (
          detail.emailAddress
        )}
      </div>
    );
  });
}

export const getParticipantsEmailsExternal = (
  instanceMessage,
  test,
  type,
  modalVisible,
  setModalVisible
) => {
  let completeEmailList = instanceMessage;
  let fixedLength = 2;
  let width = window.innerWidth;
  if (width > 1600) {
    fixedLength = 8;
  } else if (width > 1300) {
    fixedLength = 5;
  } else if (width > 900) {
    fixedLength = 4;
  } else if (width < 480) {
    fixedLength = 1;
  }
  instanceMessageLength = !isEmpty(instanceMessage)
    ? instanceMessage.length
    : 0;
  moreInstanceMessage =
    instanceMessageLength > fixedLength
      ? instanceMessage.slice(fixedLength, instanceMessageLength)
      : null;
  instanceMessage =
    instanceMessageLength > fixedLength
      ? instanceMessage.slice(0, fixedLength)
      : instanceMessage;
  return (
    <>
      <div
        className="email-info-header-wrapper"
        style={{ display: "flex", flexDirection: "row" }}
      >
        {instanceMessage?.map((detail, i) => {
          return (
            <div className="test21">
              {detail.fullName?.trim() || detail?.companyName?.trim() ? (
                <>
                  <div>
                    <div className="singel-test21-wrapper">
                      <Tooltip
                        placement="topLeft"
                        arrowPointAtCenter
                        title={`${detail.fullName} [${detail.emailAddress}]`}
                      >
                        {detail.personalAbbrevation ? (
                          <Tooltip>{detail.personalAbbrevation}</Tooltip>
                        ) : (
                          detail.fullName
                        )}
                      </Tooltip>

                      <span className="to-company-info">
                        {detail.companyAbbrevation ? (
                          <Tooltip
                            placement="topLeft"
                            arrowPointAtCenter
                            title={[
                              `${detail?.companyName}[${detail?.emailAddress}]`,
                            ]}
                          >
                            {detail.companyAbbrevation}
                          </Tooltip>
                        ) : (
                          <Tooltip
                            placement="topLeft"
                            title={`${detail?.companyName}[${detail?.emailAddress}]}`}
                          >
                            {detail.companyName && detail.companyName}
                          </Tooltip>
                        )}
                        {instanceMessage.length > i + 1 ? "," : ""}&nbsp;
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  {`${detail.emailAddress}`}{" "}
                  <div className="plusIcon">
                    <PlusCircleOutlined
                      onClick={() => {
                        test(detail.emailAddress, type);
                      }}
                    />
                  </div>
                  {instanceMessage.length > i + 1 ? "," : ""}
                </div>
              )}
            </div>
          );
        })}
        {!isEmpty(moreInstanceMessage) && !modalVisible && (
          <div className="email-header-drop-wrapper">
            <div className="drop-button">
              {instanceMessageLength - fixedLength} +
            </div>
            <div className="email-drop-down-list-wrapper">
              <div className="email-drop-down-list">
                {moreInstanceMessage?.map((detail, i) => {
                  return (
                    <div className="test21">
                      {detail.fullName?.trim() ? (
                        <>
                          <div>
                            <div className="singel-test21-wrapper">
                              <Tooltip
                                placement="top"
                                title={`${detail.fullName} [${detail.emailAddress}]`}
                              >
                                {detail.personalAbbrevation ? (
                                  <Tooltip placement="top">
                                    {detail.personalAbbrevation}
                                  </Tooltip>
                                ) : (
                                  detail.fullName
                                )}
                              </Tooltip>

                              <span className="to-company-info">
                                {detail.companyAbbrevation ? (
                                  <Tooltip
                                    placement="top"
                                    title={detail.companyName}
                                  >
                                    {detail.companyAbbrevation}
                                  </Tooltip>
                                ) : (
                                  detail.companyName && detail.companyName
                                )}
                              </span>
                              {instanceMessage.length > i + 1 ? "," : ""}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          {`${detail.emailAddress}`}{" "}
                          <div className="plusIcon">
                            <PlusCircleOutlined
                              onClick={() => {
                                test(detail.emailAddress, type);
                              }}
                            />
                          </div>
                          {instanceMessage.length > i + 1 ? "," : ""}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {/* {moreInstanceMessage ? (
          !modalVisible ? (
            <div className="view_all_detail_button">
              <span
                size="small"
                onClick={() => {
                  setModalVisible(!modalVisible);
                }}
              >
                {" "}
                more
              </span>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
<<<<<<< Updated upstream
        )} */}
      </div>
      {modalVisible && (
        <div style={{ marginTop: "3px" }}>
          <Collapse in={modalVisible}>
            <div className="collapse_email_list email-info-header-wrapper">
              <div className="example-collapse-email ">
                {moreInstanceMessage?.map((detail, i) => {
                  return (
                    <div className="test21">
                      {detail.fullName?.trim() ? (
                        <>
                          <div>
                            <div className="singel-test21-wrapper">
                              <Tooltip
                                placement="top"
                                title={`${detail.fullName} [${detail.emailAddress}]`}
                              >
                                {detail.personalAbbrevation ? (
                                  <Tooltip placement="top">
                                    {detail.personalAbbrevation}
                                  </Tooltip>
                                ) : (
                                  detail.fullName
                                )}
                              </Tooltip>

                              <span className="to-company-info">
                                {detail.companyAbbrevation ? (
                                  <Tooltip
                                    placement="top"
                                    title={detail.companyName}
                                  >
                                    {detail.companyAbbrevation}
                                  </Tooltip>
                                ) : (
                                  detail.companyName && detail.companyName
                                )}
                              </span>
                              {moreInstanceMessage.length > i + 1 ? "," : ""}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          {`${detail.emailAddress}`}{" "}
                          <div className="plusIcon">
                            <PlusCircleOutlined
                              onClick={() => {
                                test(detail.emailAddress, type);
                              }}
                            />
                          </div>
                          {instanceMessage.length > i + 1 ? "," : ""}
                        </div>
                      )}
                    </div>
                  );
                })}
                {moreInstanceMessage ? (
                  !modalVisible ? (
                    ""
                  ) : (
                    <div className="view_all_detail_button">
                      <span
                        size="small"
                        onClick={() => {
                          setModalVisible(!modalVisible);
                        }}
                      >
                        {" "}
                        less
                      </span>
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </Collapse>
        </div>
      )}
    </>
  );
};

export const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getDaysDifference = (startDate) => {
  var date1 = new Date(startDate);
  var presentDate = new Date();
  var Difference_In_Time = date1.getTime() - presentDate.getTime();
  var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) - 1;
  if (diff > 0) return diff;
  else {
    return 0;
  }
};
export function getParticipantsInfo(messages) {
  let participantEmails = "";
  messages &&
    messages.map((detail, i) => {
      participantEmails = `${participantEmails} ${detail.emailAddress || ""} ${
        detail.companyName ? `[${detail.companyName}]` : ""
      } ${messages.length > i + 1 ? "," : ""}`;
    });
  return participantEmails;
}

//return search value
export function getListingOnSearch(searchValue) {}
//Compare two array value
export const objectsEqual = (o1, o2) =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => o1[p] === o2[p])
    : o1 === o2;

export const arraysEqual = (a1, a2) => {
  return (
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]))
  );
};

export function textCenterDot(string) {
  let firstDisplayName = string.slice(0, string.length - 8) ?? "";
  let lastDisplayName = string.slice(string.length - 8, string.length) ?? "";
  return (
    <>
      <div className="text-center-overflow-wrapper">
        <span className="first-overflow">{firstDisplayName}</span>
        <span className="second-overflow">{lastDisplayName}</span>
      </div>
    </>
  );
}

export function fileDownload(url, fileName) {
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
}

export function openFolderIcon() {
  return `<span role="img" aria-label="folder-open" class="anticon anticon-folder-open">
  <svg viewBox="64 64 896 896" focusable="false" data-icon="folder-open" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 00-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zm-180 0H238c-13 0-24.8 7.9-29.7 20L136 643.2V256h188.5l119.6 114.4H748V444z">
    </path>
  </svg>
</span>`;
}

export function closeFolderIcon() {
  return ` <span role="img" aria-label="folder" class="anticon anticon-folder">
  <svg viewBox="64 64 896 896" focusable="false" data-icon="folder" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M880 298.4H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path>
  </svg>
</span>`;
}

export function fileUploadButton(isMultiple) {
  return `
      <div className='fileUpload-btn-wrapper'>
          <label className='upload-btn' htmlFor="input-file">Upload File</label>
          <input className="folder-file" style="display:none" id="input-file" type='file' name='file' multiple=${isMultiple} />
      </div>
     `;
}

export function extensionFolder(number, id) {
  return `
    <label className="folder-name" htmlFor='folder-radio-extension-${number}-${id}' data-label='${id}|1' data-code='EXTNOTICE' data-extension='true' data-multiple='false'>Extension ${number}</label>
    <input value=${id} style="display:none" name="fileLableTypeIdStageExtension" type="radio" id='folder-radio-extension-${number}-${id}' />
  `;
}

export function createHighlightedColor(xCal, yCal) {
  const width = "25px";
  const height = "25px";
  const colors = [
    "greenyellow",
    "yellow",
    "lightgreen",
    "lightblue",
    "red",
    "white",
  ];
  let highlightWrapper = document.createElement("div");
  highlightWrapper.classList.add("highlight-wrapper");
  highlightWrapper.style.position = "absolute";
  highlightWrapper.style.left = `${xCal}px`;
  highlightWrapper.style.top = `${yCal}px`;
  highlightWrapper.style.zIndex = 999;
  highlightWrapper.style.background = `#fff`;
  highlightWrapper.style.boxShadow = "0px 0px 5px 0px rgba(0,0,0,0.5)";
  highlightWrapper.style.padding = "5px 7px";
  highlightWrapper.style.display = "flex";
  highlightWrapper.style.flexFlow = "row wrap";
  highlightWrapper.style.columnGap = "5px";
  highlightWrapper.style.rowGap = "5px";
  colors?.map((item) => {
    let span = document.createElement("span");
    span.style.background = item;
    span.style.width = width;
    span.style.height = height;
    span.setAttribute("color", item);
    if (item === "white") {
      span.style.position = "relative";
      let div1 = document.createElement("div");
      div1.style.position = "absolute";
      div1.style.width = "1px";
      div1.style.height = "30px";
      div1.style.background = "red";
      div1.style.transform = "rotate(46deg)";
      div1.style.left = "12px";
      let div2 = document.createElement("div");
      div2.style.position = "absolute";
      div2.style.width = "1px";
      div2.style.height = "30px";
      div2.style.background = "red";
      div2.style.transform = "rotate(133deg)";
      div2.style.right = "12px";
      span.append(div1);
      span.append(div2);
    }
    highlightWrapper.append(span);
  });
  return highlightWrapper;
}
