import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Dropdown,
  Image,
  Select,
  Skeleton,
  Menu,
  Modal,
  Form,
  Input,
  DatePicker,
  Checkbox,
  Switch,
  message,
} from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  getTenderHistoryPagination,
  getTenderUpcomingPagination,
  getTenderFourthCompingPagination,
  dateFilteringTender,
  tenderCounter,
} from "services/redux/Reference/Tender/action";

import moment from "moment";
import { isEmpty } from "lodash";

import { tenderGetBasicDetails } from "services/redux/Reference/Tender/action";
import InternalMessage from "components/mail/InternalMessage";
import {
  getAllUsers,
  messageApplicationTypeListing,
} from "services/remanentCalls";
import ProjectsLists from "./DashboardComponents/ProjectsLists";
import DashboardModal from "components/modals/DashboardModal";
import { getDashboardProjects } from "services/redux/Reference/Project/action";
const internalMessageGreen = "assets/svg/internal-msg-12-green.svg";
const man = "assets/images/icons/man.png";
const { Option } = Select;

const getDataFromSessionStorage = () => {
  const data = sessionStorage.getItem("myData");
  const myBooleanValue = data === "true";
  return myBooleanValue;
};

const TenderLists = ({ classNames }) => {
  const [form] = Form.useForm();
  const [formDashboard] = Form.useForm();
  const [formTenderDashboard] = Form.useForm();
  const { RangePicker } = DatePicker;

  const [tenderData, setTenderData] = useState([]);
  const [tenderHistory, setTenderHistory] = useState(true);
  const [tenderFourth, setTenderFourth] = useState(false);
  const [tenderUpComingAction, setTendorUpcomingAction] = useState(false);
  const [openDateRangeModal, setOpenDateRangeModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [participated, setParticipated] = useState(false);
  const [nonParticipated, setNonParticipated] = useState(true);
  const [showdateFiltering, setShowDateFiltering] = useState(
    getDataFromSessionStorage
  );
  const [dataFilteringFrom, setDataFilteringFrom] = useState("");
  const [countTask, setCountTask] = useState([]);
  const [openNewMessage, setOpenNewMessage] = useState(false);
  const [newId, setNewId] = useState();
  const [applicationType, setApplicationType] = useState();
  const [allData, setGetAllDta] = useState();
  const [rotate, setRotate] = useState(0);
  const [isFlippedBack, setIsFlippedBack] = useState(false);
  const [isDashboardModalVisible, setIsDashboardModalVisible] = useState(false);
  const [isTenderDashboardModalVisible, setIsTenderDashboardModalVisible] =
    useState(false);

  const dispatch = useDispatch();
  const {
    tenderBasicGetDetailsResponse,
    tenderBasicGetDetailsLoading,
    tenderHistoryPaginationResponse,
    tenderUpcomingPaginationResponse,
    tenderFourthPaginationResponse,
    tenderHistoryUpcomingPaginationLoading,
    dateFilteringResponse,
    tenderCounterListResponse,
    dateFilteringResponseLoading,
  } = useSelector((state) => state?.tender);

  const { dashboardProjectPaginationLoading } = useSelector(
    (state) => state?.project
  );

  useEffect(() => {
    sessionStorage.setItem("myData", showdateFiltering.toString());
  }, [showdateFiltering]);

  const getHistoryAction = () => {
    dispatch(getTenderHistoryPagination());
    setTenderHistory(false);
    setTenderFourth(false);
    setTendorUpcomingAction(true);
    setShowDateFiltering(false);
  };

  const getUpcomingAction = () => {
    dispatch(getTenderUpcomingPagination());
    // dispatch(getTenderFourthCompingPagination());
    setTendorUpcomingAction(false);
    setTenderFourth(false);
    setTenderHistory(true);
    setShowDateFiltering(false);
  };

  const applicationTypeListing = async () => {
    const resp = await messageApplicationTypeListing().catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      resp?.data?.map((type) => {
        if (type.displayName === "Business") {
          setApplicationType(type);
        } else if (module === "Approvals" && type.displayName === "Leave") {
          setApplicationType(type);
        }
      });
    }
  };
  const getFouthUpcomingAction = () => {
    dispatch(getTenderFourthCompingPagination());
    setTenderFourth(true);
    setTenderHistory(false);
    setTendorUpcomingAction(false);
    setShowDateFiltering(false);
  };

  const handleDashboardModalOk = () => {
    setIsDashboardModalVisible(false);
  };

  const handleDashboardModalCancel = () => {
    setIsDashboardModalVisible(false);
    formDashboard.resetFields();
  };

  const handleTenderDashboardModalOk = () => {
    setIsTenderDashboardModalVisible(false);
  };

  const handleTenderDashboardModalCancel = () => {
    setIsTenderDashboardModalVisible(false);
    formTenderDashboard.resetFields();
  };

  const onFinish = async (values) => {
    setParticipated(false);
    setShowDateFiltering(true);
    setTenderFourth(false);
    setTendorUpcomingAction(false);
    setTenderHistory(false);

    var body = {
      SearchToDate: endDate,
      SearchFromDate: startDate,
      SearchType: "daterange",
    };

    if (participated) {
      body.SearchType = nonParticipated
        ? "daterangenotparticipated"
        : "daterangeparticipated";
    }

    if (values !== undefined) {
      body = {
        SearchText: values.SearchKeyword,
        SearchType: "filterText",
      };
      setDataFilteringFrom("Search");
    } else {
      setDataFilteringFrom("Date");
    }

    try {
      await dispatch(dateFilteringTender(body));
      setOpenDateRangeModal(false);
      handleTenderDashboardModalOk();
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      formTenderDashboard.resetFields();
    }
  };

  const onFinishDashboardModal = async (values) => {
    console.log("values: ", values);
    let body = {
      ...values,
      IsDashboard: true,
    };
    try {
      await dispatch(getDashboardProjects(body));
      handleDashboardModalOk();
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      formDashboard.resetFields();
    }
  };

  useEffect(() => {
    if (isEmpty(tenderData)) {
      applicationTypeListing();
    }
  }, []);

  useEffect(() => {
    dispatch(getTenderUpcomingPagination());
    dispatch(tenderCounter());

    const fetchAllParticipants = async () => {
      const resp = await getAllUsers().catch((err) => {
        message.error("Failure");
      });

      if (resp && resp.status === 200) {
        setGetAllDta(resp.data);
      }
    };
    fetchAllParticipants();
    // dispatch(getTenderHistoryPagination());
    // dispatch(getTenderFourthCompingPagination());
  }, []);

  useEffect(() => {
    if (!isEmpty(newId)) {
      dispatch(tenderGetBasicDetails(newId));
    }
  }, [newId]);

  useEffect(() => {
    if (!isEmpty(tenderCounterListResponse)) {
      setCountTask(tenderCounterListResponse);
      // setCountPrivate(tenderCounterListResponse)
    }
  }, [tenderCounterListResponse]);

  useEffect(() => {
    if (!isEmpty(tenderHistoryPaginationResponse?.items)) {
      setTenderData(tenderHistoryPaginationResponse?.items);
    } else {
      setTenderData([]);
    }
  }, [tenderHistoryPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(tenderUpcomingPaginationResponse?.items)) {
      setTenderData(tenderUpcomingPaginationResponse?.items);
    } else {
      setTenderData([]);
    }
  }, [tenderUpcomingPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(tenderFourthPaginationResponse?.items)) {
      setTenderData(tenderFourthPaginationResponse?.items);
    } else {
      setTenderData([]);
    }
  }, [tenderFourthPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(tenderFourthPaginationResponse?.items)) {
      setTenderData(tenderFourthPaginationResponse?.items);
    } else {
      setTenderData(tenderFourthPaginationResponse?.items);
    }
  }, [tenderFourthPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(dateFilteringResponse) && showdateFiltering) {
      setTenderData(dateFilteringResponse?.items);
      // setShowDateFiltering(true);
      setTenderFourth(false);
      setTenderHistory(false);
      setTendorUpcomingAction(false);
    }
  }, [dateFilteringResponse]);

  const tenderDays = (date) => {
    const momentDate = moment.utc(date, "YYYY-MM-DD");
    const momentNow = moment().startOf("day").utc(date, "YYYY-MM-DD");
    const diffDays = Math.abs(momentNow.diff(momentDate, "days"));
    if (diffDays === 0) return "Today";
    if (diffDays < 30) {
      return diffDays + "d";
    } else if (diffDays < 365) {
      return Math.trunc(diffDays / 30) + "m";
    } else {
      return Math.trunc(diffDays / 365) + "y";
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setOpenDateRangeModal(true);
        }}
      >
        Date Range
      </Menu.Item>
      <Menu.Item>Participated</Menu.Item>
      <Menu.Item>Not Participated</Menu.Item>
    </Menu>
  );

  const handleNewMessage = (id) => {
    setNewId(id);
    setOpenNewMessage(true);
    dispatch(tenderGetBasicDetails(id));
  };

  return (
    <div className="flip-card" style={{ height: "510px" }}>
      <div
        className="flip-card-inner"
        style={{ transform: `rotateY(${rotate * 180}deg)` }}
      >
        <div className="flip-card-front">
          {" "}
          <div
            className={"dashboards__tenderlist dashboards__body " + classNames}
            style={{ height: "495px" }}
          >
            <div className="dashboards__bodyWrap">
              <h4 className="dashboards__body--title">
                {/* {tenderHistory
                  ? "Tenders"
                  : tenderFourth
                  ? "Tenders"
                  : "Tenders"} */}
                Tenders/Projects
                <span
                  onClick={() => {
                    setRotate(rotate + 1);
                    setIsFlippedBack((prev) => !prev);
                  }}
                  style={{
                    marginLeft: "10px",
                    height: "30px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src="./assets/svg/toggle-arrow.svg"
                    height={15}
                    alt="complete"
                  />
                </span>
              </h4>
              {/* <Button type='link'>View All</Button> */}
              <h4 className="dashboards__body--content">
                {showdateFiltering && (
                  <button
                    className={`forthcoming-btn ${
                      showdateFiltering && "active"
                    }`}
                    // onClick={getFouthUpcomingAction}
                    // disabled={tenderFourth ? true : false}
                  >
                    {dataFilteringFrom} Filtering (
                    {dateFilteringResponse?.totalCount})
                  </button>
                )}
                <button
                  className={`forthcoming-btn ${tenderFourth && "active"}`}
                  onClick={getFouthUpcomingAction}
                  disabled={tenderFourth ? true : false}
                >
                  ForthComing-FT ({countTask?.forthcoming?.total})
                </button>
                <button
                  className={`upcoming-btn ${tenderHistory && "active"}`}
                  onClick={getUpcomingAction}
                  disabled={
                    tenderHistory && !tenderHistoryUpcomingPaginationLoading
                      ? true
                      : false
                  }
                >
                  Upcomming due date ({countTask?.current?.total})
                </button>

                <button
                  className={`history-btn ${tenderUpComingAction && "active"}`}
                  onClick={getHistoryAction}
                  disabled={tenderUpComingAction ? true : false}
                >
                  Due date crossed ({countTask?.dueDateCrossed?.total})
                </button>

                <Dropdown.Button
                  style={{ width: "20px" }}
                  overlay={menu}
                  className="open_menu_card_title_dashboard"
                ></Dropdown.Button>
                <SearchOutlined
                  style={{
                    marginLeft: "10px",
                    width: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsTenderDashboardModalVisible(true);
                  }}
                />
              </h4>
            </div>
            <DashboardModal
              visible={isTenderDashboardModalVisible}
              onCancel={handleTenderDashboardModalCancel}
              onOk={handleTenderDashboardModalOk}
              onFinish={onFinish}
              title="Advanced Search Tenders/Projects"
              footer={null}
              form={formTenderDashboard}
              isLoading={dateFilteringResponseLoading}
              type="Tenders/Projects"
            />
            <div className="dashboards__body--main">
              <div className="dashboard_title_header_tender">
                <div className="dashboard_title_main">
                  <h5 className="space-at">SN</h5>
                  <h5>Title</h5>
                </div>
                <div className="dashboard_title_main due_date_dashboard">
                  <h5 align="right">
                    {tenderHistory
                      ? "Due Date"
                      : tenderFourth
                      ? ""
                      : "Due Date"}
                  </h5>
                </div>
              </div>
              <ul
                className="dashboards__todolists"
                style={{
                  position: !isFlippedBack ? "relative" : "static",
                  zIndex: !isFlippedBack ? 2 : "auto",
                }}
              >
                {!isEmpty(tenderData) ? (
                  tenderData?.map((list, i) => (
                    <li
                      className="dashboards__tenderlist--left"
                      style={{ width: "100%" }}
                      key={list?.id}
                    >
                      <div
                        className="dashboards__tenderlist--left left1"
                        style={{ width: "75%" }}
                      >
                        <div className="left1-title">
                          <h5 style={{ marginRight: 20 }}>{i + 1}</h5>
                          <h5 className="left1-title-main">{list.title}</h5>
                        </div>

                        <div style={{ marginLeft: 28 }}>
                          {list.referenceCode}
                        </div>
                      </div>
                      {list.submissionDate !== "0001-01-01T00:00:00" && (
                        <div
                          className="dashboards__tenderlist--right"
                          style={{
                            width: "36%",
                            justifyContent: "end",
                            gap: "10px",
                          }}
                        >
                          <h6>
                            {list.submissionDate == null
                              ? ""
                              : moment(list.submissionDate).format(
                                  "DD-MMM-YYYY"
                                )}
                          </h6>

                          {list.submissionDate == null ? (
                            ""
                          ) : (
                            <span className="remaining-wrapper">
                              {tenderDays(list.submissionDate)}
                            </span>
                          )}

                          <Link
                            to={`/office-resource/business?code=${list.referenceCode}&id=${list.id}`}
                            // className="tender-view-btn"
                          >
                            <EyeOutlined />
                          </Link>

                          <Link
                            onClick={() => {
                              handleNewMessage(list?.id);
                            }}
                          >
                            <img
                              src={internalMessageGreen}
                              height="12px"
                              alt="message "
                            />
                          </Link>
                        </div>
                      )}
                    </li>
                  ))
                ) : !tenderHistoryUpcomingPaginationLoading ? (
                  <div className="dashboards__visitors--none">
                    <Image className="data-no-img" src={man} />
                    <span>There are no Tenders.</span>
                  </div>
                ) : (
                  <Skeleton />
                )}
              </ul>
            </div>
            {openDateRangeModal && (
              <Modal
                visible={openDateRangeModal}
                footer={null}
                onCancel={() => {
                  setOpenDateRangeModal(false);
                  setParticipated(false);
                }}
              >
                <div style={{ marginTop: "20px" }}>
                  <Form form={form}>
                    <Form.Item name="SearchToDate">
                      <RangePicker
                        onChange={(value) => {
                          setStartDate(
                            value ? moment(value[0]).format("YYYY-MM-DD") : ""
                          );
                          setEndDate(
                            value ? moment(value[1]).format("YYYY-MM-DD") : ""
                          );
                        }}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Checkbox
                        onChange={() => {
                          setParticipated(!participated);
                        }}
                      >
                        Participated
                      </Checkbox>
                    </Form.Item>

                    {participated && (
                      <Form.Item>
                        Non Participated{" "}
                        <Switch
                          onChange={() => {
                            setNonParticipated(false);
                          }}
                        ></Switch>{" "}
                        Participated
                      </Form.Item>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          onFinish();
                        }}
                      >
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </Modal>
            )}
            {openNewMessage && (
              <InternalMessage
                allData={allData}
                openNewMessage={openNewMessage}
                setOpenNewMessage={setOpenNewMessage}
                specificTenderData={tenderBasicGetDetailsResponse}
                applicationType={applicationType}
                setNewId={setNewId}
                tenderLoading={tenderBasicGetDetailsLoading}
              />
            )}
          </div>
        </div>
        <div className="flip-card-back">
          <div
            style={{
              backfaceVisibility: "hidden",
            }}
          >
            <div
              className={
                "dashboards__tenderlist dashboards__body " + classNames
              }
            >
              <div className="dashboards__bodyWrap">
                <h4 className="dashboards__body--title">
                  Projects/Tenders
                  <span
                    onClick={() => {
                      setRotate(rotate + 1);
                      setIsFlippedBack((prev) => !prev);
                    }}
                    style={{
                      marginLeft: "10px",
                      height: "30px",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src="./assets/svg/toggle-arrow.svg"
                      height={15}
                      alt="complete"
                    />
                  </span>
                </h4>
                <h4 className="dashboards__body--content">
                  <SearchOutlined
                    onClick={() => {
                      setIsDashboardModalVisible(true);
                    }}
                  />
                </h4>
              </div>
              <DashboardModal
                visible={isDashboardModalVisible}
                onCancel={handleDashboardModalCancel}
                onOk={handleDashboardModalOk}
                onFinish={onFinishDashboardModal}
                title="Advanced Search Project/Tenders"
                footer={null}
                form={formDashboard}
                isLoading={dashboardProjectPaginationLoading}
                type="Project/Tenders"
              />
              <ProjectsLists />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenderLists;
