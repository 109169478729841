import { TODO_TYPES } from "../../../types";

const INITIAL_STATE = {
  allEventStatusListing: [],
  eventStatusListingLoading: false,
  eventStatusListingUpdateLoading: false,
  eventStatusListingUpdateResponse: null,
  eventStatuListingAddResponse:null,
};

export default function statusEventListingReducer(
  state = INITIAL_STATE,
  action
) {
  //eventlisting
  switch (action.type) {
    case TODO_TYPES.STATUS_LISTING_INIT:
      return {
        ...state,
        allEventStatusListing: [],
        eventStatusListingLoading: true,
      };

    case TODO_TYPES.STATUS_LISTING_SUCCESS:
      return {
        ...state,
        allEventStatusListing: action.payload,
        eventStatusListingLoading: false,
      };

    case TODO_TYPES.STATUS_LISTING_FINISH:
      return {
        ...state,
        eventStatusListingLoading: false,
      };

    //Status Event Update Listing
    case TODO_TYPES.UPDATE_STATUS_LISTING_INIT:
      return {
        ...state,
        eventStatusListingUpdateLoading: true,
        eventStatusListingUpdateResponse: null,
      };
    case TODO_TYPES.UPDATE_STATUS_LISTING_SUCCESS:
      return {
        ...state,
        eventStatusListingUpdateLoading: false,
        eventStatusListingUpdateResponse: action.payload,
      };

    case TODO_TYPES.UPDATE_STATUS_LISTING_FINISH:
      return {
        ...state,
        eventStatusListingUpdateLoading: false,
        eventStatusListingUpdateResponse: null,
      };
    default:
      return {
        ...state,
      };
  }
}
