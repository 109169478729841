import { ADMIN_TYPES } from "services/types";
import { unionBy } from "lodash";

const INITIAL_STATE = {
    getIpAddressResp: [],
    getIpAddressLoading: false,

    addIpAddressResp: [],
    addIpAddressLoading: false,

    updateIpAddressResp: [],
    updateIpAddressLoading: false,
};


export default function whitelistIpReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        // listing
        case ADMIN_TYPES.WHITELIST_IPADDRESS_LISTING_INIT:
            return {
                ...state,
                getIpAddressResp: [],
                getIpAddressLoading: true,
            };

        case ADMIN_TYPES.WHITELIST_IPADDRESS_LISTING_SUCCESS:
            return {
                ...state,
                getIpAddressResp: action.payload,
                getIpAddressLoading: false,
            };

        case ADMIN_TYPES.WHITELIST_IPADDRESS_LISTING_FINISH:
            return {
                ...state,
                getIpAddressLoading: false,
            };

        // ADD
        case ADMIN_TYPES.WHITELIST_IPADDRESS_ADD_INIT:
            return {
                ...state,
                addIpAddressResp: [],
                addIpAddressLoading: true,
            };

        case ADMIN_TYPES.WHITELIST_IPADDRESS_ADD_SUCCESS:
            return {
                ...state,
                addIpAddressResp: action.payload,
                addIpAddressLoading: false,
                getIpAddressResp: { items: [action.payload, ...state?.getIpAddressResp?.items] }
            };

        case ADMIN_TYPES.WHITELIST_IPADDRESS_ADD_FINISH:
            return {
                ...state,
                addIpAddressLoading: false,
            };

        // UPDATE
        case ADMIN_TYPES.WHITELIST_IPADDRESS_UPDATE_INIT:
            return {
                ...state,
                updateIpAddressResp: [],
                updateIpAddressLoading: true,
            }

        case ADMIN_TYPES.WHITELIST_IPADDRESS_UPDATE_SUCCESS:
            const updated = state?.getIpAddressResp?.items
                ?.map((item) => {
                    if (item.id === action.payload?.id) {
                        return action.payload.isActive ? { ...action.payload } : null;
                    } else {
                        return {
                            ...item,
                        };
                    }
                })
                .filter((item) => item !== null)

            return {
                ...state,
                updateIpAddressResp: action.payload,
                updateIpAddressLoading: false,
                getIpAddressResp: { items: updated }
            }

        case ADMIN_TYPES.WHITELIST_IPADDRESS_UPDATE_FINISH:
            return {
                ...state,
                updateIpAddressLoading: false,
            }

        default:
            return {
                ...state,
            };

    }
}