import { Tooltip, Typography } from "antd";
import moment from "moment";
import React from "react";
import { showNumber } from "utils/showNumber";
import { handleSpecialDate } from "utils/tooltipSepecialDate";

const getColumnsCEMAT = ({ widthh, modalShow }) => {
  return [
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "left",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Company
        </Typography.Paragraph>
      ),
      key: "type",
      dataIndex: "type",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            className="antd-paragraphg"
            style={{
              textAlign: "left",
              fontSize: widthh < 1550 ? 13 : 15,
              marginBottom: `0px !important`,
            }}
          >
            {record?.companyName}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Fee Income
        </Typography.Paragraph>
      ),
      key: "income",
      dataIndex: "income",
      render: (_, record) => (
        <Tooltip
          // title={moment(record?.incomeUpdatedDate).format("YYYY MMM DD")}
          title={handleSpecialDate(record?.incomeUpdatedDate)}
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
            onDoubleClick={() => {
              modalShow(record?.budgetQueryParam, "fee income");
            }}
          >
            {showNumber(record?.income)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Other Income
        </Typography.Paragraph>
      ),
      key: "incomeOther",
      dataIndex: "incomeOther",
      render: (_, record) => (
        <Tooltip
          // title={moment(record?.incomeUpdatedDate).format("YYYY MMM DD")}
          title={handleSpecialDate(record?.incomeUpdatedDate)}
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
            onDoubleClick={() => {
              modalShow(record?.budgetQueryParam, "other income");
            }}
          >
            {showNumber(record?.incomeOther)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Total Income{" "}
        </Typography.Paragraph>
      ),
      key: "incomeOther",
      dataIndex: "incomeOther",
      render: (_, record) => (
        <Tooltip
          // title={moment(record?.incomeUpdatedDate).format("YYYY MMM DD")}
          title={handleSpecialDate(record?.incomeUpdatedDate)}
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
            onDoubleClick={() => {
              modalShow(record?.budgetQueryParam, "total income");
            }}
          >
            {showNumber(record?.incomeOther + record?.income)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Expense
        </Typography.Paragraph>
      ),
      key: "expense",
      dataIndex: "expense",
      render: (_, record) => (
        <Tooltip
          // title={moment(record?.expenseUpdatedDate).format("YYYY MMM DD")}
          title={handleSpecialDate(record?.expenseUpdatedDate)}
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
            onDoubleClick={() => {
              modalShow(record?.budgetQueryParam, "expense");
            }}
          >
            {showNumber(record?.expense)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            width: "100%",
            verticalAlign: "baseline",
          }}
        >
          Budget
        </Typography.Paragraph>
      ),
      key: "budget",
      dataIndex: "budget",
      render: (_, record) => (
        <Tooltip
          // title={moment(record?.expenseUpdatedDate).format("YYYY MMM DD")}
          title={handleSpecialDate(record?.expenseUpdatedDate)}
        >
          <Typography.Paragraph
            style={{ textAlign: "right", fontSize: widthh < 1550 ? 13 : 15 }}
            onDoubleClick={() => {
              modalShow(record?.budgetQueryParam, "budget");
            }}
          >
            {showNumber(record?.budget)}
          </Typography.Paragraph>
        </Tooltip>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{ fontWeight: "bold", textAlign: "right" }}
        >
          Inc-Exp
        </Typography.Paragraph>
      ),
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            fontSize: widthh < 1550 ? 13 : 15,
            fontWeight: "bold",
          }}
        >
          {showNumber(record?.income + record?.incomeOther - record?.expense)}
        </Typography.Paragraph>
      ),
    },
    {
      title: (
        <Typography.Paragraph
          style={{ fontWeight: "bold", textAlign: "right" }}
        >
          Bud-Exp
        </Typography.Paragraph>
      ),
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Typography.Paragraph
          style={{
            textAlign: "right",
            fontSize: widthh < 1550 ? 13 : 15,
            fontWeight: "bold",
          }}
        >
          {showNumber(record?.budget - record?.expense)}
        </Typography.Paragraph>
      ),
    },
  ];
};

export default getColumnsCEMAT;
