import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import DepartmentTemplateTable from "./DepartmentTemplateTable";
import DepartmentTemplateAddUpdateForm from "./DepartmentTemplateAddUpdateForm";
import EmployeeModal from "./EmployeeModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllDepartments } from "services/redux/adminSettings/organizationStructure/action";

const Department = () => {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState({
    drawer: false,
    modal: false,
  });
  const [updateData, setUpdateData] = useState("");

  useEffect(() => {
    dispatch(getAllDepartments("admin"));
  }, []);

  return (
    <>
      <div className="layout">
        <div className="organizer events">
          <div className="all-header-wrapper">
            <div className="meeting__flex">
              <h3 className="header__all">Department</h3>
              <div className="organizerHoliday__middle--wrap">
                <Button
                  className="btn-primary"
                  onClick={() => {
                    setOpenForm({ drawer: true });
                  }}
                >
                  <PlusOutlined /> New Department
                </Button>
              </div>
            </div>
          </div>
          <DepartmentTemplateTable
            setOpenForm={setOpenForm}
            setUpdateData={setUpdateData}
          />
          <DepartmentTemplateAddUpdateForm
            openForm={openForm}
            setOpenForm={setOpenForm}
            updateData={updateData}
            setUpdateData={setUpdateData}
          />
          <EmployeeModal
            openForm={openForm}
            setOpenForm={setOpenForm}
            updateData={updateData}
          />
        </div>
      </div>
    </>
  );
};

export default Department;
