import React, { useEffect, useRef, useState } from "react";
import { intersectionBy, isEmpty } from "lodash";

// antd
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
  Space,
  Spin,
  message,
  Tooltip,
  Upload,
  Progress,
  Checkbox,
  Tag,
  Skeleton,
  Layout,
  Typography,
  Input,
  Modal,
} from "antd";
import {
  LoadingOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

// ckeditor
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Editor } from "@tinymce/tinymce-react";
import "tinymce/tinymce";

import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";

// import 'tinymce/plugins/image'
// import 'tinymce/plugins/imagetools'
// import 'tinymce/plugins/table'
// import 'tinymce/plugins/hr'
// import 'tinymce/plugins/link'
// import '@tinymce/plugins/image'
// import '@tinymce/plugins/imagetools'
// import '@tinymce/plugins/table'
// import '@tinymce/plugins/hr'
// import '@tinymce/plugins/link'
// import '@tinymce/plugins/lists'
// import '@tinymce/plugins/advlist'
import { appBaseUrl, cqBaseUrl } from "utils/config";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  instanceMessageUpdate,
  instanceMessageUserSuggestion,
  replyInstanceMessage,
} from "services/redux/email/instanceMessageCenter/action";
import {
  deleteReplyMsgResponse,
  deleteReplyMsgUpdateResponse,
} from "services/redux/email/instanceMessageCenter/action";

// inlcudes
import ImageUploaderMultiple from "components/shared/ImageUploaderMultiple";
import { useHistory } from "react-router";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import {
  getAllUsers,
  uploadFile,
  MessageApplicationTypeListing,
} from "services/remanentCalls";

//storage
import storage from "../../../utils/storage";
import {
  changeEmailStatusType,
  selectedMailTypeAndMail,
} from "services/redux/email/mailMessageCenter/action";
import {
  actionForCreateGroup,
  actionForGetListingGroup,
  actionForUpdateGroup,
} from "services/redux/createGroup/action";

const { Option } = Select;
const { CheckableTag } = Tag;

const layoutFormGroup = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const CheckMark = "assets/svg/checkmark-24.svg";
const cancel = "assets/svg/white-cross.svg";
const attachment = "assets/svg/attachment-white.svg";
const threeDot = "assets/svg/3dot-white.svg";
const upChervon = "assets/svg/white-up-chervon.svg";
const send = "assets/svg/send-white.svg";
const aa = "assets/svg/Aa-white.svg";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 14, color: "white" }} spin />
);

const ReplyChatBox = ({
  setchatBox,
  setReplyStatus,
  chatBox,
  setReplyofChat,
  replyOfChat,
  mailMessageParticipationStatusListing,
  allParticipants,
  replyMsgDetails,
  currentSelectedMailMessage,
  replyStatus,
  hideInternalMessage,
  instanceMessageCenterPagination,
  replyChatheight,
  setReplyChatHeight,
  setActiveChat,
  smallChatHeight,
  setSmallChatHeight,
  predefinedData,
  setPredefinedData,
}) => {
  const [form4] = Form.useForm();
  const dispatch = useDispatch();
  const { groupData, groupDataLoading, groupDataLoadingSuccess } = useSelector(
    (state) => state.createUpdateGroup
  );

  const history = useHistory();

  const editorRef = useRef(null);

  const {
    replyInstanceMessageResp,
    instanceMessageUpdateResp,
    replyInstanceMessageRespLoading,
    allUserSuggestionResponse,
    allUserSuggestionResponseLoad,
  } = useSelector((state) => state.instanceMessageCenter);

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );
  const [form] = Form.useForm();

  const [editorData, setEditorData] = useState("");
  const [msgData, setMsgData] = useState("");
  const [par, setPar] = useState("");
  const [editorD, setEditorD] = useState("");
  // console.log("editorData", editorD);
  const [smsText, setSMSText] = useState("");
  const [attatchments, setAttatchments] = useState([]);
  const [messageParticipants, setMessageParticipants] = useState([]);
  const [messageParticipantsCC, setMessageParticipantsCC] = useState([]);
  const [quickMessageParticipantDtosTo, setQuickMessageParticipantDtosTo] =
    useState([]);
  const [quickMessageParticipantDtosCC, setQuickMessageParticipantDtosCC] =
    useState([]);

  const [mailMessageCenterType, setMailMessageCenterType] = useState([]);
  const [instanceMessageStatusName, setInstanceMessageStatusName] =
    useState("Normal");
  const [progress, setProgress] = useState(0);
  const [selectTosInternal, setSelectTosInternal] = useState(false);
  const [showEditorHeader, setEditorHeader] = useState(false);
  const [users, setUsers] = useState([]);
  const [filterParticipantForm, setFilterParticipantForm] = useState();
  const [applicationType, setApplicationType] = useState();
  // const [AltEnt, setAltEnt] = useState({});
  const [AltEnt, setAltEnt] = useState(false);

  //suggestions in internal Chat
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [suggestionAll, setAllSuggestion] = useState(false);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [selectedGroupDataToBeUpdated, setGroupDataToBeUpdate] = useState();
  const [selectTosNewCutGroup, setSelectTosNewCutGroup] = useState();
  const [selectCcsNewCutGroup, setSelectCcsNewCutGroup] = useState();

  useEffect(() => {
    fetchAllParticipants();
  }, []);

  useEffect(() => {
    // if (AltEnt?.alt && AltEnt?.enter) {
    if (AltEnt) {
      const newMailMessageCenterTypo = mailMessageCenterType.filter(
        (type) => type.systemName === "Normal"
      );
      onFinish(newMailMessageCenterTypo?.[0].systemName);
      setAltEnt(false);
    }
    // if (AltEnt?.length !== 0) {
    //   setTimeout(() => {
    //     setAltEnt([]);
    //   }, 5000);
    // }
  }, [AltEnt]);

  useEffect(() => {
    if (isEmpty(instanceMessageCenterPagination)) {
      setReplyChatHeight(true);
      setchatBox(true);
    } else {
      setReplyChatHeight(false);
    }
  }, [instanceMessageCenterPagination]);

  // useEffect(() => {
  //   applicationTypeListing();
  // }, []);

  // const applicationTypeListing = async () => {
  //   const resp = await MessageApplicationTypeListing().catch((err) => {
  //     message.error("Failure");
  //   });

  //   if (resp && resp.status === 200) {
  //     setApplicationType(resp.data);
  //   }
  // };

  useEffect(() => {
    if (!hideInternalMessage) {
      setEditorData("");
      form.setFieldsValue({
        participantCCs: [],
        participantTos: [],
      });
    }
  }, [hideInternalMessage]);

  useEffect(() => {
    if (!chatBox) {
      form.setFieldsValue({
        participantCCs: [],
        participantTos: [],
        uploadedFileDtos: [],
      });
      setEditorData("");
    }
  }, [chatBox]);

  useEffect(() => {
    if (!isEmpty(mailMessageParticipationStatusListing)) {
      let newMailMessageCenterType =
        mailMessageParticipationStatusListing.filter(
          (status) =>
            status.systemName === "Normal" ||
            status.systemName === "Pending" ||
            status.systemName === "Archived"
        );

      setMailMessageCenterType(newMailMessageCenterType);
    }
  }, [mailMessageParticipationStatusListing]);

  useEffect(() => {
    if (isEmpty(messageParticipants) && !isEmpty(allParticipants)) {
      setMessageParticipants(allParticipants);
      setMessageParticipantsCC(allParticipants);
    }
  }, [allParticipants]);
  useEffect(() => {
    // setchatBox(false);
    setReplyofChat("");
    // setReplyChatHeight(false);
  }, []);
  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      let participantFrom = storage.getItem("userId");
      let allUsers = resp.data.filter(
        (obj) => obj.appUserId == participantFrom
      );

      setUsers(allUsers);
    }
  };

  // console.log("chatBox", replyMsgDetails);

  useEffect(() => {
    if (!isEmpty(users) && !isEmpty(replyMsgDetails)) {
      let fromEmailAddresss = users[0]?.appUserId; //.employeeEmailDtos[0]
      let replyFrom =
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
          .participantUserDto.appUserId;

      if (fromEmailAddresss === replyFrom) {
        setFilterParticipantForm(true);
      }
    }
  }, [users, replyMsgDetails]);

  useEffect(() => {
    if (
      !isEmpty(replyInstanceMessageResp) ||
      !isEmpty(instanceMessageUpdateResp)
    ) {
      form.resetFields();
      setEditorData("");
      setReplyChatHeight(false);
      setTimeout(() => {
        setchatBox(false);
      }, 500);

      setReplyofChat("");
      setReplyStatus("");
      //dispatch(deleteReplyMsgResponse());
      // dispatch(deleteReplyMsgUpdateResponse());
    }
  }, [replyInstanceMessageResp, instanceMessageUpdateResp]);

  useEffect(() => {
    if (replyStatus) {
      form.resetFields();
      setSelectedEmails([]);

      setReplyChatHeight(true);
      let userId = storage.getItem("userId");
      let participantTypeTos =
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantTos.map(
          (typeTos) => {
            return { participantUserId: typeTos.participantUserId };
          }
        );
      let participantTypeCCs = !isEmpty(
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantCCs
      )
        ? replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantCCs.map(
            (typeTos) => {
              return { participantUserId: typeTos.participantUserId };
            }
          )
        : [];

      let participantTypeFrom = [
        {
          participantUserId:
            replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
              .participantUserId,
        },
      ];

      // console.log("AKCC-participantTypeFrom", participantTypeFrom);
      // console.log("AKCC-participantTypeTos", participantTypeTos);
      // console.log("AKCC-participantTypeCCs", participantTypeCCs);
      // console.log("AKCC-replyStatus", replyStatus);

      let filterParticipantTos = participantTypeFrom;
      // console.log("Here is participant form", filterParticipantTos);
      let filterParticipantCcs = participantTypeCCs.filter(
        (filteredCcs) => filteredCcs.participantUserId !== userId
      );
      let newQuickMessageParticipantDtos = participantTypeTos.filter(
        (filterTos) => filterTos.participantUserId !== userId
      );
      newQuickMessageParticipantDtos =
        newQuickMessageParticipantDtos.concat(filterParticipantCcs);

      if (
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
          .participantUserId == userId
      ) {
        // console.log("User is From ");
        //All the To and CC Remains the same
        filterParticipantTos = participantTypeTos.filter(
          (filterTos) => filterTos.participantUserId !== userId
        );
        let filterParticipantCcs = participantTypeCCs.filter(
          (filteredCcs) => filteredCcs.participantUserId !== userId
        );
        newQuickMessageParticipantDtos = filterParticipantCcs;
      }

      if (replyStatus !== "edit" && replyStatus !== "forward") {
        form.setFieldsValue({
          participantTos: filterParticipantTos.map((typeTos) => {
            return typeTos.participantUserId;
          }),

          // participantCCs: newQuickMessageParticipantDtos.map((typeTos) => {
          //   return typeTos.participantUserId;
          // }),
        });

        setQuickMessageParticipantDtosTo(filterParticipantTos);
        //setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);
        // console.log("AKCC-filterParticipantTos", filterParticipantTos);
      }
      if (replyStatus === "replyall") {
        form.setFieldsValue({
          participantCCs: newQuickMessageParticipantDtos.map((typeTos) => {
            return typeTos.participantUserId;
          }),
        });

        setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);
        let data = newQuickMessageParticipantDtos.map((typeTos) => {
          return typeTos.participantUserId;
        });
        setSelectedEmails(data);

        // console.log(
        //   "AKCC-newQuickMessageParticipantDtos",
        //   newQuickMessageParticipantDtos
        // );
      } else if (replyStatus === "forward") {
        form.setFieldsValue({
          participantTos: [],
          participantCCs: [],
        });
      } else if (replyStatus === "edit") {
        form.setFieldsValue({
          participantTos: participantTypeTos.map((typeTos) => {
            return typeTos.participantUserId;
          }),
          participantCCs: !isEmpty(participantTypeCCs)
            ? participantTypeCCs.map((typeTos) => {
                return typeTos.participantUserId;
              })
            : [],
        });

        setQuickMessageParticipantDtosTo(participantTypeTos);
        setQuickMessageParticipantDtosCC(participantTypeCCs);
      }
      if (replyStatus === "forward" || replyStatus === "edit") {
        // console.log("participantTypeFrom",replyMsgDetails)
        let data =
          replyStatus === "forward"
            ? `This is forwaded message of ${replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom?.participantUserDto?.fullName} \n ------- ${replyMsgDetails.messageText}`
            : replyMsgDetails.messageText;
        setEditorData(data);
      } else {
        let participantTypeFromName = "";
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
          .participantUserId == userId
          ? replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantTos?.map(
              (x, index) => {
                participantTypeFromName =
                  participantTypeFromName +
                  x?.participantUserDto?.userName
                    ?.toUpperCase()
                    .concat(
                      index + 1 ==
                        replyMsgDetails?.mailMessageParticipantWithTypeDtos
                          ?.participantTos?.length
                        ? ":"
                        : "/"
                    );
              }
            )
          : (participantTypeFromName =
              replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom?.participantUserDto?.userName.toUpperCase() +
              ":");
        if (replyStatus == "reply") {
          setEditorData(participantTypeFromName);
        } else if (replyStatus == "replyall") {
          // participantTypeFromName.replace(":", "/");
          // replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantCCs?.map(
          //   (x, index) => {
          //     participantTypeFromName =
          //       participantTypeFromName +
          //       x?.participantUserDto?.userName
          //         .toUpperCase()
          //         .concat(
          //           index + 1 ==
          //             replyMsgDetails?.mailMessageParticipantWithTypeDtos
          //               ?.participantCCs?.length
          //             ? ":"
          //             : "/"
          //         );
          //   }
          // );
          setEditorData(participantTypeFromName);
        } else {
          setEditorData("");
        }
      }
    }
  }, [replyMsgDetails, replyStatus, filterParticipantForm]);

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgress,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
    });
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      setAttatchments(alteredResponse);
      let olduploadedFileDtos = form.getFieldValue().uploadedFileDtos
        ? [...form.getFieldValue().uploadedFileDtos]
        : [];

      let newUploadedFileDtos = [...olduploadedFileDtos];
      newUploadedFileDtos.push(alteredResponse);
      // uploadedFileDtos[i] = e;

      form.setFieldsValue({
        uploadedFileDtos: newUploadedFileDtos,
      });
      // onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  const changeParticipantsDtosTo = (option) => {
    let newQuickMessageParticipantDtos = option.map((participant) => ({
      participantUserId: participant.value,
    }));
    // console.log(newQuickMessageParticipantDtos,allParticipants)
    setQuickMessageParticipantDtosTo(newQuickMessageParticipantDtos);
    // console.log(newQuickMessageParticipantDtos)

    let newMessagePariticipants = [];
    allParticipants.map((participant) => {
      let checkSelected = false;
      newQuickMessageParticipantDtos.map((selectedParticipant) => {
        if (selectedParticipant.participantUserId === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipants.push(participant);
      }
      // console.log(newMessagePariticipants)
    });

    setMessageParticipantsCC(newMessagePariticipants);
  };

  const changeParticipantsDtosCC = (option) => {
    // console.log("option", option);
    let newQuickMessageParticipantDtos = option?.map((participant) => ({
      participantUserId: participant.value,
    }));
    setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);

    let newMessagePariticipants = [];
    allParticipants.map((participant) => {
      let checkSelected = false;
      newQuickMessageParticipantDtos.map((selectedParticipant) => {
        if (selectedParticipant.participantUserId === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipants.push(participant);
      }
    });

    setMessageParticipants(newMessagePariticipants);
  };

  useEffect(() => {
    if (chatBox) {
      if (selectTosInternal) {
        let allParticipantsId = allParticipants?.map(
          (participant) => participant?.appUserId
        );
        let newQuickMessageParticipantDtos = allParticipants?.map(
          (participant) => ({
            participantUserId: participant?.appUserId,
          })
        );
        //added
        let isExcludedParticipantDtos = allParticipants
          .filter((participant) => !participant?.isExcludedInternalMessage)
          .map((participant) => participant?.appUserId);
        setQuickMessageParticipantDtosTo(newQuickMessageParticipantDtos);
        form.setFieldsValue({
          // participantTos: allParticipantsId,
          participantTos: isExcludedParticipantDtos,
        });
      } else {
        form.setFieldsValue({
          participantTos: [],
        });
      }
      // console.log(allParticipants)

      // console.log(allParticipantsId)
      // setQuickMessageParticipantDtosTo
    }
  }, [selectTosInternal]);

  const handleFilter = (value, type) => {
    const searchWord = value;
    if (type === "To") {
      const newFilter = messageParticipants.filter((value) => {
        return value.fullName.toLowerCase().includes(searchWord.toLowerCase());
      });
      // setMessageParticipants(newFilter);
    }
  };

  const onFinish = async (centerTypeStatus, msgCat) => {
    setSelectedEmails([]);
    setIsFocused(false);

    try {
      let values = await form.validateFields();
      // console.log("valuess", values.uploadedFileDtos);

      let selectedEmailss =
        selectedEmails.length > 0
          ? selectedEmails?.map((x) => {
              return {
                participantUserId: x,
              };
            })
          : quickMessageParticipantDtosCC;

      if (replyStatus == "edit") {
        let oldParticipantTo =
          replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantTos;
        let checkStatusSameParticipantTo = intersectionBy(
          quickMessageParticipantDtosTo,
          oldParticipantTo,
          "participantUserId"
        );

        let changeParticipantsTo = true;
        if (
          checkStatusSameParticipantTo.length ===
            quickMessageParticipantDtosTo.length &&
          checkStatusSameParticipantTo.length === oldParticipantTo.length
        ) {
          changeParticipantsTo = false;
        }

        let oldParticipantCC =
          replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantCCs;
        let checkStatusSameParticipantCC = intersectionBy(
          quickMessageParticipantDtosCC,
          oldParticipantCC,
          "participantUserId"
        );

        let changeParticipantsCC = true;
        if (
          checkStatusSameParticipantCC.length ===
            quickMessageParticipantDtosCC.length &&
          checkStatusSameParticipantCC.length === oldParticipantCC.length
        ) {
          changeParticipantsCC = false;
        }

        let checkStatusMessage = false;
        if (editorData === replyMsgDetails.messageText) {
          checkStatusMessage = true;
        }

        let oldAttachments =
          replyMsgDetails?.mailMessageParticipantWithTypeDtos
            ?.instanceMessageAttachmentDtos;
        let checkStatusSameAttachments = intersectionBy(
          values.uploadedFileDtos,
          oldAttachments,
          "fileName"
        );

        let changeAttachmentDtos = true;
        if (
          checkStatusSameAttachments.length ===
            values.uploadedFileDtos?.length &&
          checkStatusSameAttachments.length === oldAttachments.length
        ) {
          changeAttachmentDtos = false;
        }

        let body = {
          instanceMessageId: replyMsgDetails.id,

          mailMessageCenterId: selectedMailTypeAndMailData?.selectedMail?.id,
          applicationId:
            selectedMailTypeAndMailData?.selectedMail?.applicationId,
          applicationTypeId:
            selectedMailTypeAndMailData?.selectedMail?.applicationType ===
            "EMail"
              ? "4f24a8cc-d015-4b63-acfd-a6ca4dd6c5b3"
              : selectedMailTypeAndMailData?.selectedMail?.applicationTypeId,
          instanceMessageUpdateDto: !checkStatusMessage
            ? {
                messageText: editorData,
                instanceMessageId: replyMsgDetails.id,
              }
            : null,
          mailMessageParticipantWithTypeDtos:
            changeParticipantsTo || changeParticipantsCC
              ? {
                  participantTos: !isEmpty(form.getFieldValue().participantTos)
                    ? quickMessageParticipantDtosTo
                    : [],
                  // participantCCs: !isEmpty(form.getFieldValue().participantCCs)
                  //   ? quickMessageParticipantDtosCC
                  //   : [],
                  participantCCs: selectedEmailss,
                }
              : null,
          uploadedFileDtos: changeAttachmentDtos
            ? values.uploadedFileDtos
            : null,
        };
        dispatch(instanceMessageUpdate(body, currentSelectedMailMessage.id));
      } else {
        let body = {
          internalChatId: selectedMailTypeAndMailData?.selectedMail?.id,
          mailMessageParticipantTableId:
            replyMsgDetails.mailMessageParticipantTableId,
          messageText: editorData,
          applicationId:
            selectedMailTypeAndMailData?.selectedMail?.applicationId,
          mailMessageParticipantWithTypeDtos: {
            participantTos: !isEmpty(form.getFieldValue().participantTos)
              ? quickMessageParticipantDtosTo
              : [],
            // participantCCs: !isEmpty(form.getFieldValue().participantCCs)
            //   ? quickMessageParticipantDtosCC
            //   : [],
            participantCCs: selectedEmailss,
          },

          mailMessageCenterId: selectedMailTypeAndMailData?.selectedMail?.id,
          applicationTypeId:
            selectedMailTypeAndMailData?.selectedMail?.applicationType ===
            "EMail"
              ? "4f24a8cc-d015-4b63-acfd-a6ca4dd6c5b3"
              : selectedMailTypeAndMailData?.selectedMail?.applicationTypeId,
          instanceMessageStatusName: centerTypeStatus,
          instanceMessageActionName: replyStatus
            ? replyStatus === "replyall"
              ? "reply"
              : replyStatus
            : "reply",
          replyOfInstanceMessageId: replyMsgDetails.id,
          instanceMessageAttachmentDtos: values.uploadedFileDtos || [],
          ...(msgCat === "sms" && {
            messagePlainText: smsText,
            isComposeSMS: true,
          }),
        };
        if (body?.messageText) {
          // console.log("bodyyyyyy", body);
          dispatch(replyInstanceMessage(body));
        } else {
          const ifrDoc =
            document.getElementById("replyChatBox_ifr").contentDocument;
          // console.log(ifrDoc,"replybox")
          var elem = ifrDoc.createElement("div");
          elem.style.height = "200px";
          elem.style.border = "1px red solid";
          ifrDoc.body.appendChild(elem);
          const timer = setTimeout(() => {
            elem.remove();
          }, 1000);
          // timer();
        }
      }
    } catch (errorInfo) {}
  };
  // console.log("replyInstanceMessageRespLoading", replyStatus);
  const centerTypeMenu = () => (
    <Menu onClick={(e) => onFinish(e.key)}>
      {mailMessageCenterType.map(
        (centerType) =>
          centerType.systemName !== "Normal" && (
            <Menu.Item key={centerType.systemName}>
              As {centerType.displayName}
            </Menu.Item>
          )
      )}
    </Menu>
  );
  const messageParticipant = (a, b) => {
    if (a?.fullName < b?.fullName) {
      return -1;
    }
    if (a?.fullName > b?.fullName) {
      return 1;
    }
    return 0;
  };
  const sortedparticipants = messageParticipants?.sort(messageParticipant);
  // setMessageParticipants(sortedparticipants)
  // console.log(sortedparticipants)

  const handleEmailSelect = (value) => {
    if (!suggestionAll) {
      let xxx = [];
      xxx.push(...selectedEmails, value);
      setSelectedEmails(xxx);
    } else {
      let selectedAllEmails = filteredOptions?.map((x) => {
        return x?.appUserId;
      });
      setSelectedEmails(selectedAllEmails);
    }
  };

  useEffect(() => {
    if (suggestionAll) {
      let selectedAllEmails = filteredOptions?.map((x) => {
        return x?.appUserId;
      });
      setSelectedEmails(selectedAllEmails);
      setAllSuggestion(false);
    }
  }, [suggestionAll]);

  const filteredOptions = allUserSuggestionResponse?.filter(
    (option) => !selectedEmails?.includes(option?.appUserId)
  );

  const handleFocus = () => {
    let toData = form.getFieldsValue()["participantTos"];
    setIsFocused(toData?.length > 0 ? true : false);
  };

  const menu = () => (
    <Menu>
      <Menu.Item
        key="Send as Archieve"
        onClick={() => {
          let newMailMessageCenterType = mailMessageCenterType.filter(
            (type) => type.systemName === "Normal"
          );
          onFinish("Archived", "Archived");
        }}
      >
        Send as Archieve
      </Menu.Item>

      <Menu.Item
        key="Send as Pending"
        onClick={() => {
          let newMailMessageCenterType = mailMessageCenterType.filter(
            (type) => type.systemName === "Normal"
          );
          onFinish("Pending", "Pending");
        }}
      >
        Send as Pending
      </Menu.Item>
      <Menu.Item
        key="Send as SMS"
        onClick={() => {
          let newMailMessageCenterType = mailMessageCenterType.filter(
            (type) => type.systemName === "Normal"
          );
          onFinish(newMailMessageCenterType[0].systemName, "sms");
        }}
      >
        Send as SMS
      </Menu.Item>
    </Menu>
  );

  const menuGropCreate = () => (
    <Menu style={{ maxHeight: 250, overflow: "auto" }}>
      <Menu.Item
        key="Create Group"
        onClick={() => {
          setCreateGroupModal(true);
          setGroupDataToBeUpdate();
          form4.resetFields();
        }}
      >
        Create Group
      </Menu.Item>

      {groupData &&
        groupData?.map((x) => {
          return (
            <Menu.Item
              key={x?.id}
              onClick={() => {
                let userIdsForTo = [];
                let userIdsForCc = [];
                x?.userList?.map((x) => {
                  if (x?.participantTypeDto?.id === 0) {
                    userIdsForTo.push(x?.userId);
                  } else {
                    userIdsForCc.push(x?.userId);
                  }
                });
                setSelectTosNewCutGroup(userIdsForTo);
                setSelectCcsNewCutGroup(userIdsForCc);
                // added
                setSelectedEmails(userIdsForCc);
              }}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{x?.groupName}</div>

              <EditOutlined
                style={{ paddingLeft: 15 }}
                onClick={() => {
                  setGroupDataToBeUpdate(x);
                  setCreateGroupModal(true);
                }}
              />
            </Menu.Item>
          );
        })}
    </Menu>
  );

  let paramsForGroupListing = "SkipCount=0&MaxResultCount=100";
  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, []);

  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, [selectedGroupDataToBeUpdated, createGroupModal]);

  useEffect(() => {
    if (!isEmpty(selectTosNewCutGroup)) {
      let participantTos = selectTosNewCutGroup?.map((x) => {
        return {
          participantUserId: x,
        };
      });
      let participantCcs = selectCcsNewCutGroup?.map((x) => {
        return {
          participantUserId: x,
        };
      });
      setQuickMessageParticipantDtosTo(participantTos);
      setQuickMessageParticipantDtosCC(participantCcs);

      form.setFieldsValue({
        participantTos: selectTosNewCutGroup,
        participantCCs: selectCcsNewCutGroup,
      });
    } else {
      form.setFieldsValue({
        participantTos: [],
        participantCCs: [],
      });
    }
  }, [selectTosNewCutGroup, selectCcsNewCutGroup]);

  useEffect(() => {
    if (!isEmpty(selectedGroupDataToBeUpdated)) {
      form4.setFieldsValue({
        groupName: selectedGroupDataToBeUpdated?.groupName,
        userList: selectedGroupDataToBeUpdated?.userList?.map((x) => {
          return x?.userId;
        }),
      });
    }

    if (groupDataLoadingSuccess) {
      setCreateGroupModal(false);
      setSelectTosNewCutGroup();
    }
  });

  const onGroupCreateFinish = (values) => {
    const tos = values?.userListTo?.map((x) => {
      return {
        userId: x,
        participantTypeId: 0,
      };
    });
    const ccs = values?.userListCc?.map((x) => {
      return {
        userId: x,
        participantTypeId: 1,
      };
    });
    const userList = [...tos, ...ccs];

    let apiDataForGroupCreation = {
      groupName: values?.groupName,
      userList,
      isActive: true,
    };

    if (isEmpty(selectedGroupDataToBeUpdated)) {
      dispatch(actionForCreateGroup(apiDataForGroupCreation));
    } else {
      apiDataForGroupCreation = {
        ...apiDataForGroupCreation,
        id: selectedGroupDataToBeUpdated?.id,
      };
      dispatch(
        actionForUpdateGroup(
          apiDataForGroupCreation,
          selectedGroupDataToBeUpdated?.id
        )
      );
    }
  };

  const handleEditorFocus = () => {
    let data = editorData?.replace(par, "");
    if (replyStatus !== "reply" && replyStatus !== "replyall") {
      let participantsName = "";
      messageParticipants
        .filter((obj) =>
          form.getFieldsValue().participantTos?.includes(obj.appUserId)
        )
        .map((x, index) => {
          participantsName =
            participantsName +
            x?.userName
              ?.toUpperCase()
              .concat(
                index + 1 === form.getFieldsValue().participantTos?.length
                  ? ":"
                  : "/"
              );
        });
      setPar(participantsName);
      editorRef.current.insertContent(participantsName);
      setEditorData(participantsName + data);
    }
    editorRef.current.selection.setCursorLocation(
      editorRef.current.getBody(),
      1
    );
  };

  return (
    <div>
      {chatBox ? (
        <div className="chat--messageBox email_internal_chatbox">
          <div className="reply-Of chat-box-head-wrapper">
            <div className="left-chat-action-btn">
              <div className="chat-box-send-wrapper">
                {replyInstanceMessageRespLoading ? (
                  antIcon
                ) : (
                  <>
                    <Space>
                      <Layout.Content
                        overlay={centerTypeMenu}
                        onClick={() => {
                          let newMailMessageCenterType =
                            mailMessageCenterType.filter(
                              (type) => type.systemName === "Normal"
                            );
                          onFinish(newMailMessageCenterType[0].systemName);
                        }}
                      >
                        <img src={send} />
                      </Layout.Content>
                      <Dropdown.Button overlay={menu}></Dropdown.Button>
                      <Dropdown.Button
                        overlay={menuGropCreate}
                        icon={<UsergroupAddOutlined />}
                      ></Dropdown.Button>
                    </Space>
                  </>
                )}
              </div>
            </div>

            <div className="chat-action-btn">
              <div className="chat-box-editor-header-btn-wrapper">
                <div
                  className="btn__chat-editor-header"
                  onClick={() => {
                    setEditorHeader(!showEditorHeader);
                  }}
                  style={{
                    color: showEditorHeader ? "black" : "white",
                  }}
                >
                  {/* <img src={aa} /> */}
                  <span>Aa</span>
                </div>
              </div>

              <div>
                <Checkbox
                  onChange={(e) => {
                    setSelectTosInternal(!selectTosInternal);
                  }}
                >
                  To All
                </Checkbox>
              </div>
              <div className="chat-box-attachment-bth-wrapper">
                <div className="attachment-img-wrapper">
                  <Upload
                    name="uploadedFiles"
                    itemRender={(files) => {
                      <div style={{ display: "none" }}>{files}</div>;
                    }}
                    action={`${appBaseUrl}/file/uploadFile/`}
                    customRequest={uploadPhotos}
                    multiple={true}
                  >
                    <img src={attachment} />
                  </Upload>
                </div>
              </div>
              <div
                className="btn__chat--cancel"
                onClick={() => {
                  setchatBox(false);
                  setReplyofChat("");
                  setReplyStatus("");
                  setReplyChatHeight(false);
                  setActiveChat();
                  setSmallChatHeight(false);
                }}
              >
                <Tooltip title="close">
                  <img src={cancel} />
                </Tooltip>
              </div>
              <div
                className={`btn__chat--upChervon  ${
                  replyChatheight ? "active" : ""
                } `}
                onClick={() => {
                  setReplyChatHeight(!replyChatheight);
                  setActiveChat("");
                  setSmallChatHeight(replyChatheight ? true : false);
                }}
              >
                <Tooltip title={`${replyChatheight ? "Collapse" : "Expand"}`}>
                  <img src={upChervon} />
                </Tooltip>
              </div>
            </div>
          </div>

          <Form
            name="replyChatBox"
            onFinish={onFinish}
            onFinishFailed={(info) => console.log(info)}
            autoComplete="off"
            form={form}
          >
            <div
              className={`replyChatHeight ${replyChatheight ? "active" : ""} `}
            >
              <Col span={24}>
                <div className="instant-msg-box">
                  <div className="box-type">To</div>
                  <Form.Item
                    className="form__group"
                    name="participantTos"
                    rules={[{ required: true, message: "" }]}
                    style={{ width: "100%" }}
                  >
                    <Select
                      optionFilterProp="label"
                      bordered={false}
                      mode="multiple"
                      autoFocus={true}
                      placeholder="Internal To"
                      onChange={(value, option) =>
                        changeParticipantsDtosTo(option)
                      }
                    >
                      {messageParticipants.map((participant, j) => (
                        <Option
                          key={j}
                          value={participant?.appUserId}
                          label={[participant?.fullName, participant?.userName]}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">
                              {participant?.fullName} {"" + ""}
                            </span>
                            [{participant?.userName.toUpperCase()}]
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className="instant-msg-box">
                  <div className="box-type">Cc</div>

                  <Form.Item
                    className="form__group"
                    name="participantCCs"
                    rules={[{ required: false }]}
                    style={{ width: "100%" }}
                  >
                    {isFocused && filteredOptions?.length > 0 ? (
                      <>
                        <Space size={[0, 8]} wrap>
                          <CheckableTag disabled> Suggestion:</CheckableTag>
                          <Checkbox
                            onChange={(e) => {
                              setAllSuggestion(e.target.checked);
                            }}
                          >
                            All
                          </Checkbox>
                        </Space>

                        <Space size={[0, 8]} wrap>
                          {/* {allUserSuggestionResponse?.participaintCcs?.map( */}
                          {filteredOptions?.map((tag) => (
                            <CheckableTag
                              key={tag?.appUserId}
                              onChange={
                                (checked) => handleEmailSelect(tag.appUserId)
                                // changeParticipantsDtosCC(tag.appUserId)
                                // changeParticipantsDtosCC(tag)
                              }
                              style={{
                                background: "#ADD8E6",
                                width: "100%",
                                borderRadius: "50%",
                                marginTop: "3px",
                              }}
                            >
                              <span>{tag?.userName?.toUpperCase()}</span>
                            </CheckableTag>
                          ))}
                        </Space>
                      </>
                    ) : filteredOptions?.length === 0 || !isFocused ? (
                      ""
                    ) : (
                      ""
                    )}
                    <Select
                      bordered={false}
                      mode="multiple"
                      placeholder="Internal CC"
                      value={selectedEmails}
                      // onChange={(value, option) =>
                      //   changeParticipantsDtosCC(option)
                      // }
                      optionFilterProp="label"
                      //checking amit
                      onChange={(value) => {
                        setSelectedEmails(value);
                      }}
                      // onKeyDown={handleFocus}
                      onFocus={handleFocus}
                      onClick={() => {
                        let ppTo = [];
                        if (quickMessageParticipantDtosTo?.length > 0) {
                          quickMessageParticipantDtosTo?.map((x) => {
                            ppTo?.push(...ppTo, x?.participantUserId);
                          });
                          ppTo = [...new Set(ppTo)];
                          let appendUrl = "";
                          ppTo?.map((x) => {
                            appendUrl = appendUrl?.concat(
                              `ParticipaintTos=${x}&`
                            );
                          });
                          // console.log("ppTo", appendUrl);
                          dispatch(instanceMessageUserSuggestion(appendUrl));
                        }
                      }}
                    >
                      {messageParticipantsCC.map((participant, j) => (
                        <Option
                          key={j}
                          value={participant.appUserId}
                          label={[participant.fullName, participant.userName]}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">{participant.fullName}</span>[
                            {participant.userName}]
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col>
                {progress > 0 ? <Progress percent={progress} /> : null}
                {!isEmpty(form.getFieldValue().uploadedFileDtos) ? (
                  <div className="instant-msg-box">
                    {/* <div className="box-type">
                      <img src={attachment} />
                    </div> */}

                    <div className="uploadImg" style={{ width: "87%" }}>
                      <Form.Item
                        name={["uploadedFileDtos"]}
                        className="form__group"
                        // name={u.id}
                        // label={u.name}
                        rules={[{ required: false }]}
                      >
                        <ImageUploaderMultiple
                          name="emailMessageAttachmentDtos"
                          isMultipleFileAllowed={true}
                          id=""
                          uploadedFileDtos={form.getFieldValue()}
                          formName="uploadedFileDtos"
                          form={form}
                        />
                      </Form.Item>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col span={24}>
                <div className="instant-msg-box">
                  <div className="box-type" style={{ marginLeft: "5px" }}>
                    Msg
                  </div>
                  {/* <Button className=" quick_message_predefined_button" style={{marginTop:"6px"}} >
                      <PlusOutlined 
                      />
                    </Button> */}
                  <Select
                    bordered={false}
                    onSelect={(e, data) => {
                      setEditorData(data?.children);
                      // setMsgData(data?.children);
                    }}
                  >
                    {predefinedData?.map((data, index) => {
                      return <Option key={index}>{data?.message}</Option>;
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={24}>
                {/* <Form.Item name="description" className="form__group" label="Message"> */}
                {/* <Editor
                initialValue="<p>This is the initial content of the editor</p>"
                apiKey="zlyurpa97iti4svx0l8rc4nwgnqpm0s9459csq8ic2bxuo8f"
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help'
                }}
                onEditorChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorData(data);
                }}
              /> */}

                <div
                  className={`replyChatBox ${replyChatheight ? "active" : ""} ${
                    showEditorHeader ? "showHeader" : ""
                  } `}
                  style={{ position: "relative", width: "100%" }}
                >
                  <Editor
                    value={editorData}
                    // toolbar_location="bottom"
                    onKeyDown={(e) => {
                      const { key, altKey } = e;
                      if (altKey && key === "Enter") {
                        setAltEnt(true);
                      }
                    }}
                    onMouseEnter={() => setIsFocused(false)}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    init={{
                      plugins: "lists advlist link",
                      browser_spellcheck: true,
                      menubar: "false",
                      // toolbar: "", //bold  italic underLine  | backcolor forecolor   |  bullist numlist

                      content_style: "p{margin:0px}",
                      forced_root_block_attrs: {
                        style: "font-family: Arial; font-size: 10pt",
                        id: "internal_replyBox",
                      },
                      toolbar:
                        "fontselect fontsizeselect backcolor forecolor bold | italic | underLine lineheight |  strikethrough alignleft aligncenter alignright |  bullist numlist | link",

                      content_style: "p{margin:0px;padding:0px}",
                      forced_root_block: "div",
                      forced_root_block_attrs: {
                        style: "font-family: Arial; font-size: 10pt",
                        class: "editor-block-wrapper",
                        id: "editor-blocks",
                      },
                      font_formats:
                        "Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde;Arial Narrow=arial narrow,avant garde;Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier;Cambria=cambria;Calibri=calibri;Calibri Body=calibri boby;Georgia=georgia,palatino;Times New Roman=times new roman,times;Helvetica=helvetica;Verdana=verdana,geneva;",
                    }}
                    onEditorChange={(e, editor) => {
                      setEditorData(e);
                      const plainText = editor.getContent({ format: "text" });
                      setSMSText(plainText);
                    }}
                    id="replyChatBox"
                    onFocus={handleEditorFocus}
                  />
                </div>
                {/* </Form.Item> */}
              </Col>
            </div>
            {/* <div className="replyTo">
          MC
				  <a>
            <CloseOutlined />
          </a>
        </div> */}
          </Form>
        </div>
      ) : (
        <div
          className="chatmail__imag chat-button-wrapper"
          style={{
            position: "absolute",
            bottom: 0,
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            height: "15%",
            background: "#fff",
            width: "100%",
            display: "none",
          }}
        ></div>
      )}
      <Modal
        visible={createGroupModal}
        title={`${
          isEmpty(selectedGroupDataToBeUpdated)
            ? "Create Your Group"
            : "Update Your Group"
        }`}
        onCancel={() => setCreateGroupModal(false)}
        footer={null}
        height={300}
      >
        <Form {...layoutFormGroup} onFinish={onGroupCreateFinish} form={form4}>
          <Form.Item
            name="groupName"
            label="Group Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Name of your group" />
          </Form.Item>
          <Form.Item
            name="userListTo"
            label="To Users"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Users"
              allowClear
              autoComplete="off"
              mode="multiple"
              optionFilterProp="label"
            >
              {!isEmpty(messageParticipants) &&
                messageParticipants?.map((user, index) => {
                  return (
                    <Option
                      value={user?.appUserId}
                      key={index}
                      label={[user.fullName, user.userName]}
                    >
                      {user?.fullName} [{user?.userName.toUpperCase()}]
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item
            name="userListCc"
            label="Cc Users"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select Users"
              allowClear
              autoComplete="off"
              mode="multiple"
              optionFilterProp="label"
            >
              {!isEmpty(messageParticipants) &&
                messageParticipants?.map((user, index) => {
                  return (
                    <Option
                      value={user?.appUserId}
                      key={index}
                      label={[user.fullName, user.userName]}
                    >
                      {user?.fullName} [{user?.userName.toUpperCase()}]
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={groupDataLoading}>
            {isEmpty(selectedGroupDataToBeUpdated) ? "Submit" : "Update"}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default ReplyChatBox;
