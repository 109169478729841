import { message } from "antd";
import { GLOBAL, PROFILE_TYPES } from "../../types";
import { getDynamicFileLabelTypeUploaderDetail } from "../common/dynamicFileType/action";
import { ProfileSerice } from "./profile.api";
import { init, success, finish, error } from "services/common";
import { isEmpty } from "lodash";

const profileService = new ProfileSerice();

export const getGenders = () => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.GET_GENDER));
    const response = await profileService.getGenders();
    dispatch(finish(PROFILE_TYPES.GET_GENDER));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.GET_GENDER, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getOrganizationListing = () => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.GET_ORGANIZATIONLISTING));
    const response = await profileService.getorganizationListing();
    dispatch(finish(PROFILE_TYPES.GET_ORGANIZATIONLISTING));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.GET_ORGANIZATIONLISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const getOrganizationListing = () => {
//   return async (dispatch) => {
//     dispatch(init(PROFILE_TYPES.GET_ORGANIZATIONLISTING));
//     const response = await profileService.getorganizationListing();
//     dispatch(finish(PROFILE_TYPES.GET_ORGANIZATIONLISTING));
//     if (response.isSuccess) {
//       dispatch(success(response.data, PROFILE_TYPES.GET_ORGANIZATIONLISTING));
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

export const getMaritalStatusList = () => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.GET_MARITALSTATUS));
    const response = await profileService.getMaritalStatusList();
    dispatch(finish(PROFILE_TYPES.GET_MARITALSTATUS));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.GET_MARITALSTATUS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const employeeProfileDetail = (query, userType) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.GET_PROFILE));
    const response = await profileService.employeeProfileDetail(query);
    dispatch(finish(PROFILE_TYPES.GET_PROFILE));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.GET_PROFILE, response.data));
      if (userType === "loginUser") {
        dispatch({
          type: "LOGIN_USER_DTO",
          payload: response.data,
        });
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const employeeEvaluationProfileDetail = (query, userType) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.GET_EMPLOYEE_EVALUATION_PROFILE));
    const response = await profileService.employeeEvaluationProfileDetail(
      query
    );
    dispatch(finish(PROFILE_TYPES.GET_EMPLOYEE_EVALUATION_PROFILE));
    if (response.isSuccess) {
      dispatch(
        success(PROFILE_TYPES.GET_EMPLOYEE_EVALUATION_PROFILE, response.data)
      );
      if (userType === "loginUser") {
        dispatch({
          type: "LOGIN_USER_DTO",
          payload: response.data,
        });
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const employeeProfilePersonalUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.UPDATE_PROFILE));
    const response = await profileService.employeeProfilePersonalUpdate(body);
    dispatch(finish(PROFILE_TYPES.UPDATE_PROFILE));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.UPDATE_PROFILE, body));
      message.success(`Successfully updated the profile`);
      dispatch(
        employeeProfileDetail({
          AppUserId: response.data.appUserId,
        })
      );
      // dispatch(employeesPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const employeeProfileOfficalUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.UPDATE_PROFILE_OFFICIAL));
    const response = await profileService.employeeProfileOfficalUpdate(body);
    dispatch(finish(PROFILE_TYPES.UPDATE_PROFILE_OFFICIAL));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.UPDATE_PROFILE_OFFICIAL, body));
      message.success(`Successfully updated the profile`);
      dispatch(
        employeeProfileDetail({
          AppUserId: response.data.appUserId,
        })
      );
      // dispatch(employeesPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const employeesPagination = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.EMPLOYEES_PAGINATION));
    const response = await profileService.employeesPagination(query);
    dispatch(finish(PROFILE_TYPES.EMPLOYEES_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(PROFILE_TYPES.EMPLOYEES_PAGINATION, response.data, loadMore)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const userRegistrationAddUpdate = (body, leftCompany) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.USER_REGISTERATION));
    const response = await profileService.userRegistrationAddUpdate(body);
    dispatch(finish(PROFILE_TYPES.USER_REGISTERATION));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.USER_REGISTERATION, response.data));
      message.success(
        `Successfully ${body.appUserId ? "updated" : "registered"} the user`
      );
      if (leftCompany) {
        const response1 = await profileService.leftCompany(body?.appUserId);
      }
      // dispatch(employeesPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const documentFilesAddUpdate = (body, id) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.DOCUMENTS_UPLOAD_INIT));
    const response = await profileService.documentFilesAddUpdate(body, id);
    dispatch(finish(PROFILE_TYPES.DOCUMENTS_UPLOAD_FINISH));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.DOCUMENTS_UPLOAD_SUCCESS, response.data));
      message.success(`Successfully updated the documents`);
      dispatch(
        employeeProfileDetail({
          AppUserId: id,
        })
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const employeeInfoDelete = (id) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.USER_REGISTERATION));
    const response = await profileService.employeeDelete(id);
    dispatch(finish(PROFILE_TYPES.USER_REGISTERATION));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.USER_REGISTERATION, response.data));
      message.success("User deleted successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// Employee email list
export const getEmployeeEmailList = (id) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.EMPLOYEE_EMAIL_LIST));
    const response = await profileService.getEmployeeEmailList(id);
    dispatch(finish(PROFILE_TYPES.EMPLOYEE_EMAIL_LIST));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.EMPLOYEE_EMAIL_LIST, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// email signature add
export const emailSignatureAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.EMAIL_SIGNATURE_ADD));
    const response = await profileService.emailSignatureAdd(body);
    dispatch(finish(PROFILE_TYPES.EMAIL_SIGNATURE_ADD));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.EMAIL_SIGNATURE_ADD, body));
      message.success(`Successfully Saved the signature`);
      // dispatch(
      // 	employeeProfileDetail({
      // 		AppUserId: response.data.appUserId,
      // 	})
      // );
      // dispatch(employeesPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// active email singnature details
export const activeEmailSignatureDetails = (query) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.ACTIVE_EMAIL_SIGNATURE_DETAILS));
    const response = await profileService.activeEmailSignatureDetails(query);
    dispatch(finish(PROFILE_TYPES.ACTIVE_EMAIL_SIGNATURE_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(PROFILE_TYPES.ACTIVE_EMAIL_SIGNATURE_DETAILS, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// email signature update

export const emailSignatureUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.EMAIL_SIGNATURE_UPDATE));
    const response = await profileService.emailSignatureUpdate(id, body);
    dispatch(finish(PROFILE_TYPES.EMAIL_SIGNATURE_UPDATE));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.EMAIL_SIGNATURE_UPDATE, response.data));
      message.success(`Successfully updated the signature`);
      dispatch(employeeProfileDetail());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// email mark as default
export const emailMarkAsDefault = (id) => async (dispatch) => {
  dispatch(init(PROFILE_TYPES.EMAIL_MARK_AS_DEFAULT));
  const response = await profileService.emailMarkAsDefault(id);
  if (response.isSuccess) {
    dispatch(success(PROFILE_TYPES.EMAIL_MARK_AS_DEFAULT, response.data));
    dispatch(finish(PROFILE_TYPES.EMAIL_MARK_AS_DEFAULT));
    message.success(`Successfully marked as default`);
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

//IsCheckInbox Mark As Active and Inactive

export const isCheckInboxValue = (id, body) => async (dispatch) => {
  dispatch(init(PROFILE_TYPES.ISCHECKINBOX_MARK_AS_UPDATE));
  const response = await profileService.isCheckInboxValue(id, body);
  if (response.isSuccess) {
    dispatch(success(PROFILE_TYPES.ISCHECKINBOX_MARK_AS_UPDATE, response.data));
    dispatch(finish(PROFILE_TYPES.ISCHECKINBOX_MARK_AS_UPDATE));
    message.success(`Successfully marked as default`);
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const joiningMessage = (id) => async (dispatch) => {
  dispatch(init(PROFILE_TYPES.COMPOSE_JOINING_MESSAGE));
  const response = await profileService.composeWelcomeMessage(id);
  dispatch(finish(PROFILE_TYPES.COMPOSE_JOINING_MESSAGE));
  if (response.isSuccess) {
    dispatch(success(PROFILE_TYPES.COMPOSE_JOINING_MESSAGE));
    message.success(`Successfully sent joining Message`);
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const leftCompanyMain = (id) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.LEFT_COMPANY));
    const response = await profileService.leftCompany(id);
    dispatch(finish(PROFILE_TYPES.LEFT_COMPANY));
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.LEFT_COMPANY, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const clearanceFormAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.CLEARANCE_FORM_ADD));
    const response = await profileService.clearanceFormAdd(body);
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.CLEARANCE_FORM_ADD, response.data));
      message.success(`Successfully added equiment`);
      console.log("action", response.isSuccess);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(PROFILE_TYPES.CLEARANCE_FORM_ADD));
  };
};

export const clearanceFormList = (id) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.CLEARANCE_FORM_GET));
    const response = await profileService.clearanceFormList(id);
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.CLEARANCE_FORM_GET, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(PROFILE_TYPES.CLEARANCE_FORM_GET));
  };
};

export const questionTypeList = () => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.QUESTION_TYPE_GET));
    const response = await profileService.questionTypeList();
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.QUESTION_TYPE_GET, response.data));
    } else if (!response.errorMessage) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(PROFILE_TYPES.QUESTION_TYPE_GET));
  };
};

export const questionTypeAdd = (body, actionType) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.QUESTION_TYPE_ADD));

    if (actionType === "add") {
      const response = await profileService.questionTypeAdd(body);
      if (response.isSuccess) {
        dispatch(success(PROFILE_TYPES.QUESTION_TYPE_ADD, response.data));
        message.success("Interview Question added");
      }
    } else if (actionType === "update") {
      const response = await profileService.questionTypeAdd(body);
      if (response.isSuccess) {
        dispatch(success(PROFILE_TYPES.QUESTION_TYPE_ADD, response.data));
        message.success("Updated");
      }
    } else if (actionType === "delete") {
      const response = await profileService.questionTypeAdd(body);
      if (response.isSuccess) {
        dispatch(success(PROFILE_TYPES.QUESTION_TYPE_ADD, response.data));
        message.success("Deleted");
      }
    }
    // if (response.isSuccess) {
    //   dispatch(success(PROFILE_TYPES.QUESTION_TYPE_ADD, response.data));
    //   if (updateData) {
    //     message.success("Interview Question Updated");
    //   } else {
    //     message.success("Added ExitInterview Form");
    //   }
    // } else if (!response.errorMessage) {
    //   dispatch(error(response.errorMessage));
    // }
    dispatch(finish(PROFILE_TYPES.QUESTION_TYPE_ADD));
  };
};

export const questionTypeGet = () => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.QUESTION_TYPE_QUESTION_GET));
    const response = await profileService.questionTypeGet();
    if (response.isSuccess) {
      dispatch(
        success(PROFILE_TYPES.QUESTION_TYPE_QUESTION_GET, response.data)
      );
    } else if (!response.errorMessage) {
      dispatch(error(response.errorMessage));
    }
    // dispatch(finish(PROFILE_TYPES.QUESTION_TYPE_QUESTION_GET));
  };
};

export const exitInteviewGet = (id) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.EXIT_INTERVIEW_GET));
    const response = await profileService.exitInterviewGet(id);
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.EXIT_INTERVIEW_GET, response.data));
    } else if (!response.errorMessage) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(PROFILE_TYPES.EXIT_INTERVIEW_GET));
  };
};

export const exitInterviewAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.EXIT_INTERVIEW_ADD));
    const response = await profileService.exitInterviewPost(body);
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.EXIT_INTERVIEW_ADD, response.data));
      message.success("Added Supervisor");
    } else if (!response.errorMessage) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(PROFILE_TYPES.EXIT_INTERVIEW_ADD));
  };
};

export const getEmployeeReview = (id) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.EMPLOYEE_REVIEW_GET));
    const response = await profileService.getEmployeeReview(id);
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.EMPLOYEE_REVIEW_GET, response.data));
    } else if (!response.errorMessage) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(PROFILE_TYPES.EMPLOYEE_REVIEW_GET));
  };
};

// Mail
export const supervisorEmployeeMail = (
  id,
  body,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.EMPLOYEE_SUPERVISOR_MAIL));
    const response = await profileService.employeeEvaluationMail(id, body);
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.EMPLOYEE_SUPERVISOR_MAIL, response.data));
      dispatch(finish(PROFILE_TYPES.EMPLOYEE_SUPERVISOR_MAIL));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    // console.log("response log", response);
    setInstanceMessageCenterListing?.((prevData) => {
      if (!isEmpty(prevData.items)) {
        return {
          items: [response?.data?.internalMessage, ...prevData.items],
          totalCount: prevData.totalCount + 1,
        };
      } else {
        return {
          items: [response?.internalMessage],
          totalCount: 1,
        };
      }
    });
  };
};

// employeeEvaluationSelfAction
export const actionForAddEmployeeEvaluationSelfAction = (id, body, setInstanceMessageCenterListing) => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.EMPLOYEE_EVALUATION_SELF_ACTION));
    const response = await profileService.addEmployeeEvaluationSelfAction(id, body);
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.EMPLOYEE_EVALUATION_SELF_ACTION, response.data));
      dispatch(finish(PROFILE_TYPES.EMPLOYEE_EVALUATION_SELF_ACTION));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }

    setInstanceMessageCenterListing?.((prevData) => {
      if (!isEmpty(prevData.items)) {
        return {
          items: [response?.data?.internalMessage, ...prevData.items],
          totalCount: prevData.totalCount + 1,
        };
      } else {
        return {
          items: [response?.internalMessage],
          totalCount: 1,
        };
      }
    });
  }
}

// SUPER API:
export const actionForGetAllUsersPP = () => {
  return async (dispatch) => {
    dispatch(init(PROFILE_TYPES.LISTING_ALL_USER_PROFILE));
    const response = await profileService.getAllUsersPP();
    if (response.isSuccess) {
      dispatch(success(PROFILE_TYPES.LISTING_ALL_USER_PROFILE, response.data));
    } else if (!response.errorMessage) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(PROFILE_TYPES.LISTING_ALL_USER_PROFILE));
  };
};
