import { Button, Drawer, Form, Input, Layout, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addApplicationTemplate,
  updateApplicationTemplate,
} from "services/redux/organizer/ApplicationTemplate/action";

export default function ApplicationTemplateAddUpdateForm({
  formVisibility,
  setOpenForm,
  updateData,
  setUpdateData,
}) {
  const dispatch = useDispatch();

  const {
    addApplicationTemplateResponse,
    addApplicationTemplateResponseLoading,
    updateApplicationTemplateResponse,
    updateApplicationTemplateResponseLoading,
  } = useSelector((state) => state.application);
  const { Header, Footer } = Layout;

  const [visibleModal, setVisibleModal] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (value) => {
    // console.log(value)
    if (updateData) {
      let body = { ...value, isActive: true };
      dispatch(updateApplicationTemplate(updateData?.id, body));
    } else {
      dispatch(addApplicationTemplate(value));
    }
    form.resetFields();
    setOpenForm(false);
  };
  useEffect(() => {
    form.setFieldsValue({
      code: updateData?.code,
      title: updateData?.title,
      body: updateData?.body,
    });
  }, [updateData]);

  return (
    <>
      <Drawer
        visible={formVisibility}
        width={551}
        maskClosable={false}
        className='drawer'
        closable={false}
      >
        <Header className='drawer__header'>
          <h4 className='drawer__header--title'>
            {`${updateData ? "Update " : "Add "}Application Template`}
          </h4>
        </Header>
        <Form
          form={form}
          name='control-hooks'
          className='drawer-form-wrapper'
          onFinish={onFinish}
        >
          <div className='drawer-form-row'>
            <div className='drawer-form-group'>
              <Form.Item
                className='form__group'
                name='title'
                label='Title'
                rules={[{ required: true }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </div>
            <div className='drawer-form-group'>
              <Form.Item
                className='form__group'
                name='code'
                label='Code'
                rules={[{ required: true }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </div>
            <div className='drawer-form-group application_template_code_input'>
              <Form.Item
                className='form__group'
                name='body'
                label='Body'
                rules={[{ required: true }]}
              >
                <Input.TextArea autoComplete='off' height={90} />
              </Form.Item>
            </div>
            {updateData && (
              <div>
                <Button
                  onClick={() => {
                    setVisibleModal(true);
                  }}
                >
                  View Modal
                </Button>
              </div>
            )}
          </div>
          <div className='model-footer'>
            <Button
              onClick={() => {
                setOpenForm(false);
                setUpdateData("");
              }}
            >
              Cancel
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={addApplicationTemplateResponseLoading}
            >
              Submit
            </Button>
          </div>
        </Form>
        <Modal
          visible={visibleModal}
          footer={null}
          onCancel={() => {
            setVisibleModal(false);
          }}
          width={600}
        >
          {updateData && (
            <div>
              <p className='mail-iframe-wrapper'>
                <iframe
                  scroll='no'
                  frameBorder='0'
                  // id="editor_blocks_details"
                  //   ref={gridIframe}
                  className={`meetingInfos email-iframe highlight-email-iframe`}
                  srcDoc={updateData?.body}
                  //   onLoad={handleIframe}
                  // target="_blank"
                  id='printf'
                  name='printf'
                  height={200}
                />
              </p>
            </div>
          )}
        </Modal>
      </Drawer>
    </>
  );
}
