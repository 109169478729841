import React, { useRef } from "react";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { FilterOutlined, PlusOutlined, StarFilled } from "@ant-design/icons";
import {
  Button,
  Input,
  Select,
  Menu,
  Dropdown,
  Form,
  Modal,
  message,
  Slider,
  Space,
  Radio,
  Tabs,
  Row,
  Col,
  Collapse,
  Skeleton,
  Image,
  Tooltip,
} from "antd";
import moment from "moment";
import { groupChatReadStatus } from "utils/isReadGroupMail";
import { DisplayItemsWithTooltip } from "components/GroupChat/DisplayItemsWithTooltip";
import { getHalfDetailsTag } from "utils/getHalfDetailsTag";

function GroupChatList(props) {
  let {
    loading,
    data,
    selectedGroupId,
    selectedGroupData,
    setSelectedGroupId,
    setSelectedGroupData,
    setSearchGroupTitle,
    setIsDrawerVisible,
    searchKeyword,
    setSearchKeyword,
    isAdvanceClear,
    setIsAdvanceClear,
  } = props;

  const { Panel } = Collapse;
  const scrollableRef = useRef();
  const unreadDot = "assets/svg/unread.svg";

  // let isReadCurrentSelectMail = groupChatReadStatus(selectedGroupData);
  const testJson = [
    {
      referenceId: "58e2e9cd-9701-45d0-ab9e-7ba124afd7fa",
      referenceCode: "MAY21",
    },
    {
      referenceId: "d9d7a2c8-3090-42f8-8a9f-9ca26e12c111",
      referenceCode: "Huddersfield Town A.F.C.",
    },
    {
      referenceId: "d6ab151b-c868-4ec0-bda7-27985bce596c",
      referenceCode: "T2024-009",
    },
    {
      referenceId: "74d911e9-4b08-43f1-8307-250197ebf441",
      referenceCode: "ID-MAR",
    },
  ];

  return (
    <>
      <div className="layout">
        <div className="organizer contacts">
          <div className="contactDetails__components">
            <div
              className="contactDetails__split"
              style={{ display: "flex", width: "100%" }}
            >
              <div className="contactDetails__split--pagination">
                <Row className="events__buttons">
                  <Col lg={24}>
                    <div className="ContactDetails__header">
                      <h3 className="header__all">GroupChat</h3>
                    </div>
                  </Col>
                </Row>

                <div className="search-wrapper">
                  {isAdvanceClear === true ? (
                    <>
                      <Input.Search
                        className="form__group_email_search"
                        style={{ margin: 0 }}
                        size="medium"
                        bordered={false}
                        value={"[Advance Search]"}
                      />
                      <button
                        onClick={() => {
                          // resetInputField();
                          setIsAdvanceClear(false);
                          // setIsFavorite(true);
                          // form.resetFields();
                        }}
                        className="cross-section-advance-filter"
                      >
                        &#10006;
                      </button>
                    </>
                  ) : (
                    <Input.Search
                      className="form__group"
                      style={{ margin: 0 }}
                      placeholder="Search"
                      size="medium"
                      allowClear
                      onSearch={(e) => {
                        setSearchKeyword(e);
                        // setIsFavorite(!isFavorite);
                      }}
                      // onKeyDown={onChangeStarFilled}
                      // onChange={(e) => {
                      //     setInputSearchValue(e.target.value);
                      // }}
                      autoComplete="off"
                    />
                  )}

                  <div
                    className="side__search__wrapper side-contact-search-filter"
                    style={{ cursor: "pointer" }}
                    // onClick={showModal}
                    onClick={() => setIsAdvanceClear(true)}
                  >
                    <FilterOutlined />
                  </div>

                  <div className="contactDetails__dropdown">
                    <Button
                      className="contact__header__btn-primary"
                      onClick={() => {
                        // setEditPerson("add");
                        // sethidden(true);
                        // personForm.resetFields();
                        // expertForm.resetFields();
                        // personNotesForm.resetFields();
                        // form.resetFields();
                        // showDrawer();
                        setIsDrawerVisible(true);
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  </div>
                </div>

                <div className="profiledetails">
                  <Collapse
                    expandIconPosition="right"
                    accordion={true}
                    defaultActiveKey={["1"]}
                  >
                    <Panel key="1" header="All Group list">
                      <div id="scrollableGroupChat" ref={scrollableRef}>
                        {loading || loading === null ? (
                          <Skeleton paragraph={{ rows: 10 }} active />
                        ) : (
                          !isEmpty(data?.items) && (
                            <InfiniteScroll
                              // height="300px"
                              height="100vh"
                              scrollThreshold="50px"
                              dataLength={data.items.length}
                              hasMore={data.totalCount > data.items.length}
                              // next={getMoreData}
                              // loader={<Skeleton paragraph={{ rows: 4 }} active />}
                              scrollableTarget="scrollableGroupChat"
                              endMessage={
                                <p style={{ textAlign: "center" }}>
                                  {data?.items?.length > 5 ? (
                                    <b>End of blog</b>
                                  ) : null}
                                </p>
                              }
                            >
                              {data?.items?.map((item, index) => (
                                <div
                                  key={item?.id}
                                  className={`emailList__card ${
                                    selectedGroupId === item?.id ? "active" : ""
                                  }`}
                                  onClick={() => {
                                    setSelectedGroupId(item?.id);
                                    setSelectedGroupData(item);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <div className="emailList__info mailbox__info emailList-item">
                                    <div className="emailList__right">
                                      <div className="emailList__right--details">
                                        <div className="emailList__card--smallTitle email-title-icon">
                                          {!item?.isGroupChatRead ? (
                                            <Image
                                              src={unreadDot}
                                              style={{ width: 9 }}
                                              preview={false}
                                            />
                                          ) : (
                                            ""
                                          )}

                                          <div
                                            className="PaginationEmail"
                                            style={{
                                              marginLeft: "15px",
                                            }}
                                          >
                                            {item.chatGroupName}
                                          </div>
                                        </div>
                                        <div className="emailList__card--date">
                                          {item.creationTime &&
                                          moment(item.creationTime).format(
                                            "YYYY"
                                          ) !==
                                            moment(new Date()).format("YYYY")
                                            ? moment(item.creationTime).format(
                                                "DD/MM/YYYY"
                                              )
                                            : moment(
                                                item.creationTime
                                              ).calendar({
                                                sameDay: "h:mm a",
                                                nextDay: "[Tomorrow]",
                                                nextWeek: "DD MMM",
                                                lastDay: "[Yesterday]",
                                                lastWeek: "DD MMM",
                                                sameElse: "DD MMM",
                                              })}
                                        </div>
                                      </div>
                                      <div className="emailList__card--title email-title-icon">
                                        <div className="text-left"></div>
                                        <div className="text-right text-title">
                                          {item.latestMessage &&
                                            item?.latestMessage}
                                        </div>
                                        <div className="email-title-icon"></div>
                                      </div>
                                      <div className="email-title-icon reference-Mail">
                                        <div className="text-left"></div>
                                        <div className="text-left"></div>
                                        <div
                                          className="emailList__card--refComp text-right"
                                          style={{
                                            width: "300px",
                                          }}
                                        >
                                          <div className="flex-box">
                                            {!isEmpty(
                                              item?.otherReferenceDtos
                                            ) && (
                                              <div className="emailList__card--ref ">
                                                {getHalfDetailsTag(
                                                  item?.otherReferenceDtos
                                                )}
                                                {/* <DisplayItemsWithTooltip
                                                  items={
                                                    item?.otherReferenceDtos
                                                  }
                                                /> */}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </InfiniteScroll>
                          )
                        )}
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupChatList;
