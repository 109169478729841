import React, { useState } from "react";
import { Form, Modal, Table } from "antd";
import { WechatOutlined } from "@ant-design/icons";
import InternalMCompose from "components/internalMNew";

const BudgetBack = ({ rotate, setRotate, columns, dataSource1 }) => {
  const [form] = Form.useForm();

  const [internalVisible, setInternalVisible] = useState(false);

  return (
    <>
      <div className="flip-card-back">
        <div className=" honor-wrapper" style={{ paddingRight: 0 }}>
          <div
            className=" dashboards__events dashboards__body "
            style={{ height: 380 }}
          >
            <div className="dashboards__bodyWrap">
              <h4 className="dashboards__body--title">
                {/* Budget/Expense/Income/Rent Excluded */}
                Budget vs Actual (Rent Excluded)
                <span
                  onClick={() => {
                    setRotate(rotate + 1);
                  }}
                  style={{
                    marginLeft: "10px",
                    height: "30px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src="./assets/svg/toggle-arrow.svg"
                    height={15}
                    alt="complete"
                  />
                </span>
              </h4>
              <WechatOutlined
                onClick={() => {
                  setInternalVisible(true);
                }}
              />
            </div>
            <Table
              className="next-organizer-table"
              columns={columns}
              dataSource={dataSource1}
              pagination={false}
              style={{ maxHeight: 320, overflow: "auto" }}
            />
          </div>
        </div>
      </div>
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="Budget/Expense/Income/Rent"
        />
      </Modal>
    </>
  );
};

export default BudgetBack;
