import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Typography } from "antd";
import { LogoImage } from "utils/imageConstants";
import { currencyPagination } from "services/redux/contact/contact.action";

const CurrencyAddUpdateTable = ({ showAddUpdateCurrencyDrawer }) => {
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Currency",
      dataIndex: "currencyName",
      key: "currencyName",
    },
    {
      title: "Abbrevation",
      dataIndex: "abbvr",
      key: "abbvr",
    },
    {
      title: "Exchange Rate",
      dataIndex: "exchangeRate",
      key: "exchangeRate",
      render: (_, record) => {
        return <>{record.exchangeRate.toFixed(3)}</>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a
          className="action-btn action-btn-add"
          onClick={(e) => showAddUpdateCurrencyDrawer(e, record)}
        >
          Edit Currency
        </a>
      ),
    },
  ];

  const dispatch = useDispatch();
  const { currencyPaginationResponse, currencyPaginationLoading } = useSelector(
    (state) => state.contact
  );

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!currencyPaginationResponse) return;
    setdata(currencyPaginationResponse.items);
    setTotalCount(currencyPaginationResponse.totalCount);
  }, [currencyPaginationResponse]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType
  ) => {
    dispatch(
      currencyPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
      })
    );
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };

  return (
    <Table
      className="organizer__tables"
      columns={columns}
      rowKey="appUserId"
      dataSource={data}
      onChange={handleTableChange}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" alt="loading" />
        ),
        spinning: currencyPaginationLoading,
      }}
      pagination={{
        total: totalCount,
        pageSize: pageSize,
      }}
    />
  );
};

export default CurrencyAddUpdateTable;
