import React, { useEffect, useState } from "react";

// antd
import { Table, Space, Tag } from "antd";

// utils
import { LogoImage } from "utils/imageConstants";

// redux
import { getOrganizationLayerPagination } from 'services/redux/adminSettings/organizationStructure/action';
import { useDispatch, useSelector } from "react-redux";

const OrganizationLayerTable = ({
  addEditOrganizationLayer
}) => {
  const columns = [
    {
      title: "S.NO",
      key: "sno",
      render: (text, object, index) => index + 1,
      width:"5%",

    },
    {
      title: "Organization Layer Name",
      dataIndex: "organizationLayerName",
      key: "organizationLayerName",
    },
    {
      title: "Designation Name",
      key: "designationName",
      render: (record) => (
        <span>{record.designationDto?.designationName}</span>
      )
    },
    {
      title: "Parent Organization Layer Name",
      key: "designationName",
      render: (record) => (
        <span>{record.parentOrganizationLayerDto?.organizationLayerName}</span>
      )
    },
    {
      title: "Status",
      key: "isActive",
      render: (tag) => {
        let color = '';
        let status = '';
        if (tag.isActive) {
          color = 'green';
          status = 'Active';
        } else {
          color = 'red';
          status = 'InActive';
        }
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space size="middle">
          <a
            className='action-btn action-btn-add'
            onClick={() => addEditOrganizationLayer(record)}
          >
            Edit Layer
          </a>
          {/* <a
            className='action-btn action-btn-add'
            onClick={(e) => showUpdateProfileDrawer(e, record.appUserId)}>
            Edit Profile
          </a> */}
        </Space>

      ),
    },
  ];

  const dispatch = useDispatch();

  const {
    organizationLayerPagination,
    organizationLayerPaginationLoading
  } = useSelector(state => state.organizationStructure);

  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    setData(organizationLayerPagination.items);
    setTotalCount(organizationLayerPagination.totalCount)
  }, [organizationLayerPagination]);

  useEffect(() => {
    getData();
  }, [])

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    // SearchKeyword
  ) => {
    dispatch(
      getOrganizationLayerPagination(
        {
          MaxResultCount,
          SkipCount,
          // SearchKeyword
        }
      )
    );
  };

  // useEffect(() => {
  //   if (!employeesPaginationResponse) return;
  //   setdata(employeesPaginationResponse.items);
  //   setTotalCount(employeesPaginationResponse.totalCount);
  // }, [employeesPaginationResponse]);

  // const handleTableChange = (pagination, filters, sorter, extra) => {
  //   let Sorting = sorter.columnKey
  //   let SortType = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : undefined;
  //   let MaxResultCount = pageSize;
  //   let SkipCount = (pagination.current - 1) * pagination.pageSize;

  //   getData(MaxResultCount, SkipCount, Sorting, SortType);
  //   // getData(pagination.current, pagination.pageSize);
  // };

  return (
    <Table className="contact-table"
      columns={columns}
      rowKey="appUserId"
      dataSource={data}
      // onChange={handleTableChange}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" />
        ),
        spinning: organizationLayerPaginationLoading,
      }}
      pagination={{
        total: totalCount,
        pageSize: pageSize,
      }}
    />
  );
};

export default OrganizationLayerTable;
