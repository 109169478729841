import React, { useState, useEffect } from "react";
import {
  // Collapse,
  Form,
  // DatePicker,
  // TimePicker,
  // Button,
  Row,
  Col,
  Checkbox,
  Select,
  // Input,
  // Typography,
  // List,
  Table,
  Card,
  Layout,
} from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
// import {
//   PlusCircleOutlined,
//   MinusCircleOutlined,
//   UploadOutlined,
// } from "@ant-design/icons";
// import { isEmpty } from "lodash";
import DynamicImageUploader from "components/shared/DynamicImageUploader";
import AllFileViewer from "components/Documents/allFileViewer";

import "./style.css";
import { cqBaseUrl } from "utils/config";
import { isEmpty } from "lodash";

const ROTO = ({
  dynamicROTOFileTypesState,
  singleStageForm,
  setTenderStageROTOFileList,
  rotoFiles,
  token,
}) => {
  const [form] = Form.useForm();
  // const { Option } = Select;
  const [setForm, setOpenForm] = useState(false);
  const [isCheckboxOpen, setIsCheckboxOpen] = useState(false);

  const onSelect = (e) => {
    if (e.target.checked === true) {
      setOpenForm(true);
      setIsCheckboxOpen(true);
    } else {
      setOpenForm(false);
      setIsCheckboxOpen(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(rotoFiles)) {
      setOpenForm(true);
      setIsCheckboxOpen(true);
    }
  }, [rotoFiles]);

  const docRotoColumns = [
    {
      title: "S.N.",
      dataIndex: "sn",
    },
    {
      title: "Filename",
      dataIndex: "displayFileName",
    },
    {
      title: "Action",
      render: (text, record) => {
        return (
          <>
            <Layout.Content
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <AllFileViewer
                isShowFileName={false}
                file={record}
                cqBaseUrl={cqBaseUrl}
                token={token}
                height={600}
                width={900}
              />
              {/* <a
                target="_blank"
                download
                href={cqBaseUrl + "/" + record?.viewFileURL + token}
                rel="noreferrer"
              >
                <EyeOutlined />
              </a> */}
              {/* <a
                target="_blank"
                download
                href={cqBaseUrl + "/" + record?.viewFileURL + token}
                rel="noreferrer"
              >
                <DeleteOutlined />
              </a> */}
            </Layout.Content>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="edittender__header">
        <div className="edittender__header--text">
          <span className="edittender__primaryText">ROTO</span>
          <span className="edittender__secondaryText">
            (Would you like to add documents)
          </span>
        </div>

        <div className="edittender__header--checkbox">
          <Checkbox
            onChange={onSelect}
            checked={isCheckboxOpen}
            // checked={
            //   !isEmpty(editDocumentPurchase) &&
            //   editDocumentPurchase[i] === "true"
            //     ? true
            //     : false
            // }
          />
        </div>
      </div>

      {setForm && (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            {dynamicROTOFileTypesState.map((u, i) => (
              <div className="profiledetails__rowedit" key={u.id}>
                <div className="profiledetails__rowedit--label">
                  <span className="profile__bodyTitle">ROTO</span>
                </div>
                <div className="profiledetails__rowedit--data">
                  <Form.Item
                    name={["tenderStageROTOFileList"]}
                    className="form__group"
                    rules={[{ required: false }]}
                  >
                    <DynamicImageUploader
                      name={u.name}
                      isMultipleFileAllowed={u.isMultipleFileAllowed}
                      id={u.id}
                      allowedExtensions={u.allowedExtensions}
                      // fileList={fileList}
                      // setFileList={setFileList}
                      index={i}
                      form={singleStageForm}
                      callback={(e) => {
                        let olduploadedFileDtos = form.getFieldValue()[
                          "tenderStageROTOFileList"
                        ]
                          ? [
                              ...singleStageForm.getFieldValue()[
                                "tenderStageROTOFileList"
                              ],
                            ]
                          : [];

                        let uploadedFileDtos = [...olduploadedFileDtos];
                        uploadedFileDtos.push(e);

                        singleStageForm.setFieldsValue({
                          ["tenderStageROTOFileList"]: uploadedFileDtos,
                        });
                        setTenderStageROTOFileList(uploadedFileDtos);
                        // form.setFieldsValue({
                        //   uploadedFileDtos: [
                        //     ...olduploadedFileDtos,
                        //     e
                        //   ]
                        //     })
                      }}
                    />

                    {rotoFiles?.length > 0 && (
                      <Card style={{ marginTop: "10px", marginLeft: "10px" }}>
                        <Table
                          columns={docRotoColumns}
                          dataSource={rotoFiles?.map((x, idx) => {
                            return {
                              ...x,
                              sn: idx + 1,
                            };
                          })}
                          pagination={false}
                          height={50}
                        />
                      </Card>
                    )}
                  </Form.Item>
                </div>
              </div>
            ))}
          </Col>
        </Row>

        //for button of Add Address
      )}
    </div>
  );
};

export default ROTO;
