import { init, success, finish, error } from "services/common";
import { FolderService } from "./api";
import { FOLDER_SERVICE_TYPES } from "services/types";

const folderService = new FolderService();

export const getFolderList = () => {
  return async (dispatch) => {
    dispatch(init(FOLDER_SERVICE_TYPES.FOLDER_LIST));
    const response = await folderService.getDocumentListing();
    if (response.isSuccess) {
      dispatch(success(FOLDER_SERVICE_TYPES.FOLDER_LIST, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(FOLDER_SERVICE_TYPES.FOLDER_LIST, response.data));
  };
};

export const getAllChildFolderList = (id, sorter) => {
  return async (dispatch) => {
    dispatch(init(FOLDER_SERVICE_TYPES.CHILD_FOLDER_LIST));
    const response = await folderService.getAllChildFolders(id, sorter);
    if (response.isSuccess) {
      dispatch(success(FOLDER_SERVICE_TYPES.CHILD_FOLDER_LIST, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(FOLDER_SERVICE_TYPES.CHILD_FOLDER_LIST, response.data));
  };
};

export const createNewFolder = (body) => {
  return async (dispatch) => {
    dispatch(init(FOLDER_SERVICE_TYPES.CREATE_FOLDER));
    const response = await folderService.addFolder(body);
    if (response.isSuccess) {
      dispatch(success(FOLDER_SERVICE_TYPES.CREATE_FOLDER, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(FOLDER_SERVICE_TYPES.CREATE_FOLDER, response.data));
  };
};

export const uploadNewFile = (body) => {
  return async (dispatch) => {
    dispatch(init(FOLDER_SERVICE_TYPES.UPLOAD_FILE));
    const response = await folderService.uploadFiles(body);
    if (response.isSuccess) {
      dispatch(success(FOLDER_SERVICE_TYPES.UPLOAD_FILE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(FOLDER_SERVICE_TYPES.UPLOAD_FILE, response.data));
  };
};
