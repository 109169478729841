import { EMPLOYEE_EVALUATION_TYPES } from "../../../types";

const INITIAL_STATE = {
  addEmployeeEvaluationResp: [],
  addEmloyeeEvaluationLoading: false,
  getEmployeeEvaluationListingResp: [],

  getEmployeeEvaluationResp: [],
  getEmployeeEvaluationLoading: false,

  // mail
  employeeSupervisorMailLoading: false,
  employeeSupervisorMailResp: [],
  employeeSupervisorMailListingResp: [],
};

export default function employeeEvaluationReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_ADD_INIT:
      return {
        ...state,
        addEmloyeeEvaluationLoading: true,
      };

    case EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_ADD_SUCCESS:
      const updatedItem = state.getEmployeeEvaluationListingResp.items
        ? state.getEmployeeEvaluationListingResp.items.reduce(
            (accumulator, item) => {
              if (item.id === action.payload.id) {
                if (action.payload.isactive) {
                  return [...accumulator, action.payload];
                } else {
                  return accumulator;
                }
              } else {
                return [...accumulator, item];
              }
            },
            []
          )
        : [];

      const isNewItem = !updatedItem.some(
        (item) => item && item.id === action.payload.id
      );
      if (isNewItem && action.payload.isactive) {
        updatedItem.push(action.payload);
      }
      return {
        ...state,
        getEmployeeEvaluationResp: action.payload,
        questionTypeAddLoading: false,
        getEmployeeEvaluationListingResp: {
          ...state.getEmployeeEvaluationListingResp,
          items: updatedItem.filter((item) => item !== null),
        },
      };

    case EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_ADD_FINISH:
      return {
        ...state,
        addEmloyeeEvaluationLoading: false,
      };

    case EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_GET_INIT:
      return {
        ...state,
        getEmployeeEvaluationListingResp: [],
        getEmployeeEvaluationLoading: true,
      };

    case EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_GET_SUCCESS:
      return {
        ...state,
        getEmployeeEvaluationListingResp: action.payload,
        getEmployeeEvaluationLoading: false,
      };

    case EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_GET_FINISH:
      return {
        ...state,
        getEmployeeEvaluationLoading: false,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
