import React, { useState } from "react";
import { Modal, Button } from "antd";
import {
  EyeOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FileViewer = ({ file, cqBaseUrl, token, width, height }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const isPdf = file.fileName.toLowerCase().endsWith(".pdf");
  const isImage = /\.(jpg|jpeg|png|gif)$/i.test(file.fileName);

  const handleOpenFile = async () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfLoaded(true);
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `${cqBaseUrl}/${file.viewFileURL}${token}`;
    link.target = "_blank";
    link.download = "downloaded.pdf";
    link.click();
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <EyeOutlined
          style={{ cursor: "pointer", marginLeft: "8px" }}
          onClick={handleOpenFile}
        />
        <div
          className="hr_fileName"
          style={{
            maxWidth: "140px",
            textOverflow: "ellipsis",
            color: "red",
            overflow: "hidden",
            marginLeft: "6px",
            // background: "gray",
          }}
        >
          {file?.displayFileName}
        </div>
      </div>

      <Modal
        visible={modalVisible}
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Document Viewer</span>
            <span onClick={handleCloseModal}>
              <CloseOutlined />
            </span>
          </div>
        }
        footer={null}
        closable={false}
        onCancel={handleCloseModal}
        // height="400px"
        // width="40%"
        // style={{
        //   height: height ? height : "80%",
        //   width: width ? width : "40%",
        //   overflow: "auto",
        // }}
      >
        {modalVisible && (
          <>
            {isPdf ? (
              <Document
                file={`${cqBaseUrl}/${file.viewFileURL}${token}`}
                onLoadSuccess={onDocumentLoadSuccess}
                onClick={handleOpenFile}
              >
                <Page key={`page_${currentPage}`} pageNumber={currentPage} />
              </Document>
            ) : isImage ? (
              <img
                src={`${cqBaseUrl}/${file.viewFileURL}${token}`}
                alt="File"
                // style={{ maxWidth: width, height: height }}
              />
            ) : (
              <div>Unsupported file type. You can download the file:</div>
            )}
            <Button type="primary" onClick={handleDownload}>
              Download File
            </Button>
            {isPdf && (
              <>
                <Button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <LeftOutlined /> Previous Page
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === numPages}
                >
                  Next Page <RightOutlined />
                </Button>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default FileViewer;
