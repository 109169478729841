import { Divider, Layout, Tabs, Typography } from "antd";
// import DemoContent from "./demoContent";

const { Content } = Layout;
const { Title } = Typography;

export default function MainBodyHeader({ dataFromSideBody }) {
  return (
    <>
      <Title level={4} style={{ margin: "12px 0px 0px 6px" }} strong>
        Job Application
      </Title>
    </>
  );
}
