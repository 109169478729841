import { useSelector } from "react-redux";

const { Button } = require("antd");

export default function ButtonForForm({
  setOpenApplicantDrawer,
  setSelectedSingleApplicantData,
  formApplicantEdu,
  formApplicantsBasic,
  formApplicantJob,
  formApplicantReview,
  // setActiveTabIndexofAppicant,
}) {
  // const { applicationSingleDataSuccess } = useSelector(
  //   (state) => state.hrSystemServicesReducer
  // );
  const { applicationSingleDataSuccess } = useSelector(
    (state) => state.hrSystemServicesReducer
  );

  // console.log("applicantTabSuccess", applicantTabSuccess);

  const handleCancel = () => {
    formApplicantJob.resetFields();
    formApplicantsBasic.resetFields();
    formApplicantEdu.resetFields();
    formApplicantReview.resetFields();
    setOpenApplicantDrawer(false);
    setSelectedSingleApplicantData();
  };
  return (
    <>
      <div style={{ float: "right", marginTop: "5px" }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginRight: "10px" }}
          loading={applicationSingleDataSuccess}
        >
          Save
        </Button>

        <Button type="ghost" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
}
