import { useEffect } from "react";
import { Layout, Drawer } from "antd";

import { useDispatch } from "react-redux";
import ApplicantFormGE from "./FormsLevel/ApplicantFormGE";
import { getAmmountListing } from "services/redux/requistions/leaveType/action";

const { Header } = Layout;

export const GuestEntertaintmentForm = ({
  drawerVisibility,
  onAddUpdateDrawerClose,
  isUpdateApplication,
  setAddUpdateLeaveDrawerVisibility,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAmmountListing());
  }, []);

  return (
    <div>
      <Drawer
        width={500}
        maskClosable={false}
        onClose={onAddUpdateDrawerClose}
        visible={drawerVisibility}
        closable={false}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">Guest Entertainment</h4>
        </Header>

        <ApplicantFormGE
          setAddUpdateLeaveDrawerVisibility={setAddUpdateLeaveDrawerVisibility}
        />
      </Drawer>
    </div>
  );
};
