import Axios from "axios";
import queryString from "query-string";
import { rootBaseUrl, appBaseUrl } from "../utils/config";

// profile

export const getAllRoles = () => {
  return Axios.get(rootBaseUrl + "/identity/roles/all");
};

export const getAllUsers = () => {
  return Axios.get(
    appBaseUrl + "/organizationStructure/userReportingManagerUserListing"
  );
};

export const getAllContactPerson = () => {
  return Axios.get(appBaseUrl + "/contactRequest/contactPersonListing");
};

export const countryListing = () => {
  return Axios.get(appBaseUrl + "/contactRequest/countryListing");
};

export const contactLabelGroupListing = () => {
  return Axios.get(appBaseUrl + "/contactRequest/contactLabelGroupListing");
};

export const searchTagListing = () => {
  return Axios.get(appBaseUrl + "/contactRequest/searchTagListing");
};

export const scopeOfWorkListing = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(
    appBaseUrl + `/contactRequest/scopeOfWorkSearchListing?${query}`
  );
};

export const scopeofWorkAdd = (body) => {
  return Axios.post(appBaseUrl + "/contactRequest/scopeofWorkListAdd", body);
};

export const searchTagAdd = (body) => {
  return Axios.post(appBaseUrl + "/contactRequest/searchTagAdd", body);
};

export const contactLabelListing = () => {
  return Axios.get(appBaseUrl + "/contactRequest/contactLabelListing");
};

export const contactSourceListing = () => {
  return Axios.get(appBaseUrl + "/contactRequest/contactSourceListing");
};

export const cityListing = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(appBaseUrl + `/contactRequest/cityListing?${query}`);
};

export const contactFavoriteCompanyAddUpdate = (body) => {
  return Axios.post(
    appBaseUrl + `/contactRequest/contactFavoriteCompanyAddUpdate`,
    body
  );
};

export const contactFavoritePersonAddUpdate = (body) => {
  return Axios.post(
    appBaseUrl + `/contactRequest/contactFavoritePersonAddUpdate`,
    body
  );
};

// Files
export const dynamicFileLabelTypeUploaderDetailDtos = (q) => {
  return Axios.get(
    appBaseUrl +
    `/file/dynamicFileLabelTypeUploaderDetailDtos?fileLabelTypeGroupName=${q}`
  );
};
export const sectorListing = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(appBaseUrl + `/referenceManagement/sectorListing?${query}`);
};

export const sectorsPagination = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(appBaseUrl + `/referenceManagement/sectorsPagination`);
};


export const qualificationListing = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(
    appBaseUrl + `/contactRequest/contactQualificationListing?${query}`
  );
};

export const statusListing = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(
    appBaseUrl + `/referenceManagement/projectStatusListing?${query}`
  );
};

export const modalityListing = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(
    appBaseUrl + `/referenceManagement/modalityListing?${query}`
  );
};

export const contactCompanyListing = (q) => {
  const query = queryString.stringify(q);
  return Axios.post(appBaseUrl + `/contactRequest/companyListing?${query}`);
};

export const tenderProjectListing = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(
    appBaseUrl + `/referenceManagement/tenderBasicProjectListing?${query}`
  );
};

export const messageApplicationTypeListing = () => {
  return Axios.get(
    appBaseUrl + "/mailMessageCenter/mailMessageApplicationTypeListing"
  );
};
// export const loginAttendance = () => {
//   return Axios.post(appBaseUrl + `/attendance/CheckInDailyAttendance`);
// };

//for xl attendance
export const uploadExcelAttendance = (resp) => {
  return Axios.post(appBaseUrl + `/attendance/importExcelAttendance`);
};

// upload Contact attendence
export const uploadContactExcel = (resp) => {
  return Axios.post(appBaseUrl + '/importExcelFile');
}

export const logOutAttendance = () => {
  return Axios.post(appBaseUrl + `/attendance/CheckOutDailyAttendance`);
};

export const attendancePagination = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(appBaseUrl + `/attendance/AttendancePagination?${query}`);
};

export const getMonthlyAttendance2 = (q) => {
  const query = queryString.stringify(q);

  return Axios.get(
    appBaseUrl + `/attendance/monthlyGetAttendanceDetails?${query}`
  );
};
//old api listing
export const attendancePaginationByUser = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(
    appBaseUrl + `/attendance/attendancePaginationByType?${query}`
  );
};
// new api listing
export const attendancePaginationUserLogin = (q) => {
  const query = queryString.stringify(q);
  return Axios.get(
    appBaseUrl + `/attendance/attendancePaginationByTypeByLeaveDetails?${query}`
  );
};

// export const attendancePaginationByAbsentList = (q) => {
//   const query = queryString.stringify(q);
//   return Axios.get(appBaseUrl + `/attendance/attendancePaginationByTypeByLeaveDetails?${query}`);
// };

export const uploadFile = ({
  action,
  file,
  filename,
  onError,
  onProgress,
  onSuccess,
  setProgress,
}) => {
  // console.log("ActionName", action)
  try {
    const formData = new FormData();
    formData.append(filename, file);
    const request = Axios.post(action, formData, {
      "Content-Type": "multipart/form-data",
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    });
    return request;
    // onSuccess(res, file)
  } catch (err) {
    // onError(err)
    // dispatch({ type: GLOBAL_ERROR, payload: err || "Not Found" });
  }
};

export const uploadFileInternal = ({
  action,
  file,
  filename,
  onError,
  onProgress,
  onSuccess,
  setProgressInternal,
}) => {
  try {
    const formData = new FormData();
    formData.append(filename, file);
    const request = Axios.post(action, formData, {
      "Content-Type": "multipart/form-data",
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgressInternal(percent);
        if (percent === 100) {
          setTimeout(() => setProgressInternal(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    });
    return request;
    // onSuccess(res, file)
  } catch (err) {
    // onError(err)
    // dispatch({ type: GLOBAL_ERROR, payload: err || "Not Found" });
  }
};

export const uploadFileWithNoProgress = ({
  action,
  file,
  filename,
  onError,
  onProgress,
  onSuccess,
}) => {
  try {
    const formData = new FormData();
    formData.append(filename, file);
    const request = Axios.post(action, formData, {
      "Content-Type": "multipart/form-data",
      // onUploadProgress: ({ total, loaded }) => {
      //     onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
      // }
    });
    return request;
    // onSuccess(res, file)
  } catch (err) {
    // onError(err)
    // dispatch({ type: GLOBAL_ERROR, payload: err || "Not Found" });
  }
};

export const honorRollGetDetails = (id) => {
  return Axios.get(appBaseUrl + `/organizerEvent/honorRollGetDetails/${id}`);
};

// export const uploadPictire = ({
//   action,
//   file,
//   filename,
//   onError,
//   onProgress,
//   onSuccess,
//   setProgress
// }) => {
//   try {
//     const formData = new FormData();
//     formData.append(filename, file);
//     const request = Axios.post(action, formData, {
//       "Content-Type": "multipart/form-data",
//       onUploadProgress: (event) => {
//         const percent = Math.floor((event.loaded / event.total) * 100);
//         setProgress(percent);
//         if (percent === 100) {
//           setTimeout(() => setProgress(0), 1000);
//         }
//         onProgress({ percent: (event.loaded / event.total) * 100 });
//       },
//     });
//     return request;
//     // onSuccess(res, file)
//   } catch (err) {
//     // onError(err)
//     // dispatch({ type: GLOBAL_ERROR, payload: err || "Not Found" });
//   }
// };
