import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Checkbox } from "antd";

import {
  actionForGetTenderCheckList,
  actionForAddUpdatedCircularChecklist,
} from "services/redux/Reference/Tender/action";
import CheckListPresentation from "./CheckListPresentation";

function CheckListContainer(props) {
  const { title, content, referenceId } = props;

  const [data, setData] = useState([]);

  const {
    tenderCircularPlanningCheckListResponse,
    tenderCircularPlanningCheckListLoading,
    addUpdateCircularCheckListResponse,
  } = useSelector((state) => state.tender);

  const dispatch = useDispatch();

  useEffect(() => {
    if (referenceId) dispatch(actionForGetTenderCheckList(referenceId));
  }, [referenceId]);

  useEffect(() => {
    setData(tenderCircularPlanningCheckListResponse);
  }, [tenderCircularPlanningCheckListResponse]);

  const handleCheckbox = (record) => {
    if (!referenceId || !record?.id) {
      console.error("Invalid referenceId or record.id");
      return null;
    }

    const apiData = {
      referenceId,
      checklistid: record?.id,
      isactive: record && !record.isactive,
    };

    dispatch(actionForAddUpdatedCircularChecklist(apiData));
  };

  const columns = [
    {
      title: "S.N",
      render: (text, row, index) => (
        <div style={{ paddingLeft: 5 }}>{index + 1}</div>
      ),
      width: "15%",
    },
    {
      title: "Name",
      dataIndex: "category",
      key: "category",
      render: (text, record) => (
        <Checkbox
          checked={record?.isactive}
          onClick={() => handleCheckbox(record)}
        >
          {text}
        </Checkbox>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <>
      {/* CheckListContainer */}
      <CheckListPresentation
        columns={columns}
        data={data}
        loading={tenderCircularPlanningCheckListLoading}
      />
    </>
  );
}

export default CheckListContainer;
