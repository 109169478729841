import { message } from "antd";
import { TODO_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { StatusEventListingService } from "./statuslisting.api";

const statusEventListingService = new StatusEventListingService();

export const getStatusEventListing = () => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.STATUS_LISTING));
    const response = await statusEventListingService.getStatusEventListing();
    dispatch(finish(TODO_TYPES.STATUS_LISTING));
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.STATUS_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateStatusListing = (body) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.UPDATE_STATUS_LISTING));
    const response = await statusEventListingService.updateStatusListing(body);
    dispatch(finish(TODO_TYPES.UPDATE_STATUS_LISTING));
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.UPDATE_STATUS_LISTING, response.data));
      dispatch(getStatusEventListing());
      message.success('Status Event Listing updated successfully');
    }
    else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
