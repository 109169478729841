import { ADMIN_SETTINGS_TYPES } from "../../../../types";
import { init, success, finish, error } from "services/common";
import { OrganizationLayerTypeService } from "./api";

const organizationLayerTypeService = new OrganizationLayerTypeService();

export const getOrganizationLayerTypeListing = () => {
  return async (dispatch) => {
    dispatch(init(ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_TYPE_LISTING));
    const response = await organizationLayerTypeService.getOrganizationLayerTypeListing();
    dispatch(finish(ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_TYPE_LISTING));
    if (response.isSuccess) {
      dispatch(success(ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_TYPE_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const addAppointmentEvent = (body) => {
//   return async (dispatch) => {
//     dispatch(init(ORGANIZER_TYPES.ADD_APPOINTMENT));
//     const response = await roleService.addAppointmentEvent(body);
//     dispatch(finish(ORGANIZER_TYPES.ADD_APPOINTMENT));
//     if (response.isSuccess) {
//       dispatch(success(ORGANIZER_TYPES.ADD_APPOINTMENT, response.data));
//       dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
//       message.success('Appointment Event added successfully');
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

// export const updateAppointmentEvent = (body, id) => {
//   return async (dispatch) => {
//     dispatch(init(ORGANIZER_TYPES.ADD_APPOINTMENT));
//     const response = await roleService.updateAppointmentEvent(body, id);
//     dispatch(finish(ORGANIZER_TYPES.ADD_APPOINTMENT));
//     if (response.isSuccess) {
//       dispatch(success(ORGANIZER_TYPES.ADD_APPOINTMENT, response.data));
//       dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
//       message.success('Appointment Event updated successfully');
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

// export const appointmentMarkAsComplete = (id) => {
//   return async (dispatch) => {
//     dispatch(init(ORGANIZER_TYPES.APPOINTMENT_COMPLETE));
//     const response = await roleService.appointmentMarkAsComplete(id);
//     dispatch(finish(ORGANIZER_TYPES.APPOINTMENT_COMPLETE));
//     if (response.isSuccess) {
//       dispatch(success(ORGANIZER_TYPES.APPOINTMENT_COMPLETE, response.data));
//       dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
//       let status = response.data.markItOff ? 'complete' : 'incomplete';
//       message.success(`Appointment marked as ${status} successfully`);
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };