import { error, init, success } from "services/common"
import { APPLICATION_TEMPLATE } from "../../../types";
import { ApplicationService } from "./api";

const applicationService = new ApplicationService();

export const addApplicationTemplate = (value)=>{
    return async (dispatch)=>{
        dispatch(init(APPLICATION_TEMPLATE.ADD_APPLICATION_TEMPLATE))
        const response =await applicationService.addApplicationTemplate(value);
        if(response?.isSuccess){
            dispatch(success(APPLICATION_TEMPLATE.ADD_APPLICATION_TEMPLATE,response?.data));
            dispatch(getApplicationTemplate())
        }else{
            dispatch(error(response?.message))
        }
    }
} 

export const getApplicationTemplate = ()=>{
    return async(dispatch)=>{
        dispatch(init(APPLICATION_TEMPLATE.GET_APPLICATION_TEMPLATE))
        const response = await applicationService.getApplicationTemplate();
        if(response?.isSuccess){
            dispatch(success(APPLICATION_TEMPLATE.GET_APPLICATION_TEMPLATE , response?.data))
        }else{
            dispatch(error(response?.message))
        }
    }
}

export const updateApplicationTemplate = (id,body)=>{
    return async(dispatch)=>{
        dispatch(init(APPLICATION_TEMPLATE.UPDATE_APPLICATION_TEMPLATE));
        const response = await applicationService.updateApplicationTemplate(id,body);
        if(response?.isSuccess){
            dispatch(success(APPLICATION_TEMPLATE.UPDATE_APPLICATION_TEMPLATE , response?.data));
            dispatch(getApplicationTemplate())
        }else{
            dispatch(error(response?.message))
        }
    }
}