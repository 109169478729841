import { Button, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGuestEntertainment } from "services/redux/organizer/guestEvents/action";
import getColumnsGuest from "./Columns";
import { EyeOutlined } from "@ant-design/icons";

const GuestEntertainmentTable = ({
  setGuestEntertainmentId,
  setShowGuestEntertainmentDetails,
  setHideGuestEntertainmentDrawer,
  guestEntertainmentId,
  setGuestEntertainmentDetails,
}) => {
  const dispatch = useDispatch();
  const {
    allGuestEventsListingResponse,
    allGuestEventsListingResponseLoading,
  } = useSelector((state) => state.guestEvents);

  const [totalCount, setTotalCount] = useState(10);
  const [sourceData, setSourceData] = useState([]);
  const pageSize = 10;

  useEffect(() => {
    dispatch(getAllGuestEntertainment());
  }, []);

  useEffect(() => {
    if (!allGuestEventsListingResponse?.items) return;
    setSourceData(allGuestEventsListingResponse?.items);
    setTotalCount(allGuestEventsListingResponse?.totalCount);
  }, [allGuestEventsListingResponse?.items]);

  useEffect(() => {
    const getGuestEntertainmentData =
      allGuestEventsListingResponse?.items?.filter((item) => {
        if (item.id == guestEntertainmentId) return item;
      });
    setGuestEntertainmentDetails(getGuestEntertainmentData);
  }, [guestEntertainmentId]);

  const columns = [
    ...getColumnsGuest(),
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Button
          htmlType="submit"
          style={{ border: 0, backgroundColor: "transparent" }}
          icon={<EyeOutlined />}
          onClick={() => {
            console.log(
              "ID CONSOLE",
              record?.id,
              setHideGuestEntertainmentDrawer,
              setShowGuestEntertainmentDetails
            );
            setHideGuestEntertainmentDrawer(true);
            setShowGuestEntertainmentDetails(true);
            setGuestEntertainmentId(record?.id);
          }}
        />
      ),
    },
  ];

  const getData = (MaxResultCount = pageSize, SkipCount = 0) => {
    let appendUrl = `skipCount=${SkipCount}`;
    dispatch(getAllGuestEntertainment(appendUrl));
  };

  const handleTableChange = (pagination) => {
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;
    getData(MaxResultCount, SkipCount);
  };

  return (
    <>
      <Table
        style={{ padding: "10px" }}
        className="next-organizer-table"
        columns={columns}
        dataSource={sourceData}
        onChange={handleTableChange}
        pagination={{
          total: totalCount,
          pageSize: pageSize,
        }}
      />
    </>
  );
};

export default GuestEntertainmentTable;
