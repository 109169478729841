import { PROFILE_TYPES } from "../../types";
import { uniqBy } from "lodash";

const INITIAL_STATE = {
  organizationListing: [],
  organizationLoading: false,
  genders: [],
  maritalStatusList: [],
  gendersLoading: false,
  maritalStatusListLoading: false,
  profileInfo: null,
  profileInfoLoading: false,
  employeeProfileInfo: null,
  employeeProfileInfoLoading: false,
  updateProfile: null,
  updateProfileOfficial: null,
  updateProfileLoading: false,
  updateProfileOfficialLoading: false,
  employeesPaginationResponse: { items: [], totalCount: 1 },
  employeesPaginationResponseLoading: false,
  documentUploadResp: [],
  documentUploadLoading: false,
  employeeEmailListLoading: false,
  employeeEmailListResponse: null,
  // email signature add
  emailSignatureAddResponse: null,
  emailSignatureAddLoading: false,
  //   active email signature details
  activeEmailSignatureDetailsResponse: null,
  activeEmailSignatureDetailsLoading: false,
  emailSignatureUpdateResponse: null,
  emailSignatureUpdateLoading: false,

  // email mark as default
  markEmailAsDefaultResponse: null,
  markEmailAsDefaultLoading: false,

  // IsCheckInbox mark as Active and Inactive

  markIsCheckInboxValueResponse: null,
  markIsCheckInboxValueLoading: false,

  markIsCheckInboxUpdateResponse: null,
  markIsCheckInboxUpdateLoading: false,

  clearanceFormListingResponse: [],
  clearanceFormResponse: [],
  clearanceFormLoading: false,

  questionTypeGetResponse: [],
  questionTypeGetLoading: false,

  exitInterviewGetResponse: [],
  exitInterviewGetLoading: false,

  exitInterviewAddResonse: [],
  exitInterviewAddLoading: false,

  employeeReviewResponse: [],
  employeeReviewLoading: false,
  employeeReviewResponseListing: [],

  employeeEvaluationSelfActionResp: [],
  employeeEvaluationSelfActionLoading: false,

  // super api
  listingAllUsersProfileResp: [],
  listingAllUsersProfileLoading: false,

  loginUserDto: null,
};

export default function profileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // gender list
    case PROFILE_TYPES.GET_GENDER_INIT:
      return {
        ...state,
        genders: [],
        gendersLoading: true,
      };
    case PROFILE_TYPES.GET_GENDER_SUCCESS:
      // console.log("gendersssss", action.payload);
      return {
        ...state,
        genders: action.payload,
        gendersLoading: false,
      };
    case PROFILE_TYPES.GET_GENDER_FINISH:
      return {
        ...state,
        gendersLoading: false,
      };

    // marital status list
    case PROFILE_TYPES.GET_MARITALSTATUS_INIT:
      return {
        ...state,
        maritalStatusList: [],
        maritalStatusListLoading: true,
      };
    case PROFILE_TYPES.GET_MARITALSTATUS_SUCCESS:
      // console.log("martialStatus", action.payload);
      return {
        ...state,
        maritalStatusList: action.payload,
        maritalStatusListLoading: false,
      };
    case PROFILE_TYPES.GET_MARITALSTATUS_FINISH:
      return {
        ...state,
        maritalStatusListLoading: false,
      };

    // profile
    case PROFILE_TYPES.GET_PROFILE_INIT:
      return {
        ...state,
        profileInfo: null,
        profileInfoLoading: true,
      };
    case PROFILE_TYPES.GET_PROFILE_SUCCESS:
      // console.log("minimun", action.payload);
      // console.log("Profile reducer: GET_PROFILE_SUCCESS", action.payload);
      return {
        ...state,
        profileInfo: action.payload,
        profileInfoLoading: false,
      };

    case PROFILE_TYPES.GET_PROFILE_FINISH:
      return {
        ...state,
        profileInfoLoading: false,
      };

    // get profile employee evaluation
    case PROFILE_TYPES.GET_EMPLOYEE_EVALUATION_PROFILE_INIT:
      return {
        ...state,
        employeeProfileInfo: null,
        employeeProfileInfoLoading: true,
      };

    case PROFILE_TYPES.GET_EMPLOYEE_EVALUATION_PROFILE_SUCCESS:
      return {
        ...state,
        employeeProfileInfo: action.payload,
        employeeProfileInfoLoading: false,
      };

    case PROFILE_TYPES.EMPLOYEE_EVALUATION_SELF_ACTION_INIT:
      return {
        ...state,
        employeeEvaluationSelfActionResp: [],
        employeeEvaluationSelfActionLoading: true,
      }

    case PROFILE_TYPES.EMPLOYEE_EVALUATION_SELF_ACTION_SUCCESS:
      return {
        ...state,
        employeeEvaluationSelfActionResp: action.payload,
        employeeEvaluationSelfActionLoading: false,
      }

    case PROFILE_TYPES.EMPLOYEE_EVALUATION_SELF_ACTION_FINISH:
      return {
        ...state,
        employeeEvaluationSelfActionLoading: false,
      }


    case PROFILE_TYPES.GET_ORGANIZATIONLISTING_FINISH:
      return {
        ...state,
        employeeEmailListLoading: false,
      };

    case "LOGIN_USER_DTO":
      return {
        ...state,
        loginUserDto: action.payload,
      };

    // update profile
    case PROFILE_TYPES.UPDATE_PROFILE_INIT:
      return {
        ...state,
        updateProfile: null,
        updateProfileLoading: true,
      };
    case PROFILE_TYPES.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfile: action.payload,
        profileInfo: state.profileInfo
          ? {
            ...state.profileInfo,
            employeePersonalDetailUpdateDto: {
              ...state.profileInfo.employeePersonalDetailUpdateDto,
              ...action.payload,
            },
          }
          : {},
        updateProfileLoading: false,
      };
    case PROFILE_TYPES.UPDATE_PROFILE_FINISH:
      return {
        ...state,
        updateProfileLoading: false,
      };

    // update profile official
    case PROFILE_TYPES.UPDATE_PROFILE_OFFICIAL_INIT:
      return {
        ...state,
        updateProfileOfficial: null,
        updateProfileOfficialLoading: true,
      };

    case PROFILE_TYPES.UPDATE_PROFILE_OFFICIAL_SUCCESS:
      return {
        ...state,
        updateProfileOfficial: action.payload,
        profileInfo: state.profileInfo
          ? {
            ...state.profileInfo,
            employeeOfficialDetailUpdateDto: {
              ...state.profileInfo.employeeOfficialDetailUpdateDto,
              ...action.payload,
            },
          }
          : {},
        updateProfileOfficialLoading: false,
      };
    case PROFILE_TYPES.UPDATE_PROFILE_OFFICIAL_FINISH:
      return {
        ...state,
        updateProfileOfficialLoading: false,
        employeesPaginationResponseLoading: true,
      };

    // user registeration
    case PROFILE_TYPES.USER_REGISTERATION_INIT:
      return {
        ...state,
        userRegisteration: null,
        userRegisterationLoading: true,
      };
    case PROFILE_TYPES.USER_REGISTERATION_SUCCESS:
      return {
        ...state,
        userRegisteration: action.payload,
        employeesPaginationResponse: {
          items: state.employeesPaginationResponse.items.map((items) => {
            // var fullName = action.payload.firstname.concat(
            //   action.payload.surname
            // );

            let fullName = `${action.payload.firstname} ${action.payload.surname}`;
            if (items.appUserId === action.payload.appUserId) {
              return {
                appUserId: action.payload.appUserId,
                phoneMobile: action.payload.phoneNumber,
                hireDate: action.payload.hireDate,
                employeeName: fullName,
                userName: action.payload.userName,
              };
            } else {
              // console.log("same");
              return {
                ...items,
              };
            }
          }),
          totalCount: state.employeesPaginationResponse.totalCount,
        },
        userRegisterationLoading: false,
        employeesPaginationResponseLoading: false,
      };

    case PROFILE_TYPES.USER_REGISTERATION_FINISH:
      return {
        ...state,
        updateProfileLoading: false,
        userRegisterationLoading: false,
      };

    // employee pagination
    case PROFILE_TYPES.EMPLOYEES_PAGINATION_INIT:
      return {
        ...state,
        employeesPaginationResponseLoading: true,
      };
    case PROFILE_TYPES.EMPLOYEES_PAGINATION_SUCCESS:
      // console.log("forpaginationempployee", action);
      return {
        ...state,
        employeesPaginationResponse: {
          items: action.loadMore
            ? uniqBy(
              state.employeesPaginationResponse?.items.concat(
                action.payload.items
              ),
              "appUserId"
            )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
        employeesPaginationResponseLoading: false,
      };
    case PROFILE_TYPES.EMPLOYEES_PAGINATION_FINISH:
      return {
        ...state,
        employeesPaginationResponseLoading: false,
      };

    //organizationListing
    case PROFILE_TYPES.GET_ORGANIZATIONLISTING_INIT:
      return {
        ...state,
        organizationListing: [],
        organizationLoading: true,
      };
    case PROFILE_TYPES.GET_ORGANIZATIONLISTING_SUCCESS:
      // console.log("payloadorganization", action);

      return {
        ...state,
        organizationListing: action.payload,
        organizationLoading: false,
      };
    case PROFILE_TYPES.GET_ORGANIZATIONLISTING_FINISH:
      return {
        ...state,
        organizationLoading: false,
      };

    // document upload
    case PROFILE_TYPES.DOCUMENTS_UPLOAD_INIT:
      return {
        ...state,
        documentUploadResp: [],
        documentUploadLoading: true,
      };
    case PROFILE_TYPES.DOCUMENTS_UPLOAD_SUCCESS:
      return {
        ...state,
        documentUploadResp: action.payload,
        documentUploadLoading: false,
      };
    case PROFILE_TYPES.DOCUMENTS_UPLOAD_FINISH:
      return {
        ...state,
        documentUploadLoading: false,
      };

    // employee email listing
    case PROFILE_TYPES.EMPLOYEE_EMAIL_LIST_INIT:
      return {
        ...state,
        employeeEmailListResponse: null,
        employeeEmailListLoading: true,
      };
    case PROFILE_TYPES.EMPLOYEE_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        employeeEmailListResponse: action.payload,
        employeeEmailListLoading: false,
      };
    case PROFILE_TYPES.EMPLOYEE_EMAIL_LIST_FINISH:
      return {
        ...state,
        employeeEmailListLoading: false,
      };

    // email signature add

    case PROFILE_TYPES.EMAIL_SIGNATURE_ADD_INIT:
      return {
        emailSignatureAddResponse: null,
        emailSignatureAddLoading: true,
      };
    case PROFILE_TYPES.EMAIL_SIGNATURE_ADD_SUCCESS:
      return {
        ...state,
        emailSignatureAddResponse: action.payload,
        emailSignatureAddLoading: false,
      };
    case PROFILE_TYPES.EMAIL_SIGNATURE_ADD_FINISH:
      return {
        emailSignatureAddResponse: null,
        emailSignatureAddLoading: false,
      };

    // active email signature details
    case PROFILE_TYPES.ACTIVE_EMAIL_SIGNATURE_DETAILS_INIT:
      return {
        ...state,
        activeEmailSignatureDetailsResponse: null,
        activeEmailSignatureDetailsLoading: true,
      };
    case PROFILE_TYPES.ACTIVE_EMAIL_SIGNATURE_DETAILS_SUCCESS:
      return {
        ...state,
        activeEmailSignatureDetailsResponse: action.payload,
        activeEmailSignatureDetailsLoading: false,
      };
    case PROFILE_TYPES.ACTIVE_EMAIL_SIGNATURE_DETAILS_FINISH:
      return {
        ...state,
        activeEmailSignatureDetailsLoading: false,
      };

    // update Signature
    case PROFILE_TYPES.EMAIL_SIGNATURE_UPDATE_INIT:
      return {
        emailSignatureUpdateResponse: null,
        emailSignatureUpdateLoading: true,
      };
    case PROFILE_TYPES.EMAIL_SIGNATURE_UPDATE_SUCCESS:
      return {
        ...state,
        emailSignatureUpdateResponse: action.payload,
        emailSignatureUpdateLoading: false,
      };
    case PROFILE_TYPES.EMAIL_SIGNATURE_UPDATE_FINISH:
      return {
        emailSignatureUpdateResponse: null,
        emailSignatureUpdateLoading: false,
      };

    case PROFILE_TYPES.EMAIL_MARK_AS_DEFAULT_INIT:
      return {
        ...state,
        markEmailAsDefaultLoading: true,
      };
    case PROFILE_TYPES.EMAIL_MARK_AS_DEFAULT_SUCCESS:
      return {
        ...state,
        markEmailAsDefaultResponse: action.payload,
        markEmailAsDefaultLoading: false,
      };

    case PROFILE_TYPES.EMAIL_MARK_AS_DEFAULT_FINISH:
      return {
        ...state,
        markEmailAsDefaultLoading: false,
        markEmailAsDefaultResponse: null,
      };
    case PROFILE_TYPES.ISCHECKINBOX_MARK_AS_UPDATE_INIT:
      return {
        ...state,
        markIsCheckInboxUpdateLoading: true,
      };
    case PROFILE_TYPES.ISCHECKINBOX_MARK_AS_UPDATE_SUCCESS:
      return {
        ...state,
        markIsCheckInboxUpdateResponse: action.payload,
        markIsCheckInboxUpdateLoading: false,
      };

    case PROFILE_TYPES.ISCHECKINBOX_MARK_AS_UPDATE_FINISH:
      return {
        ...state,
        markIsCheckInboxUpdateLoading: false,
        markIsCheckInboxUpdateResponse: null,
      };
    case PROFILE_TYPES.COMPOSE_JOINING_MESSAGE_SUCCESS:
      return {
        ...state,
        profileInfo: {
          ...state?.profileInfo,
          employeeOfficialDetailUpdateDto: {
            ...state.employeeOfficialDetailUpdateDto,
            isSentWelcomeMessage: true,
          },
        },
      };

    case PROFILE_TYPES.LEFT_COMPANY_INIT:
      return {
        ...state,
        leftCompanyLoading: true,
      };
    case PROFILE_TYPES.LEFT_COMPANY_SUCCESS:
      return {
        ...state,
        leftCompanyResponse: action.payload,
        leftCompanyLoading: false,
      };

    case PROFILE_TYPES.LEFT_COMPANY_FINISH:
      return {
        ...state,
        leftCompanyLoading: false,
        leftCompanyResponse: null,
      };

    // clearance form
    case PROFILE_TYPES.CLEARANCE_FORM_ADD_INIT:
      return {
        ...state,
        clearanceFormLoading: true,
      };

    case PROFILE_TYPES.CLEARANCE_FORM_ADD_SUCCESS:
      const updatedItem = state.clearanceFormListingResponse
        ? state.clearanceFormListingResponse.reduce((accumulator, item) => {
          if (item.id === action.payload.id) {
            if (action.payload.isActive) {
              return [...accumulator, action.payload];
            } else {
              return accumulator;
            }
          } else {
            return [...accumulator, item];
          }
        }, [])
        : [];

      if (
        !state.clearanceFormListingResponse ||
        !state.clearanceFormListingResponse.some(
          (item) => item.id === action.payload.id
        )
      ) {
        updatedItem.push(action.payload);
      }
      return {
        ...state,
        clearanceFormResponse: action.payload,
        clearanceFormLoading: false,
        clearanceFormListingResponse: updatedItem,
      };

    case PROFILE_TYPES.CLEARANCE_FORM_ADD_FINISH:
      return {
        ...state,
        clearanceFormResponse: null,
        clearanceFormLoading: false,
      };

    // GET
    case PROFILE_TYPES.CLEARANCE_FORM_GET_INIT:
      return {
        ...state,
        clearanceFormLoading: true,
      };

    case PROFILE_TYPES.CLEARANCE_FORM_GET_SUCCESS:
      return {
        ...state,
        clearanceFormListingResponse: action.payload,
        clearanceFormLoading: false,
      };

    case PROFILE_TYPES.CLEARANCE_FORM_GET_FINISH:
      return {
        ...state,
        clearanceFormLoading: false,
      };

    case PROFILE_TYPES.QUESTION_TYPE_GET_INIT:
      return {
        ...state,
        questionTypeLoading: true,
      };

    case PROFILE_TYPES.QUESTION_TYPE_GET_SUCCESS:
      return {
        ...state,
        questionTypeResponse: action.payload,
        questionTypeLoading: false,
      };

    case PROFILE_TYPES.QUESTION_TYPE_GET_FINISH:
      return {
        ...state,
        questionTypeLoading: false,
      };

    case PROFILE_TYPES.QUESTION_TYPE_ADD_INIT:
      return {
        ...state,
        questionTypeAddLoading: true,
      };

    // case PROFILE_TYPES.QUESTION_TYPE_ADD_SUCCESS:
    //   // Initialize updatedItems as an empty array if state.questionTypeGetResponse.items is null
    //   const updatedItems = state.questionTypeGetResponse.items
    //     ? state.questionTypeGetResponse.items.reduce((accumulator, item) => {
    //         if (item.id === action.payload.id) {
    //           if (action.payload.isActive) {
    //             // Include the updated payload if isActive is true
    //             return [...accumulator, action.payload];
    //           } else {
    //             // Exclude the item if isActive is false
    //             return accumulator;
    //           }
    //         } else {
    //           // Include the unchanged item
    //           return [...accumulator, item];
    //         }
    //       }, [])
    //     : [];

    //   // Add the newly added item to the updatedItems array if it doesn't already exist
    //   if (
    //     !state.questionTypeGetResponse.items ||
    //     !state.questionTypeGetResponse.items.some(
    //       (item) => item.id === action.payload.id
    //     )
    //   ) {
    //     updatedItems.push(action.payload);
    //   }

    //   return {
    //     ...state,
    //     questionTypeAddResponse: action.payload,
    //     questionTypeAddLoading: false,
    //     questionTypeGetResponse: {
    //       ...state.questionTypeGetResponse,
    //       items: updatedItems,
    //     },
    //   };

    // FROM HERE
    case PROFILE_TYPES.QUESTION_TYPE_ADD_SUCCESS:
      let updatedItems = [];

      if (state.questionTypeGetResponse.items) {
        updatedItems = [...state.questionTypeGetResponse.items];
      }

      const existingItemIndex = updatedItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingItemIndex !== -1) {
        // If the item already exists, update it
        updatedItems[existingItemIndex] = action.payload;
      } else {
        // If the item doesn't exist, push it to the updatedItems array
        updatedItems.push(action.payload);
      }

      // Remove items with isActive: false
      updatedItems = updatedItems.filter((item) => item.isActive !== false);

      return {
        ...state,
        questionTypeAddResponse: action.payload,
        questionTypeAddLoading: false,
        questionTypeGetResponse: {
          ...state.questionTypeGetResponse,
          items: updatedItems,
        },
      };

    // QUESTION
    case PROFILE_TYPES.QUESTION_TYPE_ADD_FINISH:
      return {
        ...state,
        questionTypeAddLoading: false,
      };

    case PROFILE_TYPES.QUESTION_TYPE_QUESTION_GET_INIT:
      return {
        ...state,
        questionTypeGetResponse: [],
        questionTypeGetLoading: true,
      };

    case PROFILE_TYPES.QUESTION_TYPE_QUESTION_GET_SUCCESS:
      return {
        ...state,
        questionTypeGetResponse: action.payload,
        questionTypeGetLoading: false,
      };

    case PROFILE_TYPES.QUESTION_TYPE_QUESTION_GET_FINISH:
      return {
        ...state,
        questionTypeGetLoading: false,
      };

    case PROFILE_TYPES.EXIT_INTERVIEW_GET_INIT:
      return {
        ...state,
        exitInterviewGetLoading: true,
      };

    case PROFILE_TYPES.EXIT_INTERVIEW_GET_SUCCESS:
      return {
        ...state,
        exitInterviewGetResponse: action.payload,
        exitInterviewGetLoading: false,
      };

    case PROFILE_TYPES.EXIT_INTERVIEW_GET_FINISH:
      return {
        ...state,
        exitInterviewGetLoading: false,
      };

    case PROFILE_TYPES.EXIT_INTERVIEW_ADD_INIT:
      return {
        ...state,
        exitInterviewAddLoading: true,
      };

    case PROFILE_TYPES.EXIT_INTERVIEW_ADD_SUCCESS:
      const updatedPayload = action.payload;
      return {
        ...state,
        exitInterviewAddResonse: action.payload,
        exitInterviewAddLoading: false,
        exitInterviewGetResponse: {
          ...state.exitInterviewGetResponse,
          ...updatedPayload,
        },
      };

    case PROFILE_TYPES.EXIT_INTERVIEW_ADD_FINISH:
      return {
        ...state,
        exitInterviewAddLoading: false,
      };

    case PROFILE_TYPES.EMPLOYEE_REVIEW_GET_INIT:
      return {
        ...state,
        employeeReviewResponse: [],
        // employeeReviewResponseListing: [],
        employeeReviewLoading: true,
      };

    case PROFILE_TYPES.EMPLOYEE_REVIEW_GET_SUCCESS:
      return {
        ...state,
        employeeReviewResponse: action.payload,
        employeeReviewLoading: false,
      };

    case PROFILE_TYPES.EMPLOYEE_REVIEW_GET_FINISH:
      return {
        ...state,
        employeeReviewLoading: false,
      };

    // mail
    case PROFILE_TYPES.EMPLOYEE_SUPERVISOR_MAIL_INIT:
      return {
        ...state,
        employeeReviewLoading: true,
        employeeReviewResponse: [],
      };

    case PROFILE_TYPES.EMPLOYEE_SUPERVISOR_MAIL_SUCCESS:
      const updatedPayloads = action.payload;
      return {
        ...state,
        // exitInterviewAddLoading: false,
        employeeReviewLoading: false,
        employeeReviewResponse: {
          ...state.employeeReviewResponse,
          ...updatedPayloads,
        },
      };

    case PROFILE_TYPES.EMPLOYEE_SUPERVISOR_MAIL_FINISH:
      return {
        ...state,
        employeeReviewLoading: false,
      };

    // SUPER API
    case PROFILE_TYPES.LISTING_ALL_USER_PROFILE_INIT:
      return {
        ...state,
        listingAllUsersProfileLoading: true,
      };

    case PROFILE_TYPES.LISTING_ALL_USER_PROFILE_SUCCESS:
      return {
        ...state,
        listingAllUsersProfileResp: action.payload,
        listingAllUsersProfileLoading: false,
      };

    case PROFILE_TYPES.LISTING_ALL_USER_PROFILE_FINISH:
      return {
        ...state,
        listingAllUsersProfileLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
