import React, { useEffect, useState } from "react";

// antd
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// includes
import LeaveApplicationTypeTable from "./LeaveApplicationTypeTable";
import LeaveApplicationTypeDrawer from "./LeaveApplicationTypeDrawer";

const LeaveApplicationType = () => {

  const [addUpdateLeaveApplicationTypeVisibility, setAddUpdateLeaveApplicationTypeVisibility] = useState(false);
  const [isUpdateLeaveApplicationType, setIsUpdateLeaveApplicationType] = useState(false);

  const showAddUpdateLeaveApplicationType = (record) => {
    if (record) {
      setIsUpdateLeaveApplicationType(record);
    } else {
      setIsUpdateLeaveApplicationType(false);
    }
    setAddUpdateLeaveApplicationTypeVisibility(true);
  };

  const onAddUpdateLeaveApplicationTypeClose = () => {
    setIsUpdateLeaveApplicationType('');
    setAddUpdateLeaveApplicationTypeVisibility(false);
  }; 

  return (
    <div className='layout'>
      <div className="organizer events">
         <div className="all-header-wrapper">
              <div className="meeting__flex">
                  <h3 className="header__all">Leave Application Types</h3>
                  <div className="organizerHoliday__middle--wrap">
                    <Button
                      className='btn-primary'
                      onClick={() => {
                        showAddUpdateLeaveApplicationType();
                      }}
                      >
                      <PlusOutlined /> New Leave Application Type
                    </Button>
                  </div>
              </div>
          </div>
        <LeaveApplicationTypeTable
          showAddUpdateLeaveApplicationType={showAddUpdateLeaveApplicationType}
        />
        <LeaveApplicationTypeDrawer
          drawerVisibility={addUpdateLeaveApplicationTypeVisibility}
          onAddUpdateLeaveApplicationTypeClose={onAddUpdateLeaveApplicationTypeClose}
          isUpdateLeaveApplicationType={isUpdateLeaveApplicationType}
        />
      </div>
    </div>
  );
};

export default LeaveApplicationType;
