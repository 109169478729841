import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Switch,
  Checkbox,
  Typography,
  Button,
  Divider,
  Select,
  Skeleton,
  Form,
  Modal,
  Input,
  Tooltip,
} from "antd";
import moment from "moment";
import storage from "utils/storage";
import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  EditOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import { actionForGetTenderCircularMail } from "services/redux/circularMail/action";
import {
  actionForGetTenderCheckList,
  actionForGetReferenceTemplateGetDetailsById,
  actionForIsApproveStatusTender,
  actionForGetApprovedStatusCircularPlanning,
} from "services/redux/Reference/Tender/action";
import {
  ReferenceCompanyManualAdd,
  referencePartyAction,
  mailReferencePartyAction,
  actionForReferenceCircularPlanningUpdate,
} from "services/redux/Reference/action";
import {
  getContactCompanyPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";
import useDebouncedDispatch from "hooks/useDebouncedDispatch";
import { getCompanyorPersonEmailAddess } from "services/redux/contact/contact.action";
import CircularIndex from "components/Circular";

const LogoImage = "assets/images/loader.gif";

export default function TenderCircularMail({
  currentSelectedMailMessage,
  tenderMailId,
  refCode,
  mailMessageCenterId,
  setInstanceMessageCenterListing,
}) {
  const { circularMailLoading, circularMailResp } = useSelector(
    (state) => state.circularMailReducer
  );

  const {
    tenderCircularPlanningCheckListResponse,
    tenderCircularPlanningCheckListLoading,
    singleCircularReferenceTemplateResponse,
    singleCircularReferenceTemplateLoading,
    getApprovedStatusCircularPlanningResp,
    getApprovedStatusCircularPlanningLoading,
  } = useSelector((state) => state.tender);

  const { contactPersonPaginationResponse, contactPersonPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  const { referencePartyListingCircularUpdateLoading } = useSelector(
    (state) => state.reference
  );

  const { contactCompanyPersonMailResponse, contactCompanyPersonMailLoading } =
    useSelector((state) => state.contact);

  const dispatch = useDispatch();

  const { Title, Text, Paragraph } = Typography;

  const [isButtonVisible, setIsButtonVisible] = useState(true);
  //added
  const [isCircularEditVisible, setIsCircularEditVisible] = useState(false);
  const [searchKeywordContactPerson, setSearchKeywordContactPerson] =
    useState("");
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [data, setData] = useState([]);
  const [isPersonModalVisible, setIsPersonModalVisible] = useState(false);
  const [valueSelectAddCompany, setValueSelectAddCompany] = useState(5);

  const [isEditTemplateModalOpen, setIsEdittemplateModalOpen] = useState(false);
  const [circularTemplateContent, setCircularTemplateContent] = useState({
    id: "",
    data: "",
  });
  const [editorData, setEditorData] = useState("");
  const [referenceTemplateId, setReferenceTemplateId] = useState("");

  //
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [emails, setEmails] = useState([]);

  const [contactCompanyId, setContactCompanyId] = useState("");
  const [referenceId, setReferenceId] = useState("");

  const [approvalUserIds, setApprovalUserIds] = useState([]);
  const [hasApprovalPermission, setHasApprovalPermission] = useState();
  const [isEditCircularMailLoading, setisEditCircularMailLoading] =
    useState(false);

  const [formattedModificationTime, setFormattedModificationTime] =
    useState("");
  const [lastModifierName, setLastModifierName] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  const [form] = Form.useForm();
  const [formQuickAddPerson] = Form.useForm();
  const { Option } = Select;

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  useEffect(() => {
    dispatch(actionForGetTenderCircularMail(tenderMailId, refCode));
    dispatch(actionForGetTenderCheckList(tenderMailId));
    dispatch(actionForGetReferenceTemplateGetDetailsById(tenderMailId));
    dispatch(actionForGetApprovedStatusCircularPlanning(tenderMailId));
  }, [tenderMailId]);

  let appUserId = storage.getItem("userId");

  const closeEditTemplateModal = () => {
    setIsEdittemplateModalOpen(false);
  };

  const debounceAttentiontoDispatch = useDebouncedDispatch(
    getCompanyorPersonEmailAddess,
    300
  );

  const mapModeOfCommunicationToNumber = (mode) => {
    switch (mode) {
      case "Email":
        return 1;
      case "Facebook":
        return 2;
      case "Whatsapp":
        return 3;
      case "Twitter":
        return 4;
      case "Other":
        return 5;
      default:
        return 5;
    }
  };

  const mapNumberToModeOfCommunication = (number) => {
    switch (number) {
      case 1:
        return "Email";
      case 2:
        return "Facebook";
      case 3:
        return "Whatsapp";
      case 4:
        return "Twitter";
      case 5:
        return "Other";
      default:
        return "Other";
    }
  };

  const handleCircularEdit = (record) => {
    setIsCircularEditVisible(true);

    const attentionToArray = JSON.parse(record?.attentionto || "[]");
    const currentSelectedAttendees = attentionToArray.map(
      (item) => item.AttentionTo
    );
    let mailAddressss = JSON.parse(record?.emailTo || "[]");

    let selectedPersonIds =
      attentionToArray &&
      attentionToArray?.map((item) => ({
        contactPersonIds: item?.AttentionId,
      }));

    if (record?.contactCompanyId) {
      dispatch(
        getContactPersonPagination({
          CompanyId: record?.contactCompanyId,
        })
      );
      dispatch(
        getCompanyorPersonEmailAddess(
          selectedPersonIds,
          record.contactCompanyId
        )
      );
    }

    const modeOfCommunicationNumber = mapModeOfCommunicationToNumber(
      record?.modeofCommunication
    );

    form.setFieldsValue({
      // CompanyName: record?.partyName,
      // contactCompanyId: record?.contactCompanyId,
      attendees: currentSelectedAttendees,
      modeOfCommunication: modeOfCommunicationNumber,
      remarks: record?.remarks,
      mailTo: mailAddressss || [],
    });

    setValueSelectAddCompany(modeOfCommunicationNumber);
    setContactCompanyId(record?.contactCompanyId);
    setSelectedAttendees(attentionToArray);
    setReferenceId(record?.id);
  };

  const handleCancelModal = () => {
    setIsCircularEditVisible(false);
    setValueSelectAddCompany(null);
    setContactCompanyId("");
    setReferenceId("");

    form.resetFields();
  };

  const handleSelectChange = (values, options) => {
    const selectedData = options.map((option) => ({
      AttentionTo: option.props.children,
      AttentionId: option.key,
    }));

    setSelectedAttendees(selectedData);

    const selectedPersonIds =
      selectedData &&
      selectedData?.map((item) => ({
        contactPersonIds: item?.AttentionId,
      }));

    if (selectedPersonIds.length > 0 && selectedCompanyId) {
      debounceAttentiontoDispatch(selectedPersonIds, selectedCompanyId);
    }

    // Show email input if there are selected values
    if (values.length > 0) {
      setShowEmailInput(true);
    } else {
      setShowEmailInput(false);
    }
  };

  const handleEmailChange = (e) => {
    const emailList = e.target.value.split(",").map((email) => email.trim());
    setEmails(emailList);
  };

  const onChangeAddCompany = (value) => {
    setValueSelectAddCompany(value);
  };

  // helper functions
  const renderSelectOption = (value, label) => (
    <Select.Option key={value} value={value}>
      {label}
    </Select.Option>
  );

  function actionChangeHandler(checked, actionType, rowId) {
    dispatch(
      mailReferencePartyAction(
        rowId,
        {
          actionType: checked,
          actionOf: actionType,
        }
        // searchEmailTender
      )
    );
  }

  const onFinishCircularEdit = async (values) => {
    const modeOfCommunicationString = mapNumberToModeOfCommunication(
      valueSelectAddCompany
    );

    let body = {
      remarks: values?.remarks,
      // ModeofCommunication	: valueSelectAddCompany,
      ModeofCommunication: modeOfCommunicationString,
      attentionto: JSON.stringify(selectedAttendees),
      emailTo: JSON.stringify(values?.mailTo),
    };

    let url = {
      ReferenceId: tenderMailId,
      CompanyId: contactCompanyId,
      isCircularActionIsMail: true,
    };

    setisEditCircularMailLoading(true);

    try {
      await dispatch(actionForReferenceCircularPlanningUpdate(body, url));
      setIsCircularEditVisible(false);
    } catch (err) {
      console.log(err);
    } finally {
      setisEditCircularMailLoading(false);
    }
  };

  useEffect(() => {
    if (contactPersonPaginationResponse) {
      const newData = (contactPersonPaginationResponse?.items || []).map(
        (x) => ({
          label: x.personFullName,
          value: x.contactPersonId,
        })
      );

      setData(newData);
    }
  }, [contactPersonPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(currentSelectedMailMessage)) {
      let ids = currentSelectedMailMessage?.applicationTypeDetails?.tos?.map(
        (id) => id?.participantId
      );
      setApprovalUserIds(ids);
      setHasApprovalPermission(approvalUserIds.includes(appUserId));
    }
  }, [tenderMailId]);

  useEffect(() => {
    if (!isEmpty(singleCircularReferenceTemplateResponse)) {
      const { lastModifierName = "", lastModifictaionTime = "" } =
        singleCircularReferenceTemplateResponse;
      setLastModifierName(lastModifierName);
      const formattedTime = lastModifictaionTime
        ? moment(lastModifictaionTime).format("MMMM Do YYYY, h:mm:ss a")
        : "";
      setFormattedModificationTime(formattedTime);
      // addedxxxxxxxx
      setCircularTemplateContent({
        id: singleCircularReferenceTemplateResponse?.id,
        data: singleCircularReferenceTemplateResponse?.content,
      });
      setEditorData(singleCircularReferenceTemplateResponse?.content);
      // setReferenceTemplateId(
      //   singleCircularReferenceTemplateResponse?.id
      // );
      setReferenceTemplateId(
        (prevId) => singleCircularReferenceTemplateResponse?.id || prevId
      );
    }
  }, [singleCircularReferenceTemplateResponse]);

  const columns = [
    {
      title: "S.N",
      dataIndex: "id",
      key: "id",
      render: (text, object, index) => index + 1,
      width: "8%",
    },
    {
      // title: "Company Name",
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            Company Name
          </span>
        </div>
      ),
      dataIndex: "partyName",
      key: "partyName",
      width: "20%",
      className: "dashboard_task-daily",
      sorter: (a, b) => {
        const partyNameA = a.partyName ?? "";
        const partyNameB = b.partyName ?? "";
        return partyNameA.localeCompare(partyNameB);
      },
    },
    {
      // title: "MOC",
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            MOC
          </span>
        </div>
      ),
      dataIndex: "modeofCommunication",
      key: "modeofCommunication",
      className: "dashboard_task-daily",
      // sorter: (a, b) => a.modeofCommunication.localeCompare(b.modeofCommunication),
      sorter: (a, b) => {
        const modeA = a.modeofCommunication || "";
        const modeB = b.modeofCommunication || "";
        return modeA.localeCompare(modeB);
      },
    },
    {
      title: "Attention to",
      key: "attentionto",
      render: (record) => {
        // try {
        //   return JSON.parse(record?.attentionto && record?.attentionto)
        //     ?.map((x) => x.AttentionTo)
        //     ?.join(", ");
        // } catch (error) {
        //   console.error("Error parsing JSON:", error);
        // }
        try {
          const rawAttentionTo = record?.attentionto;
          if (
            typeof rawAttentionTo === "string" &&
            rawAttentionTo.trim().startsWith("[") &&
            rawAttentionTo.trim().endsWith("]")
          ) {
            const attentionToArray = JSON.parse(rawAttentionTo);
            if (
              Array.isArray(attentionToArray) &&
              attentionToArray.length > 0
            ) {
              return attentionToArray.map((x) => x.AttentionTo).join(", ");
            }
          }
          return "";
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return "";
        }
        //
      },
    },
    {
      title: "Mail to",
      key: "emailTo",
      width: 30,
      render: (record) => {
        try {
          const rawEmailTo = record?.emailTo;

          if (
            typeof rawEmailTo === "string" &&
            rawEmailTo.trim().startsWith("[") &&
            rawEmailTo.trim().endsWith("]")
          ) {
            const emailToArray = JSON.parse(rawEmailTo);

            if (Array.isArray(emailToArray) && emailToArray.length > 0) {
              return emailToArray.join(", ");
            }
          }

          return "";
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return "";
        }
      },
    },
    {
      // title: "Country",
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            Country
          </span>
        </div>
      ),
      dataIndex: "country",
      key: "country",
      className: "dashboard_task-daily",
      sorter: (a, b) => {
        const partyNameA = a.country ?? "";
        const partyNameB = b.country ?? "";
        return partyNameA.localeCompare(partyNameB);
      },
    },
    {
      title: "Created Date",
      // dataIndex: "creationTime",
      key: "creationTime",
      sorter: (a, b) => {
        const creationTimeA = a.creationTime ?? "";
        const creationTimeB = b.creationTime ?? "";
        return moment(creationTimeA).diff(moment(creationTimeB));
      },
      // render: (record) => moment(record?.creationTime).format("YYYY-MM-DD"),
      render: (record) => {
        const creatorName = record?.creatorName ?? "No Data";
        const creationDate = record?.creationTime
          ? moment(record.creationTime).format("YYYY-MM-DD")
          : "";
        return <Tooltip title={creatorName}>{creationDate}</Tooltip>;
      },
    },
    {
      title: "Circular Planning",
      key: "isCircularPlanning",
      render: (record) => {
        return (
          <>
            {/* <Switch
              disabled
              size="small"
              checked={record?.isCircularPlanning}
            /> */}
            <Switch
              // defaultChecked={
              //   record.isCircularPlanning && record.isCircularPlanning
              // }
              // defaultChecked={record?.tiedStatus && record?.tiedStatus}
              defaultChecked={record?.tiedStatus ? true : false}
              size="small"
              onChange={(check) => {
                actionChangeHandler(check, "tiedStatus", record.id);
              }}
            />
          </>
        );
      },
    },
    {
      title: "Latest Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        return (
          <>
            <EditTwoTone
              onClick={() => {
                handleCircularEdit(record);
                setSelectedCompanyId(record?.contactCompanyId);
              }}
            />
          </>
        );
      },
    },
  ];

  const colChecklist = [
    {
      title: "S.N",
      render: (text, row, index) => (
        <div style={{ paddingLeft: 5 }}>{index + 1}</div>
      ),
      width: "15%",
    },
    {
      title: "Category",
      // dataIndex: "category",
      key: "category",
      render: (record) => (
        <>
          {/* <Checkbox disabled checked={record?.isactive} /> */}
          {/* <Checkbox disabled checked={record?.isactive ? true : false} /> */}
          {/* {record?.isactive ? (
            <CheckSquareOutlined style={{ color: "blue" }} />
          ) : (
            <CloseSquareOutlined style={{ color: "red" }} />
          )} */}

          {record?.category}
        </>
      ),
    },
    {
      title: "Status",
      key: "id",
      render: (text, record) => (
        <>
          {/* <Checkbox disabled checked={record?.isactive} /> */}
          {record?.isactive ? "Yes" : "No"}
        </>
      ),
    },
  ];

  const handleApprove = () => {
    let body = {
      isApproved: true,
      referenceCode: refCode,
      mailmesagecenterid: mailMessageCenterId,
    };

    // dispatch(actionForIsApproveStatusTender(tenderMailId, body));
    try {
      dispatch(
        actionForIsApproveStatusTender(
          tenderMailId,
          body,
          setInstanceMessageCenterListing
        )
      );
    } catch (err) {
      console.log("Error :", err);
    } finally {
      setIsButtonVisible(false);
    }
  };

  // Circular Content
  const hasCircularContent = !!singleCircularReferenceTemplateResponse?.content;

  return (
    <>
      <div className="leave-types">
        <p style={{ padding: "0px 7px", marginTop: "10px", fontWeight: 600 }}>
          Circular Mail
        </p>

        <Table
          className="organizer__tables remove-counter"
          columns={columns}
          dataSource={circularMailResp}
          loading={{
            indicator: <img src={LogoImage} height="auto" width="50px" />,
            spinning: circularMailLoading,
          }}
          pagination={false}
        />

        <Divider />

        <p style={{ padding: "0px 7px", marginTop: "20px", fontWeight: 600 }}>
          Tender CheckList
        </p>

        <Table
          className="organizer__tables remove-counter"
          columns={colChecklist}
          dataSource={tenderCircularPlanningCheckListResponse}
          loading={{
            indicator: <img src={LogoImage} height="auto" width="50px" />,
            spinning: tenderCircularPlanningCheckListLoading,
          }}
          pagination={false}
        />

        <Divider />

        <div className="mail-leave-details">
          <div
            className="leave-details"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>Circular Content</h4>
            <EditTwoTone
              onClick={() => {
                setIsEdittemplateModalOpen(true);
                // setCircularTemplateContent({
                //   id: singleCircularReferenceTemplateResponse?.id,
                //   data: singleCircularReferenceTemplateResponse?.content,
                // });
                // setEditorData(singleCircularReferenceTemplateResponse?.content);
                // // setReferenceTemplateId(
                // //   singleCircularReferenceTemplateResponse?.id
                // // );
                // setReferenceTemplateId(
                //   (prevId) =>
                //     singleCircularReferenceTemplateResponse?.id || prevId
                // );
              }}
            />
          </div>
        </div>
        {/* <p style={{ padding: "0px 7px", marginTop: "20px", fontWeight: 600 }}>
          Circular Content
        </p> */}

        <div>
          {singleCircularReferenceTemplateLoading && (
            <img src={LogoImage} height="auto" width="50px" />
          )}
          {!singleCircularReferenceTemplateLoading && !hasCircularContent && (
            <p style={{ padding: "0px 7px" }}>No Content available.</p>
          )}
          {!singleCircularReferenceTemplateLoading && hasCircularContent && (
            <div
              dangerouslySetInnerHTML={{
                __html: singleCircularReferenceTemplateResponse.content,
              }}
              style={{
                padding: "8px 10px",
              }}
            ></div>
          )}
        </div>

        <Divider />

        {approvalUserIds.includes(appUserId) && (
          <Paragraph
            style={{
              padding: "0px 7px",
              marginTop: "10px",
              marginBottom: "40px",
              fontWeight: 600,
            }}
          >
            Approved Status:
            {getApprovedStatusCircularPlanningLoading ? (
              <img src={LogoImage} height="auto" width="50px" />
            ) : (
              <>
                {getApprovedStatusCircularPlanningResp?.isApproved ||
                !isButtonVisible ? (
                  " true"
                ) : (
                  <>
                    {isButtonVisible && (
                      <>
                        <Button
                          style={{
                            margin: "10px",
                            color: "rgb(255, 255, 255)",
                            background: "rgb(15, 106, 210)",
                            borderRadius: "4px",
                          }}
                          onClick={handleApprove}
                        >
                          Yes
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <br />
            <span>
              Last Updated Details:{" "}
              {/* {`${lastModifierName} ${formattedModificationTime}`} */}
              <span className="mail__circularcontent--lastUpdated">
                {lastModifierName}
              </span>
              {formattedModificationTime}
            </span>
          </Paragraph>
        )}

        {/* MODAL */}
        <Modal
          title="Edit of Circular Mail"
          visible={isCircularEditVisible}
          onCancel={handleCancelModal}
          footer={null}
        >
          <Form
            {...formItemLayout}
            labelAlign="left"
            form={form}
            onFinish={onFinishCircularEdit}
          >
            <Form.Item label="Moc">
              <Select
                onChange={onChangeAddCompany}
                value={valueSelectAddCompany}
                name="addupdateremarks"
              >
                {renderSelectOption(1, "Email")}
                {renderSelectOption(2, "Facebook")}
                {renderSelectOption(3, "Whatsapp")}
                {renderSelectOption(4, "Twitter")}
                {renderSelectOption(5, "Other")}
              </Select>
            </Form.Item>

            <Form.Item label="Attention to" name="attendees">
              <Select
                showSearch
                mode="multiple"
                autoComplete="off"
                optionFilterProp="label"
                placeholder="Select Person"
                allowClear
                onSearch={(value) => {
                  setSearchKeywordContactPerson(value);
                }}
                onChange={handleSelectChange}
                notFoundContent={
                  <>
                    {contactPersonPaginationLoading ? (
                      <Skeleton />
                    ) : (
                      <div>
                        Content Not Found. Do you want to Person?
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsPersonModalVisible(true);
                            // Company Details
                            // dispatch(
                            //   contactCompanyGetDetails(selectedCompanyId)
                            // );

                            const namesArray =
                              searchKeywordContactPerson.split(" ");

                            formQuickAddPerson.setFieldsValue({
                              firstName: namesArray[0],
                              lastName: namesArray.slice(1).join(" ") || "",
                            });
                          }}
                        >
                          Yes
                        </Button>
                      </div>
                    )}
                  </>
                }
              >
                {data.map((option) => (
                  <Option
                    label={option.label}
                    key={option.value}
                    value={option.label}
                  >
                    {option.label}
                  </Option>
                ))}
              </Select>

              {/* {showEmailInput && (
                <Form.Item
                  label="Email Addresses"
                  name="emails"
                >
                  <Input
                    placeholder="Enter email addresses (comma separated)"
                    onChange={handleEmailChange}
                  />
                </Form.Item>
              )} */}
            </Form.Item>
            {valueSelectAddCompany === 1 && (
              <Form.Item
                label="Mail to"
                name="mailTo"
                wrapperCol={{ span: 18 }}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Contact Person"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                >
                  {contactCompanyPersonMailResponse.map((contact) => (
                    <Option
                      key={`${contact.name}-${contact.email}`}
                      value={contact.email}
                    >
                      {contact.name} ({contact.email})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="Remarks" name="remarks">
              <Input.TextArea />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
              <Button
                style={{
                  marginTop: "12px ",
                  float: "right",
                }}
                type="primary"
                htmlType="submit"
                loading={isEditCircularMailLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title={`Application Template`}
          visible={isEditTemplateModalOpen}
          onCancel={closeEditTemplateModal}
          footer={null}
          width="60%"
          bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        >
          <CircularIndex
            id={circularTemplateContent?.id}
            data={circularTemplateContent?.data}
            editorData={editorData}
            setEditorData={setEditorData}
            referenceId={tenderMailId}
            origin="mail"
            handleCloseEditModal={closeEditTemplateModal}
          />
        </Modal>
      </div>
    </>
  );
}
