import moment from "moment";

const getColumnsLeave = () => {
  return [
    {
      key: "",
      title: "#REF",
      render: (row) => {
        return <>{moment(row?.creationTime).format("YYYY/MM/DD")}</>;
      },
    },
    {
      key: "leaveTypeName",
      title: "Type",
      center: true,
      render: (row) => {
        return <>{row?.leaveApplicationTypeDto?.leaveTypeName}</>;
      },
    },
    {
      key: "",
      title: "Start-End",
      center: true,
      render: (row) => {
        return (
          <>
            {moment(row?.leaveDayFrom).format("YYYY/MM/DD")} -{" "}
            {moment(row?.leaveDayTo).format("YYYY/MM/DD")}
          </>
        );
      },
    },
    {
      key: "",
      title: "Days",
      center: true,
      render: (row) => {
        return <>{row?.leaveDays}</>;
      },
    },
    {
      key: "",
      title: "Status",
      center: true,
      render: (row) => {
        return <>{row.leaveApplicationCurrentStatusDto?.statusDisplayName}</>;
      },
    },
  ];
};

export default getColumnsLeave;
