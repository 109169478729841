import { Button, Modal, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  CloseOutlined,
  FolderOutlined,
  FileOutlined,
  EyeOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllChildFolderList } from "services/redux/Reference/Folder/action";
import pdfViewer from "components/Documents/ViewDocument";
import { cqBaseUrl } from "utils/config";
import { fileDownload } from "utils/commonFunc";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
const newWindow = "../../../assets/svg/newWindow.svg";

const TableNew = ({
  newFolderData,
  setShowAddUpdateFolderDrawer,
  setFolderRecord,
}) => {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const pageSize = 10;

  const handleView = (src) => {
    setTimeout(() => {
      pdfViewer1(src);
    }, 1000);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      sorter: true,
      render: (_, record, index) => {
        const isFile =
          record?.viewFileURL != null || record?.viewFileURL != undefined
            ? true
            : false;
        return (
          <div
            onClick={() => {
              isFile
                ? pdfViewer(`${cqBaseUrl}/${record?.viewFileURL}${token}`)
                : dispatch(getAllChildFolderList(record?.id));
            }}
          >
            {isFile ? (
              <FileOutlined style={{ paddingRight: "5px" }} />
            ) : (
              <FolderOutlined style={{ paddingRight: "5px" }} />
            )}

            {record?.title}
          </div>
        );
      },
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (_, record) => {
        return (
          <>
            {record?.creationTime != null
              ? moment(record?.creationTime).format("YYYY MMM DD - hh:mm")
              : ""}
          </>
        );
      },
    },
    {
      title: "Creator Name",
      dataIndex: "creatorName",
      key: "creatorName",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        const isFile =
          record?.viewFileURL != null || record?.viewFileURL != undefined
            ? true
            : false;
        return (
          <>
            {isFile ? (
              <div style={{ width: "100%", margin: "0 auto", display: "flex" }}>
                <button
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    padding: "0 5px 0 5px",
                    // width: "100%",
                  }}
                  onClick={() => {
                    setFolderRecord(record);
                    setModalVisible(true);
                    handleView(`${cqBaseUrl}/${record?.viewFileURL}${token}`);
                    // pdfViewer(`${cqBaseUrl}/${record?.viewFileURL}${token}`);
                  }}
                >
                  <EyeOutlined
                    style={{
                      fontSize: "12px",
                      color: "#08c",
                      cursor: "pointer",
                      backgroundColor: "none",
                    }}
                  />
                </button>
                {modalVisible && (
                  <Modal
                    visible={modalVisible}
                    title={
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "20fr 0.5fr 0.5fr ",
                        }}
                      >
                        <span>Document Viewer</span>
                        <span
                          onClick={() => {
                            pdfViewer(
                              `${cqBaseUrl}/${record?.viewFileURL}${token}`
                            );
                          }}
                        >
                          <img
                            src={newWindow}
                            height={15}
                            style={{ marginBottom: "5px" }}
                            alt="new"
                          />
                        </span>
                        <span
                          onClick={() => {
                            setModalVisible(false);
                          }}
                        >
                          <CloseOutlined />
                        </span>
                      </div>
                    }
                    footer={null}
                    closable={false}
                    onCancel={() => {
                      setModalVisible(false);
                    }}
                    gi
                    width="70%"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: "100%",
                      }}
                    ></div>

                    {modalVisible && (
                      <iframe id="pdfViewerFrame" width="100%" height="500px" />
                    )}
                  </Modal>
                )}
                <button
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    padding: "0 5px 0 5px",
                    // width: "100%",
                  }}
                  onClick={() => {
                    setFolderRecord(record);
                    fileDownload(
                      cqBaseUrl + "/" + record?.viewFileURL + token,
                      record?.title
                    );
                  }}
                >
                  <DownloadOutlined
                    style={{
                      fontSize: "12px",
                      color: "#08c",
                      cursor: "pointer",
                      backgroundColor: "none",
                    }}
                  />
                </button>
              </div>
            ) : (
              <div style={{ width: "100%", margin: "0 auto" }}></div>
            )}
          </>
        );
      },
    },
  ];

  const getData = (newFolderData) => {
    dispatch(getAllChildFolderList(newFolderData?.[0]?.id, true));
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;

    getData(newFolderData);
    // getData(pagination.current, pagination.pageSize);
  };

  const allListing = newFolderData?.[0]?.dmsUploadedFileDtos?.concat(
    newFolderData?.[0]?.childrenFolders
  );

  return (
    <Table
      dataSource={allListing}
      columns={columns}
      className="organizer__tables1"
      onChange={handleTableChange}
      pagination={false}
    />
  );
};

export default TableNew;
