import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddUpdateDrawerDetails } from "./AddUpdatedrawer";
import { Row, Col, Button, Layout } from "antd";
import TadaDetailsTable from "./TadaDetailsTable";
import { getTadaDetails } from "services/redux/requistions/tadaDetails/action";
import { AddDrawer } from "./AddDrawer";
import TadaApproverTable from "./TadaApproverTable";

export const AddUpdateDetails = () => {
  const dispatch = useDispatch();

  const { getTadaDetailsResponse, getTadaDetailsLoading } = useSelector(
    (state) => state.tadaDetails
  );
  const [addDrawerVisibility, setAddDrawerVisibility] = useState(false);
  const [addUpdateDrawerVisibility, setAddUpdateDrawerVisibility] =
    useState(false);
  const [isUpdateApplication, setisUpdateApplication] = useState(false);

  const showAddDrawer = () => {
    setAddDrawerVisibility(true);
  };

  const onAddDrawerClose = () => {
    setAddDrawerVisibility(false);
  };

  const showAddUpdateDrawer = (e, record) => {
    setAddUpdateDrawerVisibility(false);
    if (record) {
      setisUpdateApplication(record);
    } else {
      setisUpdateApplication(false);
    }

    setAddUpdateDrawerVisibility(true);
  };

  const onAddUpdateDrawerClose = () => {
    setisUpdateApplication("");
    setAddUpdateDrawerVisibility(false);
  };

  useEffect(() => {
    dispatch(getTadaDetails());
  }, []);

  return (
    <>
      <div className="global-header-wrapper">
        <div className="header-info">
          <h3 className="header__all ">TA/DA Details</h3>
        </div>
        <div className="header-action-wrapper">
          <Button className="global-create-btn" onClick={showAddDrawer}>
            Add TA/DA Details
          </Button>
        </div>
      </div>
      <Row>
        <Col span={24}>
          <AddDrawer
            addDrawerVisibility={addDrawerVisibility}
            onAddDrawerClose={onAddDrawerClose}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AddUpdateDrawerDetails
            drawerVisibility={addUpdateDrawerVisibility}
            onAddUpdateDrawerClose={onAddUpdateDrawerClose}
            isUpdateApplication={isUpdateApplication}
          />
        </Col>
      </Row>

      <Layout.Content style={{ margin: 12 }}>
        <TadaDetailsTable
          getTadaDetailsResponse={getTadaDetailsResponse}
          getTadaDetailsLoading={getTadaDetailsLoading}
        />
      </Layout.Content>
      <Layout.Content style={{ margin: 12 }}>
        <TadaApproverTable />
      </Layout.Content>
    </>
  );
};
