import {
  EditOutlined,
  MailOutlined,
  GlobalOutlined,
  DeleteOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Radio,
  Popconfirm,
  Spin,
  Skeleton,
  Checkbox,
} from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  getContactCompanyPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";
import { getCompanyorPersonEmailAddess } from "services/redux/contact/contact.action";

import AddCompanyModal from "./AddCompanyModal";
import QuickAddCompany from "views/Contact/ContactCompanyPerson/AddQuickCompany";

function AddCompanyComponent(props) {
  const {
    selectedMenuItem,
    applicationId,
    // isCircularTemplateModal,
    isTemplateModalOpen,
    setIsTemplateModalOpen,

    emailReferenceId,
    showProjectTab,
    selectedAttendees,
    setSelectedAttendees,
    data,
    setData,
  } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [addCompanyModelForm] = Form.useForm();
  const [formQuickAddCompany] = Form.useForm();
  const pageSize = 110;
  const { Option } = Select;

  const {
    referenceCompanyManualAddResponse,
    referenceCompanyManualAddLoading,
    quickContactCompanyAdd,
    quickContactCompanyLoader,
  } = useSelector((state) => state.reference);

  const { contactCompanyPaginationResponse, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  const [showAddCompany, setShowAddCompany] = useState(false);
  const [isAddCompanyModelOpen, setIsAddCompanyModelOpen] = useState(false);

  const [formCompanyId, setFormCompanyId] = useState("");

  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  const [SearchKeyword, setSearchKeyWord] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [value, setValue] = useState("");
  const [visibleQuickModal, setVisibleQuickModal] = useState(false);

  const handleAddCompany = () => {
    setShowAddCompany(true);
    addCompanyModelForm.resetFields(null);
  };

  const handleQuickAddCompanyModal = useCallback(() => {
    dispatch(
      getContactPersonPagination({
        CompanyId: selectedCompanyId || form.getFieldValue().CompanyId,
      })
    );
    formQuickAddCompany.resetFields();
    setVisibleQuickModal(false);
  }, [formQuickAddCompany]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    ForPagination = false
  ) => {
    dispatch(
      getContactCompanyPagination(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword: SearchKeyword,
        },
        ForPagination
      )
    );
  };

  const onFinish = (values) => {
    setIsAddCompanyModelOpen(true);
    setFormCompanyId(values?.CompanyId);
    setSelectedCompanyId(values?.CompanyId);
    setSelectedAttendees(null);

    addCompanyModelForm.setFieldsValue({
      CompanyIdNew: selectedCompanyName,
    });

    // console.log("get company email");
    dispatch(getCompanyorPersonEmailAddess(null, values?.CompanyId));

    dispatch(
      getContactPersonPagination({
        CompanyId: values?.CompanyId,
      })
    );
  };

  useEffect(() => {
    if (isEmpty(SearchKeyword)) return;
    const timeoutId = setTimeout(() => getData(), 800);
    return () => clearTimeout(timeoutId);
  }, [SearchKeyword]);

  return (
    <>
      <div className="company-add-update-action-wrapper xxxxsss">
        {showAddCompany ? (
          <Form layout="inline" form={form} onFinish={onFinish}>
            <Form.Item
              style={{ width: 300 }}
              name="CompanyId"
              rules={[
                {
                  required: true,
                  message: "  ",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select company"
                optionFilterProp="label"
                allowClear={true}
                autoComplete="off"
                onSelect={(value, option) => {
                  setSelectedCompanyName(option?.label || "");
                }}
                loading={contactCompanyPaginationLoading}
                onPopupScroll={(event) => {
                  try {
                    let maxScroll =
                      event.target.scrollHeight - event.target.clientHeight;

                    let currentScroll = event.target.scrollTop;
                    if (currentScroll === maxScroll) {
                      let MaxResultCount = pageSize;
                      let SkipCount = currentPage * pageSize;
                      if (
                        contactCompanyPaginationResponse?.totalCount >
                          SkipCount ||
                        !contactCompanyPaginationResponse
                      ) {
                        getData(MaxResultCount, SkipCount, SearchKeyword, true);
                      }
                    }
                  } catch (e) {}
                }}
                onSearch={(value) => {
                  setSearchKeyWord(value);
                  setCurrentPage(1);
                  if (value) {
                    setValue(value);
                  } else {
                    setValue(value);
                  }
                }}
                notFoundContent={
                  !isEmpty(value) ? (
                    contactCompanyPaginationLoading ? (
                      <Spin />
                    ) : (
                      isEmpty(contactCompanyPaginationResponse?.items) &&
                      value?.length > 2 && (
                        <div>
                          {`Create "${value}"`}
                          <Button onClick={() => setVisibleQuickModal(true)}>
                            Add New Company
                          </Button>
                        </div>
                      )
                    )
                  ) : null
                }
              >
                {contactCompanyPaginationResponse?.items?.map((company) => (
                  <Option
                    value={company.contactCompanyId}
                    key={company.contactCompanyId}
                    label={company.contactCompanyName}
                  >
                    {company.contactCompanyName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                className="company-add-save-btn"
                type="primary"
                htmlType="submit"
                shape="round"
                disabled={referenceCompanyManualAddLoading}
              >
                Add
              </Button>
            </Form.Item>
            <Form.Item className="task-form-item task-action-cross">
              <Button
                type="dotted"
                onClick={() => {
                  setShowAddCompany(!showAddCompany);
                  form.resetFields();
                }}
                bordered={false}
                shape="round"
                // size="small"
              >
                {/* <CloseOutlined /> */}
                Close
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Button
            onClick={() => {
              handleAddCompany();
            }}
            type="text"
            size="small"
          >
            <span style={{ fontSize: 14 }}> + Add Company</span>
          </Button>
        )}
      </div>

      {/* MODAL */}
      <AddCompanyModal
        selectedMenuItem={selectedMenuItem}
        // isCircularTemplateModal={isCircularTemplateModal}
        applicationId={applicationId}
        isTemplateModalOpen={isTemplateModalOpen}
        setIsTemplateModalOpen={setIsTemplateModalOpen}
        formCompanyId={formCompanyId}
        setFormCompanyId={setFormCompanyId}
        selectedCompanyId={selectedCompanyId}
        setSelectedCompanyId={setSelectedCompanyId}
        addCompanyModelForm={addCompanyModelForm}
        emailReferenceId={emailReferenceId}
        showProjectTab={showProjectTab}
        selectedAttendees={selectedAttendees}
        setSelectedAttendees={setSelectedAttendees}
        selectedCompanyName={selectedCompanyName}
        setSelectedCompanyName={setSelectedCompanyName}
        data={data}
        setData={setData}
        isAddCompanyModelOpen={isAddCompanyModelOpen}
        setIsAddCompanyModelOpen={setIsAddCompanyModelOpen}
      />

      {/* QUICK ADD COMPANY */}
      <Modal
        title="Quick Add Company"
        visible={visibleQuickModal}
        footer={null}
        // onCancel={() => {
        //   formQuickAddCompany.setFieldsValue({
        //     scopeOfWork: [],
        //     companyName: "",
        //     countryId: "",
        //     // isActive: false,
        //     isFavorite: false,
        //     cityId: "",
        //   });
        //   setVisibleQuickModal(false);
        // }}
        onCancel={handleQuickAddCompanyModal}
        keyboard={false}
        maskClosable={false}
      >
        <QuickAddCompany
          form={formQuickAddCompany}
          mainForm={form}
          companySearch={value}
          quickContactCompanyAdd={quickContactCompanyAdd}
          quickContactCompanyLoader={quickContactCompanyLoader}
          close={handleQuickAddCompanyModal}
          setSelectedCompanyName={setSelectedCompanyName}
        />
      </Modal>
    </>
  );
}

export default AddCompanyComponent;
