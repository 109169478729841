import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, space } from "antd";
import { LogoImage } from "utils/imageConstants";
import { isEmpty, values } from "lodash-es";
import { getSisterCompanyPagination } from "services/redux/admin/sisterCompanyListing/action";

const SiscoUserList = ({
  grantedPolices,
  inputSearchValue,
  rowEventhandlersSisco,
  setSiscoClassName,
  sisterCompanyPagination,
  sisterCompanyPaginationLoading,
}) => {
  const pageSize = 100;
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "SmtpHost",
    //   dataIndex: "smtpHost",
    //   key: "smtpHost",
    // },
  ];
  const [sisterCompanyUserList, setSisterCompanyUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(10);

  useEffect(() => {
    if (!isEmpty(sisterCompanyPagination?.items)) {
      setTotalCount(sisterCompanyPagination.totalCount);

      if (
        sisterCompanyPagination.totalCount !==
        sisterCompanyPagination.items.length
      ) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    }
  }, [sisterCompanyPagination, sisterCompanyPaginationLoading]);

  useEffect(() => {
    var tableContent = document.querySelector(".person-table .ant-table-body");
    tableContent.addEventListener("scroll", (event) => {
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = Math.round(event.target.scrollTop);
      if (currentScroll === maxScroll) {
        // load more data
        let MaxResultCount = pageSize;
        let SkipCount = currentPage * pageSize;

        if (
          sisterCompanyPagination?.totalCount > SkipCount ||
          !sisterCompanyPagination
        ) {
          getData(MaxResultCount, SkipCount, inputSearchValue, true);
        }
      }
    });
  }, [currentPage, sisterCompanyPagination]);

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, [inputSearchValue]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    SearchKeyword = inputSearchValue,
    ForPagination = false
  ) => {
    dispatch(
      getSisterCompanyPagination(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword,
        },
        ForPagination
      )
    );
  };

  useEffect(() => {
    if (!isEmpty(sisterCompanyPagination?.items)) {
      setSisterCompanyUserList(sisterCompanyPagination?.items);
    } else {
      setSisterCompanyUserList([]);
    }
  }, [sisterCompanyPagination]);
  return (
    <Table
      onRow={rowEventhandlersSisco}
      rowClassName={setSiscoClassName}
      className="hr__table person-table"
      columns={columns}
      dataSource={sisterCompanyUserList}
      pagination={false}
      loading={{
        indicator: <img src={LogoImage} height="auto" width="20px" />,
        spinning: sisterCompanyPaginationLoading,
      }}
      scroll={{ y: 300, x: "max-content" }}
    />
  );
};

export default SiscoUserList;
