import { Col, Skeleton } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import FeeRemaining from "views/Home/Financial/FeeRemaining";
import BankStatus from "views/Home/BankStatus";
import Budget from "views/Home/Financial/Budget";
import DailyReport from "views/Home/Financial/DailyReport";
import FinanceInfoHeader from "views/Home/Financial/FinanceInfo";
import IncomeExpense from "views/Home/Financial/IncomeExpense";
import CoreBizIncome from "views/Home/Financial/CoreBizIncome";
import ODbalance from "views/Home/Financial/ODbalance";
import CashFlow from "views/Home/Financial/CashFlow";

const FinancialBox = () => {
  const {
    dashboardBudgetIncomeExpenseResponse,
    dashboardBudgetIncomeExpenseResponseLoading,
  } = useSelector((state) => state.fiscalYearState);

  if (dashboardBudgetIncomeExpenseResponseLoading) {
    // return null;
    return (
      <Col span={24}>
        <div className=" honor-wrapper" style={{ paddingRight: 0 }}>
          <div className="dashboards__body">
            <div className="dashboards__bodyWrap">
              <Skeleton active />
            </div>
          </div>
        </div>
      </Col>
    );
  }

  return (
    <>
      {!dashboardBudgetIncomeExpenseResponseLoading && (
        <>
          <Col span={24}>
            <FinanceInfoHeader title="Financial Information" />
          </Col>
          <Col lg={9} xs={24}>
            <Budget
              dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
              dashboardBudgetIncomeExpenseResponseLoading={
                dashboardBudgetIncomeExpenseResponseLoading
              }
            />
          </Col>
          <Col lg={15} xs={24}>
            <BankStatus
              dashboardBudgetIncomeExpenseResponse={
                dashboardBudgetIncomeExpenseResponse
              }
              type="All"
            />
          </Col>

          <Col lg={9} xs={24}>
            <FeeRemaining
              dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
              dashboardBudgetIncomeExpenseResponseLoading={
                dashboardBudgetIncomeExpenseResponseLoading
              }
            />
          </Col>
          <Col lg={15} xs={24}>
            <IncomeExpense
              dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
              dashboardBudgetIncomeExpenseResponseLoading={
                dashboardBudgetIncomeExpenseResponseLoading
              }
            />
          </Col>
          <Col lg={9} xs={24}>
            <CoreBizIncome
              dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
              dashboardBudgetIncomeExpenseResponseLoading={
                dashboardBudgetIncomeExpenseResponseLoading
              } />
          </Col>
          <Col lg={15} xs={24}>
            <CashFlow
              dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
              dashboardBudgetIncomeExpenseResponseLoading={
                dashboardBudgetIncomeExpenseResponseLoading
              }
            />
          </Col>
          <Col lg={9} xs={24}>
            <DailyReport
              dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
              dashboardBudgetIncomeExpenseResponseLoading={
                dashboardBudgetIncomeExpenseResponseLoading
              }
            />
          </Col>
          <Col lg={15} xs={24}>
            <ODbalance
              dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
              dashboardBudgetIncomeExpenseResponseLoading={
                dashboardBudgetIncomeExpenseResponseLoading
              }
            />
          </Col>
        </>
      )}
      {/* <Col span={24}>
        <FinanceInfoHeader title="Financial Information" />
      </Col>
      <Col lg={9} xs={24}>
        <Budget
          dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
          dashboardBudgetIncomeExpenseResponseLoading={
            dashboardBudgetIncomeExpenseResponseLoading
          }
        />
      </Col>
      <Col lg={15} xs={24}>
        <BankStatus
          dashboardBudgetIncomeExpenseResponse={
            dashboardBudgetIncomeExpenseResponse
          }
          type="All"
        />
      </Col>

      <Col lg={9} xs={24}>
        <FeeRemaining
          dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
          dashboardBudgetIncomeExpenseResponseLoading={
            dashboardBudgetIncomeExpenseResponseLoading
          }
        />
      </Col>
      <Col lg={15} xs={24}>
        <IncomeExpense
          dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
          dashboardBudgetIncomeExpenseResponseLoading={
            dashboardBudgetIncomeExpenseResponseLoading
          }
        />
      </Col>
      <Col lg={9} xs={24}>
        <DailyReport
          dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
          dashboardBudgetIncomeExpenseResponseLoading={
            dashboardBudgetIncomeExpenseResponseLoading
          }
        />
      </Col>
      <Col lg={15} xs={24}>
        <ODbalance
          dashboardFinancialResponse={dashboardBudgetIncomeExpenseResponse}
          dashboardBudgetIncomeExpenseResponseLoading={
            dashboardBudgetIncomeExpenseResponseLoading
          }
        />
      </Col> */}
    </>
  );
};

export default FinancialBox;
