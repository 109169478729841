import { ORGANIZATION_TYPES } from "services/types";
import { OrganizationType } from "./api"
import { init, success, finish, error } from "services/common";
import { message } from "antd";

const organizationType = new OrganizationType();

export const organizationTypeAdd = (body) => {
    return async (dispatch) => {
        dispatch(init(ORGANIZATION_TYPES.ORGANIZATION_ADD));
        const response = await organizationType.organizationTypeAdd(body);
        dispatch(finish(ORGANIZATION_TYPES.ORGANIZATION_ADD));
        if(response.isSuccess) {
            message.success(" Organization Added");    
            dispatch(getOrganizationTypepagination());
            dispatch(success(ORGANIZATION_TYPES.ORGANIZATION_ADD, response.data));
        } else if (!response.isSuccess) {
            dispatch(error(response.errorMessage));
        }
    };
};

export const getOrganizationTypepagination = (query) => {
    return async (dispatch) => {
        dispatch(init(ORGANIZATION_TYPES.ORGANIZATION_PAGINATION));
        const response = await organizationType.getOrganizationTypepagination(query);
        dispatch(finish(ORGANIZATION_TYPES.ORGANIZATION_PAGINATION));
        if(response.isSuccess) {
           
            dispatch(success(ORGANIZATION_TYPES.ORGANIZATION_PAGINATION, response.data));
        } else if (!response.isSuccess) {
            dispatch(error(response.errorMessage));
        }
    };
};

// export const getAllGuestEvents = (query) => {
//     return async (dispatch) => {
//       dispatch(init(ORGANIZER_TYPES.GUEST_EVENTS));
//       const response = await guestEventsService.getAllGuestEvents(query);
//       dispatch(finish(ORGANIZER_TYPES.GUEST_EVENTS));
//       if (response.isSuccess) {
//         dispatch(success(ORGANIZER_TYPES.GUEST_EVENTS, response.data));
//       } else if (!response.isSuccess) {
//         dispatch(error(response.errorMessage));
//       }
//     };
//   }