import { EMAIL_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { QuickMessageService } from "./api";
import { message } from "antd";
import { getMailMessageCenterPagination } from "../mailMessageCenter/action";

const quickMessageService = new QuickMessageService();

export const composeQuickMessage = (body, typeName) => {
  return async (dispatch) => {
    dispatch(init(EMAIL_TYPES.COMPOSE_QUICK_MESSAGE));
    const response = await quickMessageService.composeQuickMessage(body);
    dispatch(finish(EMAIL_TYPES.COMPOSE_QUICK_MESSAGE));
    if (response.isSuccess) {
      message.success('Message sent successfully');
      setTimeout(() => {
        dispatch(success(EMAIL_TYPES.COMPOSE_QUICK_MESSAGE, response.data));
      }, 500);
      dispatch(getMailMessageCenterPagination(`MaxResultCount=10&SkipCount=0${typeName && `&messageCenterTypeName=${typeName}`}`))
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};