import { isEmpty } from "lodash";
import { useEffect, useState } from "react";

const useInfiniteScroll = (scrollTarget) => {
  const [paginationSize, setPaginationSize] = useState(null);
  const [skipCount, setSkipCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
  }, []);

  useEffect(() => {
    if (!isEmpty(responseData)) {
      setData(responseData?.items);
      setTotalData(responseData?.totalCount);
    }
  }, [responseData]);

  useEffect(() => {
    let event = document.querySelector("#" + scrollTarget);
    const scrollEvent = (e) => {
      let target = e.target;
      if (
        target.scrollTop + target.clientHeight === target.scrollHeight &&
        data.length <= totalData
      ) {
        setCurrentPage(currentPage + 1);
        // console.log('folder',currentPage)
      }
    };
    if (event) {
      event.addEventListener("scroll", scrollEvent);
      return () => {
        event.removeEventListener("scroll", scrollEvent);
      };
    }
  }, [data.length]);

  useEffect(() => {
    setSkipCount(skipCount + paginationSize);
  }, [currentPage]);

  return {
    data,
    skipCount,
    currentPage,
    totalData,
    setCurrentPage,
    setTotalData,
    setPaginationSize,
    setResponseData,
    setSkipCount,
  };
};

export default useInfiniteScroll;
