import React from "react";

function questionCeadWeeklyReport(key) {
  const questions = [
    { aa: "AA. Main/Major Activity of the week" },
    { bb: "BB. Clients Visited/Contacted this Week" },
    { cc: "CC. Principals met/Contacted this Week" },
    { dd: "DD. Principals released to Principals this Week" },
    { ee: "EE. Fees Claimed this Week" },
    { ff: "FF. Fee Received this Week" },
    { gg: "GG. Visitors arrived this Week/will Arrive next Week" },
    { hh: "HH. Site Visits made this Week" },
    {
      ii: "II. New Contracts signed this Week/Contracts transferred from Bizpro",
    },
    { jj: "JJ. File Completion Report made this Week" },
    { kk: "KK. Reports, if any.to the Principals this week" },
    { ll: "LL. Miscellaneous" },
  ];

  const questionObj = questions.find((obj) => obj.hasOwnProperty(key));

  return questionObj ? questionObj[key] : null;
}

export default questionCeadWeeklyReport;
