import { ORGANIZATION_STRUCTURE_TYPES } from "../../../../types";

const INITIAL_STATE = {
  designationListing: [],
  designationListingLoading: false,

  // addAppointmentEventResp: [],
  // addAppointmentEventRespLoading: false,

  // appointmentMarkItOffResp: [],
  // appointmentMarkItOffLoading: false,
};

export default function designationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Appointment events
    case ORGANIZATION_STRUCTURE_TYPES.DESIGNATION_LISTING_INIT:
      return {
        ...state,
        designationListing: [],
        designationListingLoading: true,
      };
    case ORGANIZATION_STRUCTURE_TYPES.DESIGNATION_LISTING_SUCCESS:
      return {
        ...state,
        designationListing: action.payload,
        designationListingLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.DESIGNATION_LISTING_FINISH:
      return {
        ...state,
        designationListingLoading: false,
      };

    // // Appointment events add
    // case ORGANIZATION_STRUCTURE_TYPES.ADD_APPOINTMENT_INIT:
    //     return {
    //         ...state,
    //         addAppointmentEventResp: [],
    //         addAppointmentEventRespLoading: true,
    //     };
    // case ORGANIZATION_STRUCTURE_TYPES.ADD_APPOINTMENT_SUCCESS:
    //     return {
    //         ...state,
    //         addAppointmentEventResp: action.payload,
    //         addAppointmentEventRespLoading: false,
    //     };
    // case ORGANIZATION_STRUCTURE_TYPES.ADD_APPOINTMENT_FINISH:
    //     return {
    //         ...state,
    //         addAppointmentEventRespLoading: false,
    //     };

    // // Appointment events mark it off
    // case ORGANIZATION_STRUCTURE_TYPES.APPOINTMENT_COMPLETE_INIT:
    //     return {
    //         ...state,
    //         appointmentMarkItOffResp: [],
    //         appointmentMarkItOffLoading: true,
    //     };
    // case ORGANIZATION_STRUCTURE_TYPES.APPOINTMENT_COMPLETE_SUCCESS:
    //     return {
    //         ...state,
    //         appointmentMarkItOffResp: action.payload,
    //         appointmentMarkItOffLoading: false,
    //     };
    // case ORGANIZATION_STRUCTURE_TYPES.APPOINTMENT_COMPLETE_FINISH:
    //     return {
    //         ...state,
    //         appointmentMarkItOffLoading: false,
    //     };

    case ORGANIZATION_STRUCTURE_TYPES.ADD_EMPLOYEE_DEPARTMENT_INIT:
      return {
        ...state,
        employeeDepartmentAdd: [],
        employeeDepartmentLoading: true,
      };

    case ORGANIZATION_STRUCTURE_TYPES.ADD_EMPLOYEE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        employeeDepartmentAdd: action.payload,
        employeeDepartmentLoading: false,
      };

    case ORGANIZATION_STRUCTURE_TYPES.ADD_EMPLOYEE_DEPARTMENT_FINISH:
      return {
        ...state,
        employeeDepartmentLoading: false,
      };

    case ORGANIZATION_STRUCTURE_TYPES.GET_DEPARTMENT_DETAILS_INIT:
      return {
        ...state,
        departmentResponse: [],
        departmentResponseLoading: true,
      };
    case ORGANIZATION_STRUCTURE_TYPES.GET_DEPARTMENT_DETAILS_SUCCESS:
      return {
        ...state,
        departmentResponse: action.payload,
        departmentResponseLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.GET_DEPARTMENT_DETAILS_FINISH:
      return {
        ...state,
        departmentResponseLoading: false,
      };
    default:
      return { ...state };
  }
}
