import { MEETING_AGENDA_TYPES } from "../../../../types";

const INITIAL_STATE = {
    addMeetingEventAgendaResp: [],
    addMeetingEventAgendaRespLoading: false,
    
    meetingEventAgendaUpdateResp: [],
    meetingEventAgendaUpdateRespLoading: false,
};

export default function meetingEventAgendaReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Labels Lisiting
        case MEETING_AGENDA_TYPES.ADD_MEETING_AGENDA_INIT:
            return {
                ...state,
                addMeetingEventAgendaResp: [],
                addMeetingEventAgendaRespLoading: true,
            };
        case MEETING_AGENDA_TYPES.ADD_MEETING_AGENDA_SUCCESS:
            return {
                ...state,
                addMeetingEventAgendaResp: action.payload,
                addMeetingEventAgendaRespLoading: false,
            };
        case MEETING_AGENDA_TYPES.ADD_MEETING_AGENDA_FINISH:
            return {
                ...state,
                addMeetingEventAgendaRespLoading: false,
            };

            // Agenda Update
            case MEETING_AGENDA_TYPES.MEETING_AGENDA_UPDATE_INIT:
                return {
                    ...state,
                    meetingEventAgendaUpdateResp: [],
                    meetingEventAgendaUpdateRespLoading: true,
                };
            case MEETING_AGENDA_TYPES.MEETING_AGENDA_UPDATE_SUCCESS:
                return {
                    ...state,
                    meetingEventAgendaUpdateResp: action.payload,
                    meetingEventAgendaUpdateRespLoading: false,
                };
            case MEETING_AGENDA_TYPES.MEETING_AGENDA_UPDATE_FINISH:
                return {
                    ...state,
                    meetingEventAgendaUpdateRespLoading: false,
                };
        default:
            return { ...state };
    }
}
