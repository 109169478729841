import { COMPLAINT_TYPES } from "../../types";
import { ComplaintService } from "./api";
import { init, success, finish, error } from "services/common";
import { message } from "antd";

const complaintService = new ComplaintService();

export const complaintPagination = (query, forPagination) => {
  return async (dispatch) => {
    dispatch(init(COMPLAINT_TYPES.COMPLAINT_PAGINATION));
    const response = await complaintService.complaintPagination(query);
    dispatch(finish(COMPLAINT_TYPES.COMPLAINT_PAGINATION));
    if (response.isSuccess) {
      if (forPagination) {
        dispatch(
          success(COMPLAINT_TYPES.COMPLAINT_PAGINATION_CHANGE, response.data)
        );
      } else {
        dispatch(success(COMPLAINT_TYPES.COMPLAINT_PAGINATION, response.data));
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const complainGetAllCounter = () => {
  return async (dispatch) => {
    dispatch(init(COMPLAINT_TYPES.COMPLAIN_COUNTER));
    const response = await complaintService.complainGetAllCounter();
    dispatch(finish(COMPLAINT_TYPES.COMPLAIN_COUNTER));
    if (response.isSuccess) {
      dispatch(success(COMPLAINT_TYPES.COMPLAIN_COUNTER, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const complaintAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPLAINT_TYPES.COMPLAINT_ADD));
    const response = await complaintService.conplaintAdd(body);
    dispatch(finish(COMPLAINT_TYPES.COMPLAINT_ADD));
    if (response.isSuccess) {
      message.success("Issued Complaint");
      //  dispatch(complaintPagination());
      dispatch(success(COMPLAINT_TYPES.COMPLAINT_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const complaintStatusUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(COMPLAINT_TYPES.COMPLAINT_STATUS_UPDATE));
    const response = await complaintService.complaintStatusUpdate(body);
    dispatch(finish(COMPLAINT_TYPES.COMPLAINT_STATUS_UPDATE));
    if (response.isSuccess) {
      message.success("Status Updated");
      // dispatch(complaintPagination());
      dispatch(success(COMPLAINT_TYPES.COMPLAINT_STATUS_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const complainGetDetails = (id) => {
  console.log("id here", id);
  return async (dispatch) => {
    dispatch(init(COMPLAINT_TYPES.COMPLAIN_GET_DETAILS));
    const response = await complaintService.complainGetDetails(id);
    dispatch(finish(COMPLAINT_TYPES.COMPLAIN_GET_DETAILS));
    if (response.isSuccess) {
      dispatch(success(COMPLAINT_TYPES.COMPLAIN_GET_DETAILS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const complaintUpdate = (body, id, selectedModule) => {
  return async (dispatch) => {
    dispatch(init(COMPLAINT_TYPES.COMPLAINT_UPDATE));
    const response = await complaintService.complaintUpdate(body, id);
    dispatch(finish(COMPLAINT_TYPES.COMPLAINT_UPDATE));
    if (response.isSuccess) {
      // dispatch(complaintPagination());
      dispatch(success(COMPLAINT_TYPES.COMPLAINT_UPDATE, response.data));
      if (!!selectedModule) {
        dispatch(
          complaintPagination(
            `maxResultCount=30&skipCount=0&SearchTitleModule=${selectedModule}`
          )
        );
      } else {
        dispatch(
          complaintPagination(
            `maxResultCount=30&skipCount=0&SearchTitleModule=${selectedModule}`
          )
        );
      }
      message.success("Status Updated");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const errorLogData = (url) => {
  return async (dispatch) => {
    dispatch(init(COMPLAINT_TYPES.ERROR_LOG));
    const response = await complaintService.errorLogData(url);
    dispatch(finish(COMPLAINT_TYPES.ERROR_LOG));
    if (response.isSuccess) {
      dispatch(success(COMPLAINT_TYPES.ERROR_LOG, response?.data));
    } else {
      dispatch(error(response.errorMessage));
    }
  };
};
