import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

// antd
import { Drawer, Form, Button, Col, Row, Input, Checkbox, Select } from "antd";

// redux
import { useDispatch, useSelector } from 'react-redux';
import { addOrganizationLayer } from 'services/redux/adminSettings/organizationStructure/action';
import { updateOrganizationLayer } from '../../../services/redux/adminSettings/organizationStructure/action';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const { Option } = Select;
const OrganizationLayerFormDrawer = ({
  closeOrganizationLayerDrawer,
  organizationLayerDrawerVisible,
  organizationLayerTypeListing,
  organizationLayerRecord,
  designationListing,
  organizationLayerListing
}) => {
  const dispatch = useDispatch();

  const {
    addOrganizationLayerResp,
    updateOrganizationLayerResp
  } = useSelector(state => state.organizationStructure);

  const [form] = Form.useForm();

  const [domainName, setDomainName] = useState('');
  const [host, setHost] = useState('');
  const [port, setPort] = useState('');

  useEffect(() => {
    if (!isEmpty(organizationLayerRecord)) {
      form.setFieldsValue({
        designationId: organizationLayerRecord.designationId,
        organizationLayerName: organizationLayerRecord.organizationLayerName,
        organizationLayerTypeId: organizationLayerRecord.organizationLayerTypeId,
        parentOrganizationLayerId: organizationLayerRecord?.parentOrganizationLayerDto?.id || '00000000-0000-0000-0000-000000000000',
        domainName: organizationLayerRecord?.organizationLayerSMTPDomainDto?.domainName,
        host: organizationLayerRecord?.organizationLayerSMTPDomainDto?.host,
        port: organizationLayerRecord?.organizationLayerSMTPDomainDto?.port
      });
    } else {
      form.resetFields();
    }
  }, [organizationLayerRecord]);

  useEffect(() => {
    if (!isEmpty(addOrganizationLayerResp) || !isEmpty(updateOrganizationLayerResp)) {
      closeOrganizationLayerDrawer();
    }
  }, [addOrganizationLayerResp, updateOrganizationLayerResp])

  const onFinish = (values) => {
    let body = {
      designationId: values.designationId,
      organizationLayerName: values.organizationLayerName,
      organizationLayerTypeId: values.organizationLayerTypeId,
      parentOrganizationLayerId: values.parentOrganizationLayerId,
      organizationLayerSMTPDomainDto: values.domainName ? {
        domainName: values.domainName,
        host: values.host,
        port: values.port
      } : null
    };

    if (organizationLayerRecord) {
      dispatch(updateOrganizationLayer(body, organizationLayerRecord.id));
    } else {
      dispatch(addOrganizationLayer(body));
    }
  }

  return (
    <Drawer
      onClose={closeOrganizationLayerDrawer}
      visible={organizationLayerDrawerVisible}
      maskClosable={false}
      width={400}
      closable={false}
      className="main-drawer-wrapper"
    >
      <div className='drawer__header'>
        <h4 className='drawer__header--title'>{`Organization Layer ${!isEmpty(organizationLayerRecord) ? 'Update' : 'Add'}`}</h4>
      </div>
      <div className='drawer__content'>
        <div className='drawer__content--wrapper'>
          <div className='contact__content__wrapper'>
            <Form
              {...layout}
              name='meetingDrawer'
              onFinish={onFinish}
              form={form}
              className='drawer-form-wrapper'
            >
              <div className="drawer-form-row">
                <div className="drawer-form-group">
                  <Form.Item
                    label="Organization Layer Name"
                    name="organizationLayerName"
                    rules={[
                      {
                        required: true,
                        message: 'Required!',
                      },
                    ]}
                  >
                    <Input placeholder="Please enter organization layer name" autoComplete='off' />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    label="Parent Organization Layer"
                    name="parentOrganizationLayerId"
                    rules={[
                      {
                        required: true,
                        message: 'Required!',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      autoComplete="off"
                    >
                   
                      {
                        !isEmpty(organizationLayerListing) && organizationLayerListing.map((layer, index) => {
                          return (
                            <Option
                              value={layer.organizationLayerId}
                              title={layer.organizationLayerName}
                              key={index}
                            >
                              {layer.organizationLayerName}
                            </Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    label="Organization Layer Type"
                    name="organizationLayerTypeId"
                    rules={[
                      {
                        required: true,
                        message: 'Required!',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      autoComplete="off"
                    >
                      {
                        !isEmpty(organizationLayerTypeListing) && organizationLayerTypeListing.map((layerType, index) => {
                          return (
                            <Option
                              value={layerType.id}
                              title={layerType.organizationLayerTypeName}
                              key={index}
                            >
                              {layerType.organizationLayerTypeName}
                            </Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    label="Designation"
                    name="designationId"
                    rules={[
                      {
                        required: true,
                        message: 'Required!',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      autoComplete="off"
                    >
                      {
                        !isEmpty(designationListing) && designationListing.map((designation, index) => {
                          return (
                            <Option
                              value={designation.designationId}
                              title={designation.designationName}
                              key={index}
                            >
                              {designation.designationName}
                            </Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    label="Domain Name"
                    name="domainName"
                    rules={[
                      {
                        required: (host || port) ? true : false,
                        message: 'Required!',
                      },
                    ]}
                  >
                    <Input onChange={(e) => setDomainName(e.target.value)} placeholder="Please enter Domain Name" autoComplete='off' />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    label="Host"
                    name="host"
                    rules={[
                      {
                        required: (domainName || port) ? true : false,
                        message: 'Required!',
                      },
                    ]}
                  >
                    <Input onChange={(e) => setHost(e.target.value)} placeholder="Please enter Host" autoComplete='off' />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    label="Port"
                    name="port"
                    rules={[
                      {
                        required: (host || domainName) ? true : false,
                        message: 'Required!',
                      },
                    ]}
                  >
                    <Input onChange={(e) => setPort(e.target.value)} placeholder="Please enter Port" autoComplete='off' />
                  </Form.Item>
                </div>
                <div className="drawer-form-group">
                  <Form.Item
                    name="isActive"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Checkbox>Active</Checkbox>
                  </Form.Item>
                </div>
              </div>
              <div className="model-footer">
                <Button
                  onClick={closeOrganizationLayerDrawer}
                  style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button type='primary' htmlType="submit">
                  Submit
                </Button>

              </div>
            </Form>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default OrganizationLayerFormDrawer