import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// import AddUserDrawer from "./AddUserDrawer";
import StageAddUpdateTable from './StageTable';
import StageAddUpdateDrawer from './StageDrawer';

const Stage = () => {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const onReset = () => {
		form.resetFields();
	};

	const [addUpdateStageDrawerVisibility, setaddUpdateStageDrawerVisibility] = useState(false);
	const [isUpdateStage, setIsUpdateStage] = useState(false);

	const showAddUpdateStageDrawer = (e, record) => {
		setaddUpdateStageDrawerVisibility(false);
		if (record) {
			setIsUpdateStage(record);
		} else {
			setIsUpdateStage(false);
		}
		setaddUpdateStageDrawerVisibility(true);
	};

	const onAddUpdateStageClose = () => {
		setIsUpdateStage('');
		setaddUpdateStageDrawerVisibility(false);
	};

	return (
		<div className="layout">
			<div className="organizer events">
				<div className="all-header-wrapper">
					<div className="meeting__flex">
						<h3 className="header__all">Stage List</h3>
						<div className="organizerHoliday__middle--wrap">
							<Button
								className="btn-primary"
								onClick={() => {
									showAddUpdateStageDrawer();
								}}
							>
								<PlusOutlined /> New Stage
							</Button>
						</div>
					</div>
				</div>

				<StageAddUpdateTable showAddUpdateStageDrawer={showAddUpdateStageDrawer} />
				<StageAddUpdateDrawer drawerVisibility={addUpdateStageDrawerVisibility} onAddUpdateStageClose={onAddUpdateStageClose} isUpdateStage={isUpdateStage} />
			</div>
		</div>
	);
};

export default Stage;
