import { Col, Row } from "antd";
import React from "react";

const ProcurementBasicDetails = ({ procurementDetails }) => {
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={8}>Company Name:</Col>
        <Col span={10}>{procurementDetails?.sisterCompanies?.name}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Person Name:</Col>
        <Col span={16}>
          {
            procurementDetails?.procurementApplicationParticipantStatuses
              ?.fromList?.[0]?.participantName
          }
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Tentative Amount:</Col>
        <Col span={16}>
          {procurementDetails?.tentativeAmount?.toLocaleString()}
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={8}>Vendor Name:</Col>
        <Col span={16}>{procurementDetails?.vendorName}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Subject:</Col>
        <Col span={16}>{procurementDetails?.subject}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Description:</Col>
        <Col span={16}>{procurementDetails?.description}</Col>
      </Row>
    </>
  );
};

export default ProcurementBasicDetails;
