import { Col, Row, Switch } from "antd";
import React from "react";
import { SettingOutlined } from "@ant-design/icons";

const Other = ({ onChangeSwitchButton, zoomData }) => {
  return (
    <>
      <Row gutter={[20, 0]} className="dashboards__row dashboards__row--back">
        <Col lg={24} xs={24}>
          <div className="dashboards__body">
            <h5 className="dashboards__mailInfo--title">
              <SettingOutlined
                style={{
                  width: 25,
                  height: 25,
                }}
              />
              {"  "}Settings
              <hr />
            </h5>
            <h5 className="setting_header">Email Settings : </h5>
            <span>
              Show zoom button :
              <Switch onChange={onChangeSwitchButton} checked={zoomData} />
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Other;
