import { CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES } from "../../types";
import { CreateUpdateGroup } from "./api";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
const CreateUpdateGroupRequest = new CreateUpdateGroup();

export const actionForCreateGroup = (query, forPagination = false) => {
  return async (dispatch) => {
    dispatch(init(CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.CREATE_GROUP_REQUEST));
    const response = await CreateUpdateGroupRequest.apiForCreateGroup(query);
    if (response.isSuccess) {
      message.success("Successfully Created Group");
      dispatch(
        success(
          CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.CREATE_GROUP_REQUEST,
          response.data
        )
      );
      dispatch(
        finish(CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.CREATE_GROUP_REQUEST)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForGetListingGroup = (query, forPagination = false) => {
  return async (dispatch) => {
    dispatch(init(CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.LIST_GROUP_REQUEST));
    const response = await CreateUpdateGroupRequest.apiForGroupListing(query);
    if (response.isSuccess) {
      dispatch(
        success(
          CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.LIST_GROUP_REQUEST,
          response.data
        )
      );
      dispatch(
        finish(CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.LIST_GROUP_REQUEST)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForUpdateGroup = (query, groupId) => {
  return async (dispatch) => {
    dispatch(init(CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.UPDATE_GROUP_REQUEST));
    const response = await CreateUpdateGroupRequest.apiForUpdateGroup(
      query,
      groupId
    );

    if (response.isSuccess) {
      message.success("Successfully Updated Group");
      dispatch(
        success(
          CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.UPDATE_GROUP_REQUEST,
          response.data
        )
      );
      dispatch(
        finish(CREATE_UPDATE_LIST_GROUP_REQUEST_TYPES.UPDATE_GROUP_REQUEST)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
