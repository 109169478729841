import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { isEmpty, set } from "lodash";
// antd
import { Button, Col, Row, Select } from "antd";
// includes
import LeaveTable from "./Includes/LeaveTable";
import LeaveDetail from "./Includes/leaveDetail";
import LeaveForm from "./Includes/leaveForm";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getLeaveApplicationPagination } from "services/redux/requistions/leaveApplication/action";
import { getRemainingLeaveApplicationTypesForEmployee } from "../../../services/redux/requistions/leaveApplication/action";
import { getFiscalYearPagination } from "services/redux/admin/FiscalYear/action";

import useInfiniteScroll from "utils/useInfinityScroll";
import LeaveApprovalTable from "./Includes/LeaveApprovalTable";

export const AddUpdateLeave = ({
  setHideInternalMessage,
  toDoApprovalGetRow,
  getLeaveDetails,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;

  const { data, totalData, skipCount, setPaginationSize, setResponseData } =
    useInfiniteScroll("leave-table-scroll");

  const {
    leaveApplicationPagination,
    leaveApplicationPaginationLoading,
    remainingLeaveApplicationTypesListing,
    remainingLeaveApplicationTypesListingLoading,
    addLeaveApplicationResp,
  } = useSelector((state) => state.leaveApplication);

  const {
    fiscalYearPagination,
    fiscalYearPaginationLoading,
  } = useSelector((state) => state.fiscalYearState);

  // console.log("leaveApplicationPagination", leaveApplicationPagination);
  
  const [searchKeyword, setSearchKeyword] = useState("");
  const [hideLeaveDrawer, setHideLeaveDrawer] = useState(true);
  const [leaveDetailsId, setLeaveDetailsId] = useState("");
  const [showLeaveDetails, setShowLeaveDetails] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState([]);

  const [fiscalYearData, setFiscalYearData] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    if (!isEmpty(addLeaveApplicationResp))
      dispatch(getRemainingLeaveApplicationTypesForEmployee());
  }, [addLeaveApplicationResp]);

  useEffect(() => {
    const getLeaveDetailsData = leaveApplicationPagination.items?.filter(
      (item) => {
        if (item.id == leaveDetailsId) return item;
      }
    );
    setLeaveDetails(getLeaveDetailsData);
  }, [leaveDetailsId]);

  //pagination action
  const fetchData = (
    MaxResultCount = 10,
    skipCount = 0,
    searchKeyword = "",
    forPagination = false
  ) => {
    dispatch(
      getLeaveApplicationPagination(
        {
          MaxResultCount: MaxResultCount,
          skipCount: skipCount,
          searchKeyword,
        },
        forPagination
      )
    );
  };

  useEffect(() => {
    if (data.length != totalData && skipCount > 0 && data.length <= totalData) {
      fetchData(10, skipCount, searchKeyword, true);
    }
  }, [searchKeyword, skipCount]);

  useEffect(() => {
    setPaginationSize(10);
    dispatch(getRemainingLeaveApplicationTypesForEmployee());
    fetchData();
  }, []);

  useEffect(() => {
    setResponseData(leaveApplicationPagination);
  }, [leaveApplicationPagination]);
  //end of pagination

  // fiscal year
  useEffect(() => {
    if (isEmpty(fiscalYearPagination?.items)) return;
    setFiscalYearData(fiscalYearPagination.items);
    setTotalCount(fiscalYearPagination.totalCount);
  }, [fiscalYearPagination]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType
  ) => {
    dispatch(
      getFiscalYearPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeFiscalYear = useCallback((fiscalYearId) => {
    if (fiscalYearId) {
      dispatch(getLeaveApplicationPagination({ FisalYearId: fiscalYearId }));
    }
  }, [dispatch]);

  return (
    <div className="layout">
      <div
        className={`organizer fullCalendar approval-wrapper ${
          hideLeaveDrawer && "full-width"
        }`}
      >
        <div className="approval-leave-wrapper">
          <div className="approval-leave-left-wrapper">
            {/* <div className="layout-title">
              <Input
                placeholder="Search by subject"
                onPressEnter={(e) => setSearchKeyword(e.target.value)}
              />
            </div> */}
            <div className="global-header-wrapper">
              <h3 className="header__all">
                <span className="title">Summary</span>
                {(hideLeaveDrawer || showLeaveDetails) && (
                  <Button
                    className="calendar-create"
                    onClick={() => {
                      setHideLeaveDrawer(false);
                      setShowLeaveDetails(false);
                      setLeaveDetailsId("");
                    }}
                  >
                    + New Leave Application
                  </Button>
                )}
              </h3>
            </div>
            <div className="leave-summary-wrapper w-75">
              <div className="leave-summary-items">
                <div className="leave-summary-header-items-wrapper">
                  <div className="leave-summary-header-items col-7 text-center border-right">
                    <div className="leave-summary-header">
                      <h2>Provisioned</h2>
                    </div>
                    <div className="leave-summary-header">
                      <h2>Allowed</h2>
                    </div>
                    <div className="leave-summary-header">
                      <h2>Applied</h2>
                    </div>
                    <div className="leave-summary-header">
                      <h2>Approved</h2>
                    </div>
                    <div className="leave-summary-header">
                      <h2>Rejected</h2>
                    </div>
                    <div className="leave-summary-header">
                      <h2>Pending</h2>
                    </div>
                    <div className="leave-summary-header">
                      <h2>Remaining</h2>
                    </div>
                  </div>
                </div>
                <div className="leave-summary-details-items-wrapper">
                  {remainingLeaveApplicationTypesListing?.map((item) => {
                    return (
                      <div
                        className="leave-summary-details-items col-6 text-center border-right"
                        key={item.id}
                      >
                        <div className="leave-summary-details">
                          <p>{item?.leaveApplicationTypeDto?.leaveTypeName}</p>
                        </div>
                        <div className="leave-summary-details">
                          <p>{item?.allowedleave ?? 0}</p>
                        </div>
                        <div className="leave-summary-details">
                          <p>{item?.appliedLeave ?? 0}</p>
                        </div>
                        <div className="leave-summary-details">
                          <p>{item?.approvedLeave ?? 0}</p>
                        </div>
                        <div className="leave-summary-details">
                          <p>{item?.unApprovedLeave ?? 0}</p>
                        </div>
                        <div className="leave-summary-details">
                          <p className="text-red">{item?.pendingLeave ?? 0}</p>
                        </div>
                        <div className="leave-summary-details">
                          <p
                            className={
                              item?.remainingLeave > 0
                                ? "text-green"
                                : "text-red"
                            }
                          >
                            {item?.remainingLeave > 0
                              ? item?.remainingLeave
                              : 0}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="global-header-wrapper">
              <h3 className="header__all">
                <span className="title">Details</span>
              </h3>
            </div>
            <div className="leave-summary-wrapper">
              <Select
                showSearch
                placeholder="Select a fiscal year"
                optionFilterProp="label"
                onChange={onChangeFiscalYear}
                allowClear
                style={{
                  marginBottom: "1em",
                  width: "20%",
                }}
              >
                {fiscalYearData?.map((year, key) => (
                  <Option
                    key={key?.id}
                    value={year?.id}
                    id={year?.displayName}
                  >
                    <div className="demo-option-label-item">
                      {year?.displayName}
                    </div>
                  </Option>
                ))}
              </Select>

              <LeaveTable
                setLeaveDetailsId={setLeaveDetailsId}
                setShowLeaveDetails={setShowLeaveDetails}
                setHideLeaveDrawer={setHideLeaveDrawer}
                leaveDetailsId={leaveDetailsId}
                toDoApprovalGetRow={toDoApprovalGetRow}
                setHideInternalMessage={setHideInternalMessage}
                data={data}
                leaveApplicationPaginationLoading={
                  leaveApplicationPaginationLoading
                }
                getLeaveDetails={getLeaveDetails}
                totalData={totalData}
              />
            </div>
          </div>

          <Col span={24}>
            <LeaveApprovalTable />
          </Col>

          {/* leave application form or leave details */}
          <div
            className={`approval-leave-right-wrapper ${
              hideLeaveDrawer && "hide-approval-leave-right-wrapper"
            }`}
          >
            <div className="leave-details-header-wrapper">
              <h2>{showLeaveDetails ? "Leave Details" : "Application"}</h2>
            </div>
            <div className="leave-details-info-wrapper">
              <div className="leave-details-info-items">
                <div className="leave-details-info-item">
                  {showLeaveDetails && !isEmpty(leaveDetails) ? (
                    <LeaveDetail
                      leaveDetails={leaveDetails}
                      setHideLeaveDrawer={setHideLeaveDrawer}
                      setLeaveDetails={setLeaveDetails}
                    />
                  ) : (
                    <LeaveForm
                      remainingLeaveApplicationTypesListing={
                        remainingLeaveApplicationTypesListing
                      }
                      setHideLeaveDrawer={setHideLeaveDrawer}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* end of leave application form */}
        </div>
      </div>
    </div>
  );
};
