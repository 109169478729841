import { appBaseUrl } from "utils/config";
import {
  getService,
  postService,
  getServiceMail,
  putService,
} from "../../../commonServices";
import queryString from "query-string";
import axios from "axios";
import mailMessageCenterReducer from "./reducer";

export class MailMessageCenterService {
  async getMailMessageCenterPagination(query, source) {
    let url = `${appBaseUrl}/mailMessageCenter/mailMessageCenterPagination?${query}`;
    let data = await getServiceMail(url);
    return data;
  }

  composeEmail(body) {
    let url = `${appBaseUrl}/emailMessage/composeEmailMessage`;
    let data = postService(url, body, "post");
    return data;
  }
  circularComposeEmail(body) {
    let url = `${appBaseUrl}/emailMessage/circularComposeEmailMessage`;
    let data = postService(url, body, "post");
    return data;
  }

  changeEmailStatusType(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/mailMessageCenter/participantInstanceMessage_AllStatus?${query}`;
    let data = postService(url, "", "post");
    return data;
  }

  changeMultipleEmailStatusType(body) {
    let url = `${appBaseUrl}/mailMessageCenter/participantInstanceMessage_AllStatusBulk`;
    let data = postService(url, body, "post");
    return data;
  }

  refreshUserIncomingEmails() {
    let url = `${appBaseUrl}/emailMessage/refreshUserIncomingEmails`;
    let data = postService(url, "", "post");
    return data;
  }
  mailReferenceUpdate(body) {
    let url = `${appBaseUrl}/mailMessageCenter/MailMessageReferenceUpdate`;
    let data = postService(url, body, "PUT");
    return data;
  }
  mailReferenceMultipleUpdate(body) {
    let url = `${appBaseUrl}/mailMessageCenter/MailMessageReferenceUpdateBulk`;
    let data = postService(url, body, "PUT");
    return data;
  }
  mailReferenceListing() {
    let url = `${appBaseUrl}/mailMessageCenter/MailMessageReferenceListing`;
    let data = getService(url);
    return data;
  }

  mailBodyGetDetails(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/mailMessageCenter/MailMessageCenterPaginationDetails?${query}`;
    let data = getService(url);
    return data;
  }

  saveMessageAsDraft(body) {
    let url = `${appBaseUrl}/emailMessage/composeEmailMessageAsDraft`;
    let data = postService(url, body, "post");
    return data;
  }

  countMessage(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/mailMessageCenter/MailMessageCenterPaginationCountersByType?${query}`;
    let data = getService(url);
    return data;
  }

  errorMessage(query) {
    let url = `${appBaseUrl}/emailMessage/emailErrorLogsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  mailMessageCenterHighlightBody(body) {
    let url = `${appBaseUrl}/mailMessageCenter/mailMessageCenterBodyHighlight/`;
    let data = postService(url, body, "PUT");
    return data;
  }
  quickEmailAdd(body) {
    let url = `${appBaseUrl}/mailMessageCenter/mailMessageWithInstanceChatInsert`;
    let data = postService(url, body, "post");
    return data;
  }
  draftMailExistOrNot(id) {
    let url = `${appBaseUrl}/mailMessageCenter/isMailDraft/${id}`;
    let data = getService(url);
    return data;
  }
  getReplyDetails(id) {
    let url = `${appBaseUrl}/mailMessageCenter/mailMessageCenterGetDetails_OnlyReply/${id}`;
    let data = getService(url);
    return data;
  }
  getPredefinedMessageListing() {
    let url = `${appBaseUrl}/emailMessage/preDefineMessageListing`;
    let data = getService(url);
    return data;
  }
  preDefineMessageAdd(body) {
    let url = `${appBaseUrl}/emailMessage/preDefineMessageAdd`;
    let data = postService(url, body);
    return data;
  }

  preDefineMessageUpdate(id, body) {
    let url = `${appBaseUrl}/emailMessage/preDefineMessageUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  getSharedEmailLists() {
    let url = `${appBaseUrl}/emailMessage/sharedEmailForMeGetAllList`;
    let data = getService(url);
    return data;
  }
  sharedEmailAdd(body) {
    let url = `${appBaseUrl}/emailMessage/sharedEmailMessageAccessCreateRules`;
    let data = postService(url, body);
    return data;
  }
  getSharedListsAll() {
    let url = `${appBaseUrl}/emailMessage/sharedEmailMessageAccessGetListingRules`;
    let data = getService(url);
    return data;
  }

  outboxResetEmailMessageOutboxFailureCount(id) {
    let url = `${appBaseUrl}/emailMessage/resetEmailMessageOutboxFailureCount/${id}`;
    let data = putService(url);
    return data;
  }
}
