import {
  GLOBAL,
  REQUEST_AMOUNT_TYPES,
  TADA_TYPES,
  VISIT_CATEGORY_TYPES,
} from "services/types";

const INITIAL_STATE = {
  visitCategoryListingResponse: [],
  visitCategoryListingLoading: false,

  requestAmountListingResponse: [],
  requestAmountListingLoading: false,

  getTadaDetailsResponse: [],
  getTadaDetailsLoading: false,

  addTadaDetailsResponse: [],
  addTadaDetailsLoading: false,

  getTadaSingleDetailsResponse: [],
  getTadaSingleDetailsLoading: false,

  tadaDetailsUpdateSupervisorResponse: [],
  tadaDetailsUpdateSupervisorLoading: false,

  tadaDetailsUpdateManagementResponse: [],
  tadaDetailsUpdateManagementLoading: false,

  tadaDetailsUpdateAccountantResponse: [],
  tadaDetailsUpdateAccountantLoading: false,

  tadaDetailsUpdateSettlementResponse: [],
  tadaDetailsUpdateSettlementLoading: false,
};

export default function tadaDetailsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case VISIT_CATEGORY_TYPES.VISIT_CATEGORY_GET_DETAILS_INIT:
      return {
        ...state,
        visitCategoryListingResponse: null,
        visitCategoryListingLoading: true,
      };
    case VISIT_CATEGORY_TYPES.VISIT_CATEGORY_GET_DETAILS_SUCCESS:
      return {
        ...state,
        visitCategoryListingResponse: action.payload,
        visitCategoryListingLoading: false,
      };
    case VISIT_CATEGORY_TYPES.VISIT_CATEGORY_GET_DETAILS_FINISH:
      return {
        ...state,
        visitCategoryListingLoading: false,
      };

    case REQUEST_AMOUNT_TYPES.REQUEST_AMOUNT_GET_DETAILS_INIT:
      return {
        ...state,
        requestAmountListingResponse: null,
        requestAmountListingLoading: true,
      };
    case REQUEST_AMOUNT_TYPES.REQUEST_AMOUNT_GET_DETAILS_SUCCESS:
      return {
        ...state,
        requestAmountListingResponse: action.payload,
        requestAmountListingLoading: false,
      };
    case REQUEST_AMOUNT_TYPES.REQUEST_AMOUNT_GET_DETAILS_FINISH:
      return {
        ...state,
        requestAmountListingLoading: false,
      };

    case TADA_TYPES.TADA_GET_DETAILS_INIT:
      return {
        ...state,
        getTadaDetailsResponse: null,
        getTadaDetailsLoading: true,
      };

    case TADA_TYPES.TADA_GET_DETAILS_SUCCESS:
      return {
        ...state,
        getTadaDetailsResponse: action.payload,
        getTadaDetailsLoading: false,
      };
    case TADA_TYPES.TADA_GET_DETAILS_FINISH:
      return {
        ...state,
        getTadaDetailsLoading: false,
      };

    case TADA_TYPES.TADA_DETAILS_ADD_INIT:
      return {
        ...state,
        addTadaDetailsResponse: null,
        addTadaDetailsLoading: true,
      };

    case TADA_TYPES.TADA_DETAILS_ADD_SUCCESS:
      return {
        ...state,
        addTadaDetailsResponse: action.payload,
        addTadaDetailsLoading: false,
        isAddSuccess: true,
      };
    case TADA_TYPES.TADA_DETAILS_ADD_FINISH:
      return {
        ...state,
        addTadaDetailsLoading: false,
        isAddSuccess: false,
      };

    case TADA_TYPES.TADA_SINGLE_GET_DETAILS_INIT:
      return {
        ...state,
        getTadaSingleDetailsResponse: null,
        getTadaSingleDetailsLoading: true,
      };

    case TADA_TYPES.TADA_SINGLE_GET_DETAILS_SUCCESS:
      return {
        ...state,
        getTadaSingleDetailsResponse: action.payload,
        getTadaSingleDetailsLoading: false,
      };
    case TADA_TYPES.TADA_SINGLE_GET_DETAILS_FINISH:
      return {
        ...state,
        getTadaSingleDetailsLoading: false,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_SUPERVISOR_INIT:
      return {
        ...state,
        tadaDetailsUpdateSupervisorResponse: [],
        tadaDetailsUpdateSupervisorLoading: true,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_SUPERVISOR_SUCCESS:
      return {
        ...state,
        tadaDetailsUpdateSupervisorResponse: action.payload,
        tadaDetailsUpdateSupervisorLoading: false,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_SUPERVISOR_FINISH:
      return {
        ...state,
        tadaDetailsUpdateSupervisorLoading: false,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_MANAGEMENT_INIT:
      return {
        ...state,
        tadaDetailsUpdateManagementResponse: [],
        tadaDetailsUpdateManagementLoading: true,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_MANAGEMENT_SUCCESS:
      return {
        ...state,
        tadaDetailsUpdateManagementResponse: action.payload,
        tadaDetailsUpdateManagementLoading: false,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_MANAGEMENT_FINISH:
      return {
        ...state,
        tadaDetailsUpdateManagementLoading: false,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_ACCOUNTANT_INIT:
      return {
        ...state,
        tadaDetailsUpdateAccountantResponse: [],
        tadaDetailsUpdateAccountantLoading: true,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_ACCOUNTANT_SUCCESS:
      return {
        ...state,
        tadaDetailsUpdateAccountantResponse: action.payload,
        tadaDetailsUpdateAccountantLoading: false,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_ACCOUNTANT_FINISH:
      return {
        ...state,
        tadaDetailsUpdateAccountantLoading: false,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_SETTLEMENT_INIT:
      return {
        ...state,
        tadaDetailsUpdateSettlementResponse: [],
        tadaDetailsUpdateSettlementLoading: true,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_SETTLEMENT_SUCCESS:
      return {
        ...state,
        tadaDetailsUpdateSettlementResponse: action.payload,
        tadaDetailsUpdateSettlementLoading: false,
      };

    case TADA_TYPES.TADA_DETAILS_UPDATE_SETTLEMENT_FINISH:
      return {
        ...state,
        tadaDetailsUpdateSettlementLoading: false,
      };
    case TADA_TYPES.TADA_APPROVAL_INIT:
      return {
        ...state,
        tadaApprovalResponse: [],
        tadaApprovalLoading: true,
      };
    case TADA_TYPES.TADA_APPROVAL_SUCCESS:
      return {
        ...state,
        tadaApprovalResponse: action.payload,
        tadaApprovalLoading: false,
      };
    case TADA_TYPES.TADA_APPROVAL_FINISH:
      return {
        ...state,
        tadaApprovalLoading: false,
      };
    case GLOBAL.GLOBAL_ERROR:
      return {
        ...state,
        addTadaDetailsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
