import {
  Form,
  Input,
  Select,
  Space,
  Checkbox,
  Modal,
  Tooltip,
  message,
  Spin,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getAllUsers } from "services/remanentCalls";
import { instanceChatAdd } from "services/redux/instanceChat/action";
import { getAllUsersListing } from "services/redux/admin/FiscalYear/action";

const { Option } = Select;

const sendIntM = "./assets/svg/send-white.svg";
const cancel = "./assets/svg/white-cross.svg";

const InternalMessage = ({
  allData,
  openNewMessage,
  setOpenNewMessage,
  setPredefinedData,
  specificTenderData,
  replyMsgDetails,
  applicationType,
  setNewId,
  tenderLoading,
}) => {
  const { instanceChatAddResp, instanceChatAddRespLoading } = useSelector(
    (state) => state.instanceChatCenter
  );

  const { getAllUsersListResponse } = useSelector(
    (state) => state.fiscalYearState
  );

  const { quickEmailAddresponseLoading, getPredefinedMessageResponse } =
    useSelector((state) => state.instanceMessageCenter);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectTosInternal, setSelectTosInternal] = useState(false);
  // const [allData, setToUserListings] = useState([]);
  const [quickMessageParticipantDtosTo, setQuickMessageParticipantDtosTo] =
    useState([]);
  const [quickMessageParticipantDtosCC, setQuickMessageParticipantDtosCC] =
    useState([]);

  const handleSaveContinue = (values) => {
    let participantTosValue = form.getFieldValue().participantTos;
    let participantCCsValue = form.getFieldValue().participantCCs;
    let tos = [];
    let ccs = [];
    participantTosValue &&
      participantTosValue?.map((item, i) => {
        tos = [...tos, { participantUserId: item }];
      });
    participantCCsValue &&
      participantCCsValue?.map((item, i) => {
        ccs = [...ccs, { participantUserId: item }];
      });
    let messageText = form.getFieldsValue().remarks;
    let remarks = form.getFieldsValue().remarks;

    if (isEmpty(participantTosValue)) {
      message.error("Quick message To's cannot be empty");
      return;
    }
    if (isEmpty(messageText || remarks)) {
      message.error("Quick message subject or remarks cannot be empty");
      return;
    }

    let body = {
      messageText: messageText,
      mailMessageParticipantWithTypeDtos: {
        participantTos: !isEmpty(form.getFieldValue().participantTos)
          ? form.getFieldValue().participantTos.map((x) => {
              return {
                participantUserId: x,
              };
            })
          : [],
        participantCCs: !isEmpty(form.getFieldValue().participantCCs)
          ? form.getFieldValue().participantCCs.map((x) => {
              return {
                participantUserId: x,
              };
            })
          : [],
      },
      applicationId: specificTenderData.id,
      applicationTypeId: applicationType.id,
      instanceMessageStatusName: "Normal",
      instanceMessageActionName: "reply",
      mailMessageReferenceDtos: [
        {
          messageCenterId: specificTenderData.id,
          referenceCode: specificTenderData.referenceCode,
        },
      ],
      replyOfMessageCenterId: replyMsgDetails ? replyMsgDetails.id : null,
      instanceMessageAttachmentDtos: [],
    };
    // dispatch(instanceChatAdd(body, setNewId));
    //amit
    let isDashbord = true;
    dispatch(instanceChatAdd(body, isDashbord, setOpenNewMessage));

    // setOpenNewMessage(false);
    // form.resetFields();
    // setOpenNewMessage(false);
  };

  useEffect(() => {
    dispatch(getAllUsersListing());
  }, []);

  useEffect(() => {
    const data1 = allData.filter((one) => {
      return one.appUserId === specificTenderData?.primaryInchargeId;
    });

    const filterParticipantTos = data1.map((x) => x.appUserId);
    const data2 = allData.filter((one) => {
      return specificTenderData?.tenderBasicSecondaryInchargeDtos?.find(
        (x) => x.secondaryInchargeId === one.appUserId
      );
    });
    const newQuickMessageParticipantDtos = data2.map((x) => x.appUserId);

    form.setFieldsValue({
      // participantTos: allData
      //   .filter((one) => {
      //     return specificTenderData?.primaryInchargeId === one.appUserId;
      //   })
      //   .map((x) => {
      //     return `${x.fullName} [${x?.userName}]`;
      //   }),
      // participantCCs: allData
      //   .filter((one) => {
      //     return specificTenderData?.tenderBasicSecondaryInchargeDtos?.find(
      //       (x) => x.secondaryInchargeId === one.appUserId
      //     );
      //   })
      //   .map((x) => {
      //     return `${x.fullName} [${x?.userName}]`;
      //   }),
      participantTos: data1?.map((x) => x.appUserId),
      participantCCs: data2?.map((x) => x.appUserId),
    });
    setQuickMessageParticipantDtosTo(filterParticipantTos);
    setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);
  }, [specificTenderData]);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 14, color: "white" }} spin />
  );
  useEffect(() => {
    if (!isEmpty(getPredefinedMessageResponse)) {
      setPredefinedData(getPredefinedMessageResponse?.items);
    }
  }, [getPredefinedMessageResponse, setPredefinedData]);

  return (
    <Modal
      closable={false}
      visible={openNewMessage}
      footer=""
      confirmLoading={tenderLoading}
      wrapClassName="popup_modal_internal_email"
    >
      {tenderLoading ? (
        <Spin />
      ) : (
        <Form
          form={form}
          className="drawer-form-wrapper internal_message_section_email_section"
          onFinish={handleSaveContinue}
        >
          <div className="internal_message_popup_email_section">
            <div
              className="left-chat-action-btn"
              style={{ marginLeft: "14px" }}
            >
              <div className="chat-box-send-wrapper">
                {instanceChatAddRespLoading ? (
                  antIcon
                ) : (
                  <Space
                    onClick={() => {
                      handleSaveContinue();
                    }}
                  >
                    <img src={sendIntM} alt="sendM" />
                  </Space>
                )}
              </div>
            </div>

            <div className="chat-action-btn" style={{ display: "flex" }}>
              <div>
                <Checkbox
                  onChange={(e) => {
                    setSelectTosInternal(!selectTosInternal);
                  }}
                >
                  To All
                </Checkbox>
              </div>
              <div
                className="btn__chat--cancel"
                onClick={() => {
                  setOpenNewMessage(false);
                }}
              >
                <Tooltip title="close">
                  <img src={cancel} alt="cancel" />
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            className="drawer-form-row mail-quick-message"
            style={{ padding: 0, overflow: "hidden" }}
          >
            <div className="email-text-input text1">
              <div className="email-text">To</div>
              <Form.Item
                className="form__group"
                name="participantTos"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  autoComplete="off"
                  mode="multiple"
                  bordered={false}
                  optionFilterProp="label"
                >
                  {!isEmpty(allData) &&
                    allData?.map((user, index) => {
                      return (
                        <Option
                          value={user?.appUserId}
                          key={index}
                          label={[user.fullName, user.userName]}
                        >
                          {user?.fullName} [{user?.userName.toUpperCase()}]
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div className="email-text-input text1">
              <div className="email-text">Cc</div>
              <Form.Item
                className="form__group"
                name="participantCCs"
                // label="Cc"
              >
                <Select
                  autoComplete="off"
                  mode="multiple"
                  optionFilterProp="label"
                  bordered={false}
                >
                  {!isEmpty(allData) &&
                    allData?.map((user, index) => {
                      return (
                        <Option
                          value={user?.appUserId}
                          key={index}
                          label={[user.fullName, user.userName]}
                        >
                          {user?.fullName}[{user?.userName.toUpperCase()}]
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
            <div
              className="drawer-form-group textarea_quick_message"
              style={{ width: "100%" }}
            >
              <Form.Item
                className="form__group"
                name="remarks"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <TextArea
                  onKeyDown={(e) => {
                    const { key, altKey } = e;
                    if (altKey && key === "Enter") {
                      handleSaveContinue();
                    }
                  }}
                  autoComplete="off"
                  rows={10}
                  bordered={false}
                ></TextArea>
              </Form.Item>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default InternalMessage;
