import { ORGANIZER_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { GuestEventsService } from "./api";
import { message } from "antd";

const guestEventsService = new GuestEventsService();

export const getGuestEventsStatuses = () => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.GUEST_EVENTS_STATUSES));
    const response = await guestEventsService.getGuestEventsStatuses();
    dispatch(finish(ORGANIZER_TYPES.GUEST_EVENTS_STATUSES));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.GUEST_EVENTS_STATUSES, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getAllGuestEvents = (query) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.GUEST_EVENTS));
    const response = await guestEventsService.getAllGuestEvents(query);
    dispatch(finish(ORGANIZER_TYPES.GUEST_EVENTS));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.GUEST_EVENTS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addGuestEvent = (body) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.ADD_GUEST_EVENTS));
    const response = await guestEventsService.addGuestEvent(body);
    dispatch(finish(ORGANIZER_TYPES.ADD_GUEST_EVENTS));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.ADD_GUEST_EVENTS, response.data));
      dispatch(getAllGuestEvents(`maxResultCount=10&skipCount=0`));
      message.success("Guest Event added successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateGuestEvent = (id, body) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.ADD_GUEST_EVENTS));
    const response = await guestEventsService.updateGuestEvent(id, body);
    dispatch(finish(ORGANIZER_TYPES.ADD_GUEST_EVENTS));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.ADD_GUEST_EVENTS, response.data));
      dispatch(getAllGuestEvents(`maxResultCount=10&skipCount=0`));
      message.success("Guest Event updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getAllGuestEntertainment = (appendUrl, type) => {
  if (type === "approval") {
    return async (dispatch) => {
      dispatch(init(ORGANIZER_TYPES.GUEST_EVENTS_APPROVAL_LISTING));
      const response = await guestEventsService.guestEventListing(appendUrl);
      dispatch(finish(ORGANIZER_TYPES.GUEST_EVENTS_APPROVAL_LISTING));
      if (response.isSuccess) {
        dispatch(
          success(ORGANIZER_TYPES.GUEST_EVENTS_APPROVAL_LISTING, response.data)
        );
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
      }
    };
  } else {
    return async (dispatch) => {
      dispatch(init(ORGANIZER_TYPES.GUEST_EVENTS_LISTING));
      const response = await guestEventsService.guestEventListing(appendUrl);
      dispatch(finish(ORGANIZER_TYPES.GUEST_EVENTS_LISTING));
      if (response.isSuccess) {
        dispatch(success(ORGANIZER_TYPES.GUEST_EVENTS_LISTING, response.data));
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
      }
    };
  }
};
