import { message } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getMailMessageParticipantStatusListing } from "services/redux/email/messageCenterType/action";
import { getAllUsers } from "services/remanentCalls";
import GlobalChat from "./GlobalChat";
import ReplyChatBoxGlobal from "./ReplyChatBoxGlobal";

import { getInstanceChatCenterPagination } from "services/redux/instanceChat/action";
import { getInstanceMessageCenterPagination } from "services/redux/email/instanceMessageCenter/action";
import { messageApplicationTypeListing } from "services/remanentCalls";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { renderNamesWithTooltip } from "utils/renderNamesWithTooltip";

const hide = "assets/svg/hide-16.svg";

const ChatDetails = (props) => {
  const {
    setShowChat,
    setChatHeight,
    chatHeight,
    setSmallChatHeight,
    smallChatHeight,
    setchatBox,
    module,
    origin,
    chatBox,
    hideInternalMessage,
    setHideInternalMessage,
    taskId,
    setTaskId,
    todoDetails,
    handleChangeEvent,
    isPrivate,
    modalData,
    selectedTaskTos,
    selectedTaskCcs,
  } = props;
  const dispatch = useDispatch();

  const mailMessageParticipantStatusState = useSelector(
    (state) => state.mailMessageParticipantStatus
  );
  const { instanceChatCenterPagination, instanceChatCenterPaginationLoading } =
    useSelector((state) => state.instanceChatCenter);

  const { mailMessageParticipationStatusListing } =
    mailMessageParticipantStatusState;

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  const {
    instanceMessageCenterPagination,
    instanceMessageCenterPaginationLoading,
    replyInstanceMessageResp,
  } = useSelector((state) => state.instanceMessageCenter);

  const [allParticipants, setAllParticipants] = useState([]);

  const [replyMsgDetails, setReplyMsgDetails] = useState({});
  const [replyStatus, setReplyStatus] = useState("");
  const [replyOfChat, setReplyofChat] = useState("");
  const [applicationType, setApplicationType] = useState();

  const handleReply = (msg, status) => {
    setchatBox(true);
    setReplyofChat(msg.orderNumber);
    setReplyMsgDetails(msg);
    setReplyStatus(status);
    // console.log("reply message part", msg, status);
  };

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setAllParticipants(resp.data);
    }
  };

  const applicationTypeListing = async () => {
    try {
      const resp = await messageApplicationTypeListing();
      if (resp && resp.status == 200) {
        resp?.data?.map((type) => {
          // console.log("resp: ", resp);
          if (module === "Task" && type.displayName === "Task") {
            setApplicationType(type.id);
          } else if (module === "Approvals" && type.displayName === "Leave") {
            setApplicationType(type.id);
          } else if (
            module === "CEAD_WeeklyReport" &&
            type.displayName === "CEAD Weekly Report"
          ) {
            setApplicationType(type.id);
          } else if (
            module === "Circular_Planning" &&
            type.displayName === "CircularPlanning"
          ) {
            setApplicationType(type.id);
          } else if (
            module === "GroupChat" &&
            type?.displayName === "GroupChat"
          ) {
            setApplicationType(type.id);
          } else if (module === "DailyTask" && type.displayName === "Task") {
            setApplicationType(type.id);
          }
          // console.log("type", type)
        });
      }
    } catch (error) {
      message.error("Failure");
    }
  };

  useEffect(() => {
    if (isEmpty(allParticipants)) {
      fetchAllParticipants();
    }
    // if (isEmpty(applicationType)) {
    //   applicationTypeListing();
    // }
    applicationTypeListing();

    // if (isEmpty(organizerEventPriorityListing)) {
    //   dispatch(getOrganizerEventPriorityListings());
    // }

    // if (isEmpty(mailMessageParticipantTypeListing)) {
    //   dispatch(getMailMessageParticipantTypeListing());
    // }

    if (isEmpty(mailMessageParticipationStatusListing)) {
      dispatch(getMailMessageParticipantStatusListing());
    }

    // if (isEmpty(participantTypeEmailAddressesListing)) {
    //   dispatch(getParticipantTypeEmailAddressesListing());
    // }
  }, []);

  useEffect(() => {
    if (applicationType && module === "GroupChat") {
      dispatch(
        getInstanceMessageCenterPagination({
          MaxResultCount: 20,
          SkipCount: 0,
          MessageCenterId: todoDetails?.id || taskId,
          ApplicationTypeId: applicationType,
          // SearchKeyword
        })
      );
    } else {
      // instanceGetData(20, 0);
      // const dynamicName = module === "GroupChat" ? "MessageCenterId" : "ApplicationId";
      dispatch(
        getInstanceChatCenterPagination({
          MaxResultCount: 20,
          SkipCount: 0,
          ApplicationId: todoDetails?.id || taskId,
          // [dynamicName]: todoDetails?.id || taskId,
          ApplicationTypeId: applicationType,
          // SearchKeyword
        })
      );
    }
    // }, [todoDetails, applicationType]);
    // }, [todoDetails, applicationType, selectedMailTypeAndMailData]);
  }, [todoDetails, applicationType, selectedMailTypeAndMailData, taskId]);

  // {console.log("instanceChatCenterPagination: ",instanceChatCenterPagination)}
  // {console.log("instanceMessageCenterPagination: ",instanceMessageCenterPagination)}

  return (
    <div className="dashboard__body--infos global-internal-message-body">
      {hideInternalMessage && origin !== "Dashboard" ? (
        <div
          // className={`emailDetails__body--chat global-chat-block ${
          //   hideInternalMessage ? "hide" : ""
          // } `}
          className={`${
            origin !== "cead"
              ? "emailDetails__body--chat global-chat-block"
              : ""
          } ${hideInternalMessage ? "hide" : ""}`}
        >
          {module !== "CEAD_WeeklyReport" ? (
            <div className="chatmail">
              <div className="chatmail__header--wrapper">
                <div className="chatmail__header--leftcontent">
                  <div className="chatmail__text">
                    {module === "Approvals" ? "Leave" : "Task"} Details
                  </div>
                  {module !== "Approvals" && (
                    <div className="task-edit-wrapper">
                      <p
                        onClick={() => {
                          handleChangeEvent("task");
                          setHideInternalMessage(false);
                          setShowChat(false);
                        }}
                      >
                        Edit
                      </p>
                    </div>
                  )}
                  <div
                    className="global-chat-hide"
                    onClick={() => {
                      setHideInternalMessage(!hideInternalMessage);
                      setchatBox(false);
                      setShowChat(false);
                      setSmallChatHeight(false);
                      //next here
                      // setReplyofChat("");
                      // setReplyChatHeight(false);
                      // setActiveChat("");
                      // setSmallChatHeight(false);
                    }}
                  >
                    <img src={hide} alt="hide" />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className={`emailDetails__body--chatwrap ${
              chatHeight && "isOpenReplyChat"
            }`}
          >
            <GlobalChat
              isPrivate={isPrivate}
              setchatBox={setchatBox}
              module={module}
              handleReply={handleReply}
              setReplyStatus={setReplyStatus}
              chatBox={chatBox}
              todoDetails={todoDetails}
              handleChangeEvent={handleChangeEvent}
              setHideInternalMessage={setHideInternalMessage}
              hideInternalMessage={hideInternalMessage}
              setChatHeight={setChatHeight}
              chatHeight={chatHeight}
              setSmallChatHeight={setSmallChatHeight}
              smallChatHeight={smallChatHeight}
              setShowChat={setShowChat}
              taskId={taskId}
              mailMessageParticipationStatusListing={
                mailMessageParticipationStatusListing
              }
              instanceChatCenterPagination={instanceChatCenterPagination}
            />
            <ReplyChatBoxGlobal
              setchatBox={setchatBox}
              replyMsgDetails={replyMsgDetails}
              replyOfChat={replyOfChat}
              replyStatus={replyStatus}
              chatBox={chatBox}
              allParticipants={allParticipants}
              chatHeight={chatHeight}
              setChatHeight={setChatHeight}
              setSmallChatHeight={setSmallChatHeight}
              setShowChat={setShowChat}
              smallChatHeight={smallChatHeight}
              mailMessageParticipationStatusListing={
                mailMessageParticipationStatusListing
              }
              taskId={taskId}
              todoDetails={todoDetails}
              setReplyStatus={setReplyStatus}
              chatbox={chatBox}
              applicationType={applicationType}
              selectedTaskTos={selectedTaskTos}
              selectedTaskCcs={selectedTaskCcs}
            />
          </div>
        </div>
      ) : hideInternalMessage &&
        origin === "Dashboard" &&
        module === "GroupChat" ? (
        <>
          <div
            className="task-view-wrapper"
            style={{
              height: "auto",
            }}
          >
            <div className="flex-row">
              <div className="task-info-item">
                <strong>Title</strong>
                <p>{modalData?.subject}</p>
              </div>
              <div className="task-info-item">
                <strong>Date</strong>
                <p>{moment(modalData?.creationTime).format("YYYY-MM-DD")}</p>
              </div>
              <div className="task-info-item">
                <strong>To</strong>
                {/* {renderNamesWithTooltip(modalData?.applicationTypeDetails.filter(item =>))} */}
                <p>
                  {modalData?.applicationTypeDetails.tos
                    .filter((item) => item?.fullName)
                    ?.map((item, index) => item?.fullName)
                    .join(", ")}
                </p>
              </div>
              <div className="task-info-item">
                <strong>Cc</strong>
                <p>
                  {modalData?.applicationTypeDetails.cCs
                    .filter((item) => item?.fullName)
                    ?.map((item, index) => item?.fullName)
                    .join(", ")}
                </p>
              </div>
            </div>
          </div>
          <GlobalChat
            isPrivate={isPrivate}
            setchatBox={setchatBox}
            module={module}
            handleReply={handleReply}
            setReplyStatus={setReplyStatus}
            chatBox={chatBox}
            todoDetails={todoDetails}
            handleChangeEvent={handleChangeEvent}
            setHideInternalMessage={setHideInternalMessage}
            hideInternalMessage={hideInternalMessage}
            setChatHeight={setChatHeight}
            chatHeight={chatHeight}
            setSmallChatHeight={setSmallChatHeight}
            smallChatHeight={smallChatHeight}
            setShowChat={setShowChat}
            taskId={taskId}
            mailMessageParticipationStatusListing={
              mailMessageParticipationStatusListing
            }
            instanceChatCenterPagination={instanceMessageCenterPagination}
            origin={origin}
          />
          <ReplyChatBoxGlobal
            setchatBox={setchatBox}
            replyMsgDetails={replyMsgDetails}
            replyOfChat={replyOfChat}
            replyStatus={replyStatus}
            chatBox={chatBox}
            allParticipants={allParticipants}
            chatHeight={chatHeight}
            setChatHeight={setChatHeight}
            setSmallChatHeight={setSmallChatHeight}
            setShowChat={setShowChat}
            smallChatHeight={smallChatHeight}
            mailMessageParticipationStatusListing={
              mailMessageParticipationStatusListing
            }
            taskId={taskId}
            todoDetails={todoDetails}
            setReplyStatus={setReplyStatus}
            chatbox={chatBox}
            applicationType={applicationType}
            module={module}
            origin={origin}
            selectedTaskTos={selectedTaskTos}
            selectedTaskCcs={selectedTaskCcs}
          />
        </>
      ) : hideInternalMessage &&
        origin === "Dashboard" &&
        module === "DailyTask" ? (
        <>
          <GlobalChat
            isPrivate={isPrivate}
            setchatBox={setchatBox}
            module={module}
            handleReply={handleReply}
            setReplyStatus={setReplyStatus}
            chatBox={chatBox}
            todoDetails={todoDetails}
            handleChangeEvent={handleChangeEvent}
            setHideInternalMessage={setHideInternalMessage}
            hideInternalMessage={hideInternalMessage}
            setChatHeight={setChatHeight}
            chatHeight={chatHeight}
            setSmallChatHeight={setSmallChatHeight}
            smallChatHeight={smallChatHeight}
            setShowChat={setShowChat}
            taskId={taskId}
            mailMessageParticipationStatusListing={
              mailMessageParticipationStatusListing
            }
            instanceChatCenterPagination={instanceMessageCenterPagination}
            origin={origin}
          />
          <ReplyChatBoxGlobal
            setchatBox={setchatBox}
            replyMsgDetails={replyMsgDetails}
            replyOfChat={replyOfChat}
            replyStatus={replyStatus}
            chatBox={chatBox}
            allParticipants={allParticipants}
            chatHeight={chatHeight}
            setChatHeight={setChatHeight}
            setSmallChatHeight={setSmallChatHeight}
            setShowChat={setShowChat}
            smallChatHeight={smallChatHeight}
            mailMessageParticipationStatusListing={
              mailMessageParticipationStatusListing
            }
            taskId={taskId}
            todoDetails={todoDetails}
            setReplyStatus={setReplyStatus}
            chatbox={chatBox}
            applicationType={applicationType}
            module={module}
            origin={origin}
          />
        </>
      ) : hideInternalMessage && origin === "Dashboard" ? (
        <div>
          <GlobalChat
            isPrivate={isPrivate}
            setchatBox={setchatBox}
            module={module}
            handleReply={handleReply}
            setReplyStatus={setReplyStatus}
            chatBox={chatBox}
            todoDetails={todoDetails}
            handleChangeEvent={handleChangeEvent}
            setHideInternalMessage={setHideInternalMessage}
            hideInternalMessage={hideInternalMessage}
            setChatHeight={setChatHeight}
            chatHeight={chatHeight}
            setSmallChatHeight={setSmallChatHeight}
            smallChatHeight={smallChatHeight}
            setShowChat={setShowChat}
            taskId={taskId}
            mailMessageParticipationStatusListing={
              mailMessageParticipationStatusListing
            }
            instanceChatCenterPagination={instanceChatCenterPagination}
            origin={origin}
          />
          <ReplyChatBoxGlobal
            setchatBox={setchatBox}
            replyMsgDetails={replyMsgDetails}
            replyOfChat={replyOfChat}
            replyStatus={replyStatus}
            chatBox={chatBox}
            allParticipants={allParticipants}
            chatHeight={chatHeight}
            setChatHeight={setChatHeight}
            setSmallChatHeight={setSmallChatHeight}
            setShowChat={setShowChat}
            smallChatHeight={smallChatHeight}
            mailMessageParticipationStatusListing={
              mailMessageParticipationStatusListing
            }
            taskId={taskId}
            todoDetails={todoDetails}
            setReplyStatus={setReplyStatus}
            chatbox={chatBox}
            applicationType={applicationType}
            origin={origin}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ChatDetails;
