import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  message,
  Space,
  DatePicker,
  Layout,
  Image,
  Dropdown,
  Menu,
} from "antd";
import { validateMessages } from "utils/misc";
import {
  PlusOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  StarFilled,
} from "@ant-design/icons";

const addperson = "assets/svg/Addperson.svg";
const addcompany = "assets/svg/addcompany.svg";

const menu = (
  <Menu style={{ width: 144, height: "auto" }}>
    <Menu.Item className="sidebars__menu--item">
      <>
        <div
          style={{
            display: "flex",
            width: 114,
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "18%" }}>
            <img src={addcompany} />
          </div>
          <div style={{ width: "50%" }}> Goal</div>
        </div>
      </>
    </Menu.Item>

    <Menu.Item className="sidebars__menu--item">
      <>
        <div
          style={{
            display: "flex",
            width: 114,
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "18%" }}>
            <img src={addperson} />
          </div>
          <div style={{ width: "50%" }}> Appoinment</div>
        </div>
      </>
    </Menu.Item>
    <Menu.Item className="sidebars__menu--item">
      <>
        <div
          style={{
            display: "flex",
            width: 114,
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "18%" }}>
            <img src={addperson} />
          </div>
          <div style={{ width: "50%" }}> TO DO</div>
        </div>
      </>
    </Menu.Item>
  </Menu>
);
const header = ({ name }) => {
  return (
    <div>
      <Row className="events__buttons">
        <Col lg={24}>
          <div className="ContactDetails__header">
            <h3 className="header__all">{name} </h3>
          </div>
        </Col>

        {/* 
							<Col lg={6}>
								<Button className="btn-primary" onClick={showAddUpdateCountryDrawer}>
									<PlusOutlined />
									<span className="button__label">New Company</span>
								</Button>
							</Col>
							<Col lg={6}>
								<Button className="btn-primary" onClick={showAddUpdatePersonDrawer}>
									<PlusOutlined />
									<span className="button__label">New Person</span>
								</Button>
							</Col> */}
      </Row>
      {/* <div className="contactDetails__allheader">
						<Row gutter={[8, 0]}>
							<Col span={19}>
								<Input.Search
									className="form__groups"
                                    style={{margin:0}}
									placeholder="Search"
									size="medium"
									allowClear
									// onSearch={(e) => {
									// 	setSearchKeyword(e);
									// }}
									// onClick={() => {
									// 	setshowAll(true);
									// }}

									// }}
									// onClcik={(e) => setSearchKeyword(e.target.value)}
									// value={SearchKeyword}
								/>
							</Col>

							<Dropdown overlay={menu} arrow trigger={['click']} placement="bottomLeft" arrow placement="bottomRight">
								<Col span={2}>
									<div className="contactDetails__dropdown">
										<Button className="contact__header__btn-primary">
											<PlusOutlined />
										</Button>
									</div>
								</Col>
							</Dropdown>
						</Row>
					</div> */}
    </div>
  );
};

export default header;
