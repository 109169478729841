import { useCallback } from "react";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";

const useDebouncedDispatch = (actionCreator, delay = 300) => {
  const dispatch = useDispatch();

  return useCallback(
    debounce((...args) => {
      dispatch(actionCreator(...args));
    }, delay),
    [dispatch, actionCreator]
  );
};

export default useDebouncedDispatch;
