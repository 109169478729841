import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Layout,
  Button,
  Drawer,
  Steps,
  Typography,
  Collapse,
  Form,
} from "antd";

import SettlementApplicantForm from "./SettlementPhase/SettlementApplicantForm";
import TadaDetailsForm from "./TadaDetailsForm";
import storage from "utils/storage";
import { useForm } from "antd/lib/form/Form";
import TadaDetailsSettlementForm from "./TadaDetailsSettlementForm";
import SupervisorForm from "./ApplicationPhase/SupervisorForm";
import ApproverForm from "./ApplicationPhase/ApproverForm";
import AccountForm from "./ApplicationPhase/AccountForm";
import SettlementSupervisorForm from "./SettlementPhase/SettlementSupervisorForm";
import SettlementApproverForm from "./SettlementPhase/SettlementApproverForm";
import SettlementAccountForm from "./SettlementPhase/SettlementAccountForm";

const { Header } = Layout;
const { Panel } = Collapse;
const { Step } = Steps;

export const AddUpdateDrawerDetails = ({
  drawerVisibility,
  onAddUpdateDrawerClose,
  getTadaSingleDetailsResponse,
  setApplicantState,
  applicantState,
}) => {
  const [form] = useForm();
  const [form2] = useForm();
  const [current, setCurrent] = React.useState(0);
  const [reject, setReject] = useState(false);
  const [status, setStatus] = useState();
  const [details, setDetails] = useState({});

  const [currentSettlement, setCurrentSettlement] = React.useState(0);
  const [rejectSettlement, setRejectSettlement] = useState(false);

  useEffect(() => {
    setDetails(getTadaSingleDetailsResponse);
  }, [getTadaSingleDetailsResponse]);

  useEffect(() => {
    const status1 = details?.tadaApplicationStatus?.displayName;
    setStatus(status1);
  }, [details]);

  const handleAccordionChange = (key) => {
    setApplicantState(!applicantState); // Assumes only one panel can be expanded at a time
  };

  const supervisorDescription =
    details?.tadaApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance !== null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksAdvance);

  const supervisorName =
    details?.tadaApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance != null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.participantName);

  const managementDescription =
    details?.tadaApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance !== null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksAdvance);

  const managementName =
    details?.tadaApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance != null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.participantName);

  const managementApprovedAmount = details?.totalAdvanceApprovedAmount;

  const accountantDescription =
    details?.tadaApplicationParticipantStatuses?.accountantList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance !== null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksAdvance);

  const accountantName =
    details?.tadaApplicationParticipantStatuses?.accountantList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance != null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.participantName);

  const accountantApprovedAmount = details?.totalAdvanceApprovedAmount;

  let localStorageId = storage.getItem("userId");
  const fromUserId = details?.tadaApplicationParticipantStatuses?.fromList?.map(
    (userId) => userId?.participantUserId === localStorageId
  );

  const supervisorSettlementDescription =
    details?.tadaApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal !== null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksFinal);

  const supervisorSettlementName =
    details?.tadaApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal != null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.participantName);

  const managementSettlementDescription =
    details?.tadaApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal !== null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksFinal);

  const managementSettlementName =
    details?.tadaApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal != null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.participantName);

  const accountantSettlementDescription =
    details?.tadaApplicationParticipantStatuses?.accountantList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal !== null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksFinal);

  const accountantSettlementName =
    details?.tadaApplicationParticipantStatuses?.accountantList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal != null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.participantName);

  const settlementBalance = details?.settlementBalanceAmount;

  form.setFieldsValue({
    commentValue: supervisorDescription ?? "",
    commentManagementValue: managementDescription,
    managementApprovedAmount: managementApprovedAmount,
    commentAccountantValue: accountantDescription,
    accountantApprovedAmount: accountantApprovedAmount,
  });

  form2.setFieldsValue({
    commentSettlementValue: supervisorSettlementDescription ?? "",
    commentSettlementManagementValue: managementSettlementDescription ?? "",
    commentSettlementAccountantValue: accountantSettlementDescription ?? "",
    settlementBalance: settlementBalance ?? "",
  });

  const fromUser = fromUserId?.includes(true);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = (status) => {
    status === "applicant"
      ? setCurrent(current - 1)
      : setCurrentSettlement(currentSettlement - 1);
  };

  return (
    <div>
      <Drawer
        width={600}
        maskClosable={false}
        onClose={onAddUpdateDrawerClose}
        visible={drawerVisibility}
        closable={false}
        footer={
          <Layout.Content
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={onAddUpdateDrawerClose}>Cancel</Button>
            {current > 0 && !reject && applicantState && (
              <Button
                style={{ margin: "0 8px" }}
                type="primary"
                onClick={() => prev("applicant")}
              >
                Previous
              </Button>
            )}
            {currentSettlement > 0 && !rejectSettlement && !applicantState && (
              <Button
                style={{ margin: "0 8px" }}
                type="primary"
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
          </Layout.Content>
        }
      >
        <Header className="drawer__header">
          <Typography className="drawer__header--title">
            TA/DA Details
          </Typography>
        </Header>
        <Collapse
          accordion
          expandIconPosition="right"
          defaultActiveKey={1}
          style={{ backgroundColor: "#EEF2F8", marginTop: 6 }}
          onChange={handleAccordionChange}
          activeKey={applicantState ? 1 : 2}
        >
          <Panel
            header="Application Phase"
            key="1"
            style={{ fontWeight: "bold" }}
          >
            <Layout.Content>
              <Steps
                key="1"
                className="drawer__header"
                size="small"
                current={
                  status === "Supervisor Approved"
                    ? 2
                    : status === "Management Advance Approved"
                    ? 3
                    : status === "Account Advance Approved" ||
                      status === "Settlement Pending" ||
                      status === "Supervisor Settlement Approved" ||
                      status === "Management Settlement Approved" ||
                      status === "Account Settlement Approved"
                    ? 5
                    : 1
                }
              >
                <Step title={status === "Pending" ? "Apply" : "Applicant"} />
                <Step
                  title={
                    status === "Supervisor Approved"
                      ? "Approved"
                      : status === "Supervisor Reject"
                      ? "Rejected"
                      : "Supervisor"
                  }
                  status={status === "Supervisor Reject" ? "error" : ""}
                />
                <Step
                  title={
                    status === "Management Advance Approved"
                      ? "Approved"
                      : status === "Management Advance Reject"
                      ? "Rejected"
                      : "Management"
                  }
                  status={status === "Management Advance Reject" ? "error" : ""}
                />
                <Step
                  title={
                    status === "Account Advance Approved"
                      ? "Accountant"
                      : status === "Account Advance Reject"
                      ? "Rejected"
                      : "Accountant"
                  }
                  status={status === "Account Advance Reject" ? "error" : ""}
                />
              </Steps>
            </Layout.Content>

            <Form layout="vertical" form={form}>
              <TadaDetailsForm details={details} />
              {/* ======================= SUPERVISOR PART ======================= */}

              {status === "Supervisor Approved" && (
                <SupervisorForm
                  label={`Supervisor's Comment (${supervisorName})`}
                  comment="commentValue"
                />
              )}
              {/* ======================= APPROVER PART ======================= */}

              {status === "Management Advance Approved" && (
                <>
                  <SupervisorForm
                    label={`Supervisor's Comment (${supervisorName})`}
                    comment="commentValue"
                  />
                  <ApproverForm
                    label={`Approvers's Comment (${managementName})`}
                    comment="commentManagementValue"
                    amount="managementApprovedAmount"
                  />
                </>
              )}
              {/* ======================= ACCOUNTANT PART ======================= */}

              {status === "Account Advance Approved" && (
                <>
                  <SupervisorForm
                    label={`Supervisor's Comment (${supervisorName})`}
                    comment="commentValue"
                  />
                  <ApproverForm
                    label={`Approvers's Comment (${managementName})`}
                    comment="commentManagementValue"
                    amount="managementApprovedAmount"
                  />
                  <AccountForm
                    label={`Advance Arranged (${accountantName})`}
                    comment="commentAccountantValue"
                    amount="accountantApprovedAmount"
                  />
                </>
              )}
              {/* ============================ SETTLEMENT PHASE DISPLAY =============================== */}
              {(status === "Settlement Pending" ||
                status === "Supervisor Settlement Approved" ||
                status === "Management Settlement Approved" ||
                status === "Account Settlement Approved") && (
                <>
                  <SupervisorForm
                    label={`Supervisor's Comment (${supervisorName})`}
                    comment="commentValue"
                  />
                  <ApproverForm
                    label={`Approvers's Comment (${managementName})`}
                    comment="commentManagementValue"
                    amount="managementApprovedAmount"
                  />
                  <AccountForm
                    label={`Advance Arranged (${accountantName})`}
                    comment="commentAccountantValue"
                    amount="accountantApprovedAmount"
                  />
                </>
              )}
            </Form>
          </Panel>
          <Layout style={{ padding: 8 }} />
          {status === "Pending" ||
          status === "Supervisor Approved" ||
          status === "Supervisor Reject" ||
          status === "Management Advance Approved" ||
          status === "Management Advance Reject" ||
          status === "Account Advance Reject" ? null : (
            <Panel
              forceRender={applicantState}
              header="Settlement Phase"
              key="2"
              style={{ fontWeight: "bold" }}
            >
              <Steps
                key="2"
                current={
                  status === "Account Advance Approved"
                    ? 0
                    : status === "Settlement Pending"
                    ? 1
                    : status === "Supervisor Settlement Approved"
                    ? 2
                    : status === "Management Settlement Approved"
                    ? 3
                    : status === "Account Settlement Approved"
                    ? 5
                    : 4
                }
                size="small"
                className="drawer__header"
                status={rejectSettlement === true ? "error" : "process"}
              >
                <Step
                  title={
                    status === "Account Advance Approved"
                      ? "Apply"
                      : "Applicant"
                  }
                />
                <Step
                  title={
                    status === "Supervisor Settlement Approved"
                      ? "Approved"
                      : status === "Supervisor Settlement Reject"
                      ? "Rejected"
                      : "Supervisor"
                  }
                  status={
                    status === "Supervisor Settlement Reject" ? "error" : ""
                  }
                />
                <Step
                  title={
                    status === "Management Settlement Approved"
                      ? "Approved"
                      : status === "Management Settlement Reject"
                      ? "Rejected"
                      : "Management"
                  }
                  status={
                    status === "Management Settlement Reject" ? "error" : ""
                  }
                />
                <Step
                  title={
                    status === "Account Settlement Approved"
                      ? "Accountant"
                      : status === "Account Settlement Reject"
                      ? "Rejected"
                      : "Accountant"
                  }
                  status={status === "Account Settlement Reject" ? "error" : ""}
                />
              </Steps>
              <Layout.Content className="steps-content" key="2">
                {status === "Account Advance Approved" && (
                  <SettlementApplicantForm details={details} />
                )}
                <Form layout="vertical" form={form2}>
                  {status === "Settlement Pending" && (
                    <TadaDetailsSettlementForm details={details} />
                  )}
                  {/* ======================= SUPERVISOR PART ======================= */}

                  {status === "Supervisor Settlement Approved" && (
                    <>
                      <TadaDetailsSettlementForm details={details} />
                      <SettlementSupervisorForm
                        label={`Supervisor's Comment (${supervisorSettlementName})`}
                        comment="commentSettlementValue"
                      />
                    </>
                  )}
                  {/* ======================= APPROVER PART ======================= */}

                  {status === "Management Settlement Approved" && (
                    <>
                      <TadaDetailsSettlementForm details={details} />
                      <SettlementSupervisorForm
                        label={`Supervisor's Comment (${supervisorSettlementName})`}
                        comment="commentSettlementValue"
                      />
                      <SettlementApproverForm
                        label={`Approvers's Comment (${managementSettlementName})`}
                        comment="commentSettlementManagementValue"
                      />
                    </>
                  )}
                  {/* ======================= ACCOUNTANT PART ======================= */}

                  {status === "Account Settlement Approved" && (
                    <>
                      <TadaDetailsSettlementForm details={details} />
                      <SettlementSupervisorForm
                        label={`Supervisor's Comment (${supervisorSettlementName})`}
                        comment="commentSettlementValue"
                      />
                      <SettlementApproverForm
                        label={`Approvers's Comment (${managementSettlementName})`}
                        comment="commentSettlementManagementValue"
                      />
                      <SettlementAccountForm
                        label={`Advance Arranged (${accountantSettlementName})`}
                        comment="commentSettlementAccountantValue"
                        amount="settlementBalance"
                      />
                    </>
                  )}
                </Form>
              </Layout.Content>
            </Panel>
          )}
        </Collapse>
      </Drawer>
    </div>
  );
};
