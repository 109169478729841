import {
  ADMIN_SETTINGS_TYPES,
  ORGANIZATION_STRUCTURE_TYPES,
} from "../../../types";

const INITIAL_STATE = {
  organizationLayerListing: [],
  organizationLayerListingLoading: false,

  organizationLayerPagination: [],
  organizationLayerPaginationLoading: false,

  addOrganizationLayerResp: [],
  addOrganizationLayerRespLoading: false,

  updateOrganizationLayerResp: [],
  updateOrganizationLayerRespLoading: false,

  departmentListingResponse: [],
  departmentListingResponseLoading: false,

  departmentAddUpdateResponse: [],
  departmentAddUpdateResponseLoading: false,
};

export default function organizationStructureReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    // Organization Layer Listing
    case ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_LISTING_INIT:
      return {
        ...state,
        organizationLayerListing: [],
        organizationLayerListingLoading: true,
      };
    case ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_LISTING_SUCCESS:
      return {
        ...state,
        organizationLayerListing: action.payload,
        organizationLayerListingLoading: false,
      };
    case ADMIN_SETTINGS_TYPES.ORGANIZATION_LAYER_LISTING_FINISH:
      return {
        ...state,
        organizationLayerListingLoading: false,
      };

    // Organization Layer Pagination
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_PAGINATION_INIT:
      return {
        ...state,
        organizationLayerPagination: [],
        organizationLayerPaginationLoading: true,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_PAGINATION_SUCCESS:
      return {
        ...state,
        organizationLayerPagination: action.payload,
        organizationLayerPaginationLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_PAGINATION_FINISH:
      return {
        ...state,
        organizationLayerPaginationLoading: false,
      };

    // Organization layer add
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_ADD_INIT:
      return {
        ...state,
        addOrganizationLayerResp: [],
        addOrganizationLayerRespLoading: true,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_ADD_SUCCESS:
      return {
        ...state,
        addOrganizationLayerResp: action.payload,
        addOrganizationLayerRespLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_ADD_FINISH:
      return {
        ...state,
        addOrganizationLayerRespLoading: false,
      };

    // Organization layer update
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_UPDATE_INIT:
      return {
        ...state,
        updateOrganizationLayerResp: [],
        updateOrganizationLayerRespLoading: true,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_UPDATE_SUCCESS:
      return {
        ...state,
        updateOrganizationLayerResp: action.payload,
        updateOrganizationLayerRespLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_LAYER_UPDATE_FINISH:
      return {
        ...state,
        updateOrganizationLayerRespLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_DEPARTMENT_PAGINATION_INIT:
      return {
        ...state,
        departmentListingResponse: [],
        departmentListingResponseLoading: true,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_DEPARTMENT_PAGINATION_SUCCESS:
      return {
        ...state,
        departmentListingResponse: action.payload,
        departmentListingResponseLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ORGANIZATION_DEPARTMENT_PAGINATION_FINISH:
      return {
        ...state,
        departmentListingResponseLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ADD_UPDATE_DEPARTMENT_INTI:
      return {
        ...state,
        departmentAddUpdateResponse: [],
        departmentAddUpdateResponseLoading: true,
      };
    case ORGANIZATION_STRUCTURE_TYPES.ADD_UPDATE_DEPARTMENT_SUCCESS:
      let departmentAddUpdateResponse = {};
      const updatedItem = action.payload;
      const updatedItems = state.departmentListingResponse.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        } else {
          return item;
        }
      });
      const filteredItems = updatedItems.filter(
        (item) => item.id !== updatedItem.id
      );

      if (action.payload.operation === "add") {
        return {
          ...state,
          departmentAddUpdateResponse: {
            ...action.payload,
          },
          departmentListingResponse: [
            updatedItem,
            ...state.departmentListingResponse,
          ],
        };
      } else if (action.payload.operation === "update") {
        departmentAddUpdateResponse = {
          ...action.payload,
        };
        return {
          ...state,
          departmentAddUpdateResponse: departmentAddUpdateResponse,
          departmentListingResponse: updatedItems,
        };
      } else if (action.payload.operation === "delete") {
        departmentAddUpdateResponse = {
          ...action.payload,
        };
        return {
          ...state,
          departmentAddUpdateResponse: departmentAddUpdateResponse,
          departmentListingResponse: filteredItems,
        };
      }
    case ORGANIZATION_STRUCTURE_TYPES.ADD_UPDATE_DEPARTMENT_FINISH:
      return {
        ...state,
        departmentAddUpdateResponseLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.REMAINDER_LISTING_INIT:
      return {
        ...state,
        remainderListingResponse: [],
        remainderListingResponseLoading: true,
      };
    case ORGANIZATION_STRUCTURE_TYPES.REMAINDER_LISTING_SUCCESS:
      return {
        ...state,
        remainderListingResponse: action.payload,
        remainderListingResponseLoading: false,
      };
    case ORGANIZATION_STRUCTURE_TYPES.REMAINDER_LISTING_FINISH:
      return {
        ...state,
        remainderListingResponseLoading: false,
      };
    default:
      return { ...state };
  }
}
