import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";
import queryString from "query-string";
export class OrganizationLayerService {
  getOrganizationLayerListing() {
    let url = `${appBaseUrl}/organizationStructure/organizationLayerListing`;
    let data = getService(url);
    return data;
  }

  getOrganizationLayerPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizationStructure/organizationLayerPagination?${query}`;
    let data = getService(url);
    return data;
  }

  addOrganizationLayer(body) {
    let url = `${appBaseUrl}/organizationStructure/organizationLayerAdd`;
    let data = postService(url, body);
    return data;
  }

  updateOrganizationLayer(body, id) {
    let url = `${appBaseUrl}/organizationStructure/organizationLayerUpdate/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  departmentListing(type) {
    if (type === "admin") {
      let url = `${appBaseUrl}/organizationStructure/organizationDepartmentGetListing?ShowAllDepartment=true`;
      let data = getService(url);
      return data;
    } else {
      let url = `${appBaseUrl}/organizationStructure/organizationDepartmentGetListing`;
      let data = getService(url);
      return data;
    }
  }
  // appointmentMarkAsComplete(id) {
  //   let url = `${rootBaseUrl}/organizerEvent/appointmentEventMarkItOff/${id}`;
  //   let data = postService(url, '', 'patch');
  //   return data;
  // }
  departmentAddUpdate(body) {
    let url = `${appBaseUrl}/organizationStructure/organizationAddUpdateDepartment`;
    let data = postService(url, body);
    return data;
  }
  getRemainderListing() {
    let url = `${appBaseUrl}/organizerEvent/todoEventReminderTypesGetListing`;
    let data = getService(url);
    return data;
  }
}
