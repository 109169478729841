import { Col, Row } from "antd";
import AllFileViewer from "components/Documents/allFileViewer";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { cqBaseUrl } from "utils/config";
import { twoDecimalFormatter } from "utils/twoDecimalFormatter";

const CeadDetail = ({ ceadApprovalDetails }) => {
  const { token } = useSelector((state) => state.auth);
  console.log("ceadApprovalDetails", ceadApprovalDetails);

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={8}>Contract Name:</Col>
        <Col span={16}>{ceadApprovalDetails?.contract}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Reference Code:</Col>
        <Col span={16}>{ceadApprovalDetails?.referencecode}</Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={8}>Invoice Number:</Col>
        <Col span={16}>{ceadApprovalDetails?.invoiceNumber}</Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={8}>Amount:</Col>
        <Col span={16}>
          {twoDecimalFormatter({
            number: ceadApprovalDetails?.amount,
            isInteger: Number.isSafeInteger(ceadApprovalDetails?.amount),
          })}
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={8}>Issued:</Col>
        <Col span={16}>
          {moment(ceadApprovalDetails?.creationTime).format("DD MMM YYYY")}
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>files:</Col>
        <Col span={16}>
          <AllFileViewer
            file={ceadApprovalDetails?.fileUrl}
            cqBaseUrl={cqBaseUrl}
            token={token}
          />
        </Col>
      </Row>
    </>
  );
};

export default CeadDetail;
