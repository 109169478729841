import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";
import queryString from "query-string";

export class GoalEventsService {
  getGoalEventsPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizerEvent/organizerTaskEventsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  getTodayTasks(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizerEvent/organizerTaskEventsPagination?${query}`;
    let data = getService(url);
    return data;
  }
  // goal add
  addGoalEvent(body) {
    let url = `${appBaseUrl}/organizerEvent/organizerGoalEventAdd`;
    let data = postService(url, body);
    return data;
  }

  // organizer task event add
  organizerTaskEventAdd(body) {
    let url = `${appBaseUrl}/organizerEvent/organizerTaskEventAdd`;
    let data = postService(url, body);
    return data;
  }
  // organizerTaskEventGetDetails
  organizerTaskEventGetDetails(id) {
    let url = `${appBaseUrl}/organizerEvent/organizerTaskEventGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  // organizer task event update

  organizerTaskEventUpdate(body, id) {
    let url = `${appBaseUrl}/organizerEvent/organizerTaskEventUpdate/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  updateGoalEvent(body, id) {
    let url = `${appBaseUrl}/organizerEvent/goalEventUpdate/${id}`;
    let data = postService(url, body, "put");
    return data;
  }
}
