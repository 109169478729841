import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Modal, Input, Drawer } from "antd";
import { VideoCameraOutlined, PlusOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  actionForGetTutorialListing,
  actionForUpdateTutorial,
  actionForGetTutorialCategoryListing,
} from "services/redux/admin/Tutorial/action";
import TutorialTable from "./TutorialTable";
import TutorialDrawer from "./TutorialDrawer";
import TutorialModal from "./TutorialModal";
import TutorialSearch from "./TutorialSearch";
import useRoles from "hooks/useRoles";

export default function Tutorial() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  // state
  const [isTutorialDrawerOpen, setIsTutorialDrawerOpen] = useState(false);
  const [tutorial, setTutorial] = useState({
    type: "",
    add: false,
    edit: false,
    view: false,
  });
  const [setEditData, isSetEditData] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [modelTitle, setModelTitle] = useState("");
  // const [searchText, setSearchText] = useState("");
  const [searchText, setSearchText] = useState({
    search: "",
    type: "all",
  });
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [tutorialCategoryListing, setTutorialCategoryListing] = useState([]);

  const { isLimitedRoleTutorial } = useRoles();
  const {
    getTutorialListingResp,
    getTutorialListingLoading,
    getTutorialCategoryListingResp,
    getTutorialCategoryListingLoading,
  } = useSelector((state) => state.tutorialReducer);

  useEffect(() => {
    dispatch(actionForGetTutorialListing(searchText));
  }, [dispatch, searchText]);

  useEffect(() => {
    dispatch(actionForGetTutorialCategoryListing());
  }, []);

  useEffect(() => {
    if (!isEmpty(getTutorialCategoryListingResp)) {
      setTutorialCategoryListing(getTutorialCategoryListingResp);
    }
  }, [dispatch, getTutorialCategoryListingResp]);

  const onSearch = (value) => {
    // setSearchText(value);
    setSearchText({ search: value, type: searchText?.type });
  };

  const handleCategory = (value) => {
    setSearchText({ search: searchText?.search, type: value });
  };

  const handleClose = () => {
    isSetEditData("");
    form.resetFields();
    setIsFormLoading(false);
    setIsTutorialDrawerOpen(false);
  };

  const handleDelete = async (values) => {
    try {
      let deletingVal = {
        ...values,
        isActive: false,
      };
      await dispatch(actionForUpdateTutorial(values?.id, deletingVal));
    } catch (error) {
      console.log("error: ", error);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Row gutter={[20, 0]} className="dashboards__row dashboards__row--back">
        <Col lg={24} xs={24}>
          <div className="dashboards__body">
            <h5 className="dashboards__mailInfo--title">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <VideoCameraOutlined
                    style={{
                      width: 30,
                      height: 25,
                    }}
                  />
                  <span>CQ Noval Web Tutorial Videos</span>
                </div>
                {isLimitedRoleTutorial ? (
                  <Button
                    className="ant-btn btn-primary"
                    onClick={() => {
                      setTutorial({ type: "Add", add: true });
                      setIsTutorialDrawerOpen(true);
                    }}
                  >
                    <PlusOutlined />
                    Add Tutorial
                  </Button>
                ) : null}
              </div>
              <hr />
            </h5>

            <TutorialSearch
              handleCategory={handleCategory}
              searchText={searchText}
              setSearchText={setSearchText}
              onSearch={onSearch}
              listing={tutorialCategoryListing}
            />

            <TutorialTable
              isSetEditData={isSetEditData}
              isTutorialDrawerOpen={isTutorialDrawerOpen}
              setIsTutorialDrawerOpen={setIsTutorialDrawerOpen}
              setTutorial={setTutorial}
              data={getTutorialListingResp}
              loading={getTutorialListingLoading}
              isModelOpen={isModelOpen}
              setIsModelOpen={setIsModelOpen}
              youtubeUrl={youtubeUrl}
              setYoutubeUrl={setYoutubeUrl}
              setModelTitle={setModelTitle}
            />
          </div>
        </Col>
      </Row>

      <TutorialDrawer
        form={form}
        categoryType={tutorialCategoryListing}
        dispatch={dispatch}
        tutorial={tutorial}
        handleClose={handleClose}
        handleDelete={handleDelete}
        isTutorialDrawerOpen={isTutorialDrawerOpen}
        isSetEditData={isSetEditData}
        isFormLoading={isFormLoading}
        setIsFormLoading={setIsFormLoading}
        setEditData={setEditData}
        setTutorial={setTutorial}
        setIsTutorialDrawerOpen={setIsTutorialDrawerOpen}
      />

      <TutorialModal
        url={youtubeUrl}
        modelTitle={modelTitle}
        isModelOpen={isModelOpen}
        setIsModelOpen={setIsModelOpen}
      />
    </>
  );
}
