import { INSTANCE_CHAT_TYPES, REFERENCE__TYPES } from "services/types";
import { init, success, finish, error, updateOnly } from "services/common";
import { InstanceMessageChatServices } from "./api";
import { MailMessageCenterService } from "../email/mailMessageCenter/api";
import { message } from "antd";

const instanceMessageChatServices = new InstanceMessageChatServices();

const mailMessageCenterService = new MailMessageCenterService();

export const getInstanceChatCenterPagination = (query) => {
  return async (dispatch) => {
    dispatch(init(INSTANCE_CHAT_TYPES.INSTANCE_CHAT_CENTER_PAGINATION));
    const response =
      await instanceMessageChatServices.getInstanceChatCenterPagination(query);
    dispatch(finish(INSTANCE_CHAT_TYPES.INSTANCE_CHAT_CENTER_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(
          INSTANCE_CHAT_TYPES.INSTANCE_CHAT_CENTER_PAGINATION,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const instanceChatAdd = (body, isDashbord, setOpenNewMessage) => {
  return async (dispatch) => {
    dispatch(init(INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT));
    const response = await instanceMessageChatServices.instanceChatAdd(body);
    dispatch(finish(INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT));
    if (response.isSuccess) {
      dispatch(
        success(INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT, response.data)
      );
      message.success("Message sent successfull");
      // added
      setOpenNewMessage && setOpenNewMessage(false);
      if (isDashbord) {
        setOpenNewMessage(false);
      }

      // setNewId();
      //amit commet setNewId()
      // setNewId();
      // dispatch(finish(INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT))
      dispatch(updateOnly(REFERENCE__TYPES.TENDER_BASIC_GetDetails, response.data))
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const changeEmailStatusTypeInstanceMessageBusinessTask = (
  query,
  status,
  messageCenterTypeName,
  emailStatus,
  referenceValues
) => {
  // console.log("status", query);
  return async (dispatch) => {
    dispatch(init(INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT));

    const response = await mailMessageCenterService.changeEmailStatusType(
      query
    );
    if (response.isSuccess) {
      message.success("Instant Message");
      dispatch(
        success(INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT, response.data)
      );
      dispatch(finish(INSTANCE_CHAT_TYPES.INSTANCE_MESSAGE_CHAT));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
