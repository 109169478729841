import { decodedToken, isTokenExpired } from "../../../utils/misc";
import { AUTH_TYPES } from "../../types";
import { AuthService } from "./auth.api";
import { init, success, finish, error } from "services/common";
import { loginAttendance, logOutAttendance } from "services/remanentCalls";
import Storage from "utils/storage";
import { message } from "antd";
import { getApplicationConfiguration } from "../common/applicationConfiguration/action";
const authService = new AuthService();

export const autoLogout = (dispatch, exptimer, authTimer) => {
  let checkTime = parseInt((exptimer - authTimer) * 1000);
  // console.log(checkTime);
  setTimeout(() => {
    dispatch(logoutUser());
  }, checkTime);
};

export const loginUser = (body) => {
  return async (dispatch) => {
    dispatch(init(AUTH_TYPES.USER_LOGIN));
    const response = await authService.loginUser(body);
    dispatch(finish(AUTH_TYPES.USER_LOGIN));
    if (response.isSuccess) {
      const decodedTokenData = decodedToken(response.data.access_token);
      let sub = decodedTokenData.sub;
      Storage.setItem("role", decodedTokenData.role);
      Storage.setItem("role", decodedTokenData.role);
      Storage.setItem("userId", sub);
      Storage.setItem("name", decodedTokenData.name);
      autoLogout(dispatch, decodedTokenData.exp, decodedTokenData.auth_time);
      dispatch(success(AUTH_TYPES.USER_LOGIN, response.data.access_token));
      dispatch(checkInDailyAttendance());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const changePassword = (body) => {
  return async (dispatch) => {
    dispatch(init(AUTH_TYPES.USER_CHANGEPASSWORD));
    const response = await authService.changePassword(body);
    dispatch(finish(AUTH_TYPES.USER_CHANGEPASSWORD));
    if (response.isSuccess) {
      message.success("Successfully changed password!");
      dispatch(logoutUser());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const changeUsersPassword = (body) => {
  return async (dispatch) => {
    dispatch(init(AUTH_TYPES.USER_RESET_CHANGEPASSWORD));
    const response = await authService.changeUserPassword(body);
    dispatch(finish(AUTH_TYPES.USER_RESET_CHANGEPASSWORD));
    if (response.isSuccess) {
      message.success("Successfully Changed User password!");
      dispatch(success(AUTH_TYPES.USER_RESET_CHANGEPASSWORD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const reauthenticate = (token) => {
  if (isTokenExpired(token)) {
    return (dispatch) => {
      dispatch(logoutUser());
    };
  }
  return (dispatch) => {
    dispatch(success(token));
    // const _id = decodeToken(token);
    // dispatch(getUserProfile(_id));
  };
};

export const logoutUser = () => {
  logOutAttendance();
  sessionStorage.clear();
  return (dispatch) => {
    dispatch({
      type: AUTH_TYPES.USER_LOGOUT_SUCCESS,
    });
  };
};

export const checkInDailyAttendance = () => {
  return async (dispatch) => {
    dispatch(init(AUTH_TYPES.CHECK_DAILY_ATTENDENCE));
    const response = await authService.checkDaillyAttendence();
    if (response?.isSuccess) {
      dispatch(success(AUTH_TYPES.CHECK_DAILY_ATTENDENCE, response?.data));
    } else {
      dispatch(error(AUTH_TYPES.CHECK_DAILY_ATTENDENCE));
    }
  };
};

// export const leftUsers = () => {
//   return async (dispatch) => {
//     dispatch(init(AUTH_TYPES.LEFT_USERS));
//     const response = await authService.leftUsers();
//     if (response?.isSuccess) {
//       dispatch(success(AUTH_TYPES.LEFT_USERS, response?.data));
//     } else {
//       dispatch(error(AUTH_TYPES.LEFT_USERS));
//     }
//   };
// };
