import { Table } from "antd";
import { renderRightText } from "utils/rightAlignCellData";

export default function AccountSpecificDetails({ singleTenderCeadData }) {
  const className = "acc-s-cell-int";

  const columns = [
    {
      title: "Currency",
      dataIndex: "abbvr",
    },
    {
      title: renderRightText("Contract Amount", className),
      dataIndex: "contractAmount",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("Vat (%)", className),
      dataIndex: "vatPercentage",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("CV EXCL. VAT", className),
      dataIndex: "cvExclVat",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("Exclusions", className),
      dataIndex: "exclusions",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("Feeable", className),
      dataIndex: "feeableCV",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("Fee (%)", className),
      dataIndex: "feePercentage",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("Fee Amount", className),
      dataIndex: "feeAmt",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("Ex Rate", className),
      dataIndex: "exchangeRate",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("Equivalent NC Free", className),
      dataIndex: "equivalentNCFree",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("Vat % Fee Amount", className),
      dataIndex: "vatOnFeePercentage",
      render: (text) => renderRightText(text, className),
    },
    {
      title: renderRightText("Fee Amount After Vat", className),
      dataIndex: "feeAmtAfterVat",
      render: (text) => renderRightText(text, className),
    },
  ];

  const dataSource =
    singleTenderCeadData?.foreign_ContractFinancialsInformationDto?.tenderCEADContractFinancialsInformationDtos?.map(
      (x, index) => {
        let cvExclVat =
          x?.contractAmount - (x?.contractAmount * x?.vatPercentage) / 100;
        let feeableCV = cvExclVat - x?.exclusions;
        let feeAmt = (feeableCV * x?.feePercentage) / 100;
        let equivalentNCFree = feeAmt * x?.exchangeRate;
        let feeAmtAfterVat = (x?.vatOnFeePercentage / 100) * feeAmt + feeAmt;
        return {
          ...x,
          contractAmount: x?.contractAmount.toLocaleString(),
          abbvr: x?.currencyDto.abbvr,
          exclusions: x?.exclusions.toLocaleString(),
          cvExclVat: cvExclVat.toLocaleString(),
          feeableCV: feeableCV.toLocaleString(),
          feeAmt: feeAmt,
          equivalentNCFree: equivalentNCFree.toLocaleString(),
          feeAmtAfterVat: feeAmtAfterVat.toLocaleString(),
        };
      }
    );

  return (
    <>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
    </>
  );
}
