import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from "antd";
import { validateMessages } from "utils/misc";
import ColorPicker from "rc-color-picker";
import { organizerEventLabelAdd, organizerEventLabelUpdate } from "services/redux/organizer/organizer.action";

const { Header, Footer } = Layout;
const labelStyle = {
  fontSize: "12px",
  fontWeight: "500",
};
const CityAddUpdateDrawer = ({
  onAddUpdateCountryClose,
  drawerVisibility,
  isUpdateCountry,
}) => {
  const { organizerEventLabelAddResp, organizerEventLabelUpdateResp } = useSelector(
    (state) => state.organizer
  );

  const [hexColor, setHexColor] = useState();

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdateCountry) {
      form.resetFields();
      setHexColor()
      return;
    };
    form.setFieldsValue({
      ...isUpdateCountry,
    });
    setHexColor(isUpdateCountry.colorHexValue)
  }, [isUpdateCountry]);

  useEffect(() => {
    onAddUpdateCountryClose();
  }, [organizerEventLabelAddResp, organizerEventLabelUpdateResp]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (isUpdateCountry) {
        dispatch(organizerEventLabelUpdate(isUpdateCountry.id, values));
      } else {
        dispatch(organizerEventLabelAdd(values));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onAddUpdateCountryClose}
    >
      <Header className='drawer__header'>
        <h4 className='drawer__header--title'>
          {`${isUpdateCountry ? "Update" : "Create"} Organizer Label`}
        </h4>
      </Header>
      <Form
        layout='vertical'
        form={form}
        name='control-hooks'
        validateMessages={validateMessages}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name="name"
              label="Label Name"
              rules={[{ required: true }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name="colorHexValue"
              label="Pick a color"
              rules={[{ required: true }]}
            >
              <div className="color__wrap">
                <ColorPicker
                  animation="slide-up"
                  color={hexColor || "#efefef"}
                  onChange={e => {
                    setHexColor(e.color)
                    form.setFieldsValue({
                      colorHexValue: e.color
                    })
                  }}
                />
                <span className="colorTag">{hexColor}</span>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateCountryClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default CityAddUpdateDrawer;
