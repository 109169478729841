import { useEffect, useState } from "react";
import { Image, Button, message } from "antd";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "services/remanentCalls";
import { getMailMessageParticipantStatusListing } from "services/redux/email/messageCenterType/action";
import { getAllMessageApplicationType } from "services/redux/email/instanceMessageCenter/action";
import { getInstanceChatCenterPagination } from "services/redux/instanceChat/action";

import BusinessReplyChat from "./BusinessReplyChat";
import BusinessChat from "./BusinessChat";

//images
const internalMessageGreen = "assets/svg/internal-msg-12-green.svg";
const internalMessageNotGreen = "assets/svg/internal-msg-12.svg";

const hide = "assets/svg/hide-16.svg";

const BusinessChatDetails = ({
  setShowChat,
  setHideInternalMessage,
  showChat,
  hideInternalMessage,
  emailReferenceId,
  getDetails,
  showTabCategory,
  allMessageTypesResponse,
}) => {
  const [replyMsgDetails, setReplyMsgDetails] = useState({});
  const [replyStatus, setReplyStatus] = useState("");
  const [replyOfChat, setReplyofChat] = useState("");

  const [allParticipants, setAllParticipants] = useState([]);
  const [chatHeight, setChatHeight] = useState(false);
  const [smallChatHeight, setSmallChatHeight] = useState(false);
  const [chatBox, setchatBox] = useState(true);
  const dispatch = useDispatch();
  const [applicationType, setApplicationType] = useState();
  const [newApplicationType, setNewApplicationType] = useState();

  const mailMessageParticipantStatusState = useSelector(
    (state) => state.mailMessageParticipantStatus
  );

  const { mailMessageParticipationStatusListing } =
    mailMessageParticipantStatusState;

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  const instanceChatCenter = useSelector((state) => state.instanceChatCenter);

  const handleReply = (msg, status) => {
    // console.log("messagesss", msg);
    setchatBox(true);
    setReplyofChat(msg.orderNumber);
    setReplyMsgDetails(msg);
    setReplyStatus(status);
  };

  const instanceGetData = (MaxResultCount, SkipCount) => {
    dispatch(
      getInstanceChatCenterPagination({
        MaxResultCount: MaxResultCount,
        SkipCount: SkipCount,
        ApplicationId: emailReferenceId,
        ApplicationTypeId: applicationType,
      })
    );
  };

  useEffect(() => {
    if (emailReferenceId && applicationType) {
      instanceGetData(20, 0);
    }
  }, [emailReferenceId, applicationType]);

  useEffect(() => {
    dispatch(getAllMessageApplicationType());
  }, []);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setAllParticipants(resp.data);
    }
  };

  useEffect(() => {
    if (showTabCategory === "project") {
      const businessObj = allMessageTypesResponse?.find(
        (obj) => obj.systemname === "Project"
      );

      setApplicationType(businessObj?.id);
    } else if (showTabCategory === "tender") {
      const businessObj = allMessageTypesResponse?.find(
        (obj) => obj.systemname === "Business"
      );

      setApplicationType(businessObj?.id);
    }
  }, [showTabCategory, allMessageTypesResponse]);

  useEffect(() => {
    if (isEmpty(allParticipants)) {
      fetchAllParticipants();
    }

    if (isEmpty(mailMessageParticipationStatusListing)) {
      dispatch(getMailMessageParticipantStatusListing());
    }
  }, []);

  return (
    <>
      <div
        className="emailDetails__body--infos global-internal-message-body"
        style={{ zIndex: "0" }}
      >
        {hideInternalMessage ? (
          <div
            className={`emailDetails__body--chat ${
              hideInternalMessage ? "hide" : ""
            } `}
          >
            <div className="chatmail">
              <div className="chatmail__header--wrapper">
                <div className="chatmail__header--leftcontent">
                  <div className="chatmail__image">
                    <Image
                      onClick={() => {}}
                      src={
                        instanceChatCenter?.instanceChatCenterPagination?.items
                          ?.length > 0
                          ? internalMessageGreen
                          : internalMessageNotGreen
                      }
                      preview={false}
                    />
                  </div>
                  <div className="chatmail__text">Message</div>
                </div>
                <div className="chatmail__header--rightcontent">
                  <div className="chatmail__image"></div>
                  {!chatBox ? (
                    <div>
                      <Button
                        onClick={() => {
                          setchatBox(true);
                          setChatHeight(true);
                          // setReplyofChat("");
                          // setReplyStatus("");
                          // setReplyChatHeight(true);
                          // setActiveChat("");
                          // setSmallChatHeight(false);
                        }}
                        className="btn btn-internalChat"
                      >
                        +
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="chatmail__image"
                    onClick={() => {
                      setHideInternalMessage(!hideInternalMessage);
                      setchatBox(false);
                      setShowChat(false);
                      // setReplyofChat("");
                      // setReplyChatHeight(false);
                      // setActiveChat("");
                      // setSmallChatHeight(false);
                    }}
                  >
                    <img src={hide} />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`emailDetails__body--chatwrap ${
                chatHeight && "isOpenReplyChat"
              }`}
            >
              <BusinessChat
                chatHeight={chatHeight}
                smallChatHeight={smallChatHeight}
                setchatBox={setchatBox}
                handleReply={handleReply}
                setReplyStatus={setReplyStatus}
                chatBox={chatBox}
                // handleChangeEvent={handleChangeEvent}
                setHideInternalMessage={setHideInternalMessage}
                hideInternalMessage={hideInternalMessage}
                setChatHeight={setChatHeight}
                setSmallChatHeight={setSmallChatHeight}
                setShowChat={setShowChat}
              />
              <BusinessReplyChat
                setchatBox={setchatBox}
                chatBox={chatBox}
                allParticipants={allParticipants}
                chatHeight={chatHeight}
                setChatHeight={setChatHeight}
                setSmallChatHeight={setSmallChatHeight}
                smallChatHeight={smallChatHeight}
                mailMessageParticipationStatusListing={
                  mailMessageParticipationStatusListing
                }
                replyStatus={replyStatus}
                setReplyStatus={setReplyStatus}
                replyMsgDetails={replyMsgDetails}
                emailReferenceId={emailReferenceId}
                applicationType={applicationType}
                getDetails={getDetails}
              />
            </div>
          </div>
        ) : (
          <div
            className="chatmail__header--wrapper-hide chatmail__image"
            style={{ width: "38px" }}
            onClick={() => {
              setShowChat(true);
            }}
          >
            <div
              style={{ cursor: "pointer", width: "30px" }}
              onClick={() => {
                setHideInternalMessage(true);
              }}
            >
              <Image
                src={
                  instanceChatCenter?.instanceChatCenterPagination?.items
                    ?.length > 0
                    ? internalMessageGreen
                    : internalMessageNotGreen
                }
                shape="square"
                preview={false}
              />
            </div>
            <div
              style={{ cursor: "pointer", width: "30px" }}
              onClick={() => setHideInternalMessage(true)}
            >
              <Button />
            </div>

            <div
              style={{
                cursor: "pointer",
                marginTop: 5,
                width: "30px",
              }}
              onClick={() => {
                setHideInternalMessage(true);
              }}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};

export default BusinessChatDetails;
