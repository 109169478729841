import { Col, Row, Tabs } from "antd";
import { isEmpty } from "lodash";
import TabOne from "./TabOne";
import TabThree from "./TabThree";
import TabTwo from "./TabTwo";

const Attendance = ({
  setAttendenceTab,
  onFinish1,
  startDate,
  setStartDate,
  defaultDate,
  loader,
  setLoader,
  attendancePaginationResponse,
  grantedPolices,
  userId,
  setUserId,
  meetingInvitees,
  filterSoftwareType,
  calendarFilter,
  calendarRef,
  setCalendarDate,
  filterFingerType,
  fingerDetails,
  attendanceDetails,
  profileInfo,
  showDate,
  onChangeCheckbox,
  onFinish,
  setCanexport,
  canExport,
  newYear,
  newMonth,
  setNewMonth,
  isSoftware,
  calendarFilter2,
  nepaliDateResponse,
  handleDateChange,
  totalWorkingDays,
  totalDays,
  frommDate,
  absentPaginationResposne,
}) => {
  const { TabPane } = Tabs;

  const monthOptions = [
    { value: 1, label: "Baisakh" },
    { value: 2, label: "Jestha" },
    { value: 3, label: "Ashar" },
    { value: 4, label: "Shrawan" },
    { value: 5, label: "Bhadra" },
    { value: 6, label: "Ashwin" },
    { value: 7, label: "Kartik" },
    { value: 8, label: "Mangsir" },
    { value: 9, label: "Poush" },
    { value: 10, label: "Magh" },
    { value: 11, label: "Falgun" },
    { value: 12, label: "Chaitra" },
  ];

  const dateOptions = [
    { value: 2069, label: 2069 },
    { value: 2070, label: 2070 },
    { value: 2071, label: 2071 },
    { value: 2072, label: 2072 },
    { value: 2073, label: 2073 },
    { value: 2074, label: 2074 },
    { value: 2075, label: 2075 },
    { value: 2076, label: 2076 },
    { value: 2077, label: 2077 },
    { value: 2078, label: 2078 },
    { value: 2079, label: 2079 },
    { value: 2080, label: 2080 },
    { value: 2081, label: 2081 },
    { value: 2083, label: 2083 },
    { value: 2084, label: 2084 },
    { value: 2085, label: 2085 },
  ];

  const getYear = () => {
    return dateOptions.find((option) => option.value === newYear);
  };

  const handleMonthChange = (values) => {
    setNewMonth(values);
  };

  return (
    <>
      <Row gutter={[20, 0]} className='dashboards__row dashboards__row--back'>
        <Col lg={24} xs={24}>
          <Tabs
            defaultActiveKey='1'
            className='dashboard-breadcrum-wrapper'
            onChange={(tabno) => {
              setAttendenceTab(tabno);
            }}
          >
            <TabPane tab={<div style={{ width: 150 }}>All</div>} key='1'>
              <TabOne
                onFinish1={onFinish1}
                startDate={startDate}
                defaultDate={defaultDate}
                setStartDate={setStartDate}
                loader={loader}
                setLoader={setLoader}
                attendancePaginationResponse={attendancePaginationResponse}
              />
            </TabPane>
            <TabPane
              tab={<div style={{ width: 150 }}>CalendarWise</div>}
              key='2'
            >
              <TabTwo
                grantedPolices={grantedPolices}
                userId={userId}
                setUserId={setUserId}
                meetingInvitees={meetingInvitees}
                filterSoftwareType={filterSoftwareType}
                filterFingerType={filterFingerType}
                calendarFilter={calendarFilter}
                calendarRef={calendarRef}
                setCalendarDate={setCalendarDate}
                fingerDetails={fingerDetails}
                attendanceDetails={attendanceDetails}
              />
            </TabPane>

            {!isEmpty(profileInfo) &&
              profileInfo?.userRegistrationAddUpdateDto?.roleNames.includes(
                "DashboardAttendenceViewer"
              ) && (
                <TabPane
                  tab={
                    <div style={{ width: 150, display: "flex" }}>
                      Attendance list
                    </div>
                  }
                  key='3'
                >
                  <TabThree
                    showDate={showDate}
                    onChangeCheckbox={onChangeCheckbox}
                    onFinish={onFinish}
                    canExport={canExport}
                    setCanexport={setCanexport}
                    isSoftware={isSoftware}
                    calendarFilter2={calendarFilter2}
                    nepaliDateResponse={nepaliDateResponse}
                    handleDateChange={handleDateChange}
                    dateOptions={dateOptions}
                    loader={loader}
                    setLoader={setLoader}
                    handleMonthChange={handleMonthChange}
                    monthOptions={monthOptions}
                    totalWorkingDays={totalWorkingDays}
                    newMonth={newMonth}
                    getYear={getYear}
                    totalDays={totalDays}
                    frommDate={frommDate}
                    absentPaginationResposne={absentPaginationResposne}
                  />
                </TabPane>
              )}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default Attendance;
