import { SETTINGS_TYPES } from "../../types";

export const updateSettings = (setting, value) => {
    return {
      type: SETTINGS_TYPES.SETTINGS_UPDATE,
      setting,
      value,
    };
  };
  
  export const loadInitialSettings = () => ({
    type: SETTINGS_TYPES.SETTINGS_INITIAL_LOAD,
  });
  