import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService, putService } from "../../../commonServices";

export class EmployeeEvaluationService {
  addEvaluationForm(body) {
    let url = `${appBaseUrl}/requisitionManagement/employeeEvaluationRating`;
    let data = postService(url, body);
    return data;
  }

  getEvaluationPagination = (q) => {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/requisitionManagement/employeeEvaluationPagination?${query}`;
    let data = getService(url);
    return data;
  };
}
