import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDepartments } from "services/redux/adminSettings/organizationStructure/action";
import { getDepartmentEmployees } from "services/redux/adminSettings/organizationStructure/designation/action";

const DepartmentTemplateTable = ({ setOpenForm, setUpdateData }) => {
  const dispatch = useDispatch();

  const { departmentListingResponse, departmentListingResponseLoading } =
    useSelector((state) => state.organizationStructure);

  const { departmentResponse, departmentResponseLoading } = useSelector(
    (state) => state.designation
  );

  useEffect(() => {
    setUpdateData(departmentResponse);
  }, [departmentResponse]);

  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "departmentName",
      key: "departmentName",
    },
    {
      title: "Employee records",
      render: (record) => (
        <button
          style={{
            background: "none",
            border: "none",
            color: "#67a1e4",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenForm({ drawer: false, modal: true });
            dispatch(getDepartmentEmployees(record?.id));
          }}
        >
          Edit Employee
        </button>
      ),
    },
    {
      title: "Action",
      render: (record) => (
        <button
          style={{
            background: "none",
            border: "none",
            color: "#67a1e4",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenForm({
              modal: false,
              drawer: true,
            });
            dispatch(getDepartmentEmployees(record?.id));
          }}
        >
          Edit
        </button>
      ),
      width: "7%",
    },
  ];

  return (
    <Table
      style={{ fontSize: "15px" }}
      className="next-organizer-table"
      dataSource={departmentListingResponse}
      columns={columns}
      loading={{ spinning: departmentListingResponseLoading }}
    />
  );
};

export default DepartmentTemplateTable;
