import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveApplicationPagination } from "services/redux/requistions/leaveApplication/action";
import getColumnsLeave from "./Columns";

const LeaveApprovalTable = () => {
  const dispatch = useDispatch();
  const {
    leaveApplicationApprovalPagination,
    leaveApplicationApprovalPaginationLoading,
  } = useSelector((state) => state.leaveApplication);

  const columns = getColumnsLeave();

  useEffect(() => {
    const body = {
      IsPendingRequisition: true,
    };
    dispatch(getLeaveApplicationPagination(body, undefined, "approval"));
  }, []);

  return (
    <>
      <h3 className="header__all">
        <span className="title">To Approve Leave</span>
      </h3>

      <Table
        className="approve-leave"
        dataSource={leaveApplicationApprovalPagination?.items}
        columns={columns}
      />
    </>
  );
};

export default LeaveApprovalTable;
