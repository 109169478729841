import { appBaseUrl } from "utils/config";
// import queryString from 'query-string';
import { getService, postService, putService } from "../../../commonServices";

export class RequisitionService {
  getLeaveTypeListing() {
    let url = `${appBaseUrl}/requisitionManagement/leaveTypeListing`;
    let data = getService(url);
    return data;
  }

  getRequisitionApplicationStatusListing() {
    let url = `${appBaseUrl}/requisitionManagement/requisitionApplicationStatusListing`;
    let data = getService(url);
    return data;
  }
  getLeaveDetails(id) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  updateLeaveManagement(body, id) {
    let url = `${appBaseUrl}/requisitionManagement/leaveApplicationApproveRejectDashboard/${id}`;
    let data = putService(url, body);
    return data;
  }
  guestEntertainmentApply(body) {
    let url = `${appBaseUrl}/requisitionManagement/guestEntertainmentCreateApplication`;
    let data = postService(url, body);
    return data;
  }
  getGuestEntertainmentAmountList() {
    let url = `${appBaseUrl}/requisitionManagement/guestEntertainmentRatingAmountGetListing`;
    let data = getService(url);
    return data;
  }
  guestInformation(id) {
    let url = `${appBaseUrl}/requisitionManagement/guestEntertainmentGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  guestEntertainmentSupervisor(id, body) {
    let url = `${appBaseUrl}/requisitionManagement/guestEntertainmentSupervisorAction/${id}`;
    let data = putService(url, body);
    return data;
  }
  guestEntertainmentManagementAction(id, body) {
    console.log("I am at supervisor state of accountant");
    let url = `${appBaseUrl}/requisitionManagement/guestEntertainmentManagementAction/${id}`;
    let data = putService(url, body);
    return data;
  }
  guestEntertainmentAccountantAction(id, body) {
    let url = `${appBaseUrl}/requisitionManagement/guestEntertainmentAccountantAction/${id}`;
    let data = putService(url, body);
    return data;
  }
}
