import { ORGANIZER_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { PriorityListingService } from "./api";

const priorityListingService = new PriorityListingService();

export const getOrganizerEventPriorityListings = () => {
  return async (dispatch) => {
    dispatch(init(ORGANIZER_TYPES.PRIORITY_EVENTS_LISTING));
    const response = await priorityListingService.getOrganizerEventPriorityListings();
    dispatch(finish(ORGANIZER_TYPES.PRIORITY_EVENTS_LISTING));
    if (response.isSuccess) {
      dispatch(success(ORGANIZER_TYPES.PRIORITY_EVENTS_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};