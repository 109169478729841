import React, { useState } from "react";
import Summary from "./Summary";
import { AddUpdateOther } from "./AddUpdateOther";
import { Tabs, Row, Col } from "antd";

const { TabPane } = Tabs;

const ReferenceOthers = ({
  isUpdate,
  setIsUpdate,
  showChat,
  setShowChat,
  referenceType,
  setReferenceType,
}) => {
  // const [addUpdateSectorDrawerVisibility, setaddUpdateSectorDrawerVisibility] = useState(false);
  // const [isUpdateSector, setIsUpdateSector] = useState(false);

  // const showAddUpdateSectorDrawer = (e, record) => {
  // 	setaddUpdateSectorDrawerVisibility(false);
  // 	if (record) {
  // 		setIsUpdateSector(record);
  // 	} else {
  // 		setIsUpdateSector(false);
  // 	}
  // 	setaddUpdateSectorDrawerVisibility(true);
  // };

  // const onAddUpdateSectorClose = () => {
  // 	setIsUpdateSector('');
  // 	setaddUpdateSectorDrawerVisibility(false);
  // };
  return (
    <div>
      <Tabs
        className="other-tabs-wrapper global-tabs-wrapper"
        defaultActiveKey="1"
        size="large"
      >
        <TabPane
          className={`summary-wrapper ${showChat && "chatOpen"}`}
          tab="Summary"
          key="1"
        >
          <Summary
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            showChat={showChat}
            setShowChat={setShowChat}
            referenceType={referenceType}
            setReferenceType={setReferenceType}
          />
        </TabPane>
        {/* <TabPane tab="Communication" key="2">
					Communication
				</TabPane>
				<TabPane tab=" Tasks" key="3">
					Tasks
				</TabPane>
				<TabPane tab="Companies" key="4">
					Companies
				</TabPane>
				<TabPane tab="Docs" key="5">
					Docs
				</TabPane>
				<TabPane tab="Reports" key="6">
					Reports
				</TabPane>
				<TabPane tab="Fees" key="7">
					Fees
				</TabPane> */}
      </Tabs>
    </div>
  );
};

export default ReferenceOthers;
