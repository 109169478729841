import { Button } from "antd";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";

const getColumns = (
  setLoanDetailsId,
  setShowLoanDetails,
  setHideLoanDrawer
) => {
  return [
    {
      title: "S.NO",
      dataIndex: "",
      key: "",
      render: (text, row, index) => <div>{index + 1}</div>,
      width: "5%",
    },
    {
      title: "Loan Application Date",
      key: "loanApplicationDate",
      render: (record) => (
        <span>{moment(record.creationTime).format("YYYY/MM/DD")}</span>
      ),
    },
    {
      title: "Company Name",
      key: "companyName",
      render: (record) => <span>{record?.sisterCompanyDto?.name}</span>,
    },
    {
      title: "Amount Requested",
      dataIndex: "requestedAmount",
      key: "requestedAmount",
    },
    {
      title: "Application Status",
      // dataIndex: 'loanApplicationStatusDtos',
      key: "applicationStatus",
      render: (record) => (
        <span className="application_status_name">
          {record?.loanApplicationStatusDtos?.displayName}
        </span>
      ),
    },
    {
      title: "Approved Amount",
      dataIndex: "approvedAmount",
      key: "approvedAmount",
      render: (record) => <span>{record}</span>,
      width: "10%",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: "25%",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Button
          htmlType="submit"
          style={{ border: 0, backgroundColor: "transparent" }}
          icon={<EditOutlined />}
          onClick={() => {
            console.log("ID CONSOLE", record?.id);
            setHideLoanDrawer(false);
            setShowLoanDetails(true);
            setLoanDetailsId(record?.id);
          }}
        />
      ),
    },
  ];
};

export default getColumns;
