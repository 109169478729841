import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { actionForAddUpdateCEADPaymentReceivedByPrincipalAddUpdateInformation } from "services/redux/cead/action";
import { useDispatch } from "react-redux";
import commaSeparatorforList from "utils/commaSeparatorList";

const { Item, List } = Form;

export default function PaymentReceivedForm({
  currencyOptions,
  singleTenderCeadData,
  paymentRecivedByPrincipalForm,
}) {
  const dispatch = useDispatch();

  const handleVariationAmtChange = (index) => (e) => {
    const variationAmt = e.target.value?.replace(/,/g, "");

    const items =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        .paymentReceivedByPrincipal;

    const vatPer =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        ?.paymentReceivedByPrincipal?.[index]?.vatPercentage ?? 0;
    const cvExcAmt = variationAmt - (vatPer / 100) * variationAmt;
    const exclusions =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        ?.paymentReceivedByPrincipal?.[index]?.exclusions ?? 0;
    const feeableCv = cvExcAmt - exclusions;
    const feePercentage =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        ?.paymentReceivedByPrincipal?.[index]?.feePercentage ?? 0;
    const feeAmt = (feePercentage / 100) * feeableCv;
    const exchangeRate =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        ?.paymentReceivedByPrincipal?.[index]?.exchangeRate ?? 1;
    const equivalentNC = exchangeRate * feeAmt;

    items[index].variationAmount = commaSeparatorforList(e);
    items[index].cvExclVat = cvExcAmt.toLocaleString();
    items[index].feeableCv = feeableCv.toLocaleString();
    items[index].feeAmt = feeAmt.toLocaleString();
    items[index].equivalentNC = equivalentNC.toLocaleString();

    paymentRecivedByPrincipalForm.setFieldsValue({
      paymentReceivedByPrincipal: items,
    });
  };

  const handleVatPerChange = (index) => (e) => {
    const items =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        .paymentReceivedByPrincipal;
    const variationAmt = items[index].variationAmount.replace(/,/g, "");

    const vatPer = e.target.value;
    // console.log("vatPer", vatPer);
    const cvExcAmt = variationAmt - (vatPer / 100) * variationAmt;
    const exclusions =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        ?.paymentReceivedByPrincipal?.[index]?.exclusions ?? 0;
    const feeableCv = cvExcAmt - exclusions;
    const feePercentage =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        ?.paymentReceivedByPrincipal?.[index]?.feePercentage ?? 0;
    const feeAmt = (feePercentage / 100) * feeableCv;
    const exchangeRate =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        ?.paymentReceivedByPrincipal?.[index]?.exchangeRate ?? 1;
    const equivalentNC = exchangeRate * feeAmt;

    items[index].cvExclVat = cvExcAmt.toLocaleString();
    items[index].feeableCv = feeableCv.toLocaleString();
    items[index].feeAmt = feeAmt.toLocaleString();
    items[index].equivalentNC = equivalentNC.toLocaleString();

    paymentRecivedByPrincipalForm.setFieldsValue({
      paymentReceivedByPrincipal: items,
    });
  };

  const handleExclusionChange = (index) => (e) => {
    const items =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        .paymentReceivedByPrincipal;

    const exclusionsValue = e.target.value.replace(/,/g, "");
    const cvExclVat = items[index].cvExclVat?.replace(/,/g, "");
    const feePercentage =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        ?.paymentReceivedByPrincipal?.[index]?.feePercentage ?? 0;
    const exchangeRate = items[index].exchangeRate ?? 0;
    const feeableCv = cvExclVat - exclusionsValue;
    const feeAmt = (feePercentage / 100) * feeableCv;
    const equivalentNC = exchangeRate * feeAmt;

    items[index].exclusions = commaSeparatorforList(e);
    items[index].feeableCv = feeableCv.toLocaleString();
    items[index].feeAmt = feeAmt.toLocaleString();
    items[index].equivalentNC = equivalentNC.toLocaleString();

    paymentRecivedByPrincipalForm.setFieldsValue({
      paymentReceivedByPrincipal: items,
    });
  };

  const handleFeePercentageChange = (index) => (e) => {
    const items =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        .paymentReceivedByPrincipal;

    const feePercentageValue = e.target.value;
    const feeableCv = items[index].feeableCv?.replace(/,/g, "");
    const feeAmt = (feePercentageValue / 100) * feeableCv;
    const exchangeRate =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        ?.paymentReceivedByPrincipal?.[index]?.exchangeRate ?? 0;
    const equivalentNC = exchangeRate * feeAmt;

    items[index].feeAmt = feeAmt.toLocaleString();
    items[index].equivalentNC = equivalentNC.toLocaleString();

    paymentRecivedByPrincipalForm.setFieldsValue({
      paymentReceivedByPrincipal: items,
    });
  };

  const handleExRateChange = (index) => (e) => {
    const items =
      paymentRecivedByPrincipalForm?.getFieldsValue()
        .paymentReceivedByPrincipal;

    const exchangeRate = e.target.value;
    const feeAmt = items[index].feeAmt.replace(/,/g, "");
    const equivalentNC = feeAmt * exchangeRate;

    items[index].equivalentNC = equivalentNC.toLocaleString();

    paymentRecivedByPrincipalForm.setFieldsValue({
      paymentReceivedByPrincipal: items,
    });
  };

  const onFinishPaymentReceivedByPrincipalForm = (values) => {
    let apiData = {
      variationDate: values?.variationDate,
      remarks: values.remarks,
      tenderPaymentReceivedPrincipalInformationDtos:
        values?.paymentReceivedByPrincipal.map((x) => {
          return {
            isActive: true,
            ...x,
          };
        }),
    };

    dispatch(
      actionForAddUpdateCEADPaymentReceivedByPrincipalAddUpdateInformation(
        apiData,
        singleTenderCeadData?.id
        // "CEADFinancialType=3"
      )
    );
  };

  return (
    <>
      <Card>
        <Form
          onFinish={onFinishPaymentReceivedByPrincipalForm}
          form={paymentRecivedByPrincipalForm}
        >
          <Item label="Date" name="variationDate">
            <DatePicker style={{ width: "250px", height: "30px" }} />
          </Item>

          <List name="paymentReceivedByPrincipal">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={field.key} gutter={[10, 0]}>
                    <Col span={1}>{index + 1}</Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="Currency"
                        name={[field.name, "currencyId"]}
                        fieldKey={[field.fieldKey, "currencyId"]}
                        rules={[
                          {
                            required: true,
                            message: "Currency is required",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Select
                          options={currencyOptions}
                          optionFilterProp="label"
                          placeholder="Select"
                          allowClear
                        />
                      </Item>
                    </Col>

                    <Col span={4}>
                      <Item
                        key={field.key}
                        label="Amount Received By Principal"
                        name={[field.name, "variationAmount"]}
                        fieldKey={[field.fieldKey, "variationAmount"]}
                        rules={[
                          {
                            required: true,
                            message: "Amount Received By Principal is required",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Enter ..."
                          onChange={handleVariationAmtChange(index)}
                        />
                      </Item>
                    </Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="Vat %"
                        name={[field.name, "vatPercentage"]}
                        fieldKey={[field.fieldKey, "vatPercentage"]}
                        rules={[
                          {
                            required: true,
                            message: "Vat % is required",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Enter ..."
                          onChange={handleVatPerChange(index)}
                        />
                      </Item>
                    </Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="CV Excl. Vat"
                        name={[field.name, "cvExclVat"]}
                        fieldKey={[field.fieldKey, "cvExclVat"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter ..." readOnly />
                      </Item>
                    </Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="Exclusions"
                        name={[field.name, "exclusions"]}
                        fieldKey={[field.fieldKey, "exclusions"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Enter ..."
                          onChange={handleExclusionChange(index)}
                        />
                      </Item>
                    </Col>

                    <Col span={3}>
                      <Item
                        key={field.key}
                        label="Fee'able CV"
                        name={[field.name, "feeableCv"]}
                        fieldKey={[field.fieldKey, "feeableCv"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter ..." />
                      </Item>
                    </Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="Fee %"
                        name={[field.name, "feePercentage"]}
                        fieldKey={[field.fieldKey, "feePercentage"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Enter ..."
                          onChange={handleFeePercentageChange(index)}
                        />
                      </Item>
                    </Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="Fee Amt"
                        name={[field.name, "feeAmt"]}
                        fieldKey={[field.fieldKey, "feeAmt"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter ..." />
                      </Item>
                    </Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="Ex. Rate"
                        name={[field.name, "exchangeRate"]}
                        fieldKey={[field.fieldKey, "exchangeRate"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Enter ..."
                          onChange={handleExRateChange(index)}
                        />
                      </Item>
                    </Col>

                    <Col span={2}>
                      <Item
                        key={field.key}
                        label="EQ NC"
                        name={[field.name, "equivalentNC"]}
                        fieldKey={[field.fieldKey, "equivalentNC"]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input placeholder="Enter ..." />
                      </Item>
                    </Col>

                    <Col span={24}>
                      {index >= 0 && (
                        <Button
                          style={{ float: "right", marginTop: "-5px" }}
                          icon={<MinusCircleOutlined />}
                          onClick={() => remove(field.name)}
                        />
                      )}
                    </Col>
                  </Row>
                ))}
                <Button
                  style={{ marginBottom: "16px" }}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusCircleOutlined />}
                >
                  Add Payment Received by Principal Details
                </Button>
              </>
            )}
          </List>

          <Item label="Remarks" name="remarks">
            <Input.TextArea />
          </Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{ float: "right", marginTop: "5px" }}
          >
            Save
          </Button>
        </Form>
      </Card>
    </>
  );
}
