import React from "react";
import { Modal } from "antd";
import YoutubePlayer from "components/Tutorial/YoutubePlayer";

function TutorialModal({ url, modelTitle, isModelOpen, setIsModelOpen }) {
  const handleOk = () => {
    setIsModelOpen(false);
  };
  const handleCancel = () => {
    setIsModelOpen(false);
  };
  return (
    <div>
      {isModelOpen ? (
        <Modal
          title={modelTitle}
          width={740}
          visible={isModelOpen}
          centered={true}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <YoutubePlayer url={url} />
        </Modal>
      ) : null}
    </div>
  );
}

export default TutorialModal;
