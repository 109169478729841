import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { isEmpty } from "lodash";
import {
  actionForAddTutorial,
  actionForUpdateTutorial,
} from "services/redux/admin/Tutorial/action";

function TutorialForm(props) {
  const { Option } = Select;
  const {
    form,
    categoryType,
    dispatch,
    type,
    record,
    tutorial,
    isSetEditData,
    isFormLoading,
    setIsFormLoading,
    setIsTutorialDrawerOpen,
    handleClose,
  } = props;

  const options = [
    ...(categoryType || []).map((item) => ({
      value: item.typeName.toLowerCase(),
      label: item.typeName,
      id: item?.id,
    })),
  ];

  useEffect(() => {
    if (type === "edit" && !isEmpty(record)) {
      form.setFieldsValue({
        title: record?.title,
        description: record?.description,
        youTubeLink: record?.youTubeLink,
        tutorialTypeId: record?.tutorialTypeId,
      });
    } else {
      form.resetFields();
    }
  }, [type, record, form]);

  const handleChange = (value) => {
    console.log("Selected Value: ", value);
  };

  const onFinish = async (values) => {
    try {
      setIsFormLoading(true);
      if (!isEmpty(record)) {
        let id = record?.id;
        await dispatch(actionForUpdateTutorial(id, values));
      } else {
        await dispatch(actionForAddTutorial(values));
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      handleClose();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo: ", errorInfo);
  };

  return (
    <>
      <Form
        name="Tutorial Form"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ padding: "10px" }}
      >
        <div className="drawer-form-group">
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="tutorialTypeId"
            label="Category"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select onChange={handleChange}>
              {options.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="youTubeLink"
            label="Youtube url"
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>
        </div>

        <div className="model-footer" style={{ justifyContent: "flex-end" }}>
          <Form.Item>
            <Button type="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isFormLoading}>
              {tutorial?.add ? "Submit" : "Update"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

export default TutorialForm;
