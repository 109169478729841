import { ADMIN_TYPES } from "services/types";
import { unionBy } from "lodash";

const INITIAL_STATE = {
  getTutorialListingResp: [],
  getTutorialListingLoading: false,

  addTutorialResp: [],
  addTutorialLoading: false,

  updateTutorialResp: [],
  updateTutorialLoading: false,

  getTutorialCategoryListingResp: [],
  getTutorialCategoryListingLoading: false,

  addTutorialCategoryResp: [],
  addTutorialCategoryRespLoading: false,

  getTutorialCategoryDetailResp: [],
  getTutorialCategoryDetailLoading: false,
};

export default function tutorialReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // listing
    case ADMIN_TYPES.TUTORIAL_TYPE_LISTING_INIT:
      return {
        ...state,
        getTutorialListingResp: [],
        getTutorialListingLoading: true,
      };

    case ADMIN_TYPES.TUTORIAL_TYPE_LISTING_SUCCESS:
      return {
        ...state,
        getTutorialListingResp: action.payload,
        getTutorialListingLoading: false,
      };

    case ADMIN_TYPES.TUTORIAL_TYPE_LISTING_FINISH:
      return {
        ...state,
        getTutorialListingLoading: false,
      };

    // add
    case ADMIN_TYPES.TUTORIAL_TYPE_ADD_INIT:
      return {
        ...state,
        addTutorialResp: [],
        addTutorialLoading: true,
      };

    case ADMIN_TYPES.TUTORIAL_TYPE_ADD_SUCCESS:
      const updatedListAfterAdd = [
        ...state?.getTutorialListingResp,
        action.payload,
      ].sort((a, b) => a.title.localeCompare(b.title));

      return {
        ...state,
        addTutorialLoading: false,
        getTutorialListingResp: updatedListAfterAdd,
      };

    case ADMIN_TYPES.TUTORIAL_TYPE_ADD_FINISH:
      return {
        ...state,
        addTutorialLoading: false,
      };

    // update
    case ADMIN_TYPES.TUTORIAL_TYPE_UPDATE_INIT:
      return {
        ...state,
        updateTutorialLoading: true,
      };

    case ADMIN_TYPES.TUTORIAL_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        updateTutorialLoading: false,
        updateTutorialResp: action?.payload,
        getTutorialListingResp: state?.getTutorialListingResp
          ?.map((item) => {
            if (item.id === action.payload?.id) {
              return action.payload.isActive ? { ...action.payload } : null;
            } else {
              return {
                ...item,
              };
            }
          })
          .filter((item) => item !== null)
          .sort((a, b) => a.title.localeCompare(b.title)),
      };

    case ADMIN_TYPES.TUTORIAL_TYPE_UPDATE_FINISH:
      return {
        ...state,
        updateTutorialLoading: false,
      };

    // CATEGORY

    // listing
    case ADMIN_TYPES.TUTORIAL_CATEGORY_LISTING_INIT:
      return {
        ...state,
        getTutorialCategoryListingLoading: true,
      };

    case ADMIN_TYPES.TUTORIAL_CATEGORY_LISTING_SUCCESS:
      return {
        ...state,
        getTutorialCategoryListingResp: action.payload,
        getTutorialCategoryListingLoading: false,
      };

    case ADMIN_TYPES.TUTORIAL_CATEGORY_LISTING_FINISH:
      return {
        ...state,
        getTutorialCategoryListingLoading: false,
      };

    // add
    case ADMIN_TYPES.TUTORIAL_CATEGORY_ADD_INIT:
      return {
        ...state,
        getTutorialCategoryListingLoading: true,
      };

    case ADMIN_TYPES.TUTORIAL_CATEGORY_ADD_SUCCESS:
      const updatedAfterAdd = [
        ...state.getTutorialCategoryListingResp,
        action.payload,
      ];

      return {
        ...state,
        getTutorialCategoryListingLoading: false,
        getTutorialCategoryListingResp: updatedAfterAdd,
      };

    case ADMIN_TYPES.TUTORIAL_CATEGORY_ADD_FINISH:
      return {
        ...state,
        getTutorialCategoryListingLoading: false,
      };

    // update
    case ADMIN_TYPES.TUTORIAL_CATEGORY_UPDATE_INIT:
      return {
        ...state,
        getTutorialCategoryListingLoading: true,
      };

    case ADMIN_TYPES.TUTORIAL_CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        getTutorialCategoryListingLoading: false,
        getTutorialCategoryListingResp: state?.getTutorialCategoryListingResp
          ?.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload.isActive ? { ...action.payload } : null;
            } else {
              return { ...item };
            }
          })
          .filter((item) => item !== null),
      };

    case ADMIN_TYPES.TUTORIAL_CATEGORY_UPDATE_FINISH:
      return {
        ...state,
        getTutorialCategoryListingLoading: false,
      };

    case ADMIN_TYPES.TUTORIAL_CATEGORY_DETAIL_INIT:
      return {
        ...state,
      };

    case ADMIN_TYPES.TUTORIAL_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        getTutorialCategoryDetailResp: action.payload,
        getTutorialCategoryDetailLoading: false,
      };

    case ADMIN_TYPES.TUTORIAL_CATEGORY_DETAIL_FINISH:
      return {
        ...state,
        getTutorialCategoryDetailLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
