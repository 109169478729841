import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Layout, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateDepartment } from "services/redux/adminSettings/organizationStructure/action";

const DepartmentTemplateAddUpdateForm = ({
  openForm,
  setOpenForm,
  updateData,
  setUpdateData,
}) => {
  const { Header } = Layout;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { departmentAddUpdateResponseLoading } = useSelector(
    (state) => state.organizationStructure
  );

  const [deleteItem, setDeleteItem] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      departmentName: updateData?.departmentName,
    });
  }, [updateData]);

  useEffect(() => {
    if (departmentAddUpdateResponseLoading) {
      setOpenForm(false);
    }
  }, [departmentAddUpdateResponseLoading]);

  const onFinish = (values) => {
    values.isActive = true;
    if (updateData) {
      values.id = updateData?.id;
    }
    if (deleteItem) {
      values.isActive = false;
    }

    dispatch(addUpdateDepartment(values, updateData, deleteItem)).then(
      setOpenForm(false)
    );
    form.resetFields();
  };

  return (
    <Drawer
      visible={openForm.drawer}
      width={551}
      maskClosable={false}
      className="drawer"
      closable={false}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">
          {`${updateData ? "Update " : "Add "}Department`}
        </h4>
      </Header>
      <Form form={form} className="drawer-form-wrapper" onFinish={onFinish}>
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name="departmentName"
              label="Department Name"
              rules={[{ required: true }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </div>
        </div>

        <div className="model-footer">
          {updateData && (
            <Button
              type="danger"
              htmlType="submit"
              onClick={() => setDeleteItem(true)}
            >
              Delete
            </Button>
          )}
          <Button
            onClick={() => {
              setOpenForm(false);
              setUpdateData("");
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={departmentAddUpdateResponseLoading}
          >
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default DepartmentTemplateAddUpdateForm;
