import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Layout,
  Input,
  Select,
  Space,
  Form,
  DatePicker,
  Checkbox,
  message,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { getAllUsers } from "services/remanentCalls";
const { Header } = Layout;
const { Option } = Select;

const LeaveApprovalFormDrawer = ({
  drawerVisibility,
  onAddUpdateDrawerClose,
  isUpdateApplication,
}) => {
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();

  form.resetFields();

  useEffect(() => {
    fetchUsersListing();
  }, []);

  const fetchUsersListing = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setUsers(resp.data);
    }
  };

  return (
    <div>
      <Drawer
        width={472}
        maskClosable={false}
        onClose={onAddUpdateDrawerClose}
        visible={drawerVisibility}
        form={form}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">Leave Approval</h4>
        </Header>
        <div className="drawer__content">
          <div className="drawer__content--wrapper">
            <div className="contact__content__wrapper">
              <Form name="dynamic_form_nest_item">
                <Form.Item className="form__group" label="Applicant">
                  <Select
                    mode="multiple"
                    showSearch={true}
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {users.map((user) => (
                      <Option key={user.appUserId}>{user.fullName}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item className="form__group" label="Approver">
                  <Input />
                </Form.Item>
                <Form.Item
                  className="form__group"
                  label="Who else get notified"
                >
                  <Input />
                </Form.Item>
                <Form.Item className="form__group" label="Effective From">
                  <DatePicker />
                </Form.Item>
                <Form.Item className="form__group">
                  <Checkbox>Active</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type="primary">Submit</Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default LeaveApprovalFormDrawer;
