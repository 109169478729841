import React, { useState } from "react";
import { Form, Modal, Table } from "antd";
import { WechatOutlined } from "@ant-design/icons";
import InternalMCompose from "components/internalMNew";

const BudgetFrontCEMAT = ({ rotate, setRotate, columns, dataSource }) => {
  const [form] = Form.useForm();

  const [internalVisible, setInternalVisible] = useState(false);

  return (
    <>
      <div className="flip-card-front">
        {" "}
        <div className=" honor-wrapper" style={{ paddingRight: 10 }}>
          <div
            className=" dashboards__events dashboards__body "
            style={{ height: 320 }}
          >
            <div className="dashboards__bodyWrap">
              <h4 className="dashboards__body--title">
                Budget/Expense/Income/Rent Included of CEMAT
              </h4>
              <WechatOutlined
                onClick={() => {
                  setInternalVisible(true);
                }}
              />
            </div>
            <Table
              className="next-organizer-table"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              style={{ maxHeight: 270, overflow: "auto" }}
            />
          </div>
        </div>
      </div>
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="Budget/Expense/Income/Rent"
        />
      </Modal>
    </>
  );
};

export default BudgetFrontCEMAT;
