import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from 'antd';

import { validateMessages } from 'utils/misc';
import { referenceTypeAdd, referenceTypeUpdate } from 'services/redux/Reference/action';

const { Option } = Select;

const { Header, Footer } = Layout;

const TypeAddUpdateDrawer = ({ onAddUpdateTypeClose, drawerVisibility, isUpdateType }) => {
	const { referenceTypeAddResponse, referenceTypeUpdateResponse } = useSelector((state) => state.reference);

	const [form] = Form.useForm();

	form.resetFields();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isUpdateType) return;
		form.setFieldsValue({
			...isUpdateType,
		});
	}, [isUpdateType]);

	useEffect(() => {
		onAddUpdateTypeClose();
	}, [referenceTypeAddResponse, referenceTypeUpdateResponse]);

	const onSubmit = async () => {
		try {
			let values = await form.validateFields();
			if (isUpdateType) {
				dispatch(referenceTypeUpdate(isUpdateType.id, values));
			} else {
				dispatch(referenceTypeAdd(values));
			}
		} catch (errorInfo) {
			console.error('Failed:', errorInfo);
		}
	};

	return (
		<Drawer visible={drawerVisibility} width={472} maskClosable={false} className="drawer" onClose={onAddUpdateTypeClose} closable={false}>
			<Header className="drawer__header">
				<h4 className="drawer__header--title">{`${isUpdateType ? 'Update' : 'Create'} Type Name`}</h4>
			</Header>

			<Form layout="vertical" form={form} name="control-hooks" validateMessages={validateMessages} className="drawer-form-wrapper">
				<div className="drawer-form-row">
					<div className='drawer-form-group'>
						<Form.Item className="form__group" name="referenceTypeName" label="Type Name" rules={[{ required: true }]}>
							<Input autoComplete='off' />
						</Form.Item>
					
					</div>
					<div className="drawer-form-group">
						<Form.Item className="form__group" name="symbol" label="Symbol" rules={[{ required: true }]}>
							<Input autoComplete='off' />
						</Form.Item>
					</div>
				</div>
				<div className="model-footer">
					<Button onClick={onAddUpdateTypeClose}>Cancel</Button>
					<Button onClick={onSubmit} type="primary">
						Submit
					</Button>
				</div>
			</Form>
		</Drawer>
	);
};

export default TypeAddUpdateDrawer;
