import TextArea from "antd/lib/input/TextArea";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { attendenceLateReasonAdd } from "services/redux/Home/action";
import { validateReason } from "utils/validateReason";
import { dynamicFileLabelTypeUploaderDetailDtos } from "services/remanentCalls";
import ImageUploaderMultiple from "components/shared/FileUploaderApproval";
import { getRemainingLeaveApplicationTypesForEmployee } from "services/redux/requistions/leaveApplication/action";

const { Modal, Form, Input, Spin, Alert, Select, message } = require("antd");
const { Option } = Select;

const LateAttendence = ({
  id,
  data,
  leaveReasonPopUp,
  leaveStartdate,
  leaveEndDate,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [uploaders, setUploaders] = useState();
  const [deafultLeaveType, setDefaultLeaveType] = useState([]);

  const { attendenceAddNewResponse, attendenceAddNewResponseLoading } =
    useSelector((state) => state.homeServices);
  const {
    remainingLeaveApplicationTypesListing,
    remainingLeaveApplicationTypesListingLoading,
    addLeaveApplicationResp,
  } = useSelector((state) => state.leaveApplication);

  useEffect(() => {
    fetchDynamicFileLabelTypeUploaderDetailDtos();
  }, []);

  useEffect(() => {
    dispatch(getRemainingLeaveApplicationTypesForEmployee());
  }, []);

  useEffect(() => {
    if (!isEmpty(remainingLeaveApplicationTypesListing)) {
      const casualLeave = remainingLeaveApplicationTypesListing.find(
        (obj) => obj.leaveApplicationTypeDto.leaveTypeName === "Casual"
      );
      if (casualLeave?.leaveApplicationTypeDto?.id) {
        form.setFieldsValue({
          leaveApplicationTypeId: casualLeave?.leaveApplicationTypeDto?.id,
        });
      }
    }
  }, [remainingLeaveApplicationTypesListing]);

  const fetchDynamicFileLabelTypeUploaderDetailDtos = async () => {
    const resp = await dynamicFileLabelTypeUploaderDetailDtos("Leave").catch(
      (err) => {
        message.error("Failure");
      }
    );

    if (resp && resp.status === 200) {
      let uploaders = resp.data.filter(
        (respData) => respData.code === "LEAVEATTACHMENT"
      );
      setUploaders(uploaders);
    }
  };

  const onFinish = (values) => {
    const body = { ...values, attendanceDetailId: id };
    dispatch(attendenceLateReasonAdd(body));
    // console.log("body: ", body)
  };
  if (attendenceAddNewResponse === true) {
    return <Redirect to="dashBoard" />;
  }

  const startDate = moment(leaveStartdate);
  const endDate = moment(leaveEndDate);
  const durationInDays = endDate.diff(startDate, "days");

  const handleChange = (value, option) => {
    const selectedTitle = option ? option.title : null;
    const selectedValue = option ? option.value : null;

    setDefaultLeaveType({
      title: selectedTitle,
      value: selectedValue,
    });
  };

  return (
    <div className="late_login_popup">
      <Modal
        visible={true}
        footer={null}
        closable={false}
        wrapClassName="late_login_popup_modal"
      >
        {data && (
          <div className="time_format_login_popup">
            Login Time:{moment(data).format("YYYY-MM-DD,HH:mm:ss A")}
          </div>
        )}
        <div className="late_attendence_popup_header">
          <h3>
            Kindly elaborate your reason for being late{" "}
            {leaveReasonPopUp && "and also leave reason"} !!!
          </h3>
        </div>
        <br />
        <Form form={form} onFinish={onFinish}>
          <div style={{ fontWeight: "600" }}>Late Reason :</div>
          <Form.Item
            rules={[
              // {
              //     required:true,
              //     message: "Please justify the reason",
              // },
              validateReason(10, 2),
            ]}
            name="attendanceLateReason"
            // label="Late Reason"
          >
            <TextArea rows={4} style={{ fontSize: "15px" }} />
          </Form.Item>
          {leaveReasonPopUp && (
            <div>
              <div style={{ fontWeight: "600", marginTop: "10px" }}>
                You have taken leave from{" "}
                {moment(leaveStartdate).format("YYYY-MM-DD")} to{" "}
                {moment(leaveEndDate).format("YYYY-MM-DD")}{" "}
              </div>
              <div>Leave Reason :</div>
              <Form.Item
                rules={[
                  // {
                  //     required:true,
                  //     message: "Please justify the reason",
                  // },
                  validateReason(10, 2),
                ]}
                name="attendanceLeaveReason"
                // label="Leave Reason"
                // className="late_reason_submit"
              >
                <TextArea rows={4} style={{ fontSize: "15px" }} />
              </Form.Item>
              <Form.Item
                className="form__group"
                label="Choose Leave Type"
                name="leaveApplicationTypeId"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  paddingTop: "10px",
                }}
              >
                <Select
                  placeholder="Please select"
                  autoComplete="off"
                  onChange={(value, option) => handleChange(value, option)}
                >
                  {!isEmpty(remainingLeaveApplicationTypesListing) &&
                    remainingLeaveApplicationTypesListing.map(
                      (leaveType, i) => (
                        <Option
                          value={leaveType.leaveApplicationTypeId}
                          title={
                            leaveType?.leaveApplicationTypeDto?.leaveTypeName
                          }
                          key={i}
                        >
                          {leaveType?.leaveApplicationTypeDto?.leaveTypeName}
                        </Option>
                      )
                    )}
                </Select>
              </Form.Item>

              {durationInDays >= 3 && deafultLeaveType?.title === "Medical" ? (
                <>
                  {uploaders?.map((u, i) => (
                    <Form.Item
                      className="form__group"
                      name={["uploadedFileDtos"]}
                      rules={[
                        {
                          required: true,
                          message: "Please upload at least one file",
                        },
                      ]}
                    >
                      <ImageUploaderMultiple
                        name={u.name}
                        isMultipleFileAllowed={true}
                        id={u.id}
                        allowedExtensions={u.allowedExtensions}
                        index={i}
                        uploadedFileDtos={form.getFieldValue()}
                        formName="uploadedFileDtos"
                        form={form}
                        callback={(e) => {
                          let olduploadedFileDtos = form.getFieldValue()
                            .uploadedFileDtos
                            ? [...form.getFieldValue().uploadedFileDtos]
                            : [];

                          let uploadedFileDtos = [...olduploadedFileDtos];
                          uploadedFileDtos.push(e);

                          form.setFieldsValue({
                            uploadedFileDtos,
                          });
                        }}
                      />
                    </Form.Item>
                  ))}
                  <div
                    style={{
                      color: "red",
                    }}
                  >
                    your need to upload at least one file
                  </div>
                </>
              ) : null}
            </div>
          )}
          <Alert
            message="Informational Notes"
            description="Give Valid Reason with 10 minimum character & 2 words"
            type="info"
            showIcon
            style={{ marginTop: "20px" }}
          />
          <div className="button_submit">
            <button className="late_Reason_Attendence_Submit" htmlType="submit">
              {attendenceAddNewResponseLoading ? (
                <Spin className="late_reason_spinner" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
export default LateAttendence;
