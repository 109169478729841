import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import ReportForm from "../../weeklyReport/report/reportingForm";
import ExecutionWeeklyReportDetail from "../../weeklyReport/report/reportingDetail";
import {
  actionForContractWeeklyAddUpdateReportGetDetails,
  actionForWeeklyContractAddUpdateReport,
} from "services/redux/cead/action";
import WeeklyContractReportDetailsList from "./list";
import { isEmpty } from "lodash";

const { Text, Title } = Typography;

const initialWklyExecState = {
  open: false,
  type: "",
  wklyRptId: "",
};

export default function WeeklyReportExecutionStage(props) {
  const {
    singleTenderCeadData,
    tenderCeadWeeklyContractReportDetailsListing,
    tenderCeadWeeklyContractReportGetListingLoading,
  } = props;

  const { tenderCeadWeeklyContractReportAddUpdateLoading } = useSelector(
    (state) => state.ceadReducer
  );

  const [weeklyForm] = Form.useForm();

  const [show, setShow] = useState({
    isKey: "",
    isVariation: false,
    isExpandAll: false,
  });

  const [showWklyRptExecForm, setShowWklyRptExtForm] =
    useState(initialWklyExecState);

  const [weeklyId, setWeeklyId] = useState("");

  const dispatch = useDispatch();

  const arrowRight = {
    border: "solid black",
    borderWidth: "0 2px 2px 0",
    display: "inline-block",
    padding: "3px",
    marginLeft: "5px",
    marginBottom: show ? "3px" : "",
    transform: show ? "rotate(45deg)" : "rotate(-45deg)",
    transition: "0.2s ease-in-out",
  };

  useEffect(() => {
    if (!tenderCeadWeeklyContractReportAddUpdateLoading) {
      setShowWklyRptExtForm({
        ...showWklyRptExecForm,
        open: showWklyRptExecForm.wklyRptId ? true : false,
        type: showWklyRptExecForm.wklyRptId ? "detail" : "",
      });
    }
  }, [tenderCeadWeeklyContractReportAddUpdateLoading]);

  const handleFormWklyRptExtStage = (type, id) => {
    setShowWklyRptExtForm({
      open: type === "detail" ? true : !showWklyRptExecForm.open,
      type: type,
      wklyRptId: "",
    });
    if (!isEmpty(id)) {
      dispatch(actionForContractWeeklyAddUpdateReportGetDetails(id));
      setWeeklyId(id);
    }
  };

  const children = () => {
    return (
      <span style={{ float: "right", marginRight: "15px" }}>
        <Text
          onClick={() => {
            handleFormWklyRptExtStage("form", "");
            weeklyForm.resetFields();
          }}
          className="cead-stage-on-add"
        >
          Add{" "}
        </Text>
      </span>
    );
  };

  const wklyRptListOptions = [
    {
      key: "1",
      label: "Report",
      children: children(),
      content: (
        <WeeklyContractReportDetailsList
          handleFormWklyRptExtStage={handleFormWklyRptExtStage}
          tenderCeadWeeklyContractReportDetailsListing={
            tenderCeadWeeklyContractReportDetailsListing
          }
          tenderCeadWeeklyContractReportGetListingLoading={
            tenderCeadWeeklyContractReportGetListingLoading
          }
        />
      ),
    },
  ];

  const onFinishWeeklyAddUpdatReportExecution = (values) => {
    const apiData = {
      ...values,
      id: showWklyRptExecForm?.wklyRptId,
      tenderCEAD_Id: singleTenderCeadData?.id,
      isActive: true,
      // creatorUserDto: {
      //   employeeName: JSON.parse(sessionStorage.getItem("name")),
      // },
      // reportingToDate: moment(values.reportingFromDate).clone().add(1, "days"),
    };

    console.log("apiData", apiData);

    dispatch(actionForWeeklyContractAddUpdateReport(apiData));
  };

  return (
    <>
      {wklyRptListOptions.map((x) => {
        return (
          <>
            <Title level={5} style={{ background: "#D8DDEB" }} key={x.key}>
              <span
                onClick={() => {
                  setShow({
                    isKey: x?.key,
                    isVariation:
                      x?.key === show?.isKey && show.isVariation === true
                        ? false
                        : x?.key === show?.isKey && show.isVariation === false
                        ? true
                        : true,
                  });
                }}
              >
                <i
                  style={{
                    ...arrowRight,
                    marginBottom:
                      (show.isKey === x?.key && show.isVariation) ||
                      show.isExpandAll
                        ? "3px"
                        : "",
                    transform:
                      (show.isKey === x?.key && show.isVariation) ||
                      show.isExpandAll
                        ? "rotate(45deg)"
                        : "rotate(-45deg)",
                  }}
                ></i>{" "}
                {x?.label}
              </span>

              {x?.children}
            </Title>
            {((x?.key === show.isKey && show.isVariation) ||
              show.isExpandAll) &&
              x.content}
          </>
        );
      })}

      <Drawer
        title={
          showWklyRptExecForm.type === "form"
            ? "Weekly Report Form"
            : "Weekly Report Detail"
        }
        visible={showWklyRptExecForm.open}
        onClose={handleFormWklyRptExtStage}
        maskClosable={false}
        mask={false}
        width={600}
      >
        {showWklyRptExecForm.type === "form" ? (
          <ReportForm
            stage="execution"
            showWklyRptExecForm={showWklyRptExecForm}
            setShowWklyRptExtForm={setShowWklyRptExtForm}
            handleFormWklyRptExtStage={handleFormWklyRptExtStage}
            onFinishWeeklyAddUpdatReportExecution={
              onFinishWeeklyAddUpdatReportExecution
            }
            weeklyForm={weeklyForm}
          />
        ) : (
          <ExecutionWeeklyReportDetail
            weeklyForm={weeklyForm}
            stage="execution"
            setShowWklyRptExtForm={setShowWklyRptExtForm}
            id={weeklyId}
          />
        )}
      </Drawer>
    </>
  );
}
