import React from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import { Tooltip, Image, Table } from "antd";
import { cqBaseUrl } from "utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
  toDoGetDetails,
  updateToDoEvent,
} from "services/redux/organizer/todoLists/action";
import { Checkbox } from "antd";

const TaskDetailsLabel = ({
  todoDetails,
  isPrivate,
  allToDoEvents,
  taskId,
}) => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let singleTaskDetail =
    todoDetails || allToDoEvents?.["items"]?.find((x) => x?.id === taskId);

  let assigneDetails = singleTaskDetail?.todoEventCandidateDtos?.map(
    (assigneDetail, index) => {
      if (assigneDetail?.isPrimary) {
        return assigneDetail.profilePicture ? (
          <span key={index}>
            {assigneDetail.candidateName},{` `}
          </span>
        ) : null;
      }
    }
  );

  let watcherDetails = singleTaskDetail?.todoEventCandidateDtos?.map(
    (watcher, index) => {
      if (watcher?.isWatcher) {
        return watcher.profilePicture ? (
          <span key={index}>
            {watcher.candidateName},{` `}
          </span>
        ) : null;
      }
    }
  );
  const columns = [
    {
      title: "Sn",
      dataIndex: "",
      key: "",
      width: 30,
      render: (record, text, index) => <div>{index + 1}</div>,
    },
    {
      title: "Agenda",
      dataIndex: "agenda",
      key: "agenda",
    },
    {
      title: "discussion",
      dataIndex: "discussion",
      key: "discussion",
    },
  ];

  return (
    !isEmpty(singleTaskDetail) && (
      <div
        className="task-view-wrapper"
        style={
          singleTaskDetail?.todoEventAgendas?.length !== 0
            ? { height: "465px" } // if todoEventAgendas length is not 0, set height to 465px
            : { height: "210px" } // if todoEventAgendas length is 0, set height to 210px
        }
      >
        <div className="flex-row">
          <div className="task-info-item">
            <strong>Creator</strong>
            <p>{singleTaskDetail?.privateUser?.fullName}</p>
          </div>
          {/* {!isPrivate && ( */}
          <div className="task-info-item">
            <strong>Assigned (To)</strong>
            <p>{assigneDetails}</p>
          </div>
          {/* )} */}
          {/* {!isPrivate && ( */}
          <div className="task-info-item">
            <strong>Watcher (Cc)</strong>
            <p>{watcherDetails}</p>
          </div>
          {/* )} */}
          <div className="task-info-item">
            <strong>Subject</strong>
            <p>{singleTaskDetail?.subject}</p>
          </div>
          {!isPrivate && (
            <div className="task-info-item">
              <strong>Reference</strong>
              <p>{singleTaskDetail?.referenceCode}</p>
            </div>
          )}
          <div className="task-info-item">
            <strong>Progress</strong>
            <p>{singleTaskDetail?.todoEventStatusDto?.name}</p>
          </div>
          <div className="task-info-item">
            <strong>Priority</strong>
            <p>{singleTaskDetail?.organizerEventPriorityDto?.name}</p>
          </div>
          <div className="task-info-item">
            <strong>Start Date</strong>
            <p>{moment(singleTaskDetail?.startDate).format("YYYY-MM-DD")}</p>
          </div>
          <div className="task-info-item">
            <strong>Due Date</strong>
            {singleTaskDetail?.dueDate == null ? (
              <p>----</p>
            ) : (
              <p>{moment(singleTaskDetail?.dueDate).format("YYYY-MM-DD")}</p>
            )}
          </div>

          <br />
          {singleTaskDetail?.todoEventAgendas?.length !== 0 && (
            <div style={{ width: "100%" }}>
              <Table
                dataSource={singleTaskDetail?.todoEventAgendas}
                columns={columns}
                pagination={false}
              />
            </div>
          )}
          {/* {!isPrivate&&(    <div className="task-info-item">
            <strong>Assigne</strong>
            <p>{assigneDetails}</p>
          </div>
          )} */}

          {singleTaskDetail.notes == null || singleTaskDetail.notes == "" ? (
            ""
          ) : (
            <div className="task-info-item">
              <strong>Notes</strong>
              <p>{singleTaskDetail?.notes}</p>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default TaskDetailsLabel;
