import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { isEmpty } from "lodash";
import EmployeeEvaluationTable from "./Includes/EmployeeEvaluationTable";
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeEvaluationApplication } from "../../../services/redux/requistions/EmployeeEvaluation/action";
import storage from "../../../utils/storage";
import { employeeProfileDetail } from "../../../services/redux/profile/profile.action";

const EmployeeEvaluation = () => {
  const { getEmployeeEvaluationLoading, getEmployeeEvaluationListingResp } =
    useSelector((state) => state.employeeEvaluation);

  const dispatch = useDispatch();

  const [hrList, setHrList] = useState([]);
  const [currentUserHr, setCurrentUserHr] = useState(false);
  let localStorageId = storage.getItem("userId");

  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const onOpenDrawer = () => setShowDrawer(!showDrawer);
  const onCloseDrawer = () => setShowDrawer(false);

  useEffect(() => {
    dispatch(getEmployeeEvaluationApplication());
  }, []);

  useEffect(() => {
    dispatch(
      employeeProfileDetail(
        {
          AppUserId: localStorageId,
        },
        "loginUser"
      )
    );
  }, []);

  return (
    <div className="layout">
      <div>
        <div className="approval-leave-wrapper">
          <div className="approval-leave-left-wrapper">
            <div
              className="global-header-wrapper"
              style={{ justifyContent: "space-between" }}
            >
              <h3 className="header__all">
                <span className="title">Employee Evaluation</span>
              </h3>
              <Button
                className="calendar-create"
                onClick={() => {
                  setShowDrawer(true);
                  setSelectedRecord(null);
                }}
              >
                + New Evaluation Form
              </Button>
            </div>
          </div>
        </div>
      </div>
      <EmployeeEvaluationTable
        drawerVisibility={showDrawer}
        onCloseDrawer={onCloseDrawer}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
      />
    </div>
  );
};

export default EmployeeEvaluation;
