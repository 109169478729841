import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Skeleton, Row, Col, Affix, Dropdown } from "antd";
import { ExclamationOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  blogAdd,
  updateBlogMessage,
  getBlogPagination,
} from "../../../../../../services/redux/officeresource/blog/action";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { actionForGetAllUsersPP } from "services/redux/profile/profile.action";
import MatchingImageDisplay from "components/shared/MatchingImageDisplay";

const Blog = ({ tenderId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const pageSize = 40;

  const scrollableRef = useRef();

  const {
    addBlogMessageLoading,
    addBlogMessageResp,
    getBlogMsgPaginationResp,
    getBlogMsgPaginationLoading,
  } = useSelector((state) => state.blogReducer);

  const { profileInfo } = useSelector((state) => state.profile);

  const [blogMsg, setBlogMsg] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [editMode, setEditMode] = useState(false);
  const [editedBlog, setEditedBlog] = useState({});

  const appUserIdUC = sessionStorage?.userId.toUpperCase();

  // SUPERAPI **************************************************************
  const { listingAllUsersProfileResp, listingAllUsersProfileLoading } =
    useSelector((state) => state.profile);

  const [listingAllProfile, setListingAllProfile] = useState([]);

  useEffect(() => {
    dispatch(actionForGetAllUsersPP());
  }, []);

  useEffect(() => {
    if (!isEmpty(listingAllUsersProfileResp)) {
      setListingAllProfile(listingAllUsersProfileResp);
    }
  }, [listingAllUsersProfileResp]);
  // end SUPERAPI **************************************************************

  useEffect(() => {
    dispatch(
      employeeProfileDetail({
        AppUserId: sessionStorage?.userId.replaceAll('"', ""),
      })
    );
  }, []);

  useEffect(() => {
    let apiBody = {
      ReferenceCode: tenderId,
      MaxResultCount: pageSize,
      SkipCount: 0,
    };
    dispatch(getBlogPagination(apiBody, false));
  }, [tenderId]);

  useEffect(() => {
    if (!isEmpty(getBlogMsgPaginationResp?.items)) {
      setBlogMsg(getBlogMsgPaginationResp.items);
      let newCurrentPage = getBlogMsgPaginationResp.items.length / pageSize;
      setCurrentPage(newCurrentPage);
    } else {
      setBlogMsg([]);
    }
  }, [getBlogMsgPaginationResp, currentPage, pageSize]);

  const getData = (MaxResultCount = pageSize, SkipCount, loadMore = true) => {
    dispatch(
      getBlogPagination(
        {
          ReferenceCode: tenderId,
          MaxResultCount,
          SkipCount,
        },
        loadMore
      )
    );
  };

  const getMoreData = () => {
    console.log("calling");
    getData(pageSize, currentPage * pageSize, true);
  };

  const handleEditClick = (item) => {
    setEditMode(true);
    setEditedBlog({
      id: item.id,
      name: item.name,
      body: item.body,
    });
  };

  const handleEditSubmit = (values) => {
    const updatedBlog = {
      id: editedBlog.id,
      userId: sessionStorage?.userId,
      subject: "string",
      name: editedBlog.name,
      body: values.editedMessage,
      isActive: true,
      referenceCode: tenderId,
      lastmodified: moment().format(),
    };

    dispatch(updateBlogMessage(editedBlog.id, updatedBlog));

    setEditMode(false);
    setEditedBlog({});
  };

  const onFinish = (values) => {
    let data = {};
    if (values) {
      data = {
        userId: sessionStorage?.userId,
        referenceCode: tenderId,
        body: values.message,
        creationTime: moment().format(),
        subject: "string",
        isActive: true,
      };
      dispatch(blogAdd(data));
    }
  };

  const BlogForm = () => {
    return (
      <>
        <Form
          name="blog"
          onFinish={onFinish}
          wrapperCol={{ span: 24 }}
          style={{ background: "#f9f9f9", padding: "2em 4em" }}
        >
          <Row gutter={8}>
            <Col span={22}>
              <Form.Item
                name="message"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  style={{
                    height: "42px",
                    borderBottom: "4px solid #8e92d1",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ height: "100%", width: "100%" }}
                  loading={addBlogMessageLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const blogItemStyle = {
    display: "inline-block",
    background: "#FFFFFF",
    marginBottom: "1em",
    padding: "0.4em 1em",
  };

  const blogItemNameStyle = {
    color: "rgb(85, 85, 85)",
    marginBottom: "0em",
  };

  const blogItemBodyStyle = {
    fontSize: "1.1em",
    marginBottom: "0em",
  };

  const blogPagination = () => {
    return (
      <>
        {blogMsg.map((item, index) => (
          <div
            className=""
            key={index}
            style={{
              display: "flex",
              justifyContent:
                JSON.stringify(item?.userId)?.toUpperCase() === appUserIdUC
                  ? "flex-end"
                  : "flex-start",
            }}
          >
            {JSON.stringify(item?.userId)?.toUpperCase() !== appUserIdUC ? (
              <span
                style={{
                  height: "40px",
                  width: "40px",
                  background: "#67a1e4",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  color: "#FFF",
                  marginRight: "12px",
                }}
              >
                {/* {item ? item?.name[0].toUpperCase() : null} */}
                <MatchingImageDisplay
                  listingAllProfile={listingAllProfile}
                  item={item}
                />
              </span>
            ) : null}

            <div
              style={{
                display: "inline-block",
                maxWidth: "80%",
                background:
                  JSON.stringify(item?.userId)?.toUpperCase() === appUserIdUC
                    ? "#E8EBFA"
                    : "#FFFFFF",
                marginBottom: "1em",
                padding: "0.4em 1em",
              }}
            >
              {/* <span style={blogItemNameStyle}>{item ? item?.name : null}</span> */}
              <div>
                {editMode && editedBlog.id === item.id ? (
                  <Form
                    onFinish={handleEditSubmit}
                    initialValues={{ editedMessage: editedBlog.body }}
                  >
                    <Form.Item
                      name="editedMessage"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        style={{
                          height: "42px",
                          borderBottom: "4px solid #8e92d1",
                        }}
                      />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                    <Button onClick={() => setEditMode(false)}>Cancel</Button>
                  </Form>
                ) : (
                  <>
                    <div
                      className=""
                      style={{
                        display: "flex",
                      }}
                    >
                      <span style={{ marginRight: "2em" }}>{item?.name}</span>
                      <span style={{ color: "#807e7e" }}>
                        {/* {moment(item?.creationTime).startOf("min").fromNow()} */}
                        {item?.lastmodified
                          ? "Modified " + moment(item?.lastmodified).calendar()
                          : moment(item?.creationTime).startOf("min").fromNow()}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={blogItemBodyStyle}>{item?.body}</p>
                      <span
                        className={
                          JSON.stringify(item?.userId) ===
                          sessionStorage?.userId.toUpperCase()
                            ? "blog__content"
                            : ""
                        }
                      >
                        {JSON.stringify(item?.userId)?.toUpperCase() ===
                        appUserIdUC ? (
                          <EditOutlined onClick={() => handleEditClick(item)} />
                        ) : null}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="blog-content">
      <div
        id="blogDiv"
        style={{
          height: "80vh",
          // height: "600px",
          // overflowY: "scroll",
          background: "#F5F5F5",
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
        ref={scrollableRef}
      >
        {getBlogMsgPaginationLoading && (
          <Skeleton paragraph={{ rows: 10 }} active />
        )}

        {!isEmpty(getBlogMsgPaginationResp) ? (
          <InfiniteScroll
            style={{ display: "flex", flexDirection: "column-reverse" }}
            dataLength={getBlogMsgPaginationResp.items.length}
            inverse={true}
            hasMore={
              getBlogMsgPaginationResp.totalCount >
              getBlogMsgPaginationResp.items.length
            }
            next={getMoreData}
            loader={<Skeleton paragraph={{ rows: 4 }} active />}
            scrollableTarget="blogDiv"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>End of blog</b>
              </p>
            }
          >
            {blogPagination()}
          </InfiniteScroll>
        ) : (
          <p style={{ textAlign: "center" }}>
            <b>No Data</b>
          </p>
        )}
      </div>

      <BlogForm />
    </div>
  );
};

export default Blog;
