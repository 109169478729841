import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form, Button, Col, Row, Input, DatePicker, TimePicker, Checkbox, Select } from "antd";
import moment from 'moment'
import { isEmpty } from "lodash";
import { addMeetingEvent, updateMeetingEvent } from "../../../services/redux/organizer/meeting/action";

const { Option } = Select;
const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const CreateMeetingDrawer = ({
    closeMeetingFormDrawer,
    meetingDrawerVisible,
    meetingInvitees,
    sisterCompanyListing,
    meetingStatusListing,
    selectedMeeting
}) => {
    const dispatch = useDispatch();
    const meetingEventState = useSelector((state) => state.meetingEvents);

    const { addMeetingEventResp } = meetingEventState;

    const [form] = Form.useForm();

    const [disabledHours, setDisabledHours] = useState([])
    const [meetingEventCandidateDtos, setMeetingEventCandidateDtos] = useState([])
    // const [meetingInvitees, setMeetingInvitees] = useState([]);

    useEffect(() => {
        if (!isEmpty(selectedMeeting)) {
            form.setFieldsValue({
                meetingEventCandidateDtos: selectedMeeting.meetingEventCandidateDtos.map(candidate => candidate.candidateId),
                title: selectedMeeting.title,
                sisterCompanyId: selectedMeeting.sisterCompanyDto.id,
                meetingEventStatusId: selectedMeeting.meetingEventStatusDto.id,
                onBehalfId: selectedMeeting.onBehalfId,
                notes: selectedMeeting.notes,
                meetingRoom: selectedMeeting.meetingRoom,
                startDate: moment(selectedMeeting.startDate),
                startTime: moment(selectedMeeting.startDate),
                dueDate: moment(selectedMeeting.dueDate),
                dueTime: moment(selectedMeeting.dueDate),
                isActive: selectedMeeting.isActive
            })
            setMeetingEventCandidateDtos(selectedMeeting.meetingEventCandidateDtos)
        }else{
            form.resetFields();
        }
    }, [selectedMeeting]);

    useEffect(() => {
        if (!isEmpty(addMeetingEventResp)) {
            form.resetFields();
            closeMeetingFormDrawer();
        }
    }, [addMeetingEventResp])

    const onFinish = (values) => {
        let newValues = {
            ...values,
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            dueDate: moment(values.dueDate).format('YYYY-MM-DD'),
            startTime: moment(values.startTime).format('h:mm a'),
            dueTime: moment(values.dueTime).format('h:mm a'),
            meetingEventCandidateDtos
        };

        if (!isEmpty(selectedMeeting)) {
            dispatch(updateMeetingEvent(newValues, selectedMeeting.id));
        } else {
            dispatch(addMeetingEvent(newValues));
        }
    };

    const changeMeetingAttendeeDto = (value, option) => {
        let newMeetingEventCandidateDtos = option.map(attendee => {
            return {
                candidateId: attendee.value,
                candidateName: attendee.title,
            }
        });
        setMeetingEventCandidateDtos(newMeetingEventCandidateDtos)
    }

    return (
        <>
            <Drawer
                title={`Create Meeting`}
                onClose={closeMeetingFormDrawer}
                visible={meetingDrawerVisible}
                maskClosable={false}
                width={400}
                bodyStyle={{
                    padding: "15px",
                }}
            >
                <Form
                    {...layout}
                    name='meetingDrawer'
                    onFinish={onFinish}
                    form={form}
                >
                    <Row>
                        <Col lg={24}>
                            <Form.Item
                                label="Meeting Title"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter meeting title" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={8} sm={8}>
                            <Form.Item
                                label="Start Date"
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    disabledDate={current => { return current && current < moment() }}
                                    format='YYYY/MM/DD'
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} sm={8}>
                            <Form.Item
                                label="Start Time"
                                name="startTime"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <TimePicker
                                    onChange={(value) => {
                                        let startTimeHrs = value.hours() || '';
                                        let hours = Array(startTimeHrs + 1).fill(0).map((num, i) => i);
                                        setDisabledHours(hours)
                                    }}
                                    format="HH:mm"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8}>
                            <Form.Item
                                label="Meeting Of"
                                name="sisterCompanyId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                >
                                    {
                                        !isEmpty(sisterCompanyListing) && sisterCompanyListing.map((company, index) => {
                                            return (
                                                <Option
                                                    value={company.id}
                                                    title={company.name}
                                                    key={index}
                                                >
                                                    {company.name}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={8} sm={8}>
                            <Form.Item
                                label="End Date"
                                name="dueDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    disabledDate={current => { return current && current < moment() }}
                                    format='YYYY/MM/DD'
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={8} sm={8}>
                            <Form.Item
                                label="End Time"
                                name="dueTime"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <TimePicker
                                    disabledHours={
                                        () => disabledHours
                                    }
                                    format="HH:mm"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={24} sm={24}>
                            <Form.Item
                                label="Location"
                                name="meetingRoom"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={24} sm={24}>
                            <Form.Item
                                label="Attendee"
                                name="meetingEventCandidateDtos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    onChange={changeMeetingAttendeeDto}
                                >
                                    {
                                        !isEmpty(meetingInvitees) && meetingInvitees.map((employee, index) => {
                                            return (
                                                <Option
                                                    value={employee.appUserId}
                                                    title={employee.fullName}
                                                    key={index}
                                                >
                                                    {employee.fullName}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={24}>
                            <Form.Item
                                label="Remarks"
                                name="notes"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={24}>
                            <Form.Item
                                label="Progress Status"
                                name="meetingEventStatusId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Progress"
                                    style={{ width: 150 }}
                                >
                                    {
                                        !isEmpty(meetingStatusListing) && meetingStatusListing.map((status, index) => {
                                            return (
                                                <Option value={status.id} key={index}>{status.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={24} style={{ width: '100%' }}>
                            <Form.Item
                                label="On Behalf Of"
                                name="onBehalfId"
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select user"
                                >
                                    {
                                        !isEmpty(meetingInvitees) && meetingInvitees.map((employee, index) => {
                                            return (
                                                <Option
                                                    value={employee.appUserId}
                                                    title={employee.fullName}
                                                    key={index}
                                                >
                                                    {employee.fullName}
                                                </Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        name="isActive"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Checkbox>Active</Checkbox>
                    </Form.Item>
                    <Row>
                        <Col lg={12}>
                            <Button
                                onClick={closeMeetingFormDrawer}
                                style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                        </Col>
                        <Col lg={12}>
                            <Button type='primary' htmlType="submit">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
};

export default CreateMeetingDrawer;