import { ADMIN_SETTINGS_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { RoleService } from "./api";
import { message } from "antd";
import { isEmpty, update } from "lodash";

const roleService = new RoleService();

export const getAllRoles = () => {
  return async (dispatch) => {
    dispatch(init(ADMIN_SETTINGS_TYPES.ADMIN_SETTINGS));
    const response = await roleService.getAllRoles();
    dispatch(finish(ADMIN_SETTINGS_TYPES.ADMIN_SETTINGS));
    if (response.isSuccess) {
      dispatch(success(ADMIN_SETTINGS_TYPES.ADMIN_SETTINGS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addNewRole = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_SETTINGS_TYPES.ADD_NEW_ROLE));
    const response = await roleService.addNewRole(body);
    dispatch(finish(ADMIN_SETTINGS_TYPES.ADD_NEW_ROLE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_SETTINGS_TYPES.ADD_NEW_ROLE, response.data));
      if (response.data.id) {
        let newData = {
          ...body,
          roleId: response.data.id,
        };
        dispatch(actionForNewUpdateRole(newData, response.data.id));
      }
      dispatch(getAllRoles());
      message.success("New Role added successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateRole = (body, id) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_SETTINGS_TYPES.UPDATE_ROLE));
    const response = await roleService.updateRole(body, id);
    dispatch(finish(ADMIN_SETTINGS_TYPES.UPDATE_ROLE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_SETTINGS_TYPES.UPDATE_ROLE, response.data));
      dispatch(getAllRoles());
      message.success("Role updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// new update rol api working
export const actionForNewUpdateRole = (body, id) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_SETTINGS_TYPES.NEW_UPDATE_ROLE));
    const response = await roleService.newUpdateRole(body, id);
    dispatch(finish(ADMIN_SETTINGS_TYPES.NEW_UPDATE_ROLE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_SETTINGS_TYPES.NEW_UPDATE_ROLE, response.data));
      dispatch(getAllRoles());
      message.success("Role updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getPermissionListsForRole = (roleName) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_SETTINGS_TYPES.PERMISSION_LISTS_FOR_ROLE));
    const response = await roleService.getPermissionListsForRole(roleName);
    dispatch(finish(ADMIN_SETTINGS_TYPES.PERMISSION_LISTS_FOR_ROLE));
    if (response.isSuccess) {
      dispatch(
        success(ADMIN_SETTINGS_TYPES.PERMISSION_LISTS_FOR_ROLE, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const grantOrProhibitPermissionForRole = (query) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_SETTINGS_TYPES.GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE));
    const response = await roleService.grantOrProhibitPermissionForRole(query);
    dispatch(
      finish(ADMIN_SETTINGS_TYPES.GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE)
    );
    if (response.isSuccess) {
      dispatch(
        success(
          ADMIN_SETTINGS_TYPES.GRANT_OR_PROHIBIT_PERMISSION_FOR_ROLE,
          response.data
        )
      );
      message.success("Permission updated successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const appointmentMarkAsComplete = (id) => {
//   return async (dispatch) => {
//     dispatch(init(ADMIN_SETTINGS_TYPES.APPOINTMENT_COMPLETE));
//     const response = await roleService.appointmentMarkAsComplete(id);
//     dispatch(finish(ADMIN_SETTINGS_TYPES.APPOINTMENT_COMPLETE));
//     if (response.isSuccess) {
//       dispatch(success(ADMIN_SETTINGS_TYPES.APPOINTMENT_COMPLETE, response.data));
//       dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
//       let status = response.data.markItOff ? 'complete' : 'incomplete';
//       message.success(`Appointment marked as ${status} successfully`);
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };
