import { Button, Col, DatePicker, Form, Input, Modal, Row, Steps } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { isEmpty } from "lodash";
import { PaperClipOutlined, CloseOutlined } from "@ant-design/icons";

import {
  getLoanApplicationDataGetDetails,
  loanApplicationUpdateAccountant,
  loanApplicationUpdateManagement,
  loanApplicationUpdateSupervisor,
} from "services/redux/requistions/loanApplication/action";
import storage from "utils/storage";
import { cqBaseUrl } from "utils/config";
import Permission from "components/Requisition/Permission";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
import pdfViewer from "components/Documents/ViewDocument";
const attachmentView = "assets/svg/attachment-view.svg";
const newWindow = "assets/svg/newWindow.svg";

const LoanDetailss = ({
  loanApplicationId,
  setInstanceMessageCenterListing,
}) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const { Step } = Steps;

  const {
    loanApplicationDataGetDetails,
    loanApplicationDataGetDetailsLoading,
    loanUpdateSupervisor,
    loanUpdateAccountant,
    loanUpdateManagement,
  } = useSelector((state) => state.loanApplication);

  const {
    loanUpdateSupervisorLoading,
    loanUpdateAccountantLoading,
    loanUpdateManagementLoading,
  } = useSelector((state) => state.loanApplication);

  const { token } = useSelector((state) => state.auth);

  const [isApproval, setIsApproval] = useState(false);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState({
    accept: false,
    reject: false,
  });
  const [modalVisible, setModalVisible] = useState(false);

  const handleView = (src) => {
    setTimeout(() => {
      pdfViewer1(src);
    }, 1000);
  };

  const onFinish = (values) => {
    let body = { ...values, isApproval: isApproval };
    if (supervisorStatus === "Pending") {
      dispatch(
        loanApplicationUpdateSupervisor(
          body,
          loanApplicationId,
          isApproval,
          setInstanceMessageCenterListing
        )
      );
    } else if (
      supervisorStatus === "Approved" &&
      accountantStatus === "Pending"
    ) {
      dispatch(
        loanApplicationUpdateAccountant(
          body,
          loanApplicationId,
          isApproval,
          setInstanceMessageCenterListing
        )
      );
    } else {
      dispatch(
        loanApplicationUpdateManagement(
          body,
          loanApplicationId,
          isApproval,
          setInstanceMessageCenterListing
        )
      );
    }
  };

  const RemarksForm = (rankType) => {
    return (
      <Form name="basic" form={form} onFinish={onFinish}>
        {rankType === "accountant" && (
          <div className="loan_accountant_management_form">
            <Form.Item
              label="Provident Fund Deposited till date:"
              name="providentFundDepositTillDate"
              className="form_group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <DatePicker autoComplete="off" />
            </Form.Item>
            <Form.Item
              label="Rate Reasonability"
              name="rateReasonableComment"
              className="form_group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </div>
        )}
        {rankType === "management" && (
          <div className="loan_accountant_management_form">
            <Form.Item
              label="Approved Amount:"
              name="approvedAmount"
              className="form_group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Loan Repayment Per Month:"
              name="loanRepaymentAmountPerMonth"
              className="form_group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
        )}
        <Form.Item
          label="Remarks"
          name="loanApproveRejectComment"
          className="form__group"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <div className="loan_details_footer" style={{ marginTop: 15 }}>
          <Button
            className="loan_reject"
            htmlType="submit"
            onClick={() => {
              setIsApproval(false);
              setLoading((prevLoading) => ({
                ...prevLoading,
                reject: true,
              }));
            }}
            loading={
              (loanUpdateSupervisorLoading ||
                loanUpdateAccountantLoading ||
                loanUpdateManagementLoading) &&
              loading.reject
            }
          >
            Reject
          </Button>
          <Button
            className="loan_approved"
            htmlType="submit"
            onClick={() => {
              setIsApproval(true);
              setLoading((prevLoading) => ({
                ...prevLoading,
                accept: true,
              }));
            }}
            loading={
              (loanUpdateSupervisorLoading ||
                loanUpdateAccountantLoading ||
                loanUpdateManagementLoading) &&
              loading.accept
            }
          >
            Accept
          </Button>
        </div>
      </Form>
    );
  };

  const remarksDetails = (name, details, levelName) => {
    return (
      <Row gutter={20}>
        <Col lg={24} className="loan_col">
          <div className="loan_title">
            Remarks({name} , {levelName})
          </div>
          <div className="loan_input">{details}</div>
        </Col>
      </Row>
    );
  };

  const approvedAmount = (details) => {
    return (
      <Row gutter={20}>
        <Col lg={24} className="loan_col">
          <div className="loan_title">Approved Amount(from Management)</div>
          <div className="loan_input">{details?.toLocaleString()}</div>
        </Col>
      </Row>
    );
  };

  const {
    fromUser,
    supervisorStatus,
    supervisorName,
    accountantStatus,
    accountantName,
    managementStatus,
    managementName,
    supervisorUser,
    accountantUser,
    supervisorDescription,
    accountantDescription,
    managementDescription,
    managementUser,
  } = Permission({
    loan: true,
    storage,
    loanApplicationDataGetDetails: details,
  });

  useEffect(() => {
    dispatch(getLoanApplicationDataGetDetails(loanApplicationId));
  }, [loanApplicationId]);

  useEffect(() => {
    if (!isEmpty(loanApplicationDataGetDetails)) {
      setDetails(loanApplicationDataGetDetails);
    }
  }, [loanApplicationDataGetDetails]);

  useEffect(() => {
    if (!isEmpty(loanUpdateSupervisor)) {
      setDetails(loanUpdateSupervisor?.requisitionResult);
    }
    if (!isEmpty(loanUpdateAccountant)) {
      setDetails(loanUpdateAccountant?.requisitionResult);
    }
    if (!isEmpty(loanUpdateManagement)) {
      setDetails(loanUpdateManagement?.requisitionResult);
    }
  }, [loanUpdateSupervisor, loanUpdateAccountant, loanUpdateManagement]);

  return (
    <div className="mail_loan_details">
      <div className="loan_progress">
        <Steps
          current={
            supervisorStatus === "Approved"
              ? accountantStatus === "Approved"
                ? managementStatus === "Final Approved"
                  ? 5
                  : 3
                : 2
              : 1
          }
        >
          <Step title="Apply" />
          <Step
            title={
              supervisorStatus === "Approved"
                ? "Approved"
                : supervisorStatus === "Reject"
                ? "Rejected"
                : "Pending"
            }
            description="(Supervisor)"
          />
          <Step
            title={
              accountantStatus === "Approved"
                ? "Approved"
                : accountantStatus === "Reject"
                ? "Rejected"
                : "Pending"
            }
            description="(Accountant)"
          />
          <Step
            title={
              managementStatus === "Approved" ||
              managementStatus === "Final Approved"
                ? "Approved"
                : managementStatus === "LoanApprover_Reject"
                ? "Rejected"
                : "Pending"
            }
            status={managementStatus === "LoanApprover_Reject" ? "error" : ""}
            description="(Management)"
          />
          <Step title="Approved" />
        </Steps>
      </div>

      {!loanApplicationDataGetDetailsLoading && (
        <div className="loan_details">
          <Row gutter={20}>
            <Col lg={9} className="loan_col">
              <div className="loan_title">Company Name</div>
              <div className="loan_input">
                {details?.sisterCompanyDto?.name}
              </div>
            </Col>
            <Col lg={9} className="loan_col">
              <div className="loan_title">Person Name</div>
              <div className="loan_input">
                {
                  details?.loanApplicationParticipantStatuses?.fromList?.[0]
                    ?.participantUsername
                }
              </div>
            </Col>

            <Col lg={6} className="loan_col">
              <div className="loan_title">Amount Requested</div>
              <div className="loan_input">
                {details?.requestedAmount?.toLocaleString()}
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col lg={24} className="loan_col">
              <div className="loan_title">Loan Reason</div>
              <div className="loan_input">{details?.reason}</div>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col lg={24} className="loan_col">
              <div className="loan_title">Attachment</div>
              <div className="loan_input">
                <div className="office-attachment-wrapper">
                  <span className="office-attachment-length">
                    <PaperClipOutlined />
                    &nbsp;{details?.loanApplicationAttachmentDtos?.length}
                  </span>
                  {details?.loanApplicationAttachmentDtos?.length > 0 && (
                    <div className="office-attachment-dropdown">
                      {details?.loanApplicationAttachmentDtos?.map(
                        (doc, index) => {
                          return (
                            doc.isActive && (
                              <div className="office-attachment-item">
                                <PaperClipOutlined />{" "}
                                <a
                                  target="_blank"
                                  download
                                  href={
                                    cqBaseUrl + "/" + doc.viewFileURL + token
                                  }
                                >
                                  <span className="office-attachment-overflow">
                                    {doc.displayFileName.slice(
                                      0,
                                      doc.displayFileName.length - 5
                                    )}
                                  </span>
                                  {doc.displayFileName.slice(
                                    doc.displayFileName.length - 5,
                                    doc.displayFileName.length
                                  )}
                                </a>
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "none",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setModalVisible(true);
                                    handleView(
                                      `${cqBaseUrl}/${doc?.viewFileURL}${token}`
                                    );
                                    console.log(
                                      "Clicked on view here",
                                      doc?.viewFileURL,
                                      token
                                    );
                                  }}
                                >
                                  <img src={attachmentView} alt="view" />
                                </button>
                                {modalVisible && (
                                  <Modal
                                    visible={modalVisible}
                                    title={
                                      <div
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns:
                                            "20fr 0.5fr 0.5fr ",
                                        }}
                                      >
                                        <span>Document Viewer</span>
                                        <span
                                          onClick={() => {
                                            pdfViewer(
                                              `${cqBaseUrl}/${doc?.viewFileURL}${token}`
                                            );
                                          }}
                                        >
                                          <img
                                            src={newWindow}
                                            height={15}
                                            style={{ marginBottom: "5px" }}
                                            alt="new"
                                          />
                                        </span>
                                        <span
                                          onClick={() => {
                                            setModalVisible(false);
                                          }}
                                        >
                                          <CloseOutlined />
                                        </span>
                                      </div>
                                    }
                                    footer={null}
                                    closable={false}
                                    onCancel={() => {
                                      setModalVisible(false);
                                    }}
                                    width="70%"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "100%",
                                      }}
                                    ></div>

                                    {modalVisible && (
                                      <iframe
                                        id="pdfViewerFrame"
                                        width="100%"
                                        height="500px"
                                      />
                                    )}
                                  </Modal>
                                )}
                              </div>
                            )
                          );
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {supervisorUser && supervisorStatus === "Pending" && RemarksForm()}
          {supervisorStatus === "Approved" &&
            remarksDetails(supervisorName, supervisorDescription, "Supervisor")}
          {accountantUser &&
            !fromUser &&
            supervisorStatus === "Approved" &&
            accountantStatus === "Pending" &&
            RemarksForm("accountant")}
          {accountantStatus === "Approved" &&
            remarksDetails(accountantName, accountantDescription, "accountant")}
          {managementUser &&
            accountantStatus === "Approved" &&
            managementStatus === "Pending" &&
            RemarksForm("management")}
          {managementStatus === "Final Approved" &&
            remarksDetails(managementName, managementDescription, "Management")}
          {managementStatus === "Final Approved" &&
            approvedAmount(details?.approvedAmount)}
        </div>
      )}
    </div>
  );
};
export default LoanDetailss;
