import { FOLDER_SERVICE_TYPES } from "services/types";

const INITIAL_STATE = {
  folderListingLoading: false,
  folderListingResponse: [],

  childFolderListingResponse: [],
  childFolderListingLoading: false,

  fileUploadResponse: [],
  fileUploadResponseLoading: true,
};

export default function folderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FOLDER_SERVICE_TYPES.FOLDER_LIST_INIT:
      return {
        ...state,
        folderListingResponse: [],
        folderListingLoading: true,
      };
    case FOLDER_SERVICE_TYPES.FOLDER_LIST_SUCCESS:
      return {
        ...state,
        folderListingResponse: action.payload,
        folderListingLoading: false,
      };
    case FOLDER_SERVICE_TYPES.FOLDER_LIST_FINISH:
      return {
        ...state,
        folderListingLoading: false,
      };
    case FOLDER_SERVICE_TYPES.CHILD_FOLDER_LIST_INIT:
      return {
        ...state,
        childFolderListingLoading: true,
        childFolderListingResponse: [],
      };
    case FOLDER_SERVICE_TYPES.CHILD_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        childFolderListingLoading: false,
        childFolderListingResponse: action.payload,
      };
    case FOLDER_SERVICE_TYPES.CHILD_FOLDER_LIST_FINISH:
      return {
        ...state,
        childFolderListingLoading: false,
      };
    case FOLDER_SERVICE_TYPES.CREATE_FOLDER_INIT:
      return {
        ...state,
        folderAddListingLoading: true,
        // folderListingResponse: [],
      };
    case FOLDER_SERVICE_TYPES.CREATE_FOLDER_SUCCESS:
      if (
        action.payload.parentFolderId == "00000000-0000-0000-0000-000000000000"
      ) {
        const updatedFolderListing = state.folderListingResponse.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        );

        const newFolderListingResponse = updatedFolderListing.some(
          (item) => item.id === action.payload.id
        )
          ? updatedFolderListing
          : [...updatedFolderListing, action.payload];

        return {
          ...state,
          folderAddListingLoading: false,
          folderListingResponse: newFolderListingResponse,
        };
      } else {
        const updatedChildren = (
          state.childFolderListingResponse?.[0]?.childrenFolders || []
        ).map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        );

        const existingIndex = updatedChildren.findIndex(
          (item) => item.id === action.payload.id
        );
        const newChildren =
          existingIndex !== -1
            ? [
                ...updatedChildren.slice(0, existingIndex),
                action.payload,
                ...updatedChildren.slice(existingIndex + 1),
              ]
            : [...updatedChildren, action.payload];

        const updatedResult = {
          ...state.childFolderListingResponse?.[0],
          dmsUploadedFileDtos: newChildren,
        };

        return {
          ...state,
          folderAddListingLoading: false,
          childFolderListingResponse: [
            {
              ...state.childFolderListingResponse?.[0],
              childrenFolders: newChildren,
            },
          ],
        };
      }

    case FOLDER_SERVICE_TYPES.CREATE_FOLDER_FINISH:
      return {
        ...state,
        folderAddListingLoading: false,
      };

    case FOLDER_SERVICE_TYPES.UPLOAD_FILE_INIT:
      return {
        ...state,
        fileUploadResponse: [],
        fileUploadResponseLoading: true,
      };

    case FOLDER_SERVICE_TYPES.UPLOAD_FILE_SUCCESS:
      const uploadFileDtos = action.payload.dMSUploadedFileDtos;

      const updatedChildren = (
        state.childFolderListingResponse?.[0]?.dmsUploadedFileDtos || []
      ).map((item) =>
        uploadFileDtos.some(
          (file) => file.dmsFolderFileId === item.dmsFolderFileId
        )
          ? { ...item, ...action.payload }
          : item
      );

      const existingIndex = updatedChildren.findIndex(
        (item) => item.dmsFolderFileId === action.payload.dmsFolderFileId
      );

      const newChildren =
        existingIndex !== -1
          ? [
              ...updatedChildren.slice(0, existingIndex),
              action.payload,
              ...updatedChildren.slice(existingIndex + 1),
            ]
          : [...updatedChildren, action.payload.dMSUploadedFileDtos[0]];

      const updatedResult = {
        ...state.childFolderListingResponse?.[0],
        dmsUploadedFileDtos: newChildren,
      };

      const finalOutput = [
        {
          ...state.childFolderListingResponse?.[0],
          dmsUploadedFileDtos: newChildren,
        },
      ];

      console.log(
        "action.payload",
        action.payload,
        "state.childFolderListingResponse",
        state.childFolderListingResponse,
        "updatedChildren",
        updatedChildren,
        "existingIndex",
        existingIndex,
        "newChildren",
        newChildren,
        "finalOutput",
        finalOutput
      );

      return {
        ...state,
        folderAddListingLoading: false,
        childFolderListingResponse: [
          {
            ...state.childFolderListingResponse?.[0],
            dmsUploadedFileDtos: newChildren,
          },
        ],
      };

    default:
      return {
        ...state,
      };
  }
}
