export default function negativeNumColor(num) {
  if (Math.sign(num) === -1) {
    num = Math.abs(num);
    return (
      <div style={{ color: "red" }}>
        {/* ({num.toFixed(2)}) */}
        {`(${num.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })})`}
      </div>
    );
  } else if (Math.sign(num) === 0) {
    return <div>--</div>;
  } else if (Math.sign(num) === -0) {
    return <div style={{ color: "red" }}>--</div>;
  }
  return (
    <>
      {/* {num?.toFixed(2)} */}
      {`${num.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`}
    </>
  );
}
