import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  message,
  Space,
  DatePicker,
  Layout,
  Image,
  Dropdown,
  Menu,
} from "antd";
import {
  LeftSquareTwoTone,
  RightSquareTwoTone,
} from "@ant-design/icons";
import { validateMessages } from "utils/misc";
import {
  PlusOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  StarFilled,
} from "@ant-design/icons";

const addperson = "assets/svg/Addperson.svg";
const addcompany = "assets/svg/addcompany.svg";

const menu = (
  <Menu style={{ width: 144, height: "auto" }}>
    <Menu.Item className="sidebars__menu--item">
      <>
        <div
          style={{
            display: "flex",
            width: 114,
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "18%" }}>
            <img src={addcompany} />
          </div>
          <div style={{ width: "50%" }}> Goal</div>
        </div>
      </>
    </Menu.Item>

    <Menu.Item className="sidebars__menu--item">
      <>
        <div
          style={{
            display: "flex",
            width: 114,
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "18%" }}>
            <img src={addperson} />
          </div>
          <div style={{ width: "50%" }}> Appoinment</div>
        </div>
      </>
    </Menu.Item>
    <Menu.Item className="sidebars__menu--item">
      <>
        <div
          style={{
            display: "flex",
            width: 114,
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "18%" }}>
            <img src={addperson} />
          </div>
          <div style={{ width: "50%" }}> TO DO</div>
        </div>
      </>
    </Menu.Item>
  </Menu>
);
const Header = ({ name, isLeftColumnCollapsible, setIsLeftColumnCollapsible }) => {

  return (
    <div>
      <Row className="events__buttons">
        <Col lg={24}>
          <div className="ContactDetails__header">
            {isLeftColumnCollapsible ? (
              <>
                <h3 className="header__all">{name} </h3>
                <LeftSquareTwoTone
                  onClick={() => setIsLeftColumnCollapsible(false)}
                />
              </>
            ) : (
              <RightSquareTwoTone
                onClick={() => {
                  setIsLeftColumnCollapsible(true);
                }}
                // style={{
                //   width: "4px",
                // }}
              />
            )}
            
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
