import { init, success, finish, error } from "services/common";
import { DesignationService } from "./api";
import { ORGANIZATION_STRUCTURE_TYPES } from "../../../../types";
import { message } from "antd";

const designationService = new DesignationService();

export const getDesignationListing = () => {
  return async (dispatch) => {
    dispatch(init(ORGANIZATION_STRUCTURE_TYPES.DESIGNATION_LISTING));
    const response = await designationService.getDesignationListing();
    dispatch(finish(ORGANIZATION_STRUCTURE_TYPES.DESIGNATION_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(ORGANIZATION_STRUCTURE_TYPES.DESIGNATION_LISTING, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const addAppointmentEvent = (body) => {
//   return async (dispatch) => {
//     dispatch(init(ORGANIZER_TYPES.ADD_APPOINTMENT));
//     const response = await roleService.addAppointmentEvent(body);
//     dispatch(finish(ORGANIZER_TYPES.ADD_APPOINTMENT));
//     if (response.isSuccess) {
//       dispatch(success(ORGANIZER_TYPES.ADD_APPOINTMENT, response.data));
//       dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
//       message.success('Appointment Event added successfully');
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

// export const updateAppointmentEvent = (body, id) => {
//   return async (dispatch) => {
//     dispatch(init(ORGANIZER_TYPES.ADD_APPOINTMENT));
//     const response = await roleService.updateAppointmentEvent(body, id);
//     dispatch(finish(ORGANIZER_TYPES.ADD_APPOINTMENT));
//     if (response.isSuccess) {
//       dispatch(success(ORGANIZER_TYPES.ADD_APPOINTMENT, response.data));
//       dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
//       message.success('Appointment Event updated successfully');
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

// export const appointmentMarkAsComplete = (id) => {
//   return async (dispatch) => {
//     dispatch(init(ORGANIZER_TYPES.APPOINTMENT_COMPLETE));
//     const response = await roleService.appointmentMarkAsComplete(id);
//     dispatch(finish(ORGANIZER_TYPES.APPOINTMENT_COMPLETE));
//     if (response.isSuccess) {
//       dispatch(success(ORGANIZER_TYPES.APPOINTMENT_COMPLETE, response.data));
//       dispatch(getAppointmentEventsPagination(`maxResultCount=10&skipCount=0`));
//       let status = response.data.markItOff ? 'complete' : 'incomplete';
//       message.success(`Appointment marked as ${status} successfully`);
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

export const addEmployeeDepartment = (body, id) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZATION_STRUCTURE_TYPES.ADD_EMPLOYEE_DEPARTMENT));
    const response = await designationService.addEmployeeInDepartment(body, id);
    dispatch(finish(ORGANIZATION_STRUCTURE_TYPES.ADD_EMPLOYEE_DEPARTMENT));
    if (response.isSuccess) {
      dispatch(
        success(
          ORGANIZATION_STRUCTURE_TYPES.ADD_EMPLOYEE_DEPARTMENT,
          response.data
        )
      );
      message.success("Employee in depatment added successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getDepartmentEmployees = (id) => {
  return async (dispatch) => {
    dispatch(init(ORGANIZATION_STRUCTURE_TYPES.GET_DEPARTMENT_DETAILS));
    const response = await designationService.getDepartmentDetails(id);
    dispatch(finish(ORGANIZATION_STRUCTURE_TYPES.GET_DEPARTMENT_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(
          ORGANIZATION_STRUCTURE_TYPES.GET_DEPARTMENT_DETAILS,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
