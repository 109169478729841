import { Button, Col, Input, Row } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationTaskEventGetDetails } from "services/redux/organizer/goals/action";

const AppointmentDetails = ({ appointmentId }) => {
  const { taskEventGetDetailsResp, taskEventGetDetailsRespLoading } =
    useSelector((state) => state.goalEvents);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(organizationTaskEventGetDetails(appointmentId));
  }, []);

  return (
    <>
      <div className="profiledetails__header" style={{ marginTop: 5 }}>
        <div className="profiledetails__header--text">Hr-Appointment</div>
      </div>
      <Col span={24} style={{ paddingLeft: "10px" }}>
        <Row span={24}>
          <Col span={4}>Title :</Col>
          <Col span={16}>{taskEventGetDetailsResp?.title}</Col>
        </Row>
        <Row span={24}>
          <Col span={4}>Priority :</Col>
          <Col span={16}>
            {taskEventGetDetailsResp?.organizerEventPriorityName}
          </Col>
        </Row>
        <Row span={24}>
          <Col span={4}>Start Date :</Col>
          <Col span={16}>
            {moment(taskEventGetDetailsResp?.startDate).format(
              "DD MMM YYYY hh:mm:ss:a"
            )}
          </Col>
        </Row>
        <Row span={24}>
          <Col span={4}>Due Date :</Col>
          <Col span={16}>
            {moment(taskEventGetDetailsResp?.dueDate).format(
              "DD MMM YYYY hh:mm:ss:a"
            )}
          </Col>
        </Row>
        <Row span={24}>
          <Col span={4}>Status :</Col>
          <Col span={16}>
            {taskEventGetDetailsResp?.isComplete === true
              ? "Complete"
              : "Pending"}
          </Col>
        </Row>

        {taskEventGetDetailsResp?.organiserTaskEventCandidateDtos?.length !==
          0 && (
          <Row span={24}>
            <Col span={4}>Asignees :</Col>
            <Col span={16}>
              {taskEventGetDetailsResp?.organiserTaskEventCandidateDtos
                ?.length === 1
                ? taskEventGetDetailsResp.organiserTaskEventCandidateDtos[0]
                    .candidateName
                : taskEventGetDetailsResp.organiserTaskEventCandidateDtos?.map(
                    (x, index, array) => {
                      if (index === array.length - 1) {
                        return x.candidateName;
                      } else {
                        return `${x.candidateName}, `;
                      }
                    }
                  )}
            </Col>
          </Row>
        )}
      </Col>
    </>
  );
};

export default AppointmentDetails;
