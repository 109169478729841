import { Button, Card, Col, Form, Input, Modal, Row, Select, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAmmountListing } from "services/redux/requistions/leaveType/action";
import { getProcurementSummary } from "services/redux/requistions/procurement/action";
import GuestEntertainmentBasicDetail from "../Requisition/BasicDetails/GuestEntertainment";
import LeaveBasicDetails from "../Requisition/BasicDetails/LeaveDetails";
import LoanBasicDetails from "../Requisition/BasicDetails/Loan";
import ProcurementBasicDetails from "../Requisition/BasicDetails/Procurement";
import TaDaBasicDetail from "../Requisition/BasicDetails/TaDa";
import GuestEntertainmentMoreDetail from "../Requisition/MoreDetails/GuestEntertainment";
import LoanMoreDetails from "../Requisition/MoreDetails/LoanDetails";
import TaDaMoreDetail from "../Requisition/MoreDetails/TaDaDetail";
import { handleSubmit } from "../Requisition/RequisitionFunction";
import LeaveTable from "../Tables/LeaveTable";
import ProcurementTable from "../Tables/ProcurementTable";
import CeadDetail from "../Requisition/BasicDetails/CeadDetail";

const RequisitionModal1 = ({
  loan,
  cead,
  leave,
  procurement,
  tada,
  loanApplicationDataGetDetails,
  loanApplicationDataGetDetailsLoading,
  loanApplicationId,
  requisitionModal,
  setRequisitionModal,
  supervisorStatus,
  accountantStatus,
  accountantUser,
  managementUser,
  managementStatus,
  fromUser,
  type,
  getTadaSingleDetailsResponse,
  getTadaSingleDetailsLoading,
  guestEntertainment,
  guestEntertainmentDetailResponse,
  guestEntertainmentDetailLoading,
  supervisorUsers,
  isSupervisor,
  managementUsers,
  isManagement,
  accountantUsers,
  isAccountant,
  accountantName,
  accountantDescription,
  supervisorUser,
  supervisorName,
  supervisorDescription,
  managementName,
  managementDescription,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    loanUpdateSupervisorLoading,
    loanUpdateAccountantLoading,
    loanUpdateManagementLoading,
  } = useSelector((state) => state.loanApplication);

  const { leaveDetails, leaveDetailsLoading, leaveUpdateManagementLoading } =
    useSelector((state) => state.leaveType);

  const { ceadApprovalDetails, ceadApprovalDetailsLoading } = useSelector(
    (state) => state?.approvalCEAD
  );

  const {
    tadaDetailsUpdateSupervisorLoading,
    tadaDetailsUpdateManagementLoading,
    tadaDetailsUpdateAccountantLoading,
  } = useSelector((state) => state?.tadaDetails);

  const {
    procurementDetails,
    procurementDetailsLoading,
    procurementSummaryManagementResponse,
    procurementSummaryManagementLoading,
    procurementCounterResponse,
    procurementUpdateAccountantLoading,
    procurementCounterLoading,
    procurementUpdateSupervisorLoading,
    procurementUpdateManagementLoading,
  } = useSelector((state) => state?.procurement);

  const [showMore, setShowMore] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isApproval, setIsApproval] = useState(false);
  const [position, setPosition] = useState();
  const [geStatus, setGeStatus] = useState(false);

  useEffect(() => {
    const status =
      guestEntertainmentDetailResponse?.currentGuestEntertainmentStatus;
    setPosition(status);
  }, [guestEntertainmentDetailResponse]);

  const getProcurementAction = (type) => {
    dispatch(getProcurementSummary(type));
  };

  useEffect(() => {
    dispatch(getAmmountListing());
  }, []);

  const remarksDetails = (name, details, levelName) => {
    return (
      <Row gutter={20}>
        <Col lg={8} className="procurement_col">
          <div className="procurement_title">
            Remarks
            <br />({levelName})
          </div>
        </Col>
        <Col lg={8} className="procurement_col">
          <div className="procurement_input">{details}</div>
        </Col>
      </Row>
    );
  };

  const RemarksForm = (supervisor) => {
    return (
      <>
        {supervisor && (
          <>
            <Row gutter={[24, 10]}>
              <Col span={8}>Vendor Name</Col>
              <Col span={16}>
                <Form.Item
                  name="vendorName"
                  className="form_group"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 10]}>
              <Col span={8}>Tentative Amount</Col>
              <Col span={16}>
                <Form.Item
                  name="tentativeAmount"
                  className="form_group"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input type="number" min={0} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 10]}>
              <Col span={8}>Rate Reasonability</Col>
              <Col span={16}>
                <Form.Item
                  name="rateReasonableComment"
                  className="form_group"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const onFinish = (values) => {
    handleSubmit({
      loan,
      cead,
      procurement,
      leave,
      tada,
      guestEntertainment,
      values,
      isApproval,
      supervisorStatus,
      accountantStatus,
      managementStatus,
      dispatch,
      loanApplicationId,
      form,
      setRequisitionModal,
      getProcurementAction,
      geStatus,
      guestEntertainmentDetailResponse,
    });
  };

  return (
    <>
      <Modal
        confirmLoading={
          leaveDetailsLoading ||
          procurementDetailsLoading ||
          ceadApprovalDetailsLoading ||
          loanApplicationDataGetDetailsLoading
        }
        visible={requisitionModal}
        title={
          loan
            ? "Loan Details"
            : leave
            ? "Leave Details"
            : procurement
            ? "Procurement Details"
            : tada
            ? "TA/DA details"
            : guestEntertainment
            ? "Guest Entertainment"
            : cead
            ? "Cead Details"
            : ""
        }
        footer={null}
        closable={true}
        onCancel={() => {
          setRequisitionModal?.(false);
          setShowMore(false);
        }}
        wrapClassName="advance_search_email"
      >
        {procurementDetailsLoading ||
        leaveDetailsLoading ||
        ceadApprovalDetailsLoading ||
        loanApplicationDataGetDetailsLoading ||
        getTadaSingleDetailsLoading ? (
          <Spin />
        ) : (
          <div>
            <Form
              form={form}
              className="drawer-form-wrapper internal_message_section_email_section"
              onFinish={onFinish}
            >
              <div
                className="requistion_details"
                style={{ margin: 10, marginBottom: 0 }}
              >
                {guestEntertainment && (
                  <>
                    <GuestEntertainmentBasicDetail
                      guestEntertainmentDetailResponse={
                        guestEntertainmentDetailResponse
                      }
                    />
                    <GuestEntertainmentMoreDetail
                      position={position}
                      guestEntertainmentDetailResponse={
                        guestEntertainmentDetailResponse
                      }
                      setGeStatus={setGeStatus}
                    />
                  </>
                )}

                {tada && (
                  <>
                    <TaDaBasicDetail
                      getTadaSingleDetailsResponse={
                        getTadaSingleDetailsResponse
                      }
                    />
                    <TaDaMoreDetail supervisorStatus={supervisorStatus} />
                  </>
                )}
                {loan && (
                  <>
                    <LoanBasicDetails
                      loanApplicationDataGetDetails={
                        loanApplicationDataGetDetails
                      }
                    />
                    <LoanMoreDetails
                      accountantUser={accountantUser}
                      fromUser={fromUser}
                      supervisorStatus={supervisorStatus}
                      accountantStatus={accountantStatus}
                      managementUser={managementUser}
                      managementStatus={managementStatus}
                    />
                  </>
                )}

                {procurement && (
                  <ProcurementBasicDetails
                    procurementDetails={procurementDetails}
                  />
                )}

                {leave && <LeaveBasicDetails leaveDetails={leaveDetails} />}

                {cead && (
                  <CeadDetail ceadApprovalDetails={ceadApprovalDetails} />
                )}
              </div>

              {procurement &&
                accountantUser &&
                !fromUser &&
                accountantStatus === "Pending" &&
                RemarksForm("Accountant")}

              {procurement &&
                accountantStatus === "Approved" &&
                remarksDetails(
                  accountantName,
                  accountantDescription,
                  "Accountant"
                )}

              {procurement &&
                supervisorUser &&
                !fromUser &&
                supervisorStatus === "Pending" &&
                accountantStatus === "Approved" &&
                RemarksForm()}

              {procurement &&
                supervisorStatus === "Approved" &&
                remarksDetails(
                  supervisorName,
                  supervisorDescription,
                  "Supervisor"
                )}

              {procurement &&
                managementUser &&
                !fromUser &&
                supervisorStatus === "Approved" &&
                managementStatus === "Pending" &&
                RemarksForm()}

              {procurement &&
                managementStatus === "Approved" &&
                remarksDetails(
                  managementName,
                  managementDescription,
                  "Management"
                )}

              {(loan || leave || procurement || tada || cead) && (
                <>
                  <div
                    className="requistion_details"
                    style={{ margin: 10, marginTop: 0 }}
                  >
                    <Row gutter={[10, 10]}>
                      <Col span={8}>
                        <div className="form_remarks">Remarks:</div>
                      </Col>
                    </Row>
                    <Row gutter={[10, 10]}>
                      <Col span={24}>
                        <Form.Item
                          name={
                            loan
                              ? "loanApproveRejectComment"
                              : procurement
                              ? "comment"
                              : leave || cead
                              ? "approveRejectRemarks"
                              : tada &&
                                (supervisorStatus === "Settlement Pending" ||
                                  supervisorStatus ===
                                    "Supervisor Settlement Approved" ||
                                  supervisorStatus ===
                                    "Management Settlement Approved")
                              ? "settlementComment"
                              : "comment"
                          }
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea
                            autoComplete="off"
                            rows={4}
                            rules={[
                              {
                                required: true,
                                message: "Required!",
                              },
                            ]}
                          ></TextArea>
                        </Form.Item>
                      </Col>
                      <br />
                    </Row>

                    {(leave || procurement) && (
                      <Button
                        type="link"
                        onClick={() => {
                          setShowMore(!showMore);
                        }}
                      >
                        Load More
                      </Button>
                    )}

                    <Row gutter={[10, 10]}>
                      <Col span={24}>
                        {showMore &&
                          (leave ? (
                            <LeaveTable loanApplicationId={loanApplicationId} />
                          ) : procurement ? (
                            <ProcurementTable
                              loanApplicationId={
                                procurementDetails?.requestedUserId
                              }
                            />
                          ) : (
                            ""
                          ))}
                      </Col>
                    </Row>
                  </div>
                  <div
                    className="leave-details-footer"
                    style={{ marginTop: 15 }}
                  >
                    <Row gutter={[24, 24]} style={{ float: "right" }}>
                      <Button
                        loading={
                          loading1 &&
                          (loanUpdateSupervisorLoading ||
                            loanUpdateAccountantLoading ||
                            loanUpdateManagementLoading ||
                            procurementUpdateAccountantLoading ||
                            procurementUpdateSupervisorLoading ||
                            procurementUpdateManagementLoading ||
                            tadaDetailsUpdateSupervisorLoading ||
                            tadaDetailsUpdateManagementLoading ||
                            tadaDetailsUpdateAccountantLoading ||
                            ceadApprovalDetailsLoading ||
                            leaveUpdateManagementLoading)
                        }
                        type="danger"
                        className="loan_reject"
                        htmlType="submit"
                        onClick={() => {
                          setLoading1(true);
                          setIsApproval(false);
                        }}
                      >
                        Reject
                      </Button>

                      <Button
                        loading={
                          loading2 &&
                          (loanUpdateSupervisorLoading ||
                            loanUpdateAccountantLoading ||
                            loanUpdateManagementLoading ||
                            procurementUpdateAccountantLoading ||
                            procurementUpdateSupervisorLoading ||
                            procurementUpdateManagementLoading ||
                            tadaDetailsUpdateSupervisorLoading ||
                            tadaDetailsUpdateManagementLoading ||
                            tadaDetailsUpdateAccountantLoading ||
                            ceadApprovalDetailsLoading ||
                            leaveUpdateManagementLoading)
                        }
                        type="primary"
                        style={{ marginLeft: "15px", marginRight: "15px" }}
                        htmlType="submit"
                        onClick={() => {
                          setLoading2(true);
                          setIsApproval(true);
                        }}
                      >
                        Accept
                      </Button>
                    </Row>
                  </div>
                </>
              )}
            </Form>
          </div>
        )}
      </Modal>
    </>
  );
};

export default RequisitionModal1;
