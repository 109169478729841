import { init, success, finish, error } from "services/common";
import { MeetingAgendaService } from "./api";
import { MEETING_AGENDA_TYPES } from "../../../../types";
import { meetingEventAgendaDecisionsList } from "../action";

const meetingAgendaService = new MeetingAgendaService();

export const addMeetingEventAgenda = (body) => {
  return async (dispatch) => {
    dispatch(init(MEETING_AGENDA_TYPES.ADD_MEETING_AGENDA));
    const response = await meetingAgendaService.addMeetingEventAgenda(body);
    dispatch(finish(MEETING_AGENDA_TYPES.ADD_MEETING_AGENDA));
    if (response.isSuccess) {
      dispatch(success(MEETING_AGENDA_TYPES.ADD_MEETING_AGENDA, response.data));
      dispatch(meetingEventAgendaDecisionsList(body.meetingEventId))
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const meetingEventAgendaUpdate = (body, id) => {
  return async (dispatch) => {
    dispatch(init(MEETING_AGENDA_TYPES.MEETING_AGENDA_UPDATE));
    const response = await meetingAgendaService.meetingEventAgendaUpdate(body, id);
    dispatch(finish(MEETING_AGENDA_TYPES.MEETING_AGENDA_UPDATE));
    if (response.isSuccess) {
      dispatch(success(MEETING_AGENDA_TYPES.MEETING_AGENDA_UPDATE, response.data));
      dispatch(meetingEventAgendaDecisionsList(body.meetingEventId))
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};