import { Button, Form, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { getAllUsers } from "services/remanentCalls";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { addEmployeeDepartment } from "services/redux/adminSettings/organizationStructure/designation/action";

const EmployeeModal = ({ openForm, setOpenForm, updateData }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const [messageParticipants, setMessageParticipants] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setMessageParticipants(resp.data);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      organizationDepartmentEmployeeDtos:
        updateData?.organizationDepartmentEmployeeDtos,
    });
  }, [updateData]);

  useEffect(() => {
    fetchAllParticipants();
  }, []);

  const onFinish = (values) => {
    const body = values?.organizationDepartmentEmployeeDtos?.map((x) => {
      return {
        employeeId: x.employeeId,
        isActive: true,
      };
    });
    const removedData = updateData?.organizationDepartmentEmployeeDtos
      ?.filter((item) => !body?.some((x) => x.employeeId === item.employeeId))
      .map((item) => ({
        ...item,
        isActive: false,
      }));

    dispatch(addEmployeeDepartment(body, updateData?.id));
  };

  const handleSelectChange = (field, value) => {
    setSelectedOptions((prevState) => {
      const newSelectedOptions = [...prevState];
      newSelectedOptions[field.key] = value;
      return newSelectedOptions;
    });
  };

  return (
    <Modal
      visible={openForm.modal}
      closable={false}
      footer={false}
      title={`Employees list of ${updateData?.departmentName} Department`}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.List name="organizationDepartmentEmployeeDtos">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => {
                const disabledOptions = messageParticipants.filter(
                  (participant) =>
                    selectedOptions.includes(participant?.employeeId)
                );
                return (
                  <>
                    <Form.Item
                      key={`${field.key}-id`}
                      {...field}
                      name={[field.name, "employeeId"]}
                      fieldKey={[field.fieldKey, "employeeId"]}
                      rules={[{ required: true, message: "Name is required" }]}
                      style={{
                        display: "inline-block",
                        width: "50%",
                      }}
                    >
                      <Select
                        style={{ fontSize: "14px" }}
                        optionFilterProp="label"
                        bordered={true}
                        autoFocus={true}
                        onChange={(value) => handleSelectChange(field, value)}
                        placeholder={`Internal To`}
                      >
                        {messageParticipants.map((participant, j) => {
                          const isDisabled = disabledOptions.some(
                            (option) =>
                              option.employeeId === participant?.employeeId
                          );

                          return (
                            <Option
                              key={j}
                              value={participant?.employeeId}
                              label={[
                                participant?.fullName,
                                participant?.userName,
                              ]}
                              disabled={isDisabled}
                            >
                              <div className="demo-option-label-item">
                                <span role="img">
                                  {participant?.fullName} {"" + ""}
                                </span>
                                [{participant?.userName.toUpperCase()}]
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <MinusCircleOutlined
                      style={{
                        display: "inline-block",
                        paddingLeft: 20,
                        paddingTop: 10,
                      }}
                      className="contact__btn-danger"
                      onClick={() => {
                        const indexesToRemove = [field.name];

                        const newSelectedOptions = selectedOptions.filter(
                          (_, index) => !indexesToRemove.includes(index)
                        );

                        setSelectedOptions((prevState) => {
                          return newSelectedOptions;
                        });

                        indexesToRemove.forEach((index) => remove(index));
                      }}
                    />
                  </>
                );
              })}
              <Form.Item>
                <Button
                  style={{
                    width: 45,
                    marginRight: 5,
                  }}
                  type="dashed"
                  className="contact__buttons"
                  onClick={() => {
                    add();
                  }}
                >
                  <PlusCircleOutlined
                    style={{
                      color: "green",
                    }}
                  />
                  Add User
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button
            onClick={() => {
              setOpenForm(false);
              //   setUpdateData("");
            }}
          >
            Cancel
          </Button>
          <Button
            type="link"
            className="btn-cancel"
            style={{
              width: "120px",
              backgroundColor: "#3879c5",
              color: "white",
              marginLeft: "8px",
            }}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmployeeModal;
