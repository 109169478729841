import { CEADAPPROVAL_TYPES } from "services/types";
import { init, success, finish, error } from "services/common";
import { ApprovaCEAD } from "./api";
import { message } from "antd";

const approvalCEAD = new ApprovaCEAD();

export const getApprovalCeadDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(CEADAPPROVAL_TYPES.CEADAPPROVAL_GET_DETAILS));
    const response = await approvalCEAD.getApprovalCeadDetails(id);
    dispatch(finish(CEADAPPROVAL_TYPES.CEADAPPROVAL_GET_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(CEADAPPROVAL_TYPES.CEADAPPROVAL_GET_DETAILS, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const approvalCeadApplicationUpdateManagement = (
  body,
  id,
  isApproval,
  setRequisitionModal,
  getProcurementAction,
  getProcurementCounter,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(CEADAPPROVAL_TYPES.CEADAPPROVAL_UPDATE_MANAGEMENT));
    const response =
      await approvalCEAD.apiForPaymentReceivedPrincipalInvoiceAccountApproved(
        body,
        id
      );
    dispatch(finish(CEADAPPROVAL_TYPES.CEADAPPROVAL_UPDATE_MANAGEMENT));
    if (response?.isSuccess) {
      dispatch(
        success(
          CEADAPPROVAL_TYPES.CEADAPPROVAL_UPDATE_MANAGEMENT,
          response?.data
        )
      );
      if (isApproval) {
        message.success("The cead is accepted");
      } else {
        message.success("The cead is rejected");
      }
      setRequisitionModal?.(false);
      getProcurementAction?.("TenderCEAD");
      dispatch(getProcurementCounter());
    } else {
      dispatch(error(response?.errorMessage));
      message.error("This cead has error");
      setRequisitionModal?.(false);
    }
  };
};
