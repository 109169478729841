import { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { isTokenExpired } from "../utils/misc";
import { isEmpty } from "lodash";
import LateAttendence from "./Home/LateAttendence";
import LoginForm from "./Login";
import useRoles from "hooks/useRoles";

export default function Welcome() {
  const [userName, setUserName] = useState();
  const { token } = useSelector((state) => state.auth);
  const { checkDailyAttendenceResponse, checkDailyAttendenceResponseLoading } =
    useSelector((state) => state.auth);
  const { LimitedRoleDemo, isLimitedRoleMailBox } = useRoles();

  if (!isTokenExpired(token)) {
    if (!isEmpty(checkDailyAttendenceResponse)) {
      if (checkDailyAttendenceResponse?.isLateReasonNeeded === true) {
        return (
          <LateAttendence
            id={checkDailyAttendenceResponse?.attendanceDetailId}
            data={checkDailyAttendenceResponse?.attendanceIn}
            leaveReasonPopUp={checkDailyAttendenceResponse?.isLeaveReasonNeeded}
            leaveStartdate={checkDailyAttendenceResponse?.leaveStartDate}
            leaveEndDate={checkDailyAttendenceResponse?.leaveEndDate}
          />
        );
      } else if (checkDailyAttendenceResponse?.isLateReasonNeeded === false) {
        // return <Redirect to="dashBoard" />;
        return (
          <Redirect
            to={
              LimitedRoleDemo
                ? "demoRoute"
                : isLimitedRoleMailBox
                ? "home"
                : "dashBoard"
            }
          />
        );
      }
    }
  }

  return (
    <div className="centered-view">
      <div className="centered-container">
        <LoginForm
          userName={userName}
          checkDailyAttendenceResponseLoading={
            checkDailyAttendenceResponseLoading
          }
        />
      </div>
    </div>
  );
}
