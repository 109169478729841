import { DOWNLOAD_FILE } from "services/types";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
import { DownloadFileService } from "./api";

const downloadFileService = new DownloadFileService();

export const actionForSampleDailyDairy = () => {
  return async (dispatch) => {
    dispatch(init(DOWNLOAD_FILE.SAMPLE_DAILY_DIARY));
    const response = await downloadFileService.apiForSampleDailyDairy();
    if (response.isSuccess) {
      dispatch(success(DOWNLOAD_FILE.SAMPLE_DAILY_DIARY, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(DOWNLOAD_FILE.SAMPLE_DAILY_DIARY));
  };
};
