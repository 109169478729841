import React from 'react'
import { Image, Modal } from "antd";
import {
    ExclamationCircleOutlined,
    ExclamationOutlined,
} from '@ant-design/icons';
import storage from 'utils/storage';
import { actionForGroupChatMailRead } from 'services/redux/groupChat/action';
import { useDispatch } from 'react-redux';
import { groupChatReadStatus } from 'utils/isReadGroupMail';
import GroupTitleCenter from './GroupTitleCenter';

// ICON
const forward = "./assets/svg/forward-12.svg";
const deletegroup = "./assets/svg/delete-12.svg";
const readIcon = "./assets/svg/send-12.svg";
const unreadDot = "assets/svg/unread.svg";

function DetailMenuCenter(props) {

    let {
        selectedGroupId,
        selectedGroupData,
        setSelectedGroupData,
        setIsDrawerVisible,
        setIsGroupTitleUpdating,
    } = props;

    let localStorageId = storage.getItem("userId");
    const dispatch = useDispatch();
    let isReadMail =  groupChatReadStatus(selectedGroupData);
    // console.log("selectedGroupData: ",selectedGroupData)

    const showDeleteConfirm = () => {
        Modal.confirm({
            title: 'Are you sure you want to delete this group?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                // Handle the delete action here
                console.log('Deleted');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <>
            <div className='emailDetails__header top-header-email'>
                <div
                    className={`emailDetails__header--icon`}
                    onClick={() => {
                        setIsDrawerVisible(true);
                        setIsGroupTitleUpdating(true);
                    }}
                >
                    {/* <Image src={forward} shape="square" preview={false} /> */}
                    <ExclamationCircleOutlined />
                    {/* <ExclamationOutlined /> */}
                    <p>Detail</p>
                </div>

                {/* {isReadMail ? ( */}
                {selectedGroupData?.isGroupChatRead ? (
                    <>
                    <div
                        className="emailDetails__header--icon"
                        onClick={() => {
                            if (selectedGroupId) {
                                dispatch(actionForGroupChatMailRead(
                                    selectedGroupId, 
                                    {
                                        participantStatusName: "unread",
                                        isGroupChatRead: false,
                                    },
                                    selectedGroupData?.creatorId,
                                    selectedGroupData?.id,
                                ));
                            }

                            setSelectedGroupData((prevData) => ({
                                ...prevData,
                                isGroupChatRead: false,
                            }))
                        }}
                    >
                        <Image src={readIcon} shape="square" preview={false} />
                        <p>UnRead</p>
                    </div>
                    </>
                ) : (
                    <div
                        className="emailDetails__header--icon"
                        onClick={() => {
                            if (selectedGroupId) {
                                dispatch(actionForGroupChatMailRead(
                                    selectedGroupId,
                                    {
                                        participantStatusName: "read",
                                        isGroupChatRead: true,
                                    },
                                    selectedGroupData?.creatorId,
                                    selectedGroupData?.id,
                                ));
                            }

                            setSelectedGroupData((prevData) => ({
                                ...prevData,
                                isGroupChatRead: true,
                            }))
                        }}
                    >
                        <Image src={readIcon} shape="square" preview={false} />
                        <p>Read</p>
                    </div>
                )}

                {localStorageId === selectedGroupData?.creatorId && (
                    <div
                        className="emailDetails__header--icon"
                        // onClick={() => {
                        //     // Handle delete group action here
                        // }}
                        onClick={showDeleteConfirm}
                        
                    >
                        <Image src={deletegroup} shape="square" preview={false} />
                        <p>Delete</p>
                    </div>
                )}
            </div>

            <GroupTitleCenter
                title={selectedGroupData?.chatGroupName}
                members={selectedGroupData?.userList}
                tags={selectedGroupData?.otherReferenceDtos}
            />
        </>
    )
}

export default DetailMenuCenter