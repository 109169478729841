import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeeCategoryData } from "services/redux/admin/FiscalYear/action";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import FeeRemainingTable from "./FeeChangeTable";
import FeeDueModal from "./FeeChangeModal";
import FeeDueTable from "./FeeChangeTable";

const FeeRemaining = () => {
  const dispatch = useDispatch();

  const { feeCategoryListResponse, feeCategoryListLoading } = useSelector(
    (state) => state.fiscalYearState
  );

  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    dispatch(getFeeCategoryData("FeeDueSub"));
  }, []);

  return (
    <>
      <div className="layout">
        <div className="organizer events">
          <div className="all-header-wrapper">
            <div className="meeting__flex">
              <h3 className="header__all">Fee Due </h3>
              <div className="organizerHoliday__middle--wrap">
                <Button
                  className="btn-primary"
                  onClick={() => {
                    setShowDrawer(true);
                  }}
                >
                  <PlusOutlined /> Add New Category
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FeeDueModal
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        // data={undefined}
      />

      <FeeDueTable
        dashboardFinancialResponse={feeCategoryListResponse}
        feeCategoryListLoading={feeCategoryListLoading}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
      />
    </>
  );
};

export default FeeRemaining;
