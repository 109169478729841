import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Skeleton,
  Button,
  Input,
  Dropdown,
  Form,
  Checkbox,
  Modal,
  Collapse,
  Row,
  Col,
} from "antd";
import {
  StarFilled,
  PlusOutlined,
  FilterOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import SiscoUserList from "./UserList/SiscoUserList";
import HrDropDownMenu from "./UserList/HrDropdownMenu";
import UserListCompact from "./UserList/UserListCompact";
import UpdateProfileView from "./UserList/UpdateProfileView";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import { getSisterCompanyPagination } from "services/redux/admin/sisterCompanyListing/action";
import AddUserDrawer from "./UserList/AddUserDrawer";
import AddUpdateSisterCompanyDrawer from ".././AdminSettings/AddUpdateSisterCompany/AddUpdateSisterCompanyDrawer";
import SiscoView from ".././AdminSettings/AddUpdateSisterCompany/SiscoView";
import { employeesPagination } from "services/redux/profile/profile.action";
import DepartmentListCompact from "./UserList/DepartmentListCompact";
import {
  getAllDepartments,
  getAllRemainder,
} from "services/redux/adminSettings/organizationStructure/action";
import { getPermissionListsForRole } from "services/redux/adminSettings/roles/action";
import { actionForExportSiscoUserListToExcel, actionForExportCompanyUserListToExcel } from "services/redux/holiday/action";
import UpdateRoleView from "../AdminSettings/Roles/UpdateRoleView";
import UpdateRole from "../AdminSettings/Roles/UpdateRole";
import ViewPermissionDrawer from "../AdminSettings/Roles/ViewPermissionDrawer";
import { saveBase64 } from "utils/saveBase64";

const minimize = "assets/images/icons/minimize.png";
const maximize = "assets/images/icons/maximize.png";

const defaultRoute = "/admin/user-list";
const { Panel } = Collapse;

function Admin() {
  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );
  const { profileInfo } = useSelector((state) => state.profile);
  const {
    sisterCompanyPagination,
    sisterCompanyPaginationLoading,
    addUpdatesisterCompanyResponse,
  } = useSelector((state) => state.sisterCompany);

  const {
    departmentListingResponse,
    departmentListingResponseLoading,
    remainderListingResponse,
    remainderListingResponseLoading,
  } = useSelector((state) => state.organizationStructure);

  const {
    holidaySampleResponse,
    holidaySampleLoading,
    userSiscolListResponse,
    userSiscoListLoading
  } = useSelector((state) => state.holiday);

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [addUserDrawerVisibility, setAddUserDrawerVisibility] = useState(false);
  const [permissionDrawerVisibility, setPermissionDrawerVisibility] =
    useState(false);
  const [updateProfileDrawerVisibility, setUpdateProfileDrawerVisibility] =
    useState(false);
  const [
    addUpdateSisterCompanyDrawerVisibility,
    setAddUpdateSisterCompanyDrawerVisibility,
  ] = useState(false);
  const [isUpdateSisterCompany, setIsUpdateSisterCompany] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState();
  const [searchList, setSearchList] = useState();
  const [SearchKeyword, setSearchKeyword] = useState();
  const pageSize = 40;

  const [sisocCompanyId, setSiscoCompanyId] = useState();
  // export button
  const [isDownloadStatus, setIsDownloadStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState(null);

  const [siscoId, setSiscoId] = useState();
  const [show, setHidden] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [userEmployeeId, setEmployeeUserId] = useState("");
  const [isUpdateCredentials, setIsUpdateCredentials] = useState();
  const [isUpdateSiscoCredentials, setIsUpdateSiscoCredentials] = useState();
  // added
  const [isEmployeeStatusChecked, setIsEmployeeStatusChecked] = useState(false);
  const [showAdvanceSearchModal, setShowAdvanceSearchModal] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [permissionDetails, setPermissionDetails] = useState();

  const showAddUserDrawer = () => {
    setUpdateProfileDrawerVisibility(false);

    setAddUserDrawerVisibility(true);
    setIsUpdateCredentials("");
  };

  const onAddUpdateSisterCompanyClose = () => {
    setIsUpdateSisterCompany("");
    setAddUpdateSisterCompanyDrawerVisibility(false);
  };

  const showAddUpdateSisterCompanyDrawer = (record) => {
    form.resetFields();
    if (record) {
      setIsUpdateSisterCompany(record);
    }
    setAddUpdateSisterCompanyDrawerVisibility(true);
  };

  const showUpdateProfileDrawer = (e, userId) => {
    setAddUserDrawerVisibility(false);
    if (userId) {
      dispatch(
        employeeProfileDetail({
          AppUserId: userId,
        })
      );
    }
    setUpdateProfileDrawerVisibility(true);
  };

  const onAddUserClose = () => {
    setAddUserDrawerVisibility(false);
  };

  const onUpdateProfileClose = () => {
    setUpdateProfileDrawerVisibility(false);
  };

  // Permission Drawer
  const { permissionListsForRoleResp, permissionListsForRoleLoading } =
    useSelector((state) => state.roleLists);

  const [selectedPermissionList, setSelectedPermissionList] = useState([]);

  const onViewPermissionClose = () => {
    setPermissionDrawerVisibility(false);
    setSelectedPermissionList([]);
  };

  useEffect(() => {
    if (!isEmpty(permissionDetails)) {
      dispatch(getPermissionListsForRole(permissionDetails));
    }
  }, [permissionDetails, dispatch]);

  useEffect(() => {
    if (!isEmpty(permissionListsForRoleResp)) {
      const newSelectedPermissionLists = permissionListsForRoleResp.filter(
        (list) => list.isGranted
      );
      setSelectedPermissionList(newSelectedPermissionLists);
    }
  }, [permissionListsForRoleResp]);
  //

  // const [departmentId, setDepartmentId] = useState();
  const [department, setDepartment] = useState({ id: "", type: "" });
  const [roleDetails, setRoleDetails] = useState({});
  const [drawerVisibility, setDrawerVisibility] = useState(false);

  const [state, setState] = useState({
    current: null,
    collapsed: false,
    openKeys: [],
  });

  const [grantedPolices, setGrantedPolices] = useState({});

  const handleClick = (e) => {
    history.push(e.key);
  };

  // added
  const handleEmployeeStatusChange = (checked) => {
    setIsEmployeeStatusChecked(!isEmployeeStatusChecked);
  };

  const handleOk = () => {
    setShowAdvanceSearchModal(false);
  };

  const handleCancel = () => {
    setShowAdvanceSearchModal(false);
    form.resetFields();
  };

  // dummy func
  const handleFormSubmit = () => {
    setShowResetButton(true);
    setShowAdvanceSearchModal(false);
    if (isEmployeeStatusChecked === true) {
      dispatch(
        employeesPagination(
          {
            EmployeeStatus: isEmployeeStatusChecked,
            MaxResultCount: 200,
            // SkipCount: 0,
          },
          false
        )
      );
    } else {
      getData();
    }
  };

  // added getData here
  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    ForPagination = false
  ) => {
    dispatch(
      employeesPagination(
        {
          MaxResultCount,
          SkipCount,
          Sorting,
          //SortType,
          SearchKeyword: inputSearchValue,
        },
        ForPagination
      )
    );
  };

  const getHrData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getSisterCompanyPagination(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword: SearchKeyword,
        },
        loadMore
      )
    );
  };
  const toggleCollapse = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };
  useEffect(() => {
    getHrData();
  }, [SearchKeyword]);

  useEffect(() => {
    if (!isEmpty(applicationConfiguration)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  useEffect(() => {
    if (!isEmpty(profileInfo)) {
      setIsUpdateCredentials(profileInfo);
    }
  }, [profileInfo]);

  useEffect(() => {
    if (addUpdatesisterCompanyResponse) {
      setIsUpdateSiscoCredentials(addUpdatesisterCompanyResponse);
    }
  }, [addUpdatesisterCompanyResponse]);

  useEffect(() => {
    if (history.location.pathname === "/admin") {
      history.push(defaultRoute);
    }
    let openKeys = [];
    if (
      history.location.pathname === "/admin/leave-fiscal-day" ||
      history.location.pathname === "/admin/leave-application-type"
    ) {
      openKeys.push("sub1");
    }
    setState({ ...state, current: history.location.pathname, openKeys });
  }, [history.location.pathname]);

  // added
  useEffect(() => {
    dispatch(getAllDepartments());
  }, []);

  // const renderComponent = (pathname) => {
  // 	switch (pathname) {
  // 		case defaultRoute:
  // 			return !isEmpty(grantedPolices) && grantedPolices['AbpIdentity.Users'] ? <UserList grantedPolices={grantedPolices} /> : <Redirect to="/admin/add-country" />;

  // 		default:
  // 			return <UserList />;
  // 	}
  // };

  useEffect(() => {
    if (!isEmpty(userSiscolListResponse) || !userSiscoListLoading) {
      try {
        if (isDownloadStatus) {
          let props = {
            base64Data: userSiscolListResponse?.data,
            fileName: userSiscolListResponse?.fileName,
            fileType: userSiscolListResponse?.fileExtension
          }
          saveBase64(props);
        }
      } catch (err) {
        console.log("error: ", err);
      } finally {
        setIsDownloadStatus(false);
      }
    }
  }, [userSiscolListResponse])

  const rowEventhandlersEmployee = (record) => {
    return {
      onClick: () => {
        setHidden(true);
        setSiscoId("");
        dispatch(employeeProfileDetail({ appUserId: record.appUserId }));
        setEmployeeId(record.appUserId);
        setEmployeeUserId(record?.employeeId);
        setDepartment({ id: "", type: "" });
      },
    };
  };
  const rowEventhandlersSisco = (record) => {
    return {
      onClick: () => {
        setIsUpdateSiscoCredentials(record);
        setSiscoCompanyId(record.id);
        setHidden(false);
        setEmployeeId();
        setEmployeeUserId();
        setDepartment({ id: "", type: "" });
        setSiscoId(record.id);
      },
    };
  };
  // added
  const rowEventhandlersDepartment = (record) => {
    return {
      onClick: () => {
        // setDepartmentId(record.id);
        setDepartment({ id: record.id, type: record.departmentName });
        setHidden(false);
        setEmployeeId();
        setEmployeeUserId();
        setSiscoCompanyId();
      },
    };
  };

  const onAddUpdateRoleClose = () => {
    setRoleDetails("");
    setDrawerVisibility(false);
  };

  const editRoleDetails = (data) => {
    setRoleDetails(data);
    setDrawerVisibility(true);
  };

  const viewPermissionDetails = (data) => {
    setPermissionDetails(data);
    setPermissionDrawerVisibility(true);
  };

  const setRowClassName = (record) => {
    return record.appUserId === employeeId ? "clickRowStyl" : "";
  };

  const setSiscoClassName = (record) => {
    return record.id === siscoId ? "clickRowStyl" : "";
  };

  const setDepartmentClassName = (record) => {
    // return record.id === departmentId ? "clickRowStyl" : "";
    return record.id === department?.id ? "clickRowStyl" : "";
  };

  const handleExport = async (type) => {
    // console.log("type");
    setLoading(true);
    setLoadingType(type);
    setIsDownloadStatus(true);
    try {
      if(type == 'company') {
        await dispatch(actionForExportCompanyUserListToExcel());
      } else {
        await dispatch(actionForExportSiscoUserListToExcel());
      }
    } catch(err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
      setLoadingType(null);
    }
  }

  if (!state.current) {
    return <Skeleton />;
  }

  return (
    <div className="sidebars">
      <div
        className={`sidebars__menu ${state.collapsed && "text-align-right"}`}
      >
        {/* <div className="toggle__arrow" onClick={toggleCollapse}> */}
        <div className="toggle__arrow">
          {state.collapsed ? <img src={maximize} /> : <img src={minimize} />}
        </div>
        <div className="sidebar-title">Human Resources</div>
        <div className="search-wrapper">
          {/* <StarFilled width="10" height="10" /> */}
          {/* <Input.Search
            className="form__group_email_search"
            bordered={false}
            style={{ margin: 0 }}
            placeholder="Search"
            // size="medium"
            allowClear
            onSearch={(e) => {
              setInputSearchValue(e);
            }}
            autoComplete="off"
          /> */}
          {!showResetButton ? (
            <Input.Search
              className="form__group_email_search"
              bordered={false}
              style={{ margin: 0 }}
              placeholder="Search"
              // size="medium"
              allowClear
              onSearch={(e) => {
                setInputSearchValue(e);
              }}
              autoComplete="off"
            />
          ) : (
            <>
              <Input.Search
                className="form__group_email_search"
                style={{ margin: 0 }}
                // allowClear
                size="medium"
                bordered={false}
                value="[Advance Search]"
              />
              <button
                className="clear_icon_advance_search"
                onClick={() => {
                  getData();
                  setShowResetButton(false);
                }}
              >
                <CloseCircleOutlined className="ant_close_icon" />
              </button>
            </>
          )}

          <div
            onClick={() => {
              setShowAdvanceSearchModal(true);
            }}
          >
            <FilterOutlined className="hr_searchOutlined" />
          </div>

          {/* <StarFilled width="10" height="10" /> */}
          {/* <Input
            allowClear
            // onClear={() => {
            //   setInputSearchValue("");
            // }}
            bordered={false}
            placeholder="Search"
            onPressEnter={(e) => {
              setInputSearchValue(e.target.value);
            }}
            autoComplete="off"
          /> */}
          <Dropdown
            placement="bottomRight"
            trigger={["click"]}
            overlay={
              <HrDropDownMenu
                showAddUserDrawer={showAddUserDrawer}
                showAddUpdateSisterCompanyDrawer={
                  showAddUpdateSisterCompanyDrawer
                }
              />
            }
            arrow
          >
            <div className="contactDetails__dropdown">
              <Button className="contact__header__btn-primary">
                <PlusOutlined width={10} height={10} />
              </Button>
            </div>
          </Dropdown>
        </div>
        <div></div>
        {/* <div style={{ height: "43%" }}>
          <SiscoUserList
            setSiscoClassName={setSiscoClassName}
            grantedPolices={grantedPolices}
            rowEventhandlersSisco={rowEventhandlersSisco}
            inputSearchValue={inputSearchValue}
            sisterCompanyPagination={sisterCompanyPagination}
            sisterCompanyPaginationLoading={sisterCompanyPaginationLoading}
          />
        </div>
        <div style={{ height: "43%" }}>
          <UserListCompact
            rowEventhandlers={rowEventhandlersEmployee}
            setRowClassName={setRowClassName}
            grantedPolices={grantedPolices}
            inputSearchValue={inputSearchValue}
            isEmployeeStatusChecked={isEmployeeStatusChecked}
          />
        </div> */}
        <div className="profiledetails">
          <Collapse
            expandIconPosition="right"
            accordion={true}
            defaultActiveKey={["1"]}
          >
            <Panel key="1" header="Sisco User list">
              <div>
                <div style={{ height: "43%" }}>
                  <SiscoUserList
                    setSiscoClassName={setSiscoClassName}
                    grantedPolices={grantedPolices}
                    rowEventhandlersSisco={rowEventhandlersSisco}
                    inputSearchValue={inputSearchValue}
                    sisterCompanyPagination={sisterCompanyPagination}
                    sisterCompanyPaginationLoading={
                      sisterCompanyPaginationLoading
                    }
                  />
                </div>
              </div>
            </Panel>
            <Panel key="2" header="User list">
              <div>
                <div style={{ height: "43%" }}>
                  <UserListCompact
                    rowEventhandlers={rowEventhandlersEmployee}
                    setRowClassName={setRowClassName}
                    grantedPolices={grantedPolices}
                    inputSearchValue={inputSearchValue}
                    isEmployeeStatusChecked={isEmployeeStatusChecked}
                  />
                </div>
              </div>
            </Panel>
            <Panel key="3" header="Deparment">
              <div style={{ height: "43%" }}>
                <DepartmentListCompact
                  rowEventhandlers={rowEventhandlersDepartment}
                  setRowClassName={setDepartmentClassName}
                  departmentListingResponse={departmentListingResponse}
                  departmentListingResponseLoading={
                    departmentListingResponseLoading
                  }
                />
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
      <div className="main__body admin__body">
        <div style={{ padddingLeft: 25 }}>
          {show ? (
            employeeId ? (
              <UpdateProfileView
                isUpdateCredentials={employeeId}
                form={form}
                showAddUserDrawer={showAddUserDrawer}
                userEmployeeId={userEmployeeId}
              />
            ) : (
              <div style={{ padddingLeft: 25 }}>
                <Skeleton
                  avatar={{ size: 96, shape: "square" }}
                  paragraph={{ rows: 10 }}
                />
              </div>
            )
          ) : sisocCompanyId ? (
            <div>
              <SiscoView
                isUpdateSiscoCredentials={isUpdateSiscoCredentials}
                showAddUpdateSisterCompanyDrawer={
                  showAddUpdateSisterCompanyDrawer
                }
              />
            </div>
          ) : // : (
          //   <div style={{ padddingLeft: 25 }}>
          //     <Skeleton
          //       avatar={{ size: 96, shape: "square" }}
          //       paragraph={{ rows: 10 }}
          //     />
          //   </div>
          // )}
          // departmentId ? (
          department?.type ? (
            <div>
              <UpdateRoleView
                type={department?.type}
                grantedPolices={grantedPolices}
                editRoleDetails={editRoleDetails}
                viewPermissionDetails={viewPermissionDetails}
              />
            </div>
          ) : (
            <div style={{ paddingLeft: 25 }}>
              <Skeleton
                avatar={{ size: 96, shape: "square" }}
                paragraph={{ rows: 10 }}
              />
            </div>
          )}
        </div>

        <AddUpdateSisterCompanyDrawer
          drawerVisibility={addUpdateSisterCompanyDrawerVisibility}
          onAddUpdateSisterCompanyClose={onAddUpdateSisterCompanyClose}
          isUpdateSisterCompany={isUpdateSisterCompany}
          // sisterCompanyListing={sisterCompanyListing}
        />

        <UpdateRole
          onAddUpdateRoleClose={onAddUpdateRoleClose}
          roleDetails={roleDetails}
          drawerVisibility={drawerVisibility}
        />
      </div>
      {/* <div className="main__body">{renderComponent(history.location.pathname)}</div> */}
      <AddUserDrawer
        form={form}
        drawerVisibility={addUserDrawerVisibility}
        onAddUserClose={onAddUserClose}
        isUpdateCredentials={isUpdateCredentials}
      />

      <ViewPermissionDrawer
        drawerVisibility={permissionDrawerVisibility}
        onViewPermissionClose={onViewPermissionClose}
        permissionDetails={permissionDetails}
        permissionListsForRoleLoading={permissionListsForRoleLoading}
        selectedPermissionList={selectedPermissionList}
      />

      <Modal
        title="Advance HR Search"
        visible={showAdvanceSearchModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        wrapClassName="advance_search_email"
      >
        <Form
          onFinish={handleFormSubmit}
        >
          <Row
            gutter={[0, 12]}
          >
            <Col span="8">Export Company</Col>
            <Col span="16">
              <Button
                type="dashed"
                onClick={() => handleExport('company')}
                loading={loading && loadingType === 'company'}
              >Download</Button>
            </Col>
            <Col span="8">Export Person</Col>
            <Col span="16">
              <Button
                type="dashed"
                onClick={() => handleExport('person')}
                loading={loading && loadingType === 'person'}
              >Download</Button>
            </Col>
            <Col span="8">show InActive Users</Col>
            <Col span="16">
              <Checkbox
                checked={isEmployeeStatusChecked}
                onChange={(e) => handleEmployeeStatusChange(e.target.checked)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "6px",
                }}
              />
            </Col>
          </Row>
          <Button
            type="ghost"
            shape="round"
            htmlType="submit"
            autoComplete="off"
          >
            Search
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

// export default withBaseLayout(Admin);
export default Admin;
