import { EMAIL_TYPES } from "../../../types";
import { uniqBy } from "lodash";

const INITIAL_STATE = {
  participantTypeEmailAddressesListing: {
    items: [],
    totalCount: 1,
  },
  participantTypeEmailAddressesListingLoading: true,

  emailAddressFromWithBehalfListing: [],
  emailAddressFromWithBehalfListingLoading: false,
};

export default function participantTypeEmailAddressesListingReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    // Appointment events
    case EMAIL_TYPES.PARTICIPANT_EMAIL_ADDRESSES_LISTING_INIT:
      return {
        ...state,

        participantTypeEmailAddressesListingLoading: true,
      };
    case EMAIL_TYPES.PARTICIPANT_EMAIL_ADDRESSES_LISTING_SUCCESS:
      // console.log("infinite", action);
      return {
        ...state,
        participantTypeEmailAddressesListing: {
          items: action.loadMore
            ? uniqBy(
                state.participantTypeEmailAddressesListing.items.concat(
                  action.payload.items
                ),
                "id"
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
        participantTypeEmailAddressesListingLoading: false,
      };
    case EMAIL_TYPES.PARTICIPANT_EMAIL_ADDRESSES_LISTING_FINISH:
      return {
        ...state,
        participantTypeEmailAddressesListingLoading: true,
      };

    //  Email Addresses from with behalf listing
    case EMAIL_TYPES.EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING_INIT:
      return {
        ...state,
        emailAddressFromWithBehalfListing: [],
        emailAddressFromWithBehalfListingLoading: true,
      };
    case EMAIL_TYPES.EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING_SUCCESS:
      return {
        ...state,
        emailAddressFromWithBehalfListing: action.payload,
        emailAddressFromWithBehalfListingLoading: false,
      };
    case EMAIL_TYPES.EMAIL_ADDRESSES_FROM_WITH_BEHALF_LISTIING_FINISH:
      return {
        ...state,
        emailAddressFromWithBehalfListingLoading: false,
      };
    default:
      return { ...state };
  }
}
