import { Card, Col, Row, Typography } from "antd";
import cross from "assets/images/cross.png";
import tick from "assets/images/tick.png";
import { isEmpty } from "lodash";

export default function QADetails({ qaDetails }) {
  const formatKey = (key) => {
    // Split the string by lowercase letters followed by uppercase letters
    const words = key.match(/[a-z]+|[A-Z][a-z]*/g);
    // Capitalize the first letter of each word
    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    // Join the words with a space
    return formattedWords ? formattedWords.join(" ").replace("Is", "") : key;
  };

  return (
    <>
      <Card>
        {!isEmpty(qaDetails) ? (
          <Row>
            {Object.entries(qaDetails)?.map(([key, value]) => (
              <>
                <Col offset={0} span={5} className="cead-details-text">
                  {formatKey(key)}:
                </Col>
                <Col span={17} className="cead-details-text">
                  {" "}
                  {value === true ? (
                    <img src={tick} alt="" height={10} />
                  ) : value === false ? (
                    <img src={cross} alt="" height={10} />
                  ) : (
                    value
                  )}
                </Col>
              </>
            ))}
          </Row>
        ) : (
          <div className="cead-details-text-no-data">No Available Data</div>
        )}
      </Card>
    </>
  );
}
