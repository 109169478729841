import { REFERENCE__TYPES, CIRCULAR_MAIL_TYPES } from "../../types";
import { ReferencesService } from "./api";
import {
  init,
  success,
  finish,
  error,
  updateOnly,
  partialUpdate,
} from "services/common";
import { message } from "antd";
import { actionForUpdateTenderCircularMail } from "../circularMail/action";

const referencesService = new ReferencesService();

export const stagePagination = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.STAGE_PAGINATION));
    const response = await referencesService.getStagePagination();
    dispatch(finish(REFERENCE__TYPES.STAGE_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.STAGE_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const stageAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.STAGE_ADD));
    const response = await referencesService.stageAdd(body);
    dispatch(finish(REFERENCE__TYPES.STAGE_ADD));
    if (response.isSuccess) {
      message.success("Successfully added Stage");
      dispatch(stagePagination());
      dispatch(success(REFERENCE__TYPES.STAGE_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const stageUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.STAGE_UPDATE));
    const response = await referencesService.stageUpdate(id, body);
    dispatch(finish(REFERENCE__TYPES.STAGE_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated stage");
      dispatch(stagePagination());
      dispatch(success(REFERENCE__TYPES.STAGE_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const allCompanyListing = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.CURRENCY_LISTING));
    const response = await referencesService.allCurrencyListing();
    dispatch(finish(REFERENCE__TYPES.CURRENCY_LISTING));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.CURRENCY_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//ReferenceType
export const referenceTypePagination = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_PAGINATION));
    const response = await referencesService.referenceTypePagination();
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_TYPE_PAGINATION, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const referenceTypeAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_ADD));
    const response = await referencesService.referenceTypeAdd(body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_ADD));
    if (response.isSuccess) {
      message.success("Successfully added Refernce Type");
      dispatch(referenceTypePagination());
      dispatch(success(REFERENCE__TYPES.REFERENCE_TYPE_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const referenceTypeUpdate = (id, body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE));
    const response = await referencesService.referenceTypeUpdate(id, body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE));
    if (response.isSuccess) {
      message.success("Successfully updated Reference Type");
      dispatch(referenceTypePagination());
      dispatch(success(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getReferenceListing = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_EMAIL_LISTING));
    const response = await referencesService.referenceListing(query);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_EMAIL_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_EMAIL_LISTING,
          response.data,
          loadMore
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// ReferencePartyListing

export const referencePartyListing = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_PARTY_lISTING));
    const response = await referencesService.referencePartyListing(id);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_PARTY_lISTING));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_PARTY_lISTING, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// ADDED cirular planning
export const actionForReferencePartyListingCircular = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_PARTY_LISTING_CIRCULAR));
    const response = await referencesService.referencePartyListingCircular(id);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_PARTY_LISTING_CIRCULAR));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_PARTY_LISTING_CIRCULAR,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// post
export const actionForReferenceCircularPlanningAdd = (
  type,
  bodyPayload,
  data,
  data1
) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_ADD));
    const response =
      await referencesService.referenceCompanyCircularPlanningAdd(
        bodyPayload,
        data
      );
    dispatch(finish(REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_ADD));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_ADD, response.data)
      );
      dispatch(
        partialUpdate(REFERENCE__TYPES.TENDER_BASIC_GetDetails, response.data)
      );
      if (type === "tender") {
        await dispatch(referenceProjectCircularPlanningRemarksAdd(data1));
      } else {
        await dispatch(referenceCircularPlanningRemarksAdd(data1));
      }
      // await dispatch(referenceCircularPlanningRemarksAdd(data1));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// UPDATE CIRCULAR
export const actionForReferenceCircularPlanningUpdate = (body, data) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_UPDATE));
    try {
      const response =
        await referencesService.referenceCompanyCircularPlanningAdd(body, data);
      if (response?.isSuccess) {
        dispatch(
          success(
            REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_UPDATE,
            response.data
          )
        );

        dispatch(actionForUpdateTenderCircularMail(response.data));
        dispatch(
          updateOnly(
            REFERENCE__TYPES.REFERENCE_PARTY_LISTING_CIRCULAR,
            response.data
          )
        );
        message.success("updated");
      } else if (!response?.isSuccess) {
        dispatch(error(response.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(finish(REFERENCE__TYPES.REFERENCE_CIRCULAR_PLANNING_UPDATE));
    }
  };
};

// circular remkarks add
export const referenceCircularPlanningRemarksAdd = (data) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_REMARKS_CIRCULAR_PLANNING));
    const response = await referencesService.referencePartyRemarksHistoryAdd(
      data
    );
    dispatch(finish(REFERENCE__TYPES.REFERENCE_REMARKS_CIRCULAR_PLANNING));
    if (response.isSuccess) {
      message.success("Successfully added Remarks");
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_REMARKS_CIRCULAR_PLANNING,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// PROJECT REMARKS ADD
export const referenceProjectCircularPlanningRemarksAdd = (data) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_REMARKS_CIRCULAR_PLANNING));
    const response = await referencesService.projectPartyRemarksHistoryAdd(
      data
    );
    dispatch(finish(REFERENCE__TYPES.REFERENCE_REMARKS_CIRCULAR_PLANNING));
    if (response.isSuccess) {
      message.success("Successfully added Remarks");
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_REMARKS_CIRCULAR_PLANNING,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// DELETE COMPANY
export const actionForReferenceCompanyDelete = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_COMPANY_DELETE));
    const response = await referencesService.referenceCompanyDelete(id);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_COMPANY_DELETE));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_COMPANY_DELETE, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//   ReferencePartyRemarksHistoryAdd
export const referencePartyRemarksHistoryAdd = (apiData) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_ADD));
    const response = await referencesService.referencePartyRemarksHistoryAdd(
      apiData
    );
    dispatch(finish(REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_ADD));
    if (response.isSuccess) {
      message.success("Successfully added Remarks");
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_ADD,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// solo REMARKS ADD
export const referencePartyRemarksAddSolo = (type, data) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_SOLO));
    // const response =
    // type === "tender" ? await referencesService.referencePartyRemarksHistoryAddSolo(data) : await referencesService.projectPartyRemarksHistoryAdd(data);
    // dispatch(finish(REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_SOLO));

    // if (response.isSuccess) {
    //   message.success("Success added Reamrks");
    //   dispatch(
    //     success(
    //       REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_SOLO,
    //       response.data
    //     )
    //   );
    // } else if (!response?.isSuccess) {
    //   dispatch(error(response?.errorMessage));
    // }
    try {
      const response =
        type === "tender"
          ? await referencesService.referencePartyRemarksHistoryAddSolo(data)
          : await referencesService.projectPartyRemarksHistoryAdd(data);

      dispatch(finish(REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_SOLO));

      if (response.isSuccess) {
        message.success("Remarks added successfully");
        dispatch(
          success(
            REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_SOLO,
            response.data
          )
        );
      } else {
        dispatch(error(response.errorMessage || "Failed to add Remarks"));
      }
    } catch (error) {
      dispatch(error("An error occurred while adding Remarks"));
    }
  };
};

// Reference Party Remarks History listing
export const referencePartyRemarksHistoryListing = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_LISTING));
    const response =
      await referencesService.referencePartyRemarksHistoryListing(body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_LISTING));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_LISTING,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// PROJECT HISTORY LISING
export const actionForProjectHistoryListing = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_LISTING));
    const response = await referencesService.apiForProjectRemarksHistoryListing(
      body
    );
    dispatch(finish(REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_LISTING));

    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_PARTY_REMARKS_HISTORY_LISTING,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const ReferenceCompanyManualAdd = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_COMPANY_MANUAL_ADD));
    const response = await referencesService.ReferenceCompanyManualAdd(id);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_COMPANY_MANUAL_ADD));
    if (response.isSuccess) {
      message.success("Successfully updated company ");
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_COMPANY_MANUAL_ADD, response.data)
      );
      // console.log("ACTION: response - ", response);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// add company and remarks both
export const ReferenceRemarksCompanyAdd = (type, data1, data2) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_COMPANY_MANUAL_ADD));
    const response = await referencesService.ReferenceCompanyManualAdd(data1);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_COMPANY_MANUAL_ADD));
    if (response.isSuccess) {
      message.success("Successfully updated company");
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_COMPANY_MANUAL_ADD, response.data)
      );
      if (type === "tender") {
        await dispatch(referencePartyRemarksHistoryAdd(data2));
      } else {
        await dispatch(referenceProjectCircularPlanningRemarksAdd(data2));
      }
      // await dispatch(referencePartyRemarksHistoryAdd(data2));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateReferenceCompany = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.UPDATE_REFERENCE_COMPANY));
    const response =
      await referencesService.UpdateReferenceCompanyOfMailMessageByReference(
        id
      );
    dispatch(finish(REFERENCE__TYPES.UPDATE_REFERENCE_COMPANY));
    if (response.isSuccess) {
      message.success("Successfully updated Reference ");
      dispatch(
        success(REFERENCE__TYPES.UPDATE_REFERENCE_COMPANY, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const referencePartyAction = (id, body, searchEmailTender) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_PARTY_ACTION));
    const response = await referencesService.referencePartyAction(id, body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_PARTY_ACTION));
    if (response.isSuccess) {
      message.success("Successfully updated Reference action");
      // dispatch(referenceTypePagination());
      body?.actionOf === "interested" &&
        dispatch(
          referencePartyListing({
            referenceCode: searchEmailTender,
          })
        );
      dispatch(success(REFERENCE__TYPES.REFERENCE_PARTY_ACTION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// mail
export const mailReferencePartyAction = (id, body, searchEmailTender) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_PARTY_ACTION));
    const response = await referencesService.referencePartyAction(id, body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_PARTY_ACTION));
    if (response.isSuccess) {
      message.success("Successfully updated Reference action");
      // dispatch(referenceTypePagination());
      body?.actionOf === "interested" &&
        dispatch(
          referencePartyListing({
            referenceCode: searchEmailTender,
          })
        );
      dispatch(success(REFERENCE__TYPES.REFERENCE_PARTY_ACTION, response.data));
      // added
      // console.log("ACTION data: ", response.data);
      let responsePayload = {
        id: id,
        tiedStatus: response.data,
      };
      // console.log("responsePayload: ", responsePayload);
      dispatch(
        partialUpdate(
          CIRCULAR_MAIL_TYPES.GET_TENDER_CIRCULAR_MAIL,
          responsePayload
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getAutoReferenceData = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.AUTO_REFERENCE_LISTING));
    const response = await referencesService.getAutoReferenceData();
    dispatch(finish(REFERENCE__TYPES.AUTO_REFERENCE_LISTING));
    if (response?.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.AUTO_REFERENCE_LISTING, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};
export const emailAutoReferenceAdd = (body, form) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD));
    const response = await referencesService.emailAutoReferenceAdd(body);
    dispatch(finish(REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD));
    if (response?.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD, response?.data)
      );
      message.success("Successfully added reference rule");
      dispatch(getAutoReferenceData());
      // dispatch(getAutoReferenceData());
      // form.resetFields();
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const emailAutoReferenceUpdate = (body, form, id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD));
    const response = await referencesService.emailAutoReferenceUpdate(body, id);
    dispatch(finish(REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD));
    if (response?.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.EMAIL_AUTO_REFERENCE_ADD, response?.data)
      );
      message.success("Successfully Updated Reference Rules.");
      dispatch(getAutoReferenceData());
      // form.resetFields();
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const contactUpdateSmall = (id, body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.CONTACT_UPDATE));
    const response = await referencesService.updateContactData(id, body);
    if (response.isSuccess) {
      message.success("Successfully updated the contact");
      dispatch(success(REFERENCE__TYPES.CONTACT_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.CONTACT_UPDATE));
  };
};

// added
export const actionForGetAllCircularPlanningTemplate = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_ALL_CIRCULARPLANNING_TEMPLATE));
    const response = await referencesService.getAllCircularPlanningTemplate();
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.GET_ALL_CIRCULARPLANNING_TEMPLATE,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.GET_ALL_CIRCULARPLANNING_TEMPLATE));
  };
};

export const actionForGetCircularPlanningCompanyDtos = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_CIRCULARPLANNING_TEMPLATE));
    const response = await referencesService.getCircularPlanningTemplate(id);
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.GET_CIRCULARPLANNING_TEMPLATE, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.GET_CIRCULARPLANNING_TEMPLATE));
  };
};

// post
export const actionForAddCircularPlanningTemplate = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.ADD_CIRCULARPLANNING_TEMPLATE));
    const response = await referencesService.addCircularPlanningTemplate(body);
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.ADD_CIRCULARPLANNING_TEMPLATE, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.ADD_CIRCULARPLANNING_TEMPLATE));
  };
};

// updated
export const actionForUpdateCircularPlanningCompany = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.UPDATED_CIRCULARPLANNING_COMPANY));
    const response = await referencesService.updateCircularPlanningCompany(
      body
    );
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.UPDATED_CIRCULARPLANNING_COMPANY,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.UPDATED_CIRCULARPLANNING_COMPANY));
  };
};

export const actionForAddCompanyOrContactPersonEmailAddById = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.ADD_COMPANY_EMAIL));
    const response =
      await referencesService.addCompanyOrContactPersonEmailAddById(body);
    try {
      if (response.isSuccess) {
        message.success("Saved Mail Address");
        dispatch(success(REFERENCE__TYPES.ADD_COMPANY_EMAIL, response.data));
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch (err) {
      throw err;
    }

    dispatch(finish(REFERENCE__TYPES.ADD_COMPANY_EMAIL));
  };
};

// import
export const actionForAddImportIntoReferenceCompanies = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.ADD_IMPORT_INTO_REFERENCE_COMPANY));
    const response = await referencesService.addImportIntoReferenceCompanies(
      body
    );
    if (response.isSuccess) {
      message.success("Import success");
      dispatch(
        success(
          REFERENCE__TYPES.ADD_IMPORT_INTO_REFERENCE_COMPANY,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.ADD_IMPORT_INTO_REFERENCE_COMPANY));
  };
};

// log
export const actionForCircularPlanningLog = (q, loadMore) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_CIRCULAR_PLANNING_LOG));
    const response = await referencesService.getCircularPlanningLog(q);
    if (response.isSuccess) {
      if (loadMore) {
        console.log("loadMore");
        dispatch(
          success(
            REFERENCE__TYPES.GET_CIRCULAR_PLANNING_LOG_MORE,
            response.data
          )
        );
      } else {
        dispatch(
          success(REFERENCE__TYPES.GET_CIRCULAR_PLANNING_LOG, response.data)
        );
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.GET_CIRCULAR_PLANNING_LOG));
  };
};
