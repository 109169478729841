import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddUpdateTendeDrawer } from "./AddUpdateDrawer";
import { Row, Col, Button } from "antd";


export const AddUpdateNewsCutting = () => {
   const [addUpdateDrawerVisibility, setAddUpdateDrawerVisibility] = useState(false);
   const [isUpdateApplication, setisUpdateApplication] = useState(false);
   const showAddUpdateDrawer = (e, record) => {
     setAddUpdateDrawerVisibility(false);
     if (record) {
       setisUpdateApplication(record);
     } else {
       setisUpdateApplication(false);
     }

     setAddUpdateDrawerVisibility(true);
   };
   const onAddUpdateDrawerClose = () => {
     setisUpdateApplication('');
     setAddUpdateDrawerVisibility(false);
   };
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <h3 className='header__all '>Procrument</h3>
        </Col>
        <Col span={12}>
          <div className='organizerHoliday__middle--wrap'>
            <Button onClick={showAddUpdateDrawer}>Add Procrument</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AddUpdateTendeDrawer
            drawerVisibility={addUpdateDrawerVisibility}
            onAddUpdateDrawerClose={onAddUpdateDrawerClose}
            isUpdateApplication={isUpdateApplication}
          />
        </Col>
      </Row>
    </div>
  );
};
