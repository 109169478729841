import { appBaseUrl } from "utils/config";
import { getService, getServiceMail } from "../../../commonServices";
import queryString from "query-string";
export class ParticipationTypeService {
  getParticipantTypeEmailAddressesListing(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/emailMessage/participantTypeEmailAddressesListingPagination?${query}`;
    let data = getService(url);
    return data;
  }

  getEmailAddressesFromWithBehalfOfListing() {
    let url = `${appBaseUrl}/emailMessage/emailAddressesFromWithBehalfOfListing`;
    let data = getService(url);
    return data;
  }
}
