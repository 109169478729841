import { Button, Table } from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { EditOutlined, BankOutlined } from "@ant-design/icons";
import { AddUpdateDrawerDetails } from "./AddUpdatedrawer";
import { useDispatch, useSelector } from "react-redux";
import { getSingleTadaDetails } from "services/redux/requistions/tadaDetails/action";

const TadaDetailsTable = ({
  getTadaDetailsResponse,
  getTadaDetailsLoading,
}) => {
  const dispatch = useDispatch();
  const { getTadaSingleDetailsResponse } = useSelector(
    (state) => state.tadaDetails
  );
  const [addUpdateDrawerVisibility, setAddUpdateDrawerVisibility] =
    useState(false);
  const [isUpdateApplication, setisUpdateApplication] = useState(false);

  const [applicantState, setApplicantState] = useState(true);

  const showAddUpdateDrawer = (e, record) => {
    setAddUpdateDrawerVisibility(false);
    if (record) {
      setisUpdateApplication(record);
    } else {
      setisUpdateApplication(false);
    }

    setAddUpdateDrawerVisibility(true);
  };

  const onAddUpdateDrawerClose = () => {
    setisUpdateApplication("");
    setAddUpdateDrawerVisibility(false);
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "sisterCompany",
      key: "sisterCompany",
      render: (record) => <span>{record?.name}</span>,
    },
    {
      title: "Purpose of Visit",
      dataIndex: "purposeOfVisit",
      key: "purposeOfVisit",
    },
    {
      title: "Place of Visit",
      dataIndex: "placeOfVisit",
      key: "placeOfVisit",
    },
    {
      title: "Visit Date Range",
      key: "",
      render: (row) => {
        return (
          <>
            {moment(row?.visitStartDateEn).format("YYYY/MM/DD")} -{" "}
            {moment(row?.visitEndDateEn).format("YYYY/MM/DD")}
          </>
        );
      },
    },
    {
      title: "Visit Days",
      dataIndex: "visitDays",
      key: "visitDays",
    },
    {
      title: "Requested Amount",
      dataIndex: "totalAdvanceRequestedAmount",
      key: "totalAdvanceRequestedAmount",
    },
    {
      title: "Status",
      dataIndex: "tadaApplicationStatus",
      key: "tadaApplicationStatus",
      render: (record) => (
        <span className="application_status_name">{record?.displayName}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (record) => (
        <>
          {record?.tadaApplicationStatus?.displayName ===
          "Account Advance Approved" ? null : (
            <Button
              htmlType="submit"
              style={{ border: 0, backgroundColor: "transparent" }}
              icon={<EditOutlined />}
              onClick={() => {
                showAddUpdateDrawer(true);
                dispatch(getSingleTadaDetails(record?.id));
                record?.tadaApplicationStatus?.displayName ===
                  "Settlement Pending" ||
                record?.tadaApplicationStatus?.displayName ===
                  "Supervisor Settlement Approved" ||
                record?.tadaApplicationStatus?.displayName ===
                  "Supervisor Settlement Reject" ||
                record?.tadaApplicationStatus?.displayName ===
                  "Management Settlement Approved" ||
                record?.tadaApplicationStatus?.displayName ===
                  "Management Settlement Reject" ||
                record?.tadaApplicationStatus?.displayName ===
                  "Account Settlement Approved" ||
                record?.tadaApplicationStatus?.displayName ===
                  "Account Settlement Reject"
                  ? setApplicantState(false)
                  : setApplicantState(true);
              }}
            />
          )}
          {record?.tadaApplicationStatus?.displayName ===
            "Account Advance Approved" && (
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              style={{ borderRadius: 5 }}
              icon={<BankOutlined />}
              onClick={() => {
                showAddUpdateDrawer(true);
                dispatch(getSingleTadaDetails(record?.id));
                record?.tadaApplicationStatus?.displayName ===
                "Account Advance Approved"
                  ? setApplicantState(false)
                  : setApplicantState(true);
              }}
            >
              Settlement
            </Button>
          )}
        </>
      ),
    },
  ];
  const [tadaDetailsData, setTadaDetailsData] = useState([]);

  useEffect(() => {
    setTadaDetailsData(getTadaDetailsResponse?.tadaApplications);
  }, [getTadaDetailsResponse]);

  return (
    <>
      <Table
        className="next-organizer-table"
        columns={columns}
        dataSource={tadaDetailsData}
        loading={getTadaDetailsLoading}
        bordered
        scroll={{ x: 2000 }}
      >
        <Column title="Action" key="action" />
      </Table>
      <AddUpdateDrawerDetails
        drawerVisibility={addUpdateDrawerVisibility}
        onAddUpdateDrawerClose={onAddUpdateDrawerClose}
        isUpdateApplication={isUpdateApplication}
        getTadaSingleDetailsResponse={getTadaSingleDetailsResponse}
        applicantState={applicantState}
        setApplicantState={setApplicantState}
      />
    </>
  );
};

export default TadaDetailsTable;
