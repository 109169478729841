import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Select,
  Pagination,
  Form,
  Table,
  Space,
  Tooltip,
} from "antd";
import {
  SaveOutlined,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { LogoImage } from "utils/imageConstants";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import { getStatusEventListing } from "services/redux/todos/StatusListing/statuslisting.action";
import {
  complaintStatusUpdate,
  complainGetDetails,
  complaintPagination,
} from "services/redux/complaint/action";
import DivTable from "components/table";

const ComplainView = (props) => {

  const [complainDetailsId, setComplainDetailsID] = useState();
  const { setShowLeaveDrawer, pageSize, selectedModule, setViewRemarks,complaintData, SearchKeyword } =
    props;
  const { Option } = Select;
  const dispatch = useDispatch();
  // const [complaintData, setComplaintData] = useState([]);
  const [updateStatusValue, setUpdateStatusValue] = useState();
  const [updatePaginationId, setUpdatePaginationId] = useState();
  const [updateComplain, setUpdateComplain] = useState("");
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [totalCount, setTotalCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const tableRef = useRef(null);


  const columns = [
    {
      title: "Feedback By",
      dataIndex: "issueName",
      key: "issueName",
      // render: (record) => {
      //   return <span>{record.issueName}</span>;
      // },
    },

    {
      title: "Module",
      dataIndex: "title",
      key: "title",
      // render: (record) => {
      //   return <span>{record.title}</span>;
      // },
    },
    {
      title: "Progress",
      // title: () => {
      //   return (
      //     <Select
      //       placeholder="progress"
      //       bordered={false}
      //       onChange={(value) => {
      //         setSelectedStatusId(value);
      //         props.getData(pageSize, 0, selectedModule, value);
      //       }}
      //       value={selectedStatusId}
      //     >
      //       <Option value={""}>Progress</Option>
      //       {!isEmpty(allEventStatusListing) &&
      //         allEventStatusListing.map((status, index) => {
      //           return (
      //             <Option value={status.id} key={index}>
      //               {status.name}
      //             </Option>
      //           );
      //         })}
      //     </Select>
      //   );
      // },
      dataIndex: "statusName",
      key: "statusName",
      // render: (record) => {
      //   return <span>{record.statusName}</span>;
      // },
    },
    {
      title: "Creation Time",
      key: "creationTime",
      // dataIndex: "creationTime",
      render: (record) => {
        return moment(record.creationTime).format("YYYY-MM-DD : hh:mm A");
      },
    },
    {
      title: "Estimated Date",
      key: "estimatedDate",
      // dataIndex: "creationTime",
      // render: (record) => {
      //   return moment(record.estimatedDate).format("YYYY-MM-DD : hh:mm A");
      // },
      render: (record) => {
        return record.estimatedDate ? 
        moment(record.estimatedDate).format("YYYY-MM-DD")
        : "----";
      },
    },
    {
      title: "Completion Time",
      key: "endDate",
      // dataIndex: "creationTime",
      render: (record) => {
        return record.completionTime
          ? moment(record.completionTime).format("YYYY-MM-DD : hh:mm A")
          : "_ _ _"; 
      },
    }, 
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      // render: (record) => {
      //   return <div>{record.remarks}</div>;
      // },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <button
            className="view-complain-remark"
            onClick={() => {
              // setIsModalVisible(true);
              setViewRemarks(record.remarks);
              setShowLeaveDrawer(true);
              dispatch(complainGetDetails(record.id));
            }}
          >
            <EyeOutlined />
          </button>
          <Space size="middle">
            <a
              className="action-btn action-btn-add"
              onClick={() => {
                setShowLeaveDrawer(true);
                setViewRemarks(false);
                dispatch(complainGetDetails(record.id));
              }}
            >
              <EditOutlined /> Edit
            </a>
          </Space>
        </>
      ),
    },
  ];
const { complaintPaginationResponse, complaintPaginationResponseLoading } =
  useSelector((state) => state.complaint);

const {errorLogsDetailsResponse , errorLogsDetailsLoading} = useSelector((state)=>state.complaint);
const errorlogAdminData = errorLogsDetailsResponse?.emailErrorLogListDtos ;

  const columnsErrorLog = [
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width:"18%"
    },

    {
      title: "Date",
      key: "lastModificationTime",
      dataIndex: "lastModificationTime",
      render: (errorlogAdminData) => (
        <div>
          <span className="merge--contact">
            {moment(errorlogAdminData).format("DD/MM/YYYY ,h:mm:ss a")}
          </span>
        </div>
      ),
      width:"18%"
    },
    {
      title: "Message",
      key: "message",
      dataIndex: "message",
      width:"60%"
    }
  ];

  useEffect(() => {
    if (isEmpty(allEventStatusListing)) {
      dispatch(getStatusEventListing());
    }
  }, []);

  useEffect(() => {
    if (
      !isEmpty(complaintPaginationResponse?.items) &&
      !complaintPaginationResponseLoading
    ) {
      if (
        complaintPaginationResponse?.totalCount >
        complaintPaginationResponse?.items?.length
      ) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    }
  }, [complaintPaginationResponse]);

  const statusListingState = useSelector((state) => state.statusListing);
  const { allEventStatusListing } = statusListingState;


  // const getComplainData = (
  //   MaxResultCount = pageSize,
  //   SkipCount = 0,
  // ) => {
  //   let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;
  //   if (SearchKeyword) {
  //     appendUrl += `&SearchKeyword=${SearchKeyword}`;
  //   }
  //   dispatch(complaintPagination(appendUrl));
  // };

  // useEffect(() => {
  //   getComplainData();
  // },[SearchKeyword])

  useEffect(() => {
    if (!isEmpty(selectedModule)) {
      props.getData(pageSize, 0, selectedModule, selectedStatusId);
    }
  }, [selectedModule]);

  // useEffect(() => {
  //   var tableContent = document.querySelector(
  //     ".complain-table .ant-table-body"
  //   );
  //   tableContent.addEventListener("scroll", (event) => {
  //     let maxScroll = event.target.scrollHeight - event.target.clientHeight;
  //     let currentScroll = Math.round(event.target.scrollTop);

  //     // if (currentScroll == maxScroll) {
  //     //   let MaxResultCount = pageSize;
  //     //   let SkipCount = currentPage * pageSize;

  //     //   if (complaintPaginationResponse?.totalCount > SkipCount) {
  //     //     props.getData(
  //     //       MaxResultCount,
  //     //       SkipCount,
  //     //       selectedModule,
  //     //       selectedStatusId,
  //     //       true
  //     //     );
  //     //   }
  //     // }
  //   });
  // }, [complaintPaginationResponse, currentPage]);

  const handleActiveTable = (id) => {
    setComplainDetailsID(id);
  };
  
  return (
    <>
      {/* <Modal
        title="Remarks"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={false}
      >
        <p> {viewRemarks}</p>
      </Modal> */}
      <Table
        columns={selectedModule == "ErrorLog" ? columnsErrorLog : columns}
        sticky={true}
        rowKey={(record) => record.id}
        scroll={{ y: 770 }}
        className={`organizer__table contact-table complain-table`}
        dataSource={selectedModule == "ErrorLog" ? errorlogAdminData : complaintData}
        pagination={false}
        loading={{
          indicator: <img src={LogoImage} height="auto" width="50px" />,
          spinning: complaintPaginationResponseLoading,
        }}
      />

      {/* <DivTable
        columns={columns}
        rows={complaintData}
        height={800}
        scrollTarget="leave-table-scroll"
        totalLength={complaintData.length}
        loading={complaintPaginationResponseLoading}
        handleEvent={handleActiveTable}
        activeKey={complainDetailsId}
        className="complain-table"
      /> */}
    </>
  );
};

export default ComplainView;
