import queryString from "query-string";
import {
  getService,
  patchService,
  postService,
  putService,
} from "../../../commonServices";
import { appBaseUrl } from "utils/config";

export class TenderService {
  //tenderBasic
  tenderBasicPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/tenderBasicPagination?${query}`;
    let data = getService(url);
    return data;
  }
  dueDateTender(query) {
    let q = queryString.stringify(query);
    let url = `${appBaseUrl}/referenceManagement/tenderBasicPaginationDueDate?${q}`;
    let data = getService(url);
    return data;
  }
  //tender history
  tenderHistoryPagination() {
    let url = `${appBaseUrl}/referenceManagement/tenderBasicPaginationHistoryDate`;
    let data = getService(url);
    return data;
  }
  //tender upcoming
  tenderUpcomingPagination() {
    let url = `${appBaseUrl}/referenceManagement/tenderBasicPaginationUpcomingDate`;
    let data = getService(url);
    return data;
  }
  //tender section Counter
  tenderCounter() {
    let url = `${appBaseUrl}/referenceManagement/tenderBasicGetAllCounter`;
    let data = getService(url);
    return data;
  }

  tenderFourthCompingPagination() {
    const plus = "?MaxResultCount=100";
    let url = `${appBaseUrl}/referenceManagement/tenderBasicPaginationForthComingNear${plus}`;
    let data = getService(url);
    return data;
  }
  //tenderParticipationPagintion
  tenderParticipationPagination(q) {
    // let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/tenderParticipationPagination/${q}`;
    let data = getService(url);
    return data;
  }
  tenderBasicGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderBasicGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  tenderBasicAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderBasicAdd`;
    let data = postService(url, body);
    return data;
  }
  tenderBasicUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderBasicUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  // tender delete
  tenderDelete(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderBasicDelete/${id}`;
    let data = patchService(url);
    return data;
  }

  tenderStageListing() {
    let url = `${appBaseUrl}/referenceManagement/tenderStageListing`;
    let data = getService(url);
    return data;
  }
  tenderTypeListing() {
    let url = `${appBaseUrl}/referenceManagement/tenderTypePagination`;
    let data = getService(url);
    return data;
  }

  tenderSubTypeListing(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/tenderTypePagination?${query}`;
    let data = getService(url);
    return data;
  }

  //tenderDocument

  tenderDocumentGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderDocumentPurchaseGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  // tenderDocumentAdd(body) {
  // 	let url = `${appBaseUrl}/referenceManagement/tenderDocumentPurchaseAdd`;
  // 	let data = postService(url, body);
  // 	return data;
  // }
  tenderDocumentUpdate(body, id) {
    let url = `${appBaseUrl}/referenceManagement/updateTenderDocumentPurchaseDataList/${id}`;
    let data = postService(url, body);
    return data;
  }

  //TenderBasic Multiple document add
  documentFilesAddUpdate(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderBasicAdvanceDocumentFilesAddUpdate/${id}`;
    let data = postService(url, body);
    return data;
  }

  tenderParticipationGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderParticipationGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  // tenderDocumentAdd(body) {
  // 	let url = `${appBaseUrl}/referenceManagement/tenderDocumentPurchaseAdd`;
  // 	let data = postService(url, body);
  // 	return data;
  // }
  tenderParticipationUpdate(body, id) {
    let url = `${appBaseUrl}/referenceManagement/updateTenderParticipationList/${id}`;
    let data = postService(url, body, "POST");
    return data;
  }

  tenderFavAddUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderFavoriteAddUpdate`;
    let data = postService(url, body);
    return data;
  }
  tenderBasicAddExtension(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderExtensionAdd`;
    let data = postService(url, body);
    return data;
  }
  tenderBasicUpdateExtension(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderExtensionUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  tenderBasicExtensionGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderExtensionGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  stageDocumentPurchaseUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderStageLevelDocumentPurchaseUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  stageLevelAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderStageLevelAdd`;
    let data = postService(url, body);
    return data;
  }

  stageLevelUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderStageLevelUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }

  stageLevelDocumentPurchase(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderStageLevelDocumentPurchaseAdd`;
    let data = postService(url, body);
    return data;
  }
  tenderParticipantAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderParticipationAdd`;
    let data = postService(url, body);
    return data;
  }

  tenderBasicParticipantUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderParticipationUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  tenderParticipationDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderParticipationGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  tenderDocumentPagination(q) {
    // let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/tenderDocumentPurchasePagination/${q}`;
    let data = getService(url);
    return data;
  }

  tenderExtensionAddMutiple(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderExtensionAdd`;
    let data = postService(url, body);
    return data;
  }

  tenderStageExtensionDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderExtensionGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  tenderStageExtensionUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderExtensionUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  tenderAwardStatusAdd(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderStageLevelAwardStatusAdd`;
    let data = postService(url, body);
    return data;
  }
  tenderAwardStatusUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderStageLevelAwardStatusUpdate/${body.id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  tenderAwardStatusGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderStageLevelAwardStatusGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
  tenderAwardStatusDelete(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderExtensionDelete/${id}`;
    let data = postService(url, "", "PUT");
    return data;
  }
  dateFilteringTender(body) {
    let query = queryString.stringify(body);
    let plus = "?MaxResultCount=100";
    let url = `${appBaseUrl}/referenceManagement/tenderBasicPaginationForthComingNear${plus}&${query}`;
    let data = getService(url);
    return data;
  }
  getAllTenderDetails(tenderBasicId) {
    let url = `${appBaseUrl}/referenceManagement/allTendersGetFiles/${tenderBasicId}`;
    let data = getService(url);
    return data;
  }

  // GET:: tender checklist in circular planning tab
  getTenderCheckList(id) {
    let url = `${appBaseUrl}/referenceManagement/viewReferenceChecklistbyreference?referenceid=${id}`;
    let data = getService(url);
    return data;
  }

  getTenderCheckListById(id) {
    let url = `${appBaseUrl}/referenceManagement/${id}/viewTenderChecklist`;
    let data = getService(url);
    return data;
  }

  addTenderCheckList() {
    let url = `${appBaseUrl}/referenceManagement/TenderChecklist`;
    let data = postService(url);
    return data;
  }

  updateTenderCheckList() {
    let url = `${appBaseUrl}/referenceManagement/TenderChecklist`;
    let data = putService(url);
    return data;
  }

  // ViewTenderChecklistBycategory

  // CHECKLIST API
  addUpdateCircularCheckList(body) {
    let url = `${appBaseUrl}/referenceManagement/referencechecklist`;
    let data = postService(url, body);
    return data;
  }

  // CIRCULAR CONTENT
  // GET
  getReferenceTemplateGetDetailsById(id) {
    let url = `${appBaseUrl}/referenceManagement/referenceTemplateGetDetailsById/${id}`;
    let data = getService(url);
    return data;
  }

  // ADD/UPDATED
  addUpdateReferenceTemplateAddUpdate(body) {
    let url = `${appBaseUrl}/referenceManagement/referenceTemplateAddUpdate`;
    let data = postService(url, body);
    return data;
  }

  // TENDER APPROVED API
  approvedStatusReferenceTemplateMail(id, body) {
    let query = queryString.stringify(body);
    let url = `${appBaseUrl}/referenceManagement/circularPlanningApproveReject/${id}?${query}`;
    let data = postService(url);
    return data;
  }

  // GET APPROVES STATUS CIRCULAR PLANNING
  apiForGetApprovedStatusCircularPlanning(id) {
    let url = `${appBaseUrl}/referenceManagement/circularPlanningApproveStatusGetDetails/${id}`;
    let data = getService(url);
    return data;
  }
}
