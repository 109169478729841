import queryString from "query-string";
import { appBaseUrl } from "utils/config";
import { getService, postService, putService } from "services/commonServices";

export class GroupChatService {
    apiForGroupChatTitleAdd(body) {
        let url = `${appBaseUrl}/mailMessageCenter/messageGroupchatGroupAdd`;
        let data = postService(url, body);
        return data;
    }

    apiForGroupChatTitleUpdate(id, body) {
        let url = `${appBaseUrl}/mailMessageCenter/messageGroupchatGroupUpdate/${id}`;
        let data = putService(url, body);
        return data;  
    }

    apiForGroupChatTitlePagination(q) {
        let query = queryString.stringify(q);
        let url = `${appBaseUrl}/mailMessageCenter/messageGroupchatGroupPagination?${query}`;
        let data = getService(url);
        return data;
    }

    apiForGroupChatTitleDetails(q) {
        let query = queryString.stringify(q);
        let url = `${appBaseUrl}/mailMessageCenter/messageGroupChatGroupDetails/${q}`;
        let data = getService(url);
        return data;
    }

    // message
    apiForGroupMessagePagination(q) {
        let query = queryString.stringify(q);
        let url = `${appBaseUrl}/mailMessageCenter/messageGroupChatPagination?${query}`;
        let data = getService(url);
        return data;
    }

    apiForGroupMessageAdd(body) {
        let url = `${appBaseUrl}/mailMessageCenter/messageGroupChatAdd`;
        let data = postService(url, body);
        return data;
    }


    // Mail READ
    apiForGroupChatMailRead(id, q) {
        let query = queryString.stringify(q);
        let url = `${appBaseUrl}/mailMessageCenter/messageGroupChatAllStatus/${id}?${query}`;
        // console.log(url);
        let data = postService(url);
        return data;
    }

    // countGroupMail
    apiForCountGroupMail(q) {
        let query = queryString.stringify(q);
        let url = `${appBaseUrl}/mailMessageCenter/messageGroupchatGroupPagination?${query}`;
        let data = getService(url);
        return data;
    }
}