import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space, Layout, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, uniqBy } from 'lodash';

import { getAllUsers } from 'services/remanentCalls';
import { actionForGroupChatTitleAdd, actionForGetChatTitleDetails, actionForGroupChatTitleUpdate } from 'services/redux/groupChat/action';
import { getReferenceListing } from 'services/redux/Reference/action';

function GroupChatDrawer(props) {

    let {
      isDrawerVisible,
      handleDrawerClose,
      form,
      isGroupTitleUpdating,
      selectedGroupId,
    } = props;

    const { referenceListingResponse, referenceListingLoading } = useSelector(
      (state) => state.reference
    );

    const {
        groupChatTitlePaginationResp,
        groupChatTitlePaginationLoading,
        groupChatTitleDetailsResp,
        groupChatTitleDetailsRespLoading,
        groupChatTitleAddLoading,
        updateGroupTitleLoading,
    } = useSelector((state) => state.groupChatReducer);
      
    const dispatch = useDispatch();
    const { Option } = Select;
    const { Header, Footer } = Layout;
    const { TextArea } = Input;

    //
    const [searchValueReference, setSearchValueReference] = useState("");
    const [filterParticipantType, setFilterParticipantType] = useState("");
    const [filterReference, setFilterReference] = useState([]);
    const [referenceListing, setReferenceListing] = useState([]);

    // users
    const [user, setUsers] = useState([]);

    useEffect(() => {
        fetchAllUsers();
      }, []);

    useEffect(() => {
      if (filterParticipantType === "REF") {
        const timeoutId = setTimeout(() => {
          dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
        }, 200);
        return () => clearTimeout(timeoutId);
      }
    }, [searchValueReference]);
    
    useEffect(() => {
      if (searchValueReference) {
          if (referenceListingResponse) {
          setReferenceListing(referenceListingResponse);
          }
      }
    }, [referenceListingResponse, searchValueReference]);

    useEffect(() => {
      if (!isEmpty(referenceListing) && filterParticipantType == "REF") {
          setFilterReference(referenceListing.items);
      }
    }, [referenceListing]);

    useEffect(() => {
        if (isGroupTitleUpdating)
            dispatch(actionForGetChatTitleDetails(selectedGroupId));
    }, [isGroupTitleUpdating])


    useEffect(() => {
        if(!isEmpty(groupChatTitleDetailsResp)) {
            let recentlyUsedTags= [{
                code: groupChatTitleDetailsResp?.referenceCode,
                id: groupChatTitleDetailsResp?.referenceId,
            }]

            let otherReferenceDtos = groupChatTitleDetailsResp?.otherReferenceDtos.map((item) => {
                const { referenceCode, referenceId } = item;
                return {
                    code: referenceCode,
                    id: referenceId 
                }; 
            });

            let listOfOtherRefIds = otherReferenceDtos.map((item, index) => 
                item.id
            )

            // setFilterReference([...recentlyUsedTags, ...otherReferenceDtos]);
            const combineArray = [...recentlyUsedTags, ...otherReferenceDtos];
            const uniqArray = uniqBy(combineArray, 'id');
            setFilterReference(uniqArray);


            form.setFieldsValue({
                chatGroupName: groupChatTitleDetailsResp?.chatGroupName,
                description: groupChatTitleDetailsResp?.description,
                userListIds: groupChatTitleDetailsResp?.userListIds,
                adminListIds: groupChatTitleDetailsResp?.adminListIds,
                // referenceId: groupChatTitleDetailsResp?.referenceId,
                otherReferenceId: listOfOtherRefIds && listOfOtherRefIds, 
            });
        }
    }, [groupChatTitleDetailsResp, form])


    // func
    const fetchAllUsers = async () => {
        const resp = await getAllUsers().catch((err) => {
          message.error("Failure");
        });
    
        if (resp && resp.status === 200) {
          setUsers(resp.data);
        }
    };
    

    const handleSubmit = async(values) => {
      try {
        // console.log("values: ", values)
        if (isGroupTitleUpdating) {
            await dispatch(actionForGroupChatTitleUpdate(selectedGroupId, values));
        } else {
            await dispatch(actionForGroupChatTitleAdd(values));
        }

        handleDrawerClose();
      } catch (error) {
          console.log("error: ", error);
      }
    }

    return (
      <>
      <Drawer
        className="drawer complain-drawer main-drawer-wrapper"
        width={420}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
        styles={{
            body: {
                paddingBottom: 80,
            },
        }}
      >
        <Header className="drawer__header">
          <h4 className="drawer__header--title">{isGroupTitleUpdating ? "Update" : "Create a new Group"}</h4>
        </Header>

        <div className="drawer__content">
          <div className="drawer__content--wrapper">
              <div className="contact__content__wrapper">
              <Form
                  className="add__user__form drawer-form-wrapper"
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
              >
                    <div className="drawer-form-row">
                        <div className="drawer-form-group">
                            <Form.Item
                                className="form__group"
                                name="chatGroupName"
                                label="Title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter user name',
                                    },
                                ]}
                            >
                                <Input placeholder="Please enter user name" />
                            </Form.Item>
                        </div>
              
                        <div className="drawer-form-group">
                            <Form.Item
                                className="form__group"
                                name="description"
                                label="Description"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter description',
                                    },
                                ]}
                            >
                                {/* <Input
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please enter description"
                                /> */}

                                <TextArea
                                    rows={4}
                                    placeholder="Please enter description"
                                />
                            </Form.Item>
                        </div>

                        <div className="drawer-form-group">
                            <Form.Item
                                className="form__group"
                                name="userListIds"
                                rules={[{ required: false }]}
                                label="Users"
                            >
                                <Select
                                    showSearch={true}
                                    mode="multiple"
                                    allowClear
                                    filterOption={(input, option) =>
                                    option.children[0] &&
                                    option.children[0]
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {user.map((user) => (
                                        <Option key={user.appUserId}>
                                            {user.fullName} {`[${user.userName}]`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="drawer-form-group">
                            <Form.Item
                                className="form__group"
                                name="adminListIds"
                                rules={[{ required: false }]}
                                label="Admins"
                            >
                                <Select
                                    showSearch={true}
                                    mode="multiple"
                                    allowClear
                                    filterOption={(input, option) =>
                                    option.children[0] &&
                                    option.children[0]
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {user.map((user) => (
                                        <Option key={user.appUserId}>
                                            {user.fullName} {`[${user.userName}]`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        {/* <div className="drawer-form-group">
                            <Form.Item
                                className="form__group"
                                name="referenceId"
                                label="Reference ID"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select reference',
                                    },
                                ]}
                            >
                                <Select
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    showSearch
                                    placeholder="Select reference"
                                    optionFilterProp="label"
                                    allowClear={true}
                                    onSearch={(value) => {
                                        setSearchValueReference(value);
                                        setFilterParticipantType("REF");
                                    }}
                                    autoComplete="off"
                                >
                                {filterReference.map((reference, index) => {
                                    return (
                                        <Option
                                            value={reference.id}
                                            key={index}
                                            label={[reference.code, reference.title]}
                                        >
                                            <div className="demo-option-label-item">
                                                <div>
                                                    {reference.code} {""}
                                                </div>
                                                <div>{reference.title}</div>
                                            </div>
                                        </Option>
                                    );
                                })}
                                </Select>
                            </Form.Item>
                        </div> */}

                        <div className="drawer-form-group">
                            <Form.Item
                                className="form__group"
                                name="otherReferenceId"
                                label="Reference ID"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select reference',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    showSearch
                                    placeholder="Select reference"
                                    optionFilterProp="label"
                                    allowClear={true}
                                    onSearch={(value) => {
                                        setSearchValueReference(value);
                                        setFilterParticipantType("REF");
                                    }}
                                    autoComplete="off"
                                >
                                {filterReference.map((reference, index) => {
                                    return (
                                        <Option
                                            value={reference.id}
                                            key={index}
                                            label={[reference.code, reference.title]}
                                        >
                                            <div className="demo-option-label-item">
                                                <div>
                                                    {reference.code} {""}
                                                </div>
                                                <div>{reference.title}</div>
                                            </div>
                                        </Option>
                                    );
                                })}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="model-footer">
                            <Form.Item>
                                <Button
                                    className="company-add-save-btn"
                                    type="primary"
                                    htmlType="submit"
                                    loading={isGroupTitleUpdating ? updateGroupTitleLoading  : groupChatTitleAddLoading}
                                >
                                {isGroupTitleUpdating ? "Update" :  "Submit"}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
              </Form>
              </div>
          </div>
        </div>
      </Drawer>
      </>
    )
}

export default GroupChatDrawer;