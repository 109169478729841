import queryString from "query-string";
import { getService, postService, putService } from "../../../commonServices";
import { appBaseUrl } from "utils/config";

export class EmailReferenceService {
  // referenceOthersPagination(q) {
  // 	let query = queryString.stringify(q);
  // 	let url = `${appBaseUrl}/referenceManagement/referenceOthersPagination?${query}`;
  // 	let data = getService(url);
  // 	return data;
  // }

  mailReferenceAdd(body) {
    let url = `${appBaseUrl}/mailMessageCenter/MailMessageReferenceAdd`;
    let data = postService(url, body);
    return data;
  }

  mailReferenceGetDetails(id) {
    let url = `${appBaseUrl}/mailMessageCenter/mailMessageReferenceListing/${id}`;
    let data = getService(url);
    return data;
  }
}
