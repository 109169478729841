import { Col, Form, Input, InputNumber, Layout, Row } from "antd";
import React from "react";

const ApproverForm = ({ label, comment, amount }) => {
  return (
    <Layout.Content
      style={{
        backgroundColor: "#FAFAFA",
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        marginTop: 10,
      }}
    >
      <Form.Item label={label} name={comment}>
        <Input.TextArea
          readOnly
          autoSize={{ minRows: 1, maxRows: 6 }}
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item>
        <Row>
          <Col span={5}>
            <Form.Item name={amount}>
              <InputNumber readOnly style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </Layout.Content>
  );
};

export default ApproverForm;
