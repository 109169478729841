import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from 'antd';
import { validateMessages } from 'utils/misc';
import { primaryRefrenceAdd } from 'services/redux/adminSettings/primaryReference/action';
const { Header } = Layout;
export const PrimaryReferenceAddUpdateDrawer = ({  onCloseReferenceDrawer,drawerVisibility, isUpdateSector }) => {

const [form] = Form.useForm();
form.resetFields();
const dispatch = useDispatch();
const onSubmit = async () => {
    try{
        let values = await form.validateFields();
        dispatch(primaryRefrenceAdd(values));
    }catch(errorInfo) {
        console.error("failed", errorInfo); 
    }
};
	return (
        <Drawer visible={drawerVisibility} width={472} maskClosable={false} className="drawer"  onClose={onCloseReferenceDrawer} closable={false} >
        <Header className="drawer__header">
            <h4 className="drawer__header--title">{`${isUpdateSector ? 'Update' : 'Create'} Source Name`}</h4>
        </Header>
        <Form
        form={form} 
        layout="vertical"  
        name="control-hooks"
        validateMessages={validateMessages}
        className="drawer-form-wrapper">
            <div className="drawer-form-row">
                <Form.Item className="form__group" name="title" label="Primary Reference" rules={[{ required: true }]}>
                    <Input autoComplete='off' />
                </Form.Item>
            </div>
            <div className="drawer-form-row">
                <Form.Item className="form__group" name="description" label="Description" rules={[{ required: true }]}>
                    <Input autoComplete='off' />
                </Form.Item>
            </div>
            <div className="model-footer">
                <Button onClick={onCloseReferenceDrawer}>Cancel</Button>
                <Button onClick={onSubmit} type="primary">
                    Submit
                </Button>
            </div>
        </Form>
    </Drawer>
	);
};

