import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Button, InputNumber } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import ButtonForForm from "./buttons";
import JobInterviewForm from "./jobInterviewForm";
import JobInterviewTable from "./jobInterviewTable";
import JobInterviewListDetails from "./jobInterviewListDetails";
import {
  actionForGetJobInterviewPag,
  actionForGetJobInterviewById,
} from "services/redux/hrSystem/action";

function JobInterview({
  setOpenApplicantDrawer,
  setSelectedSingleApplicantData,
  formApplicantsBasic,
  formApplicantEdu,
  formApplicantJob,
  formApplicantReview,
  setActiveTabIndexofAppicant,
  statusUpdateButton,
  selectedSingleApplicantDAta,
  jobTitleId,
  selectedTemporarilyApplicantId,
}) {
  const dispatch = useDispatch();

  const { appplicationJobListingPag, appplicationJobListingPagLoading } =
    useSelector((state) => state.hrSystemServicesReducer);

  // const [openJobForm, setOpenJobForm] = useState(false);

  // Job form status
  const [jobForm, setJobForm] = useState({
    add: false,
    view: false,
    update: false,
    data: {},
  });

  useEffect(() => {
    dispatch(actionForGetJobInterviewPag());
    // dispatch(actionForGetJobInterviewById(selectedSingleApplicantDAta?.id));
  }, [dispatch, selectedSingleApplicantDAta?.id]);

  return (
    <>
      {/* {!openJobForm ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <PlusCircleOutlined
            onClick={() => setOpenJobForm(true)}
            style={{ color: "green" }}
          />
          {"  "}Add Job Interview
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <MinusCircleOutlined
            onClick={() => setOpenJobForm(!openJobForm)}
            style={{ color: "green" }}
          />
          {"  "}Show Table
        </div>
      )}

      {openJobForm && (
        <>
          <JobInterviewForm
            setOpenApplicantDrawer={setOpenApplicantDrawer}
            selectedSingleApplicantDAta={selectedSingleApplicantDAta}
            setSelectedSingleApplicantData={setSelectedSingleApplicantData}
            formApplicantsBasic={formApplicantsBasic}
            formApplicantEdu={formApplicantEdu}
            formApplicantJob={formApplicantJob}
            formApplicantReview={formApplicantReview}
            setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
            btnStatus={openJobForm}
          />
        </>
      )}

      {!openJobForm && (
        <>
          <JobInterviewTable
            setJobForm={setJobForm}
            tblData={
              appplicationJobListingPag && appplicationJobListingPag?.items
            }
          />
        </>
      )} */}

      {/* added */}
      {!jobForm?.add && !jobForm?.update && !jobForm?.view ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <PlusCircleOutlined
            onClick={() =>
              setJobForm({ ...jobForm, add: true, update: false, view: false })
            }
            style={{ color: "green" }}
          />
          {"  "}Add Job Interview
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <MinusCircleOutlined
            onClick={() =>
              setJobForm({
                ...jobForm,
                add: false,
                update: false,
                view: false,
              })
            }
            style={{ color: "green" }}
          />
          {"  "}Show Table
        </div>
      )}

      {jobForm?.add && (
        <>
          <h5>Add Form</h5>
          <JobInterviewForm
            setOpenApplicantDrawer={setOpenApplicantDrawer}
            selectedSingleApplicantDAta={selectedSingleApplicantDAta}
            setSelectedSingleApplicantData={setSelectedSingleApplicantData}
            formApplicantsBasic={formApplicantsBasic}
            formApplicantEdu={formApplicantEdu}
            formApplicantJob={formApplicantJob}
            formApplicantReview={formApplicantReview}
            setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
            jobFormData={jobForm}
            setJobForm={setJobForm}
          />
        </>
      )}

      {jobForm?.update && (
        <>
          <h5>Update Form</h5>
          <JobInterviewForm
            setOpenApplicantDrawer={setOpenApplicantDrawer}
            selectedSingleApplicantDAta={selectedSingleApplicantDAta}
            setSelectedSingleApplicantData={setSelectedSingleApplicantData}
            formApplicantsBasic={formApplicantsBasic}
            formApplicantEdu={formApplicantEdu}
            formApplicantJob={formApplicantJob}
            formApplicantReview={formApplicantReview}
            setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
            jobFormData={jobForm}
            setJobForm={setJobForm}
          />
        </>
      )}

      {jobForm?.view && (
        <>
          <h5>Listing Details</h5>
          <JobInterviewListDetails
            selectedData={jobForm?.data}
            setJobForm={setJobForm}
            jobFormData={jobForm}
          />
        </>
      )}

      {!jobForm?.add && !jobForm?.update && !jobForm?.view && (
        <>
          <JobInterviewTable
            setJobForm={setJobForm}
            tblData={
              appplicationJobListingPag && appplicationJobListingPag?.items
            }
            isLoading={appplicationJobListingPagLoading}
          />
        </>
      )}
    </>
  );
}

export default JobInterview;
