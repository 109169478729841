import React, { useState, useEffect } from "react";
import {
  Input,
  Col,
  Row,
  Button,
  InputNumber,
  DatePicker,
  Select,
  Collapse,
  Form,
  Skeleton,
  message,
} from "antd";
import { PlusOutlined, StarFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { AddUpdateOther } from "./AddUpdateOther";
import RenderData from "./RenderData";
import { isEmpty, pickBy } from "lodash";
import ContactEmail from "../TenderTabs/Includes/EmailChat.";

const edit = "./assets/svg/profile-edit.svg";

const { Option } = Select;

const Summary = ({
  isUpdate,
  setIsUpdate,
  showChat,
  setShowChat,
  referenceType,
  setReferenceType,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showEditForm, setEditForm] = useState(true);
  const [getDetails, setGetDetails] = useState();

  const {
    referenceOthersDetailsResponse,
    referenceOthersUpdateResponse,
    referenceOthersLoading,
  } = useSelector((state) => state.referenceOthers);

  // useEffect(() => {
  //   if (isUpdate === "details") {
  //     setEditForm(false);
  //   } else {
  //     setEditForm(true);
  //     if (isUpdate === "add") {
  //       setGetDetails("");
  //     } else {
  //       setGetDetails(referenceOthersDetailsResponse);
  //     }
  //   }
  // }, [isUpdate]);

  // useEffect(() => {
  // 	if (projectUpdateResponse) {
  // 		setEditForm(true);
  // 	}
  // }, [projectUpdateResponse]);

  useEffect(() => {
    if (isUpdate === "details") {
      setEditForm(false);
    } else if (isUpdate === "add") {
      setEditForm(true);
      setGetDetails("");
    } else {
      setEditForm(true);
      setReferenceType("");
    }
    console.log("thisget called");
    setGetDetails(referenceOthersDetailsResponse);
  }, [isUpdate]);

  // useEffect(() => {
  //   if (isEmpty(getDetails)) {
  //     form.resetFields();
  //     return;
  //   }
  //   form.setFieldsValue({
  //     ...getDetails,
  //   });
  // }, [getDetails, showEditForm]);

  const renderProject = () => {
    console.log("I am called here");
    return (
      <>
        <RenderData setEditForm={setEditForm} setIsUpdate={setIsUpdate} />
      </>
    );
  };

  const updateProject = () => {
    return (
      <div>
        <AddUpdateOther
          setEditForm={setEditForm}
          getDetails={getDetails}
          setIsUpdate={setIsUpdate}
          referenceType={referenceType}
          isUpdate={isUpdate}
        />
      </div>
    );
  };
  return (
    <div style={{ display: "flex" }}>
      {!showEditForm ? (
        <div style={{ width: "100%" }}>{renderProject()}</div>
      ) : (
        <div style={{ width: "100%" }}> {updateProject()}</div>
      )}
    </div>
  );
};

export default Summary;
