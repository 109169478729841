import React, { useEffect, useState } from "react";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";

// antd
import {
  Form,
  Input,
  Button,
  Checkbox,
  DatePicker,
  Select,
  TimePicker,
  Row,
  Col,
} from "antd";
import { useForm } from "antd/lib/form/Form";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  addAppointmentEvent,
  updateAppointmentEvent,
} from "../../../services/redux/organizer/appointments/action";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const tailLayout = {
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const AppointmentForm = (props) => {
  const dispatch = useDispatch();
  const appointmentEventsState = useSelector(
    (state) => state.appointmentEvents
  );

  const [appointmentEventCandidateDtos, setAppointmentEventCandidateDtos] =
    useState([]);
  const [disabledHours, setDisabledHours] = useState([]);
  const [disableMakeMePrimary, setDisableMakeMePrimary] = useState(false);

  const { addAppointmentEventResp } = appointmentEventsState;

  const [form] = useForm();
  const {
    selectedAppointmentEvent,
    organizerEventLabelListing,
    organizerEventPriorityListing,
    editAppointmentList,
    meetingInvitees,
    appointmentDrawer,
  } = props;

  useEffect(() => {
    if (!isEmpty(selectedAppointmentEvent)) {
      form.setFieldsValue({
        appointmentEventCandidateDtos:
          selectedAppointmentEvent.appointmentEventCandidateDtos.map(
            (candidate) => candidate.candidateId
          ),
        subject: selectedAppointmentEvent.subject,
        organizerEventLabelId:
          selectedAppointmentEvent.organizerEventLabelDto.id,
        organizerEventPriorityId:
          selectedAppointmentEvent.organizerEventPriorityDto.id,
        notes: selectedAppointmentEvent.notes,
        startTime: moment(selectedAppointmentEvent.startDateTime),
        startDate: moment(selectedAppointmentEvent.startDate),
        endDate: moment(selectedAppointmentEvent.endDate),
        endTime: moment(selectedAppointmentEvent.endDateTime),
        reminderDate: moment(selectedAppointmentEvent.reminderDate),
        reminderTime: moment(selectedAppointmentEvent.reminderDateTime),
        makeMePrimary: selectedAppointmentEvent.makeMePrimary,
        isReminder: selectedAppointmentEvent.isReminder,
        isActive: selectedAppointmentEvent.isActive,
      });
      setAppointmentEventCandidateDtos(
        selectedAppointmentEvent.appointmentEventCandidateDtos
      );
    }
  }, [selectedAppointmentEvent]);

  useEffect(() => {
    if (!isEmpty(addAppointmentEventResp)) {
      form.resetFields();
      if (!appointmentDrawer) {
        editAppointmentList({});
        setAppointmentEventCandidateDtos([]);
      }
    }
  }, [addAppointmentEventResp]);

  const onFinish = (values) => {
    let newValues = {
      ...values,
      startTime: moment(values.startTime).format("h:mm:ss a"),
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
      endTime: moment(values.endTime).format("h:mm:ss a"),
      reminderDate: moment(values.reminderDate).format("YYYY-MM-DD"),
      reminderTime: moment(values.reminderTime).format("h:mm:ss a"),
    };
    newValues.appointmentEventCandidateDtos = appointmentEventCandidateDtos;

    if (isEmpty(selectedAppointmentEvent)) {
      dispatch(addAppointmentEvent(newValues));
    } else {
      dispatch(updateAppointmentEvent(newValues, selectedAppointmentEvent.id));
    }
  };

  const changeEmployeeDto = (value, option) => {
    let newAppointmentEventCandidates = option.map((candidate) => {
      if (appointmentEventCandidateDtos.length > 0) {
        let newCandidate = {};
        appointmentEventCandidateDtos.map((oldCandidate) => {
          if (oldCandidate.candidateId === candidate.value) {
            newCandidate = { ...oldCandidate };
          }
        });
        if (!isEmpty(newCandidate)) {
          return { ...newCandidate };
        } else {
          return {
            candidateId: candidate.value,
            candidateName: candidate.title,
            isPrimary: false,
          };
        }
      } else {
        return {
          candidateId: candidate.value,
          candidateName: candidate.title,
          isPrimary: false,
        };
      }
    });
    setAppointmentEventCandidateDtos(newAppointmentEventCandidates);
    if (option.length === 0) {
      form.setFieldsValue({ makeMePrimary: true });
    }
  };

  useEffect(() => {
    if (!isEmpty(appointmentEventCandidateDtos)) {
      let primaryValueArray = appointmentEventCandidateDtos.map(
        (candidate) => candidate.isPrimary
      );
      if (primaryValueArray.includes(true)) {
        setDisableMakeMePrimary(false);
      } else {
        setDisableMakeMePrimary(true);
      }
    }
  }, [appointmentEventCandidateDtos]);

  const changeCandidatePrimary = (value, index) => {
    let newAppointmentEventCandidateDtos = cloneDeep(
      appointmentEventCandidateDtos
    );

    newAppointmentEventCandidateDtos[index].isPrimary = value;
    let primaryValueArray = newAppointmentEventCandidateDtos.map(
      (candidate) => candidate.isPrimary
    );

    if (
      primaryValueArray.includes(true) ||
      form.getFieldValue("makeMePrimary")
    ) {
      setAppointmentEventCandidateDtos(newAppointmentEventCandidateDtos);
    }
  };

  return (
    <div className="gray__form gray-margin">
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={[8, 0]}>
          <Col lg={24}>
            <Form.Item
              label="Subject"
              name="subject"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input placeholder="Please enter subject" />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Start Date"
              name="startDate"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) => {
                  return current && current < moment();
                }}
                format="YYYY/MM/DD"
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Start Time"
              name="startTime"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <TimePicker
                onChange={(value) => {
                  let startTimeHrs = value.hours() || "";
                  let hours = Array(startTimeHrs + 1)
                    .fill(0)
                    .map((num, i) => i);
                  setDisabledHours(hours);
                }}
                format="HH:mm"
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="End Date"
              name="endDate"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) => {
                  return (
                    current &&
                    (form.getFieldValue("startDate")
                      ? current < form.getFieldValue("startDate")
                      : current < moment())
                  );
                }}
                format="YYYY/MM/DD"
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="End Time"
              name="endTime"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <TimePicker
                disabledHours={() => disabledHours}
                format="HH:mm"
                // use12Hours={true}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Priority"
              name="organizerEventPriorityId"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Select placeholder="Select Priority">
                {!isEmpty(organizerEventPriorityListing) &&
                  organizerEventPriorityListing.map((status, index) => {
                    return (
                      <Option value={status.id} key={index}>
                        {status.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Goal Label"
              name="organizerEventLabelId"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Select placeholder="Select Goal">
                {!isEmpty(organizerEventLabelListing) &&
                  organizerEventLabelListing.map((status, index) => {
                    return (
                      <Option value={status.id} key={index}>
                        {status.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              label="Remarks"
              name="notes"
              className="form__group"
              rules={[
                {
                  required: false,
                  message: "Required!",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Reminder Date"
              name="reminderDate"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) => {
                  return (
                    current < moment()
                    // form.getFieldValue('endDate') ? current > form.getFieldValue('endDate') : current < moment()
                  );
                }}
                format="YYYY/MM/DD"
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="Reminder Time"
              name="reminderTime"
              className="form__group"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <TimePicker format="HH:mm" />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              name="isReminder"
              className="form__group"
              {...tailLayout}
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox>Meeting Invitees</Checkbox>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item
              name="appointmentEventCandidateDtos"
              className="form__group"
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Please select"
                onChange={changeEmployeeDto}
              >
                {!isEmpty(meetingInvitees) &&
                  meetingInvitees.map((employee, index) => {
                    return (
                      <Option
                        value={employee.appUserId}
                        title={employee.fullName}
                        key={index}
                      >
                        {employee.fullName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <div className="appointment-select-form form__group">
              {appointmentEventCandidateDtos?.map((candidate, index) => {
                return (
                  <div className="appointment-candidate" key={index}>
                    <span>{candidate.candidateName}</span>
                    <span>
                      <Checkbox
                        checked={candidate?.isPrimary}
                        onChange={() =>
                          changeCandidatePrimary(!candidate?.isPrimary, index)
                        }
                      >
                        Primary
                      </Checkbox>
                    </span>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col lg={12}>
            <Form.Item
              name="makeMePrimary"
              className="form__group"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox
                disabled={
                  appointmentEventCandidateDtos.length === 0 ||
                  disableMakeMePrimary
                }
              >
                Make me primary
              </Checkbox>
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              name="isActive"
              valuePropName="checked"
              className="form__group"
              initialValue={true}
            >
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AppointmentForm;
