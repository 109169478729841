import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from "antd";
import { validateMessages } from "utils/misc";
import ColorPicker from "rc-color-picker";
import { organizerEventPriorityAdd, organizerEventPriorityUpdate } from "services/redux/organizer/organizer.action";

const { Header, Footer } = Layout;

const CityAddUpdateDrawer = ({
  onAddUpdateCountryClose,
  drawerVisibility,
  isUpdateCountry,
}) => {
  const { organizerEventPriorityAddResp, organizerEventPriorityUpdateResp } = useSelector(
    (state) => state.organizer
  );

  const [hexColor, setHexColor] = useState();
  const [fontHexColor, setFontHexColor] = useState();

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdateCountry) {
      form.resetFields();
      setHexColor()
      setFontHexColor()
      return;
    };
    form.setFieldsValue({
      ...isUpdateCountry,
    });
    setHexColor(isUpdateCountry.colorHexValue)
    setFontHexColor(isUpdateCountry.colorFontHexValue)
  }, [isUpdateCountry]);

  useEffect(() => {
    onAddUpdateCountryClose();
  }, [organizerEventPriorityAddResp, organizerEventPriorityUpdateResp]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (isUpdateCountry) {
        dispatch(organizerEventPriorityUpdate(isUpdateCountry.id, values));
      } else {
        dispatch(organizerEventPriorityAdd(values));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <>
      <Drawer
        visible={drawerVisibility}
        width={551}
        maskClosable={false}
        className="drawer"
        onClose={onAddUpdateCountryClose}
        closable={false}
      >
        <Header className='drawer__header'>
          <h4 className='drawer__header--title'>
            {`${isUpdateCountry ? "Update" : "Create"} Organizer Priority Label`}
          </h4>
        </Header>
        <Form
          form={form}
          name='control-hooks'
          validateMessages={validateMessages}
          className='drawer-form-wrapper'
        >
          <div className="drawer-form-row">
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="name"
                label="Label Name"
                rules={[{ required: true }]}>
                <Input autoComplete="off" />
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                name="colorHexValue"
                label="Pick a Background color"
                className="form__group"
                rules={[{ required: true }]}>
                <div className="color__wrap">
                  <ColorPicker
                    animation="slide-up"
                    color={hexColor || "#efefef"}
                    onChange={e => {
                      setHexColor(e.color)
                      form.setFieldsValue({
                        colorHexValue: e.color
                      })
                    }}
                  />
                  <span className="colorTag">{hexColor}</span>
                </div>
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                name="colorFontHexValue"
                label="Pick a Font color"
                className="form__group"
                rules={[{ required: true }]}>
                <div className="color__wrap">
                  <ColorPicker
                    animation="slide-up"
                    color={fontHexColor || "#efefef"}
                    onChange={e => {
                      setFontHexColor(e.color)
                      form.setFieldsValue({
                        colorFontHexValue: e.color
                      })
                    }}
                  />
                  <span className="colorTag">{fontHexColor}</span>
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="model-footer">
            <Button onClick={onAddUpdateCountryClose}>
              Cancel
            </Button>
            <Button onClick={onSubmit} type="primary">
              Submit
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default CityAddUpdateDrawer;
