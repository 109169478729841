import { message } from "antd";
import { EmployeeEvaluationService } from "./api";
import { init, success, finish, error } from "services/common";
import { EMPLOYEE_EVALUATION_TYPES } from "../../../types";
import { isEmpty } from "lodash";

const employeeEvaluationService = new EmployeeEvaluationService();

export const addEmployeeEvaluationApplication = (body, actionType) => {
  return async (dispatch) => {
    dispatch(init(EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_ADD));

    if (actionType === "add") {
      const response = await employeeEvaluationService.addEvaluationForm(body);
      if (response.isSuccess) {
        dispatch(
          success(
            EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_ADD,
            response.data
          )
        );
        message.success("Employee Evaluation Added Successfully");
      }
    } else if (actionType === "delete") {
      const response = await employeeEvaluationService.addEvaluationForm(body);
      if (response.isSuccess) {
        dispatch(
          success(
            EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_ADD,
            response.data
          )
        );
        message.success("Deleted Successfully");
      }
    }
    dispatch(finish(EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_ADD));
  };
};

export const getEmployeeEvaluationApplication = (query) => {
  return async (dispatch) => {
    dispatch(init(EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_GET));
    const response = await employeeEvaluationService.getEvaluationPagination(
      query
    );
    if (response.isSuccess) {
      dispatch(
        success(
          EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_GET,
          response.data
        )
      );
      dispatch(finish(EMPLOYEE_EVALUATION_TYPES.EMPLOYEE_EVALUATION_GET));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
