import { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommas, getDaysDifference } from "utils/commonFunc";

import {
  Input,
  Button,
  Select,
  Collapse,
  Checkbox,
  Form,
  Row,
  Col,
  message,
  Radio,
  Popover,
  Spin,
  InputNumber,
  Typography,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  StarFilled,
  PlusCircleOutlined,
  MoreOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { cqBaseUrl } from "utils/config";

const BusinessTenderDetails = ({ getDetails }) => {
  const [uploadersExceptProfile, setUploadersExceptProfile] = useState([]);
  const [groupedByDocuments, setGroupedByDocuments] = useState([]);
  // console.log("getDetailsBasictenderDetails", getDetails);

  const { token } = useSelector(
    //for accessing to the token form redux store
    (state) => state.auth
  );

  const getDayDiffVadlitiy = (ValidityDate) => {
    // var date1 = new Date(ValidityDate);
    // var date2 = new Date(submissionDate);
    // var Difference_In_Time = date1.getTime() - date2.getTime();
    // var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    // return `${diff} `;
  };

  const getDayDiffSecurity = (startDate) => {
    // var date1 = new Date(startDate);
    // var date2 = new Date(startDate);
    // var presentDate = new Date();
    // if ((isUpdate = "details")) {
    //   // To calculate the time difference of two dates
    //   var Difference_In_Time = date1.getTime() - date2.getTime();
    //   // To calculate the no. of days between two dates
    //   var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    // } else if (isUpdate == "add") {
    //   var Difference_In_Time = presentDate.getTime() - date1.getTime();
    //   // To calculate the no. of days between two dates
    //   var diff = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    //   setShowParticipation(diff);
    // }
    // return `${diff}`;
  };

  const popOver = (value, header) => {
    const textContent = (
      <div>
        <h3 className="popOver--title">Please Enter {header}</h3>
        <div className="popOver--content">
          <Input defaultValue={value} />
          <div className="popUpbuttons">
            <Button
              type="primary"
              className="popUpbuttons--btn-success"
              icon={<CheckOutlined style={{ fontSize: 12 }} />}
            />
            <Button
              type="danger"
              className="popUpbuttons--btn-danger"
              icon={<CloseOutlined style={{ fontSize: 12 }} />}
            />
          </div>
        </div>
      </div>
    );

    return <div>{value}</div>;
  };
  const screenSize = window.innerHeight;

  return (
    <>
      <div
        className={`profiledetails ${
          screenSize < 600
            ? "business_type_emailView_smallScreen"
            : "business_type_emailView"
        }`}
      >
        {!isEmpty(getDetails) ? (
          <div className="">
            <div className="profiledetails__header" style={{ marginTop: 5 }}>
              <div className="profiledetails__header--text">
                {/* {console.log("getDetails", getDetails)} */}
                {getDetails.referenceCode}
              </div>
            </div>
            <div className="profiledetails__content--divider">
              {getDetails?.title ? (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">Title </span>
                  </div>
                  <div className="referenceDetails__labeldata">
                    <span className="referenceDetails--subtitle noWrap-content">
                      {popOver(getDetails?.title, "Title")}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {getDetails?.budget ? (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">Budget</span>
                  </div>

                  <div className="referenceDetails__labeldata">
                    <span className="referenceDetails--subtitle ">
                      {getDetails?.budgetCurrencySymbol} &nbsp;
                      {numberWithCommas(getDetails?.budget)}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {getDetails?.projectTitle ? (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">
                      Project(if Any){" "}
                    </span>
                  </div>
                  <div className="referenceDetails__labeldata">
                    <span className="referenceDetails--subtitle ">
                      {popOver(getDetails?.projectTitle, "Project")}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {getDetails?.clientName ? (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">Client</span>
                  </div>
                  <div className="referenceDetails__labeldata no">
                    <span className="referenceDetails--subtitle  noWrap-content">
                      {`${getDetails?.clientName} ${
                        getDetails.clientAbbrevation
                          ? `[${getDetails.clientAbbrevation}]`
                          : ""
                      }`}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {getDetails?.department ? (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">
                      Department{" "}
                    </span>
                  </div>
                  <div className="referenceDetails__labeldata">
                    <span className="referenceDetails--subtitle ">
                      {popOver(getDetails?.department, "Department")}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="profiledetails__content--divider">
              {getDetails?.tenderTypeName ? (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">Type </span>
                  </div>
                  <div className="referenceDetails__labeldata">
                    <span className="referenceDetails--subtitle ">
                      {getDetails?.tenderTypeName}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}

              {getDetails?.sectorName ? (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">Sector </span>
                  </div>
                  <div className="referenceDetails__labeldata">
                    <span className="referenceDetails--subtitle ">
                      {getDetails?.sectorName}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="profiledetails__content--divider">
              {getDetails?.primaryInchargeName ? (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">
                      Primary Incharge
                    </span>
                  </div>
                  <div className="referenceDetails__labeldata">
                    <span className="referenceDetails--subtitle ">
                      {`${getDetails?.primaryInchargeName} ${
                        getDetails?.primaryInchargeUserName
                          ? `[${getDetails?.primaryInchargeUserName}]`
                          : ""
                      }`}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}

              {!isEmpty(getDetails?.tenderBasicSecondaryInchargeDtos) ? (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">
                      Secondary Incharge
                    </span>
                  </div>
                  <div className="referenceDetails__labeldata">
                    {getDetails?.tenderBasicSecondaryInchargeDtos.map(
                      (data, i) => {
                        return (
                          <span
                            className="referenceDetails--subtitle bold"
                            key={i}
                          >
                            {`${data.secondaryInchargeName}${
                              data.secondaryInchargeUserName
                                ? `[${data.secondaryInchargeUserName}]`
                                : ""
                            }`}
                            {getDetails?.tenderBasicSecondaryInchargeDtos
                              .length >
                            i + 1
                              ? ", "
                              : ""}
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {getDetails?.scopeOfWork && (
                <div className="referenceDetails__info">
                  <div className="referenceDetails__label">
                    <span className="referenceDetails--title ">
                      Scope of Work
                    </span>
                  </div>

                  <div className="referenceDetails__labeldata">
                    <span className="referenceDetails--subtitle">
                      {getDetails?.scopeOfWork}
                    </span>
                  </div>
                </div>
              )}
              {getDetails.officialInvitation && (
                <>
                  <div className="referenceDetails__info">
                    <div className="referenceDetails__label">
                      <span className="referenceDetails--title ">
                        Tender Process
                      </span>
                    </div>
                    <div className="referenceDetails__labeldata">
                      <span className="referenceDetails--subtitle">
                        {getDetails.tenderProcessType === 1
                          ? "Single Stage"
                          : getDetails.tenderProcessType === 2
                          ? "Double Stage"
                          : ""}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="profiledetails__content--divider">
              {!isEmpty(getDetails.tenderStageLevelDtos) &&
                getDetails?.tenderStageLevelDtos?.map((data, i) => {
                  return (
                    <>
                      <div className="profiledetails__header" key={i}>
                        <div className="profiledetails__header--text">
                          {data.stageLevel === "stage1"
                            ? `${
                                getDetails.tenderStageLevelDtos?.length === 1
                                  ? " "
                                  : "Stage 1"
                              }`
                            : "Stage 2"}
                        </div>
                      </div>

                      {data?.inviatationDate !== null ? (
                        <div className="referenceDetails__info">
                          <div className="referenceDetails__label">
                            <span className="referenceDetails--title ">
                              {" "}
                              Invitation On{" "}
                            </span>
                          </div>
                          <div className="referenceDetails__labeldata">
                            <span className="referenceDetails--subtitle noWrap-content">
                              {moment(data.inviatationDate).format(
                                "YYYY/MM/DD"
                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {data?.inviatationRef !== null ? (
                        <div className="referenceDetails__info">
                          <div className="referenceDetails__label">
                            <span className="referenceDetails--title ">
                              {" "}
                              Invitation Ref{" "}
                            </span>
                          </div>
                          <div className="referenceDetails__labeldata">
                            <span className="referenceDetails--subtitle noWrap-content">
                              {data.inviatationRef}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {data?.description !== null ? (
                        <div className="referenceDetails__info">
                          <div className="referenceDetails__label">
                            <span className="referenceDetails--title ">
                              Description
                            </span>
                          </div>
                          <div className="referenceDetails__labeldata">
                            <span className="referenceDetails--subtitle noWrap-content">
                              {data.description}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {data?.documentCost !== null ? (
                        <div className="referenceDetails__info">
                          <div className="referenceDetails__label">
                            <span className="referenceDetails--title ">
                              Document Cost
                            </span>
                          </div>
                          <div className="referenceDetails__labeldata">
                            <span className="referenceDetails--subtitle noWrap-content">
                              {data.documentCostCurrencies} &nbsp;
                              {numberWithCommas(data.documentCost)}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {data?.submissionDate !== null ? (
                        <div className="referenceDetails__info">
                          <div className="referenceDetails__label">
                            <span className="referenceDetails--title ">
                              Submission On
                            </span>
                          </div>
                          <div className="referenceDetails__labeldata">
                            <span className="referenceDetails--subtitle noWrap-content">
                              {moment(data?.submissionDate).format(
                                "YYYY/MM/DD"
                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {data?.bidValidityDate !== null ? (
                        <div className="referenceDetails__info">
                          <div className="referenceDetails__label">
                            <span className="referenceDetails--title ">
                              Bid Validity Till
                            </span>
                          </div>
                          <div className="referenceDetails__labeldata">
                            <span className="referenceDetails--subtitle noWrap-content">
                              {moment(data.bidValidityDate).format(
                                "YYYY/MM/DD"
                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {data?.bidSecurityValue !== null ? (
                        <div className="referenceDetails__info">
                          <div className="referenceDetails__label">
                            <span className="referenceDetails--title ">
                              Bid Security Amount
                            </span>
                          </div>
                          <div className="referenceDetails__labeldata">
                            <span className="referenceDetails--subtitle noWrap-content">
                              {data.bidSecurityValueCurrencies}&nbsp;
                              {numberWithCommas(data.bidSecurityValue)}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {data?.bidSecurityValueDate !== null ? (
                        <div className="referenceDetails__info">
                          <div className="referenceDetails__label">
                            <span className="referenceDetails--title ">
                              Bid Security Validity Till
                            </span>
                          </div>
                          <div className="referenceDetails__labeldata">
                            <span className="referenceDetails--subtitle noWrap-content">
                              {moment(data.bidSecurityValueDate).format(
                                "YYYY/MM/DD"
                              )}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {!isEmpty(
                        data?.tenderStageDocumentPurchaseAddUpdateDtos
                      ) ? (
                        <div className="business_email_view">
                          <div className="businees_email_title_document">
                            Documents
                          </div>
                          {data?.tenderStageDocumentPurchaseAddUpdateDtos?.map(
                            (document, index) => (
                              <div
                                className="document-purchase-info-wrapper "
                                key={index}
                              >
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Company</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    <span style={{ marginRight: "auto" }}>
                                      {document.contactCompanyName}
                                    </span>
                                  </div>
                                </div>
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Purchase</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    <span>
                                      {moment(document.purchaseDate).format(
                                        "YYYY/MM/DD"
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Through</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    <span>{data?.through}</span>
                                  </div>
                                </div>
                                <div className="business-tender-project-item">
                                  <div className="business-tender-project-title">
                                    <span>Claim</span>
                                  </div>
                                  <div className="business-tender-project-desc">
                                    <span>
                                      {" "}
                                      {document.claim ? "Yes" : "No"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
            </div>
            {getDetails?.tenderAwardStatusDto && (
              <div className="award_status_email_view_tender">
                <div>
                  <div
                    className="award_status_title"
                    style={{
                      marginLeft: "8px",
                      fontWeight: 600,
                      fontSize: "13px",
                    }}
                  >
                    Award Status
                  </div>
                  {getDetails?.tenderAwardStatusDto && (
                    <div>
                      <div className="reference__view">
                        <div>
                          <div className="reference__header">
                            <span className="reference__header--text">
                              {getDetails?.tenderAwardStatusDto?.awardStatus}
                            </span>
                          </div>
                          <div className="reference__view--wrapper">
                            <div className="reference__info">
                              <div className="reference__label">
                                <span className="referenceDetails--title ">
                                  Company
                                </span>
                              </div>
                              <div className="reference__labeldata">
                                <span className="referenceDetails--subtitle ">
                                  {
                                    getDetails?.tenderAwardStatusDto
                                      ?.companyName
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="reference__info">
                              <div className="reference__label">
                                <span className="referenceDetails--title ">
                                  Contact Date
                                </span>
                              </div>
                              <div className="reference__labeldata">
                                <span className="referenceDetails--subtitle ">
                                  {moment(
                                    getDetails?.tenderAwardStatusDto
                                      ?.contactDate
                                  ).format("YYYY / MM / DD")}
                                </span>
                              </div>
                            </div>
                            <div className="reference__info">
                              <div className="reference__label">
                                <span className="referenceDetails--title ">
                                  Letter of Award Date
                                </span>
                              </div>
                              <div className="reference__labeldata">
                                <span className="referenceDetails--subtitle ">
                                  {moment(
                                    getDetails?.tenderAwardStatusDto
                                      ?.letterOfAwardDate
                                  ).format("YYYY / MM / DD")}
                                </span>
                              </div>
                            </div>
                            <div className="reference__info">
                              <div className="reference__label">
                                <span className="referenceDetails--title ">
                                  Letter of Intern Date
                                </span>
                              </div>
                              <div className="reference__labeldata">
                                <span className="referenceDetails--subtitle ">
                                  {moment(
                                    getDetails?.tenderAwardStatusDto
                                      ?.letterOfInternDate
                                  ).format("YYYY / MM / DD")}
                                </span>
                              </div>
                            </div>
                            <div className="reference__info">
                              <div className="reference__label">
                                <span className="referenceDetails--title ">
                                  Local Partner
                                </span>
                              </div>
                              <div className="reference__labeldata">
                                <span className="referenceDetails--subtitle ">
                                  {
                                    getDetails?.tenderAwardStatusDto
                                      ?.localPatner
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="reference__info">
                              <div className="reference__label">
                                <span className="referenceDetails--title ">
                                  Perform Security
                                </span>
                              </div>
                              <div className="reference__labeldata">
                                <span className="referenceDetails--subtitle ">
                                  {
                                    getDetails?.tenderAwardStatusDto
                                      ?.performanceSecurity
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>No data Selected</div>
        )}
      </div>
    </>
  );
};

export default BusinessTenderDetails;
