import { Col, Image, Row } from "antd";
import React from "react";
import mailApp from "../../../../assets/svg/mailinbox-12.svg";

const Notification = ({ allBellNotifications, readNotificationFunc }) => {
  return (
    <>
      <Row gutter={[20, 0]} className="dashboards__row dashboards__row--back">
        <Col lg={24} xs={24}>
          <div className="dashboards__body">
            <h5 className="dashboards__mailInfo--title">
              <Image src={mailApp} />
              My Notifications
            </h5>
            <ul className="dashboard-notification-lists">
              {allBellNotifications.map((notification, i) => {
                const description = JSON.parse(
                  notification.description || notification.Description
                );
                return (
                  <li key={i}>
                    <div className="notification-text">
                      {/* {description.Text} */}
                    </div>
                    {!notification.seenOn && (
                      <div className="mark-as-read">
                        <button
                          type="button"
                          className="btn mark-read marked"
                          title="Mark as read"
                          onClick={() =>
                            readNotificationFunc(notification.notificationId)
                          }
                        >
                          <span></span>
                        </button>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Notification;
