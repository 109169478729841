import { Row, Col, Button, Form, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { groupBy, isEmpty } from "lodash";
import { documentFilesAddUpdate } from "../../services/redux/profile/profile.action";
import { cqBaseUrl } from "../../utils/config";
import DynamicImageUploader from "../../components/shared/DynamicImageUploader";
// import { employeeProfilePersonalUpdate } from 'services/redux/profile/profile.action';
import AllFileViewer from "components/Documents/allFileViewer";

const _Documents = ({ dynamicFileTypesState }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const [pages, setPages] = useState(1);

  const [showInfo, setShowInfo] = useState(true);
  const [adDocs, setAdDocs] = useState([]);
  const { token } = useSelector((state) => state.auth);

  const [groupedByDocuments, setGroupedByDocuments] = useState([]);

  const {
    profileInfo,
    updateProfileLoading,
    updateProfile,
    documentUploadResp,
  } = useSelector((state) => state.profile);

  //   console.log("dynamicFileTypesState", dynamicFileTypesState);

  useEffect(() => {
    if (!isEmpty(documentUploadResp)) {
      setShowInfo(true);
    }
  }, [documentUploadResp]);

  const validateMessages = {
    required: "${label} is required!",
    whitespace: "${label} can not be empty!",
    types: {
      email: "${label} is not valid!",
    },
  };

  // const showInitialInfo = (profileInfo) => {
  //   return {
  //     ...profileInfo,
  //   };
  // };

  useEffect(() => {
    form.resetFields();
    if (!profileInfo) return;
    form.setFieldsValue({
      ...profileInfo,
    });

    let documentsUploadedFiles = [...profileInfo?.uploadedFileDtos];
    let groupByDocuments = groupBy(documentsUploadedFiles, "fileLableTypeName");

    setGroupedByDocuments(groupByDocuments);

    dynamicFileTypesState.map((file) => {
      let fileName = file.name;
      fileName &&
        groupedByDocuments.hasOwnProperty(fileName) &&
        form.setFieldsValue({
          [`${file.name}FileDtos`]: groupedByDocuments[`${fileName}`],
        });
    });
  }, [profileInfo, showInfo]);

  useEffect(() => {
    setAdDocs(profileInfo?.uploadedFileDtos);
  }, [profileInfo?.uploadedFileDtos]);

  const onFinish = (values) => {
    // if (profileInfo?.employeePersonalDetailUpdateDto.appUserId) {
    // 	let val = {
    // 		...profileInfo.employeePersonalDetailUpdateDto,
    // 		...values,
    // 	};
    // 	val = pickBy(val, (v) => !!v);
    // 	dispatch(employeeProfilePersonalUpdate(val));
    // } action.record
    let body = adDocs;
    dynamicFileTypesState.forEach((file) => {
      if (!isEmpty(values[`${file.name}FileDtos`])) {
        body = body.concat(values[`${file.name}FileDtos`]);
      }
    });
    dispatch(
      documentFilesAddUpdate(
        body,
        profileInfo.employeePersonalDetailUpdateDto.appUserId
      )
    );
    form.resetFields();
    setShowInfo(true);
  };

  const onDocumentError = (err) => {
    console.error("pdf viewer error:", err);
  };

  const renderProfileInfo = () => {
    if (!profileInfo?.employeePersonalDetailUpdateDto) {
      return <Skeleton paragraph={{ rows: 4 }} />;
    }
    return (
      <div className="profiledetails">
        <div className="profiledetails__wrapper">
          <div className="profiledetails__wrapper--conatainer">
            {dynamicFileTypesState.map((file) => {
              let fileName = file.name;

              return (
                <>
                  <div className="profiledetails__row">
                    <div className="profiledetails__row--label">
                      <b>{fileName}</b>
                      {console.log("TEST", fileName)}
                    </div>
                    <div className="profiledetails__row--data">
                      {fileName &&
                        groupedByDocuments.hasOwnProperty(fileName) &&
                        groupedByDocuments[`${fileName}`].map((doc) => {
                          return (
                            doc.isActive && (
                              <div>
                                {/* <PaperClipOutlined />{" "}
                                <a
                                  rel="noreferrer"
                                  target="_blank"
                                  download
                                  href={
                                    cqBaseUrl + "/" + doc.viewFileURL + token
                                  }
                                >
                                  {doc.displayFileName}
                                </a> */}
                                <AllFileViewer
                                  file={doc}
                                  cqBaseUrl={cqBaseUrl}
                                  token={token}
                                />
                              </div>
                            )
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
            {/* <span className="profile__bodyTitle">Document</span>
					      <span className="profile__bodyInfo">---</span> */}

            {/* <PDF file={cqBaseUrl + '/' + profileInfo?.uploadedFileDtos[0].viewFileURL + token}
						   // page={page}
						  onDocumentError={onDocumentError}
					      /> */}
            <div
              className="profile__details--footer"
              style={{ textAlign: "left" }}
            >
              <Button
                type="link"
                className="btn-update"
                onClick={() => setShowInfo(false)}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderProfileUpdate = () => {
    if (!profileInfo?.employeePersonalDetailUpdateDto) {
      return <Skeleton paragraph={{ rows: 10 }} />;
    }
    return (
      <div className="profiledetails">
        <div className="profiledetails__wrapper">
          <Form
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            validateMessages={validateMessages}
            // initialValues={showInitialInfo(
            //   profileInfo?.employeePersonalDetailUpdateDto
            // )}
          >
            <div className="profiledetails__wrapper--conatainer">
              {/* <span className="profile__bodyTitle">Document</span> */}
              {/* <Form.Item name="document">
						  <Input placeholder="Document" />
					    </Form.Item> */}
              {dynamicFileTypesState.map((u, i) => (
                <Row gutter={[12, 12]}>
                  <Col lg={24}>
                    <div className="profiledetails__rowedit">
                      <div className="profiledetails__rowedit--label">
                        <span className="profile__bodyTitle">{u.name}</span>
                      </div>
                      <div className="profiledetails__rowedit--data">
                        {/* <Form.List name={u.name}>
													{(fields, { add, remove }) => (
														<>
															{fields.map((field, fieldIndex) => (
																<React.Fragment key={field.key}>
																	<Row gutter={[8, 8]}>
																		<Form.Item name={[`${u.name}FileDtos`]} className="form__group" rules={[{ required: false }]}>
																			<DynamicImageUploader
																				name={u.name}
																				isMultipleFileAllowed={u.isMultipleFileAllowed}
																				id={u.id}
																				allowedExtensions={u.allowedExtensions}
																				// fileList={fileList}
																				// setFileList={setFileList}
																				index={i}
																				form={form}
																				callback={(e) => {
																					let olduploadedFileDtos = form.getFieldValue()[`${u.name}FileDtos`] ? [...form.getFieldValue()[`${u.name}FileDtos`]] : [];

																					let uploadedFileDtos = [...olduploadedFileDtos];
																					uploadedFileDtos.push(e);

																					form.setFieldsValue({
																						[`${u.name}FileDtos`]: uploadedFileDtos,
																					});

																					// form.setFieldsValue({
																					//   uploadedFileDtos: [
																					//     ...olduploadedFileDtos,
																					//     e
																					//   ]
																					//     })
																				}}
																			/>
																		</Form.Item>

																		<Col lg={1}>
																			<div style={{ display: 'flex' }}>
																				<MinusCircleOutlined style={{ width: 45, marginTop: 3 }} className="contact__btn-danger" onClick={() => remove(field.name)} />
																			</div>
																		</Col>
																	</Row>
			
																</React.Fragment>
															))}

															{fields.length === 0 && (
																<Row>
																	<Col lg={12}>
																		<Form.Item>
																			<Button
																				type="dashed"
																				className="contact__buttons"
																				onClick={() =>add()}
																				block
																				icon={<PlusCircleOutlined style={{ color: 'green' }} />}
																			>
																				Add {u.name}
																			</Button>
																		</Form.Item>
																	</Col>
																</Row>
															)}
														</>

														//for button of Add Address
													)}
												</Form.List> */}
                        <Form.Item
                          name={[`${u.name}FileDtos`]}
                          className="form__group"
                          rules={[{ required: false }]}
                        >
                          <DynamicImageUploader
                            name={u.name}
                            isMultipleFileAllowed={u.isMultipleFileAllowed}
                            id={u.id}
                            allowedExtensions={u.allowedExtensions}
                            // fileList={fileList}
                            // setFileList={setFileList}
                            index={i}
                            form={form}
                            callback={(e) => {
                              let olduploadedFileDtos = form.getFieldValue()[
                                `${u.name}FileDtos`
                              ]
                                ? [...form.getFieldValue()[`${u.name}FileDtos`]]
                                : [];

                              let uploadedFileDtos = [...olduploadedFileDtos];
                              uploadedFileDtos.push(e);

                              form.setFieldsValue({
                                [`${u.name}FileDtos`]: uploadedFileDtos,
                              });

                              // form.setFieldsValue({
                              //   uploadedFileDtos: [
                              //     ...olduploadedFileDtos,
                              //     e
                              //   ]
                              //     })
                            }}
                            setAdDocs={setAdDocs}
                            adDocs={adDocs}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
              <div
                className="profile__info--footer"
                style={{ textAlign: "left !important" }}
              >
                <Button
                  type="link"
                  className="btn-cancel"
                  onClick={() => setShowInfo(true)}
                >
                  Cancel
                </Button>
                <Button
                  type="link"
                  className="btn-update"
                  htmlType="submit"
                  loading={updateProfileLoading}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  return <div>{showInfo ? renderProfileInfo() : renderProfileUpdate()}</div>;
};

export default _Documents;
