import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Form,
  Input,
  Layout,
  message,
  Row,
  Select,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isArray, isEmpty } from "lodash";
import {
  corporateEmailAdd,
  corporateEmailPermissionAdd,
  emailShareGetDetails,
  sharedEmailParticipation,
} from "services/redux/admin/EmailShare/action";
import { getAllUsers } from "services/remanentCalls";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import moment from "moment";

export default function PermissionShareDrawer({
  type,
  setOpenDrawer,
  openDrawer,
  openNewDrawer,
  setOpenNewDrawer,
  permissionData,
  setPermissionData,
  newPermissionData,
  setNewPermissionData,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;

  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);
  const [startDate, setStartDate] = useState("");
  const {
    corporateEmailAddResponse,
    corporateEmailAddResponseLoading,
    emailShareGetDetailsResponse,
  } = useSelector((state) => state.emailShare);

  const { Header, Footer } = Layout;
  const [messageParticipants, setMessageParticipants] = useState([]);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setMessageParticipants(resp.data);
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
  };

  const onSubmit = (values) => {
    if (!isEmpty(newPermissionData)) {
      let list1 = values?.myList?.map((data) => {
        return {
          employeeId: data?.participantTos,
          isActive: true,
          maxLimitCount: data?.maxLimitCount,
          expiryDateTime: data?.expiryDateTime?._d,
        };
      });
      dispatch(sharedEmailParticipation(list1, type, newPermissionData.id));
    } else {
      let newId = values.participantTos;
      let corporateEmailId = newPermissionData.id;
      let body = {
        newId,
        corporateEmailId,
        isActive: true,
      };
      dispatch(sharedEmailParticipation(newId, body));
    }
    setOpenNewDrawer(false);
    setNewPermissionData("");
  };

  useEffect(() => {
    if (isEmpty(sisterCompanyListing)) {
      dispatch(getSisterCompaniesListings());
    }
    fetchAllParticipants();
  }, []);

  useEffect(() => {
    if (isEmpty(corporateEmailAddResponse)) {
      setOpenDrawer(false);
    }
  }, [corporateEmailAddResponse]);

  useEffect(() => {
    if (isEmpty(corporateEmailAddResponse)) {
      setOpenNewDrawer(false);
    }
  }, [corporateEmailAddResponse]);

  useEffect(() => {
    if (!isEmpty(newPermissionData)) {
      dispatch(emailShareGetDetails(newPermissionData?.id, type));
    }
  }, [permissionData, newPermissionData]);

  useEffect(() => {
    if (
      !isEmpty(newPermissionData) &&
      !isEmpty(emailShareGetDetailsResponse?.employeeEmailShareAccessDtos)
    ) {
      form.setFieldsValue({
        myList: emailShareGetDetailsResponse?.employeeEmailShareAccessDtos?.map(
          (item, index) => {
            return {
              participantTos: item?.employeeId,
              maxLimitCount: item?.maxLimitCount,
              expiryDateTime: moment(item?.expiryDateTime),
            };
          }
        ),
      });
    }
  }, [newPermissionData, emailShareGetDetailsResponse]);

  const onAddAddress = (add) => {
    add();
  };

  return (
    <div>
      <Drawer
        visible={openNewDrawer}
        width={472}
        maskClosable={false}
        closable={false}
        className="drawer main-drawer-wrapper form_email_share"
      >
        <Form
          onFinish={onSubmit}
          form={form}
          name="control-hooks"
          className="add__user__form "
        >
          {!isEmpty(newPermissionData) && (
            <div>
              <div>Give Permission Tos</div>

              <Form.List name="myList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <>
                        <Form.Item
                          className="form__group"
                          // name="participantTos"
                          name={[field.name, "participantTos"]}
                          fieldKey={[field.fieldKey, "participantTos"]}
                          rules={[{ required: true, message: "" }]}
                          style={{ width: "100%" }}
                        >
                          <Select
                            optionFilterProp="label"
                            bordered={true}
                            autoFocus={true}
                            placeholder={`Internal To`}
                          >
                            {messageParticipants.map((participant, j) => (
                              <Option
                                key={j}
                                value={participant?.employeeId}
                                label={[
                                  participant?.fullName,
                                  participant?.userName,
                                ]}
                              >
                                <div className="demo-option-label-item">
                                  <span role="img">
                                    {participant?.fullName} {"" + ""}
                                  </span>
                                  [{participant?.userName.toUpperCase()}]
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            label={`Enter limit count: ${index + 1}`}
                            {...field}
                            name={[field.name, "maxLimitCount"]}
                            fieldKey={[field.fieldKey, "maxLimitCount"]}
                            rules={[
                              {
                                required: true,
                                message: "Input field is required",
                              },
                            ]}
                          >
                            <Input type="number" min={0} />
                          </Form.Item>
                        </div>
                        <div style={{ display: "flex" }}>
                          <Form.Item
                            label={`Enter expiry limit: ${index + 1}`}
                            {...field}
                            name={[field.name, "expiryDateTime"]}
                            fieldKey={[field.fieldKey, "expiryDateTime"]}
                            rules={[
                              {
                                required: true,
                                message: "Input field is required",
                              },
                            ]}
                          >
                            <DatePicker
                              placeholder=""
                              disabledDate={(current) => {
                                return (
                                  current &&
                                  current <
                                    moment().add(1, "days").startOf("day")
                                );
                              }}
                              style={{ width: 178 }}
                              onChange={(value) => {
                                setStartDate(
                                  value
                                    ? moment(value?._d).format("DD-MMM-YYYY")
                                    : ""
                                );
                              }}
                            />
                          </Form.Item>
                          <div className="remove-field-wrapper">
                            <MinusCircleOutlined
                              style={{
                                width: 45,
                                marginTop: 3,
                              }}
                              className="contact__btn-danger"
                              onClick={() => remove(field.name)}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                    <Form.Item>
                      <Button
                        style={{
                          width: 45,
                          marginRight: 5,
                        }}
                        type="dashed"
                        className="contact__buttons"
                        onClick={() => onAddAddress(add)}
                      >
                        <PlusCircleOutlined
                          style={{
                            color: "green",
                          }}
                        />
                        Add User
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          )}

          <div className="model-footer">
            <Button
              onClick={() => {
                setOpenNewDrawer(false);
                setNewPermissionData("");
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            {isEmpty(newPermissionData) ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={corporateEmailAddResponseLoading}
              >
                Submit
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </div>
        </Form>
      </Drawer>
    </div>
  );
}
