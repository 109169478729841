import React, { useEffect } from "react";
import moment from "moment";
import { isEmpty } from "lodash";

// antd
import {
  Button,
  Drawer,
  Layout,
  Input,
  Space,
  Form,
  DatePicker,
  Checkbox,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

// redux
import { useDispatch } from "react-redux";
import { addBulkHolidayEvents } from "../../../services/redux/organizer/organizer.action";

const { Header } = Layout;

export const BulkFormDrawer = ({
  name,
  visible,
  onClose,
  selectedEditHolidayEvent,
  eventFormType,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    let status = !isEmpty(selectedEditHolidayEvent) ? "Edited" : "Addded";
    dispatch(addBulkHolidayEvents([...values.holidayEvents], status));
  };

  useEffect(() => {
    if (!isEmpty(selectedEditHolidayEvent)) {
      let holidayEvents = selectedEditHolidayEvent.map((holiday) => ({
        id: holiday.id || holiday.key,
        title: holiday.title,
        date: moment(holiday.date),
        remarks: holiday.remarks,
        isActive: holiday.isActive,
      }));

      form.setFieldsValue({
        holidayEvents,
      });
    } else {
      form.setFieldsValue({
        holidayEvents: [
          {
            title: "",
            date: "",
            remarks: "",
            isActive: true,
          },
        ],
      });
    }
  }, [selectedEditHolidayEvent]);

  return (
    <Drawer
      visible={visible}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onClose}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">{name}</h4>
      </Header>

      <div className="drawer__content">
        <div className="drawer__content--wrapper">
          <div className="contact__content__wrapper">
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Form.List name="holidayEvents">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => {
                      return (
                        <Space
                          key={field.key}
                          style={{ display: "block", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...field}
                            label="Holiday Title"
                            className="form__group"
                            name={[field.name, "title"]}
                            fieldKey={[field.fieldKey, "title"]}
                            rules={[
                              { required: true, message: "Missing title" },
                            ]}
                          >
                            <Input placeholder="Holiday Title" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label="Day"
                            className="form__group"
                            name={[field.name, "date"]}
                            fieldKey={[field.fieldKey, "date"]}
                            rules={[
                              { required: true, message: "Missing Date" },
                            ]}
                          >
                            <DatePicker format="YYYY/MM/DD" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label="Remarks"
                            className="form__group"
                            name={[field.name, "remarks"]}
                            fieldKey={[field.fieldKey, "remarks"]}
                            rules={[
                              { required: false, message: "Missing Remarks" },
                            ]}
                          >
                            <Input.TextArea placeholder="remarks" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            label="Remarks"
                            className="form__group"
                            name={[field.name, "isActive"]}
                            fieldKey={[field.fieldKey, "isActive"]}
                            valuePropName="checked"
                          >
                            <Checkbox>Active</Checkbox>
                          </Form.Item>
                          {eventFormType === "bulk" && (
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          )}
                        </Space>
                      );
                    })}
                    {eventFormType === "bulk" && (
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      {/* <Row gutter={[16, 16]} className='contact__bottom__items'>
        <Col span={12}>
          <div className='contact__bottom__buttons' style={{ float: "left" }}>
            <Button>Cancel</Button>
          </div>
        </Col>

        <Col span={12}>
          <div className='contact__bottom__buttons'>
            <Button className='contact__bottom__buttons__style'>
              Save Changes
            </Button>
          </div>
        </Col>
      </Row> */}
    </Drawer>
  );
};
