import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Form } from "antd";
import { LogoImage } from "utils/imageConstants";
import { countryPagination } from "services/redux/contact/contact.action";

const AddUpdateCountryTable = ({
  showAddUpdateCountryDrawer,
  showUpdateProfileDrawer,
}) => {
  const [form] = Form.useForm();
  form.resetFields();
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a
          className="action-btn action-btn-add"
          onClick={(e) => showAddUpdateCountryDrawer(e, record)}
        >
          Edit Country
        </a>
      ),
    },
  ];

  const dispatch = useDispatch();
  const { countryPaginationResponse, countryPaginationLoading } = useSelector(
    (state) => state.contact
  );
  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!countryPaginationResponse) return;
    setdata(countryPaginationResponse.items);
    setTotalCount(countryPaginationResponse.totalCount);
  }, [countryPaginationResponse]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SortType
  ) => {
    dispatch(
      countryPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
      })
    );
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };

  return (
    <Table
      className="contact-table"
      columns={columns}
      rowKey="id"
      dataSource={data}
      onChange={handleTableChange}
      // loading={countryPaginationLoading}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" alt="loader" />
        ),
        spinning: countryPaginationLoading,
      }}
      pagination={{
        total: totalCount,
        pageSize: pageSize,
      }}
    />
  );
};

export default AddUpdateCountryTable;
