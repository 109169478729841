import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import AddUserDrawer from "./AddUserDrawer";
import CurrencyAddUpdateTable from "./CurrencyTable";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import CurrencyAddUpdateDrawer from "./CurrencyDrawer";
import { ProjectService } from "../../../../services/redux/Reference/Project/api";

const ProjectSetting = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onReset = () => {
    form.resetFields();
  };

  const [
    addUpdateCurrencyDrawerVisibility,
    setaddUpdateCurrencyDrawerVisibility,
  ] = useState(false);
  const [isUpdateCurrency, setIsUpdateCurrency] = useState(false);

  const showAddUpdateCurrencyDrawer = (e, record) => {
    setaddUpdateCurrencyDrawerVisibility(false);
    if (record) {
      setIsUpdateCurrency(record);
    } else {
      setIsUpdateCurrency(false);
    }
    setaddUpdateCurrencyDrawerVisibility(true);
  };

  const onAddUpdateCurrencyClose = () => {
    setIsUpdateCurrency("");
    setaddUpdateCurrencyDrawerVisibility(false);
  };

  return (
    <div className="layout">
      <div className="organizer events">
        <div className="all-header-wrapper">
          <div className="meeting__flex">
            <h3 className="header__all">Currency List</h3>
            <div className="organizerHoliday__middle--wrap">
              <Button
                className="btn-primary"
                onClick={() => {
                  showAddUpdateCurrencyDrawer();
                }}
              >
                <PlusOutlined /> New Currency
              </Button>
            </div>
          </div>
        </div>
        <CurrencyAddUpdateTable
          showAddUpdateCurrencyDrawer={showAddUpdateCurrencyDrawer}
        />
        <CurrencyAddUpdateDrawer
          drawerVisibility={addUpdateCurrencyDrawerVisibility}
          onAddUpdateCurrencyClose={onAddUpdateCurrencyClose}
          isUpdateCurrency={isUpdateCurrency}
        />
      </div>
    </div>
  );
};

export default ProjectSetting;
