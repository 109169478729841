import React from "react";
import { Modal, Form, Input, DatePicker } from "antd";

const LogModal = ({ visible, form, onOk, onCancel }) => {
  return (
    <Modal
      title="Advanced Log Search"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText="Submit"
      cancelText="Cancel"
    >
      <Form form={form} name="log_form" labelAlign="left">
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Please enter a title!" }]}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
          name="date"
          label="Date"
          rules={[{ required: true, message: "Please select a date!" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default LogModal;
