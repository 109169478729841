import { useDispatch } from "react-redux";
import { Form, Row, Col, Input, Button } from "antd";
import { actionForCreateUpdateApplicants } from "services/redux/hrSystem/action";
import ButtonForForm from "./buttons";
import { isEmpty } from "lodash";

export default function BasicDetails({
  setOpenApplicantDrawer,
  jobTitleId,
  setSelectedSingleApplicantData,
  selectedSingleApplicantDAta,
  setActiveTabIndexofAppicant,
  formApplicantsBasic,
  formApplicantEdu,
  formApplicantJob,
  formApplicantReview,
}) {
  const dispatch = useDispatch();

  const onFinishBasicDetails = (values) => {
    let apiData = {
      ...values,
      employeeRequisitionId: jobTitleId,
    };

    if (!isEmpty(selectedSingleApplicantDAta)) {
      apiData = {
        ...apiData,
        employeeRequisitionId:
          selectedSingleApplicantDAta?.employeeRequisitionId,
        id: selectedSingleApplicantDAta?.id,
        applicationStatusId: selectedSingleApplicantDAta?.applicationStatusId,
      };
      dispatch(actionForCreateUpdateApplicants(apiData, "update"));
    } else {
      dispatch(actionForCreateUpdateApplicants(apiData, "add"));
    }
  };

  return (
    <>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinishBasicDetails}
        form={formApplicantsBasic}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[{ required: true, message: "Full name is required" }]}
            >
              <Input placeholder="Enter Full Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Current Address"
              name="currentAddress"
              rules={[
                { required: true, message: "Current address is required" },
              ]}
            >
              <Input placeholder="Enter Current Address" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Permanent Address"
              name="permanentAddress"
              rules={[
                { required: true, message: "Permanent address is required" },
              ]}
            >
              <Input placeholder="Enter Permanent Address" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Email Address"
              name="emailAddress"
              rules={[{ required: true, message: "Email address is required" }]}
            >
              <Input placeholder="Enter Email Address" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Mobile Number"
              name="mobileNumber"
              rules={[{ required: true, message: "Mobile number is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Last Company Name"
              name="lastCompanyName"
              rules={[
                { required: true, message: "Last company name is required" },
              ]}
            >
              <Input placeholder="Last Company Name" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Education"
              name="highestLevelEducation"
              rules={[{ required: true, message: "Education is required" }]}
            >
              <Input placeholder="Highest Level of Education" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Citizenship Number"
              name="citizenShipNumber"
              rules={[
                { required: true, message: "Citizenship number is required" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Skills"
              name="skills"
              rules={[{ required: true, message: "Skills are required" }]}
            >
              <Input.TextArea autoSize={{ minRows: 6 }} />
            </Form.Item>
          </Col>
        </Row>

        <ButtonForForm
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          formApplicantsBasic={formApplicantsBasic}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
          formApplicantEdu={formApplicantEdu}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
        />
      </Form>
    </>
  );
}
