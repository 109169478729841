import { Form, Row, Col, Input, Button, DatePicker, Checkbox } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import ButtonForForm from "./buttons";
import { useDispatch } from "react-redux";
import { actionForCreateUpdateApplicantsEducation } from "services/redux/hrSystem/action";

const applicantIdSessionStorage = localStorage.getItem("applicantId");

export default function EducationDetails({
  setOpenApplicantDrawer,
  setSelectedSingleApplicantData,
  selectedSingleApplicantDAta,
  formApplicantsBasic,
  formApplicantEdu,
  formApplicantJob,
  formApplicantReview,
  setActiveTabIndexofAppicant,
  applicantSingleDataID,
  setIsGraduated,
  selectedIsGraduated,
  jobTitleId,
  selectedTemporarilyApplicantId,
}) {
  const dispatch = useDispatch();

  const onFinishEducationDetails = (values) => {
    // console.log("valuesEdu", values);
    let applicantId = selectedSingleApplicantDAta
      ? selectedSingleApplicantDAta?.id
      : selectedTemporarilyApplicantId;
    let apiData = values?.education?.map((x) => {
      return {
        ...x,
        applicantId: applicantId,
      };
    });
    // console.log("apiData", apiData);

    dispatch(
      actionForCreateUpdateApplicantsEducation(
        apiData,
        applicantId,
        jobTitleId,
        "add"
      )
    );
  };

  return (
    <>
      <Form
        form={formApplicantEdu}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinishEducationDetails}
      >
        <Form.List name="education">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} gutter={[10, 0]}>
                  <Col span={24}>
                    {index >= 0 && (
                      <Button
                        style={{
                          float: "right",
                          // marginTop: "-12px"
                        }}
                        icon={<MinusCircleOutlined style={{ color: "red" }} />}
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </Col>

                  <Col span={1}>{index + 1}</Col>

                  <Col span={11}>
                    <Form.Item
                      key={field.key}
                      label="Institution"
                      name={[field.name, "institution"]}
                      fieldKey={[field.fieldKey, "institution"]}
                      rules={[
                        { required: true, message: "Institution is required" },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <Input placeholder="Enter institution name" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Education Level"
                      name={[field.name, `educationLevel`]}
                      fieldKey={[field.fieldKey, "educationLevel"]}
                      rules={[
                        {
                          required: true,
                          message: "Education level is required",
                        },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <Input placeholder="Enter Education Level" />
                    </Form.Item>
                  </Col>

                  <Col offset={1} span={11}>
                    <Form.Item
                      label="Area of Study"
                      name={[field.name, `areaOfStudy`]}
                      fieldKey={[field.fieldKey, "areaOfStudy"]}
                      rules={[
                        {
                          required: true,
                          message: "Area of study is required",
                        },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <Input placeholder="Enter area of study" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      key={field.key}
                      label="Graduated"
                      name={[field.name, "isGraduated"]}
                      fieldKey={[field.fieldKey, "isGraduated"]}
                      valuePropName="checked"
                      // rules={[
                      //   { required: true, message: "Graduated is required" },
                      // ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <Checkbox defaultChecked={true} />
                    </Form.Item>
                  </Col>

                  <Col offset={1} span={11}>
                    <Form.Item
                      label="Joined Date"
                      name={[field.name, `joinedDate`]}
                      fieldKey={[field.fieldKey, "joinedDate"]}
                      rules={[
                        { required: true, message: "Joined date is required" },
                      ]}
                      labelAlign="left"
                      align="middle"
                      vertical="top"
                    >
                      <DatePicker placeholder="Select date" />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Button
                style={{ marginBottom: "16px" }}
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusCircleOutlined />}
              >
                Add Education
              </Button>
            </>
          )}
        </Form.List>

        <ButtonForForm
          setOpenApplicantDrawer={setOpenApplicantDrawer}
          formApplicantsBasic={formApplicantsBasic}
          formApplicantEdu={formApplicantEdu}
          formApplicantJob={formApplicantJob}
          formApplicantReview={formApplicantReview}
          setSelectedSingleApplicantData={setSelectedSingleApplicantData}
          setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
        />
      </Form>
    </>
  );
}
