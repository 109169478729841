import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from 'antd';
import { validateMessages } from 'utils/misc';
import { stageAdd, stageUpdate } from 'services/redux/Reference/action';

const { Option } = Select;

const { Header, Footer } = Layout;

const StageAddUpdateDrawer = ({ onAddUpdateStageClose, drawerVisibility, isUpdateStage }) => {
	const { stageAddResponse, stageUpdateResponse } = useSelector((state) => state.reference);

	const [form] = Form.useForm();

	form.resetFields();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isUpdateStage) return;
		form.setFieldsValue({
			...isUpdateStage,
		});
	}, [isUpdateStage]);

	useEffect(() => {
		onAddUpdateStageClose();
	}, [stageAddResponse, stageUpdateResponse]);

	const onSubmit = async () => {
		try {
			let values = await form.validateFields();
			if (isUpdateStage) {
				dispatch(stageUpdate(isUpdateStage.id, values));
			} else {
				dispatch(stageAdd(values));
			}
		} catch (errorInfo) {
			console.error('Failed:', errorInfo);
		}
	};

	return (
		<Drawer visible={drawerVisibility} width={472} maskClosable={false} className="drawer" onClose={onAddUpdateStageClose} closable={false}>
			<Header className="drawer__header">
				<h4 className="drawer__header--title">{`${isUpdateStage ? 'Update' : 'Create'} Stage Name`}</h4>
			</Header>

			<Form layout="vertical" form={form} name="control-hooks" validateMessages={validateMessages} className="drawer-form-wrapper">
				<div className="drawer-form-row">
					<div className="drawer-form-group">
						<Form.Item className="form__group" name="stageName" label="Stage Name" rules={[{ required: true }]}>
							<Input autoComplete='off' />
						</Form.Item>
					</div>
				</div>
				<div className="model-footer">
					<Button onClick={onAddUpdateStageClose}>Cancel</Button>
					<Button onClick={onSubmit} type="primary">
						Submit
					</Button>
				</div>
			</Form>
		</Drawer>
	);
};

export default StageAddUpdateDrawer;
