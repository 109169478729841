import React, { useEffect } from "react";
import { isEmpty } from "lodash-es";
import moment from "moment";

// antd
import { Drawer, Form, Button, Select, Input, Layout } from "antd";

// redux
import { useDispatch, useSelector } from "react-redux";
import { fiscalYearAddUpdate } from "services/redux/admin/FiscalYear/action";

// utils
import { validateMessages } from "utils/misc";
import { leaveApplicationFiscalDayAdd, leaveApplicationFiscalDayUpdate } from "../../../../services/redux/admin/LeaveApplication/LeaveFiscalDay/action";

const { Header, Footer } = Layout;
const { Option } = Select;

const LeaveFiscalDayAddUpdateDrawer = ({
  onAddUpdateLeaveFiscalDayClose,
  drawerVisibility,
  isUpdateLeaveFiscalDay,
  fiscalYearListingWithStatus
}) => {
  const { addLeaveFiscalDayResponse, updateLeaveFiscalDayResponse } = useSelector(
    (state) => state.leaveFiscalDaysState
  );

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(isUpdateLeaveFiscalDay)) {
      form.resetFields();
      return;
    }

    form.setFieldsValue({
      ...isUpdateLeaveFiscalDay,
    });
  }, [isUpdateLeaveFiscalDay]);

  useEffect(() => {
    onAddUpdateLeaveFiscalDayClose();
  }, [addLeaveFiscalDayResponse, updateLeaveFiscalDayResponse]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();

      if (!isEmpty(isUpdateLeaveFiscalDay)) {
        dispatch(leaveApplicationFiscalDayUpdate(values, isUpdateLeaveFiscalDay.id))
      } else {
        dispatch(leaveApplicationFiscalDayAdd(values))
      }

    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onAddUpdateLeaveFiscalDayClose}
      closable={false}
    >
      <Header className='drawer__header'>
        <h4 className='drawer__header--title'>
          {`${isUpdateLeaveFiscalDay ? "Update" : "Create"} Leave Fiscal Day`}
        </h4>
      </Header>
      <Form
        layout='vertical'
        form={form}
        name='control-hooks'
        validateMessages={validateMessages}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name='totalAllowedDays'
              label='Total Allowed Days'
              rules={[{ required: true }]}>
              <Input type="number" autoComplete="off" />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              name='fiscalYearId'
              label='Fiscal Year'
              className="form__group"
              rules={[{ required: true }]}
            >
              <Select
                showSearch={true}
                allowClear
              >
                {fiscalYearListingWithStatus.map((fiscalYear) => (
                  <Option key={fiscalYear.fiscalYearId}>{fiscalYear.displayName}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateLeaveFiscalDayClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default LeaveFiscalDayAddUpdateDrawer;
