import { ADMIN_TYPES } from "../../../../types";

const INITIAL_STATE = {
    leaveFiscalDayPagination: {
        items: [],
        totalCount: 0
    },
    leaveFiscalDayPaginationLoading: false,

    addLeaveFiscalDayResponse: {},
    addLeaveFiscalDayResponseLoading: false,

    updateLeaveFiscalDayResponse: {},
    updateLeaveFiscalDayResponseLoading: false,
};

export default function leaveFiscalDaysReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Fiscal Year Pagination
        case ADMIN_TYPES.LEAVE_FISCAL_DAYS_PAGINATION_INIT:
            return {
                ...state,
                leaveFiscalDayPagination: {
                    items: [],
                    totalCount: 0
                },
                leaveFiscalDayPaginationLoading: true,
            };
        case ADMIN_TYPES.LEAVE_FISCAL_DAYS_PAGINATION_SUCCESS:
            return {
                ...state,
                leaveFiscalDayPagination: action.payload,
                leaveFiscalDayPaginationLoading: false,
            };
        case ADMIN_TYPES.LEAVE_FISCAL_DAYS_PAGINATION_FINISH:
            return {
                ...state,
                leaveFiscalDayPaginationLoading: false,
            };

        // Add Leave Fiscal Day
        case ADMIN_TYPES.LEAVE_FISCAL_DAYS_ADD_INIT:
            return {
                ...state,
                addLeaveFiscalDayResponse: {},
                addLeaveFiscalDayResponseLoading: true,
            };
        case ADMIN_TYPES.LEAVE_FISCAL_DAYS_ADD_SUCCESS:
            return {
                ...state,
                addLeaveFiscalDayResponse: action.payload,
                addLeaveFiscalDayResponseLoading: false,
            };
        case ADMIN_TYPES.LEAVE_FISCAL_DAYS_ADD_FINISH:
            return {
                ...state,
                addLeaveFiscalDayResponseLoading: false,
            };

        // Update Leave Fiscal Day
        case ADMIN_TYPES.LEAVE_FISCAL_DAYS_UPDATE_INIT:
            return {
                ...state,
                updateLeaveFiscalDayResponse: {},
                updateLeaveFiscalDayResponseLoading: true,
            };
        case ADMIN_TYPES.LEAVE_FISCAL_DAYS_UPDATE_SUCCESS:
            return {
                ...state,
                updateLeaveFiscalDayResponse: action.payload,
                updateLeaveFiscalDayResponseLoading: false,
            };
        case ADMIN_TYPES.LEAVE_FISCAL_DAYS_UPDATE_FINISH:
            return {
                ...state,
                updateLeaveFiscalDayResponseLoading: false,
            };
        default:
            return { ...state };
    }
}
