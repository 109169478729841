import React from "react";

import { Menu } from "antd";
import { Link } from "react-router-dom";

const addperson = "assets/svg/addperson.svg";
const addcompany = "assets/svg/addcompany.svg";

const HrDropDownMenu = ({
  showAddUserDrawer,
  showAddUpdateSisterCompanyDrawer,

}) => {
  return (
    <Menu style={{ width: 144, height: 76 }}>
      <Menu.Item
        className="sidebars__menu--item"
        onClick={() => {
          showAddUpdateSisterCompanyDrawer();
        }}
      >
        <>
          <div style={{ width: "18%" }}>
            <img src={addcompany} />
          </div>
          <div style={{ width: "50%" }}>Sisco</div>
        </>
      </Menu.Item>
      <Menu.Item
        className="sidebars__menu--item"
        onClick={() => {
          showAddUserDrawer();
        }}
      >
        <>
          <div style={{ width: "18%" }}>
            <img src={addperson} />
          </div>
          <div style={{ width: "50%" }}>Employee</div>
        </>
      </Menu.Item>
    </Menu>
  );
};

export default HrDropDownMenu;
