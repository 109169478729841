import queryString from "query-string";
import { getService, postService } from "services/commonServices";
import { appBaseUrl } from "utils/config";

export class HonorRollService {
  honorRollsPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizerEvent/honorRollsPagination?${query}`;
    let data = getService(url);
    return data;
  }

  honorRollAdd(body) {
    let url = `${appBaseUrl}/organizerEvent/honorRollAdd`;
    let data = postService(url, body);
    return data;
  }

  honorRollUpdate(id, body) {
    let url = `${appBaseUrl}/organizerEvent/honorRollUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
}
