import { Typography } from "antd";

export const showNumber = (number, sn) => {
  if (sn !== ".") {
    if (number === 0) {
      return `--`;
    } else if (number < 0) {
      return (
        <Typography.Text style={{ color: "red" }}>
          ({Math.abs(Math.trunc(number))?.toLocaleString()})
        </Typography.Text>
      );
    } else {
      return (
        <Typography.Text>{Math.trunc(number).toLocaleString()}</Typography.Text>
      );
    }
  } else {
    return "";
  }
};
