import React from "react";
import { Select } from 'antd';
import { Controller } from "react-hook-form";
import { isEmpty } from "lodash";
import moment from 'moment';
import { DatePicker } from 'antd';

function errorMessage(error) {
    switch (error.type) {
        case "required":
            return "Required";
        default:
            return error.message;
    }
}

const AntdDate = (props) => {
    const { containerclassname, label, rules, control, defaultValue, onChangeSelect, name, error, required, options, multiple } = props;
    return (
        <div className={containerclassname ?? "form-group"}>
            {label && <label>{label} {required ? <b className="required-star">*</b> : ''}</label>}

            <Controller
                defaultValue={defaultValue || ''}
                name={name}
                control={control}
                rules={rules}
                render={({ onChange, value }) => (
                    <DatePicker
                        disabledDate={current => {
                            return (
                                current < moment()
                                // form.getFieldValue('endDate') ? current > form.getFieldValue('endDate') : current < moment()
                            )
                        }}
                        onChange={(selectValue, option) => {
                            onChange(selectValue)
                            if (onChangeSelect) {
                                onChangeSelect(selectValue, option);
                            }
                            return { value: value }
                        }
                        }
                        defaultValue={defaultValue}
                        {...props}
                    />
                )}
            />

            {/* error message */}
            {error && <label style={{ color: "red" }}>{errorMessage(error)}</label>}
        </div>
    );
};

export default AntdDate;