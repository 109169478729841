import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../../commonServices";

export class OrganizationLayerTypeService {
  getOrganizationLayerTypeListing() {
    let url = `${appBaseUrl}/organizationStructure/organizationLayerTypeListing`;
    let data = getService(url);
    return data;
  }

  // addAppointmentEvent(body) {
  //   let url = `${rootBaseUrl}/organizerEvent/appointmentEventAdd`;
  //   let data = postService(url, body);
  //   return data;
  // }

  // updateAppointmentEvent(body, id) {
  //   let url = `${rootBaseUrl}/organizerEvent/appointmentEventUpdate/${id}`;
  //   let data = postService(url, body, 'put');
  //   return data;
  // }

  // appointmentMarkAsComplete(id) {
  //   let url = `${rootBaseUrl}/organizerEvent/appointmentEventMarkItOff/${id}`;
  //   let data = postService(url, '', 'patch');
  //   return data;
  // }
}
