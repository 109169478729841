import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import { isEmpty } from "lodash";
import {
  actionForGetTenderCEADGetAccountViewPaginationListing,
  actionForGetTenderCEADGetPaginationListing,
  actionForWeeklyContractReportGetPaginationListing,
  actionForWeeklyReportGetPaginationListing,
} from "services/redux/cead/action";
import ContractStage from "./tabs/contractStage";
import ExecutionStage from "./tabs/anotherStage";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import { getAllUsersListing } from "services/redux/admin/FiscalYear/action";
import { allCompanyListing } from "services/redux/Reference/action";
import WeeklyReport from "./tabs/weeklyReport";
import moment from "moment";

const { TabPane } = Tabs;

export default function CEAD() {
  const {
    tenderCeadList,
    tenderCeadListLoading,
    singleTenderCeadData,
    tenderCeadAccountViewList,
    tenderCeadAccountViewListLoading,
    tenderCeadWeeklyReportDetailsListing,
    tenderCeadWeeklyReportDetailsListLoading,
    tenderCeadWeeklyContractReportDetailsListing,
    tenderCeadWeeklyContractReportGetListingLoading,
  } = useSelector((state) => state.ceadReducer);

  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);

  const { getAllUsersListResponse } = useSelector(
    (state) => state.fiscalYearState
  );

  const { allCurrencyListingResponse } = useSelector(
    (state) => state.reference
  );

  const { referenceListingResponse } = useSelector((state) => state.reference);

  const {
    contactCompanyPaginationResponse,
    // contactCompanyPaginationLoading,
    contactPersonPaginationResponse,
    contactPersonPaginationLoading,
  } = useSelector((state) => state.contactCompanyPerson);

  const dispatch = useDispatch();

  // const [checkedValues, setCheckValue] = useState(["active", "archived"]);
  const [checkedValues, setCheckValue] = useState(["active"]);

  const today = new Date();
  const initialSearchValues = {
    SearchKeyword: "",
    Reference_SearchKeyword: "",
    Contract_SearchKeyword: "",
    SearchKeyword_Year: today.getFullYear(),
    SearchKeyword_Month: today.getMonth() + 1,
    Sort: "asc",
    Sorting: [],
    SkipCount: 0,
    MaxResultCount: 40,
    tab: "",
    isEnterKey: false,
  };
  const [searchValues, setSearchValue] = useState(initialSearchValues);

  let appendQuery = "SkipCount=0&MaxResultCount=1000&";

  useEffect(() => {
    if (isEmpty(sisterCompanyListing)) {
      dispatch(getSisterCompaniesListings());
    }

    if (isEmpty(getAllUsersListResponse)) {
      dispatch(getAllUsersListing());
    }

    dispatch(
      actionForGetTenderCEADGetPaginationListing(
        `${appendQuery}IsClosed_SearchKeyword=false`
      )
    );
    dispatch(actionForGetTenderCEADGetAccountViewPaginationListing());
    dispatch(
      actionForWeeklyReportGetPaginationListing(
        `${appendQuery}SearchKeyword_Year=${searchValues?.SearchKeyword_Year}&SearchKeyword_Month=${searchValues?.SearchKeyword_Month}`
      )
    );
    dispatch(actionForWeeklyContractReportGetPaginationListing());
  }, []);

  useEffect(() => {
    let __appendQuery = appendQuery.concat(
      `&SortType=${searchValues?.Sort}&Sorting=${searchValues?.Sorting}&SearchKeyword=${searchValues?.SearchKeyword}&Reference_SearchKeyword=${searchValues?.Reference_SearchKeyword}&Contract_SearchKeyword=${searchValues?.Contract_SearchKeyword}`
    );

    let IsClosed_SearchKeyword = "";

    if (checkedValues?.length === 1) {
      IsClosed_SearchKeyword = `&IsClosed_SearchKeyword=${checkedValues.includes(
        "archived"
      )}`;
      __appendQuery = __appendQuery.concat(IsClosed_SearchKeyword);
    }

    if (searchValues?.SearchKeyword_Year) {
      let dateSearch = `&SearchKeyword_Year=${searchValues?.SearchKeyword_Year}`;
      if (searchValues?.SearchKeyword_Month) {
        dateSearch = `${dateSearch}&SearchKeyword_Month=${searchValues?.SearchKeyword_Month}`;
      }
      __appendQuery = __appendQuery.concat(dateSearch);
    }

    if (searchValues?.creatorName) {
      const creator = `&CreatorName=${searchValues?.creatorName}`;
      __appendQuery = __appendQuery.concat(creator);
    }

    setSearchValue({
      ...searchValues,
      isEnterKey: false,
      // SearchKeyword: "",
      // Contract_SearchKeyword: "",
      // Reference_SearchKeyword: "",
    });

    if (searchValues?.isEnterKey) {
      const callDispatch =
        searchValues.tab !== "weeklyReport"
          ? dispatch(actionForGetTenderCEADGetPaginationListing(__appendQuery))
          : dispatch(actionForWeeklyReportGetPaginationListing(__appendQuery));
      return callDispatch;
    }
  }, [checkedValues, searchValues?.isEnterKey]);

  useEffect(() => {
    if (isEmpty(allCurrencyListingResponse)) {
      dispatch(allCompanyListing());
    }
  }, []);

  let companyOptions = contactCompanyPaginationResponse?.items?.map((x) => {
    return {
      label: x?.contactCompanyName,
      value: x?.contactCompanyId,
    };
  });

  let contactPersonOptions =
    !contactPersonPaginationLoading &&
    contactPersonPaginationResponse?.items?.map((y) => {
      return {
        label: y?.personFullName,
        value: y?.contactPersonId,
      };
    });

  let currencyOptions = allCurrencyListingResponse?.map((x) => {
    return {
      label: x?.abbvr,
      value: x?.currencyId,
    };
  });

  let throughCompOptions = sisterCompanyListing?.map((x) => {
    return {
      label: x?.name,
      value: x?.id,
    };
  });

  let inchargeUsersOptions = getAllUsersListResponse?.map((x) => {
    return {
      label: x?.userName,
      value: x?.appUserId,
    };
  });

  let referenceOptions = referenceListingResponse?.items?.map((x) => {
    return {
      label: x?.code,
      value: x?.code,
    };
  });

  const headerTabs = [
    {
      key: "1",
      label: "Contract Stage",
      children: (
        <ContractStage
          singleTenderCeadData={singleTenderCeadData}
          tenderCeadList={tenderCeadList}
          tenderCeadListLoading={tenderCeadListLoading}
          throughCompOptions={throughCompOptions}
          inchargeUsersOptions={inchargeUsersOptions}
          companyOptions={companyOptions}
          contactPersonOptions={contactPersonOptions}
          currencyOptions={currencyOptions}
          referenceListingResponse={referenceListingResponse}
          referenceOptions={referenceOptions}
          setCheckValue={setCheckValue}
          checkedValues={checkedValues}
          setSearchValue={setSearchValue}
          searchValues={searchValues}
          initialSearchValues={initialSearchValues}
        />
      ),
    },
    {
      key: "2",
      label: "Execution Stage",
      children: (
        <ExecutionStage
          currencyOptions={currencyOptions}
          singleTenderCeadData={singleTenderCeadData}
          tenderCeadAccountViewList={tenderCeadAccountViewList}
          tenderCeadAccountViewListLoading={tenderCeadAccountViewListLoading}
          checkedValues={checkedValues}
          setCheckValue={setCheckValue}
          setSearchValue={setSearchValue}
          searchValues={searchValues}
          initialSearchValues={initialSearchValues}
          tenderCeadWeeklyContractReportDetailsListing={
            tenderCeadWeeklyContractReportDetailsListing
          }
          tenderCeadWeeklyContractReportGetListingLoading={
            tenderCeadWeeklyContractReportGetListingLoading
          }
        />
      ),
    },
    {
      key: "3",
      label: "Weekly Report",
      children: (
        <WeeklyReport
          tenderCeadWeeklyReportDetailsListLoading={
            tenderCeadWeeklyReportDetailsListLoading
          }
          tenderCeadWeeklyReportDetailsListing={
            tenderCeadWeeklyReportDetailsListing
          }
          checkedValues={checkedValues}
          setCheckValue={setCheckValue}
          setSearchValue={setSearchValue}
          searchValues={searchValues}
          initialSearchValues={initialSearchValues}
        />
      ),
    },
  ];

  // console.log("singleTenderCeadData", singleTenderCeadData);

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        tabBarGutter={25}
        // activeKey={activeTabIndexofApplicant}
        // onChange={(key) => {
        //   setActiveTabIndexofAppicant(key);
        // }}
        style={{ marginLeft: "10px" }}
      >
        {headerTabs?.map((x) => {
          return (
            <>
              <TabPane tab={x?.label} key={x?.key}>
                {x?.children}
              </TabPane>
            </>
          );
        })}
      </Tabs>
    </>
  );
}
