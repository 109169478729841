import { Button, Skeleton } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getSingleTadaDetails } from "services/redux/requistions/tadaDetails/action";
import NoContent from "components/shared/NoContent";

const TADATable = ({
  tadaApprovalResponse,
  tadaApprovalLoading,
  tada,
  setLoanApplicationId,
  setRequisitionModal,
  setShowMore,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {tadaApprovalResponse?.totalCount !== 0 ? (
        tadaApprovalResponse?.items?.map((list, i) => (
          <div key={i} className="hover-item">
            {
              <div>
                <li className="dashboards__tenderlist--left">
                  <div className="dashboards__tenderlist--left left1">
                    <div style={{ display: "flex" }}>
                      <h5 style={{ paddingLeft: 5 }}>{i + 1}</h5>
                      <h5 style={{ paddingLeft: 15 }}>
                        {list.requestedUserName}
                      </h5>
                    </div>
                    <span>
                      <>
                        <span style={{ paddingLeft: 25 }}>
                          Company:{list?.sisterCompanyName}
                        </span>
                        <br />
                        <span style={{ paddingLeft: 25 }}>
                          Subject:{list?.subject}
                        </span>
                        <br />
                        <span style={{ paddingLeft: 25 }}>
                          Application on:
                          {moment(list?.creationTime).format("DD MMM YYYY")}
                        </span>
                      </>
                    </span>
                  </div>
                  <div
                    className="dashboards__tenderlist--right right1"
                    style={{ paddingRight: 10 }}
                  >
                    {tada && (
                      <Button
                        style={{ height: "28px" }}
                        onClick={() => {
                          setLoanApplicationId(list?.id);
                          setRequisitionModal(true);
                          setShowMore(false);
                          dispatch(getSingleTadaDetails(list?.id));
                        }}
                      >
                        <EditOutlined
                          style={{
                            fontSize: "16px",
                            color: "#08c",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                          }}
                        />
                      </Button>
                    )}
                  </div>
                </li>
              </div>
            }
          </div>
        ))
      ) : tadaApprovalLoading ? (
        <Skeleton />
      ) : tadaApprovalResponse?.totalCount == 0 ? (
        <NoContent />
      ) : (
        ""
      )}
    </>
  );
};

export default TADATable;
