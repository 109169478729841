import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Skeleton, Space } from "antd";
import moment from "moment";
import NoStyleButton from "components/Button/NoStyleButton";
import { isEmpty } from "lodash";
import { getEmployeeEvaluationApplication } from "../../services/redux/requistions/EmployeeEvaluation/action";
import ReviewTemplateTablePresentation from "./ReviewTemplateTablePresentation";

const ReviewTemplateTable = ({ employeeUserId }) => {
  const { employeeReviewResponse, profileInfo } = useSelector(
    (state) => state.profile
  );
  const { getEmployeeEvaluationLoading, getEmployeeEvaluationListingResp } =
    useSelector((state) => state.employeeEvaluation);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [isCurrentUserHR, setIsCurrentUserHR] = useState(false);
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
  const [profileReviewData, setProfileReviewData] = useState([]);

  const closeProfileDrawer = () => {
    setOpenProfileDrawer(false);
  };

  const columns = [
    {
      title: "S.N",
      key: "sn1",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name1",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "job1",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "deparment1",
    },
    {
      title: "Review Period (in days)",
      dataIndex: "reviewPeriod",
      key: "review1",
    },
    {
      title: "Supervisor",
      dataIndex: "immidiateSuperior",
      key: "supervisor1",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date1",
      render: (_, record) => {
        return (
          <>
            {record?.date ? moment.utc(record?.date).format("YYYY-MM-DD") : ""}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "Edit1",
      render: (_, record) => (
        // isCurrentUserHR ? (
        //   <>
        //     <Space size="middle">
        //       <NoStyleButton
        //         text="View"
        //         OnClickButton={() => {
        //           setOpenProfileDrawer(true);
        //           setProfileReviewData(record);
        //         }}
        //       />
        //     </Space>
        //   </>
        // ) : null,
        <>
          <Space size="middle">
            <NoStyleButton
              text="View"
              OnClickButton={() => {
                setProfileReviewData(record);
                setOpenProfileDrawer(true);
              }}
            />
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!isEmpty(employeeUserId)) {
      dispatch(
        getEmployeeEvaluationApplication({
          EmployeeId: employeeUserId,
        })
      );
    }
  }, [dispatch, employeeUserId]);

  useEffect(() => {
    if (!isEmpty(profileInfo)) {
      setIsCurrentUserHR(
        profileInfo?.userRegistrationAddUpdateDto?.roleNames.includes("HR")
      );
    }
  }, [profileInfo, employeeUserId]);

  const profileReview = () => {
    return (
      <>
        <Table
          dataSource={getEmployeeEvaluationListingResp?.items}
          columns={columns}
          pagination={false}
          scroll={{
            x: 400,
            y: 400,
          }}
          className="clearance__form-tbl"
        />

        <ReviewTemplateTablePresentation
          openProfileDrawer={openProfileDrawer}
          closeProfileDrawer={closeProfileDrawer}
          data={profileReviewData}
        />
      </>
    );
  };

  return !isEmpty(getEmployeeEvaluationListingResp) ? (
    <>{profileReview()}</>
  ) : (
    <Skeleton active />
  );
  // return loading ? <Skeleton active /> : profileReview();
};

export default ReviewTemplateTable;
