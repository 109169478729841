import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allTendersGetFiles } from "services/redux/Reference/Tender/action";

import { EyeOutlined } from "@ant-design/icons";

import { cqBaseUrl } from "utils/config";
import pdfViewer from "components/Documents/ViewDocument";

const TenderDocuments = ({ getDetails }) => {
  const dispatch = useDispatch();

  const { allTenderGetDetailsResponse, allTenderGetDetailsLoading } =
    useSelector((state) => state.tender);

  const { token } = useSelector((state) => state.auth);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "sn",
      key: "sn",
      width: 20,
    },
    {
      title: "Display File Name",
      dataIndex: "displayFileName",
      key: "displayFileName",
    },
    {
      title: "File Type Name",
      dataIndex: "fileLableTypeName",
      key: "fileLableTypeName",
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <div>
            {/* {console.log(`${cqBaseUrl}/${record?.viewFileURL}${token}`)} */}
            <div
              onClick={() => {
                pdfViewer(`${cqBaseUrl}/${record?.viewFileURL}${token}`);
              }}
            >
              <EyeOutlined />
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allTendersGetFiles(getDetails?.id));
  }, [getDetails]);

  return (
    <div>
      {getDetails?.referenceCode && (
        <div className="business-header" style={{ marginTop: "5px" }}>
          <div className="business-header-title">
            <div
              className="profiledetails__rowedit--data"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              title={getDetails?.title}
            >
              <span
                className="referenceDetails--subtitle"
                style={{ color: "blue" }}
              >
                {getDetails?.referenceCode} [{getDetails?.title}]
              </span>
            </div>
          </div>
        </div>
      )}
      <Table
        dataSource={allTenderGetDetailsResponse?.map((x, index) => {
          return {
            ...x,
            sn: index + 1 + ".",
          };
        })}
        columns={columns}
      />
    </div>
  );
};

export default TenderDocuments;
