import { error, finish, init, success } from "services/common";
import {
  REQUEST_AMOUNT_TYPES,
  TADA_TYPES,
  VISIT_CATEGORY_TYPES,
} from "services/types";
import { RequestAmount, TadaDetails, VisitCategory } from "./api";
import { message } from "antd";
import { isEmpty } from "lodash";
import { getProcurementCounter } from "../procurement/action";

const visitCategory = new VisitCategory();
const requestAmount = new RequestAmount();
const tadaDetails = new TadaDetails();

export const getVisitCategory = (radioValue) => {
  return async (dispatch) => {
    dispatch(init(VISIT_CATEGORY_TYPES.VISIT_CATEGORY_GET_DETAILS));
    const response = await visitCategory.getVisitCategory(radioValue);
    dispatch(finish(VISIT_CATEGORY_TYPES.VISIT_CATEGORY_GET_DETAILS));
    if (response?.isSuccess) {
      dispatch(
        success(VISIT_CATEGORY_TYPES.VISIT_CATEGORY_GET_DETAILS, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getRequestAmount = (
  designationTypeId,
  radioValue,
  designationId
) => {
  return async (dispatch) => {
    dispatch(init(REQUEST_AMOUNT_TYPES.REQUEST_AMOUNT_GET_DETAILS));
    const response = await requestAmount.getRequestAmount(
      designationTypeId,
      radioValue,
      designationId
    );
    dispatch(finish(REQUEST_AMOUNT_TYPES.REQUEST_AMOUNT_GET_DETAILS));
    if (response?.isSuccess) {
      dispatch(
        success(REQUEST_AMOUNT_TYPES.REQUEST_AMOUNT_GET_DETAILS, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getTadaDetails = () => {
  return async (dispatch) => {
    dispatch(init(TADA_TYPES.TADA_GET_DETAILS));
    const response = await tadaDetails.getTadaDetailsPagination();
    dispatch(finish(TADA_TYPES.TADA_GET_DETAILS));
    if (response?.isSuccess) {
      dispatch(success(TADA_TYPES.TADA_GET_DETAILS, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const addTadaDetails = (body) => {
  return async (dispatch) => {
    dispatch(init(TADA_TYPES.TADA_DETAILS_ADD));
    const response = await tadaDetails.addTadaDetails(body);
    if (response?.isSuccess) {
      dispatch(success(TADA_TYPES.TADA_DETAILS_ADD, response?.data));
      message.success("Successfully Applied");
      dispatch(getTadaDetails());
      dispatch(finish(TADA_TYPES.TADA_DETAILS_ADD));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const getSingleTadaDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(TADA_TYPES.TADA_SINGLE_GET_DETAILS));
    const response = await tadaDetails.getTadaSingleDetails(id);
    dispatch(finish(TADA_TYPES.TADA_SINGLE_GET_DETAILS));
    if (response?.isSuccess) {
      dispatch(success(TADA_TYPES.TADA_SINGLE_GET_DETAILS, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const tadaDetailsUpdateSupervisor = (
  isApproveOrReject,
  body,
  id,
  setRequisitionModal,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(TADA_TYPES.TADA_DETAILS_UPDATE_SUPERVISOR));
    const response = await tadaDetails.tadaDetailsUpdateSupervisor(body, id);

    dispatch(finish(TADA_TYPES.TADA_DETAILS_UPDATE_SUPERVISOR));
    if (response?.isSuccess) {
      dispatch(
        success(TADA_TYPES.TADA_DETAILS_UPDATE_SUPERVISOR, response?.data)
      );
      if ((isApproveOrReject = 1 || 3)) {
        message.success("TADA Approved From Supervisor");
      } else {
        message.error("TADA Rejected From Supervisor ");
      }
      // dispatch(getSingleTadaDetails(id));
      setInstanceMessageCenterListing?.((prevData) => {
        if (!isEmpty(prevData.items)) {
          return {
            items: [response?.data?.internalMessage, ...prevData.items],
            totalCount: prevData.totalCount + 1,
          };
        } else {
          return {
            items: [response?.internalMessage],
            totalCount: 1,
          };
        }
      });
      setRequisitionModal?.(false);
      dispatch(getAllApprovalDetails());
      dispatch(getProcurementCounter());
    } else {
      dispatch(error(response?.errorMessage));
      message.error("The tada has error");
    }
  };
};

export const tadaDetailsUpdateManagement = (
  isApproveOrReject,
  body,
  id,
  setRequisitionModal,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(TADA_TYPES.TADA_DETAILS_UPDATE_MANAGEMENT));
    const response = await tadaDetails.tadaDetailsUpdateManagement(body, id);
    dispatch(finish(TADA_TYPES.TADA_DETAILS_UPDATE_MANAGEMENT));
    if (response?.isSuccess) {
      dispatch(
        success(TADA_TYPES.TADA_DETAILS_UPDATE_MANAGEMENT, response?.data)
      );
      if ((isApproveOrReject = 1)) {
        message.success("TADA Approved From Management");
      } else {
        message.error("TADA Rejected From Management");
      }
      // dispatch(getSingleTadaDetails(id));
      setInstanceMessageCenterListing?.((prevData) => {
        if (!isEmpty(prevData.items)) {
          return {
            items: [response?.data?.internalMessage, ...prevData.items],
            totalCount: prevData.totalCount + 1,
          };
        } else {
          return {
            items: [response?.internalMessage],
            totalCount: 1,
          };
        }
      });
      setRequisitionModal?.(false);
      dispatch(getAllApprovalDetails());
      dispatch(getProcurementCounter());
    } else {
      dispatch(error(response?.errorMessage));
      message.error("The tada has error");
    }
  };
};

export const tadaDetailsUpdateAccountant = (
  isApproveOrReject,
  body,
  id,
  setRequisitionModal,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(TADA_TYPES.TADA_DETAILS_UPDATE_ACCOUNTANT));
    const response = await tadaDetails.tadaDetailsUpdateAccountant(body, id);
    dispatch(finish(TADA_TYPES.TADA_DETAILS_UPDATE_ACCOUNTANT));

    if (response?.isSuccess) {
      dispatch(
        success(TADA_TYPES.TADA_DETAILS_UPDATE_ACCOUNTANT, response?.data)
      );
      if ((isApproveOrReject = 1)) {
        message.success("TADA Approved From Accountant");
      } else {
        message.error("TADA Rejected From Accountant");
      }
      // dispatch(getSingleTadaDetails(id));
      setInstanceMessageCenterListing?.((prevData) => {
        if (!isEmpty(prevData.items)) {
          return {
            items: [response?.data?.internalMessage, ...prevData.items],
            totalCount: prevData.totalCount + 1,
          };
        } else {
          return {
            items: [response?.internalMessage],
            totalCount: 1,
          };
        }
      });
      setRequisitionModal?.(false);
      dispatch(getAllApprovalDetails());
      dispatch(getProcurementCounter());
    } else {
      dispatch(error(response?.errorMessage));
      message.error("The tada has error");
    }
  };
};

export const tadaDetailsUpdateSettlement = (body, id) => {
  return async (dispatch) => {
    dispatch(init(TADA_TYPES.TADA_DETAILS_UPDATE_SETTLEMENT));
    const response = await tadaDetails.tadaDetailsUpdateSettlement(body, id);
    dispatch(finish(TADA_TYPES.TADA_DETAILS_UPDATE_SETTLEMENT));
    if (response?.isSuccess) {
      dispatch(
        success(TADA_TYPES.TADA_DETAILS_UPDATE_SETTLEMENT, response?.data)
      );
      message.success("TADA Settlement Applied");
      dispatch(getSingleTadaDetails(id));
    } else {
      dispatch(error(response?.errorMessage));
      message.error("The tada has error");
    }
  };
};

export const getAllApprovalDetails = () => {
  return async (dispatch) => {
    dispatch(init(TADA_TYPES.TADA_APPROVAL));
    const response = await tadaDetails.tadaApprovalData();
    dispatch(finish(TADA_TYPES.TADA_APPROVAL));
    if (response?.isSuccess) {
      dispatch(success(TADA_TYPES.TADA_APPROVAL, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};
