import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, message, Modal, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "services/remanentCalls";
import axios from "axios";
import { appBaseUrl } from "utils/config";
import AutoForwardDatatable from "components/tables";
import { mailReferenceTagUpdate } from "services/redux/email/mailMessageCenter/action";

import * as CONSTANT from "components/constants";

const Modals = ({
  title,
  content,
  visibility = false,
  handleOk,
  handleCancel,
  setShowModalForCorretionTag,
  ...props
}) => {
  const dispatch = useDispatch();
  const { mailReferenceUpdateRespoonseLoading } = useSelector(
    (state) => state.mailMessageCenter
  );
  const [formAutoForward, recentReferenceTagform] = Form.useForm();
  const { replyInstanceMessageRespLoading } = useSelector(
    (state) => state?.instanceMessageCenter
  );
  const [allUsersOptions, setAllUsersOptions] = useState([]);
  const [expirationDateTime, setExpirationDate] = useState();
  const [autoForwardLoad, setAutoForwardLoad] = useState(false);

  const fetchAllParticipants = async () => {
    const response = await getAllUsers();

    const data = response?.data?.map((x) => {
      return {
        label: `${x?.fullName} [${x?.userName}]`,
        value: x?.appUserId,
      };
    });
    setAllUsersOptions(data);
  };

  useEffect(() => {
    if (allUsersOptions?.length <= 0) {
      fetchAllParticipants();
    }

    formAutoForward.setFieldsValue({
      subject: props?.currentSelectedMailMessage?.subject,
      emailAddress:
        props?.currentSelectedMailMessage?.applicationTypeDetails?.from?.[0]
          ?.emailAddress,
    });
  }, [props?.currentSelectedMailMessage]);

  const onAutoForwardFinish = async (values) => {
    setAutoForwardLoad(true);
    try {
      const response = await axios.post(
        `${appBaseUrl}/emailMessage/internalMessageForwardingRulesAdd`,
        {
          ...values,
          expiryDateTime: expirationDateTime,
        }
      );
      if (response?.status === 200) {
        message.success("Email Forwaded");
        setAutoForwardLoad(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.error?.message);
      setAutoForwardLoad(false);
    }
  };

  const onRecentReferenceTagFinish = async (values) => {
    let reference = [];
    reference =
      props?.currentSelectedMailMessage?.mailMessageReferenceDtos?.map((x) => {
        return x?.referenceCode;
      });

    const apiData = values?.recentTags?.map((x) => {
      reference?.push(x);
    });

    reference = [...new Set(reference)];

    const lastApiData = reference?.map((x) => {
      return {
        messageCenterId:
          props?.currentSelectedMailMessage?.instanceMainMessageCenterId,
        referenceCode: x,
      };
    });
    dispatch(
      mailReferenceTagUpdate(lastApiData, props?.currentSelectedMailMessage)
    );
    if (mailReferenceUpdateRespoonseLoading === false) {
      props.setOpenRecentlyAddTags(false);
    }
  };

  const onChange = (date, dateString) => {
    setExpirationDate(dateString);
  };

  const autoForwardContent = () => {
    return (
      <>
        <Form
          labelCol={{
            span: 4,
          }}
          form={formAutoForward}
          onFinish={onAutoForwardFinish}
        >
          <Form.Item label="Subject" name="subject">
            <Input placeholder="Subject" rules={[{ required: true }]} />
          </Form.Item>

          <Form.Item
            label="Sender Address"
            name="emailAddress"
            rules={[
              {
                required: true,
                type: "email",
                message: "Invalid email! eg. example@example.example",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Forward To"
            name="userIds"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              options={allUsersOptions}
              placeholder="Select Users"
            />
          </Form.Item>

          <Form.Item
            label="Expiration Date"
            name="expiryDateTime"
            // rules={[{ required: true }]}
          >
            <DatePicker onChange={onChange} showTime />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "right", margin: "12px 0 " }}
              loading={autoForwardLoad}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  const recentlyAddReferenceContent = () => {
    return (
      <>
        <Form
          form={recentReferenceTagform}
          onFinish={onRecentReferenceTagFinish}
        >
          <Form.Item name="recentTags" label="Recent Tags">
            <Select
              mode="multiple"
              placeholder="Select Recently Used Tags"
              options={props?.recentlyUsedReferenceTags}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ float: "right", margin: "12px 0 " }}
              loading={mailReferenceUpdateRespoonseLoading}
            >
              Add
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };

  const callbackContents = () => {
    if (content === CONSTANT.AUTO_FORWARD) {
      return autoForwardContent();
    } else if (content === CONSTANT.RECENT_REFERENCE_TAGS) {
      return recentlyAddReferenceContent();
    } else {
      return content;
    }
  };

  const handleOkMethods = () => {
    if (content === CONSTANT.AUTO_FORWARD) {
      return onAutoForwardFinish();
    } else if (content === CONSTANT.RECENT_REFERENCE_TAGS) {
      return onRecentReferenceTagFinish();
    } else {
      return handleOk();
    }
  };

  const onCancleMethods = () => {
    if (content === CONSTANT.AUTO_FORWARD) {
      props.setShowAutoForwardModal(false);
    } else if (content === CONSTANT.RECENT_REFERENCE_TAGS) {
      props.setOpenRecentlyAddTags(false);
    } else {
      setShowModalForCorretionTag({
        visibility: false,
        tagName: "",
      });
    }
  };

  return (
    <>
      <Modal
        title={title}
        visible={visibility}
        onOk={handleOkMethods}
        confirmLoading={
          content == CONSTANT.AUTO_FORWARD
            ? autoForwardLoad
            : content == CONSTANT.RECENT_REFERENCE_TAGS
            ? mailReferenceUpdateRespoonseLoading
            : replyInstanceMessageRespLoading
        }
        onCancel={() => onCancleMethods()}
        {...(content === CONSTANT.AUTO_FORWARD ||
        content === CONSTANT.RECENT_REFERENCE_TAGS
          ? {
              footer: null,
              width: 820,
            }
          : "")}
      >
        {callbackContents()}
        {content === CONSTANT.AUTO_FORWARD && (
          <>
            <AutoForwardDatatable
              autoForwardLoad={autoForwardLoad}
              setAutoForwardLoad={setAutoForwardLoad}
            />
          </>
        )}
      </Modal>
    </>
  );
};
export default Modals;
