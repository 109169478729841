import { isEmpty } from "lodash";
import { getService, postService } from "services/commonServices";
import { appBaseUrl } from "utils/config";
export class CeadService {
  apiRouteForGetTenderCEADGetPaginationListing(query) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADGetPaginationListing?${query}`;
    let data = getService(url);
    return data;
  }

  apiRouteForGetTenderCEADAccountViewGetListing(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADAccountViewGetListing/${
      id ? id : ""
    }`;
    let data = getService(url);
    return data;
  }

  apiRouteForGetSingleCeadTenderDetail(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderGetCEADDetails/${id}`;
    let data = getService(url);
    return data;
  }

  apiForAddUpdateFinancialInformation(body, id, ceadFinancialType) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADContractFinancialsAddUpdateInformation/${id}`;
    // let url = `${appBaseUrl}/referenceManagement/tenderCEADContractFinancialsAddUpdateInformation/${id}?${ceadFinancialType}`;
    let data = postService(url, body);
    return data;
  }

  apiForAddUpdateVariationInformation(body, id, ceadFinancialType) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADVariationAddUpdateInformation/${id}`;
    // let url = `${appBaseUrl}/referenceManagement/tenderCEADContractFinancialsAddUpdateInformation/${id}?${ceadFinancialType}`;
    let data = postService(url, body);
    return data;
  }

  apiRouteForAddUpdateAccountsCommissionRelatedContactPersonAtPrinipal(body) {
    // let url = `${appBaseUrl}/organizerEvent/calendarEventList?${body}`;
    // let data = postService(url);
    // return data;
  }

  apiRouteForAddUpdateCEADTender(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderAddUpdateCEAD`;
    let data = postService(url, body);
    return data;
  }

  apiRouteForAddUpdateFunding(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADAddUpdateFundingAgency/${id}`;
    let data = postService(url, body);
    return data;
  }

  // for multiple bid bounds
  apiRouteForAddUpdateBidBounds(body, id, ETenderCEADBidTypes) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADAddUpdateBidBounds/${id}?${ETenderCEADBidTypes}`;
    let data = postService(url, body);
    return data;
  }

  // for single bid bound
  apiRouteForAddUpdateBidBound(body, id, ETenderCEADBidTypes) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADAddUpdateBidBound/${id}?${ETenderCEADBidTypes}`;
    let data = postService(url, body);
    return data;
  }

  apiRouteForAddUpdatePerformanceBound(body) {
    // let url = `${appBaseUrl}/organizerEvent/calendarEventList?${body}`;
    // let data = postService(url);
    // return data;
  }

  apiRouteForAddUpdateAdvancePaymentGuarantee(body) {
    // let url = `${appBaseUrl}/organizerEvent/calendarEventList?${body}`;
    // let data = postService(url);
    // return data;
  }

  apiForAddUpdateCEADPaymentReceivedAddUpdateInformation(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADPaymentReceivedAddUpdateInformation/${id}`;
    let data = postService(url, body);
    return data;
  }

  apiForTenderCEADPaymentReceivedPrincipalAddUpdateInformation(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADPaymentReceivedPrincipalAddUpdateInformation/${id}`;
    let data = postService(url, body);
    return data;
  }

  apiForAddUpdateCEADOtherInformation(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADOtherAddUpdateInformation/${id}`;
    let data = postService(url, body);
    return data;
  }

  //payment received by principal invoice generated

  apiForPaymentReceivedPrincipalInvoiceGenerate(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADPaymentReceivedPrincipalInvoiceGenerate/${id}`;
    let data = postService(url);
    return data;
  }

  apiForPaymentReceivedPrincipalInvoiceAccountApproved(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADPaymentReceivedPrincipalInvoiceAccountApproved/${id}`;
    let data = postService(url, body);
    return data;
  }

  apiForPaymentReceivedPrincipalInvoiceGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADPaymentReceivedPrincipalInvoiceGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  apiForPaymentReceivedPrincipalInvoiceManagementApproved(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADPaymentReceivedPrincipalInvoiceManagementApproved/${id}`;
    let data = postService(url, body);
    return data;
  }

  apiForPaymentReceivedPrincipalInvoiceFinalAccountApproved(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADPaymentReceivedPrincipalInvoiceFinalAccountApproved/${id}`;
    let data = postService(url, body);
    return data;
  }

  apiForPaymentReceivedPrincipalInvoiceLogGetListing(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADPaymentReceivedPrincipalInvoiceLogGetListing/${id}`;
    let data = getService(url);
    return data;
  }
  //end

  apiForUpdateQuestionAnswer(body, id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADUpdateQuestionAnswer/${id}`;
    let data = postService(url, body);
    return data;
  }

  apiForCeadEmailCompose(id) {
    let url = `${appBaseUrl}/referenceManagement/composeMessageParameterGetInvoiceGenerate/${id}`;
    let data = getService(url);
    return data;
  }

  apiForContractAddUpdateClosure(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADContractAddUpdateClosure`;
    let data = postService(url, body);
    return data;
  }

  //WEELY REPORT

  apiForWeeklyAddUpdateReport(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADWeeklyAddUpdateReport`;
    let data = postService(url, body);
    return data;
  }

  apiForWeeklyReportGetPaginationListing(query) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADWeeklyReportGetPaginationListing?${query}`;
    let data = getService(url);
    return data;
  }

  apiForWeeklyAddUpdateReportGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADWeeklyAddUpdateReportGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  //CONTRACT WEEKLY REPORT

  apiForWeeklyContractAddUpdateReport(body) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADWeeklyContractAddUpdateReport`;
    let data = postService(url, body);
    return data;
  }

  apiForWeeklyContractReportGetPaginationListing(query) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADWeeklyContractReportGetPaginationListing?${query}`;
    let data = getService(url);
    return data;
  }

  apiForContractWeeklyAddUpdateReportGetDetails(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCEADWeeklyAddUpdateContractReportGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  // apiForContractWeeklyAddUpdateReportGetDetails
  apiForTenderCeadSummary(id) {
    let url = `${appBaseUrl}/referenceManagement/tenderCeadSummary?ceadid=${id}`;
    let data = getService(url);
    return data;
  }

  apiForCEADWeeklyReportMail(id, body) {
    let url = `${appBaseUrl}/requisitionManagement/tenderCeadWeeklyReport/${id}`;
    let data = postService(url, body);
    return data;
  }
}
