import { ADMIN_TYPES } from "services/types";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
import { WhitelistIpService } from "./api";

const whitelistIpService = new WhitelistIpService();


export const actionForGetWhiteAccessGetListingIpAddress = (q) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.WHITELIST_IPADDRESS_LISTING));
    const response = await whitelistIpService.getWhiteAccessGetListingIpAddress(q);

    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.WHITELIST_IPADDRESS_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }

    dispatch(finish(ADMIN_TYPES.WHITELIST_IPADDRESS_LISTING));
  };
}

export const actionForAddWhiteAccessCreateIpAddress = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.WHITELIST_IPADDRESS_ADD));
    const response = await whitelistIpService.addWhiteAccessCreateIpAddress(body);
    dispatch(finish(ADMIN_TYPES.WHITELIST_IPADDRESS_ADD));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.WHITELIST_IPADDRESS_ADD, response.data));
      message.success("Added Ip Address");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForUpdateWhiteAccessUpdateIpAddress = (id, body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.WHITELIST_IPADDRESS_UPDATE));
    const response = await whitelistIpService.updateWhiteAccessUpdateIpAddress(id, body);

    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.WHITELIST_IPADDRESS_UPDATE, response.data));
      message.success("Updated Ip Address");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }

    dispatch(finish(ADMIN_TYPES.WHITELIST_IPADDRESS_UPDATE));
  };
};