import { Table } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApplicationTemplate } from "services/redux/organizer/ApplicationTemplate/action";
import SectorAddUpdateTable from "../ProjectSetting/Sector/SectorTable";

export default function ApplicationTemplateViewTable({
  setOpenForm,
  setUpdateData,
}) {
  const dispatch = useDispatch();
  const [data, setData] = useState("");

  const {
    getApplicationTemplateResponse,
    getApplicationTemplateResponseLoading,
  } = useSelector((state) => state.application);
  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Action",
      render: (record) => (
        <a
          onClick={() => {
            setUpdateData(record);
            setOpenForm(true);
          }}
        >
          Edit
        </a>
      ),
      width: "7%",
    },
  ];

  // useEffect(() => {
  //   if (!isEmpty(getApplicationTemplateResponse)) {
  //     setData(getApplicationTemplateResponse?.items);
  //   }
  // }, [getApplicationTemplateResponse]);

  useEffect(() => {
    dispatch(getApplicationTemplate());
  }, []);

  return (
    <div>
      <Table
        className='organizer__table contact-table'
        columns={columns}
        scroll={true}
        // rowClassName={setRowClassName}
        rowKey='appUserId'
        dataSource={getApplicationTemplateResponse?.items}
        sticky={true}
        // onChange={handleTableChange}
        loading={{
          // indicator: <img src={LogoImage} height="auto" width="50px" />,
          spinning: getApplicationTemplateResponseLoading,
        }}
      />
    </div>
  );
}
