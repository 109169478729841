import React, { useEffect, useState } from "react";
// antd
import { Button, Col, Row, Select, Input } from "antd";

// includes
import Calender from "./Calendar";

// redux
import { useDispatch, useSelector } from "react-redux";
// import { getCalenderListEvents } from "services/redux/calendar/action";
// import EventsListing from "./EventsListing";

// import { union, uniqBy, isEmpty } from "lodash-es";

// const { Option } = Select;

const FullCalender = ({
  handleChangeEvent,
  //   onCloseEventFormDrawer,
  setDateClickDetails,
  origin,
}) => {
  //   const dispatch = useDispatch();
  const calendarEventState = useSelector((state) => state.calendarEvents);
  const { allCalendarEvents } = calendarEventState;
  //   const [checkedKeys, setCheckedKeys] = useState([]);
  //   const [showDropdown, setShowDropdown] = useState(false);
  const [dateRange, setDateRange] = useState({});

  return (
    <div className="layout">
      <div className="organizer fullCalendar">
        {/* <div className="layout-title">
					<Input.Search
						className="form__groups"
						placeholder="Search"
						size="medium"
						allowClear
						style={{ width: 400 }}
						// onSearch={(e) => {
						//   setSearchKeyword(e);
						// }}
					/>
				</div> */}
        <div className="calender-body">
          <Row gutter={30}>
            <Col lg={24} className="guest__divider">
              {/* <h3 className="header__all">
								<span className="title">Calender</span> */}
              {/* {showDropdown ? (
								<Select placeholder="Select Event Form Type" style={{ width: 180 }} onChange={handleChangeEvent} allowClear>
									<Option value="goal">Goal</Option>
									<Option value="appointment">Appointment</Option>
									<Option value="todo">To Do</Option>
								</Select>
							) : (
								<Button className="calendar-create" onClick={() => setShowDropdown(true)}>
									+ Create
								</Button>
							)} */}
              {/* </h3> */}
              <Calender
                allCalendarEvents={allCalendarEvents}
                setDateRange={setDateRange}
                handleChangeEvent={handleChangeEvent}
                setDateClickDetails={setDateClickDetails}
                origin={origin}
              />
            </Col>
            {/* <Col lg={9}>
							<EventsListing
								allCalendarEvents={allCalendarEvents}
								dateRange={dateRange}
								onCheckFilter={onCheckFilter}
								checkedKeys={checkedKeys}
								setCheckedKeys={setCheckedKeys}
							/>
						</Col> */}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FullCalender;
