import { TenderService } from "./api";
import { REFERENCE__TYPES } from "../../../types";
import { init, success, finish, remove, error } from "services/common";
import { actionForPersistTenderMailContent } from "views/OfficeResource/Reference/Tabs/TenderTabs/companies/persistTender/action";
import { message } from "antd";
import queryString from "query-string";
import { isEmpty } from "lodash";

const tenderService = new TenderService();

// export const getProjectSearchPagination = (body, forPagination) => {
// 	return async (dispatch) => {
// 		dispatch(init(REFERENCE__TYPES.PROJECT_PAGINATION));
// 		const response = await tenderService.projectPagination(body);
// 		dispatch(finish(REFERENCE__TYPES.PROJECT_PAGINATION));
// 		if (response.isSuccess) {
// 			if (forPagination) {
// 				dispatch(success(REFERENCE__TYPES.PROJECT_PAGINATION_CHANGE, response.data));
// 			} else {
// 				dispatch(success(REFERENCE__TYPES.PROJECT_PAGINATION, response.data));
// 			}
// 		} else if (!response.isSuccess) {
// 			dispatch(error(response.errorMessage));
// 		}
// 	};
// };

export const getTenderBasicSearchPagination = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_BASIC_PAGINATION));
    const response = await tenderService.tenderBasicPagination(query);
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.TENDER_BASIC_PAGINATION,
          response.data,
          loadMore
        )
      );
      dispatch(finish(REFERENCE__TYPES.TENDER_BASIC_PAGINATION));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// history tender
export const getTenderHistoryPagination = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_HISTORY_PAGINATION));
    const response = await tenderService.tenderHistoryPagination();
    dispatch(finish(REFERENCE__TYPES.TENDER_HISTORY_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_HISTORY_PAGINATION, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//upcoming tender
export const getTenderUpcomingPagination = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_UPCOMING_PAGINATION));
    const response = await tenderService.tenderUpcomingPagination();
    dispatch(finish(REFERENCE__TYPES.TENDER_UPCOMING_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_UPCOMING_PAGINATION, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// Counter In tender
export const tenderCounter = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_COUNTER));
    const response = await tenderService.tenderCounter();
    dispatch(finish(REFERENCE__TYPES.TENDER_COUNTER));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.TENDER_COUNTER, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
//FourthUpcoming Tender
export const getTenderFourthCompingPagination = (query, forPagination) => {
  return async (dispatch) => {
    // dispatch(init(REFERENCE__TYPES.TENDER_FOURTH_UPCOMING_PAGINATION));
    // const response = await tenderService.tenderFourthCompingPagination(query);
    // dispatch(finish(REFERENCE__TYPES.TENDER_FOURTH_UPCOMING_PAGINATION));
    // if (response.isSuccess) {
    //   dispatch(
    //     success(
    //       REFERENCE__TYPES.TENDER_FOURTH_UPCOMING_PAGINATION,
    //       response?.data
    //     )
    //   );
    // } else if (!response.isSuccess) {
    //   dispatch(error(response.errorMessage));
    // }
    dispatch(init(REFERENCE__TYPES.TENDER_FOURTH_UPCOMING_PAGINATION));
    const response = await tenderService.tenderFourthCompingPagination(query);

    dispatch(finish(REFERENCE__TYPES.TENDER_FOURTH_UPCOMING_PAGINATION));
    if (response.isSuccess) {
      if (forPagination) {
        dispatch(
          success(
            REFERENCE__TYPES.TENDER_FOURTH_UPCOMING_PAGINATION,
            response.data
          )
        );
      } else {
        dispatch(
          success(
            REFERENCE__TYPES.TENDER_FOURTH_UPCOMING_PAGINATION,
            response.data
          )
        );
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//get pagination tender
export const getDueDateTender = (query) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_DUE_TENDER));
    const response = await tenderService.dueDateTender(query);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_DUE_TENDER));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.REFERENCE_DUE_TENDER, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//tenderParticipationPagination

export const getParticipationPagination = (query) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_PARTICIPATION_PAGINATION));
    const response = await tenderService.tenderParticipationPagination(query);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_PARTICIPATION_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_TYPE_PARTICIPATION_PAGINATION,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//document purchase pagination
export const getDocumentPurchasePagintaion = (query) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_DOCUMENT_PAGINATION));
    const response = await tenderService.tenderDocumentPagination(query);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_DOCUMENT_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_TYPE_DOCUMENT_PAGINATION,
          response.data
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// MODAL GET ONLY
export const tenderGetBasicROTODetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_BASIC_ROTO_GetDetails));
    const response = await tenderService.tenderBasicGetDetails(id);
    dispatch(finish(REFERENCE__TYPES.TENDER_BASIC_ROTO_GetDetails));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_BASIC_ROTO_GetDetails, response.data)
      );

      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderGetBasicDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_BASIC_GetDetails));
    const response = await tenderService.tenderBasicGetDetails(id);
    dispatch(finish(REFERENCE__TYPES.TENDER_BASIC_GetDetails));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_BASIC_GetDetails, response.data)
      );

      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderBasicAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_BASIC_ADD));
    const response = await tenderService.tenderBasicAdd(body);
    dispatch(finish(REFERENCE__TYPES.TENDER_BASIC_ADD));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.TENDER_BASIC_ADD, response.data));
      message.success("Successfully added Tender");
      // dispatch(getTenderBasicSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const stageLevelAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_STAGE_ADD));

    const response = await tenderService.stageLevelAdd(body);
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.TENDER_STAGE_ADD, response.data));
      message.success(`Successfully added Stage`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }

    dispatch(finish(REFERENCE__TYPES.TENDER_STAGE_ADD));
  };
};

//PARTICIPATION ADDED
export const tenderParticipationAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_PARTICIPATION_ADD));
    const response = await tenderService.tenderParticipantAdd(body);
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_PARTICIPATION_ADD, response.data)
      );
      message.success(`Successfully added Participation`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.TENDER_PARTICIPATION_ADD));
  };
};

//PARTICIPATION Update
export const tenderParticipationUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_BASIC_UPDATE_PARTICIPATION));
    const response = await tenderService.tenderBasicParticipantUpdate(body);
    dispatch(finish(REFERENCE__TYPES.TENDER_BASIC_UPDATE_PARTICIPATION));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.TENDER_BASIC_UPDATE_PARTICIPATION,
          response.data
        )
      );
      message.success(`Successfully Updated Participation`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//partcipation GetDetails

export const tenderBasicUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_BASIC_UPDATE));
    const response = await tenderService.tenderBasicUpdate(body);
    dispatch(finish(REFERENCE__TYPES.TENDER_BASIC_UPDATE));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.TENDER_BASIC_UPDATE, response.data));
      message.success(`Successfully updated Tender`);
      // dispatch(getTenderBasicSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// delete
export const tenderDelete = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_BASIC_UPDATE));
    const response = await tenderService.tenderDelete(body?.id);
    const updatedResponse = {
      data: {
        ...body,
        isActive: false,
      },
    };
    dispatch(finish(REFERENCE__TYPES.TENDER_BASIC_UPDATE));
    if (response.isSuccess) {
      dispatch(
        remove(REFERENCE__TYPES.TENDER_BASIC_UPDATE, updatedResponse.data)
      );
      message.success(`Successfully Deleted Tender`);
      // dispatch(getTenderBasicSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderStageListing = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_TENDERSTAGELISTING));
    const response = await tenderService.tenderStageListing();
    dispatch(finish(REFERENCE__TYPES.GET_TENDERSTAGELISTING));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.GET_TENDERSTAGELISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderTypeListing = () => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_TENDERTYPELISTING));
    const response = await tenderService.tenderTypeListing();
    dispatch(finish(REFERENCE__TYPES.GET_TENDERTYPELISTING));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.GET_TENDERTYPELISTING, response.data.items)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// sub tender/category listing
export const tenderSubTypeListing = (q) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_TENDER_SUBTYPE_LISTING));
    const response = await tenderService.tenderSubTypeListing(q);
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.GET_TENDER_SUBTYPE_LISTING, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.GET_TENDER_SUBTYPE_LISTING));
  };
};

//TENDERDocument actions

export const tenderDocumentGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_DOCUMENT_GET_DETAILS));
    const response = await tenderService.tenderDocumentGetDetails(id);
    dispatch(finish(REFERENCE__TYPES.TENDER_DOCUMENT_GET_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_DOCUMENT_GET_DETAILS, response.data)
      );
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const tenderDocumentAdd = (body) => {
// 	return async (dispatch) => {
// 		dispatch(init(REFERENCE__TYPES.TENDER_DOCUMENT_ADD));
// 		const response = await tenderService.tenderDocumentAdd(body);
// 		dispatch(finish(REFERENCE__TYPES.TENDER_DOCUMENT_ADD));
// 		if (response.isSuccess) {
// 			dispatch(success(REFERENCE__TYPES.TENDER_DOCUMENT_ADD, response.data));
// 			message.success('Successfully added Tender');
// 		} else if (!response.isSuccess) {
// 			dispatch(error(response.errorMessage));
// 		}
// 	};
// };

export const tenderDocumentUpdate = (body, id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_DOCUMENT_UPDATE));
    const response = await tenderService.tenderDocumentUpdate(body, id);
    dispatch(finish(REFERENCE__TYPES.TENDER_DOCUMENT_UPDATE));
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.TENDER_DOCUMENT_UPDATE, response.data));
      message.success(`Successfully updated Document Purchase`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//tenderBasic Mutliple Document
export const documentFilesAddUpdate = (body, id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.DOCUMENTS_UPLOAD_INIT));
    const response = await tenderService.documentFilesAddUpdate(body, id);
    dispatch(finish(REFERENCE__TYPES.DOCUMENTS_UPLOAD_FINISH));
    if (response.isSuccess) {
      dispatch(
        success(response.data, REFERENCE__TYPES.DOCUMENTS_UPLOAD_SUCCESS)
      );
      message.success(`Successfully updated the documents`);
      dispatch(
        tenderGetBasicDetails({
          id: id,
        })
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//tenderParticipationgetdetails
export const tenderParticipationGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_PARTICIPATION_GET_DETAILS));
    const response = await tenderService.tenderParticipationDetails(id);
    dispatch(finish(REFERENCE__TYPES.TENDER_PARTICIPATION_GET_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.TENDER_PARTICIPATION_GET_DETAILS,
          response.data
        )
      );
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const tenderDocumentAdd = (body) => {
// 	return async (dispatch) => {
// 		dispatch(init(REFERENCE__TYPES.TENDER_DOCUMENT_ADD));
// 		const response = await tenderService.tenderDocumentAdd(body);
// 		dispatch(finish(REFERENCE__TYPES.TENDER_DOCUMENT_ADD));
// 		if (response.isSuccess) {
// 			dispatch(success(REFERENCE__TYPES.TENDER_DOCUMENT_ADD, response.data));
// 			message.success('Successfully added Tender');
// 		} else if (!response.isSuccess) {
// 			dispatch(error(response.errorMessage));
// 		}
// 	};
// };

// export const tenderParticipationUpdate = (body, id) => {
//   return async (dispatch) => {
//     dispatch(init(REFERENCE__TYPES.TENDER_PARTICIPATION_UPDATE));
//     const response = await tenderService.tenderParticipationUpdate(body, id);
//     dispatch(finish(REFERENCE__TYPES.TENDER_PARTICIPATION_UPDATE));
//     if (response.isSuccess) {
//       dispatch(
//         success(REFERENCE__TYPES.TENDER_PARTICIPATION_UPDATE, response.data)
//       );
//       message.success(`Successfully updated Participation `);
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

export const addStageDocumentPurchase = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_DOC));
    const response = await tenderService.stageLevelDocumentPurchase(body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_DOC));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_DOC, response.data)
      );

      message.success(`Successfully Added Document Purchase `);
      dispatch(tenderGetBasicDetails(body.tenderBasicId));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const tenderFavAddUpdate = (body, record) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_IS_FAV));
    const response = await tenderService.tenderFavAddUpdate(body);
    dispatch(finish(REFERENCE__TYPES.TENDER_IS_FAV));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_IS_FAV, response.data, "", record)
      );
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderAddExtensions = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_ADD_EXTENSION));
    const response = await tenderService.tenderBasicAddExtension(body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_ADD_EXTENSION));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_TYPE_ADD_EXTENSION, response.data)
      );
      message.success("Successfully added Extensions");

      dispatch(tenderextensionGetBasicDetails(response.data.tenderBasicId));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderAddExtensionUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_EXTENSION));
    const response = await tenderService.tenderBasicUpdateExtension(body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_EXTENSION));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_EXTENSION, response.data)
      );
      message.success(`Successfully updated Extensions `);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateStageDocumentPurchase = (body) => {
  // console.log();
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE));
    const response = await tenderService.stageDocumentPurchaseUpdate(body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE,
          response.data,
          "",
          body
        )
      );
      message.success(`Successfully updated Stages Doc `);
      dispatch(tenderGetBasicDetails(body.tenderBasicId));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateStagelevel = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_LEVEL));
    const response = await tenderService.stageLevelUpdate(body);
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_LEVEL,
          response.data
        )
      );
      message.success(`Successfully updated Stages `);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_LEVEL));
  };
};
export const tenderextensionGetBasicDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_EXTENSION_GET_DETAILS));
    const response = await tenderService.tenderBasicExtensionGetDetails(id);
    dispatch(finish(REFERENCE__TYPES.TENDER_EXTENSION_GET_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_EXTENSION_GET_DETAILS, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

//tenderStageExtension
export const tenderStageExtensionAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_EXTENSION));
    const response = await tenderService.tenderExtensionAddMutiple(body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_EXTENSION));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_TYPE_ADD_STAGE_EXTENSION, {
          ...response.data,
        })
      );
      message.success("Successfully added Extensions");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderStageExtensionUpdate = (body) => {
  console.log("I should be extension update phase");
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_EXTENSION));
    const response = await tenderService.tenderStageExtensionUpdate(body);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_EXTENSION));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_TYPE_UPDATE_STAGE_EXTENSION,
          response.data
        )
      );
      message.success("Successfully Updated Extensions");
      // dispatch(tenderextensionGetBasicDetails(response.data.tenderBasicId));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderStageGetDetails = (body) => {
  return async (dispatch) => {
    dispatch(
      init(REFERENCE__TYPES.REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS)
    );
    const response = await tenderService.tenderStageExtensionDetails(body);
    dispatch(
      finish(REFERENCE__TYPES.REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS)
    );
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_TYPE_EXTENSIONS_STAGE_GET_DETAILS,
          response.data
        )
      );

      // dispatch(tenderextensionGetBasicDetails(response.data.tenderBasicId));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// tender award status
export const tenderAwardStatusAdd = (body, id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_AWARD_STATUS_ADD));
    const response = await tenderService.tenderAwardStatusAdd(body);
    dispatch(finish(REFERENCE__TYPES.TENDER_AWARD_STATUS_ADD));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_AWARD_STATUS_ADD, response.data)
      );
      message.success("Successfully added award status");
      dispatch(tenderGetBasicDetails(id));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderAwardStatusDetails = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_AWARD_STATUS_DETAILS));
    const response = await tenderService.tenderAwardStatusGetDetails(body);
    dispatch(finish(REFERENCE__TYPES.TENDER_AWARD_STATUS_DETAILS));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_AWARD_STATUS_DETAILS, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderAwardStatusUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_AWARD_STATUS_UPDATE));
    const response = await tenderService.tenderAwardStatusUpdate(body);
    dispatch(finish(REFERENCE__TYPES.TENDER_AWARD_STATUS_UPDATE));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_AWARD_STATUS_UPDATE, response.data)
      );
      message.success("Successfully Updated award status");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const tenderStageExtensionDelete = (extensionId, tenderStageId) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.TENDER_STAGE_EXTENSION_DELETE));
    const response = await tenderService.tenderAwardStatusDelete(extensionId);
    if (response?.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.TENDER_STAGE_EXTENSION_DELETE, response?.data)
      );
      message.success("Successfully delete award status");
      dispatch(tenderStageGetDetails(tenderStageId));
    } else {
      dispatch(error(response?.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.TENDER_STAGE_EXTENSION_DELETE));
  };
};

export const dateFilteringTender = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.DATE_FILTERING_TENDER));
    const response = await tenderService.dateFilteringTender(body);
    if (response?.isSuccess) {
      dispatch(success(REFERENCE__TYPES.DATE_FILTERING_TENDER, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const allTendersGetFiles = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.ALL_TENDER_GET_DETAILS));
    const response = await tenderService.getAllTenderDetails(id);
    if (response?.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.ALL_TENDER_GET_DETAILS, response?.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// CIRCUR PLANNING TENDER CHECKLISt

export const actionForGetTenderCheckList = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_TENDER_CHECKLIST));
    const response = await tenderService.getTenderCheckList(id);
    if (response?.isSuccess) {
      dispatch(success(REFERENCE__TYPES.GET_TENDER_CHECKLIST, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// Checklist api
export const actionForAddUpdatedCircularChecklist = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.ADDUPDATE_CIRCULAR_CHEKLIST));
    const response = await tenderService.addUpdateCircularCheckList(body);
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.ADDUPDATE_CIRCULAR_CHEKLIST, response.data)
      );
      message.success("updated checklist");
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// TENDER MAIL APPROVE STATUS
export const actionForIsApproveStatusTender = (
  id,
  body,
  setInstanceMessageCenterListing
) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.ADD_ISAPPROVED_STATUS));
    const response = await tenderService.approvedStatusReferenceTemplateMail(
      id,
      body
    );
    if (response?.isSuccess) {
      dispatch(success(REFERENCE__TYPES.ADD_ISAPPROVED_STATUS, response.data));
      message.success("Updated Status");
      setInstanceMessageCenterListing?.((prevData) => {
        if (!isEmpty(prevData.items)) {
          return {
            items: [response?.data?.internalMessage, ...prevData.items],
            totalCount: prevData.totalCount + 1,
          };
        } else {
          return {
            items: [response?.data?.internalMessage],
            totalCount: 1,
          };
        }
      });
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// get: circular content
export const actionForGetReferenceTemplateGetDetailsById = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE));
    const response = await tenderService.getReferenceTemplateGetDetailsById(id);
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE, response.data)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// PERSIST CONTENT
export const actionForPersistenTenderContent = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE));
    const response = await tenderService.getReferenceTemplateGetDetailsById(id);
    try {
      if (response.isSuccess) {
        dispatch(
          success(
            REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE,
            response.data
          )
        );

        // PERSIST
        // console.log("ACTION: PERSIST TENDER CONTENT response api: ", id, response)
        dispatch(actionForPersistTenderMailContent(response.data));
      } else {
        dispatch(error(response?.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch (err) {
      dispatch(error(err.message || "An error occurred"));
      throw err;
    }
  };
};

// mail saving circular content
export const actionForMailUpdateReferenceTemplateById = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE));
    const response = await tenderService.getReferenceTemplateGetDetailsById(id);
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE, response.data)
      );
      console.log("ACTION response.data :", response.data);
      dispatch(actionForUpdateTemplate(response.data));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// addupdate: circular content actionForAddUpdateReferenceTemplate
export const actionForAddUpdateReferenceTemplate = (type, body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE));
    const response = await tenderService.addUpdateReferenceTemplateAddUpdate(
      body
    );
    try {
      if (response.isSuccess) {
        message.success("Saved Circular Content");
        dispatch(
          success(
            REFERENCE__TYPES.ADD_UPDATE_CIRCUAL_REFERENCE_TEMPLATE,
            response.data
          )
        );

        if (type === "mail") {
          dispatch(actionForUpdateTemplate(response.data));
        }
      } else {
        dispatch(error(response?.errorMessage));
        throw new Error(response.errorMessage);
      }
    } catch (err) {
      throw error;
    }
  };
};

// GET Approve Status of Circular Planning
export const actionForGetApprovedStatusCircularPlanning = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.GET_APPROVED_STATUS_CIRCULARPLANNING));
    const response =
      await tenderService.apiForGetApprovedStatusCircularPlanning(id);
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.GET_APPROVED_STATUS_CIRCULARPLANNING,
          response.data
        )
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// export
export const actionForUpdateTemplate = (data) => {
  return {
    type: REFERENCE__TYPES.GET_CIRCULAR_REFERENCE_TEMPLATE_UPDATE,
    payload: data,
  };
};
