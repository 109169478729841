import React, { useEffect, useState } from "react";
import moment from 'moment';
import { isEmpty } from "lodash-es";

// antd
import { Table, Tag } from "antd";

// utils
import { LogoImage } from "utils/imageConstants";

// redux
import { useDispatch, useSelector } from "react-redux";
import { getFiscalYearPagination } from "services/redux/admin/FiscalYear/action";

const FiscalYearTable = ({ showAddUpdateFiscalYear,
  fiscalStart,
  fiscalEnd,
}) => {
  const columns = [
    {
      title: "Enqueue Number",
      dataIndex: "enqueueNumber",
      key: "enqueueNumber",
    },
    {
      title: "Fiscal Start",
      dataIndex: "fiscalStart",
      key: "fiscalStart",
      render: (date) => {
				return <span>{moment(date).format('YYYY/MM/DD')}</span>;
			}
    },
    {
      title: "Fiscal End",
      dataIndex: "fiscalEnd",
      key: "fiscalEnd",
      render: (date) => {
				return <span>{moment(date).format('YYYY/MM/DD')}</span>;
			}
    },
		{
			title: 'Status',
			render: (tag) => {
				let color = '';
				let status = '';
				if (tag?.fiscalYearStatusDto?.systemName) {
					color = 'green';
					status = 'Active';
				} else {
					color = 'red';
					status = 'InActive';
				}
				return <Tag color={color}>{status.toUpperCase()}</Tag>;
			},
		},
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a className='action-btn action-btn-add' onClick={() => showAddUpdateFiscalYear(record)}>
          Edit Fiscal Year
        </a>
      ),
    },
  ];

  const dispatch = useDispatch();
  const {
    fiscalYearPagination,
    fiscalYearPaginationLoading,
  } = useSelector((state) => state.fiscalYearState);

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, [fiscalStart,fiscalEnd]);

  useEffect(() => {
    if (isEmpty(fiscalYearPagination?.items)) return;
    setdata(fiscalYearPagination.items);
    setTotalCount(fiscalYearPagination.totalCount);
  }, [fiscalYearPagination]);
  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SearchDateFrom = fiscalStart,
    SearchDateTo = fiscalEnd,
    SortType
  ) => {
    dispatch(
      getFiscalYearPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType,
        SearchDateFrom,
        SearchDateTo
      })
    );
  };
  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting = sorter.columnKey;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };


  return (
    <Table className="contact-table"
      columns={columns}
      dataSource={data}
      onChange={handleTableChange}
      loading={{
        indicator: (
          <img src={LogoImage} height="auto" width="50px" />
        ),
        spinning: fiscalYearPaginationLoading,
      }}
      pagination={{
        total: totalCount,
        pageSize: pageSize,
      }}
    />
  );
};

export default FiscalYearTable;