import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clone, cloneDeep, isEmpty, pickBy, upperCase } from "lodash";
import moment from "moment";
import LeaveDrawer from "./ChangePassword__drawer";
import { KeyOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import {
  Row,
  Image,
  Col,
  Button,
  Select,
  Input,
  Form,
  DatePicker,
  Skeleton,
  Checkbox,
} from "antd";
import {
  getGenders,
  getMaritalStatusList,
  employeeProfileDetail,
  employeeProfilePersonalUpdate,
} from "services/redux/profile/profile.action";
import ImageUploader from "../../components/shared/ImageUploader";
import { cqBaseUrl } from "../../utils/config";

const edit = "./assets/svg/profile-edit.svg";
const { Option } = Select;

const requiredRule = [
  {
    required: true,
    whitespace: true,
  },
];

const MainProfile = ({
  isFromDrawer,
  dynamicFileTypesState,
  updateUsers,
  showAddUserDrawer,
  location,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const [form] = Form.useForm();

  const [SelectButton, SetButton] = useState(""); //for button purpose mutiple
  const [visible, setvisible] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [isAnnivarsary, setIsAnnivarsary] = useState(false);
  const [allUploadedFilesDtos, setAllUploadedFilesDtos] = useState([]);
  const [employeeProfilePictureName, setEmployeeProfilePictureName] =
    useState("");
  const [getDetils, setDetails] = useState("");

  const {
    genders,
    maritalStatusList,
    profileInfo,
    updateProfile,
    updateProfileLoading,
  } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getGenders());
    dispatch(getMaritalStatusList());
    // !isFromDrawer && dispatch(employeeProfileDetail());
  }, [dispatch]);

  useEffect(() => {
    if (updateProfile) {
      setShowInfo(true);
    }
  }, [updateProfile]);

  // console.log("profileInfo", profileInfo);

  useEffect(() => {
    form.resetFields();
    if (!profileInfo) return;
    form.setFieldsValue({
      // anniversaryDate = moment(employeePersonalDetailUpdateDto.isAnnivarsary),
      ...profileInfo.employeePersonalDetailUpdateDto,
      anniversaryDate: profileInfo.employeePersonalDetailUpdateDto
        .anniversaryDate
        ? moment(profileInfo.employeePersonalDetailUpdateDto.anniversaryDate)
        : "",
      dateOfBirth: profileInfo.employeePersonalDetailUpdateDto.dateOfBirth
        ? moment(profileInfo.employeePersonalDetailUpdateDto.dateOfBirth)
        : "",
      isActive: profileInfo.employeePersonalDetailUpdateDto?.isActive,
    });
    setAllUploadedFilesDtos(
      cloneDeep(profileInfo?.employeePersonalDetailUpdateDto.uploadedFileDtos)
    );
    if (profileInfo.employeePersonalDetailUpdateDto?.employeeName) {
      let name = profileInfo.employeePersonalDetailUpdateDto.employeeName;
      // console.log("namedin", name);
      let profilePicNameFL = name.match(/\b(\w)/g);
      setEmployeeProfilePictureName(upperCase(profilePicNameFL.join("")));
    }
  }, [profileInfo]);

  const onFinish = (values) => {
    if (profileInfo?.employeePersonalDetailUpdateDto.appUserId) {
      let val = {
        ...profileInfo.employeePersonalDetailUpdateDto,
        ...values,
      };
      val = pickBy(val, (v) => !!v);
      if (!values.uploadedFileDtos[0]?.viewFileURL) {
        delete val["uploadedFileDtos"];
      }

      dispatch(employeeProfilePersonalUpdate(val));
    }
  };

  const validateMessages = {
    required: "${label} is required!",
    whitespace: "${label} can not be empty!",
    types: {
      email: "${label} is not valid!",
    },
  };

  const onSelect = (name) => {
    SetButton(name);
    setvisible(true);
  };

  //function triggered when button is clicked for drawer
  const ClickedButton = ({ name }) => {
    return (
      <Button onClick={() => onSelect(name)}>
        <KeyOutlined />
      </Button>
    );
  };

  const onClose = () => setvisible(false);

  const renderNameOrNA = (name) => name || "";

  const renderProfileInfo = () => {
    // if (!profileInfo?.employeePersonalDetailUpdateDto) {
    // 	return <Skeleton avatar paragraph={{ rows: 4 }} />;
    // }

    return (
      <div className="profile__info profile__wrap">
        <div className="profile__info--wrapper">
          <div className="profile__info--headerbutton mail-profile-header">
            <Button type="link" onClick={() => setShowInfo(false)}>
              <img src={edit} />
            </Button>
            <ClickedButton
              className="changeUserpassword"
              name="ChangePassword"
            />
            {location.pathname !== "/profile" && (
              <div
                className="edit-user-profile"
                onClick={() => {
                  showAddUserDrawer(
                    profileInfo?.employeePersonalDetailUpdateDto.appUserId
                  );
                }}
              >
                {" "}
                <UserSwitchOutlined />
              </div>
            )}
          </div>
          <div className="profile__info--top">
            <div className="profileinfo__image">
              {!isEmpty(allUploadedFilesDtos) ? (
                allUploadedFilesDtos.map(
                  //For accessing image
                  (imagefile) =>
                    imagefile?.isActive && (
                      <Image
                        src={cqBaseUrl + "/" + imagefile?.viewFileURL + token}
                      />
                    )
                )
              ) : (
                <div className="default-profile-name">
                  {employeeProfilePictureName}
                </div>
              )}
            </div>
            <div className="profile__info--toptext">
              <h4>
                {renderNameOrNA(
                  profileInfo?.employeePersonalDetailUpdateDto.employeeName
                )}
              </h4>
            </div>
            <div className="profile__info--toptext"></div>
          </div>
        </div>
        <LeaveDrawer
          Button={SelectButton}
          visible={visible}
          onClose={onClose}
          updateUsers={updateUsers}
        />

        <div className="profile__info--body">
          <Row gutter={[8, 0]}>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Gender</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto
                        .genderSystemName
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Marital Status</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto
                        .maritalStatusSystemName
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Spouse Name</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto.spouseName
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Spouse Profession</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto
                        .spouseProfession
                    )}
                  </span>
                </div>
              </div>
            </Col>

            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Children Name</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto.childrenNames
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Current Address</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto
                        .currentAddress
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Phone</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto.phoneMobile
                    )}
                  </span>
                </div>
              </div>
            </Col>

            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Phone Residence</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto
                        .phoneResidence
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Hobbies</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto.hobbies
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Allergies</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {renderNameOrNA(
                      profileInfo?.employeePersonalDetailUpdateDto.allergy
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Anniversary Date</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {profileInfo?.employeePersonalDetailUpdateDto
                      .anniversaryDate
                      ? renderNameOrNA(
                          moment(
                            profileInfo?.employeePersonalDetailUpdateDto
                              .anniversaryDate
                          ).format("YYYY/MM/DD")
                        )
                      : "YYYY/MM/DD"}
                  </span>
                </div>
              </div>
            </Col>
            <Col span="24">
              <div className="profile__row">
                <div className="profile__row--label">
                  <span className="profile__bodyTitle">Date of Birth</span>
                </div>

                <div className="profile__row--data">
                  <span className="profile__bodyInfo">
                    {profileInfo?.employeePersonalDetailUpdateDto.dateOfBirth
                      ? renderNameOrNA(
                          moment(
                            profileInfo?.employeePersonalDetailUpdateDto
                              .dateOfBirth
                          ).format("YYYY/MM/DD")
                        )
                      : ""}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const renderProfileUpdate = () => {
    if (!profileInfo?.employeePersonalDetailUpdateDto) {
      return <Skeleton paragraph={{ rows: 10 }} />;
    }
    return (
      <div className="profile__info profile__wrap">
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <LeaveDrawer
            Button={SelectButton}
            visible={visible}
            onClose={onClose}
          />
          <div className="profile__info--wrapper">
            <div className="profile__info--top">
              <div className="profile__info--image">
                {dynamicFileTypesState.map((u, i) => (
                  <Form.Item
                    name={["uploadedFileDtos"]}
                    rules={[{ required: false }]}
                  >
                    <ImageUploader
                      name={u.name}
                      isMultipleFileAllowed={u.isMultipleFileAllowed}
                      id={u.id}
                      // fileList={fileList}
                      // setFileList={setFileList}
                      index={i}
                      uploadedFileDtos={form.getFieldValue()}
                      form={form}
                      callback={(e) => {
                        let olduploadedFileDtos =
                          cloneDeep(allUploadedFilesDtos);

                        let uploadedFileDtos = olduploadedFileDtos.map(
                          (file) => {
                            return {
                              ...file,
                              isActive: false,
                            };
                          }
                        );
                        uploadedFileDtos.unshift(e);

                        form.setFieldsValue({
                          uploadedFileDtos,
                        });
                        // form.setFieldsValue({
                        //   uploadedFileDtos: [
                        //     ...olduploadedFileDtos,
                        //     e
                        //   ]
                        //     })
                      }}
                    />
                  </Form.Item>
                ))}
              </div>
              {/* <Image src="https://randomuser.me/api/portraits/men/64.jpg" /> */}
              <div>
                <div className="profile__info--toptext">
                  <Form.Item
                    name="employeeName"
                    rules={[
                      {
                        required: true,
                        message: "Employee Name is required",
                      },
                    ]}
                  >
                    <Input placeholder="Employee Name" />
                  </Form.Item>
                  {/* <ClickedButton name="ChangePassword" /> */}
                </div>
                <div></div>
                <div className="profile__info--toptext"></div>
              </div>
            </div>
          </div>
          <div className="profile__info--body">
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Gender</span>
                  </div>

                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="genderSystemName"
                      rules={[
                        {
                          required: false,
                          message: "Gender is required",
                        },
                      ]}
                    >
                      <Select
                        loading={!genders?.length}
                        disabled={!genders?.length}
                      >
                        {genders?.map((gender, i) => (
                          <Option value={gender.systemName} key={i}>
                            {gender.displayName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={24} md={12} xs={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Marital Status</span>
                  </div>

                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="maritalStatusSystemName"
                      rules={[
                        {
                          required: false,
                          message: "Marital Status is required",
                        },
                      ]}
                    >
                      <Select
                        loading={!maritalStatusList?.length}
                        disabled={!maritalStatusList?.length}
                      >
                        {maritalStatusList?.map((status, i) => (
                          <Option value={status.systemName} key={i}>
                            {status.displayName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={24} md={12} xs={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Blood Group</span>
                  </div>

                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="bloodGroup"
                      rules={[
                        {
                          required: false,
                          message: "Blood Group is required",
                        },
                      ]}
                    >
                      <Select placeholder="Blood Group">
                        <Option value="A+">A+</Option>
                        <Option value="A-">A-</Option>
                        <Option value="B+">B+</Option>
                        <Option value="B-">B-</Option>
                        <Option value="O+">O+</Option>
                        <Option value="O-">O-</Option>
                        <Option value="AB+">AB+</Option>
                        <Option value="AB-">AB-</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col lg={24} md={12} xs={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Spouse Name</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="spouseName"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Spouse Name" />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col lg={24} md={24} xs={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">
                      Spouse Profession
                    </span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="spouseProfession"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Spouse Profession" />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={24} md={24} xs={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Children Name</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="childrenNames"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Children Name" />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col lg={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Current Address</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="currentAddress"
                      rules={[
                        {
                          required: false,
                          message: "Current Address is required",
                        },
                      ]}
                    >
                      <Input placeholder="Current Address" />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">
                      Permanent Address
                    </span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="permanentAddress"
                      rules={[
                        {
                          required: false,
                          message: "Permanent Address is required",
                        },
                      ]}
                    >
                      <Input placeholder="Permanent Address" />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col lg={24} md={12} xs={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Phone</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="phoneMobile"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Input placeholder="Phone" type="number" />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={24} md={24} xs={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Phone Residence</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item name="phoneResidence">
                      <Input placeholder="Phone Residence" type="number" />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Hobbies</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="hobbies"
                      rules={[
                        {
                          required: false,
                          message: "Hobbies are required",
                        },
                      ]}
                    >
                      <Input placeholder="Hobbies" />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Allergies</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="allergy"
                      rules={[
                        {
                          required: false,
                          message: "Allergies are required",
                        },
                      ]}
                    >
                      <Input placeholder="Allergies" />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col lg={24} md={24} xs={24} style={{ marginBottom: 10 }}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Anniversary Date</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={isAnnivarsary}
                        onChange={() =>
                          setIsAnnivarsary((prevState) => !prevState)
                        }
                      ></Checkbox>

                      <Form.Item
                        name="anniversaryDate"
                        rules={[
                          {
                            required: false,
                            message: "DOB is required",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "188px" }}
                          disabled={!isAnnivarsary}
                          format="YYYY/MM/DD"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={24} md={24} xs={24}>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">Date of Birth</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <Form.Item
                      name="dateOfBirth"
                      rules={[
                        {
                          required: false,
                          message: "DOB is required",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format="YYYY/MM/DD"
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="profile__rowedit">
                  <div className="profile__rowedit--label">
                    <span className="profile__bodyTitle">IsActive</span>
                  </div>
                  <div className="profile__rowedit--data">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    ></div>
                    <div className="drawer-form-group">
                      <Form.Item
                        name="isActive"
                        valuePropName="checked"
                        initialValue={false}
                      >
                        <Checkbox></Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="profile__info--footer">
            <Button
              type="link"
              className="btn-cancel"
              onClick={() => {
                setShowInfo(true);
                form.setFieldsValue({
                  uploadedFileDtos: [...allUploadedFilesDtos],
                });
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-update"
              type="link"
              htmlType="submit"
              loading={updateProfileLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    );
  };

  return <>{showInfo ? renderProfileInfo() : renderProfileUpdate()}</>;
};

export default MainProfile;
