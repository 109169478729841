import { COMMON_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { EmployeeSisterCompanyService } from "./api";

const employeeSisterCompanyService = new EmployeeSisterCompanyService();

export const getEmployeeSisterCompanyListing = (type) => {
  return async (dispatch) => {
    dispatch(init(COMMON_TYPES.EMPLOYEE_SISTER_COMPANY_LISTING));
    const response = await employeeSisterCompanyService.getEmployeeSisterCompanyListing(type);
    dispatch(finish(COMMON_TYPES.EMPLOYEE_SISTER_COMPANY_LISTING));
    if (response.isSuccess) {
      dispatch(success(COMMON_TYPES.EMPLOYEE_SISTER_COMPANY_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};