import { Modal, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getdashboardIncomeResponse,
  getdashboardLedgerResponse,
  getdashboardCashFlowResponse,
  getdashboardBudgetExpenseResponse
} from "services/redux/admin/FiscalYear/action";

const IncomeModal = ({ modalVisible, setModalVisible, modalData, type }) => {
  const dispatch = useDispatch();

  const {
    accountIncomeResponse,
    accountIncomeLoading,
    accountLedgerResponse,
    accountLedgerLoading,
    accountCashFlowResponse,
    accountCashFlowLoading,
    accountBudgetExpenseResponse,
    accountBudgetExpenseLoading
  } = useSelector(
    (state) => state.fiscalYearState
  );

  useEffect(() => {
    const body = {
      theBranchis: modalData.query,
      dTypeIncome: modalData.dTypeIncome,
    };

    if (type == 'dlreport') {
      let dlbody = {
        theBranchis: modalData.query,
        dTypeIncome: modalData.dTypeIncome,
        dExtaQuery: modalData?.queryTypeFormula,
        // [modalData.queryTypeParam]: modalData?.queryTypeFormula,
      }
      dispatch(getdashboardLedgerResponse(dlbody));
    }

    else if (type === 'cashflow') {
      let cashFlowbody = {
        theBranchis: modalData.query,
        dTypeIncome: modalData.dTypeIncome,
        dExtaQuery: modalData?.queryTypeFormula,
      }
      dispatch(getdashboardCashFlowResponse(cashFlowbody));
    }

    else if (type === 'bank' && modalData?.colType === 'accountBudgetExpense') {
      dispatch(getdashboardBudgetExpenseResponse(body));
    }

    else
      dispatch(getdashboardIncomeResponse(body));
  }, []);

  const showNumber = (number) => {
    if (number === 0) {
      return `--`;
    } else if (number < 0) {
      return (
        <Typography.Text style={{ color: "red" }}>
          ({Math.abs(Math.trunc(number))?.toLocaleString()} )
        </Typography.Text>
      );
    } else {
      return (
        <Typography.Text>
          {Math.trunc(number)?.toLocaleString()}
        </Typography.Text>
      );
    }
  };

  const bankStatuscolumns = [
    {
      title: "S.N",
      key: "serialNumber",
      dataIndex: "serialNumber",
      render: (_, record, index) => index + 1,
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "left" }}>
          Income head
        </Typography.Paragraph>
      ),
      key: "incomeHead",
      dataIndex: "incomeHead",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.incomeHead === "Total"
                ? { fontWeight: "bold", textAlign: "left" }
                : { textAlign: "left" }
            }
          >
            {record?.incomeHead}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right" }}>
          NPR Amount
        </Typography.Paragraph>
      ),
      key: "nprAmount",
      align: "right",
      dataIndex: "nprAmount",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.incomeHead === "Total"
                ? { fontWeight: "bold", textAlign: "right" }
                : { textAlign: "right" }
            }
          >
            {showNumber(record?.nprAmount)}
          </Typography.Paragraph>
        );
      },
    },
    // {
    //   title: (
    //     <Typography.Paragraph style={{ textAlign: "right" }}>
    //       FC Rate
    //     </Typography.Paragraph>
    //   ),
    //   key: "fcRate",
    //   align: "right",
    //   dataIndex: "fcRate",
    //   render: (_, record) => {
    //     return (
    //       <Typography.Paragraph
    //         style={
    //           record?.incomeHead === "Total"
    //             ? { fontWeight: "bold", textAlign: "right" }
    //             : { textAlign: "right" }
    //         }
    //       >
    //         {showNumber(record?.fcRate)}
    //       </Typography.Paragraph>
    //     );
    //   },
    // },
    // {
    //   title: (
    //     <Typography.Paragraph style={{ textAlign: "right" }}>
    //       FC Amount
    //     </Typography.Paragraph>
    //   ),
    //   key: "fcamount",
    //   dataIndex: "fcamount",
    //   align: "right",
    //   render: (_, record) => {
    //     return (
    //       <Typography.Paragraph
    //         style={
    //           record?.incomeHead === "Total"
    //             ? { fontWeight: "bold", textAlign: "right" }
    //             : { textAlign: "right" }
    //         }
    //       >
    //         {showNumber(record?.fcamount)}
    //       </Typography.Paragraph>
    //     );
    //   },
    // },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right" }}>
          Description
        </Typography.Paragraph>
      ),
      key: "description",
      dataIndex: "description",
      width: 300,
      render: (_, record) => (
        <Typography
          variant="body1"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2 /* number of lines to show */,
            WebkitBoxOrient: "vertical",
            textAlign: "right",
          }}
        >
          {record?.description}
        </Typography>
      ),
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "center" }}>
          Updated Date
        </Typography.Paragraph>
      ),
      key: "dateSn",
      dataIndex: "dateSn",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {record?.dateSn ? moment(record?.dateSn)?.format("YYYY MMM DD") : null}
          </Typography.Paragraph>
        );
      },
    },
  ];

  const commonColumns = [
    {
      title: "S.N",
      key: "sn",
      dataIndex: "sn",
      render: (_, record, index) => index + 1,
    },
    // {
    //   title: "Income Head",
    //   dataIndex: "accountHead",
    //   key: "accountHead",
    //   render: (_, record) => {
    //     return (
    //       <Typography.Text
    //         style={
    //           record?.incomeHead === "Total" ? { fontWeight: "bold" } : {}
    //         }
    //       >
    //         {record?.incomeHead}
    //       </Typography.Text>
    //     );
    //   },
    // },
  ];

  const dlReportColumns = [
    {
      title: "Income Head",
      dataIndex: "accountHead",
      key: "accountHead",
      render: (_, record) => {
        return (
          <Typography.Text
            style={
              record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
            }
          >
            {record?.accountHead}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right" }}>
          cr Amount
        </Typography.Paragraph>
      ),
      key: "crAmount",
      align: "right",
      dataIndex: "crAmount",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.incomeHead === "Total"
                ? { fontWeight: "bold", textAlign: "right" }
                : { textAlign: "right" }
            }
          >
            {showNumber(record?.crAmount)}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right" }}>
          dr Amount
        </Typography.Paragraph>
      ),
      key: "drAmount",
      align: "right",
      dataIndex: "drAmount",
      render: (_, record) => {
        return (
          <Typography.Paragraph
            style={
              record?.incomeHead === "Total"
                ? { fontWeight: "bold", textAlign: "right" }
                : { textAlign: "right" }
            }
          >
            {showNumber(record?.drAmount)}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right" }}>
          Description
        </Typography.Paragraph>
      ),
      key: "description",
      dataIndex: "description",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {record?.description}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "center" }}>
          Date
        </Typography.Paragraph>
      ),
      key: "date",
      dataIndex: "date",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {record?.date ? moment(record?.date)?.format("YYYY MMM DD") : null}
          </Typography.Paragraph>
        );
      },
    },
  ]

  const cashFlowColumn = [
    {
      title: "Income Head",
      dataIndex: "accountHead",
      key: "accountHead",
      render: (_, record) => {
        return (
          <Typography.Text
            style={
              record?.accountHead === "Total" ? { fontWeight: "bold" } : {}
            }
          >
            {record?.accountHead}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "center" }}>
          Opening
        </Typography.Paragraph>
      ),
      key: "opening",
      dataIndex: "opening",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {record?.opening}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "center" }}>
          Debit
        </Typography.Paragraph>
      ),
      key: "dateSn",
      dataIndex: "dateSn",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {showNumber(record?.debit)}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "center" }}>
          Credit
        </Typography.Paragraph>
      ),
      key: "dateSn",
      dataIndex: "dateSn",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {showNumber(record?.credit)}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "center" }}>
          Closing
        </Typography.Paragraph>
      ),
      key: "dateSn",
      dataIndex: "dateSn",
      render: (_, record) => {
        return (
          <Typography.Paragraph style={{ textAlign: "center" }}>
            {showNumber(record?.closing)}
          </Typography.Paragraph>
        );
      },
    },
  ]

  const serialNumber = accountIncomeResponse && accountIncomeResponse?.reduce(
    (acc, curr) => acc + (curr?.serialNumber || 0),
    0
  );
  const nprAmount = accountIncomeResponse && accountIncomeResponse?.reduce(
    (acc, curr) => acc + (curr?.nprAmount || 0),
    0
  );

  const fcRate = accountIncomeResponse && accountIncomeResponse?.reduce(
    (acc, curr) => acc + (curr?.fcRate || 0),
    0
  );

  const fcamount = accountIncomeResponse && accountIncomeResponse?.reduce(
    (acc, curr) => acc + (curr?.fcamount || 0),
    0
  );

  const totalRow = {
    key: "Total",
    incomeHead: "Total",
    serialNumber: serialNumber,
    nprAmount: nprAmount,
    fcRate: fcRate,
    fcamount: fcamount,
  };

  const dataSourceIncome = [...(accountIncomeResponse || []), totalRow];

  // DL Report
  const dlSerialNumber = accountLedgerResponse && accountLedgerResponse?.reduce(
    (acc, curr) => acc + (curr?.serialNumber || 0),
    0
  );

  const dlNprAmount = accountLedgerResponse && accountLedgerResponse?.reduce(
    (acc, curr) => acc + (curr?.drAmount || 0),
    0
  );

  const dlCrAmount = accountLedgerResponse && accountLedgerResponse?.reduce(
    (acc, curr) => acc + (curr?.crAmount || 0),
    0
  );

  const totalRowDl = {
    key: "Total",
    serialNumber: dlSerialNumber,
    accountHead: "Total",
    drAmount: dlNprAmount,
    crAmount: dlCrAmount,
  };

  const dataSourceDl = [...(accountLedgerResponse || []), totalRowDl]

  // CashFlow
  const cfOpeningAmount = accountCashFlowResponse && accountCashFlowResponse?.reduce(
    (acc, curr) => acc + (curr?.opening || 0),
    0
  );

  const cfDebitAmount = accountCashFlowResponse && accountCashFlowResponse?.reduce(
    (acc, curr) => acc + (curr?.debit || 0),
    0
  );

  const cfCreditAmount = accountCashFlowResponse && accountCashFlowResponse?.reduce(
    (acc, curr) => acc + (curr?.credit || 0),
    0
  );

  const cfClosingAmount = accountCashFlowResponse && accountCashFlowResponse?.reduce(
    (acc, curr) => acc + (curr?.closing || 0),
    0
  );

  const totalCashFlow = {
    key: "Total",
    serialNumber: dlSerialNumber,
    accountHead: "Total",
    opening: cfOpeningAmount,
    debit: cfDebitAmount,
    credit: cfCreditAmount,
    closing: cfClosingAmount,
  }

  const dataSourceCashFlow = [...(accountCashFlowResponse || []), totalCashFlow];


  const columns = type === "bank"
    ? [...bankStatuscolumns]
    : type === "dlreport"
      ? [...commonColumns, ...dlReportColumns]
      : type === "cashflow"
        ? [...commonColumns, ...cashFlowColumn]
        : null;

  // const dataSource = type === "dlreport" ? dataSourceDl : dataSourceIncome;

  const dataSource = type === "dlreport"
    ? dataSourceDl
    : type === "cashflow"
      ? dataSourceCashFlow : dataSourceIncome;



  return (
    <>
      <Modal
        visible={modalVisible}
        // title="Summary of Income ${modalData?.colTitle ? modalData?.colTitle} : null}`"
        title={`Summary of  ${modalData?.colTitle || 'Income'}`}
        onCancel={() => setModalVisible({ income: false })}
        footer={null}
        width={1000}
      >
        <Table
          className="next-organizer-table"
          dataSource={dataSource}
          columns={columns}
          loading={accountIncomeLoading}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default IncomeModal;
