import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Upload, Modal, message, Button, Progress, Space, Card } from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { uploadFile } from "../../services/remanentCalls";
import { appBaseUrl, cqBaseUrl } from "../../utils/config";

import { cloneDeep } from "lodash";
import pdfViewer from "components/Documents/ViewDocument";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
// const fileLabelTypeId = "34ffc957-b591-4ca7-b2d1-8dbae3225769";
const DynamicImageUploader = ({
  name,
  deleteImageById,
  isImageRemoved,
  isMultipleFileAllowed,
  id, 
  callback,
  form,
  index,
  allowedExtensions,
  // fileList,
  // setFileList
  setAdDocs,
  adDocs,
  category,
}) => {
  const { token } = useSelector((state) => state.auth);
  // console.log(form.getFieldValue()[`${name}FileDtos`]  &&  form.getFieldValue()[`${name}FileDtos`][index])

  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });
  const [fileList, setFileList] = useState([
    // {
    //     uid: '-1',
    //     name: 'image.png',
    //     status: 'done',
    //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ]);
  const [progress, setProgress] = useState(0);
  const [showViewer, setShowViewer] = useState(false);
  const uploadedFileDtos = form.getFieldValue()[`${name}FileDtos`];

  useEffect(() => {
    if (uploadedFileDtos && uploadedFileDtos.length) {
      // if (isMultipleFileAllowed) {
      let fileLists = uploadedFileDtos
        .filter((list) => list.isActive)
        .map((list, index) => ({
          uid: index,
          name: list?.displayFileName,
          status: "done",
          fileInformationId: list?.fileInformationId,
          url: `${cqBaseUrl}/${list?.viewFileURL}${token}`,
        }));
      setFileList(fileLists);
      // }
      // else if (uploadedFileDtos[0]?.displayFileName && uploadedFileDtos[0]?.isActive) {
      //   setFileList([
      //     {
      //       uid: 0,
      //       name: uploadedFileDtos[0]?.displayFileName,
      //       fileName: uploadedFileDtos[0]?.fileName,
      //       status: "done",
      //       url: `${cqBaseUrl}/${uploadedFileDtos[0]?.viewFileURL}${token}`
      //     }
      //   ])
      // }
      // else { }
    }
  }, [uploadedFileDtos]);

  const handleView = (src) => {
    setShowViewer(true);
    setTimeout(() => {
      // console.log("pdfViewer", pdfViewer);
      pdfViewer1(src);
    }, 1000);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleCancel = () => {
    setPreview({ ...preview, previewVisible: false });
    setShowViewer(false);
  };

  const onChange = ({ file, fileList: newFileList, event }) => {
    if (file.status === "done") {
      // console.log({newFileList})
      // let newUrl = file?.response.data[0].viewFileURL
      // console.log({newUrl})
      // setFileList(newFileList);
      let updatedInfo = form.getFieldValue()[`${name}FileDtos`];

      let newUpdatedInfo = updatedInfo.map((newFile, i) => {
        if (newFile.displayFileName === file.name) {
          return {
            ...newFile,
            isActive: true,
          };
        } else {
          return {
            ...newFile,
          };
        }
      });

      form.setFieldsValue({
        [`${name}FileDtos`]: newUpdatedInfo,
      });
    }

    // setFileList(newFileList);
  };
  const onPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    // const isPdf = file.type === "application/pdf";
    // if (!isJpgOrPng && !isPdf) {
    //   message.error("You can only upload JPG/PNG file!");
    //   return false;
    // }
    const isOverSize = file.size > 2480 * 3230;
    if (isOverSize) {
      message.error("Image must smaller than 2480 * 32308!");
      return false;
    }
    return true;
  };

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    // console.log("hey progress", onProgress);
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgress,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
      setFileList([]);
    });
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      callback(alteredResponse);
      onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  return (
    <>
      <Upload
        accept={allowedExtensions}
        action={`${appBaseUrl}/file/uploadFile/${id}`}
        customRequest={uploadPhotos}
        listType={
          name !== "Profile" && name !== "ProfilePicture" ? "" : "picture-card"
        }
        multiple={isMultipleFileAllowed}
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        onRemove={async (file) => {
          let updatedInfo = form.getFieldValue()[`${name}FileDtos`];
          const addDocs = adDocs?.map((x) => {
            if (x?.fileInformationId == file?.fileInformationId) {
              return {
                ...x,
                isActive: false,
              };
            } else {
              return {
                ...x,
              };
            }
          });

          if (category === "adDocs") {
            setAdDocs(addDocs);
          }

          updatedInfo.splice(file.uid, 1);

          let newfileLists = updatedInfo.map((list, index) => ({
            uid: index,
            name: list?.displayFileName,
            status: "done",
            fileInformationId: list?.fileInformationId,
            url: `${cqBaseUrl}/${list?.viewFileURL}${token}`,
          }));
          setFileList(newfileLists);
          form.setFieldsValue({
            [`${name}FileDtos`]: updatedInfo,
          });
          return false;
          // return await deleteImageById(user?._id, file.response.data[0]._id)
          // return isImageRemoved
        }}
        progress={{
          strokeColor: {
            "0%": "#108ee9",
            "100%": "#87d068",
          },
          strokeWidth: 3,
          format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
        }}
        name="uploadedFiles"
      >
        {progress > 0 ? <Progress percent={progress} /> : null}
        {!isMultipleFileAllowed && fileList?.length === 0 && (
          <Button icon={<PlusOutlined />}> + add files</Button>
        )}
        {isMultipleFileAllowed && (
          <Button icon={<PlusOutlined />}>+add files</Button>
        )}
      </Upload>
      <Modal
        visible={preview.previewVisible}
        title={preview.previewTitle}
        footer={null}
        onCancel={handleCancel}
        width="70%"
      >
        {name === "Profile" || name === "ProfilePicture" ? (
          <img
            alt="alt"
            style={{ width: "100%" }}
            src={preview.previewImage}
            // onError={() => {
            //   let altmodalDiv = document.getElementById("altmodal");
            //   altmodalDiv.innerHTML = `
            //     <a href="${cqBaseUrl}/${fileList[0].response.viewFileURL}${token} download">Download</a>
            //     `;
            // }}
          />
        ) : (
          <>
            {/* <a
              // href={`${cqBaseUrl}/${fileList[0]?.viewFileURL}${token} download`}
              href={fileList[0]?.url}
              target="_blank"
              rel="noopener noreferrer"
            > */}{" "}
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  pdfViewer(fileList[0]?.url);
                }}
              >
                View in New Tab
              </Button>
              <Button onClick={() => handleView(fileList[0]?.url)}>
                View Here
              </Button>
            </Space>
            {showViewer && (
              <iframe id="pdfViewerFrame" width="100%" height="300px" />
            )}
            {/* </a> */}
          </>
          // <a href={`${cqBaseUrl}/${fileList[0].response.viewFileURL}${token} download`}>Download</a>
        )}
      </Modal>
    </>
  );
};

DynamicImageUploader.propTypes = {
  user: PropTypes.object,
  // uploadFile:PropTypes.func,
  // isImageRemoved: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  // isImageRemoved: state.product.isImageRemoved
});

const mapDispatchToProps = {
  // uploadFile,
  // deleteImageById
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicImageUploader);
