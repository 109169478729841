import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Tabs, Form } from "antd";
import { withBaseLayout } from "../../layouts/Base";
import MainProfile from "./MainProfile";
import OfficialInformation from "./_OfficialInformation";
import FirstPointContact from "./_FirstPointContact";
import Documents from "./_Documents";
import { useDispatch, useSelector } from "react-redux";
import { getDynamicFileLabelTypeUploaderDetail } from "../../services/redux/common/dynamicFileType/action";
import { exitInteviewGet } from "services/redux/profile/profile.action";
import EmailChangePassWord from "./EmailPassword";
import {
  clearanceFormList,
  employeeProfileDetail,
} from "services/redux/profile/profile.action";
import storage from "../../utils/storage";
import AddUserDrawer from "views/Admin/UserList/AddUserDrawer";
import { isEmpty } from "lodash";
import EmailShare from "./EmailShare";
import ClearanceTemplateAddUpdateForm from "./ClearanceTemplateAddUpdateForm";
import ExitInterviewTable from "./ExitInterviewTable";
import ClearanceTemplate from "./ClearanceTemplate";
import ReviewTemplate from "./ReviewTemplate";
import LeaveTemplate from "./LeaveTemplate";

const { TabPane } = Tabs;

const Profile = ({ employeeId }) => {
  return (
    <div className="profile profile-wrapper">
      <div className="profile_header">
        <div className="header__wrapper">
          <div className="header__main">
            <h1 className="header__main--text">My Profile</h1>
          </div>
        </div>
      </div>
      <AllProfileInfos userEmployeeId={employeeId} />
    </div>
  );
};

export const AllProfileInfos = ({
  isFromDrawer,
  updateUsers,
  userEmployeeId,
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [isUpdateCredentials, setIsUpdateCredentials] = useState("");
  const [addUserDrawerVisibility, setAddUserDrawerVisibility] = useState(false);
  const [isExitFormStatus, setIsExitFormStatus] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const dynamicFileTypesState = useSelector(
    (state) => state.dynamicFileLabelTypes
  );
  const { profileInfo } = useSelector((state) => state.profile);
  let localStorageId = storage.getItem("userId");

  const selectedReferenceType = {
    referenceCode: localStorageId,
    type: "Profile",
  };

  useEffect(() => {
    if (!isEmpty(isUpdateCredentials)) {
      setIsExitFormStatus(
        isUpdateCredentials?.employeeOfficialDetailUpdateDto?.isExitForm
      );
    }
  }, [isUpdateCredentials?.employeeOfficialDetailUpdateDto?.isExitForm]);

  useEffect(() => {
    if (!isEmpty(profileInfo)) {
      setIsUpdateCredentials(profileInfo);
    }
  }, [profileInfo]);

  useEffect(() => {
    if (userEmployeeId) {
      dispatch(clearanceFormList(userEmployeeId));
    }
  }, [dispatch, userEmployeeId]);

  useEffect(() => {
    if (userEmployeeId) {
      dispatch(exitInteviewGet(userEmployeeId));
    }
  }, [dispatch, userEmployeeId]);

  const { dynamicFileLabelTypes } = dynamicFileTypesState;

  useEffect(() => {
    dispatch(getDynamicFileLabelTypeUploaderDetail(selectedReferenceType));
  }, []);

  const showAddUserDrawer = (userId) => {
    if (userId) {
      if (userId) {
        dispatch(
          employeeProfileDetail({
            AppUserId: userId,
          })
        );
      }
    }
    setAddUserDrawerVisibility(true);
  };

  const onAddUserClose = () => {
    setAddUserDrawerVisibility(false);
    setIsUpdateCredentials("");
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/profile") {
      dispatch(
        employeeProfileDetail({
          AppUserId: localStorageId,
        })
      );
    }
  }, []);

  return (
    <div className="profile__wrapper">
      <MainProfile
        isFromDrawer={isFromDrawer}
        updateUsers={updateUsers}
        dynamicFileTypesState={dynamicFileLabelTypes?.filter(
          (obj) => obj.name === "ProfilePicture"
        )}
        onAddUserClose={onAddUserClose}
        showAddUserDrawer={showAddUserDrawer}
        location={location}
      />
      <div
        className="profile__details profile__wrap"
        style={{ overflow: "auto" }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Official Information" key="1">
            <OfficialInformation />
          </TabPane>
          <TabPane tab="First Point Contact" key="2">
            <FirstPointContact />
          </TabPane>
          <TabPane tab="Documents" key="3">
            <Documents
              dynamicFileTypesState={dynamicFileLabelTypes?.filter(
                (obj) => obj.name !== "ProfilePicture"
              )}
            />
          </TabPane>
          <TabPane tab="Email" key="4">
            <EmailChangePassWord
              employeeUserId={updateUsers}
              profileInfo={profileInfo}
            />
          </TabPane>
          <TabPane tab="Email Share" key="5">
            <EmailShare />
          </TabPane>
          {isExitFormStatus && (
            <>
              <TabPane tab="Clearance Form" key="6">
                <ClearanceTemplate
                  employeeUserId={userEmployeeId}
                  updateData={updateData}
                  setUpdateData={setUpdateData}
                />
              </TabPane>
              <TabPane tab="Exit Interview" key="7">
                <ExitInterviewTable employeeUserId={userEmployeeId} />
              </TabPane>
            </>
          )}
          <TabPane tab="Review" key="8">
            <ReviewTemplate employeeUserId={userEmployeeId} />
          </TabPane>
          <TabPane tab="Leave" key="9">
            <LeaveTemplate 
              employeeUserId={userEmployeeId}
              appUserId = {localStorageId}
            />
          </TabPane>
        </Tabs>
      </div>

      <AddUserDrawer
        drawerVisibility={addUserDrawerVisibility}
        onAddUserClose={onAddUserClose}
        isUpdateCredentials={isUpdateCredentials}
        form={form}
      />
    </div>
  );
};

//

export default Profile;
