import { Modal } from "antd";
import React, { useState } from "react";
import ProjectForm from "../../ProjectTabs/ProjectForm";

const ProjectModal = ({
  projectModal,
  setProjectModal,
  selectedSearchedProjectTitle,
}) => {
  const [contactCompanyList, setContactCompanyList] = useState([]);
  const [searchTitleProject, setSearchTitleProject] = useState([]);
  const [value, setValue] = useState("");

  return (
    <Modal
      visible={projectModal}
      footer={false}
      title="Add a Project"
      onCancel={() => {
        setProjectModal(false);
      }}
    >
      <ProjectForm
        contactCompanyList={contactCompanyList}
        setContactCompanyList={setContactCompanyList}
        setSearchTitleProject={setSearchTitleProject}
        value={value}
        setValue={setValue}
        selectedSearchedProjectTitle={selectedSearchedProjectTitle}
      />
    </Modal>
  );
};

export default ProjectModal;
