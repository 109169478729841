import { Button } from "antd";
import moment from "moment";
import { isEmpty } from "lodash";
import { cqBaseUrl } from "utils/config";
import { textCenterDot, fileDownload } from "utils/commonFunc";
import {
  EyeOutlined,
  DownloadOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
const DocDetail = ({
  docDetail,
  setHideDrawer,
  token,
  showModal,
  setDocDetailId,
}) => {
  return (
    <>
      <div className="global-single-detail-wrapper">
        <div className="global-single-detail-item">
          <div className="global-single-detail">
            <p>File Name</p>
          </div>
          <div className="global-single-detail-info">
            <p>{textCenterDot(docDetail[0]?.fileDisplayName)}</p>
          </div>
        </div>
        <div className="global-single-detail-item">
          <div className="global-single-detail">
            <p>Ref</p>
          </div>
          <div className="global-single-detail-info">
            <p>{docDetail[0]?.referenceCode}</p>
          </div>
        </div>
        <div className="global-single-detail-item">
          <div className="global-single-detail">
            <p>Code</p>
          </div>
          <div className="global-single-detail-info">
            <p>{docDetail[0]?.code}</p>
          </div>
        </div>
        <div className="global-single-detail-item">
          <div className="global-single-detail">
            <p>Label Group</p>
          </div>
          <div className="global-single-detail-info">
            <p>{docDetail[0]?.fileLabelTypeGroup}</p>
          </div>
        </div>
        <div className="global-single-detail-item">
          <div className="global-single-detail">
            <p>Sector</p>
          </div>
          <div className="global-single-detail-info">
            <p>{docDetail[0]?.sectorNames?.join(", ")}</p>
          </div>
        </div>
        <div className="global-single-detail-item">
          <div className="global-single-detail">
            <p>Type</p>
          </div>
          <div className="global-single-detail-info">
            <p>{docDetail[0]?.fileLabelType}</p>
          </div>
        </div>
        <div className="global-single-detail-item">
          <div className="global-single-detail">
            <p>Uploaded Date</p>
          </div>
          <div className="global-single-detail-info">
            <p> {moment(docDetail[0]?.creationTime).format("YYYY/MM/DD")}</p>
          </div>
        </div>
        <div className="global-single-detail-item">
          <div className="global-single-detail">
            <p>Remark</p>
          </div>
          <div className="global-single-detail-info">
            <p>{docDetail[0]?.notes}</p>
          </div>
        </div>
        <div className="global-single-detail-item">
          <div className="global-single-detail">
            <p>Action</p>
          </div>
          <div className="global-single-detail-info">
            <div className="file-action-btn-wrapper">
              {!isEmpty(docDetail[0]?.viewFileURL) && (
                <>
                  {docDetail[0]?.fileDisplayName.slice(-3) === "pdf" && (
                    <div className="action-btn-item">
                      <a
                        onClick={() => {
                          window.open(
                            `${cqBaseUrl}/${docDetail[0]?.viewFileURL}${token}`,
                            "_blank",
                            "top=50,left=200,frame=true"
                          );
                        }}
                      >
                        <EyeOutlined />
                      </a>
                    </div>
                  )}

                  <div className="action-btn-item">
                    <a
                      onClick={() => {
                        fileDownload(
                          cqBaseUrl + "/" + docDetail[0]?.viewFileURL + token,
                          docDetail[0]?.fileDisplayName
                        );
                      }}
                    >
                      <DownloadOutlined />
                    </a>
                  </div>
                  <div className="action-btn-item">
                    <ShareAltOutlined onClick={showModal} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="model-footer">
        <Button
          onClick={() => {
            setHideDrawer(true);
            setDocDetailId(null);
          }}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default DocDetail;
