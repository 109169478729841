import { Button, Skeleton } from "antd";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { getLoanApplicationDataGetDetails } from "services/redux/requistions/loanApplication/action";
import { EditOutlined } from "@ant-design/icons";
import { getProcurementDetailsIndividual } from "services/redux/requistions/procurement/action";
import {
  getGuestEntertainmentDetails,
  getLeaveDetails,
} from "services/redux/requistions/leaveType/action";
import NoContent from "components/shared/NoContent";
import LeaveDetailsList from "./ListDetails/LeaveDetails";
import LoanDetailsList from "./ListDetails/LoanDetails";
import OtherDetailsList from "./ListDetails/OtherDetails";
import GuestEntertainmentDetailList from "./ListDetails/GuestEntertainmentDetail";
import CeadDetailsList from "./ListDetails/CeadDetailsList";
import { getApprovalCeadDetails } from "services/redux/requistions/ceadApproval/action";

export const LoanLeaveProcurementTable = ({
  procurementSummaryManagementResponse,
  procurementSummaryManagementLoading,
  width,
  style,
  loan,
  leave,
  cead,
  procurement,
  guestEntertainment,
  setLoanApplicationId,
  setRequisitionModal,
  setShowMore,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {!isEmpty(procurementSummaryManagementResponse?.items) ? (
        procurementSummaryManagementResponse?.items?.map((list, i) => (
          <div key={i} className="hover-item">
            {
              <div>
                <li className="dashboards__tenderlist--left">
                  <div className="dashboards__tenderlist--left left1">
                    <div style={{ display: "flex" }}>
                      <h5 style={{ paddingLeft: 5 }}>{i + 1}</h5>
                      <h5 style={{ paddingLeft: 15 }}>
                        {list.requestedUserName !== undefined
                          ? list.requestedUserName
                          : list?.tenderId !== undefined
                          ? "Contract Name : " + list.contract
                          : list?.applicantDetails?.fullName}
                      </h5>
                    </div>

                    <span>
                      {list?.applyNumberOfGuest !== undefined ? (
                        <GuestEntertainmentDetailList list={list} />
                      ) : list?.tenderId !== undefined ? (
                        <CeadDetailsList list={list} />
                      ) : list?.loanRequestedAmount !== 0 ? (
                        <LoanDetailsList list={list} />
                      ) : list?.leaveDays !== 0 ? (
                        <LeaveDetailsList
                          list={list}
                          width={width}
                          style={style}
                        />
                      ) : (
                        <OtherDetailsList list={list} />
                      )}
                    </span>
                  </div>
                  <div
                    className="dashboards__tenderlist--right right1"
                    style={{ paddingRight: 10 }}
                  >
                    {loan && (
                      <Button
                        style={{ height: "28px" }}
                        onClick={() => {
                          setLoanApplicationId(list?.id);
                          setRequisitionModal(true);
                          setShowMore(false);
                          dispatch(getLoanApplicationDataGetDetails(list?.id));
                        }}
                      >
                        <EditOutlined
                          style={{
                            fontSize: "16px",
                            color: "#08c",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                          }}
                        />
                      </Button>
                    )}

                    {procurement && (
                      <Button
                        style={{ height: "28px" }}
                        onClick={() => {
                          setLoanApplicationId(list?.id);
                          setRequisitionModal(true);
                          setShowMore(false);
                          dispatch(getProcurementDetailsIndividual(list?.id));
                        }}
                      >
                        <EditOutlined
                          style={{
                            fontSize: "16px",
                            color: "#08c",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                          }}
                        />
                      </Button>
                    )}

                    {leave && (
                      <Button
                        style={{ height: "28px" }}
                        onClick={() => {
                          setLoanApplicationId(list?.id);
                          setRequisitionModal(true);
                          setShowMore(false);
                          dispatch(getLeaveDetails(list?.id));
                          // getProcurementDetailsIndividual(list?.id)
                        }}
                      >
                        <EditOutlined
                          style={{
                            fontSize: "16px",
                            color: "#08c",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                          }}
                        />
                      </Button>
                    )}

                    {guestEntertainment && (
                      <Button
                        style={{ height: "28px" }}
                        onClick={() => {
                          setLoanApplicationId(list?.id);
                          setRequisitionModal(true);
                          setShowMore(false);
                          dispatch(getGuestEntertainmentDetails(list?.id));
                          // getProcurementDetailsIndividual(list?.id)
                        }}
                      >
                        <EditOutlined
                          style={{
                            fontSize: "16px",
                            color: "#08c",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                          }}
                        />
                      </Button>
                    )}

                    {cead && (
                      <Button
                        style={{ height: "28px" }}
                        onClick={() => {
                          setLoanApplicationId(list?.id);
                          setRequisitionModal(true);
                          setShowMore(false);
                          dispatch(getApprovalCeadDetails(list?.id));
                          // getProcurementDetailsIndividual(list?.id)
                        }}
                      >
                        <EditOutlined
                          style={{
                            fontSize: "16px",
                            color: "#08c",
                            cursor: "pointer",
                            backgroundColor: "#fff",
                          }}
                        />
                      </Button>
                    )}
                  </div>
                </li>
              </div>
            }
          </div>
        ))
      ) : !procurementSummaryManagementLoading ? (
        <NoContent />
      ) : (
        <Skeleton />
      )}
    </>
  );
};
