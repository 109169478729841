import { message } from "antd";
import { error, init, success } from "services/common";
import { ADMIN_TYPES } from "services/types";
import { EmailShareService } from "./api";

const emailShareService = new EmailShareService();

export const emailShareListing = (type, query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.EMAIL_SHARE_LISTING));
    const response = await emailShareService.emailShareListing(type, query);
    if (response?.isSuccess) {
      dispatch(
        success(ADMIN_TYPES.EMAIL_SHARE_LISTING, response?.data, loadMore)
      );
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const corporateEmailAdd = (body, form) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.CORPORATE_EMAIL_ADD));
    const response = await emailShareService.corporateEmailAdd(body);
    if (response?.isSuccess) {
      dispatch(success(ADMIN_TYPES.CORPORATE_EMAIL_ADD, response?.data));
      dispatch(emailShareListing("corporate"));
      form.resetFields();
    } else {
      dispatch(error(ADMIN_TYPES.CORPORATE_EMAIL_ADD));
    }
  };
};

export const corporateEmailPermissionAdd = (body, id, type) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.CORPORATE_EMAIL_PERMISSION_ADD));
    const response = await emailShareService.corporateEmailPermissionAdd(
      body,
      id,
      type
    );
    if (response?.isSuccess) {
      dispatch(
        success(ADMIN_TYPES.CORPORATE_EMAIL_PERMISSION_ADD, response?.data)
      );
      message.success("Successfully added email permission");
    } else {
      dispatch(error(ADMIN_TYPES.CORPORATE_EMAIL_PERMISSION_ADD));
    }
  };
};

export const emailShareGetDetails = (id, type) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.EMAIL_SHARE_GET_DETAILS));
    const response = await emailShareService.emailShareGetDetails(id, type);

    if (response?.isSuccess) {
      dispatch(success(ADMIN_TYPES.EMAIL_SHARE_GET_DETAILS, response?.data));
    } else {
      dispatch(error(ADMIN_TYPES.EMAIL_SHARE_GET_DETAILS));
    }
  };
};

export const sharedEmailParticipation = (body, type, id) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.CORPORATE_EMAIL_SHARE));
    const response = await emailShareService.corporateEmailShare(
      body,
      type,
      id
    );
    if (response?.isSuccess) {
      dispatch(success(ADMIN_TYPES.CORPORATE_EMAIL_SHARE, response?.data));
      message.success("Permission is updated sucessfully");
    } else {
      dispatch(error(ADMIN_TYPES.CORPORATE_EMAIL_SHARE));
      message.error("Error in updating permission");
    }
  };
};
