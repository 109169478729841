import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDashboardProjects } from "services/redux/Reference/Project/action";
import { EyeOutlined } from "@ant-design/icons";
import { Image } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

const man = "assets/images/icons/man.png";
const ProjectsLists = () => {
  const dispatch = useDispatch();
  const internalMessageGreen = "assets/svg/internal-msg-12-green.svg";
  const [currentPage, setCurrentPage] = useState(1);
  const {
    dashboardProjectPaginationLoading,
    dashboardProjectPaginationResponse,
  } = useSelector((state) => state.project);

  useEffect(() => {
    const body = {
      IsDashboard: true,
    };
    dispatch(getDashboardProjects(body));
  }, []);

  useEffect(() => {
    let newPage = dashboardProjectPaginationResponse?.length / pageSize;
    setCurrentPage(newPage);
  }, [dashboardProjectPaginationResponse?.length]);

  const tenderDays = (date) => {
    const momentDate = moment.utc(date, "YYYY-MM-DD");
    const momentNow = moment().startOf("day").utc(date, "YYYY-MM-DD");
    const diffDays = Math.abs(momentNow.diff(momentDate, "days"));
    if (diffDays === 0) return "Today";
    if (diffDays < 30) {
      return diffDays + "d";
    } else if (diffDays < 365) {
      return Math.trunc(diffDays / 30) + "months";
    } else {
      return Math.trunc(diffDays / 365) + "years";
    }
  };

  const pageSize = 10;
  const getProjectList = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    forPagination = false,
    IsDashboardtask = true
  ) => {
    let appendUrl = `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}&IsDashboardtask=${IsDashboardtask}`;

    const body = {
      IsDashboard: true,
      MaxResultCount: MaxResultCount,
      SkipCount: SkipCount,
    };
    dispatch(getDashboardProjects(body));
  };
  const fetchMoreData = () => {
    console.log(" I should be called now");
    getProjectList(pageSize, currentPage * pageSize, true);
  };

  const projectListPagination = () => {
    return (
      <>
        {!isEmpty(dashboardProjectPaginationResponse) ? (
          <>
            <ul className="dashboards__todolists" style={{ marginTop: "10px" }}>
              {dashboardProjectPaginationResponse?.items?.map((list, i) => {
                return (
                  <div key={i} className="hover-item">
                    <li
                      className="dashboards__tenderlist--left"
                      style={{ width: "100%" }}
                    >
                      <div
                        className="dashboards__tenderlist--left left1"
                        style={{ width: "75%" }}
                      >
                        <div className="left1-title">
                          <h5 style={{ marginRight: 20 }}>{i + 1}</h5>
                          <h5 className="left1-title-main">{list.title}</h5>
                        </div>
                        <div style={{ marginLeft: 28 }}>
                          {list.referenceCode}
                        </div>
                      </div>
                      {list.submissionDate !== "0001-01-01T00:00:00" && (
                        <div
                          className="dashboards__tenderlist--right"
                          style={{
                            width: "36%",
                            justifyContent: "end",
                          }}
                        >
                          <h6>
                            {list.submissionDate == null
                              ? ""
                              : moment(list.submissionDate).format(
                                  "DD-MMM-YYYY"
                                )}
                          </h6>
                          {list.submissionDate == null ? (
                            ""
                          ) : (
                            <span className="remaining-wrapper">
                              {tenderDays(list.submissionDate)}
                            </span>
                          )}
                          <Link
                            to={`/office-resource/business?code=${list.referenceCode}&id=${list.id}`}
                            className="tender-view-btn"
                          >
                            <EyeOutlined />
                          </Link>
                        </div>
                      )}
                    </li>
                  </div>
                );
              })}
            </ul>
          </>
        ) : (
          <div className="dashboards__todolists leave_absent_listss">
            <Image className="data-no-img" src={man} />
            <span>There are no Absent List.</span>
          </div>
        )}
      </>
    );
  };
  return (
    <div
      className="dashboards__body--main"
      style={{ height: "100%", color: "white" }}
    >
      <div className="dashboard_title_header_tender">
        <div className="dashboard_title_main">
          <h5 className="space-at">SN</h5>
          <h5>Title</h5>
        </div>
        <div className="dashboard_title_main due_date_dashboard">
          <h5 align="right">Action</h5>
        </div>
      </div>{" "}
      {dashboardProjectPaginationResponse?.items?.length !== 0 ? (
        // <InfiniteScroll
        //   dataLength={dashboardProjectPaginationResponse?.items?.length}
        //   // next={fetchMoreData}
        //   next={() => {
        //     console.log("More data should be fetched");
        //   }}
        //   hasMore={
        //     dashboardProjectPaginationResponse?.totalCount >
        //     dashboardProjectPaginationResponse?.items?.length
        //   }
        //   scrollableTarget="today-target"
        //   // loader={<Skeleton paragraph={{ rows: 1 }} active />}
        // >
        <>{projectListPagination()}</>
      ) : (
        // </InfiniteScroll>
        <>There are no projects</>
      )}
    </div>
  );
};

export default ProjectsLists;
