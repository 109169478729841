import { Col, Row } from "antd";
import React from "react";

const GuestEntertainmentBasicDetail = ({
  guestEntertainmentDetailResponse,
}) => {
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={8}>Person:</Col>
        <Col span={16}>
          {guestEntertainmentDetailResponse?.applicantDetails?.fullName}
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Subject:</Col>
        <Col span={16}>{guestEntertainmentDetailResponse?.subject}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Guest Company:</Col>
        <Col span={16}>
          {guestEntertainmentDetailResponse?.guestCompanyName}
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Number of guests:</Col>
        <Col span={16}>
          {guestEntertainmentDetailResponse?.applyNumberOfGuest}
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Guest Names:</Col>
        <Col span={16}>{guestEntertainmentDetailResponse?.guestNames}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Applied Food No:</Col>
        <Col span={16}>{guestEntertainmentDetailResponse?.applyFoodtimes}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Applied Drink No:</Col>
        <Col span={16}>{guestEntertainmentDetailResponse?.applyDrinktimes}</Col>
      </Row>
    </>
  );
};

export default GuestEntertainmentBasicDetail;
