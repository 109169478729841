import React, { useState, useEffect } from "react";
import { Button, Drawer, Col, Row, Layout } from "antd";
import Changepassword from "../ChangePassword/index.js";
import { useSelector } from "react-redux";
import Form from "antd/lib/form/Form";

const { Header } = Layout;
const LeaveDrawer = ({ Button, visible, onClose, updateUsers }) => {
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const { userPasswordResponse } = useSelector((state) => state.auth);
  useEffect(() => {
    onClose();
  }, [userPasswordResponse]);

  return (
    <Drawer
      visible={visible}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onClose}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">Change Password</h4>
      </Header>
      <div className="drawer__content">
        <div className="drawer__content--wrapper">
          <div className="contact__content__wrapper">
            <Changepassword updateUsers={updateUsers} />
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default LeaveDrawer;
