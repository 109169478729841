import { TODO_TYPES, DAILY_DAIRY } from "../../../types";
import { init, success, finish, error } from "services/common";
import { ToDoListService } from "./api";
import { message, notification } from "antd";
import { updateDailyDiaryReducer } from "services/redux/dailyDairy/action";

const toDoListService = new ToDoListService();
export const getTodoEventsPagination = (query, forPagination) => {
  const updatedQuery = `${query}`;
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.TODO_LISTS));
    const response = await toDoListService.getTodoEventsPagination(
      updatedQuery
    );
    dispatch(finish(TODO_TYPES.TODO_LISTS));
    if (response.isSuccess) {
      if (forPagination) {
        dispatch(success(TODO_TYPES.TOD0_LIST_CHANGE, response.data));
      } else {
        dispatch(success(TODO_TYPES.TODO_LISTS, response.data));
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// export const privatePaginationData = () => {
//   return async(dispatch) => {
//     dispatch(init(TODO_TYPES.PRIVATE_LISTS));
//     const response = await toDoListService.privatePaginationData();
//     dispatch(finish(TODO_TYPES.PRIVATE_LISTS));
//     if(response.isSuccess){
//       dispatch(success(TODO_TYPES.PRIVATE_LISTS,response.data));
//     } else if (!response.isSuccess) {
//       dispatch(error(response.errorMessage));
//     }
//   };
// };

export const getTodoEventCompleted = (query, forPagination) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.TODO_LISTS_TODAY));
    const response = await toDoListService.getTodoEventsPagination(query);
    dispatch(finish(TODO_TYPES.TODO_LISTS_TODAY));
    if (response.isSuccess) {
      if (forPagination) {
      } else {
        dispatch(success(TODO_TYPES.TODO_LISTS_TODAY, response.data));
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getTodoEventsKanban = () => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.TODO_LISTS_KANBAN));
    const response = await toDoListService?.getTodoEventsKanban();
    dispatch(finish(TODO_TYPES.TODO_LISTS_KANBAN));
    if (response?.isSuccess) {
      dispatch(success(TODO_TYPES.TODO_LISTS_KANBAN, response?.data));
    } else {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addToDoEvent = (body, type, taskCat) => {
  return async (dispatch) => {
    // console.log("type: ", type, ", taskCat at last: ", taskCat);
    dispatch(init(TODO_TYPES.ADD_TODO_LISTS));
    const response = await toDoListService.addToDoEvent(body);
    dispatch(finish(TODO_TYPES.ADD_TODO_LISTS));
    // console.log("ACTION PAYLOAD: ", body)
    if (response.isSuccess) {
      // if (type !== "calls") {
      //   dispatch(getTodoEventsPagination());
      // }
      // console.log("Response DATA: ", response)
      dispatch(
        success(TODO_TYPES.ADD_TODO_LISTS, { ...response.data, taskCat })
      );
      dispatch(todoEventGetAllCounter());
      // dispatch(getTodoEventsPagination(`maxResultCount=10&skipCount=0`));
      message.success("To-Do Event added successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const addPrivateEvent = (body) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.ADD_PRIVATE_LISTS));
    const response = await toDoListService.addToDoEvent(body);
    dispatch(finish(TODO_TYPES.ADD_PRIVATE_LISTS));
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.ADD_PRIVATE_LISTS, response.data));
      // dispatch(getTodoEventsPagination(`maxResultCount=10&skipCount=0`));
      message.success("To-Do Event added successfully");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const updateToDoEvent = (
  body,
  id,
  taskAssignedTo,
  referenceId,
  hideState
) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.UPDATE_TODO_EVENT));

    const response = await toDoListService.updateToDoEvent(body, id);

    dispatch(finish(TODO_TYPES.UPDATE_TODO_EVENT));
    if (response.isSuccess) {
      message.success("ToDo Event updated successfully");
      if (
        response?.organizationDepartmentDto?.id !== body?.departmentId ||
        response?.todoEventRemainderTypeDto?.id !==
          body?.todoEventReminderType ||
        body?.changeValue
      ) {
        dispatch(
          success(TODO_TYPES.UPDATE_TODO_EVENT, {
            ...response.data,
            hide: true,
          })
        );
      } else {
        dispatch(
          success(TODO_TYPES.UPDATE_TODO_EVENT, {
            ...response.data,
            hide: hideState,
          })
        );
      }
      dispatch(todoEventGetAllCounter());
      if (!!taskAssignedTo) {
        // dispatch(
        //   getTodoEventsPagination(
        //     `maxResultCount=30&skipCount=0&AssignedStatus=${taskAssignedTo}`
        //   )
        // );
      } else {
        return;
      }
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const toDoMarkAsComplete = (id) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.TODO_COMPLETE));
    const response = await toDoListService.toDoMarkAsComplete(id);
    dispatch(finish(TODO_TYPES.TODO_COMPLETE));
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.TODO_COMPLETE, response.data));
      // dispatch(getTodoEventsPagination(`maxResultCount=10&skipCount=0`));
      let status = response.data.markItOff ? "complete" : "incomplete";
      message.success(`Todo status updated successfully`);

      // added
      // console.log("ACTION response: ", response.data);
      dispatch(updateDailyDiaryReducer(DAILY_DAIRY.GET_PAGINATION_DAILY_DIARY_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const toDoMarkAsFlagged = (id) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.TODO_ISFLAGGED));
    const response = await toDoListService.toDoMarkAsFlagged(id);
    dispatch(finish(TODO_TYPES.TODO_ISFLAGGED));
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.TODO_ISFLAGGED, response.data));
      // dispatch(getTodoEventsPagination(`maxResultCount=10&skipCount=0`));
      let status = response.data.isFlagged ? "Flagged" : "UnFlagged";
      message.success(`Todo ${status} updated successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const toDoGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.TODO_GET_DETAILS));
    const response = await toDoListService.eventGetDetails(id);
    dispatch(finish(TODO_TYPES.TODO_GET_DETAILS));
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.TODO_GET_DETAILS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const kanbanToDO = (body) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.TODO_KANBAN));
    dispatch(success(TODO_TYPES.TODO_KANBAN, body));
  };
};

export const kanbanDrag = (taskId, statusId, kanbanData) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.TODO_KANBAN_DRAG));
    const response = await toDoListService.kanbanDragData(taskId, statusId);
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.TODO_KANBAN_DRAG, kanbanData));
      notification.success({ message: "Successfully updated Task" });
    } else {
      dispatch(error(response.errorMessage));
    }
  };
};

export const todoEventGetAllCounter = () => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.TASK_COUNTER));
    const response = await toDoListService.todoEventGetAllCounter();
    dispatch(finish(TODO_TYPES.TASK_COUNTER));
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.TASK_COUNTER, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const todayTasks = (query) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.ALL_TODAY_TASKS));
    const response = await toDoListService.getAllTodayTasks(query);
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.ALL_TODAY_TASKS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const taskUsedReference = () => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.ALL_USED_REFERENCE));
    const response = await toDoListService.getTasksReference();
    dispatch(finish(TODO_TYPES.ALL_USED_REFERENCE));
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.ALL_USED_REFERENCE, response.data));
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const toDoGetAgendas = (body) => {
  return async (dispatch) => {
    dispatch(init(TODO_TYPES.AGENDAS_LIST));
    const response = await toDoListService.getAgendaListing(body);
    dispatch(finish(TODO_TYPES.AGENDAS_LIST));
    if (response.isSuccess) {
      dispatch(success(TODO_TYPES.AGENDAS_LIST, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};


// dailydiary
export const updateDailyTaskReducer = (TODO_LISTS_UPDATE, data) => {
  return {
    type: TODO_TYPES.TODO_LISTS_UPDATE,
    payload: data
  };
}

export const updateStatusMovetoDailyDiary = (TODO_LISTS_STATUS, data) => {
    return {
      type: TODO_TYPES.TODO_LISTS_STATUS,
      payload: data,
    }
}