import { Button } from "antd";
import React from "react";

const NoStyleButton = ({ text, OnClickButton }) => {
  return (
    <Button
      style={{
        background: "none",
        border: "none",
        color: "#67a1e4",
        cursor: "pointer",
      }}
      onClick={OnClickButton}
    >
      {text}
    </Button>
  );
};

export default NoStyleButton;
