import { Collapse } from "antd";
import { StarFilled } from "@ant-design/icons";
import EditNotes from "./EditNotes";
import { projectFavAddUpdate } from "services/redux/Reference/Project/action";

const renderProject = ({
  projectGetDetailsResponse,
  genExtra,
  numberWithCommas,
  dispatch,
}) => {
  const { Panel } = Collapse;
  return (
    <>
      <div className="business-information-wrapper" style={{ marginTop: 10 }}>
        <div
          className="business-tender-project-info-wrapper"
          style={{ padding: 0 }}
        >
          <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
            <Panel header="Basic" key="1" extra={genExtra()}>
              {projectGetDetailsResponse?.referenceCode && (
                <div className="business-tender-project-item">
                  <div
                    className="business-tender-project-title"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Reference</span>
                    <span
                      className="project-reference-star"
                      style={{ marginRight: 7 }}
                    >
                      <StarFilled
                        onClick={() => {
                          dispatch(
                            projectFavAddUpdate(
                              { projectId: projectGetDetailsResponse?.id },
                              projectGetDetailsResponse
                            )
                          );
                        }}
                        style={{
                          color: `${
                            projectGetDetailsResponse?.isFavorite
                              ? "#FBA525"
                              : "grey"
                          }`,
                        }}
                      />
                    </span>
                  </div>
                  <div className="business-tender-project-desc">
                    <span className="project-reference">
                      {" "}
                      {projectGetDetailsResponse?.referenceCode}
                    </span>
                  </div>
                </div>
              )}
              {projectGetDetailsResponse?.projectSectorDtos ? (
                <div className="business-tender-project-item">
                  <div className="business-tender-project-title">
                    <span>Sector </span>
                  </div>
                  <div className="business-tender-project-desc">
                    {projectGetDetailsResponse?.projectSectorDtos.map(
                      (data, index) => {
                        return (
                          <span>
                            {data?.sectorName}
                            {index <
                            projectGetDetailsResponse?.projectSectorDtos
                              ?.length -
                              1
                              ? ","
                              : ""}
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {projectGetDetailsResponse?.title ? (
                <div className="business-tender-project-item">
                  <div className="business-tender-project-title">
                    <span>Title </span>
                  </div>
                  <div className="business-tender-project-desc">
                    <span>{projectGetDetailsResponse?.title}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {projectGetDetailsResponse?.projectStatus ? (
                <div className="business-tender-project-item">
                  <div className="business-tender-project-title">
                    <span>Status</span>
                  </div>
                  <div className="business-tender-project-desc">
                    <span>{projectGetDetailsResponse?.projectStatus}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {projectGetDetailsResponse?.clientName ? (
                <div className="business-tender-project-item">
                  <div className="business-tender-project-title">
                    <span>Client</span>
                  </div>
                  <div className="business-tender-project-desc">
                    <span>{projectGetDetailsResponse?.clientName}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {projectGetDetailsResponse?.description ? (
                <div className="business-tender-project-item">
                  <div className="business-tender-project-title">
                    <span> Description </span>
                  </div>
                  <div className="business-tender-project-desc">
                    <span>{projectGetDetailsResponse?.description}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {projectGetDetailsResponse?.modalityName ? (
                <div className="business-tender-project-item">
                  <div className="business-tender-project-title">
                    <span> Modality </span>
                  </div>
                  <div className="business-tender-project-desc">
                    <span>{projectGetDetailsResponse?.modalityName}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {projectGetDetailsResponse?.projectFundingAgencyDtos ? (
                <div className="business-tender-project-item">
                  <div className="business-tender-project-title">
                    <span>Funding Agency</span>
                  </div>
                  <div className="business-tender-project-desc">
                    {projectGetDetailsResponse?.projectFundingAgencyDtos.map(
                      (data, i) => {
                        return (
                          <span>
                            {data.fundingAgencyName}{" "}
                            {projectGetDetailsResponse?.projectFundingAgencyDtos
                              .length >
                            i + 1
                              ? ", "
                              : ""}
                          </span>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {projectGetDetailsResponse?.projectCost ? (
                <div className="business-tender-project-item">
                  <div className="business-tender-project-title">
                    <span>Project Cost</span>
                  </div>
                  <div className="business-tender-project-desc">
                    <span>
                      {projectGetDetailsResponse?.currencyName} &nbsp;
                      {numberWithCommas(projectGetDetailsResponse?.projectCost)}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {projectGetDetailsResponse?.technicalfeatures ? (
                <div className="business-tender-project-item">
                  <div className="business-tender-project-title">
                    <span>Technical Features</span>
                  </div>
                  <div className="business-tender-project-desc">
                    <span>{projectGetDetailsResponse?.technicalfeatures}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Panel>
          </Collapse>
          <div className="project-note-wrapper">
            <EditNotes projectGetDetailsResponse={projectGetDetailsResponse} />
          </div>
        </div>
      </div>
    </>
  );
};

export default renderProject;
