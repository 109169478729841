import { MULTIPLE_EMAIL_SELECTION, EMAIL_TYPES } from "../../../types";

const INITIAL_STATE = {
  multipleSelectedEmail: [],
  multipleReferenceUpdateLoading: false,
};

export default function multipleSelectedEmailReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    // Appointment events
    case MULTIPLE_EMAIL_SELECTION.MULTIPLE_EMAIL_SELECTION_LIST_SUCCESS:
      if (action.payload.checkStatus) {
        INITIAL_STATE.multipleSelectedEmail = [
          ...INITIAL_STATE.multipleSelectedEmail,
          action.payload.data,
        ];
      } else {
        let removeIndex = INITIAL_STATE.multipleSelectedEmail
          .map((item) => item.id)
          .indexOf(action.payload.data.id);
        INITIAL_STATE.multipleSelectedEmail.splice(removeIndex, 1);
      }
      return {
        ...state,
        multipleSelectedEmail: INITIAL_STATE.multipleSelectedEmail,
      };
    case EMAIL_TYPES.MAIL_REFERENCE_TAGS_UPDATE_SUCCESS: {
      INITIAL_STATE.multipleSelectedEmail = [];
      return {
        ...EMAIL_TYPES,
      };
    }

    case EMAIL_TYPES.MAI_REFERENCE_TAGS_MULTIPLE_UPDATE_INIT:
      return {
        multipleReferenceUpdateLoading: true,
      };
    case EMAIL_TYPES.MAI_REFERENCE_TAGS_MULTIPLE_UPDATE_SUCCESS:
      INITIAL_STATE.multipleSelectedEmail = [];

      return {
        multipleReferenceUpdateLoading: false,
      };
    default:
      return { ...state };
  }
}
