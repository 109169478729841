import { appBaseUrl } from "utils/config";
import { postService } from "../../../../commonServices";

export class MeetingAgendaService {
  addMeetingEventAgenda(body) {
    let url = `${appBaseUrl}/organizerEvent/meetingEventAgendaAdd`;
    let data = postService(url, body, 'post');
    return data;
  }
  meetingEventAgendaUpdate(body, id) {
    let url = `${appBaseUrl}/organizerEvent/meetingEventAgendaUpdate/${id}`;
    let data = postService(url, body, 'put');
    return data;
  }
}