import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button , Row, Col} from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import AddUserDrawer from "./AddUserDrawer";
import ContactLabelAddUpdateTable from "./ContactLabelAddUpdateTable";
import { employeeProfileDetail } from "services/redux/profile/profile.action";
import ContactLabelAddUpdateDrawer from "./ContactLabelAddUpdateDrawer";
import { contactLabelPagination } from "services/redux/contact/contact.action";

const ContactLabelAddUpdate = () => {
  const dispatch = useDispatch();

  const [addUpdateCountryDrawerVisibility, setAddUpdateCountryDrawerVisibility] = useState(false);
  const [
    updateProfileDrawerVisibility,
    setUpdateProfileDrawerVisibility,
  ] = useState(false);
  const [isUpdateCountry, setIsUpdateCountry] = useState(false);
  const [skipCount, setSkipCount] = useState(0);
  const pageSize = 10;

  const showAddUpdateCountryDrawer = (e, record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false)
    }
    setAddUpdateCountryDrawerVisibility(true);
  };

  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry('');
    setAddUpdateCountryDrawerVisibility(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = skipCount,
    Sorting,
    SortType
  ) => {
    dispatch(
      contactLabelPagination({
        MaxResultCount,
        SkipCount,
        Sorting,
        SortType
      })
    );
  };

  return (
    <div className='layout'>
      <div className="organizer events">
        <div className="all-header-wrapper">
            <div className="meeting__flex">
                <h3 className="header__all">Contact Label List</h3>
                <div className="organizerHoliday__middle--wrap">
                  <Button
                    className='btn-primary'
                    onClick={showAddUpdateCountryDrawer}
                    >
                    <PlusOutlined /> New Contact Label
                  </Button>
                </div>
            </div>
        </div>
        <ContactLabelAddUpdateTable
          showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
          getData={getData}
          setSkipCount={setSkipCount}
          pageSize={pageSize}
        />
        <ContactLabelAddUpdateDrawer
          drawerVisibility={addUpdateCountryDrawerVisibility}
          onAddUpdateCountryClose={onAddUpdateCountryClose}
          isUpdateCountry={isUpdateCountry}
          skipCount={skipCount}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default ContactLabelAddUpdate;
