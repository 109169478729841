export const SPECIALFLAG1 = "Special Flag 1";
export const SPECIALFLAG2 = "Special Flag 2";
export const SPECIALFLAG3 = "Special Flag 3";
export const SPECIALFLAG4 = "Special Flag 4";
export const SPECIALFLAG5 = "Special Flag 5";
export const SPECIALFLAG = "Special Flag";

export const FLAGTYPE1 = "FlagType1";
export const FLAGTYPE2 = "FlagType2";
export const FLAGTYPE3 = "FlagType3";
export const FLAGTYPE4 = "FlagType4";
export const FLAGTYPE5 = "FlagType5";
export const FLAG_TYPE = "flag_type";
export const NORMAL = "Normal";

export const INDIGO = "indigo";
export const RED = "red";
export const GREEN = "green";
export const PINK = "pink";
export const VIOLET = "violet";

export const NONE = "None";
export const BOLD = "bold";

export const DRAFT = "Draft";
export const FROM = "From";
export const SENT_CIRCULAR_FROM = "From";
export const APPROVAL = "Approval";
export const PROCUREMENT = "Procurement";
export const LOAN = "Loan";
export const LEAVE = "Leave";
export const ALL_APPROVAL = "Loan,Leave,Procurement";
export const LOAN_APPROVAL = "Approval / Loan";
export const PROCUREMENT_APPROVAL = "Approval / Procurement";
export const LEAVE_APPROVAL = "Approval / Leave";
export const TENDER = "Tender";
export const BUSINESS = "Business";
export const CORPORATE = "Corporate";
export const SENT_CIRCULAR_FROM_DISPLAY = "Sent / Circular";
export const INBOX_CIRCULAR_DISPLAY = "Inbox / Circular";
export const SENT = "Sent";
export const TO = "To";
export const CC = "Cc";
export const BCC = "BCc";
export const INBOX = "Inbox";
export const INBOX_LOWERCASE = "inbox";
export const FORWARD = "forward";
export const READ = "Read";
export const JUNK = "Junk";
export const CIRCULAR = "Circular";
export const TENDERFAVOURITE = "tenderfavourite";
export const FAVOURITE = "Favourite";
export const TENDERFAVOURITEINBOX = "Inbox \\ Favourite";
export const INBOX_TO = "Inbox \\ To";
export const INBOX_CC = "Inbox \\ Cc";
export const INBOX_BCC = "Inbox \\ BCc";
export const INBOX_JUNK = "Inbox \\ Junk";
export const INBOX_CIRCULAR = "Inbox \\ Circular";
export const SHAREDMAIL_INBOX = "Shared Mail / Inbox";
export const SHAREDMAIL_SENT = "Shared Mail / Sent";
export const EMAIL_TYPE_SHAREDMAIL_SENT = "sharedMailSent";
export const UNREAD = "Unread";
export const TRASH = "Trash";
export const OUTBOX = "OutBox";
export const ARCHIEVED = "Archived";
export const STARRED_FLAG_NAME = "specialflag1";
export const STARRED_DISPLAY_NAME = "Starred";
export const ALL = "All";
export const PENDING = "Pending";
export const COMPLETED = "Completed";
export const DELETED = "Deleted";
export const DELETE = "Delete";
export const REMOVE = "Remove";
export const ADD = "Add";
export const ARCHIEVE = "Archive";
export const UNARCHIEVE = "Unarchive";
export const GROUP_CHAT = "groupchat";
export const GROUP_CHAT_ARCHIEVE = "GroupChatArchive";
export const GROUP_CHAT_SWITCH = "GroupChatSwitch"

export const AUTO_FORWARD = "autoForward";
export const RECENT_REFERENCE_TAGS = "recentReferenceTags";
export const UNSOLICITED = "unsolicited";
export const CEAD = "tenderceadinvoice";
