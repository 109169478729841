import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Layout, Button } from "antd";
import ExitInterviewSupervisorAddUpdateForm from "./ExitInterviewSupervisorAddUpdateForm";
const { Footer } = Layout;

const ExitInterviewTable = ({ employeeUserId }) => {
  const dispatch = useDispatch();
  const { exitInterviewGetResponse } = useSelector((state) => state.profile);
  const [supervisorQuestion, setSupervisorQuestion] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [showSaveCancel, setShowSaveCancel] = useState(false);
  const [currentSupervisor, setCurrentSupervisor] = useState([]);

  useEffect(() => {
    setCurrentSupervisor(
      exitInterviewGetResponse?.employeeReviewSupervisorPoints?.map(
        (item) => item?.reviewerUserDto?.employeeName
      )
    );
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
    setShowSaveCancel(true);
  };

  useEffect(() => {
    const supervisorQuestions =
      exitInterviewGetResponse?.employeeReviewSupervisorPoints?.flatMap(
        (item) =>
          item?.questionAnswareDtos?.map((questionItem, index) => ({
            key: `question_${index}`,
            question: questionItem.question,
            id: questionItem.id,
            answered: questionItem.answered,
            supervisorId: item.reviewerId,
            supervisorName: item?.reviewerUserDto?.employeeName,
          }))
      ) || [];
    setSupervisorQuestion(supervisorQuestions || []);
  }, [exitInterviewGetResponse]);

  const supervisorColumn = [
    {
      title: "",
      dataIndex: "question",
      key: "question",
      width: "33.33%",
      render: (_, record, index) => {
        return <span>{record.question}</span>;
      },
    },
    {
      title: "Supervisor",
      dataIndex: "supervisorName",
      key: "supervisorName",
      width: "33.33%",
      render: (_, record, index) => {
        if (index === 0) {
          return <span>{record.supervisorName}</span>;
        }
        return null;
      },
    },
    {
      title: "Value",
      dataIndex: "answered",
      key: "answered",
      width: "33.33%",
      render: (_, record) => {
        let displayText = "";
        if (record.answered == 1) {
          displayText = "Always";
        } else if (record.answered == 2) {
          displayText = "Sometimes";
        } else if (record.answered == 3) {
          displayText = "Usually";
        } else if (record.answered == 4) {
          displayText = "Never";
        } else if (typeof record.answered === "undefined") {
          displayText = "null";
        }
        return <span>{displayText}</span>;
      },
    },
  ];

  const CompanyPointsCol = [
    {
      title: "",
      dataIndex: "points",
      key: "points",
      render: (_, record, index) => {
        return <span>{record.question}</span>;
      },
    },
    {
      title: "Value",
      dataIndex: "answered",
      key: "answered",
      render: (_, record) => {
        let displayText = "";
        if (record.answered == "YES1") {
          displayText = "1 Star";
        } else if (record.answered == "YES2") {
          displayText = "2 Star";
        } else if (record.answered == "YES3") {
          displayText = "3 Star";
        } else if (record.answered == "YES4") {
          displayText = "4 Star";
        } else if (record.answered == "YES5") {
          displayText = "5 Star";
        } else if (typeof record.answered === "undefined") {
          displayText = "null";
        }
        return <span>{displayText}</span>;
      },
    },
  ];

  const recommendationData = [
    {
      id: 1,
      recommendation:
        exitInterviewGetResponse?.wouldYouRecommendEmploymentToAnyoneHere ??
        null,
    },
  ];

  const recommendationColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Recommendation",
      dataIndex: "recommendation",
      key: "recommendation",
      render: (recommendation) => (recommendation ? "Yes" : "No"),
    },
  ];

  const renderExitInterviewTable = () => {
    return (
      <>
        <div className="exitInterview__tbl">
          <Table
            dataSource={exitInterviewGetResponse?.primaryEmployeeQuestions}
            columns={[
              {
                title: "Question",
                dataIndex: "question",
                key: "question",
              },
              {
                title: "Answer",
                dataIndex: "answered",
                key: "answered",
              },
            ]}
            pagination={false}
          />

          <h3 className="exit-interview__row">
            Your comment about your supervisor on the following points
          </h3>

          {exitInterviewGetResponse?.employeeReviewSupervisorPoints ? (
            exitInterviewGetResponse.employeeReviewSupervisorPoints.map(
              (subArray, index) => (
                <Table
                  key={`table_${index}`}
                  dataSource={subArray.questionAnswareDtos.map((item) => ({
                    ...item,
                    supervisorId: subArray.reviewerId,
                    supervisorName: subArray?.reviewerUserDto?.employeeName,
                  }))}
                  columns={supervisorColumn}
                  pagination={false}
                  className="exitinterview-supervisor"
                />
              )
            )
          ) : (
            <p>No supervisor points available.</p>
          )}

          <h3 className="exit-interview__row">
            How would you rate the company on the following points:
          </h3>
          <Table
            dataSource={exitInterviewGetResponse?.employeeReviewCompanyPoints}
            columns={CompanyPointsCol}
            pagination={false}
          />

          <h3 className="exit-interview__row">
            Would you recommend employment to anyone here?
          </h3>
          <Table
            dataSource={recommendationData}
            columns={recommendationColumns}
            pagination={false}
          />

          <Footer>
            <Button
              className="primary"
              onClick={handleEditClick}
              disabled={editMode}
            >
              Edit
            </Button>
          </Footer>
        </div>
      </>
    );
  };

  return (
    <>
      {editMode ? (
        <ExitInterviewSupervisorAddUpdateForm
          employeeUserId={employeeUserId}
          editMode={editMode}
          setEditMode={setEditMode}
          showSaveCancel={showSaveCancel}
          setShowSaveCancel={setShowSaveCancel}
          currentSupervisor={currentSupervisor}
          exitInterviewGetResponse={exitInterviewGetResponse}
        />
      ) : (
        renderExitInterviewTable()
      )}
    </>
  );
};

export default ExitInterviewTable;
