import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Skeleton, Row, Col, Affix, Dropdown } from "antd";
import moment from "moment";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  blogAdd,
  updateBlogMessage,
  getBlogPagination,
} from "services/redux/officeresource/blog/action";
import {
  employeeProfileDetail,
  actionForGetAllUsersPP,
} from "services/redux/profile/profile.action";
import BlogItem from "./BlogItem";
import BlogForm from "./BlogForm";

const Blog = ({ tenderId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const pageSize = 40;

  const scrollableRef = useRef();

  const {
    addBlogMessageLoading,
    addBlogMessageResp,
    getBlogMsgPaginationResp,
    getBlogMsgPaginationLoading,
  } = useSelector((state) => state.blogReducer);

  // const {}

  const { profileInfo } = useSelector((state) => state.profile);

  const [blogMsg, setBlogMsg] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [editMode, setEditMode] = useState(false);
  const [editedBlog, setEditedBlog] = useState({});

  const appUserIdUC = sessionStorage?.userId.toUpperCase();

  // SUPERAPI **************************************************************
  const { listingAllUsersProfileResp, listingAllUsersProfileLoading } =
    useSelector((state) => state.profile);

  const [listingAllProfile, setListingAllProfile] = useState([]);

  useEffect(() => {
    dispatch(actionForGetAllUsersPP());
  }, []);

  useEffect(() => {
    if (!isEmpty(listingAllUsersProfileResp)) {
      setListingAllProfile(listingAllUsersProfileResp);
    }
  }, [listingAllUsersProfileResp]);
  // end SUPERAPI **************************************************************

  useEffect(() => {
    dispatch(
      employeeProfileDetail({
        AppUserId: sessionStorage?.userId.replaceAll('"', ""),
      })
    );
  }, []);

  useEffect(() => {
    let apiBody = {
      ReferenceCode: tenderId,
      MaxResultCount: pageSize,
      SkipCount: 0,
    };
    dispatch(getBlogPagination(apiBody, false));
  }, [tenderId]);

  useEffect(() => {
    if (!isEmpty(getBlogMsgPaginationResp?.items)) {
      setBlogMsg(getBlogMsgPaginationResp.items);
      let newCurrentPage = getBlogMsgPaginationResp.items.length / pageSize;
      setCurrentPage(newCurrentPage);
    } else {
      setBlogMsg([]);
    }
  }, [getBlogMsgPaginationResp, currentPage, pageSize]);

  const getData = (MaxResultCount = pageSize, SkipCount, loadMore = true) => {
    dispatch(
      getBlogPagination(
        {
          ReferenceCode: tenderId,
          MaxResultCount,
          SkipCount,
        },
        loadMore
      )
    );
  };

  const getMoreData = () => {
    getData(pageSize, currentPage * pageSize, true);
  };

  const handleEditClick = (item) => {
    setEditMode(true);
    setEditedBlog({
      id: item.id,
      name: item.name,
      body: item.body,
    });
  };

  const handleEditSubmit = (values) => {
    const updatedBlog = {
      id: editedBlog.id,
      userId: sessionStorage?.userId,
      subject: "string",
      name: editedBlog.name,
      body: values.editedMessage,
      isActive: true,
      referenceCode: tenderId,
      lastmodified: moment().format(),
    };

    dispatch(updateBlogMessage(editedBlog.id, updatedBlog));

    setEditMode(false);
    setEditedBlog({});
  };

  const onFinish = (values) => {
    let data = {};
    if (values) {
      data = {
        userId: sessionStorage?.userId,
        referenceCode: tenderId,
        body: values.message,
        creationTime: moment().format(),
        subject: "string",
        isActive: true,
      };
      // dispatch(blogAdd(data));
      try {
        dispatch(blogAdd(data));
      } catch (err) {
        console.log("error: ", err);
      } finally {
        form.resetFields();
      }
    }
  };

  return (
    <div className="blog-content">
      <div id="blogDiv" ref={scrollableRef}>
        {getBlogMsgPaginationLoading && (
          <Skeleton paragraph={{ rows: 10 }} active />
        )}

        {!isEmpty(getBlogMsgPaginationResp) ? (
          <InfiniteScroll
            style={{ display: "flex", flexDirection: "column-reverse" }}
            dataLength={getBlogMsgPaginationResp.items.length}
            inverse={true}
            hasMore={
              getBlogMsgPaginationResp.totalCount >
              getBlogMsgPaginationResp.items.length
            }
            next={getMoreData}
            loader={<Skeleton paragraph={{ rows: 4 }} active />}
            scrollableTarget="blogDiv"
            endMessage={
              <p style={{ textAlign: "center" }}>
                {getBlogMsgPaginationResp?.items?.length > 10 ? (
                  <b>End of blog</b>
                ) : null}
              </p>
            }
          >
            <BlogItem
              blogMsg={blogMsg}
              appUserIdUC={appUserIdUC}
              editMode={editMode}
              setEditMode={setEditMode}
              editedBlog={editedBlog}
              handleEditClick={handleEditClick}
              handleEditSubmit={handleEditSubmit}
              listingAllProfile={listingAllProfile}
            />
          </InfiniteScroll>
        ) : (
          <p style={{ textAlign: "center" }}>
            <b>No Data</b>
          </p>
        )}
      </div>
      <BlogForm
        form={form}
        onFinish={onFinish}
        loading={addBlogMessageLoading}
      />
    </div>
  );
};

export default Blog;
