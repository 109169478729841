import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Modal, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import { WechatOutlined } from "@ant-design/icons";
import InternalMCompose from "components/internalMNew";
import { handleSpecialDate } from "utils/tooltipSepecialDate";
import getCashColumnsCEMAT from "./BudgetViewsCEMAT/CashFlowColumn.js";
import BudgetModal1 from "../Modal/BudgetModal.js";
import IncomeModal from "../Modal/IncomeModal.js";

const CashFlow = (props) => {
  const { dashboardFinancialResponse, dashboardFinancialResponseLoading } =
    props;

  const {
    dashboardBudgetIncomeExpenseResponse,
    dashboardBudgetIncomeExpenseResponseLoading,
  } = useSelector((state) => state.fiscalYearState);

  const [form] = Form.useForm();
  const [widthh, setWidthh] = useState(window.innerWidth);
  const [rotate, setRotate] = useState(0);
  const [modalVisible, setModalVisible] = useState({
    income: false,
    expense: false,
    budget: false,
  });
  const [modalData, setModalData] = useState({
    dTypeIncome: 0,
    query: "",
    queryTypeFormula: "",
    colTitle: "",
  });

  const [internalVisible, setInternalVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => setWidthh(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const modalShow = (query, type, extraFormula, title) => {
    let dType = 0;
    let modalVisibleState = {
      income: false,
      expense: false,
      budget: false,
    };

    if (
      type === "fee income" ||
      type === "other income" ||
      type === "total income"
    ) {
      modalVisibleState.income = true;
      if (type === "fee income") {
        dType = 1;
      } else if (type === "other income") {
        dType = 2;
      }
    } else if (type === "expense") {
      modalVisibleState.expense = true;
    } else if (type === "budget") {
      modalVisibleState.budget = true;
    }

    setModalVisible(modalVisibleState);
    setModalData({
      query,
      dTypeIncome: dType,
      queryTypeFormula: extraFormula,
      colTitle: title,
    });
  };

  const columns = getCashColumnsCEMAT({
    widthh,
    modalShow,
  });

  const totalOpeningBal =
    dashboardBudgetIncomeExpenseResponse?.cashFlowIncomeExpense?.reduce(
      (total, item) => total + item.openingBal,
      0
    );

  const totalDebit =
    dashboardBudgetIncomeExpenseResponse?.cashFlowIncomeExpense?.reduce(
      (total, item) => total + item.debit,
      0
    );
  const totalCredit =
    dashboardBudgetIncomeExpenseResponse?.cashFlowIncomeExpense?.reduce(
      (total, item) => total + item.credit,
      0
    );
  const totalClosingBal =
    dashboardBudgetIncomeExpenseResponse?.cashFlowIncomeExpense?.reduce(
      (total, item) => total + item.closingBal,
      0
    );

  const totalRow = {
    key: "total",
    companyName: "Total",
    openingBal: totalOpeningBal,
    debit: totalDebit,
    credit: totalCredit,
    closingBal: totalClosingBal,
  };

  const dataSource = [
    ...(dashboardBudgetIncomeExpenseResponse?.cashFlowIncomeExpense || []),
    totalRow,
  ];

  return (
    <div className=" honor-wrapper" style={{ paddingRight: 0 }}>
      <div
        className=" dashboards__events dashboards__body "
        style={{ height: 275 }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">Cash Flow (Fiscal Year)</h4>
          <WechatOutlined
            onClick={() => {
              setInternalVisible(true);
            }}
          />
        </div>
        <Table
          className="next-organizer-table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          // scroll={{ y: 400, x: 400 }}
          style={{ maxHeight: 200, overflow: "auto" }}
          rowKey="key"
          // sticky
        />
      </div>
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="Cash Flow (Fiscal Year)"
        />
      </Modal>

      {(modalVisible.budget || modalVisible.expense) && (
        <BudgetModal1
          modalVisible={modalVisible.budget || modalVisible.expense}
          setModalVisible={setModalVisible}
          modalData={modalData}
          type="cashflow"
        />
      )}

      {modalVisible?.income && (
        <IncomeModal
          modalVisible={modalVisible.income}
          setModalVisible={setModalVisible}
          modalData={modalData}
          type="cashflow"
        />
      )}
    </div>
  );
};

export default CashFlow;
