import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, DatePicker } from "antd";
import ButtonForForm from "./buttons";

import { actionForFinalApprove } from "services/redux/hrSystem/action";
import { isEmpty } from "lodash";
import moment from "moment";

function ApproveDetails({
  jobTitleId,
  selectedSingleApplicantDAta,
  setOpenApplicantDrawer,
  setSelectedSingleApplicantData,
  formApplicantEdu,
  formApplicantsBasic,
  formApplicantJob,
  formApplicantReview,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [hiredate, setHiredate] = useState();

  const onFinishJobDetails = (values) => {
    const apiData = {
      username: values.username,
      password: values.password,
      hireDate: hiredate,
      phoneNumber: values.phoneNumber,
    };

    dispatch(actionForFinalApprove(selectedSingleApplicantDAta?.id, apiData));
  };

  const convertForm = () => {
    return (
      <>
        <h5>Convert into Employee</h5>
        <Form
          // form={formApplicantJob}
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinishJobDetails}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Hire Date"
            name="hireDate"
            rules={[
              {
                required: true,
                message: "Hired date is required",
              },
            ]}
          >
            <DatePicker
              placeholder="Select date"
              onChange={(value) => {
                setHiredate(
                  value ? moment(value._d).format("YYYY-MM-DD") : null
                );
              }}
            />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true, message: "Phone number is required" }]}
          >
            <Input />
          </Form.Item>

          <ButtonForForm
            setOpenApplicantDrawer={setOpenApplicantDrawer}
            setSelectedSingleApplicantData={setSelectedSingleApplicantData}
            formApplicantsBasic={formApplicantsBasic}
            formApplicantEdu={formApplicantEdu}
            formApplicantJob={formApplicantJob}
            formApplicantReview={formApplicantReview}
          ></ButtonForForm>
        </Form>
      </>
    );
  };

  return (
    <>
      {selectedSingleApplicantDAta?.isApplicantMigrateIntoEmployee ? (
        <h5>Already Converted into Employee</h5>
      ) : (
        convertForm()
      )}
    </>
  );
}

export default ApproveDetails;
