import moment from "moment";
import { useEffect, useState } from "react";
import {
  LoadingOutlined,
  CloseOutlined,
  BellOutlined,
} from "@ant-design/icons";
import {
  Col,
  Divider,
  Dropdown,
  Form,
  Layout,
  Modal,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { isArray, isEmpty, upperCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  changeEmailStatusTypeInstanceMessage,
  instanceMessageDelete,
  replyInstanceMessage,
} from "services/redux/email/instanceMessageCenter/action";
import { changeEmailStatusType } from "services/redux/email/mailMessageCenter/action";
import { fileDownload, getParticipantsEmails } from "utils/commonFunc";
import { setSelectedFileName } from "../../services/redux/common/persistFiles/action";
import { cqBaseUrl } from "../../utils/config";
import storage from "../../utils/storage";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
import pdfViewer from "components/Documents/ViewDocument";
import AllFileViewer from "components/Documents/allFileViewer";

const Reply = "assets/svg/reply.svg";
const ReplyAll = "assets/svg/replyAll.svg";
const Forward = "assets/svg/next.svg";
const attachment = "assets/svg/attatchment.svg";
const archieve = "./assets/svg/archive-12.svg";
const attachmentView = "assets/svg/attachment-view.svg";
const attachmentDownload = "assets/svg/attachment-download.svg";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const newWindow = "assets/svg/newWindow.svg";
const EmailChat = ({
  handleReply,
  setActiveChat,
  instanceMessageCenterPagination,
  setReplyofChat,
  activeChat,
  instanceMessageCenterPaginationLoading,
  replyInstanceMessageResp,
  corporateSharedAccessAccountHolderUserId,
  messageCenterTypeName,
  currentSelectedMailMessage,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const [showAllAttachments, setShowAllAttachments] = useState(false);
  const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);
  const [widthh, setWidthh] = useState(window.innerWidth);
  const [modalVisible, setModalVisible] = useState(false);
  const currentUserId = storage.getItem("userId");
  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  useEffect(() => {
    const handleResize = () => setWidthh(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const ackKnowledgeMessage = (msgdetails) => {
    let userId = storage.getItem("userId");
    let participantTypeTos =
      msgdetails?.mailMessageParticipantWithTypeDtos?.participantTos.map(
        (typeTos) => {
          return { participantUserId: typeTos.participantUserId };
        }
      );

    let participantTypeCCs = !isEmpty(
      msgdetails?.mailMessageParticipantWithTypeDtos?.participantCCs
    )
      ? msgdetails?.mailMessageParticipantWithTypeDtos?.participantCCs.map(
          (typeTos) => {
            return { participantUserId: typeTos.participantUserId };
          }
        )
      : [];

    let participantTypeFrom = [
      {
        participantUserId:
          msgdetails?.mailMessageParticipantWithTypeDtos?.participantFrom
            .participantUserId,
      },
    ];

    let filterParticipantTos = participantTypeFrom;
    let filterParticipantCcs = participantTypeCCs.filter(
      (filteredCcs) => filteredCcs.participantUserId !== userId
    );
    let newQuickMessageParticipantDtos = participantTypeTos.filter(
      (filterTos) => filterTos.participantUserId !== userId
    );
    newQuickMessageParticipantDtos =
      newQuickMessageParticipantDtos.concat(filterParticipantCcs);

    if (
      msgdetails?.mailMessageParticipantWithTypeDtos?.participantFrom
        .participantUserId === userId
    ) {
      filterParticipantTos = participantTypeTos.filter(
        (filterTos) => filterTos.participantUserId !== userId
      );
      let filterParticipantCcs = participantTypeCCs.filter(
        (filteredCcs) => filteredCcs.participantUserId !== userId
      );
      newQuickMessageParticipantDtos = filterParticipantCcs;
    }
    let body = {
      applicationId: selectedMailTypeAndMailData?.selectedMail?.applicationId,
      // applicationTypeId: msgdetails.applicationTypeId,
      instanceMessageActionName: "reply",
      instanceMessageAttachmentDtos: [],
      instanceMessageStatusName: "Normal",
      internalChatId: selectedMailTypeAndMailData?.selectedMail?.id,
      mailMessageParticipantTableId: msgdetails.mailMessageParticipantTableId,
      mailMessageCenterId: selectedMailTypeAndMailData?.selectedMail?.id,
      applicationTypeId:
        selectedMailTypeAndMailData?.selectedMail?.applicationType === "EMail"
          ? "4f24a8cc-d015-4b63-acfd-a6ca4dd6c5b3"
          : selectedMailTypeAndMailData?.selectedMail?.applicationTypeId,
      //mailMessageParticipantTableId: msgdetails.mailMessageParticipantTableId,
      mailMessageParticipantWithTypeDtos: {
        participantCCs: newQuickMessageParticipantDtos,
        participantTos: participantTypeFrom,
      },
      messageText:
        '<p style="font-family: Arial; font-size: 10pt;">Message has been acknowledged</p>',
      replyOfInstanceMessageId: msgdetails.id,
    };

    dispatch(replyInstanceMessage(body));
  };

  useEffect(() => {
    if (!isEmpty(instanceMessageCenterPagination.items)) {
      if (replyInstanceMessageResp) {
        if (instanceMessageCenterPagination?.changeIndex)
          setActiveChat(instanceMessageCenterPagination.items[0].id);
      }
      let lists = [...popoverMenuVisible];
      instanceMessageCenterPagination.items.map((item, i) => {
        lists.push(false);
      });
      setPopoverMenuVisible(lists);
    }
  }, [instanceMessageCenterPagination, replyInstanceMessageResp]);

  const handleView = (src) => {
    setTimeout(() => {
      // console.log("pdfViewer", pdfViewer);
      pdfViewer1(src);
    }, 1000);
  };

  const getActionContent = (msg, index) => {
    const participantFromId =
      msg.mailMessageParticipantWithTypeDtos?.participantFrom
        ?.participantUserId;
    const isUserMsg = currentUserId === participantFromId ? true : false;
    return (
      <div className="guest-actions">
        {isUserMsg && (
          <>
            {/* <div
              className="guest-act"
              onClick={() => {
                handleReply(msg, "edit");
                handleVisibleChange(index);
              }}
            >
              Edit
            </div>
            <div
              className="guest-act"
              onClick={() => {
                deleteInstanceMessage(msg);
                handleVisibleChange(index);
              }}
            >
              Delete
            </div> */}
          </>
        )}
        <div
          className="guest-act"
          onClick={() => {
            let query = {
              messageCenterId: msg.mailMessageCenterId,
              participantStatusName: "Read",
              instanceMessageId: msg.id,
            };
            dispatch(changeEmailStatusType(query));
          }}
        >
          Mark as Read
        </div>
      </div>
    );
  };

  const showAttatchments = (msg, instant) => {
    const menu = (
      <div className={`dropdown-attatchmemnt hereeeeee ${instant}`}>
        {!isEmpty(msg?.instanceMessageAttachmentDtos) &&
          msg?.instanceMessageAttachmentDtos.map((fileList, i) => {
            const fileType = fileList.displayFileName.split(".").pop();
            return (
              <div
                key={i}
                className="attachmentItem"
                // style={{ background: "red", color: "white" }}
              >
                {/* <img
                  src={attachment}
                  style={{ paddingRight: 8 }}
                  alt="attachment"
                />
                {fileType === "pdf" ||
                fileType === "jpg" ||
                fileType === "jpeg" ? (
                  <div className="attachment--info-wrapper">
                    <span className="attachment--file-name">
                      {fileList.displayFileName}
                    </span>
                    <div className="single-attachment-action-wrapper">
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          color: "#67a1e4",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setModalVisible(true);
                          handleView(
                            `${cqBaseUrl}/${fileList?.viewFileURL}${token}`
                          );
                        }}
                      >
                        <img src={attachmentView} alt="attachment" />
                      </button>
                      {modalVisible && (
                        <Modal
                          visible={modalVisible}
                          title={
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "20fr 0.5fr 0.5fr ",
                              }}
                            >
                              <span>Document Viewer</span>
                              <span
                                onClick={() => {
                                  pdfViewer(
                                    `${cqBaseUrl}/${fileList?.viewFileURL}${token}`
                                  );
                                }}
                              >
                                <img
                                  src={newWindow}
                                  height={15}
                                  style={{ marginBottom: "5px" }}
                                  alt="new"
                                />
                              </span>
                              <span
                                onClick={() => {
                                  setModalVisible(false);
                                }}
                              >
                                <CloseOutlined />
                              </span>
                            </div>
                          }
                          footer={null}
                          closable={false}
                          onCancel={() => {
                            setModalVisible(false);
                          }}
                          width="70%"
                        >
                          {modalVisible && (
                            <iframe
                              title="pdfViewer"
                              id="pdfViewerFrame"
                              width="100%"
                              height="500px"
                            />
                          )}
                        </Modal>
                      )}
                      <a
                        onClick={() => {
                          fileDownload(
                            `${cqBaseUrl}/${fileList?.viewFileURL}${token}`,
                            fileList?.displayFileName
                          );
                        }}
                      >
                        <img src={attachmentDownload} alt="attachment" />
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="attachment--info-wrapper">
                    <a
                      target="_blank"
                      href={`${cqBaseUrl}/${fileList?.viewFileURL}${token}`}
                      download
                      rel="noreferrer"
                    >
                      <span className="attachment--file-name">
                        <span className="attach-filename">
                          {fileList.displayFileName.slice(
                            0,
                            fileList.displayFileName.length - 5
                          )}
                        </span>
                        <span className="attach-extension">
                          {fileList.displayFileName.slice(
                            fileList.displayFileName.length - 5,
                            fileList.displayFileName.length
                          )}
                        </span>
                      </span>
                    </a>
                    <div className="single-attachment-action-wrapper">
                      <a
                        href={`${cqBaseUrl}/${fileList?.viewFileURL}${token}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={attachmentView} alt="view" />
                      </a>
                      <a
                        href={`${cqBaseUrl}/${fileList?.viewFileURL}${token}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={attachmentDownload} alt="download" />
                      </a>
                    </div>
                  </div>
                )} */}
                <AllFileViewer
                  file={fileList}
                  cqBaseUrl={cqBaseUrl}
                  token={token}
                />
              </div>
            );
          })}

        <Divider style={{ margintTop: "", margintbottom: "" }} />

        {!isEmpty(msg?.instanceMessageAttachmentDtos) &&
          msg?.instanceMessageAttachmentDtos.length > 1 && (
            <div className="attachment-download-all-wrapper">
              <button
                className="attachment-download-all-btn"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  msg?.instanceMessageAttachmentDtos.map((fileList, i) => {
                    fileDownload(
                      `${cqBaseUrl}/${fileList?.viewFileURL}${token}`,
                      fileList?.displayFileName
                    );
                  });
                }}
              >
                Download All
              </button>
            </div>
          )}
      </div>
    );

    return (
      <div>
        <Dropdown overlay={menu}>
          <a onClick={(e) => e.preventDefault()}>
            <span>
              <img
                src="/assets/svg/chervon_down.svg"
                style={{ height: "auto", width: "auto" }}
              />
            </span>
          </a>
        </Dropdown>
      </div>
    );
  };

  const changeEmailStatus = async (msg, emailCat) => {
    let toAck = msg?.mailMessageParticipantWithTypeDtos?.participantTos?.find(
      (x) => {
        return (
          x?.participantUserId == currentUserId && x?.isAcknowledged === true
        );
      }
    );
    let ccAck = msg?.mailMessageParticipantWithTypeDtos?.participantCCs?.find(
      (x) => {
        return (
          x?.participantUserId == currentUserId && x?.isAcknowledged === true
        );
      }
    );

    let emailStatus = {
      isAcknowledged: toAck === true || ccAck === true ? false : true,
    };
    let query = {
      // messageCenterId: selectedMailTypeAndMailData?.selectedMail?.id,
      // messageCenterId:
      //   emailCat === "ack" ? msg?.id : emailCat === "reminder" ? msg?.id : "",
      messageCenterId: msg?.id,
      typeSystemName: selectedMailTypeAndMailData?.typeDisplayName,
      mailMessageParticipantTableId:
        selectedMailTypeAndMailData?.selectedMail
          ?.mailMessageParticipantTableId,
      participantStatusName:
        emailCat === "reminder"
          ? "RemainderCount"
          : emailCat === "ack" &&
            (!isEmpty(toAck) === true || !isEmpty(ccAck) === true)
          ? "UnAcknowledged"
          : "Acknowledged",
      instanceMessageId:
        selectedMailTypeAndMailData?.selectedMail?.applicationTypeSystemName ===
        "InstanceMessage"
          ? selectedMailTypeAndMailData?.selectedMail?.latestInstanceMessageId
          : "",
      instanceMainMessageCenterId:
        selectedMailTypeAndMailData?.selectedMail?.instanceMainMessageCenterId,

      CorporateSharedAccessAccountHolderUserId:
        corporateSharedAccessAccountHolderUserId,
    };

    if (emailCat !== "reminder") {
      dispatch(
        // changeEmailStatusType(query, status, messageCenterTypeName, emailStatus)
        changeEmailStatusType(query, "", messageCenterTypeName, emailStatus)
      );
    } else {
      dispatch(
        changeEmailStatusTypeInstanceMessage(
          query,
          "",
          messageCenterTypeName,
          emailStatus
        )
      );
    }
  };

  const getAckUnAckLabel = (msg) => {
    let toAck = msg?.mailMessageParticipantWithTypeDtos?.participantTos?.find(
      (x) => {
        return (
          x?.participantUserId == currentUserId && x?.isAcknowledged === true
        );
      }
    );
    let ccAck = msg?.mailMessageParticipantWithTypeDtos?.participantCCs?.find(
      (x) => {
        return (
          x?.participantUserId == currentUserId && x?.isAcknowledged === true
        );
      }
    );

    return !isEmpty(toAck) === true
      ? "UnAck Msg"
      : !isEmpty(ccAck) === true
      ? "UnAck Msg"
      : "Ack Msg";
  };

  return (
    <>
      {instanceMessageCenterPaginationLoading ? (
        <div>{antIcon}</div>
      ) : !isEmpty(instanceMessageCenterPagination.items) ? (
        instanceMessageCenterPagination?.items?.map((msg, j) => {
          let participantTypeFrom =
            msg?.mailMessageParticipantWithTypeDtos?.participantFrom;

          let participantToRead =
            msg?.mailMessageParticipantWithTypeDtos?.participantTos?.filter(
              (data) => data?.isReadMessage == true
            );
          let participantToUnread =
            msg?.mailMessageParticipantWithTypeDtos?.participantTos?.filter(
              (data) => data?.isReadMessage == false
            );

          let participantToNames = getParticipantsEmails(
            msg?.mailMessageParticipantWithTypeDtos?.participantTos
          );
          let newParticipantToNames = participantToNames;
          if (participantToNames.split(",").length > 4) {
            participantToNames = participantToNames
              .split(",", 4)
              .join(",")
              .concat(" ...");
          }

          let participantCCNames = getParticipantsEmails(
            msg?.mailMessageParticipantWithTypeDtos?.participantCCs
          );
          let newParticipantCCNames = participantCCNames;

          if (participantCCNames.split(",").length > 4) {
            participantCCNames = participantCCNames
              .split(",", 4)
              .join(",")
              .concat("...");
          }
          let profilePicNameFL = "";
          if (participantTypeFrom?.participantUserDto?.fullName) {
            let name = participantTypeFrom?.participantUserDto?.fullName;
            profilePicNameFL = name.match(/\b(\w)/g);
            profilePicNameFL = upperCase(profilePicNameFL.join(""));
          }

          const profilePicture =
            participantTypeFrom?.participantUserDto?.profilePictureViewUrl;

          return (
            msg?.isActive && (
              <div
                className={`chatItem ${activeChat === msg.id ? "active" : ""}`}
                key={j}
              >
                <div className="chatItem__wrapper">
                  <div className="chatItem__head">
                    <div
                      className={`chatInfo__wrapper ${
                        currentUserId ===
                          participantTypeFrom?.participantUserId && "active"
                      }`}
                    >
                      <div className="chatHead_wrap">
                        <div
                          className="chatItem__left"
                          style={{ width: "65%" }}
                        >
                          <div className="chatItem__left--content">
                            <h5 className="chatItem__title">
                              <div
                                className="my-chat-info-wrapper"
                                // style={{ paddingTop: "5px" }}
                              >
                                <Tooltip
                                  placement="topLeft"
                                  // overlayStyle={{
                                  //   maxWidth: "500px",
                                  //   display: "block",
                                  // }}
                                  title={
                                    <>
                                      <Row style={{ width: 350 }}>
                                        <Col span={3}>From:</Col>
                                        <Col span={21}>
                                          {participantTypeFrom?.participantUserDto?.userName?.toUpperCase()}
                                        </Col>
                                        <Col span={3}>To:</Col>
                                        <Col
                                          span={21}
                                          style={{
                                            wordWrap: true,
                                            wordBreak: "normal",
                                          }}
                                        >
                                          {msg?.mailMessageParticipantWithTypeDtos?.participantTos?.map(
                                            (x) => {
                                              return (
                                                <Typography.Text
                                                  style={{
                                                    color: x?.isAcknowledged
                                                      ? "red"
                                                      : x?.isReadMessage &&
                                                        !x?.isAcknowledged
                                                      ? "green"
                                                      : "black",
                                                  }}
                                                >
                                                  <span className="chat-to-info">
                                                    {x?.participantUserDto?.userName?.toUpperCase()}
                                                    ,
                                                  </span>
                                                </Typography.Text>
                                              );
                                            }
                                          )}
                                        </Col>

                                        {!isEmpty(participantCCNames) && (
                                          <>
                                            <Col span={3}>Cc:</Col>
                                            <Col
                                              span={21}
                                              style={{
                                                wordWrap: true,
                                                wordBreak: "normal",
                                              }}
                                            >
                                              {msg?.mailMessageParticipantWithTypeDtos?.participantCCs?.map(
                                                (x) => {
                                                  return (
                                                    <Typography.Text
                                                      style={{
                                                        color: x?.isAcknowledged
                                                          ? "red"
                                                          : x?.isReadMessage &&
                                                            !x?.isAcknowledged
                                                          ? "green"
                                                          : "black",
                                                      }}
                                                    >
                                                      <span className="chat-to-info">
                                                        {x?.participantUserDto?.userName?.toUpperCase()}
                                                        ,
                                                      </span>
                                                    </Typography.Text>
                                                  );
                                                }
                                              )}
                                            </Col>
                                          </>
                                        )}
                                      </Row>
                                    </>
                                  }
                                >
                                  <div style={{ display: "flex", gap: "5px" }}>
                                    <div
                                      className="left_one"
                                      style={{
                                        width: widthh < 1524 ? "20%" : "15%",
                                      }}
                                    >
                                      <div style={{ paddingBottom: "4px" }}>
                                        From:
                                      </div>
                                      <div style={{ paddingBottom: "4px" }}>
                                        To:
                                      </div>
                                      {!isEmpty(participantCCNames) && (
                                        <div>Cc</div>
                                      )}
                                    </div>
                                    <div className="right_one">
                                      <Typography.Paragraph
                                        style={{
                                          color: "black",
                                          paddingBottom: "4px",
                                        }}
                                      >
                                        {" "}
                                        {participantTypeFrom?.participantUserDto?.userName?.toUpperCase()}{" "}
                                      </Typography.Paragraph>
                                      <div style={{ paddingBottom: "4px" }}>
                                        {msg?.mailMessageParticipantWithTypeDtos?.participantTos?.map(
                                          (x) => {
                                            return (
                                              <Typography.Text
                                                style={{
                                                  color: x?.isAcknowledged
                                                    ? "red"
                                                    : x?.isReadMessage &&
                                                      !x?.isAcknowledged
                                                    ? "green"
                                                    : "black",
                                                }}
                                              >
                                                <span className="chat-to-info">
                                                  {x?.participantUserDto?.userName?.toUpperCase()}
                                                  ,
                                                </span>
                                              </Typography.Text>
                                            );
                                          }
                                        )}
                                      </div>

                                      {!isEmpty(participantCCNames) &&
                                        msg?.mailMessageParticipantWithTypeDtos?.participantCCs?.map(
                                          (x) => {
                                            return (
                                              <Typography.Text
                                                style={{
                                                  color: x?.isAcknowledged
                                                    ? "red"
                                                    : x?.isReadMessage &&
                                                      !x?.isAcknowledged
                                                    ? "green"
                                                    : "black",
                                                }}
                                              >
                                                <span className="chat-to-info">
                                                  {x?.participantUserDto?.userName?.toUpperCase()}
                                                  ,
                                                </span>
                                              </Typography.Text>
                                            );
                                          }
                                        )}
                                    </div>
                                  </div>
                                </Tooltip>
                              </div>
                            </h5>
                          </div>
                        </div>
                        <div className="chatItem__right">
                          <div className="chatItem__right--date">
                            <span className="chat-date-item">
                              {moment(msg.creationTime).format("YYYY") !==
                              moment(new Date()).format("YYYY")
                                ? moment(msg.creationTime).format("DD/MM/YYYY")
                                : moment(msg.creationTime).calendar({
                                    sameDay: "h:mm a",
                                    nextDay: "[Tomorrow]",
                                    nextWeek: "DD MMM",
                                    lastDay: "[Yesterday]",
                                    lastWeek: "DD MMM",
                                    sameElse: "DD MMM",
                                  })}
                            </span>
                            [
                            <span className="reply-counter-item">
                              {msg.orderNumber}
                            </span>
                            {msg.replyOfInstanceMessageOrderNumber && (
                              <div>
                                , Reply of{" "}
                                {msg.replyOfInstanceMessageOrderNumber}
                              </div>
                            )}
                            ]
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "-14px",
                          marginBottom: "-14px",
                        }}
                      >
                        <Divider />
                      </div>
                      <div className="chatItem__body">
                        <div className="message_body_wrapper">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: msg?.messageText,
                            }}
                          ></p>
                        </div>
                      </div>

                      {!isEmpty(msg?.instanceMessageAttachmentDtos) && (
                        <ul className="chat_attachment_list instantchat-attachment-wrapper">
                          {!isEmpty(msg?.instanceMessageAttachmentDtos) && (
                            <div
                              className="email-attachments quick-msg-attach chat-attachment-item"
                              style={{
                                height: showAllAttachments ? "100%" : 40,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                              }}
                            >
                              <img
                                alt="attachment-chat"
                                className="attachmentImage-chat"
                                style={{
                                  height: 17,
                                  paddingTop: 5,
                                  width: "auto",
                                }}
                                src={attachment}
                              />{" "}
                              <span className="attachment_lenght_count">
                                {msg?.instanceMessageAttachmentDtos.length}{" "}
                              </span>
                              {showAttatchments(msg, "instantHover")}
                            </div>
                          )}
                        </ul>
                      )}
                      {/* {currentUserId ===
                      participantTypeFrom?.participantUserId ? (
                        <div
                        // className="chat-read-unread-icon-wrapper"
                        >
                          <p>Reminder: {msg?.remainderCount}</p>
                        </div>
                      ) : (
                        ""
                      )} */}
                      <div className="chat__replayIcon_wrapper">
                        <div className="chatItem__right--icons">
                          <div className="chat-action-wrapper">
                            {currentUserId ===
                              participantTypeFrom?.participantUserId && (
                              <>
                                <a
                                  onClick={() =>
                                    changeEmailStatus(msg, "reminder")
                                  }
                                  className={`ant-image noneIcon`}
                                >
                                  <span className="chatReply-desc">
                                    Reminder
                                  </span>
                                  <BellOutlined
                                    style={{
                                      color: "grey",
                                      paddingLeft: "11px",
                                    }}
                                  />
                                </a>
                              </>
                            )}

                            {currentUserId !=
                              participantTypeFrom?.participantUserId && (
                              <>
                                <a
                                  // onClick={() => {
                                  //   ackKnowledgeMessage(msg, "ackCheck");
                                  // }}
                                  onClick={() => changeEmailStatus(msg, "ack")}
                                  className="ant-image noneIcon"
                                >
                                  <span className="chatReply-desc">
                                    {getAckUnAckLabel(msg)}
                                  </span>

                                  <img src={archieve} alt="" />
                                </a>
                              </>
                            )}
                            <a
                              onClick={() => {
                                handleReply(msg, "forward");
                              }}
                              className="ant-image noneIcon"
                            >
                              <span className="chatReply-desc">Forward</span>
                              <img src={Forward} />
                            </a>
                            <a
                              onClick={() => {
                                handleReply(msg, "reply");
                              }}
                              className={`ant-image noneIcon`}
                            >
                              <span className="chatReply-desc">Reply</span>
                              <img src={Reply} />
                            </a>
                            <a
                              onClick={() => {
                                handleReply(msg, "replyall");
                              }}
                              className="ant-image"
                            >
                              <span className="chatReply-desc noneIcon">
                                Reply All
                              </span>
                              <img src={ReplyAll} />
                            </a>

                            {/* <Popover
                                    content={() => getActionContent(msg, j)}
                                    title="Action"
                                    trigger="click"
                                    className="tooltips"
                                    visible={popoverMenuVisible[j]}
                                    onVisibleChange={() => handleVisibleChange(j)}
                                    placement="bottomRight"
                                  >
                                    <MoreOutlined style={{ fontSize: 18 }} />
                                  </Popover> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {currentUserId !=
                      participantTypeFrom?.participantUserId && (
                      <div className="acknowledge-check-wrapper">
                        <div className="acknowledge-icons-wrapper">
                          <div className="acknowledge-icons">
                            <ul>
                              <Tooltip
                                title={
                                  <Typography.Text>
                                    Message has been acknowledged
                                  </Typography.Text>
                                }
                                placement="leftBottom"
                              >
                                <li className="d-none">
                                  <input
                                    id="ak-read"
                                    onChange={() => {
                                      ackKnowledgeMessage(msg, "ackCheck");
                                    }}
                                    type="checkbox"
                                  />
                                  <label htmlFor="ak-read">
                                    <img src={archieve} />
                                  </label>
                                </li>
                              </Tooltip>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )} */}

                    {false &
                    (currentUserId !=
                      participantTypeFrom?.participantUserId) ? (
                      <div className="chat-read-unread-icon-wrapper">
                        {/* <CheckCircleOutlined /> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )
          );
        })
      ) : (
        ""
      )}
    </>
  );
};

export default EmailChat;
