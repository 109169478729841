import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Tag, message, Form } from "antd";
import moment from "moment";
import { LogoImage } from "utils/imageConstants";
import { honorRollsPagination } from "services/redux/officeresource/honorroll/action";
import { noticePagination } from "services/redux/absent/absent.action";
import { getNoticeBoardPagination } from "services/redux/absent/absent.action";
import { honorRollGetDetails } from "../../../services/remanentCalls";
import { isEmpty } from "lodash";
const NoticeListTable = ({
  showAddUpdateCountryDrawer,
  isUpdateCountry,
  startDate,
  endDate,
}) => {
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      render: (text, row, index) => <div>{index + 1}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      // sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Start Date",
      key: "startDate",
      render: (record) => {
        return record.startDate
          ? moment(record.startDate).format("YYYY-MM-DD")
          : "----";
      },
    },
    {
      title: "End Date",
      key: "endDate",
      render: (record) => {
        return record.endDate
          ? moment(record.endDate).format("YYYY-MM-DD")
          : "---";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a
          className="action-btn action-btn-add"
          onClick={(e) => showAddUpdateCountryDrawer(e, record)}
        >
          Edit Notice Board
        </a>
      ),
    },
  ];

  const pageSize = 10;
  const dispatch = useDispatch();
  const [noticeData, setNoticeData] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const [noticeBoard, setNoticeBoard] = useState([]);
  const { noticePaginationResponse, noticePaginationLoading } = useSelector(
    (state) => state.absent
  );

  useEffect(() => {
    getNoticeDataList();
  }, [startDate, endDate]);
  useEffect(() => {
    if (!noticePaginationResponse) return;
    setNoticeData(noticePaginationResponse);
  }, [noticePaginationResponse]);

  const getNoticeDataList = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    SearchDateFrom = startDate,
    SearchDateTo = endDate,
    SortType
  ) => {
    let appendUrl = `maxResultCount=${MaxResultCount}&SearchDateFrom=${SearchDateFrom}&SearchDateTo=${SearchDateTo}`;
    dispatch(noticePagination(appendUrl));
  };
  // const handleNoticeTable = (pagination, filters, sorter, extra) => {
  //   let MaxResultCount = pageSize;
  //   let SkipCount = (pagination.current - 1) * pagination.pageSize;
  //    getNoticeDataList(MaxResultCount, SkipCount);
  //    getNoticeDataList(pagination.current, pagination.pageSize);
  // };
  return (
    <>
      <Table
        dataSource={noticePaginationResponse?.items}
        // onChange={handleNoticeTable}
        className="organizer__tables"
        columns={columns}
        rowKey="appUserId"
      />
    </>
  );
};

export default NoticeListTable;
