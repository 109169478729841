import { appBaseUrl } from "utils/config";
import queryString from "query-string";
import { getService, postService } from "../../../commonServices";

export class SisterCompanyService {
  getSisterCompaniesListings() {
    let url = `${appBaseUrl}/organizerEvent/sisterCompanyListing`;
    let data = getService(url);
    return data;
  }

  getSisterCompanyPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/organizerEvent/sisterCompanyPagination?${query}`;
    let data = getService(url);
    return data;
  }

  updateSisterCompany(body) {
    let url = `${appBaseUrl}/organizerEvent/sisterCompanyAddUpdate`;
    let data = postService(url, body, 'post');
    return data;
  }
}
