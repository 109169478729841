import React, { useEffect, useState } from "react";
import Board,{moveCard} from '@asseinfo/react-kanban'
import CardTaskBox from "./taskCard";
// import { Row, Col } from "reactstrap";
// import CardTaskBox from "./taskCard";
import RenderCardTitle from "./HeaderComponents";
import { useDispatch } from "react-redux";
import { kanbanDrag } from "services/redux/organizer/todoLists/action";
import { map } from "lodash";

const ControlledBoard = props => {
  const dispatch = useDispatch();
  const [destinationData , setDestinationData] = useState(null)
  const content = props.board;
  const onCardMove =(board , card , source , destination)=>{
    dispatch(kanbanDrag(board?.id,source?.toColumnId))
  }
  const [controlledBoard, setBoard] = useState(content);
  useEffect(()=>{
    setBoard(content)
  },[])

  function handleCardMove(_card, source, destination) {
    const updatedBoard = moveCard(controlledBoard, source, destination);
    const data = map(updatedBoard,items=>({...items , items:updatedBoard?.columns}))
    dispatch(kanbanDrag(_card?.id,destination?.toColumnId , data?.[0]))
    setBoard(updatedBoard);
  }
  return (
    <React.Fragment>
      {/* <div>
        <h1>{content?.columns?.[0]?.statusName}</h1>
      </div> */}
          <Board
            children={controlledBoard}
            renderColumnHeader={({ statusName, badge }) => (
              <RenderCardTitle name={statusName} badge={badge} />
            )}
            renderCard={(data, { dragging }) => (
              <CardTaskBox data={data} dragging={dragging} content={controlledBoard} setHideInternalMessage={props?.setHideInternalMessage} >
                {data}
              </CardTaskBox>
            )}
            onNewCardConfirm={draftCard => ({
              id: new Date().getTime(),
              ...draftCard,
            })}
            onCardDragEnd = {handleCardMove}
            disableColumnDrag
          >
            {controlledBoard}
            </Board>
    </React.Fragment>
  );
};

export default ControlledBoard;
