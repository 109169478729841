import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash-es';

// antd
import { Table, Tag } from 'antd';

// utils
import { LogoImage } from 'utils/imageConstants';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { getLeaveApplicationTypePagination } from 'services/redux/admin/LeaveApplication/LeaveApplicationType/action';

const LeaveFiscalDayTable = ({ showAddUpdateLeaveApplicationType }) => {
	const columns = [
		{
			title: 'S.NO',
			dataIndex: '',
			key: '',
			render: (text, row, index) => <div>{index + 1}</div>,
		},
		{
			title: 'Leave Type Name',
			dataIndex: 'leaveTypeName',
			key: 'leaveTypeName',
		},
		{
			title: 'Allocated Days',
			dataIndex: 'allocatedDays',
			key: 'allocatedDays',
		},
		{
			title: 'Action',
			key: 'action',
			render: (record) => (
				<a className="action-btn action-btn-add" onClick={() => showAddUpdateLeaveApplicationType(record)}>
					Edit Application Type
				</a>
			),
		},
	];

	const dispatch = useDispatch();
	const { leaveApplicationTypePagination, leaveApplicationTypePaginationLoading } = useSelector((state) => state.leaveApplicationTypeState);

	const [data, setdata] = useState([]);
	const [totalCount, setTotalCount] = useState(10);
	const pageSize = 10;

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (isEmpty(leaveApplicationTypePagination?.items)) return;
		setdata(leaveApplicationTypePagination.items);
		setTotalCount(leaveApplicationTypePagination.totalCount);
	}, [leaveApplicationTypePagination]);

	const getData = (MaxResultCount = pageSize, SkipCount = 0, Sorting, SortType) => {
		dispatch(
			getLeaveApplicationTypePagination({
				MaxResultCount,
				SkipCount,
				Sorting,
				SortType,
			})
		);
	};

	return (
		<Table
			className="organizer__table contact-table"
			columns={columns}
			dataSource={data}
			loading={{
				indicator: <img src={LogoImage} height="auto" width="50px" />,
				spinning: leaveApplicationTypePaginationLoading,
			}}
			pagination={{
				total: totalCount,
				pageSize: pageSize,
			}}
		/>
	);
};

export default LeaveFiscalDayTable;
