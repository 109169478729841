import React, { useEffect } from "react";
import { isEmpty } from "lodash-es";

// antd
import { Drawer, Form, Button, Select, Input, Layout, Checkbox } from "antd";

// redux
import { useDispatch, useSelector } from "react-redux";

// utils
import { validateMessages } from "utils/misc";
import { leaveApplicationTypeAdd } from "services/redux/admin/LeaveApplication/LeaveApplicationType/action";
import { leaveApplicationTypeUpdate } from "services/redux/admin/LeaveApplication/LeaveApplicationType/action";

const { Header, Footer } = Layout;

const LeaveFiscalDayAddUpdateDrawer = ({
  onAddUpdateLeaveApplicationTypeClose,
  drawerVisibility,
  isUpdateLeaveApplicationType
}) => {
  const { addLeaveApplicationTypeResponse, updateLeaveApplicationTypeResponse } = useSelector(
    (state) => state.leaveApplicationTypeState
  );

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(isUpdateLeaveApplicationType)) {
      form.resetFields();
      return;
    }

    form.setFieldsValue({
      ...isUpdateLeaveApplicationType,
    });
  }, [isUpdateLeaveApplicationType]);

  useEffect(() => {
    onAddUpdateLeaveApplicationTypeClose();
  }, [addLeaveApplicationTypeResponse, updateLeaveApplicationTypeResponse]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (!isEmpty(isUpdateLeaveApplicationType)) {
        dispatch(leaveApplicationTypeUpdate(values, isUpdateLeaveApplicationType.id))
      } else {
        dispatch(leaveApplicationTypeAdd(values))
      }

    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onAddUpdateLeaveApplicationTypeClose}
      closable={false}
    >
      <Header className='drawer__header'>
        <h4 className='drawer__header--title'>
          {`${isUpdateLeaveApplicationType ? "Update" : "Create"} Leave Application Type`}
        </h4>
      </Header>
      <Form
        layout='vertical'
        form={form}
        name='control-hooks'
        validateMessages={validateMessages}
        className="drawer-form-wrapper"
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name='leaveTypeName'
              label='Leave Type Name'
              rules={[{ required: true }]}>
              <Input autoComplete="off" />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name='allocatedDays'
              label='Allocated Days'
              rules={[{ required: true }]}>
              <Input type="number" autoComplete="off" />
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              name="isActive"
              valuePropName="checked"
              className='form__group'
              initialValue={true}
            >
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </div>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateLeaveApplicationTypeClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default LeaveFiscalDayAddUpdateDrawer;
