import React from 'react';
import { Layout, Tag, Tooltip } from 'antd';

const GroupTitleCenter = (props) => {

    const {
        title,
        members,
        tags,
    } = props;

    const { Content } = Layout;

    // let otherReferenceDtos =  [
    //     {
    //       "referenceId": "58e2e9cd-9701-45d0-ab9e-7ba124afd7fa",
    //       "referenceCode": "MAY21"
    //     },
    //     {
    //       "referenceId": "b7dd7386-b45b-433a-93d2-fed5d6e2ee97",
    //       "referenceCode": "asdqweqweasdasddas"
    //     },
    //     {
    //       "referenceId": "d9d7a2c8-3090-42f8-8a9f-9ca26e12c111",
    //       "referenceCode": "Huddersfield Town A.F.C."
    //     }
    // ]



    return (
        <>
        <Layout>
            <Content className='groupchat-message-center'>
                <div className='message-row'>
                    <span className='group-message-title'>Title:</span>
                    <span className='message-content'>{title}</span>
                </div>
                <div className='message-row'>
                    <span className='group-message-title'>Members:</span>
                    <span className='message-content'>
                        {members?.map((item) => item?.userName).filter(Boolean).join(', ')}
                    </span>
                </div>
                <div className='message-row'>
                    <span className='group-message-title'>Tags:</span>
                    {/* {console.log("otherReferenceDtos: ", otherReferenceDtos?.map((item) => item.referenceCode))} */}

                    {/* {Array.isArray(tags) 
                    ? tags.map((item) => (
                        <Tag color="magenta" key={item.referenceCode}>{item.referenceCode}</Tag>
                     )) : otherReferenceDtos?.map((item) => (
                        <Tag color="magenta" key={item.referenceCode}>{item.referenceCode}</Tag>
                    ))} */}


                    {tags && tags.map((item) => (
                        <Tag color="magenta" key={item.referenceCode}>{item.referenceCode}</Tag>
                     )) }
                </div>
            </Content>
        </Layout>
        </>
    )
}

export default GroupTitleCenter;