import queryString from 'query-string';
import { getService, postService } from '../../commonServices';
import { appBaseUrl } from 'utils/config';

export class ComplaintService {
  //tenderBasic
  complaintPagination(query) {
    let url = `${appBaseUrl}/adminModule/ComplainPagination?${query}`;
    let data = getService(url);
    return data;
  }
  // tenderBasicGetDetails(id) {
  // 	let url = `${appBaseUrl}/referenceManagement/tenderBasicGetDetails/${id}`;
  // 	let data = getService(url);
  // 	return data;
  // }

  conplaintAdd(body) {
    let url = `${appBaseUrl}/adminModule/complainAdd`;
    let data = postService(url, body);
    return data;
  }
  complaintStatusUpdate(body) {
    let url = `${appBaseUrl}/adminModule/ComplainStatusUpdate?complainId=${body.complainId}&statusId=${body.statusId}`;
    let data = postService(url, body, 'PUT');
    return data;
  }

  complainGetDetails(id) {
    let url = `${appBaseUrl}/adminModule/complainGetDetails/${id}`;
    let data = getService(url);
    return data;
  }

  complaintUpdate(body, id) {
    let url = `${appBaseUrl}/adminModule/complainUpdate/${id}`;
    let data = postService(url, body, 'put');
    return data;
  }

  errorLogData(body) {
    let url = `${appBaseUrl}/emailMessage/emailErrorLogsPaginationAdmin?${body}`;
    let data = getService(url);
    return data;
  }

  complainGetAllCounter() {
    let url = `${appBaseUrl}/adminModule/complainGetAllCounter`;
    let data = getService(url);
    return data;
  }
}
