import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddUpdateDrawerProcrument } from "./AddUpdateDrawer";
import { Row, Col, Button } from "antd";
import ProcurementTable from "./ProcurementTable";
import { getProcurement } from "services/redux/requistions/procurement/action";
import ApprovalTableProcurement from "./ApprovalTable";
import { isEmpty } from "lodash";
import ProcurementDetails from "./ProcurementDetails";

export const AddUpdateProcrument = () => {
  const dispatch = useDispatch();

  const {
    addProcurementResp,
    addProcurementRespLoading,
    procurementPagination,
    procurementRespLoading,
  } = useSelector((state) => state.procurement);

  const [addUpdateDrawerVisibility, setAddUpdateDrawerVisibility] =
    useState(false);
  const [isUpdateApplication, setisUpdateApplication] = useState(false);
  const [searchKeyWord, setSearchKeyword] = useState("");
  const [procurementDetailsId, setProcurementDetailsId] = useState("");
  const [hideProcurementDrawer, setHideProcurementDrawer] = useState(false);
  const [showProcurementDetails, setShowProcurementDetails] = useState(false);
  const [procurementDetails, setProcurementDetails] = useState([]);

  const pageSize = 20;

  const showAddUpdateDrawer = (e, record) => {
    setAddUpdateDrawerVisibility(false);
    if (record) {
      setisUpdateApplication(record);
    } else {
      setisUpdateApplication(false);
    }

    setAddUpdateDrawerVisibility(true);
  };
  const onAddUpdateDrawerClose = () => {
    setisUpdateApplication("");
    setAddUpdateDrawerVisibility(false);
  };

  const getData = (
    SkipCount = 0,
    MaxResultCount = pageSize,
    SearchKeyword = searchKeyWord
  ) => {
    dispatch(
      getProcurement(
        { MaxResultCount, SkipCount, SearchKeyword },
        "No Approval"
      )
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const getProcrumentDetailsData =
      procurementPagination.procurementApplications?.filter((item) => {
        if (item.id == procurementDetailsId) return item;
      });
    setProcurementDetails(getProcrumentDetailsData);
  }, [procurementDetailsId]);

  return (
    <>
      <div className="global-header-wrapper">
        <div className="header-info">
          <h3 className="header__all ">Procurement</h3>
        </div>
        <div className="header-action-wrapper">
          <Button
            className="global-create-btn"
            onClick={() => {
              showAddUpdateDrawer(true);
              setHideProcurementDrawer(false);
              setProcurementDetailsId("");
            }}
          >
            Add Procurement
          </Button>
        </div>
      </div>
      {showProcurementDetails && !isEmpty(procurementDetails) ? (
        <Row>
          <Col span={24}>
            <ProcurementDetails
              procurementDetails={procurementDetails}
              drawerVisibility={hideProcurementDrawer}
              setHideProcrumentDrawer={setHideProcurementDrawer}
              onAddUpdateDrawerClose={setHideProcurementDrawer}
              setProcurementDetails={setProcurementDetails}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <AddUpdateDrawerProcrument
              drawerVisibility={addUpdateDrawerVisibility}
              onAddUpdateDrawerClose={onAddUpdateDrawerClose}
              isUpdateApplication={isUpdateApplication}
              addProcurementResp={addProcurementResp}
              addProcurementRespLoading={addProcurementRespLoading}
            />
          </Col>
        </Row>
      )}

      <div>
        <ProcurementTable
          procurementPagination={procurementPagination}
          procurementRespLoading={procurementRespLoading}
          setHideProcurementDrawer={setHideProcurementDrawer}
          setProcurementDetailsId={setProcurementDetailsId}
          setShowProcurementDetails={setShowProcurementDetails}
        />
      </div>
      <h3 className="header__all">
        <span className="title">To Approve Procurement</span>
      </h3>
      <ApprovalTableProcurement />
    </>
  );
};
