import { error, finish, init, success } from "services/common";
import { CeadService } from "./api";
import { CEAD_TYPES } from "services/types";
import { message } from "antd";
import CEAD from "views/CEAD";

const ceadService = new CeadService();

export const actionForAddUpdateTenderCEAD = () => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.ADD_UPDATE_TENDER_CEAD));
    const response = await ceadService.apiRouteForAddUpdateTenderCEAD();
    if (response?.isSuccess) {
      dispatch(success(CEAD_TYPES.ADD_UPDATE_TENDER_CEAD, response?.data));
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_TENDER_CEAD));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const actionForGetTenderCEADGetPaginationListing = (query) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_TENDER_CEAD_LIST));
    const response =
      await ceadService.apiRouteForGetTenderCEADGetPaginationListing(query);
    if (response?.isSuccess) {
      dispatch(success(CEAD_TYPES.GET_TENDER_CEAD_LIST, response?.data));
    } else {
      dispatch(error(response?.errorMessage));
      dispatch(finish(CEAD_TYPES.GET_TENDER_CEAD_LIST));
    }
  };
};

export const actionForGetTenderCEADGetAccountViewPaginationListing = (
  tender_cead_id
) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_TENDER_CEAD_ACCOUNT_VIEW_LIST));
    const response =
      await ceadService.apiRouteForGetTenderCEADAccountViewGetListing(
        tender_cead_id
      );

    if (response?.isSuccess) {
      dispatch(
        success(CEAD_TYPES.GET_TENDER_CEAD_ACCOUNT_VIEW_LIST, response?.data)
      );
      dispatch(finish(CEAD_TYPES.GET_TENDER_CEAD_ACCOUNT_VIEW_LIST));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const actionForGetSingleCeadTenderDetail = (id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_SINGLE_TENDER_CEAD_LIST));
    const response = await ceadService.apiRouteForGetSingleCeadTenderDetail(id);
    if (response?.isSuccess) {
      // message.success("Got Details");
      dispatch(success(CEAD_TYPES.GET_SINGLE_TENDER_CEAD_LIST, response?.data));
      dispatch(finish(CEAD_TYPES.GET_SINGLE_TENDER_CEAD_LIST));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const actionForAddUpdateAccountsCommissionRelatedContactPersonAtPrinipal =
  () => {
    return async (dispatch) => {
      dispatch(init(CEAD_TYPES.ADD_UPDATE_CEAD_ACCOUNT_COMMISSION));
      const response =
        await ceadService.apiRouteForAddUpdateAccountsCommissionRelatedContactPersonAtPrinipal();
      if (response?.isSuccess) {
        dispatch(
          success(CEAD_TYPES.ADD_UPDATE_CEAD_ACCOUNT_COMMISSION, response?.data)
        );
        dispatch(finish(CEAD_TYPES.ADD_UPDATE_CEAD_ACCOUNT_COMMISSION));
      } else {
        dispatch(error(response?.errorMessage));
      }
    };
  };

export const actionForAddUpdateCEADTender = (body) => {
  return async (dispatch) => {
    let msg = body?.id ? "CEAD Updated" : "Transferred CEAD Successfully";
    dispatch(init(CEAD_TYPES.ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER));
    const response = await ceadService.apiRouteForAddUpdateCEADTender(body);
    if (response?.isSuccess) {
      message.success(msg);
      dispatch(
        success(CEAD_TYPES.ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER, response?.data)
      );
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_BIZ_PRO_CEAD_TRANSFER));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const actionForAddUpdateFunding = (body, id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.ADD_UPDATE_FUNDING));
    const response = await ceadService.apiRouteForAddUpdateFunding(body, id);
    if (response?.isSuccess) {
      message.success("Updated");
      dispatch(success(CEAD_TYPES.ADD_UPDATE_FUNDING, response?.data));
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_FUNDING));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// for multiple bid bounds
export const actionForAddUpdateBidBounds = (body, id, ETenderCEADBidTypes) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.ADD_UPDATE_BID_BOUND));
    const response = await ceadService.apiRouteForAddUpdateBidBounds(
      body,
      id,
      ETenderCEADBidTypes
    );
    if (response?.isSuccess) {
      message.success("Updated");
      dispatch(
        success(
          CEAD_TYPES.ADD_UPDATE_BID_BOUND,
          response?.data,
          "",
          ETenderCEADBidTypes
        )
      );
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_BID_BOUND));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

// for single bid bound
export const actionForAddUpdateBidBound = (body, id, ETenderCEADBidTypes) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.ADD_UPDATE_BID_BOUND));
    const response = await ceadService.apiRouteForAddUpdateBidBound(
      body,
      id,
      ETenderCEADBidTypes
    );
    if (response?.isSuccess) {
      message.success("Updated");
      dispatch(
        success(
          CEAD_TYPES.ADD_UPDATE_BID_BOUND,
          response?.data,
          "",
          ETenderCEADBidTypes
        )
      );
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_BID_BOUND));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const actionForAddUpdatePerformanceBound = () => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.ADD_UPDATE_PERFORMANCE_BOUND));
    const response = await ceadService.apiRouteForAddUpdatePerformanceBound();
    if (response?.isSuccess) {
      dispatch(
        success(CEAD_TYPES.ADD_UPDATE_PERFORMANCE_BOUND, response?.data)
      );
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_PERFORMANCE_BOUND));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const actionForAddUpdateAdvancePaymentGuarantee = () => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE));
    const response =
      await ceadService.apiRouteForAddUpdateAdvancePaymentGuarantee();
    if (response?.isSuccess) {
      dispatch(
        success(CEAD_TYPES.ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE, response?.data)
      );
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_ADVANCE_PAYMENT_GUARANTEE));
    } else {
      dispatch(error(response?.errorMessage));
    }
  };
};

export const actionForAddUpdateCeadFinancialInformation = (
  body,
  id,
  ceadFinancialType
) => {
  return async (dispatch) => {
    console.log("ceadFinancialType", ceadFinancialType);
    dispatch(init(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION, body));
    const response = await ceadService.apiForAddUpdateFinancialInformation(
      body,
      id,
      ceadFinancialType
    );
    if (response.isSuccess) {
      message.success("Updated");
      dispatch(
        success(
          CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION,
          response.data,
          "",
          "",
          ceadFinancialType
        )
      );
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION));
    }
  };
};

export const actionForAddUpdateCeadVariationInformation = (
  body,
  id,
  ceadFinancialType
) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION, body));
    const response = await ceadService.apiForAddUpdateVariationInformation(
      body,
      id,
      ceadFinancialType
    );
    if (response.isSuccess) {
      message.success("Updated");
      dispatch(
        success(
          CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION,
          response.data,
          "",
          "",
          ceadFinancialType
        )
      );
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION));
    }
  };
};

export const actionForAddUpdateCEADPaymentReceivedAddUpdateInformation = (
  body,
  id,
  ceadFinancialType
) => {
  return async (dispatch) => {
    // dispatch(init(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION, body));
    const response =
      await ceadService.apiForAddUpdateCEADPaymentReceivedAddUpdateInformation(
        body,
        id,
        ceadFinancialType
      );
    if (response.isSuccess) {
      message.success("Updated");
      dispatch(
        success(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION, response.data)
      );
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION));
    } else if (!response.isSuccess) {
      // dispatch(error(response.errorMessage));
      // dispatch(finish(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION));
      message.success("Error");
    }
  };
};
export const actionForAddUpdateCEADPaymentReceivedByPrincipalAddUpdateInformation =
  (body, id) => {
    return async (dispatch) => {
      dispatch(init(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION, body));
      const response =
        await ceadService.apiForTenderCEADPaymentReceivedPrincipalAddUpdateInformation(
          body,
          id
        );
      if (response.isSuccess) {
        message.success("Updated");
        dispatch(
          success(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION, response.data)
        );
        dispatch(finish(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION));
      } else if (!response.isSuccess) {
        dispatch(error(response.errorMessage));
        dispatch(finish(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION));
        message.error("Error");
      }
    };
  };

export const actionForAddUpdateCEADOtherInformation = (body, id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.ADD_UPDATE_FINANCIAL_INFORMATION, body));
    const response = await ceadService.apiForAddUpdateCEADOtherInformation(
      body,
      id
    );
    if (response.isSuccess) {
      message.success("Updated");
    } else if (!response.isSuccess) {
      message.error("Error");
    }
  };
};

export const actionForPaymentReceivedPrincipalInvoiceGenerate = (id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.INVOICE_GENERATED));
    const response =
      await ceadService.apiForPaymentReceivedPrincipalInvoiceGenerate(id);
    if (response.isSuccess) {
      message.success("Invoice Generated");
      dispatch(success(CEAD_TYPES.INVOICE_GENERATED, response.data));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.INVOICE_GENERATED));
    }
  };
};

export const actionForPaymentReceivedPrincipalInvoiceGetDetail = (id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST));
    const response =
      await ceadService.apiForPaymentReceivedPrincipalInvoiceGetDetails(id);
    if (response.isSuccess) {
      dispatch(success(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST, response.data));
      dispatch(finish(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST));
    } else {
      message.error(response?.errorMessage);
    }
  };
};

export const actionForUpdateQuestionAnswer = (body, id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.UPDATE_QA));
    const response = await ceadService.apiForUpdateQuestionAnswer(body, id);
    if (response.isSuccess) {
      message.success("Updated");
      dispatch(success(CEAD_TYPES.UPDATE_QA, response.data));
      dispatch(finish(CEAD_TYPES.UPDATE_QA));
    } else {
      message.error(response?.errorMessage);
    }
  };
};

export const actionForPaymentReceivedPrincipalInvoiceAccountApproved = (
  body,
  id
) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST));
    const response =
      await ceadService.apiForPaymentReceivedPrincipalInvoiceAccountApproved(
        body,
        id
      );
    if (response.isSuccess) {
      message.success("Approved");
      dispatch(success(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST, response.data));
      dispatch(finish(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST));
    } else {
      message.error(response?.errorMessage);
    }
  };
};

export const actionForPaymentReceivedPrincipalInvoiceManagementApproved = (
  body,
  id
) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST));
    const response =
      await ceadService.apiForPaymentReceivedPrincipalInvoiceManagementApproved(
        body,
        id
      );
    if (response.isSuccess) {
      message.success("Approved");
      dispatch(success(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST, response.data));
      dispatch(finish(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST));
    } else {
      message.error(response?.errorMessage);
    }
  };
};

export const actionForPaymentReceivedPrincipalInvoiceFinalAccountApproved = (
  body,
  id
) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST));
    const response =
      await ceadService.apiForPaymentReceivedPrincipalInvoiceFinalAccountApproved(
        body,
        id
      );
    if (response.isSuccess) {
      message.success("Approved");
      dispatch(success(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST, response.data));
      dispatch(finish(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.GET_PRINCIPLE_INVOICE_LIST));
    }
  };
};

export const actionForCeadEmailCompose = (id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_CEAD_COMPOSE_EMAIL_DETAIL));
    const response = await ceadService.apiForCeadEmailCompose(id);
    if (response.isSuccess) {
      // message.success("Approved");
      dispatch(
        success(CEAD_TYPES.GET_CEAD_COMPOSE_EMAIL_DETAIL, response.data)
      );
      dispatch(finish(CEAD_TYPES.GET_CEAD_COMPOSE_EMAIL_DETAIL));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.GET_CEAD_COMPOSE_EMAIL_DETAIL));
    }
  };
};

export const actionForPaymentReceivedPrincipalInvoiceLogGetListing = (id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_PAYMENT_RECEIVED_LOG));
    const response =
      await ceadService.apiForPaymentReceivedPrincipalInvoiceLogGetListing(id);
    if (response.isSuccess) {
      message.success("Got payment Invoice Details");
      dispatch(success(CEAD_TYPES.GET_PAYMENT_RECEIVED_LOG, response.data));
      dispatch(finish(CEAD_TYPES.GET_PAYMENT_RECEIVED_LOG));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.GET_PAYMENT_RECEIVED_LOG));
    }
  };
};

export const actionForContractAddUpdateClosure = (body) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.CONTRACT_ADD_UPDATE_CLOSURE));
    const response = await ceadService.apiForContractAddUpdateClosure(body);
    if (response.isSuccess) {
      message.success("Updated");
      dispatch(success(CEAD_TYPES.CONTRACT_ADD_UPDATE_CLOSURE, response.data));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.CONTRACT_ADD_UPDATE_CLOSURE));
    }
  };
};

export const actionForWeeklyAddUpdateReport = (body) => {
  return async (dispatch) => {
    const msg = body?.id ? "Updated" : "Added";
    dispatch(init(CEAD_TYPES.ADD_UPDATE_WEEKLY_REPORT));
    const response = await ceadService.apiForWeeklyAddUpdateReport(body);
    if (response.isSuccess) {
      message.success(msg);
      dispatch(
        success(CEAD_TYPES.ADD_UPDATE_WEEKLY_REPORT, response.data, "", msg)
      );
      // dispatch(finish(CEAD_TYPES.ADD_UPDATE_WEEKLY_REPORT));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_WEEKLY_REPORT));
    }
  };
};

export const actionForWeeklyReportGetPaginationListing = (query) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_LIST));
    const response = await ceadService.apiForWeeklyReportGetPaginationListing(
      query
    );
    if (response.isSuccess) {
      dispatch(
        success(CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_LIST, response.data)
      );
      dispatch(finish(CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_LIST));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL_LIST));
    }
  };
};

export const actionForWeeklyAddUpdateReportGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL));
    const response = await ceadService.apiForWeeklyAddUpdateReportGetDetails(
      id
    );
    if (response.isSuccess) {
      // message.success("Got Details Single");
      dispatch(success(CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL, response.data));
      dispatch(finish(CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.WEEKLY_REPORT_GET_DETAIL));
    }
  };
};

//CONTRACT WEEKLY REPORT

export const actionForWeeklyContractAddUpdateReport = (body) => {
  return async (dispatch) => {
    const msg = body?.id ? "Updated" : "Added";
    dispatch(init(CEAD_TYPES.ADD_UPDATE_WEEKLY_CONTRACT_REPORT));
    const response = await ceadService.apiForWeeklyContractAddUpdateReport(
      body
    );
    if (response.isSuccess) {
      message.success(msg);
      dispatch(
        success(
          CEAD_TYPES.ADD_UPDATE_WEEKLY_CONTRACT_REPORT,
          response.data,
          "",
          msg
        )
      );
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_WEEKLY_CONTRACT_REPORT));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.ADD_UPDATE_WEEKLY_CONTRACT_REPORT));
    }
  };
};

export const actionForWeeklyContractReportGetPaginationListing = (query) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST));
    const response =
      await ceadService.apiForWeeklyContractReportGetPaginationListing(query);
    if (response.isSuccess) {
      dispatch(
        success(
          CEAD_TYPES.WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST,
          response.data
        )
      );
      dispatch(finish(CEAD_TYPES.WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.WEEKLY_CONTRACT_REPORT_GET_DETAIL_LIST));
    }
  };
};

export const actionForContractWeeklyAddUpdateReportGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.CONTRACT_WEEKLY_REPORT_GET_DETAIL));
    const response =
      await ceadService.apiForContractWeeklyAddUpdateReportGetDetails(id);
    if (response.isSuccess) {
      dispatch(
        success(CEAD_TYPES.CONTRACT_WEEKLY_REPORT_GET_DETAIL, response.data)
      );
      dispatch(finish(CEAD_TYPES.CONTRACT_WEEKLY_REPORT_GET_DETAIL));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.CONTRACT_WEEKLY_REPORT_GET_DETAIL));
    }
  };
};

export const actionForTenderCeadSummary = (id) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.GET_CEAD_TENDER_SUMMARY));
    const response = await ceadService.apiForTenderCeadSummary(id);
    if (response.isSuccess) {
      dispatch(success(CEAD_TYPES.GET_CEAD_TENDER_SUMMARY, response.data));
      dispatch(finish(CEAD_TYPES.GET_CEAD_TENDER_SUMMARY));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.GET_CEAD_TENDER_SUMMARY));
    }
  };
};

// cead weekly report mail
export const actionForAddCEADWeeklyReportMail = (id, body) => {
  return async (dispatch) => {
    dispatch(init(CEAD_TYPES.CEAD_WEEKLY_REPORT_MAIL));
    const response = await ceadService.apiForCEADWeeklyReportMail(id, body);
    if (response.isSuccess) {
      message.success("Cead Weekly Report Mail");
      dispatch(success(CEAD_TYPES.CEAD_WEEKLY_REPORT_MAIL, response.data));
      dispatch(finish(CEAD_TYPES.CEAD_WEEKLY_REPORT_MAIL));
    } else {
      message.error(response?.errorMessage);
      dispatch(finish(CEAD_TYPES.CEAD_WEEKLY_REPORT_MAIL));
    }
  };
};
