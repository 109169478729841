import React from "react";
import { Table, Space } from "antd";
import { LogoImage } from "utils/imageConstants";

function DepartmentListCompact({
  rowEventhandlers,
  setRowClassName,
  departmentListingResponse,
  departmentListingResponseLoading,
}) {
  const columns = [
    {
      title: "Name",
      dataIndex: "departmentName",
      key: "employeeName",
      sorter: false,
      width: 10,
    },
  ];

  // All department
  const all = {
    departmentName: "All",
    id: "1",
    isActive: true,
    organizationDepartmentEmployeeDtos: null,
  };

  const updatedDepartmentListingResponse = [all, ...departmentListingResponse];

  return (
    <div>
      <Table
        onRow={rowEventhandlers}
        // className="userlistcompact_tbl organizer__table person-table"
        className="userlistcompact_tbl hr__table person-table"
        columns={columns}
        rowClassName={setRowClassName}
        //scroll={true}
        rowKey="appUserId"
        // dataSource={departmentListingResponse}
        dataSource={updatedDepartmentListingResponse}
        sticky={true}
        scroll={{ y: 350, x: "max-content" }}
        //onChange={handleTableChange}
        loading={{
          indicator: <img src={LogoImage} height="auto" width="50px" />,
          spinning: departmentListingResponseLoading,
        }}
        // loading={departmentListingResponseLoading}
        pagination={false}
        // pagination={{
        // 	total: totalCount,
        // 	pageSize: pageSize,
        // }}
      />
    </div>
  );
}

export default DepartmentListCompact;
