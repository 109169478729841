import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { TODO_TYPES } from "../../../types";
import { uniqBy } from "lodash";

const INITIAL_STATE = {
  allToDoEvents: {
    items: [],
    totalCount: 0,
  },
  todoEventsLoading: false,

  allToDoPrivate: {
    items: [],
    totalCount: 1,
  },

  taskCounterListResponse: {
    items: [],
  },

  todoPrivateLoading: false,

  allTodayList: {
    items: [],
    totalCount: 1,
  },
  todoEventCompleteLoading: false,

  addToDoEventResp: [],
  addToDoEventRespLoading: false,

  addToDoPrivateResp: [],
  addToDoPrivateResLoading: false,

  toDoMarkItOffResp: [],
  toDoMarkItOffLoading: false,

  toDoMarkItFlaggedResp: [],
  toDoMarkItFlaggedLoading: false,

  toDoGetDetailsResponse: [],
  todoDetailsRespLoading: false,

  updateTodoEventResp: [],
  updateTodoEventRespLoading: false,
  // addTodoEventsKanban:[]
};
export default function todoEventsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // ToDo events
    case TODO_TYPES.TODO_LISTS_INIT:
      return {
        ...state,
        todoEventsLoading: true,
      };
    case TODO_TYPES.TODO_LISTS_SUCCESS:
      return {
        ...state,
        allToDoEvents: {
          items: action.payload.items,
          totalCount: action.payload.totalCount,
        },
        todoEventsLoading: false,
      };
    case TODO_TYPES.TODO_LISTS_FINISH:
      return {
        ...state,
        todoEventsLoading: false,
      };
    

    case TODO_TYPES.TODO_LISTS_STATUS:
      // console.log("action.payload: ", action.payload);
      // console.log("action.payload.transferToDailyTask: ",action.payload.transferToDailyTask,)
      // console.log("allToDoEvents: ", state?.allToDoEvents)
      // if(item.todoEventStatusId === action.payload.stutusId) {

      let updateStatus = state?.allToDoEvents?.items?.map((item) => {
        if(action.payload.todoEventId === item.id) {
          return {
            ...item,
            todoTransferToDailyTask: action.payload.transferToDailyTask,
          }
        }
        else {
          return item;
        }
      })
      // console.log("updateStatus: ",updateStatus)

      return {
        ...state,
        todoEventsLoading: false,
        allToDoEvents: {
          items: updateStatus,
          totalCount: state.allToDoEvents?.totalCount,
        }
      }

    case TODO_TYPES.TODO_LISTS_UPDATE:
      // console.log("ACTION TODO_LISTS_UPDATE: ", action.payload);
      // console.log("allToDoEvents: ", state?.allToDoEvents);

      const updatedItems = state?.allToDoEvents.items?.map((item) => {
        if(item.id === action.payload.todoEventId) {
          return {
            ...item,
            todoEventStatusDto: action.payload.statusDto 
          }
        } else {
          return item;
        }
      })

      return {
        ...state,
        todoEventsLoading: false,
        allToDoEvents: {
          items: updatedItems,
          totalCount: state.allToDoEvents?.totalCount,
        }
      }
    //Private Task Listing
    // case TODO_TYPES.PRIVATE_LISTS_INIT:
    // return {
    //     privateTaskResponse:null,
    //     privateTaskLoading:true,
    // };

    // case TODO_TYPES.PRIVATE_LISTS_FINISH:
    //   return {
    //   privateTaskResponse:null,
    //   privateTaskLoading:false,
    // };

    // case TODO_TYPES.PRIVATE_LISTS_SUCCESS:
    // return {
    //     ...state,
    //     privateTaskResponse: action.payload,
    //     privateTaskLoading: false,
    // };

    //Today Completed Date As today
    case TODO_TYPES.TODO_LISTS_TODAY_INIT:
      return {
        ...state,
        todoEventCompleteLoading: true,
      };
    case TODO_TYPES.TODO_LISTS_TODAY_SUCCESS:
      return {
        ...state,
        allTodayList: {
          items: action.payload.items,
          totalCount: action.payload.totalCount,
        },
        todoEventCompleteLoading: false,
      };

    case TODO_TYPES.TODO_LISTS_TODAY_FINISH:
      return {
        ...state,
        todoEventCompleteLoading: false,
      };

    case TODO_TYPES.TODO_LIST_CHANGE_SUCCESS:
      return {
        ...state,
        allToDoEvents: state.allToDoEvents
          ? {
            items: uniqBy(
              state.allToDoEvents?.items?.concat(action.payload.items),
              "id"
            ),
            totalCount: action.payload.totalCount,
          }
          : action.payload,
        todoEventsLoading: false,
      };
    case TODO_TYPES.TODO_LISTS_KANBAN_INIT:
      return {
        ...state,
        addTodoEventsLoading: true,
      };
    case TODO_TYPES.TODO_LISTS_KANBAN_SUCCESS:
      return {
        ...state,
        addTodoEventsKanban: action.payload,
        addTodoEventsLoading: false,
      };
    case TODO_TYPES.TODO_KANBAN_DRAG_SUCCESS:
      return {
        ...state,
        addTodoEventsKanban: action.payload,
      };
    case TODO_TYPES.TODO_KANBAN_SUCCESS:
      return {
        ...state,
        kanbanData: action.payload,
      };

    // Todo events add
    case TODO_TYPES.ADD_TODO_LISTS_INIT:
      return {
        ...state,
        addToDoEventResp: [],
        addToDoEventRespLoading: true,
      };
    case TODO_TYPES.ADD_TODO_LISTS_SUCCESS:
      let allToDoEvents = {};
      if (
        !action?.payload?.taskCat.includes("-") &&
        action.payload.organizationDepartmentDto.id !== null
      ) {
        allToDoEvents = {
          items: [...state.allToDoEvents.items],
          totalCount: state.allToDoEvents.totalCount,
        };
      } else {
        allToDoEvents = {
          items: [action.payload, ...state.allToDoEvents.items],
          totalCount: state.allToDoEvents.totalCount + 1,
        };
      }
      return {
        ...state,
        addToDoEventResp: action.payload,
        addToDoEventRespLoading: false,
        allToDoEvents: allToDoEvents,
        // allToDoEvents: {
        //   // items: state.allToDoEvents.items.map((item) => {
        //   //   return action.payload, item;
        //   // }),
        //   items: [action.payload, ...state.allToDoEvents.items],
        //   totalCount: state.allToDoEvents.totalCount,
        // },
        // addTodoEventsKanban:{
        //   items:{
        //     [0]:{
        //       cards : [...state.addTodoEventsKanban.items?.[0]?.cards ,action.payload ]
        //     }
        //   },
        //   totalCount:state.addTodoEventsKanban.totalCount
        // }
      };
    case TODO_TYPES.ADD_TODO_LISTS_FINISH:
      return {
        ...state,
        addToDoEventRespLoading: false,
      };

    //Private Task Add

    case TODO_TYPES.ADD_PRIVATE_LISTS:
      return {
        ...state,
        addToDoPrivateResp: [],
        addToDoPrivateResLoading: true,
      };
    case TODO_TYPES.ADD_PRIVATE_LISTS_SUCCESS:
      // console.log("alltodostate", allToDoEvents);
      // console.log("statetodo", state);
      return {
        ...state,
        addToDoPrivateResp: action.payload,
        addToDoPrivateResLoading: false,
        allToDoEvents: {
          // items: state.allToDoEvents.items.map((item) => {
          //   return action.payload, item;
          // }),
          items: [action.payload, ...state.allToDoEvents.items],
          totalCount: state.allToDoEvents.totalCount,
        },
        // addTodoEventsKanban:{
        //   items:{
        //     [0]:{
        //       cards : [...state.addTodoEventsKanban.items?.[0]?.cards ,action.payload ]
        //     }
        //   },
        //   totalCount:state.addTodoEventsKanban.totalCount
        // }
      };
    case TODO_TYPES.ADD_PRIVATE_LISTS_FINISH:
      return {
        ...state,
        addToDoPrivateResLoading: false,
      };

    // Todo events mark it off
    case TODO_TYPES.TODO_COMPLETE_INIT:
      return {
        ...state,
        toDoMarkItOffResp: [],
        toDoMarkItOffLoading: true,
      };
    case TODO_TYPES.TODO_COMPLETE_SUCCESS:
      return {
        ...state,
        toDoMarkItOffResp: action.payload,
        toDoMarkItOffLoading: false,
        allToDoEvents: {
          items: state.allToDoEvents.items.map((item) => {
            if (action.payload.id === item.id) {
              return {
                ...action.payload,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
          totalCount: state.allToDoEvents.totalCount,
        },
        todayTasksResponse: {
          items: state.todayTasksResponse?.items?.map((item) => {
            if (action.payload.id === item.id) {
              return {
                ...action.payload,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
          totalCount: state?.todayTasksResponse?.totalCount,
        },
      };
    case TODO_TYPES.TODO_COMPLETE_FINISH:
      return {
        ...state,
        toDoMarkItOffLoading: false,
      };

    // Todo events mark as Flagged
    case TODO_TYPES.TODO_ISFLAGGED_INIT:
      return {
        ...state,
        toDoMarkItFlaggedResp: [],
        toDoMarkItFlaggedLoading: true,
      };
    case TODO_TYPES.TODO_ISFLAGGED_SUCCESS:
      return {
        ...state,
        toDoMarkItOffResp: action.payload,
        toDoMarkItFlaggedResp: false,
        allToDoEvents: {
          items: state.allToDoEvents.items.map((item) => {
            if (action.payload.id === item.id) {
              return {
                ...action.payload,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
          totalCount: state.allToDoEvents.totalCount,
        },
        todayTasksResponse: {
          items: state.todayTasksResponse?.items?.map((item) => {
            if (action.payload.id === item.id) {
              return {
                ...action.payload,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
          totalCount: state?.todayTasksResponse?.totalCount,
        },
      };
    case TODO_TYPES.TODO_ISFLAGGED_FINISH:
      return {
        ...state,
        toDoMarkItFlaggedResp: false,
      };

    //get Details
    case TODO_TYPES.TODO_GET_DETAILS_INIT:
      return {
        ...state,
        toDoGetDetailsResponse: [],
        todoDetailsRespLoading: true,
      };
    case TODO_TYPES.TODO_GET_DETAILS_SUCCESS:
      return {
        ...state,
        toDoGetDetailsResponse: action.payload,
        todoDetailsRespLoading: false,
      };
    case TODO_TYPES.TODO_GET_DETAILS_FINISH:
      return {
        ...state,
        todoDetailsRespLoading: false,
      };

    // UPDATE TODO events
    case TODO_TYPES.UPDATE_TODO_EVENT_INIT:
      return {
        ...state,
        updateTodoEventResp: [],
        updateTodoEventRespLoading: true,
      };
    // case TODO_TYPES.UPDATE_TODO_EVENT_SUCCESS:
    //   return {
    //     ...state,
    //     updateTodoEventResp: action.payload,
    //     updateTodoEventRespLoading: false,
    //     allToDoEvents: {
    //       items: state.allToDoEvents.items.map((item) => {
    //         let todoEventId = action.payload.id;
    //         if (item.id === todoEventId) {
    //           return {
    //             ...item,
    //             ...action.payload,
    //           };
    //         } else {
    //           return { ...item };
    //         }
    //       }),
    //       totalCount: state.allToDoEvents.totalCount,
    //     },
    //   };
    case TODO_TYPES.UPDATE_TODO_EVENT_SUCCESS:
      const updatedItem = action.payload;
      if (action.payload.hide === true) {
        const updatedItem = action.payload;
        const updatedItems = state.allToDoEvents.items.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem;
          } else {
            return item;
          }
        });
        const filteredItems = updatedItems.filter(
          (item) => item.id !== updatedItem.id
        );
        return {
          ...state,
          updateTodoEventResp: updatedItem,
          updateTodoEventRespLoading: false,
          allToDoEvents: {
            items: filteredItems,
            totalCount: state.allToDoEvents.totalCount,
          },
        };
      } else {
        const updatedItems = state.allToDoEvents.items.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem;
          } else {
            return item;
          }
        });
        return {
          ...state,
          updateTodoEventResp: updatedItem,
          updateTodoEventRespLoading: false,
          allToDoEvents: {
            items: updatedItems,
            totalCount: state.allToDoEvents.totalCount,
          },
        };
      }
    case TODO_TYPES.UPDATE_TODO_EVENT_FINISH:
      return {
        ...state,
        updateTodoEventRespLoading: false,
      };
    // Task List Counter
    case TODO_TYPES.TASK_COUNTER_INIT:
      return {
        ...state,
        taskCounterListLoading: true,
      };
    case TODO_TYPES.TASK_COUNTER_SUCCESS:
      return {
        ...state,
        taskCounterListResponse: action.payload,
        taskCounterListLoading: false,
      };
    case TODO_TYPES.TASK_COUNTER_FINISH:
      return {
        ...state,
        taskCounterListResponse: null,
        taskCounterListLoading: false,
      };

    case TODO_TYPES.ALL_TODAY_TASKS_INIT:
      return {
        ...state,
        todayTasksResponse: [],
        todayTasksResponseLoading: true,
      };
    case TODO_TYPES.ALL_TODAY_TASKS_SUCCESS:
      return {
        ...state,
        todayTasksResponse: action.payload,
        todayTasksResponseLoading: false,
      };

    case TODO_TYPES.ALL_USED_REFERENCE_INIT:
      return {
        ...state,
        tasksUsedReference: null,
        tasksUsedReferenceLoading: true,
      };
    case TODO_TYPES.ALL_USED_REFERENCE_SUCCESS:
      return {
        ...state,
        tasksUsedReference: action.payload,
        tasksUsedReferenceLoading: false,
      };
    case TODO_TYPES.ALL_TODAY_TASKS_FINISH:
      return {
        ...state,
        tasksUsedReferenceLoading: false,
      };
    case TODO_TYPES.AGENDAS_LIST_INIT:
      return {
        ...state,
        todoAgendasListingResponse: [],
        todoAgendasListingResponseLoading: true,
      };
    case TODO_TYPES.AGENDAS_LIST_SUCCESS:
      return {
        ...state,
        todoAgendasListingResponse: action.payload,
        todoAgendasListingResponseLoading: false,
      };
    case TODO_TYPES.AGENDAS_LIST_FINISH:
      return {
        ...state,
        todoAgendasListingResponseLoading: false,
      };
    default:
      return { ...state };
  }
}
