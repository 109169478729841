import { Badge } from "antd";
import Navbar from "components/Navbar";
import { isEmpty, startCase, toLower } from "lodash";
import moment from "moment";
import React from "react";
import {
  UserOutlined,
  CarOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import Tour from "reactour";
import TenderLists from "../tenders";
import HomeTour from "../HomeTour";
const bell = "assets/images/icons/bell.png";

const Layout = ({
  nepaliDateResponse,
  userName,
  bellCount,
  setShowInformation,
  showBellNotificationLists,
  setShowBellNotificationLists,
  showAttendenceLists,
  setShowAttendenceLists,
  showSettings,
  setShowSettings,
  setIsModalVisible,
  showPoliciesPage,
  setShowPoliciesPage,
  setIsTourOpen,
  showTutorial,
  setShowTutorial,
  isTourOpen,
}) => {
  return (
    <>
      {/* <Navbar></Navbar> */}
      <div className="dashboards__wraps">
        <div>
          <h4 className="dashboards__sub">
            Date:{moment().format("DD-MMM-YYYY")}/
            {`${nepaliDateResponse?.nepaliDateLong}`}
          </h4>
          <h3 className="dashboards__title">
            Hello {"  "}
            <span className="dashboards__title--name">
              {!isEmpty(userName) ? startCase(toLower(userName)) : "User"},
            </span>
          </h3>
        </div>

        <div
          className="dashboards__bellIcon"
          title="Notification"
          onClick={() => {
            setShowInformation("Notifications");
            setShowBellNotificationLists(!showBellNotificationLists);
            setShowAttendenceLists(false);
            setShowSettings(false);
            setIsModalVisible(false);
            setShowPoliciesPage(false);
            setShowTutorial(false);
          }}
        >
          <Badge count={bellCount} size={20}>
            <img src={bell} alt="bell" />
          </Badge>
        </div>
      </div>

      <div className="navbarsRight">
        <div className="navbarsRight__wrapper">
          <div className="navbarsRight__top">
            <div
              className="navbarsRight__Iconwrap active"
              onClick={() => {
                setShowInformation("Attendence");
                setShowAttendenceLists(!showAttendenceLists);
                setShowBellNotificationLists(false);
                setShowSettings(false);
                setIsModalVisible(false);
                setShowPoliciesPage(false);
                setShowTutorial(false);
              }}
            >
              <UserOutlined />
              <span>Absent</span>
            </div>
          </div>
          <div className="navbarsRight__bottom">
            <div
              className="navbarsRight__Iconwrap"
              onClick={() => {
                setShowInformation("Tutorial");
                setShowTutorial(!showTutorial);
                setShowPoliciesPage(false);
                setShowSettings(false);
                setIsModalVisible(false);
                setShowBellNotificationLists(false);
              }}
            >
              <span>
                <VideoCameraOutlined width={20} height={10} />
                Tutorial
              </span>
            </div>
            <div
              className="navbarsRight__Iconwrap"
              onClick={() => {
                setIsTourOpen(true);
              }}
            >
              <span>
                <CarOutlined width={20} height={10} />
                Navigation
              </span>
            </div>
            <div
              className="navbarsRight__Iconwrap"
              onClick={() => {
                setShowInformation("Settings");
                setShowSettings(!showSettings);
                setShowAttendenceLists(false);
                setShowBellNotificationLists(false);
                setIsModalVisible(false);
                setShowPoliciesPage(false);
                setShowTutorial(false);
              }}
            >
              <span>
                <SettingOutlined width={20} height={10} />
                Settings
              </span>
            </div>

            {/* <div className="navbarsRight__Iconwrap" onClick={()=>{
              setOpen(true);
              setShowPoliciesPage(!showPoliciesPage);
              setShowAttendenceLists(false);
              setShowBellNotificationLists(false);
              setShowSettings(false);
            }}>
              <span><ExclamationCircleOutlined width={20} height={20}/>Policies</span>
            </div> */}
            <div
              className="navbarsRight__Iconwrap"
              onClick={() => {
                setShowInformation("Policies");
                setShowPoliciesPage(!showPoliciesPage);
                setShowAttendenceLists(false);
                setShowBellNotificationLists(false);
                setShowSettings(false);
                setShowTutorial(false);
              }}
            >
              <span>
                <ExclamationCircleOutlined width={20} height={10} />
                Policies
              </span>
            </div>
          </div>
        </div>
        <HomeTour setIsTourOpen={setIsTourOpen} isTourOpen={isTourOpen} />
      </div>
    </>
  );
};

export default Layout;
