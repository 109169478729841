import { EMAIL_TYPES } from "../../../types";

const INITIAL_STATE = {
    mailMessageParticipationStatusListing: [],
    mailMessageParticipationStatusListingLoading: false,

    personMailListing: [],
    personMailListingLoading: false,
};

export default function mailMessageParticipationStatusListingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Appointment events
        case EMAIL_TYPES.MESSAGE_PARTICIPANT_STATUS_LISTING_INIT:
            return {
                ...state,
                mailMessageParticipationStatusListing: [],
                mailMessageParticipationStatusListingLoading: true,
            };
        case EMAIL_TYPES.MESSAGE_PARTICIPANT_STATUS_LISTING_SUCCESS:
            return {
                ...state,
                mailMessageParticipationStatusListing: action.payload,
                mailMessageParticipationStatusListingLoading: false,
            };
        case EMAIL_TYPES.MESSAGE_PARTICIPANT_STATUS_LISTING_FINISH:
            return {
                ...state,
                mailMessageParticipationStatusListingLoading: false,
            };
        

        // EMAIL ADRESS
        case EMAIL_TYPES.COMPANY_PERSON_EMAIL_LISTING_INIT:
            return {
                ...state,
                personMailListing: [],
                personMailListingLoading: true,
            };
        case EMAIL_TYPES.COMPANY_PERSON_EMAIL_LISTING_SUCCESS:
            return {
                ...state,
                personMailListing: action.payload,
                personMailListingLoading: false,
            };
        case EMAIL_TYPES.COMPANY_PERSON_EMAIL_LISTING_FINISH:
            return {
                ...state,
                personMailListingLoading: false,
            };

        default:
            return { ...state };
    }
}
