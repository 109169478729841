import axios from "axios";
import { error, finish, init, success } from "services/common";
import { SOW_TYPES } from "services/types";
import { BusinessService } from "./api";

const businessService = new BusinessService();
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const getSOWDetails = (q, loadMore, setSowDetailsLoad) => {
  return async (dispatch) => {
    dispatch(init(SOW_TYPES.SOW_TYPE));
    const response = await businessService.getSOWDetails(q, source);
    if (response?.cancelApi === false) return;
    if (response.isSuccess) {
      dispatch(success(SOW_TYPES.SOW_TYPE, response.data, loadMore));
      setSowDetailsLoad?.(false);
      dispatch(finish(SOW_TYPES.SOW_TYPE));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
export const getAllSectors = () => {
  return async (dispatch) => {
    dispatch(init(SOW_TYPES.ALL_SECTORS_LISTING));
    const response = await businessService.getAllSectors();
    if (response.isSuccess) {
      dispatch(success(SOW_TYPES.ALL_SECTORS_LISTING, response.data.items));
      dispatch(finish(SOW_TYPES.ALL_SECTORS_LISTING));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getSubSectors = (q) => {
  return async (dispatch) => {
    dispatch(init(SOW_TYPES.SUB_SECTORS_LISTING));
    const response = await businessService.getSubSectors(q);
    if (response.isSuccess) {
      dispatch(success(SOW_TYPES.SUB_SECTORS_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(SOW_TYPES.SUB_SECTORS_LISTING));
  };
};

export const getAllStatus = () => {
  return async (dispatch) => {
    dispatch(init(SOW_TYPES.ALL_STATUS_LISTING));
    const response = await businessService.getAllStatus();
    if (response.isSuccess) {
      dispatch(success(SOW_TYPES.ALL_STATUS_LISTING, response.data));
      dispatch(finish(SOW_TYPES.ALL_STATUS_LISTING));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getAllModality = () => {
  return async (dispatch) => {
    dispatch(init(SOW_TYPES.ALL_MODALITY_LISTING));
    const response = await businessService.getAllModality();
    if (response.isSuccess) {
      dispatch(success(SOW_TYPES.ALL_MODALITY_LISTING, response.data));
      dispatch(finish(SOW_TYPES.ALL_MODALITY_LISTING));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
