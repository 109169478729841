const { Modal, Form, Input, Select, Button } = require("antd");
const { isEmpty } = require("lodash");

const { Option } = Select;
const layoutFormGroup = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

function GroupCreateUpdateModal({
  createGroupModal,
  setCreateGroupModal,
  selectedGroupDataToBeUpdated,
  onGroupCreateFinish,
  form4,
  messageParticipants,
  groupDataLoading,
}) {
  return (
    <Modal
      visible={createGroupModal}
      title={`${
        isEmpty(selectedGroupDataToBeUpdated)
          ? "Create Your Group"
          : "Update Your Group"
      }`}
      onCancel={() => setCreateGroupModal(false)}
      footer={null}
      height={300}
    >
      <Form {...layoutFormGroup} onFinish={onGroupCreateFinish} form={form4}>
        <Form.Item
          name="groupName"
          label="Group Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Name of your group" />
        </Form.Item>

        <Form.Item
          name="userListTo"
          label="To Users"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select Users"
            allowClear
            autoComplete="off"
            mode="multiple"
            optionFilterProp="label"
          >
            {!isEmpty(messageParticipants) &&
              messageParticipants?.map((user, index) => {
                return (
                  <Option
                    value={user?.appUserId}
                    key={index}
                    label={[user.fullName, user.userName]}
                  >
                    {user?.fullName} [{user?.userName.toUpperCase()}]
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="userListCc"
          label="Cc Users"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select Users"
            allowClear
            autoComplete="off"
            mode="multiple"
            optionFilterProp="label"
          >
            {!isEmpty(messageParticipants) &&
              messageParticipants?.map((user, index) => {
                return (
                  <Option
                    value={user?.appUserId}
                    key={index}
                    label={[user.fullName, user.userName]}
                  >
                    {user?.fullName} [{user?.userName.toUpperCase()}]
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={groupDataLoading}>
          {isEmpty(selectedGroupDataToBeUpdated) ? "Submit" : "Update"}
        </Button>
      </Form>
    </Modal>
  );
}

export default GroupCreateUpdateModal;
