import { MEETING_TYPES } from "../../../types";

const INITIAL_STATE = {
    allMeetingEvents: [],
    meetingEventsLoading: false,

    addMeetingEventResp: [],
    addMeetingEventRespLoading: false,

    meetingMarkItOffResp: [],
    meetingMarkItOffLoading: false,

    meetingEventAgendaDecisionListResp: [],
    meetingEventAgendaDecisionListLoading: false,
};

export default function meetingEventsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        // Meeting events
        case MEETING_TYPES.MEETING_LISTS_INIT:
            return {
                ...state,
                allMeetingEvents: [],
                meetingEventsLoading: true,
            };
        case MEETING_TYPES.MEETING_LISTS_SUCCESS:
            return {
                ...state,
                allMeetingEvents: action.payload,
                meetingEventsLoading: false,
            };
        case MEETING_TYPES.MEETING_LISTS_FINISH:
            return {
                ...state,
                meetingEventsLoading: false,
            };

        // Meeting events add
        case MEETING_TYPES.ADD_MEETING_EVENT_INIT:
            return {
                ...state,
                addMeetingEventResp: [],
                addMeetingEventRespLoading: true,
            };
        case MEETING_TYPES.ADD_MEETING_EVENT_SUCCESS:
            return {
                ...state,
                addMeetingEventResp: action.payload,
                addMeetingEventRespLoading: false,
            };
        case MEETING_TYPES.ADD_MEETING_EVENT_FINISH:
            return {
                ...state,
                addMeetingEventRespLoading: false,
            };

        // Meeting events mark it off
        case MEETING_TYPES.MEETING_COMPLETE_EVENT_INIT:
            return {
                ...state,
                meetingMarkItOffResp: [],
                meetingMarkItOffLoading: true,
            };
        case MEETING_TYPES.MEETING_COMPLETE_EVENT_SUCCESS:
            return {
                ...state,
                meetingMarkItOffResp: action.payload,
                meetingMarkItOffLoading: false,
            };
        case MEETING_TYPES.MEETING_COMPLETE_EVENT_FINISH:
            return {
                ...state,
                meetingMarkItOffLoading: false,
            };

        // Meeting events agenda list
        case MEETING_TYPES.MEETING_EVENT_AGENDA_DECISION_LIST_INIT:
            return {
                ...state,
                meetingEventAgendaDecisionListResp: [],
                meetingEventAgendaDecisionListLoading: true,
            };
        case MEETING_TYPES.MEETING_EVENT_AGENDA_DECISION_LIST_SUCCESS:
            return {
                ...state,
                meetingEventAgendaDecisionListResp: action.payload,
                meetingEventAgendaDecisionListLoading: false,
            };
        case MEETING_TYPES.MEETING_EVENT_AGENDA_DECISION_LIST_FINISH:
            return {
                ...state,
                meetingEventAgendaDecisionListLoading: false,
            };

        // add meeting agenda
        case MEETING_TYPES.ADD_MEETING_AGENDA:
            return {
                ...state,
                meetingEventAgendaDecisionListResp: [...state.meetingEventAgendaDecisionListResp, action.addAgenda],
                meetingEventAgendaDecisionListLoading: false,
            };

        // remove meeting agenda
        case MEETING_TYPES.DELETE_MEETING_AGENDA:
            return {
                ...state,
                meetingEventAgendaDecisionListResp: state.meetingEventAgendaDecisionListResp.filter((_, index) => index !== (state.meetingEventAgendaDecisionListResp.length - 1)
                ),
                meetingEventAgendaDecisionListLoading: false,
            };

        // toggle edit meeting agenda
        case MEETING_TYPES.TOGGLE_EDIT_MEETING_AGENDA:
            return {
                ...state,
                meetingEventAgendaDecisionListResp: state.meetingEventAgendaDecisionListResp.map((d, index) => index === action.index ? ({ ...d, isEditable: !d.isEditable }) : d),
                meetingEventAgendaDecisionListLoading: false,
            };
        default:
            return { ...state };
    }
}
