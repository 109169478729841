import React, { useEffect, useState } from "react";
import Summary from "./Summary";
import { AddProject } from "./ProjectAdd";
import { Tabs, Row, Col } from "antd";
import { AddProjectSector } from "../../SectorDrawer";
import Communication from "../TenderTabs/communication/Communication";
import ToDo from "../TenderTabs/Todo";
import { useDispatch, useSelector } from "react-redux";
import { getTodoEventsPagination } from "services/redux/organizer/todoLists/action";
import { isEmpty } from "lodash";
import Companies from "../TenderTabs/companies/Companies";
import {
  contactCompanyGetDetails,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";
import {
  referencePartyRemarksHistoryListing,
  actionForProjectHistoryListing,
} from "services/redux/Reference/action";

const { TabPane } = Tabs;

const Project = ({
  emailReferenceId,
  getDetails,
  addProjectAddVisibilty,
  searchEmailTender,
  isUpdate,
  height,
  setIsUpdate,
  showChat,
  setShowChat,
  projectListing,
  setSearchKeyword,
  selectedProjectId,
  setSelectedProjectId,
  showProjectTab,
}) => {
  const dispatch = useDispatch();
  const [addUpdateSectorDrawerVisibility, setaddUpdateSectorDrawerVisibility] =
    useState(false);
  const [contactCompanyId, setContactCompanyId] = useState("");
  const [isUpdateSector, setIsUpdateSector] = useState(false);
  const [chatHeight, setChatHeight] = useState(false);
  const [details, setDetails] = useState([]);
  const { projectGetDetailsResponse, projectDetailsLoading } = useSelector(
    (state) => state.project
  );
  const showAddUpdateSectorDrawer = (e, record) => {
    setaddUpdateSectorDrawerVisibility(false);
    if (record) {
      setIsUpdateSector(record);
    } else {
      setIsUpdateSector(false);
    }
    setaddUpdateSectorDrawerVisibility(true);
  };

  const onAddUpdateSectorClose = () => {
    setIsUpdateSector("");
    setaddUpdateSectorDrawerVisibility(false);
  };

  const getTodo = (
    MaxResultCount = 100,
    SkipCount = 0,
    isComplete = 0,
    labelStatusId = "",
    CompleteStatus = "",
    orderBy = "",
    sortType = "",
    priorityStatusId = "",
    progressStatusId = "",
    assignedTo = "",
    referenceId = "",
    forPagination = false
  ) => {
    let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;

    if (labelStatusId) {
      appendUrl += `&labelStatuses=${labelStatusId}`;
    }
    if (!isEmpty(CompleteStatus)) {
      CompleteStatus.map((status) => {
        appendUrl += `&todoStatuses=${status}`;
      });
    }
    if (orderBy) {
      appendUrl += `&sorting=${orderBy}`;
    }
    if (sortType) {
      appendUrl += `&sortType=${sortType}`;
    }
    if (priorityStatusId) {
      appendUrl += `&priorityStatuses=${priorityStatusId}`;
    }
    if (progressStatusId) {
      appendUrl += `&TodoStatuses=${progressStatusId}`;
    }
    if (referenceId) {
      appendUrl += `&ReferenceIds=${referenceId}`;
    }
    dispatch(getTodoEventsPagination(appendUrl));
  };

  const rowEventhandlerscompany = (record) => {
    return {
      onClick: () => {
        setContactCompanyId(record.contactCompanyId);
        dispatch(
          getContactPersonPagination({
            CompanyId: record.contactCompanyId,
            SearchKeyword: record.partyName,
          })
        );

        dispatch(contactCompanyGetDetails(record.contactCompanyId));

        const body = {
          contactCompanyId: record.contactCompanyId,
          projectId: getDetails.id,
          isCircular: record?.isCircularPlanning ? true : false,
        };
        dispatch(actionForProjectHistoryListing(body));
      },
      // onDoubleClick: () => {
      //   dispatch(
      //     getContactPersonPagination({
      //       CompanyId: record.contactCompanyId,
      //       SearchKeyword: record.partyName,
      //     })
      //   );
      //   dispatch(contactCompanyGetDetails(record.contactCompanyId));
      //   const body = {
      //     contactCompanyId: record.contactCompanyId,
      //     projectId: getDetails.id,
      //     isCircular: record?.isCircularPlanning ? true : false,
      //   };
      //   dispatch(actionForProjectHistoryListing(body));
      // },
    };
  };

  useEffect(() => {
    !isEmpty(details) &&
      getTodo(
        10,
        0,
        0,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        details?.referenceId,
        false
      );
  }, [details]);

  useEffect(() => {
    if (!isEmpty(projectGetDetailsResponse)) {
      setDetails(projectGetDetailsResponse);
    }
  }, [projectGetDetailsResponse]);

  return (
    <div>
      <Tabs
        className="project-tabs-wrapper global-tabs-wrapper"
        defaultActiveKey="1"
        size="large"
      >
        <TabPane
          className={`summary-wrapper ${showChat && "chatOpen"}`}
          tab="Summary"
          key="1"
        >
          <AddProjectSector
            drawerVisibilty={addUpdateSectorDrawerVisibility}
            onAddUpdateSectorClose={onAddUpdateSectorClose}
          />
          <Summary
            getDetails={getDetails}
            showChat={showChat}
            setShowChat={setShowChat}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            projectListing={projectListing}
            setSearchKeyword={setSearchKeyword}
            setSelectedProjectId={setSelectedProjectId}
          />
        </TabPane>
        <TabPane tab="Communication" key="2">
          <Communication
            height={height}
            getDetails={getDetails}
            searchEmailTender={searchEmailTender}
            selectedTenderRowId={selectedProjectId}
            chatHeight={chatHeight}
            setChatHeight={setChatHeight}
          />
        </TabPane>

        <TabPane
          tab="Companies"
          key="3"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        >
          <Companies
            getDetails={getDetails}
            searchEmailTender={searchEmailTender}
            rowEventhandlers={rowEventhandlerscompany}
            companyId={contactCompanyId}
            emailReferenceId={emailReferenceId}
            selectedTenderRowId={selectedProjectId}
            showProjectTab={showProjectTab}
            isCircular={false}
          />
        </TabPane>

        <TabPane
          tab=" Tasks"
          key="4"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        >
          <ToDo
            getDetails={projectGetDetailsResponse}
            emailReferenceId={projectGetDetailsResponse?.referenceId}
            todoType="project"
          />
        </TabPane>
        {/* <TabPane tab="Companies" key="4">
          Companies
        </TabPane> */}
        {/* <TabPane tab="Docs" key="5">
          Docs
        </TabPane>
        <TabPane tab="Reports" key="6">
          Reports
        </TabPane>
        <TabPane tab="Fees" key="7">
          Fees
        </TabPane> */}
        <TabPane
          tab="Blog"
          key="5"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        ></TabPane>
        <TabPane
          tab="Circular Planning"
          key="6"
          className={`summary-wrapper ${showChat && "chatOpen"}`}
        >
          <Companies
            getDetails={getDetails}
            searchEmailTender={searchEmailTender}
            rowEventhandlers={rowEventhandlerscompany}
            companyId={contactCompanyId}
            emailReferenceId={emailReferenceId}
            selectedTenderRowId={selectedProjectId}
            isCircular={true}
            showProjectTab={showProjectTab}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Project;
