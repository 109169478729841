import queryString from "query-string";
import { getService, postService } from "../../commonServices";
import { appBaseUrl } from "utils/config";
import { Axios } from "axios";

export class AbsentService {
  // attendancePaginationByTypeByLeaveDetails(query) {
  //     let url =  `${appBaseUrl}/attendance/attendancePaginationByTypeByLeaveDetails?${query}`;
  //     let data =  getService(url);
  //     return data;
  // }

  todayGetAbsentUserList() {
    let url = `${appBaseUrl}/attendance/todayGetAbsentUserList`;
    let data = getService(url);
    return data;
  }

  noticeBoardAdd(body) {
    let url = `${appBaseUrl}/adminModule/noticeBoardAdd`;
    let data = postService(url, body);
    return data;
  }

  noticeBoardListing(query) {
    let url = `${appBaseUrl}/adminModule/noticeBoardListing?${query}`;
    let data = getService(url);
    return data;
  }

  getNoticeBoardPagination(query) {
    let url = `${appBaseUrl}/adminModule/noticeBoardListing?${query}`;
    let data = getService(url);
    return data;
  }
  updateNoticeBoard(id, body) {
    let url = `${appBaseUrl}/adminModule/noticeBoardUpdate/${id}`;
    let data = postService(url, body, "PUT");
    return data;
  }
  updateSisterCompany(body) {
    let url = `${appBaseUrl}/organizerEvent/sisterCompanyAddUpdate`;
    let data = postService(url, body, "post");
    return data;
  }
  // contactCompanyUpdate(id, body) {
  //     let url = `${appBaseUrl}/contactRequest/contactCompanyUpdate/${id}`;
  //     let data = postService(url, body, "PUT");
  //     return data;
  //   }
  getAllUsers = (q) => {
    const query = queryString.stringify(q);
    return Axios.get(appBaseUrl + `/attendance/AttendancePagination?${query}`);
  };
}
