import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Modal, Form, Input, DatePicker, Tooltip } from "antd";
import moment from "moment";
import InfiniteScrollTable from "components/Fallback/InfiniteScrollTable";
import { isEmpty, isNull } from "lodash";
import { actionForCircularPlanningLog } from "services/redux/Reference/action";
import { SearchOutlined, CloseCircleTwoTone } from "@ant-design/icons";
import LogModal from "./LogModal";

const LogList = (props) => {
  let { loading, data, referenceId } = props;
  const LogoImage = "assets/images/loader.gif";

  // added
  const dispatch = useDispatch();
  const [logData, setLogData] = useState([]);
  const [currentPage, setCurrentpage] = useState(1);
  const pageSize = 10;

  const [formLog] = Form.useForm();
  const [visibleLogModal, setVisibleLogModal] = useState(false);
  const [searchKeyWord, setSearchKeyWord] = useState(null);

  const { getCircularPlanningResp, getCircularPlanningLoading } = useSelector(
    (state) => state.reference
  );

  const columns = [
    {
      title: "S.N",
      dataIndex: "key",
      width: "8%",
      render: (_, __, index) => index + 1,
    },
    {
      // title: "Remarks",
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Remarks</span>
          {isNull(searchKeyWord) ? (
            <Tooltip title="Search">
              <SearchOutlined
                style={{ color: "blue" }}
                onClick={() => setVisibleLogModal(true)}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Clear search">
              <CloseCircleTwoTone
                onClick={() => {
                  setSearchKeyWord(null);
                }}
                twoToneColor="red"
              />
            </Tooltip>
          )}
        </div>
      ),
      width: "70%",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (text) => {
        const formattedDate = moment(text).format("YYYY-MM-DD");
        const formattedTime = moment(text).format("hh:mm A");
        return (
          <span>
            {formattedDate}{" "}
            <span style={{ color: "red" }}>{formattedTime}</span>
          </span>
        );
      },
    },
  ];

  const dataSource = (data?.items || []).map((log) => ({
    key: log.id,
    remarks: log.remarks,
    creationTime: log.creationTime,
  }));

  // added
  useEffect(() => {
    dispatch(
      actionForCircularPlanningLog(
        {
          ReferenceId: referenceId,
          MaxResultCount: pageSize,
          SkipCount: 0,
          SearchKeyword: searchKeyWord,
        },
        false
      )
    );
  }, [referenceId, searchKeyWord]);

  useEffect(() => {
    if (!isEmpty(getCircularPlanningResp?.items)) {
      setLogData(getCircularPlanningResp?.items);
      const newCurrentPage = getCircularPlanningResp?.items?.length / pageSize;
      setCurrentpage(newCurrentPage);
    } else {
      setLogData([]);
    }
  }, [getCircularPlanningResp, currentPage, pageSize]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount,
    SearchKeyword,
    loadMore = true
  ) => {
    dispatch(
      actionForCircularPlanningLog(
        {
          ReferenceId: referenceId,
          MaxResultCount,
          SkipCount,
          SearchKeyword,
        },
        loadMore
      )
    );
  };

  const getMoreData = () => {
    getData(pageSize, currentPage * pageSize, searchKeyWord, true);
  };

  // Handle
  const handleOk = async () => {
    try {
      const values = await formLog.validateFields();
      const formattedDate = moment(values.date).format("YYYY-MM-DD");

      setSearchKeyWord(values?.title);
      handleCancel();
      formLog.resetFields();
    } catch (error) {
      console.log("Failed to submit:", error);
    }
  };

  const handleCancel = () => {
    setVisibleLogModal(false);
    formLog.resetFields();
  };

  return (
    // <Table
    //   // loading={{
    //   //   indicator: <img src={LogoImage} height="auto" width="50px" />,
    //   //   spinning: loading,
    //   // }}
    //   dataSource={dataSource}
    //   loading={loading}
    //   columns={columns}
    //   pagination={false}
    //   scroll={{ y: 200 }}
    // />
    <>
      <InfiniteScrollTable
        fetchData={getMoreData}
        hasMore={
          getCircularPlanningResp?.totalCount >
          getCircularPlanningResp?.items?.length
        }
        columns={columns}
        data={logData}
        loading={getCircularPlanningLoading}
      />

      <LogModal
        visible={visibleLogModal}
        form={formLog}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </>
  );
};

export default LogList;
