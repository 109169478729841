import React, { useState, useEffect } from "react";
import ColorPicker from "rc-color-picker";

// antd
import { Drawer, Button, Col, Row, Input, Form, Layout } from "antd";

// utils
import { validateMessages } from "utils/misc";


// redux
import { useDispatch, useSelector } from "react-redux";
import { updateStatusListing } from "services/redux/todos/StatusListing/statuslisting.action";

const { Header, Footer } = Layout;
const labelStyle = {
  fontSize: "12px",
  fontWeight: "500",
};

const ListingAddUpdateDrawer = ({
  drawerVisibility,
  onAddUpdatedrawerClose,
  isUpdateStatusListing,
}) => {
  const dispatch = useDispatch();
  const { eventStatusListingUpdateResponse } = useSelector(
    (state) => state.statusListing
  );

  const [form] = Form.useForm();
  const [hexColor, setHexColor] = useState();
  const [fontHexColor, setFontHexColor] = useState();

  useEffect(() => {
    if (!isUpdateStatusListing) {
      form.resetFields();
      setHexColor();
      setFontHexColor();
      return;
    }
    form.setFieldsValue({
      ...isUpdateStatusListing,
    });
    setHexColor(isUpdateStatusListing.colorHexValue);
    setFontHexColor(isUpdateStatusListing.colorFontHexValue);
  }, [isUpdateStatusListing]);

  useEffect(() => {
    onAddUpdatedrawerClose();
  }, [eventStatusListingUpdateResponse]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      values.id = isUpdateStatusListing.id;
      dispatch(updateStatusListing(values));
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={551}
      maskClosable={false}
      className="drawer"
      onClose={onAddUpdatedrawerClose}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">Update Status Event Listing</h4>
      </Header>
      <div className="drawer__content">
        <div className="drawer__content--wrapper">
          <div className="contact__content__wrapper">
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              validateMessages={validateMessages}
            >
              <Form.Item
                className="form__group"
                name="name"
                label="Status Event"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                className="form__group"
                name="colorHexValue"
                label="Pick a Background color"
                rules={[{ required: true }]}
              >
                <div className="color__wrap">
                  <ColorPicker
                    animation="slide-up"
                    color={hexColor || "#efefef"}
                    onChange={(e) => {
                      setHexColor(e.color);
                      form.setFieldsValue({
                        colorHexValue: e.color,
                      });
                    }}
                    />
                    <span className="colorTag">{hexColor}</span>
                </div>
              </Form.Item>
              <Form.Item
                className="form__group"
                name="colorFontHexValue"
                label="Pick a Font color"
                rules={[{ required: true }]}
              >
                <div className="color__wrap">
                  <ColorPicker
                    animation="slide-up"
                    color={fontHexColor || "#efefef"}
                    onChange={(e) => {
                      setFontHexColor(e.color);
                      form.setFieldsValue({
                        colorFontHexValue: e.color,
                      });
                    }}
                    />
                    <span className="colorTag">{fontHexColor}</span>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <Footer className="drawer__footer">
        <Button onClick={onAddUpdatedrawerClose}>Cancel</Button>
        <Button onClick={onSubmit} type="primary">
          Submit
        </Button>
      </Footer>
    </Drawer>
  );
};

export default ListingAddUpdateDrawer;
