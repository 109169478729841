import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
// antd
import {
  Select,
  Popover,
  DatePicker,
  Pagination,
  Row,
  Col,
  Form,
  Table,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { getAllGuestEvents } from "services/redux/organizer/guestEvents/action";
// constants
import { LogoImage } from "utils/imageConstants";
const { Option } = Select;
const { RangePicker } = DatePicker;
const GuestIndex = (props) => {
  const dispatch = useDispatch();

  const columns = [
    {
      title: "S No",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Guest Name",
      dataIndex: "guestName",
      key: "guestName",
    },

    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Arrival Date",
      key: "arrivalDate",
      render: (record) => {
        return moment(record.arrivalDate).format("YYYY-MM-DD");
      },
    },
    {
      title: "Departure Date",
      key: "departureDate",
      render: (record) => {
        return moment(record.departureDate).format("YYYY-MM-DD");
      },
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <a
          className="action-btn action-btn-add"
          onClick={(e) => showAddUpdateCountryDrawer(e, record)}
        >
          Edit Guest
        </a>
      ),
    },
  ];
  const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);
  const guestEventsState = useSelector((state) => state.guestEvents);
  const {
    data,
    startDate,
    endDate,
    guestEventsLoading,
    guestEventStatuses,
    currentPage,
    showAddUpdateCountryDrawer,
    setCurrentPage,
  } = props;
  const { allGuestEvents, addGuestEventResp } = guestEventsState;
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [sourceData, setSourceData] = useState([]);
  const [arrivalDate, setArrivalDate] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [viewList, setViewList] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const location = useLocation();
  const pageSize = 10;

  useEffect(() => {
    if (!isEmpty(allGuestEvents?.items)) {
      let viewlists = [...viewList];
      allGuestEvents?.items.map((item, i) => {
        let lists = [...popoverMenuVisible];
        lists[i] = false;
        viewlists[i] = "More";
        setPopoverMenuVisible(lists);
        setViewList(viewlists);
      });
      setSourceData(allGuestEvents.items);
      setTotalCount(allGuestEvents.totalCount);
    }
  }, [allGuestEvents?.items]);
  // useEffect(() => {
  //   if (!isEmpty(allGuestEvents?.items)) {
  //     return;
  //     setSourceData(allGuestEvents.items);
  //     setTotalCount(allGuestEvents.totalCount);
  //   }
  // },[allGuestEvents?.items])
  useEffect(() => {
    if (!allGuestEvents?.items) return;
    setSourceData(allGuestEvents.items);
    setTotalCount(allGuestEvents.totalCount);
  }, [allGuestEvents?.items]);

  const handleVisibleChange = (index) => {
    let lists = [...popoverMenuVisible];
    lists[index] = !lists[index];
    setPopoverMenuVisible(lists);
  };

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    ArrivalDate = startDate,
    DepartureDate = endDate,
    statusId
  ) => {
    const myCurrentDate = new Date();
    const myFutureDate = new Date(myCurrentDate);
    const upcomingEvents = myFutureDate.setDate(myFutureDate.getDate() + 1000);
    const upcomingEventString = new Date(upcomingEvents);
    let appendUrl = `skipCount=${SkipCount}&ArrivalDate=${ArrivalDate}&DepartureDate=${DepartureDate}`;

    dispatch(getAllGuestEvents(appendUrl));
  };
  const handleTableChange = (pagination, filters, sorter, extra) => {
    let Sorting =
      sorter.order === "ascend"
        ? "subject"
        : sorter.order === "descend"
        ? "subject"
        : undefined;
    let SortType =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : undefined;
    let MaxResultCount = pageSize;
    let SkipCount = (pagination.current - 1) * pagination.pageSize;

    console.log(
      "MaxResultCount, SkipCount, Sorting, SortType",
      MaxResultCount,
      SkipCount,
      Sorting,
      SortType
    );
    getData(MaxResultCount, SkipCount, Sorting, SortType);
    // getData(pagination.current, pagination.pageSize);
  };
  return (
    <>
      <Table
        className="organizer__tables"
        columns={columns}
        onChange={handleTableChange}
        dataSource={sourceData}
        pagination={{
          total: totalCount,
          pageSize: pageSize,
        }}
      />
    </>
  );
};

export default GuestIndex;
