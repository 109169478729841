import { appBaseUrl } from "utils/config";
import { getService, postService, putService } from "services/commonServices";
import queryString from "query-string";

export class BlogService {
  createBlogMessage(body) {
    let url = `${appBaseUrl}/referenceManagement/userpost`;
    let data = postService(url, body);
    return data;
  }

  updateBlogMessage(id, body) {
    let url = `${appBaseUrl}/referenceManagement/UserUpdatePost/${id}`;
    let data = putService(url, body);
    return data;
  }

  getBlogMessagePagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/referenceManagement/channelPostPagination?${query}`;
    let data = getService(url);
    return data;
  }
}
