import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClearanceTemplateTable from "./ClearanceTemplateTable";
import { Skeleton } from "antd";

const ClearanceTemplate = ({ employeeUserId, updateData, setUpdateData }) => {
  const dispatch = useDispatch();

  const { clearanceFormListingResponse } = useSelector(
    (state) => state.profile
  );
  const [formState, setFormState] = useState({
    addForm: false,
    editForm: false,
  });

  useEffect(() => {
    setFormState({
      addForm: false,
      editForm: false,
    });
  }, [clearanceFormListingResponse]);

  return (
    <>
      {!clearanceFormListingResponse ? (
        <Skeleton paragraph={{ rows: 4 }} />
      ) : (
        <ClearanceTemplateTable
          employeeUserId={employeeUserId}
          clearanceFormListingResponse={clearanceFormListingResponse}
          formState={formState}
          setFormState={setFormState}
          updateData={updateData}
          setUpdateData={setUpdateData}
        />
      )}
    </>
  );
};

export default ClearanceTemplate;
