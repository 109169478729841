import { Col, Row } from "antd";
import moment from "moment";
import React from "react";

const LeaveBasicDetails = ({ leaveDetails }) => {
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={8}>Person Name:</Col>
        <Col span={16}>{leaveDetails?.requestedUserDto?.fullName}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={8}>Leave From:</Col>
        <Col span={16}>
          {moment(leaveDetails?.leaveDayFrom).format("DD MMM YYYY")}
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={8}>Leave To:</Col>
        <Col span={16}>
          {moment(leaveDetails?.leaveDayTo).format("DD MMM YYYY")}
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={8}>Leave Type:</Col>
        <Col span={16}>
          {leaveDetails?.leaveApplicationTypeDto?.leaveTypeName}
        </Col>
      </Row>

      <Row gutter={[10, 10]}>
        <Col span={8}>Reason:</Col>
        <Col span={16}>{leaveDetails?.reason}</Col>
      </Row>
    </>
  );
};

export default LeaveBasicDetails;
