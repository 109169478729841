import React from "react";

const OtherDetailsList = ({ list }) => {
  return (
    <>
      <>
        <span style={{ paddingLeft: 25 }}>Subject:{list?.subject}</span>
        <br />

        <span style={{ paddingLeft: 25 }}>
          Amount:{" "}
          {list?.procurementApplicationDto?.tentativeAmount.toLocaleString()}
        </span>
        <br />
        <span style={{ paddingLeft: 25 }}>{list?.sisterCompanyName}</span>
      </>
    </>
  );
};

export default OtherDetailsList;
