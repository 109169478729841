import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button, Form, Layout } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { dropRight, isEmpty } from "lodash";
import {
  actionForGetMessagePagination,
  actionForGroupMessageAdd,
} from "services/redux/groupChat/action";
import ChatPaginationListing from "./ChatPaginationView";
import icon from "assets/svg/groupchaticon.svg";

const sendIntM = "./assets/svg/send-white.svg";
const cancel = "./assets/svg/white-cross.svg";

function ChatInput(props) {
  let { selectedGroupId, isChatOpen, setIsChatOpen } = props;

  const { Content } = Layout;

  const {
    paginationGroupMessageResp,
    paginationGroupMessageLoading,
    addGroupMessageLoading,
  } = useSelector((state) => state.groupChatReducer);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (selectedGroupId) {
      dispatch(
        actionForGetMessagePagination({
          MessageGroupChatGroupId: selectedGroupId,
        })
      );
    }
  }, [selectedGroupId]);

  useEffect(() => {
    if (!isEmpty(selectedGroupId)) {
      setIsChatOpen(false);
    }
  }, [selectedGroupId]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setMessage(data);
  };

  const handleSubmit = async () => {
    try {
      let values = await form.validateFields();

      // Check if message is not empty before dispatching
      if (message.trim()) {
        let body = {
          groupChatGroupId: selectedGroupId,
          messageBody: message,
        };

        dispatch(actionForGroupMessageAdd(body));
        setMessage("");
        setIsChatOpen(false);
      } else {
        // Optionally handle the case where the message is empty
        console.log("Message cannot be empty");
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <>
      <ChatPaginationListing
        isChatOpen={isChatOpen}
        loading={paginationGroupMessageLoading}
        data={paginationGroupMessageResp}
      />

      {/* Input */}
      {isChatOpen ? (
        <div className="chat-input">
          <Form onFinish={handleSubmit}>
            <div className="groupchat-msg-actions">
              <Button
                type="primary"
                htmlType="submit"
                loading={addGroupMessageLoading}
              >
                {/* Send */}
                <img src={sendIntM} />
              </Button>
              <Button
                type="secondary"
                onClick={() => {
                  setIsChatOpen(false);
                  setMessage("");
                }}
                style={{
                  marginLeft: "8px",
                }}
              >
                {/* Close */}
                {/* <img 
                                src={cancel}
                            /> */}
                <CloseOutlined />
              </Button>
            </div>

            <CKEditor
              editor={ClassicEditor}
              data={message}
              onChange={(event, editor) => {
                const data = editor.getData();
                setMessage(data);
              }}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "100px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
            />
          </Form>
        </div>
      ) : (
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p onClick={() => setIsChatOpen(true)} className="groupchat-wrapper">
            <img src={icon} className="chatgroup-img" />
            <span className="groupchat-title">Comment</span>
          </p>
        </Content>
      )}
    </>
  );
}

export default ChatInput;
