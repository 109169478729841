import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  DatePicker,
  Select,
  Checkbox,
  Form,
  Row,
  Col,
  Radio,
  InputNumber,
  Spin,
  Modal,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  tenderBasicUpdate,
  tenderStageListing,
  tenderTypeListing,
  tenderBasicAdd,
} from "services/redux/Reference/Tender/action";
import { allCompanyListing } from "services/redux/Reference/action";
import { allCountryListing } from "services/redux/contact/contact.action";
import { useSelector, useDispatch } from "react-redux";
import { add, isEmpty, cloneDeep } from "lodash";
import ParticipationSecurityForm from "./ParticipationBidSecurity";
import {
  tenderParticipationAdd,
  tenderParticipationUpdate,
} from "services/redux/Reference/Tender/action";
import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";
import QuickAddCompany from "views/Contact/ContactCompanyPerson/AddQuickCompany";

import moment from "moment";

const ParticipationForm = ({
  getDetails,
  tenderTypeListingResponse,
  particiaptionform,
  setParticipationform,
  data,
  participationForm,
  defaultUSD,
  getParticipationDetails,
  setGetParticipantDetails,
}) => {
  const [formParticipation] = Form.useForm();
  const [addCompany] = Form.useForm();

  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);
  const {
    tenderParticipationUpdateResponse,
    participationAddResponse,
    participationAddLoading,
    participationUpdateLoading,
  } = useSelector((state) => state.tender);
  const { quickContactCompanyAdd, quickContactCompanyLoader } = useSelector(
    (state) => state.contactCompanyPerson
  );
  const dispatch = useDispatch();
  const [participationData, setPartticipationData] = useState();
  const { Option } = Select;
  const [showShortListing, setShowShortListing] = useState(false);
  const [awarded, setAwarded] = useState(false);
  const [showJvComapny, setShowJvCompany] = useState(false);
  const [showSubContractCompany, setShowSubContractCompany] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [contactCompanyList, setContactCompanyList] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [companyTender, setCompanyTender] = useState("");
  const [companyPartner, setCompanyPartner] = useState("");
  const [subContract, setSubContract] = useState("");
  const [commaSeparatorNum, setCommaSeparatorNum] = useState("");

  const [get, setGet] = useState([]);

  const { contactCompanyPaginationResponse, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  useEffect(() => {
    if (getParticipationDetails) {
      if (!isEmpty(getParticipationDetails.jvPartnerCompanyDtos)) {
        setShowJvCompany(true);
      }
      if (!isEmpty(getParticipationDetails.subContractCompanyDtos)) {
        setShowSubContractCompany(true);
      }
      setShowShortListing(getParticipationDetails.shortListed);
      formParticipation.setFieldsValue({
        ...getParticipationDetails,
        bidSecurityValidity: moment(
          getParticipationDetails.bidSecurityValidity
        ),
        bidValidity: moment(getParticipationDetails.bidValidity),
        JVPartnerCompanyDtos:
          getParticipationDetails?.jvPartnerCompanyDtos?.map(
            (data, i) => data.companyId
          ),
        SubContractCompanyDtos:
          getParticipationDetails?.subContractCompanyDtos?.map(
            (data, i) => data.companyId
          ),
      });
    }
  }, [getParticipationDetails]);

  useEffect(() => {
    if (quickContactCompanyAdd) {
      addCompany.setFieldsValue({
        scopeOfWork: [],
        companyName: "",
        countryId: "",
        isActive: false,
        isFavorite: false,
        cityId: "",
      });

      setVisibleModal(false);

      setTimeout(() => {
        companyTender == "companyclient" &&
          formParticipation.setFieldsValue({
            companyId: quickContactCompanyAdd.id,
          });
      }, 500);
    }
  }, [quickContactCompanyAdd]);
  useEffect(() => {
    if (quickContactCompanyAdd) {
      addCompany.setFieldsValue({
        scopeOfWork: [],
        companyName: "",
        countryId: "",
        isActive: false,
        isFavorite: false,
        cityId: "",
      });

      setVisibleModal(false);

      setTimeout(() => {
        companyPartner == "partner" &&
          formParticipation.setFieldsValue({
            JVPartnerCompanyDtos: quickContactCompanyAdd.id,
          });
      }, 500);
    }
  }, [quickContactCompanyAdd]);
  useEffect(() => {
    if (quickContactCompanyAdd) {
      addCompany.setFieldsValue({
        scopeOfWork: [],
        companyName: "",
        countryId: "",
        isActive: false,
        isFavorite: false,
        cityId: "",
      });

      setVisibleModal(false);

      setTimeout(() => {
        subContract == "subcontract" &&
          formParticipation.setFieldsValue({
            SubContractCompanyDtos: quickContactCompanyAdd.id,
          });
      }, 500);
    }
  }, [quickContactCompanyAdd]);

  // useEffect(() => {
  //   if (!isEmpty(getDetails?.tenderParticipationDtos)) {
  //     getDetails.tenderParticipationDtos.map((data, i) => {
  //       setPartticipationData(data.bidSecurity);
  //     });
  //   }
  // }, [getDetails]);

  // useEffect(() => {
  //   if (!isEmpty(getDetails?.tenderParticipationDtos)) {
  //     setParticipationform(true);
  //     let data = getDetails?.tenderParticipationDtos
  //       .filter(
  //         (student) =>
  //           student.bidSecurity === "Counter-guranteed" ||
  //           student.bidSecurity === "Foreign-Bank"
  //       )
  //       .map((filteredStudent, i) => ({
  //         ...filteredStudent,
  //       }));

  //     let lists = [...bidSecuirtyForm];
  //     data?.map((item, i) => {
  //       lists[i] = "true";
  //     });
  //     setBidSecurityForm(lists);

  //     let filterLocal = getDetails?.tenderParticipationDtos
  //       .filter((student) => student.bidSecurity === "Counter-guranteed")
  //       .map((filteredStudent, i) => ({
  //         ...filteredStudent,
  //       }));

  //     let listsLocal = [...localForm];
  //     filterLocal?.map((item, i) => {
  //       listsLocal[i] = "true";
  //     });
  //     setLocalForm(listsLocal);

  //     let filterCash = getDetails?.tenderParticipationDtos
  //       .filter((student) => student.bidSecurity === "Cash")
  //       .map((filteredStudent, i) => ({
  //         ...filteredStudent,
  //       }));

  //     let listsCash = [...cashForm];
  //     filterCash?.map((item, i) => {
  //       listsCash[i] = "true";
  //     });
  //     setCashForm(listsCash);
  //   }
  // }, [getDetails?.tenderParticipationDtos]);
  // console.log("getParticipationDetails", getParticipationDetails);

  const { allCountryListingResponse } = useSelector((state) => state.contact);
  const { allCurrencyListingResponse } = useSelector(
    (state) => state.reference
  );
  useEffect(() => {
    dispatch(tenderTypeListing());
    dispatch(tenderStageListing());
    dispatch(allCountryListing());
    dispatch(allCompanyListing());
  }, [dispatch]);
  const [form] = Form.useForm();

  const [cashForm, setCashForm] = useState([]);
  const [localForm, setLocalForm] = useState([]);
  const [bidSecuirtyForm, setBidSecurityForm] = useState([]);

  const onSelectParticipant = (e) => {
    let checked = e.target.checked;
    if (checked === true) {
      setParticipationform(true);
      form.setFieldsValue({
        particiaption: [
          {
            company: "",
            stage: "",
            through: "",
            bidPrice: "",
            validity: "",
            rank: "",
            bidSecurity: "",
            type: "",
            arrangedBy: "",
            issuingBank: "",
            refNo: "",
            value: "",
          },
        ],
      });
    } else {
      setParticipationform(false);
      form.setFieldsValue({
        particiaption: [],
      });
    }
  };

  const numberCommaSepartor = (event) => {
    let budget = formParticipation.getFieldValue("bidPrice");
    console.log("budget", budget);

    const commaNum = budget.replace(/\,/g, "");
    if (isNaN(Number(commaNum))) {
      console.log("yesthisis");
      formParticipation.setFieldsValue({ bidPrice: commaSeparatorNum });
      return;
    }

    let tmp = budget.replace(/,/g, "");
    let val = Number(tmp).toLocaleString();
    if (tmp == "") {
      formParticipation.setFieldsValue({ bidPrice: "" });
    } else {
      formParticipation.setFieldsValue({ bidPrice: val });
      setCommaSeparatorNum(val);
    }
    console.log("test", tmp);
  };

  const addNewForm = (add, fieldIndex) => {
    add();
  };

  const getCompanyPagination = (SkipCount = 0, ForPagination = false) => {
    dispatch(
      getContactCompanyPagination(
        {
          SkipCount,
          SearchKeyword: searchValue,
        },
        ForPagination
      )
    );
  };

  const showBidSecurityForm = (value, fieldIndex) => {
    if (value === "Counter-guranteed") {
      let lists = cloneDeep(bidSecuirtyForm);
      lists[fieldIndex] = "true";
      setBidSecurityForm(lists);

      let Locallists = cloneDeep(localForm);
      Locallists[fieldIndex] = "false";
      setLocalForm(Locallists);

      let Cashlists = cloneDeep(cashForm);
      Cashlists[fieldIndex] = "false";
      setCashForm(Cashlists);
    } else if (value === "Foreign-Bank") {
      let lists = cloneDeep(bidSecuirtyForm);
      lists[fieldIndex] = "true";
      setBidSecurityForm(lists);

      let Locallists = cloneDeep(localForm);
      Locallists[fieldIndex] = "false";
      setLocalForm(Locallists);

      let Cashlists = cloneDeep(cashForm);
      Cashlists[fieldIndex] = "false";
      setCashForm(Cashlists);
    } else if (value === "Cash") {
      let lists = cloneDeep(cashForm);

      lists[fieldIndex] = "true";
      setCashForm(lists);

      let Locallists = cloneDeep(localForm);
      Locallists[fieldIndex] = "false";
      setLocalForm(Locallists);
    } else if (value === "Local") {
      let lists = cloneDeep(bidSecuirtyForm);
      lists[fieldIndex] = "true";
      setBidSecurityForm(lists);

      let localLists = cloneDeep(localForm);
      localLists[fieldIndex] = "true";
      setLocalForm(localLists);

      let Cashlists = cloneDeep(cashForm);
      Cashlists[fieldIndex] = "false";
      setCashForm(Cashlists);
    }
  };

  useEffect(() => {
    if (!isEmpty(contactCompanyPaginationResponse?.items)) {
      setContactCompanyList(contactCompanyPaginationResponse?.items);
    } else {
    }
  }, [contactCompanyPaginationResponse]);

  useEffect(() => {
    if (isEmpty(searchValue)) setContactCompanyList([]);
    if (isEmpty(searchValue)) return;
    if (
      !isEmpty(contactCompanyList) &&
      isEmpty(contactCompanyPaginationResponse?.items)
    )
      return;

    const timeoutId = setTimeout(() => getCompanyPagination(), 500);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  // console.log("getDetails", participationAddResponse);
  const onSubmitUpdate = async (action) => {
    try {
      let values = await formParticipation.validateFields();

      if (action === "add") {
        let add = {
          ...values,
          bidPriceCurrencyId: values.bidPriceCurrencyId
            ? values.bidPriceCurrencyId
            : defaultUSD,
          tenderBasicId: getDetails.id,
          shortListed: showShortListing,
          awarded: awarded,
          bidPrice: isEmpty(values.bidPrice)
            ? 1
            : Number(values.bidPrice.replace(/\,/g, "")),
          JVPartnerCompanyDtos: values.JVPartnerCompanyDtos?.map((data, i) => ({
            companyId: data,
          })),
          SubContractCompanyDtos: values.SubContractCompanyDtos?.map(
            (data, i) => ({
              companyId: data,
            })
          ),
        };

        dispatch(tenderParticipationAdd(add));
      } else {
        let update = {
          ...values,
          bidPriceCurrencyId: values.bidPriceCurrencyId
            ? values.bidPriceCurrencyId
            : defaultUSD,
          tenderBasicId: getDetails.id,
          bidPrice: isEmpty(values.bidPrice)
            ? 1
            : Number(values.bidPrice.replace(/\,/g, "")),
          shortListed: showShortListing,
          awarded: awarded,
          id: getParticipationDetails.id,
          JVPartnerCompanyDtos: values.JVPartnerCompanyDtos?.map((data, i) => ({
            companyId: data,
          })),
          SubContractCompanyDtos: values.SubContractCompanyDtos?.map(
            (data, i) => ({
              companyId: data,
            })
          ),
        };
        // console.log("updateValues", update);
        dispatch(tenderParticipationUpdate(update));

        // dispatch(addStageDocumentPurchase(add));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  //function for rendering rank
  const ranks = [];
  var value = 1;
  for (var i = 0; i < 20; i++) {
    ranks[i] = "L" + value;
    value++;
  }

  return (
    <>
      {particiaptionform && (
        <div className="business-form-wrapper" style={{ marginTop: 10 }}>
          <Form layout="vertical" form={formParticipation} name="control-hooks">
            <div className="global-form-flex">
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Company</label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="companyId"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      allowClear
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={(value) => {
                        if (value) {
                          setSearchValue(value);
                        } else {
                          setContactCompanyList([]);
                          setSearchValue(value);
                        }
                      }}
                      notFoundContent={
                        !isEmpty(searchValue) ? (
                          contactCompanyPaginationLoading ? (
                            <Spin />
                          ) : (
                            isEmpty(contactCompanyPaginationResponse?.items) &&
                            searchValue?.length > 2 && (
                              <div>{`Create "${searchValue}"`}</div>
                            )
                          )
                        ) : null
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (
                            isEmpty(contactCompanyPaginationResponse?.items) &&
                            !isEmpty(searchValue)
                          ) {
                            setVisibleModal(true);
                            setCompanyTender("companyclient");
                          }
                        }
                      }}
                      onBlur={() => setContactCompanyList([])}
                    >
                      {!isEmpty(getParticipationDetails) && (
                        <Option value={getParticipationDetails.companyId}>
                          {getParticipationDetails.companyName}
                        </Option>
                      )}
                      {contactCompanyList?.map((company) => (
                        <Option
                          value={company.contactCompanyId}
                          key={company.contactCompanyId}
                          label={company.contactCompanyName}
                        >
                          {company.contactCompanyName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label>Local Agency</label>
                </div>
                <div className="global-form-input">
                  <Form.Item
                    name="localAgency"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </div>
              </div>
              <div className="global-form-group calc">
                <div className="global-form-label"></div>
                <div className="global-form-input">
                  <div className="participation-company-wrapper">
                    {!showJvComapny && (
                      <Button
                        type="dashed"
                        className="reference__btns--add"
                        onClick={() => {
                          setShowJvCompany(true);
                        }}
                        block
                        icon={<PlusCircleOutlined />}
                      >
                        Add JV Company
                      </Button>
                    )}
                    {!showSubContractCompany && (
                      <Button
                        type="dashed"
                        className="reference__btns--add"
                        onClick={() => {
                          setShowSubContractCompany(true);
                        }}
                        block
                        icon={<PlusCircleOutlined />}
                      >
                        Add Sub Contract Company
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              {showJvComapny && (
                <div className="global-form-group calc">
                  <div className="global-form-label">
                    <label> JV Partner Company</label>
                    <MinusCircleOutlined
                      style={{
                        width: 45,
                        marginTop: 3,
                      }}
                      className="contact__btn-danger"
                      onClick={() => {
                        setShowJvCompany(false);
                      }}
                    />
                  </div>
                  <div className="global-form-input">
                    <Form.Item
                      name="JVPartnerCompanyDtos"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        mode="multiple"
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onSearch={(value) => {
                          if (value) {
                            setSearchValue(value);
                          } else {
                            setContactCompanyList([]);
                            setSearchValue(value);
                          }
                        }}
                        notFoundContent={
                          !isEmpty(searchValue) ? (
                            contactCompanyPaginationLoading ? (
                              <Spin />
                            ) : (
                              isEmpty(
                                contactCompanyPaginationResponse?.items
                              ) &&
                              searchValue?.length > 2 && (
                                <div>{`Create "${searchValue}"`}</div>
                              )
                            )
                          ) : null
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (
                              isEmpty(
                                contactCompanyPaginationResponse?.items
                              ) &&
                              !isEmpty(searchValue)
                            ) {
                              setVisibleModal(true);
                              setCompanyPartner("partner");
                            }
                          }
                        }}
                        onBlur={() => setContactCompanyList([])}
                      >
                        {!isEmpty(
                          getParticipationDetails?.jvPartnerCompanyDtos
                        ) &&
                          getParticipationDetails?.jvPartnerCompanyDtos?.map(
                            (country) => (
                              <Option
                                value={country.companyId}
                                key={country.id}
                              >
                                {country.companyName}
                              </Option>
                            )
                          )}
                        {!isEmpty(contactCompanyList) &&
                          allCountryListingResponse?.map((country) => (
                            <Option value={country.id} key={country.id}>
                              {country.companyName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              )}
              {showSubContractCompany && (
                <div className="global-form-group calc">
                  <div className="global-form-label">
                    <label>Sub Contract Company</label>
                    <MinusCircleOutlined
                      style={{
                        width: 45,
                        marginTop: 3,
                      }}
                      className="contact__btn-danger"
                      onClick={() => {
                        setShowSubContractCompany(false);
                      }}
                    />
                  </div>
                  <div className="global-form-input">
                    <Form.Item
                      name="SubContractCompanyDtos"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Select
                        size="small"
                        mode="multiple"
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onSearch={(value) => {
                          if (value) {
                            setSearchValue(value);
                          } else {
                            setContactCompanyList([]);
                            setSearchValue(value);
                          }
                        }}
                        notFoundContent={
                          !isEmpty(searchValue) ? (
                            contactCompanyPaginationLoading ? (
                              <Spin />
                            ) : (
                              isEmpty(
                                contactCompanyPaginationResponse?.items
                              ) &&
                              searchValue?.length > 2 && (
                                <div>{`Create "${searchValue}"`}</div>
                              )
                            )
                          ) : null
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (
                              isEmpty(
                                contactCompanyPaginationResponse?.items
                              ) &&
                              !isEmpty(searchValue)
                            ) {
                              setVisibleModal(true);
                              setSubContract("subcontract");
                            }
                          }
                        }}
                        onBlur={() => setContactCompanyList([])}
                      >
                        {!isEmpty(
                          getParticipationDetails?.subContractCompanyDtos
                        ) &&
                          getParticipationDetails?.subContractCompanyDtos?.map(
                            (country) => (
                              <Option
                                value={country.companyId}
                                key={country.id}
                              >
                                {country.companyName}
                              </Option>
                            )
                          )}
                        {!isEmpty(contactCompanyList) &&
                          allCountryListingResponse?.map((country) => (
                            <Option value={country.companyId} key={country.id}>
                              {country.companyName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              )}
              <div className="global-form-group calc">
                <div className="global-form-label">
                  <label> Short Listing</label>
                </div>
                <div className="global-form-input">
                  <Form.Item name="shortListed">
                    <Checkbox
                      onChange={(e) => {
                        setShowShortListing(!showShortListing);
                      }}
                      defaultValue={showShortListing}
                      checked={showShortListing}
                    />
                  </Form.Item>
                </div>
              </div>
              {showShortListing && (
                <>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label>Bid Price</label>
                    </div>
                    <div className="global-form-input flex">
                      <Form.Item
                        className="currency"
                        name="bidPriceCurrencyId"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        {!isEmpty(allCurrencyListingResponse) && (
                          <Select
                            size="small"
                            defaultValue={() => {
                              let value = "";
                              allCurrencyListingResponse?.map((currency) => {
                                if (currency.abbvr == "USD") {
                                  value = currency.currencyId;
                                }
                              });

                              return value;
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {allCurrencyListingResponse?.map((currency) => (
                              <Option
                                key={currency.currencyId}
                                value={currency.currencyId}
                              >
                                {currency.abbvr}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                      <Form.Item
                        className="budget-number"
                        name="bidPrice"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input size="small" onChange={numberCommaSepartor} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label>BID Validity</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item
                        name="bidValidityDate"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <DatePicker
                          size="small"
                          disabledDate={(current) => {
                            return current < moment();
                          }}
                          format="YYYY/MM/DD"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label>Rank</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item
                        name="rank"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Select size="small">
                          {ranks.map((rank, index) => {
                            return (
                              <Option key={index} value={rank}>
                                {rank}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label>BID Security</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          // onChange={(value) =>
                          //   showBidSecurityForm(value, fieldIndex)
                          // }
                        >
                          <Option value="Counter-guranteed">
                            Counter-guranteed
                          </Option>
                          <Option value="Foreign-Bank">Foreign Bank</Option>
                          <Option value="Local">Local</Option>
                          <Option value="Cash">Cash</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label>Technical Score</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item
                        name="technicalScore"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <InputNumber size="samll" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label> Financial Score</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item
                        name="financialScore"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <InputNumber size="samll" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label> Arranged By</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item
                        name="arrangedBy"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input size="samll" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label> Principal By</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item
                        name="principalBank"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input size="samll" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label>Issuing Bank</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item
                        name="issuingBank"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input size="samll" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label>Reference No</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item
                        name="referenceNo"
                        rules={[
                          {
                            required: false,
                          },
                        ]}
                      >
                        <Input size="samll" />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="global-form-group calc">
                    <div className="global-form-label">
                      <label>Awarded</label>
                    </div>
                    <div className="global-form-input">
                      <Form.Item name="awarded">
                        <Checkbox
                          onChange={() => {
                            setAwarded(!awarded);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </>
              )}
              <div className="global-form-footer">
                <Button
                  className="cancleAction"
                  onClick={() => {
                    setParticipationform(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="saveAction"
                  loading={
                    participationAddLoading || participationUpdateLoading
                  }
                  onClick={() => {
                    onSubmitUpdate(getParticipationDetails ? "update" : "add");
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
          <Modal
            visible={visibleModal}
            footer={null}
            onCancel={() => {
              addCompany.setFieldsValue({
                scopeOfWork: [],
                companyName: "",
                countryId: "",
                isActive: false,
                isFavorite: false,
                cityId: "",
              });
              setVisibleModal(false);
            }}
            keyboard={false}
            maskClosable={false}
          >
            <QuickAddCompany
              form={addCompany}
              companySearch={searchValue}
              quickContactCompanyAdd={quickContactCompanyAdd}
              quickContactCompanyLoader={quickContactCompanyLoader}
              quickAddFromProject="project"
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default ParticipationForm;
