import { Button, Col, Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAmmountListing } from "services/redux/requistions/leaveType/action";

const GuestEntertainmentMoreDetail = ({
  position,
  guestEntertainmentDetailResponse,
  isManagement,
  isSupervisor,
  isAccountant,
  setGeStatus,
  supervisorActionResponseLoading,
  managementActionResponseLoading,
  accountantActionResponseLoading,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;

  const { amountResponse, amountResponseLoading } = useSelector(
    (state) => state.leaveType
  );
  const [submitButton, setSubmitButton] = useState(false);
  const [clearButton, setClearButton] = useState(false);

  useEffect(() => {
    dispatch(getAmmountListing());
  }, []);

  return (
    <>
      {position === "SupervisorApproved" && (
        <Row gutter={[10, 10]}>
          <Col span={8}>
            <div className="leave-title">Supervisor comment:</div>
          </Col>
          <Col span={16}>
            {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
              (x) => {
                if (x.participantTypeName === "Supervisor")
                  return x.approveRejectRemarks;
              }
            )}
          </Col>
        </Row>
      )}
      {position === "SupervisorApproved" && (
        <>
          <Row gutter={[10, 10]}>
            <Col lg={12}>
              <div className="leave-title">Approve Guests Number</div>
              <Form.Item
                required="true"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
                name="approvedNumberOfGuest"
                style={{ width: "100%" }}
              >
                <Input type="number"></Input>
              </Form.Item>
            </Col>

            <Col lg={12}>
              <div className="leave-title">Food Rating Amount</div>

              <Form.Item
                required="true"
                name="foodRatingAmountId"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ width: "95%" }}
              >
                <Select placeholder="Select Company" autoComplete="off">
                  {!isEmpty(amountResponse) &&
                    amountResponse.map((amount, index) => {
                      return (
                        <Option value={amount.id} key={index}>
                          {amount.displayName}({amount.ratingAmount})
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <div className="leave-title">Drinking Rating Amount</div>

              <Form.Item
                required="true"
                name="drinkRatingAmountId"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ width: "95%" }}
              >
                <Select placeholder="Select Company" autoComplete="off">
                  {!isEmpty(amountResponse) &&
                    amountResponse.map((amount, index) => {
                      return (
                        <Option value={amount.id} key={index}>
                          {amount.displayName}({amount.ratingAmount})
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row></Row>
        </>
      )}
      {position === "SupervisorApproved" && (
        <>
          <Form.Item
            name="approvedRejectRemarks"
            style={{ width: "100%" }}
            label="Remarks"
            required="true"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
            labelAlign="left"
          >
            <Col span={24}>
              <TextArea />
            </Col>
          </Form.Item>
          <Row span={24} style={{ paddingTop: "20px" }}>
            <Button
              type="primary"
              style={{ marginRight: "15px" }}
              htmlType="submit"
              loading={managementActionResponseLoading}
              onClick={() => {
                setGeStatus("Approve");
              }}
            >
              Approve
            </Button>
            <Button
              type="danger"
              htmlType="submit"
              onClick={() => {
                setGeStatus("Reject");
              }}
            >
              Reject
            </Button>
          </Row>
        </>
      )}
      {position === "ManagementApproved" && (
        <>
          <Row>
            <Col span={8}>Supervisor comment</Col>
            <Col span={16}>
              {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                (x) => {
                  if (x.participantTypeName === "Supervisor")
                    return x.approveRejectRemarks;
                }
              )}
            </Col>
          </Row>

          <Row>
            <Col lg={8}>
              <div className="leave-title">Approved Food Amount</div>
            </Col>
            <Col span={16}>
              {
                guestEntertainmentDetailResponse
                  ?.managementFoodAmountApprovedDetails?.ratingAmount
              }
              (
              {
                guestEntertainmentDetailResponse
                  ?.managementFoodAmountApprovedDetails?.displayName
              }
              )
            </Col>

            <Col lg={8}>
              <div className="leave-title">Approved Drink Amount</div>
            </Col>

            <Col span={16}>
              {
                guestEntertainmentDetailResponse
                  ?.managementFoodAmountApprovedDetails?.ratingAmount
              }
              (
              {
                guestEntertainmentDetailResponse
                  ?.managementDrinkAmountApprovedDetails?.displayName
              }
              )
            </Col>
            <Col lg={8}>
              <div className="leave-title">Approved guests</div>
            </Col>
            <Col span={16}>
              {guestEntertainmentDetailResponse?.approvedNumberOfGuest}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className="leave-title">Management comment</div>
            </Col>
            <Col span={16}>
              {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                (x) => {
                  if (x.participantTypeName === "Management")
                    return x.approveRejectRemarks;
                }
              )}
            </Col>
          </Row>
        </>
      )}
      {position === "ManagementApproved" && (
        <>
          <Form.Item
            name="approvedRejectRemarks"
            style={{ width: "100%" }}
            label="Remarks"
            labelAlign="left"
            required="true"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
          >
            <Col span={24}>
              <TextArea />
            </Col>
          </Form.Item>
          <Row span={24} style={{ paddingTop: "20px" }}>
            <Button
              type="primary"
              style={{ marginRight: "15px" }}
              htmlType="submit"
              loading={managementActionResponseLoading}
              onClick={() => setGeStatus("Approve")}
            >
              Approve
            </Button>
            <Button
              type="danger"
              htmlType="submit"
              onClick={() => setGeStatus("Reject")}
            >
              Reject
            </Button>
          </Row>
        </>
      )}
      {position === "AccountantApproved" && (
        <>
          <Row>
            <Col span={8}>
              <div className="leave-title">Supervisor comment</div>
            </Col>
            <Col span={16}>
              {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                (x) => {
                  if (x.participantTypeName === "Supervisor")
                    return x.approveRejectRemarks;
                }
              )}
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <div className="leave-title">Approved Food Amount</div>

              {
                guestEntertainmentDetailResponse
                  ?.managementFoodAmountApprovedDetails?.displayName
              }
            </Col>
            <Col lg={8}>
              <div className="leave-title">Approved guests</div>

              {guestEntertainmentDetailResponse?.approvedNumberOfGuest}
            </Col>
            <Col lg={8}>
              <div className="leave-title">Approved Drink Amount</div>

              {
                guestEntertainmentDetailResponse
                  ?.managementDrinkAmountApprovedDetails?.displayName
              }
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <div className="leave-title">Management comment</div>

              {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                (x) => {
                  if (x.participantTypeName === "Management")
                    return x.approveRejectRemarks;
                }
              )}
            </Col>
          </Row>
          <Row gutter={20}>
            <Col lg={24}>
              <div className="leave-title">Accountant comment</div>

              {guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.map(
                (x) => {
                  if (x.participantTypeName === "Accountant")
                    return x.approveRejectRemarks;
                }
              )}
            </Col>
          </Row>
        </>
      )}
      {position === "Apply" && (
        <>
          <Form.Item
            name="approvedRejectRemarks"
            required="true"
            rules={[
              {
                required: true,
                message: "Required!",
              },
            ]}
            style={{ width: "100%" }}
            label="Remarks"
            labelAlign="left"
          >
            <Col span={24}>
              <TextArea />
            </Col>
          </Form.Item>
          <Row span={24} style={{ paddingTop: "20px" }}>
            <Button
              type="primary"
              style={{ marginRight: "15px" }}
              htmlType="submit"
              loading={
                (supervisorActionResponseLoading ||
                  managementActionResponseLoading ||
                  accountantActionResponseLoading) &&
                submitButton
              }
              onClick={() => {
                setSubmitButton(true);
                setGeStatus("Approve");
              }}
            >
              Approve
            </Button>
            <Button
              type="danger"
              htmlType="submit"
              loading={
                clearButton &&
                (supervisorActionResponseLoading ||
                  managementActionResponseLoading ||
                  accountantActionResponseLoading)
              }
              onClick={() => {
                setClearButton(true);
                setGeStatus("Reject");
              }}
            >
              Reject
            </Button>
          </Row>
        </>
      )}
    </>
  );
};

export default GuestEntertainmentMoreDetail;
