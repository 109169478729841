export const formatReferenceCodesWithTooltip = (items, maxDisplayCount = 2) => {
  if (!Array.isArray(items) || items.length === 0) return null;

  // Extract the reference codes
  const referenceCodes = items.map((item) => item.referenceCode);

  // Get the first few reference codes to display
  const visibleCodes = referenceCodes.slice(0, maxDisplayCount);

  // Get the remaining reference codes for the tooltip
  const remainingCodes = referenceCodes.slice(maxDisplayCount);

  // Format the display text (first few items + "+X" if there are more)
  const displayText =
    remainingCodes.length > 0
      ? `${visibleCodes.join(", ")} +${remainingCodes.length}`
      : visibleCodes.join(", ");

  // Tooltip text (only the remaining items)
  const tooltipText =
    remainingCodes.length > 0 ? remainingCodes.join(", ") : null;

  return { displayText, tooltipText };
};
