import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from 'antd';
import { validateMessages } from 'utils/misc';
import { statusAdd, statusUpdate } from 'services/redux/contact/contact.action';

const { Header, Footer } = Layout;
const { Option } = Select;

const StatusAddUpdateDrawer = ({ onAddUpdateStatusClose, drawerVisibility, isUpdateStatus }) => {
	const { statusAddResponse, statusUpdateResponse } = useSelector((state) => state.contact);

	const [form] = Form.useForm();

	form.resetFields();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isUpdateStatus) return;
		form.setFieldsValue({
			...isUpdateStatus,
		});
	}, [isUpdateStatus]);

	useEffect(() => {
		onAddUpdateStatusClose();
	}, [statusAddResponse, statusUpdateResponse]);

	const onSubmit = async () => {
		try {
			let values = await form.validateFields();
			if (isUpdateStatus) {
				dispatch(statusUpdate(isUpdateStatus.id, values));
			} else {
				dispatch(statusAdd(values));
			}
		} catch (errorInfo) {
			console.error('Failed:', errorInfo);
		}
	};

	return (
		<Drawer visible={drawerVisibility} width={472} maskClosable={false} className="drawer" onClose={onAddUpdateStatusClose} closable={false}>
			<Header className="drawer__header">
				<h4 className="drawer__header--title">{`${isUpdateStatus ? 'Update' : 'Create'} Status Name`}</h4>
			</Header>

			<Form layout="vertical" form={form} name="control-hooks" validateMessages={validateMessages} className="drawer-form-wrapper">
				<div className="drawer-form-row">
					<div className="drawer-form-group">
						<Form.Item className="form__group" name="status" label="Status Name" rules={[{ required: true }]}>
							<Input autoComplete='off' />
						</Form.Item>
					</div>
				</div>
				<div className="model-footer">
					<Button onClick={onAddUpdateStatusClose}>Cancel</Button>
					<Button onClick={onSubmit} type="primary">
						Submit
					</Button>
				</div>
			</Form>
		</Drawer>
	);
};

export default StatusAddUpdateDrawer;
