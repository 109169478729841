import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import CompanyNoteDrawer from "./CompanyNoteDrawer";
import { contactCompanyNoteUpdate } from "services/redux/contact/companypeople/action";
import { useDispatch, useSelector } from "react-redux";
const addcompany = "assets/svg/addcompany.svg";
const CheckMark = "assets/svg/checkmark-24.svg";
const cancel = "assets/svg/cross-24.svg";

const EditNotes = ({
  responseNotes,
  showAddUpdateDrawer,
  drawerVisibility,
  showArrow,
  onAddUpdateClose,
  windowWidth,
  form,
  notes,
  setNotes,
}) => {
  const dispatch = useDispatch();

  const show = "assets/svg/email-show16.svg";

  const edit = "./assets/svg/profile-edit.svg";

  useEffect(() => {
    if (showArrow == false) {
      setNotes(true);
    }
  }, [showArrow]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();

      dispatch(contactCompanyNoteUpdate(responseNotes.id, values));
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  // console.log(notes,"notes");
  return (
    <>
      {notes ? (
        <div className={`right__notes ${!showArrow ? "active" : ""}`}>
          <div className="contactsDetails__infonotes">
            <div
              className="contactDetails__label--infoNotes"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span className="contactsDetails--title bold w-100">Notes </span>
              <div>
                <img
                  src={edit}
                  onClick={() => {
                    setNotes(false);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="contactDetails__labeldata">
            <span className="contactsDetails--subtitle bold">
              {responseNotes?.notes}
            </span>
          </div>
        </div>
      ) : (
        <div className={`right__notes ${!showArrow ? "active" : ""}`}>
          <div className="contactsDetails__infonotes">
            <div
              className="contactDetails__label--infoNotes"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px, 5px",
              }}
            >
              <span className="contactsDetails--title bold w-100">Notes </span>
              <div className="contactDetails__button">
                <div>
                  <img
                    src={CheckMark}
                    onClick={() => {
                      setNotes(true);
                      onSubmit();
                    }}
                  />
                </div>
                <div>
                  <img
                    src={cancel}
                    onClick={() => {
                      setNotes(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="editnote-form-wrapper">
            <Form layout="vertical" form={form} name="control-hooks">
              <Form.Item name="notes" rules={[{ required: false }]}>
                <Input.TextArea
                  bordered={false}
                  defaultValue={responseNotes?.notes}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      )}

      {showArrow ? (
        <div className="right__notes--show">
          <div
            className="email-internal-hide-show"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="meetingInfos">
              {/* <div className="email-description">
							<p
								className="main_content"
								dangerouslySetInnerHTML={{
									__html: initialMessage,
								}}
							></p>
						</div> */}
            </div>
            <div
              className="chatmail__header--wrapper-hide chatmail__image"
              style={{ marginLeft: -3 }}
              onClick={() => showAddUpdateDrawer()}
            >
              <img src={edit} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <CompanyNoteDrawer
        responseNotes={responseNotes}
        windowWidth={windowWidth}
        drawerVisibility={drawerVisibility}
        onAddUpdateClose={onAddUpdateClose}
      />
    </>
  );
};
export default EditNotes;
