import { getService, postService, putService } from "services/commonServices";
import { appBaseUrl } from "utils/config";
import queryString from "query-string";

export class HrSystemServices {
  apiForGetStageLevelListing() {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionApplicationStageLevelListing`;
    const response = getService(url);
    return response;
  }

  //Application Apis
  apiForCreateEmpRequisitionApplication(data) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionCreateApplication`;
    const response = postService(url, data);
    return response;
  }

  // apiForGetEmpRequisitionApplicationListDetails() {
  //   let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionGetPaginationListing`;
  //   const response = getService(url);
  //   return response;
  // }
  // updated code
  apiForGetEmpRequisitionApplicationListDetails(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionGetPaginationListing?${query}`;
    let data = getService(url);
    return data;
  }

  apiForGetEmpRequisitionSingleApplicationDetail(jobTitleId) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionApplicationGetDetails/${jobTitleId}`;
    const response = getService(url);
    return response;
  }

  apiForGetEmpRequisitionSingleApplicationStatusCounterDetail(jobTitleId) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionApplicantsGetAllCounter/${jobTitleId}`;
    const response = getService(url);
    return response;
  }

  apiForUpdateEmpRequisitionSingleApplicationDetail(jobTitleId, body) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionUpdateApplication/${jobTitleId}`;
    const response = putService(url, body);
    return response;
  }

  //end

  //Applicants Apis
  //add update basic details
  apiForCreateUpdateApplicants(data) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionAddUpdateApplicant`;
    const response = postService(url, data);
    return response;
  }

  //add update education
  apiForCreateUpdateApplicantsEducation(data, applicantId) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionAddUpdateApplicantEducationInformation/${applicantId}`;
    const response = postService(url, data);
    return response;
  }

  //add update job details
  apiForCreateUpdateApplicantsJobDetails(data, applicantId) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionAddUpdateApplicantPreviousJobDetails/${applicantId}`;
    // let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionAddUpdateApplicantPreviousJobDetails`;
    const response = postService(url, data);
    return response;
  }

  //add update review details
  apiForCreateUpdateApplicantReviews(data, applicantId) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionAddUpdateApplicantReview`;
    // let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionAddUpdateApplicantPreviousJobDetails`;
    const response = postService(url, data);
    return response;
  }

  // corporate email address listing
  apiForCorporateEmailListing(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/emailMessage/corporateEmailListing?${query}`;
    let data = getService(url);
    return data;
  }

  // final approve
  apiForCreateFinalApprove(applicantId, data) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionApplicantTransferToEmployee/${applicantId}`;
    const response = postService(url, data);
    return response;
  }

  // job interview
  // apiForCreateUpdateJobInterview(applicantId, data) {
  apiForCreateJobInterview(data) {
    let url = `${appBaseUrl}/requisitionManagement/CreateJobInterview`;
    const response = postService(url, data);
    return response;
  }

  apiForUpdateJobInterview(applicantId, data) {
    let url = `${appBaseUrl}/requisitionManagement/UpdateJobInterview/${applicantId}`;
    const response = putService(url, data);
    return response;
  }

  apiForGetJobInterviewById(id) {
    let url = `${appBaseUrl}/requisitionManagement/GetJobInterviewById/${id}`;
    const response = getService(url);
    return response;
  }

  apiForGetJobInterviewPagination() {
    let url = `${appBaseUrl}/requisitionManagement/jobInterviewPagination`;
    const response = getService(url);
    return response;
  }

  apiForJobApplicantComposeEmail(data) {
    let url = `${appBaseUrl}/requisitionManagement/employeeRequisitionComposeEmailForApplicant`;
    const response = postService(url, data);
    return response;
  }

  //end
}
