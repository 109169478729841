import React, { useState, useEffect } from "react";
import {
  Collapse,
  Form,
  DatePicker,
  TimePicker,
  Button,
  Layout,
  Drawer,
  Input,
  Popover,
  Checkbox,
} from "antd";
import {
  PlusCircleOutlined,
  MoreOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import DynamicImageUploader from "components/shared/DynamicImageUploader";
import { cqBaseUrl } from "utils/config";

import {
  tenderStageExtensionAdd,
  tenderStageExtensionDelete,
  tenderStageExtensionUpdate,
} from "services/redux/Reference/Tender/action";
const AddExtensions = ({
  showExtensionDrawer,
  onClose,
  getDetails,
  extensionData,
  setExtensionData,
  showAddExtension,
  dynamicExtensionFileTypesState,
  fetchData,
}) => {
  const [showExtensioForm, setExtensionForm] = useState(false);
  const {
    tenderStageUpdateExtensionResponse,
    tenderStageAddExtensionResponse,
  } = useSelector((state) => state.tender);

  const { token } = useSelector(
    //for accessing to the token form redux store
    (state) => state.auth
  );

  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const [form2] = Form.useForm();
  const { Header } = Layout;

  const [form] = Form.useForm();

  const [popoverMenuVisible, setPopoverMenuVisible] = useState([]);
  const [showDocumentPurchae, setDocumentPurchase] = useState([]);
  const [value, setValue] = useState();
  const [getDocPurchaseDetails, setDocPurchaseDetails] = useState();
  const [bidChecked, setBidChecked] = useState(false);
  const [extSubDate, setExtSubDate] = useState("");
  const [extInvDate, setExtInvDate] = useState("");
  const [extOpenDate, setExtOpenDate] = useState("");

  const handleVisibleChange = (index) => {
    let lists = [...popoverMenuVisible];
    lists[index] = !lists[index];
    setPopoverMenuVisible(lists);
  };

  useEffect(() => {
    if (!isEmpty(getDocPurchaseDetails)) {
      setExtSubDate(getDocPurchaseDetails?.submissionDate);
      setExtOpenDate(getDocPurchaseDetails.openingDate);
      setExtInvDate(getDocPurchaseDetails.invitationDate);
    }
  }, [getDocPurchaseDetails]);

  useEffect(() => {
    if (isEmpty(getDocPurchaseDetails)) return;

    form2.setFieldsValue({
      ...getDocPurchaseDetails,
      invitationDate: moment(getDocPurchaseDetails.invitationDate),
      submissionDate: moment(getDocPurchaseDetails.submissionDate),
      bankGuranteeValidity:
        getDocPurchaseDetails.bankGuranteeValidity != null
          ? moment(getDocPurchaseDetails.bankGuranteeValidity)
          : "",
      bidValidity:
        getDocPurchaseDetails.bidValidity != null
          ? moment(getDocPurchaseDetails.bidValidity)
          : "",
      openingDate: moment(getDocPurchaseDetails.openingDate),
      tenderstageextensionFileDtos:
        getDocPurchaseDetails.tenderStageExtensionFileList,
    });
  }, [getDocPurchaseDetails]);

  useEffect(() => {
    const timeoutId = setTimeout(() => 1000);
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    if (tenderStageUpdateExtensionResponse || tenderStageAddExtensionResponse) {
      setDocumentPurchase([]);
      form.resetFields();
    }
  }, [tenderStageUpdateExtensionResponse, tenderStageAddExtensionResponse]);

  useEffect(() => {
    if (tenderStageAddExtensionResponse) {
      setExtensionForm(false);
      form.resetFields();
    }
  }, [tenderStageAddExtensionResponse]);

  const getActionContent = (item, index) => {
    return (
      <div className="guest-actions">
        <div
          className="guest-act"
          onClick={() => {
            handleVisibleChange(index);
            let lists = cloneDeep(showDocumentPurchae);
            lists[index] = "true";
            setDocumentPurchase(lists);
            setDocPurchaseDetails(item);
          }}
        >
          Edit
        </div>
        <div
          className="guest-act"
          onClick={() => {
            dispatch(
              tenderStageExtensionDelete(item?.id, item?.tenderStageLevelId)
            );
          }}
        >
          Delete
        </div>
      </div>
    );
  };

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      let add = {
        ...values,
        tenderStageLevelId: fetchData.id,
        tenderStageExtensionFileList: values.TenderFileDtos,
      };
      dispatch(tenderStageExtensionAdd(add));
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  const onSubmitUpdate = async () => {
    try {
      let values = await form2.validateFields();
      let update = {
        ...values,
        submissionDate: extSubDate,
        openingDate: extOpenDate,
        invitationDate: extInvDate,
        id: getDocPurchaseDetails?.id,
        tenderBasicId: getDocPurchaseDetails?.tenderBasicId,
        tenderStageExtensionFileList: values.tenderstageextensionFileDtos,
        tenderStageLevelId: fetchData.id,
      };
      dispatch(tenderStageExtensionUpdate(update));
    } catch (errorInfo) {
      // console.error("Failed:", errorInfo);
    }
  };

  const onChange = (e) => {
    setBidChecked(!bidChecked);
  };
  return (
    <Drawer
      placement="right"
      width={500}
      className="drawer"
      closable={false}
      visible={showExtensionDrawer}
      onClose={() => {
        onClose();
        setExtensionData();
      }}
    >
      <Header className="drawer__header">
        <h4 className="drawer__header--title">Extension History</h4>
      </Header>
      <div className="extensions">
        <div className="extensions__wrapper">
          <div className="profiledetails__content--divider">
            <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title"> Reference</span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle">
                  {getDetails?.referenceCode}
                </span>
              </div>
            </div>

            <div className="reference__info noWrap-content">
              <div className="reference__label">
                <span className="referenceDetails--title ">Title </span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle noWrap-content">
                  {getDetails?.title}
                </span>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="profiledetails__content--divider">
          <div className="reference__info">
            <div className="reference__label">
              <span className="referenceDetails--title ">
                Client Reference{" "}
              </span>
            </div>
            <div className="reference__labeldata">
              <span className="referenceDetails--subtitle ">
                {getDetails?.clientReference
                  ? moment(getDetails?.clientReference)
                  : "_ _ _ "}
              </span>
            </div>
          </div>

          <div className="reference__info noWrap-content">
            <div className="reference__label">
              <span className="referenceDetails--title ">Description </span>
            </div>
            <div className="reference__labeldata">
              <span className="referenceDetails--subtitle noWrap-content">
                {fetchData?.description ? fetchData?.description : "_ _ _"}
              </span>
            </div>
          </div>
          <h1>{value}</h1>

          <div className="reference__info">
            <div className="reference__label">
              <span className="referenceDetails--title "> Document Cost </span>
            </div>
            <div className="reference__labeldata">
              <span className="referenceDetails--subtitle ">
                {getDetails?.documentCostCurrencySymbol}.
                {fetchData?.documentCost}
              </span>
            </div>
          </div>
        </div>
        <div className="profiledetails__content--divider">
          <div className="reference__info">
            <div className="reference__label">
              <span className="referenceDetails--title ">Submission On </span>
            </div>
            <div className="reference__labeldata">
              <span className="referenceDetails--subtitle ">
                {moment(getDetails?.submissionDate).format("YYYY / MM / DD")}
              </span>
            </div>
          </div>

          {getDetails?.bidValidity ? (
            <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title ">
                  Bid Validity till{" "}
                </span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle ">
                  {moment(getDetails?.bidValidity).format("YYYY / MM / DD")}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}

          {getDetails?.bidValidity && (
            <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title ">
                  Bid Validity till{" "}
                </span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle ">
                  {getDetails?.bidValidity
                    ? moment(getDetails?.bidValidity).format("YYYY / MM / DD")
                    : "_ _ _"}
                </span>
              </div>
            </div>
          )}

          {getDetails?.bankGuranteeCurrencySymbol &&
            getDetails?.bankGuranteeValue && (
              <div className="reference__info">
                <div className="reference__label">
                  <span className="referenceDetails--title ">
                    {" "}
                    Bid Seurity of{" "}
                  </span>
                </div>
                <div className="reference__labeldata">
                  <span className="referenceDetails--subtitle ">
                    {getDetails?.bankGuranteeCurrencySymbol}.
                    {getDetails?.bankGuranteeValue}
                  </span>
                </div>
              </div>
            )}

          {getDetails?.bankGuranteeValidity && (
            <div className="reference__info">
              <div className="reference__label">
                <span className="referenceDetails--title ">
                  Bid Security Validity till{" "}
                </span>
              </div>
              <div className="reference__labeldata">
                <span className="referenceDetails--subtitle ">
                  {getDetails?.bankGuranteeValidity
                    ? moment(getDetails?.bankGuranteeValidity).format(
                        "YYYY/MM/DD"
                      )
                    : "_ _ _"}
                </span>
              </div>
            </div>
          )}
        </div>
        {!isEmpty(extensionData) ? (
          <div>
            <>
              {extensionData?.map((data, index) => {
                return (
                  <div className="reference__view" key={data?.id}>
                    <div className="reference__header">
                      <span className="reference__header--text">
                        Extension No: {index + 1}
                      </span>
                      <div className="reference__header--left">
                        <span className="reference__header--rank">
                          {data.rank}
                        </span>
                        <Popover
                          content={() => getActionContent(data, index)}
                          title="Action"
                          trigger="click"
                          className="tooltips"
                          visible={popoverMenuVisible[index]}
                          onVisibleChange={() => handleVisibleChange(index)}
                          placement="bottomRight"
                        >
                          <MoreOutlined style={{ fontSize: 18 }} />
                        </Popover>
                      </div>
                    </div>
                    <div className="profiledetails__content--divider">
                      <div className="reference__info">
                        <div className="reference__label">
                          <span className="referenceDetails--title ">
                            Opening Date
                          </span>
                        </div>
                        <div className="reference__labeldata">
                          <span className="referenceDetails--subtitle ">
                            {/* {moment(data.openingDate).format(
                              "YYYY/MM/DD,h:mm:ss"
                            )} */}
                            {moment(data.openingDate).format(
                              "YYYY/MM/DD,hh:mm A"
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="reference__info">
                        <div className="reference__label">
                          <span className="referenceDetails--title ">
                            Submission On
                          </span>
                        </div>
                        <div className="reference__labeldata">
                          <span className="referenceDetails--subtitle ">
                            {/* {moment(data.submissionDate).format(
                              "YYYY/MM/DD,h:mm:ss"
                            )} */}
                            {moment(data.submissionDate).format(
                              "YYYY/MM/DD,hh:mm A"
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="reference__info">
                        <div className="reference__label">
                          <span className="referenceDetails--title ">
                            {" "}
                            Invitation On
                          </span>
                        </div>
                        <div className="reference__labeldata">
                          <span className="referenceDetails--subtitle ">
                            {/* {moment(data.invitationDate).format(
                              "YYYY/MM/DD,h:mm:ss"
                            )} */}
                            {moment(data.invitationDate).format(
                              "YYYY/MM/DD,hh:mm A"
                            )}
                          </span>
                        </div>
                      </div>
                      {data.bankGuranteeValidity && (
                        <div className="reference__info">
                          <div className="reference__label">
                            <span className="referenceDetails--title ">
                              {" "}
                              Bid BG Validity
                            </span>
                          </div>
                          <div className="reference__labeldata">
                            <span className="referenceDetails--subtitle ">
                              {/* {moment(data.bankGuranteeValidity).format(
                              "YYYY/MM/DD"
                            )} */}
                              {data.bankGuranteeValidity
                                ? moment(data.bankGuranteeValidity).format(
                                    "YYYY/MM/DD"
                                  )
                                : "_ _ _"}
                            </span>
                          </div>
                        </div>
                      )}

                      {data.bidValidity && (
                        <div className="reference__info">
                          <div className="reference__label">
                            <span className="referenceDetails--title ">
                              {" "}
                              Bid Validity
                            </span>
                          </div>
                          <div className="reference__labeldata">
                            <span className="referenceDetails--subtitle ">
                              {data.bidValidity
                                ? moment(data.bidValidity).format("YYYY/MM/DD")
                                : "_ _ _"}
                            </span>
                          </div>
                        </div>
                      )}

                      <>
                        <div className="referenceDetails__label">
                          <span className="referenceDetails--title">
                            Extension Notice
                          </span>
                        </div>
                        <div
                          className="referenceDetails__labeldata"
                          style={{ marginRight: 4 }}
                        >
                          <span
                            className="referenceDetails--subtitle"
                            style={{ display: "flex" }}
                          >
                            {data.tenderStageExtensionFileList.map(
                              (doc, index) => {
                                return (
                                  doc.isActive && (
                                    <div>
                                      <PaperClipOutlined />{" "}
                                      <a
                                        target="_blank"
                                        download
                                        href={
                                          cqBaseUrl +
                                          "/" +
                                          doc.viewFileURL +
                                          token
                                        }
                                      >
                                        {doc.displayFileName}
                                      </a>
                                    </div>
                                  )
                                );
                              }
                            )}
                          </span>
                        </div>
                      </>
                    </div>
                    <>
                      {!isEmpty(showDocumentPurchae) &&
                      showDocumentPurchae[index] === "true" ? (
                        <div className="profiledetails">
                          <div className="reference__view">
                            <div className="edittender__wrapper">
                              <Form
                                layout="vertical"
                                form={form2}
                                name="control-hooks"
                              >
                                <div
                                  className="editender__body"
                                  style={{ padding: "0px 12px 0px 16px" }}
                                >
                                  <div className="profiledetails__rowedit">
                                    <div className="profiledetails__rowedit--label">
                                      <span className="profile__bodyTitle">
                                        Opening Dateee
                                      </span>
                                    </div>
                                    <div
                                      className="profiledetails__rowedit--data"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span
                                        className="contactsDetails--subtitle bold"
                                        style={{ width: "100%" }}
                                      >
                                        <Form.Item
                                          name="openingDate"
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            onChange={(value, dateString) => {
                                              setExtOpenDate(dateString);
                                            }}
                                            format="YYYY/MM/DD hh:mm A"
                                            showTime
                                          />
                                        </Form.Item>
                                      </span>
                                      {/* <span
                                        className="contactsDetails--subtitle bold"
                                        style={{ width: "30%" }}
                                      >
                                        <Form.Item
                                          name="bidSecurityValue"
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <TimePicker
                                            size="small"
                                            getPopupContainer={(trigger) =>
                                              trigger.parentNode
                                            }
                                          />
                                        </Form.Item>
                                      </span> */}
                                    </div>
                                  </div>

                                  <div className="profiledetails__rowedit">
                                    <div className="profiledetails__rowedit--label">
                                      <span className="profile__bodyTitle">
                                        Submission Date
                                      </span>
                                    </div>
                                    <div
                                      className="profiledetails__rowedit--data"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span
                                        className="contactsDetails--subtitle bold"
                                        style={{ width: "100%" }}
                                      >
                                        <Form.Item
                                          name="submissionDate"
                                          initialValue={moment(
                                            data.submissionDate
                                          )}
                                          rules={[
                                            {
                                              required: false,
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            onChange={(value, dateString) => {
                                              setExtSubDate(dateString);
                                            }}
                                            format="YYYY/MM/DD hh:mm A"
                                            showTime
                                          />
                                        </Form.Item>
                                      </span>
                                      {/* <span
                                        className="contactsDetails--subtitle bold"
                                        style={{ width: "30%" }}
                                      >
                                        <Form.Item
                                          rules={[
                                            {
                                              required: false,
                                            },
                                          ]}
                                        >
                                          <TimePicker size="small" />
                                        </Form.Item>
                                      </span> */}
                                    </div>
                                  </div>
                                  <div className="profiledetails__rowedit">
                                    <div className="profiledetails__rowedit--label">
                                      <span className="profile__bodyTitle">
                                        Invitation Date
                                      </span>
                                    </div>
                                    <div className="profiledetails__rowedit--data">
                                      <span className="referenceDetails--subtitle ">
                                        <Form.Item
                                          name="invitationDate"
                                          initialValue={moment(
                                            data.invitationDate
                                          )}
                                          rules={[
                                            {
                                              required: false,
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            size="small"
                                            format="YYYY/MM/DD"
                                            onChange={(value, dateString) => {
                                              setExtInvDate(dateString);
                                            }}
                                            getPopupContainer={(trigger) =>
                                              trigger.parentNode
                                            }
                                          />
                                        </Form.Item>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="profiledetails__rowedit">
                                    <div className="profiledetails__rowedit--label">
                                      <span className="profile__bodyTitle">
                                        BG Validity
                                      </span>
                                    </div>
                                    <div className="profiledetails__rowedit--data">
                                      <span className="referenceDetails--subtitle ">
                                        <Form.Item
                                          name="bankGuranteeValidity"
                                          initialValue={
                                            data.bankGuranteeValidity == null
                                              ? ""
                                              : moment(
                                                  data.bankGuranteeValidity
                                                )
                                          }
                                          rules={[
                                            {
                                              required: false,
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            size="small"
                                            format="YYYY/MM/DD"
                                            getPopupContainer={(trigger) =>
                                              trigger.parentNode
                                            }
                                          />
                                        </Form.Item>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="profiledetails__rowedit">
                                    <div className="profiledetails__rowedit--label">
                                      <span className="profile__bodyTitle">
                                        Bid Validity
                                      </span>
                                    </div>
                                    <div className="profiledetails__rowedit--data">
                                      <span className="referenceDetails--subtitle ">
                                        <Form.Item
                                          name="bidValidity"
                                          initialValue={moment(
                                            data.bidValidity
                                          )}
                                          rules={[
                                            {
                                              required: false,
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            size="small"
                                            format="YYYY/MM/DD"
                                            getPopupContainer={(trigger) =>
                                              trigger.parentNode
                                            }
                                          />
                                        </Form.Item>
                                      </span>
                                    </div>
                                  </div>
                                  {dynamicExtensionFileTypesState.map(
                                    (u, i) => (
                                      <div
                                        className="profiledetails__rowedit"
                                        key={u.id}
                                      >
                                        <div className="profiledetails__rowedit--label">
                                          <span className="profile__bodyTitle">
                                            Extension Notice
                                          </span>
                                        </div>
                                        <div className="profiledetails__rowedit--data">
                                          <Form.Item
                                            name={[`${u.name}FileDtos`]}
                                            className="form__group"
                                            rules={[{ required: false }]}
                                          >
                                            <DynamicImageUploader
                                              name={u.name}
                                              isMultipleFileAllowed={
                                                u.isMultipleFileAllowed
                                              }
                                              id={u.id}
                                              allowedExtensions={
                                                u.allowedExtensions
                                              }
                                              // fileList={fileList}
                                              // setFileList={setFileList}
                                              index={i}
                                              form={form2}
                                              callback={(e) => {
                                                let olduploadedFileDtos =
                                                  form2.getFieldValue()[
                                                    `${u.name}FileDtos`
                                                  ]
                                                    ? [
                                                        ...form2.getFieldValue()[
                                                          `${u.name}FileDtos`
                                                        ],
                                                      ]
                                                    : [];

                                                let uploadedFileDtos = [
                                                  ...olduploadedFileDtos,
                                                ];
                                                uploadedFileDtos.push(e);

                                                form2.setFieldsValue({
                                                  [`${u.name}FileDtos`]:
                                                    uploadedFileDtos,
                                                });

                                                // form.setFieldsValue({
                                                //   uploadedFileDtos: [
                                                //     ...olduploadedFileDtos,
                                                //     e
                                                //   ]
                                                //     })
                                              }}
                                            />
                                          </Form.Item>
                                        </div>
                                      </div>
                                    )
                                  )}

                                  <div className="reference__head">
                                    <Button
                                      className="btn-cancel"
                                      onClick={() => {
                                        setExtensionForm(false);
                                        let lists =
                                          cloneDeep(showDocumentPurchae);
                                        lists[index] = "false";
                                        setDocumentPurchase(lists);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      type="primary"
                                      onClick={() => {
                                        onSubmitUpdate();
                                      }}
                                      className="btn-update__updateref"
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                );
              })}
            </>
          </div>
        ) : (
          <div className="reply-Of">
            <span>You have no Extension History</span>
          </div>
        )}

        {showExtensioForm && (
          <div className="profiledetails">
            <div className="reference__view">
              <div
                className="edittender__wrapper"
                style={{ height: bidChecked === false && "314px" }}
              >
                <Form layout="vertical" form={form} name="control-hooks">
                  <div className="editender__body">
                    <div className="profiledetails__rowedit">
                      <div className="profiledetails__rowedit--label">
                        <span className="profile__bodyTitle">
                          Extension Date Notice
                        </span>
                      </div>
                      <div className="profiledetails__rowedit--data">
                        <span className="referenceDetails--subtitle ">
                          <Form.Item
                            name="invitationDate"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              size="small"
                              format="YYYY/MM/DD"
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </Form.Item>
                        </span>
                      </div>
                    </div>

                    <div className="profiledetails__rowedit">
                      <div className="profiledetails__rowedit--label">
                        <span className="profile__bodyTitle">
                          Submission On
                        </span>
                      </div>
                      <div
                        className="profiledetails__rowedit--data"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="contactsDetails--subtitle bold"
                          style={{ width: "65%" }}
                        >
                          <Form.Item
                            name="submissionDate"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              size="small"
                              format="YYYY/MM/DD"
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </Form.Item>
                        </span>
                        <span
                          className="contactsDetails--subtitle bold"
                          style={{ width: "30%" }}
                        >
                          <Form.Item
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <TimePicker
                              size="small"
                              minuteStep={15}
                              format="HH:mm"
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </Form.Item>
                        </span>
                      </div>
                    </div>
                    <div className="profiledetails__rowedit">
                      <div className="profiledetails__rowedit--label">
                        <span className="profile__bodyTitle">Opening Date</span>
                      </div>
                      <div
                        className="profiledetails__rowedit--data"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className="contactsDetails--subtitle bold"
                          style={{ width: "65%" }}
                        >
                          <Form.Item
                            name="openingDate"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <DatePicker
                              size="small"
                              format="YYYY/MM/DD"
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </Form.Item>
                        </span>
                        <span
                          className="contactsDetails--subtitle bold"
                          style={{ width: "30%" }}
                        >
                          <Form.Item
                            name="bidSecurityValue"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <TimePicker
                              size="small"
                              minuteStep={15}
                              format="HH:mm"
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </Form.Item>
                        </span>
                      </div>
                    </div>
                    <Checkbox onChange={onChange}>Bid Validity</Checkbox>
                    {bidChecked && (
                      <>
                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">
                              Bid Validity
                            </span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <span className="referenceDetails--subtitle ">
                              <Form.Item name="bidValidity">
                                <DatePicker
                                  size="small"
                                  format="YYYY/MM/DD"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                />
                              </Form.Item>
                            </span>
                          </div>
                        </div>

                        <div className="profiledetails__rowedit">
                          <div className="profiledetails__rowedit--label">
                            <span className="profile__bodyTitle">
                              BG Validity
                            </span>
                          </div>
                          <div className="profiledetails__rowedit--data">
                            <span className="referenceDetails--subtitle ">
                              <Form.Item name="bankGuranteeValidity">
                                <DatePicker
                                  size="small"
                                  format="YYYY/MM/DD"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                />
                              </Form.Item>
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    {dynamicExtensionFileTypesState.map((u, i) => (
                      <div className="profiledetails__rowedit">
                        <div className="profiledetails__rowedit--label">
                          <span className="profile__bodyTitle">
                            Extension Notice
                          </span>
                        </div>
                        <div className="profiledetails__rowedit--data">
                          <Form.Item
                            name={[`${u.fileLableTypeGroupName}FileDtos`]}
                            className="form__group"
                            rules={[{ required: false }]}
                          >
                            <DynamicImageUploader
                              name={u.fileLableTypeGroupName}
                              isMultipleFileAllowed={u.isMultipleFileAllowed}
                              id={u.id}
                              allowedExtensions={u.allowedExtensions}
                              // fileList={fileList}
                              // setFileList={setFileList}
                              index={i}
                              form={form}
                              callback={(e) => {
                                let olduploadedFileDtos = form.getFieldValue()[
                                  `${u.fileLableTypeGroupName}FileDtos`
                                ]
                                  ? [
                                      ...form.getFieldValue()[
                                        `${u.fileLableTypeGroupName}FileDtos`
                                      ],
                                    ]
                                  : [];

                                let uploadedFileDtos = [...olduploadedFileDtos];
                                uploadedFileDtos.push(e);

                                form.setFieldsValue({
                                  [`${u.fileLableTypeGroupName}FileDtos`]:
                                    uploadedFileDtos,
                                });

                                // form.setFieldsValue({
                                //   uploadedFileDtos: [
                                //     ...olduploadedFileDtos,
                                //     e
                                //   ]
                                //     })
                              }}
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                    <div className="reference__head">
                      <Button
                        className="btn-cancel"
                        onClick={() => setExtensionForm(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type=""
                        className="btn-update__updateref ant-btn-primary"
                        onClick={() => {
                          onSubmit();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
        {showExtensioForm ? (
          ""
        ) : (
          <div className="reference__btns">
            {showAddExtension ? (
              <Button
                type="dashed"
                className="reference__btns--add"
                onClick={() => setExtensionForm(true)}
                block
                icon={<PlusCircleOutlined />}
              >
                Add New
              </Button>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default AddExtensions;
