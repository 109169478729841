import { appBaseUrl } from "utils/config";
import { getService, postService } from "../../../commonServices";

export class StatusEventListingService {
  getStatusEventListing() {
    let url = `${appBaseUrl}/organizerEvent/todoEventStatusListing`;
    let data = getService(url);
    return data;
  }
  
  updateStatusListing(body) {
    let url = `${appBaseUrl}/organizerEvent/todoEventStatusColorUpdate`;
    let data = postService(url, body, 'put');
    return data;
  }
}


