import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  Button,
  Row,
  Col,
  Input,
  Divider,
  Select,
  Menu,
  Dropdown,
  Skeleton,
  Collapse,
  Tabs,
  Form,
  Modal,
  DatePicker,
  Checkbox,
  Switch,
  Typography,
} from "antd";
import { ContractPagination } from "./Reference/ContractPagination";
import { ProjectPagination } from "./Reference/ProjectPagination";
import { TenderPagination } from "./Reference/TenderPagination";
import { ReferenceOthersPagination } from "./Reference/OthersPagination";
import Tender from "./Reference/Tabs/TenderTabs";
import Project from "./Reference/Tabs/ProjectTabs";
import ReferenceOthers from "./Reference/Tabs/OtherTabs";
// import Header from "components/header";
import Header from "components/Header/index";
import { useDispatch, useSelector } from "react-redux";

import {
  getProjectSearchPagination,
  projectGetDetails,
} from "services/redux/Reference/Project/action";
import {
  tenderGetBasicDetails,
  getTenderBasicSearchPagination,
} from "services/redux/Reference/Tender/action";
import { getOthersSearchPagination } from "services/redux/Reference/Others/action";
import {
  tenderAddExtensions,
  tenderExtensionUpdate,
  tenderextensionGetBasicDetails,
} from "services/redux/Reference/Tender/action";

import { referenceOthersGetDetails } from "services/redux/Reference/Others/action";
import InfiniteScroll from "react-infinite-scroll-component";
import BusinessChatDetails from "./Reference/Tabs/TenderTabs/include";
import { getTodoEventsPagination } from "services/redux/organizer/todoLists/action";

//remanent
import { tenderProjectListing } from "services/remanentCalls";
import ChatDetails from "views/GlobalEmailChat";

import { LogoImage } from "utils/imageConstants";

// includes
import { withBaseLayout } from "../../layouts/Base";
import HonorRoll from "./HonorRoll";

import {
  PlusOutlined,
  StarOutlined,
  StarFilled,
  FilterOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { isEmpty, set } from "lodash";
import { current } from "@reduxjs/toolkit";
import { getMailMessageParticipantStatusListing } from "services/redux/email/messageCenterType/action";
import { getAllMessageApplicationType } from "services/redux/email/instanceMessageCenter/action";
import { getReferenceListing } from "services/redux/Reference/action";
import moment from "moment";

const defaultRoute = "/office-resource/business";

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;
const formLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
// const minimize = "assets/images/icons/minimize.png";
// const maximize = "assets/images/icons/maximize.png";
const { RangePicker } = DatePicker;

const getDataFromSessionStorage = () => {
  const data = sessionStorage.getItem("myData1");
  if (data == "null") {
    return "";
  } else {
    return data;
  }
};

const OfficeResource = ({ height, setHeight }) => {
  const [advanceTenderSearchForm] = Form.useForm();
  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );
  const [searchDataAtBusinessHeaderForm] = Form.useForm();
  const location = useLocation();
  const getReferenceCodeFromUrl =
    queryString.parse(location.search)?.code || "";
  const getReferenceIdFromUrl = queryString.parse(location.search)?.id || "";
  const [addProjectAddVisibilty, setAddProjectVisibility] = useState("hidden");
  const [addTenderDrawerVisibility, setAddTenderDrawerVisibility] =
    useState("hidden");
  const dispatch = useDispatch();
  const [tenderTotalCount, setTenderTotalCount] = useState();

  //for Function search function
  const [tenderData, setTenderData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [OthersData, setOthersData] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState();
  const [projectTotalCount, setprojectTotalCount] = useState();
  const [othersTotalCount, setOthersTotalCount] = useState();
  // const [activeKey, setActiveKey] = useState(["1"]);
  const [id, setId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageProject, setCurrentPageProject] = useState(1);
  const [currentPageOthers, setCurrentPageOthers] = useState(1);
  const [selectedTenderRowId, setSelectedTenderRowId] = useState();
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [selectedOtherRowId, setSelectedOtherRowId] = useState();
  const [searchKeyword, setSearchKeyword] = useState(getDataFromSessionStorage);
  const [addContractDrawerVisibility, setContractDrawerVisibility] =
    useState("hidden");

  const [showTabCategory, setTabCategory] = useState();
  const [showTenderTab, setTenderTab] = useState(false);
  const [showProjectTab, setProjectTab] = useState(false);
  const [showContractTab, setContractTab] = useState(false);
  const [isUpdate, setIsUpdate] = useState("details");
  const [starColor, setStarColor] = useState("#c8c8c8");
  const [showChat, setShowChat] = useState(false);

  // business chat
  const [hideInternalMessage, setHideInternalMessage] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [extensionData, setExtensionData] = useState([]);
  const [getDetails, setGetDetails] = useState({});
  const [projectListing, setProjectListing] = useState([]);

  //for tenderEmailSearch
  const [searchEmailTender, setSearchEmailTender] = useState("");
  const [emailReferenceId, setEmailReferenceId] = useState("");
  const [showAdvanceSearchModal, setShowAdvanceSearchModal] = useState(false);
  const [searchValueReference, setSearchValueReference] = useState(null);
  const [getAllTenderList, setGetAllTenderList] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [participation, setParticipation] = useState(false);
  const [nonParticipated, setNonParticipated] = useState(true);
  const [advanceSearchData, setAdvancedSearchData] = useState({
    title: "",
    refkeyword: "",
    SearchDateFrom: "",
    SearchDateTo: "",
    IsParticipated: "",
  });
  const [selectedBusinessTabPaneKey, setBusinessTabPaneKey] = useState("1");

  // ADDED
  const [filterParticipantType, setFilterParticipantType] = useState("REF");
  const [referenceListing, setReferenceListing] = useState([]);
  const [filterReference, setFilterReference] = useState([]);
  const [showResetButton, setShowResetButton] = useState(false);

  // column collapsible
  const [isLeftColumnCollapsible, setIsLeftColumnCollapsible] = useState(true);

  //bishow
  const [contextMenushow, setContextMenushow] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("myData1", searchKeyword);
  }, [searchKeyword]);

  useEffect(() => {
    setBusinessTabPaneKey("1");
  }, [selectedTenderRowId]);

  // const [starColor, setStarColor] = useState('#c8c8c8');

  useEffect(() => {
    const retrievedData = getDataFromSessionStorage();
  }, []);

  useEffect(() => {
    if (!isEmpty(getReferenceCodeFromUrl)) {
      setBusinessTabPaneKey(1);

      let target = document.querySelector(".businessSearch input");

      target.value = getReferenceCodeFromUrl;

      if (getReferenceCodeFromUrl.charAt(0) === "P") {
        setSelectedProjectId(getReferenceCodeFromUrl);
        setSearchKeyword(getReferenceCodeFromUrl);
        setInputSearchValue(getReferenceCodeFromUrl);
        setSearchEmailTender(getReferenceCodeFromUrl);
        rowEventhandlersProject(getReferenceIdFromUrl);
        // setActiveKey(2);
      } else if (
        getReferenceCodeFromUrl.charAt(0) === "T" ||
        getReferenceCodeFromUrl.charAt(0) === "F"
      ) {
        setSearchKeyword(getReferenceCodeFromUrl);
        setInputSearchValue(getReferenceCodeFromUrl);
        setSelectedTenderRowId(getReferenceCodeFromUrl);
        setSearchEmailTender(getReferenceCodeFromUrl);
        rowEventhandlersTender(getReferenceIdFromUrl);
      }
    }
  }, [getReferenceCodeFromUrl]);

  // useEffect(() => {
  //   if (!isEmpty(searchValueReference)) {
  //     const timeoutId = setTimeout(() => {
  //       dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
  //     }, 200);
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [searchValueReference]);

  useEffect(() => {
    window.location.hash = "/office-resource/business";
    searchDataAtBusinessHeaderForm.setFieldsValue({
      searchDataAtBusinessHeader: searchKeyword,
    });
  }, [searchKeyword]);

  // ADDED
  useEffect(() => {
    if (searchValueReference) {
      if (referenceListingResponse) {
        setReferenceListing(referenceListingResponse);
      }
    }
  }, [referenceListingResponse, searchValueReference]);
  useEffect(() => {
    if (!isEmpty(referenceListing) && filterParticipantType == "REF") {
      setFilterReference(referenceListing.items);
    }
  }, [referenceListing]);
  useEffect(() => {
    if (filterParticipantType === "REF") {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  // refernce combine and secondary add
  const [referenceType, setReferenceType] = useState("");
  const {
    projectGetDetailsResponse,
    projectUpdateResponse,
    projectUpdateLoading,
  } = useSelector((state) => state.project);

  const {
    tenderBasicPaginationResponse,
    tenderBasicPaginationLoading,
    tenderBasicExtensionGetDetailsResponse,
    tenderBasicGetDetailsResponse,
    tenderBasicGetDetailsLoading,
    tenderStageGetDetailsExtensionResponse,
  } = useSelector((state) => state.tender);

  const { projectPaginationResponse, projectPaginationLoading } = useSelector(
    (state) => state.project
  );

  const {
    referenceOthersPaginationResponse,
    referenceOthersPaginationLoading,
  } = useSelector((state) => state.referenceOthers);

  const { allMessageTypesResponse, allMessageTypeResponseLoading } =
    useSelector((state) => state?.instanceMessageCenter);

  const {
    referenceOthersUpdateResponse,
    referenceOthersCombineUpdateResponse,
  } = useSelector((state) => state.referenceOthers);

  useEffect(() => {
    if (
      !isEmpty(referenceOthersCombineUpdateResponse) ||
      !isEmpty(referenceOthersUpdateResponse)
    ) {
      setSearchKeyword("");
      setInputSearchValue("");
    }
  }, [referenceOthersUpdateResponse, referenceOthersCombineUpdateResponse]);

  useEffect(() => {
    dispatch(getMailMessageParticipantStatusListing());
  }, []);

  // useEffect(() => {
  // 	if (!isEmpty(tenderBasicPaginationResponse?.items)) {
  // 		let id = tenderBasicPaginationResponse.items[0].id;
  // 		rowEventhandlersTender(id);
  // 		setSelectedTenderRowId(id);
  // 	} else {
  // 		setSelectedTenderRowId('');
  // 	}
  // }, [tenderBasicPaginationResponse]);
  // useEffect(() => {
  // 	if (!isEmpty(projectPaginationResponse?.items)) {
  // 		let id = projectPaginationResponse.items[0].id;
  // 		rowEventhandlersProject(id);
  // 		setSelectedProjectId(id);
  // 		setIsUpdate('details');
  // 		setContractTab(true);
  // 		setTabCategory('project');
  // 	} else {
  // 		setSelectedProjectId('');
  // 	}
  // }, [projectPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(tenderStageGetDetailsExtensionResponse)) {
      setExtensionData(tenderStageGetDetailsExtensionResponse);
    } else {
      setExtensionData([]);
    }
  }, [tenderStageGetDetailsExtensionResponse]);

  const showAddUpdateTenderDrawer = () => {
    setAddTenderDrawerVisibility("shown");
    setIsUpdate("add");
    setSelectedTenderRowId("");
    setTenderTab(true);
    setTabCategory("tender");
    setAddProjectVisibility(false);
    setContractDrawerVisibility(false);
    setContractTab(false);
    setGetDetails();
  };

  const showAddUpdateProjectDrawer = () => {
    setAddProjectVisibility("hidden");
    setIsUpdate("add");
    setAddTenderDrawerVisibility(false);
    setTabCategory("project");
    setContractDrawerVisibility(false);
    setProjectTab(true);
    setTenderTab(false);
    setContractTab(false);
  };

  const showAddUpdateOthersDrawer = (type) => {
    setContractDrawerVisibility(true);
    setAddTenderDrawerVisibility(false);
    setAddProjectVisibility(false);
    setContractTab(true);
    setTabCategory("others");
    setProjectTab(false);
    setTenderTab(false);
    setIsUpdate("add");
    setReferenceType(type);
  };

  const history = useHistory();
  const [state, setState] = useState({
    current: defaultRoute,
    collpased: false,
  });

  const rowEventhandlersTender = (data) => {
    setEmailReferenceId(data);
    setTenderTab(true);
    setTabCategory("tender");
    setIsUpdate("details");
    dispatch(tenderGetBasicDetails(data));
    dispatch(tenderextensionGetBasicDetails(data));
    setProjectTab(false);
    setContractTab(false);
  };

  const rowEventhandlersProject = (data) => {
    setEmailReferenceId(data);
    setAddProjectVisibility("hidden");
    setTabCategory("project");
    setIsUpdate("details");
    dispatch(projectGetDetails(data));
    setSelectedProjectId(data);
    setProjectTab(true);
    setTenderTab(false);
    setContractTab(false);
  };

  const rowEventhandlersOthers = (data) => {
    setEmailReferenceId(data);
    setTenderTab(false);
    setTabCategory("others");
    setIsUpdate("details");
    dispatch(referenceOthersGetDetails(data));
    setProjectTab(false);
    setSelectedOtherRowId(data);
    setContractTab(true);
  };
  const pageSize = 30;

  // const onAddUpdatePersonClose = () => {
  // 	// setIsUpdatePerson('');
  // 	// setSearchKeyword('');
  // 	setAddUpdatePersonDrawerVisibility(false);
  // };

  // const handleClick = (e) => {
  // 	history.push(e.key);
  // };

  // const toggleCollapse = () => {
  // 	setState({
  // 		...state,
  // 		collapsed: !state.collapsed,
  // 	});
  // };

  useEffect(() => {
    fetchProjectListing();
    dispatch(getAllMessageApplicationType());
  }, []);

  //for next pagination of Business Module
  useEffect(() => {
    if (isEmpty(tenderBasicPaginationResponse?.items)) return;
    let newCurrentPage = tenderBasicPaginationResponse.items.length / pageSize;
    setCurrentPage(newCurrentPage);
  }, [tenderBasicPaginationResponse]);

  useEffect(() => {
    if (isEmpty(projectPaginationResponse?.items)) return;
    let newCurrentPage = projectPaginationResponse.items.length / pageSize;
    setCurrentPageProject(newCurrentPage);
  }, [projectPaginationResponse]);

  useEffect(() => {
    if (isEmpty(referenceOthersPaginationResponse?.items)) return;
    let newCurrentPage =
      referenceOthersPaginationResponse.items.length / pageSize;
    setCurrentPageOthers(newCurrentPage);
  }, [referenceOthersPaginationResponse]);

  useEffect(() => {
    if (showTenderTab) {
      if (!isEmpty(tenderBasicGetDetailsResponse)) {
        setGetDetails(tenderBasicGetDetailsResponse);
      }
    }

    if (showProjectTab) {
      if (!isEmpty(projectGetDetailsResponse)) {
        setGetDetails(projectGetDetailsResponse);
      }
    }
  }, [
    tenderBasicGetDetailsResponse,
    projectGetDetailsResponse,
    showProjectTab,
    showTenderTab,
  ]);

  useEffect(() => {
    if (
      tenderBasicPaginationResponse ||
      projectPaginationResponse ||
      referenceOthersPaginationResponse
    ) {
      setTenderData(tenderBasicPaginationResponse?.items);
      setTenderTotalCount(tenderBasicPaginationResponse?.totalCount);
      setProjectData(projectPaginationResponse?.items);
      setprojectTotalCount(projectPaginationResponse?.totalCount);
      setOthersData(referenceOthersPaginationResponse?.items);
      setOthersTotalCount(referenceOthersPaginationResponse?.totalCount);
    }
  }, [
    tenderBasicPaginationResponse,
    projectPaginationResponse,
    referenceOthersPaginationResponse,
  ]);

  // useEffect(() => {
  //   getData();
  //   getDataProject();
  //   getDataOthers();
  //   setAdvancedSearchData();
  // }, [searchKeyword, getAllTenderList, advanceSearchData]);

  // useEffect(() => {
  //   getData();
  //   getDataProject();
  //   getDataOthers();
  //   // setAdvancedSearchData();
  // }, [searchKeyword, getReferenceCodeFromUrl]);

  useEffect(() => {
    getData();
    getDataProject();
    getDataOthers();
  }, [searchKeyword, getAllTenderList, getReferenceCodeFromUrl]);

  //for Tender Pagination
  const searchTender = tenderData
    .filter((fav) => fav.isFavorite === true)
    .map((filteredfav) => {
      return filteredfav;
    });

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getTenderBasicSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          SearchKeyword: searchKeyword,
          ...(getAllTenderList && {
            GetAllTender: true,
          }),
          ...advanceSearchData,
        },
        loadMore
      )
    );
  };

  //For ProjectPagination
  const getDataProject = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getProjectSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: searchKeyword,
          ...(getAllTenderList && {
            GetAllProject: true,
          }),
          ...advanceSearchData,
        },
        loadMore
      )
    );
  };

  const getDataOthers = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      getOthersSearchPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: searchKeyword,
          ...advanceSearchData,
        },
        loadMore
      )
    );
  };
  const fetchProjectListing = async () => {
    const resp = await tenderProjectListing().catch((err) => {
      // message.error('Failure');
    });

    if (resp && resp.status === 200) {
      setProjectListing(resp.data);
    }
  };

  useEffect(() => {
    if (
      (searchTender?.length > 0 &&
        tenderData?.length > 0 &&
        searchProject?.length > 0 &&
        projectData?.length > 0) ||
      (searchOthers?.length > 0 && OthersData?.length > 0)
    ) {
      if (
        searchTender?.length === tenderData?.length &&
        searchProject?.length === projectData?.length &&
        searchOthers?.length === OthersData?.length
      ) {
        setStarColor("#ffd036");
      } else {
        setStarColor("#c8c8c8");
      }
    } else {
      setStarColor("#c8c8c8");
    }
  }, [searchTender, tenderData]);

  // useEffect(() => {
  // 	if (!projectPaginationResponse) return;
  // 	setProjectData(projectPaginationResponse?.items);
  // }, [projectPaginationResponse]);

  useEffect(() => {
    if (history.location.pathname === "/office-resource") {
      history.push(defaultRoute);
    }
    setState({ ...state, current: history.location.pathname });
  }, [history.location.pathname]);
  useEffect(() => {
    if (selectedProjectId) {
      dispatch(projectGetDetails(selectedProjectId));
    }
    // if (contactCompanyId) {
    // 	dispatch(contactCompanyGetDetails(contactCompanyId));
    // }
  }, [projectUpdateResponse]);

  const getTodo = (
    MaxResultCount = 100,
    SkipCount = 0,
    isComplete = 0,
    labelStatusId = "",
    CompleteStatus = "",
    orderBy = "",
    sortType = "",
    priorityStatusId = "",
    progressStatusId = "",
    assignedTo = "",
    referenceId = "",
    forPagination = false
  ) => {
    let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;

    if (labelStatusId) {
      appendUrl += `&labelStatuses=${labelStatusId}`;
    }
    if (!isEmpty(CompleteStatus)) {
      CompleteStatus.map((status) => {
        appendUrl += `&todoStatuses=${status}`;
      });
    }
    if (orderBy) {
      appendUrl += `&sorting=${orderBy}`;
    }
    if (sortType) {
      appendUrl += `&sortType=${sortType}`;
    }
    if (priorityStatusId) {
      appendUrl += `&priorityStatuses=${priorityStatusId}`;
    }
    if (progressStatusId) {
      appendUrl += `&TodoStatuses=${progressStatusId}`;
    }
    if (referenceId) {
      appendUrl += `&ReferenceIds=${referenceId}`;
    }
    dispatch(getTodoEventsPagination(appendUrl));
  };

  useEffect(() => {
    getTodo(
      100,
      0,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      tenderBasicGetDetailsResponse?.referenceId,
      false
    );
  }, [getDetails]);

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  });
  useEffect(() => {
    if (windowWidth < 961) {
      setShowChat(false);
    } else {
      setShowChat(false);
    }
  }, [windowWidth]);
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const searchProject = projectData
    ?.filter((fav) => fav.isFavorite === true)
    .map((filteredfav) => {
      return filteredfav;
    });

  const searchOthers = OthersData?.filter((fav) => fav.isFavorite === true).map(
    (filteredfav) => {
      return filteredfav;
    }
  );

  const renderComponent = (pathname) => {
    switch (pathname) {
      case defaultRoute:
        return <HonorRoll />;
      //   case '/office-resource/holiday':
      //     return <GuestEntry />;
      case "/office-resource/Project":
        return <Project />;
      default:
        return <HonorRoll />;
      // return <Event />;
    }
  };
  const renderNameOrNA = (name) => name || "0";

  const fetchMoreData = () => {
    getData(pageSize, currentPage * pageSize, true);
  };

  const fetchMoreDataProject = () => {
    getDataProject(pageSize, currentPageProject * pageSize, true);
  };

  const fetchMoreDataOthers = () => {
    getDataProject(pageSize, currentPageOthers * pageSize, true);
  };

  const tenderHeader = () => {
    return <div> Tender [{renderNameOrNA(tenderTotalCount)}]</div>;
  };

  const projectHeader = () => {
    return <div> Project [{renderNameOrNA(projectTotalCount)}]</div>;
  };

  const othersHeader = () => {
    return <div> Others [{renderNameOrNA(othersTotalCount)}]</div>;
  };

  const menu = (
    <Menu style={{ width: 144 }}>
      <Menu.Item
        className="sidebars__menu--item"
        onClick={showAddUpdateTenderDrawer}
      >
        <>
          <div style={{ display: "flex", width: 114 }}>
            <div style={{ width: "50%" }}> Tenders</div>
          </div>
        </>
      </Menu.Item>
      <Menu.Item
        className="sidebars__menu--item"
        onClick={showAddUpdateProjectDrawer}
      >
        <>
          <div style={{ display: "flex", width: 114 }}>
            <div style={{ width: "50%" }}> Projects</div>
          </div>
        </>
      </Menu.Item>
      <Menu.Item className="sidebars__menu--item">
        <>
          <div
            style={{ display: "flex", width: 114 }}
            onClick={() => showAddUpdateOthersDrawer("level3")}
          >
            <div style={{ width: "50%" }}>Other</div>
          </div>
        </>
      </Menu.Item>
      <Menu.Item className="sidebars__menu--item">
        <>
          <div
            style={{ display: "flex", width: 114 }}
            onClick={() => showAddUpdateOthersDrawer("level2")}
          >
            <div style={{ width: "50%" }}>Tags</div>
          </div>
        </>
      </Menu.Item>
    </Menu>
  );

  const handleOk = () => {
    setShowAdvanceSearchModal(false);
  };

  const handleCancel = () => {
    setShowAdvanceSearchModal(false);
  };

  const switchIcon = "assets/svg/toogle-icon.svg";
  const switchBlue = "assets/svg/toogle-icon-blue.svg";

  const onAdvancedTenderSearchFinish = (values) => {
    let advanceParameterData = {
      ...advanceSearchData,
      ...values,
      refkeyword: values.refkeyword,
      title: values.title,
      SearchDateFrom: moment(values?.searchByDate?.[0]).format("YYYY-MM-DD"),
      SearchDateTo: moment(values?.searchByDate?.[1]).format("YYYY-MM-DD"),
      IsParticipated: nonParticipated,
    };
    delete advanceSearchData.searchByDate;

    let newValues = {
      title: values.title,
      refkeyword: values.refkeyword,
      SearchDateFrom: moment(values?.searchByDate?.[0]).format("YYYY-MM-DD"),
      SearchDateTo: moment(values?.searchByDate?.[1]).format("YYYY-MM-DD"),
    };

    dispatch(getTenderBasicSearchPagination(newValues));
    setAdvancedSearchData(advanceParameterData);
    setParticipation(false);
    setShowAdvanceSearchModal(false);
    advanceTenderSearchForm.resetFields();
    setShowResetButton(true);
  };

  const animateDiv = {
    opacity: isLeftColumnCollapsible ? 1 : 0,
    transition: "opacity 0.5s ease",
  };

  return (
    // <div className="sidebars">
    // 	<div className={`sidebars__menu ${state.collapsed && 'text-align-right'}`}>
    // 		<div className="toggle__arrow" onClick={toggleCollapse}>
    // 			{state.collapsed ? <img src={maximize} /> : <img src={minimize} />}
    // 		</div>
    // 		{/* <Menu className="sidebars__menu--items" defaultSelectedKeys={[state.current]} mode="inline" inlineCollapsed={state.collapsed}>
    // 			<Menu.Item key={defaultRoute} className="sidebars__menu--item" onClick={handleClick}>
    // 				Honor Roll
    // 			</Menu.Item>
    // 			<Menu.Item key="/office-resource/Project" className="sidebars__menu--item" onClick={handleClick}>
    // 				Project
    // 			</Menu.Item>
    // 			{/* <Menu.Item
    //         key="/office-resource/holiday"
    //         className='userlist__menu__item'
    //         onClick={handleClick}>
    //         Guest Entry
    //       </Menu.Item> */}
    // 		</Menu> */}
    // 	</div>
    // 	<div className="main__body">{renderComponent(history.location.pathname)}</div>
    // </div>
    <>
      <div className="layout">
        <div className="organizer contacts">
          <div className="contactDetails__split">
            {/* <div className="contactDetails__split--pagination side-bg-header-wrapper"> */}
            <div
              className={`side-bg-header-wrapper ${
                isLeftColumnCollapsible
                  ? "contactDetails__split--pagination"
                  : "contactDetails__split--pagination-collapse"
              } `}
            >
              <Header
                name="Business"
                isLeftColumnCollapsible={isLeftColumnCollapsible}
                setIsLeftColumnCollapsible={setIsLeftColumnCollapsible}
              />

              <div style={animateDiv}>
                <div className="search-wrapper">
                  <StarFilled
                    onClick={(e) => {
                      getData();
                      getDataOthers();
                      getDataProject();
                      setSearchKeyword("");
                      setInputSearchValue("");
                    }}
                    style={{ color: starColor }}
                  />
                  {!showResetButton ? (
                    <Form form={searchDataAtBusinessHeaderForm}>
                      <Form.Item name="searchDataAtBusinessHeader">
                        <Input.Search
                          className="form__groups businessSearch"
                          // placeholder="Search"
                          placeholder={
                            showAdvanceSearchModal
                              ? "[Advanced Search Ongoing]"
                              : "Search"
                          }
                          size="medium"
                          allowClear
                          onSearch={(e) => {
                            setSearchKeyword(e);
                          }}
                          onChange={(e) => {
                            setInputSearchValue(e.target.value);
                          }}
                          value={inputSearchValue}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Form>
                  ) : (
                    <>
                      <Input.Search
                        className="form__group_email_search"
                        style={{ margin: 0 }}
                        // allowClear
                        size="medium"
                        bordered={false}
                        value="[Advance Search]"
                      />
                      <button
                        className="clear_icon_advance_search"
                        onClick={() => {
                          setAdvancedSearchData({});
                          // setAdvancedFiltersData({});
                          // getData();
                          // getData(pageSize, 0, false, "All");
                          // dispatch(getMailMessageCenterPagination(appendUrl, loadMore));
                          setShowResetButton(false);
                        }}
                      >
                        <CloseCircleOutlined className="ant_close_icon" />
                      </button>
                    </>
                  )}

                  <div
                    className="side__search__wrapper"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setGetAllTenderList(!getAllTenderList);
                    }}
                  >
                    <img
                      src={getAllTenderList ? switchBlue : switchIcon}
                      alt=""
                    />
                  </div>

                  <div
                    className="side__search__wrapper side-contact-search-filter"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowAdvanceSearchModal(true);
                    }}
                  >
                    <FilterOutlined />
                  </div>

                  <Dropdown
                    overlay={menu}
                    placement="bottomRight"
                    arrow
                    trigger={["click"]}
                  >
                    <div className="contactDetails__dropdown">
                      <Button className="contact__header__btn-primary">
                        <PlusOutlined width={10} height={10} />
                      </Button>
                    </div>
                  </Dropdown>
                </div>
                <Row
                  gutter={[8, 8]}
                  style={{ marginTop: "-12px", height: "36%" }}
                >
                  {/* `${id.id ? 'Update' : 'New'} Company` */}
                  <Col span={24}>
                    <div className="profiledetails">
                      <Collapse
                        expandIconPosition="right"
                        defaultActiveKey={["1"]}
                        accordion={true}
                        // activeKey={activeKey}
                      >
                        <Panel
                          header={tenderHeader()}
                          key="1"
                          onChange={() => {
                            setTenderTab(true);
                            setTabCategory("tender");
                            setProjectTab(false);
                          }}
                        >
                          <div
                            id="tenderScroll"
                            style={{
                              height: "calc(98.5vh - 153px)",
                              overflowY: "auto",
                            }}
                          >
                            {!isEmpty(tenderData) && (
                              <InfiniteScroll
                                dataLength={
                                  tenderBasicPaginationResponse?.items.length
                                }
                                //This is important field to render the next data
                                hasMore={
                                  tenderBasicPaginationResponse?.totalCount >
                                  tenderBasicPaginationResponse?.items.length
                                }
                                next={fetchMoreData}
                                scrollableTarget="tenderScroll"
                                endMessage={
                                  <p style={{ textAlign: "center" }}>
                                    <b>End of Tenders</b>
                                  </p>
                                }
                                loader={
                                  tenderBasicPaginationLoading ? (
                                    <Skeleton paragraph={{ rows: 2 }} active />
                                  ) : (
                                    ""
                                  )
                                }
                              >
                                <TenderPagination
                                  rowEventhandlersTender={
                                    rowEventhandlersTender
                                  }
                                  setGetDetails={setGetDetails}
                                  setSearchEmailTender={setSearchEmailTender}
                                  pageSize={pageSize}
                                  isUpdate={isUpdate}
                                  setSelectedTenderRowId={
                                    setSelectedTenderRowId
                                  }
                                  selectedTenderRowId={selectedTenderRowId}
                                  // tenderData={tenderData}
                                  tenderData={
                                    tenderBasicPaginationResponse?.items
                                  }
                                  // searchDataAtBusinessHeaderForm={
                                  //   searchDataAtBusinessHeaderForm
                                  // }
                                  // setSearchKeyword={setSearchKeyword}
                                  setBusinessTabPaneKey={setBusinessTabPaneKey}
                                  selectedBusinessTabPaneKey={
                                    selectedBusinessTabPaneKey
                                  }
                                  contextMenushow={contextMenushow}
                                  setContextMenushow={setContextMenushow}
                                />
                              </InfiniteScroll>
                            )}
                            {tenderBasicPaginationLoading &&
                              tenderBasicPaginationResponse?.items.length <=
                                0 && (
                                <Skeleton paragraph={{ rows: 2 }} active />
                              )}
                          </div>
                        </Panel>

                        <Panel
                          header={projectHeader()}
                          key="2"
                          expandIconPosition="right"
                        >
                          <div className="collapse__wrapper">
                            <div
                              id="scrollableProject"
                              style={{
                                height: "calc(98.5vh - 153px)",
                                overflowY: "auto",
                              }}
                            >
                              {!isEmpty(projectPaginationResponse?.items) && (
                                <InfiniteScroll
                                  dataLength={
                                    projectPaginationResponse?.items.length
                                  }
                                  //This is important field to render the next data
                                  next={fetchMoreDataProject}
                                  hasMore={
                                    projectPaginationResponse.totalCount >
                                    projectPaginationResponse.items.length
                                  }
                                  scrollableTarget="scrollableProject"
                                  endMessage={
                                    <p style={{ textAlign: "center" }}>
                                      <b>End of Project</b>
                                    </p>
                                  }
                                >
                                  <ProjectPagination
                                    rowEventhandlersProject={
                                      rowEventhandlersProject
                                    }
                                    projectPaginationResponse={
                                      projectPaginationResponse
                                    }
                                    setSearchEmailTender={setSearchEmailTender}
                                    isUpdate={isUpdate}
                                    selectedProjectId={selectedProjectId}
                                    setSelectedProjectId={setSelectedProjectId}
                                    setGetDetails={setGetDetails}
                                  />
                                </InfiniteScroll>
                              )}
                              {projectPaginationLoading && (
                                <Skeleton paragraph={{ rows: 2 }} active />
                              )}
                            </div>
                          </div>
                        </Panel>

                        <Panel
                          header={othersHeader()}
                          key="3"
                          expandIconPosition="right"
                        >
                          <div className="collapse__wrapper">
                            <div
                              id="scrollableOthers"
                              style={{ height: "78vh", overflowY: "auto" }}
                            >
                              {!isEmpty(
                                referenceOthersPaginationResponse?.items
                              ) && (
                                <InfiniteScroll
                                  dataLength={
                                    referenceOthersPaginationResponse.items
                                      .length
                                  }
                                  //This is important field to render the next data
                                  hasMore={true}
                                  next={fetchMoreDataOthers}
                                  scrollableTarget="scrollableOthers"
                                  endMessage={
                                    <p style={{ textAlign: "center" }}>
                                      <b>End of Others</b>
                                    </p>
                                  }
                                >
                                  <ReferenceOthersPagination
                                    rowEventhandlersOthers={
                                      rowEventhandlersOthers
                                    }
                                    referenceOthersPaginationResponse={
                                      referenceOthersPaginationResponse
                                    }
                                    isUpdate={isUpdate}
                                    selectedOtherRowId={selectedOtherRowId}
                                    setSelectedOtherRowId={
                                      setSelectedOtherRowId
                                    }
                                    setSearchEmailTender={setSearchEmailTender}
                                    setSelectedProjectId={setSelectedProjectId}
                                  />
                                </InfiniteScroll>
                              )}
                              {referenceOthersPaginationLoading && (
                                <Skeleton paragraph={{ rows: 2 }} active />
                              )}
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            {/* <div className="contactDetails__split--right"> */}
            <div
              className={`${
                isLeftColumnCollapsible
                  ? "contactDetails__split--right"
                  : "contactDetails__split--right-collapse"
              }`}
            >
              <div className="referencetabs tabs-wrapper custom-tabs-wrapper">
                {showTabCategory === "tender" ? (
                  showTenderTab ? (
                    <>
                      <Tender
                        isUpdate={isUpdate}
                        extensionData={extensionData}
                        setExtensionData={setExtensionData}
                        setIsUpdate={setIsUpdate}
                        projectListing={projectListing}
                        // setaddTenderDrawerVisibility={addTenderDrawerVisibility}
                        //addamit
                        setaddTenderDrawerVisibility={
                          setAddTenderDrawerVisibility
                        }
                        addTenderDrawerVisibility={addTenderDrawerVisibility}
                        //.......addamit_end
                        tenderId={selectedTenderRowId}
                        showChat={showChat}
                        setGetDetails={setGetDetails}
                        setShowChat={setShowChat}
                        searchEmailTender={searchEmailTender}
                        selectedTenderRowId={selectedTenderRowId}
                        getDetails={getDetails}
                        setSelectedTenderRowId={setSelectedTenderRowId}
                        tenderBasicGetDetailsLoading={
                          tenderBasicGetDetailsLoading
                        }
                        height={height}
                        // setSearchKeyword={setSearchKeyword}
                        // searchDataAtBusinessHeaderForm={
                        //   searchDataAtBusinessHeaderForm
                        // }
                        emailReferenceId={emailReferenceId}
                        setBusinessTabPaneKey={setBusinessTabPaneKey}
                        selectedBusinessTabPaneKey={selectedBusinessTabPaneKey}
                        showTenderTab={showTenderTab}
                      />
                    </>
                  ) : (
                    <div style={{ padddingLeft: 25 }}>
                      <Skeleton paragraph={{ rows: 10 }} />
                    </div>
                  )
                ) : showTabCategory === "project" ? (
                  showProjectTab ? (
                    <div>
                      <Project
                        getDetails={getDetails}
                        isUpdate={isUpdate}
                        setIsUpdate={setIsUpdate}
                        addProjectAddVisibilty={addProjectAddVisibilty}
                        selectedProjectId={selectedProjectId}
                        windowWidth={windowWidth}
                        showChat={showChat}
                        setShowChat={setShowChat}
                        searchEmailTender={searchEmailTender}
                        projectListing={projectListing}
                        setSearchKeyword={setSearchKeyword}
                        setSelectedProjectId={setSelectedProjectId}
                        emailReferenceId={emailReferenceId}
                        height={height}
                        showProjectTab={showProjectTab}
                      />
                    </div>
                  ) : (
                    <div style={{ padddingLeft: 25 }}>
                      <Skeleton paragraph={{ rows: 10 }} />
                    </div>
                  )
                ) : showTabCategory === "others" ? (
                  showContractTab ? (
                    <div>
                      <ReferenceOthers
                        searchEmailTender={searchEmailTender}
                        isUpdate={isUpdate}
                        setIsUpdate={setIsUpdate}
                        windowWidth={windowWidth}
                        showChat={showChat}
                        setShowChat={setShowChat}
                        referenceType={referenceType}
                        setReferenceType={setReferenceType}
                      />
                    </div>
                  ) : (
                    <div style={{ padddingLeft: 25 }}>
                      <Skeleton paragraph={{ rows: 10 }} />
                    </div>
                  )
                ) : (
                  <Typography.Title
                    style={{
                      textAlign: "center",
                      marginTop: "6em",
                      color: "lightgray",
                      fontSize: "2.2em",
                    }}
                  >
                    No Data Selected
                  </Typography.Title>
                )}
              </div>
              {!isEmpty(getDetails) && (
                <div className="center-left">
                  <BusinessChatDetails
                    setShowChat={setShowChat}
                    setHideInternalMessage={setHideInternalMessage}
                    hideInternalMessage={hideInternalMessage}
                    emailReferenceId={emailReferenceId}
                    getDetails={getDetails}
                    showTabCategory={showTabCategory}
                    allMessageTypesResponse={allMessageTypesResponse}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Advanced Tender Search"
        visible={showAdvanceSearchModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        wrapClassName="advance_search_email"
      >
        <Form
          {...formLayout}
          form={advanceTenderSearchForm}
          onFinish={onAdvancedTenderSearchFinish}
        >
          <Form.Item name="title" label="Title">
            <Input />
          </Form.Item>

          <Form.Item name="refkeyword" label="Reference">
            <Select
              showSearch
              placeholder="Select Reference"
              optionFilterProp="label"
              allowClear={true}
              onSearch={(value) => {
                setSearchValueReference(value);
              }}
              // onChange={(value, option) =>
              //   setSelectedReferenceType(option?.label[2])
              // }
              autoComplete="off"
            >
              {!isEmpty(referenceListingResponse?.items) &&
                referenceListingResponse?.items?.map((reference, index) => {
                  return (
                    <Option
                      value={reference.id}
                      key={index}
                      label={[
                        reference.code,
                        reference.title,
                        reference.referencType,
                      ]}
                    >
                      <div className="demo-option-label-item">
                        {}
                        <div>
                          {reference.code} {""}
                        </div>
                        <div>{reference.title}</div>
                      </div>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item name="searchByDate" label="Range">
            <RangePicker
              // onChange={(value) => {
              //   setStartDate(
              //     value ? moment(value[0]).format("YYYY-MM-DD") : ""
              //   );
              //   setEndDate(value ? moment(value[1]).format("YYYY-MM-DD") : "");
              // }}
              picker="month"
              format="YYYY-MM-DD"
            />
          </Form.Item>

          <Form.Item label="Participation" name="participation">
            <Checkbox
              onChange={(e) => {
                setParticipation(!participation);
              }}
            />
          </Form.Item>

          {participation && (
            <Form.Item>
              Non Participated{" "}
              <Switch
                onChange={(checked) => {
                  setNonParticipated(checked ? false : true);
                }}
              ></Switch>{" "}
              Participated
            </Form.Item>
          )}

          <Row
            style={{
              justifyContent: "right",
              paddingTop: "10px",
              marginTop: "20px !important",
              gap: "10px",
            }}
          >
            <Button
              type="ghost"
              shape="round"
              autoComplete="off"
              onClick={() => {
                advanceTenderSearchForm.resetFields();
                setParticipation(false);
              }}
            >
              Remove
            </Button>

            <Button
              type="ghost"
              shape="round"
              htmlType="submit"
              autoComplete="off"
            >
              Search
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

// export default withBaseLayout(OfficeResource);
export default OfficeResource;
