import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from 'services/redux/auth/auth.action';
import Storage from 'utils/storage';

const Logo = 'assets/images/logo.png';

const Login = ({ userName, checkDailyAttendenceResponseLoading }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24, margin: 1 }} spin />;
  const { loading, token } = useSelector((state) => state.auth);

  useEffect(() => {
    if ('name' in sessionStorage)
      form.setFieldsValue({
        username: Storage.getItem('name'),
      });
  }, [Storage.getItem('name')]);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    //setLoggedInState(loading);
  };

  const [form] = Form.useForm();
  const name = Storage.getItem('name');

  const onFinish = (values) => {
    dispatch(loginUser(values));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <div className="login">
      <div className="login__box">
        <div className="login__box--image">
          <img src={Logo} alt="logo" />
        </div>
        <Form
          name="basic"
          form={form}
          className="login__form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Username"
            className="form__group"
            name="username"
            rules={[
              {
                message: 'Please input your username!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            // className="form__group form__group--pass"
            className="form__group"
            name="password"
            rules={[
              {
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Button
            type="primary"
            className="ant-btn-block"
            htmlType="submit"
            onClick={handleSubmit}
            loading={loading || checkDailyAttendenceResponseLoading}
            disabled={loading || checkDailyAttendenceResponseLoading}
          >
            {loading && checkDailyAttendenceResponseLoading === undefined && (
              <span>Signing </span>
            )}
            {!loading && !checkDailyAttendenceResponseLoading && (
              <span>Log In </span>
            )}
            {!loading &&
              checkDailyAttendenceResponseLoading === (undefined || true) && (
                <span>Checking Attendence</span>
              )}
          </Button>
        </Form>
      </div>
    </div>
  );
};
export default Login;
