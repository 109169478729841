import React, { useEffect, useState } from "react";
import { PlusOutlined, StarFilled } from "@ant-design/icons";
import {
  Button,
  Radio,
  Col,
  Row,
  Input,
  Dropdown,
  Menu,
  Checkbox,
  Tabs,
  Collapse,
  Switch,
} from "antd";
import Header from "components/header";
import DraggableHome from "./DraggableHome";
import ComplainBox from "./ComplainBox";
import { useDispatch, useSelector } from "react-redux";
import {
  complaintPagination,
  complainGetAllCounter,
  errorLogData,
} from "services/redux/complaint/action";
import { getApplicationConfiguration } from "services/redux/common/applicationConfiguration/action";

import { capitalize, isEmpty, startCase, toLower } from "lodash-es";
import ComplainView from "./ComplainView";

const ComplainHomes = () => {
  const { Panel } = Collapse;
  const [starColor] = useState("#c8c8c8");
  const [userName, setUserName] = useState();
  const [SearchKeyword, setSearchKeyword] = useState();
  const [inputSearchValue, setInputSearchValue] = useState();
  const [employeeProfile, setEmployeeProfile] = useState("");
  const [showLeaveDrawer, setShowLeaveDrawer] = useState(false);
  const [complainDetail, setComplaintDetail] = useState();
  const [selectedModule, setSelectedModule] = useState("");
  const [viewRemarks, setViewRemarks] = useState();
  const [grantedPolices, setGrantedPolices] = useState({});
  const [complaintData, setComplaintData] = useState([]);
  const [countData, setCountData] = useState([]);

  const dispatch = useDispatch();
  const pageSize = 40;

  const onCloseLeaveDrawer = () => {
    setShowLeaveDrawer(false);
    setComplaintDetail(null);
    setViewRemarks(false);
  };
  const { profileInfo } = useSelector((state) => state.profile);

  const { complainGetDetailsResponse, complainGetDetailsLoading } = useSelector(
    (state) => state.complaint
  );

  const {
    complaintPaginationResponse,
    complaintPaginationResponseLoading,
    complainCounterListResponse,
    complainCounterListLoading,
  } = useSelector((state) => state.complaint);

  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );
  useEffect(() => {
    if (!isEmpty(complaintPaginationResponse?.items)) {
      setComplaintData(
        complaintPaginationResponse?.items?.filter(
          (item) => item?.statusName !== "Completed"
        )
      );
    } else {
      setComplaintData([]);
    }
  }, [complaintPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(applicationConfiguration?.auth?.grantedPolicies)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  useEffect(() => {
    if (!isEmpty(employeeProfile)) {
      setUserName(startCase(toLower(employeeProfile.employeeName)));
    }
  }, [employeeProfile]);

  useEffect(() => {
    if (
      !isEmpty(profileInfo) &&
      !isEmpty(profileInfo.employeePersonalDetailUpdateDto)
    ) {
      setEmployeeProfile(profileInfo.employeePersonalDetailUpdateDto);
    }
  }, [profileInfo]);

  useEffect(() => {
    if (!isEmpty(complainGetDetailsResponse)) {
      setComplaintDetail(complainGetDetailsResponse);
    }
  }, [complainGetDetailsResponse]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    moduleTitle,
    statusesId,
    forPagination = false
  ) => {
    let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;
    if (statusesId) {
      appendUrl += `&ComplainStatuses=${statusesId}`;
    }
    if (moduleTitle) {
      appendUrl += `&SearchTitleModule=${moduleTitle}`;
    }
    if (SearchKeyword) {
      appendUrl += `&SearchKeyword=${SearchKeyword}`;
    }
    dispatch(complaintPagination(appendUrl, forPagination));
  };
  const getComplainData = (MaxResultCount = pageSize, SkipCount = 0) => {
    let appendUrl = `maxResultCount=${MaxResultCount}&skipCount=${SkipCount}`;
    dispatch(complaintPagination(appendUrl));
  };

  useEffect(() => {
    getData();
  }, [SearchKeyword]);

  const getErrorLogData = (MaxResultCount = 50, SkipCount = 0) => {
    let appendUrl = `SkipCount=${SkipCount}&MaxResultCount=${MaxResultCount}`;
    dispatch(errorLogData(appendUrl));
  };

  useEffect(() => {
    getData();
    dispatch(getApplicationConfiguration());
    dispatch(complainGetAllCounter());
  }, []);

  const onChangeCompleted = (checked) => {
    if (checked) {
      // setComplaintData((prevData) =>
      //   prevData.filter((data) => data.statusName == "Completed")
      // );
      setComplaintData(
        complaintPaginationResponse?.items?.filter(
          (item) => item?.statusName == "Completed"
        )
      );
    } else {
      setComplaintData(
        complaintPaginationResponse?.items?.filter(
          (item) => item?.statusName !== "Completed"
        )
      );
    }
  };
  useEffect(() => {
    if (!isEmpty(complainCounterListResponse)) {
      setCountData(complainCounterListResponse);
    }
  }, [complainCounterListResponse]);

  return (
    <div className="sidebars">
      <div className={`sidebars__menu organizer-sidebar`}>
        <div></div>
        <div className="layout">
          <div className="organizer contacts">
            <Header name="Complain" />
            <div className="search-wrapper">
              {/* <StarFilled
                // onClick={(e) => {
                //   getData();
                //   getDataOthers();
                //   getDataProject();
                //   setSearchKeyword("");
                //   setInputSearchValue("");
                // }}
                style={{ color: starColor }}
                height="10"
                width="10"
              /> */}
              <Input.Search
                className="form__group"
                style={{ margin: 0 }}
                placeholder="Search"
                size="medium"
                allowClear
                onSearch={(e) => {
                  setSearchKeyword(e);
                }}
                onPressEnter={(e) => {
                  setInputSearchValue(e.target.value);
                }}
                value={inputSearchValue}
                // onClick={() => {
                // 	setshowAll(true);
                // }}
                // onClcik={(e) => setSearchKeyword(e.target.value)}
                // value={SearchKeyword}
                // onClick={showModal}
                autoComplete="off"
              />

              <Button className="contact__header__btn-primary task-plus-btn">
                <PlusOutlined
                  onClick={() => {
                    setShowLeaveDrawer(true);
                  }}
                />
              </Button>
            </div>
          </div>
        </div>

        <Row gutter={[8, 8]} style={{ marginTop: "-12px", height: "36%" }}>
          <Col span={24}>
            <div className="profiledetails">
              <Collapse
                expandIconPosition="right"
                defaultActiveKey={["1"]}
                accordion={true}
              >
                <Panel className="task-panel" header="Complain List" key="1">
                  <Radio.Group
                    className="panel-nav-wrapper"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "calc(98.5vh - 183px)",
                      overflowY: "auto",
                    }}
                    value={selectedModule}
                    onChange={(e) => {
                      setSelectedModule(e.target.value);
                    }}
                    // defaultValue=""
                  >
                    <Radio.Button
                      value={""}
                      onClick={() => {
                        getData();
                      }}
                    >
                      All Complains (
                      {countData?.allComplin?.total -
                        countData?.allComplin?.complected}
                      )
                    </Radio.Button>
                    <Radio.Button value="MailBox">
                      MailBox(
                      {countData?.mailBox?.total -
                        countData?.mailBox?.complected}
                      )
                    </Radio.Button>
                    <Radio.Button value="Calendar">
                      Calendar(
                      {countData?.calendar?.total -
                        countData?.calendar?.complected}
                      )
                    </Radio.Button>
                    <Radio.Button value="Contact">
                      Contact(
                      {countData?.contact?.total -
                        countData?.contact?.complected}
                      )
                    </Radio.Button>
                    <Radio.Button value="Business">
                      Business(
                      {countData?.business?.total -
                        countData?.business?.complected}
                      )
                    </Radio.Button>
                    <Radio.Button value="Task">
                      Task(
                      {countData?.task?.total - countData?.task?.complected})
                    </Radio.Button>
                    <Radio.Button value="Document">
                      Document(
                      {countData?.document?.total -
                        countData?.document?.complected}
                      )
                    </Radio.Button>
                    <Radio.Button value="Approvals">
                      Approvals(
                      {countData?.approvals?.total -
                        countData?.approvals?.complected}
                      )
                    </Radio.Button>
                    <Radio.Button value="Admin">
                      Admin(
                      {countData?.admin?.total - countData?.admin?.complected})
                    </Radio.Button>
                    <Radio.Button value="hr">
                      HR({countData?.hr?.total - countData?.hr?.complected})
                    </Radio.Button>
                  </Radio.Group>
                </Panel>
              </Collapse>
              {!isEmpty(grantedPolices) &&
                grantedPolices["AbpIdentity.Roles"] && (
                  <Collapse
                    expandIconPosition="right"
                    defaultActiveKey={["2"]}
                    // accordion={true}
                  >
                    <Panel className="task-panel" header="Log" key="2">
                      <Radio.Group
                        className="panel-nav-wrapper"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "calc(98.5vh - 183px)",
                          overflowY: "auto",
                        }}
                        onChange={(e) => {
                          setSelectedModule(e.target.value);
                        }}
                      >
                        <Radio.Button
                          value="ErrorLog"
                          onClick={() => {
                            getErrorLogData();
                            setSelectedModule("ErrorLog");
                          }}
                        >
                          Error Log
                        </Radio.Button>
                      </Radio.Group>
                    </Panel>
                  </Collapse>
                )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="main__body" style={{ width: "calc(100% - 299px)" }}>
        <div className="organizer__list todo__list">
          <div style={{ width: "180px", marginLeft: "auto" }}>
            <Switch onChange={onChangeCompleted} />
            <span style={{ marginRight: "5px" }}>show completed</span>
          </div>
        </div>
        <ComplainView
          setShowLeaveDrawer={setShowLeaveDrawer}
          getData={getData}
          pageSize={pageSize}
          selectedModule={selectedModule}
          setViewRemarks={setViewRemarks}
          complaintData={complaintData}
          SearchKeyword={SearchKeyword}
        />
      </div>
      <ComplainBox
        userName={userName}
        drawerVisibility={showLeaveDrawer}
        selectedModule={selectedModule}
        onCloseLeaveDrawer={onCloseLeaveDrawer}
        complainDetail={complainDetail}
        setViewRemarks={setViewRemarks}
        viewRemarks={viewRemarks}
        grantedPolices={grantedPolices}
      />
    </div>
  );
};

export default ComplainHomes;
