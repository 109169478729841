import { SOURCE_TYPES } from "../../types";

const INITIAL_STATE = {
    sourceAddResponse: null,
    sourceAddResponseLoading: false,

    sourceUpdateResponse: null,
    sourceUpdateResponseLoading: false,
};

export default function sourceReducer(state = INITIAL_STATE, action) {
    switch (action.type){
        case SOURCE_TYPES.SOURCE_ADD_INIT:
            return {
                ...state,
                sourceAddResponse:null,
                sourceAddResponseLoading:true,
            };
        case SOURCE_TYPES.SOURCE_ADD_SUCCESS:
            return {
                ...state,
                sourceAddResponse: action.payload,
                sourceAddResponseLoading: false,
            };
        case SOURCE_TYPES.SOURCE_ADD_FINISH:
            return {
                sourceAddResponse:null,
                sourceAddResponseLoading:false,
            };
        case SOURCE_TYPES.SOURCE_PAGINATION_INIT:
            return {
                sourcePaginationResponse: null,
                sourcePaginationLoading: true,
            };
        case SOURCE_TYPES.SOURCE_PAGINATION_SUCCESS:
            return {
                ...state,
                sourcePaginationResponse: action.payload,
                sourcePaginationLoading: false,
            };
        case SOURCE_TYPES.SOURCE_PAGINATION_FINISH:
            return {
                sourcePaginationResponse: null,
                sourcePaginationLoading:false,
            };
            default:
                return {
                    ...state,
                };
    };
};