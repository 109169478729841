import { useEffect, useState } from "react";
import BudgetModal1 from "./Modal/BudgetModal";
import IncomeModal from "./Modal/IncomeModal";
import BudgetFront from "./Financial/BudgetViews/BudgetFront";
import BudgetBack from "./Financial/BudgetViews/BudgetBack";
import getColumns from "./Financial/BudgetViews/Column";

const BankStatus = ({ dashboardBudgetIncomeExpenseResponse, type }) => {
  const [widthh, setWidthh] = useState(window.innerWidth);
  const [rotate, setRotate] = useState(0);
  const [modalVisible, setModalVisible] = useState({
    income: false,
    expense: false,
    budget: false,
  });

  const [modalData, setModalData] = useState({
    dTypeIncome: 0,
    query: "",
    colType: "",
    colTitle: "",
  });

  useEffect(() => {
    const handleResize = () => setWidthh(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const modalShow = (query, type, colName, title) => {
    let dType = 0;
    let modalVisibleState = {
      income: false,
      expense: false,
      budget: false,
    };

    if (
      type === "fee income" ||
      type === "other income" ||
      type === "total income"
    ) {
      modalVisibleState.income = true;
      if (type === "fee income") {
        dType = 1;
      } else if (type === "other income") {
        dType = 2;
      }
    } else if (type === "expense") {
      modalVisibleState.expense = true;
    } else if (type === "budget") {
      modalVisibleState.budget = true;
    }

    setModalVisible(modalVisibleState);
    setModalData({
      query,
      dTypeIncome: dType,
      colType: colName,
      colTitle: title,
    });
  };

  const columns = getColumns({
    widthh,
    modalShow,
  });

  const calculateTotal = (data) => {
    const totalIncome = data?.reduce((total, item) => total + item.income, 0);

    const totalIncomeOther = data?.reduce(
      (total, item) => total + item.incomeOther,
      0
    );
    const totalExpense = data?.reduce((total, item) => total + item.expense, 0);
    const totalBudget = data?.reduce((total, item) => total + item.budget, 0);
    const totalIncomeBudget = data?.reduce(
      (total, item) => total + item.incomeBudget,
      0
    );

    const totalRow = {
      key: "total",
      companyName: "Total",
      income: totalIncome,
      incomeOther: totalIncomeOther,
      expense: totalExpense,
      budget: totalBudget,
      incomeBudget: totalIncomeBudget,
    };

    // console.log("totalROW: ", totalRow)

    return [...(data || []), totalRow];
  };

  return (
    <div className="flip-card">
      <div
        className="flip-card-inner"
        style={{ transform: `rotateY(${rotate * 180}deg)` }}
      >
        <BudgetFront
          rotate={rotate}
          setRotate={setRotate}
          columns={columns}
          dataSource={calculateTotal(
            dashboardBudgetIncomeExpenseResponse?.rentIncluded
          )}
        />

        <BudgetBack
          rotate={rotate}
          setRotate={setRotate}
          columns={columns}
          dataSource1={calculateTotal(
            dashboardBudgetIncomeExpenseResponse?.rentNotIncluded
          )}
        />
      </div>

      {(modalVisible.budget || modalVisible.expense) && (
        <BudgetModal1
          modalVisible={modalVisible.budget || modalVisible.expense}
          setModalVisible={setModalVisible}
          modalData={modalData}
          type="bank"
        />
      )}

      {modalVisible?.income && (
        <IncomeModal
          modalVisible={modalVisible.income}
          setModalVisible={setModalVisible}
          modalData={modalData}
          type="bank"
        />
      )}
    </div>
  );
};

export default BankStatus;
