import { ADMIN_TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { FiscalYearService } from "./api";
import { message } from "antd";

const fiscalYearService = new FiscalYearService();

export const getFiscalYearPagination = (query) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.FISCAL_YEAR_PAGINATION));
    const response = await fiscalYearService.getFiscalYearPagination(query);
    dispatch(finish(ADMIN_TYPES.FISCAL_YEAR_PAGINATION));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.FISCAL_YEAR_PAGINATION, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const fiscalYearAddUpdate = (body, status) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE));
    const response = await fiscalYearService.fiscalYearAddUpdate(body);
    dispatch(finish(ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE, response.data));
      dispatch(getFiscalYearPagination(`maxResultCount=10&skipCount=0`));
      message.success(`Fiscal Year ${status} Successfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getFiscalYearListingWithStatus = () => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.FISCAL_YEAR_LISTING_WITH_STATUS));
    const response = await fiscalYearService.getFiscalYearListingWithStatus();
    dispatch(finish(ADMIN_TYPES.FISCAL_YEAR_LISTING_WITH_STATUS));
    if (response.isSuccess) {
      dispatch(
        success(ADMIN_TYPES.FISCAL_YEAR_LISTING_WITH_STATUS, response.data)
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getFinancialDashboardListing = (query) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.DASHBOARD_FINANCIAL_INFO));
    const response = await fiscalYearService.getFinancialInfo(query);
    dispatch(finish(ADMIN_TYPES.DASHBOARD_FINANCIAL_INFO));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.DASHBOARD_FINANCIAL_INFO, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getBudgetIncomeExpenseDashboardAnother = () => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.BUDGET_INCOME_EXPENSE_INFO));
    try {
      const response = await fiscalYearService.getBudgetDataDashBoard();
      if (response.isSuccess) {
        dispatch(
          success(ADMIN_TYPES.BUDGET_INCOME_EXPENSE_INFO, response.data)
        );
      } else {
        dispatch(error(response.errorMessage));
      }
    } catch (eror) {
      dispatch(error(error.message));
    } finally {
      dispatch(finish(ADMIN_TYPES.BUDGET_INCOME_EXPENSE_INFO));
    }
  };
};

export const getAllUsersListing = () => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.GET_ALL_USERS));
    const response = await fiscalYearService.getAllUsersList();
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.GET_ALL_USERS, response.data));
      // dispatch(finish(ADMIN_TYPES.GET_ALL_USERS));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getdashboardBudgetExpenseResponse = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.ACCOUNT_BUDGET_EXPENSE));
    const response = await fiscalYearService.getBudgetData(body);
    dispatch(finish(ADMIN_TYPES.ACCOUNT_BUDGET_EXPENSE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.ACCOUNT_BUDGET_EXPENSE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getdashboardIncomeResponse = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.ACCOUNT_INCOME));
    const response = await fiscalYearService.getIncomeData(body);
    dispatch(finish(ADMIN_TYPES.ACCOUNT_INCOME));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.ACCOUNT_INCOME, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getdashboardLedgerResponse = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.ACCOUNT_LEDGER));
    const response = await fiscalYearService.getLedgerData(body);
    dispatch(finish(ADMIN_TYPES.ACCOUNT_LEDGER));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.ACCOUNT_LEDGER, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getdashboardCashFlowResponse = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.ACCOUNT_CASHFLOW));
    const response = await fiscalYearService.getCashFlowData(body);
    dispatch(finish(ADMIN_TYPES.ACCOUNT_CASHFLOW));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.ACCOUNT_CASHFLOW, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForAddUpdateFinancialInformation = (body) => {
  console.log("bodyNCFC", body);
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE, body));
    const response =
      await fiscalYearService.apiForAddUpdateFinancialInformation(body);
    if (response.isSuccess) {
      message.success("Updated");
      dispatch(success(ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE, response.data));
      dispatch(finish(ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
      dispatch(finish(ADMIN_TYPES.FISCAL_YEAR_ADD_UPDATE));
    }
  };
};

export const DashboardFeeUpdate = (body, type) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.DASHBOARD_FEE_UPDATE));
    const response = await fiscalYearService.updateDashboardFee(body);
    dispatch(finish(ADMIN_TYPES.DASHBOARD_FEE_UPDATE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.DASHBOARD_FEE_UPDATE, response.data));
      message.success(`Dashboard fee updated sucessfully`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const getFeeCategoryData = (category) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.FEE_CATEGORY_LISTING));
    // const response = await fiscalYearService.getFeeSubcategorySub(category);
    // dispatch(finish(ADMIN_TYPES.FEE_CATEGORY_LISTING));
    // if (response.isSuccess) {
    //   dispatch(success(ADMIN_TYPES.FEE_CATEGORY_LISTING, response.data));
    // } else if (!response.isSuccess) {
    //   dispatch(error(response.errorMessage));
    // }
    const response = await fiscalYearService.getFeeSubcategorySub(category);
    try {
      if (response.isSuccess) {
        dispatch(success(ADMIN_TYPES.FEE_CATEGORY_LISTING, response.data));
      } else {
        dispatch(error(response.errorMessage));
      }
    } catch (error) {
      dispatch(error(error.message));
    } finally {
      dispatch(finish(ADMIN_TYPES.FEE_CATEGORY_LISTING));
    }
  };
};

// action for geting updated roles in hr module
export const getUserReportingManagerUserListing = (value) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.GET_ALL_ROLES_USERS));
    const response = await fiscalYearService.getUserReportingManagerUserList(
      value
    );
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.GET_ALL_ROLES_USERS, response.data));
      // dispatch(finish(ADMIN_TYPES.GET_ALL_ROLES_USERS));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

// update all roles
export const actionForuserRoleAddUpdate = (body, id) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.UPDATE_ALL_ROLLS_USERS));
    const response = await fiscalYearService.apiForuserRoleAddUpdate(body, id);
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.UPDATE_ALL_ROLLS_USERS, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
