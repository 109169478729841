import React, { useState } from "react";
import {
  Form,
  Input,
  Layout,
  Button,
  Checkbox,
  DatePicker,
  Select,
  Row,
  Col,
  Divider,
  Drawer,
  Radio,
  Steps,
  message,
  InputNumber,
  Typography,
} from "antd";
import { ExportOutlined } from "@ant-design/icons";
import moment from "moment";

const TadaDetailsSettlementForm = ({ details }) => {
  const [radioValue, setRadioValue] = useState();
  return (
    <>
      <Layout.Content style={{ paddingLeft: 10, paddingRight: 10 }}>
        <Form.Item label="Applicant">
          <Input
            readOnly
            autoComplete="off"
            size="small"
            color="black"
            value={
              details?.tadaApplicationParticipantStatuses?.fromList[0]
                ?.participantName
            }
            suffix={<ExportOutlined />}
          />
        </Form.Item>
        {/* <Layout.Content style={{ border: 1, borderColor: "black" }}>
          <Typography>
            {
              details?.tadaApplicationParticipantStatuses?.fromList[0]
                ?.participantName
            }
          </Typography>
        </Layout.Content> */}
        <Row>
          <Col span={8}>
            <Form.Item label="Start Date (Actual)" style={{ display: "block" }}>
              <Input
                autoComplete="off"
                placeholder="Start Date"
                readOnly
                value={moment(details?.actualStartDateEn).format("YYYY-MM-DD")}
              />
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={8}>
            <Form.Item label="End Date (Actual)" style={{ display: "block" }}>
              <Input
                autoComplete="off"
                placeholder="End Date"
                readOnly
                value={moment(details?.actualEndDateEn).format("YYYY-MM-DD")}
              />
            </Form.Item>
          </Col>
          <Col span={2} />
          <Col span={4}>
            <Form.Item
              label="Days (Actual)"
              style={{ display: "block", width: "100%" }}
            >
              <InputNumber
                value={details?.actualVisitDays}
                readOnly
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="radio-group" label="Type of visit">
          <Radio.Group value={details?.isDomesticOrInternational}>
            {details?.isDomesticOrInternational === 0 ? (
              <>
                <Radio checked>Domestic</Radio>
              </>
            ) : (
              <>
                <Radio checked>International</Radio>
              </>
            )}
          </Radio.Group>
        </Form.Item>

        <Row style={{ justifyContent: "space-between", paddingTop: 5 }}>
          <Col span={12}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="Visit Category"
                readOnly
                value={details?.tadaDestinationTypeDto?.typeName}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="City/Country Name"
                readOnly
                value={details?.placeOfVisit}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Purpose of visit">
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 6 }}
            autoComplete="off"
            readOnly
            placeholder="Mention purpose of visit here..."
            value={details?.purposeOfVisit}
          />
        </Form.Item>

        <Form.Item label="Transport Means">
          <Input
            autoComplete="off"
            readOnly
            placeholder="for e.g Bus, Air, Train and so on."
            value={details?.transportationMeans}
          />
        </Form.Item>

        <Typography style={{ marginBottom: 4 }}>Actual Amount</Typography>
      </Layout.Content>
      <Layout.Content
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 5,
        }}
      >
        <Row>
          <Col span={8}>
            <Typography style={{ marginBottom: 4 }}>Category</Typography>
          </Col>
          <Col span={8}>
            <Typography style={{ marginBottom: 4, textAlign: "end" }}>
              Per Day
            </Typography>
          </Col>
          <Col span={8}>
            <Typography style={{ marginBottom: 4, textAlign: "end" }}>
              Total
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Typography style={{ marginBottom: 4 }}>Lodging</Typography>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                readOnly
                value={details?.lodgingRate}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                readOnly
                value={details?.lodgingAdvanceTotal}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Typography style={{ marginBottom: 4 }}>Fooding</Typography>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                readOnly
                value={details?.foodingRate}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                readOnly
                value={details?.foodingAdvanceTotal}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
          <Col span={8}>
            <Typography style={{ marginBottom: 4 }}>Misc. Expenses</Typography>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                readOnly
                value={details?.miscellaneousAdvanceTotal}
              />
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
          <Col span={8}>
            <Typography style={{ marginBottom: 4 }}>
              Total Actual Amount
            </Typography>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                readOnly
                value={
                  details?.foodingAdvanceTotal + details?.lodgingAdvanceTotal
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Layout.Content>
      <Layout.Content
        style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}
      >
        <Typography style={{ marginBottom: 4 }}>Expense Summary</Typography>
      </Layout.Content>
      <Layout.Content
        style={{
          backgroundColor: "#FAFAFA",
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 5,
        }}
      >
        <Row>
          <Col span={8}>
            <Typography style={{ marginBottom: 4 }}>Actual Expense</Typography>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                value={details?.totalActualAmount}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Typography style={{ marginBottom: 4 }}>
              Miscellaneous Expense
            </Typography>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                value={details?.miscellaneousActualTotal}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Typography style={{ marginBottom: 4 }}>Advance Taken</Typography>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                readOnly
                value={details?.totalAdvanceApprovedAmount}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Typography style={{ marginBottom: 4 }}>Balance</Typography>
          </Col>
          <Col span={16}>
            <Form.Item>
              <Input
                autoComplete="off"
                placeholder="0"
                readOnly
                value={details?.settlementBalanceAmount}
              />
            </Form.Item>
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

export default TadaDetailsSettlementForm;
