import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Button, Form, Input, Select, message } from "antd";
import ImageUploaderMultiple from "components/shared/FileUploaderApproval";
import { dynamicFileLabelTypeUploaderDetailDtos } from "services/remanentCalls";
import { useDispatch, useSelector } from "react-redux";
import { getSisterCompaniesListings } from "services/redux/admin/sisterCompanyListing/action";
import { addLoanApplication } from "services/redux/requistions/loanApplication/action";
import { updateLoanApplication } from "../../../../services/redux/requistions/loanApplication/action";

const { TextArea } = Input;
const { Option } = Select;

const ApplicantForm = ({ onCloseLoanDrawer, selectedLoanApp }) => {
  const dispatch = useDispatch();
  const { employeeSisterCompanyListing } = useSelector(
    (state) => state.employeeSisterCompanyState
  );
  const {
    addLoanApplicationResp,
    addLoanApplicationRespLoading,
    updateLoanApplicationResp,
  } = useSelector((state) => state.loanApplication);
  const { sisterCompanyListing } = useSelector((state) => state.sisterCompany);

  const [form] = Form.useForm();
  const [uploaders, setUploaders] = useState();
  useEffect(() => {
    fetchDynamicFileLabelTypeUploaderDetailDtos();
  }, []);

  const fetchDynamicFileLabelTypeUploaderDetailDtos = async () => {
    const resp = await dynamicFileLabelTypeUploaderDetailDtos("Leave").catch(
      (err) => {
        message.error("Failure");
      }
    );
    if (resp && resp.status === 200) {
      let uploaders = resp.data.filter(
        (respData) => respData.code === "LEAVEATTACHMENT"
      );
      setUploaders(uploaders);
    }
  };

  useEffect(() => {
    dispatch(getSisterCompaniesListings());
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedLoanApp)) {
      form.setFieldsValue({
        ...selectedLoanApp,
      });
    } else {
      form.resetFields();
    }
  }, [selectedLoanApp]);

  useEffect(() => {
    if (
      !isEmpty(addLoanApplicationResp) ||
      !isEmpty(updateLoanApplicationResp)
    ) {
      form.resetFields();
      onCloseLoanDrawer();
    }
  }, [addLoanApplicationResp, updateLoanApplicationResp]);

  const onSubmitApplicationLeave = (values) => {
    let body = {
      ...values,
      requestedAmount: parseInt(values.requestedAmount),
    };

    if (isEmpty(selectedLoanApp)) {
      dispatch(addLoanApplication(body));
    } else {
      dispatch(updateLoanApplication(body, selectedLoanApp.id));
    }
  };

  return (
    <div>
      <Form
        className="drawer-form-wrapper"
        form={form}
        name="applicantLeave"
        onFinish={onSubmitApplicationLeave}
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              label="Sister Company Name"
              name="sisterCompanyId"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Select placeholder="Select Company" autoComplete="off">
                {!isEmpty(sisterCompanyListing) &&
                  sisterCompanyListing?.map((company, index) => {
                    return (
                      <Option value={company.id} key={index}>
                        {company.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              label="Amount Requested"
              name="requestedAmount"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <Input type="number" autoComplete="off" />
            </Form.Item>
          </div>
          <div className="drawer-form-group upload_file_loan">
            {uploaders?.map((u, i) => (
              <Form.Item
                className="form__group"
                name={["uploadedFileDtos"]}
                key={u.name}
              >
                <ImageUploaderMultiple
                  name={u.name}
                  isMultipleFileAllowed={true}
                  id={u.id}
                  uploadedFileDtos={form.getFieldValue()}
                  formName="uploadedFileDtos"
                  form={form}
                  callback={(e) => {
                    let olduploadedFileDtos = form.getFieldValue()
                      .uploadedFileDtos
                      ? [...form.getFieldValue().uploadedFileDtos]
                      : [];

                    let uploadedFileDtos = [...olduploadedFileDtos];
                    uploadedFileDtos.push(e);
                    // uploadedFileDtos[i] = e;

                    form.setFieldsValue({
                      uploadedFileDtos,
                    });
                  }}
                />
              </Form.Item>
            ))}
          </div>
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              label="Loan Reason"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Required!",
                },
              ]}
            >
              <TextArea autoComplete="off" rows={15} />
            </Form.Item>
          </div>
        </div>
        <div className="model-footer">
          <Button
            onClick={() => {
              onCloseLoanDrawer();
              form.resetFields();
            }}
          >
            <span>Cancel</span>
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={addLoanApplicationRespLoading}
          >
            Apply
          </Button>
        </div>
        {/* <h3>From Account</h3>
                <Form.Item className='form__group' label='Loan Amount Outstanding Against the Applicant' name="loanRepaymentAmountPerMonth">
                    <Input />
                </Form.Item>
                <Form.Item className='form__group' label='Provident Fund Deposits till Date' name="pfDepositTillDate">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Forward to Manager
                    </Button>
                </Form.Item>

                <h3>Loan Approval</h3>
                <Form.Item className='form__group' label='Loan Amount Outstanding Against the Applicant' name="loanRepaymentAmountPerMonth">
                    <Input />
                </Form.Item>
                <Form.Item className='form__group' label='Provident Fund Deposits till Date' name="pfDepositTillDate">
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Forward to Manager
                    </Button>
                </Form.Item> */}
      </Form>
    </div>
  );
};

export default ApplicantForm;
