import React, { useState } from "react";
import { Form, Modal, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import FinanceReportModal from "./FinanceReportModal";
import { useDispatch, useSelector } from "react-redux";
import { getFeeCategoryData } from "services/redux/admin/FiscalYear/action";
import { showNumber } from "utils/showNumber";
import { WechatOutlined } from "@ant-design/icons";
import InternalMCompose from "components/internalMNew";
import { handleSpecialDate } from "utils/tooltipSepecialDate";

const Budget = ({ dashboardFinancialResponse }) => {
  const dispatch = useDispatch();

  const { feeCategoryListResponse, feeCategoryListLoading } = useSelector(
    (state) => state.fiscalYearState
  );

  const [modalShow, setModalShow] = useState(false);
  const [typeFee, setTypeFee] = useState();
  const [form] = Form.useForm();

  const [internalVisible, setInternalVisible] = useState(false);

  const showModalFunction = (type) => {
    setTypeFee(type);
    if (type === "Fee Due") {
      // dispatch(getFeeCategoryData("FeeDueSub"));
      dispatch(getFeeCategoryData("feedue"));
    } else if (type === "Fee Remaining") {
      // dispatch(getFeeCategoryData("FeeRemainingSub"));
      dispatch(getFeeCategoryData("feeremaining"));
    }
  };

  const columns = [
    {
      title: (
        <Typography.Paragraph
          style={{ textAlign: "left", width: "100%", fontSize: 15 }}
        >
          Title
        </Typography.Paragraph>
      ),
      dataIndex: "companyAbbrevation",
      key: "companyAbbrevation",

      render: (_, record) => {
        return <Typography.Text>{record?.type}</Typography.Text>;
      },
    },
    {
      title: (
        <Typography.Paragraph style={{ textAlign: "right", fontSize: 15 }}>
          NPR Amount
        </Typography.Paragraph>
      ),
      dataIndex: (_, record) =>
        record?.financialInformationFeeStatus?.feeDue !== 0
          ? `feeDue`
          : "feeRemaining",

      render: (_, record) => {
        return (
          <Tooltip
            title={
              // moment(record?.lastUpdatedDate).format("YYYY MMM DD")
              handleSpecialDate(record?.lastUpdatedDate)
            }
          >
            <Typography.Paragraph
              style={{ textAlign: "right" }}
              onDoubleClick={() => {
                setModalShow(true);
                showModalFunction(record?.type);
              }}
            >
              {showNumber(record?.amount)}
            </Typography.Paragraph>
          </Tooltip>
        );
      },
    },

    // {
    //   title: (
    //     <Typography.Paragraph style={{ textAlign: "right", fontSize: 15 }}>
    //       Total
    //     </Typography.Paragraph>
    //   ),
    //   dataIndex: "",
    //   render: (_, record) => (
    //     <Tooltip title={moment(record?.lastUpdatedDate).format("YYYY MMM DD")}>
    //       <Typography.Paragraph style={{ textAlign: "right" }}>
    //         {showNumber(record?.total)}
    //       </Typography.Paragraph>
    //     </Tooltip>
    //   ),
    // },
  ];

  const filteredDataSource = dashboardFinancialResponse?.feeStatusInformations;

  const tableDataSource = [
    {
      type: "Fee Due",
      amount: filteredDataSource
        ?.map((x) => {
          if (x.feeDue !== 0) {
            return x.feeDue;
          }
        })
        .filter((x) => x !== undefined),
      dollarAmount: filteredDataSource
        ?.map((x) => {
          if (x.feeDueUsd !== 0) {
            return x.feeDueUsd;
          }
        })
        .filter((x) => x !== undefined),
      total: filteredDataSource
        ?.map((x) => {
          if (x.feeDueTotal !== 0) {
            return x.feeDueTotal;
          }
        })
        .filter((x) => x !== undefined),
      lastUpdatedDate: filteredDataSource
        ?.map((x) => {
          if (x?.accountCategory === "FeeDue") {
            return x.lastUpdatedDate;
          }
        })
        .filter((x) => x !== undefined)
        .join(""),
    },
    {
      type: "Fee Remaining",
      amount: filteredDataSource
        ?.map((x) => {
          if (x.feeRemaining !== 0) {
            return x.feeRemaining;
          }
        })
        .filter((x) => x !== undefined),
      dollarAmount: filteredDataSource
        ?.map((x) => {
          if (x.feeRemainingUsd !== 0) {
            return x.feeRemainingUsd;
          }
        })
        .filter((x) => x !== undefined),
      total: filteredDataSource
        ?.map((x) => {
          if (x.feeRemainingTotal !== 0) {
            return x.feeRemainingTotal;
          }
        })
        .filter((x) => x !== undefined),
      lastUpdatedDate: filteredDataSource
        ?.map((x) => {
          if (x?.accountCategory === "FeeRemaining") {
            return x.lastUpdatedDate;
          }
        })
        .filter((x) => x !== undefined)
        .join(""),
    },
  ];

  return (
    <div className=" honor-wrapper" style={{ paddingRight: 0 }}>
      <div
        className=" dashboards__events dashboards__body "
        style={{ height: 380 }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">Fee Status</h4>
          <WechatOutlined
            onClick={() => {
              setInternalVisible(true);
            }}
          />
        </div>
        {tableDataSource?.length > 0 ? (
          <Table
            className="next-organizer-table"
            columns={columns}
            locale={{ emptyText: "" }}
            dataSource={tableDataSource}
            pagination={false}
          />
        ) : null}
      </div>
      {/* <FinanceReportModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        feeCategoryListResponse={feeCategoryListResponse}
        feeCategoryListLoading={feeCategoryListLoading}
        typeFee={typeFee}
      /> */}

      {modalShow && typeFee && !feeCategoryListLoading && (
        <>
          <FinanceReportModal
            modalShow={modalShow}
            setModalShow={setModalShow}
            feeCategoryListResponse={feeCategoryListResponse}
            feeCategoryListLoading={feeCategoryListLoading}
            typeFee={typeFee}
          />
        </>
      )}

      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="Fee Status"
        />
      </Modal>
    </div>
  );
};

export default Budget;
