import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BroadcastChannel } from "broadcast-channel";
import {
  Form,
  Button,
  Input,
  Select,
  message,
  Layout,
  Modal,
  AutoComplete,
  Checkbox,
} from "antd";
import { validateMessages } from "utils/misc";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  StarFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  contactCompanyDetailAdd,
  contactCompanyUpdate,
  deleteCompany,
} from "services/redux/contact/companypeople/action";

import {
  searchTagListing,
  searchTagAdd,
  scopeOfWorkListing,
  scopeofWorkAdd,
  contactLabelGroupListing,
  contactLabelListing,
  contactSourceListing,
  countryListing,
  cityListing,
  dynamicFileLabelTypeUploaderDetailDtos,
} from "services/remanentCalls";
import { getContactCompanyPagination } from "services/redux/contact/companypeople/action";

import {
  allCountryListing,
  getAllTagsList,
} from "services/redux/contact/contact.action";
import ContactImageUploader from "../../../components/shared/ContactImageUploader";
// added
import CityAddUpdateDrawer from "../../AdminSettings/CityAddUpdate/CityAddUpdateDrawer";
import { cloneDeep, groupBy, isEmpty } from "lodash";
import DynamicImageUploader from "components/shared/DynamicImageUploader";
import { useLocation } from "react-router";
import querString from "query-string";
import { contactCompanyListing } from "services/remanentCalls";

const { Option } = Select;
const { Header, Footer } = Layout;

export const AddCompanyDrawer = ({ setEditCompany, editCompany, form }) => {
  const [tagLists, setTagLists] = useState([]);
  const [companyCurrentPage, setCompanyCurrentPage] = useState(1);
  const [value, setValue] = useState("");
  const [isFav, setIsFav] = useState(false);
  const [grantedPolices, setGrantedPolices] = useState({});
  const [contactCompanyListingResponse, setContactCompanyListing] = useState(
    []
  );
  const [dropdownscope, setdropdownscope] = useState(false);
  const [dropdowntag, setdropdowntag] = useState(false);
  const [adDocs, setAdDocs] = useState([]);
  const [scopeTags, setScopeTags] = useState([]);
  const [compareTags, setCompareTags] = useState([]);
  const [contactLabelGroup, setContactLabelGroup] = useState([]);
  const [contactLabel, setContactLabel] = useState([]);
  const [contactSources, setContactSources] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [uploaders, setUploaders] = useState([]);
  const [uploadersExceptProfile, setUploadersExceptProfile] = useState([]);
  const [groupedByDocuments, setGroupedByDocuments] = useState([]);
  const [filterCitiesLists, setFilterCitiesLists] = useState([]);
  const [loading, setLoading] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [test, setTest] = useState("update");
  const [scopeSearch, setScopeSearch] = useState("");
  const [scopeLoader, setScopeLoader] = useState(false);
  const [parentCompany, setParentCompany] = useState("");
  const [childrenCompanies, setChildrenCompanies] = useState([]);
  //
  const [selectedCountryId, setSelectedCountryId] = useState();

  // added
  const [
    addUpdateCountryDrawerVisibility,
    setAddUpdateCountryDrawerVisibility,
  ] = useState(false);
  const [updateProfileDrawerVisibility, setUpdateProfileDrawerVisibility] =
    useState(false);
  const [isUpdateCountry, setIsUpdateCountry] = useState(false);
  const [cityValue, setCityValue] = useState("");
  const [showCreateCityButton, setShowCreateCityButton] = useState(false);
  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry("");
    setAddUpdateCountryDrawerVisibility(false);
  };
  const showAddUpdateCountryDrawer = (record) => {
    setUpdateProfileDrawerVisibility(false);
    if (record) {
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };
  const handleCreateCity = () => {
    // let record = {
    //   countryId: selectedCountryId,
    //   name: cityValue,
    // };
    // let record = {};
    showAddUpdateCountryDrawer();
    // showAddUpdateCountryDrawer(record);
  };
  const handleCityChange = (value) => {
    setCityValue(value);
    const isCityInList = filterCitiesLists[0]?.some(
      (item) => item.name.toLowerCase() === value.toLowerCase()
    );
    setShowCreateCityButton(!isCityInList && value !== "");
  };
  //

  const dispatch = useDispatch();
  const bc = new BroadcastChannel("test_channel");

  const {
    contactCompanyDetailAddResponse,
    contactCompanyUpdateResponse,
    contactCompanyGetDetailsResponse,
    contactCompanyPaginationResponse,
    contactCompanyUpdateLoading,
    contactCompanyDetailAddLoading,
    contactCompanyDelete,
    contactDeleteCompanyLoading,
    contactCompanyPaginationLoading,
  } = useSelector((state) => state.contactCompanyPerson);
  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.contactSelectedState
  );
  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );

  const {
    tagsListResponse,
    tagsListResponseLoading,
    cityAddResponse,
    cityAddLoading,
  } = useSelector((state) => state.contact);

  const { confirm } = Modal;
  const pageSize = 110;

  const location = useLocation();

  const id = querString.parse(location.search);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  useEffect(() => {
    if (isEmpty(value)) return;

    const timeoutId = setTimeout(
      () => fetchContactCompanyListing({ contactSearch: value }),
      800
    );
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    if (!isEmpty(applicationConfiguration?.auth?.grantedPolicies)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  // added by bishow
  useEffect(() => {
    if (!isEmpty(cityAddResponse)) {
      const newInnerArray = cityAddResponse;
      const updatedCities = [...cities, newInnerArray];
      setCities(updatedCities);
    }
  }, [cityAddResponse]);

  const getCompanyPagination = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    // Sorting,
    // SortType,
    SearchKeyword,
    ForPagination = false
  ) => {
    dispatch(
      getContactCompanyPagination(
        {
          MaxResultCount,
          SkipCount,
          // Sorting,
          // SortType,
          SearchKeyword: value,
        },
        ForPagination
      )
    );
  };

  function info() {
    let data = scopeSearch.replace(/\s+/g, " ").trim();
    data = scopeSearch.split(" ");
    // let capitalizeData = data.map((element) => {
    //   return (
    //     element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
    //   );
    // });
    // let capitalizedCompareTags = compareTags.map((element) => {
    //   return (
    //     element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
    //   );
    // });
    data = data.filter((val) => !compareTags.includes(val));
    Modal.confirm({
      title: `Are you sure if you want to add new Scope of Work "${data}"`,
      okText: "Yes",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      cancelText: "No",
      onOk() {
        let val = data.map((list) => ({
          name: list,
        }));
        addScopeofWork(val, "");
        form.setFieldsValue({
          scopeOfWork: !isEmpty(form.getFieldsValue().scopeOfWork)
            ? [...form.getFieldsValue().scopeOfWork.concat(data)]
            : data,
        });
      },
      onCancel() {},
    });
  }
  function showConfirm() {
    confirm({
      title: "Do you Want to delete this Company?",
      icon: <ExclamationCircleOutlined />,

      onOk() {
        setTest("delete");
        onSubmit("delete");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function cancel(removeIndex, remove) {
    remove(removeIndex);
    let data = childrenCompanies.filter((data, index) => index !== removeIndex);

    setChildrenCompanies(data);
  }

  const filteredOptions = (groupId) => {
    if (!groupId) return contactLabel;

    return contactLabel.filter(
      (label) => label.contactLabelGroupId === groupId
    );
  };
  const filteredOptionsCities = (fieldKey, array) => {
    if (!form.getFieldValue().contactCompanyGroupAddressDtos[fieldKey]) {
      return array;
    } else {
      const id =
        form.getFieldValue().contactCompanyGroupAddressDtos[fieldKey].country;
      return array.filter((label) => label.countryId === id);
    }
  };

  useEffect(() => {
    if (!isEmpty(contactCompanyGetDetailsResponse) && editCompany === "edit") {
      if (
        contactCompanyGetDetailsResponse?.isFavorite &&
        editCompany === "edit"
      ) {
        setIsFav(true);
      } else {
        setIsFav(false);
      }
      setChildrenCompanies(
        contactCompanyGetDetailsResponse?.childCompanyDtos?.map(
          (value, index) => {
            return {
              ...value,
              index: index,
            };
          }
        )
      );

      setParentCompany(contactCompanyGetDetailsResponse?.parentCompanyId);
      //get details of tagNames with trim, filter and map
      const tagNamestrim =
        contactCompanyGetDetailsResponse?.tagNames?.split(",");
      let filteredTagNames = tagNamestrim?.filter((data) => {
        return data;
      });

      let tagNames = filteredTagNames?.map((data) => {
        return data.replace(/\s+/g, "");
      });

      tagNames = tagNamestrim?.filter((fav) => fav);

      //get details of scopeofWork with trim, filter and map
      const scopeOfWorktrim =
        contactCompanyGetDetailsResponse?.scopeOfWork?.split(",");

      let filteredscopeOfWork = scopeOfWorktrim?.filter((data) => {
        return data;
      });

      let scopeOfWork = filteredscopeOfWork?.map((data) => {
        return data.replace(/\s+/g, "");
      });

      // const cityId = filtercities;

      const contactCompanyGroupAddressDtos = {
        [contactCompanyGetDetailsResponse?.contactCompanyGroupAddressDtos?.[0]
          ?.contactLabelGroupId]: [],
      };
      const contactCompanyGroupLabelDtos = {};

      let newFilterCities = [];
      contactCompanyGetDetailsResponse.contactCompanyGroupAddressDtos?.forEach(
        (resp, i) => {
          if (resp.countryId) {
            contactCompanyGroupAddressDtos[
              Object.keys(contactCompanyGroupAddressDtos)[0]
            ].push(resp);
            // onChange(resp.countryId, i);
            const data = cities.filter(
              (filtered) => filtered.countryId === resp.countryId
            );

            newFilterCities[i] = cloneDeep(data);
          }
        }
      );
      setFilterCitiesLists(newFilterCities);

      let documentsUploadedFiles = cloneDeep(
        contactCompanyGetDetailsResponse?.uploadedFileDtos
      );

      let groupByDocuments = groupBy(
        documentsUploadedFiles,
        "fileLableTypeName"
      );

      setGroupedByDocuments(groupByDocuments);

      // contactCompanyGetDetailsResponse.contactCompanyGroupLabelDtos.forEach((resp, i) => {
      //   contactCompanyGroupLabelDtos[contactCompanyGetDetailsResponse.contactCompanyGroupLabelDtos[i].contactLabelGroupId] = []

      //   if(resp.contactComapnyPersonLabelDtos[i].contactLabelDataValue){
      //     contactCompanyGroupLabelDtos[Object.keys(contactCompanyGroupLabelDtos)[i]].push(...resp?.contactComapnyPersonLabelDtos);
      //   }
      // })

      contactCompanyGetDetailsResponse.contactCompanyGroupLabelDtos?.forEach(
        (resp, i) => {
          if (!contactCompanyGroupLabelDtos[resp.contactLabelGroupId]) {
            contactCompanyGroupLabelDtos[resp.contactLabelGroupId] = [];
          }

          if (resp.contactLabelDataValue) {
            contactCompanyGroupLabelDtos[resp.contactLabelGroupId].push(resp);
          }
        }
      );

      if (editCompany === "edit") {
        uploadersExceptProfile.map((file) => {
          let fileName = file.name;
          fileName &&
            groupByDocuments.hasOwnProperty(fileName) &&
            form.setFieldsValue({
              [`${fileName}FileDtos`]: groupByDocuments[`${fileName}`],
            });
        });

        form.setFieldsValue({
          ...contactCompanyGetDetailsResponse,

          tagNames,
          //  ...cityId,
          ...newFilterCities,
          scopeOfWork,
          childCompanyDtos: childrenCompanies?.map((value) => {
            return {
              id: value.companyName,
            };
          }),
          parentCompanyId: contactCompanyGetDetailsResponse.parentCompanyName,
          contactCompanyGroupAddressDtos,
          contactCompanyGroupLabelDtos,

          uploadedFileDtos:
            contactCompanyGetDetailsResponse?.uploadedFileDtos.filter(
              (files) => files.fileLableTypeName === "Profile"
            ),
        });
      }
    }
  }, [
    contactCompanyGetDetailsResponse,
    uploadersExceptProfile,
    editCompany,
    cities,
  ]);

  // useEffect(() => {
  // 	if (!isUpdateCountry || !isUpdate) {
  // 		setFilterCitiesLists([]);
  // 		form.resetFields();
  // 		return;
  // 	}
  // }, [isUpdateCountry, isUpdate]);

  useEffect(() => {
    dispatch(allCountryListing());
    // dispatch(cityListing());
  }, [contactCompanyDetailAddResponse, contactCompanyUpdateResponse]);

  useEffect(() => {
    dispatch(getAllTagsList());
    fetchSearchTagListing();
    fetchContactLabelGroupListing();
    fetchContactLabelListing();
    fetchContactSourceListing();
    fetchCountryListing();
    getScopeListing();
    fetchCities();
    fetchDynamicFileLabelTypeUploaderDetailDtos();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  });
  const fetchSearchTagListing = async () => {
    const resp = await searchTagListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setTagLists(resp.data);
    }
  };

  const updateWindowWidth = () => {
    setWindowWidth(window?.innerWidth);
  };

  // const addSearchTag = async (value, option) => {
  //   if (isEmpty(option)) {
  //     const resp = await searchTagAdd({ name: value }).catch((err) => {
  //       message.error('Failed to add this tag');
  //     });
  //   }
  // };

  const addSearchTag = async (value, option) => {
    const trimmedValue = value.trim();
    if (trimmedValue === "") {
      return; // ignore empty tags
    }
    if (isEmpty(option)) {
      const resp = await searchTagAdd({ name: trimmedValue }).catch((err) => {
        message.error("Failed to add this tag");
      });
    }
  };

  const fetchContactLabelGroupListing = async (body) => {
    const resp = await contactLabelGroupListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setContactLabelGroup(resp.data);
    }
  };

  const fetchContactLabelListing = async (body) => {
    const resp = await contactLabelListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setContactLabel(resp.data);
    }
  };

  const fetchContactCompanyListing = async (body) => {
    const resp = await contactCompanyListing(body).catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      setContactCompanyListing(resp.data);
    }
  };

  const fetchScopeofWorkListing = async () => {
    const resp = await scopeOfWorkListing({ searchText: scopeSearch }).catch(
      (err) => {
        message.error("failure");
      }
    );
    if (resp && resp.status === 200) {
      setScopeTags(resp.data);
    }
  };

  const getScopeListing = async () => {
    const resp = await scopeOfWorkListing({ searchText: "" }).catch((err) => {
      message.error("failure");
    });
    if (resp && resp.status === 200) {
      setCompareTags(resp.data.map((data) => data.name));
    }
  };

  useEffect(() => {
    if (scopeSearch) {
      fetchScopeofWorkListing();
    } else {
      setScopeTags([]);
    }
  }, [scopeSearch]);

  const addScopeofWork = async (value, option) => {
    if (isEmpty(option)) {
      const resp = await scopeofWorkAdd(value).catch((err) => {
        message.error("Failed to add this tag");
      });
    }
  };

  const fetchContactSourceListing = async (body) => {
    const resp = await contactSourceListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setContactSources(resp.data);
    }
  };

  const fetchCountryListing = async (body) => {
    const resp = await countryListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setCountries(resp.data);
    }
  };
  const fetchDynamicFileLabelTypeUploaderDetailDtos = async () => {
    const resp = await dynamicFileLabelTypeUploaderDetailDtos("contact").catch(
      (err) => {
        message.error("Failure");
      }
    );

    if (resp && resp.status === 200) {
      let uploaders = resp.data.filter(
        (respData) => respData.name === "Profile"
      );
      setUploaders(uploaders);
      let newUploadersExcept = resp.data.filter(
        (respData) => respData.name !== "Profile"
      );
      setUploadersExceptProfile(newUploadersExcept);
    }
  };

  const fetchCities = async (body) => {
    // const resp = await cityListing({ CountryId }).catch((err) => {
    // 	message.error('Failure');
    // });
    const resp = await cityListing().catch((err) => {
      message.error("Failure");
    });

    // contactCompanyGroupAddressDtos: [
    //   {},
    //   {}
    // ]
    const value = form.getFieldValue(`contactCompanyGroupAddressDtos`);

    if (resp && resp.status === 200) {
      //   const fields = form.getFieldsValue()
      // const { contactCompanyGroupAddressDtos } = fields
      // Object.assign(contactCompanyGroupAddressDtos[key], { city: '' })
      // form.setFieldsValue({ contactCompanyGroupAddressDtos })
      // form.setFieldsValue({
      //   ['contactCompanyGroupAddressDtos']: [...value, value[key].city: ''],
      //   'companyCEO': 'efg'
      // })
      setCities(resp.data);
    }
  };

  const onChange = (value, index) => {
    const data = cities.filter((filtered) => filtered.countryId === value);

    let newFilterCities = cloneDeep(filterCitiesLists);

    newFilterCities[index] = cloneDeep(data);
    setFilterCitiesLists(newFilterCities);
    //
    setSelectedCountryId(value);
  };

  const onInputKeyDown = (event) => {
    if (event.key === "Enter") {
      setdropdownscope(false);
    }
  };
  const onkeyDownTagsName = (event) => {
    if (event.key === "Enter") {
      setdropdowntag(false);
    }
  };

  const childCompanies = (value, name) => {
    setChildrenCompanies((oldArray) => [
      ...oldArray,
      { id: value.value, index: name },
    ]);
  };

  const onSubmit = async (action) => {
    try {
      let values = await form.validateFields();

      const { parentCompanyId, childCompanyDtos, ...rest } = values; //deleting unwanted object  with destructuring and the spread operator
      let tagNames = values.tagNames && values.tagNames.join(",");
      let scopeOfWork = values.scopeOfWork && values.scopeOfWork.join(",");

      let contactCompanyGroupLabelDtos = [];
      let contactCompanyGroupAddressDtos = [];
      let uploadedFileDtos = [];

      for (let val in values?.contactCompanyGroupLabelDtos) {
        if (values?.contactCompanyGroupLabelDtos[val]) {
          values?.contactCompanyGroupLabelDtos[val].forEach((lab) => {
            let ele = {};
            if (lab.contactLabelDataId) {
              let changedContactLabelId = true;
              contactCompanyGetDetailsResponse.contactCompanyGroupLabelDtos.forEach(
                (resp, i) => {
                  if (resp.contactLabelDataId) {
                    if (lab.contactLabelId === resp.contactLabelId) {
                      changedContactLabelId = false;
                    }
                    // contactCompanyGroupLabelDtos[resp.contactLabelGroupId].push(resp);
                  }
                }
              );
              if (!changedContactLabelId) {
                ele = {
                  ...lab,
                  contactLabelDataValue: lab.contactLabelDataValue,
                  contactLabelGroupId: val,
                };
              } else {
                ele = {
                  contactLabelId: lab.contactLabelId,
                  contactLabelDataValue: lab.contactLabelDataValue,
                  contactLabelGroupId: val,
                };
              }
            } else {
              ele = {
                contactLabelId: lab.contactLabelId,
                contactLabelDataValue: lab.contactLabelDataValue,
                contactLabelGroupId: val,
              };
            }
            contactCompanyGroupLabelDtos.push(ele);
            // lab.contactLabelGroupId = val;
          });
          // contactCompanyGroupLabelDtos.push(...values.contactCompanyGroupLabelDtos[val]);
        }
      }

      for (let val in values.contactCompanyGroupAddressDtos) {
        if (values.contactCompanyGroupAddressDtos[val]) {
          values.contactCompanyGroupAddressDtos[val].forEach((lab) => {
            lab.contactLabelGroupId = val;
          });
          contactCompanyGroupAddressDtos.push(
            ...values.contactCompanyGroupAddressDtos[val]
          );
        }
      }

      values.uploadedFileDtos?.forEach((file) => {
        file && uploadedFileDtos.push(file);
      });

      uploadersExceptProfile.map((file) => {
        if (!isEmpty(values[`${file.name}FileDtos`])) {
          uploadedFileDtos = uploadedFileDtos.concat(
            values[`${file.name}FileDtos`]
          );
        }
      });

      if (action === "add") {
        dispatch(
          contactCompanyDetailAdd({
            ...rest,
            parentCompanyId: parentCompany,
            childCompanyDtos: childrenCompanies?.map((value) => {
              return {
                id: value.id,
              };
            }),
            contactCompanyGroupLabelDtos,
            isFavorite: isFav,
            contactCompanyGroupAddressDtos,
            tagNames,
            scopeOfWork,
            uploadedFileDtos,
          })
        );
      } else if (action === "edit") {
        dispatch(
          contactCompanyUpdate(contactCompanyGetDetailsResponse?.id, {
            contactCompanyDelete: false,
            parentCompanyId: parentCompany,
            childCompanyDtos: childrenCompanies?.map((value) => {
              return {
                id: value.id,
              };
            }),
            ...rest,
            contactCompanyGroupLabelDtos,
            contactCompanyGroupAddressDtos,
            tagNames,
            isFavorite: isFav,
            scopeOfWork,
            uploadedFileDtos,
          })
        );
      } else if (action === "delete") {
        dispatch(deleteCompany(contactCompanyGetDetailsResponse?.id));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  const onAddAddress = (add, fieldIndex, labelGroup) => {
    add();

    let values = form.getFieldsValue();
    for (let val in values.contactCompanyGroupAddressDtos) {
      if (values.contactCompanyGroupAddressDtos[val]) {
        values.contactCompanyGroupAddressDtos[val].forEach((lab, i) => {
          if (lab === undefined) {
            // const data = cities.filter((filtered) => filtered.countryId === countries[0].id);
            // let newFilterCities = cloneDeep(filterCitiesLists);
            // newFilterCities[fieldIndex] = cloneDeep(data);
            // setFilterCitiesLists(newFilterCities);

            values.contactCompanyGroupAddressDtos[val][i] = {
              contactLabelId: filteredOptions(labelGroup.id)[0].id,
              // countryId: countries[0].id,
              // cityId: newFilterCities[fieldIndex] ? newFilterCities[fieldIndex][0]?.id : '',
            };

            form.setFieldsValue({ ...values });
          }
        });
      }
    }
  };

  const onAddContactGroupLabels = (add, labelGroup) => {
    add();
    let values = form.getFieldsValue();
    for (let val in values.contactCompanyGroupLabelDtos) {
      if (values.contactCompanyGroupLabelDtos[val]) {
        values.contactCompanyGroupLabelDtos[val].forEach((lab, i) => {
          if (lab === undefined) {
            values.contactCompanyGroupLabelDtos[val][i] = {
              contactLabelId: filteredOptions(labelGroup.id)[0].id,
            };
            form.setFieldsValue({ ...values });
          }
        });
      }
    }
  };

  return (
    <>
      <div
        className="contact-person-company-wrapper"
        onClick={() => {
          setdropdownscope(false);
          setdropdowntag(false);
        }}
      >
        <Form
          className="add__user__form"
          layout="vertical"
          form={form}
          name="control-hooks"
          validateMessages={validateMessages}
        >
          <div className="row-wrapper">
            <div className="contact-person-company-form-wrapper">
              <div className="global-form-flex">
                <div className="global-form-group profile-img-wrapper">
                  {uploaders.map((u, i) => (
                    <Form.Item
                      name={[`uploadedFileDtos`]}
                      className="form__group"
                      // name={u.id}
                      rules={[{ required: false }]}
                    >
                      <ContactImageUploader
                        name={u.name}
                        isMultipleFileAllowed={u.isMultipleFileAllowed}
                        id={u.id}
                        allowedExtensions={u.allowedExtensions}
                        // fileList={fileList}
                        // setFileList={setFileList}
                        index={i}
                        form={form}
                        callback={(e) => {
                          let olduploadedFileDtos = form.getFieldValue()[
                            `uploadedFileDtos`
                          ]
                            ? [...form.getFieldValue()[`uploadedFileDtos`]]
                            : [];

                          let uploadedFileDtos = cloneDeep(olduploadedFileDtos);
                          uploadedFileDtos.unshift(e);

                          form.setFieldsValue({
                            uploadedFileDtos,
                          });

                          // form.setFieldsValue({
                          //   uploadedFileDtos: [
                          //     ...olduploadedFileDtos,
                          //     e
                          //   ]
                          //     })
                        }}
                      />
                    </Form.Item>
                  ))}
                </div>
                <div className="global-form-group star">
                  <StarFilled
                    style={{
                      color: `${isFav ? "#FBA525" : "grey"}`,
                    }}
                    onClick={() => {
                      setIsFav((prevState) => !prevState);
                    }}
                  />
                </div>
                <div className="personal-info-form-wrapper">
                  <div className="global-form-group">
                    <Form.Item
                      name="companyName"
                      rules={[
                        {
                          required: true,
                          message: "Company Name is required",
                        },
                      ]}
                    >
                      <Input
                        size="default"
                        autoFocus={true}
                        placeholder="Company"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </div>
                  <div className="global-form-group">
                    <Form.Item
                      name="companyAbbrevation"
                      rules={[{ required: false, message: "Abbr is Required" }]}
                    >
                      <Input
                        size="default"
                        placeholder="Abbrevation"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </div>
                  <div className="global-form-group">
                    <Form.Item
                      name="scopeOfWork"
                      rules={[
                        {
                          required: test === "delete" ? false : true,
                          message: "Scope of Work is Required",
                        },
                      ]}
                    >
                      <Select
                        autoComplete="off"
                        placeholder="Scope of Work"
                        size="default"
                        mode="multiple"
                        onPopupScroll={(e) => {}}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        notFoundContent={
                          !isEmpty(scopeSearch)
                            ? isEmpty(scopeTags) && `Create ${scopeSearch}`
                            : null
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === "Tab") {
                            setScopeTags([]);
                            setScopeSearch("");
                            if (isEmpty(scopeTags) && !isEmpty(scopeSearch)) {
                              info();
                            }
                          }
                        }}
                        // onInputKeyDown={(e) => {
                        //   if(e.key === "Enter"){
                        //     setScopeTags([]);
                        //     setScopeTags('')

                        //   }

                        // }}
                        onSearch={(e) => {
                          setScopeSearch(e);
                        }}
                        // onSelect={(value, option) => addScopeofWork(value, option)}
                        // open={dropdownscope}
                        listHeight={100}
                        // // onInputKeyDown={onInputKeyDown}
                        onClear={() => {
                          setScopeSearch("");
                        }}
                      >
                        {scopeTags?.map((tag) => (
                          <Option value={tag.name} key={tag.id}>
                            {tag.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="company-label-wrapper" style={{ width: "100%" }}>
                <div className="company-person-label-items">
                  {contactLabelGroup.map((labelGroup) => (
                    <div className="company-person-label-item">
                      {!labelGroup.isMultipleValuedAllowed ? (
                        <div className="company-person-label-item-wrapper">
                          <div className="company-person-label-header-wrapper">
                            <span className="company-person-label-header">
                              {labelGroup.name}
                            </span>
                          </div>
                          <div className="company-person-label-field-wrapper">
                            <Form.List
                              name={[
                                "contactCompanyGroupLabelDtos",
                                labelGroup.id,
                              ]}
                            >
                              {(fields, { add, remove }) => (
                                <div className="global-form-flex">
                                  {fields.map((field, fieldIndex) => (
                                    <React.Fragment key={field.key}>
                                      <div className="global-form-group calc mt">
                                        <div className="global-form-label">
                                          <Form.Item
                                            noStyle
                                            shouldUpdate={(
                                              prevValues,
                                              curValues
                                            ) =>
                                              prevValues.labelGroup !==
                                              curValues.labelGroup
                                            }
                                          >
                                            {() => (
                                              <Form.Item
                                                {...field}
                                                // label="Type"
                                                // className="form__group"
                                                name={[
                                                  field.name,
                                                  "contactLabelId",
                                                ]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "contactLabelId",
                                                ]}
                                              >
                                                <Select
                                                  size="default"
                                                  bordered={false}
                                                  autoComplete="off"
                                                >
                                                  {filteredOptions(
                                                    labelGroup.id
                                                  ).map((item) => (
                                                    <Option
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.name}
                                                    </Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            )}
                                          </Form.Item>
                                        </div>
                                        <div className="global-form-input">
                                          <Form.Item
                                            {...field}
                                            // label="contactLabelDataValue"
                                            name={[
                                              field.name,
                                              "contactLabelDataValue",
                                            ]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "contactLabelDataValue",
                                            ]}
                                            rules={[
                                              {
                                                required: true,
                                                message: ` ${labelGroup.name} is Required`,
                                              },
                                            ]}
                                          >
                                            <Input
                                              size="default"
                                              autoFocus={true}
                                              autoComplete="off"
                                              // type={
                                              //   labelGroup?.name == "Phone"
                                              //     ? "number"
                                              //     : ""
                                              // }
                                            />
                                          </Form.Item>
                                        </div>
                                        <div className="remove-field-wrapper">
                                          <MinusCircleOutlined
                                            style={{
                                              width: 45,
                                              marginTop: 3,
                                            }}
                                            className="contact__btn-danger"
                                            onClick={() => remove(field.name)}
                                          />
                                        </div>
                                      </div>
                                      <div className="global-form-group calc">
                                        {fields.length === fieldIndex + 1 && (
                                          <Form.Item>
                                            <Button
                                              type="dashed"
                                              className="contact__buttons"
                                              onClick={() =>
                                                onAddContactGroupLabels(
                                                  add,
                                                  labelGroup
                                                )
                                              }
                                              icon={
                                                <PlusCircleOutlined
                                                  style={{
                                                    color: "green",
                                                  }}
                                                />
                                              }
                                            >
                                              Add {labelGroup.name}
                                            </Button>
                                          </Form.Item>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}
                                  {fields.length === 0 && (
                                    <div className="global-form-group calc">
                                      <Form.Item>
                                        <Button
                                          type="dashed"
                                          className="contact__buttons"
                                          onClick={() =>
                                            onAddContactGroupLabels(
                                              add,
                                              labelGroup
                                            )
                                          }
                                          block
                                          icon={
                                            <PlusCircleOutlined
                                              style={{
                                                color: "green",
                                              }}
                                            />
                                          }
                                        >
                                          Add {labelGroup.name}
                                        </Button>
                                      </Form.Item>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Form.List>
                          </div>
                        </div>
                      ) : (
                        <div className="company-person-label-item-wrapper">
                          <div className="company-person-label-header-wrapper">
                            <span className="company-person-label-header">
                              {labelGroup.name}
                            </span>
                          </div>
                          <div className="company-person-label-field-wrapper">
                            <Form.List
                              name={[
                                "contactCompanyGroupAddressDtos",
                                labelGroup.id,
                              ]}
                            >
                              {(fields, { add, remove }) => (
                                <div className="global-form-flex">
                                  {fields.map((field, fieldIndex) => (
                                    <React.Fragment key={field.key}>
                                      <div className="global-form-group calc mt">
                                        <div className="global-form-label">
                                          <Form.Item noStyle>
                                            <Form.Item
                                              {...field}
                                              // label="Type"
                                              // className="form__group"
                                              name={[
                                                field.name,
                                                "contactLabelId",
                                              ]}
                                              fieldKey={[
                                                field.fieldKey,
                                                "contactLabelId",
                                              ]}
                                            >
                                              <Select
                                                getPopupContainer={(trigger) =>
                                                  trigger.parentNode
                                                }
                                                size="default"
                                                bordered={false}
                                                autoComplete="off"
                                              >
                                                {filteredOptions(
                                                  labelGroup.id
                                                ).map((item) => (
                                                  <Option
                                                    key={item.id}
                                                    value={item.id}
                                                  >
                                                    {item.name}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </Form.Item>
                                        </div>
                                        <div className="global-form-input">
                                          <div className="global-form-group calc">
                                            <Form.Item
                                              {...field}
                                              // label="streetName"
                                              // className="form__group"
                                              name={[field.name, "streetName"]}
                                              fieldKey={[
                                                field.fieldKey,
                                                "streetName",
                                              ]}
                                            >
                                              <Input
                                                size="default"
                                                placeholder="Street Name"
                                                autoComplete="off"
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className="global-group w-49">
                                            <div className="global-form-group">
                                              <Form.Item
                                                {...field}
                                                // label="Country"
                                                className="mar-select-input"
                                                name={[field.name, "countryId"]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "countryId",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Country Source is Required",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  filterOption={(
                                                    input,
                                                    option
                                                  ) =>
                                                    option.children
                                                      .toLowerCase()
                                                      .indexOf(
                                                        input.toLowerCase()
                                                      ) >= 0
                                                  }
                                                  showSearch={true}
                                                  size="default"
                                                  onChange={(value) =>
                                                    onChange(value, fieldIndex)
                                                  }
                                                  placeholder="Country"
                                                  autoComplete="off"
                                                  getPopupContainer={(
                                                    trigger
                                                  ) => trigger.parentNode}
                                                >
                                                  {countries.map((item) => (
                                                    <Option
                                                      key={item.id}
                                                      value={item.id}
                                                    >
                                                      {item.name
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        item.name.slice(1)}
                                                    </Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            </div>

                                            <div className="global-form-group">
                                              <Form.Item
                                                {...field}
                                                // label="Zip/Postal"
                                                // className="form__group"
                                                name={[
                                                  field.name,
                                                  "postalCodeZip",
                                                ]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "postalCodeZip",
                                                ]}
                                              >
                                                <Input
                                                  size="default"
                                                  placeholder="Postal Code"
                                                  autoComplete="off"
                                                />
                                              </Form.Item>
                                            </div>
                                          </div>

                                          <div className="global-group w-49">
                                            <div className="global-form-group">
                                              <Form.Item
                                                className="mar-select-input"
                                                {...field}
                                                // label="City"
                                                // className="form__group"
                                                name={[field.name, "cityId"]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "cityId",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: "City is Required",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  getPopupContainer={(
                                                    trigger
                                                  ) => trigger.parentNode}
                                                  size="default"
                                                  autoComplete="off"
                                                  filterOption={(
                                                    input,
                                                    option
                                                  ) =>
                                                    option.children
                                                      .toLowerCase()
                                                      .indexOf(
                                                        input.toLowerCase()
                                                      ) >= 0
                                                  }
                                                  showSearch={true}
                                                  placeholder="City"
                                                  value={cityValue}
                                                  onSearch={handleCityChange}
                                                  notFoundContent={
                                                    showCreateCityButton ? (
                                                      <div>
                                                        Create a city:{" "}
                                                        <Button
                                                          onClick={
                                                            handleCreateCity
                                                          }
                                                        >
                                                          Create
                                                        </Button>
                                                      </div>
                                                    ) : null
                                                  }
                                                >
                                                  {filterCitiesLists &&
                                                    filterCitiesLists[
                                                      fieldIndex
                                                    ]?.map((item) => (
                                                      <Option
                                                        key={item.id}
                                                        value={item.id}
                                                      >
                                                        {item.name
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          item.name.slice(1)}
                                                      </Option>
                                                    ))}
                                                </Select>
                                              </Form.Item>
                                            </div>
                                            <div className="global-form-group">
                                              <Form.Item
                                                {...field}
                                                // label="stateProvince"
                                                // className="form__group"
                                                name={[
                                                  field.name,
                                                  "stateProvince",
                                                ]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "stateProvince",
                                                ]}
                                              >
                                                <Input
                                                  size="default"
                                                  placeholder="State/Province"
                                                  autoComplete="off"
                                                />
                                              </Form.Item>
                                            </div>
                                          </div>
                                          <div className="global-group w-49">
                                            <div className="global-form-group">
                                              <Form.Item
                                                key={field.key}
                                                {...field}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "isPrimaryAddress",
                                                ]}
                                                valuePropName="checked"
                                                name={[
                                                  field.name,
                                                  "isPrimaryAddress",
                                                ]}
                                              >
                                                <Checkbox>Is Primary</Checkbox>
                                              </Form.Item>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="remove-field-wrapper">
                                          <MinusCircleOutlined
                                            style={{
                                              width: 45,
                                              marginTop: 3,
                                            }}
                                            onClick={() => remove(field.name)}
                                            className="contact__btn-danger"
                                          />
                                        </div>
                                      </div>
                                      <div className="global-form-group calc">
                                        {fields.length === fieldIndex + 1 && (
                                          <Button
                                            style={{
                                              width: 45,
                                              marginRight: 5,
                                            }}
                                            type="dashed"
                                            className="contact__buttons"
                                            onClick={() =>
                                              onAddAddress(
                                                add,
                                                fieldIndex + 1,
                                                labelGroup
                                              )
                                            }
                                          >
                                            <PlusCircleOutlined
                                              style={{
                                                color: "green",
                                              }}
                                            />
                                            Add {labelGroup.name}
                                          </Button>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}
                                  {fields.length === 0 && (
                                    <div className="global-fomr-group calc">
                                      <Form.Item>
                                        <Button
                                          type="dashed"
                                          className="contact__buttons"
                                          onClick={() =>
                                            onAddAddress(add, 0, labelGroup)
                                          }
                                          block
                                          icon={
                                            <PlusCircleOutlined
                                              style={{
                                                color: "green",
                                              }}
                                            />
                                          }
                                        >
                                          Add {labelGroup.name}
                                        </Button>
                                      </Form.Item>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Form.List>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="extra-form-wrapper">
                <div className="company-person-label-header-wrapper">
                  <span className="company-person-label-header">
                    Background
                  </span>
                </div>
                <div className="background-form-wrapper">
                  <div className="global-form-flex">
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Tags</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          name="tagNames"
                          rules={[{ required: false }]}
                        >
                          <Select
                            size="default"
                            mode="tags"
                            style={{ width: "100%" }}
                            onClick={() => setdropdowntag(false)}
                            onSearch={(e) => setdropdowntag(true)}
                            onSelect={(value, option) => {
                              addSearchTag(value.trim(), option).then(() => {
                                const tags =
                                  form.getFieldValue("tagNames") || [];
                                if (tags.includes("")) {
                                  form.setFieldsValue({
                                    tags: tags.filter((tag) => tag !== " "),
                                  });
                                }
                              });
                            }}
                            onInputKeyDown={onkeyDownTagsName}
                            open={dropdowntag}
                            autoComplete="off"
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {tagLists
                              ?.filter((tag) => tag.name.trim() !== "")
                              ?.map((tag) => (
                                <Option value={tag.name} key={tag.id}>
                                  {tag.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Source</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          name="contactSourceId"
                          rules={[
                            {
                              required: true,
                              message: "Contact Source is Required",
                            },
                          ]}
                        >
                          <Select
                            autoComplete="off"
                            size="default"
                            allowClear
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {contactSources.map((source) => (
                              <Option key={source.id}>{source.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Document</label>
                      </div>
                      <div className="global-form-input">
                        {uploadersExceptProfile.map((u, i) => (
                          <div
                            className="profiledetails__rowedit--data"
                            style={{ width: "100%", marginTop: "1px" }}
                          >
                            <Form.Item
                              name={[`${u.name}FileDtos`]}
                              className="form__group"
                              rules={[{ required: false }]}
                            >
                              <DynamicImageUploader
                                name={u.name}
                                isMultipleFileAllowed={u.isMultipleFileAllowed}
                                id={u.id}
                                allowedExtensions={u.allowedExtensions}
                                // fileList={fileList}
                                // setFileList={setFileList}
                                index={i}
                                form={form}
                                callback={(e) => {
                                  let olduploadedFileDtos =
                                    form.getFieldValue()[`${u.name}FileDtos`]
                                      ? [
                                          ...form.getFieldValue()[
                                            `${u.name}FileDtos`
                                          ],
                                        ]
                                      : [];
                                  let uploadedFileDtos = [
                                    ...olduploadedFileDtos,
                                  ];
                                  uploadedFileDtos.push(e);

                                  form.setFieldsValue({
                                    [`${u.name}FileDtos`]: uploadedFileDtos,
                                  });

                                  // form.setFieldsValue({
                                  //   uploadedFileDtos: [
                                  //     ...olduploadedFileDtos,
                                  //     e
                                  //   ]
                                  //     })
                                  setAdDocs = { setAdDocs };
                                  adDocs = { adDocs };
                                }}
                              />
                            </Form.Item>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="extra-form-wrapper">
                <div className="company-person-label-header-wrapper">
                  <span className="company-person-label-header">Company</span>
                </div>
                <div className="background-form-wrapper">
                  <div className="global-form-flex">
                    <div className="global-form-group calc">
                      <div className="global-form-label">
                        <label>Parent Company</label>
                      </div>
                      <div className="global-form-input">
                        <Form.Item
                          name="parentCompanyId"
                          rules={[
                            {
                              required: false,
                              message: "Company Name is required",
                            },
                          ]}
                        >
                          <AutoComplete
                            // onFocus={() => setdropdownscope(false)}
                            // onSearch={(e) => setdropdownscope(true)}
                            // onInputKeyDown={onInputKeyDown}
                            // onMouseEnter={() => setdropdownscope(false)}
                            // open={dropdownscope}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            autoComplete="off"
                            placeholder="Parent Company"
                            size="default"
                            onSearch={(value) => setValue(value)}
                            onSelect={(option, value) => {
                              setParentCompany(value.value);
                              form.setFieldsValue({
                                parentCompanyId: value.label,
                              });
                            }}
                            filterOption={(input, option) => {
                              return (
                                option?.label
                                  ?.toLowerCase()
                                  ?.indexOf(input?.toLowerCase()) >= 0
                              );
                            }}
                            showSearch={true}
                          >
                            {contactCompanyListingResponse?.map((company) => (
                              <Option
                                value={company.id}
                                key={company.companyName}
                                label={company.companyName}
                              >
                                {company.companyName}
                              </Option>
                            ))}
                          </AutoComplete>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="company-person-label-field-wrapper">
                    <Form.List name={["childCompanyDtos"]}>
                      {(fields, { add, remove }) => (
                        <div
                          className="global-form-flex"
                          style={{ padding: 0 }}
                        >
                          {fields.map((field, fieldIndex) => (
                            <React.Fragment key={field.key}>
                              <div className="global-form-group calc">
                                <div className="global-form-label">
                                  <label>Children Company</label>
                                </div>
                                <div className="global-form-input">
                                  <Form.Item
                                    {...field}
                                    // label="Type"
                                    // className="form__group"
                                    name={[field.name, "id"]}
                                    fieldKey={[field.fieldKey, "id"]}
                                    rules={[{ required: false }]}
                                  >
                                    <AutoComplete
                                      getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                      }
                                      autoComplete="off"
                                      placeholder="Children Company"
                                      size="default"
                                      value={childrenCompanies[fieldIndex]}
                                      onSelect={(option, value) => {
                                        childCompanies(value, field.name);

                                        form.setFieldsValue({
                                          childCompanyDtos: form
                                            .getFieldValue()
                                            .childCompanyDtos.map(
                                              (data, index) => {
                                                if (index === field.name) {
                                                  return {
                                                    id: value.label,
                                                  };
                                                } else {
                                                  return data;
                                                }
                                              }
                                            ),
                                        });
                                      }}
                                      onSearch={(value) => setValue(value)}
                                      filterOption={(input, option) => {
                                        return (
                                          option?.label
                                            ?.toLowerCase()
                                            ?.indexOf(input?.toLowerCase()) >= 0
                                        );
                                      }}
                                      showSearch={true}
                                    >
                                      {contactCompanyListingResponse?.map(
                                        (company, index) => (
                                          <Option
                                            value={company.id}
                                            key={index}
                                            label={company.companyName}
                                          >
                                            {company.companyName}
                                          </Option>
                                        )
                                      )}
                                    </AutoComplete>
                                  </Form.Item>
                                </div>
                                <div className="remove-field-wrapper">
                                  <MinusCircleOutlined
                                    style={{
                                      width: 45,
                                      marginTop: 3,
                                    }}
                                    onClick={() => cancel(field.name, remove)}
                                    className="contact__btn-danger"
                                  />
                                </div>
                              </div>
                              <div className="global-form-group calc">
                                {fields.length === fieldIndex + 1 && (
                                  <Button
                                    style={{
                                      width: 45,
                                      marginRight: 5,
                                    }}
                                    type="dashed"
                                    className="contact__buttons"
                                    onClick={() => add()}
                                  >
                                    <PlusCircleOutlined
                                      style={{
                                        color: "green",
                                      }}
                                    />
                                    Add Children
                                  </Button>
                                )}
                              </div>
                            </React.Fragment>
                          ))}
                          {fields.length === 0 && (
                            <div className="global-form-group calc">
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  className="contact__buttons"
                                  onClick={() => add()}
                                  block
                                  icon={
                                    <PlusCircleOutlined
                                      style={{
                                        color: "green",
                                      }}
                                    />
                                  }
                                >
                                  Add Children
                                </Button>
                              </Form.Item>
                            </div>
                          )}
                        </div>
                      )}
                    </Form.List>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-person-company-editor-wrapper">
              <div className="editor-header-wrapper">
                <h3>Notes</h3>
              </div>
              <div className="global-form-group">
                <Form.Item
                  className="form__group"
                  name="notes"
                  rules={[{ required: false }]}
                >
                  <Input.TextArea showCount autoComplete="off" />
                </Form.Item>
              </div>
            </div>
            <div className="global-form-footer">
              {!isEmpty(grantedPolices) &&
                grantedPolices["AbpIdentity.Roles"] && (
                  <div className="delete-button-permission">
                    {editCompany === "edit" ? (
                      <Button
                        onClick={() => {
                          showConfirm();
                        }}
                        danger
                        loading={contactDeleteCompanyLoading}
                      >
                        Delete
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              <Button
                className="cancel-btn"
                onClick={() => setEditCompany("view")}
              >
                <span>Cancel</span>
              </Button>
              <Button
                loading={
                  contactCompanyUpdateLoading ||
                  contactCompanyDetailAddLoading ||
                  contactDeleteCompanyLoading
                }
                onClick={() => {
                  onSubmit(editCompany === "add" ? "add" : "edit");
                }}
                type="primary"
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
        <CityAddUpdateDrawer
          drawerVisibility={addUpdateCountryDrawerVisibility}
          onAddUpdateCountryClose={onAddUpdateCountryClose}
          isUpdateCountry={isUpdateCountry}
        />
      </div>
    </>
  );
};

export default AddCompanyDrawer;
