import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Dropdown,
  Image,
  Menu,
  Skeleton,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import AppointmentDetailModal from "../Modal/AppointmentDetailModal";
const day = "assets/svg/internationalDay.svg";
const man = "assets/images/icons/man.png";

const TodayAppointment = ({
  calendarTodayData,
  rotate,
  setRotate,
  setVisibleEventFormDrawer,
  setEventFormSelect,
  setNewTask,
  type,
  handleNewMessage,
  setNewId,
  setModalVisible,
  calendarEventsLoading,
  modalVisible,
  taskEventGetDetailsResp,
  applicationType,
  isWatcher,
  isAssignee,
  isSelf,
  setIsWatcher,
  setIsAssignee,
  setIsSelf,
}) => {
  // const [isWatcher, setIsWatcher] = useState(false);
  // const [isAssignee, setIsAssignee] = useState(false);
  // const [isSelf, setIsSelf] = useState(true);
  const [appointments, setAppointments] = useState();

  // useEffect(() => {
  //   const filteredData = calendarTodayData?.filter((x) => {
  //     return (
  //       JSON.stringify(x?.eventCreatorUserName) === sessionStorage?.name ||
  //       (x?.isWatcher === false &&
  //         JSON.stringify(x?.eventCreatorUserName) !== sessionStorage?.name)
  //     );
  //   });

  //   if (isWatcher) {
  //     setAppointments(calendarTodayData);
  //   } else {
  //     setAppointments(filteredData);
  //   }
  // }, [calendarTodayData, isWatcher]);

  useEffect(() => {
    const filteredData = calendarTodayData.filter((x) => {
      const isCurrentUserCreator =
        JSON.stringify(x?.eventCreatorUserName) === sessionStorage?.name;
      const isNotWatcherOrAssignee =
        x?.isWatcher === false && x?.isAsignee === false;

      if (isWatcher && !isAssignee && !isSelf) {
        return x?.isWatcher === true;
      } else if (!isWatcher && isAssignee && !isSelf) {
        return x?.isAsignee === true;
      } else if (!isWatcher && !isAssignee && isSelf) {
        return isCurrentUserCreator && isNotWatcherOrAssignee;
      } else if (isWatcher && isAssignee && !isSelf) {
        return x?.isWatcher === true || x?.isAsignee === true;
      } else if (!isWatcher && isAssignee && isSelf) {
        return x?.isAsignee === true || isCurrentUserCreator;
      } else if (isWatcher && !isAssignee && isSelf) {
        return x?.isWatcher === true || isCurrentUserCreator;
      } else {
        return true;
      }
    });

    setAppointments(filteredData);
  }, [calendarTodayData, isWatcher, isAssignee, isSelf]);

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setIsWatcher(!isWatcher);
        }}
      >
        <Checkbox checked={isWatcher} /> Watcher
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsAssignee(!isAssignee);
        }}
      >
        <Checkbox checked={isAssignee} /> Assignee
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsSelf(!isSelf);
        }}
      >
        <Checkbox checked={isSelf} /> Self
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div>
        <div className="dashboards__bodyWrap" style={{ marginBottom: "5px" }}>
          <h4 className="dashboards__body--title">
            Today's Appointments ({appointments?.length})
            <span
              onClick={() => {
                setRotate(rotate + 1);
              }}
              style={{
                marginLeft: "10px",
                height: "30px",
                marginBottom: "10px",
              }}
            >
              <img
                src="./assets/svg/toggle-arrow.svg"
                height={15}
                alt="complete"
              />
            </span>
          </h4>
          <div style={{ display: "flex", gap: "10px", marginLeft: "auto" }}>
            <h4>{moment().format("DD-MMM-YYYY")}</h4>
            <Button className="contact__header__btn-primary">
              <PlusOutlined
                onClick={() => {
                  setVisibleEventFormDrawer(true);
                  setEventFormSelect("event");
                  setNewTask(true);
                }}
              />
            </Button>
          </div>
          <Dropdown.Button
            overlay={menu}
            className="new_menu"
            style={{
              marginBottom: "5px",
              width: "20px",
              border: "none",
              marginLeft: "2px",
            }}
          />
        </div>

        <div>
          <ul className="dashboards__todolists" style={{ height: "280px" }}>
            {appointments?.length > 0 ? (
              appointments?.map((list, i) => (
                <>
                  {list?.event !== "day" ? (
                    <li
                      key={i}
                      className="dashboards__events--list"
                      style={{
                        padding: "0px",
                        color: list?.color,
                      }}
                    >
                      <Tooltip
                        title={
                          (sessionStorage.userId !==
                          JSON.stringify(list?.eventCreatorId)
                            ? "[" +
                              list?.eventCreatorUserName?.toUpperCase() +
                              "]"
                            : "") +
                          " " +
                          list?.title
                        }
                      >
                        <div
                          className={`dashboards__events--listTitle ${list?.event} `}
                          style={{ flexBasis: "40%" }}
                        >
                          {i + 1}.
                          <Typography.Text style={{ color: "gray" }}>
                            {sessionStorage.userId !==
                            JSON.stringify(list?.eventCreatorId)
                              ? "[" +
                                list?.eventCreatorUserName?.toUpperCase() +
                                "]"
                              : ""}{" "}
                          </Typography.Text>
                          {list?.title}
                        </div>
                      </Tooltip>

                      {type === "private" && (
                        <div
                          className="dashboards__events--date"
                          style={{
                            color: "black",
                            width: "45%",
                            display: "flex",
                            justifyContent: "right",
                          }}
                        >
                          {list?.allDay ? (
                            <span>All Day</span>
                          ) : moment().format("DD-MMM-YYYY") ===
                            moment(list?.start).format("DD-MMM-YYYY") ? (
                            <span>
                              {moment(list?.start).format("h:mm A")}-
                              {moment(list?.end).format("h:mm A")}{" "}
                            </span>
                          ) : (
                            <span>
                              {moment(list?.start).format("h:mm A")}{" "}
                              {moment(list?.start).format("DD MMM YYYY")}
                            </span>
                          )}
                          <Link
                            onClick={() => {
                              handleNewMessage(list?.id);
                            }}
                          >
                            {/* <img
                          src={internalMessageGreen}
                          height="12px"
                          alt="message "
                        /> */}
                          </Link>
                          <div
                            style={{
                              display: "inline",
                              marginLeft: "5px",
                            }}
                            onClick={() => {
                              setNewId(list?.id);
                              setModalVisible(true);
                            }}
                          >
                            {" "}
                            <EyeOutlined
                            // style={{ marginLeft: "5px" }}
                            />
                          </div>
                        </div>
                      )}
                      {type === "public" && (
                        <div
                          className="dashboards__events--date"
                          style={{ color: "black", width: "45%" }}
                        >
                          {moment(list.start).format("DD-MMM-YYYY")}
                        </div>
                      )}
                    </li>
                  ) : (
                    <li key={i} className="dashboards__events--list specialDay">
                      <div className="dashboards__events--listTitle day">
                        <Image src={day} /> {list.eventTitle}
                      </div>
                      <div
                        className="dashboards__events--date"
                        style={{ width: "32%" }}
                      >
                        {list.date}
                      </div>
                    </li>
                  )}
                </>
              ))
            ) : !calendarEventsLoading ? (
              <div className="dashboards__visitors--none">
                <Image
                  className="data-no-img"
                  src={man}
                  height={50}
                  width={50}
                />
                <span>There is no information.</span>
              </div>
            ) : (
              <Skeleton />
            )}
            {modalVisible && (
              <AppointmentDetailModal
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                details={taskEventGetDetailsResp}
                applicationType={applicationType?.id}
              />
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TodayAppointment;
