import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Skeleton } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";

import {
  contactCompanyFav,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action.js";
import { getContactCompanyPagination } from "../../../services/redux/contact/companypeople/action";

import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

const AddUpdateCompanyTable = ({
  contactCompanyGetDetailsResponse,
  showAddUpdatePersonDrawer,
  rowEventhandlers,
  setRowClassName,
  searchKeyword,
  selectedMailTypeAndMailData,
  setOnClickCompany,
  onClickCompany,
  pageSize,
}) => {
  const dispatch = useDispatch();

  const { contactCompanyPaginationResponse, contactCompanyPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  // console.log("table", contactCompanyPaginationLoading);

  // const pageSize = 15;

  // useEffect(() => {
  //   dispatch(getContactCompanyPagination());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (searchKeyword && !isEmpty(selectedMailTypeAndMailData)) {
  //     getCompanyData();
  //   }
  // }, [searchKeyword]);

  // useEffect(() => {
  //   if (
  //     isEmpty(selectedMailTypeAndMailData) ||
  //     isEmpty(selectedMailTypeAndMailData.search)
  //   ) {
  //     getCompanyData();
  //   }
  // }, []);

  useEffect(() => {
    if (!contactCompanyPaginationResponse) return;
    setdata(contactCompanyPaginationResponse.items);
    let totalCount = contactCompanyPaginationResponse.totalCount;
    setTotalCount(totalCount);

    let newCurrentPage =
      contactCompanyPaginationResponse?.items?.length / pageSize;
    setCurrentPage(newCurrentPage);
  }, [contactCompanyPaginationResponse]);

  const getCompanyData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    // SearchKeyword,
    ForPagination = false
  ) => {
    // dispatch(
    //   getContactCompanyPagination(
    //     {
    //       MaxResultCount,
    //       SkipCount,
    //       SearchKeyword: searchKeyword,
    //     },
    //     ForPagination
    //   )
    // );
    const {
      CompanyContactName,
      ContactActiveStatus,
      Email,
      ExperienceFrom,
      ExperienceTo,
      IsAdvancedFilter,
      Phone,
      Qualification,
      Website,
      majorSubject,
      sector,
      yearsOfExperience,
      scopeOfWork,
    } = searchKeyword;
    console.log("COMPANY CALLED");
    dispatch(
      getContactCompanyPagination(
        {
          ...(CompanyContactName !== undefined && { CompanyContactName }),
          ...(ContactActiveStatus !== undefined && { ContactActiveStatus }),
          ...(Email !== undefined && { Email }),
          ...(ExperienceFrom !== undefined && { ExperienceFrom }),
          ...(ExperienceTo !== undefined && { ExperienceTo }),
          IsAdvancedFilter,
          MaxResultCount,
          SkipCount,
          ...(typeof searchKeyword === "string" && { searchKeyword }),
          ...(Phone !== undefined && { Phone }),
          ...(Qualification !== undefined && { Qualification }),
          ...(Website !== undefined && { Website }),
          ...(majorSubject !== undefined && { majorSubject }),
          ...(sector !== undefined && { sector }),
          ...(yearsOfExperience !== undefined && { yearsOfExperience }),
          ...(scopeOfWork !== undefined && { scopeOfWork }),
        },
        ForPagination
      )
    );
  };

  const fetchMoreData = () => {
    getCompanyData(pageSize, currentPage * pageSize, searchKeyword, true);
  };

  const contactCompanyPagination = () => {
    return (
      <>
        <div>
          {data?.map((company, key) => {
            const backGroundColor = key % 2 === 0 ? "" : "#f2f2f2";

            return (
              <>
                <div
                  style={{ background: backGroundColor, height: "30px" }}
                  className={`reference ${
                    setRowClassName(company) && "active"
                  }`}
                  onClick={() => {
                    rowEventhandlers(company);
                    setOnClickCompany(!onClickCompany);
                  }}
                  onDoubleClick={() => {
                    const CompanyId = company.contactCompanyId;
                    dispatch(getContactPersonPagination({ CompanyId }));
                    // console.log(
                    //   "I am here at contact listing - Double Click",
                    //   company
                    // );
                  }}
                  key={key}
                >
                  <div className="reference__wrapper">
                    <div className="reference__wrapper--contents">
                      <div className="reference__body">
                        <div
                          className="reference__body--right "
                          onClick={(event) => {
                            dispatch(
                              contactCompanyFav(
                                { contactCompanyId: company.contactCompanyId },
                                contactCompanyGetDetailsResponse
                              )
                            );
                          }}
                        >
                          {company.isFavorite ? (
                            <StarFilled style={{ color: "#ffd036" }} />
                          ) : (
                            <StarOutlined style={{ color: "#ffd036" }} />
                          )}
                        </div>

                        <div className="reference__body--right">
                          <span className="reference__body--5right">
                            <Tooltip
                              title={`${company.contactCompanyName}${
                                company.contactCompanyAbbrevation != null &&
                                company.contactCompanyAbbrevation
                                  ? `[${company.contactCompanyAbbrevation}]`
                                  : ""
                              }${
                                company.contactCompanyCityName != null &&
                                company.contactCompanyCityName
                                  ? `, ${company.contactCompanyCityName}`
                                  : ""
                              }${
                                company.contactCompanyCountryName !== null &&
                                company.contactCompanyCountryName
                                  ? `, ${company.contactCompanyCountryName}`
                                  : ""
                              }`}
                              placement="bottomLeft"
                            >
                              {/* {company.contactCompanyName}*/}
                              <span
                                style={{
                                  color:
                                    company?.contactActiveStatus === 1
                                      ? "black"
                                      : company?.contactActiveStatus === 2
                                      ? "blue"
                                      : company?.contactActiveStatus === 0
                                      ? "green"
                                      : undefined,
                                }}
                              >
                                {company.contactCompanyName}
                              </span>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  };
  return (
    <>
      <p
        style={{
          background: "#d8ddeb",
          fontWeight: "bold",
          padding: "5px",
          marginBottom: "0px",
        }}
      >
        Company
      </p>
      <div
        id="tenderScroll"
        style={{
          height: "80%",
          overflowY: "auto",
        }}
      >
        {contactCompanyPaginationLoading && (
          <>
            <Skeleton paragraph={{ rows: 10 }} active />
          </>
        )}
        {!isEmpty(data) ? (
          <InfiniteScroll
            dataLength={contactCompanyPaginationResponse?.items.length}
            hasMore={
              contactCompanyPaginationResponse?.totalCount >
              contactCompanyPaginationResponse?.items.length
            }
            next={fetchMoreData}
            loader={<Skeleton paragraph={{ rows: 2 }} active />}
            scrollableTarget="tenderScroll"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>End of Company</b>
              </p>
            }
          >
            {contactCompanyPagination()}
          </InfiniteScroll>
        ) : (
          <p style={{ textAlign: "center" }}>
            <b>No Data</b>
          </p>
        )}
      </div>
    </>
  );
};

export default AddUpdateCompanyTable;
