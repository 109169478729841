import React from "react";
import DetailMenuCenter from "../MenuCenter/DetailMenuCenter";
import ChatInput from "./ChatInput";

function GroupChatDetail(props) {
  let {
    selectedGroupId,
    selectedGroupData,
    setSelectedGroupData,
    setIsDrawerVisible,
    setIsGroupTitleUpdating,
    isChatOpen,
    setIsChatOpen,
  } = props;

  return (
    <>
      <div className="emailDetails ">
        <DetailMenuCenter
          selectedGroupId={selectedGroupId}
          selectedGroupData={selectedGroupData}
          setSelectedGroupData={setSelectedGroupData}
          setIsDrawerVisible={setIsDrawerVisible}
          setIsGroupTitleUpdating={setIsGroupTitleUpdating}
        />

        <div className="emailDetails__body">
          <div className="emailDetails__body--infos-group">
            <div className="email-description groupchat-description">
              <ChatInput
                selectedGroupId={selectedGroupId}
                isChatOpen={isChatOpen}
                setIsChatOpen={setIsChatOpen}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupChatDetail;
