import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
  Switch,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { getAllUsers } from "services/remanentCalls";
import { isEmpty } from "lodash";

const ENTER = "Enter";

const ceadSearch = "cead-search";

const CheckboxGroup = Checkbox.Group;

export default function CeadSearch(props) {
  const {
    checkedValues,
    setCheckValue,
    setSearchValue,
    searchValues,
    initialSearchValues,
    tab,
  } = props;
  const [isShowSearchFields, setShowSearchFields] = useState(true);
  const [userListing, setUserListing] = useState([]);

  // const { wform } = Form.useForm();

  const options = [
    {
      label: "Archived",
      value: "archived",
    },
    {
      label: "Active",
      value: "active",
    },
  ];

  // # endregion fetch the userlisting form api
  useEffect(() => {
    const fetchUserListing = async () => {
      const resp = await getAllUsers().catch((err) => {
        message.error("Failure");
      });
      if (resp && resp.status === 200) {
        const userData = resp.data.map((x) => {
          return {
            label: x.fullName,
            value: x.fullName.trim(),
          };
        });
        setUserListing(userData);
      }
    };
    if (isEmpty(userListing)) {
      fetchUserListing();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.code === ENTER) {
      setSearchValue({
        ...searchValues,
        // isEnterKey: false,
        isEnterKey: true,
      });
    }
  };

  const onChange = (checkedValues) => {
    setCheckValue(checkedValues);
    setSearchValue({ ...initialSearchValues, isEnterKey: true });
  };

  const onDateChange = (date, dateString, changeType) => {
    const year =
      changeType === "year" ? dateString : searchValues.SearchKeyword_Year;
    const month = changeType === "month" ? date : "";

    setSearchValue({
      ...searchValues,
      SearchKeyword_Year: year,
      SearchKeyword_Month: month,
      isEnterKey: true,
      tab: tab,
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ justifyContent: "space-around" }}>
          {!isShowSearchFields && (
            <SearchOutlined
              onClick={() => {
                setShowSearchFields(!isShowSearchFields);
              }}
            />
          )}

          <span
            className={`search-fields ${
              isShowSearchFields ? "cead-t-show" : ""
            }`}
          >
            {tab === "weeklyReport" ? (
              <>
                <DatePicker.YearPicker
                  className={ceadSearch}
                  onChange={(date, dateString) => {
                    onDateChange(date, dateString, "year");
                  }}
                />

                <DatePicker.MonthPicker
                  className={ceadSearch}
                  onChange={(date, dateString) => {
                    const today = new Date();
                    const _date = date ?? today;
                    onDateChange(
                      moment(_date).format("MM"),
                      dateString,
                      "month"
                    );
                  }}
                  format="MMM"
                />

                <Select
                  className={ceadSearch}
                  options={userListing}
                  onChange={(value) => {
                    setSearchValue({
                      ...searchValues,
                      creatorName: value ?? "",
                      isEnterKey: true,
                    });
                  }}
                  allowClear
                  placeholder="Select Creator Name"
                />
              </>
            ) : (
              <>
                <Input
                  placeholder="Search"
                  loading
                  allowClear
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSearchValue({
                      ...searchValues,
                      SearchKeyword: value,
                    });
                  }}
                  className={ceadSearch}
                  value={searchValues.SearchKeyword}
                />

                {/* <Input
                  placeholder="Search by Reference"
                  loading
                  allowClear
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSearchValue({
                      ...searchValues,
                      Reference_SearchKeyword: value,
                    });
                  }}
                  className={ceadSearch}
                  value={searchValues.Reference_SearchKeyword}
                />

                <Input
                  placeholder="Search By Contract Title"
                  loading
                  allowClear
                  onKeyDown={handleKeyDown}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSearchValue({
                      ...searchValues,
                      Contract_SearchKeyword: value,
                    });
                  }}
                  className={ceadSearch}
                  value={searchValues.Contract_SearchKeyword}
                /> */}

                {/* <RangePicker
              className={ceadSearch}
              onKeyDown={handleKeyDown}
              onChange={(value, datestring) => {
                const dateFrom = datestring[0];
                // const dateTo = datestring[1];
                console.log("dateFrom", dateFrom);
                setSearchValue({
                  ...searchValues,
                  Sorting: [dateFrom],
                  isEnterKey: true,
                });
              }}
              // value={searchValues.Sorting}
            /> */}
              </>
            )}
            <span
              className="close-icon"
              onClick={() => {
                setShowSearchFields(!isShowSearchFields);
                setSearchValue(initialSearchValues);
              }}
            >
              &times;
            </span>
          </span>
        </div>

        {tab !== "weeklyReport" && (
          <div>
            <CheckboxGroup
              options={options}
              defaultValue={checkedValues} // You can set the initial/default selected values here
              onChange={onChange}
            />
          </div>
        )}
      </div>
    </>
  );
}
