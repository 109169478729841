import React from "react";
import { Modal, Form, Input, Button } from "antd";
import { useDispatch } from "react-redux";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const QuickAddCompanyEmailModal = ({ visible, onClose, onSubmit, loading }) => {
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    await onSubmit(values.newEmail);
    form.resetFields();
  };

  return (
    <Modal
      title="Add Company Email"
      visible={visible}
      footer={null}
      onCancel={onClose}
      style={{ zIndex: 1051 }}
    >
      <Form form={form} onFinish={handleFinish}>
        <Form.Item
          label="Email Address"
          name="newEmail"
          rules={[
            { required: true, message: "Please enter the company email!" },
            {
              pattern: emailRegex,
              message: "Please enter a valid email address!",
            },
          ]}
        >
          <Input placeholder="Enter company email" />
        </Form.Item>
        <Form.Item>
          <Button
            className="company-add-save-btn"
            type="primary"
            htmlType="submit"
            shape="round"
            loading={loading}
          >
            Add Email
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default QuickAddCompanyEmailModal;
