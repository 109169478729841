import { EMAIL_TYPES } from "../../../types";

const INITIAL_STATE = {
    composeQuickMessageResp: [],
    composeQuickMessageRespLoading: false,
};

export default function quickMessageReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Appointment events
        case EMAIL_TYPES.COMPOSE_QUICK_MESSAGE_INIT:
            return {
                ...state,
                composeQuickMessageResp: [],
                composeQuickMessageRespLoading: true,
            };
        case EMAIL_TYPES.COMPOSE_QUICK_MESSAGE_SUCCESS:
            return {
                ...state,
                composeQuickMessageResp: action.payload,
                composeQuickMessageRespLoading: false,
            };
        case EMAIL_TYPES.COMPOSE_QUICK_MESSAGE_FINISH:
            return {
                ...state,
                composeQuickMessageRespLoading: false,
            };
        default:
            return { ...state };
    }
}
