import { ADMIN_TYPES } from "services/types";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
import { TutorialService } from "./api";

const tutorialService = new TutorialService();

export const actionForGetTutorialListing = (q) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.TUTORIAL_TYPE_LISTING));
    const response = await tutorialService.getTutorialListing(q);
    dispatch(finish(ADMIN_TYPES.TUTORIAL_TYPE_LISTING));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.TUTORIAL_TYPE_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForAddTutorial = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.TUTORIAL_TYPE_ADD));
    const response = await tutorialService.createTutorial(body);
    dispatch(finish(ADMIN_TYPES.TUTORIAL_TYPE_ADD));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.TUTORIAL_TYPE_ADD, response.data));
      console.log("ACTION add: ", response.data);
      message.success("Added Tutorial Video");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForUpdateTutorial = (id, body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.TUTORIAL_TYPE_UPDATE));
    const response = await tutorialService.updateTutorial(id, body);
    dispatch(finish(ADMIN_TYPES.TUTORIAL_TYPE_UPDATE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.TUTORIAL_TYPE_UPDATE, response.data));
      console.log("ACTTION Update: ", response.data);
      message.success("Updated Tutorial Video");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForGetTutorialCategoryListing = () => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.TUTORIAL_CATEGORY_LISTING));
    const response = await tutorialService.getTutorialCategoryListing();
    dispatch(finish(ADMIN_TYPES.TUTORIAL_CATEGORY_LISTING));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.TUTORIAL_CATEGORY_LISTING, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForAddTutorialCategory = (body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.TUTORIAL_CATEGORY_ADD));
    const response = await tutorialService.createTutorialCategory(body);
    dispatch(finish(ADMIN_TYPES.TUTORIAL_CATEGORY_ADD));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.TUTORIAL_CATEGORY_ADD, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForUpdateTutorialCategory = (id, body) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.TUTORIAL_CATEGORY_UPDATE));
    const response = await tutorialService.updateTutorialCategory(id, body);
    dispatch(finish(ADMIN_TYPES.TUTORIAL_CATEGORY_UPDATE));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.TUTORIAL_CATEGORY_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const actionForTutorialCategoryDetail = (id) => {
  return async (dispatch) => {
    dispatch(init(ADMIN_TYPES.TUTORIAL_CATEGORY_DETAIL));
    const response = await tutorialService.getTutorialCategoryDetails(id);
    dispatch(finish(ADMIN_TYPES.TUTORIAL_CATEGORY_DETAIL));
    if (response.isSuccess) {
      dispatch(success(ADMIN_TYPES.TUTORIAL_CATEGORY_UPDATE, response.data));
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};
