import moment from "moment";
import { Form, Button, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
// import BlogForm from "./BlogForm";
import MatchingImageDisplay from "components/shared/MatchingImageDisplay";
const { TextArea } = Input;

const BlogItem = ({
  blogMsg,
  appUserIdUC,
  editMode,
  setEditMode,
  editedBlog,
  handleEditClick,
  handleEditSubmit,
  listingAllProfile,
}) => {
  const [form] = Form.useForm();

  return (
    <>
      {blogMsg.map((item, index) => (
        <div
          className="blogWrapper"
          key={index}
          style={{
            display: "flex",
            justifyContent:
              JSON.stringify(item?.userId)?.toUpperCase() === appUserIdUC
                ? "flex-end"
                : "flex-start",
          }}
        >
          {JSON.stringify(item?.userId)?.toUpperCase() !== appUserIdUC ? (
            <span
              style={{
                height: "40px",
                width: "40px",
                background: "#67a1e4",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                color: "#FFF",
                marginRight: "12px",
              }}
            >
              <MatchingImageDisplay
                listingAllProfile={listingAllProfile}
                item={item}
              />
            </span>
          ) : null}

          <div
            style={{
              display: "inline-block",
              maxWidth: "80%",
              background:
                JSON.stringify(item?.userId)?.toUpperCase() === appUserIdUC
                  ? "#E8EBFA"
                  : "#FFFFFF",
              marginBottom: "1em",
              padding: "0.4em 1em",
            }}
          >
            <div>
              {editMode && editedBlog.id === item.id ? (
                // <BlogForm
                //   initialValue={item.body}
                //   onFinish={(values) => {
                //     handleEditSubmit(item.id, values.editedMessage);
                //     // toggleEditMode();
                //   }}
                // />
                <Form
                  onFinish={handleEditSubmit}
                  initialValues={{ editedMessage: editedBlog.body }}
                >
                  <Form.Item
                    name="editedMessage"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    {/* <Input */}
                    <TextArea
                      style={{
                        // height: "42px",
                        borderBottom: "4px solid #8e92d1",
                        resize: "none",
                      }}
                    />
                  </Form.Item>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                  <Button onClick={() => setEditMode(false)}>Cancel</Button>
                </Form>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <span className="blog-username">
                      {item?.name?.toUpperCase()}
                    </span>
                    <span style={{ color: "#807e7e" }}>
                      {item?.lastmodified
                        ? "Modified " + moment(item?.lastmodified).calendar()
                        : moment(item?.creationTime).startOf("min").fromNow()}
                    </span>
                  </div>
                  <div
                    className="blogBody"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>{item?.body}</p>
                    <span
                      className={
                        JSON.stringify(item?.userId) ===
                        sessionStorage?.userId.toUpperCase()
                          ? "blog__content"
                          : ""
                      }
                    >
                      {JSON.stringify(item?.userId)?.toUpperCase() ===
                      appUserIdUC ? (
                        <EditOutlined onClick={() => handleEditClick(item)} />
                      ) : null}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default BlogItem;
