import { appBaseUrl, rootBaseUrl, cqBaseUrl } from "../../../utils/config";
import { getService, postService, putService } from "../../commonServices";
import queryString from "query-string";
export class ProfileSerice {
  getGenders() {
    let url = `${appBaseUrl}/employeeRequest/genderList`;
    let data = getService(url);
    return data;
  }

  //organization Listing
  getorganizationListing() {
    let url = `${appBaseUrl}/organizationStructure/organizationLayerListing`;
    let data = getService(url);
    return data;
  }

  getMaritalStatusList() {
    let url = `${appBaseUrl}/employeeRequest/maritalStatusList`;
    let data = getService(url);
    return data;
  }

  employeeProfileDetail(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/employeeRequest/employeeDetail?${query}`;
    let data = getService(url);
    return data;
  }

  employeeEvaluationProfileDetail(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/employeeRequest/employeeDetail?${query}`;
    let data = getService(url);
    return data;
  }

  employeeProfilePersonalUpdate(body) {
    let url = `${appBaseUrl}/employeeRequest/employeePersonalProfileUpdate`;
    let data = postService(url, body);
    return data;
  }

  employeeProfileOfficalUpdate(body) {
    let url = `${appBaseUrl}/employeeRequest/employeeOfficalProfileUpdate`;
    let data = postService(url, body);
    return data;
  }

  userRegistrationAddUpdate(body) {
    let url = `${appBaseUrl}/employeeRequest/userRegistrationAddUpdate`;
    let data = postService(url, body);
    return data;
  }

  employeesPagination(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/employeeRequest/employeesPagination?${query}`;
    let data = getService(url);
    return data;
  }

  documentFilesAddUpdate(body, id) {
    let url = `${appBaseUrl}/employeeRequest/documentFilesAddUpdate/${id}`;
    let data = postService(url, body);
    return data;
  }
  employeeDelete(id) {
    let url = `${appBaseUrl}/employeeRequest/employeeDelete/${id}`;
    let data = postService(url, {}, "delete");
    return data;
  }
  userDetail(id) {
    let url = `${rootBaseUrl}/user-info/user-details/${id}`;
    let data = getService(url);
    return data;
  }
  getEmployeeEmailList(q) {
    let query = queryString.stringify(q);
    // console.log("query", query);
    let url = `${appBaseUrl}/employeeRequest/employeeEmailList?${query}`;
    let data = getService(url);
    return data;
  }

  emailSignatureAdd(body) {
    let url = `${appBaseUrl}/emailMessage/emailSignatureAdd`;
    let data = postService(url, body);
    return data;
  }

  // active email signature details

  activeEmailSignatureDetails(q) {
    let query = queryString.stringify(q);
    let url = `${appBaseUrl}/emailMessage/activeEmailSignatureDetails?${query}`;
    let data = getService(url);
    return data;
  }
  // emailSignatureUpdate
  emailSignatureUpdate(id, body) {
    let url = `${appBaseUrl}/emailMessage/emailSignatureUpdate/${id}`;
    let data = postService(url, body, "put");
    return data;
  }

  // /api/app/employeeRequest/{id}/markAsDefaultEmail

  emailMarkAsDefault(id) {
    let url = `${appBaseUrl}/employeeRequest/${id}/markAsDefaultEmail`;
    let data = postService(url);
    return data;
  }

  //Incheckbox active and inactive
  isCheckInboxValue(id, body) {
    let url = `${appBaseUrl}/employeeRequest/checkingInboxEnableDisable/${id}?isCheckInbox=${body}`;
    // let url = `${appBaseUrl}/employeeRequest/checkingInboxEnableDisable/${id}`;
    let data = postService(url, body, "put");
    return data;
  }
  composeWelcomeMessage = (id) => {
    let url = `${appBaseUrl}/employeeRequest/composeWelcomeMessageForNewUser/${id}`;
    let data = putService(url);
    return data;
  };
  leftCompany = (id) => {
    let url = `${appBaseUrl}/employeeRequest/lockoutUserEnableDisable/${id}`;
    let data = postService(url);
    return data;
  };

  clearanceFormAdd = (body) => {
    let url = `${appBaseUrl}/employeeRequest/employeeClearanceAddUpdateRequest`;
    let data = postService(url, body);
    return data;
  };

  clearanceFormList = (id) => {
    let url = `${appBaseUrl}/employeeRequest/employeeClearanceRequestGetListing/${id}`;
    let data = getService(url);
    return data;
  };

  questionTypeList = () => {
    let url = `${appBaseUrl}/organizationStructure/questionTypesGetListing`;
    let data = getService(url);
    return data;
  };

  questionTypeAdd = (body) => {
    let url = `${appBaseUrl}/organizationStructure/questionAddUpdateInformation`;
    let data = postService(url, body);
    return data;
  };

  questionTypeGet = () => {
    let url = `${appBaseUrl}/organizationStructure/question`;
    let data = getService(url);
    return data;
  };

  exitInterviewGet = (id) => {
    let url = `${appBaseUrl}/employeeRequest/employeeExitRequestGetDetails/${id}`;
    let data = getService(url);
    return data;
  };

  exitInterviewPost = (body) => {
    let url = `${appBaseUrl}/employeeRequest/employeeExitAddUpdateRequest`;
    let data = postService(url, body);
    return data;
  };

  getEmployeeReview = (id) => {
    let url = `${appBaseUrl}/requisitionManagement/employeeEvaluationById/${id}`;
    let data = getService(url);
    return data;
  };

  employeeEvaluationMail = (id, body) => {
    let url = `${appBaseUrl}/requisitionManagement/employeeEvaluationSupervisorAction/${id}`;
    let data = putService(url, body);
    return data;
  };

  addEmployeeEvaluationSelfAction = (id, body) => {
    let url = `${appBaseUrl}/requisitionManagement/employeeEvaluationSelfAction/${id}`;
    let data = putService(url, body);
    return data;
  }

  // SUPER API: listing of all users profile picture
  getAllUsersPP = () => {
    let url = `${cqBaseUrl}/File/GetUsersProfileInformation`;
    let data = getService(url);
    return data;
  };
}
