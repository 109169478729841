import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Menu, Skeleton, Button, Row, Col } from "antd";

//inlcudes
import { withBaseLayout } from "../../layouts/Base";
import { AddUpdateLeave } from "./AddUpdateLeave";
import { AddUpdateGuestEntertainment } from "./AddUpdateGuestEntertainment";
import { AddUpdateNewsCutting } from "./AddUpdateNewsCutting";
import { AddUpdateProcrument } from "./AddUpdateProcrument";
import { AddUpdateDetails } from "./AddUpdateTdaDetails";
import { LoanApplication } from "./LoanApplication";
import EmployeeEvaluation from "./AddUpdateEmployeeEvaluation";
//import HonorRollAddUpdate from "../OfficeResource/HonorRoll";
//import GuestEntry from "views/Oragnizer/guestEntry";
import Header from "components/header";
import ChatDetails from "views/GlobalEmailChat";
import { get, isEmpty } from "lodash";
import storage from "../../utils/storage";
import useRoles from "hooks/useRoles";

const minimize = "assets/images/icons/minimize.png";
const maximize = "assets/images/icons/maximize.png";

const defaultRoute = "/requisition/leave";

function Requisition() {
  let localStorageId = storage.getItem("userId");
  const history = useHistory();
  const { profileInfo, profileInfoLoading } = useSelector(
    (state) => state.profile
  );

  const [state, setState] = useState({
    current: defaultRoute,
    collpase: false,
  });
  const handleClick = (e) => {
    history.push(e.key);
  };

  const toggleCollapse = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };

  const [isCurrentUserHR, setIsCurrentUserHR] = useState(null);
  const { isRoleHr } = useRoles();

  //state for chat details
  const [showChat, setShowChat] = useState(false);
  const [chatHeight, setChatHeight] = useState(false);
  const [smallChatHeight, setSmallChatHeight] = useState(false);
  const [chatBox, setchatBox] = useState(true);
  const [hideInternalMessage, setHideInternalMessage] = useState(false);
  const [getLeaveDetails, setLeaveDetails] = useState("");

  //get row detail on Click
  const toDoApprovalGetRow = (record) => {
    // console.log("You clicked it", record)
    return {
      onClick: (e) => {
        setLeaveDetails(record);
      },
    };
  };

  useEffect(() => {
    if (!isEmpty(profileInfo) && !profileInfoLoading) {
      setIsCurrentUserHR(
        profileInfo?.userRegistrationAddUpdateDto?.roleNames.includes("HR")
      );
    }
  }, [localStorageId, profileInfo]);
  //}, [profileInfo, profileInfoLoading]);

  useEffect(() => {
    if (history.location.pathname === "/requisition") {
      history.push(defaultRoute);
    }
    setState({ ...state, current: history.location.pathname });
  }, [history.location.pathname]);

  const renderComponent = (pathname) => {
    switch (pathname) {
      case defaultRoute:
        return (
          <AddUpdateLeave
            setHideInternalMessage={setHideInternalMessage}
            toDoApprovalGetRow={toDoApprovalGetRow}
            getLeaveDetails={getLeaveDetails}
          />
        );
      case "/requisition/guest-entertainment":
        return <AddUpdateGuestEntertainment />;
      case "/requisition/news-cutting":
        return <AddUpdateNewsCutting />;
      case "/requisition/loan-application":
        return <LoanApplication />;
      case "/requisition/procrument":
        return <AddUpdateProcrument />;
      case "/requisition/TD-DA-details":
        return <AddUpdateDetails />;
      // case "/requisition/honorRoll":
      //   return <HonorRollAddUpdate />;
      // case "/requisition/guestEntry":
      //   return <GuestEntry />;
      case "/requisition/employee-evaluation":
        // return isCurrentUserHR ? <EmployeeEvaluation /> : null;
        return isRoleHr && <EmployeeEvaluation />

      // if (!isEmpty(profileInfo) && !profileInfoLoading) {
      //   if (
      //     profileInfo.userRegistrationAddUpdateDto?.roleNames.includes("HR")
      //   ) {
      //     return <EmployeeEvaluation />;
      //   }
      // }
      // return null;
      default:
        return (
          <AddUpdateLeave
            setHideInternalMessage={setHideInternalMessage}
            toDoApprovalGetRow={toDoApprovalGetRow}
          />
        );
    }
  };

  return (
    <div className="sidebars">
      <div
        className={`sidebars__menu ${state.collapsed && "text-align-right"}`}
      >
        <div className="toggle__arrow" onClick={toggleCollapse}>
          {state.collpased ? <img src={maximize} /> : <img src={minimize} />}
        </div>
        <div className="layout">
          <Header name="Approvals" />

          <Row gutter={[8, 8]} style={{ marginTop: "-12px", height: "36%" }}>
            <Col span={24} className="p-0">
              <Menu
                className="sidebars__menu--items"
                style={{ background: "transparent", border: "none" }}
                selectedKeys={[state.current]}
                mode="inline"
                inlineCollapsed={state.collapsed}
              >
                <Menu.Item
                  key={defaultRoute}
                  onClick={handleClick}
                  className="sidebars__menu--item"
                >
                  Leave Application
                </Menu.Item>
                <Menu.Item
                  key="/requisition/loan-application"
                  onClick={handleClick}
                  className="sidebars__menu--item"
                >
                  Loan Application
                </Menu.Item>
                <Menu.Item
                  key="/requisition/guest-entertainment"
                  onClick={handleClick}
                  className="sidebars__menu--item"
                >
                  Guest Entertainment
                </Menu.Item>
                <Menu.Item
                  key="/requisition/procrument"
                  onClick={handleClick}
                  className="sidebars__menu--item"
                >
                  Procurement
                </Menu.Item>
                {/* <Menu.Item
            key='/requisition/news-cutting'
            onClick={handleClick}
            className='sidebars__menu--item'>
            News Cutting
          </Menu.Item> */}
                {/* <Menu.Item
                  key="/requisition/TD-DA-details"
                  onClick={handleClick}
                  className="sidebars__menu--item"
                >
                  TA/DA Details
                </Menu.Item> */}

                <Menu.Item
                  key="/requisition/TD-DA-details"
                  onClick={handleClick}
                  className="sidebars__menu--item"
                >
                  TA/DA Details
                </Menu.Item>

                {/* <Menu.Item
                  key="/requisition/honorRoll"
                  onClick={handleClick}
                  className="sidebars__menu--item"
                >
                  Honor Roll
                </Menu.Item>
                <Menu.Item
                  key="/requisition/guestEntry"
                  onClick={handleClick}
                  className="sidebars__menu--item"
                >
                  Guest Entry
                </Menu.Item> */}
                {/* {isCurrentUserHR && ( */}
                {isRoleHr && (
                  <Menu.Item
                    key="/requisition/employee-evaluation"
                    onClick={handleClick}
                    className="sidebars__menu--item"
                  >
                    Employee Evaluation
                  </Menu.Item>
                )}
                {/* {!isEmpty(profileInfo) &&
                  !profileInfoLoading &&
                  profileInfo.userRegistrationAddUpdateDto?.roleNames.includes(
                    "HR"
                  ) && (
                    <Menu.Item
                      key="/requisition/employee-evaluation"
                      onClick={handleClick}
                      className="sidebars__menu--item"
                    >
                      Employee Evaluation
                    </Menu.Item>
                  )} */}

                {/* <Menu.Item
                  key="/requisition/employee-evaluation"
                  onClick={handleClick}
                  className="sidebars__menu--item"
                >
                  Employee Evaluation
                </Menu.Item> */}
              </Menu>
            </Col>
          </Row>
        </div>
      </div>
      <div className="main__body task__body sidebar-width-calc">
        {renderComponent(history.location.pathname)}
        <ChatDetails
          setShowChat={setShowChat}
          setHideInternalMessage={setHideInternalMessage}
          hideInternalMessage={hideInternalMessage}
          setSmallChatHeight={setSmallChatHeight}
          setChatHeight={setChatHeight}
          chatHeight={chatHeight}
          module="Approvals"
          smallChatHeight={smallChatHeight}
          setchatBox={setchatBox}
          chatBox={chatBox}
          todoDetails={getLeaveDetails}
        />
      </div>
    </div>
  );
}

// export default withBaseLayout(Requisition);
export default Requisition;
