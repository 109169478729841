import React, { useEffect, useState } from "react";
// antd
import {
  Button,
  Form,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
  Space,
  Spin,
  message,
  Tooltip,
  Upload,
  Progress,
  Layout,
} from "antd";
import {
  LoadingOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { indexOf, isEmpty, set, wrap } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  instanceMessageUpdate,
  replyInstanceMessage,
} from "services/redux/email/instanceMessageCenter/action";
import storage from "utils/storage";

// ckdeditor
import { Editor } from "@tinymce/tinymce-react";
import { appBaseUrl, cqBaseUrl } from "utils/config";
import { instanceChatAdd } from "services/redux/instanceChat/action";
import { getAllUsers, uploadFile } from "services/remanentCalls";
import ImageUploaderMultiple from "components/shared/ImageUploaderMultiple";
import {
  actionForCreateGroup,
  actionForGetListingGroup,
  actionForUpdateGroup,
} from "services/redux/createGroup/action";
import ModalForGroupCreateUpdate from "components/modals/modalGroupCreateUpdate";

const { Option } = Select;
const CheckMark = "assets/svg/checkmark-24.svg";
const cancel = "assets/svg/white-cross.svg";
const attachment = "assets/svg/attachment-white.svg";
const threeDot = "assets/svg/3dot-white.svg";
const upChervon = "assets/svg/white-up-chervon.svg";
const send = "assets/svg/send-white.svg";
const aa = "assets/svg/Aa-white.svg";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 14, color: "white" }} spin />
);

const BusinessReplyChat = ({
  setchatBox,
  chatBox,
  allParticipants,
  setChatHeight,
  smallChatHeight,
  setSmallChatHeight,
  chatHeight,
  mailMessageParticipationStatusListing,
  replyMsgDetails,
  replyStatus,
  setReplyStatus,
  emailReferenceId,
  applicationType,
  getDetails,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { groupData, groupDataLoading, groupDataLoadingSuccess } = useSelector(
    (state) => state.createUpdateGroup
  );
  const [form4] = Form.useForm();
  const [editorData, setEditorData] = useState("");
  const [smsText, setSMSText] = useState("");
  const [messageParticipants, setMessageParticipants] = useState([]);
  const [mailMessageCenterType, setMailMessageCenterType] = useState([]);
  const [messageParticipantsCC, setMessageParticipantsCC] = useState([]);
  const [quickMessageParticipantDtosTo, setQuickMessageParticipantDtosTo] =
    useState([]);
  const [quickMessageParticipantDtosCC, setQuickMessageParticipantDtosCC] =
    useState([]);

  const [attatchments, setAttatchments] = useState([]);
  const [filterParticipantForm, setFilterParticipantForm] = useState();
  const [users, setUsers] = useState([]);
  const [progress, setProgress] = useState(0);
  // const [AltEnt, setAltEnt] = useState({});
  const [AltEnt, setAltEnt] = useState(false);
  const [openNewMessage, setOpenNewMessage] = useState(false);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [selectedGroupDataToBeUpdated, setGroupDataToBeUpdate] = useState();
  const [selectTosNewCutGroup, setSelectTosNewCutGroup] = useState();
  const [selectCcsNewCutGroup, setSelectCcsNewCutGroup] = useState();

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );

  const instanceChatCenter = useSelector((state) => state.instanceChatCenter);

  const {
    instanceChatCenterPagination,
    instanceChatCenterPaginationLoading,
    instanceChatAddResp,
    instanceChatAddRespLoading,
  } = instanceChatCenter;

  useEffect(() => {
    if (!isEmpty(users) && !isEmpty(replyMsgDetails)) {
      let fromEmailAddresss = users[0]?.appUserId; //.employeeEmailDtos[0]
      let replyFrom =
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
          .participantUserDto.appUserId;

      if (fromEmailAddresss === replyFrom) {
        setFilterParticipantForm(true);
      }
    }
  }, [users, replyMsgDetails]);

  useEffect(() => {
    if (isEmpty(messageParticipants)) {
      setMessageParticipants(allParticipants);
      setMessageParticipantsCC(allParticipants);
    }
  }, [allParticipants]);

  const fetchAllParticipants = async () => {
    const resp = await getAllUsers().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      let participantFrom = storage.getItem("userId");
      let allUsers = resp.data.filter(
        (obj) => obj.appUserId === participantFrom
      );

      setUsers(allUsers);
    }
  };

  useEffect(() => {
    fetchAllParticipants();
  }, []);

  const uploadPhotos = async ({
    action,
    file,
    filename,
    onError,
    onProgress,
    onSuccess,
  }) => {
    const resp = await uploadFile({
      action,
      file,
      filename,
      onError,
      onProgress,
      onSuccess,
      setProgress,
    }).catch((err) => {
      message.error("Error while uploading");
      onError(err);
    });
    if (resp?.status === 200) {
      let alteredResponse = {
        ...resp.data[0],
        uploadedFileDomainMappingDto: {
          isPublic: true,
        },
        uploadedFileDomainPermisionDtos: [],
      };
      setAttatchments(alteredResponse);
      let olduploadedFileDtos = form.getFieldValue().uploadedFileDtos
        ? [...form.getFieldValue().uploadedFileDtos]
        : [];

      let newUploadedFileDtos = [...olduploadedFileDtos];
      newUploadedFileDtos.push(alteredResponse);
      // uploadedFileDtos[i] = e;

      form.setFieldsValue({
        uploadedFileDtos: newUploadedFileDtos,
      });
      // onSuccess(resp.data[0], file);
    }
    // return resp.data[0];
  };

  const changeParticipantsDtosTo = (option) => {
    let newQuickMessageParticipantDtos = option.map((participant) => ({
      participantUserId: participant.value,
    }));
    setQuickMessageParticipantDtosTo(newQuickMessageParticipantDtos);

    let newMessagePariticipants = [];
    allParticipants.map((participant) => {
      let checkSelected = false;
      newQuickMessageParticipantDtos.map((selectedParticipant) => {
        if (selectedParticipant.participantUserId === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipants.push(participant);
      }
    });

    setMessageParticipantsCC(newMessagePariticipants);
  };

  const changeParticipantsDtosCC = (option) => {
    let newQuickMessageParticipantDtos = option.map((participant) => ({
      participantUserId: participant.value,
    }));
    setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);

    let newMessagePariticipants = [];
    allParticipants.map((participant) => {
      let checkSelected = false;
      newQuickMessageParticipantDtos.map((selectedParticipant) => {
        if (selectedParticipant.participantUserId === participant.appUserId) {
          checkSelected = true;
        }
      });

      if (!checkSelected) {
        newMessagePariticipants.push(participant);
      }
    });

    setMessageParticipants(newMessagePariticipants);
  };

  useEffect(() => {
    if (!isEmpty(mailMessageParticipationStatusListing)) {
      let newMailMessageCenterType =
        mailMessageParticipationStatusListing.filter(
          (status) =>
            status.systemName === "Normal" ||
            status.systemName === "Pending" ||
            status.systemName === "Archived"
        );

      setMailMessageCenterType(newMailMessageCenterType);
    }
  }, [mailMessageParticipationStatusListing]);

  useEffect(() => {
    setchatBox(false);
  }, []);

  useEffect(() => {
    if (!isEmpty(instanceChatAddResp)) {
      setchatBox(false);
      setChatHeight(false);

      setTimeout(() => {
        form.resetFields();
        setEditorData(" ");
      }, 2000);
    }
  }, [instanceChatAddResp]);

  //Aplication type is not defined here so error
  // console.log("applicationType", applicationType);

  const onFinish = async (centerTypeStatus, msgCat) => {
    let values = await form.validateFields();
    // console.log("applicationType", applicationType);
    let body = {
      messageText: editorData,
      mailMessageParticipantWithTypeDtos: {
        participantTos: !isEmpty(form.getFieldValue().participantTos)
          ? quickMessageParticipantDtosTo
          : null,
        participantCCs: !isEmpty(form.getFieldValue().participantCCs)
          ? quickMessageParticipantDtosCC
          : null,
      },
      applicationId: emailReferenceId,
      applicationTypeId: applicationType,
      instanceMessageStatusName: centerTypeStatus,
      instanceMessageActionName: replyStatus
        ? replyStatus === "replyall"
          ? "reply"
          : replyStatus
        : "reply",
      mailMessageReferenceDtos: [{ referenceCode: getDetails?.referenceCode }],
      replyOfMessageCenterId: replyMsgDetails ? replyMsgDetails.id : null,
      instanceMessageAttachmentDtos: values.uploadedFileDtos || [],
      ...(msgCat === "sms" && {
        messagePlainText: smsText,
        isComposeSMS: true,
      }),
    };
    if (!isEmpty(instanceChatCenterPagination)) {
      body.mailMessageCenterId =
        instanceChatCenterPagination?.items[0]?.mailMessageCenterId;
    }
    dispatch(instanceChatAdd(body, false, setOpenNewMessage));
  };

  useEffect(() => {
    if (replyStatus) {
      form.resetFields();
      setchatBox(true);
      let userId = storage.getItem("userId");
      let participantTypeTos =
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantTos.map(
          (typeTos) => {
            return { participantUserId: typeTos.participantUserId };
          }
        );
      let participantTypeCCs = !isEmpty(
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantCCs
      )
        ? replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantCCs.map(
            (typeTos) => {
              return { participantUserId: typeTos.participantUserId };
            }
          )
        : [];

      let participantTypeFrom = [
        {
          participantUserId:
            replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
              .participantUserId,
        },
      ];

      let filterParticipantTos = participantTypeFrom;
      let filterParticipantCcs = participantTypeCCs.filter(
        (filteredCcs) => filteredCcs.participantUserId !== userId
      );
      let newQuickMessageParticipantDtos = participantTypeTos.filter(
        (filterTos) => filterTos.participantUserId !== userId
      );
      newQuickMessageParticipantDtos =
        newQuickMessageParticipantDtos.concat(filterParticipantCcs);

      if (
        replyMsgDetails?.mailMessageParticipantWithTypeDtos?.participantFrom
          .participantUserId == userId
      ) {
        //All the To and CC Remains the same
        filterParticipantTos = participantTypeTos.filter(
          (filterTos) => filterTos.participantUserId !== userId
        );
        let filterParticipantCcs = participantTypeCCs.filter(
          (filteredCcs) => filteredCcs.participantUserId !== userId
        );
        newQuickMessageParticipantDtos = filterParticipantCcs;
      }

      if (replyStatus !== "edit" && replyStatus !== "forward") {
        form.setFieldsValue({
          participantTos: filterParticipantTos.map((typeTos) => {
            return typeTos.participantUserId;
          }),
          // participantTos: [],

          // participantCCs: newQuickMessageParticipantDtos.map((typeTos) => {
          //   return typeTos.participantUserId;
          // }),
        });

        setQuickMessageParticipantDtosTo(filterParticipantTos);
        // setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);
      }
      if (replyStatus === "replyall") {
        form.setFieldsValue({
          participantCCs: newQuickMessageParticipantDtos.map((typeTos) => {
            return typeTos.participantUserId;
          }),
        });

        setQuickMessageParticipantDtosCC(newQuickMessageParticipantDtos);
      } else if (replyStatus === "forward") {
        form.setFieldsValue({
          // participantTos: null,
          // participantCCs: null,
          participantTos: [],
          participantCCs: [],
        });
      } else if (replyStatus === "edit") {
        form.setFieldsValue({
          participantTos: participantTypeTos.map((typeTos) => {
            return typeTos.participantUserId;
          }),
          participantCCs: !isEmpty(participantTypeCCs)
            ? participantTypeCCs.map((typeTos) => {
                return typeTos.participantUserId;
              })
            : null,
        });

        setQuickMessageParticipantDtosTo(participantTypeTos);
        setQuickMessageParticipantDtosCC(participantTypeCCs);
      }
      if (replyStatus === "forward" || replyStatus === "edit") {
        let data =
          replyStatus === "forward"
            ? `This is forwaded message of ${participantTypeFrom?.participantUserDto?.userName} \n ------- ${replyMsgDetails.messageText}`
            : replyMsgDetails.messageText;
        setEditorData(data);
      } else {
        setEditorData("");
      }
    } else if (!isEmpty(getDetails)) {
      setEditorData("");

      // let newQuickMessageParticipantDtos = [
      //   {
      //     participantUserId: getDetails.primaryInchargeId,
      //   },
      // ];
      // setQuickMessageParticipantDtosTo(newQuickMessageParticipantDtos);

      // let newQuickMessageParticipantCcDtos =
      //   getDetails.tenderBasicSecondaryInchargeDtos?.map((participant) => ({
      //     participantUserId: participant.secondaryInchargeId,
      //   }));

      // setQuickMessageParticipantDtosCC(newQuickMessageParticipantCcDtos);

      // let tOs = [];
      // allParticipants?.find((x) => {
      //   if (x?.appUserId === getDetails.primaryInchargeId) {
      //     tOs.push(getDetails.primaryInchargeId);
      //   }
      // });

      // let cCs = [];
      // allParticipants?.forEach((x) => {
      //   getDetails?.tenderBasicSecondaryInchargeDtos?.map((y) => {
      //     if (x?.appUserId === y.secondaryInchargeId) {
      //       cCs.push(y);
      //     }
      //   });
      // });

      // form.setFieldsValue({
      //   // participantTos: [getDetails.primaryInchargeId],
      //   // participantTos: getDetails.primaryInchargeId,
      //   // participantCCs: getDetails?.tenderBasicSecondaryInchargeDtos?.map(
      //   //   (participant) => participant.secondaryInchargeId
      //   // ),
      //   participantTos: tOs,
      //   participantCCs: cCs?.map(
      //     (participant) => participant.secondaryInchargeId
      //   ),
      // });
    }
  }, [replyMsgDetails, replyStatus, filterParticipantForm, getDetails]);

  const centerTypeMenu = () => (
    // <Menu>
    //   {/* <Menu.Item>AsPending</Menu.Item>
    //   <Menu.Item>AsArchieved</Menu.Item> */}
    // </Menu>
    <></>
  );

  // alt&Enter
  useEffect(() => {
    // if (AltEnt?.alt && AltEnt?.enter) {
    //   let newMailMessageCenterType = mailMessageCenterType.filter(
    //     (type) => type.systemName === "Normal"
    //   );
    //   onFinish(newMailMessageCenterType[0].systemName, "");
    // }
    // if (AltEnt?.length !== 0) {
    //   setTimeout(() => {
    //     setAltEnt([]);
    //   }, 5000);
    // }
    if (AltEnt) {
      const newMailMessageCenterTypo = mailMessageCenterType.filter(
        (type) => type.systemName === "Normal"
      );
      onFinish(newMailMessageCenterTypo?.[0].systemName);
      setAltEnt(false);
    }
  }, [AltEnt]);

  const menu = () => (
    <Menu>
      <Menu.Item
        key="Send as SMS"
        onClick={() => {
          let newMailMessageCenterType = mailMessageCenterType.filter(
            (type) => type.systemName === "Normal"
          );
          onFinish(newMailMessageCenterType[0].systemName, "sms");
        }}
      >
        Send as SMS
      </Menu.Item>
    </Menu>
  );

  //Predefined User Data

  const menuGroupCreate = () => (
    <Menu style={{ maxHeight: 250, overflow: "auto" }}>
      <Menu.Item
        key="Create Group"
        onClick={() => {
          setCreateGroupModal(true);
          setGroupDataToBeUpdate();
          form4.resetFields();
        }}
      >
        Create Group
      </Menu.Item>

      {groupData &&
        groupData?.map((x) => {
          return (
            <Menu.Item
              key={x?.id}
              onClick={() => {
                let userIdsForTo = [];
                let userIdsForCc = [];
                x?.userList?.map((x) => {
                  if (x?.participantTypeDto?.id === 0) {
                    userIdsForTo.push(x?.userId);
                  } else {
                    userIdsForCc.push(x?.userId);
                  }
                });
                setSelectTosNewCutGroup(userIdsForTo);
                setSelectCcsNewCutGroup(userIdsForCc);
              }}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{x?.groupName}</div>

              <EditOutlined
                style={{ paddingLeft: 15 }}
                onClick={() => {
                  setGroupDataToBeUpdate(x);
                  setCreateGroupModal(true);
                }}
              />
            </Menu.Item>
          );
        })}
    </Menu>
  );
  let paramsForGroupListing = "SkipCount=0&MaxResultCount=100";
  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, []);

  useEffect(() => {
    dispatch(actionForGetListingGroup(paramsForGroupListing));
  }, [selectedGroupDataToBeUpdated, createGroupModal]);

  useEffect(() => {
    if (!isEmpty(selectTosNewCutGroup)) {
      let participantTos = selectTosNewCutGroup?.map((x) => {
        return {
          participantUserId: x,
        };
      });
      let participantCCs = selectCcsNewCutGroup?.map((x) => {
        return {
          participantUserId: x,
        };
      });
      setQuickMessageParticipantDtosTo(participantTos);
      setQuickMessageParticipantDtosCC(participantCCs);
      form.setFieldsValue({
        participantTos: selectTosNewCutGroup,
        participantCCs: selectCcsNewCutGroup,
      });
    } else {
      form.setFieldsValue({
        participantTos: [],
        participantCCs: [],
      });
    }
  }, [selectTosNewCutGroup]);

  useEffect(() => {
    if (!isEmpty(selectedGroupDataToBeUpdated)) {
      form4.setFieldsValue({
        groupName: selectedGroupDataToBeUpdated?.groupName,
        userList: selectedGroupDataToBeUpdated?.userList?.map((x) => {
          return x?.userId;
        }),
      });
    }

    if (groupDataLoadingSuccess) {
      setCreateGroupModal(false);
      setSelectTosNewCutGroup();
    }
  });

  const onGroupCreateFinish = (values) => {
    const tos = values?.userListTo?.map((x) => {
      return {
        userId: x,
        participantTypeId: 0,
      };
    });
    const ccs = values?.userListCc?.map((x) => {
      return {
        userId: x,
        participantTypeId: 1,
      };
    });
    const to_cc = [...tos, ...ccs];

    let apiDataForGroupCreation = {
      groupName: values?.groupName,
      userList: to_cc,
      isActive: true,
    };

    if (isEmpty(selectedGroupDataToBeUpdated)) {
      dispatch(actionForCreateGroup(apiDataForGroupCreation));
    } else {
      apiDataForGroupCreation = {
        ...apiDataForGroupCreation,
        id: selectedGroupDataToBeUpdated?.id,
      };
      dispatch(
        actionForUpdateGroup(
          apiDataForGroupCreation,
          selectedGroupDataToBeUpdated?.id
        )
      );
    }
  };

  return (
    <>
      {chatBox ? (
        <div
          className={`chat--messageBox ${smallChatHeight && "smallChatHeight"}`}
        >
          <div className="reply-Of chat-box-head-wrapper wrapper1">
            <div className="left-chat-action-btn">
              <div className="chat-box-send-wrapper">
                {instanceChatAddRespLoading ? (
                  antIcon
                ) : (
                  <Space>
                    {/* <Dropdown.Button
                      overlay={centerTypeMenu}
                      onClick={() => {
                        let newMailMessageCenterType =
                          mailMessageCenterType.filter(
                            (type) => type.systemName === "Normal"
                          );
                        onFinish(newMailMessageCenterType[0].systemName);
                      }}
                    >
                    <img src={send} className="send here" alt="send" />

                    </Dropdown.Button> */}
                    <Layout.Content
                      overlay={centerTypeMenu}
                      onClick={() => {
                        let newMailMessageCenterType =
                          mailMessageCenterType.filter(
                            (type) => type.systemName === "Normal"
                          );
                        onFinish(newMailMessageCenterType[0].systemName);
                      }}
                    >
                      <img src={send} alt="send" />
                    </Layout.Content>

                    {/* </Dropdown.Button> */}
                    <Dropdown.Button overlay={menu}></Dropdown.Button>
                    <Dropdown.Button
                      overlay={menuGroupCreate}
                      icon={<UsergroupAddOutlined />}
                    ></Dropdown.Button>
                  </Space>
                )}
              </div>
            </div>
            <div className="chat-action-btn">
              {/* <div className="chat-box-editor-header-btn-wrapper">
          <div
            className="btn__chat-editor-header"
            onClick={() => {
              setEditorHeader(!showEditorHeader);
            }}
          >
            <img src={aa} />
          </div>
        </div> */}
              <div className="chat-box-attachment-bth-wrapper">
                <div className="attachment-img-wrapper">
                  <Upload
                    name="uploadedFiles"
                    itemRender={(files) => {
                      <div style={{ display: "none" }}>{files}</div>;
                    }}
                    action={`${appBaseUrl}/file/uploadFile/`}
                    customRequest={uploadPhotos}
                  >
                    <img src={attachment} />
                  </Upload>
                </div>
              </div>
              <div
                className="btn__chat--cancel"
                onClick={() => {
                  setchatBox(false);
                  setChatHeight(false);
                  setReplyStatus("");
                  //   setReplyofChat("");
                  //   setReplyStatus("");
                  //   setReplyChatHeight(false);
                  //   setActiveChat();
                  //   setSmallChatHeight(false);
                }}
              >
                <Tooltip title="close">
                  <img src={cancel} />
                </Tooltip>
              </div>
              <div
                className={`btn__chat--upChervon`}
                // onClick={() => {
                //   setReplyChatHeight(!replyChatheight);
                //   setActiveChat("");
                //   setSmallChatHeight(replyChatheight ? true : false);
                // }}
                onClick={() => {
                  chatHeight ? setChatHeight(false) : setChatHeight(true);
                  setSmallChatHeight(!smallChatHeight);
                }}
              >
                <img src={upChervon} />
              </div>
            </div>
          </div>
          <Form
            name="replyChatBox"
            onFinish={onFinish}
            onFinishFailed={(info) => console.log(info)}
            autoComplete="off"
            form={form}
          >
            <div
            //   className={`replyChatHeight ${replyChatheight ? "active" : ""} `}
            >
              <Col span={24}>
                <div className="instant-msg-box">
                  <div className="box-type">To</div>
                  <Form.Item
                    className="form__group"
                    name="participantTos"
                    rules={[{ required: true, message: "" }]}
                    style={{ width: "100%" }}
                  >
                    <Select
                      // bordered={false}
                      mode="multiple"
                      // autoFocus={true}
                      placeholder="Internal To"
                      onChange={(value, option) =>
                        changeParticipantsDtosTo(option)
                      }
                      options={messageParticipants?.map((x) => {
                        return {
                          label: `${x?.fullName} [${x?.userName}]`,
                          value: x?.appUserId,
                        };
                      })}
                      optionFilterProp="label"
                    >
                      {/* {messageParticipants?.map((participant, j) => (
                        <Option
                          key={j}
                          value={participant.appUserId}
                          // label={[participant.fullName, participant.userName]}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">
                              {participant.fullName} {"" + ""}
                            </span>
                            [{participant.userName.toUpperCase()}]
                          </div>
                        </Option>
                      ))} */}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className="instant-msg-box">
                  <div className="box-type type1">Cc</div>
                  <Form.Item
                    className="form__group"
                    name="participantCCs"
                    rules={[{ required: false }]}
                    style={{ width: "100%" }}
                  >
                    <Select
                      bordered={false}
                      mode="multiple"
                      placeholder="Internal CC"
                      options={messageParticipantsCC?.map((x) => {
                        return {
                          label: `${x?.fullName} [${x?.userName}]`,
                          value: x?.appUserId,
                        };
                      })}
                      onChange={(value, option) =>
                        changeParticipantsDtosCC(option)
                      }
                      optionFilterProp="label"
                    >
                      {/* {messageParticipantsCC.map((participant, j) => (
                        <Option
                          key={j}
                          value={participant.appUserId}
                          label={[participant.fullName, participant.userName]}
                        >
                          <div className="demo-option-label-item">
                            <span role="img">{participant.fullName}</span>[
                            {participant.userName}]
                          </div>
                        </Option>
                      ))} */}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col>
                {!isEmpty(form.getFieldValue().uploadedFileDtos) ? (
                  <div className="instant-msg-box">
                    {/* <div className="box-type">
                    <img src={attachment} />
                  </div> */}
                    {progress > 0 ? <Progress percent={progress} /> : null}
                    <div className="uploadImg" style={{ width: "87%" }}>
                      <Form.Item
                        name={["uploadedFileDtos"]}
                        className="form__group"
                        // name={u.id}
                        // label={u.name}
                        rules={[{ required: false }]}
                      >
                        <ImageUploaderMultiple
                          name="emailMessageAttachmentDtos"
                          isMultipleFileAllowed={true}
                          id=""
                          uploadedFileDtos={form.getFieldValue()}
                          formName="uploadedFileDtos"
                          form={form}
                        />
                      </Form.Item>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col span={24}>
                {/* <Form.Item name="description" className="form__group" label="Message"> */}
                {/* <Editor
              initialValue="<p>This is the initial content of the editor</p>"
              apiKey="zlyurpa97iti4svx0l8rc4nwgnqpm0s9459csq8ic2bxuo8f"
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
              }}
              onEditorChange={(event, editor) => {
                const data = editor.getData();
                setEditorData(data);
              }}
            /> */}

                <div
                  className={`replyChatBox`}
                  style={{ position: "relative", width: "100%" }}
                >
                  <Editor
                    value={editorData}
                    // toolbar_location="bottom"
                    onKeyDown={(e) => {
                      // let bbb = {};
                      // if (e.key === "Alt") {
                      //   bbb = {
                      //     ...AltEnt,
                      //     alt: e.key,
                      //   };
                      //   setAltEnt(bbb);
                      // }
                      // if (e.key === "Enter") {
                      //   bbb = {
                      //     ...AltEnt,
                      //     enter: e.key,
                      //   };
                      //   setAltEnt(bbb);
                      // }
                      const { key, altKey } = e;
                      if (altKey && key === "Enter") {
                        setAltEnt(true);
                      }
                    }}
                    init={{
                      plugins: "lists advlist",
                      browser_spellcheck: true,
                      menubar: "false",
                      toolbar: "", //bold  italic underLine  | backcolor forecolor   |  bullist numlist

                      content_style: "p{margin:0px}",
                    }}
                    onEditorChange={(e, editor) => {
                      setEditorData(e);
                      const plainText = editor.getContent({ format: "text" });
                      setSMSText(plainText);
                    }}
                  />
                </div>
              </Col>
            </div>
          </Form>
        </div>
      ) : (
        <div
          className="chatmail__imag chat-button-wrapper"
          style={{
            position: "absolute",
            bottom: 0,
            display: "flex",
            flexFlow: "row wrap",
            alignItems: "center",
            height: "15%",
            background: "#fff",
            width: "100%",
            display: "none",
          }}
        ></div>
      )}
      <ModalForGroupCreateUpdate
        createGroupModal={createGroupModal}
        setCreateGroupModal={setCreateGroupModal}
        selectedGroupDataToBeUpdated={selectedGroupDataToBeUpdated}
        onGroupCreateFinish={onGroupCreateFinish}
        form4={form4}
        messageParticipants={messageParticipants}
        groupDataLoading={groupDataLoading}
      />
    </>
  );
};

export default BusinessReplyChat;
