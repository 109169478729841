import {
  Avatar,
  Col,
  Divider,
  Layout,
  List,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

const { Content } = Layout;

const { Title } = Typography;

const titleHeight = {
  backgroundColor: "rgb(216, 221, 235)",
  height: "30px",
  padding: "3px 0px 0px 6px",
  margin: "0px",
};

export default function OnGoing({
  setJobApplcationId,
  onGoingApplicationData,
  activeIndex,
  setActiveIndex,
  applicationAllDataLoading,
}) {
  //Just for Checking for Infinite Scroll

  // const [data, setData] = useState(
  //   Array.from({ length: 5 }, (_, i) => ({
  //     name: `Name ${i + 1}`,
  //     avatar: `https://picsum.photos/50?random=${i}`,
  //   }))
  // );

  // const fetchData = async () => {
  //   // simulate API call delay
  //   // await new Promise((resolve) => setTimeout(resolve, 1000));
  //   const newData = Array.from({ length: 5 }, (_, i) => ({
  //     name: `Name ${data.length + i + 1}`,
  //     avatar: `https://picsum.photos/50?random=${data.length + i}`,
  //   }));
  //   setData([...data, ...newData]);
  // };

  //end Checking

  //render Item for List

  // const renderItem = (item, index) => {
  //   const isActive = activeIndex === item.id;
  //   const itemStyle = {
  //     background: isActive ? "#40a9ff" : "none",
  //     height: "fit-content",
  //   };

  //   return (
  //     <List.Item
  //       onClick={() => {
  //         setActiveIndex(item?.id);
  //         setJobApplcationId(item?.id);
  //       }}
  //       style={itemStyle}
  //     >
  //       <List.Item.Meta
  //         // className="hrSystem_sidebody_list"
  //         // avatar={<Avatar src={item.avatar} />}
  //         title={
  //           <Tooltip title={item.jobTitle}>
  //             <Row
  //               style={{
  //                 marginLeft: 20,
  //                 fontSize: 11,
  //               }}
  //             >
  //               <Col
  //                 offset={2}
  //                 xs={22}
  //                 md={22}
  //                 lg={12}
  //                 style={{
  //                   overflow: "hidden",
  //                   textOverflow: "ellipsis",
  //                   whiteSpace: "nowrap",
  //                 }}
  //               >
  //                 {item.jobTitle}
  //               </Col>
  //               <Col offset={2} xs={24} md={24} lg={8}>
  //                 {moment(item.closingDate).format("YYYY-MM-DD")}
  //               </Col>
  //             </Row>
  //           </Tooltip>
  //         }
  //         // description={`Description for ${item.categoryName}`}
  //       />
  //     </List.Item>
  //   );
  // };

  //end render Item for List

  // console.log("applicationAllDataLoading", applicationAllDataLoading);

  const jobTitleDate = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
    <>
      <Title level={5} style={titleHeight}>
        Ongoing
      </Title>
      {applicationAllDataLoading || applicationAllDataLoading === undefined ? (
        <Skeleton />
      ) : (
        <Content style={{ height: "280px", overflowY: "scroll" }}>
          {/* <InfiniteScroll
          dataLength={data.length}
          next={fetchData}
          hasMore={true}
          loader={<Skeleton />}
        > */}
          {/* <List dataSource={onGoingApplicationData} renderItem={renderItem} /> */}
          {onGoingApplicationData?.map((item, index) => {
            return (
              <div
                style={{
                  // background: activeIndex === item.id ? "#67a1e4 " : "#f2f2f2",
                  background: activeIndex === item.id && "#67a1e4",
                }}
                className="hr_System_sideBody_row"
                key={item?.id}
              >
                <Tooltip title={item.jobTitle}>
                  <Row
                    className={`hrSystem_sidebody_list ${
                      activeIndex === item.id ? "active" : ""
                    }`}
                    onClick={() => {
                      setActiveIndex(item?.id);
                      setJobApplcationId(item?.id);
                    }}
                    style={{
                      marginLeft: 20,
                      fontWeight: activeIndex === item.id ? 400 : "normal",
                      fontSize: 12,
                      height: 28,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      offset={2}
                      xs={22}
                      md={22}
                      lg={12}
                      style={{
                        ...jobTitleDate,
                        color: activeIndex === item.id ? "white" : "black",
                      }}
                    >
                      {item.jobTitle}
                    </Col>
                    <Col
                      offset={2}
                      xs={24}
                      md={24}
                      lg={8}
                      style={{
                        ...jobTitleDate,
                        color: activeIndex === item.id ? "white" : "black",
                      }}
                    >
                      {/* {moment(item.closingDate).format("YYYY-MM-DD")} */}
                      {moment(item.closingDate).format("DD-MMM-YYYY")}
                    </Col>
                  </Row>
                </Tooltip>
                {/* <Divider style={{ margin: "-1px 0px 5px 0px" }} /> */}
              </div>
            );
          })}
          {/* </InfiniteScroll> */}
        </Content>
      )}
    </>
  );
}
