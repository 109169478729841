import { ReferenceOthersService } from "./api";
import { REFERENCE__TYPES } from "../../../types";
import { init, success, finish, error } from "services/common";
import { message } from "antd";
import queryString from "query-string";

const referenceOthersService = new ReferenceOthersService();

// export const getProjectSearchPagination = (body, forPagination) => {
// 	return async (dispatch) => {
// 		dispatch(init(REFERENCE__TYPES.PROJECT_PAGINATION));
// 		const response = await projectService.projectPagination(body);
// 		dispatch(finish(REFERENCE__TYPES.PROJECT_PAGINATION));
// 		if (response.isSuccess) {
// 			if (forPagination) {
// 				dispatch(success(REFERENCE__TYPES.PROJECT_PAGINATION_CHANGE, response.data));
// 			} else {
// 				dispatch(success(REFERENCE__TYPES.PROJECT_PAGINATION, response.data));
// 			}
// 		} else if (!response.isSuccess) {
// 			dispatch(error(response.errorMessage));
// 		}
// 	};
// };

export const getOthersSearchPagination = (query, loadMore) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_OTHERS_PAGINATION));
    const response = await referenceOthersService.referenceOthersPagination(
      query
    );
    dispatch(finish(REFERENCE__TYPES.REFERENCE_OTHERS_PAGINATION));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_OTHERS_PAGINATION,
          response.data,
          loadMore
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const referenceOthersGetDetails = (id) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_OTHERS_GetDetails));
    const response = await referenceOthersService.referenceOthersGetDetails(id);
    dispatch(finish(REFERENCE__TYPES.REFERENCE_OTHERS_GetDetails));
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_OTHERS_GetDetails, response.data)
      );
      // dispatch(contactCompanyPersonSearchPagination());
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const referenceOthersAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_OTHERS_ADD));
    const response = await referenceOthersService.referenceOthersAdd(body);
    if (response.isSuccess) {
      dispatch(success(REFERENCE__TYPES.REFERENCE_OTHERS_ADD, response.data));
      message.success("Successfully added reference");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.REFERENCE_OTHERS_ADD));
  };
};

export const referenceOthersUpdate = (body) => {
  // console.log("actionss", body);
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_OTHERS_UPDATE));
    const response = await referenceOthersService.referenceOthersUpdate(body);

    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_OTHERS_UPDATE, response.data)
      );
      message.success(`Successfully updated Reference Others`);
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.REFERENCE_OTHERS_UPDATE));
  };
};

export const referenceOthersFavAddUpdate = (body, record) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_OTHERS_IS_FAV));
    const response = await referenceOthersService.referenceOthersFavAddUpdate(
      body
    );
    dispatch(finish(REFERENCE__TYPES.REFERENCE_OTHERS_IS_FAV));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_OTHERS_IS_FAV,
          response.data,
          "",
          record
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const ReferenceOthersPaginationWithRootLevel = (query, loadMore) => {
  // console.log("loadmoressss", loadMore);
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_OTHERS_ROOTLEVEL));
    const response =
      await referenceOthersService.referenceOthersPaginationWithRootLevel(
        query
      );
    dispatch(finish(REFERENCE__TYPES.REFERENCE_OTHERS_ROOTLEVEL));
    if (response.isSuccess) {
      dispatch(
        success(
          REFERENCE__TYPES.REFERENCE_OTHERS_ROOTLEVEL,
          response.data,
          loadMore
        )
      );
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
  };
};

export const referenceOthersCombineAdd = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_OTHERS__COMBINED_ADD));
    const response = await referenceOthersService.referenceOthersCombineAdd(
      body
    );
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_OTHERS__COMBINED_ADD, response.data)
      );
      message.success("Successfully added refernce");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.REFERENCE_OTHERS__COMBINED_ADD));
  };
};

export const referenceOthersCombineUpdate = (body) => {
  return async (dispatch) => {
    dispatch(init(REFERENCE__TYPES.REFERENCE_OTHERS_COMBINE_UPDATE));
    const response = await referenceOthersService.referenceOthersCombineUpdate(
      body
    );
    if (response.isSuccess) {
      dispatch(
        success(REFERENCE__TYPES.REFERENCE_OTHERS_COMBINE_UPDATE, response.data)
      );
      message.success("Successfully updated refernce");
    } else if (!response.isSuccess) {
      dispatch(error(response.errorMessage));
    }
    dispatch(finish(REFERENCE__TYPES.REFERENCE_OTHERS_COMBINE_UPDATE));
  };
};
