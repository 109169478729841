import { ORGANIZER_TYPES } from "../../../types";

const INITIAL_STATE = {
  allGuestEvents: [],
  guestEventsLoading: false,

  addGuestEventResp: [],
  addGuestEventRespLoading: false,

  guestEventStatuses: [],
  guestEventStatusesLoading: false,
};

export default function guestEventsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Guest events
    case ORGANIZER_TYPES.GUEST_EVENTS_INIT:
      return {
        ...state,
        allGuestEvents: [],
        guestEventsLoading: true,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_SUCCESS:
      return {
        ...state,
        allGuestEvents: action.payload,
        guestEventsLoading: false,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_FINISH:
      return {
        ...state,
        guestEventsLoading: false,
      };

    // Guest events statuses
    case ORGANIZER_TYPES.GUEST_EVENTS_STATUSES_INIT:
      return {
        ...state,
        guestEventStatuses: [],
        guestEventStatusesLoading: true,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_STATUSES_SUCCESS:
      return {
        ...state,
        guestEventStatuses: action.payload,
        guestEventStatusesLoading: false,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_STATUSES_FINISH:
      return {
        ...state,
        guestEventStatusesLoading: false,
      };

    // Guest events add
    case ORGANIZER_TYPES.ADD_GUEST_EVENTS_INIT:
      return {
        ...state,
        addGuestEventResp: [],
        addGuestEventRespLoading: true,
      };
    case ORGANIZER_TYPES.ADD_GUEST_EVENTS_SUCCESS:
      return {
        ...state,
        addGuestEventResp: action.payload,
        addGuestEventRespLoading: false,
      };
    case ORGANIZER_TYPES.ADD_GUEST_EVENTS_FINISH:
      return {
        ...state,
        addGuestEventRespLoading: false,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_LISTING_INIT:
      return {
        ...state,
        allGuestEventsListingResponse: [],
        allGuestEventsListingResponseLoading: true,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_LISTING_SUCCESS:
      return {
        ...state,
        allGuestEventsListingResponse: action.payload,
        allGuestEventsListingResponseLoading: false,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_LISTING_FINISH:
      return {
        ...state,
        allGuestEventsListingResponseLoading: false,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_APPROVAL_LISTING_INIT:
      return {
        ...state,
        allGuestEventsApprovalListingResponse: [],
        allGuestEventsApprovalListingResponseLoading: true,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_APPROVAL_LISTING_SUCCESS:
      return {
        ...state,
        allGuestEventsApprovalListingResponse: action.payload,
        allGuestEventsApprovalListingResponseLoading: false,
      };
    case ORGANIZER_TYPES.GUEST_EVENTS_APPROVAL_LISTING_FINISH:
      return {
        ...state,
        allGuestEventsApprovalListingResponseLoading: false,
      };
    default:
      return { ...state };
  }
}
