import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { referenceOthersAdd } from "services/redux/Reference/Others/action";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

function QuickAddSecondaryTag(props) {
  const { form, close, name } = props;
  const dispatch = useDispatch();
  const [quickAddSecondaryForm] = Form.useForm();
  const [isQuickAddSecondaryTagLoading, setIsQuickAddSecondaryTagLoading] =
    useState(false);

  const { referenceOthersAddResponse, referenceOthersAddLoading } = useSelector(
    (state) => state.referenceOthers
  );

  useEffect(() => {
    if (!isEmpty(name)) {
      quickAddSecondaryForm.setFieldsValue({
        quickAddsecondary: name,
      });
    }
  }, [quickAddSecondaryForm, props?.name]);

  const onFinish = (values) => {
    setIsQuickAddSecondaryTagLoading(true);
    try {
      let body = {
        title: values?.quickAddsecondary,
        description: values?.quickAddSecondaryDescription,
      };
      dispatch(referenceOthersAdd(body));
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsQuickAddSecondaryTagLoading(false);
      quickAddSecondaryForm.resetFields();
      form.setFieldsValue({
        titleSecondary: values?.quickAddsecondary,
        description: values?.quickAddSecondaryDescription,
      });
      close();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("errorInfo: ", errorInfo);
  };

  return (
    <div>
      <Form
        name="basic"
        form={quickAddSecondaryForm}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        // initialValues={{
        //   remember: true,
        // }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Secondary Tag"
          name="quickAddsecondary"
          rules={[
            {
              required: true,
              message: "Please input your tag!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="quickAddSecondaryDescription"
          rules={[
            {
              required: true,
              message: "Please input your description!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={isQuickAddSecondaryTagLoading}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default QuickAddSecondaryTag;
