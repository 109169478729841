import React, { useState, useEffect, useCallback } from "react";
import { isEmpty } from "lodash-es";

// antd
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Table,
  Typography,
} from "antd";
import { EditFilled } from "@ant-design/icons";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  AuditOutlined,
  MailOutlined,
} from "@ant-design/icons";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  changeEmailStatusType,
  changeMultipleEmailStatusType,
  draftMailExistOrNot,
  resetOutboxMailFailureCount,
} from "services/redux/email/mailMessageCenter/action";
import { Link } from "react-router-dom";
import { mailReferenceGetDetails } from "services/redux/email/referenceTags/action";

//includes
import { BusinessModal } from "./BusinessModal";
import { multipleEmailSelectionAction } from "services/redux/email/multipleEmailSelection/action";
import {
  // emailAutoReferenceAdd,
  emailAutoReferenceUpdate,
  getAutoReferenceData,
  getReferenceListing,
} from "services/redux/Reference/action";

import { emailAutoReferenceAdd } from "services/redux/email/mailMessageCenter/action";

import {
  NORMAL,
  FLAG_TYPE,
  FLAGTYPE1,
  FLAGTYPE2,
  FLAGTYPE3,
  FLAGTYPE4,
  FLAGTYPE5,
  SPECIALFLAG1,
  SPECIALFLAG2,
  SPECIALFLAG3,
  SPECIALFLAG4,
  SPECIALFLAG5,
  NONE,
  DRAFT,
  FROM,
  TO,
  INBOX,
  FORWARD,
  ARCHIEVED,
  OUTBOX,
  TRASH,
  UNREAD,
  READ,
  ALL,
  PENDING,
  COMPLETED,
  SPECIALFLAG,
  DELETED,
  DELETE,
  REMOVE,
  ADD,
  UNSOLICITED,
  GROUP_CHAT,
  GROUP_CHAT_ARCHIEVE,
  GROUP_CHAT_SWITCH,
  SENT,
} from "components/constants";

import { color } from "components/constants/colors";

import {
  styleForFlagMenu,
  styleForFlagMenuIcon,
  styleForFlagMenuName,
  styleForSubMenuCompose,
} from "../style";

import specialFlag0 from "../../../assets/svg/flagSpecial0.svg";
import specialFlag1 from "../../../assets/svg/flagSpecial1.svg";
import specialFlag2 from "../../../assets/svg/flagSpecial2.svg";
import specialFlag3 from "../../../assets/svg/flagSpecial3.svg";
import specialFlag4 from "../../../assets/svg/flagSpecial4.svg";
import specialFlag5 from "../../../assets/svg/flagSpecial5.svg";
import { ARCHIEVE } from "components/constants";
import { UNARCHIEVE } from "components/constants";
import CommonModal from "components/modals";
import resend from "../../../assets/svg/resend-outbox.svg";
import chatTransfer from "../../../assets/svg/chatTransfer.svg";
import chatArchive from "../../../assets/svg/chat-message-delete.svg";

const reply = "./assets/svg/reply-12.svg";
const replyall = "./assets/svg/replyAll.svg";
const forward = "./assets/svg/forward-12.svg";
const refrence = "./assets/svg/refrence-12.svg";
const pending = "./assets/svg/pending-12.svg";
const archieve = "./assets/svg/archive-12.svg";
const read = "./assets/svg/send-12.svg";
const trash = "./assets/svg/delete-12.svg";
const junk = "./assets/svg/junk.svg";
const printer = "./assets/svg/printer-12.svg";
// const forwardall = "./assets/svg/forwardall-12.svg";
// const resend = "./assets/svg/resend-outbox.svg";

const MailMessageCenterHeader = ({
  currentSelectedMailMessage,
  // replyEmailCompose,
  // instanceMessageCenterPagination,
  messageCenterTypeName,
  printTableRef,
  mailMessageCenterPagination,
  setPreSelectEmailCard,
  setCurrentSelectedMailMessage,
  info,
  referenceTags,
  // openLink,
  setOpenLink,
  appType,
  // setAppType,
  multipleSelectedEmail,
  printFunction,
  openAutoReferencing,
  setOpenAutoReferencing,
  setLoadingMail,
  // getData,
  // pageSize,
  corporateSharedAccessAccountHolderUserId,
  setBulkStatusLoading,
  emailType,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [form] = Form.useForm();

  const { selectedMailTypeAndMailData, activeEmail } = useSelector(
    (state) => state.mailMessagePeristState
  );

  const [pendingloader, setPendingLoader] = useState(false);
  const [trashLoader, setTrashLoader] = useState(false);
  const [readLoader, setReadLoader] = useState(false);
  const [archieveLoader, setArchieveLoader] = useState(false);
  const [junkLoader, setJunkLoader] = useState(false);
  const [showAutoReferencedata, setShowAutoreferenceData] = useState("");
  const [responseStatus, setResponseStatus] = useState();
  const [fetchquery, setFetchQuery] = useState();
  const [zoomProp, setZoomProp] = useState(100);

  const {
    referenceListingResponse,
    // referenceListingLoading,
    autoReferenceListingResponse,
    autoReferenceListingResponseLoading,
    // emailAutoReferenceAddResponse,
    emailAutoReferenceAddResponseLoading,
  } = useSelector((state) => state.reference);

  const {
    changeEmailStatusTypeRespLoading,
    // changeEmailStatusTypeResp,
    getResponseStatus,
    mailReferenceUpdateResponse,
    outBoxResetFailCountResponse,
    outBoxResetFailCountLoading,
    // emailExistOrNot,
  } = useSelector((state) => state.mailMessageCenter);

  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const [searchValueReference, setSearchValueReference] = useState("");
  const [filterParticipantType, setFilterParticipantType] = useState("");
  const [lowPriorityChecked, setLowPriorityChecked] = useState();
  const [referenceListing, setReferenceListing] = useState([]);
  const [filterReference, setFilterReference] = useState([]);
  const [autoReferenceListingData, setAutoReferenceListingData] = useState([]);
  const [showAutoForwardModal, setShowAutoForwardModal] = useState(false);

  //redux store
  const { mailReferenceGetDetailsResponse } = useSelector(
    (state) => state.mailReference
  );
  //zoom button show or bot data
  const { zoomData } = useSelector((state) => state.settingsData);

  useEffect(() => {
    if (getResponseStatus && changeEmailStatusTypeRespLoading === true) {
      setResponseStatus(fetchquery);
    } else {
      setResponseStatus("");
    }
  }, [getResponseStatus, changeEmailStatusTypeRespLoading]);

  useEffect(() => {
    if (changeEmailStatusTypeRespLoading) {
      setReadLoader(true);
      setPendingLoader(true);
      setArchieveLoader(true);
      setTrashLoader(true);
      setJunkLoader(true);
    } else {
      setReadLoader(false);
      setPendingLoader(false);
      setArchieveLoader(false);
      setTrashLoader(false);
      setJunkLoader(false);
    }
  }, [changeEmailStatusTypeRespLoading]);

  useEffect(() => {
    let forward = document.querySelector(".forward-link-btn");
    // let reply = document.querySelector(".reply-link-btn");
    // let replyAll = document.querySelector(".replyAll-link-btn");
    if (forward && appType == FORWARD) {
      forward.click();
      setOpenLink(false);
    }
  }, [mailReferenceUpdateResponse]);

  // if (
  //   responseStatus?.participantStatusName === READ ||
  //   responseStatus?.participantStatusName === "Un-read"
  // ) {
  //   setReadLoader(true);
  // } else if (responseStatus?.participantStatusName === PENDING) {
  //   setPendingLoader(true);
  // } else if (responseStatus?.participantStatusName === ARCHIEVED) {
  //   setArchieveLoader(true);
  // } else if (responseStatus?.participantStatusName === TRASH) {
  //   setTrashLoader(true);
  // } else if (responseStatus?.participantStatusName === "Junk") {
  //   setJunkLoader(true);
  // }

  const changeEmailStatus = async (emailStatus, status) => {
    // console.log("emailStatus", emailStatus)
    // console.log("status", status)
    let query = {
      messageCenterId: selectedMailTypeAndMailData?.selectedMail?.id,
      typeSystemName: selectedMailTypeAndMailData?.typeDisplayName,
      mailMessageParticipantTableId:
        selectedMailTypeAndMailData?.selectedMail
          ?.mailMessageParticipantTableId,
      participantStatusName: emailStatus.isRead
        ? READ
        : emailStatus.pending
        ? emailStatus.pending
        : emailStatus.junked
        ? emailStatus.junked
        : emailStatus.groupChatarchived
        ? emailStatus.groupChatarchived
        : emailStatus.archived
        ? emailStatus.archived
        : emailStatus.groupChatSwitch
        ? emailStatus.groupChatSwitch
        : emailStatus.isUnSolicited
        ? "Special1"
        : emailStatus.participantStatusName
        ? emailStatus.participantStatusName
        : emailStatus.trash
        ? `${
            selectedMailTypeAndMailData?.typeSystemName !== TRASH
              ? TRASH
              : selectedMailTypeAndMailData?.typeSystemName == TRASH &&
                emailStatus.move === INBOX
              ? TRASH
              : "doubleTrash"
          }`
        : "Un-read",
      instanceMessageId:
        selectedMailTypeAndMailData?.selectedMail?.applicationTypeSystemName ===
        "InstanceMessage"
          ? selectedMailTypeAndMailData?.selectedMail?.latestInstanceMessageId
          : "",
      instanceMainMessageCenterId:
        selectedMailTypeAndMailData?.selectedMail?.instanceMainMessageCenterId,
      //amit add
      FlagTypeId: emailStatus?.FlagTypeId,
      CorporateSharedAccessAccountHolderUserId:
        corporateSharedAccessAccountHolderUserId,
    };

    setFetchQuery(query);
    dispatch(
      changeEmailStatusType(query, status, messageCenterTypeName, emailStatus)
    );
  };

  const changeMultipleEmailStatus = (emailStatus, type) => {
    setBulkStatusLoading(true);
    let body = [];
    emailStatus?.map((item) => {
      body = [
        ...body,
        {
          messageCenterId: item?.id,
          instanceMessageId: item?.latestInstanceMessageId,
          instanceMainMessageCenterId: item?.instanceMainMessageCenterId,
          participantStatusName: type,
          mailMessageParticipantTableId: item?.mailMessageParticipantTableId,
          typeSystemName: selectedMailTypeAndMailData?.typeDisplayName,
        },
      ];
    });
    dispatch(
      changeMultipleEmailStatusType(
        body,
        type,
        selectedMailTypeAndMailData?.typeDisplayName,
        setBulkStatusLoading
      )
    );

    mailMessageCenterPagination?.items?.map((mail, id) => {
      var x = document?.getElementById(`email-list-${mail?.id}`);
      x.checked = false;
      dispatch(
        multipleEmailSelectionAction({
          checkStatus: false,
          // data: mail,
          data: [],
        })
      );
    });
  };

  const columns = [
    {
      title: "Sender's Address",
      dataIndex: "emailaddress",
      key: "emailaddress",
      width: "25%",
    },
    {
      title: "Reference",
      key: "referenceCode",
      width: "30%",
      render: (record) =>
        `${record?.emailReferenceIdReferenceCodeDtos?.map(
          (data) => data?.referenceCode
        )} ${record?.emailReferenceIdReferenceCodeDtos?.length > 1 ? "," : ""}`,
      // `${autoReferenceListingResponse?.[0]?.emailReferenceIdReferenceCodeDtos?.map(
      //   (data) => `${data?.referenceCode},`
      // )}`,
    },
    {
      title: "Subject Keyword",
      dataIndex: "subject",
      key: "subject",
      width: "25%",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <Dropdown.Button
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setShowAutoreferenceData(record);
                  }}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    let deleteBody = {
                      ...record,
                      id: record?.id,
                      // subject: record?.subjectValue,
                      emailaddress: record?.emailaddress,
                      isActive: false,
                      // emailReferenceIdReferenceCodeDtos: [
                      //   { referenceCode: record?.referenceCode },
                      // ],
                    };
                    dispatch(
                      emailAutoReferenceUpdate(deleteBody, form, record?.id)
                    );
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
            trigger={"hover"}
            className="open_menu_card_title_dashboard"
            onClick={() => {
              // console.log(record);
            }}
          ></Dropdown.Button>
        </>
      ),
      width: "5%",
    },
  ];

  const onFinishAutoReferenceSave = async () => {
    const subjectValue = form.getFieldsValue().subject;
    const emailaddress = form.getFieldsValue().emailaddress;
    const referenceId = form.getFieldsValue().referenceId;
    if (referenceId?.length <= 0) {
      message.info({
        content: "Reference Required",
      });
    } else {
      let body = {
        subject: subjectValue,
        emailaddress: emailaddress,
        folderLocation: lowPriorityChecked ? "unsolicited" : "",
      };
      if (!showAutoReferencedata) {
        let addBody = {
          ...body,
          messageCenterId:
            currentSelectedMailMessage?.instanceMainMessageCenterId,
          emailReferenceIdReferenceCodeDtos: referenceId?.map((code) => {
            return { referenceCode: code };
          }),
        };
        dispatch(emailAutoReferenceAdd(addBody, form));
      } else {
        let updateBody = {
          ...body,
          messageCenterId:
            currentSelectedMailMessage?.instanceMainMessageCenterId,
          id: showAutoReferencedata?.id,
          emailReferenceIdReferenceCodeDtos: referenceId?.map((code) => {
            return { referenceCode: code };
          }),
        };
        // console.log("updateBody", updateBody);
        dispatch(
          emailAutoReferenceUpdate(updateBody, form, showAutoReferencedata?.id)
        );
      }
    }
  };

  useEffect(() => {
    const mailBody = document.getElementById("printf")?.contentDocument?.body;
    if (mailBody) {
      // mailBody.style.zoom = ;
      if (zoomProp == "70") {
        mailBody.style.zoom = "70%";
      } else if (zoomProp == "80") {
        mailBody.style.zoom = "80%";
      } else if (zoomProp == "90") {
        mailBody.style.zoom = "90%";
      } else if (zoomProp == "100") {
        mailBody.style.zoom = "100%";
      } else if (zoomProp == "110") {
        mailBody.style.zoom = "110%";
      } else if (zoomProp == "120") {
        mailBody.style.zoom = "120%";
      } else if (zoomProp == "120") {
        mailBody.style.zoom = "120%";
      } else if (zoomProp == "130") {
        mailBody.style.zoom = "130%";
      } else if (zoomProp == "140") {
        mailBody.style.zoom = "140%";
      } else if (zoomProp == "150") {
        mailBody.style.zoom = "150%";
      }
    }
  }, [zoomProp]);

  useEffect(() => {
    if (searchValueReference) {
      if (referenceListingResponse) {
        setReferenceListing(referenceListingResponse);
      }
    }
  }, [referenceListingResponse, searchValueReference]);
  useEffect(() => {
    if (!isEmpty(referenceListing) && filterParticipantType == "REF") {
      setFilterReference(referenceListing.items);
    }
  }, [referenceListing]);

  useEffect(() => {
    if (filterParticipantType === "REF") {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  useEffect(() => {
    dispatch(getAutoReferenceData());
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      subject: currentSelectedMailMessage?.subject,
      emailaddress:
        currentSelectedMailMessage?.applicationTypeDetails?.from?.[0]
          ?.emailAddress,
      referenceId: currentSelectedMailMessage?.mailMessageReferenceDtos?.map(
        (reference) => {
          return reference.referenceCode;
        }
      ),
    });
  }, [currentSelectedMailMessage]);

  // useEffect(()=>{
  //   if(!isEmpty(emailAutoReferenceAddResponse)){
  //     // setAutoReferenceListingData(...autoReferenceListingData,emailAutoReferenceAddResponse)
  //     console.log(emailAutoReferenceAddResponse,"in")
  //     autoReferenceListingData?.push(emailAutoReferenceAddResponse)
  //   }
  // },[emailAutoReferenceAddResponse])

  useEffect(() => {
    if (!isEmpty(autoReferenceListingResponse)) {
      setAutoReferenceListingData(autoReferenceListingResponse);
    }
  }, [autoReferenceListingResponse]);

  useEffect(() => {
    if (!isEmpty(showAutoReferencedata)) {
      form.setFieldsValue({
        subject: showAutoReferencedata?.subject,
        emailaddress: showAutoReferencedata?.emailaddress,
        referenceId:
          showAutoReferencedata?.emailReferenceIdReferenceCodeDtos?.map(
            (data) => data?.referenceCode
          ),
      });
    }
  }, [showAutoReferencedata]);

  const subMenuCompose = (
    <Menu style={styleForSubMenuCompose}>
      <Menu.Item className="sidebars__menu--item">
        <>
          <div
            style={styleForFlagMenu}
            onClick={() => {
              setLoadingMail(true);
              if (!isEmpty(selectedMailTypeAndMailData)) {
                changeEmailStatus(
                  currentSelectedMailMessage?.flagTypeName !== NONE
                    ? {
                        FlagTypeId: 0,
                        flagTypeName: NORMAL,
                        participantStatusName: FLAG_TYPE,
                        flagTypeId: 0,
                      }
                    : {
                        FlagTypeId: 0,
                        flagTypeName: NORMAL,
                        participantStatusName: FLAG_TYPE,
                        flagTypeId: 0,
                      }
                );
              }
            }}
          >
            <div className=" " style={styleForFlagMenuName}>
              None
            </div>
          </div>
        </>
      </Menu.Item>

      <Menu.Item
        className="sidebars__menu--item"
        style={{
          backgroundColor:
            currentSelectedMailMessage?.flagTypeName === FLAGTYPE1
              ? color.LIGHTGREEN
              : "",
        }}
      >
        <>
          <div
            style={styleForFlagMenu}
            onClick={() => {
              setLoadingMail(true);
              if (!isEmpty(selectedMailTypeAndMailData)) {
                changeEmailStatus(
                  currentSelectedMailMessage?.flagTypeName !== FLAGTYPE1
                    ? {
                        FlagTypeId: 1,
                        flagTypeName: FLAGTYPE1,
                        participantStatusName: FLAG_TYPE,
                        flagTypeId: 1,
                      }
                    : {
                        FlagTypeId: 0,
                        flagTypeName: NORMAL,
                        participantStatusName: FLAG_TYPE,
                        flagTypeId: 0,
                      }
                );
              }
            }}
          >
            <div style={styleForFlagMenuIcon}>
              {/* <Image
                src={
                  currentSelectedMailMessage?.flagTypeName === FLAGTYPE1
                    ? specialFlag1
                    : specialFlag0
                }
                preview={false}
              /> */}
              <Image src={specialFlag1} preview={false} />
            </div>

            <div className="" style={styleForFlagMenuName}>
              Flag 1
            </div>
          </div>
        </>
      </Menu.Item>

      <Menu.Item
        className="sidebars__menu--item"
        style={{
          backgroundColor:
            currentSelectedMailMessage?.flagTypeName === FLAGTYPE2
              ? color.LIGHTGREEN
              : "",
        }}
      >
        <>
          <div
            style={styleForFlagMenu}
            onClick={() => {
              setLoadingMail(true);
              if (!isEmpty(selectedMailTypeAndMailData)) {
                changeEmailStatus(
                  currentSelectedMailMessage?.flagTypeName !== FLAGTYPE2
                    ? {
                        FlagTypeId: 2,
                        flagTypeName: FLAGTYPE2,
                        participantStatusName: FLAG_TYPE,
                        flagTypeId: 2,
                      }
                    : {
                        FlagTypeId: 0,
                        flagTypeName: NORMAL,
                        participantStatusName: FLAG_TYPE,
                        flagTypeId: 0,
                      }
                );
              }
            }}
          >
            <div style={styleForFlagMenuIcon}>
              <Image src={specialFlag2} preview={false} />
            </div>

            <div style={styleForFlagMenuName}>Flag 2</div>
          </div>
        </>
      </Menu.Item>

      <Menu.Item
        className="sidebars__menu--item"
        style={{
          backgroundColor:
            currentSelectedMailMessage?.flagTypeName === FLAGTYPE3
              ? color.LIGHTGREEN
              : "",
        }}
      >
        <>
          <div
            style={styleForFlagMenu}
            onClick={() => {
              setLoadingMail(true);
              if (!isEmpty(selectedMailTypeAndMailData)) {
                changeEmailStatus(
                  currentSelectedMailMessage?.flagTypeName !== FLAGTYPE3
                    ? {
                        FlagTypeId: 3,
                        participantStatusName: FLAG_TYPE,
                        flagTypeName: FLAGTYPE3,

                        flagTypeId: 3,
                      }
                    : {
                        FlagTypeId: 0,
                        participantStatusName: FLAG_TYPE,
                        flagTypeName: NORMAL,
                        flagTypeId: 0,
                      }
                );
              }
            }}
          >
            <div style={styleForFlagMenuIcon}>
              <Image src={specialFlag3} preview={false} />
            </div>

            <div style={styleForFlagMenuName}>Flag 3</div>
          </div>
        </>
      </Menu.Item>

      <Menu.Item
        className="sidebars__menu--item"
        style={{
          backgroundColor:
            currentSelectedMailMessage?.flagTypeName === FLAGTYPE4
              ? color.LIGHTGREEN
              : "",
        }}
      >
        <>
          <div
            style={styleForFlagMenu}
            onClick={() => {
              setLoadingMail(true);
              if (!isEmpty(selectedMailTypeAndMailData)) {
                changeEmailStatus(
                  currentSelectedMailMessage?.flagTypeName !== FLAGTYPE4
                    ? {
                        FlagTypeId: 4,
                        participantStatusName: FLAG_TYPE,
                        flagTypeName: FLAGTYPE4,
                        flagTypeId: 4,
                      }
                    : {
                        FlagTypeId: 0,
                        participantStatusName: FLAG_TYPE,
                        flagTypeName: NORMAL,
                        flagTypeId: 0,
                      }
                );
              }
            }}
          >
            <div style={styleForFlagMenuIcon}>
              <Image src={specialFlag4} preview={false} />
            </div>

            <div style={styleForFlagMenuName}>Flag 4</div>
          </div>
        </>
      </Menu.Item>

      <Menu.Item
        className="sidebars__menu--item"
        style={{
          backgroundColor:
            currentSelectedMailMessage?.flagTypeName === FLAGTYPE5
              ? color.LIGHTGREEN
              : "",
        }}
      >
        <>
          <div
            style={styleForFlagMenu}
            onClick={() => {
              setLoadingMail(true);
              if (!isEmpty(selectedMailTypeAndMailData)) {
                changeEmailStatus(
                  currentSelectedMailMessage?.flagTypeName !== FLAGTYPE5
                    ? {
                        FlagTypeId: 5,
                        flagTypeName: FLAGTYPE5,
                        participantStatusName: FLAG_TYPE,
                        flagTypeId: 5,
                      }
                    : {
                        FlagTypeId: 0,
                        flagTypeName: NORMAL,
                        participantStatusName: FLAG_TYPE,
                        flagTypeId: 0,
                      }
                );
              }
            }}
          >
            <div style={styleForFlagMenuIcon}>
              <Image src={specialFlag5} preview={false} />
            </div>

            <div style={styleForFlagMenuName}>Flag 5</div>
          </div>
        </>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {selectedMailTypeAndMailData?.typeSystemName !== DRAFT ? (
        <div
          className="emailDetails__header top-header-email"
          onClick={() => {
            setPreSelectEmailCard(false);
          }}
        >
          {/* <div className="emailDetails__header--icon">
				<Image src={IntMsg} shape="square" />
				<p>Int. Msg</p>
			</div> */}
          {/* <div className="emailDetails__header--icon">
				<Image src={Mail} shape="square" />
				<Dropdown overlay={menu} size="small">
					<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
						F/up Resend <DownOutlined />
					</a>
				</Dropdown>
			</div> */}

          {selectedMailTypeAndMailData?.typeSystemName === FROM &&
            isEmpty(multipleSelectedEmail) && (
              <>
                <div
                  className={`emailDetails__header--icon  ${
                    currentSelectedMailMessage?.applicationTypeSystemName !==
                      "EMail" && "disabled"
                  }`}
                  onClick={() => {
                    // electron.openForwardSendWindow('emailForwardSend');
                  }}
                >
                  <Link
                    to="/email-compose?applicationType=reSend"
                    target="_blank"
                    rel="opener"
                  >
                    <Image src={forward} shape="square" preview={false} />
                    <p>Re-Send</p>
                  </Link>
                </div>
                <div
                  className={`emailDetails__header--icon  ${
                    currentSelectedMailMessage?.applicationTypeSystemName !==
                      "EMail" && "disabled"
                  }`}
                  onClick={() => {
                    // electron.openForwardSendWindow('emailForwardSend');
                  }}
                >
                  <Link
                    to="/email-compose?applicationType=emailForwardSend"
                    target="_blank"
                    rel="opener"
                  >
                    <Image src={forward} shape="square" preview={false} />
                    <p>Follow Up</p>
                  </Link>
                </div>
              </>
            )}
          {(selectedMailTypeAndMailData?.typeSystemName === TO ||
            isEmpty(selectedMailTypeAndMailData) ||
            selectedMailTypeAndMailData?.typeSystemName === INBOX) &&
          isEmpty(multipleSelectedEmail) ? (
            <div
              style={{ display: "flex" }}
              className="mail-header-flex-wrapper"
            >
              <div
                className={`emailDetails__header--icon ${
                  currentSelectedMailMessage?.applicationTypeSystemName !==
                    "EMail" && "disabled"
                }`}
              >
                {isEmpty(referenceTags) ? (
                  <div
                    onClick={() => {
                      info("reply");
                    }}
                  >
                    <Image
                      src={reply}
                      shape="square"
                      preview={false}
                      // onClick={() => {
                      //   info("reply");
                      // }}
                    />
                    <p>Reply</p>
                  </div>
                ) : (
                  <Link
                    to="/email-compose?applicationType=reply"
                    target="_blank"
                    rel="opener"
                    className="reply-link-btn"
                  >
                    <Image src={reply} shape="square" preview={false} />
                    <p>Reply</p>
                  </Link>
                )}
              </div>
              <div
                className={`emailDetails__header--icon  ${
                  currentSelectedMailMessage?.applicationTypeSystemName !==
                    "EMail" && "disabled"
                }`}
              >
                {isEmpty(referenceTags) ? (
                  <div
                    onClick={() => {
                      info("replyAll");
                    }}
                  >
                    <Image
                      className="replyAll__Icon"
                      src={replyall}
                      shape="square"
                      preview={false}
                      // onClick={() => {
                      //   info("replyAll");
                      // }}
                    />
                    <p>Reply All</p>
                  </div>
                ) : (
                  <Link
                    to="/email-compose?applicationType=replyall"
                    target="_blank"
                    rel="opener"
                    className="replyAll-link-btn"
                  >
                    <Image
                      className="replyAll__Icon"
                      src={replyall}
                      shape="square"
                      preview={false}
                    />
                    <p>Reply All</p>
                  </Link>
                )}
              </div>
            </div>
          ) : (
            selectedMailTypeAndMailData?.typeDisplayName === ALL &&
            isEmpty(multipleSelectedEmail) && (
              <div
                style={{ display: "flex" }}
                className="mail-header-flex-wrapper"
              >
                <div
                  className={`emailDetails__header--icon  ${
                    currentSelectedMailMessage?.applicationTypeSystemName !==
                      "EMail" && "disabled"
                  }`}
                  onClick={() => {
                    // electron.openForwardSendWindow('reSend');
                  }}
                ></div>
                <div
                  className={`emailDetails__header--icon  ${
                    currentSelectedMailMessage?.applicationTypeSystemName !==
                      "EMail" && "disabled"
                  }`}
                >
                  <Link
                    to="/email-compose?applicationType=followUp"
                    target="_blank"
                    rel="opener"
                  >
                    <Image src={replyall} shape="square" preview={false} />
                    <p>Follow Up</p>
                  </Link>
                </div>
              </div>
            )
          )}
          {selectedMailTypeAndMailData?.typeSystemName !== TRASH &&
            selectedMailTypeAndMailData?.typeSystemName !== OUTBOX &&
            isEmpty(multipleSelectedEmail) && (
              <div
                className={`emailDetails__header--icon iconBorder  ${
                  currentSelectedMailMessage?.applicationTypeSystemName !==
                    "EMail" && "disabled"
                }`}
              >
                {isEmpty(referenceTags) ? (
                  <div
                    onClick={() => {
                      info(FORWARD);
                    }}
                  >
                    <Image
                      src={forward}
                      shape="square"
                      preview={false}
                      // onClick={() => {
                      //   info("forward");
                      // }}
                    />
                    <p>Forward</p>
                  </div>
                ) : (
                  <Link
                    to="/email-compose?applicationType=forward"
                    target="_blank"
                    rel="opener"
                    className="forward-link-btn"
                  >
                    <Image src={forward} shape="square" preview={false} />
                    <p>Forward</p>
                  </Link>
                )}
              </div>
            )}

          {!selectedMailTypeAndMailData?.typeSystemName === TO && (
            <div
              onClick={() => {
                setShowBusinessModal(true);
                dispatch(
                  mailReferenceGetDetails(currentSelectedMailMessage?.id)
                );
              }}
              className={`emailDetails__header--icon ${
                currentSelectedMailMessage?.applicationTypeSystemName !==
                  "EMail" && "disabled"
              }`}
            >
              <Image src={refrence} shape="square" preview={false} />
              <p>Ref/Party</p>
            </div>
          )}

          {selectedMailTypeAndMailData?.typeSystemName === OUTBOX && (
            <div
              className={`emailDetails__header--icon loader--action-wrapper ${
                (isEmpty(currentSelectedMailMessage) ||
                  multipleSelectedEmail?.length > 0) &&
                "disabled"
              } ${trashLoader && "disabled"}`}
              onClick={() => {
                if (currentSelectedMailMessage?.id) {
                  dispatch(
                    resetOutboxMailFailureCount(currentSelectedMailMessage?.id)
                  );
                }
              }}
            >
              <Image src={resend} shape="square" preview={false} />
              <p>Resend</p>
            </div>
          )}

          {selectedMailTypeAndMailData?.typeSystemName === GROUP_CHAT && (
            <>
              <div
                className={`emailDetails__header--icon loader--action-wrapper ${
                  (isEmpty(currentSelectedMailMessage) ||
                    multipleSelectedEmail?.length > 0) &&
                  "disabled"
                } ${trashLoader && "disabled"}`}
                onClick={() => {
                  if (!isEmpty(selectedMailTypeAndMailData)) {
                    changeEmailStatus(
                      !selectedMailTypeAndMailData.selectedMail?.isArchieve
                        ? {
                            isGroupChatArchieve: true,
                            isGroupChatArchieve_InstanceMessage: true,
                            groupChatarchived: GROUP_CHAT_ARCHIEVE,
                          }
                        : {
                            isGroupChatArchieve: false,
                            isGroupChatArchieve_InstanceMessage: false,
                            groupChatarchived: GROUP_CHAT_ARCHIEVE,
                          },
                      selectedMailTypeAndMailData.selectedMail
                        ?.isGroupChatArchieve
                        ? ADD
                        : REMOVE
                    );
                  }
                }}
              >
                <Image src={chatArchive} shape="square" preview={false} />
                <p>ArchiveChat</p>
              </div>

              <div
                className={`emailDetails__header--icon loader--action-wrapper ${
                  (isEmpty(currentSelectedMailMessage) ||
                    multipleSelectedEmail?.length > 0) &&
                  "disabled"
                } ${trashLoader && "disabled"}`}
                onClick={() => {
                  if (!isEmpty(selectedMailTypeAndMailData)) {
                    // changeEmailStatus(
                    //   currentSelectedMailMessage?.flagTypeName !== NONE
                    //     ? {
                    //       participantStatusName: INBOX,
                    //     }
                    //     : {
                    //       participantStatusName: INBOX,
                    //     }
                    // );
                    changeEmailStatus({
                      isGroupChatSwitch: true,
                      isGroupChatSwitch_InstanceMessage: true,
                      groupChatSwitch: GROUP_CHAT_SWITCH,
                    });
                    // console.log("selectedMailTypeAndMailData.selectedMail: ",selectedMailTypeAndMailData.selectedMail)
                  }
                }}
              >
                <Image src={chatTransfer} shape="square" preview={false} />
                <p>ToInbox</p>
              </div>
            </>
          )}

          {selectedMailTypeAndMailData?.typeSystemName === FROM && (
            <>
              <div
                className={`emailDetails__header--icon loader--action-wrapper ${
                  (isEmpty(currentSelectedMailMessage) ||
                    multipleSelectedEmail?.length > 0) &&
                  "disabled"
                } ${trashLoader && "disabled"}`}
                onClick={() => {
                  if (!isEmpty(selectedMailTypeAndMailData)) {
                    changeEmailStatus({
                      isGroupChatSwitch: true,
                      isGroupChatSwitch_InstanceMessage: true,
                      groupChatSwitch: GROUP_CHAT_SWITCH,
                    });
                  }
                }}
              >
                <Image src={chatTransfer} shape="square" preview={false} />
                <p>GroupChat</p>
              </div>
            </>
          )}

          {emailType !== "Business" && (
            <>
              {selectedMailTypeAndMailData?.typeSystemName !== FROM &&
                selectedMailTypeAndMailData?.typeSystemName !== TRASH &&
                selectedMailTypeAndMailData?.typeSystemName !== OUTBOX && (
                  <>
                    <div
                      className={`emailDetails__header--icon loader--action-wrapper read-unread-header-wrapper ${
                        (isEmpty(currentSelectedMailMessage) ||
                          multipleSelectedEmail?.length > 0) &&
                        "disabled"
                      } ${readLoader && "disabled"}`}
                      onClick={() =>
                        changeEmailStatus(
                          !currentSelectedMailMessage?.isRead
                            ? { isRead: true, isRead_InstanceMessage: true }
                            : { isRead: false, isRead_InstanceMessage: false }
                        )
                      }
                    >
                      <Image src={read} shape="square" preview={false} />
                      <p>
                        {!currentSelectedMailMessage?.isRead ? READ : UNREAD}
                      </p>
                      {/* {readLoader && (
                    <div className="Statusloader">
                      <Spin indicator={<LoadingOutlined />} />
                    </div>
                  )} */}
                    </div>
                  </>
                )}

              {selectedMailTypeAndMailData?.typeSystemName !== FROM &&
                selectedMailTypeAndMailData?.typeSystemName !== TRASH &&
                selectedMailTypeAndMailData?.typeSystemName !== OUTBOX &&
                selectedMailTypeAndMailData?.typeSystemName !== GROUP_CHAT && (
                  <>
                    <div
                      className={`emailDetails__header--icon archive__header--icon loader--action-wrapper ${
                        (isEmpty(currentSelectedMailMessage) ||
                          multipleSelectedEmail?.length > 0) &&
                        "disabled"
                      } ${archieveLoader && "disabled"}`}
                      onClick={() => {
                        if (!isEmpty(selectedMailTypeAndMailData)) {
                          if (!isEmpty(referenceTags)) {
                            changeEmailStatus(
                              !selectedMailTypeAndMailData.selectedMail
                                ?.isArchieve
                                ? {
                                    isArchieve: true,
                                    isArchived_InstanceMessage: true,
                                    archived: ARCHIEVED,
                                  }
                                : {
                                    isArchived_InstanceMessage: false,
                                    isArchieve: false,
                                    archived: ARCHIEVED,
                                  },
                              selectedMailTypeAndMailData.selectedMail
                                ?.isArchieve
                                ? REMOVE
                                : ADD
                            );
                          } else {
                            info();
                          }
                        }
                      }}
                    >
                      <Image src={archieve} shape="square" preview={false} />
                      <p>
                        {!isEmpty(selectedMailTypeAndMailData) &&
                        selectedMailTypeAndMailData.selectedMail?.isArchieve
                          ? UNARCHIEVE
                          : ARCHIEVE}
                      </p>
                    </div>
                  </>
                )}
              {selectedMailTypeAndMailData?.typeSystemName !== TRASH &&
              selectedMailTypeAndMailData?.typeSystemName !== OUTBOX ? (
                <>
                  <div
                    className={`emailDetails__header--icon loader--action-wrapper  iconBorder ${
                      (isEmpty(currentSelectedMailMessage) ||
                        multipleSelectedEmail?.length > 0) &&
                      "disabled"
                    } ${pendingloader && "disabled"}`}
                    onClick={() => {
                      if (!isEmpty(selectedMailTypeAndMailData)) {
                        changeEmailStatus(
                          selectedMailTypeAndMailData?.selectedMail?.isPending
                            ? {
                                isPending: false,
                                isPending_InstanceMessage: false,
                                pending: PENDING,
                              }
                            : {
                                isPending: true,
                                isPending_InstanceMessage: true,
                                pending: PENDING,
                              },
                          currentSelectedMailMessage?.selectedMail?.isPending
                            ? REMOVE
                            : ADD
                        );
                      }
                    }}
                  >
                    <Image src={pending} shape="square" preview={false} />
                    <p>
                      {currentSelectedMailMessage?.isPending
                        ? COMPLETED
                        : PENDING}
                    </p>
                    {/* {pendingloader && (
                  <div className="Statusloader">
                    <Spin indicator={<LoadingOutlined />} />
                  </div>
                )} */}
                  </div>
                </>
              ) : (
                selectedMailTypeAndMailData?.typeSystemName !== OUTBOX && (
                  <div
                    className={`emailDetails__header--icon  iconBorder ${
                      (isEmpty(currentSelectedMailMessage) ||
                        multipleSelectedEmail?.length > 0) &&
                      "disabled"
                    }`}
                    onClick={() => {
                      if (!isEmpty(selectedMailTypeAndMailData)) {
                        changeEmailStatus(
                          !selectedMailTypeAndMailData?.selectedMail.isTrashed
                            ? {
                                isTrashed: true,
                                isTrashed_InstanceMessage: true,
                                trash: TRASH,
                                move: INBOX,
                              }
                            : {
                                isTrashed_InstanceMessage: false,
                                isTrashed: false,
                                trash: TRASH,
                                move: INBOX,
                              },
                          selectedMailTypeAndMailData?.selectedMail
                            ?.isTrashed ||
                            selectedMailTypeAndMailData?.selectedMail
                              ?.isTrashed_InstanceMessage
                            ? "Move to Inbox"
                            : "Undo"
                        );
                      }
                    }}
                  >
                    <Image src={pending} shape="square" preview={false} />
                    <p> Recover </p>
                  </div>
                )
              )}
              <>
                <div
                  className={`emailDetails__header--icon loader--action-wrapper ${
                    (isEmpty(currentSelectedMailMessage) ||
                      multipleSelectedEmail?.length > 0) &&
                    "disabled"
                  } ${trashLoader && "disabled"}`}
                  onClick={() =>
                    changeEmailStatus(
                      !currentSelectedMailMessage?.isTrashed &&
                        !currentSelectedMailMessage?.isTrashed_InstanceMessage
                        ? {
                            isTrashed: true,
                            isTrashed_InstanceMessage: true,
                            trash: TRASH,
                            move: "doubleTrash",
                          }
                        : {
                            isTrashed_InstanceMessage: false,
                            isTrashed: false,
                            move: "doubleTrash",
                          },
                      currentSelectedMailMessage?.isTrashed ||
                        currentSelectedMailMessage?.isTrashed_InstanceMessage
                        ? REMOVE
                        : ADD
                    )
                  }
                >
                  <Image src={trash} shape="square" preview={false} />
                  <p>
                    {currentSelectedMailMessage?.isTrashed ? DELETED : DELETE}
                  </p>
                  {/* {trashLoader && (
                <div className="Statusloader">
                  <Spin indicator={<LoadingOutlined />} />
                </div>
              )} */}
                </div>

                {selectedMailTypeAndMailData?.typeSystemName !== OUTBOX && (
                  <div
                    className={`emailDetails__header--icon loader--action-wrapper   ${
                      (isEmpty(currentSelectedMailMessage) ||
                        multipleSelectedEmail?.length > 0) &&
                      "disabled"
                    } ${junkLoader && "disabled"}`}
                    onClick={() => {
                      setLoadingMail(true);
                      if (!isEmpty(selectedMailTypeAndMailData)) {
                        changeEmailStatus(
                          !selectedMailTypeAndMailData?.selectedMail?.isJunk
                            ? {
                                isJunk: true,
                                isJunked_InstanceMessage: true,
                                junked: "Junk",
                              }
                            : {
                                isJunked_InstanceMessage: false,
                                isJunk: false,
                                junked: "Junk",
                              },
                          currentSelectedMailMessage?.isJunk ? REMOVE : ADD
                        );
                      }
                    }}
                  >
                    <Image
                      className="junk-icon"
                      src={junk}
                      shape="square"
                      preview={false}
                    />
                    <p>
                      {currentSelectedMailMessage?.isJunk ? "Junked" : "Junk"}
                    </p>
                    {/* {junkLoader && (
                <div className="Statusloader">
                  <Spin indicator={<LoadingOutlined />} />
                </div>
              )} */}
                  </div>
                )}
              </>

              {selectedMailTypeAndMailData?.typeSystemName !== OUTBOX && (
                <div
                  className={`emailDetails__header--icon iconBorder ${
                    (isEmpty(currentSelectedMailMessage) ||
                      multipleSelectedEmail?.length > 0) &&
                    "disabled"
                  }`}
                  onClick={() => {
                    printFunction();
                  }}
                >
                  <Image src={printer} shape="square" preview={false} />
                  <p>Print</p>
                </div>
              )}

              {/* Group Chat Archive */}
              {selectedMailTypeAndMailData?.typeSystemName ===
                GROUP_CHAT_ARCHIEVE && (
                <div
                  className={`emailDetails__header--icon iconBorder ${
                    (isEmpty(currentSelectedMailMessage) ||
                      multipleSelectedEmail?.length > 0) &&
                    "disabled"
                  }`}
                  onClick={() => {
                    if (!isEmpty(selectedMailTypeAndMailData)) {
                      changeEmailStatus(
                        !currentSelectedMailMessage?.isTrashed &&
                          !currentSelectedMailMessage?.isTrashed_InstanceMessage
                          ? {
                              isTrashed: true,
                              isTrashed_InstanceMessage: true,
                              trash: TRASH,
                              move: GROUP_CHAT,
                            }
                          : {
                              isTrashed_InstanceMessage: false,
                              isTrashed: false,
                              move: GROUP_CHAT,
                            },

                        currentSelectedMailMessage?.isTrashed ||
                          currentSelectedMailMessage?.isTrashed_InstanceMessage
                          ? REMOVE
                          : ADD
                      );
                    }
                  }}
                >
                  <Image src={printer} shape="square" preview={false} />
                  <p>RecoverChat</p>
                </div>
              )}

              {/* Special Flags */}
              {(selectedMailTypeAndMailData?.typeDisplayName === SPECIALFLAG ||
                selectedMailTypeAndMailData?.typeDisplayName === SPECIALFLAG1 ||
                selectedMailTypeAndMailData?.typeDisplayName === SPECIALFLAG2 ||
                selectedMailTypeAndMailData?.typeDisplayName === SPECIALFLAG3 ||
                selectedMailTypeAndMailData?.typeDisplayName === SPECIALFLAG4 ||
                selectedMailTypeAndMailData?.typeDisplayName ===
                  SPECIALFLAG5) && (
                <>
                  <Dropdown
                    overlay={subMenuCompose}
                    arrow
                    // trigger={["click"]}
                    placement="bottomRight"
                  >
                    <div
                      className={`emailDetails__header--icon auto_reference_class ${
                        (isEmpty(currentSelectedMailMessage) ||
                          multipleSelectedEmail?.length > 0) &&
                        "disabled"
                      }`}
                    >
                      {currentSelectedMailMessage?.flagTypeName ===
                      FLAGTYPE1 ? (
                        <>
                          <Image src={specialFlag1} preview={false} />
                          <p>Flag 1</p>
                        </>
                      ) : currentSelectedMailMessage?.flagTypeName ===
                        FLAGTYPE2 ? (
                        <>
                          <Image src={specialFlag2} preview={false} />
                          <p>Flag 2</p>
                        </>
                      ) : currentSelectedMailMessage?.flagTypeName ===
                        FLAGTYPE3 ? (
                        <>
                          <Image src={specialFlag3} preview={false} />
                          <p>Flag 3</p>
                        </>
                      ) : currentSelectedMailMessage?.flagTypeName ===
                        FLAGTYPE4 ? (
                        <>
                          <Image src={specialFlag4} preview={false} />
                          <p>Flag 4</p>
                        </>
                      ) : currentSelectedMailMessage?.flagTypeName ===
                        FLAGTYPE5 ? (
                        <>
                          <Image src={specialFlag5} preview={false} />
                          <p>Flag 5</p>
                        </>
                      ) : (
                        <>
                          <Image src={specialFlag0} preview={false} />
                          <p>Special Flags</p>
                        </>
                      )}
                    </div>
                  </Dropdown>
                </>
              )}

              {/* auto referencing functionaity? */}

              {selectedMailTypeAndMailData?.typeDisplayName === INBOX && (
                <>
                  <div
                    className={`emailDetails__header--icon auto_reference_class ${
                      (isEmpty(currentSelectedMailMessage) ||
                        multipleSelectedEmail?.length > 0) &&
                      "disabled"
                    }`}
                    onClick={() => {
                      setOpenAutoReferencing(true);
                    }}
                  >
                    <AuditOutlined />
                    <p>Auto Refe..</p>
                  </div>

                  <div
                    className={`emailDetails__header--icon auto_reference_class ${
                      (isEmpty(currentSelectedMailMessage) ||
                        multipleSelectedEmail?.length > 0) &&
                      "disabled"
                    }`}
                    onClick={() => {
                      setShowAutoForwardModal(true);
                    }}
                  >
                    <AuditOutlined />
                    <p>Auto Forward</p>
                  </div>
                  <Divider type="vertical" style={{ height: "40px" }} />

                  {/* Auto Reference Modal */}
                  <Modal
                    onCancel={() => {
                      setOpenAutoReferencing(false);
                      setShowAutoreferenceData("");
                    }}
                    // onOk={()}
                    visible={openAutoReferencing}
                    footer={null}
                    width={700}
                    wrapClassName="auto_referencing_popup"
                  >
                    <div className="modal_auto_referencing">
                      <div
                        style={{ marginBottom: "20px" }}
                        className="header_auto_referencing"
                      >
                        Reference Assignment
                      </div>
                      <Form form={form}>
                        <Form.Item label="Sender Address" name="emailaddress">
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Subject"
                          name="subject"
                          className="form_input_subject"
                        >
                          <Input width={70} allowClear />
                        </Form.Item>
                        <Form.Item
                          label="Add Reference"
                          name="referenceId"
                          className="form_input_reference"
                        >
                          <Select
                            showSearch
                            placeholder="Select reference"
                            bordered={true}
                            optionFilterProp="label"
                            allowClear={true}
                            mode="multiple"
                            // getPopupContainer={(trigger) => trigger.parentNode}
                            onSearch={(value) => {
                              setSearchValueReference(value);
                              setFilterParticipantType("REF");
                            }}
                            autoComplete="off"
                          >
                            {filterReference.map((reference, index) => {
                              return (
                                <Option
                                  value={reference.code}
                                  key={index}
                                  // label={[reference.code, reference.title]}
                                  label={reference.title}
                                >
                                  {reference.code}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <Form.Item name="low_priority">
                          <Checkbox
                            onChange={(e) => {
                              // console.log("eeeeeeeee", e.target.checked);
                              setLowPriorityChecked(e.target.checked);
                            }}
                          >
                            Sent To Low Priority Mail
                          </Checkbox>
                        </Form.Item>
                      </Form>
                      <div className="button_save_auto_reference">
                        <Button
                          htmlType="submit"
                          onClick={() => onFinishAutoReferenceSave()}
                          loading={emailAutoReferenceAddResponseLoading}
                        >
                          Save Rule
                        </Button>
                      </div>
                      <div className="reference_listing_table">
                        Existing Rules
                        <div>
                          <Table
                            style={{ marginTop: "5px", marginBottom: "30px" }}
                            columns={columns}
                            sticky={true}
                            // rowKey={(record) => record.id}
                            scroll={{ y: true }}
                            className={`organizer__table complain-table auto_reference_table`}
                            // dataSource={autoReferenceListingData}
                            dataSource={autoReferenceListingResponse}
                            pagination={false}
                            loading={{
                              // indicator: <img src={LogoImage} height="auto" width="50px" />,
                              spinning: autoReferenceListingResponseLoading,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal>

                  {/* Auto Forward Modal */}
                  <CommonModal
                    visibility={showAutoForwardModal}
                    currentSelectedMailMessage={currentSelectedMailMessage}
                    setShowAutoForwardModal={setShowAutoForwardModal}
                    title={
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        Auto Forward Email-User Assign
                      </p>
                    }
                    content="autoForward"
                  />

                  {/* Special Flag */}

                  <Dropdown
                    overlay={subMenuCompose}
                    arrow
                    // trigger={["click"]}
                    placement="bottomRight"
                  >
                    <div
                      className={`emailDetails__header--icon auto_reference_class ${
                        (isEmpty(currentSelectedMailMessage) ||
                          multipleSelectedEmail?.length > 0) &&
                        "disabled"
                      }`}
                      // onClick={() => {
                      //   setLoadingMail(true);
                      //   if (!isEmpty(selectedMailTypeAndMailData)) {
                      //     changeEmailStatus(
                      //       currentSelectedMailMessage?.flagTypeName === NORMAL
                      //         ? {
                      //             flagTypeName: "flag_type_1",
                      //             flag_type_1: "flag_type_1",
                      //           }
                      //         : {
                      //             flag_type_1: "flag_type_1",
                      //             flagTypeName: NORMAL,
                      //           }
                      //     );
                      //   }
                      // }}
                    >
                      {currentSelectedMailMessage?.flagTypeName ===
                      FLAGTYPE1 ? (
                        <>
                          <Image src={specialFlag1} preview={false} />
                          <p>Flag 1</p>
                        </>
                      ) : currentSelectedMailMessage?.flagTypeName ===
                        FLAGTYPE2 ? (
                        <>
                          <Image src={specialFlag2} preview={false} />
                          <p>Flag 2</p>
                        </>
                      ) : currentSelectedMailMessage?.flagTypeName ===
                        FLAGTYPE3 ? (
                        <>
                          <Image src={specialFlag3} preview={false} />
                          <p>Flag 3</p>
                        </>
                      ) : currentSelectedMailMessage?.flagTypeName ===
                        FLAGTYPE4 ? (
                        <>
                          <Image src={specialFlag4} preview={false} />
                          <p>Flag 4</p>
                        </>
                      ) : currentSelectedMailMessage?.flagTypeName ===
                        FLAGTYPE5 ? (
                        <>
                          <Image src={specialFlag5} preview={false} />
                          <p>Flag 5</p>
                        </>
                      ) : (
                        <>
                          <Image src={specialFlag0} preview={false} />
                          <p>Special Flags</p>
                        </>
                      )}
                    </div>
                  </Dropdown>
                </>
              )}

              {(selectedMailTypeAndMailData?.typeSystemName === INBOX ||
                selectedMailTypeAndMailData?.typeSystemName ===
                  UNSOLICITED) && (
                <div
                  className={`emailDetails__header--icon auto_reference_class ${
                    (isEmpty(currentSelectedMailMessage) ||
                      multipleSelectedEmail?.length > 0) &&
                    "disabled"
                  }`}
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    changeEmailStatus({
                      isUnSolicited: true,
                      isUnSolicited_InstanceMessage: true,
                      unsolicited: "unsolicited",
                      isSpecialFlagOne: true,
                    })
                  }
                >
                  <MailOutlined style={{ color: "grey" }} />
                  <p>
                    {selectedMailTypeAndMailData?.typeSystemName === UNSOLICITED
                      ? "Move to Inbox"
                      : "Low Priority Mail"}
                  </p>
                </div>
              )}
            </>
          )}

          {/* IF Email Type is Not Business show above Navs like Read, Archiece, Pending and so on. End. */}

          {!(multipleSelectedEmail && multipleSelectedEmail?.length) &&
            !!zoomData && (
              <div
                className={`emailDetails__header--icon zoom_In_Out ${
                  isEmpty(currentSelectedMailMessage) && "disabled"
                }`}
              >
                {/* <p>Zoom</p> */}
                <p className="zoom_in_property">
                  <MinusCircleOutlined
                    onClick={() => {
                      zoomProp > 70 && setZoomProp(zoomProp - 10);
                    }}
                    className="zoominout_button"
                    span={true}
                  />
                  <button className="zoom_show_button">
                    <span
                      onClick={(e) => {
                        setZoomProp(100);
                      }}
                    >
                      &nbsp;Reset({zoomProp})%&nbsp;
                    </span>
                  </button>
                  <PlusCircleOutlined
                    onClick={() => {
                      zoomProp < 150 && setZoomProp(zoomProp + 10);
                    }}
                    className="zoominout_button"
                    span={true}
                    style={{ height: "15px" }}
                  />
                </p>
              </div>
            )}
          {multipleSelectedEmail && multipleSelectedEmail?.length > 0 && (
            <>
              <div
                className={`emailDetails__header--icon iconBorder`}
                onClick={() => {
                  const multipleType =
                    selectedMailTypeAndMailData?.typeDisplayName === INBOX
                      ? TRASH
                      : "doubleTrash";
                  changeMultipleEmailStatus(
                    multipleSelectedEmail,
                    multipleType
                  );
                }}
              >
                <Image src={trash} shape="square" preview={false} />
                <p> Delete All</p>
              </div>
              {selectedMailTypeAndMailData?.typeDisplayName !== OUTBOX && (
                <>
                  <div
                    className={`emailDetails__header--icon iconBorder`}
                    onClick={() => {
                      changeMultipleEmailStatus(
                        multipleSelectedEmail,
                        ARCHIEVED
                      );
                    }}
                  >
                    <Image src={archieve} shape="square" preview={false} />
                    <p>
                      {" "}
                      {selectedMailTypeAndMailData?.typeDisplayName ===
                      ARCHIEVED
                        ? "UnArchive All"
                        : "Archive All"}
                    </p>
                  </div>
                  <div
                    className={`emailDetails__header--icon iconBorder`}
                    onClick={() => {
                      setShowBusinessModal(true);
                    }}
                  >
                    <Image src={read} shape="square" preview={false} />
                    <p> Reference</p>
                  </div>
                </>
              )}
            </>
          )}
          <BusinessModal
            instanceMessageCenterPagination={currentSelectedMailMessage}
            showBusinessModal={showBusinessModal}
            setShowBusinessModal={setShowBusinessModal}
            referenceTags={mailReferenceGetDetailsResponse}
            currentSelectedMailMessage={currentSelectedMailMessage}
            multipleSelectedEmail={multipleSelectedEmail}
            mailMessageCenterPagination={mailMessageCenterPagination}
          />
        </div>
      ) : (
        <div className="emailDetails__header top-header-email">
          {" "}
          {isEmpty(multipleSelectedEmail) ? (
            <div
              className={`emailDetails__header--icon  ${
                currentSelectedMailMessage?.applicationTypeSystemName !==
                  "EMail" && "disabled"
              }`}
              onClick={() => {
                dispatch(draftMailExistOrNot(currentSelectedMailMessage?.id));
              }}
            >
              {/* {console.log(!emailExistOrNot ?message.error("Email doesnot exist") :`/email-compose?applicationType=composeDraft`)} */}
              {/* <Link
              to={`/email-compose?applicationType=composeDraft`}
              target="_blank"
              rel="opener"
              className="compose-email-header"
            > */}
              {/* <Image src={replyall} shape="square" preview={false} /> */}
              <EditFilled />
              <p>Compose</p>
              {/* </Link> */}
            </div>
          ) : (
            <div
              className={`emailDetails__header--icon iconBorder`}
              onClick={() => {
                const multipleType =
                  selectedMailTypeAndMailData?.typeDisplayName === INBOX
                    ? TRASH
                    : "doubleTrash";
                changeMultipleEmailStatus(multipleSelectedEmail, multipleType);
              }}
            >
              <Image src={trash} shape="square" preview={false} />
              <p> Delete All</p>
            </div>
          )}
          {isEmpty(multipleSelectedEmail) && (
            <div
              className={`emailDetails__header--icon ${
                isEmpty(currentSelectedMailMessage) && "disabled"
              }`}
              onClick={() =>
                changeEmailStatus(
                  !currentSelectedMailMessage?.isTrashed &&
                    !currentSelectedMailMessage?.isTrashed_InstanceMessage
                    ? {
                        isTrashed: true,
                        isTrashed_InstanceMessage: true,
                        trash: TRASH,
                      }
                    : {
                        isTrashed_InstanceMessage: false,
                        isTrashed: false,
                        trash: TRASH,
                      },
                  currentSelectedMailMessage?.isTrashed ||
                    currentSelectedMailMessage?.isTrashed_InstanceMessage
                    ? REMOVE
                    : ADD
                )
              }
            >
              <Image src={trash} shape="square" preview={false} />
              <p>{currentSelectedMailMessage?.isTrashed ? DELETED : DELETE}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MailMessageCenterHeader;
