import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Tooltip, Image, Modal, Form } from "antd";
import { todayGetAbsentUserList } from "services/redux/absent/absent.action";
import moment from "moment";
import InternalMessageGreen from "../../assets/svg/internal-msg-12-green.svg";
import AbsentModal from "./Modal/AbsentModal";
import { WechatOutlined } from "@ant-design/icons";
import InternalMCompose from "components/internalMNew";

const man = "assets/images/icons/man.png";

const AbsentList = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [internalVisible, setInternalVisible] = useState(false);

  const { todayAbsentListResp } = useSelector((state) => state.absent);

  const [modalShow, setModalShow] = useState(false);
  const [record, setRecord] = useState();

  const getTodayAbsentList = () => {
    dispatch(todayGetAbsentUserList());
  };

  useEffect(() => {
    getTodayAbsentList();
  }, []);

  const lateCount = todayAbsentListResp?.filter(
    (obj) => obj.isLate === true
  ).length;
  return (
    <div className="honor-wrapper">
      <div
        className="dashboards__events dashboards__body "
        style={{ height: "340px" }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">
            Absent List({todayAbsentListResp?.length - lateCount})/Late Login (
            {lateCount})
          </h4>
          <WechatOutlined
            onClick={() => {
              setInternalVisible(true);
            }}
          />
        </div>
        {!isEmpty(todayAbsentListResp) ? (
          <ul className="dashboards__todolists leave_absent_list list3">
            {todayAbsentListResp?.map((list, i) => (
              <div key={i}>
                <li className="dashboards__tenderlist--left attendance_list">
                  <div className="dashboards__tenderlist--left">
                    <h5>{list.fullName} </h5>
                  </div>
                  <div
                    className="dashboards__tenderlist--right"
                    style={list?.isLeave ? { paddingRight: "27px" } : {}}
                  >
                    {list.isLeave === true ? (
                      <span>
                        <Tooltip
                          title={`${moment(list.leaveDateFrom).format(
                            "DD MMM YYYY"
                          )}-${moment(list.leaveDateTo).format(
                            "DD MMM YYYY"
                          )} (${list.leaveDays} days)`}
                        >
                          <div
                            className="badge badge-outline--default"
                            style={{
                              color: "red",
                              background: list?.isLeave?.colorHexValue,
                            }}
                          >
                            In leave{" "}
                          </div>
                        </Tooltip>
                      </span>
                    ) : list.isLate === true ? (
                      <span>
                        <div
                          className="badge badge-outline--default"
                          style={{
                            color: "orange",
                            background: list?.isLeave?.colorHexValue,
                          }}
                        >
                          Late {list.lateTime}
                        </div>
                      </span>
                    ) : (
                      <>
                        <span>
                          <div className="badge badge-outline--default">
                            Not login
                          </div>
                        </span>
                        <img
                          alt="message"
                          src={InternalMessageGreen}
                          style={{ paddingLeft: "5px" }}
                          onClick={() => {
                            setModalShow(true);
                            setRecord(list);
                          }}
                        />
                      </>
                    )}
                  </div>
                </li>
              </div>
            ))}
          </ul>
        ) : (
          <div className="dashboards__todolists leave_absent_listss">
            <Image className="data-no-img" src={man} />
            <span>There are no Absent List.</span>
          </div>
        )}
      </div>
      <AbsentModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        record={record}
      />
      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="Absent List/Late Count"
        />
      </Modal>
    </div>
  );
};

export default AbsentList;
