import { Collapse, Form, Layout, Steps } from "antd";
import { useForm } from "antd/lib/form/Form";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleTadaDetails,
  tadaDetailsUpdateAccountant,
  tadaDetailsUpdateManagement,
  tadaDetailsUpdateSupervisor,
} from "services/redux/requistions/tadaDetails/action";
import storage from "utils/storage";
import AccountForm from "views/Requisition/AddUpdateTdaDetails/ApplicationPhase/AccountForm";
import ApproverForm from "views/Requisition/AddUpdateTdaDetails/ApplicationPhase/ApproverForm";
import SupervisorForm from "views/Requisition/AddUpdateTdaDetails/ApplicationPhase/SupervisorForm";
import SettlementAccountForm from "views/Requisition/AddUpdateTdaDetails/SettlementPhase/SettlementAccountForm";
import SettlementApproverForm from "views/Requisition/AddUpdateTdaDetails/SettlementPhase/SettlementApproverForm";
import SettlementSupervisorForm from "views/Requisition/AddUpdateTdaDetails/SettlementPhase/SettlementSupervisorForm";
import TadaDetailsForm from "./TadaDetailsForm";
import TadaDetailsSettlementForm from "./TadaDetailsSettlementForm";
import AccountInputForm from "./TadaForms/AccountInputForm";
import ManagementInputForm from "./TadaForms/ManagementInputForm";
import SettlementAccountantInputForm from "./TadaForms/SettlementAccountantInputForm";
import SettlementManagementInputForm from "./TadaForms/SettlementManagementInputForm";
import SettlementSupervisorInputForm from "./TadaForms/SettlementSupervisorInputForm";
import SupervisorInputForm from "./TadaForms/SupervisorInputForm";

const TadaDetails = ({ tadaId, setInstanceMessageCenterListing }) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [form2] = useForm();
  const { Step } = Steps;
  const { Panel } = Collapse;

  const {
    getTadaSingleDetailsResponse,
    getTadaSingleDetailsLoading,

    tadaDetailsUpdateSupervisorResponse,
    tadaDetailsUpdateSupervisorLoading,

    tadaDetailsUpdateManagementResponse,
    tadaDetailsUpdateManagementLoading,

    tadaDetailsUpdateAccountantResponse,
    tadaDetailsUpdateAccountantLoading,
  } = useSelector((state) => state.tadaDetails);

  const [details, setDetails] = useState({});
  const [status, setStatus] = useState();

  const [radioValue, setRadioValue] = useState();
  const [isApprovedOrRejected, setIsApprovedOrRejected] = useState(null);

  const [applicantState, setApplicantState] = useState(true);

  useEffect(() => {
    dispatch(getSingleTadaDetails(tadaId));
  }, [tadaId]);

  useEffect(() => {
    setDetails(getTadaSingleDetailsResponse);
  }, [getTadaSingleDetailsResponse]);

  console.log(
    "getTadaSingleDetailsResponse change",
    getTadaSingleDetailsResponse
  );
  useEffect(() => {
    setRadioValue(details?.isDomesticOrInternational);
  }, [details?.isDomesticOrInternational]);

  const handleAccordionChange = (key) => {
    setApplicantState(!applicantState); // Assumes only one panel can be expanded at a time
  };

  useEffect(() => {
    status === "Settlement Pending" ||
    status === "Supervisor Settlement Approved" ||
    status === "Supervisor Settlement Reject" ||
    status === "Management Settlement Approved" ||
    status === "Management Settlement Reject" ||
    status === "Account Settlement Approved" ||
    status === "Account Settlement Reject"
      ? setApplicantState(false)
      : setApplicantState(true);
  }, [status]);

  const onFinish = (values) => {
    let body = {
      ...values,
      isApprovedOrRejected: isApprovedOrRejected,
      isSettlement: false,
    };
    if (status === "Pending" || isEmpty(status)) {
      dispatch(
        tadaDetailsUpdateSupervisor(
          isApprovedOrRejected,
          body,
          tadaId,
          undefined,
          setInstanceMessageCenterListing
        )
      );
      dispatch(getSingleTadaDetails(tadaId));
    } else if (status === "Supervisor Approved") {
      dispatch(
        tadaDetailsUpdateManagement(
          isApprovedOrRejected,
          body,
          tadaId,
          undefined,
          setInstanceMessageCenterListing
        )
      );
      dispatch(getSingleTadaDetails(tadaId));
    } else if (status === "Management Advance Approved") {
      dispatch(
        tadaDetailsUpdateAccountant(
          isApprovedOrRejected,
          body,
          tadaId,
          undefined
        )
      );
      dispatch(getSingleTadaDetails(tadaId));
    }
  };

  const onFinish2 = (values) => {
    let body = {
      ...values,
      isApprovedOrRejected: isApprovedOrRejected,
      isSettlement: true,
    };
    if (status === "Settlement Pending" || isEmpty(status)) {
      dispatch(
        tadaDetailsUpdateSupervisor(
          isApprovedOrRejected,
          body,
          tadaId,
          undefined,
          setInstanceMessageCenterListing
        )
      );
    } else if (status === "Supervisor Settlement Approved") {
      dispatch(
        tadaDetailsUpdateManagement(
          isApprovedOrRejected,
          body,
          tadaId,
          undefined,
          setInstanceMessageCenterListing
        )
      );
    } else if (status === "Management Settlement Approved") {
      dispatch(
        tadaDetailsUpdateAccountant(
          isApprovedOrRejected,
          body,
          tadaId,
          undefined,
          setInstanceMessageCenterListing
        )
      );
    }
  };

  console.log("details here", details);

  useEffect(() => {
    const status1 = details?.tadaApplicationStatus?.displayName;
    setStatus(status1);
  }, [details]);

  const supervisorDescription =
    details?.tadaApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance !== null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksAdvance);

  const supervisorName =
    details?.tadaApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance != null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.participantName);

  const managementDescription =
    details?.tadaApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance !== null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksAdvance);

  const managementName =
    details?.tadaApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance != null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.participantName);

  const managementApprovedAmount = details?.totalAdvanceApprovedAmount;

  const accountantDescription =
    details?.tadaApplicationParticipantStatuses?.accountantList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance !== null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksAdvance);

  const accountantName =
    details?.tadaApplicationParticipantStatuses?.accountantList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksAdvance != null &&
          x?.approveRejectRemarksAdvance !== undefined
      )
      ?.map((x) => x?.participantName);

  const accountantApprovedAmount = details?.totalAdvanceApprovedAmount;

  const supervisorSettlementDescription =
    details?.tadaApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal !== null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksFinal);

  const supervisorSettlementName =
    details?.tadaApplicationParticipantStatuses?.supervisorList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal != null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.participantName);

  const managementSettlementDescription =
    details?.tadaApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal !== null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksFinal);

  const managementSettlementName =
    details?.tadaApplicationParticipantStatuses?.managementList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal != null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.participantName);

  const accountantSettlementDescription =
    details?.tadaApplicationParticipantStatuses?.accountantList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal !== null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.approveRejectRemarksFinal);

  const accountantSettlementName =
    details?.tadaApplicationParticipantStatuses?.accountantList
      ?.filter(
        (x) =>
          x?.approveRejectRemarksFinal != null &&
          x?.approveRejectRemarksFinal !== undefined
      )
      ?.map((x) => x?.participantName);

  const settlementBalance = details?.settlementBalanceAmount;

  form.setFieldsValue({
    commentValue: supervisorDescription ?? "",
    commentManagementValue: managementDescription,
    managementApprovedAmount: managementApprovedAmount,
    commentAccountantValue: accountantDescription,
    accountantApprovedAmount: accountantApprovedAmount,
  });

  form2.setFieldsValue({
    commentSettlementValue: supervisorSettlementDescription ?? "",
    commentSettlementManagementValue: managementSettlementDescription ?? "",
    commentSettlementAccountantValue: accountantSettlementDescription ?? "",
    settlementBalance: settlementBalance ?? "",
  });

  let localStorageId = storage.getItem("userId");
  //from details userid
  const fromUserId = details?.tadaApplicationParticipantStatuses?.fromList?.map(
    (userId) => userId?.participantUserId === localStorageId
  );
  const fromUser = fromUserId?.includes(true);
  //supervisor details userid
  const supervisorUserId =
    details?.tadaApplicationParticipantStatuses?.supervisorList?.map(
      (userId) => userId?.participantUserId === localStorageId
    );
  const supervisorUser = supervisorUserId?.includes(true);

  //accountant details userId
  const accountantUserId =
    details?.tadaApplicationParticipantStatuses?.accountantList?.map(
      (userId) => userId?.participantUserId === localStorageId
    );
  const accountantUser = accountantUserId?.includes(true);
  //management details userId
  const managementUserId =
    details?.tadaApplicationParticipantStatuses?.managementList?.map(
      (userId) => userId?.participantUserId === localStorageId
    );
  const managementUser = managementUserId?.includes(true);
  // console.log( supervisorUser)

  return (
    <React.Fragment>
      <Collapse
        accordion
        expandIconPosition="right"
        defaultActiveKey={1}
        style={{ backgroundColor: "#EEF2F8", marginTop: 6 }}
        onChange={handleAccordionChange}
        activeKey={applicantState ? 1 : 2}
      >
        <Panel
          header="Application Phase"
          key="1"
          style={{ fontWeight: "bold" }}
        >
          <Layout.Content>
            <Steps
              key="1"
              current={
                status === "Supervisor Approved"
                  ? 2
                  : status === "Management Advance Approved"
                  ? 3
                  : status === "Account Advance Approved" ||
                    status === "Settlement Pending" ||
                    status === "Supervisor Settlement Approved" ||
                    status === "Management Settlement Approved" ||
                    status === "Account Settlement Approved"
                  ? 5
                  : 1
              }
              size="small"
            >
              <Step title="Apply" />
              <Step
                title={
                  status === "Supervisor Approved" ||
                  status === "Management Advance Approved" ||
                  status === "Management Advance Reject" ||
                  status === "Account Advance Approved" ||
                  status === "Account Advance Reject" ||
                  status === "Settlement Pending" ||
                  status === "Supervisor Settlement Approved" ||
                  status === "Supervisor Settlement Reject" ||
                  status === "Management Settlement Approved" ||
                  status === "Management Settlement Reject" ||
                  status === "Account Settlement Approved" ||
                  status === "Account Settlement Reject"
                    ? "Approved"
                    : status === "Supervisor Reject"
                    ? "Rejected"
                    : "Pending"
                }
                description="(Supervisor)"
                status={status === "Supervisor Reject" ? "error" : ""}
              />
              <Step
                title={
                  status === "Management Advance Approved" ||
                  status === "Account Advance Approved" ||
                  status === "Account Advance Reject" ||
                  status === "Settlement Pending" ||
                  status === "Supervisor Settlement Approved" ||
                  status === "Supervisor Settlement Reject" ||
                  status === "Management Settlement Approved" ||
                  status === "Management Settlement Reject" ||
                  status === "Account Settlement Approved" ||
                  status === "Account Settlement Reject"
                    ? "Approved"
                    : status === "Management Advance Reject"
                    ? "Rejected"
                    : "Pending"
                }
                status={status === "Management Advance Reject" ? "error" : ""}
                description="(Management)"
              />
              <Step
                title={
                  status === "Account Advance Approved" ||
                  status === "Settlement Pending" ||
                  status === "Supervisor Settlement Approved" ||
                  status === "Supervisor Settlement Reject" ||
                  status === "Management Settlement Approved" ||
                  status === "Management Settlement Reject" ||
                  status === "Account Settlement Approved" ||
                  status === "Account Settlement Reject"
                    ? "Approved"
                    : status === "Account Advance Reject"
                    ? "Rejected"
                    : "Pending"
                }
                description="(Accountant)"
                status={status === "Account Advance Reject" ? "error" : ""}
              />

              <Step title="Approved" />
            </Steps>
          </Layout.Content>

          <Form form={form} layout="vertical" onFinish={onFinish}>
            {/* ======================= TADA DETAILS FORM ======================= */}
            <TadaDetailsForm details={details} />
            {/* ======================= SUPERVISOR PART ======================= */}
            {console.log("status", status)}

            {supervisorUser && status === "Pending" && (
              <SupervisorInputForm
                commentName="comment"
                loading={tadaDetailsUpdateSupervisorLoading}
                onClickRecommend={() => {
                  setIsApprovedOrRejected(1);
                }}
                onClickReject={() => {
                  setIsApprovedOrRejected(0);
                }}
              />
            )}
            {status === "Supervisor Approved" && (
              <SupervisorForm
                label={`Supervisor's Comment (${supervisorName})`}
                comment="commentValue"
              />
            )}
            {/* ======================= APPROVER PART ======================= */}
            {managementUser &&
              !fromUser &&
              status === "Supervisor Approved" && (
                <ManagementInputForm
                  commentName="comment"
                  totalName="totalAdvanceApprovedAmount"
                  loading={tadaDetailsUpdateManagementLoading}
                  onClickApprove={() => setIsApprovedOrRejected(1)}
                  onClickReject={() => setIsApprovedOrRejected(0)}
                />
              )}
            {status === "Management Advance Approved" && (
              <>
                <SupervisorForm
                  label={`Supervisor's Comment (${supervisorName})`}
                  comment="commentValue"
                />
                <ApproverForm
                  label={`Approvers's Comment (${managementName})`}
                  comment="commentManagementValue"
                  amount="managementApprovedAmount"
                />
              </>
            )}
            {/* ======================= ACCOUNTANT PART ======================= */}
            {accountantUser && status === "Management Advance Approved" && (
              <AccountInputForm
                commentName="comment"
                amountName="managementApprovedAmount"
                loading={tadaDetailsUpdateAccountantLoading}
                onClick={() => setIsApprovedOrRejected(1)}
              />
            )}
            {status === "Account Advance Approved" && (
              <>
                <SupervisorForm
                  label={`Supervisor's Comment (${supervisorName})`}
                  comment="commentValue"
                />
                <ApproverForm
                  label={`Approvers's Comment (${managementName})`}
                  comment="commentManagementValue"
                  amount="managementApprovedAmount"
                />
                <AccountForm
                  label={`Advance Arranged (${accountantName})`}
                  comment="commentAccountantValue"
                  amount="accountantApprovedAmount"
                />
              </>
            )}

            {/* ============================ SETTLEMENT PHASE DISPLAY =============================== */}
            {(status === "Settlement Pending" ||
              status === "Supervisor Settlement Approved" ||
              status === "Management Settlement Approved" ||
              status === "Account Settlement Approved") && (
              <>
                <SupervisorForm
                  label={`Supervisor's Comment (${supervisorName})`}
                  comment="commentValue"
                />
                <ApproverForm
                  label={`Approvers's Comment (${managementName})`}
                  comment="commentManagementValue"
                  amount="managementApprovedAmount"
                />
                <AccountForm
                  label={`Advance Arranged (${accountantName})`}
                  comment="commentAccountantValue"
                  amount="accountantApprovedAmount"
                />
              </>
            )}
          </Form>
        </Panel>
        <Layout style={{ padding: 8 }} />
        {status === "Pending" ||
        status === "Supervisor Approved" ||
        status === "Supervisor Reject" ||
        status === "Management Advance Approved" ||
        status === "Management Advance Reject" ||
        status === "Account Advance Approved" ||
        status === "Account Advance Reject" ? null : (
          <Panel
            forceRender={applicantState}
            header="Settlement Phase"
            key="2"
            style={{ fontWeight: "bold" }}
            disabled={
              status === "Pending" ||
              "Supervisor Approved" ||
              "Supervisor Reject" ||
              "Management Advance Approved" ||
              "Management Advance Reject"
            }
            collapsible={
              status === "Pending" ||
              "Supervisor Approved" ||
              "Supervisor Reject" ||
              "Management Advance Approved" ||
              "Management Advance Reject"
            }
          >
            <Steps
              key="2"
              current={
                status === "Supervisor Settlement Approved"
                  ? 2
                  : status === "Management Settlement Approved"
                  ? 3
                  : status === "Account Settlement Approved"
                  ? 5
                  : 1
              }
              size="small"
            >
              <Step title="Apply" />
              <Step
                title={
                  status === "Supervisor Settlement Approved" ||
                  status === "Management Settlement Approved" ||
                  status === "Management Settlement Reject" ||
                  status === "Account Settlement Approved" ||
                  status === "Account Settlement Reject"
                    ? "Approved"
                    : status === "Supervisor Settlement Reject"
                    ? "Rejected"
                    : "Pending"
                }
                description="(Supervisor)"
                status={
                  status === "Supervisor Settlement Reject" ? "error" : ""
                }
              />
              <Step
                title={
                  status === "Management Settlement Approved" ||
                  status === "Account Settlement Approved" ||
                  status === "Account Settlement Reject"
                    ? "Approved"
                    : status === "Management Settlement Reject"
                    ? "Rejected"
                    : "Pending"
                }
                status={
                  status === "Management Settlement Reject" ? "error" : ""
                }
                description="(Management)"
              />
              <Step
                title={
                  status === "Account Settlement Approved"
                    ? "Approved"
                    : status === "Account Settlement Reject"
                    ? "Rejected"
                    : "Pending"
                }
                description="(Accountant)"
                status={status === "Account Settlement Reject" ? "error" : ""}
              />

              <Step title="Approved" />
            </Steps>
            <Form layout="vertical" form={form2} onFinish={onFinish2}>
              <TadaDetailsSettlementForm details={details} />
              {/* ======================= SUPERVISOR PART ======================= */}
              {supervisorUser && status === "Settlement Pending" && (
                <SettlementSupervisorInputForm
                  commentName="settlementComment"
                  loading={tadaDetailsUpdateSupervisorLoading}
                  onClickRecommend={() => setIsApprovedOrRejected(3)}
                  onClickReject={() => setIsApprovedOrRejected(2)}
                />
              )}
              {status === "Supervisor Settlement Approved" && (
                <SettlementSupervisorForm
                  label={`Supervisor's Comment (${supervisorSettlementName})`}
                  comment="commentSettlementValue"
                />
              )}
              {/* ======================= APPROVER PART ======================= */}
              {managementUser &&
                !fromUser &&
                status === "Supervisor Settlement Approved" && (
                  <SettlementManagementInputForm
                    commentName="settlementComment"
                    loading={tadaDetailsUpdateManagementLoading}
                    onClickApprove={() => setIsApprovedOrRejected(3)}
                    onClickReject={() => setIsApprovedOrRejected(2)}
                  />
                )}
              {status === "Management Settlement Approved" && (
                <>
                  <SettlementSupervisorForm
                    label={`Supervisor's Comment (${supervisorSettlementName})`}
                    comment="commentSettlementValue"
                  />
                  <SettlementApproverForm
                    label={`Approvers's Comment (${managementSettlementName})`}
                    comment="commentSettlementManagementValue"
                  />
                </>
              )}
              {/* ======================= ACCOUNTANT PART ======================= */}
              {accountantUser &&
                status === "Management Settlement Approved" && (
                  <SettlementAccountantInputForm
                    commentName="settlementComment"
                    amountName="settlementBalance"
                    loading={tadaDetailsUpdateAccountantLoading}
                    onClick={() => setIsApprovedOrRejected(3)}
                  />
                )}
              {status === "Account Settlement Approved" && (
                <>
                  <SettlementSupervisorForm
                    label={`Supervisor's Comment (${supervisorSettlementName})`}
                    comment="commentSettlementValue"
                  />
                  <SettlementApproverForm
                    label={`Approvers's Comment (${managementSettlementName})`}
                    comment="commentSettlementManagementValue"
                  />
                  <SettlementAccountForm
                    label={`Advance Arranged (${accountantSettlementName})`}
                    comment="commentSettlementAccountantValue"
                    amount="settlementBalance"
                  />
                </>
              )}
            </Form>
          </Panel>
        )}
      </Collapse>
    </React.Fragment>
  );
};

export default TadaDetails;
