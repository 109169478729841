import { Button, Col, Form, Input, Modal, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendBirthdayMessage } from "services/redux/email/instanceMessageCenter/action";

const BirthdayModal = ({ birthdayModal, setBirthdayModal, person }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { birthdayAniversaryMessageLoading } = useSelector(
    (state) => state.instanceMessageCenter
  );

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const sendMessage = (values) => {
    const body = {
      appUserId: person?.id,
      messageText: values?.messageText,
      employeeCelebrationType: person?.type === "Birthday" ? 1 : 2,
    };
    dispatch(sendBirthdayMessage(body))
      .then(setBirthdayModal(false))
      .then(form.resetFields());
  };

  return (
    <>
      <Modal
        title="Send Message"
        visible={birthdayModal}
        onCancel={() => {
          setBirthdayModal(false);
        }}
        footer={null}
      >
        <Row
          style={{
            fontWeight: "bold",
            margin: "auto !important",
            display: "flex",
            width: "100%",
          }}
        >
          Send your wishes for {person?.type} to {person?.name}
        </Row>

        <Form
          form={form}
          onFinish={sendMessage}
          {...layout}
          style={{ paddingTop: "10px" }}
        >
          <Form.Item name="messageText" label="Message Text">
            <Input.TextArea autoSize={{ minRows: 4 }} />
          </Form.Item>
          <Row>
            <Col span={21}></Col>
            <Col span={4} style={{ float: "right", paddingTop: "10px" }}>
              <Button
                type="primary "
                htmlType="submit"
                style={{ paddingTop: "5px" }}
                loading={birthdayAniversaryMessageLoading}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default BirthdayModal;
