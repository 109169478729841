import { NOTIFICATIONS_TYPES } from "../../types";

const INITIAL_STATE = {
    allBellNotifications: [],
    connection: null,
    bellCount: 0
};

export default function notificationsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        // Set Bell Notification
        case NOTIFICATIONS_TYPES.SET_BELL_NOTIFICATION_LISTS:
            return {
                ...state,
                allBellNotifications: action.payload,
            };
        // Set Connection
        case NOTIFICATIONS_TYPES.SET_CONNECTION:
            return {
                ...state,
                connection: action.payload,
            };
        // Bell Notification Count
        case NOTIFICATIONS_TYPES.GET_BELL_NOTIFICATION_COUNT:
            return {
                ...state,
                bellCount: action.payload,
            };
        default:
            return { ...state };
    }
}
