import React, { useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Collapse,
  Modal,
  Spin,
  Checkbox,
  Textarea,
  AutoComplete,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { validateMessages } from "utils/misc";
import {
  PlusOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  referenceOthersAdd,
  referenceOthersUpdate,
  ReferenceOthersPaginationWithRootLevel,
  referenceOthersCombineAdd,
  referenceOthersCombineUpdate,
} from "services/redux/Reference/Others/action";
import { fromPairs, isEmpty, uniq, uniqBy } from "lodash";
import Summary from "./Summary";
import QuickAddSecondaryTag from "./QuickAddSecondaryTag";

export const AddUpdateOther = ({
  getDetails,
  setIsUpdate,
  setEditForm,
  referenceType,
  isUpdate,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [secondaryTagForm] = Form.useForm();
  const [rootLevel, setRootLevel] = useState(1);
  const [primaryTagListing, setPrimaryTagListing] = useState();
  const [secondaryTagListing, setSecondaryTagListing] = useState([]);
  const [isQuickAddSecondaryTag, setIsQuickAddSecondaryTag] = useState(false);
  const [quickAddSecondaryValue, setQuickAddSecondaryValue] = useState("");
  const pageSize = 30;

  // antd form
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const {
    referenceOthersPaginationWithRootLevelLoading,
    referenceOthersPaginationWithRootLevelResponse,
    referenceOthersAddResponse,
    referenceOthersAddLoading,
    referenceOthersCombineAddResponse,
    referenceOthersCombineAddLoading,
    referenceOthersLoading,
    referenceOthersDetailsResponse,
    referenceOthersUpdateResponse,
    referenceOthersCombineUpdateResponse,
    referenceOthersCombineUpdateLoading,
  } = useSelector((state) => state.referenceOthers);

  useEffect(() => {
    if (isEmpty(value)) return;

    const timeoutId = setTimeout(() => getReferenceListWithRootLevel(), 200);
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    getReferenceListWithRootLevel();
  }, []);

  useEffect(() => {
    if (referenceType && isUpdate == "add") {
      form.resetFields();
    }
  }, [referenceType, isUpdate]);

  useEffect(() => {
    if (!isEmpty(getDetails) && isUpdate == "edit") {
      if (getDetails.rootLevel == "3") {
        form.setFieldsValue({
          title: getDetails.title,
          primaryDescription: getDetails.primaryDescription,
          titleSecondary: getDetails.titleSecondary,
          description: getDetails.description,
        });
      } else if (getDetails.rootLevel == "2") {
        form.setFieldsValue({
          title: getDetails.title,
          description: getDetails.description,
        });
      }
    }
  }, [getDetails]);

  useEffect(() => {
    if (
      !isEmpty(referenceOthersPaginationWithRootLevelResponse) &&
      rootLevel === 1
    ) {
      setPrimaryTagListing(referenceOthersPaginationWithRootLevelResponse);
    } else if (
      !isEmpty(referenceOthersPaginationWithRootLevelResponse) &&
      rootLevel === 2
    ) {
      let rootLevelTwoList = uniqBy(
        referenceOthersPaginationWithRootLevelResponse?.items,
        "title"
      );

      setSecondaryTagListing(rootLevelTwoList);
    }
  }, [referenceOthersPaginationWithRootLevelResponse]);

  const getReferenceListWithRootLevel = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    loadMore = false
  ) => {
    dispatch(
      ReferenceOthersPaginationWithRootLevel(
        {
          MaxResultCount,
          SkipCount,
          rootLevel: rootLevel,
          SearchKeyword: value,
        },
        loadMore
      )
    );
  };

  useEffect(() => {
    if (
      !isEmpty(referenceOthersCombineUpdateResponse) ||
      !isEmpty(referenceOthersCombineAddResponse)
    ) {
      setTimeout(() => {
        form.resetFields();
        setSecondaryTagListing([]);
      }, 1000);
      if (!isEmpty(referenceOthersCombineUpdateResponse)) {
        setIsUpdate("details");
      }
    }
  }, [referenceOthersCombineAddResponse, referenceOthersCombineUpdateResponse]);

  useEffect(() => {
    if (
      !isEmpty(referenceOthersAddResponse) ||
      !isEmpty(referenceOthersUpdateResponse)
    ) {
      // setTimeout(() => {
      //   form.resetFields();
      // }, 1000);
      if (!isEmpty(referenceOthersUpdateResponse)) setIsUpdate("details");
    }
  }, [referenceOthersAddResponse, referenceOthersUpdateResponse]);

  const onFinish = (values) => {
    const { primaryDescription, ...newValues } = values;
    if (!isEmpty(referenceOthersDetailsResponse) && isUpdate == "edit") {
      if (referenceOthersDetailsResponse?.rootLevel == "3") {
        let updateDetails = {
          id: referenceOthersDetailsResponse?.id,
          ...newValues,
        };
        dispatch(referenceOthersCombineUpdate(updateDetails));
      }
    } else {
      dispatch(referenceOthersCombineAdd(newValues));
    }
  };

  const secondaryTagAdd = (values) => {
    let body = {
      ...values,
    };
    if (!isEmpty(referenceOthersDetailsResponse) && isUpdate == "edit") {
      if (referenceOthersDetailsResponse?.rootLevel == "2") {
        let updateDetails = { id: referenceOthersDetailsResponse?.id, ...body };
        dispatch(referenceOthersUpdate(updateDetails));
      }
    } else {
      dispatch(referenceOthersAdd(body));
    }
  };

  const handleQuickAddSecondaryTag = () => {
    setIsQuickAddSecondaryTag(false);
  };

  const referenceOthers = () => {
    return (
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className="other-ref-form"
      >
        <div className="global-form-flex">
          <div className="global-group">
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Primary</label>
              </div>
              <div className="global-form-input">
                <Form.Item name="title" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select primary Tag"
                    allowClear
                    optionFilterProp="label"
                    showSearch={true}
                    onClear={() => {
                      form.setFieldsValue({
                        primaryDescription: "",
                      });
                    }}
                    onSelect={(value, option) => {
                      form.setFieldsValue({
                        primaryDescription: option.label[1],
                      });
                    }}
                  >
                    {primaryTagListing?.items?.map((reference) => (
                      <Option
                        value={reference.title}
                        key={reference.id}
                        label={[reference.title, reference.description]}
                      >
                        <>
                          <span>{reference.title}</span>
                          <h5>{reference.description}</h5>
                        </>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Description</label>
              </div>
              <div className="global-form-input">
                <Form.Item name="primaryDescription">
                  <Input disabled rows={2} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="global-group">
            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Secondary</label>
              </div>
              <div className="global-form-input">
                <Form.Item name="titleSecondary" rules={[{ required: true }]}>
                  <Select
                    placeholder="Select Secondary Tag"
                    size="default"
                    allowClear
                    showSearch={true}
                    onClear={() => {
                      form.setFieldsValue({
                        description: "",
                      });
                      setValue("");
                      setSecondaryTagListing([]);
                    }}
                    onSelect={(value, option) => {
                      form.setFieldsValue({
                        description: option.label[1],
                      });
                    }}
                    onSearch={(value) => {
                      setValue(value);
                      setRootLevel(2);
                    }}
                    optionFilterProp="label"
                    notFoundContent={
                      // value ? (
                      //   referenceOthersPaginationWithRootLevelLoading ? (
                      //     <Spin />
                      //   ) : null
                      // ) : null
                      !isEmpty(value) ? (
                        referenceOthersPaginationWithRootLevelLoading ? (
                          <Spin />
                        ) : (
                          isEmpty(
                            referenceOthersPaginationWithRootLevelResponse?.items
                          ) &&
                          value?.length > 2 && (
                            <div>
                              {`Create "${value}"`}
                              <Button
                                type="primary"
                                onClick={() => {
                                  // setOpenCreateEmployee({
                                  //   type: "client",
                                  //   open: true,
                                  // });
                                  setIsQuickAddSecondaryTag(true);
                                  setQuickAddSecondaryValue(value);
                                }}
                              >
                                Add Secondary Tag
                              </Button>
                            </div>
                          )
                        )
                      ) : null
                    }
                  >
                    {secondaryTagListing.map((reference) => (
                      <Option
                        value={reference.title}
                        key={reference.id}
                        label={[reference.title, reference.description]}
                      >
                        <>
                          <span>{reference.title}</span>
                          <h5>{reference.description}</h5>
                        </>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="global-form-group calc">
              <div className="global-form-label">
                <label>Description</label>
              </div>
              <div className="global-form-input">
                <Form.Item name="description">
                  <Input rows={2} />
                </Form.Item>
              </div>
            </div>

            {/* Secondary tag */}
            <Modal
              title="Quick Add Secondary Tag"
              visible={isQuickAddSecondaryTag}
              footer={null}
              // onCancel={() => setIsQuickAddSecondaryTag(false)}
              onCancel={handleQuickAddSecondaryTag}
            >
              <QuickAddSecondaryTag
                form={form}
                close={handleQuickAddSecondaryTag}
                name={quickAddSecondaryValue}
              />
            </Modal>
          </div>
          <div className="global-form-group calc">
            <div className="global-form-label"></div>
            <div className="global-form-input">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.title !== currentValues.title ||
                  prevValues.titleSecondary !== currentValues.titleSecondary
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("title") &&
                  getFieldValue("titleSecondary") && (
                    <Form.Item>
                      <Button
                        type="primary"
                        className="save-btn"
                        htmlType="submit"
                        loading={
                          referenceOthersCombineAddLoading ||
                          referenceOthersCombineUpdateLoading
                        }
                        disabled={
                          referenceOthersCombineAddLoading ||
                          referenceOthersCombineUpdateLoading
                        }
                      >
                        Save
                      </Button>
                    </Form.Item>
                  )
                }
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    );
  };

  const referenceTags = () => {
    return (
      <Form
        onFinish={secondaryTagAdd}
        autoComplete="off"
        form={form}
        className="other-ref-form w-50"
      >
        <div className="global-form-flex">
          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>Secondary</label>
            </div>
            <div className="global-form-input">
              <Form.Item name="title" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="global-form-group calc">
            <div className="global-form-label">
              <label>Description</label>
            </div>
            <div className="global-form-input">
              <Form.Item name="description" rules={[{ required: true }]}>
                <Input rows={2} />
              </Form.Item>
            </div>
          </div>
          <div className="global-form-group calc">
            <div className="global-form-label"> </div>
            <div className="global-form-input">
              <Form.Item>
                <Button
                  type="primary"
                  className="save-btn"
                  htmlType="submit"
                  loading={referenceOthersAddLoading || referenceOthersLoading}
                  disabled={referenceOthersAddLoading || referenceOthersLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    );
  };
  return (referenceType == "level3" && isUpdate == "add") ||
    (referenceOthersDetailsResponse?.rootLevel == "3" && isUpdate == "edit")
    ? referenceOthers()
    : referenceTags();
};
