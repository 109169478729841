import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from "antd";
import { validateMessages } from "utils/misc";
import {
  contactLabelGroupAdd,
  contactLabelGroupUpdate,
} from "services/redux/contact/contact.action";

const { Header, Footer } = Layout;

const AddUserDrawer = ({
  onAddUpdateCountryClose,
  drawerVisibility,
  isUpdateCountry,
}) => {
  const { contactLabelsAddResponse, contactLabelsUpdateResponse } = useSelector((state) => state.contact);

  const [form] = Form.useForm();

  form.resetFields();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUpdateCountry) return;
    form.setFieldsValue({
      ...isUpdateCountry,
    });
  }, [isUpdateCountry]);

  useEffect(() => {
    onAddUpdateCountryClose();
  }, [contactLabelsAddResponse, contactLabelsUpdateResponse]);

  const onSubmit = async () => {
    try {
      let values = await form.validateFields();
      if (isUpdateCountry) {
        dispatch(contactLabelGroupUpdate(isUpdateCountry.id, values));
      } else {
        dispatch(contactLabelGroupAdd(values));
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  return (
    <Drawer
      visible={drawerVisibility}
      width={472}
      maskClosable={false}
      className="drawer"
      onClose={onAddUpdateCountryClose}
      closable={false}
    >
      <Header className='drawer__header'>
        <h4 className='drawer__header--title'>
          {`${isUpdateCountry ? "Update" : "Create"} Label Group Name`}
        </h4>
      </Header>
      <Form
        layout='vertical'
        form={form}
        name='control-hooks'
        className="drawer-form-wrapper"
        validateMessages={validateMessages}
      >
        <div className="drawer-form-row">
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name='name'
                label='Label Group Name'
                rules={[{ required: true }]}>
                <Input autoComplete="off" />
              </Form.Item>
            </div>
        </div>
        <div className="model-footer">
          <Button onClick={onAddUpdateCountryClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} type="primary">
            Submit
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default AddUserDrawer;
