import React from "react";
import { AllProfileInfos } from "../../Profile";

const UpdateProfileView = ({
  isUpdateCredentials,
  showAddUserDrawer,
  form,
  userEmployeeId,
}) => {
  return (
    <>
      <AllProfileInfos
        isFromDrawer={true}
        updateUsers={isUpdateCredentials}
        form={form}
        userEmployeeId={userEmployeeId}
      />
    </>
  );
};

export default UpdateProfileView;
