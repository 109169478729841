import { getService, postService } from "services/commonServices";
import { appBaseUrl } from "utils/config";

export class HomeServices  {
    englishToNepaliConverter(date){
        let url = `${appBaseUrl}/nepaliDateConveter/convertSingleNepaliDate?englishDate=${date}`;
        const data = getService(url);
        return data;
    }
    attendenceLateReasonAdd(body){
        let url = `${appBaseUrl}/attendance/attendanceLateReasonAdd`;
        const data = postService(url , body);
        return data ;
    }
}