import queryString from 'query-string';
import { postService } from '../../commonServices';
import { rootBaseUrl, baseUrl, appBaseUrl } from 'utils/config';

export class AuthService {
	loginUser(body) {
		let credentials = {
			...body,
			client_id: 'Framework_App',
			grant_type: 'password',
		};
		let url = `${baseUrl}/connect/token`;
		let data = postService(url, queryString.stringify(credentials));
		return data;
	}

	changePassword(body) {
		// let url=`${rootBaseUrl}/identity​/my-profile​/change-password`; //500
		let url = `${rootBaseUrl}/identity/my-profile/change-password`; //403
		let data = postService(url, body);
		return data;
	}
	changeUserPassword(body) {
		let url = `${appBaseUrl}/employeeRequest/changePasswordForUser`;
		let data = postService(url, body);
		return data;
	}
	checkDaillyAttendence(){
		let url = `${appBaseUrl}/attendance/checkInDailyAttendanceNew`;
		let data = postService(url);
		return data;
	}

	// leftUsers(){
	// 	let url = `${appBaseUrl}/attendance/checkInDailyAttendanceNew`;
	// 	let data = postService(url);
	// 	return data;
	// }
}
