import moment from "moment";
import React from "react";

const LeaveDetailsList = ({ list, width, style }) => {
  return (
    <>
      <span style={{ paddingLeft: 25 }}>
        From:
        {moment(list.leaveDayFrom).format("DD-MMM-YYYY")}{" "}
        {width < 1509 && <br />}
        <span style={style}>
          To:
          {list.leaveDayTo && moment(list.leaveDayTo).format("DD-MMM-YYYY")}(
          {list?.leaveDays} days)
        </span>
      </span>
      <br></br>
      <span style={{ paddingLeft: 25 }}>
        Leave Type:{list?.leaveApplicationType}
      </span>
      <br />
      <div style={{ paddingLeft: 25 }}>Reason:{list?.leaveReason}</div>
    </>
  );
};

export default LeaveDetailsList;
