const Permission = ({
  loan,
  procurement,
  tada,
  guestEntertainment,
  storage,
  loanApplicationDataGetDetails,
  procurementDetails,
  getTadaSingleDetailsResponse,
  guestEntertainmentDetailResponse,
}) => {
  let localStorageId = storage.getItem("userId");

  //ORDER=From-Supervisor-Accountant-Management--Loan-Procurement-Leave-TADA

  const fromUserId = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses?.fromList?.map(
        (userId) => userId?.participantUserId === localStorageId
      )
    : "";
  const fromUser = fromUserId?.includes(true);

  //Supervisor=Status,Name,Id,User,Descirption
  const supervisorStatus = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
        ?.supervisorList?.[0]?.loanApplicationStatus?.displayName
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses
        ?.supervisorList?.[0]?.procurementApplicationStatus?.displayName
    : tada
    ? getTadaSingleDetailsResponse?.tadaApplicationStatus?.displayName
    : guestEntertainment
    ? guestEntertainmentDetailResponse?.currentGuestEntertainmentStatus
    : "";

  const supervisorUsers =
    guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.filter(
      (user) =>
        user?.participantDetails?.participantId === localStorageId &&
        user?.participantTypeName === "Supervisor"
    );

  const isSupervisor = supervisorUsers?.some(
    (user) => user?.participantDetails?.participantId === localStorageId
  );

  const supervisorName = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
        ?.supervisorList?.[0]?.participantUsername
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses
        ?.supervisorList?.[0]?.participantUsername
    : "";

  const supervisorUserId = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses?.supervisorList?.map(
        (userId) => userId?.participantUserId === localStorageId
      )
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses?.supervisorList?.map(
        (userId) => userId?.participantUserId === localStorageId
      )
    : "";

  const supervisorUser = supervisorUserId?.includes(true);

  const supervisorDescription = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
        ?.supervisorList?.[0]?.approveRejectRemarks
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses
        ?.supervisorList?.[0]?.approveRejectRemarks
    : "";

  //Accountant-Status,Name,Id,User,Description
  const accountantStatus = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
        ?.accountantList?.[0]?.loanApplicationStatus?.displayName
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses
        ?.accountantList?.[0]?.procurementApplicationStatus?.displayName
    : procurement
    ? guestEntertainmentDetailResponse?.currentGuestEntertainmentStatus
    : "";

  const accountantName = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
        ?.accountantList?.[0]?.participantUsername
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses
        ?.accountantList?.[0]?.participantUsername
    : "";

  const accountantUsers =
    guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.filter(
      (user) =>
        user?.participantDetails?.participantId === localStorageId &&
        user?.participantTypeName === "Accountant"
    );
  const isAccountant = accountantUsers?.some(
    (user) => user?.participantDetails?.participantId === localStorageId
  );

  const accountantUserId = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses?.accountantList?.map(
        (userId) => userId?.participantUserId === localStorageId
      )
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses?.accountantList?.map(
        (userId) => userId?.participantUserId === localStorageId
      )
    : "";
  const accountantUser = accountantUserId?.includes(true);

  const accountantDescription = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
        ?.accountantList?.[0]?.approveRejectRemarks
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses
        ?.accountantList?.[0]?.approveRejectRemarks
    : "";

  //Management-Staus,Name,Id,User,Description
  const managementStatus = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
        ?.managementList?.[0]?.loanApplicationStatus?.displayName
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses
        ?.managementList?.[0]?.procurementApplicationStatus?.displayName
    : guestEntertainment
    ? guestEntertainmentDetailResponse?.currentGuestEntertainmentStatus
    : "";

  const managementName = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
        ?.managementList?.[0]?.participantUsername
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses
        ?.managementList?.[0]?.participantUsername
    : "";

  const managementUserId = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses?.managementList?.map(
        (userId) => userId?.participantUserId === localStorageId
      )
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses?.managementList?.map(
        (userId) => userId?.participantUserId === localStorageId
      )
    : "";
  const managementUser = managementUserId?.includes(true);

  const managementUsers =
    guestEntertainmentDetailResponse?.guestEntertainmentParticipantDetails?.filter(
      (user) =>
        user?.participantDetails?.participantId === localStorageId &&
        user?.participantTypeName === "Management"
    );
  const isManagement = managementUsers?.some(
    (user) => user?.participantDetails?.participantId === localStorageId
  );

  const managementDescription = loan
    ? loanApplicationDataGetDetails?.loanApplicationParticipantStatuses
        ?.managementList?.[0]?.approveRejectRemarks
    : procurement
    ? procurementDetails?.procurementApplicationParticipantStatuses
        ?.managementList?.[0]?.approveRejectRemarks
    : "";

  return {
    fromUserId,
    fromUser,
    supervisorStatus,
    supervisorName,
    supervisorUserId,
    supervisorUser,
    supervisorUsers,
    isSupervisor,
    supervisorDescription,
    accountantStatus,
    accountantName,
    accountantUser,
    accountantUsers,
    isAccountant,
    accountantDescription,
    managementStatus,
    managementName,
    managementUserId,
    managementUser,
    managementUsers,
    isManagement,
    managementDescription,
  };
};

export default Permission;
