import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import {
  Tooltip,
  Image,
  Modal,
  Form,
  Layout,
  Tag,
  Input,
  Checkbox,
  Button,
} from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import GroupChatPresentationTable from "components/GroupChat/groupChatPresentation";
import GroupChatModal from "components/GroupChat/groupChatModal";
import ChatDetails from "views/GlobalEmailChat";
import {
  getMailMessageCenterPagination,
  getMailMessageCenterPaginationDashboard,
} from "services/redux/email/mailMessageCenter/action";
import InternalMCompose from "components/internalMNew";
import moment from "moment";
import {
  StarFilled,
  PlusOutlined,
  FilterOutlined,
  CloseCircleOutlined,
  WechatOutlined,
} from "@ant-design/icons";

const man = "assets/images/icons/man.png";

const GroupChat = () => {
  const {
    mailBodyGetDetailsResponse,
    mailMessageCenterPagination,
    mailMessageCenterPaginationLoading,
    mailMessageCenterPaginationDasboard,
    mailMessageCenterPaginationDasboardLoading,
    mailBodyGetDetailsLoading,
    mailScrollLoader,
    changeInstanceMessageonStatusChange,
    infiniteScrollLoading,
    mailMessagePaginationSuccess,
  } = useSelector((state) => state.mailMessageCenter);
  // console.log("mailMessageCenterPaginationDasboard: ",mailMessageCenterPaginationDasboard)
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [showChat, setShowChat] = useState(false);
  const [chatHeight, setChatHeight] = useState(false);
  const [smallChatHeight, setSmallChatHeight] = useState(false);
  const [chatBox, setchatBox] = useState(true);
  const [hideInternalMessage, setHideInternalMessage] = useState(false);

  const [modalData, setModalData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [internalVisible, setInternalVisible] = useState(false);

  const [goupChatTableData, setGroupChatTableData] = useState([]);

  // search
  const [showAdvanceSearchModal, setShowAdvanceSearchModal] = useState(false);
  const [isAscendingOrder, setIsAscendingOrder] = useState(false);
  const handleOk = () => {
    setShowAdvanceSearchModal(false);
  };
  const handleCancel = () => {
    setShowAdvanceSearchModal(false);
    form.resetFields();
  };
  const handleAscendingOrderChange = (checked) => {
    setIsAscendingOrder(!isAscendingOrder);
  };
  const handleFormSubmit = () => {
    // setShowResetButton(true);
    setShowAdvanceSearchModal(false);
    if (isAscendingOrder === true) {
      // dispatch(
      //   employeesPagination(
      //     {
      //       EmployeeStatus: isEmployeeStatusChecked,
      //       MaxResultCount: 200,
      //       // SkipCount: 0,
      //     },
      //     false
      //   )
      // );
      getGroupChat(40, 0, false, "groupchat", "title", "asc");
    } else {
      // getData();
      console.log("NOT CHECKED");
    }
  };

  const getGroupChat = (
    MaxResultCount = 40,
    SkipCount = 0,
    loadMore = false,
    MessageCenterTypeName = "groupchat",
    SortKeyword = null,
    Sorting = null
  ) => {
    let appendUrl = `skipCount=${SkipCount}&maxResultCount=${MaxResultCount}`;
    if (MessageCenterTypeName) {
      appendUrl += `&messageCenterTypeName=${MessageCenterTypeName}`;
    }
    if (SortKeyword) {
      appendUrl += `&SortKeyword=${SortKeyword}`;
    }
    if (Sorting) {
      appendUrl += `&Sorting=${Sorting}`;
    }
    dispatch(getMailMessageCenterPaginationDashboard(appendUrl, loadMore));
  };

  useEffect(() => {
    getGroupChat();
  }, []);

  // useEffect(() => {
  //     setGroupChatTableData();
  //     if(!isEmpty(mailMessageCenterPagination?.items)) {
  //     }
  // }, [mailMessageCenterPagination])

  const columns = [
    {
      title: "S.N",
      key: "sno",
      render: (text, object, index) => index + 1,
      width: "5%",
    },
    {
      title: "Title",
      dataIndex: "subject",
      key: "subject",
      render: (text, record) => {
        return (
          <>
            <span> {record?.subject}</span>
          </>
        );
      },
    },
    {
      title: "Message",
      dataIndex: "bodyPlainText",
      key: "bodyPlainText",
    },
    {
      title: "To",
      dataIndex: "tos",
      key: "tos",
      width: "15%",
      render: (text, record) => {
        const filteredTos = record?.applicationTypeDetails?.tos.filter(
          (item) => item.fullName
        );
        if (!filteredTos || filteredTos.length === 0) return null;

        const firstFullName = filteredTos[0].fullName;
        const otherFullNames = filteredTos
          .slice(1)
          .map((item) => item.fullName)
          .join(", ");
        return (
          <Tooltip
            title={filteredTos.map((item, index) => item.fullName).join(", ")}
          >
            <span>
              {firstFullName}
              {filteredTos.length > 1 && (
                <span>, +{filteredTos.length - 1} more</span>
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "CC",
      dataIndex: "ccs",
      key: "ccs",
      width: "15%",
      render: (text, record) => {
        const filteredTos = record?.applicationTypeDetails?.cCs.filter(
          (item) => item.fullName
        );
        if (!filteredTos || filteredTos.length === 0) return null;

        const firstFullName = filteredTos[0].fullName;
        const otherFullNames = filteredTos
          .slice(1)
          .map((item) => item.fullName)
          .join(", ");
        return (
          <Tooltip
            title={filteredTos.map((item, index) => item.fullName).join(", ")}
          >
            <span>
              {firstFullName}
              {filteredTos.length > 1 && (
                <span>, +{filteredTos.length - 1} more</span>
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Updated Date",
      dataIndex: "creationTime",
      key: "creationTime",
      render: (text, record) => {
        return <>{moment(record?.creationTime).format("YYYY-MM-DD hh:mm a")}</>;
      },
    },
    {
      title: "Action",
      dataIndex: "creator",
      key: "creator",
      render: (text, record) => {
        return (
          <>
            {/* {console.log("Hello", record)} */}
            <Layout.Content
              style={{ display: "flex", justifyContent: "start" }}
            >
              <EyeOutlined
                onClick={() => {
                  // console.log("Hello", record);
                  setModalData(record);
                  setIsModalVisible(true);
                  setHideInternalMessage(true);
                }}
              />
            </Layout.Content>
          </>
        );
      },
    },
  ];

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  let allProps = {
    taskId: modalData?.id,
    showChat: showChat,
    setShowChat: setShowChat,
    chatHeight: chatHeight,
    setChatHeight: setChatHeight,
    smallChatHeight: smallChatHeight,
    setSmallChatHeight: setSmallChatHeight,
    chatBox: chatBox,
    setchatBox: setchatBox,
    hideInternalMessage: hideInternalMessage,
    setHideInternalMessage: setHideInternalMessage,
    isModalVisible: isModalVisible,
    setIsModalVisible: setIsModalVisible,
    form: form,
    module: "GroupChat",
    origin: "Dashboard",
    isPrivate: false,
    modalData: modalData,
  };
  // console.log("modalData: ", modalData);
  // console.log(
  //   "mailMessageCenterPagination ----1: ",
  //   mailMessageCenterPagination?.items
  // );

  return (
    <div className="honor-wrapper">
      <div
        className="dashboards__events dashboards__body "
        style={{ height: "340px" }}
      >
        <div className="dashboards__bodyWrap">
          <h4 className="dashboards__body--title">Group Chat</h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <WechatOutlined
              style={{ marginRight: "8px", fontSize: "18px" }}
              onClick={() => {
                setInternalVisible(true);
              }}
            />
            <div
              onClick={() => {
                setShowAdvanceSearchModal(true);
              }}
              style={{
                width: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FilterOutlined className="hr_searchOutlined" />
            </div>

            <Input.Search
              className="form__group_email_search"
              style={{
                // margin: "0 auto",
                // width: "30%",
                width: "100%",
                border: "1px solid gray",
              }}
              placeholder="Group Chat Search"
              size="medium"
              allowClear
              bordered={true}
              onSearch={(e) => {
                // setSearchKeyword(e);
                //should search from here
                const body = `messageCenterTypeName=groupchat&Subject_SearchKeyword=${e}`;
                const loadMore = false;
                // dispatch(getTodoEventsPagination(body));
                // console.log("Search keyword: ", e)
                dispatch(
                  getMailMessageCenterPaginationDashboard(body, loadMore)
                );
              }}
              autoComplete="off"
            />
          </div>
        </div>
        {!isEmpty(mailMessageCenterPaginationDasboard?.items) ? (
          <ul className="dashboards__todolists leave_absent_list list3">
            <GroupChatPresentationTable
              columns={columns}
              data={mailMessageCenterPaginationDasboard?.items}
              // loading={false}
              loading={mailMessageCenterPaginationDasboardLoading}
            />
          </ul>
        ) : (
          <div className="dashboards__todolists leave_absent_listss">
            <Image className="data-no-img" src={man} />
            <span>There are no Group Chat.</span>
          </div>
        )}
      </div>

      <Modal
        closable={false}
        visible={internalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
      >
        <InternalMCompose
          setInternalVisible={setInternalVisible}
          form={form}
          origin="Group Chat"
        />
      </Modal>

      <Modal
        title="Group Chat"
        visible={isModalVisible}
        footer=""
        wrapClassName="popup_modal_internal_email"
        onCancel={handleModalCancel}
      >
        {/* <GroupChatModal 
                    {...allProps}
                /> */}
        <ChatDetails {...allProps} />
      </Modal>

      {/* SEARCH */}
      <Modal
        title="Advance Dashboard Search"
        visible={showAdvanceSearchModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        wrapClassName="advance_search_email"
      >
        {/* <Form> */}
        <Form onFinish={handleFormSubmit}>
          <Checkbox
            checked={isAscendingOrder}
            onChange={(e) => handleAscendingOrderChange(e.target.checked)}
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "6px",
            }}
          >
            Ascending Order
          </Checkbox>
          <Button
            type="ghost"
            shape="round"
            htmlType="submit"
            autoComplete="off"
          >
            Search
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default GroupChat;
