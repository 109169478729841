import { Button, Card, Col, Form, Input, Row, Select, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contactPersonAdd } from "services/redux/contact/companypeople/action";
import {
  contactLabelGroupListing,
  contactLabelListing,
} from "services/remanentCalls";

export default function CreateEmployee({
  setOpenCreateEmployee,
  selectedClientCompanyid,
  selectedPrincipalCompanyid,
  type,
}) {
  const { contactPersonAddLoading } = useSelector(
    (state) => state.contactCompanyPerson
  );

  const [form] = useForm();
  const [contactLabelGroup, setContactLabelGroup] = useState([]);

  const dispatch = useDispatch();

  const fetchContactLabelListing = async (body) => {
    const resp = await contactLabelListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      return resp.data;
      // setContactLabelListing(resp.data);
    }
  };

  const fetchContactLabelGroupListing = async (body) => {
    const resp = await contactLabelGroupListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      const emailGroup = resp.data.find((obj) => obj.name === "Email");
      const allContactLabelListing = await fetchContactLabelListing();
      const newEmailLabelListing = allContactLabelListing.filter(
        (obj) => obj.contactLabelGroupId === emailGroup.id
      );

      form.setFieldsValue({
        contactLabelId: newEmailLabelListing?.[0]?.id,
      });

      setContactLabelGroup(
        newEmailLabelListing?.map((x) => {
          return {
            label: x.name,
            value: x?.id,
          };
        })
      );
    }
  };

  useEffect(() => {
    if (!contactPersonAddLoading) {
      setOpenCreateEmployee({
        type: "",
        open: false,
      });
    }
    fetchContactLabelGroupListing();
  }, [contactPersonAddLoading, setOpenCreateEmployee]);

  const onFinish = (values) => {
    let apiData = {
      ...values,
      contactPersonGroupLabelDtos: [
        {
          contactLabelDataValue: values.contactLabelDataValue,
          contactLabelId: values.contactLabelId,
        },
      ],
      companyContactId:
        type === "client"
          ? selectedClientCompanyid
          : selectedPrincipalCompanyid,
    };
    dispatch(contactPersonAdd(apiData));
  };

  // const filterEmailLabel = dataContactGroupOptions?.find(x=>x.name==="")
  //   const initialValue = contactLabelGroup?.find((x) => {
  //     return x?.name === "Official";
  //   }).value;

  return (
    <>
      <Card>
        <Form
          onFinish={onFinish}
          layout="horizontal"
          //   labelAlign="left"
          //   labelCol={{ span: 4 }}
          //   wrapperCol={{ span: 20 }}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item name="firstName" label="First Name">
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="lastName" label="Last Name">
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row>
                <Col span={10} offset={2} style={{ marginLeft: "34px" }}>
                  <Form.Item name="contactLabelId" label="Email">
                    <Select options={contactLabelGroup} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="contactLabelDataValue">
                    <Input placeholder="Email" style={{ height: "32px" }} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <div style={{ float: "right", marginTop: "12px" }}>
            <Button
              type=""
              onClick={() => {
                setOpenCreateEmployee({
                  type: "",
                  open: false,
                });
              }}
              style={{ marginRight: "6px" }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              loading={contactPersonAddLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
}
