import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Skeleton } from "antd";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import {
  contactPersonFav,
  getContactPersonPagination,
  getContactCompanyPagination,
} from "../../../services/redux/contact/companypeople/action";

const AddUpdateCompanyTable = ({
  showAddUpdatePersonDrawer,
  showUpdateProfileDrawer,
  contactCompanyId,
  rowEventhandlers,
  setRowPersonClassName,
  searchPersonCompany,
  contactPersonGetDetailsResponse,
  selectedMailTypeAndMailData,
  setOnClickPerson,
  onClickPerson,
  searchKeyword,
  isFavorite,
  pageSize,
}) => {
  const [color, setColor] = useState("#ffd036");
  const dispatch = useDispatch();
  const { contactPersonPaginationResponse, contactPersonPaginationLoading } =
    useSelector((state) => state.contactCompanyPerson);

  const [data, setdata] = useState([]);
  const [get, setGet] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // const pageSize = 50;
  // const pageSize = 15;

  // useEffect(() => {
  //   if (!isEmpty(selectedMailTypeAndMailData)) {
  //     getData();
  //   }
  // }, [searchPersonCompany]);

  // useEffect(() => {

  //   if (
  //     isEmpty(selectedMailTypeAndMailData) ||
  //     isEmpty(selectedMailTypeAndMailData.search)
  //   ) {
  //     getData();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!contactPersonPaginationResponse) return;
  //   setdata(contactPersonPaginationResponse?.items);
  //   setTotalCount(contactPersonPaginationResponse?.totalCount);
  //   let newCurrentPage =
  //     contactPersonPaginationResponse?.items?.length / pageSize;
  //   setCurrentPage(newCurrentPage);
  // }, [contactPersonPaginationResponse]);

  // added
  useEffect(() => {
    if (!isEmpty(contactPersonPaginationResponse?.items)) {
      setdata(contactPersonPaginationResponse.items);
      setTotalCount(contactPersonPaginationResponse.totalCount);

      // if (
      //   contactPersonPaginationResponse.totalCount >
      //   (currentPage - 1) * pageSize
      // ) {
      //   setCurrentPage((currentPage) => currentPage + 1);
      // }
      let newCurrentPage =
        contactPersonPaginationResponse?.items?.length / pageSize;
      setCurrentPage(newCurrentPage);
    } else {
      setdata([]);
    }
  }, [contactPersonPaginationResponse, currentPage, pageSize]);

  // const getData = (
  //   MaxResultCount = pageSize,
  //   SkipCount = 0,
  //   SearchKeyword,

  //   ForPagination = false
  // ) => {
  //   {
  //     dispatch(
  //       getContactPersonPagination(
  //         {
  //           MaxResultCount,
  //           SkipCount,
  //           SearchKeyword: searchPersonCompany,
  //           CompanyId: contactCompanyId
  //             ? contactCompanyId
  //             : "00000000-0000-0000-0000-000000000000",
  //         },
  //         ForPagination
  //       )
  //     );
  //   }
  // };

  // const fetchMoreData = () => {
  //   getData(pageSize, currentPage * pageSize, searchPersonCompany, true);
  // };

  // added
  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    ForPagination = false
    // IsFavourite_SearchKeyword = isFavorite ? isFavorite : "false"
  ) => {
    // dispatch(
    //   getContactPersonPagination(
    //     {
    //       MaxResultCount,
    //       SkipCount,
    //       IsFavourite_SearchKeyword,
    //       SearchKeyword: searchKeyword,
    //     },
    //     ForPagination
    //   )
    // );
    const {
      CompanyContactName,
      ContactActiveStatus,
      Email,
      ExperienceFrom,
      ExperienceTo,
      IsAdvancedFilter,
      Phone,
      Qualification,
      Website,
      majorSubject,
      sector,
      yearsOfExperience,
    } = searchKeyword;

    dispatch(
      getContactPersonPagination(
        {
          ...(CompanyContactName !== undefined && { CompanyContactName }),
          ...(ContactActiveStatus !== undefined && { ContactActiveStatus }),
          ...(Email !== undefined && { Email }),
          ...(ExperienceFrom !== undefined && { ExperienceFrom }),
          ...(ExperienceTo !== undefined && { ExperienceTo }),
          IsAdvancedFilter,
          MaxResultCount,
          SkipCount,
          ...(typeof searchKeyword === "string" && { searchKeyword }),
          ...(Phone !== undefined && { Phone }),
          ...(Qualification !== undefined && { Qualification }),
          ...(Website !== undefined && { Website }),
          ...(majorSubject !== undefined && { majorSubject }),
          ...(sector !== undefined && { sector }),
          ...(yearsOfExperience !== undefined && { yearsOfExperience }),
        },
        ForPagination
      )
    );
  };

  const getMoreData = () => {
    // getData(pageSize, currentPage * pageSize, searchKeyword, isFavorite);
    getData(pageSize, currentPage * pageSize, searchKeyword, true);
  };

  const contactPeronsPagination = () => {
    return (
      <>
        <div>
          {data?.map((person, key) => {
            const backGroundColor = key % 2 == 0 ? "" : "#f2f2f2";

            return (
              <>
                <div
                  style={{ background: backGroundColor, height: "30px" }}
                  className={`reference ${
                    setRowPersonClassName(person) && "active"
                  }`}
                  onClick={() => {
                    rowEventhandlers(person);
                    setOnClickPerson(!onClickPerson);
                  }}
                  onDoubleClick={() => {
                    const ContactPersonId = person?.contactPersonId;
                    dispatch(getContactCompanyPagination({ ContactPersonId }));
                  }}
                  key={key}
                >
                  <div className="reference__wrapper">
                    <div className="reference__wrapper--contents">
                      <div className="reference__body">
                        <div
                          className="reference__body--right "
                          onClick={() => {
                            dispatch(
                              contactPersonFav(
                                { contactPersonId: person.contactPersonId },
                                contactPersonGetDetailsResponse
                              )
                            );
                          }}
                        >
                          {person.isFavorite ? (
                            <StarFilled style={{ color: "#ffd036" }} />
                          ) : (
                            <StarOutlined style={{ color: "#ffd036" }} />
                          )}
                        </div>
                        <div className="reference__body--right">
                          <span className="reference__body--5right">
                            <Tooltip
                              title={`${person.personFullName}  ${
                                person.personNickName === null ||
                                !person.personNickName
                                  ? ""
                                  : `[${person.personNickName}],`
                              } ${person.contactCompanyName} `}
                              placement="bottomLeft"
                            >
                              {/* {person.personFullName} */}
                              <span
                                style={{
                                  color:
                                    person?.contactActiveStatus === 1
                                      ? "black"
                                      : person?.contactActiveStatus === 2
                                      ? "blue"
                                      : person?.contactActiveStatus === 0
                                      ? "green"
                                      : undefined,
                                }}
                              >
                                {person.personFullName}
                              </span>
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <p
        style={{
          background: "#d8ddeb",
          fontWeight: "bold",
          padding: "5px",
          marginBottom: "0px",
        }}
      >
        Person
      </p>
      <div
        id="personScroll"
        style={{
          // height: "98%",
          height: "calc(100% - 150px)",
          overflowY: "auto",
        }}
      >
        {contactPersonPaginationLoading && (
          <Skeleton paragraph={{ rows: 10 }} active />
        )}

        {!isEmpty(data) ? (
          <InfiniteScroll
            dataLength={contactPersonPaginationResponse?.items.length}
            //This is important field to render the next data
            hasMore={
              contactPersonPaginationResponse?.totalCount >
              contactPersonPaginationResponse?.items.length
            }
            next={getMoreData}
            loader={<Skeleton paragraph={{ rows: 2 }} active />}
            scrollableTarget="personScroll"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>End of Contact</b>
              </p>
            }
          >
            {contactPeronsPagination()}
          </InfiniteScroll>
        ) : (
          <p style={{ textAlign: "center" }}>
            <b>No Data</b>
          </p>
        )}
      </div>
    </>
  );
};

export default AddUpdateCompanyTable;
