import { GLOBAL, REQUISITION_TYPES } from "../../../types";

const INTIAL_STATE = {
  leaveTypeListing: [],
  leaveTypeListingLoading: false,

  requisitionAppStatusListing: [],
  requisitionAppStatusListingLoading: false,
  leaveDetails: [],
  leaveDetailsLoading: false,
};

export default function leaveTypeReducer(state = INTIAL_STATE, action) {
  switch (action.type) {
    // Leave Application Pagination
    case REQUISITION_TYPES.LEAVE_TYPE_LISTING_INT:
      return {
        ...state,
        leaveTypeListing: [],
        leaveTypeListingLoading: true,
      };
    case REQUISITION_TYPES.LEAVE_TYPE_LISTING_SUCCESS:
      return {
        ...state,
        leaveTypeListing: action.payload,
        leaveTypeListingLoading: false,
      };

    case REQUISITION_TYPES.LEAVE_TYPE_LISTING_FINISH:
      return {
        ...state,
        leaveTypeListingLoading: false,
      };

    // Requisition Application Status Listing
    case REQUISITION_TYPES.REQUISITION_APP_STATUS_LISTING_INT:
      return {
        ...state,
        requisitionAppStatusListing: [],
        requisitionAppStatusListingLoading: true,
      };
    case REQUISITION_TYPES.REQUISITION_APP_STATUS_LISTING_SUCCESS:
      return {
        ...state,
        requisitionAppStatusListing: action.payload,
        requisitionAppStatusListingLoading: false,
      };

    case REQUISITION_TYPES.REQUISITION_APP_STATUS_LISTING_FINISH:
      return {
        ...state,
        requisitionAppStatusListingLoading: false,
      };

    case REQUISITION_TYPES.LEAVE_DETAILS_INIT:
      return {
        ...state,
        leaveDetails: [],
        leaveDetailsLoading: true,
      };
    case REQUISITION_TYPES.LEAVE_DETAILS_SUCCESS:
      return {
        ...state,
        leaveDetails: action.payload,
        leaveDetailsLoading: false,
      };
    case REQUISITION_TYPES.LEAVE_DETAILS_FINISH:
      return {
        ...state,
        leaveDetailsLoading: false,
      };
    case REQUISITION_TYPES.LEAVE_APPLICATION_UPDATE_MANAGEMENT_INIT:
      return {
        ...state,
        leaveUpdateManagement: [],
        leaveUpdateManagementLoading: true,
      };

    case REQUISITION_TYPES.LEAVE_APPLICATION_UPDATE_MANAGEMENT_FINISH:
      return {
        leaveUpdateManagement: action.payload,
        leaveUpdateManagementLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAINMENT_APPLY_INIT:
      return {
        ...state,
        guestInfoAddUpdate: [],
        guestInfoAddUpdateLoading: true,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAINMENT_APPLY_SUCCESS:
      return {
        ...state,
        guestInfoAddUpdate: action.payload,
        guestInfoAddUpdateLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAINMENT_APPLY_FINISH:
      return {
        ...state,
        guestInfoAddUpdateLoading: false,
      };
    case REQUISITION_TYPES.AMOUNT_RESPONSE_INIT:
      return {
        ...state,
        amountResponseLoading: true,
      };
    case REQUISITION_TYPES.AMOUNT_RESPONSE_SUCCESS:
      return {
        ...state,
        amountResponse: action.payload,
        amountResponseLoading: false,
      };
    case REQUISITION_TYPES.AMOUNT_RESPONSE_FINISH:
      return {
        ...state,
        amountResponseLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_DETAIL_INIT:
      return {
        ...state,
        guestEntertainmentDetailResponse: [],
        guestEntertainmentDetailLoading: true,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_DETAIL_SUCCESS:
      return {
        ...state,
        guestEntertainmentDetailResponse: action.payload,
        guestEntertainmentDetailLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_DETAIL_FINISH:
      return {
        ...state,
        guestEntertainmentDetailLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_SUPERVISOR_ACTION_INIT:
      return {
        ...state,
        supervisorActionResponse: [],
        supervisorActionResponseLoading: true,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_SUPERVISOR_ACTION_SUCCESS:
      return {
        ...state,
        supervisorActionResponse: action.payload,
        supervisorActionResponseLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_SUPERVISOR_ACTION_FINISH:
      return {
        ...state,
        managementActionResponseLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_MANAGEMENT_ACTION_INIT:
      return {
        ...state,
        managementActionResponse: [],
        managementActionResponseLoading: true,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_MANAGEMENT_ACTION_SUCCESS:
      return {
        ...state,
        managementActionResponse: action.payload,
        managementActionResponseLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_MANAGEMENT_ACTION_FINISH:
      return {
        ...state,
        managementActionResponseLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION_INIT:
      return {
        ...state,
        accountantActionResponse: [],
        accountantActionResponseLoading: true,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION_SUCCESS:
      return {
        ...state,
        accountantActionResponse: action.payload,
        accountantActionResponseLoading: false,
      };
    case REQUISITION_TYPES.GUEST_ENTERTAIMENT_ACCOUNTANT_ACTION_FINISH:
      return {
        ...state,
        accountantActionResponseLoading: false,
      };
    case GLOBAL.GLOBAL_ERROR:
      return {
        ...state,
        guestInfoAddUpdateLoading: false,
        leaveUpdateManagementLoading: false,
        managementActionResponseLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
