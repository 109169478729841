import React, { useEffect } from "react";
import CematBox from "./CematBox";
import FinancialBox from "./FinancialBox";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { getBudgetIncomeExpenseDashboardAnother } from "services/redux/admin/FiscalYear/action";

const FinanceMainBox = ({ profileInfo }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBudgetIncomeExpenseDashboardAnother());
  }, []);

  return (
    <>
      {!isEmpty(profileInfo) &&
        profileInfo?.userRegistrationAddUpdateDto?.roleNames.includes(
          "Dashboard Account Viewer"
        ) && <FinancialBox />}

      {profileInfo?.userRegistrationAddUpdateDto?.roleNames.includes(
        "CEMAT viewer"
      ) && <CematBox />}
    </>
  );
};

export default FinanceMainBox;
