import { jsonParser } from "utils/misc";
import { AUTH_TYPES, GLOBAL } from "../../types";

const INITIAL_STATE = {
  token:
    jsonParser(jsonParser(sessionStorage.getItem("persist:root"))?.auth)
      ?.token || null,
  loading: false,
  userPasswordLoadingResponse: false,
  userPasswordResponse: null,
  checkDailyAttendenceResponse: [],
  checkDailyAttendenceResponseLoading: false,
};

export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_TYPES.USER_LOGIN_INIT:
      return {
        token: null,
        loading: true,
      };
    case AUTH_TYPES.USER_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        loading: false,
      };
    case AUTH_TYPES.USER_LOGIN_FINISH:
      return {
        token: null,
        loading: false,
      };
    case AUTH_TYPES.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        loading: false,
      };
    case AUTH_TYPES.USER_RESET_CHANGEPASSWORD_INIT:
      return {
        ...state,
        userPasswordResponse: null,
        userPasswordLoadingResponse: true,
      };
    case AUTH_TYPES.USER_RESET_CHANGEPASSWORD_SUCCESS:
      return {
        ...state,
        userPasswordResponse: action.payload,
        userPasswordLoadingResponse: false,
      };
    case AUTH_TYPES.USER_RESET_CHANGEPASSWORD_FINISH:
      return {
        ...state,
        userPasswordResponse: null,
        userPasswordLoadingResponse: false,
      };

    case AUTH_TYPES.CHECK_DAILY_ATTENDENCE_INIT:
      return {
        ...state,
        checkDailyAttendenceResponse: [],
        checkDailyAttendenceResponseLoading: true,
      };
    case AUTH_TYPES.CHECK_DAILY_ATTENDENCE_SUCCESS:
      return {
        ...state,
        checkDailyAttendenceResponse: action.payload,
        checkDailyAttendenceResponseLoading: false,
      };
    case AUTH_TYPES.CHECK_DAILY_ATTENDENCE_FINISH:
      return {
        ...state,
        checkDailyAttendenceResponseLoading: false,
      };
    case GLOBAL.GLOBAL_ERROR:
      return {
        ...state,
        checkDailyAttendenceResponseLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
