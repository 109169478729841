import { uniqBy } from "lodash";
import { REFERENCE__TYPES } from "../../../types";

const INITIAL_STATE = {
  secondaryBasicPaginationResponse: {
    items: [],
    totalCount: 1,
  },
  secondaryBasicPaginationLoading: false,

  // reference secondary add
  referencSecondaryAddResponse: [],
  referenceSecondaryAddLoading: false,
  referenceSecondaryAddIsSuccess: false,
};

export default function secondaryRoot2Reducer(state = INITIAL_STATE, action) {
  //reference secondary pagination
  switch (action.type) {
    //Secondary Basic
    case REFERENCE__TYPES.TENDER_BASIC_PAGINATION_SECONDARY_INIT:
      return {
        ...state,
        secondaryBasicPaginationLoading: true,
      };

    case REFERENCE__TYPES.TENDER_BASIC_PAGINATION_SECONDARY_SUCCESS:
      return {
        ...state,
        secondaryBasicPaginationResponse: {
          items: action.loadMore
            ? uniqBy(
                state.secondaryBasicPaginationResponse.items.concat(
                  action.payload.items
                ),
                "id"
              )
            : action.payload.items,
          totalCount: action.payload.totalCount,
        },
        secondaryBasicPaginationLoading: false,
      };

    //Add Secondary Reference Tags

    case REFERENCE__TYPES.REFERENCE_SECONDARY_ADD_INIT:
      return {
        ...state,
        referenceSecondaryAddLoading: true,
        referenceSecondaryAddIsSuccess: false,
      };

    case REFERENCE__TYPES.REFERENCE_SECONDARY_ADD_SUCCESS:
      return {
        ...state,
        referenceSecondaryAddLoading: false,
        referenceSecondaryAddIsSuccess: true,
        referencSecondaryAddResponse: action.payload,
      };
    case REFERENCE__TYPES.REFERENCE_SECONDARY_ADD_FINISH:
      return {
        ...state,
        referenceSecondaryAddLoading: false,
        referenceSecondaryAddIsSuccess: true,
        referencSecondaryAddResponse: [],
      };

    default:
      return {
        ...state,
      };
  }
}
