import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import _ from "lodash";
import { cqBaseUrl } from "utils/config";
import {
  openFolderIcon,
  closeFolderIcon,
  extensionFolder,
  textCenterDot,
  fileUploadButton,
} from "utils/commonFunc";
import {
  Modal,
  Input,
  Select,
  Button,
  Form,
  message,
  DatePicker,
  TimePicker,
} from "antd";

import { getReferenceListing } from "services/redux/Reference/action";
import {
  documentRequestDocumentUpload,
  documentUploadToTempFile,
} from "services/redux/document/action";
import { getDynamicFileLabelTypeUploaderDetail } from "services/redux/common/dynamicFileType/action";
import { AbortError } from "@microsoft/signalr";
import { sectorListing } from "services/remanentCalls";

const CreateEditDocument = ({
  hideDrawer,
  setHideDrawer,
  setFileTypeId,
  token,
}) => {
  const { Option } = Select;
  const drop = useRef(null);
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [extensionForm] = Form.useForm();
  const dispatch = useDispatch();
  //selector
  const { referenceListingResponse, referenceListingLoading } = useSelector(
    (state) => state.reference
  );
  const { dynamicFileLabelTypes } = useSelector(
    (state) => state.dynamicFileLabelTypes
  );
  const {
    documentRequestdocumentUploadLoading,
    documentRequestdocumentUploadTempFileResponse,
    documentRequestdocumentUploadSuccess,
  } = useSelector((state) => state.documentRequestUpload);
  const { tenderStageAddExtensionResponse } = useSelector(
    (state) => state.tender
  );
  //state
  const [searchValueReference, setSearchValueReference] = useState(null);
  const [filterReference, setFilterReference] = useState([]);
  // const [selectedReferenceType, setSelectedReferenceType] = useState(null);
  const [selectedReferenceType, setSelectedReferenceType] = useState({
    referenceCode: "",
    type: "",
  });
  const [filterFileLabelType, setFilterFileLabelType] = useState([]);
  const [stageNo, setStageNo] = useState("");
  const [visible, setExtensionVisible] = useState(false);
  const [code, setCode] = useState(null);
  const [fileInformationId, setFileInformationId] = useState(null);
  const [extensionInfo, setExtensionInfo] = useState(null);
  const [sectors, setSectors] = useState([]);

  const handleCancel = () => {
    setExtensionVisible(false);
    let newFolder = document.querySelector(".new-folder");
    if (newFolder) {
      newFolder.remove();
    }
  };
  //get filter  reference
  useEffect(() => {
    if (!isEmpty(searchValueReference)) {
      const timeoutId = setTimeout(() => {
        dispatch(getReferenceListing({ searchKeyWord: searchValueReference }));
      }, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValueReference]);

  const fetchAllSector = async () => {
    const resp = await sectorListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      const data = resp.data.map((x) => {
        return {
          label: x.sectorName,
          value: x.id,
        };
      });
      setSectors(data);
    }
  };

  useEffect(() => {
    setFilterReference(referenceListingResponse?.items);
    fetchAllSector();
  }, [referenceListingResponse]);
  //end of filter reference
  //get file label type according to reference type
  useEffect(() => {
    if (!isEmpty(selectedReferenceType.type)) {
      dispatch(getDynamicFileLabelTypeUploaderDetail(selectedReferenceType));
    }
  }, [selectedReferenceType]);

  useEffect(() => {
    setFilterFileLabelType(dynamicFileLabelTypes);
  }, [dynamicFileLabelTypes]);
  useEffect(() => {
    // console.log(
    //   "documentRequestdocumentUploadTempFileResponse",
    //   documentRequestdocumentUploadTempFileResponse
    // );
    form.setFieldsValue({
      uploadedFileDtos: documentRequestdocumentUploadTempFileResponse,
    });
  }, [documentRequestdocumentUploadTempFileResponse]);

  useEffect(() => {
    form.setFieldsValue({ TenderStageLevel: stageNo });
  }, [stageNo]);
  // end of file label type according to reference type
  //folder  open close

  useEffect(() => {
    if (drop.current != null) {
      drop.current.addEventListener("dragover", handleDragOver);
      drop.current.addEventListener("dragleave", handleDragLeave);
      drop.current.addEventListener("drop", handleDrop);
    }
  }, []);

  useEffect(() => {
    setExtensionVisible(false);
  }, [tenderStageAddExtensionResponse]);
  const uploadFile = (files, childrens, label) => {
    let fileListHtml = "";
    let formData = new FormData();
    Object.entries(files)?.map(([index, item], i) => {
      if (item.type.includes("application")) {
        formData.append("uploadedFiles", item);
        fileListHtml += `<div class="folder-file-item">${item?.name}</div>`;
      } else {
        message.error("File not acceptable");
        return;
      }
    });
    for (var i = 0; i < childrens.length; i++) {
      if (childrens[i].className == "file-list-wrapper") {
        let stringArr = label.split("|");
        childrens[i].innerHTML = fileListHtml;
        let stageNo = "";
        if (stringArr[1]) {
          stageNo = "stage" + stringArr[1];
        }
        setStageNo(stageNo);
        dispatch(documentUploadToTempFile(formData, stringArr[0]));
        break;
      }
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFileInformationId(null);
    if (e.target.classList.contains("folder-name"))
      e.target.classList.add("hover");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    let target = e.target;
    let folder = target.getAttribute("folder");
    let code = target.getAttribute("data-code");
    let fileInfoId = target.getAttribute("data-fileinformation");
    let label = target.getAttribute("data-label");
    let multiple = target.getAttribute("data-multiple");
    let stage = target.getAttribute("data-stage");
    let childrens = target?.parentNode?.parentNode?.nextSibling?.children;
    let fileLength = files.length;
    let referenceLabel = target.getAttribute("data-referencelabel");
    if (fileInfoId) {
      setFileInformationId(fileInfoId);
    }
    if (code) {
      setCode(code);
    }

    if (target.classList.contains("folder-name")) {
      target.classList.remove("hover");
      document.querySelectorAll(".file-list-wrapper").forEach((item, i) => {
        item.innerHTML = "";
      });
      if (multiple === "false" && fileLength > 1) {
        message.error("Only one file in acceptable");
        return message;
      }
      if (multiple === "false" && !stage) {
        message.error("Existing file will replace with new file");
      }
      if (folder == "has-folder" && code !== "EXTNOTICE") {
        message.error(`You can't upload in ${target.innerHTML}`);
        return;
      }
      // if(folder = 'no-folder' && (referenceLabel && referenceLabel!=='Tender')){
      //   console.log('folder',childrens)
      // }
      if (folder == "has-folder" && code == "EXTNOTICE") {
        if (stage) {
          setExtensionVisible(true);
          let appendDiv = target.parentNode.parentNode.nextSibling;
          let div = document.createElement("div");
          div.classList.add("sub-folder-info-wrapper");
          div.classList.add("new-folder");
          let info = document.createElement("div");
          info.classList.add("sub-folder-info");
          let span = document.createElement("span");
          span.classList.add("folder-icon");
          span.innerHTML = closeFolderIcon();
          info.append(span);
          let folderWrapper = document.createElement("div");
          folderWrapper.classList.add("sub-folder-file-upload-wrapper");
          folderWrapper.classList.add("hide");
          let fileList = document.createElement("div");
          fileList.classList.add("file-list-wrapper");
          folderWrapper.append(fileList);
          let folderNameWrapper = document.createElement("div");
          folderNameWrapper.classList.add("folder-radio-wrapper");
          let folderNumber = appendDiv.children.length + 1;
          folderNameWrapper.innerHTML = extensionFolder(
            folderNumber,
            label.split("|")[0]
          );
          info.append(folderNameWrapper);
          div.append(info);
          div.append(folderWrapper);
          appendDiv.append(div);
          if (childrens) {
            let label = target.getAttribute("data-label");
            uploadFile(files, folderWrapper.children, label);
          }
          return;
        }
      }

      if (childrens) {
        uploadFile(files, childrens, label);
      }
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.classList.contains("folder-name"))
      e.target.classList.remove("hover");
  };

  const handleOpenCloseFolder = (e) => {
    let target = e.target;
    if (target.tagName == "SPAN") return;
    let targetParent = target.parentNode.parentNode;
    let targetParentNext = targetParent.nextSibling;
    let folderIcon = target.parentNode.previousSibling;
    let hasFolder = target.getAttribute("folder");
    if (targetParentNext) {
      let level = parseInt(target.getAttribute("data-level"));
      let folderName = document.querySelectorAll(".folder-name");
      let subFolders = document.querySelectorAll(
        ".sub-folder-file-upload-wrapper"
      );
      folderName.forEach((item, index) => {
        let itemParentNext = item.parentNode.parentNode.nextSibling;
        let itemFolderIcon = item.parentNode.previousSibling;
        var findHirechy;
        for (var i = 1; i <= level; i++) {
          findHirechy = targetParentNext.parentNode.parentNode.parentNode;
          if (
            findHirechy.classList.contains("sub-folder-file-upload-wrapper")
          ) {
            if (
              subFolders[index] !== findHirechy &&
              targetParentNext !== itemParentNext
            ) {
              subFolders[index].classList.add("hide");
              if (itemFolderIcon) itemFolderIcon.innerHTML = closeFolderIcon();
            }
            if (
              subFolders[index] === findHirechy &&
              targetParentNext !== itemParentNext
            ) {
              if (subFolders[index].classList.contains("hide")) {
                subFolders[index].classList.remove("hide");
                if (itemFolderIcon) itemFolderIcon.innerHTML = openFolderIcon();
              } else {
                subFolders[index].classList.add("hide");
                if (itemFolderIcon)
                  itemFolderIcon.innerHTML = closeFolderIcon();
              }
            }
          }
        }
      });
      let fileWrapper = document.querySelector(".file-upload-wrapper");
      if (fileWrapper) fileWrapper.remove();
      if (targetParentNext.classList.contains("hide")) {
        targetParentNext.classList.remove("hide");
        if (folderIcon) {
          folderIcon.innerHTML = openFolderIcon();
          if (hasFolder == "no-folder") {
            let browseButtonWrapper = document.createElement("div");
            browseButtonWrapper.classList.add("file-upload-wrapper");
            let browseButton = document.createElement("input");
            browseButton.setAttribute("type", "file");
            browseButton.setAttribute("id", "browse-input");
            browseButton.style.display = "none";
            let browseLabel = document.createElement("label");
            browseLabel.setAttribute("for", "browse-input");
            browseLabel.classList.add("browse-label");
            browseLabel.innerHTML = "Add File";
            browseButtonWrapper.append(browseButton);
            browseButtonWrapper.append(browseLabel);
            targetParentNext.append(browseButtonWrapper);
            browseButton.addEventListener("change", function (e) {
              let files = e.target.files;
              let label = target.getAttribute("data-label");
              uploadFile(files, targetParentNext.children, label);
            });
          }
        }
      } else {
        targetParentNext.classList.add("hide");
        if (folderIcon) {
          folderIcon.innerHTML = closeFolderIcon();
        }
      }
    }
  };

  // end of folder open close
  //form submit
  useEffect(() => {
    if (documentRequestdocumentUploadSuccess) {
      setFileInformationId(null);
      setHideDrawer(true);
      form.resetFields();
    }
  }, [documentRequestdocumentUploadLoading]);

  const onFinish = (values) => {
    setFileTypeId(null);
    let finalData = {
      ...values,
      uploadedFileDtos: values.uploadedFileDtos.map((uploadedFile) => ({
        ...uploadedFile,
        fileInformationSectorDtos:
          null ||
          values?.sectors?.map((x) => {
            return {
              isActive: true,
              sectorId: x,
            };
          }),
      })),
    };
    if (fileInformationId) {
      finalData = {
        ...finalData,
        fileReplaceDto: [
          {
            fileLabelTypeCode: code,
            fileInformationId: fileInformationId,
          },
        ],
      };
    }
    dispatch(documentRequestDocumentUpload(finalData));
    setHideDrawer(true);
    form.resetFields();
  };

  const createExtensionForm = (values) => {
    setExtensionInfo(values);
    setExtensionVisible(false);
    message.success("Extension folder created sucessfully");
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="drawer-form-wrapper document-form-wrapper"
      >
        <div className="drawer-form-row">
          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name="referenceId"
              label="Ref"
              rules={[{ required: true, message: "" }]}
            >
              <Select
                showSearch
                placeholder="Select reference"
                optionFilterProp="label"
                allowClear={true}
                onSearch={(value) => {
                  setSearchValueReference(value);
                }}
                onChange={(value, option) => {
                  // setSelectedReferenceType(option?.label[2]);
                  setSelectedReferenceType({
                    referenceCode: option?.label[0],
                    type: option?.label[2],
                  });
                }}
                autoComplete="off"
              >
                {!isEmpty(filterReference) &&
                  filterReference?.map((reference, index) => {
                    return (
                      <Option
                        value={reference.id}
                        key={index}
                        label={[
                          reference.code,
                          reference.title,
                          reference.referencType,
                        ]}
                      >
                        <div className="demo-option-label-item">
                          {}
                          <div>
                            {reference.code} {""}
                          </div>
                          <div>{reference.title}</div>
                        </div>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>

          <div className="file-drag" ref={drop}>
            {!isEmpty(selectedReferenceType) && (
              <>
                <div className="drawer-form-group">
                  <Form.Item className="form__group" label="File Upload">
                    <div className="folder-structure-file-upload-wrapper">
                      <div
                        className="parent-folder-wrapper"
                        onClick={handleOpenCloseFolder}
                      >
                        <div className="parent-folder-info-wrapper">
                          <div className="parent-folder-info open">
                            <span className="folder-icon">
                              <span
                                role="img"
                                aria-label="folder-open"
                                className="anticon anticon-folder-open"
                              >
                                <svg
                                  viewBox="64 64 896 896"
                                  focusable="false"
                                  data-icon="folder-open"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 00-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zm-180 0H238c-13 0-24.8 7.9-29.7 20L136 643.2V256h188.5l119.6 114.4H748V444z"></path>
                                </svg>
                              </span>
                            </span>
                            <span
                              className="folder-name"
                              data-level="1"
                              data-label=""
                              data-referencelabel={selectedReferenceType}
                              folder={
                                // selectedReferenceType == "Tender"
                                selectedReferenceType.referenceCode === "Tender"
                                  ? "has-folder"
                                  : "no-folder"
                              }
                            >
                              {/* {selectedReferenceType} */}
                              {selectedReferenceType.referenceCode}
                            </span>
                          </div>
                        </div>
                        <div className="sub-folder-file-upload-wrapper">
                          <Form.Item
                            name="uploadedFileDtos"
                            style={{ display: "none" }}
                          ></Form.Item>
                          <Form.Item
                            name="TenderStageLevel"
                            style={{ display: "none" }}
                          ></Form.Item>

                          {!isEmpty(filterFileLabelType) &&
                            filterFileLabelType?.map((item, i) => {
                              let stage1File = item?.uploadedFileStage1Dtos;
                              let stage2File = item?.uploadedFileStage2Dtos;
                              let otherFile = item?.uploadedFileDtos;
                              let stageCount =
                                !isEmpty(stage1File) &&
                                1 + !isEmpty(stage2File) &&
                                1;
                              return (
                                <div
                                  className="sub-folder-wrapper"
                                  key={item.id}
                                >
                                  <div className="sub-folder-info-wrapper">
                                    <div className="sub-folder-info">
                                      <span className="folder-icon">
                                        <span
                                          role="img"
                                          aria-label="folder"
                                          className="anticon anticon-folder"
                                        >
                                          <svg
                                            viewBox="64 64 896 896"
                                            focusable="false"
                                            data-icon="folder"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path d="M880 298.4H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                      <div className="folder-radio-wrapper">
                                        <label
                                          className="folder-name sub-parent"
                                          data-level="2"
                                          data-label={item?.id}
                                          folder={
                                            !isEmpty(stage1File) ||
                                            !isEmpty(stage2File)
                                              ? "has-folder"
                                              : "no-folder"
                                          }
                                          data-multiple={
                                            item?.isMultipleFileAllowed
                                          }
                                        >
                                          {item?.name}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="sub-folder-file-upload-wrapper hide">
                                      {_.times(stageCount, (increment) => {
                                        let stageFile =
                                          increment == 0
                                            ? stage1File
                                            : stage2File;
                                        return (
                                          <div
                                            className="sub-folder-info-wrapper"
                                            key={increment}
                                          >
                                            <div className="sub-folder-info">
                                              <span className="folder-icon">
                                                <span
                                                  role="img"
                                                  aria-label="folder"
                                                  className="anticon anticon-folder"
                                                >
                                                  <svg
                                                    viewBox="64 64 896 896"
                                                    focusable="false"
                                                    data-icon="folder"
                                                    width="1em"
                                                    height="1em"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                  >
                                                    <path d="M880 298.4H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path>
                                                  </svg>
                                                </span>
                                              </span>
                                              <div className="folder-radio-wrapper">
                                                <label
                                                  className="folder-name"
                                                  data-level="3"
                                                  data-stage={true}
                                                  data-code={item.code}
                                                  data-label={`${item?.id}|${
                                                    increment + 1
                                                  }`}
                                                  folder={
                                                    item?.code === "EXTNOTICE"
                                                      ? "has-folder"
                                                      : "no-folder"
                                                  }
                                                  data-multiple={
                                                    item?.isMultipleFileAllowed
                                                  }
                                                >
                                                  Stage {increment + 1}
                                                </label>
                                              </div>
                                            </div>
                                            <div className="sub-folder-file-upload-wrapper hide">
                                              {item.code === "EXTNOTICE" ? (
                                                <>
                                                  {_.times(
                                                    stageFile.length,
                                                    (i) => {
                                                      return (
                                                        <div className="sub-folder-info-wrapper">
                                                          <div className="sub-folder-info">
                                                            <span className="folder-icon">
                                                              <span
                                                                role="img"
                                                                aria-label="folder"
                                                                className="anticon anticon-folder"
                                                              >
                                                                <svg
                                                                  viewBox="64 64 896 896"
                                                                  focusable="false"
                                                                  data-icon="folder"
                                                                  width="1em"
                                                                  height="1em"
                                                                  fill="currentColor"
                                                                  aria-hidden="true"
                                                                >
                                                                  <path d="M880 298.4H521L403.7 186.2a8.15 8.15 0 00-5.5-2.2H144c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V330.4c0-17.7-14.3-32-32-32z"></path>
                                                                </svg>
                                                              </span>
                                                            </span>
                                                            <div className="folder-radio-wrapper">
                                                              <label
                                                                className="folder-name"
                                                                data-level="4"
                                                                data-fileinformation={
                                                                  stageFile[i]
                                                                    ?.fileInformationId
                                                                }
                                                                data-extension={
                                                                  true
                                                                }
                                                                data-code={
                                                                  item?.code
                                                                }
                                                                data-label={`${
                                                                  item?.id
                                                                }|${
                                                                  increment + 1
                                                                }`}
                                                                folder="no-folder"
                                                                data-multiple={
                                                                  item?.isMultipleFileAllowed
                                                                }
                                                              >
                                                                Extension{" "}
                                                                {stageFile.length -
                                                                  i}
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div className="sub-folder-file-upload-wrapper hide">
                                                            <div className="file-list-wrapper"></div>
                                                            {!isEmpty(
                                                              stageFile
                                                            ) &&
                                                              stageFile[i] && (
                                                                <div className="file-list-wrapper-old">
                                                                  <ul>
                                                                    <li>
                                                                      <a
                                                                        onClick={() => {
                                                                          window.open(
                                                                            `${cqBaseUrl}/${stageFile[i]?.viewFileURL}${token}`,
                                                                            "_blank",
                                                                            "top=50,left=200,frame=true"
                                                                          );
                                                                        }}
                                                                      >
                                                                        {textCenterDot(
                                                                          stageFile[
                                                                            i
                                                                          ]
                                                                            ?.displayFileName
                                                                        )}
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              )}
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <div className="file-list-wrapper"></div>
                                                  <div className="file-list-wrapper-old">
                                                    <ul>
                                                      {stageFile?.map(
                                                        (file, index) => {
                                                          return (
                                                            <li key={index}>
                                                              <a
                                                                onClick={() => {
                                                                  window.open(
                                                                    `${cqBaseUrl}/${file?.viewFileURL}${token}`,
                                                                    "_blank",
                                                                    "top=50,left=200,frame=true"
                                                                  );
                                                                }}
                                                              >
                                                                {textCenterDot(
                                                                  file?.displayFileName
                                                                )}
                                                              </a>
                                                            </li>
                                                          );
                                                        }
                                                      )}
                                                    </ul>
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                      {!stageCount && (
                                        <>
                                          <div className="file-list-wrapper"></div>
                                          {!isEmpty(otherFile) && (
                                            <div className="file-list-wrapper-old">
                                              <ul>
                                                {otherFile?.map(
                                                  (file, index) => {
                                                    return (
                                                      <li key={index}>
                                                        <a
                                                          onClick={() => {
                                                            window.open(
                                                              `${cqBaseUrl}/${file?.viewFileURL}${token}`,
                                                              "_blank",
                                                              "top=50,left=200,frame=true"
                                                            );
                                                          }}
                                                        >
                                                          {textCenterDot(
                                                            file?.displayFileName
                                                          )}
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                )}
                                              </ul>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          <div className="file-list-wrapper"></div>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </>
            )}
          </div>

          <div className="drawer-form-group">
            <Form.Item className="form__group" name="sectors" label="Sector">
              <Select
                mode="multiple"
                options={sectors}
                placeholder="Select Sectors"
              />
            </Form.Item>
          </div>

          <div className="drawer-form-group">
            <Form.Item
              className="form__group"
              name="notes"
              label="Remarks"
              rules={[{ required: true, message: "" }]}
            >
              <TextArea autoComplete="off" rows={10} />
            </Form.Item>
          </div>
        </div>

        <div className="model-footer">
          <Button
            onClick={() => {
              setHideDrawer(true);
              form.resetFields();
              setFilterFileLabelType([]);
              // setSelectedReferenceType(null);
              setSelectedReferenceType({
                referenceCode: "",
                type: "",
              });
            }}
          >
            Close
          </Button>
          <Button
            loading={documentRequestdocumentUploadLoading}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
      <Modal
        title="Create Extension"
        closable={false}
        visible={visible}
        footer=""
      >
        <Form
          form={extensionForm}
          className="drawer-form-wrapper"
          onFinish={createExtensionForm}
        >
          <div
            className="drawer-form-row"
            style={{ padding: 0, overflow: "hidden" }}
          >
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="invitationDate"
                label="Invitation On"
                rules={[{ required: true, message: "" }]}
              >
                <DatePicker size="small" format="YYYY/MM/DD" />
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="submissionDate"
                label="Submission Date"
                rules={[{ required: true, message: "" }]}
              >
                <DatePicker size="small" format="YYYY/MM/DD" />
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="openingDate"
                label="Opening Date"
                rules={[{ required: true, message: "" }]}
              >
                <DatePicker size="small" format="YYYY/MM/DD" />
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="bidValidity"
                label="Bid Validity"
                rules={[{ required: true, message: "" }]}
              >
                <DatePicker size="small" format="YYYY/MM/DD" />
              </Form.Item>
            </div>
            <div className="drawer-form-group">
              <Form.Item
                className="form__group"
                name="bankGuranteeValidity"
                label="BG Validity"
                rules={[{ required: true, message: "" }]}
              >
                <DatePicker size="small" format="YYYY/MM/DD" />
              </Form.Item>
            </div>
            <div
              className="drawer-form-group"
              style={{ display: "flex", columnGap: 7, marginTop: 15 }}
            >
              <Button
                type="default"
                onClick={handleCancel}
                style={{ marginLeft: "auto" }}
                htmlType="button"
              >
                Cancle
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CreateEditDocument;
