import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationTaskEventGetDetails } from "services/redux/organizer/goals/action";

import InternalMessage from "components/mail/InternalMessage";
import { isEmpty } from "lodash";
import moment from "moment";
import { getCalenderListEvents } from "services/redux/calendar/action";
import { getInstanceChatCenterPagination } from "services/redux/instanceChat/action";
import { messageApplicationTypeListing } from "services/remanentCalls";
import CreateEventDrawer from "views/Oragnizer/TaskEventDrawer/CreateEventDrawer";
import PrivateEvents from "./Events/PrivateEvents";
import PublicEvents from "./Events/PublicEvents";
import TodayAppointment from "./Events/TodayAppointment";

const EventLists = ({ type, meetingInvitees }) => {
  const dispatch = useDispatch();
  const textRef = useRef(null);
  const { allCalendarEvents, calendarEventsLoading } = useSelector(
    (state) => state.calendarEvents
  );

  const { taskEventGetDetailsResp, taskEventGetDetailsRespLoading } =
    useSelector((state) => state.goalEvents);

  const [rotate, setRotate] = useState(0);
  const [visibleEventFormDrawer, setVisibleEventFormDrawer] = useState(false);
  const [eventFormSelect, setEventFormSelect] = useState("");
  const [newId, setNewId] = useState();
  const [openNewMessage, setOpenNewMessage] = useState(false);
  const [applicationType, setApplicationType] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [newTask, setNewTask] = useState(true);
  const [birthdayModal, setBirthdayModal] = useState(false);
  const [person, setPerson] = useState();

  const [isWatcher, setIsWatcher] = useState(false);
  const [isAssignee, setIsAssignee] = useState(true);
  const [isSelf, setIsSelf] = useState(true);

  let appointmentResponse = allCalendarEvents?.filter((x) => {
    return x?.eventTypeSystemName === "PERSONAL_GOAL";
  });

  let publicEventsResponse = allCalendarEvents?.filter((x) => {
    return (
      x?.eventTypeSystemName === "BIRTHDAY_ANNIVERSARY" ||
      x?.eventTypeSystemName === "PUBLIC_HOLIDAY"
    );
  });

  let calenderPublicData = publicEventsResponse;

  let calendarTodayData = appointmentResponse
    ?.filter(
      (newDate) =>
        moment(newDate?.start).startOf("day").format("YYYY-MM-DD") ===
        moment().startOf("day").format("YYYY-MM-DD")
    )
    .sort((a, b) => moment(a.start).diff(moment(b.start)));

  useEffect(() => {
    applicationTypeListing();
  }, []);

  useEffect(() => {
    if (!isEmpty(newId)) {
      dispatch(organizationTaskEventGetDetails(newId));
    }
  }, [newId]);

  useEffect(() => {
    if (applicationType) {
      const body = {
        MaxResultCount: 20,
        skipCount: 0,
        ApplicationTypeId: applicationType.id,
        ApplicationId: newId,
      };
      dispatch(getInstanceChatCenterPagination(body));
    }
  }, [newId, applicationType]);

  const applicationTypeListing = async () => {
    const resp = await messageApplicationTypeListing().catch((err) => {
      message.error("Failure");
    });
    if (resp && resp.status === 200) {
      resp?.data?.map((type) => {
        if (type.displayName === "Appointment") {
          setApplicationType(type);
        }
      });
    }
  };

  const getData = () => {
    let appendUrl = `skipCount=0`;
    dispatch(getCalenderListEvents(appendUrl, "Dashboard"));
  };

  const onCloseEventFormDrawer = () => {
    setVisibleEventFormDrawer(false);
  };

  const handleNewMessage = (id) => {
    setNewId(id);
    setOpenNewMessage(true);
    dispatch(organizationTaskEventGetDetails(id));
  };

  const currentDate = moment().startOf("day").format("YYYY-MM-DD");

  const calendarRemainingData = appointmentResponse?.filter(
    (newDate) =>
      moment(newDate?.start).startOf("day").format("YYYY-MM-DD") > currentDate
  );

  const allCalenderData =
    type === "public" ? calenderPublicData : appointmentResponse;

  return (
    <div
      className="flip-card"
      style={
        type === "public"
          ? { height: "340px", overflow: "auto" }
          : { height: "363px", overflow: "auto" }
      }
    >
      {type === "public" ? (
        <PublicEvents
          allCalenderData={allCalenderData}
          textRef={textRef}
          type={type}
          setBirthdayModal={setBirthdayModal}
          setPerson={setPerson}
          calendarEventsLoading={calendarEventsLoading}
          birthdayModal={birthdayModal}
          person={person}
        />
      ) : (
        <>
          <div
            className="flip-card-inner"
            style={{ transform: `rotateY(${rotate * 180}deg)` }}
          >
            <div className="flip-card-front" style={{ padding: "15px" }}>
              <TodayAppointment
                calendarTodayData={calendarTodayData}
                rotate={rotate}
                setRotate={setRotate}
                setVisibleEventFormDrawer={setVisibleEventFormDrawer}
                setEventFormSelect={setEventFormSelect}
                setNewTask={setNewTask}
                type={type}
                handleNewMessage={handleNewMessage}
                setNewId={setNewId}
                setModalVisible={setModalVisible}
                calendarEventsLoading={calendarEventsLoading}
                modalVisible={modalVisible}
                taskEventGetDetailsResp={taskEventGetDetailsResp}
                applicationType={applicationType}
                isWatcher={isWatcher}
                isAssignee={isAssignee}
                isSelf={isSelf}
                setIsWatcher={setIsWatcher}
                setIsAssignee={setIsAssignee}
                setIsSelf={setIsSelf}
              />
            </div>
            <div className="flip-card-back" style={{ padding: "15px" }}>
              <PrivateEvents
                calendarRemainingData={calendarRemainingData}
                rotate={rotate}
                setRotate={setRotate}
                setVisibleEventFormDrawer={setVisibleEventFormDrawer}
                setEventFormSelect={setEventFormSelect}
                setNewTask={setNewTask}
                type={type}
                handleNewMessage={handleNewMessage}
                setNewId={setNewId}
                setModalVisible={setModalVisible}
                calendarEventsLoading={calendarEventsLoading}
                isWatcher={isWatcher}
                isAssignee={isAssignee}
                isSelf={isSelf}
                setIsWatcher={setIsWatcher}
                setIsAssignee={setIsAssignee}
                setIsSelf={setIsSelf}
              />
              {openNewMessage && (
                <InternalMessage
                  allData={meetingInvitees}
                  openNewMessage={openNewMessage}
                  setOpenNewMessage={setOpenNewMessage}
                  specificTenderData={taskEventGetDetailsResp}
                  applicationType={applicationType}
                  setNewId={setNewId}
                  tenderLoading={taskEventGetDetailsRespLoading}
                />
              )}
            </div>
          </div>

          <CreateEventDrawer
            visibleEventFormDrawer={visibleEventFormDrawer}
            eventFormSelect={eventFormSelect}
            onCloseEventFormDrawer={onCloseEventFormDrawer}
            // dateClickDetails={dateClickDetails}
            setModalVisible={setModalVisible}
            getData={getData}
            newTask={newTask}
          />
        </>
      )}
    </div>
  );
};

export default EventLists;
