import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  InputNumber,
  Checkbox,
  Card,
  Typography,
  Divider,
  Drawer,
  Empty,
  Rate,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import ButtonForForm from "./buttons";
import { useDispatch, useSelector } from "react-redux";
import { actionForCreateUpdateApplicantReviews } from "services/redux/hrSystem/action";
import moment from "moment";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

const reviewCardStyle = {
  background: "#D6E9FF",
  marginBottom: 5,
};

const { Text } = Typography;

export default function ReviewDetails({
  setOpenApplicantDrawer,
  setSelectedSingleApplicantData,
  formApplicantsBasic,
  formApplicantEdu,
  formApplicantJob,
  formApplicantReview,
  setActiveTabIndexofAppicant,
  statusUpdateButton,
  selectedSingleApplicantDAta,
  jobTitleId,
  selectedTemporarilyApplicantId,
}) {
  const dispatch = useDispatch();

  const { applicationSingleDataSuccess, applicantTabSuccess } = useSelector(
    (state) => state.hrSystemServicesReducer
  );

  const storedData = sessionStorage.getItem("userId");

  const [openFormOrDetailReview, setOpenFormOrAndDetailReview] =
    useState(false);

  useEffect(() => {
    setOpenFormOrAndDetailReview(applicationSingleDataSuccess);
  }, [applicationSingleDataSuccess]);

  const onFinishReviewDetails = (values) => {
    let applicantId = selectedSingleApplicantDAta
      ? selectedSingleApplicantDAta?.id
      : selectedTemporarilyApplicantId;
    let apiData = {
      ...values,
      applicantId: applicantId,
      reviewerId: JSON.parse(storedData),
    };
    dispatch(
      actionForCreateUpdateApplicantReviews(
        apiData,
        applicantId,
        jobTitleId,
        "add"
      )
    );
  };

  const handleOpenFormReviewAndDetail = () => {
    setOpenFormOrAndDetailReview(!openFormOrDetailReview);
  };

  const [showForm, setShowForm] = useState(false);
  // console.log("showForms", showForm);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const formLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <>
      <div
        style={{
          // background: "green",
          marginLeft: 505,
        }}
      >
        {!openFormOrDetailReview ? (
          <>
            <PlusCircleOutlined
              onClick={handleOpenFormReviewAndDetail}
              style={{ color: "green" }}
            />
            {"  "}Add Review
          </>
        ) : (
          <>
            <MinusCircleOutlined
              onClick={handleOpenFormReviewAndDetail}
              style={{ color: "green" }}
            />
            {"  "}Show Reviews
          </>
        )}
      </div>

      {!openFormOrDetailReview && (
        <>
          {selectedSingleApplicantDAta?.applicantReviewDtos?.length > 0 ? (
            <>
              {selectedSingleApplicantDAta?.applicantReviewDtos?.map(
                (x, index) => (
                  <div
                    className={
                      index === 0 ? "all-reviews review-at-top" : "all-reviews"
                    }
                    // style={reviewCardStyle}
                  >
                    <Text style={{ marginLeft: "10px" }}>
                      {x?.reviwerRemarks}
                    </Text>
                    <Divider style={{ marginBottom: 5, marginTop: 15 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "10px",
                      }}
                    >
                      <span>
                        <Text> By: {x?.reviewerName}</Text>
                        <Rate
                          allowHalf
                          defaultValue={x?.overallRatting}
                          // onChange={handleRatingChange}
                          disabled
                          style={{ fontSize: "10px", marginLeft: "10px" }}
                        />
                      </span>
                      ​
                      <Text>
                        {moment(x?.reviewDateTime).format("YYYY-MM-DD hh:mm a")}
                      </Text>
                    </div>
                  </div>
                )
              )}
            </>
          ) : (
            <Empty />
          )}
        </>
      )}

      {openFormOrDetailReview && (
        <Form
          onFinish={onFinishReviewDetails}
          // layout="vertical"
          form={formApplicantReview}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Row gutter={24}>
            {/* <Col span={12}>
              <Form.Item
                label="Name"
                name="reviewerName"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                // rules={[
                //   { required: true, message: "Please enter a name" },
                // ]}
              >
                <Input placeholder="Enter your name..." />
              </Form.Item>
            </Col> */}

            <Col span={12}>
              <Form.Item
                label="Ratings"
                name="overallRatting"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter your ratings",
                //   },
                // ]}
              >
                {/* <Input type="number" placeholder="Enter your ratings..." /> */}
                <Rate
                  // onChange={onRateChange}
                  style={{ border: "1px solid lightgray", width: "250px" }}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Stage"
                name="applicationStageLeveId"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                // rules={[
                //   { required: true, message: "Please enter a stage" },
                // ]}
              >
                <Select
                  placeholder="Choose one..."
                  options={statusUpdateButton}
                />
              </Form.Item>
            </Col>

            {/* <Col span={12}>
              <Form.Item
                label="Approved"
                name="isApproved"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                valuePropName="checked"
                // rules={[
                //   { required: true, message: "Please select an option" },
                // ]}
              >
                <Checkbox />
              </Form.Item>
            </Col> */}

            <Col span={24}>
              <Form.Item
                label="Remarks"
                name="reviwerRemarks"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
              >
                <Input.TextArea
                  placeholder="Enter remarks..."
                  autoSize={{ minRows: 6 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <ButtonForForm
            setOpenApplicantDrawer={setOpenApplicantDrawer}
            formApplicantsBasic={formApplicantsBasic}
            formApplicantEdu={formApplicantEdu}
            formApplicantJob={formApplicantJob}
            formApplicantReview={formApplicantReview}
            setSelectedSingleApplicantData={setSelectedSingleApplicantData}
            setActiveTabIndexofAppicant={setActiveTabIndexofAppicant}
          />
        </Form>
      )}
    </>
  );
}
