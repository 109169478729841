import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space } from "antd";
import { employeesPagination } from "services/redux/profile/profile.action";
import { LogoImage } from "utils/imageConstants";
import { isEmpty } from "lodash-es";
import { current } from "@reduxjs/toolkit";

const UserListCompact = ({
  rowEventhandlers,
  setRowClassName,
  grantedPolices,
  inputSearchValue,
  isEmployeeStatusChecked,
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "employeeName",
      key: "employeeName",
      sorter: false,
      width: 10,
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      sorter: false,
      width: 10,
    },
  ];

  const dispatch = useDispatch();
  const { employeesPaginationResponse, employeesPaginationResponseLoading } =
    useSelector((state) => state.profile);

  const [data, setdata] = useState([]);
  const [totalCount, setTotalCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  // added
  const handleFormSubmit = () => {
    dispatch(
      employeesPagination(
        {
          EmployeeStatus: isEmployeeStatusChecked,
        },
        false
      )
    );
  };
  // useEffect(() => {
  //   if (isEmployeeStatusChecked === true) {
  //     dispatch(
  //       employeesPagination(
  //         {
  //           EmployeeStatus: isEmployeeStatusChecked,
  //         },
  //         false
  //       )
  //     );
  //   } else {
  //     getData();
  //   }
  // }, [isEmployeeStatusChecked]);

  useEffect(() => {
    getData();
  }, [inputSearchValue]);

  // useEffect(() => {
  //   if (!isEmpty(employeesPaginationResponse?.items)) {
  //     setdata(employeesPaginationResponse.items);
  //     setTotalCount(employeesPaginationResponse.totalCount);

  //     if (
  //       employeesPaginationResponse.totalCount !==
  //       employeesPaginationResponse.items.length
  //     ) {
  //       setCurrentPage((currentPage) => currentPage + 1);
  //     }
  //   } else {
  //     setdata();
  //   }
  // }, [employeesPaginationResponse, employeesPaginationResponseLoading]);

  useEffect(() => {
    if (!isEmpty(employeesPaginationResponse?.items)) {
      setdata(employeesPaginationResponse.items);
      setTotalCount(employeesPaginationResponse.totalCount);

      if (
        employeesPaginationResponse.totalCount >
        (currentPage - 1) * pageSize
      ) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    } else {
      setdata([]);
    }
  }, [employeesPaginationResponse, currentPage, pageSize]);

  const getData = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    Sorting,
    ForPagination = false
  ) => {
    dispatch(
      employeesPagination(
        {
          MaxResultCount,
          SkipCount,
          Sorting,
          //SortType,
          SearchKeyword: inputSearchValue,
        },
        ForPagination
      )
    );
  };

  // const handleTableChange = (pagination, filters, sorter, extra) => {
  // 	let Sorting = sorter.columnKey;
  // 	let SortType = sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : undefined;
  // 	let MaxResultCount = pageSize;
  // 	let SkipCount = (pagination.current - 1) * pagination.pageSize;

  // 	getData(MaxResultCount, SkipCount, Sorting, SortType);
  // 	// getData(pagination.current, pagination.pageSize);
  // };

  // bug pervious
  // useEffect(() => {
  //   var tableContent = document.querySelector(".person-table .ant-table-body");
  //   tableContent.addEventListener("scroll", (event) => {
  //     let maxScroll = event.target.scrollHeight - event.target.clientHeight;
  //     let currentScroll = Math.round(event.target.scrollTop);
  //     if (currentScroll === maxScroll) {
  //       // load more data
  //       let MaxResultCount = pageSize;
  //       let SkipCount = currentPage * pageSize;
  //       if (
  //         employeesPaginationResponse?.totalCount > SkipCount ||
  //         !employeesPaginationResponse
  //       ) {
  //         getData(MaxResultCount, SkipCount, inputSearchValue, true);
  //       }
  //     }
  //   });
  // }, [currentPage, employeesPaginationResponse]);

  // solved
  useEffect(() => {
    // var tableContent = document.querySelector(".person-table .ant-table-body");
    var tableContent = document.querySelector(
      ".userlistcompact_tbl .ant-table-body"
    );
    const handleScroll = (event) => {
      let maxScroll = event.target.scrollHeight - event.target.clientHeight;
      let currentScroll = event.target.scrollTop;
      // Adjust this buffer value as needed
      let buffer = 10;

      if (currentScroll + buffer >= maxScroll) {
        // Load more data if available
        let MaxResultCount = pageSize;
        let SkipCount = data.length;
        if (employeesPaginationResponse?.totalCount > SkipCount) {
          getData(MaxResultCount, SkipCount, inputSearchValue, true);
        }
      }
    };

    tableContent.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      tableContent.removeEventListener("scroll", handleScroll);
    };
  }, [data, employeesPaginationResponse, inputSearchValue]);

  return (
    <Table
      onRow={rowEventhandlers}
      // className="userlistcompact_tbl organizer__table person-table"
      className="userlistcompact_tbl hr__table person-table"
      columns={columns}
      rowClassName={setRowClassName}
      //scroll={true}
      rowKey="appUserId"
      dataSource={data}
      sticky={true}
      scroll={{ y: 350, x: "max-content" }}
      //onChange={handleTableChange}
      loading={{
        indicator: <img src={LogoImage} height="auto" width="50px" />,
        spinning: employeesPaginationResponseLoading,
      }}
      pagination={false}
      // pagination={{
      // 	total: totalCount,
      // 	pageSize: pageSize,
      // }}
    />
  );
};

export default UserListCompact;
