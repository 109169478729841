import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd";
import { useDispatch } from "react-redux";
import { actionForAddUpdateBidBound } from "services/redux/cead/action";
const { Item } = Form;

export default function AdvancedDetailsForm({
  setIsRenewalRecord,
  isRenewalRecord,
  singleTenderCeadData,
  advancedDetailsForm,
  currencyOptions,
}) {
  const dispatch = useDispatch();

  const OnAnotherStagePerformanceBoundFormFinish = (values) => {
    let apiBody = values;

    dispatch(
      actionForAddUpdateBidBound(apiBody, singleTenderCeadData?.id, "bidType=5")
    );

    console.log("valuess", apiBody);
  };
  return (
    <Form
      labelCol={{ span: 10 }}
      form={advancedDetailsForm}
      onFinish={OnAnotherStagePerformanceBoundFormFinish}
    >
      <Item label="Issuing Bank" name="issueingBank">
        <Input placeholder="Select Bank" />
      </Item>

      <Item label="Issuing Foreign Bank (if any)" name="foreignBank">
        <Input placeholder="Select Bank" />
      </Item>

      <Item label="L/C Description" name="description">
        <Input.TextArea placeholder="Enter Description" />
      </Item>

      <Item label="Currency" name="currencyId">
        <Select
          options={currencyOptions}
          // defaultValue={() => {
          //   allCurrencyListingResponse?.map((currency) => {
          //     if (currency.abbvr == "USD") {
          //       return currency.currencyId;
          //     }
          //   });
          // }}
        />
      </Item>

      <Item label="Amount" name="amount">
        <Input placeholder="Enter Amount" />
      </Item>

      <Item label="Validity" style={{ marginTop: "15px" }} name="validUpto">
        <DatePicker />
      </Item>

      <Item
        label="Last Shipment Date"
        style={{ marginTop: "25px" }}
        name="lastShipmentDate"
      >
        <DatePicker />
      </Item>

      {/* <Item label="Renewal Record" name="IsRenewalRecord">
        <Checkbox
          onChange={(e) => {
            setIsRenewalRecord(e.target.checked);
          }}
          value={isRenewalRecord}
          defaultChecked={
            singleTenderCeadData?.performancE_BOUND_BidInformation?.[0]
              ?.isRenewalRecord
          }
        />
      </Item> */}

      <Button type="primary" htmlType="submit" style={{ marginLeft: "29em" }}>
        Save
      </Button>
    </Form>
  );
}
