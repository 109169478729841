import { REQUISITION_TYPES } from '../../../types';

const INTIAL_STATE = {
	remainingLeaveForUser: [],
	remainingLeaveForUserLoading: true,
};

export default function remaininigLeaveForUserReducer(state = INTIAL_STATE, action) {
	switch (action.type) {

		// Leave Application Pagination
		case REQUISITION_TYPES.LEAVE_REMAINING_FOR_USER_INT:
			return {
				...state,
				remainingLeaveForUser: [],
				remainingLeaveForUserLoading: true,
			};
		case REQUISITION_TYPES.LEAVE_REMAINING_FOR_USER_SUCCESS:
			return {
				...state,
				remainingLeaveForUser: action.payload,
				remainingLeaveForUserLoading: false,
			};

		case REQUISITION_TYPES.LEAVE_REMAINING_FOR_USER_FINISH:
			return {
				...state,
				remainingLeaveForUserLoading: false,
			};

		default:
			return {
				...state,
			};
	}
}
