import { GLOBAL, LOAN_APPLICATION_TYPES } from "../../../types";

const INTIAL_STATE = {
  loanApplicationPagination: [],
  loanApplicationPaginationLoading: false,

  addLoanApplicationResp: [],
  addLoanApplicationRespLoading: false,

  updateLoanApplicationResp: [],
  updateLoanApplicationRespLoading: false,

  loanApplicationDataGetDetails: {},
  loanApplicationDataGetDetailsLoading: false,

  loanUpdateSupervisor: [],
  loanUpdateSupervisorLoading: false,

  loanUpdateAccountant: [],
  loanUpdateAccountantLoading: false,

  loanUpdateManagement: [],
  loanUpdateManagementLoading: false,
};

export default function loanApplicationReducer(state = INTIAL_STATE, action) {
  switch (action.type) {
    // Leave Application Pagination
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_PAGINATION_INIT:
      return {
        ...state,
        loanApplicationPagination: [],
        loanApplicationPaginationLoading: true,
      };
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_PAGINATION_SUCCESS:
      return {
        ...state,
        loanApplicationPagination: action.payload,
        loanApplicationPaginationLoading: false,
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_PAGINATION_FINISH:
      return {
        ...state,
        loanApplicationPaginationLoading: false,
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_APPROVAL_PAGINATION_INIT:
      return {
        ...state,
        loanApplicationApprovalPagination: [],
        loanApplicationApprovalPaginationLoading: true,
      };
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_APPROVAL_PAGINATION_SUCCESS:
      return {
        ...state,
        loanApplicationApprovalPagination: action.payload,
        loanApplicationApprovalPaginationLoading: false,
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_APPROVAL_PAGINATION_FINISH:
      return {
        ...state,
        loanApplicationApprovalPaginationLoading: false,
      };

    // Add Leave Application
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_ADD_INIT:
      return {
        ...state,
        addLoanApplicationResp: [],
        addLoanApplicationRespLoading: true,
      };
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_ADD_SUCCESS:
      const updatedItem = action.payload;
      const updatedLoanApplicationPagination =
        state.loanApplicationPagination?.items?.concat(updatedItem);
      return {
        ...state,
        addLoanApplicationResp: action.payload,
        addLoanApplicationRespLoading: false,
        loanApplicationPagination: {
          items: updatedLoanApplicationPagination,
          totalCount: updatedLoanApplicationPagination.length,
        },
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_ADD_FINISH:
      return {
        ...state,
        addLoanApplicationRespLoading: false,
      };

    // Update Leave Application
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_INIT:
      return {
        ...state,
        updateLoanApplicationResp: [],
        updateLoanApplicationRespLoading: true,
      };
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_SUCCESS:
      return {
        ...state,
        updateLoanApplicationResp: action.payload,
        updateLoanApplicationRespLoading: false,
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_FINISH:
      return {
        ...state,
        updateLoanApplicationRespLoading: false,
      };

    // Leave Application Details
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_DATA_DETAILS_INIT:
      return {
        ...state,
        loanApplicationDataGetDetails: [],
        loanApplicationDataGetDetailsLoading: true,
      };
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_DATA_DETAILS_SUCCESS:
      return {
        ...state,
        loanApplicationDataGetDetails: action.payload,
        loanApplicationDataGetDetailsLoading: false,
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_DATA_DETAILS_FINISH:
      return {
        ...state,
        loanApplicationDataGetDetailsLoading: false,
      };

    //loan update supervisor
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_SUPERVISOR_INIT:
      return {
        ...state,
        loanUpdateSupervisor: [],
        loanUpdateSupervisorLoading: true,
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_SUPERVISOR_SUCCESS:
      return {
        ...state,
        loanUpdateSupervisor: action.payload,
        loanUpdateSupervisorLoading: false,
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_ACCOUNTANT_INIT:
      return {
        ...state,
        loanUpdateAccountant: [],
        loanUpdateAccountantLoading: true,
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_ACCOUNTANT_SUCCESS:
      return {
        ...state,
        loanUpdateAccountant: action.payload,
        loanUpdateAccountantLoading: false,
      };
    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_MANAGEMENT_INIT:
      return {
        ...state,
        loanUpdateManagement: [],
        loanUpdateManagementLoading: true,
      };

    case LOAN_APPLICATION_TYPES.LOAN_APPLICATION_UPDATE_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loanUpdateManagement: action.payload,
        loanUpdateManagementLoading: false,
      };

    case GLOBAL.GLOBAL_ERROR:
      return {
        ...state,
        loanUpdateSupervisorLoading: false,
        loanUpdateAccountantLoading: false,
        loanUpdateManagementLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
