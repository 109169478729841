export const styleForFlagMenu = {
  display: "flex",
  width: 140,
  justifyContent: "space-around",
};

export const styleForFlagMenuIcon = {
  width: "18%",
};

export const styleForFlagMenuName = {
  width: "96%",
};

export const styleForSubMenuCompose = { width: 144, height: "auto" };
