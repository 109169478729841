import { Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { renderRightText } from "utils/rightAlignCellData";

export default function FeeReceivedDetails({ singleTenderCeadData }) {
  const columns = [
    {
      title: "Currency",
      dataIndex: "abbvr",
    },
    {
      title: renderRightText("Received Amt"),
      dataIndex: "receivedAmount",
      render: (text) => renderRightText(text),
    },
    {
      title: renderRightText("Exchabge Rate"),
      dataIndex: "exchangeRate",
      render: (text) => renderRightText(text),
    },
    {
      title: renderRightText("Vat %"),
      dataIndex: "vatPercentage",
      render: (text) => renderRightText(text),
    },
    {
      title: renderRightText("Bank Charge"),
      dataIndex: "bankCharge",
      render: (text) => renderRightText(text),
    },
    {
      title: renderRightText("L/C Description"),
      dataIndex: "remarks",
      render: (text) => renderRightText(text),
    },
    {
      title: renderRightText("Action"),
      render: (text) => renderRightText(<EditOutlined />),
    },
  ];

  let dataSo = [];

  const dataSource = singleTenderCeadData?.tenderCEADPaymentReceivedDtos?.map(
    (y, index) => {
      singleTenderCeadData?.tenderCEADPaymentReceivedDtos?.[
        index
      ]?.tenderPaymentReceivedInformationDtos?.map((x) => {
        // return {
        //   ...singleTenderCeadData?.tenderCEADPaymentReceivedDtos?.[index],
        //   ...x,
        //   ...x.currencyDto,
        // };
        dataSo = [
          ...dataSo,
          {
            ...singleTenderCeadData?.tenderCEADPaymentReceivedDtos?.[index],
            ...x,
            ...x.currencyDto,
          },
        ];
      });
    }
  );

  return (
    <>
      <Table columns={columns} dataSource={dataSo} pagination={false} />
    </>
  );
}
