import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Button, Col, Row, Input, Select, Layout } from 'antd';
import { validateMessages } from 'utils/misc';
import { sectorAdd, sectorUpdate } from 'services/redux/contact/contact.action';

const { Header, Footer } = Layout;
const { Option } = Select;

export const SectorAddUpdateDrawer = ({ onAddUpdateSectorClose, drawerVisibility, isUpdateSector }) => {
	const { sectorAddResponse, sectorUpdateResponse } = useSelector((state) => state.contact);

	const [form] = Form.useForm();

	form.resetFields();

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isUpdateSector) return;
		form.setFieldsValue({
			...isUpdateSector,
		});
	}, [isUpdateSector]);

	useEffect(() => {
		onAddUpdateSectorClose();
	}, [sectorAddResponse, sectorUpdateResponse]);

	const onSubmit = async () => {
		try {
			let values = await form.validateFields();
			if (isUpdateSector) {
				dispatch(sectorUpdate(isUpdateSector.id, values));
			} else {
				dispatch(sectorAdd(values));
			}
		} catch (errorInfo) {
			console.error('Failed:', errorInfo);
		}
	};

	return (
		<Drawer visible={drawerVisibility} width={472} maskClosable={false} className="drawer" onClose={onAddUpdateSectorClose} closable={false}>
			<Header className="drawer__header">
				<h4 className="drawer__header--title">{`${isUpdateSector ? 'Update' : 'Create'} Sector Name`}</h4>
			</Header>

			<Form layout="vertical" form={form} name="control-hooks" validateMessages={validateMessages} className="drawer-form-wrapper">
				<div className="drawer-form-row">
					<Form.Item className="form__group" name="sectorName" label="Sector Name" rules={[{ required: true }]}>
						<Input autoComplete='off' />
					</Form.Item>
				</div>
				<div className="model-footer">
					<Button onClick={onAddUpdateSectorClose}>Cancel</Button>
					<Button onClick={onSubmit} type="primary">
						Submit
					</Button>
				</div>
			</Form>
		</Drawer>
	);
};

