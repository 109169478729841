import { Button, Form, Input, Modal, Row, DatePicker, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSOWDetails } from "services/redux/officeresource/business/action";
const { RangePicker } = DatePicker;

const SowFilter = ({ filterModal, setFilterModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [title, setTitle] = useState("");
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const advancedSearch = (values) => {
    const newValues = {
      // SearchKeyword: values.SearchKeyword ?? "",
      ScopeOfWorks: values.SearchKeyword ?? "",
      SearchDateFrom: startDate ?? "",
      SearchDateTo: endDate ?? "",
    };

    dispatch(getSOWDetails(newValues));
    setFilterModal(false);
  };

  return (
    <Modal
      visible={filterModal}
      onCancel={() => {
        setFilterModal(false);
      }}
      footer={false}
      title={
        <Typography.Paragraph style={{ margin: "auto", textAlign: "center" }}>
          SOW Filter
        </Typography.Paragraph>
      }
    >
      <Form
        form={form}
        labelAlign="left"
        colon={false}
        autocomplete="off"
        onFinish={advancedSearch}
        {...layout}
      >
        <Form.Item
          name="SearchKeyword"
          label="Search Keyboard"
          style={{ paddingBottom: "5px" }}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Range">
          <RangePicker
            onChange={(value) => {
              setStartDate(value ? moment(value[0]).format("YYYY-MM-DD") : "");
              setEndDate(value ? moment(value[1]).format("YYYY-MM-DD") : "");
            }}
          />
        </Form.Item>
        <Row
          style={{
            justifyContent: "right",
            paddingTop: "10px",
            marginTop: "20px !important",
            gap: "10px",
          }}
        >
          <Button
            type="ghost"
            shape="round"
            autoComplete="off"
            onClick={() => {
              form.resetFields();
            }}
          >
            Remove
          </Button>

          <Button
            type="ghost "
            shape="round"
            htmlType="submit"
            autoComplete="off"
          >
            Search
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default SowFilter;
