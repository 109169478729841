import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Split from "react-split";
import { isEmpty, slice, stubFalse, cloneDeep } from "lodash";

import {
  Divider,
  Layout,
  Tag,
  Typography,
  Image,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Tooltip,
  Menu,
  Dropdown,
  Skeleton,
} from "antd";

import BusinessTenderDetails from "views/OfficeResource/Reference/Tabs/TenderTabs/BusinessTenderDetails";

import {
  CloseOutlined,
  PlusCircleOutlined,
  StarFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

// includes
import EmailChat from "./EmailChat";
import ReplyChatBox from "./Includes/ReplyChatBox";
import MailMessageCenterHeader from "./Includes/MailMessageCenterHeader";
import { BusinessModal } from "./Includes/BusinessModal";
import AddNewQuickEmail from "./Includes/AddNewQuickEmail";
import TaskDetailsLabel from "views/GlobalEmailChat/TaskDetailsLabel";

// redux

import { useDispatch, useSelector } from "react-redux";
import {
  getInstanceMessageCenterPagination,
  sendCorrectionMessage,
} from "services/redux/email/instanceMessageCenter/action";
import {
  mailReferenceTagUpdate,
  changeEmailStatusType,
  mailReferenceListing,
  getChildReferenceUpateResponse,
  getReplyDetails,
} from "services/redux/email/mailMessageCenter/action";
import { mailReferenceGetDetails } from "services/redux/email/referenceTags/action";

// utils
import { getParticipantsEmailDetails } from "utils/commonFunc";
import { appBaseUrl, cqBaseUrl } from "../../utils/config";
import LeaveDetails from "./Includes/LeaveDetails";
import {
  getParticipantsEmailsExternal,
  createHighlightedColor,
  zipDownload,
} from "../../utils/commonFunc";
import { textCenterDot, fileDownload } from "utils/commonFunc";

import { setSelectedFileName } from "../../services/redux/common/persistFiles/action";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import {
  getEmailAddressesFromWithBehalfOfListing,
  getParticipantTypeEmailAddressesListing,
} from "services/redux/email/participantEmailAdresses/action";
import { current } from "@reduxjs/toolkit";
import { LogoImage } from "utils/imageConstants";
import { useForm } from "react-hook-form";
import { Procurement } from "services/redux/requistions/procurement/api";
import ProcurementDetails from "./Includes/ProcurementDetails";
import LoanDetailss from "./Includes/LoanDetailss";
import ModalCorrectionMessage from "../../components/modals";
import ProjectDetails from "views/OfficeResource/Reference/Tabs/TenderTabs/ProjectDetails";
import pdfViewerMail from "components/Documents/pdfViewerMail";
import AppointmentDetails from "./Includes/AppointmentDetails";
import EmployeeEvaluationDetails from "./Includes/EmployeeEvaluationDetails";
import TadaDetails from "./Includes/TadaDetails";
import GuestEntertaimentDetails from "views/GuestEntertainment/GuestEntertaimentDetails";
import CompliantDetails from "views/OfficeResource/Reference/Tabs/TenderTabs/CompliantDetails";
import pdfViewer1 from "components/Documents/pdfViewerIfrane";
import pdfViewer from "components/Documents/ViewDocument";
import AllFileViewer from "components/Documents/allFileViewer";
import { PrincpalPaymentData } from "views/CEAD/tabs/anotherStage/accountView/paymentReceived/details";
import { CEADWeeklyReportData } from "views/CEAD/tabs/weeklyReport/report/details";
import { getSingleDocumentDetails } from "services/redux/email/documentSector/action";
import TenderCircularMail from "views/OfficeResource/Reference/Tabs/TenderTabs/companies/CircularMail";

const Trash = "assets/svg/email-trash.svg";
const attachment = "assets/svg/attatchment.svg";
const TrashRed = "assets/svg/trash-red.svg";
const Printer = "assets/svg/printer-outline.svg";
const Star = "assets/svg/star.svg";
const StarOutlined = "assets/svg/starred-12.svg";
const internalmessage = "assets/svg/internal-msg-12.svg";
const internalMessageGreen = "assets/svg/internal-msg-12-green.svg";
const windownew = "assets/svg/windownew-16.svg";
const hide = "assets/svg/hide-16.svg";
const show = "assets/svg/email-show16.svg";
const Extend = "assets/svg/windownew-16.svg";
const internalMsg = "assets/svg/internal-msg.svg";
const chatIcon = "assets/svg/chats.svg";
const attachmentView = "assets/svg/attachment-view.svg";
const attachmentDownload = "assets/svg/attachment-download.svg";
const newWindow = "assets/svg/newWindow.svg";

const EmailDetails = ({
  emailType,
  toggleMail,
  currentSelectedMailMessage,
  mailMessageParticipantTypeListing,
  mailMessageParticipationStatusListing,
  allParticipants,
  replyEmailCompose,
  messageCenterTypeName,
  setchatBox,
  chatBox,
  setChatHeight,
  height,
  chatHeight,
  setShowSideMenuEmail,
  mailMessageCenterPaginationLoading,
  mailMessageCenterPagination,
  setPreSelectEmailCard,
  setMessageCenterId,
  messageCenterId,
  mailBodyGetDetailsLoading,
  setCurrentSelectedMailMessage,
  mailBodyGetDetailsResponse,
  openAutoReferencing,
  setOpenAutoReferencing,
  predefinedData,
  setPredefinedData,
  referenceTagsFromAutoReference,
  filteredEmails,
  corporateSharedAccessAccountHolderUserId,
  setBulkStatusLoading,
  bulkStatusLoading,
  setContextMenushow,
  contextMenushow,
  origin,
}) => {
  console.log("currentSelectedMailMessage", currentSelectedMailMessage);

  const dispatch = useDispatch();
  const [loadingMail, setLoadingMail] = useState(false);

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.mailMessagePeristState
  );
  const {
    instanceMessageCenterPagination,
    instanceMessageCenterPaginationLoading,
    replyInstanceMessageResp,
  } = useSelector((state) => state.instanceMessageCenter);

  const {
    mailReferenceUpdateResponse,
    // childReferenceUpdateResponse,
    changeEmailStatusTypeRespLoading,
    getDetailsReplyResponse,
    // getDetailsReplyResponseLoading,
  } = useSelector((state) => state.mailMessageCenter);

  const { mailReferenceGetDetailsResponse } = useSelector(
    (state) => state.mailReference
  );

  const {
    procurementUpdateSupervisor,
    // procurementUpdateSupervisorLoading,
    procurementUpdateAccountant,
    // procurementUpdateAccountantLoading,
    procurementUpdateManagement,
    // procurementUpdateManagementLoading,
  } = useSelector((state) => state.procurement);
  const { loanUpdateSupervisor, loanUpdateAccountant, loanUpdateManagement } =
    useSelector((state) => state.loanApplication);
  // console.log(mailBodyGetDetailsResponse,mailReferenceUpdateResponse,mailMessageCenterPagination?.items?.[0],"from emaildetailsscreen")

  const [form] = Form.useForm();
  const gridIframe = useRef(null);

  const {
    participantTypeEmailAddressesListing,
    participantTypeEmailAddressesListingLoading,
  } = useSelector((state) => state.participantEmailAddresses);
  const { token } = useSelector((state) => state.auth);
  const todoEventDetails = useSelector((state) => state.toDoEvents);
  const { toDoGetDetailsResponse } = todoEventDetails;

  const tenderBasicDetail = useSelector((state) => state.tender);

  const { multipleSelectedEmail } = useSelector(
    (state) => state.multipleSelectedEmail
  );
  // console.log('multipelEmailSelection',multipleSelectedEmail)
  const { tenderBasicGetDetailsResponse } = tenderBasicDetail;

  const { projectGetDetailsResponse, projectDetailsLoading } = useSelector(
    (state) => state.project
  );

  const { complainGetDetailsResponse, complainGetDetailsLoading } = useSelector(
    (state) => state.complaint
  );

  const { getDocumentSingleDetailsResponse, getDocumentSingleDetailsLoading } =
    useSelector((state) => state.documentSector);

  const printTableRef = useRef();

  const [hideInternalMessage, setHideInternalMessage] = useState(false);

  const [attachmentModalVisible, setAttachmentModalVisible] = useState(false);
  const [instanceMessageCenterListing, setInstanceMessageCenterListing] =
    useState({ totalCount: 0, items: [] });
  const [participantTypeFrom, setParticipantTypeFrom] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sliceWidth, setSliceWidth] = useState(window.innerWidth);
  const [participantTypeToNames, setParticipantTypeToNames] = useState("");
  const [emailTypeToNames, setEmailTypeToNames] = useState("");
  const [emailTypeCCNames, setEmailTypeCCNames] = useState("");
  const [participantTypeCCNames, setParticipantTypeCCNames] = useState("");
  const [initialMessage, setInitialMessage] = useState({});
  const [replyMsgDetails, setReplyMsgDetails] = useState({});
  const [replyStatus, setReplyStatus] = useState("");
  const [resetReplyChat, setResetReplyChat] = useState("");
  const [replyOfChat, setReplyofChat] = useState("");
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const [referenceTags, setReferenceTags] = useState([]);
  const [splicedData, setSplicedData] = useState([]);
  const [checkEmail, setCheckEmail] = useState("");
  const [showClosable, setShowClosable] = useState(-1);
  const [visibleModal, setVisibleModal] = useState(false);
  const [activeChat, setActiveChat] = useState(false);
  const [replyChatheight, setReplyChatHeight] = useState(false);
  const [smallChatHeight, setSmallChatHeight] = useState(false);
  const [isHover, setHoverActive] = useState(false);
  const [newQuickCompanyValue, setNewQuickCompanyValue] = useState("");
  const [pdfLoaded, setPdfLoaded] = useState(false);
  //for quickaddmodal
  const [notFoundCompany, setNotFoundCompany] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [companyCurrentPage, setCompanyCurrentPage] = useState(1);
  const [personId, setPersonId] = useState();
  const [companyId, setCompanyId] = useState();
  const [disableSavedButton, setDisableSaveButton] = useState(true);
  const [notFoundValue, setNotFoundValue] = useState("");
  const [allPersonListingState, setAllPersonListingState] = useState([]);
  const [allCompanyListingState, setAllCompanyListingState] = useState([]);
  const [newValuePersonCompany, setNewValuePersonCompany] = useState(false);
  const [newCompanyValue, setNewCompanyValue] = useState(false);
  const [participantType, setParticipantType] = useState("");
  const [openLink, setOpenLink] = useState(false);
  const [appType, setAppType] = useState("");
  const [composeIfReference, setComposeIfReference] = useState("");
  const [multipelEmailSelection, setMultipleEmailSelection] = useState();
  const [modalVisibleTo, setModalVisibleTo] = useState(false);
  const [modalVisibleCc, setModalVisibleCc] = useState(false);
  const [modalVisibleBcc, setModalVisibleBcc] = useState(false);
  const [openRepliedModal, setOpenRepliedModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");
  const [showModalForCorrection, setShowModalForCorretionTag] = useState({
    visibility: false,
    tagName: "",
  });

  //for checkMessage from emailDetails to addNewQuickEmail
  const [messageFromEmailDetails, setMessageFromEmailDetails] =
    useState("EmailDetails");

  // ..........................................................................

  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  // const [contextMenushow, setContextMenushow] = useState(false); // hide menu
  const [rightClickIndex, setRightClickIndex] = useState(null);

  // console.log(currentSelectedMailMessage);

  const handleBodyClick = () => {
    setContextMenushow(false);
  };

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      let target;
      if (
        event.target.offsetParent.classList.contains("emailList__card--ref")
      ) {
        target = event.target.offsetParent;
      } else if (event.target.classList.contains("div.emailList__card--ref")) {
        target = event.target.querySelector(".emailList__card--ref");
      } else {
        target = event.target.offsetParent.offsetParent;
      }

      if (target) {
        let id = target.hasAttribute("data-id")
          ? target.getAttribute("data-id")
          : null;
        if (id) {
          setAnchorPoint({ x: event.pageX, y: event.pageY });
          setContextMenushow(true);
          let rightIndex = mailMessageCenterPagination?.items
            ?.map((item) => {
              return item.id;
            })
            .indexOf(id);
          setRightClickIndex(rightIndex);
        }
      }
    },
    [setAnchorPoint]
  );
  const [doc, setDoc] = useState();
  // useEffect(async () => {
  //   if (currentSelectedMailMessage) {
  //     const doc1 = await pdfViewerMail(
  //       `${currentSelectedMailMessage.body}${token}`
  //     );
  //     setDoc(doc1);
  //   }
  // }, [currentSelectedMailMessage, token]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentSelectedMailMessage) {
        const doc1 = await pdfViewerMail(
          `${currentSelectedMailMessage.body}${token}`
        );
        setDoc(doc1);
      }
    };

    fetchData();
  }, [currentSelectedMailMessage, token]);

  useEffect(() => {
    if (
      currentSelectedMailMessage?.applicationTypeSystemName === "Document" &&
      currentSelectedMailMessage?.mailMessageReferenceDtos?.length > 0
    ) {
      const body = {
        reference:
          currentSelectedMailMessage?.mailMessageReferenceDtos[0]
            ?.referenceCode ?? null,
      };
      dispatch(
        getSingleDocumentDetails(
          currentSelectedMailMessage?.applicationId,
          body
        )
      );
    }
  }, [currentSelectedMailMessage]);

  useEffect(() => {
    let referenceList = document.querySelector(".emailList__card--ref_2");
    document.addEventListener("click", handleBodyClick);
    if (referenceList) {
      referenceList.addEventListener("contextmenu", handleContextMenu);
      return () => {
        referenceList.removeEventListener("contextmenu", handleContextMenu);
      };
    }
    return () => {
      document.removeEventListener("click", handleBodyClick);
    };
  });

  useEffect(() => {}, [currentSelectedMailMessage]);
  // ..........................................................................

  // console.log("filteredEmailsMessageHeader", filteredEmails);

  useEffect(() => {
    setReferenceTags([]);
    if (isEmpty(currentSelectedMailMessage?.mailMessageReferenceDtos)) {
      setReferenceTags([]);
    } else {
      const tenderDtos =
        currentSelectedMailMessage?.mailMessageReferenceDtos?.map((data) => {
          return data?.referenceCode;
        });

      setReferenceTags(tenderDtos);
    }
  }, [currentSelectedMailMessage]);

  useEffect(() => {
    setReferenceTags([]);
    if (mailReferenceUpdateResponse) {
      setReferenceTags(mailReferenceUpdateResponse);
    }
    if (isEmpty(mailReferenceUpdateResponse)) {
      setReferenceTags([]);
    } else {
      const tenderDtos = mailReferenceUpdateResponse?.map((data) => {
        return data.referenceCode;
      });

      setReferenceTags(tenderDtos);
    }
  }, [mailReferenceUpdateResponse]);

  useEffect(() => {
    setReferenceTags([]);
    // console.log(
    //   "changeEmailStatusTypeRespLoadingHeree",
    //   // referenceTags,
    //   changeEmailStatusTypeRespLoading,
    //   currentSelectedMailMessage
    // );
    if (changeEmailStatusTypeRespLoading === false) {
      const tenderDtoss = filteredEmails?.filter((x) => {
        return (
          x.instanceMainMessageCenterId ===
          currentSelectedMailMessage?.instanceMainMessageCenterId
        );
      });

      const tenderDtos = tenderDtoss?.[0]?.mailMessageReferenceDtos?.map(
        (data) => {
          return data?.referenceCode;
        }
      );

      // setReferenceTags(tenderDtos);

      setReferenceTags(tenderDtos);
    }
  }, [changeEmailStatusTypeRespLoading, filteredEmails]);

  // useEffect(() => {
  //   setReferenceTags([]);
  //   let x = [];
  //   if (
  //     !isEmpty(
  //       referenceTagsFromAutoReference?.currentMessageCenterReferenceDtos
  //     )
  //   ) {
  //     x =
  //       referenceTagsFromAutoReference?.currentMessageCenterReferenceDtos?.map(
  //         (x) => {
  //           return x.referenceCode;
  //         }
  //       );
  //     setReferenceTags(x);
  //   } else {
  //     x =
  //       referenceTagsFromAutoReference?.emailReferenceIdReferenceCodeDtos?.map(
  //         (x) => {
  //           return x.referenceCode;
  //         }
  //       );
  //     setReferenceTags(x);
  //   }
  // }, [referenceTagsFromAutoReference]);

  useEffect(() => {
    if (
      !isEmpty(currentSelectedMailMessage) &&
      !isEmpty(instanceMessageCenterPagination?.items)
    ) {
      setHideInternalMessage(true);
      setInstanceMessageCenterListing(instanceMessageCenterPagination);

      // setHideInternalMessage(true);
    } else {
      setInstanceMessageCenterListing([]);
      if (!chatBox) {
        setHideInternalMessage(false);
      }
      // setHideInternalMessage(false);
    }
  }, [instanceMessageCenterPagination, currentSelectedMailMessage]);

  const filterTags = (removedTag) => {
    let data = referenceTags.filter((tags) => tags !== removedTag);
    setReferenceTags(data);
    if (!isEmpty(data)) {
      let val = {
        tenderDtos: data?.map((tenderCode) => ({
          messageCenterId:
            currentSelectedMailMessage?.instanceMainMessageCenterId,
          referenceCode: tenderCode,
        })),
      };
      dispatch(
        mailReferenceTagUpdate(val.tenderDtos, currentSelectedMailMessage)
      );
    } else if (isEmpty(data)) {
      let val = {
        tenderDtos: [
          {
            messageCenterId:
              !isEmpty(currentSelectedMailMessage) &&
              // currentSelectedMailMessage?.id,
              currentSelectedMailMessage?.instanceMainMessageCenterId,
            referenceCode: null,
          },
        ],
      };
      dispatch(
        mailReferenceTagUpdate(val.tenderDtos, currentSelectedMailMessage)
      );
    }
  };

  function info(type) {
    setAppType(type);
    setOpenLink(true);
    setComposeIfReference(type);
    Modal.confirm({
      title: "Please Assigne Reference",
      okText: "Yes",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      cancelText: "No",
      onOk() {
        setShowBusinessModal(true);
        dispatch(mailReferenceGetDetails(currentSelectedMailMessage?.id));
      },
      onCancel() {},
    });
  }

  // const totalToCounts =
  //   currentSelectedMailMessage?.applicationTypeDetails?.tos?.length;

  const optionForCorrections = (tag) => {
    return (
      <Layout.Content>
        <Button
          onClick={() => {
            setShowModalForCorretionTag({
              visibility: true,
              tagName: tag,
            });
          }}
        >
          Send Correction Message
        </Button>
      </Layout.Content>
    );
  };

  function test(value, type) {
    setVisibleModal(true);
    setCheckEmail(value);
    setParticipantType(type);
  }

  useEffect(() => {
    if (!isEmpty(currentSelectedMailMessage)) {
      let emailMessageParticipantWithTypeDtos =
        currentSelectedMailMessage?.applicationTypeDetails;
      if (!isEmpty(currentSelectedMailMessage?.body)) {
        setInitialMessage(currentSelectedMailMessage?.body);
      }

      if (currentSelectedMailMessage?.applicationTypeSystemName === "EMail") {
        // let emailToNames = GetParticipantsEmailsExternal(currentSelectedMailMessage?.applicationTypeDetails?.emailRecipients?.emailTOs);
        // let emailToNames = getParticipantsEmailsExternal(
        //   currentSelectedMailMessage?.applicationTypeDetails?.tos,
        //   test
        // );
        let emailCCNames =
          currentSelectedMailMessage?.applicationTypeDetails?.cCs;
        setEmailTypeCCNames(emailCCNames);
        // setEmailTypeToNames(emailToNames);
      } else {
        if (!isEmpty(emailMessageParticipantWithTypeDtos)) {
          setParticipantTypeFrom(emailMessageParticipantWithTypeDtos?.from);

          let participantToNames = getParticipantsEmailDetails(
            emailMessageParticipantWithTypeDtos?.tos
          );

          setParticipantTypeToNames(participantToNames);

          let participantCCNames = getParticipantsEmailDetails(
            emailMessageParticipantWithTypeDtos?.cCs
          );
          setParticipantTypeCCNames(participantCCNames);
        } else {
          setParticipantTypeFrom([]);
          setParticipantTypeToNames([]);
          setParticipantTypeCCNames([]);
        }
      }
    } else {
      setMessageCenterId("");
      setInitialMessage("");
      setParticipantTypeFrom([]);
      setParticipantTypeToNames([]);
      setParticipantTypeCCNames([]);
    }
  }, [currentSelectedMailMessage]);
  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  });
  useEffect(() => {
    if (windowWidth < 961) {
      setHideInternalMessage(false);
    } else {
      setHideInternalMessage(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (currentSelectedMailMessage?.applicationTypeSystemName !== "EMail")
      setCheckEmail(participantUserFromDto?.from[0]?.emailAddress);
    else {
      setCheckEmail(
        currentSelectedMailMessage?.applicationTypeDetails?.from[0]
          ?.emailAddress
      );
    }
  }, [currentSelectedMailMessage]);

  useEffect(() => {
    if (!isEmpty(getDetailsReplyResponse)) {
      setReplyMessage(getDetailsReplyResponse?.body);
    }
  }, [getDetailsReplyResponse]);

  // useEffect(() => {
  //   if (!isEmpty(procurementUpdateSupervisor)) {
  //     setInstanceMessageCenterListing(
  //       procurementUpdateSupervisor?.internalMessage
  //     );
  //     setHideInternalMessage(true);
  //   }
  //   if (!isEmpty(procurementUpdateAccountant)) {
  //     setInstanceMessageCenterListing(
  //       procurementUpdateAccountant?.internalMessage
  //     );
  //     setHideInternalMessage(true);
  //   }
  //   if (!isEmpty(procurementUpdateManagement)) {
  //     setInstanceMessageCenterListing(
  //       procurementUpdateManagement?.internalMessage
  //     );
  //     setHideInternalMessage(true);
  //   }
  // }, [
  //   procurementUpdateAccountant,
  //   procurementUpdateManagement,
  //   procurementUpdateSupervisor,
  // ]);

  // useEffect(() => {
  //   if (!isEmpty(loanUpdateSupervisor)) {
  //     setInstanceMessageCenterListing(loanUpdateSupervisor?.internalMessage);
  //     setHideInternalMessage(true);
  //   }
  //   if (!isEmpty(loanUpdateAccountant)) {
  //     setInstanceMessageCenterListing(loanUpdateAccountant?.internalMessage);
  //     setHideInternalMessage(true);
  //   }
  //   if (!isEmpty(loanUpdateManagement)) {
  //     setInstanceMessageCenterListing(loanUpdateManagement?.internalMessage);
  //     setHideInternalMessage(true);
  //   }
  // }, [loanUpdateSupervisor, loanUpdateAccountant, loanUpdateManagement]);

  useEffect(() => {
    if (openRepliedModal) {
      // console.log(currentSelectedMailMessage?.replyId)
      dispatch(getReplyDetails(currentSelectedMailMessage?.replyId));
    }
  }, [openRepliedModal]);
  // useEffect(() => {
  //   if (checkEmail) {
  //     dispatch(
  //       getParticipantTypeEmailAddressesListing({ searchkeyWord: checkEmail })
  //     );
  //   }
  // }, [checkEmail]);

  // useEffect(() => {
  // 	if (!isEmpty()) {
  // 		dispatch(getParticipantTypeEmailAddressesListing({ searchkeyWord: checkEmail }));
  // 	}
  // }, [participantTypeEmailAddressesListing]);

  const handleView = (src) => {
    setTimeout(() => {
      pdfViewer1(src);
    }, 1000);
  };

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleMouseEnter = (i) => {
    setShowClosable(i);
  };
  const handleMouseLeave = (i) => {
    setShowClosable(i);
  };
  const changeEmailStatus = (emailStatus, status, referenceValues) => {
    let query = {
      messageCenterId:
        selectedMailTypeAndMailData?.selectedMail?.instanceMainMessageCenterId,
      participantStatusName: emailStatus.trash ? "Trash" : "Special-flag-1",
      mailMessageParticipantTableId:
        selectedMailTypeAndMailData?.selectedMail
          ?.mailMessageParticipantTableId,
    };
    // console.log("query", query);

    if (!isEmpty(instanceMessageCenterPagination.items)) {
      let instanceMessageId = instanceMessageCenterPagination.items[0].id;
      query.instanceMessageId = instanceMessageId;
    }

    dispatch(
      changeEmailStatusType(
        query,
        status,
        messageCenterTypeName,
        emailStatus,
        referenceValues
      )
    );
  };

  const handleReply = (msg, status) => {
    setchatBox(true);

    setReplyofChat(msg.orderNumber);
    setReplyMsgDetails(msg);
    setReplyStatus(status);
    setReplyChatHeight(!replyChatheight);
  };

  const participantUserFromDto =
    currentSelectedMailMessage?.applicationTypeDetails;

  // console.log("currentSelectedMailMessage", currentSelectedMailMessage);

  let fixedLength = 2;
  let width = window.innerWidth;
  if (width > 1600) {
    fixedLength = 12;
  } else if (width > 1300) {
    fixedLength = 8;
  } else if (width > 900) {
    fixedLength = 6;
  } else if (width < 480) {
    fixedLength = 4;
  }
  //handle iframe
  const handleIframe = (e) => {
    let iframeItem = gridIframe.current;
    // console.log(iframeItem)
    if (iframeItem) {
      let dom = iframeItem.contentWindow.document;
      let pTag = dom.querySelector(".editor-block-wrapper");
      // console.log(dom)
      if (pTag) {
        pTag.addEventListener("mousedown", function (e) {
          let highlight = dom.querySelector(".highlight-wrapper");
          if (highlight) highlight.remove();
        });
        pTag.addEventListener("mouseup", function (e) {
          const selection = dom.getSelection();
          const { x, y, width } = selection
            .getRangeAt(0)
            .getBoundingClientRect();
          const xCal = x + width / 2;
          const yCal = y + window.scrollY - 40;
          const range = selection.getRangeAt(0).cloneRange();
          const highlightWrapper = createHighlightedColor(xCal, yCal);
          highlightWrapper.addEventListener("click", function (e) {
            let span = document.createElement("span");
            if (e.target.tagName == "SPAN") {
              let color = e.target.getAttribute("color");
              span.style.background = color;
              range.surroundContents(span);
            } else if (e.target.tagName == "DIV") {
              let color = e.target.parentNode.getAttribute("color");
              span.style.background = color;
              range.surroundContents(span);
            }
            highlightWrapper.remove();
          });
          if (width > 1) {
            pTag.parentNode.insertBefore(highlightWrapper, pTag);
          }
        });
      }
    }
  };
  // const iframePresentOrNot = document.getElementById("printf") ? true : false ;
  // console.log(iframePresentOrNot)

  const printFunction = () => {
    // console.log("currentdata", currentSelectedMailMessage);

    let from =
      currentSelectedMailMessage?.applicationTypeDetails?.from[0]?.emailAddress;
    let tos = currentSelectedMailMessage?.applicationTypeDetails?.tos.map(
      (to) => to.emailAddress
    );
    let tosEmailAddress = tos.toString();

    let cCs = currentSelectedMailMessage?.applicationTypeDetails?.cCs.map(
      (cc) => cc.emailAddress
    );

    let ccEmailAddress = cCs.toString();
    const iframePresentOrNot = document.getElementById("printf") ? true : false;

    if (iframePresentOrNot) {
      const ifrDoc = document.getElementById("printf").contentDocument;
      // console.log(ifrDoc,"printfunction")
      var elem = ifrDoc.createElement("div");
      const dateAndTimeEmail =
        currentSelectedMailMessage?.creationTime &&
        moment(currentSelectedMailMessage?.creationTime).format("LLLL");

      const subjectToPrint = currentSelectedMailMessage?.subject;

      const referenceTagsPrint = referenceTags?.map((tags) => tags);
      // style="border-bottom-style: solid;
      //                     border-bottom-width: thin;margin-bottom:3px;
      elem.innerHTML = `<div>
                          <div>From: &nbsp${from} <br>
                          To:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${tosEmailAddress}<br>
                          ${
                            !isEmpty(
                              currentSelectedMailMessage?.applicationTypeDetails
                                ?.cCs
                            )
                              ? "Cc:&nbsp&nbsp;&nbsp;&nbsp;&nbsp;  " +
                                ccEmailAddress
                              : ""
                          }
                          </div>
                          <hr>
                          <div>
                            Date: ${dateAndTimeEmail}
                          </div>
                          <hr>
                          <div>
                            ${
                              referenceTags?.length > 0
                                ? "Reference: " + referenceTagsPrint
                                : ""
                            }
                          </div>
                          <div>
                            Subject: ${subjectToPrint}
                          </div>
                        </div>`;

      elem.style.fontSize = "16px";
      elem.style.marginBottom = "30px";
      elem.style.fontWeight = "400";
      elem.style.color = "#8e8a8a";
      elem.style.wordBreak = "break-all";

      // console.log(hideInternalMessage)

      ifrDoc.body.firstElementChild?.prepend(elem);
      let internalMsg = document.querySelector(".emailDetails__body--chat");
      let cloneInternal = internalMsg?.cloneNode(true);
      const internalMsgWrapper = document.createElement("div");
      if (hideInternalMessage) {
        var newChildNodes = ifrDoc.body?.childNodes;
        var newElement = document.createElement("div");
        for (var i = 0; i < newChildNodes.length; i++) {
          newElement.appendChild(newChildNodes.item(i));
        }
        newElement.style.width = "100%";
        newElement.style.marginRight = "2%";

        internalMsgWrapper.style.height = "500px";
        internalMsgWrapper.style.width = hideInternalMessage ? "35%" : "0%";
        internalMsgWrapper.style.borderStyle = "none";
        internalMsgWrapper.style.borderLeft = "1px solid black";

        const messageHeaderDiv = document.createElement("div");
        messageHeaderDiv.style.height = "30px";
        messageHeaderDiv.style.background = "#f5f5f5";
        messageHeaderDiv.style.borderBottom = "none";
        messageHeaderDiv.style.textAlign = "center";

        messageHeaderDiv.innerHTML = "<h3>Messages</h3>";

        internalMsgWrapper.appendChild(messageHeaderDiv);

        const internalMessageDiv = document.createElement("div");
        const mesagePortion = instanceMessageCenterPagination?.items?.map(
          (toFromCcs) => {
            const userName = JSON.parse(sessionStorage.getItem("name"));
            const participantFrom =
              toFromCcs?.mailMessageParticipantWithTypeDtos?.participantFrom
                ?.participantUserDto?.userName;
            const participantTo =
              toFromCcs?.mailMessageParticipantWithTypeDtos?.participantTos?.[0]
                ?.participantUserDto?.userName;
            const participantCcs =
              toFromCcs?.mailMessageParticipantWithTypeDtos?.participantCCs?.[0]
                ?.participantUserDto?.userName;

            const dateAndTime =
              moment(toFromCcs.creationTime).format("YYYY") !==
              moment(new Date()).format("YYYY")
                ? moment(toFromCcs.creationTime).format("DD/MM/YYYY")
                : moment(toFromCcs.creationTime).calendar({
                    sameDay: "h:mm a",
                    nextDay: "[Tomorrow]",
                    nextWeek: "DD MMM",
                    lastDay: "[Yesterday]",
                    lastWeek: "DD MMM",
                    sameElse: "DD MMM",
                  });
            const replyNumber = toFromCcs?.orderNumber;

            const instanceMessageOrderNumber =
              toFromCcs?.replyOfInstanceMessageOrderNumber;

            // const dateAndTimeWithCss = `<div style="font-size:"8px">${dateAndTime}</div>`
            const allParticipants = `${participantFrom} => ${participantTo}${
              participantCcs ? "," + participantCcs : ""
            }
          &nbsp  ${dateAndTime} [${replyNumber},${
              instanceMessageOrderNumber
                ? "Reply of  " + instanceMessageOrderNumber
                : ""
            }]<br>
          ${toFromCcs?.messageText}`;

            const stylePartipants = `<div style ="border-style: none;margin-top: 5px;padding: 0px 5px;border-radius: 15px;
          ${userName == participantFrom ? "margin-left:30px" : ""};
          ${
            userName == participantFrom
              ? "background: #d6e9ff;"
              : "background-color: #f5f5f5;"
          }">
          <div style = "padding: 4px 12px;">${allParticipants}</div>
          </div>`;

            let chatItem = document.createElement("div");
            chatItem.innerHTML = stylePartipants;
            internalMessageDiv.appendChild(chatItem);
            // return allParticipants
          }
        );
        // console.log(allParticipants)
        internalMsgWrapper.appendChild(internalMessageDiv);

        ifrDoc.body.appendChild(newElement);
        ifrDoc.body.appendChild(internalMsgWrapper);
        ifrDoc.body.style.display = "flex";
        ifrDoc.body.style.justifyContent = "space-between";
      }
      window.frames["printf"].focus();
      window.frames["printf"].print();
      if (hideInternalMessage) internalMsgWrapper.remove();
      elem.remove();
      window.focus();
    } else {
      window.print();
    }
  };
  // useEffect(()=>{
  //   setModalVisibleTo(false)
  //   // setModalVisibleCc(false)
  // },[currentSelectedMailMessage])

  const screenWidth = window.innerWidth;
  let fixedLengthEmail = 2;
  if (screenWidth > 1600) {
    fixedLengthEmail = 8;
  } else if (screenWidth > 1300) {
    fixedLengthEmail = 5;
  } else if (screenWidth > 900) {
    fixedLengthEmail = 4;
  } else if (screenWidth < 480) {
    fixedLengthEmail = 1;
  }

  const menu = (
    <div
      className="dropdown-attatchmemnt"
      style={{ maxHeight: "400px", overflow: "scroll" }}
    >
      {!isEmpty(currentSelectedMailMessage?.mailMessageAttachmentDtos) &&
        currentSelectedMailMessage?.mailMessageAttachmentDtos.length > 1 && (
          <div
            className="attachment-download-all-wrapper"
            style={{ float: "right", paddingBottom: "5px" }}
          >
            <button
              className="attachment-download-all-btn"
              style={{ cursor: "pointer" }}
              onClick={() => {
                fileDownload(
                  `${cqBaseUrl}/File/GetEmailMessageAttachmentFileWithZip/${currentSelectedMailMessage?.mailMessageAttachmentDtos?.[0]?.fileInformationId}?access_token=${token}`,
                  "compress.zip"
                );
              }}
            >
              Download All
            </button>
          </div>
        )}
      {!isEmpty(currentSelectedMailMessage?.mailMessageAttachmentDtos) &&
        currentSelectedMailMessage?.mailMessageAttachmentDtos.map(
          (fileList, i) => {
            const fileType = fileList.displayFileName.split(".").pop();
            return (
              <div key={i} className="attachmentItem">
                <AllFileViewer
                  file={fileList}
                  cqBaseUrl={cqBaseUrl}
                  token={token}
                />
              </div>
            );
          }
        )}
    </div>
  );

  const hanldeOkForModalCorrection = () => {
    const correctionBody = {
      mailMessageCenterId:
        currentSelectedMailMessage?.instanceMainMessageCenterId,
      messageText: `Please correct this reference tag named "${showModalForCorrection?.tagName}"`,
    };
    dispatch(
      sendCorrectionMessage(correctionBody, setShowModalForCorretionTag)
    );
  };

  return (
    <>
      {/* {contextMenushow && (
        <>
          <ul
            className="email-list-menu-action-wrapper"
            // style={{ top: anchorPoint.y + 5, left: anchorPoint.x + 20 }}
            // onClick={handleStatusClick}
          >
            <li data-action="Delete">
              <div className="right-click-menu-item">
                <p>Delete</p>
              </div>
            </li>

            <li data-action="Show">
              <div className="right-click-menu-item">
                <p>Show</p>
              </div>
            </li>
          </ul>
        </>
      )} */}

      <div
        onClick={() => {
          setShowSideMenuEmail(false);
        }}
        onMouseEnter={() => {
          setContextMenushow(false);
        }}
        className={`emailDetails ${toggleMail ? "show" : ""}`}
      >
        <div>
          <div>
            {origin !== "tender" && (
              <MailMessageCenterHeader
                emailType={emailType}
                currentSelectedMailMessage={currentSelectedMailMessage}
                setCurrentSelectedMailMessage={setCurrentSelectedMailMessage}
                mailMessageCenterPagination={mailMessageCenterPagination}
                replyEmailCompose={replyEmailCompose}
                instanceMessageCenterPagination={instanceMessageCenterListing}
                messageCenterTypeName={messageCenterTypeName}
                printTableRef={printTableRef}
                setPreSelectEmailCard={setPreSelectEmailCard}
                info={info}
                appType={appType}
                setAppType={setAppType}
                openLink={openLink}
                setOpenLink={setOpenLink}
                referenceTags={referenceTags}
                multipleSelectedEmail={multipleSelectedEmail}
                printFunction={printFunction}
                openAutoReferencing={openAutoReferencing}
                setOpenAutoReferencing={setOpenAutoReferencing}
                setLoadingMail={setLoadingMail}
                corporateSharedAccessAccountHolderUserId={
                  corporateSharedAccessAccountHolderUserId
                }
                setBulkStatusLoading={setBulkStatusLoading}
              />
            )}

            {bulkStatusLoading && mailMessageCenterPaginationLoading ? (
              <Skeleton />
            ) : filteredEmails?.length > 0 ? (
              <>
                {multipleSelectedEmail && multipleSelectedEmail.length > 0 ? (
                  <div className="multiple-selection-wrapper">
                    <span>
                      {multipleSelectedEmail.length} Email
                      {multipleSelectedEmail?.length > 1 ? "s " : " "}Selected
                    </span>
                  </div>
                ) : (
                  <div className="emailDetails__body">
                    <div className="emailChat__left">
                      <div className="emailDetails__body--title">
                        <div className="email-title-start">
                          {origin !== "tender" && (
                            <div
                              className={`email-star ${
                                isEmpty(currentSelectedMailMessage)
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() =>
                                changeEmailStatus(
                                  !currentSelectedMailMessage?.isSpecialFlagOne &&
                                    !currentSelectedMailMessage?.isSpecialFlagOne_InstanceMessage
                                    ? {
                                        isSpecialFlagOne: true,
                                        isSpecialFlagOne_InstanceMessage: true,
                                        starred: "Special-Flag-1",
                                      }
                                    : {
                                        isSpecialFlagOne_InstanceMessage: false,
                                        isSpecialFlagOne: false,
                                        starred: "Special-Flag-1",
                                      },
                                  currentSelectedMailMessage?.isSpecialFlagOne ||
                                    currentSelectedMailMessage?.isSpecialFlagOne_InstanceMessage
                                    ? "Remove"
                                    : "Add",
                                  currentSelectedMailMessage?.mailMessageReferenceDtos
                                )
                              }
                            >
                              <StarFilled
                                style={{
                                  width: 10,
                                  height: 10,
                                  color:
                                    currentSelectedMailMessage?.isSpecialFlagOne ||
                                    currentSelectedMailMessage?.isSpecialFlagOne_InstanceMessage
                                      ? "#ffd036"
                                      : "#c8c8c8",
                                }}
                              />
                            </div>
                          )}

                          <div className="emailReference__title">
                            <div className="email-title">
                              <div className="email-title--show">
                                <span
                                  className="subject-wrapper"
                                  onMouseEnter={() => {
                                    setHoverActive(true);
                                  }}
                                  onMouseOut={() => {
                                    setHoverActive(false);
                                  }}
                                >
                                  {currentSelectedMailMessage?.applicationTypeSystemName ===
                                  "Document"
                                    ? isEmpty(
                                        currentSelectedMailMessage?.mailMessageReferenceDtos
                                      )
                                      ? "Shared Document"
                                      : // : `Shared Document of ${currentSelectedMailMessage?.mailMessageReferenceDtos[0]?.referenceCode}`
                                        `${currentSelectedMailMessage?.subject}`
                                    : currentSelectedMailMessage?.subject}
                                </span>

                                {isHover && (
                                  <span className="custom-tooltip">
                                    {currentSelectedMailMessage?.subject}
                                  </span>
                                )}
                              </div>
                              <div className="emailList__card--refComp text-right tags">
                                {!isEmpty(currentSelectedMailMessage) && (
                                  <div
                                    className="emailList__card--comp"
                                    style={{ marginTop: -2 }}
                                  >
                                    <PlusCircleOutlined
                                      onClick={() => {
                                        setShowBusinessModal(true);
                                        dispatch(
                                          mailReferenceGetDetails(
                                            currentSelectedMailMessage?.instanceMainMessageCenterId
                                          )
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                                {!isEmpty(referenceTags) &&
                                  (referenceTags.length <= fixedLength ||
                                    referenceTags.length > fixedLength) &&
                                  referenceTags
                                    .slice(0, fixedLength)
                                    .map((tag, i) => {
                                      return (
                                        <>
                                          <Dropdown
                                            overlay={optionForCorrections(tag)}
                                            trigger={["contextMenu"]}
                                          >
                                            <div
                                              // className="emailList__card--ref"
                                              className={`emailList__card--ref`}
                                              key={i}
                                              onMouseEnter={() => {
                                                handleMouseEnter(i);
                                              }}
                                              onMouseLeave={() => {
                                                handleMouseLeave(-1);
                                              }}
                                            >
                                              {
                                                <Tag
                                                  data-id={tag}
                                                  closable={
                                                    showClosable == i
                                                      ? true
                                                      : false
                                                  }
                                                  className="reference-tags"
                                                  key={i}
                                                  onClose={() =>
                                                    filterTags(tag)
                                                  }
                                                >
                                                  <Tooltip
                                                    placement="bottom"
                                                    title={tag}
                                                  >
                                                    <div
                                                      className="emailList__card--ref"
                                                      style={{
                                                        marginTop: "5px",
                                                      }}
                                                    >
                                                      {tag?.substring(0, 12)}
                                                      {/* hello */}
                                                    </div>
                                                  </Tooltip>
                                                </Tag>
                                              }
                                            </div>
                                          </Dropdown>
                                        </>
                                      );
                                    })}
                                {!isEmpty(referenceTags) &&
                                  referenceTags.length > fixedLength && (
                                    <div className="reference-dropdown-wrapper">
                                      <span className="reference-count-wrapper">
                                        {referenceTags.length - fixedLength} +
                                      </span>
                                      <div className="reference-dropdown">
                                        <div className="reference-dropdown-bg">
                                          {referenceTags
                                            .slice(
                                              fixedLength,
                                              referenceTags.length
                                            )
                                            .map((tag, i) => {
                                              return (
                                                <>
                                                  <div
                                                    className="emailList__card--ref"
                                                    key={i}
                                                    onMouseEnter={() => {
                                                      handleMouseEnter(i);
                                                    }}
                                                    onMouseLeave={() => {
                                                      handleMouseLeave(-1);
                                                    }}
                                                  >
                                                    {
                                                      <Tag
                                                        closable={
                                                          showClosable == i
                                                            ? true
                                                            : false
                                                        }
                                                        className="reference-tags"
                                                        key={i}
                                                        onClose={() =>
                                                          filterTags(tag)
                                                        }
                                                      >
                                                        <Tooltip
                                                          placement="bottom"
                                                          title={tag}
                                                        >
                                                          <div className="emailList__card--ref">
                                                            {tag.substring(
                                                              0,
                                                              12
                                                            )}
                                                          </div>
                                                        </Tooltip>
                                                      </Tag>
                                                    }
                                                  </div>
                                                </>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {/* {!isEmpty(referenceTags) && referenceTags.length > 24 && (
														<div className="referenceDetails__reference--revisions mailSection">
															<Tooltip title={splicedData.join(',')}>
																<span className="referenceDetails--subtitle">+{splicedData.length}</span>
															</Tooltip>
														</div>
													)} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="emailDetails__body--titleIcon">
                          <div style={{ marginRight: -3 }}>
                            {/* <Link
                      rel="opener"
                      to="/emailDetails"
                      target="_blank"
                      height={height}
                      setChatHeight={setChatHeight}
                      chatHeight={chatHeight}
                      currentSelectedMailMessage={currentSelectedMailMessage}
                      mailMessageParticipantTypeListing={
                        mailMessageParticipantTypeListing
                      }
                      mailMessageParticipationStatusListing={
                        mailMessageParticipationStatusListing
                      }
                      mailMessageCenterPaginationLoading={
                        mailMessageCenterPaginationLoading
                      }
                      allParticipants={allParticipants}
                      replyEmailCompose={replyEmailCompose}
                      messageCenterTypeName={messageCenterTypeName}
                      setchatBox={setchatBox}
                      chatBox={chatBox}
                    >
                      <Image src={Extend} shape="square" preview={false} />
                    </Link> */}
                          </div>
                        </div>
                      </div>
                      {isEmpty(currentSelectedMailMessage) ? (
                        ""
                      ) : (
                        <div
                          className={`emailDetails__body--infosMain ${
                            isEmpty(
                              currentSelectedMailMessage?.applicationTypeDetails
                                ?.cCs
                            ) && "height"
                          }`}
                        >
                          <div className="email-from-date">
                            <div className="infosMain">
                              <span className="title">From:</span>
                              <div
                                style={{ display: "flex", cursor: "pointer" }}
                              >
                                {/* Here This From Where Sector Needs To Be Placed */}
                                <span>
                                  {participantUserFromDto?.from[0]?.fullName?.trim() ||
                                  participantUserFromDto?.from[0]?.username?.trim() ? (
                                    currentSelectedMailMessage?.applicationTypeSystemName !==
                                    "EMail" ? (
                                      participantUserFromDto?.from[0]
                                        ?.emailAddress ? (
                                        <Tooltip
                                          placement="bottom"
                                          title={
                                            <>
                                              {!isEmpty(
                                                participantUserFromDto?.from[0]
                                                  ?.emailAddress
                                              ) && (
                                                <span className="from-email-address">
                                                  {
                                                    participantUserFromDto
                                                      ?.from[0]?.emailAddress
                                                  }
                                                </span>
                                              )}
                                              {!isEmpty(
                                                participantUserFromDto?.from[0]
                                                  ?.fullName
                                              ) && (
                                                <span className="from-name">
                                                  {
                                                    participantUserFromDto
                                                      ?.from[0]?.fullName
                                                  }
                                                </span>
                                              )}
                                              {!isEmpty(
                                                participantUserFromDto?.from[0]
                                                  ?.companyName
                                              ) && (
                                                <span className="from-company-name">
                                                  {
                                                    participantUserFromDto
                                                      ?.from[0]?.companyName
                                                  }
                                                </span>
                                              )}
                                            </>
                                          }
                                        >
                                          <span className="contactsDetails--subtitle bold">
                                            {participantUserFromDto?.from[0]?.fullName?.trim()
                                              ? participantUserFromDto?.from[0]
                                                  ?.fullName
                                              : participantUserFromDto?.from[0]
                                                  ?.companyName}
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        participantUserFromDto?.from[0]
                                          ?.username ||
                                        participantUserFromDto?.from[0]
                                          ?.emailAddress
                                      )
                                    ) : participantUserFromDto?.from[0]
                                        ?.username ? (
                                      <Tooltip
                                        title={
                                          <>
                                            {!isEmpty(
                                              participantUserFromDto?.from[0]
                                                ?.emailAddress
                                            ) && (
                                              <span className="from-email-address">
                                                {
                                                  participantUserFromDto
                                                    ?.from[0]?.emailAddress
                                                }
                                              </span>
                                            )}
                                            {!isEmpty(
                                              participantUserFromDto?.from[0]
                                                ?.fullName
                                            ) && (
                                              <span className="from-name">
                                                {
                                                  participantUserFromDto
                                                    ?.from[0]?.fullName
                                                }
                                              </span>
                                            )}
                                            {!isEmpty(
                                              participantUserFromDto?.from[0]
                                                ?.companyName
                                            ) && (
                                              <span className="from-company-name">
                                                {
                                                  participantUserFromDto
                                                    ?.from[0]?.companyName
                                                }
                                              </span>
                                            )}
                                          </>
                                        }
                                        placement="bottom"
                                      >
                                        <span className="contactsDetails--subtitle bold">
                                          {
                                            participantUserFromDto?.from[0]
                                              ?.fullName
                                          }
                                        </span>
                                      </Tooltip>
                                    ) : !isEmpty(
                                        participantUserFromDto?.from[0]?.fullName?.trim()
                                      ) ? (
                                      <Tooltip
                                        placement="bottom"
                                        title={
                                          <>
                                            {!isEmpty(
                                              participantUserFromDto?.from[0]
                                                ?.emailAddress
                                            ) && (
                                              <span className="from-email-address">
                                                {
                                                  participantUserFromDto
                                                    ?.from[0]?.emailAddress
                                                }
                                              </span>
                                            )}
                                            {!isEmpty(
                                              participantUserFromDto?.from[0]
                                                ?.fullName
                                            ) && (
                                              <span className="from-name">
                                                {
                                                  participantUserFromDto
                                                    ?.from[0]?.fullName
                                                }
                                              </span>
                                            )}
                                            {!isEmpty(
                                              participantUserFromDto?.from[0]
                                                ?.companyName
                                            ) && (
                                              <span className="from-company-name">
                                                {
                                                  participantUserFromDto
                                                    ?.from[0]?.companyName
                                                }
                                              </span>
                                            )}
                                          </>
                                        }
                                      >
                                        <span className="contactsDetails--subtitle bold">
                                          {
                                            participantUserFromDto?.from[0]
                                              ?.fullName
                                          }
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <div className="plusIcon">
                                        {
                                          participantUserFromDto?.from[0]
                                            ?.emailAddress
                                        }
                                      </div>
                                    )
                                  ) : participantUserFromDto?.companyName &&
                                    currentSelectedMailMessage
                                      ?.applicationTypeDetails?.from[0]
                                      ?.companyName ? (
                                    <div>
                                      <Tooltip
                                        placement="bottom"
                                        title={
                                          participantUserFromDto?.from[0]
                                            ?.companyAbbrevation
                                            ? currentSelectedMailMessage
                                                ?.applicationTypeDetails
                                                ?.from[0]?.companyName
                                            : currentSelectedMailMessage
                                                ?.applicationTypeDetails
                                                ?.from[0]?.emailAddress
                                        }
                                      >
                                        <span className="to-company-info">
                                          {participantUserFromDto?.from[0]
                                            ?.companyAbbrevation
                                            ? participantUserFromDto?.from[0]
                                                ?.companyAbbrevation
                                            : participantUserFromDto?.from[0]
                                                ?.companyName}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    isEmpty(
                                      participantUserFromDto?.from[0]
                                        ?.companyName
                                    ) &&
                                    isEmpty(
                                      participantUserFromDto?.from[0]?.companyId
                                    ) &&
                                    isEmpty(
                                      participantUserFromDto?.from[0]?.fullName?.trim()
                                    ) &&
                                    isEmpty(
                                      participantUserFromDto?.from[0]?.username?.trim()
                                    ) && (
                                      <span className="contactsDetails--subtitle bold">
                                        {
                                          participantUserFromDto?.from[0]
                                            ?.emailAddress
                                        }
                                      </span>
                                    )
                                  )}
                                </span>
                                {/* Up To Here ============================ */}
                                &nbsp;
                                {currentSelectedMailMessage
                                  ?.applicationTypeDetails?.from[0]
                                  ?.companyName ? (
                                  <div>
                                    <Tooltip
                                      placement="topLeft"
                                      title={
                                        participantUserFromDto?.from[0]
                                          ?.companyAbbrevation
                                          ? `${currentSelectedMailMessage?.applicationTypeDetails?.from[0]?.companyName}[${currentSelectedMailMessage?.applicationTypeDetails?.from[0]?.emailAddress}]`
                                          : currentSelectedMailMessage
                                              ?.applicationTypeDetails?.from[0]
                                              ?.emailAddress
                                      }
                                    >
                                      <span className="to-company-info">
                                        {participantUserFromDto?.from[0]
                                          ?.companyAbbrevation
                                          ? participantUserFromDto?.from[0]
                                              ?.companyAbbrevation
                                          : participantUserFromDto?.from[0]
                                              ?.companyName}
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {isEmpty(
                                  participantUserFromDto?.from[0]?.companyName
                                ) &&
                                isEmpty(
                                  participantUserFromDto?.from[0]?.companyId
                                ) &&
                                isEmpty(
                                  participantUserFromDto?.from[0]?.fullName?.trim()
                                ) &&
                                isEmpty(
                                  participantUserFromDto?.from[0]?.username?.trim()
                                ) ? (
                                  <div className="plusIcon">
                                    <PlusCircleOutlined
                                      onClick={() => {
                                        setVisibleModal(true);
                                        setParticipantType("From");
                                      }}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/* {console.log(currentSelectedMailMessage?.body)} */}
                            {currentSelectedMailMessage?.applicationTypeSystemName ===
                              "Document" && (
                              <div className="infosMain">
                                <div style={{ display: "flex" }}>
                                  <span className="title">Sector: </span>
                                  <span
                                    className="details"
                                    title={getDocumentSingleDetailsResponse?.sectorNames?.join(
                                      ", "
                                    )}
                                    style={{
                                      fontSize: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {getDocumentSingleDetailsResponse?.sectorNames?.join(
                                      ", "
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}

                            <div className="infosMain">
                              <div>
                                <span
                                  className="details"
                                  title={
                                    currentSelectedMailMessage?.creationTime &&
                                    moment(
                                      currentSelectedMailMessage?.creationTime
                                    ).format("LLLL")
                                  }
                                  style={{ fontSize: "10px" }}
                                >
                                  {currentSelectedMailMessage?.creationTime &&
                                    moment(
                                      currentSelectedMailMessage?.creationTime
                                    ).format("LLLL")}
                                </span>{" "}
                              </div>
                            </div>
                          </div>

                          {/* after from-date */}

                          <div className="email-from-date">
                            <div className="infosMain">
                              <span className="title">To:</span>
                              <div
                                style={{ display: "flex", cursor: "pointer" }}
                              >
                                <span>
                                  {getParticipantsEmailsExternal(
                                    currentSelectedMailMessage
                                      ?.applicationTypeDetails?.tos,
                                    test,
                                    "To",
                                    modalVisibleTo,
                                    setModalVisibleTo
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className="infosMain">
                              <div>
                                <Dropdown overlay={menu}>
                                  <div className="infosMain header__attachments">
                                    {currentSelectedMailMessage?.applicationTypeSystemName !==
                                      "Leave" &&
                                      currentSelectedMailMessage?.applicationTypeSystemName !==
                                        "Loan" && (
                                        <>
                                          {!isEmpty(
                                            currentSelectedMailMessage?.mailMessageAttachmentDtos
                                          ) && (
                                            <div
                                              className="email-attachment"
                                              style={{
                                                background: "transparent",
                                              }}
                                            >
                                              <span
                                                className="emailDetails__body--headerAttach"
                                                // onClick={() =>
                                                //   setAttachmentModalVisible(true)
                                                // }
                                              >
                                                <img
                                                  className="attach-icon icon1"
                                                  src={attachment}
                                                  alt="attachment"
                                                />{" "}
                                                <span className="attachment_lenght_count">
                                                  {
                                                    currentSelectedMailMessage
                                                      ?.mailMessageAttachmentDtos
                                                      .length
                                                  }{" "}
                                                </span>
                                                {/* <Dropdown overlay={menu}> */}
                                                <a
                                                  onClick={(e) =>
                                                    e.preventDefault()
                                                  }
                                                >
                                                  <span className="attachement__chervon_down ">
                                                    <img src="/assets/svg/chervon_down.svg" />
                                                  </span>
                                                </a>
                                                {/* </Dropdown> */}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      )}
                                  </div>
                                </Dropdown>
                              </div>
                            </div>
                          </div>

                          {/* after to-attachment */}

                          <div
                          // className={`email-to-cc ${
                          //   modalVisibleTo &&
                          //   totalToCount > fixedLengthEmail &&
                          //   `more_email_visible`
                          // }`}
                          >
                            {!isEmpty(
                              currentSelectedMailMessage?.applicationTypeDetails
                                ?.cCs
                            ) && (
                              <>
                                <div
                                  className={`infosMain email-to ${
                                    currentSelectedMailMessage
                                      ?.applicationTypeDetails?.cCs
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <span className="title">Cc:</span>
                                  <div style={{ cursor: "pointer" }}>
                                    {getParticipantsEmailsExternal(
                                      currentSelectedMailMessage
                                        ?.applicationTypeDetails?.cCs,
                                      test,
                                      "CC",
                                      modalVisibleCc,
                                      setModalVisibleCc
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {!isEmpty(
                              currentSelectedMailMessage?.applicationTypeDetails
                                ?.bcCs
                            ) && (
                              <>
                                <div
                                  className={`email-to-Bcc ${
                                    currentSelectedMailMessage
                                      ?.applicationTypeDetails?.bcCs
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <span className="title">Bcc:</span>
                                  <div style={{ cursor: "pointer" }}>
                                    {getParticipantsEmailsExternal(
                                      currentSelectedMailMessage
                                        ?.applicationTypeDetails?.bcCs,
                                      test,
                                      "BCC",
                                      modalVisibleBcc,
                                      setModalVisibleBcc
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          {currentSelectedMailMessage?.replyDateTime && (
                            <div>
                              This{" "}
                              {
                                // <a
                                //   onClick={() => {
                                //     setOpenRepliedModal(true);
                                //   }}
                                // >
                                //   message
                                // </a>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    setOpenRepliedModal(true);
                                  }}
                                >
                                  message
                                </Link>
                              }{" "}
                              has been replied on{" "}
                              {moment(
                                currentSelectedMailMessage?.replyDateTime
                              ).format("LLLL")}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      className={`emailDetails__body--infos ${
                        hideInternalMessage && "chatVisible"
                      }`}
                      ref={printTableRef}
                    >
                      {currentSelectedMailMessage?.applicationTypeSystemName ===
                      "Task" ? (
                        <div className="globa-chat-wrapper">
                          <TaskDetailsLabel
                            todoDetails={toDoGetDetailsResponse}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {currentSelectedMailMessage?.applicationTypeSystemName ===
                      "Business" ? (
                        <div className="globa-chat-wrapper">
                          <BusinessTenderDetails
                            getDetails={tenderBasicGetDetailsResponse}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {currentSelectedMailMessage?.applicationTypeSystemName ===
                      "Project" ? (
                        <div className="globa-chat-wrapper">
                          <ProjectDetails
                            getDetails={projectGetDetailsResponse}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {currentSelectedMailMessage?.applicationTypeSystemName ===
                      "Complaint" ? (
                        <div className="globa-chat-wrapper">
                          <CompliantDetails
                            getDetails={complainGetDetailsResponse}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {isEmpty(currentSelectedMailMessage) ? (
                        !mailBodyGetDetailsLoading ? (
                          <div className="no-data-wrapper">
                            No Mail Selected
                          </div>
                        ) : (
                          <div>
                            <Skeleton />
                          </div>
                        )
                      ) : mailBodyGetDetailsLoading &&
                        isEmpty(currentSelectedMailMessage) ? (
                        <div>
                          <Skeleton />
                        </div>
                      ) : (
                        <div
                          className="email-description"
                          style={{ height: window.innerHeight - 109 }}
                        >
                          {currentSelectedMailMessage?.applicationTypeSystemName ===
                          "Leave" ? (
                            <LeaveDetails
                              leaveAppId={
                                currentSelectedMailMessage?.applicationId
                              }
                            />
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "Loan" ? (
                            <LoanDetailss
                              loanApplicationId={
                                currentSelectedMailMessage?.applicationId
                              }
                              setInstanceMessageCenterListing={
                                setInstanceMessageCenterListing
                              }
                            />
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "Procurement" ? (
                            <ProcurementDetails
                              procurementId={
                                currentSelectedMailMessage?.applicationId
                              }
                            />
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "TenderCEADInvoice" ? (
                            <PrincpalPaymentData
                              id={currentSelectedMailMessage?.applicationId}
                              setInstanceMessageCenterListing={
                                setInstanceMessageCenterListing
                              }
                            />
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "CEAD_WeeklyReport" ? (
                            <CEADWeeklyReportData
                              id={currentSelectedMailMessage?.applicationId}
                              setInstanceMessageCenterListing={
                                setInstanceMessageCenterListing
                              }
                            ></CEADWeeklyReportData>
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "EmployeeEvaluation" ? (
                            <>
                              <EmployeeEvaluationDetails
                                id={currentSelectedMailMessage?.applicationId}
                                setInstanceMessageCenterListing={
                                  setInstanceMessageCenterListing
                                }
                                showButton={true}
                                currentStep={null}
                              />
                            </>
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "Tada" ? (
                            <TadaDetails
                              tadaId={currentSelectedMailMessage?.applicationId}
                              setInstanceMessageCenterListing={
                                setInstanceMessageCenterListing
                              }
                            />
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "Appointment" ? (
                            <AppointmentDetails
                              appointmentId={
                                currentSelectedMailMessage?.applicationId
                              }
                            />
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "GuestEntertainment" ? (
                            <div className="globa-chat-wrapper">
                              <GuestEntertaimentDetails
                                guestInfoId={
                                  currentSelectedMailMessage?.applicationId
                                }
                                setInstanceMessageCenterListing={
                                  setInstanceMessageCenterListing
                                }
                              />
                            </div>
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "CircularPlanning" ? (
                            <TenderCircularMail
                              currentSelectedMailMessage={
                                currentSelectedMailMessage
                              }
                              tenderMailId={
                                currentSelectedMailMessage?.applicationId
                              }
                              mailMessageCenterId={
                                currentSelectedMailMessage?.instanceMainMessageCenterId
                              }
                              refCode={
                                currentSelectedMailMessage?.mailMessageReferenceDtos &&
                                currentSelectedMailMessage
                                  ?.mailMessageReferenceDtos[0]?.referenceCode
                              }
                              setInstanceMessageCenterListing={
                                setInstanceMessageCenterListing
                              }
                            />
                          ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                            "Document" ? (
                            <p>
                              <iframe
                                id="pdfViewerFrame"
                                style={{ height: 800, width: "100%" }}
                                title="document"
                                src={doc}
                                // onLoad={() => {
                                //   if (!pdfLoaded) {
                                //     // Check if the PDF has already been loaded
                                //     setPdfLoaded(true);
                                //     setTimeout(() => {
                                //       pdfViewer1(
                                //         `${currentSelectedMailMessage.body}${token}`,
                                //         800
                                //       );
                                //     }, 1000);
                                //   }
                                // }}
                              ></iframe>
                            </p>
                          ) : (
                            // : currentSelectedMailMessage?.applicationTypeSystemName ===
                            //   "QuickMessage" ? (
                            //   <>
                            //     {console.log(
                            //       "Test Me",
                            //       currentSelectedMailMessage
                            //     )}
                            //     <div className="mail-leave-details">
                            //       <div className="leave-details">
                            //     <Row gutter={20}>
                            //     <Col lg={12} className="leave-col">
                            //       <div className="leave-title">Leave Dates</div>
                            //       <div className="leave-input">
                            //       {currentSelectedMailMessage?.}
                            //       </div>
                            //     </Col>
                            //     </Row>
                            //       </div>
                            //     </div>
                            //   </>
                            // )
                            <>
                              {currentSelectedMailMessage?.applicationTypeSystemName !==
                                "Business" &&
                                currentSelectedMailMessage?.applicationTypeSystemName !==
                                  "Task" && (
                                  <div
                                    className="mail-iframe-wrapper"
                                    // style={
                                    //   currentSelectedMailMessage?.applicationTypeSystemName ===
                                    //   "Project"
                                    //     ? { zoom: "0%" }
                                    //     : { zoom: "200%" }
                                    // }
                                  >
                                    {currentSelectedMailMessage?.applicationTypeSystemName ===
                                      "Project" && (
                                      <span style={{ paddingLeft: "3px" }}>
                                        <b style={{ fontSize: "16px" }}>
                                          Notes:
                                        </b>{" "}
                                      </span>
                                    )}

                                    <iframe
                                      scroll="no"
                                      frameBorder="0"
                                      // id="editor_blocks_details"
                                      ref={gridIframe}
                                      className={`meetingInfos email-iframe highlight-email-iframe IFrameClass  ${
                                        currentSelectedMailMessage
                                          ?.applicationTypeDetails?.cCs &&
                                        "height"
                                      }`}
                                      srcDoc={initialMessage}
                                      onLoad={handleIframe}
                                      // target="_blank"
                                      id="printf"
                                      name="printf"
                                    />
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                      )}
                      {/* {console.log(
                        "currentSelectedMailMessage: ",
                        currentSelectedMailMessage
                      )} */}
                      {hideInternalMessage ? (
                        <Split
                          sizes={[65, 35]}
                          className="split__chat"
                          minSize={[50, 400]}
                          maxsize={300}
                          style={{ display: "flex", width: "100%" }}
                          expandToMin={false}
                          gutterSize={4}
                          gutterAlign="center"
                          snapOffset={30}
                          dragInterval={1}
                          cursor="col-resize"
                        >
                          <div
                            className={`meetingInfos  ${
                              currentSelectedMailMessage?.applicationTypeDetails
                                ?.cCs && "height"
                            }`}
                          >
                            {/* {currentSelectedMailMessage?.applicationTypeSystemName !==
                        "Leave" &&
                        currentSelectedMailMessage?.applicationTypeSystemName !==
                          "Loan" && (
                          <>
                            {!isEmpty(
                              currentSelectedMailMessage?.mailMessageAttachmentDtos
                            ) && (
                              <div className="email-attachment">
                                <span
                                  className="emailDetails__body--headerAttach"
                                  onClick={() =>
                                    setAttachmentModalVisible(true)
                                  }
                                >
                                  <img src={attachment} />{" "}
                                  {
                                    currentSelectedMailMessage?.mail
                                      ?.mailMessageAttachmentDtos
                                  }{" "}
                                  Attachments
                                </span>
                              </div>
                            )}
                          </>
                        )} */}
                            {/* <div className="email-description">
                      {currentSelectedMailMessage?.applicationTypeSystemName ===
                      "Leave" ? (
                        <LeaveDetails
                          leaveAppId={currentSelectedMailMessage?.applicationId}
                        />
                      ) : currentSelectedMailMessage?.applicationTypeSystemName ===
                        "Loan" ? (
                        <LoanDetails
                          loanAppId={currentSelectedMailMessage?.applicationId}
                        />
                      ) : (
                        <p>
                          <iframe
                            scroll="no"
                            frameBorder="0"
                            className={`meetingInfos email-iframe  ${
                              currentSelectedMailMessage?.applicationTypeDetails
                                ?.cCs && "height"
                            }`}
                            srcDoc={initialMessage}

                            // target="_blank"
                          />
                        </p>
                      )}
                    </div> */}
                          </div>

                          {selectedMailTypeAndMailData?.typeSystemName !==
                            "Trash" &&
                            // selectedMailTypeAndMailData?.typeSystemName !==
                            //   "Archived" &&
                            // selectedMailTypeAndMailData?.typeSystemName !==
                            //   "Pending" &&
                            selectedMailTypeAndMailData?.typeSystemName !==
                              "Draft" &&
                            selectedMailTypeAndMailData?.typeSystemName !==
                              "OutBox" &&
                            selectedMailTypeAndMailData?.typeSystemName !==
                              "Junk" && (
                              <div
                                className={`emailDetails__body--chat ${
                                  hideInternalMessage ? "hide" : ""
                                } ${
                                  !isEmpty(
                                    currentSelectedMailMessage
                                      ?.applicationTypeDetails?.cCs
                                  ) && "isCC"
                                }`}
                              >
                                <div className="chatmail">
                                  <div className="chatmail__header--wrapper">
                                    <div className="chatmail__header--leftcontent">
                                      <div className="chatmail__image">
                                        {isEmpty(
                                          instanceMessageCenterPagination?.items
                                        ) ? (
                                          <img src={internalmessage} />
                                        ) : (
                                          <img src={internalMessageGreen} />
                                        )}
                                      </div>
                                      <div className="chatmail__text">
                                        Message
                                      </div>
                                    </div>
                                    <div className="chatmail__header--rightcontent">
                                      <div className="chatmail__image">
                                        {/* <Image src={Extend} shape="square" preview={false} /> */}
                                      </div>
                                      {!chatBox ? (
                                        <div>
                                          <Button
                                            onClick={() => {
                                              setchatBox(true);
                                              setReplyofChat("");
                                              setReplyStatus("");
                                              setReplyChatHeight(true);
                                              setActiveChat("");
                                              setSmallChatHeight(false);
                                            }}
                                            className="btn btn-internalChat"
                                          >
                                            +
                                          </Button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <div
                                        className="chatmail__image"
                                        onClick={() => {
                                          setHideInternalMessage(
                                            !hideInternalMessage
                                          );
                                          setchatBox(false);
                                          setReplyofChat("");
                                          setReplyChatHeight(false);
                                          setActiveChat("");
                                          setSmallChatHeight(false);
                                        }}
                                      >
                                        <img src={hide} />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className={`emailDetails__body--chatwrap ${
                                    replyChatheight ? "showHeight" : ""
                                  } ${
                                    smallChatHeight ? "showSmallHeight" : ""
                                  }`}
                                >
                                  <EmailChat
                                    handleReply={handleReply}
                                    setActiveChat={setActiveChat}
                                    activeChat={activeChat}
                                    instanceMessageCenterPagination={
                                      instanceMessageCenterListing
                                    }
                                    mailMessageParticipantTypeListing={
                                      mailMessageParticipantTypeListing
                                    }
                                    setReplyofChat={setReplyofChat}
                                    setchatBox={setchatBox}
                                    chatBox={chatBox}
                                    instanceMessageCenterPaginationLoading={
                                      instanceMessageCenterPaginationLoading
                                    }
                                    replyInstanceMessageResp={
                                      replyInstanceMessageResp
                                    }
                                    corporateSharedAccessAccountHolderUserId={
                                      corporateSharedAccessAccountHolderUserId
                                    }
                                    messageCenterTypeName={
                                      messageCenterTypeName
                                    }
                                    currentSelectedMailMessage={
                                      currentSelectedMailMessage
                                    }
                                  />
                                </div>

                                <ReplyChatBox
                                  replyOfChat={replyOfChat}
                                  setReplyStatus={setReplyStatus}
                                  mailMessageParticipantTypeListing={
                                    mailMessageParticipantTypeListing
                                  }
                                  mailMessageParticipationStatusListing={
                                    mailMessageParticipationStatusListing
                                  }
                                  allParticipants={allParticipants}
                                  replyMsgDetails={replyMsgDetails}
                                  currentSelectedMailMessage={
                                    currentSelectedMailMessage
                                  }
                                  setchatBox={setchatBox}
                                  replyStatus={replyStatus}
                                  resetReplyChat={resetReplyChat}
                                  chatBox={chatBox}
                                  instanceMessageCenterPagination={
                                    instanceMessageCenterListing
                                  }
                                  setReplyofChat={setReplyofChat}
                                  replyChatheight={replyChatheight}
                                  setReplyChatHeight={setReplyChatHeight}
                                  setActiveChat={setActiveChat}
                                  smallChatHeight={smallChatHeight}
                                  setSmallChatHeight={setSmallChatHeight}
                                  predefinedData={predefinedData}
                                  setPredefinedData={setPredefinedData}
                                />
                              </div>
                            )}
                        </Split>
                      ) : (
                        <>
                          {isEmpty(currentSelectedMailMessage) ? (
                            !mailBodyGetDetailsLoading ? (
                              <div className="no-data-wrapper"></div>
                            ) : (
                              <div>
                                <Skeleton />
                              </div>
                            )
                          ) : (
                            <div
                              className="email-internal-hide-show"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                className={`meetingInfos  ${
                                  currentSelectedMailMessage
                                    ?.applicationTypeDetails?.cCs && "height"
                                }`}
                              ></div>

                              {selectedMailTypeAndMailData?.typeSystemName !==
                                "Trash" &&
                                // selectedMailTypeAndMailData?.typeSystemName !==
                                //   "Pending" &&
                                selectedMailTypeAndMailData?.typeSystemName !==
                                  "Draft" &&
                                selectedMailTypeAndMailData?.typeSystemName !==
                                  "OutBox" &&
                                selectedMailTypeAndMailData?.typeSystemName !==
                                  "Junk" && (
                                  <div
                                    className={`chatmail__header--wrapper-hide chatmail__image ${
                                      !isEmpty(
                                        currentSelectedMailMessage
                                          ?.applicationTypeDetails?.cCs
                                      ) && " isCC"
                                    }`}
                                    style={{ width: "38px" }}
                                  >
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        width: "30px",
                                      }}
                                      onClick={() =>
                                        setHideInternalMessage(true)
                                      }
                                    >
                                      {isEmpty(
                                        instanceMessageCenterPagination?.items
                                      ) ? (
                                        <Image
                                          src={internalmessage}
                                          shape="square"
                                          preview={false}
                                        />
                                      ) : (
                                        <Image
                                          src={internalMessageGreen}
                                          shape="square"
                                          preview={false}
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        width: "30px",
                                      }}
                                      onClick={() =>
                                        setHideInternalMessage(true)
                                      }
                                    >
                                      <Button />
                                    </div>

                                    <div
                                      style={{
                                        cursor: "pointer",
                                        marginTop: 5,
                                        width: "30px",
                                      }}
                                      onClick={() => {
                                        setHideInternalMessage(true);
                                      }}
                                    ></div>
                                  </div>
                                )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Typography.Title
                style={{
                  textAlign: "center",
                  color: "lightgray",
                  marginTop: "1.5em",
                }}
                level={5}
              >
                There are no any mails at the moment.
              </Typography.Title>
            )}
          </div>
          <Modal
            visible={attachmentModalVisible}
            title={"Attachments"}
            footer={null}
            onCancel={() => setAttachmentModalVisible(false)}
          >
            {!isEmpty(currentSelectedMailMessage?.mailMessageAttachmentDtos) &&
              currentSelectedMailMessage?.mailMessageAttachmentDtos.map(
                (fileList, i) => {
                  const fileType = fileList.displayFileName.split(".").pop();
                  return (
                    <div key={i}>
                      <img src={attachment} style={{ paddingRight: 8 }} />
                      {fileType === "pdf" ||
                      fileType === "jpg" ||
                      fileType === "jpeg" ? (
                        <a
                          onClick={() => {
                            if (fileType === "pdf") {
                              window.open(
                                `${cqBaseUrl}/${fileList?.viewFileURL}${token}`,
                                "_blank",
                                "top=50,left=200,frame=true"
                              );
                            } else {
                              dispatch(
                                setSelectedFileName(
                                  `${cqBaseUrl}/${fileList?.viewFileURL}${token}`
                                )
                              );
                              // let dirname = electron.commonApi.getDirName();
                              // window.open(`file://${dirname}/index.html#/viewFile`, '_blank', 'top=50,left=200,frame=true');
                            }
                          }}
                        >
                          {fileList.displayFileName}
                        </a>
                      ) : (
                        <a
                          href={`${cqBaseUrl}/${fileList?.viewFileURL}${token}`}
                          download
                        >
                          {fileList.displayFileName}
                        </a>
                      )}
                    </div>
                  );
                }
              )}
          </Modal>
        </div>
        <Modal
          visible={visibleModal}
          keyboard={false}
          footer={null}
          onCancel={() => {
            setPersonId();
            setCompanyId();
            form.setFieldsValue({
              companyPersonId: "",
              companyContactId: "",
              remarks: "",
              scopeOfWork: [],
            });
            setVisibleModal(false);
          }}
          maskClosable={false}
          wrapClassName="quick_add_email_modal"
          afterClose={() => {
            setAllPersonListingState([]);
            setAllCompanyListingState([]);
            setNewCompanyValue([]);
            setNewValuePersonCompany([]);
            setNotFoundValue([]);
          }}
        >
          <AddNewQuickEmail
            message={messageFromEmailDetails}
            setVisibleModal={setVisibleModal}
            type={participantType}
            form={form}
            emailText={checkEmail}
            currentSelectedMailMessage={currentSelectedMailMessage}
            notFoundCompany={notFoundCompany}
            setNotFoundCompany={setNotFoundCompany}
            companyCurrentPage={companyCurrentPage}
            setCompanyCurrentPage={setCompanyCurrentPage}
            personId={personId}
            setPersonId={setPersonId}
            companyId={companyId}
            setCompanyId={setCompanyId}
            setDisableSaveButton={setDisableSaveButton}
            disableSavedButton={disableSavedButton}
            notFoundValue={notFoundValue}
            setNotFoundValue={setNotFoundValue}
            allPersonListingState={allPersonListingState}
            setAllPersonListingState={setAllPersonListingState}
            allCompanyListingState={allCompanyListingState}
            setAllCompanyListingState={setAllCompanyListingState}
            newValuePersonCompany={newValuePersonCompany}
            setNewValuePersonCompany={setNewValuePersonCompany}
            newCompanyValue={newCompanyValue}
            setNewCompanyValue={setNewCompanyValue}
            setNewQuickCompanyValue={setNewQuickCompanyValue}
            newQuickCompanyValue={newQuickCompanyValue}
          />
        </Modal>
        {openRepliedModal && (
          <Modal
            visible={openRepliedModal}
            onCancel={() => {
              setOpenRepliedModal(false);
            }}
            footer={null}
            // height={60}
            width={800}
            wrapClassName="reply_modal_mail_details"
            title="Email Reply Detail"
          >
            <div className="emailDetails__body email_details_reply_popup">
              <div className="email-title--show">
                <div
                  className="subject-wrapper"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div> Subject:</div>
                  <div>{getDetailsReplyResponse?.subject}</div>
                </div>

                <div
                  style={{ display: "flex" }}
                  className="reference_listing_email_reply_details"
                >
                  <div className="subject-wrapper" style={{ fontSize: "13px" }}>
                    Reference:
                  </div>
                  {getDetailsReplyResponse?.mailMessageReferenceDtos?.map(
                    (data) => {
                      return (
                        <div
                          className="emailList__card--ref email"
                          style={{ marginTop: "4px" }}
                        >
                          <span className="ant-tag reference-tags">
                            <div className="emailList__card--ref">
                              {data?.referenceCode}
                            </div>
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div
                className="emailDetails__body--infosMain"
                style={{ height: "30px" }}
              >
                <div className="email-from-date">
                  <div className="infosMain">
                    <span className="title">From:</span>
                    <div style={{ display: "flex", cursor: "pointer" }}>
                      <Tooltip
                        title={
                          getDetailsReplyResponse?.applicationTypeDetails
                            ?.from?.[0]?.emailAddress
                        }
                      >
                        {getDetailsReplyResponse?.applicationTypeDetails
                          ?.from?.[0]?.fullName
                          ? getDetailsReplyResponse?.applicationTypeDetails
                              ?.from?.[0]?.fullName
                          : getDetailsReplyResponse?.applicationTypeDetails
                              ?.from?.[0]?.companyAbbrevation}
                      </Tooltip>
                    </div>
                  </div>
                  <div className="infosMain">
                    <span className="details">
                      Date:
                      {moment(getDetailsReplyResponse?.creationTime).format(
                        "LLLL"
                      )}
                    </span>
                  </div>
                </div>
                <div className="email-to-cc">
                  <div className="infosMain">
                    <span className="title">To:</span>
                    <div style={{ display: "flex", cursor: "pointer" }}>
                      {getParticipantsEmailsExternal(
                        getDetailsReplyResponse?.applicationTypeDetails?.tos,
                        test,
                        "To",
                        modalVisibleTo,
                        setModalVisibleTo
                      )}
                    </div>
                  </div>
                </div>
                {/* {!isEmpty( getDetailsReplyResponse?.applicationTypeDetails?.cCs) &&
                <div className="email-to-cc">
                <div className="infosMain">
                    <span className="title">CC:</span>
                    <div style={{display:"flex",cursor:"pointer"}}>
                      {getParticipantsEmailsExternal(
                        getDetailsReplyResponse?.applicationTypeDetails
                          ?.cCs,
                        test,
                        "To",
                        modalVisibleTo,
                        setModalVisibleTo
                      )}
                    </div>
                </div>
              </div>
              } */}
                {/* {!isEmpty( getDetailsReplyResponse?.applicationTypeDetails?.bcCs) &&
                <div className="email-to-cc">
                <div className="infosMain">
                    <span className="title">BCC:</span>
                    <div style={{display:"flex",cursor:"pointer"}}>
                    {getParticipantsEmailsExternal(
                        getDetailsReplyResponse?.applicationTypeDetails
                          ?.bcCs,
                        test,
                        "To",
                        modalVisibleTo,
                        setModalVisibleTo
                      )}
                    </div>
                </div>
              </div>
              } */}
              </div>
              {/* <div className="reference_listing_email_reply_details">
              <div>Reference:<span className="reference_individual_listing">{getDetailsReplyResponse?.mailMessageReferenceDtos?.map((data)=>(data?.referenceCode))}</span></div>
            </div> */}

              <p className="mail-iframe-wrapper">
                <iframe
                  scroll="no"
                  frameBorder="0"
                  // id="editor_blocks_details"
                  // ref={gridIframe}
                  className={`meetingInfos email-iframe highlight-email-iframe  ${
                    currentSelectedMailMessage?.applicationTypeDetails?.cCs &&
                    "height"
                  }`}
                  srcDoc={replyMessage}
                  onLoad={handleIframe}
                  // target="_blank"
                  id="printf"
                  name="printf"
                  height="300"
                />
              </p>
            </div>
          </Modal> 
        )}
        <BusinessModal
          instanceMessageCenterPagination={currentSelectedMailMessage}
          showBusinessModal={showBusinessModal}
          setShowBusinessModal={setShowBusinessModal}
          referenceTags={mailReferenceGetDetailsResponse}
          currentSelectedMailMessage={currentSelectedMailMessage}
          composeIfReferenceValue={composeIfReference}
          setComposeIfReference={setComposeIfReference}
        />

        <ModalCorrectionMessage
          title="Tag Correcton!"
          visibility={showModalForCorrection?.visibility}
          handleOk={hanldeOkForModalCorrection}
          setShowModalForCorretionTag={setShowModalForCorretionTag}
          content={
            <p>
              Are you Sure to Send Correction Message for this tag{" "}
              <span style={{ color: "blue" }}>
                "{showModalForCorrection?.tagName}"
              </span>
              ?
            </p>
          }
        />
      </div>
    </>
  );
};

export default EmailDetails;
