import { DYNAMIC_FILE_TYPES, ORGANIZER_TYPES } from "../../../types";

const INITIAL_STATE = {
    dynamicFileLabelTypesAll:[],
    dynamicFileLabelTypes: [],
    dynamicFileLabelLoading: false,

    // addGoalEventResp: [],
    // addGoalEventRespLoading: false,

    // guestEventStatuses: [],
    // guestEventStatusesLoading: false,
};

export default function dynamicFileLabelTypeReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        // Goal events
        case DYNAMIC_FILE_TYPES.DYNAMIC_FILE_LABEL_TYPES_INIT:
            return {
                ...state,
                dynamicFileLabelTypes: [],
                dynamicFileLabelLoading: true,
            };
        case DYNAMIC_FILE_TYPES.DYNAMIC_FILE_LABEL_TYPES_SUCCESS:
            return {
                ...state,
                dynamicFileLabelTypes: action.payload,
                dynamicFileLabelLoading: false,
            };
        case DYNAMIC_FILE_TYPES.DYNAMIC_FILE_LABEL_TYPES_ALL_SUCCESS:
            return{
                ...state,
                dynamicFileLabelLoading: false,
                dynamicFileLabelTypesAll:action.payload,
            }
        case DYNAMIC_FILE_TYPES.DYNAMIC_FILE_LABEL_TYPES_FINISH:
            return {
                ...state,
                dynamicFileLabelLoading: false,
            };

        // Goal events add
        // case DYNAMIC_FILE_TYPES.ADD_GOAL_EVENTS_INIT:
        //     return {
        //         ...state,
        //         addGoalEventResp: [],
        //         addGoalEventRespLoading: true,
        //     };
        // case DYNAMIC_FILE_TYPES.ADD_GOAL_EVENTS_SUCCESS:
        //     return {
        //         ...state,
        //         addGoalEventResp: action.payload,
        //         addGoalEventRespLoading: false,
        //     };
        // case DYNAMIC_FILE_TYPES.ADD_GOAL_EVENTS_FINISH:
        //     return {
        //         ...state,
        //         addGoalEventRespLoading: false,
        //     };
        default:
            return { ...state };
    }
}
