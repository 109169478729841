import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionForAddUpdateCEADTender } from "services/redux/cead/action";
import {
  getContactCompanyPagination,
  getContactPersonPagination,
} from "services/redux/contact/companypeople/action";
import CreateEmployee from "./createEmployee";
import CeadDetails from "./details";
import { EditOutlined } from "@ant-design/icons";

const { Title } = Typography;

export default function CreateCead({
  formCreateCead,
  throughCompOptions,
  inchargeUsersOptions,
  referenceOptions,
  singleTenderCeadData,
  contactPersonOptions,
  companyOptions,
}) {
  const dispatch = useDispatch();

  const { contactPersonPaginationLoading } = useSelector(
    (state) => state.contactCompanyPerson
  );

  const {
    singleTenderCeadLoading,
    tenderCeadTransferLoading,
    isTransferToCead,
  } = useSelector((state) => state.ceadReducer);

  const [selectedClientCompany, setSelectedClientCompany] = useState();
  const [selectedClientCompanyid, setSelectedClientCompanyId] = useState();
  const [selectedPrincipleCompanyid, setSelectedPrincipleCompanyId] =
    useState();
  const [selectedPrincipalCompany, setSelectedPrincipalCompany] = useState();
  const [openCreateEmployee, setOpenCreateEmployee] = useState({
    type: "",
    open: false,
  });
  const [showFunding, setFundingShow] = useState(false);

  useEffect(() => {
    if (!isEmpty(selectedClientCompany || selectedPrincipalCompany)) {
      let apiBody = {
        MaxResultCount: 50,
        SkipCount: 0,
        searchKeyword: selectedClientCompany
          ? selectedClientCompany
          : selectedPrincipalCompany,
      };
      dispatch(getContactPersonPagination(apiBody));
    }
  }, [selectedClientCompany, selectedPrincipalCompany]);

  useEffect(() => {
    if (!tenderCeadTransferLoading) {
      setFundingShow(!showFunding);
    }
  }, [tenderCeadTransferLoading]);

  useEffect(() => {
    if (!isEmpty(singleTenderCeadData)) {
      setSelectedClientCompanyId(singleTenderCeadData?.clientId);
      setSelectedClientCompany(singleTenderCeadData?.clientName);
      setSelectedPrincipleCompanyId(singleTenderCeadData?.principalId);
      setSelectedPrincipalCompany(singleTenderCeadData?.principalName);
      formCreateCead?.setFieldsValue({
        tendeR_BASIC_Inchars: singleTenderCeadData?.tendeR_BASIC_Inchars?.map(
          (x) => {
            return x?.participantId;
          }
        ),
        ceaD_Inchars: singleTenderCeadData?.ceaD_Inchars?.map((x) => {
          return x?.participantId;
        }),
        contractSignedDate: moment(singleTenderCeadData?.contractSignedDate),
        contractEffectiveDate: moment(
          singleTenderCeadData?.contractEffectiveDate
        ),
        contractEndDate: moment(singleTenderCeadData?.contractEndDate),
        clientId: singleTenderCeadData?.clientName,
        contactId: singleTenderCeadData?.ceaD_CLIENT_ContactInformations?.map(
          (x) => {
            return x?.contactPersonName;
          }
        ),
        principalId: singleTenderCeadData?.principalName,
        principalContactId:
          singleTenderCeadData?.ceaD_PRINCIPAL_ContactInformations?.map((x) => {
            return x?.contactPersonName;
          }),
      });
    }
  }, [dispatch, formCreateCead, singleTenderCeadData]);

  const onCeadCreateUpdateFinish = (values) => {
    let clientId_data =
      values?.clientId === singleTenderCeadData?.clientName
        ? singleTenderCeadData?.clientId
        : values?.clientId;

    let principalId_data =
      values?.principalId === singleTenderCeadData?.principalName
        ? singleTenderCeadData?.principalId
        : values?.principalId;

    let ceaD_CLIENT_ContactInformations_data = [];
    let ceaD_PRINCIPAL_ContactInformations_data = [];

    //ceaD_CLIENT_ContactInformations_data
    singleTenderCeadData?.ceaD_CLIENT_ContactInformations?.forEach(
      (element) => {
        values?.contactId?.map((y) => {
          if (element?.contactPersonName === y) {
            ceaD_CLIENT_ContactInformations_data.push(element?.contactId);
          } else {
            ceaD_CLIENT_ContactInformations_data.push(y);
          }
        });
      }
    );

    //ceaD_PRINCIPAL_ContactInformations_data
    singleTenderCeadData?.ceaD_PRINCIPAL_ContactInformations?.forEach(
      (element) => {
        values?.principalContactId?.map((y) => {
          if (element?.contactPersonName === y) {
            ceaD_PRINCIPAL_ContactInformations_data.push(element?.contactId);
          } else {
            ceaD_PRINCIPAL_ContactInformations_data.push(y);
          }
        });
      }
    );

    let apiBody = {
      ...values,
      isActive: true,
      id: singleTenderCeadData?.id,
      contract: singleTenderCeadData?.contract,
      referenceId: singleTenderCeadData?.referenceId,
      throughCompanyId: singleTenderCeadData?.throughCompanyId,

      ceaD_Inchars: values?.ceaD_Inchars?.map((x) => {
        return {
          isActive: true,
          participantId: x,
        };
      }),
      tendeR_BASIC_Inchars: values?.tendeR_BASIC_Inchars?.map((x) => {
        return {
          isActive: true,
          participantId: x,
        };
      }),
      clientId: clientId_data,
      ceaD_CLIENT_ContactInformations:
        ceaD_CLIENT_ContactInformations_data?.map((x) => {
          return {
            isActive: true,
            contactId: x,
          };
        }),

      principalId: principalId_data,
      ceaD_PRINCIPAL_ContactInformations:
        ceaD_PRINCIPAL_ContactInformations_data?.map((x) => {
          return {
            isActive: true,
            contactId: x,
          };
        }),
      handedOverToId: JSON.parse(sessionStorage.getItem("userId")),
    };

    dispatch(actionForAddUpdateCEADTender(apiBody));
  };

  return (
    <>
      <Title level={5} style={{ background: "#DFDFDF" }}>
        CEAD Details
        {showFunding && (
          <span
            onClick={() => {
              setFundingShow(!showFunding);
            }}
            className="bid-pointer-cursor"
          >
            <EditOutlined />
          </span>
        )}
      </Title>

      {singleTenderCeadLoading ? (
        <Skeleton active />
      ) : (
        <>
          {!showFunding ? (
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              form={formCreateCead}
              onFinish={onCeadCreateUpdateFinish}
            >
              <Row gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    label="Biz-Pro-In-Charge"
                    name="tendeR_BASIC_Inchars"
                  >
                    <Select
                      mode="multiple"
                      options={inchargeUsersOptions}
                      placeholder="Select Incharge"
                      allowClear
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item label="CEAD-In-Charge" name="ceaD_Inchars">
                    <Select
                      mode="multiple"
                      options={inchargeUsersOptions}
                      placeholder="Select Incharge"
                      allowClear
                    />
                  </Form.Item>
                </Col>

                <Col span={8} className="cead-date-picker">
                  <Form.Item
                    label="Contract Signed Date"
                    name="contractSignedDate"
                  >
                    <DatePicker placeholder="Select date" />
                  </Form.Item>
                </Col>

                <Col span={8} className="cead-date-picker">
                  <Form.Item
                    label="Contract Effective Date"
                    name="contractEffectiveDate"
                  >
                    <DatePicker placeholder="Select date" />
                  </Form.Item>
                </Col>

                <Col span={8} className="cead-date-picker">
                  <Form.Item label="Contract End Date" name="contractEndDate">
                    <DatePicker placeholder="Select date" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />
              {/* clientInfoForm */}
              <Typography.Title level={5}>Client Info</Typography.Title>

              <Row gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item label="Client Name" name="clientId">
                    <Select
                      options={companyOptions}
                      optionFilterProp="label"
                      placeholder="Select Incharge"
                      allowClear
                      showSearch
                      onSearch={(value) => {
                        let apiBody = {
                          MaxResultCount: 40,
                          SkipCount: 0,
                          searchKeyword: value,
                        };
                        dispatch(getContactCompanyPagination(apiBody));
                      }}
                      onChange={(value, label) => {
                        setSelectedClientCompany(label?.label);
                        setSelectedClientCompanyId(label?.value);
                        formCreateCead.setFieldsValue({
                          contactId: [],
                        });
                      }}
                      onFocus={() => {
                        let apiBody = {
                          MaxResultCount: 40,
                          SkipCount: 0,
                          searchKeyword: selectedClientCompany,
                        };
                        dispatch(getContactCompanyPagination(apiBody));
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Contact Person" name="contactId">
                    <Select
                      mode="multiple"
                      options={contactPersonOptions}
                      placeholder="Select Incharge"
                      allowClear
                      allowSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      notFoundContent={
                        <>
                          <>
                            {contactPersonPaginationLoading ? (
                              <Skeleton />
                            ) : (
                              <div>
                                Content Not Found. Do you want to create?
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setOpenCreateEmployee({
                                      type: "client",
                                      open: true,
                                    });
                                  }}
                                >
                                  Yes
                                </Button>
                              </div>
                            )}
                          </>
                        </>
                      }
                      onFocus={() => {
                        // let apiBody = {
                        //   MaxResultCount: 50,
                        //   SkipCount: 0,
                        //   searchKeyword: selectedClientCompany,
                        // };
                        let apiBody = {
                          companyId: selectedClientCompanyid,
                        };
                        dispatch(getContactPersonPagination(apiBody));
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <Typography.Title level={5}>Principal Info</Typography.Title>

              <Row gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item label="Principal Name" name="principalId">
                    <Select
                      options={companyOptions}
                      optionFilterProp="label"
                      placeholder="Select Incharge"
                      allowClear
                      showSearch
                      onSearch={(value) => {
                        let apiBody = {
                          MaxResultCount: 40,
                          SkipCount: 0,
                          searchKeyword: value,
                        };
                        dispatch(getContactCompanyPagination(apiBody));
                      }}
                      onChange={(value, label) => {
                        setSelectedPrincipalCompany(label?.label);
                        setSelectedPrincipleCompanyId(label?.value);
                        formCreateCead.setFieldsValue({
                          principalContactId: [],
                        });
                      }}
                      onFocus={() => {
                        // let apiBody = {
                        //   MaxResultCount: 40,
                        //   SkipCount: 0,
                        //   searchKeyword: selectedPrincipalCompany,
                        // };
                        let apiBody = {
                          companyId: selectedPrincipleCompanyid,
                        };
                        dispatch(getContactCompanyPagination(apiBody));
                      }}
                      // loading={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Contact Person" name="principalContactId">
                    <Select
                      mode="multiple"
                      options={contactPersonOptions}
                      placeholder="Select Incharge"
                      allowClear
                      allowSearch
                      optionFilterProp="label"
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      notFoundContent={
                        <>
                          <>
                            {contactPersonPaginationLoading ? (
                              <Skeleton />
                            ) : (
                              <div>
                                Content Not Found. Do you want to create?
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setOpenCreateEmployee({
                                      type: "principal",
                                      open: true,
                                    });
                                  }}
                                >
                                  Yes
                                </Button>
                              </div>
                            )}
                          </>
                        </>
                      }
                      onFocus={() => {
                        // let apiBody = {
                        //   MaxResultCount: 50,
                        //   SkipCount: 0,
                        //   searchKeyword: selectedPrincipalCompany,
                        // };
                        let apiBody = {
                          companyId: selectedPrincipleCompanyid,
                        };
                        dispatch(getContactPersonPagination(apiBody));
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ float: "right" }}>
                <Col>
                  <Button
                    type=""
                    onClick={() => {
                      setFundingShow(!showFunding);
                      // setFundingShow(false);
                    }}
                    style={{ marginRight: "6px" }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: "6px" }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <CeadDetails
              ceadDetails={{
                "Biz-Pro-In-Charge": singleTenderCeadData?.tendeR_BASIC_Inchars
                  ?.map((x) => {
                    return x?.fullName;
                  })
                  .join(","),
                "CEAD-In-Charge": singleTenderCeadData?.ceaD_Inchars
                  ?.map((x) => {
                    return x?.fullName;
                  })
                  .join(","),
                "Contract Signed Date": moment(
                  singleTenderCeadData?.contractSignedDate
                ).format("YYYY-MM-DD"),
                "Contract Effective Date": moment(
                  singleTenderCeadData?.contractEffectiveDate
                ).format("YYYY-MM-DD"),
                "Contract End Date": moment(
                  singleTenderCeadData?.contractEndDate
                ).format("YYYY-MM-DD"),
                "Client Info": singleTenderCeadData?.clientName,
                "Client Contact Person":
                  singleTenderCeadData?.ceaD_CLIENT_ContactInformations
                    ?.map((x) => {
                      return x?.contactPersonName;
                    })
                    .join(","),
                "Principal Info": singleTenderCeadData?.principalName,
                "Principal Contact Person":
                  singleTenderCeadData?.ceaD_PRINCIPAL_ContactInformations
                    ?.map((x) => {
                      return x?.contactPersonName;
                    })
                    .join(","),
              }}
            />
          )}
        </>
      )}

      <Modal visible={openCreateEmployee.open} closable={false} footer={null}>
        <CreateEmployee
          setOpenCreateEmployee={setOpenCreateEmployee}
          selectedClientCompanyid={selectedClientCompanyid}
          selectedPrincipleCompanyid={selectedPrincipleCompanyid}
          type={openCreateEmployee.type}
        />
      </Modal>
    </>
  );
}
