import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Menu, Skeleton } from "antd";

import ContactCompanyPerson from "./ContactCompanyPerson";
import { useDispatch } from "react-redux";

const defaultRoute = "/contact/company-person";
const minimize = "assets/images/icons/minimize.png";
const maximize = "assets/images/icons/maximize.png";

function Contact() {
  const history = useHistory();
  const dispatch = useDispatch();

  // const showAddUpdateCountryDrawer = (e, record) => {
  // 	setAddUpdatePersonDrawerVisibility(false);
  // 	if (record) {
  // 		dispatch(contactCompanyGetDetails(record.contactCompanyId));
  // 		// console.log({contactCompanyPerson})
  // 		setIsUpdateCountry(record);
  // 	} else {
  // 		setIsUpdateCountry(false);
  // 	}
  // 	setAddUpdateCountryDrawerVisibility(true);
  // };

  //for new company and new person

  const [
    addUpdateCountryDrawerVisibility,
    setAddUpdateCountryDrawerVisibility,
  ] = useState(false);
  const [addUpdatePersonDrawerVisibility, setAddUpdatePersonDrawerVisibility] =
    useState(false);

  //for function of for drawer , show, hide or update message

  const [state, setState] = useState({
    current: null,
    collapsed: false,
  });

  const handleClick = (e) => {
    history.push(e.key);
  };

  const toggleCollapse = () => {
    setState({
      ...state,
      collapsed: !state.collapsed,
    });
  };

  useEffect(() => {
    if (history.location.pathname === "/contact") {
      history.push(defaultRoute);
    }
    setState({ ...state, current: history.location.pathname });
  }, [history.location.pathname]);

  const renderComponent = (pathname) => {
    switch (pathname) {
      case defaultRoute:
        return <ContactCompanyPerson />;
      default:
        return <ContactCompanyPerson />;
    }
  };

  if (!state.current) {
    return <Skeleton />;
  }
  return (
    <div className="sidebars">
      <div
        className={`sidebars__menu ${state.collapsed && "text-align-right"}`}
        style={{ display: "none" }}
      >
        <div className="toggle__arrow" onClick={toggleCollapse}>
          {state.collapsed ? <img src={maximize} /> : <img src={minimize} />}
        </div>
        <Menu
          className="sidebars__menu--items"
          defaultSelectedKeys={[state.current]}
          mode="inline"
          inlineCollapsed={state.collapsed}
        >
          <Menu.Item
            key={defaultRoute}
            className="sidebars__menu--item"
            onClick={handleClick}
          >
            Contact Company People
          </Menu.Item>
          {/* <Menu.Item className='sidebars__menu--item' onClick={handleClick}></Menu.Item>
					<Menu.Item className='sidebars__menu--item' onClick={handleClick}></Menu.Item> */}
        </Menu>
      </div>
      <div className="main__body">
        {" "}
        {renderComponent(history.location.pathname)}
      </div>
    </div>
  );
}

// export default withBaseLayout(Contact);
export default Contact;
