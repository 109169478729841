import React from 'react';
import { Tooltip, Button } from 'antd';
const IconButtonWithTooltip = ({ tooltipText, buttonType = 'dashed', shape = 'circle', onClick, children }) => {
    return (
        <Tooltip title={tooltipText}>
            <Button
                type={buttonType}
                shape={shape}
                onClick={onClick}
            >
                {children}
            </Button>
        </Tooltip>
    );
};

export default IconButtonWithTooltip;
