import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Row, Col } from "antd";

import { requiredRule, validateMessages } from "utils/misc";
import { changePassword } from "services/redux/auth/auth.action";
import { changeUsersPassword } from "services/redux/auth/auth.action";

const ChangePassword = ({ updateUsers }) => {
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(changePassword(values));
  };

  const UpdateUsersPassword = (values) => {
    let val = {
      ...values,
      temporaryPassword: values.temporaryPassword,
      appUserId: updateUsers,
    };
    // console.log(val);
    dispatch(changeUsersPassword(val));
  };

  const [UpdateUsers] = Form.useForm();
  const [basic] = Form.useForm();
  UpdateUsers.resetFields();
  basic.resetFields();

  return (
    <div>
      {updateUsers ? (
        <Form
          name="UpdateUsers"
          form={UpdateUsers}
          onFinish={UpdateUsersPassword}
          validateMessages={validateMessages}
        >
          {/* <Form.Item label="Current Password" name="currentPassword" className="form__group" rules={requiredRule}>
						<Input.Password />
					</Form.Item> */}
          <Form.Item
            name="setpassword"
            className="form__group"
            label="New Password"
            rules={[
              {
                required: true,
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()-_])[A-Za-z\d@$!%*?&#^()-_]{8,}$/,
                message:
                  "Minimum 8 characters, at least 1 uppercase letter, 1 number and 1 special character",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="temporaryPassword"
            className="form__group"
            label="Confirm Password"
            dependencies={["setpassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("setpassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
            // hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item className="change__password__form__submitbutton">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <div>
          <Form
            name="basic"
            form={basic}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Form.Item
              label="Current Password"
              name="currentPassword"
              className="form__group"
              rules={requiredRule}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="setpassword"
              className="form__group"
              label="New Password"
              rules={[
                {
                  required: true,
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()-_])[A-Za-z\d@$!%*?&#^()-_]{8,}$/,
                  message:
                    "Minimum 8 characters, at least 1 uppercase letter, 1 number and 1 special character",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="newPassword"
              className="form__group"
              label="Confirm Password"
              dependencies={["setpassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("setpassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
              //   hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className="change__password__form__submitbutton">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
