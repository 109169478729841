import { BroadcastChannel } from "broadcast-channel";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCompanyDrawer from "./AddUpdateCompanyDrawer";
import AddPersonDrawer from "./AddUpdatePersonDrawer";
import { Showdataform } from "./Showdataformcompany";
import { Showdataformperson } from "./Showdataformperson";

import { FilterOutlined, PlusOutlined, StarFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Slider,
  message,
} from "antd";

import { isEmpty } from "lodash";
import { qualificationListing, sectorListing } from "services/remanentCalls";
import AddUpdatePersonTable from "./AddUpdatePersonTable";
import EditNotes from "./EditNotes";
import PersonEditNotes from "./PersonEditNotes";

import {
  contactCompanyGetDetails,
  contactPersonGetDetails,
  contactUpdate,
  getContactCompanyPagination,
  getContactPersonPagination,
  getExpertPagnination,
} from "services/redux/contact/companypeople/action";
import { selectedMailTypeAndMail } from "services/redux/contact/persistcontact/action";

const { Option } = Select;

const addperson = "assets/svg/addperson.svg";
const addcompany = "assets/svg/addcompany.svg";

const ExpertCompanyPerson = () => {
  const [qualificationData, setQualificationData] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [form] = Form.useForm();
  const [companyForm] = Form.useForm();
  const [companyNotesForm] = Form.useForm();

  const [personForm] = Form.useForm();
  const [expertForm] = Form.useForm();
  const [personNotesForm] = Form.useForm();

  const { Option } = Select;
  const { Search } = Input;
  const dispatch = useDispatch();
  const [favList, setFavList] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFavorite, setIsFavorite] = useState(true);
  const [test, setTest] = useState();
  const [fomValueReset, setFormValueReset] = useState();
  const [advanceButtonDisabled, setAdvanceButtonDisabled] = useState(true);
  const [notes, setNotes] = useState(false);
  const [onClickCompany, setOnClickCompany] = useState(false);
  const [onClickPerson, setOnClickPerson] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [hiddenButton, setHiddenButton] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const bc = new BroadcastChannel("test_channel");
  const bcPerson = new BroadcastChannel("testperson");
  const switchIcon = "assets/svg/toogle-icon.svg";

  const {
    contactCompanyUpdateResponse,
    contactCompanyDetailAddResponse,
    contactPersonAddResponse,
    contactCompanyDelete,
    contactPersonUpdateResponse,
    contactCompanyGetDetailsResponse,
    contactPersonGetDetailsResponse,
    contactFavSelectReponse,
    contactPersonPaginationResponse,
    contactPersonNoteRespone,
    contactCompanyNoteRespone,
    contactPersonDelete,
    contactCompanyGetDetailsLoading,
    contactPersonGetDetailsLoading,
    contactPersonPaginationLoading,
    expertPersonPaginationResponse,
    expertPersonPaginationLoading,
  } = useSelector((state) => state.contactCompanyPerson);

  const { selectedMailTypeAndMailData } = useSelector(
    (state) => state.contactSelectedState
  );

  const { applicationConfiguration } = useSelector(
    (state) => state.applicationConfig
  );
  // const [showNotes, setShowNotes] = useState('');
  const [data, setdata] = useState([]);

  const [isUpdateCountry, setIsUpdateCountry] = useState(false);
  const [isUpdatePerson, setIsUpdatePerson] = useState(false);
  const [id, setId] = useState();
  const [contactCompanyId, setContactCompanyId] = useState("");
  const [contactPersonId, setContactPersonId] = useState("");
  const [showAll, setshowAll] = useState(false);
  const [show, sethidden] = useState(false);

  const [
    addUpdateCountryDrawerVisibility,
    setAddUpdateCountryDrawerVisibility,
  ] = useState(false);
  const [addUpdatePersonDrawerVisibility, setAddUpdatePersonDrawerVisibility] =
    useState(false);
  const [SearchKeyword, setSearchKeyword] = useState();
  const [searchPersonCompany, setSearchPersonCompany] = useState("");
  const [grantedPolices, setGrantedPolices] = useState({});
  // const [hideInternalMessage, setHideInternalMessage] = useState(true);
  const [starColor, setStarColor] = useState("#c8c8c8");
  const [inputSearchValue, setInputSearcHValue] = useState();
  const [inputSearchList, setInputSearchList] = useState();
  const [responseNotes, setResponseNotes] = useState();
  const [personResponseNotes, setPersonResponseNotes] = useState();
  const [companyData, setCompanyData] = useState([]);
  const [persistData, setPersistData] = useState();
  const [addUpdateDrawerVisibility, setAddUpdateDrawerVisibility] =
    useState(false);
  const [showArrow, setShowArrow] = useState(true);
  const [subjectValue, setSubjectValue] = useState("");
  const [qulification, setQulification] = useState("");
  const [editCompany, setEditCompany] = useState("view");
  const [editPerson, setEditPerson] = useState("view");
  const [isAdvanceClear, setIsAdvanceClear] = useState(false);
  const pageSize = 40;

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  });
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };
  const onChangeStarFilled = (e) => {
    if (e.key == "Enter") {
      setIsFavorite(false);
    }
  };

  useEffect(() => {
    fetchAllSector();
    getQualificationListing();
  }, []);
  useEffect(() => {
    setFormValueReset();
  }, []);

  // useEffect(()=>{
  //   if(qulification || subjectValue){
  //     setAdvanceButtonDisabled(false)
  //   }else{
  //     setAdvanceButtonDisabled(true)
  //   }
  // },[qulification,subjectValue])

  const fetchAllSector = async () => {
    const resp = await sectorListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setSectors(resp.data);
    }
  };

  const getQualificationListing = async () => {
    const resp = await qualificationListing().catch((err) => {
      message.error("Failure");
    });

    if (resp && resp.status === 200) {
      setQualificationData(resp.data);
    }
  };

  useEffect(() => {
    if (
      !isEmpty(contactCompanyDetailAddResponse) ||
      !isEmpty(contactCompanyUpdateResponse)
    ) {
      let data = {
        ...contactCompanyUpdateResponse,
        status: "company",
      };
      dispatch(contactUpdate(data));
      setEditCompany("view");
    }
  }, [contactCompanyDetailAddResponse, contactCompanyUpdateResponse]);

  useEffect(() => {
    if (contactCompanyDelete) {
      let data = {
        contactCompanyDelete: contactCompanyDelete,
        id: contactCompanyGetDetailsResponse?.id,
        status: "company",
      };
      setEditCompany("view");
      dispatch(contactUpdate(data));
      dispatch(selectedMailTypeAndMail(""));
    }
  }, [contactCompanyDelete]);

  useEffect(() => {
    if (
      !isEmpty(contactPersonAddResponse) ||
      !isEmpty(contactPersonUpdateResponse)
    ) {
      setEditPerson("view");
      let data = {
        ...contactPersonUpdateResponse,
        status: "person",
      };
      dispatch(contactUpdate(data));
    }
  }, [contactPersonAddResponse, contactPersonUpdateResponse]);

  useEffect(() => {
    if (contactPersonDelete) {
      let data = {
        contactPersonDelete: contactPersonDelete,
        id: contactPersonGetDetailsResponse?.id,
        status: "person",
      };
      setEditPerson("view");
      dispatch(contactUpdate(data));
      dispatch(selectedMailTypeAndMail(""));
    }
  }, [contactPersonDelete]);

  const showAddUpdateDrawer = (e) => {
    setAddUpdateDrawerVisibility(true);
    setShowArrow(false);
  };

  const onAddUpdateClose = () => {
    setAddUpdateDrawerVisibility(false);
    setShowArrow(true);
  };

  useEffect(() => {
    if (!isEmpty(applicationConfiguration)) {
      setGrantedPolices(applicationConfiguration?.auth?.grantedPolicies);
    }
  }, [applicationConfiguration]);

  useEffect(() => {
    if (isEmpty(expertPersonPaginationResponse?.items)) {
      setContactCompanyId();
      setId();
    }
  }, [expertPersonPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(selectedMailTypeAndMailData)) {
      // setPersistData(selectedMailTypeAndMailData);

      if (selectedMailTypeAndMailData?.id) {
        sethidden(false);
        if (selectedMailTypeAndMailData?.table === "company") {
          setSearchPersonCompany("");
          setContactPersonId("");
          setContactCompanyId(
            selectedMailTypeAndMailData?.id
              ? selectedMailTypeAndMailData?.id
              : ""
          );
          dispatch(contactCompanyGetDetails(selectedMailTypeAndMailData.id));
        } else if (selectedMailTypeAndMailData?.table === "person") {
          sethidden(true);
          setContactCompanyId("");
          setContactPersonId(
            selectedMailTypeAndMailData?.id
              ? selectedMailTypeAndMailData?.id
              : ""
          );
          dispatch(contactPersonGetDetails(selectedMailTypeAndMailData.id));
        }
      }
      setId(
        selectedMailTypeAndMailData?.id ? selectedMailTypeAndMailData?.id : ""
      );
      setInputSearcHValue(
        selectedMailTypeAndMailData.search
          ? selectedMailTypeAndMailData.search
          : ""
      );
      setSearchKeyword(
        selectedMailTypeAndMailData.search
          ? selectedMailTypeAndMailData.search
          : ""
      );
      setSearchPersonCompany(
        selectedMailTypeAndMailData.search
          ? selectedMailTypeAndMailData.search
          : ""
      );
    }
  }, []);

  useEffect(() => {
    if (!contactPersonPaginationResponse) return;
    setdata(contactPersonPaginationResponse?.items);
    setIsModalVisible(false);
  }, [contactPersonPaginationResponse]);

  useEffect(() => {
    if (!isEmpty(expertPersonPaginationResponse?.items)) return;
    setCompanyData(expertPersonPaginationResponse?.items);
  }, [expertPersonPaginationResponse]);

  // const getNullContactDetail = (
  //   MaxResultCount = 60,
  //   SkipCount = 0,
  //   IsFavourite_SearchKeyword = isFavorite ? isFavorite : "false",
  //   loadMore = false
  // ) => {
  //   let appendUrl =  `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;
  //   // console.log(isFavorite,"isFavorite")

  //   dispatch(
  //     getContactPersonPagination({
  //       MaxResultCount,
  //       SkipCount,
  //       IsFavourite_SearchKeyword,
  //     },
  //     loadMore
  //     )
  //   );
  // }

  // const getNullCompanyDetail = (
  //   MaxResultCount = 10,
  //   SkipCount = 0,
  //   IsFavourite_SearchKeyword = isFavorite ? isFavorite : "false",
  //   loadMore = false
  // ) => {
  //   let appendUrl =  `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;
  //   // console.log(isFavorite,"isFavorite")

  //   dispatch(
  //     getContactCompanyPagination({
  //       MaxResultCount,
  //       SkipCount,
  //       IsFavourite_SearchKeyword,

  //     },
  //     loadMore
  //     )
  //   );
  // }
  const getContactDetails = (
    MaxResultCount = 50,
    SkipCount = 0,
    IsFavourite_SearchKeyword = isFavorite ? isFavorite : "false",
    loadMore = false
  ) => {
    let appendUrl = `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;
    if (isFavorite == true) {
      appendUrl += `&IsFavourite_SearchKeyword=${IsFavourite_SearchKeyword}`;
    } else if (isFavorite == false) {
      appendUrl += undefined;
    }
    console.log("index: getContactDetails Called");
    dispatch(getExpertPagnination({ IsFavourite_SearchKeyword: true }));
  };

  const getPersonDetails = (
    MaxResultCount = pageSize,
    SkipCount = 0,
    IsFavourite_SearchKeyword = isFavorite ? isFavorite : "false",
    loadMore = false
  ) => {
    let appendUrl = `MaxResultCount=${MaxResultCount}&SkipCount=${SkipCount}`;
    // console.log(isFavorite,"isFavorite")
    if (isFavorite == true) {
      appendUrl += `&IsFavourite_SearchKeyword=${IsFavourite_SearchKeyword}`;
    } else {
    }
    // console.log("index: getPersonDetails Called");
    dispatch(
      getExpertPagnination(
        {
          MaxResultCount,
          SkipCount,
          IsFavourite_SearchKeyword,
          searchKeyword: SearchKeyword,
        },
        loadMore
      )
    );
  };

  useEffect(() => {
    getContactDetails();

    getPersonDetails();
  }, [SearchKeyword, isFavorite, isAdvanceClear]);

  const search = data
    ?.filter((fav) => fav.isFavorite === true)
    .map((filteredfav) => {
      return filteredfav;
    });

  const searchCompany =
    !isEmpty(expertPersonPaginationResponse) &&
    expertPersonPaginationResponse?.items
      ?.filter((fav) => fav.isFavorite === true)
      .map((filteredfav) => {
        return filteredfav;
      });

  useEffect(() => {
    if (contactCompanyGetDetailsResponse) {
      setResponseNotes(contactCompanyGetDetailsResponse);
    }
  }, [contactCompanyGetDetailsResponse]);

  useEffect(() => {
    setNotes(true);
  }, [onClickCompany, onClickPerson]);

  useEffect(() => {
    // getNullContactDetail();
    // getNullCompanyDetail();
  }, [SearchKeyword, isFavorite]);

  useEffect(() => {
    if (contactPersonGetDetailsResponse) {
      setPersonResponseNotes(contactPersonGetDetailsResponse);
    }
    // if (contactPersonNoteRespone) {
    //   setPersonResponseNotes(contactPersonNoteRespone);
    // }
  }, [contactPersonGetDetailsResponse]);

  const rowEventhandlerscompany = (record) => {
    sethidden(false);
    setEditCompany("view");
    companyForm.resetFields();
    dispatch(contactCompanyGetDetails(record.contactCompanyId));
    setContactCompanyId(record.contactCompanyId);
    setSearchPersonCompany(record.contactCompanyName);
    setId(record.contactCompanyId);
    dispatch(
      selectedMailTypeAndMail({
        id: record.contactCompanyId,
        search: SearchKeyword,
        table: "company",
      })
    );
  };

  const setRowClassName = (record) => {
    return record.contactCompanyId === id ? "clickRowStyl" : "";
  };

  const rowEventhandlersperson = (record) => {
    setEditPerson("view");
    // setSearchPersonCompany("");
    personForm.resetFields();
    sethidden(true);
    dispatch(contactPersonGetDetails(record.contactPersonId));
    setContactPersonId(record.contactPersonId);
    // setSearchPersonCompany(record.contactCompanyName);
    setId(record.contactPersonId);
    dispatch(
      selectedMailTypeAndMail({
        id: record.contactPersonId,
        search: SearchKeyword,
        table: "person",
      })
    );
  };

  const setRowPersonClassName = (record) => {
    return record.contactPersonId === id;
  };

  const showAddUpdateCountryDrawer = (e, record) => {
    setAddUpdatePersonDrawerVisibility(false);
    if (record) {
      dispatch(contactCompanyGetDetails(record.contactCompanyId));
      setIsUpdateCountry(record);
    } else {
      setIsUpdateCountry(false);
    }
    setAddUpdateCountryDrawerVisibility(true);
  };

  const showAddUpdatePersonDrawer = (e, record) => {
    setAddUpdateCountryDrawerVisibility(false);
    if (record) {
      dispatch(contactPersonGetDetails(record.contactPersonId));
      setIsUpdatePerson(record);
    } else {
      setIsUpdatePerson(false);
    }
    setAddUpdatePersonDrawerVisibility(true);
  };

  const onAddUpdateCountryClose = () => {
    setIsUpdateCountry("");
    setSearchKeyword("");
    setAddUpdateCountryDrawerVisibility(false);
    form.resetFields();
  };

  const onAddUpdatePersonClose = () => {
    setIsUpdatePerson("");
    setSearchKeyword("");
    setAddUpdatePersonDrawerVisibility(false);
  };

  useEffect(() => {
    if (contactPersonId) {
      dispatch(contactPersonGetDetails(contactPersonId));
    }
  }, [contactPersonUpdateResponse]);

  useEffect(() => {
    if (
      search?.length > 0 &&
      data?.length > 0 &&
      searchCompany?.length > 0 &&
      expertPersonPaginationResponse?.items?.length > 0
    ) {
      if (
        search.length === data.length &&
        searchCompany.length === expertPersonPaginationResponse?.items?.length
      ) {
        setStarColor("#ffd036");
      } else {
        setStarColor("#c8c8c8");
      }
    } else {
      setStarColor("#c8c8c8");
    }
  }, [search, data]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    const filterValue = {
      ...values,
      IsAdvancedFilter: true,
      ExperienceFrom: values?.yearsOfExperience?.[0],
      ExperienceTo: values?.yearsOfExperience?.[1],
    };
    // delete filterValue.yearsOfExperience;
    if (
      values?.Email === undefined &&
      values?.Phone === undefined &&
      values?.Qualification === undefined &&
      values?.Website === undefined &&
      values?.majorSubject === undefined &&
      values?.sector === undefined &&
      values?.yearsOfExperience === undefined
    ) {
      message.error("Please Fill the Form");
    } else {
      dispatch(
        getExpertPagnination(
          {
            MaxResultCount: 35,
            SkipCount: 0,
            ...filterValue,
          },
          false
        )
      );
      setIsAdvanceClear(true);
    }
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (
      !isEmpty(expertPersonPaginationResponse?.items) &&
      isEmpty(contactCompanyId) &&
      isEmpty(contactPersonId)
    ) {
      let id = expertPersonPaginationResponse.items[0].contactCompanyId;

      setContactCompanyId(id);
      setId(id);
    } else {
      setContactCompanyId("");
      setId("");
    }
  }, [expertPersonPaginationResponse]);

  const resetInputField = () => {
    setSearchKeyword("");
  };

  return (
    <div className="layout">
      <div className="organizer contacts">
        <div className="contactDetails__components">
          <div
            className="contactDetails__split"
            style={{ display: "flex", width: "100%" }}
          >
            <div className="contactDetails__split--pagination">
              <Row className="events__buttons">
                <Col lg={24}>
                  <div className="ContactDetails__header">
                    <h3 className="header__all">Expert </h3>
                  </div>
                </Col>
              </Row>

              <Modal
                title="Advance Expert Search"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
                wrapClassName="advance_search_email"
              >
                <Form
                  form={form}
                  labelAlign="left"
                  colon={false}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="row-wrapper">
                    <div
                      className="global-form-flex"
                      style={{ flexFlow: "row wrap" }}
                    >
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Sector</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="sector">
                            <Select
                              showSearch={true}
                              allowClear
                              filterOption={(input, option) =>
                                option
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {sectors.map((data) => (
                                <Option key={data.id} value={data.sectorName}>
                                  {data.sectorName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Qualification</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="Qualification">
                            <Input placeholder="Qualification" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Major Subject</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="majorSubject">
                            <Input placeholder="Major Subject" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Phone Number</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="Phone">
                            <Input placeholder="Phone Number" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Email</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="Email">
                            <Input placeholder="Email" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Website</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="Website">
                            <Input placeholder="Website" />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc">
                        <div className="global-form-label">
                          <label>Years of Experience</label>
                        </div>
                        <div className="global-form-input">
                          <Form.Item name="yearsOfExperience">
                            <Slider
                              range
                              step={1}
                              defaultValue={[0, 0]}
                              // onChange={onChange}
                              // onAfterChange={onAfterChange}
                              max="25"
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <div className="global-form-group calc advance-btn-search-wrapper">
                        <Form.Item>
                          <Button
                            type="ghost"
                            shape="round"
                            autoComplete="off"
                            // onClick={clearAdvanceForm}
                            onClick={() => {
                              form.resetFields();
                            }}
                            // disabled={advanceButtonDisabled}
                          >
                            Remove
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="ghost "
                            shape="round"
                            htmlType="submit"
                            loading={contactPersonPaginationLoading}
                            autoComplete="off"
                            // disabled={advanceButtonDisabled}
                            //  onClick={() => {
                            //   form.resetFields();
                            // }}
                          >
                            Search
                          </Button>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>
              </Modal>
              <div className="search-wrapper">
                <StarFilled
                  style={{ color: isFavorite ? "#ffd036" : "#c8c8c8" }}
                  onClick={() => {
                    setIsFavorite(!isFavorite);
                  }}
                />
                {isAdvanceClear === true ? (
                  <>
                    <Input.Search
                      className="form__group_email_search"
                      style={{ margin: 0 }}
                      // allowClear
                      size="medium"
                      bordered={false}
                      value={"[Advance Search]"}
                    />
                    <button
                      onClick={() => {
                        resetInputField();
                        setIsAdvanceClear(false);
                        setIsFavorite(true);
                        //  getNullContactDetail();
                        //  getNullCompanyDetail();
                        form.resetFields();
                      }}
                      className="cross-section-advance-filter"
                    >
                      &#10006;
                    </button>{" "}
                  </>
                ) : (
                  <Input.Search
                    className="form__group"
                    style={{ margin: 0 }}
                    placeholder="Search"
                    size="medium"
                    allowClear
                    onSearch={(e) => {
                      setSearchKeyword(e);
                      setIsFavorite(!isFavorite);
                    }}
                    onKeyDown={onChangeStarFilled}
                    onChange={(e) => {
                      setInputSearcHValue(e.target.value);
                    }}
                    autoComplete="off"
                  />
                )}

                <div
                  className="side__search__wrapper side-contact-search-filter"
                  style={{ cursor: "pointer" }}
                  //  onClick={() => {
                  //    setUnRead(!read);
                  //    setSelectTopEmail(true);
                  //    setCurrentSelectedMailMessage("");
                  //  }}
                  onClick={showModal}
                >
                  <FilterOutlined />
                </div>

                <div className="contactDetails__dropdown">
                  <Button
                    className="contact__header__btn-primary"
                    onClick={() => {
                      setEditPerson("add");
                      sethidden(true);
                      personForm.resetFields();
                      expertForm.resetFields();
                      personNotesForm.resetFields();
                    }}
                  >
                    <PlusOutlined />
                  </Button>
                </div>
              </div>

              <>
                <div style={{ height: "100%" }}>
                  <AddUpdatePersonTable
                    searchKey={SearchKeyword}
                    rowEventhandlers={rowEventhandlersperson}
                    showAddUpdatePersonDrawer={showAddUpdatePersonDrawer}
                    setRowPersonClassName={setRowPersonClassName}
                    searchPersonCompany={searchPersonCompany}
                    contactPersonGetDetailsResponse={
                      contactPersonGetDetailsResponse
                    }
                    contactPersonGetDetailsLoading={
                      contactPersonGetDetailsLoading
                    }
                    contactCompanyId={contactCompanyId}
                    selectedMailTypeAndMailData={selectedMailTypeAndMailData}
                    setOnClickPerson={setOnClickPerson}
                    onClickPerson={onClickPerson}
                  />
                </div>
                : <Skeleton />
              </>
            </div>
            <div
              className="contactDetails__split--right conatct-right-side"
              style={{ display: "flex" }}
            >
              <div
                className="contact__details__render compant-person-render"
                style={{ padddingLeft: 25 }}
              >
                {show ? (
                  !contactPersonGetDetailsLoading &&
                  !isEmpty(contactPersonGetDetailsResponse && editPerson) ? (
                    editPerson === "view" ? (
                      <Showdataformperson
                        setEditPerson={setEditPerson}
                        showAddUpdatePersonDrawer={showAddUpdatePersonDrawer}
                        SearchKeyword={SearchKeyword}
                        grantedPolices={grantedPolices}
                        data={data}
                      />
                    ) : (
                      <AddPersonDrawer
                        setEditPerson={setEditPerson}
                        editPerson={editPerson}
                        form={personForm}
                        form1={expertForm}
                      />
                    )
                  ) : editPerson === "add" ? (
                    <AddPersonDrawer
                      setEditPerson={setEditPerson}
                      editPerson={editPerson}
                      form={personForm}
                      form1={expertForm}
                    />
                  ) : (
                    <div style={{ padddingLeft: 25 }}>
                      <Skeleton
                        avatar={{ size: 96, shape: "square" }}
                        paragraph={{ rows: 10 }}
                      />
                    </div>
                  )
                ) : !contactCompanyGetDetailsLoading &&
                  !isEmpty(contactCompanyGetDetailsResponse && editCompany) ? (
                  editCompany === "edit" || editCompany === "add" ? (
                    <AddCompanyDrawer
                      setEditCompany={setEditCompany}
                      editCompany={editCompany}
                      form={companyForm}
                    />
                  ) : (
                    <div style={{ padddingLeft: 25 }}>
                      <Showdataform
                        setEditCompany={setEditCompany}
                        showAddUpdateCountryDrawer={showAddUpdateCountryDrawer}
                        SearchKeyword={SearchKeyword}
                        grantedPolices={grantedPolices}
                        data={data}
                      />
                    </div>
                  )
                ) : editCompany === "add" ? (
                  <AddCompanyDrawer
                    setEditCompany={setEditCompany}
                    editCompany={editCompany}
                    form={companyForm}
                  />
                ) : (
                  <div style={{ padddingLeft: 25 }}>
                    <Skeleton
                      avatar={{ size: 96, shape: "square" }}
                      paragraph={{ rows: 10 }}
                    />
                  </div>
                )}
              </div>

              {show ? (
                contactPersonId ? (
                  editPerson === "view" && (
                    <div className="contact__editnotes">
                      <PersonEditNotes
                        personResponseNotes={personResponseNotes}
                        showArrow={showArrow}
                        drawerVisibility={addUpdateDrawerVisibility}
                        showAddUpdateDrawer={showAddUpdateDrawer}
                        onAddUpdateClose={onAddUpdateClose}
                        notes={notes}
                        setNotes={setNotes}
                      />
                    </div>
                  )
                ) : (
                  <div style={{ padddingLeft: 25 }}>
                    <Skeleton paragraph={{ rows: 1 }} />
                  </div>
                )
              ) : contactCompanyId ? (
                editCompany === "view" && (
                  <div className="contact__editnotes">
                    <div className="editnotes-wrapper">
                      <EditNotes
                        drawerVisibility={addUpdateDrawerVisibility}
                        showArrow={showArrow}
                        showAddUpdateDrawer={showAddUpdateDrawer}
                        responseNotes={responseNotes}
                        onAddUpdateClose={onAddUpdateClose}
                        windowWidth={windowWidth}
                        editCompany={editCompany}
                        form={companyNotesForm}
                        notes={notes}
                        setNotes={setNotes}
                      />
                    </div>
                  </div>
                )
              ) : (
                <div style={{ padddingLeft: 25 }}>
                  <Skeleton paragraph={{ rows: 1 }} />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <Row gutter={32} style={{ height: '98vh', overflow: 'hidden' }}>
					<Col lg={12} className="organizer__divider">
					</Col>

					<Col lg={12}>
						
					</Col>
				</Row> */}
      </div>
    </div>
  );
};

export default ExpertCompanyPerson;
