import { Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const ViewFiles = () => {
  const { selectedFileName } = useSelector(
    (state) => state.selectedFileNameState
  );

  return (
    <div style={{ height: "100vh" }}>
      <img
        src={selectedFileName}
        style={{ height: "100%", width: "100%", objectFit: "contain" }}
      />
      <a
        href={selectedFileName}
        download
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
        }}
      >
        <Button>Save</Button>
      </a>
    </div>
  );
};

export default ViewFiles;
